import * as Constant from '../../../constants'
export default async function getgroups(datasetId, items, item_dict_lookup) {
    //Pull Groups
    //Look at items from sim and items from geo and see which groups are in the sim...
    //Review Item Groups and Avaialable Items...
    //If There Are All Items For a Given Group in SElected Items, mark that Group As In the Sim....

    let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetId)
    var user_dataset_settings_data
    if (user_dataset_settings_response.status === 200) {
        user_dataset_settings_data = await user_dataset_settings_response.json()
    }
    else {
        user_dataset_settings_data = []
    }

    var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)
    var itemgroupssettings_data
    if (usersettings_list.includes('ItemGroups')) {
        let itemgroupsettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetId + '/ItemGroups')
        if (itemgroupsettings_response.status === 200) {
            itemgroupssettings_data = await itemgroupsettings_response.json()
        }
    }

    else {
        itemgroupssettings_data = {}
    }

    //Determine if any are aleady in the sim (?)        
    //Add a key called checked to itemgroupssettings_data
    var itemgroups = this.state.itemgroups
    if (!itemgroups) {
        itemgroups = itemgroupssettings_data['setting_value'] || []
        itemgroups.forEach(group => {
            group['checked'] = false
        })
    }

    let valid_items_for_geo = Object.keys(item_dict_lookup)
    var selectedgroups = []
    var selectedgroupsforitems = []
    itemgroups.forEach(group => {
        let group_items = group.item_vals
        let valid_items_forgroup_and_geo = []
        valid_items_for_geo.forEach(item => {
            if (group_items.includes(parseInt(item))) {
                valid_items_forgroup_and_geo.push(parseInt(item))
            }
        })
        //Determine if items_from_sim contains the whole valid group
        let items_from_sim_from_group = []
        
        items.forEach(item => {
            if (valid_items_forgroup_and_geo.includes(parseInt(item))) {
                items_from_sim_from_group.push(parseInt(item))
            }
        })        

        if (items_from_sim_from_group.length >= 1) { //Change this to allow partial groups... == valid_items_forgroup_and_geo.length
            group['checked'] = true
            selectedgroups.push(group)
            selectedgroupsforitems.push(group)
        }
    })


    let selecteditems_fromgroup = []
    selectedgroupsforitems.forEach(group => {
        group.item_vals.forEach(item => {
            //confirm its in the item_lookuop_object and if so, add to the list of items
            let valid_items = Object.keys(item_dict_lookup)
            if (valid_items.includes(String(item))) {
                selecteditems_fromgroup.push(String(item))
            }
        })
    })

    //create itemgroup_options

    let itemgroup_options = selectedgroups.map(group => {
        return { 'label': group.groupname, 'value': group.groupname }
    })

    let selected_itemgroup = (selectedgroups[0] || {})['groupname']

    this.setState({
        selectedgroups
        , selectedgroupsforitems
        , selecteditems_fromgroup
        , itemgroups
        , itemgroup_options
        , selected_itemgroup
    })
}