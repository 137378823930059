import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import AppLite from './App'
//import { render } from "react-dom";
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Switch>
            <Route path="/Lite" component={AppLite} />
            <Route path="/" component={App} />
            <Route render={() => <h1>Page not found</h1>} />
        </Switch>
  </BrowserRouter>,
  rootElement);

//registerServiceWorker();

