import React, { Component } from 'react';
import { TopBottomMetricsSelect } from '../../UIElements/Dropdowns/TopBottomMetricsSelect'
import { TopBottomSwitch } from '../../UIElements/Switches/TopBottomSwitch'
import { TopBottomGraph } from '../../../Graphs/TopBottomGraph'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';

export class ALRvsROM_TopBottomWeeks extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className='row' style={{ textAlign: 'center', padding: '25px' }}>
                    {!this.props.state.indexTopBottom ?
                        <div className='col-sm-12'><span>Top Performing Weeks for {this.props.state.metricsforindexviews_selected.metric}</span></div>
                        :
                        <div className='col-sm-12'><span>Bottom Performing Weeks for {this.props.state.metricsforindexviews_selected.metric}</span></div>
                    }
                    {this.props.state.chart_title_info.split('<br>').map(info => {
                        return <div className='col-sm-12' key={'ChartInfo_' + info}><span>{info}</span></div>
                    })}
                </div>
                <div className="row" >
                    <div align='left' className="col-sm-6" style={{ padding: '5px' }} >
                        <TopBottomMetricsSelect
                            state={this.props.state}
                            changeMetricsIndexedWeeks={this.props.changeMetricsIndexedWeeks}
                        />
                    </div>

                    <div align='center' className="col-sm-6 row" style={{ padding: '5px' }} >
                        <TopBottomSwitch
                            state={this.props.state}
                            handleChange_IndexTopBottom={this.props.handleChange_IndexTopBottom}
                        />
                    </div>
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <TopBottomGraph
                        state={this.props.state}
                    />
                </div>
            </div>
        )
    }
}