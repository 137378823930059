export default function findPercentileValue(v, totalSum, obj) {
    let runningSum = 0
    let value = 0
    let pricereturned = 0
    let prices = Object.keys(obj).sort(function (a, b) { return a - b })
    prices.forEach(price => {
        pricereturned = parseFloat(price)
        if (pricereturned > v) {

        }
        else {
            runningSum += obj[price]
            if (v > pricereturned) {
                value = (runningSum + 1) / (totalSum + 1)
            }
            else {
                value = (runningSum) / totalSum
            }
        }
    })
    return value
}