import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import * as Constant from '../../../constants';

export class ScoreCardDownloadMenuYOY extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <IconButton aria-label="close" color="inherit" onClick={this.props.openScoreCardMenuYOY}>
                    <MenuIcon fontSize="large" />
                </IconButton>
                <Constant.NPDPopover
                    anchorEl={this.props.state.anchorELPos}
                    open={this.props.state.openScorecardMenuYOY}
                    transition
                    onClose={this.props.closeScoreCardMenuYOY}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div style={{ padding: '25px' }}>
                        <div >

                            <span
                                style={{
                                    color: '#4e106f',
                                    cursor: 'pointer',
                                    fontWeight: 'bold'
                                }}
                                onClick={this.props.downloadScoreCardDataYOY}
                            >
                                Download XLSX
							</span>
                        </div>
                    </div>
                </Constant.NPDPopover>
            </Fragment>
        )
    }
}