import React, { Component, Fragment } from 'react';
import { FlexibleBrandTable } from './FlexibleBrandTable'

export class FlexibleBrandTableView extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        return !! nextProps.state.render_update
    }

    render() {
        console.log(this.props)
        return (
            <div style={{  marginBottom: '25px', overflowY: 'auto', maxHeight: '1000px' }}>
                <h5>Brand Performance</h5>
                <FlexibleBrandTable
                    state={this.props.state}
                    updateData={this.props.updateData}
                    handleChange_twoyago={this.props.handleChange_twoyago}
                    changeViewFilters={this.props.changeViewFilters}
                    changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    openFlexibleBrandTableMenu={this.props.openFlexibleBrandTableMenu}
                    closeFlexibleBrandTableMenu={this.props.closeFlexibleBrandTableMenu}
                    downloadFlexibleBrandTableData={this.props.downloadFlexibleBrandTableData}
                />
            </div>
        )
    }
}
