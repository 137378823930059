export default function CreateWeeksNew(filter_object, times_data, datasettimeagg) {
    //See How Many Weeks Are in the Filters
    let startdate = filter_object['startdate']
    let enddate = filter_object['enddate']
    let numdaysinperiod_start = ((new Date(enddate) - new Date(startdate)) / (1000 * 60 * 60 * 24)) + 7
    let numweeksinperiod = numdaysinperiod_start / 7

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function formatDateString(d) {
        
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function createDate(date, days) {
        let date_String = String(parseInt(date.substring(0, 4))) + "-" + date.substring(5, 7) + "-" + date.substring(8, 10) + 'T00:00:00'
        let date_Date = new Date(date_String)
        date_Date.setDate(date_Date.getDate() - days);
        return date_Date
    }

    function createDateArray(startdate, enddate) {

        let date_array = []
        //Add 7 days from start to end
        let date_String = String(parseInt(formatDate(startdate).substring(0, 4))) + "-" + formatDate(startdate).substring(5, 7) + "-" + formatDate(startdate).substring(8, 10) + 'T00:00:00'
        let datecalc = new Date(date_String)

        while (datecalc <= enddate) {
            let date_string = formatDate(datecalc)
            date_array.push(date_string)
            //add seven days
            datecalc.setDate(datecalc.getDate() + 7)
        }
        return date_array
    }

    function createDateMappingNew(startdate, enddate, numweeks) {
        let date_String = String(parseInt(formatDate(startdate).substring(0, 4))) + "-" + formatDate(startdate).substring(5, 7) + "-" + formatDate(startdate).substring(8, 10) + 'T00:00:00'
        let datecalc = new Date(date_String)
        let weekbound_datecalc = new Date(date_String)
        let datemapping = {}
        let groupeddates = []

        while (datecalc <= enddate) {
            let weekbound = weekbound_datecalc.setDate(weekbound_datecalc.getDate() + ((numweeks) * 7))
            let weekbound_date = new Date(weekbound)
            weekbound_date.setDate(weekbound_date.getDate() - 7) //Use The last valid week of the bounded time period
            groupeddates.push(formatDate(weekbound_date))

            while (datecalc < weekbound) {
                datemapping[formatDate(datecalc)] = formatDate(weekbound_date)
                datecalc.setDate(datecalc.getDate() + 7)
            }
        }

        //Perform a calculation of how many weeks have been provided
        let startdate_date = new Date(startdate)
        let enddate_date = new Date(enddate)
        // To calculate the time difference of two dates
        var Difference_In_Time = enddate_date.getTime() - startdate_date.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 7;
        var Difference_In_Weeks = Difference_In_Days / 7;
        var NumberOfWeeks = Difference_In_Weeks / numweeks

        if (groupeddates.length > NumberOfWeeks) {
            console.log('Need to Remove The Last Week...Not Enough Data')
            groupeddates.pop()
        }

        return {
            datemapping, groupeddates
        }

    }

    function createDateFormatting(filter_object, datasettimeagg) {
        var weekagg = filter_object['numweeks']
        var xDateFormatString = ""
        var weeklytimeperiods = ""
        var axisFormatString = ""
        var axisText = ""
        var timeperiodlabels = ""
        var stepNum = 1
        var paddingNum = 5
        var fontSizeString = '11px'
        if (datasettimeagg === 'Monthly') {
            timeperiodlabels = "Month"
            if (weekagg !== 1) {
                xDateFormatString = weekagg + ' Month Period Ending: %Y-%m-%d'
                weeklytimeperiods = weekagg + " Month Time Periods"
                axisFormatString = '%e. %b'
                axisText = weekagg + ' Month Period'
            }

            else {
                xDateFormatString = 'Month Ending: %Y-%m-%d'
                weeklytimeperiods = "Month"
                axisFormatString = '%b %Y'
                axisText = 'Month'
            }
        }
        else {
            timeperiodlabels = "Week"
            if (weekagg !== 1) {
                xDateFormatString = weekagg + ' Week Period Ending: %Y-%m-%d'
                weeklytimeperiods = weekagg + " Week Time Periods"
                axisFormatString = '%b %Y'
                axisText = weekagg + ' Week Period'
            }

            else {
                xDateFormatString = 'Week Ending: %Y-%m-%d'
                weeklytimeperiods = "Week"
                axisFormatString = '%b %Y'
                axisText = 'Week Ending'
            }
        }

        return {
            xDateFormatString,
            weeklytimeperiods,
            axisFormatString,
            axisText,
            fontSizeString,
            timeperiodlabels,
            paddingNum,
            stepNum,
            fontSizeString
        }
    }

    function calculateShowYOY(filter_object, times_data, priorperiod_start, priorperiod_end, lastperiod_start, lastperiod_end, threeyearperiod_start, threeyearperiod_end) {

        var showYOYViews, showLastViews, showThreeYearViews
        let datasetstartdate = new Date(((times_data || [])[0] || ""))
        let datasetendate = new Date(((times_data || [])[1] || ""))


        if (priorperiod_start >= datasetstartdate && priorperiod_end <= datasetendate) {
            showYOYViews = true
        }

        else {
            showYOYViews = false
        }


        if (lastperiod_start >= datasetstartdate && lastperiod_end <= datasetendate) {
            showLastViews = true
        }

        else {
            showLastViews = false
        }

        if (threeyearperiod_start >= datasetstartdate && threeyearperiod_end <= datasetendate) {
            showThreeYearViews = true
        }

        else {
            showThreeYearViews = false
        }

        return { showYOYViews, showLastViews, showThreeYearViews }

    }

    //Get Offset for Creating Dates
    let dt = new Date()
    let offset = dt.getTimezoneOffset() * 60 * 1000
    //create eligible periods....
    var periodweeks
        , numdaysinperiod
        , currperiod_start
        , currperiod_end
        , priorperiod_start
        , priorperiod_end
        , lastperiod_start
        , lastperiod_end
        , threeyearperiod_start
        , threeyearperiod_end
        , currperiod_days
        , priorperiod_days
        , lastperiod_days
        , threeyearperiod_days

    if (numweeksinperiod > 156) {
        periodweeks = numweeksinperiod - 156
        numdaysinperiod = periodweeks * 7
    }

    else if (numweeksinperiod <= 156 && numweeksinperiod > 104) {
        periodweeks = numweeksinperiod - 104
        numdaysinperiod = periodweeks * 7
    }

    else if (numweeksinperiod <= 104 && numweeksinperiod > 52) {
        periodweeks = numweeksinperiod - 52
        numdaysinperiod = periodweeks * 7
    }

    else if (numweeksinperiod <= 52) {
        periodweeks = numweeksinperiod
        numdaysinperiod = periodweeks * 7
    }

    currperiod_start = createDate(enddate, (numdaysinperiod - 7))
    currperiod_end = createDate(enddate, 0)

    priorperiod_start = createDate(enddate, 364 + (numdaysinperiod - 7))
    priorperiod_end = createDate(enddate, 364)

    lastperiod_start = createDate(enddate, 728 + (numdaysinperiod - 7))
    lastperiod_end = createDate(enddate, 728)

    threeyearperiod_start = createDate(enddate, 1092 + (numdaysinperiod - 7))
    threeyearperiod_end = createDate(enddate, 1092)

    currperiod_days = createDateArray(currperiod_start, currperiod_end)
    priorperiod_days = createDateArray(priorperiod_start, priorperiod_end)
    lastperiod_days = createDateArray(lastperiod_start, lastperiod_end)
    threeyearperiod_days = createDateArray(threeyearperiod_start, threeyearperiod_end)

    let datemapping_new_withend = createDateMappingNew(createDate(startdate, 0), createDate(enddate, 0), filter_object.numweeks)['datemapping']
    let datemapping_new_withend_prior = createDateMappingNew(priorperiod_start, priorperiod_end, filter_object.numweeks)['datemapping']
    let datemapping_new_withend_last = createDateMappingNew(lastperiod_start, lastperiod_end, filter_object.numweeks)['datemapping']
    let datemapping_new_withend_threeyear = createDateMappingNew(threeyearperiod_start, threeyearperiod_end, filter_object.numweeks)['datemapping']
    let groupeddates = createDateMappingNew(createDate(startdate, 0), createDate(enddate, 0), filter_object.numweeks)['groupeddates']
    let dateFormats = createDateFormatting(filter_object, datasettimeagg)
    let showYOY_vars = calculateShowYOY(filter_object, times_data, priorperiod_start, priorperiod_end, lastperiod_start, lastperiod_end, threeyearperiod_start, threeyearperiod_end)

    //groupeddates

    let groupeddates_prior = priorperiod_days
    let groupeddates_calc = currperiod_days
    let groupeddates_last = lastperiod_days
    let groupeddates_threeyears = threeyearperiod_days
    let showYOYViews = showYOY_vars['showYOYViews']
    let showLastViews = showYOY_vars['showLastViews']
    let showThreeYearViews = showYOY_vars['showThreeYearViews']
    let xDateFormatString = dateFormats['xDateFormatString']
    let weeklytimeperiods = dateFormats['weeklytimeperiods']
    let axisFormatString = dateFormats['axisFormatString']
    let axisText = dateFormats['axisText']

    let timeperiodlabels = dateFormats['timeperiodlabels']
    let paddingNum = dateFormats['paddingNum']
    let stepNum = dateFormats['stepNum']
    let fontSizeString = dateFormats['fontSizeString']

    let startdate_calc = formatDateString(currperiod_start)
    let prior_start = formatDateString(priorperiod_start)
    let prior_end = formatDateString(priorperiod_end)    
    let last_start = formatDateString(lastperiod_start)
    let last_end = formatDateString(lastperiod_end)

    let threeyear_start = formatDateString(threeyearperiod_start)
    let threeyear_end = formatDateString(threeyearperiod_end)

    let alldates_prior = priorperiod_days
    let alldates_last = lastperiod_days
    let alldates_threeyear = threeyearperiod_days
    

    return {
        groupeddates,
        groupeddates_prior,
        groupeddates_last,
        groupeddates_calc,
        groupeddates_threeyears,
        datemapping_new_withend,
        datemapping_new_withend_prior,
        datemapping_new_withend_last,
        datemapping_new_withend_threeyear,
        showYOYViews,
        showLastViews,
        showThreeYearViews,
        xDateFormatString,
        weeklytimeperiods,
        axisFormatString,
        axisText,
        startdate,
        enddate,
        prior_start,
        prior_end,
        last_start,
        last_end,
        threeyear_start,
        threeyear_end,
        startdate_calc,
        alldates_prior,
        alldates_last,
        alldates_threeyear,
        timeperiodlabels,
        paddingNum,
        stepNum,
        fontSizeString
    }
}