export default function changeItemGroupSelected(selectedOptions) {
    if (this.state.simsettings.sim_type === 'promo') {
        this.setState({
            selected_itemgroup: selectedOptions.value,
            // The waterfall data item must be a totally new item for the widget to function, so we make a deep copy
            waterfall_data_itemgroup: JSON.parse(JSON.stringify(this.state.waterfall_data_itemgroup)),
            total_volume_change_itemgroup: this.state.total_volume_changes_itemgroup[selectedOptions.value],
            starting_base_volume_itemgroup: this.state.starting_volume_itemgroup[selectedOptions.value],
            diff_total_dollar_itemgroup: this.state.diff_total_dollars_itemgroup[selectedOptions.value],
            old_total_dollar_itemgroup: this.state.start_total_dollars_itemgroup[selectedOptions.value],
            incremental_profit_total_itemgroup: this.state.incremental_profit_itemgroup[selectedOptions.value],

            //If Promo Sim Change Metrics

            basedollars_total_itemgroup: this.state.basedollars_itemgroup[selectedOptions.value],
            retailer_eday_margin_dollars_total_itemgroup: this.state.retailer_eday_margin_dollars_itemgroup[selectedOptions.value],
            retailer_promotion_profit_total_itemgroup: this.state.retailer_promotion_profit_itemgroup[selectedOptions.value],
            retailer_promo_margin_dollars_total_itemgroup: this.state.retailer_promo_margin_dollars_itemgroup[selectedOptions.value],
            incremental_profit_total_itemgroup: this.state.incremental_profit_itemgroup[selectedOptions.value],
            incremental_net_revenue_total_itemgroup: this.state.incremental_net_revenue_itemgroup[selectedOptions.value],
            incremental_gross_revenue_total_itemgroup: this.state.incremental_gross_revenue_itemgroup[selectedOptions.value],
            net_revenue_total_itemgroup: this.state.net_revenue_itemgroup[selectedOptions.value],
            ttl_cost_per_addl_unit_total_itemgroup: this.state.ttl_cost_per_addl_unit_itemgroup[selectedOptions.value],
            total_spend_total_itemgroup: this.state.total_spend_itemgroup[selectedOptions.value],
            gross_revenue_total_itemgroup: this.state.gross_revenue_itemgroup[selectedOptions.value],
            total_promo_volume_itemgroup: this.state.promo_volume_itemgroup[selectedOptions.value],
            total_nonpromo_volume_itemgroup: this.state.nonpromo_volume_itemgroup[selectedOptions.value],
            incdollar_total_itemgroup: this.state.incdollar_itemgroup[selectedOptions.value],
            promodollar_total_itemgroup: this.state.promodollar_itemgroup[selectedOptions.value],
            nonpromodollar_total_itemgroup: this.state.nonpromodollar_itemgroup[selectedOptions.value],
            discountdollar_total_itemgroup: this.state.discountdollar_itemgroup[selectedOptions.value],
            unsubsidized_base_volume_total_itemgroup: this.state.unsubsidized_base_volume_itemgroup[selectedOptions.value],
            subsidized_base_volume_total_itemgroup: this.state.subsidized_base_volume_itemgroup[selectedOptions.value],
            unsubsidized_base_dollars_total_itemgroup: this.state.unsubsidized_base_dollars_itemgroup[selectedOptions.value],
            subsidized_base_dollars_total_itemgroup: this.state.subsidized_base_dollars_itemgroup[selectedOptions.value],
        })
    } else {
        this.setState({
            selected_itemgroup: selectedOptions.value,
            // The waterfall data item must be a totally new item for the widget to function, so we make a deep copy
            waterfall_data_itemgroup: JSON.parse(JSON.stringify(this.state.waterfall_data_itemgroup)),
            total_volume_change_itemgroup: this.state.total_volume_changes_itemgroup[selectedOptions.value],
            starting_base_volume_itemgroup: this.state.starting_volume_itemgroup[selectedOptions.value],
            diff_total_dollar_itemgroup: this.state.diff_total_dollars_itemgroup[selectedOptions.value],
            old_total_dollar_itemgroup: this.state.start_total_dollars_itemgroup[selectedOptions.value],
            incremental_profit_total_itemgroup: this.state.incremental_profit_itemgroup[selectedOptions.value],
            competitor_graph_data_itemgroups: this.state.competitor_data[selectedOptions.value][0],
            competitor_graph_data_price: this.state.competitor_data[selectedOptions.value][1],
            competitor_graph_data_promo: this.state.competitor_data[selectedOptions.value][2],
            competitor_graph_data_total: this.state.competitor_data[selectedOptions.value][3],
            competitor_graph_data_price_pct: this.state.competitor_data[selectedOptions.value][4],
            competitor_graph_data_promo_pct: this.state.competitor_data[selectedOptions.value][5],
            competitor_graph_data_total_pct: this.state.competitor_data[selectedOptions.value][6],
        })
    }
}
