import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import selectColourStyles from '../../../styles/SelectColourStyles'

export class RankOptions extends Component {
    constructor(props) {
        super(props);
    }

    //Create Rank Options...


    render() {
        var unitlabel
        if (this.props.state.indexSwitchCheckDollars) {
            unitlabel = this.props.state.currencyType
        }

        else {
            unitlabel = this.props.state.unitsvar
        }

        return (
            <Fragment>
                <span className="formfieldtitle">{"Select Rank (Based on " + unitlabel })</span>
                <Select
                    options={this.props.state.RankOptions}
                    placeholder={'Select Rank'}
                    onChange={(item, position) => this.props.changeRank(item, 0)}
                    value={this.props.state.selectedRank}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}