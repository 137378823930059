import React, { Component, Fragment } from 'react';
import { FlexibleWeeklyTable } from './FlexibleWeeklyTable'

export class FlexibleWeeklyTableView extends Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return !! nextProps.state.render_update
    }

    render() {
        console.log(this.props)
        return (
            <div style={{  marginBottom: '25px', overflowY: 'auto', maxHeight: '1000px' }}>
                <h5>Weekly Performance</h5>
                <FlexibleWeeklyTable
                    state={this.props.state}
                    updateData={this.props.updateData}
                    handleChange_twoyago={this.props.handleChange_twoyago}
                    changeViewFilters={this.props.changeViewFilters}
                    changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    openFlexibleWeeklyTableMenu={this.props.openFlexibleWeeklyTableMenu}
                    closeFlexibleWeeklyTableMenu={this.props.closeFlexibleWeeklyTableMenu}
                    downloadFlexibleWeeklyTableData={this.props.downloadFlexibleWeeklyTableData}
                />
            </div>
        )
    }
}
