import { saveAs } from "file-saver";
const ExcelJS = require('exceljs');

export default async function downloadScoreCardDataYOY(event) {
    console.log('Scorecard Export Started')

    var name = 'Key Metrics Data'
    var newdate = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
    datestring = datestring.replaceAll(',', '')

    var metadatabrands = ""
    var metadatasegments = ""
    var metadatageos = ""
    var metadatadates = ""
    var metadataattributes = ""
    var metadatapricebands = ""
    var metadataasprange = ""

    try {
        //metadatabrands = this.state.chartmetadata_singlebrand.brands
        //metadatageos = this.state.chartmetadata_singlebrand.geography
        if (this.state.channelviews === true) {
            metadatabrands = this.state.brandsstring
            metadatageos = this.state.geos[0] + ', ' + this.state.selectedChannel
        }
        else {
            metadatabrands = this.state.selectedBrandLabel
            metadatageos = this.state.chartmetadata_singlebrand.geography
        }

        metadatasegments = this.state.chartmetadata_singlebrand.segments
        metadatadates = 'Showing Metrics for the time period from: ' + this.state.distinctdates[0] + ' to ' + this.state.distinctdates[this.state.distinctdates.length - 1]
        metadataattributes = this.state.chartmetadata_singlebrand.attributes
        metadatapricebands = this.state.chartmetadata_singlebrand.pricebands
        metadataasprange = this.state.chartmetadata_singlebrand.asprange
    }
    catch (error) {

    }

    var headers = ['Metric', 'Value']

    //Metrics for Scorecard
    var base_units_curr_withoutYOY = this.state.base_my_withoutYOY
    var sub_base_units_curr_withoutYOY = this.state.sub_my_withoutYOY
    var unsub_base_units_curr_withoutYOY = base_units_curr_withoutYOY - sub_base_units_curr_withoutYOY
    var inc_units_curr_withoutYOY = this.state.inc_my_withoutYOY
    var nonpromo_units_curr_withoutYOY = this.state.nonpromo_my_withoutYOY
    var promo_units_curr_withoutYOY = this.state.promo_my_withoutYOY
    var total_units_curr_withoutYOY = base_units_curr_withoutYOY + inc_units_curr_withoutYOY
    var base_unit_pct_curr_withoutYOY = base_units_curr_withoutYOY / total_units_curr_withoutYOY
    var inc_unit_pct_curr_withoutYOY = inc_units_curr_withoutYOY / total_units_curr_withoutYOY
    var nonpromo_unit_pct_curr_withoutYOY = nonpromo_units_curr_withoutYOY / total_units_curr_withoutYOY
    var promo_unit_pct_curr_withoutYOY = promo_units_curr_withoutYOY / total_units_curr_withoutYOY
    var lift_curr_withoutYOY = inc_units_curr_withoutYOY / base_units_curr_withoutYOY
    var lift_promo_curr_withoutYOY = inc_units_curr_withoutYOY / sub_base_units_curr_withoutYOY
    var eff_curr_withoutYOY = inc_units_curr_withoutYOY / promo_units_curr_withoutYOY
    var avg_base_price_curr_withoutYOY = this.state.weightedbp_my_withoutYOY / this.state.total_my_withoutYOY
    //var avg_base_price_curr_withoutno_withoutYOY = this.state.weightedbp_withoutno_my_withoutYOY / this.state.total_my_withoutYOY


    var nonpromoted_dollars_curr_withoutYOY = this.state.nonpromoteddollars_my_withoutYOY
    var promoted_dollars_curr_withoutYOY = this.state.promoteddollars_my_withoutYOY
    var subbasedollars_curr_withoutYOY = this.state.revopp_my_withoutYOY
    
    //var avg_promo_price_curr_withoutYOY = (this.state.weightedbp_my_withoutYOY / this.state.total_my_withoutYOY) * (1 - (this.state.disc_my_withoutYOY_withoutno / this.state.total_my_withoutYOY_withoutno))
    var avg_disc_curr_withoutYOY = Math.min((this.state.disc_my_withoutYOY / this.state.total_my_withoutYOY), 1)
    var avg_disc_withoutno_curr_withoutYOY = Math.min((this.state.disc_my_withoutYOY_withoutno / this.state.total_my_withoutYOY_withoutno), 1)
    var incremental_dollars_curr_withoutYOY = this.state.incdollars_my_withoutYOY
    var disc_dollars_curr_withoutYOY = this.state.discdollars_my_withoutYOY
    var payback_ratio_curr_withoutYOY = incremental_dollars_curr_withoutYOY / disc_dollars_curr_withoutYOY
    var total_dollars_curr_withoutYOY = this.state.totaldollars_my_withoutYOY
    var nonpromo_dollars_curr_withoutYOY = this.state.nonpromoteddollars_my_withoutYOY
    var promo_dollars_curr_withoutYOY = this.state.promoteddollars_my_withoutYOY
    var base_dollars_curr_withoutYOY = this.state.basedollars_my_withoutYOY
    var inc_dollars_curr_withoutYOY = total_dollars_curr_withoutYOY - base_dollars_curr_withoutYOY//this.state.incrementaldollars_my_withoutYOY
    var sub_base_dollars_curr_withoutYOY = this.state.revopp_my_withoutYOY
    var unsub_base_dollars_curr_withoutYOY = base_dollars_curr_withoutYOY - subbasedollars_curr_withoutYOY
    var unsubbasedollars_curr_withoutYOY = base_dollars_curr_withoutYOY - subbasedollars_curr_withoutYOY

    var avg_promo_price_curr_withoutYOY = promoted_dollars_curr_withoutYOY / promo_units_curr_withoutYOY

    var unsub_base_units_curr_pct_withoutYOY = unsub_base_units_curr_withoutYOY / base_units_curr_withoutYOY
    var sub_base_units_curr_pct_withoutYOY = sub_base_units_curr_withoutYOY / base_units_curr_withoutYOY
    var base_dollars_pct_curr_withoutYOY = base_dollars_curr_withoutYOY / total_dollars_curr_withoutYOY
    var inc_dollars_pct_curr_withoutYOY = incremental_dollars_curr_withoutYOY / total_dollars_curr_withoutYOY
    var nonpromo_dollars_pct_curr_withoutYOY = nonpromoted_dollars_curr_withoutYOY / total_dollars_curr_withoutYOY
    var promo_dollars_pct_curr_withoutYOY = promoted_dollars_curr_withoutYOY / total_dollars_curr_withoutYOY
    var unsub_base_dollars_curr_pct_withoutYOY = unsubbasedollars_curr_withoutYOY / base_dollars_curr_withoutYOY    
    var sub_base_dollars_curr_pct_withoutYOY = subbasedollars_curr_withoutYOY / base_dollars_curr_withoutYOY
    var avg_nonpromo_price_curr_withoutYOY = nonpromoted_dollars_curr_withoutYOY / nonpromo_units_curr_withoutYOY
    var avg_selling_price_curr_withoutYOY = total_dollars_curr_withoutYOY / total_units_curr_withoutYOY
    var lift_curr_dollars_withoutYOY = (incremental_dollars_curr_withoutYOY / base_dollars_curr_withoutYOY)
    var lift_promo_curr_dollars_withoutYOY = (incremental_dollars_curr_withoutYOY / subbasedollars_curr_withoutYOY)
    var eff_curr_dollars_withoutYOY = (incremental_dollars_curr_withoutYOY / promoted_dollars_curr_withoutYOY)

    var metrics = [
        'Total Units'
        , 'Base Units'
        , 'Incremental Units'
        , 'Base Units % of Total'
        , 'Incremental Units % of Total'
        , 'Unsubsidized Base Units'
        , 'Subsidized Base Units'
        , 'Unsubsidized Base Units % of Base'
        , 'Subsidized Base Units % of Base'
        , 'Non-Promoted Units'
        , 'Promoted Units'
        , 'Non-Promoted Units % of Total'
        , 'Promoted Units % of Total'
        , 'Total Dollars'
        , 'Non-Promoted Dollars'
        , 'Promoted Dollars'
        , 'Base Dollars'
        , 'Incremental Dollars'
        , 'Unsubsidized Base Dollars'
        , 'Subsidized Base Dollars'
        , 'Non-Promoted % of Total Dollars'
        , 'Promoted % of Total Dollars'
        , 'Base % of Total Dollars'
        , 'Incremental % of Total Dollars'
        , 'Unsubsidized Base % of Base Dollars'
        , 'Subsidized Base % of Base Dollars'
        , 'Average Base Price'
        , 'Average Selling Price'
        , 'Average Promo Price'
        , 'Average Non-Promo Price'
        , 'Average Discount'
        , 'Average Promoted Discount'
        , 'Discount Dollars'
        , 'Market Lift'
        , 'Promoted Lift'
        , 'Efficiency'
        , 'Market Lift (Dollars)'
        , 'Promoted Lift (Dollars)'
        , 'Efficiency (Dollars)'
        , 'Payback Ratio'
        //, 'Average Base Price in Promoted Discount Ranges'
    ]

    var curr_metrics = [
        total_units_curr_withoutYOY
        , base_units_curr_withoutYOY
        , inc_units_curr_withoutYOY
        , base_unit_pct_curr_withoutYOY
        , inc_unit_pct_curr_withoutYOY
        , unsub_base_units_curr_withoutYOY
        , sub_base_units_curr_withoutYOY
        , unsub_base_units_curr_pct_withoutYOY
        , sub_base_units_curr_pct_withoutYOY
        , nonpromo_units_curr_withoutYOY
        , promo_units_curr_withoutYOY
        , nonpromo_unit_pct_curr_withoutYOY
        , promo_unit_pct_curr_withoutYOY
        , total_dollars_curr_withoutYOY
        , nonpromo_dollars_curr_withoutYOY
        , promo_dollars_curr_withoutYOY
        , base_dollars_curr_withoutYOY
        , inc_dollars_curr_withoutYOY
        , unsub_base_dollars_curr_withoutYOY
        , sub_base_dollars_curr_withoutYOY
        , nonpromo_dollars_pct_curr_withoutYOY
        , promo_dollars_pct_curr_withoutYOY
        , base_dollars_pct_curr_withoutYOY
        , inc_dollars_pct_curr_withoutYOY
        , unsub_base_dollars_curr_pct_withoutYOY
        , sub_base_dollars_curr_pct_withoutYOY
        , avg_base_price_curr_withoutYOY
        , avg_selling_price_curr_withoutYOY
        , avg_promo_price_curr_withoutYOY
        , avg_nonpromo_price_curr_withoutYOY
        , avg_disc_curr_withoutYOY
        , avg_disc_withoutno_curr_withoutYOY
        , disc_dollars_curr_withoutYOY
        , lift_curr_withoutYOY
        , lift_promo_curr_withoutYOY
        , eff_curr_withoutYOY
        , lift_curr_dollars_withoutYOY
        , lift_promo_curr_dollars_withoutYOY
        , eff_curr_dollars_withoutYOY
        , payback_ratio_curr_withoutYOY
    ]

    //Try Creating A new Workbook Here....
    let filenameexcel = name + ' - Extracted - ' + datestring
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Metrics');
    const worksheet = workbook.getWorksheet('Metrics');


    worksheet.getColumn(1).values = metrics
    worksheet.getColumn(2).values = curr_metrics

    //Set Styling
    function setFormat_Number(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = '##,#';
        })

    }

    function setFormat_Percent(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = '0.00%';
        })

    }

    function setFormat_Currency(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = currencysymbol + '#,##0';
        })

    }

    function setFormat_Currency_Price(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = currencysymbol + '#,##.00';
        })

    }

    function setFormat_PPTs(worksheet, cols) {
        cols.forEach(col => {
            let value = worksheet.getCell(col).value
            let newValue = String((value * 100).toFixed(3)) + ' ppts'
            worksheet.getCell(col).value = newValue
        })

    }

    function setFormat_Empty(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).value = null
        })

    }

    function setFormat_Decimal(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = '##,#.00';
        })

    }

    setFormat_Number(worksheet, ['B1', 'C1', 'D1', 'B2', 'C2', 'D2', 'B3', 'C3', 'D3', 'B6', 'C6', 'D6', 'B7', 'C7', 'D7', 'B10', 'C10', 'D10', 'B11', 'C11', 'D11'])
    setFormat_Percent(worksheet,
        ['B4', 'C4', 'B5', 'C5', 'B8', 'C8', 'B9', 'C9', 'B12', 'C12', 'B13', 'C13',
            'B21', 'C21', 'B22', 'C22', 'B23', 'C23', 'B24', 'C24', 'B25', 'C25', 'B26', 'C26',
            'B31', 'C31', 'B32', 'C32', 'B34', 'C34', 'B35', 'C35', 'B36', 'C36', 'B37', 'C37',
            'B38', 'C38', 'B39', 'C39'])
    setFormat_Currency(worksheet, ['B14', 'C14', 'D14',
        'B15', 'C15', 'D15',
        'B16', 'C16', 'D16',
        'B17', 'C17', 'D17',
        'B18', 'C18', 'D18',
        'B19', 'C19', 'D19',
        'B20', 'C20', 'D20',
        'B33', 'C33', 'D33'], this.state.currencysymbol)
    setFormat_Currency_Price(worksheet, ['B27', 'C27', 'D27',
        'B28', 'C28', 'D28',
        ' B29', 'C29', 'D29',
        'B30', 'C30', 'D30',], this.state.currencysymbol)
    //setFormat_PPTs(worksheet, ['D4', 'D5', 'D8', 'D9', 'D12', 'D13', 'D21', 'D22', 'D23', 'D24', 'D25', 'D26', 'D31', 'D32', 'D34', 'D35', 'D36', 'D37', 'D38', 'D39'])
    //setFormat_Empty(worksheet, ['E4', 'E5', 'E8', 'E9', 'E12', 'E13', 'E21', 'E22', 'E23', 'E24', 'E25', 'E26', 'E31', 'E32', 'E34', 'E35', 'E36', 'E37', 'E38', 'E39', 'E40'])
    setFormat_Decimal(worksheet, ['B40', 'C40', 'D40'])

    worksheet.getColumn(5).numFmt = '0.00%'

    //Add Headers

    worksheet.insertRow(1, headers);

    //Set Widths
    function setWidthAndAlignment(worksheet, colnum, widthnum) {
        var Col
        Col = worksheet.getColumn(colnum);
        Col.width = widthnum;
        Col.eachCell(function (cell, rowNumber) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        })
    }

    setWidthAndAlignment(worksheet, 1, 35)
    setWidthAndAlignment(worksheet, 2, 20)

    //Add Selection Information
    const newsheet = workbook.addWorksheet('Selection Criteria');
    const newworksheet = workbook.getWorksheet('Selection Criteria')

    let titles = Object.keys(this.state.chartmetadata)
    let values = Object.values(this.state.chartmetadata)

    var metadatadates
    if (this.state.ThreeYAGO === true) {
        metadatadates = 'Three Years Ago Ranges From: ' + this.state.distinctdates_threeyears[0] + ' to ' + this.state.distinctdates_threeyears[this.state.distinctdates_threeyears.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }
    else if (this.state.TwoYAGO === true) {
        metadatadates = 'Two Years Ago Ranges From: ' + this.state.distinctdates_last[0] + ' to ' + this.state.distinctdates_last[this.state.distinctdates_last.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }
    else {
        metadatadates = 'Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }

    titles.push('Comparison Date Range: ')
    values.push(metadatadates)

    newworksheet.getColumn(1).values = titles
    newworksheet.getColumn(2).values = values

    setWidthAndAlignment(newworksheet, 1, 22)

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, filenameexcel + fileExtension);

}