import React, { Component, Fragment } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export class SelectionInformation extends Component {

    render() {

		function createDate(date, days) {
			if (date) {
				let date_String = String(parseInt(date.substring(0, 4))) + "-" + date.substring(5, 7) + "-" + date.substring(8, 10) + 'T00:00:00'
				let date_Date = new Date(date_String)
				date_Date.setDate(date_Date.getDate() - days);
				return date_Date
			}
			else {
				return new Date()
            }
        }

        //Format Dates
        let threeyearstart = (this.props.state.distinctdates_threeyears || [])[0]
        let threeyearend = (this.props.state.distinctdates_threeyears || [])[(this.props.state.distinctdates_threeyears || []).length - 1]

        let laststart = this.props.state.distinctdates_last[0]
        let lastend = this.props.state.distinctdates_last[this.props.state.distinctdates_last.length - 1]
        let priorstart = this.props.state.prior_start
        let priorend = this.props.state.distinctdates_prior[this.props.state.distinctdates_prior.length - 1]
        let currentstart = this.props.state.distinctdates_forcalc[0]
        let currentend = this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1]
        let numdates = this.props.state.distinctdates_forcalc.length

        if (!this.props.state.showYOYViews) {
            currentstart = this.props.state.distinctdates[0]
            currentend = this.props.state.distinctdates[this.props.state.distinctdates.length - 1]
            numdates = this.props.state.distinctdates.length
        }

        let threeyearstart_string = createDate(threeyearstart, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
        let threeyearend_string = createDate(threeyearend, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })

        let laststart_string = createDate(laststart, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
        let lastend_string = createDate(lastend, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })

        let priorstart_string = createDate(priorstart, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
        let priorend_string = createDate(priorend, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
        
        let currentstart_string = createDate(currentstart, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
        let currentend_string = createDate(currentend, 0).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })        

		return (
			<div className='col-sm-12' style={{ padding: '20px' }} >
				<Accordion style={{ backgroundColor: this.props.back || '#FFFFFF' }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<h4>Selection Information</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div align='left' className="contentHolder col-sm-12" style={{ margin: "0px", maxHeight: '250px', overflowY: 'auto' }}>
						
						{Object.keys(this.props.state.chartmetadata).map((key, idx) => {
							if (key.length > 0 && key !== 'Dates') {
								return <span key={key + '_' + idx}> {key + ': ' + this.props.state.chartmetadata[key]} <br /> </span>
							}
							else {
								return null
							}
						})
						}
						<br />
						<span>{'Number of Weeks in Comparison Period: ' + numdates}<br /></span>
						{this.props.state.showYOYViews ?
							<Fragment>
								{this.props.state.showThreeYearViews ?
									<span> Three Years Ago Ranges From: {threeyearstart_string} to {threeyearend_string}</span>
									:
									<span />
								}
								{this.props.state.showThreeYearViews ?
									<br />
									:
									<span />
								}
								{this.props.state.showLastViews ?
									<span> Two Years Ago Ranges From: {laststart_string} to {lastend_string}</span>
									:
									<span />
								}
								<br />
								<span> Previous Year Ranges From: {priorstart_string} to {priorend_string}</span>
								<br />
								<span> Current &nbsp; Year Ranges From: {currentstart_string} to {currentend_string}</span>
							</Fragment>
							:
							<Fragment>
								<span> Showing Metrics for the time period from: {currentstart_string} to {currentend_string}</span>
								<br />
								<span> Previous Year Comparison cannot be shown as there is not enough data in the dataset to make equal periods.</span>
							</Fragment>
						}
					</div>
				</AccordionDetails>
				</Accordion>
			</div>
                                        
        )
    }
}