import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class MultiBrandSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <div align='left' className="col-sm-12" >
                    <span className="formfieldtitle">Select Brands</span>
                    <Select
                        options={[{ 'value': 'Select All', 'label': 'Select All'}].concat(this.props.state.brands_sorted.map(brand => { return { 'value': brand, 'label': brand } }))}
                        isMulti
                        placeholder={'Select Brands'}
                        onChange={(item, position) => this.props.changeSelectedBrandMulti(item, 0)}                    
                        value={this.props.state.selectedBrands.map(brand => { return { 'label': brand, 'value': brand } })}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}
                    />     
                </div>
                <div align='left' className="col-sm-12" >
                    <span className="formfieldtitle">If More than 10 brands are selected in the filters, this will default to the first 10 brands by sales volume.</span>                
                </div>
            </Fragment>
        )
    }
}