import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as Constant from '../../../constants';

export class ItemASPDistDownload extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle" style={{ color:'#FFFFFF'}}> Download The Data</span>
                {this.props.state.ASPRetailerItem ?
                    <ThemeProvider theme={Constant.muiTheme}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.props.exportItemWeeklyData}
                            className={Constant.classes.button}
                        >
                            Export Weekly Data
	                    </Button>
                    </ThemeProvider>
                    :
                    <div/>
                }
            </Fragment>
        )
    }
}