export default function handleChangeShareMetrics (event) {
    var type = event.currentTarget.value
    if (type === 'Total') {

        this.setState({ sharemetricstotal: true, sharemetricsbase: false, sharemetricsincremental: false, sharemetricspromoted: false, sharemetricsnonpromoted: false, showSnackbar_changebrands: true })
    }
    else if (type === 'Base') {

        this.setState({ sharemetricstotal: false, sharemetricsbase: true, sharemetricsincremental: false, sharemetricspromoted: false, sharemetricsnonpromoted: false, showSnackbar_changebrands: true })
    }
    else if (type === 'Incremental') {

        this.setState({ sharemetricstotal: false, sharemetricsbase: false, sharemetricsincremental: true, sharemetricspromoted: false, sharemetricsnonpromoted: false, showSnackbar_changebrands: true })
    }
    else if (type === 'Promoted') {

        this.setState({ sharemetricstotal: false, sharemetricsbase: false, sharemetricsincremental: false, sharemetricspromoted: true, sharemetricsnonpromoted: false, showSnackbar_changebrands: true })
    }
    else if (type === 'Non-Promoted') {

        this.setState({ sharemetricstotal: false, sharemetricsbase: false, sharemetricsincremental: false, sharemetricspromoted: false, sharemetricsnonpromoted: true, showSnackbar_changebrands: true })
    }
}