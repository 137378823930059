import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Select from 'react-select'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import ShareRoundedIcon from '@material-ui/icons/ShareRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded'
import Modal from '@material-ui/core/Modal'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import '../styles/custom.css'
import * as Constant from '../constants'
import { saveAs } from "file-saver"
const ExcelJS = require('exceljs')

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

export default function SimToolTips(props) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [open, setOpen] = React.useState(false)
    const [errordisplay, seterrordisplay] = useState('none')
    const [availableusers, setAvailableUsers] = useState([])
    const [selectedusers, setSelectedUsers] = useState([])
    const [loadershare, setLoaderShare] = useState(false)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [openDialogShare, setOpenDialogShare] = React.useState(false)
    const [openDialog_deleteError, setOpenDialog_deleteError] = React.useState(false)

    const handleCloseModal = () => {
        setOpen(false)
    }

    const shareSim = () => {
        setLoaderShare(true)

        console.log(availableusers)
        console.log(selectedusers)

        //Make response data equal to an array of the values of the Selected Options
        let userids = selectedusers.map(user => user.value)
        console.log(userids)
        fetch(Constant.baseapiurl + "SimSettings/" + findid() + "/Sharing",
            {
                method: "PUT",
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userids)
            })
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.text()
                        .then(
                            //console.log('ok')
                            () => {
                                console.log('Simulation Shared!')
                                handleOpenDialogShare()
                                setOpen(false)
                                setLoaderShare(false)
                            },
                            (error) => {
                                console.log(error)
                            }
                        )
                } else {
                    console.log('There was an Error Running the API')
                }
            })
    }

    const ChangeUsers = (event) => {
        console.log(event)
        setSelectedUsers(event)
        seterrordisplay('none')
        console.log({ selectedusers })
    }

    const findid = () => {
        var url = window.location.href
        console.log(url)
        var temp = url.split("/")
        console.log(temp)
        var i = unescape(temp[5])
        var idnum = parseInt(i, 10)
        return idnum
    }

    const handleClick_copy = () => {
        window.location.href = Constant.baseurl + 'PNP/copysimulation/' + findid()
    }

    const handleClick_edit = () => {
        window.location.href = Constant.baseurl + 'PNP/EditSimulation/' + findid()
    }

    const handleClick_delete = () => {
        var simid = findid()
        console.log('Running Delete!')
        fetch(Constant.baseapiurl + 'SimSettings/' + simid, { method: 'DELETE' })
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.text()
                        .then(
                            () => {
                                window.location.reload(true)
                                console.log('reloaded')
                                window.location.href = Constant.baseurl + 'PNP/Home'
                            },
                            (error) => {
                                console.log('There Was an Error' + error)
                            }
                        )
                } else if (res.status === 401 || res.status === 404) {
                    setOpenDialog(false)
                    setOpenDialog_deleteError(true)
                } else {
                    console.log('There was an Error Running the API')
                }
            })
    }

    const handleClick_planfromsim = () => {
    }

    const request_users = async () => {
        const response = await fetch(Constant.baseapiurl + "SimSettings/" + findid() + "/SharableWith")
        const json = await response.json()
        let user_options = []
        json.map(company => company.users.map(user => user_options.push({ 'value': user.user_id, 'label': user.user_name })))
        //let new_users = json.map(company => company.users.map(user => { return { 'value': user.user_id, 'label': user.user_name } }))
        setAvailableUsers(user_options)

        const response_shared = await fetch(Constant.baseapiurl + "SimSettings/" + findid() + "/Sharing")
        const json_shared = await response_shared.json()
        let user_options_shared = []
        //Need a map of all users to get emails...
        let user_dict = []
        json.map(company => company.users.map(user => user_dict[user.user_id] = user.user_name))
        json_shared.map(user => user_options_shared.push({ 'value': user.user_id, 'label': user_dict[user.user_id] }))
        //let new_users = json.map(company => company.users.map(user => { return { 'value': user.user_id, 'label': user.user_name } }))
        setSelectedUsers(user_options_shared)
    }

    const handleClick_share = () => {
        setOpen(true)
        request_users()
    }

    const download_criteria_worksheet = async (criteria_worksheet) => {
        var titles = []
        var values = []

        titles.push('Date Range:')
        values.push(props.state.simsettings.sim_start.substr(0, 10) + " to " + props.state.simsettings.sim_end.substr(0, 10))

        titles.push('')
        titles.push('Last Modified:')
        values.push('')
        values.push(props.state.simsettings.time_updated_adj.substring(0, 19))

        titles.push('')
        titles.push('Geography:')
        let geoid = props.state.simsettings.geogid[0]
        let geodesc
        props.state.dataset_info.geos.map(geo_info => {
            if (parseInt(geo_info.geogid) == parseInt(geoid)) {
                geodesc = geo_info.geogdesc_modified
            }
        })
        values.push('')
        values.push(geodesc)

        titles.push('')
        titles.push('Type:')
        values.push('')
        values.push(props.state.simsettings.sim_type.substring(0, 1).toUpperCase() + props.state.simsettings.sim_type.substring(1))

        titles.push('')
        values.push('')
        titles.push('Item Information:')

        criteria_worksheet.getColumn(1).values = titles
        criteria_worksheet.getColumn(2).values = values

        var item_row_num = 11
        var item_headers = ['Item', 'Base', 'Base Price', 'Promoted Price', 'Discount Percent', props.state.simsettings.sim_type === 'base' ? 'Simulated Base Price' : 'Simulated Discount Percent', props.state.simsettings.sim_type === 'base' ? 'Base Price % Change' : 'Simulated Promoted Price']
        criteria_worksheet.insertRow(10, item_headers)

        const currency_obj = { style: 'currency', currency: props.state.currencycode }
        const percent_obj = { style: 'percent', maximumFractionDigits: 2 }

        var item_row = []
        props.state.simsettings.items.map(item => {
            item_row = []
            if (props.state.simsettings.sim_type === 'base') {
                props.state.dataset_info.items.map(item_info => {
                    if (parseInt(item_info.prodid) == item.prod_id) {
                        item_row.push(item_info.proddesc)
                    }
                })

                if (item.override_base_flag === true) {
                    item_row.push(item.override_base_volume.toLocaleString())
                    item_row.push(item.override_base_price.toLocaleString(undefined, currency_obj))
                    item_row.push((item.override_base_price * (1 - (parseFloat(props.state.basecalcresultdis[item.prod_id])))).toLocaleString(undefined, currency_obj))
                    item_row.push((props.state.basecalcresultdis[item.prod_id]).toLocaleString(undefined, percent_obj))
                    item_row.push(item.sim_base_price.toLocaleString(undefined, currency_obj))
                    item_row.push(((item.sim_base_price - item.override_base_price) / item.override_base_price).toLocaleString(undefined, percent_obj))
                } else {
                    item_row.push(Math.round(props.state.basecalcresultvol[item.prod_id]).toLocaleString())
                    item_row.push((props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, currency_obj))
                    item_row.push(((props.state.basecalcresultasp[item.prod_id]) * (1 - ((Math.round(parseFloat(props.state.basecalcresultdis[item.prod_id]) * 100)) / 100))).toLocaleString(undefined, currency_obj))
                    item_row.push((props.state.basecalcresultdis[item.prod_id]).toLocaleString(undefined, percent_obj))
                    item_row.push(item.sim_base_price.toLocaleString(undefined, currency_obj))
                    item_row.push(((item.sim_base_price - props.state.basecalcresultasp[item.prod_id]) / props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, percent_obj))
                }
            }
            else {
                props.state.dataset_info.items.map(item_info => {
                    if (parseInt(item_info.prodid) == item.prod_id) {
                        item_row.push(item_info.proddesc)
                    }
                })

                if (item.override_base_flag === true) {
                    item_row.push(Math.round((item.override_base_volume * props.state.item_equiv_lookup[item.prod_id])).toLocaleString())
                    item_row.push((props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, currency_obj))
                    item_row.push(((props.state.basecalcresultasp[item.prod_id]) * (1 - (parseFloat(item.override_base_discount)))).toLocaleString(undefined, { style: 'currency', currency: props.state.currencycode }))
                    item_row.push((item.override_base_discount).toLocaleString(undefined, percent_obj))
                    item_row.push(item.sim_discount_pct.toLocaleString(undefined, percent_obj))
                    item_row.push(((props.state.basecalcresultasp[item.prod_id]) * (1 - item.sim_discount_pct)).toLocaleString(undefined, currency_obj))
                } else {
                    item_row.push(Math.round(props.state.basecalcresultvol[item.prod_id] * props.state.item_equiv_lookup[item.prod_id]).toLocaleString())
                    item_row.push((props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, currency_obj))
                    item_row.push(((props.state.basecalcresultasp[item.prod_id]) * (1 - ((Math.round(parseFloat(props.state.basecalcresultdis[item.prod_id]) * 100)) / 100))).toLocaleString(undefined, { style: 'currency', currency: props.state.currencycode }))
                    item_row.push((props.state.basecalcresultdis[item.prod_id].toLocaleString(undefined, percent_obj)))
                    item_row.push(item.sim_discount_pct.toLocaleString(undefined, percent_obj))
                    item_row.push(((props.state.basecalcresultasp[item.prod_id]) * (1 - item.sim_discount_pct)).toLocaleString(undefined, currency_obj))
                    
                }
            }

            criteria_worksheet.insertRow(item_row_num++, item_row)
        })
    }

    const download_total_worksheet = async (total_worksheet) => {
    }

    const download_brand_worksheet = async (worksheet) => {
        const reducer = (accumulator, currentValue) => accumulator + currentValue
        var levelstoview = [], headers_base = [], headers_promo = [], levels = [], units_label, currency_label, header1, header2, header3, header4, header5, header6, header7, header8, header9, header10, header11, header12, header13, header14, header15
        console.log('IconBarSimulation props', props.state )
        units_label = props.state.unitsvar
        currency_label = props.state.currencytype
        let is_base = props.state.simsettings.sim_type === 'base'

        header1 = 'Brand Name'
        header2 = 'Base ' + units_label
        header3 = (is_base) ? 'Change in Base ' + units_label : 'Incremental ' + units_label
        header4 = (is_base) ? '% Change in Base ' + units_label : 'Incremental ' + units_label + '(' + units_label + ')'
        header5 = 'Simulated Market Share (' + units_label + ')'
        header6 = 'Difference in Market Share (Points)'
        header7 = (is_base) ? '' : 'Subsidized Base ' + units_label
        header8 = (is_base) ? '' : 'Simulated Total ' + units_label
        header9 = 'Base ' + currency_label
        header10 = (is_base) ? 'Change in Base ' + currency_label : 'Incremental ' + currency_label
        header11 = (is_base) ? '% Change in Base ' + currency_label : 'Incremental ' + currency_label + '(' + currency_label + ')'
        header12 = 'Simulated Market Share (' + currency_label + ')'
        header13 = 'Difference in Market Share (Points)'
        header14 = (is_base) ? '' : 'Subsidized Base ' + currency_label
        header15 = (is_base) ? '' : 'Simulated Total ' + currency_label

        headers_base = [header1, header2, header3, header4, header5, header6, header9, header10, header11, header12, header13]
        headers_promo = [header1, header2, header3, header4, header5, header6, header7, header8, header9, header10, header11, header12, header13, header14, header15]

        var metric_start_col = 2
        levelstoview = (props.state.brands_for_totalcat || Object.keys(props.state.brand_base_vols))
        //console.log('props.state.brands_for_totalcat', props.state.brands_for_totalcat)
        //console.log(' Object.keys(props.state.brand_base_vols)', Object.keys(props.state.brand_base_vols))

        let base = []
        let change = []
        let change_pct = []
        let mktshare = []
        let mktshare_change = []
        let subbase = []
        let promo = []
        let base2 = []
        let change2 = []
        let change_pct2 = []
        let mktshare2 = []
        let mktshare_change2 = []
        let subbase2 = []
        let promo2 = []

        let total_category_volume_old = Object.values(props.state.brand_base_vols).reduce(reducer)
        
        let total_category_changes = Object.values(props.state.brand_inc_vols).reduce(reducer)
        let total_category_compchanges = ([0].concat(Object.values(props.state.comp_item_changes_brand))).reduce(reducer)
        let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges
        let total_category_sub_base = 0
        if (props.state.simsettings.sim_type === 'promo') {
            total_category_sub_base = Object.values(props.state.subsidized_base_volume_brand).reduce(reducer)
        }
        

        let total_category_volume_old2 = Object.values(props.state.brand_base_dols).reduce(reducer)
        let total_category_changes2 = Object.values(props.state.brand_inc_dols).reduce(reducer)
        let total_category_compchanges2 = ([0].concat(Object.values(props.state.comp_item_changes_brand_dollars))).reduce(reducer)
        let total_category_volume_new2 = total_category_volume_old2 + total_category_changes2 + total_category_compchanges2
        let total_category_sub_base2 = 0
        if (props.state.simsettings.sim_type === 'promo') {
            total_category_sub_base2 = Object.values(props.state.subsidized_base_dollars_brand).reduce(reducer)
        }
        levelstoview.forEach(brand => {
            let basevol = props.state.brand_base_vols[brand]
            let changevol = props.state.brand_inc_vols[brand]
            let compchangevol = props.state.comp_item_changes_brand[brand] || 0
            let subbasevol = 0
            if (props.state.simsettings.sim_type === 'promo') {
                subbasevol = props.state.subsidized_base_volume_brand[brand]
            }

            let newshare = 0
            if (total_category_volume_new !== 0) {
                newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                
            }

            let oldshare = 0
            if (total_category_volume_old !== 0) {
                oldshare = basevol / total_category_volume_old
            }

            levels.push(brand)
            base.push(basevol)
            change.push(changevol + compchangevol)
            if (basevol !== 0) {
                change_pct.push(((changevol + compchangevol) / basevol) || 0)
            } else {
                change_pct.push(0)
            }
            mktshare.push(newshare)
            mktshare_change.push((newshare - oldshare) * 100)
            subbase.push(subbasevol)
            promo.push(subbasevol + changevol)


            let basevol2 = props.state.brand_base_dols[brand]
            let changevol2 = props.state.brand_inc_dols[brand]
            let compchangevol2 = props.state.comp_item_changes_brand_dollars[brand] || 0
            
            let subbasevol2 = 0
            if (props.state.simsettings.sim_type === 'promo') {
                subbasevol2 = props.state.subsidized_base_dollars_brand[brand]
            }

            let newshare2 = 0
            if (total_category_volume_new2 !== 0) {
                if (props.state.simsettings.sim_type === 'base') {
                    newshare2 = (basevol2 + changevol2 + compchangevol2) / total_category_volume_new2
                }
                else {
                    newshare2 = (subbasevol2 + changevol2) / (total_category_sub_base2 + total_category_changes2)
                }
            }

            let oldshare2 = 0
            if (total_category_volume_old2 !== 0) {
                oldshare2 = basevol2 / total_category_volume_old2
            }

            base2.push(basevol2)
            change2.push(changevol2 + compchangevol2)
            if (basevol2 !== 0) {
                change_pct2.push(((changevol2 + compchangevol2) / basevol2) || 0)
            } else {
                change_pct2.push(0)
            }
            mktshare2.push(newshare2)
            mktshare_change2.push((newshare2 - oldshare2) * 100)
            subbase2.push(subbasevol2)
            promo2.push(subbasevol2 + changevol2 )
        })
        //Old values before inputing new columns
        //worksheet.getColumn(0 + metric_start_col).values = base
        //worksheet.getColumn(1 + metric_start_col).values = change
        //worksheet.getColumn(2 + metric_start_col).values = change_pct
        //worksheet.getColumn(3 + metric_start_col).values = mktshare
        //worksheet.getColumn(4 + metric_start_col).values = mktshare_change

        //worksheet.getColumn(5 + metric_start_col).values = base2
        //worksheet.getColumn(6 + metric_start_col).values = change2
        //worksheet.getColumn(7 + metric_start_col).values = change_pct2
        //worksheet.getColumn(8 + metric_start_col).values = mktshare2
        //worksheet.getColumn(9 + metric_start_col).values = mktshare_change2

        if (props.state.simsettings.sim_type === 'base') {
            worksheet.getColumn(0 + metric_start_col).values = base
            worksheet.getColumn(1 + metric_start_col).values = change
            worksheet.getColumn(2 + metric_start_col).values = change_pct
            worksheet.getColumn(3 + metric_start_col).values = mktshare
            worksheet.getColumn(4 + metric_start_col).values = mktshare_change

            worksheet.getColumn(5 + metric_start_col).values = base2
            worksheet.getColumn(6 + metric_start_col).values = change2
            worksheet.getColumn(7 + metric_start_col).values = change_pct2
            worksheet.getColumn(8 + metric_start_col).values = mktshare2
            worksheet.getColumn(9 + metric_start_col).values = mktshare_change2
           

            worksheet.getColumn(1).values = levels
            worksheet.insertRow(1, headers_base)

            worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 2).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 3).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
            worksheet.getColumn(metric_start_col + 5).numFmt = props.state.currencysymbol + '#,##'
            worksheet.getColumn(metric_start_col + 6).numFmt = props.state.currencysymbol + '##,#'
            worksheet.getColumn(metric_start_col + 7).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 8).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 9).numFmt = '#.000'
            
        }
        else {
            worksheet.getColumn(0 + metric_start_col).values = base
            worksheet.getColumn(1 + metric_start_col).values = change
            worksheet.getColumn(2 + metric_start_col).values = change_pct
            worksheet.getColumn(3 + metric_start_col).values = mktshare
            worksheet.getColumn(4 + metric_start_col).values = mktshare_change
            worksheet.getColumn(5 + metric_start_col).values = subbase
            worksheet.getColumn(6 + metric_start_col).values = promo

            worksheet.getColumn(7 + metric_start_col).values = base2
            worksheet.getColumn(8 + metric_start_col).values = change2
            worksheet.getColumn(9 + metric_start_col).values = change_pct2
            worksheet.getColumn(10 + metric_start_col).values = mktshare2
            worksheet.getColumn(11 + metric_start_col).values = mktshare_change2
            worksheet.getColumn(12 + metric_start_col).values = subbase2
            worksheet.getColumn(13 + metric_start_col).values = promo2

            worksheet.getColumn(1).values = levels
            worksheet.insertRow(1, headers_promo)

            worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 2).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 3).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
            worksheet.getColumn(metric_start_col + 5).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 6).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 7).numFmt = props.state.currencysymbol + '#,##'
            worksheet.getColumn(metric_start_col + 8).numFmt = props.state.currencysymbol + '##,#'
            worksheet.getColumn(metric_start_col + 9).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 10).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 11).numFmt = '#.000'
            worksheet.getColumn(metric_start_col + 12).numFmt = props.state.currencysymbol + '##,#'
            worksheet.getColumn(metric_start_col + 13).numFmt = props.state.currencysymbol + '#,##'
        }

        //worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
        //worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
        //worksheet.getColumn(metric_start_col + 2).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 3).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
        //worksheet.getColumn(metric_start_col + 5).numFmt = '##,#'
        //worksheet.getColumn(metric_start_col + 6).numFmt = '##,#'
        //worksheet.getColumn(metric_start_col + 7).numFmt = props.state.currencysymbol + '#,##'
        //worksheet.getColumn(metric_start_col + 8).numFmt = props.state.currencysymbol + '##,#'
        //worksheet.getColumn(metric_start_col + 9).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 10).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 11).numFmt = '#.000'
        //worksheet.getColumn(metric_start_col + 12).numFmt = props.state.currencysymbol + '##,#'
        //worksheet.getColumn(metric_start_col + 13).numFmt = props.state.currencysymbol + '#,##'
    }

    const download_item_worksheet = async (worksheet) => {
        const reducer = (accumulator, currentValue) => accumulator + currentValue
        var levelstoview = [], headers_base = [], headers_promo = [], levels = [], units_label, currency_label, header1, header2, header3, header4, header5, header6, header7, header8, header9, header10, header11, header12, header13, header14, header15

        units_label = props.state.unitsvar
        currency_label = props.state.currencytype
        let is_base = props.state.simsettings.sim_type === 'base'
        //old headers
        //header1 = 'Item Name'
        //header2 = 'Base ' + units_label
        //header3 = 'Subsidized Base ' + units_label
        //header4 = 'Simulated Total ' + units_label
        //header5 = (is_base) ? 'Change in Base ' + units_label : 'Incremental ' + units_label
        //header6 = (is_base) ? '% Change in Base ' + units_label : 'Incremental ' + units_label + '(' + units_label + ')'
        //header7 = 'Simulated Market Share (' + units_label + ')'
        //header8 = 'Difference in Market Share (Points)'
        //header9 = 'Base ' + currency_label
        //header10 = 'Subsidized Base ' + currency_label
        //header11 = 'Simulated Total ' + currency_label
        //header12 = (is_base) ? 'Change in Base ' + currency_label : 'Incremental ' + currency_label
        //header13 = (is_base) ? '% Change in Base ' + currency_label : 'Incremental ' + currency_label + '(' + currency_label + ')'
        //header14 = 'Simulated Market Share (' + currency_label + ')'
        //header15 = 'Difference in Market Share (Points)'
        //headers = [header1, header2, header3, header4, header5, header6, header7, header8, header9, header10, header11, header12, header13, header14, header15]


        header1 = 'Item Name'
        header2 = 'Base ' + units_label
        header3 = (is_base) ? 'Change in Base ' + units_label : 'Incremental ' + units_label
        header4 = (is_base) ? '% Change in Base ' + units_label : 'Incremental ' + units_label + '(' + units_label + ')'
        header5 = 'Simulated Market Share (' + units_label + ')'
        header6 = 'Difference in Market Share (Points)'
        header7 = (is_base) ? '' : 'Subsidized Base ' + units_label
        header8 = (is_base) ? '' : 'Simulated Total ' + units_label
        header9 = 'Base ' + currency_label
        header10 = (is_base) ? 'Change in Base ' + currency_label : 'Incremental ' + currency_label
        header11 = (is_base) ? '% Change in Base ' + currency_label : 'Incremental ' + currency_label + '(' + currency_label + ')'
        header12 = 'Simulated Market Share (' + currency_label + ')'
        header13 = 'Difference in Market Share (Points)'
        header14 = (is_base) ? '' : 'Subsidized Base ' + currency_label
        header15 = (is_base) ? '' : 'Simulated Total ' + currency_label

        headers_base = [header1, header2, header3, header4, header5, header6, header9, header10, header11, header12, header13]
        headers_promo = [header1, header2, header3, header4, header5, header6, header7, header8, header9, header10, header11, header12, header13, header14, header15]

        var metric_start_col = 2

        let base = []
        let change = []
        let change_pct = []
        let mktshare = []
        let mktshare_change = []
        let subbase = []
        let promo = []
        let base2 = []
        let change2 = []
        let change_pct2 = []
        let mktshare2 = []
        let mktshare_change2 = []
        let subbase2 = []
        let promo2 = []


        let simulation_results = props.state.simulation_results
        let all_items = simulation_results.item_list

        var simsettings = props.state.simsettings
       
        let sim_items = {}
        const currency_obj = { style: 'currency', currency: props.state.currencycode }
        simsettings.items.forEach(item => {
            sim_items[item.prod_id] = {
                'simdisc': item.sim_discount_pct,
                'simbase': item.sim_base_price,
                'simprice': ((props.state.basecalcresultasp[item.prod_id]) * (1 - item.sim_discount_pct)).toLocaleString(undefined, currency_obj)
            }
         //   console.log('sim_items[item.prod_id]', sim_items[item.prod_id])
        })
        console.log('simsettings', simsettings)
        let simitems = props.state.simsettings.items.map(item => item.prod_id)
        all_items.forEach(item => {
            let result = simulation_results[item]
            //console.log('simulation_results[item]', result)
            var item_total
            // Adjust the total if item in simitems
            if (simitems.includes(parseInt(item))) {
                //console.log('Adjusting Metrics')
                let totalcrosschange = [0].concat(result['cross_items'].map(crossitem => {
                    if (simitems.includes(parseInt(crossitem))) {
                        return result['cross'][crossitem]['cross_total']
                    } else {
                        return 0
                    }
                })).reduce(reducer) - result['total_cross']
               // console.log('***** totalcrosschange value is: ' + totalcrosschange + '; setting totalcrosschange to 0')
                totalcrosschange = 0
                item_total = result['total'] + totalcrosschange

                //console.log(item_total)
            } else {
                item_total = result.total
            }

            result['adjusted_total'] = item_total
        })

        let brand_base_vols = {}
        let brand_inc_vols = {}
        let brand_vol_pct = {}
        let brand_share_pct_old = {}
        let brand_share_pct_new = {}
        let brand_base_dols = {}
        let promo_dollars = {}
        let subdollars = {}
        let brand_inc_dols = {}
        

        all_items.forEach(item => {
            let result = simulation_results[item]
            //console.log('simulation_results[item]',item)
            var item_price = 0
            var old_item_price = 0
            var item_disc_pct = 0
            try {
                let item_brand = result.brand
                //console.log('item_brand', item_brand)
               
                let item_starting_base_volume = result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume
                //console.log('item_starting_base_volume', item_starting_base_volume)
                var item_total = result.adjusted_total
                //console.log('item_total', item_total)

                if (simsettings.sim_type === 'base') {
                    item_price = (sim_items[item] || {}).simbase || result.avgbp || 0
                    old_item_price = props.state.basecalcresultasp[item] || result.avgbp || 0
                    item_disc_pct = 0
                } else {
                    item_price = result.avgbp || 0
                    item_disc_pct = (sim_items[item] || {}).simdisc || 0
                    old_item_price = (item_price) * (1 - (item_disc_pct))
                }
                //console.log('item_price', item_price)
                //console.log('item_disc_pct', item_disc_pct)
                //console.log('old_item_price', old_item_price)

                if (!Object.keys(brand_base_vols).includes(item_brand)) {
                    brand_base_vols[item_brand] = 0
                }
                let item_base_vol = item_starting_base_volume * simsettings.numweeks
                //console.log('item_base_vol', item_base_vol)
                //console.log('item_price', item_price)
                brand_base_vols[item_brand] += item_base_vol



                if (!Object.keys(brand_inc_vols).includes(item_brand)) {
                    brand_inc_vols[item_brand] = 0
                }
                let item_inc_vol = item_starting_base_volume * item_total * simsettings.numweeks * simsettings.coverage
                //console.log('item_starting_base_volume', item_starting_base_volume)
                //console.log('item_total', item_total)
                //console.log('simsettings.numweeks', simsettings.numweeks)
                //console.log('simsettings.coverage', simsettings.coverage)
                //console.log('item_inc_vol', item_inc_vol)
                brand_inc_vols[item_brand] += item_inc_vol
                

                if (!Object.keys(brand_base_dols).includes(item_brand)) {
                    brand_base_dols[item_brand] = 0
                }
                //console.log('brand_base_dols', item_starting_base_volume * simsettings.numweeks * item_price)
                brand_base_dols[item_brand] += (item_starting_base_volume * simsettings.numweeks * item_price)

                if (!Object.keys(brand_inc_dols).includes(item_brand)) {
                    brand_inc_dols[item_brand] = 0
                }

                let item_inc_dols
                if (simsettings.sim_type === 'base') {
                    item_inc_dols = (item_starting_base_volume * (1 + item_total) * simsettings.numweeks * simsettings.coverage * item_price * (1 - item_disc_pct)) - (item_starting_base_volume * simsettings.numweeks * old_item_price)
                } else {
                    item_inc_dols = item_starting_base_volume * item_total * simsettings.numweeks * simsettings.coverage * item_price * (1 - item_disc_pct)
                }
                //console.log('item_inc_dols', item_inc_dols)
                brand_inc_dols[item_brand] += item_inc_dols
              
                //if (!Object.keys(promo_dollars).includes(item_brand)) {
                //    promo_dollars[item_brand] = 0
                //}
                //promo_dollars[item_brand] += ((brand_inc_vols[item_brand] + brand_base_vols[item_brand]) * old_item_price)

                //if (!Object.keys(subdollars).includes(item_brand)) {
                //    subdollars[item_brand] = 0
                //}
                //subdollars[item_brand] += (promo_dollars[item_brand] - brand_inc_dols[item_brand])
                if (!Object.keys(promo_dollars).includes(item_brand)) {
                    promo_dollars[item_brand] = 0
                }

                if (!Object.keys(subdollars).includes(item_brand)) {
                    subdollars[item_brand] = 0
                }
                
                if (simsettings.sim_type === 'promo') {
                    //console.log('promo_dollars', ((item_inc_vol + item_base_vol) * old_item_price))
                    //console.log('subdollars', (((item_inc_vol + item_base_vol) * old_item_price) - item_inc_dols))
                    promo_dollars[item_brand] += ((item_inc_vol + item_base_vol) * old_item_price)
                    subdollars[item_brand] += (((item_inc_vol + item_base_vol) * old_item_price) - item_inc_dols)
                }
                
                    }
                
                catch (error) {
                console.log(error)
                }

        })

        //console.log(brand_base_vols)
        let total_category_basedollars = Object.values(props.state.brand_base_dols).reduce(reducer)
        //var total_category_basedollars = Object.values(brand_base_dols).reduce(reducer)
        //console.log('total_category_basedollars', total_category_basedollars)
        
        var total_category_incdollars = Object.values(brand_inc_dols).reduce(reducer)
        //console.log('total_category_incdollars', total_category_incdollars)

        let total_category_subdollars = 0
        if (props.state.simsettings.sim_type === 'promo') {
            total_category_subdollars = Object.values(subdollars).reduce(reducer)
        }

        //var total_category_subdollars = Object.values(subdollars).reduce(reducer)
        //console.log('total_category_subdollars', total_category_subdollars)

        var total_category_promo_doll = Object.values(promo_dollars).reduce(reducer)
        //console.log('total category promo doll', total_category_promo_doll)

        var total_category_volume_old = Object.values(brand_base_vols).reduce(reducer)
       // console.log('total_category_volume_old', total_category_volume_old)

        let total_category_changes = Object.values(props.state.brand_inc_vols).reduce(reducer)
        //console.log('total_category_changes', total_category_changes)

        var total_category_incremental_vol = Object.values(brand_inc_vols).reduce(reducer)
        //console.log('total_category_incremental_vol', total_category_incremental_vol)

        var total_category_compchanges_dol = ([0].concat(Object.values(props.state.comp_item_changes_item_dollars))).reduce(reducer)
        //console.log('total_category_compchanges_dol', total_category_compchanges_dol)
       
        var total_category_volume_new = Object.values(brand_base_vols).reduce(reducer) + Object.values(brand_inc_vols).reduce(reducer)

        var total_category_volume_old_dol = Object.values(brand_base_dols).reduce(reducer)
        //console.log('total_category_volume_old_dol', total_category_volume_old_dol)

        //var total_category_volume_new_dol = Object.values(brand_base_dols).reduce(reducer) + Object.values(brand_inc_dols).reduce(reducer)
        var total_category_volume_new_dol = total_category_basedollars + total_category_incdollars
        //console.log('total_category_volume_new_dol', total_category_volume_new_dol)

        Object.keys(brand_base_vols).map(brand => brand_vol_pct[brand] = (brand_inc_vols[brand] / (brand_base_vols[brand] - brand_inc_vols[brand])) * 100)
        Object.keys(brand_base_vols).map(brand => brand_share_pct_new[brand] = ((brand_inc_vols[brand] + brand_base_vols[brand]) / total_category_volume_new) * 100)//total_category_volume_old) * 100)
        Object.keys(brand_base_vols).map(brand => brand_share_pct_old[brand] = (brand_base_vols[brand] / total_category_volume_old) * 100)

        let brand_items_basevols = []
        let brand_items_incvols = []
        let brand_items_vol_pct = []
        let brand_items_share_new = []
        let brand_items_share_old = []

        let brand_items_basedols = []
        let brand_items_incdols = []
        let brand_items_dol_pct = []
        let brand_items_share_new_dol = []
        let brand_items_share_old_dol = []

        let brand_items_names = []
        let brand_items_nums = []
        let brand_items_price = []

        let brand_items_all_metrics = {}
        all_items.forEach(item => {
            let result = simulation_results[item]

            // Push base vols
            let item_starting_base_volume = (result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume) || 0
            brand_items_basevols.push((item_starting_base_volume * simsettings.numweeks))

            // Push Inc Vols
            brand_items_incvols.push(item_starting_base_volume * result.adjusted_total * simsettings.numweeks * simsettings.coverage)

            // Push Total Promo Dollars
            //brand_items_total_sim_dols.push(promo_dollars)

            // Push Vols % Change
            brand_items_vol_pct.push((result.adjusted_total * 100 * simsettings.coverage))

            // Old Share
            brand_items_share_old.push((((item_starting_base_volume * simsettings.numweeks) + (item_starting_base_volume * result.adjusted_total * simsettings.numweeks)) / total_category_volume_old) * 100)

            // New Share
            brand_items_share_new.push((((item_starting_base_volume * simsettings.numweeks) + (item_starting_base_volume * result.adjusted_total * simsettings.numweeks * simsettings.coverage)) / total_category_volume_new) * 100)

            // Push Item Names
            brand_items_names.push(props.state.item_dict_lookup[parseInt(item)] || result.brand + ' All Other')
            brand_items_nums.push(parseInt(item))

            // Create a lookup for items....
            let numweeks = simsettings.numweeks
            let coverage = simsettings.coverage
            var item_total = result.adjusted_total


            var item_price = 0
            var old_item_price = 0
            var item_disc_pct = 0
            var item_sim_price = 0
            if (simsettings.sim_type === 'base') {
                item_price = (sim_items[item] || {}).simbase || result.avgbp || 0
                old_item_price = props.state.basecalcresultasp[item] || result.avgbp || 0
                item_disc_pct = 0
            } else {
                item_price = result.avgbp || 0
                old_item_price = result.avgbp || 0
                item_disc_pct = (sim_items[item] || {}).simdisc || 0
               
            }

            var basedollars = ((item_starting_base_volume * numweeks * old_item_price))
            var incdollars = 0
            var pctchg = 0
            if (simsettings.sim_type === 'base') {
                incdollars = (((item_starting_base_volume * (1 + item_total) * numweeks * coverage) * item_price * (1 - item_disc_pct)) - (((item_starting_base_volume * numweeks * old_item_price))))
            } else {
                incdollars = (item_starting_base_volume * item_total * numweeks * coverage * item_price * (1 - item_disc_pct))
            }

            if (simsettings.sim_type === 'base') {
                pctchg = (incdollars / basedollars) * 100
            } else {
                pctchg = (incdollars / basedollars) * 100
            }

            brand_items_all_metrics[item] = {}

            // Add Name
            brand_items_all_metrics[item]['proddesc'] = props.state.item_dict_lookup[item] || result.brand + ' All Other'

            // Add Measures
            brand_items_all_metrics[item]['basevol'] = item_starting_base_volume * numweeks
            brand_items_all_metrics[item]['incvol'] = item_starting_base_volume * result.adjusted_total * numweeks * coverage
            brand_items_all_metrics[item]['volpct'] = result.adjusted_total * 100 * coverage
            brand_items_all_metrics[item]['oldshare'] = ((((item_starting_base_volume * numweeks)) / total_category_volume_old) * 100)
            brand_items_all_metrics[item]['newshare'] = ((((item_starting_base_volume * numweeks) + (item_starting_base_volume * result.adjusted_total * numweeks * coverage)) / total_category_volume_new) * 100)

            //Dollars
            brand_items_all_metrics[item]['basedollars'] = basedollars
            brand_items_all_metrics[item]['incdollars'] = incdollars
            brand_items_all_metrics[item]['volpct_dollars'] = pctchg
            brand_items_all_metrics[item]['oldshare_dollars'] = basedollars / total_category_volume_old_dol
            brand_items_all_metrics[item]['newshare_dollars'] = (basedollars + incdollars) / total_category_volume_new_dol

            // Push dollars
            let equiv = 1 // item_equiv_lookup[item] || 1 //Removed Based on Feedback
            item_starting_base_volume = item_starting_base_volume * equiv
            var item_total = result.adjusted_total

            var item_price = 0
            var old_item_price = 0
            var item_disc_pct = 0
            if (simsettings.sim_type === 'base') {
                item_price = (sim_items[item] || {}).simbase || result.avgbp || 0
                old_item_price = props.state.basecalcresultasp[item] || result.avgbp || 0
                item_disc_pct = 0
            } else {
                item_price = result.avgbp || 0
                old_item_price = result.avgbp || 0
                item_disc_pct = (sim_items[item] || {}).simdisc || 0
            }

            // push base dollars
            var basedollars = ((item_starting_base_volume * simsettings.numweeks * old_item_price))
            var incdollars = 0
            var pctchg = 0
            if (simsettings.sim_type === 'base') {
                incdollars = (((item_starting_base_volume * (1 + item_total) * simsettings.numweeks * simsettings.coverage) * item_price * (1 - item_disc_pct)) - (((item_starting_base_volume * simsettings.numweeks * old_item_price))))
            } else {
                incdollars = (item_starting_base_volume * item_total * simsettings.numweeks * simsettings.coverage * item_price * (1 - item_disc_pct))
            }
            // Base dollars
            brand_items_basedols.push(basedollars)
            // Inc dollars
            brand_items_incdols.push(incdollars)
            // Percent change...
            if (simsettings.sim_type === 'base') {
                pctchg = (incdollars / basedollars) * 100
            } else {
                pctchg = (incdollars / basedollars) * 100
            }

            brand_items_dol_pct.push(pctchg || 0)
            // Old Share
            brand_items_share_old_dol.push(basedollars / total_category_volume_old_dol)
            // New Share
            brand_items_share_new_dol.push((basedollars + incdollars) / total_category_volume_new_dol)
            // Average Item Price
            brand_items_price.push(item_price)
        })

        levelstoview = (props.state.items_for_totalcat || brand_items_nums)
        levelstoview.forEach(item => {
            let metrics = brand_items_all_metrics[item]
            let compchanges = props.state.comp_item_changes_item[parseInt(item)] || 0
            //console.log('***** compchanges value is: ' +  compchanges + '; Setting compchanges to 0')
            compchanges = 0
            let itemname = metrics['proddesc']
            let incvol = metrics['incvol'] + compchanges
            let basevol = metrics['basevol']
            let subbasevol = 0
            if (props.state.simsettings.sim_type === 'promo') {
                subbasevol = props.state.subsidized_base_volume_item[item]
            }


            let volpct = 0
            if (basevol !== 0) {
                volpct = incvol / basevol
            }
            let newshare = 0
            if (total_category_volume_new !== 0) {
                newshare = (basevol + incvol + compchanges) / total_category_volume_new
            }

            //let newshare = metrics['newshare'] / 100

            let oldshare = 0
            if (total_category_volume_old !== 0) {
                oldshare = basevol / total_category_volume_old
            }
            
            //let oldshare = metrics['oldshare'] / 100
            
            let sharechange = metrics['newshare'] - metrics['oldshare']

            levels.push(itemname)

            base.push(basevol)
            change.push(incvol)
            change_pct.push(volpct)
            mktshare.push(newshare)
            mktshare_change.push((newshare - oldshare) * 100)
            //mktshare_change.push(sharechange)
            subbase.push(subbasevol)
            promo.push(subbasevol + incvol)

            let compchanges2 = props.state.comp_item_changes_item_dollars[parseInt(item)] || 0
            //console.log('***** compchanges2 value is: ' + compchanges2 + '; Setting compchanges2 to 0')
            compchanges2 = 0
            let itemname2 = metrics['proddesc']
            let incdollars2 = metrics['incdollars'] + compchanges2
            let basedollars2 = metrics['basedollars']

            let subbasedol2 = 0
            if (props.state.simsettings.sim_type === 'promo') {
                subbasedol2 = props.state.subsidized_base_dollars_item[item]
            }


            let volpct_dollars2 = 0
            if (basedollars2 !== 0) {
                volpct_dollars2 = incdollars2 / basedollars2
            }

            let newshare2 = 0
            if (total_category_volume_new_dol !== 0) {
                if (props.state.simsettings.sim_type === 'base') {
                    newshare2 = (basedollars2 + incdollars2 + compchanges2) / (total_category_basedollars + total_category_incdollars)
                }
                else {
                    newshare2 = (subbasedol2 + incdollars2) / (total_category_subdollars + total_category_incdollars)
                }
            }
            
            //let newshare2 = metrics['newshare_dollars']
            //let oldshare2 = metrics['oldshare_dollars']

            let oldshare2 = 0
            if (total_category_volume_old_dol !== 0) {
                oldshare2 = basedollars2 / total_category_volume_old_dol
            }

            let sharechange2 = (newshare2 - oldshare2) * 100

            //levels.push(itemname2)

            base2.push(basedollars2)
            change2.push(incdollars2 + compchanges2)
            if (basedollars2 !== 0) {
                change_pct2.push(((incdollars2 + compchanges2) / basedollars2) || 0)
            }
            else {
                change_pct2.push(0)
            }
            mktshare2.push(newshare2)
            //mktshare_change2.push(sharechange2)
            mktshare_change2.push((newshare2 - oldshare2) * 100)
            subbase2.push(subbasedol2)
            promo2.push(subbasedol2 + incdollars2)

        })
        //old ones
        //worksheet.getColumn(0 + metric_start_col).values = base
        //worksheet.getColumn(1 + metric_start_col).values = change
        //worksheet.getColumn(2 + metric_start_col).values = change_pct
        //worksheet.getColumn(3 + metric_start_col).values = mktshare
        //worksheet.getColumn(4 + metric_start_col).values = mktshare_change

        //worksheet.getColumn(5 + metric_start_col).values = base2
        //worksheet.getColumn(6 + metric_start_col).values = change2
        //worksheet.getColumn(7 + metric_start_col).values = change_pct2
        //worksheet.getColumn(8 + metric_start_col).values = mktshare2
        //worksheet.getColumn(9 + metric_start_col).values = mktshare_change2

        //worksheet.getColumn(1).values = levels
        //worksheet.insertRow(1, headers)

        //worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
        //worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
        //worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
        //worksheet.getColumn(metric_start_col + 2).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 3).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
        //worksheet.getColumn(metric_start_col + 5).numFmt = props.state.currencysymbol + '#,##'
        //worksheet.getColumn(metric_start_col + 6).numFmt = props.state.currencysymbol + '##,#'
        //worksheet.getColumn(metric_start_col + 7).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 8).numFmt = '0%'
        //worksheet.getColumn(metric_start_col + 9).numFmt = '#.000'

        if (props.state.simsettings.sim_type === 'base') {
            worksheet.getColumn(0 + metric_start_col).values = base
            worksheet.getColumn(1 + metric_start_col).values = change
            worksheet.getColumn(2 + metric_start_col).values = change_pct
            worksheet.getColumn(3 + metric_start_col).values = mktshare
            worksheet.getColumn(4 + metric_start_col).values = mktshare_change

            worksheet.getColumn(5 + metric_start_col).values = base2
            worksheet.getColumn(6 + metric_start_col).values = change2
            worksheet.getColumn(7 + metric_start_col).values = change_pct2
            worksheet.getColumn(8 + metric_start_col).values = mktshare2
            worksheet.getColumn(9 + metric_start_col).values = mktshare_change2


            worksheet.getColumn(1).values = levels
            worksheet.insertRow(1, headers_base)

            worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 2).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 3).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
            worksheet.getColumn(metric_start_col + 5).numFmt = props.state.currencysymbol + '#,##'
            worksheet.getColumn(metric_start_col + 6).numFmt = props.state.currencysymbol + '##,#'
            worksheet.getColumn(metric_start_col + 7).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 8).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 9).numFmt = '#.000'

        }
        else {
            worksheet.getColumn(0 + metric_start_col).values = base
            worksheet.getColumn(1 + metric_start_col).values = change
            worksheet.getColumn(2 + metric_start_col).values = change_pct
            worksheet.getColumn(3 + metric_start_col).values = mktshare
            worksheet.getColumn(4 + metric_start_col).values = mktshare_change
            worksheet.getColumn(5 + metric_start_col).values = subbase
            worksheet.getColumn(6 + metric_start_col).values = promo

            worksheet.getColumn(7 + metric_start_col).values = base2
            worksheet.getColumn(8 + metric_start_col).values = change2
            worksheet.getColumn(9 + metric_start_col).values = change_pct2
            worksheet.getColumn(10 + metric_start_col).values = mktshare2
            worksheet.getColumn(11 + metric_start_col).values = mktshare_change2
            worksheet.getColumn(12 + metric_start_col).values = subbase2
            worksheet.getColumn(13 + metric_start_col).values = promo2

            worksheet.getColumn(1).values = levels
            worksheet.insertRow(1, headers_promo)

            worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 2).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 3).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
            worksheet.getColumn(metric_start_col + 5).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 6).numFmt = '##,#'
            worksheet.getColumn(metric_start_col + 7).numFmt = props.state.currencysymbol + '#,##'
            worksheet.getColumn(metric_start_col + 8).numFmt = props.state.currencysymbol + '##,#'
            worksheet.getColumn(metric_start_col + 9).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 10).numFmt = '0%'
            worksheet.getColumn(metric_start_col + 11).numFmt = '#.000'
            worksheet.getColumn(metric_start_col + 12).numFmt = props.state.currencysymbol + '##,#'
            worksheet.getColumn(metric_start_col + 13).numFmt = props.state.currencysymbol + '#,##'
        }


    }

    const handleClick_download = () => {
        const workbook = new ExcelJS.Workbook()

        var simid = findid()
        console.log('downloadData: simid = ' + simid)

        var name = 'Simulation_' + simid
        var newdate = new Date()
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
        var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
        datestring = datestring.replaceAll(',', '')
        let filenameexcel = name // + '_' + datestring

        //Simulation Criteria Tab
        let criteria_worksheet = workbook.addWorksheet('Simulation Criteria')
        download_criteria_worksheet(criteria_worksheet)

        //Brand View Tab
        let brand_worksheet = workbook.addWorksheet('Brands')
        download_brand_worksheet(brand_worksheet)

        //Item View Tab
        let item_worksheet = workbook.addWorksheet('Items')
        download_item_worksheet(item_worksheet)

        workbook.xlsx
            .writeBuffer()
            .then((buffer) =>
                saveAs(new Blob([buffer]), filenameexcel + '.xlsx')
            )
            .catch((err) => console.log("Error writing excel export for simulation " + simid, err))
    }

    const handleClick_generatePDF = () => {
        var reportid = findid()
        console.log(reportid)
        window.location.href = Constant.baseurl + 'PNP/GeneratePDF/simulation/' + reportid
    }

    const handleOpenDialog = () => {
        console.log('Click!')
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCloseDialog_deleteError = () => {
        setOpenDialog_deleteError(false)
    }

    const handleOpenDialogShare = () => {
        setOpenDialogShare(true)
    }

    const handleCloseDialogShare = () => {
        setOpenDialogShare(false)
    }

    return (
        <div style={{ alignContent: 'right', padding: '0px', margin: '0px', position: 'absolute', top: '0px', right: '0vw', marginRight: '80px', zIndex: '2' }}>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete this Simulation?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete this simulation. This action is final and can not be undone.  Continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialog} className="btn btn-secondarynpd">
                        Cancel
                    </button>
                    <button onClick={handleClick_delete} className="btn btn-primary" autoFocus>
                        Delete Simulation
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogShare}
                onClose={handleCloseDialogShare}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Simulation Shared"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Simulation has been Successfully Shared.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialogShare} className="btn btn-secondarynpd">
                        Ok
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialog_deleteError}
                onClose={handleCloseDialog_deleteError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Insufficient Privileges"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have insufficient privileges to delete this simulation
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialog_deleteError} className="btn btn-primary" autoFocus>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>

            <Tooltip title="Copy Sim">
                <div className="dotsmall">
                    <IconButton aria-label="copy" onClick={handleClick_copy}>
                        <FileCopyRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Edit Sim">
                <div className="dotsmall">
                    <IconButton aria-label="edit" onClick={handleClick_edit}>
                        <EditRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Create Plan From Sim">
                <div className="dotsmall">
                    <IconButton aria-label="plan" onClick={handleClick_planfromsim}>
                        <EventNoteRoundedIcon style={{ color: '#8E908F' }} />
                        <AddRoundedIcon style={{ color: '#8E908F', top: '25px', left: '25px', position: 'absolute', fontSize: '20px' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Share Sim">
                <div className="dotsmall">
                    <IconButton aria-label="share" onClick={handleClick_share}>
                        <ShareRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Download Data">
                <div className="dotsmall">
                    <IconButton aria-label="download" onClick={handleClick_download}>
                        <GetAppRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Generate PDF">
                <div className="dotsmall">
                    <IconButton aria-label="pdf" onClick={handleClick_generatePDF}>
                        <PictureAsPdfRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Delete Sim">
                <div className="dotsmall">
                    <IconButton aria-label="delete" onClick={handleOpenDialog}>
                        <DeleteRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleCloseModal}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Share This Simulation</h2>
                    <p id="simple-modal-description">
                        Select who you would like to share this Simulation with:
                    </p>
                    <div >
                        <Select
                            options={availableusers}
                            value={selectedusers}
                            placeholder={'Select Users'}
                            isMulti={true}
                            onChange={ChangeUsers}
                        >
                        </Select>
                        <ErrorRoundedIcon style={{ color: '#822433', display: errordisplay }} /><span style={{ color: '#822433', display: errordisplay }}>Please Provide at Least one User</span>
                    </div>
                    <button
                        onClick={shareSim}
                        className="btn btn-primary"
                        style={{ margin: '10px' }}
                    >
                        Share This Simulation
                    </button>
                    {loadershare ? <div class="custom-loader"></div>/*<CircularProgress color="inherit" />*/ : <span />}
                </div>
            </Modal>
        </div>
    )
}
