import React, { Component } from 'react';
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { BaseIncPromoNonPromoRadio } from '../../UIElements/Radios/BaseIncPromoNonPromoRadio'
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import BaseIncOrPromoNonPromoByWeek from '../../../Graphs/BaseIncOrPromoNonPromoByWeek'
import {reducer} from '../../Functions/Utilities/Reducer'

export class BaseIncrementalTrended extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        console.log('Base Inc Chart Rendered')
        var chart_title_info_var = this.props.state.chart_title_info
        var attr_caption_text_var = this.props.state.attr_caption_text
        var chartmetadata_singlebrand_var = this.props.state.chartmetadata 

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div className="row" >
                    <div className="col-sm-9 row" style={{ marginLeft: '20px' }}>
                        <BaseIncPromoNonPromoRadio
                            state={this.props.state}
                            handleChangeRadioBasePromo={this.props.handleChangeRadioBasePromo}
                            handleToggle_stackingType={this.props.handleToggle_stackingType}
                        />
                    </div>
                    <div className="col-sm-3 row" align='right'>
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%"}}>                        
                        {this.props.state.baseselected ?
                            <div>
                                {this.props.state.indexSwitchCheckDollars ?
                                    <BaseIncOrPromoNonPromoByWeek
                                        chart_title_info={
                                            chart_title_info_var
                                        }
                                        attr_caption_text={attr_caption_text_var}
                                        unitsvar={'Dollars'}
                                        series1data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['unsubsidizedbasedollars']])}
                                        series2data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['incrementaldollars']])}
                                        series3data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['subsidizedbasedollars']])}
                                        //Sending Weighted Base Weeks and QuadBaseWeeks...
                                        series4data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), (((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['subsidizedbasedollars']) / ((((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['subsidizedbasedollars']) + (((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['unsubsidizedbasedollars']))])}

                                        series1Name="UnSubsidized Base "
                                        series2Name="Incremental "
                                        series3Name="Subsidized Base "
                                        series4Name="% Base Support "

                                        series3Color="#7d328a"
                                        series3Color="#fdd900"
                                        series3Color="#bc81ec"
                                        series4Color="#f3508e"

                                        series1tablevalue='unsubsidizedbasedollars'
                                        series2tablevalue='incrementaldollars'
                                        series3tablevalue='subsidizedbasedollars'
                                        series4tablevalue='basesupport'

                                        aspweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totaldollars'] / ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totalunits']])}
                                        avgbaseweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.props.state.distinctdates.sort().map(date => ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['basedollars']).reduce(reducer) / this.props.state.distinctdates.length])}
                                        distinctdates={this.props.state.distinctdates}
                                        weekly_data={this.props.state.weekly_data}
                                        selectedBrand={this.props.state.selectedBrand}
                                        showDataTable={this.props.state.showDataTable}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        type='Base'
                                        weekagg={this.props.state.filter_object.numweeks}
                                        aggperiod={this.props.state.datasettimeagg}
                                        currencycode={this.props.state.currencycode}
                                        currencytype={this.props.state.currencyType}
                                        metadata={chartmetadata_singlebrand_var}
                                        stackingType={this.props.state.stackingType}
                                    />
                                    :
                                    <BaseIncOrPromoNonPromoByWeek
                                        chart_title_info={
                                            chart_title_info_var
                                        }
                                        attr_caption_text={attr_caption_text_var}
                                        unitsvar={this.props.state.unitsvar}
                                        series1data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['unsubsidizedbaseunits']])}
                                        series2data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['incrementalunits']])}
                                        series3data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['subsidizedbaseunits']])}
                                        //Sending Weighted Base Weeks and QuadBaseWeeks...
                                        series4data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), (((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['subsidizedbaseunits']) / ((((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['subsidizedbaseunits']) + (((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['unsubsidizedbaseunits']))])}

                                        series1Name="UnSubsidized Base "
                                        series2Name="Incremental "
                                        series3Name="Subsidized Base "
                                        series4Name="% Base Support "

                                        series3Color="#7d328a"
                                        series3Color="#fdd900"
                                        series3Color="#bc81ec"
                                        series4Color="#f3508e"

                                        series1tablevalue='unsubsidizedbaseunits'
                                        series2tablevalue='incrementalunits'
                                        series3tablevalue='subsidizedbaseunits'
                                        series4tablevalue='basesupport'

                                        aspweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totaldollars'] / ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totalunits']])}
                                        avgbaseweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.props.state.distinctdates.sort().map(date => ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['baseunits']).reduce(reducer) / this.props.state.distinctdates.length])}
                                        distinctdates={this.props.state.distinctdates}
                                        weekly_data={this.props.state.weekly_data}
                                        selectedBrand={this.props.state.selectedBrand}
                                        showDataTable={this.props.state.showDataTable}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        type='Base'
                                        weekagg={this.props.state.filter_object.numweeks}
                                        aggperiod={this.props.state.datasettimeagg}
                                        currencycode={this.props.state.currencycode}
                                        currencytype={this.props.state.currencyType}
                                        metadata={chartmetadata_singlebrand_var}
                                        stackingType={this.props.state.stackingType}
                                    />
                                }
                            </div>
                            :
                            <div>
                                {this.props.state.indexSwitchCheckDollars ?
                                    <BaseIncOrPromoNonPromoByWeek
                                        chart_title_info={
                                            chart_title_info_var
                                        }
                                        unitsvar={'Dollars'}
                                        series1data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['nonpromoteddollars']])}
                                        series2data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['promoteddollars']])}
                                        series3data={[]}
                                        series3Name=""
                                        series3Color="#FFFFFF"
                                        aspweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totaldollars'] / ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totalunits']])}
                                        avgbaseweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.props.state.distinctdates.sort().map(date => ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['basedollars']).reduce(reducer) / this.props.state.distinctdates.length])}
                                        distinctdates={this.props.state.distinctdates}
                                        weekly_data={this.props.state.weekly_data}
                                        selectedBrand={this.props.state.selectedBrand}
                                        showDataTable={this.props.state.showDataTable}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        attr_caption_text={attr_caption_text_var}
                                        type='Promo'
                                        weekagg={this.props.state.filter_object.numweeks}
                                        aggperiod={this.props.state.datasettimeagg}
                                        currencycode={this.props.state.currencycode}
                                        currencytype={this.props.state.currencyType}
                                        metadata={chartmetadata_singlebrand_var}
                                        stackingType={this.props.state.stackingType}
                                    />
                                    :
                                    <BaseIncOrPromoNonPromoByWeek
                                        chart_title_info={
                                            chart_title_info_var
                                        }
                                        unitsvar={this.props.state.unitsvar}
                                        series1data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['nonpromotedunits']])}
                                        series2data={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['promotedunits']])}
                                        series3data={[]}
                                        series3Name=""
                                        series3Color="#FFFFFF"
                                        aspweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totaldollars'] / ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['totalunits']])}
                                        avgbaseweeklydata={(this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.props.state.distinctdates.sort().map(date => ((this.props.state.weekly_data[this.props.state.selectedBrand] || {})[date] || {})['baseunits']).reduce(reducer) / this.props.state.distinctdates.length])}
                                        distinctdates={this.props.state.distinctdates}
                                        weekly_data={this.props.state.weekly_data}
                                        selectedBrand={this.props.state.selectedBrand}
                                        showDataTable={this.props.state.showDataTable}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        attr_caption_text={attr_caption_text_var}
                                        type='Promo'
                                        weekagg={this.props.state.filter_object.numweeks}
                                        aggperiod={this.props.state.datasettimeagg}
                                        currencycode={this.props.state.currencycode}
                                        currencytype={this.props.state.currencyType}
                                        metadata={chartmetadata_singlebrand_var}
                                        stackingType={this.props.state.stackingType}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}