import React, { Fragment, Component } from 'react';
import WeeklyViewMultipleSeries from './WeeklyViewMultipleSeries'
import * as Constant from '../constants';


export class ASPBasePriceByWeekGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {

        var aggtype = this.props.state.datasettimeagg
        var currencycode = this.props.state.currencycode

        return (
            <Fragment>
                {this.props.state.channelviews ?
                    <div id="" className="row" style={{ width: "100%", height: "100%" }}>
                        <div id="basepricebyweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                            <WeeklyViewMultipleSeries
                                chart_title={"Base Price by " + this.props.state.xAxisText}
                                chart_title_info={this.props.state.chart_title_info_nochannels}
                                unitsvar={this.props.state.unitsvar}
                                seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                    return {
                                        name: geo,
                                        type: 'spline',
                                        color: Constant.graph_colors[idx],
                                        tooltip: {
                                            pointFormatter: function () {
                                                let dt = new Date(this.x)
                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                let date = new Date(this.x + offset)
                                                if (aggtype === 'Monthly') {
                                                    return '<span>Month: '
                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                        + '<br>'
                                                        + this.series.name
                                                        + '<br> '
                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                        + '</span>'
                                                }
                                                else {
                                                    return '<span>Week Ending: '
                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                        + '<br>'
                                                        + this.series.name
                                                        + '<br> '
                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                        + '</span>'
                                                }
                                            }
                                        },
                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                        (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['weightedbp'] / ((this.props.state.weekly_data_geo[geo] || {})[date] || {})['totalunits'])
                                        ]),
                                        zIndex: 1
                                    }
                                })}
                                distinctdates={this.props.state.distinctdates}
                                weekly_data={this.props.state.weekly_data_geo}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                weekagg={this.props.state.filter_object.numweeks}
                                height={2}
                                attr_caption_text={this.props.state.attr_caption_text}
                                yAxisTitle='Base Price'
                                yAxisType='currency'
                                selectedBrands={this.props.state.selectedChannels}
                                aggperiod={this.props.state.datasettimeagg}
                                numTicks={5}
                                yMin={0}
                                yMax={this.props.state.yMax}
                                currencycode={this.props.state.currencycode}
                                currencytype={this.props.state.currencyType}
                                metadata={this.props.state.chartmetadata}
                            />
                        </div>
                        <div id="averagesellingpricebyweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                            <WeeklyViewMultipleSeries
                                chart_title={"Average Selling Price by " + this.props.state.xAxisText}
                                chart_title_info={this.props.state.chart_title_info_nochannels}
                                unitsvar={this.props.state.unitsvar}
                                seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                    return {
                                        name: geo,
                                        type: 'spline',
                                        color: Constant.graph_colors[idx],
                                        tooltip: {
                                            pointFormatter: function () {
                                                let dt = new Date(this.x)
                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                let date = new Date(this.x + offset)
                                                if (aggtype === 'Monthly') {
                                                    return '<span>Month: '
                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                        + '<br>'
                                                        + this.series.name
                                                        + '<br> '
                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                        + '</span>'
                                                }
                                                else {
                                                    return '<span>Week Ending: '
                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                        + '<br>'
                                                        + this.series.name
                                                        + '<br> '
                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                        + '</span>'
                                                }
                                            }
                                        },
                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                        (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['weightedasp'] / ((this.props.state.weekly_data_geo[geo] || {})[date] || {})['totalunits'])
                                        ]),
                                        zIndex: 1
                                    }
                                })}
                                distinctdates={this.props.state.distinctdates}
                                weekly_data={this.props.state.weekly_data_geo}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                weekagg={this.props.state.filter_object.numweeks}
                                height={2}
                                attr_caption_text={this.props.state.attr_caption_text}
                                yAxisTitle='Average Selling Price'
                                yAxisType='currency'
                                selectedBrands={this.props.state.selectedChannels}
                                aggperiod={this.props.state.datasettimeagg}
                                numTicks={5}
                                yMin={0}
                                yMax={this.props.state.yMax}
                                currencycode={this.props.state.currencycode}
                                currencytype={this.props.state.currencyType}
                                metadata={this.props.state.chartmetadata}
                            />
                        </div>
                    </div>
                    :
                    !this.props.state.viewItems ?
                        <div id="" className="row" style={{ width: "100%", height: "100%" }}>
                            <div id="basepricebyweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                <WeeklyViewMultipleSeries
                                    chart_title={"Base Price by " + this.props.state.xAxisText}
                                    chart_title_info={this.props.state.chart_title_info_nobrands}
                                    unitsvar={this.props.state.unitsvar}
                                    seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                        return {
                                            name: brand,
                                            type: 'spline',
                                            color: Constant.graph_colors[idx],
                                            tooltip: {
                                                pointFormatter: function () {
                                                    let dt = new Date(this.x)
                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                    let date = new Date(this.x + offset)
                                                    if (aggtype === 'Monthly') {
                                                        return '<span>Month: '
                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                    else {
                                                        return '<span>Week Ending: '
                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                }
                                            },
                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                            ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['weightedbp'] / (((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits'])
                                            ]),
                                            zIndex: 1
                                        }
                                    })}
                                    distinctdates={this.props.state.distinctdates}
                                    weekly_data={this.props.state.weekly_data}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    weekagg={this.props.state.filter_object.numweeks}
                                    height={2}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    yAxisTitle='Base Price'
                                    yAxisType='currency'
                                    selectedBrands={this.props.state.selectedBrands}
                                    aggperiod={this.props.state.datasettimeagg}
                                    numTicks={5}
                                    yMin={0}
                                    yMax={this.props.state.yMax}
                                    currencycode={this.props.state.currencycode}
                                    currencytype={this.props.state.currencyType}
                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                />
                            </div>
                            <div id="averagesellingpricebyweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                <WeeklyViewMultipleSeries
                                    chart_title={"Average Selling Price by " + this.props.state.xAxisText}
                                    chart_title_info={this.props.state.chart_title_info_nobrands}
                                    unitsvar={this.props.state.unitsvar}
                                    seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                        return {
                                            name: brand,
                                            type: 'spline',
                                            color: Constant.graph_colors[idx],
                                            tooltip: {
                                                pointFormatter: function () {
                                                    let dt = new Date(this.x)
                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                    let date = new Date(this.x + offset)
                                                    if (aggtype === 'Monthly') {
                                                        return '<span>Month: '
                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                    else {
                                                        return '<span>Week Ending: '
                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                }
                                            },
                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                            ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['weightedasp'] / (((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits'])
                                            ]),
                                            zIndex: 1
                                        }
                                    })}
                                    distinctdates={this.props.state.distinctdates}
                                    weekly_data={this.props.state.weekly_data}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    weekagg={this.props.state.filter_object.numweeks}
                                    height={2}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    yAxisTitle='Average Selling Price'
                                    yAxisType='currency'
                                    selectedBrands={this.props.state.selectedBrands}
                                    aggperiod={this.props.state.datasettimeagg}
                                    numTicks={5}
                                    yMin={0}
                                    yMax={this.props.state.yMax}
                                    currencycode={this.props.state.currencycode}
                                    currencytype={this.props.state.currencyType}
                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                />
                            </div>
                        </div>
                        :
                        <div id="" className="row" style={{ width: "100%", height: "100%" }}>
                            <div id="basepricebyweekitem" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                <WeeklyViewMultipleSeries
                                    chart_title={"Base Price by " + this.props.state.xAxisText}
                                    chart_title_info={this.props.state.chart_title_info_nobrands + "<br>" + this.props.state.selectedBrand + " Items"}
                                    unitsvar={this.props.state.unitsvar}
                                    seriesList={this.props.state.selectedItems.map((item, idx) => {
                                        return {
                                            name: item,
                                            type: 'spline',
                                            color: Constant.graph_colors[idx],
                                            tooltip: {
                                                pointFormatter: function () {
                                                    let dt = new Date(this.x)
                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                    let date = new Date(this.x + offset)
                                                    if (aggtype === 'Monthly') {
                                                        return '<span>Month: '
                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                    else {
                                                        return '<span>Week Ending: '
                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                }
                                            },
                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                            (((this.props.state.item_data_weekly[item] || {})[date] || {})['weightedbp'] / ((this.props.state.item_data_weekly[item] || {})[date] || {})['totalunits'])
                                            ]),
                                            zIndex: 1
                                        }
                                    })}
                                    distinctdates={this.props.state.distinctdates}
                                    weekly_data={this.props.state.item_data_weekly}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    weekagg={this.props.state.filter_object.numweeks}
                                    height={2}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    yAxisTitle='Base Price'
                                    yAxisType='currency'
                                    selectedBrands={this.props.state.selectedItems}
                                    aggperiod={this.props.state.datasettimeagg}
                                    numTicks={5}
                                    yMin={0}
                                    yMax={this.props.state.yMaxItem}
                                    currencycode={this.props.state.currencycode}
                                    currencytype={this.props.state.currencyType}
                                    metadata={this.props.state.chartmetadata}
                                />
                            </div>
                            <div id="averagesellingpricebyweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                <WeeklyViewMultipleSeries
                                    chart_title={"Average Selling Price by " + this.props.state.xAxisText}
                                    chart_title_info={this.props.state.chart_title_info_nobrands + "<br>" + this.props.state.selectedBrand + " Items"}
                                    unitsvar={this.props.state.unitsvar}
                                    seriesList={this.props.state.selectedItems.map((item, idx) => {
                                        return {
                                            name: item,
                                            type: 'spline',
                                            color: Constant.graph_colors[idx],
                                            tooltip: {
                                                pointFormatter: function () {
                                                    let dt = new Date(this.x)
                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                    let date = new Date(this.x + offset)
                                                    if (aggtype === 'Monthly') {
                                                        return '<span>Month: '
                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                    else {
                                                        return '<span>Week Ending: '
                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                            + '</span>'
                                                    }
                                                }
                                            },
                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                            (((this.props.state.item_data_weekly[item] || {})[date] || {})['weightedasp'] / ((this.props.state.item_data_weekly[item] || {})[date] || {})['totalunits'])
                                            ]),
                                            zIndex: 1
                                        }
                                    })}
                                    distinctdates={this.props.state.distinctdates}
                                    weekly_data={this.props.state.item_data_weekly}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    weekagg={this.props.state.filter_object.numweeks}
                                    height={2}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    yAxisTitle='Average Selling Price'
                                    yAxisType='currency'
                                    selectedBrands={this.props.state.selectedItems}
                                    aggperiod={this.props.state.datasettimeagg}
                                    numTicks={5}
                                    yMin={0}
                                    yMax={this.props.state.yMaxItem}
                                    currencycode={this.props.state.currencycode}
                                    currencytype={this.props.state.currencyType}
                                    metadata={this.props.state.chartmetadata}
                                />
                            </div>
                        </div>
                }
            </Fragment>
        )
    }

}