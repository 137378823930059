import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import * as Constant from '../constants'

var route = Constant.whereamilist[3]
var subroute = Constant.whereamilist[4]

if (route === 'PDTRetailer') {
    route = 'PDT'
}

export default function AuthService(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorAlert, seterrorAlert] = useState(false);

    var urllist = window.location.href.split('/').slice(3)
    var location = ''
    urllist.map(path => location += path + '/')

    const windowRedirect = (path) => {
        let win = window.top || window,
            redirectPath = win.location.origin,
            oldPath = win.location.pathname;
        redirectPath = redirectPath + path;
        if (oldPath !== path)
            win.location.assign(redirectPath);
    }

    const handleCloseUsererrorAlert = () => {
        seterrorAlert(false)
    }

    useEffect(() => {
        const logErrorToMiddleware = async (message) => {
            var error_date, error_time
            error_date = new Date()
            error_time = error_date.toISOString()

            seterrorAlert(false) // Setting to false for now until we determine the language of the popup message

            var message_with_time = message + ' at: ' + error_time
            console.log(message_with_time)
            console.trace()

            // Post a Message to the log...
            const messagelog = await fetch(Constant.baseurl + 'account/Log',
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: message_with_time
                })
            console.log(messagelog)
        }

        // This method checks that the user is in the Pricing database and has access to at least SOME datasets
        const checkDataPermissions = async (result, which_app) => {
            const sessionperms = await fetch(Constant.baseapiurl_gen + 'SessionUser/Permissions')
            let sessionperms_status = sessionperms.status
            if (sessionperms_status === 401) {
                // Post a Message to the log...
                logErrorToMiddleware("User " + result.data.name + " got a 401 error response from request /api/SessionUser/Permissions")
            } else if (sessionperms_status === 200) {
                // Check the session permissions to see that they have a dataset defined...
                let sessionperms_data = await sessionperms.json()
                if (!sessionperms_data.hasOwnProperty(which_app) || sessionperms_data[which_app].length === 0) {
                    // Post a Message to the log...
                    logErrorToMiddleware("User " + result.data.name + " has no datasets for " + which_app + " in response from /api/SessionUser/Permissions")
                } else {
                    console.log('User has access to ' + sessionperms_data[which_app].length + ' ' + which_app + ' datasets')
                }
            } else {
                seterrorAlert(false)
            }

            // FIXME: This method always set true even when we get errors in SessionUser/Permissions -- that does not seem right
            setIsAuthenticated(true)
        }

        const checkAuthenticated = async () => {
            // Check in the Mididlewear that the user has logged in
            const result = await axios.get('/account/isauthenticated');
            if (!result.data.isAuthenticated) {
                if (!result.data.isAuthorized) {
                    // If Not Logged In or authorized, route the user to the login page
                    windowRedirect('/account/login?reroute=' + location.substring(0, location.length - 1));
                } else {
                    // If user is authenticated, but not logged in, send to login page
                    windowRedirect('/account/login?reroute=' + location.substring(0, location.length - 1));
                }
                return
            }

            // The user is authenticated with the ID server and Middlewear if we get here

            // Get the applications list and make sure it is a list
            var applications = []
            if (result.data.applicationList) {
                if (typeof result.data.applicationList === 'string') {
                    applications = [result.data.applicationList]
                } else {
                    applications = result.data.applicationList
                }
            } else {
                applications = [] // If Null, make it an empty list
            }

            // Get the roles list and make sure it is a list
            var userrole = []
            if (result.data.userRole) {
                if (typeof result.data.userRole === 'string') {
                    userrole = [result.data.userRole]
                } else {
                    userrole = result.data.userRole
                }
            } else {
                userrole = [] // If Null, make it an empty list
            }

            // Get User Type...privileged or not
            var privilegedUser = 'False'
            if (result.data.internalUser === 'True' && (userrole.includes('Admin') || userrole.includes('Power User'))) {
                privilegedUser = 'True'
            }

            // Now we're all set up to do our route-by-route testing

            if (route === '' || route === '#' || route === 'unauthorized') {
                setIsAuthenticated(true); // No Need to Check anything for these routes...
            } else if (route === 'PNP') {
                // For PNP we first check the application list
                if (!applications.includes("priceandpromotion")) {
                    windowRedirect('/unauthorized/pnp')
                } else {
                    // checkPermissions calls setIsAuthenticated(true) if appropriate
                    checkDataPermissions(result, 'pnp')  // Check that user has access to at least one PNP dataset
                }
            } else if (route === 'PDT') {
                // For PDT we check the application list and that the user has access to at least one PDT dataset
                // Except for "DatasetsStaging" where we also make sure the user is a privileged user
                if (!applications.includes("pricendiscounttrends")) {
                    windowRedirect('/unauthorized/pdt')
                } else {
                    if (subroute === 'DatasetsStaging' && privilegedUser !== "True") {
                        windowRedirect('/unauthorized')
                    } else {
                        // checkPermissions calls setIsAuthenticated(true) if appropriate
                        checkDataPermissions(result, 'pdt')  // Check that user has access to at least one PDT dataset
                    }
                }
            } else if (route === 'PDTETL' || route === 'PNPETL' || route === 'Admin') {
                // Do a privileged user check for ETL and Admin routes, but don't check to see if they already have access to datasets
                if (privilegedUser === "True") {
                    setIsAuthenticated(true);
                } else {
                    windowRedirect('/unauthorized')
                }
            } else {
                // If we get an unexpected URL, always redirect to /unauthorized
                setIsAuthenticated(true);
                windowRedirect('/unauthorized')
            }
        }

        checkAuthenticated();

        return () => {
            //cancel = true;
        }
    }, [location]);

    return isAuthenticated ? <>
        {props.children}
        <Dialog
        open={errorAlert}
        onClose={handleCloseUsererrorAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"No Access"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You do not have access to any datasets.  Please reach out to your customer representative for assistance.
		    </DialogContentText>
        </DialogContent>
        <DialogActions>
            <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                Ok
		    </button>
        </DialogActions>
    </Dialog></> : <div></div>;
};

