export default function hideGroup2Def (event) {

    this.setState({ showGroup2DefModal: false })
    //Only Run The Comparison if the metric has been selected for the comparison page we are on.
    if (this.state.value2 === 0 && this.state.comparisonMetricSelected !== "") {
        this.runcomparison_apis()
    }
    else if (this.state.value2 === 1 && this.state.comparisonMetricSelected_trended !== "") {
        this.runcomparison_apis()
    }
    else if (this.state.value2 > 1) {
        this.runcomparison_apis()
    }
    else {
        console.log('Not Running Comparison')
    }
}