import React, { Component } from 'react';
import './styles/custom.css'
import * as Constant from './constants'

export class PDTRecentAccess extends Component {
    static displayName = PDTRecentAccess.name;

    constructor(props) {
        super(props);
        this.state = {
            mostRecentReports: [],
        }
    }
    componentDidMount() {
        //console.log('Running Audits');
        let appname = Constant.whereamilist[3]
        console.log(appname)
        fetch(Constant.baseapiurl + "Audit/Report/User")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                console.log(result)
                                let reports = []
                                if (result.length > 0) {
                                    result.forEach(report => {                                        
                                        let report_timestamp = null
                                        report.audit.forEach(audit => {
                                            if (appname === 'PDTRetailer') {
                                                if (audit.action === "E" && Constant.PDT_RETAILER_DATASETS.includes(report.dataset_id)) {
                                                    report_timestamp = audit.timestamp
                                                    reports.push([report.report_id, report_timestamp, report.report_name])
                                                }
                                            }
                                            else {
                                                if (audit.action === "E") {
                                                    report_timestamp = audit.timestamp
                                                    reports.push([report.report_id, report_timestamp, report.report_name])
                                                }
                                            }
                                        })
                                    })
                                }
                                try {
                                    let reports_sorted = reports.sort((a, b) => Date.parse(b[1].substr(0, 19)) - Date.parse(a[1].substr(0, 19)))
                                    this.setState({ mostRecentReports: reports_sorted })
                                }
                                catch{
                                    //Do Nothing
                                }

                            },
                            (reason) => {
                                console.log("req.json() - Audit/Report/User - failed with reason " + reason)
                            })
                }
                else {
                    console.log('No Recent Reports')
                }
            },
                (error) => {
                    console.log("fetch request - Audit/Report/User - failed with reason " + error)
                })
            .catch(error => {
                console.log('There was an error making the request Audit/Report/User')
                console.log(error)
            })

    }

    render() {
        //console.log(this.state)
        var appname = Constant.whereamilist[3]
        return (
            <div className='col-sm-12' align="center" style={{ textAlign: 'center' }} >
                <div className='' align='center' style={{ textAlign: 'center' }}>
                    <h4>Most Recent Accessed Reports</h4>
                    <ul style={{ listStyleType: "none", padding: "0px" }} align="center">
                        {this.state.mostRecentReports.map((report, idx) => {
                            if (idx < 3) {
                                return <li key={report[0]}><a href={'/' + appname + '/Report/' + report[0]}>{report[2]}</a></li>
                            }
                            else {
                                return null
                            }
                        })}
                    </ul>
                </div>
            </div>

        )
    }
}

export class PNPRecentAccess extends Component {
    static displayName = PNPRecentAccess.name;

    constructor(props) {
        super(props);
        this.state = {
            mostRecentReports: [],
            mostRecentSims: [],
            mostRecentPlans: [],
        }
    }
    componentDidMount() {
        //console.log('Running Audits');

        fetch(Constant.baseapiurl + "Audit/Report/User")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                //console.log(result)
                                let reports = []
                                if (result.length > 0) {
                                    result.forEach(report => {
                                        let report_timestamp = null
                                        report.audit.forEach(audit => {
                                            if (audit.action === "E") {
                                                report_timestamp = audit.timestamp
                                                reports.push([report.report_id, report_timestamp, report.report_name])
                                            }
                                        })
                                    })
                                }
                                try {
                                    let reports_sorted = reports.sort((a, b) => Date.parse(b[1].substr(0, 19)) - Date.parse(a[1].substr(0, 19)))
                                    this.setState({ mostRecentReports: reports_sorted })
                                }
                                catch{
                                    //Do Nothing
                                }

                            },
                            (reason) => {
                                console.log("req.json() - Audit/Report/User - failed with reason " + reason)
                            })
                }
                else {
                    console.log('No Recent Reports')
                }
            },
                (error) => {
                    console.log("fetch request - Audit/Report/User - failed with reason " + error)
                })
            .catch(error => {
                console.log('There was an error making the request Audit/Report/User')
                console.log(error)
            })

        fetch(Constant.baseapiurl + "Audit/Sim/User")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                //console.log(result)
                                let sims = []
                                if (result.length > 0) {
                                    result.forEach(sim => {
                                        let sim_timestamp = null
                                        sim.Audit.forEach(audit => {
                                            if (audit.action === "E") {
                                                sim_timestamp = audit.timestamp
                                                sims.push([sim.sim_id, sim_timestamp, sim.sim_name])
                                            }
                                        })
                                    })
                                }
                                try {
                                    let sims_sorted = sims.sort((a, b) => Date.parse(b[1].substr(0, 19)) - Date.parse(a[1].substr(0, 19)))
                                    this.setState({ mostRecentSims: sims_sorted })
                                }
                                catch{
                                    //Do Nothing
                                }
                            },
                            (reason) => {
                                console.log("req.json() - Audit/Sim/User - failed with reason " + reason)
                            })
                }
                else {
                    console.log('No Recent Sims')
                }
            },
                (error) => {
                    console.log("fetch request - Audit/Sim/User - failed with reason " + error)
                })
            .catch(error => {
                console.log('There was an error making the request Audit/Sim/User')
                console.log(error)
            })

    }

    render() {
        //console.log(this.state)
        return (
            <div>
                <div className='contentHolder'>
                    <div style={{ maxHeight: '75vh' }} className='row  col-sm-12' align='center'>
                        <div className='col-sm-4' align='center'>
                            <h4>Most Recent Accessed Reports</h4>
                            <ul style={{ listStyleType: "none", padding: "0px" }} align="center">
                                {this.state.mostRecentReports.map((report, idx) => { if (idx < 3) { return <li key={report[0]}><a href={'/PNP/Report/' + report[0]}>{report[2]}</a></li> } else { return null } } )}
                            </ul>
                        </div>
                        <div className='col-sm-4' align='center'>
                            <h4>Most Recent Accessed Simulations</h4>
                            <ul style={{ listStyleType: "none" }} align="center">
                                {this.state.mostRecentSims.map((sim, idx) => { if (idx < 3) { return <li key={sim[0]}><a href={'/PNP/Simulation/' + sim[0]}>{sim[2]}</a></li> } else { return null } })}
                            </ul>
                        </div>
                        <div className='col-sm-4' align='center'>
                            <h4 align='center'>Most Recent Accessed Plans</h4>
                            <ul style={{ listStyleType: "none" }} align="center">
                                {this.state.mostRecentPlans.map((plan, idx) => { if (idx < 3) { return <li key={plan[0]}><a href={'/PNP/Plan/' + plan[0]}>{plan[2]}</a></li> } else { return null } })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ height: '30px', padding: '40px', margin: '40px' }} className='' align='center'>
                    <p style={{ fontFamily: 'Roboto', color: '#ffffff', fontSize: '1vw' }}>
                        We hope you find this tool useful.  
                        You may also refer to our &nbsp;
                                    <a href='PNP/Resources'>
                            resources page
                                    </a>
                                     &nbsp; for more information.
                                </p>
                </div>
            </div>
        )
    }
}