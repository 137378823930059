export default function calculateDateRange () {
    console.log('Calculating Date Range')

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    let numweeks = parseInt(this.state.daterangenumweeks) - 1
    let calctype = this.state.rangeStartingPoint.value
    var calculatedrange
    if (calctype === 'MostRecent') {
        let enddate_range = this.state.times_data[1].substring(0, 10)
        let enddate_label = enddate_range
        let numdays_forrange = (numweeks * 7)
        let numdays_forlabel = (numweeks * 7) + 6
        let begindateDate_forrange = new Date(enddate_range + 'T00:00:00')
        let begindateDate_forlabel = new Date(enddate_range + 'T00:00:00')
        let begindate_range = formatDate(begindateDate_forrange.setDate(begindateDate_forrange.getDate() - numdays_forrange));
        let begindate_label = formatDate(begindateDate_forlabel.setDate(begindateDate_forlabel.getDate() - numdays_forlabel));
        console.log(begindate_range)
        console.log(begindate_label)
        console.log(enddate_range)
        console.log(enddate_label)
        calculatedrange = { begindate_range, begindate_label, enddate_range, enddate_label }
    }
    else {

        if (this.state.rangeAddSubtract.value === 'Subtract') {
            let enddate_range = this.state.selectedendRange.substring(0, 10)
            let enddate_label = enddate_range
            let numdays_forrange = (numweeks * 7)
            let numdays_forlabel = (numweeks * 7) + 6
            let begindateDate_forrange = new Date(enddate_range + 'T00:00:00')
            let begindateDate_forlabel = new Date(enddate_range + 'T00:00:00')
            let begindate_range = formatDate(begindateDate_forrange.setDate(begindateDate_forrange.getDate() - numdays_forrange));
            let begindate_label = formatDate(begindateDate_forlabel.setDate(begindateDate_forlabel.getDate() - numdays_forlabel));
            console.log(begindate_range)
            console.log(begindate_label)
            console.log(enddate_range)
            console.log(enddate_label)
            calculatedrange = { begindate_range, begindate_label, enddate_range, enddate_label }
        }
        else {
            let begindate_range = this.state.selectedendRange.substring(0, 10)
            let begindateDate_forrange = new Date(begindate_range + 'T00:00:00')
            let begindateDate_forlabel = new Date(begindate_range + 'T00:00:00')
            let numdays_forrange = ((numweeks) * 7)
            let numdays_forlabel = - 6
            let enddate_range = formatDate(begindateDate_forrange.setDate(begindateDate_forrange.getDate() + numdays_forrange));
            let enddate_label = enddate_range
            let begindate_label = formatDate(begindateDate_forlabel.setDate(begindateDate_forlabel.getDate() + numdays_forlabel));
            console.log(begindate_range)
            console.log(begindate_label)
            console.log(enddate_range)
            console.log(enddate_label)
            calculatedrange = { begindate_range, begindate_label, enddate_range, enddate_label }
        }

    }

    //Perform Error Checks on calculated range
    let begindateDate = new Date(calculatedrange.begindate_range + 'T00:00:00')
    let enddateDate = new Date(calculatedrange.enddate_range + 'T00:00:00')
    let begindatasetDate = new Date(this.state.times_data[0].substring(0, 10) + 'T00:00:00')
    let enddatasetDate = new Date(this.state.times_data[1].substring(0, 10) + 'T00:00:00')

    var range_set_warning = false
    var range_set_error = false

    if (begindateDate < begindatasetDate && enddateDate < begindatasetDate) {
        range_set_error = true
    }

    if (begindateDate < begindatasetDate && enddateDate >= begindatasetDate) {
        range_set_warning = true
    }

    if (begindateDate <= enddatasetDate && enddateDate > enddatasetDate) {
        range_set_warning = true
    }

    if (begindateDate > enddatasetDate && enddateDate > enddatasetDate) {
        range_set_error = true
    }

    this.setState({ calculatedrange, range_set_warning, range_set_error })
}