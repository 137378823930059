import React, { Component, Fragment } from 'react';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import * as Constant from '../../../constants';

export class AttributeGroupDefIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Constant.HTMLTooltip title={
                <Fragment>
                    <p>  {' Attribute Group Definitions for ' + this.props.state.attributenamelabels[this.props.state.graph_selected_attributes[this.props.state.viewattrnum]]}</p>
                    <br />
                    
                        {this.props.state.customAttrGroups[this.props.state.graph_selected_attributes[this.props.state.viewattrnum]].map(group => {
                            return <ul> <h5>{group.groupname}</h5>
                                {group.attr_vals.map(val => {
                                    return <li> {val} </li>
                                })}
                            </ul>
                        })}
                    
                </Fragment>
            } placement="right">
                <span style={{ fontSize: '.94rem', lineHeight: '2.5', padding: '5px' }}>
                    <InfoRoundedIcon
                        style={{ fontSize: '1.875rem'}}
                    />
                    Hover Here for Attribute Group Definitions
                </span>
            </Constant.HTMLTooltip>
        )
    }
}