import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export class GenericDialog extends Component {
    constructor(props) {
        super(props);
        console.log(props)
    }

    render() {
        return (
            <Dialog
                open={this.props.openVal}
                onClose={this.props.onCloseFunc}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ zIndex: '99999999' }}
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={this.props.onCloseFunc} className="btn btn-primary" autoFocus>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
}