import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as Constant from '../../../constants';

export class BrandChannelSwitch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        return (            
            this.props.state.all_geos.length > 1 ?
                <Fragment>
                    <ThemeProvider theme={Constant.muiTheme}>
                        <span style={{ lineHeight: '5' }}>Brands</span>
                        <Switch
                            checked={this.props.state.channelviews}
                            onChange={this.props.handleChange_channelviews}
                            color="secondary"
                        />
                        <span style={{ lineHeight: '5' }}>Channels </span>
                    </ThemeProvider>
                </Fragment>
                :
                <Fragment/>
                        
        )
    }
}