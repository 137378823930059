import { reducer } from '../../Utilities/Reducer'
export default async function changeSelectedChannel(selectedOptions, position) {
    if (selectedOptions) {

        var distinctdates = this.state.distinctdates
        var distinctdates_forcalc = this.state.distinctdates_forcalc
        var distinctdates_prior = this.state.distinctdates_prior
        var distinctdates_last = this.state.distinctdates_last
        var weekly_data = this.state.weekly_data_geo
        var weekly_data_prior = this.state.weekly_data_prior_geo
        var weekly_data_last = this.state.weekly_data_last_geo
        var selectedChannel = selectedOptions.value

        var nonpromo_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['nonpromotedunits']).reduce(reducer)
        var promo_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['promotedunits']).reduce(reducer)
        var base_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['baseunits']).reduce(reducer)
        var disc_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedpctdisc']).reduce(reducer)
        var total_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['totalunits']).reduce(reducer)

        var disc_my_withoutno = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedpctdisc_withoutno']).reduce(reducer)
        var total_my_withoutno = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['totalunits_withoutno']).reduce(reducer)

        var inc_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['incrementalunits']).reduce(reducer)
        var sub_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['subsidizedbaseunits']).reduce(reducer)
        var unsub_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['unsubsidizedbaseunits']).reduce(reducer)
        var weightedbp_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedbp']).reduce(reducer)
        var weightedbp_withoutno_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedbp_withoutno']).reduce(reducer)
        var incdollars_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['incrementaldollars']).reduce(reducer)
        var discdollars_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['discountdollars']).reduce(reducer)
        var totaldollars_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['totaldollars']).reduce(reducer)

        var nonpromoteddollars_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['nonpromoteddollars']).reduce(reducer)
        var promoteddollars_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['promoteddollars']).reduce(reducer)
        var basedollars_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['basedollars']).reduce(reducer)
        var incrementaldollars_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['incrementaldollars']).reduce(reducer)

        var revopp_my = distinctdates_forcalc.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['revopportunity']).reduce(reducer)


        var nonpromo_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['nonpromotedunits']).reduce(reducer)
        var promo_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['promotedunits']).reduce(reducer)
        var base_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['baseunits']).reduce(reducer)
        var disc_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedpctdisc']).reduce(reducer)
        var total_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['totalunits']).reduce(reducer)
        var disc_my_withoutYOY_withoutno = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedpctdisc_withoutno']).reduce(reducer)
        var total_my_withoutYOY_withoutno = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['totalunits_withoutno']).reduce(reducer)
        var inc_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['incrementalunits']).reduce(reducer)
        var sub_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['subsidizedbaseunits']).reduce(reducer)
        var unsub_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['unsubsidizedbaseunits']).reduce(reducer)
        var weightedbp_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedbp']).reduce(reducer)
        var weightedbp_withoutno_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['weightedbp_withoutno']).reduce(reducer)
        var incdollars_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['incrementaldollars']).reduce(reducer)
        var discdollars_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['discountdollars']).reduce(reducer)
        var totaldollars_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['totaldollars']).reduce(reducer)
        var nonpromoteddollars_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['nonpromoteddollars']).reduce(reducer)
        var promoteddollars_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['promoteddollars']).reduce(reducer)
        var basedollars_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['basedollars']).reduce(reducer)
        var incrementaldollars_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['incrementaldollars']).reduce(reducer)
        var revopp_my_withoutYOY = distinctdates.map(date => ((weekly_data[selectedChannel] || {})[date] || {})['revopportunity']).reduce(reducer)


        var nonpromo_my_prior = 0
        var promo_my_prior = 0
        var base_my_prior = 0
        var disc_my_prior = 0
        var total_my_prior = 0
        var disc_my_withoutno_prior = 0
        var total_my_withoutno_prior = 0
        var inc_my_prior = 0
        var sub_my_prior = 0
        var unsub_my_prior = 0
        var weightedbp_my_prior = 0
        var weightedbp_withoutno_my_prior = 0
        var incdollars_my_prior = 0
        var discdollars_my_prior = 0
        var totaldollars_my_prior = 0
        var nonpromoteddollars_my_prior = 0
        var promoteddollars_my_prior = 0
        var basedollars_my_prior = 0
        var incrementaldollars_my_prior = 0
        var revopp_my_prior = 0

        var nonpromo_my_last = 0
        var promo_my_last = 0
        var base_my_last = 0
        var disc_my_last = 0
        var total_my_last = 0
        var disc_my_withoutno_last = 0
        var total_my_withoutno_last = 0
        var inc_my_last = 0
        var sub_my_last = 0
        var unsub_my_last = 0
        var weightedbp_my_last = 0
        var weightedbp_withoutno_my_last = 0
        var incdollars_my_last = 0
        var discdollars_my_last = 0
        var totaldollars_my_last = 0
        var nonpromoteddollars_my_last = 0
        var promoteddollars_my_last = 0
        var basedollars_my_last = 0
        var incrementaldollars_my_last = 0
        var revopp_my_last = 0

        try {
            nonpromo_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['nonpromotedunits']).reduce(reducer)
            promo_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['promotedunits']).reduce(reducer)
            base_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['baseunits']).reduce(reducer)
            disc_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['weightedpctdisc']).reduce(reducer)
            total_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['totalunits']).reduce(reducer)

            disc_my_withoutno_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['weightedpctdisc_withoutno']).reduce(reducer)
            total_my_withoutno_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['totalunits_withoutno']).reduce(reducer)

            inc_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['incrementalunits']).reduce(reducer)
            sub_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['subsidizedbaseunits']).reduce(reducer)
            unsub_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['unsubsidizedbaseunits']).reduce(reducer)
            weightedbp_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['weightedbp']).reduce(reducer)
            weightedbp_withoutno_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['weightedbp_withoutno']).reduce(reducer)
            incdollars_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['incrementaldollars']).reduce(reducer)
            discdollars_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['discountdollars']).reduce(reducer)
            totaldollars_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['totaldollars']).reduce(reducer)

            nonpromoteddollars_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['nonpromoteddollars']).reduce(reducer)
            promoteddollars_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['promoteddollars']).reduce(reducer)
            basedollars_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['basedollars']).reduce(reducer)
            incrementaldollars_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['incrementaldollars']).reduce(reducer)

            revopp_my_prior = distinctdates_prior.map(date => ((weekly_data_prior[selectedChannel] || {})[date] || {})['revopportunity']).reduce(reducer)

            nonpromo_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['nonpromotedunits']).reduce(reducer)
            promo_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['promotedunits']).reduce(reducer)
            base_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['baseunits']).reduce(reducer)
            disc_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['weightedpctdisc']).reduce(reducer)
            total_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['totalunits']).reduce(reducer)

            disc_my_withoutno_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['weightedpctdisc_withoutno']).reduce(reducer)
            total_my_withoutno_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['totalunits_withoutno']).reduce(reducer)

            inc_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['incrementalunits']).reduce(reducer)
            sub_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['subsidizedbaseunits']).reduce(reducer)
            unsub_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['unsubsidizedbaseunits']).reduce(reducer)
            weightedbp_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['weightedbp']).reduce(reducer)
            weightedbp_withoutno_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['weightedbp_withoutno']).reduce(reducer)
            incdollars_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['incrementaldollars']).reduce(reducer)
            discdollars_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['discountdollars']).reduce(reducer)
            totaldollars_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['totaldollars']).reduce(reducer)

            nonpromoteddollars_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['nonpromoteddollars']).reduce(reducer)
            promoteddollars_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['promoteddollars']).reduce(reducer)
            basedollars_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['basedollars']).reduce(reducer)
            incrementaldollars_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['incrementaldollars']).reduce(reducer)

            revopp_my_last = distinctdates_last.map(date => weekly_data_last[selectedChannel][date]['revopportunity']).reduce(reducer)
        }

        catch (error) {
            nonpromo_my_prior = 0
            promo_my_prior = 0
            base_my_prior = 0
            disc_my_prior = 0
            total_my_prior = 0
            inc_my_prior = 0
            sub_my_prior = 0
            unsub_my_prior = 0
            weightedbp_my_prior = 0
            weightedbp_withoutno_my_prior = 0
            incdollars_my_prior = 0
            discdollars_my_prior = 0
        }

        let chartmetadata_singlechannel = this.state.chartmetadata_singlechannel
        chartmetadata_singlechannel['geography'] = chartmetadata_singlechannel['geography'].split(', ')[0] + ', ' + selectedChannel

        this.setState({
            selectedChannel,

            nonpromo_my,
            promo_my,
            base_my,
            disc_my,
            total_my,
            disc_my_withoutno,
            total_my_withoutno,
            inc_my,
            sub_my,
            unsub_my,
            weightedbp_my,
            weightedbp_withoutno_my,
            incdollars_my,
            discdollars_my,
            revopp_my,

            nonpromo_my_withoutYOY,
            promo_my_withoutYOY,
            inc_my_withoutYOY,
            sub_my_withoutYOY,
            unsub_my_withoutYOY,
            base_my_withoutYOY,
            disc_my_withoutYOY,
            total_my_withoutYOY,
            disc_my_withoutYOY_withoutno,
            total_my_withoutYOY_withoutno,
            weightedbp_my_withoutYOY,
            weightedbp_withoutno_my_withoutYOY,
            incdollars_my_withoutYOY,
            discdollars_my_withoutYOY,
            revopp_my_withoutYOY,

            revopp_my_prior,
            nonpromo_my_prior,
            promo_my_prior,
            base_my_prior,
            disc_my_prior,
            total_my_prior,
            disc_my_withoutno_prior,
            total_my_withoutno_prior,
            inc_my_prior,
            sub_my_prior,
            unsub_my_prior,
            weightedbp_my_prior,
            weightedbp_withoutno_my_prior,
            incdollars_my_prior,
            discdollars_my_prior,
            totaldollars_my,
            nonpromoteddollars_my,
            promoteddollars_my,
            basedollars_my,
            incrementaldollars_my,
            totaldollars_my_withoutYOY,
            nonpromoteddollars_my_withoutYOY,
            promoteddollars_my_withoutYOY,
            basedollars_my_withoutYOY,
            incrementaldollars_my_withoutYOY,
            totaldollars_my_prior,
            nonpromoteddollars_my_prior,
            promoteddollars_my_prior,
            basedollars_my_prior,
            incrementaldollars_my_prior,

            showSnackbar_changebrands: true,
            chartmetadata_singlechannel

        })

    }
}