import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { DepthOfDiscountByALRGraph } from '../../../Graphs/DepthOfDiscountByALRGraph'


export class DepthOfDiscountByALR extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div align='left' className="col-sm-4 row" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <DepthOfDiscountByALRGraph
                            state={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}