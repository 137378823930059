import React, { Component } from 'react';
import './styles/NavMenu.css';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import Avatar from '@material-ui/core/Avatar';
import './styles/custom.css'
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as Constant from './constants'

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.selectedfunc = this.selectedfunc.bind(this);
        this.state = {
            collapsed: true,
            search_dd_val: "Search All",
            isAuthenticated: false,
            userName: ''
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    selectedfunc() {
        console.log('Selected')
    }

    componentDidMount() {
        const checkAuthenticated = async () => {
            const result = await axios.get('/account/isauthenticated');
            //console.log(result)
            this.setState({ isAuthenticated: result.data.isAuthenticated, userName: result.data.name })
        }
        checkAuthenticated();
    }

    render() {
        let appname = Constant.whereamilist[3]
        var apptitle = ""
        var apphomelink = ""
        var profilelink = ""

        if (appname === 'PNP') {
            apptitle = "Price and Promotion Simulator"
            apphomelink = "/PNP/Home"
            profilelink = "/PNP/Profile"
        }
        else if (appname === 'PDT') {
            apptitle = "Price and Discount Trends"
            apphomelink = "/PDT/Home"
            profilelink = "/PDT/Profile"
        }
        else {
            apptitle = "Circana Analytics"
            apphomelink = "/"
            profilelink = "/#"
        }

        const StyledBadge = withStyles(theme => ({
            badge: {
                right: -3,
                top: 13,
                border: "2px solid '#ef2a79'",
                padding: '0 4px',
                backgroundColor: '#ef2a79',
                color: "#FFF"
            },
        }))(Badge);

        const headStyle = {
            height: '60px',
            backgroundColor: "#FFFFFF",
            color: '#565A5C',
            width: '100%',
            fontFamily: 'Roboto',
            paddingLeft: '100px',
            borderBottom: '6px solid #FFFFFF',
            paddingBottom: '30px',
            paddingTop: '15px',
            paddingRight: '0px',
        };

        const navHeader = {
            color: '#565A5C'
            , fontFamily: 'Roboto'
            , fontSize: '1.6rem'
            , lineHeight: '15px'
            , textDecoration: 'none'
        };

        const userName  = this.state.userName;
        //console.log(isAuthenticated)

        return (
            <header style={headStyle} >
                <div className="row col-sm-12" style={{ paddingBottom: '15px', marginRight: '50px' }} >
                    <div className="" align="left" style={{ paddingLeft: '75px', width: '70%' }}>
                        <span >
                            <a href={apphomelink} style={navHeader}>
                                <b>{apptitle}</b>
                            </a>
                        </span>
                    </div>
                    <div style={{ width: '15%' }} align="left" className=""></div>
                    <div style={{ width: '15%' }} align="left" className="">
                        <div className=" " style={{ margin: '10px', display: 'none' }}>
                            <StyledBadge badgeContent={4} style={{
                                color: '#666666'
                                , fontFamily: 'Roboto'
                                , fontSize: '1.6rem'
                                , textDecoration: 'none'
                                , position: 'absolute', top: '0px', left: '80%'
                            }} align="right"><NotificationsRoundedIcon style={{ fontSize: '1.9rem' }}></NotificationsRoundedIcon></StyledBadge>
                        </div>
                        <div className=" " style={{ margin: '10px' }}>
                            <Tooltip title={userName}>
                                <a href={profilelink} style={{ textDecoration: 'none', fontSize: '1.9rem', paddingLeft: '0px', paddingRight: '0px', lineHeight: '20px' }}>
                                    <Avatar align="right" style={{ position: 'absolute', top: '-5px', left: '85%' }}>
                                        {userName !== null && userName.length > 0 ? userName.substring(0, 1).toUpperCase() : '?'}
                                    </Avatar>
                                </a>
                            </Tooltip>
                        </div>
                        <div className=" " style={{ margin: '10px' }}>
                            <a style={{ color: '#666666', fontFamily: 'Roboto', fontSize: '1.6rem', lineHeight: '15px', textDecoration: 'none', position: 'absolute', top: '5px', left: '90%' }} href='/account/logout/' align="right">Logout</a>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
