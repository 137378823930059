import React, { Component } from 'react';
import CreateFilters from '../../Functions/Utilities/CreateFilters'
import { FlexibleBrandTableDownloadMenu } from '../../UIElements/IconButtons/FlexibleBrandTableDownloadMenu'
import { render_flexible_table } from './FlexibleTableCommon'
import * as Constant from '../../../constants'

export class FlexibleBrandTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoading: false,
            expanded_level2: false,
            expanded_level3: false,
            expanded_level4: false,
            selectedMetrics: [
                { label: 'Total Dollars', value: 'totaldollars', format: 'currency' },
                { label: 'Dollar Share', value: 'totaldollars', format: 'percent', denominator: 'dollarshare' },
                { label: 'Average Promoted Discount', value: 'weightedpctdisc_withoutno', format: 'percent', denominator: 'totalunits_withoutno' },
                { label: 'Promoted Dollars %', value: 'promoteddollars', format: 'percent', denominator: 'totaldollars' },
                { label: 'Incremental Dollars %', value: 'incrementaldollars', format: 'percent', denominator: 'totaldollars' },
                { label: 'Promoted Units %', value: 'promotedunits', format: 'percent', denominator: 'totalunits' },
                { label: 'Incremental Units %', value: 'incrementalunits', format: 'percent', denominator: 'totalunits' },
                { label: 'Efficiency (Units)', value: 'incrementalunits', format: 'percent', denominator: 'promotedunits' }],
            metricOptions: [
                { 'label': 'Total ' + this.props.state.currencyType, 'value': 'totaldollars', 'format': 'currency' },
                { 'label': 'Total Units', 'value': 'totalunits', 'format': 'units' },
                { 'label': 'Base ' + this.props.state.currencyType, 'value': 'basedollars', 'format': 'currency' },
                { 'label': 'Base Units', 'value': 'baseunits', 'format': 'units' },
                { 'label': 'Incremental Units', 'value': 'incrementalunits', 'format': 'units' },
                { 'label': 'Incremental ' + this.props.state.currencyType, 'value': 'incrementaldollars', 'format': 'currency' },
                { 'label': 'Subsidized Base Units', 'value': 'subsidizedbaseunits', 'format': 'units' },
                { 'label': 'Subsidized Base ' + this.props.state.currencyType, 'value': 'subsidizedbasedollars', 'format': 'currency' },
                { 'label': 'UnSubsidized Base Units', 'value': 'unsubsidizedbaseunits', 'format': 'units' },
                { 'label': 'UnSubsidized Base ' + this.props.state.currencyType, 'value': 'unsubsidizedbasedollars', 'format': 'units' },
                { 'label': 'Promoted Units', 'value': 'promotedunits', 'format': 'units' },
                { 'label': 'Promoted ' + this.props.state.currencyType, 'value': 'promoteddollars', 'format': 'currency' },
                { 'label': 'Non-Promoted Units', 'value': 'nonpromotedunits', 'format': 'units' },
                { 'label': 'Non-Promoted ' + this.props.state.currencyType, 'value': 'nonpromoteddollars', 'format': 'units' },
                { 'label': 'Average Promoted Discount', 'value': 'weightedpctdisc_withoutno', 'format': 'percent', 'denominator': 'totalunits_withoutno' },
                { 'label': 'Promoted Units %', 'value': 'promotedunits', 'format': 'percent', 'denominator': 'totalunits' },
                { 'label': 'Non-Promoted Units %', 'value': 'nonpromotedunits', 'format': 'percent', 'denominator': 'totalunits' },
                { 'label': 'Base Units %', 'value': 'baseunits', 'format': 'percent', 'denominator': 'totalunits' },
                { 'label': 'Subsidized Base Units %', 'value': 'subsidizedbaseunits', 'format': 'percent', 'denominator': 'totalunits' },
                { 'label': 'UnSubsidized Base Units %', 'value': 'unsubsidizedbaseunits', 'format': 'percent', 'denominator': 'totalunits' },
                { 'label': 'Incremental Units %', 'value': 'incrementalunits', 'format': 'percent', 'denominator': 'totalunits' },
                { 'label': 'Promoted ' + this.props.state.currencyType + ' %', 'value': 'promoteddollars', 'format': 'percent', 'denominator': 'totaldollars' },
                { 'label': 'Non-Promoted ' + this.props.state.currencyType + ' %', 'value': 'nonpromoteddollars', 'format': 'percent', 'denominator': 'totaldollars' },
                { 'label': 'Base ' + this.props.state.currencyType + ' %', 'value': 'basedollars', 'format': 'percent', 'denominator': 'totaldollars' },
                { 'label': 'Subsidized Base ' + this.props.state.currencyType + ' %', 'value': 'subsidizedbasedollars', 'format': 'percent', 'denominator': 'totaldollars' },
                { 'label': 'UnSubsidized Base ' + this.props.state.currencyType + ' %', 'value': 'unsubsidizedbasedollars', 'format': 'percent', 'denominator': 'totaldollars' },
                { 'label': 'Incremental ' + this.props.state.currencyType + ' %', 'value': 'incrementaldollars', 'format': 'percent', 'denominator': 'totaldollars' },
                { 'label': 'Market Lift (Units)', 'value': 'incrementalunits', 'format': 'percent', 'denominator': 'baseunits' },
                { 'label': 'Promoted Lift (Units)', 'value': 'incrementalunits', 'format': 'percent', 'denominator': 'subsidizedbaseunits' },
                { 'label': 'Efficiency (Units)', 'value': 'incrementalunits', 'format': 'percent', 'denominator': 'promotedunits' },
                { 'label': 'Payback Ratio', 'value': 'incrementaldollars', 'format': 'units', 'denominator': 'discountdollars' },
                { 'label': 'Dollar Share', 'value': 'totaldollars', 'format': 'percent', 'denominator': 'dollarshare' },
                { 'label': 'Unit Share', 'value': 'totalunits', 'format': 'percent', 'denominator': 'unitshare' },
            ]
        }
        this.updateAllData = this.updateAllData.bind(this)
        this.openFlexibleBrandTableMenu = this.openFlexibleBrandTableMenu.bind(this)
        this.closeFlexibleBrandTableMenu = this.closeFlexibleBrandTableMenu.bind(this)

        this.changeSelectedMetrics = this.changeSelectedMetrics.bind(this)
        this.reduceLevel = this.reduceLevel.bind(this)
        this.expandLevel = this.expandLevel.bind(this)

        this.render_flexible_table = render_flexible_table.bind(this)
    }

    componentDidMount() {
        // Nothing to do
    }

    componentDidUpdate() {
        // Nothing to do
    }

    changeSelectedMetrics(selectedOptions) {
        if (selectedOptions) {
            this.setState({ selectedMetrics: selectedOptions })
        } else {
            this.setState({ selectedMetrics: [] })
        }
    }

    reduceLevel(level) {
        if (level === 2) {
            this.setState({ expanded_level2: false, expanded_level3: false, expanded_level4: false })
        } else if (level === 3) {
            this.setState({ expanded_level3: false, expanded_level4: false })
        } else if (level === 4) {
            this.setState({ expanded_level4: false })
        }
    }

    expandLevel(level) {
        if (level === 2) {
            this.setState({ expanded_level2: true })
        } else if (level === 3) {
            this.setState({ expanded_level3: true })
        } else if (level === 4) {
            this.setState({ expanded_level4: true })
        }
    }

    openFlexibleBrandTableMenu(event) {
        console.log('Open FlexibleBrandTable Menu')
        this.setState({ openFlexibleBrandTableMenu: true, anchorELPos: event.currentTarget })
    }

    closeFlexibleBrandTableMenu() {
        console.log('Close FlexibleBrandTable Menu')
        this.setState({ openFlexibleBrandTableMenu: false })
    }

    async updateAllData() {
        console.log(this.props)
        this.setState({ dataLoading: true })

        let priceBandOptions = this.props.state.priceBandOptions
        let exclude_array = this.props.state.exclude_array
        let weeks_info = this.props.state.weeks_info
        let filter_object = this.props.state.filter_object

        let filters_threeyearsago = CreateFilters(filter_object, exclude_array, weeks_info, 'threeyearsago', priceBandOptions)
        let filters_twoyearsago = CreateFilters(filter_object, exclude_array, weeks_info, 'twoyearsago', priceBandOptions)
        let filters_prioryear = CreateFilters(filter_object, exclude_array, weeks_info, 'prioryear', priceBandOptions)
        let filters_currentyear = CreateFilters(filter_object, exclude_array, weeks_info, 'currentyear', priceBandOptions)

        let levels = (this.state.levelsSelected || [
            { 'value': 'brand' },
            { 'value': 'segment1_name' },
            { 'value': 'segment2_name' },
            { 'value': 'outlet' }
        ]).map(level => level.value)

        console.log('============ UPDATING DATA IN FlexibleBrandTable ============')
        await this.props.updateData([filters_currentyear, filters_prioryear, filters_twoyearsago, filters_threeyearsago], levels)
        console.log('======================== DATA UPDATED ========================')
        this.setState({ dataLoading: false })
    }

    render() {
        let defaultLevelsSelected = [
            { 'label': 'Brand', 'value': 'brand' },
            { 'label': this.props.state.segment1label, 'value': 'segment1_name' },
            { 'label': this.props.state.segment2label, 'value': 'segment2_name' },
            { 'label': 'Outlet', 'value': 'outlet' }
        ]
        let levelOptions = [
            { 'label': 'Brand', 'value': 'brand' },
            { 'label': this.props.state.segment1label, 'value': 'segment1_name' },
            { 'label': this.props.state.segment2label, 'value': 'segment2_name' },
            { 'label': 'Week Ending', 'value': 'rowdate' },
            { 'label': 'Outlet', 'value': 'outlet' },
            (this.props.state.segment3label || "").length > 0 ? { 'label': this.props.state.segment3label, 'value': 'segment3_name' } : null,
            (this.props.state.segment4label || "").length > 0 ? { 'label': this.props.state.segment4label, 'value': 'segment4_name' } : null,
            (this.props.state.channel_options_filters || []).length > 1 ? { 'label': 'Channel', 'value': 'geogdesc' } : null
        ].concat(Object.keys((this.props.state.attributenamelabels || {})).map(attr => { return { 'label': this.props.state.attributenamelabels[attr], 'value': attr } })).filter(Boolean)

        let current_data = this.props.state.level4_brand_table_currentyear || {}
        let level4_set = new Set()
        Object.keys(current_data).forEach(level1 => {
            let level1_obj = current_data[level1]
            Object.keys(level1_obj).forEach(level2 => {
                let level2_obj = (level1_obj[level2] || {})
                Object.keys(level2_obj).forEach(level3 => {
                    let level3_obj = level2_obj[level3] || {}
                    Object.keys(level3_obj).forEach(level4 => {
                        level4_set.add(level4)
                    })
                })
            })
        })
        let store_group_is_alr = level4_set.size > 1 && level4_set.has('ROM')
        if (!store_group_is_alr) {
            console.log('Flexible Brand Table: Not an ALR; level4_set = ' + [...level4_set].join(', '))
        }

        var has_past_data
        if (!this.props.state.TwoYAGO) {
            has_past_data = Object.keys(this.props.state.level4_segment_table_oneyearago || {}).length > 0
        } else {
            has_past_data = Object.keys(this.props.state.level4_segment_table_twoyearsago || {}).length > 0
        }

        let menu = <FlexibleBrandTableDownloadMenu
                        state={this.props.state}
                        openFlexibleBrandTableMenu={this.props.openFlexibleBrandTableMenu}
                        closeFlexibleBrandTableMenu={this.props.closeFlexibleBrandTableMenu}
                        downloadFlexibleBrandTableData={this.props.downloadFlexibleBrandTableData}
                        store_group_is_alr={store_group_is_alr}
                        has_past_data={has_past_data}
                    />

        return this.render_flexible_table('brand', defaultLevelsSelected, levelOptions, menu, store_group_is_alr, has_past_data)
    }
}
