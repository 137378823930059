export default async function CreateChartMetadata(filters, exclude_array, priceBandOptions) {
    //console.log('Creating Chart Metadata')
    var charmetadata
    console.log(filters)
    //console.log(filters)
    //console.log(this.state)


    //Lookup of All Values...
    let lookup_all_vals = {
        'brands': this.state.filter_view_options['brands']['options'],
        'items': this.state.filter_view_options['items']['options'],
        //'channels': this.state.filter_view_options['channels']['options'],
        //'items': this.state.items_sorted,
        //'outlets': this.state.filter_view_options['outlets']['options'],
        'asp_range': [this.state.aspmin, this.state.aspmax],
        'pricebands': this.state.filter_view_options['price_bands']['options'],
        //'segment1': this.state.filter_view_options['sel1_list']['options'],
        //'segment2': this.state.filter_view_options['sel2_list']['options'],
        //'segment3': this.state.filter_view_options['sel3_list']['options'],
        //'segment4': this.state.filter_view_options['sel4_list']['options'],
        'attributes': {}
    }


    if (this.state.all_geos.length > 1) {
        lookup_all_vals['channels'] = this.state.filter_view_options['channels']['options']
    }

    else {
        lookup_all_vals['channels'] = [{ 'label': this.state.all_geos[0], 'value': this.state.all_geos[0] }]
    }

    console.log(this.state.all_outlets)
    if (this.state.all_outlets.length > 1) {
        lookup_all_vals['outlets'] = this.state.filter_view_options['outlets']['options']
    }

    else {
        lookup_all_vals['outlets'] = [{ 'label': this.state.all_outlets[0], 'value': this.state.all_outlets[0] }]
    }

    if (this.state.dataset_data.sel1_list && this.state.dataset_data.sel1_list.length > 1) {
        lookup_all_vals['segment1'] = this.state.filter_view_options['sel1_list']['options']
    }

    else {
        lookup_all_vals['segment1'] = this.state.segment_1_options_filters
    }


    if (this.state.dataset_data.sel2_list && this.state.dataset_data.sel2_list.length > 1) {
        lookup_all_vals['segment2'] = this.state.filter_view_options['sel2_list']['options']
    }

    else {
        lookup_all_vals['segment2'] = this.state.segment_2_options_filters
    }

    if (this.state.dataset_data.sel3_list && this.state.dataset_data.sel3_list.length > 1) {
        lookup_all_vals['segment3'] = this.state.filter_view_options['sel3_list']['options']
    }

    else {
        lookup_all_vals['segment3'] = this.state.segment_3_options_filters
    }

    if (this.state.dataset_data.sel4_list && this.state.dataset_data.sel4_list.length > 1) {
        lookup_all_vals['segment4'] = this.state.filter_view_options['sel4_list']['options']
    }

    else {
        lookup_all_vals['segment4'] = this.state.segment_4_options_filters
    }
    

    //Add Each Attribute
    Object.keys(filters.attributes).forEach(attr => {
        lookup_all_vals['attributes'][attr] = this.state.filter_view_options[attr]['options']
    })

    console.log(lookup_all_vals)

    let lookup_sel_vals = {
        'brands': this.state.filter_view_selected['brand'],
        'channels': this.state.filter_view_selected['channel'],
        //'items': this.state.items_sorted,
        'items': this.state.filter_view_selected['item'],
        'outlets': this.state.filter_view_selected['outlet'],
        'asp_range': this.state.sliderRange,
        'pricebands': this.state.filter_view_selected['priceband'],
        'segment1': this.state.filter_view_selected['segment1'],
        'segment2': this.state.filter_view_selected['segment2'],
        'segment3': this.state.filter_view_selected['segment3'],
        'segment4': this.state.filter_view_selected['segment4'],
        'attributes': {}
    }

    Object.keys(filters.attributes).forEach(attr => {
        lookup_sel_vals['attributes'][attr] = this.state.filter_view_selected[attr]
    })

    console.log(lookup_sel_vals)

    charmetadata = {}

    function ifEmpty(filters, filterkey) {
        //handle Attributes
        if (filterkey === 'attributes') {
            let attributes = Object.keys(filters.attributes)
            let attr_return = {}
            attributes.forEach(attr => {
                if ((filters['attributes'][attr] || []).length === 0) {
                    attr_return[attr] = lookup_all_vals['attributes'][attr] || []
                }

                else if ((filters['attributes'][attr] || []).length === 1 && filters['attributes'][attr][0]['label'] === 'All') {
                    attr_return[attr] = lookup_all_vals['attributes'][attr] || []
                }

                else {
                    attr_return[attr] = lookup_sel_vals['attributes'][attr] || []
                }
            })

            return attr_return
        }
        //All Other Keys
        else {
            if ((filters[filterkey] || []).length === 0) {
                return lookup_all_vals[filterkey] || []
            }

            else if ((filters[filterkey] || []).length === 1 && filters[filterkey][0]['label'] === 'All') {
                return lookup_all_vals[filterkey] || []
            }

            else {
                return lookup_sel_vals[filterkey] || []
            }
        }

    }

    function ifEmptyTitle(filters, filterkey) {
        //handle Attributes
        if (filterkey === 'attributes') {
            let attributes = Object.keys(filters.attributes)
            let attr_return = {}
            attributes.forEach(attr => {
                if ((filters['attributes'][attr] || []).length === 0) {
                    attr_return[attr] = lookup_all_vals['attributes'][attr] || []
                }

                else if ((filters['attributes'][attr] || []).length === 1 && filters['attributes'][attr][0]['label'] === 'All') {
                    attr_return[attr] = lookup_all_vals['attributes'][attr] || []
                }

                else {
                    attr_return[attr] = lookup_sel_vals['attributes'][attr] || []
                }
            })

            return attr_return
        }
        //All Other Keys
        else {
            var values_init
            if ((filters[filterkey] || []).length === 0) {
                values_init = lookup_all_vals[filterkey] || []
            }

            else if ((filters[filterkey] || []).length === 1 && filters[filterkey][0]['label'] === 'All') {
                values_init = lookup_all_vals[filterkey] || []
            }

            else {
                values_init = lookup_sel_vals[filterkey] || []
            }

            var values
            if (values_init.length > 5) {
                values = [{ 'label': values_init.length + ' Selected', 'value': values_init.length + ' Selected' }]
            }

            else {
                values = values_init
            }

            return values
        }

    }

    //Build Strings
    let selectedend = filters.enddate
    let date = Date.UTC(parseInt(selectedend.substring(0, 4)), parseInt(selectedend.substring(5, 7)) - 1, parseInt(selectedend.substring(8, 10)), 0, 0, 0, 0)
    let dt = new Date(date)
    let offset = dt.getTimezoneOffset() * 60 * 1000
    var dateformatted = (new Date(date + offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })

    charmetadata['Dates'] = this.state.weeks_info.groupeddates.length + ' ' + this.state.weeks_info.timeperiodlabels + 's Ending: ' + dateformatted
    charmetadata['Brands'] = ifEmpty(filters, 'brands').map(option => option.label).join(', ')
    charmetadata['Items'] = ifEmpty(filters, 'items').map(option => option.label).join(', ')
    charmetadata['Channels'] = ifEmpty(filters, 'channels').map(option => option.label).join(', ')
    charmetadata['Outlets'] = ifEmpty(filters, 'outlets').map(option => option.label).join(', ')
    charmetadata['ASP'] = ifEmpty(filters, 'asp_range').map(option => (option || "").toLocaleString(undefined, { style: 'currency', currency: this.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })).join(' to ')
    charmetadata['Price Bands'] = ifEmpty(filters, 'pricebands').map(option => option.label).join(', ')
    charmetadata[this.state.segment1label] = ifEmpty(filters, 'segment1').map(option => option.label).join(', ')
    charmetadata[this.state.segment2label] = ifEmpty(filters, 'segment2').map(option => option.label).join(', ')
    charmetadata[this.state.segment3label] = ifEmpty(filters, 'segment3').map(option => option.label).join(', ')
    charmetadata[this.state.segment4label] = ifEmpty(filters, 'segment4').map(option => option.label).join(', ')    

    let attr_vals = ifEmpty(filters, 'attributes')

    //console.log(attr_vals)

    Object.keys(filters.attributes).forEach(attr => {
        charmetadata[this.state.attributenamelabels[attr]] = attr_vals[attr].map(option => option.label).join(', ')
    })

    let attr_caption_text = 'Attributes Selected: '
        + Object.keys(filters.attributes).map(attribute => {
            if ((filters.attributes[attribute] || []).length > 0) {
                return this.state.attributenamelabels[attribute] + ': ' + filters.attributes[attribute].join(' , ')
            }
            else {
                return this.state.attributenamelabels[attribute] + ': All' 
            }
        }).join('; ')


    let selectedend_prior = this.state.weeks_info.prior_end
    let date_prior = Date.UTC(parseInt(selectedend_prior.substring(0, 4)), parseInt(selectedend_prior.substring(5, 7)) - 1, parseInt(selectedend_prior.substring(8, 10)), 0, 0, 0, 0)
    let dt_prior = new Date(date_prior)
    let offset_prior = dt_prior.getTimezoneOffset() * 60 * 1000
    var dateformatted_prior = (new Date(date_prior + offset_prior)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })

    let selectedend_last = this.state.weeks_info.last_end
    let date_last = Date.UTC(parseInt(selectedend_last.substring(0, 4)), parseInt(selectedend_last.substring(5, 7)) - 1, parseInt(selectedend_last.substring(8, 10)), 0, 0, 0, 0)
    let dt_last = new Date(date_last)
    let offset_last = dt_last.getTimezoneOffset() * 60 * 1000
    var dateformatted_last = (new Date(date_last + offset_last)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })

    let timePeriod_all_title = this.state.weeks_info.groupeddates.length + ' ' + this.state.weeks_info.timeperiodlabels + 's Ending: ' + dateformatted
    let timePeriod_current_title = this.state.weeks_info.groupeddates_calc.length + ' ' + this.state.weeks_info.timeperiodlabels + 's Ending: ' + dateformatted
    let timePeriod_prior_title = this.state.weeks_info.groupeddates_prior.length + ' ' + this.state.weeks_info.timeperiodlabels + 's Ending: ' + dateformatted_prior
    let timePeriod_last_title = this.state.weeks_info.groupeddates_last.length + ' ' + this.state.weeks_info.timeperiodlabels + 's Ending: ' + dateformatted_last

    let channels_outlets_title = 'Channels: ' + ifEmptyTitle(filters, 'channels').map(option => option.label).join(', ') + '; ' + 'Outlets: ' + ifEmptyTitle(filters, 'outlets').map(option => option.label).join(', ')
    

    var segment1title = '', segment2title = '', segment3title = '', segment4title = ''

    segment1title = this.state.segment1label + ': ' + ifEmptyTitle(filters, 'segment1').map(option => option.label).join(', ') + '; '
    segment2title = this.state.segment2label + ': ' + ifEmptyTitle(filters, 'segment2').map(option => option.label).join(', ')

    if (this.state.segment3label.length > 0) {
        segment3title = '; ' + this.state.segment3label + ': ' + ifEmptyTitle(filters, 'segment3').map(option => option.label).join(', ')
    }

    if (this.state.segment4label.length > 0) {
        segment4title = '; ' + this.state.segment4label + ': ' + ifEmptyTitle(filters, 'segment4').map(option => option.label).join(', ')
    }

    let segments_title = segment1title + segment2title + segment3title + segment4title
                 
    let brands_title = 'Brand(s) Selected: Total Selection'    
    if ((filters['brands'] || []).length !== 0) {
        brands_title = 'Brand(s) Selected: ' + lookup_sel_vals['brands'].map(option => option.label).join(', ')
    }

    let items_title = 'Item(s) Selected: Total Selection'
    if ((filters['items'] || []).length !== 0) {
        items_title = 'Item(s) Selected: ' + lookup_sel_vals['items'].map(option => option.label).join(', ')
    }
    if (this.state.item_options_filters && this.state.item_options_filters.length <= 1)
        items_title = ""

    //create titles....

    var chart_title_info = "<br>"
        + channels_outlets_title
        + "<br>"
        + timePeriod_all_title
        + "<br>"
        + segments_title
        + "<br>"
        + brands_title
        + "<br>"
        + items_title
        + "<br>"

    var chart_title_info_nobrands = "<br>"
        + channels_outlets_title
        + "<br>"
        + timePeriod_all_title
        + "<br>"
        + segments_title
        + "<br>"
        + items_title
        + "<br>"

    var chart_title_info_noitems = "<br>"
        + channels_outlets_title
        + "<br>"
        + timePeriod_all_title
        + "<br>"
        + segments_title
        + "<br>"
        + brands_title
        + "<br>"

    var chart_title_info_nochannels = "<br>"
        + channels_outlets_title //.split(', ')[0]
        + "<br>"
        + timePeriod_all_title
        + "<br>"
        + segments_title
        + "<br>"
        + brands_title
        + "<br>"
        + items_title
        + "<br>"

    var chart_title_info_nobrands_prior = "<br>" + channels_outlets_title
    var chart_title_info_noitems_prior = "<br>" + channels_outlets_title
    var chart_title_info_nochannels_prior = "<br>" + channels_outlets_title + "<br>" //.split(', ')[0] + "<br>"

    this.setState({
        chart_title_info,
        chart_title_info_nobrands,
        chart_title_info_noitems,
        chart_title_info_nochannels,
        chart_title_info_nobrands_prior,
        chart_title_info_noitems_prior,
        chart_title_info_nochannels_prior,
        chartmetadata: charmetadata,
        chartmetadata_final: charmetadata,
        chartmetadata_multiplebrands: charmetadata,
        chartmetadata_singlebrand: charmetadata,
        chartmetadata_singlechannel: charmetadata,
        attr_caption_text,
    })

    return charmetadata
}