import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../../UIElements/Switches/UnitsDollarSwitch'
import { MetricLevelOptionsBaseInc } from '../../../UIElements/Dropdowns/MetricLevelOptionsBaseInc'
//import { FilterBarReportViews } from '../../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../../UIElements/Filters/FilterBarReportViewsFunctional';
import { RankOptions } from '../../../UIElements/Dropdowns/RankOptions'
import { PriceBandDefIcon } from '../../../UIElements/IconButtons/PriceBandDefIcon'
import { DownloadAggregateDataAll } from '../../../UIElements/IconButtons/DownloadAggregateDataAll'
import DiscountDollarsAndPBR from '../../../../Graphs/DiscountDollarsAndPBR'

export class DiscDollar_PBR extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }
        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        //Create Data...
        function filterArray(array, start, end) {
            let new_array = array.map((value, idx) => {
                if (idx >= start && idx < end) {
                    return value
                }
                else {
                    return null
                }
            }).filter(x => x !== null)
            return new_array
        }

        //create series list....
        //Use Aggregated Data
        var categories, DiscountDollarData, PBRData
        var categories_dollars = this.props.state.agg_categories_dollars || []
        var categories_units = this.props.state.agg_categories_units || []
        var RankStart = this.props.state.RankStart || 0
        var RankEnd = this.props.state.RankEnd || 10
        if (this.props.state.indexSwitchCheckDollars) {
            categories = filterArray(categories_dollars, RankStart, RankEnd)
            DiscountDollarData = filterArray(categories_dollars.map(key => (this.props.state.aggregatedData[key] || {})['discountdollars'] || 0), RankStart, RankEnd)
            PBRData = filterArray(categories_dollars.map(key => ((this.props.state.aggregatedData[key] || {})['incrementaldollars'] / (this.props.state.aggregatedData[key] || {})['discountdollars']) || 0), RankStart, RankEnd)
        }
        else {
            categories = filterArray(categories_units, RankStart, RankEnd)
            DiscountDollarData = filterArray(categories_units.map(key => (this.props.state.aggregatedData[key] || {})['discountdollars'] || 0), RankStart, RankEnd)
            PBRData = filterArray(categories_units.map(key => ((this.props.state.aggregatedData[key] || {})['incrementaldollars'] / (this.props.state.aggregatedData[key] || {})['discountdollars']) || 0), RankStart, RankEnd)
        }

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div className="col-sm-4" >
                        <MetricLevelOptionsBaseInc
                            state={this.props.state}
                            changeSelectedLevel_BaseInc={this.props.changeSelectedLevel_BaseInc}
                        />
                    </div>
                    <div className="col-sm-2 row" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    {(this.props.state.RankOptions || []).length > 1 ?
                        <div align='left' className="col-sm-3" >
                            <RankOptions
                                state={this.props.state}
                                changeRank={this.props.changeRank}
                            />
                        </div>
                        :
                        <div />
                    }
                    {this.props.state.level_baseinc === 'Price Band' ?
                        <div align='left' className="col-sm-3" >
                            <PriceBandDefIcon
                                state={this.props.state}
                            />
                        </div>
                        :
                        <div />
                    }
                </div>

                <div className="row">
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ height: '100%', width: "100%" }} className="col-sm-12">
                        <div className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                            <DiscountDollarsAndPBR
                                DiscountDollarData={DiscountDollarData}
                                PBRData={PBRData}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                showDataTable={this.props.state.showDataTable}
                                currencyType={this.props.state.currencyType}
                                currencycode={this.props.state.currencycode}
                                categories={categories}
                                attr_caption_text={this.props.state.attr_caption_text}
                                chart_title_info={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.selectedBrand === 'Total Selection' ?
                                            this.props.state.chart_title_info_nobrands + ' Brand(s) Selected: ' + this.props.state.brandsstring
                                            :
                                            this.props.state.chart_title_info_nobrands + ' Brand Selected: ' + this.props.state.selectedBrand
                                        :
                                        this.props.state.chart_title_info
                                }
                                level={this.props.state.level_baseinc}
                                metadata={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.chartmetadata_singlebrand
                                        :
                                        this.props.state.chartmetadata
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
