import React, { Component, Fragment, useContext, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Select from 'react-select'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Grid from '@mui/material/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import AttributeGroupDefIconFunctional from '../IconButtons/AttrGroupDefIconFunctional';
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'
import '../../../styles/custom.css'

import {ReportContext} from "../../Context/ReportContext"
import _ from "lodash"

const sxClasses = {
    selectGridItem: {
        paddingX: "15px"
    }
}

const all_value = [{ 'label': 'All', 'value': 'All' }]


export default function FilterBarReportViewsFunctional() {
    const reportContext = useContext(ReportContext)
    const {
        use_attr_groups, 
        filter_view_selected, 
        filter_view_options, 
        changeViewFilters,
        changeFilterPause_reportViews,
        all_geos,
        all_outlets,
        dataset_data,
        segment1label,
        segment2label,
        segment3label,
        segment4label,
        isLoaded_Filters,
        handleToggle_useAttrGroups,
        filterPause_reportViews,
        slicers_not_applied,
        priceBandOptions,
        item_options_filters,
        attributenamelabels,
        attributes,

        customUserAttrGroups,
        user_selected_attr_groups,
        setUserSelectedAttrGroups
    } = reportContext

    
    const [openHelp, setOpenHelp] = useState(false)


    let brand_options = ((filter_view_options['brands'] || {})['options'] || [])
    let item_options = ((filter_view_options['items'] || {})['options'] || [])
    let price_band_options_init = ((filter_view_options['price_bands'] || {})['options'] || [])
    let channel_options = ((filter_view_options['channels'] || {})['options'] || [])
    let outlet_options = ((filter_view_options['outlets'] || {})['options'] || [])
    let segment1_options = ((filter_view_options['sel1_list'] || {})['options'] || [])
    let segment2_options = ((filter_view_options['sel2_list'] || {})['options'] || [])
    let segment3_options = ((filter_view_options['sel3_list'] || {})['options'] || [])
    let segment4_options = ((filter_view_options['sel4_list'] || {})['options'] || [])

    let price_band_options = []
    var valid_price_bands = price_band_options_init.map(option=>option.label)
    //Compare priceBandOptions to price_band_options_init.  If price band in both, push to options so we have the right order
    priceBandOptions.forEach(option => {
        if (valid_price_bands.includes(option.label)) {
            price_band_options.push({ 'label': option.label, 'value': option.label })
        }
    })

    const handleClick_help = () => {
        setOpenHelp(true)
    }

    const handleCloseModalHelp = () => {
        setOpenHelp(false)
    };

    function handleAttributeFieldChange(items, attribute) {
        if (items == null) {
            setUserSelectedAttrGroups({...user_selected_attr_groups, [attribute]: []})
        } else if (use_attr_groups) {
            // if attribute groups box checked, can add or remove one of these groups
            // check all available groups against selected items
            var newSelectedGroups = []
            const addedOption = items[items.length-1]
            if (!_.isEqual(addedOption, all_value[0])) {
                // if user just selected All then custom attr groups should be cleared
                for (const group of customUserAttrGroups[attribute]) {
                    if (_.find(items, function(thisGroup) {return thisGroup.label === group.groupname && _.isEmpty(_.xor(thisGroup.value, group.attr_vals))}) != null) {
                        newSelectedGroups.push(group)
                    }
                }
            }
            setUserSelectedAttrGroups({...user_selected_attr_groups, [attribute]: newSelectedGroups})
        } else {
            // if attribute groups box not chekced, can only potentially remove groups
            var impliedSelectedAttrGroups = []
            if (!items.includes(all_value[0])) {
                const allValues = items.map(item => item.value)
                impliedSelectedAttrGroups = user_selected_attr_groups[attribute].filter(attrGroup => _.difference(attrGroup.attr_vals, allValues).length === 0)
            }
            setUserSelectedAttrGroups({...user_selected_attr_groups, [attribute]: impliedSelectedAttrGroups})
        }
        changeViewFilters(items, attribute, 'weekly')
    }


    return (                                        
        <div className='col-sm-12' style={{ padding: '20px' }} >

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{ overflowY: 'scroll', zIndex: 999999 }}
                open={openHelp}
                onClose={handleCloseModalHelp}
            >
                <div style={{
                    top: '40%',
                    left: '40%',
                    transform: 'translate(-40%, -40%)',
                    position: 'absolute',
                    minWidth: 800,
                    maxWidth: 800,
                    border: '2px solid #000',
                    backgroundColor: '#FFFFFF',
                    padding: '20px'
                }}
                >
                    <div align="right">
                        <IconButton aria-label="copy" onClick={handleCloseModalHelp}  >
                            <CloseIcon style={{ color: '#4e106f' }} />
                        </IconButton>
                    </div>
                    <h2 id="simple-modal-title" align="center">How to Use Slicers</h2>                        
                    <p>To use the slicers, select an option from the dropdown to further limit the data in the view.  </p>
                    <p>Once a selection is made, the values in the dropdowns will reflect the available options for the current selection. </p>
                    <p>Once you have finalized your selections, hit the apply button to change the view.  </p>
                    <p>Data will not update until you hit the apply button to apply the slicers.</p>
                </div>
            </Modal>

            <Accordion
                style={{ backgroundColor: '#F4F4F4' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h5>Slicers</h5>
                </AccordionSummary>
                <AccordionDetails>

                    {isLoaded_Filters ? (
                        <Grid container sx={{bgcolor: "#F4F4F4"}}>
                            <Grid item xs={2} sx={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                <Tooltip title="How do I use these slicers?  Click for more information">
                                    <div className="dotsmall_hideborders">
                                        <IconButton aria-label="copy" onClick={handleClick_help}  >
                                            <HelpOutlineRoundedIcon style={{ color: '#4e106f' }} />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Grid>
                            <Grid 
                                item 
                                xs={4}  
                                sx={{
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "flex-end",
                                    paddingRight: "15px"
                                }}
                            >
                                {Object.values(customUserAttrGroups).flat().length > 0 &&
                                    <>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={use_attr_groups}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Use Groups?' }}
                                            onChange={handleToggle_useAttrGroups}
                                        />
                                    </ThemeProvider>
                                    <span style={{ lineHeight: '5' }} >Use Defined Attribute Groups</span>
                                    </>
                                }
                            </Grid>

                            <Grid 
                                item 
                                xs={5}  
                                sx={{
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "flex-start",
                                    paddingLeft: "15px"
                                }}
                            >
                                {use_attr_groups &&
                                    <AttributeGroupDefIconFunctional
                                        attributenamelabels={attributenamelabels}
                                        customUserAttrGroups={customUserAttrGroups}
                                    />
                                }
                            </Grid>
                            <Grid item xs={1}  sx={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                {filterPause_reportViews === 'apply' ?
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={changeFilterPause_reportViews}
                                        value='pause'
                                        style={{ padding: '5px', margin: '5px' }}
                                    >
                                        <Tooltip title="Pause Filters" placement="right">
                                            <PauseRoundedIcon />
                                        </Tooltip>
                                    </IconButton>

                                    :
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={changeFilterPause_reportViews}
                                        value='apply'
                                        style={{ padding: '5px', margin: '5px' }}
                                    >
                                        <Tooltip title="Apply Slicers" placement="right">
                                            <PlayArrowRoundedIcon />
                                        </Tooltip>
                                    </IconButton>
                                }
                            </Grid>
                            {slicers_not_applied === true &&
                                <Grid 
                                    item 
                                    xs={12} 
                                    sx={{
                                        display: "flex", 
                                        alignItems: "center", 
                                        justifyContent: "flex-start",
                                        paddingLeft: "15px"
                                    }}
                                    >
                                    <span style={{ color: '#f0ab00' }}>
                                        <WarningAmberRoundedIcon />
                                        Slicer values have updated, but the data has not been updated.  Hit apply button to refresh the data.
                                    </span>
                                </Grid>
                            }
                            {all_geos.length > 1 &&
                                <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                    <span className="formfieldtitle">Channel</span>
                                    <Select
                                        options={all_value.concat(channel_options)}
                                        placeholder={'Select A Channel'}
                                        onChange={(item, key, viewtype) => changeViewFilters(item, 'channel', 'weekly')}
                                        value={filter_view_selected['channel']}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </Grid>
                            }
                            {all_outlets.length > 1 &&
                                <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                    <span className="formfieldtitle">Outlet</span>
                                    <Select
                                        options={all_value.concat(outlet_options)}
                                        placeholder={'Select An Outlet'}
                                        onChange={(item, key, viewtype) => changeViewFilters(item, 'outlet', 'weekly')}
                                        value={filter_view_selected['outlet']}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </Grid>
                            }
                            {dataset_data.sel1_list && dataset_data.sel1_list.length > 1 &&
                                <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                    <span className="formfieldtitle">{segment1label}</span>
                                    <Select
                                        options={all_value.concat(segment1_options)}
                                        placeholder={'Select A ' + segment1label}
                                        onChange={(item, key, viewtype) => changeViewFilters(item, 'segment1', 'weekly')}
                                        value={filter_view_selected['segment1']}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </Grid>
                            }
                            {dataset_data.sel2_list && dataset_data.sel2_list.length > 1 &&
                                <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                    <span className="formfieldtitle">{segment2label}</span>
                                    <Select
                                        options={all_value.concat(segment2_options)}
                                        placeholder={'Select A ' + segment2label}
                                        onChange={(item, key, viewtype) => changeViewFilters(item, 'segment2', 'weekly')}
                                        value={filter_view_selected['segment2']}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </Grid>
                            }
                            {dataset_data.sel3_list && dataset_data.sel3_list.length > 1 &&
                                <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                    <span className="formfieldtitle">{segment3label}</span>
                                    <Select
                                        options={all_value.concat(segment3_options)}
                                        placeholder={'Select A ' + segment3label}
                                        onChange={(item, key, viewtype) => changeViewFilters(item, 'segment3', 'weekly')}
                                        value={filter_view_selected['segment3']}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </Grid>
                            }
                            {dataset_data.sel4_list && dataset_data.sel4_list.length > 1 &&
                                <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                    <span className="formfieldtitle">{segment4label}</span>
                                    <Select
                                        options={all_value.concat(segment4_options)}
                                        placeholder={'Select A ' + segment4label}
                                        onChange={(item, key, viewtype) => changeViewFilters(item, 'segment4', 'weekly')}
                                        value={filter_view_selected['segment4']}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </Grid>
                            }
                            <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                <span className="formfieldtitle">Brand</span>
                                <Select
                                    options={all_value.concat(brand_options)}
                                    placeholder={'Select A Brand'}
                                    onChange={(item, key, viewtype) => changeViewFilters(item, 'brand', 'weekly')}
                                    value={filter_view_selected['brand']}
                                    styles={Constant.dropdownStyles}
                                    styles={selectColourStyles}
                                    isMulti
                                />
                            </Grid>
                            {item_options_filters && item_options_filters.length > 1 &&
                                <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                    <span className="formfieldtitle">Item</span>
                                    <Select
                                        options={all_value.concat(item_options)}
                                        placeholder={'Select An Item'}
                                        onChange={(item, key, viewtype) => changeViewFilters(item, 'item', 'weekly')}
                                        value={filter_view_selected['item']}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </Grid>
                            }
                            <Grid item xs={3} sx={sxClasses.selectGridItem} >
                                <span className="formfieldtitle">Price Band</span>
                                <Select
                                    options={all_value.concat(price_band_options)}
                                    placeholder={'Select A Price Band'}
                                    onChange={(item, key, viewtype) => changeViewFilters(item, 'priceband', 'weekly')}
                                    value={filter_view_selected['priceband']}
                                    styles={Constant.dropdownStyles}
                                    styles={selectColourStyles}
                                    isMulti
                                />
                            </Grid>

                            {Object.keys(attributes).map(attribute => {
                                if (filter_view_options[attribute].options == null) {
                                    return
                                }
                                var options = all_value
                                var value = _.cloneDeep(filter_view_selected[attribute])
                                if (use_attr_groups === true) {
                                    options = options.concat(customUserAttrGroups[attribute].map(group => ({label: group.groupname, value: group.attr_vals})))
                                    for (const group of user_selected_attr_groups[attribute]) {
                                        value = value.filter(basicValue => !group.attr_vals.includes(basicValue.value))
                                    }
                                    value = user_selected_attr_groups[attribute].map(group => ({label: group.groupname, value: group.attr_vals})).concat(value)
                                } 

                                options = options.concat(filter_view_options[attribute].options)
                                options = options.filter(option => option.label != null && option.value != null)
                                const idname = attribute + "groupdropdown"
                                if (options.length > 0) {
                                    return (
                                        <Grid item xs={3} sx={sxClasses.selectGridItem} key={idname} >
                                            <span className="formfieldtitle">{attributenamelabels[attribute]}</span>
                                            <Select
                                                id={idname}
                                                options={options}
                                                placeholder={'Select ' + attributenamelabels[attribute]}
                                                onChange={(item, key, viewtype) => handleAttributeFieldChange(item, attribute)}
                                                value={value}
                                                styles={Constant.dropdownStyles}
                                                styles={selectColourStyles}
                                                isMulti
                                            />
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid> 
                    ) : (
                            //<CircularProgress color="#4e106f" />
                            <div class="custom-loader"></div>
                    )}
                </AccordionDetails>
            </Accordion>                                             
        </div>            
    )
}