import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../../../constants';
import Select from 'react-select'
import createRankOptions from '../../Functions/Utilities/CreateRankOptions'

// FIXME DEAD CODE: THIS FILE DOES NOT APPEAR TO BE USED ANYWHERE
export class FlexibleViewGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RankStart: 0,
            RankEnd: 10,
        }
    }

    componentDidUpdate() {
        if (this.props.state.selectedLevel2 && !this.state.selectedLevel1_value) {
            this.setState({ selectedLevel1_value: this.props.state.selectedLevel2.label })
        }

        if (this.props.state.selectedLevel2 && this.state.selectedLevel1_value && this.state.selected_level1) {
            if (this.state.selectedLevel1_value !== this.props.state.selectedLevel2.label) {
                this.setState({
                    selected_level1: null,
                    selectedLevel1_value: null,
                    selectedRank: null,
                    RankStart: 0,
                    RankEnd: 10
                })
            }
        }
    }

    render() {
        const changeSelectedLevel1 = selectedOptions => {
            this.setState({
                selected_level1: selectedOptions.value
            })
        }

        const changeRank = selectedOptions => {
            this.setState({
                selectedRank: selectedOptions,
                RankStart: selectedOptions.value[0],
                RankEnd: selectedOptions.value[1]
            })
        }

        var units_dollars
        if (this.props.globalstate.indexSwitchCheckDollars) {
            units_dollars = 'dollars'
        }
        else {
            units_dollars = 'units'
        }

        //create the approriate series and dropdowns...
        var data = {}, level1_values = [], selected_level1, categories = []
        if (this.props.state.aggregated) {
            if ((this.props.state.aggregated_data || {}).level1_data) {
                data = this.props.state.aggregated_data.level1_data
                if (units_dollars === 'units') {
                    categories = this.props.state.aggregated_data.level1_ordered_units
                }
                else {
                    categories = this.props.state.aggregated_data.level1_ordered_dollars
                }

                selected_level1 = null
                level1_values = []

                if ((this.props.state.selectedLevel1 || {}).label === 'Price Band') {
                    let pricebands_ordered = this.props.globalstate.priceBandOptions.map(pb => pb.label)
                    let categories_corrected = []
                    pricebands_ordered.forEach(cat => {
                        if (categories.includes(cat)) {
                            categories_corrected.push(cat)
                        }
                    })
                    categories = categories_corrected
                }
            }
            else if ((this.props.state.aggregated_data || {}).level2_data) {
                level1_values = Object.keys(this.props.state.aggregated_data.level2_data || {})
                selected_level1 = this.state.selected_level1 || level1_values[0]
                data = (this.props.state.aggregated_data.level2_data || {})[selected_level1]
                categories = Object.keys(data)
                //correct categories if priceband...
                if ((this.props.state.selectedLevel1 || {}).label === 'Price Band') {
                    let pricebands_ordered = this.props.globalstate.priceBandOptions.map(pb => pb.label)
                    let categories_corrected = []
                    pricebands_ordered.forEach(cat => {
                        if (categories.includes(cat)) {
                            categories_corrected.push(cat)
                        }
                    })
                    categories = categories_corrected
                }
                //Else...sort by the data....
                else {
                    let sortkey
                    if (units_dollars === 'units') {
                        sortkey = 'totalunits'
                    }
                    else {
                        sortkey = 'totaldollars'
                    }

                    var sortable = [];
                    categories.forEach(cat => {
                        sortable.push([cat, data[cat][sortkey]]);
                    })

                    sortable.sort(function (a, b) {
                        return b[1] - a[1];
                    });

                    var final_sorted = sortable.map(sorted => sorted[0])
                    categories = final_sorted
                }

                //correct level1_values if priceband...
                if ((this.props.state.selectedLevel2 || {}).label === 'Price Band') {
                    let pricebands_ordered = this.props.globalstate.priceBandOptions.map(pb => pb.label)
                    let level1_values_corrected = []
                    pricebands_ordered.forEach(cat => {
                        if (level1_values.includes(cat)) {
                            level1_values_corrected.push(cat)
                        }
                    })
                    level1_values = level1_values_corrected
                }
            }
        }
        else if (this.props.state.trended) {
            console.log((this.props.state.aggregated_data || {}).level2_data)
            level1_values = Object.keys((this.props.state.aggregated_data || {}).level2_data || {})
            selected_level1 = this.state.selected_level1 || level1_values[0]
            data = (((this.props.state.aggregated_data || {}).level2_data || {})[selected_level1] || {})
            categories = this.props.globalstate.distinctdates

            //correct level1_values if priceband...
            if ((this.props.state.selectedLevel1 || {}).label === 'Price Band') {
                let pricebands_ordered = this.props.globalstate.priceBandOptions.map(pb => pb.label)
                let level1_values_corrected = []
                pricebands_ordered.forEach(cat => {
                    if (level1_values.includes(cat)) {
                        level1_values_corrected.push(cat)
                    }
                })
                level1_values = level1_values_corrected
            }
        }

        let category_ranks = createRankOptions(categories.length, ((this.props.state.selectedLevel1 || {}).label || "") + ' ')
        let selectedRank = this.state.selectedRank || category_ranks[0]

        let metrics = (this.props.state.selectedMetrics || []).map(metric => {
            var units_dollars_type, totalmetrictopull, metrictopull, graphtype, format_type, yaxis, metricname
            let Metric = metric.value

            if (Metric === 'Non-Promoted') {
                units_dollars_type = 'nonpromoted'
                graphtype = 'column'
                format_type = units_dollars
                yaxis = 0
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 1
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Promoted') {
                units_dollars_type = 'promoted'
                graphtype = 'column'
                format_type = units_dollars
                yaxis = 0
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 1
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Incremental') {
                units_dollars_type = 'incremental'
                graphtype = 'column'
                format_type = units_dollars
                yaxis = 0
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 1
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'UnSubsidized Base') {
                units_dollars_type = 'unsubsidizedbase'
                graphtype = 'column'
                format_type = units_dollars
                yaxis = 0
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 1
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Subsidized Base') {
                units_dollars_type = 'subsidizedbase'
                graphtype = 'column'
                format_type = units_dollars
                yaxis = 0
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 1
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Base') {
                units_dollars_type = 'base'
                graphtype = 'column'
                format_type = units_dollars
                yaxis = 0
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 1
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Share of Base') {
                units_dollars_type = 'base'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 'share'
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Share of Subsidized Base') {
                units_dollars_type = 'subsidizedbase'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 'share'
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Share of UnSubsidized Base') {
                units_dollars_type = 'unsubsidizedbase'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 'share'
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Share of Incremental') {
                units_dollars_type = 'incremental'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 'share'
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Share of Promoted') {
                units_dollars_type = 'promoted'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 'share'
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Share of Non-Promoted') {
                units_dollars_type = 'nonpromoted'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric + ' ' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase()
                totalmetrictopull = 'share'
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Promoted Lift') {
                units_dollars_type = 'incremental'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 1
                metricname = Metric + ' (' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase() + ')'
                totalmetrictopull = 'subsidizedbase' + units_dollars
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Market Lift') {
                units_dollars_type = 'incremental'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 1
                metricname = Metric + ' (' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase() + ')'
                totalmetrictopull = 'base' + units_dollars
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Efficiency') {
                units_dollars_type = 'incremental'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric + ' (' + units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase() + ')'
                totalmetrictopull = 'promoted' + units_dollars
                metrictopull = units_dollars_type + units_dollars
            }
            else if (Metric === 'Average Promoted Discount %') {
                units_dollars_type = 'weightedpctdisc_withoutno'
                graphtype = 'line'
                format_type = 'percent'
                yaxis = 2
                metricname = Metric
                totalmetrictopull = 'totalunits_withoutno'
                metrictopull = units_dollars_type
            }

            return { metrictopull, totalmetrictopull, graphtype, format_type, metricname, yaxis }
        })

        function filterArray(array, start, end) {
            let new_array = array.map((value, idx) => {
                if (idx >= start && idx < end) {
                    return value
                }
                else {
                    return null
                }
            }).filter(x => x !== null)
            return new_array
        }

        var categories_to_use = categories
        //if categories.length > 10, filter the array
        if (categories.length > 10) {
            categories_to_use = filterArray(categories, this.state.RankStart, this.state.RankEnd)
        }

        let seriesArray = metrics.map((metric, idx) => {
            let graphcolor = Constant.graph_colors[idx]

            let dataarray = []

            var total_object = {}
            var total = 0
            if (this.props.state.aggregated) {
                if ((this.props.state.aggregated_data || {}).level1_data) {
                    categories.map(category => {
                        total += (data[category] || {})[metric.metrictopull] || 0
                    })
                }
                else {
                    level1_values.map(outerkey => {
                        let total_to_track = 0
                        let dataset = (((this.props.state.aggregated_data || {}).level2_data || {})[outerkey] || {})
                        categories.map(category => {
                            total_to_track += (dataset[category] || {})[metric.metrictopull] || 0
                        })
                        total_object[outerkey] = total_to_track
                    })
                }
            }
            else if (this.props.state.trended) {
                categories.map(category => {
                    let total_to_track = 0
                    level1_values.map(outerkey => {
                        let dataset = (((this.props.state.aggregated_data || {}).level2_data || {})[outerkey] || {})
                        total_to_track += (dataset[category] || {})[metric.metrictopull] || 0
                    })
                    total_object[category] = total_to_track
                })
            }

            console.log(total)
            console.log(total_object)

            if (this.props.state.aggregated) {
                dataarray = categories_to_use.map(category => {
                    if (metric.totalmetrictopull === 1) {
                        return (data[category] || {})[metric.metrictopull] || 0
                    }
                    else if (metric.totalmetrictopull === 'share') {
                        if (this.props.state.aggregated) {
                            if ((this.props.state.aggregated_data || {}).level1_data) {
                                return (((data[category] || {})[metric.metrictopull] || 0) / (total))
                            }
                            else {
                                return (((data[category] || {})[metric.metrictopull] || 0) / (total_object[selected_level1]))
                            }
                        }
                        else {
                            console.log(total_object[category])
                            return (((data[category] || {})[metric.metrictopull] || 0) / (total_object[category]))
                        }
                    }
                    else {
                        return (((data[category] || {})[metric.metrictopull] || 0) / ((data[category] || {})[metric.totalmetrictopull] || 0))
                    }
                })
            }
            else {
                dataarray = categories.sort().map(date => {
                    let dateAdj = Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))
                    if (metric.totalmetrictopull === 1) {
                        return [dateAdj, (data[date] || {})[metric.metrictopull] || 0]
                    }
                    else if (metric.totalmetrictopull === 'share') {
                        return [dateAdj, ((data[date] || {})[metric.metrictopull] || 0) / total_object[date]]
                    }
                    else {
                        return [dateAdj, (((data[date] || {})[metric.metrictopull] || 0) / ((data[date] || {})[metric.totalmetrictopull] || 0))]
                    }
                })
            }

            if (metric.graphtype === 'line' && this.props.state.aggregated) {
                return {
                    name: metric.metricname,
                    data: dataarray,
                    type: metric.graphtype,
                    color: graphcolor,
                    showInLegend: true,
                    yAxis: metric.yaxis,
                    xAxis: 0,
                    format_type: metric.format_type,
                    lineWidth: 0,
                    marker: {
                        enabled: true,
                        radius: 10
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0
                        }
                    },
                }
            }
            else {
                return {
                    name: metric.metricname,
                    data: dataarray,
                    type: metric.graphtype,
                    color: graphcolor,
                    showInLegend: true,
                    yAxis: metric.yaxis,
                    xAxis: 0,
                    format_type: metric.format_type,
                }
            }
        })
        console.log(seriesArray)
        var currencycode = this.props.globalstate.currencycode
        var stackingType = this.props.globalstate.stackingType || 'normal'

        var weeklytimeperiods, axisFormatString, axisText

        if (this.props.globalstate.filter_object.numweeks !== 1) {
            weeklytimeperiods = this.props.globalstate.filter_object.numweeks + " Week Time Periods"
            axisFormatString = '%e. %b'
            axisText = this.props.globalstate.filter_object.numweeks + ' Week Period'
        }
        else {
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Week Ending'
        }

        var tickpositionsarray = this.props.globalstate.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))))
        var stepNum = 1
        var paddingNum = 5
        var fontSizeString = '11px'
        if (tickpositionsarray.length > 26) {
            stepNum = 2
            paddingNum = 10
            fontSizeString = '9px'
        }

        var xAxisArray
        if (this.props.state.aggregated === true) {
            xAxisArray = [
                {
                    title: { text: (this.props.state.selectedLevel1 || {}).label },
                    type: 'category',
                    categories: categories_to_use,
                    tickPositions: null,
                    labels: {
                        rotation: 270,
                        step: stepNum,
                        padding: paddingNum,
                    },
                    dateTimeLabelFormats: {}
                },
            ]
        }
        else {
            xAxisArray = [
                {
                    title: {
                        text: axisText
                    },
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: axisFormatString
                    },
                    //tickInterval: tickIntervalNum
                    tickPositions: tickpositionsarray,
                    labels: {
                        formatter: function () {
                            let dt = new Date(this.value)
                            let offset = dt.getTimezoneOffset() * 60 * 1000
                            let date = new Date(this.value + offset)
                            const options = { year: 'numeric', month: 'short' };
                            const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                            return date.toLocaleDateString(undefined, options2) //.toISOString().substring(0, 10)
                        },
                        rotation: 270,
                        step: stepNum,
                        padding: paddingNum,
                        style: {
                            fontSize: fontSizeString
                        }
                    },
                    events: {
                        setExtremes: function (e) {
                            Highcharts.syncExtremes(e);
                        }
                    }
                }
            ]
        }

        console.log(xAxisArray)
        console.log(this.props.state)
        console.log(categories_to_use)

        return (
            <Fragment>
                {level1_values.length > 0 ?
                    <div>
                        {this.props.state.aggregated ?
                            <span className="formfieldtitle">{'Select A ' + (this.props.state.selectedLevel2 || {}).label}</span>
                            :
                            <span className="formfieldtitle">{'Select A ' + (this.props.state.selectedLevel1 || {}).label}</span>
                        }
                        <Select
                            options={level1_values.map(level1 => {
                                return { 'label': level1, 'value': level1 }
                            })}
                            placeholder={'Select A Level'}
                            onChange={changeSelectedLevel1}
                            value={{ 'label': this.state.selected_level1 || level1_values[0], 'value': this.state.selected_level1 || level1_values[0] }}
                            styles={Constant.dropdownStyles}
                        />
                    </div>
                    :
                    <div />
                }
                {categories.length > 10 && this.props.state.aggregated ?
                    <div>
                        <span className="formfieldtitle">{"Select Rank (Based on " + units_dollars})</span>
                        <Select
                            options={category_ranks}
                            placeholder={'Select Rank'}
                            onChange={(item, position) => changeRank(item, 0)}
                            value={selectedRank}
                        />
                    </div>
                    :
                    <div />
                }
                {
                    Object.keys((this.props.state.aggregated_data || {})).length > 0 ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    height: (9 / 16 * 125) + '%', //16:9 ratio
                                    events: {
                                        redraw: function resize() {
                                        }
                                        , load: function resize() {
                                        }
                                    }
                                },
                                metadata: this.props.globalstate.chartmetadata,
                                caption: {
                                    text: this.props.globalstate.attr_caption_text
                                },
                                title: {
                                    text:
                                        this.props.state.aggregated ?
                                            (this.props.state.selectedLevel2 || {}).label ?
                                                metrics.map(metric => metric.metricname).join(', ')
                                                + ' By '
                                                + (this.props.state.selectedLevel1 || {}).label
                                                + '<br>'
                                                + (this.props.state.selectedLevel2 || {}).label
                                                + ' Selected: '
                                                + (this.state.selected_level1 || level1_values[0])
                                                + this.props.globalstate.chart_title_info_nobrands
                                                :
                                                metrics.map(metric => metric.metricname).join(', ')
                                                + ' By '
                                                + (this.props.state.selectedLevel1 || {}).label
                                                + this.props.globalstate.chart_title_info_nobrands
                                            :
                                            metrics.map(metric => metric.metricname).join(', ')
                                            + '<br>'
                                            + (this.props.state.selectedLevel1 || {}).label
                                            + ' Selected: '
                                            + (this.state.selected_level1 || level1_values[0])
                                            + this.props.globalstate.chart_title_info_nobrands
                                },
                                series: seriesArray,
                                xAxis: xAxisArray,
                                yAxis: [
                                    {
                                        title: { text: stackingType === 'percent' ? 'Percent' : units_dollars.charAt(0).toUpperCase() + units_dollars.substr(1).toLowerCase() },
                                        gridLineColor: "#CCC",
                                        gridLineWidth: 1,
                                        reversedStacks: false,
                                        labels: {
                                            formatter: function () {
                                                function nFormatter(num, digits, axisMax) {
                                                    var si = [
                                                        { value: 1E18, symbol: "E" },
                                                        { value: 1E15, symbol: "P" },
                                                        { value: 1E12, symbol: "T" },
                                                        { value: 1E9, symbol: "B" },
                                                        { value: 1E6, symbol: "M" },
                                                        { value: 1E3, symbol: "k" }
                                                    ], i;

                                                    let symbolForAxis = " "
                                                    let valueForAxis = 1

                                                    for (i = 0; i < si.length; i++) {
                                                        if (axisMax >= si[i].value) {
                                                            symbolForAxis = si[i].symbol
                                                            valueForAxis = si[i].value
                                                            break;
                                                        }
                                                    }

                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()
                                                }

                                                if (stackingType === 'percent') {
                                                    return (this.value / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                }
                                                else {
                                                    if (units_dollars === 'units') {
                                                        return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                                    }
                                                    else {
                                                        let value = nFormatter(this.value, 1, this.axis.max)
                                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                        let symbol = value.substring(value.length - 1)
                                                        return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        title: { text: 'Lift' },
                                        gridLineColor: "#CCC",
                                        gridLineWidth: 1,
                                        reversedStacks: false,
                                        labels: {
                                            formatter: function () {
                                                return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                            }
                                        },
                                        opposite: true
                                    },
                                    {
                                        title: { text: 'Efficiency/Average Promoted Discount Percent/Share' },
                                        gridLineColor: "#CCC",
                                        gridLineWidth: 1,
                                        reversedStacks: false,
                                        labels: {
                                            formatter: function () {
                                                return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                            }
                                        },
                                        opposite: true
                                    },
                                ],
                                plotOptions: {
                                    series: {
                                        dataLabels: {
                                            enabled: true,
                                            formatter: function () {
                                                function nFormatter(num, digits) {
                                                    var si = [
                                                        { value: 1E18, symbol: "E" },
                                                        { value: 1E15, symbol: "P" },
                                                        { value: 1E12, symbol: "T" },
                                                        { value: 1E9, symbol: "B" },
                                                        { value: 1E6, symbol: "M" },
                                                        { value: 1E3, symbol: "k" }
                                                    ], i;
                                                    for (i = 0; i < si.length; i++) {
                                                        if (num >= si[i].value) {
                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                        }
                                                        else if (num < 1000) {
                                                            return num.toFixed(0)
                                                        }
                                                    }
                                                    return num.toString();
                                                }

                                                if (this.series.userOptions.format_type === 'percent') {
                                                    return (this.y !== 0) ? this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ""
                                                }
                                                else if (this.series.userOptions.format_type === 'dollars') {

                                                    let value = nFormatter(this.y, 1)
                                                    let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                    let symbol = value.substring(value.length - 1)
                                                    return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol

                                                    //return (this.y !== 0) ? this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0, minimumFractionDigits: 0 }) : ""
                                                }
                                                else {
                                                    return nFormatter(this.point.y, 1).toLocaleString()
                                                    //return (this.y !== 0) ? this.y.toLocaleString() : ""
                                                }
                                            },
                                            color: 'black',
                                            borderRadius: 5,
                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                            borderWidth: 1,
                                            borderColor: '#d6d7d8',
                                            padding: 2,
                                            style: {
                                                textOutline: 'none',
                                                fontSize: '20px'
                                            },
                                        }
                                        , connectNulls: false
                                    },
                                    waterfall: {
                                        borderColor: '#ffffff'
                                    },
                                    column: {
                                        grouping: false,
                                        stacking: stackingType,
                                    }
                                },
                                tooltip: {
                                    formatter: function () {
                                        var tooltiptitle = this.series.name
                                        if (this.series.userOptions.format_type === 'percent') {
                                            return '<span> '
                                                + tooltiptitle
                                                + ' <br> '
                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                + '</span>'
                                        }
                                        else if (this.series.userOptions.format_type === 'dollars') {
                                            return '<span> '
                                                + tooltiptitle
                                                + ' <br> '
                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                + ' <br> '
                                                + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                + '</span>'
                                        }
                                        else {
                                            return '<span> '
                                                + tooltiptitle
                                                + ' <br> '
                                                + this.y.toLocaleString()
                                                + ' <br> '
                                                + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                + '</span>'
                                        }
                                    }
                                },
                                exporting: {
                                    showTable: this.props.globalstate.showDataTable,
                                    tableCaption: false
                                },
                            }}
                        />
                        :
                        <Fragment/>
                }
            </Fragment>
        )
    }
}
