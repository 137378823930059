import React, { Component, Fragment } from 'react';
import { FlexibleSegmentTable } from './FlexibleSegmentTable'

export class FlexibleSegmentTableView extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        return !! nextProps.state.render_update
    }

    render() {
        console.log(this.props)
        return (
            <div style={{  marginBottom: '25px', overflowY: 'auto', maxHeight: '1000px' }}>
                <h5>{this.props.state.segment1label} Performance</h5>
                <FlexibleSegmentTable
                    state={this.props.state}
                    updateData={this.props.updateData}
                    handleChange_twoyago={this.props.handleChange_twoyago}
                    changeViewFilters={this.props.changeViewFilters}
                    changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    openFlexibleSegmentTableMenu={this.props.openFlexibleSegmentTableMenu}
                    closeFlexibleSegmentTableMenu={this.props.closeFlexibleSegmentTableMenu}
                    downloadFlexibleSegmentTableData={this.props.downloadFlexibleSegmentTableData}
                />
            </div>
        )
    }
}
