import CreateWeeksNew from '../Utilities/CreateWeeks'
import CreateSelectionInfo from '../Utilities/CreateSelectionInfo'
import CreateFilters from '../Utilities/CreateFilters'
import adjustWeeklyDataObject from '../Utilities/AdjustWeeklyDataObject'
import adjustWeeklyDataObjectOutlets from '../Utilities/AdjustOutletWeeklyDataObject'

export default async function evaluateRows_newAPIs_consolidated(filter_object, exclude_array_filters = [false, false], exclude_array = [false, false], source = 'filters') {
    this.setState({ render_update: false, ALRvsROM_loading: false })
    var startTime = new Date()
    var weeks_info, selectionInfo
    if (source === 'update') {
        weeks_info = this.state.weeks_info
    } else {
        weeks_info = CreateWeeksNew(filter_object, this.state.times_data, this.state.datasettimeagg)
    }

    if (source === 'update') {
        selectionInfo = this.state.selectionInfo
    } else {
        selectionInfo = CreateSelectionInfo(filter_object, weeks_info, this.state, exclude_array)
    }

    let priceBandOptions = this.state.priceBandOptions

    let axisFormatString = weeks_info['axisFormatString']
    let axisText = weeks_info['axisText']
    let datemapping_new_withend = weeks_info['datemapping_new_withend']
    let datemapping_new_withend_prior = weeks_info['datemapping_new_withend_prior']
    let datemapping_new_withend_last = weeks_info['datemapping_new_withend_last']
    let datemapping_new_withend_threeyear = weeks_info['datemapping_new_withend_threeyear']
    let groupeddates = weeks_info['groupeddates']
    let groupeddates_calc = weeks_info['groupeddates_calc']
    let groupeddates_prior = weeks_info['groupeddates_prior']
    let groupeddates_last = weeks_info['groupeddates_last']
    let groupeddates_threeyears = weeks_info['groupeddates_threeyears']
    let prior_start = weeks_info['prior_start']
    let showYOYViews = weeks_info['showYOYViews']
    let showLastViews = weeks_info['showLastViews']
    let showThreeYearViews = weeks_info['showThreeYearViews']
    let weeklytimeperiods = weeks_info['weeklytimeperiods']
    let xDateFormatString = weeks_info['xDateFormatString']
    let timeperiodlabels = weeks_info['timeperiodlabels']
    let stepNum = weeks_info['stepNum']
    let paddingNum = weeks_info['paddingNum']
    let fontSizeString = weeks_info['fontSizeString']

    // Create Filters
    console.log('Creating time-based filters')
    let filters_totaltimeperiod_with_exclude = CreateFilters(filter_object, exclude_array, weeks_info, 'totaltimeperiod', priceBandOptions)
    var filters_totaltimeperiod, filters_threeyearsago, filters_twoyearsago, filters_prioryear, filters_currentyear

    // Don't use exclude brands/items flag if applying slicers
    let create_filter_exclude_array = (source === 'update') ? [false, false] : exclude_array
    filters_totaltimeperiod = CreateFilters(filter_object, create_filter_exclude_array, weeks_info, 'totaltimeperiod', priceBandOptions)
    filters_threeyearsago = CreateFilters(filter_object, create_filter_exclude_array, weeks_info, 'threeyearsago', priceBandOptions)
    filters_twoyearsago = CreateFilters(filter_object, create_filter_exclude_array, weeks_info, 'twoyearsago', priceBandOptions)
    filters_prioryear = CreateFilters(filter_object, create_filter_exclude_array, weeks_info, 'prioryear', priceBandOptions)
    filters_currentyear = CreateFilters(filter_object, create_filter_exclude_array, weeks_info, 'currentyear', priceBandOptions)
    console.log('Filters created')

    console.log(this.state.filterPause_reportViews)
    console.log(source)
    console.log(this.state.slicers_not_applied)

    // If paused, only update the dropdowns...
    if (this.state.filterPause_reportViews === 'pause' && source !== 'initialloading') {
        console.log('Running Updates Only On Dropdowns')
        await this.updateDropdowns(filters_totaltimeperiod_with_exclude)
        this.setState({ slicers_not_applied: true, isLoaded_Filters: true })
 
        var numsecs = (new Date() - startTime) / 1000
        console.log('It Took ' + numsecs + ' seconds to pull the data to update the drop-downs')
    } else {
        console.log('Running Updates')
        await this.getTableInfo(filters_totaltimeperiod)
        // Dropdowns
        await this.updateDropdowns(filters_totaltimeperiod_with_exclude)

        let current_123ago_filters_list = [filters_currentyear, filters_prioryear, filters_twoyearsago, filters_threeyearsago]

        // First load all the data that we load no matter what
        let promise_list = [
            () => this.updateCommonItems(filters_totaltimeperiod),                                // CommonOutletItems
            () => this.updateAggregatedData(filters_totaltimeperiod),                             // Aggregated - Total Time Period
            () => this.updateAggregatedDataOutlet(filters_totaltimeperiod),                       // Aggregated - Total Time Period
            () => this.updateBrandData(filters_totaltimeperiod),                                  // Brands
            () => this.updateItemData(filters_totaltimeperiod),                                   // Items
            () => this.updateChannelData(filters_totaltimeperiod),                                // Channels
            () => this.updateOutletDiscountBandData(filters_totaltimeperiod, 'totaltimeperiod'),  // OutletDiscountBand
            () => this.ASPWaterfallCalc(filters_currentyear, filters_prioryear),                  // Item ASP Waterfall

            // Weekly Data
            () => this.updateWeeklyData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateWeeklyData(filters_threeyearsago, 'threeyearsago'),
            () => this.updateWeeklyData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateWeeklyData(filters_prioryear, 'prioryear'),

            // Weekly Brand
            () => this.updateWeeklyBrandData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateWeeklyBrandData(filters_threeyearsago, 'threeyearsago'),
            () => this.updateWeeklyBrandData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateWeeklyBrandData(filters_prioryear, 'prioryear'),

            // Weekly Channel
            () => this.updateWeeklyChannelData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateWeeklyChannelData(filters_threeyearsago, 'threeyearsago'),
            () => this.updateWeeklyChannelData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateWeeklyChannelData(filters_prioryear, 'prioryear'),

            // Item Weekly Data
            () => this.updateItemWeeklyData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateItemWeeklyData(filters_threeyearsago, 'threeyearsago'),
            () => this.updateItemWeeklyData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateItemWeeklyData(filters_prioryear, 'prioryear'),

            // Outlet Brand Week Data
            () => this.updateOutletBrandWeeklyData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateOutletBrandWeeklyData(filters_threeyearsago, 'threeyearsago'),
            () => this.updateOutletBrandWeeklyData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateOutletBrandWeeklyData(filters_prioryear, 'prioryear'),

            // Outlet Channel Week Data
            () => this.updateOutletChannelWeeklyData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateOutletChannelWeeklyData(filters_threeyearsago, 'threeyearsago'),
            () => this.updateOutletChannelWeeklyData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateOutletChannelWeeklyData(filters_prioryear, 'prioryear'),

            // Outlet Week Data
            () => this.updateWeeklyOutletData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateWeeklyOutletData(filters_threeyearsago, 'threeyearsago'),
            () => this.updateWeeklyOutletData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateWeeklyOutletData(filters_prioryear, 'prioryear'),

            // Discount Bands
            () => this.updateDiscountBandData(filters_totaltimeperiod, 'totaltimeperiod'),
            () => this.updateDiscountBandData(filters_currentyear, 'currentyear'),
            () => this.updateDiscountBandData(filters_prioryear, 'prioryear'),
            () => this.updateDiscountBandData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateDiscountBandData(filters_threeyearsago, 'threeyearsago'),

            // Scorecard Data
            () => this.updateScorecardData(filters_currentyear, 'currentyear'),
            () => this.updateScorecardData(filters_prioryear, 'prioryear'),
            () => this.updateScorecardData(filters_twoyearsago, 'twoyearsago'),
            () => this.updateScorecardData(filters_threeyearsago, 'threeyearsago')
        ]

        // For the visualzations and controls listed below, we still have to figure out if there is a 1-to-1 mapping
        // from the visualization being present to the data being needed

        // 'Base and Incremental'                             BaseIncrementalPromoNonPromoAggregate - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Lift, Efficiency & Discount'                      LiftEffDiscAggregate_New              - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Discount Dollars and Payback Ratio - Aggregate'   DiscDollar_PBR                        - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Fair Share of Subsidization'                      Fair_Share                            - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Payback Ratio and Efficiency by Discount Range'   iftEffPBRByDiscBandAggregate          - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Promotion Performance'                            BaseIncrementalTrended                - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Lift and Efficiency'                              iftAndEfficiencyTrended               - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Discount Dollars and Payback Ratio'               DiscDollarsAndPBRTrended              - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Base Velocity'                                    BaseVelocity                          - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Share by Brands'                                  ShareTrended                          - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Units by Brand/Item'                              AreaChart                             - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Discount Range'                                   DiscountRangeYOY                      - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Total Volume'                                     VolumeYOY                             - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Base and Incremental Units'                       ScoreCardBaseInc                      - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Promoted and Non-Promoted Units'                  ScoreCardPromoNonPromo                - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Sales Metrics'                                    ScoreCardSalesMetrics                 - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Pricing Metrics'                                  ScoreCardPricingMetrics               - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Promotion Metrics'                                ScoreCardPromotionMetrics             - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Metrics Changes'                                  ALRvsROM_MetricChanges                - in default_alr_order_all, default_alr_order_retail, default_tmm_order
        // 'Average Promoted Discount Pct Trended'            AvgPromotedDiscountTrended            - in                                                  default_tmm_order
        // 'Average Promoted Discount Pct With Dollar Share'  AvgPromotedDiscountWithDollarShare    - in                                                  default_tmm_order
        // 'Depth of Discount'                                DepthOfDiscount                       - in                                                  default_tmm_order
        // 'Promoted/Non-Promoted Price'                      PromotedNonPromotedPriceByWeek        - in                                                  default_tmm_order
        // 'Base/Average Selling Price'                       ASPBasePriceByWeek                    - in                                                  default_tmm_order
        // 'Promoted Units/Dollars'                           PromotedUnitsDollarsByWeek            - in                                                  default_tmm_order
        // 'Metrics Changes With Market Share'                MetricChangesWithMktShare             - in                                                  default_tmm_order
        // 'Item Change Information'                          ItemYOYInformation                    - in default_alr_order_all,                           default_tmm_order
        // 'Average Selling Price Distribution by item'       ItemASPDistRetailerROM                - in default_alr_order_all
        // 'ALR vs ROM'                                       ALRvsROMGraph                         - in default_alr_order_all, default_alr_order_retail
        // 'Average Promoted Discount % Trended'              AvgDiscPercentALRvsROM                - in default_alr_order_all, default_alr_order_retail
        // 'Average Selling '                                 ASPWeeklyALRvsROM                     - in default_alr_order_all, default_alr_order_retail
        // 'Depth of Discount by Discount Range'              DepthOfDiscountByALR                  - in default_alr_order_all, default_alr_order_retail
        // 'Base and Incremental ALR'                         ALRvsROM_BaseIncremental              - in default_alr_order_all, default_alr_order_retail
        // 'Lift, Efficiency, and Discount'                   ALRvsROM_LiftEfficiency               - in default_alr_order_all, default_alr_order_retail
        // 'Metric Comparisons to ROM'                        ALRvsROM_MetricComparisons            - in default_alr_order_all, default_alr_order_retail
        // 'Top/Bottom Performing Weeks'                      ALRvsROM_TopBottomWeeks               - in default_alr_order_all, default_alr_order_retail
        // 'Promo Support and Share'                          PromoSupportAndShareImpactGraph       - in                        default_alr_order_retail
        // 'Weekly Performance'                               FlexibleWeeklyTableView               - in                        default_alr_order_retail
        // 'Segment Performance'                              FlexibleSegmentTableView              - in                        default_alr_order_retail
        // 'Brand Performance'                                FlexibleBrandTableView                - in                        default_alr_order_retail
        // 'Base and Incremental by Attribute'                BaseIncrementalPromoNonPromoAggregate - in

        // Data loaded in the cases below is continent on a specific visualizations being viewed
        const visualization_set = this.state.visualization_set
        if (visualization_set.has('Weekly Performance')) {  // This is the data for view FlexibleWeeklyTableView
            promise_list.push(() => this.updateLevel4WeeklyData(current_123ago_filters_list, ['rowdate', 'segment1_name', 'brand', 'outlet']))
        }
        if (visualization_set.has('Segment Performance')) {  // This is the data for view FlexibleSegmentTableView
            promise_list.push(() => this.updateLevel4SegmentData(current_123ago_filters_list, ['segment1_name', 'segment2_name', 'brand', 'outlet']))
        }
        if (visualization_set.has('Brand Performance')) {  // This is the data for view FlexibleBrandTableView
            promise_list.push(() => this.updateLevel4BrandData(current_123ago_filters_list, ['brand', 'segment1_name', 'segment2_name', 'outlet']))
        }

        // Run all the data pulls in parallel
        await Promise.all(promise_list.map(f => f()))

//        // FIXME TEMP Run comparison code
//        await Promise.all([ // FIXME TEMP
//            this.tempCall(filters_currentyear, 'currentyear', ['rowdate', 'segment1_name', 'brand', 'outlet']),     // FIXME TEMP
//            this.tempCall(filters_prioryear, 'prioryear', ['rowdate', 'segment1_name', 'brand', 'outlet']),         // FIXME TEMP
//            this.tempCall(filters_twoyearsago, 'twoyearsago', ['rowdate', 'segment1_name', 'brand', 'outlet']),     // FIXME TEMP
//            this.tempCall(filters_threeyearsago, 'threeyearsago', ['rowdate', 'segment1_name', 'brand', 'outlet']), // FIXME TEMP
//        ]) // FIXME TEMP

        var numsecs = (new Date() - startTime) / 1000
        console.log('It Took ' + numsecs + ' seconds to pull the data')

        startTime = new Date()

        if (source !== 'update') {
            console.log('clearing dropdowns')
            await this.clearDropdowns()
        }

        let weekly_data = this.state.weekly_data
        let weekly_data_prior = this.state.weekly_data_prior
        let weekly_data_last = this.state.weekly_data_last
        let weekly_data_threeyears = this.state.weekly_data_threeyears

        weekly_data['Total Category'] = this.state.weekly_data_totalcat_new
        weekly_data['Total Selection'] = this.state.weekly_data_totalsel_new

        weekly_data_prior['Total Category'] = this.state.weekly_data_totalcat_prior_new
        weekly_data_prior['Total Selection'] = this.state.weekly_data_totalsel_prior_new

        weekly_data_last['Total Category'] = this.state.weekly_data_totalcat_last_new
        weekly_data_last['Total Selection'] = this.state.weekly_data_totalsel_last_new

        weekly_data_threeyears['Total Category'] = this.state.weekly_data_totalcat_threeyears_new
        weekly_data_threeyears['Total Selection'] = this.state.weekly_data_totalsel_threeyears_new

        let item_data_weekly = this.state.item_data_weekly
        let item_data_weekly_prior = this.state.item_data_weekly_prior
        let item_data_weekly_last = this.state.item_data_weekly_last
        let item_data_weekly_threeyears = this.state.item_data_weekly_threeyears

        item_data_weekly['Total Category'] = this.state.weekly_data_totalcat_new
        item_data_weekly['Total Selection'] = this.state.weekly_data_totalsel_new

        item_data_weekly_prior['Total Category'] = this.state.weekly_data_totalcat_prior_new
        item_data_weekly_prior['Total Selection'] = this.state.weekly_data_totalsel_prior_new

        item_data_weekly_last['Total Category'] = this.state.weekly_data_totalcat_last_new
        item_data_weekly_last['Total Selection'] = this.state.weekly_data_totalsel_last_new

        item_data_weekly_threeyears['Total Category'] = this.state.weekly_data_totalcat_threeyears_new
        item_data_weekly_threeyears['Total Selection'] = this.state.weekly_data_totalsel_threeyears_new

        let outlet_data_weekly_brand = this.state.outlet_data_weekly_brand
        let outlet_data_weekly_brand_prior = this.state.outlet_data_weekly_brand_prior
        let outlet_data_weekly_brand_last = this.state.outlet_data_weekly_brand_last
        let outlet_data_weekly_brand_threeyears = this.state.outlet_data_weekly_brand_threeyears

        let outlet_data_weekly_geo = this.state.outlet_data_weekly_geo
        let outlet_data_weekly_geo_prior = this.state.outlet_data_weekly_geo_prior
        let outlet_data_weekly_geo_last = this.state.outlet_data_weekly_geo_last
        let outlet_data_weekly_geo_threeyears = this.state.outlet_data_weekly_geo_threeyears

        let weekly_data_geo = this.state.weekly_data_geo
        let weekly_data_prior_geo = this.state.weekly_data_prior_geo
        let weekly_data_last_geo = this.state.weekly_data_last_geo
        let weekly_data_threeyears_geo = this.state.weekly_data_threeyears_geo

        let outlet_data_weekly = this.state.outlet_data_weekly
        let outlet_data_weekly_prior = this.state.outlet_data_weekly_prior
        let outlet_data_weekly_last = this.state.outlet_data_weekly_last
        let outlet_data_weekly_threeyears = this.state.outlet_data_weekly_threeyears

        weekly_data_geo['All Channels'] = this.state.weekly_data_totalcat_new
        //weekly_data_geo['Total Selection'] = this.state.weekly_data_totalsel_new

        weekly_data_prior_geo['All Channels'] = this.state.weekly_data_totalcat_prior_new
        //weekly_data_prior_geo['Total Selection'] = this.state.weekly_data_totalsel_prior_new

        weekly_data_last_geo['All Channels'] = this.state.weekly_data_totalcat_last_new
        //weekly_data_last_geo['Total Selection'] = this.state.weekly_data_totalsel_last_new

        weekly_data_threeyears_geo['All Channels'] = this.state.weekly_data_totalcat_threeyears_new
        //weekly_data_threeyears_geo['Total Selection'] = this.state.weekly_data_totalsel_threeyears_new

        let weekly_data_new = adjustWeeklyDataObject(weekly_data,
            Object.keys(weekly_data),
            datemapping_new_withend,
            Object.keys(datemapping_new_withend)
        )

        let weekly_data_prior_new = adjustWeeklyDataObject(weekly_data_prior,
            Object.keys(weekly_data_prior),
            datemapping_new_withend_prior,
            Object.keys(datemapping_new_withend_prior)
        )

        let weekly_data_last_new = adjustWeeklyDataObject(weekly_data_last,
            Object.keys(weekly_data_last),
            datemapping_new_withend_last,
            Object.keys(datemapping_new_withend_last)
        )

        let weekly_data_threeyears_new = adjustWeeklyDataObject(weekly_data_threeyears,
            Object.keys(weekly_data_threeyears),
            datemapping_new_withend_threeyear,
            Object.keys(datemapping_new_withend_threeyear)
        )

        let item_data_weekly_new = adjustWeeklyDataObject(item_data_weekly,
            Object.keys(item_data_weekly),
            datemapping_new_withend,
            Object.keys(datemapping_new_withend)
        )

        let item_data_weekly_prior_new = adjustWeeklyDataObject(item_data_weekly_prior,
            Object.keys(item_data_weekly_prior),
            datemapping_new_withend_prior,
            Object.keys(datemapping_new_withend_prior)
        )

        let item_data_weekly_last_new = adjustWeeklyDataObject(item_data_weekly_last,
            Object.keys(item_data_weekly_last),
            datemapping_new_withend_last,
            Object.keys(datemapping_new_withend_last)
        )

        let item_data_weekly_threeyears_new = adjustWeeklyDataObject(item_data_weekly_threeyears,
            Object.keys(item_data_weekly_threeyears),
            datemapping_new_withend_threeyear,
            Object.keys(datemapping_new_withend_threeyear)
        )

        let weekly_data_geo_new = adjustWeeklyDataObject(weekly_data_geo,
            Object.keys(weekly_data_geo),
            datemapping_new_withend,
            Object.keys(datemapping_new_withend)
        )

        let weekly_data_prior_geo_new = adjustWeeklyDataObject(weekly_data_prior_geo,
            Object.keys(weekly_data_prior_geo),
            datemapping_new_withend_prior,
            Object.keys(datemapping_new_withend_prior)
        )

        let weekly_data_last_geo_new = adjustWeeklyDataObject(weekly_data_last_geo,
            Object.keys(weekly_data_last_geo),
            datemapping_new_withend_last,
            Object.keys(datemapping_new_withend_last)
        )

        let weekly_data_threeyears_geo_new = adjustWeeklyDataObject(weekly_data_threeyears_geo,
            Object.keys(weekly_data_threeyears_geo),
            datemapping_new_withend_threeyear,
            Object.keys(datemapping_new_withend_threeyear)
        )

        let outlet_data_weekly_brand_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_brand,
            Object.keys(outlet_data_weekly_brand),
            this.state.brands_sorted,
            datemapping_new_withend,
            Object.keys(datemapping_new_withend)
        )

        let outlet_data_weekly_brand_prior_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_brand_prior,
            Object.keys(outlet_data_weekly_brand_prior),
            this.state.brands_sorted,
            datemapping_new_withend_prior,
            Object.keys(datemapping_new_withend_prior)
        )

        let outlet_data_weekly_brand_last_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_brand_last,
            Object.keys(outlet_data_weekly_brand_last),
            this.state.brands_sorted,
            datemapping_new_withend_last,
            Object.keys(datemapping_new_withend_last)
        )

        let outlet_data_weekly_brand_threeyears_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_brand_threeyears,
            Object.keys(outlet_data_weekly_brand_threeyears),
            this.state.brands_sorted,
            datemapping_new_withend_threeyear,
            Object.keys(datemapping_new_withend_threeyear)
        )

        let outlet_data_weekly_geo_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_geo,
            Object.keys(outlet_data_weekly_geo),
            this.state.geos,
            datemapping_new_withend,
            Object.keys(datemapping_new_withend)
        )

        let outlet_data_weekly_geo_prior_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_geo_prior,
            Object.keys(outlet_data_weekly_geo_prior),
            this.state.geos,
            datemapping_new_withend_prior,
            Object.keys(datemapping_new_withend_prior)
        )

        let outlet_data_weekly_geo_last_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_geo_last,
            Object.keys(outlet_data_weekly_geo_last),
            this.state.geos,
            datemapping_new_withend_last,
            Object.keys(datemapping_new_withend_last)
        )

        let outlet_data_weekly_geo_threeyears_new = adjustWeeklyDataObjectOutlets(outlet_data_weekly_geo_threeyears,
            Object.keys(outlet_data_weekly_geo_threeyears),
            this.state.geos,
            datemapping_new_withend_threeyear,
            Object.keys(datemapping_new_withend_threeyear)
        )

        let outlet_data_weekly_new = adjustWeeklyDataObject(outlet_data_weekly,
            this.state.outlets,
            datemapping_new_withend,
            Object.keys(datemapping_new_withend)
        )

        let outlet_data_weekly_prior_new = adjustWeeklyDataObject(outlet_data_weekly_prior,
            this.state.outlets,
            datemapping_new_withend_prior,
            Object.keys(datemapping_new_withend_prior)
        )

        let outlet_data_weekly_last_new = adjustWeeklyDataObject(outlet_data_weekly_last,
            this.state.outlets,
            datemapping_new_withend_last,
            Object.keys(datemapping_new_withend_last)
        )

        let outlet_data_weekly_threeyears_new = adjustWeeklyDataObject(outlet_data_weekly_threeyears,
            this.state.outlets,
            datemapping_new_withend_threeyear,
            Object.keys(datemapping_new_withend_threeyear)
        )

        this.state.outlets.forEach(outlet => {
            // Set to Empty If Not Existing -- Brand
            outlet_data_weekly_brand_new[outlet] = outlet_data_weekly_brand_new[outlet] || {}
            outlet_data_weekly_brand_prior_new[outlet] = outlet_data_weekly_brand_prior_new[outlet] || {}
            outlet_data_weekly_brand_last_new[outlet] = outlet_data_weekly_brand_last_new[outlet] || {}
            outlet_data_weekly_brand_threeyears_new[outlet] = outlet_data_weekly_brand_threeyears_new[outlet] || {}
            // Set to Empty If Not Existing -- Channel
            outlet_data_weekly_geo_new[outlet] = outlet_data_weekly_geo_new[outlet] || {}
            outlet_data_weekly_geo_prior_new[outlet] = outlet_data_weekly_geo_prior_new[outlet] || {}
            outlet_data_weekly_geo_last_new[outlet] = outlet_data_weekly_geo_last_new[outlet] || {}
            outlet_data_weekly_geo_threeyears_new[outlet] = outlet_data_weekly_geo_threeyears_new[outlet] || {}
            // Set to Total Selection -- Current
            outlet_data_weekly_brand_new[outlet]['Total Category'] = this.state.weekly_data_totalcat_new
            outlet_data_weekly_brand_new[outlet]['Total Selection'] = this.state.weekly_data_totalsel_new
            outlet_data_weekly_geo_new[outlet]['All Channels'] = this.state.weekly_data_totalcat_new
            // Set to Total Selection -- Prior
            outlet_data_weekly_brand_prior_new[outlet]['Total Category'] = this.state.weekly_data_totalcat_prior_new
            outlet_data_weekly_brand_prior_new[outlet]['Total Selection'] = this.state.weekly_data_totalsel_prior_new
            outlet_data_weekly_geo_prior_new[outlet]['All Channels'] = this.state.weekly_data_totalcat_prior_new
            // Set to Total Selection -- Two Years Ago
            outlet_data_weekly_brand_last_new[outlet]['Total Category'] = this.state.weekly_data_totalcat_last_new
            outlet_data_weekly_brand_last_new[outlet]['Total Selection'] = this.state.weekly_data_totalsel_last_new
            outlet_data_weekly_geo_last_new[outlet]['All Channels'] = this.state.weekly_data_totalcat_last_new
            // Set to Total Selection -- Three Years Ago
            outlet_data_weekly_brand_threeyears_new[outlet]['Total Category'] = this.state.weekly_data_totalcat_threeyears_new
            outlet_data_weekly_brand_threeyears_new[outlet]['Total Selection'] = this.state.weekly_data_totalsel_threeyears_new
            outlet_data_weekly_geo_threeyears_new[outlet]['All Channels'] = this.state.weekly_data_totalcat_threeyears_new
        })

        var metrickey = 'totalunits'

        if (this.state.indexSwitchCheckDollars) {
            metrickey = 'totaldollars'
        }

        var selectedBrand = 'Total Selection'
        var selectedBrandLabel = 'Total Selection'

        var distinctdates = groupeddates
        var distinctdates_forcalc = groupeddates_calc // alldates
        var distinctdates_prior = groupeddates_prior // alldates_prior
        var distinctdates_last = groupeddates_last // alldates_prior
        var distinctdates_threeyears = groupeddates_threeyears

        console.log('Setting State')
        this.setState({
            weekly_data: weekly_data_new,
            weekly_data_prior: weekly_data_prior_new,
            weekly_data_last: weekly_data_last_new,
            weekly_data_threeyears: weekly_data_threeyears_new,
            item_data_weekly: item_data_weekly_new,
            item_data_weekly_prior: item_data_weekly_prior_new,
            item_data_weekly_last: item_data_weekly_last_new,
            item_data_weekly_threeyears: item_data_weekly_threeyears_new,
            weekly_data_geo: weekly_data_geo_new,
            weekly_data_prior_geo: weekly_data_prior_geo_new,
            weekly_data_last_geo: weekly_data_last_geo_new,
            weekly_data_threeyears_geo: weekly_data_threeyears_geo_new,
            outlet_data_weekly_brand: outlet_data_weekly_brand_new,
            outlet_data_weekly_brand_prior: outlet_data_weekly_brand_prior_new,
            outlet_data_weekly_brand_last: outlet_data_weekly_brand_last_new,
            outlet_data_weekly_brand_threeyears: outlet_data_weekly_brand_threeyears_new,
            outlet_data_weekly_geo: outlet_data_weekly_geo_new,
            outlet_data_weekly_geo_prior: outlet_data_weekly_geo_prior_new,
            outlet_data_weekly_geo_last: outlet_data_weekly_geo_last_new,
            outlet_data_weekly_geo_threeyears: outlet_data_weekly_geo_threeyears_new,
            outlet_data_weekly: outlet_data_weekly_new,
            outlet_data_weekly_prior: outlet_data_weekly_prior_new,
            outlet_data_weekly_last: outlet_data_weekly_last_new,
            outlet_data_weekly_threeyears: outlet_data_weekly_threeyears_new
        })

        this.setState({
            numsecs: numsecs
            , numruns: this.state.numruns + 1
            , numrowevals: this.state.numrowevals += 1
            , selectedLevel: this.state.selectedLevel || { 'label': 'Total', 'value': 'Total' }
            , selectedLevel_BaseInc: this.state.selectedLevel_BaseInc || { 'label': 'Total', 'value': 'Total' }
            , selectedMetric: this.state.selectedMetric || { 'label': 'Base', 'value': 'Base' }
            , selectedMetric_Area: this.state.selectedMetric_Area || { 'label': 'Total', 'value': 'total' }
            , AreaKey: metrickey
            , selectedBrand
            , selectedBrandLabel
            , distinctdates
            , distinctdates_forcalc
            , distinctdates_prior
            , distinctdates_last
            , distinctdates_threeyears
            , showYOYViews
            , showLastViews
            , showThreeYearViews
            , xAxisStepNum: stepNum
            , xAxisPadNum: paddingNum
            , xAxisFontSizeString: fontSizeString
            , xAxisText: axisText
            , timeperiodlabels: timeperiodlabels
            , xDateFormatString: xDateFormatString
            , xAxisLabelFormatString: axisFormatString
            , weeklytimeperiods: weeklytimeperiods
            , prior_start
            , selectionInfo
            , weeks_info
            , filterPause_reportViews: 'pause'
            , slicers_not_applied: false
        }, async () => await this.CreateChartMetadata(filter_object, exclude_array, priceBandOptions))

        numsecs = (new Date() - startTime) / 1000
        console.log('It Took ' + numsecs + ' seconds to iterate through the rows')

        // Run the comparison...
        if (this.state.g1definition && this.state.g2definition) {
            this.runcomparison_apis()
        }

        if (source !== 'initialloading') {
            this.setState({ isLoaded: true })
        }

        if (source !== 'update') {
            this.setState({ render_update: true })
        }
    }
}
