export default function handleToggle_stackingType() {

    let stackingType_checked_new = !this.state.stackingType_checked
    var stackingType_new
    if (stackingType_checked_new) {
        stackingType_new = 'percent'
    }
    else {
        stackingType_new = 'normal'
    }

    this.setState({ stackingType_checked: stackingType_checked_new, stackingType: stackingType_new })

}