import React, { Component } from 'react';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import { Layout } from '../Layout';
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import * as Constant from '../constants'
import FetchConfigWithDefault from './Functions/Utilities/FetchConfigWithDefault'
import FetchDataWithErrorHandling from './Functions/Utilities/FetchDataWithErrorHandling'
import selectColourStyles from '../styles/SelectColourStyles'

export class PDTListofAllReports extends Component {
    static displayName = PDTListofAllReports.name;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            reports: [],
            datamap: {},
            countrymap: {},
            industrymap: {},
            store_group_map: {},
            errorAlert: false,
            report_favorites: [],
            header_sort_active: [true, false, false, false, false, false],
            header_sort_direction: ['asc', 'asc', 'asc', 'asc', 'asc', 'asc'],
            showBackground: true,
            setAPIRequestsFailed: false,
            filters: {
                'datasets': ['All'],
                'countries': ['All'],
                'industries': ['All'],
                'storegroups': ['All']
            }
        };
    }

    async componentDidMount() {
        let appname = Constant.whereamilist[3]

        this.FetchConfigWithDefault = FetchConfigWithDefault.bind(this)
        this.FetchDataWithErrorHandling = FetchDataWithErrorHandling.bind(this)

        let my_results = await this.FetchConfigWithDefault([
            { 'url': Constant.baseurl + "account/isauthenticated", 'default': {} },
        ], 'AllReports.js componentDidMount()', 'User Authentication Status')

        if (my_results['ErrorCount'] > 0) {
            console.log('Bailing out of report information fetch #1 in componentDidMount()')
            this.setState({
                isLoaded: true,
                showBackground: false,
                setAPIRequestsFailed: true
            });
            return
        }

        let userdata = my_results['ReturnList'][0]
        console.log(userdata)
        let userRole_data = userdata.userRole || []
        let userRole = userRole_data[0] || "User"
        console.log(userRole)

        let requests = [
            { 'url': Constant.baseapiurl_gen + "lookups/Countries", 'default': {} },
            { 'url': Constant.baseapiurl + "ReportSettings/Favorites", 'default': {} },
            { 'url': Constant.baseapiurl + "Datasets/DatasetDetails", 'default': {} },
            { 'url': Constant.baseapiurl + "ReportSettings/ReportDetails", 'default': {} },
        ]

        // If the user has a role of Admin or Power User, then run the DatasetDetails?env=staging, otherwise set datasetdetailsdata_staging = []
        if (userRole !== "User") {
            requests.push({ 'url': Constant.baseapiurl + "Datasets/DatasetDetails?env=staging", 'default': {} })
        }

        my_results = await this.FetchConfigWithDefault(requests, 'AllReports.js componentDidMount()', 'info for all reports')

        if (my_results['ErrorCount'] > 0) {
            console.log('Bailing out of report information fetch #2 in componentDidMount()')
            this.setState({
                isLoaded: true,
                showBackground: false,
                setAPIRequestsFailed: true
            });
            return
        }

        let countriesdata = my_results['ReturnList'][0]
        let favoritesdata = my_results['ReturnList'][1]
        let datasetdetailsdata = my_results['ReturnList'][2]
        let reportsdata = my_results['ReturnList'][3]

        var datasetdetailsdata_staging = []
        if (userRole !== "User") {
            datasetdetailsdata_staging = my_results['ReturnList'][4]
        }

        // -------------------- Process countries data --------------------

        let countryname = {}
        countriesdata.forEach(country => {
            countryname[country.country_code] = country.country_shortname
        })

        this.setState({ countryname })

        // -------------------- Process favorites data --------------------

        let report_favorites = favoritesdata.map(favorite => favorite.report_id)
        this.setState({ report_favorites: report_favorites })
        console.log('Favorites Complete')

        // -------------------- Process Dataset Details --------------------

        console.log(datasetdetailsdata)
        console.log(datasetdetailsdata_staging)

        // Create All DataSets by taking the Dataset Details
        let alldatasetdetailsdata = datasetdetailsdata
        let current_ids = alldatasetdetailsdata.map(dataset => dataset.dataset_id)

        // Walk through staging data and if Staging Dataset is not in the current list of ids, add it to the alldatasetdetailsdata
        datasetdetailsdata_staging.forEach(dataset => {
            if (!current_ids.includes(dataset.dataset_id)){
                alldatasetdetailsdata.push(dataset)
            }
        })

        console.log(alldatasetdetailsdata)

        var datamap = {}
        var store_group_map = {}
        var countrymap = {}
        var industrymap = {}

        alldatasetdetailsdata.forEach(dataset => {
            var specific_store_group = {}
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    datamap[dataset.dataset_id] = dataset.short_desc
                    countrymap[dataset.dataset_id] = dataset.country
                    industrymap[dataset.dataset_id] = dataset.industry_name
                    dataset.store_groups.forEach(store_group => {
                        if (store_group.storegpdesc.toUpperCase().indexOf('RM') > -1) {
                            store_group.storegpdesc_modified = store_group.storegpdesc + ' (Remaining Market)'
                        } else if (store_group.storegpdesc.toUpperCase().indexOf('ALR') > -1) {
                            store_group.storegpdesc_modified = store_group.storegpdesc + ' (Account Level Report)'
                        } else if (store_group.storegpdesc.toUpperCase().indexOf('ASSG') > -1) {
                            store_group.storegpdesc_modified = store_group.storegpdesc + ' (Athletic Specialty Sporting Goods)'
                        } else {
                            store_group.storegpdesc_modified = store_group.storegpdesc
                        }

                        specific_store_group[store_group.storegpid] = store_group.storegpdesc_modified
                        store_group_map[dataset.dataset_id] = specific_store_group
                    })
                }
            } else {
                datamap[dataset.dataset_id] = dataset.short_desc
                countrymap[dataset.dataset_id] = dataset.country
                industrymap[dataset.dataset_id] = dataset.industry_name
                dataset.store_groups.forEach(store_group => {
                    if (store_group.storegpdesc.toUpperCase().indexOf('RM') > -1) {
                        store_group.storegpdesc_modified = store_group.storegpdesc + ' (Remaining Market)'
                    } else if (store_group.storegpdesc.toUpperCase().indexOf('ALR') > -1) {
                        store_group.storegpdesc_modified = store_group.storegpdesc + ' (Account Level Report)'
                    } else if (store_group.storegpdesc.toUpperCase().indexOf('ASSG') > -1) {
                        store_group.storegpdesc_modified = store_group.storegpdesc + ' (Athletic Specialty Sporting Goods)'
                    } else {
                        store_group.storegpdesc_modified = store_group.storegpdesc
                    }

                    specific_store_group[store_group.storegpid] = store_group.storegpdesc_modified
                    store_group_map[dataset.dataset_id] = specific_store_group
                })
            }
        })

        this.setState({
            datamap,
            store_group_map,
            countrymap,
            industrymap
        })

        // -------------------- Process Report Details --------------------

        console.log(reportsdata)

        let dt = new Date()
        let offset = dt.getTimezoneOffset() * 60 * 1000
        reportsdata.map(report => report.time_updated_adj = (new Date(Date.parse(report.time_updated) - offset)).toISOString())
        let result_sorted = reportsdata.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        console.log(result_sorted)
        for (var i = 0; i < result_sorted.length; i++) {
            let report = result_sorted[i]
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(report.dataset_id)) {
                    report['showreport'] = true
                } else {
                    report['showreport'] = false
                }
            } else {
                report['showreport'] = true
            }
            report['dataset_name'] = datamap[report.dataset_id]
            report['country'] = countrymap[report.dataset_id]
            report['industry'] = industrymap[report.dataset_id]

            if (report_favorites.includes(report.report_id)) {
                report['favorite'] = true
            } else {
                report['favorite'] = false
            }

            // Perform Logic on Last Updated Date....
            var date_dictionary = {
                '01': 'January',
                '02': 'February',
                '03': 'March',
                '04': 'April',
                '05': 'May',
                '06': 'June',
                '07': 'July',
                '08': 'August',
                '09': 'September',
                '10': 'October',
                '11': 'November',
                '12': 'December'
            }
            var current_date = new Date()
            var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
            var current_date_val = current_date.toISOString()
            var report_updated_date_val = report.time_updated_adj.substring(0, 19)

            var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            var timetodisplay = ''
            var timeString = ""
            var H = ""
            var h = 0
            var ampm = ""
            if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                // Just the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = timeString
            } else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                // Just the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = 'Yesterday ' + timeString
            } else if (Difference_In_Days < 2) {
                // Yesterday plus the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = 'Yesterday ' + timeString
            } else {
                // The Date with the time
                var dateString = report.time_updated_adj.substring(0, 10)
                var year = dateString.substring(0, 4)
                var month = dateString.substring(5, 7)
                var dateNum = dateString.substring(8, 10)
                var monthName = date_dictionary[month]
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
            }

            report['timetodisplay'] = timetodisplay

            // Format the storegroup as text (not the integer ID)
            var storegroup = ''
            try {
                storegroup = store_group_map[report.dataset_id][report.store_group]
            } catch (error) {
                storegroup = 'Error Loading The Data'
            }
            report['storegroup'] = storegroup

            // Format brands as a string -- this does not seem to be used at the moment
            let brands = report['report_brands']
            if (brands) {
                report['brands'] = brands.join(", ")
            }
        }

        // Initial sort of favorites by timestamp then nonfavorites by timestamp
        let favs = []
        let nonfavs = []

        result_sorted.forEach(report => {
            if (report.favorite) {
                favs.push(report)
            }
            else {
                nonfavs.push(report)
            }
        })

        // Sort favs
        let favssorted = favs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        // Sort nonfavs
        let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        // Concat
        let final_sort = favssorted.concat(nonfavssorted)

        this.setState({
            isLoaded: true,
            reports: final_sort,
            showBackground: false
        })
        console.log('componentDidMount Complete')
    }

    render() {
        console.log(this.state)

        const closeModalFunc = () => {
            this.setState({ setAPIRequestsFailed: false });
        }

        let sgs = Object.values(this.state.store_group_map)
        let all_sgs = []
        //console.log(sgs)
        sgs.forEach(sg => {
            //console.log(sg)
            let sgstopush = Object.values(sg)
            //console.log(sgstopush)
            sgstopush.forEach(storegroup => { all_sgs.push(storegroup) })
        })

        //console.log(Array.from(new Set(all_sgs)))

        const sortData = (event, sortby) => {
            console.log(event)
            console.log('Sorting by ' + sortby)

            var current_header_direction = this.state.header_sort_direction
            var new_header_active = []
            var new_direction = ''
            var new_header_direction = []
            if (sortby === 'Favorites') {
                new_header_active = [true, false, false, false, false, false, false, false]
                new_header_direction = current_header_direction
                new_header_direction[0] = new_direction = (current_header_direction[0] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'ReportName') {
                new_header_active = [false, true, false, false, false, false, false, false]
                new_header_direction = current_header_direction
                new_header_direction[1] = new_direction = (current_header_direction[1] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'StoreGroup') {
                new_header_active = [false, false, true, false, false, false, false, false]
                new_header_direction = current_header_direction
                new_header_direction[2] = new_direction = (current_header_direction[2] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Brands') {
                new_header_active = [false, false, false, true, false, false, false, false]
                new_header_direction = current_header_direction
                new_header_direction[3] = new_direction = (current_header_direction[3] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Dataset') {
                new_header_active = [false, false, false, false, true, false, false, false]
                new_header_direction = current_header_direction
                new_header_direction[4] = new_direction = (current_header_direction[4] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'LastModified') {
                new_header_active = [false, false, false, false, false, true, false, false]
                new_header_direction = current_header_direction
                new_header_direction[5] = new_direction = (current_header_direction[5] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Country') {
                new_header_active = [false, false, false, false, false, false, true, false]
                new_header_direction = current_header_direction
                new_header_direction[6] = new_direction = (current_header_direction[6] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Industry') {
                new_header_active = [false, false, false, false, false, false, false, true]
                new_header_direction = current_header_direction
                new_header_direction[7] = new_direction = (current_header_direction[7] === 'asc') ? 'desc' : 'asc'
            }

            // Sort the data...
            var data_to_sort = this.state.reports
            console.log(data_to_sort)
            var final_sort = []
            if (sortby === 'Favorites') {
                let favs = []
                let nonfavs = []
                data_to_sort.forEach(report => {
                    if (report.favorite) {
                        favs.push(report)
                    } else {
                        nonfavs.push(report)
                    }
                })

                // Sort favs
                let favssorted = favs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                // Sort nonfavs
                let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                // Concatenate the two
                if (new_direction === 'asc') {
                    final_sort = favssorted.concat(nonfavssorted)
                } else {
                    final_sort = nonfavssorted.concat(favssorted)
                }
            } else if (sortby === 'ReportName') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.report_name || "").toLowerCase() < (b.report_name || "").toLowerCase() ? -1 : ((a.report_name || "").toLowerCase() > (b.report_name || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.report_name || "").toLowerCase() < (a.report_name || "").toLowerCase() ? -1 : ((b.report_name || "").toLowerCase() > (a.report_name || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'StoreGroup') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.storegroup || "").toLowerCase() < (b.storegroup || "").toLowerCase() ? -1 : ((a.storegroup || "").toLowerCase() > (b.storegroup || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.storegroup || "").toLowerCase() < (a.storegroup || "").toLowerCase() ? -1 : ((b.storegroup || "").toLowerCase() > (a.storegroup || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Brands') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.brands || "").toLowerCase() < (b.brands || "").toLowerCase() ? -1 : ((a.brands || "").toLowerCase() > (b.brands || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.brands || "").toLowerCase() < (a.brands || "").toLowerCase() ? -1 : ((b.brands || "").toLowerCase() > (a.brands || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Dataset') {
                // Need to adjust
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.dataset_name || "").toLowerCase() < (b.dataset_name || "").toLowerCase() ? -1 : ((a.dataset_name || "").toLowerCase() > (b.dataset_name || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.dataset_name || "").toLowerCase() < (a.dataset_name || "").toLowerCase() ? -1 : ((b.dataset_name || "").toLowerCase() > (a.dataset_name || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'LastModified') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.time_updated) - Date.parse(b.time_updated) })
                }
            } else if (sortby === 'Country') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.country || "").toLowerCase() < (b.country || "").toLowerCase() ? -1 : ((a.country || "").toLowerCase() > (b.country || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.country || "").toLowerCase() < (a.country || "").toLowerCase() ? -1 : ((b.country || "").toLowerCase() > (a.country || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Industry') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.industry || "").toLowerCase() < (b.industry || "").toLowerCase() ? -1 : ((a.industry || "").toLowerCase() > (b.industry || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.industry || "").toLowerCase() < (a.industry || "").toLowerCase() ? -1 : ((b.industry || "").toLowerCase() > (a.industry || "").toLowerCase() ? 1 : 0))
                }
            }

            console.log(final_sort)

            this.setState({
                header_sort_direction: new_header_direction,
                header_sort_active: new_header_active,
                reports: final_sort
            })
        }

        const filterReports = (selectedOptions, filterby) => {
            console.log(selectedOptions)
            console.log(filterby)
            let appname = Constant.whereamilist[3]
            let filters = this.state.filters
            let reports = this.state.reports
            console.log(filters[filterby])
            if (filterby === 'datasets') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['datasets'] = selectedOptions.map(option => option.label) // Compare the label to the dataset name
                } else {
                    filters['datasets'] = ['All']
                }
            }

            if (filterby === 'countries') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['countries'] = selectedOptions.map(option => option.value)
                } else {
                    filters['countries'] = ['All']
                }
            }

            if (filterby === 'industries') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['industries'] = selectedOptions.map(option => option.value)
                } else {
                    filters['industries'] = ['All']
                }
            }

            if (filterby === 'storegroups') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['storegroups'] = selectedOptions.map(option => option.value)
                } else {
                    filters['storegroups'] = ['All']
                }
            }

            // Loop through reports, and ensure that all filters are met...
            reports.forEach(report => {
                let showreport = false
                let datasetmatch = false
                let countrymatch = false
                let industrymatch = false
                let storegroupmatch = false

                if (filters['datasets'].includes(report.dataset_name) || (filters['datasets'].length === 1 && filters['datasets'][0] === 'All')) {
                    datasetmatch = true
                }

                if (filters['countries'].includes(report.country) || (filters['countries'].length === 1 && filters['countries'][0] === 'All')) {
                    countrymatch = true
                }

                if (filters['industries'].includes(report.industry) || (filters['industries'].length === 1 && filters['industries'][0] === 'All')) {
                    industrymatch = true
                }

                if (filters['storegroups'].includes(report.storegroup) || (filters['storegroups'].length === 1 && filters['storegroups'][0] === 'All')) {
                    storegroupmatch = true
                }

                if (datasetmatch && countrymatch && industrymatch && storegroupmatch) {
                    if (appname === 'PDTRetailer') {
                        if (Constant.PDT_RETAILER_DATASETS.includes(report.dataset_id)) {
                            showreport = true
                        } else {
                            showreport = false
                        }
                    } else {
                        showreport = true
                    }
                }

                report['showreport'] = showreport
            })

            this.setState({ filters, reports })
        }

        const handleClickFavorite = (event, id, type, remove_add) => {
            var current_favorites = []
            var new_favorites = []
            if (type === 'report') {
                current_favorites = this.state.report_favorites
                new_favorites = []
                if (remove_add === 'remove') {
                    // Remove from state of report favorites
                    current_favorites.forEach(reportid => { if (reportid !== id) { new_favorites.push(reportid) } })
                    console.log(new_favorites)
                    this.setState({ report_favorites: new_favorites })
                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (new_favorites.includes(report.report_id)) {
                            report['favorite'] = true
                        } else {
                            report['favorite'] = false
                        }
                    })

                    this.setState({ reports })

                    // Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            } else {
                                console.log('Error')
                            }
                        })
                } else {
                    // Add to report favorites
                    current_favorites.push(id)
                    console.log(current_favorites)
                    this.setState({ report_favorites: current_favorites })

                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (current_favorites.includes(report.report_id)) {
                            report['favorite'] = true
                        }
                    })

                    this.setState({ reports })

                    // Perform API to add to favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            } else {
                                console.log('Error')
                            }
                        })
                }
            }
        }

        const handleOpenDialog = (reportid) => {
            console.log('Click!')
            console.log((reportid))
            this.setState({
                deleteDialog: true,
                reportidtodelete: reportid
            })
        }

        const handleCloseDialog = () => {
            console.log('Click!')
            this.setState({ deleteDialog: false })
        }

        const handleClick_delete = () => {
            console.log(this.state.reportidtodelete);
            console.log('Running Delete!')
            var reportid = this.state.reportidtodelete
            var appname = Constant.whereamilist[3]
            fetch(Constant.baseapiurl + 'ReportSettings/' + reportid, { method: 'DELETE' })
                .then(res => {
                    // Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        res.text()
                            .then(
                                () => {
                                    window.location.reload(true);
                                    window.location.href = Constant.baseurl + appname + '/Reports'
                                },
                                (error) => {
                                    console.log('There Was an Error' + error)
                                }
                            )
                    } else if (res.status === 401 || res.status === 404) {
                        //setOpenDialog(false)
                        //setOpenDialog_deleteError(true)
                        this.setState({ deleteDialog: false })
                    } else {
                        console.log('There was an Error Running the API')
                    }
                })

            // Delete from cache
            caches.open('reportDataCache').then(async cache => {
                cache.delete(Constant.baseapiurl + 'ReportData/' + reportid).then(reportdelete => {
                    console.log('Report Cache Deleted')
                })
            })
        }

        var appname = Constant.whereamilist[3]

        return (
            <Layout title="All Reports">
                <Dialog
                    open={this.state.deleteDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete this Report?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to delete this report. This action is final and can not be undone.  Continue?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog} className="btn btn-secondarynpd">
                            Cancel
                    </button>
                        <button onClick={handleClick_delete} className="btn btn-primary" autoFocus>
                            Delete Report
                    </button>
                    </DialogActions>
                </Dialog>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <Dialog
                        open={this.state.setAPIRequestsFailed}
                        onClose={closeModalFunc}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ overflowY: 'scroll' }}
                    >
                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                An error occurred loading the requested information.  You can try refreshing this page.
                                If it still does not load correctly, please reach out to pricing support at Pricing.Support@npd.com
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={closeModalFunc} className="btn btn-secondary">
                                Ok
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>

                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    {/*<CircularProgress ClassName="custom-loader" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <div name='banner' className='banner'>
                    <h1white align='center'>All Reports</h1white><br/>
                    <h2white align='center'>Below is a list of all reports in the tool you have access to.</h2white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <div className='contentHolder'>
                        <div className='row'>
                            <div className='col-sm-12'><h4>Filters</h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Dataset</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Country</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Industry</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Store Groups</span></h4></div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="datasetdropdown"
                                    options={Object.keys(this.state.datamap).map(dataset => {
                                        return { 'value': dataset, 'label': this.state.datamap[dataset] }
                                    })
                                    }
                                    placeholder={'Select Datasets to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterReports(options, 'datasets')}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="countrydropdown"
                                    options={Array.from(new Set(Object.values(this.state.countrymap))).map(country => {
                                        return { 'value': country, 'label': (this.state.countryname[country] || "Unknown") }
                                    })
                                    }
                                    placeholder={'Select Countries to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterReports(options, 'countries')}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="industrydropdown"
                                    options={Array.from(new Set(Object.values(this.state.industrymap))).map(industry => {
                                        return { 'value': industry, 'label': (industry || "Unknown") }
                                    })
                                    }
                                    placeholder={'Select Industries to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterReports(options, 'industries')}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="storegroupdropdown"
                                    options={Array.from(new Set(all_sgs)).map(storegroup => {
                                        return { 'value': storegroup, 'label': storegroup }
                                    })
                                    }
                                    placeholder={'Select Store Groups to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterReports(options, 'storegroups')}
                                    styles={selectColourStyles}
                                />
                            </div>
                        </div>
                    </div>

                    <Table
                        id="reporTable"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[0]}
                                        direction={this.state.header_sort_direction[0]}
                                        onClick={(event) => sortData(event, 'Favorites')}
                                    >
                                        <h4>Favorite</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[6]}
                                        direction={this.state.header_sort_direction[6]}
                                        onClick={(event) => sortData(event, 'Country')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Country</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[7]}
                                        direction={this.state.header_sort_direction[7]}
                                        onClick={(event) => sortData(event, 'Industry')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Industry</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[1]}
                                        direction={this.state.header_sort_direction[1]}
                                        onClick={(event) => sortData(event, 'ReportName')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Report Name</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[2]}
                                        direction={this.state.header_sort_direction[2]}
                                        onClick={(event) => sortData(event, 'StoreGroup')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Store Group</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[4]}
                                        direction={this.state.header_sort_direction[4]}
                                        onClick={(event) => sortData(event, 'Dataset')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Dataset</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[5]}
                                        direction={this.state.header_sort_direction[5]}
                                        onClick={(event) => sortData(event, 'LastModified')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Last Modified</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    Delete Report
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.reports.map((report) => {
                                let showreport = report.showreport
                                if (showreport) {
                                    return <TableRow key={report.report_id}>
                                        {report.favorite ?
                                            <TableCell onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')}> <StarRoundedIcon style={{ color: '#4e106f' }} /></TableCell>
                                            :
                                            <TableCell onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')}> <StarBorderRoundedIcon style={{ color: '#4e106f' }} /></TableCell>
                                        }
                                        <TableCell> {this.state.countryname[report.country] || "Unknown"}</TableCell>
                                        <TableCell> {report.industry || "Unknown"}</TableCell>
                                        <TableCell> <a href={'/' + appname + '/Report/' + report.report_id} >{report.report_name} </a></TableCell>
                                        <TableCell> {report.storegroup} </TableCell>
                                        <TableCell> {report.dataset_name} </TableCell>
                                        <TableCell> {report.timetodisplay} </TableCell>
                                        <TableCell>
                                            <IconButton aria-label="delete" onClick={(reportid) => handleOpenDialog(report.report_id)}>
                                                <DeleteRoundedIcon style={{ color: '#333333' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                } else {
                                    return null
                                }
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Layout>
        )
    }
}
