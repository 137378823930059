export default function changeSelectedChannelMulti(selectedOptions, position) {
    if (selectedOptions && selectedOptions.length > 0) {
        this.setState({
            selectedChannels: selectedOptions.map(option => option.value)
        })
    }
    else {
        this.setState({
            selectedChannels: [], yMax: 0
        })
    }
}