export default async function FetchConfigWithDefault(request_list, log_id, config_name) {
    const json_headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    console.log('Getting the config for ' + config_name)

    // Use Promise.allSettled() so that we will wait for every reqeust to complete, whether
    // successful or not, and we'll get the status of each.
    let result_list = await Promise.allSettled(request_list.map(request => {
        return fetch(request['url'], { method: "GET", headers: json_headers })
    }))

    // We have to wrap the map() in "await Promise'all" to get an array of the *result* of each Promise
    // rather than getting an array of completed Promises that we then have to unwrap individually.
    let return_list = await Promise.all(await request_list.map(async (request, i) => {
        let response = result_list[i].value
        if (response.status === 200) {
            return await response.json()
        } else {
            let error_to_set = (response.status === 404) ? 'errorAlert_noAccess' : 'errorAlert_report'
            console.log('***** ' + log_id + ' failed to fetch config ' + request['url'] +
                        ' with status ' + response.status + ', setting ' + error_to_set + ' = true')
            console.log('The body of the response to the failure is:')
            const contentType = response.headers.get("content-type")
            let body
            if ((contentType && contentType.indexOf('application/json') !== -1) || response.status === 511) {
                body = await response.json()
            } else {
                body = await response.text()
            }
            console.log(body)

            // We get HTTP 511 if our auth cookie does not connect to any cookie in the server, which indicates that the
            // Middleware server has been restarted since we acquired our authorization
            if (response.status === 511) {
                console.log('***** We got HTTP 511, which signals that the Middleware server has restarted since we last authenticated *****')
                // HTTP 511 will always return a JSON body, so just assume we have JSON not text
                console.log(body['Reason'])
                this.setState({
                    displayReloadDialog: true
                    , reloadDialog: body['Reason']
                    , showBackdrop: false
                })
            }
            return request['default']
        }
    }))

    // Figure out how many errors we had, either promises not fulfilled or HTTP failures
    var total_length = 0
    var error_count = 0
    var error_not_fulfilled = 0
    var error_alert = false
    var error_no_access = false
    for (const rsp of result_list) {
        if (rsp.status === 'fulfilled') {
            let response = rsp.value
            total_length += (response.headers.has('content-length')) ? Number(response.headers.get('content-length')) : 0
            error_count += (response.status === 200 || response.status === 202) ? 0 : 1
            if (response.status >= 400) {
                if (response.status == 404) {
                    error_no_access = true
                } else {
                    error_alert = true
                }
            }
        } else {
            error_count += 1
            error_not_fulfilled += 1
        }
    }

    if (error_count > 0) {
        this.setState({
            errorAlert_report: error_alert,
            errorAlert_noAccess: error_no_access
        })
    }

    console.log('Getting config for ' + config_name + ' finished with ' + error_count + '/' + request_list.length + ' errors, ' +
        'and total received bytes = ' + total_length + ' (' + Number(total_length / (1024. * 1024.)).toFixed(2) + ' MB)')
    if (error_not_fulfilled > 0) {
        console.log('Also, ' + error_not_fulfilled + '/' + request_list.length + ' requests failed in the browser')
    }
    return { "ErrorCount": error_count, "ReturnList": return_list, "TotalSize": total_length }
}
