import '../styles/custom.css'
import * as Constant from '../constants'

export async function onChangePdtSplittingCountry(selectedOptions, action) {
    var pdtSplitCountry = selectedOptions.value
    var pdtSplitCountryName = selectedOptions.label
    this.setState({
        pdtSplitCountry,
        pdtSplitCountryName,
        pdtSplitIndustry: null,
        pdtSplitIndustryName: '',
        pdtSplitDatasetId: '',
        pdtSplitDatasetName: '',
        pdtSplitStoreGroupId: '',
        pdtSplitStoreGroupName: '',
        pdt_splitting_rom_outlet: '',
        pdt_splitting_short_desc: '',
        pdt_splitting_description: '',
    })

    // Read in Industries from Country lookup
    let results = await this.entityCrud('Getting industries for PDT splitting for country ' + pdtSplitCountry, [
        { 'url': Constant.baseapiurl_gen + "etl/IndustryTables/ByCountry/" + pdtSplitCountry, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results.Errors > 0) {
        return
    }

    let industry_country_data = results.ResultList[0]
    let countryInd_options = []
    industry_country_data.forEach(country => {
        countryInd_options.push({ 'label': country.industry_name, 'value': country.industry_id })
    })
    countryInd_options = countryInd_options.sort((a, b) => a.label.localeCompare(b.label))
    this.setState({ countryInd_options })
}

export async function onChangePdtSplittingIndustry(selectedOptions, action) {
    var pdtSplitIndustry = selectedOptions.value
    var pdtSplitIndustryName = selectedOptions.label
    this.setState({
        pdtSplitIndustry,
        pdtSplitIndustryName,
        pdtSplitDatasetId: '',
        pdtSplitDatasetName: '',
        pdtSplitStoreGroupId: '',
        pdtSplitStoreGroupName: '',
        pdt_splitting_rom_outlet: '',
        pdt_splitting_short_desc: '',
        pdt_splitting_description: '',
    })

    await this.getSplittingDatasetList(pdtSplitIndustry, this.state.splitting_env)
}

export async function onChangePdtSplittingEnvRadio(event) {
    this.setState({
        splitting_env: event.target.value,
        pdtSplitDatasetId: '',
        pdtSplitDatasetName: '',
        pdtSplitStoreGroupId: '',
        pdtSplitStoreGroupName: '',
        pdt_splitting_rom_outlet: '',
        pdt_splitting_short_desc: '',
        pdt_splitting_description: '',
    })
    // If someone is changing the radio button before we have selected a country and industry, don't do anything more
    if (this.state.pdtSplitIndustry === null) {
        return
    }
    await this.getSplittingDatasetList(this.state.pdtSplitIndustry, event.target.value)
}

export async function getSplittingDatasetList(pdtSplitIndustry, splitting_env) {
    // Read in Industry Lookup Table (parent and children)
    let results = await this.entityCrud('getting template datasets for industry ' + pdtSplitIndustry + ' for ' + splitting_env, [
        { 'url': Constant.baseapiurl_gen + 'pdt/Datasets/ByIndustry/' + pdtSplitIndustry + '?env=' + splitting_env, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results.Errors > 0) {
        return
    }

    let dataset_list = results.ResultList[0]

    let pdt_split_dataset_options = []
    dataset_list.forEach(dataset => {
        pdt_split_dataset_options.push({ 'label': dataset.short_desc, 'value': dataset.dataset_id })
    })
    pdt_split_dataset_options = pdt_split_dataset_options.sort((a, b) => a.label.localeCompare(b.label))
    this.setState({ pdt_split_dataset_options })
}

export async function onChangePdtSplittingDataset(selectedOptions, action) {
    var pdtSplitDatasetId = selectedOptions.value
    var pdtSplitDatasetName = selectedOptions.label
    this.setState({
        pdtSplitDatasetId,
        pdtSplitDatasetName,
        pdt_splitting_short_desc: '',
        pdt_splitting_description: '',
    })

    let results = await this.entityCrud('Getting datasets information for dataset ID ' + pdtSplitDatasetId + ' for ' + this.state.splitting_env, [
        { 'url': Constant.baseapiurl_gen + 'pdt/Datasets/' + pdtSplitDatasetId + '?env=' + this.state.splitting_env, 'method': 'GET', 'body': null },
        { 'url': Constant.baseapiurl_gen + 'pdt/Datasets/' + pdtSplitDatasetId + '/StoreGroups?env=' + this.state.splitting_env, 'method': 'GET', 'body': null },

    ], this.on_api_entity_crud_error)
    if (results.Errors > 0) {
        return
    }

    let dataset_info = results.ResultList[0]
    let store_groups = results.ResultList[1]

    let pdt_split_store_group_options = []
    store_groups.forEach(dataset => {
        if (dataset.storegpdesc != 'Total Measured Market') {
            pdt_split_store_group_options.push({ 'label': dataset.storegpdesc, 'value': dataset.storegpid })
        }
    })
    pdt_split_store_group_options = pdt_split_store_group_options.sort((a, b) => a.label.localeCompare(b.label))

    // Don't fill in a default prefix unless we get at least one possible dataset to stack with
    var rom_outlet = (this.state.pdtSplitIndustryName == 'U.S. Auto (Tires)') ? 'Modern Trade' : ''
    this.setState({
        pdt_splitting_rom_outlet: rom_outlet,
        pdt_splitting_short_desc: dataset_info.short_desc,
        pdt_splitting_description: dataset_info.description,
        pdt_split_store_group_options,
        pdt_splitting_button_enabled: this.state.pdtSplitStoreGroupName != ''
    })
}

export function onChangePdtSplittingStoreGroup(selectedOptions, action) {
    var pdtSplitStoreGroupId = selectedOptions.value
    var pdtSplitStoreGroupName = selectedOptions.label
    this.setState({
        pdtSplitStoreGroupId,
        pdtSplitStoreGroupName,
        pdt_splitting_button_enabled: pdtSplitStoreGroupName != ''
    })
}

export function onChangePdtSplittingRomOutlet(event, newValue) {
    this.setState({ pdt_splitting_rom_outlet: document.getElementById('pdtSplittingromoutlet').value })
}

export function onChangePdtSplittingShortDesc(event, newValue) {
    let short_desc = document.getElementById('pdtSplittingShortDesc').value
    this.setState({ pdt_splitting_short_desc: short_desc })
}

export function onChangePdtSplittingLongDesc(event, newValue) {
    this.setState({ pdt_splitting_description: document.getElementById('pdtSplittingLongDesc').value })
}

export async function handleSubmitPdtSplitting() {
    this.setState({ pdt_splitting_button_enabled: false })

    let request_body = {
        'storegpid': this.state.pdtSplitStoreGroupId,
        'short_desc': this.state.pdt_splitting_short_desc,
        'description': this.state.pdt_splitting_description,
        'rom_outlet_value': this.state.pdt_splitting_rom_outlet,
    }
    let results = await this.entityCrud('Splitting dataset ID ' + this.state.pdtSplitDatasetId + ' for ' + this.state.splitting_env, [
        { 'url': Constant.baseapiurl_gen + 'pdt/Splitting/' + this.state.pdtSplitDatasetId + '?env=' + this.state.splitting_env, 'method': 'POST', 'body': request_body },
    ], this.on_api_entity_crud_error)
    this.setState({ pdt_splitting_button_enabled: true })
    if (results.Errors > 0) {
        return
    }
    alert('Splitting was successful')
}
