export default async function FetchDataWithErrorHandling(request_list, log_id, pull_name) {
    const json_headers = {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
    }

    console.log(pull_name + ' Data Pull Started')

    // Use Promise.allSettled() so that we will wait for every reqeust to complete, whether
    // successful or not, and we'll get the status of each.
    let result_list = await Promise.allSettled(request_list.map(request => {
        if (request['method'] == 'GET') {
            return fetch(request['url'],
                {
                    method: "GET",
                    headers: json_headers
                })
        } else if (request['body'] !== null) {
            return fetch(request['url'],
                {
                    method: request['method'],
                    headers: json_headers,
                    body: JSON.stringify(request['body'])
                })
        } else {
            return fetch(request['url'],
                {
                    method: request['method'],
                    headers: json_headers
                })
        }
    }))

    // We have to wrap the map() in "await Promise'all" to get an array of the *result* of each Promise
    // rather than getting an array of completed Promises that we then have to unwrap individually.
    let return_list = await Promise.all(await request_list.map(async (request, i) => {
        let response = result_list[i].value
        if (response == null) {
            console.log('Response is not a value for #' + i + ' for ' + request['method'] + ' to ' + request['url'])
            console.log(result_list[i])
            return {}
        } else if (response.status === 200) {
            return await response.json()
        } else {
            console.log('***** ' + log_id + ' failed to fetch ' + request['url'] +
                        ' with status ' + response.status + ', setting errorAlert_report = true')
            console.log('The body of the response to the failure is:')
            const contentType = response.headers.get("content-type")
            let body
            if (contentType && contentType.indexOf("application/json") !== -1) {
                body = await response.json()
            } else {
                body = await response.text()
            }
            console.log(body)
            return {}
        }
    }))

    // Figure out how many errors we had, either promises not fulfilled or HTTP failures
    var total_length = 0
    var error_count = 0
    var error_not_fulfilled = 0
    for (const rsp of result_list) {
        if (rsp.status === 'fulfilled') {
            let response = rsp.value
            total_length += (response.headers.has('content-length')) ? Number(response.headers.get('content-length')) : 0
            error_count += (response.status === 200) ? 0 : 1
        } else {
            error_count += 1
            error_not_fulfilled += 1
        }
    }
    if (error_count > 0) {
        this.setState({ errorAlert_report: true })
    }

    console.log(pull_name + ' Data Pull Finished with ' + error_count + '/' + request_list.length + ' errors, ' +
        'and total received bytes = ' + total_length + ' (' + Number(total_length / (1024. * 1024.)).toFixed(2) + ' MB)')
    if (error_not_fulfilled > 0) {
        console.log('Also, ' + error_not_fulfilled + '/' + request_list.length + ' requests failed in the browser')
    }
    return { "ErrorCount": error_count, "ReturnList": return_list, "TotalSize": total_length }
}
