export default function handleToggle_exclude_brands() {

    this.setState({ exclude_brands: !this.state.exclude_brands })
    //Filter Dataset....
    let selected_option = this.state.filter_object['brands'].map(brand => {
        return { 'label': brand, 'value': brand }
    })

    let exclude_array = [!this.state.exclude_brands, this.state.exclude_items]
    this.setfilters(selected_option, 'brands', exclude_array)
}