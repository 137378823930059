import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as Constant from '../../../constants';

export class TopBottomSwitch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        return(
            <Fragment>
                <span className="formfieldtitle">Top Weeks are 20% or more above average and Bottom Weeks are 20% or more below average </span>
                <ThemeProvider theme={Constant.muiTheme}>
                    <span style={{ width: '200px' }}></span>
                    <span style={{ lineHeight: '2.5' }}>Top Weeks</span>
                    <div style={{ lineHeight: '0' }}>
                        <Switch
                            checked={this.props.state.indexTopBottom}
                            onChange={this.props.handleChange_IndexTopBottom}
                            color="secondary"
                        />
                    </div>
                    <span style={{ lineHeight: '2.5' }}>Bottom Weeks</span>
                </ThemeProvider>
            </Fragment>
        )
    }
}