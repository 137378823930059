import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { StackingTypeCheckbox } from '../../UIElements/Checkboxes/StackingTypeCheckbox'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import BaseIncremental from '../../../Graphs/BaseIncremental'
import PromotedNonPromoted from '../../../Graphs/PromotedNonPromoted'

export class ALRvsROM_BaseIncremental extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        var outletLookup = {}
        var outletLookupDollars = {}
        Object.keys(this.props.state.outlet_data).forEach(outlet => outletLookup[outlet] = ((this.props.state.outlet_data[outlet] || {})['totalunits'] || 0))
        Object.keys(this.props.state.outlet_data).forEach(outlet => outletLookupDollars[outlet] = ((this.props.state.outlet_data[outlet] || {})['totaldollars'] || 0))

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <UnitsDollarSwitch
                        state={this.props.state}
                        handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                    />
                    <StackingTypeCheckbox
                        state={this.props.state}
                        handleToggle_stackingType={this.props.handleToggle_stackingType}
                    />
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        {this.props.state.indexSwitchCheckDollars ?
                            <div className="row" style={{ width: "100%", height: "100%" }}>
                                <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                    < PromotedNonPromoted
                                        NonPromotedBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['nonpromoteddollars'] || 0))}
                                        PromotedBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['promoteddollars'] || 0))}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        showDataTable={this.props.state.showDataTable}
                                        unitsvar={this.props.state.currencyType}
                                        categories={this.props.state.outlets}
                                        attr_caption_text={this.props.state.attr_caption_text}
                                        chart_title_info={this.props.state.chart_title_info}
                                        TotalBarLookup={outletLookupDollars}//this.props.state.outlet_data}
                                        level='Outlet'
                                        metadata={this.props.state.chartmetadata}
                                        currencycode={this.props.state.currencycode}
                                        stackingType={this.props.state.stackingType}
                                    />
                                </div>
                                <div style={{ height: '100%', width: "100%", }} className="col-sm-6">
                                    < BaseIncremental
                                        UnSubBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['unsubsidizedbasedollars'] || 0))}
                                        SubBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['subsidizedbasedollars'] || 0))}
                                        IncBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['incrementaldollars'] || 0))}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        showDataTable={this.props.state.showDataTable}
                                        unitsvar={this.props.state.currencyType}
                                        categories={this.props.state.outlets}
                                        attr_caption_text={this.props.state.attr_caption_text}
                                        chart_title_info={this.props.state.chart_title_info}
                                        TotalBarLookup={outletLookupDollars}
                                        level='Outlet'
                                        metadata={this.props.state.chartmetadata}
                                        currencycode={this.props.state.currencycode}
                                        stackingType={this.props.state.stackingType}
                                    />
                                </div>
                            </div>
                            :
                            <div className="row" style={{ width: "100%", height: "100%" }}>
                                <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                    < PromotedNonPromoted
                                        NonPromotedBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['nonpromotedunits'] || 0))}
                                        PromotedBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['promotedunits'] || 0))}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        showDataTable={this.props.state.showDataTable}
                                        unitsvar={this.props.state.unitsvar}
                                        categories={this.props.state.outlets}
                                        attr_caption_text={this.props.state.attr_caption_text}
                                        chart_title_info={this.props.state.chart_title_info}
                                        TotalBarLookup={outletLookup}//this.props.state.outlet_data}
                                        level='Outlet'
                                        metadata={this.props.state.chartmetadata}
                                        currencycode={this.props.state.currencycode}
                                        stackingType={this.props.state.stackingType}
                                    />
                                </div>
                                <div style={{ height: '100%', width: "100%", }} className="col-sm-6">
                                    < BaseIncremental
                                        UnSubBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['unsubsidizedbaseunits'] || 0))}
                                        SubBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['subsidizedbaseunits'] || 0))}
                                        IncBarData={this.props.state.outlets.map(outlet => ((this.props.state.outlet_data[outlet] || {})['incrementalunits'] || 0))}
                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                        showDataTable={this.props.state.showDataTable}
                                        unitsvar={this.props.state.unitsvar}
                                        categories={this.props.state.outlets}
                                        attr_caption_text={this.props.state.attr_caption_text}
                                        chart_title_info={this.props.state.chart_title_info}
                                        TotalBarLookup={outletLookup}
                                        level='Outlet'
                                        metadata={this.props.state.chartmetadata}
                                        currencycode={this.props.state.currencycode}
                                        stackingType={this.props.state.stackingType}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
