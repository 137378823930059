
export default async function downloadFlexibleWeeklyTableData() {
    let defaultLevelsSelected = [
        { 'label': 'Week Ending', 'value': 'rowdate' },
        { 'label': this.state.segment1label, 'value': 'segment1_name' },
        { 'label': 'Brand', 'value': 'brand' },
        { 'label': 'Outlet', 'value': 'outlet' }
    ]
    this.downloadFlexibleTableDataCommon('weekly', defaultLevelsSelected, 'Retailer Weekly Performance')
}
