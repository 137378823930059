import React, { Fragment } from 'react';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import * as Constant from '../constants';

const ScorecardSalesMetrics = props => {

    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'
    var appname = props.appname

    var total_dollars_curr = props.total_dollars_curr
    var total_dollars_prior = props.total_dollars_prior

    var nonpromoted_dollars_curr = props.nonpromoted_dollars_curr
    var nonpromoted_dollars_prior = props.nonpromoted_dollars_prior

    var promoted_dollars_curr = props.promoted_dollars_curr
    var promoted_dollars_prior = props.promoted_dollars_prior

    var base_dollars_curr = props.base_dollars_curr
    var base_dollars_prior = props.base_dollars_prior

    var incremental_dollars_curr = props.incremental_dollars_curr
    var incremental_dollars_prior = props.incremental_dollars_prior

    var subbasedollars_curr = props.subbasedollars_curr
    var unsubbasedollars_curr = props.unsubbasedollars_curr

    var subbasedollars_prior = props.subbasedollars_prior
    var unsubbasedollars_prior = props.unsubbasedollars_prior

    var promodollars_pct_curr = promoted_dollars_curr / total_dollars_curr
    var promodollars_pct_prior = promoted_dollars_prior / total_dollars_prior

    var nonpromodollars_pct_curr = nonpromoted_dollars_curr / total_dollars_curr
    var nonpromodollars_pct_prior = nonpromoted_dollars_prior / total_dollars_prior

    var incdollars_pct_curr = incremental_dollars_curr / total_dollars_curr
    var incdollars_pct_prior = incremental_dollars_prior / total_dollars_prior

    var basedollars_pct_curr = base_dollars_curr / total_dollars_curr
    var basedollars_pct_prior = base_dollars_prior / total_dollars_prior

    var subbasedollars_pct_curr = subbasedollars_curr / base_dollars_curr
    var subbasedollars_pct_prior = subbasedollars_prior / base_dollars_prior

    var unsubbasedollars_pct_curr = unsubbasedollars_curr / base_dollars_curr
    var unsubbasedollars_pct_prior = unsubbasedollars_prior / base_dollars_prior

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>Sales Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Total ' + currencytype + ' Sold in the Reporting Period'}</p>
                                <p> Please note that:</p>
                                <p> {'Total ' + currencytype + ' = Non-Promoted ' + currencytype + ' + Promoted ' + currencytype + ', and'}</p>
                                <p> {'Total ' + currencytype + ' = Base ' + currencytype + ' + Incremental ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Total ' + currencytype}</h5>
                                <p>{(total_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(total_dollars_curr - total_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(total_dollars_curr - total_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((total_dollars_curr - total_dollars_prior) / total_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(total_dollars_curr - total_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((total_dollars_curr - total_dollars_prior) / total_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{currencytype + ' sales that occur when there is no temporary price reduction'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5 style={{ fontSize: "1.25rem" }}>{'Non-Promoted ' + currencytype}</h5>
                                <p>{(nonpromoted_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(nonpromoted_dollars_curr - nonpromoted_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromoted_dollars_curr - nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((nonpromoted_dollars_curr - nonpromoted_dollars_prior) / nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromoted_dollars_curr - nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((nonpromoted_dollars_curr - nonpromoted_dollars_prior) / nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{currencytype + ' sales in store/weeks that had a temporary price reduction'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted ' + currencytype}</h5>
                                <p>{(promoted_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(promoted_dollars_curr - promoted_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promoted_dollars_curr - promoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((promoted_dollars_curr - promoted_dollars_prior) / promoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promoted_dollars_curr - promoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((promoted_dollars_curr - promoted_dollars_prior) / promoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'An estimate of unit sales that would have occurred without promotions from a temporary price reduction multiplied by the base price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base ' + currencytype}</h5>
                                <p>{(base_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(base_dollars_curr - base_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(base_dollars_curr - base_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((base_dollars_curr - base_dollars_prior) / base_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(base_dollars_curr - base_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((base_dollars_curr - base_dollars_prior) / base_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'An estimate of the impact on unit sales due to the presence of a temporary price reduction multiplied by the promoted price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental ' + currencytype}</h5>
                                <p>{(incremental_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(incremental_dollars_curr - incremental_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(incremental_dollars_curr - incremental_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((incremental_dollars_curr - incremental_dollars_prior) / incremental_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(incremental_dollars_curr - incremental_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((incremental_dollars_curr - incremental_dollars_prior) / incremental_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Unsubsidized Base Units are the estimated base units sold in non-promoted stores multiplied by the base price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base ' + currencytype}</h5>
                                <p>{(unsubbasedollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(unsubbasedollars_curr - unsubbasedollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(unsubbasedollars_curr - unsubbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((unsubbasedollars_curr - unsubbasedollars_prior) / unsubbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(unsubbasedollars_curr - unsubbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((unsubbasedollars_curr - unsubbasedollars_prior) / unsubbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Subsidized Base Units are estimated base units that were sold in stores with a temporary price reduction multiplied by the promoted price.  These are the estimated units that would have sold regardless of the promotion.'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base ' + currencytype}</h5>
                                <p>{(subbasedollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(subbasedollars_curr - subbasedollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(subbasedollars_curr - subbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((subbasedollars_curr - subbasedollars_prior) / subbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(subbasedollars_curr - subbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((subbasedollars_curr - subbasedollars_prior) / subbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Non-Promoted ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Non-Promoted % of Total ' + currencytype}</h5>
                                <p>{(nonpromodollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(nonpromodollars_pct_curr - nonpromodollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromodollars_pct_curr - nonpromodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromodollars_pct_curr - nonpromodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Promoted ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted % of Total ' + currencytype}</h5>
                                <p>{(promodollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(promodollars_pct_curr - promodollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promodollars_pct_curr - promodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promodollars_pct_curr - promodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Base ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base % of Total ' + currencytype}</h5>
                                <p>{(basedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(basedollars_pct_curr - basedollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(basedollars_pct_curr - basedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(basedollars_pct_curr - basedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Incremental ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental % of Total ' + currencytype}</h5>
                                <p>{(incdollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(incdollars_pct_curr - incdollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(incdollars_pct_curr - incdollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(incdollars_pct_curr - incdollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Subsidized Base ' + currencytype + ' / Base ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base % of Base ' + currencytype}</h5>
                                <p>{(subbasedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(subbasedollars_pct_curr - subbasedollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(subbasedollars_pct_curr - subbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(subbasedollars_pct_curr - subbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'UnSubsidized Base ' + currencytype + ' / Base ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base % of Base ' + currencytype}</h5>
                                <p>{(unsubbasedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(unsubbasedollars_pct_curr - unsubbasedollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(unsubbasedollars_pct_curr - unsubbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(unsubbasedollars_pct_curr - unsubbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                </div>
            </Fragment>
            :
            <Fragment>
            </Fragment>
    )
}

export default ScorecardSalesMetrics ;