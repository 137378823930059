export default function CreateSelectionInfo(filter_object, weeks_info, state_object, exclude_array) {

    function createDate(date, days) {
        let date_String = String(parseInt(date.substring(0, 4))) + "-" + date.substring(5, 7) + "-" + date.substring(8, 10) + 'T00:00:00'
        let date_Date = new Date(date_String)
        date_Date.setDate(date_Date.getDate() - days);
        return date_Date
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    //console.log(filter_object)
    //console.log(weeks_info)
    //console.log(state_object)
    //Use the State and the Objects to Create the Selection Information Object

    let selection_info = {}

    //Create Start Dates that are reflective of 6 days (Starting Date...)
    selection_info['Number of Weeks in Comparison Period'] = weeks_info.groupeddates_calc.length
    selection_info['Current Year Ranges From'] = [formatDate(createDate(weeks_info.startdate_calc, 6)), weeks_info.enddate].join(' to ')
    selection_info['Previous Year Ranges From'] = [formatDate(createDate(weeks_info.prior_start, 6)), weeks_info.prior_end].join(' to ')

    //console.log(weeks_info['showLastViews'])

    if (weeks_info['showLastViews'] === true) {
        selection_info['Two Years Ago Ranges From'] = [formatDate(createDate(weeks_info.last_start, 6)), weeks_info.last_end].join(' to ')
    }

    if (weeks_info['showThreeYearViews'] === true) {
        selection_info['Three Years Ago Ranges From'] = [formatDate(createDate(weeks_info.threeyear_start, 6)), weeks_info.threeyear_end].join(' to ')
    }

    //Run through the filter, and add the appropriate text
    function determine_filter_use(filter) {
        if (filter === 'attributes') {
            let all_attrs = Object.keys(filter_object[filter])
            all_attrs.forEach(attr => {
                if (filter_object['attributes'][attr].length > 0) {
                    //selection_info['attributes'] = selection_info['attributes'] || {}
                    selection_info[state_object['attributenamelabels'][attr]] = filter_object['attributes'][attr].join(', ')
                }
            })                            
        }        

        else if (filter === 'segment1') {
            if (filter_object[filter].length > 0) {
                selection_info[state_object['segment1label']] = filter_object[filter].map(seg1=>state_object['segment_1_lookup'][seg1]).join(', ')
            }
        }

        else if (filter === 'segment2') {
            if (filter_object[filter].length > 0) {
                selection_info[state_object['segment2label']] = filter_object[filter].map(seg2 => state_object['segment_2_lookup'][seg2]).join(', ')
            }
        }

        else if (filter === 'segment3') {
            if (filter_object[filter].length > 0) {
                selection_info[state_object['segment3label']] = filter_object[filter].map(seg3 => state_object['segment_3_lookup'][seg3]).join(', ')
            }
        }

        else if (filter === 'segment4') {
            if (filter_object[filter].length > 0) {
                selection_info[state_object['segment4label']] = filter_object[filter].map(seg4 => state_object['segment_4_lookup'][seg4]).join(', ')
            }
        }

        else if (filter === 'brands') {
            if (filter_object[filter].length > 0) {
                selection_info['Brands Selected'] = filter_object[filter].map(brand => state_object['brand_lookup_filters'][brand]).join(', ')
                if (exclude_array[0]) {
                    selection_info['Brands Selected'] = 'Excluding: ' + selection_info['Brands Selected']
                }
            }
        }

        else if (filter === 'items') {
            if (filter_object[filter].length > 0) {
                selection_info['Items Selected'] = filter_object[filter].map(item => state_object['item_lookup'][item]).join(', ')
                if (exclude_array[1]) {
                    selection_info['Items Selected'] = 'Excluding: ' + selection_info['Items Selected']
                }
            }
        }

        else if (filter === 'pricebands') {
            if (filter_object[filter].length > 0) {
                selection_info['Price Bands Selected'] = filter_object[filter].map(priceband => priceband.label).join(', ')
            }
        }

        else if (filter === 'channels') {
            if (filter_object[filter].length > 0) {
                selection_info['Channels Selected'] = filter_object[filter].map(channel => state_object['channel_lookup'][channel]).join(', ')
            }
        }

        else if (filter === 'outlets') {
            if (filter_object[filter].length > 0) {
                selection_info['Outlets Selected'] = filter_object[filter].join(', ')
            }
        }

        else if (filter === 'asp_range') {
            if (filter_object[filter].length > 0) {
                selection_info['Average Selling Price'] = filter_object[filter].map(asp => asp.toLocaleString(undefined, { style: 'currency', currency: state_object.currencycode })).join(' to ')
            }
        }

        else {
            if (filter_object[filter].length > 0) {
                selection_info[filter] = filter_object[filter]
            }
        }
        
    }

    determine_filter_use('outlets')
    determine_filter_use('channels')
    determine_filter_use('segment1')
    determine_filter_use('segment2')
    determine_filter_use('segment3')
    determine_filter_use('segment4')
    determine_filter_use('asp_range')
    determine_filter_use('pricebands')
    determine_filter_use('attributes')        
    //determine_filter_use('brands')
    determine_filter_use('items')
    
    //console.log(selection_info)

    return selection_info
}