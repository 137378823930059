import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'


const PromotedNonPromoted = props => {
    var TotalBarLookup = props.TotalBarLookup    
    var unitsvar = props.unitsvar
    var currencycode = props.currencycode || 'USD'
    var showdollars = false
    if (unitsvar === 'Units' || unitsvar === 'Quarts') {
        showdollars = false
    }
    else {
        showdollars = true
    }

    var stackingType = props.stackingType || 'normal'

    var yaxistext
    if (stackingType === 'percent') {
        yaxistext = 'Percent'
    }
    else {
        yaxistext = props.unitsvar
    }

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'column',
                            height: (9 / 16 * (250 * (props.height || 1))) + '%', //16:9 ratio 
                            zoomType: 'x'
                        },
                        metadata: props.metadata || {},
                        caption: {
                            text: props.attr_caption_text
                        },
                        title: {
                            text: 'Non-Promoted & Promoted ' + props.unitsvar + props.chart_title_info
                        },
                        series: [

                            {
                                name: 'Non-Promoted',
                                data: props.NonPromotedBarData,
                                color: Constant.graph_colors[0]
                            },
                            {
                                name: 'Promoted',
                                data: props.PromotedBarData,
                                color: Constant.graph_colors[1]
                            },

                        ],
                        xAxis: {
                            title: {
                                text: ''
                            },
                            categories:
                                props.categories,
                            min: 0
                        },
                        yAxis: {
                            title: {
                                text: yaxistext
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            reversedStacks: false,
                            labels: {
                                formatter: function () {
                                    function nFormatter(num, digits, axisMax) {
                                        var si = [
                                            { value: 1E18, symbol: "E" },
                                            { value: 1E15, symbol: "P" },
                                            { value: 1E12, symbol: "T" },
                                            { value: 1E9, symbol: "B" },
                                            { value: 1E6, symbol: "M" },
                                            { value: 1E3, symbol: "k" }
                                        ], i;

                                        let symbolForAxis = " "
                                        let valueForAxis = 1

                                        for (i = 0; i < si.length; i++) {
                                            if (axisMax >= si[i].value) {
                                                symbolForAxis = si[i].symbol
                                                valueForAxis = si[i].value
                                                break;
                                            }
                                        }

                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                    }

                                    if (stackingType === 'percent') {
                                        return ((this.value / 100) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }

                                    else {
                                        if (showdollars) {
                                            console.log(this.axis.max)
                                            let value = nFormatter(this.value, 1, this.axis.max)
                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                            let symbol = value.substring(value.length - 1)
                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                        }
                                        else {
                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                        }
                                    }

                                    
                                }
                            }
                        },
                        plotOptions: {
                            column: {
                                stacking: stackingType,
                                dataLabels: {
                                    enabled: true,
                                    //format: '{point.y:,.0f}',
                                    formatter: function () {
                                        function nFormatter(num, digits) {
                                            var si = [
                                                { value: 1E18, symbol: "E" },
                                                { value: 1E15, symbol: "P" },
                                                { value: 1E12, symbol: "T" },
                                                { value: 1E9, symbol: "B" },
                                                { value: 1E6, symbol: "M" },
                                                { value: 1E3, symbol: "k" }
                                            ], i;
                                            for (i = 0; i < si.length; i++) {
                                                if (num >= si[i].value) {
                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                }
                                            }
                                            return num.toString();
                                        }
                                        if (showdollars) {
                                            //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            let value = nFormatter(this.point.y, 1)
                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                            let symbol = value.substring(value.length - 1)
                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                        }
                                        else {
                                            //return (this.point.y || 0).toLocaleString()
                                            return nFormatter(this.point.y, 1).toLocaleString()
                                        }
                                    },
                                    color: 'black',
                                    borderRadius: 5,
                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                    borderWidth: 1,
                                    borderColor: '#d6d7d8',
                                    padding: 0,
                                    style: {
                                        textOutline: 'none',
                                        fontSize: '20px'
                                    },
                                    y: -5
                                }
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                if (showdollars) {
                                    return this.x
                                        + '<br/> <span style="color:'
                                        + this.series.color
                                        + '"> '
                                        + this.series.name
                                        + '</span>: <b>'
                                        + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0  })
                                        + '</b> ('
                                        + (this.y / TotalBarLookup[this.x]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        + ') <br/>'
                                }
                                else {
                                    return this.x
                                        + '<br/> <span style="color:'
                                        + this.series.color
                                        + '"> '
                                        + this.series.name
                                        + '</span>: <b>'
                                        + Math.round(this.y).toLocaleString()
                                        + ' '
                                        + unitsvar
                                        + '</b> ('
                                        + (this.y / TotalBarLookup[this.x]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        + ') <br/>'
                                }
                            },
                        },
                        exporting: {
                            showTable: props.showDataTable,
                            tableCaption: false
                        },

                    }}
            />
            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g7">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{props.level}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Non-Promoted ' + unitsvar}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Promoted ' + unitsvar}</Constant.NPDTableCell>
                        </Constant.NPDTableRow>
                        {props.categories.map((category, idx) => {
                            if (showdollars) {
                                return <Constant.NPDTableRow key={"datarow_" + category + "_g7"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{category}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(props.NonPromotedBarData[idx]).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0  })}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(props.PromotedBarData[idx]).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0  })}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else {
                                return <Constant.NPDTableRow key={"datarow_" + category + "_g7"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{category}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(props.NonPromotedBarData[idx]).toLocaleString()}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(props.PromotedBarData[idx]).toLocaleString()}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>                                            
        </Fragment>
    )
}

export default PromotedNonPromoted;