import React, { Component } from 'react'
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import { Layout } from '../Layout'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import * as Constant from '../constants'

export class ListofAllReports extends Component {
    static displayName = ListofAllReports.name

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            reports: [],
            datamap: {},
            geomap: {},
            countrymap: {},
            industrymap: {},
            errorAlert: false,
            report_favorites: [],
            header_sort_active: [true, false, false, false, false, false, false, false],
            header_sort_direction: ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc'],
            showBackground: true
        }
    }

    formatTime = (report) => {
        // Perform Logic on Last Updated Date....
        var date_dictionary = {
            '01': 'January',
            '02': 'February',
            '03': 'March',
            '04': 'April',
            '05': 'May',
            '06': 'June',
            '07': 'July',
            '08': 'August',
            '09': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December'
        }
        var current_date = new Date()
        var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
        var current_date_val = current_date.toISOString()
        var report_updated_date_val = report.time_updated_adj.substring(0, 19)

        var Difference_In_Time = current_date.getTime() - report_updated_date.getTime()
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
        var timetodisplay = ''
        var timeString = ''
        var H = ''
        var h = 0
        var ampm = ''
        if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
            // Just the time in AM/PM
            timeString = report.time_updated_adj.substring(11, 19)
            H = +timeString.substr(0, 2)
            h = H % 12 || 12
            ampm = (H < 12 || H === 24) ? "AM" : "PM"
            timeString = h + timeString.substr(2, 3) + ampm
            timetodisplay = timeString
        } else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
            // Just the time in AM/PM
            timeString = report.time_updated_adj.substring(11, 19)
            H = +timeString.substr(0, 2)
            h = H % 12 || 12
            ampm = (H < 12 || H === 24) ? "AM" : "PM"
            timeString = h + timeString.substr(2, 3) + ampm
            timetodisplay = 'Yesterday ' + timeString
        } else if (Difference_In_Days < 2) {
            // Yesterday plus the time in AM/PM
            timeString = report.time_updated_adj.substring(11, 19)
            H = +timeString.substr(0, 2)
            h = H % 12 || 12
            ampm = (H < 12 || H === 24) ? "AM" : "PM"
            timeString = h + timeString.substr(2, 3) + ampm
            timetodisplay = 'Yesterday ' + timeString
        } else {
            // The Date with the time
            var dateString = report.time_updated_adj.substring(0, 10)
            var year = dateString.substring(0, 4)
            var month = dateString.substring(5, 7)
            var dateNum = dateString.substring(8, 10)
            var monthName = date_dictionary[month]
            timeString = report.time_updated_adj.substring(11, 19)
            H = +timeString.substr(0, 2)
            h = H % 12 || 12
            ampm = (H < 12 || H === 24) ? "AM" : "PM"
            timeString = h + timeString.substr(2, 3) + ampm
            timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
        }
        return timetodisplay
    }

    async componentDidMount() {
        // Pull Favorites
        const favoritesres = await fetch(Constant.baseapiurl + "ReportSettings/Favorites")
        var favoritesdata = []
        if (favoritesres.status === 200) {
            favoritesdata = await favoritesres.json()
        } else {
            favoritesdata = []
        }

        let report_favorites = favoritesdata.map(favorite => favorite.report_id)
        this.setState({ report_favorites: report_favorites })
        console.log('Favorites Complete')

        // Pull Dataset Details
        const datasetdetailsres = await fetch(Constant.baseapiurl + 'Datasets/DatasetDetails')
        var datasetdetailsdata = []
        if (datasetdetailsres.status === 200) {
            datasetdetailsdata = await datasetdetailsres.json()
        } else {
            datasetdetailsdata = []
        }
        console.log('Dataset Details Complete')
        console.log(datasetdetailsdata)

        var datamap = {}
        var geomap = {}
        var countrymap = {}
        var industrymap = {}

        datasetdetailsdata.forEach(dataset => {
            datamap[dataset.dataset_id] = dataset.shortdesc
            countrymap[dataset.dataset_id] = dataset.country
            industrymap[dataset.dataset_id] = dataset.industry_name
            var specific_geo = {}
            dataset.geos.forEach(geo => {
                if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
                } else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
                } else if (geo.geogdesc.toUpperCase().indexOf('ASSG') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Athletic Specialty Sporting Goods)'
                } else {
                    geo.geogdesc_modified = geo.geogdesc
                }

                specific_geo[geo.geogid] = geo.geogdesc_modified
                geomap[dataset.dataset_id] = specific_geo
            })
        })

        this.setState({ datamap, geomap, countrymap, industrymap })

        // Pull Report Details
        const reportsdetailsres = await fetch(Constant.baseapiurl + 'ReportSettings/ReportDetails')
        var reportsdata = []
        if (reportsdetailsres.status === 200) {
            reportsdata = await reportsdetailsres.json()
        } else {
            reportsdata = []
        }
        console.log('Reports Details Complete')
        console.log(reportsdata)

        let dt = new Date()
        let offset = dt.getTimezoneOffset() * 60 * 1000
        reportsdata.map(report => report.time_updated_adj = (new Date(Date.parse(report.time_updated) - offset)).toISOString())
        let result_sorted = reportsdata.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })

        for (var i = 0; i < result_sorted.length; i++) {
            let report = result_sorted[i]
            report['showreport'] = true // set initial show state to true
            report['dataset_name'] = datamap[report.dataset_id]
            report['country'] = countrymap[report.dataset_id]
            report['industry'] = industrymap[report.dataset_id]
            report['favorite'] = (report_favorites.includes(report.report_id)) ? true : false
            report['timetodisplay'] = this.formatTime(report)
            report['brands'] = report['report_brands'].join(', ')

            var geos
            try {
                // Handle geos being missing, e.g. if a geos table for a PNP dataset gets emptied of all rows but a report still exists for that dataset
                geos = (report.geogid || []).map(geo => (geomap[report.dataset_id] !== undefined) ? geomap[report.dataset_id][geo] : [])
            } catch (error) {
                console.log('Error getting geo list for report ' + report.report_id)
                console.log(error)
                geos = []
            }
            report['geos'] = geos.join(', ')
        }

        // Initial sort of favorites by timestamp then nonfavorites by timestamp
        let favs = []
        let nonfavs = []

        result_sorted.forEach(report => {
            if (report.favorite) {
                favs.push(report)
            } else {
                nonfavs.push(report)
            }
        })

        // Sort
        let favssorted = favs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        let final_sort = favssorted.concat(nonfavssorted)
        this.setState({
            isLoaded: true,
            reports: final_sort,
            showBackground: false
        })
        console.log('AllReport Initialization Complete')
    }

    render() {
        console.log(this.state)

        const sortData = (event, sortby) => {
            console.log('Sorting by ' + sortby + ' for event:')
            console.log(event)
            var current_header_direction = this.state.header_sort_direction
            var new_header_active = []
            if (sortby === 'Favorites') {
                new_header_active = [true, false, false, false, false, false, false, false]
            } else if (sortby === 'Country') {
                new_header_active = [false, true, false, true, false, false, false, false]
            } else if (sortby === 'Industry') {
                new_header_active = [false, false, true, true, false, false, false, false]
            } else if (sortby === 'ReportName') {
                new_header_active = [false, false, false, true, false, false, false, false]
            } else if (sortby === 'Geographies') {
                new_header_active = [false, false, false, false, true, false, false, false]
            } else if (sortby === 'Brands') {
                new_header_active = [false, false, false, false, false, true, false, false]
            } else if (sortby === 'Dataset') {
                new_header_active = [false, false, false, false, false, false, true, false]
            } else if (sortby === 'LastModified') {
                new_header_active = [false, false, false, false, false, false, false, true]
            }

            var new_direction
            var new_header_direction = []
            if (sortby === 'Favorites') {
                new_header_direction = current_header_direction
                new_header_direction[0] = new_direction = (current_header_direction[0] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Country') {
                new_header_direction = current_header_direction
                new_header_direction[1] = new_direction = (current_header_direction[1] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Industry') {
                new_header_direction = current_header_direction
                new_header_direction[2] = new_direction = (current_header_direction[2] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'ReportName') {
                new_header_direction = current_header_direction
                new_header_direction[3] = new_direction = (current_header_direction[3] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Geographies') {
                new_header_direction = current_header_direction
                new_header_direction[4] = new_direction = (current_header_direction[4] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Brands') {
                new_header_direction = current_header_direction
                new_header_direction[5] = new_direction = (current_header_direction[5] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'Dataset') {
                new_header_direction = current_header_direction
                new_header_direction[6] = new_direction = (current_header_direction[6] === 'asc') ? 'desc' : 'asc'
            } else if (sortby === 'LastModified') {
                new_header_direction = current_header_direction
                new_header_direction[7] = new_direction = (current_header_direction[7] === 'asc') ? 'desc' : 'asc'
            }

            // Sort the report list...
            var report_list = this.state.reports
            var final_sort = []
            if (sortby === 'Favorites') {
                let favs = []
                let nonfavs = []

                report_list.forEach(report => {
                    if (report.favorite) {
                        favs.push(report)
                    } else {
                        nonfavs.push(report)
                    }
                })

                let favssorted = favs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                if (new_direction === 'asc') {
                    final_sort = favssorted.concat(nonfavssorted)
                } else {
                    final_sort = nonfavssorted.concat(favssorted)
                }
            } else if (sortby === 'Country') {
                if (new_direction === 'asc') {
                    final_sort = report_list.sort((a, b) => a.country.toLowerCase() < b.country.toLowerCase() ? -1 : (a.country.toLowerCase() > b.country.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = report_list.sort((a, b) => b.country.toLowerCase() < a.country.toLowerCase() ? -1 : (b.country.toLowerCase() > a.country.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Industry') {
                if (new_direction === 'asc') {
                    final_sort = report_list.sort((a, b) => a.industry.toLowerCase() < b.industry.toLowerCase() ? -1 : (a.industry.toLowerCase() > b.industry.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = report_list.sort((a, b) => b.industry.toLowerCase() < a.industry.toLowerCase() ? -1 : (b.industry.toLowerCase() > a.industry.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'ReportName') {
                if (new_direction === 'asc') {
                    final_sort = report_list.sort((a, b) => a.report_name.toLowerCase() < b.report_name.toLowerCase() ? -1 : (a.report_name.toLowerCase() > b.report_name.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = report_list.sort((a, b) => b.report_name.toLowerCase() < a.report_name.toLowerCase() ? -1 : (b.report_name.toLowerCase() > a.report_name.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Geographies') {
                if (new_direction === 'asc') {
                    final_sort = report_list.sort((a, b) => a.geos.toLowerCase() < b.geos.toLowerCase() ? -1 : (a.geos.toLowerCase() > b.geos.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = report_list.sort((a, b) => b.geos.toLowerCase() < a.geos.toLowerCase() ? -1 : (b.geos.toLowerCase() > a.geos.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Brands') {
                if (new_direction === 'asc') {
                    final_sort = report_list.sort((a, b) => a.brands.toLowerCase() < b.brands.toLowerCase() ? -1 : (a.brands.toLowerCase() > b.brands.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = report_list.sort((a, b) => b.brands.toLowerCase() < a.brands.toLowerCase() ? -1 : (b.brands.toLowerCase() > a.brands.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Dataset') {
                if (new_direction === 'asc') {
                    final_sort = report_list.sort((a, b) => a.dataset_name.toLowerCase() < b.dataset_name.toLowerCase() ? -1 : (a.dataset_name.toLowerCase() > b.dataset_name.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = report_list.sort((a, b) => b.dataset_name.toLowerCase() < a.dataset_name.toLowerCase() ? -1 : (b.dataset_name.toLowerCase() > a.dataset_name.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'LastModified') {
                if (new_direction === 'asc') {
                    final_sort = report_list.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                } else {
                    final_sort = report_list.sort(function (a, b) { return Date.parse(a.time_updated) - Date.parse(b.time_updated) })
                }
            }

            this.setState({
                header_sort_direction: new_header_direction,
                header_sort_active: new_header_active,
                reports: final_sort
            })
        }

        const handleClickFavorite = (event, id, type, remove_add) => {
            var current_favorites = []
            var new_favorites = []
            if (type === 'report') {
                current_favorites = this.state.report_favorites
                new_favorites = []
                if (remove_add === 'remove') {
                    // Remove from state of report favorites
                    current_favorites.forEach(reportid => { if (reportid !== id) { new_favorites.push(reportid) } })
                    console.log(new_favorites)
                    this.setState({ report_favorites: new_favorites })
                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (new_favorites.includes(report.report_id)) {
                            report['favorite'] = true
                        } else {
                            report['favorite'] = false
                        }
                    })

                    this.setState({ reports })

                    // Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            } else {
                                console.log('Error')
                            }
                        })
                } else {
                    // Add to report favorites
                    current_favorites.push(id)
                    console.log(current_favorites)
                    this.setState({ report_favorites: current_favorites })

                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (current_favorites.includes(report.report_id)) {
                            report['favorite'] = true
                        }
                    })

                    this.setState({ reports })

                    // Perform API to add to favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            } else {
                                console.log('Error')
                            }
                        })
                }
            } else if (type === 'sim') {
                current_favorites = this.state.sim_favorites
                new_favorites = []
                if (remove_add === 'remove') {
                    current_favorites.forEach(simid => { if (simid !== id) { new_favorites.push(simid) } })
                    this.setState({ sim_favorites: new_favorites })
                    // Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "SimSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            } else {
                                console.log('Error')
                            }
                        })
                } else {
                    current_favorites.push(id)
                    this.setState({ sim_favorites: current_favorites })
                    // Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "SimSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            } else {
                                console.log('Error')
                            }
                        })
                }
            }
        }

        return (
            <Layout title="All Reports">
                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    <div class="custom-loader"></div>
                    {/*<CircularProgress color="inherit" />*/}
                </Constant.NPDBackdrop>

                <div name='banner' className='banner'>
                    <h1white align='center'>All Reports</h1white><br/>
                    <h2white align='center'>Below is a list of all reports in the tool you have access to.</h2white>
                </div>
                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <Table
                        id="reporTable"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[0]}
                                        direction={this.state.header_sort_direction[0]}
                                        onClick={(event) => sortData(event, 'Favorites')}
                                    >
                                        <h4>Favorite</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[1]}
                                        direction={this.state.header_sort_direction[1]}
                                        onClick={(event) => sortData(event, 'Country')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Country</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[2]}
                                        direction={this.state.header_sort_direction[2]}
                                        onClick={(event) => sortData(event, 'Industry')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Industry</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[3]}
                                        direction={this.state.header_sort_direction[3]}
                                        onClick={(event) => sortData(event, 'ReportName')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Report Name</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[4]}
                                        direction={this.state.header_sort_direction[4]}
                                        onClick={(event) => sortData(event, 'Geographies')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Geographies</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[5]}
                                        direction={this.state.header_sort_direction[5]}
                                        onClick={(event) => sortData(event, 'Brands')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Brands</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[6]}
                                        direction={this.state.header_sort_direction[6]}
                                        onClick={(event) => sortData(event, 'Dataset')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Dataset</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[7]}
                                        direction={this.state.header_sort_direction[7]}
                                        onClick={(event) => sortData(event, 'LastModified')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Last Modified</h4>
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.reports.map((report) => {
                                return <TableRow key={report.report_id}>
                                    {report.favorite ?
                                        <TableCell onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')}> <StarRoundedIcon style={{ color: '#4e106f' }} /></TableCell>
                                        :
                                        <TableCell onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')}> <StarBorderRoundedIcon style={{ color: '#4e106f' }} /></TableCell>
                                    }
                                    <TableCell> {report.country} </TableCell>
                                    <TableCell> {report.industry} </TableCell>
                                    <TableCell> <a href={'/PNP/Report/' + report.report_id} >{report.report_name} </a></TableCell>
                                    <TableCell> {report.geos} </TableCell>
                                    <TableCell> {report.brands.length > 250 ? report.brands.substring(0, 250) + '...' : report.brands}</TableCell>
                                    <TableCell> {report.dataset_name} </TableCell>
                                    <TableCell> {report.timetodisplay} </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Layout>
        )
    }
}
