import * as Constant from '../../../constants'

export default async function updateAggregatedDataOutlet(filters) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let level = this.state.selectedLevel.value || 'Total'
    function assign_levels(filters, levels) {
        let newfilters = JSON.parse(JSON.stringify(filters));
        levels.forEach((level, idx) => {
            let keyname = 'level' + String((idx + 1))
            newfilters[keyname] = level
        })

        return newfilters
    }

    let levels_lookup = {
        'Total': 'total',
        'Brand': 'brand',
        'Channel': 'geogdesc',
        'Outlet': 'outlet',
        'Price Band': 'pricebandgroup',
        'Item': 'proddesc',
        'Segment 1': 'segment1_name',
        'Segment 2': 'segment2_name',
        'Segment 3': 'segment3_name',
        'Segment 4': 'segment4_name'
    }

    // Add Attributes to lookup
    Object.keys(this.state.attributes).forEach(attr => {
        levels_lookup[attr] = attr
    })

    let levels_label_lookup = {
        'Total': 'Total',
        'Brand': 'Brand',
        'Channel': 'Channel',
        'Outlet': 'Outlet',
        'Price Band': 'Price Band',
        'Item': 'Item',
        'Segment 1': this.state.segment1label,
        'Segment 2': this.state.segment2label,
        'Segment 3': this.state.segment3label,
        'Segment 4': this.state.segment4label
    }

    // Add Attributes to lookup
    Object.keys(this.state.attributes).forEach(attr => {
        levels_label_lookup[attr] = this.state.attributenamelabels[attr]
    })

    let filters_aggregate = assign_levels(filters, ['outlet', levels_lookup[level]])
    let weekly_filters = assign_levels(filters, ['outlet', 'rowdate'])

    // pull categories and level1data
    let url_level1selection = Constant.baseapiurl + "ReportData/Filtered/Level1Data/" + reportid + extraparam
    let url_level2selection = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam

    var url_for_pull, filters_for_pull, filters_for_cat_pull, categories
    if (level === 'Total') {
        url_for_pull = url_level1selection
        filters_for_pull = assign_levels(filters, ['outlet'])
        categories = ['Total']
    } else {
        url_for_pull = url_level2selection
        filters_for_pull = filters_aggregate

        // Perform Category Pull
        filters_for_cat_pull = assign_levels(filters, [levels_lookup[level]])
        let my_results = await this.FetchDataWithErrorHandling([
            { 'url': url_level1selection, 'method': 'POST', 'body': filters_for_cat_pull }
        ], 'updateAggregatedDataOutlet.js updateAggregatedDataOutlet()', 'Category')
        let cat_data = my_results['ReturnList'][0]
        categories = cat_data['level1_ordered_dollars'] || []
    }

    // Perform the Pull
    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_for_pull,        'method': 'POST', 'body': filters_for_pull },
        { 'url': url_level2selection, 'method': 'POST', 'body': weekly_filters }
    ], 'updateAggregatedDataOutlet.js updateAggregatedDataOutlet()', 'Aggregated and weekly')
    let aggregated_data = my_results['ReturnList'][0]
    let weekly_data = my_results['ReturnList'][1]
    var outlet_data_weekly = weekly_data['level2_data'] || {}

    // Get Data and Categories...
    console.log(aggregated_data)
    var aggregatedData
    if (level === 'Total') {
        aggregatedData = aggregated_data['level1_data'] || {}
        categories = ['Total']
    } else if (level === 'Price Band') {
        aggregatedData = aggregated_data['level2_data'] || {}
    } else {
        aggregatedData = aggregated_data['level2_data'] || {}
    }

    console.log('Aggregated Data State Update')
    if (level === 'Total') {
        this.setState({ outlet_data: aggregatedData })
    } else if (level === 'Brand') {
        // adjust categories for Brand to be first 10...
        categories = categories.splice(0, 10)
        this.setState({ outlet_data_brand: aggregatedData, selectedBrands: categories })
    } else if (level === 'Price Band') {
        this.setState({ outlet_data_priceband: aggregatedData })
    } else if (level === 'Channel') {
        this.setState({ outlet_data_geo: aggregatedData })
    } else if (level === 'Segment 1') {
        this.setState({ outlet_data_segment1: aggregatedData })
    } else if (level === 'Segment 2') {
        this.setState({ outlet_data_segment2: aggregatedData })
    } else if (level === 'Segment 3') {
        this.setState({ outlet_data_segment3: aggregatedData })
    } else if (level === 'Segment 4') {
        this.setState({ outlet_data_segment4: aggregatedData })
    }

    this.setState({ outlet_data_weekly, ALRvsROMCategories: categories, ALRvsROMDataset: aggregatedData, ALRvsROM_loading: false })
    return 'Completed'
}
