import * as Constant from '../../../constants'
export default async function getdatasetinfo(datasetId, items, geo, start, end) {
    //Dataset Settings
    let datasetResult = await fetch(Constant.baseapiurl + "Datasets/" + datasetId)
    var unitsvar = 'Units', attribute_lookup = {}
    if (datasetResult.status === 200) {
        let result = await datasetResult.json()
        if (Constant.promo_add_one_only.includes(result.shortdesc)) {            
            unitsvar = 'Quarts'
        }
        //Build Attributes lookup
        let attributesfromdata = result.attributes
        let hr_attributesfromdata = result.attributes_human
        attributesfromdata.forEach((attr, idx) => attribute_lookup[attr] = hr_attributesfromdata[idx])        
    }

    var datasetitems = []
    let datasetItemsResult = await fetch(Constant.baseapiurl + "Datasets/" + datasetId + "/Items")
    if (datasetItemsResult.status === 200) {
        let result = await datasetItemsResult.json()
        datasetitems = result.map(item => item.prodid)
    }

    var item_dict_lookup = {}, item_options = [], dataset_info, item_equiv_lookup = {}, attribute_options = {}, attributes = [], geolookup = {}
    let datasetParamsResult = await fetch(Constant.baseapiurl + "Datasets/" + datasetId + "/Parameters?geos=" + geo)
    if (datasetParamsResult.status === 200) {
        let result = await datasetParamsResult.json()
        dataset_info = result
        //Clean Up Geos
        result.geos.forEach(geo => {
            if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
            }
            else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
            }
            else if (geo.geogdesc.toUpperCase().indexOf('ASSG') > -1) {
                geo.geogdesc_modified = geo.geogdesc + ' (Athletic Specialty Sporting Goods)'
            }
            else {
                geo.geogdesc_modified = geo.geogdesc
            }

            geolookup[geo.geogid] = geo.geogdesc
        })
        
        result.items.forEach(item => { item_dict_lookup[item.prodid] = item.proddesc })
        item_options = items.map(item => { return { 'label': item_dict_lookup[item], 'value': item } })
        //Set Attributes of the Dataset        
        let valid_attributes = {}
        let attributes_unsorted = result.attributes.map(attr => attr.attribute)
        
        var subcat_index = attributes_unsorted.indexOf('subcategory')        
        if (subcat_index !== -1) {
            attributes_unsorted.splice(subcat_index, 1)            
            attributes = ['subcategory'].concat(attributes_unsorted.sort())
        }
        else {
            attributes = attributes_unsorted.sort()
        }

        attributes.map(attr => valid_attributes[attr] = [])
        //walk through the simitems and if we find a match, push its attribute values to valid attributes
        result.items.forEach(item => {
            if (items.includes(item.prodid)) {
                attributes.forEach(attr => {
                    if (!valid_attributes[attr].includes(item[attr])) {
                        valid_attributes[attr].push(item[attr])
                    }
                })
            }

            item_equiv_lookup[item.prodid] = 1
        })
                 
        result.attributes.forEach(attr => {
            attribute_options[attr.attribute] = valid_attributes[attr.attribute].map(val => { return { 'label': val, 'value': val } })
        })        
    }

    else {
        this.setState({ showBackground: false, errorAlert: true })
    }

    var jsonbody = JSON.stringify(datasetitems)
    let datasetBrandsFromItems = await fetch(Constant.baseapiurl + "Datasets/" + datasetId + "/BrandsFromItems",
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: jsonbody
        })

    var itembrandlookup = {}
    if (datasetBrandsFromItems.status === 200) {
        let result = await datasetBrandsFromItems.json()
        result.forEach(item => {
            itembrandlookup[item.prodid] = item.brand
        })
    }

    else {
        this.setState({ showBackground: false, errorAlert: true })
    }

    //Set Currency Code...
    let datasetsettings_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + datasetId)
    var datasetsettings_data
    if (datasetsettings_resp.status === 200) {
        datasetsettings_data = await datasetsettings_resp.json()
    }
    else {
        datasetsettings_data = []
        //this.setState({ showBackground: false, errorAlert: true })
    }

    var datasetsettings_list = datasetsettings_data.map(setting => setting.setting_name)
    var currency_resp_data
    if (datasetsettings_list.includes('currency_info')) {
        let datasetsettings_currency_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + datasetId + '/currency_info')
        if (datasetsettings_currency_resp.status === 200) {
            currency_resp_data = await datasetsettings_currency_resp.json()
            console.log(currency_resp_data)
        }
        else {
            currency_resp_data = {
                "setting_value": {
                    "currencycode": "USD",
                    "currencytype": "Dollars",
                    "currencysymbol": '$'
                },
            }
        }
    }
    else {
        currency_resp_data = {
            "setting_value": {
                "currencycode": "USD",
                "currencytype": "Dollars",
                "currencysymbol": '$'
            },
        }
    }

    let currencycode = currency_resp_data.setting_value.currencycode
    let currencytype = currency_resp_data.setting_value.currencytype
    let currencysymbol = currency_resp_data.setting_value.currencysymbol

    this.setState({
        dataset_info
        , item_dict_lookup
        , attributes
        , attribute_options
        , item_options
        , item_equiv_lookup
        , geolookup, unitsvar
        , attribute_lookup
        , itembrandlookup
        , datasetitems
        , currencycode
        , currencytype
        , currencysymbol
    })

}