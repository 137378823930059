import * as Constant from '../constants'

export function  hidePdtConfig(event) {
    this.setState({ view_pdt_config: false })
}

export function handleChangePdtDataSetConfigLong(event, newValue) {
    this.setState({ pdt_dataset_long_desc: document.getElementById("datasetconfigLongDesc").value })
}

export function handleChangePdtDataSetConfigShort(event, newValue) {
    this.setState({ pdt_dataset_short_desc: document.getElementById("datasetconfigShortDesc").value })
}

export function handleChangePdtDataSetConfigTablePrefix(event, newValue) {
    this.setState({ pdt_dataset_table_prefix: document.getElementById("datasetconfigTablePrefix").value })
}

export function handleChangePdtDataSetConfigAttributes(event, newValue) {
    this.setState({ pdt_dataset_attributes: document.getElementById("datasetconfigAttributes").value.split(',') })
}

export function handleChangePdtDataSetConfigAttributesHuman(event, newValue) {
    this.setState({ pdt_dataset_attributes_human: document.getElementById("datasetconfigAttributesHuman").value.split(',') })
}

export function handleChangePdtDataSetConfigGlobalAttributes(event, newValue) {
    this.setState({ pdt_dataset_global_attributes: document.getElementById("datasetconfigGlobalAttributes").value.split(',') })
}

export function handleChangePdtDataSetConfigGlobalAttributesHuman(event, newValue) {
    this.setState({ pdt_dataset_global_attributes_human: document.getElementById("datasetconfigGlobalAttributesHuman").value.split(',') })
}

export function handleChangePdtDataSetConfigCategoryLevel(event, newValue) {
    this.setState({ pdt_dataset_category_level: document.getElementById("datasetconfig_category_level") })
}

export function handleChangePdtDataSetConfigSegment1(event, newValue) {
    this.setState({ pdt_dataset_segment_1: document.getElementById("datasetconfigSegment1").value })
}

export function handleChangePdtDataSetConfigSegment2(event, newValue) {
    this.setState({ pdt_dataset_segment_2: document.getElementById("datasetconfigSegment2").value })
}

export function handleChangePdtDataSetConfigSegment3(event, newValue) {
    this.setState({ pdt_dataset_segment_3: document.getElementById("datasetconfigSegment3").value })
}

export function handleChangePdtDataSetConfigSegment4(event, newValue) {
    this.setState({ pdt_dataset_segment_4: document.getElementById("datasetconfigSegment4").value })
}

export function handleChangePdtDataSetConfigSel1List(event, newValue) {
    this.setState({ pdt_dataset_sel1_list: document.getElementById("datasetconfigSel1List").value })
}

export function handleChangePdtDataSetConfigSel2List(event, newValue) {
    this.setState({ pdt_dataset_sel2_list: document.getElementById("datasetconfigSel2List").value })
}

export function handleChangePdtDataSetConfigSel3List(event, newValue) {
    this.setState({ pdt_dataset_sel3_list: document.getElementById("datasetconfigSel3List").value })
}

export function handleChangePdtDataSetConfigSel4List(event, newValue) {
    this.setState({ pdt_dataset_sel4_list: document.getElementById("datasetconfigSel4List").value })
}

export function handleChangePdtDataSetConfigStoreGroups(event, newValue) {
    this.setState({ pdt_dataset_store_groups: document.getElementById("datasetconfigStoreGroups").value.split(',') })
}

export function handleChangePdtDataSetConfigIndustry(selectedOptions) {
    this.setState({ pdt_dataset_industry: selectedOptions['value'] })
}

export function handleChangePdtDataSetConfigTimeAgg(selectedOptions) {
    this.setState({ pdt_dataset_time_agg: selectedOptions['value'] })
}

export function handleChangePdtDataSetConfigBeingModified(event, newValue) {
    this.setState({ pdt_being_modified: (this.state.pdt_being_modified) ? false : true })
}

export function handleChangePdtDataSetConfigIsInvalid(event, newValue) {
    this.setState({ pdt_is_invalid: (this.state.pdt_is_invalid) ? false : true })
}

export function handleChangePdtDataSetConfigIsWholeIndustry(event, newValue) {
    this.setState({ pdt_is_whole_industry: (this.state.pdt_is_whole_industry) ? false : true })
}

export function handleChangePdtDataSetConfigFlags(event, newValue) {
    this.setState({ pdt_dataset_flags: document.getElementById("datasetconfigFlags").value.split(',') })
}

export async function openPdtDataSetInfo(dataset_id) {
    if (!dataset_id) {
        alert('There is no Dataset Associated with this Config')
        return
    }

    console.log('Getting PDT Dataset Information for id ' + dataset_id)
    let url = Constant.baseapiurl_gen + 'pdt/Datasets/' + dataset_id
    let results = await this.entityCrud('getting PDT Dataset information for ID ' + dataset_id, [
        { 'url': url, 'method': 'GET', 'body': null },
        { 'url': url + '/StoreGroups', 'method': 'GET', 'body': null },
        { 'url': url + '/Brands', 'method': 'GET', 'body': null },
        { 'url': url + '/Times', 'method': 'GET', 'body': null },
        { 'url': url + '/AttributeValues', 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var datasetsettings_data = results['ResultList'][0]
        var datasetgeosettings_data = results['ResultList'][1]
        var datasetbrandsettings_data = results['ResultList'][2]
        var datasettimesettings_data = results['ResultList'][3]
        var datasetattrsettings_data = results['ResultList'][4]

        let brand_name_list = []
        datasetbrandsettings_data.map(brand_info => brand_name_list.push(brand_info.brand_name))
        datasetbrandsettings_data = brand_name_list

        // Build a lookup of human-readable attributes
        let pdt_attr_lookup = {}
        datasetsettings_data.attributes.forEach((attr, idx) => {
            pdt_attr_lookup[attr] = datasetsettings_data.attributes_human[idx]
        })

        this.setState({
            datasetsettings_data,
            datasetgeosettings_data,
            datasetbrandsettings_data,
            datasettimesettings_data,
            datasetattrsettings_data,
            pdt_attr_lookup,
            show_pdt_dataset_info: true
        })
    }
}

export function handleOpenNewPdtConfig() {
    this.setState({
        config_data: {},
        pdt_dataset_long_desc: null,
        pdt_dataset_short_desc: null,
        pdt_dataset_table_prefix: null,
        pdt_dataset_attributes: [],
        pdt_dataset_attributes_human: [],
        pdt_dataset_global_attributes: [],
        pdt_dataset_global_attributes_human: [],
        pdt_dataset_store_groups: [],
        pdt_dataset_category_level: null,
        pdt_dataset_segment_1: null,
        pdt_dataset_segment_2: null,
        pdt_dataset_segment_3: null,
        pdt_dataset_segment_4: null,
        pdt_dataset_sel1_list: null,
        pdt_dataset_sel2_list: null,
        pdt_dataset_sel3_list: null,
        pdt_dataset_sel4_list: null,
        pdt_dataset_time_agg: null,
        pdt_being_modified: false,
        pdt_is_invalid: false,
        pdt_is_whole_industry: false,
        pdt_dataset_flags: [],
        pdt_dataset_industry: null,
        pdt_dataset_etl_id: null,
        view_pdt_config_new: true
    })
}

export async function openEditPdtDataSetConfig(configid) {
    if (!configid) {
        alert('There is no Dataset Associated with this Config')
        return
    }

    console.log('Getting Config for PDT Dataset ID ' + configid)
    let results = await this.entityCrud('getting PDT Dataset configuration for ID ' + configid, [
        { 'url': Constant.baseapiurl_gen + 'pdt/DatasetLoading/Import/' + configid, 'method': 'GET', 'body': null }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var config_data = results['ResultList'][0]
        this.setState({
            config_data,
            view_pdt_config: true,
            pdt_dataset_long_desc: config_data.description,
            pdt_dataset_short_desc: config_data.short_desc,
            pdt_dataset_table_prefix: config_data.dataset_prefix,
            pdt_dataset_attributes: config_data.attributes,
            pdt_dataset_attributes_human: config_data.attributes_human,
            pdt_dataset_global_attributes: config_data.global_attributes,
            pdt_dataset_global_attributes_human: config_data.global_attributes,
            pdt_dataset_category_level: config_data.category_level,
            pdt_dataset_segment_1: config_data.segment1,
            pdt_dataset_segment_2: config_data.segment2,
            pdt_dataset_segment_3: config_data.segment3,
            pdt_dataset_segment_4: config_data.segment4,
            pdt_dataset_sel1_list: config_data.sel1_list,
            pdt_dataset_sel2_list: config_data.sel2_list,
            pdt_dataset_sel3_list: config_data.sel3_list,
            pdt_dataset_sel4_list: config_data.sel4_list,
            pdt_dataset_store_groups: config_data.store_groups,
            pdt_dataset_time_agg: config_data.time_aggregation,
            pdt_being_modified: config_data.being_modified,
            pdt_is_invalid: config_data.is_invalid,
            pdt_is_whole_industry: config_data.is_whole_industry,
            pdt_dataset_flags: config_data.dataset_flags,
            pdt_dataset_industry: config_data.industry_id,
            pdt_dataset_etl_id: config_data.etl_id
        })
    }
}

export async function handleSubmitPdtConfigNew() {
    this.setState({ pdt_edit_button_enabled: false })
    var config = {
        dataset_prefix: this.state.pdt_dataset_table_prefix,
        short_desc: this.state.pdt_dataset_short_desc,
        description: this.state.pdt_dataset_long_desc,
        store_groups: this.state.pdt_dataset_store_groups,
        category_level: this.state.pdt_dataset_category_level,
        segment1: this.state.pdt_dataset_segment_1,
        segment2: this.state.pdt_dataset_segment_2,
        segment3: this.state.pdt_dataset_segment_3,
        segment4: this.state.pdt_dataset_segment_4,
        sel1_list: this.state.pdt_dataset_sel1_list,
        sel2_list: this.state.pdt_dataset_sel2_list,
        sel3_list: this.state.pdt_dataset_sel3_list,
        sel4_list: this.state.pdt_dataset_sel4_list,
        attributes: this.state.pdt_dataset_attributes,
        attributes_human: this.state.pdt_dataset_attributes_human,
        global_attributes: this.state.pdt_dataset_global_attributes,
        global_attributes_human: this.state.pdt_dataset_global_attributes_human,
        time_aggregation: this.state.pdt_dataset_time_agg,
        being_modified: this.state.pdt_being_modified,
        is_invalid: this.state.pdt_is_invalid,
        is_whole_industry: this.state.pdt_is_whole_industry,
        dataset_flags: this.state.pdt_dataset_flags,
        industry_id: this.state.pdt_dataset_industry,
        etl_id: this.state.pdt_dataset_etl_id
    }

    console.log('Submitting new PDT dataset configuration')
    let results = await this.entityCrud('submit for new PDT dataset configuration', [
        { 'url': Constant.baseapiurl_gen + 'pdt/DatasetLoading/Import', 'method': 'POST', 'body': config }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        this.setState({ datasetinfo_data: results['ResultList'][0] })
        this.handleCloseNewConfig()
        alert('Changes Made Successfully')
    }

    this.setState({ pdt_edit_button_enabled: true })
}

export async function handleSubmitPdtConfig() {
    this.setState({ pdt_edit_button_enabled: false })
    let config_data = this.state.config_data

    var dataset_id = config_data.dataset_id
    var config = {
        dataset_id: dataset_id,
        time_updated: config_data.time_updated,
        dataset_prefix: this.state.pdt_dataset_table_prefix,
        short_desc: this.state.pdt_dataset_short_desc,
        description: this.state.pdt_dataset_long_desc,
        store_groups: this.state.pdt_dataset_store_groups,
        category_level: this.state.pdt_dataset_category_level,
        segment1: this.state.pdt_dataset_segment_1,
        segment2: this.state.pdt_dataset_segment_2,
        segment3: this.state.pdt_dataset_segment_3,
        segment4: this.state.pdt_dataset_segment_4,
        sel1_list: this.state.pdt_dataset_sel1_list,
        sel2_list: this.state.pdt_dataset_sel2_list,
        sel3_list: this.state.pdt_dataset_sel3_list,
        sel4_list: this.state.pdt_dataset_sel4_list,
        attributes: this.state.pdt_dataset_attributes,
        attributes_human: this.state.pdt_dataset_attributes_human,
        global_attributes: this.state.pdt_dataset_global_attributes,
        global_attributes_human: this.state.pdt_dataset_global_attributes_human,
        time_aggregation: this.state.pdt_dataset_time_agg,
        being_modified: this.state.pdt_being_modified,
        is_invalid: this.state.pdt_is_invalid,
        is_whole_industry: this.state.pdt_is_whole_industry,
        dataset_flags: this.state.pdt_dataset_flags,
        industry_id: this.state.pdt_dataset_industry,
        etl_id: this.state.pdt_dataset_etl_id
    }

    console.log('Updating PDT dataset configuration for dataset ID ' + dataset_id)
    let results = await this.entityCrud('submit for updated PDT dataset configuration for dataset ' + dataset_id, [
        { 'url': Constant.baseapiurl_gen + 'pdt/DatasetLoading/Import/' + config_data.dataset_id, 'method': 'PUT', 'body': config }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        this.hidePdtConfig()
        alert('Changes Made Successfully')
    }

    this.setState({ pdt_edit_button_enabled: true })
}

export function handleClosePdtNewConfig() {
    this.setState({ view_pdt_config_new: false })
}

export function handleClosePdtDatasetInfo() {
    this.setState({ show_pdt_dataset_info: false })
}
