import React, { Component, Fragment } from 'react';
import { UnitsDollarSwitch } from '../../../UIElements/Switches/UnitsDollarSwitch'
import { BaseIncPromoNonPromoRadio } from '../../../UIElements/Radios/BaseIncPromoNonPromoRadio'
import { MetricLevelOptionsBaseInc } from '../../../UIElements/Dropdowns/MetricLevelOptionsBaseInc'
//import { FilterBarReportViews } from '../../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../../UIElements/Filters/FilterBarReportViewsFunctional';
import { RankOptions } from '../../../UIElements/Dropdowns/RankOptions'
import { PriceBandDefIcon } from '../../../UIElements/IconButtons/PriceBandDefIcon'
import { DownloadPrevYearsCheckbox } from '../../../UIElements/Checkboxes/DownloadPrevYearsCheckbox'
import { DownloadAggregateDataAll } from '../../../UIElements/IconButtons/DownloadAggregateDataAll'
import LiftEffDiscPct from '../../../../Graphs/LiftEffDiscPct'
import * as Constant from '../../../../constants'

export class LiftEffDiscAggregate_New extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }
        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        function filterArray(array, start, end) {
            let new_array = array.map((value, idx) => {
                if (idx >= start && idx < end) {
                    return value
                }
                else {
                    return null
                }
            }).filter(x => x !== null)
            return new_array
        }

        //create series list....
        //Use Aggregated Data
        var LiftGraphData, PromoLiftGraphData, EffGraphData, DiscGraphData, categories, unitsvar
        var categories_dollars = this.props.state.agg_categories_dollars || []
        var categories_units = this.props.state.agg_categories_units || []
        var RankStart = this.props.state.RankStart || 0
        var RankEnd = this.props.state.RankEnd || 10

        if (this.props.state.indexSwitchCheckDollars) {
            categories = filterArray(categories_dollars, RankStart, RankEnd)
            unitsvar = this.props.state.currencyType
            LiftGraphData = filterArray(categories_dollars.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['incrementaldollars'] / (this.props.state.aggregatedData[key] || {})['basedollars']),10)), RankStart, RankEnd)
            PromoLiftGraphData = filterArray(categories_dollars.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['incrementaldollars'] / (this.props.state.aggregatedData[key] || {})['subsidizedbasedollars']), 10)), RankStart, RankEnd)
            EffGraphData = filterArray(categories_dollars.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['incrementaldollars'] / (this.props.state.aggregatedData[key] || {})['promoteddollars']), 1)), RankStart, RankEnd)
            DiscGraphData = filterArray(categories_dollars.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['weightedpctdisc_withoutno'] / (this.props.state.aggregatedData[key] || {})['totalunits_withoutno']), 1)), RankStart, RankEnd)
        }
        else {
            categories = filterArray(categories_units, RankStart, RankEnd)
            unitsvar = this.props.state.unitsvar
            LiftGraphData = filterArray(categories_units.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['incrementalunits'] / (this.props.state.aggregatedData[key] || {})['baseunits']), 10)), RankStart, RankEnd)
            PromoLiftGraphData = filterArray(categories_units.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['incrementalunits'] / (this.props.state.aggregatedData[key] || {})['subsidizedbaseunits']), 10)), RankStart, RankEnd)
            EffGraphData = filterArray(categories_units.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['incrementalunits'] / (this.props.state.aggregatedData[key] || {})['promotedunits']), 1)), RankStart, RankEnd)
            DiscGraphData = filterArray(categories_units.map(key => Math.min(((this.props.state.aggregatedData[key] || {})['weightedpctdisc_withoutno'] / (this.props.state.aggregatedData[key] || {})['totalunits_withoutno']), 1)), RankStart, RankEnd)
        }

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div className="col-sm-4" >
                        <MetricLevelOptionsBaseInc
                            state={this.props.state}
                            changeSelectedLevel_BaseInc={this.props.changeSelectedLevel_BaseInc}
                        />
                    </div>
                    <div className="col-sm-2 row" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    <div className="col-sm-6 row">
                        <BaseIncPromoNonPromoRadio
                            state={this.props.state}
                            handleChangeRadioBasePromo={this.props.handleChangeRadioBasePromo}
                            handleToggle_stackingType={this.props.handleToggle_stackingType}
                        />
                    </div>
                    {(this.props.state.RankOptions || []).length > 1 ?
                        <div align='left' className="col-sm-3" >
                            <RankOptions
                                state={this.props.state}
                                changeRank={this.props.changeRank}
                            />
                        </div>
                        :
                        <div />
                    }

                    {this.props.state.level_baseinc === 'Price Band' ?
                        <div align='left' className="col-sm-3" >
                            <PriceBandDefIcon
                                state={this.props.state}
                            />
                        </div>
                        :
                        <div />
                    }
                </div>

                <div className="row">
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <Fragment>
                            <LiftEffDiscPct
                                LiftGraphData={LiftGraphData}
                                PromoLiftGraphData={PromoLiftGraphData}
                                EffGraphData={EffGraphData}
                                DiscGraphData={DiscGraphData}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                showDataTable={this.props.state.showDataTable}
                                unitsvar={unitsvar}
                                categories={categories}
                                attr_caption_text={this.props.state.attr_caption_text}
                                chart_title_info={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.selectedBrand === 'Total Selection' ?
                                            this.props.state.chart_title_info_nobrands + ' Brand(s) Selected: ' + this.props.state.brandsstring
                                            :
                                            this.props.state.chart_title_info_nobrands + ' Brand Selected: ' + this.props.state.selectedBrand
                                        :
                                        this.props.state.chart_title_info
                                }
                                level={this.props.state.level_baseinc}
                                appname='PDT'
                                metadata={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.chartmetadata_singlebrand
                                        :
                                        this.props.state.chartmetadata
                                }
                            />
                        </Fragment>
                    </div>
                </div>
            </div>
        )
    }
}
