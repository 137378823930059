export default function createRankOptions(numbertorank, labelbeginning) {
    let rankOptions = []
    var labelstring, labelstart, labelend, numiterations

    if (numbertorank % 10 === 0) {
        numiterations = (numbertorank / 10) - 1
    }
    else {
        numiterations = Math.floor(numbertorank / 10)
    }

    for (var i = 0; i <= numiterations; i++) {
        labelstart = (i * 10) + 1
        if (i === numiterations) {
            labelend = numbertorank
        }
        else {
            labelend = Math.min(((i + 1) * 10), numbertorank)
        }

        labelstring = labelbeginning + labelstart + ' - ' + labelend
        rankOptions.push({ 'label': labelstring, 'value': [(i * 10), labelend] })
    }
    return (rankOptions)
}