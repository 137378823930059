import React, { Component } from 'react';
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import LiftEffDiscPct from '../../../Graphs/LiftEffDiscPct'

export class ALRvsROM_LiftEfficiency extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        {this.props.state.indexSwitchCheckDollars ?
                            <div>
                                <LiftEffDiscPct
                                    LiftGraphData={this.props.state.outlets.map(outlet => (this.props.state.outlet_data[outlet]['incrementaldollars'] / this.props.state.outlet_data[outlet]['basedollars']))}
                                    PromoLiftGraphData={this.props.state.outlets.map(outlet => (this.props.state.outlet_data[outlet]['incrementaldollars'] / this.props.state.outlet_data[outlet]['subsidizedbasedollars']))}
                                    EffGraphData={this.props.state.outlets.map(outlet => (this.props.state.outlet_data[outlet]['incrementaldollars'] / this.props.state.outlet_data[outlet]['promoteddollars']))}
                                    DiscGraphData={this.props.state.outlets.map(outlet => Math.min((this.props.state.outlet_data[outlet]['weightedpctdisc_withoutno'] / this.props.state.outlet_data[outlet]['totalunits_withoutno']), 1))}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    showDataTable={this.props.state.showDataTable}
                                    unitsvar={this.props.state.unitsvar}
                                    categories={this.props.state.outlets}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    chart_title_info={this.props.state.chart_title_info}
                                    level='Outlet'
                                    appname='PDT'
                                    metadata={this.props.state.chartmetadata}
                                />
                            </div>
                            :
                            <div>
                                <LiftEffDiscPct
                                    LiftGraphData={this.props.state.outlets.map(outlet => (this.props.state.outlet_data[outlet]['incrementalunits'] / this.props.state.outlet_data[outlet]['baseunits']))}
                                    PromoLiftGraphData={this.props.state.outlets.map(outlet => (this.props.state.outlet_data[outlet]['incrementalunits'] / this.props.state.outlet_data[outlet]['subsidizedbaseunits']))}
                                    EffGraphData={this.props.state.outlets.map(outlet => (this.props.state.outlet_data[outlet]['incrementalunits'] / this.props.state.outlet_data[outlet]['promotedunits']))}
                                    DiscGraphData={this.props.state.outlets.map(outlet => Math.min((this.props.state.outlet_data[outlet]['weightedpctdisc_withoutno'] / this.props.state.outlet_data[outlet]['totalunits_withoutno']), 1))}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    showDataTable={this.props.state.showDataTable}
                                    unitsvar={this.props.state.unitsvar}
                                    categories={this.props.state.outlets}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    chart_title_info={this.props.state.chart_title_info}
                                    level='Outlet'
                                    appname='PDT'
                                    metadata={this.props.state.chartmetadata}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
