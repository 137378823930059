import '../styles/custom.css'
import * as Constant from '../constants'

export async function onChangePdtStackingCountry(selectedOptions, action) {
    var selectedCountry = selectedOptions.value
    var selectedCountryName = selectedOptions.label
    this.setState({
        selectedCountry,
        selectedCountryName,
        selectedIndustry: null,
        selectedIndustryName: '',
        selectedTemplateDatasetId: '',
        selectedTemplateDatasetName: '',
        extra_dataset_options_values: [],
        pdt_stacking_prefix: '',
        pdt_stacking_short_desc: '',
        pdt_stacking_description: '',
    })

    // Read in Industries from Country lookup
    let results = await this.entityCrud('Getting industries for PDT stacking for country ' + selectedCountry, [
        { 'url': Constant.baseapiurl_gen + "etl/IndustryTables/ByCountry/" + selectedCountry, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results.Errors > 0) {
        return
    }

    let industry_country_data = results.ResultList[0]
    let countryInd_options = []
    industry_country_data.forEach(country => {
        countryInd_options.push({ 'label': country.industry_name, 'value': country.industry_id })
    })
    countryInd_options = countryInd_options.sort((a, b) => a.label.localeCompare(b.label))
    this.setState({ countryInd_options })
}

export async function onChangePdtStackingIndustry(selectedOptions, action) {
    var selectedIndustry = selectedOptions.value
    var selectedIndustryName = selectedOptions.label
    this.setState({
        selectedIndustry,
        selectedIndustryName,
        selectedTemplateDatasetId: '',
        selectedTemplateDatasetName: '',
        extra_dataset_options_values: [],
        pdt_stacking_prefix: '',
        pdt_stacking_short_desc: '',
        pdt_stacking_description: '',
    })

    await this.getTemplateDatasetList(selectedIndustry, this.state.stacking_env)
}

export async function onChangePdtStackingEnvRadio(event) {
    this.setState({
        stacking_env: event.target.value,
        selectedTemplateDatasetId: '',
        selectedTemplateDatasetName: '',
        extra_dataset_options_values: [],
        pdt_stacking_prefix: '',
        pdt_stacking_short_desc: '',
        pdt_stacking_description: '',
    })
    // If someone is changing the radio button before we have selected a country and industry, don't do anything more
    if (this.state.selectedIndustry === null) {
        return
    }
    await this.getTemplateDatasetList(this.state.selectedIndustry, event.target.value)
}

export async function getTemplateDatasetList(selectedIndustry, stacking_env) {
    // Read in Industry Lookup Table (parent and children)
    let results = await this.entityCrud('getting template datasets for industry ' + selectedIndustry + ' for ' + stacking_env, [
        { 'url': Constant.baseapiurl_gen + 'pdt/Datasets/ByIndustry/' + selectedIndustry + '?env=' + stacking_env, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results.Errors > 0) {
        return
    }

    let dataset_list = results.ResultList[0]

    let template_dataset_options = []
    dataset_list.forEach(dataset => {
        template_dataset_options.push({ 'label': dataset.short_desc, 'value': dataset.dataset_id })
    })
    template_dataset_options = template_dataset_options.sort((a, b) => a.label.localeCompare(b.label))
    this.setState({ template_dataset_options })
}

export async function onChangePdtStackingTemplateDataset(selectedOptions, action) {
    var selectedTemplateDatasetId = selectedOptions.value
    var selectedTemplateDatasetName = selectedOptions.label
    this.setState({
        selectedTemplateDatasetId,
        selectedTemplateDatasetName,
        extra_dataset_options_values: [],
        pdt_stacking_prefix: '',
        pdt_stacking_short_desc: '',
        pdt_stacking_description: '',
    })

    let results = await this.entityCrud('Getting compatible datasets list for template dataset ID ' + selectedTemplateDatasetId + ' for ' + this.state.stacking_env, [
        { 'url': Constant.baseapiurl_gen + 'pdt/Stacking/Compatible_datasets/' + selectedTemplateDatasetId + '?env=' + this.state.stacking_env, 'method': 'GET', 'body': null },
        { 'url': Constant.baseapiurl_gen + 'pdt/Datasets/' + selectedTemplateDatasetId + '?env=' + this.state.stacking_env, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results.Errors > 0) {
        return
    }

    let dataset_list = results.ResultList[0]
    let template_dataset_info = results.ResultList[1]
    let extra_dataset_options = []
    dataset_list.forEach(dataset => {
        extra_dataset_options.push({ 'label': dataset.short_desc, 'value': dataset.dataset_id })
    })
    extra_dataset_options = extra_dataset_options.sort((a, b) => a.label.localeCompare(b.label))

    // Don't fill in a default prefix unless we get at least one possible dataset to stack with
    let default_prefix = (extra_dataset_options.length > 0) ? template_dataset_info.dataset_prefix + '_stacked' : ''
    this.setState({
        extra_dataset_options,
        pdt_stacking_prefix: default_prefix,
        pdt_stacking_short_desc: '',
        pdt_stacking_description: ''
    })
}

export async function onChangePdtStackingExtraDatasets(selectedOptions, action) {
    let extra_dataset_options_values = selectedOptions
    let pdt_stacking_description = 'Datasets "' + this.state.selectedTemplateDatasetName + '"'
    if (selectedOptions !== null) {
        pdt_stacking_description += ' and ' + (selectedOptions || []).map(x => '"' + x.label + '"').join(' and ') + ' Stacked'
    }

    let button_enabled = (this.state.pdt_stacking_short_desc !== null && this.state.pdt_stacking_short_desc.length > 0
        && selectedOptions !== null && selectedOptions.length > 0)
    this.setState({
        extra_dataset_options_values,
        pdt_stacking_description,
        pdt_stacking_button_enabled: button_enabled
    })
}

export function onChangePdtStackingPrefix(event, newValue) {
    this.setState({ pdt_stacking_prefix: document.getElementById('pdtStackingPrefix').value })
}

export function onChangePdtStackingShortDesc(event, newValue) {
    let short_desc = document.getElementById('pdtStackingShortDesc').value
    let button_enabled = (short_desc !== null && short_desc.length > 0
        && this.state.extra_dataset_options_values !== null && this.state.extra_dataset_options_values.length > 0)
    this.setState({
        pdt_stacking_short_desc: short_desc,
        pdt_stacking_button_enabled: button_enabled
    })
}

export function onChangePdtStackingLongDesc(event, newValue) {
    this.setState({ pdt_stacking_description: document.getElementById('pdtStackingLongDesc').value })
}

export async function handleSubmitPdtStacking() {
    this.setState({ pdt_stacking_button_enabled: false })

    let request_body = {
        "template_dataset_id": this.state.selectedTemplateDatasetId,
        "dataset_list": this.state.extra_dataset_options_values.map(x => x.value),
        "dataset_prefix": this.state.pdt_stacking_prefix,
        "short_desc": this.state.pdt_stacking_short_desc,
        "description": this.state.pdt_stacking_description
    }

    let results = await this.entityCrud('Stacking ' + this.state.extra_dataset_options_values.length + ' datasets with template dataset ID ' + this.state.selectedTemplateDatasetId + ' for ' + this.state.stacking_env, [
        { 'url': Constant.baseapiurl_gen + 'pdt/Stacking/Stack?env=' + this.state.stacking_env, 'method': 'POST', 'body': request_body },
    ], this.on_api_entity_crud_error)
    this.setState({ pdt_stacking_button_enabled: true })
    if (results.Errors > 0) {
        return
    }
    alert('Stacking was successful')
}
