export default function handleChangeASPMaxBox (event, newValue) {
    const setfilters = this.setfilters
    var input = document.getElementById("aspmax");
    let value = parseFloat(input.value)
    var sliderRange = []
    if (isNaN(value)) {
        alert('You Must Only input Numbers.  Resetting to ASP Maximum')
        sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
        this.setState({ sliderRange }, () => { setfilters(sliderRange, 'asprange') })
    }

    else {
        //check that value >= 0 and <= max
        if (value < 0 || value > this.state.sliderRangeMax) {
            let alerttext = 'Value Entered Must Be Between' + this.state.sliderRangeMin + ' and ' + this.state.sliderRangeMax + '. Resetting ASP Maximum'
            sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
            this.setState({ sliderRange }, () => { setfilters(sliderRange, 'asprange') })
            alert(alerttext)
        }

        else {
                    
            sliderRange = [this.state.sliderRange[0], value]
            this.setState({ sliderRange }, () => { setfilters(sliderRange, 'asprange') })
        }

    }

            
    // Execute a function when the user releases a key on the keyboard
    let numruns = 0
    input.addEventListener("keyup", function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            console.log('Enter Key Hit')
        }
    });
}
