import { createContext } from "react";

const initialValue = {
    use_attr_groups: false,
    customAttrGroups: {},
    filter_view_selected: {},
    filter_view_options: {},
    changeViewFilters: () => {},
    changeFilterPause_reportViews: () => {},
    all_geos: [],
    all_outlets: [],
    dataset_data: {},
    segment1label: null,
    segment2label: null,
    segment3label: null,
    segment4label: null,
    isLoaded_Filters: false,
    handleToggle_useAttrGroups: () => {},
    filterPause_reportViews: null,
    slicers_not_applied: true,
    priceBandOptions: [],
    item_options_filters: [],
    attributenamelabels: {},
    attributes: {},
    selected_attr_groups: {},

    customUserAttrGroups: {},
    user_selected_attr_groups: {},
    setUserSelectedAttrGroups: () => {}
}

export const ReportContext = createContext(initialValue)
