import React, { Component } from 'react';
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { VolumeYOYGraph } from '../../../Graphs/VolumeYOYGraph'
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class VolumeYOY extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
        this.state = {
            metric: 'totalunits',
            selectedMetric: { 'label': 'Total', 'value': 'total' },
            units_dollars: 'units',
            metricOptions: [
                { 'label': 'Total', 'value': 'total' },
                { 'label': 'Base', 'value': 'base' },
                { 'label': 'Subsidized Base', 'value': 'subsidizedbase' },
                { 'label': 'UnSubsidized Base', 'value': 'unsubsidizedbase' },
                { 'label': 'Incremental', 'value': 'incremental' },
                { 'label': 'Promoted', 'value': 'promoted' },
                { 'label': 'Non-Promoted', 'value': 'nonpromoted' },
            ],
            formatting_object: {
                type: 'units',
                axisText: this.props.state.unitsvar,
                metricName: 'Total ' + this.props.state.unitsvar
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    componentDidUpdate() {
        console.log('This is an Update')
        if (this.props.state.indexSwitchCheckDollars && this.state.units_dollars === 'units') {
            this.setState({                
                metric: this.state.selectedMetric.value + 'dollars',
                units_dollars: 'dollars',
                formatting_object: {
                    type: 'dollars',
                    axisText: this.props.state.currencyType,
                    metricName: this.state.selectedMetric.label + ' ' + this.props.state.currencyType
                }
            })
        }
        else if (!this.props.state.indexSwitchCheckDollars && this.state.units_dollars === 'dollars') {
            this.setState({
                metric: this.state.selectedMetric.value + 'units',
                units_dollars: 'units',
                formatting_object: {
                    type: 'units',
                    axisText: this.props.state.unitsvar,
                    metricName: this.state.selectedMetric.label + ' ' + this.props.state.unitsvar
                }
            })
        }
    }

    render() {
        console.log(this.state)
        const changeSelectedMetric = selectedOptions => {
            console.log(selectedOptions)
            if (selectedOptions) {

                var units_dollars, formatting_object
                if (this.props.state.indexSwitchCheckDollars) {
                    units_dollars = 'dollars'
                    formatting_object = {
                        type: units_dollars,
                        axisText: this.props.state.currencyType,
                        metricName: selectedOptions.label + this.props.state.currencyType
                    }
                }

                else {
                    units_dollars = 'units'
                    formatting_object = {
                        type: units_dollars,
                        axisText: this.props.state.unitsvar,
                        metricName: selectedOptions.label  + this.props.state.unitsvar
                    }
                }

                var metric = selectedOptions.value + units_dollars
                this.setState({
                    selectedMetric: selectedOptions,
                    metric,
                    units_dollars,
                    formatting_object
                })
            }
            else {
                this.setState({
                    selectedMetric: { 'label': 'Total', 'value': 'total' },
                    metric: 'totalunits',
                    units_dollars: 'units',
                    formatting_object: {
                        type: 'units',
                        axisText: this.props.state.unitsvar,
                        metricName: 'Total ' + this.props.state.unitsvar
                    }
                })
            }
        }

        return (

            <div style={{ width: "100%", height: "100%" }}>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row col-sm-12">
                    <div className="col-sm-6">
                        <span className="formfieldtitle">Select A Metric</span>
                        <Select
                            options={this.state.metricOptions}
                            placeholder={'Select A Metric'}
                            onChange={changeSelectedMetric}
                            value={this.state.selectedMetric}
                            styles={Constant.dropdownStyles}
                            styles={selectColourStyles}
                        />
                    </div>
                    <div className="row col-sm-6">
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>

                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <VolumeYOYGraph
                            state={this.props.state}
                            app='PDT'
                            metric={this.state.metric}
                            formatting_object={this.state.formatting_object}
                        />
                    </div>
                </div>
            </div>
        )
    }
}