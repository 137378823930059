import * as Constant from '../../../constants'

export default async function updateCommonItems(filters) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_for_pull = Constant.baseapiurl + 'ReportData/Filtered/OutletCommonItems/' + reportid + extraparam

    // Perform the Pull
    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_for_pull, 'method': 'POST', 'body': filters }
    ], 'UpdateCommonItems.js updateCommonItems()', 'Common Items')
    let items_data = my_results['ReturnList'][0]
    let commonoutletitems = items_data['commonitems']

    // SetState
    console.log('Common Items Data State Update')
    this.setState({ commonitems_new: commonoutletitems })

    // Return a completion...
    return 'Completed'
}
