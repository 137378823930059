import * as Constant from '../constants'

export async function entityCrud(crud_description, api_requests, on_error) {
    console.log(crud_description + ' started')
    const start = performance.now()

    // Use Promise.allSettled() so that we will wait for every reqeust to complete, whether
    // successful or not, and we'll get the status of each.
    let result_list = await Promise.allSettled(api_requests.map(request => {
        let responseType = (request.responseType !== undefined) ? request.responseType : 'json'
        if (request.method === 'GET' || request.body === null) {
            return fetch(request.url,
                {
                    method: request.method,
                    headers: Constant.json_headers,
                    responseType: responseType
                })
        } else {
            return fetch(request.url,
                {
                    method: request.method,
                    headers: Constant.json_headers,
                    responseType: responseType,
                    body: JSON.stringify(request.body)
                })
        }
    }))

    // We have to wrap the map() in "await Promise.all()" to get an array of the *result* of each Promise
    // rather than getting an array of completed Promises that we then have to unwrap individually.
    let results = await Promise.all(await api_requests.map(async (request, i) => {
        let shared_string = crud_description + ' #' + i + ' for ' + request.method + ' ' + request.url
        let response = result_list[i].value
        if (response == null) {
            console.log('**** Promise failed for ' + shared_string + ':')
            console.log(result_list[i])
            return null
        } else if (response.status === 200 || response.status === 202) {
            if (request.responseType === 'blob') {
                return await response.blob()
            } else {
                return await response.json()
            }
        } else {
            // We include 207 -- failure of some or all batch requests -- in this block
            console.log('**** Failed to fetch ' + shared_string + ' with status ' + response.status)
            console.log('The body of the response to the failure is:')
            const contentType = response.headers.get('content-type')
            let body
            if (contentType && contentType.indexOf('application/json') !== -1) {
                body = await response.json()
                console.log(body)
                return body
            } else {
                // If the body is not JSON it is most likely HTML, in which case there's no value in logging all of it
                body = await response.text()
                if (body.length > 250) {
                    body = body.substr(0, 250)
                } else if (body.length === 0) {
                    console.log('(The body to the response is zero-length)')
                } else {
                    console.log(body)
                }
                return null
            }
        }
    }))

    var error_list = []
    function handle_errors(promise_status, idx, request, result, status_code, batch_idx, batch_len) {
        var EntityErrors
        if (result != null) {
            var { Status, Message, Messages, MessageList } = result
            if (Messages !== undefined) {
                EntityErrors = Messages.EntityErrors
            }
        } else {
            // If the API is down, get "undefined" values for these variables
            var Status, Message, Messages, MessageList
        }

        error_list.push({
            index: idx,
            len: result_list.length,
            promise_status: promise_status,
            request: request,
            response_json: result,
            status: status_code,
            status_message: Status, // This will always be the string "Error"
            error_message: Message, // "Message" is a single string, single line summary of the complaint
            error_entity_list: EntityErrors, // "Messages" is actually a list of entities, not a list of messages
            error_message_list: MessageList,  // "MessageList" is a list of strings
            batch_index: batch_idx,
            batch_len: batch_len
        })
    }

    // Figure out how many errors we had, either promises not fulfilled or HTTP failures
    var total_requests = api_requests.length
    var total_length = 0
    var total_errors = 0
    var not_fulfilled = 0
    result_list.forEach((rsp, idx) => {
        if (rsp.status === 'fulfilled') {
            let response = rsp.value
            total_length += (response.headers.has('content-length')) ? Number(response.headers.get('content-length')) : 0
            // Include a batch HTTP 207 response as "not an error" because we handle that error case separately
            let not_an_error = (response.status === 200 || response.status === 202 || response.status === 207)
            total_errors += (not_an_error) ? 0 : 1
            // If a batch request fails with HTTP 4xx or HTTP 5xx, then we won't have an array of results
            if (not_an_error && api_requests[idx].url.endsWith('/api/pdt/Batch')) {
                total_requests += (results[idx].length - 1)
            }
            if (response.status === 207) {
                // Handle failures in a batch request individually here
                // 207 will only be returned in response to a batch reqyest if and only if at least one subrequest failed
                results[idx].forEach((result, batch_idx) => {
                    if (result.status_code != 200 && result.status_code != 202) {
                        total_errors += 1
                        handle_errors(rsp.status, idx, result.request, result.body, result.status_code, batch_idx, results[idx].length)
                    }
                })
            } else if (response.status >= 400) {
                handle_errors(rsp.status, idx, api_requests[idx], results[idx], response.status, undefined, undefined)
            }
        } else {
            // If the promise is not fulfilled, it must be rejected (as we waited for all promises to be completed, so none are "pending")
            total_errors += 1
            not_fulfilled += 1
            error_list.push({
                index: idx,
                len: result_list.length,
                promise_status: rsp.status,
                request: api_requests[idx],
                response_json: null,
                status: undefined,
                status_message: undefined,
                error_message: JSON.stringify(rsp.reason), // Since the promise is rejected, we should have a reason
                error_entity_list: undefined,
                error_message_list: undefined,
                batch_index: undefined,
                batch_len: undefined
            })
        }
    })

    let error_str = (total_errors > 0) ? 'with ' + total_errors + '/' + total_requests + ' errors, and ' : '(' + total_requests + ' requests) '
    let len_str = 'total received bytes = ' + total_length + ' (' + Number(total_length / (1024. * 1024.)).toFixed(2) + ' MB)'
    let fulfilled = (not_fulfilled > 0) ? ' (' + not_fulfilled + ' requests failed in the browser)' : ''
    let timing = Number((performance.now() - start) / 1000.).toFixed(1)
    console.log(crud_description + ' finished ' + error_str + len_str + fulfilled + ' after ' + timing + ' sec')

    if (total_errors > 0 && on_error !== null && on_error !== undefined) {
        console.log(crud_description + ' invoking on_error')
        on_error(crud_description, error_list)
    }

    return { 'ResultList': results, 'TotalBytesReceived': total_length, 'Errors': total_errors, 'ErrorList': error_list }
}
