import * as Constant from '../../../constants'

export default async function updateOutletDiscountBandData(filters, timeperiod) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_discountband = Constant.baseapiurl + "ReportData/Filtered/DiscountBandsOutlet/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_discountband, 'method': 'POST', 'body': filters }
    ], 'UpdateOutletDiscountBands.js updateOutletDiscountBandData()', 'Outlet Discount Band')
    let discountband_obj = my_results['ReturnList'][0]
    let outlet_data_discountrange = discountband_obj['outlet_data_discountrange'] || {}
    this.setState({ outlet_data_discountrange })
}
