import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import selectColourStyles from '../../../styles/SelectColourStyles'

export class ItemASPDistSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle"> Select an Item</span>
                <Select
                    id="commonitems_new"
                    options={this.props.state.commonitems_new.map(item => {
                        return { 'label': item, 'value': item }
                    })}
                    placeholder={'Select an Item'}
                    onChange={(item, position) => this.props.setSelectedItem_new(item, 'item')}
                    value={{ 'value': this.props.state.selectedItem_new, 'label': this.props.state.selectedItem_new }}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}