import React, { Component } from 'react';
import { MetricsComparisonsSelect } from '../../UIElements/Dropdowns/MetricsComparisonsSelect'
import { MetricsComparisonsToggle } from '../../UIElements/IconButtons/MetricsComparisonsToggle'
import { LastYearSwitch } from '../../UIElements/Switches/LastYearSwitch'
import { MetricsComparisonsGraph } from '../../../Graphs/MetricComparisonsGraph'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';

export class ALRvsROM_MetricComparisons extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >                    
                    <div align='left' className="col-sm-12" style={{ padding: '5px' }} >
                        <MetricsComparisonsSelect
                            state={this.props.state}
                            changeMetricsCompareROM={this.props.changeMetricsCompareROM}
                        />
                    </div>
                        
                    <div align='center' className="col-sm-12" style={{ padding: '5px' }} >
                        <MetricsComparisonsToggle
                            state={this.props.state}
                            moveviewdown={this.props.moveviewdown}
                            moveviewup={this.props.moveviewup}  
                        />
                    </div>
                    <div align='center' className="col-sm-12" style={{ padding: '5px' }} >
                        <LastYearSwitch
                            state={this.props.state}
                            handleChange_twoyago={this.props.handleChange_twoyago}
                        />
                    </div>                    
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <MetricsComparisonsGraph
                            state={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}