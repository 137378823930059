import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import * as Constant from '../../../constants';

export class BaseIncPromoNonPromoRadio extends Component {
    constructor(props) {
        super(props);
    }
    render() {


        return(
            <Fragment>
                <ThemeProvider theme={Constant.muiTheme}>
                    <Radio
                        checked={this.props.state.baseselected}
                        onChange={this.props.handleChangeRadioBasePromo}
                        value="Base"
                        name="BaseRadio"
                        label="Base"
                        inputProps={{ 'aria-label': 'Base' }}
                    />
                    <span style={{ lineHeight: '5' }}>Base/Incremental</span>
                    <Radio
                        checked={this.props.state.promoselected}
                        onChange={this.props.handleChangeRadioBasePromo}
                        value="Promo"
                        name="PromoRadio"
                        label="Promo"
                        inputProps={{ 'aria-label': 'Promo' }}
                    />
                    <span style={{ lineHeight: '5' }} >Non-Promoted/Promoted</span>
                    <Checkbox
                        checked={this.props.state.stackingType_checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': 'Show 100% Stacked Bar?' }}
                        onChange={this.props.handleToggle_stackingType}
                    />
                    <span style={{ lineHeight: '5' }} >Show 100% Stacked Bar?</span>
                </ThemeProvider>
            </Fragment>
        )
    }
}