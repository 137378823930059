export default function changeSelectedMetric(selectedOptions){
    this.setState({ value6: 5 })
    /*
    setTimeout(
        () => { this.setState({ value6: 8 })},
        500
    )        
    */
    setTimeout(
        () => { this.setState({ value6: 0, selectedMetric: selectedOptions }) },
        10
    )
}