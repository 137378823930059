import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Select from 'react-select'
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../styles/custom.css'
import * as Constant from '../constants'

export class GroupDialogs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                {this.props.state.isLoaded === true &&
                    <div>
                        {/* Group Edit Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showgroupinfo}
                            onClose={this.props.handleCloseGroupInfo}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Group Info</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseGroupInfo}>
                                            <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                Group Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeGroupName}
                                        id="groupinfoName"
                                        value={this.props.state.groupinfoName}
                                        fullWidth
                                    />
                                </div>
                                Group ID
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.groupinfodata.group_id}
                                        fullWidth
                                    />
                                </div>
                                Last Updated
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.groupinfodata.time_updated}
                                        fullWidth
                                    />
                                </div>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.props.handleDeleteGroup}
                                                disabled={!this.props.state.group_delete_button_enabled}
                                            >
                                                Delete Group
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitGroup}
                                                disabled={!this.props.state.group_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* New Group (Create) Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showgroupinfo_new}
                            onClose={this.props.handleCloseGroupInfoNew}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>New Group Info</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseGroupInfoNew}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                Group Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeGroupName}
                                        id="groupinfoName"
                                        value={this.props.state.groupinfoName}
                                        fullWidth
                                    />
                                </div>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitGroupNew}
                                                disabled={!this.props.state.group_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* Group Permissions Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showgroupperms}
                            onClose={this.props.handleCloseGroupPerms}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Group Permissions</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseGroupPerms}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <h4>Price and Promotion Permissions</h4>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_pnp_permissions">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Permission ID</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Permission Dataset</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Geographies</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Time Updated</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>More Dataset Information</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Remove Permission</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                            {
                                                this.props.state.groupinfoPermsPNP.slice(this.props.state.page_start_group_pnp_dataset_perms,
                                                                                         this.props.state.page_start_group_pnp_dataset_perms + this.props.state.rowsPerPage_group_pnp_dataset_perms).map((permission, idx) => {
                                                var index = idx
                                                return <Constant.NPDTableRow key={"datasetrow_" + permission.permission_id + "_pnp"}>
                                                    {permission.permission_id > 0 ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.permission_id}</Constant.NPDTableCell>
                                                        :
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}></Constant.NPDTableCell>
                                                    }
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={(this.props.state.pnp_datasets || []).map(dataset => {
                                                                return { 'label': dataset.shortdesc, 'value': dataset.dataset_id }
                                                            })}
                                                            placeholder={'Select A Dataset'}
                                                            onChange={(selectedOptions, app, idx, perm) => this.props.handleChangeGroupPerms(selectedOptions, 'pnp', index, 'dataset')}
                                                            value={[{ 'label': permission.shortdesc, 'value': permission.dataset_id }]}
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={Object.keys(((this.props.state.datasetgeomapping_pnp || {})[permission.dataset_id] || {})).map(geo => {
                                                                return { 'label': this.props.state.datasetgeomapping_pnp[permission.dataset_id][geo], 'value': parseInt(geo) }
                                                            })}
                                                            placeholder={'Select Geos'}
                                                            onChange={(selectedOptions, app, idx, perm) => this.props.handleChangeGroupPerms(selectedOptions, 'pnp', index, 'geos')}
                                                            value={permission.geogids.map(geo => { return { 'label': ((this.props.state.datasetgeomapping_pnp || {})[permission.dataset_id] || {})[geo], 'value': parseInt(geo) } })}
                                                            isMulti
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.time_updated}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.openPnpDataSetInfo(permission.dataset_id)}>
                                                            <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.removeGroupPermission(permission.permission_id, 'pnp')}>
                                                            <RemoveCircleRoundedIcon style={{ color: 'red', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })}
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={this.props.state.groupinfoPermsPNP.length}
                                        rowsPerPage={this.props.state.rowsPerPage_group_pnp_dataset_perms}
                                        page={this.props.state.page_group_pnp_dataset_perms}
                                        onChangePage={(event, newPage) => this.props.handleChangePageGroups(event, newPage, 'pnpdatasetperms')}
                                        onChangeRowsPerPage={(event) => this.props.handleChangeRowsPerPageGroups(event, 'pnpdatasetperms')}
                                    />
                                </div>
                                <div className="row">
                                    <ThemeProvider theme={this.props.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                                onClick={() => this.props.addNewGroupPermission('pnp', this.props.state.groupinfodata.group_id)}
                                        >
                                            Add a New <br />PNP Permission
                                        </Button>
                                        <span style={{ width: '100px' }} />
                                    </ThemeProvider>
                                </div>
                                <div className="row">
                                    <h4>Price and Discount Trends Permissions</h4>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_pdt_permissions">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Permission ID</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Permission Dataset</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Store Groups</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Time Updated</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>More Dataset Information</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Remove Permission</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                            {
                                                this.props.state.groupinfoPermsPDT.slice(this.props.state.page_start_group_pdt_dataset_perms,
                                                                                         this.props.state.page_start_group_pdt_dataset_perms + this.props.state.rowsPerPage_group_pdt_dataset_perms).map((permission, idx) => {
                                                var index = idx
                                                return <Constant.NPDTableRow key={"datasetrow_" + permission.permission_id + "_pdt"}>
                                                    {permission.permission_id > 0 ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.permission_id}</Constant.NPDTableCell>
                                                        :
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}></Constant.NPDTableCell>
                                                    }
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={(this.props.state.pdt_datasets || []).map(dataset => {
                                                                return { 'label': dataset.short_desc, 'value': dataset.dataset_id }
                                                            })}
                                                            placeholder={'Select A Dataset'}
                                                            onChange={(selectedOptions, app, idx, perm) => this.props.handleChangeGroupPerms(selectedOptions, 'pdt', index, 'dataset')}
                                                            value={[{ 'label': permission.short_desc, 'value': permission.dataset_id }]}
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={Object.keys(((this.props.state.datasetgeomapping_pdt || {})[permission.dataset_id] || {})).map(storegroup => {
                                                                return { 'label': this.props.state.datasetgeomapping_pdt[permission.dataset_id][storegroup], 'value': parseInt(storegroup) }
                                                            })}
                                                            placeholder={'Select Store Groups'}
                                                            onChange={(selectedOptions, app, idx, perm) => this.props.handleChangeGroupPerms(selectedOptions, 'pdt', index, 'geos')}
                                                            value={permission.store_groups.map(storegroup => { return { 'label': ((this.props.state.datasetgeomapping_pdt || {})[permission.dataset_id] || {})[storegroup], 'value': parseInt(storegroup) } })}
                                                            isMulti
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.time_updated}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.openPdtDataSetInfo(permission.dataset_id)}>
                                                            <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.removeGroupPermission(permission.permission_id, 'pdt')}>
                                                            <RemoveCircleRoundedIcon style={{ color: 'red', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })}
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={this.props.state.groupinfoPermsPDT.length}
                                        rowsPerPage={this.props.state.rowsPerPage_group_pdt_dataset_perms}
                                        page={this.props.state.page_group_pdt_dataset_perms}
                                        onChangePage={(event, newPage) => this.props.handleChangePageGroups(event, newPage, 'pdtdatasetperms')}
                                        onChangeRowsPerPage={(event) => this.props.handleChangeRowsPerPageGroups(event, 'pdtdatasetperms')}
                                    />
                                </div>
                                <div className="row">
                                    <ThemeProvider theme={this.props.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.props.addNewGroupPermission('pdt', this.props.state.groupinfodata.group_id)}
                                        >
                                            Add a New <br />PDT Permission
                                        </Button>
                                        <span style={{ width: '100px' }} />
                                    </ThemeProvider>
                                </div>
                                <div className="row">
                                    <div className="col-sm-9"></div>
                                    <div className="col-sm-3">
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.props.handleSubmitGroupChangesPerms()}
                                                disabled={!this.props.state.group_edit_perm_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* Group Membership Modal */}
                        {/* <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showgroupusers}
                            onClose={this.props.handleCloseGroupUsers}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Group Membership</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseGroupUsers}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                Group Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        id="groupinfoName"
                                        value={this.props.state.groupinfoName}
                                        fullWidth
                                        disabled
                                    />
                                </div>
                                Group ID
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.groupinfodata.group_id}
                                        fullWidth
                                    />
                                </div>
                                Last Updated
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.groupinfodata.time_updated}
                                        fullWidth
                                    />
                                </div>
                                <h4>Users by Company</h4>
                                {(this.props.state.users_by_company || []).map(company => {

                                    return <ExpansionPanel>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <h5 style={{ padding: '10px' }}>{company.company_name}</h5>
                                            <p>{this.props.state.users_selected_by_company[company.company_id] + '/' + company.users.length + ' selected'}</p>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <div>
                                                <h5>{ }</h5>
                                                {(company.users || []).map(user => {
                                                    return <div>
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Checkbox
                                                                checked={groupinfoUsers.includes(parseInt(user.user_id))}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                                onChange={(userid) => this.props.handleChangeUsersCheckbox(user.user_id)}
                                                            />
                                                            {user.user_name}
                                                        </ThemeProvider>
                                                    </div>
                                                })
                                                }
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                })
                                }
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4'></div>
                                    <div className='col-sm-4'></div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitGroup}
                                                disabled={!this.props.state.group_membership_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal> */}
                    </div>
                }
            </Fragment>
        )
    }
}
