import React, { Component } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout } from '../Layout';
import '../styles/custom.css'
import * as Constant from '../constants'


//Import Highcharts
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";

highchartsGantt(Highcharts);

const NPDBackdrop = withStyles({
    root: {
        backgroundColor: 'rgba(142, 144, 143,.5)',
        color: '#4e106f',
        zIndex: '9999999'
    },

})(Backdrop);


export class CreatePlan extends Component {
    static displayName = CreatePlan.name;

    constructor(props) {
        super(props);
        //this.handleDateStart = this.handleDateStart.bind(this)
        this.state = {
            allsims: [],
            left: [],
            right: [],
            checked: [],
            simulations: [],
            sim_name_dict: {},
            sim_data_dict: {},
            geonames: [],
            brandnames: [],
            itemnames: [],
            num_base: 0,
            num_promo: 0,
            min_date: null,
            max_date: null,
            item_dict: {},
            simdates_data: [],
            simdates_categories: [],
            AllGeosModalOpen: false,
            AllBrandsModalOpen: false,
            AllItemsModalOpen: false,
            basevolumes: 0,
            unitschanged: 0,
            errorAlert: false,
            showBackdrop_var: false
        }
    }

    

    componentDidMount() {

        fetch('/environment/getenvironment')
            .then(res => {
                res.text()
                    .then(
                        (result) => {
                            this.setState({ environment: result })
                        })
            })

        //Grab all Sims to Use for Left/Right
        fetch(Constant.baseapiurl + "SimSettings")
            //.then(console.log(result))
            .then(res => res.json())
            .then(
                (result) => {
                    var sim_data_dict = {}
                    var sim_name_dict = {}
                    var left = []
                    var allsims = []
                    result.forEach(sim => {
                        sim_name_dict[sim.sim_id] = sim.sim_name
                        sim_data_dict[sim.sim_id] = sim.dataset_id
                        allsims.push(sim.sim_id)
                        left.push(sim.sim_id)                        
                    })
                   
                    this.setState({                        
                        simulations: result,
                        left,
                        sim_name_dict,
                        sim_data_dict,
                        allsims
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {      
        //console.log(this.state)
        const classes = makeStyles(theme => ({
            root: {
                margin: 'auto',
            },
            paper: {
                width: 200,
                height: 230,
                overflow: 'auto',
            },
            button: {
                margin: theme.spacing(0.5, 0),
            },
        }));

        const muiTheme = createMuiTheme({
            palette: {
                primary: {
                    main: '#4e106f',
                },
                secondary: {
                    main: '#ef2a79',
                }
            }
        });


        const checked = this.state.checked

        const resetStateCriteria = () => {
            this.setState({
                geonames: [],
                brandnames: [],
                itemnames:[],
                num_base: 0,
                num_promo: 0,
                min_date: null,
                max_date: null,
                item_dict: {},
                simdates_data: [],
                basevolumes: 0,
                unitschanged: 0
            })
        }

        const getGeoName = (datasetid, geogid, items) => {           
            var geoname = ""
            var geonames = this.state.geonames
            fetch(Constant.baseapiurl + "Datasets/" + datasetid + "/Parameters")
                .then(res => res.json())
                .then((result) => {
                    let item_dict = {}
                    result.items.map(item => item_dict[item.prodid] = item.proddesc)                   
                    let old_items = this.state.itemnames
                    let new_items = items.map(item => item_dict[item.prod_id])
                    let all_items = old_items.concat(new_items)
                    let all_items_set = new Set(all_items)
                    let itemnames = Array.from(all_items_set)
                    this.setState({ item_dict })
                    this.setState({ itemnames: itemnames })
                    result.geos.forEach(geo => {                        
                        if (geo.geogid === geogid) {
                            geoname = geo.geogdesc
                            if (!geonames.includes(geoname)) {
                                geonames.push(geoname)
                                this.setState({ geonames })
                            }                            
                        }                           
                    })
                })            
        }

        const getItemBrands = (datasetid, geogid, items) => {
            var brandnames = this.state.brandnames
            fetch(Constant.baseapiurl + "Datasets/" + datasetid + "/BrandsFromItems?geos=" + geogid,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(items)
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        result.forEach(item => {
                            if (!brandnames.includes(item.brand)) {
                                brandnames.push(item.brand)
                                this.setState({ brandnames })
                            }                            
                        })
                    })        
        }

        const grabSimInfo = (sims) => {
            console.log('Running Sim')
            this.setState({ showBackdrop_var: true})
            var num_base = 0;
            var num_promo = 0;
            var min_date = null;
            var max_date = null;
            var simdates_data = []
            var simdates_categories = []
            var unitschanged = 0
            var basevolumes = 0
            if (sims.length === 0) {
                resetStateCriteria()
            }
            else {
                sims.forEach((simid, idx) => {
                    var numweeks = 0
                    var coverage = 1
                    fetch(Constant.baseapiurl + "SimSettings/" + simid)
                        .then(res => res.json())
                        .then((result) => {
                            console.log(result)
                            simdates_categories.push(result.sim_name)
                            simdates_data.push(
                                { 'x': Date.UTC(parseInt(result.sim_start.substring(0, 4)), parseInt(result.sim_start.substring(5, 7)) - 1, parseInt(result.sim_start.substring(8, 10))), 'x2': Date.UTC(parseInt(result.sim_end.substring(0, 4)), parseInt(result.sim_end.substring(5, 7)) - 1, parseInt(result.sim_end.substring(8, 10))), 'y': idx }
                            )
                            numweeks = Math.round((new Date(result.sim_start.substring(0, 4), result.sim_start.substring(5, 7), result.sim_start.substring(8, 10)) - new Date(result.sim_end.substring(0, 4), result.sim_end.substring(5, 7), result.sim_end.substring(8, 10))) / (7 * 25 * 60 * 60 * 1000)) * -1
                            coverage = result.coverage
                            getGeoName(result.dataset_id, result.geogid[0], result.items)
                            getItemBrands(result.dataset_id, result.geogid[0], result.items.map(item => item.prod_id))
                            
                            if (result.sim_type === 'base') {
                                num_base += 1
                                this.setState({ num_base })
                            }
                            else if (result.sim_type === 'promo') {
                                num_promo += 1
                                this.setState({ num_promo })
                            }
                            //Check Min Date
                            if (result.sim_start < min_date || min_date === null) {
                                min_date = result.sim_start
                                this.setState({ min_date })
                            }
                            //Check Max Date
                            if (result.sim_end > max_date || max_date === null) {
                                max_date = result.sim_end
                                this.setState({ max_date })
                            }
                            this.setState({ simdates_categories, simdates_data })

                        })
                    fetch(Constant.baseapiurl + "Simulation/" + simid)
                        .then(res => {
                            if (res.status === 200) {                                
                                res.text()
                                    .then((result) => {
                                        var result2 = result.replace(/NaN/g, "0")
                                        var resultformatted = JSON.parse(result2)
                                        console.log('Running Through Items')
                                        resultformatted['item_list'].forEach((item,idx) => {
                                            if (resultformatted[item]['item_type']['from_sim']) {
                                                basevolumes += resultformatted[item]['starting_base_volume'] * numweeks
                                                unitschanged += resultformatted[item]['starting_base_volume'] * resultformatted[item]['total'] * numweeks * coverage
                                                //previously setting state here...took way too long
                                            }
                                            if (idx === resultformatted['item_list'].length - 1) {
                                                this.setState({ showBackdrop_var: false })
                                            }                                            
                                        })
                                        this.setState({ unitschanged })
                                        this.setState({ basevolumes })
                                        
                                    })
                            }
                            else {
                                this.setState({ errorAlert: true }) 
                            }

                        })                    
                })

            }
        }

        const handleToggle = value => () => {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...this.state.checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            this.setState({ checked: newChecked.sort() });
            console.log(newChecked.sort())
            grabSimInfo(newChecked.sort())
            console.log(value)
            let dataset_valid = this.state.sim_data_dict[value]
            //Walk through left, and only values with this dataset should be available...if nothing is selected then all should be available
            console.log(newChecked.length)
            if (newChecked.length === 0) {
                console.log('Empty Set')
                let validsims = this.state.allsims
                this.setState({ left: validsims })
            }
            else {
                let validsims = []
                this.state.allsims.forEach(sim => {
                    if (this.state.sim_data_dict[sim] === dataset_valid) { // && !newChecked.includes(sim)
                        validsims.push(sim)
                        this.setState({ left: validsims })
                    }                    
                })
            }
        };


        const handleOpenAllGeosModal = () => {
            this.setState({ AllGeosModalOpen: true })
        }

        const handleCloseAllGeosModal = () => {
            this.setState({ AllGeosModalOpen: false })
        }

        const handleOpenAllBrandsModal = () => {
            this.setState({ AllBrandsModalOpen: true })
        }

        const handleCloseAllBrandsModal = () => {
            this.setState({ AllBrandsModalOpen: false })
        }

        const handleOpenAllItemsModal = () => {
            this.setState({ AllItemsModalOpen: true })
        }

        const handleCloseAllItemsModal = () => {
            this.setState({ AllItemsModalOpen: false })
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })            
        }

        const customList = sims => (
            <Paper className={classes.paper}>
                <List dense component="div" role="list" style={{ height: '230px', width: '1115px', overflowY:'auto' }}>
                    {sims.map(sim => {
                        const labelId = `transfer-list-item-${sim}-label`;
                        return (
                            <ListItem key={sim.sim_id} role="listitem" button onClick={handleToggle(sim)}>
                                <ListItemIcon>
                                    <ThemeProvider theme={muiTheme}>
                                        <Checkbox
                                            checked={checked.indexOf(sim) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ThemeProvider>
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={this.state.sim_name_dict[sim]} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Paper>
        );

        return (
            <Layout title="Plan Entry">
                {this.state.environment === 'Development' ?
                    <div className="contentHolderGradient" style={{ width: '100%', position: 'absolute', left: '120px', top: '60px', backgroundColor: '#F4F4F4', maxWidth: 'calc(100vw - 135px)', paddingLeft: '50px' }}>
                        <NPDBackdrop
                            open={this.state.showBackdrop_var}
                            invisible={false}
                        >
                            {/*<CircularProgress color="inherit" />*/}
                            <div class="custom-loader"></div>
                        </NPDBackdrop>

                        <Dialog
                            open={this.state.errorAlert}
                            onClose={handleCloseUsererrorAlert}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Error Running the Simulation"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    There was an Error Running the Simulation
		                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                                    Ok
		                </button>
                            </DialogActions>
                        </Dialog>
                        <h1>Create a New Plan</h1>
                        <h3>Select Simulations to add to the Plan</h3>

                        <Grid container spacing={2} justify="" alignItems="center" className={classes.root}>
                            <Grid item >
                                <h5>Simulations</h5>
                                {customList(this.state.left)}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="" alignItems="center" className={classes.root}>
                            <Grid item>
                                <h5>Criteria of Simulations in the Plan</h5>
                                <Paper className={classes.paper}>
                                    <div style={{ height: '230px', width: '1115px', overflowY: 'auto', padding: '15px' }}>
                                        <div className="row">
                                            <div align="center" className="col-sm-4"><span className="formfieldtitle">Geographies</span></div>
                                            <div align="center" className="col-sm-4"><span className="formfieldtitle">Brands</span></div>
                                            <div align="center" className="col-sm-4"><span className="formfieldtitle">Items</span></div>
                                        </div>
                                        <Divider />
                                        <div className="row">
                                            <div align="center" className="col-sm-4">
                                                {this.state.geonames.map((geo, idx) => {
                                                    var numgeos = this.state.geonames.length
                                                    if (numgeos > 2) {
                                                        if (idx < 2) {
                                                            return <span key={geo}>{geo}<br /></span>
                                                        }
                                                        else if (idx === 2) {
                                                            //console.log('Return the Link!')
                                                            return <span style={{
                                                                color: '#4e106f',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold'
                                                            }}
                                                                onClick={handleOpenAllGeosModal}
                                                                key="AllGeosLink"
                                                            >
                                                                View All {numgeos} Geographies
                                                                        </span>
                                                        }

                                                        else {
                                                            return null
                                                        }

                                                    }
                                                    else {
                                                        return <span key={geo}>{geo}<br /></span>
                                                    }                                                    
                                                })
                                                }
                                            </div>

                                            <div align="center" className="col-sm-4">
                                                {this.state.brandnames.map((brand, idx) => {
                                                    var numbrands = this.state.brandnames.length
                                                    if (numbrands > 2) {
                                                        if (idx < 2) {
                                                            return <span key={brand}>{brand}<br /></span>
                                                        }
                                                        else if (idx === 2) {
                                                            //console.log('Return the Link!')
                                                            return <span style={{
                                                                color: '#4e106f',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold'
                                                            }}
                                                                onClick={handleOpenAllBrandsModal}
                                                                key="AllBrandsLink"
                                                            >
                                                                View All {numbrands} Brands
                                                                        </span>
                                                        }
                                                        else {
                                                            return null
                                                        }

                                                    }
                                                    else {
                                                        return <span key={brand}>{brand}<br /></span>
                                                    }                                                    
                                                })
                                                }
                                            </div>

                                            <div align="center" className="col-sm-4">
                                                {this.state.itemnames.map((item, idx) => {
                                                    var numitems = this.state.itemnames.length
                                                    if (numitems > 2) {
                                                        if (idx < 2) {
                                                            return <span key={item}>{item}<br /></span>
                                                        }
                                                        else if (idx === 2) {
                                                            //console.log('Return the Link!')
                                                            return <span style={{
                                                                color: '#4e106f',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold'
                                                            }}
                                                                onClick={handleOpenAllItemsModal}
                                                                key="AllItemsLink"
                                                            >
                                                                View All {numitems} Items
                                                                        </span>
                                                        }
                                                        else {
                                                            return null
                                                        }

                                                    }
                                                    else {
                                                        return <span key={item}>{item}<br /></span>
                                                    }                                                    
                                                })
                                                }
                                            </div>
                                        </div>
                                        <Divider />
                                        <div className="row">

                                        </div>
                                        <Divider />
                                        <div className="row">
                                            <div align="center" className="col-sm-4"><span className="formfieldtitle">Simulation Types</span></div>
                                            <div align="center" className="col-sm-4"><span className="formfieldtitle">Dates Covered:</span></div>
                                        </div>
                                        <Divider />
                                        <div className="row">
                                            <div align="center" className="col-sm-4">
                                                {this.state.num_promo > 0 ? "Promotion :" + this.state.num_promo : ""}
                                                <br />
                                                {this.state.num_base > 0 ? "Base Price :" + this.state.num_base : ""}
                                            </div>
                                            <div className="col-sm-4" align="center">
                                                {this.state.min_date != null && this.state.max_date != null ? this.state.min_date.substring(0, 10) + " to " + this.state.max_date.substring(0, 10) : ""}
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2} justify="" alignItems="center" className={classes.root}>
                            <Grid item >
                                <h5>Calendar View of Selected Simulations</h5>
                                <div style={{ width: '1115px' }}> {/*width: '50vw' }}>*/}
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={"ganttChart"}
                                        options={{

                                            yAxis: {
                                                type: "category",
                                                grid: {
                                                    enabled: true,
                                                    columns: [{ title: "TEST", categories: this.state.simdates_categories }]
                                                }
                                            },
                                            tooltip: {
                                                formatter: function () {
                                                    return '<b>' + this.series.name + '</b><br/>' +
                                                        '<b>Start date:</b> ' +
                                                        Highcharts.dateFormat('%e - %b - %Y', new Date(this.x)) +
                                                        '<br/><b>End Date date:</b> ' +
                                                        Highcharts.dateFormat('%e - %b - %Y', new Date(this.x2))
                                                }
                                            },
                                            series: [
                                                {
                                                    name: "Simulation Dates",
                                                    data: this.state.simdates_data,
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <h5>Plan Output</h5>
                        <div className="contentHolderNoMargin" style={{ width: '1115px' }}>
                            <h5 align='center'>Base Units and Incremental Units</h5>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'bar'
                                        },
                                        title: {
                                            text: ''
                                        },
                                        series: [

                                            {
                                                name: 'Starting Units',
                                                data: [this.state.basevolumes]
                                            },
                                            {
                                                name: 'Change in Units',
                                                data: [this.state.unitschanged]
                                            },

                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Volume'
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1
                                        },
                                        yAxis: {
                                            title: {
                                                text: ''
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1
                                        },
                                        plotOptions: {
                                            series: {
                                                //stacking: 'normal',
                                                dataLabels: {
                                                    enabled: true,
                                                    format: '{point.y:,.0f}'
                                                }
                                            }
                                        },
                                        tooltip: {
                                            formatter: function () {
                                                return this.series.name + ' : ' + Math.round(this.y).toLocaleString()
                                            }
                                        }

                                    }}
                            />
                        </div>
                        <br />
                        <div className="contentHolderNoMargin" style={{ width: '25%' }}>Percent Change: {(this.state.unitschanged / this.state.basevolumes).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                        <div style={{ height: '100px' }}></div>


                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.AllGeosModalOpen}
                            onClose={handleCloseAllGeosModal}
                        >
                            <div align="center" className="contentHolderTight" style={{ transform: 'translate(0%, 50%)' }}>
                                <h5>All Geographies in the Plan</h5>
                                {this.state.geonames.map((geo, idx) => {
                                    return <span key={geo}>{geo}<br /></span>
                                })
                                }
                            </div>
                        </Modal>
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.AllBrandsModalOpen}
                            onClose={handleCloseAllBrandsModal}
                        >
                            <div align="center" className="contentHolderTight" style={{ transform: 'translate(0%, 50%)' }}>
                                <h5>All Brands in the Plan</h5>
                                {this.state.brandnames.map((brand, idx) => {
                                    return <span key={brand}>{brand}<br /></span>
                                })}
                            </div>
                        </Modal>
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.AllItemsModalOpen}
                            onClose={handleCloseAllItemsModal}
                        >
                            <div align="center" className="contentHolderTight" style={{ transform: 'translate(0%, 50%)' }}>
                                <h5>All Items in the Plan</h5>
                                {this.state.itemnames.map((item, idx) => {
                                    return <span key={item}>{item}<br /></span>
                                })}
                            </div>
                        </Modal>

                    </div>
                    :
                    <div className="contentHolderGradient" style={{ height: '100%', width: '100%', position: 'absolute', left: '120px', top: '60px', backgroundColor: '#F4F4F4', maxWidth: 'calc(100vw - 135px)', paddingLeft: '50px' }}>
                        This Feature will be coming soon
                    </div>
                }
                
            </Layout>
        )
    }
}

export class ListofPlans extends Component {
    static displayName = ListofPlans.name;
    constructor(props) {
        super(props);   
        this.state =  {
            environment: "",
        }
    }

    

    componentDidMount() {

        fetch('/environment/getenvironment')
            .then(res => {
                res.text()
                    .then(
                        (result) => {
                            this.setState({ environment: result })
                        })
            })
    }

    render() {
        const divheight = {
            height: '00px'
        }

        return (
            <Layout >                
                <div name='banner' className='banner'>
                    <h1 align='center'>All Plans</h1>
                    <h2 align='center'>Below is a list of all plans in the tool you have access to.</h2>
                </div>
                {this.state.environment === 'Development' ?
                    <div style={{ width: '100%', height: '100%', position: 'absolute', left: '120px', top: '210px', backgroundColor: '#F4F4F4', maxWidth: 'calc(100vw - 135px)' }}>
                        <div style={divheight}>
                            This Feature will be coming soon
                        </div>
                    </div>
                    :
                    <div style={{ width: '100%', height: '100%', position: 'absolute', left: '120px', top: '210px', backgroundColor: '#F4F4F4', maxWidth: 'calc(100vw - 135px)' }}>
                        <div style={divheight}>
                            This Feature will be coming soon
                        </div>
                    </div>
                }
            </Layout>
        )
    }
}