export default function changeSelectedMetric(selectedOptions) {
    console.log('Changing Metric For Area Chart')

    //Determine the key to use for the metrics
    console.log(this.state.indexSwitchCheckDollars)
    var keytype
    if (this.state.indexSwitchCheckDollars) {
        keytype = 'dollars'
    }
    else {
        keytype = 'units'
    }

    let metrickey = selectedOptions.value + keytype
    console.log(metrickey)

    this.setState({ selectedMetric_Area: selectedOptions, AreaKey: metrickey })
}