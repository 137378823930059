import React, { Fragment } from 'react';
import * as Constant from '../constants';

const ScorecardWithoutYOYSalesMetrics = props => {
    console.log(props)
    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'
    var appname = props.appname
    var total_dollars_curr = props.total_dollars_curr
    var nonpromoted_dollars_curr = props.nonpromoted_dollars_curr
    var promoted_dollars_curr = props.promoted_dollars_curr
    var base_dollars_curr = props.base_dollars_curr
    var incremental_dollars_curr = props.incremental_dollars_curr
    var subbasedollars_curr = props.subbasedollars_curr
    var unsubbasedollars_curr = props.unsubbasedollars_curr
    var promodollars_pct_curr = promoted_dollars_curr / total_dollars_curr
    var nonpromodollars_pct_curr = nonpromoted_dollars_curr / total_dollars_curr
    var incdollars_pct_curr = incremental_dollars_curr / total_dollars_curr
    var basedollars_pct_curr = base_dollars_curr / total_dollars_curr
    var subbasedollars_pct_curr = subbasedollars_curr / base_dollars_curr
    var unsubbasedollars_pct_curr = unsubbasedollars_curr / base_dollars_curr

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>Sales Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Total ' + currencytype + ' Sold in the Reporting Period'}</p>
                                <p> Please note that:</p>
                                <p> {'Total ' + currencytype + ' = Non-Promoted ' + currencytype + ' + Promoted ' + currencytype + ', and'}</p>
                                <p> {'Total ' + currencytype + ' = Base ' + currencytype + ' + Incremental ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Total ' + currencytype}</h5>
                                <p>{(total_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{currencytype + ' sales that occur when there is no temporary price reduction'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5 style={{ fontSize: "1.25rem" }}>{'Non-Promoted ' + currencytype}</h5>
                                <p>{(nonpromoted_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{currencytype + ' sales in store/weeks that had a temporary price reduction'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted ' + currencytype}</h5>
                                <p>{(promoted_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'An estimate of unit sales that would have occurred without promotions from a temporary price reduction multiplied by the base price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base ' + currencytype}</h5>
                                <p>{(base_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'An estimate of the impact on unit sales due to the presence of a temporary price reduction multiplied by the promoted price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental ' + currencytype}</h5>
                                <p>{(incremental_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Unsubsidized Base Units are the estimated base units sold in non-promoted stores multiplied by the base price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base ' + currencytype}</h5>
                                <p>{(unsubbasedollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Subsidized Base Units are estimated base units that were sold in stores with a temporary price reduction multiplied by the promoted price.  These are the estimated units that would have sold regardless of the promotion.'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base ' + currencytype}</h5>
                                <p>{(subbasedollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Non-Promoted ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Non-Promoted % of Total ' + currencytype}</h5>
                                <p>{(nonpromodollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Promoted ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted % of Total ' + currencytype}</h5>
                                <p>{(promodollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Base ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base % of Total ' + currencytype}</h5>
                                <p>{(basedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Incremental ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental % of Total ' + currencytype}</h5>
                                <p>{(incdollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'Subsidized Base ' + currencytype + ' / Base ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base % of Base ' + currencytype}</h5>
                                <p>{(subbasedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>{'UnSubsidized Base ' + currencytype + ' / Base ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base % of Base ' + currencytype}</h5>
                                <p>{(unsubbasedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                </div>
            </Fragment>
            :
            <Fragment>
            </Fragment>
    )
}

export default ScorecardWithoutYOYSalesMetrics;