import React, { Component, Fragment } from 'react';
//import Datetime from 'react-datetime'
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import { Layout } from '../Layout';
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import { ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AddBoxIcon from '@material-ui/icons/AddBox';
import selectColourStyles from '../styles/SelectColourStyles'

import * as Constant from '../constants'

export class PNPListofAllDatasets extends Component {
    static displayName = PNPListofAllDatasets.name;

    constructor(props) {
        super(props);
        this.state = {
            datasetsdata: [],
            datasetnames: [],

            geos: [],

            filters: {
                'datasets': ['All'],
                'geos': ['All'],                
                'lastupdated_start': null,
                'lastupdated_end': null
            },
            showBackground: true,
            header_sort_active: [true, false, false, false],
            header_sort_direction: ['asc', 'asc', 'asc', 'asc'],
            dataset_for_modal: {
                datasetinfo: {},
                attr_lookup: {},
                datasetattrsettings_data: [],
                datasetbrands_data: [],
            },
            DatevaluesTimeRange: null,
            DatevaluesLastUpdated: null,
        }
    }

    async componentDidMount() {
        //Pull Datasets...
        console.log('Getting Datasets')
        const datasetsres = await fetch(Constant.baseapiurl + "Datasets/DatasetDetails")
        var datasetsdata = []
        if (datasetsres.status === 200) {
            datasetsdata = await datasetsres.json()
        }
        else {
            datasetsdata = []
        }

        //set datasetflag to true
        datasetsdata.forEach(async (dataset, idx) => {
            datasetsdata[idx]['showdataset'] = true
        })

        let datasetnames = datasetsdata.map(dataset => dataset.shortdesc)
        //let industries = Array.from(new Set(datasetsdata.map(dataset => dataset.industry_name)))
        //let countries = Array.from(new Set(datasetsdata.map(dataset => dataset.country)))
        //let timeaggs = Array.from(new Set(datasetsdata.map(dataset => dataset.time_aggregation)))

        let geosall = []
        datasetsdata.map(dataset => (dataset.geos || []).forEach(geo => geosall.push(geo.geogdesc)))
        let geos = Array.from(new Set(geosall))

        //let categoriesall = []
        //datasetsdata.map(dataset => (dataset.levels || []).forEach(level => categoriesall.push(level.sel1_name)))
        //let categories = Array.from(new Set(categoriesall))

        //let subcategoriesall = []
        //datasetsdata.map(dataset => (dataset.levels || []).forEach(level => (level.sel2_items || []).forEach(sublevel => subcategoriesall.push(sublevel.sel2_name))))
        //let subcategories = Array.from(new Set(subcategoriesall))

        this.setState({
            datasetsdata,
            datasetnames,
            //industries,
            //countries,
            //timeaggs,
            geos,
            //categories,
            //subcategories,
            showBackground: false
        })

        console.log(datasetsdata)
        console.log('Finished!')

    }

    render() {
        console.log(this.state)

        const filterDatasets = (selectedOptions, filterby) => {

            //setfilters
            let filters = this.state.filters
            let datasets = this.state.datasetsdata

            if (filterby === 'datasets') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['datasets'] = selectedOptions.map(option => option.label)
                }
                else {
                    filters['datasets'] = ['All']
                }
            }
            /*
            if (filterby === 'countries') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['countries'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['countries'] = ['All']
                }
            }

            if (filterby === 'industries') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['industries'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['industries'] = ['All']
                }
            }
            */
            if (filterby === 'geos') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['geos'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['geos'] = ['All']
                }
            }
            /*
            if (filterby === 'categories') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['categories'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['categories'] = ['All']
                }
            }

            if (filterby === 'subcategories') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['subcategories'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['subcategories'] = ['All']
                }
            }

            if (filterby === 'timeaggregations') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['timeaggregations'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['timeaggregations'] = ['All']
                }
            }
            */
            if (filterby === 'lastupdated') {
                console.log(this.state.selectedstart)
                console.log(this.state.selectedend)
                filters['lastupdated_start'] = this.state.selectedstart
                filters['lastupdated_end'] = this.state.selectedend
            }

            if (filterby === 'daterange') {
                console.log(this.state.selectedstartdaterange)
                console.log(this.state.selectedenddaterange)
                filters['daterange_start'] = this.state.selectedstartdaterange
                filters['daterange_end'] = this.state.selectedenddaterange
            }

            //Loop through reports, and ensure that all filters are met...
            datasets.forEach(dataset => {
                let showdataset = false
                let datasetmatch = false
                //let countrymatch = false
                //let industrymatch = false
                let geomatch = false
                //let categorymatch = false
                //let subcategorymatch = false
                //let timeaggregationmatch = false
                let lastupdatedmatch = false
                let daterangematch = false
                console.log(dataset.shortdesc)
                if (filters['datasets'].includes(dataset.shortdesc) || (filters['datasets'].length === 1 && filters['datasets'][0] === 'All')) {
                    datasetmatch = true
                }
                /*
                if (filters['countries'].includes(dataset.country) || (filters['countries'].length === 1 && filters['countries'][0] === 'All')) {
                    countrymatch = true
                }

                if (filters['industries'].includes(dataset.industry_name) || (filters['industries'].length === 1 && filters['industries'][0] === 'All')) {
                    industrymatch = true
                }

                if (filters['timeaggregations'].includes(dataset.time_aggregation) || (filters['timeaggregations'].length === 1 && filters['timeaggregations'][0] === 'All')) {
                    timeaggregationmatch = true
                }
                */
                let geos = (dataset.geos || []).map(geo => geo.geogdesc)

                geos.forEach(geo => {
                    if (filters['geos'].includes(geo) || (filters['geos'].length === 1 && filters['geos'][0] === 'All')) {
                        geomatch = true
                    }
                })
                /*
                let categories = (dataset.levels || []).map(level => level.sel1_name)
                let fullcategories = (dataset.levels || []).map(level => level.sel1_complete)
                categories.forEach((cat, idx) => {
                    if (this.state.useCompleteCats) {
                        if ((filters['categories'].includes(cat) && fullcategories[idx]) || (filters['categories'].length === 1 && filters['categories'][0] === 'All')) {
                            categorymatch = true
                        }
                    }
                    else {
                        if (filters['categories'].includes(cat) || (filters['categories'].length === 1 && filters['categories'][0] === 'All')) {
                            categorymatch = true
                        }
                    }

                })

                let subcategories = (dataset.levels || []).map(level => (level.sel2_items || []).map(sublevel => sublevel.sel2_name))

                subcategories.forEach(subcat => {
                    subcat.forEach(subcatvalue => {
                        if (filters['subcategories'].includes(subcatvalue) || (filters['subcategories'].length === 1 && filters['subcategories'][0] === 'All')) {
                            subcategorymatch = true
                        }
                    })

                    if (subcat.length === 0 && (filters['subcategories'].length === 1 && filters['subcategories'][0] === 'All')) {
                        subcategorymatch = true
                    }
                })

                if (categories.length === 0 && (filters['categories'].length === 1 && filters['categories'][0] === 'All')) {
                    if (this.state.useCompleteCats) {
                        categorymatch = false
                    }
                    else {
                        categorymatch = true
                    }

                }

                if (filters['categories'].length === 1 && filters['categories'][0] === 'All') {
                    if (this.state.useCompleteCats) {
                        let numcompletecats = 0
                        fullcategories.forEach(cat => { if (cat) { numcompletecats += 1 } })
                        if (numcompletecats > 0) {
                            categorymatch = true
                        }
                        else {
                            categorymatch = false
                        }
                    }
                    else {
                        categorymatch = true
                    }
                }

                if (subcategories.length === 0 && (filters['subcategories'].length === 1 && filters['subcategories'][0] === 'All')) {
                    subcategorymatch = true
                }
                */
                let time_updated_forcomp = dataset.time_updated.substring(0, 10)
                if (filters['lastupdated_start'] && filters['lastupdated_end']) {
                    if (time_updated_forcomp <= filters['lastupdated_end'] && time_updated_forcomp >= filters['lastupdated_start']) {
                        lastupdatedmatch = true
                    }
                }

                if (filters['lastupdated_start'] && !filters['lastupdated_end']) {
                    if (time_updated_forcomp >= filters['lastupdated_start']) {
                        lastupdatedmatch = true
                    }
                }

                if (!filters['lastupdated_start'] && filters['lastupdated_end']) {
                    if (time_updated_forcomp <= filters['lastupdated_end']) {
                        lastupdatedmatch = true
                    }
                }

                if (!filters['lastupdated_start'] && !filters['lastupdated_end']) {
                    lastupdatedmatch = true
                }


                let time_updated_daterange_start = ((dataset.times || {})['min'] || "").substring(0, 10)
                let time_updated_daterange_end = ((dataset.times || {})['max'] || "").substring(0, 10)

                if (filters['daterange_start'] && filters['daterange_end']) {
                    if (time_updated_daterange_start <= filters['daterange_start'] && time_updated_daterange_end >= filters['daterange_end']) {
                        daterangematch = true
                    }
                }

                if (filters['daterange_start'] && !filters['daterange_end']) {
                    if (time_updated_daterange_start <= filters['daterange_start']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && filters['daterange_end']) {
                    if (time_updated_daterange_end >= filters['daterange_end']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && !filters['daterange_end']) {
                    daterangematch = true
                }

                if (datasetmatch && geomatch && lastupdatedmatch && daterangematch) {
                    showdataset = true
                }

                dataset['showdataset'] = showdataset
            })

            this.setState({ filters, datasetdata: datasets })

        }

        const sortData = (event, sortby) => {
            console.log(event)
            console.log('Sorting by ' + sortby)

            var current_header_direction = this.state.header_sort_direction
            var new_header_active = []
            if (sortby === 'Name') {
                new_header_active = [true, false, false, false]
            }

            else if (sortby === 'Geo') {
                new_header_active = [false, true, false, false]
            }

            else if (sortby === 'DateRange') {
                new_header_active = [false, false, true, false]
            }

            else if (sortby === 'LastModified') {
                new_header_active = [false, false, false, true]
            }

            var curr_direction = ""
            var new_direction = ""
            var new_header_direction = []
            if (sortby === 'Name') {
                curr_direction = current_header_direction[0]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[0] = new_direction
            }

            else if (sortby === 'Geo') {
                curr_direction = current_header_direction[1]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[1] = new_direction
            }

            else if (sortby === 'DateRange') {
                curr_direction = current_header_direction[2]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[2] = new_direction
            }

            else if (sortby === 'LastModified') {
                curr_direction = current_header_direction[3]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[3] = new_direction
            }
           
            //Sort the data...
            var data_to_sort = this.state.datasetsdata
            console.log(data_to_sort)
            var final_sort = []
            if (sortby === 'Name') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.shortdesc || "").toLowerCase() < (b.shortdesc || "").toLowerCase() ? -1 : ((a.shortdesc || "").toLowerCase() > (b.shortdesc || "").toLowerCase() ? 1 : 0))
                }

                else {
                    final_sort = data_to_sort.sort((a, b) => (b.shortdesc || "").toLowerCase() < (a.shortdesc || "").toLowerCase() ? -1 : ((b.shortdesc || "").toLowerCase() > (a.shortdesc || "").toLowerCase() ? 1 : 0))
                }

            }

          
            if (sortby === 'Geo') {

                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let geos_a = (a.geos || []).map(geo => geo.geogdesc).join(',')
                        let geos_b = (b.geos || []).map(geo => geo.geogdesc).join(',')
                        return (geos_a || "").toLowerCase() < (geos_b || "").toLowerCase() ? -1 : ((geos_a || "").toLowerCase() > (geos_b || "").toLowerCase() ? 1 : 0)
                    })
                }

                else {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let geos_a = (a.geos || []).map(geo => geo.geogdesc).join(',')
                        let geos_b = (b.geos || []).map(geo => geo.geogdesc).join(',')
                        return (geos_b || "").toLowerCase() < (geos_a || "").toLowerCase() ? -1 : ((geos_b || "").toLowerCase() > (geos_a || "").toLowerCase() ? 1 : 0)
                    })
                }

            }

            if (sortby === 'DateRange') {

                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let timetocheck_a = (a.times || {})['min']
                        let timetocheck_b = (b.times || {})['min']
                        return Date.parse(timetocheck_a) - Date.parse(timetocheck_b)
                    })
                }

                else {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let timetocheck_a = (a.times || {})['max']
                        let timetocheck_b = (b.times || {})['max']
                        return Date.parse(timetocheck_b) - Date.parse(timetocheck_a)
                    })
                }

            }

            if (sortby === 'LastModified') {

                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.time_updated) - Date.parse(b.time_updated) })
                }

                else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                }

            }

            console.log(final_sort)

            this.setState({
                header_sort_direction: new_header_direction,
                header_sort_active: new_header_active,
                datasetsdata: final_sort
            })

        }

        const handleDates_TimeRange = (values) => {
            console.log(values)
            let selectedstartdaterange = null
            let selectedenddaterange = null
            if (values) {
                selectedstartdaterange = (values[0].toISOString()).substring(0, 10)
                selectedenddaterange = (values[1].toISOString()).substring(0, 10)
            }
            else {
                selectedstartdaterange = null
                selectedenddaterange = null
            }

            this.setState({
                selectedstartdaterange: selectedstartdaterange,
                selectedenddaterange: selectedenddaterange,
                DatevaluesTimeRange: values
            }, () => {
                filterDatasets(values, 'daterange')
            }
            )

        }

        const handleDates_LastUpdated = (values) => {
            console.log(values)
            let selectedstart = null
            let selectedend = null
            if (values) {
                selectedstart = (values[0].toISOString()).substring(0, 10)
                selectedend = (values[1].toISOString()).substring(0, 10)
            }

            else {
                selectedstart = null
                selectedend = null
            }

            this.setState({
                selectedstart: selectedstart,
                selectedend: selectedend,
                DatevaluesLastUpdated: values
            }, () => {
                filterDatasets(values, 'lastupdated')
            }
            )

        }



        const handleOpenStoreGroupModal = (event, storegroups, datasetname) => {
            console.log(storegroups)
            this.setState({ storegroupmodal: true, storegroups_formodal: storegroups, selectedDataSetName: datasetname })
        }

        const handleOpenSubCategoryModal = (event, levels, datasetname) => {
            console.log(levels)
            this.setState({ subcatgroupmodal: true, levels_formodal: levels, selectedDataSetName: datasetname })
        }

        const handleOpenDataSetInfoModal = async (event, datasetid, datasetinfo) => {
            console.log(datasetid)
            console.log(datasetinfo)

            //pull dataset info
            var datasetparams, datasetparams_resp, datasetparams_data
            datasetparams = await fetch(Constant.baseapiurl + 'Datasets/' + datasetid)
            datasetparams_resp = datasetparams.status
            if (datasetparams_resp === 200) {
                datasetparams_data = await datasetparams.json()
            }
            else {
                datasetparams_data = {}
            }
            //pull information for brands

            var datasetbrands, datasetbrands_resp, datasetbrands_data
            datasetbrands = await fetch(Constant.baseapiurl + 'Datasets/' + datasetid + '/Brands')
            datasetbrands_resp = datasetbrands.status
            if (datasetbrands_resp === 200) {
                datasetbrands_data = await datasetbrands.json()
            }
            else {
                datasetbrands_data = []
            }

            //pull information for attributes

            //Pull Attribute Value Settings
            var datasetattrsettings, datasetattrsettings_resp, datasetattrsettings_data
            datasetattrsettings = await fetch(Constant.baseapiurl + 'Datasets/' + datasetid + '/AttributeValues')
            datasetattrsettings_resp = datasetattrsettings.status
            if (datasetattrsettings_resp === 200) {
                datasetattrsettings_data = await datasetattrsettings.json()
            }

            //Build a lookup of humanreadable attributes
            let attr_lookup = {}
            datasetparams_data.attributes.forEach((attr, idx) => {
                attr_lookup[attr] = datasetparams_data.attributes_human[idx]
            })



            //Set info for Modal...
            let dataset_for_modal = {
                datasetinfo: datasetinfo,
                attr_lookup: attr_lookup,
                datasetattrsettings_data: datasetattrsettings_data,
                datasetbrands_data: datasetbrands_data,                
            }

            this.setState({ datasetgroupmodal: true, dataset_for_modal: dataset_for_modal, selectedDataSetName: datasetinfo.shortdesc })
        }

        const sendToCreateReportForm = (event, datasetid) => {
            //Route the user to the create report tab with a parameter for the datasetid to use
            window.open(Constant.baseurl + 'PNP/createreport/' + datasetid)
        }

        const openItemGroupsPage = (event, datasetid) => {
            //Route the user to the create report tab with a parameter for the datasetid to use
            window.open(Constant.baseurl + 'PNP/ItemGroupDefinition?datasetid=' + datasetid)
        }

        const handleCloseStoreGroupModal = () => {
            this.setState({ storegroupmodal: false })
        }

        const handleCloseSubCategoryModal = () => {
            this.setState({ subcatgroupmodal: false })
        }

        const handleCloseDataSetInfoModal = () => {
            this.setState({ datasetgroupmodal: false })
        }

        return (
            <Layout title="All Datasets" >

                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    <div class="custom-loader"></div>
                    {/*<CircularProgress color="inherit" />*/}
                </Constant.NPDBackdrop>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.storegroupmodal}
                    onClose={handleCloseStoreGroupModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'scroll', maxHeight: '85%' }} className="contentHolder">
                        <div className='row'>
                            <div style={{ textAlign: 'left', width: '50%' }}><h4>{'All Store Groups for ' + this.state.selectedDataSetName}</h4></div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <IconButton
                                    onClick={handleCloseStoreGroupModal}
                                >
                                    <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>

                            </div>
                        </div>
                        <ul>
                            {(this.state.storegroups_formodal || []).map(geo => {
                                return <li> {geo.geogdesc}</li>
                            })}
                        </ul>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.subcatgroupmodal}
                    onClose={handleCloseSubCategoryModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'scroll', maxHeight: '85%' }} className="contentHolder">
                        <div className='row'>
                            <div style={{ textAlign: 'left', width: '50%' }}><h4>{'All Categories and Subcategories for ' + this.state.selectedDataSetName}</h4></div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <IconButton
                                    onClick={handleCloseSubCategoryModal}
                                >
                                    <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>

                            </div>
                        </div>

                        {(this.state.levels_formodal || []).map(level => {
                            let sel2 = level.sel2_items || []
                            return <Fragment>
                                {level.sel1_complete ?
                                    <h6>{level.sel1_name}</h6>
                                    :
                                    <p>{level.sel1_name}</p>
                                }
                                <ul>
                                    {sel2.map(sel => {
                                        return <li> {sel.sel2_name}</li>
                                    })}
                                </ul>
                            </Fragment>
                        })}

                        <br />
                        <h6>Bold Indicates the Category Selection is Complete</h6>

                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.datasetgroupmodal}
                    onClose={handleCloseDataSetInfoModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'scroll', maxHeight: '85%' }} className="contentHolder">
                        <div className='row'>
                            <div style={{ textAlign: 'left', width: '50%' }}><h4>{'More Information for ' + this.state.selectedDataSetName}</h4></div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <IconButton
                                    onClick={handleCloseDataSetInfoModal}
                                >
                                    <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3>Long Description: </h3>
                                <p> {this.state.dataset_for_modal.datasetinfo.description} </p>
                            </div>
                            <div className='row col-sm-12' style={{ overflowY: 'scroll', maxHeight: '500px', padding: '25px' }}>
                                <div className='col-sm-12'>
                                    <h3>Attributes:</h3>
                                </div>
                                {this.state.dataset_for_modal.datasetattrsettings_data.map(attr => {
                                    return <div className='col-sm-4' style={{ overflowY: 'scroll', maxHeight: '450px', padding: '25px' }}>
                                        <h6>{this.state.dataset_for_modal.attr_lookup[attr.attribute]}</h6>
                                        <ul>
                                            {attr.attribute_values.map(value => {
                                                return <li>{value}</li>
                                            })}
                                        </ul>
                                    </div>
                                })}
                            </div>
                            <div className='col-sm-12' style={{ height: '50px' }}></div>
                            <div className='col-sm-12' style={{ overflowY: 'scroll', maxHeight: '450px', padding: '25px' }}>
                                <h3>Brands:</h3>
                                <p>{this.state.dataset_for_modal.datasetbrands_data.join(', ')}</p>
                            </div>
                            <div className='col-sm-12' style={{ height: '50px' }}></div>                            
                        </div>
                    </div>
                </Modal>

                <div name='banner' className='banner'>
                    <h1white align='center'>All Datasets</h1white><br/>
                    <h2white align='center'>Below is a list of all Datasets in the tool you have access to.</h2white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <div className='contentHolder' >
                        <div className='row'>
                            <div className='col-sm-12'><h4>Filters</h4></div>
                            <div className='col-sm-6'><h4><span className='formfieldtitle'>Search for a Dataset</span></h4></div>
                            <div className='col-sm-6'><h4><span className='formfieldtitle'>Geographies</span></h4></div>

                            <div className='col-sm-6'>
                                <Select
                                    inputId="datasetdropdown"
                                    options={this.state.datasetnames.map(dataset => {
                                        return { 'value': dataset, 'label': dataset }
                                    })
                                    }
                                    placeholder={'Select Datasets to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'datasets')}
                                    styles={selectColourStyles}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <Select
                                    inputId="geodropdown"
                                    options={this.state.geos.map(geo => {
                                        return { 'value': geo, 'label': geo }
                                    })
                                    }
                                    placeholder={'Select Geographies to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'geos')}
                                    styles={selectColourStyles}
                                />
                            </div>

                            <div className='col-sm-6'><h4><span className='formfieldtitle'>Dataset Includes Time Range</span></h4></div>
                            <div className='col-sm-6'><h4 style={{ textAlign: 'right' }}><span className='formfieldtitle' >Dataset Last updated Between</span></h4></div>

                            <div className="col-sm-3" style={{ backgroundColor: '#FFFFFF', padding: '0px' }}>
                                <DateRangePicker
                                    onChange={handleDates_TimeRange}
                                    value={this.state.DatevaluesTimeRange}
                                    calendarIcon={<DateRangeRoundedIcon style={{ color: '#4e106f' }} />}
                                    clearIcon={<ClearRoundedIcon style={{ color: '#822433' }} />}
                                    className={"testForDatePicker"}
                                />
                            </div>
                            <div className='col-sm-3'></div>
                            <div className='col-sm-3'></div>
                            <div className="col-sm-3" style={{ backgroundColor: '#FFFFFF', padding: '0px' }}>
                                <DateRangePicker
                                    onChange={handleDates_LastUpdated}
                                    value={this.state.DatevaluesLastUpdated}
                                    calendarIcon={<DateRangeRoundedIcon style={{ color: '#4e106f' }} />}
                                    clearIcon={<ClearRoundedIcon style={{ color: '#822433' }} />}
                                    className={"testForDatePicker"}
                                />
                            </div>
                            <div className='col-sm-12' style={{ height: '50px' }}></div>
                            <div className='col-sm-12'><b style={{ color: "#4e106f" }}> Bold Indicates that the Category Selection is Complete </b></div>
                        </div>
                    </div>

                    <Table id="DatasetsTable">
                        <TableHead>
                            <TableRow>
                                {/*
                                <TableCell style={{ width: '5%' }}>

                                </TableCell>
                                */}
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[0]}
                                        direction={this.state.header_sort_direction[0]}
                                        onClick={(event) => sortData(event, 'Name')}
                                    >
                                        <h4>Extract Name</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[1]}
                                        direction={this.state.header_sort_direction[1]}
                                        onClick={(event) => sortData(event, 'Geo')}
                                    >
                                        <h4>Store Groups</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[2]}
                                        direction={this.state.header_sort_direction[2]}
                                        onClick={(event) => sortData(event, 'DateRange')}
                                    >
                                        <h4>Date Range</h4>
                                    </TableSortLabel>
                                </TableCell>


                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[3]}
                                        direction={this.state.header_sort_direction[3]}
                                        onClick={(event) => sortData(event, 'LastModified')}
                                    >
                                        <h4>Last Updated</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '5%' }}>
                                    <h4>Create Item Groups</h4>                                   
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.state.datasetsdata || []).map((dataset) => {
                                let showdataset = dataset.showdataset


                                //Format Date...
                                let lastupdateddate = dataset.time_updated
                                let datasetstart = (dataset.times || {})['min']
                                let datasetend = (dataset.times || {})['max']

                                let dt = new Date()
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                let dateupdatedformatted = (new Date(Date.parse(lastupdateddate) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                                var datasetstartformatted, datasetendformatted, timeaggdesc
                                datasetstartformatted = (new Date(Date.parse(datasetstart) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                                datasetendformatted = (new Date(Date.parse(datasetend) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                                timeaggdesc = 'Week Ending'

                                let geos = dataset.geos || []


                                if (showdataset) {
                                    return <TableRow key={dataset.dataset_id}>
                                        {/*
                                        <TableCell style={{ width: '5%' }}>
                                            <IconButton
                                                //onClick={(event) => sendToCreateReportForm(event, dataset.dataset_id)}
                                            >
                                                <Tooltip
                                                    title={'Create a new report with the ' + dataset.shortdesc + ' dataset'}
                                                    placement="top-start"
                                                >
                                                    <AddBoxIcon style={{ color: '#4e106f', fontSize: '1vw' }} />
                                                </Tooltip>
                                            </IconButton>

                                        </TableCell>
                                        */}
                                        <TableCell>
                                            <span style={{
                                                color: '#4e106f',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                            }}
                                                onClick={(event) => handleOpenDataSetInfoModal(event, dataset.dataset_id, dataset)}
                                            >
                                                {dataset.shortdesc}
                                            </span>

                                        </TableCell>

                                        
                                        
                                        {geos.length > 5 ?
                                            <TableCell>
                                                <span style={{
                                                    color: '#4e106f',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                    onClick={(event) => handleOpenStoreGroupModal(event, geos, dataset.shortdesc)}
                                                >
                                                    {'Click Here to See all ' + geos.length + ' Geographies'}
                                                </span>

                                            </TableCell>
                                            :
                                            <TableCell> {geos.map(geo => geo.geogdesc).join(',')}  </TableCell>
                                        }

                                        <TableCell> {timeaggdesc + ' ' + datasetstartformatted + ' to ' + timeaggdesc + ' ' + datasetendformatted} </TableCell>                                        
                                        <TableCell> {dateupdatedformatted} </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={(event) => openItemGroupsPage(event, dataset.dataset_id)}
                                            >
                                                <AddCircleOutlineRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                }
                                else {
                                    return null
                                }

                            })}
                        </TableBody>
                    </Table>

                </div>
            </Layout>
        )
    }
}