import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateWeeklyBrandData(filters, timeperiod) {
    let filters_brand_weekly = assignFilterLevels(filters, ['brand', 'rowdate'])
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level2selection = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level2selection, 'method': 'POST', 'body': filters_brand_weekly }
    ], 'UpdateWeeklyBrandData.js updateWeeklyBrandData()', 'Weekly Brand')
    let brand_weeklydata_data = my_results['ReturnList'][0]
    let brand_weeklydata_obj = brand_weeklydata_data['level2_data'] || {}

    if (timeperiod === 'totaltimeperiod') {
        this.setState({ weekly_data: brand_weeklydata_obj })
    } else if (timeperiod === 'threeyearsago') {
        this.setState({ weekly_data_threeyears: brand_weeklydata_obj })
    } else if (timeperiod === 'twoyearsago') {
        this.setState({ weekly_data_last: brand_weeklydata_obj })
    } else if (timeperiod === 'prioryear') {
        this.setState({ weekly_data_prior: brand_weeklydata_obj })
    }
    
    return 'Completed'
}
