export default function calc_metrics_promo_new(
	simulation_results
	, filtered_items = null
	, selected_item = this.state.selected_item
	, selected_brands = this.state.selected_brands
	, selected_itemgroup = this.state.selected_itemgroup
) {
	const reducer = (accumulator, currentValue) => accumulator + currentValue;
	//Use the ProdIds from the simsettings items
	let simsettings = this.state.simsettings
	let prodids = simsettings.items.map(item => item.prod_id)

	//If specified filtered_items then use them
	if (filtered_items) {		
		let prodids_raw = filtered_items
		prodids = []
		Object.keys(this.state.simulation_results).forEach(item => { if (prodids_raw.includes(parseInt(item))) { prodids.push(parseInt(item)) } })
	}

	//Vars for calculations	
	let simitems = simsettings.items.map(item => item.prod_id)
	let numweeks = simsettings.numweeks
	let coverage = simsettings.coverage
	let basecalcresultasp = this.state.basecalcresultasp
	
	//Styling
	var showLabelsBrand = true
	var showLabelsItem = true
	var showLabelsItemGroup = true
	var waterfall_categories = [
		"Current Base " + this.state.unitsvar
		, "Price Change"
		, "Non-Price Promotion"
		, "Internal Competitors"
		, "External Competitors"
		, "Simulated Total " + this.state.unitsvar
	]
	var equiv = 1

	var starting_base_volumes = []
	var incremental_volumes = []

	var promo_volumes = []
	var nonpromo_volumes = []
	var subsidized_base_volumes = []
	var unsubsidized_base_volumes = []

	var starting_base_dollars = []
	var new_base_dollars = []
	var diff_base_dollars = []
	var incremental_dollars = []
	var discountdollars = []
	var promodollars = []
	var nonpromodollars = []
	var subsidized_base_dollars = []
	var unsubsidized_base_dollars = []
	var gross_revenues = []
	var self_price_vol_changes = []
	var self_promo_vol_changes = []
	var self_total_vol_changes = []
	var total_cross_vol_changes = []
	var external_cross_vol_changes = []
	var internal_cross_vol_changes = []

	//Used for brand and item views
	var total_volume_changes_brand = {}
	var total_volume_changes_item = {}
	var total_volume_changes_itemgroup = {}

	var starting_volume_brand = {}
	var starting_volume_item = {}
	var starting_volume_itemgroup = {}

	let start_total_dollars_brand = {}
	let start_total_dollars_item = {}
	let start_total_dollars_itemgroup = {}

	let diff_total_dollars_brand = {}
	let diff_total_dollars_item = {}
	let diff_total_dollars_itemgroup = {}

	var total_volume_change_brand = 0
	var total_volume_change_item = 0
	var total_volume_change_itemgroup = 0

	var starting_base_volume_brand = 0
	var starting_base_volume_item = 0
	var starting_base_volume_itemgroup = 0

	var promo_volume_brand = {}
	var nonpromo_volume_brand = {}
	var incdollar_brand = {}
	var promodollar_brand = {}
	var nonpromodollar_brand = {}
	var discountdollar_brand = {}
	var unsubsidized_base_volume_brand = {}
	var subsidized_base_volume_brand = {}
	var unsubsidized_base_dollars_brand = {}
	var subsidized_base_dollars_brand = {}

	var promo_volume_item = {}
	var nonpromo_volume_item = {}
	var incdollar_item = {}
	var promodollar_item = {}
	var nonpromodollar_item = {}
	var discountdollar_item = {}
	var unsubsidized_base_volume_item = {}
	var subsidized_base_volume_item = {}
	var unsubsidized_base_dollars_item = {}
	var subsidized_base_dollars_item = {}

	var promo_volume_itemgroup = {}
	var nonpromo_volume_itemgroup = {}
	var incdollar_itemgroup = {}
	var promodollar_itemgroup = {}
	var nonpromodollar_itemgroup = {}
	var discountdollar_itemgroup = {}
	var unsubsidized_base_volume_itemgroup = {}
	var subsidized_base_volume_itemgroup = {}
	var unsubsidized_base_dollars_itemgroup = {}
	var subsidized_base_dollars_itemgroup = {}

	var total_promo_volume_brand = 0
	var total_nonpromo_volume_brand = 0
	var incdollar_total_brand = 0
	var promodollar_total_brand = 0
	var nonpromodollar_total_brand = 0
	var discountdollar_total_brand = 0
	var unsubsidized_base_volume_total_brand = 0
	var subsidized_base_volume_total_brand = 0
	var unsubsidized_base_dollars_total_brand = 0
	var subsidized_base_dollars_total_brand = 0

	var total_promo_volume_item = 0
	var total_nonpromo_volume_item = 0
	var incdollar_total_item = 0
	var promodollar_total_item = 0
	var nonpromodollar_total_item = 0
	var discountdollar_total_item = 0
	var unsubsidized_base_volume_total_item = 0
	var subsidized_base_volume_total_item = 0
	var unsubsidized_base_dollars_total_item = 0
	var subsidized_base_dollars_total_item = 0

	var total_promo_volume_itemgroup = 0
	var total_nonpromo_volume_itemgroup = 0
	var incdollar_total_itemgroup = 0
	var promodollar_total_itemgroup = 0
	var nonpromodollar_total_itemgroup = 0
	var discountdollar_total_itemgroup = 0
	var unsubsidized_base_volume_total_itemgroup = 0
	var subsidized_base_volume_total_itemgroup = 0
	var unsubsidized_base_dollars_total_itemgroup = 0
	var subsidized_base_dollars_total_itemgroup = 0

	var waterfall_data_brand = {}
	var waterfall_data_item = {}
	var waterfall_data_itemgroup = {}

	var brand_waterfall_effects = {}
	var item_waterfall_effects = {}
	var itemgroup_waterfall_effects = {}

	//Comp Data
	let competitor_data = {}
	let competitor_graph_data_items = []
	let competitor_graph_data_price = []
	let competitor_graph_data_promo = []
	let competitor_graph_data_total = []
	let competitor_graph_data_price_pct = []
	let competitor_graph_data_promo_pct = []
	let competitor_graph_data_total_pct = []

	//Financials
	let gross_revenue = []
	let total_spend = []
	let net_revenue = []
	let total_ttl_cost_per_addl_unit = []
	let incremental_gross_revenue = []
	let incremental_net_revenue = []
	let incremental_profit = []
	let sales_roi = []
	let retailer_promo_margin_dollars = []
	let retailer_promo_margin_percent = []
	let retailer_everyday_margin_percent = []
	let retailer_promotion_profit = []
	let retailer_eday_margin_dollars = []

	let gross_revenue_brand = {}
	let total_spend_brand = {}
	let net_revenue_brand = {}
	let ttl_cost_per_addl_unit_brand = {}
	let incremental_gross_revenue_brand = {}
	let incremental_net_revenue_brand = {}
	let incremental_profit_brand = {}
	let retailer_promo_margin_dollars_brand = {}
	let retailer_promotion_profit_brand = {}
	let retailer_eday_margin_dollars_brand = {}
	let basedollars_brand = {}

	let gross_revenue_item = {}
	let total_spend_item = {}
	let net_revenue_item = {}
	let ttl_cost_per_addl_unit_item = {}
	let incremental_gross_revenue_item = {}
	let incremental_net_revenue_item = {}
	let incremental_profit_item = {}
	let retailer_promo_margin_dollars_item = {}
	let retailer_promotion_profit_item = {}
	let retailer_eday_margin_dollars_item = {}
	let basedollars_item = {}

	let gross_revenue_itemgroup = {}
	let total_spend_itemgroup = {}
	let net_revenue_itemgroup = {}
	let ttl_cost_per_addl_unit_itemgroup = {}
	let incremental_gross_revenue_itemgroup = {}
	let incremental_net_revenue_itemgroup = {}
	let incremental_profit_itemgroup = {}
	let retailer_promo_margin_dollars_itemgroup = {}
	let retailer_promotion_profit_itemgroup = {}
	let retailer_eday_margin_dollars_itemgroup = {}
	let basedollars_itemgroup = {}

	let diff_total_dollar_brand = 0
	let old_total_dollar_brand = 0
	let gross_revenue_total_brand = 0
	let total_spend_total_brand = 0
	let net_revenue_total_brand = 0
	let ttl_cost_per_addl_unit_total_brand = 0
	let incremental_gross_revenue_total_brand = 0
	let incremental_net_revenue_total_brand = 0
	let incremental_profit_total_brand = 0
	let retailer_promo_margin_dollars_total_brand = 0
	let retailer_promotion_profit_total_brand = 0
	let retailer_eday_margin_dollars_total_brand = 0
	let basedollars_total_brand = 0

	let diff_total_dollar_item = 0
	let old_total_dollar_item = 0
	let gross_revenue_total_item = 0
	let total_spend_total_item = 0
	let net_revenue_total_item = 0
	let ttl_cost_per_addl_unit_total_item = 0
	let incremental_gross_revenue_total_item = 0
	let incremental_net_revenue_total_item = 0
	let incremental_profit_total_item = 0
	let retailer_promo_margin_dollars_total_item = 0
	let retailer_promotion_profit_total_item = 0
	let retailer_eday_margin_dollars_total_item = 0
	let basedollars_total_item = 0

	let diff_total_dollar_itemgroup = 0
	let old_total_dollar_itemgroup = 0
	let gross_revenue_total_itemgroup = 0
	let total_spend_total_itemgroup = 0
	let net_revenue_total_itemgroup = 0
	let ttl_cost_per_addl_unit_total_itemgroup = 0
	let incremental_gross_revenue_total_itemgroup = 0
	let incremental_net_revenue_total_itemgroup = 0
	let incremental_profit_total_itemgroup = 0
	let retailer_promo_margin_dollars_total_itemgroup = 0
	let retailer_promotion_profit_total_itemgroup = 0
	let retailer_eday_margin_dollars_total_itemgroup = 0
	let basedollars_total_itemgroup = 0

	var comp_item_changes_brand = {}
	var comp_item_changes_item = {}
	var comp_item_changes_brand_dollars = {}
	var comp_item_changes_item_dollars = {}
	
	prodids.forEach((prodid, idx) => {
		// The back-end identifies items that we won't find in the simulation outputs that are configured in the sim
		if (simulation_results['missing_ids'] !== undefined && simulation_results['missing_ids'].includes(parseInt(prodid))) {
			console.log("ProdID " + prodid + " has no sales and is probably a stale item no longer in the dataset -- skipping it")
			return
		}

		let comp_items = []
		let comp_price_vol_change = []
		let comp_promo_vol_change = []
		let comp_total_vol_change = []
		let comp_price_vol_change_pct = []
		let comp_promo_vol_change_pct = []
		let comp_total_vol_change_pct = []

		let item = {}
		simsettings.items.forEach(item_obj => { if (item_obj.prod_id === prodid) { item = item_obj } })
		
		let adj_base_volume = (simulation_results[prodid]['starting_base_volume'] + simulation_results[prodid]['covid_base_volume'] + simulation_results[prodid]['holiday_base_volume']) * equiv			
		let base_price = basecalcresultasp[prodid] || 0
		let disc_pct = parseFloat(item.sim_discount_pct) || 0
		let promo_price = base_price * (1 - disc_pct)
		let cost_per_unit = item.cost_per_unit || 0
		let profit_margin = (item.gross_margin / 100) || 0
		let retail_margin = (item.retail_margin / 100) || 0

		let totalcrosschange = [0].concat(simulation_results[prodid]['cross_items'].map(item => {
			if (simitems.includes(parseInt(item))) {
				return simulation_results[prodid]['cross'][item]['cross_total']
			} else {
				return 0
			}
		})).reduce(reducer)

		let external_crosschange = [0].concat(simulation_results[prodid]['cross_items'].map(item => {
			if (simitems.includes(parseInt(item)) && !simsettings['sim_brands'].includes(simulation_results[prodid]['cross'][item]['brand'])) {
				return simulation_results[prodid]['cross'][item]['cross_total']
			} else {
				return 0
			}
		})).reduce(reducer)

		let internal_crosschange = [0].concat(simulation_results[prodid]['cross_items'].map(item => {
			if (simitems.includes(parseInt(item)) && simsettings['sim_brands'].includes(simulation_results[prodid]['cross'][item]['brand'])) {
				return simulation_results[prodid]['cross'][item]['cross_total']
			} else {
				return 0
			}
		})).reduce(reducer)

		let simulated_change_factor = simulation_results[prodid]['total'] // - simulation_results[prodid]['total_cross'] + totalcrosschange

		let base_vol = adj_base_volume
		let inc_vol = adj_base_volume * simulated_change_factor
		let promo_vol = adj_base_volume * coverage + inc_vol
		
		let nonpromo_vol = (base_vol + inc_vol) - promo_vol
		let unsubsidized_vol = adj_base_volume * numweeks * (1. - coverage)
		let subsidized_vol = adj_base_volume * numweeks * coverage

		let selfpricevolchange = simulation_results[prodid]['self_price']
		let selftotalvolchange = simulation_results[prodid]['self_total']
		let selfpromovolchange = simulation_results[prodid]['self_promo']

		let base_dollars = base_vol * numweeks * base_price
		let inc_dollars = inc_vol * numweeks * promo_price
		let subsidized_dollars = adj_base_volume * numweeks * promo_price
		let unsubsidizedbase_dollars = unsubsidized_vol * base_price
		let total_dollars = subsidized_dollars + inc_dollars + unsubsidizedbase_dollars
		let nonpromoted_dollars = base_price * numweeks * nonpromo_vol
		let promoted_dollars = total_dollars - nonpromoted_dollars

		let item_brand = simulation_results[prodid].brand

		self_price_vol_changes.push(adj_base_volume * selfpricevolchange * coverage * numweeks)
		self_promo_vol_changes.push(adj_base_volume * selfpromovolchange * coverage * numweeks)
		self_total_vol_changes.push(adj_base_volume * selftotalvolchange * coverage * numweeks)
		total_cross_vol_changes.push(adj_base_volume * totalcrosschange * coverage * numweeks)
		external_cross_vol_changes.push(adj_base_volume * external_crosschange * coverage * numweeks)
		internal_cross_vol_changes.push(adj_base_volume * internal_crosschange * coverage * numweeks)

		// Financial Metrics
		let basedollars_val = base_dollars
		let gross_rev_val = (base_vol * numweeks * promo_price * coverage) + (base_vol * numweeks * base_price * (1. - coverage)) + (inc_vol * promo_price * numweeks)
		let total_spend_val = (promo_vol * (base_price - promo_price) * numweeks) + (promo_vol * numweeks * cost_per_unit)
		let promo_dollars_val = promoted_dollars
		let inc_gross_rev_val = inc_dollars
		let inc_net_rev_val = gross_rev_val - (promo_vol * numweeks * cost_per_unit) 
		//let inc_net_rev_val = inc_gross_rev_val - total_spend_val
		let subsidizedbasedollars_val = subsidized_dollars
		//let net_revenue_new = gross_rev_val - (ttl_cost_per_addl_unit * numweeks)
		//console.log('gross_rev_val', gross_rev_val, 'net_revenue_new', net_revenue_new)
		let ttl_cost_per_addl_unit_calc = promo_vol * numweeks * cost_per_unit


		let add_to_all_sums = (total_summer, item_summer, brand_summer, itemgroup_summer, value_to_add) => {
			// Totals
			if (total_summer !== null) {
				total_summer.push(value_to_add)
            }

			// Brand
			if (!Object.keys(brand_summer).includes(item_brand)) {
				brand_summer[item_brand] = 0
			}
			brand_summer[item_brand] += value_to_add

			// Item
			if (!Object.keys(item_summer).includes(prodid)) {
				item_summer[prodid] = 0
			}
			item_summer[prodid] += value_to_add

			// Item Group
			this.state.selectedgroups.forEach(group => {
				let groupname = group.groupname
				let groupitems = group.item_vals
				if (groupitems.includes(prodid)) {
					if (!Object.keys(itemgroup_summer).includes(groupname)) {
						itemgroup_summer[groupname] = 0
					}
					itemgroup_summer[groupname] += value_to_add
				}
			})
        }

		add_to_all_sums(gross_revenue,					gross_revenue_item,					gross_revenue_brand,					gross_revenue_itemgroup,				gross_rev_val)
		add_to_all_sums(total_spend,					total_spend_item,					total_spend_brand,						total_spend_itemgroup,					total_spend_val)
		add_to_all_sums(net_revenue,					net_revenue_item,					net_revenue_brand,						net_revenue_itemgroup,					gross_rev_val - (promo_vol * numweeks * cost_per_unit))
		add_to_all_sums(total_ttl_cost_per_addl_unit,	ttl_cost_per_addl_unit_item,		ttl_cost_per_addl_unit_brand,			ttl_cost_per_addl_unit_itemgroup,		ttl_cost_per_addl_unit_calc)
		add_to_all_sums(incremental_gross_revenue, incremental_gross_revenue_item, incremental_gross_revenue_brand, incremental_gross_revenue_itemgroup, inc_gross_rev_val)
		add_to_all_sums(incremental_net_revenue,		incremental_net_revenue_item,       incremental_net_revenue_brand,			incremental_net_revenue_itemgroup,		inc_gross_rev_val - total_spend_val)
		add_to_all_sums(incremental_profit,				incremental_profit_item,            incremental_profit_brand,				incremental_profit_itemgroup,			(inc_gross_rev_val - total_spend_val) * profit_margin)
		add_to_all_sums(retailer_promo_margin_dollars,	retailer_promo_margin_dollars_item, retailer_promo_margin_dollars_brand,	retailer_promo_margin_dollars_itemgroup, promo_dollars_val * retail_margin)
		add_to_all_sums(retailer_promotion_profit,		retailer_promotion_profit_item,     retailer_promotion_profit_brand,		retailer_promotion_profit_itemgroup,    (gross_rev_val - subsidizedbasedollars_val) * retail_margin)
		add_to_all_sums(retailer_eday_margin_dollars,	retailer_eday_margin_dollars_item,  retailer_eday_margin_dollars_brand,		retailer_eday_margin_dollars_itemgroup, basedollars_val * retail_margin)

		add_to_all_sums(null, basedollars_item,          basedollars_brand,          basedollars_itemgroup,          basedollars_val)
		add_to_all_sums(null, total_volume_changes_item, total_volume_changes_brand, total_volume_changes_itemgroup, inc_vol * numweeks)
		add_to_all_sums(null, incremental_profit_item,   incremental_profit_brand,   incremental_profit_itemgroup,   inc_dollars * profit_margin)
		add_to_all_sums(null, incdollar_item,            incdollar_brand,            incdollar_itemgroup,            inc_vol * numweeks * promo_price)

		add_to_all_sums(starting_base_volumes,     starting_volume_item,           starting_volume_brand,           starting_volume_itemgroup,           adj_base_volume * numweeks)
		add_to_all_sums(starting_base_dollars,     start_total_dollars_item,       start_total_dollars_brand,       start_total_dollars_itemgroup,       base_dollars)
		add_to_all_sums(incremental_dollars,       diff_total_dollars_item,        diff_total_dollars_brand,        diff_total_dollars_itemgroup,        inc_dollars)
		add_to_all_sums(promo_volumes,             promo_volume_item,              promo_volume_brand,              promo_volume_itemgroup,              promo_vol * numweeks)
		add_to_all_sums(nonpromo_volumes,          nonpromo_volume_item,           nonpromo_volume_brand,           nonpromo_volume_itemgroup,           nonpromo_vol * numweeks)
		add_to_all_sums(subsidized_base_volumes,   subsidized_base_volume_item,    subsidized_base_volume_brand,    subsidized_base_volume_itemgroup,    subsidized_vol)
		add_to_all_sums(unsubsidized_base_volumes, unsubsidized_base_volume_item,  unsubsidized_base_volume_brand,  unsubsidized_base_volume_itemgroup,  unsubsidized_vol)
		add_to_all_sums(promodollars,              promodollar_item,               promodollar_brand,               promodollar_itemgroup,               promoted_dollars)
		add_to_all_sums(nonpromodollars,           nonpromodollar_item,            nonpromodollar_brand,            nonpromodollar_itemgroup,            nonpromoted_dollars)
		add_to_all_sums(discountdollars,           discountdollar_item,            discountdollar_brand,            discountdollar_itemgroup,            (base_price - promo_price) * promo_vol * numweeks)
		add_to_all_sums(unsubsidized_base_dollars, unsubsidized_base_dollars_item, unsubsidized_base_dollars_brand, unsubsidized_base_dollars_itemgroup, unsubsidizedbase_dollars)
		add_to_all_sums(subsidized_base_dollars,   subsidized_base_dollars_item,   subsidized_base_dollars_brand,   subsidized_base_dollars_itemgroup,   subsidized_dollars)

		sales_roi.push((inc_net_rev_val - total_spend_val) / total_spend_val)
		retailer_promo_margin_percent.push(retail_margin)
		retailer_everyday_margin_percent.push(retail_margin)
		incremental_volumes.push(inc_vol * numweeks)
		diff_base_dollars.push(inc_dollars - base_dollars)

		// Add to total brand/item change here...
		Object.keys(simulation_results[prodid]['cross']).forEach(compitem => {
			let itemprice = simulation_results[compitem]['avgbp'] || 0
			let item_base_volume = simulation_results[prodid]['starting_base_volume'] + simulation_results[prodid]['covid_base_volume'] + simulation_results[prodid]['holiday_base_volume']
			if (simitems.includes(parseInt(compitem))) {
				comp_items.push(compitem)
				comp_price_vol_change.push(item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_price'] * coverage)
				comp_promo_vol_change.push(item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_promo'] * coverage)
				comp_total_vol_change.push(item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_total'] * numweeks * -1 * coverage)
				comp_price_vol_change_pct.push(((item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_price'] * coverage) / item_base_volume))
				comp_promo_vol_change_pct.push(((item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_promo'] * coverage) / item_base_volume))
				comp_total_vol_change_pct.push(((item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_total'] * coverage) / item_base_volume) * -1)

				competitor_data[prodid] = [comp_items, comp_price_vol_change, comp_promo_vol_change, comp_total_vol_change, comp_price_vol_change_pct, comp_promo_vol_change_pct, comp_total_vol_change_pct]
			}
			//brandlevel
			let itembrand = this.state.itembrandlookup[compitem] || this.state.simulation_results[compitem]['brand']
			comp_item_changes_brand[itembrand] = 0
			// comp_item_changes_brand[itembrand] = comp_item_changes_brand[itembrand] || 0
			// comp_item_changes_brand[itembrand] += (item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_total'] * numweeks * -1 * coverage)
			//Dollars
			comp_item_changes_brand_dollars[itembrand] = 0
			// comp_item_changes_brand_dollars[itembrand] = comp_item_changes_brand_dollars[itembrand] || 0
			// comp_item_changes_brand_dollars[itembrand] += (item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_total'] * numweeks * -1 * coverage) * itemprice
			//itemlevel
			comp_item_changes_item[compitem] = 0
			// comp_item_changes_item[compitem] = comp_item_changes_item[compitem] || 0
			// comp_item_changes_item[compitem] += (item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_total'] * numweeks * -1 * coverage)
			//Dollars
			comp_item_changes_item_dollars[compitem] = 0
			// comp_item_changes_item_dollars[compitem] = comp_item_changes_item_dollars[compitem] || 0
			// comp_item_changes_item_dollars[compitem] += (item_base_volume * simulation_results[prodid]['cross'][compitem]['cross_total'] * numweeks * -1 * coverage) * itemprice
		})

		// Waterfall by brand
		if (Object.keys(brand_waterfall_effects).includes(item_brand)) {
			brand_waterfall_effects[item_brand][0] += adj_base_volume * numweeks
			brand_waterfall_effects[item_brand][1] += adj_base_volume * selfpricevolchange * coverage * numweeks
			brand_waterfall_effects[item_brand][2] += adj_base_volume * totalcrosschange * coverage * numweeks
			brand_waterfall_effects[item_brand][3] += adj_base_volume * selfpromovolchange * coverage * numweeks
			brand_waterfall_effects[item_brand][4] += adj_base_volume * internal_crosschange * coverage * numweeks //Internal Competitive Effects
			brand_waterfall_effects[item_brand][5] += adj_base_volume * external_crosschange * coverage * numweeks //External Competitive Effects
		} else {
			brand_waterfall_effects[item_brand] = [
				adj_base_volume * numweeks
				, adj_base_volume * selfpricevolchange * coverage * numweeks
				, adj_base_volume * totalcrosschange * coverage * numweeks
				, adj_base_volume * selfpromovolchange * coverage * numweeks
				, adj_base_volume * internal_crosschange * coverage * numweeks
				, adj_base_volume * external_crosschange * coverage * numweeks
			]
		}

		// Waterfall by item
		if (Object.keys(item_waterfall_effects).includes(prodid)) {
			item_waterfall_effects[prodid][0] += adj_base_volume * numweeks
			item_waterfall_effects[prodid][1] += adj_base_volume * selfpricevolchange * coverage * numweeks
			item_waterfall_effects[prodid][2] += adj_base_volume * totalcrosschange * coverage * numweeks
			item_waterfall_effects[prodid][3] += adj_base_volume * selfpromovolchange * coverage * numweeks
			item_waterfall_effects[prodid][4] += adj_base_volume * internal_crosschange * coverage * numweeks //Internal Competitive Effects
			item_waterfall_effects[prodid][5] += adj_base_volume * external_crosschange * coverage * numweeks //External Competitive Effects
		} else {
			item_waterfall_effects[prodid] = [
				adj_base_volume * numweeks
				, adj_base_volume * selfpricevolchange * coverage * numweeks
				, adj_base_volume * totalcrosschange * coverage * numweeks
				, adj_base_volume * selfpromovolchange * coverage * numweeks
				, adj_base_volume * internal_crosschange * coverage * numweeks
				, adj_base_volume * external_crosschange * coverage * numweeks
			]
		}

		this.state.selectedgroups.forEach(group => {
			let groupname = group.groupname
			let groupitems = group.item_vals
			if (groupitems.includes(prodid)) {
				if (Object.keys(itemgroup_waterfall_effects).includes(groupname)) {
					itemgroup_waterfall_effects[groupname][0] += adj_base_volume * numweeks
					itemgroup_waterfall_effects[groupname][1] += adj_base_volume * selfpricevolchange * coverage * numweeks
					itemgroup_waterfall_effects[groupname][2] += adj_base_volume * totalcrosschange * coverage * numweeks
					itemgroup_waterfall_effects[groupname][3] += adj_base_volume * selfpromovolchange * coverage * numweeks
					itemgroup_waterfall_effects[groupname][4] += adj_base_volume * internal_crosschange * coverage * numweeks //Internal Competitive Effects
					itemgroup_waterfall_effects[groupname][5] += adj_base_volume * external_crosschange * coverage * numweeks //External Competitive Effects
				} else {
					itemgroup_waterfall_effects[groupname] = [
						adj_base_volume * numweeks
						, adj_base_volume * selfpricevolchange * coverage * numweeks
						, adj_base_volume * totalcrosschange * coverage * numweeks
						, adj_base_volume * selfpromovolchange * coverage * numweeks
						, adj_base_volume * internal_crosschange * coverage * numweeks
						, adj_base_volume * external_crosschange * coverage * numweeks
					]
				}
			}
		})

		total_volume_change_brand = total_volume_changes_brand[selected_brands]
		total_volume_change_item = total_volume_changes_item[selected_item]
		total_volume_change_itemgroup = total_volume_changes_itemgroup[selected_itemgroup]

		starting_base_volume_brand = starting_volume_brand[selected_brands]
		starting_base_volume_item = starting_volume_item[selected_item]
		starting_base_volume_itemgroup = starting_volume_itemgroup[selected_itemgroup]

		old_total_dollar_brand = start_total_dollars_brand[selected_brands]
		old_total_dollar_item = start_total_dollars_item[selected_item]
		old_total_dollar_itemgroup = start_total_dollars_itemgroup[selected_itemgroup]

		diff_total_dollar_brand = diff_total_dollars_brand[selected_brands]
		diff_total_dollar_item = diff_total_dollars_item[selected_item]
		diff_total_dollar_itemgroup = diff_total_dollars_itemgroup[selected_itemgroup]

		basedollars_total_brand = basedollars_brand[selected_brands]
		basedollars_total_item = basedollars_item[selected_item]
		basedollars_total_itemgroup = basedollars_itemgroup[selected_itemgroup]

		retailer_eday_margin_dollars_total_itemgroup = retailer_eday_margin_dollars_itemgroup[selected_itemgroup]
		retailer_eday_margin_dollars_total_item = retailer_eday_margin_dollars_item[selected_item]
		retailer_eday_margin_dollars_total_brand = retailer_eday_margin_dollars_brand[selected_brands]

		retailer_promotion_profit_total_itemgroup = retailer_promotion_profit_itemgroup[selected_itemgroup]
		retailer_promotion_profit_total_item = retailer_promotion_profit_item[selected_item]
		retailer_promotion_profit_total_brand = retailer_promotion_profit_brand[selected_brands]

		retailer_promo_margin_dollars_total_itemgroup = retailer_promo_margin_dollars_itemgroup[selected_itemgroup]
		retailer_promo_margin_dollars_total_item = retailer_promo_margin_dollars_item[selected_item]
		retailer_promo_margin_dollars_total_brand = retailer_promo_margin_dollars_brand[selected_brands]

		incremental_profit_total_itemgroup = incremental_profit_itemgroup[selected_itemgroup]
		incremental_profit_total_item = incremental_profit_item[selected_item]
		incremental_profit_total_brand = incremental_profit_brand[selected_brands]

		incremental_net_revenue_total_itemgroup = incremental_net_revenue_itemgroup[selected_itemgroup]
		incremental_net_revenue_total_item = incremental_net_revenue_item[selected_item]
		incremental_net_revenue_total_brand = incremental_net_revenue_brand[selected_brands]

		incremental_gross_revenue_total_itemgroup = incremental_gross_revenue_itemgroup[selected_itemgroup]
		incremental_gross_revenue_total_item = incremental_gross_revenue_item[selected_item]
		incremental_gross_revenue_total_brand = incremental_gross_revenue_brand[selected_brands]

		net_revenue_total_itemgroup = net_revenue_itemgroup[selected_itemgroup]
		net_revenue_total_item = net_revenue_item[selected_item]
		net_revenue_total_brand = net_revenue_brand[selected_brands]

		ttl_cost_per_addl_unit_total_itemgroup = ttl_cost_per_addl_unit_itemgroup[selected_itemgroup]
		ttl_cost_per_addl_unit_total_item = ttl_cost_per_addl_unit_item[selected_item]
		ttl_cost_per_addl_unit_total_brand = ttl_cost_per_addl_unit_brand[selected_brands]

		total_spend_total_itemgroup = total_spend_itemgroup[selected_itemgroup]
		total_spend_total_item = total_spend_item[selected_item]
		total_spend_total_brand = total_spend_brand[selected_brands]

		gross_revenue_total_itemgroup = gross_revenue_itemgroup[selected_itemgroup]
		gross_revenue_total_item = gross_revenue_item[selected_item]
		gross_revenue_total_brand = gross_revenue_brand[selected_brands]

		total_promo_volume_brand = promo_volume_brand[selected_brands]
		total_nonpromo_volume_brand = nonpromo_volume_brand[selected_brands]
		incdollar_total_brand = incdollar_brand[selected_brands]
		promodollar_total_brand = promodollar_brand[selected_brands]
		nonpromodollar_total_brand = nonpromodollar_brand[selected_brands]
		discountdollar_total_brand = discountdollar_brand[selected_brands]
		unsubsidized_base_volume_total_brand = unsubsidized_base_volume_brand[selected_brands]
		subsidized_base_volume_total_brand = subsidized_base_volume_brand[selected_brands]
		unsubsidized_base_dollars_total_brand = unsubsidized_base_dollars_brand[selected_brands]
		subsidized_base_dollars_total_brand = subsidized_base_dollars_brand[selected_brands]

		total_promo_volume_item = promo_volume_item[selected_item]
		total_nonpromo_volume_item = nonpromo_volume_item[selected_item]
		incdollar_total_item = incdollar_item[selected_item]
		promodollar_total_item = promodollar_item[selected_item]
		nonpromodollar_total_item = nonpromodollar_item[selected_item]
		discountdollar_total_item = discountdollar_item[selected_item]
		unsubsidized_base_volume_total_item = unsubsidized_base_volume_item[selected_item]
		subsidized_base_volume_total_item = subsidized_base_volume_item[selected_item]
		unsubsidized_base_dollars_total_item = unsubsidized_base_dollars_item[selected_item]
		subsidized_base_dollars_total_item = subsidized_base_dollars_item[selected_item]

		total_promo_volume_itemgroup = promo_volume_itemgroup[selected_itemgroup]
		total_nonpromo_volume_itemgroup = nonpromo_volume_itemgroup[selected_itemgroup]
		incdollar_total_itemgroup = incdollar_itemgroup[selected_itemgroup]
		promodollar_total_itemgroup = promodollar_itemgroup[selected_itemgroup]
		nonpromodollar_total_itemgroup = nonpromodollar_itemgroup[selected_itemgroup]
		discountdollar_total_itemgroup = discountdollar_itemgroup[selected_itemgroup]
		unsubsidized_base_volume_total_itemgroup = unsubsidized_base_volume_itemgroup[selected_itemgroup]
		subsidized_base_volume_total_itemgroup = subsidized_base_volume_itemgroup[selected_itemgroup]
		unsubsidized_base_dollars_total_itemgroup = unsubsidized_base_dollars_itemgroup[selected_itemgroup]
		subsidized_base_dollars_total_itemgroup = subsidized_base_dollars_itemgroup[selected_itemgroup]

		try {
			competitor_graph_data_items = competitor_data[selected_item][0]
			competitor_graph_data_price = competitor_data[selected_item][1]
			competitor_graph_data_promo = competitor_data[selected_item][2]
			competitor_graph_data_total = competitor_data[selected_item][3]

			competitor_graph_data_price_pct = competitor_data[selected_item][4]
			competitor_graph_data_promo_pct = competitor_data[selected_item][5]
			competitor_graph_data_total_pct = competitor_data[selected_item][6]
		} catch (error) {
			competitor_graph_data_items = 0
			competitor_graph_data_price = 0
			competitor_graph_data_promo = 0
			competitor_graph_data_total = 0
			competitor_graph_data_price_pct = 0
			competitor_graph_data_promo_pct = 0
			competitor_graph_data_total_pct = 0
		}

		var price_color = ((adj_base_volume * selfpricevolchange * coverage * numweeks) > 0) ? '#ff8b6a' : '#7d32ba'
		var promo_color = ((adj_base_volume * selfpromovolchange * coverage * numweeks) > 0) ? '#ff8b6a' : '#7d32ba'
		var internal_color = ((adj_base_volume * internal_crosschange * coverage * numweeks) > 0) ? '#ff8b6a' : '#7d32ba'
		var external_color = ((adj_base_volume * external_crosschange * coverage * numweeks) > 0) ? '#ff8b6a' : '#7d32ba'

		waterfall_data_item[prodid] =
			[
				{
					name: 'Base ' + this.state.unitsvar,
					y: adj_base_volume * numweeks
				},
				{
					name: 'Self Price',
					y: adj_base_volume * selfpricevolchange * coverage * numweeks,
					color: price_color
				},
				{
					name: 'Self Promo',
					y: adj_base_volume * selfpromovolchange * coverage * numweeks,
					color: promo_color
				},
				{
					name: 'Internal Cross Effects',
					y: adj_base_volume * internal_crosschange * coverage * numweeks,
					color: internal_color
				},
				{
					name: 'External Cross Effects',
					y: adj_base_volume * external_crosschange * coverage * numweeks,
					color: external_color
				},
				{
					name: 'Total Change',
					isIntermediateSum: true,
				}
			]
	})

	// Get the total comp_changes...
	// Only include the comp changes for the All Other Items...
	let comp_item_changes_item_total = [0].concat(prodids.map(id => {
		return comp_item_changes_item[id] || 0
	})).reduce(reducer)

	//console.log('***** comp_item_changes_item_total value is: ' + comp_item_changes_item_total + '; Setting comp_item_changes_item_total to 0')
	comp_item_changes_item_total = 0.

	var showLabelsBrand = true
	var showLabelsItem = true
	var showLabelsItemGroup = true
	if (Object.keys(starting_volume_brand).length < 6) {
		showLabelsBrand = true
	} else {
		showLabelsBrand = false
	}

	if (Object.keys(starting_volume_item).length < 6) {
		showLabelsItem = true
	} else {
		showLabelsItem = false
	}

	var starting_base_volume_total = 0
	var incremental_volume_total = 0
	var promo_volume_total = 0
	var nonpromo_volume_total = 0
	var subsidized_base_volume_total = 0
	var unsubsidized_base_volume_total = 0
	var starting_base_dollars_total = 0
	var incremental_dollars_total = 0
	var discountdollar_total = 0
	var promodollar_total = 0
	var nonpromodollar_total = 0
	var subsidized_base_dollars_total = 0
	var unsubsidized_base_dollars_total = 0
	var gross_revenue_total = 0
	var total_spend_total = 0
	var net_revenue_total = 0
	var ttl_cost_per_addl_unit_total = 0
	var incremental_gross_revenue_total = 0
	var incremental_net_revenue_total = 0
	var incremental_profit_total = 0
	var sales_roi_total = 0
	var retailer_promo_margin_dollars_total = 0
	var retailer_promo_margin_percent_total = 0
	var retailer_everyday_margin_percent_total = 0
	var retailer_promotion_profit_total = 0
	var retailer_eday_margin_dollars_total = 0
	var diff_base_dollars_total = 0
	var self_price_vol_change = 0
	var self_promo_vol_change = 0
	var total_cross_vol_change = 0
	var internal_cross_vol_change = 0
	var external_cross_vol_change = 0

	try {
		starting_base_volume_total = starting_base_volumes.reduce(reducer)
		incremental_volume_total = incremental_volumes.reduce(reducer) + comp_item_changes_item_total
		promo_volume_total = promo_volumes.reduce(reducer)
		nonpromo_volume_total = nonpromo_volumes.reduce(reducer)
		subsidized_base_volume_total = subsidized_base_volumes.reduce(reducer)
		unsubsidized_base_volume_total = unsubsidized_base_volumes.reduce(reducer)
		starting_base_dollars_total = starting_base_dollars.reduce(reducer)
		incremental_dollars_total = incremental_dollars.reduce(reducer)
		discountdollar_total = discountdollars.reduce(reducer)
		promodollar_total = promodollars.reduce(reducer)
		nonpromodollar_total = nonpromodollars.reduce(reducer)
		subsidized_base_dollars_total = subsidized_base_dollars.reduce(reducer)
		unsubsidized_base_dollars_total = unsubsidized_base_dollars.reduce(reducer)
		gross_revenue_total = gross_revenue.reduce(reducer)
		
		total_spend_total = total_spend.reduce(reducer)
		net_revenue_total = net_revenue.reduce(reducer)
		ttl_cost_per_addl_unit_total = total_ttl_cost_per_addl_unit.reduce(reducer)
		incremental_gross_revenue_total = incremental_gross_revenue.reduce(reducer)
		incremental_net_revenue_total = incremental_net_revenue.reduce(reducer)
		incremental_profit_total = incremental_profit.reduce(reducer)
		sales_roi_total = sales_roi.reduce(reducer)
		retailer_promo_margin_dollars_total = retailer_promo_margin_dollars.reduce(reducer)
		retailer_promo_margin_percent_total = retailer_promo_margin_percent.reduce(reducer)
		retailer_everyday_margin_percent_total = retailer_everyday_margin_percent.reduce(reducer)
		retailer_promotion_profit_total = retailer_promotion_profit.reduce(reducer)
		retailer_eday_margin_dollars_total = retailer_eday_margin_dollars.reduce(reducer)
		diff_base_dollars_total = diff_base_dollars.reduce(reducer)
		self_price_vol_change = self_price_vol_changes.reduce(reducer)
		self_promo_vol_change = self_promo_vol_changes.reduce(reducer)
		total_cross_vol_change = total_cross_vol_changes.reduce(reducer)
		external_cross_vol_change = external_cross_vol_changes.reduce(reducer) + comp_item_changes_item_total
		internal_cross_vol_change = internal_cross_vol_changes.reduce(reducer)
	} catch (error) {
		starting_base_volume_total = 0
		incremental_volume_total = 0
		promo_volume_total = 0
		nonpromo_volume_total = 0
		subsidized_base_volume_total = 0
		unsubsidized_base_volume_total = 0
		starting_base_dollars_total = 0
		incremental_dollars_total = 0
		discountdollar_total = 0
		promodollar_total = 0
		nonpromodollar_total = 0
		subsidized_base_dollars_total = 0
		unsubsidized_base_dollars_total = 0
		gross_revenue_total = 0
		total_spend_total = 0
		net_revenue_total = 0
		ttl_cost_per_addl_unit_total = 0
		incremental_gross_revenue_total = 0
		incremental_net_revenue_total = 0
		incremental_profit_total = 0
		sales_roi_total = 0
		retailer_promo_margin_dollars_total = 0
		retailer_promo_margin_percent_total = 0
		retailer_everyday_margin_percent_total = 0
		retailer_promotion_profit_total = 0
		retailer_eday_margin_dollars_total = 0
		diff_base_dollars_total = 0
		self_price_vol_change = 0
		self_promo_vol_change = 0
		total_cross_vol_change = 0
		internal_cross_vol_change = 0
		external_cross_vol_change = 0
	}

	var price_color = (self_price_vol_change > 0) ? '#ff8b6a' : '#7d32ba'
	var promo_color = (self_promo_vol_change > 0) ? '#ff8b6a' : '#7d32ba'
	var total_color = (total_cross_vol_change > 0) ? '#ff8b6a' : '#7d32ba'
	var internal_color = (internal_cross_vol_change > 0) ? '#ff8b6a' : '#7d32ba'
	var external_color = (external_cross_vol_change > 0) ? '#ff8b6a' : '#7d32ba'

	var waterfall_data =
		[
			{
				name: 'Base ' + this.state.unitsvar,
				y: starting_base_volume_total
			},
			{
				name: 'Self Price',
				y: self_price_vol_change,
				color: price_color
			},
			{
				name: 'Self Promo',
				y: self_promo_vol_change,
				color: promo_color
			},
			{
				name: 'Internal Cross Effects',
				y: internal_cross_vol_change,
				color: internal_color
			},
			{
				name: 'External Cross Effects',
				y: external_cross_vol_change,
				color: external_color
			},
			{
				name: 'Total Change',
				isIntermediateSum: true,
			}
		]
	//console.log(brand_waterfall_effects)
	this.state.brand_options.map(brand => {
		try {
			waterfall_data_brand[brand.value] =
				[
					{
						name: 'Base ' + this.state.unitsvar,
						y: brand_waterfall_effects[brand.value][0]
					},
					{
						name: 'Self Price',
						y: brand_waterfall_effects[brand.value][1],
						color: price_color
					},
					{
						name: 'Self Promo',
						y: brand_waterfall_effects[brand.value][3],
						color: total_color
					},
					{
						name: 'Internal Cross Effects',
						y: brand_waterfall_effects[brand.value][4],
						color: internal_color
					},
					{
						name: 'External Cross Effects',
						y: brand_waterfall_effects[brand.value][5],
						color: external_color
					},
					{
						name: 'Total Change',
						isIntermediateSum: true,
					}
				]
		}
		catch (error) {
			//console.log('Brand Not in Filters')
		}
	})

	this.state.itemgroup_options.map(itemgroup =>
		waterfall_data_itemgroup[itemgroup.value] =
		[
			{
				name: 'Base ' + this.state.unitsvar,
				y: itemgroup_waterfall_effects[itemgroup.value][0]
			},
			{
				name: 'Self Price',
				y: itemgroup_waterfall_effects[itemgroup.value][1],
				color: price_color
			},
			{
				name: 'Self Promo',
				y: itemgroup_waterfall_effects[itemgroup.value][3],
				color: total_color
			},
			{
				name: 'Internal Cross Effects',
				y: itemgroup_waterfall_effects[itemgroup.value][4],
				color: internal_color
			},
			{
				name: 'External Cross Effects',
				y: itemgroup_waterfall_effects[itemgroup.value][5],
				color: external_color
			},
			{
				name: 'Total Change',
				isIntermediateSum: true,
			}
		]
	)

	// For sims against datasets without causal data, splice out the "Non-Price Promotion" item which will always be zero
	if (!simulation_results['has_causal']) {
		let total_brand_npp = [0].concat(this.state.brand_options.map(brand => waterfall_data_brand[brand.label][2]['y'])).reduce(reducer)
		let total_item_npp = [0].concat(prodids.map(prodid => waterfall_data_item[prodid][2]['y'])).reduce(reducer)
		let total_itemgrp_npp = [0].concat(this.state.itemgroup_options.map(itemgroup => waterfall_data_itemgroup[itemgroup.value][2]['y'])).reduce(reducer)
		if (total_brand_npp == 0 && total_item_npp == 0 && total_itemgrp_npp == 0) {
			//console.log('For promo sim without any causal data, removing always-empty "Non-Price Promotion"')
			waterfall_categories.splice(2, 1)

			waterfall_data.splice(2, 1)
			prodids.forEach((prodid, idx) => {
				if (!(simulation_results['missing_ids'] !== undefined && simulation_results['missing_ids'].includes(parseInt(prodid)))) {
					waterfall_data_item[prodid].splice(2, 1)
				}
			})
			this.state.brand_options.map(brand => waterfall_data_brand[brand.value].splice(2, 1))
			this.state.itemgroup_options.map(itemgroup => waterfall_data_itemgroup[itemgroup.value].splice(2, 1))
		} else {
			console.log('************************************************************************************************************************')
			console.log('For promo sim without any causal data, cannot remove always-empty "Non-Price Promotion" because we have non-zero values!')
			console.log('total_brand_npp = ' + total_brand_npp + ", total_item_npp = " + total_item_npp + ", total_itemgrp_npp = " + total_itemgrp_npp)
			console.log('************************************************************************************************************************')
        }
    }

	this.setState({
		unitChangeType: 'Incremental ' + this.state.unitsvar,
		unitPctChangeType: 'Lift % (' + this.state.unitsvar + ')',
		totalCatVoltype: 'Simulated Total ' + this.state.unitsvar,
		totalCatDoltype: 'Simulated Total ' + this.state.currencytype,
		dollarChangeType: 'Incremental ' + this.state.currencytype,
		dollarPctChangeType: '% Lift (' + this.state.currencytype + ')',
		starting_base_volumes,
		incremental_volumes,
		starting_base_dollars,
		new_base_dollars,
		diff_base_dollars,
		gross_revenues,
		starting_base_volume_total,
		incremental_volume_total,
		promo_volume_total,
		nonpromo_volume_total,
		unsubsidized_base_volume_total,
		subsidized_base_volume_total,
		promodollar_total,
		nonpromodollar_total,
		subsidized_base_dollars_total,
		unsubsidized_base_dollars_total,
		starting_base_dollars_total,
		incremental_dollars,
		incremental_dollars_total,
		discountdollars,
		discountdollar_total,
		diff_base_dollars_total,
		gross_revenue_total,
		waterfall_data,
		waterfall_categories,
		display_base: 'none',
		display_promo: '',
		total_volume_changes_brand,
		total_volume_changes_item,
		starting_volume_brand,
		starting_volume_item,
		diff_total_dollars_brand,
		diff_total_dollars_item,
		start_total_dollars_brand,
		start_total_dollars_item,
		showLabelsBrand,
		showLabelsItem,
		waterfall_data_brand,
		waterfall_data_item,
		waterfall_data_itemgroup,
		brand_waterfall_effects,
		item_waterfall_effects,
		total_volume_change_brand,
		total_volume_change_item,
		starting_base_volume_brand,
		starting_base_volume_item,
		diff_total_dollar_brand,
		diff_total_dollar_item,
		old_total_dollar_brand,
		old_total_dollar_item,
		incremental_profit_brand,
		incremental_profit_item,
		competitor_data,
		competitor_graph_data_items,
		competitor_graph_data_price,
		competitor_graph_data_promo,
		competitor_graph_data_total,
		competitor_graph_data_price_pct,
		competitor_graph_data_promo_pct,
		competitor_graph_data_total_pct,
		basedollars_total_item,
		basedollars_total_brand,
		retailer_eday_margin_dollars_total_item,
		retailer_eday_margin_dollars_total_brand,
		retailer_promotion_profit_total_item,
		retailer_promotion_profit_total_brand,
		retailer_promo_margin_dollars_total_item,
		retailer_promo_margin_dollars_total_brand,
		incremental_profit_total_item,
		incremental_profit_total_brand,
		incremental_net_revenue_total_item,
		incremental_net_revenue_total_brand,
		incremental_gross_revenue_total_item,
		incremental_gross_revenue_total_brand,
		net_revenue_total_item,
		net_revenue_total_brand,
		ttl_cost_per_addl_unit_total_item,
		ttl_cost_per_addl_unit_total_brand,
		total_spend_total_item,
		total_spend_total_brand,
		gross_revenue_total_item,
		gross_revenue_total_brand,
		total_spend_total,
		net_revenue_total,
		ttl_cost_per_addl_unit_total,
		incremental_gross_revenue_total,
		incremental_net_revenue_total,
		incremental_profit_total,
		sales_roi_total,
		retailer_promo_margin_dollars_total,
		retailer_promo_margin_percent_total,
		retailer_everyday_margin_percent_total,
		retailer_promotion_profit_total,
		retailer_eday_margin_dollars_total,
		promo_volume_brand,
		nonpromo_volume_brand,
		incdollar_brand,
		promodollar_brand,
		nonpromodollar_brand,
		discountdollar_brand,
		unsubsidized_base_volume_brand,
		subsidized_base_volume_brand,
		unsubsidized_base_dollars_brand,
		subsidized_base_dollars_brand,
		retailer_eday_margin_dollars_brand,
		retailer_promotion_profit_brand,
		retailer_promo_margin_dollars_brand,
		incremental_net_revenue_brand,
		incremental_gross_revenue_brand,
		net_revenue_brand,
		ttl_cost_per_addl_unit_brand,
		total_spend_brand,
		gross_revenue_brand,
		promo_volume_item,
		nonpromo_volume_item,
		incdollar_item,
		promodollar_item,
		nonpromodollar_item,
		discountdollar_item,
		unsubsidized_base_volume_item,
		subsidized_base_volume_item,
		unsubsidized_base_dollars_item,
		subsidized_base_dollars_item,
		retailer_eday_margin_dollars_item,
		retailer_promotion_profit_item,
		retailer_promo_margin_dollars_item,
		incremental_net_revenue_item,
		incremental_gross_revenue_item,
		net_revenue_item,
		ttl_cost_per_addl_unit_item,
		total_spend_item,
		gross_revenue_item,
		total_promo_volume_brand,
		total_nonpromo_volume_brand,
		incdollar_total_brand,
		promodollar_total_brand,
		nonpromodollar_total_brand,
		discountdollar_total_brand,
		unsubsidized_base_volume_total_brand,
		subsidized_base_volume_total_brand,
		unsubsidized_base_dollars_total_brand,
		subsidized_base_dollars_total_brand,

		total_promo_volume_item,
		total_nonpromo_volume_item,
		incdollar_total_item,
		promodollar_total_item,
		nonpromodollar_total_item,
		discountdollar_total_item,
		unsubsidized_base_volume_total_item,
		subsidized_base_volume_total_item,
		unsubsidized_base_dollars_total_item,
		subsidized_base_dollars_total_item,

		basedollars_item,
		basedollars_brand,
		comp_item_changes_brand,
		comp_item_changes_item,
		comp_item_changes_brand_dollars,
		comp_item_changes_item_dollars,

		total_volume_changes_itemgroup,
		starting_volume_itemgroup,
		diff_total_dollars_itemgroup,
		start_total_dollars_itemgroup,
		showLabelsItemGroup,
		waterfall_data_itemgroup,
		itemgroup_waterfall_effects,
		total_volume_change_itemgroup,
		starting_base_volume_itemgroup,
		diff_total_dollar_itemgroup,
		old_total_dollar_itemgroup,
		incremental_profit_itemgroup,
		basedollars_total_itemgroup,
		retailer_eday_margin_dollars_total_itemgroup,
		retailer_promotion_profit_total_itemgroup,
		retailer_promo_margin_dollars_total_itemgroup,
		incremental_profit_total_itemgroup,
		incremental_net_revenue_total_itemgroup,
		incremental_gross_revenue_total_itemgroup,
		net_revenue_total_itemgroup,
		ttl_cost_per_addl_unit_total_itemgroup,
		total_spend_total_itemgroup,
		gross_revenue_total_itemgroup,
		promo_volume_itemgroup,
		nonpromo_volume_itemgroup,
		incdollar_itemgroup,
		promodollar_itemgroup,
		nonpromodollar_itemgroup,
		discountdollar_itemgroup,
		unsubsidized_base_volume_itemgroup,
		subsidized_base_volume_itemgroup,
		unsubsidized_base_dollars_itemgroup,
		subsidized_base_dollars_itemgroup,
		retailer_eday_margin_dollars_itemgroup,
		retailer_promotion_profit_itemgroup,
		retailer_promo_margin_dollars_itemgroup,
		incremental_net_revenue_itemgroup,
		incremental_gross_revenue_itemgroup,
		net_revenue_itemgroup,
		ttl_cost_per_addl_unit_itemgroup,
		total_spend_itemgroup,
		gross_revenue_itemgroup,
		total_promo_volume_itemgroup,
		total_nonpromo_volume_itemgroup,
		incdollar_total_itemgroup,
		promodollar_total_itemgroup,
		nonpromodollar_total_itemgroup,
		discountdollar_total_itemgroup,
		unsubsidized_base_volume_total_itemgroup,
		subsidized_base_volume_total_itemgroup,
		unsubsidized_base_dollars_total_itemgroup,
		subsidized_base_dollars_total_itemgroup,
		basedollars_itemgroup,
		showBackground: false,
		//rollup_options
	})
}
