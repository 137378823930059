import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'

const reducer = (accumulator, currentValue) => accumulator + currentValue;
const BaseIncOrPromoNonPromoByWeek = props => {
    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'
    //console.log(currencytype)
    //console.log(props)
    //Set Chart Variables based on Promo of Base type
    var chart_title = ""
    var series_1_name = ""
    var series_1_color = ""
    var series_2_name = ""
    var series_2_color = ""
    var series_3_name = ""
    var series_3_color = ""
    var table1header = ""
    var table2header = ""
    var table3header = ""
    var table1value = ""
    var table2value = ""
    var table3value = ""
    //var xDateFormatString = ""
    var weeklytimeperiods = ""
    var axisFormatString = ""
    var axisText = ""
    //var tickIntervalNum = 24 * 3600 * 1000 * 7 * props.weekagg
    var tickpositionsarray = props.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))))
    var seriesvar = []

    var series4_vals, series5_vals

    var stepNum = 1
    var paddingNum = 5
    var fontSizeString = '11px'
    if (tickpositionsarray.length > 26 && props.height === 2) {
        stepNum = 2
        paddingNum = 10
        fontSizeString = '9px'
    }
    var aggtype = ""
    if ((props.aggperiod || "") === 'Monthly') {
        aggtype = 'Monthly'
    }
    else {
        aggtype = 'Weekly'
    }

    var stackingType = props.stackingType || 'normal'
    console.log(stackingType)
    var weeklybaseaxis, weeklybasevisible
    if (stackingType === 'percent') {
        weeklybaseaxis = 2
        weeklybasevisible = false
    }
    else {
        weeklybaseaxis = 0
        weeklybasevisible = true
    }

    //Create Series Var
    if (props.type === 'Base') {

          
        series_1_name = props.series1Name || 'Base '        
        series_2_name = props.series2Name || 'Incremental '        
        series_3_name = props.series3Name || 'Holiday '

        series_1_color = props.series1Color || '#7d32ba'
        series_2_color = props.series2Color || '#fdd900'
        series_3_color = props.series3Color || '#ff8b6a'  
           
        table1value = props.series1tablevalue || 'baseunits'
        table2value = props.series2tablevalue || 'incrementalunits'
        table3value = props.series3tablevalue || 'holidayunits'

        if (props.unitsvar === 'Dollars') {
            chart_title = 'Base and Incremental ' + currencytype + ' with ASP by Week - '
            table1header = series_1_name + currencytype
            table2header = series_2_name + currencytype
            table3header = series_3_name + currencytype
        }
        else {
            chart_title = 'Base and Incremental ' + props.unitsvar + ' with ASP by Week - '
            table1header = series_1_name + props.unitsvar
            table2header = series_2_name + props.unitsvar
            table3header = series_3_name + props.unitsvar
        }
        

        seriesvar = [
            {
                name: series_1_name,
                type: 'column',
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {                        
                        let dt = new Date(this.x)                        
                        let offset = dt.getTimezoneOffset() * 60 * 1000                        
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'                                    
                            }
                            else {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                        else {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined,{ month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                        
                    }
                },
                color: series_1_color,
                data: props.series1data,
                stack: 0,
                stacking: stackingType
            },
            {
                name: series_3_name,
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                        else {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                    }
                },
                color: series_3_color,
                data: props.series3data || [],
                stack: 0,
                stacking: stackingType
            },
            {
                name: series_2_name,
                type: 'column',
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                        else {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                    }
                },
                color: series_2_color,
                data: props.series2data,
                stack: 0,
                stacking: stackingType
            },

            {
                name: 'Average Selling Price',
                type: 'spline',
                yAxis: 1,
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            return '<span>Month: '
                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                + '</span>'
                        }
                        else {
                            return '<span>Week Ending: '
                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                + '</span>'
                        }

                    }
                },
                color: '#565a5c',
                data: props.aspweeklydata

            },

            {
                name: 'Average ' + aggtype + ' Base ',
                type: 'line',
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        let dt = new Date(this.value)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    + ' </span>'
                            }
                            else {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' </span>'
                            }
                        }
                        else {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    + ' </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' </span>'
                            }
                        }

                    }

                    , enabled: true
                },
                color: '#000000',
                data: props.avgbaseweeklydata,
                enableMouseTracking: false,
                dataLabels: {
                    enabled: true,
                    filter: {
                        property: 'index',
                        operator: '===',
                        value: 0
                    },
                    formatter: function () {
                        //console.log(this)
                        function nFormatter(num, digits) {
                            var si = [
                                { value: 1E18, symbol: "E" },
                                { value: 1E15, symbol: "P" },
                                { value: 1E12, symbol: "T" },
                                { value: 1E9, symbol: "B" },
                                { value: 1E6, symbol: "M" },
                                { value: 1E3, symbol: "k" }
                            ], i;
                            for (i = 0; i < si.length; i++) {
                                if (num >= si[i].value) {
                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                }
                                else if (num < 1E3) {
                                    return (num).toFixed(0);
                                }
                            }
                            return num.toString();
                        }
                        if (props.unitsvar === 'Dollars') {
                            //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            let value = nFormatter(this.y, 1)
                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                            let symbol = value.substring(value.length - 1)
                            return 'Average ' + aggtype + ' Base ' + currencytype + ' : ' + numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                        }
                        else {
                            //return (this.point.y || 0).toLocaleString()
                            return 'Average ' + aggtype + ' Base ' + props.unitsvar + ' : ' + nFormatter(this.y, 1).toLocaleString()
                        }
                    },

                },
                yAxis: weeklybaseaxis,
                visible: weeklybasevisible
            }

        ]

        if (props.series4data) {
            //Add series4 to the seriesvar...
            let series4_settings = {
                name: props.series4Name,
                type: 'spline',
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            return '<span>Month: '
                                + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + ' </span>'
                        }
                        else {
                            return '<span>Week Ending: '
                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + ' </span>'
                        }

                    }

                    , enabled: true
                },
                color: props.series4Color,
                data: props.series4data,
                enableMouseTracking: true,
                dataLabels: {
                    enabled: false,
                    formatter: function () {
                        return (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    },

                },
                yAxis: 3,
                visible: true,
                marker: {
                    enabled: false,
                    radius: 6,
                    symbol: 'diamond',

                }
            }

            if (props.weekagg === 1) {
                seriesvar.push(series4_settings)
            }
            
            //add series 5 to view...
            let series4data_vals = props.series4data.map(datapoint => datapoint[1])
            series4_vals = series4data_vals
            if (props.weekagg > 1) {

                let series3data_vals = props.series3data.map(datapoint => datapoint[1])
                let series1data_vals = props.series1data.map(datapoint => datapoint[1])

                console.log(series3data_vals)
                console.log(series1data_vals)
                console.log(props.weekagg)
                let series5data_vals = series3data_vals.map((sub, idx) => { return ((sub / (sub + series1data_vals[idx])) * props.weekagg) })
                console.log(series5data_vals)
                let series5_data = props.distinctdates.sort().map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), series5data_vals[idx]])
                console.log(series5_data)
                let series5_settings = {
                    name: props.weekagg + ' Week Base Weighted Weeks',
                    type: 'spline',
                    tooltip: {
                        //xDateFormat: xDateFormatString,
                        pointFormatter: function () {
                            let dt = new Date(this.x)
                            let offset = dt.getTimezoneOffset() * 60 * 1000
                            let date = new Date(this.x + offset)
                            if (aggtype === 'Monthly') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + (this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    + ' </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + (this.y).toLocaleString(undefined, {  minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    + ' </span>'
                            }

                        }

                        , enabled: true
                    },
                    color: '#337497',
                    data: series5_data,
                    enableMouseTracking: true,
                    dataLabels: {
                        enabled: false,
                        formatter: function () {
                            return (this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        },

                    },
                    yAxis: 4,
                    visible: true,
                    marker: {
                        enabled: false,
                        radius: 6,
                        symbol: 'diamond',

                    }
                }
                seriesvar.push(series5_settings)
                series5_vals = series5data_vals
            }
        }

    }
    else {
        if (props.unitsvar === 'Dollars') {
            chart_title = 'Non-Promoted and Promoted ' + currencytype + ' with ASP by Week - '
            table1header = 'Non-Promoted ' + currencytype
            table2header = 'Promoted ' + currencytype
            table1value = 'nonpromoteddollars'
            table2value = 'promoteddollars'
        }
        else {
            chart_title = 'Non-Promoted and Promoted ' + props.unitsvar + ' with ASP by Week - '
            table1header = 'Non-Promoted ' + props.unitsvar
            table2header = 'Promoted ' + props.unitsvar
            table1value = 'nonpromotedunits'
            table2value = 'promotedunits'
        }
        
        series_1_name = 'Non-Promoted '
        series_1_color = '#7d32ba'
        series_2_name = 'Promoted '
        series_2_color = '#bc81ec'
        series_3_name = props.series3Name || ""
        series_3_color = props.series3Color || "#FFFFFF"
                
        table3header = ''
        table3value = ['unknown']

        seriesvar = [
            {
                name: series_1_name,
                type: 'column',
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                        else {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                    }
                },
                color: series_1_color,
                data: props.series1data,
                stack: 0,
                stacking: stackingType
            },
            {
                name: series_3_name,
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                        else {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                    }
                },
                color: series_3_color,
                data: props.series3data || [],
                stack: 0,
                stacking: stackingType
            },
            {
                name: series_2_name,
                type: 'column',
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                        else {
                            if (props.unitsvar === 'Dollars') {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + Math.round(this.y).toLocaleString()
                                    + ' '
                                    + props.unitsvar
                                    + ' ('
                                    + (this.percentage / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + ') </span>'
                            }
                        }
                    }
                },
                color: series_2_color,
                data: props.series2data,
                stack: 0,
                stacking: stackingType
            },

            {
                name: 'Average Selling Price',
                type: 'spline',
                yAxis: 1,
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            return '<span>Month: '
                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                + '</span>'
                        }
                        else {
                            return '<span>Week Ending: '
                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                + '</span>'
                        }
                    }
                },
                color: '#565a5c',
                data: props.aspweeklydata

            },

            {
                name: 'Average ' + aggtype + ' Base ',
                type: 'line',
                tooltip: {
                    //xDateFormat: xDateFormatString,
                    pointFormatter: function () {
                        if (props.unitsvar === 'Dollars') {
                            return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' ' + props.unitsvar + ' </span>'
                        }
                        else {
                            return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + ' ' + props.unitsvar + ' </span>'
                        }

                    }

                    , enabled: true
                },
                color: '#000000',
                data: props.avgbaseweeklydata,
                enableMouseTracking: false,
                dataLabels: {
                    enabled: true,
                    filter: {
                        property: 'index',
                        operator: '===',
                        value: 0
                    },
                    formatter: function () {
                        //console.log(this)
                        function nFormatter(num, digits) {
                            var si = [
                                { value: 1E18, symbol: "E" },
                                { value: 1E15, symbol: "P" },
                                { value: 1E12, symbol: "T" },
                                { value: 1E9, symbol: "B" },
                                { value: 1E6, symbol: "M" },
                                { value: 1E3, symbol: "k" }
                            ], i;
                            for (i = 0; i < si.length; i++) {
                                if (num >= si[i].value) {
                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                }
                                else if (num < 1E3) {
                                    return (num).toFixed(0);
                                }
                            }
                            return num.toString();
                        }
                        if (props.unitsvar === 'Dollars') {
                            //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            let value = nFormatter(this.y, 1)
                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                            let symbol = value.substring(value.length - 1)
                            return 'Average ' + aggtype + ' Base ' + currencytype + ' : ' + numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                        }
                        else {
                            //return (this.point.y || 0).toLocaleString()
                            return 'Average ' + aggtype + ' Base ' + props.unitsvar + ' : ' + nFormatter(this.y, 1).toLocaleString()
                        }
                    },

                },
                yAxis: weeklybaseaxis,
                visible: weeklybasevisible
            }


        ]
    }   
    //Look at props.weekagg...use that to change the xDateFormat...
    if ((props.aggperiod || "") === 'Monthly') {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Month Period Ending: %Y-%m-%d'
            weeklytimeperiods = props.weekagg + " Monthly Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Month Period'
        }

        else {
            //xDateFormatString = 'Month Ending: %Y-%m-%d'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Month'
        }
    }
    else {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Week Period Ending: %Y-%m-%d'
            weeklytimeperiods = props.weekagg + " Week Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Week Period'
        }

        else {
            //xDateFormatString = 'Week Ending: %Y-%m-%d'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Week Ending'
        }
    }

    var yaxistext = ''
    var averageweeklylabel = ''
    if (props.unitsvar === 'Dollars') {
        yaxistext = currencytype
        averageweeklylabel = 'Average Weekly Base ' + currencytype
        //console.log(averageweeklylabel)
    }
    else {
        yaxistext = props.unitsvar
    }

    if (stackingType === 'percent') {
        yaxistext = 'Percent'
    }

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        zoomType: 'xy',
                        height: (9 / 16 * 100) + '%' //16:9 ratio
                    },
                    metadata: props.metadata || {},
                    caption: { text: props.attr_caption_text },
                    title: { text: chart_title + props.chart_title_info + weeklytimeperiods},
                    series: seriesvar,
                    xAxis: {
                        title: {
                            text: axisText
                        },
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            month: axisFormatString
                        },  
                        //tickInterval: tickIntervalNum
                        tickPositions: tickpositionsarray,
                        labels: {
                            formatter: function () {
                                let dt = new Date(this.value)
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                let date = new Date(this.value + offset)
                                //console.log(date)
                                const options = { year: 'numeric', month: 'short' };
                                const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                if ((props.aggperiod || "") === 'Monthly') {
                                    return date.toLocaleDateString(undefined, options)
                                }
                                else {
                                    return date.toLocaleDateString(undefined, options2)//date.toISOString().substring(0, 10)
                                }
                                
                            },
                            rotation: 270,
                            step: stepNum,
                            padding: paddingNum,
                            style: {
                                fontSize: fontSizeString
                            }
                        }
                    },
                    yAxis: [
                        {
                            title: {
                                text: yaxistext
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            reversedStacks: false,
                            labels: {
                                formatter: function () {
                                    //console.log(this)
                                    function nFormatter(num, digits, axisMax) {
                                        var si = [
                                            { value: 1E18, symbol: "E" },
                                            { value: 1E15, symbol: "P" },
                                            { value: 1E12, symbol: "T" },
                                            { value: 1E9, symbol: "B" },
                                            { value: 1E6, symbol: "M" },
                                            { value: 1E3, symbol: "k" }
                                        ], i;

                                        let symbolForAxis = " "
                                        let valueForAxis = 1

                                        for (i = 0; i < si.length; i++) {
                                            if (axisMax >= si[i].value) {
                                                symbolForAxis = si[i].symbol
                                                valueForAxis = si[i].value
                                                break;
                                            }
                                        }

                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                    }
                                    if (stackingType === 'percent') {
                                        return ((this.value / 100) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                    else {
                                        if (props.unitsvar === 'Dollars') {                                            
                                            let value = nFormatter(this.value, 1, this.axis.max)
                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                            let symbol = value.substring(value.length - 1)
                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                        }
                                        else {                                            
                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                        }
                                    }
                                    
                                },

                            }
                        },
                        {
                            title: {
                                text: 'Average Selling Price'
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            opposite: true,
                            labels: {
                                formatter: function () {
                                    function nFormatter(num, digits, axisMax) {
                                        //console.log(axisMax)
                                        var si = [
                                            { value: 1E18, symbol: "E" },
                                            { value: 1E15, symbol: "P" },
                                            { value: 1E12, symbol: "T" },
                                            { value: 1E9, symbol: "B" },
                                            { value: 1E6, symbol: "M" },
                                            { value: 1E3, symbol: "k" }
                                        ], i;

                                        let symbolForAxis = " "
                                        let valueForAxis = 1

                                        for (i = 0; i < si.length; i++) {
                                            if (axisMax >= si[i].value) {
                                                symbolForAxis = si[i].symbol
                                                valueForAxis = si[i].value
                                                break;
                                            }
                                        }

                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                    }

                                    let value = nFormatter(this.value, 2, this.axis.max)
                                    let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                    let symbol = value.substring(value.length - 1)
                                    return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                    
                                }
                            }
                        },
                        {
                            title: {
                                text: yaxistext
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            reversedStacks: false,
                            labels: {
                                formatter: function () {
                                    //console.log(this)
                                    function nFormatter(num, digits, axisMax) {
                                        var si = [
                                            { value: 1E18, symbol: "E" },
                                            { value: 1E15, symbol: "P" },
                                            { value: 1E12, symbol: "T" },
                                            { value: 1E9, symbol: "B" },
                                            { value: 1E6, symbol: "M" },
                                            { value: 1E3, symbol: "k" }
                                        ], i;

                                        let symbolForAxis = " "
                                        let valueForAxis = 1

                                        for (i = 0; i < si.length; i++) {
                                            if (axisMax >= si[i].value) {
                                                symbolForAxis = si[i].symbol
                                                valueForAxis = si[i].value
                                                break;
                                            }
                                        }

                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                    }
                                    if (props.unitsvar === 'Dollars') {
                                        //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        let value = nFormatter(this.value, 1, this.axis.max)
                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                        let symbol = value.substring(value.length - 1)
                                        return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                    }
                                    else {
                                        //return (this.point.y || 0).toLocaleString()
                                        return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                    }
                                },

                            },
                            visible: false
                        },
                        {
                            title: {
                                text: 'Base Support %'
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            reversedStacks: false,
                            labels: {
                                formatter: function () {
                                    return (this.value  || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                },

                            }
                        },
                        {
                            title: {
                                text: props.weekagg + ' Week Base Weighted Weeks'
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            reversedStacks: false,
                            labels: {
                                formatter: function () {
                                    return (this.value || 0).toLocaleString(undefined, {  minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                },

                            },
                            opposite: true
                        }
                    ],
                    plotOptions: {
                        column: {
                            stacking: stackingType,
                            dataLabels: {
                                enabled: false,
                                format: '{point.y:,.0f}'
                            },
                            opacity: 1
                        },

                        spline: {
                            marker: {
                                enabled: false
                            }
                        },
                        line: {
                            marker: {
                                enabled: false
                            },
                            tooltip: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                },
                                
                            },
                        },
                        series: {
                            states: {
                                hover: {
                                    enabled: false
                                },
                                inactive: {
                                    opacity: .25
                                }
                            }
                        }

                    },
                    tooltip: {
                        headerFormat: '',                        
                    },
                    exporting: {
                        showTable: props.showDataTable,
                        tableCaption: false
                    },

                }}
            />
            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g15">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{table1header}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{table2header}</Constant.NPDTableCell>
                            {((props.series3data || []).length > 0) ? <Constant.NPDTableCell style={{ textAlign: 'center' }}>{table3header}</Constant.NPDTableCell> : <Fragment/>}
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Average Selling Price</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{props.unitsvar === 'Dollars'
                                ? <Fragment>{averageweeklylabel}</Fragment>
                                : <Fragment>Average Weekly Base Units</Fragment>}
                            </Constant.NPDTableCell>
                            {((props.series4data || []).length > 0 && props.weekagg === 1) ? <Constant.NPDTableCell style={{ textAlign: 'center' }}>Base Weeks Support</Constant.NPDTableCell> : <Fragment />}
                            {((props.series4data || []).length > 0 && props.weekagg > 1) ? <Constant.NPDTableCell style={{ textAlign: 'center' }}>{props.weekagg + ' Weeks Base Weighted Weeks'}</Constant.NPDTableCell> : <Fragment />}
                        </Constant.NPDTableRow>
                        {props.distinctdates.map((date, idx) => {
                            return <Constant.NPDTableRow key={"datarow_" + date + "_g15"}>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                {props.unitsvar === 'Dollars'
                                    ?
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data[props.selectedBrand] || {})[date] || {})[table1value]).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    :
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data[props.selectedBrand] || {})[date] || {})[table1value]).toLocaleString()}</Constant.NPDTableCell>
                                }
                                {props.unitsvar === 'Dollars'
                                    ?
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data[props.selectedBrand] || {})[date] || {})[table2value]).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    :
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data[props.selectedBrand] || {})[date] || {})[table2value]).toLocaleString()}</Constant.NPDTableCell>
                                }
                                {props.unitsvar === 'Dollars'
                                    ?
                                    ((props.series3data || []).length > 0) ? <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data[props.selectedBrand] || {})[date] || {})[table3value]).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell> : <Fragment />
                                    :
                                    ((props.series3data || []).length > 0) ? <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data[props.selectedBrand] || {})[date] || {})[table3value]).toLocaleString()}</Constant.NPDTableCell> : <Fragment />
                                }
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{((((props.weekly_data[props.selectedBrand] || {})[date] || {})['totaldollars'] / ((props.weekly_data[props.selectedBrand] || {})[date] || {})['totalunits']) || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                {props.unitsvar === 'Dollars'
                                    ?
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(props.distinctdates.sort().map(date => ((props.weekly_data[props.selectedBrand] || {})[date] || {})['basedollars']).reduce(reducer) / props.distinctdates.length).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    :
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(props.distinctdates.sort().map(date => ((props.weekly_data[props.selectedBrand] || {})[date] || {})['baseunits']).reduce(reducer) / props.distinctdates.length).toLocaleString()}</Constant.NPDTableCell>
                                }
                                {((props.series4data || []).length > 0 && props.weekagg === 1)
                                    ?
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(series4_vals[idx]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    :
                                    <Fragment/>
                                }
                                {((props.series4data || []).length > 0 && props.weekagg > 1)
                                    ?
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(series5_vals[idx] || '').toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    :
                                    <Fragment />
                                }
                            </Constant.NPDTableRow>
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default BaseIncOrPromoNonPromoByWeek;