import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { ALRMetricSelect } from '../../UIElements/Dropdowns/ALRMetricSelect'
import { ALRLevelSelect } from '../../UIElements/Dropdowns/ALRLevelSelect'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import CircularProgress from '@material-ui/core/CircularProgress';
import ALRvsROM from '../../../Graphs/ALRvsROM'
import '../../../styles/custom.css'


export class ALRvsROMGraph extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        var aggtype = this.props.state.datasettimeagg

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div align='left' className="col-sm-4" >
                        <ALRMetricSelect
                            state={this.props.state}
                            changeSelectedMetric={this.props.changeSelectedMetric}
                        />
                    </div>
                    <div align='left' className="col-sm-4" >
                        <ALRLevelSelect
                            state={this.props.state}
                            changeSelectedLevel={this.props.changeSelectedLevel}
                        />
                    </div>
                    <div align='left' className="col-sm-4 row" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    {this.props.state.selectedLevel.value === 'Brand' ?
                        <div align='left' className="col-sm-12" >
                            <MultiBrandSelect
                                state={this.props.state}
                                changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                            />
                        </div>
                        :
                        <div align='left' className="col-sm-12" ></div>
                    }
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        {this.props.state.ALRvsROM_loading
                            ?
                            <div style={{ minHeight: '250px', padding: '100px' }}>
                                <div class="custom-loader"></div>
{/*                                <CircularProgress color="inherit" />*/}
                            </div>
                            :
                            <ALRvsROM
                                chartmetadata={this.props.state.chartmetadata}
                                attr_caption_text={this.props.state.attr_caption_text}
                                chart_title_info={this.props.state.chart_title_info}
                                outlets={this.props.state.outlets}
                                categories={this.props.state.ALRvsROMCategories || ['Total']}
                                metric={this.props.state.selectedMetric.value}
                                level={this.props.state.selectedLevel.value}
                                dataset={this.props.state.ALRvsROMDataset}
                                showDataTable={this.props.state.showDataTable}
                                usedollars={this.props.state.indexSwitchCheckDollars}
                                currencyType={this.props.state.currencyType}
                            />
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}