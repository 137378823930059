import removeItemOnce from '../Utilities/RemoveItemOnce'

export default function CreateFilters(filters_raw, exclude_array, weeks_info, daterange, priceBandOptions) {
    let api_filter_attrs = Object.keys(filters_raw['attributes']).map(attr => {
        return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All').filter(Boolean)]
    })

    var filter_daterange
    if (daterange === 'currentyear') {
        filter_daterange = [weeks_info.startdate_calc, weeks_info.enddate]
    } else if (daterange === 'prioryear') {
        filter_daterange =[weeks_info.prior_start, weeks_info.prior_end]
    } else if (daterange === 'twoyearsago') {
        filter_daterange = [weeks_info.last_start, weeks_info.last_end]
    } else if (daterange === 'threeyearsago') {
        filter_daterange = [weeks_info.threeyear_start, weeks_info.threeyear_end]
    } else {
        filter_daterange = [weeks_info.startdate, weeks_info.enddate]
    }

    return {
        exclude_brands: exclude_array[0],
        exclude_items: exclude_array[1],
        brands: removeItemOnce(filters_raw['brands'], 'All').filter(Boolean),
        items: removeItemOnce(filters_raw['items'], 'All').filter(Boolean),
        week_aggregation: 1, // filters_raw['numweeks'],
        asp_range: filters_raw['asp_range'],
        date_range: filter_daterange,
        outlets: removeItemOnce(filters_raw['outlets'], 'All').filter(Boolean),
        sel1_list: removeItemOnce(filters_raw['segment1'], 'All').filter(Boolean),
        sel2_list: removeItemOnce(filters_raw['segment2'], 'All').filter(Boolean),
        sel3_list: removeItemOnce(filters_raw['segment3'], 'All').filter(Boolean),
        sel4_list: removeItemOnce(filters_raw['segment4'], 'All').filter(Boolean),
        attributes: api_filter_attrs,
        price_bands: removeItemOnce(filters_raw['pricebands'], 'All').filter(Boolean),
        price_bands_defs: priceBandOptions,
        channels: removeItemOnce(filters_raw['channels'], 'All').filter(Boolean)
    }
}
