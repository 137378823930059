
export default async function downloadFlexibleBrandTableData() {
    let defaultLevelsSelected = [
        { 'label': 'Brand', 'value': 'brand' },
        { 'label': this.state.segment1label, 'value': 'segment1_name' },
        { 'label': this.state.segment2label, 'value': 'segment2_name' },
        { 'label': 'Outlet', 'value': 'outlet' }
    ]
    this.downloadFlexibleTableDataCommon('brand', defaultLevelsSelected, 'Retailer Brand Performance')
}
