export default function setPriceDistOption(selectedOptions) {

    const runcomparison_apis = this.runcomparison_apis
    const setSelectedItem_new = this.setSelectedItem_new

    async function runupdates(state_object) {

        //If selectedItem_new run setSelectedItem_new
        if (state_object.selectedItem_new) {
            await setSelectedItem_new({ 'value': state_object.selectedItem_new, 'label': state_object.selectedItem_new }, 'item')
        }

        //If definitions run runcomparison_apis
        if (state_object.g1definition && state_object.g2definition) {
            await runcomparison_apis()
        }
    }

    console.log(selectedOptions)

    this.setState({
        price_dist_option: selectedOptions.value,
        selected_itemdistpriceoption: selectedOptions
    }, () => runupdates(this.state))
    
    
}