import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class MultiItemSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <div align='left' className="col-sm-12" >
                    <span className="formfieldtitle">Select Items</span>
                    <Select
                        options={this.props.state.items_sorted.map(brand => { return { 'value': brand, 'label': brand } })}
                        isMulti
                        placeholder={'Select Items'}
                        onChange={(item, position) => this.props.changeSelectedItemMulti(item, 0)}
                        value={this.props.state.selectedItems.map(item => { return { 'label': item, 'value': item } })}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}
                    />
                </div>
                <div align='left' className="col-sm-12" >
                    <span className="formfieldtitle">If More than 10 items are selected in the filters, this will default to the first 10 items by sales volume.</span>
                </div>                  
            </Fragment>
        )
    }
}