import React, { Component, Fragment } from 'react';
import { Layout } from '../Layout';
import Select from 'react-select'
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@mui/material/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Prism from 'prismjs'
import selectColourStyles from '../styles/SelectColourStyles'
import "../styles/prism.css";
import * as Constant from '../constants'
import '../styles/custom.css';

function formatTimeFromNum(timenum) {
    let sec_num = parseInt(timenum * 60 * 60)
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    var days = Math.floor(hours / 24)
    var daypart = ''

    if (days > 0) {
        hours = hours % 24
        if (days === 1) {
            daypart = days + ' Day '
        }
        else {
            daypart = days + ' Days '
        }
    }

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return daypart + hours + ':' + minutes + ':' + seconds;
}

async function getCloudJobs(refreshData = false) {
    this.setState({ showBackground: true })
    let url = Constant.baseapiurl_gen + 'cloud/ListJobHistory?max_days=' + this.state.maxDays + '&max_jobs=' + this.state.maxJobs
    let jobDataResult = await fetch(url)
    let jobData = []
    let jobID_options = []
    let runID_options = []
    let etlID_options = []
    let etlName_options = []
    let industry_options = []
    let apiversion_options = []
    let status_options = []
    let runtime_options = []
    let jobstarted_options = []
    let lastUpdatedTimeString
    if (jobDataResult.status === 200) {
        jobData = await jobDataResult.json()
        let lastUpdatedTime_date = new Date()
        lastUpdatedTimeString = lastUpdatedTime_date.toLocaleDateString() + ' ' + lastUpdatedTime_date.toLocaleTimeString()
        //Add a key called 'showdataset'
        jobData.forEach(job => job['showdataset'] = true)
        //If existing filters, run the filters...
        
        //Build Options
        function buildOptions(key) {
            let uniqueOptions = (Array.from(new Set(jobData.map(job => job[key])))) || []
            return uniqueOptions.map(option => {
                if (key === 'run_time') {
                    try {
                        return { 'label': formatTimeFromNum(option), 'value': option }
                    }
                    catch (error) {
                        return { 'label': option, 'value': option }
                    }
                }
                else {
                    return { 'label': option, 'value': option }
                }
                
            })
        }

        jobID_options = buildOptions('job_id')
        runID_options = buildOptions('run_id')
        etlID_options = buildOptions('etl_id')
        etlName_options = buildOptions('etl_shortname')
        industry_options = buildOptions('industry')
        apiversion_options = buildOptions('version')
        status_options = buildOptions('status')
        runtime_options = buildOptions('run_time')
        jobstarted_options = buildOptions('start_time')
    }
    else {
        alert('There was an error retrieving cloud job data')
    }

    this.setState({
        jobData,
        showBackground: false,
        lastUpdatedTimeString,
        jobID_options,
        runID_options,
        etlID_options,
        etlName_options,
        industry_options,
        apiversion_options,
        status_options,
        runtime_options,
        jobstarted_options,
        refreshData
    })
}

export class PDTETLCloudStatus extends Component {
    static displayName = PDTETLCloudStatus.name;

    constructor(props) {
        super(props);
        this.getCloudJobs = getCloudJobs.bind(this)
        this.state = {
            showBackground: true,
            maxDays: 2,
            maxJobs: 20,
            jobData: [],
            notebookLogsData: [],
            clusterData: {},
            openNotebookLogsModal: false,
            openClusterModal: false,
            openCancelDialog: false,
            lastUpdatedTimeString: null,
            lastUpdatedTimeString_Notebooks: null,
            lastUpdatedTimeString_Cluster: null,
            active_runid: null,
            notebooksRepeat: null,
            clusterRepeat: null,
            page: 0,
            rowsPerPage: 5,
            header_sort_info: {
                'jobid': {
                    'active': true,
                    'direction': 'desc'
                },
                'runid': {
                    'active': false,
                    'direction': 'desc'
                },
                'etlid': {
                    'active': false,
                    'direction': 'desc'
                },
                'etlshortdesc': {
                    'active': false,
                    'direction': 'desc'
                },
                'industry': {
                    'active': false,
                    'direction': 'desc'
                },
                'apiversion': {
                    'active': false,
                    'direction': 'desc'
                },
                'jobstatus': {
                    'active': false,
                    'direction': 'desc'
                },
                'runtime': {
                    'active': false,
                    'direction': 'desc'
                },
                'jobstarted': {
                    'active': false,
                    'direction': 'desc'
                }
            },
            jobID_options: [],
            runID_options: [],
            etlID_options: [],
            etlName_options: [],
            industry_options: [],
            apiversion_options: [],
            status_options: [],
            runtime_options: [],
            jobstarted_options: [],
            filters: {
                'jobid': ['All'],
                'runid': ['All'],
                'etlid': ['All'],
                'etlshortdesc': ['All'],
                'industry': ['All'],
                'apiversion': ['All'],
                'jobstatus': ['All'],
                'runtime': ['All'],
                'jobstarted': ['All'],
            },
            selectedstartdaterange: null,
            selectedenddaterange: null,
            DatevaluesTimeRange: null,
            refreshData: false,
        }
    }

    async componentDidMount() {
        this.setState({ showBackground: false })
        Prism.highlightAll();
        setInterval(() => this.setState({ time: Date.now() }), 1000)
        setInterval(() => { this.getCloudJobs(true) }, 300000) //Update the API data Every 5 minutes
    }

    componentWillUnmount() {
        clearInterval()
    }


    

    render() {
        console.log(this.state)

        const getJobStatus = async (runid) => {
            this.setState({ showBackground: true })
            let url = Constant.baseapiurl_gen + 'cloud/JobStatus?run_id=' + runid
            let jobStatusDataResult = await fetch(url)
            let jobStatusData = {}
            
            if (jobStatusDataResult.status === 200) {
                jobStatusData = await jobStatusDataResult.json()                
            }
            else {
                alert('There was an error retrieving cloud job data')
            }

            this.setState({ jobStatusData, showBackground: false })
        }

        const repeatClusterEvents = async (runid) => {
            await getClusterEvents(runid)
            const clusterRepeat = setInterval(() => { getClusterEvents(runid) }, 600000) //Update the API data Every 10 minutes
            this.setState({ clusterRepeat, openClusterModal: true })
        }

        const getClusterEvents = async (runid) => {
            this.setState({ showBackground: true })
            let url = Constant.baseapiurl_gen + 'cloud/ClusterEvents?run_id=' + runid
            let clusterDataResult = await fetch(url)
            let clusterData = {}
            let lastUpdatedTimeString_Cluster
            if (clusterDataResult.status === 200) {
                clusterData = await clusterDataResult.json()
                let sortedEvents = clusterData.events.sort((a, b) => { return a.timestamp - b.timestamp })
                clusterData.events = sortedEvents
                let lastUpdatedTime_date = new Date()
                lastUpdatedTimeString_Cluster = lastUpdatedTime_date.toLocaleDateString() + ' ' + lastUpdatedTime_date.toLocaleTimeString()
            }
            else {
                alert('There was an error retrieving cloud job data')
            }

            this.setState({ clusterData, showBackground: false, lastUpdatedTimeString_Cluster })
        }

        const repeatNotebookLogs = async (runid) => {
            await getNotebookLogs(runid)
            const notebooksRepeat = setInterval(() => { getNotebookLogs(runid) }, 600000) //Update the API data Every 10 minutes
            this.setState({ notebooksRepeat, openNotebookLogsModal: true })
        }

        const getNotebookLogs = async (runid) => {
            this.setState({ showBackground: true })
            let url = Constant.baseapiurl_gen + 'cloud/NotebookLogs?run_id=' + runid
            let notebookLogsDataResult = await fetch(url)
            let notebookLogsData = []
            let lastUpdatedTimeString_Notebooks
            if (notebookLogsDataResult.status === 200) {
                notebookLogsData = await notebookLogsDataResult.json()
                let lastUpdatedTime_date = new Date()
                lastUpdatedTimeString_Notebooks = lastUpdatedTime_date.toLocaleDateString() + ' ' + lastUpdatedTime_date.toLocaleTimeString()
            }
            else {
                alert('There was an error retrieving cloud job data')
            }

            this.setState({ notebookLogsData, showBackground: false, lastUpdatedTimeString_Notebooks })
        }

        const handleClick_edit = (etlid) => {
            window.open(Constant.baseurl + 'PDTETL/EditExtract/' + etlid, '_blank')
        }

        const cancelRun = async () => {
            this.setState({ showBackground: true })
            let url = Constant.baseapiurl_gen + 'cloud/CancelJob'
            let postBody = { 'run_id': this.state.active_runid }
            let cancelDataResult = await fetch(url,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postBody)
            })
            let cancelData = []            
            if (cancelDataResult.status === 200) {
                cancelData = await cancelDataResult.json()                
                alert('Job was successfully cancelled')
                this.setState({ active_runid: null })
                await this.getCloudJobs()
                closeCancelDialogFunc()
            }
            else {
                alert('There was an error retrieving cloud job data')
            }
        }

        const handleChangeDays = event => {
            let value = event.target.value            
            let numdays = parseInt(value)

            if (isNaN(value)) {
                alert('Value must be a number!')
                numdays = this.state.maxDays
            }

            else if (value === "") {
                numdays = 1
            }

            if (numdays === 0) {
                alert('Value must be greater than 0!')
                numdays = 1
            }

            else if (numdays > 31) {
                alert('Value must be less than or equal to 31!')
                numdays = 31
            }

            this.setState({ maxDays: numdays })

        }

        const handleChangeJobs = event => {
            let value = event.target.value
            let numjobs = parseInt(value)

            if (isNaN(value)) {
                alert('Value must be a number!')
                numjobs = this.state.maxJobs
            }

            else if (value === "") {
                numjobs = 1
            }

            if (numjobs === 0) {
                alert('Value must be greater than 0!')
                numjobs = 1
            }
                        
            this.setState({ maxJobs: numjobs })
            
        }

        const closeNotebookLogsModalFunc = () => {
            this.setState({ openNotebookLogsModal: false });            
            clearInterval(this.state.notebooksRepeat)            
        }

        const closeClusterModalFunc = () => {
            this.setState({ openClusterModal: false });
            clearInterval(this.state.clusterRepeat)
        }

        const openCancelDialogFunc = (runid) => {
            this.setState({ openCancelDialog: true, active_runid: runid });
        }

        const closeCancelDialogFunc = (runid) => {
            this.setState({ openCancelDialog: false, active_runid: null });
        }
       
        function formatTimeFromString(timestring) {
            let timestring_formatted = (new Date(timestring)).toLocaleDateString() + ' ' + (new Date(timestring)).toLocaleTimeString()
            return timestring_formatted
        }

        function getTimeFromDate(timestring, current_time) {
            //Calculate Current Time in Hours From Execution Time
            let start_time = new Date(timestring)
            let diff_of_times = current_time - start_time
            let time = diff_of_times / (1000 * 60 * 60)            
            var timeString = formatTimeFromNum(time)
            return timeString                       
        }

        function formatExecutionString(run_time) {
            try {
                let execution_time = run_time.split('Cell execution time: ')[1].split(' from ')[0]
                let start_time = run_time.split('Cell execution time: ')[1].split(' from ')[1].split(' to ')[0]
                let end_time = run_time.split('Cell execution time: ')[1].split(' from ')[1].split(' to ')[1]
                let start_time_formatted = formatTimeFromString(start_time)
                let end_time_formatted = formatTimeFromString(end_time)

                return 'Cell execution time: ' + execution_time + ' from ' + start_time_formatted + ' to ' + end_time_formatted
            }
            catch (error) {
                try {
                    let execution_time = run_time.split('Running since ')[1]
                    let execution_time_formatted = formatTimeFromString(execution_time)
                    return 'Running since  ' + execution_time_formatted
                }
                catch (error) {
                    return run_time
                }                
            }
        }

        function getColorFromStatus(status) {
            
            let styleColor_Lookup = {
                'TERMINATED: FAILED': '#822433',
                'INTERNAL_ERROR: FAILED': '#822433',
                'TERMINATED: SUCCESS': '#ff8b6a',
                'TERMINATED: CANCELED': '#7d32ba',
                'TERMINATED: CANCELED: Run cancelled.': '#7d32ba'
            }

            let styleColor = styleColor_Lookup[status] || '#565a5c'

            return styleColor
        }

        function getDetailMessages(details) {
            let detailKeys = Object.keys(details)
            let detailMessages = []
            detailKeys.forEach(key => {
                var keyName, keyValue
                if (key === 'previous_disk_size') {
                    keyName = 'Previous Disk Size'
                    keyValue = Math.round(details['previous_disk_size'] / 1073741824) + ' GB'
                }

                else if (key === 'disk_size') {
                    keyName = 'New Disk Size'
                    keyValue = Math.round(details['disk_size'] / 1073741824) + ' GB'
                }

                else if (key === 'free_space') {
                    keyName = 'Free Space'
                    keyValue = Math.round(details['free_space'] / 1073741824) + ' GB'
                }

                else if (key === 'instance_id') {
                    keyName = 'Instance ID'
                    keyValue = details['instance_id']
                }

                else if (key === 'cause') {
                    keyName = 'Cause'
                    keyValue = details['cause']
                }

                else if (key === 'target_num_workers') {
                    keyName = 'Target Number of Workers'
                    keyValue = details['target_num_workers']
                }

                else if (key === 'current_num_workers') {
                    keyName = 'Current Number of Workers'
                    keyValue = details['current_num_workers']
                }

                else if (key === 'cluster_size') {
                    keyName = 'Cluster Size'
                    let cluster_size = details['cluster_size']
                    if (Object.keys(cluster_size).includes('autoscale')) {
                        let minworkers = cluster_size['autoscale']['min_workers']
                        let maxworkers = cluster_size['autoscale']['max_workers']
                        keyValue = 'Desired cluster size is autoscaling with ' + minworkers + ' - ' + maxworkers + ' nodes'
                    }
                    else {
                        let numworkers = cluster_size["num_workers "]
                        keyValue = 'Desired cluster size is autoscaling with ' + numworkers + ' nodes'
                    }
                }

                else if (key === 'previous_cluster_size') {
                    keyName = 'Previous Cluster Size'
                    let previous_cluster_size = details['previous_cluster_size']
                    if (Object.keys(previous_cluster_size).includes('autoscale')) {
                        let minworkers = previous_cluster_size['autoscale']['min_workers']
                        let maxworkers = previous_cluster_size['autoscale']['max_workers']
                        keyValue = 'Desired cluster size is autoscaling with ' + minworkers + ' - ' + maxworkers + ' nodes'
                    }
                    else {
                        let numworkers = previous_cluster_size["num_workers "]
                        keyValue = 'Desired cluster size is autoscaling with ' + numworkers + ' nodes'
                    }
                }

                else if (key === 'reason') {
                    keyName = 'Reason'

                    //keyValue = details['cause']
                    //need to iterate through the reason keys
                    let reasons = Object.keys(details['reason'])
                    if (reasons.includes('type')) {
                        keyValue = details['reason']['type'] + ': ' + details['reason']['code']
                    }
                    else {
                        keyValue = details['reason']['code']
                    }
                    if (reasons.includes('parameters')){
                        Object.keys(details['reason']['parameters']).forEach(param=>{
                            keyValue += param + ': ' + details['reason']['parameters'][param]
                        })
                    }
                }

                else if (key === 'job_run_name') {
                    keyName = 'Job Run Name'
                    keyValue = details['job_run_name']
                }

                else if (key === 'user') {
                    keyName = 'User'
                    keyValue = details['user']
                }

                else {
                    keyName = key                   
                }

                detailMessages.push({ keyName, keyValue })
            })

            return detailMessages
        }

        function formatEventType(type, job_run_name = '') {
            var type_formatted
            if (type == 'CREATING') {
                // Indicates that the cluster is being created.
                type_formatted = 'Creating cluster for job ' + job_run_name
            }
            else if (type == 'STARTING') {
                // Indicates that the cluster is being started.
                type_formatted = 'Cluster is being started'
            }
            else if (type == 'RESTARTING') {
                // Indicates that the cluster is being started.
                type_formatted = 'Cluster is being restarted'
            }
            else if (type == 'TERMINATING') {
                // Indicates that the cluster is being terminated.
                type_formatted = 'Cluster is being terminated'
            }
            else if (type == 'RUNNING') {
                // Indicates the cluster has finished being created. Includes the number of nodes in the cluster and a
                // failure reason if ( some nodes could not be acquired.
                type_formatted = 'Cluster is now running'
            }
            else if (type == 'RESIZING') {
                // Indicates a change in the target size of the cluster (upsize or downsize).
                type_formatted = 'Cluster resizing is complete'
            }
            else if (type == 'UPSIZE_COMPLETED') {
                // Indicates that nodes finished being added to the cluster. Includes the number of nodes in the cluster
                // and a failure reason if ( some nodes could not be acquired.
                type_formatted = 'Cluster upsizing is complete'
            }
            else if (type == 'INIT_SCRIPTS_STARTING') {
                type_formatted = 'Cluster init scripts are starting'
            }
            else if (type == 'INIT_SCRIPTS_FINISHED') {
                // Indicates that the cluster scoped init script has finished.
                type_formatted = 'Cluster init scripts are finished'
            }
            else if (type == 'DID_NOT_EXPAND_DISK') {
                // Indicates that a disk is low on space, but adding disks would put it over the max capacity.
                type_formatted = 'Failed to expand disk'
            }
            else if (type == 'EXPANDED_DISK') {
                // Indicates that a disk was low on space and the disks were expanded.
                type_formatted = 'Completed disk expansion'
            }
            else if (type == 'FAILED_TO_EXPAND_DISK') {
                // Indicates that a disk was low on space and disk space could not be expanded.
                type_formatted = 'Failed to expand disk'
            }
            else if (type == 'EDITED') {
                // Indicates that the cluster has been edited.
                type_formatted = 'Cluster edited'
            }
            else if (type == 'NODES_LOST') {
                // Indicates that some nodes were lost from the cluster.
                type_formatted = 'Cluster nodes lost'
            }
            else if (type == 'DRIVER_HEALTHY') {
                // Indicates that the driver is healthy and the cluster is ready for use.
                type_formatted = 'Cluster driver healthy'
            }
            else if (type == 'DRIVER_UNAVAILABLE') {
                // Indicates that the driver is unavailable.
                type_formatted = 'Cluster driver unavailable'
            }
            else if (type == 'SPARK_EXCEPTION') {
                // Indicates that a Spark exception was thrown from the driver.
                type_formatted = 'Spark exception thrown from driver node'
            }
            else if (type == 'DRIVER_NOT_RESPONDING') {
                // Indicates that the driver is up but is not responsive, likely due to GC.
                type_formatted = 'Driver node is up but not responding'
            }
            else if (type == 'DBFS_DOWN') {
                // Indicates that the driver is up but DBFS is down.
                type_formatted = 'Driver node is up but DBFS is down'
            }
            else if (type == 'METASTORE_DOWN') {
                // Indicates that the driver is up but the metastore is down.
                type_formatted = 'Driver node is up but metastore is down'
            }
            else if (type == 'NODE_BLACKLISTED') {
                // Indicates that a node is not allowed by Spark.
                type_formatted = 'Node blacklisted by Spark'
            }
            else if (type == 'PINNED') {
                // Indicates that the cluster was pinned.
                type_formatted = 'Cluster pinned by Spark'
            }
            else if (type == 'UNPINNED') {
                type_formatted = 'Cluster unpinned by Spark'
            }
            else {
                type_formatted = 'Unexpected event type ' + type
            }

            return type_formatted
        }

        const handleChangePage = (event, newPage) => {
            this.setState({ page: newPage })            
        };

        const handleChangeRowsPerPage = (event) => {
            this.setState({ rowsPerPage: parseInt(event.target.value, 10) })
            this.setState({ page: 0 })
        };

        const handleDates_TimeRange = (values) => {            
            let selectedstartdaterange = null
            let selectedenddaterange = null
            if (values) {
                let start_year = values[0].getFullYear()
                let start_day = values[0].getDate()
                let start_month = values[0].getMonth() + 1

                let end_year = values[1].getFullYear()
                let end_day = values[1].getDate()
                let end_month = values[1].getMonth() + 1

                if (start_day < 10) {        
                    start_day = String('0'+start_day)
                }

                if (start_month < 10) {
                    start_month = String('0' + start_month)
                }

                if (end_day < 10) {
                    end_day = String('0' + end_day)
                }

                if (end_month < 10) {
                    end_month = String('0' + end_month)
                }

                selectedstartdaterange = String(start_year) + '-' + String(start_month) + '-' + String(start_day) //(values[0].toISOString()).substring(0, 10)
                selectedenddaterange = String(end_year) + '-' + String(end_month) + '-' + String(end_day) //(values[1].toISOString()).substring(0, 10)
            }
            else {
                selectedstartdaterange = null
                selectedenddaterange = null
            }

            this.setState({
                selectedstartdaterange: selectedstartdaterange,
                selectedenddaterange: selectedenddaterange,
                DatevaluesTimeRange: values
            }, () => {
                filterJobs(values, 'jobstarted')
            }
            )

        }

        const sortData = (event, sortby, refreshData = false) => {
            //var current_header_active = this.state.header_sort_active
            var current_header_info = this.state.header_sort_info
            
            Object.keys(current_header_info).map(key => {
                var new_direction
                if (key === sortby) {
                    current_header_info[key]['active'] = true
                }
                else {
                    current_header_info[key]['active'] = false
                }
                let curr_direction = current_header_info[key]['direction']
                if (refreshData === false) {
                    if (curr_direction === 'asc') {
                        new_direction = 'desc'
                    }
                    else {
                        new_direction = 'asc'
                    }
                }
                else {
                    new_direction = curr_direction
                }

                current_header_info[key]['direction'] = new_direction
            })


            var data_to_sort = this.state.jobData
            let direction = current_header_info[sortby]['direction']
            var final_sort
            if (sortby === 'jobid') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => Number(a.job_id) < Number(b.job_id) ? -1 : (Number(a.job_id) > Number(b.job_id) ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => Number(b.job_id) < Number(a.job_id) ? -1 : (Number(b.job_id) > Number(a.job_id) ? 1 : 0))
                }
            }

            else if (sortby === 'runid') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => Number(a.run_id) < Number(b.run_id) ? -1 : (Number(a.run_id) > Number(b.run_id) ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => Number(b.run_id) < Number(a.run_id) ? -1 : (Number(b.run_id) > Number(a.run_id) ? 1 : 0))
                }
            }

            else if (sortby === 'etlid') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => Number(a.etl_id) < Number(b.etl_id) ? -1 : (Number(a.etl_id) > Number(b.etl_id) ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => Number(b.etl_id) < Number(a.etl_id) ? -1 : (Number(b.etl_id) > Number(a.etl_id) ? 1 : 0))
                }
            }

            else if (sortby === 'etlshortdesc') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.etl_shortname || '').toLowerCase() < (b.etl_shortname || '').toLowerCase() ? -1 : ((a.etl_shortname || '').toLowerCase() > (b.etl_shortname || '').toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => (b.etl_shortname || '').toLowerCase() < (a.etl_shortname || '').toLowerCase() ? -1 : ((b.etl_shortname || '').toLowerCase() > (a.etl_shortname || '').toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'industry') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.industry || '').toLowerCase() < (b.industry || '').toLowerCase() ? -1 : ((a.industry || '').toLowerCase() > (b.industry || '').toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => (b.industry || '').toLowerCase() < (a.industry || '').toLowerCase() ? -1 : ((b.industry || '').toLowerCase() > (a.industry || '').toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'apiversion') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.version || '').toLowerCase() < (b.version || '').toLowerCase() ? -1 : ((a.version || '').toLowerCase() > (b.version || '').toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => (b.version || '').toLowerCase() < (a.version || '').toLowerCase() ? -1 : ((b.version || '').toLowerCase() > (a.version || '').toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'jobstatus') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.status || '').toLowerCase() < (b.status || '').toLowerCase() ? -1 : ((a.status || '').toLowerCase() > (b.status || '').toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => (b.status || '').toLowerCase() < (a.status || '').toLowerCase() ? -1 : ((b.status || '').toLowerCase() > (a.status || '').toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'runtime') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => Number(a.run_time) < Number(b.run_time) ? -1 : (Number(a.run_time) > Number(b.run_time) ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => Number(b.run_time) < Number(a.run_time) ? -1 : (Number(b.run_time) > Number(a.run_time) ? 1 : 0))
                }
            }

            else if (sortby === 'jobstarted') {
                if (direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.start_time) - Date.parse(a.start_time) })
                }
                else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.start_time) - Date.parse(b.start_time) })
                }
            }

            this.setState({
                jobData: final_sort, header_sort_info: current_header_info
            })
            
        }

        const filterJobs = (selectedOptions, filterby) => {

            //setfilters
            let filters = this.state.filters
            let datasets = this.state.jobData
            
            if (filterby === 'jobid') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['jobid'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['jobid'] = ['All']
                }
            }

            if (filterby === 'runid') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['runid'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['runid'] = ['All']
                }
            }

            if (filterby === 'etlid') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['etlid'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['etlid'] = ['All']
                }
            }

            if (filterby === 'etlshortdesc') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['etlshortdesc'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['etlshortdesc'] = ['All']
                }
            }

            if (filterby === 'industry') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['industry'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['industry'] = ['All']
                }
            }

            if (filterby === 'apiversion') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['apiversion'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['apiversion'] = ['All']
                }
            }

            if (filterby === 'jobstatus') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['jobstatus'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['jobstatus'] = ['All']
                }
            }

            if (filterby === 'runtime') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['runtime'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['runtime'] = ['All']
                }
            }

            if (filterby === 'jobstarted') {
                filters['daterange_start'] = this.state.selectedstartdaterange
                filters['daterange_end'] = this.state.selectedenddaterange
            }

            //Loop through reports, and ensure that all filters are met...
            datasets.forEach(dataset => {
                let showdataset = false
                let jobidmatch = false
                let runidmatch = false
                let etlidmatch = false
                let etlshortdescmatch = false
                let industrymatch = false
                let apiversionmatch = false
                let jobstatusmatch = false
                let runtimematch = false
                let daterangematch = false

                if (filters['jobid'].includes(dataset.job_id) || (filters['jobid'].length === 1 && filters['jobid'][0] === 'All')) {
                    jobidmatch = true
                }

                if (filters['runid'].includes(dataset.run_id) || (filters['runid'].length === 1 && filters['runid'][0] === 'All')) {
                    runidmatch = true
                }

                if (filters['etlid'].includes(dataset.etl_id) || (filters['etlid'].length === 1 && filters['etlid'][0] === 'All')) {
                    etlidmatch = true
                }

                if (filters['etlshortdesc'].includes(dataset.etl_shortname) || (filters['etlshortdesc'].length === 1 && filters['etlshortdesc'][0] === 'All')) {
                    etlshortdescmatch = true
                }

                if (filters['industry'].includes(dataset.industry) || (filters['industry'].length === 1 && filters['industry'][0] === 'All')) {
                    industrymatch = true
                }

                if (filters['apiversion'].includes(dataset.version) || (filters['apiversion'].length === 1 && filters['apiversion'][0] === 'All')) {
                    apiversionmatch = true
                }

                if (filters['jobstatus'].includes(dataset.status) || (filters['jobstatus'].length === 1 && filters['jobstatus'][0] === 'All')) {
                    jobstatusmatch = true
                }

                if (filters['runtime'].includes(dataset.run_time) || (filters['runtime'].length === 1 && filters['runtime'][0] === 'All')) {
                    runtimematch = true
                }               
                
                let start_time_tocheck = (dataset['start_time'] || "").substring(0, 10)
                

                

                if (filters['daterange_start'] && filters['daterange_end']) {
                    if (start_time_tocheck <= filters['daterange_end'] && start_time_tocheck >= filters['daterange_start']) {
                        daterangematch = true
                    }
                }

                if (filters['daterange_start'] && !filters['daterange_end']) {
                    if (start_time_tocheck >= filters['daterange_start']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && filters['daterange_end']) {
                    if (start_time_tocheck <= filters['daterange_end']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && !filters['daterange_end']) {
                    daterangematch = true
                }
                
                if (jobidmatch && runidmatch && etlidmatch && etlshortdescmatch && industrymatch && apiversionmatch && jobstatusmatch && runtimematch  && daterangematch) {
                    showdataset = true
                }

                dataset['showdataset'] = showdataset
            })

            this.setState({ filters, datasetdata: datasets })

        }

        if (this.state.refreshData === true) {
            let sortKey = Object.keys(this.state.header_sort_info).map(key => {
                if (this.state.header_sort_info[key]['active'] === true) {
                    return key
                }
                else return null
            }).filter(Boolean)[0]
            sortData(null, sortKey, true)
            filterJobs(null, null)
            this.setState({ refreshData: false })
        }
        var jobData_filtered = this.state.jobData.map(job => { if (job.showdataset) { return job } else { return null} }).filter(Boolean)

        return (
            <Layout title="Cloud Job Status" >
                <Modal
                    open={this.state.openNotebookLogsModal}
                    onClose={closeNotebookLogsModalFunc}
                >
                    <div
                        style={{
                            paddingLeft: '20px',
                            paddingRight: '10px',
                            borderRadius: '3',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            minWidth: '1000px',
                            maxHeight: '75vh',
                            backgroundColor: '#FFF',
                            overflowY: 'auto'
                        }} >
                        <div
                            className='col-sm-12'
                            align='right'
                        >
                            <IconButton
                                aria-label="close"
                                onClick={closeNotebookLogsModalFunc}
                            >
                                <CancelRoundedIcon
                                    style={{
                                        color: '#4e106f',
                                        fontSize: '3vh'
                                    }}
                                />
                            </IconButton>
                        </div>
                        <h6
                            id="simple-modal-title"
                            style={{ margin: '30px' }}
                        >
                            Notebook Logs
                        </h6>
                        <p
                            style={{
                                marginLeft: '30px',
                                marginRight: '30px',
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        >
                            Data Last Retrieved from API at: {this.state.lastUpdatedTimeString_Notebooks}
                        </p>
                        <span
                            style={{
                                marginLeft: '30px',
                                marginRight: '30px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                fontSize: '1.25vh',
                                color: '#4e106f'
                            }}
                        >
                            **Data will auto-refresh every 10 minutes**
                        </span>
                        {this.state.notebookLogsData.map(cell => {
                            return <div
                                style={{ padding: '15px', margin: '10px' }}
                            >
                                <h5>
                                    {'Cell #' + cell.cell}
                                </h5>
                                <p>
                                    {formatExecutionString(cell.run_time)}
                                </p>
                                <div
                                    className='row'
                                    style={{ padding: '15px', margin: '10px' }}
                                >
                                    <div
                                        className='col-sm-12'
                                        style={{ padding: '0px' }}
                                    >
                                        Command
                                    </div>
                                    <div
                                        className='col-sm-12 row'
                                        style={{
                                            padding: '10px',
                                            maxHeight: '300px',
                                            backgroundColor: '#FFF',
                                            overflowY: 'auto',
                                            borderStyle: 'solid',
                                            borderRadius: '10px',
                                            boxShadow: "none"
                                        }}
                                    >
                                        <pre
                                            className="language-python"
                                        >
                                            {cell.command.split('\n').map(command_line => {                                                
                                                return <Fragment>
                                                    <code>
                                                        {command_line}
                                                    </code>
                                                    <br />
                                                </Fragment>
                                            })}
                                        </pre>
                                    </div>
                                    
                                </div>
                                <div
                                    className='row'
                                    style={{ padding: '15px', margin: '10px' }}
                                >
                                    <span>
                                        Output
                                    </span>
                                    <div
                                        className='col-sm-12 row'
                                        style={{
                                            padding: '10px',
                                            maxHeight: '300px',
                                            backgroundColor: '#FFF',
                                            overflowY: 'auto',
                                            borderStyle: 'solid',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        {cell.output.split('\n').map(output_line => {
                                            return <Fragment>
                                                <code>
                                                    {output_line}
                                                </code>
                                                <br />
                                            </Fragment>
                                        })}
                                    </div>                                   
                                </div>
                            </div>
                        })}
                    </div>
                </Modal>

                {/*Cluster Events Modal*/}

                <Modal
                    open={this.state.openClusterModal}
                    onClose={closeClusterModalFunc}
                >
                    <div
                        style={{
                            paddingLeft: '20px',
                            paddingRight: '10px',
                            borderRadius: '3',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            minWidth: '1000px',
                            maxHeight: '75vh',
                            backgroundColor: '#FFF',
                            overflowY: 'auto'
                        }} >
                        <div
                            className='col-sm-12'
                            align='right'
                        >
                            <IconButton
                                aria-label="close"
                                onClick={closeClusterModalFunc}
                            >
                                <CancelRoundedIcon
                                    style={{
                                        color: '#4e106f',
                                        fontSize: '3vh'
                                    }}
                                />
                            </IconButton>
                        </div>
                        <h6
                            id="simple-modal-title"
                            
                        >
                            Cluster Events
                        </h6>
                        <p
                            style={{
                                marginLeft: '30px',
                                marginRight: '30px',
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        >
                            Data Last Retrieved from API at: {this.state.lastUpdatedTimeString_Cluster}
                        </p>
                        <span
                            style={{
                                marginLeft: '30px',
                                marginRight: '30px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                fontSize: '1.25vh',
                                color: '#4e106f'
                            }}
                        >
                            **Data will auto-refresh every 10 minutes**
                        </span>
                        {(this.state.clusterData.events || []).map((clusterEvent, idx) => {
                            let details = clusterEvent.details
                            let detailMessages = getDetailMessages(details)
                            return <Accordion
                                style={{ backgroundColor: '#F4F4F4', padding: '10px', marginTop: '20px', marginBottom: '20px' }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <h5>Cluster Event # {idx + 1}: {formatEventType(clusterEvent.type, (clusterEvent.details.job_run_name || ''))}</h5>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='row col-sm-12'>
                                        <div className='row col-sm-12'>
                                            <p>Cluster id: {clusterEvent.cluster_id}</p>
                                        </div>
                                        <div className='row col-sm-12'>
                                            <p>Time: {formatTimeFromString(clusterEvent.datetime)}</p>
                                        </div>
                                        {/*walkthrough details keys*/}
                                        {detailMessages.map(key => {
                                            return <div className='row col-sm-12'>
                                                <p>{key.keyName + ': ' + key.keyValue}</p>
                                            </div>
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        })}
                        
                    </div>
                </Modal>


                <Dialog
                    open={this.state.openCancelDialog}
                    onClose={closeCancelDialogFunc}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Cancel this Run?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to cancel this run. This action is final and can not be undone.  Continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={closeCancelDialogFunc} className="btn btn-secondarynpd">
                            Never Mind
                        </button>
                        <button onClick={cancelRun} className="btn btn-primary" autoFocus>
                            Cancel Run
                        </button>
                    </DialogActions>
                </Dialog>

                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    <div class="custom-loader"></div>
                {/*    <CircularProgress*/}
                {/*        color="inherit"*/}
                {/*    />*/}
                </Constant.NPDBackdrop>

                <div
                    name='banner'
                    className='banner'
                >
                    <h1white
                        align='center'
                    >
                        Cloud Status Dashboard
                    </h1white>
                </div>

                <div
                    className="mainbodydiv"
                    style={{ padding: '50px' }}
                >
                    <div
                        className='row'
                    >
                        
                        <button
                            onClick={this.getCloudJobs}
                            style={{ margin: '20px' }}
                            className="btn btn-primary"
                        >
                            Retrieve Cloud Jobs
                        </button>
                        <TextField
                            variant="filled"
                            value={this.state.maxDays}
                            onChange={handleChangeDays}
                            label="Max Number of Days"
                            style={{ margin:'20px'}}
                        />
                        <TextField
                            variant="filled"
                            value={this.state.maxJobs}
                            onChange={handleChangeJobs}
                            label="Max Number of Jobs"
                            style={{ margin: '20px' }}
                        />
                        <p
                            style={{ margin: '30px' }}
                        >
                            Data Last Retrieved from API at: {this.state.lastUpdatedTimeString}
                        </p>
                        {
                            this.state.lastUpdatedTimeString ?
                                <span
                                    style={{
                                        marginLeft: '30px',
                                        marginRight: '30px',
                                        marginTop: '30px',
                                        marginBottom: '30px',
                                        fontSize: '1.25vh',
                                        color: '#4e106f'
                                    }}
                                >
                                    **Data will auto-refresh every 5 minutes**
                                </span>
                                :
                                <span/>
                        }
                        
                    </div>
                    <div
                        className='row col-sm-12'
                    >
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>Job ID</span>
                            <Select
                                options={this.state.jobID_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'jobid')}
                                styles={selectColourStyles}
                            />                            
                        </div>
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>Run ID</span>
                            <Select
                                options={this.state.runID_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'runid')}                                
                                styles={selectColourStyles}

                            />
                        </div>
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>ETL ID</span>
                            <Select
                                options={this.state.etlID_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'etlid')}
                                styles={selectColourStyles}

                            />
                        </div>
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>ETL Short Desc</span>
                            <Select
                                options={this.state.etlName_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'etlshortdesc')}
                                styles={selectColourStyles}

                            />
                            <br/>
                        </div>
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>Industry</span>
                            <Select
                                options={this.state.industry_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'industry')}
                                styles={selectColourStyles}

                            />
                        </div>
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>API Version</span>
                            <Select
                                options={this.state.apiversion_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'apiversion')}
                                styles={selectColourStyles}

                            />
                        </div>
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>Job Status</span>
                            <Select
                                options={this.state.status_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'jobstatus')}
                                styles={selectColourStyles}

                            />
                        </div>
                        <div
                            className='col-sm-3'
                        >
                            <span className='formfieldtitle3'>Run Time</span>
                            <Select
                                options={this.state.runtime_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'runtime')}
                                styles={selectColourStyles}

                            />
                            <br/>
                        </div>
                        <div className="col-sm-3">
                            <span className='formfieldtitle3'>Job Started</span>
                            <DateRangePicker
                                onChange={handleDates_TimeRange}
                                value={this.state.DatevaluesTimeRange}
                                calendarIcon={<DateRangeRoundedIcon style={{ color: '#4e106f' }} />}
                                clearIcon={<ClearRoundedIcon style={{ color: '#822433' }} />}
                                className={"testForDatePicker"}
                                style={{
                                    backgroundColor: '#FFFFFF'
                                }}
                            />
                        </div>
                    </div>

                    <div
                        className='row' style={{marginTop: '20px'}}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['jobid']['active']}
                                            direction={this.state.header_sort_info['jobid']['direction']}
                                            onClick={(event) => sortData(event, 'jobid')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>Job ID</span>
                                        </TableSortLabel>                                        
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['runid']['active']}
                                            direction={this.state.header_sort_info['runid']['direction']}
                                            onClick={(event) => sortData(event, 'runid')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>Run ID</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['etlid']['active']}
                                            direction={this.state.header_sort_info['etlid']['direction']}
                                            onClick={(event) => sortData(event, 'etlid')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>ETL ID</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['etlshortdesc']['active']}
                                            direction={this.state.header_sort_info['etlshortdesc']['direction']}
                                            onClick={(event) => sortData(event, 'etlshortdesc')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>ETL Short Name</span>
                                        </TableSortLabel>                                        
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['industry']['active']}
                                            direction={this.state.header_sort_info['industry']['direction']}
                                            onClick={(event) => sortData(event, 'industry')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>Industry</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['apiversion']['active']}
                                            direction={this.state.header_sort_info['apiversion']['direction']}
                                            onClick={(event) => sortData(event, 'apiversion')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>API Version</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['jobstatus']['active']}
                                            direction={this.state.header_sort_info['jobstatus']['direction']}
                                            onClick={(event) => sortData(event, 'jobstatus')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>Job Run Status</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['runtime']['active']}
                                            direction={this.state.header_sort_info['runtime']['direction']}
                                            onClick={(event) => sortData(event, 'runtime')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>Run Time</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.header_sort_info['jobstarted']['active']}
                                            direction={this.state.header_sort_info['jobstarted']['direction']}
                                            onClick={(event) => sortData(event, 'jobstarted')}
                                            hideSortIcon={false}
                                        >
                                            <span className='greenHeader'>Job Started</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        
                                    </TableCell>
                                    <TableCell>
                                        
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {jobData_filtered.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(job => {
                                    let styleColor = getColorFromStatus(job.status)
                                    let statusArray = job.status.split(':')
                                    let currStatus = statusArray[0]
                                    if (job.showdataset === true) {
                                        return <TableRow>
                                            <TableCell>
                                                {job.job_id}
                                            </TableCell>
                                            <TableCell>
                                                {job.run_id}
                                            </TableCell>
                                            <TableCell>
                                                {job.etl_id}
                                            </TableCell>
                                            <TableCell>
                                                {job.etl_shortname}
                                            </TableCell>
                                            <TableCell>
                                                {job.industry}
                                            </TableCell>
                                            <TableCell>
                                                {job.version}
                                            </TableCell>
                                            <TableCell
                                                style={{ color: styleColor }}
                                            >
                                                {job.status}
                                            </TableCell>
                                            <TableCell>
                                                {currStatus === 'TERMINATED' ?
                                                    formatTimeFromNum(job.run_time)
                                                    :
                                                    getTimeFromDate(job.start_time, this.state.time)
                                                }

                                            </TableCell>
                                            <TableCell>
                                                {formatTimeFromString(job.start_time)}
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip
                                                    title={'Get Cluster Events'}
                                                    placement="top-start"
                                                >
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={(runid) => repeatClusterEvents(job.run_id)}
                                                    >
                                                        <SettingsApplicationsRoundedIcon
                                                            style={{ color: '#4e106f' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip
                                                    title={'Get Notebook Logs'}
                                                    placement="top-start"
                                                >
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={(runid) => repeatNotebookLogs(job.run_id)}
                                                    >
                                                        <ArticleRoundedIcon
                                                            style={{ color: '#ff8b6a' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell>
                                                <Tooltip
                                                    title={'Cancel This Job'}
                                                    placement="top-start"
                                                >
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={(runid) => openCancelDialogFunc(job.run_id)}
                                                    >
                                                        <CancelRoundedIcon
                                                            style={{ color: '#822433' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell >
                                                <Tooltip
                                                    title="Edit"
                                                    placement="top-start"
                                                >
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={(etlid) => handleClick_edit(job.etl_id)}
                                                    >
                                                        <EditIcon
                                                            style={{ color: '#565a5c' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    else {
                                        return null
                                    }
                                    
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={jobData_filtered.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Layout>
        )
    }
}