import * as Constant from '../../../constants'
export default async function getsimsettings(simid) {
    console.log(simid)
    let simSettingsResult = await fetch(Constant.baseapiurl + "SimSettings/" + simid)
    var simSettingsData = {}
    if (simSettingsResult.status === 200) {
        simSettingsData = await simSettingsResult.json()
        let dt = new Date()
        let offset = dt.getTimezoneOffset() * 60 * 1000
        //Add To Result    
        simSettingsData.time_updated_adj = (new Date(Date.parse(simSettingsData.time_updated) - offset)).toISOString().replace('T', ' ')
        simSettingsData.numweeks = Math.round((new Date(simSettingsData.sim_start.substring(0, 4), simSettingsData.sim_start.substring(5, 7), simSettingsData.sim_start.substring(8, 10)) - new Date(simSettingsData.sim_end.substring(0, 4), simSettingsData.sim_end.substring(5, 7), simSettingsData.sim_end.substring(8, 10))) / (7 * 24 * 60 * 60 * 1000)) * -1

    }

    else {
        this.setState({ showBackground: false, errorAlert: true })
    }

    console.log(simSettingsData)

    this.setState({
        simsettings: simSettingsData
        , selected_item: simSettingsData.items[0]['prod_id']
        , items: (simSettingsData.items || []).map(item => item.prod_id)
        , start: (simSettingsData.sim_start || "").substring(0, 10)
        , end: (simSettingsData.sim_end || "").substring(0, 10)
        , geo: simSettingsData.geogid
        , datasetid: simSettingsData.dataset_id
    })
}