import React, { Fragment } from 'react';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import * as Constant from '../constants';

const ScorecardPromoNonPromo = props => {

    var appname = props.appname
    var unitsvar = props.unitsvar
    var nonpromo_units_curr = props.nonpromo_units_curr
    var nonpromo_units_prior = props.nonpromo_units_prior
    var promo_units_curr = props.promo_units_curr
    var promo_units_prior = props.promo_units_prior
    var nonpromo_unit_pct_curr = props.nonpromo_unit_pct_curr
    var nonpromo_unit_pct_prior = props.nonpromo_unit_pct_prior
    var promo_unit_pct_curr = props.promo_unit_pct_curr
    var promo_unit_pct_prior = props.promo_unit_pct_prior

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>{'Promoted and Non-Promoted ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Unit sales that occur when there is no temporary price reduction</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Non-Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(nonpromo_units_curr, 10)).toLocaleString()}</p>
                                {(nonpromo_units_curr - nonpromo_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (+' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Unit sales in store/weeks that had a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(promo_units_curr, 10)).toLocaleString()}</p>
                                {(promo_units_curr - promo_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (+' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Non-Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Non-Promoted ' + unitsvar}</h5> <h5>Percent of Total</h5>
                                <p>{(nonpromo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted ' + unitsvar}</h5><h5>Percent of Total</h5>
                                <p>{(promo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(promo_unit_pct_curr - promo_unit_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
            :
            <Fragment>
                <h3>{'Promoted and Non-Promoted ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Unit sales that occur when there is no temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Non-Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(nonpromo_units_curr, 10)).toLocaleString()}</p>
                            {(nonpromo_units_curr - nonpromo_units_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (+' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Unit sales in store/weeks that had a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(promo_units_curr, 10)).toLocaleString()}</p>
                            {(promo_units_curr - promo_units_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (+' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Non-Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Non-Promoted ' + unitsvar}</h5> <h5>Percent of Total</h5>
                            <p>{(nonpromo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Promoted ' + unitsvar}</h5><h5>Percent of Total</h5>
                            <p>{(promo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(promo_unit_pct_curr - promo_unit_pct_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
    )
}

export default ScorecardPromoNonPromo;