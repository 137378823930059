import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import * as Constant from '../../../constants';

export class FlexibleBrandTableDownloadMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <IconButton aria-label="close" color="inherit" onClick={this.props.openFlexibleBrandTableMenu}>
                    <MenuIcon fontSize="large" />
                </IconButton>
                <Constant.NPDPopover
                    anchorEl={this.props.state.anchorELPos}
                    open={this.props.state.openFlexibleBrandTableMenu}
                    transition
                    onClose={this.props.closeFlexibleBrandTableMenu}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <div style={{ padding: '25px' }}>
                        <div>
                            {this.props.store_group_is_alr && this.props.has_past_data ?
                                <span style={{ color: '#4e106f', cursor: 'pointer', fontWeight: 'bold' }}
                                    onClick={this.props.downloadFlexibleBrandTableData}
                                >
                                    Download Brand Performance Report as XLSX
                                </span>
                            :
                                <span style={{ color: '#4e106f', fontStyle: 'italic' }}>
                                    Download is only enabled for reports where the store group is an ALR and when YAGO data is available
                                </span>
                            }
                        </div>
                    </div>
                </Constant.NPDPopover>
            </Fragment>
        )
    }
}
