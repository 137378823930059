import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import { Layout } from '../Layout';
import ReportToolTips from './IconBar_Report';
import IconButton from '@material-ui/core/IconButton';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import ShowChartRoundedIcon from '@material-ui/icons/ShowChartRounded';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import TableChartRoundedIcon from '@material-ui/icons/TableChartRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import MenuIcon from '@material-ui/icons/Menu';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_export_data from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';
import HighchartOptions from "../highcharts_options";

import Scorecard from '../Graphs/Scorecard'
import BaseIncremental from '../Graphs/BaseIncremental'
import PromotedNonPromoted from '../Graphs/PromotedNonPromoted'
import LiftEffDiscPct from '../Graphs/LiftEffDiscPct'
import BaseIncOrPromoNonPromoByWeek from '../Graphs/BaseIncOrPromoNonPromoByWeek'
import LiftEffDiscByWeek from '../Graphs/LiftEffDiscByWeek'
import DiscDollarsAndPBRByWeek from '../Graphs/DiscDollarsAndPBRByWeek'
import * as Constant from '../constants'
import { saveAs } from "file-saver";

const ExcelJS = require('exceljs');

HC_exporting(Highcharts)
HC_export_data(Highcharts)
more(Highcharts);
HighchartOptions(Highcharts);

window.Highcharts = Highcharts; //this line did the magic

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function adjustDate(date, target_dow) {
    let dow = date.getDay()
    let numdaystoadd = target_dow - dow
    return date.setDate(date.getDate() + numdaystoadd)
}

const dropdownStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        maxHeight: "75px",
        overflowY: "auto"
    }),
}

export class ReportOutput extends Component {
    static displayName = ReportOutput.name;

    constructor(props) {
        super(props);
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        this.handleDateEndRange = this.handleDateEndRange.bind(this)
        this.getReportSettings = this.getReportSettings.bind(this)
        this.getReportData = this.getReportData.bind(this)
        this.handleToggle_exclude_brands = this.handleToggle_exclude_brands.bind(this)
        this.handleToggle_exclude_brands_g1 = this.handleToggle_exclude_brands_g1.bind(this)
        this.handleToggle_exclude_brands_g2 = this.handleToggle_exclude_brands_g2.bind(this)
        this.handleToggle_exclude_items = this.handleToggle_exclude_items.bind(this)
        this.handleToggle_stackingType = this.handleToggle_stackingType.bind(this)
        this.moveviewup_attr = this.moveviewup_attr.bind(this)
        this.moveviewdown_attr = this.moveviewdown_attr.bind(this)
        this.state =
        {
            activevalue: 1,
            value: 0,
            value2: 0,
            value3: 0,
            value4: 0,
            reportsettings: [],
            reportdata: [],
            reportgeos: [],
            reportbrands: [],
            datatable: 0,
            filtered_report_data: [],
            distinctitems: [],
            distinctitemnums: [],
            distinctdates: [],
            distinctcolnames: [],
            distinctbrands: [],
            distinctgeos: [],
            item_dict: {},
            colname_vals: {},
            colname_data: {},
            voldecomp_by_week: {},
            base_data_by_week: {},
            hol_data_by_week: {},
            promo_data_by_week: {},
            nonpromo_data_by_week: {},
            inc_data_by_week: {},
            inc_data_by_week_calcs: {},
            base_data_by_week_calcs: {},
            hol_data_corrected_graph: {},
            inc_data_corrected_graph: {},
            base_data_corrected_graph: {},
            asp_data_by_week: {},
            base_data_by_week_corrections: {},
            item_volume_by_week: {},
            volume_by_item: {},
            dollars_by_item: {},
            volume_by_brand: {},
            dollars_by_brand: {},
            volume_by_geo: {},
            TotalUnitsOnPromo: 0,
            TotalUnitsOnPromo_all: 0,
            TotalUnits: 0,
            incUnits: 0,
            inctouse_corrected_tracking: 0,
            basetouse_corrected_tracking: 0,
            baseUnits: 0,
            holUnits: 0,
            weighted_dollars_nonpromo: 0,
            weighted_dollars_promo: 0,
            weighted_dollars_incremental: 0,
            total_vol_by_week: {},
            total_vol_by_week_current: {},
            disc_pct_by_week: {},
            disc_pct_by_week_raw: {},
            bp_by_week: {},
            disc_pct_by_brand: {},
            disc_pct_by_item: {},
            disc_pct_by_priceband: {},
            disc_dollars_by_week: {},
            weighted_dollars_inc_week: {},
            weighted_dollars_promo_week: {},
            weighted_dollars_nonpromo_week: {},
            totaldisc: 0,
            revenue_opportunity: 0,
            subsidized_vol: 0,
            subbasevar_prior: 0,
            disc_dollars_curr: 0,
            disc_dollars_prior: 0,
            //Prior Period Data   
            start_date_prior: null,
            end_date_prior: null,
            voldecomp_by_week_prior: {},
            base_data_by_week_prior: {},
            promo_data_by_week_prior: {},
            inc_data_by_week_prior: {},
            asp_data_by_week_prior: {},
            item_volume_by_week_prior: {},
            volume_by_item_prior: {},
            volume_by_geo_prior: {},
            TotalUnitsOnPromo_prior: 0,
            TotalUnits_prior: 0,
            TotalDisc_prior: 0,
            incUnits_prior: 0,
            baseUnits_prior: 0,
            weighted_dollars_nonpromo_prior: 0,
            weighted_dollars_promo_prior: 0,
            weighted_dollars_incremental_prior: 0,
            total_vol_by_week_prior: {},
            disc_pct_by_week_prior: {},
            filters: [],
            compfilters_attr: [],
            brands: [],
            geos: [],
            items: [],
            itemsforfilter: [],
            table_headers: [],
            table_data_decomp: [],
            showDataTable: false,
            colors: [],
            data_area_graph: {},
            data_area_graph_col: {},
            data_area_graph_brands: {},
            data_area_graph_items: {},
            data_waterfall: [],
            voldecomp_type: 'area',
            //Chart Title Variables
            title_num_weeks: 0,
            title_begin_date: '',
            title_brands: '',
            title_brands_detail: '',
            title_geo_selected: '',
            title_items: '',
            title_items_detail: '',
            //Snackbar
            showSnackbar_filter: false,
            showSnackbar_compran: false,
            //Modals
            ReportCriteriaModalOpen: false,
            AllItemsModalOpen: false,
            AllBrandsModalOpen: false,
            AllAttrsModalOpen: false,
            AllAttrsModalOpenG1: false,
            AllAttrsModalOpenG2: false,
            AllGeosModalOpen: false,
            //FilterBar and Criteria
            displayCriteria: 'block-inline',
            displayFilters: 'none',
            displayFilters_comp: 'none',
            displayInsights: 'none',
            source_of_vol_pie_data: [],
            reportInsightText: '',
            reportInsightText_emph: '',
            reportInsightText_end: '',
            openInfoMetrics: false,
            anchorElMetricsInfo: null,
            //Used for filtering the data
            reportstartdate: null,
            reportenddate: null,
            //Used for Calendar Input (selected date)
            selectedstart: null,
            selectedend: null,
            //Used for valid input dates in calendar (bounds)
            pastdate: Datetime.moment().set({ 'year': 2019, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            futuredate: Datetime.moment().set({ 'year': 2021, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            pastdate_formatted: '2019-01-01',
            futuredate_formatted: '2021-01-01',
            voldecomp_min: 0,
            voldecomp_max: 0,
            vol_decomp_data_date: {},
            errorAlert: false,
            errorAlert_priv: false,
            errorAlert_other: false,
            errorAlert_compare: false,
            errorAlertComp: false,
            title_end_date: "",
            title_start_date: "",
            promoselectedperf: false,
            baseselectedperf: true,
            baseselected: true,
            promoselected: false,
            brandselected: true,
            itemselected: false,
            pricebandselected: false,
            lifteffbybrand: true,
            lifteffbyitem: false,
            lifteffbypriceband: false,
            baseselectedattr: true,
            promoselectedattr: false,
            liftselectedattr: false,
            attributes: {},
            attribute_vals: {},
            attribute_vals_sorted: {},
            attribute_vals_filters: {},
            attribute_lookup: {},
            comparisonMetricOptions: [
                { 'label': 'Payback Ratio', 'value': 'Payback Ratio' },
                { 'label': 'Market Lift', 'value': 'Market Lift' },
                { 'label': 'Promoted Lift', 'value': 'Promoted Lift' },
                { 'label': 'Efficiency', 'value': 'Efficiency' },
                { 'label': 'Share of Incremental', 'value': 'Share of Incremental' },
                //{ 'label': 'Payback Ratio and Discount Depth Weekly', 'value': 'Payback Ratio and Discount Depth Weekly' },
                //{ 'label': 'Dollar Share By Week', 'value': 'Dollar Share By Week'}
            ],
            comparisonMetricOptions_trended: [
                { 'label': 'Payback Ratio and Discount Depth Weekly', 'value': 'Payback Ratio and Discount Depth Weekly' },
                { 'label': 'Average Discount Weekly', 'value': 'Average Discount Weekly' },
                { 'label': 'Market Lift Weekly', 'value': 'Market Lift Weekly' },
                { 'label': 'Promoted Lift Weekly', 'value': 'Promoted Lift Weekly' },
                { 'label': 'Efficiency Weekly', 'value': 'Efficiency Weekly' },
                { 'label': 'Share of Incremental Weekly', 'value': 'Share of Incremental Weekly' },
                { 'label': 'Average Selling Price Trended', 'value': 'Average Selling Price Trended' },
                { 'label': 'Base Price Trended', 'value': 'Base Price Trended' },
                { 'label': 'ASP Gap (Group 1 minus Group 2) Trended', 'value': 'ASP Gap (Group 1 minus Group 2) Trended' },
                { 'label': 'Base Price Gap (Group 1 minus Group 2) Trended', 'value': 'Base Price Gap (Group 1 minus Group 2) Trended' }
                //{ 'label': 'Dollar Share By Week', 'value': 'Dollar Share By Week'}
            ],
            overlayMetricOptions: [
                { 'label': 'Average Discount', 'value': 'Average Discount' },
                { 'label': 'Market Lift', 'value': 'Market Lift' },
                { 'label': 'Promoted Lift', 'value': 'Promoted Lift' },
                { 'label': 'Efficiency', 'value': 'Efficiency' },
                { 'label': 'Share of Incremental', 'value': 'Share of Incremental' },
                { 'label': 'Average Selling Price', 'value': 'Average Selling Price' },
                { 'label': 'Base Price', 'value': 'Base Price' },
                { 'label': 'ASP Gap (Group 1 minus Group 2)', 'value': 'ASP Gap (Group 1 minus Group 2)' },
                { 'label': 'Base Price Gap (Group 1 minus Group 2)', 'value': 'Base Price Gap (Group 1 minus Group 2)' },
            ],
            comparisonMetricSelected: "",
            comparisonMetricSelected_trended: "",
            overlayMetricSelected: "",
            comparisonLevelOptions: [{ 'label': 'Brand', 'value': 'Brand' }, { 'label': 'Product', 'value': 'Item' }, { 'label': 'Price Band', 'value': 'Price Band' }],
            comparisonLevelSelected: "",
            comparisonMemberOptions_g1: [],
            comparisonMemberOptions_g2: [],
            comparison_starting_options: [],
            comparisonGroup1: [],
            comparisonGroup2: [],
            MetricToCompare: "",
            compareGraphData: [],
            compareGraphData_trended: [],
            compareIndexGraphData: [],
            compareIndexGraphDataOverUnder: [],
            compareIndexGraphData_toview: [],
            //discount_buckets: ['<0', '0-10', '10-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80-90', '90-100', '100+'],
            discount_buckets: ['No Discount', '5-19%', '20-29%', '30-49%', '50%+'],
            discmaxes: [0.05, 0.2, 0.3, 0.5, 1],
            discount_bucket_mapping: {},
            discount_bucket_mapping_sbu: {},
            discount_bucket_mapping_bu: {},
            discount_bucket_mapping_iu: {},
            discount_bucket_mapping_id: {},
            discount_bucket_mapping_dd: {},
            discount_bucket_mapping_totaldollars: {},
            discount_bucket_mapping_totalunits: {},
            discount_bucket_mapping_prior: {},
            discount_bucket_mapping_brand: {},
            discount_pie_data: [],
            discount_bar_data: [],
            total_cat_data: [],
            asp_max: 0,
            asp_min: 0,
            sliderRange: [],
            sliderRangeMin: 0,
            sliderRangeMax: 100,
            sorted_brand_names: [],
            index_compare_type: 'Indexed',
            indexSwitchCheck: false,
            eligiblebrandscomp: [],
            eligibleitemscomp: [],
            eligibleitems_filters: [],
            unitsvar: 'Units',
            showDataTableStyle: 'none',
            exclude_brands: false,
            exclude_items: false,
            includerm: false,
            displayitemcomplink: 'none',
            displayincludermcheckbox: 'none',
            othergeo_alr: "",
            already_selected_attrs_comp: {},
            priceBandOptions: [],
            selectedPriceBands: [],

            showGroup1DefModal: false,
            showGroup2DefModal: false,
            selectedBrands_g1: [],
            selectedAttributes_g1: {},
            selectedPriceBands_g1: [],
            selectedGeos_g1: [],
            selectedBrands_g2: [],
            selectedAttributes_g2: {},
            selectedPriceBands_g2: [],
            selectedGeos_g2: [],
            numcomparisonsran: 0,
            filterPause: 'apply',
            graph_selected_attributes: [],
            graph_selected_attributes_labels: [],
            numweeks: 1,
            itemRankStart:1,
            itemRankEnd:10,
            brandRankStart:1,
            brandRankEnd: 10,
            nodiscSwitchCheck: false,
            g1Name: 'Group 1',
            g2Name: 'Group 2',
            exclude_brands_g1: false,
            exclude_brands_g2: false,
            indexSwitchCheckDollars: false,
            openScorecardMenu: false,
            openScorecardMenuYOY: false,
            showLastViews: true, 
            TwoYAGO: false,
            //currencysymbol: '$',
            extraparam: "",
            ComputeDateRangeModalOpen: false,
            viewattrnum: 0,
            target_dow: 6,
        }
    }

    handleToggle_exclude_brands() {
        this.setState({ exclude_brands: !this.state.exclude_brands })
        //Filter Dataset....
        let selected_option = { 'label': this.state.datasetgeos_lookup && this.state.datasetgeos_lookup[this.state.filters[0]], 'value': this.state.filters[0] }
        let exclude_array = [!this.state.exclude_brands, this.state.exclude_items]
        this.filterdataset_new(selected_option, 0, exclude_array)
    }

    handleToggle_exclude_items() {
        this.setState({ exclude_items: !this.state.exclude_items })
        //Filter Dataset....
        let selected_option = { 'label': this.state.datasetgeos_lookup && this.state.datasetgeos_lookup[this.state.filters[0]], 'value': this.state.filters[0] }
        let exclude_array = [this.state.exclude_brands, !this.state.exclude_items]
        this.filterdataset_new(selected_option, 0, exclude_array)
    }

    handleToggle_stackingType() {

        let stackingType_checked_new = !this.state.stackingType_checked
        var stackingType_new
        if (stackingType_checked_new) {
            stackingType_new = 'percent'
        }
        else {
            stackingType_new = 'normal'
        }

        this.setState({ stackingType_checked: stackingType_checked_new, stackingType: stackingType_new })

    }

    moveviewup_attr() {

        let viewattrnum = this.state.viewattrnum
        let maxviews = Math.max(this.state.graph_selected_attributes.length - 1, 0)
        var newviewromnum = Math.min(viewattrnum += 1, maxviews)
        this.setState({ viewattrnum: newviewromnum })
    }

    moveviewdown_attr() {

        let viewattrnum = this.state.viewattrnum
        let minviews = 0
        var newviewromnum = Math.max(viewattrnum += -1, minviews)
        this.setState({ viewattrnum: newviewromnum })
    }

    //Need to Edit this....
    handleDateStart(date) {
        if (date.length < 10) {
            //this.setState({ selectedstart: "" });
        }
        else {
            try {
                var startdate = String(date.format('YYYY-MM-DD'))
                this.setState({ selectedstart: startdate });
                this.setState({ reportstartdate: startdate + 'T00:00:00' })
                this.filterdataset_new([startdate, this.state.selectedend + 'T00:00:00'], 3)
            }
            catch (err) {

            }
        }

    };

    handleDateEnd(date) {
        if (date.length < 10) {
        }
        else {
            try {
                var enddate = String(date.format('YYYY-MM-DD'))
                this.setState({ selectedend: enddate });
                this.setState({ reportenddate: enddate + 'T00:00:00' })
                this.filterdataset_new([this.state.selectedstart, enddate + 'T00:00:00'], 3)
            }
            catch (err) {

            }
        }
    };

    handleDateEndRange(date) {
        var enddate = String(date.format('YYYY-MM-DD'))
        this.setState({ selectedendRange: enddate, calculatedrange: null });
    }

    async getReportSettings(reportid) {

        var report_settings_data = {}
        let report_settings_response = await fetch(Constant.baseapiurl + "ReportSettings/" + reportid)
        if (report_settings_response.status === 200) {
            report_settings_data = await report_settings_response.json()
        }
        else {
            report_settings_data = {}
        }
        console.log(report_settings_data)
        //Get Dataset Times    
        //let times_response = await fetch(Constant.baseapiurl + "Datasets/" + report_settings_data.dataset_id + "/Times" + this.state.extraparam)
        //if (times_response.status === 200) {
        //    var times_data = await times_response.json()
        //}
        //else {
        //    this.setState({ errorAlert_report: true })
        //    var times_data = []
        //}

        this.setState({ times_data: [report_settings_data.start_date, report_settings_data.end_date] })

        //fetch 
        //LOOK TO THE SETTINGS, AND SEE IF THE PRICE BANDS HAVE BEEN DEFINED

        let datasetidnum = report_settings_data.dataset_id        
        var pricebandssettings_data, discountbandssettings_data, user_dataset_settings_data
        //Check if PriceBands is in the list of settings....

        let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum)
        if (user_dataset_settings_response.status === 200) {
            user_dataset_settings_data = await user_dataset_settings_response.json()
        }
        else {
            user_dataset_settings_data = []
        }

        var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)

        if (usersettings_list.includes('PriceBands')) {
            let pricebandssettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/PriceBands')
            if (pricebandssettings_response.status === 200) {
                pricebandssettings_data = await pricebandssettings_response.json()
            }
        }

        if (usersettings_list.includes('DiscountBands')) {
            let discountbandssettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/DiscountBands')
            if (discountbandssettings_response.status === 200) {
                discountbandssettings_data = await discountbandssettings_response.json()
            }
        }

        console.log(pricebandssettings_data)
        console.log(discountbandssettings_data)

        if (discountbandssettings_data) {
            let discount_buckets = discountbandssettings_data.setting_value.bands.map(band => band.label)
            let discmaxes = discountbandssettings_data.setting_value.bands.map(band => band.value[1])
            this.setState({ discount_buckets, discmaxes})
        }

        //set state

        fetch(Constant.baseapiurl + "ReportSettings/" + reportid)
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {

                    res.json()
                        .then(
                            (result) => {
                                //adjust the modified date based on timezone
                                let dt = new Date()
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                result.time_updated_adj = (new Date(Date.parse(result.time_updated) - offset)).toISOString()
                                var reportsettings_var = result
                                var startdate = result.start_date
                                var pastdate_formatted = startdate.substring(0, 10)
                                var startdate_year = parseInt(startdate.substring(0, 4))
                                var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
                                var startdate_day = parseInt(startdate.substring(8, 10))
                                var pastdate = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                                //enddate
                                var enddate = result.end_date
                                var futuredate_formatted = enddate.substring(0, 10)
                                var enddate_year = parseInt(enddate.substring(0, 4))
                                var enddate_month = parseInt(enddate.substring(5, 7)) - 1 //Zero Based Month                                    
                                var enddate_day = parseInt(enddate.substring(8, 10))
                                var futuredate = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

                                fetch(Constant.baseapiurl + "Datasets/" + result.dataset_id)
                                    .then(res => {
                                        //Look at status code, and if not 200, throw error
                                        if (res.status === 200) {
                                            res.json()
                                                .then(
                                                    (result) => {
                                                        var datasetname = result.shortdesc
                                                        this.setState({ datasetname })
                                                        this.setState({ unitsvar: Constant.unitsvarlookup[datasetname] || "Units" })

                                                        //Build Attributes lookup
                                                        let attributesfromdata = result.attributes
                                                        let hr_attributesfromdata = result.attributes_human

                                                        let attribute_lookup = {}
                                                        attributesfromdata.forEach((attr, idx) => attribute_lookup[attr] = hr_attributesfromdata[idx])
                                                        this.setState({ attribute_lookup })


                                                    })
                                        }
                                        else {

                                        }
                                    })

                                fetch(Constant.baseapiurl + "Datasets/" + result.dataset_id + "/Parameters")
                                    .then(res => {
                                        //Look at status code, and if not 200, throw error
                                        if (res.status === 200) {

                                            res.json()
                                                .then(
                                                    (result) => {
                                                        var datasetgeos = result.geos;
                                                        var datasetgeos_lookup = {}
                                                        //datasetbrands = result.brands;
                                                        result.geos.map(geo => datasetgeos_lookup[geo.geogid] = geo.geogdesc)
                                                        this.setState({
                                                            datasetgeos: result.geos,
                                                            datasetgeos_lookup: datasetgeos_lookup,
                                                        })

                                                        this.getReportData(reportid, pricebandssettings_data)
                                                    }
                                                )
                                        }
                                        else if (res.status === 401 || res.status === 404) {
                                            let filters = [null, null, null, null, null, {
                                                'Error': [{ 'label': 'Error', 'value': 'Error' }]
                                            }]

                                            var volume_by_brand_object = {
                                                'Error': {
                                                    'totalvolume': 0,
                                                    'promovol': 0,
                                                    'nonpromovol': 0,
                                                    'basevol': 0,
                                                    'holvol': 0,
                                                    'incvol': 0,
                                                    'subsidizedbase': 0,
                                                    'unsubsidizedbase': 0,
                                                    'inctouseforcalc': 0,
                                                    'basetouseforcalc': 0
                                                }
                                            }

                                            var volume_by_priceband_object = {
                                                'Error': {
                                                    'totalvolume': 0,
                                                    'promovol': 0,
                                                    'nonpromovol': 0,
                                                    'basevol': 0,
                                                    'holvol': 0,
                                                    'incvol': 0,
                                                    'subsidizedbase': 0,
                                                    'unsubsidizedbase': 0,
                                                    'inctouseforcalc': 0,
                                                    'basetouseforcalc': 0
                                                }
                                            }

                                            var volume_by_item_object = {
                                                'Error': {
                                                    'totalvolume': 0,
                                                    'promovol': 0,
                                                    'nonpromovol': 0,
                                                    'basevol': 0,
                                                    'holvol': 0,
                                                    'incvol': 0,
                                                    'subsidizedbase': 0,
                                                    'unsubsidizedbase': 0,
                                                    'inctouseforcalc': 0,
                                                    'basetouseforcalc': 0
                                                }
                                            }



                                            this.setState({ isLoaded: true, errorAlert_priv: true, filters, volume_by_brand_object, volume_by_item_object })

                                        }
                                        else {

                                        }
                                    })


                                //This is too simplistic...subtract one year...if the resulting date is a Friday, add one day, Otherwise if Thursday, add two days to get to a Saturday to Saturday comp
                                var prior_start_s = String(parseInt(startdate.substring(0, 4)) - 1) + "-" + startdate.substring(5, 7) + "-" + startdate.substring(8, 10) + 'T00:00:00'
                                var prior_end_s = String(parseInt(enddate.substring(0, 4)) - 1) + "-" + enddate.substring(5, 7) + "-" + enddate.substring(8, 10) + 'T00:00:00'

                                var prior_start_d = new Date(prior_start_s)
                                var prior_end_d = new Date(prior_end_s)

                                var prior_start_dow = prior_start_d.getDay()
                                var prior_end_dow = prior_end_d.getDay()

                                var prior_start_numdays = 6 - prior_start_dow
                                var prior_end_numdays = 6 - prior_end_dow

                                prior_start_d.setDate(prior_start_d.getDate() + prior_start_numdays)
                                prior_end_d.setDate(prior_end_d.getDate() + prior_end_numdays)

                                var prior_start = prior_start_d.toISOString().substring(0, 10) + 'T00:00:00'
                                var prior_end = prior_end_d.toISOString().substring(0, 10) + 'T00:00:00'

                                //set state
                                this.setState({
                                    reportsettings: result,
                                    reportgeos: result.geogid,
                                    reportbrands: result.report_brands,
                                    datatable: result.dataset_id,
                                    reportstartdate: result.start_date,
                                    reportenddate: result.end_date,
                                    selectedstart: result.start_date.substring(0, 10),
                                    selectedend: result.end_date.substring(0, 10),
                                    start_date_prior: prior_start,
                                    end_date_prior: prior_end,
                                    pastdate: pastdate,
                                    futuredate: futuredate,
                                    pastdate_formatted: pastdate_formatted,
                                    futuredate_formatted: futuredate_formatted
                                });


                            })
                }

                else if (res.status === 401 || res.status === 404) {
                    let filters = [null, null, null, null, null, {
                        'Error': [{ 'label': 'Error', 'value': 'Error' }]
                    }]

                    var volume_by_brand_object = {
                        'Error': {
                            'totalvolume': 0,
                            'promovol': 0,
                            'nonpromovol': 0,
                            'basevol': 0,
                            'holvol': 0,
                            'incvol': 0,
                            'subsidizedbase': 0,
                            'unsubsidizedbase': 0,
                            'inctouseforcalc': 0,
                            'basetouseforcalc': 0
                        }
                    }

                    var volume_by_priceband_object = {
                        'Error': {
                            'totalvolume': 0,
                            'promovol': 0,
                            'nonpromovol': 0,
                            'basevol': 0,
                            'holvol': 0,
                            'incvol': 0,
                            'subsidizedbase': 0,
                            'unsubsidizedbase': 0,
                            'inctouseforcalc': 0,
                            'basetouseforcalc': 0
                        }
                    }

                    var volume_by_item_object = {
                        'Error': {
                            'totalvolume': 0,
                            'promovol': 0,
                            'nonpromovol': 0,
                            'basevol': 0,
                            'holvol': 0,
                            'incvol': 0,
                            'subsidizedbase': 0,
                            'unsubsidizedbase': 0,
                            'inctouseforcalc': 0,
                            'basetouseforcalc': 0
                        }
                    }


                    this.setState({ isLoaded: true, errorAlert_priv: true, filters, volume_by_brand_object, volume_by_item_object, start_date_prior: 'Error', end_date_prior: 'Error' })

                }

                else {

                }
            })

        //Set Currency Code...
        let datasetsettings_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + datasetidnum)
        var datasetsettings_data
        if (datasetsettings_resp.status === 200) {
            datasetsettings_data = await datasetsettings_resp.json()
        }
        else {
            datasetsettings_data = []
        }

        var datasetsettings_list = datasetsettings_data.map(setting => setting.setting_name)
        console.log(datasetsettings_list)
        var currency_resp_data
        if (datasetsettings_list.includes('currency_info')) {
            let datasetsettings_currency_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + datasetidnum + '/currency_info')
            if (datasetsettings_currency_resp.status === 200) {
                currency_resp_data = await datasetsettings_currency_resp.json()
                console.log(currency_resp_data)
            }
            else {
                currency_resp_data = {
                    "setting_value": {
                        "currencycode": "USD",
                        "currencytype": "Dollars",
                        "currencysymbol": '$'
                    },
                }
            }
        }
        else {
            currency_resp_data = {
                "setting_value": {
                    "currencycode": "USD",
                    "currencytype": "Dollars",
                    "currencysymbol": '$'
                },
            }
        }

        let currencycode = currency_resp_data.setting_value.currencycode
        let currencytype = currency_resp_data.setting_value.currencytype
        let currencysymbol = currency_resp_data.setting_value.currencysymbol
        this.setState({
            currencycode, currencytype, currencysymbol
        })

    }

    getReportData(reportid, pricebandssettings_data) {
        fetch(Constant.baseapiurl + "ReportData/" + reportid)
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {

                    res.json()
                        .then(
                            (result) => {
                                if (result.length === 0) {
                                    console.log('No Rows!!')
                                    let filters = [null, null, null, null, null, {
                                        'Error': [{ 'label': 'Error', 'value': 'Error' }]
                                    }]

                                    var volume_by_brand_object = {
                                        'Error': {
                                            'totalvolume': 0,
                                            'promovol': 0,
                                            'nonpromovol': 0,
                                            'basevol': 0,
                                            'holvol': 0,
                                            'incvol': 0,
                                            'subsidizedbase': 0,
                                            'unsubsidizedbase': 0,
                                            'inctouseforcalc': 0,
                                            'basetouseforcalc': 0
                                        }
                                    }

                                    var volume_by_priceband_object = {
                                        'Error': {
                                            'totalvolume': 0,
                                            'promovol': 0,
                                            'nonpromovol': 0,
                                            'basevol': 0,
                                            'holvol': 0,
                                            'incvol': 0,
                                            'subsidizedbase': 0,
                                            'unsubsidizedbase': 0,
                                            'inctouseforcalc': 0,
                                            'basetouseforcalc': 0
                                        }
                                    }

                                    var volume_by_item_object = {
                                        'Error': {
                                            'totalvolume': 0,
                                            'promovol': 0,
                                            'nonpromovol': 0,
                                            'basevol': 0,
                                            'holvol': 0,
                                            'incvol': 0,
                                            'subsidizedbase': 0,
                                            'unsubsidizedbase': 0,
                                            'inctouseforcalc': 0,
                                            'basetouseforcalc': 0
                                        }
                                    }

                                    this.setState({ isLoaded: true, errorAlert: true, filters, volume_by_brand_object, volume_by_item_object, volume_by_priceband_object })
                                }
                                else {
                                    var allcolnames = Object.keys(result[0])
                                    var distinctcolnames = []
                                    var attribute_col_names_raw = []
                                    allcolnames.map(colname => { if (colname.substring(0, 3) === 'dt_') { distinctcolnames.push(colname) } })
                                    let valid_cols = ['avgasp', 'avgbp', 'avgpctdisc', 'brand', 'date', 'dollarsonpromo', 'geogid', 'proddesc', 'prodid', 'timeid', '_ACTUAL', 'equiv', 'suppression','geogdesc']
                                    allcolnames.map(colname => {
                                        if (colname.substring(0, 3) !== 'dt_' && colname.substring(0, 7) !== 'unitson' && !valid_cols.includes(colname) && !attribute_col_names_raw.includes(colname)) {
                                            attribute_col_names_raw.push(colname)
                                        }
                                    })

                                    var attribute_col_names = []
                                    var subcat_index = attribute_col_names_raw.indexOf('subcategory')
                                    console.log(subcat_index)                                    
                                    if (subcat_index !== -1) {
                                        attribute_col_names_raw.splice(subcat_index, 1)
                                        //Need to Sort attribute_col_names with subcategory first, and everything else sorted by the description
                                        attribute_col_names = ['subcategory'].concat(attribute_col_names_raw.sort())
                                    }
                                    else {
                                        attribute_col_names = attribute_col_names_raw.sort()
                                    }                                    

                                    //Custom Sort attribute_col_names...
                                    console.log(attribute_col_names)


                                    var attributes_comp_g1 = {}
                                    var attributes_comp_g2 = {}
                                    attribute_col_names.map(attribute => {
                                        attributes_comp_g1[attribute] = []
                                        attributes_comp_g2[attribute] = []
                                    })

                                    var attributes = {}
                                    var distinctitemsarray = []
                                    var distinctitemnumsarray = []
                                    var distictdatesarray = []
                                    var distinctgeos = []
                                    var distinctgeonames = []
                                    var distinctbrands = []
                                    var itemdict = {}
                                    var reportsettings_var = this.state.reportsettings
                                    var brand_options = []
                                    var item_options = []
                                    var geo_options = []
                                    var asp_min = null
                                    var asp_max = null
                                    var productAttrDict = {}
                                    var productAttrDict_vals = {}
                                    result.map(row => (
                                        distinctitemsarray.push(row.proddesc),
                                        distinctitemnumsarray.push(row.prodid),
                                        itemdict[row.proddesc] = row.prodid,
                                        distinctgeos.push(row.geogid),
                                        distinctbrands.push(row.brand)
                                    )
                                    )


                                    result.map(row => {

                                        //adjust row.date
                                        let adjusted_rowdate = formatDate(adjustDate(new Date(row.date+'T00:00:00'), this.state.target_dow))

                                        if (adjusted_rowdate.substring(0, 10) >= reportsettings_var.start_date.substring(0, 10) && adjusted_rowdate.substring(0, 10) <= reportsettings_var.end_date.substring(0, 10)) {
                                            distictdatesarray.push(adjusted_rowdate)
                                            //ASP MIN
                                            if (!asp_min) {
                                                asp_min = row.avgasp
                                            }
                                            else if (row.avgasp < asp_min) {
                                                asp_min = row.avgasp
                                            }

                                            //ASP MAX
                                            if (!asp_max) {
                                                asp_max = row.avgasp
                                            }
                                            else if (row.avgasp > asp_max) {
                                                asp_max = row.avgasp
                                            }
                                        }
                                        attribute_col_names.map(attribute => {
                                            if (Object.keys(attributes).includes(attribute)) {
                                                if (!attributes[attribute].includes(row[attribute])) {
                                                    attributes[attribute].push(row[attribute])
                                                }
                                            }
                                            else {
                                                attributes[attribute] = [row[attribute]]
                                            }
                                        })



                                        //create an entry in the product_attr_dict
                                        if (!Object.keys(productAttrDict).includes(row.proddesc)) {
                                            let attr_string = ""
                                            attribute_col_names.map(attribute => attr_string += this.state.attribute_lookup[attribute] + ": " + row[attribute] + " ")
                                            productAttrDict[row.proddesc] = attr_string
                                        }

                                        if (!Object.keys(productAttrDict_vals).includes(row.proddesc)) {
                                            productAttrDict_vals[row.proddesc] = {}
                                            attribute_col_names.map(attribute =>
                                                productAttrDict_vals[row.proddesc][attribute] = row[attribute]
                                            )
                                        }
                                    }

                                    )
                                    Array.from(new Set(distinctbrands)).map(brand => brand_options.push(({ 'value': brand, 'label': brand })))
                                    Array.from(new Set(distinctitemsarray)).map(item => item_options.push(({ 'value': item, 'label': item })))
                                    Array.from(new Set(distinctgeos)).map(geo => {
                                        this.state.datasetgeos.map(datasetgeo => {
                                            if (datasetgeo.geogid == geo) {
                                                geo_options.push(({ 'value': geo, 'label': datasetgeo.geogdesc }))
                                                distinctgeonames.push(datasetgeo.geogdesc)
                                            }
                                        })

                                    })

                                    //Add All to Attributes Filter for Initial Setting
                                    let attribute_all_vals = {}
                                    attribute_col_names.map(colname => attribute_all_vals[colname] = [{ 'label': 'All', 'value': 'All' }])
                                    let filters = [Array.from(new Set(distinctgeos))[0], 'All', 'All', reportsettings_var.start_date, reportsettings_var.end_date, attribute_all_vals]
                                    let alr_object = Constant.alr_object
                                    if (alr_object[this.state.datasetname]) {
                                        if (Object.keys(alr_object[this.state.datasetname]).includes(Array.from(new Set(distinctgeos))[0].toString())) {
                                            this.setState({ displayincludermcheckbox: 'inline' })
                                        }
                                        else {
                                            this.setState({ displayincludermcheckbox: 'none' })
                                        }
                                    }

                                    else {
                                        this.setState({ displayincludermcheckbox: 'none' })
                                    }

                                    let voldecomp_type = ""
                                    let title_items = ""
                                    if (Array.from(new Set(distictdatesarray)).length <= 12) {
                                        voldecomp_type = 'column'
                                    }
                                    else {
                                        voldecomp_type = 'area'
                                    }

                                    if (Array.from(new Set(distinctitemsarray)).length === 1) {
                                        title_items = Array.from(new Set(distinctitemsarray))[0]
                                    }

                                    else {
                                        title_items = 'Multiple'
                                    }


                                    //Calculate the price bands from the asp_min and asp_max
                                    var price_band_options = []
                                    var currencysymbol = this.state.currencysymbol
                                    if (pricebandssettings_data) {
                                        
                                        let settings_value = pricebandssettings_data.setting_value
                                        if (settings_value.type === 'rulebased') {
                                            let bandsize = settings_value.bandsize
                                            let numbands = settings_value.numbands
                                            //create the bands...
                                            let band_start = 0
                                            let band_end = bandsize
                                            for (var band = 0; band < numbands; band++) {
                                                let band_start_label = band_start + 1
                                                if (band === 0) {
                                                    let price_band_option_label = 'Under ' + currencysymbol + band_end
                                                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                                                }
                                                else if (band !== (numbands - 1)) {
                                                    let price_band_option_label = currencysymbol + band_start_label + ' to ' + currencysymbol + band_end
                                                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                                                }

                                                else if (band === (numbands - 1)) {
                                                    let price_band_option_label = currencysymbol + band_start_label + '+'
                                                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, 10000000] })  /*arbitrary price to max out the band...*/
                                                }

                                                band_start += bandsize
                                                band_end += bandsize
                                            }
                                        }
                                        else if (settings_value.type === 'custom') {
                                            price_band_options = settings_value.bands
                                        }

                                        //this.setState({ priceBandOptions: price_band_options })
                                    }

                                    else {
                                        let asp_range_raw = asp_max - asp_min
                                        let max_num_bands = 7
                                        let band_size_raw = asp_range_raw / max_num_bands
                                        var band_size = 0
                                        if (band_size_raw < 7.5) {
                                            band_size = 5
                                        }
                                        else if (band_size_raw >= 7.5 && band_size_raw < 15) {
                                            band_size = 10
                                        }

                                        else if (band_size_raw >= 15 && band_size_raw < 35) {
                                            band_size = 25
                                        }

                                        else if (band_size_raw >= 35 && band_size_raw < 65) {
                                            band_size = 50
                                        }

                                        else if (band_size_raw >= 65 && band_size_raw < 95) {
                                            band_size = 75
                                        }

                                        else if (band_size_raw >= 95 && band_size_raw < 150) {
                                            band_size = 100
                                        }

                                        else if (band_size_raw >= 150 && band_size_raw < 250) {
                                            band_size = 200
                                        }

                                        else if (band_size_raw >= 250 && band_size_raw < 350) {
                                            band_size = 300
                                        }

                                        else if (band_size_raw >= 350 && band_size_raw < 450) {
                                            band_size = 400
                                        }

                                        else {
                                            band_size = 500
                                        }

                                        //If Notebooks, Notebooks and Tablets, or Monitors, set band to 300.
                                        if (['Notebook Computers', 'Monitors', 'Notebooks and Tablets'].includes(this.state.datasetname)) {
                                            band_size = 300
                                            max_num_bands = 6
                                        }

                                        let band_start = 0
                                        let band_end = band_size

                                        
                                        for (var band = 0; band < max_num_bands; band++) {
                                            if (band_start < asp_max) {
                                                let band_start_label = band_start + 1
                                                if (band === 0) {
                                                    let price_band_option_label = 'Under $' + band_end
                                                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                                                }
                                                else if ((band_end < asp_max) && band !== (max_num_bands - 1)) {
                                                    let price_band_option_label = '$' + band_start_label + ' to $' + band_end
                                                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                                                }

                                                else if ((band_end < asp_max) && band === (max_num_bands - 1)) {
                                                    let price_band_option_label = '$' + band_start_label + '+'
                                                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, asp_max] })
                                                }

                                                else {
                                                    let price_band_option_label = '$' + band_start_label + '+'
                                                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                                                }

                                                band_start += band_size
                                                band_end += band_size
                                            }
                                        }

                                        if (['Notebook Computers', 'Monitors', 'Notebooks and Tablets'].includes(this.state.datasetname)) {
                                            price_band_options = [
                                                { 'label': '$300 and Under', 'value': [0, 300] }
                                                , { 'label': '$301 to $500', 'value': [300, 500] }
                                                , { 'label': '$501 to $700', 'value': [500, 700] }
                                                , { 'label': '$701 to $900', 'value': [700, 900] }
                                                , { 'label': '$901 to $1100', 'value': [900, 1100] }
                                                , { 'label': '$1101 to $1500', 'value': [1100, 1500] }
                                                , { 'label': '$1501 and Above', 'value': [1500, asp_max] }
                                            ]
                                        }

                                        if (['Coffee Makers'].includes(this.state.datasetname)) {
                                            price_band_options = [
                                                { 'label': '$75 and Under', 'value': [0, 75] }
                                                , { 'label': '$76 to $150', 'value': [75, 150] }
                                                , { 'label': '$151 to $225', 'value': [150, 225] }
                                                , { 'label': '$226 to $300', 'value': [225, 300] }
                                                , { 'label': '$301 to $375', 'value': [300, 375] }
                                                , { 'label': '$376 to $450', 'value': [375, 450] }
                                                , { 'label': '$451 and Above', 'value': [450, asp_max] }
                                            ]
                                        }
                                    }

                                    

                                    this.setState({
                                        brands: brand_options,
                                        geos: geo_options,
                                        items: item_options,
                                        itemsforfilter: item_options,
                                        distinctbrands: Array.from(new Set(distinctbrands)),
                                        distinctgeos: distinctgeonames,
                                        distinctitems: Array.from(new Set(distinctitemsarray)),
                                        distinctitemnums: Array.from(new Set(distinctitemnumsarray)),
                                        item_dict: itemdict,
                                        distinctdates: Array.from(new Set(distictdatesarray)).sort(),
                                        distinctcolnames: Array.from(new Set(distinctcolnames)),
                                        filters: filters, //[Array.from(new Set(distinctgeos))[0], 'All', 'All', reportsettings_var.start_date, reportsettings_var.end_date],
                                        compfilters_attr: [filters[5]],
                                        attributes: attributes,
                                        selectedAttributes_g1: attributes_comp_g1,
                                        selectedAttributes_g2: attributes_comp_g2,
                                        attribute_col_names: attribute_col_names,
                                        graph_selected_attributes: attribute_col_names,
                                        graph_selected_attributes_labels: [{'label':'All','value':'All'}],
                                        //attribute_vals: attribute_vals,
                                        selectedItems_filter: [{ 'label': 'All', 'value': 'All' }],
                                        selectedBrands_filter: [{ 'label': 'All', 'value': 'All' }],
                                        title_num_weeks: Array.from(new Set(distictdatesarray)).length, //Num Weeks
                                        title_begin_date: reportsettings_var.start_date.substring(0, 10), //Start Date
                                        title_end_date: reportsettings_var.end_date.substring(0, 10), //Start Date
                                        title_geo_selected: this.state.datasetgeos_lookup[Array.from(new Set(distinctgeos))[0]], //Initial Geo 
                                        voldecomp_type: voldecomp_type,
                                        title_items: title_items,
                                        sliderRange: [Math.floor(asp_min), Math.round(asp_max)],
                                        sliderRangeMin: Math.floor(asp_min),
                                        sliderRangeMax: Math.round(asp_max),
                                        priceBandOptions: price_band_options,
                                        selectedPriceBands: price_band_options,
                                        productAttrDict_vals,
                                        productAttrDict,
                                        reportdata: result
                                    });

                                    this.evaluateRows(result, filters, [Math.floor(asp_min), Math.round(asp_max)], [], price_band_options, this.state.numweeks)
                                }
                            })
                        .catch(
                            (error) => {
                                console.log(error)
                                let filters = [null, null, null, null, null, {
                                    'Error': [{ 'label': 'Error', 'value': 'Error' }]
                                }]

                                var volume_by_brand_object = {
                                    'Error': {
                                        'totalvolume': 0,
                                        'promovol': 0,
                                        'nonpromovol': 0,
                                        'basevol': 0,
                                        'holvol': 0,
                                        'incvol': 0,
                                        'subsidizedbase': 0,
                                        'unsubsidizedbase': 0,
                                        'inctouseforcalc': 0,
                                        'basetouseforcalc': 0
                                    }
                                }

                                var volume_by_priceband_object = {
                                    'Error': {
                                        'totalvolume': 0,
                                        'promovol': 0,
                                        'nonpromovol': 0,
                                        'basevol': 0,
                                        'holvol': 0,
                                        'incvol': 0,
                                        'subsidizedbase': 0,
                                        'unsubsidizedbase': 0,
                                        'inctouseforcalc': 0,
                                        'basetouseforcalc': 0
                                    }
                                }

                                var volume_by_item_object = {
                                    'Error': {
                                        'totalvolume': 0,
                                        'promovol': 0,
                                        'nonpromovol': 0,
                                        'basevol': 0,
                                        'holvol': 0,
                                        'incvol': 0,
                                        'subsidizedbase': 0,
                                        'unsubsidizedbase': 0,
                                        'inctouseforcalc': 0,
                                        'basetouseforcalc': 0
                                    }
                                }




                                this.setState({ isLoaded: true, errorAlert_other: true, filters, volume_by_brand_object, volume_by_item_object, volume_by_priceband_object })
                            })
                }

                else if (res.status === 401) {


                    let filters = [null, null, null, null, null, {
                        'Error': [{ 'label': 'Error', 'value': 'Error' }]
                    }]

                    var volume_by_brand_object = {
                        'Error': {
                            'totalvolume': 0,
                            'promovol': 0,
                            'nonpromovol': 0,
                            'basevol': 0,
                            'holvol': 0,
                            'incvol': 0,
                            'subsidizedbase': 0,
                            'unsubsidizedbase': 0,
                            'inctouseforcalc': 0,
                            'basetouseforcalc': 0
                        }
                    }

                    var volume_by_priceband_object = {
                        'Error': {
                            'totalvolume': 0,
                            'promovol': 0,
                            'nonpromovol': 0,
                            'basevol': 0,
                            'holvol': 0,
                            'incvol': 0,
                            'subsidizedbase': 0,
                            'unsubsidizedbase': 0,
                            'inctouseforcalc': 0,
                            'basetouseforcalc': 0
                        }
                    }

                    var volume_by_item_object = {
                        'Error': {
                            'totalvolume': 0,
                            'promovol': 0,
                            'nonpromovol': 0,
                            'basevol': 0,
                            'holvol': 0,
                            'incvol': 0,
                            'subsidizedbase': 0,
                            'unsubsidizedbase': 0,
                            'inctouseforcalc': 0,
                            'basetouseforcalc': 0
                        }
                    }




                    this.setState({ isLoaded: true, errorAlert_priv: true, filters, volume_by_brand_object, volume_by_item_object, volume_by_priceband_object })

                }

                else {

                }
            })
    }

    run_comparison(reportData, filters, asp_range, fromwhere, numweeks, metricsupplied = {}, metricsupplied_trended = {}, metricsupplied_overlay = {}, unitsdollars) {
        console.log('Running Comparison')


        const reducer = (accumulator, currentValue) => accumulator + currentValue;

        //Get the values of Metric, Level, and Group Members
        //If Any One of these is empty, throw an error....
        //check that metric has been selected....
        let current_tab = this.state.value4
        var error = false
        let numcomparisonsran = this.state.numcomparisonsran
        let metric = (metricsupplied || {}).value || (this.state.comparisonMetricSelected.value || "")
        let metric_trended = (metricsupplied_trended || {}).value || (this.state.comparisonMetricSelected_trended.value || "")
        var metric_trended_1 = ""
        var metric_trended_2 = ""
        let level = this.state.comparisonLevelSelected.value
        let group1 = this.state.comparisonGroup1
        let group2 = this.state.comparisonGroup2
        //let filters = filters
        let result = reportData

        try {
            var overlay = (metricsupplied_overlay || {}).value || (this.state.overlayMetricSelected.value || 'Not Selected')
        }
        catch{
            var overlay = 'Not Selected'
        }

        let geo_filter = filters[0]

        //ALRS
        let alr_object = Constant.alr_object



        let member_filter_g1 = group1.map(member => member.value)
        let member_filter_g2 = group2.map(member => member.value)
        let member_filter_g1_label = group1.map(member => member.label)
        let member_filter_g2_label = group2.map(member => member.label)
        let start_date_filter = filters[3]
        let end_date_filter = filters[4] + 'T00:00:00'
        var error = false

        if (error === true) {

        }

        else {
            var rownums = []
            var weighted_dollars_inc_g1 = 0
            var weighted_dollars_promo_g1 = 0
            var weighted_dollars_nonpromo_g1 = 0
            var weighted_dollars_disc_g1 = 0
            var total_dollars_g1 = 0
            var promounits_g1 = 0
            var nonpromounits_g1 = 0
            var incunits_g1 = 0
            var totalunits_g1 = 0
            var subbaseunits_g1 = 0
            var weighted_dollars_nonpromo_g1_weekly = {}
            var weighted_dollars_incremental_g1_weekly = {}
            var incremental_g1_weekly = {}
            var incremental_g2_weekly = {}
            var promounits_g1_weekly = {}
            var subbaseunits_g1_weekly = {}
            var nonpromounits_g1_weekly = {}
            var totalunits_g1_weekly = {}
            var discpct_g1_weekly = {}
            var total_dollars_weekly_g1_me = {}
            var total_dollars_weekly_g2_me = {}

            var hol_data_by_weekly_g1 = {}
            var hol_data_by_weekly_g2 = {}//Holiday Volume by Week

            var total_baseprice_weekly_g1 = {}
            var total_baseprice_weekly_g2 = {}
            var disc_dollars_weekly_g1 = {}
            var disc_dollars_weekly_g2 = {}

            var weighted_dollars_nonpromo_g2_weekly = {}
            var weighted_dollars_incremental_g2_weekly = {}
            var promounits_g2_weekly = {}
            var subbaseunits_g2_weekly = {}
            var nonpromounits_g2_weekly = {}
            var totalunits_g2_weekly = {}
            var discpct_g2_weekly = {}

            var weighted_dollars_inc_g2 = 0
            var weighted_dollars_promo_g2 = 0
            var weighted_dollars_nonpromo_g2 = 0
            var weighted_dollars_disc_g2 = 0
            var total_dollars_g2 = 0
            var total_dollars_me = 0
            var promounits_g2 = 0
            var nonpromounits_g2 = 0
            var incunits_g2 = 0
            var totalunits_g2 = 0
            var subbaseunits_g2 = 0

            var numrows_g1 = 0
            var numrows_g2 = 0
            var numrows_else = 0

            var totalincunits = 0
            var totalincunits_weekly = {}

            var my_share = 0

            //Add Date Mapping Here...

            let startdate = filters[3].substring(0, 10)
            let enddate = filters[4].substring(0, 10)
            //let numweeks = this.state.numweeks

            //if startdate is a sunday add 6 to it...
            var startdate_year = parseInt(startdate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set... 
            var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
            var startdate_day = parseInt(startdate.substring(8, 10))
            var startdate_moment = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
            console.log(startdate_moment.day())
            if (startdate_moment.day() === 0) {
                startdate = startdate_moment.subtract(-6, 'd').format('YYYY-MM-DD')
            }

            console.log(startdate)

            //Adding in grouped dates as a test...
            let groupeddates = [startdate]
            let dateconvgrouped = new Date(startdate + 'T00:00:00Z')
            let newdategrouped = new Date(dateconvgrouped.setTime(dateconvgrouped.getTime() + (7 * numweeks * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates

            //make a mapping to make the dates in the group equal to the date...
            let datemapping = {}
            datemapping[startdate] = startdate
            let dateconvstart = new Date(startdate + 'T00:00:00Z')
            let newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
            //console.log(newdategrouped_mapping)

            while (newdategrouped_mapping < newdategrouped) {
                datemapping[newdategrouped_mapping] = startdate
                newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
            }

            while (newdategrouped <= enddate) {
                groupeddates.push(newdategrouped)
                datemapping[newdategrouped] = newdategrouped
                let timeref = newdategrouped
                let dateconvstart = new Date(newdategrouped + 'T00:00:00Z')

                dateconvgrouped = new Date(newdategrouped + 'T00:00:00Z')
                newdategrouped = new Date(dateconvgrouped.setTime(dateconvgrouped.getTime() + (7 * numweeks * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)

                let newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates

                while (newdategrouped_mapping < newdategrouped) {
                    datemapping[newdategrouped_mapping] = timeref
                    newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
                }
            }

            var exclude_brands_g1 = this.state.exclude_brands_g1 
            var exclude_brands_g2 = this.state.exclude_brands_g2

            var g1Name = this.state.g1Name
            var g2Name = this.state.g2Name

            console.log(datemapping)

            result.map((row, idx) => {
                var equiv = 1 // row['equiv'] || 1 //Removed Lookup based on feedback
                var userow_g1 = false
                var userow_g2 = false
                var userow_else = false
                var userow_total = false
                var price_band_group = ""
                this.state.priceBandOptions.forEach(band => {
                    if (row.avgbp >= band.value[0] && row.avgbp <= band.value[1]) {
                        price_band_group = band.label
                    }
                })

                let adjusted_rowdate = formatDate(adjustDate(new Date(row.date + 'T00:00:00'), this.state.target_dow))

                let rowdate = datemapping[adjusted_rowdate]
                

                let row_attr_val = []

                let geo_g1_match = false
                let geo_g2_match = false

                let brand_g1_match = false
                let brand_g2_match = false

                let row_attr_val_g1 = []
                let row_attr_val_g2 = []

                let priceband_g1_match = false
                let priceband_g2_match = false

                //Bring in the values for rows, price bands, and attributes....
                let brand = row.brand //(row.brand_name || "Unknown").replaceAll('"', '').trim()

                let selectedbrands_g1 = this.state.selectedBrands_g1
                let selectedattributes_g1 = this.state.selectedAttributes_g1
                let selectedpricebands_g1 = this.state.selectedPriceBands_g1
                let selectedgeos_g1 = this.state.selectedGeos_g1

                let selectedbrands_g2 = this.state.selectedBrands_g2
                let selectedattributes_g2 = this.state.selectedAttributes_g2
                let selectedpricebands_g2 = this.state.selectedPriceBands_g2
                let selectedgeos_g2 = this.state.selectedGeos_g2

                //create geo list...if selectedgeos, then use that, otherwise, use filter[0]

                if (selectedgeos_g1.length > 0) {
                    var geo_filter_adj_g1 = selectedgeos_g1.map(geo => geo.value)
                }
                else {
                    var geo_filter_adj_g1 = [filters[0]]
                }

                if (selectedgeos_g2.length > 0) {
                    var geo_filter_adj_g2 = selectedgeos_g2.map(geo => geo.value)
                }
                else {
                    var geo_filter_adj_g2 = [filters[0]]
                }

                //geo_g1_match...
                if (geo_filter_adj_g1.includes(row.geogid)) {
                    geo_g1_match = true
                }

                else {
                    geo_g1_match = false
                }

                //geo_g1_match...
                if (geo_filter_adj_g2.includes(row.geogid)) {
                    geo_g2_match = true
                }

                else {
                    geo_g2_match = false
                }

                //Iterate through brand 1 selections.  If row brand in brands for group 1 or group 1 is blank then set as true.
                //Look at exclude_brands_g1...if true, use opposite logic as include...
                if (exclude_brands_g1) {
                    if (selectedbrands_g1.length === 0) {
                        brand_g1_match = false
                    }
                    else {
                        let brands = selectedbrands_g1.map(brand => brand.value)
                        if (!brands.includes(brand)) {
                            brand_g1_match = true
                        }
                        else {
                            brand_g1_match = false
                        }
                    }

                }
                else {
                    if (selectedbrands_g1.length === 0) {
                        brand_g1_match = true
                    }
                    else {
                        let brands = selectedbrands_g1.map(brand => brand.value)                        
                        if (brands.includes(brand)) {
                            brand_g1_match = true
                        }
                        else {
                            brand_g1_match = false
                        }
                    }

                }

                //Iterate through brand 2 selections.  If row brand in brands for group 2 or group 2 is blank then set as true.
                //Look at exclude_brands_g1...if true, use opposite logic as include...
                if (exclude_brands_g2) {
                    if (selectedbrands_g2.length === 0) {
                        brand_g2_match = false
                    }
                    else {
                        let brands = selectedbrands_g2.map(brand => brand.value)
                        if (!brands.includes(brand)) {
                            brand_g2_match = true
                        }
                        else {
                            brand_g2_match = false
                        }
                    }

                }
                else {
                    if (selectedbrands_g2.length === 0) {
                        brand_g2_match = true
                    }
                    else {
                        let brands = selectedbrands_g2.map(brand => brand.value)
                        if (brands.includes(brand)) {
                            brand_g2_match = true
                        }
                        else {
                            brand_g2_match = false
                        }
                    }

                }

                


                //Look at attributes...
                if (Object.keys(selectedattributes_g1).length === 0) {
                    row_attr_val_g1.push(true)
                }
                Object.keys(selectedattributes_g1).map(attribute => {
                    let row_val = row[attribute]
                    let attr_vals = Object.values(selectedattributes_g1[attribute]).map((value => value.value))
                    if (attr_vals.length === 0) {
                        row_attr_val_g1.push(true)
                    }
                    else if (attr_vals.includes(row_val)) {
                        row_attr_val_g1.push(true)
                    }
                    else {
                        row_attr_val_g1.push(false)
                    }
                })

                if (Object.keys(selectedattributes_g2).length === 0) {
                    row_attr_val_g2.push(true)
                }
                Object.keys(selectedattributes_g2).map(attribute => {
                    let row_val = row[attribute]
                    let attr_vals = Object.values(selectedattributes_g2[attribute]).map((value => value.value))
                    if (attr_vals.length === 0) {
                        row_attr_val_g2.push(true)
                    }
                    else if (attr_vals.includes(row_val)) {
                        row_attr_val_g2.push(true)
                    }
                    else {
                        row_attr_val_g2.push(false)
                    }
                })

                //Look at price bands...

                //Iterate through brand 1 selections.  If row brand in brands for group 1 or group 1 is blank then set as true.
                if (selectedpricebands_g1.length === 0) {
                    priceband_g1_match = true
                }
                else {
                    let bands = selectedpricebands_g1.map(band => band.label)
                    if (bands.includes(price_band_group)) {
                        priceband_g1_match = true
                    }
                    else {
                        priceband_g1_match = false
                    }
                }

                //Iterate through brand 2 selections.  If row brand in brands for group 2 or group 2 is blank then set as true.
                if (selectedpricebands_g2.length === 0) {
                    priceband_g2_match = true
                }
                else {
                    let bands = selectedpricebands_g2.map(band => band.label)
                    if (bands.includes(price_band_group)) {
                        priceband_g2_match = true
                    }
                    else {
                        priceband_g2_match = false
                    }
                }

                let attr_match_g1 = row_attr_val_g1.reduce(reducer) === row_attr_val_g1.length | row_attr_val_g1.reduce(reducer) === true
                let attr_match_g2 = row_attr_val_g2.reduce(reducer) === row_attr_val_g2.length | row_attr_val_g2.reduce(reducer) === true

                if (geo_g1_match && adjusted_rowdate.substring(0, 10) >= start_date_filter.substring(0, 10) && adjusted_rowdate.substring(0, 10) <= end_date_filter.substring(0, 10) && brand_g1_match && priceband_g1_match && attr_match_g1) {
                    userow_g1 = true
                    numrows_g1 += 1
                }
                else if (geo_g2_match && adjusted_rowdate.substring(0, 10) >= start_date_filter.substring(0, 10) && adjusted_rowdate.substring(0, 10) <= end_date_filter.substring(0, 10) && brand_g2_match && priceband_g2_match && attr_match_g2) {
                    userow_g2 = true
                    numrows_g2 += 1
                }

                //add to total incremental units if in geo1 or geo2
                if ((geo_g1_match || geo_g2_match) && adjusted_rowdate.substring(0, 10) >= start_date_filter.substring(0, 10) && adjusted_rowdate.substring(0, 10) <= end_date_filter.substring(0, 10)) {
                    userow_total = true
                }


                var proddesc = row.proddesc
                var brandname = row.brand
                var avgdisc = row.avgpctdisc
                var avgbp = row.avgbp
                var avgasp = row.avgasp
                //Create Metrics.....
                var totalvol = parseFloat(row['_ACTUAL']) * equiv
                var seasonality_vals = ['dt_LNSEAS1', 'dt_LNSEAS2', 'dt_LNSEAS3',]
                var seasonality_value = 0

                for (var seas = 0; seas < 3; seas++) {
                    if (row[seasonality_vals[seas]]) {
                        seasonality_value += parseFloat(row[seasonality_vals[seas]] * equiv)
                    }
                    else {
                        seasonality_value += 0
                    }
                }

                var promounits = row.unitsonpromo * equiv
                var basevol_raw = (((parseFloat(row['dt_CORE']) || 0) + (parseFloat(row['dt_COMPPDISC']) || 0) + (parseFloat(row['dt_CENLNBPU']) || 0)) * equiv) + seasonality_value
                var hol_raw = parseFloat(row['dt_HOLIDAY'] * equiv) || 0
                var total_raw = parseFloat(row['_ACTUAL'] * equiv)
                var inc_raw = total_raw - basevol_raw - hol_raw


                //look at base and correct as needed...
                let corrected_base_final = 0
                let corrected_hol_final = 0
                let corrected_inc_final = 0

                if (basevol_raw > total_raw) {
                    corrected_base_final = total_raw
                    corrected_hol_final = 0
                    corrected_inc_final = 0
                }

                else if (basevol_raw < 0) {
                    corrected_base_final = 0
                    let interim_holiday = hol_raw + basevol_raw
                    if (interim_holiday < 0) {
                        corrected_hol_final = 0
                        corrected_inc_final = total_raw
                    }
                    else if (interim_holiday > total_raw) {
                        corrected_hol_final = total_raw
                        corrected_inc_final = 0
                    }
                    else {
                        corrected_hol_final = interim_holiday
                        corrected_inc_final = inc_raw
                    }
                }

                else {
                    corrected_base_final = basevol_raw
                    if (hol_raw < 0) {
                        corrected_hol_final = 0
                        corrected_inc_final = inc_raw + hol_raw
                    }
                    else if (inc_raw < 0) {
                        corrected_hol_final = inc_raw + hol_raw
                        corrected_inc_final = 0
                    }
                    else {
                        corrected_hol_final = hol_raw
                        corrected_inc_final = inc_raw
                    }
                }


                var basetouse_corrected = corrected_base_final
                var inctouse_corrected = corrected_hol_final + corrected_inc_final

                let subbasevar_check = (corrected_base_final - (totalvol - promounits))

                if (subbasevar_check < 0) {

                    var unsubbasevar = corrected_base_final
                    var subbasevar = 0
                }

                else {

                    var unsubbasevar = (corrected_base_final - subbasevar_check)
                    var subbasevar = subbasevar_check
                }

                var promounits = (corrected_inc_final + corrected_hol_final) + subbasevar //row.unitsonpromo * equiv
                var nonpromounits = totalvol - promounits
                var totaldollars = row.avgasp * totalvol
                var basedollars = corrected_base_final * row.avgbp
                var incdollars = (corrected_inc_final + corrected_hol_final) * row.avgasp
                var promodollars = row.dollarsonpromo
                var nonpromodollars = totaldollars - promodollars
                var weighted_dollars_nonpromo_var = avgbp * nonpromounits
                var weighted_dollars_promo_var = (avgbp * (1 - avgdisc) * (promounits))
                var weighted_dollars_incremental_var = (avgbp * (1 - avgdisc) * inctouse_corrected);
                var weighted_dollars_base_var = avgbp * corrected_base_final
                var weighted_dollars_total_var = avgasp * totalvol
                var weighted_baseprice_var = avgbp * totalvol
                var disc_dollars_var = avgbp * promounits * avgdisc

                //Determine the discount bucket
                if (avgdisc < this.state.discmaxes[0]) {
                    var discbucket = this.state.discount_buckets[0]
                }

                else if (avgdisc < this.state.discmaxes[1]) {
                    var discbucket = this.state.discount_buckets[1]
                }

                else if (avgdisc < this.state.discmaxes[2]) {
                    var discbucket = this.state.discount_buckets[2]
                }

                else if (avgdisc < this.state.discmaxes[3]) {
                    var discbucket = this.state.discount_buckets[3]
                }
                else {
                    var discbucket = this.state.discount_buckets[4]
                }

                if (userow_g1 === true) {

                    rownums.push(idx)
                    weighted_dollars_inc_g1 += weighted_dollars_incremental_var
                    weighted_dollars_nonpromo_g1 += weighted_dollars_nonpromo_var
                    weighted_dollars_promo_g1 += weighted_dollars_promo_var
                    weighted_dollars_disc_g1 += disc_dollars_var
                    total_dollars_g1 += weighted_dollars_total_var
                    promounits_g1 += promounits
                    nonpromounits_g1 += nonpromounits
                    incunits_g1 += inctouse_corrected
                    totalunits_g1 += totalvol
                    subbaseunits_g1 += subbasevar
                    //Create Weekly Objects

                    if (Object.keys(hol_data_by_weekly_g1).includes(rowdate)) {
                        hol_data_by_weekly_g1[rowdate] += corrected_hol_final
                    }
                    else {
                        hol_data_by_weekly_g1[rowdate] = corrected_hol_final;
                    }

                    if (Object.keys(incremental_g1_weekly).includes(rowdate)) {
                        incremental_g1_weekly[rowdate] += inctouse_corrected
                    }
                    else {
                        incremental_g1_weekly[rowdate] = inctouse_corrected
                    }

                    if (Object.keys(weighted_dollars_nonpromo_g1_weekly).includes(rowdate)) {
                        weighted_dollars_nonpromo_g1_weekly[rowdate] += weighted_dollars_nonpromo_var
                    }
                    else {
                        weighted_dollars_nonpromo_g1_weekly[rowdate] = weighted_dollars_nonpromo_var
                    }

                    if (Object.keys(weighted_dollars_incremental_g1_weekly).includes(rowdate)) {
                        weighted_dollars_incremental_g1_weekly[rowdate] += weighted_dollars_incremental_var
                    }
                    else {
                        weighted_dollars_incremental_g1_weekly[rowdate] = weighted_dollars_incremental_var
                    }

                    if (Object.keys(promounits_g1_weekly).includes(rowdate)) {
                        promounits_g1_weekly[rowdate] += promounits
                    }
                    else {
                        promounits_g1_weekly[rowdate] = promounits
                    }

                    if (Object.keys(subbaseunits_g1_weekly).includes(rowdate)) {
                        subbaseunits_g1_weekly[rowdate] += subbasevar
                    }
                    else {
                        subbaseunits_g1_weekly[rowdate] = subbasevar
                    }
                    

                    if (Object.keys(nonpromounits_g1_weekly).includes(rowdate)) {
                        nonpromounits_g1_weekly[rowdate] += nonpromounits
                    }
                    else {
                        nonpromounits_g1_weekly[rowdate] = nonpromounits
                    }

                    if (Object.keys(totalunits_g1_weekly).includes(rowdate)) {
                        totalunits_g1_weekly[rowdate] += totalvol
                    }
                    else {
                        totalunits_g1_weekly[rowdate] = totalvol
                    }

                    if (Object.keys(discpct_g1_weekly).includes(rowdate)) {
                        discpct_g1_weekly[rowdate] += totalvol * avgdisc
                    }
                    else {
                        discpct_g1_weekly[rowdate] = totalvol * avgdisc
                    }

                    if (Object.keys(total_dollars_weekly_g1_me).includes(rowdate)) {
                        total_dollars_weekly_g1_me[rowdate] += weighted_dollars_total_var
                    }
                    else {
                        total_dollars_weekly_g1_me[rowdate] = weighted_dollars_total_var
                    }

                    if (Object.keys(total_baseprice_weekly_g1).includes(rowdate)) {
                        total_baseprice_weekly_g1[rowdate] += weighted_baseprice_var
                    }
                    else {
                        total_baseprice_weekly_g1[rowdate] = weighted_baseprice_var
                    }

                    if (Object.keys(disc_dollars_weekly_g1).includes(rowdate)) {
                        disc_dollars_weekly_g1[rowdate] += disc_dollars_var
                    }
                    else {
                        disc_dollars_weekly_g1[rowdate] = disc_dollars_var
                    }

                }

                else if (userow_g2 === true) {
                    weighted_dollars_inc_g2 += weighted_dollars_incremental_var
                    weighted_dollars_nonpromo_g2 += weighted_dollars_nonpromo_var
                    weighted_dollars_promo_g2 += weighted_dollars_promo_var
                    weighted_dollars_disc_g2 += disc_dollars_var
                    total_dollars_g2 += weighted_dollars_total_var
                    promounits_g2 += promounits
                    nonpromounits_g2 += nonpromounits
                    incunits_g2 += inctouse_corrected
                    totalunits_g2 += totalvol
                    subbaseunits_g2 += subbasevar


                    //Create Weekly Objects

                    if (Object.keys(hol_data_by_weekly_g2).includes(rowdate)) {
                        hol_data_by_weekly_g2[rowdate] += corrected_hol_final
                    }
                    else {
                        hol_data_by_weekly_g2[rowdate] = corrected_hol_final;
                    }

                    if (Object.keys(incremental_g2_weekly).includes(rowdate)) {
                        incremental_g2_weekly[rowdate] += inctouse_corrected
                    }
                    else {
                        incremental_g2_weekly[rowdate] = inctouse_corrected
                    }

                    if (Object.keys(weighted_dollars_nonpromo_g2_weekly).includes(rowdate)) {
                        weighted_dollars_nonpromo_g2_weekly[rowdate] += weighted_dollars_nonpromo_var
                    }
                    else {
                        weighted_dollars_nonpromo_g2_weekly[rowdate] = weighted_dollars_nonpromo_var
                    }

                    if (Object.keys(weighted_dollars_incremental_g2_weekly).includes(rowdate)) {
                        weighted_dollars_incremental_g2_weekly[rowdate] += weighted_dollars_incremental_var
                    }
                    else {
                        weighted_dollars_incremental_g2_weekly[rowdate] = weighted_dollars_incremental_var
                    }

                    if (Object.keys(promounits_g2_weekly).includes(rowdate)) {
                        promounits_g2_weekly[rowdate] += promounits
                    }
                    else {
                        promounits_g2_weekly[rowdate] = promounits
                    }

                    if (Object.keys(subbaseunits_g2_weekly).includes(rowdate)) {
                        subbaseunits_g2_weekly[rowdate] += subbasevar
                    }
                    else {
                        subbaseunits_g2_weekly[rowdate] = subbasevar
                    }

                    if (Object.keys(nonpromounits_g2_weekly).includes(rowdate)) {
                        nonpromounits_g2_weekly[rowdate] += nonpromounits
                    }
                    else {
                        nonpromounits_g2_weekly[rowdate] = nonpromounits
                    }

                    if (Object.keys(totalunits_g2_weekly).includes(rowdate)) {
                        totalunits_g2_weekly[rowdate] += totalvol
                    }
                    else {
                        totalunits_g2_weekly[rowdate] = totalvol
                    }

                    if (Object.keys(discpct_g2_weekly).includes(rowdate)) {
                        discpct_g2_weekly[rowdate] += totalvol * avgdisc
                    }
                    else {
                        discpct_g2_weekly[rowdate] = totalvol * avgdisc
                    }

                    if (Object.keys(total_dollars_weekly_g2_me).includes(rowdate)) {
                        total_dollars_weekly_g2_me[rowdate] += weighted_dollars_total_var
                    }
                    else {
                        total_dollars_weekly_g2_me[rowdate] = weighted_dollars_total_var
                    }

                    if (Object.keys(total_baseprice_weekly_g2).includes(rowdate)) {
                        total_baseprice_weekly_g2[rowdate] += weighted_baseprice_var
                    }
                    else {
                        total_baseprice_weekly_g2[rowdate] = weighted_baseprice_var
                    }

                    if (Object.keys(disc_dollars_weekly_g2).includes(rowdate)) {
                        disc_dollars_weekly_g2[rowdate] += disc_dollars_var
                    }
                    else {
                        disc_dollars_weekly_g2[rowdate] = disc_dollars_var
                    }

                }

                if (userow_total === true) {
                    totalincunits += inctouse_corrected
                    if (Object.keys(totalincunits_weekly).includes(rowdate)) {
                        totalincunits_weekly[rowdate] += inctouse_corrected
                    }
                    else {
                        totalincunits_weekly[rowdate] = inctouse_corrected
                    }
                }
            })

            console.log('Comparison Ran')

            if (this.state.numcomparisonsran > 0 && fromwhere == 'runcomp') {
                var showcompbar = true
            }
            else {
                var showcompbar = false
            }
            //console.log('Num Rows G1: ' + numrows_g1 + ' Num Rows G2: ' + numrows_g2 + ' Num Rows Else: ' + numrows_else)

            //Set Highcharts trace
            var graph_trace = []
            var graph_trace_trended = []
            var graph_trace_index = []
            var graph_trace_index_overunder = []
            if (metric === 'Payback Ratio') {

                if (promounits_g1 !== 0) {
                    var y_g1 = weighted_dollars_inc_g1 / weighted_dollars_disc_g1

                }
                else {
                    var y_g1 = 0
                }

                if (promounits_g2 !== 0) {
                    var y_g2 = weighted_dollars_inc_g2 / weighted_dollars_disc_g2
                }
                else {
                    var y_g2 = 0
                }

                graph_trace = [
                    {
                        name: 'Payback Ratio',
                        data: [[g1Name, y_g1], [g2Name, y_g2]],
                        type: 'column',
                        xAxis: 0
                    },
                ]
            }

            else if (metric === 'Market Lift') {

                if (totalunits_g1 !== 0) {
                    var y_g1 = Math.min(incunits_g1 / (totalunits_g1 - incunits_g1), 10)
                }
                else {
                    var y_g1 = 0
                }

                if (totalunits_g2 !== 0) {
                    var y_g2 = Math.min(incunits_g2 / (totalunits_g2 - incunits_g2), 10)
                }
                else {
                    var y_g2 = 0
                }

                graph_trace = [
                    {
                        name: 'Market Lift',
                        data: [[g1Name, y_g1], [g2Name, y_g2]],
                        type: 'column',
                        xAxis: 0
                    },
                ]
            }

            else if (metric === 'Promoted Lift') {

                if (totalunits_g1 !== 0) {
                    var y_g1 = Math.min(incunits_g1 / (subbaseunits_g1), 10)
                }
                else {
                    var y_g1 = 0
                }

                if (totalunits_g2 !== 0) {
                    var y_g2 = Math.min(incunits_g2 / (subbaseunits_g2), 10)
                }
                else {
                    var y_g2 = 0
                }

                graph_trace = [
                    {
                        name: 'Promoted Lift',
                        data: [[g1Name, y_g1], [g2Name, y_g2]],
                        type: 'column',
                        xAxis: 0
                    },
                ]
            }

            else if (metric === 'Efficiency') {

                if (promounits_g1 !== 0) {
                    var y_g1 = Math.min(incunits_g1 / promounits_g1, 1)
                }
                else {
                    var y_g1 = 0
                }

                if (promounits_g2 !== 0) {
                    var y_g2 = Math.min(incunits_g2 / promounits_g2, 1)
                }
                else {
                    var y_g2 = 0
                }

                graph_trace = [
                    {
                        name: 'Efficiency',
                        data: [[g1Name, y_g1], [g2Name, y_g2]],
                        type: 'column',
                        xAxis: 0
                    },
                ]
            }

            else if (metric === 'Share of Incremental') {

                if (promounits_g1 !== 0) {
                    var y_g1 = Math.min(incunits_g1 / totalincunits, 1)
                }
                else {
                    var y_g1 = 0
                }

                if (promounits_g2 !== 0) {
                    var y_g2 = Math.min(incunits_g2 / totalincunits, 1)
                }
                else {
                    var y_g2 = 0
                }

                graph_trace = [
                    {
                        name: 'Share of Incremental',
                        data: [[g1Name, y_g1], [g2Name, y_g2]],
                        type: 'column',
                        xAxis: 0
                    },
                ]
            }

            var metric_trended_2_reverse = false
            if (metric_trended === 'Payback Ratio and Discount Depth Weekly') {
                metric_trended_1 = 'Payback Ratio'
                metric_trended_2 = 'Discount Depth'
                metric_trended_2_reverse = true
                graph_trace_trended = [
                    {
                        name: g1Name + ' - Discount Depth',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), discpct_g1_weekly[date] / totalunits_g1_weekly[date]]),
                        xAxis: 0,
                        yAxis: 1,
                        stack: 'dd',
                        pointWidth: 30,
                        type: 'column',
                        color: '#7d32ba'
                    },
                    {
                        name: g2Name +' - Discount Depth',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), discpct_g2_weekly[date] / totalunits_g2_weekly[date]]),
                        xAxis: 0,
                        yAxis: 1,
                        stack: 'dd',
                        pointWidth: 15,
                        type: 'column',
                        color: '#000000'
                    },
                    {
                        name: g1Name +' - Payback Ratio',
                        data: (this.state.distinctdates).sort().map(date => {
                            let payback_ratio = 0

                            if (discpct_g1_weekly[date] / totalunits_g1_weekly[date] >= .01) {
                                payback_ratio = weighted_dollars_incremental_g1_weekly[date] /
                                    (promounits_g1_weekly[date] *
                                        (
                                            (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) *
                                            (discpct_g1_weekly[date] / totalunits_g1_weekly[date])
                                        )
                                    )
                            }

                            else {
                                payback_ratio = 0
                            }

                            return [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), payback_ratio]
                        }),
                        xAxis: 0,
                        yAxis: 0,
                        stack: 'pr',
                        pointWidth: 30,
                        type: 'column',
                        color: '#ff8b6a'
                    },
                    {
                        name: g2Name +' - Payback Ratio',
                        data: (this.state.distinctdates).sort().map(date => {
                            let payback_ratio = 0
                            if (discpct_g2_weekly[date] / totalunits_g2_weekly[date] >= .01) {
                                payback_ratio = weighted_dollars_incremental_g2_weekly[date] /
                                    (promounits_g2_weekly[date] *
                                        (
                                            (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]) *
                                            (discpct_g2_weekly[date] / totalunits_g2_weekly[date])
                                        )
                                    )
                            }

                            else {
                                payback_ratio = 0
                            }

                            return [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), payback_ratio]
                        }),
                        xAxis: 0,
                        yAxis: 0,
                        stack: 'pr',
                        pointWidth: 15,
                        type: 'column',
                        color: '#565a5c'
                    },
                ]
            }
            
            else if (metric_trended === 'Average Discount Weekly') {
                console.log(discpct_g1_weekly)
                console.log(totalunits_g1_weekly)
                metric_trended_1 = ''
                metric_trended_2 = 'Discount Depth'
                graph_trace_trended = [
                    {
                        name: g1Name +' - Discount Depth',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), discpct_g1_weekly[date] / totalunits_g1_weekly[date]]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g2Name +' - Discount Depth',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), discpct_g2_weekly[date] / totalunits_g2_weekly[date]]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#000000'
                    },
                ]
            }

            else if (metric_trended === 'Market Lift Weekly') {
                metric_trended_1 = ''
                metric_trended_2 = 'Market Lift'
                graph_trace_trended = [
                    {
                        name: g1Name +' - Market Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date]), 10)]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g2Name +' - Market Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g2_weekly[date] / (totalunits_g2_weekly[date] - incremental_g2_weekly[date]), 10)]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#000000'
                    },
                ]
            }

            else if (metric_trended === 'Promoted Lift Weekly') {
                metric_trended_1 = ''
                metric_trended_2 = 'Promoted Lift'
                graph_trace_trended = [
                    {
                        name: g1Name +' - Promoted Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date]), 10)]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#b0df97'
                    },
                    {
                        name: g2Name +' - Promoted Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g2_weekly[date] / (subbaseunits_g2_weekly[date]), 10)]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#000000'
                    },
                ]
            }

            else if (metric_trended === 'Efficiency Weekly') {
                metric_trended_1 = ''
                metric_trended_2 = 'Efficiency'
                graph_trace_trended = [
                    {
                        name: g1Name +' - Efficiency',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g2Name +' - Efficiency',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g2_weekly[date] / (promounits_g2_weekly[date]), 1)]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#000000'
                    },
                ]
            }

            else if (metric_trended === 'Share of Incremental Weekly') {

                metric_trended_1 = ''
                metric_trended_2 = 'Share of Incremental'
                graph_trace_trended = [
                    {
                        name: g1Name +' - Share of Incremental',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (incremental_g1_weekly[date] / totalincunits_weekly[date])]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g2Name +' - Share of Incremental',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (incremental_g2_weekly[date] / totalincunits_weekly[date])]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'spline',
                        color: '#000000'
                    },
                ]
            }

            //Add Additional Metrics Here....

            else if (metric_trended === 'Average Selling Price Trended') {
                metric_trended_1 = ''
                metric_trended_2 = 'Average Selling Price'
                graph_trace_trended = [
                    {
                        name: g1Name +' - Average Selling Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date])]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g2Name +' - Average Selling Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_dollars_weekly_g2_me[date] / totalunits_g2_weekly[date])]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'spline',
                        color: '#000000'
                    },
                ]
            }

            else if (metric_trended === 'Base Price Trended') {
                metric_trended_1 = ''
                metric_trended_2 = 'Base Price'
                graph_trace_trended = [
                    {
                        name: g1Name +' - Base Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g2Name +' - Base Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date])]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'spline',
                        color: '#000000'
                    },
                ]
            }

            else if (metric_trended === 'ASP Gap (Group 1 minus Group 2) Trended') {
                metric_trended_1 = ''
                metric_trended_2 = 'Average Selling Price Gap'
                graph_trace_trended = [
                    {
                        name: 'Average Selling Price Gap - Group 1 minus Group 2',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                                ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]) - (total_dollars_weekly_g2_me[date] / totalunits_g2_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g1Name + ' - Average Selling Price',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 3,
                        type: 'spline',
                        color: '#3f3f3f'
                    },
                ]
            }

            else if (metric_trended === 'Base Price Gap (Group 1 minus Group 2) Trended') {
                metric_trended_1 = ''
                metric_trended_2 = 'Base Price Gap'
                graph_trace_trended = [
                    {
                        name: 'Base Price Gap - Group 1 minus Group 2',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'spline',
                        color: '#7d32ba'
                    },
                    {
                        name: g1Name + ' - Base Price',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 3,
                        type: 'spline',
                        color: '#3f3f3f'
                    },
                ]
            }


            //Look and See if Index is Dollars or Units...
            var unitsdollars_final = unitsdollars 
            console.log(unitsdollars_final)
            if (unitsdollars_final === undefined) {
                unitsdollars_final = this.state.indexSwitchCheckDollars
            }

            if (unitsdollars_final) {
                try {
                    total_dollars_me = Object.values(total_dollars_weekly_g1_me).reduce(reducer)
                    total_dollars_g2 = Object.values(total_dollars_weekly_g2_me).reduce(reducer)
                    my_share = total_dollars_me / (total_dollars_g2 + total_dollars_me)
                }
                catch (error) {
                    total_dollars_me = 0
                    my_share = 0
                }
            }
            else {
                try {
                    total_dollars_me = Object.values(totalunits_g1_weekly).reduce(reducer)
                    total_dollars_g2 = Object.values(totalunits_g2_weekly).reduce(reducer)
                    my_share = total_dollars_me / (total_dollars_g2 + total_dollars_me)
                }
                catch (error) {
                    total_dollars_me = 0
                    my_share = 0
                }
            }

            /*
            try {
                var total_dollars_me = Object.values(total_dollars_weekly_g1_me).reduce(reducer)
                var total_dollars_g2 = Object.values(total_dollars_weekly_g2_me).reduce(reducer)
                var my_share = total_dollars_me / (total_dollars_g2 + total_dollars_me)
            }
            catch (error) {
                var total_dollars_me = 0
                var total_dollars_else = 0
                var my_share = 0
            }
            */
            //Look and See if Index is Dollars or Units...
            if (unitsdollars_final) {
                graph_trace_index = [
                    {
                        name: g1Name + ' - Dollar Share Index',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        (total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share
                        ]),
                        xAxis: 0,
                        yAxis: 0,
                        pointWidth: 20,
                        type: 'column'
                    },
                    {
                        name: g1Name + ' - Average Dollar Share',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            1
                        ]),
                        xAxis: 0,
                        yAxis: 0,
                        type: 'line',
                        color: '#fdd900'
                    },
                ]

                graph_trace_index_overunder = [
                    {
                        name: g1Name + ' - Over/Under Dollar Share',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share) - 1
                        ]),
                        xAxis: 0,
                        yAxis: 0,
                        pointWidth: 20,
                        type: 'column'
                    },
                ]
            }
            else {
                graph_trace_index = [
                    {
                        name: g1Name + ' - ' + this.state.unitsvar + ' Share Index',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        (totalunits_g1_weekly[date] / (totalunits_g2_weekly[date] + totalunits_g1_weekly[date])) / my_share
                        ]),
                        xAxis: 0,
                        yAxis: 0,
                        pointWidth: 20,
                        type: 'column'
                    },
                    {
                        name: g1Name + ' - Average ' + this.state.unitsvar + ' Share',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            1
                        ]),
                        xAxis: 0,
                        yAxis: 0,
                        type: 'line',
                        color: '#fdd900'
                    },
                ]

                graph_trace_index_overunder = [
                    {
                        name: g1Name + ' - Over/Under ' + this.state.unitsvar + ' Share',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((totalunits_g1_weekly[date] / (totalunits_g2_weekly[date] + totalunits_g1_weekly[date])) / my_share) - 1
                        ]),
                        xAxis: 0,
                        yAxis: 0,
                        pointWidth: 20,
                        type: 'column'
                    },
                ]
            }

            function create_share_summary_table(dates, dollars, currencycode, metric) {
                let distinctdates = (dates).sort()
                var allmetric = []
                if (metric === 'discount') {
                    allmetric = (dates).sort().map(date => {
                        return Math.min((discpct_g1_weekly[date] / totalunits_g1_weekly[date]), 1)
                    })
                }
                else if (metric === 'marketlift') {
                    allmetric = (dates).sort().map(date => {
                        return Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10)
                    })
                }

                else if (metric === 'promolift') {
                    allmetric = (dates).sort().map(date => {
                        return Math.min((incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date])), 10)
                    })
                }

                else if (metric === 'efficiency') {
                    allmetric = (dates).sort().map(date => {
                        return Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)
                    })
                }

                else if (metric === 'asp') {
                    allmetric = (dates).sort().map(date => {
                        return (total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date])
                    })
                }

                else if (metric === 'bp') {
                    allmetric = (dates).sort().map(date => {
                        return (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])
                    })
                }

                else if (metric === 'aspgap') {
                    allmetric = (dates).sort().map(date => {
                        return ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]) - (total_dollars_weekly_g2_me[date] / totalunits_g2_weekly[date]))
                    })
                }

                else if (metric === 'bpgap') {
                    allmetric = (dates).sort().map(date => {
                        return ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                    })
                }

                let metric_min = Math.min(...allmetric)
                let metric_max = Math.max(...allmetric)
                let metricrange = metric_max - metric_min
                let metricstep = metricrange / 5
                let metricbands = []
                let metriccount = metric_min
                let sharelookup = {}
                let metricbandlabels = []

                while (metriccount < metric_max) {
                    metricbands.push([metriccount, metriccount + metricstep])
                    metriccount += metricstep
                }

                console.log(metricbands)
                metricbands.forEach(band => {
                    console.log(band)
                    var label
                    if (metric === 'discount' || metric === 'marketlift' || metric === 'promolift' || metric === 'efficiency') {
                        label = band[0].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            + ' to '
                            + band[1].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    }
                    else {
                        label = band[0].toLocaleString(undefined, { style: 'currency', currency: currencycode })
                            + ' to '
                            + band[1].toLocaleString(undefined, { style: 'currency', currency: currencycode })
                    }
                    metricbandlabels.push(label)
                })

                console.log(metricbandlabels)
                distinctdates.forEach(date => {
                    //console.log(date)
                    var share
                    var metrictouse
                    if (dollars) {
                        share = ((total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share)
                    }
                    else {
                        share = ((totalunits_g1_weekly[date] / (totalunits_g2_weekly[date] + totalunits_g1_weekly[date])) / my_share)
                    }

                    if (metric === 'discount') {
                        metrictouse = Math.min((discpct_g1_weekly[date] / totalunits_g1_weekly[date]), 1)
                    }

                    else if (metric === 'marketlift') {
                        metrictouse = Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10)
                    }

                    else if (metric === 'promolift') {
                        metrictouse = Math.min((incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date])), 10)
                    }

                    else if (metric === 'efficiency') {
                        metrictouse = Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)
                    }

                    else if (metric === 'asp') {
                        metrictouse = (total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date])
                    }

                    else if (metric === 'bp') {
                        metrictouse = (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])
                    }

                    else if (metric === 'aspgap') {
                        metrictouse = ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]) - (total_dollars_weekly_g2_me[date] / totalunits_g2_weekly[date]))
                    }

                    else if (metric === 'bpgap') {
                        metrictouse = ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                    }

                    console.log(share)
                    console.log(metrictouse)
                    //Calculate Discount Band...
                    metricbands.forEach((band, idx) => {
                        console.log(band)
                        console.log(metrictouse)
                        if (metrictouse >= band[0] && metrictouse <= band[1]) {
                            sharelookup[metricbandlabels[idx]] = sharelookup[metricbandlabels[idx]] ||
                            {
                                'numweeks': 0,
                                'totalshare': 0
                            }

                            sharelookup[metricbandlabels[idx]]['numweeks'] += 1
                            sharelookup[metricbandlabels[idx]]['totalshare'] += share
                        }
                    })
                })

                console.log(sharelookup)
                return sharelookup
            }
            /*
            graph_trace_index = [
                {
                    name: g1Name +' - Dollar Share Index',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    (total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    pointWidth: 20,
                    type: 'column'
                },
                {
                    name: g1Name +' - Average Dollar Share',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        1
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    type: 'line',
                    color: '#0078bf'
                },
            ]

            graph_trace_index_overunder = [
                {
                    name: g1Name +' - Over/Under Dollar Share',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    ((total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share) - 1
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    pointWidth: 20,
                    type: 'column'
                },
            ]
            */
            if (overlay === 'Average Discount') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Average Discount',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        discpct_g1_weekly[date] / totalunits_g1_weekly[date]
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },

                )
                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Average Discount',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        discpct_g1_weekly[date] / totalunits_g1_weekly[date]
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )
            }

            else if (overlay === 'Market Lift') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Market Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10)
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Market Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10)
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )
            }

            else if (overlay === 'Promoted Lift') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Promoted Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            Math.min((incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date])), 10)
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#b0df97'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Promoted Lift',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            Math.min((incremental_g1_weekly[date] / (subbaseunits_g2_weekly[date])), 10)
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#b0df97'
                    },
                )
            }

            else if (overlay === 'Efficiency') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Efficiency',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Efficiency',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )
            }

            else if (overlay === 'Share of Incremental') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Share of Incremental',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        incremental_g1_weekly[date] / totalincunits_weekly[date]
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Share of Incremental',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        incremental_g1_weekly[date] / totalincunits_weekly[date]
                        ]),
                        xAxis: 0,
                        yAxis: 1,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )
            }

           
            //Add Additional Metrics Here

            else if (overlay === 'Average Selling Price') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Average Selling Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            (total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date])
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Average Selling Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            (total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date])
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )
            }

            else if (overlay === 'Base Price') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Base Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Base Price',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                )
            }

            else if (overlay === 'ASP Gap (Group 1 minus Group 2)') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - ASP Gap',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]) - (total_dollars_weekly_g2_me[date] / totalunits_g2_weekly[date]))
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                    {
                        name: g1Name + ' - Average Selling Price',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 3,
                        type: 'spline',
                        color: '#565a5c'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - ASP Gap',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]) - (total_dollars_weekly_g2_me[date] / totalunits_g2_weekly[date]))
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                    {
                        name: g1Name + ' - Average Selling Price',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                                ((total_dollars_weekly_g1_me[date] / totalunits_g1_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 3,
                        type: 'spline',
                        color: '#565a5c'
                    },
                )
            }

            else if (overlay === 'Base Price Gap (Group 1 minus Group 2)') {
                graph_trace_index.push(
                    {
                        name: g1Name +' - Base Price Gap',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                    {
                        name: g1Name + ' - Base Price',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 3,
                        type: 'spline',
                        color: '#565a5c'
                    },
                )

                graph_trace_index_overunder.push(
                    {
                        name: g1Name +' - Base Price Gap',
                        data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                        ]),
                        xAxis: 0,
                        yAxis: 2,
                        type: 'line',
                        color: '#ff8b6a'
                    },
                    {
                        name: g1Name + ' - Base Price',
                        data: (this.state.distinctdates).sort().map(date =>
                            [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                            ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                            ]),
                        xAxis: 0,
                        yAxis: 3,
                        type: 'spline',
                        color: '#565a5c'
                    },
                )
            }

            this.setState({
                MetricToCompare: metric,
                MetricToCompare_trended: metric_trended,
                MetricToCompare_trended_1: metric_trended_1,
                MetricToCompare_trended_2: metric_trended_2,
                metric_trended_2_reverse: metric_trended_2_reverse,
                compareGraphData: graph_trace,
                compareIndexGraphData: graph_trace_index,
                compareIndexGraphDataOverUnder: graph_trace_index_overunder,
                compareGraphData_trended: graph_trace_trended,
                totalunits_g1_weekly,
                totalunits_g2_weekly,
                total_dollars_weekly_g1_me,
                total_dollars_weekly_g2_me,
                showSnackbar_compran: showcompbar,
                numcomparisonsran: this.state.numcomparisonsran + 1
            })

            if (this.state.indexSwitchCheck) {
                this.setState({ compareIndexGraphData_toview: graph_trace_index_overunder })
            }

            else {
                this.setState({ compareIndexGraphData_toview: graph_trace_index })
            }

        }
    }
    //Evaluate Rows Function
    //
    evaluateRows(reportData, new_filters, asp_range, exclude_array = [false, false], selectedpricebands, numweeks) {
        console.log(new_filters)
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        var t0 = performance.now();
        //evaluate rows...
        var TotalUnits_new = 0
        var TotalUnits_all = 0
        var TotalDollars_new = 0
        var BaseDollars_new = 0
        var IncDollars_new = 0
        var BaseUnits_new = 0
        var IncUnits_new = 0
        var holUnits = 0
        var PromoDollars_new = 0
        var NonPromoDollars_new = 0
        var PromoUnits_new = 0
        var NonPromoUnits_new = 0
        var NonPromoUnits_new = 0
        var attribute_vals = {}
        var numrowsprocessed = 0;
        var discount_bucket_mapping = {}
        var discount_bucket_mapping_iu = {}
        var discount_bucket_mapping_sbu = {}
        var discount_bucket_mapping_bu = {}
        var discount_bucket_mapping_id = {}
        var discount_bucket_mapping_totaldollars = {}
        var discount_bucket_mapping_totalunits = {}
        var discount_bucket_mapping_dd = {}
        var discount_bucket_mapping_brand = {}
        var discount_bucket_mapping_prior = {}
        var discount_bucket_mapping_current = {}

        //Current
        var colname_week_dict = {}
        var colname_week_dict_current = {}
        var voldecomp_by_week = {}
        var base_data_by_week = {}
        var hol_data_by_week = {}
        var inc_data_by_week = {}
        var inc_data_by_week_calcs = {}
        var base_data_by_week_calcs = {}

        var weekly_data = { 'All': {}}

        var hol_data_corrected_graph = {}
        var inc_data_corrected_graph = {}
        var base_data_corrected_graph = {}

        var asp_data_by_week = {}
        var promo_data_by_week = {}
        var nonpromo_data_by_week = {}
        var item_volume_by_week = {}
        var brand_volume_by_week = {}
        var volume_by_item = {}
        var dollars_by_item = {}
        var volume_by_brand = {}
        var dollars_by_brand = {}
        var volume_by_geo = {}
        var disc_pct_by_week = {}
        var disc_pct_by_week_raw = {}
        var bp_by_week = {}
        var disc_pct_by_brand = {}
        var disc_pct_by_item = {}
        var disc_pct_by_priceband = {}
        var weighted_dollars_inc_week = {}
        var weighted_dollars_promo_week = {}
        var weighted_dollars_nonpromo_week = {}
        var total_vol_by_week = {}
        var totalunits = 0;
        var TotalUnitsOnPromo = 0;
        var TotalUnitsOnPromo_all = 0;
        var weighted_dollars_nonpromo = 0;
        var weighted_dollars_promo = 0;
        var weighted_dollars_incremental = 0;
        var revenue_opportunity = 0;
        var subsidized_vol = 0;
        var disc_dollars_curr = 0;
        var disc_dollars_prior = 0;
        //Prior
        var colname_week_dict_prior = {}
        var voldecomp_by_week_prior = {}
        var base_data_by_week_prior = {}
        var inc_data_by_week_prior = {}
        var asp_data_by_week_prior = {}
        var promo_data_by_week_prior = {}
        var item_volume_by_week_prior = {}
        var brand_volume_by_week_prior = {}
        var volume_by_item_prior = {}
        var volume_by_geo_prior = {}
        var disc_pct_by_week_prior = {}
        var total_vol_by_week_prior = {}
        var total_vol_by_week_current = {}
        var totalunits_prior = 0;
        var totaldisc_prior = 0;
        var subbasevar_prior = 0;
        var TotalUnitsOnPromo_prior = 0;
        var weighted_dollars_nonpromo_prior = 0;
        var weighted_dollars_promo_prior = 0;
        var weighted_dollars_incremental_prior = 0;

        //Adding in Calculation for prior period calc in line with PDT.....

        //Calculate the number of days between startdate and end date

        let startdate = new_filters[3].substring(0, 10)
        let startdate_calc = new_filters[3].substring(0, 10)
        let enddate = new_filters[4].substring(0, 10)

        //if startdate is a sunday add 6 to it...
        var startdate_year = parseInt(startdate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set... 
        var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
        var startdate_day = parseInt(startdate.substring(8, 10))
        var startdate_moment = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
        console.log(startdate_moment.day())
        if (startdate_moment.day() === 0) {
            startdate = startdate_moment.subtract(-6, 'd').format('YYYY-MM-DD')
            startdate_calc = startdate
        }

        console.log(startdate)

        let numdaysinperiod = ((new Date(enddate) - new Date(startdate)) / (1000 * 60 * 60 * 24)) + 7

        if (numdaysinperiod <= 365) {
            //calculate priorstart and prior end for YOY calcs
            //This is too simplistic...subtract one year...if the resulting date is a Friday, add one day, Otherwise if Thursday, add two days to get to a Saturday to Saturday comp
            //This might need to change for Europe.  We might need to do a Sunday instead of Saturday for the end date...
            //In that case, we will replace the 6 below with the dow from end date...

            var enddate_dow = new Date(enddate + 'T00:00:00').getDay()
            console.log(enddate_dow)

            var prior_start_s = String(parseInt(startdate.substring(0, 4)) - 1) + "-" + startdate.substring(5, 7) + "-" + startdate.substring(8, 10) + 'T00:00:00'
            var prior_end_s = String(parseInt(enddate.substring(0, 4)) - 1) + "-" + enddate.substring(5, 7) + "-" + enddate.substring(8, 10) + 'T00:00:00'

            var enddate_dow = new Date(enddate + 'T00:00:00').getDay()
            console.log(enddate_dow)

            var prior_start_d = new Date(prior_start_s)
            var prior_end_d = new Date(prior_end_s)

            var prior_start_dow = prior_start_d.getDay()
            var prior_end_dow = prior_end_d.getDay()

            var prior_start_numdays = enddate_dow - prior_start_dow
            var prior_end_numdays = enddate_dow - prior_end_dow

            prior_start_d.setDate(prior_start_d.getDate() + prior_start_numdays)
            prior_end_d.setDate(prior_end_d.getDate() + prior_end_numdays)

            var prior_start = prior_start_d.toISOString().substring(0, 10) //+ 'T00:00:00'
            var prior_end = prior_end_d.toISOString().substring(0, 10) //+ 'T00:00:00'

            if (this.state.datasettimeagg === 'Monthly') {
                prior_start = prior_start_d.toISOString().substring(0, 8) + '01'
            }

            console.log(prior_start)
        } 

        else {
            //calculate priorstart and prior end for YOY calcs
            //This is too simplistic...subtract one year...if the resulting date is a Friday, add one day, Otherwise if Thursday, add two days to get to a Saturday to Saturday comp
            var prior_start_s = String(parseInt(enddate.substring(0, 4)) - 2) + "-" + enddate.substring(5, 7) + "-" + enddate.substring(8, 10) + 'T00:00:00'
            var prior_end_s = String(parseInt(enddate.substring(0, 4)) - 1) + "-" + enddate.substring(5, 7) + "-" + enddate.substring(8, 10) + 'T00:00:00'

            var enddate_dow = new Date(enddate + 'T00:00:00').getDay()

            var prior_start_d = new Date(prior_start_s)
            var prior_end_d = new Date(prior_end_s)

            var prior_start_dow = prior_start_d.getDay()
            var prior_end_dow = prior_end_d.getDay()

            var prior_start_numdays = enddate_dow - prior_start_dow
            var prior_end_numdays = enddate_dow - prior_end_dow


            prior_start_d.setDate(prior_start_d.getDate() + prior_start_numdays + 7)
            prior_end_d.setDate(prior_end_d.getDate() + prior_end_numdays)

            if (this.state.datasettimeagg !== 'Monthly') {
                if (prior_start_d.toISOString().substring(0, 10) < startdate) {
                    var prior_start = prior_start_d.toISOString().substring(0, 10) //startdate
                }
                else {
                    var prior_start = prior_start_d.toISOString().substring(0, 10) //+ 'T00:00:00'
                }

            }
            else {
                var prior_start = prior_start_d.toISOString().substring(0, 8) + '01'
            }
            var prior_end = prior_end_d.toISOString().substring(0, 10) //+ 'T00:00:00'

            //Change start for dates.... 
            //Be More Explicit in setting date here due to timezones
            let dt = new Date()
            let offset = dt.getTimezoneOffset() * 60 * 1000
            var startdate_new_raw = new Date(new Date(enddate) - (365 * 1000 * 60 * 60 * 24))
            var startdate_new = new Date(startdate_new_raw - offset)
            var startdate_new_dow = startdate_new.getDay()
            var startdate_new_numdays = enddate_dow - startdate_new_dow
            if (startdate_new_dow !== enddate_dow) {
                startdate_new.setDate(startdate_new.getDate() + startdate_new_numdays) //+ 6 + (startdate_new_numdays))
            }

            var startdate_new_final = startdate_new.toISOString().substring(0, 10) //+ 'T00:00:00'
            if (this.state.datasettimeagg !== 'Monthly') {
                if (numweeks === 1) {
                    startdate_calc = startdate_new_final
                }
                else {
                    let dateconv = new Date(startdate + 'T00:00:00Z')
                    let newdate = dateconv.toISOString().substring(0, 10)
                    while (newdate <= startdate_new_final) {
                        let dateconv = new Date(newdate + 'T00:00:00Z')
                        newdate = new Date(dateconv.setTime(dateconv.getTime() + (7 * numweeks * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)
                    }
                    startdate_calc = newdate
                }
            }
            else {
                startdate_calc = startdate_new_final.substring(0, 8) + '01'
            }
        }

        console.log(startdate_calc)
        console.log(enddate)
        console.log(prior_start)
        console.log(prior_end)
        startdate = startdate_calc

        //Create Title Objects...

        function createformatteddatestring(datestring) {
            //create UTC
            let UTCdatestring = Date.UTC(parseInt(datestring.substring(0, 4)), parseInt(datestring.substring(5, 7)) - 1, parseInt(datestring.substring(8, 10)) + 1)            
            let datex_raw = new Date(UTCdatestring)
            let tz_offset = datex_raw.getTimezoneOffset()
            let datex = new Date(UTCdatestring - (tz_offset * 60))
            //return formatted datestring
            return datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
        }

        var currperiodstart_caption = createformatteddatestring(startdate)
        var currperiodend_caption = createformatteddatestring(enddate)
        var prevperiodstart_caption = createformatteddatestring(prior_start)
        var prevperiodend_caption = createformatteddatestring(prior_end)

        //Adding in grouped dates as a test...
        let groupeddates = [startdate]
        let dateconvgrouped = new Date(startdate + 'T00:00:00Z')
        let newdategrouped = new Date(dateconvgrouped.setTime(dateconvgrouped.getTime() + (7 * numweeks * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates

        //make a mapping to make the dates in the group equal to the date...
        let datemapping = {}
        datemapping[startdate] = startdate
        let dateconvstart = new Date(startdate + 'T00:00:00Z')
        let newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
        //console.log(newdategrouped_mapping)

        while (newdategrouped_mapping < newdategrouped) {
            datemapping[newdategrouped_mapping] = startdate
            newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
        }

        while (newdategrouped <= enddate) {
            groupeddates.push(newdategrouped)
            datemapping[newdategrouped] = newdategrouped
            let timeref = newdategrouped
            let dateconvstart = new Date(newdategrouped + 'T00:00:00Z')

            dateconvgrouped = new Date(newdategrouped + 'T00:00:00Z')
            newdategrouped = new Date(dateconvgrouped.setTime(dateconvgrouped.getTime() + (7 * numweeks * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)

            let newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates

            while (newdategrouped_mapping < newdategrouped) {
                datemapping[newdategrouped_mapping] = timeref
                newdategrouped_mapping = new Date(dateconvstart.setTime(dateconvstart.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
            }
        }

        //Adding in grouped dates as a test...
        let groupeddates_prior = [prior_start.substring(0,10)]
        let dateconvgrouped_prior = new Date(prior_start.substring(0, 10) + 'T00:00:00Z')
        let newdategrouped_prior = new Date(dateconvgrouped_prior.setTime(dateconvgrouped_prior.getTime() + (7 * numweeks * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates

        //make a mapping to make the dates in the group equal to the date...
        datemapping[prior_start.substring(0, 10)] = prior_start.substring(0, 10)
        let dateconvstart_prior = new Date(prior_start.substring(0, 10) + 'T00:00:00Z')
        let newdategrouped_mapping_prior = new Date(dateconvstart_prior.setTime(dateconvstart_prior.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
        //console.log(newdategrouped_mapping_prior)

        while (newdategrouped_mapping_prior < newdategrouped_prior) {
            datemapping[newdategrouped_mapping_prior] = prior_start.substring(0, 10)
            newdategrouped_mapping_prior = new Date(dateconvstart_prior.setTime(dateconvstart_prior.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
        }

        while (newdategrouped_prior <= prior_end.substring(0, 10)) {
            groupeddates_prior.push(newdategrouped_prior)
            datemapping[newdategrouped_prior] = newdategrouped_prior
            let timeref = newdategrouped_prior
            let dateconvstart_prior = new Date(newdategrouped_prior + 'T00:00:00Z')

            dateconvgrouped_prior = new Date(newdategrouped_prior + 'T00:00:00Z')
            newdategrouped_prior = new Date(dateconvgrouped_prior.setTime(dateconvgrouped_prior.getTime() + (7 * numweeks * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)

            let newdategrouped_mapping_prior = new Date(dateconvstart_prior.setTime(dateconvstart_prior.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates

            while (newdategrouped_mapping_prior < newdategrouped_prior) {
                datemapping[newdategrouped_mapping_prior] = timeref
                newdategrouped_mapping_prior = new Date(dateconvstart_prior.setTime(dateconvstart_prior.getTime() + (7 * 1 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10) //starting with groups of 4 dates
            }
        }

        console.log(datemapping)

        fetch(Constant.baseapiurl + "Datasets/" + this.state.datatable + "/Parameters?geos=" + new_filters[0])
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                var all_brands_in_geo = result.brands.sort();
                                if (new_filters[1] === 'All') {
                                    var all_brands_in_rpt = this.state.distinctbrands.sort();
                                }
                                else {
                                    var all_brands_in_rpt = new_filters[1].sort()
                                }
                                if (exclude_array[0]) {
                                    if (JSON.stringify(all_brands_in_geo) === JSON.stringify(all_brands_in_rpt)) {
                                        this.setState({ title_brands: 'Total Category', title_brands_detail: 'Total Category Excluding All' })
                                    }

                                    else if (all_brands_in_geo.length < all_brands_in_rpt.length) {
                                        this.setState({ title_brands: 'Total Category', title_brands_detail: 'Total Category Excluding All' })
                                    }

                                    else if (all_brands_in_rpt.length === 1) {
                                        let brand = all_brands_in_rpt[0]
                                        if (brand.substring(0, 3).toLowerCase() === 'all') {
                                            var title_brands_detail = 'Total Category Excluding All'
                                        }
                                        else {
                                            var title_brands_detail = 'Total Category Excluding ' + all_brands_in_rpt[0]
                                        }

                                        this.setState({ title_brands: title_brands_detail, title_brands_detail: title_brands_detail })

                                    }

                                    else {
                                        var brandlist = ''
                                        all_brands_in_rpt.map((brand, idx) => {
                                            if (idx === all_brands_in_rpt.length - 1) {
                                                brandlist += ', and ' + brand
                                            }
                                            else {
                                                brandlist += ', ' + brand
                                            }
                                        })

                                        if (brandlist.length < 100) {
                                            var title_brands_detail = brandlist.substring(1)
                                        }
                                        else {
                                            var title_brands_detail = brandlist.substring(1, 100) + '...'
                                        }
                                        this.setState({ title_brands_detail: 'Total Category Excluding ' + title_brands_detail })
                                    }
                                }
                                else {

                                    if (JSON.stringify(all_brands_in_geo) === JSON.stringify(all_brands_in_rpt)) {
                                        this.setState({ title_brands: 'Total Category', title_brands_detail: 'Total Category' })
                                    }

                                    else if (all_brands_in_geo.length < all_brands_in_rpt.length) {
                                        this.setState({ title_brands: 'Total Category', title_brands_detail: 'Total Category' })
                                    }

                                    else if (all_brands_in_rpt.length === 1) {
                                        let brand = all_brands_in_rpt[0]
                                        if (brand.substring(0, 3).toLowerCase() === 'all') {
                                            var title_brands_detail = 'Total Category'
                                        }
                                        else {
                                            var title_brands_detail = all_brands_in_rpt[0]
                                        }

                                        this.setState({ title_brands_detail: title_brands_detail })

                                    }

                                    else {
                                        var brandlist = ''
                                        all_brands_in_rpt.map((brand, idx) => {
                                            if (idx === all_brands_in_rpt.length - 1) {
                                                brandlist += ', and ' + brand
                                            }
                                            else {
                                                brandlist += ', ' + brand
                                            }
                                        })

                                        if (brandlist.length < 100) {
                                            var title_brands_detail = brandlist.substring(1)
                                        }
                                        else {
                                            var title_brands_detail = brandlist.substring(1, 100) + '...'
                                        }
                                        this.setState({ title_brands: 'Multiple Brands', title_brands_detail: title_brands_detail })
                                    }
                                }

                            }
                        )
                }
                else {

                }
            })

        var distictdatesarray = []
        var volume_total_object = {}
        var volume_by_brand_object = {}
        var volume_by_priceband_object = {}
        var volume_by_item_object = {}
        var basetouse_corrected_tracking = 0
        var inctouse_corrected_tracking = 0
        var eligiblebrandscomp = []
        var eligibleitemscomp = []
        var eligibleitems_filters = []


        var attributes_for_filter = {}
        var allitemsindataset = []
        var suppresseditemsindataset = []
        reportData.map((row, idx) => {
            var equiv = 1 //row['equiv'] || 1 //Removed Based on Feedback
            var userow = false
            var userow_forcalcs = false
            var userow_prior = false
            var suppression = false
            if (row.suppression === 1) {
                suppression = true
            }
            else {
                suppression = false
            }

            let row_attr_val = []
            Object.keys(new_filters[5]).map(attribute => {
                let row_val = row[attribute]
                let attr_vals = Object.values(new_filters[5][attribute]).map((value => value.value))
                if (attr_vals.includes('All')) {
                    row_attr_val.push(true)
                }
                else if (attr_vals.includes(row_val)) {
                    row_attr_val.push(true)
                }
                else {
                    row_attr_val.push(false)
                }
            })

            let adjusted_rowdate = formatDate(adjustDate(new Date(row.date + 'T00:00:00'), this.state.target_dow))

            let attr_match = row_attr_val.reduce(reducer) === row_attr_val.length | row_attr_val.reduce(reducer) === true
            let geo_match = row.geogid == new_filters[0]
            let start_date_match_forviews = adjusted_rowdate.substring(0, 10) >= new_filters[3].substring(0, 10)
            let start_date_match_forcalcs = adjusted_rowdate.substring(0, 10) >= startdate //new_filters[3].substring(0, 10)
            let end_date_match = adjusted_rowdate.substring(0, 10) <= enddate //new_filters[4].substring(0, 10)

            let start_date_prior_match = adjusted_rowdate.substring(0, 10) >= prior_start.substring(0, 10)
            let end_date_prior_match = adjusted_rowdate.substring(0, 10) <= prior_end.substring(0, 10)

            let asp_min_match = row.avgasp >= asp_range[0]
            let asp_max_match = row.avgasp <= asp_range[1]

            var price_band_group = ""
            this.state.priceBandOptions.forEach(band => {
                if (row.avgbp >= band.value[0] && row.avgbp <= band.value[1]) {
                    price_band_group = band.label
                }
            })



            //look and see if price group is in selected price groups

            let selected_price_bands = selectedpricebands.map(band => band.label)
            if (selected_price_bands.includes(price_band_group)) {
                var price_band_match = true
            }
            else {
                price_band_match = false
            }

            //brand_match            
            if (exclude_array[0]) {
                //Exclude Brands
                //If All
                if (new_filters[1].includes('All')) {
                    var brand_match = false
                }
                //Otherwise
                else {
                    //If Not in filters, include
                    if (!new_filters[1].includes(row.brand)) {
                        var brand_match = true
                    }
                    //Otherwise ignore
                    else {
                        var brand_match = false
                    }
                }

            }
            else {
                //Include Brands
                //If All
                if (new_filters[1].includes('All')) {
                    var brand_match = true
                }
                //Otherwise
                else {
                    //If in filters, include
                    if (new_filters[1].includes(row.brand)) {
                        var brand_match = true
                    }
                    //Otherwise ignore
                    else {
                        var brand_match = false
                    }
                }
            }
            //item_match

            if (exclude_array[1]) {
                //Exclude Items
                //If All
                if (new_filters[2].includes('All')) {
                    var item_match = false
                }
                //Otherwise
                else {
                    //If Not in filters, include
                    if (!new_filters[2].includes(row.proddesc)) {
                        var item_match = true
                    }
                    //Otherwise ignore
                    else {
                        var item_match = false
                    }
                }
            }
            else {
                //Include Items                
                //If All
                if (new_filters[2].includes('All')) {
                    var item_match = true
                }
                //Otherwise
                else {
                    //If in filters, include
                    if (new_filters[2].includes(row.proddesc)) {
                        var item_match = true
                    }
                    //Otherwise ignore
                    else {
                        var item_match = false
                    }
                }
            }



            //Determine if the item/brand meets the criteria for the current period
            if (brand_match
                && item_match
                && geo_match
                && attr_match
                && start_date_match_forviews
                && end_date_match
                && asp_min_match
                && asp_max_match
                && price_band_match
            ) {
                userow = true;
                //dump the items into the lists...
                if (!allitemsindataset.includes((row.proddesc))) {
                    allitemsindataset.push(row.proddesc)
                }
                if (!suppresseditemsindataset.includes((row.proddesc)) && suppression === true) {
                    suppresseditemsindataset.push(row.proddesc)
                }

            }

            if (brand_match
                && item_match
                && geo_match
                && attr_match
                && start_date_match_forcalcs
                && end_date_match
                && asp_min_match
                && asp_max_match
                && price_band_match
            ) {
                userow_forcalcs = true;
                //dump the items into the lists...
                if (!allitemsindataset.includes((row.proddesc))) {
                    allitemsindataset.push(row.proddesc)
                }
                if (!suppresseditemsindataset.includes((row.proddesc)) && suppression === true) {
                    suppresseditemsindataset.push(row.proddesc)
                }

            }

            //Determine if the item/brand meets the criteria for the previous period
            if (brand_match
                && item_match
                && geo_match
                && attr_match
                && start_date_prior_match
                && end_date_prior_match
                && asp_min_match
                && asp_max_match
                && price_band_match
            ) {
                userow_prior = true;
            }

            //Determine if the item/brand meets the filters for ASP, Dates, and Attributes....
            if (
                geo_match
                && attr_match
                && start_date_match_forviews
                && end_date_match
                && asp_min_match
                && asp_max_match
                && price_band_match
            ) {
                if (!eligiblebrandscomp.includes(row.brand)) {
                    eligiblebrandscomp.push(row.brand)
                }

                if (!eligibleitemscomp.includes(row.proddesc)) {
                    eligibleitemscomp.push(row.proddesc)
                }

            }

            if (
                brand_match
                && geo_match
                //&& attr_match
                && start_date_match_forviews
                && end_date_match
                && asp_min_match
                && asp_max_match
                && price_band_match
            ) {

                if (!eligibleitems_filters.includes(row.proddesc) && suppression === false) {
                    eligibleitems_filters.push(row.proddesc)
                }



            }

            //If a row matches brand, geo, dates and asps, use its attribute values...
            if (
                brand_match
                && geo_match
                && start_date_match_forviews
                && end_date_match
                && asp_min_match
                && asp_max_match
                && price_band_match
            ) {
                this.state.attribute_col_names.map(attr => {
                    if (Object.keys(attributes_for_filter).includes(attr)) {
                        if (!attributes_for_filter[attr].includes(row[attr])) {
                            attributes_for_filter[attr].push(row[attr])
                            attributes_for_filter[attr].sort()
                        }
                    }
                    else {
                        attributes_for_filter[attr] = [row[attr]]
                    }
                }
                )

            }


            var proddesc = row.proddesc
            var brandname = row.brand
            var avgdisc = row.avgpctdisc
            var avgbp = row.avgbp

            //Create Metrics.....

            //Rework the inc, base, holiday....
            var totalvol = parseFloat(row['_ACTUAL'] * equiv)
            var seasonality_vals = ['dt_LNSEAS1', 'dt_LNSEAS2', 'dt_LNSEAS3',]
            var seasonality_value = 0

            for (var seas = 0; seas < 3; seas++) {
                if (row[seasonality_vals[seas]]) {
                    seasonality_value += parseFloat(row[seasonality_vals[seas]] * equiv)
                }
                else {
                    seasonality_value += 0
                }
            }

            var promounits = row.unitsonpromo * equiv

            var basevol_raw = (((parseFloat(row['dt_CORE']) || 0) + (parseFloat(row['dt_COMPPDISC']) || 0) + (parseFloat(row['dt_CENLNBPU']) || 0)) * equiv) + seasonality_value
            var hol_raw = parseFloat(row['dt_HOLIDAY'] * equiv) || 0
            var total_raw = totalvol
            var inc_raw = total_raw - basevol_raw - hol_raw

            //look at base and correct as needed...
            let corrected_base_final = 0
            let corrected_hol_final = 0
            let corrected_inc_final = 0

            if (basevol_raw > total_raw) {
                corrected_base_final = total_raw
                corrected_hol_final = 0
                corrected_inc_final = 0
            }

            else if (basevol_raw < 0) {
                corrected_base_final = 0
                let interim_holiday = hol_raw + basevol_raw
                if (interim_holiday < 0) {
                    corrected_hol_final = 0
                    corrected_inc_final = total_raw
                }
                else if (interim_holiday > total_raw) {
                    corrected_hol_final = total_raw
                    corrected_inc_final = 0
                }
                else {
                    corrected_hol_final = interim_holiday
                    corrected_inc_final = inc_raw
                }
            }

            else {
                corrected_base_final = basevol_raw
                if (hol_raw < 0) {
                    corrected_hol_final = 0
                    corrected_inc_final = inc_raw + hol_raw
                }
                else if (inc_raw < 0) {
                    corrected_hol_final = inc_raw + hol_raw
                    corrected_inc_final = 0
                }
                else {
                    corrected_hol_final = hol_raw
                    corrected_inc_final = inc_raw
                }
            }

            var basetouse_corrected = corrected_base_final
            var inctouse_corrected = corrected_hol_final + corrected_inc_final
            let subbasevar_check = (corrected_base_final - (totalvol - promounits))

            if (subbasevar_check < 0) {

                var unsubbasevar = corrected_base_final
                var subbasevar = 0
            }

            else {

                var unsubbasevar = (corrected_base_final - subbasevar_check)
                var subbasevar = subbasevar_check
            }

            var promounits = (corrected_inc_final + corrected_hol_final) + subbasevar
            var nonpromounits = totalvol - promounits
            var totaldollars = row.avgasp * totalvol
            var basedollars = corrected_base_final * row.avgbp
            var incdollars = (corrected_inc_final + corrected_hol_final) * row.avgasp
            var promodollars = promounits * row.avgasp
            var nonpromodollars = totaldollars - promodollars
            var disc_dollars = (promounits * row.avgbp * row.avgpctdisc)
            var revenue_opportunity_dollars = subbasevar * (row.avgbp * row.avgpctdisc)
            var weighted_dollars_nonpromo_var = avgbp * nonpromounits
            var weighted_dollars_promo_var = (avgbp * (1 - avgdisc) * (promounits))
            var weighted_dollars_incremental_var = (avgbp * (1 - avgdisc) * (corrected_inc_final + corrected_hol_final));
            //Determine the discount bucket

            //console.log(this.state.discmaxes)
            //console.log(this.state.discbuckets)


            if (avgdisc < this.state.discmaxes[0]) {
                var discbucket = this.state.discount_buckets[0]
            }

            else if (avgdisc < this.state.discmaxes[1]) {
                var discbucket = this.state.discount_buckets[1]
            }

            else if (avgdisc < this.state.discmaxes[2]) {
                var discbucket = this.state.discount_buckets[2]
            }

            else if (avgdisc < this.state.discmaxes[3]) {
                var discbucket = this.state.discount_buckets[3]
            }
            else {
                var discbucket = this.state.discount_buckets[4]
            }

            let rowdate = datemapping[adjusted_rowdate]



            //Put the values in the right places
            if (userow === true) {
                if (!distictdatesarray.includes(rowdate) && rowdate) {
                    distictdatesarray.push(rowdate)                     
                }
                TotalUnits_all += totalvol
                TotalUnitsOnPromo_all += promounits
                revenue_opportunity += revenue_opportunity_dollars
                /*
                TotalUnits_new += totalvol
                TotalDollars_new += totaldollars
                BaseDollars_new += basedollars
                IncDollars_new += incdollars
                BaseUnits_new += corrected_base_final
                IncUnits_new += corrected_inc_final
                holUnits += corrected_hol_final
                PromoDollars_new += promodollars
                NonPromoDollars_new += nonpromodollars
                PromoUnits_new += promounits
                NonPromoUnits_new += nonpromounits
                basetouse_corrected_tracking += corrected_base_final
                inctouse_corrected_tracking += (corrected_inc_final + corrected_hol_final)


                //Weighted Dollars
                weighted_dollars_nonpromo += weighted_dollars_nonpromo_var
                weighted_dollars_promo += weighted_dollars_promo_var
                weighted_dollars_incremental += weighted_dollars_incremental_var

                //Revenue Opportunity
                subsidized_vol += subbasevar
                revenue_opportunity += revenue_opportunity_dollars

                //Discount Dollars
                disc_dollars_curr += disc_dollars
                */
                if (Object.keys(volume_total_object).length > 0) {
                    volume_total_object['totalvolume'] += totalvol
                    volume_total_object['promovol'] += promounits
                    volume_total_object['nonpromovol'] += (totalvol - promounits)
                    volume_total_object['basevol'] += corrected_base_final
                    volume_total_object['holvol'] += corrected_hol_final
                    volume_total_object['incvol'] += corrected_inc_final
                    volume_total_object['subsidizedbase'] += subbasevar
                    volume_total_object['unsubsidizedbase'] += unsubbasevar
                }
                else {
                    volume_total_object['totalvolume'] = totalvol
                    volume_total_object['promovol'] = promounits
                    volume_total_object['nonpromovol'] = (totalvol - promounits)
                    volume_total_object['basevol'] = corrected_base_final
                    volume_total_object['holvol'] = corrected_hol_final
                    volume_total_object['incvol'] = corrected_inc_final
                    volume_total_object['subsidizedbase'] = subbasevar
                    volume_total_object['unsubsidizedbase'] = unsubbasevar
                }

                if (Object.keys(volume_by_brand_object).includes(row.brand)) {
                    volume_by_brand_object[row.brand]['totalvolume'] += totalvol
                    volume_by_brand_object[row.brand]['promovol'] += promounits
                    volume_by_brand_object[row.brand]['nonpromovol'] += (totalvol - promounits)
                    volume_by_brand_object[row.brand]['basevol'] += corrected_base_final
                    volume_by_brand_object[row.brand]['holvol'] += corrected_hol_final
                    volume_by_brand_object[row.brand]['incvol'] += corrected_inc_final
                    volume_by_brand_object[row.brand]['subsidizedbase'] += subbasevar
                    volume_by_brand_object[row.brand]['unsubsidizedbase'] += unsubbasevar
                    volume_by_brand_object[row.brand]['inctouseforcalc'] += (corrected_inc_final + corrected_hol_final)
                    volume_by_brand_object[row.brand]['basetouseforcalc'] += corrected_base_final
                }
                else {
                    volume_by_brand_object[row.brand] = {}
                    volume_by_brand_object[row.brand]['totalvolume'] = totalvol
                    volume_by_brand_object[row.brand]['promovol'] = promounits
                    volume_by_brand_object[row.brand]['nonpromovol'] = (totalvol - promounits)
                    volume_by_brand_object[row.brand]['basevol'] = corrected_base_final
                    volume_by_brand_object[row.brand]['holvol'] = corrected_hol_final
                    volume_by_brand_object[row.brand]['incvol'] = corrected_inc_final
                    volume_by_brand_object[row.brand]['subsidizedbase'] = subbasevar
                    volume_by_brand_object[row.brand]['unsubsidizedbase'] = unsubbasevar
                    volume_by_brand_object[row.brand]['inctouseforcalc'] = (corrected_inc_final + corrected_hol_final)
                    volume_by_brand_object[row.brand]['basetouseforcalc'] = corrected_base_final
                }

                if (Object.keys(volume_by_priceband_object).includes(price_band_group)) {
                    volume_by_priceband_object[price_band_group]['totalvolume'] += totalvol
                    volume_by_priceband_object[price_band_group]['promovol'] += promounits
                    volume_by_priceband_object[price_band_group]['nonpromovol'] += (totalvol - promounits)
                    volume_by_priceband_object[price_band_group]['basevol'] += corrected_base_final
                    volume_by_priceband_object[price_band_group]['holvol'] += corrected_hol_final
                    volume_by_priceband_object[price_band_group]['incvol'] += corrected_inc_final
                    volume_by_priceband_object[price_band_group]['subsidizedbase'] += subbasevar
                    volume_by_priceband_object[price_band_group]['unsubsidizedbase'] += unsubbasevar
                    volume_by_priceband_object[price_band_group]['inctouseforcalc'] += (corrected_inc_final + corrected_hol_final)
                    volume_by_priceband_object[price_band_group]['basetouseforcalc'] += corrected_base_final
                }
                else {
                    volume_by_priceband_object[price_band_group] = {}
                    volume_by_priceband_object[price_band_group]['totalvolume'] = totalvol
                    volume_by_priceband_object[price_band_group]['promovol'] = promounits
                    volume_by_priceband_object[price_band_group]['nonpromovol'] = (totalvol - promounits)
                    volume_by_priceband_object[price_band_group]['basevol'] = corrected_base_final
                    volume_by_priceband_object[price_band_group]['holvol'] = corrected_hol_final
                    volume_by_priceband_object[price_band_group]['incvol'] = corrected_inc_final
                    volume_by_priceband_object[price_band_group]['subsidizedbase'] = subbasevar
                    volume_by_priceband_object[price_band_group]['unsubsidizedbase'] = unsubbasevar
                    volume_by_priceband_object[price_band_group]['inctouseforcalc'] = (corrected_inc_final + corrected_hol_final)
                    volume_by_priceband_object[price_band_group]['basetouseforcalc'] = corrected_base_final
                }

                if (suppression === false) {
                    if (Object.keys(volume_by_item_object).includes(proddesc)) {
                        volume_by_item_object[proddesc]['totalvolume'] += totalvol
                        volume_by_item_object[proddesc]['promovol'] += promounits
                        volume_by_item_object[proddesc]['nonpromovol'] += (totalvol - promounits)
                        volume_by_item_object[proddesc]['basevol'] += corrected_base_final
                        volume_by_item_object[proddesc]['holvol'] += corrected_hol_final
                        volume_by_item_object[proddesc]['incvol'] += corrected_inc_final
                        volume_by_item_object[proddesc]['subsidizedbase'] += subbasevar
                        volume_by_item_object[proddesc]['unsubsidizedbase'] += unsubbasevar
                        volume_by_item_object[proddesc]['inctouseforcalc'] += (corrected_inc_final + corrected_hol_final)
                        volume_by_item_object[proddesc]['basetouseforcalc'] += corrected_base_final
                        volume_by_item_object[proddesc]['weightedbp'] += (totalvol * avgbp)
                    }
                    else {
                        volume_by_item_object[proddesc] = {}
                        volume_by_item_object[proddesc]['totalvolume'] = totalvol
                        volume_by_item_object[proddesc]['promovol'] = promounits
                        volume_by_item_object[proddesc]['nonpromovol'] = (totalvol - promounits)
                        volume_by_item_object[proddesc]['basevol'] = corrected_base_final
                        volume_by_item_object[proddesc]['holvol'] = corrected_hol_final
                        volume_by_item_object[proddesc]['incvol'] = corrected_inc_final
                        volume_by_item_object[proddesc]['subsidizedbase'] = subbasevar
                        volume_by_item_object[proddesc]['unsubsidizedbase'] = unsubbasevar
                        volume_by_item_object[proddesc]['inctouseforcalc'] = (corrected_inc_final + corrected_hol_final)
                        volume_by_item_object[proddesc]['basetouseforcalc'] = corrected_base_final
                        volume_by_item_object[proddesc]['weightedbp'] = (totalvol * avgbp)
                    }
                }


                this.state.attribute_col_names.map(attribute => {
                    this.state.attributes[attribute].map(attr_val => {

                        if (row[attribute] === attr_val) {
                            if (Object.keys(attribute_vals).includes(attribute)) {
                                if (Object.keys(attribute_vals[attribute]).includes(attr_val.toString())) {
                                    attribute_vals[attribute][attr_val]['totalvol'] += totalvol
                                    attribute_vals[attribute][attr_val]['promovol'] += promounits
                                    attribute_vals[attribute][attr_val]['nonpromovol'] += (totalvol - promounits)
                                    attribute_vals[attribute][attr_val]['basevol'] += corrected_base_final
                                    attribute_vals[attribute][attr_val]['holvol'] += corrected_hol_final
                                    attribute_vals[attribute][attr_val]['incvol'] += corrected_inc_final
                                    attribute_vals[attribute][attr_val]['totaldollars'] += totaldollars
                                    attribute_vals[attribute][attr_val]['promodollars'] += promodollars
                                    attribute_vals[attribute][attr_val]['nonpromodollars'] += nonpromodollars
                                    attribute_vals[attribute][attr_val]['subsidizedbase'] += subbasevar
                                    attribute_vals[attribute][attr_val]['unsubsidizedbase'] += unsubbasevar
                                    attribute_vals[attribute][attr_val]['basetouseforcalc'] += corrected_base_final
                                    attribute_vals[attribute][attr_val]['inctouseforcalc'] += (corrected_inc_final + corrected_hol_final)
                                    attribute_vals[attribute][attr_val]['totaldisc'] += row.avgpctdisc * totalvol
                                    attribute_vals[attribute][attr_val]['attributevalue'] = attr_val
                                }
                                else {
                                    attribute_vals[attribute][attr_val] = {
                                        'totalvol': totalvol,
                                        'promovol': promounits,
                                        'nonpromovol': totalvol - promounits,
                                        'basevol': corrected_base_final,
                                        'holvol': corrected_hol_final,
                                        'incvol': corrected_inc_final,
                                        'totaldollars': totaldollars,
                                        'promodollars': promodollars,
                                        'nonpromodollars': nonpromodollars,
                                        'subsidizedbase': subbasevar,
                                        'unsubsidizedbase': unsubbasevar,
                                        'basetouseforcalc': corrected_base_final,
                                        'inctouseforcalc': (corrected_inc_final + corrected_hol_final),
                                        'totaldisc': row.avgpctdisc * totalvol,
                                        'attributevalue': attr_val
                                    }
                                }
                            }
                            else {
                                attribute_vals[attribute] = {}
                                attribute_vals[attribute][attr_val] = {
                                    'totalvol': totalvol,
                                    'promovol': promounits,
                                    'nonpromovol': totalvol - promounits,
                                    'basevol': corrected_base_final,
                                    'holvol': corrected_hol_final,
                                    'incvol': corrected_inc_final,
                                    'totaldollars': totaldollars,
                                    'promodollars': promodollars,
                                    'nonpromodollars': nonpromodollars,
                                    'subsidizedbase': subbasevar,
                                    'unsubsidizedbase': unsubbasevar,
                                    'basetouseforcalc': corrected_base_final,
                                    'inctouseforcalc': (corrected_inc_final + corrected_hol_final),
                                    'totaldisc': row.avgpctdisc * totalvol,
                                    'attributevalue': attr_val
                                }
                            }
                        }
                    })
                })

                //Create Objects for graphing....

                //Base Data Graphing
                if (Object.keys(base_data_corrected_graph).includes(rowdate)) {
                    base_data_corrected_graph[rowdate] += corrected_base_final
                }
                else {
                    base_data_corrected_graph[rowdate] = corrected_base_final
                }

                //Inc Data Graphing
                if (Object.keys(inc_data_corrected_graph).includes(rowdate)) {
                    inc_data_corrected_graph[rowdate] += corrected_inc_final
                }
                else {
                    inc_data_corrected_graph[rowdate] = corrected_inc_final
                }

                //Holiday Data Graphing
                if (Object.keys(hol_data_corrected_graph).includes(rowdate)) {
                    hol_data_corrected_graph[rowdate] += corrected_hol_final
                }
                else {
                    hol_data_corrected_graph[rowdate] = corrected_hol_final
                }

                //Create graphs and other data....
                //Determine if the discount bucket has been added...
                if (Object.keys(discount_bucket_mapping_bu).includes(discbucket)) {
                    discount_bucket_mapping_bu[discbucket] += corrected_base_final
                }

                else {
                    discount_bucket_mapping_bu[discbucket] = corrected_base_final
                }

                if (Object.keys(discount_bucket_mapping_totalunits).includes(discbucket)) {
                    discount_bucket_mapping_totalunits[discbucket] += totalvol
                }

                else {
                    discount_bucket_mapping_totalunits[discbucket] = totalvol
                }

                if (Object.keys(discount_bucket_mapping_totaldollars).includes(discbucket)) {
                    discount_bucket_mapping_totaldollars[discbucket] += totaldollars
                }

                else {
                    discount_bucket_mapping_totaldollars[discbucket] = totaldollars
                }

                if (Object.keys(discount_bucket_mapping_sbu).includes(discbucket)) {
                    discount_bucket_mapping_sbu[discbucket] += subbasevar
                }

                else {
                    discount_bucket_mapping_sbu[discbucket] = subbasevar
                }

                if (Object.keys(discount_bucket_mapping).includes(discbucket)) {
                    discount_bucket_mapping[discbucket] += promounits
                }

                else {
                    discount_bucket_mapping[discbucket] = promounits
                }
                //Inc Vol
                if (Object.keys(discount_bucket_mapping_iu).includes(discbucket)) {
                    discount_bucket_mapping_iu[discbucket] += (corrected_inc_final + corrected_hol_final)
                }

                else {
                    discount_bucket_mapping_iu[discbucket] = (corrected_inc_final + corrected_hol_final)
                }

                //Inc Dol
                if (Object.keys(discount_bucket_mapping_id).includes(discbucket)) {
                    if (discbucket === 'No Discount') {
                        discount_bucket_mapping_id[discbucket] += incdollars
                    }
                    else {
                        discount_bucket_mapping_id[discbucket] += incdollars
                    }

                }

                else {
                    if (discbucket === 'No Discount') {
                        discount_bucket_mapping_id[discbucket] = incdollars
                    }
                    else {
                        discount_bucket_mapping_id[discbucket] = incdollars
                    }
                }

                //Discount Dollars
                if (Object.keys(discount_bucket_mapping_dd).includes(discbucket)) {
                    discount_bucket_mapping_dd[discbucket] += disc_dollars
                }

                else {
                    discount_bucket_mapping_dd[discbucket] = disc_dollars
                }

                //Add for the brand...
                if (Object.keys(discount_bucket_mapping_brand).includes(discbucket)) {
                    if (Object.keys(discount_bucket_mapping_brand[discbucket]).includes(row.brand)) {
                        discount_bucket_mapping_brand[discbucket][brandname] += promounits
                    }
                    else {
                        discount_bucket_mapping_brand[discbucket][brandname] = promounits
                    }
                }
                else {
                    let objecttoAdd = {}
                    objecttoAdd[brandname] = promounits
                    discount_bucket_mapping_brand[discbucket] = objecttoAdd
                }

                //More graphs
                //Volume by Geograophy
                if (Object.keys(volume_by_geo).includes(String(row.geogid))) {
                    volume_by_geo[row.geogid] = [
                        volume_by_geo[row.geogid][0] + promounits
                        , volume_by_geo[row.geogid][1] + totalvol
                        , volume_by_geo[row.geogid][2] + inctouse_corrected
                        , volume_by_geo[row.geogid][3] + subbasevar
                        , volume_by_geo[row.geogid][4] + unsubbasevar
                    ]
                }

                else {
                    volume_by_geo[row.geogid] = [
                        promounits
                        , totalvol
                        , inctouse_corrected
                        , subbasevar
                        , unsubbasevar
                    ];
                }
                //Volume by Brand
                if (Object.keys(volume_by_brand).includes(row.brand)) {
                    volume_by_brand[row.brand] = [
                        volume_by_brand[row.brand][0] + promounits,
                        volume_by_brand[row.brand][1] + totalvol,
                        volume_by_brand[row.brand][2] + (corrected_inc_final + corrected_hol_final),
                        volume_by_brand[row.brand][3] + subbasevar
                    ]
                }
                else {
                    volume_by_brand[row.brand] = [
                        promounits,
                        totalvol,
                        (corrected_inc_final + corrected_hol_final),
                        subbasevar
                    ];
                }

                //Adding volume by item....
                if (Object.keys(volume_by_item).includes(proddesc)) {
                    volume_by_item[proddesc] = [
                        volume_by_item[proddesc][0] + promounits,
                        volume_by_item[proddesc][1] + totalvol,
                        volume_by_item[proddesc][2] + (corrected_inc_final + corrected_hol_final),
                        volume_by_item[proddesc][3] + subbasevar
                    ]
                }
                else {
                    volume_by_item[proddesc] = [promounits, totalvol, (corrected_inc_final + corrected_hol_final), subbasevar]
                }

                //Add to Weekly Data....
                if (Object.keys(weekly_data['All']).includes(rowdate)) {
                    weekly_data['All'][rowdate]['totalunits'] += totalvol
                    weekly_data['All'][rowdate]['promotedunits'] += promounits
                    weekly_data['All'][rowdate]['nonpromotedunits'] += totalvol - promounits
                    weekly_data['All'][rowdate]['baseunits'] += corrected_base_final
                    weekly_data['All'][rowdate]['holidayunits'] += corrected_hol_final
                    weekly_data['All'][rowdate]['incrementalunits'] += corrected_inc_final
                    weekly_data['All'][rowdate]['totaldollars'] += totaldollars
                    weekly_data['All'][rowdate]['promodollars'] += promodollars
                    weekly_data['All'][rowdate]['nonpromodollars'] += nonpromodollars
                    weekly_data['All'][rowdate]['basedollars'] += basedollars
                    weekly_data['All'][rowdate]['subsidizedbaseunits'] += subbasevar
                    weekly_data['All'][rowdate]['unsubsidizedbase'] += unsubbasevar
                    weekly_data['All'][rowdate]['basetouseforcalc'] += corrected_base_final
                    weekly_data['All'][rowdate]['inctouseforcalc'] += (corrected_inc_final + corrected_hol_final)
                    weekly_data['All'][rowdate]['weightedpctdisc'] += row.avgpctdisc * totalvol
                    weekly_data['All'][rowdate]['discountdollars'] += disc_dollars
                    weekly_data['All'][rowdate]['incrementaldollars'] += incdollars
                }
                else {
                    weekly_data['All'][rowdate] = {
                        'totalunits': totalvol,
                        'promotedunits': promounits,
                        'nonpromotedunits': totalvol - promounits,
                        'baseunits': corrected_base_final,
                        'holidayunits': corrected_hol_final,
                        'incrementalunits': corrected_inc_final,
                        'totaldollars': totaldollars,
                        'promodollars': promodollars,
                        'nonpromodollars': nonpromodollars,
                        'basedollars': basedollars,
                        'subsidizedbaseunits': subbasevar,
                        'unsubsidizedbase': unsubbasevar,
                        'basetouseforcalc': corrected_base_final,
                        'inctouseforcalc': (corrected_inc_final + corrected_hol_final),
                        'weightedpctdisc': row.avgpctdisc * totalvol,         
                        'discountdollars': disc_dollars,
                        'incrementaldollars': incdollars
                    }
                }


                

                //Base Volume by Week
                if (Object.keys(base_data_by_week).includes(rowdate)) {
                    base_data_by_week[rowdate] += corrected_base_final
                }
                else {
                    base_data_by_week[rowdate] = corrected_base_final;
                }

                //Holiday Volume by Week
                if (Object.keys(hol_data_by_week).includes(rowdate)) {
                    hol_data_by_week[rowdate] += corrected_hol_final
                }
                else {
                    hol_data_by_week[rowdate] = corrected_hol_final;
                }

                //Inc Volume by Week
                if (Object.keys(inc_data_by_week).includes(rowdate)) {
                    inc_data_by_week[rowdate] += corrected_inc_final + corrected_hol_final
                }
                else {
                    inc_data_by_week[rowdate] = corrected_inc_final + corrected_hol_final;
                }

                //Inc Volume by Week - Calcs
                if (Object.keys(inc_data_by_week_calcs).includes(rowdate)) {
                    inc_data_by_week_calcs[rowdate] += (corrected_inc_final + corrected_hol_final)
                }
                else {
                    inc_data_by_week_calcs[rowdate] = (corrected_inc_final + corrected_hol_final)
                }

                //Inc Volume by Week - Calcs
                if (Object.keys(base_data_by_week_calcs).includes(rowdate)) {
                    base_data_by_week_calcs[rowdate] += basetouse_corrected
                }
                else {
                    base_data_by_week_calcs[rowdate] = basetouse_corrected
                }


                //ASP by Week
                if (Object.keys(asp_data_by_week).includes(rowdate)) {
                    (asp_data_by_week[rowdate] += row.avgasp * totalvol)
                }
                else {
                    asp_data_by_week[rowdate] = row.avgasp * totalvol;
                }
                //Total Vol by Week 
                if (Object.keys(total_vol_by_week).includes(rowdate)) {
                    total_vol_by_week[rowdate] += totalvol
                }
                else {
                    total_vol_by_week[rowdate] = totalvol;
                }
                //Promo by Week
                if (Object.keys(promo_data_by_week).includes(rowdate)) {
                    (promo_data_by_week[rowdate] += promounits)
                }
                else {
                    promo_data_by_week[rowdate] = promounits;
                }

                //NonPromo by Week
                if (Object.keys(nonpromo_data_by_week).includes(rowdate)) {
                    (nonpromo_data_by_week[rowdate] += totalvol - promounits)
                }
                else {
                    nonpromo_data_by_week[rowdate] = (totalvol - promounits);
                }
                //Discount % by Week
                if (Object.keys(disc_pct_by_week).includes(rowdate)) {
                    disc_pct_by_week[rowdate] += row.avgpctdisc * totalvol
                }
                else {
                    disc_pct_by_week[rowdate] = row.avgpctdisc * totalvol
                }

                //Avg BP By Week
                if (Object.keys(bp_by_week).includes(rowdate)) {
                    bp_by_week[rowdate] += row.avgbp * totalvol
                }
                else {
                    bp_by_week[rowdate] = row.avgbp * totalvol
                }

                //Discount % by Week Raw
                if (Object.keys(disc_pct_by_week_raw).includes(rowdate)) {
                    disc_pct_by_week_raw[rowdate] += row.avgpctdisc
                }
                else {
                    disc_pct_by_week_raw[rowdate] = row.avgpctdisc
                }

                //Discount % by brand
                if (Object.keys(disc_pct_by_brand).includes(row.brand)) {
                    disc_pct_by_brand[row.brand] += row.avgpctdisc * totalvol
                }
                else {
                    disc_pct_by_brand[row.brand] = row.avgpctdisc * totalvol
                }
                //Discount % by item
                if (Object.keys(disc_pct_by_item).includes(proddesc)) {
                    disc_pct_by_item[proddesc] += row.avgpctdisc * totalvol
                }
                else {
                    disc_pct_by_item[proddesc] = row.avgpctdisc * totalvol
                }

                //Discount % by item
                if (Object.keys(disc_pct_by_priceband).includes(price_band_group)) {
                    disc_pct_by_priceband[price_band_group] += row.avgpctdisc * totalvol
                }
                else {
                    disc_pct_by_priceband[price_band_group] = row.avgpctdisc * totalvol
                }

                //Discount Dollars by Week
                if (Object.keys(weighted_dollars_inc_week).includes(rowdate)) {
                    weighted_dollars_inc_week[rowdate] += row.avgasp * ((corrected_inc_final + corrected_hol_final))
                }
                else {
                    weighted_dollars_inc_week[rowdate] = row.avgasp * ((corrected_inc_final + corrected_hol_final))
                }

                //Promo Dollars by Week
                if (Object.keys(weighted_dollars_promo_week).includes(rowdate)) {
                    weighted_dollars_promo_week[rowdate] += promounits * row.avgasp
                }
                else {
                    weighted_dollars_promo_week[rowdate] = promounits * row.avgasp
                }

                //NonPromo Dollars by Week
                if (Object.keys(weighted_dollars_nonpromo_week).includes(rowdate)) {
                    weighted_dollars_nonpromo_week[rowdate] += (totalvol - promounits) * row.avgbp
                }
                else {
                    weighted_dollars_nonpromo_week[rowdate] = (totalvol - promounits) * row.avgbp
                }

                //Total Volume by Brand
                var data_map_brand = {}
                data_map_brand[row.brand] = totalvol
                if (Object.keys(brand_volume_by_week).includes(rowdate)) {
                    if (Object.keys(brand_volume_by_week[rowdate]).includes(row.brand)) {
                        brand_volume_by_week[rowdate][row.brand] += totalvol
                    }

                    else {
                        brand_volume_by_week[rowdate][row.brand] = totalvol
                    }
                }

                else {
                    brand_volume_by_week[rowdate] = data_map_brand
                }
                //Item Volume By Week
                var data_map_item = {}
                data_map_item[proddesc] = totalvol
                if (!suppression) {
                    if (Object.keys(item_volume_by_week).includes(rowdate)) {
                        if (Object.keys(item_volume_by_week[rowdate]).includes(proddesc)) {
                            item_volume_by_week[rowdate][proddesc] += totalvol
                        }
                        else {
                            item_volume_by_week[rowdate][proddesc] = totalvol
                        }
                    }
                    else {
                        item_volume_by_week[rowdate] = data_map_item
                    }
                }

                //Total Units
                totalunits += totalvol
                //TotalUnitsOnPromo += promounits
                //Promoted Units by Item
                if (Object.keys(dollars_by_brand).includes(row.brand)) {
                    dollars_by_brand[row.brand] =
                        [
                            dollars_by_brand[row.brand][0] + (row.avgasp * totalvol)
                            , dollars_by_brand[row.brand][1] + (row.avgbp * (1 - row.avgpctdisc) * (promounits))
                            , dollars_by_brand[row.brand][2] + (row.avgbp * (totalvol - promounits))
                        ]
                }
                else {
                    dollars_by_brand[row.brand] = [
                        (row.avgasp * totalvol)
                        , (row.avgbp * (1 - row.avgpctdisc) * (promounits))
                        , (row.avgbp * (totalvol - promounits))
                    ]
                }

                if (Object.keys(dollars_by_item).includes(proddesc)) {
                    dollars_by_item[proddesc] =
                        [
                            dollars_by_item[proddesc][0] + (row.avgasp * totalvol)
                            , dollars_by_item[proddesc][1] + (row.avgbp * (1 - row.avgpctdisc) * (promounits))
                            , dollars_by_item[proddesc][2] + (row.avgbp * (totalvol - promounits))
                        ]
                }
                else {
                    dollars_by_item[proddesc] = [
                        (row.avgasp * totalvol)
                        , (row.avgbp * (1 - row.avgpctdisc) * (promounits))
                        , (row.avgbp * (totalvol - promounits))
                    ]
                }

                //Need to process by column type
                this.state.distinctcolnames.map(colname => {
                    //Source of Volume
                    if (Object.keys(colname_week_dict).includes(colname)) {
                        (colname_week_dict[colname] += parseFloat(row[colname] * equiv) || 0)
                    }
                    else {
                        colname_week_dict[colname] = parseFloat(row[colname] * equiv) || 0;
                    }
                    //voldecomp_by_week
                    var data_map = {}
                    data_map[colname] = parseFloat(row[colname] * equiv) || 0
                    if (Object.keys(voldecomp_by_week).includes(rowdate)) {
                        if (Object.keys(voldecomp_by_week[rowdate]).includes(colname)) {
                            voldecomp_by_week[rowdate][colname] += parseFloat(row[colname] * equiv) || 0
                        }

                        else {
                            voldecomp_by_week[rowdate][colname] = parseFloat(row[colname] * equiv) || 0
                        }
                    }
                    else {
                        voldecomp_by_week[rowdate] = data_map
                    }
                })

            }

            if (userow_prior === true) {
                //Volume by Geograophy
                if (Object.keys(volume_by_geo_prior).includes(String(row.geogid))) {
                    volume_by_geo_prior[row.geogid] = [volume_by_geo_prior[row.geogid][0] + promounits, volume_by_geo_prior[row.geogid][1] + totalvol, volume_by_geo_prior[row.geogid][2] + corrected_inc_final + corrected_hol_final]
                }

                else {
                    volume_by_geo_prior[row.geogid] = [promounits, totalvol, corrected_inc_final + corrected_hol_final];
                }

                //Base Volume by Week
                if (Object.keys(base_data_by_week_prior).includes(rowdate)) {
                    base_data_by_week_prior[rowdate] += corrected_base_final
                }
                else {
                    base_data_by_week_prior[rowdate] = corrected_base_final
                }
                //Inc Volume by Week
                if (Object.keys(inc_data_by_week_prior).includes(rowdate)) {
                    inc_data_by_week_prior[rowdate] += corrected_inc_final + corrected_hol_final
                }
                else {
                    inc_data_by_week_prior[rowdate] = corrected_inc_final + corrected_hol_final
                }
                //ASP by Week
                if (Object.keys(asp_data_by_week_prior).includes(rowdate)) {
                    (asp_data_by_week_prior[rowdate] += row.avgasp * totalvol)
                }
                else {
                    asp_data_by_week_prior[rowdate] = row.avgasp * totalvol;
                }
                //Total Vol by Week 
                if (Object.keys(total_vol_by_week_prior).includes(rowdate)) {
                    total_vol_by_week_prior[rowdate] += totalvol
                }
                else {
                    total_vol_by_week_prior[rowdate] = totalvol;
                }
                //Promo by Week
                if (Object.keys(promo_data_by_week_prior).includes(rowdate)) {
                    (promo_data_by_week_prior[rowdate] += promounits)
                }
                else {
                    promo_data_by_week_prior[rowdate] = promounits;
                }

                //NonPromo by week
                if (Object.keys(nonpromo_data_by_week).includes(rowdate)) {
                    (nonpromo_data_by_week[rowdate] += (totalvol - promounits))
                }
                else {
                    nonpromo_data_by_week[rowdate] = (totalvol - promounits);
                }

                //Discount % by Week
                if (Object.keys(disc_pct_by_week_prior).includes(rowdate)) {
                    disc_pct_by_week_prior[rowdate] += row.avgpctdisc * totalvol
                }
                else {
                    disc_pct_by_week_prior[rowdate] = row.avgpctdisc * totalvol
                }

                //Determine if the discount bucket has been added...
                if (Object.keys(discount_bucket_mapping_prior).includes(discbucket)) {
                    discount_bucket_mapping_prior[discbucket] += promounits
                }

                else {
                    discount_bucket_mapping_prior[discbucket] = promounits
                }

                //Total Volume by Brand
                var data_map_brand_prior = {}
                data_map_brand_prior[row.brand] = totalvol
                if (Object.keys(brand_volume_by_week_prior).includes(rowdate)) {
                    if (Object.keys(brand_volume_by_week_prior[rowdate]).includes(row.brand)) {
                        brand_volume_by_week_prior[rowdate][row.brand] += totalvol
                    }

                    else {
                        brand_volume_by_week_prior[rowdate][row.brand] = totalvol
                    }
                }

                else {
                    brand_volume_by_week_prior[rowdate] = data_map_brand_prior
                }
                //Item Volume By Week
                var data_map_item_prior = {}
                data_map_item_prior[proddesc] = totalvol
                if (!suppression) {
                    if (Object.keys(item_volume_by_week_prior).includes(rowdate)) {
                        if (Object.keys(item_volume_by_week_prior[rowdate]).includes(proddesc)) {
                            item_volume_by_week_prior[rowdate][proddesc] += totalvol
                        }
                        else {
                            item_volume_by_week_prior[rowdate][proddesc] = totalvol
                        }
                    }
                    else {
                        item_volume_by_week_prior[rowdate] = data_map_item_prior
                    }
                }
                //Total Units
                totalunits_prior += totalvol
                TotalUnitsOnPromo_prior += promounits
                subbasevar_prior += subbasevar
                //Promoted Units by Item

                if (Object.keys(volume_by_item_prior).includes(proddesc)) {
                    volume_by_item_prior[proddesc] = [volume_by_item_prior[proddesc][0] + promounits, volume_by_item_prior[proddesc][1] + totalvol, volume_by_item_prior[proddesc][2] + corrected_inc_final + corrected_hol_final]
                }
                else {
                    volume_by_item_prior[proddesc] = [promounits, totalvol, corrected_inc_final + corrected_hol_final];
                }

                //Weighted Dollars
                weighted_dollars_nonpromo_prior += (row.avgbp) * (totalvol - promounits);
                weighted_dollars_promo_prior += (row.avgasp * (promounits));
                weighted_dollars_incremental_prior += (row.avgasp * (corrected_inc_final + corrected_hol_final));

                //Discount Dollars
                disc_dollars_prior += (promounits * row.avgbp * row.avgpctdisc)

                //Need to process by column type
                this.state.distinctcolnames.map(colname => {
                    //Source of Volume
                    if (Object.keys(colname_week_dict_prior).includes(colname)) {
                        (colname_week_dict_prior[colname] += parseFloat(row[colname] * equiv))
                    }
                    else {
                        colname_week_dict_prior[colname] = parseFloat(row[colname] * equiv);
                    }
                    //voldecomp_by_week
                    var data_map_prior = {}
                    data_map_prior[colname] = parseFloat(row[colname] * equiv)
                    if (Object.keys(voldecomp_by_week_prior).includes(rowdate)) {
                        if (Object.keys(voldecomp_by_week_prior[rowdate]).includes(colname)) {
                            voldecomp_by_week_prior[rowdate][colname] += parseFloat(row[colname] * equiv)
                        }

                        else {
                            voldecomp_by_week_prior[rowdate][colname] = parseFloat(row[colname] * equiv)
                        }
                    }
                    else {
                        voldecomp_by_week_prior[rowdate] = data_map_prior
                    }
                })

            }

            if (userow_forcalcs === true) {

                TotalUnits_new += totalvol
                TotalDollars_new += totaldollars
                BaseDollars_new += basedollars
                IncDollars_new += incdollars
                BaseUnits_new += corrected_base_final
                IncUnits_new += corrected_inc_final
                holUnits += corrected_hol_final
                PromoDollars_new += promodollars
                NonPromoDollars_new += nonpromodollars
                PromoUnits_new += promounits
                NonPromoUnits_new += nonpromounits
                basetouse_corrected_tracking += corrected_base_final
                inctouse_corrected_tracking += (corrected_inc_final + corrected_hol_final)
                TotalUnitsOnPromo += promounits

                //Weighted Dollars
                weighted_dollars_nonpromo += weighted_dollars_nonpromo_var
                weighted_dollars_promo += weighted_dollars_promo_var
                weighted_dollars_incremental += weighted_dollars_incremental_var

                //Revenue Opportunity
                subsidized_vol += subbasevar
                //revenue_opportunity += revenue_opportunity_dollars

                //Discount Dollars
                disc_dollars_curr += disc_dollars

                //Total Vol by Week 
                if (Object.keys(total_vol_by_week_current).includes(rowdate)) {
                    total_vol_by_week_current[rowdate] += totalvol
                }
                else {
                    total_vol_by_week_current[rowdate] = totalvol;
                }

                this.state.distinctcolnames.map(colname => {
                    //Source of Volume
                    if (Object.keys(colname_week_dict_current).includes(colname)) {
                        (colname_week_dict_current[colname] += parseFloat(row[colname] * equiv) || 0)
                    }
                    else {
                        colname_week_dict_current[colname] = parseFloat(row[colname] * equiv) || 0;
                    }
                })

                if (Object.keys(discount_bucket_mapping_current).includes(discbucket)) {
                    discount_bucket_mapping_current[discbucket] += promounits
                }

                else {
                    discount_bucket_mapping_current[discbucket] = promounits
                }

            }


        })


        //Check the number of items in the resulting set
        //Check the number of suppressed items in the resulting set
        //If Both equal 1, then make all datasets empty
        if (suppresseditemsindataset.length === 1 && allitemsindataset.length === 1) {
            //evaluate rows...
            TotalUnits_new = 0
            TotalUnits_all = 0
            TotalDollars_new = 0
            BaseDollars_new = 0
            IncDollars_new = 0
            BaseUnits_new = 0
            IncUnits_new = 0
            holUnits = 0
            PromoDollars_new = 0
            NonPromoDollars_new = 0
            PromoUnits_new = 0
            NonPromoUnits_new = 0
            NonPromoUnits_new = 0
            attribute_vals = {}
            numrowsprocessed = 0;
            discount_bucket_mapping = {}
            discount_bucket_mapping_iu = {}
            discount_bucket_mapping_bu = {}
            discount_bucket_mapping_sbu = {}
            discount_bucket_mapping_id = {}
            discount_bucket_mapping_dd = {}
            discount_bucket_mapping_totaldollars = {}
            discount_bucket_mapping_totalunits = {}
            discount_bucket_mapping_brand = {}
            discount_bucket_mapping_prior = {}

            //Current
            colname_week_dict = {}
            colname_week_dict_current = {}
            voldecomp_by_week = {}
            base_data_by_week = {}
            hol_data_by_week = {}
            inc_data_by_week = {}
            weekly_data = { 'All': {}}
            inc_data_by_week_calcs = {}
            base_data_by_week_calcs = {}

            hol_data_corrected_graph = {}
            inc_data_corrected_graph = {}
            base_data_corrected_graph = {}

            asp_data_by_week = {}
            promo_data_by_week = {}
            nonpromo_data_by_week = {}
            item_volume_by_week = {}
            brand_volume_by_week = {}
            volume_by_item = {}
            dollars_by_item = {}
            volume_by_brand = {}
            dollars_by_brand = {}
            volume_by_geo = {}
            disc_pct_by_week = {}
            disc_pct_by_week_raw = {}
            bp_by_week = {}
            disc_pct_by_brand = {}
            disc_pct_by_item = {}
            disc_pct_by_priceband = {}
            weighted_dollars_inc_week = {}
            weighted_dollars_promo_week = {}
            weighted_dollars_nonpromo_week = {}
            total_vol_by_week = {}
            total_vol_by_week_current = {}
            totalunits = 0;
            TotalUnitsOnPromo = 0;
            TotalUnitsOnPromo_all = 0;
            weighted_dollars_nonpromo = 0;
            weighted_dollars_promo = 0;
            weighted_dollars_incremental = 0;
            revenue_opportunity = 0;
            subsidized_vol = 0;
            disc_dollars_curr = 0;
            disc_dollars_prior = 0;
            //Prior
            colname_week_dict_prior = {}
            voldecomp_by_week_prior = {}
            base_data_by_week_prior = {}
            inc_data_by_week_prior = {}
            asp_data_by_week_prior = {}
            promo_data_by_week_prior = {}
            item_volume_by_week_prior = {}
            brand_volume_by_week_prior = {}
            volume_by_item_prior = {}
            volume_by_geo_prior = {}
            disc_pct_by_week_prior = {}
            total_vol_by_week_prior = {}
            totalunits_prior = 0;
            totaldisc_prior = 0;
            subbasevar_prior = 0;
            TotalUnitsOnPromo_prior = 0;
            weighted_dollars_nonpromo_prior = 0;
            weighted_dollars_promo_prior = 0;
            weighted_dollars_incremental_prior = 0;

            distictdatesarray = []
            volume_total_object = {}
            volume_by_brand_object = {}
            volume_by_priceband_object = {}

            basetouse_corrected_tracking = 0
            inctouse_corrected_tracking = 0
            //eligiblebrandscomp = []
            //eligibleitemscomp = []
            //eligibleitems_filters = []
            //attributes_for_filter = {}

            volume_by_item_object = {
                'Error': {
                    'totalvolume': 0,
                    'promovol': 0,
                    'nonpromovol': 0,
                    'basevol': 0,
                    'holvol': 0,
                    'incvol': 0,
                    'subsidizedbase': 0,
                    'unsubsidizedbase': 0,
                    'inctouseforcalc': 0,
                    'basetouseforcalc': 0
                }
            }
            volume_by_priceband_object = {
                'Error': {
                    'totalvolume': 0,
                    'promovol': 0,
                    'nonpromovol': 0,
                    'basevol': 0,
                    'holvol': 0,
                    'incvol': 0,
                    'subsidizedbase': 0,
                    'unsubsidizedbase': 0,
                    'inctouseforcalc': 0,
                    'basetouseforcalc': 0
                }
            }
            volume_by_brand_object = {
                'Error': {
                    'totalvolume': 0,
                    'promovol': 0,
                    'nonpromovol': 0,
                    'basevol': 0,
                    'holvol': 0,
                    'incvol': 0,
                    'subsidizedbase': 0,
                    'unsubsidizedbase': 0,
                    'inctouseforcalc': 0,
                    'basetouseforcalc': 0
                }
            }

        }



        var attribute_vals_sorted = {}
        var attribute_vals_sorted_final = {}
        if (Object.keys(attribute_vals).length > 0) {
            this.state.attribute_col_names.map(colname =>
                attribute_vals_sorted[colname] = Object.values(attribute_vals[colname]).sort((a, b) => b['totalvol'] - a['totalvol'])
            )

            this.state.attribute_col_names.map(colname => {
                attribute_vals_sorted_final[colname] = []
                Object.values(attribute_vals_sorted[colname]).map(value =>
                    attribute_vals_sorted_final[colname].push(value.attributevalue)
                )
            })
        }


        //Sort Brand Volume by largest volume
        //Figure Out the Brands that have the largest volume...
        let brand_volumes = {}
        Object.values(brand_volume_by_week).map(week => {
            Object.keys(week).map(brand => {
                if (Object.keys(brand_volumes).includes(brand)) {
                    brand_volumes[brand] += week[brand]
                }
                else {
                    brand_volumes[brand] = week[brand]
                }
            })
        })

        let sorted_brand_vols = Object.values(brand_volumes).sort((a, b) => b - a)

        let sorted_brand_names = sorted_brand_vols.map(volume => {
            let i = Object.values(brand_volumes).indexOf(volume)
            return (Object.keys(brand_volumes)[i])
        })

        let brand_volume_by_week_sorted = {}
        Object.keys(brand_volume_by_week).map(week => {
            let week_val = {}
            sorted_brand_names.map(brand => {
                try {
                    week_val[brand] = brand_volume_by_week[week][brand]
                }
                catch (error) {
                    week_val[brand] = 0
                }

            })
            brand_volume_by_week_sorted[week] = week_val
        })

        //Doing the Same for items...
        //Figure Out the Brands that have the largest volume...
        let item_volumes = {}
        Object.values(item_volume_by_week).map(week => {
            Object.keys(week).map(item => {
                if (Object.keys(item_volumes).includes(item)) {
                    item_volumes[item] += week[item]
                }
                else {
                    item_volumes[item] = week[item]
                }
            })
        })

        //This is the correct order of brands...
        let sorted_item_vols = Object.values(item_volumes).sort((a, b) => b - a)
        let sorted_item_names = sorted_item_vols.map(volume => {
            let i = Object.values(item_volumes).indexOf(volume)
            return (Object.keys(item_volumes)[i])
        })

        let item_volume_by_week_sorted = {}
        Object.keys(item_volume_by_week).map(week => {
            let week_val = {}
            sorted_item_names.map(item => {
                try {
                    week_val[item] = item_volume_by_week[week][item]
                }
                catch (error) {
                    week_val[item] = 0
                }

            })
            item_volume_by_week_sorted[week] = week_val
        })

        let volume_by_brand_sorted = {}
        sorted_brand_names.map(brand => {
            volume_by_brand_sorted[brand] = volume_by_brand_object[brand]
        })

        let volume_by_priceband_sorted = {}
        let sorted_pricebands = this.state.priceBandOptions.map(band => band.label)
        sorted_pricebands.map(band => {
            if (Object.keys(volume_by_priceband_object).includes(band)) {
                volume_by_priceband_sorted[band] = volume_by_priceband_object[band]
            }
        })

        let volume_by_item_sorted = {}
        sorted_item_names.map(item => {
            volume_by_item_sorted[item] = volume_by_item_object[item]
        })

        //Create the Options for the Dropdowns...
        let numbrandsforrank = sorted_brand_vols.length
        var integerPart_brand = Math.floor(numbrandsforrank / 10);
        let numitemsforrank = sorted_item_vols.length
        var integerPart = Math.floor(numitemsforrank / 10);
        let brandRankOptions = []
        let itemRankOptions = []

        for (var i = 0; i <= integerPart_brand; i++) {
            let labelstart = (i * 10) + 1
            let labelend = Math.min(((i + 1) * 10), numbrandsforrank)
            let labelstring = 'Brands Ranked ' + labelstart + ' - ' + labelend
            brandRankOptions.push({ 'label': labelstring, 'value': [(i * 10), labelend] })
        }
        
        for (var i = 0; i <= integerPart; i++) {
            let labelstart = (i * 10) + 1
            let labelend = Math.min(((i + 1) * 10), numitemsforrank)
            let labelstring = 'Items Ranked ' + labelstart + ' - ' + labelend
            itemRankOptions.push({ 'label': labelstring, 'value': [(i * 10), labelend] })
        }

        this.setState({
            brandRankOptions,
            brandRankStart: 0,
            brandRankEnd: 10,
            selectedBrandRank: { 'label': 'Brands Ranked 1 - 10', 'value': [0, 10] },
            itemRankOptions,
            itemRankStart: 0,
            itemRankEnd: 10,
            selectedItemRank: { 'label': 'Items Ranked 1 - 10', 'value': [0, 10] }
        })

        try {
            var totaldisc = (Object.values(disc_pct_by_week).reduce(reducer))
        }
        catch (error) {
            var totaldisc = 0
        }


        try {
            var totaldisc_prior = (Object.values(disc_pct_by_week_prior).reduce(reducer))
        }
        catch (error) {
            var totaldisc_prior = 0
        }

        var t1 = performance.now();

        //Make Discount Bucket Data...

        let discount_pie_data = []
        this.state.discount_buckets.map(bucket => {
            if (discount_bucket_mapping[bucket]) {
                discount_pie_data.push({
                    'name': bucket,
                    'y': discount_bucket_mapping[bucket]
                })
            }

        })

        let discount_bucket_mapping_pro = {}
        let total_cat_data = []

        if (Object.values(discount_bucket_mapping).length > 0) {
            var all_discount_units = Object.values(discount_bucket_mapping).reduce(reducer)
        }
        else {
            var all_discount_units = 0
        }

        this.state.discount_buckets.map(bucket => {
            discount_bucket_mapping_pro[bucket] = discount_bucket_mapping[bucket] / all_discount_units
            total_cat_data.push(discount_bucket_mapping[bucket] / all_discount_units)
        })

        let discount_bar_data = []
        let sorted_brand_names_bar_data = sorted_brand_vols.map(volume => {
            let i = Object.values(brand_volumes).indexOf(volume)
            return (Object.keys(brand_volumes)[i])
        })

        sorted_brand_names_bar_data.map(brand => {
            let brand_data = []
            this.state.discount_buckets.map(bucket => {
                if (discount_bucket_mapping_brand[bucket]) {
                    if (discount_bucket_mapping_brand[bucket][brand]) {
                        brand_data.push(discount_bucket_mapping_brand[bucket][brand])
                    }
                    else {
                        brand_data.push(0)
                    }
                }
                else {
                    brand_data.push(0)
                }
            })
            discount_bar_data.push({
                'name': brand,
                'data': brand_data,
                'type': 'column'
            })
        })

        let brand_total_promo = {}
        discount_bar_data.map(datapoint => {
            brand_total_promo[datapoint.name] = datapoint.data.reduce(reducer)
        })
        discount_bar_data.map(datapoint => {
            datapoint['proportionaldata'] = datapoint.data.map(value => {
                if (value / brand_total_promo[datapoint.name]) {
                    return value / brand_total_promo[datapoint.name]
                }
                else {
                    return 0
                }
            })
        })

        let discount_bar_data_pro = []
        discount_bar_data.map((datapoint, idx) => {
            try {
                var colorvar = Constant.graph_colors[idx]
            }
            catch (error) {
                var colorvar = '#000000'
            }
            discount_bar_data_pro.push({
                'name': datapoint['name'],
                'data': datapoint['proportionaldata'],
                'type': 'column',
                'color': colorvar
            })
        })

        discount_bar_data_pro.push({
            'name': 'Total Category',
            'data': total_cat_data,
            'type': 'line',
            'color': '#000000',
            'marker': {
                'symbol': 'rect',
                'lineColor': null,
                'radius': 8,
                'lineWidth': 0
            },
            'lineWidth': 0,
            'states': {
                'hover': {
                    'lineWidthPlus': 0
                }
            }
        })

        //Create title_num_weeks_compperiods
        let title_num_weeks_compperiods = Object.keys(total_vol_by_week_current).length

        this.setState({
            attribute_vals: attribute_vals,
            attribute_vals_sorted: attribute_vals_sorted_final,
            attribute_vals_filters: attributes_for_filter,
            TotalUnits_new,
            TotalUnits_all,
            TotalDollars_new,
            BaseDollars_new,
            IncDollars_new,
            BaseUnits_new,
            IncUnits_new,
            incUnits: IncUnits_new,
            holUnits: holUnits,
            PromoDollars_new,
            NonPromoDollars_new,
            PromoUnits_new,
            NonPromoUnits_new,
            isLoaded: true,
            volume_by_geo: volume_by_geo,
            colname_vals: colname_week_dict,
            colname_vals_current: colname_week_dict_current,
            voldecomp_by_week: voldecomp_by_week,
            base_data_by_week: base_data_by_week,
            hol_data_by_week: hol_data_by_week,
            inc_data_by_week: inc_data_by_week,
            weekly_data: weekly_data,
            inc_data_by_week_calcs: inc_data_by_week_calcs,
            base_data_by_week_calcs: base_data_by_week_calcs,
            hol_data_corrected_graph: hol_data_corrected_graph,
            inc_data_corrected_graph: inc_data_corrected_graph,
            base_data_corrected_graph: base_data_corrected_graph,

            discount_bucket_mapping,
            discount_bucket_mapping_current,
            discount_bucket_mapping_brand,
            discount_bucket_mapping_dd,
            discount_bucket_mapping_id,
            discount_bucket_mapping_iu,
            discount_bucket_mapping_bu,
            discount_bucket_mapping_sbu,
            discount_bucket_mapping_totaldollars,
            discount_bucket_mapping_totalunits,
            discount_pie_data: discount_pie_data,
            discount_bar_data: discount_bar_data_pro,
            total_cat_data: total_cat_data,
            asp_data_by_week: asp_data_by_week,
            promo_data_by_week: promo_data_by_week,
            nonpromo_data_by_week: nonpromo_data_by_week,
            brand_volume_by_week: brand_volume_by_week_sorted,
            item_volume_by_week: item_volume_by_week_sorted,
            volume_by_item: volume_by_item,
            dollars_by_item: dollars_by_item,
            volume_by_brand: volume_by_brand_sorted,
            dollars_by_brand: dollars_by_brand,
            TotalUnits: BaseUnits_new + IncUnits_new, // + holUnits,//totalunits,
            baseUnits: BaseUnits_new,
            TotalUnitsOnPromo: TotalUnitsOnPromo,
            TotalUnitsOnPromo_all: TotalUnitsOnPromo_all,
            weighted_dollars_nonpromo: weighted_dollars_nonpromo,
            weighted_dollars_promo: weighted_dollars_promo,
            weighted_dollars_incremental: weighted_dollars_incremental,
            revenue_opportunity: revenue_opportunity,
            disc_dollars_curr: disc_dollars_curr,
            disc_dollars_prior: disc_dollars_prior,
            subsidized_vol: subsidized_vol,
            total_vol_by_week: total_vol_by_week,
            total_vol_by_week_current: total_vol_by_week_current,
            disc_pct_by_week: disc_pct_by_week,
            disc_pct_by_week_raw: disc_pct_by_week_raw,
            bp_by_week: bp_by_week,
            disc_pct_by_brand: disc_pct_by_brand,
            disc_pct_by_item: disc_pct_by_item,
            disc_pct_by_priceband: disc_pct_by_priceband,
            weighted_dollars_nonpromo_week: weighted_dollars_nonpromo_week,
            weighted_dollars_promo_week: weighted_dollars_promo_week,
            weighted_dollars_inc_week: weighted_dollars_inc_week,
            totaldisc: totaldisc,
            volume_by_geo_prior: volume_by_geo_prior,
            colname_vals_prior: colname_week_dict_prior,
            voldecomp_by_week_prior: voldecomp_by_week_prior,
            base_data_by_week_prior: base_data_by_week_prior,
            inc_data_by_week_prior: inc_data_by_week_prior,
            asp_data_by_week_prior: asp_data_by_week_prior,
            promo_data_by_week_prior: promo_data_by_week_prior,
            brand_volume_by_week_prior: brand_volume_by_week_prior,
            item_volume_by_week_prior: item_volume_by_week_prior,
            volume_by_item_prior: volume_by_item_prior,
            TotalUnits_prior: totalunits_prior,
            TotalDisc_prior: totaldisc_prior,
            subbasevar_prior: subbasevar_prior,
            TotalUnitsOnPromo_prior: TotalUnitsOnPromo_prior,
            weighted_dollars_nonpromo_prior: weighted_dollars_nonpromo_prior,
            weighted_dollars_promo_prior: weighted_dollars_promo_prior,
            weighted_dollars_incremental_prior: weighted_dollars_incremental_prior,
            total_vol_by_week_prior: total_vol_by_week_prior,
            disc_pct_by_week_prior: disc_pct_by_week_prior,
            discount_bucket_mapping_prior: discount_bucket_mapping_prior,
            volume_total_object,
            volume_by_brand_object: volume_by_brand_sorted,
            volume_by_priceband_object: volume_by_priceband_sorted,
            volume_by_item_object: volume_by_item_sorted,
            title_num_weeks: Array.from(new Set(distictdatesarray)).length,
            distinctdates: Array.from(new Set(distictdatesarray)),
            title_geo_selected: this.state.datasetgeos_lookup[new_filters[0]],
            basetouse_corrected_tracking,
            inctouse_corrected_tracking,

            eligiblebrandscomp,
            eligibleitemscomp,
            eligibleitems_filters,
            //Titles for Previous Periods
            currperiodstart_caption,
            currperiodend_caption,
            prevperiodstart_caption,
            prevperiodend_caption,
            title_num_weeks_compperiods,
        })

        var distinctdatesarray = Array.from(new Set(distictdatesarray))

        //Find Top 10 items by volume....
        var items_vol = []
        this.state.distinctitems.map(item => {
            try {
                var volume = volume_by_item[item][1]
            }
            catch (error) {
                var volume = 0
            }
            items_vol.push({ 'name': item, 'volume': volume })
        })

        var byVolume = items_vol.slice(0);

        byVolume.sort(function (a, b) {
            return b.volume - a.volume;
        });

        //List the top 10 items....
        var topitems = []
        for (var i = 0; i < 10; i++) {
            try {
                topitems.push(byVolume[i].name)
            }
            catch (error) {
            }
        }

        //Create Metrics

        try {
            var newbasevalues = Object.values(base_data_by_week).reduce(reducer)
        }

        catch (error) {
            var newbasevalues = 0
        }

        try {
            var holiday_total = Object.values(hol_data_by_week).reduce(reducer)
        }

        catch (error) {
            var holiday_total = 0
        }

        try {
            var newincvalues = Object.values(inc_data_by_week).reduce(reducer) //+ holiday_total
        }
        catch (error) {
            var newincvalues = 0
        }

        try {
            var baseUnits_prior = Object.values(base_data_by_week_prior).reduce(reducer)
        }

        catch (error) {

            var baseUnits_prior = 0
        }

        try {
            var incUnits_prior = Object.values(inc_data_by_week_prior).reduce(reducer)
        }
        catch (error) {
            var incUnits_prior = 0
        }

        this.setState({ incUnits_prior, baseUnits_prior })
        
        var all_brands_in_rpt = new_filters[1]
        if (all_brands_in_rpt === 'All') {
            all_brands_in_rpt = this.state.distinctbrands
        }
        var title_brands_detail = ''
        console.log(new_filters)
        console.log(all_brands_in_rpt)
        console.log(exclude_array[0])
        if (exclude_array[0]) {
            if (all_brands_in_rpt.length === 1) {
                if (all_brands_in_rpt[0] === 'All') {
                    title_brands_detail = 'Total Category Excluding All'
                }
                else {
                    title_brands_detail = 'Total Category Excluding ' + all_brands_in_rpt[0]
                }

            }

            else {
                var brandlist = ''
                all_brands_in_rpt.map((brand, idx) => {
                    if (idx === all_brands_in_rpt.length - 1) {
                        brandlist += ', and ' + brand
                    }
                    else {
                        brandlist += ', ' + brand
                    }
                })

                if (brandlist.length < 100) {
                    title_brands_detail = 'Total Category Excluding ' + brandlist.substring(1)
                }
                else {
                    title_brands_detail = 'Total Category Excluding ' + brandlist.substring(1, 100) + '...'
                }
            }
            
        }
        else {
            var title_brands_detail = ''
            var brandlist = ''
            all_brands_in_rpt.map((brand, idx) => {
                if (idx === all_brands_in_rpt.length - 1) {
                    brandlist += ', and ' + brand
                }
                else {
                    brandlist += ', ' + brand
                }
            })

            if (brandlist.length < 100) {
                title_brands_detail =  brandlist.substring(1)
            }
            else {
                title_brands_detail =  brandlist.substring(1, 100) + '...'
            }
        }

        console.log(title_brands_detail)
        //this.setState({ title_brands_detail })
        
        //Create Highcharts Objects
        var data_pos_date = {}
        var data_neg_date = {}
        var vol_decomp_data_date = {}

        //sort these....
        let sort_order = ['dt_CORE', 'dt_COMPPDISC', 'dt_CENLNBPU', 'dt_LNSEAS1', 'dt_LNSEAS2', 'dt_LNSEAS3', 'dt_HOLIDAY', 'dt_PDISC'] //
        //Find each element in the sort_order and remove...
        let elements_in_cols = []
        let elements_not_in_cols = []


        sort_order.map(sortedcol => {
            //check each col to see if it is in our dataset...
            this.state.distinctcolnames.map(colname => {
                if (sortedcol === colname) {
                    elements_in_cols.push(colname)
                }
                //If the column is not in the sort order and not already accounted for
                else if (!elements_not_in_cols.includes(colname) && !sort_order.includes(colname)) {
                    elements_not_in_cols.push(colname)
                }
            })
        })

        elements_not_in_cols.sort()

        let sorted_cols = elements_in_cols.concat(elements_not_in_cols)
        var data_waterfall = []
        var data_area_graph = []
        var data_area_graph_col = []
        var data_area_graph_brands = []
        var data_area_graph_items = []
        var source_of_vol_pie_data = []

        sorted_cols.map(colname => {
            if (Constant.checktranslations.includes(colname)) {
                if (Constant.colnamelabels_promos_ads[this.state.datasetname]) {
                    if (Constant.colnamelabels_promos_ads[this.state.datasetname][colname]) {
                        var colname_labeled = Constant.colnamelabels_promos_ads[this.state.datasetname][colname]
                    }
                    else {
                        var colname_labeled = Constant.colnamelabels[colname]
                    }
                }
                else {
                    var colname_labeled = Constant.colnamelabels[colname]
                }
            }
            else {
                var colname_labeled = Constant.colnamelabels[colname]
            }
            //Change to Current Values
            if ((colname_week_dict_current[colname] - colname_week_dict_prior[colname]) !== 0) {
                if ((colname_week_dict_current[colname] - colname_week_dict_prior[colname]) > 0) {
                    var trace = {
                        name: colname_labeled,
                        y: colname_week_dict_current[colname] - colname_week_dict_prior[colname],
                        color: '#bc81ec'
                    }
                    data_waterfall.push(trace)
                    data_waterfall.sort(function (a, b) { return b.y - a.y })
                }
                else {
                    var trace = {
                        name: colname_labeled,
                        y: colname_week_dict_current[colname] - colname_week_dict_prior[colname],
                        color: '#f3508e'
                    }
                    data_waterfall.push(trace)
                    data_waterfall.sort(function (a, b) { return b.y - a.y })
                }

            }

            try {
                let data_pos = []
                let data_neg = []

                distinctdatesarray.sort().map((date, idx) => {
                    if (vol_decomp_data_date[date]) {
                        vol_decomp_data_date[date] += voldecomp_by_week[date][colname] || 0
                    }
                    else {
                        vol_decomp_data_date[date] = voldecomp_by_week[date][colname] || 0
                    }
                    if (voldecomp_by_week[date][colname] > 0) {
                        data_pos.push([Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), voldecomp_by_week[date][colname]])
                        if (data_pos_date[date]) {
                            data_pos_date[date] += voldecomp_by_week[date][colname] || 0
                        }
                        else {
                            data_pos_date[date] = voldecomp_by_week[date][colname] || 0
                        }
                    }
                    else if (voldecomp_by_week[date][colname] < 0) {
                        data_neg.push([Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), voldecomp_by_week[date][colname]])
                        if (data_neg_date[date]) {
                            data_neg_date[date] += voldecomp_by_week[date][colname] || 0
                        }
                        else {
                            data_neg_date[date] = voldecomp_by_week[date][colname] || 0
                        }
                    }

                    else if (voldecomp_by_week[date][colname] === 0) {
                        data_pos.push([Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0])
                        data_neg.push([Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0])

                        if (data_pos_date[date]) {
                            data_pos_date[date] += 0
                        }
                        else {
                            data_pos_date[date] = 0
                        }

                        if (data_neg_date[date]) {
                            data_neg_date[date] += 0
                        }
                        else {
                            data_neg_date[date] = 0
                        }
                    }
                })

                if (Object.values(data_neg_date).length > 0) {
                    this.setState({ voldecomp_min: Math.min.apply(Math, Object.values(data_neg_date)) })
                }

                //Check if data_pos and data_neg is completely 0....

                try {
                    let pos_records = data_pos.map(record => record[1])
                    var pos_records_check = pos_records.reduce(reducer)
                }
                catch{
                    var pos_records_check = 0
                }

                try {
                    let neg_records = data_neg.map(record => record[1])
                    var neg_records_check = neg_records.reduce(reducer)
                }
                catch{
                    var neg_records_check = 0
                }


                if (pos_records_check === 0 && neg_records_check === 0) {
                    //Do Nothing only if there are no records for either...
                }

                else {
                    var trace = {
                        name: colname_labeled, //+ ' - Positive',
                        data: data_pos,
                        color: Constant.colnamesolids[colname],
                        stack: 'pos',

                    }

                    var trace_col = {
                        name: colname_labeled, //+ ' - Positive',
                        data: data_pos,
                        color: Constant.colnamesolids[colname],
                        stack: 'stack',

                    }

                    var trace2 = {
                        name: colname_labeled, //+ ' - Negative',
                        data: data_neg,
                        color: Constant.colnamesolids[colname],
                        stack: 'neg',

                        //yAxis: 1,
                        linkedTo: ':previous'
                    }


                    var trace2_col = {
                        name: colname_labeled, //+ ' - Negative',
                        data: data_neg,
                        color: Constant.colnamesolids[colname],
                        stack: 'stack',

                        //yAxis: 1,
                        linkedTo: ':previous'
                    }
                    data_area_graph.push(trace)
                    data_area_graph_col.push(trace_col)
                    data_area_graph.push(trace2)
                    data_area_graph_col.push(trace2_col)
                }

            }
            catch (error) {

                var trace = {
                    name: colname_labeled,
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0]),
                    color: Constant.colnamesolids[colname],
                    stack: 'pos',

                }
                var trace2 = {
                    name: colname_labeled,
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0]),
                    color: Constant.colnamesolids[colname],
                    stack: 'neg',
                    linkedTo: ':previous'
                }

                var trace_col = {
                    name: colname_labeled,
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0]),
                    color: Constant.colnamesolids[colname],
                    stack: 'stack',

                }
                var trace2_col = {
                    name: colname_labeled, //+ ' - Negative',
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0]),
                    color: Constant.colnamesolids[colname],
                    stack: 'stack',

                    //yAxis: 1,
                    linkedTo: ':previous'
                }
            }

            if (colname_week_dict[colname] > 0) {
                source_of_vol_pie_data.push({
                    'name': colname_labeled,
                    'y': Math.abs(colname_week_dict[colname]),
                    color: Constant.colnamesolids[colname]
                })
            }
            else if (colname_week_dict[colname] < 0) {
                source_of_vol_pie_data.push({
                    'name': colname_labeled,
                    'y': colname_week_dict[colname],
                    color: Constant.colnamesolids[colname]
                })
            }
        })

        this.setState({ voldecomp_max: Math.max.apply(Math, Object.values(data_pos_date)), vol_decomp_data_date })

        if (Object.values(data_neg_date).length > 0) {
            this.setState({ voldecomp_min: Math.min.apply(Math, Object.values(data_neg_date)) })
        }

        data_area_graph.push({
            name: 'Total Units',
            data: Object.keys(vol_decomp_data_date).map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), vol_decomp_data_date[date]]),
            type: 'line',
            color: '#000'
        })

        data_area_graph_col.push({
            name: 'Total Units',
            data: Object.keys(vol_decomp_data_date).map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), vol_decomp_data_date[date]]),
            type: 'line',
            color: '#000'
        })

        sorted_brand_names.map(brand => {
            try {
                var trace = {
                    name: brand,
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), brand_volume_by_week[date][brand]]),
                }
            }
            catch (error) {

                var trace = {
                    name: brand,
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0]),
                }
            }
            data_area_graph_brands.push(trace)
        })

        data_area_graph_brands = data_area_graph_brands.sort()

        sorted_item_names.map(item => {
            try {
                var trace = {
                    name: item,
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), item_volume_by_week[date][item]]),
                }
            }
            catch (error) {

                var trace = {
                    name: item,
                    data: distinctdatesarray.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), 0]),
                }
            }
            data_area_graph_items.push(trace)
        })

        data_area_graph_items = data_area_graph_items.sort()

        this.setState({
            data_waterfall: data_waterfall,
            data_area_graph: data_area_graph,
            data_area_graph_col: data_area_graph_col,
            source_of_vol_pie_data: source_of_vol_pie_data,
            data_area_graph_brands: data_area_graph_brands,
            data_area_graph_items: data_area_graph_items
        })

        //Create Insight Text....
        let value = this.state.value
        let value2 = this.state.value2
        let value3 = this.state.value3
        let value4 = this.state.value4
        let activevalue = this.state.activevalue
        try {
            var newbasevalues = Object.values(base_data_by_week).reduce(reducer)
        }

        catch (error) {
            var newbasevalues = 0
        }
        try {
            var newincvalues = Object.values(inc_data_by_week).reduce(reducer)
        }
        catch (error) {
            var newincvalues = 0
        }

        var newtotalvalues = newbasevalues + newincvalues

        //Find largest value in source of volume....
        var largest_val = 0
        var largest_var = 0
        var total_vol = 0
        var base_cols = ['Competitive Pricing', 'Base Price', 'Core Distribution', 'Seasonality']
        for (var i = 0; i < source_of_vol_pie_data.length; i++) {
            total_vol += source_of_vol_pie_data[i].y
            if (base_cols.includes(source_of_vol_pie_data[i].name)) {
                //Do Nothing
            }
            else {
                if (source_of_vol_pie_data[i].y > largest_val) {
                    largest_val = source_of_vol_pie_data[i].y
                    largest_var = source_of_vol_pie_data[i].name
                }
            }

        }

        try {
            var subsidized_vol_insight = volume_by_geo[new_filters[0]][3]
        }
        catch (error) {
            var subsidized_vol_insight = 0
        }
        //Issue Here
        console.log(title_brands_detail)
        console.log(this.state.title_brands_detail)
        if (activevalue === 1) {
            if (value === 0 || value === 1 || value === 2) {
                let reportInsightText = (newbasevalues / (TotalUnits_all)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " of the Product " + this.state.unitsvar.toLowerCase() + " are everyday base sales, while " + (inctouse_corrected_tracking / (TotalUnits_all)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " is incremental sales driven by promotions. "
                    + ((TotalUnitsOnPromo_all / (TotalUnits_all))).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " of " + title_brands_detail
                    + " " + this.state.unitsvar.toLowerCase() + " were sold on promotion, and "
                    + (subsidized_vol_insight / TotalUnits_all).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " were subsidized meaning they were sold on promotion but could have been purchased at a higher price.  There is "
                try {
                    var reportInsightText_emph = Math.round(
                        revenue_opportunity
                    ).toLocaleString(undefined, { style: 'currency', currency: this.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                }
                catch (error) {
                    var reportInsightText_emph = 0
                }

                let reportInsightText_end = " of revenue available to capture from optimizing promotional spending. "

                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })

            }

            else if (value === 4) {
                let reportInsightText = (largest_val / total_vol).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " of the " + this.state.unitsvar.toLowerCase() + " are incremental driven by " + largest_var
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }

            else if (value === 5) {
                let reportInsightText = 'Promotions drove ' + parseInt(newincvalues).toLocaleString() + ' incremental ' + this.state.unitsvar.toLowerCase() + ' or a ' + Math.min((newincvalues / newbasevalues), 10).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' lift for ' + title_brands_detail + ' during the ' + this.state.title_num_weeks + ' weeks ending ' + this.state.title_end_date + ' in ' + this.state.datasetgeos_lookup[new_filters[0]]
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }
            else {
                let reportInsightText = ''
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }
        }
        else if (activevalue === 2) {

            let reportInsightText = ''
            let reportInsightText_emph = ""
            let reportInsightText_end = ""
            this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })

        }

        else if (activevalue === 3) {
            if (value3 === 0) {
                let volChangePct = (totalunits - totalunits_prior) / totalunits_prior
                let highestVar = data_waterfall[0].name
                let lowestVar = data_waterfall[data_waterfall.length - 1].name
                let reportInsightText = "Units changed " + volChangePct.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " from a YAG driven by " + highestVar + " and offset by " + lowestVar
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }

            else {
                let reportInsightText = ''
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }

        }

        else if (activevalue === 4) {
            let reportInsightText = ''
            let reportInsightText_emph = ""
            let reportInsightText_end = ""
            this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
        }

        this.run_comparison(reportData, new_filters, asp_range, 'evaluaterows', numweeks)
    }

    componentDidMount() {
        const findid = () => {
            var url = window.location.href;
            var temp = url.split("/");
            var i = unescape(temp[5]);
            var idnum = parseInt(i, 10);
            return idnum;
        }
        const reportid = findid();
      
        this.getReportSettings(reportid)

        

    }

    setcomparison_metric = (selectedOptions) => {
        this.setState({ comparisonMetricSelected: selectedOptions })        
        this.runcomparison(selectedOptions, null, null, null)
    }

    setcomparison_metric_trended = (selectedOptions) => {
        this.setState({ comparisonMetricSelected_trended: selectedOptions })
        this.runcomparison(null, selectedOptions, null, null)
    }

    setoverlay_metric = (selectedOptions) => {
        this.setState({ overlayMetricSelected: selectedOptions })
        this.runcomparison(null, null, selectedOptions, null)
    }

    setAttrsForGraphs = (selectedOptions) => {
        var graph_selected_attributes = []
        var graph_selected_attributes_labels = [] 

        let curr_labels = this.state.graph_selected_attributes_labels

        if (selectedOptions && selectedOptions.length > 0) {
            if (curr_labels.length === 1 && curr_labels[0].value === 'All') {
                graph_selected_attributes = []
                selectedOptions.forEach(option => { if (option.value !== 'All') { graph_selected_attributes.push(option.value) } })
                graph_selected_attributes_labels = graph_selected_attributes.map(attr => { return { 'label': this.state.attribute_lookup[attr], 'value': attr } })

            }
            else if (selectedOptions.map(option => option.value).includes('All')) {
                graph_selected_attributes = this.state.attribute_col_names
                graph_selected_attributes_labels = [{ 'label': 'All', 'value': 'All' }]
            }
            else {
                graph_selected_attributes = selectedOptions.map(option => option.value)
                graph_selected_attributes_labels = (selectedOptions.map(option => option.value)).map(attr => { return { 'label': this.state.attribute_lookup[attr], 'value': attr } })
            }
            
        }
        else {
            graph_selected_attributes = []
            graph_selected_attributes_labels = []
        }
        this.setState({ graph_selected_attributes, graph_selected_attributes_labels })
        
    }

    runcomparison = (metricsupplied = {}, metricsupplied_trended = {}, metricsupplied_overlay = {}, unitsdollars_provided) => {

        console.log(metricsupplied)
        console.log(metricsupplied_trended)
        //check that metric has been selected....
        let current_tab = this.state.value4
        var error = false
        let numcomparisonsran = this.state.numcomparisonsran
        let metric = (metricsupplied || {}).value || (this.state.comparisonMetricSelected.value || "")
        let metric_trended = (metricsupplied_trended || {}).value || (this.state.comparisonMetricSelected_trended.value || "")
        let metric_overlay = (metricsupplied_overlay || {}).value || (this.state.overlayMetricSelected.value || "")
        let unitsdollars = unitsdollars_provided 
        let unitsdollars_final = unitsdollars_provided
        if (unitsdollars === undefined) {
            unitsdollars_final = this.state.indexSwitchCheckDollars
        }
        else {
            unitsdollars_final = unitsdollars_provided
        }
        if (current_tab === 0 && numcomparisonsran > 0 && !metric) {
            error = true
        }

        else if (current_tab === 1 && numcomparisonsran > 0 && !metric_trended) {
            error = true
        }

        if (error) {
            this.setState({ errorAlert_compare: true })
        }
        else {
            this.run_comparison(this.state.reportdata, this.state.filters, this.state.sliderRange, 'runcomp', this.state.numweeks, metricsupplied, metricsupplied_trended, metricsupplied_overlay, unitsdollars_final)
        }

    }

    handleToggle_exclude_brands_g1() {
        console.log('Setting State')
        this.setState({ exclude_brands_g1: !this.state.exclude_brands_g1 })
    }

    handleToggle_exclude_brands_g2() {
        console.log('Setting State')
        this.setState({ exclude_brands_g2: !this.state.exclude_brands_g2 })
    }

    setRangeStartingPoint = (selectedOptions) => {
        this.setState({ rangeStartingPoint: selectedOptions, calculatedrange: null })
    }

    setRangeAddSubtract = (selectedOptions) => {
        this.setState({ rangeAddSubtract: selectedOptions, calculatedrange: null })
    }

    setg1definition = (selectedOptions, position) => {

        var g1definition = ""
        var selectedBrands_g1 = this.state.selectedBrands_g1
        var exclude_brands_g1 = this.state.exclude_brands_g1
        var selectedPriceBands_g1 = this.state.selectedPriceBands_g1
        var selectedGeos_g1 = this.state.selectedGeos_g1
        var selectedAttributes_g1 = this.state.selectedAttributes_g1

        if (position === 'brand') {
            if (selectedOptions) {
                selectedBrands_g1 = selectedOptions
                this.setState({ selectedBrands_g1: selectedOptions })                
            }
            else {
                selectedBrands_g1 = []
                this.setState({ selectedBrands_g1: [] })
            }
        }

        else if (position === 'geo') {
            if (selectedOptions) {
                if (selectedOptions.length > 1) {
                    //Only Allow this if there are two values and they are both the ALR and the Corresponding RM...
                    if (selectedOptions.length > 2) {
                        //Error Occurred...
                        selectedGeos_g1 = []
                        this.setState({ selectedGeos_g1: [], errorAlertComp: true })
                    }
                    else {
                        let geos = selectedOptions.map(option => option.value).sort()
                        let alr_object = Constant.alr_object_new
                        function isArrayInArray(arr, item) {
                            var item_as_string = JSON.stringify(item);

                            var contains = arr.some(function (ele) {
                                return JSON.stringify(ele) === item_as_string;
                            });
                            return contains;
                        }

                        var geosincluded = isArrayInArray(alr_object[this.state.datasetname], geos)
                        console.log(geosincluded)
                        if (geosincluded) {
                            selectedGeos_g1 = selectedOptions
                            this.setState({ selectedGeos_g1: selectedOptions })
                        }
                        else {
                            //Error Occurred...
                            selectedGeos_g1 =  []
                            this.setState({ selectedGeos_g1: [], errorAlertComp: true })
                        }
                    }
                }
                else {
                    //If it is only one, take that option
                    selectedGeos_g1 = selectedOptions
                    this.setState({ selectedGeos_g1: selectedOptions })
                }

            }
            else {
                selectedGeos_g1 = []
                this.setState({ selectedGeos_g1: [] })
            }
        }

        else if (position === 'priceband') {
            if (selectedOptions) {
                selectedPriceBands_g1 = selectedOptions
                this.setState({ selectedPriceBands_g1: selectedOptions })
            }
            else {
                selectedPriceBands_g1 = []
                this.setState({ selectedPriceBands_g1: [] })
            }
        }
        else {
            let attributes = this.state.selectedAttributes_g1
            if (selectedOptions) {
                attributes[position] = selectedOptions
                selectedAttributes_g1 = attributes
                this.setState({ selectedAttributes_g1: attributes })
            }
            else {
                attributes[position] = []
                selectedAttributes_g1 = attributes
                this.setState({ selectedAttributes_g1: attributes })
            }
        }


        //Set Definition
        var brandstring = ""
        var attrstring = ""
        var pricebandstring = ""
        var geostring = ""

        if (selectedBrands_g1.length) {
            if (exclude_brands_g1) {
                brandstring = '<span> Excluding Brands: ' + selectedBrands_g1.map(brand => brand.value).join(',') + '<br /></span>'
            }
            else {
                brandstring = '<span> Brands: ' + selectedBrands_g1.map(brand => brand.value).join(',') + '<br /></span>'
            }
        }

        Object.keys(this.state.selectedAttributes_g1).map(attribute => {
            if (selectedAttributes_g1[attribute].length === 0) {
                attrstring += ""
            }
            else {
                attrstring += '<span>' + this.state.attribute_lookup[attribute] + ' : ' + selectedAttributes_g1[attribute].map(attr => attr.label).join(',') + ' <br /></span>'
            }
        })

        if (selectedPriceBands_g1.length > 0) {
            pricebandstring = '<span> Price Bands: ' + selectedPriceBands_g1.map(band => band.label).join(',') + '<br /></span>'
        }

        if (selectedGeos_g1.length > 0) {
            geostring = '<span> Geographies: ' + selectedGeos_g1.map(geo => geo.label).join(',') + '<br /></span>'
        }

        g1definition = brandstring + attrstring + pricebandstring + geostring
        console.log(g1definition)
        this.setState({ g1definition })
    }

    setg2definition = (selectedOptions, position) => {
        var g2definition = ""
        var selectedBrands_g2 = this.state.selectedBrands_g2
        var exclude_brands_g2 = this.state.exclude_brands_g2
        var selectedPriceBands_g2 = this.state.selectedPriceBands_g2
        var selectedGeos_g2 = this.state.selectedGeos_g2
        var selectedAttributes_g2 = this.state.selectedAttributes_g2

        if (position === 'brand') {
            if (selectedOptions) {
                selectedBrands_g2 = selectedOptions
                this.setState({ selectedBrands_g2: selectedOptions })
            }
            else {
                selectedBrands_g2 = []
                this.setState({ selectedBrands_g2: [] })
            }
        }

        else if (position === 'geo') {
            if (selectedOptions) {
                if (selectedOptions.length > 1) {
                    //Only Allow this if there are two values and they are both the ALR and the Corresponding RM...
                    if (selectedOptions.length > 2) {
                        //Error Occurred...
                        selectedGeos_g2 = []
                        this.setState({ selectedGeos_g2: [], errorAlertComp: true })
                    }
                    else {
                        let geos = selectedOptions.map(option => option.value).sort()
                        let alr_object = Constant.alr_object_new
                        function isArrayInArray(arr, item) {
                            var item_as_string = JSON.stringify(item);

                            var contains = arr.some(function (ele) {
                                return JSON.stringify(ele) === item_as_string;
                            });
                            return contains;
                        }

                        var geosincluded = isArrayInArray(alr_object[this.state.datasetname], geos)
                        console.log(geosincluded)
                        if (geosincluded) {
                            selectedGeos_g2 = selectedOptions
                            this.setState({ selectedGeos_g2: selectedOptions })
                        }
                        else {
                            //Error Occurred...
                            selectedGeos_g2 = []
                            this.setState({ selectedGeos_g2: [], errorAlertComp: true })
                        }
                    }
                }
                else {
                    //If it is only one, take that option
                    selectedGeos_g2 = selectedOptions
                    this.setState({ selectedGeos_g2: selectedOptions })
                }

            }
            else {
                selectedGeos_g2 = []
                this.setState({ selectedGeos_g2: [] })
            }
        }

        else if (position === 'priceband') {
            if (selectedOptions) {
                selectedPriceBands_g2 = selectedOptions
                this.setState({ selectedPriceBands_g2: selectedOptions })
            }
            else {
                selectedPriceBands_g2 = []
                this.setState({ selectedPriceBands_g2: [] })
            }
        }
        else {
            let attributes = this.state.selectedAttributes_g2
            if (selectedOptions) {
                attributes[position] = selectedOptions
                selectedAttributes_g2 = attributes
                this.setState({ selectedAttributes_g2: attributes })
            }
            else {
                attributes[position] = []
                selectedAttributes_g2 = attributes
                this.setState({ selectedAttributes_g2: attributes })
            }
        }


        //Set Definition
        var brandstring = ""
        var attrstring = ""
        var pricebandstring = ""
        var geostring = ""

        if (selectedBrands_g2.length) {
            if (exclude_brands_g2) {
                brandstring = '<span> Excluding Brands: ' + selectedBrands_g2.map(brand => brand.value).join(',') + '<br /></span>'
            }
            else {
                brandstring = '<span> Brands: ' + selectedBrands_g2.map(brand => brand.value).join(',') + '<br /></span>'
            }
        }

        Object.keys(this.state.selectedAttributes_g2).map(attribute => {
            if (selectedAttributes_g2[attribute].length === 0) {
                attrstring += ""
            }
            else {
                attrstring += '<span>' + this.state.attribute_lookup[attribute] + ' : ' + selectedAttributes_g2[attribute].map(attr => attr.label).join(',') + ' <br /></span>'
            }
        })

        if (selectedPriceBands_g2.length > 0) {
            pricebandstring = '<span> Price Bands: ' + selectedPriceBands_g2.map(band => band.label).join(',') + '<br /></span>'
        }

        if (selectedGeos_g2.length > 0) {
            geostring = '<span> Geographies: ' + selectedGeos_g2.map(geo => geo.label).join(',') + '<br /></span>'
        }

        g2definition = brandstring + attrstring + pricebandstring + geostring
        this.setState({ g2definition })
    }

    filterdataset_new = (selectedOptions, position, exclude_array = [this.state.exclude_brands, this.state.exclude_items], filterPause = this.state.filterPause, numweeks = this.state.numweeks) => {

        console.log(filterPause)

        //Set Filters

        var curr_filters = this.state.filters
        var set_items = false

        if (position === 0) {
            var new_filters = [selectedOptions.value, curr_filters[1], curr_filters[2], this.state.selectedstart, this.state.selectedend, curr_filters[5]]
            var asp_range = this.state.sliderRange
            var selectedpricebands = this.state.selectedPriceBands
        }

        else if (position === 1) {
            set_items = true
            var asp_range = this.state.sliderRange
            var selectedpricebands = this.state.selectedPriceBands
            if (selectedOptions) {
                if (selectedOptions.length === 0) {
                    var new_filters = [curr_filters[0], ['All'], curr_filters[2], this.state.selectedstart, this.state.selectedend, curr_filters[5]]
                    var prior_start = this.state.start_date_prior
                    var prior_end = this.state.end_date_prior
                    var selectedBrands_filter = []
                    selectedBrands_filter.push({ 'label': 'All', 'value': 'All' })
                    this.setState({ selectedBrands_filter })
                }
                else {
                    let brands_in_filter = []
                    selectedOptions.map(selected => {
                        if (selected.value !== 'All') {
                            brands_in_filter.push(selected.value)
                        }
                        else if (selected.value === 'All' && !curr_filters[1].includes('All')) {
                            //brands_in_filter.push(selected.value)
                            brands_in_filter = ['All']
                        }
                    })
                    var new_filters = [curr_filters[0], brands_in_filter, curr_filters[2], this.state.selectedstart, this.state.selectedend, curr_filters[5]]
                    var prior_start = this.state.start_date_prior
                    var prior_end = this.state.end_date_prior
                    var selectedBrands_filter = []
                    //Ignore All When more than one option
                    selectedOptions.map(selected => {
                        if (selected.value !== 'All') {
                            selectedBrands_filter.push({ 'label': selected.value, 'value': selected.value })
                        }
                        else if (selected.value === 'All' && !curr_filters[1].includes('All')) {
                            selectedBrands_filter = [{ 'label': selected.value, 'value': selected.value }]
                        }
                    })
                    this.setState({ selectedBrands_filter })
                }
            }
            else {
                var new_filters = [curr_filters[0], ['All'], curr_filters[2], this.state.selectedstart, this.state.selectedend, curr_filters[5]]
                var prior_start = this.state.start_date_prior
                var prior_end = this.state.end_date_prior
                var selectedBrands_filter = []
                selectedBrands_filter.push({ 'label': 'All', 'value': 'All' })
                this.setState({ selectedBrands_filter })
            }
        }

        else if (position === 2) {
            var asp_range = this.state.sliderRange
            var selectedpricebands = this.state.selectedPriceBands
            if (selectedOptions) {
                if (selectedOptions.length === 0) {

                    var prior_start = this.state.start_date_prior
                    var prior_end = this.state.end_date_prior
                    var selectedItems_filter = []
                    selectedItems_filter.push({ 'label': 'All', 'value': 'All' })
                    this.setState({ selectedItems_filter })

                    //Reset Attributes...

                    let new_attrs = {}
                    Object.keys(this.state.attributes).map(attr => new_attrs[attr] = [{ 'label': 'All', 'value': 'All' }])
                    var new_filters = [curr_filters[0], curr_filters[1], ['All'], this.state.selectedstart, this.state.selectedend, new_attrs] //curr_filters[5]]
                }
                else {
                    let items_in_filter = []
                    let attrs_added = {}
                    let new_attrs = {}
                    selectedOptions.map(selected => {
                        if (selected.value !== 'All') {
                            items_in_filter.push(selected.value)


                            Object.keys(this.state.productAttrDict_vals[selected.label]).map(attr => {
                                //Add if nothing added
                                if (!attrs_added[attr]) {
                                    new_attrs[attr] = [{ 'label': this.state.productAttrDict_vals[selected.label][attr], 'value': this.state.productAttrDict_vals[selected.label][attr] }]
                                }
                                //Also add if not already added
                                else if (!attrs_added[attr].includes(this.state.productAttrDict_vals[selected.label][attr])) {
                                    new_attrs[attr].push({ 'label': this.state.productAttrDict_vals[selected.label][attr], 'value': this.state.productAttrDict_vals[selected.label][attr] })
                                }
                            })

                            //Add attributes to list of already added attributes
                            Object.keys(this.state.productAttrDict_vals[selected.label]).map(attr => {
                                if (Object.keys(attrs_added).includes(attr)) {
                                    attrs_added[attr].push(this.state.productAttrDict_vals[selected.label][attr])
                                }
                                else {
                                    attrs_added[attr] = [this.state.productAttrDict_vals[selected.label][attr]]
                                }
                            })

                        }
                        else if (selected.value === 'All' && !curr_filters[2].includes('All')) {
                            items_in_filter = ['All']
                            set_items = true
                            //Reset Attributes...
                            Object.keys(this.state.attributes).map(attr => new_attrs[attr] = [{ 'label': 'All', 'value': 'All' }])
                        }
                    })

                    var new_filters = [curr_filters[0], curr_filters[1], items_in_filter, this.state.selectedstart, this.state.selectedend, new_attrs]
                    var prior_start = this.state.start_date_prior
                    var prior_end = this.state.end_date_prior
                    var selectedItems_filter = []
                    selectedOptions.map(selected => {
                        if (selected.value !== 'All') {
                            selectedItems_filter.push({ 'label': selected.value, 'value': selected.value })
                        }
                        else if (selected.value === 'All' && !curr_filters[2].includes('All')) {
                            selectedItems_filter = [{ 'label': selected.value, 'value': selected.value }]
                        }
                    })
                    this.setState({ selectedItems_filter })
                }

            }
            else {
                var new_attrs = {}
                Object.keys(this.state.attributes).map(attr => new_attrs[attr] = [{ 'label': 'All', 'value': 'All' }])
                var prior_start = this.state.start_date_prior
                var prior_end = this.state.end_date_prior
                var selectedItems_filter = []
                selectedItems_filter.push({ 'label': 'All', 'value': 'All' })
                this.setState({ selectedItems_filter })
                set_items = true
                var new_filters = [curr_filters[0], curr_filters[1], ['All'], this.state.selectedstart, this.state.selectedend, new_attrs] //curr_filters[5]]
            }
        }

        else if (position === 3) {
            var asp_range = this.state.sliderRange
            var new_filters = [curr_filters[0], curr_filters[1], curr_filters[2], selectedOptions[0], selectedOptions[1], curr_filters[5]]
            var prior_start = String(parseInt(selectedOptions[0].substring(0, 4)) - 1) + "-" + selectedOptions[0].substring(5, 7) + "-" + selectedOptions[0].substring(8, 10) + 'T00:00:00'
            var prior_end = String(parseInt(selectedOptions[1].substring(0, 4)) - 1) + "-" + selectedOptions[1].substring(5, 7) + "-" + selectedOptions[1].substring(8, 10) + 'T00:00:00'
            var title_end_date = selectedOptions[1].substring(0, 10)
            this.setState({ start_date_prior: prior_start })
            this.setState({ end_date_prior: prior_end })
            this.setState({ title_end_date })
            var selectedpricebands = this.state.selectedPriceBands
        }

        //ASP Range
        else if (position === 4) {

            set_items = true
            var asp_range = selectedOptions
            var new_filters = curr_filters
            var prior_start = this.state.start_date_prior
            var prior_end = this.state.end_date_prior
            var selectedpricebands = this.state.selectedPriceBands
        }
        //Price Band Filters
        else if (position === 5) {

            var asp_range = this.state.sliderRange
            var new_filters = curr_filters
            var prior_start = this.state.start_date_prior
            var prior_end = this.state.end_date_prior
            var selectedpricebands = selectedOptions
        }

        //Calculated Date Range
        else if (position === 6) {

        }

        //attributes
        else {
            set_items = true
            let attribute = position
            var prior_start = this.state.start_date_prior
            var prior_end = this.state.end_date_prior
            var asp_range = this.state.sliderRange
            var selectedpricebands = this.state.selectedPriceBands
            if (Object.keys(this.state.attributes).includes(attribute)) {
                let attribute_vals = curr_filters[5]


                //current attributes
                let current_vals = attribute_vals[attribute].map(currentval => currentval.value)


                if (selectedOptions) {
                    if (selectedOptions.length === 0) {
                        attribute_vals[attribute] = [{ 'value': 'All', 'label': 'All' }]
                        var new_filters = [curr_filters[0], curr_filters[1], curr_filters[2], curr_filters[3], curr_filters[4], attribute_vals]
                        this.setState({ filters: new_filters })
                    }
                    else {
                        let attrs_in_filter = []
                        selectedOptions.map(selected => {

                            if (selected.value !== 'All') {
                                attrs_in_filter.push({ 'label': selected.value, 'value': selected.value })
                            }
                            else if (selected.value === 'All' && !current_vals.includes('All')) {
                                attrs_in_filter = [{ 'label': 'All', 'value': 'All' }]
                            }
                        })


                        attribute_vals[attribute] = attrs_in_filter //selectedOptions.map(selected => { return { 'label': selected.value, 'value': selected.value } })
                        var new_filters = [curr_filters[0], curr_filters[1], curr_filters[2], curr_filters[3], curr_filters[4], attribute_vals]
                        this.setState({ filters: new_filters })
                    }

                }
                else {
                    attribute_vals[attribute] = [{ 'value': 'All', 'label': 'All' }]
                    var new_filters = [curr_filters[0], curr_filters[1], curr_filters[2], curr_filters[3], curr_filters[4], attribute_vals]
                    this.setState({ filters: new_filters })
                }
            }

        }


        this.setState({ filters: new_filters })
        //let default filters for comp be the  attribute values...
        this.setState({ compfilters_attr: [new_filters[5]] })
        //Look at geo, and if an ALR, include the RM button...

        //geo
        let alr_object = Constant.alr_object
        if (alr_object[this.state.datasetname]) {
            if (Object.keys(alr_object[this.state.datasetname]).includes(new_filters[0].toString())) {
                this.setState({ displayincludermcheckbox: 'inline' })
            }
            else {
                this.setState({ displayincludermcheckbox: 'none' })
            }
        }

        else {
            this.setState({ displayincludermcheckbox: 'none' })
        }

        //Evaluate the rows....

        //Only Evaluate the rows if the filterPause State === 'apply'

        if (filterPause === 'apply') {
            this.evaluateRows(this.state.reportdata, new_filters, asp_range, exclude_array, selectedpricebands, numweeks)
            //Set Snackbar...
            this.setState({ showSnackbar_filter: true })
        }
    }

    changeItemRank = (selectedOptions, position) => {
        console.log(selectedOptions)
        this.setState({
            itemRankStart: selectedOptions.value[0],
            itemRankEnd: selectedOptions.value[1],
            selectedItemRank: selectedOptions
        })
    }

    changeBrandRank = (selectedOptions, position) => {
        console.log(selectedOptions)
        this.setState({
            brandRankStart: selectedOptions.value[0],
            brandRankEnd: selectedOptions.value[1],
            selectedBrandRank: selectedOptions
        })
    }

    render() {

        /*Metrics*/
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        console.log(this.state)

        try {
            var total_units_curr = this.state.TotalUnits_new
            var inc_units_curr = this.state.inctouse_corrected_tracking
            var base_units_curr = this.state.basetouse_corrected_tracking //(this.state.TotalUnits - this.state.incUnits)
            var promo_units_curr = this.state.TotalUnitsOnPromo
            var nonpromo_units_curr = this.state.TotalUnits_new - this.state.TotalUnitsOnPromo
            var lift_curr = Math.min(inc_units_curr / base_units_curr, 10)
            var eff_curr = inc_units_curr / promo_units_curr
            var base_price_curr = this.state.weighted_dollars_nonpromo / nonpromo_units_curr
            var avg_disc_curr = (Object.values(this.state.disc_pct_by_week).reduce(reducer) / this.state.TotalUnits_new)
            var avg_promo_price_curr = base_price_curr * (1 - avg_disc_curr)
            var disc_dollars_curr = this.state.disc_dollars_curr //promo_units_curr * base_price_curr * avg_disc_curr
            var payback_ratio_curr = this.state.weighted_dollars_incremental / disc_dollars_curr

            var total_units_prior = this.state.TotalUnits_prior
            var inc_units_prior = this.state.incUnits_prior
            var base_units_prior = this.state.baseUnits_prior //this.state.TotalUnits_prior - this.state.incUnits_prior
            var promo_units_prior = this.state.TotalUnitsOnPromo_prior
            var nonpromo_units_prior = this.state.TotalUnits_prior - this.state.TotalUnitsOnPromo_prior
            var lift_prior = Math.min(inc_units_prior / base_units_prior, 10)
            var eff_prior = inc_units_prior / promo_units_prior
            var base_price_prior = this.state.weighted_dollars_nonpromo_prior / nonpromo_units_prior
            var avg_disc_prior = (this.state.TotalDisc_prior / this.state.TotalUnits_prior)
            var avg_promo_price_prior = base_price_prior * (1 - avg_disc_prior)
            var disc_dollars_prior = this.state.disc_dollars_prior //promo_units_prior * base_price_prior * avg_disc_prior
            var payback_ratio_prior = this.state.weighted_dollars_incremental_prior / disc_dollars_prior

        }

        catch (error) {
            var total_units_curr = 0
            var inc_units_curr = 0
            var base_units_curr = 0
            var promo_units_curr = 0
            var nonpromo_units_curr = 0
            var lift_curr = 0
            var eff_curr = 0
            var base_price_curr = 0
            var avg_disc_curr = 0
            var avg_promo_price_curr = 0
            var disc_dollars_curr = 0
            var payback_ratio_curr = 0

            var total_units_prior = 0
            var inc_units_prior = 0
            var base_units_prior = 0
            var promo_units_prior = 0
            var nonpromo_units_prior = 0
            var lift_prior = 0
            var eff_prior = 0
            var base_price_prior = 0
            var avg_disc_prior = 0
            var avg_promo_price_prior = 0
            var disc_dollars_prior = 0
            var payback_ratio_prior = 0
        }

        var group1members = this.state.comparisonGroup1.map(member => member.label).join(',')
        var group2members = this.state.comparisonGroup2.map(member => member.label).join(',')

        const classes = makeStyles(theme => ({
            root: {
                width: '100%',
            },
            button: {
                marginRight: theme.spacing(1),
            },
            instructions: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        }));

        const muiTheme = createMuiTheme({
            palette: {
                primary: {
                    main: '#7d32ba',
                },
                secondary: {
                    main: '#ff8b6a',
                },
                white: {
                    main: '#FFF'
                }
            }
        });


        const numitems = this.state.distinctitems.length
        const numbrands = this.state.reportbrands.length
        const numgeos = this.state.distinctgeos.length

        const calculateDateRange = () => {
            console.log('Calculating Date Range')

            function formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            }

            let numweeks = parseInt(this.state.daterangenumweeks) - 1
            let calctype = this.state.rangeStartingPoint.value
            var calculatedrange
            if (calctype === 'MostRecent') {
                let enddate_range = this.state.times_data[1].substring(0, 10)
                let enddate_label = enddate_range
                let numdays_forrange = (numweeks * 7)
                let numdays_forlabel = (numweeks * 7) + 6
                let begindateDate_forrange = new Date(enddate_range + 'T00:00:00')
                let begindateDate_forlabel = new Date(enddate_range + 'T00:00:00')
                let begindate_range = formatDate(begindateDate_forrange.setDate(begindateDate_forrange.getDate() - numdays_forrange));
                let begindate_label = formatDate(begindateDate_forlabel.setDate(begindateDate_forlabel.getDate() - numdays_forlabel));
                console.log(begindate_range)
                console.log(begindate_label)
                console.log(enddate_range)
                console.log(enddate_label)
                calculatedrange = { begindate_range, begindate_label, enddate_range, enddate_label }
            }
            else {

                if (this.state.rangeAddSubtract.value === 'Subtract') {
                    let enddate_range = this.state.selectedendRange.substring(0, 10)
                    let enddate_label = enddate_range
                    let numdays_forrange = (numweeks * 7)
                    let numdays_forlabel = (numweeks * 7) + 6
                    let begindateDate_forrange = new Date(enddate_range + 'T00:00:00')
                    let begindateDate_forlabel = new Date(enddate_range + 'T00:00:00')
                    let begindate_range = formatDate(begindateDate_forrange.setDate(begindateDate_forrange.getDate() - numdays_forrange));
                    let begindate_label = formatDate(begindateDate_forlabel.setDate(begindateDate_forlabel.getDate() - numdays_forlabel));
                    console.log(begindate_range)
                    console.log(begindate_label)
                    console.log(enddate_range)
                    console.log(enddate_label)
                    calculatedrange = { begindate_range, begindate_label, enddate_range, enddate_label }
                }
                else {
                    let begindate_range = this.state.selectedendRange.substring(0, 10)
                    let begindateDate_forrange = new Date(begindate_range + 'T00:00:00')
                    let begindateDate_forlabel = new Date(begindate_range + 'T00:00:00')
                    let numdays_forrange = ((numweeks) * 7)
                    let numdays_forlabel = - 6
                    let enddate_range = formatDate(begindateDate_forrange.setDate(begindateDate_forrange.getDate() + numdays_forrange));
                    let enddate_label = enddate_range
                    let begindate_label = formatDate(begindateDate_forlabel.setDate(begindateDate_forlabel.getDate() + numdays_forlabel));
                    console.log(begindate_range)
                    console.log(begindate_label)
                    console.log(enddate_range)
                    console.log(enddate_label)
                    calculatedrange = { begindate_range, begindate_label, enddate_range, enddate_label }
                }

            }

            //Perform Error Checks on calculated range
            let begindateDate = new Date(calculatedrange.begindate_range + 'T00:00:00')
            let enddateDate = new Date(calculatedrange.enddate_range + 'T00:00:00')
            let begindatasetDate = new Date(this.state.times_data[0].substring(0, 10) + 'T00:00:00')
            let enddatasetDate = new Date(this.state.times_data[1].substring(0, 10) + 'T00:00:00')

            var range_set_warning = false
            var range_set_error = false

            if (begindateDate < begindatasetDate && enddateDate < begindatasetDate) {
                range_set_error = true
            }

            if (begindateDate < begindatasetDate && enddateDate >= begindatasetDate) {
                range_set_warning = true
            }

            if (begindateDate <= enddatasetDate && enddateDate > enddatasetDate) {
                range_set_warning = true
            }

            if (begindateDate > enddatasetDate && enddateDate > enddatasetDate) {
                range_set_error = true
            }

            this.setState({ calculatedrange, range_set_warning, range_set_error })
        }

        const openPriceBandsDefInition = () => {
            //Open Price Bands Definition Page
            let url = Constant.baseurl + 'PNP/PriceBandDefinition?datasetid=' + this.state.reportsettings.dataset_id + '?reportid=' + this.state.reportsettings.report_id + this.state.extraparam
            window.location.href = url
        }

        const openDiscountBandsDefInition = () => {
            //Open Price Bands Definition Page
            let url = Constant.baseurl + 'PNP/DiscountBandDefinition?datasetid=' + this.state.reportsettings.dataset_id + '?reportid=' + this.state.reportsettings.report_id + this.state.extraparam
            window.location.href = url
        }

        const handleChange_IndexSwitchDollars = (event) => {
            let current_switch = this.state.indexSwitchCheckDollars
            let new_switch = !current_switch
            this.setState({
                indexSwitchCheckDollars: new_switch,
            })
            this.runcomparison(null, null, null, new_switch)            
        }

        const handleChangeG1NameBox = (event, newValue) => {
            var value = document.getElementById("g1Name").value
            this.setState({ g1Name: value })
        }

        const handleChangeG2NameBox = (event, newValue) => {
            var value = document.getElementById("g2Name").value
            this.setState({ g2Name: value })
        }

        const handleChange = (event, newValue) => {
            this.setState({ value: newValue })
            this.setState({ active_value: 1 })

            //Find largest value in source of volume....
            var largest_val = 0
            var largest_var = 0
            var total_vol = 0
            var base_cols = ['Competitive Pricing', 'Base Price', 'Core Distribution', 'Seasonality']
            for (var i = 0; i < this.state.source_of_vol_pie_data.length; i++) {
                total_vol += this.state.source_of_vol_pie_data[i].y
                if (base_cols.includes(this.state.source_of_vol_pie_data[i].name)) {
                    //Do Nothing
                }
                else {
                    if (this.state.source_of_vol_pie_data[i].y > largest_val) {
                        largest_val = this.state.source_of_vol_pie_data[i].y
                        largest_var = this.state.source_of_vol_pie_data[i].name
                    }
                }

            }

            try {
                var subsidized_vol_insight = this.state.volume_by_geo[this.state.filters[0]][3]
            }
            catch (error) {
                var subsidized_vol_insight = 0
            }

            if (newValue === 0 || newValue === 1 || newValue === 2) {
                let reportInsightText = (this.state.basetouse_corrected_tracking / (this.state.TotalUnits_all)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " of the Product " + this.state.unitsvar.toLowerCase() + " are everyday base sales, while "
                    + (this.state.inctouse_corrected_tracking / (this.state.TotalUnits_all)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " is incremental sales driven by promotions. "
                    + ((this.state.TotalUnitsOnPromo_all / (this.state.TotalUnits_all))).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " of "
                    + this.state.title_brands_detail
                    + " " + this.state.unitsvar.toLowerCase() + " were sold on promotion, and "
                    + (subsidized_vol_insight / this.state.TotalUnits_all).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    + " were subsidized meaning they were sold on promotion but could have been purchased at a higher price.  There is "
                let reportInsightText_emph = Math.round(
                    this.state.revenue_opportunity

                ).toLocaleString(undefined, { style: 'currency', currency: this.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })


                let reportInsightText_end = " of revenue available to capture from optimizing promotional spending. "
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }

            else if (newValue === 4) {
                let reportInsightText = (largest_val / total_vol).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " of the " + this.state.unitsvar.toLowerCase() + " are incremental driven by " + largest_var
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }

            else if (newValue === 5) {
                let reportInsightText = 'Promotions drove ' + parseInt(this.state.incUnits).toLocaleString() + ' incremental ' + this.state.unitsvar.toLowerCase() + ' or a ' + Math.min((this.state.incUnits / this.state.baseUnits), 10).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' lift for ' + this.state.title_brands_detail + ' during the ' + this.state.title_num_weeks + ' weeks ending ' + this.state.title_end_date + ' in ' + this.state.title_geo_selected
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }

            else {
                let reportInsightText = ''
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }

        };

        const handleChange2 = (event, newValue) => {
            this.setState({ value2: newValue })
            this.setState({ active_value: 2 })

            if (newValue === 0) {
                let reportInsightText = ''
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }



            else {
                let reportInsightText = ''
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }


        };

        const handleChange3 = (event, newValue) => {
            this.setState({ value3: newValue })
            this.setState({ active_value: 3 })

            if (newValue === 0) {

                let volChangePct = (this.state.TotalUnits - this.state.TotalUnits_prior) / this.state.TotalUnits_prior
                if (this.state.data_waterfall.length > 0) {
                    let highestVar = this.state.data_waterfall[0].name
                    let lowestVar = this.state.data_waterfall[this.state.data_waterfall.length - 1].name
                    let reportInsightText = "Units changed " + volChangePct.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " from a YAG driven by " + highestVar + " and offset by " + lowestVar
                    let reportInsightText_emph = ""
                    let reportInsightText_end = ""
                    this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
                }
            }

            else {
                let reportInsightText = ''
                let reportInsightText_emph = ""
                let reportInsightText_end = ""
                this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
            }


        }

        const handleChange4 = (event, newValue) => {
            this.setState({ value4: newValue })
            this.setState({ active_value: 4 })
            let reportInsightText = ''
            let reportInsightText_emph = ""
            let reportInsightText_end = ""
            this.setState({ reportInsightText, reportInsightText_emph, reportInsightText_end })
        }


        const changeTypeGraph = (event) => {
            var type = event.currentTarget.value
            this.setState({ voldecomp_type: type })
        }

        const changeFilterPause = (event) => {
            var filterPause = event.currentTarget.value
            this.setState({ filterPause })
            //If We Change from Pause to Apply, Apply the filters...
            if (filterPause === 'apply') {
                this.filterdataset_new({ 'label': 'Test', 'value': this.state.filters[0] }, 0, [this.state.exclude_brands, this.state.exclude_items] ,filterPause)
            }
        }

        const handleChangeRadio = (event) => {
            var type = event.currentTarget.value
            if (type === 'Base') {
                //Set PromoDisplay to none and BaseDisplay to inherit
                let element = document.getElementById('BaseChartPromoPerf')
                element.style.display = 'block'
                let otherelement = document.getElementById('PromoChartPromoPerf')
                otherelement.style.display = 'none'
                //Set True/False for values for radio buttons
                this.setState({ promoselectedperf: false, baseselectedperf: true })
                window.dispatchEvent(new Event('resize'));

            }
            else {
                //Set PromoDisplay to inherit and BaseDisplay to none
                let element = document.getElementById('BaseChartPromoPerf')
                element.style.display = 'none'
                let otherelement = document.getElementById('PromoChartPromoPerf')
                otherelement.style.display = 'block'
                //Set True/False for values for radio buttons
                this.setState({ promoselectedperf: true, baseselectedperf: false })
                window.dispatchEvent(new Event('resize'));
            }
            //this.setState({ voldecomp_type: type })
        }

        const handleChangeRadio2 = (event) => {

            //Need to determine the already selected options....
            var promoselected_curr = this.state.promoselected
            var baseselected_curr = this.state.baseselected
            var brandselected_curr = this.state.brandselected
            var itemselected_curr = this.state.itemselected
            var pricebandselected_curr = this.state.pricebandselected
            var type = event.currentTarget.value


            //Change the state variables
            if (type === 'Base') {
                this.setState({ baseselected: true, promoselected: false })
                //Define the elements to use...
                /*
                if (itemselected_curr) {
                    var element = document.getElementById('BaseChartByItem')
                    var otherelement = document.getElementById('PromoChartByItem')
                    var elementtohide1 = document.getElementById('PromoChartByBrand')
                    var elementtohide2 = document.getElementById('BaseChartByBrand')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                else if (brandselected_curr) {
                    var element = document.getElementById('BaseChartByBrand')
                    var otherelement = document.getElementById('PromoChartByBrand')
                    var elementtohide1 = document.getElementById('PromoChartByItem')
                    var elementtohide2 = document.getElementById('BaseChartByItem')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                else {
                    var element = document.getElementById('BaseChartByPriceBand')
                    var otherelement = document.getElementById('PromoChartByPriceBand')
                    var elementtohide1 = document.getElementById('PromoChartByItem')
                    var elementtohide2 = document.getElementById('BaseChartByItem')
                    var elementtohide3 = document.getElementById('BaseChartByBrand')
                    var elementtohide4 = document.getElementById('PromoChartByBrand')
                }
                */
            }

            else if (type === 'Promo') {
                this.setState({ baseselected: false, promoselected: true })
                //Define the elements to use...
                /*
                if (itemselected_curr) {
                    var element = document.getElementById('PromoChartByItem')
                    var otherelement = document.getElementById('BaseChartByItem')
                    var elementtohide1 = document.getElementById('PromoChartByBrand')
                    var elementtohide2 = document.getElementById('BaseChartByBrand')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                else if (brandselected_curr) {
                    var element = document.getElementById('PromoChartByBrand')
                    var otherelement = document.getElementById('BaseChartByBrand')
                    var elementtohide1 = document.getElementById('PromoChartByItem')
                    var elementtohide2 = document.getElementById('BaseChartByItem')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                else {
                    var element = document.getElementById('PromoChartByPriceBand')
                    var otherelement = document.getElementById('BaseChartByPriceBand')
                    var elementtohide1 = document.getElementById('PromoChartByItem')
                    var elementtohide2 = document.getElementById('BaseChartByItem')
                    var elementtohide3 = document.getElementById('BaseChartByBrand')
                    var elementtohide4 = document.getElementById('PromoChartByBrand')
                }
                */
            }

            else if (type === 'Brand') {
                this.setState({ brandselected: true, itemselected: false, pricebandselected: false })
                //Define the elements to use...
                /*
                if (promoselected_curr) {
                    var element = document.getElementById('PromoChartByBrand')
                    var otherelement = document.getElementById('BaseChartByBrand')
                    var elementtohide1 = document.getElementById('PromoChartByItem')
                    var elementtohide2 = document.getElementById('BaseChartByItem')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                else {
                    var element = document.getElementById('BaseChartByBrand')
                    var otherelement = document.getElementById('PromoChartByBrand')
                    var elementtohide1 = document.getElementById('PromoChartByItem')
                    var elementtohide2 = document.getElementById('BaseChartByItem')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                */
            }
            
            else if (type === 'Item') {
                this.setState({ brandselected: false, itemselected: true, pricebandselected: false })
                //Define the elements to use...
                /*
                if (promoselected_curr) {
                    var element = document.getElementById('PromoChartByItem')
                    var otherelement = document.getElementById('BaseChartByItem')
                    var elementtohide1 = document.getElementById('PromoChartByBrand')
                    var elementtohide2 = document.getElementById('BaseChartByBrand')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                else {
                    var element = document.getElementById('BaseChartByItem')
                    var otherelement = document.getElementById('PromoChartByItem')
                    var elementtohide1 = document.getElementById('PromoChartByBrand')
                    var elementtohide2 = document.getElementById('BaseChartByBrand')
                    var elementtohide3 = document.getElementById('BaseChartByPriceBand')
                    var elementtohide4 = document.getElementById('PromoChartByPriceBand')
                }
                */
            }

            else if (type === 'PriceBand') {
                this.setState({ brandselected: false, itemselected: false, pricebandselected: true })
                //Define the elements to use...
                /*
                if (promoselected_curr) {
                    var element = document.getElementById('PromoChartByPriceBand')
                    var otherelement = document.getElementById('BaseChartByPriceBand')
                    var elementtohide1 = document.getElementById('PromoChartByBrand')
                    var elementtohide2 = document.getElementById('BaseChartByBrand')
                    var elementtohide3 = document.getElementById('BaseChartByItem')
                    var elementtohide4 = document.getElementById('PromoChartByItem')
                }
                else {
                    var element = document.getElementById('BaseChartByPriceBand')
                    var otherelement = document.getElementById('PromoChartByPriceBand')
                    var elementtohide1 = document.getElementById('PromoChartByBrand')
                    var elementtohide2 = document.getElementById('BaseChartByBrand')
                    var elementtohide3 = document.getElementById('BaseChartByItem')
                    var elementtohide4 = document.getElementById('PromoChartByItem')
                }
                */
            }

            //Hide and Display Elements...
            /*
            let comingfromheight = otherelement.offsetHeight

            element.style.display = 'block'
            otherelement.style.display = 'none'
            elementtohide1.style.display = 'none'
            elementtohide2.style.display = 'none'
            elementtohide3.style.display = 'none'
            elementtohide4.style.display = 'none'
            let newheight = element.offsetHeight

            window.dispatchEvent(new Event('resize'));
            */
        }

        const handleChangeRadio3 = (event) => {
            var type = event.currentTarget.value

            if (type === 'Item') {
                //Set PromoDisplay to none and BaseDisplay to inherit
                let element = document.getElementById('lifteffbyitem')
                element.style.display = 'block'
                let otherelement = document.getElementById('lifteffbybrand')
                otherelement.style.display = 'none'
                let thirdelement = document.getElementById('lifteffbypriceband')
                thirdelement.style.display = 'none'
                //Set True/False for values for radio buttons
                this.setState({ lifteffbybrand: false, lifteffbyitem: true, lifteffbypriceband: false })
                window.dispatchEvent(new Event('resize'));
            }
            else if (type === 'Brand') {
                //Set PromoDisplay to inherit and BaseDisplay to none
                let element = document.getElementById('lifteffbyitem')
                element.style.display = 'none'
                let otherelement = document.getElementById('lifteffbybrand')
                otherelement.style.display = 'block'
                let thirdelement = document.getElementById('lifteffbypriceband')
                thirdelement.style.display = 'none'
                //Set True/False for values for radio buttons
                this.setState({ lifteffbybrand: true, lifteffbyitem: false, lifteffbypriceband: false })
                window.dispatchEvent(new Event('resize'));
            }
            else {
                //Set PromoDisplay to inherit and BaseDisplay to none
                let element = document.getElementById('lifteffbyitem')
                element.style.display = 'none'
                let otherelement = document.getElementById('lifteffbybrand')
                otherelement.style.display = 'none'
                let thirdelement = document.getElementById('lifteffbypriceband')
                thirdelement.style.display = 'block'
                //Set True/False for values for radio buttons
                this.setState({ lifteffbybrand: false, lifteffbyitem: false, lifteffbypriceband: true })
                window.dispatchEvent(new Event('resize'));
            }
            //this.setState({ voldecomp_type: type })
        }

        const handleChangeRadio4 = (event) => {
            var type = event.currentTarget.value

            if (type === 'Base') {
                //Set PromoDisplay to none and BaseDisplay to inherit
                let elements = document.getElementsByName('basechartsattr')
                for (var e = 0; e < elements.length; e++) {
                    elements[e].style.display = 'block'
                }

                let otherelements = document.getElementsByName('promochartsattr')
                for (var e = 0; e < otherelements.length; e++) {
                    otherelements[e].style.display = 'none'
                }

                let lastelements = document.getElementsByName('lifteffchartsattr')
                for (var e = 0; e < lastelements.length; e++) {
                    lastelements[e].style.display = 'none'
                }
                //Set True/False for values for radio buttons
                this.setState({ baseselectedattr: true, promoselectedattr: false, liftselectedattr: false })
                window.dispatchEvent(new Event('resize'));

            }

            else if (type === 'Promo') {
                //Set PromoDisplay to none and BaseDisplay to inherit
                let elements = document.getElementsByName('basechartsattr')
                for (var e = 0; e < elements.length; e++) {
                    elements[e].style.display = 'none'
                }

                let otherelements = document.getElementsByName('promochartsattr')
                for (var e = 0; e < otherelements.length; e++) {
                    otherelements[e].style.display = 'block'
                }

                let lastelements = document.getElementsByName('lifteffchartsattr')
                for (var e = 0; e < lastelements.length; e++) {
                    lastelements[e].style.display = 'none'
                }
                //Set True/False for values for radio buttons
                this.setState({ baseselectedattr: false, promoselectedattr: true, liftselectedattr: false })
                window.dispatchEvent(new Event('resize'));
            }

            if (type === 'Lift') {
                //Set PromoDisplay to none and BaseDisplay to inherit
                let elements = document.getElementsByName('basechartsattr')
                for (var e = 0; e < elements.length; e++) {
                    elements[e].style.display = 'none'
                }

                let otherelements = document.getElementsByName('promochartsattr')
                for (var e = 0; e < otherelements.length; e++) {
                    otherelements[e].style.display = 'none'
                }

                let lastelements = document.getElementsByName('lifteffchartsattr')
                for (var e = 0; e < lastelements.length; e++) {
                    lastelements[e].style.display = 'block'
                }
                //Set True/False for values for radio buttons
                this.setState({ baseselectedattr: false, promoselectedattr: false, liftselectedattr: true })

            }

        }

        const showHelperText = (event) => {

            let origin = event.target
            let origin_type = origin.tagName.toLowerCase()


            if (origin_type === 'button') {
                var element_to_change = origin.name
            }

            else if (origin_type === 'path') {
                let parent = origin.parentElement.parentElement.parentElement

                var element_to_change = parent.name
            }

            else if (origin_type === 'svg') {
                let parent = origin.parentElement.parentElement

                var element_to_change = parent.name
            }

            else if (origin_type === 'span') {
                let parent = origin.parentElement

                var element_to_change = parent.name
            }


            try {
                let element = document.getElementById(element_to_change)
                if (element.style.display === 'inline') {
                    element.style.display = 'none'
                }

                else if (element.style.display === 'none') {
                    element.style.display = 'inline'
                }
            }
            catch (error) {

            }

        }

        const showGroup1Def = (event) => {
            this.setState({ showGroup1DefModal: true })
        }

        const showGroup2Def = (event) => {
            this.setState({ showGroup2DefModal: true })
        }

        const hideGroup1Def = (event) => {
            this.setState({ showGroup1DefModal: false })
            this.runcomparison()
        }

        const hideGroup2Def = (event) => {
            this.setState({ showGroup2DefModal: false })
            this.runcomparison()
        }

        const handleChange_IndexSwitch = (event) => {
            let current_switch = this.state.indexSwitchCheck
            let new_switch = !current_switch

            var data_to_view = []

            if (new_switch === false) {
                data_to_view = this.state.compareIndexGraphData

                this.setState({
                    indexSwitchCheck: new_switch,
                    index_compare_type: 'Indexed',
                    //compareIndexGraphData_toview: data_to_view                    
                })
                this.runcomparison()
            }
            else {
                data_to_view = this.state.compareIndexGraphDataOverUnder

                this.setState({
                    indexSwitchCheck: new_switch,
                    index_compare_type: 'OverUnder',
                    //compareIndexGraphData_toview: data_to_view
                })
                this.runcomparison()
            }
        }

        const handleChange_NoDiscSwitch = (event) => {
            let current_switch = this.state.nodiscSwitchCheck
            let new_switch = !current_switch
            console.log('Setting State')
            this.setState({
                nodiscSwitchCheck: new_switch
            })
        }

        const changeShowDataTable = (event, id) => {

            if (this.state.showDataTableStyle === 'none') {
                var showDataTableStyle = 'inline'
            }

            else {
                var showDataTableStyle = 'none'
            }


            this.setState({ showDataTableStyle }) //, showDataTable: !this.state.showDataTable })

        }



        const closeFilterSnackBar = () => {
            this.setState({ showSnackbar_filter: false })
        }

        const closeCompSnackBar = () => {
            this.setState({ showSnackbar_compran: false })
        }

        const handleOpenReportCriteriaModal = () => {
            this.setState({ ReportCriteriaModalOpen: true })
        }

        const handleCloseReportCriteriaModal = () => {
            this.setState({ ReportCriteriaModalOpen: false })
        }

        const handleOpenAllItemsModal = () => {
            this.setState({ AllItemsModalOpen: true })
        }

        const handleCloseAllItemsModal = () => {
            this.setState({ AllItemsModalOpen: false })
        }

        const handleOpenAllBrandsModal = () => {
            this.setState({ AllBrandsModalOpen: true })
        }

        const handleCloseAllBrandsModal = () => {
            this.setState({ AllBrandsModalOpen: false })
        }

        const handleOpenAllAttrsModal = () => {
            this.setState({ AllAttrsModalOpen: true })
        }

        const handleCloseAllAttrsModal = () => {
            this.setState({ AllAttrsModalOpen: false })
        }

        const handleOpenAllAttrsModalG1 = () => {
            this.setState({ AllAttrsModalOpenG1: true })
        }

        const handleCloseAllAttrsModalG1 = () => {
            this.setState({ AllAttrsModalOpenG1: false })
        }

        const handleOpenAllAttrsModalG2 = () => {
            this.setState({ AllAttrsModalOpenG2: true })
        }

        const handleCloseAllAttrsModalG2 = () => {
            this.setState({ AllAttrsModalOpenG2: false })
        }



        const handleOpenAllGeosModal = () => {
            this.setState({ AllGeosModalOpen: true })
        }

        const handleCloseAllGeosModal = () => {
            this.setState({ AllGeosModalOpen: false })
        }

        const showHideFilters = () => {
            var filterState = this.state.displayFilters;

            if (filterState == 'none') {
                this.setState({ displayFilters: 'flex' })

            }
            else if (filterState == 'flex') {
                this.setState({ displayFilters: 'none' })

            }
        }

        const showHideFilters_comp = () => {
            var filterState = this.state.displayFilters_comp;

            if (filterState == 'none') {
                this.setState({ displayFilters_comp: 'flex' })

            }
            else if (filterState == 'flex') {
                this.setState({ displayFilters_comp: 'none' })

            }
        }


        const showHideReportInsights = () => {
            var insightsState = this.state.displayInsights;

            if (insightsState == 'none') {
                this.setState({ displayInsights: 'flex' })

            }
            else if (insightsState == 'flex') {
                this.setState({ displayInsights: 'none' })

            }
        }

        const showInfoMetrics = event => {

            this.setState({ openInfoMetrics: true })
            this.setState({ anchorElMetricsInfo: event.target })
        }

        const hideInfoMetrics = () => {

            this.setState({ openInfoMetrics: false })
            this.setState({ anchorElMetricsInfo: null })
        }

        const valid = (current) => {
            return current.day() === 6 && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
        };

        const valid_start = (current) => {
            return current.day() === 0 && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
        };

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
            const findid = () => {
                var url = window.location.href;
                var temp = url.split("/");
                var i = unescape(temp[5]);
                var idnum = parseInt(i, 10);
                return idnum;
            }

            const reportid = findid();
            window.location.href = Constant.baseurl + 'PNP/EditReport/' + reportid
        }

        const handleCloseUsererrorAlertPriv = () => {
            this.setState({ errorAlert_priv: false })
            window.location.href = Constant.baseurl + 'PNP/Home'
        }

        const handleCloseUsererrorAlertOther = () => {
            this.setState({ errorAlert_other: false })
        }

        const handleCloseUsererrorAlertCompare = () => {
            this.setState({ errorAlert_compare: false })
        }

        const handleCloseUsererrorAlertComp = () => {
            this.setState({ errorAlertComp: false })
        }

        function valuetext(value) {
            return value.toLocaleString(undefined, { style: 'currency', currency: this.state.currencycode })
        }

        const handleChangeSlider = (event, newValue) => {

            this.setState({ sliderRange: newValue })
        }

        const handleChangeASPMinBox = (event, newValue) => {
            console.log('Setting State')
            var input = document.getElementById("aspmin");
            let value = input.value
            console.log(value)
            var sliderRange = []
            if (isNaN(value)) {
                alert('You Must Only input Numbers.  Resetting to ASP Minimum')
                sliderRange = [this.state.sliderRangeMin, this.state.sliderRange[1]]
                this.setState({ sliderRange })
            }

            else {
                //check that value >= 0 and <= max
                if (value < 0 || value > this.state.sliderRangeMax) {
                    let alerttext = 'Value Entered Must Be Between' + this.state.sliderRangeMin + ' and ' + this.state.sliderRangeMax + '. Resetting ASP Minimum'
                    sliderRange = [this.state.sliderRangeMin, this.state.sliderRange[1]]
                    this.setState({ sliderRange })
                    alert(alerttext)
                }

                else {
                    console.log('Setting State')
                    sliderRange = [value, this.state.sliderRange[1]]
                    this.setState({ sliderRange })
                }

            }

            const setfilters = this.setfilters
            // Execute a function when the user releases a key on the keyboard
            let numruns = 0
            input.addEventListener("keyup", function (event) {
                // Number 13 is the "Enter" key on the keyboard
                if (event.keyCode === 13) {
                    // Cancel the default action, if needed
                    //event.preventDefault();
                    // Trigger the button element with a click
                    console.log('Enter Key Hit')
                    //numruns += 1
                    //console.log(numruns)
                    //if (numruns === 1) {                        
                    //    setTimeout(function () {
                    //        setfilters(sliderRange, 'asprange')
                    //    }, 1000);
                    //}

                    //run setfilters
                }
            });
        }

        const handleChangeASPMaxBox = (event, newValue) => {
            console.log('Setting State')
            var input = document.getElementById("aspmax");
            let value = input.value
            console.log(value)
            var sliderRange = []
            if (isNaN(value)) {
                alert('You Must Only input Numbers.  Resetting to ASP Maximum')
                sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
                this.setState({ sliderRange })
            }

            else {
                //check that value >= 0 and <= max
                if (value < 0 || value > this.state.sliderRangeMax) {
                    let alerttext = 'Value Entered Must Be Between' + this.state.sliderRangeMin + ' and ' + this.state.sliderRangeMax + '. Resetting ASP Maximum'
                    sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
                    this.setState({ sliderRange })
                    alert(alerttext)
                }

                else {
                    console.log('Setting State')
                    sliderRange = [this.state.sliderRange[0], value]
                    this.setState({ sliderRange })
                }

            }

            const setfilters = this.setfilters
            // Execute a function when the user releases a key on the keyboard
            let numruns = 0
            input.addEventListener("keyup", function (event) {
                // Number 13 is the "Enter" key on the keyboard
                if (event.keyCode === 13) {
                    // Cancel the default action, if needed
                    //event.preventDefault();
                    // Trigger the button element with a click
                    console.log('Enter Key Hit')
                    //numruns += 1
                    //console.log(numruns)
                    //if (numruns === 1) {                        
                    //    setTimeout(function () {
                    //        setfilters(sliderRange, 'asprange')
                    //    }, 1000);
                    //}

                    //run setfilters
                }
            });
        }

        const sliderCommitted = () => {

            this.setState({ isLoaded: false })
            var sliderRange = this.state.sliderRange
            const filterdataset_new = this.filterdataset_new
            setTimeout(function () {
                filterdataset_new(sliderRange, 4)
            }, 1000);
        }

        const weekAggFilter = (selectedOptions, position) => {

            var numweeks = selectedOptions['value']
            var weekagg = selectedOptions['value']
            var xDateFormatString = ""
            var weeklytimeperiods = ""
            var axisFormatString = ""
            var axisText = ""
            var stepNum = 1
            var paddingNum = 5
            var fontSizeString = '11px'

            if (weekagg !== 1) {
                xDateFormatString = weekagg + ' Week Period Ending: %Y-%m-%d'
                weeklytimeperiods = weekagg + " Week Time Periods"
                axisFormatString = '%e. %b'
                axisText = weekagg + ' Week Period'
            }

            else {
                xDateFormatString = 'Week Ending: %Y-%m-%d'
                weeklytimeperiods = "Week"
                axisFormatString = '%b %Y'
                axisText = 'Week'
            }

            this.setState({
                xAxisStepNum: stepNum,
                xAxisPadNum: paddingNum,
                xAxisFontSizeString: fontSizeString,
                xAxisText: axisText,
                xDateFormatString: xDateFormatString,
                xAxisLabelFormatString: axisFormatString,
                weeklytimeperiods: weeklytimeperiods,
                numweeks
            })

            this.filterdataset_new({ 'label': 'Test', 'value': this.state.filters[0] }, 0, [this.state.exclude_brands, this.state.exclude_items], this.state.filterPause, numweeks)

        }

        const priceBandFilter = (selectedOptions, position) => {

            //Make a new array of values...     
            let asp_range_values = []
            if (selectedOptions && selectedOptions.length > 0) {
                selectedOptions.map(option => option.value.map(value => asp_range_values.push(value)))
                asp_range_values.sort(function (a, b) { return a - b })

                //Apply filter
                this.setState({ selectedPriceBands: selectedOptions })
                this.filterdataset_new(selectedOptions, 5)
            }
            else {
                this.setState({ selectedPriceBands: this.state.priceBandOptions })
                this.filterdataset_new(this.state.priceBandOptions, 5)
            }
        }

        const openScoreCardMenu = (event) => {
            this.setState({ openScorecardMenu: true, anchorELPos: event.currentTarget })
        }

        const closeScoreCardMenu = () => {
            this.setState({ openScorecardMenu: false })
        }

        const handleCloseComputeDateRangeModal = () => {

            this.setState({ ComputeDateRangeModalOpen: false })
        }

        const computeDateRange = (event) => {
            console.log('Need to Open Modal')
            this.setState({ ComputeDateRangeModalOpen: true })
        }

        const applyCalculatedRange = () => {
            console.log('Applying Range')
            console.log(this.state.calculatedrange)
            let startdate = this.state.calculatedrange.begindate_label            
            let enddate = this.state.calculatedrange.enddate_range
            this.setState({ selectedstart: startdate, reportstartdate: startdate + 'T00:00:00', selectedend: enddate, reportenddate: enddate + 'T00:00:00' });
            this.filterdataset_new([startdate + 'T00:00:00', enddate + 'T00:00:00'], 3)

        }

        const handleChangeDateRangeWeeksBox = (event, newValue) => {
            var input = document.getElementById("daterangenumweeks");
            let value = input.value
            if (isNaN(value)) {
                alert('You Must Only input Numbers.')
                this.setState({ daterangenumweeks: null, calculatedrange: null })
            }
            else {
                this.setState({ daterangenumweeks: value, calculatedrange: null })
            }


        }

        const downloadScoreCardData = async (event) => {
            console.log('Scorecard Export Started')
            var name = 'Key Metrics Data'
            var newdate = new Date();
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
            datestring = datestring.replaceAll(',', '')
            var metadatabrands = ""
            var metadatasegments = ""
            var metadatageos = ""
            var metadatadates = ""
            var metadataattributes = ""
            var metadatapricebands = ""
            var metadataasprange = ""

            var headers

            if (this.state.TwoYAGO === true) {
                headers = ['Metric', 'Current Year Value', 'Two Years Ago Value', 'Difference', '% Difference']
            }

            else {
                headers = ['Metric', 'Current Year Value', 'Previous Year Value', 'Difference', '% Difference']
            }

            var unitsvar = this.state.unitsvar 
            var base_units_curr = this.state.basetouse_corrected_tracking 
            var base_units_prior = this.state.baseUnits_prior 
            var inc_units_curr = this.state.inctouse_corrected_tracking 
            var inc_units_prior = this.state.incUnits_prior 
            var base_unit_pct_curr = (this.state.basetouse_corrected_tracking / this.state.TotalUnits_new)        
            var base_unit_pct_prior = (this.state.baseUnits_prior / this.state.TotalUnits_prior)    
            var inc_unit_pct_curr = (this.state.inctouse_corrected_tracking / this.state.TotalUnits_new)

            var inc_unit_pct_prior = (this.state.incUnits_prior / this.state.TotalUnits_prior)
            var nonpromo_units_curr = (this.state.TotalUnits_new - this.state.TotalUnitsOnPromo)
            var nonpromo_units_prior = (this.state.TotalUnits_prior - this.state.TotalUnitsOnPromo_prior)
            var promo_units_curr =  this.state.TotalUnitsOnPromo 
            var promo_units_prior =  this.state.TotalUnitsOnPromo_prior 
            var nonpromo_unit_pct_curr = (1 - (this.state.TotalUnitsOnPromo / this.state.TotalUnits_new))
            var nonpromo_unit_pct_prior = (1 - (this.state.TotalUnitsOnPromo_prior / this.state.TotalUnits_prior))
            var promo_unit_pct_curr = (this.state.TotalUnitsOnPromo / this.state.TotalUnits_new)
            var promo_unit_pct_prior = (this.state.TotalUnitsOnPromo_prior / this.state.TotalUnits_prior)
            var avg_base_price_curr = (this.state.weighted_dollars_nonpromo / (this.state.TotalUnits_new - this.state.TotalUnitsOnPromo))
            var avg_base_price_prior = (this.state.weighted_dollars_nonpromo_prior / (this.state.TotalUnits_prior - this.state.TotalUnitsOnPromo_prior))
            var avg_promo_price_curr = (this.state.weighted_dollars_nonpromo / (this.state.TotalUnits_new - this.state.TotalUnitsOnPromo)) * (1 - (this.state.totaldisc / this.state.TotalUnits_new))
            var avg_promo_price_prior = (this.state.weighted_dollars_promo_prior / (this.state.TotalUnits_prior - this.state.TotalUnitsOnPromo_prior)) * (1 - (this.state.TotalDisc_prior / this.state.TotalUnits_prior))
            var avg_disc_curr = (this.state.totaldisc / this.state.TotalUnits_new)
            var avg_disc_prior = (this.state.TotalDisc_prior / this.state.TotalUnits_prior)
            var disc_dollars_curr = this.state.disc_dollars_curr
            var disc_dollars_prior = this.state.disc_dollars_prior
            var lift_curr =  Math.min((this.state.inctouse_corrected_tracking / this.state.basetouse_corrected_tracking), 10) 
            var lift_prior =  Math.min((this.state.incUnits_prior / this.state.baseUnits_prior), 10) 
            var eff_curr =  Math.min((this.state.inctouse_corrected_tracking / this.state.TotalUnitsOnPromo), 1) 
            var eff_prior =  Math.min((this.state.incUnits_prior / this.state.TotalUnitsOnPromo_prior), 1) 
            var payback_ratio_curr = this.state.weighted_dollars_incremental / this.state.disc_dollars_curr 
            var payback_ratio_prior = this.state.weighted_dollars_incremental_prior / this.state.disc_dollars_prior 
            var lift_promo_curr =  Math.min((this.state.inctouse_corrected_tracking / this.state.subsidized_vol), 10) 
            var lift_promo_prior = Math.min((this.state.incUnits_prior / this.state.subbasevar_prior), 10) 

            var metrics = [
                , 'Base Units'
                , 'Incremental Units'
                , 'Base Units % of Total'
                , 'Incremental Units % of Total'
                , 'Non-Promoted Units'
                , 'Promoted Units'
                , 'Non-Promoted Units % of Total'
                , 'Promoted Units % of Total'                
                , 'Average Base Price'
                , 'Average Promo Price'
                , 'Average Discount'
                , 'Discount Dollars'
                , 'Market Lift'
                , 'Promoted Lift'
                , 'Efficiency'
                , 'Payback Ratio'
            ]

            var curr_metrics = [                
                  base_units_curr
                , inc_units_curr
                , base_unit_pct_curr
                , inc_unit_pct_curr
                , nonpromo_units_curr
                , promo_units_curr
                , nonpromo_unit_pct_curr
                , promo_unit_pct_curr                
                , avg_base_price_curr
                , avg_promo_price_curr
                , avg_disc_curr
                , disc_dollars_curr
                , lift_curr
                , lift_promo_curr
                , eff_curr
                , payback_ratio_curr
            ]

            var prior_metrics = [
                  base_units_prior
                , inc_units_prior
                , base_unit_pct_prior
                , inc_unit_pct_prior               
                , nonpromo_units_prior
                , promo_units_prior
                , nonpromo_unit_pct_prior
                , promo_unit_pct_prior
                , avg_base_price_prior
                , avg_promo_price_prior
                , avg_disc_prior
                , disc_dollars_prior
                , lift_prior
                , lift_promo_prior
                , eff_prior
                , payback_ratio_prior
            ]

            var diff_metrics = curr_metrics.map((metric, idx) => { return (metric - prior_metrics[idx]) })
            var pct_diff_metrics = curr_metrics.map((metric, idx) => { return ((metric - prior_metrics[idx]) / prior_metrics[idx]) })

            //Try Creating A new Workbook Here....
            let filenameexcel = name + ' - Extracted - ' + datestring
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet('Metrics');
            const worksheet = workbook.getWorksheet('Metrics');


            worksheet.getColumn(1).values = metrics
            worksheet.getColumn(2).values = curr_metrics
            worksheet.getColumn(3).values = prior_metrics
            worksheet.getColumn(4).values = diff_metrics
            worksheet.getColumn(5).values = pct_diff_metrics

            //Set Styling
            function setFormat_Number(worksheet, cols) {
                cols.forEach(col => {
                    worksheet.getCell(col).numFmt = '##,#';
                })

            }

            function setFormat_Percent(worksheet, cols) {
                cols.forEach(col => {
                    worksheet.getCell(col).numFmt = '0.00%';
                })

            }

            function setFormat_Currency(worksheet, cols, currencysymbol) {
                cols.forEach(col => {
                    worksheet.getCell(col).numFmt = currencysymbol + '#,##0';
                })

            }

            function setFormat_Currency_Price(worksheet, cols, currencysymbol) {
                cols.forEach(col => {
                    worksheet.getCell(col).numFmt = currencysymbol + '#,##.00';
                })

            }

            function setFormat_PPTs(worksheet, cols) {
                cols.forEach(col => {
                    let value = worksheet.getCell(col).value
                    let newValue = String((value * 100).toFixed(3)) + ' ppts'
                    worksheet.getCell(col).value = newValue
                })

            }

            function setFormat_Empty(worksheet, cols) {
                cols.forEach(col => {
                    worksheet.getCell(col).value = null
                })

            }

            function setFormat_Decimal(worksheet, cols) {
                cols.forEach(col => {
                    worksheet.getCell(col).numFmt = '##,#.00';
                })

            }
            //Add Headers
            worksheet.insertRow(1, headers);
            
            setFormat_Number(worksheet, ['B2', 'C2', 'D2', 'B3', 'C3', 'D3', 'B6', 'C6', 'D6', 'B7', 'C7', 'D7'])
            setFormat_Percent(worksheet,['B4', 'C4', 'B5', 'C5', 'B8', 'C8', 'B9', 'C9', 'B12', 'C12', 'B14', 'C14','B15', 'C15', 'B16', 'C16', 'B17', 'C17'])
            setFormat_Currency(worksheet, ['B13', 'C13', 'D13'], this.state.currencysymbol)
            setFormat_Currency_Price(worksheet, ['B10', 'C10', 'D10', 'B11', 'C11', 'D11', ], this.state.currencysymbol)
            setFormat_PPTs(worksheet,  ['D4', 'D5', 'D8', 'D9', 'D12', 'D14', 'D15', 'D16'])
            setFormat_Empty(worksheet, ['E4', 'E5', 'E8', 'E9', 'E12', 'E14', 'E15', 'E16', 'E17'])
            setFormat_Decimal(worksheet, ['B17', 'C17', 'D17'])

            worksheet.getColumn(5).numFmt = '0.00%'
            
            //Set Widths
            function setWidthAndAlignment(worksheet, colnum, widthnum) {
                var Col
                Col = worksheet.getColumn(colnum);
                Col.width = widthnum;
                Col.eachCell(function (cell, rowNumber) {
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                })
            }

            setWidthAndAlignment(worksheet, 1, 35)
            setWidthAndAlignment(worksheet, 2, 20)
            setWidthAndAlignment(worksheet, 3, 20)
            setWidthAndAlignment(worksheet, 4, 15)
            setWidthAndAlignment(worksheet, 5, 20)

            //Add Selection Information
            const newsheet = workbook.addWorksheet('Selection Criteria');
            const newworksheet = workbook.getWorksheet('Selection Criteria')
            newworksheet.getColumn(1).values = ['Brands:', 'Category/Subcategory', 'Geography:', 'Dates:', 'Attributes:']
            newworksheet.getColumn(2).values = [metadatabrands, metadatasegments, metadatageos, metadatadates, metadataattributes, metadatapricebands, metadataasprange]

            setWidthAndAlignment(newworksheet, 1, 22)

            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';

            const blob = new Blob([buffer], { type: fileType });

            saveAs(blob, filenameexcel + fileExtension);

        }

        const downloadScoreCardDataYOY_All = async (event) => {
            console.log('All Scorecard Export Started')
        }
        

        const handleChange_twoyago = (event) => {
            let current_switch = this.state.TwoYAGO
            let new_switch = !current_switch
            this.setState({
                TwoYAGO: new_switch
            })
            //var options = this.state.dataforviews_romcompare.map(metric => { return { 'label': metric.metric, 'value': metric.metric } })
            /*
            this.setState({
                TwoYAGO: new_switch,
            }, () => {
                this.changeMetricsCompareROM(options)
            }
            )
            */
        }

        var total_cat_data = this.state.total_cat_data
        var itemnames = (Object.keys(this.state.volume_by_item)).map(item => item)
        var itemattrdict = this.state.productAttrDict
        var volume_by_item_object = {} 
        var volume_by_brand_object = {}
        var volume_by_priceband_object = {}
        try {
            Object.keys(this.state.volume_by_brand_object).forEach(brand => volume_by_brand_object[brand] = this.state.volume_by_brand_object[brand]['totalvolume'])
            Object.keys(this.state.volume_by_item_object).forEach(item => volume_by_item_object[item] = this.state.volume_by_item_object[item]['totalvolume'])
            Object.keys(this.state.volume_by_priceband_object).forEach(band => volume_by_priceband_object[band] = this.state.volume_by_priceband_object[band]['totalvolume'])
        }
        catch (error) {
            //Do Nothing
        }
         

        var compareIndexGraphData_toview_var = []
        if (this.state.index_compare_type === 'Indexed') {
            compareIndexGraphData_toview_var = this.state.compareIndexGraphData
        }
        else if (this.state.index_compare_type === 'OverUnder') {
            compareIndexGraphData_toview_var = this.state.compareIndexGraphDataOverUnder
        }

        var totalvolLookup = {}

        try {
            Object.keys(this.state.volume_by_geo).forEach(geo => totalvolLookup[this.state.datasetgeos_lookup[geo]] = this.state.TotalUnits_all)            
        }
        catch (error) {
            Object.keys(this.state.volume_by_geo).forEach(geo => totalvolLookup[this.state.datasetgeos_lookup[geo]] = 0)            
        }

        try {
            var totalvol = this.state.TotalUnits_all
        }
        catch (error) {
            var totalvol = 0
        }

        var var_total_vol_by_week = this.state.total_vol_by_week
        var var_total_vol_by_week_prior = this.state.total_vol_by_week_prior


        //Find the discount buckets to use....

        let buckets = []
        this.state.discount_buckets.map(bucket => {
            if (Object.keys(this.state.discount_bucket_mapping).includes(bucket)) {
                buckets.push(bucket)
            }
        })

        var unitsvar_forgraphs = this.state.unitsvar

        try {
            var totalvol_attrs = {}
            Object.keys(this.state.attribute_vals).map(
                attribute => {
                    totalvol_attrs[attribute] = {}
                    Object.keys(this.state.attribute_vals[attribute]).map(attr_level => {
                        if (Object.keys(totalvol_attrs[attribute]).includes(attr_level)) {
                            totalvol_attrs[attribute][attr_level] += this.state.attribute_vals[attribute][attr_level]['totalvol']
                        }
                        else {
                            totalvol_attrs[attribute][attr_level] = this.state.attribute_vals[attribute][attr_level]['totalvol']
                        }

                    })
                }
            )
        }

        catch (error) {
            var totalvol_attrs = {}
        }

        console.log(totalvol_attrs)

        try {
            var amounttoadd = Object.values(this.state.data_waterfall).map(row => row.y).reduce(reducer)
        }

        catch (error) {
            var amounttoadd = 0
        }

        try {
            var totaldiffyoy = this.state.discount_buckets.map(bucket => {
                if (this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                    var y = this.state.discount_bucket_mapping_current[bucket] - this.state.discount_bucket_mapping_prior[bucket]
                }
                else if (this.state.discount_bucket_mapping_prior[bucket] && !this.state.discount_bucket_mapping_current[bucket]) {
                    var y = 0 - this.state.discount_bucket_mapping_prior[bucket]
                }
                else if (!this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                    var y = this.state.discount_bucket_mapping_current[bucket] - 0
                }
                else {
                    var y = 0
                }
                return y
            }).reduce(reducer)
        }

        catch (error) {
            var totaldiffyoy = 0
        }

        var total_dollars_weekly_g1_me = this.state.total_dollars_weekly_g1_me
        var totalunits_g1_weekly = this.state.totalunits_g1_weekly
        var total_dollars_weekly_g2_me = this.state.total_dollars_weekly_g2_me
        var totalunits_g2_weekly = this.state.totalunits_g2_weekly

        var g1Name = this.state.g1Name
        var g2Name = this.state.g2Name

        var currencycode = this.state.currencycode
        var currencytype = this.state.currencytype

        return (
            <Layout title="Report Output" >

                <Constant.NPDBackdrop
                    open={!this.state.isLoaded}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <Constant.NPDSnackbar
                    open={this.state.showSnackbar_filter}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={closeFilterSnackBar}
                >
                    <Constant.NPDSnackbarContent
                        message="Data Filtered"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeFilterSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContent>
                </Constant.NPDSnackbar>

                <Constant.NPDSnackbar
                    open={this.state.showSnackbar_compran}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={closeCompSnackBar}
                >
                    <Constant.NPDSnackbarContent
                        message="Comparison Ran"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeCompSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContent>
                </Constant.NPDSnackbar>

                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"No Rows Found"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There were no rows found for the given report.  Please edit the report on the following page.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.errorAlertComp}
                    onClose={handleCloseUsererrorAlertComp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Invalid Geographies Selected"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            When selecting geographies for a comparison group, please ensure you meet the following criteria:
                            <ul>
                                <li>There are no more than two geographies selected</li>
                                <li>If there is more than one geography selected, please ensure it is an ALR geography and the corresponding Rest of Market</li>
                            </ul>
                            Because you have reached this error, the geography for the group will be set to blank.  This means that the geography used in the global filters will apply when running the comparison.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlertComp} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.errorAlert_priv}
                    onClose={handleCloseUsererrorAlertPriv}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Insufficient Priveleges"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You do not have access to the report.  You will now be sent back to the home screen.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlertPriv} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.errorAlert_other}
                    onClose={handleCloseUsererrorAlertOther}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Unexpected Error."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There was an unexpected error.   Please reload.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlertOther} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.errorAlert_compare}
                    onClose={handleCloseUsererrorAlertCompare}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"More Information Needed"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You must select a metric to run a comparison
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlertCompare} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <div name='banner' className='banner_output' style={{ display: 'none' }}>
                    <h1 align='center'>Report Output</h1>
                    {/*<h2 align='center'> These are the metrics for the report with the criteria below</h2>*/}
                </div>
                <div className="outputStyle contentHolderGradient">

                    <ReportToolTips />

                    <div className="col-sm-12" style={{ borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '40px', paddingRight: '40px' }}>
                        <h3>{this.state.reportsettings.report_name}</h3>
                    </div>

                    <div className="row sticky" style={{ paddingLeft: '15px', zIndex: '2', }}>
                        <div className="row col-sm-12" style={{

                        }}>
                            <span style={{
                                color: '#7d32ba',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                marginLeft: '40px',
                                paddingLeft: '10px',
                                backgroundColor: '#FFF',
                                borderRadius: '10px 0px 0px 10px'
                            }}
                                onClick={showHideFilters}
                                key="FiltersLink"
                            >
                                Show/Hide Filters
					        </span>
                            <span style={{
                                color: '#7d32ba',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                paddingLeft: '40px',
                                backgroundColor: '#FFF'
                            }}
                                onClick={handleOpenReportCriteriaModal}
                                key="AllGeosLink"
                            >
                                View Report Criteria
					        </span>
                            <span style={{
                                color: '#7d32ba',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                paddingLeft: '40px',
                                //paddingRight: '10px',
                                backgroundColor: '#FFF',
                                //borderRadius: '0px 10px 10px 0px'
                            }}
                                onClick={showHideReportInsights}
                                key="InsightsLink"
                            >
                                Show/Hide Report Insights
                            </span>
                            <span style={{
                                color: '#7d32ba',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                paddingLeft: '40px',
                                //paddingRight: '10px',
                                backgroundColor: '#FFF',
                                //borderRadius: '0px 10px 10px 0px'
                            }}
                                onClick={openPriceBandsDefInition}
                                key="PriceBandLink"
                            >
                                Define Price Bands
                            </span>
                            <span style={{
                                color: '#7d32ba',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                paddingLeft: '40px',
                                paddingRight: '10px',
                                backgroundColor: '#FFF',
                                borderRadius: '0px 10px 10px 0px'
                            }}
                                onClick={openDiscountBandsDefInition}
                                key="DiscountBandLink"
                            >
                                Define Discount Ranges
					        </span>
                        </div>
                        <br />
                        <div className="row sticky contentHolder" style={{ padding: '10px', display: this.state.displayFilters, maxWidth: '1390px', marginRight: '55px', overflowY:'auto' }}>
                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={this.state.AllAttrsModalOpen}
                                onClose={handleCloseAllAttrsModal}
                                style={{ overflowY: 'scroll' }}
                            >
                                <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                    <h4>Product Attributes</h4>
                                    <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                        {Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                            let attribute_vals = this.state.attribute_vals_filters[attribute]
                                            let idname = attribute + "dropdown"
                                            return <div className='col-sm-4' key={idname}>
                                                <p>{this.state.attribute_lookup[attribute]}</p>
                                                <Select
                                                    id={idname}
                                                    options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                    isMulti
                                                    placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                    onChange={(item, position) => this.filterdataset_new(item, attribute)}
                                                    value={this.state.filters[5][attribute]}
                                                    styles={dropdownStyles}
                                                />
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </Modal>

                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={this.state.ComputeDateRangeModalOpen}
                                onClose={handleCloseComputeDateRangeModal}
                                style={{ overflowY: 'scroll' }}
                            >
                                <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                    <div className='row'>
                                        <div style={{ textAlign: 'left', width: '50%' }}>
                                            <h4>Calculate a Date Range</h4>
                                        </div>
                                        <div style={{ textAlign: 'right', width: '50%' }}>
                                            <IconButton
                                                onClick={handleCloseComputeDateRangeModal}
                                            >
                                                <CancelRoundedIcon style={{ color: '#7d32ba', fontSize: '1.5vw' }} />
                                            </IconButton>

                                        </div>
                                    </div>
                                    <p>Please Select the Number of Weeks</p>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChangeDateRangeWeeksBox}
                                        id="daterangenumweeks"
                                        value={this.state.daterangenumweeks}
                                    />
                                    <p>Where do you want the Range to Start From?</p>
                                    <Select
                                        id="StartingPoint"
                                        options={[{ 'value': 'MostRecent', 'label': 'Most Recent Week in Dataset' }, { 'value': 'Specified', 'label': 'Choose a Specific Week' }]}
                                        placeholder={'Choose a Starting Point'}
                                        onChange={(item) => this.setRangeStartingPoint(item)}
                                        value={[this.state.rangeStartingPoint]}

                                    />
                                    {(this.state.rangeStartingPoint || {})['value'] === 'Specified' ?
                                        <Fragment>
                                            <p>Choose a Date</p>
                                            <Datetime
                                                input={false}
                                                isValidDate={valid}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat={false}
                                                onChange={this.handleDateEndRange}
                                                inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddateRange" }}
                                                value={this.state.selectedendRange}
                                            />
                                            {this.state.selectedendRange ?
                                                <Fragment>
                                                    <p>Do you want to Add or Subtract Weeks from this Date?</p>
                                                    <Select
                                                        id="AddSubtractDateRange"
                                                        options={[{ 'value': 'Add', 'label': 'Add' }, { 'value': 'Subtract', 'label': 'Subtract' }]}
                                                        placeholder={'Add or Subtract Weeks'}
                                                        onChange={(item) => this.setRangeAddSubtract(item)}
                                                        value={[this.state.rangeAddSubtract]}
                                                    />
                                                </Fragment>
                                                :
                                                <Fragment />
                                            }

                                        </Fragment>
                                        :
                                        <Fragment />
                                    }

                                    {((this.state.rangeStartingPoint || {})['value'] && this.state.daterangenumweeks) ?
                                        (this.state.rangeStartingPoint.value === 'Specified' && this.state.selectedendRange && this.state.rangeAddSubtract) ?
                                            <button
                                                onClick={calculateDateRange}
                                                className="btn btn-primary"
                                                autoFocus
                                                style={{ margin: '25px' }}
                                            >
                                                Calculate Date Range
		                        </button>
                                            :
                                            this.state.rangeStartingPoint.value === 'MostRecent' ?
                                                <button
                                                    onClick={calculateDateRange}
                                                    className="btn btn-primary"
                                                    autoFocus
                                                    style={{ margin: '25px' }}
                                                >
                                                    Calculate Date Range
                              </button>
                                                :
                                                <div style={{ padding: '25px' }} />
                                        : <div style={{ padding: '25px' }} />
                                    }

                                    {this.state.calculatedrange ?
                                        <Fragment>
                                            <p>{'Calculated Date Range is as follows: ' + this.state.calculatedrange.begindate_label + ' to ' + this.state.calculatedrange.enddate_label}</p>
                                            {this.state.range_set_warning === true ?
                                                <div>
                                                    <span style={{ color: '#f0ab00' }}>
                                                        <WarningRoundedIcon style={{ color: '#f0ab00' }} />
                                                        {'Part of the Calculated Range Falls outside of the dates available in the dataset.  Please change the number of weeks so the range falls between: '
                                                            + new Date(new Date(((this.state.times_data || [])[0] || "").substring(0, 10) + 'T00:00:00') - (6 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + ' and '
                                                            + new Date(new Date(((this.state.times_data || [])[1] || "").substring(0, 10) + 'T00:00:00') - (0 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                        }
                                                    </span>
                                                </div>
                                                :
                                                <div />
                                            }
                                            {this.state.range_set_error === true ?
                                                <div>
                                                    <span style={{ color: '#f0ab00' }}>
                                                        <ErrorRoundedIcon style={{ color: '#822433' }} />
                                                        {'Part of the Calculated Range Falls outside of the dates available in the dataset.  Please change the number of weeks so the range falls between: '
                                                            + new Date(new Date(((this.state.times_data || [])[0] || "").substring(0, 10) + 'T00:00:00') - (6 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + ' and '
                                                            + new Date(new Date(((this.state.times_data || [])[1] || "").substring(0, 10) + 'T00:00:00') - (0 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                        }
                                                    </span>
                                                </div>
                                                :
                                                <div />
                                            }
                                            {this.state.range_set_error === false ?
                                                <button
                                                    onClick={applyCalculatedRange}
                                                    className="btn btn-primary"
                                                    autoFocus
                                                >
                                                    Use Date Range
		                            </button>
                                                :
                                                <div />
                                            }

                                        </Fragment>
                                        :
                                        <Fragment />
                                    }

                                    <button
                                        onClick={handleCloseComputeDateRangeModal}
                                        className="btn btn-secondarynpd"
                                        autoFocus
                                        style={{ margin: '25px' }}
                                    >
                                        Cancel
		                            </button>

                                </div>
                            </Modal>

                            <div className='col-sm-12'>
                                <h4>Filters</h4>
                            </div>
                            <div className='col-sm-4'>
                                {this.state.filterPause === 'apply' ?                                    
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={changeFilterPause}
                                        value='pause'
                                        style={{ padding: '5px', margin: '5px' }}
                                    >
                                        <Tooltip title="Pause Filters" placement="right">
                                            <PauseRoundedIcon />
                                        </Tooltip>
                                    </IconButton>
                                    
                                    :
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={changeFilterPause}
                                        value='apply'
                                        style={{ padding: '5px', margin: '5px' }}
                                    >
                                        <Tooltip title="Apply Filters" placement="right">
                                            <PlayArrowRoundedIcon />
                                        </Tooltip>
                                    </IconButton>                                
                                }
                                
                            </div>
                            <div className='col-sm-4'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        checked={this.state.exclude_brands}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                        onChange={this.handleToggle_exclude_brands}
                                    />
                                    Exclude Brands?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-4'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        checked={this.state.exclude_items}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                        onChange={this.handleToggle_exclude_items}
                                    />
                                    Exclude Items?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-4'>
                                <p>Geographies</p>
                                <Select
                                    id="GeoFilter1"
                                    options={this.state.geos}
                                    placeholder={'Select Geographies'}
                                    onChange={(item, position) => this.filterdataset_new(item, 0)}
                                    value={[{ 'label': this.state.datasetgeos_lookup && this.state.datasetgeos_lookup[this.state.filters[0]], 'value': this.state.filters[0] }]}
                                />
                            </div>
                            <div className='col-sm-4'>
                                <p>Brands</p>
                                <Select
                                    id="BrandFilter1"
                                    options={this.state.eligiblebrandscomp.map(brand => {
                                        return { 'value': brand, 'label': brand }
                                    })}
                                    //options={Object.keys(this.state.volume_by_brand).map(brand => {
                                    //    return { 'value': brand, 'label': brand }
                                    //})}
                                    //options={this.state.brands} //Keeping this here if we want to revert back
                                    isMulti
                                    placeholder={'Select Brands'}
                                    onChange={(item, position) => this.filterdataset_new(item, 1)}
                                    value={this.state.selectedBrands_filter}
                                    styles={dropdownStyles}
                                />
                            </div>
                            <div className='col-sm-4'>
                                <p>Items</p>
                                <Select
                                    id="itemdropdown"
                                    options={this.state.eligibleitems_filters.map(item => {
                                        return { 'value': item, 'label': item }
                                    })}
                                    //options={this.state.itemsforfilter} //Keeping this here if we want to revert back
                                    isMulti
                                    placeholder={'Select Items'}
                                    onChange={(item, position) => this.filterdataset_new(item, 2)}
                                    value={this.state.selectedItems_filter}
                                    styles={dropdownStyles}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <span className="row">
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={computeDateRange}
                                        //value='pause'
                                        style={{ padding: '5px', margin: '5px' }}
                                    >
                                        <Tooltip title="Compute a Date Range" placement="left">
                                            <DateRangeRoundedIcon />
                                        </Tooltip>
                                    </IconButton>
                                    <p style={{ marginTop: '1rem' }}>Start Date</p>
                                </span>
                                <Datetime
                                    input={true}
                                    isValidDate={valid_start}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    onChange={this.handleDateStart}
                                    inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportstartdate" }}
                                    value={this.state.selectedstart}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <p style={{ marginTop: '1rem' }}>End Date</p>
                                <Datetime
                                    input={true}
                                    isValidDate={valid}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    onChange={this.handleDateEnd}
                                    inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddate" }}
                                    value={this.state.selectedend}
                                />
                            </div>
                            <div className='col-sm-4'>
                                <p style={{ marginTop: '1rem' }}>Average Selling Price</p>
                                <div className='row col-sm-12 '>
                                    <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            //placeholder="Report Name"
                                            //inputProps={{ 'aria-label': 'naked', id: "reportname" }}
                                            InputProps={{
                                                startAdornment: <Constant.NPDInputAdornment position="start"> &#x24; </Constant.NPDInputAdornment>
                                            }}
                                            onChange={handleChangeASPMinBox}
                                            onKeyUp={(event) => {
                                                if (event.key == 'Enter')
                                                    sliderCommitted()
                                            }}
                                            id="aspmin"
                                            //style={dropdownInput}
                                            value={this.state.sliderRange[0]}
                                        />                                            
                                    </div>
                                    <div className='col-sm-6 '>
                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                color="primary"
                                                value={this.state.sliderRange}
                                                onChange={handleChangeSlider}
                                                onChangeCommitted={sliderCommitted}
                                                valueLabelDisplay="auto"
                                                //marks={true}
                                                //step={1}
                                                aria-labelledby="range-slider"
                                                //getAriaValueText={valuetext}
                                                //min={0}
                                                max={this.state.sliderRangeMax}
                                            />
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <Constant.NPDInputAdornment position="start"> &#x24; </Constant.NPDInputAdornment>
                                            }}
                                            onChange={handleChangeASPMaxBox}
                                            onKeyUp={(event) => {
                                                if (event.key == 'Enter')
                                                    sliderCommitted()
                                            }}
                                            id="aspmax"
                                            value={this.state.sliderRange[1]}
                                        />                                            
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-12'>
                                <br />
                                {Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                    <h5>
                                        Product Attributes &nbsp;
                                    </h5>
                                    :
                                    <h5>
                                        Product Attributes &nbsp;
                                    <span style={{
                                            color: '#7d32ba',
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                            fontSize: '13px'
                                        }}
                                            onClick={handleOpenAllAttrsModal}
                                            key="AllAttrsLink"
                                        >
                                            View All {Object.keys(this.state.attribute_vals_filters).length} Attributes
									</span>
                                    </h5>
                                }
                            </div>
                            {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                            {

                                Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                    Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                        let attribute_vals = this.state.attribute_vals_filters[attribute]
                                        let idname = attribute + "dropdown"
                                        return <div className='col-sm-4' key={idname}>
                                            <p>{this.state.attribute_lookup[attribute]}</p>
                                            <Select
                                                id={idname}
                                                options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                isMulti
                                                placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                onChange={(item, position) => this.filterdataset_new(item, attribute)}
                                                value={this.state.filters[5][attribute]}
                                                styles={dropdownStyles}
                                            />
                                        </div>
                                    })
                                    :
                                    Object.keys(this.state.attribute_vals_filters).map((attribute, idx) => {
                                        let attribute_vals = this.state.attribute_vals_filters[attribute]
                                        let idname = attribute + "dropdown"
                                        if (idx < 9) {
                                            return <div className='col-sm-4' key={idname}>
                                                <p>{this.state.attribute_lookup[attribute]}</p>
                                                <Select
                                                    id={idname}
                                                    options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                    isMulti
                                                    placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                    onChange={(item, position) => this.filterdataset_new(item, attribute)}
                                                    value={this.state.filters[5][attribute]}
                                                    styles={dropdownStyles}
                                                />
                                            </div>
                                        }

                                        else {
                                            return <div className='col-sm-4' key={idname} />
                                        }
                                    })

                            }
                            <div className='col-sm-12'> 
                                <h5>Price Band and Week Aggregation</h5>
                            </div>
                            <div className='col-sm-6'>                                
                                <p>Price Band</p>
                                <Select
                                    id='pricebandfilter'
                                    options={this.state.priceBandOptions}
                                    isMulti
                                    placeholder='Select a Price Band'
                                    onChange={(item, position) => priceBandFilter(item, 4)}
                                    styles={dropdownStyles}                                    
                                    //value={this.state.filters[5][attribute]}
                                />
                            </div>
                            <div className='col-sm-6' >
                                <p>Week Aggregation</p>
                                <Select
                                    id='weekaggregation'
                                    options={[1, 4, 5, 13, 26].map(num => {
                                        return { 'value': num, 'label': num + ' Weeks' }
                                    })}
                                    placeholder='Select a Week Aggregation'
                                    onChange={(item, position) => weekAggFilter(item, 6)}
                                    value={[{ 'label': this.state.numweeks + ' Weeks', 'value': this.state.numweeks }]}                                    
                                />
                            </div>
                            <div className='col-sm-12' style={{ height: '25px' }}/>

                        </div>
                    </div>
                    {/*Report Criteria Modal*/}

                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.ReportCriteriaModalOpen}
                        onClose={handleCloseReportCriteriaModal}
                    >
                        <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
                            <h5 style={{ padding: '0px' }}>Report Criteria</h5>
                            <div className="" style={{ width: '100%' }} >
                                <div className="row " style={{ padding: '0px' }}>
                                    <div className="col-sm-6 "><h4>Time Frame</h4> <p>{String(this.state.reportsettings.start_date).substring(0, 10)} to {String(this.state.reportsettings.end_date).substring(0, 10)}</p></div>
                                    <div className="col-sm-6 " align='right'><h4>Last Updated</h4> <p>{String(this.state.reportsettings.time_updated_adj).substring(0, 19).replace('T', ' ')}</p></div>

                                </div>
                                <div className="row " style={{ padding: '0px', marginBottom: '0px', paddingBottom: '0px' }}>
                                    <div className="col-sm-4 " style={{ marginRight: '0px', marginLeft: '0px' }}><h4>Geographies</h4></div>
                                    <div className="col-sm-4 " style={{ marginRight: '0px', marginLeft: '0px' }}><h4>Brands</h4></div>
                                    <div className="col-sm-4 " style={{ marginRight: '0px', marginLeft: '0px' }}><h4>Items</h4></div>
                                </div>
                                <div className="row" style={{ padding: '0px' }}>

                                    <div className="col-sm-4" style={{ maxHeight: '100px', overflowY: 'auto', borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginRight: '0px', marginLeft: '0px' }}>
                                        <div>
                                            {
                                                this.state.distinctgeos.map((geo, idx) => {
                                                    if (numgeos > 4) {
                                                        if (idx < 3) {
                                                            return <span key={geo}>{geo}<br /></span>
                                                        }
                                                        else if (idx === 3) {
                                                            return <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold'
                                                            }}
                                                                onClick={handleOpenAllGeosModal}
                                                                key="AllGeosLink"
                                                            >
                                                                View All {numgeos} Geographies
										</span>
                                                        }

                                                    }
                                                    else {
                                                        return <span key={geo}>{geo}<br /></span>
                                                    }

                                                })
                                            }


                                        </div>
                                    </div>
                                    <Modal
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                        open={this.state.AllGeosModalOpen}
                                        onClose={handleCloseAllGeosModal}
                                    >
                                        <div style={{ transform: 'translate(150%, 0%)', backgroundColor: '#fff', width: '25%', overflowY: 'auto' }} className="contentHolder">
                                            <h4>Brands</h4>
                                            {this.state.distinctgeos.map((geo, idx) => {
                                                return <span key={geo}>{geo}<br /></span>
                                            })}
                                        </div>
                                    </Modal>
                                    <div className="col-sm-4" style={{ maxHeight: '100px', overflowY: 'auto', borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginRight: '0px', marginLeft: '0px' }}>
                                        <div>
                                            {
                                                this.state.reportbrands.map((brand, idx) => {
                                                    if (numbrands > 4) {
                                                        if (idx < 3) {
                                                            return <span key={brand}>{brand}<br /></span>
                                                        }
                                                        else if (idx === 3) {
                                                            return <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold'
                                                            }}
                                                                onClick={handleOpenAllBrandsModal}
                                                                key="AllBrandsLink"
                                                            >
                                                                View All {numbrands} Brands
										</span>
                                                        }

                                                    }
                                                    else {
                                                        return <span key={brand}>{brand}<br /></span>
                                                    }

                                                })
                                            }
                                        </div>
                                    </div>
                                    {/*This is the Modal that houses all of the items.  It will be shown on the click of the View All Items Span Above*/}
                                    <Modal
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                        open={this.state.AllBrandsModalOpen}
                                        onClose={handleCloseAllBrandsModal}
                                    >
                                        <div style={{ transform: 'translate(150%, 0%)', backgroundColor: '#fff', width: '25%', overflowY: 'auto' }} className="contentHolder">
                                            <h4>Brands</h4>
                                            {this.state.reportbrands.map((brand, idx) => {
                                                return <span key={brand}>{brand}<br /></span>
                                            })}
                                        </div>
                                    </Modal>




                                    <div className="col-sm-4" style={{ maxHeight: '125px', overflowY: 'auto', borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginRight: '0px', marginLeft: '0px', wordWrap: 'normal', whiteSpace: 'nowrap' }}>
                                        <div>
                                            {
                                                this.state.distinctitems.map((item, idx) => {
                                                    if (numitems > 4) {
                                                        if (idx < 3) {
                                                            return <span key={item}>{item}<br /></span>
                                                        }
                                                        else if (idx === 3) {
                                                            return <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold'
                                                            }}
                                                                onClick={handleOpenAllItemsModal}
                                                                key="AllItemsLink"
                                                            >
                                                                View All {numitems} Items
											</span>
                                                        }

                                                    }
                                                    else {
                                                        return <span key={item}>{item}<br /></span>
                                                    }

                                                })
                                            }
                                        </div>

                                    </div>
                                    {/*This is the Modal that houses all of the items.  It will be shown on the click of the View All Items Span Above*/}
                                    <Modal
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                        open={this.state.AllItemsModalOpen}
                                        onClose={handleCloseAllItemsModal}
                                    >
                                        <div style={{ transform: 'translate(150%, 0%)', backgroundColor: '#fff', width: '25%', overflowY: 'auto' }} className="contentHolder">
                                            <h4>Items</h4>
                                            {this.state.distinctitems.map((item, idx) => {
                                                return <span key={item}>{item}<br /></span>
                                            })}
                                        </div>
                                    </Modal>

                                </div>
                            </div>
                            <ThemeProvider theme={muiTheme}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCloseReportCriteriaModal}
                                    className={classes.button}
                                >
                                    Close Report Criteria
	                            </Button>
                            </ThemeProvider>

                        </div>
                    </Modal>

                    <div className="row sticky" style={{ display: this.state.displayInsights, zIndex: '1' }}>
                        <div className="col-sm-12" style={{ maxWidth: '1500px' }} >
                            <div className="contentHolderTight" align='center'>
                                {this.state.isLoaded ?
                                    <span>
                                        <h5>Market Summary</h5>
                                        <p>{this.state.reportInsightText}  <b>{this.state.reportInsightText_emph}</b>  {this.state.reportInsightText_end} </p>
                                    </span>
                                    : <div />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-sm-12" style={{ maxWidth: '1500px' }}>

                            <div align='center' className="contentHolderTight" >
                                <h5>Performance Evaluation</h5>

                                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }} className="">
                                    <Constant.NPDTabs
                                        value={this.state.value}
                                        onChange={handleChange}
                                        aria-label="simple tabs example"
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Constant.NPDTab label="BASE AND INCREMENTAL"  {...a11yProps(0)} />
                                        <Constant.NPDTab label="BASE AND INCREMENTAL BY ATTRIBUTE" {...a11yProps(1)} />
                                        <Constant.NPDTab label="BASE AND INCREMENTAL BY PRODUCT" {...a11yProps(2)} />
                                        <Constant.NPDTab label="LIFT, EFFICIENCY & DISCOUNT BY PRODUCT" {...a11yProps(3)} />
                                        <Constant.NPDTab label={"SOURCE OF " + this.state.unitsvar.toUpperCase()} {...a11yProps(4)}  />
                                        <Constant.NPDTab label={this.state.unitsvar.toUpperCase() + " DECOMPOSITION"} {...a11yProps(5)}  />
                                    </Constant.NPDTabs>
                                </Constant.NPDAppbar>
                                {this.state.isLoaded ?

                                    <TabPanel value={this.state.value} index={0}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>



                                        </div>
                                        <div className="row" style={{ width: "100%", height: "100%" }}>
                                            <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                < PromotedNonPromoted
                                                    NonPromotedBarData={(Object.keys(this.state.volume_by_geo)).map(geo => (this.state.volume_by_geo[geo][1] - this.state.volume_by_geo[geo][0]))}
                                                    PromotedBarData={(Object.keys(this.state.volume_by_geo)).map(geo => this.state.volume_by_geo[geo][0])}
                                                    showDataTableStyle={this.state.showDataTableStyle}
                                                    showDataTable={this.state.showDataTable}
                                                    unitsvar={this.state.unitsvar}
                                                    categories={(Object.keys(this.state.volume_by_geo)).map(geo => this.state.datasetgeos_lookup[geo])}
                                                    attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                    chart_title_info={'<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                    TotalBarLookup={totalvolLookup}
                                                    level='Geography'
                                                    stackingType={this.state.stackingType}
                                                />
                                            </div>
                                            <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                < BaseIncremental
                                                    UnSubBarData={Object.keys(this.state.volume_by_geo).map(geo => this.state.volume_by_geo[geo][4])}
                                                    SubBarData={Object.keys(this.state.volume_by_geo).map(geo => this.state.volume_by_geo[geo][3])}
                                                    IncBarData={Object.keys(this.state.volume_by_geo).map(geo => this.state.volume_by_geo[geo][2])}
                                                    showDataTableStyle={this.state.showDataTableStyle}
                                                    showDataTable={this.state.showDataTable}
                                                    unitsvar={this.state.unitsvar}
                                                    categories={(Object.keys(this.state.volume_by_geo)).map(geo => this.state.datasetgeos_lookup[geo])}
                                                    attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                    chart_title_info={'<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                    TotalBarLookup={totalvolLookup}
                                                    level='Geography'
                                                    stackingType={this.state.stackingType}
                                                /> 
                                            </div>
                                        </div>

                                    </TabPanel>

                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value} index={1}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                            <ThemeProvider theme={muiTheme}>
                                                <Radio
                                                    checked={this.state.baseselectedattr}
                                                    onChange={handleChangeRadio4}
                                                    value="Base"
                                                    name="BaseRadio"
                                                    label="Base/Incremental"
                                                    inputProps={{ 'aria-label': 'Base/Incremental' }}
                                                />
                                                <span>Base/Incremental</span>
                                                <Radio
                                                    checked={this.state.promoselectedattr}
                                                    onChange={handleChangeRadio4}
                                                    value="Promo"
                                                    name="PromoRadio"
                                                    label="Promoted/NonPromoted"
                                                    inputProps={{ 'aria-label': 'Promoted/NonPromoted' }}
                                                />
                                                <span>Promoted/NonPromoted</span>
                                                <Radio
                                                    checked={this.state.liftselectedattr}
                                                    onChange={handleChangeRadio4}
                                                    value="Lift"
                                                    name="LiftRadio"
                                                    label="Lift/Efficiency"
                                                    inputProps={{ 'aria-label': 'Lift/Efficiency' }}
                                                />
                                                <span>Lift/Efficiency</span>
                                                <Checkbox
                                                    checked={this.state.stackingType_checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': 'Show 100% Stacked Bar?' }}
                                                    onChange={this.handleToggle_stackingType}
                                                />
                                                <span style={{ lineHeight: '5' }} >Show 100% Stacked Bar?</span>
                                            </ThemeProvider>

                                        </div>
                                        <div className="row">
                                            <div className='col-sm-12' align='left'>
                                                <span className='formfieldtitle'>Select Attributes to View</span>
                                            </div>
                                            <div className='col-sm-6'>
                                                <Select
                                                    id="attribute_graph_selections"
                                                    options={[{ 'label': 'All', 'value': 'All' }].concat(this.state.attribute_col_names.map(attr => { return { 'label': this.state.attribute_lookup[attr], 'value': attr } }))}
                                                    placeholder={'Select Attributes to View'}
                                                    isMulti
                                                    onChange={(item, action) => this.setAttrsForGraphs(item, action)}
                                                    value={this.state.graph_selected_attributes_labels}
                                                    styles={dropdownStyles}
                                                />
                                            </div>
                                            <div className='col-sm-2'>
                                                {this.state.viewattrnum === 0 ?
                                                    <Fragment />
                                                    :
                                                    <Fragment>
                                                        <IconButton
                                                            className="btn btn-primary ChangeDataTableButton"
                                                            onClick={this.moveviewdown_attr}
                                                            style={{
                                                                padding: '5px', margin: '5px'
                                                            }}
                                                            id='MoveViewDownAttr'
                                                        >
                                                            <Tooltip title={"View Graphs for " + this.state.attribute_lookup[this.state.graph_selected_attributes[this.state.viewattrnum - 1]]} placement="right">
                                                                < SkipPreviousRoundedIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Fragment>
                                                }
                                                {this.state.viewattrnum === (this.state.graph_selected_attributes.length - 1) ?
                                                    <Fragment />
                                                    :
                                                    <Fragment>
                                                        <IconButton
                                                            className="btn btn-primary ChangeDataTableButton"
                                                            onClick={this.moveviewup_attr}
                                                            style={{
                                                                padding: '5px', margin: '5px'
                                                            }}
                                                            id='MoveViewUpAttr'
                                                        >
                                                            <Tooltip title={"View Graphs for " + this.state.attribute_lookup[this.state.graph_selected_attributes[this.state.viewattrnum + 1]]} placement="right">
                                                                <SkipNextRoundedIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Fragment>
                                                }
                                            </div>
                                            <div className='col-sm-4'>
                                                <span style={{ lineHeight: '2.5' }}>
                                                    {"Viewing Graphs for  " + this.state.attribute_lookup[this.state.graph_selected_attributes[this.state.viewattrnum]]}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row" >
                                            {this.state.graph_selected_attributes.map((attribute, idx) => {
                                                if (idx === this.state.viewattrnum) {
                                                    return <div className='col-sm-12' style={{ height: '100%', width: "100%", }} name="basechartsattr" key={"basechartsattr_" + attribute}>
                                                        < BaseIncremental
                                                            UnSubBarData={this.state.attribute_vals_sorted[attribute].map(attr_val => this.state.attribute_vals[attribute][attr_val].unsubsidizedbase)}
                                                            SubBarData={this.state.attribute_vals_sorted[attribute].map(attr_val => this.state.attribute_vals[attribute][attr_val].subsidizedbase)}
                                                            IncBarData={this.state.attribute_vals_sorted[attribute].map(attr_val => this.state.attribute_vals[attribute][attr_val].incvol + this.state.attribute_vals[attribute][attr_val].holvol)}
                                                            showDataTableStyle={this.state.showDataTableStyle}
                                                            showDataTable={this.state.showDataTable}
                                                            unitsvar={this.state.unitsvar}
                                                            categories={this.state.attribute_vals_sorted[attribute]}
                                                            attr_caption_text={'The Levels of the Attribute are Sorted by Total ' + this.state.unitsvar}
                                                            chart_title_info={' by ' + this.state.attribute_lookup[attribute] + ' <br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                            TotalBarLookup={totalvol_attrs[attribute]}
                                                            level={this.state.attribute_lookup[attribute]}
                                                            stackingType={this.state.stackingType}
                                                            height={.5}
                                                        />
                                                    </div>
                                                }
                                                else {
                                                    return null
                                                }
                                            })}
                                        </div>
                                        <div className="row">
                                            {this.state.graph_selected_attributes.map((attribute,idx) => {    
                                                if (idx === this.state.viewattrnum) {
                                                    return <div className='col-sm-12' style={{ height: '100%', width: "100%", display: 'none' }} name="promochartsattr" key={"promochartsattr_" + attribute}>
                                                        < PromotedNonPromoted
                                                            NonPromotedBarData={this.state.attribute_vals_sorted[attribute].map(attr_val => this.state.attribute_vals[attribute][attr_val].nonpromovol)}
                                                            PromotedBarData={this.state.attribute_vals_sorted[attribute].map(attr_val => this.state.attribute_vals[attribute][attr_val].promovol)}
                                                            showDataTableStyle={this.state.showDataTableStyle}
                                                            showDataTable={this.state.showDataTable}
                                                            unitsvar={this.state.unitsvar}
                                                            categories={this.state.attribute_vals_sorted[attribute]}
                                                            attr_caption_text={'The Levels of the Attribute are Sorted by Total ' + this.state.unitsvar}
                                                            chart_title_info={' by ' + this.state.attribute_lookup[attribute] + ' <br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                            TotalBarLookup={totalvol_attrs[attribute]}
                                                            level={this.state.attribute_lookup[attribute]}
                                                            stackingType={this.state.stackingType}
                                                            height={.5}
                                                        />
                                                    </div>
                                                }
                                                else {
                                                    return null
                                                }
                                            })}
                                        </div>
                                        <div className="row">
                                            {this.state.graph_selected_attributes.map((attribute, idx) => {
                                                if (idx === this.state.viewattrnum) {
                                                    return <div className='col-sm-12' style={{ height: '100%', width: "100%", display: 'none' }} name="lifteffchartsattr" key={"lifteffchartsattr_" + attribute}>
                                                        <LiftEffDiscPct
                                                            LiftGraphData={this.state.attribute_vals_sorted[attribute].map(attr_val => Math.min((this.state.attribute_vals[attribute][attr_val].inctouseforcalc / this.state.attribute_vals[attribute][attr_val].basetouseforcalc), 10))}
                                                            PromoLiftGraphData={this.state.attribute_vals_sorted[attribute].map(attr_val => Math.min((this.state.attribute_vals[attribute][attr_val].inctouseforcalc / this.state.attribute_vals[attribute][attr_val].subsidizedbase), 10))}
                                                            EffGraphData={this.state.attribute_vals_sorted[attribute].map(attr_val => Math.min((this.state.attribute_vals[attribute][attr_val]['inctouseforcalc'] / this.state.attribute_vals[attribute][attr_val]['promovol']), 1))}
                                                            DiscGraphData={this.state.attribute_vals_sorted[attribute].map(attr_val => (this.state.attribute_vals[attribute][attr_val]['totaldisc'] / this.state.attribute_vals[attribute][attr_val]['totalvol']))}
                                                            showDataTableStyle={this.state.showDataTableStyle}
                                                            showDataTable={this.state.showDataTable}
                                                            unitsvar={this.state.unitsvar}
                                                            categories={this.state.attribute_vals_sorted[attribute]}
                                                            attr_caption_text={'The Levels of the Attribute are Sorted by Total ' + this.state.unitsvar}
                                                            chart_title_info={' <br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                            level={this.state.attribute_lookup[attribute]}
                                                            //height='2'
                                                        />
                                                    </div>
                                                }
                                                else {
                                                    return null
                                                }
                                            })}
                                        </div>
                                    </TabPanel>
                                    : <div />}
                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value} index={2}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                            <ThemeProvider theme={muiTheme}>
                                                <Radio
                                                    checked={this.state.baseselected}
                                                    onChange={handleChangeRadio2}
                                                    value="Base"
                                                    name="BaseRadio2"
                                                    label="Base/Incremental"
                                                    inputProps={{ 'aria-label': 'Base/Incremental' }}
                                                />
                                                <span>Base/Incremental</span>
                                                <Radio
                                                    checked={this.state.promoselected}
                                                    onChange={handleChangeRadio2}
                                                    value="Promo"
                                                    name="PromoRadio2"
                                                    label="Promoted/NonPromoted"
                                                    inputProps={{ 'aria-label': 'Promoted/NonPromoted' }}
                                                />
                                                <span>Promoted/NonPromoted</span>
                                                <Radio
                                                    checked={this.state.brandselected}
                                                    onChange={handleChangeRadio2}
                                                    value="Brand"
                                                    name="BrandRadio2"
                                                    label="Brand"
                                                    inputProps={{ 'aria-label': 'Brand' }}
                                                />
                                                <span>Brand</span>
                                                <Radio
                                                    checked={this.state.itemselected}
                                                    onChange={handleChangeRadio2}
                                                    value="Item"
                                                    name="ItemRadio2"
                                                    label="Item"
                                                    inputProps={{ 'aria-label': 'Item' }}
                                                />
                                                <span>Item</span>
                                                <Radio
                                                    checked={this.state.pricebandselected}
                                                    onChange={handleChangeRadio2}
                                                    value="PriceBand"
                                                    name="PriceBandRadio2"
                                                    label="Price Band"
                                                    inputProps={{ 'aria-label': 'Price Band' }}
                                                />
                                                <span>Price Band</span>
                                                <Checkbox
                                                    checked={this.state.stackingType_checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': 'Show 100% Stacked Bar?' }}
                                                    onChange={this.handleToggle_stackingType}
                                                />
                                                <span style={{ lineHeight: '5' }} >Show 100% Stacked Bar?</span>
                                            </ThemeProvider>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }} className="row">
                                            {this.state.itemselected ?
                                                <div className="row col-sm-12">
                                                    <div align='left' className="col-sm-3" >
                                                        <span className="formfieldtitle">Select Item Rank (Based on Unit Sales)</span>
                                                        <Select
                                                            options={this.state.itemRankOptions}
                                                            placeholder={'Select Item Ranks'}
                                                            onChange={(item, position) => this.changeItemRank(item, 0)}
                                                            value={this.state.selectedItemRank}
                                                        />
                                                    </div>
                                                    <div align='left' className="col-sm-3" ></div>
                                                    <div align='left' className="col-sm-3" ></div>
                                                    <div align='left' className="col-sm-3" ></div>
                                                </div>
                                                :
                                                this.state.brandselected ?
                                                    <div className="row col-sm-12">
                                                        <div align='left' className="col-sm-3" >
                                                            <span className="formfieldtitle">Select Brand Rank (Based on Unit Sales)</span>
                                                            <Select
                                                                options={this.state.brandRankOptions}
                                                                placeholder={'Select Brand Ranks'}
                                                                onChange={(item, position) => this.changeBrandRank(item, 0)}
                                                                value={this.state.selectedBrandRank}
                                                            />
                                                        </div>
                                                        <div align='left' className="col-sm-3" ></div>
                                                        <div align='left' className="col-sm-3" ></div>
                                                        <div align='left' className="col-sm-3" ></div>
                                                    </div>
                                                    :
                                                    <div className="row col-sm-12" />
                                            } 

                                            {(this.state.baseselected && this.state.itemselected) ? 
                                                <div id="BaseChartByItem" className='col-sm-12' style={{ width: "100%", height: "100%"}}>
                                                    < BaseIncremental
                                                        //UnSubBarData={(Object.keys(this.state.volume_by_item_object)).map(item => this.state.volume_by_item_object[item]['unsubsidizedbase'])}
                                                        //SubBarData={(Object.keys(this.state.volume_by_item_object)).map(item => this.state.volume_by_item_object[item]['subsidizedbase'])}
                                                        //IncBarData={(Object.keys(this.state.volume_by_item_object)).map(item => this.state.volume_by_item_object[item]['inctouseforcalc'])}
                                                        UnSubBarData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                            if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                                return this.state.volume_by_item_object[item]['unsubsidizedbase']
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        }).filter(Boolean)}
                                                        SubBarData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                            if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                                return this.state.volume_by_item_object[item]['subsidizedbase']
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        }).filter(Boolean)}
                                                        IncBarData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                            if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                                return this.state.volume_by_item_object[item]['inctouseforcalc']
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        }).filter(Boolean)}
                                                        showDataTableStyle={this.state.showDataTableStyle}
                                                        showDataTable={this.state.showDataTable}
                                                        unitsvar={this.state.unitsvar}
                                                        categories={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                            if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                                return item
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        }).filter(Boolean)}
                                                        //categories={(Object.keys(this.state.volume_by_item_object)).map(item => item)}
                                                        attr_caption_text={'The Items are Sorted by Total ' + this.state.unitsvar + '. ' + ' Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                        chart_title_info={' by Item<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                        TotalBarLookup={volume_by_item_object}
                                                        level='Item'
                                                        height='.5'
                                                        stackingType={this.state.stackingType}
                                                    />
                                                </div>
                                                :
                                                (this.state.promoselected && this.state.itemselected) ? 
                                                    <div id="PromoChartByItem" className="col-sm-12" style={{ width: "100%", height: "100%"}}>
                                                        < PromotedNonPromoted
                                                            //NonPromotedBarData={(Object.keys(this.state.volume_by_item_object).map(item => this.state.volume_by_item_object[item]['nonpromovol']))}
                                                            //PromotedBarData={(Object.keys(this.state.volume_by_item_object)).map(item => this.state.volume_by_item_object[item]['promovol'])}
                                                            NonPromotedBarData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                                if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                                    return this.state.volume_by_item_object[item]['nonpromovol']
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            }).filter(Boolean)}
                                                            PromotedBarData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                                if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                                    return this.state.volume_by_item_object[item]['promovol']
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            }).filter(Boolean)}
                                                            showDataTableStyle={this.state.showDataTableStyle}
                                                            showDataTable={this.state.showDataTable}
                                                            unitsvar={this.state.unitsvar}
                                                            categories={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                                if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                                    return item
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            }).filter(Boolean)}
                                                            //categories={(Object.keys(this.state.volume_by_item_object)).map(item => item)}
                                                            attr_caption_text={'The Items are Sorted by Total ' + this.state.unitsvar + '. ' + ' Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                            chart_title_info={' by Item<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                            TotalBarLookup={volume_by_item_object}
                                                            level='Item'
                                                            height='.5'
                                                            stackingType={this.state.stackingType}
                                                        />
                                                    </div>
                                                    :
                                                    (this.state.baseselected && this.state.brandselected) ? 
                                                        <div id="BaseChartByBrand" className='col-sm-12' style={{ width: "100%", height: "100%"}}>
                                                            < BaseIncremental
                                                                //UnSubBarData={(Object.keys(this.state.volume_by_brand_object)).map(brand => this.state.volume_by_brand_object[brand]['unsubsidizedbase'])}
                                                                //SubBarData={(Object.keys(this.state.volume_by_brand_object)).map(brand => this.state.volume_by_brand_object[brand]['subsidizedbase'])}
                                                                //IncBarData={(Object.keys(this.state.volume_by_brand_object)).map(brand => this.state.volume_by_brand_object[brand]['inctouseforcalc'])}
                                                                UnSubBarData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                                    if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                                        return this.state.volume_by_brand_object[brand]['unsubsidizedbase']
                                                                    }
                                                                    else {
                                                                        return null
                                                                    }
                                                                }).filter(Boolean)}
                                                                SubBarData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                                    if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                                        return this.state.volume_by_brand_object[brand]['subsidizedbase']
                                                                    }
                                                                    else {
                                                                        return null
                                                                    }
                                                                }).filter(Boolean)}
                                                                IncBarData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                                    if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                                        return this.state.volume_by_brand_object[brand]['inctouseforcalc']
                                                                    }
                                                                    else {
                                                                        return null
                                                                    }
                                                                }).filter(Boolean)}
                                                                showDataTableStyle={this.state.showDataTableStyle}
                                                                showDataTable={this.state.showDataTable}
                                                                unitsvar={this.state.unitsvar}
                                                                categories={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                                    if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                                        return brand
                                                                    }
                                                                    else {
                                                                        return null
                                                                    }
                                                                }).filter(Boolean)}
                                                                //categories={(Object.keys(this.state.volume_by_brand_object)).map(brand => brand)}
                                                                attr_caption_text={'The Brands are Sorted by Total ' + this.state.unitsvar + '. ' + ' Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                                chart_title_info={' by Brand<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                                TotalBarLookup={volume_by_brand_object}
                                                                level='Brand'
                                                                height='.5'
                                                                stackingType={this.state.stackingType}
                                                            />
                                                        </div>
                                                        :
                                                        (this.state.promoselected && this.state.brandselected) ?
                                                            <div id="PromoChartByBrand" className="col-sm-12" style={{ width: "100%", height: "100%"}}>
                                                                < PromotedNonPromoted
                                                                    //NonPromotedBarData={(Object.keys(this.state.volume_by_brand_object).map(brand => this.state.volume_by_brand_object[brand]['nonpromovol']))}
                                                                    //PromotedBarData={(Object.keys(this.state.volume_by_brand_object)).map(brand => this.state.volume_by_brand_object[brand]['promovol'])}
                                                                    NonPromotedBarData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                                            return this.state.volume_by_brand_object[brand]['nonpromovol']
                                                                        }
                                                                        else {
                                                                            return null
                                                                        }
                                                                    }).filter(Boolean)}
                                                                    PromotedBarData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                                            return this.state.volume_by_brand_object[brand]['promovol']
                                                                        }
                                                                        else {
                                                                            return null
                                                                        }
                                                                    }).filter(Boolean)}
                                                                    showDataTableStyle={this.state.showDataTableStyle}
                                                                    showDataTable={this.state.showDataTable}
                                                                    unitsvar={this.state.unitsvar}
                                                                    categories={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                                            return brand
                                                                        }
                                                                        else {
                                                                            return null
                                                                        }
                                                                    }).filter(Boolean)}
                                                                    //categories={(Object.keys(this.state.volume_by_brand_object)).map(brand => brand)}
                                                                    attr_caption_text={'The Brands are Sorted by Total ' + this.state.unitsvar + '. ' + ' Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                                    chart_title_info={' by Brand<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                                    TotalBarLookup={volume_by_brand_object}
                                                                    level='Brand'
                                                                    height='.5'
                                                                    stackingType={this.state.stackingType}
                                                                />
                                                            </div>
                                                            :
                                                            (this.state.baseselected && this.state.pricebandselected) ?
                                                                <div id="BaseChartByPriceBand" className='col-sm-12' style={{ width: "100%", height: "100%"}}>
                                                                    < BaseIncremental
                                                                        UnSubBarData={(Object.keys(this.state.volume_by_priceband_object)).map(band => this.state.volume_by_priceband_object[band]['unsubsidizedbase'])}
                                                                        SubBarData={(Object.keys(this.state.volume_by_priceband_object)).map(band => this.state.volume_by_priceband_object[band]['subsidizedbase'])}
                                                                        IncBarData={(Object.keys(this.state.volume_by_priceband_object)).map(band => this.state.volume_by_priceband_object[band]['inctouseforcalc'])}
                                                                        showDataTableStyle={this.state.showDataTableStyle}
                                                                        showDataTable={this.state.showDataTable}
                                                                        unitsvar={this.state.unitsvar}
                                                                        categories={(Object.keys(this.state.volume_by_priceband_object)).map(band => band)}
                                                                        attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                                        chart_title_info={' by Price Band<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                                        TotalBarLookup={volume_by_priceband_object}
                                                                        level='Price Band'
                                                                        height='.5'
                                                                        stackingType={this.state.stackingType}
                                                                    />
                                                                </div>
                                                                :
                                                                (this.state.promoselected && this.state.pricebandselected) ?
                                                                    <div id="PromoChartByPriceBand" className='col-sm-12' style={{ width: "100%", height: "100%"}}>
                                                                        < PromotedNonPromoted
                                                                            NonPromotedBarData={(Object.keys(this.state.volume_by_priceband_object).map(band => this.state.volume_by_priceband_object[band]['nonpromovol']))}
                                                                            PromotedBarData={(Object.keys(this.state.volume_by_priceband_object)).map(band => this.state.volume_by_priceband_object[band]['promovol'])}
                                                                            showDataTableStyle={this.state.showDataTableStyle}
                                                                            showDataTable={this.state.showDataTable}
                                                                            unitsvar={this.state.unitsvar}
                                                                            categories={(Object.keys(this.state.volume_by_priceband_object)).map(band => band)}
                                                                            attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                                            chart_title_info={' by Price Band<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                                            TotalBarLookup={volume_by_priceband_object}
                                                                            level='Price Band'
                                                                            height='.5'
                                                                            stackingType={this.state.stackingType}
                                                                        />
                                                                    </div>
                                                                    : 
                                                                    <div/>

                                                }

                                            
                                            
                                            
                                            
                                            
                                            
                                        </div>
                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value} index={3}>
                                        <div style={{ height: '100%', width: "100%", }} className="row">
                                            <div className="" align='left' id="IconBar">

                                                <IconButton
                                                    className="btn btn-primary"
                                                    onClick={changeTypeGraph}
                                                    value='area'
                                                    style={{ padding: '5px', margin: '5px' }}
                                                >
                                                    <ShowChartRoundedIcon />

                                                </IconButton>

                                                <IconButton
                                                    className="btn btn-primary"
                                                    onClick={changeTypeGraph}
                                                    value='column'
                                                    style={{
                                                        padding: '5px', margin: '5px'
                                                    }}
                                                >
                                                    <BarChartRoundedIcon />
                                                </IconButton>

                                                <IconButton
                                                    className="btn btn-primary ChangeDataTableButton"
                                                    onClick={changeShowDataTable}
                                                    style={{
                                                        padding: '5px', margin: '5px'
                                                    }}
                                                    id='SourceOfVolume'
                                                >
                                                    <TableChartRoundedIcon />
                                                </IconButton>
                                                <ThemeProvider theme={muiTheme}>
                                                    <Radio
                                                        checked={this.state.lifteffbybrand}
                                                        onChange={handleChangeRadio3}
                                                        value="Brand"
                                                        name="PromoRadio3"
                                                        label="Brand"
                                                        inputProps={{ 'aria-label': 'Brand' }}
                                                    />
                                                    <span>Brand</span>
                                                    <Radio
                                                        checked={this.state.lifteffbyitem}
                                                        onChange={handleChangeRadio3}
                                                        value="Item"
                                                        name="BaseRadio3"
                                                        label="Item"
                                                        inputProps={{ 'aria-label': 'Item' }}
                                                    />
                                                    <span>Item</span>

                                                    <Radio
                                                        checked={this.state.lifteffbypriceband}
                                                        onChange={handleChangeRadio3}
                                                        value="PriceBand"
                                                        name="PromoRadio3"
                                                        label="Price Band"
                                                        inputProps={{ 'aria-label': 'Price Band' }}
                                                    />
                                                    <span>Price Band</span>
                                                </ThemeProvider>

                                            </div>
                                            {this.state.lifteffbyitem ?
                                                <div className="row col-sm-12">
                                                    <div align='left' className="col-sm-3" >
                                                        <span className="formfieldtitle">Select Item Rank (Based on Unit Sales)</span>
                                                        <Select
                                                            options={this.state.itemRankOptions}
                                                            placeholder={'Select Item Ranks'}
                                                            onChange={(item, position) => this.changeItemRank(item, 0)}
                                                            value={this.state.selectedItemRank}
                                                        />
                                                    </div>
                                                    <div align='left' className="col-sm-3" ></div>
                                                    <div align='left' className="col-sm-3" ></div>
                                                    <div align='left' className="col-sm-3" ></div>
                                                </div>
                                                :
                                                this.state.lifteffbybrand ?
                                                    <div className="row col-sm-12">
                                                        <div align='left' className="col-sm-3" >
                                                            <span className="formfieldtitle">Select Brand Rank (Based on Unit Sales)</span>
                                                            <Select
                                                                options={this.state.brandRankOptions}
                                                                placeholder={'Select Brand Ranks'}
                                                                onChange={(item, position) => this.changeBrandRank(item, 0)}
                                                                value={this.state.selectedBrandRank}
                                                            />
                                                        </div>
                                                        <div align='left' className="col-sm-3" ></div>
                                                        <div align='left' className="col-sm-3" ></div>
                                                        <div align='left' className="col-sm-3" ></div>
                                                    </div>
                                                    :
                                                    <div className="row col-sm-12" />
                                            }
                                            <div id="lifteffbyitem" style={{ width: "100%", height: "100%", display: 'none' }}>
                                                <LiftEffDiscPct
                                                    //LiftGraphData={(Object.keys(this.state.volume_by_item_object)).map(item => Math.min((this.state.volume_by_item_object[item]['inctouseforcalc'] / this.state.volume_by_item_object[item]['basetouseforcalc']), 10))}
                                                    //PromoLiftGraphData={(Object.keys(this.state.volume_by_item_object)).map(item => Math.min((this.state.volume_by_item_object[item]['inctouseforcalc'] / this.state.volume_by_item_object[item]['subsidizedbase']), 10))}
                                                    //EffGraphData={(Object.keys(this.state.volume_by_item_object)).map(item => Math.min((this.state.volume_by_item_object[item]['inctouseforcalc'] / this.state.volume_by_item_object[item]['promovol']), 1))}
                                                    //DiscGraphData={(Object.keys(this.state.volume_by_item_object)).map(item => (this.state.disc_pct_by_item[item] / this.state.volume_by_item_object[item]['totalvolume']))}
                                                    LiftGraphData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                        if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                            return Math.min((this.state.volume_by_item_object[item]['inctouseforcalc'] / this.state.volume_by_item_object[item]['basetouseforcalc']), 10)
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    PromoLiftGraphData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                        if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                            return Math.min((this.state.volume_by_item_object[item]['inctouseforcalc'] / this.state.volume_by_item_object[item]['subsidizedbase']), 10)
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    EffGraphData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                        if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                            return Math.min((this.state.volume_by_item_object[item]['inctouseforcalc'] / this.state.volume_by_item_object[item]['promovol']), 1)
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    DiscGraphData={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                        if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                            return (this.state.disc_pct_by_item[item] / this.state.volume_by_item_object[item]['totalvolume'])
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    showDataTableStyle={this.state.showDataTableStyle}
                                                    showDataTable={this.state.showDataTable}
                                                    unitsvar={this.state.unitsvar}
                                                    categories={(Object.keys(this.state.volume_by_item_object)).map((item, idx) => {
                                                        if (idx >= this.state.itemRankStart && idx < this.state.itemRankEnd) {
                                                            return item
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    //categories={(Object.keys(this.state.volume_by_item_object)).map(item => item)}
                                                    attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                    chart_title_info={' by Item<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                    level='Item'                                                   
                                                />
                                            </div>
                                            <div id="lifteffbybrand" style={{ width: "100%", height: "100%" }}>
                                                <LiftEffDiscPct
                                                    //LiftGraphData={(Object.keys(this.state.volume_by_brand_object)).map(brand => Math.min((this.state.volume_by_brand_object[brand]['inctouseforcalc'] / this.state.volume_by_brand_object[brand]['basetouseforcalc']), 10))}
                                                    //PromoLiftGraphData={(Object.keys(this.state.volume_by_brand_object)).map(brand => Math.min((this.state.volume_by_brand_object[brand]['inctouseforcalc'] / this.state.volume_by_brand_object[brand]['subsidizedbase']), 10))}
                                                    //EffGraphData={(Object.keys(this.state.volume_by_brand_object)).map(brand => Math.min((this.state.volume_by_brand_object[brand]['inctouseforcalc'] / this.state.volume_by_brand_object[brand]['promovol']), 1))}
                                                    //DiscGraphData={(Object.keys(this.state.volume_by_brand_object)).map(brand => (this.state.disc_pct_by_brand[brand] / this.state.volume_by_brand_object[brand]['totalvolume']))}
                                                    LiftGraphData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                            return Math.min((this.state.volume_by_brand_object[brand]['inctouseforcalc'] / this.state.volume_by_brand_object[brand]['basetouseforcalc']), 10)
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    PromoLiftGraphData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                            return Math.min((this.state.volume_by_brand_object[brand]['inctouseforcalc'] / this.state.volume_by_brand_object[brand]['subsidizedbase']), 10)
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    EffGraphData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                            return Math.min((this.state.volume_by_brand_object[brand]['inctouseforcalc'] / this.state.volume_by_brand_object[brand]['promovol']), 1)
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    DiscGraphData={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                            return (this.state.disc_pct_by_brand[brand] / this.state.volume_by_brand_object[brand]['totalvolume'])
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    showDataTableStyle={this.state.showDataTableStyle}
                                                    showDataTable={this.state.showDataTable}
                                                    unitsvar={this.state.unitsvar}
                                                    categories={(Object.keys(this.state.volume_by_brand_object)).map((brand, idx) => {
                                                        if (idx >= this.state.brandRankStart && idx < this.state.brandRankEnd) {
                                                            return brand
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    }).filter(Boolean)}
                                                    //categories={(Object.keys(this.state.volume_by_brand)).map(brand => brand)}
                                                    attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                    chart_title_info={' by Brand<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                    level='Brand'
                                                />
                                            </div>
                                            <div id="lifteffbypriceband" style={{ width: "100%", height: "100%", display: 'none' }}>
                                                <LiftEffDiscPct
                                                    LiftGraphData={(Object.keys(this.state.volume_by_priceband_object)).map(band => Math.min((this.state.volume_by_priceband_object[band]['inctouseforcalc'] / this.state.volume_by_priceband_object[band]['basetouseforcalc']), 10))}
                                                    PromoLiftGraphData={(Object.keys(this.state.volume_by_priceband_object)).map(band => Math.min((this.state.volume_by_priceband_object[band]['inctouseforcalc'] / this.state.volume_by_priceband_object[band]['subsidizedbase']), 10))}
                                                    EffGraphData={(Object.keys(this.state.volume_by_priceband_object)).map(band => Math.min((this.state.volume_by_priceband_object[band]['inctouseforcalc'] / this.state.volume_by_priceband_object[band]['promovol']), 1))}
                                                    DiscGraphData={(Object.keys(this.state.volume_by_priceband_object)).map(band => (this.state.disc_pct_by_priceband[band] / this.state.volume_by_priceband_object[band]['totalvolume']))}
                                                    showDataTableStyle={this.state.showDataTableStyle}
                                                    showDataTable={this.state.showDataTable}
                                                    unitsvar={this.state.unitsvar}
                                                    categories={(Object.keys(this.state.volume_by_priceband_object)).map(band => band)}
                                                    attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                    chart_title_info={' by Price Band<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                    level='Price Band'
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value} index={4}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            //type: 'column'
                                                            plotBackgroundColor: null,
                                                            plotBorderWidth: null,
                                                            plotShadow: false,
                                                            type: 'bar',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                        },
                                                        title: {
                                                            text: 'Source of ' + this.state.unitsvar + '<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                        },
                                                        series: [{
                                                            name: 'Source of ' + this.state.unitsvar,
                                                            data: this.state.source_of_vol_pie_data
                                                        }],

                                                        xAxis: {
                                                            title: {
                                                                text: 'Source'
                                                            },
                                                            categories:
                                                                this.state.source_of_vol_pie_data.map(col => col.name)
                                                        },
                                                        yAxis: {
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            title: {
                                                                text: this.state.unitsvar
                                                            }
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                dataLabels: {
                                                                    enabled: true,                                                                    
                                                                    formatter: function () {
                                                                        //console.log(this)
                                                                        function nFormatter(num, digits) {
                                                                            var si = [
                                                                                { value: 1E18, symbol: "E" },
                                                                                { value: 1E15, symbol: "P" },
                                                                                { value: 1E12, symbol: "T" },
                                                                                { value: 1E9, symbol: "B" },
                                                                                { value: 1E6, symbol: "M" },
                                                                                { value: 1E3, symbol: "k" }
                                                                            ], i;
                                                                            for (i = 0; i < si.length; i++) {
                                                                                if (Math.abs(num) >= si[i].value) {
                                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                }
                                                                                else if (Math.abs(num) < 1E3) {
                                                                                    return (num).toFixed(digits) + " ";
                                                                                }
                                                                            }
                                                                            return num.toString();
                                                                        }
                                                                        return nFormatter(this.point.y, 1).toLocaleString()
                                                                    },
                                                                    color: 'black',
                                                                    borderRadius: 5,
                                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                    borderWidth: 1,
                                                                    borderColor: '#d6d7d8',
                                                                    padding: 0,
                                                                    style: {
                                                                        textOutline: 'none',
                                                                        fontSize: '20px'
                                                                    },
                                                                    y: 0
                                                                }
                                                            },
                                                            pie: {
                                                                allowPointSelect: true,
                                                                cursor: 'pointer',
                                                                dataLabels: {
                                                                    enabled: true,
                                                                    format: '<b>{point.name}</b>: {point.percentage:.0f} %'
                                                                }
                                                            }

                                                        },
                                                        tooltip: {
                                                            formatter: function () {
                                                                return '<span>'
                                                                    + this.x
                                                                    + '<br /> '
                                                                    + unitsvar_forgraphs
                                                                    + ': '
                                                                    + Math.round(this.y).toLocaleString()
                                                                    + '<br /> Percent of '
                                                                    + unitsvar_forgraphs
                                                                    + ' : '
                                                                    + (this.y / totalvol).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                    + '</span>'
                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },
                                                    }}
                                            />
                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow header="header_g13">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Source</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{this.state.unitsvar}</Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                    {(Object.values(this.state.source_of_vol_pie_data)).map(source => {
                                                        return <Constant.NPDTableRow key={"datarow_" + source.name + "_g13"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{source.name}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(source.y).toLocaleString()}</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                    })
                                                    }
                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>

                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value} index={5} style={{ width: '100%', height: '100%' }} >
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div className="" style={{ height: '100%' }}>
                                            {this.state.voldecomp_type === 'column' ?
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {

                                                            chart: {
                                                                type: this.state.voldecomp_type,
                                                                zoomType: 'xy',
                                                                styledMode: false,
                                                                //height:'35%'
                                                                height: (9 / 16 * 100) + '%', //(9 / 16 * 100) + '%', //16:9 ratio
                                                                //width:'1200'                                                        
                                                            },
                                                            boost: {
                                                                useGPUTranslations: true
                                                            },
                                                            caption: {
                                                                text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                            },
                                                            title: {
                                                                text: this.state.unitsvar + ' Decomposition<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                            },
                                                            series: this.state.data_area_graph_col,
                                                            xAxis: {
                                                                title: {
                                                                    text: 'Week'
                                                                },
                                                                type: 'datetime',
                                                                tickPositions: this.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                                                labels: {
                                                                    formatter: function () {
                                                                        let dt = new Date(this.value)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.value + offset)                                                                        
                                                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                                        return date.toLocaleDateString(undefined, options2)                                                                        
                                                                    },
                                                                    rotation: 270,
                                                                    step: 1,
                                                                    padding: 5,
                                                                    style: {
                                                                        fontSize: '11px'
                                                                    }
                                                                },                                                                
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: this.state.unitsvar
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    reversedStacks: false
                                                                },
                                                                {
                                                                    title: {
                                                                        text: this.state.unitsvar
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    //min: this.state.voldecomp_min,
                                                                    //max: this.state.voldecomp_max,
                                                                    visible: false,
                                                                    reversedStacks: true
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                area: {
                                                                    stacking: 'normal',
                                                                    lineColor: '#ffffff',
                                                                    lineWidth: 1,
                                                                    marker: {
                                                                        lineWidth: 1,
                                                                        lineColor: '#ffffff',
                                                                        enabled: false
                                                                    }
                                                                },
                                                                column: {
                                                                    stacking: 'normal',
                                                                    dataLabels: { enabled: false },
                                                                    groupPadding: 0
                                                                },
                                                                line: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                }
                                                            },
                                                            tooltip: {
                                                                formatter: function () {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + ' <br> '
                                                                        + new Date(this.x + 1).toLocaleDateString({ month: 'long', day: 'numeric', year: 'numeric' })
                                                                        + ' <br> '
                                                                        + Math.round(this.y).toLocaleString({ maximumFractionDigits: 0, minimumFractionDigits: 0 })
                                                                        + ' '
                                                                        + unitsvar_forgraphs
                                                                        + '</span>'
                                                                }
                                                            },
                                                            exporting: {
                                                                showTable: this.state.showDataTable,
                                                                tableCaption: false
                                                            },
                                                            responsive: {
                                                                rules: [{
                                                                    condition: {
                                                                        maxHeight: 500,
                                                                        maxWidth: 500
                                                                    },
                                                                }]
                                                            },
                                                        }}
                                                />
                                                :
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {

                                                            chart: {
                                                                type: this.state.voldecomp_type,
                                                                zoomType: 'xy',
                                                                styledMode: false,
                                                                //height:'35%'
                                                                height: (9 / 16 * 100) + '%', //(9 / 16 * 100) + '%', //16:9 ratio
                                                                //width:'1200'                                                        
                                                            },
                                                            boost: {
                                                                useGPUTranslations: true
                                                            },
                                                            caption: {
                                                                text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                            },
                                                            title: {
                                                                text: this.state.unitsvar + ' Decomposition<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                            },
                                                            series: this.state.data_area_graph,
                                                            xAxis: {
                                                                title: {
                                                                    text: 'Week'
                                                                },
                                                                type: 'datetime',
                                                                tickPositions: this.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                                                labels: {
                                                                    formatter: function () {
                                                                        let dt = new Date(this.value)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.value + offset)
                                                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                                        return date.toLocaleDateString(undefined, options2)
                                                                    },
                                                                    rotation: 270,
                                                                    step: 1,
                                                                    padding: 5,
                                                                    style: {
                                                                        fontSize: '11px'
                                                                    }
                                                                },
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: this.state.unitsvar
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    reversedStacks: false
                                                                },
                                                                {
                                                                    title: {
                                                                        text: this.state.unitsvar
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    visible: false,
                                                                    reversedStacks: true
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                area: {
                                                                    stacking: 'normal',
                                                                    lineColor: '#ffffff',
                                                                    lineWidth: 1,
                                                                    marker: {
                                                                        lineWidth: 1,
                                                                        lineColor: '#ffffff',
                                                                        enabled: false
                                                                    }
                                                                },
                                                                column: {
                                                                    stacking: 'normal',
                                                                    dataLabels: { enabled: false },
                                                                    groupPadding: 0
                                                                },
                                                                line: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                }
                                                            },
                                                            tooltip: {
                                                                formatter: function () {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + ' <br> '
                                                                        + new Date(this.x + 1).toLocaleDateString({ month: 'long', day: 'numeric', year: 'numeric' })
                                                                        + ' <br> '
                                                                        + Math.round(this.y).toLocaleString({ maximumFractionDigits: 0, minimumFractionDigits: 0 })
                                                                        + ' '
                                                                        + unitsvar_forgraphs
                                                                        + '</span>'
                                                                }
                                                            },
                                                            exporting: {
                                                                showTable: this.state.showDataTable,
                                                                tableCaption: false
                                                            },
                                                            responsive: {
                                                                rules: [{
                                                                    condition: {
                                                                        maxHeight: 500,
                                                                        maxWidth: 500
                                                                    },
                                                                }]
                                                            },
                                                        }}
                                                />
                                            }

                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow header="header_g21">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                                        {Object.values(this.state.data_area_graph).map((dueto, duetoidx2) => {
                                                            return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={dueto.name + duetoidx2 + "_cell"}>{dueto.name}</Constant.NPDTableCell>
                                                        })}
                                                    </Constant.NPDTableRow>
                                                    {Object.values(this.state.distinctdates).map((date, idx) => {
                                                        return <Constant.NPDTableRow key={"datarow_" + date + "_g20"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                                            {Object.values(this.state.data_area_graph).map((dueto, duetoidx) => {
                                                                try {
                                                                    return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={"cell" + date + dueto.name + idx + duetoidx}>{Math.round((dueto.data[idx][1] || 0)).toLocaleString()}</Constant.NPDTableCell>
                                                                }
                                                                catch (error) {
                                                                    return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={"cell" + date + dueto.name + idx + duetoidx}>{(0).toLocaleString()}</Constant.NPDTableCell>
                                                                }

                                                            })}

                                                        </Constant.NPDTableRow>
                                                    })
                                                    }
                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>

                                        <div className="" style={{ height: '50px' }} />
                                    </TabPanel>
                                    : <div />}


                            </div>

                        </div>

                    </div>

                    <div className="row" >
                        <div className="col-sm-12" style={{ maxWidth: '1500px' }}>
                            <div align='center' className="contentHolderTight">
                                <h5 style={{ padding: '10px' }}>Brand and Item Analysis</h5>
                                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }}>
                                    <Constant.NPDTabs
                                        value={this.state.value2}
                                        onChange={handleChange2}
                                        aria-label="simple tabs example"
                                        className="reporttabsheader"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Constant.NPDTab label="PROMOTION PERFORMANCE" {...a11yProps(0)} />
                                        <Constant.NPDTab label="LIFT AND EFFICIENCY" {...a11yProps(1)} />
                                        <Constant.NPDTab label="DISCOUNT DOLLARS AND PAYBACK RATIO" {...a11yProps(2)} />
                                        <Constant.NPDTab label="PAYBACK RATIO, EFFICIENCY AND LIFT BY DISCOUNT BAND" {...a11yProps(6)} />
                                        <Constant.NPDTab label={this.state.unitsvar.toUpperCase() + " BY BRAND"} {...a11yProps(3)}  />
                                        <Constant.NPDTab label={this.state.unitsvar.toUpperCase() + " BY PRODUCT"} {...a11yProps(4)}/>
                                        <Constant.NPDTab label={this.state.unitsvar.toUpperCase() + " BY DISCOUNT BAND"} {...a11yProps(5)} />
                                    </Constant.NPDTabs>
                                </Constant.NPDAppbar>

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value2} index={0}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>
                                            <ThemeProvider theme={muiTheme}>
                                                <Radio
                                                    checked={this.state.baseselectedperf}
                                                    onChange={handleChangeRadio}
                                                    value="Base"
                                                    name="BaseRadio"
                                                    label="Base/Incremental"
                                                    inputProps={{ 'aria-label': 'Base/Incremental' }}
                                                />
                                                <span>Base/Incremental</span>
                                                <Radio
                                                    checked={this.state.promoselectedperf}
                                                    onChange={handleChangeRadio}
                                                    value="Promo"
                                                    name="PromoRadio"
                                                    label="Promoted/NonPromoted"
                                                    inputProps={{ 'aria-label': 'Promoted/NonPromoted' }}
                                                />
                                                <span>Promoted/NonPromoted</span>
                                                <Checkbox
                                                    checked={this.state.stackingType_checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': 'Show 100% Stacked Bar?' }}
                                                    onChange={this.handleToggle_stackingType}
                                                />
                                                <span style={{ lineHeight: '5' }} >Show 100% Stacked Bar?</span>
                                            </ThemeProvider>


                                        </div>
                                        <div id='BaseChartPromoPerf' style={{ height: '100%', width: "100%", }}>
                                            {/*Update these*/}
                                            <BaseIncOrPromoNonPromoByWeek
                                                chart_title_info={this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                unitsvar={this.state.unitsvar}
                                                series1data={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.state.base_data_corrected_graph[date]])}
                                                series2data={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.state.inc_data_corrected_graph[date]])}
                                                series3data={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.state.hol_data_corrected_graph[date]])}
                                                aspweeklydata={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.state.asp_data_by_week[date] / this.state.total_vol_by_week[date]])}
                                                avgbaseweeklydata={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), (Object.values(this.state.base_data_by_week).reduce(reducer) / this.state.title_num_weeks)])}
                                                distinctdates={this.state.distinctdates}
                                                weekly_data={this.state.weekly_data}
                                                selectedBrand={'All'}
                                                showDataTable={this.state.showDataTable}
                                                showDataTableStyle={this.state.showDataTableStyle}
                                                type='Base'
                                                weekagg={this.state.numweeks} //{this.state.filter_object.numweeks}
                                                attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                stackingType={this.state.stackingType}
                                                currencycode={this.state.currencycode}
                                                currencytype={this.state.currencytype}
                                            
                                            /> 
                                        </div>
                                        <div id='PromoChartPromoPerf' style={{ height: '100%', width: "100%", display: 'none' }}>
                                            <BaseIncOrPromoNonPromoByWeek
                                                chart_title_info={this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                unitsvar={this.state.unitsvar}
                                                series1data={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), (this.state.total_vol_by_week[date] - this.state.promo_data_by_week[date])])}
                                                series2data={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.state.promo_data_by_week[date]])}                                                
                                                aspweeklydata={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), this.state.asp_data_by_week[date] / this.state.total_vol_by_week[date]])}
                                                avgbaseweeklydata={(this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), (Object.values(this.state.base_data_by_week).reduce(reducer) / this.state.title_num_weeks)])}
                                                distinctdates={this.state.distinctdates}
                                                weekly_data={this.state.weekly_data}
                                                selectedBrand={'All'}
                                                showDataTable={this.state.showDataTable}
                                                showDataTableStyle={this.state.showDataTableStyle}
                                                type='Promo'
                                                weekagg={this.state.numweeks} //{this.state.filter_object.numweeks}
                                                attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                stackingType={this.state.stackingType}
                                                currencycode={this.state.currencycode}
                                                currencytype={this.state.currencytype}
                                            />                                             
                                        </div>
                                    </TabPanel>
                                    : <div />}
                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value2} index={1}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }}>
                                            <LiftEffDiscByWeek
                                                chart_title_info={this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                unitsvar={this.state.unitsvar}
                                                distinctdates={this.state.distinctdates}
                                                weekly_data={this.state.weekly_data}
                                                selectedBrand={'All'}
                                                showDataTable={this.state.showDataTable}
                                                showDataTableStyle={this.state.showDataTableStyle}
                                                attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                weekagg={this.state.numweeks} //this.state.filter_object.numweeks}
                                            />
                                        </div>
                                    </TabPanel>
                                    :
                                    <div />
                                }

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value2} index={2}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }}>
                                            <DiscDollarsAndPBRByWeek
                                                chart_title_info={this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail}
                                                unitsvar={this.state.unitsvar}
                                                distinctdates={this.state.distinctdates}
                                                weekly_data={this.state.weekly_data}
                                                selectedBrand={'All'}
                                                showDataTable={this.state.showDataTable}
                                                showDataTableStyle={this.state.showDataTableStyle}
                                                attr_caption_text={'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))}
                                                weekagg={this.state.numweeks} //this.state.filter_object.numweeks}
                                                currencycode={this.state.currencycode}
                                                currencytype={this.state.currencytype}
                                            />
                                        </div>
                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value2} index={3}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div className='col-sm-12' style={{ margin: '0px'}} align='left'>
                                            <ThemeProvider theme={muiTheme}>
                                                <span style={{ width: '100px' }}></span>
                                                <span>Include No Discount Range</span>
                                                <Switch
                                                    checked={this.state.nodiscSwitchCheck}
                                                    onChange={handleChange_NoDiscSwitch}
                                                    color="secondary"
                                                />
                                                <span>Remove No Discount Range</span>
                                            </ThemeProvider>
                                        </div>
                                        {this.state.nodiscSwitchCheck ?
                                            <div style={{ height: '100%', width: "100%", }}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {
                                                            chart: {
                                                                zoomType: 'xy',
                                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                                            },
                                                            caption: {
                                                                text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                            },
                                                            title: {
                                                                text: 'Lift, Efficiency, and Payback Ratio by Discount Band<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                            },
                                                            series: [
                                                                {
                                                                    name: 'Efficiency',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.slice(1).map(bucket => Math.min(this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping[bucket], 1)),
                                                                    yAxis: 0
                                                                },
                                                                {
                                                                    name: 'Payback Ratio',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.slice(1).map(bucket => this.state.discount_bucket_mapping_id[bucket] / this.state.discount_bucket_mapping_dd[bucket]),
                                                                    yAxis: 1
                                                                },
                                                                {
                                                                    name: 'Market Lift',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.slice(1).map(bucket => this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping_bu[bucket]),
                                                                    yAxis: 0
                                                                },
                                                                {
                                                                    name: 'Promoted Lift',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.slice(1).map(bucket => this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping_sbu[bucket]),
                                                                    yAxis: 0
                                                                },

                                                                {
                                                                    name: 'Percent of Sales',
                                                                    type: 'scatter',
                                                                    marker: {
                                                                        enabled: true,
                                                                        radius: 6,
                                                                        symbol: 'square'
                                                                    },
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.slice(1).map(bucket => this.state.discount_bucket_mapping_totalunits[bucket] / this.state.TotalUnits_all),
                                                                    yAxis: 0
                                                                },

                                                            ],
                                                            xAxis: {
                                                                title: {
                                                                    text: 'Discount Band'
                                                                },
                                                                categories: buckets.slice(1) //this.state.discount_buckets.concat(['Total Difference'])
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: 'Lift/Efficiency'
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    opposite: true,
                                                                    labels: {
                                                                        formatter: function () {
                                                                            return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    title: {
                                                                        text: 'Payback Ratio'
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                column: {
                                                                    stacking: 'normal',
                                                                    dataLabels: {
                                                                        enabled: false,
                                                                        format: '{point.y:,.0f}'
                                                                    }
                                                                },

                                                                spline: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                }

                                                            },
                                                            exporting: {
                                                                showTable: this.state.showDataTable,
                                                                tableCaption: false
                                                            },

                                                        }}
                                                />
                                            </div>
                                            :
                                            <div style={{ height: '100%', width: "100%", }}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {
                                                            chart: {
                                                                zoomType: 'xy',
                                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                                            },
                                                            caption: {
                                                                text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                            },
                                                            title: {
                                                                text: 'Lift, Efficiency, and Payback Ratio by Discount Band<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                            },
                                                            series: [
                                                                {
                                                                    name: 'Efficiency',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.map(bucket => Math.min(this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping[bucket], 1)),
                                                                    yAxis: 0
                                                                },
                                                                {
                                                                    name: 'Payback Ratio',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.map(bucket => this.state.discount_bucket_mapping_id[bucket] / this.state.discount_bucket_mapping_dd[bucket]),
                                                                    yAxis: 1
                                                                },
                                                                {
                                                                    name: 'Market Lift',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.map(bucket => this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping_bu[bucket]),
                                                                    yAxis: 0
                                                                },
                                                                {
                                                                    name: 'Promoted Lift',
                                                                    type: 'line',
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.map(bucket => this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping_sbu[bucket]),
                                                                    yAxis: 0
                                                                },
                                                                {
                                                                    name: 'Percent of Sales',
                                                                    type: 'scatter',
                                                                    marker: {
                                                                        enabled: true,
                                                                        radius: 6,
                                                                        symbol: 'square'
                                                                    },
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                                                        }
                                                                    },
                                                                    data: buckets.map(bucket => this.state.discount_bucket_mapping_totalunits[bucket] / this.state.TotalUnits_all),
                                                                    yAxis: 0
                                                                },

                                                            ],
                                                            xAxis: {
                                                                title: {
                                                                    text: 'Discount Band'
                                                                },
                                                                categories: buckets //this.state.discount_buckets.concat(['Total Difference'])
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: 'Lift/Efficiency'
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    opposite: true,
                                                                    labels: {
                                                                        formatter: function () {
                                                                            return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    title: {
                                                                        text: 'Payback Ratio'
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                column: {
                                                                    stacking: 'normal',
                                                                    dataLabels: {
                                                                        enabled: false,
                                                                        format: '{point.y:,.0f}'
                                                                    }
                                                                },

                                                                spline: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                }

                                                            },
                                                            exporting: {
                                                                showTable: this.state.showDataTable,
                                                                tableCaption: false
                                                            },

                                                        }}
                                                />
                                            </div>}
                                        
                                        {this.state.nodiscSwitchCheck ?
                                            <div style={{ display: this.state.showDataTableStyle }}>
                                                <Constant.NPDTable>
                                                    <Constant.NPDTableBody>
                                                        <Constant.NPDTableRow header="header_g16">
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Band</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Efficiency</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Payback Ratio</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                        {buckets.slice(1).map(bucket => {
                                                            return <Constant.NPDTableRow key={"datarow_" + bucket + "_g16"}>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.min(this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping[bucket], 1).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(this.state.discount_bucket_mapping_id[bucket] / this.state.discount_bucket_mapping_dd[bucket]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                                            </Constant.NPDTableRow>
                                                        })
                                                        }
                                                    </Constant.NPDTableBody>
                                                </Constant.NPDTable>
                                            </div>
                                            :
                                            <div style={{ display: this.state.showDataTableStyle }}>
                                                <Constant.NPDTable>
                                                    <Constant.NPDTableBody>
                                                        <Constant.NPDTableRow header="header_g16">
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Band</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Efficiency</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Payback Ratio</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                        {buckets.map(bucket => {
                                                            return <Constant.NPDTableRow key={"datarow_" + bucket + "_g16"}>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.min(this.state.discount_bucket_mapping_iu[bucket] / this.state.discount_bucket_mapping[bucket], 1).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(this.state.discount_bucket_mapping_id[bucket] / this.state.discount_bucket_mapping_dd[bucket]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                                            </Constant.NPDTableRow>
                                                        })
                                                        }
                                                    </Constant.NPDTableBody>
                                                </Constant.NPDTable>
                                            </div>}
                                        

                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value2} index={4}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            type: 'area',
                                                            zoomType: 'xy',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                        },
                                                        title: {
                                                            text: this.state.unitsvar + ' by Brand<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                        },
                                                        series: this.state.data_area_graph_brands,
                                                        xAxis: {
                                                            title: {
                                                                text: 'Week'
                                                            },
                                                            type: 'datetime',
                                                            tickPositions: this.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                                            labels: {
                                                                formatter: function () {
                                                                    let dt = new Date(this.value)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.value + offset)
                                                                    const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                                    return date.toLocaleDateString(undefined, options2)
                                                                },
                                                                rotation: 270,
                                                                step: 1,
                                                                padding: 5,
                                                                style: {
                                                                    fontSize: '11px'
                                                                }
                                                            },
                                                        },
                                                        yAxis: {
                                                            title: {
                                                                text: this.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            reversedStacks: false

                                                        },
                                                        plotOptions: {
                                                            area: {
                                                                //stacking: 'percent',
                                                                stacking: 'normal',
                                                                lineColor: '#ffffff',
                                                                lineWidth: 1,
                                                                marker: {
                                                                    lineWidth: 1,
                                                                    lineColor: '#ffffff',
                                                                    enabled: false
                                                                },
                                                                line: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        tooltip: {
                                                            formatter: function () {
                                                                return '<span>'
                                                                    + this.series.name
                                                                    + '<br> '
                                                                    + new Date(this.x).toLocaleDateString({ month: 'long', day: 'numeric', year: 'numeric' })
                                                                    + '<br> '
                                                                    + Math.round(this.y).toLocaleString()
                                                                    + ' '
                                                                    + unitsvar_forgraphs
                                                                    + '</span>'
                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />

                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow header="header_g20">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                                        {Object.values(this.state.data_area_graph_brands).map(brand => {
                                                            return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={brand.name + "_cell"}>{brand.name}</Constant.NPDTableCell>
                                                        })}
                                                    </Constant.NPDTableRow>
                                                    {Object.values(this.state.distinctdates).map((date, idx) => {
                                                        return <Constant.NPDTableRow key={"datarow_" + date + "_g20"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                                            {Object.values(this.state.data_area_graph_brands).map(brand => {
                                                                try {
                                                                    return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={"cell" + date + brand.name + idx}>{(brand.data[idx][1] || 0).toLocaleString()}</Constant.NPDTableCell>
                                                                }
                                                                catch (error) {
                                                                    return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={"cell" + date + brand.name + idx}>{(0).toLocaleString()}</Constant.NPDTableCell>
                                                                }
                                                            })}

                                                        </Constant.NPDTableRow>
                                                    })
                                                    }
                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>

                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value2} index={5}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            type: 'area',
                                                            zoomType: 'xy',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                        },
                                                        title: {
                                                            text: this.state.unitsvar + ' by Item<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                        },
                                                        series: this.state.data_area_graph_items,

                                                        //data: brand.x.map((date,idx)=>[Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), brand.y[idx]])
                                                        xAxis: [
                                                            {
                                                                title: {
                                                                    text: 'Week'
                                                                },
                                                                type: 'datetime',
                                                                tickPositions: this.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                                                labels: {
                                                                    formatter: function () {
                                                                        let dt = new Date(this.value)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.value + offset)
                                                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                                        return date.toLocaleDateString(undefined, options2)
                                                                    },
                                                                    rotation: 270,
                                                                    step: 1,
                                                                    padding: 5,
                                                                    style: {
                                                                        fontSize: '11px'
                                                                    }
                                                                },
                                                            },
                                                            {
                                                                title: {
                                                                    text: 'Week'
                                                                },
                                                                type: 'datetime',
                                                                tickPositions: this.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                                                labels: {
                                                                    formatter: function () {
                                                                        let dt = new Date(this.value)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.value + offset)
                                                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                                        return date.toLocaleDateString(undefined, options2)
                                                                    },
                                                                    rotation: 270,
                                                                    step: 1,
                                                                    padding: 5,
                                                                    style: {
                                                                        fontSize: '11px'
                                                                    }
                                                                },
                                                                opposite: true
                                                            }
                                                        ],
                                                        yAxis: {
                                                            title: {
                                                                text: this.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            reversedStacks: false
                                                        },
                                                        plotOptions: {
                                                            area: {
                                                                stacking: 'normal',
                                                                lineColor: '#ffffff',
                                                                lineWidth: 1,
                                                                marker: {
                                                                    lineWidth: 1,
                                                                    lineColor: '#ffffff',
                                                                    enabled: false
                                                                },
                                                            },
                                                            line: {
                                                                marker: {
                                                                    enabled: false
                                                                }
                                                            }
                                                        },
                                                        tooltip: {
                                                            formatter: function () {
                                                                return this.series.name
                                                                    + '<br> '
                                                                    + new Date(this.x).toLocaleDateString({ month: 'long', day: 'numeric', year: 'numeric' })
                                                                    + '<br> '
                                                                    + Math.round(this.y).toLocaleString()
                                                                    + ' '
                                                                    + unitsvar_forgraphs
                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />

                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow header="header_g21">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                                        {Object.values(this.state.data_area_graph_items).map(item => {
                                                            return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={item.name + "_cell"}>{item.name}</Constant.NPDTableCell>
                                                        })}
                                                    </Constant.NPDTableRow>
                                                    {Object.values(this.state.distinctdates).map((date, idx) => {
                                                        return <Constant.NPDTableRow key={"datarow_" + date + "_g20"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                                            {Object.values(this.state.data_area_graph_items).map(item => {
                                                                try {
                                                                    return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={"cell" + date + item.name + idx}>{(item.data[idx][1] || 0).toLocaleString()}</Constant.NPDTableCell>
                                                                }
                                                                catch (error) {
                                                                    return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={"cell" + date + item.name + idx}>{(0).toLocaleString()}</Constant.NPDTableCell>
                                                                }

                                                            })}

                                                        </Constant.NPDTableRow>
                                                    })
                                                    }
                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>


                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value2} index={6}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>

                                        <div style={{ height: '100%', width: "100%", }}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                oneToOne={true}
                                                options={
                                                    {
                                                        chart: {
                                                            //type: 'column'
                                                            plotBackgroundColor: null,
                                                            plotBorderWidth: null,
                                                            plotShadow: false,
                                                            type: 'column',
                                                            //16:9 ratio
                                                            height: (9 / 16 * 100) + '%',
                                                            events: {
                                                                load: function renderMarkers() {

                                                                    var positions = total_cat_data, //[300, 400, 650, 250, 50],
                                                                        chart = this,
                                                                        xAxis = chart.xAxis[0],
                                                                        yAxis = chart.yAxis[0],
                                                                        renderer = chart.renderer,
                                                                        tempArray = [],
                                                                        singleMarkerPath;


                                                                    positions.forEach(function (position, index) {
                                                                        singleMarkerPath = [
                                                                            'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                            'H', xAxis.toPixels(index + 0.25)
                                                                        ];

                                                                        if (!chart.additionalMarkers) {
                                                                            tempArray.push(renderer.path(singleMarkerPath)
                                                                                .attr({
                                                                                    'stroke-width': 2,
                                                                                    stroke: '#565a5c',
                                                                                    zIndex: 10
                                                                                })
                                                                                .add());
                                                                        } else {
                                                                            chart.additionalMarkers[index].attr({
                                                                                d: singleMarkerPath
                                                                            })
                                                                        }
                                                                    });

                                                                    if (!chart.additionalMarkers) {
                                                                        chart.additionalMarkers = tempArray;
                                                                    }
                                                                    window.dispatchEvent(new Event('resize'));
                                                                },
                                                                redraw: function renderMarkers() {

                                                                    var positions = total_cat_data, //[300, 400, 650, 250, 50],
                                                                        chart = this,
                                                                        xAxis = chart.xAxis[0],
                                                                        yAxis = chart.yAxis[0],
                                                                        renderer = chart.renderer,
                                                                        tempArray = [],
                                                                        singleMarkerPath;


                                                                    positions.forEach(function (position, index) {
                                                                        singleMarkerPath = [
                                                                            'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                            'H', xAxis.toPixels(index + 0.25)
                                                                        ];

                                                                        if (!chart.additionalMarkers) {
                                                                            tempArray.push(renderer.path(singleMarkerPath)
                                                                                .attr({
                                                                                    'stroke-width': 2,
                                                                                    stroke: '#565a5c',
                                                                                    zIndex: 10
                                                                                })
                                                                                .add());
                                                                        } else {
                                                                            chart.additionalMarkers[index].attr({
                                                                                d: singleMarkerPath
                                                                            })
                                                                        }
                                                                    });

                                                                    if (!chart.additionalMarkers) {
                                                                        chart.additionalMarkers = tempArray;
                                                                    }
                                                                    window.dispatchEvent(new Event('resize'));
                                                                }
                                                            }
                                                        },
                                                        caption: {
                                                            text: 'Attributes Selected: ' + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                        },
                                                        title: {
                                                            text: 'Promoted ' + this.state.unitsvar + ' by Discount Band<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>' + this.state.title_brands_detail
                                                        },
                                                        series: this.state.discount_bar_data,
                                                        xAxis: {
                                                            title: {
                                                                text: 'Discount Band'
                                                            },
                                                            categories:
                                                                this.state.discount_buckets
                                                        },
                                                        yAxis: {
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            title: {
                                                                text: 'Percent of ' + this.state.unitsvar + ' Sold in Discount Band'
                                                            },
                                                            labels: {
                                                                //format: '{value}%'
                                                                formatter: function () {
                                                                    return Highcharts.numberFormat(this.value * 100, 0) + '%'
                                                                }
                                                            }
                                                        },
                                                        plotOptions: {
                                                            column: {
                                                                dataLabels: {
                                                                    enabled: true,
                                                                    formatter: function () {
                                                                        return Highcharts.numberFormat(this.y * 100, 0) + '%'
                                                                    }
                                                                }
                                                            },


                                                        },
                                                        tooltip: {
                                                            //pointFormat: '{series.name}: <b>{point}%</b>'
                                                            formatter: function () {
                                                                return '<span> '
                                                                    + this.series.name
                                                                    + '<br/>'
                                                                    + this.x
                                                                    + '<br/>'
                                                                    + this.y.toLocaleString(undefined, {
                                                                        style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                                    })
                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },
                                                    }}
                                            />
                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow key="header_g17">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Brand</Constant.NPDTableCell>
                                                        {buckets.map(bucket => {
                                                            return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={bucket + "_cell"}>{bucket}</Constant.NPDTableCell>
                                                        })}
                                                    </Constant.NPDTableRow>
                                                    {Object.values(this.state.discount_bar_data).map(row => {
                                                        return <Constant.NPDTableRow key={"datarow_" + row.name + "_g17"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{row.name}</Constant.NPDTableCell>
                                                            {buckets.map((bucket, idx) => {
                                                                return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={"rowdata_" + idx}>{row.data[idx].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                            })}

                                                        </Constant.NPDTableRow>
                                                    })
                                                    }
                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>

                                    </TabPanel>
                                    : <div />}

                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-sm-12" style={{ maxWidth: '1500px' }}>

                            <div align='center' className="contentHolderTight" >
                                <h5>Competitive Alignment </h5>

                                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }} className="">
                                    <Constant.NPDTabs
                                        value={this.state.value4}
                                        onChange={handleChange4}
                                        aria-label="simple tabs example"
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Constant.NPDTab label="AGGREGATE VIEWS"  {...a11yProps(0)} />
                                        <Constant.NPDTab label="WEEKLY VIEWS"  {...a11yProps(1)} />
                                        <Constant.NPDTab label="INDEXED SHARE BY WEEK"  {...a11yProps(2)} />
                                    </Constant.NPDTabs>
                                </Constant.NPDAppbar>
                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value4} index={0}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>
                                        </div>
                                        <div className="" align='left' id="IconBar">
                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                name="helperText1"
                                                onClick={showHelperText}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            //id='SourceOfVolume'
                                            >
                                                <InfoRoundedIcon />
                                            </IconButton>
                                        </div>

                                        <div className="row">
                                            <div id="helperText1" className="col-sm-12" style={{ display: 'none' }}>
                                                <p>
                                                    This tab will show aggregated metrics for two groups.
                                                    To see the view, please select a metric you wish to compare and define the groups.
                                                    The groups must be defined at the brand or product level, and each group can have one or more members.
                                                    Once all fields are selected, hit "Run Comparison" to see the view.
                                                    If any changes are made to the fields, hit "Run Comparison" again to see the updated view.
                                                </p>
                                            </div>
                                            <div className="col-sm-6">
                                                <span>Please Select a Metric You Wish to Compare</span>
                                                <Select
                                                    id="compareMetricdropdown"
                                                    options={this.state.comparisonMetricOptions}
                                                    placeholder={'Select a Metric'}
                                                    onChange={(item, action) => this.setcomparison_metric(item, action)}
                                                    value={this.state.comparisonMetricSelected}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                            </div>
                                        </div>


                                        <div className="row" style={{ padding: "10px" }}>
                                            <div className='col-sm-6' >
                                                <span style={{
                                                    color: '#7d32ba',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#FFF',
                                                    borderRadius: '10px 0px 0px 10px',
                                                    padding: '9px'
                                                }}
                                                    onClick={showGroup1Def}
                                                    key="Group1DefLink"
                                                >
                                                    Click Here to Define Group 1
					                            </span>
                                            </div>
                                            <div className='col-sm-6'>
                                                <span style={{
                                                    color: '#7d32ba',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#FFF',
                                                    borderRadius: '10px 0px 0px 10px',
                                                    padding: '9px'
                                                }}
                                                    onClick={showGroup2Def}
                                                    key="Group1DefLink"
                                                >
                                                    Click Here to Define Group 2
					                            </span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: "5px" }}>

                                            <div className='col-sm-6' >
                                                <h5>{this.state.g1Name + ' Definition'}</h5>
                                                {this.state.selectedGeos_g1.length > 0 ?
                                                    <span> Geography: {this.state.selectedGeos_g1[0].label}<br /></span>
                                                    :
                                                    <span />
                                                }

                                                {this.state.selectedBrands_g1.length > 0 ?
                                                    this.state.exclude_brands_g1 ?
                                                        <span> Excluding Brands: {this.state.selectedBrands_g1.map(brand => brand.value).join(',')}<br /></span>
                                                        :
                                                        <span> Brands: {this.state.selectedBrands_g1.map(brand => brand.value).join(',')}<br /></span>                                                    
                                                    :
                                                    <span />
                                                }

                                                {Object.keys(this.state.selectedAttributes_g1).map(attribute => {
                                                    if (this.state.selectedAttributes_g1[attribute].length === 0) {
                                                        return <span key={'blanklineg1tab1' + attribute} />
                                                    }
                                                    else {
                                                        return <span key={attribute + 'tab1g1'}> {this.state.attribute_lookup[attribute] + ' : ' + this.state.selectedAttributes_g1[attribute].map(attr => attr.label).join(',')} <br /></span>
                                                    }
                                                })
                                                }

                                                {this.state.selectedPriceBands_g1.length > 0 ?
                                                    <span> Price Bands: {this.state.selectedPriceBands_g1.map(band => band.label).join(',')}<br /></span>
                                                    :
                                                    <span />
                                                }
                                            </div>
                                            <div className='col-sm-6' >
                                                <h5>{this.state.g2Name + ' Definition'}</h5>
                                                {this.state.selectedGeos_g2.length > 0 ?
                                                    <span> Geography: {this.state.selectedGeos_g2[0].label}<br /></span>
                                                    :
                                                    <span />
                                                }
                                                {this.state.selectedBrands_g2.length > 0 ?
                                                    this.state.exclude_brands_g2 ?
                                                        <span> Excluding Brands: {this.state.selectedBrands_g2.map(brand => brand.value).join(',')}<br /></span>
                                                        :
                                                        <span> Brands: {this.state.selectedBrands_g2.map(brand => brand.value).join(',')}<br /></span>                                                    
                                                    :
                                                    <span />
                                                }
                                                {Object.keys(this.state.selectedAttributes_g2).map(attribute => {
                                                    if (this.state.selectedAttributes_g2[attribute].length === 0) {
                                                        return <span key={'blanklineg2tab1' + attribute} />
                                                    }
                                                    else {
                                                        return <span key={attribute + 'tab1g2'}> {this.state.attribute_lookup[attribute] + ' : ' + this.state.selectedAttributes_g2[attribute].map(attr => attr.label).join(',')} <br /></span>
                                                    }
                                                })
                                                }
                                                {this.state.selectedPriceBands_g2.length > 0 ?
                                                    <span> Price Bands: {this.state.selectedPriceBands_g2.map(band => band.label).join(',')}<br /></span>
                                                    :
                                                    <span />
                                                }
                                            </div>
                                        </div>



                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.showGroup1DefModal}
                                            onClose={hideGroup1Def}
                                            style={{ overflowY: 'scroll' }}
                                        >

                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
                                                <Modal
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description"
                                                    open={this.state.AllAttrsModalOpenG1}
                                                    onClose={handleCloseAllAttrsModalG1}
                                                    style={{ overflowY: 'scroll' }}
                                                >
                                                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                        <h4>Product Attributes</h4>
                                                        <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                                            {Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g1[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <h4>First Group Definition</h4>
                                                <p>Use the following form to select the brands, attributes and price bands that define the first group for comparison.</p>
                                                <p>Group Name</p>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handleChangeG1NameBox}
                                                    inputProps={{ 'aria-label': 'naked', id: "g1Name" }}
                                                    value={this.state.g1Name}
                                                />
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Brands</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Checkbox
                                                                checked={this.state.exclude_brands_g1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                                onChange={this.handleToggle_exclude_brands_g1}
                                                            />
                                                            Exclude Brands?
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="BrandFilter1"
                                                            options={this.state.brands}
                                                            isMulti
                                                            placeholder={'Select Brands'}
                                                            onChange={(item, position) => this.setg1definition(item, 'brand')}
                                                            value={this.state.selectedBrands_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Geographies</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="GeoFilter1"
                                                            options={this.state.geos}
                                                            isMulti
                                                            placeholder={'Select Geographies'}
                                                            onChange={(item, position) => this.setg1definition(item, 'geo')}
                                                            value={this.state.selectedGeos_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    {Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    </h5>
                                                        :
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontSize: '13px'
                                                            }}
                                                                onClick={handleOpenAllAttrsModalG1}
                                                                key="AllAttrsLink"
                                                            >
                                                                View All {Object.keys(this.state.attribute_vals_filters).length} Attributes
									                </span>
                                                        </h5>
                                                    }
                                                </div>
                                                <div className='row col-sm-12'>
                                                    {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                                                    {
                                                        Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                            Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g1[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })
                                                            :
                                                            Object.keys(this.state.attribute_vals_filters).map((attribute, idx) => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                if (idx < 9) {
                                                                    return <div className='col-sm-4' key={idname}>
                                                                        <p>{this.state.attribute_lookup[attribute]}</p>
                                                                        <Select
                                                                            id={idname}
                                                                            options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                            isMulti
                                                                            placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                            onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                            value={this.state.selectedAttributes_g1[attribute]}
                                                                            styles={dropdownStyles}
                                                                        />
                                                                    </div>
                                                                }

                                                                else {
                                                                    return <div className='col-sm-4' key={idname} />
                                                                }
                                                            })

                                                    }
                                                </div>
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Price Band</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <p>Price Band</p>
                                                        <Select
                                                            id='pricebandfilter'
                                                            options={this.state.priceBandOptions}
                                                            isMulti
                                                            placeholder='Select a Price Band'
                                                            onChange={(item, position) => this.setg1definition(item, 'priceband')}
                                                            value={this.state.selectedPriceBands_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                    <div className='col-sm-4' >
                                                        <p style={{ color: '#fff' }}>Definition Complete</p>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={hideGroup1Def}
                                                                className={classes.button}
                                                            >
                                                                Ok
	                                                        </Button>
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12' style={{ height: '300px' }}>

                                                </div>

                                            </div>
                                        </Modal>

                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.showGroup2DefModal}
                                            onClose={hideGroup2Def}
                                            style={{ overflowY: 'scroll' }}
                                        >
                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
                                                <Modal
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description"
                                                    open={this.state.AllAttrsModalOpenG2}
                                                    onClose={handleCloseAllAttrsModalG2}
                                                    style={{ overflowY: 'scroll' }}
                                                >
                                                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                        <h4>Product Attributes</h4>
                                                        <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                                            {Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g2[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <h4>Second Group Definition</h4>
                                                <p>Use the following form to select the brands, attributes and price bands that define the second group for comparison.</p>
                                                <p>Group Name</p>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handleChangeG2NameBox}
                                                    inputProps={{ 'aria-label': 'naked', id: "g2Name" }}
                                                    value={this.state.g2Name}
                                                />
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Brands</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Checkbox
                                                                checked={this.state.exclude_brands_g2}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                                onChange={this.handleToggle_exclude_brands_g2}
                                                            />
                                                            Exclude Brands?
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="BrandFilter2"
                                                            options={this.state.brands}
                                                            isMulti
                                                            placeholder={'Select Brands'}
                                                            onChange={(item, position) => this.setg2definition(item, 'brand')}
                                                            value={this.state.selectedBrands_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Geographies</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="GeoFilter2"
                                                            options={this.state.geos}
                                                            isMulti
                                                            placeholder={'Select Geographies'}
                                                            onChange={(item, position) => this.setg2definition(item, 'geo')}
                                                            value={this.state.selectedGeos_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    {Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    </h5>
                                                        :
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontSize: '13px'
                                                            }}
                                                                onClick={handleOpenAllAttrsModalG2}
                                                                key="AllAttrsLink"
                                                            >
                                                                View All {Object.keys(this.state.attribute_vals_filters).length} Attributes
									                </span>
                                                        </h5>
                                                    }
                                                </div>
                                                <div className='row col-sm-12'>

                                                    {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                                                    {
                                                        Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                            Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g2[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })
                                                            :
                                                            Object.keys(this.state.attribute_vals_filters).map((attribute, idx) => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                if (idx < 9) {
                                                                    return <div className='col-sm-4' key={idname}>
                                                                        <p>{this.state.attribute_lookup[attribute]}</p>
                                                                        <Select
                                                                            id={idname}
                                                                            options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                            isMulti
                                                                            placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                            onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                            value={this.state.selectedAttributes_g2[attribute]}
                                                                            styles={dropdownStyles}
                                                                        />
                                                                    </div>
                                                                }

                                                                else {
                                                                    return <div className='col-sm-4' key={idname} />
                                                                }
                                                            })

                                                    }

                                                </div>
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Price Band</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <p>Price Band</p>
                                                        <Select
                                                            id='pricebandfilterg2'
                                                            options={this.state.priceBandOptions}
                                                            isMulti
                                                            placeholder='Select a Price Band'
                                                            onChange={(item, position) => this.setg2definition(item, 'priceband')}
                                                            value={this.state.selectedPriceBands_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                    <div className='col-sm-4' >
                                                        <p style={{ color: '#fff' }}>Definition Complete</p>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={hideGroup2Def}
                                                                className={classes.button}
                                                            >
                                                                Ok
	                                                        </Button>
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12' style={{ height: '300px' }}>

                                                </div>

                                            </div>
                                        </Modal>



                                        <div className="row" style={{ padding: "25px" }}>
                                            <ThemeProvider theme={muiTheme}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.runcomparison}
                                                    className={classes.button}
                                                >
                                                    Run Comparison
	                                            </Button>
                                            </ThemeProvider>
                                        </div>
                                        <div style={{ height: '100%', width: "100%" }}>

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            type: 'column',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: this.state.g1Name + ' Definition: ' + this.state.g1definition + '<br>' + this.state.g2Name + ' Definition: ' + this.state.g2definition + '<br>'
                                                        },
                                                        title: {
                                                            text: 'Comparison of ' + this.state.MetricToCompare + '<br/> ' + this.state.title_geo_selected + this.state.othergeo_alr + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>'
                                                        },
                                                        series: this.state.compareGraphData
                                                        //{
                                                        //name: this.state.MetricToCompare,
                                                        //data: this.state.compareGraphData
                                                        // }
                                                        ,
                                                        xAxis: [
                                                            {
                                                                title: {
                                                                    text: ''
                                                                },
                                                                type: 'category',
                                                                categories: [g1Name, g2Name]
                                                            },
                                                        ],

                                                        yAxis: [
                                                            {
                                                                title: {
                                                                    text: this.state.MetricToCompare
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                labels: {
                                                                    formatter: function () {
                                                                        if (this.chart.series[0].name === 'Payback Ratio') {
                                                                            return this.value
                                                                        }
                                                                        else {
                                                                            return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                                                        }

                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: {
                                                                    text: this.state.MetricToCompare
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                reversed: true,
                                                                opposite: true
                                                            },

                                                        ],
                                                        plotOptions: {
                                                            series: {
                                                                dataLabels: {
                                                                    enabled: true,
                                                                    formatter: function () {
                                                                        if (this.series.name === 'Payback Ratio') {
                                                                            return this.y.toFixed(2)
                                                                        }
                                                                        else if (this.series.name === 'Market Lift') {
                                                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }
                                                                        else if (this.series.name === 'Promoted Lift') {
                                                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }
                                                                        else if (this.series.name === 'Efficiency') {
                                                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }
                                                                        else if (this.series.name === 'Share of Incremental') {
                                                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }
                                                                    },
                                                                    color: 'black',
                                                                    borderRadius: 5,
                                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                    borderWidth: 1,
                                                                    borderColor: '#d6d7d8',
                                                                    padding: 2,
                                                                    style: {
                                                                        textOutline: 'none',
                                                                        fontSize: '20px'
                                                                    },                                                                    
                                                                }
                                                            },
                                                            waterfall: {
                                                                borderColor: '#ffffff'
                                                            },
                                                            column: {
                                                                grouping: false,
                                                            }
                                                        },
                                                        tooltip: {
                                                            formatter: function () {
                                                                if (this.series.name === g1Name +' - Discount Depth') {
                                                                    return '<span> Discount Depth <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                                else if (this.series.name === g2Name +' - Discount Depth') {
                                                                    return '<span> Discount Depth <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + ' <br/> x: '
                                                                        + this.x
                                                                        + '</span>'

                                                                }

                                                                else if (this.series.name === g1Name +' - Payback Ratio') {
                                                                    return '<span> Payback Ratio <br> '
                                                                        + this.y.toFixed(2)
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name +' - Payback Ratio') {
                                                                    return '<span> Payback Ratio <br> '
                                                                        + this.y.toFixed(2)
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Dollar Share Index') {
                                                                    return '<span> Dollar Share Index <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Average Dollar Share') {
                                                                    return '<span> Average Dollar Share <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Market Lift') {
                                                                    return '<span> Market Lift <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Promoted Lift') {
                                                                    return '<span> Promoted Lift <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Efficiency') {
                                                                    return '<span> Efficiency <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Share of Incremental') {
                                                                    return '<span> Share of Incremental <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name +' - Share of Incremental') {
                                                                    return '<span> Share of Incremental <br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === 'Payback Ratio') {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toFixed(2)
                                                                        + '</span>'                                                                    
                                                                }

                                                                else if (this.series.name === 'Share of Incremental') {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'                                                                    
                                                                }

                                                                else {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'                                                                    
                                                                }

                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />
                                        </div>
                                    </TabPanel>
                                    : <div />}
                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value4} index={1}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div className="" align='left' id="IconBar">
                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                name="helperText2"
                                                onClick={showHelperText}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            //id='SourceOfVolume'
                                            >
                                                <InfoRoundedIcon />
                                            </IconButton>
                                        </div>

                                        <div className="row">
                                            <div id="helperText2" className="col-sm-12" style={{ display: 'none' }}>
                                                <p>
                                                    This tab will show trended metrics for two groups.
                                                    To see the view, please select a metric you wish to compare and define the groups.
                                                    The groups must be defined at the brand or product level, and each group can have one or more members.
                                                    Once all fields are selected, hit "Run Comparison" to see the view.
                                                    If any changes are made to the fields, hit "Run Comparison" again to see the updated view.
                                                </p>
                                            </div>
                                            <div className="col-sm-6">
                                                <span>Please Select a Trended View You Wish to See</span>
                                                <Select
                                                    id="compareMetricdropdownTrended"
                                                    options={this.state.comparisonMetricOptions_trended}
                                                    placeholder={'Select a View'}
                                                    onChange={(item, action) => this.setcomparison_metric_trended(item, action)}
                                                    value={this.state.comparisonMetricSelected_trended}
                                                />
                                            </div>
                                            <div className="col-sm-6">

                                            </div>
                                        </div>


                                        <div className="row" style={{ padding: "10px" }}>
                                            <div className='col-sm-6' >
                                                <span style={{
                                                    color: '#7d32ba',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#FFF',
                                                    borderRadius: '10px 0px 0px 10px',
                                                    padding: '9px'
                                                }}
                                                    onClick={showGroup1Def}
                                                    key="Group1DefLink"
                                                >
                                                    Click Here to Define Group 1
					                            </span>
                                            </div>
                                            <div className='col-sm-6'>
                                                <span style={{
                                                    color: '#7d32ba',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#FFF',
                                                    borderRadius: '10px 0px 0px 10px',
                                                    padding: '9px'
                                                }}
                                                    onClick={showGroup2Def}
                                                    key="Group1DefLink"
                                                >
                                                    Click Here to Define Group 2
					                            </span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: "5px" }}>

                                            <div className='col-sm-6' >
                                                <h5>{this.state.g1Name + ' Definition'}</h5>
                                                {this.state.selectedGeos_g1.length > 0 ?
                                                    <span> Geography: {this.state.selectedGeos_g1[0].label}<br /></span>
                                                    :
                                                    <span />
                                                }
                                                {this.state.selectedBrands_g1.length > 0 ?

                                                    this.state.exclude_brands_g1 ?
                                                        <span> Excluding Brands: {this.state.selectedBrands_g1.map(brand => brand.value).join(',')}<br /></span>
                                                        :
                                                        <span> Brands: {this.state.selectedBrands_g1.map(brand => brand.value).join(',')}<br /></span>                                                    
                                                    :
                                                    <span />
                                                }

                                                {Object.keys(this.state.selectedAttributes_g1).map(attribute => {
                                                    if (this.state.selectedAttributes_g1[attribute].length === 0) {
                                                        return <span key={'blanklineg1tab2' + attribute} />
                                                    }
                                                    else {
                                                        return <span key={'g1tab2' + attribute}> {this.state.attribute_lookup[attribute] + ' : ' + this.state.selectedAttributes_g1[attribute].map(attr => attr.label).join(',')} <br /></span>
                                                    }
                                                })
                                                }

                                                {this.state.selectedPriceBands_g1.length > 0 ?
                                                    <span> Price Bands: {this.state.selectedPriceBands_g1.map(band => band.label).join(',')}<br /></span>
                                                    :
                                                    <span />
                                                }
                                            </div>
                                            <div className='col-sm-6' >
                                                <h5>{this.state.g2Name + ' Definition'}</h5>
                                                {this.state.selectedGeos_g2.length > 0 ?
                                                    <span> Geography: {this.state.selectedGeos_g2[0].label}<br /></span>
                                                    :
                                                    <span />
                                                }
                                                {this.state.selectedBrands_g2.length > 0 ?
                                                    this.state.exclude_brands_g2 ?
                                                        <span> Excluding Brands: {this.state.selectedBrands_g2.map(brand => brand.value).join(',')}<br /></span>
                                                        :
                                                        <span> Brands: {this.state.selectedBrands_g2.map(brand => brand.value).join(',')}<br /></span>                                                    
                                                    :
                                                    <span />
                                                }
                                                {Object.keys(this.state.selectedAttributes_g2).map(attribute => {
                                                    if (this.state.selectedAttributes_g2[attribute].length === 0) {
                                                        return <span key={'blanklineg2tab2' + attribute} />
                                                    }
                                                    else {
                                                        return <span key={'g2tab2' + attribute}> {this.state.attribute_lookup[attribute] + ' : ' + this.state.selectedAttributes_g2[attribute].map(attr => attr.label).join(',')} <br /></span>
                                                    }
                                                })
                                                }
                                                {this.state.selectedPriceBands_g2.length > 0 ?
                                                    <span> Price Bands: {this.state.selectedPriceBands_g2.map(band => band.label).join(',')}<br /></span>
                                                    :
                                                    <span />
                                                }
                                            </div>
                                        </div>



                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.showGroup1DefModal}
                                            onClose={hideGroup1Def}
                                        >
                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
                                                <Modal
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description"
                                                    open={this.state.AllAttrsModalOpenG1}
                                                    onClose={handleCloseAllAttrsModalG1}
                                                    style={{ overflowY: 'scroll' }}
                                                >
                                                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                        <h4>Product Attributes</h4>
                                                        <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                                            {Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g1[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <h4>First Group Definition</h4>
                                                <p>Use the following form to select the brands, attributes and price bands that define the first group for comparison.</p>
                                                <p>Group Name</p>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handleChangeG1NameBox}
                                                    inputProps={{ 'aria-label': 'naked', id: "g1Name" }}
                                                    value={this.state.g1Name}
                                                />
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Brands</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Checkbox
                                                                checked={this.state.exclude_brands_g1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                                onChange={this.handleToggle_exclude_brands_g1}
                                                            />
                                                    Exclude Brands?
                                                </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="BrandFilter1"
                                                            options={this.state.brands}
                                                            isMulti
                                                            placeholder={'Select Brands'}
                                                            onChange={(item, position) => this.setg1definition(item, 'brand')}
                                                            value={this.state.selectedBrands_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Geographies</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="GeoFilter1"
                                                            options={this.state.geos}
                                                            isMulti
                                                            placeholder={'Select Geographies'}
                                                            onChange={(item, position) => this.setg1definition(item, 'geo')}
                                                            value={this.state.selectedGeos_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    {Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    </h5>
                                                        :
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontSize: '13px'
                                                            }}
                                                                onClick={handleOpenAllAttrsModalG1}
                                                                key="AllAttrsLink"
                                                            >
                                                                View All {Object.keys(this.state.attribute_vals_filters).length} Attributes
									                </span>
                                                        </h5>
                                                    }
                                                </div>
                                                <div className='row col-sm-12'>
                                                    {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                                                    {
                                                        Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                            Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g1[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })
                                                            :
                                                            Object.keys(this.state.attribute_vals_filters).map((attribute, idx) => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                if (idx < 9) {
                                                                    return <div className='col-sm-4' key={idname}>
                                                                        <p>{this.state.attribute_lookup[attribute]}</p>
                                                                        <Select
                                                                            id={idname}
                                                                            options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                            isMulti
                                                                            placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                            onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                            value={this.state.selectedAttributes_g1[attribute]}
                                                                            styles={dropdownStyles}
                                                                        />
                                                                    </div>
                                                                }

                                                                else {
                                                                    return <div className='col-sm-4' key={idname} />
                                                                }
                                                            })

                                                    }
                                                </div>
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Price Band</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <p>Price Band</p>
                                                        <Select
                                                            id='pricebandfilter'
                                                            options={this.state.priceBandOptions}
                                                            isMulti
                                                            placeholder='Select a Price Band'
                                                            onChange={(item, position) => this.setg1definition(item, 'priceband')}
                                                            value={this.state.selectedPriceBands_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                    <div className='col-sm-4' >
                                                        <p style={{ color: '#fff' }}>Definition Complete</p>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={hideGroup1Def}
                                                                className={classes.button}
                                                            >
                                                                Ok
	                                                        </Button>
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12' style={{ height: '300px' }}>

                                                </div>

                                            </div>
                                        </Modal>

                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.showGroup2DefModal}
                                            onClose={hideGroup2Def}
                                        >
                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
                                                <Modal
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description"
                                                    open={this.state.AllAttrsModalOpenG2}
                                                    onClose={handleCloseAllAttrsModalG2}
                                                    style={{ overflowY: 'scroll' }}
                                                >
                                                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                        <h4>Product Attributes</h4>
                                                        <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                                            {Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g2[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <h4>Second Group Definition</h4>
                                                <p>Use the following form to select the brands, attributes and price bands that define the second group for comparison.</p>
                                                <p>Group Name</p>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handleChangeG2NameBox}
                                                    inputProps={{ 'aria-label': 'naked', id: "g2Name" }}
                                                    value={this.state.g2Name}
                                                />
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Brands</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Checkbox
                                                                checked={this.state.exclude_brands_g2}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                                onChange={this.handleToggle_exclude_brands_g2}
                                                            />
                                                            Exclude Brands?
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="BrandFilter2"
                                                            options={this.state.brands}
                                                            isMulti
                                                            placeholder={'Select Brands'}
                                                            onChange={(item, position) => this.setg2definition(item, 'brand')}
                                                            value={this.state.selectedBrands_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Geographies</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="GeoFilter2"
                                                            options={this.state.geos}
                                                            isMulti
                                                            placeholder={'Select Geographies'}
                                                            onChange={(item, position) => this.setg2definition(item, 'geo')}
                                                            value={this.state.selectedGeos_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    {Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    </h5>
                                                        :
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontSize: '13px'
                                                            }}
                                                                onClick={handleOpenAllAttrsModalG2}
                                                                key="AllAttrsLink"
                                                            >
                                                                View All {Object.keys(this.state.attribute_vals_filters).length} Attributes
									                </span>
                                                        </h5>
                                                    }
                                                </div>
                                                <div className='row col-sm-12'>
                                                    {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                                                    {
                                                        Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                            Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g2[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })
                                                            :
                                                            Object.keys(this.state.attribute_vals_filters).map((attribute, idx) => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                if (idx < 9) {
                                                                    return <div className='col-sm-4' key={idname}>
                                                                        <p>{this.state.attribute_lookup[attribute]}</p>
                                                                        <Select
                                                                            id={idname}
                                                                            options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                            isMulti
                                                                            placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                            onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                            value={this.state.selectedAttributes_g2[attribute]}
                                                                            styles={dropdownStyles}
                                                                        />
                                                                    </div>
                                                                }

                                                                else {
                                                                    return <div className='col-sm-4' key={idname} />
                                                                }
                                                            })

                                                    }
                                                </div>
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Price Band</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <p>Price Band</p>
                                                        <Select
                                                            id='pricebandfilterg2'
                                                            options={this.state.priceBandOptions}
                                                            isMulti
                                                            placeholder='Select a Price Band'
                                                            onChange={(item, position) => this.setg2definition(item, 'priceband')}
                                                            value={this.state.selectedPriceBands_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                    <div className='col-sm-4' >
                                                        <p style={{ color: '#fff' }}>Definition Complete</p>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={hideGroup2Def}
                                                                className={classes.button}
                                                            >
                                                                Ok
	                                                        </Button>
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12' style={{ height: '300px' }}>

                                                </div>

                                            </div>
                                        </Modal>
                                        <div className="row" style={{ padding: "25px" }}>
                                            <ThemeProvider theme={muiTheme}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.runcomparison}
                                                    className={classes.button}
                                                >
                                                    Run Comparison
	                                            </Button>
                                            </ThemeProvider>
                                        </div>
                                        <div style={{ height: '100%', width: "100%" }}>

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            type: 'column',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: this.state.g1Name + ' Definition: ' + this.state.g1definition + '<br>' + this.state.g2Name + ' Definition: ' + this.state.g2definition + '<br>'
                                                        },
                                                        title: {
                                                            text: 'Comparison of ' + this.state.MetricToCompare_trended + '<br/> ' + this.state.title_geo_selected + this.state.othergeo_alr + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>'
                                                        },
                                                        series: this.state.compareGraphData_trended
                                                        ,
                                                        xAxis: [
                                                            {
                                                                title: {
                                                                    text: 'Date'
                                                                },
                                                                type: 'datetime',
                                                                tickPositions: this.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                                                labels: {
                                                                    formatter: function () {
                                                                        let dt = new Date(this.value)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.value + offset)
                                                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                                        return date.toLocaleDateString(undefined, options2)
                                                                    },
                                                                    rotation: 270,
                                                                    step: 1,
                                                                    padding: 5,
                                                                    style: {
                                                                        fontSize: '11px'
                                                                    }
                                                                },
                                                            },
                                                        ],

                                                        yAxis: [
                                                            {
                                                                title: {
                                                                    text: this.state.MetricToCompare_trended_1
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                opposite: true,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: {
                                                                    text: this.state.MetricToCompare_trended_2
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                reversed: this.state.metric_trended_2_reverse,
                                                                opposite: false,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: {
                                                                    text: this.state.MetricToCompare_trended_2
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                reversed: this.state.metric_trended_2_reverse,
                                                                opposite: false,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: {
                                                                    text: g1Name + 'Base/Average Selling Price'
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                reversed: this.state.metric_trended_2_reverse,
                                                                opposite: false,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                    }
                                                                }
                                                            },

                                                        ],
                                                        plotOptions: {
                                                            series: {
                                                                dataLabels: {
                                                                    enabled: false,
                                                                    format: '{point.y:,.2f}'
                                                                }
                                                            },
                                                            waterfall: {
                                                                borderColor: '#ffffff'
                                                            },
                                                            column: {
                                                                //pointWidth: 20,
                                                                //groupPadding: 0.5
                                                                grouping: false,
                                                            }
                                                        },
                                                        tooltip: {
                                                            formatter: function () {
                                                                let datex_raw = new Date(this.x)
                                                                let tz_offset = datex_raw.getTimezoneOffset()
                                                                let datex = new Date(this.x - (tz_offset * 60))
                                                                let formatted_datex = datex.toISOString().substring(0, 10)
                                                                if (this.series.name === g1Name +' - Discount Depth') {
                                                                    return '<span> Discount Depth <br> '
                                                                        + new Date(this.x).toLocaleDateString({ month: 'long', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + ' <br/> ASP: '
                                                                        + (total_dollars_weekly_g1_me[formatted_datex] / totalunits_g1_weekly[formatted_datex]).toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }
                                                                else if (this.series.name === g2Name +' - Discount Depth') {                                                                    
                                                                    return '<span> Discount Depth <br> '
                                                                        + datex.toLocaleDateString({ month: 'long', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + ' <br/> ASP: '
                                                                        + (total_dollars_weekly_g2_me[formatted_datex] / totalunits_g2_weekly[formatted_datex]).toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Payback Ratio') {
                                                                    return '<span> Payback Ratio <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toFixed(2)
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name +' - Payback Ratio') {
                                                                    return '<span> Payback Ratio <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toFixed(2)
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Average Selling Price') {
                                                                    return '<span> Average Selling Price <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name +' - Average Selling Price') {
                                                                    return '<span> Average Selling Price <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === 'Average Selling Price Gap - Group 1 minus Group 2') {
                                                                    return '<span> Average Selling Price Gap<br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === 'Base Price Gap - Group 1 minus Group 2') {
                                                                    return '<span> Base Price Gap <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Base Price') {
                                                                    return '<span> Base Price <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name +' - Base Price') {
                                                                    return '<span> Base Price <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Dollar Share Index') {
                                                                    return '<span> Dollar Share Index <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Average Dollar Share') {
                                                                    return '<span> Average Dollar Share <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Market Lift') {
                                                                    return '<span> Market Lift <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Promoted Lift') {
                                                                    return '<span> Promoted Lift <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Efficiency') {
                                                                    return '<span> Efficiency <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name + ' - Market Lift') {
                                                                    return '<span> Market Lift <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name + ' - Promoted Lift') {
                                                                    return '<span> Promoted Lift <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name + ' - Efficiency') {
                                                                    return '<span> Efficiency <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name +' - Share of Incremental') {
                                                                    return '<span> Share of Incremental <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Share of Incremental') {
                                                                    return '<span> Share of Incremental <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === 'Payback Ratio') {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toFixed(2) 
                                                                        + '</span>'                                                                    
                                                                }

                                                                else if (this.series.name === 'Share of Incremental') {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'                                                                    
                                                                }

                                                                else {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'                                                                    
                                                                }

                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />
                                        </div>
                                    </TabPanel>
                                    : <div />}
                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value4} index={2}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div className="" align='left' id="IconBar">
                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                name="helperText3"
                                                onClick={showHelperText}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            //id='SourceOfVolume'
                                            >
                                                <InfoRoundedIcon />
                                            </IconButton>
                                        </div>

                                        <div className="row">
                                            <div id="helperText3" className="col-sm-12" style={{ display: 'none' }}>
                                                <p>
                                                    This tab will show the indexed share for a group.
                                                    To see the view, please define the members for the group.
                                                    The group must be defined at the brand or product level, and the group can have one or more members.
                                                    There is also an option to add an additional metric overlayed on the view.
                                                    Once all fields are selected, hit "Run Comparison" to see the view.
                                                    If any changes are made to the fields, hit "Run Comparison" again to see the updated view.
                                                </p>
                                            </div>
                                            <div className="col-sm-6">
                                                <span>Please Select a Metric You Wish to Overlay</span>
                                                <Select
                                                    id="OverlayMetricdropdown"
                                                    options={this.state.overlayMetricOptions}
                                                    placeholder={'Select a Metric'}
                                                    onChange={(item, action) => this.setoverlay_metric(item, action)}
                                                    value={this.state.overlayMetricSelected}
                                                />
                                            </div>
                                            <div className="col-sm-6">

                                            </div>
                                        </div>

                                        <div className="row" style={{ padding: "10px" }}>
                                            <div className='col-sm-6' >
                                                <span style={{
                                                    color: '#7d32ba',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#FFF',
                                                    borderRadius: '10px 0px 0px 10px',
                                                    padding: '9px'
                                                }}
                                                    onClick={showGroup1Def}
                                                    key="Group1DefLink"
                                                >
                                                    Click Here to Define Group 1
					                            </span>
                                            </div>
                                            <div className='col-sm-6'>
                                                <span style={{
                                                    color: '#7d32ba',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#FFF',
                                                    borderRadius: '10px 0px 0px 10px',
                                                    padding: '9px'
                                                }}
                                                    onClick={showGroup2Def}
                                                    key="Group1DefLink"
                                                >
                                                    Click Here to Define Group 2
					                            </span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: "5px" }}>

                                            <div className='col-sm-6' >
                                                <h5>{this.state.g1Name + ' Definition'}</h5>
                                                {this.state.selectedGeos_g1.length > 0 ?
                                                    <span> Geography: {this.state.selectedGeos_g1[0].label}<br /></span>
                                                    :
                                                    <span />
                                                }
                                                {this.state.selectedBrands_g1.length > 0 ?
                                                    this.state.exclude_brands_g1 ?
                                                        <span> Excluding Brands: {this.state.selectedBrands_g1.map(brand => brand.value).join(',')}<br /></span>
                                                        :
                                                        <span> Brands: {this.state.selectedBrands_g1.map(brand => brand.value).join(',')}<br /></span>                                                    
                                                    :
                                                    <span />
                                                }

                                                {Object.keys(this.state.selectedAttributes_g1).map(attribute => {
                                                    if (this.state.selectedAttributes_g1[attribute].length === 0) {
                                                        return <span key={'blanklineg1tab3' + attribute} />
                                                    }
                                                    else {
                                                        return <span key={'g1tab3' + attribute}> {this.state.attribute_lookup[attribute] + ' : ' + this.state.selectedAttributes_g1[attribute].map(attr => attr.label).join(',')} <br /></span>
                                                    }
                                                })
                                                }

                                                {this.state.selectedPriceBands_g1.length > 0 ?
                                                    <span> Price Bands: {this.state.selectedPriceBands_g1.map(band => band.label).join(',')}<br /></span>
                                                    :
                                                    <span />
                                                }
                                            </div>
                                            <div className='col-sm-6' >
                                                <h5>{this.state.g2Name + ' Definition'}</h5>
                                                {this.state.selectedGeos_g2.length > 0 ?
                                                    <span> Geography: {this.state.selectedGeos_g2[0].label}<br /></span>
                                                    :
                                                    <span />
                                                }
                                                {this.state.selectedBrands_g2.length > 0 ?
                                                    this.state.exclude_brands_g2 ?
                                                        <span> Excluding Brands: {this.state.selectedBrands_g2.map(brand => brand.value).join(',')}<br /></span>
                                                        :
                                                        <span> Brands: {this.state.selectedBrands_g2.map(brand => brand.value).join(',')}<br /></span>                                                    
                                                    :
                                                    <span />
                                                }
                                                {Object.keys(this.state.selectedAttributes_g2).map(attribute => {
                                                    if (this.state.selectedAttributes_g2[attribute].length === 0) {
                                                        return <span key={'blanklineg2tab3' + attribute} />
                                                    }
                                                    else {
                                                        return <span key={'g2tab3' + attribute}> {this.state.attribute_lookup[attribute] + ' : ' + this.state.selectedAttributes_g2[attribute].map(attr => attr.label).join(',')} <br /></span>
                                                    }
                                                })
                                                }
                                                {this.state.selectedPriceBands_g2.length > 0 ?
                                                    <span> Price Bands: {this.state.selectedPriceBands_g2.map(band => band.label).join(',')}<br /></span>
                                                    :
                                                    <span />
                                                }
                                            </div>
                                        </div>



                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.showGroup1DefModal}
                                            onClose={hideGroup1Def}
                                        >
                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
                                                <Modal
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description"
                                                    open={this.state.AllAttrsModalOpenG1}
                                                    onClose={handleCloseAllAttrsModalG1}
                                                    style={{ overflowY: 'scroll' }}
                                                >
                                                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                        <h4>Product Attributes</h4>
                                                        <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                                            {Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g1[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <h4>First Group Definition</h4>
                                                <p>Use the following form to select the brands, attributes and price bands that define the first group for comparison.</p>
                                                <p>Group Name</p>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handleChangeG1NameBox}
                                                    inputProps={{ 'aria-label': 'naked', id: "g1Name" }}
                                                    value={this.state.g1Name}
                                                />
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Brands</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Checkbox
                                                                checked={this.state.exclude_brands_g1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                                onChange={this.handleToggle_exclude_brands_g1}
                                                            />
                                                    Exclude Brands?
                                                </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="BrandFilter1"
                                                            options={this.state.brands}
                                                            isMulti
                                                            placeholder={'Select Brands'}
                                                            onChange={(item, position) => this.setg1definition(item, 'brand')}
                                                            value={this.state.selectedBrands_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Geographies</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="GeoFilter1"
                                                            options={this.state.geos}
                                                            isMulti
                                                            placeholder={'Select Geographies'}
                                                            onChange={(item, position) => this.setg1definition(item, 'geo')}
                                                            value={this.state.selectedGeos_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    {Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    </h5>
                                                        :
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontSize: '13px'
                                                            }}
                                                                onClick={handleOpenAllAttrsModalG1}
                                                                key="AllAttrsLink"
                                                            >
                                                                View All {Object.keys(this.state.attribute_vals_filters).length} Attributes
									                </span>
                                                        </h5>
                                                    }
                                                </div>
                                                <div className='row col-sm-12'>
                                                    {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                                                    {
                                                        Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                            Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g1[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })
                                                            :
                                                            Object.keys(this.state.attribute_vals_filters).map((attribute, idx) => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                if (idx < 9) {
                                                                    return <div className='col-sm-4' key={idname}>
                                                                        <p>{this.state.attribute_lookup[attribute]}</p>
                                                                        <Select
                                                                            id={idname}
                                                                            options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                            isMulti
                                                                            placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                            onChange={(item, position) => this.setg1definition(item, attribute)}
                                                                            value={this.state.selectedAttributes_g1[attribute]}
                                                                            styles={dropdownStyles}
                                                                        />
                                                                    </div>
                                                                }

                                                                else {
                                                                    return <div className='col-sm-4' key={idname} />
                                                                }
                                                            })

                                                    }
                                                </div>
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Price Band</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <p>Price Band</p>
                                                        <Select
                                                            id='pricebandfilter'
                                                            options={this.state.priceBandOptions}
                                                            isMulti
                                                            placeholder='Select a Price Band'
                                                            onChange={(item, position) => this.setg1definition(item, 'priceband')}
                                                            value={this.state.selectedPriceBands_g1}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                    <div className='col-sm-4' >
                                                        <p style={{ color: '#fff' }}>Definition Complete</p>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={hideGroup1Def}
                                                                className={classes.button}
                                                            >
                                                                Ok
	                                                        </Button>
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12' style={{ height: '300px' }}>

                                                </div>

                                            </div>
                                        </Modal>

                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.showGroup2DefModal}
                                            onClose={hideGroup2Def}
                                        >
                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
                                                <Modal
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description"
                                                    open={this.state.AllAttrsModalOpenG2}
                                                    onClose={handleCloseAllAttrsModalG2}
                                                    style={{ overflowY: 'scroll' }}
                                                >
                                                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                        <h4>Product Attributes</h4>
                                                        <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                                            {Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g2[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <h4>Second Group Definition</h4>
                                                <p>Use the following form to select the brands, attributes and price bands that define the second group for comparison.</p>
                                                <p>Group Name</p>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={handleChangeG2NameBox}
                                                    inputProps={{ 'aria-label': 'naked', id: "g2Name" }}
                                                    value={this.state.g2Name}
                                                />
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Brands</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Checkbox
                                                                checked={this.state.exclude_brands_g2}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                                onChange={this.handleToggle_exclude_brands_g2}
                                                            />
                                                            Exclude Brands?
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="BrandFilter2"
                                                            options={this.state.brands}
                                                            isMulti
                                                            placeholder={'Select Brands'}
                                                            onChange={(item, position) => this.setg2definition(item, 'brand')}
                                                            value={this.state.selectedBrands_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Geographies</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            id="GeoFilter2"
                                                            options={this.state.geos}
                                                            isMulti
                                                            placeholder={'Select Geographies'}
                                                            onChange={(item, position) => this.setg2definition(item, 'geo')}
                                                            value={this.state.selectedGeos_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>
                                                    <br />
                                                    {Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    </h5>
                                                        :
                                                        <h5>
                                                            Product Attributes &nbsp;
                                                    <span style={{
                                                                color: '#7d32ba',
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontSize: '13px'
                                                            }}
                                                                onClick={handleOpenAllAttrsModalG2}
                                                                key="AllAttrsLink"
                                                            >
                                                                View All {Object.keys(this.state.attribute_vals_filters).length} Attributes
									                </span>
                                                        </h5>
                                                    }
                                                </div>
                                                <div className='row col-sm-12'>
                                                    {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                                                    {
                                                        Object.keys(this.state.attribute_vals_filters).length <= 9 ?
                                                            Object.keys(this.state.attribute_vals_filters).map(attribute => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                return <div className='col-sm-4' key={idname}>
                                                                    <p>{this.state.attribute_lookup[attribute]}</p>
                                                                    <Select
                                                                        id={idname}
                                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                        isMulti
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                        onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                        value={this.state.selectedAttributes_g2[attribute]}
                                                                        styles={dropdownStyles}
                                                                    />
                                                                </div>
                                                            })
                                                            :
                                                            Object.keys(this.state.attribute_vals_filters).map((attribute, idx) => {
                                                                let attribute_vals = this.state.attribute_vals_filters[attribute]
                                                                let idname = attribute + "dropdown"
                                                                if (idx < 9) {
                                                                    return <div className='col-sm-4' key={idname}>
                                                                        <p>{this.state.attribute_lookup[attribute]}</p>
                                                                        <Select
                                                                            id={idname}
                                                                            options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                                            isMulti
                                                                            placeholder={'Select ' + this.state.attribute_lookup[attribute]}
                                                                            onChange={(item, position) => this.setg2definition(item, attribute)}
                                                                            value={this.state.selectedAttributes_g2[attribute]}
                                                                            styles={dropdownStyles}
                                                                        />
                                                                    </div>
                                                                }
                                                                else {
                                                                    return <div className='col-sm-4' key={idname} />
                                                                }
                                                            })

                                                    }
                                                </div>
                                                <div className='col-sm-12'>
                                                    <br />
                                                    <h5>Price Band</h5>
                                                </div>
                                                <div className='row col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <p>Price Band</p>
                                                        <Select
                                                            id='pricebandfilterg2'
                                                            options={this.state.priceBandOptions}
                                                            isMulti
                                                            placeholder='Select a Price Band'
                                                            onChange={(item, position) => this.setg2definition(item, 'priceband')}
                                                            value={this.state.selectedPriceBands_g2}
                                                            styles={dropdownStyles}
                                                        />
                                                    </div>
                                                    <div className='col-sm-4' >
                                                        <p style={{ color: '#fff' }}>Definition Complete</p>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={hideGroup2Def}
                                                                className={classes.button}
                                                            >
                                                                Ok
	                                                        </Button>
                                                        </ThemeProvider>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12' style={{ height: '300px' }}>

                                                </div>

                                            </div>
                                        </Modal>
                                        <div className="row" style={{ padding: "25px" }}>
                                            <ThemeProvider theme={muiTheme}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.runcomparison}
                                                    className={classes.button}
                                                >
                                                    Run Comparison
	                                            </Button>
                                                <span style={{ width: '100px' }}></span>
                                                <span>Indexed View</span>
                                                <Switch
                                                    checked={this.state.indexSwitchCheck}
                                                    onChange={handleChange_IndexSwitch}
                                                    color="secondary"
                                                />
                                                <span>Over/Under View </span>
                                                <span style={{ width: '100px' }}></span>
                                                <span style={{ lineHeight: '5' }}>Units</span>
                                                <div style={{ lineHeight: '5' }}>
                                                    <Switch
                                                        checked={this.state.indexSwitchCheckDollars}
                                                        onChange={handleChange_IndexSwitchDollars}
                                                        color="secondary"
                                                    />
                                                </div>
                                                <span style={{ lineHeight: '5' }}>Dollars </span>
                                            </ThemeProvider>
                                        </div>
                                        <div style={{ height: '100%', width: "100%" }}>

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            type: 'column',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: this.state.g1Name + ' Definition: ' + this.state.g1definition + '<br>' + this.state.g2Name + ' Definition: ' + this.state.g2definition + '<br>'
                                                        },
                                                        title: {
                                                            text: 'Comparison of  Dollar Share By Week <br/> ' + this.state.title_geo_selected + this.state.othergeo_alr + '<br/> ' + this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4) + '<br/>'
                                                        },
                                                        series: compareIndexGraphData_toview_var //this.state.compareIndexGraphData_toview //this.state.compareIndexGraphData                  
                                                        ,
                                                        xAxis: [
                                                            {
                                                                title: {
                                                                    text: 'Date'
                                                                },
                                                                type: 'datetime',
                                                                tickPositions: this.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                                                labels: {
                                                                    formatter: function () {
                                                                        let dt = new Date(this.value)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.value + offset)
                                                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                                        return date.toLocaleDateString(undefined, options2)
                                                                    },
                                                                    rotation: 270,
                                                                    step: 1,
                                                                    padding: 5,
                                                                    style: {
                                                                        fontSize: '11px'
                                                                    }
                                                                },
                                                            },
                                                        ],

                                                        yAxis: [
                                                            {
                                                                title: {
                                                                    text: 'Indexed Dollar Share'
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: {
                                                                    text: 'Average Discount/Lift/Efficiency'
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                reversed: false,
                                                                opposite: true,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: {
                                                                    text: 'Average Selling Price/Base Price/Price Gaps'
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                reversed: false,
                                                                opposite: true,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: {
                                                                    text: 'Average Selling Price/Base Price'
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                                reversed: false,
                                                                opposite: true,
                                                                labels: {
                                                                    formatter: function () {
                                                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                    }
                                                                }
                                                            },

                                                        ],
                                                        plotOptions: {
                                                            series: {
                                                                dataLabels: {
                                                                    enabled: false,
                                                                    format: '{point.y:,.2f}'
                                                                }
                                                            },
                                                            waterfall: {
                                                                borderColor: '#ffffff'
                                                            },
                                                            column: {
                                                                //pointWidth: 20,
                                                                //groupPadding: 0.5
                                                                grouping: false,
                                                            }
                                                        },
                                                        tooltip: {
                                                            formatter: function () {
                                                                let datex_raw = new Date(this.x)
                                                                let tz_offset = datex_raw.getTimezoneOffset()
                                                                let datex = new Date(this.x - (tz_offset * 60))
                                                                let formatted_datex = datex.toISOString().substring(0, 10)
                                                                if (this.series.name === g1Name + ' - Discount Depth') {
                                                                    return '<span> Discount Depth <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                                else if (this.series.name === g2Name + ' - Discount Depth') {
                                                                    return '<span> Discount Depth <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name + ' - Payback Ratio') {
                                                                    return '<span> Payback Ratio <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toFixed(2)
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === 'Group 2 - Payback Ratio') {
                                                                    return '<span> Payback Ratio <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toFixed(2)
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Dollar Share Index') {
                                                                    return '<span> Dollar Share Index <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Over/Under Dollar Share') {
                                                                    return '<span> Dollar Share Index <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })                                                                        
                                                                        + '<br/> Percent Over/Under Share: '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Average Dollar Share') {
                                                                    return '<span> Average Dollar Share <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Average Discount') {
                                                                    return '<span> Average Discount <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Market Lift') {
                                                                    return '<span> Market Lift <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +'- Promoted Lift') {
                                                                    return '<span> Promoted Lift <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Efficiency') {
                                                                    return '<span> Efficiency <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Share of Incremental') {
                                                                    return '<span> Share of Incremental <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g2Name +'- Share of Incremental') {
                                                                    return '<span> Share of Incremental <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Average Selling Price') {
                                                                    return '<span> Average Selling Price <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Base Price') {
                                                                    return '<span> Base Price <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - ASP Gap') {
                                                                    return '<span> ASP Gap <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }

                                                                else if (this.series.name === g1Name +' - Base Price Gap') {
                                                                    return '<span> Base Price Gap <br> Week Ending: '
                                                                        + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br/>'
                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                        + '</span>'
                                                                }



                                                                else if (this.series.name === 'Payback Ratio') {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toFixed(2) 
                                                                        + '</span>'                                                                    
                                                                }

                                                                else {
                                                                    return '<span>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'                                                                    
                                                                }

                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />
                                        </div>
                                    </TabPanel>
                                    : <div />}
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-sm-12" style={{ maxWidth: '1500px' }}>
                            <div align='center' className="contentHolderTight">
                                <h5 style={{ padding: '10px' }}>Prior Period Analysis</h5>
                                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }}>
                                    <Constant.NPDTabs value={this.state.value3} onChange={handleChange3} aria-label="simple tabs example" className="reporttabsheader">
                                        <Constant.NPDTab label="DUE TO ANALYSIS" {...a11yProps(0)} />
                                        <Constant.NPDTab label="TOTAL VOLUME COMPARISON" {...a11yProps(1)} />
                                        <Constant.NPDTab label="DEPTH OF DISCOUNT COMPARISON" {...a11yProps(1)} />
                                    </Constant.NPDTabs>
                                </Constant.NPDAppbar>
                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value3} index={0}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%" }}>

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            type: 'waterfall',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: 'Current Period is from '
                                                                + this.state.currperiodstart_caption
                                                                + ' to '
                                                                + this.state.currperiodend_caption
                                                                + '<br/> Previous Period is from '
                                                                + this.state.prevperiodstart_caption//this.state.start_date_prior.substring(0, 10)
                                                                + ' to '
                                                                + this.state.prevperiodend_caption//this.state.end_date_prior.substring(0, 10)
                                                                + '<br/>'
                                                                + 'Attributes Selected: '
                                                                + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                        },
                                                        title: {
                                                            text: 'Due to Analyses<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks_compperiods + ' Weeks ending ' + this.state.currperiodend_caption + '<br/>' + this.state.title_brands_detail
                                                        },
                                                        legend: {
                                                            enabled: false
                                                        },
                                                        series: [{
                                                            name: 'Source of Units',
                                                            data:
                                                                [{
                                                                    name: 'Previous Period ' + this.state.unitsvar,
                                                                    y: this.state.TotalUnits_prior,
                                                                    color: '#7d32ba'
                                                                }].concat(this.state.data_waterfall).concat([{
                                                                    name: 'Current Period ' + this.state.unitsvar,
                                                                    isIntermediateSum: true,
                                                                    color: '#7d32ba'
                                                                }])
                                                        }],
                                                        xAxis: {
                                                            title: {
                                                                text: ''
                                                            },
                                                            type: 'category',

                                                        },
                                                        yAxis: [
                                                            {
                                                                title: {
                                                                    text: this.state.unitsvar
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1
                                                            },

                                                        ],
                                                        plotOptions: {
                                                            series: {
                                                                dataLabels: {
                                                                    enabled: true,
                                                                    formatter: function () {
                                                                        //console.log(this)
                                                                        function nFormatter(num, digits) {
                                                                            var si = [
                                                                                { value: 1E18, symbol: "E" },
                                                                                { value: 1E15, symbol: "P" },
                                                                                { value: 1E12, symbol: "T" },
                                                                                { value: 1E9, symbol: "B" },
                                                                                { value: 1E6, symbol: "M" },
                                                                                { value: 1E3, symbol: "k" }
                                                                            ], i;
                                                                            for (i = 0; i < si.length; i++) {
                                                                                if (Math.abs(num) >= si[i].value) {
                                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                }
                                                                                else if (Math.abs(num) < 1E3) {
                                                                                    return (num).toFixed(digits) + " ";
                                                                                }
                                                                            }
                                                                            return num.toString();
                                                                        }
                                                                        return nFormatter(this.point.y, 1).toLocaleString()
                                                                    },
                                                                    color: 'black',
                                                                    borderRadius: 5,
                                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                    borderWidth: 1,
                                                                    borderColor: '#d6d7d8',
                                                                    padding: 0,
                                                                    style: {
                                                                        textOutline: 'none',
                                                                        fontSize: '20px'
                                                                    },
                                                                    y: 0
                                                                }                                                            
                                                            },
                                                            waterfall: {
                                                                borderColor: '#ffffff'
                                                            }
                                                        },
                                                        tooltip: {
                                                            formatter: function () {
                                                                return '<span>' + this.point.name + ' <br> ' + Math.round(this.y).toLocaleString() + ' ' + unitsvar_forgraphs + ' </span>'
                                                            }
                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />
                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow key="header_g18">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Due To</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{this.state.unitsvar}</Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                    <Constant.NPDTableRow key="previousunits_g18">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Previous Period " + this.state.unitsvar}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{this.state.TotalUnits_prior}</Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                    {Object.values(this.state.data_waterfall).map(row => {
                                                        return <Constant.NPDTableRow key={"datarow_" + row.name + "_g18"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{row.name}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(row.y).toLocaleString()}</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                    })
                                                    }
                                                    <Constant.NPDTableRow key="Currentunits_g18">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Current Period " + this.state.unitsvar}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(this.state.TotalUnits_prior + amounttoadd).toLocaleString()}</Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>

                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value3} index={1}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            zoomType: 'xy',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: 'Current Period is from '
                                                                + this.state.currperiodstart_caption
                                                                + ' to '
                                                                + this.state.currperiodend_caption
                                                                + '<br/> Previous Period is from '
                                                                + this.state.prevperiodstart_caption//this.state.start_date_prior.substring(0, 10)
                                                                + ' to '
                                                                + this.state.prevperiodend_caption//this.state.end_date_prior.substring(0, 10)
                                                                + '<br/>'
                                                                + 'Attributes Selected: '
                                                                + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                        },
                                                        title: {
                                                            text: 'Total ' + this.state.unitsvar + ' YOY<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks_compperiods + ' Weeks ending ' + this.state.currperiodend_caption + '<br/>' + this.state.title_brands_detail
                                                        },
                                                        series: [
                                                            {
                                                                name: 'Previous Period Total ' + this.state.unitsvar,
                                                                type: 'spline',
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        return '<span>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + Math.round(this.y).toLocaleString()
                                                                            + '<br> '
                                                                            + Object.keys(var_total_vol_by_week_prior)[this.x].substring(0, 10)
                                                                            + '</span>'
                                                                    }
                                                                },
                                                                data: Object.values(this.state.total_vol_by_week_prior)

                                                            },

                                                            {
                                                                name: 'Current Period Total ' + this.state.unitsvar,
                                                                type: 'spline',
                                                                color: '#ff8b6a',
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        return '<span>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + Math.round(this.y).toLocaleString()
                                                                            + '<br> '
                                                                            + Object.keys(var_total_vol_by_week)[this.x].substring(0, 10)
                                                                            + '</span>'
                                                                    }
                                                                },
                                                                data: Object.values(this.state.total_vol_by_week_current)
                                                            },

                                                        ],
                                                        xAxis: {
                                                            title: {
                                                                text: 'Week'
                                                            },
                                                            allowDecimals: false,
                                                        },
                                                        yAxis: [
                                                            {
                                                                title: {
                                                                    text: this.state.unitsvar
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                            },
                                                        ],
                                                        plotOptions: {
                                                            column: {
                                                                stacking: 'normal',
                                                                dataLabels: {
                                                                    enabled: false,
                                                                    format: '{point.y:,.0f}'
                                                                }
                                                            },

                                                            spline: {
                                                                marker: {
                                                                    enabled: false
                                                                }
                                                            }

                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />
                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow key="header_g19">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Week</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Prior Period " + this.state.unitsvar}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Current Period " + this.state.unitsvar}</Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>

                                                    {Object.keys(this.state.total_vol_by_week_prior).map(date => {
                                                        return <Constant.NPDTableRow key={"datarowprior_" + date + "_g18"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{this.state.total_vol_by_week_prior[date].toLocaleString()}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                    })
                                                    }

                                                    {Object.keys(this.state.total_vol_by_week_current).map(date => {
                                                        return <Constant.NPDTableRow key={"datarow_" + date + "_g18"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{this.state.total_vol_by_week_current[date].toLocaleString()}</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                    })
                                                    }



                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>

                                    </TabPanel>
                                    : <div />}

                                {this.state.isLoaded ?
                                    <TabPanel value={this.state.value3} index={2}>
                                        <div className="" align='left' id="IconBar">

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='area'
                                                style={{ padding: '5px', margin: '5px' }}
                                            >
                                                <ShowChartRoundedIcon />

                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary"
                                                onClick={changeTypeGraph}
                                                value='column'
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                            >
                                                <BarChartRoundedIcon />
                                            </IconButton>

                                            <IconButton
                                                className="btn btn-primary ChangeDataTableButton"
                                                onClick={changeShowDataTable}
                                                style={{
                                                    padding: '5px', margin: '5px'
                                                }}
                                                id='SourceOfVolume'
                                            >
                                                <TableChartRoundedIcon />
                                            </IconButton>

                                        </div>
                                        <div style={{ height: '100%', width: "100%", }}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    {
                                                        chart: {
                                                            zoomType: 'xy',
                                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                                        },
                                                        caption: {
                                                            text: 'Current Period is from '
                                                                + this.state.currperiodstart_caption
                                                                + ' to '
                                                                + this.state.currperiodend_caption
                                                                + '<br/> Previous Period is from '
                                                                + this.state.prevperiodstart_caption//this.state.start_date_prior.substring(0, 10)
                                                                + ' to '
                                                                + this.state.prevperiodend_caption//this.state.end_date_prior.substring(0, 10)
                                                                + '<br/>'
                                                                + 'Attributes Selected: '
                                                                + Object.keys(this.state.filters[5]).map(attribute => this.state.attribute_lookup[attribute] + ': ' + this.state.filters[5][attribute].map(attrval => attrval.value))
                                                        },
                                                        title: {
                                                            text: 'Difference in Promoted ' + this.state.unitsvar + ' for Discount Band YOY<br/>' + this.state.title_geo_selected + '<br/> ' + this.state.title_num_weeks_compperiods + ' Weeks ending ' + this.state.currperiodend_caption + '<br/>' + this.state.title_brands_detail
                                                        },
                                                        legend: {
                                                            enabled: false
                                                        },
                                                        series: [
                                                            {
                                                                name: 'Difference of Current Period from Prior Period',
                                                                type: 'column',
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                                    }
                                                                },
                                                                data: this.state.discount_buckets.map(bucket => {
                                                                    if (this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                                                                        var colorchoice = ""
                                                                        if (this.state.discount_bucket_mapping_current[bucket] - this.state.discount_bucket_mapping_prior[bucket] > 0) {
                                                                            colorchoice = '#7d32ba'
                                                                        }
                                                                        else {
                                                                            colorchoice = '#f3508e'
                                                                        }
                                                                        return {
                                                                            'y': this.state.discount_bucket_mapping_current[bucket] - this.state.discount_bucket_mapping_prior[bucket],
                                                                            'color': colorchoice
                                                                        }
                                                                    }
                                                                    else if (this.state.discount_bucket_mapping_prior[bucket] && !this.state.discount_bucket_mapping_current[bucket]) {
                                                                        return {
                                                                            'y': 0 - this.state.discount_bucket_mapping_prior[bucket],
                                                                            'color': '#f3508e'
                                                                        }
                                                                    }
                                                                    else if (!this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                                                                        return {
                                                                            'y': this.state.discount_bucket_mapping_current[bucket] - 0,
                                                                            'color': '#bc81ec'
                                                                        }
                                                                    }
                                                                    else {
                                                                        return {
                                                                            'y': 0,
                                                                            'color': '#565a5c'
                                                                        }
                                                                    }
                                                                }).concat([
                                                                    {
                                                                        'y': this.state.discount_buckets.map(bucket => {
                                                                            if (this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                                                                                return this.state.discount_bucket_mapping_current[bucket] - this.state.discount_bucket_mapping_prior[bucket]
                                                                            }
                                                                            else if (this.state.discount_bucket_mapping_prior[bucket] && !this.state.discount_bucket_mapping_current[bucket]) {
                                                                                return 0 - this.state.discount_bucket_mapping_prior[bucket]
                                                                            }
                                                                            else if (!this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                                                                                return this.state.discount_bucket_mapping_current[bucket] - 0
                                                                            }
                                                                            else {
                                                                                return 0
                                                                            }
                                                                        }).reduce(reducer),
                                                                        'color': '#7d32ba'
                                                                    }
                                                                ])

                                                            },

                                                        ],
                                                        xAxis: {
                                                            title: {
                                                                text: 'Discount Band'
                                                            },
                                                            categories: this.state.discount_buckets.concat(['Total Difference'])
                                                        },
                                                        yAxis: [
                                                            {
                                                                title: {
                                                                    text: this.state.unitsvar
                                                                },
                                                                gridLineColor: "#CCC",
                                                                gridLineWidth: 1,
                                                            },
                                                        ],
                                                        plotOptions: {
                                                            column: {
                                                                stacking: 'normal',
                                                                dataLabels: {
                                                                    enabled: true,
                                                                    formatter: function () {
                                                                        //console.log(this)
                                                                        function nFormatter(num, digits) {
                                                                            var si = [
                                                                                { value: 1E18, symbol: "E" },
                                                                                { value: 1E15, symbol: "P" },
                                                                                { value: 1E12, symbol: "T" },
                                                                                { value: 1E9, symbol: "B" },
                                                                                { value: 1E6, symbol: "M" },
                                                                                { value: 1E3, symbol: "k" }
                                                                            ], i;
                                                                            for (i = 0; i < si.length; i++) {
                                                                                if (Math.abs(num) >= si[i].value) {
                                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                }
                                                                                else if (Math.abs(num) < 1E3) {
                                                                                    return (num).toFixed(digits) + " ";
                                                                                }
                                                                            }
                                                                            return num.toString();
                                                                        }
                                                                        return nFormatter(this.point.y, 1).toLocaleString()
                                                                    },
                                                                    color: 'black',
                                                                    borderRadius: 5,
                                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                    borderWidth: 1,
                                                                    borderColor: '#d6d7d8',
                                                                    padding: 0,
                                                                    style: {
                                                                        textOutline: 'none',
                                                                        fontSize: '20px'
                                                                    },
                                                                    y: 0
                                                                } 
                                                            },

                                                            spline: {
                                                                marker: {
                                                                    enabled: false
                                                                }
                                                            }

                                                        },
                                                        exporting: {
                                                            showTable: this.state.showDataTable,
                                                            tableCaption: false
                                                        },

                                                    }}
                                            />
                                        </div>

                                        <div style={{ display: this.state.showDataTableStyle }}>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow key="header_g19">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Band</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Difference in " + this.state.unitsvar}</Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>

                                                    {this.state.discount_buckets.map(bucket => {
                                                        if (this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                                                            var y = this.state.discount_bucket_mapping_current[bucket] - this.state.discount_bucket_mapping_prior[bucket]
                                                        }
                                                        else if (this.state.discount_bucket_mapping_prior[bucket] && !this.state.discount_bucket_mapping_current[bucket]) {
                                                            var y = 0 - this.state.discount_bucket_mapping_prior[bucket]
                                                        }
                                                        else if (!this.state.discount_bucket_mapping_prior[bucket] && this.state.discount_bucket_mapping_current[bucket]) {
                                                            var y = this.state.discount_bucket_mapping_current[bucket] - 0
                                                        }
                                                        else {
                                                            var y = 0
                                                        }
                                                        return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                    })
                                                    }
                                                    <Constant.NPDTableRow key={"datarowtotal_g19"}>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Total Difference</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            {totaldiffyoy.toLocaleString()}
                                                        </Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>

                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                        </div>


                                    </TabPanel>
                                    : <div />}
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '40px', paddingRight: '40px' }}>
                        <div className="col-sm-12 contentHolder" style={{ maxWidth: 'calc(85vw - 250px)', margin: '15px', }}>
                            <div className="col-sm-12 row" style={{ maxWidth: 'calc(85vw - 150px)', margin: '0px',  }}>
                                <div align='left' className="col-sm-11" >
                                    <h3>Key Metrics</h3>
                                </div>
                                <div align='right' className="col-sm-1" >
                                <IconButton aria-label="close" color="white" onClick={openScoreCardMenu}>
                                    <MenuIcon fontSize="large" />
                                </IconButton>
                                <Constant.NPDPopover
                                    anchorEl={this.state.anchorELPos}
                                    open={this.state.openScorecardMenu}
                                    transition
                                    onClose={closeScoreCardMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                //marginThreshold={0}
                                //transitionDuration={0}
                                >
                                    <div style={{ padding: '25px' }}>
                                        <div >
                                            <span style={{
                                                    color: '#7d32ba',
                                                cursor: 'pointer',
                                                fontWeight: 'bold'
                                            }}
                                                onClick={downloadScoreCardData}
                                            >
                                                Download Scorecard as XLSX
										                        </span>
                                        </div>
                                        </div>
                                        {/*
                                         <div style={{ padding: '25px' }}>
                                            <div >
                                                <span style={{
                                                    color: '#0078BE',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold'
                                                }}
                                                    onClick={downloadScoreCardDataYOY_All}
                                                >
                                                    Download Scorecard as XLSX and Include All Years
										                            </span>
                                            </div>
                                        </div>
                                        */}
                                    {this.state.showLastViews ?                                        
                                        <div />
                                        :
                                        <div />
                                    }
                                </Constant.NPDPopover>
                                </div>
                            </div>

                            <div className="col-sm-12" style={{ maxWidth: 'calc(85vw - 150px)', margin:'0px' }}>
                                <div align='left' className="contentHolder col-sm-12" style={{ margin: '0px'}}>
                                    <h4>Selection Information</h4>
                                    <span>{this.state.title_geo_selected}</span>
                                    <br />
                                    <span>{this.state.title_num_weeks + ' Weeks ending ' + this.state.title_end_date.substring(5, 7) + '/' + this.state.title_end_date.substring(8, 10) + '/' + this.state.title_end_date.substring(0, 4)}</span>
                                    <br />
                                    <span>{this.state.title_brands_detail}</span>
                                    <br />                                
                                    <span> Previous Period Ranges From: {this.state.prevperiodstart_caption} to {this.state.prevperiodend_caption}</span>
                                    <br />
                                    <span> Current &nbsp; Period Ranges From: {this.state.currperiodstart_caption} to {this.state.currperiodend_caption}</span>
                                </div> 
                            </div>
                            <br/>

                            <Scorecard
                                unitsvar={this.state.unitsvar}
                                currencycode={this.state.currencycode}
                                currencytype={this.state.currencytype}
                                base_units_curr={this.state.basetouse_corrected_tracking}
                                base_units_prior={this.state.baseUnits_prior}
                                inc_units_curr={this.state.inctouse_corrected_tracking}
                                inc_units_prior={this.state.incUnits_prior}
                                base_unit_pct_curr={(this.state.basetouse_corrected_tracking / this.state.TotalUnits_new)}
                                base_unit_pct_prior={(this.state.baseUnits_prior / this.state.TotalUnits_prior)}
                                inc_unit_pct_curr={(this.state.inctouse_corrected_tracking / this.state.TotalUnits_new)}
                                inc_unit_pct_prior={(this.state.incUnits_prior / this.state.TotalUnits_prior)}
                                nonpromo_units_curr={(this.state.TotalUnits_new - this.state.TotalUnitsOnPromo)}
                                nonpromo_units_prior={(this.state.TotalUnits_prior - this.state.TotalUnitsOnPromo_prior)}
                                promo_units_curr={this.state.TotalUnitsOnPromo}
                                promo_units_prior={this.state.TotalUnitsOnPromo_prior}
                                nonpromo_unit_pct_curr={(1 - (this.state.TotalUnitsOnPromo / this.state.TotalUnits_new))}
                                nonpromo_unit_pct_prior={(1 - (this.state.TotalUnitsOnPromo_prior / this.state.TotalUnits_prior))}
                                promo_unit_pct_curr={(this.state.TotalUnitsOnPromo / this.state.TotalUnits_new)}
                                promo_unit_pct_prior={(this.state.TotalUnitsOnPromo_prior / this.state.TotalUnits_prior)}
                                avg_base_price_curr={(this.state.weighted_dollars_nonpromo / (this.state.TotalUnits_new - this.state.TotalUnitsOnPromo))}
                                avg_base_price_prior={(this.state.weighted_dollars_nonpromo_prior / (this.state.TotalUnits_prior - this.state.TotalUnitsOnPromo_prior))}
                                avg_promo_price_curr={(this.state.weighted_dollars_nonpromo / (this.state.TotalUnits_new - this.state.TotalUnitsOnPromo)) * (1 - (this.state.totaldisc / this.state.TotalUnits_new))}
                                avg_promo_price_prior={(this.state.weighted_dollars_promo_prior / (this.state.TotalUnits_prior - this.state.TotalUnitsOnPromo_prior)) * (1 - (this.state.TotalDisc_prior / this.state.TotalUnits_prior))}
                                avg_disc_curr={(this.state.totaldisc / this.state.TotalUnits_new)}
                                avg_disc_prior={(this.state.TotalDisc_prior / this.state.TotalUnits_prior)}
                                disc_dollars_curr={disc_dollars_curr}
                                disc_dollars_prior={disc_dollars_prior}
                                lift_curr={Math.min((this.state.inctouse_corrected_tracking / this.state.basetouse_corrected_tracking), 10)}
                                lift_prior={Math.min((this.state.incUnits_prior / this.state.baseUnits_prior), 10)}
                                eff_curr={Math.min((this.state.inctouse_corrected_tracking / this.state.TotalUnitsOnPromo), 1)}
                                eff_prior={Math.min((this.state.incUnits_prior / this.state.TotalUnitsOnPromo_prior), 1)}
                                payback_ratio_curr={payback_ratio_curr}
                                payback_ratio_prior={payback_ratio_prior}
                                lift_promo_curr={Math.min((this.state.inctouse_corrected_tracking / this.state.subsidized_vol),10)}
                                lift_promo_prior={Math.min((this.state.incUnits_prior /this.state.subbasevar_prior),10)}

                            />
                        </div>
                    </div>
                    <div className="row" style={{ height: '500px' }}></div>
                </div>

            </Layout>
        )
    }
}