import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants';

export class MetricsComparisonsGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        var currencycode = this.props.state.currencycode
        return (
            <Fragment>
                {this.props.state.dataforviews_romcompare.map((metric, idx) => {
                    if (idx === this.props.state.viewromnum && this.props.state.ThreeYAGO) {
                        return <div className="col-sm-12" key={'ThreeYAgo_' + metric} style={{ width: "100%", height: "100%" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                oneToOne={true}
                                options={
                                    {
                                        chart: {
                                            //type: 'column'
                                            plotBackgroundColor: null,
                                            plotBorderWidth: null,
                                            plotShadow: false,
                                            type: 'column',
                                            //16:9 ratio
                                            height: (9 / 16 * 100) + '%',
                                        },
                                        metadata: this.props.state.chartmetadata,
                                        caption: {
                                            text: this.props.state.attr_caption_text
                                        },
                                        title: {
                                            text: 'Comparison of '
                                                + metric.metric
                                                + ' Retailer vs ROM'
                                                + this.props.state.chart_title_info.split('<br>').slice(0, 2).join('<br>')
                                                + '<br>'
                                                + this.props.state.distinctdates_forcalc.length
                                                + ' '
                                                + this.props.state.timeperiodlabels
                                                + 's Ending: '
                                                + new Date(this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1] + 'T00:00:00').toLocaleDateString()
                                                + '<br>'
                                                + this.props.state.chart_title_info.split('<br>').slice(4).join('<br>')
                                        },
                                        series: [
                                            {
                                                'name': metric.metric,
                                                'data': metric.currvalues,
                                                'type': 'column',
                                                yAxis: 0,
                                                color: '#7d32ba'
                                            },
                                            {
                                                'name': metric.metric + ' % Change Three Years Ago',
                                                'data': metric.priorvalchg,
                                                'type': 'line',
                                                lineWidth: 0,
                                                marker: {
                                                    enabled: true,
                                                    radius: 8,
                                                    symbol: 'diamond'
                                                },
                                                states: {
                                                    hover: {
                                                        lineWidthPlus: 0
                                                    }
                                                },
                                                yAxis: 1,
                                                color: '#ff8b6a'
                                            },
                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Outlet'
                                            },
                                            categories: this.props.state.all_outlets,
                                        },
                                        yAxis: [
                                            {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: metric.metric
                                                },
                                                labels: {
                                                    formatter: function () {

                                                        function nFormatter(num, digits, axisMax) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;

                                                            let symbolForAxis = " "
                                                            let valueForAxis = 1

                                                            for (i = 0; i < si.length; i++) {
                                                                if (axisMax >= si[i].value) {
                                                                    symbolForAxis = si[i].symbol
                                                                    valueForAxis = si[i].value
                                                                    break;
                                                                }
                                                            }

                                                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                        }
                                                        if (metric.metrictype === 'percent') {
                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                        else if (metric.metrictype === 'currency') {
                                                            let value = nFormatter(this.value, 1, this.axis.max)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        }
                                                        else {
                                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                                        }
                                                    },
                                                }
                                            },
                                            {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: metric.metric + ' % Change Three Years Ago'
                                                },
                                                labels: {
                                                    formatter: function () {
                                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                    }
                                                },
                                                opposite: true
                                            }
                                        ],
                                        plotOptions: {
                                            column: {
                                                dataLabels: {
                                                    enabled: true,
                                                    //format: '{point.y:,.0f}',
                                                    formatter: function () {

                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (num >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (num < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        if (metric.metrictype === 'currency') {
                                                            //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            let value = nFormatter(this.point.y, 1)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        }
                                                        else if (metric.metrictype === 'units') {
                                                            return nFormatter(this.point.y, 1).toLocaleString()
                                                        }
                                                        else if (metric.metrictype === 'percent') {
                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '20px'
                                                    },
                                                    y: -5
                                                }

                                            },


                                        },
                                        tooltip: {
                                            //pointFormat: '{series.name}: <b>{point}%</b>'
                                            formatter: function () {
                                                if (metric.metrictype === 'units' && this.series.name.substring(this.series.name.length - 3) !== 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString()
                                                }
                                                else if (metric.metrictype === 'currency' && this.series.name.substring(this.series.name.length - 3) !== 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                }
                                                else if (metric.metrictype === 'percent' && this.series.name.substring(this.series.name.length - 3) !== 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                }
                                                else if (this.series.name.substring(this.series.name.length - 3) === 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                }
                                            }
                                        },
                                        exporting: {
                                            showTable: this.props.state.showDataTable,
                                            tableCaption: false
                                        },
                                    }}
                            />
                            <div style={{ display: this.props.state.showDataTableStyle }}>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g3">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Outlet</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.metric}</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.metric + ' % Change Three Years Ago'}</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                        {this.props.state.all_outlets.map((outlet, idx) => {
                                            return <Constant.NPDTableRow key={outlet + "_g4"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{outlet}</Constant.NPDTableCell>
                                                {metric.metrictype === 'units' ?
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString()}</Constant.NPDTableCell>
                                                    :
                                                    metric.metrictype === 'currency' ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                        :
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                }
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.priorvalchg[idx].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })
                                        }
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>

                        </div>
                    }
                    else if (idx === this.props.state.viewromnum && this.props.state.TwoYAGO) {
                        return <div className="col-sm-12" key={'TwoYAgo_' + metric} style={{ width: "100%", height: "100%" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                oneToOne={true}
                                options={
                                    {
                                        chart: {
                                            //type: 'column'
                                            plotBackgroundColor: null,
                                            plotBorderWidth: null,
                                            plotShadow: false,
                                            type: 'column',
                                            //16:9 ratio
                                            height: (9 / 16 * 100) + '%',
                                        },
                                        metadata: this.props.state.chartmetadata,
                                        caption: {
                                            text: this.props.state.attr_caption_text
                                        },
                                        title: {
                                            text: 'Comparison of '
                                                + metric.metric
                                                + ' Retailer vs ROM'
                                                + this.props.state.chart_title_info.split('<br>').slice(0, 2).join('<br>')
                                                + '<br>'
                                                + this.props.state.distinctdates_forcalc.length
                                                + ' '
                                                + this.props.state.timeperiodlabels
                                                + 's Ending: '
                                                + new Date(this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1] + 'T00:00:00').toLocaleDateString()
                                                + '<br>'
                                                + this.props.state.chart_title_info.split('<br>').slice(4).join('<br>')
                                        },
                                        series: [
                                            {
                                                'name': metric.metric,
                                                'data': metric.currvalues,
                                                'type': 'column',
                                                yAxis: 0,
                                                color: '#7d32ba'
                                            },
                                            {
                                                'name': metric.metric + ' % Change Two Years Ago',
                                                'data': metric.priorvalchg,
                                                'type': 'line',
                                                lineWidth: 0,
                                                marker: {
                                                    enabled: true,
                                                    radius: 8,
                                                    symbol: 'diamond'
                                                },
                                                states: {
                                                    hover: {
                                                        lineWidthPlus: 0
                                                    }
                                                },
                                                yAxis: 1,
                                                color: '#ff8b6a'
                                            },
                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Outlet'
                                            },
                                            categories: this.props.state.all_outlets,
                                        },
                                        yAxis: [
                                            {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: metric.metric
                                                },
                                                labels: {
                                                    formatter: function () {

                                                        function nFormatter(num, digits, axisMax) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;

                                                            let symbolForAxis = " "
                                                            let valueForAxis = 1

                                                            for (i = 0; i < si.length; i++) {
                                                                if (axisMax >= si[i].value) {
                                                                    symbolForAxis = si[i].symbol
                                                                    valueForAxis = si[i].value
                                                                    break;
                                                                }
                                                            }

                                                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                        }
                                                        if (metric.metrictype === 'percent') {
                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                        else if (metric.metrictype === 'currency') {
                                                            let value = nFormatter(this.value, 1, this.axis.max)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        }
                                                        else {
                                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                                        }
                                                    },
                                                }
                                            },
                                            {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: metric.metric + ' % Change Two Years Ago'
                                                },
                                                labels: {
                                                    formatter: function () {
                                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                    }
                                                },
                                                opposite: true
                                            }
                                        ],
                                        plotOptions: {
                                            column: {
                                                dataLabels: {
                                                    enabled: true,
                                                    //format: '{point.y:,.0f}',
                                                    formatter: function () {

                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (num >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (num < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        if (metric.metrictype === 'currency') {
                                                            //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            let value = nFormatter(this.point.y, 1)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        }
                                                        else if (metric.metrictype === 'units') {
                                                            return nFormatter(this.point.y, 1).toLocaleString()
                                                        }
                                                        else if (metric.metrictype === 'percent') {
                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '20px'
                                                    },
                                                    y: -5
                                                }

                                            },


                                        },
                                        tooltip: {
                                            //pointFormat: '{series.name}: <b>{point}%</b>'
                                            formatter: function () {
                                                if (metric.metrictype === 'units' && this.series.name.substring(this.series.name.length - 3) !== 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString()
                                                }
                                                else if (metric.metrictype === 'currency' && this.series.name.substring(this.series.name.length - 3) !== 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                }
                                                else if (metric.metrictype === 'percent' && this.series.name.substring(this.series.name.length - 3) !== 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                }
                                                else if (this.series.name.substring(this.series.name.length - 3) === 'Ago') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                }
                                            }
                                        },
                                        exporting: {
                                            showTable: this.props.state.showDataTable,
                                            tableCaption: false
                                        },
                                    }}
                            />
                            <div style={{ display: this.props.state.showDataTableStyle }}>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g3">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Outlet</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.metric}</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.metric + ' % Change Two Years Ago'}</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                        {this.props.state.all_outlets.map((outlet, idx) => {
                                            return <Constant.NPDTableRow key={outlet + "_g4"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{outlet}</Constant.NPDTableCell>
                                                {metric.metrictype === 'units' ?
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString()}</Constant.NPDTableCell>
                                                    :
                                                    metric.metrictype === 'currency' ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                        :
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                }
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.priorvalchg[idx].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })
                                        }
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>

                        </div>
                    }
                    else if (idx === this.props.state.viewromnum && this.props.state.OneYAGO) {
                        return <div className="col-sm-12" key={'TwoYAGO_' + idx} style={{ width: "100%", height: "100%" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                oneToOne={true}
                                options={
                                    {
                                        chart: {
                                            //type: 'column'
                                            plotBackgroundColor: null,
                                            plotBorderWidth: null,
                                            plotShadow: false,
                                            type: 'column',
                                            //16:9 ratio
                                            height: (9 / 16 * 100) + '%',
                                        },
                                        metadata: this.props.state.chartmetadata,
                                        caption: {
                                            text: this.props.state.attr_caption_text
                                        },
                                        title: {
                                            text: 'Comparison of '
                                                + metric.metric
                                                + ' Retailer vs ROM'
                                                + this.props.state.chart_title_info.split('<br>').slice(0, 2).join('<br>')
                                                + '<br>'
                                                + this.props.state.distinctdates_forcalc.length
                                                + ' '
                                                + this.props.state.timeperiodlabels
                                                + 's Ending: '
                                                + new Date(this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1] + 'T00:00:00').toLocaleDateString()
                                                + '<br>'
                                                + this.props.state.chart_title_info.split('<br>').slice(4).join('<br>')
                                        },
                                        series: [
                                            {
                                                'name': metric.metric,
                                                'data': metric.currvalues,
                                                'type': 'column',
                                                yAxis: 0,
                                                color: '#7d32ba'
                                            },
                                            {
                                                'name': metric.metric + ' % Change YOY',
                                                'data': metric.priorvalchg,
                                                'type': 'line',
                                                lineWidth: 0,
                                                marker: {
                                                    enabled: true,
                                                    radius: 8,
                                                    symbol: 'diamond'
                                                },
                                                states: {
                                                    hover: {
                                                        lineWidthPlus: 0
                                                    }
                                                },
                                                yAxis: 1,
                                                color: '#ff8b6a'
                                            },
                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Outlet'
                                            },
                                            categories: this.props.state.all_outlets,
                                        },
                                        yAxis: [
                                            {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: metric.metric
                                                },
                                                labels: {
                                                    formatter: function () {

                                                        function nFormatter(num, digits, axisMax) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;

                                                            let symbolForAxis = " "
                                                            let valueForAxis = 1

                                                            for (i = 0; i < si.length; i++) {
                                                                if (axisMax >= si[i].value) {
                                                                    symbolForAxis = si[i].symbol
                                                                    valueForAxis = si[i].value
                                                                    break;
                                                                }
                                                            }

                                                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                        }
                                                        if (metric.metrictype === 'percent') {
                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                        else if (metric.metrictype === 'currency') {
                                                            let value = nFormatter(this.value, 1, this.axis.max)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        }
                                                        else {
                                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                                        }
                                                    },
                                                }
                                            },
                                            {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: metric.metric + ' % Change YOY'
                                                },
                                                labels: {
                                                    formatter: function () {
                                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                    }
                                                },
                                                opposite: true
                                            }
                                        ],
                                        plotOptions: {
                                            column: {
                                                dataLabels: {
                                                    enabled: true,
                                                    //format: '{point.y:,.0f}',
                                                    formatter: function () {

                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (num >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (num < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        if (metric.metrictype === 'currency') {
                                                            //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            let value = nFormatter(this.point.y, 1)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        }
                                                        else if (metric.metrictype === 'units') {
                                                            return nFormatter(this.point.y, 1).toLocaleString()
                                                        }
                                                        else if (metric.metrictype === 'percent') {
                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '20px'
                                                    },
                                                    y: -5
                                                }

                                            },


                                        },
                                        tooltip: {
                                            //pointFormat: '{series.name}: <b>{point}%</b>'
                                            formatter: function () {
                                                if (metric.metrictype === 'units' && this.series.name.substring(this.series.name.length - 3) !== 'YOY') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString()
                                                }
                                                else if (metric.metrictype === 'currency' && this.series.name.substring(this.series.name.length - 3) !== 'YOY') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                }
                                                else if (metric.metrictype === 'percent' && this.series.name.substring(this.series.name.length - 3) !== 'YOY') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                }
                                                else if (this.series.name.substring(this.series.name.length - 3) === 'YOY') {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                }
                                            }
                                        },
                                        exporting: {
                                            showTable: this.props.state.showDataTable,
                                            tableCaption: false
                                        },
                                    }}
                            />
                            <div style={{ display: this.props.state.showDataTableStyle }}>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g3">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Outlet</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.metric}</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.metric + ' % Change YOY'}</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                        {this.props.state.all_outlets.map((outlet, idx) => {
                                            return <Constant.NPDTableRow key={outlet + "_g4"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{outlet}</Constant.NPDTableCell>
                                                {metric.metrictype === 'units' ?
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString()}</Constant.NPDTableCell>
                                                    :
                                                    metric.metrictype === 'currency' ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                        :
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.currvalues[idx].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                }
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{metric.priorvalchg[idx].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })
                                        }
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>

                        </div>
                    }
                    else {
                        return null
                    }

                })}
            </Fragment>
        )
    }
}