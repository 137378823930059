import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants';
import { reducer } from '../PDT/Functions/Utilities/Reducer';


export class DiscountRangeYOYGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {

        var selectedBrand = this.props.state.selectedBrand
        if (this.props.app === 'PDT') {
            selectedBrand = 'Total Selection'
        }

        let current_year_ending = this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1]
        let prior_year_ending = this.props.state.distinctdates_prior[this.props.state.distinctdates_prior.length - 1]
        let twoyear_year_ending = this.props.state.distinctdates_last[this.props.state.distinctdates_last.length - 1]
        let threeyear_year_ending = this.props.state.distinctdates_threeyears[this.props.state.distinctdates_threeyears.length - 1]

        function formatDate(datestring) {
            let date = Date.UTC(parseInt(datestring.substring(0, 4)), parseInt(datestring.substring(5, 7)) - 1, parseInt(datestring.substring(8, 10)), 0, 0, 0, 0)
            let dt = new Date(date)
            let offset = dt.getTimezoneOffset() * 60 * 1000
            var dateformatted = (new Date(date + offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
            return dateformatted
        }

        let current_year_ending_string = formatDate(current_year_ending)
        let prior_year_ending_string = formatDate(prior_year_ending)
        let twoyear_year_ending_string = formatDate(twoyear_year_ending)
        let threeyear_year_ending_string = formatDate(threeyear_year_ending)


        let numweeks_curr = this.props.state.distinctdates_forcalc.length
        let numweeks_prior = this.props.state.distinctdates_prior.length
        let numweeks_twoyear = this.props.state.distinctdates_last.length
        let numweeks_threeyear = this.props.state.distinctdates_threeyears.length

        var comparison_to_prev_year_title = 'Difference in ' + this.props.state.unitsvar + ' for Discount Range YOY'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string
            + ' Compared to '
            + numweeks_prior
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + prior_year_ending_string
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        var comparison_to_two_year_title = 'Difference in ' + this.props.state.unitsvar + ' for Discount Range 2 Years Ago'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string
            + ' Compared to '
            + numweeks_twoyear
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + twoyear_year_ending_string
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        var comparison_to_three_year_title = 'Difference in ' + this.props.state.unitsvar + ' for Discount Range 3 Years Ago'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string
            + ' Compared to '
            + numweeks_threeyear
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + threeyear_year_ending_string
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        var threeyearago_title = this.props.state.unitsvar
            + ' for Discount Range - Three Years Ago'
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_threeyear
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + threeyear_year_ending_string
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        var twoyearago_title = this.props.state.unitsvar
            + ' for Discount Range - Two Years Ago'
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_twoyear
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + twoyear_year_ending_string
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        var yearago_title = this.props.state.unitsvar
            + ' for Discount Range - Prior Year'
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_prior
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + prior_year_ending_string
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        var currentyear_title = this.props.state.unitsvar
            + ' for Discount Range - Current Year'
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        return (
            <Fragment>
                {!this.props.state.indexSwitchYOY ?
                    this.props.state.channelviews ?
                        <div style={{ height: '100%', width: "100%", }}>
                            {this.props.state.ThreeYAGO ?
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={
                                        {
                                            chart: {
                                                zoomType: 'xy',
                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                            },
                                            metadata: this.props.state.chartmetadata,
                                            caption: {
                                                text: this.props.state.attr_caption_text
                                            },
                                            title: {
                                                text: comparison_to_three_year_title
                                            },
                                            legend: {
                                                enabled: false
                                            },
                                            series: [
                                                {
                                                    name: 'Difference of Current Year from Three Years Ago',
                                                    type: 'column',
                                                    tooltip: {
                                                        pointFormatter: function () {
                                                            return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                        }
                                                    },
                                                    data: this.props.state.discountbuckets.map(bucket => {

                                                        let priorval = ((this.props.state.discountband_data_threeyears_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0
                                                        let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                        var colorchoice = ""
                                                        if (currentval - priorval > 0) {
                                                            colorchoice = '#7d32ba'
                                                        }
                                                        else {
                                                            colorchoice = '#f3508e'
                                                        }
                                                        return {
                                                            'y': currentval - priorval,
                                                            'color': colorchoice
                                                        }

                                                    }).concat([
                                                        {
                                                            'y': ['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                                let priorval = (((this.props.state.discountband_data_threeyears_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                                let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                                return currentval - priorval
                                                            }).reduce(reducer),
                                                            'color': '#f3508e'
                                                        }
                                                    ])

                                                },

                                            ],
                                            xAxis: {
                                                title: {
                                                    text: 'Discount Range'
                                                },
                                                categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9)).concat(['Total Difference'])
                                            },
                                            yAxis: [
                                                {
                                                    title: {
                                                        text: this.props.state.unitsvar
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    labels: {

                                                        formatter: function () {

                                                            function nFormatter(num, digits, axisMax) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;

                                                                let symbolForAxis = " "
                                                                let valueForAxis = 1

                                                                for (i = 0; i < si.length; i++) {
                                                                    if (axisMax >= si[i].value) {
                                                                        symbolForAxis = si[i].symbol
                                                                        valueForAxis = si[i].value
                                                                        break;
                                                                    }
                                                                }

                                                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                            }
                                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                        }
                                                    },
                                                },
                                            ],
                                            plotOptions: {
                                                column: {
                                                    stacking: 'normal',
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter:
                                                            function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                        else if (Math.abs(num) < 1E3) {
                                                                            return Math.round(num).toLocaleString()
                                                                        }

                                                                    }
                                                                    return num.toString();
                                                                }

                                                                return nFormatter(this.point.y, 0).toLocaleString()

                                                            },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 2,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '20px'
                                                        },
                                                    },

                                                },

                                                spline: {
                                                    marker: {
                                                        enabled: false
                                                    }
                                                }

                                            },
                                            exporting: {
                                                showTable: this.props.state.showDataTable,
                                                tableCaption: false
                                            },

                                        }}
                                />
                                :
                                this.props.state.TwoYAGO ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: comparison_to_two_year_title
                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Difference of Current Year from Two Years Ago',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            let priorval = ((this.props.state.discountband_data_last_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0
                                                            let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                            var colorchoice = ""
                                                            if (currentval - priorval > 0) {
                                                                colorchoice = '#7d32ba'
                                                            }
                                                            else {
                                                                colorchoice = '#f3508e'
                                                            }
                                                            return {
                                                                'y': currentval - priorval,
                                                                'color': colorchoice
                                                            }

                                                        }).concat([
                                                            {
                                                                'y': ['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                                    let priorval = (((this.props.state.discountband_data_last_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                                    let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                                    return currentval - priorval
                                                                }).reduce(reducer),
                                                                'color': '#f3508e'
                                                            }
                                                        ])

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9)).concat(['Total Difference'])
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter:
                                                                function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (Math.abs(num) >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                            else if (Math.abs(num) < 1E3) {
                                                                                return Math.round(num).toLocaleString()
                                                                            }

                                                                        }
                                                                        return num.toString();
                                                                    }

                                                                    return nFormatter(this.point.y, 0).toLocaleString()

                                                                },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },
                                                        },

                                                    },

                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }

                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                                    :
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: comparison_to_prev_year_title
                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Difference of Current Year from Prior Year',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            let priorval = ((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0
                                                            let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                            var colorchoice = ""
                                                            if (currentval - priorval > 0) {
                                                                colorchoice = '#7d32ba'
                                                            }
                                                            else {
                                                                colorchoice = '#f3508e'
                                                            }
                                                            return {
                                                                'y': currentval - priorval,
                                                                'color': colorchoice
                                                            }

                                                        }).concat([
                                                            {
                                                                'y': ['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                                    let priorval = (((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                                    let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                                    return currentval - priorval
                                                                }).reduce(reducer),
                                                                'color': '#f3508e'
                                                            }
                                                        ])

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9)).concat(['Total Difference'])
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter:
                                                                function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (Math.abs(num) >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                            else if (Math.abs(num) < 1E3) {
                                                                                return Math.round(num).toLocaleString()
                                                                            }

                                                                        }
                                                                        return num.toString();
                                                                    }

                                                                    return nFormatter(this.point.y, 0).toLocaleString()

                                                                },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },
                                                        },

                                                    },

                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }

                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                            }
                        </div>
                        :
                        <div style={{ height: '100%', width: "100%", }}>
                            {this.props.state.ThreeYAGO ?
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={
                                        {
                                            chart: {
                                                zoomType: 'xy',
                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                            },
                                            metadata: this.props.state.chartmetadata,
                                            caption: {
                                                text: this.props.state.attr_caption_text
                                            },
                                            title: {
                                                text: comparison_to_three_year_title

                                            },
                                            legend: {
                                                enabled: false
                                            },
                                            series: [
                                                {
                                                    name: 'Difference of Current Year from Three Years Ago',
                                                    type: 'column',
                                                    tooltip: {
                                                        pointFormatter: function () {
                                                            return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                        }
                                                    },
                                                    data: this.props.state.discountbuckets.map(bucket => {

                                                        let priorval = ((this.props.state.discountband_data_threeyears[selectedBrand] || {})[bucket] || {})['totalunits'] || 0
                                                        let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                        var colorchoice = ""
                                                        if (currentval - priorval > 0) {
                                                            colorchoice = '#7d32ba'
                                                        }
                                                        else {
                                                            colorchoice = '#f3508e'
                                                        }
                                                        return {
                                                            'y': currentval - priorval,
                                                            'color': colorchoice
                                                        }

                                                    }).concat([
                                                        {
                                                            'y': ['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                                let priorval = (((this.props.state.discountband_data_threeyears[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                                let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                                return currentval - priorval
                                                            }).reduce(reducer),
                                                            'color': '#f3508e'
                                                        }
                                                    ])

                                                },

                                            ],
                                            xAxis: {
                                                title: {
                                                    text: 'Discount Range'
                                                },
                                                categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9)).concat(['Total Difference'])
                                            },
                                            yAxis: [
                                                {
                                                    title: {
                                                        text: this.props.state.unitsvar
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    labels: {

                                                        formatter: function () {

                                                            function nFormatter(num, digits, axisMax) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;

                                                                let symbolForAxis = " "
                                                                let valueForAxis = 1

                                                                for (i = 0; i < si.length; i++) {
                                                                    if (axisMax >= si[i].value) {
                                                                        symbolForAxis = si[i].symbol
                                                                        valueForAxis = si[i].value
                                                                        break;
                                                                    }
                                                                }

                                                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                            }
                                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                        }
                                                    },
                                                },
                                            ],
                                            plotOptions: {
                                                column: {
                                                    stacking: 'normal',
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter:
                                                            function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                        else if (Math.abs(num) < 1E3) {
                                                                            return Math.round(num).toLocaleString()
                                                                        }

                                                                    }
                                                                    return num.toString();
                                                                }

                                                                return nFormatter(this.point.y, 0).toLocaleString()

                                                            },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 2,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '20px'
                                                        },
                                                    },

                                                },

                                                spline: {
                                                    marker: {
                                                        enabled: false
                                                    }
                                                }

                                            },
                                            exporting: {
                                                showTable: this.props.state.showDataTable,
                                                tableCaption: false
                                            },

                                        }}
                                />
                                :
                                this.props.state.TwoYAGO ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: comparison_to_two_year_title
                                                    
                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Difference of Current Year from Prior Year',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            let priorval = ((this.props.state.discountband_data_last[selectedBrand] || {})[bucket] || {})['totalunits'] || 0
                                                            let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                            var colorchoice = ""
                                                            if (currentval - priorval > 0) {
                                                                colorchoice = '#7d32ba'
                                                            }
                                                            else {
                                                                colorchoice = '#f3508e'
                                                            }
                                                            return {
                                                                'y': currentval - priorval,
                                                                'color': colorchoice
                                                            }

                                                        }).concat([
                                                            {
                                                                'y': ['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                                    let priorval = (((this.props.state.discountband_data_last[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                                    let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                                    return currentval - priorval
                                                                }).reduce(reducer),
                                                                'color': '#f3508e'
                                                            }
                                                        ])

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9)).concat(['Total Difference'])
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter:
                                                                function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (Math.abs(num) >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                            else if (Math.abs(num) < 1E3) {
                                                                                return Math.round(num).toLocaleString()
                                                                            }

                                                                        }
                                                                        return num.toString();
                                                                    }

                                                                    return nFormatter(this.point.y, 0).toLocaleString()

                                                                },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },
                                                        },

                                                    },

                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }

                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                                    :
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: comparison_to_prev_year_title

                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Difference of Current Year from Prior Year',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            let priorval = ((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket] || {})['totalunits'] || 0
                                                            let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                            var colorchoice = ""
                                                            if (currentval - priorval > 0) {
                                                                colorchoice = '#7d32ba'
                                                            }
                                                            else {
                                                                colorchoice = '#f3508e'
                                                            }
                                                            return {
                                                                'y': currentval - priorval,
                                                                'color': colorchoice
                                                            }

                                                        }).concat([
                                                            {
                                                                'y': ['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                                    let priorval = (((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                                    let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                                    return currentval - priorval
                                                                }).reduce(reducer),
                                                                'color': '#f3508e'
                                                            }
                                                        ])

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9)).concat(['Total Difference'])
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter:
                                                                function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (Math.abs(num) >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                            else if (Math.abs(num) < 1E3) {
                                                                                return Math.round(num).toLocaleString()
                                                                            }

                                                                        }
                                                                        return num.toString();
                                                                    }

                                                                    return nFormatter(this.point.y, 0).toLocaleString()

                                                                },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },
                                                        },

                                                    },

                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }

                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                            }
                        </div>
                    :
                    this.props.state.channelviews ?
                        <div style={{ height: '100%', width: "100%", }} className="row">
                            <div className='col-sm-6'>
                                {this.props.state.ThreeYAGO ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 200) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: threeyearago_title

                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Threee Years Ago',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            let priorval = ((this.props.state.discountband_data_threeyears_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0

                                                            return {
                                                                'y': priorval,
                                                                'color': '#7d32ba'
                                                            }

                                                        })

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter:
                                                                function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (num >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                        }
                                                                        return num.toString();
                                                                    }

                                                                    return nFormatter(this.point.y, 0).toLocaleString()

                                                                },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },
                                                        },
                                                    },

                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }

                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                                    :
                                    this.props.state.TwoYAGO ?
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                {
                                                    chart: {
                                                        zoomType: 'xy',
                                                        height: (9 / 16 * 200) + '%' //16:9 ratio
                                                    },
                                                    metadata: this.props.state.chartmetadata,
                                                    caption: {
                                                        text: this.props.state.attr_caption_text
                                                    },
                                                    title: {
                                                        text: twoyearago_title
                                                        
                                                    },
                                                    legend: {
                                                        enabled: false
                                                    },
                                                    series: [
                                                        {
                                                            name: 'Two Years Ago',
                                                            type: 'column',
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                                }
                                                            },
                                                            data: this.props.state.discountbuckets.map(bucket => {

                                                                let priorval = ((this.props.state.discountband_data_last_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0

                                                                return {
                                                                    'y': priorval,
                                                                    'color': '#7d32ba'
                                                                }

                                                            })

                                                        },

                                                    ],
                                                    xAxis: {
                                                        title: {
                                                            text: 'Discount Range'
                                                        },
                                                        categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                    },
                                                    yAxis: [
                                                        {
                                                            title: {
                                                                text: this.props.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            labels: {

                                                                formatter: function () {

                                                                    function nFormatter(num, digits, axisMax) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;

                                                                        let symbolForAxis = " "
                                                                        let valueForAxis = 1

                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (axisMax >= si[i].value) {
                                                                                symbolForAxis = si[i].symbol
                                                                                valueForAxis = si[i].value
                                                                                break;
                                                                            }
                                                                        }

                                                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                    }
                                                                    return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                                }
                                                            },
                                                        },
                                                    ],
                                                    plotOptions: {
                                                        column: {
                                                            stacking: 'normal',
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter:
                                                                    function () {
                                                                        function nFormatter(num, digits) {
                                                                            var si = [
                                                                                { value: 1E18, symbol: "E" },
                                                                                { value: 1E15, symbol: "P" },
                                                                                { value: 1E12, symbol: "T" },
                                                                                { value: 1E9, symbol: "B" },
                                                                                { value: 1E6, symbol: "M" },
                                                                                { value: 1E3, symbol: "k" }
                                                                            ], i;
                                                                            for (i = 0; i < si.length; i++) {
                                                                                if (num >= si[i].value) {
                                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                }
                                                                            }
                                                                            return num.toString();
                                                                        }

                                                                        return nFormatter(this.point.y, 0).toLocaleString()

                                                                    },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 2,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '20px'
                                                                },
                                                            },
                                                        },

                                                        spline: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }

                                                    },
                                                    exporting: {
                                                        showTable: this.props.state.showDataTable,
                                                        tableCaption: false
                                                    },

                                                }}
                                        />
                                        :
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                {
                                                    chart: {
                                                        zoomType: 'xy',
                                                        height: (9 / 16 * 200) + '%' //16:9 ratio
                                                    },
                                                    metadata: this.props.state.chartmetadata,
                                                    caption: {
                                                        text: this.props.state.attr_caption_text
                                                    },
                                                    title: {
                                                        text: yearago_title
                                                        
                                                    },
                                                    legend: {
                                                        enabled: false
                                                    },
                                                    series: [
                                                        {
                                                            name: 'Prior Year',
                                                            type: 'column',
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                                }
                                                            },
                                                            data: this.props.state.discountbuckets.map(bucket => {

                                                                let priorval = ((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0

                                                                return {
                                                                    'y': priorval,
                                                                    'color': '#7d32ba'
                                                                }

                                                            })

                                                        },

                                                    ],
                                                    xAxis: {
                                                        title: {
                                                            text: 'Discount Range'
                                                        },
                                                        categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                    },
                                                    yAxis: [
                                                        {
                                                            title: {
                                                                text: this.props.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            labels: {

                                                                formatter: function () {

                                                                    function nFormatter(num, digits, axisMax) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;

                                                                        let symbolForAxis = " "
                                                                        let valueForAxis = 1

                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (axisMax >= si[i].value) {
                                                                                symbolForAxis = si[i].symbol
                                                                                valueForAxis = si[i].value
                                                                                break;
                                                                            }
                                                                        }

                                                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                    }
                                                                    return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                                }
                                                            },
                                                        },
                                                    ],
                                                    plotOptions: {
                                                        column: {
                                                            stacking: 'normal',
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter:
                                                                    function () {
                                                                        function nFormatter(num, digits) {
                                                                            var si = [
                                                                                { value: 1E18, symbol: "E" },
                                                                                { value: 1E15, symbol: "P" },
                                                                                { value: 1E12, symbol: "T" },
                                                                                { value: 1E9, symbol: "B" },
                                                                                { value: 1E6, symbol: "M" },
                                                                                { value: 1E3, symbol: "k" }
                                                                            ], i;
                                                                            for (i = 0; i < si.length; i++) {
                                                                                if (num >= si[i].value) {
                                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                }
                                                                            }
                                                                            return num.toString();
                                                                        }

                                                                        return nFormatter(this.point.y, 0).toLocaleString()

                                                                    },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 2,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '20px'
                                                                },
                                                            },
                                                        },

                                                        spline: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }

                                                    },
                                                    exporting: {
                                                        showTable: this.props.state.showDataTable,
                                                        tableCaption: false
                                                    },

                                                }}
                                        />
                                }
                            </div>

                            <div className='col-sm-6'>
                                {this.props.state.ThreeYAGO ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 200) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: yearago_title

                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Current Year',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            //let priorval = ((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0
                                                            let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0

                                                            return {
                                                                'y': currentval,
                                                                'color': '#f3508e'
                                                            }

                                                        })

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (num >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }
                                                                return nFormatter(this.point.y, 0).toLocaleString()
                                                            },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },

                                                        },
                                                    },
                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }
                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                                    :
                                    this.props.state.TwoYAGO ?
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                {
                                                    chart: {
                                                        zoomType: 'xy',
                                                        height: (9 / 16 * 200) + '%' //16:9 ratio
                                                    },
                                                    metadata: this.props.state.chartmetadata,
                                                    caption: {
                                                        text: this.props.state.attr_caption_text
                                                    },
                                                    title: {
                                                        text: yearago_title
                                                    
                                                    },
                                                    legend: {
                                                        enabled: false
                                                    },
                                                    series: [
                                                        {
                                                            name: 'Current Year',
                                                            type: 'column',
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                                }
                                                            },
                                                            data: this.props.state.discountbuckets.map(bucket => {

                                                                //let priorval = ((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0
                                                                let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0

                                                                return {
                                                                    'y': currentval,
                                                                    'color': '#f3508e'
                                                                }

                                                            })

                                                        },

                                                    ],
                                                    xAxis: {
                                                        title: {
                                                            text: 'Discount Range'
                                                        },
                                                        categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                    },
                                                    yAxis: [
                                                        {
                                                            title: {
                                                                text: this.props.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            labels: {

                                                                formatter: function () {

                                                                    function nFormatter(num, digits, axisMax) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;

                                                                        let symbolForAxis = " "
                                                                        let valueForAxis = 1

                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (axisMax >= si[i].value) {
                                                                                symbolForAxis = si[i].symbol
                                                                                valueForAxis = si[i].value
                                                                                break;
                                                                            }
                                                                        }

                                                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                    }
                                                                    return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                                }
                                                            },
                                                        },
                                                    ],
                                                    plotOptions: {
                                                        column: {
                                                            stacking: 'normal',
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter: function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (num >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                        }
                                                                        return num.toString();
                                                                    }
                                                                    return nFormatter(this.point.y, 0).toLocaleString()
                                                                },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 2,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '20px'
                                                                },

                                                            },
                                                        },
                                                        spline: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    exporting: {
                                                        showTable: this.props.state.showDataTable,
                                                        tableCaption: false
                                                    },

                                                }}
                                        />
                                        :
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                {
                                                    chart: {
                                                        zoomType: 'xy',
                                                        height: (9 / 16 * 200) + '%' //16:9 ratio
                                                    },
                                                    metadata: this.props.state.chartmetadata,
                                                    caption: {
                                                        text: this.props.state.attr_caption_text
                                                    },
                                                    title: {
                                                        text: currentyear_title
                                                    },
                                                    legend: {
                                                        enabled: false
                                                    },
                                                    series: [
                                                        {
                                                            name: 'Current Year',
                                                            type: 'column',
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                                }
                                                            },
                                                            data: this.props.state.discountbuckets.map(bucket => {

                                                                //let priorval = ((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket] || {})['totalunits'] || 0
                                                                let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0

                                                                return {
                                                                    'y': currentval,
                                                                    'color': '#f3508e'
                                                                }

                                                            })

                                                        },

                                                    ],
                                                    xAxis: {
                                                        title: {
                                                            text: 'Discount Range'
                                                        },
                                                        categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                    },
                                                    yAxis: [
                                                        {
                                                            title: {
                                                                text: this.props.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            labels: {

                                                                formatter: function () {

                                                                    function nFormatter(num, digits, axisMax) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;

                                                                        let symbolForAxis = " "
                                                                        let valueForAxis = 1

                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (axisMax >= si[i].value) {
                                                                                symbolForAxis = si[i].symbol
                                                                                valueForAxis = si[i].value
                                                                                break;
                                                                            }
                                                                        }

                                                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                    }
                                                                    return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                                }
                                                            },
                                                        },
                                                    ],
                                                    plotOptions: {
                                                        column: {
                                                            stacking: 'normal',
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter: function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (num >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                        }
                                                                        return num.toString();
                                                                    }
                                                                    return nFormatter(this.point.y, 0).toLocaleString()
                                                                },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 2,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '20px'
                                                                },

                                                            },
                                                        },
                                                        spline: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    exporting: {
                                                        showTable: this.props.state.showDataTable,
                                                        tableCaption: false
                                                    },

                                                }}
                                        />
                                }
                            </div>
                        </div>
                        :
                        <div style={{ height: '100%', width: "100%", }} className="row">
                            <div className='col-sm-6'>
                                {this.props.state.ThreeYAGO ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 200) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: threeyearago_title


                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Three Years Ago',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            let priorval = ((this.props.state.discountband_data_threeyears[selectedBrand] || {})[bucket] || {})['totalunits'] || 0

                                                            return {
                                                                'y': priorval,
                                                                'color': '#7d32ba'
                                                            }

                                                        })

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter:
                                                                function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (num >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                        }
                                                                        return num.toString();
                                                                    }

                                                                    return nFormatter(this.point.y, 0).toLocaleString()

                                                                },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },
                                                        },
                                                    },

                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }

                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                                    :
                                    this.props.state.TwoYAGO ?
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                {
                                                    chart: {
                                                        zoomType: 'xy',
                                                        height: (9 / 16 * 200) + '%' //16:9 ratio
                                                    },
                                                    metadata: this.props.state.chartmetadata,
                                                    caption: {
                                                        text: this.props.state.attr_caption_text
                                                    },
                                                    title: {
                                                        text: twoyearago_title

                                                    
                                                    },
                                                    legend: {
                                                        enabled: false
                                                    },
                                                    series: [
                                                        {
                                                            name: 'Prior Year',
                                                            type: 'column',
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                                }
                                                            },
                                                            data: this.props.state.discountbuckets.map(bucket => {

                                                                let priorval = ((this.props.state.discountband_data_last[selectedBrand] || {})[bucket] || {})['totalunits'] || 0

                                                                return {
                                                                    'y': priorval,
                                                                    'color': '#7d32ba'
                                                                }

                                                            })

                                                        },

                                                    ],
                                                    xAxis: {
                                                        title: {
                                                            text: 'Discount Range'
                                                        },
                                                        categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                    },
                                                    yAxis: [
                                                        {
                                                            title: {
                                                                text: this.props.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            labels: {

                                                                formatter: function () {

                                                                    function nFormatter(num, digits, axisMax) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;

                                                                        let symbolForAxis = " "
                                                                        let valueForAxis = 1

                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (axisMax >= si[i].value) {
                                                                                symbolForAxis = si[i].symbol
                                                                                valueForAxis = si[i].value
                                                                                break;
                                                                            }
                                                                        }

                                                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                    }
                                                                    return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                                }
                                                            },
                                                        },
                                                    ],
                                                    plotOptions: {
                                                        column: {
                                                            stacking: 'normal',
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter:
                                                                    function () {
                                                                        function nFormatter(num, digits) {
                                                                            var si = [
                                                                                { value: 1E18, symbol: "E" },
                                                                                { value: 1E15, symbol: "P" },
                                                                                { value: 1E12, symbol: "T" },
                                                                                { value: 1E9, symbol: "B" },
                                                                                { value: 1E6, symbol: "M" },
                                                                                { value: 1E3, symbol: "k" }
                                                                            ], i;
                                                                            for (i = 0; i < si.length; i++) {
                                                                                if (num >= si[i].value) {
                                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                }
                                                                            }
                                                                            return num.toString();
                                                                        }

                                                                        return nFormatter(this.point.y, 0).toLocaleString()

                                                                    },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 2,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '20px'
                                                                },
                                                            },
                                                        },

                                                        spline: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }

                                                    },
                                                    exporting: {
                                                        showTable: this.props.state.showDataTable,
                                                        tableCaption: false
                                                    },

                                                }}
                                        />
                                        :
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                {
                                                    chart: {
                                                        zoomType: 'xy',
                                                        height: (9 / 16 * 200) + '%' //16:9 ratio
                                                    },
                                                    metadata: this.props.state.chartmetadata,
                                                    caption: {
                                                        text: this.props.state.attr_caption_text
                                                    },
                                                    title: {
                                                        text: yearago_title
                                                    },
                                                    legend: {
                                                        enabled: false
                                                    },
                                                    series: [
                                                        {
                                                            name: 'Prior Year',
                                                            type: 'column',
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                                }
                                                            },
                                                            data: this.props.state.discountbuckets.map(bucket => {

                                                                let priorval = ((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket] || {})['totalunits'] || 0

                                                                return {
                                                                    'y': priorval,
                                                                    'color': '#7d32ba'
                                                                }

                                                            })

                                                        },

                                                    ],
                                                    xAxis: {
                                                        title: {
                                                            text: 'Discount Range'
                                                        },
                                                        categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                    },
                                                    yAxis: [
                                                        {
                                                            title: {
                                                                text: this.props.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            labels: {

                                                                formatter: function () {

                                                                    function nFormatter(num, digits, axisMax) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;

                                                                        let symbolForAxis = " "
                                                                        let valueForAxis = 1

                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (axisMax >= si[i].value) {
                                                                                symbolForAxis = si[i].symbol
                                                                                valueForAxis = si[i].value
                                                                                break;
                                                                            }
                                                                        }

                                                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                    }
                                                                    return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                                }
                                                            },
                                                        },
                                                    ],
                                                    plotOptions: {
                                                        column: {
                                                            stacking: 'normal',
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter:
                                                                    function () {
                                                                        function nFormatter(num, digits) {
                                                                            var si = [
                                                                                { value: 1E18, symbol: "E" },
                                                                                { value: 1E15, symbol: "P" },
                                                                                { value: 1E12, symbol: "T" },
                                                                                { value: 1E9, symbol: "B" },
                                                                                { value: 1E6, symbol: "M" },
                                                                                { value: 1E3, symbol: "k" }
                                                                            ], i;
                                                                            for (i = 0; i < si.length; i++) {
                                                                                if (num >= si[i].value) {
                                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                }
                                                                            }
                                                                            return num.toString();
                                                                        }

                                                                        return nFormatter(this.point.y, 0).toLocaleString()

                                                                    },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 2,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '20px'
                                                                },
                                                            },
                                                        },

                                                        spline: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }

                                                    },
                                                    exporting: {
                                                        showTable: this.props.state.showDataTable,
                                                        tableCaption: false
                                                    },

                                                }}
                                        />
                                }
                            </div>

                            <div className='col-sm-6'>
                                {this.props.state.TwoYAGO ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 200) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: currentyear_title
                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Current Year',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            //let priorval = ((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket] || {})['totalunits'] || 0
                                                            let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0

                                                            return {
                                                                'y': currentval,
                                                                'color': '#f3508e'
                                                            }

                                                        })

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (num >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }
                                                                return nFormatter(this.point.y, 0).toLocaleString()
                                                            },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },

                                                        },
                                                    },
                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }
                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                                    :
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    zoomType: 'xy',
                                                    height: (9 / 16 * 200) + '%' //16:9 ratio
                                                },
                                                metadata: this.props.state.chartmetadata,
                                                caption: {
                                                    text: this.props.state.attr_caption_text
                                                },
                                                title: {
                                                    text: currentyear_title
                                                },
                                                legend: {
                                                    enabled: false
                                                },
                                                series: [
                                                    {
                                                        name: 'Current Year',
                                                        type: 'column',
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                return '<span>' + this.series.name + '<br> ' + Math.round(this.y).toLocaleString() + '</span>'
                                                            }
                                                        },
                                                        data: this.props.state.discountbuckets.map(bucket => {

                                                            //let priorval = ((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket] || {})['totalunits'] || 0
                                                            let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0

                                                            return {
                                                                'y': currentval,
                                                                'color': '#f3508e'
                                                            }

                                                        })

                                                    },

                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Discount Range'
                                                    },
                                                    categories: this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {

                                                            formatter: function () {

                                                                function nFormatter(num, digits, axisMax) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;

                                                                    let symbolForAxis = " "
                                                                    let valueForAxis = 1

                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (axisMax >= si[i].value) {
                                                                            symbolForAxis = si[i].symbol
                                                                            valueForAxis = si[i].value
                                                                            break;
                                                                        }
                                                                    }

                                                                    return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                                }
                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()

                                                            }
                                                        },
                                                    },
                                                ],
                                                plotOptions: {
                                                    column: {
                                                        stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (num >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }
                                                                return nFormatter(this.point.y, 0).toLocaleString()
                                                            },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 2,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '20px'
                                                            },

                                                        },
                                                    },
                                                    spline: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }
                                                },
                                                exporting: {
                                                    showTable: this.props.state.showDataTable,
                                                    tableCaption: false
                                                },

                                            }}
                                    />
                                }
                            </div>
                        </div>
                }

                {!this.props.state.indexSwitchYOY ?
                    this.props.state.channelviews ?
                        this.props.state.showLastViews ?
                            <div style={{ display: this.props.state.showDataTableStyle }}>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g19">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Difference in " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                        {this.props.state.discountbuckets.map(bucket => {
                                            let priorval = (((this.props.state.discountband_data_last_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                            let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                            var y = Math.round(currentval - priorval)
                                            return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })
                                        }
                                        <Constant.NPDTableRow key={"datarowtotal_g19"}>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Total Difference</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                {['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                    let priorval = (((this.props.state.discountband_data_last_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                    let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                    return currentval - priorval
                                                }).reduce(reducer).toLocaleString()}
                                            </Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                            :
                            <div style={{ display: this.props.state.showDataTableStyle }}>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g19">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Difference in " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                        {this.props.state.discountbuckets.map(bucket => {
                                            let priorval = (((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                            let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                            var y = Math.round(currentval - priorval)
                                            return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })
                                        }
                                        <Constant.NPDTableRow key={"datarowtotal_g19"}>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Total Difference</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                {['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                    let priorval = (((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                    let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                    return currentval - priorval
                                                }).reduce(reducer).toLocaleString()}
                                            </Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                        :
                        this.props.state.showLastViews ?
                            <div style={{ display: this.props.state.showDataTableStyle }}>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g19">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Difference in " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                        {this.props.state.discountbuckets.map(bucket => {
                                            let priorval = (((this.props.state.discountband_data_last[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                            let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                            var y = Math.round(currentval - priorval)
                                            return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })
                                        }
                                        <Constant.NPDTableRow key={"datarowtotal_g19"}>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Total Difference</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                {['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                    let priorval = (((this.props.state.discountband_data_last[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                    let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                    return currentval - priorval
                                                }).reduce(reducer).toLocaleString()}
                                            </Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                            :
                            <div style={{ display: this.props.state.showDataTableStyle }}>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g19">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Difference in " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                        {this.props.state.discountbuckets.map(bucket => {
                                            let priorval = (((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                            let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                            var y = Math.round(currentval - priorval)
                                            return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })
                                        }
                                        <Constant.NPDTableRow key={"datarowtotal_g19"}>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Total Difference</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                {['0'].concat(this.props.state.discountbuckets).map(bucket => {
                                                    let priorval = (((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                    let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                    return currentval - priorval
                                                }).reduce(reducer).toLocaleString()}
                                            </Constant.NPDTableCell>
                                        </Constant.NPDTableRow>

                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                    :
                    this.props.state.channelviews ?
                        this.props.state.showLastViews ?
                            <div style={{ height: '100%', width: "100%", }} className="row">
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Two Years Ago " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                let priorval = (((this.props.state.discountband_data_last_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                //let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(priorval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Current Year " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                //let priorval = (((this.props.state.discountband_data_last_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(currentval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                            </div>
                            :
                            <div style={{ height: '100%', width: "100%", }} className="row">
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Prior Year " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                let priorval = (((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                //let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(priorval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Current Year " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                //let priorval = (((this.props.state.discountband_data_prior_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                let currentval = (((this.props.state.discountband_data_current_geo[this.props.state.selectedChannel] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(currentval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                            </div>
                        :
                        this.props.state.showLastViews ?
                            <div style={{ height: '100%', width: "100%", }} className="row">
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Two Years Ago " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                let priorval = (((this.props.state.discountband_data_last[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                //let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(priorval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Current Year " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                //let priorval = (((this.props.state.discountband_data_last[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(currentval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                            </div>
                            :
                            <div style={{ height: '100%', width: "100%", }} className="row">
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Prior Year " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                let priorval = (((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                //let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(priorval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                                <div className='col-sm-6' style={{ display: this.props.state.showDataTableStyle, height: '100%', width: "100%", }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g19">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Range</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Current Year " + this.props.state.unitsvar}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>

                                            {this.props.state.discountbuckets.map(bucket => {
                                                //let priorval = (((this.props.state.discountband_data_prior[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                let currentval = (((this.props.state.discountband_data_current[selectedBrand] || {})[bucket]) || {})['totalunits'] || 0
                                                var y = Math.round(currentval)
                                                return <Constant.NPDTableRow key={"datarow_" + bucket + "_g19"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{y.toLocaleString()}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>
                            </div>
                }
            </Fragment>
        )
    }
}