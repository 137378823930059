import { reducer } from '../Utilities/Reducer'
import findPercentile from '../Utilities/FindPercentile'
import findPercentileValue from '../Utilities/FindPercentileValue'
import * as Constant from '../../../constants'

export default async function runcomparison_apis() {
    var startTime = new Date()
    this.setState({ isLoaded: false })
    //Make Objects
    let g1Name = this.state.g1Name
    let g2Name = this.state.g2Name
    let exclude_brands_g1 = this.state.exclude_brands_g1
    let exclude_brands_g2 = this.state.exclude_brands_g2

    //let numcomparisonsran = this.state.numcomparisonsran
    let metric = this.state.comparisonMetricSelected.value || ""
    let metric_trended = this.state.comparisonMetricSelected_trended.value || ""
    var metric_trended_1 = ""
    var metric_trended_2 = ""

    let current_tab = this.state.value2
    var error = false

    //Look at value and if error, throw dialog...
    if (current_tab === 0 && metric === "") {
        error = true
    }
    else if (current_tab === 1 && metric_trended === "") {
        error = true
    }

    if (error) {
        this.setState({ errorAlert: true, isLoaded: true })
    }
    else {
        //Build Filters
        let attrs_for_filters = Object.keys(this.state.attributes)
        
        let filters_g1 = {
            'brands': this.state.selectedBrands_g1.map(brand => brand.value)
            , 'exclude_brands': exclude_brands_g1
            , 'items': []
            , 'exclude_items': false
            , 'week_aggregation': this.state.filter_object['numweeks']
            , 'asp_range': []
            , 'date_range': [this.state.filter_object['startdate'], this.state.filter_object['enddate']]
            , 'date_range_curr': [this.state.filter_object['startdate'], this.state.filter_object['enddate']]
            , 'date_range_prior': [this.state.filter_object['startdate'], this.state.filter_object['enddate']]
            , 'outlets': this.state.selectedoutlet_g1.map(outlet => outlet.label)
            , 'sel1_list': this.state.selectedsegment1_g1.map(seg1 => seg1.value)
            , 'sel2_list': this.state.selectedsegment2_g1.map(seg2 => seg2.value)
            , 'sel3_list': this.state.selectedsegment3_g1.map(seg3 => seg3.value)
            , 'sel4_list': this.state.selectedsegment4_g1.map(seg4 => seg4.value)
            , 'attributes': attrs_for_filters.map(attr => { return [attr, (this.state.selectedAttributes_g1[attr] || []).map(attrval => attrval.label)] })
            , 'price_bands': this.state.selectedPriceBands_g1
            , 'price_bands_defs': this.state.priceBandOptions
            , 'channels': this.state.selectedchannel_g1.map(channel => channel.label)
            , 'price_dist_option': [this.state.price_dist_option || 'asp']
        }

        let filters_g2 = {
            'brands': this.state.selectedBrands_g2.map(brand => brand.value)
            , 'exclude_brands': exclude_brands_g2
            , 'items': []
            , 'exclude_items': false
            , 'week_aggregation': this.state.filter_object['numweeks']
            , 'asp_range': []
            , 'date_range': [this.state.filter_object['startdate'], this.state.filter_object['enddate']]
            , 'date_range_curr': [this.state.filter_object['startdate'], this.state.filter_object['enddate']]
            , 'date_range_prior': [this.state.filter_object['startdate'], this.state.filter_object['enddate']]
            , 'outlets': this.state.selectedoutlet_g2.map(outlet => outlet.label)
            , 'sel1_list': this.state.selectedsegment1_g2.map(seg1 => seg1.value)
            , 'sel2_list': this.state.selectedsegment2_g2.map(seg2 => seg2.value)
            , 'sel3_list': this.state.selectedsegment3_g2.map(seg3 => seg3.value)
            , 'sel4_list': this.state.selectedsegment4_g2.map(seg4 => seg4.value)
            , 'attributes': attrs_for_filters.map(attr => { return [attr, (this.state.selectedAttributes_g2[attr] || []).map(attrval => attrval.label)] })
            , 'price_bands': this.state.selectedPriceBands_g2
            , 'price_bands_defs': this.state.priceBandOptions
            , 'channels': this.state.selectedchannel_g2.map(channel => channel.label)
            , 'price_dist_option': [this.state.price_dist_option || 'asp']
        }

        //Await Data

        let url_compare = Constant.baseapiurl + "ReportData/Filtered/GroupCompare/" + this.state.reportsettings.report_id + this.state.extraparam

        let results = await Promise.all([
            fetch(url_compare,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_g1)
                }),

            fetch(url_compare,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_g2)
                }),
        ])

        let comparison_g1_response = results[0]
        let comparison_g2_response = results[1]

        let comparison_g1_obj = {}
        let comparison_g2_obj = {}

        if (comparison_g1_response.status === 200) {
            comparison_g1_obj = await comparison_g1_response.json()
        } else {
            comparison_g1_obj = {}
            this.setState({ errorAlert_report: false })
        }

        if (comparison_g2_response.status === 200) {
            comparison_g2_obj = await comparison_g2_response.json()
        } else {
            comparison_g2_obj = {}
            this.setState({ errorAlert_report: false })
        }

        //G1 Objects
        var numrows_g1 = comparison_g1_obj['numrows'] || 0
        var weighted_dollars_inc_g1 = comparison_g1_obj['incrementaldollars']
        var weighted_dollars_disc_g1 = comparison_g1_obj['discountdollars']
        var promounits_g1 = comparison_g1_obj['promounits']
        var incunits_g1 = comparison_g1_obj['incunits']
        var totalunits_g1 = comparison_g1_obj['totalunits']
        var totalunits_withoutno_g1 = comparison_g1_obj['totalunits_withoutno']
        var subbaseunits_g1 = comparison_g1_obj['subbasevol']

        var weighted_dollars_incremental_g1_weekly = {}
        var weighted_dollars_discount_g1_weekly = {}
        var incremental_g1_weekly = {}
        var promounits_g1_weekly = {}
        var subbaseunits_g1_weekly = {}
        var totalunits_g1_weekly = {}
        var totalunits_g1_withoutno_weekly = {}
        var discpct_g1_weekly = {}
        var total_dollars_weekly_g1_me = {}
        var total_baseprice_weekly_g1 = {}
        var total_aspprice_weekly_g1 = {}

        let dates_g1 = Object.keys(comparison_g1_obj['week_obj'] || {})
        dates_g1.forEach(date => {
            weighted_dollars_incremental_g1_weekly[date] = comparison_g1_obj['week_obj'][date]['incrementaldollars']
            weighted_dollars_discount_g1_weekly[date] = comparison_g1_obj['week_obj'][date]['discountdollars']
            incremental_g1_weekly[date] = comparison_g1_obj['week_obj'][date]['incunits']
            promounits_g1_weekly[date] = comparison_g1_obj['week_obj'][date]['promounits']
            subbaseunits_g1_weekly[date] = comparison_g1_obj['week_obj'][date]['subbasevol']
            totalunits_g1_weekly[date] = comparison_g1_obj['week_obj'][date]['totalunits']
            totalunits_g1_withoutno_weekly[date] = comparison_g1_obj['week_obj'][date]['totalunits_withoutno']
            discpct_g1_weekly[date] = comparison_g1_obj['week_obj'][date]['weightedpctdisc_withoutno'] /// comparison_g1_obj['week_obj'][date]['totalunits_withoutno']
            total_dollars_weekly_g1_me[date] = comparison_g1_obj['week_obj'][date]['totaldollars']
            total_baseprice_weekly_g1[date] = comparison_g1_obj['week_obj'][date]['weightedbp'] /// comparison_g1_obj['week_obj'][date]['totalunits']
            total_aspprice_weekly_g1[date] = comparison_g1_obj['week_obj'][date]['weightedasp'] /// comparison_g1_obj['week_obj'][date]['totalunits']
        })

        var aspDistg1 = {}

        let asps_g1 = Object.keys(comparison_g1_obj['asp_obj'] || {})
        asps_g1.forEach(asp => {
            aspDistg1[asp] = comparison_g1_obj['asp_obj'][asp]['totalunits']
        })

        //G2 Objects

        var numrows_g2 = comparison_g2_obj['numrows'] || 0
        var weighted_dollars_inc_g2 = comparison_g2_obj['incrementaldollars']
        var weighted_dollars_disc_g2 = comparison_g2_obj['discountdollars']
        var promounits_g2 = comparison_g2_obj['promounits']
        var incunits_g2 = comparison_g2_obj['incunits']
        var totalunits_g2 = comparison_g2_obj['totalunits']
        var totalunits_withoutno_g2 = comparison_g2_obj['totalunits_withoutno']
        var subbaseunits_g2 = comparison_g2_obj['subbasevol']

        var weighted_dollars_incremental_g2_weekly = {}
        var weighted_dollars_discount_g2_weekly = {}
        var incremental_g2_weekly = {}
        var promounits_g2_weekly = {}
        var subbaseunits_g2_weekly = {}
        var totalunits_g2_weekly = {}
        var totalunits_g2_withoutno_weekly = {}
        var discpct_g2_weekly = {}
        var total_dollars_weekly_g2_me = {}
        var total_baseprice_weekly_g2 = {}
        var total_aspprice_weekly_g2 = {}

        let dates_g2 = Object.keys(comparison_g2_obj['week_obj'] || {})
        dates_g2.forEach(date => {
            weighted_dollars_incremental_g2_weekly[date] = comparison_g2_obj['week_obj'][date]['incrementaldollars']
            weighted_dollars_discount_g2_weekly[date] = comparison_g2_obj['week_obj'][date]['discountdollars']
            incremental_g2_weekly[date] = comparison_g2_obj['week_obj'][date]['incunits']
            promounits_g2_weekly[date] = comparison_g2_obj['week_obj'][date]['promounits']
            subbaseunits_g2_weekly[date] = comparison_g2_obj['week_obj'][date]['subbasevol']
            totalunits_g2_weekly[date] = comparison_g2_obj['week_obj'][date]['totalunits']
            totalunits_g2_withoutno_weekly[date] = comparison_g2_obj['week_obj'][date]['totalunits_withoutno']
            discpct_g2_weekly[date] = comparison_g2_obj['week_obj'][date]['weightedpctdisc_withoutno'] /// comparison_g2_obj['week_obj'][date]['totalunits_withoutno']
            total_dollars_weekly_g2_me[date] = comparison_g2_obj['week_obj'][date]['totaldollars']
            total_baseprice_weekly_g2[date] = comparison_g2_obj['week_obj'][date]['weightedbp'] /// comparison_g2_obj['week_obj'][date]['totalunits']
            total_aspprice_weekly_g2[date] = comparison_g2_obj['week_obj'][date]['weightedasp'] /// comparison_g2_obj['week_obj'][date]['totalunits']
        })

        var aspDistg2 = {}

        let asps_g2 = Object.keys(comparison_g2_obj['asp_obj'] || {})
        asps_g2.forEach(asp => {
            aspDistg2[asp] = comparison_g2_obj['asp_obj'][asp]['totalunits']
        })

        var overlay = ""
        try {
            overlay = this.state.overlayMetricSelected.value
        }
        catch (error) {
            overlay = 'Not Selected'
        }

        var datemapping_new_withend = this.state.datemapping_new_withend

        console.log('Comparison Ran')
        console.log('Num Rows G1: ' + numrows_g1 + ' Num Rows G2: ' + numrows_g2)

        if (numrows_g1 === 0 || numrows_g2 === 0) {
            //Show Message, and set all to bland
            if (numrows_g1 === 0 && numrows_g2 !== 0) {
                this.setState({ errorAlert_runcompg1: true })
            }
            else if (numrows_g1 !== 0 && numrows_g2 === 0) {
                this.setState({ errorAlert_runcompg2: true })
            }
            else {
                this.setState({ errorAlert_runcompg1g2: true })
            }
        }
        else {
            //Show Nothing
        }

        //Perform Distribution Calcs....

        //Variables

        let pricesobj_g1 = aspDistg1
        let pricesobj_g2 = aspDistg2
        let percentiles = [.05, .10, .15, .20, .25, .30, .35, .40, .45, .50, .55, .60, .65, .70, .75, .80, .85, .90, .95]

        //Calculate Average Price for Retailer....
        let totalPrice = 0
        Object.keys(pricesobj_g1).forEach(price => {
            totalPrice += price * pricesobj_g1[price]
        })
        let numunits = [0].concat(Object.values(pricesobj_g1)).reduce(reducer)
        let averageASP = totalPrice / numunits
        let numunits_rom = [0].concat(Object.values(pricesobj_g2)).reduce(reducer)
        //Run through the distributions....

        let percentile_vals = percentiles.map(p => { return findPercentile(p, numunits_rom, pricesobj_g2) })
        let aspPercentileVal = findPercentileValue(averageASP, numunits_rom, pricesobj_g2)

        let itemDistYAxisMin = Math.floor(percentile_vals[0] / 25) * 25

        //Set Highcharts trace
        var graph_trace = []
        var graph_trace_trended = []
        var graph_trace_index = []
        var graph_trace_index_overunder = []
        var y_g1 = 0
        var y_g2 = 0
        if (metric === 'Payback Ratio') {
            if (promounits_g1 !== 0) {
                y_g1 = weighted_dollars_inc_g1 / weighted_dollars_disc_g1
            }
            else {
                y_g1 = 0
            }

            if (promounits_g2 !== 0) {
                y_g2 = weighted_dollars_inc_g2 / weighted_dollars_disc_g2
            }
            else {
                y_g2 = 0
            }

            graph_trace = [
                {
                    name: 'Payback Ratio',
                    data: [[g1Name, y_g1], [g2Name, y_g2]],
                    type: 'column',
                    xAxis: 0
                },
            ]
        }
        else if (metric === 'Market Lift') {

            if (totalunits_g1 !== 0) {
                y_g1 = Math.min(incunits_g1 / (totalunits_g1 - incunits_g1), 10)
            }
            else {
                y_g1 = 0
            }

            if (totalunits_g2 !== 0) {
                y_g2 = Math.min(incunits_g2 / (totalunits_g2 - incunits_g2), 10)
            }
            else {
                y_g2 = 0
            }

            graph_trace = [
                {
                    name: 'Market Lift',
                    data: [[g1Name, y_g1], [g2Name, y_g2]],
                    type: 'column',
                    xAxis: 0
                },
            ]
        }
        else if (metric === 'Promoted Lift') {
            if (totalunits_g1 !== 0) {
                y_g1 = Math.min(incunits_g1 / (subbaseunits_g1), 10)
            }
            else {
                y_g1 = 0
            }

            if (totalunits_g2 !== 0) {
                y_g2 = Math.min(incunits_g2 / (subbaseunits_g2), 10)
            }
            else {
                y_g2 = 0
            }

            graph_trace = [
                {
                    name: 'Promoted Lift',
                    data: [[g1Name, y_g1], [g2Name, y_g2]],
                    type: 'column',
                    xAxis: 0
                },
            ]
        }
        else if (metric === 'Efficiency') {
            if (promounits_g1 !== 0) {
                y_g1 = Math.min(incunits_g1 / promounits_g1, 1)
            }
            else {
                y_g1 = 0
            }

            if (promounits_g2 !== 0) {
                y_g2 = Math.min(incunits_g2 / promounits_g2, 1)
            }
            else {
                y_g2 = 0
            }

            graph_trace = [
                {
                    name: 'Efficiency',
                    data: [[g1Name, y_g1], [g2Name, y_g2]],
                    type: 'column',
                    xAxis: 0
                },
            ]
        }

        var metric_trended_2_reverse = false
        if (metric_trended === 'Payback Ratio and Discount Depth Trended') {
            metric_trended_1 = 'Payback Ratio'
            metric_trended_2 = 'Discount Depth'
            metric_trended_2_reverse = true
            graph_trace_trended = [
                {
                    name: g1Name + ' - Discount Depth',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min((discpct_g1_weekly[date] / totalunits_g1_withoutno_weekly[date]), 1)]),
                    xAxis: 0,
                    yAxis: 1,
                    stack: 'dd',
                    pointWidth: 30,
                    type: 'column',
                    color: '#7d32ba'
                },
                {
                    name: g2Name + ' - Discount Depth',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min((discpct_g2_weekly[date] / totalunits_g2_withoutno_weekly[date]), 1)]),
                    xAxis: 0,
                    yAxis: 1,
                    stack: 'dd',
                    pointWidth: 15,
                    type: 'column',
                    color: '#000000'
                },
                {
                    name: g1Name + ' - Payback Ratio',
                    data: (this.state.distinctdates).sort().map(date => {
                        let payback_ratio = 0

                        if (discpct_g1_weekly[date] / totalunits_g1_withoutno_weekly[date] >= .01) {
                            payback_ratio = weighted_dollars_incremental_g1_weekly[date] / weighted_dollars_discount_g1_weekly[date]
                        }

                        else {
                            payback_ratio = weighted_dollars_incremental_g1_weekly[date] / weighted_dollars_discount_g1_weekly[date] //0
                        }

                        return [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), payback_ratio]
                    }),
                    xAxis: 0,
                    yAxis: 0,
                    stack: 'pr',
                    pointWidth: 30,
                    type: 'column',
                    color: '#ff8b6a'
                },
                {
                    name: g2Name + ' - Payback Ratio',
                    data: (this.state.distinctdates).sort().map(date => {
                        let payback_ratio = 0
                        if (discpct_g2_weekly[date] / totalunits_g2_withoutno_weekly[date] >= .01) {
                            payback_ratio = weighted_dollars_incremental_g2_weekly[date] / weighted_dollars_discount_g2_weekly[date]
                        }

                        else {
                            payback_ratio = weighted_dollars_incremental_g2_weekly[date] / weighted_dollars_discount_g2_weekly[date] //0
                        }

                        return [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), payback_ratio]
                    }),
                    xAxis: 0,
                    yAxis: 0,
                    stack: 'pr',
                    pointWidth: 15,
                    type: 'column',
                    color: '#ff9971'
                },
            ]
        }
        else if (metric_trended === 'Average Promoted Discount Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Discount Depth'
            graph_trace_trended = [
                {
                    name: g1Name + ' - Discount Depth',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), discpct_g1_weekly[date] / totalunits_g1_withoutno_weekly[date]]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g2Name + ' - Discount Depth',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), discpct_g2_weekly[date] / totalunits_g2_withoutno_weekly[date]]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#000000'
                },
            ]
        }
        else if (metric_trended === 'Market Lift Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Market Lift'
            graph_trace_trended = [
                {
                    name: g1Name + ' - Market Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date]), 10)]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g2Name + ' - Market Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g2_weekly[date] / (totalunits_g2_weekly[date] - incremental_g2_weekly[date]), 10)]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#000000'
                },
            ]
        }
        else if (metric_trended === 'Promoted Lift Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Promoted Lift'
            graph_trace_trended = [
                {
                    name: g1Name + ' - Promoted Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date]), 10)]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g2Name + ' - Promoted Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g2_weekly[date] / (subbaseunits_g2_weekly[date]), 10)]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#000000'
                },
            ]
        }
        else if (metric_trended === 'Efficiency Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Efficiency'
            graph_trace_trended = [
                {
                    name: g1Name + ' - Efficiency',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g2Name + ' - Efficiency',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), Math.min(incremental_g2_weekly[date] / (promounits_g2_weekly[date]), 1)]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'spline',
                    color: '#000000'
                },
            ]
        }
        else if (metric_trended === 'Average Selling Price Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Average Selling Price'
            graph_trace_trended = [
                {
                    name: g1Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date])]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g2Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_aspprice_weekly_g2[date] / totalunits_g2_weekly[date])]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'spline',
                    color: '#000000'
                },
            ]
        }
        else if (metric_trended === 'Base Price Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Base Price'
            graph_trace_trended = [
                {
                    name: g1Name + ' - Base Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g2Name + ' - Base Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date])]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'spline',
                    color: '#000000'
                },
            ]
        }
        else if (metric_trended === 'ASP Gap (Group 1 minus Group 2) Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Average Selling Price Gap'
            graph_trace_trended = [
                {
                    name: 'Average Selling Price Gap - Group 1 minus Group 2',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_aspprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g1Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 3,
                    type: 'spline',
                    color: '#3f3f3f'
                },
            ]
        }
        else if (metric_trended === 'Base Price Gap (Group 1 minus Group 2) Trended') {
            metric_trended_1 = ''
            metric_trended_2 = 'Base Price Gap'
            graph_trace_trended = [
                {
                    name: 'Base Price Gap - Group 1 minus Group 2',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'spline',
                    color: '#7d32ba'
                },
                {
                    name: g1Name + ' - Base Price',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 3,
                    type: 'spline',
                    color: '#3f3f3f'
                },
            ]
        }

        //Add additional metrics here...

        var total_dollars_me = 0
        var total_dollars_g2 = 0
        var my_share = 0

        //Look and See if Index is Dollars or Units...
        if (this.state.indexSwitchCheckDollars) {
            try {
                total_dollars_me = Object.values(total_dollars_weekly_g1_me).reduce(reducer)
                total_dollars_g2 = Object.values(total_dollars_weekly_g2_me).reduce(reducer)
                my_share = total_dollars_me / (total_dollars_g2 + total_dollars_me)
            }
            catch (error) {
                total_dollars_me = 0
                my_share = 0
            }
        }
        else {
            try {
                total_dollars_me = Object.values(totalunits_g1_weekly).reduce(reducer)
                total_dollars_g2 = Object.values(totalunits_g2_weekly).reduce(reducer)
                my_share = total_dollars_me / (total_dollars_g2 + total_dollars_me)
            }
            catch (error) {
                total_dollars_me = 0
                my_share = 0
            }
        }

        //Look and See if Index is Dollars or Units...
        if (this.state.indexSwitchCheckDollars) {
            graph_trace_index = [
                {
                    name: g1Name + ' - ' + this.state.currencyTypeSingular + ' Share Index',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    (total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    pointWidth: 20,
                    type: 'column'
                },
                {
                    name: g1Name + ' - Average ' + this.state.currencyTypeSingular + ' Share',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        1
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    type: 'line',
                    color: '#7d32ba'
                },
            ]

            graph_trace_index_overunder = [
                {
                    name: g1Name + ' - Over/Under ' + this.state.currencyTypeSingular + ' Share',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    ((total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share) - 1
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    pointWidth: 20,
                    type: 'column'
                },
            ]
        }
        else {
            graph_trace_index = [
                {
                    name: g1Name + ' - ' + this.state.unitsvarSingular + ' Share Index',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    (totalunits_g1_weekly[date] / (totalunits_g2_weekly[date] + totalunits_g1_weekly[date])) / my_share
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    pointWidth: 20,
                    type: 'column'
                },
                {
                    name: g1Name + ' - Average ' + this.state.unitsvarSingular + ' Share',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        1
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    type: 'line',
                    color: '#7d32ba'
                },
            ]

            graph_trace_index_overunder = [
                {
                    name: g1Name + ' - Over/Under ' + this.state.unitsvarSingular + ' Share',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    ((totalunits_g1_weekly[date] / (totalunits_g2_weekly[date] + totalunits_g1_weekly[date])) / my_share) - 1
                    ]),
                    xAxis: 0,
                    yAxis: 0,
                    pointWidth: 20,
                    type: 'column'
                },
            ]
        }

        function create_share_summary_table(dates, dollars, currencycode, metric) {
            let distinctdates = (dates).sort()
            var allmetric = []
            if (metric === 'discount') {
                allmetric = (dates).sort().map(date => {
                    let metric = Math.min((discpct_g1_weekly[date] / totalunits_g1_withoutno_weekly[date]), 1) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric
                })
            }
            else if (metric === 'marketlift') {
                allmetric = (dates).sort().map(date => {
                    let metric = Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric
                })
            }
            else if (metric === 'promolift') {
                allmetric = (dates).sort().map(date => {
                    let metric = Math.min((incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date])), 10) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric

                })
            }
            else if (metric === 'efficiency') {
                allmetric = (dates).sort().map(date => {
                    let metric = Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric
                })
            }
            else if (metric === 'asp') {
                allmetric = (dates).sort().map(date => {
                    let metric = (total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric
                })
            }
            else if (metric === 'bp') {
                allmetric = (dates).sort().map(date => {
                    let metric = (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric
                })
            }
            else if (metric === 'aspgap') {
                allmetric = (dates).sort().map(date => {
                    let metric = ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_aspprice_weekly_g2[date] / totalunits_g2_weekly[date])) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric
                })
            }
            else if (metric === 'bpgap') {
                allmetric = (dates).sort().map(date => {
                    let metric = ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date])) || 0
                    if (!isFinite(metric)) {
                        metric = null
                    }
                    return metric
                })
            }

            let metric_min = Math.min(...allmetric)
            let metric_max = Math.max(...allmetric)
            let metricrange = metric_max - metric_min
            let metricstep = metricrange / 5
            let metricbands = []
            let metriccount = metric_min
            let sharelookup = {}
            let metricbandlabels = []

            while (metriccount < metric_max) {
                metricbands.push([metriccount, metriccount + metricstep])
                metriccount += metricstep
            }

            metricbands.forEach(band => {
                var label
                if (metric === 'discount' || metric === 'marketlift' || metric === 'promolift' || metric === 'efficiency') {
                    label = band[0].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                        + ' to '
                        + band[1].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                }
                else {
                    label = band[0].toLocaleString(undefined, { style: 'currency', currency: currencycode })
                        + ' to '
                        + band[1].toLocaleString(undefined, { style: 'currency', currency: currencycode })
                }
                metricbandlabels.push(label)
            })

            distinctdates.forEach(date => {
                var share
                var metrictouse
                if (dollars) {
                    share = ((total_dollars_weekly_g1_me[date] / (total_dollars_weekly_g2_me[date] + total_dollars_weekly_g1_me[date])) / my_share)
                }
                else {
                    share = ((totalunits_g1_weekly[date] / (totalunits_g2_weekly[date] + totalunits_g1_weekly[date])) / my_share)
                }

                if (metric === 'discount') {
                    metrictouse = Math.min((discpct_g1_weekly[date] / totalunits_g1_withoutno_weekly[date]), 1) || 0
                }
                else if (metric === 'marketlift') {
                    metrictouse = Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10) || 0
                }
                else if (metric === 'promolift') {
                    metrictouse = Math.min((incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date])), 10) || 0
                }
                else if (metric === 'efficiency') {
                    metrictouse = Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1) || 0
                }
                else if (metric === 'asp') {
                    metrictouse = (total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]) || 0
                }
                else if (metric === 'bp') {
                    metrictouse = (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) || 0
                }
                else if (metric === 'aspgap') {
                    metrictouse = ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_aspprice_weekly_g2[date] / totalunits_g2_weekly[date])) || 0
                }
                else if (metric === 'bpgap') {
                    metrictouse = ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date])) || 0
                }

                //Calculate Discount Band...
                metricbands.forEach((band, idx) => {
                    if (metrictouse >= band[0] && metrictouse <= band[1]) {
                        sharelookup[metricbandlabels[idx]] = sharelookup[metricbandlabels[idx]] ||
                        {
                            'numweeks': 0,
                            'totalshare': 0,
                            'band_start': band[0],
                            'band_end': band[1]
                        }

                        sharelookup[metricbandlabels[idx]]['numweeks'] += 1
                        sharelookup[metricbandlabels[idx]]['totalshare'] += share
                    }
                })
            })

            return sharelookup
        }

        if (overlay === 'Average Promoted Discount') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - Average Promoted Discount',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min((discpct_g1_weekly[date] / totalunits_g1_withoutno_weekly[date]), 1)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )
            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - Average Promoted Discount',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min((discpct_g1_weekly[date] / totalunits_g1_withoutno_weekly[date]), 1)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'discount')
        }
        else if (overlay === 'Market Lift') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - Market Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - Market Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min((incremental_g1_weekly[date] / (totalunits_g1_weekly[date] - incremental_g1_weekly[date])), 10)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'marketlift')
        }
        else if (overlay === 'Promoted Lift') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - Promoted Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min((incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date])), 10)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + '- Promoted Lift',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min((incremental_g1_weekly[date] / (subbaseunits_g1_weekly[date])), 10)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'promolift')
        }
        else if (overlay === 'Efficiency') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - Efficiency',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - Efficiency',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    Math.min(incremental_g1_weekly[date] / (promounits_g1_weekly[date]), 1)
                    ]),
                    xAxis: 0,
                    yAxis: 1,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'efficiency')
        }
        else if (overlay === 'Average Selling Price') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    (total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date])
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + '- Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    (total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date])
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'asp')
        }
        else if (overlay === 'Base Price') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - Base Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - Base Price',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    (total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date])
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'bp')
        }
        else if (overlay === 'ASP Gap (Group 1 minus Group 2)') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - ASP Gap',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_aspprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )
            graph_trace_index.push(
                {
                    name: g1Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 3,
                    type: 'spline',
                    color: '#565a5c'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - ASP Gap',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_aspprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_aspprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 3,
                    type: 'spline',
                    color: '#565a5c'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'aspgap')
        }
        else if (overlay === 'Base Price Gap (Group 1 minus Group 2)') {
            graph_trace_index.push(
                {
                    name: g1Name + ' - Base Price Gap',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index.push(
                {
                    name: g1Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 3,
                    type: 'spline',
                    color: '#565a5c'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - Base Price Gap',
                    data: (this.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                    ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]) - (total_baseprice_weekly_g2[date] / totalunits_g2_weekly[date]))
                    ]),
                    xAxis: 0,
                    yAxis: 2,
                    type: 'line',
                    color: '#ff8b6a'
                },
            )

            graph_trace_index_overunder.push(
                {
                    name: g1Name + ' - Average Selling Price',
                    data: (this.state.distinctdates).sort().map(date =>
                        [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1),
                        ((total_baseprice_weekly_g1[date] / totalunits_g1_weekly[date]))
                        ]),
                    xAxis: 0,
                    yAxis: 3,
                    type: 'spline',
                    color: '#565a5c'
                },
            )

            var sharelookup = create_share_summary_table(this.state.distinctdates, this.state.indexSwitchCheckDollars, this.state.currencycode, 'bpgap')
        }

        //Add additional overlays here

        //sort share_sorted
        var sharelookup_sorted = Object.keys(sharelookup || {}).sort(function (a, b) { return sharelookup[a].band_start - sharelookup[b].band_start })

        this.setState({
            MetricToCompare: metric,
            MetricToCompare_trended: metric_trended,
            MetricToCompare_trended_1: metric_trended_1,
            MetricToCompare_trended_2: metric_trended_2,
            metric_trended_2_reverse: metric_trended_2_reverse,
            compareGraphData: graph_trace,
            compareIndexGraphData: graph_trace_index,
            compareIndexGraphDataOverUnder: graph_trace_index_overunder,
            compareGraphData_trended: graph_trace_trended,
            totalunits_g1_weekly,
            totalunits_g2_weekly,
            total_dollars_weekly_g1_me,
            total_dollars_weekly_g2_me,
            total_aspprice_weekly_g1,
            total_aspprice_weekly_g2,
            numcomparisonsran: this.state.numcomparisonsran + 1,
            percentile_vals_g1_g2: percentile_vals,
            aspPercentileVal_g1_g2: aspPercentileVal,
            ASP_g1_g2: averageASP.toLocaleString(undefined, { style: 'currency', currency: this.state.currencycode }),
            itemDistYAxisMin_g1_g2: itemDistYAxisMin,
            g1Name,
            g2Name,
            sharelookup,
            sharelookup_sorted,
            isLoaded: true
        })
    }
}
