import React, { Component, Fragment } from 'react'
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import Select, { components } from 'react-select'
import Typography from '@material-ui/core/Typography'
import { Layout } from '../Layout'
import { ThemeProvider } from '@material-ui/core/styles'
import Datetime from 'react-datetime'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Slider from '@material-ui/core/Slider'
import IconButton from '@material-ui/core/IconButton'
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded'
import * as Constant from '../constants'
import selectColourStyles from '../styles/SelectColourStyles'

import hasstaging from './Functions/Utilities/HasStaging'
import definePriceBands from './Functions/DataPulls/DefinePriceBands'
import defineAttrGroups from './Functions/DataPulls/DefineAttrGroups'
import handleToggle_exclude_brands from './Functions/UIFunctions/HandleFunctions/HandleToggleExcludeBrands'
import handleToggle_exclude_items from './Functions/UIFunctions/HandleFunctions/HandleToggleExcludeItems'
import computeDateRange from './Functions/UIFunctions/OtherFunctions/ComputeDateRange'
import handleDateStart from './Functions/UIFunctions/HandleFunctions/HandleDateStart'
import handleDateEnd from './Functions/UIFunctions/HandleFunctions/HandleDateEnd'
import handleChangeASPMinBox from './Functions/UIFunctions/HandleFunctions/HandleChangeASPMinBox'
import handleChangeASPMaxBox from './Functions/UIFunctions/HandleFunctions/HandleChangeASPMaxBox'
import handleChangeSlider from './Functions/UIFunctions/HandleFunctions/HandleChangeSlider'

import FetchConfigWithDefault from './Functions/Utilities/FetchConfigWithDefault'
import FetchDataWithErrorHandling from './Functions/Utilities/FetchDataWithErrorHandling'

// API CRUD requests and error handling
import { ApiErrorDialog, openApiErrorDialog, closeApiErrorDialog, on_api_entity_crud_error } from '../ApiMethods/ApiErrorDialog'
import { entityCrud } from '../ApiMethods/EntityCrud'

export class PDTDownloadData extends Component {
    static displayName = PDTDownloadData.name

    constructor(props) {
        super(props)
        this.state = {
            api_error_dialog: false,
            api_error_details: null,
            api_error_count: 0,
            initComplete: false,
            outlet_options_filters: [],
            extraparam: '',
            dataset_data: {},
            attributes: {},
            customAttrGroups: {},
            sliderRange: [0, 0],
            filter_object: {
                'brands': [],
                'items': [],
                'startdate': '2000-01-01',
                'enddate': '2999-12-31',
                'asprange': [],
                'pricebands': [],
                'numweeks': 1,
                'segment1': [],
                'segment2': [],
                'segment3': [],
                'segment4': [],
                'outlets': [],
                'channels': [],
                'attributes': {},
            },
            exclude_brands: false,
            exclude_items: false,
            isLoaded: true,
            useDoors_inDownload: false,
            download_status: '',
            download_status_color: '#565A5C',
            container_chosen: { 'label': 'Default', 'value': null }
        }
        this.definePriceBands = definePriceBands.bind(this)
        this.defineAttrGroups = defineAttrGroups.bind(this)
        this.handleToggle_exclude_brands = handleToggle_exclude_brands.bind(this)
        this.handleToggle_exclude_items = handleToggle_exclude_items.bind(this)
        this.computeDateRange = computeDateRange.bind(this)
        this.handleDateStart = handleDateStart.bind(this)
        this.handleDateEnd = handleDateEnd.bind(this)
        this.handleChangeASPMinBox = handleChangeASPMinBox.bind(this)
        this.handleChangeASPMaxBox = handleChangeASPMaxBox.bind(this)
        this.handleChangeSlider = handleChangeSlider.bind(this)

        this.entityCrud = entityCrud.bind(this)
        this.openApiErrorDialog = openApiErrorDialog.bind(this)
        this.closeApiErrorDialog = closeApiErrorDialog.bind(this)
        this.on_api_entity_crud_error = on_api_entity_crud_error.bind(this)
        this.FetchConfigWithDefault = FetchConfigWithDefault.bind(this)
        this.FetchDataWithErrorHandling = FetchDataWithErrorHandling.bind(this)
    }

    async componentDidMount() {
        var appname = Constant.whereamilist[3]

        var extraparam, extraparam_multi
        if (hasstaging()) {
            extraparam = '?env=staging'
            extraparam_multi = '&env=staging'
        } else {
            extraparam = ''
            extraparam_multi = ''
        }

        let my_results = await this.entityCrud('Download page initial data fetch', [
            { 'url': Constant.baseapiurl + 'Datasets' + extraparam, 'method': 'GET', 'body': null },
            { 'url': Constant.baseurl + 'account/isauthenticated', 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails, but we want to set "isLoaded" before we bail
            this.setState({ isLoaded: true })
            return
        }

        let datasets_data = my_results.ResultList[0]
        let is_authenticated_data = my_results.ResultList[1]

        let user_role = (is_authenticated_data.userRole !== undefined) ? is_authenticated_data.userRole[0] : 'None'
        this.setState({
            role: user_role,
            extraparam,
            extraparam_multi,
        })

        // -------------------- Process the Dataset Settings --------------------

        var dataset_options = []
        dataset_options = datasets_data.map(dataset => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    return { 'value': dataset.dataset_id, 'label': dataset.short_desc }
                } else {
                    return null
                }
            } else {
                return { 'value': dataset.dataset_id, 'label': dataset.short_desc }
            }
        }).filter(Boolean)

        let dataset_info = {}
        datasets_data.forEach(dataset => {
            dataset_info[dataset.dataset_id] = {
                'country': dataset.country,
                'description': dataset.description,
                'industry_name': dataset.industry_name || "Unknown",
                'short_desc': dataset.short_desc,
                'time_aggregation': dataset.time_aggregation,
                'time_updated': dataset.time_updated
            }
        })

        let industrymapping = {}

        datasets_data.forEach(dataset => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    let industryname = dataset.industry_name || "Unknown"
                    industrymapping[industryname] = industrymapping[industryname] || []
                    industrymapping[industryname].push({ 'value': dataset.dataset_id, 'label': dataset.short_desc })
                }
            } else {
                let industryname = dataset.industry_name || "Unknown"
                industrymapping[industryname] = industrymapping[industryname] || []
                industrymapping[industryname].push({ 'value': dataset.dataset_id, 'label': dataset.short_desc })
            }
        })

        console.log(industrymapping)
        var dataset_array = Object.keys(industrymapping).sort().map(industry => {
            return {
                'label': industry, 'options': industrymapping[industry].sort(function (a, b) {
                    return a.label - b.label
                })
            }
        })

        var hover_requests = datasets_data.map(dataset => {
            return { 'path': '/api/pdt/Datasets/' + dataset.dataset_id + '/' + 'HoverParameters' + extraparam, 'method': 'GET', 'body': null }
        })

        let batch_request = [{ 'url': Constant.baseapiurl_gen + 'pdt/Batch' + extraparam, 'method': 'POST', 'body': hover_requests }]
        let my_hover_results = await this.entityCrud('Hover parameters data fetch, via batch', batch_request, this.on_api_entity_crud_error)
        if (my_hover_results.Errors > 0) {
            // An error screen will be popped up if this fails, but we want to set "isLoaded" before we bail
            this.setState({ isLoaded: true })
            return
        }

        my_hover_results.ResultList[0].forEach((hover_param_results, ind) => {
            let dataset_store_group_results = hover_param_results.body['store_groups']

            let this_dataset = dataset_info[datasets_data[ind].dataset_id]
            this_dataset['storegroups'] = dataset_store_group_results.map(storegroup => storegroup.storegpdesc)
            this_dataset['storegroup_options'] = dataset_store_group_results.map(storegroup => { return { 'label': storegroup.storegpdesc, 'value': storegroup.storegpid } })
            this_dataset['times'] = hover_param_results.body['times']
            this_dataset['levels'] = hover_param_results.body['levels']
        })

        // -------------------- DONE --------------------

        this.setState({
            is_internal_user: is_authenticated_data.internalUser === 'True',
            datasets: dataset_array,
            dataset_info,
            datasets_data,
            dataset_options,
            initComplete: true
        })
        console.log('componentDidMount completed')
    }

    render() {
        console.log(this.state)

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            color: '#000000',
        }

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#4e106f',
            display: 'inline-block',
            fontSize: '.75rem',
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        }

        const dropdownInput = {
            width: '100%',
            height: '50px',
            borderRadius: '5px',
            fontFamily: 'Roboto',
            borderColor: '#4E106F'
        }

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        )

        // This function is used to generate the Dataset combo options
        const Option = (props) => {
            let dataset_id = props.data.value
            let my_dataset = this.state.dataset_info[dataset_id]
            let countryname = Constant.countrynamelookup[my_dataset['country']] || 'Unknown'

            let offset = new Date().getTimezoneOffset() * 60 * 1000 // Get our DST offset

            let lastupdateddate = my_dataset['time_updated']
            let dateupdatedformatted = (new Date(Date.parse(lastupdateddate) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })

            // Some fields (like 'times') are lazily loaded in the background and might not be available at first.
            // We want to just display nothing for those fields at first, and show their values once we have them.
            let datasetstart = (my_dataset['times'] || [])[0]
            let datasetend = (my_dataset['times'] || [])[1]

            var datasetstartformatted, datasetendformatted, timeaggdesc
            let timeagg = my_dataset['time_aggregation']
            if (timeagg === 'Weekly') {
                datasetstartformatted = (new Date(Date.parse(datasetstart) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                datasetendformatted = (new Date(Date.parse(datasetend) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                timeaggdesc = 'Week Ending'
            } else {
                datasetstartformatted = (new Date(Date.parse(datasetstart) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                datasetendformatted = (new Date(Date.parse(datasetend) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                timeaggdesc = ''
            }
            // If we don't have formattable dates don't show something that will confuse the user
            let time_description = timeaggdesc + ' ' + datasetstartformatted + ' to ' + timeaggdesc + ' ' + datasetendformatted

            let storegroups = my_dataset['storegroups'] || []
            let storegroups_list = storegroups.join(',')

            // Some extra items use metadata that is lazily loaded -- don't show them unless we have that data
            let show_extras = datasetendformatted !== 'Invalid Date' && datasetendformatted !== 'Invalid Date' && storegroups_list.length > 0

            let levels = my_dataset['levels'] || []
            let categories = (levels.items || []).map(level => level.sel_name)

            let fulllevel = my_dataset['complete_at_level']
            let full_cat = false
            if (fulllevel === 1) {
                full_cat = true
            }

            let categories_full = (levels.items || []).map(level => full_cat)
            let numcategoriesfull = 0
            categories_full.forEach(cat => { if (cat) { numcategoriesfull += 1 } })

            let numcategories = categories.length
            let fulldataset = (numcategories === numcategoriesfull && numcategories > 0)

            let subcategories = []
            let cats = (levels.items || [])
            cats.forEach(level => (level.items || []).forEach(sublevel => subcategories.push(sublevel.sel_name)))

            return (
                <Constant.HTMLTooltip title={
                    <Fragment>
                        {fulldataset ?
                            <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{my_dataset['short_desc']}</Typography>
                            :
                            <Typography color="inherit">{my_dataset['short_desc']}</Typography>
                        }
                        {!show_extras &&
                            <div><em>Some information about this dataset is still being loaded...</em></div>
                        }
                        <b>Country:</b> {countryname}
                        <br />
                        <b>Industry:</b> {my_dataset['industry_name']}
                        <br />
                        <b>Time Aggregation:</b> {timeagg}
                        <br />
                        {show_extras &&
                            <div>
                                <b>Time Range:</b> {time_description}
                                <br />
                                <b>Store Groups:</b> {storegroups_list}
                                <br />
                                <b>Categories:</b> {categories.map((cat, idx) => {
                                    let sep = ", "
                                    if (idx === (numcategories - 1)) {
                                        sep = ""
                                    }

                                    if (categories_full[idx]) {
                                        return <b style={{ color: "#4e106f" }}>{cat + sep}</b>
                                    } else {
                                        return <span>{cat + sep}</span>
                                    }
                                })}
                                <br />
                                <b>Sub-Categories:</b> {subcategories.join(',')}
                                <br />
                            </div>
                        }
                        <b>Description:</b> {my_dataset['description']}
                        <br />
                        <b>Last Updated Date:</b> {dateupdatedformatted}
                        <br />
                        <br />
                        <b style={{ color: "#4e106f" }}>*Bold Dataset Names indicate that the dataset selection is comprised of only full categories</b>
                        <br />
                        <b style={{ color: "#4e106f" }}>*Bold Category Names indicate that the category selection is complete</b>
                    </Fragment>
                } placement="bottom" {...props}>
                    <div>
                        <components.Option {...props} />
                    </div>
                </Constant.HTMLTooltip>
            )
        }

        // This function is used to generate the Price Band combo options
        const PBOption = (props) => {
            let pbname = props.data.label
            let pbstart = props.data.value[0]
            let pbend = props.data.value[1]
            if ((String(pbstart).slice(-3) !== '.01') && pbstart > 0) {
                pbstart += 1
            }
            var pb_def_string
            if (!pbend) {
                pb_def_string = pbname + ': ' + this.state.currencysymbol + pbstart + ' + '
            } else if (pbstart === 0) {
                pb_def_string = pbname + ': Under ' + this.state.currencysymbol + pbend
            } else {
                pb_def_string = pbname + ': ' + this.state.currencysymbol + pbstart + ' - ' + this.state.currencysymbol + pbend
            }

            return (
                <Constant.HTMLTooltip title={
                    <Fragment>
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{pb_def_string}</Typography>
                    </Fragment>
                } placement="right" {...props}>
                    <div style={{ marginLeft: '25px' }}>
                        <components.Option {...props} />
                    </div>
                </Constant.HTMLTooltip>
            )
        }

        const changeDataset = (selectedOptions) => {
            this.setState({
                selectedDataset: selectedOptions,
                selectedStoreGroup: null,
                download_status: '',
                download_status_color: '#565A5C',

                // In case someone is selecting a new dataset, reset all values below to sensible defaults
                segment_1_options_filters: [],
                segment_2_options_filters: [],
                segment_3_options_filters: [],
                segment_4_options_filters: [],
                segment_1_lookup: { 'All': 'All' },
                segment_2_lookup: { 'All': 'All' },
                segment_3_lookup: { 'All': 'All' },
                segment_4_lookup: { 'All': 'All' },
                segment1_label: 'Top Level',
                segment2_label: 'Second Level',
                segment3_label: null,
                segment4_label: null,
                outlet_options_filters: [],
                channel_options_filters: [],
                channel_lookup: { 'All': 'All' },
                item_options_filters: [],
                item_lookup: { 'All': 'All' },
                item_lookup_reverse: { 'All': 'All' },
                attributes: {},
                brand_lookup_filters: { 'All': 'All' },
                brand_options_filters: [],
                attribute_col_names: [],
                dataset_data: {},
                currencysymbol: '$',
                attributenamelabels: {},
                startdate_raw: '2000-01-01',
                selectedend: '2999-12-31'
                // FIXME: Don't know what to initialize these values to:
                // pastdate: null,
                // futuredate: null,
            })
        }

        const changeStoreGroup = async (selectedOptions) => {
            this.setState({ selectedStoreGroup: selectedOptions, isLoaded: false })
            // Get Settings For Dataset and Store Group
            await getDatasetSettings(this.state.selectedDataset.value, selectedOptions.value)
        }

        const getDatasetSettings = async (datasetid, storegroup) => {
            let my_results = await this.entityCrud('dataset information for dataset ID ' + datasetid, [
                { 'url': Constant.baseapiurl + "Datasets/" + datasetid + this.state.extraparam, 'method': 'GET', 'body': null },
                { 'url': Constant.baseapiurl + "Datasets/" + datasetid + '/Parameters?store_group=' + storegroup + this.state.extraparam_multi, 'method': 'GET', 'body': null },
                { 'url': Constant.baseapiurl_gen + "cloud/Containers/Visible", 'method': 'GET', 'body': null },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails, but we want to set "isLoaded" before we bail
                this.setState({ isLoaded: true })
                return
            }

            let dataset_data = my_results.ResultList[0]
            let params_data = my_results.ResultList[1]
            let containers_data = my_results.ResultList[2]

            // -------------------- Process the Dataset Settings --------------------

            var attributenamelabels = {}
            dataset_data.attributes.forEach((attr, idx) => {
                attributenamelabels[attr] = dataset_data.attributes_human[idx]
            })

            // Add Global Attributes
            dataset_data.global_attributes.forEach((attr, idx) => {
                attributenamelabels[attr] = dataset_data.global_attributes_human[idx]
            })

            function get_segment_label(human_label, not_human_label) {
                // Force first character to be uppercase
                if (human_label) {
                    return human_label.charAt(0).toUpperCase() + human_label.slice(1)
                } else if (not_human_label) {
                    return not_human_label.charAt(0).toUpperCase() + not_human_label.slice(1)
                }
                return undefined
            }

            var segment1_label = get_segment_label(dataset_data.selection1_human, dataset_data.segment1)
            var segment2_label = get_segment_label(dataset_data.selection2_human, dataset_data.segment2)
            var segment3_label = get_segment_label(dataset_data.selection3_human, dataset_data.segment3)
            var segment4_label = get_segment_label(dataset_data.selection4_human, dataset_data.segment4)

            // Set Currency from dataset_data... if Not Explicitly set, use dollars
            var currencysymbol
            if (dataset_data.currency === 'GBP') {
                currencysymbol = '£'
            } else if (dataset_data.currency === 'CAD') {
                currencysymbol = '$'
            } else if (dataset_data.currency === 'EUR') {
                currencysymbol = '€'
            } else if (dataset_data.currency === 'MXN') {
                currencysymbol = '$'
            } else {
                currencysymbol = '$'
            }

            // -------------------- Process the Dataset Settings --------------------

            let outlets = params_data.outlets || []
            let outlet_options_filters = outlets.map(outlet => { return { 'value': outlet, 'label': outlet } })

            // From the levels, create the options
            let levels = params_data.levels || {}
            let num_levels = 2
            if (levels.segment3_human !== undefined) {
                num_levels = 3
            }
            if (levels.segment4_human !== undefined) {
                num_levels = 4
            }

            let segment1items = levels.items

            let segment2items = []
            segment1items.forEach(level1 => {
                level1.items.forEach(nextlevel => {
                    segment2items.push(nextlevel)
                })
            })

            let segment3items = []
            if (num_levels >= 3) {
                segment2items.forEach(level2 => {
                    level2.items.forEach(nextlevel => {
                        segment3items.push(nextlevel)
                    })
                })
            }

            let segment4items = []
            if (num_levels === 4) {
                segment3items.forEach(level3 => {
                    level3.items.forEach(nextlevel => {
                        segment4items.push(nextlevel)
                    })
                })
            }

            let segment_1_lookup = { 'All': 'All' }
            segment1items.forEach(level => segment_1_lookup[level.sel] = level.sel_name)
            let segment_2_lookup = { 'All': 'All' }
            segment2items.forEach(level => segment_2_lookup[level.sel] = level.sel_name)
            let segment_3_lookup = { 'All': 'All' }
            segment3items.forEach(level => segment_3_lookup[level.sel] = level.sel_name)
            let segment_4_lookup = { 'All': 'All' }
            segment4items.forEach(level => segment_4_lookup[level.sel] = level.sel_name)

            let segment_1_options_filters = segment1items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })
            let segment_2_options_filters = segment2items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })
            let segment_3_options_filters = segment3items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })
            let segment_4_options_filters = segment4items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })

            let channels = params_data.channels || []
            let channel_options_filters = channels.map(channel => { return { 'label': channel.geogdesc, 'value': channel.geogid } })
            let channel_lookup = { 'All': 'All' }
            channels.forEach(channel => channel_lookup[channel.geogid] = channel.geogdesc)

            let items = params_data.items || []
            let item_options_filters = items.map(item => { return { 'label': item.proddesc, 'value': item.prodid } })
            let item_lookup = { 'All': 'All' }
            let item_lookup_reverse = { 'All': 'All' }
            items.forEach(item => item_lookup[item.prodid] = item.proddesc)
            items.forEach(item => item_lookup_reverse[item.proddesc] = item.prodid)
            var items_data = [] // Leave it blank for now or use items

            // -------------------- Process the Brands Settings --------------------

            let brands_data = params_data.brands
            console.log(brands_data)
            var brand_options_filters = brands_data.map(brand => { return { 'label': brand.brand_name, 'value': brand.brand } })
            var brand_lookup_filters = { 'All': 'All' }
            var brand_lookup_filters_reverse = { 'All': 'All' }
            brands_data.forEach(brand => {
                brand_lookup_filters[brand.brand] = brand.brand_name
                brand_lookup_filters_reverse[brand.brand_name] = brand.brand
            })

            // -------------------- Process the Attributes (global and dataset) Settings --------------------

            let attributes_data_dataset = params_data.attributes
            let attributes_data_global = params_data.global_attr_values

            var attributes = {}
            let attribute_col_names = attributes_data_dataset.map(attribute => attribute.attribute).concat(attributes_data_global.map(attribute => attribute.global_attribute))

            attributes_data_dataset.forEach(attribute => {
                attributes[attribute.attribute] = (attribute.attribute_values || []).map(attrval => (attrval || ""))
            })

            attributes_data_global.forEach(attribute => {
                attributes[attribute.global_attribute] = (attribute.global_attribute_values || []).map(attrval => (attrval || ""))
            })

            // -------------------- Process the Times Settings --------------------

            var datasetstart = params_data.times[0].substring(0, 10)
            var datasetend = params_data.times[1].substring(0, 10)

            var datasetstartdate_year = parseInt(datasetstart.substring(0, 4))
            var datasetstartdate_month = parseInt(datasetstart.substring(5, 7)) - 1 //Zero Based Month
            var datasetstartdate_day = parseInt(datasetstart.substring(8, 10))

            var datasetenddate_year = parseInt(datasetend.substring(0, 4))
            var datasetenddate_month = parseInt(datasetend.substring(5, 7)) - 1 //Zero Based Month
            var datasetenddate_day = parseInt(datasetend.substring(8, 10))

            var pastdate = Datetime.moment().set({ 'year': datasetstartdate_year, 'month': datasetstartdate_month, 'date': datasetstartdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })
            var futuredate = Datetime.moment().set({ 'year': datasetenddate_year, 'month': datasetenddate_month, 'date': datasetenddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })

            // Make Past date the beginning of the week
            pastdate = pastdate.subtract(6, 'd')

            var selectedstart = datasetstart.substring(0, 10)
            var startdate_raw = datasetstart.substring(0, 10)
            var selectedend = datasetend.substring(0, 10)

            var startdate_year = parseInt(selectedstart.substring(0, 4))
            var startdate_month = parseInt(selectedstart.substring(5, 7)) - 1 //Zero Based Month
            var startdate_day = parseInt(selectedstart.substring(8, 10))

            var enddate_year = parseInt(selectedend.substring(0, 4))
            var enddate_month = parseInt(selectedend.substring(5, 7)) - 1 //Zero Based Month
            var enddate_day = parseInt(selectedend.substring(8, 10))

            var startdate_moment = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })
            var enddate_moment = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })

            // Change this so we always add six days back (on the dataset entry we go from day after end date)
            if (dataset_data.time_aggregation !== 'Monthly' && enddate_moment.day() === startdate_moment.day()) {
                startdate_raw = startdate_moment.subtract(6, 'd').format('YYYY-MM-DD')
            }

            //var pastdate_formatted = selectedstart.substring(0, 10)
            var startdate_year = parseInt(selectedstart.substring(0, 4))
            var startdate_month = parseInt(selectedstart.substring(5, 7)) - 1 //Zero Based Month
            var startdate_day = parseInt(selectedstart.substring(8, 10))

            var enddate_year = parseInt(selectedend.substring(0, 4))
            var enddate_month = parseInt(selectedend.substring(5, 7)) - 1 //Zero Based Month
            var enddate_day = parseInt(selectedend.substring(8, 10))

            var startdate_moment = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })
            var enddate_moment = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })

            // Change this so we always add six days back (on the dataset entry we go from day after end date)
            if (dataset_data.time_aggregation !== 'Monthly' && enddate_moment.day() !== startdate_moment.day()) {
                selectedstart = startdate_moment.subtract(-6, 'd').format('YYYY-MM-DD')
            }

            // Set EndDate Day to state
            this.setState({ enddate_dow: enddate_moment.day() })

            // -------------------- Process the Container Settings --------------------

            let containers = [{ 'label': 'Default', 'value': null }].concat(containers_data.map(c => { return { 'label': c.blob_container_name, 'value': c.blob_container_name }}))

            // -------------------- DONE --------------------

            // Define items used by definePriceBands() and defineAttrGroups() here.  The rest can be defined at the end
            this.setState({
                dataset_data,
                currencysymbol,
                attributes
            })

            console.log('Getting Values from Dataset ' + datasetid + ', Store Group ' + storegroup + ' to Create Price Bands...')
            var aspmax, aspmin
            if (await this.definePriceBands(undefined, storegroup)) {
                let aspvals_data = this.state.min_max_asp_info
                aspmax = Math.round(aspvals_data['aspmax'])
                aspmin = aspvals_data['aspmin']
            } else {
                aspmax = 10000
                aspmin = 0
            }

            await this.defineAttrGroups()

            // set initial filters to be blank to erase any possible previous settings from another dataset or store group
            let filter_object = {
                'brands': [],
                'items': [],
                'startdate': startdate_raw,
                'enddate': selectedend,
                'asprange': [],
                'pricebands': [],
                'numweeks': 1,
                'segment1': [],
                'segment2': [],
                'segment3': [],
                'segment4': [],
                'outlets': [],
                'channels': [],
                'attributes': {},
            }

            attribute_col_names.forEach(attr => {
                filter_object['attributes'][attr] = []
            })

            this.setState({
                segment_1_options_filters,
                segment_2_options_filters,
                segment_3_options_filters,
                segment_4_options_filters,
                segment_1_lookup,
                segment_2_lookup,
                segment_3_lookup,
                segment_4_lookup,
                segment1_label: segment1_label,
                segment2_label: segment2_label,
                segment3_label: segment3_label,
                segment4_label: segment4_label,
                outlet_options_filters,
                channel_options_filters,
                channel_lookup,
                item_options_filters,
                item_lookup,
                item_lookup_reverse,
//                times_data,
                pastdate,
                futuredate,
                brand_lookup_filters,
                brand_options_filters,
                attribute_col_names,
                attributenamelabels,
                startdate_raw,
                selectedend,
                sliderRange: [Math.floor(aspmin), Math.round(aspmax)],
                sliderRangeMin: Math.floor(aspmin),
                sliderRangeMax: Math.round(aspmax),
                filter_object,
                containers,
                isLoaded: true
            })
        }

        const selectAllAggregations = () => {
            this.setState({
                brand_checked: true,
                proddesc_checked: true,
                geogdesc_checked: true,
                attributes_checked: true,
                segment1name_checked: true,
                segment2name_checked: true,
                segment3name_checked: true,
                segment4name_checked: true,
            })
        }

        const clearAllAggregations = () => {
            this.setState({
                brand_checked: false,
                proddesc_checked: false,
                geogdesc_checked: false,
                attributes_checked: false,
                segment1name_checked: false,
                segment2name_checked: false,
                segment3name_checked: false,
                segment4name_checked: false,
            })
        }

        const handleToggle_download = (column) => {
            if (column === 'brand') {
                this.setState({ brand_checked: !this.state.brand_checked })
            } else if (column === 'proddesc') {
                this.setState({ proddesc_checked: !this.state.proddesc_checked })
            } else if (column === 'geogdesc') {
                this.setState({ geogdesc_checked: !this.state.geogdesc_checked })
            } else if (column === 'attributes') {
                this.setState({ attributes_checked: !this.state.attributes_checked })
            } else if (column === 'segment1_name') {
                this.setState({ segment1name_checked: !this.state.segment1name_checked })
            } else if (column === 'segment2_name') {
                this.setState({ segment2name_checked: !this.state.segment2name_checked })
            } else if (column === 'segment3_name') {
                this.setState({ segment3name_checked: !this.state.segment3name_checked })
            } else if (column === 'segment4_name') {
                this.setState({ segment4name_checked: !this.state.segment4name_checked })
            } else if (column === 'useDoors_inDownload') {
                this.setState({ useDoors_inDownload: !this.state.useDoors_inDownload })
            }
        }

        const onChangeContainer = (selectedOptions, action) => {
            console.log(selectedOptions)
            var container_chosen = selectedOptions
            this.setState({ container_chosen })
        }

        const runDownload = (destination) => {
            console.log('run download clicked...')
            let columnstouse = []
            if (this.state.brand_checked) {
                columnstouse.push('brand')
            }
            if (this.state.proddesc_checked) {
                columnstouse.push('proddesc')
            }
            columnstouse.push('outlet')  // Always push outlet
            if (this.state.geogdesc_checked) {
                columnstouse.push('geogdesc')
            }
            if (this.state.segment1name_checked) {
                columnstouse.push('segment1_name')
            }
            if (this.state.segment2name_checked) {
                columnstouse.push('segment2_name')
            }
            if (this.state.segment3name_checked) {
                columnstouse.push('segment3_name')
            }
            if (this.state.segment4name_checked) {
                columnstouse.push('segment4_name')
            }

            let includeattributes = (this.state.attributes_checked) ? true : false

            // build filter
            function removeItemOnce(arr, value) {
                let arrcopy = arr
                var index = arrcopy.indexOf(value)
                if (index > -1) {
                    arrcopy.splice(index, 1)
                }
                return arrcopy
            }

            let filters_raw = this.state.filter_object
            let apifilterattrs = Object.keys(filters_raw['attributes']).map(attr => {
                return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All')]
            })

            let filter = {
                storegpid: this.state.selectedStoreGroup.value,
                brands: removeItemOnce(filters_raw['brands'], 'All'),
                exclude_brands: this.state.exclude_brands,
                items: removeItemOnce(filters_raw['items'], 'All'),
                exclude_items: this.state.exclude_items,
                week_aggregation: filters_raw['numweeks'],
                asp_range: filters_raw['asprange'],
                date_range: [filters_raw.startdate, filters_raw.enddate],
                date_range_curr: [filters_raw.startdate, filters_raw.enddate],
                date_range_prior: [filters_raw.startdate, filters_raw.enddate],
                outlets: removeItemOnce(filters_raw['outlets'], 'All'),
                sel1_list: removeItemOnce(filters_raw['segment1'], 'All'),
                sel2_list: removeItemOnce(filters_raw['segment2'], 'All'),
                sel3_list: removeItemOnce(filters_raw['segment3'], 'All'),
                sel4_list: removeItemOnce(filters_raw['segment4'], 'All'),
                attributes: apifilterattrs,
                price_bands: removeItemOnce(filters_raw['pricebands'], 'All'),
                price_bands_defs: this.state.priceBandOptions,
                channels: removeItemOnce(filters_raw['channels'], 'All'),
                download_columns: columnstouse,
                download_includeattributes: includeattributes,
                download_includedoors: this.state.useDoors_inDownload
            }

            // Kick off an async download
            issue_async_download(destination, filter)
        }

        const issue_async_download = async (destination, filters) => {
            var dataset_id = this.state.selectedDataset.value
            let download_base_url = Constant.baseapiurl + 'DatasetData/Filtered/DownloadData/' + dataset_id + this.state.extraparam
            if (destination === 'Excel' || destination === 'Parquet') {
                if (destination == 'Excel') {
                    var file_extension = '.xlsx'
                } else {
                    var file_extension = '.parquet'
                }
                alert('Download is Processing.  File will appear in lower bar when available.')
                console.log('Starting dataset data download to ' + destination + '...')
                this.setState({
                    download_status: 'Download to ' + destination + ' is running for ' + this.state.selectedDataset.label,
                    download_status_color: '#ef2a79'
                })
                // For download we need to specify the desired file format
                let my_results = await this.entityCrud('downloading ' + destination + ' file containing dataset data', [
                    { 'url': download_base_url + '?to_azure=false&format=' + destination + this.state.extraparam_multi, 'method': 'POST', 'body': filters, responseType: 'blob' },
                ], this.on_api_entity_crud_error)
                if (my_results.Errors > 0) {
                    console.log('Download to ' + destination + ' failed!')
                    this.setState({
                        download_status: 'Download to ' + destination + ' failed for ' + this.state.selectedDataset.label,
                        download_status_color: 'red'
                    })
                } else {
                    // Turn the downloaded data into a downloaded file -- make a link and click it
                    console.log(destination + ' download into memory is complete - saving BLOB we downloaded as a file...')
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(new Blob([my_results.ResultList[0]]))

                    var newdate = new Date()
                    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
                    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
                    datestring = datestring.replaceAll(',', '')
                    let file_name = 'Downloaded Data for Dataset ' + dataset_id + ' - Extracted - ' + datestring + file_extension

                    link.setAttribute('download', file_name)
                    document.body.appendChild(link)
                    link.click()
                    console.log('File saved')
                    this.setState({
                        download_status: 'Download to ' + destination + ' complete for ' + this.state.selectedDataset.label,
                        download_status_color: '#565A5C'
                    })
                }
            } else {
                if (this.state.container_chosen.label != 'Default') {
                    filters['destination_container'] = this.state.container_chosen.value
                }
                alert('Upload into the cloud is Processing.  You will get an Email when it is complete.')
                console.log('Starting dataset data upload into the cloud...')
                this.setState({
                    download_status: 'Upload into Azure is running for ' + this.state.selectedDataset.label,
                    download_status_color: '#ef2a79'
                })
                // For cloud upload the only format is Parquet so we don't specify it
                let my_results = await this.entityCrud('uploading dataset data into the cloud', [
                    { 'url': download_base_url + '?to_azure=true' + this.state.extraparam_multi, 'method': 'POST', 'body': filters },
                ], this.on_api_entity_crud_error)
                if (my_results.Errors > 0) {
                    console.log('Upload to cloud failed!')
                    this.setState({
                        download_status: 'Upload into Azure failed for ' + this.state.selectedDataset.label,
                        download_status_color: 'red'
                    })
                } else {
                    console.log('Upload is complete')
                    console.log(my_results.ResultList[0])
                    this.setState({
                        download_status: 'Upload into Azure is complete for ' + this.state.selectedDataset.label,
                        download_status_color: '#565A5C'
                    })
                }
            }
        }

        const valid = (current) => {
            if (this.state.datasettimeagg === 'Monthly') {
                return current.format('D') === "1" && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            } else {
                return current.day() === this.state.enddate_dow && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            }
        }

        const valid_start = (current) => {
            if (this.state.time_aggregation === 'Monthly') {
                return current.format('D') === "1" && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            } else {
                return current.day() === ((this.state.enddate_dow + 1) % 7) && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            }
        }

        var filter_segment_label = this.state.segment1_label + '/' + this.state.segment2_label
        if (this.state.dataset_data) {
            if (this.state.dataset_data.sel3_list) {
                filter_segment_label += '/' + this.state.segment3_label
            }
            if (this.state.dataset_data.sel4_list) {
                filter_segment_label += '/' + this.state.segment4_label
            }
        }

        const setfilters = (selectedOptions, position, attr_selected = null) => {
            // Convert a list of options into a list of values for a multi-select combobox type of item
            function get_filter_value_list_skipping_all(selectedOptions) {
                let item_list = []
                if (selectedOptions && selectedOptions.length > 0) {
                    selectedOptions.forEach(list_item => {
                        if (list_item.value !== 'All') {
                            item_list.push(list_item.value)
                        }
                    })
                }
                return item_list
            }

            var filter_object = this.state.filter_object
            var selected_attr_groups = this.state.selected_attr_groups || {}

            if (position === 'brands') {
                filter_object['brands'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'items') {
                filter_object['items'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'segment1') {
                filter_object['segment1'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'segment2') {
                filter_object['segment2'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'segment3') {
                filter_object['segment3'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'segment4') {
                filter_object['segment4'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'outlets') {
                filter_object['outlets'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'channels') {
                filter_object['channels'] = get_filter_value_list_skipping_all(selectedOptions)
            } else if (position === 'startdate') {
                filter_object['startdate'] = selectedOptions
            } else if (position === 'enddate') {
                filter_object['enddate'] = selectedOptions
            } else if (position === 'daterangecalc') {
                filter_object['startdate'] = selectedOptions[0]
                filter_object['enddate'] = selectedOptions[1]
            } else if (position === 'asprange') {
                filter_object['asprange'] = selectedOptions
            } else if (position === 'priceband') {
                filter_object['pricebands'] = (selectedOptions && selectedOptions.length > 0) ? selectedOptions : []
            } else if (position === 'attributegroup') {
                filter_object['attributes'][attr_selected] = get_filter_value_list_skipping_all(selectedOptions)
                selected_attr_groups[attr_selected] = (selectedOptions && selectedOptions.length > 0) ? selectedOptions : []
            } else if (position === 'attribute') {
                let attrvalues = get_filter_value_list_skipping_all(selectedOptions)
                filter_object['attributes'][attr_selected] = attrvalues
                // For each custom attribute, if attrvalues includes all attribute values in the group, then set that attribute group as selected
                selected_attr_groups[attr_selected] = []
                if (selectedOptions && selectedOptions.length > 0) {
                    let customAttrGroups_thisattr = this.state.customAttrGroups[attr_selected] || []
                    customAttrGroups_thisattr.forEach(group => {
                        if (group.attr_vals.every(v => attrvalues.includes(v))) {
                            selected_attr_groups[attr_selected].push({ 'label': group.groupname, 'value': group.attr_vals })
                        }
                    })
                }
            } else {
                alert('An unexpcted filter item was passed to setFilter: ' + position)
            }

            this.setState({ filter_object, selected_attr_groups })
        }

        this.setfilters = setfilters.bind(this)

        const sliderCommitted = () => {
            var sliderRange = this.state.sliderRange
            const setfilters = this.setfilters
            setTimeout(function () {
                setfilters(sliderRange, 'asprange')
            }, 1000);
        }

        this.sliderCommitted = sliderCommitted.bind(this)

        return (
            <ThemeProvider theme={Constant.muiTheme}>
            <Layout title="Dataset Data Download">
                <Constant.NPDBackdrop
                    open={!this.state.isLoaded}
                    invisible={false}
                >
                        <div class="custom-loader"></div>
                        {/*<CircularProgress color="inherit" />*/}
                </Constant.NPDBackdrop>

                {/* API error pop-up */}
                <ApiErrorDialog
                    state={this.state}
                    closeApiErrorDialog={this.closeApiErrorDialog}
                />

                {/* This is the beginning of the actual web page */}
                <div name='banner' className='banner'>
                    <h1white align='center'>Price and Discount Trends Dataset Download</h1white><br/>
                    <h2white align='center'>Use this page to download data from a Dataset</h2white>
                </div>

                <div className="mainbodydiv contentHolderGradient" style={{ padding: '25px' }}>
                    {/* First the two comboboxes to select the dataset and then the store group */}
                    <div style={{ maxWidth: '1200px', padding: '30px' }}>
                        <div className="row">
                            <div className="col-sm-2">
                                <span style={{ lineHeight: '1', fontSize: '1.5rem' }} className="formfieldtitle">Dataset</span>
                            </div>
                            <div className="col-sm-4">
                                <Select
                                    inputId="datasetdropdown"
                                    options={this.state.datasets}
                                    options={this.state.datasets}
                                    placeholder={'Select a Dataset'}
                                    onChange={(options) => changeDataset(options)}
                                    value={this.state.selectedDataset}
                                    components={{ Option }}
                                    formatGroupLabel={formatGroupLabel}
                                    isDisabled={!this.state.initComplete}
                                    styles={selectColourStyles}
                                />
                                <br />
                            </div>
                            <div className="col-sm-6">
                                {!this.state.initComplete &&
                                    <span style={{ lineHeight: '1', fontSize: '1.25rem' }}>Controls will be enabled when metadata is loaded</span>
                                }
                            </div>
                            <br/>
                        </div>
                        {this.state.selectedDataset &&
                            <div className="row">
                                <div className="col-sm-2">
                                    <span style={{ lineHeight: '1', fontSize: '1.5rem' }} className="formfieldtitle">Store Group</span>
                                </div>
                                <div className="col-sm-4">
                                    <Select
                                        inputId="storegroupdropdown"
                                        options={((this.state.dataset_info || {})[this.state.selectedDataset.value] || {})['storegroup_options']}
                                        placeholder={'Select a Storegroup'}
                                        onChange={(options) => changeStoreGroup(options)}
                                        value={this.state.selectedStoreGroup}
                                        isDisabled={!this.state.initComplete}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                <div className="col-sm-6"/>
                            </div>
                        }
                    </div>

                    {/* Next is the real body of the page once a dataset and store group are selected */}
                    <div className="row">
                        {this.state.selectedStoreGroup &&
                            <div className="row contentHolder" style={{ padding: '10px', display: this.state.displayFilters, maxWidth: '1390px', marginRight: '55px', paddingLeft: 0 }}>
                                {/* Next, the filters */}
                                <div className='col-sm-12'>
                                    <span style={{ lineHeight: '1', fontSize: '2rem' }} className="formfieldtitle">Filters</span>
                                </div>
                                <div className='col-sm-4'>
                                        <Checkbox
                                            id='excludeBrandsCheck'
                                            checked={this.state.exclude_brands}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.handleToggle_exclude_brands}
                                        />
                                        <label htmlFor='excludeBrandsCheck'>Exclude Brands Selected Below?</label>
                                </div>
                                <div className='col-sm-4'>
                                        <Checkbox
                                            id='excludeItemsCheck'
                                            checked={this.state.exclude_items}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.handleToggle_exclude_items}
                                        />
                                        <label htmlFor='excludeItemsCheck'>Exclude Items Selected Below?</label>
                                </div>
                                <div className='col-sm-4'/>
                                <div className='col-sm-4'>
                                    <p>Brands</p>
                                    <Select
                                        id="BrandFilter1"
                                        options={this.state.brand_options_filters}
                                        isMulti
                                        placeholder={'Select Brands'}
                                        onChange={(item, position) => this.setfilters(item, 'brands')}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <p>Items</p>
                                    <Select
                                        id="itemdropdown"
                                        options={this.state.item_options_filters}
                                        isMulti
                                        placeholder={'Select Items'}
                                        onChange={(item, position) => this.setfilters(item, 'items')}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                {(this.state.outlet_options_filters.length > 1) ?
                                    <div className='col-sm-4'>
                                        <p>Outlet</p>
                                        <Select
                                            id="Outlet"
                                            options={this.state.outlet_options_filters}
                                            isMulti
                                            placeholder={'Select Outlets'}
                                            onChange={(item, position) => this.setfilters(item, 'outlets')}
                                            closeMenuOnSelect={false}
                                            styles={Constant.dropdownStyles}
                                            styles={selectColourStyles}
                                        />
                                    </div>
                                    : 
                                    <div className='col-sm-4'>
                                    </div>
                                }
                                <div className='col-sm-4'>
                                    <span className="row">
                                        <IconButton
                                            className="btn btn-primary"
                                            onClick={this.computeDateRange}
                                            style={{ padding: '5px', margin: '5px' }}
                                        >
                                            <Tooltip title="Compute a Date Range" placement="left">
                                                <DateRangeRoundedIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <p style={{ marginTop: '1rem' }}>Start Date</p>
                                    </span>
                                    <Datetime
                                        input={true}
                                        isValidDate={valid_start}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        onChange={this.handleDateStart}
                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportstartdate" }}
                                        value={this.state.startdate_raw}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }}>End Date</p>
                                    <Datetime
                                        input={true}
                                        isValidDate={valid}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        onChange={this.handleDateEnd}
                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddate" }}
                                        value={this.state.selectedend}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }}>Channels</p>
                                    <Select
                                        id="Channel"
                                        options={this.state.channel_options_filters}
                                        isMulti
                                        placeholder={'Select Channels'}
                                        onChange={(channel, position) => this.setfilters(channel, 'channels')}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }}>Average Selling Price</p>
                                    <div className='row col-sm-12 '>
                                        <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{ startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment> }}
                                                onChange={this.handleChangeASPMinBox}
                                                onKeyUp={(event) => {
                                                    if (event.key == 'Enter') {
                                                        this.sliderCommitted()
                                                    }
                                                }}
                                                id="aspmin"
                                                value={this.state.sliderRange[0]}
                                            />
                                        </div>
                                        <div className='col-sm-6 '>
                                                <Slider
                                                    color="primary"
                                                    value={this.state.sliderRange}
                                                    onChange={this.handleChangeSlider}
                                                    onChangeCommitted={this.sliderCommitted}
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                    max={this.state.sliderRangeMax}
                                                />
                                        </div>
                                        <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{ startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol }</Constant.NPDInputAdornment> }}
                                                onChange={this.handleChangeASPMaxBox}
                                                onKeyUp={(event) => {
                                                    if (event.key == 'Enter') {
                                                        this.sliderCommitted()
                                                    }
                                                }}
                                                id="aspmax"
                                                value={this.state.sliderRange[1]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }}>Price Band</p>
                                    <Select
                                        inputId='pricebandfilter'
                                        options={this.state.priceBandOptions}
                                        isMulti
                                        placeholder='Select a Price Band'
                                        onChange={(item, position) => this.setfilters(item, 'priceband')}
                                        maxMenuHeight={250}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        components={{ PBOption }}
                                    />
                                </div>
                                <div className='col-sm-4' />
                                <div className='col-sm-12'>
                                    <br />
                                    <h5>{filter_segment_label}</h5>
                                </div>
                                <div className='col-sm-6'>
                                    <p>{this.state.segment1_label}</p>
                                    <Select
                                        options={this.state.segment_1_options_filters}
                                        isMulti
                                        placeholder={'Select ' + this.state.segment1_label}
                                        onChange={(item, position) => this.setfilters(item, 'segment1')}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                <div className='col-sm-6'>
                                    <p>{this.state.segment2_label}</p>
                                    <Select
                                        options={this.state.segment_2_options_filters}
                                        isMulti
                                        placeholder={'Select ' + this.state.segment2_label}
                                        onChange={(item, position) => this.setfilters(item, 'segment2')}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                {this.state.segment3_label != null && <div className='col-sm-6'>
                                    <p>{this.state.segment3_label}</p>
                                    <Select
                                        options={this.state.segment_3_options_filters}
                                        isMulti
                                        placeholder={'Select ' + this.state.segment3_label}
                                        onChange={(item, position) => this.setfilters(item, 'segment3')}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                    </div>
                                }
                                {this.state.segment4_label != null && <div className='col-sm-6'>
                                    <p>{this.state.segment4_label}</p>
                                    <Select
                                        options={this.state.segment_4_options_filters}
                                        isMulti
                                        placeholder={'Select ' + this.state.segment4_label}
                                        onChange={(item, position) => this.setfilters(item, 'segment4')}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                    </div>
                                }
                                {
                                    Object.keys(this.state.attributes).length > 0 ?
                                        <Fragment>
                                            <div className='col-sm-12'>
                                                <br />
                                                <h5>Attributes</h5>
                                            </div>

                                            {Object.keys(this.state.attributes).map(attribute => {
                                                let attribute_vals = this.state.attributes[attribute]
                                                let idname = attribute + "dropdown"
                                                return <div className='col-sm-4' key={idname}>
                                                    <p>{this.state.attributenamelabels[attribute]}</p>
                                                    <Select
                                                        id={idname}
                                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                                        isMulti
                                                        placeholder={'Select ' + this.state.attributenamelabels[attribute]}
                                                        onChange={(item, position) => this.setfilters(item, 'attribute', attribute)}
                                                        value={((this.state.filter_object['attributes'] || {})[attribute] || []).map(attrval => { return { 'label': attrval, 'value': attrval } })}
                                                        closeMenuOnSelect={false}
                                                        styles={Constant.dropdownStyles}
                                                        styles={selectColourStyles}
                                                    />
                                                </div>
                                            })}
                                            <div className='col-sm-12'>
                                                <br />
                                                <h5>Attribute Groups</h5>
                                            </div>

                                            {Object.keys(this.state.customAttrGroups).map(attribute => {
                                                let attribute_groups = this.state.customAttrGroups[attribute]
                                                let idname = attribute + "groupdropdown"
                                                return <div className='col-sm-4' key={idname}>
                                                    <p>{this.state.attributenamelabels[attribute]}</p>
                                                    <Select
                                                        id={idname}
                                                        options={attribute_groups.map(group => { return { 'label': group.groupname, 'value': group.attr_vals } })}
                                                        isMulti
                                                        placeholder={'Select ' + this.state.attributenamelabels[attribute] + ' Group'}
                                                        onChange={(item, position, excludearray, attrsent) => this.setfilters(item, 'attributegroup', attribute)}
                                                        value={(this.state.selected_attr_groups || {})[attribute] || []}
                                                        closeMenuOnSelect={false}
                                                        styles={Constant.dropdownStyles}
                                                        styles={selectColourStyles}
                                                    />
                                                </div>
                                            })}
                                        </Fragment>
                                        :
                                        <Fragment/>
                                }

                                {/* Aggregation information */}
                                <div className="col-sm-12 row" style={{ marginTop: '25px' }}>
                                    <div className='col-sm-12' style={{ padding: '5px' }}>
                                        <span style={{ lineHeight: '1', fontSize: '2rem' }} className="formfieldtitle">Select Level of Data Aggregation</span>
                                    </div>
                                    <div className='col-sm-12' style={{ padding: '5px' }}>
                                        <p>
                                            Outlet and Week-Ending Date are always included in the download (and will not be aggregated over).  Therefore,
                                            those items cannot be selected or deselected below.  The Store Group "Total Measured Market" has only that one
                                            value for Outlet.  If you have selected a Store Group for an ALR, then the downloaded data will have two possible
                                            values for Outlet: the ALR name and "ROM" for Rest of Market.
                                        </p>
                                        {(this.state.is_internal_user) &&
                                            <p>
                                                For internal users, door count information can optionally be included in the download data.
                                                These columns are not available to external users at all.
                                            </p>
                                        }
                                        <p>
                                            Metrics selected below will be included in the dataset. Metrics not selected will be aggregated over.  If nothing
                                            is selected below, then the result will be one row per week, per outlet, for total weekly sales.
                                        </p>
                                    </div>
                                    <div className='col-sm-12' style={{ padding: '5px' }}>
                                        <button
                                            onClick={() => selectAllAggregations()}
                                            className="btn btn-primary"
                                            autoFocus
                                            style={{ margin: '5px' }}
                                        >
                                            Select All
                                        </button>
                                        <button
                                            onClick={() => clearAllAggregations()}
                                            className="btn btn-primary"
                                            autoFocus
                                            style={{ margin: '5px' }}
                                        >
                                            Unselect All
                                        </button>
                                    </div>
                                    <div className='col-sm-4' style={{ padding: '5px' }}>
                                        <Checkbox
                                            id='includeBrand'
                                            checked={this.state.brand_checked || false}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={(column) => handleToggle_download('brand')}
                                        />
                                        <label htmlFor='includeBrand'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>Brand</span></label>
                                    </div>
                                    <div className='col-sm-4' style={{ padding: '5px' }}>
                                        <Checkbox
                                            id='includeItem'
                                            checked={this.state.proddesc_checked || false}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={(column) => handleToggle_download('proddesc')}
                                        />
                                        <label htmlFor='includeItem'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>Item</span></label>
                                    </div>
                                    <div className='col-sm-4' style={{ padding: '5px' }}>
                                        <Checkbox
                                            id='includeChannel'
                                            checked={this.state.geogdesc_checked || false}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={(column) => handleToggle_download('geogdesc')}
                                        />
                                        <label htmlFor='includeChannel'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>Channel</span></label>
                                    </div>
                                    <div className='col-sm-4' style={{ padding: '5px' }}>
                                        <Checkbox
                                            id='includeAttributes'
                                            checked={this.state.attributes_checked || false}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={(column) => handleToggle_download('attributes')}
                                        />
                                        <label htmlFor='includeAttributes'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>Attributes</span></label>
                                    </div>
                                    {this.state.segment1_label &&
                                        <div className='col-sm-4' style={{ padding: '5px' }}>
                                            <Checkbox
                                                id='includeSegment1'
                                                checked={this.state.segment1name_checked || false}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={(column) => handleToggle_download('segment1_name')}
                                            />
                                            <label htmlFor='includeSegment1'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>{this.state.segment1_label}</span></label>
                                        </div>
                                    }
                                    {this.state.segment2_label &&
                                        <div className='col-sm-4' style={{ padding: '5px' }}>
                                            <Checkbox
                                                id='includeSegment2'
                                                checked={this.state.segment2name_checked || false}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={(column) => handleToggle_download('segment2_name')}
                                            />
                                            <label htmlFor='includeSegment2'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>{this.state.segment2_label}</span></label>
                                        </div>
                                    }
                                    {this.state.segment3_label &&
                                        <div className='col-sm-4' style={{ padding: '5px' }}>
                                            <Checkbox
                                                id='includeSegment3'
                                                checked={this.state.segment3name_checked || false}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={(column) => handleToggle_download('segment3_name')}
                                            />
                                            <label htmlFor='includeSegment3'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>{this.state.segment3_label}</span></label>
                                        </div>
                                    }
                                    {this.state.segment4_label &&
                                        <div className='col-sm-4' style={{ padding: '5px' }}>
                                            <Checkbox
                                                id='includeSegment4'
                                                checked={this.state.segment4name_checked || false}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={(column) => handleToggle_download('segment4_name')}
                                            />
                                            <label htmlFor='includeSegment4'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>{this.state.segment4_label}</span></label>
                                        </div>
                                    }
                                    {(this.state.is_internal_user) &&
                                        <div className='col-sm-4' style={{ padding: '5px' }}>
                                            <Checkbox
                                                id='includedoors'
                                                checked={this.state.useDoors_inDownload || false}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={(column) => handleToggle_download('useDoors_inDownload')}
                                            />
                                            <label htmlFor='includedoors'><span style={{ lineHeight: '1', fontSize: '1.5rem' }}>Doors Columns</span></label>
                                        </div>
                                    }
                                </div>
                               
                                <h4 style={{ marginLeft: '25px', marginTop: '25px', color: this.state.download_status_color }}>{this.state.download_status}</h4>
                                {this.state.is_internal_user ?
                                    <div className="col-sm-12 row" style={{ marginTop: '25px', display: 'flex' }}>
                                        <div className='col-sm-4'>
                                            <button
                                                onClick={() => runDownload('Excel')}
                                                className="btn btn-primary"
                                                autoFocus
                                                style={{ margin: '5px' }}
                                            >
                                                Download Data as Excel
                                            </button>
                                            <button
                                                onClick={() => runDownload('Parquet')}
                                                className="btn btn-primary"
                                                autoFocus
                                                style={{ margin: '5px' }}
                                            >
                                                Download Data as Parquet
                                            </button>
                                        </div>
                                        <div className='col-sm-2'>
                                            <button
                                                onClick={() => runDownload('Cloud')}
                                                className="btn btn-primary"
                                                autoFocus
                                                style={{ margin: '5px' }}
                                            >
                                                Upload Data into the Cloud
                                            </button>
                                        </div>
                                        <div className='col-sm-2'>
                                            <Select
                                                inputId="container"
                                                styles={selectColourStyles}
                                                options={this.state.containers}
                                                placeholder={'Azure Container for Upload into Cloud'}
                                                onChange={(item, action) => onChangeContainer(item, action)}
                                                value={this.state.container_chosen}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="col-sm-12 row" style={{ marginTop: '10px', display: 'flex' }}>
                                        <div className="col-sm-12 row" style={{ marginTop: '25px' }}>
                                            <button
                                                onClick={() => runDownload('Excel')}
                                                className="btn btn-primary"
                                                autoFocus
                                                style={{ margin: '5px' }}
                                            >
                                                Download Data as Excel
                                            </button>
                                            <button
                                                onClick={() => runDownload('Parquet')}
                                                className="btn btn-primary"
                                                autoFocus
                                                style={{ margin: '5px' }}
                                            >
                                                Download Data as Parquet
                                            </button>
                                        </div>
                                    </div>
                                    }
                            </div>
                        }
                    </div>
                </div>
            </Layout>
            </ThemeProvider>
        )
    }
}
