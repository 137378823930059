import CreateFilters from '../../Utilities/CreateFilters'
export default function changeSelectedLevel(selectedOptions) {
    let filter_object = this.state.final_filters || this.state.filter_object
    let exclude_array = this.state.exclude_array
    let weeks_info = this.state.weeks_info
    let priceBandOptions = this.state.priceBandOptions
    let filters_totaltimeperiod = CreateFilters(filter_object, exclude_array, weeks_info, 'totaltimeperiod', priceBandOptions)

    this.setState({
        selectedLevel: selectedOptions,
        ALRvsROM_loading: true,
    }, () => this.updateAggregatedDataOutlet(filters_totaltimeperiod)
    )


    
   
}