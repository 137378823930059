import React, { Component, Fragment } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import Grid from '@material-ui/core/Grid'
import { TableWithPagination } from '../TableWithPagination'
import '../styles/custom.css'
import * as Constant from '../constants'

export class PnpDialogs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                {this.props.state.isLoaded === true &&
                    <div>
                        {/* PNP Dataset Loading Completed Successful */}
                        <Dialog
                            open={this.props.state.pnp_loading_successful}
                            onClose={this.props.closeApiLoadingSuccessful}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            style={{ overlay: { zIndex: 1000 } }}
                        >
                            <DialogTitle id="alert-dialog-title">PNP Dataset Loading Successful</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    The PNP Dataset "{this.props.state.pnp_dataset_name_to_load}" loaded successfully after {this.props.state.pnp_loading_duration} seconds.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button onClick={this.props.closeApiLoadingSuccessful} className="btn btn-primary" autoFocus>
                                    Ok
                                </button>
                            </DialogActions>
                        </Dialog>
                        {/* PNP Dataset Loading Failed */}
                        <Dialog
                            open={this.props.state.pnp_loading_failed}
                            onClose={this.props.closeApiLoadingFailed}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            style={{ overlay: { zIndex: 1000 } }}
                        >
                            <DialogTitle id="alert-dialog-title">PNP Dataset Loading Failed</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    PNP Dataset "{this.props.state.pnp_dataset_name_to_load}" loading failed after {this.props.state.pnp_loading_duration} seconds. <br/>
                                    See the logged messages below the "Load Dataset" button to see why the dataset load failed.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button onClick={this.props.closeApiLoadingFailed} className="btn btn-primary" autoFocus>
                                    Ok
                                </button>
                            </DialogActions>
                        </Dialog>

                        {/* Edit PNP Dataset Configuration Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.view_pnp_config}
                            onClose={this.props.hidePnpConfig}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className='row'>
                                    <div style={{ textAlign: 'left', width: '50%' }}><h1>PNP Dataset Configuration</h1></div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.hidePnpConfig}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>Long Description</Grid>
                                    <Grid item xs={10}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigLongDesc"
                                            onChange={this.props.handleChangePnpDataSetConfigLong}
                                            value={this.props.state.pnp_dataset_long_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Short Description</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigShortDesc"
                                            onChange={this.props.handleChangePnpDataSetConfigShort}
                                            value={this.props.state.pnp_dataset_short_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Enabled?</Grid>
                                    <Grid item xs={4}>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                checked={this.props.state.pnp_dataset_is_enabled}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                onChange={this.props.handleChangePnpConfigCheckboxEnabled}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={2}>Table Prefix</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigTablePrefix"
                                            onChange={this.props.handleChangePnpDataSetConfigTablePrefix}
                                            value={this.props.state.pnp_dataset_table_prefix}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Identifier</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigDatasetIdentifier"
                                            onChange={this.props.handleChangePnpDataSetConfigDatasetIdentifier}
                                            value={this.props.state.pnp_dataset_identifier}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Folder</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigDatasetFolder"
                                            onChange={this.props.handleChangePnpDataSetConfigDatasetFolder}
                                            value={this.props.state.pnp_dataset_folder}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Industry</Grid>
                                    <Grid item xs={4}>
                                        <Select
                                            id="datasetconfigIndustry"
                                            onChange={this.props.handleChangePnpDataSetConfigIndustry}
                                            options={this.props.state.industry_options}
                                            placeholder={'Select An Industry'}
                                            value={[{ 'label': this.props.state.industry_lookup[this.props.state.pnp_dataset_industry], 'value': this.props.state.pnp_dataset_industry }]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Last Modified Date/Time</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            disabled
                                            value={this.props.state.config_data.time_updated}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Creator ID</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            disabled
                                            value={this.props.state.config_data.creator_id}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Config ID</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            disabled
                                            value={this.props.state.config_data.dataset_detail_id}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset ID</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            disabled
                                            value={this.props.state.config_data.dataset_id}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                        {/* Delete Config Button here? */}
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitPnpConfig}
                                                disabled={!this.props.state.pnp_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* New PNP Dataset Configuration Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.view_pnp_config_new}
                            onClose={this.props.handleCloseNewPnpConfig}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className='row'>
                                    <div style={{ textAlign: 'left', width: '50%' }}><h1>New PNP Dataset Configuration</h1></div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseNewPnpConfig}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>Long Description</Grid>
                                    <Grid item xs={10}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigLongDesc"
                                            onChange={this.props.handleChangePnpDataSetConfigLong}
                                            value={this.props.state.pnp_dataset_long_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Short Description</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigShortDesc"
                                            onChange={this.props.handleChangePnpDataSetConfigShort}
                                            value={this.props.state.pnp_dataset_short_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Enabled?</Grid>
                                    <Grid item xs={4}>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                checked={this.props.state.pnp_dataset_is_enabled}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                onChange={this.props.handleChangePnpConfigCheckboxEnabled}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={2}>Table Prefix</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigTablePrefix"
                                            onChange={this.props.handleChangePnpDataSetConfigTablePrefix}
                                            value={this.props.state.pnp_dataset_table_prefix}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Identifier</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigDatasetIdentifier"
                                            onChange={this.props.handleChangePnpDataSetConfigDatasetIdentifier}
                                            value={this.props.state.pnp_dataset_identifier}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Folder</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfigDatasetFolder"
                                            onChange={this.props.handleChangePnpDataSetConfigDatasetFolder}
                                            value={this.props.state.datasetfpnp_dataset_folderolder}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Industry</Grid>
                                    <Grid item xs={4}>
                                        <Select
                                            id="datasetconfigIndustry"
                                            onChange={this.props.handleChangePnpDataSetConfigIndustry}
                                            options={this.props.state.industry_options}
                                            placeholder={'Select An Industry'}
                                            value={[{ 'label': this.props.state.industry_lookup[this.props.state.pnp_dataset_industry], 'value': this.props.state.pnp_dataset_industry }]}
                                        />
                                    </Grid>
                                </Grid>

                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                        {/* No delete button for the "new" item */}
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitPnpConfigNew}
                                                disabled={!this.props.state.pnp_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* PNP Dataset Information Modal */}
                        {this.props.state.show_pnp_dataset_info &&
                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={this.props.state.show_pnp_dataset_info}
                                onClose={this.props.handleClosePnpDatasetInfo}
                                style={{ overflowY: 'scroll', overlay: { zIndex: 750 } }}
                            >
                                <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                    <div className="row">
                                        <div style={{ textAlign: 'left', width: '50%' }}>
                                            <h1>PNP Dataset Info</h1>
                                        </div>
                                        <div style={{ textAlign: 'right', width: '50%' }}>
                                            <IconButton onClick={this.props.handleClosePnpDatasetInfo}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </div>
                                        <div className='col-sm-12'>
                                            <h2>Dataset Short Description: {this.props.state.datasetsettings_data.shortdesc}</h2>
                                        </div>
                                        <div className='col-sm-12'>
                                            <h5>Time Ranges From: {(this.props.state.datasettimesettings_data[0] || "").substring(0, 10)} to {(this.props.state.datasettimesettings_data[1] || "").substring(0, 10)}</h5>
                                        </div>
                                        <div className='col-sm-6'>
                                            <h5>Geographies</h5>
                                        </div>
                                        <div className='col-sm-6'>
                                            <h5>Brands</h5>
                                        </div>
                                        <div className='col-sm-6'>
                                            <TableWithPagination
                                                test='This is a test'
                                                length={this.props.state.datasetgeosettings_data.length}
                                                columns={['Geography ID', 'Geography Description']}
                                                data={this.props.state.datasetgeosettings_data.map(geo => { return [geo.geogid, geo.geogdesc] })}
                                            />
                                        </div>
                                        <div className='col-sm-6'>
                                            <TableWithPagination
                                                test='This is a test'
                                                length={this.props.state.datasetbrandsettings_data.length}
                                                columns={['Brand Name']}
                                                data={this.props.state.datasetbrandsettings_data.map(brand => { return [brand] })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <h5>Attributes</h5>
                                    </div>
                                    <div className='col-sm-12'>
                                        <TableWithPagination
                                            test='This is a test'
                                            length={this.props.state.datasetattrsettings_data.length}
                                            columns={['Attribute Column Name', 'Attribute Human Readable Label', 'Attribute Values']}
                                            data={this.props.state.datasetattrsettings_data.map(attr => { return [attr.attribute, this.props.state.pnp_attr_lookup[attr.attribute], attr.attribute_values.join(',')] })}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
                }
            </Fragment>
        )
    }
}
