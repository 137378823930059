import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateOutletBrandWeeklyData(filters, timeperiod) {
    let filters_outlet_brand_weekly = assignFilterLevels(filters, ['outlet', 'brand', 'rowdate'])
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level3selection = Constant.baseapiurl + "ReportData/Filtered/Level3Data/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level3selection, 'method': 'POST', 'body': filters_outlet_brand_weekly }
    ], 'UpdateOutletWeeklyBrand.js updateOutletBrandWeeklyData()', 'Outlet Weekly Brand')
    let outlet_brand_weeklydata_data = my_results['ReturnList'][0]
    let outlet_brand_weeklydata_obj = outlet_brand_weeklydata_data['level3_data'] || {}

    if (timeperiod === 'totaltimeperiod') {
        this.setState({ outlet_data_weekly_brand: outlet_brand_weeklydata_obj })
    } else if (timeperiod === 'currentyear') {
        this.setState({ outlet_data_weekly_brand: outlet_brand_weeklydata_obj })
    } else if (timeperiod === 'threeyearsago') {
        this.setState({ outlet_data_weekly_brand_threeyears: outlet_brand_weeklydata_obj })
    } else if (timeperiod === 'twoyearsago') {
        this.setState({ outlet_data_weekly_brand_last: outlet_brand_weeklydata_obj })
    } else if (timeperiod === 'prioryear') {
        this.setState({ outlet_data_weekly_brand_prior: outlet_brand_weeklydata_obj })
    }
}
