export default function calc_metrics_base_new(
	  simulation_results
	, filtered_items = null
	, selected_item = this.state.selected_item
	, selected_brands = this.state.selected_brands
	, selected_itemgroup = this.state.selected_itemgroup
) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    //Use the ProdIds from the simsettings items
	let prodids = this.state.simsettings.items.map(item => item.prod_id)

    //If specified filtered_items then use them
    if (filtered_items) {
        prodids = filtered_items
    }
    //Vars for calculations
    let simitems = this.state.simsettings.items.map(item => item.prod_id)
    let numweeks = this.state.simsettings.numweeks
    let coverage = this.state.simsettings.coverage
    let basecalcresultasp = this.state.basecalcresultasp	
	let simsettings = this.state.simsettings
    //Styling
    var showLabelsBrand = true
    var showLabelsItem = true
    var showLabelsItemGroup = true
    var waterfall_categories = [
        "Current Base " + this.state.unitsvar
        , "Price Change"
        , "Internal Competitors"
        , "External Competitors"
        , "Simulated Base " + this.state.unitsvar
    ]

    //Objects and Arrays for State

	var starting_base_volumes = []
	var base_vol_changes = []
	var starting_base_dollars = []
	var new_base_dollars = []
	var diff_base_dollars = []
	var gross_revenues = []
	var incremental_profits = []
	var self_price_vol_changes = []
	var self_promo_vol_changes = []
	var self_total_vol_changes = []
	var total_cross_vol_changes = []
	var external_cross_vol_changes = []
	var internal_cross_vol_changes = []

	//Used for brand and item views
	var total_volume_changes_brand = {}
	var total_volume_changes_item = {}
	var total_volume_changes_itemgroup = {}

	var starting_volume_brand = {}
	var starting_volume_item = {}
	var starting_volume_itemgroup = {}

	let start_total_dollars_brand = {}
	let start_total_dollars_item = {}
	let start_total_dollars_itemgroup = {}

	let diff_total_dollars_brand = {}
	let diff_total_dollars_item = {}
	let diff_total_dollars_itemgroup = {}

	let incremental_profit_brand = {}
	let incremental_profit_item = {}
	let incremental_profit_itemgroup = {}

	var total_volume_change_brand = 0
	var total_volume_change_item = 0
	var total_volume_change_itemgroup = 0

	var starting_base_volume_brand = 0
	var starting_base_volume_item = 0
	var starting_base_volume_itemgroup = 0

	var diff_total_dollar_brand = 0
	var diff_total_dollar_item = 0
	var diff_total_dollar_itemgroup = 0

	var old_total_dollar_brand = 0
	var old_total_dollar_item = 0
	var old_total_dollar_itemgroup = 0

	var incremental_profit_total_brand = 0
	var incremental_profit_total_item = 0
	var incremental_profit_total_itemgroup = 0

	var waterfall_data_brand = {}
	var waterfall_data_item = {}
	var waterfall_data_itemgroup = {}

	var brand_waterfall_effects = {}
	var item_waterfall_effects = {}
	var itemgroup_waterfall_effects = {}

	//var waterfall_data_brand_graph = []
	//var waterfall_data_item_graph = []

	//Comp Data
	let competitor_data = {}
	let competitor_graph_data_items = []
	let competitor_graph_data_price = []
	let competitor_graph_data_promo = []
	let competitor_graph_data_total = []
	let competitor_graph_data_price_pct = []
	let competitor_graph_data_promo_pct = []
	let competitor_graph_data_total_pct = []

	var comp_item_changes_brand = {}
	var comp_item_changes_item = {}

	var comp_item_changes_brand_dollars = {}
	var comp_item_changes_item_dollars = {}

    //Iterate Over each prodid
	prodids.forEach((prodid, idx) => {
		// The back-end identifies items that we won't find in the simulation outputs that are configured in the sim
		if (simulation_results['missing_ids'] !== undefined && simulation_results['missing_ids'].includes(parseInt(prodid))) {
			console.log("ProdID " + prodid + " has no sales and is probably a stale item no longer in the dataset -- skipping it")
			return
		}

		let comp_items = []
		let comp_price_vol_change = []
		let comp_promo_vol_change = []
		let comp_total_vol_change = []
		let comp_price_vol_change_pct = []
		let comp_promo_vol_change_pct = []
		let comp_total_vol_change_pct = []
		let item = {}
		var adj_base_volume = 0
		var simulated_change_factor = 0
		var selfpricevolchange = 0
		var selftotalvolchange = 0
		var totalcrosschange = 0
		var external_crosschange = 0
		var internal_crosschange = 0
		var item_brand = ""

		let oldbp = basecalcresultasp[prodid] || 0
		
		//console.log(this.state.simsettings.items[idx]['override_base_flag'])
		if ((simsettings.items[idx] || {})['override_base_flag']) {
			oldbp = simsettings.items[idx]['override_base_price']
		}
		
		simsettings.items.forEach(item_obj => { if (item_obj.prod_id === prodid) { item = item_obj } })

		let newbp = item.sim_base_price || 0
		let profit_margin = (item.gross_margin / 100) || 0

		if (simulation_results[prodid]) {
			let starting_base_volume = simulation_results[prodid]['starting_base_volume']
			let covid_base_volume = simulation_results[prodid]['covid_base_volume']
			let holiday_base_volume = simulation_results[prodid]['holiday_base_volume']
			let cross_items = simulation_results[prodid]['cross_items']
			let total_effect = simulation_results[prodid]['total']
			let total_cross = simulation_results[prodid]['total_cross']

			item_brand = simulation_results[prodid]['brand']
			adj_base_volume = starting_base_volume + covid_base_volume + holiday_base_volume
			selfpricevolchange = simulation_results[prodid]['self_price']
			selftotalvolchange = simulation_results[prodid]['self_total']

			totalcrosschange = [0].concat(cross_items.map(item => {
				let useitem = simitems.includes(parseInt(item))
				if (useitem) {
					return simulation_results[prodid]['cross'][item]['cross_total']
				} else {
					return 0
				}
			})).reduce(reducer)

			external_crosschange = [0].concat(cross_items.map(item => {
				let useitem = simitems.includes(parseInt(item))
				if (useitem && !this.state.simsettings['sim_brands'].includes(simulation_results[prodid]['cross'][item]['brand'])) {
					return simulation_results[prodid]['cross'][item]['cross_total']
				} else {
					return 0
				}
			})).reduce(reducer)

			internal_crosschange = [0].concat(cross_items.map(item => {
				let useitem = simitems.includes(parseInt(item))
				if (useitem && this.state.simsettings['sim_brands'].includes(simulation_results[prodid]['cross'][item]['brand'])) {
					return simulation_results[prodid]['cross'][item]['cross_total']
				} else {
					return 0
				}
			})).reduce(reducer)

			simulated_change_factor = total_effect - total_cross + totalcrosschange

			if (!this.state.itembrandlookup) {
				//Wait 3 seconds
				setTimeout(() => { console.log('Waited 3 seconds') }, 3000)
			}

			cross_items.forEach(compitem => {				
				let itemprice = simulation_results[compitem]['avgbp'] || 0
				let item_starting_base_volume = adj_base_volume
				let useCompItem = simitems.includes(parseInt(compitem))

				let compObj = simulation_results[prodid]['cross'][compitem]
				let comp_cross_price = compObj['cross_price']
				let comp_cross_promo = compObj['cross_promo']
				let comp_cross_total = compObj['cross_total']

				let comp_price_vol_change_val = item_starting_base_volume * comp_cross_price * coverage
				let comp_promo_vol_change_val = item_starting_base_volume * comp_cross_promo * coverage
				let comp_total_vol_change_val = item_starting_base_volume * comp_cross_total * numweeks * -1 * coverage

				let itembrand = this.state.itembrandlookup[compitem] || this.state.simulation_results[compitem]['brand']

				if (useCompItem) {
					comp_items.push(compitem)
					comp_price_vol_change.push(comp_price_vol_change_val)
					comp_promo_vol_change.push(comp_promo_vol_change_val)
					comp_total_vol_change.push(comp_total_vol_change_val)
					comp_price_vol_change_pct.push((comp_price_vol_change_val / item_starting_base_volume))
					comp_promo_vol_change_pct.push((comp_promo_vol_change_val / item_starting_base_volume))
					comp_total_vol_change_pct.push((comp_total_vol_change_val / item_starting_base_volume))
					competitor_data[prodid] = [comp_items, comp_price_vol_change, comp_promo_vol_change, comp_total_vol_change, comp_price_vol_change_pct, comp_promo_vol_change_pct, comp_total_vol_change_pct]
				}
				//brandlevel
				comp_item_changes_brand[itembrand] = 0
				// comp_item_changes_brand[itembrand] = comp_item_changes_brand[itembrand] || 0
				// comp_item_changes_brand[itembrand] += comp_total_vol_change_val
				//Dollars
				comp_item_changes_brand_dollars[itembrand] = 0
				// comp_item_changes_brand_dollars[itembrand] = comp_item_changes_brand_dollars[itembrand] || 0
				// comp_item_changes_brand_dollars[itembrand] += comp_total_vol_change_val * itemprice
				//itemlevel
				comp_item_changes_item[compitem] = 0
				// comp_item_changes_item[compitem] = comp_item_changes_item[compitem] || 0
				// comp_item_changes_item[compitem] += comp_total_vol_change_val
				//Dollars
				comp_item_changes_item_dollars[compitem] = 0
				// comp_item_changes_item_dollars[compitem] = comp_item_changes_item_dollars[compitem] || 0
				// comp_item_changes_item_dollars[compitem] += comp_total_vol_change_val * itemprice
			})
		} else {
			adj_base_volume = 0
			simulated_change_factor = 0
			selfpricevolchange = 0
			selftotalvolchange = 0
			totalcrosschange = 0
			item_brand = ""
		}

		//console.log(prodid, item_brand, simulated_change_factor, totalcrosschange )

		let oldbasevol = adj_base_volume
		let newbasevol = adj_base_volume * (1 + simulated_change_factor)

		let self_price_vol_change = adj_base_volume * selfpricevolchange * coverage * numweeks
		let self_promo_vol_change = 0
		let self_total_vol_change = adj_base_volume * selftotalvolchange * coverage * numweeks
		let total_cross_vol_change = adj_base_volume * totalcrosschange * coverage * numweeks
		let external_cross_vol_change = adj_base_volume * external_crosschange * coverage * numweeks
		let internal_cross_vol_change = adj_base_volume * internal_crosschange * coverage * numweeks
		let starting_base_volume = adj_base_volume * numweeks
		let base_vol_change = (adj_base_volume * simulated_change_factor) * numweeks			
		let starting_base_dollar = (oldbasevol * numweeks * oldbp)
		let new_base_dollar = (newbasevol * numweeks * newbp)
		let diff_base_dollar = new_base_dollar - starting_base_dollar
		let incremental_profit = new_base_dollar * profit_margin

		self_price_vol_changes.push(self_price_vol_change)
		self_promo_vol_changes.push(self_promo_vol_change)
		self_total_vol_changes.push(self_total_vol_change)
		total_cross_vol_changes.push(total_cross_vol_change)
		external_cross_vol_changes.push(external_cross_vol_change)
		internal_cross_vol_changes.push(internal_cross_vol_change)
		new_base_dollars.push(new_base_dollar)
		gross_revenues.push(new_base_dollar)

		//Waterfall by brand
		if (Object.keys(brand_waterfall_effects).includes(item_brand)) {
			brand_waterfall_effects[item_brand][0] += starting_base_volume
			brand_waterfall_effects[item_brand][1] += self_price_vol_change
			brand_waterfall_effects[item_brand][2] += total_cross_vol_change
			brand_waterfall_effects[item_brand][3] += internal_cross_vol_change //Internal Competitive Effects
			brand_waterfall_effects[item_brand][4] += external_cross_vol_change //External Competitive Effects
		} else {
			brand_waterfall_effects[item_brand] = [
				starting_base_volume
				, self_price_vol_change
				, total_cross_vol_change
				, internal_cross_vol_change
				, external_cross_vol_change
			]
		}

		//Waterfall by itemgroup
		this.state.selectedgroups.forEach(group => {
			let groupname = group.groupname
			let groupitems = group.item_vals
			if (groupitems.includes(prodid)) {
				if (Object.keys(itemgroup_waterfall_effects).includes(groupname)) {
					itemgroup_waterfall_effects[groupname][0] += starting_base_volume
					itemgroup_waterfall_effects[groupname][1] += self_price_vol_change
					itemgroup_waterfall_effects[groupname][2] += total_cross_vol_change
					itemgroup_waterfall_effects[groupname][3] += internal_cross_vol_change //Internal Competitive Effects
					itemgroup_waterfall_effects[groupname][4] += external_cross_vol_change //External Competitive Effects
				} else {
					itemgroup_waterfall_effects[groupname] = [
						starting_base_volume
						, self_price_vol_change
						, total_cross_vol_change
						, internal_cross_vol_change
						, external_cross_vol_change
					]
				}
			}
		})

		//Waterfall by item
		//console.log(prodid, starting_base_volume)
		if (Object.keys(item_waterfall_effects).includes(prodid)) {
			item_waterfall_effects[prodid][0] += starting_base_volume
			item_waterfall_effects[prodid][1] += self_price_vol_change
			item_waterfall_effects[prodid][2] += total_cross_vol_change
			item_waterfall_effects[prodid][3] += internal_cross_vol_change //Internal Competitive Effects
			item_waterfall_effects[prodid][4] += external_cross_vol_change //External Competitive Effects
		} else {
			item_waterfall_effects[prodid] = [
				starting_base_volume
				, self_price_vol_change
				, total_cross_vol_change
				, internal_cross_vol_change
				, external_cross_vol_change
			]
		}

		//console.log(item_waterfall_effects)

		let add_to_all_sums = (total_summer, item_summer, brand_summer, itemgroup_summer, value_to_add) => {
			// Totals
			if (total_summer !== null) {
				total_summer.push(value_to_add)
			}

			// Brand
			if (!Object.keys(brand_summer).includes(item_brand)) {
				brand_summer[item_brand] = 0
			}
			brand_summer[item_brand] += value_to_add

			// Item
			if (!Object.keys(item_summer).includes(prodid)) {
				item_summer[prodid] = 0
			}
			item_summer[prodid] += value_to_add

			// Item Group
			this.state.selectedgroups.forEach(group => {
				let groupname = group.groupname
				let groupitems = group.item_vals
				if (groupitems.includes(prodid)) {
					if (!Object.keys(itemgroup_summer).includes(groupname)) {
						itemgroup_summer[groupname] = 0
					}
					itemgroup_summer[groupname] += value_to_add
				}
			})
		}

		add_to_all_sums(starting_base_volumes, starting_volume_item, starting_volume_brand, starting_volume_itemgroup, starting_base_volume)
		add_to_all_sums(base_vol_changes, total_volume_changes_item, total_volume_changes_brand, total_volume_changes_itemgroup, base_vol_change)
		add_to_all_sums(starting_base_dollars, start_total_dollars_item, start_total_dollars_brand, start_total_dollars_itemgroup, starting_base_dollar)
		add_to_all_sums(diff_base_dollars, diff_total_dollars_item, diff_total_dollars_brand, diff_total_dollars_itemgroup, diff_base_dollar)
		add_to_all_sums(incremental_profits, incremental_profit_item, incremental_profit_brand, incremental_profit_itemgroup, incremental_profit)

		total_volume_change_brand = total_volume_changes_brand[selected_brands]
		total_volume_change_item = total_volume_changes_item[selected_item]
		total_volume_change_itemgroup = total_volume_changes_itemgroup[selected_itemgroup]

		starting_base_volume_brand = starting_volume_brand[selected_brands]
		starting_base_volume_item = starting_volume_item[selected_item]
		starting_base_volume_itemgroup = starting_volume_itemgroup[selected_itemgroup]

		old_total_dollar_brand = start_total_dollars_brand[selected_brands]
		old_total_dollar_item = start_total_dollars_item[selected_item]
		old_total_dollar_itemgroup = start_total_dollars_itemgroup[selected_itemgroup]

		diff_total_dollar_brand = diff_total_dollars_brand[selected_brands]
		diff_total_dollar_item = diff_total_dollars_item[selected_item]
		diff_total_dollar_itemgroup = diff_total_dollars_itemgroup[selected_itemgroup]

		incremental_profit_total_brand = incremental_profit_brand[selected_brands]
		incremental_profit_total_item = incremental_profit_item[selected_item]
		incremental_profit_total_itemgroup = incremental_profit_itemgroup[selected_itemgroup]

		try {
			competitor_graph_data_items = competitor_data[selected_item][0]
			competitor_graph_data_price = competitor_data[selected_item][1]
			competitor_graph_data_promo = competitor_data[selected_item][2]
			competitor_graph_data_total = competitor_data[selected_item][3]

			competitor_graph_data_price_pct = competitor_data[selected_item][4]
			competitor_graph_data_promo_pct = competitor_data[selected_item][5]
			competitor_graph_data_total_pct = competitor_data[selected_item][6]
		} catch (error) {
			competitor_graph_data_items = []
			competitor_graph_data_price = []
			competitor_graph_data_promo = []
			competitor_graph_data_total = []

			competitor_graph_data_price_pct = []
			competitor_graph_data_promo_pct = []
			competitor_graph_data_total_pct = []
		}
		 
		var price_color = (self_price_vol_change < 0) ? '#7d32ba' : "#ff8b6a"
		var internal_color = (internal_crosschange < 0) ? '#7d32ba' : "#ff8b6a"
		var external_color = (external_crosschange < 0) ? '#7d32ba' : "#ff8b6a"

		waterfall_data_item[prodid] =
			[
				{
					name: 'Base ' + this.state.unitsvar,
					y: base_vol_change
				},
				{
					name: 'Self Price',
					y: self_price_vol_change,
					color: price_color
				},
				{
					name: 'Internal Cross Effects',
					y: internal_cross_vol_change,
					color: internal_color
				},
				{
					name: 'External Cross Effects',
					y: external_cross_vol_change,
					color: external_color
				},
				{
					name: 'Total Change',
					isIntermediateSum: true,
				}
			]
	})

	if (Object.keys(starting_volume_brand).length < 6) {
		showLabelsBrand = true
	} else {
		showLabelsBrand = false
	}

	if (Object.keys(starting_volume_item).length < 6) {
		showLabelsItem = true
	} else {
		showLabelsItem = false
	}

	//Get the total comp_changes...
	//Only include the comp changes for the All Other Items...
	let comp_item_changes_item_total = [0].concat(prodids.map(id => {
		return comp_item_changes_item[id] || 0
	})).reduce(reducer)

	console.log('***** comp_item_changes_item_total value is: ' + comp_item_changes_item_total + '; Setting comp_item_changes_item_total to 0')
	comp_item_changes_item_total = 0.

	let starting_base_volume_total = starting_base_volumes.reduce(reducer)
	let base_volume_change_total = base_vol_changes.reduce(reducer) + comp_item_changes_item_total

	let starting_base_dollars_total = starting_base_dollars.reduce(reducer)
	let new_base_dollars_total = new_base_dollars.reduce(reducer)
	let diff_base_dollars_total = diff_base_dollars.reduce(reducer)
	let gross_revenue_total = gross_revenues.reduce(reducer)
	let incremental_profit_total = incremental_profits.reduce(reducer)
	let self_price_vol_change = self_price_vol_changes.reduce(reducer)
	let self_promo_vol_change = self_promo_vol_changes.reduce(reducer)
	let total_cross_vol_change = total_cross_vol_changes.reduce(reducer)
	let internal_cross_vol_change = internal_cross_vol_changes.reduce(reducer)
	let external_cross_vol_change = external_cross_vol_changes.reduce(reducer) + comp_item_changes_item_total

	var price_color = (self_price_vol_change > 0) ? '#ff8b6a' : '#7d32ba'
	var internal_color = (internal_cross_vol_change > 0) ? '#ff8b6a' : '#7d32ba'
	var external_color = (external_cross_vol_change > 0) ? '#ff8b6a' : '#7d32ba'

	var waterfall_data =
		[
			{
				name: 'Base ' + this.state.unitsvar,
				y: starting_base_volume_total
			},
			{
				name: 'Self Price',
				y: self_price_vol_change,
				color: price_color
			},
			{
				name: 'Internal Cross Effects',
				y: internal_cross_vol_change,
				color: internal_color
			},
			{
				name: 'External Cross Effects',
				y: external_cross_vol_change,
				color: external_color
			},
			{
				name: 'Total Change',
				isIntermediateSum: true,
			}
		]

	//console.log('Changing Data')
	this.state.brand_options.map(brand =>
		waterfall_data_brand[brand.value] =
		[
			{
				name: 'Base ' + this.state.unitsvar,
				y: brand_waterfall_effects[brand.value][0]
			},
			{
				name: 'Self Price',
				y: brand_waterfall_effects[brand.value][1],
				color: price_color
			},
			{
				name: 'Internal Cross Effects',
				y: brand_waterfall_effects[brand.value][3],
				color: internal_color
			},
			{
				name: 'External Cross Effects',
				y: brand_waterfall_effects[brand.value][4],
				color: external_color
			},
			{
				name: 'Total Change',
				isIntermediateSum: true,
			}
		]
	)

	this.state.itemgroup_options.map(itemgroup =>
		waterfall_data_itemgroup[itemgroup.value] =
		[
			{
				name: 'Base ' + this.state.unitsvar,
				y: itemgroup_waterfall_effects[itemgroup.value][0]
			},
			{
				name: 'Self Price',
				y: itemgroup_waterfall_effects[itemgroup.value][1],
				color: price_color
			},
			{
				name: 'Internal Cross Effects',
				y: itemgroup_waterfall_effects[itemgroup.value][3],
				color: internal_color
			},
			{
				name: 'External Cross Effects',
				y: itemgroup_waterfall_effects[itemgroup.value][4],
				color: external_color
			},
			{
				name: 'Total Change',
				isIntermediateSum: true,
			}
		]
	)

	this.setState({
		unitChangeType: 'Change in Base ' + this.state.unitsvar,
		dollarChangeType: 'Change in Base ' + this.state.currencytype,
		unitPctChangeType: '% Change in Base Units',
		dollarPctChangeType: '% Change in Base ' + this.state.currencytype,
		totalCatVoltype: 'Simulated Base ' + this.state.unitsvar,
		totalCatDoltype: 'Simulated Base ' + this.state.currencytype,
		starting_base_volumes,
		base_vol_changes,
		starting_base_dollars,
		new_base_dollars,
		diff_base_dollars,
		gross_revenues,
		incremental_profit_total,
		starting_base_volume_total,
		base_volume_change_total,
		starting_base_dollars_total,
		new_base_dollars_total,
		diff_base_dollars_total,
		gross_revenue_total,
		waterfall_data,
		waterfall_categories,
		display_base: '',
		display_promo: 'none',
		total_volume_changes_brand,
		total_volume_changes_item,
		starting_volume_brand,
		starting_volume_item,
		diff_total_dollars_brand,
		diff_total_dollars_item,
		start_total_dollars_brand,
		start_total_dollars_item,
		showLabelsBrand,
		showLabelsItem,
		waterfall_data_brand,
		waterfall_data_item,
		waterfall_data_itemgroup,
		brand_waterfall_effects,
		item_waterfall_effects,
		total_volume_change_brand,
		total_volume_change_item,
		starting_base_volume_brand,
		starting_base_volume_item,
		diff_total_dollar_brand,
		diff_total_dollar_item,
		old_total_dollar_brand,
		old_total_dollar_item,
		incremental_profit_total_brand,
		incremental_profit_brand,
		incremental_profit_total_item,
		incremental_profit_item,
		total_volume_changes_itemgroup,
		starting_volume_itemgroup,
		diff_total_dollars_itemgroup,
		start_total_dollars_itemgroup,
		showLabelsItemGroup,
		waterfall_data_itemgroup,
		itemgroup_waterfall_effects,
		total_volume_change_itemgroup,
		starting_base_volume_itemgroup,
		diff_total_dollar_itemgroup,
		old_total_dollar_itemgroup,
		incremental_profit_total_itemgroup,
		incremental_profit_itemgroup,
		competitor_data,
		competitor_graph_data_items,
		competitor_graph_data_price,
		competitor_graph_data_promo,
		competitor_graph_data_total,
		competitor_graph_data_price_pct,
		competitor_graph_data_promo_pct,
		competitor_graph_data_total_pct,
		comp_item_changes_brand,
		comp_item_changes_item,
		comp_item_changes_brand_dollars,
		comp_item_changes_item_dollars,
		showBackground: false,
		//rollup_options
	})
}
