import React, { Component } from 'react';
import { LastYearSwitch } from '../../UIElements/Switches/LastYearSwitch'
import { SideBySideSwitch } from '../../UIElements/Switches/SideBySideSwitch'
import { DiscountRangeYOYGraph } from '../../../Graphs/DiscountRangeYOYGraph'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';

export class DiscountRangeYOY extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        return (

            <div style={{ width: "100%", height: "100%" }}>
                <div className="row">
                    <div className="col-sm-3" align='left'>
                        <SideBySideSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchYOY={this.props.handleChange_IndexSwitchYOY}
                        />
                    </div>

                    <div className="col-sm-6" align='left'>
                        <LastYearSwitch
                            state={this.props.state}
                            handleChange_twoyago={this.props.handleChange_twoyago}
                        />
                    </div>                                                            
                </div>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <DiscountRangeYOYGraph
                            state={this.props.state}
                            app = 'PDT'
                        />
                    </div>
                </div>
            </div>
        )
    }
}