import React, { Fragment } from 'react';
import * as Constant from '../constants';

const ScorecardWithoutYOYPricingMetrics = props => {
    console.log(props)
    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'
    var appname = props.appname

    var base_units_curr = props.base_units_curr  
    var inc_units_curr = props.inc_units_curr
    var total_units_curr = base_units_curr + inc_units_curr
    var nonpromo_units_curr = props.nonpromo_units_curr
    var promo_units_curr = props.promo_units_curr

    var avg_base_price_curr = props.avg_base_price_curr
    var avg_disc_curr = props.avg_disc_curr
    var avg_disc_withoutno_curr = props.avg_disc_withoutno_curr
    var disc_dollars_curr = props.disc_dollars_curr

    var total_dollars_curr = props.total_dollars_curr
    var nonpromoted_dollars_curr = props.nonpromoted_dollars_curr
    var promoted_dollars_curr = props.promoted_dollars_curr

    var avg_promo_price_curr, avg_selling_price_curr
    if (appname === 'pdt') {
        avg_promo_price_curr = promoted_dollars_curr / promo_units_curr
    }

    else {
        avg_promo_price_curr = props.avg_promo_price_curr
    }

    var avg_nonpromo_price_curr = nonpromoted_dollars_curr / nonpromo_units_curr

    if (appname === 'pdt') {
        avg_selling_price_curr = total_dollars_curr / total_units_curr
    }
    else {
        avg_selling_price_curr = props.avg_promo_price_curr
    }

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>Pricing Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of Units Identified as Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Base Price <span style={{ color: '#FFFFFF' }}> UN </span></h5>
                                <p>{(avg_base_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Average Selling Price of All Units Sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Selling Price <span style={{ color: '#FFFFFF' }}> Un </span></h5>
                                <p>{(avg_selling_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Promo Price <span style={{ color: '#FFFFFF' }}> Un </span></h5>
                                <p>{(avg_promo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as not being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Non-Promo Price</h5>
                                <p>{(avg_nonpromo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across all units sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Discount <span style={{ color: '#FFFFFF' }}> Gettonext </span></h5>
                                <p>{(avg_disc_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across units identified as being on promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Promoted Discount</h5>
                                <p>{(avg_disc_withoutno_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The total value of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the actual price of a promoted product and multiplying by Promoted Unit sales.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Discount ' + currencytype}</h5>
                                <p>{(disc_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
            :
            <Fragment>
                <h3>Pricing Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of Units Identified as Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Base Price</h5>
                            <p>{(avg_base_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Promo Price</h5>
                            <p>{(avg_promo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across all units sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Discount</h5>
                            <p>{(avg_disc_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The total value of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the actual price of a promoted product and multiplying by Promoted Unit sales.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Discount ' + currencytype}</h5>
                            <p>{(disc_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                        </div>
                    </Constant.HTMLTooltip>

                </div>
            </Fragment>
    )
}

export default ScorecardWithoutYOYPricingMetrics;