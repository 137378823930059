export default async function changeViewFilters(selectedOptions, key, viewtype) {

    //Run the New APIs
    console.log('Running New APIs')

    //define filter_view_all

    console.log(selectedOptions)
    console.log(key)
    console.log(viewtype)

    const all_value = [{ 'label': 'All', 'value': 'All' }]

    let filter_view_all = {
        'brand': all_value,
        'item': all_value,
        'priceband': all_value,
        'outlet': all_value,
        'channel': all_value,
        'segment1': all_value,
        'segment2': all_value,
        'segment3': all_value,
        'segment4': all_value,
    }    

    //Add each attribute
    Object.keys(this.state.customAttrGroups).forEach(attribute => { filter_view_all[attribute] = all_value })

    let filter_view_selected = this.state.filter_view_selected || filter_view_all

    //correct the selected options
    var corrected_selected_options = []
    if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach(option => {
            if (Array.isArray(option.value)) {
                option.value.forEach(val => {
                    corrected_selected_options.push({ 'label': val, 'value': val })
                })
            }
            else {
                corrected_selected_options.push(option)
            }
        })
    }




    if (corrected_selected_options && corrected_selected_options.length > 0) {

        //Perform some special handling for All...

        let selectedOptions_vals = corrected_selected_options.map(option => option.value)

        //see if 'All' is in values....
        if (selectedOptions_vals.includes('All')) {
            //If in first position, remove it, and take the rest of the options...
            if (selectedOptions_vals[0] === 'All') {
                corrected_selected_options = corrected_selected_options.splice(1)
            }
            else {
                corrected_selected_options = all_value
            }
        }

        filter_view_selected[key] = corrected_selected_options
    }

    else {
        filter_view_selected[key] = all_value
    }

    
    //Call the changeSelectedLevel_BaseInc function
    this.setState({
        filter_view_selected,
        isLoaded_Filters: false,
    }, async () => {
        this.updateReportViews(viewtype)
    })

    
}