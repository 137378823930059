import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { reducer } from '../PDT/Functions/Utilities/Reducer';

export class MetricsChangesWithMktShareGraph extends Component {

    render() {        

        let current_year_ending = this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1]
        let prior_year_ending = this.props.state.distinctdates_prior[this.props.state.distinctdates_prior.length - 1]
        let twoyear_year_ending = this.props.state.distinctdates_last[this.props.state.distinctdates_last.length - 1]
        let threeyear_year_ending = this.props.state.distinctdates_threeyears[this.props.state.distinctdates_threeyears.length - 1]

        function formatDate(datestring) {
            let date = Date.UTC(parseInt(datestring.substring(0, 4)), parseInt(datestring.substring(5, 7)) - 1, parseInt(datestring.substring(8, 10)), 0, 0, 0, 0)
            let dt = new Date(date)
            let offset = dt.getTimezoneOffset() * 60 * 1000
            var dateformatted = (new Date(date + offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
            return dateformatted
        }

        let current_year_ending_string = formatDate(current_year_ending)
        let prior_year_ending_string = formatDate(prior_year_ending)
        let twoyear_year_ending_string = formatDate(twoyear_year_ending)
        let threeyear_year_ending_string = formatDate(threeyear_year_ending)

        let numweeks_curr = this.props.state.distinctdates_forcalc.length
        let numweeks_prior = this.props.state.distinctdates_prior.length
        let numweeks_twoyear = this.props.state.distinctdates_last.length
        let numweeks_threeyear = this.props.state.distinctdates_threeyears.length

        var title_string

        if (this.props.state.ThreeYAGO) {
            title_string = '<br>'
                + this.props.state.chart_title_info.split('<br>')[1]
                + '<br>'
                + numweeks_curr
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + current_year_ending_string
                + ' Compared To '
                + numweeks_threeyear
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + threeyear_year_ending_string

                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[3]
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[4]
        }

        else if (this.props.state.TwoYAGO) {
            title_string = '<br>'
                + this.props.state.chart_title_info.split('<br>')[1]
                + '<br>'
                + numweeks_curr
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + current_year_ending_string
                + ' Compared To '
                + numweeks_twoyear
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + twoyear_year_ending_string

                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[3]
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[4]
        }

        else if (this.props.state.OneYAGO) {
            title_string = '<br>'
                + this.props.state.chart_title_info.split('<br>')[1]
                + '<br>'
                + numweeks_curr
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + current_year_ending_string
                + ' Compared To '
                + numweeks_prior
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + prior_year_ending_string

                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[3]
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[4]
        }

        
        //Create Variables to Create the Charts
        var title, aggregation, units, units_type,
            vals_to_map, dataset_to_use, dataset_to_use_prior, dates_to_use_prior, dates_to_use,
            metric_val, series_var_change, mkt_share,
            yaxis_change, datalabels, tooltip_change, level_for_share


        dates_to_use = this.props.state.distinctdates_forcalc

        if (this.props.state.ThreeYAGO) {
            dates_to_use_prior = this.props.state.distinctdates_threeyears
        }

        else if (this.props.state.TwoYAGO) {
            dates_to_use_prior = this.props.state.distinctdates_last
        }

        else {
            dates_to_use_prior = this.props.state.distinctdates_prior
        }

        if (this.props.state.channelviews) {
            aggregation = 'Channel'            
            vals_to_map = this.props.state.selectedChannels
            dataset_to_use = this.props.state.outlet_data_weekly_geo
            level_for_share = 'All Channels'

           if (this.props.state.ThreeYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_geo_threeyears
            }

           else if (this.props.state.TwoYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_geo_last
            }

           else {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_geo_prior
            }

        }

        else {
            aggregation = 'Brand'            
            vals_to_map = this.props.state.selectedBrands
            dataset_to_use = this.props.state.outlet_data_weekly_brand
            level_for_share = 'Total Category'

            if (this.props.state.ThreeYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_brand_threeyears
            }

            else if (this.props.state.TwoYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_brand_last
            }

            else {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_brand_prior
            }
        }

        if (this.props.state.indexSwitchCheckDollars) {
            units = this.props.state.currencyType
            metric_val = 'dollars'
        }
        else {
            units = this.props.state.unitsvar
            metric_val = 'units'
        }

        if (this.props.state.promoselected) {
            units_type = 'Promoted and Non-Promoted'            
        }

        else if (this.props.state.baseselected) {
            units_type = 'Base and Incremental'            
        }

        if (this.props.state.use_market_share) {
            mkt_share = ' Market Share'
        }

        else {
            mkt_share = ""
        }

        title = units_type + ' ' + units + mkt_share + ' by ' + aggregation + title_string



        //create series        
        series_var_change = []

        //Let Metrics to Use be the full list...
        var metrics_to_use = []
        if (this.props.state.promoselected) {
            metrics_to_use = ['total', 'nonpromoted', 'promoted']
        }
        else {
            metrics_to_use = ['total', 'base', 'incremental' ]
        }
        let curr_series_data = []
        let change_series_data = []
        metrics_to_use.forEach((metric,idx) => {
            let metric_to_use = metric + metric_val
            let metricname = metric.substring(0, 1).toUpperCase() + metric.substring(1)

            if (this.props.state.use_market_share) {
                let vals = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                let mkt = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))

                curr_series_data = vals.map((val, idx) => val / mkt[idx])
            }
            else {
                curr_series_data = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
            }


            if (this.props.state.use_market_share) {
                let vals = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                let mkt = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[0]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                let prior_series_data = vals.map((val, idx) => val / mkt[idx])

                change_series_data = vals.map((val, idx) => (curr_series_data[idx] - prior_series_data[idx]) * 100)
            }
            else {
                change_series_data = vals_to_map.map(val => {
                    let prior = dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer)
                    let current = dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer)
                    let diff = current - prior
                    let pctdiff = ((diff / prior) || 0)
                    return pctdiff                
                })
                
            }

            series_var_change.push({
                'name': this.props.state.outlets[0] + ' - ' + metricname,
                'data': change_series_data,
                'type': 'column',
                yAxis: 0,
                /*color: '#4e106f'*/
            })

            if (this.props.state.all_outlets.length > 1) {

                let curr_series_data = []
                if (this.props.state.use_market_share) {
                    let vals = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                    let mkt = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))

                    curr_series_data = vals.map((val, idx) => val / mkt[idx])
                }
                else {
                    curr_series_data = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                }

                let change_series_data = []
                if (this.props.state.use_market_share) {
                    let vals = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                    let mkt = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                    let prior_series_data = vals.map((val, idx) => val / mkt[idx])

                    change_series_data = vals.map((val, idx) => curr_series_data[idx] - prior_series_data[idx])
                }
                else {
                    change_series_data = vals_to_map.map(val =>
                    (dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer) -
                        dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                    )
                }

                series_var_change.push({
                    'name': this.props.state.outlets[1] + ' - ' + metricname,
                    'data': change_series_data,
                    'type': 'column',
                    yAxis: 0,
                    /*color: '#4e106f'*/
                })
            }
        })

        


        if (this.props.state.use_market_share) {

            yaxis_change = [{
                gridLineColor: "#CCC",
                gridLineWidth: 1,
                title: {
                    text: 'Change in ' + units_type + ' ' + units + ' Market Share (pts)'
                },
                labels: {
                    formatter: function () {
                        return this.value.toFixed(1)

                    }
                }
            },]

            datalabels = {
                enabled: true,
                formatter: function () {
                    return this.y.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            }

            tooltip_change = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + 'Change in share: ' + this.y.toLocaleString() + ' pts'
                }
            }
        }

        else if (this.props.state.indexSwitchCheckDollars) {            

            yaxis_change = [{
                gridLineColor: "#CCC",
                gridLineWidth: 1,
                title: {
                    text: 'Percent Change in ' + units
                },
                labels: {
                    formatter: function () {
                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 }) 
                    }
                }
            }]

            datalabels = {
                enabled: true,
                formatter: function () {
                    return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })
                }
            }

            tooltip_change = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })
                }
            }
        }

        else {            

            yaxis_change = [{
                gridLineColor: "#CCC",
                gridLineWidth: 1,
                title: {
                    text: 'Percent Change in ' + units
                },
                labels: {
                    formatter: function () {
                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })                        
                    }
                }
            },]

            datalabels = {
                enabled: true,
                formatter: function () {
                    return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })
                }
            }



            tooltip_change = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })
                }
            }
        }

        return (

            <div className="row col-sm-12" style={{ padding: '25px' }} >                
                <div className='col-sm-12'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        oneToOne={true}
                        options={
                            {
                                chart: {
                                    //type: 'column'
                                    plotBackgroundColor: null,
                                    plotBorderWidth: null,
                                    plotShadow: false,
                                    type: 'column',
                                    //16:9 ratio
                                    height: (9 / 16 * 100) + '%',
                                },
                                metadata: this.props.state.chartmetadata,
                                caption: {
                                    text: this.props.state.attr_caption_text
                                },
                                title: {
                                    text: 'Change in ' + title
                                },
                                series: series_var_change,
                                xAxis: {
                                    title: {
                                        text: ''
                                    },
                                    categories: vals_to_map,
                                },
                                yAxis: yaxis_change,
                                plotOptions: {
                                    column: {
                                        dataLabels: datalabels
                                    },


                                },
                                tooltip: tooltip_change,
                                exporting: {
                                    showTable: this.props.state.showDataTable,
                                    tableCaption: false
                                },
                            }}
                    />
                </div>
            </div>


        )
    }
}