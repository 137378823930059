import React, { Fragment } from 'react';
import * as Constant from '../constants';

const ScorecardWithoutYOYBaseInc = props => {
    console.log(props)
    var appname = props.appname
    var unitsvar = props.unitsvar
    var base_units_curr = props.base_units_curr
    var sub_base_units_curr = props.sub_base_units_curr
    //var unsub_base_units_curr = base_units_curr - sub_base_units_curr
    var unsub_base_units_curr = props.unsub_base_units_curr
    var unsub_base_units_curr_pct = unsub_base_units_curr / base_units_curr
    var sub_base_units_curr_pct = sub_base_units_curr / base_units_curr
    var inc_units_curr = props.inc_units_curr
    var total_units_curr = base_units_curr + inc_units_curr
    var base_unit_pct_curr = props.base_unit_pct_curr
    var inc_unit_pct_curr = props.inc_unit_pct_curr

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>{'Base and Incremental ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Total Units Sold in the Reporting Period</p>
                                <p> Please note that:</p>
                                <p> Total Units = Non-Promoted Units + Promoted Units, and</p>
                                <p> Total Units = Base Units + Incremental Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >

                        <div className="col-sm-2 " align='center' style={{ padding: '0px' }} >
                            <div className="contentHolder_scorecard">
                                <h5>{'Total ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(total_units_curr, 10)).toLocaleString()}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>An estimate of unit sales that would have occurred without promotions from a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }} >
                            <div className="contentHolder_scorecard">
                                <h5>{'Base ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(base_units_curr, 10)).toLocaleString()}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>An estimate of the impact on unit sales due to the presence of a temporary price reduction. Incremental sales are Total Sales minus Base Sales in stores with a temporary price discount.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental ' + unitsvar}</h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(inc_units_curr, 10)).toLocaleString()}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Base Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base ' + unitsvar}</h5> <h5>Percent of Total</h5>
                                <p>{(base_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Incremental Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental ' + unitsvar}</h5><h5>Percent of Total</h5>
                                <p>{(inc_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Unsubsidized Base Units are the estimated base units sold in non-promoted stores.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base ' + unitsvar}</h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(unsub_base_units_curr, 10)).toLocaleString()}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Subsidized Base Units are estimated base units that were sold in stores with a temporary price reduction. These are the estimated units that would have sold regardless of the promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base ' + unitsvar} <span style={{ color: '#FFFFFF' }}> Un </span></h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(sub_base_units_curr, 10)).toLocaleString()}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Unsubsidized Base Units / Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base ' + unitsvar}</h5> <h5>Percent of Base</h5>
                                <p>{unsub_base_units_curr_pct.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Subsidized Base Units / Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base ' + unitsvar} <span style={{ color: '#FFFFFF' }}> Un </span></h5> <h5>Percent of Base</h5>
                                <p>{sub_base_units_curr_pct.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                </div>
            </Fragment>
            :
            <Fragment>
                <h3>{'Base and Incremental ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>An estimate of unit sales that would have occurred without promotions from a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Base ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(base_units_curr, 10)).toLocaleString()}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>An estimate of the impact on unit sales due to the presence of a temporary price reduction. Incremental sales are Total Sales minus Base Sales in stores with a temporary price discount.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Incremental ' + unitsvar}</h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(inc_units_curr, 10)).toLocaleString()}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Base Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Base ' + unitsvar}</h5> <h5>Percent of Total</h5>
                            <p>{(base_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Incremental Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Incremental ' + unitsvar}</h5><h5>Percent of Total</h5>
                            <p>{(inc_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
    )
}

export default ScorecardWithoutYOYBaseInc;