import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Select from 'react-select'
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import '../styles/custom.css'
import * as Constant from '../constants'

export class UserDialogs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                {this.props.state.isLoaded === true &&
                    <div>
                        {/* User Edit Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showuserinfo}
                            onClose={this.props.handleCloseUserInfo}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>User Info</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseUserInfo}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                User Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeUserInfoName}
                                        id="userinfoName"
                                        value={this.props.state.userinfoName}
                                        fullWidth
                                    />
                                </div>
                                Email Address
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeUserInfoEmail}
                                        id="userinfoEmail"
                                        value={this.props.state.userinfoEmail}
                                        fullWidth
                                    />
                                </div>
                                Company
                                <Select
                                    id="Company"
                                    options={this.props.state.company_options}
                                    placeholder={'Select A Company'}
                                    onChange={this.props.handleChangeUserInfoCompany}
                                    value={[{ 'label': this.props.state.company_lookup[this.props.state.userinfoCompanyId], 'value': this.props.state.userinfoCompanyId }]}
                                />
                                Login Allowed?
                                <div>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.userinfoLogin}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleChangeUserInfoCheckboxEnabled}
                                        />
                                    </ThemeProvider>
                                </div>
                                User ID
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.userinfodata.user_id}
                                        fullWidth
                                    />
                                </div>
                                Last Updated
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.userinfodata.time_updated}
                                        fullWidth
                                    />
                                </div>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.props.handleDeleteUser}
                                                disabled={!this.props.state.user_delete_button_enabled}
                                            >
                                                Delete User
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitUser}
                                                disabled={!this.props.state.user_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* New User (Create) Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showuserinfo_new}
                            onClose={this.props.handleCloseNewUser}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Create a New User</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseNewUser}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                User Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeUserInfoName}
                                        id="userinfoName"
                                        value={this.props.state.userinfoName}
                                        fullWidth
                                    />
                                </div>
                                Email Address
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeUserInfoEmail}
                                        id="userinfoEmail"
                                        value={this.props.state.userinfoEmail}
                                        fullWidth
                                    />
                                </div>
                                Company
                                <Select
                                    id="Company"
                                    options={this.props.state.company_options}
                                    placeholder={'Select A Company'}
                                    onChange={this.props.handleChangeUserInfoCompany}
                                    value={[{ 'label': this.props.state.company_lookup[this.props.state.userinfoCompanyId], 'value': this.props.state.userinfoCompanyId }]}
                                />
                                Login Allowed?
                                <div>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.userinfoLogin}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleChangeUserInfoCheckboxEnabled}
                                        />
                                    </ThemeProvider>
                                </div>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitUserNew}
                                                disabled={!this.props.state.user_edit_button_enabled}
                                            >
                                                Create User
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* View User Permissions Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.viewuserperms}
                            onClose={this.props.handleCloseUserPerms}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>User Permissions</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseUserPerms}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                User Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.userinfoName}
                                        fullWidth
                                    />
                                </div>
                                Company
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.company_lookup[this.props.state.userinfoCompanyId]}
                                        fullWidth
                                    />
                                </div>
                                <h4>Permissions for Price and Promotion</h4>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_pnp_permissions">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Dataset ID</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Dataset Short Desc</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Geographies</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Dataset Information</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                        {
                                            this.props.state.userpermspnpdata.slice(this.props.state.page_start_user_pnp_dataset_perms,
                                                                                    this.props.state.page_start_user_pnp_dataset_perms + this.props.state.rowsPerPage_user_pnp_dataset_perms).map(dataset => {
                                            return <Constant.NPDTableRow key={"datasetpermsrow_" + dataset.dataset_id + "_pnp"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{dataset.dataset_id}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>{dataset.shortdesc}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                    <Select
                                                        options={Object.keys(((this.props.state.datasetgeomapping_pnp || {})[dataset.dataset_id] || {})).map(geo => {
                                                            return { 'label': this.props.state.datasetgeomapping_pnp[dataset.dataset_id][geo], 'value': parseInt(geo) }
                                                        })}
                                                        value={dataset.geogids.map(geo => { return { 'label': ((this.props.state.datasetgeomapping_pnp || {})[dataset.dataset_id] || {})[parseInt(geo)], 'value': parseInt(geo) } })}
                                                        isMulti
                                                        disabled
                                                    />
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <IconButton onClick={() => this.props.openPnpDataSetInfo(dataset.dataset_id)}>
                                                        <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                    </IconButton>
                                                </Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={this.props.state.userpermspnpdata.length}
                                    rowsPerPage={this.props.state.rowsPerPage_user_pnp_dataset_perms}
                                    page={this.props.state.page_user_pnp_dataset_perms}
                                    onChangePage={(event, newPage) => this.props.handleChangePageUser(event, newPage, 'pnpdatasetperms')}
                                    onChangeRowsPerPage={(event) => this.props.handleChangeRowsPerPageUser(event, 'pnpdatasetperms')}
                                />
                                <h4>Permissions for Price and Discount Trends</h4>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_pdt_permissions">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Dataset ID</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Dataset Short Desc</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Store Groups</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Dataset Information</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                        {
                                            this.props.state.userpermspdtdata.slice(this.props.state.page_start_user_pdt_dataset_perms,
                                                                                    this.props.state.page_start_user_pdt_dataset_perms + this.props.state.rowsPerPage_user_pdt_dataset_perms).map(dataset => {
                                            return <Constant.NPDTableRow key={"datasetpermsrow_" + dataset.dataset_id + "_pdt"}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{dataset.dataset_id}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>{dataset.short_desc}</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                    <Select
                                                        options={Object.keys(((this.props.state.datasetgeomapping_pdt || {})[dataset.dataset_id] || {})).map(storegroup => {
                                                            return { 'label': this.props.state.datasetgeomapping_pdt[dataset.dataset_id][storegroup], 'value': parseInt(storegroup) }
                                                        })}
                                                        value={dataset.store_groups.map(storegroup => { return { 'label': ((this.props.state.datasetgeomapping_pdt || {})[dataset.dataset_id] || {})[storegroup], 'value': parseInt(storegroup) } })}
                                                        isMulti
                                                        disabled
                                                    />
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <IconButton onClick={() => this.props.openPdtDataSetInfo(dataset.dataset_id)}>
                                                        <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                    </IconButton>
                                                </Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={this.props.state.userpermspdtdata.length}
                                    rowsPerPage={this.props.state.rowsPerPage_user_pdt_dataset_perms}
                                    page={this.props.state.page_user_pdt_dataset_perms}
                                    onChangePage={(event, newPage) => this.props.handleChangePageUser(event, newPage, 'pdtdatasetperms')}
                                    onChangeRowsPerPage={(event) => this.props.handleChangeRowsPerPageUser(event, 'pdtdatasetperms')}
                                />
                            </div>
                        </Modal>

                        {/* Create Multiple Users Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showuserinfo_new_multiple}
                            onClose={this.props.handleCloseNewUsers}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Create Multiple Users</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseNewUsers}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                Company
                                <Select
                                    id="Company"
                                    options={this.props.state.company_options}
                                    placeholder={'Select A Company'}
                                    onChange={this.props.handleChangeUserInfoCompany}
                                    value={[{ 'label': this.props.state.company_lookup[this.props.state.userinfoCompanyId], 'value': this.props.state.userinfoCompanyId }]}
                                />
                                Group
                                <Select
                                    id="Group"
                                    options={this.props.state.group_options}
                                    placeholder={'Select A Group'}
                                    onChange={this.props.handleChangeUserInfoGroup}
                                    //value={[{ 'label': this.props.state.company_lookup[this.props.state.userinfoCompanyId], 'value': this.props.state.userinfoCompanyId }]}
                                />
                                Login Allowed?
                                <div>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.userinfoLogin}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleChangeUserInfoCheckboxEnabled}
                                        />
                                    </ThemeProvider>
                                </div>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_add_multipleusers">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>User Name</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Email</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Remove User</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                        {(this.props.state.newusers || []).map((user, idx) => {
                                            let indexval = idx
                                            return <Constant.NPDTableRow key={user.username}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <TextField
                                                        variant="outlined"
                                                        onChange={() => this.props.handleChangeUserInfoMultiple('username', indexval)}
                                                        id={"userinfoNameMultiple_" + idx}
                                                        value={user.username}
                                                        fullWidth
                                                    />
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <TextField
                                                        variant="outlined"
                                                        onChange={() => this.props.handleChangeUserInfoMultiple('email', indexval)}
                                                        id={"userinfoEmailMultiple_" + idx}
                                                        value={user.email}
                                                        fullWidth
                                                    />
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <IconButton onClick={() => this.props.removeUserMultiple(idx)}>
                                                        <RemoveCircleRoundedIcon style={{ color: 'red', fontSize: '1.5vw' }} />
                                                    </IconButton>
                                                </Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                                <div className="row">
                                    <ThemeProvider theme={this.props.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.props.addNewUserMultiple}
                                        >
                                            Add Another <br />User
                                        </Button>
                                        <span style={{ width: '500px' }} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.props.submitMultipleUsers}
                                        >
                                            Submit
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                        </Modal>

                        {/* Create IT Ticket Form for User Access Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showittickets}
                            onClose={this.props.handleCloseUsersITTickets}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Create IT TIcket for Users</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseUsersITTickets}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_add_multipleusers">
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>User Name</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Role</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>IP Restriction Needed?</Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Remove User</Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                        {(this.props.state.selectedUsers_ITTickets || []).map((user, idx) => {
                                            let indexval = idx
                                            return <Constant.NPDTableRow key={user.username}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <Select
                                                        id="UsersITTickets"
                                                        options={(this.props.state.usersdata || []).map(user => {
                                                            return { 'label': user.user_name, 'value': user.user_id }
                                                        })}
                                                        placeholder={'Select A User'}
                                                        onChange={(item) => this.props.handleChangeUserInfoITTickets(item, 'user', indexval)}
                                                        value={[{ 'label': user.username, 'value': user.userid }]}
                                                    />
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <Select
                                                        id="RoleITTickets"
                                                        options={[{ 'label': 'User', 'value': 'User' }, { 'label': 'Power User', 'value': 'Power User' }, { 'label': 'Admin', 'value': 'Admin' }]}
                                                        placeholder={'Select A Role'}
                                                        onChange={(item) => this.props.handleChangeUserInfoITTickets(item, 'role', indexval)}
                                                        value={[{ 'label': user.userrole, 'value': user.userrole }]}
                                                    />
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <Select
                                                        id="RoleITTickets"
                                                        options={[{ 'label': 'Yes', 'value': 'Yes' }, { 'label': 'No', 'value': 'No' }]}
                                                        placeholder={'Select A IP Restriction'}
                                                        onChange={(item) => this.props.handleChangeUserInfoITTickets(item, 'ip', indexval)}
                                                        value={[{ 'label': user.iprestriction, 'value': user.iprestriction }]}
                                                    />
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                    <IconButton onClick={() => this.props.removeUserITTickets(idx)}>
                                                        <RemoveCircleRoundedIcon style={{ color: 'red', fontSize: '1.5vw' }} />
                                                    </IconButton>
                                                </Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        })}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                                <div className="row">
                                    <ThemeProvider theme={this.props.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.props.addNewUserITTickets}
                                        >
                                            Add Another<br/>User
                                        </Button>
                                        <span style={{ width: '500px' }} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.props.submitITTickets}
                                        >
                                            Submit
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                        </Modal>
                    </div>
                }
            </Fragment>
        )
    }
}
