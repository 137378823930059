export default function assignFilterLevels(filters, levels) {
    //console.log(filters)
    //console.log(levels)
    let newfilters = JSON.parse(JSON.stringify(filters));
    levels.forEach((level, idx) => {
        let keyname = 'level' + String((idx + 1))
        newfilters[keyname] = level
    })

    return newfilters
}