import React, { Fragment } from 'react';
import * as Constant from '../constants';

const ScorecardWithoutYOYPromotionMetrics = props => {
    console.log(props)
    var currencytype = props.currencytype || 'Dollars'
    var appname = props.appname

    var lift_curr = props.lift_curr
    var lift_promo_curr = props.lift_promo_curr
    var eff_curr = props.eff_curr
    var payback_ratio_curr = props.payback_ratio_curr

    var promoted_dollars_curr = props.promoted_dollars_curr
    var base_dollars_curr = props.base_dollars_curr
    var incremental_dollars_curr = props.incremental_dollars_curr
    var subbasedollars_curr = props.subbasedollars_curr

    var lift_curr_dollars = (incremental_dollars_curr / base_dollars_curr)
    var lift_promo_curr_dollars = (incremental_dollars_curr / subbasedollars_curr)
    var eff_curr_dollars = (incremental_dollars_curr / promoted_dollars_curr)

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>Promotion Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Market Lift <span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Promoted Lift<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_promo_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Efficiency<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(eff_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Market Lift (' + currencytype + ')'}<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted Lift (' + currencytype + ')'}</h5>
                                <p>{(lift_promo_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Efficiency (' + currencytype + ')'}<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(eff_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Payback Ratio</h5>
                                <p>{(payback_ratio_curr).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</p>
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
            :
            <Fragment>
                <h3>Promotion Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Market Lift</h5>
                            <p>{(lift_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Promoted Lift</h5>
                            <p>{(lift_promo_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Efficiency</h5>
                            <p>{(eff_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Payback Ratio</h5>
                            <p>{(payback_ratio_curr).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</p>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
    )
}

export default ScorecardWithoutYOYPromotionMetrics;