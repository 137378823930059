import * as Constant from '../../constants'

export default async function handleClickSave(saveOnly) {
    console.log(saveOnly)
    console.log(this.state)

    var num_errors = 0

    this.setState({ report_type_missing: false })

    //Total US or ALR missing
    if (this.state.selectedAlr === "") {
        num_errors += 1
        this.setState({ alr_type_missing: true })
        console.log('No ALR Type Selected')
    } else {
        this.setState({ alr_type_missing: false })
    }

    //Client Yes No Missing
    if (this.state.selectedClient === "") {
        num_errors += 1
        this.setState({ client_yes_no_missing: true })
        console.log('Client Yes No not selected')
    } else {
        this.setState({ client_yes_no_missing: false })
    }

    //Client Delivery Frequency Missing
    if (this.state.selectedDelFreq === "") {
        num_errors += 1
        this.setState({ client_del_freq_missing: true })
        console.log('Client Delivery Frequency not selected')
    } else {
        this.setState({ client_del_freq_missing: false })
    }

    //Industry Missing
    if (this.state.selectedIndustry === "") {
        num_errors += 1
        this.setState({ industry_missing: true })
        console.log('No industry selected')
    } else {
        this.setState({ industry_missing: false })
    }

    //Hierarchy Level missing
    if (this.state.selectedCategoryLevel.length < 1) {
        num_errors += 1
        this.setState({ catLevel_missing: true })
        console.log('No Hierarchy Level Selected')
    } else {
        this.setState({ catLevel_missing: false })
    }

    //Cat List Missing
    if (this.state.selectedCategory.length < 1) {
        num_errors += 1
        this.setState({ catList_missing: true })
        console.log('No Categories Selected')
    } else {
        this.setState({ catList_missing: false })
    }

    //Email Address Missing
    if (this.state.selectedEmailAddress.length < 1) {
        num_errors += 1
        this.setState({ email_missing: true })
        console.log('No Email address entered')
    } else {
        this.setState({ email_missing: false })
    }

    //PDT ETL Long Description Missing
    if (this.state.selectedReportName === "") {
        num_errors += 1
        this.setState({ report_name_missing: true })
        console.log('Please enter a PDT ETL long description')
    } else {
        this.setState({ report_name_missing: false })
    }

    //PDT ETL Short Name Missing
    if (this.state.selectedShortDesc === "") {
        num_errors += 1
        this.setState({ short_desc_missing: true })
        console.log('Please enter a short name')
    } else {
        this.setState({ short_desc_missing: false })
    }

    // Bail out at this point if there are any errors
    if (num_errors > 0) {
        console.log(num_errors + ' errors were found')
        return
    }

    //// This code breaks apart the clicked into what needs to get passed to the API for global attr filtering
    console.log('CHECKING STATE OF CHECKED BEFORE SAVE AND RUN', this.state.checked)
    if (this.state.checked.length > 0) {
        let topLevel = []
        let bottomLevel = []

        this.state.checked.forEach(id => {
            if (id.includes("_")) {
                bottomLevel.push(id)
            } else {
                topLevel.push(id)
            }
        })

        topLevel.forEach(top => {
            let searchString = "_" + top
            bottomLevel.forEach((bottom, idx) => {
                if (bottom && bottom.includes(searchString)) {
                    bottomLevel[idx] = null
                }
            })
        })

        bottomLevel = bottomLevel.filter(Boolean)
        var valueLookup = {}

        bottomLevel.forEach(bot => {
            let topIdx = bot.search("_")
            if (topIdx > 0) {
                let value = parseInt(bot.substring(0, topIdx))
                let topLevelValue = parseInt(bot.substring(topIdx + 1))
                if (valueLookup[topLevelValue]) {
                    valueLookup[topLevelValue].push(value)
                } else {
                    valueLookup[topLevelValue] = [value]
                }
            }
        })

        topLevel.forEach(top => {
            valueLookup[top] = []
        })
        console.log(valueLookup)

        var returnObject = []
        Object.keys(valueLookup).forEach(key => {
            if (valueLookup[key].length > 0) {
                returnObject.push({ 'global_attr_id': parseInt(key), 'global_attr_code': valueLookup[key] })
            }
        })

        var global_attribute_ids = Object.keys(valueLookup).map(id => parseInt(id))
        this.state.glblAttrNoFilterChosen.forEach(chosen => {
            global_attribute_ids.push(chosen['value'])
        })
        console.log(global_attribute_ids)
        this.setState({ global_attribute_ids: global_attribute_ids, returnObject })
    } else {
        // If no filter is set, then just copy the global attributes selected (if any) into our state
        var global_attribute_ids = []
        this.state.glblAttrNoFilterChosen.forEach(chosen => {
            global_attribute_ids.push(chosen['value'])
        })
        console.log(global_attribute_ids)
        this.setState({ global_attribute_ids: global_attribute_ids })
    }

    //console.log(Constant.entrytype)
    if (this.state.selectedDelFreq === 'On-Demand') {
        var contract_ids = this.state.selectedContractId
    } else {
        var contract_ids = [(parseInt(this.state.selectedContractId))]
    }

    let pdtetl = {
        aggregate_channels: this.state.aggregate_channels,
        attribute_ids: this.state.selectedAttrID || [],
        category_level: this.state.selectedCategoryLevelName,
        category_list: this.state.selectedCategory,
        channel_reporting_level: this.state.selectedChannelOption,
        channels: this.state.channels,
        contract_ids: contract_ids,
        etl_description: this.state.selectedReportName,
        etl_email_address: this.state.selectedEmailAddress,
        etl_shortname: this.state.selectedShortDesc,
        forced_brand_list: this.state.selectedBrand,
        global_attribute_filters: returnObject || [],
        global_attribute_ids: global_attribute_ids || [],
        industry_id: this.state.selectedIndustry,
        is_manufacturer: true,
        is_retailer: false,
        job_frequency: this.state.selectedDelFreq,
        outlets: this.state.selectedOutlet,
        time_aggregation: this.state.selectedOutputAgg,
        time_period_type: this.state.selectedTimePeriodType,
        n_brands: this.state.n_brands,
        n_items: this.state.n_items
    }
    console.log(pdtetl)
    var method

    var urlAddition = ""

    if (Constant.entrytype === 'create') {
        this.setState({ selectedRetMan: 'Manufacturer', is_manufacturer: true, is_retailer: false })
        method = "POST"
        if (this.state.selectedDelFreq === 'On-Demand') {
            var contract_ids = this.state.selectedContractId
        } else {
            var contract_ids = [(parseInt(this.state.selectedContractId))]
        }
    } else if (Constant.entrytype === 'edit') {
        method = "PUT"
        pdtetl['channels'] = this.state.channels
        pdtetl['etl_id'] = this.state.etlIDEdit
        pdtetl['time_updated'] = this.state.editTimeUpdated
        pdtetl['contract_ids'] = this.state.contract_ids
        urlAddition = "/"+String(this.state.etlIDEdit)
    }

    let my_results = await this.entityCrud('saving a PDT ELT via ' + method, [
        { 'url': Constant.baseapiurl_gen + "etl/Pdt_Etl" + urlAddition, 'method': method, 'body': pdtetl },
    ], this.on_api_entity_crud_error)
    if (my_results.Errors > 0) {
        // An error screen will be popped up if this fails
        return
    }

    let data = my_results.ResultList[0]
    console.log('RESULTS FROM RUNNING EXTRACT ONLY')
    console.log(data)

    if (saveOnly === false) {
        this.setState({
            openModal: false,
            setOpenSaveRun: true
        })

        if (this.state.runCloud === true && this.state.runSAS === false) {
            // Full run in Cloud
            let my_results = await this.entityCrud('launching a PDT ETL in the cloud', [
                { 'url': Constant.baseapiurl_gen + "etl/PdtEtlExecution/" + data.etl_id + "?cloud=" + this.state.runCloud + "&job_flags=trap_col_error" + this.state.skip_extract_text + this.state.skip_calculations_text + this.state.resume_if_possible_text + this.state.force_eu_bp_file_text + this.state.useDisaggFile_text + this.state.selectedContainer, 'method': 'GET', 'body': null },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails
                console.log('There was an error launching the PDT ETL in the cloud')
                return
            }
            let runextract_response = my_results.ResultList[0]
            console.log(runextract_response)
        } else if (this.state.runCloud === false && this.state.runSAS === true) {
            if (this.state.selectedServer == null) {
                alert('Please choose a jupyter server from the list and click Run')
            } else {
                // Full run on SAS and Jupyter
                const jupyter_launch_body = {
                    jupyter_server: this.state.selectedServer,
                    n_brands: this.state.n_brands,
                    n_items: this.state.n_items,
                    job_flags: "job_flags=trap_col_error" + this.state.skip_extract_text + this.state.skip_calculations_text + this.state.resume_if_possible_text + this.state.force_eu_bp_file_text
                }

                let my_results = await this.entityCrud('launching a PDT ETL in Jupyter', [
                    { 'url': Constant.baseapiurl_gen + "etl/PdtEtlExecution/" + data.etl_id + "?cloud=" + this.state.runCloud, 'method': 'POST', 'body': jupyter_launch_body },
                ], this.on_api_entity_crud_error)
                if (my_results.Errors > 0) {
                    // An error screen will be popped up if this fails
                    console.log('There was an error launching the PDT ETL in Jupyter')
                    return
                }
                let runextract_response = my_results.ResultList[0]
                console.log(runextract_response)
            }
        }
    }

    this.setState({ setOpenSaveOnly: true })
    //alert('Your extract job has been submitted.  An email will be sent when the job is complete')
}
