import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateWeeklyOutletData(filters, timeperiod) {
    let filters_outlet_weekly = assignFilterLevels(filters, ['outlet', 'rowdate'])
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level2selection = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level2selection, 'method': 'POST', 'body': filters_outlet_weekly }
    ], 'UpdateWeeklyOutlet.js updateWeeklyOutletData()', 'Weekly Outlet')
    let outlet_weeklydata_data = my_results['ReturnList'][0]
    let outlet_weeklydata_obj = outlet_weeklydata_data['level2_data'] || {}

    if (timeperiod === 'totaltimeperiod') {
        this.setState({ outlet_data_weekly: outlet_weeklydata_obj })
    } else if (timeperiod === 'twoyearsago') {
        this.setState({ outlet_data_weekly_last: outlet_weeklydata_obj })
    } else if (timeperiod === 'threeyearsago') {
        this.setState({ outlet_data_weekly_threeyears: outlet_weeklydata_obj })
    } else if (timeperiod === 'prioryear') {
        this.setState({ outlet_data_weekly_prior: outlet_weeklydata_obj })
    }

    return 'Completed'
}
