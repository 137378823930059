import CreateFilters from '../../Utilities/CreateFilters'

export default function changeSelectedLevel_BaseInc(selectedOptions) {

    console.log('Changing Level')

    let level = selectedOptions.value
    var level_baseinc
    if (level === 'Total') {
        level_baseinc = 'Total Selection'
    }
  
    else {        
        level_baseinc = level
    }

    let filter_object = this.state.final_filters || this.state.filter_object
    let exclude_array = this.state.exclude_array
    let weeks_info = this.state.weeks_info
    let priceBandOptions = this.state.priceBandOptions

    let filters_totaltimeperiod = CreateFilters(filter_object, exclude_array, weeks_info, 'totaltimeperiod', priceBandOptions)

    this.setState({
        selectedLevel_BaseInc: selectedOptions,
        level_baseinc
    }, () => this.updateAggregatedData(filters_totaltimeperiod))
}