import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import * as Constant from '../../../constants';

export class StackingTypeCheckbox extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <ThemeProvider theme={Constant.muiTheme}>
                    <Checkbox
                        checked={this.props.state.stackingType_checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': 'Show 100% Stacked Bar?' }}
                        onChange={this.props.handleToggle_stackingType}
                    />
                    <span style={{ lineHeight: '5' }} >Show 100% Stacked Bar?</span>
                </ThemeProvider>
            </Fragment>
        )
    }
}

