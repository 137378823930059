import * as Constant from '../../../constants'

export default async function updateScorecardData(filters, timeagg) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level0selection = Constant.baseapiurl + "ReportData/Filtered/Level0Data/" + reportid + extraparam

    //Perform the Pull
    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level0selection, 'method': 'POST', 'body': filters }
    ], 'updateScorecardData.js updateScorecardData()', 'Aggregated')
    let aggregated_data = my_results['ReturnList'][0]
    let scoreCardData = aggregated_data['level0_data'] || {}

    if (timeagg === 'currentyear') {
        var nonpromo_my = scoreCardData['nonpromotedunits']
        var promo_my = scoreCardData['promotedunits']
        var base_my = scoreCardData['baseunits']
        var disc_my = scoreCardData['weightedpctdisc']
        var total_my = scoreCardData['totalunits']
        var disc_my_withoutno = scoreCardData['weightedpctdisc_withoutno']
        var total_my_withoutno = scoreCardData['totalunits_withoutno']
        var inc_my = scoreCardData['incrementalunits']
        var sub_my = scoreCardData['subsidizedbaseunits']
        var unsub_my = scoreCardData['unsubsidizedbaseunits']
        var weightedbp_my = scoreCardData['weightedbp']
        var incdollars_my = scoreCardData['incrementaldollars']
        var discdollars_my = scoreCardData['discountdollars']
        var revopp_my = scoreCardData['revopportunity']
        var nonpromo_my_withoutYOY = scoreCardData['nonpromotedunits']
        var promo_my_withoutYOY = scoreCardData['promotedunits']
        var base_my_withoutYOY = scoreCardData['baseunits']
        var disc_my_withoutYOY = scoreCardData['weightedpctdisc']
        var total_my_withoutYOY = scoreCardData['totalunits']
        var disc_my_withoutYOY_withoutno = scoreCardData['weightedpctdisc_withoutno']
        var total_my_withoutYOY_withoutno = scoreCardData['totalunits_withoutno']
        var inc_my_withoutYOY = scoreCardData['incrementalunits']
        var sub_my_withoutYOY = scoreCardData['subsidizedbaseunits']
        var unsub_my_withoutYOY = scoreCardData['unsubsidizedbaseunits']
        var weightedbp_my_withoutYOY = scoreCardData['weightedbp']
        var incdollars_my_withoutYOY = scoreCardData['incrementaldollars']
        var discdollars_my_withoutYOY = scoreCardData['discountdollars']
        var totaldollars_my_withoutYOY = scoreCardData['totaldollars']
        var nonpromoteddollars_my_withoutYOY = scoreCardData['nonpromoteddollars']
        var promoteddollars_my_withoutYOY = scoreCardData['promoteddollars']
        var basedollars_my_withoutYOY = scoreCardData['basedollars']
        var incrementaldollars_my_withoutYOY = scoreCardData['incrementaldollars']
        var totaldollars_my = scoreCardData['totaldollars']
        var nonpromoteddollars_my = scoreCardData['nonpromoteddollars']
        var promoteddollars_my = scoreCardData['promoteddollars']
        var basedollars_my = scoreCardData['basedollars']
        var incrementaldollars_my = scoreCardData['incrementaldollars']
        var revopp_my_withoutYOY = scoreCardData['revopportunity']

        let chart_title_info_array = this.state.chart_title_info.split("<br>")
        let selectedBrand_insight = chart_title_info_array[3]

        let reportInsightText = (base_my / total_my).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
            + " of the Product " + this.state.unitsvar.toLowerCase() + " are everyday base sales, while "
            + (inc_my / total_my).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
            + " is incremental sales driven by promotions. "
            + (promo_my / total_my).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
            + " of "
            + selectedBrand_insight
            + " " + this.state.unitsvar.toLowerCase() + " were sold on promotion, and "
            + (sub_my / total_my).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
            + " were subsidized meaning they were sold on promotion but could have been purchased at a higher price.  There is "
        let reportInsightText_emph = Math.round(
            revopp_my
        ).toLocaleString(undefined, { style: 'currency', currency: this.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })

        let reportInsightText_end = " of revenue available to capture from optimizing promotional spending."

        this.setState({
            nonpromo_my
            , promo_my
            , inc_my
            , sub_my
            , unsub_my
            , base_my
            , disc_my
            , total_my
            , disc_my_withoutno
            , total_my_withoutno
            , weightedbp_my
            , incdollars_my
            , discdollars_my
            , revopp_my
            , nonpromo_my_withoutYOY
            , promo_my_withoutYOY
            , inc_my_withoutYOY
            , sub_my_withoutYOY
            , unsub_my_withoutYOY
            , base_my_withoutYOY
            , disc_my_withoutYOY
            , total_my_withoutYOY
            , disc_my_withoutYOY_withoutno
            , total_my_withoutYOY_withoutno
            , weightedbp_my_withoutYOY
            , incdollars_my_withoutYOY
            , discdollars_my_withoutYOY
            , revopp_my_withoutYOY
            , totaldollars_my
            , nonpromoteddollars_my
            , promoteddollars_my
            , basedollars_my
            , incrementaldollars_my
            , totaldollars_my_withoutYOY
            , nonpromoteddollars_my_withoutYOY
            , promoteddollars_my_withoutYOY
            , basedollars_my_withoutYOY
            , incrementaldollars_my_withoutYOY
            , scoreCardData_current: scoreCardData
            , reportInsightText
            , reportInsightText_emph
            , reportInsightText_end
        })
    }
    else if (timeagg === 'prioryear') {
        var nonpromo_my_prior = scoreCardData['nonpromotedunits']
        var promo_my_prior = scoreCardData['promotedunits']
        var base_my_prior = scoreCardData['baseunits']
        var disc_my_prior = scoreCardData['weightedpctdisc']
        var total_my_prior = scoreCardData['totalunits']
        var disc_my_withoutno_prior = scoreCardData['weightedpctdisc_withoutno']
        var total_my_withoutno_prior = scoreCardData['totalunits_withoutno']
        var inc_my_prior = scoreCardData['incrementalunits']
        var sub_my_prior = scoreCardData['subsidizedbaseunits']
        var unsub_my_prior = scoreCardData['unsubsidizedbaseunits']
        var weightedbp_my_prior = scoreCardData['weightedbp']
        var incdollars_my_prior = scoreCardData['incrementaldollars']
        var discdollars_my_prior = scoreCardData['discountdollars']
        var revopp_my_prior = scoreCardData['revopportunity']
        var totaldollars_my_prior = scoreCardData['totaldollars']
        var nonpromoteddollars_my_prior = scoreCardData['nonpromoteddollars']
        var promoteddollars_my_prior = scoreCardData['promoteddollars']
        var basedollars_my_prior = scoreCardData['basedollars']
        var incrementaldollars_my_prior = scoreCardData['incrementaldollars']

        this.setState({
              revopp_my_prior
            , nonpromo_my_prior
            , promo_my_prior
            , inc_my_prior
            , sub_my_prior
            , unsub_my_prior
            , base_my_prior
            , disc_my_prior
            , total_my_prior
            , disc_my_withoutno_prior
            , total_my_withoutno_prior
            , weightedbp_my_prior
            , incdollars_my_prior
            , discdollars_my_prior
            , totaldollars_my_prior
            , nonpromoteddollars_my_prior
            , promoteddollars_my_prior
            , basedollars_my_prior
            , incrementaldollars_my_prior
            , scoreCardData_prior: scoreCardData
        })
    }
    else if (timeagg === 'twoyearsago') {
        var nonpromo_my_last = scoreCardData['nonpromotedunits']
        var promo_my_last = scoreCardData['promotedunits']
        var base_my_last = scoreCardData['baseunits']
        var disc_my_last = scoreCardData['weightedpctdisc']
        var total_my_last = scoreCardData['totalunits']
        var disc_my_withoutno_last = scoreCardData['weightedpctdisc_withoutno']
        var total_my_withoutno_last = scoreCardData['totalunits_withoutno']
        var inc_my_last = scoreCardData['incrementalunits']
        var sub_my_last = scoreCardData['subsidizedbaseunits']
        var unsub_my_last = scoreCardData['unsubsidizedbaseunits']
        var weightedbp_my_last = scoreCardData['weightedbp']
        var incdollars_my_last = scoreCardData['incrementaldollars']
        var discdollars_my_last = scoreCardData['discountdollars']
        var revopp_my_last = scoreCardData['revopportunity']
        var totaldollars_my_last = scoreCardData['totaldollars']
        var nonpromoteddollars_my_last = scoreCardData['nonpromoteddollars']
        var promoteddollars_my_last = scoreCardData['promoteddollars']
        var basedollars_my_last = scoreCardData['basedollars']
        var incrementaldollars_my_last = scoreCardData['incrementaldollars']

        this.setState({
              revopp_my_last
            , nonpromo_my_last
            , promo_my_last
            , inc_my_last
            , sub_my_last
            , unsub_my_last
            , base_my_last
            , disc_my_last
            , total_my_last
            , disc_my_withoutno_last
            , total_my_withoutno_last
            , weightedbp_my_last
            , incdollars_my_last
            , discdollars_my_last
            , totaldollars_my_last
            , nonpromoteddollars_my_last
            , promoteddollars_my_last
            , basedollars_my_last
            , incrementaldollars_my_last
            , scoreCardData_last: scoreCardData
        })
    }
    else if (timeagg === 'threeyearsago') {
        var nonpromo_my_threeyears = scoreCardData['nonpromotedunits']
        var promo_my_threeyears = scoreCardData['promotedunits']
        var base_my_threeyears = scoreCardData['baseunits']
        var disc_my_threeyears = scoreCardData['weightedpctdisc']
        var total_my_threeyears = scoreCardData['totalunits']
        var disc_my_withoutno_threeyears = scoreCardData['weightedpctdisc_withoutno']
        var total_my_withoutno_threeyears = scoreCardData['totalunits_withoutno']
        var inc_my_threeyears = scoreCardData['incrementalunits']
        var sub_my_threeyears = scoreCardData['subsidizedbaseunits']
        var unsub_my_threeyears = scoreCardData['unsubsidizedbaseunits']
        var weightedbp_my_threeyears = scoreCardData['weightedbp']
        var incdollars_my_threeyears = scoreCardData['incrementaldollars']
        var discdollars_my_threeyears = scoreCardData['discountdollars']
        var revopp_my_threeyears = scoreCardData['revopportunity']
        var totaldollars_my_threeyears = scoreCardData['totaldollars']
        var nonpromoteddollars_my_threeyears = scoreCardData['nonpromoteddollars']
        var promoteddollars_my_threeyears = scoreCardData['promoteddollars']
        var basedollars_my_threeyears = scoreCardData['basedollars']
        var incrementaldollars_my_threeyears = scoreCardData['incrementaldollars']

        this.setState({
            revopp_my_threeyears
            , nonpromo_my_threeyears
            , promo_my_threeyears
            , inc_my_threeyears
            , sub_my_threeyears
            , unsub_my_threeyears
            , base_my_threeyears
            , disc_my_threeyears
            , total_my_threeyears
            , disc_my_withoutno_threeyears
            , total_my_withoutno_threeyears
            , weightedbp_my_threeyears
            , incdollars_my_threeyears
            , discdollars_my_threeyears
            , totaldollars_my_threeyears
            , nonpromoteddollars_my_threeyears
            , promoteddollars_my_threeyears
            , basedollars_my_threeyears
            , incrementaldollars_my_threeyears
            , scoreCardData_threeyears: scoreCardData
        })
    }
}
