import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'
import { reducer } from '../PDT/Functions/Utilities/Reducer';

const LiftEffPBRByDiscBand = props => {

    //Calculate Total Units...
    let totalunits_array = props.discountbuckets.map(bucket => props.discountband_data[props.selectedBrand][bucket]['totalunits'])
    let totalunits = ([0].concat(totalunits_array)).reduce(reducer)

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            zoomType: 'xy',
                            height: (9 / 16 * 100) + '%' //16:9 ratio
                        },
                        metadata: props.metadata || {},
                        caption: {
                            text: props.attr_caption_text
                        },
                        title: {
                            text: 'Efficiency and Payback Ratio by Discount Band - ' + props.chart_title_info
                        },
                        series: [
                            {
                                name: 'Efficiency',
                                type: 'line',
                                tooltip: {
                                    pointFormatter: function () {
                                        return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                    }
                                },
                                data: props.discountbuckets.map(bucket => Math.min((props.discountband_data[props.selectedBrand][bucket]['incrementalunits'] / props.discountband_data[props.selectedBrand][bucket]['promotedunits']),1)),
                                yAxis: 2
                            },
                            {
                                name: 'Payback Ratio',
                                type: 'line',
                                tooltip: {
                                    pointFormatter: function () {
                                        return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '</span>'
                                    }
                                },
                                data: props.discountbuckets.map(bucket => props.discountband_data[props.selectedBrand][bucket]['incrementaldollars'] / props.discountband_data[props.selectedBrand][bucket]['discountdollars']),
                                yAxis: 1
                            },
                            {
                                name: 'Market Lift',
                                type: 'line',
                                tooltip: {
                                    pointFormatter: function () {
                                        return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                    }
                                },
                                data: props.discountbuckets.map(bucket => Math.min((props.discountband_data[props.selectedBrand][bucket]['incrementalunits'] / props.discountband_data[props.selectedBrand][bucket]['baseunits']),10)),
                                yAxis: 0
                            },
                            {
                                name: 'Promoted Lift',
                                type: 'line',
                                tooltip: {
                                    pointFormatter: function () {
                                        return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                    }
                                },
                                data: props.discountbuckets.map(bucket => Math.min((props.discountband_data[props.selectedBrand][bucket]['incrementalunits'] / props.discountband_data[props.selectedBrand][bucket]['subsidizedbaseunits']), 10)),
                                yAxis: 0
                            },
                            {
                                name: 'Percent of Sales',
                                type: 'scatter',
                                marker: {
                                    enabled: true,
                                    radius: 6,
                                    symbol: 'square'
                                },
                                tooltip: {
                                    pointFormatter: function () {
                                        return '<span>' + this.series.name + '<br> ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '</span>'
                                    }
                                },
                                data: props.discountbuckets.map(bucket => Math.min((props.discountband_data[props.selectedBrand][bucket]['totalunits'] / totalunits), 1)),
                                yAxis: 2,
                                color: '#83b767'
                            },

                        ],
                        xAxis: {
                            title: {
                                text: 'Discount Range'
                            },
                            categories: props.discountbuckets.map(bucket=>bucket.substring(9))
                        },
                        yAxis: [
                            {
                                title: {
                                    text: 'Lift'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                    }
                                }
                            },
                            {
                                title: {
                                    text: 'Payback Ratio'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                            },
                            {
                                title: {
                                    text: 'Efficiency'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                    }
                                }
                            },
                        ],
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: false,
                                    format: '{point.y:,.0f}'
                                }
                            },

                            spline: {
                                marker: {
                                    enabled: false
                                }
                            }

                        },
                        exporting: {
                            showTable: props.showDataTable,
                            tableCaption: false
                        },

                    }}
            />
            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key={"header_g14"}>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Discount Band</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Efficiency</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Payback Ratio</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Lift</Constant.NPDTableCell>
                        </Constant.NPDTableRow>
                        {props.discountbuckets.map(bucket => {
                            return <Constant.NPDTableRow key={"datarow_" + bucket + "_g14"}>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{bucket}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(Math.min((props.discountband_data[props.selectedBrand][bucket]['incrementalunits'] / props.discountband_data[props.selectedBrand][bucket]['promotedunits']),1) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{((props.discountband_data[props.selectedBrand][bucket]['incrementaldollars'] / props.discountband_data[props.selectedBrand][bucket]['discountdollars']) || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(Math.min((props.discountband_data[props.selectedBrand][bucket]['incrementalunits'] / props.discountband_data[props.selectedBrand][bucket]['baseunits']),10) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                            </Constant.NPDTableRow>
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default LiftEffPBRByDiscBand;