import React, { Component, Fragment } from 'react'; 
import { ThemeProvider } from '@material-ui/core/styles';
import Select from 'react-select'
import Datetime from 'react-datetime'
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import * as Constant from '../../constants';
import selectColourStyles from '../../styles/SelectColourStyles'

export class Modals extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    render() {

        //const numitems = this.props.state.distinctitems.length
        const numitems = this.props.state.reportitems.length
        const numbrands = this.props.state.reportbrands.length
        const numgeos = this.props.state.distinctgeos.length

        const valid = (current) => {
            if (this.props.state.datasettimeagg === 'Monthly') {
                return current.format('D') === "1" && current.isSameOrAfter(this.props.state.pastdate) && current.isSameOrBefore(this.props.state.futuredate)
            }
            else {
                return current.day() === this.props.state.enddate_dow && current.isSameOrAfter(this.props.state.pastdate) && current.isSameOrBefore(this.props.state.futuredate)
            }

        };

        return (
            <Fragment>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.state.ComputeDateRangeModalOpen || false}
                    onClose={this.props.handleCloseComputeDateRangeModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                        <div className='row'>
                            <div style={{ textAlign: 'left', width: '50%' }}>
                                <h4>Calculate a Date Range</h4>
                            </div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <IconButton
                                    onClick={this.props.handleCloseComputeDateRangeModal}
                                >
                                    <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>

                            </div>
                        </div>
                        <p>Please Select the Number of Weeks</p>
                        <TextField
                            variant="outlined"
                            size="small"
                            onChange={this.props.handleChangeDateRangeWeeksBox}
                            id="daterangenumweeks"
                            value={this.props.state.daterangenumweeks}
                        />
                        <p>Where do you want the Range to Start From?</p>
                        <Select
                            id="StartingPoint"
                            options={[{ 'value': 'MostRecent', 'label': 'Most Recent Week in Dataset' }, { 'value': 'Specified', 'label': 'Choose a Specific Week' }]}
                            placeholder={'Choose a Starting Point'}
                            onChange={(item) => this.props.setRangeStartingPoint(item)}
                            value={[this.props.state.rangeStartingPoint]}

                        />
                        {(this.props.state.rangeStartingPoint || {})['value'] === 'Specified' ?
                            <Fragment>
                                <p>Choose a Date</p>
                                <Datetime
                                    input={false}
                                    isValidDate={valid}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    onChange={this.props.handleDateEndRange}
                                    inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddateRange" }}
                                    value={this.props.state.selectedendRange}
                                />
                                {this.props.state.selectedendRange ?
                                    <Fragment>
                                        <p>Do you want to Add or Subtract Weeks from this Date?</p>
                                        <Select
                                            id="AddSubtractDateRange"
                                            options={[{ 'value': 'Add', 'label': 'Add' }, { 'value': 'Subtract', 'label': 'Subtract' }]}
                                            placeholder={'Add or Subtract Weeks'}
                                            onChange={(item) => this.props.setRangeAddSubtract(item)}
                                            value={[this.props.state.rangeAddSubtract]}
                                        />
                                    </Fragment>
                                    :
                                    <Fragment />
                                }

                            </Fragment>
                            :
                            <Fragment />
                        }

                        {((this.props.state.rangeStartingPoint || {})['value'] && this.props.state.daterangenumweeks) ?
                            (this.props.state.rangeStartingPoint.value === 'Specified' && this.props.state.selectedendRange && this.props.state.rangeAddSubtract) ?
                                <button
                                    onClick={this.props.calculateDateRange}
                                    className="btn btn-primary"
                                    autoFocus
                                    style={{ margin: '25px' }}
                                >
                                    Calculate Date Range
		                        </button>
                                :
                                this.props.state.rangeStartingPoint.value === 'MostRecent' ?
                                    <button
                                        onClick={this.props.calculateDateRange}
                                        className="btn btn-primary"
                                        autoFocus
                                        style={{ margin: '25px' }}
                                    >
                                        Calculate Date Range
                              </button>
                                    :
                                    <div style={{ padding: '25px' }} />
                            : <div style={{ padding: '25px' }} />
                        }

                        {this.props.state.calculatedrange ?
                            <Fragment>
                                <p>{'Calculated Date Range is as follows: ' + this.props.state.calculatedrange.begindate_label + ' to ' + this.props.state.calculatedrange.enddate_label}</p>
                                {this.props.state.range_set_warning === true ?
                                    <div>
                                        <span style={{ color: '#f0ab00' }}>
                                            <WarningRoundedIcon style={{ color: '#f0ab00' }} />
                                            {'Part of the Calculated Range Falls outside of the dates available in the dataset.  Please change the number of weeks so the range falls between: '
                                                + new Date(new Date(((this.props.state.times_data || [])[0] || "").substring(0, 10) + 'T00:00:00') - (6 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + ' and '
                                                + new Date(new Date(((this.props.state.times_data || [])[1] || "").substring(0, 10) + 'T00:00:00') - (0 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            }
                                        </span>
                                    </div>
                                    :
                                    <div />
                                }
                                {this.props.state.range_set_error === true ?
                                    <div>
                                        <span style={{ color: '#f0ab00' }}>
                                            <ErrorRoundedIcon style={{ color: '#822433' }} />
                                            {'Part of the Calculated Range Falls outside of the dates available in the dataset.  Please change the number of weeks so the range falls between: '
                                                + new Date(new Date(((this.props.state.times_data || [])[0] || "").substring(0, 10) + 'T00:00:00') - (6 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + ' and '
                                                + new Date(new Date(((this.props.state.times_data || [])[1] || "").substring(0, 10) + 'T00:00:00') - (0 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            }
                                        </span>
                                    </div>
                                    :
                                    <div />
                                }
                                {this.props.state.range_set_error === false ?
                                    <button
                                        onClick={this.props.applyCalculatedRange}
                                        className="btn btn-primary"
                                        autoFocus
                                    >
                                        Use Date Range
		                            </button>
                                    :
                                    <div />
                                }

                            </Fragment>
                            :
                            <Fragment />
                        }

                        <button
                            onClick={this.props.handleCloseComputeDateRangeModal}
                            className="btn btn-secondarynpd"
                            autoFocus
                            style={{ margin: '25px' }}
                        >
                            Cancel
		                </button>

                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.state.ReportCriteriaModalOpen || false}
                    onClose={this.props.handleCloseReportCriteriaModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto', zIndex: '99999999' }} className="contentHolder">
                        <h5 style={{ padding: '0px' }}>Report Criteria</h5>
                        <div className="" style={{ width: '100%' }} >
                            <div className="row " style={{ padding: '0px' }}>
                                <div className="col-sm-6 "><h4>Dataset Used</h4> <p>{(this.props.state.dataset_data || {}).short_desc}</p></div>
                            </div>

                            <div className="row " style={{ padding: '0px' }}>
                                <div className="col-sm-6 "><h4>Time Frame</h4> <p>{String(this.props.state.reportsettings.start_date).substring(0, 10)} to {String(this.props.state.reportsettings.end_date).substring(0, 10)}</p></div>
                                <div className="col-sm-6 " align='right'><h4>Last Updated</h4> <p>{String(this.props.state.reportsettings.time_updated_adj).substring(0, 19).replace('T', ' ')}</p></div>

                            </div>
                            <div className="row " style={{ padding: '0px', marginBottom: '0px', paddingBottom: '0px' }}>
                                <div className="col-sm-4 " style={{ marginRight: '0px', marginLeft: '0px' }}><h4>Store Group</h4></div>
                                <div className="col-sm-4 " style={{ marginRight: '0px', marginLeft: '0px' }}><h4>Brands</h4></div>
                                <div className="col-sm-4 " style={{ marginRight: '0px', marginLeft: '0px' }}><h4>Items</h4></div>
                            </div>
                            <div className="row" style={{ padding: '0px' }}>

                                <div className="col-sm-4" style={{ maxHeight: '100px', overflowY: 'auto', borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginRight: '0px', marginLeft: '0px' }}>
                                    <div>
                                        {
                                            this.props.state.distinctgeos.map((geo, idx) => {
                                                if (numgeos > 4) {
                                                    if (idx < 3) {
                                                        return <span key={geo}>{geo}<br /></span>
                                                    }
                                                    else if (idx === 3) {
                                                        return <span style={{
                                                            color: '#4e106f',
                                                            cursor: 'pointer',
                                                            fontWeight: 'bold'
                                                        }}
                                                            onClick={this.props.handleOpenAllGeosModal}
                                                            key="AllGeosLink"
                                                        >
                                                            View All {numgeos} Store Groups
										                </span>
                                                    }
                                                    else {
                                                        return null
                                                    }

                                                }
                                                else {
                                                    return <span key={geo}>{geo}<br /></span>
                                                }

                                            })
                                        }


                                    </div>
                                </div>
                                <Modal
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    open={this.props.state.AllGeosModalOpen || false}
                                    onClose={this.props.handleCloseAllGeosModal}
                                >
                                    <div style={{ transform: 'translate(150%, 0%)', backgroundColor: '#fff', width: '25%', overflowY: 'auto' }} className="contentHolder">
                                        <h4>Store Group</h4>
                                        {this.props.state.distinctgeos.map((geo, idx) => {
                                            return <span key={geo}>{geo}<br /></span>
                                        })}
                                    </div>
                                </Modal>
                                <div className="col-sm-4" style={{ maxHeight: '100px', overflowY: 'auto', borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginRight: '0px', marginLeft: '0px' }}>
                                    <div>
                                        {
                                            this.props.state.reportbrands.map((brand, idx) => {
                                                if (numbrands > 4) {
                                                    if (idx < 3) {
                                                        return <span key={brand}>{brand}<br /></span>
                                                    }
                                                    else if (idx === 3) {
                                                        return <span style={{
                                                            color: '#4e106f',
                                                            cursor: 'pointer',
                                                            fontWeight: 'bold'
                                                        }}
                                                            onClick={this.props.handleOpenAllBrandsModal}
                                                            key="AllBrandsLink"
                                                        >
                                                            View All {numbrands} Brands
										                </span>
                                                    }
                                                    else {
                                                        return null
                                                    }

                                                }
                                                else {
                                                    return <span key={brand}>{brand}<br /></span>
                                                }

                                            })
                                        }
                                    </div>
                                </div>
                                {/*This is the Modal that houses all of the items.  It will be shown on the click of the View All Items Span Above*/}
                                <Modal
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    open={this.props.state.AllBrandsModalOpen || false}
                                    onClose={this.props.handleCloseAllBrandsModal}
                                    style={{ overflowY: 'scroll' }}
                                >
                                    <div style={{ transform: 'translate(150%, 0%)', backgroundColor: '#fff', width: '25%', overflowY: 'auto' }} className="contentHolder">
                                        <h4>Brands</h4>
                                        {this.props.state.reportbrands.map((brand, idx) => {
                                            return <span key={brand}>{brand}<br /></span>
                                        })}
                                    </div>
                                </Modal>
                                <div className="col-sm-4" style={{ maxHeight: '125px', overflowY: 'auto', borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginRight: '0px', marginLeft: '0px', wordWrap: 'normal', whiteSpace: 'nowrap' }}>
                                    <div>
                                        {
                                            this.props.state.reportitems.map((item, idx) => {
                                                if (numitems > 4) {
                                                    if (idx < 3) {
                                                        return <span key={item}>{item}<br /></span>
                                                    }
                                                    else if (idx === 3) {
                                                        return <span style={{
                                                            color: '#4e106f',
                                                            cursor: 'pointer',
                                                            fontWeight: 'bold'
                                                        }}
                                                            onClick={this.props.handleOpenAllItemsModal}
                                                            key="AllItemsLink"
                                                        >
                                                            View All {numitems} Items
											                </span>
                                                    }
                                                    else {
                                                        return null
                                                    }

                                                }
                                                else {
                                                    return <span key={item}>{item}<br /></span>
                                                }

                                            })
                                        }
                                    </div>

                                </div>
                                {/*This is the Modal that houses all of the items.  It will be shown on the click of the View All Items Span Above*/}
                                <Modal
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    open={this.props.state.AllItemsModalOpen || false}
                                    onClose={this.props.handleCloseAllItemsModal}
                                    style={{ overflowY: 'scroll' }}
                                >
                                    <div style={{ transform: 'translate(150%, 0%)', backgroundColor: '#fff', width: '25%', overflowY: 'auto' }} className="contentHolder">
                                        <h4>Items</h4>
                                        {this.props.state.reportitems.map((item, idx) => {
                                            return <span key={item}>{item}<br /></span>
                                        })}
                                    </div>
                                </Modal>

                            </div>
                            <div className="row " style={{ padding: '0px' }}>
                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <h4>{'Available ' + this.props.state.segment1label + '/' + this.props.state.segment2label}</h4>
                                    </div>
                                    {Object.keys(this.props.state.reportsubcategories || {}).map(key => {
                                        return <div key={key} className='col-sm-3'>
                                            <h6>{key}</h6>
                                            <ul>
                                                {this.props.state.reportsubcategories[key].map(subcat => {
                                                    return <li key={'subcat_' + subcat}>{subcat}</li>
                                                })}
                                            </ul>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <ThemeProvider theme={Constant.muiTheme}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.props.handleCloseReportCriteriaModal}
                                className={Constant.classes.button}
                            >
                                Close Report Criteria
	                        </Button>
                        </ThemeProvider>

                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.state.showVizzesModal || false}
                    onClose={this.props.openChangeVizzes}
                >
                    <div style={{ transform: 'translate(60%, 0%)', backgroundColor: '#fff', width: '40%', height: '50%', overflowY: 'auto', zIndex: '99999999' }} className="contentHolder">
                        <div className='row'>
                            <div align='left' style={{ width: '75%', padding: '12px' }}>
                                <h4>Choose a Configuration</h4>
                            </div>
                            <div align='right' style={{ width: '25%' }}>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={this.props.cancelSetViz}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div align='left' style={{ width: '100%' }}>
                                <Select
                                    id="VizSelect"
                                    options={this.props.state.savedOrderOptions_forDropdown}
                                    placeholder={'Choose a Visualization Set'}
                                    onChange={(item) => this.props.setVizOption(item)}
                                    value={this.props.state.savedOrderValue_forDropdown}
                                    styles={selectColourStyles}
                                />
                            </div>
                        </div>
                        <div className='row' style={{ position: "absolute", bottom: "30px", width: "90%" }}>
                            <div align='left' style={{ width: '33%' }}>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Tooltip
                                        title='Reset to the Default View for this Report'
                                        placement='top-start'
                                    >
                                        <Button
                                            variant="contained"                                            
                                            onClick={this.props.setDefaultOrder}
                                            className={Constant.classes.button}
                                        >
                                            Reset to Default View
                                        </Button>
                                    </Tooltip>
                                </ThemeProvider>
                            </div>
                            <div align='center' style={{ width: '33%' }}>                            
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Tooltip
                                        title='Apply the order and make it the default when opening the report'
                                        placement='top-start'
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.props.setNewVizOrderAndSetting}
                                            className={Constant.classes.button}
                                        >
                                            Apply and Save
	                                    </Button>
                                    </Tooltip>
                                </ThemeProvider>
                            </div>
                            <div align='right' style={{ width: '33%' }}>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Tooltip
                                        title='Apply the order without making it the default for the report'
                                        placement='top-start'
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={this.props.setNewVizOrderOnly}
                                            className={Constant.classes.button}
                                        >
                                            Apply
	                                    </Button>
                                    </Tooltip>
                                </ThemeProvider>
                            </div>  
                        </div> 
                    </div>
                </Modal>

            </Fragment>
        )
    }
}