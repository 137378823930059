import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout } from '../Layout';
import '../styles/react-datetime.css';
import '../styles/datepicker.scss';
import '../styles/custom.css';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import * as Constant from '../constants'

export class ListofAllSimulations extends Component {
    static displayName = ListofAllSimulations.name;

    constructor(props) {
        super(props);
        //this.getFavorites = this.getFavorites.bind(this)
        this.state = {
            error: null,
            isLoaded: false,
            reports: [],
            datamap: {},
            geomap: {},
            errorAlert: false,
            report_favorites: [],
            header_sort_active: [true, false, false, false, false, false, false, false, false],
            header_sort_direction: ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc'],
            showBackground: true
        };
    }
    //Load the Data
    getFavorites = async () => {
        console.log('Getting Favorites')
        const favoritesres = await fetch(Constant.baseapiurl + "SimSettings/Favorites")
        var favoritesdata = []
        if (favoritesres.status === 200) {
            favoritesdata = await favoritesres.json()
        }
        else {
            favoritesdata = []
        }
        let favorites = favoritesdata.map(favorite => favorite.sim_id)
        this.setState({ report_favorites: favorites })
        console.log('Favorites Complete')
        this.getDatasets()
    }

    getDatasets = async () => {
        console.log('Getting Datasets')
        const datasetsres = await fetch(Constant.baseapiurl + "Datasets")
        var datasetsdata = []
        if (datasetsres.status === 200) {
            datasetsdata = await datasetsres.json()
        }
        else {
            datasetsdata = []
        }

        var datamap = {}
        var geomap = {}

        datasetsdata.map(async (dataset) => {
            datamap[dataset.dataset_id] = dataset.shortdesc
            //Get the parameters for each dataset...
            const datasetparamsres = await fetch(Constant.baseapiurl + "Datasets/" + dataset.dataset_id + "/Parameters")
            var datasetparamsdata = {}
            if (datasetparamsres.status === 200) {
                datasetparamsdata = await datasetparamsres.json()
            }
            else {
                datasetparamsdata = { 'geos': [] }
            }

            var specific_geo = {}
            datasetparamsdata.geos.forEach(geo => {

                if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
                }
                else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
                }
                else if (geo.geogdesc.toUpperCase().indexOf('ASSG') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Athletic Specialty Sporting Goods)'
                }
                else {
                    geo.geogdesc_modified = geo.geogdesc
                }

                specific_geo[geo.geogid] = geo.geogdesc_modified
                geomap[dataset.dataset_id] = specific_geo
            })
            //console.log(datamap)

        }
        )
        console.log(Object.keys(datamap))
        this.setState({ datamap, geomap })
        console.log('Datasets Complete')
        setTimeout(function () { this.getReports(geomap); }.bind(this), 3000);


    }

    getReports = async (geomap) => {
        console.log('Getting Simulations')
        const reportsres = await fetch(Constant.baseapiurl + "SimSettings")
        var reportsdata = []
        if (reportsres.status === 200) {
            reportsdata = await reportsres.json()
        }
        else {
            reportsdata = []
        }

        let dt = new Date()
        let offset = dt.getTimezoneOffset() * 60 * 1000
        reportsdata.map(report => report.time_updated_adj = (new Date(Date.parse(report.time_updated) - offset)).toISOString())
        let result_sorted = reportsdata.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })

        for (var i = 0; i < result_sorted.length; i++) {
            let report = result_sorted[i]

            report['dataset_name'] = this.state.datamap[report.dataset_id]

            if (this.state.report_favorites.includes(report.sim_id)) {
                report['favorite'] = true
            }
            else {
                report['favorite'] = false
            }


            const reportsettingres = await fetch(Constant.baseapiurl + "SimSettings/" + report.sim_id)
            var reportsettingssdata = {}
            if (reportsettingres.status === 200) {
                reportsettingssdata = await reportsettingres.json()
            }
            else {
                reportsettingssdata = { 'report_brands': [], geogid: 0 }
            }
            console.log(reportsettingssdata)


            report['sim_type'] = reportsettingssdata.sim_type
            report['sim_start'] = reportsettingssdata.sim_start
            report['sim_end'] = reportsettingssdata.sim_end

            let itemlist = reportsettingssdata.items.map(item => item.prod_id)
            console.log(itemlist)
            console.log('Running Items')
            const itemsdatares = await fetch(Constant.baseapiurl + "Datasets/" + reportsettingssdata.dataset_id + "/BrandsFromItems?geos=" + reportsettingssdata.geogid[0],
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(itemlist)
                })
            var itemsdatadata = []
            if (itemsdatares.status === 200) {
                itemsdatadata = await itemsdatares.json()
            }
            else {
                itemsdatadata = []
            }

            var brands = []
            for (let index = 0; index < itemsdatadata.length; index++) {
                if (!brands.includes(itemsdatadata[index].brand)) {
                    brands.push(itemsdatadata[index].brand)
                }
            }

            //report['brands'] = brands

            //Perform Logic on Last Udated Date....
            var date_dictionary = {
                '01': 'January',
                '02': 'February',
                '03': 'March',
                '04': 'April',
                '05': 'May',
                '06': 'June',
                '07': 'July',
                '08': 'August',
                '09': 'September',
                '10': 'October',
                '11': 'November',
                '12': 'December'
            }
            var current_date = new Date()
            var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
            var current_date_val = current_date.toISOString()
            var report_updated_date_val = report.time_updated_adj.substring(0, 19)

            var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            var timetodisplay = ''
            var timeString = ''
            var H = ''
            var h = 0
            var ampm = ''
            if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                //Just the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = timeString
            }
            else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                //Just the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = 'Yesterday ' + timeString
            }
            else if (Difference_In_Days < 2) {
                //Yesterday plus the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = 'Yesterday ' + timeString
            }
            else {
                //The Date with the time
                var dateString = report.time_updated_adj.substring(0, 10)
                var year = dateString.substring(0, 4)
                var month = dateString.substring(5, 7)
                var dateNum = dateString.substring(8, 10)
                var monthName = date_dictionary[month]
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
            }

            report['timetodisplay'] = timetodisplay

            //Format the geos....
            //let brands = reportsettingssdata.report_brands
            var geos = []
            try {
                geos = reportsettingssdata.geogid.map(geo => geomap[report.dataset_id][geo])
            }
            catch (error) {
                geos = ['Error Loading The Data']
            }

            report['brands'] = brands.join(", ")
            report['geos'] = geos.join(", ")

            console.log(i)
        }

        //Initial sort of favorites by timestamp then nonfavorites by timestamp
        let favs = []
        let nonfavs = []

        result_sorted.forEach(report => {
            if (report.favorite) {
                favs.push(report)
            }
            else {
                nonfavs.push(report)
            }
        })

        //Sort favs
        let favssorted = favs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        //Sort nonfavs
        let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        //Concat 
        let final_sort = favssorted.concat(nonfavssorted)

        console.log('Setting State')
        this.setState({
            isLoaded: true
            , reports: final_sort
            , showBackground: false
        })
        console.log('Simulations Complete')
    }

    async componentDidMount() {
        //this.getFavorites()
        const favoritesres = await fetch(Constant.baseapiurl + "SimSettings/Favorites")
        var favoritesdata = []
        if (favoritesres.status === 200) {
            favoritesdata = await favoritesres.json()
        }
        else {
            favoritesdata = []
        }

        let report_favorites = favoritesdata.map(favorite => favorite.report_id)
        this.setState({ report_favorites: report_favorites })
        console.log('Favorites Complete')
        //Pull Dataset Details
        const datasetdetailsres = await fetch(Constant.baseapiurl + "Datasets/DatasetDetails")
        var datasetdetailsdata = []
        if (datasetdetailsres.status === 200) {
            datasetdetailsdata = await datasetdetailsres.json()
        }
        else {
            datasetdetailsdata = []
        }
        console.log('Dataset Details Complete')
        console.log(datasetdetailsdata)

        var datamap = {}
        var geomap = {}
        //var countrymap = {}
        //var industrymap = {}

        datasetdetailsdata.forEach(dataset => {
            datamap[dataset.dataset_id] = dataset.shortdesc
            //countrymap[dataset.dataset_id] = dataset.country
            //industrymap[dataset.dataset_id] = dataset.industry_name
            var specific_geo = {}
            dataset.geos.forEach(geo => {

                if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
                }
                else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
                }
                else if (geo.geogdesc.toUpperCase().indexOf('ASSG') > -1) {
                    geo.geogdesc_modified = geo.geogdesc + ' (Athletic Specialty Sporting Goods)'
                }
                else {
                    geo.geogdesc_modified = geo.geogdesc
                }

                specific_geo[geo.geogid] = geo.geogdesc_modified
                geomap[dataset.dataset_id] = specific_geo
            })

        })

        this.setState({ datamap, geomap }) // countrymap, industrymap })

        //Pull Report Details
        const reportsdetailsres = await fetch(Constant.baseapiurl + "SimSettings/SimDetails")
        var reportsdata = []
        if (reportsdetailsres.status === 200) {
            reportsdata = await reportsdetailsres.json()
        }
        else {
            reportsdata = []
        }
        console.log('Reports Details Complete')
        console.log(reportsdata)

        let dt = new Date()
        let offset = dt.getTimezoneOffset() * 60 * 1000
        reportsdata.map(report => report.time_updated_adj = (new Date(Date.parse(report.time_updated) - offset)).toISOString())
        let result_sorted = reportsdata.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })

        for (var i = 0; i < result_sorted.length; i++) {
            let report = result_sorted[i]
            report['showreport'] = true //set initial show state to true
            report['dataset_name'] = datamap[report.dataset_id]
            //report['country'] = countrymap[report.dataset_id]
            //report['industry'] = industrymap[report.dataset_id]

            if (report_favorites.includes(report.report_id)) {
                report['favorite'] = true
            }
            else {
                report['favorite'] = false
            }

            //Perform Logic on Last Udated Date....
            var date_dictionary = {
                '01': 'January',
                '02': 'February',
                '03': 'March',
                '04': 'April',
                '05': 'May',
                '06': 'June',
                '07': 'July',
                '08': 'August',
                '09': 'September',
                '10': 'October',
                '11': 'November',
                '12': 'December'
            }
            var current_date = new Date()
            var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
            var current_date_val = current_date.toISOString()
            var report_updated_date_val = report.time_updated_adj.substring(0, 19)

            var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            var timetodisplay = ''
            var timeString = ""
            var H = ""
            var h = 0
            var ampm = ""
            if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                //Just the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = timeString
            }
            else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                //Just the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = 'Yesterday ' + timeString
            }
            else if (Difference_In_Days < 2) {
                //Yesterday plus the time in AM/PM
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = 'Yesterday ' + timeString
            }
            else {
                //The Date with the time
                var dateString = report.time_updated_adj.substring(0, 10)
                var year = dateString.substring(0, 4)
                var month = dateString.substring(5, 7)
                var dateNum = dateString.substring(8, 10)
                var monthName = date_dictionary[month]
                timeString = report.time_updated_adj.substring(11, 19);
                H = +timeString.substr(0, 2);
                h = H % 12 || 12;
                ampm = (H < 12 || H === 24) ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + ampm;
                timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
            }

            report['timetodisplay'] = timetodisplay

            //Format the storegroup....
            let brands = report['sim_brands'] || []

            //console.log(Object.keys(store_group_map))

            var geos = (report.geogid || []).map(geo => ((geomap[report.dataset_id] || {})[geo] || "Unknown"))

            report['brands'] = brands.join(", ")
            report['geos'] = geos.join(", ")

            //console.log(i)
        }

        //Initial sort of favorites by timestamp then nonfavorites by timestamp
        let favs = []
        let nonfavs = []

        result_sorted.forEach(report => {
            if (report.favorite) {
                favs.push(report)
            }
            else {
                nonfavs.push(report)
            }
        })

        //Sort favs
        let favssorted = favs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        //Sort nonfavs
        let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
        //Concat 
        let final_sort = favssorted.concat(nonfavssorted)

        console.log('Setting State')
        this.setState({
            isLoaded: true
            , reports: final_sort
            , showBackground: false
        })
        console.log('Reports Complete')
    }

    render() {
        console.log(this.state)

        const sortData = (event, sortby) => {
            console.log(event)
            console.log('Sorting by ' + sortby)
            //var current_header_active = this.state.header_sort_active
            var current_header_direction = this.state.header_sort_direction
            var new_header_active = []
            if (sortby === 'Favorites') {
                new_header_active = [true, false, false, false, false, false, false, false, false]
            }

            else if (sortby === 'ReportName') {
                new_header_active = [false, true, false, false, false, false, false, false, false]
            }

            else if (sortby === 'Geographies') {
                new_header_active = [false, false, true, false, false, false, false, false, false]
            }

            else if (sortby === 'Brands') {
                new_header_active = [false, false, false, true, false, false, false, false, false]
            }

            else if (sortby === 'Dataset') {
                new_header_active = [false, false, false, false, true, false, false, false, false]
            }

            else if (sortby === 'SimStart') {
                new_header_active = [false, false, false, false, false, true, false, false, false]
            }

            else if (sortby === 'SimEnd') {
                new_header_active = [false, false, false, false, false, false, true, false, false]
            }

            else if (sortby === 'Type') {
                new_header_active = [false, false, false, false, false, false, false, true, false]
            }

            else if (sortby === 'LastModified') {
                new_header_active = [false, false, false, false, false, false, false, false, true]
            }
            //
            var curr_direction = ''
            var new_direction = ''
            var new_header_direction = []
            if (sortby === 'Favorites') {
                curr_direction = current_header_direction[0]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[0] = new_direction
            }

            else if (sortby === 'ReportName') {
                curr_direction = current_header_direction[1]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[1] = new_direction
            }

            else if (sortby === 'Geographies') {
                curr_direction = current_header_direction[2]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[2] = new_direction
            }

            else if (sortby === 'Brands') {
                curr_direction = current_header_direction[3]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[3] = new_direction
            }

            else if (sortby === 'Dataset') {
                curr_direction = current_header_direction[4]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[4] = new_direction
            }

            else if (sortby === 'SimStart') {
                curr_direction = current_header_direction[5]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[5] = new_direction
            }

            else if (sortby === 'SimEnd') {
                curr_direction = current_header_direction[6]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[6] = new_direction
            }

            else if (sortby === 'Type') {
                curr_direction = current_header_direction[7]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[7] = new_direction
            }

            else if (sortby === 'LastModified') {
                curr_direction = current_header_direction[8]
                if (curr_direction === 'asc') {
                    new_direction = 'desc'
                }
                else {
                    new_direction = 'asc'
                }
                new_header_direction = current_header_direction
                new_header_direction[8] = new_direction
            }

            //Sort the data...
            var data_to_sort = this.state.reports
            console.log(data_to_sort)
            var final_sort = []
            if (sortby === 'Favorites') {
                let favs = []
                let nonfavs = []

                data_to_sort.forEach(report => {
                    if (report.favorite) {
                        favs.push(report)
                    }
                    else {
                        nonfavs.push(report)
                    }
                })

                //Sort favs
                let favssorted = favs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                //Sort nonfavs
                let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                //Concat 
                if (new_direction === 'asc') {
                    final_sort = favssorted.concat(nonfavssorted)
                }

                else {
                    final_sort = nonfavssorted.concat(favssorted)
                }


            }

            else if (sortby === 'ReportName') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.sim_name.toLowerCase() < b.sim_name.toLowerCase() ? -1 : (a.sim_name.toLowerCase() > b.sim_name.toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => b.sim_name.toLowerCase() < a.sim_name.toLowerCase() ? -1 : (b.sim_name.toLowerCase() > a.sim_name.toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'Geographies') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.geos.toLowerCase() < b.geos.toLowerCase() ? -1 : (a.geos.toLowerCase() > b.geos.toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => b.geos.toLowerCase() < a.geos.toLowerCase() ? -1 : (b.geos.toLowerCase() > a.geos.toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'Brands') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.brands.toLowerCase() < b.brands.toLowerCase() ? -1 : (a.brands.toLowerCase() > b.brands.toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => b.brands.toLowerCase() < a.brands.toLowerCase() ? -1 : (b.brands.toLowerCase() > a.brands.toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'Dataset') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.dataset_name.toLowerCase() < b.dataset_name.toLowerCase() ? -1 : (a.dataset_name.toLowerCase() > b.dataset_name.toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => b.dataset_name.toLowerCase() < a.dataset_name.toLowerCase() ? -1 : (b.dataset_name.toLowerCase() > a.dataset_name.toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'SimStart') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.sim_start) - Date.parse(a.sim_start) })
                }
                else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.sim_start) - Date.parse(b.sim_start) })
                }
            }

            else if (sortby === 'SimEnd') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.sim_end) - Date.parse(a.sim_end) })
                }
                else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.sim_end) - Date.parse(b.sim_end) })
                }
            }

            else if (sortby === 'Type') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.sim_type.toLowerCase() < b.sim_type.toLowerCase() ? -1 : (a.sim_type.toLowerCase() > b.sim_type.toLowerCase() ? 1 : 0))
                }
                else {
                    final_sort = data_to_sort.sort((a, b) => b.sim_type.toLowerCase() < a.sim_type.toLowerCase() ? -1 : (b.sim_type.toLowerCase() > a.sim_type.toLowerCase() ? 1 : 0))
                }
            }

            else if (sortby === 'LastModified') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                }
                else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.time_updated) - Date.parse(b.time_updated) })
                }
            }

            console.log(final_sort)

            this.setState({
                header_sort_direction: new_header_direction,
                header_sort_active: new_header_active,
                reports: final_sort
            })



        }

        const handleClickFavorite = (event, id, type, remove_add) => {
            console.log(id + ' ' + type + ' ' + remove_add)
            var current_favorites = []
            var new_favorites = []
            if (type === 'report') {
                current_favorites = this.state.report_favorites
                new_favorites = []
                if (remove_add === 'remove') {
                    //Perform the following
                    //Remove from state of report favorites                    
                    current_favorites.forEach(reportid => { if (reportid !== id) { new_favorites.push(reportid) } })
                    console.log(new_favorites)
                    this.setState({ report_favorites: new_favorites })
                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (new_favorites.includes(report.report_id)) {
                            report['favorite'] = true
                        }
                        else {
                            report['favorite'] = false
                        }
                    })

                    this.setState({ reports })

                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
                else {
                    //Perform the following
                    //Add to report favorites
                    current_favorites.push(id)
                    console.log(current_favorites)
                    this.setState({ report_favorites: current_favorites })

                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (current_favorites.includes(report.report_id)) {
                            report['favorite'] = true
                        }
                    })

                    this.setState({ reports })

                    //Perform API to add to favorites

                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
            }
            else if (type === 'sim') {
                current_favorites = this.state.report_favorites
                new_favorites = []
                if (remove_add === 'remove') {
                    console.log('Removing Sim')
                    current_favorites.forEach(simid => { if (simid !== id) { new_favorites.push(simid) } })
                    this.setState({ report_favorites: new_favorites })

                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (new_favorites.includes(report.sim_id)) {
                            report['favorite'] = true
                        }
                        else {
                            report['favorite'] = false
                        }
                    })

                    this.setState({ reports })

                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "SimSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })
                }
                else {
                    current_favorites.push(id)
                    this.setState({ report_favorites: current_favorites })

                    let reports = this.state.reports
                    reports.forEach(report => {
                        if (current_favorites.includes(report.sim_id)) {
                            report['favorite'] = true
                        }
                    })

                    this.setState({ reports })

                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "SimSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })
                }
            }
        }
        return (
            <Layout title="All Simulations" >

                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    <div class="custom-loader"></div>
                    {/*<CircularProgress color="inherit" />*/}
                </Constant.NPDBackdrop>

                <div name='banner' className='banner'>
                    <h1white align='center'>All Simulations</h1white><br/>
                    <h2white align='center'>Below is a list of all simulations in the tool you have access to.</h2white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <Table
                        id="reporTable"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[0]}
                                        direction={this.state.header_sort_direction[0]}
                                        onClick={(event) => sortData(event, 'Favorites')}
                                    >
                                        <h4>Favorite</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[1]}
                                        direction={this.state.header_sort_direction[1]}
                                        onClick={(event) => sortData(event, 'ReportName')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Simulation Name</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[2]}
                                        direction={this.state.header_sort_direction[2]}
                                        onClick={(event) => sortData(event, 'Geographies')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Geographies</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[3]}
                                        direction={this.state.header_sort_direction[3]}
                                        onClick={(event) => sortData(event, 'Brands')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Brands</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[4]}
                                        direction={this.state.header_sort_direction[4]}
                                        onClick={(event) => sortData(event, 'Dataset')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Dataset</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[5]}
                                        direction={this.state.header_sort_direction[5]}
                                        onClick={(event) => sortData(event, 'SimStart')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Simulation Start</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[6]}
                                        direction={this.state.header_sort_direction[6]}
                                        onClick={(event) => sortData(event, 'SimEnd')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Simulation End</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[7]}
                                        direction={this.state.header_sort_direction[7]}
                                        onClick={(event) => sortData(event, 'Type')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Type</h4>
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[8]}
                                        direction={this.state.header_sort_direction[8]}
                                        onClick={(event) => sortData(event, 'LastModified')}
                                        hideSortIcon={false}
                                    >
                                        <h4>Last Modified</h4>
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.reports.map((report) => {
                                return <TableRow key={report.sim_id}>
                                    {report.favorite ?
                                        <TableCell onClick={(event) => handleClickFavorite(event, report.sim_id, 'sim', 'remove')}> <StarRoundedIcon style={{ color: '#4e106f' }} /></TableCell>
                                        :
                                        <TableCell onClick={(event) => handleClickFavorite(event, report.sim_id, 'sim', 'add')}> <StarBorderRoundedIcon style={{ color: '#4e106f' }} /></TableCell>
                                    }

                                    <TableCell> <a href={'/PNP/Simulation/' + report.sim_id} >{report.sim_name} </a></TableCell>
                                    <TableCell> {report.geos} </TableCell>
                                    <TableCell> {report.brands.length > 250 ? report.brands.substring(0, 250) + '...' : report.brands}</TableCell>
                                    <TableCell> {report.dataset_name} </TableCell>
                                    <TableCell> {report.sim_start.substring(0, 10)} </TableCell>
                                    <TableCell> {report.sim_end.substring(0, 10)} </TableCell>
                                    <TableCell> {report.sim_type.substring(0, 1).toUpperCase() + report.sim_type.substring(1).toLowerCase()} </TableCell>
                                    <TableCell> {report.timetodisplay} </TableCell>
                                </TableRow>

                            })}
                        </TableBody>
                    </Table>
                </div>
            </Layout>
        )

    }


}


