import React, { Component, Fragment } from 'react';
import { BrandChannelSwitch } from '../../UIElements/Switches/BrandChannelSwitch'
import { BrandItemsSwitch } from '../../UIElements/Switches/BrandItemsSwitch'
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { SingleMultipleViewSwitch } from '../../UIElements/Switches/SingleMultipleViewSwitch'
import { SingleBrandDropDown } from '../../UIElements/Dropdowns/SingleBrandDropDown'
import { SingleChannelDropDown } from '../../UIElements/Dropdowns/SingleChannelDropDown'
import { MultiItemSelect } from '../../UIElements/Dropdowns/MultiItemSelect'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
import { MultiGeoSelect } from '../../UIElements/Dropdowns/MultiGeoSelect'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import WeeklyViewMultipleSeries from '../../../Graphs/WeeklyViewMultipleSeries'
import WeeklyStackedBarMultipleSeries from '../../../Graphs/WeeklyStackedBarMultipleSeries'
import * as Constant from '../../../constants';

export class AvgPromotedDiscountTrended extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        var aggtype = this.props.state.datasettimeagg

        return (

            <div style={{ width: "100%", height: "100%" }}>
                <div className="row">
                    <div className="col-sm-3" align='left'>
                        <BrandChannelSwitch
                            state={this.props.state}
                            handleChange_channelviews={this.props.handleChange_channelviews}
                        />
                    </div>
                    <div className="col-sm-3 row" align='left'>
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    {this.props.state.viewMultipleBrands ?
                        this.props.state.channelviews ?
                            <div className="col-sm-12" align='left'>
                                <MultiGeoSelect
                                    state={this.props.state}
                                    changeSelectedChannelMulti={this.props.changeSelectedChannelMulti}
                                />
                            </div>
                            :
                            this.props.state.viewItems ?
                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <MultiItemSelect
                                            state={this.props.state}
                                            changeSelectedItemMulti={this.props.changeSelectedItemMulti}
                                        />
                                    </div>
                                </div>
                                :
                                <div className="col-sm-12">
                                    <MultiBrandSelect
                                        state={this.props.state}
                                        changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                                    />
                                </div>
                        
                        :
                        this.props.state.channelviews ?
                            <div className="col-sm-12" align='left'>
                                <div className="col-sm-3">
                                    <SingleChannelDropDown
                                        state={this.props.state}
                                        changeSelectedChannel={this.props.changeSelectedChannel}
                                    />
                                </div>
                                <div className="col-sm-9"/>
                            </div>
                            :
                            <div className="col-sm-12" align='left'>
                                <div className="col-sm-3">
                                    <SingleBrandDropDown
                                        state={this.props.state}
                                        changeSelectedBrand={this.props.changeSelectedBrand}
                                    />
                                </div>
                                <div className="col-sm-9" />
                            </div>
                                                
                    }
                    <div className="row col-sm-4">
                        <SingleMultipleViewSwitch
                            state={this.props.state}
                            handleChange_MultipleBrandsSwitch={this.props.handleChange_MultipleBrandsSwitch}
                        />
                    </div>
                    {this.props.state.viewMultipleBrands && !this.props.state.channelviews ?
                        <div className="col-sm-8">
                            <BrandItemsSwitch
                                state={this.props.state}
                                handleChange_BrandsItemsSwitch={this.props.handleChange_BrandsItemsSwitch}
                            />
                        </div>
                        :
                        <div className="col-sm-8" />
                    }
                </div>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        {this.props.state.viewMultipleBrands ?
                            this.props.state.channelviews ?
                                <Fragment>
                                    <div id="HCcontainer" className="row" style={{ width: "100%", height: "100%" }}>
                                        <div id="avgdiscweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                            <WeeklyViewMultipleSeries
                                                chart_title={"Average Promoted Discount % by " + this.props.state.xAxisText}
                                                chart_title_info={this.props.state.chart_title_info_nochannels}
                                                unitsvar={this.props.state.unitsvar}
                                                seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                    return {
                                                        name: geo,
                                                        type: 'spline',
                                                        color: Constant.graph_colors[idx],
                                                        yAxis: 0,
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                let dt = new Date(this.x)
                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                let date = new Date(this.x + offset)
                                                                if (aggtype === 'Monthly') {
                                                                    return '<span>Month: '
                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                                else {
                                                                    return '<span>Week Ending: '
                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }

                                                            }
                                                        },
                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                        Math.min((((this.props.state.weekly_data_geo[geo] || {})[date] || {})['weightedpctdisc_withoutno']) / (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['totalunits_withoutno']), 1)
                                                        ]),
                                                        zIndex: 1
                                                    }
                                                }).concat([
                                                    {
                                                        name: 'All Channels',
                                                        type: 'spline',
                                                        color: '#000000',
                                                        yAxis: 0,
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                let dt = new Date(this.x)
                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                let date = new Date(this.x + offset)
                                                                if (aggtype === 'Monthly') {
                                                                    return '<span>Month: '
                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                                else {
                                                                    return '<span>Week Ending: '
                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                            }
                                                        },

                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                        Math.min((this.props.state.weekly_data_geo['All Channels'][date]['weightedpctdisc_withoutno'] / this.props.state.weekly_data_geo['All Channels'][date]['totalunits_withoutno']), 1)
                                                        ]),
                                                        zIndex: 1
                                                    }
                                                ])}
                                                distinctdates={this.props.state.distinctdates}
                                                weekly_data={this.props.state.weekly_data_geo}
                                                selectedBrand={this.props.state.selectedChannel}
                                                showDataTable={this.props.state.showDataTable}
                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                weekagg={this.props.state.filter_object.numweeks}
                                                height={2}
                                                attr_caption_text={this.props.state.attr_caption_text}
                                                yAxisTitle='Average Promoted Discount %'
                                                yAxisType='percent'
                                                selectedBrands={this.props.state.selectedChannels}
                                                aggperiod={this.props.state.datasettimeagg}
                                                currencycode={this.props.state.currencycode}
                                                currencytype={this.props.state.currencyType}
                                                metadata={this.props.state.chartmetadata}
                                            />

                                        </div>
                                        {this.props.state.indexSwitchCheckDollars ?
                                            <div id="dollarshareweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info_nochannels}
                                                    seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                        return {
                                                            name: geo,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['totaldollars']) / (this.props.state.weekly_data_geo['All Channels'][date]['totaldollars'])
                                                            ]),
                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={this.props.state.currencyType + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                            :
                                            <div id="dollarshareweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info_nochannels}
                                                    seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                        return {
                                                            name: geo,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['totalunits']) / (this.props.state.weekly_data_geo['All Channels'][date]['totalunits'])
                                                            ]),
                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={this.props.state.unitsvar + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                        }
                                        
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    {!this.props.state.viewItems ?
                                        <div id="HCcontainer" className="row" style={{ width: "100%", height: "100%" }}>
                                            <div id="avgdiscweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyViewMultipleSeries
                                                    chart_title={"Average Promoted Discount % by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info_nobrands}
                                                    unitsvar={this.props.state.unitsvar}
                                                    seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                        return {
                                                            name: brand,
                                                            type: 'spline',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }

                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            Math.min(((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['weightedpctdisc_withoutno']) / ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits_withoutno']), 1)
                                                            ]),
                                                            zIndex: 1
                                                        }
                                                    }).concat([
                                                        {
                                                            name: 'Total Category',
                                                            type: 'spline',
                                                            color: '#000000',
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },

                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            Math.min((((this.props.state.weekly_data_totalcat_new || {})[date] || {})['weightedpctdisc_withoutno'] / ((this.props.state.weekly_data_totalcat_new || {})[date] || {})['totalunits_withoutno']), 1)
                                                            ]),
                                                            zIndex: 1
                                                        }
                                                    ])}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle='Average Promoted Discount %'
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedBrands}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                                />

                                            </div>
                                            {this.props.state.indexSwitchCheckDollars ?
                                                <div id="dollarshareweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info_nobrands}
                                                        seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                            return {
                                                                name: brand,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldollars']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['totaldollars'])
                                                                //]),
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totaldollars'])
                                                                ]),

                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.weekly_data}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={this.props.state.currencyType + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedBrands}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata_multiplebrands}
                                                    />
                                                </div>
                                                :
                                                <div id="dollarshareweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info_nobrands}
                                                        seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                            return {
                                                                name: brand,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                //    ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['totalunits'])
                                                                //]),
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totalunits'])
                                                                ]),
                                                               
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.weekly_data}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={this.props.state.unitsvar + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedBrands}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata_multiplebrands}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div id="HCcontainer3" className="row" style={{ width: "100%", height: "100%" }}>
                                            <div id="avgdiscweekitem" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyViewMultipleSeries
                                                    chart_title={"Average Promoted Discount % by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info_nobrands + "<br>" + this.props.state.selectedBrand + " Items"}
                                                    unitsvar={this.props.state.unitsvar}
                                                    seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                        return {
                                                            name: item,
                                                            type: 'spline',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            Math.min((((this.props.state.item_data_weekly[item] || {})[date] || {})['weightedpctdisc_withoutno']) / (((this.props.state.item_data_weekly[item] || {})[date] || {})['totalunits_withoutno']), 1)
                                                            ]),
                                                            zIndex: 1
                                                        }
                                                    }).concat([
                                                        {
                                                            name: 'Total Category',
                                                            type: 'spline',
                                                            color: '#000000',
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },

                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            Math.min((((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['weightedpctdisc_withoutno'] / ((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['totalunits_withoutno']), 1)
                                                            ]),
                                                            zIndex: 1
                                                        }
                                                    ])}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.item_data_weekly}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle='Average Promoted Discount %'
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedItems}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />

                                            </div>
                                            {this.props.state.indexSwitchCheckDollars ?
                                                <div id="dollarshareweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info_nobrands + "<br>" + this.props.state.selectedBrand + " Items"}
                                                        seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                            return {
                                                                name: item,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                (((this.props.state.item_data_weekly[item] || {})[date] || {})['totaldollars']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['totaldollars'])
                                                                ]),
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.item_data_weekly}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={this.props.state.currencyType + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedItems}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                                :
                                                <div id="dollarshareweek" className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info_nobrands + "<br>" + this.props.state.selectedBrand + " Items"}
                                                        seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                            return {
                                                                name: item,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((this.props.state.item_data_weekly[item] || {})[date] || {})['totalunits']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['totalunits'])
                                                                ]),
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.item_data_weekly}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={this.props.state.unitsvar + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedItems}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                </Fragment>
                            :
                            this.props.state.channelviews ?
                                <Fragment>
                                    <div className="row col-sm-12" style={{ width: "100%", height: "100%" }}>
                                        {this.props.state.indexSwitchCheckDollars ?
                                            <div id="dollarshareweekonebrand" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={"Average Promoted Discount Percent and " + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info_nochannels}
                                                    seriesList={
                                                        [
                                                            {
                                                                name: this.props.state.selectedChannel + ' - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: Constant.graph_colors[0],
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min((((this.props.state.weekly_data_geo[this.props.state.selectedChannel] || {})[date] || {})['weightedpctdisc_withoutno']) / (((this.props.state.weekly_data_geo[this.props.state.selectedChannel] || {})[date] || {})['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 1
                                                            },
                                                            {
                                                                name: this.props.state.selectedChannel + ' - ' + this.props.state.currencyType + ' Share',
                                                                type: 'column',
                                                                color: Constant.graph_colors[2],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                (((this.props.state.weekly_data_geo[this.props.state.selectedChannel] || {})[date] || {})['totaldollars']) / (this.props.state.weekly_data_geo['All Channels'][date]['totaldollars'])
                                                                ]),
                                                                zIndex: 0
                                                            },

                                                            {
                                                                name: 'All Channels - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: '#000000',
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },

                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min((this.props.state.weekly_data_geo['All Channels'][date]['weightedpctdisc_withoutno'] / this.props.state.weekly_data_geo['All Channels'][date]['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 2
                                                            }
                                                        ]
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={this.props.state.currencyType + ' Share'}
                                                    yAxisTitle2='Average Promoted Discount'
                                                    yAxisType='percent'
                                                    yAxisType2='percent'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                            :
                                            <div id="dollarshareweekonebrand" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={"Average Promoted Discount Percent and " + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info_nochannels}
                                                    seriesList={
                                                        [
                                                            {
                                                                name: this.props.state.selectedChannel + ' - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: Constant.graph_colors[0],
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min((((this.props.state.weekly_data_geo[this.props.state.selectedChannel] || {})[date] || {})['weightedpctdisc_withoutno']) / (((this.props.state.weekly_data_geo[this.props.state.selectedChannel] || {})[date] || {})['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 1
                                                            },
                                                            {
                                                                name: this.props.state.selectedChannel + ' - ' + this.props.state.unitsvar + ' Share',
                                                                type: 'column',
                                                                color: Constant.graph_colors[2],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((this.props.state.weekly_data_geo[this.props.state.selectedChannel] || {})[date] || {})['totalunits']) / (this.props.state.weekly_data_geo['All Channels'][date]['totalunits'])
                                                                ]),
                                                                zIndex: 0
                                                            },

                                                            {
                                                                name: 'All Channels - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: '#000000',
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },

                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min((this.props.state.weekly_data_geo['All Channels'][date]['weightedpctdisc_withoutno'] / this.props.state.weekly_data_geo['All Channels'][date]['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 2
                                                            }
                                                        ]
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={this.props.state.unitsvar + ' Share'}
                                                    yAxisTitle2='Average Promoted Discount'
                                                    yAxisType='percent'
                                                    yAxisType2='percent'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <div className="row col-sm-12" style={{ width: "100%", height: "100%" }}>
                                        {this.props.state.indexSwitchCheckDollars ?
                                            <div id="dollarshareweekonebrand" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={"Average Promoted Discount Percent and " + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={
                                                        this.props.state.selectedBrand === 'Total Selection' ?
                                                            this.props.state.chart_title_info_nobrands + ' Brand(s) Selected: ' + this.props.state.brandsstring
                                                            :
                                                            this.props.state.chart_title_info_nobrands + ' Brand Selected: ' + this.props.state.selectedBrand
                                                    }
                                                    seriesList={
                                                        [
                                                            {
                                                                name: this.props.state.selectedBrand + ' - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: Constant.graph_colors[0],
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min(((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['weightedpctdisc_withoutno']) / ((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 1
                                                            },
                                                            {
                                                                name: this.props.state.selectedBrand + ' - ' + this.props.state.currencyType + ' Share',
                                                                type: 'column',
                                                                color: Constant.graph_colors[2],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                //((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totaldollars']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['totaldollars'])
                                                                //]),
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totaldollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totaldollars'])
                                                                ]),

                                                                zIndex: 0
                                                            },

                                                            {
                                                                name: 'Total Category - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: '#000000',
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },

                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min((((this.props.state.weekly_data_totalcat_new || {})[date] || {})['weightedpctdisc_withoutno'] / ((this.props.state.weekly_data_totalcat_new || {})[date] || {})['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 2
                                                            }
                                                        ]
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={this.props.state.currencyType + ' Share'}
                                                    yAxisTitle2='Average Promoted Discount'
                                                    yAxisType='percent'
                                                    yAxisType2='percent'
                                                    selectedBrands={this.props.state.selectedBrands}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                            :
                                            <div id="dollarshareweekonebrand" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={"Average Promoted Discount Percent and " + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={
                                                        this.props.state.selectedBrand === 'Total Selection' ?
                                                            this.props.state.chart_title_info_nobrands + ' Brand(s) Selected: ' + this.props.state.brandsstring
                                                            :
                                                            this.props.state.chart_title_info_nobrands + ' Brand Selected: ' + this.props.state.selectedBrand
                                                    }
                                                    seriesList={
                                                        [
                                                            {
                                                                name: this.props.state.selectedBrand + ' - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: Constant.graph_colors[0],
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min(((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['weightedpctdisc_withoutno']) / ((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 1
                                                            },
                                                            {
                                                                name: this.props.state.selectedBrand + ' - ' + this.props.state.unitsvar + ' Share',
                                                                type: 'column',
                                                                color: Constant.graph_colors[2],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                //((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totalunits']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['totalunits'])
                                                                //]),
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totalunits']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totalunits'])
                                                                ]),
                                                                
                                                                zIndex: 0
                                                            },

                                                            {
                                                                name: 'Total Category - Average Promoted Discount',
                                                                type: 'spline',
                                                                color: '#000000',
                                                                yAxis: 1,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },

                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                Math.min((((this.props.state.weekly_data_totalcat_new || {})[date] || {})['weightedpctdisc_withoutno'] / ((this.props.state.weekly_data_totalcat_new || {})[date] || {})['totalunits_withoutno']), 1)
                                                                ]),
                                                                zIndex: 2
                                                            }
                                                        ]
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={this.props.state.unitsvar + ' Share'}
                                                    yAxisTitle2='Average Promoted Discount'
                                                    yAxisType='percent'
                                                    yAxisType2='percent'
                                                    selectedBrands={this.props.state.selectedBrands}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                        }
                    </div>   
                </div> 
            </div>            
        )
    }
}