export default function handleToggle_exclude_items() {

    this.setState({ exclude_items: !this.state.exclude_items })
    //Filter Dataset....
    let selected_option = this.state.filter_object['items'].map(item => {
        return { 'label': item, 'value': item }
    })

    let exclude_array = [this.state.exclude_brands, !this.state.exclude_items]
    this.setfilters(selected_option, 'items', exclude_array)
}