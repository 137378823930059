import React, { Component } from 'react';
import { LastYearSwitch } from '../../UIElements/Switches/LastYearSwitch'
import { ScoreCardDownloadMenu } from '../../UIElements/IconButtons/ScoreCardDownloadMenu'
import { ScoreCardDownloadMenuYOY } from '../../UIElements/IconButtons/ScorecardDownloadMenuYOY'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { SelectionInformation } from '../../UIElements/InfoBoxes/SelectionInformation'
import ScorecardWithYOYBaseInc  from '../../../Graphs/Scorecard_BaseInc'
import ScorecardWithoutYOYBaseInc  from '../../../Graphs/ScorecardWithoutYOY_BaseInc'

export default function ScoreCardBaseIncFunctional(props) {

	var total_my
	var total_my_withoutno
	var weightedbp_my
	var disc_my
	var disc_my_withoutno
	var total_units_curr
	var total_units_prior
	var weightedbp_my_prior
	var total_my_prior
	var disc_my_prior
	var disc_my_withoutno_prior
	var total_my_withoutno_prior

	const scoreCardProps = {
		unitsvar: props.state.unitsvar,
		currencycode: props.state.currencycode,
		currencytype: props.state.currencyType,
		appname: "pdt"
	}

	if (props.state.showYOYViews) {

		weightedbp_my = props.state.weightedbp_my
		total_my = props.state.total_my
		total_my_withoutno = props.state.total_my_withoutno
		disc_my = props.state.disc_my
		disc_my_withoutno = props.state.disc_my_withoutno
		

		scoreCardProps.base_units_curr = props.state.base_my
		scoreCardProps.sub_base_units_curr = props.state.sub_my
		scoreCardProps.inc_units_curr = props.state.inc_my
		scoreCardProps.nonpromo_units_curr = props.state.nonpromo_my
		scoreCardProps.promo_units_curr = props.state.promo_my
		scoreCardProps.disc_dollars_curr = props.state.disc_dollars_curr
		scoreCardProps.total_dollars_curr = props.state.totaldollars_my_withouYOY
		scoreCardProps.nonpromoted_dollars_curr = props.state.totaldollars_my
		scoreCardProps.promoted_dollars_curr = props.state.promoteddollars_my
		scoreCardProps.base_dollars_curr = props.state.basedollars_my
		scoreCardProps.subbasedollars_curr = props.state.revopp_my
		scoreCardProps.incremental_dollars_curr = scoreCardProps.total_dollars_curr - scoreCardProps.base_dollars_curr


		if (props.state.ThreeYAGO && props.state.showLastViews) {

			weightedbp_my_prior = props.state.weightedbp_my_threeyears
			total_my_prior = props.state.total_my_threeyears
			disc_my_prior = props.state.disc_my_threeyears
			disc_my_withoutno_prior = props.state.disc_my_withoutno_threeyears
			total_my_withoutno_prior = props.state.total_my_withoutno_threeyears

			scoreCardProps.base_units_prior = props.state.base_my_threeyears
			scoreCardProps.sub_base_units_prior = props.state.sub_my_threeyears
			scoreCardProps.inc_units_prior = props.state.inc_my_threeyears
			scoreCardProps.nonpromo_units_prior = props.state.nonpromo_my_threeyears
			scoreCardProps.promo_units_prior = props.state.promo_my_threeyears
			scoreCardProps.promoted_dollars_prior = props.state.promoteddollars_my_threeyears
			scoreCardProps.disc_dollars_prior = props.state.discdollars_my_threeyears
			scoreCardProps.total_dollars_prior = props.state.totaldollars_my_threeyears
			scoreCardProps.base_dollars_prior = props.state.basedollars_my_threeyears
			scoreCardProps.incremental_dollars_prior = props.state.incdollars_my_threeyears
			scoreCardProps.nonpromoted_dollars_prior = props.state.nonpromoteddollars_my_threeyears
			scoreCardProps.promoted_dollars_prior = props.state.promoteddollars_my_threeyears
			scoreCardProps.subbasedollars_prior = props.state.revopp_my_threeyears
		
		} else if (props.state.TwoYAGO && props.state.showLastViews) {

			weightedbp_my_prior = props.state.weightedbp_my_last
			total_my_prior = props.state.total_my_last
			disc_my_prior = props.state.disc_my_last
			disc_my_withoutno_prior = props.state.disc_my_withoutno_last
			total_my_withoutno_prior = props.state.total_my_withoutno_last

			scoreCardProps.base_units_prior = props.state.base_my_last
			scoreCardProps.sub_base_units_prior = props.state.sub_my_last
			scoreCardProps.inc_units_prior = props.state.inc_my_last
			scoreCardProps.nonpromo_units_prior = props.state.nonpromo_my_last
			scoreCardProps.promo_units_prior = props.state.promo_my_last
			scoreCardProps.promoted_dollars_prior = props.state.promoteddollars_my_last
			scoreCardProps.disc_dollars_prior = props.state.discdollars_my_last
			scoreCardProps.total_dollars_prior = props.state.totaldollars_my_last
			scoreCardProps.base_dollars_prior = props.state.basedollars_my_last
			scoreCardProps.incremental_dollars_prior = props.state.incdollars_my_last
			scoreCardProps.nonpromoted_dollars_prior = props.state.nonpromoteddollars_my_last
			scoreCardProps.promoted_dollars_prior = props.state.promoteddollars_my_last
			scoreCardProps.subbasedollars_prior = props.state.revopp_my_last

		} else {

			weightedbp_my_prior = props.state.weightedbp_my_prior
			total_my_prior = props.state.total_my_prior
			disc_my_prior = props.state.disc_my_prior
			disc_my_withoutno_prior = props.state.disc_my_withoutno_prior
			total_my_withoutno_prior = props.state.total_my_withoutno_prior

			scoreCardProps.base_units_prior = props.state.base_my_prior
			scoreCardProps.sub_base_units_prior = props.state.sub_my_prior
			scoreCardProps.inc_units_prior = props.state.inc_my_prior
			scoreCardProps.nonpromo_units_prior = props.state.nonpromo_my_prior
			scoreCardProps.promo_units_prior = props.state.promo_my_prior
			scoreCardProps.promoted_dollars_prior = props.state.promoteddollars_my_prior
			scoreCardProps.disc_dollars_prior = props.state.discdollars_my_prior
			scoreCardProps.total_dollars_prior = props.state.totaldollars_my_prior
			scoreCardProps.base_dollars_prior = props.state.basedollars_my_prior
			//scoreCardProps.incremental_dollars_prior = scoreCardProps.incdollars_my_prior
			scoreCardProps.incremental_dollars_prior = scoreCardProps.total_dollars_prior - scoreCardProps.base_dollars_prior
			scoreCardProps.nonpromoted_dollars_prior = props.state.nonpromoteddollars_my_prior
			scoreCardProps.promoted_dollars_prior = props.state.promoteddollars_my_prior
			scoreCardProps.subbasedollars_prior = props.state.revopp_my_prior

		}

	} else {

		weightedbp_my = props.state.weightedbp_my_withoutYOY
		total_my = props.state.total_my_withoutYOY
		total_my_withoutno = props.state.total_my_withoutYOY_withoutno
		disc_my = props.state.disc_my_withoutYOY
		disc_my_withoutno = props.state.disc_my_withoutYOY_withoutno

		scoreCardProps.base_units_curr = props.state.base_my_withoutYOY
		scoreCardProps.sub_base_units_curr = props.state.sub_my_withoutYOY
		scoreCardProps.inc_units_curr = props.state.inc_my_withoutYOY
		scoreCardProps.nonpromo_units_curr = props.state.nonpromo_my_withoutYOY
		scoreCardProps.promo_units_curr = props.state.promo_my_withoutYOY
		scoreCardProps.disc_dollars_curr = props.state.disc_dollars_curr_withoutYOY
		scoreCardProps.total_dollars_curr = props.state.totaldollars_my_withouYOY
		scoreCardProps.nonpromoted_dollars_curr = props.state.totaldollars_my_withoutYOY
		scoreCardProps.promoted_dollars_curr = props.state.promoteddollars_my_withoutYOY
		scoreCardProps.base_dollars_curr = props.state.basedollars_my_withoutYOY
		scoreCardProps.subbasedollars_curr = props.state.revopp_my_withoutYOY
		scoreCardProps.incremental_dollars_curr = props.state.incdollars_my_withoutYOY
	
	}

	// shared calculations
	total_units_curr = scoreCardProps.base_units_curr + scoreCardProps.inc_units_curr

	scoreCardProps.base_unit_pct_curr = scoreCardProps.base_units_curr / total_units_curr
	scoreCardProps.inc_unit_pct_curr = (scoreCardProps.inc_units_curr / total_units_curr) || 0
	scoreCardProps.nonpromo_unit_pct_curr = scoreCardProps.nonpromo_units_curr / total_units_curr
	scoreCardProps.promo_unit_pct_curr = scoreCardProps.promo_units_curr / total_units_curr
	scoreCardProps.avg_base_price_curr = weightedbp_my / total_my
	scoreCardProps.avg_promo_price_curr = (weightedbp_my / total_my) * (1 - (disc_my_withoutno / total_my_withoutno))
	scoreCardProps.avg_disc_curr = Math.min((disc_my / total_my), 1)
	scoreCardProps.avg_disc_withoutno_curr = Math.min((disc_my_withoutno / total_my_withoutno), 1)
	scoreCardProps.lift_curr = scoreCardProps.inc_units_curr / scoreCardProps.base_units_curr
	scoreCardProps.lift_promo_curr = scoreCardProps.inc_units_curr / scoreCardProps.sub_base_units_curr
	scoreCardProps.eff_curr = scoreCardProps.inc_units_curr / scoreCardProps.promo_units_curr
	scoreCardProps.payback_ratio_curr = scoreCardProps.incremental_dollars_curr / scoreCardProps.disc_dollars_curr
	scoreCardProps.unsubbasedollars_curr = scoreCardProps.base_dollars_curr - scoreCardProps.subbasedollars_curr
	
	// only YOY calculations
	if (props.state.showYOYViews) {

		total_units_prior = scoreCardProps.base_units_prior + scoreCardProps.inc_units_prior

		scoreCardProps.base_unit_pct_prior = (scoreCardProps.base_units_prior / total_units_prior) || 0
		scoreCardProps.inc_unit_pct_prior = (scoreCardProps.inc_units_prior / total_units_prior) || 0
		scoreCardProps.nonpromo_unit_pct_prior = (scoreCardProps.nonpromo_units_prior / total_units_prior) || 0
		scoreCardProps.promo_unit_pct_prior = (scoreCardProps.promo_units_prior / total_units_prior) || 0
		scoreCardProps.avg_base_price_prior = (weightedbp_my_prior / total_my_prior) || 0
		scoreCardProps.avg_promo_price_prior = scoreCardProps.promoted_dollars_prior / scoreCardProps.promo_units_prior
		scoreCardProps.avg_disc_prior = Math.min(((disc_my_prior / total_my_prior) || 0), 1)
		scoreCardProps.avg_disc_withoutno_prior = Math.min(((disc_my_withoutno_prior / total_my_withoutno_prior) || 0), 1)
		scoreCardProps.lift_prior = (scoreCardProps.inc_units_prior / scoreCardProps.base_units_prior) || 0
		scoreCardProps.lift_promo_prior = (scoreCardProps.inc_units_prior / scoreCardProps.sub_base_units_prior) || 0
		scoreCardProps.eff_prior = (scoreCardProps.inc_units_prior / scoreCardProps.promo_units_prior) || 0
		scoreCardProps.payback_ratio_prior = (scoreCardProps.incremental_dollars_prior / scoreCardProps.disc_dollars_prior) || 0
		scoreCardProps.unsubbasedollars_prior = scoreCardProps.base_dollars_prior - scoreCardProps.subbasedollars_prior
	}

	// special use of aggregateData to match unsubsidized unites from baseInc chart
	scoreCardProps.numWeeks = (props.state.weeks_info || {}).groupeddates != null ? props.state.weeks_info.groupeddates.length : null
	scoreCardProps.unsub_base_units_curr = props.state.aggregatedData[props.state.agg_categories_units[0]].unsubsidizedbaseunits
	console.log("HERE", props.state)
	return (
		<div style={{ width: "100%", height: "100%", backgroundColor: '#bfbfbf', borderRadius: '10px', padding: '20px', boxShadow: '10px 10px 5px rgba(0, 0, 0, .1)' }}>
			<div className="row">
				<div className="col-sm-12" align='left'>
					<LastYearSwitch
						state={props.state}
						handleChange_twoyago={props.handleChange_twoyago}
					/>
				</div>
				<div className="col-sm-12">
					{/* <FilterBarReportViews
						state={props.state}
						changeViewFilters={props.changeViewFilters}
						handleToggle_useAttrGroups={props.handleToggle_useAttrGroups}
						changeFilterPause_reportViews={props.changeFilterPause_reportViews}
					/> */}
					<FilterBarReportViewsFunctional />
				</div>
			</div>
			<div style={{ height: '100%', width: "100%", }} className="row">
                <div style={{ width: "100%", height: "100%" }}></div>
				{props.state.showYOYViews ? (
					<div className="row" id="Scorecard" style={{ borderRadius: '0px', borderColor: '#3f3f3f', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
						<div className="col-sm-12">                                    
							<div className="row">
								<div align='left' className="col-sm-11" >
									<h3>Key Metrics for {(props.state.channelviews === true ? props.state.selectedChannel : props.state.selectedBrandLabel) + ' - ' + props.state.distinctdates_forcalc.length + ' ' + props.state.timeperiodlabels + 's Current Year vs ' + (
										props.state.ThreeYAGO === true ? ' Three Years Ago ' : props.state.TwoYAGO === true ? ' Two Years Ago ' : 'Prior Year')}</h3>
								</div>
								<div align='right' className="col-sm-1" >
									<ScoreCardDownloadMenu
										state={props.state}
										openScoreCardMenu={props.openScoreCardMenu}
										closeScoreCardMenu={props.closeScoreCardMenu}
										downloadScoreCardData={props.downloadScoreCardData}
										downloadScoreCardDataYOY_All={props.downloadScoreCardDataYOY_All}
									/>
								</div>
							</div>

							<SelectionInformation
								state={props.state}
							/>
							{props.state.showLastViews ? (
								<div className="row">
									<div className="col-sm-12">
										<ScorecardWithYOYBaseInc {...scoreCardProps} />
									</div>
								</div>
							) : (
								<ScorecardWithYOYBaseInc {...scoreCardProps} />
							)}
						</div>
					</div>
				) : (
					<div className="row" id="ScorecardWithoutYOY" style={{ borderRadius: '0px', borderColor: '#3f3f3f', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
						<div className="col-sm-12">                                    
							<div className="row">
								<div align='left' className="col-sm-11" >
									<h3>Key Metrics for {(props.state.channelviews === true ? props.state.selectedChannel : props.state.selectedBrandLabel) + ' - ' + props.state.distinctdates_forcalc.length + ' ' + props.state.timeperiodlabels + 's Current Year'}
									</h3>
								</div>

								<div align='right' className="col-sm-1" >
									<ScoreCardDownloadMenuYOY
										state={props.state}
										openScoreCardMenuYOY={props.openScoreCardMenuYOY}
										closeScoreCardMenuYOY={props.closeScoreCardMenuYOY}
										downloadScoreCardDataYOY={props.downloadScoreCardDataYOY}
										downloadScoreCardDataYOY_All={props.downloadScoreCardDataYOY_All}
									/>
								</div>
							</div>
							<SelectionInformation
								state={props.state}
							/>

							<ScorecardWithoutYOYBaseInc {...scoreCardProps} />
						</div>
					</div>
				)}
			</div>
		</div>
	)

}