import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import * as Constant from '../../../constants';

export class LastYearSwitch extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            this.props.state.showThreeYearViews ?
                <Fragment>
                    <ThemeProvider theme={Constant.muiTheme}>
                        <Radio
                            checked={this.props.state.OneYAGO}
                            onChange={this.props.handleChange_twoyago}
                            value="PrevYear"
                            name="PrevYear"                                                        
                        />
                        <span style={{ lineHeight: '5' }}>Previous Year</span>
                        <Radio
                            checked={this.props.state.TwoYAGO}
                            onChange={this.props.handleChange_twoyago}
                            value="TwoYearAgo"
                            name="TwoYearAgo"
                        />
                        <span style={{ lineHeight: '5' }}>Two Years Ago</span>
                        <Radio
                            checked={this.props.state.ThreeYAGO}
                            onChange={this.props.handleChange_twoyago}
                            value="ThreeYearAgo"
                            name="ThreeYearAgo"
                        />
                        <span style={{ lineHeight: '5' }}>Three Years Ago</span>
                    </ThemeProvider>
                </Fragment>
                :
                this.props.state.showLastViews ?
                    <Fragment>
                        <ThemeProvider theme={Constant.muiTheme}>
                            <Radio
                                checked={this.props.state.OneYAGO}
                                onChange={this.props.handleChange_twoyago}
                                value="PrevYear"
                                name="PrevYear"
                            />
                            <span style={{ lineHeight: '5' }}>Previous Year</span>
                            <Radio
                                checked={this.props.state.TwoYAGO}
                                onChange={this.props.handleChange_twoyago}
                                value="TwoYearAgo"
                                name="TwoYearAgo"
                            />
                            <span style={{ lineHeight: '5' }}>Two Years Ago</span>
                        </ThemeProvider>
                    </Fragment>
                    :
                    <Fragment/>
        )
    }
}