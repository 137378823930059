import React from 'react';
import * as Constant from './constants'

export class DeBugTest extends React.Component {
    static displayName = DeBugTest.name
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    async PostErrorMessage(errorMessage) {
        const message = errorMessage
        const messagelog = await fetch(Constant.baseurl + 'account/Log',
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: message
            })
        console.log(messagelog.status)
    }

    componentDidCatch (error, info) {
        // Display fallback UI
        this.setState({
            hasError: true
            , error: error
            , errorinfo: info
        });
        //Log the Error in the middleware
        //Post a Message to the log...
        this.PostErrorMessage("At the following url: " + window.location.href + " User Encountered the Following error: " + error.stack)
        
    }

    render() {   
        //console.log(this.state)
        if (this.state.hasError) {
            console.log('Error Found')
            console.log(this.state.error)
            console.log(this.state.errorinfo)
            // You can render any custom fallback UI
            return (
                <div style={{ padding: "40px" }}>
                    <h1>Something went wrong.</h1>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.message.toString()}
                        <br />
                        {this.state.error.stack}
                        <br />                        
                        {this.state.errorinfo.componentStack}  
                    </details>
                    <br />                       
                </div>
            )
        }
        return this.props.children;        
    }

}
