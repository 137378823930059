import * as Constant from '../../../constants'

export default async function updateDropdowns(filters) {
    //let filter_keys = ['brands', 'channels', 'outlets', 'price_bands', 'sel1_list', 'sel2_list', 'sel3_list', 'sel4_list'].concat(Object.keys(this.state.customAttrGroups))
    let filter_keys = ['brands', 'items', 'price_bands'].concat(Object.keys(this.state.customAttrGroups))

    // Determine if 'channels', 'outlets', 'sel1_list', 'sel2_list', 'sel3_list', 'sel4_list' should be added to the filter_keys
    if (this.state.all_geos.length > 1) {
        filter_keys.push('channels')
    }
    console.log(this.state.all_outlets)
    if (this.state.all_outlets.length > 1) {
        filter_keys.push('outlets')
    }

    if (this.state.dataset_data.sel1_list && this.state.dataset_data.sel1_list.length > 1) {
        filter_keys.push('sel1_list')
    }

    if (this.state.dataset_data.sel2_list && this.state.dataset_data.sel2_list.length > 1) {
        filter_keys.push('sel2_list')
    }

    if (this.state.dataset_data.sel3_list && this.state.dataset_data.sel3_list.length > 1) {
        filter_keys.push('sel3_list')
    }

    if (this.state.dataset_data.sel4_list && this.state.dataset_data.sel4_list.length > 1) {
        filter_keys.push('sel4_list')
    }

    // Look and see what filters should be updated...

    let url_updatefilters = Constant.baseapiurl + "ReportData/Filtered/UpdateFilterOptions/" + this.state.reportsettings.report_id + this.state.extraparam

    const option_lookup = {
        'brands': { 'label': 'brand', 'value': 'brandid' },
        'items': { 'label': 'proddesc', 'value': 'prodid' },
        'channels': { 'label': 'geogdesc', 'value': 'geogid' },
        'outlets': { 'label': 'outlet', 'value': 'outlet' },
        'price_bands': { 'label': 'pricebandgroup', 'value': 'PriceBandGroup' },
        'sel1_list': { 'label': 'segment1_name', 'value': 'segment1' },
        'sel2_list': { 'label': 'segment2_name', 'value': 'segment2' },
        'sel3_list': { 'label': 'segment3_name', 'value': 'segment3' },
        'sel4_list': { 'label': 'segment4_name', 'value': 'segment4' },
        'attributes': {}
    }

    const useboth_lookup = {
        'brands': true,
        'items': true,
        'channels': true,
        'outlets': false,
        'price_bands': false,
        'sel1_list': true,
        'sel2_list': true,
        'sel3_list': true,
        'sel4_list': true,
        'attributes': {}
    }

    // Add attributes...
    Object.keys(this.state.customAttrGroups).forEach(attr => {
        option_lookup['attributes'][attr] = { 'label': attr, 'value': attr }
        useboth_lookup['attributes'][attr] = false
    })

    // Need to reference Existing Filters and Use those instead of the new ones so the options don't blank out
    function create_filters_for_update(filter_object, filters, level) {
        // translation....
        let translation = {
            'sel1_list': 'segment1',
            'sel2_list': 'segment2',
            'sel3_list': 'segment3',
            'sel4_list': 'segment4',
            'price_bands': 'pricebands',
        }
        // Make a Copy
        let nonattributes = ['brands', 'items', 'channels', 'outlets', 'price_bands', 'sel1_list', 'sel2_list', 'sel3_list', 'sel4_list']
        let newfilters = JSON.parse(JSON.stringify(filters));
        // The filters passed should respect the initial dataset.  The rest of the filters should apply.
        // handle attributes a little differently...
        if (!nonattributes.includes(level)) {
            (newfilters['attributes'] || []).forEach(attr_array => {
                if (attr_array[0] === level) {
                    attr_array[1] = filter_object['attributes'][level]
                }
            })
            newfilters['option'] = option_lookup['attributes'][level]
            newfilters['useboth'] = useboth_lookup['attributes'][level]
        }
        else {
            let lookeduplevel = translation[level] || level
            newfilters[level] = filter_object[lookeduplevel]
            newfilters['option'] = option_lookup[level]
            newfilters['useboth'] = useboth_lookup[level]
        }

        return newfilters
    }

    let requests_map = filter_keys.map(key => {
        let filters_key = create_filters_for_update(this.state.filter_object, filters, key)
        return { 'url': url_updatefilters, 'method': 'POST', 'body': filters_key }
    })
    let my_results = await this.FetchDataWithErrorHandling(requests_map, 'UpdateDropDowns.js updateDropdowns()', 'Update Dropdowns')
    let filter_view_options = {}
    for (var i = 0; i < my_results['ReturnList'].length; i++) {
        filter_view_options[filter_keys[i]] = my_results['ReturnList'][i]
    }
    this.setState({ filter_view_options: filter_view_options })
}
