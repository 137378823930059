import React, { Fragment } from 'react';
//import ReactDOM from 'react-dom';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font  } from '@react-pdf/renderer';
import logoreverse from "../images/logo_reverse.png"
import examplegraph from "../images/example_graph.png"
import robotoCondensed from "../fonts/RobotoCondensed-Regular.ttf"
import robotoCondensedBold from "../fonts/RobotoCondensed-Bold.ttf"
import robotoCondensedItalic from "../fonts/RobotoCondensed-Italic.ttf"
import robotoCondensedLight from "../fonts/RobotoCondensed-Light.ttf"
import robotoCondensedLightItalic from "../fonts/RobotoCondensed-LightItalic.ttf"
import robotoCondensedBoldItalic from "../fonts/RobotoCondensed-BoldItalic.ttf"
import '../styles/custom.css'
import titleBackground from "../images/PDF/TitleBackground.jpg"
import pageHeader from "../images/PDF/PageHeader.jpg"
import * as Constant from '../constants'

Font.register({
  family: 'RobotoCondensed',
  fonts: [
    {
        src: robotoCondensed
    },      
    {
        src: robotoCondensedBold,
        fontWeight: 'bold'
    },
    {
        src: robotoCondensedItalic,
        fontWeight: 'normal',
        fontStyle: 'italic'
    },
    {
        src: robotoCondensedLight,
        fontWeight: 'light',      
    },
    {
        src: robotoCondensedLightItalic,
        fontWeight: 'light',
        fontStyle: 'italic'
    },
    {
        src: robotoCondensedBoldItalic,
        fontWeight: 'bold',
        fontStyle: 'italic'
    }    
  ]
})

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FAFAFA',        
    },
    text: {
        fontFamily: 'RobotoCondensed'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

var whatamigenerating = ""
var generatingid = "0"
var error = null

if (Constant.whereamilist.length < 6) {
    whatamigenerating = ""
    generatingid = "0"
    error=true
}

else {
    whatamigenerating = Constant.whereamilist[5].toLowerCase()
    generatingid = Constant.whereamilist[6]
    error=false
}

export class GeneratePDF extends React.Component {
    static displayName = GeneratePDF.name;      
    constructor(props) {
        super(props);
        this.state =
            {
            pdfdisplay: 'inline',
            errordisplay: 'none',
            title: "",
            geos: [],
            brands: [],
            items: []
            }
    }

    componentDidMount() {
        if (error === true) {
            this.setState({ pdfdisplay: 'none', errordisplay: 'inline' })
        }

        //If Development, Show, if Prod, Show coming soon
        console.log(Constant.baseurl + 'environment/getenvironment')
        fetch(Constant.baseurl + 'environment/getenvironment')
            .then(res => res.text())
            .then(
                (result) => {
                    this.setState({ env: result})
                },
                (error) => {
                    this.setState({ env: 'Unknown' })
                }
                )

        //Grab Data from what I am trying to get.

        if (whatamigenerating === 'report') {
            fetch(Constant.baseapiurl + "ReportSettings/" + generatingid)
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        //console.log('ok')
                        res.json()
                        .then(
                            (result) => {
                                this.setState({
                                    title: result.report_name,
                                    geos: result.geogid,
                                    brands: result.report_brands,
                                    items: []
                                });
                            }
                        )
                    }
                    else {
                        console.log('There was an Error Running the API')
                    }
                })
            //Run Report Data
            fetch(Constant.baseapiurl + "ReportData/" + generatingid)
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        //console.log('ok')
                        res.json()
                        .then(
                            (result) => {
                                this.setState({
                                    reportData: result,
                                });
                            }
                        )
                    }
                    else {
                        console.log('There was an Error Running the API')
                    }
                })
        }

    }

    render() {
        console.log(this.state)
        return (  
            <div className="" align='center'>
                <Fragment>
                    {this.state.env === 'Development' ?
                        <div style={{ display: this.state.pdfdisplay }}>
                            <PDFViewer
                                height='900px'
                                width='750px'
                            >
                                <Document
                                    title={'PDF for ' + whatamigenerating.substring(0, 1).toUpperCase() + whatamigenerating.substring(1).toLowerCase() + ' : ' + this.state.title}
                                    author='Circana'
                                    producer='Circana'
                                    creator='Circana'
                                    subject='PDF Generated from Circana Price and Promotion Tool'
                                    keywords='Price and Promotion Circana'
                                >
                                    <Page size="LETTER" >
                                        <Image
                                            src={titleBackground}
                                            style={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}
                                        >
                                        </Image>
                                        <Text
                                            style={{ fontFamily: 'Roboto', position: 'absolute', top: '300px', left: '40px', fontSize: '26', color: "#4e106f", width: "67%" }}
                                            fixed={true}
                                        >
                                            {this.state.title}

                                        </Text>

                                        <Text
                                            style={{ fontFamily: 'Roboto', position: 'absolute', top: '400px', left: '40px', fontSize: '18', color: "#3f3f3f" }}
                                            fixed={true}
                                        >
                                            Based on Price and Promotion
                                        </Text>

                                        <Text
                                            style={{ fontFamily: 'Roboto', position: 'absolute', top: '425px', left: '40px', fontSize: '12', color: "#3f3f3f" }}
                                            fixed={true}
                                        >
                                            Date Generated
                                        </Text>

                                        <Text
                                            style={{ fontFamily: 'Roboto', position: 'absolute', top: '700px', left: '40px', fontSize: '8', color: "#3f3f3f", width: "33%" }}
                                            fixed={false}
                                        >
                                            Copyright 2020. The NPD Group, Inc. All Rights Reserved.
                                            This document is
                                            <Text style={{ fontWeight: 'bold', color: '#000' }}> &nbsp;Proprietary and Confidential&nbsp;</Text>
                                            and may not be disclosed in any manner, in whole or in part, to any third party without the express written consent of NPD.
                                        </Text>
                                    </Page>
                                    <Page size="LETTER" >
                                        <Image
                                            src={pageHeader}
                                            style={{ fontFamily: 'Roboto', marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}
                                        >
                                        </Image>
                                        <Text
                                            style={{ fontFamily: 'Roboto', marginLeft: "30px", padding: "10px", fontSize: '8', color: "#4e106f" }}
                                        >
                                            The NPD Group, Inc.  |  Proprietary and confidential
                                        </Text>
                                        <Text
                                            style={{ fontFamily: 'Roboto', marginLeft: "235px", padding: "0px", fontSize: '8', color: "#4e106f" }}
                                        >
                                            2
                                        </Text>
                                        <Text
                                            style={{
                                                fontFamily: 'Roboto', marginLeft: "30px", fontSize: '26', color: "#4e106f", padding: '10px', marginTop: '50px'
                                            }}
                                        >
                                            Table of Contents
                                        </Text>

                                        <Text
                                            style={{
                                                fontFamily: 'Roboto', marginLeft: "30px", fontSize: '12', color: "#ef2a79", padding: '10px', fontWeight: 'bold'
                                            }}
                                        >
                                            {this.state.title}
                                            .........................................................................................................3
                                        </Text>

                                        <Text
                                            style={{
                                                fontFamily: 'Roboto', marginLeft: "30px", fontSize: '11', color: "#000", padding: '10px'
                                            }}
                                        >
                                            Report Parameters
                                            ........................................................................................................................................3
                                        </Text>

                                        <Text
                                            style={{
                                                fontFamily: 'Roboto', marginLeft: "40px", fontSize: '11', color: "#000", padding: '10px', fontStyle: 'italic',
                                            }}
                                        >
                                            Geographies Selected
                                            ................................................................................................................................3
                                        </Text>


                                    </Page>
                                    <Page size="LETTER" style={styles.page}>
                                        <View style={styles.section}>
                                            <Image
                                                src={logoreverse}
                                                style={{ width: '75px', height: '75px', margin: '30px', marginRight: '30px' }}
                                            />
                                            <Text
                                                style={{ fontFamily: 'Roboto', marginLeft: '30px', marginBottom: '15px', marginRight: '30px', fontSize: 18 }}
                                            >
                                                PDF for {whatamigenerating.substring(0, 1).toUpperCase()}{whatamigenerating.substring(1).toLowerCase()} : {this.state.title}
                                            </Text>

                                            <Text
                                                style={{ fontFamily: 'Roboto', marginLeft: '30px', marginBottom: '15px', fontSize: 14 }}
                                            >
                                                Here is some more text to create a narrative.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                            </Text>

                                            <Text
                                                style={{ fontFamily: 'Roboto', marginLeft: '30px', marginBottom: '15px', fontSize: 14 }}
                                            >
                                                Incremental and Promoted Volume by Week
                                            </Text>

                                            <Image
                                                src={examplegraph}
                                                style={{ fontFamily: 'Roboto', width: '500px', height: '250px', marginLeft: '30px', marginRight: '30px' }}
                                            />
                                            <Image
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAEYklEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlACBB1YxAJfjJb2jAAAAAElFTkSuQmCC"
                                            >
                                            </Image>

                                        </View>
                                    </Page>
                                </Document>
                            </PDFViewer>
                        </div>
                        :
                        <div> This feature will be coming soon</div>
                    }
                </Fragment>
                <div className="" style={{ display: this.state.errordisplay }}>ERROR</div>
            </div>


            )
    }
}