import * as Constant from '../../../constants'

export default async function setChartCats(reportid) {

    //Pull Data From Report...
    var reportcategories = []
    var reportsubcategories = {}
    let seg1s_response = await fetch(Constant.baseapiurl + "ReportData/DistinctSeg1s/" + reportid + this.state.extraparam)
    if (seg1s_response.status === 200) {
        let reportcategories_val = await seg1s_response.json()
        reportcategories = reportcategories_val['seg1s']
    }

    let seg1seg2s_response = await fetch(Constant.baseapiurl + "ReportData/DistinctSeg1Seg2s/" + reportid + this.state.extraparam)
    if (seg1seg2s_response.status === 200) {
        let reportsubcategories_val = await seg1seg2s_response.json()
        reportsubcategories = reportsubcategories_val['seg1_lookup']
    }

    var reportcategoriesfull = {}
    var fullstringscats = {}
    var fullstringscatsfordownload = {}
    reportcategories.forEach(cat => {
        reportcategoriesfull[cat] = false
        if (reportsubcategories[cat].length < 5) {
            fullstringscats[cat] = cat + ' - ' + reportsubcategories[cat].join(', ')
            fullstringscatsfordownload[cat] = cat + ' - ' + reportsubcategories[cat].join(', ')
        }
        else {
            fullstringscats[cat] = cat  //+ ' - Partial Category'
            fullstringscatsfordownload[cat] = cat + ' - ' + reportsubcategories[cat].join(', ')
        }
    })

    let chart_title_info = this.state.chart_title_info || ""

    let chart_title_info_nobrands = this.state.chart_title_info_nobrands || ""
    let chart_title_info_nochannels = this.state.chart_title_info_nochannels || ""
    let chart_title_info_split = chart_title_info.split('<br>')

    var cat_subcat = ""
    var cat_subcat_fordownload = ""
    Object.values(fullstringscats).forEach(catstring => cat_subcat += "<br>" + catstring)
    Object.values(fullstringscatsfordownload).forEach(catstring => cat_subcat_fordownload += '<br>' + catstring)
    chart_title_info_split[3] = cat_subcat
    let chart_title_info_new = chart_title_info_split.join("<br>")

    chart_title_info_nobrands += cat_subcat
    chart_title_info_new = chart_title_info_new.replace('<br><br>', '<br>')
    chart_title_info_nobrands = chart_title_info_nobrands.replace('<br><br>', '<br>')
    chart_title_info_nochannels = chart_title_info_nochannels.replace('<br><br>', '<br>')
    //Adjust the metadata for segments as well...
    let chartmetadata = this.state.chartmetadata || {}
    let chartmetadata_singlebrand = this.state.chartmetadata_singlebrand || {}
    let chartmetadata_multiplebrands = this.state.chartmetadata_multiplebrands || {}
    let chartmetadata_singlechannel = this.state.chartmetadata_singlechannel || {}

    chartmetadata['segments'] = cat_subcat_fordownload.split('<br>').join(' & ').slice(2)
    chartmetadata_singlebrand['segments'] = cat_subcat_fordownload.split('<br>').join(' & ').slice(2)
    chartmetadata_multiplebrands['segments'] = cat_subcat_fordownload.split('<br>').join(' & ').slice(2)
    chartmetadata_singlechannel['segments'] = cat_subcat_fordownload.split('<br>').join(' & ').slice(2)

    this.setState({
        reportcategories,
        reportsubcategories,
        reportcategoriesfull,
        fullstringscats,
        chart_title_info: chart_title_info_new,
        chart_title_info_nobrands,
        chart_title_info_nochannels,
        chartmetadata,
        chartmetadata_singlebrand,
        chartmetadata_multiplebrands,
        chartmetadata_singlechannel
    })
}