import * as Constant from '../../constants'
import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

export class CompareSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            checked: [],
            simsCompare: [],
            compareErrorDisplay: 'none',
        }
        console.log(props)
    }

    render() {

        const openPop = Boolean(this.state.anchorEl);
        const id = openPop ? 'simple-popover' : undefined;

        //Handle Functions
        const handleChangeCheck = idx => event => {
            var checked = this.state.checked
            checked[idx] = event.target.checked
            this.setState({ checked })
            var simstocompare = this.state.simsCompare
            //If True, add to list of sims
            if (event.target.checked === true) {
                simstocompare.push(event.target.value)
                this.setState({ simsCompare: simstocompare })
            }

            //If False, remove from list of sims
            else if (event.target.checked === false) {
                var index = simstocompare.indexOf(event.target.value)
                simstocompare.splice(index, 1)
                this.setState({ simsCompare: simstocompare })
            }

        }

        //Handle PopOver
        const handleClickPopover = event => {
            this.setState({ anchorEl: event.currentTarget });
        };

        const handleClosePopover = () => {
            this.setState({ anchorEl: null });
        }

        //Functionality (Search and Compare Sims)
        const searchFunction = (event) => {
            //console.log(event.target.value);
            var input, filter, sims, divs, span, i, txtValue;
            input = event.target.value
            filter = input.toUpperCase();
            sims = document.getElementById("SimCompareBox");
            divs = sims.getElementsByTagName("div");
            for (i = 0; i < divs.length; i++) {
                span = divs[i].getElementsByTagName("span")[0];
                txtValue = span.textContent || span.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    divs[i].style.display = "";
                }
                else {
                    divs[i].style.display = "none";
                }
            }
        }

        const CompareSimsFunc = () => {
            const findid = () => {
                var url = window.location.href;
                var temp = url.split("/");
                var i = unescape(temp[5]);
                var idnum = parseInt(i, 10);
                return idnum;
            }
            const simid = findid();

            if (this.state.simsCompare.length === 0) {
                this.setState({ compareErrorDisplay: 'inline' })
            }

            else {
                var compareurl = Constant.baseurl + 'PNP/CompareSims/' + simid + "_"
                this.state.simsCompare.map(simnum => compareurl += simnum + "_")
                this.setState({ compareErrorDisplay: 'none' })
                window.open(compareurl.substring(0, compareurl.length - 1), '_blank')
            }
        }


        return (
            <div className="col-sm-12" style={{ paddingLeft: '40px' }}>
                <button aria-describedby={id} variant="contained" className="btn btn-primary" onClick={handleClickPopover} >
                    Compare To...
                </button>
                <Popover
                    id={id}
                    open={openPop}
                    anchorEl={this.state.anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className="" style={{ width: '750px', height: '500px', overflowY: 'auto', backgroundColor: '#F4F4F4' }}>
                        <div className="row" style={{ backgroundColor: '#FFF', fontSize: '.75rem', padding: '10px', margin: '5px' }}>
                            <TextField
                                style={{ width: '100%' }}
                                variant="outlined"
                                InputProps={{ endAdornment: <Constant.NPDInputAdornment position="end"><SearchRoundedIcon /></Constant.NPDInputAdornment>, }}
                                placeholder="Search for a simulation"
                                onChange={searchFunction}
                            >
                            </TextField>
                        </div>
                        <div className="row" style={{ backgroundColor: '#F4F4F4', fontSize: '.75rem', padding: '10px', margin: '5px' }} id="SimCompareBox">
                            {this.props.allSimsForCompare.length > 0 ?

                                this.props.allSimsForCompare.map((simulation, idx) =>
                                    <div className="contentHolderSkinny col-sm-3" key={'simcomp' + simulation.sim_id} id={'simcomp_' + simulation.sim_name}>
                                        <span style={{ fontSize: '.75rem', fontFamily: 'Roboto', lineHeight: '2px' }}>
                                            {simulation.sim_name}
                                            <br />
                                        </span>
                                        <Checkbox
                                            checked={this.state.checked[idx]} //for each sim add a checked = false
                                            onChange={handleChangeCheck(idx)} //for each index set true or false
                                            value={simulation.sim_id} //id from sim
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            style={{ color: "#4e106f" }}
                                        />
                                        Updated: {simulation.time_updated_adj.substring(0, 10)}
                                    </div>)
                                :
                                <div>
                                    <p>There are no comparable simulations</p>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="row" style={{ backgroundColor: '#F4F4F4', fontSize: '.75rem', padding: '10px', margin: '5px', height: '75px' }}>
                        <div className="col-sm-3" align="left" style={{ marginLeft: '0px' }}>
                            <button className="btn btn-secondarynpd" onClick={handleClosePopover}>
                                Cancel
                            </button>
                        </div>
                        <div className="col-sm-5">
                            <ErrorRoundedIcon style={{ color: '#822433', display: this.state.compareErrorDisplay }} />
                            <span style={{ color: '#822433', display: this.state.compareErrorDisplay }}>
                                You must select at least one simulation to compare.
                            </span>
                        </div>
                        <div className="col-sm-3" align="left" style={{ marginRight: '0px' }} >
                            <button className="btn btn-primary" onClick={CompareSimsFunc}>
                                Compare
                            </button>
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }
}