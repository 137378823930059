export default function setVizOption(selectedOptions) {
    console.log(selectedOptions)    

    //Set User Settings

    //Set the state
    //let ordername = selectedOptions.value
    //let order = this.state.savedOrderOptions[ordername]
    let savedOrderValue_forDropdown = selectedOptions
    this.setState({ savedOrderValue_forDropdown })

}
