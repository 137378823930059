import React, { Fragment } from 'react';
import Select from 'react-select'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { reducer } from '../../Functions/Utilities/Reducer'
import { LastYearSwitch } from '../../UIElements/Switches/LastYearSwitch'
import { SelectionInformation } from '../../UIElements/InfoBoxes/SelectionInformation'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import * as Constant from '../../../constants'
import selectColourStyles from '../../../styles/SelectColourStyles'
import '../../../styles/custom.css'

// which_table is 'brand' or 'segment' or 'weekly'
export function render_flexible_table(which_table, defaultLevelsSelected, levelOptions, menu, store_group_is_alr, has_past_data) {
    console.log('Common render was invoked for ' + which_table)
    const dropdownStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            maxHeight: "75px",
            overflowY: "auto"
        })
    }

    let current_data = this.props.state['level4_' + which_table + '_table_currentyear'] || {}
    let past_data = this.props.state['level4_' + which_table + '_table_oneyearago'] || {}

    let summedData_current_level1 = this.props.state['level1_' + which_table + '_table_currentyear'] || {}
    let summedData_current_level2 = this.props.state['level2_' + which_table + '_table_currentyear'] || {}
    let summedData_current_level3 = this.props.state['level3_' + which_table + '_table_currentyear'] || {}
    let summedData_current_level4 = current_data

    let summedData_prior_level1 = this.props.state['level1_' + which_table + '_table_oneyearago'] || {}
    let summedData_prior_level2 = this.props.state['level2_' + which_table + '_table_oneyearago'] || {}
    let summedData_prior_level3 = this.props.state['level3_' + which_table + '_table_oneyearago'] || {}
    let summedData_prior_level4 = past_data

    // If two years ago is checked, change prior datasets
    if (this.props.state.TwoYAGO) {
        past_data = this.props.state['level4_' + which_table + '_table_twoyearsago'] || {}
        summedData_prior_level1 = this.props.state['level1_' + which_table + '_table_twoyearsago'] || {}
        summedData_prior_level2 = this.props.state['level2_' + which_table + '_table_twoyearsago'] || {}
        summedData_prior_level3 = this.props.state['level3_' + which_table + '_table_twoyearsago'] || {}
        summedData_prior_level4 = past_data
    }

    let level1 = Object.keys(current_data)

    let level2 = []
    level1.forEach(level1 => {
        let level1_obj = (current_data[level1] || {})
        level2 = level2.concat(Object.keys(level1_obj))
    })
    level2 = Array.from(new Set(level2))

    let level3 = []
    level1.forEach(level1 => {
        level2.forEach(level2 => {
            let level1_obj = (current_data[level1] || {})
            let level2_obj = (level1_obj[level2] || {})
            level3 = level3.concat(Object.keys(level2_obj))
        })
    })
    level3 = Array.from(new Set(level3))

    let level4 = []
    level1.forEach(level1 => {
        level2.forEach(level2 => {
            level3.forEach(level3 => {
                let level1_obj = (current_data[level1] || {})
                let level2_obj = (level1_obj[level2] || {})
                let level3_obj = (level2_obj[level3] || {})
                level4 = level4.concat(Object.keys(level3_obj))
            })
        })
    })
    level4 = Array.from(new Set(level4))

    let level1_prior = Object.keys(past_data)

    let level2_prior = []
    level1_prior.forEach(level1_prior => {
        let level1_prior_obj = past_data[level1_prior] || {}
        level2_prior = level2_prior.concat(Object.keys(level1_prior_obj))
    })
    level2_prior = Array.from(new Set(level2_prior))

    let level3_prior = []
    level1_prior.forEach(level1_prior => {
        level2_prior.forEach(level2_prior => {
            let level1_prior_obj = past_data[level1_prior] || {}
            let level2_prior_obj = level1_prior_obj[level2_prior] || {}
            level3_prior = level3_prior.concat(Object.keys(level2_prior_obj))
        })
    })
    level3_prior = Array.from(new Set(level3_prior))

    let level4_prior = []
    level1_prior.forEach(level1_prior => {
        level2_prior.forEach(level2_prior => {
            level3_prior.forEach(level3_prior => {
                let level1_prior_obj = past_data[level1_prior] || {}
                let level2_prior_obj = level1_prior_obj[level2_prior] || {}
                let level3_prior_obj = level2_prior_obj[level3_prior] || {}
                level4_prior = level4_prior.concat(Object.keys(level3_prior_obj))
            })
        })
    })
    level4_prior = Array.from(new Set(level4_prior))

    // Sort values
    level1.sort()
    level2.sort()
    level3.sort()
    level4.sort()
    level1_prior.sort()
    level2_prior.sort()
    level3_prior.sort()
    level4_prior.sort()

    // Find Highest Level...
    let highest_level = 1
    if (this.state.expanded_level4) {
        highest_level = 4
    } else if (this.state.expanded_level3) {
        highest_level = 3
    } else if (this.state.expanded_level2) {
        highest_level = 2
    }

    let tableMetrics = this.state.selectedMetrics

    let levelsSelected = this.state.levelsSelected || defaultLevelsSelected
    let levelLabels = levelsSelected.map(level => level.label)
    let levels = []
    if (highest_level === 1) {
        levels = [levelLabels[0]]
    } else if (highest_level === 2) {
        levels = [levelLabels[1]]
    } else if (highest_level === 3) {
        levels = [levelLabels[2]]
    } else if (highest_level === 4) {
        levels = [levelLabels[3]]
    }

    let pct_format_obj = { style: 'percent', maximumFractionDigits: 1 }
    let currency_format_obj = { style: 'currency', currency: this.props.state.currencycode, maximumFractionDigits: 0 }
    let units_format_obj = { maximumFractionDigits: 0 }
    let format_lookup = {
        'units': units_format_obj,
        'currency': currency_format_obj,
        'percent': pct_format_obj
    }

    // Look at Levels and if Level is Week Ending, then we need to use the corresponding dates...
    let priorLevelValues = {}

    level1.forEach((level, idx) => {
        if (levelLabels[0] === 'Week Ending') {
            priorLevelValues[level] = level1_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    level2.forEach((level, idx) => {
        if (levelLabels[1] === 'Week Ending') {
            priorLevelValues[level] = level2_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    level3.forEach((level, idx) => {
        if (levelLabels[2] === 'Week Ending') {
            priorLevelValues[level] = level3_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    level4.forEach((level, idx) => {
        if (levelLabels[3] === 'Week Ending') {
            priorLevelValues[level] = level4_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    // Note that "Gap to ROM," "Impact of Share Change", and "Fair Share Opportunity" will only be rendered when level 4 is expanded
    var headers = []
    if (tableMetrics.length > 0) {
        headers = levels.concat(tableMetrics.map(metric => metric.label)).concat(tableMetrics.map(metric => {
            if (this.state.expanded_level4) {
                if (metric.denominator === 'dollarshare') {
                    return [metric.label + ' YOY Change', 'Impact of Share Change', 'Fair Share Opportunity']
                } else if (metric.denominator === 'unitshare') {
                    return [metric.label + ' YOY Change']
                } else if (metric.denominator) {
                    return [metric.label + ' YOY Change', 'Gap to ROM']
                } else {
                    return [metric.label + ' YOY Change']
                }
            } else {
                return [metric.label + ' YOY Change']
            }
        }).reduce((a, c) => { return a.concat(c) }))
        console.log(which_table + ' headers')
        console.log(headers)
    }

    // Remove the Already Selected Values from Level Options...
    let validLevelSelections = []
    levelOptions.forEach(option => {
        if (levelsSelected.map(level => level.value).includes(option.value)) {
            // Do Nothing
        } else {
            validLevelSelections.push(option)
        }
    })
    console.log(which_table + ' LeveOptions:')
    console.log(validLevelSelections)

    // Get Total Units
    // Get Total Dollars
    let totaldollars_for_share_current_level0 = [0].concat(level1.map(level1 => {
        return summedData_current_level1[level1]['totaldollars']
    })).reduce(reducer)

    let totalunits_for_share_current_level0 = [0].concat(level1.map(level1 => {
        return summedData_current_level1[level1]['totalunits']
    })).reduce(reducer)

    let totaldollars_for_share_prior_level0 = [0].concat(level1_prior.map(level1 => {
        return summedData_prior_level1[level1]['totaldollars']
    })).reduce(reducer)

    let totalunits_for_share_prior_level0 = [0].concat(level1_prior.map(level1 => {
        return summedData_prior_level1[level1]['totalunits']
    })).reduce(reducer)

    const color_plus_minus = (value) => {
        return (value < 0) ? '#822433' : '#ef2a79'
    }

    function safe_value(value) {
        return (isFinite(value)) ? value : 0
    }

    const render_l1_rows = () => {
        return level1.map((level1, idx) => {
            let level1_exists = summedData_current_level1[level1]
            if (!level1_exists) {
                return null
            }
            let l1_item_summedl1_current = summedData_current_level1[level1] || {}
            let l1_item_summedl1_prior = summedData_prior_level1[priorLevelValues[level1]] || {}
            let total_units = l1_item_summedl1_current['totalunits'] || 0
            if (total_units <= 0) {
                return null
            }
            return <Constant.NPDTableRow key={"datarow_" + level1 + "_g8"}>
                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                    {level1}
                </Constant.NPDTableCell>
                {tableMetrics.map(metric => {
                    let metric_summedl1_current = l1_item_summedl1_current[metric.value] || 0
                    if (metric.denominator) {
                        let denominator
                        if (metric.denominator === 'dollarshare') {
                            denominator = totaldollars_for_share_current_level0
                        } else if (metric.denominator === 'unitshare') {
                            denominator = totalunits_for_share_current_level0
                        } else {
                            denominator = l1_item_summedl1_current[metric.denominator] || 0
                        }

                        let value = safe_value(metric_summedl1_current / denominator)
                        return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                            {value.toLocaleString(undefined, format_lookup[metric.format])}
                        </Constant.NPDTableCell>
                    } else {
                        return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                            {metric_summedl1_current.toLocaleString(undefined, format_lookup[metric.format])}
                        </Constant.NPDTableCell>
                    }
                })}
                {/*Comparison to Last Year */}
                {tableMetrics.map(metric => {
                    let metric_summedl1_current = l1_item_summedl1_current[metric.value] || 0
                    let total_units = l1_item_summedl1_current['totalunits'] || 0
                    if (total_units > 0) {
                        if (metric.denominator) {
                            let denominator_curr
                            let denominator_prior
                            if (metric.denominator === 'dollarshare') {
                                denominator_curr = totaldollars_for_share_current_level0
                                denominator_prior = totaldollars_for_share_prior_level0
                            } else if (metric.denominator === 'unitshare') {
                                denominator_curr = totalunits_for_share_current_level0
                                denominator_prior = totalunits_for_share_prior_level0
                            } else {
                                denominator_curr = l1_item_summedl1_current[metric.denominator] || 0
                                denominator_prior = l1_item_summedl1_prior[metric.denominator] || 0
                            }
                            let numerator_prior = l1_item_summedl1_prior[metric.value] || 0

                            let pct_change = safe_value((metric_summedl1_current / denominator_curr - numerator_prior / denominator_prior) * 100)
                            return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: color_plus_minus(pct_change), fontWeight: 'bold' }}>
                                {pct_change.toLocaleString(undefined, { maximumFractionDigits: 1 }) + ' pts'}
                            </Constant.NPDTableCell>
                        } else {
                            let prior_val = l1_item_summedl1_prior[metric.value] || 0
                            let pct_change = safe_value((metric_summedl1_current - prior_val) / prior_val)
                            return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: color_plus_minus(pct_change), fontWeight: 'bold' }}>
                                {pct_change.toLocaleString(undefined, pct_format_obj)}
                            </Constant.NPDTableCell>
                        }
                    }
                })}
            </Constant.NPDTableRow>
        })
    }

    const render_l2_rows = () => {
        return level1.map(level1 => {
            let level1_exists = summedData_current_level1[level1]
            if (!level1_exists) {
                return null
            }
            let total_units = (summedData_current_level1[level1] || {})['totalunits'] || 0
            if (total_units <= 0) {
                return null
            }
            let current_total_dollars = (summedData_current_level1[level1] || {})['totaldollars']
            let current_total_units = (summedData_current_level1[level1] || {})['totalunits']
            let prior_total_dollars = (summedData_prior_level1[priorLevelValues[level1]] || {})['totaldollars']
            let prior_total_units = (summedData_prior_level1[priorLevelValues[level1]] || {})['totalunits']

            let l1_item_summedl2_current = summedData_current_level2[level1] || {}
            let l1_item_summedl2_prior = summedData_prior_level2[priorLevelValues[level1]] || {}
            return <Constant.NPDTableRow key={"datarow_" + level1 + "_g8"}>
                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                    {level1}
                </Constant.NPDTableCell>
                {level2.map(level2 => {
                    let l2_item_summedl2_current = l1_item_summedl2_current[level2] || {}
                    let l2_item_summedl2_prior = l1_item_summedl2_prior[priorLevelValues[level2]] || {}

                    let total_units = l2_item_summedl2_current['totalunits'] || 0
                    if (total_units <= 0) {
                        return null
                    }
                    return <Constant.NPDTableRow className={"level2rows"} key={"datarow_" + level2 + "_g8"} style={{ textAlign: 'center', width: 400 }}>
                        <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                            {level2}
                        </Constant.NPDTableCell>
                        {tableMetrics.map(metric => {
                            let value_raw = l2_item_summedl2_current[metric.value] || 0
                            var value
                            if (metric.denominator) {
                                let denominator
                                if (metric.denominator === 'dollarshare') {
                                    denominator = current_total_dollars
                                } else if (metric.denominator === 'unitshare') {
                                    denominator = current_total_units
                                } else {
                                    denominator = l2_item_summedl2_current[metric.denominator] || 0
                                }

                                value = safe_value(value_raw / denominator)
                            } else {
                                value = value_raw
                            }
                            return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                {value.toLocaleString(undefined, format_lookup[metric.format])}
                            </Constant.NPDTableCell>
                        })}
                        {tableMetrics.map(metric => {
                            let current_val_raw = l2_item_summedl2_current[metric.value] || 0
                            let prior_val_raw = l2_item_summedl2_prior[metric.value] || 0
                            var pct_change
                            var format_obj = pct_format_obj
                            var pts_string = ""
                            if (metric.denominator) {
                                let denominator_curr
                                let denominator_prior
                                if (metric.denominator === 'dollarshare') {
                                    denominator_curr = current_total_dollars
                                    denominator_prior = prior_total_dollars
                                } else if (metric.denominator === 'unitshare') {
                                    denominator_curr = current_total_units
                                    denominator_prior = prior_total_units
                                } else {
                                    denominator_curr = l2_item_summedl2_current[metric.denominator] || 0
                                    denominator_prior = l2_item_summedl2_prior[metric.denominator] || 0
                                }

                                pct_change = safe_value((current_val_raw / denominator_curr - prior_val_raw / denominator_prior) * 100)
                                format_obj = { maximumFractionDigits: 1 }
                                pts_string = ' pts'
                            } else {
                                pct_change = safe_value((current_val_raw - prior_val_raw) / prior_val_raw)
                            }

                            return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: color_plus_minus(pct_change), fontWeight: 'bold' }}>
                                {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                            </Constant.NPDTableCell>
                        })}
                    </Constant.NPDTableRow>
                })}
            </Constant.NPDTableRow>
        })
    }

    const render_l3_rows = () => {
        return level1.map(level1 => {
            let level1_exists = summedData_current_level1[level1]
            if (!level1_exists) {
                return null
            }
            let total_units = (summedData_current_level1[level1] || {})['totalunits'] || 0
            if (total_units <= 0) {
                return null
            }
            let l1_item_summedl2_current = summedData_current_level2[level1] || {}
            let l1_item_summedl2_prior = summedData_prior_level2[priorLevelValues[level1]] || {}
            let l1_item_summedl3_current = summedData_current_level3[level1] || {}
            let l1_item_summedl3_prior = summedData_prior_level3[priorLevelValues[level1]] || {}
            return <Constant.NPDTableRow key={"datarow_" + level1 + "_g8"} >
                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                    {level1}
                </Constant.NPDTableCell>
                {level2.map(level2 => {
                    let l2_item_summedl2_current = l1_item_summedl2_current[level2] || {}
                    let l2_item_summedl2_prior = l1_item_summedl2_prior[priorLevelValues[level2]] || {}
                    let total_units = l2_item_summedl2_current['totalunits'] || 0
                    if (total_units <= 0) {
                        return null
                    }
                    let l2_item_summedl3_current = l1_item_summedl3_current[level2] || {}
                    let l2_item_summedl3_prior = l1_item_summedl3_prior[priorLevelValues[level2]] || {}
                    return <Constant.NPDTableRow key={"datarow_" + level2 + "_g8"} className={"level2rows"} style={{ textAlign: 'center', width: 400, minWidth: '150px' }}>
                        <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                            {level2}
                        </Constant.NPDTableCell>
                        {level3.map(level3 => {
                            let l3_item_summedl3_current = l2_item_summedl3_current[level3] || {}
                            let l3_item_summedl3_prior = l2_item_summedl3_prior[priorLevelValues[level3]] || {}
                            // Check if level 3 of total units > 0
                            let total_units = l3_item_summedl3_current['totalunits'] || 0
                            if (total_units <= 0) {
                                return null
                            }
                            return <Constant.NPDTableRow key={"datarow_" + level3 + "_g8"} className={"level3rows"} style={{ textAlign: 'center', width: 400, minWidth: '150px' }}>
                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                    {level3}
                                </Constant.NPDTableCell>
                                {
                                    tableMetrics.map(metric => {
                                        let value_raw = l3_item_summedl3_current[metric.value] || 0
                                        var value
                                        if (metric.denominator) {
                                            let denominator = l2_item_summedl2_current[metric.denominator] || 0
                                            if (metric.denominator === 'dollarshare') {
                                                denominator = l2_item_summedl2_current['totaldollars'] || 0
                                            } else if (metric.denominator === 'unitshare') {
                                                denominator = l2_item_summedl2_current['totalunits'] || 0
                                            }

                                            value = safe_value(value_raw / denominator)
                                        } else {
                                            value = value_raw
                                        }
                                        return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                            {value.toLocaleString(undefined, format_lookup[metric.format])}
                                        </Constant.NPDTableCell>
                                    })
                                }
                                {
                                    tableMetrics.map(metric => {
                                        let current_val_raw = l3_item_summedl3_current[metric.value] || 0
                                        let prior_val_raw = l3_item_summedl3_prior[metric.value] || 0
                                        var pct_change
                                        var format_obj = pct_format_obj
                                        var pts_string = ""
                                        if (metric.denominator) {
                                            let denominator_curr
                                            let denominator_prior
                                            if (metric.denominator === 'dollarshare') {
                                                denominator_curr = l2_item_summedl2_current['totaldollars'] || 0
                                                denominator_prior = l2_item_summedl2_prior['totaldollars'] || 0
                                            } else if (metric.denominator === 'unitshare') {
                                                denominator_curr = l2_item_summedl2_current['totalunits'] || 0
                                                denominator_prior = l2_item_summedl2_prior['totalunits'] || 0
                                            } else {
                                                denominator_curr = l3_item_summedl3_current[metric.denominator] || 0
                                                denominator_prior = l3_item_summedl3_prior[metric.denominator] || 0
                                            }

                                            pct_change = safe_value((current_val_raw / denominator_curr - prior_val_raw / denominator_prior) * 100)
                                            format_obj = { maximumFractionDigits: 1 }
                                            pts_string = ' pts'
                                        } else {
                                            pct_change = safe_value((current_val_raw - prior_val_raw) / prior_val_raw)
                                        }

                                        return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: color_plus_minus(pct_change), fontWeight: 'bold' }}>
                                            {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                        </Constant.NPDTableCell>
                                    })
                                }
                            </Constant.NPDTableRow>
                        })}
                    </Constant.NPDTableRow>
                })}
            </Constant.NPDTableRow>
        })
    }

    const render_l4_rows = () => {
        return level1.map(level1 => {
            let level1_exists = summedData_current_level1[level1]
            if (!level1_exists) {
                return null
            }
            let total_units = (summedData_current_level1[level1] || {})['totalunits'] || 0
            if (total_units <= 0) {
                return null
            }
            let l1_item_summedl2_current = summedData_current_level2[level1] || {}
            let l1_item_summedl3_current = summedData_current_level3[level1] || {}
            let l1_item_summedl3_prior = summedData_prior_level3[priorLevelValues[level1]] || {}
            let l1_item_summedl4_current = summedData_current_level4[level1] || {}
            let l1_item_summedl4_prior = summedData_prior_level4[priorLevelValues[level1]] || {}
            return <Constant.NPDTableRow key={"datarow_" + level1 + "_g8"}>
                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                    {level1}
                </Constant.NPDTableCell>
                {level2.map(level2 => {
                    let level2_exists = l1_item_summedl2_current[level2]
                    if (!level2_exists) {
                        return null
                    }
                    let l2_item_summedl2_current = l1_item_summedl2_current[level2] || {}
                    let total_units = l2_item_summedl2_current['totalunits'] || 0
                    if (total_units <= 0) {
                        return null
                    }
                    let l2_item_summedl3_current = l1_item_summedl3_current[level2] || {}
                    let l2_item_summedl3_prior = l1_item_summedl3_prior[priorLevelValues[level2]] || {}
                    let l2_item_summedl4_current = l1_item_summedl4_current[level2] || {}
                    let l2_item_summedl4_prior = l1_item_summedl4_prior[priorLevelValues[level2]] || {}
                    return <Constant.NPDTableRow key={"datarow_" + level2 + "_g8"} style={{ textAlign: 'center', width: 400 }} className={"level2rows"}>
                        <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                            {level2}
                        </Constant.NPDTableCell>
                        {level3.map(level3 => {
                            // Check if level 3 exists
                            let level3_exists = l2_item_summedl3_current[level3]
                            if (!level3_exists) {
                                return null
                            }
                            let l3_item_summedl3_current = l2_item_summedl3_current[level3] || {}
                            let l3_item_summedl3_prior = l2_item_summedl3_prior[priorLevelValues[level3]] || {}
                            let l3_item_summedl4_current = l2_item_summedl4_current[level3] || {}
                            let l3_item_summedl4_prior = l2_item_summedl4_prior[priorLevelValues[level3]] || {}
                            let total_units = l3_item_summedl3_current['totalunits'] || 0
                            if (total_units <= 0) {
                                return null
                            }
                            return <Constant.NPDTableRow key={"datarow_" + level3 + "_g8"} className={"level3rows"} style={{ textAlign: 'center', width: 400, minWidth: '150px' }}>
                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                    {level3}
                                </Constant.NPDTableCell>
                                {level4.map(level4 => {
                                    let l4_item_summedl4_current = l3_item_summedl4_current[level4] || {}
                                    let l4_item_summedl4_prior = l3_item_summedl4_prior[priorLevelValues[level4]] || {}
                                    let total_units = l4_item_summedl4_current['totalunits'] || 0
                                    if (total_units <= 0) {
                                        return null
                                    }
                                    return <Constant.NPDTableRow key={"datarow_" + level4 + "_g8"} className={"level4rows"} style={{ textAlign: 'center', width: 400, minWidth: '150px' }}>
                                        <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                            {level4}
                                        </Constant.NPDTableCell>
                                        {
                                            tableMetrics.map(metric => {
                                                let value_raw = l4_item_summedl4_current[metric.value] || 0
                                                var value
                                                if (metric.denominator) {
                                                    let denominator = l4_item_summedl4_current[metric.denominator] || 0
                                                    if (metric.denominator === 'dollarshare') {
                                                        denominator = l3_item_summedl3_current['totaldollars'] || 0
                                                    } else if (metric.denominator === 'unitshare') {
                                                        denominator = l3_item_summedl3_current['totalunits'] || 0
                                                    }
                                                    value = safe_value(value_raw / denominator)
                                                } else {
                                                    value = value_raw
                                                }
                                                return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                    {value.toLocaleString(undefined, format_lookup[metric.format])}
                                                </Constant.NPDTableCell>
                                            })
                                        }
                                        {
                                            tableMetrics.map(metric => {
                                                let current_val_raw = l4_item_summedl4_current[metric.value] || 0
                                                let prior_val_raw = l4_item_summedl4_prior[metric.value] || 0
                                                var current_val, prior_val, pct_change, rom_gap, share_impact, fair_share_opp
                                                var format_obj = pct_format_obj
                                                var pts_string = ""
                                                if (metric.denominator) {
                                                    let denominator_curr
                                                    let denominator_prior
                                                    if (metric.denominator === 'dollarshare') {
                                                        denominator_curr = l3_item_summedl3_current['totaldollars'] || 0
                                                        denominator_prior = l3_item_summedl3_prior['totaldollars'] || 0
                                                    } else if (metric.denominator === 'unitshare') {
                                                        denominator_curr = l3_item_summedl3_current['totalunits'] || 0
                                                        denominator_prior = l3_item_summedl3_prior['totalunits'] || 0
                                                    } else {
                                                        denominator_curr = l4_item_summedl4_current[metric.denominator] || 0
                                                        denominator_prior = l4_item_summedl4_prior[metric.denominator] || 0
                                                    }

                                                    current_val = current_val_raw / denominator_curr
                                                    prior_val = prior_val_raw / denominator_prior

                                                    pct_change = safe_value((current_val - prior_val) * 100)
                                                    format_obj = { maximumFractionDigits: 1 }
                                                    pts_string = ' pts'

                                                    // If level4 !== ROM we need to add the gaps
                                                    if (level4 !== 'ROM') {
                                                        let l4_item_summedl4_current_rom = l3_item_summedl4_current['ROM'] || {}
                                                        if (metric.denominator === 'dollarshare') {
                                                            // Calculate the Impact of Share Change and Fair Share Opportunity
                                                            let total_cat_dollars = l2_item_summedl2_current['totaldollars'] || 0
                                                            let total_row_dollars_rom = l4_item_summedl4_current_rom['totaldollars'] || 0
                                                            let total_row_dollars_alr = l4_item_summedl4_current['totaldollars'] || 0
                                                            let total_row_dollars = total_row_dollars_alr + total_row_dollars_rom
                                                            share_impact = safe_value((current_val - prior_val) * total_cat_dollars)
                                                            fair_share_opp = safe_value((current_val - prior_val) * total_row_dollars)
                                                        } else if (metric.denominator === 'unitshare') {
                                                            // No Additional Metrics Defined for unitshare
                                                        } else {
                                                            // Calculate the Gap to ROM
                                                            let numerator_curr_rom = l4_item_summedl4_current_rom[metric.value] || 0
                                                            let denom_curr_rom = l4_item_summedl4_current_rom[metric.denominator] || 0
                                                            rom_gap = safe_value((current_val - numerator_curr_rom / denom_curr_rom) * 100)
                                                        }
                                                    }
                                                } else {
                                                    pct_change = safe_value((current_val_raw - prior_val_raw) / prior_val_raw)
                                                }

                                                let colorStyle = color_plus_minus(pct_change)
                                                if (level4 !== 'ROM') {
                                                    if (metric.denominator === 'dollarshare') {
                                                        return <Fragment>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                            </Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                {share_impact.toLocaleString(undefined, currency_format_obj)}
                                                            </Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                {fair_share_opp.toLocaleString(undefined, currency_format_obj)}
                                                            </Constant.NPDTableCell>
                                                        </Fragment>
                                                    } else if (metric.denominator === 'unitshare') {
                                                        return <Fragment>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                            </Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>  </Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>  </Constant.NPDTableCell>
                                                        </Fragment>
                                                    } else if (metric.denominator) {
                                                        return <Fragment>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                            </Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                {(rom_gap || 0).toLocaleString(undefined, format_obj) + ' pts'}
                                                            </Constant.NPDTableCell>
                                                        </Fragment>
                                                    } else {
                                                        if (typeof (metric.denominator) !== 'undefined' && metric.denominator != null) {
                                                            return <Fragment>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                    {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                                </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>  </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>  </Constant.NPDTableCell>
                                                            </Fragment>
                                                        } else {
                                                            return <Fragment>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                    {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                                </Constant.NPDTableCell>
                                                            </Fragment>
                                                        }
                                                    }
                                                } else {
                                                    if (typeof (metric.denominator) !== 'undefined' && metric.denominator != null) {
                                                        if (metric.value === 'totaldollars') {
                                                            return <Fragment>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                    {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                                </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>  </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>  </Constant.NPDTableCell>
                                                            </Fragment>
                                                        } else {
                                                            return <Fragment>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                    {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                                </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>  </Constant.NPDTableCell>
                                                            </Fragment>
                                                        }
                                                    } else {
                                                        return <Fragment>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px', color: colorStyle, fontWeight: 'bold' }}>
                                                                {pct_change.toLocaleString(undefined, format_obj) + pts_string}
                                                            </Constant.NPDTableCell>
                                                        </Fragment>
                                                    }
                                                }
                                            })
                                        }
                                    </Constant.NPDTableRow>
                                })}
                            </Constant.NPDTableRow>
                        })}
                    </Constant.NPDTableRow>
                })}
            </Constant.NPDTableRow>
        })
    }

    return (
        this.state.dataLoading ?
            <div style={{ width: "100%", height: "100%", minHeight: '500px' }}>
                Data is loading
                {/*<CircularProgress color="inherit" />*/}
                <div class="custom-loader"></div>
            </div>
            : (!store_group_is_alr) ?
                <div style={{ width: "100%", height: "100%", minHeight: '500px' }}>
                    This chart will only render when the report's selected Store Group is an ALR and when the filter for Outlet, if any, does not exclude the
                    ALR or ROM data.  If the report's Store Group is an ALR, and if the filter does not exclude the ALR or the ROM data, then ensure that
                    attribute filters do not accidentally filter out all data from either the ALR or the ROM.
                </div>
            :
            <div style={{ width: "100%", height: "100%", minHeight: '500px', backgroundColor: '#F4F4F4', borderRadius: '10px', padding: '20px', boxShadow: '10px 10px 5px rgba(0, 0, 0, .1)' }}>
                <div className='row col-sm-12'>
                    <div className='col-sm-8'>
                        <span className="formfieldtitle">Select Metrics</span>
                        <Select
                            options={this.state.metricOptions}
                            placeholder={'Select Metrics'}
                            onChange={(item) => this.changeSelectedMetrics(item)}
                            styles={dropdownStyles}
                            styles={selectColourStyles}
                            value={this.state.selectedMetrics}
                            isMulti
                            isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
                        />
                    </div>
                    <div className='col-sm-4' align='left'>
                        <LastYearSwitch
                            state={this.props.state}
                            handleChange_twoyago={this.props.handleChange_twoyago}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <FilterBarReportViewsFunctional />
                    </div>
                </div>
                <div className='row col-sm-12'>
                    <div align='center' className="col-sm-11">
                        <br />
                        Levels for this chart are {levelLabels.join(',')}
                    </div>
                    <div align='right' className="col-sm-1">
                        {menu}
                    </div>
                    {(!has_past_data) && <div align='left'>
                        No past data is available, so the download button is disabled and all YOY statistics are all 0
                    </div>}
                </div>
                <SelectionInformation
                    state={this.props.state}
                />
                {this.state.selectedMetrics.length === 0 ?
                    <div>Please select at least one Metric to see the table</div>
                :
                    <div style={{ width: "100%", height: "100%" }} id='MainTable'>
                        {highest_level === 1 ?
                            // ------------------------------------------- Showing one level -------------------------------------------
                            <div style={{ width: "100%", height: "100%" }} id='MainTableLevel1'>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g8" className="sticky">
                                            {headers.map((header, idx) => {
                                                if (idx === 0) {
                                                    return <Constant.NPDTableCell style={{ textAlign: 'center', width: 300, minWidth: '150px' }}>
                                                        {header}
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            style={{ paddingRight: '5px' }}
                                                            onClick={(level) => this.expandLevel(2)}
                                                        >
                                                            <Tooltip title={'Expand a Level'} placement="bottom">
                                                                <AddBoxRoundedIcon fontSize="medium" />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                } else {
                                                    return <Constant.NPDTableCell style={{ textAlign: 'center', width: 300, minWidth: '150px' }}>{header}</Constant.NPDTableCell>
                                                }
                                            })}
                                        </Constant.NPDTableRow>
                                        {render_l1_rows()}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                        : highest_level === 2 ?
                            // ------------------------------------------- Showing two levels -------------------------------------------
                            <div style={{ width: "100%", height: "100%" }} id='MainTableLevel2'>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g8" className="sticky">
                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                {levelLabels[0]}
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    style={{ paddingRight: '5px' }}
                                                    onClick={(level) => this.reduceLevel(2)}
                                                >
                                                    <Tooltip title={'Reduce a Level'} placement="bottom">
                                                        <RemoveRoundedIcon fontSize="medium" />
                                                    </Tooltip>
                                                </IconButton>
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableRow key="header_g8">
                                                {headers.map((header, idx) => {
                                                    if (idx === 0) {
                                                        return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                            {header}
                                                            <IconButton
                                                                aria-label="close"
                                                                color="inherit"
                                                                style={{ paddingRight: '5px' }}
                                                                onClick={(level) => this.expandLevel(3)}
                                                            >
                                                                <Tooltip title={'Expand a Level'} placement="bottom">
                                                                    <AddBoxRoundedIcon fontSize="medium" />
                                                                </Tooltip>
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                    } else {
                                                        return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>{header}</Constant.NPDTableCell>
                                                    }
                                                })}
                                            </Constant.NPDTableRow>
                                        </Constant.NPDTableRow>
                                        {render_l2_rows()}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                        : highest_level === 3 ?
                            // ------------------------------------------- Showing three levels -------------------------------------------
                            <div style={{ width: "100%", height: "100%" }} id='MainTableLevel3'>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g8" className="sticky">
                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                {levelLabels[0]}
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    style={{ paddingRight: '5px' }}
                                                    onClick={(level) => this.reduceLevel(2)}
                                                >
                                                    <Tooltip title={'Reduce a Level'} placement="bottom">
                                                        <RemoveRoundedIcon fontSize="medium" />
                                                    </Tooltip>
                                                </IconButton>
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableRow key="header_g8">
                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                    {levelLabels[1]}
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        style={{ paddingRight: '5px' }}
                                                        onClick={(level) => this.reduceLevel(3)}
                                                    >
                                                        <Tooltip title={'Reduce a Level'} placement="bottom">
                                                            <RemoveRoundedIcon fontSize="medium" />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableRow key="header_g8" style={{ textAlign: 'center', width: 400 }}>
                                                    {headers.map((header, idx) => {
                                                        if (idx === 0) {
                                                            return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                                {header}
                                                                <IconButton
                                                                    aria-label="close"
                                                                    color="inherit"
                                                                    style={{ paddingRight: '5px' }}
                                                                    onClick={(level) => this.expandLevel(4)}
                                                                >
                                                                    <Tooltip title={'Expand a Level'} placement="bottom">
                                                                        <AddBoxRoundedIcon fontSize="medium" />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </Constant.NPDTableCell>
                                                        } else {
                                                            return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>{header}</Constant.NPDTableCell>
                                                        }
                                                    })}
                                                </Constant.NPDTableRow>
                                            </Constant.NPDTableRow>
                                        </Constant.NPDTableRow>
                                        {render_l3_rows()}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                        : highest_level === 4 ?
                            // ------------------------------------------- Showing four levels -------------------------------------------
                            <div style={{ width: "100%", height: "100%" }} id='MainTableLevel4'>
                                <Constant.NPDTable>
                                    <Constant.NPDTableBody>
                                        <Constant.NPDTableRow key="header_g8" className="sticky">
                                            <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                {levelLabels[0]}
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    style={{ paddingRight: '5px' }}
                                                    onClick={(level) => this.reduceLevel(2)}
                                                >
                                                    <Tooltip title={'Reduce a Level'} placement="bottom">
                                                        <RemoveRoundedIcon fontSize="medium" />
                                                    </Tooltip>
                                                </IconButton>
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableRow key="header_g8">
                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                    {levelLabels[1]}
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        style={{ paddingRight: '5px' }}
                                                        onClick={(level) => this.reduceLevel(3)}
                                                    >
                                                        <Tooltip title={'Reduce a Level'} placement="bottom">
                                                            <RemoveRoundedIcon fontSize="medium" />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>
                                                    {levelLabels[2]}
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        style={{ paddingRight: '5px' }}
                                                        onClick={(level) => this.reduceLevel(4)}
                                                    >
                                                        <Tooltip title={'Reduce a Level'} placement="bottom">
                                                            <RemoveRoundedIcon fontSize="medium" />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Constant.NPDTableCell>
                                                <Constant.NPDTableRow key="header_g8" style={{ textAlign: 'center', width: 400, minWidth: '150px' }}>
                                                    {headers.map((header, idx) => {
                                                        return <Constant.NPDTableCell style={{ textAlign: 'center', width: 150, minWidth: '150px' }}>{header}</Constant.NPDTableCell>
                                                    })}
                                                </Constant.NPDTableRow>
                                            </Constant.NPDTableRow>
                                        </Constant.NPDTableRow>
                                        {render_l4_rows()}
                                    </Constant.NPDTableBody>
                                </Constant.NPDTable>
                            </div>
                        :
                            // ------------------------------------------- Bug if we get here -------------------------------------------
                            <div />
                        }
                    </div>
                }
            </div>
    )
}
