export default function showHideFilters () {
    var filterState = this.state.displayFilters;
    if (filterState === 'none') {

        this.setState({ displayFilters: 'flex' })
    }
    else if (filterState === 'flex') {

        this.setState({ displayFilters: 'none' })
    }
}