import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { StackingTypeCheckbox } from '../../UIElements/Checkboxes/StackingTypeCheckbox'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import Select from 'react-select'
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import CreateFilters from '../../Functions/Utilities/CreateFilters'
import assignFilterLevels from '../../Functions/Utilities/AssignFilterLevels'
import { FlexibleViewGraph } from './FlexibleViewGraph'
import * as Constant from '../../../constants';

export class FlexibleView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aggregated: false,
            trended: false
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            return true
        }
        else {
            return false
        }
    }

    render() {
        console.log(this.state)

        const handleChangeAggTrended = event => {
            console.log(event.target.value)
            let val = event.target.value
            if (val === 'trended') {
                this.setState({
                    trended: true,
                    aggregated: false
                })
            }

            else if (val === 'aggregated') {
                this.setState({
                    trended: false,
                    aggregated: true
                })
            }
        }

        const changeSelectedMetrics = selectedOptions => {
            console.log(selectedOptions)
            if (selectedOptions) {
                this.setState({
                    selectedMetrics: selectedOptions
                })
            }
            else {
                this.setState({
                    selectedMetrics: []
                })
            }
        }

        const changeSelectedLevel = selectedOptions => {
            console.log(selectedOptions)
            if (selectedOptions) {
                this.setState({
                    selectedLevel1: selectedOptions
                })
            }
            else {
                this.setState({
                    selectedLevel1: null
                })
            }
        }

        const changeSelectedLevel2 = selectedOptions => {
            console.log(selectedOptions)
            if (selectedOptions) {
                this.setState({
                    selectedLevel2: selectedOptions
                })
            }
            else {
                this.setState({
                    selectedLevel2: null
                })
            }
        }

        const clearSelections = event => {
            console.log(event)
            this.setState({
                trended: false,
                aggregated: false,
                selectedMetrics: [],
                selectedLevel1: null,
                selectedLevel2: null,
                aggregated_data: {}
            })
        }

        const applySelections = async event => {
            var aggregated_data, url_for_pull, level1_val, level2_val

            console.log('Perform Error Checking to Ensure we have correct parts')
            console.log('Build Appropriate Filters...')
            let filters_to_use = this.props.state.final_filters || this.props.state.filter_object
            let exclude_array = this.props.state.exclude_array
            let weeks_info = this.props.state.weeks_info
            let daterange = 'currentyear'
            let priceBandOptions = this.props.state.priceBandOptions
            let filters_current = CreateFilters(filters_to_use, exclude_array, weeks_info, daterange, priceBandOptions)

            console.log('Build Use Appropriate API Call')
            let reportid = this.props.state.reportsettings.report_id
            let extraparam = this.props.state.extraparam

            var use_level1, use_level2
            if (this.state.selectedLevel1) {
                use_level1 = true
            }

            if (this.state.selectedLevel2) {
                use_level2 = true
            }

            if (this.state.trended) {
                url_for_pull = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam
            }
            else {
                if (use_level1 && !use_level2) {
                    url_for_pull = Constant.baseapiurl + "ReportData/Filtered/Level1Data/" + reportid + extraparam
                }
                else if (use_level1 && use_level2) {
                    url_for_pull = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam
                }
            }

            console.log('Build Appropriate JSON Object')

            var lookups = []

            let levels_lookup = {
                'Total Selection': 'total',
                'Brand': 'brand',
                'Channel': 'geogdesc',
                'Outlet': 'outlet',
                'Price Band': 'pricebandgroup',
                'Item': 'proddesc',
            }

            levels_lookup[this.props.state.segment1label] = 'segment1_name'
            levels_lookup[this.props.state.segment2label] = 'segment2_name'
            levels_lookup[this.props.state.segment3label] = 'segment3_name'
            levels_lookup[this.props.state.segment4label] = 'segment4_name'

            //Add Attributes to lookup
            Object.keys(this.props.state.attributes || {}).forEach(attr => {
                levels_lookup[this.props.state.attributenamelabels[attr]] = attr
            })

            if (this.state.trended) {
                level1_val = levels_lookup[this.state.selectedLevel1.label]
                lookups.push(level1_val)
                lookups.push('rowdate')
            }
            else {
                if (use_level2) {
                    level2_val = levels_lookup[this.state.selectedLevel2.label]
                    lookups.push(level2_val)
                    level1_val = levels_lookup[this.state.selectedLevel1.label]
                    lookups.push(level1_val)
                }
                else {
                    level1_val = levels_lookup[this.state.selectedLevel1.label]
                    lookups.push(level1_val)
                }
            }

            let filters_for_pull = assignFilterLevels(filters_current, lookups)
            console.log('Data Pull Started')
            let aggregated_response = await fetch(url_for_pull,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_for_pull)
                })
            console.log('Data Pull Finished')

            if (aggregated_response.status === 200) {
                aggregated_data = await aggregated_response.json()
            } else {
                console.log('***** FlexibleView.js applySelections failed to fetch ' + url_for_pull +
                            ' with status ' + aggregated_response.status + ', setting errorAlert_report = true')
                aggregated_data = {}
                this.setState({ errorAlert_report: true })
            }

            console.log('Set State')
            this.setState({ aggregated_data })
        }

        let newOptions = []
        let usedvalues = ['Total']
        this.props.state.levelOptions_BaseInc.forEach(option => {
            if (!usedvalues.includes(option.value)) {
                usedvalues.push(option.value)
                newOptions.push(option)
            }
        })

        let shareMetrics = ['Base', 'Subsidized Base', 'UnSubsidized Base', 'Incremental', 'Promoted', 'Non-Promoted']

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>                
                <div style={{ height: '100%', width: "100%", }}>
                    <div className='col-sm-12 row'>
                        <div className='col-sm-3'>
                            <ThemeProvider theme={Constant.muiTheme}>
                                <Radio
                                    checked={this.state.aggregated}
                                    onChange={handleChangeAggTrended}
                                    value="aggregated"
                                />
                                <span style={{ lineHeight: '5' }}>Aggregated</span>
                                <Radio
                                    checked={this.state.trended}
                                    onChange={handleChangeAggTrended}
                                    value="trended"
                                />
                                <span style={{ lineHeight: '5' }}>Trended</span>
                            </ThemeProvider>
                        </div>
                        <div className='col-sm-3'>
                            <span className="formfieldtitle">Select Metrics</span>
                            <Select
                                options={this.props.state.metricOptions.concat(shareMetrics.map(metric => { return { 'label': 'Share of ' + metric, 'value': 'Share of ' + metric } }))}
                                placeholder={'Select A Metric'}
                                onChange={changeSelectedMetrics}
                                value={this.state.selectedMetrics}
                                styles={Constant.dropdownStyles}
                                isMulti
                            />
                        </div>
                        {this.state.aggregated ?
                            <div className='col-sm-3'>
                                <span className="formfieldtitle">Select A Level For X-axis</span>
                                <Select
                                    options={newOptions.concat(Object.keys(this.props.state.attributes).map(attr => { return { 'label': this.props.state.attributenamelabels[attr], 'value': attr } }))}
                                    placeholder={'Select A Level'}
                                    onChange={changeSelectedLevel}
                                    value={this.state.selectedLevel1}
                                    styles={Constant.dropdownStyles}
                                />
                            </div>
                            :
                            this.state.trended ?
                                <div className='col-sm-3'>
                                    <span className="formfieldtitle">Select A Level to Page By</span>
                                    <Select
                                        options={newOptions.concat(Object.keys(this.props.state.attributes).map(attr => { return { 'label': this.props.state.attributenamelabels[attr], 'value': attr } }))}
                                        placeholder={'Select A Level'}
                                        onChange={changeSelectedLevel}
                                        value={this.state.selectedLevel1}
                                        styles={Constant.dropdownStyles}
                                    />
                                </div>
                                :
                                <div className='col-sm-3'/>
                        }
                        {this.state.aggregated ?
                            <div className='col-sm-3'>

                                <span className="formfieldtitle">Optional: Select A Level to Page By</span>
                                <Select
                                    options={newOptions.concat(Object.keys(this.props.state.attributes).map(attr => { return { 'label': this.props.state.attributenamelabels[attr], 'value': attr } }))}
                                    placeholder={'Select A Level'}
                                    onChange={changeSelectedLevel2}
                                    value={this.state.selectedLevel2}
                                    styles={Constant.dropdownStyles}
                                />
                            </div>
                            :
                            <div className='col-sm-3' />
                        }
                    </div>
                    <div className='col-sm-12 row'>
                        <IconButton
                            className="btn btn-primary ChangeDataTableButton"
                            onClick={clearSelections}
                            style={{ padding: '5px', margin: '5px' }}
                            id='ClearSelections'
                        >
                            <Tooltip title={'Clear Selections'} placement="right">
                                <RestartAltRoundedIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            className="btn btn-primary ChangeDataTableButton"
                            onClick={applySelections}
                            style={{ padding: '5px', margin: '5px' }}
                            id='ApplySelections'
                        >
                            <Tooltip title={'Create Charts'} placement="right">
                                <PlayArrowRoundedIcon />
                            </Tooltip>
                        </IconButton>
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                        <StackingTypeCheckbox
                            state={this.props.state}
                            handleToggle_stackingType={this.props.handleToggle_stackingType}
                        />
                    </div>
                    <div>
                        <FlexibleViewGraph
                            state={this.state}
                            globalstate={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
