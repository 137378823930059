import React, { Component, Fragment } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Layout } from '../Layout';
import { DropdownIndicator, IndicatorSeparator } from '../Dropdown'
import Select from 'react-select'
import { isNull } from 'util';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Datetime from 'react-datetime'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover'
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import CloseIcon from '@material-ui/icons/Close';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Constant from '../constants'
import '../styles/custom.css'
import selectColourStyles from '../styles/SelectColourStyles'
import dropdownStyles from '../styles/DropdownStyles'

const steps = ['Define Criteria', 'Add Items', 'Input Simulation Data', 'Add Financials']
const alloption = { 'label': 'Select All', 'value': '*' }

function basePricesToLocalStorage(simId, itemIds, basePrices) {
    if (simId == null) {
        return
    }
    const simBasePrices = [...Array(itemIds.length).keys()].reduce((prev, current) => ({...prev, [itemIds[current]]: String(basePrices[current])}), {})
    localStorage.setItem(String(simId), JSON.stringify(simBasePrices))
}

function basePricesFromLocalStorage(simId, itemIds) {
    if (simId == null) {
        return itemIds.map(itemId => null)
    }
    const simBasePrices = JSON.parse(localStorage.getItem(String(simId)) || "{}")
    return itemIds.map(itemId => simBasePrices[itemId])
}

export class SimulationEntry extends Component {
    static displayName = SimulationEntry.name

    constructor(props) {
        super(props);
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        
    }
    
    state = {
        activeStep: 0,
        showBackdrop_CreateSim: false,        
        displayReloadDialog: false,
        reloadDialog: '',
        allSimNames: [],
        skipped: new Set(),
        coverage: 1,
        simtype: "",
        DataSetChosen: 0,
        DataSetChosen_option: [],
        Geochosen: [],
        selectedattributes: [],
        matching_prodids_attr : null,
        matching_prodids_asp: null,
        matching_prodids_pricebands: null,
        matching_items_proddesc: [],
        bulkeditdisplay: 'none',
        item_error_display: 'none',
        openRemoveAllItemsErrorDialog: false,
        openInfoProject: false,
        errorAlert: false,
        openInfoCoverage: false,
        openInfoMyBrandItems: false,
        openInfoCompetitors: false,
        openInfoOtherItems: false,
        openBulkEditModal: false,
        openInfoHistData: false,
        openInfoSimData: false,
        bulkediterror: false,
        bulkedit_error_text: "",
        AllAttrsModalOpen: false,
        ItemGroupsModalOpen: false,
        itemgroupssettings_data: {}
        //loadedfromstate: false
    }
    
    addBackdrop = () => {
        this.setState({ showBackdrop_CreateSim: true })
    }

    removeBackdrop = () => {
        //Wait 2 seconds..long term solution is to make the functions await so we can remove once all is loaded.
        setTimeout(function () {
            this.setState({ showBackdrop_CreateSim: false })
        }.bind(this), 2000);
        
    }

    findid = () => {
        //Get the id from the page:
        var url = window.location.href;
        console.log(url)
        var temp = url.split("/");
        console.log(temp)
        var i = unescape(temp[5]);
        var idnum = parseInt(i, 10);
        return idnum;
    }

    SetDataSetInfo = async (datasetid) => {
        this.addBackdrop()
        //fetch datasetinfo
        fetch(Constant.baseapiurl + "Datasets/" + datasetid)
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                //Build Attributes lookup
                                let attributesfromdata = result.attributes
                                let hr_attributesfromdata = result.attributes_human
                                let attribute_lookup = {}
                                attributesfromdata.forEach((attr, idx) => attribute_lookup[attr] = hr_attributesfromdata[idx])
                                this.setState({ attribute_lookup })
                            })
                }
            })

        fetch(Constant.baseapiurl + "Datasets")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (results) => {
                                //console.log(results);
                                results.forEach(result => {
                                    //console.log(datasetid)
                                    var dataset_array = []
                                    if (result.dataset_id === datasetid) {
                                        //console.log('match')
                                        dataset_array.push({ 'value': result.dataset_id, 'label': result.shortdesc })
                                        if (Constant.entrytype === 'create') {
                                            this.setState({
                                                DataSetChosen_option: dataset_array,
                                                DataSetChosen: datasetid
                                            });
                                        }
                                        else {
                                            this.setState({
                                                datasets: dataset_array,
                                                DataSetChosen_option: dataset_array,
                                                DataSetChosen: datasetid
                                            });
                                        }
                                    }

                                    

                                })
                                this.removeBackdrop()
                            }
                        )
                        .catch(function (error) {
                            console.log(error)
                        })
                }
                else if (res.status === 511) {
                    res.json()
                        .then(
                            (results) => {
                                console.log(results);
                                this.setState({
                                    displayReloadDialog: true
                                    , reloadDialog: results["Reason"]
                                    , showBackdrop_CreateSim: false
                                })
                            })
                }
                else {
                    console.log('There was an in running the API')
                    //Show an error to the user...
                    this.setState({
                        displayReloadDialog: true
                        , reloadDialog: "There was an error loading data.  Please reload this page."
                        , showBackdrop_CreateSim: false
                    })
                }
            })

        //Set Currency Code...
        let datasetsettings_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + datasetid)
        var datasetsettings_data
        if (datasetsettings_resp.status === 200) {
            datasetsettings_data = await datasetsettings_resp.json()
        }
        else {
            datasetsettings_data = []
        }

        var datasetsettings_list = datasetsettings_data.map(setting => setting.setting_name)
        //console.log(datasetsettings_list)
        var currency_resp_data
        if (datasetsettings_list.includes('currency_info')) {
            let datasetsettings_currency_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + datasetid + '/currency_info')
            if (datasetsettings_currency_resp.status === 200) {
                currency_resp_data = await datasetsettings_currency_resp.json()
                console.log(currency_resp_data)
            }
            else {
                currency_resp_data = {
                    "setting_value": {
                        "currencycode": "USD",
                        "currencytype": "Dollars",
                        "currencysymbol": '$'
                    },
                }
            }
        }
        else {
            currency_resp_data = {
                "setting_value": {
                    "currencycode": "USD",
                    "currencytype": "Dollars",
                    "currencysymbol": '$'
                },
            }
        }

        let currencycode = currency_resp_data.setting_value.currencycode
        let currencytype = currency_resp_data.setting_value.currencytype
        let currencysymbol = currency_resp_data.setting_value.currencysymbol
        this.setState({
            currencycode, currencytype, currencysymbol
        })

    }
    //Get Geos for just a dataset...
    SetGeos = (datasetid, geo) => {
        this.addBackdrop()
        fetch(Constant.baseapiurl + "Datasets/" + datasetid + "/Parameters")
            .then(
                res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (results) => {
                                    //console.log(results)
                                    //Geos
                                    var geos_array = []
                                    var geo_string = ""

                                    results.geos.forEach(geo => {
                                        //console.log(geo)
                                        if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                                            geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
                                        }
                                        else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                                            geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
                                        }
                                        else if (geo.geogdesc.toUpperCase().indexOf('ASSG') > -1) {
                                            geo.geogdesc_modified = geo.geogdesc + ' (Athletic Specialty Sporting Goods)'
                                        }
                                        else {
                                            geo.geogdesc_modified = geo.geogdesc
                                        }
                                    })

                                    results.geos.map(geo => (geos_array.push({ 'value': geo.geogid, 'label': geo.geogdesc_modified })))
                                    results.geos.map(geo => geo_string += "," + geo.geogdesc_modified)
                                    var geoval = geo
                                    if (Constant.entrytype !== 'create') {
                                        results.geos.forEach(geo => {
                                            if (geo.geogid === geoval) {
                                                //console.log('Setting State...')
                                                this.setState({ Geochosen: { 'label': geo.geogdesc_modified, 'value': geo.geogid } })
                                            }
                                        })
                                    }


                                    this.setState({
                                        geo_string: geo_string.substring(1),
                                    })

                                    //console.log(geos_array)
                                    //get labels
                                    var geo_labels = geos_array.map(geo => geo.label)
                                    //Find if 'Total US' is in geo_labels and remove from the array....
                                    var TUS_index = geo_labels.indexOf('Total US')
                                    var sorted_geos = []
                                    if (TUS_index !== -1) {
                                        geo_labels.splice(TUS_index, 1)
                                        //Need to Sort geos_array with Total US first, and everything ekse sorted by the description
                                        sorted_geos = ['Total US'].concat(geo_labels.sort())

                                    }
                                    else {
                                        sorted_geos = geo_labels.sort()
                                    }                                    

                                    var finalsorted_geos = []
                                    sorted_geos.forEach(geo => {
                                        for (var g = 0; g < geos_array.length; g++) {
                                            if (geos_array[g].label === geo) {
                                                finalsorted_geos.push(geos_array[g])
                                            }
                                        }
                                    })

                                    //console.log(finalsorted_geos)
                                   // console.log('Setting State...')
                                    this.setState({ geos: finalsorted_geos })
                                    this.removeBackdrop()
                                })
                    }

                    else if (res.status === 511) {
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop_CreateSim: false
                                    })
                                })
                    }

                    else {
                        console.log('There was an Error Running the API')
                        //Show an error to the user...
                        this.setState({
                            displayReloadDialog: true
                            , reloadDialog: "There was an error loading data.  Please reload this page."
                            , showBackdrop_CreateSim: false
                        })
                    }
                })
    }
    //Sets Geos, Attributes, Dates, and Brands for a given dataset and geography
    SetParametersInfo = (datasetid, geoid) => {
        this.addBackdrop()
        fetch(Constant.baseapiurl + "Datasets/" + datasetid + "/Parameters?geos=" + geoid)
            .then(
                res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (results) => {
                                    //console.log(results);
                                    //attribute_object
                                    let attr_obj = {}
                                    results.attributes.forEach(attr => attr_obj[attr.attribute] = attr.attribute_values)
                                    //Attributes
                                    //order these...

                                    let attr_cols = results.attributes.map(attr => attr.attribute)
                                    var attribute_col_names = []
                                    //sort the attr_cols
                                    var subcat_index = attr_cols.indexOf('subcategory')
                                    //console.log(subcat_index)
                                    if (subcat_index !== -1) {
                                        attr_cols.splice(subcat_index, 1)
                                        //Need to Sort attribute_col_names with subcategory first, and everything else sorted by the description
                                        attribute_col_names = ['subcategory'].concat(attr_cols.sort())
                                    }
                                    else {
                                        attribute_col_names = attr_cols.sort()
                                    }         

                                    let sortedattributes = attribute_col_names.map(attr => { return { "attribute": attr, "attribute_values": attr_obj[attr]} })
                                    //console.log(sortedattributes)

                                    this.setState({ attributes: sortedattributes }) //results.attributes })
                                    //Dates
                                    function addDays(date, days) {
                                        var result = new Date(date);
                                        result.setDate(result.getDate() + days);
                                        //Get Day of week
                                        //console.log(result.getDay())
                                        //If Sunday, go back one day
                                        if (result.getDay() === 0) {
                                            result.setDate(result.getDate() - 1);
                                        }

                                        return result;
                                    }

                                    let end_date_from_data = Date.parse(results.times[1])
                                    //console.log(end_date_from_data)
                                    let end_date_from_data_one_week = addDays(end_date_from_data, -7)
                                    //console.log(end_date_from_data_one_week)
                                    let end_date_from_data_one_year = addDays(end_date_from_data, 365)
                                    //var enddate = results.times[1]
                                    var enddate_year = parseInt(end_date_from_data_one_week.toISOString().split('T')[0].substring(0, 4))
                                    var enddate_month = parseInt(end_date_from_data_one_week.toISOString().split('T')[0].substring(5, 7)) - 1 //Zero Based Month                                    
                                    var enddate_day = parseInt(end_date_from_data_one_week.toISOString().split('T')[0].substring(8, 10))
                                    var enddate_year_1 = parseInt(end_date_from_data_one_year.toISOString().split('T')[0].substring(0, 4))
                                    var enddate_month_1 = parseInt(end_date_from_data_one_year.toISOString().split('T')[0].substring(5, 7)) - 1 //Zero Based Month                                    
                                    var enddate_day_1 = parseInt(end_date_from_data_one_year.toISOString().split('T')[0].substring(8, 10))
                                    var futuredate = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day });
                                    var futuredateplustwo = Datetime.moment().set({ 'year': enddate_year_1, 'month': enddate_month_1, 'date': enddate_day_1 });
                                    //console.log('Setting State...')
                                    //console.log(futuredate)
                                    this.setState({ futuredate, futuredateplustwo })
                                    //Brands
                                    var brands_array = []
                                    results.brands.map(brand => (brands_array.push({ 'value': brand, 'label': brand })))
                                    let brand_string = ""
                                    results.brands.map(brand => brand_string += "," + brand)
                                    this.setState({
                                        brands: brands_array,
                                        brand_string: brand_string.substring(1)
                                    })
                                    this.removeBackdrop()
                                })
                    }

                    else if (res.status === 511) {
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop_CreateSim: false
                                    })
                                })
                    }

                    else {
                        console.log('There was an Error')
                    }
                })
    }

    SetDisplayTypes = (simtype, datasetid) => {
        var basedisplay_var = 'table-cell'
        var promodisplay_var = 'none'
        var promodisplayad_var = 'none'
        var adeffect_options = []
        var promoeffect_options = []
        var AdTypeALabel_var = ''
        var AdTypeBLabel_var = ''
        var AdTypeCLabel_var = ''
        var AdTypeCustomLabel_var = ''
        if (simtype === 'base') {
            basedisplay_var = 'table-cell'
            promodisplay_var = 'none'
            promodisplayad_var = 'none'
        }
        else if (simtype === 'promo') {
            basedisplay_var = 'none'
            promodisplay_var = 'table-cell'

            //Set The Options...
            var datasetname = this.state.DataSetChosen_option[0].label
            if (Object.keys(Constant.adeffect_options).includes(datasetname)){
                adeffect_options = Constant.adeffect_options[datasetname]
            }
            else {
                adeffect_options = []
            }

            if (Object.keys(Constant.promoeffect_options).includes(datasetname)){
                promoeffect_options = Constant.promoeffect_options[datasetname]
            }
            else {
                promoeffect_options = []
            }
            if (datasetid === 1 || datasetid === 2 || datasetid === 5 || datasetid === 6) {
                promodisplayad_var = 'table-cell'
                if (datasetid === 1 || datasetid === 2) {
                    AdTypeALabel_var = 'OCS Featured Front or Back'
                    AdTypeBLabel_var = 'OCS Featured Inside'
                    AdTypeCLabel_var = 'Listing Only (no OCS)'
                    AdTypeCustomLabel_var = 'OCS'
                }
                else if (datasetid === 5 || datasetid === 6) {
                    AdTypeALabel_var = 'Inside'
                    AdTypeBLabel_var = 'Front'
                    AdTypeCLabel_var = 'Back'
                    AdTypeCustomLabel_var = 'Online'
                }
            }
            else {
                promodisplayad_var = 'none'
                AdTypeALabel_var = ''
                AdTypeBLabel_var = ''
                AdTypeCLabel_var = ''
                AdTypeCustomLabel_var = ''
            }
        }
        else {
            basedisplay_var = 'none'
            promodisplay_var = 'none'
            promodisplayad_var = 'none'
        }
        this.setState({
            basedisplay: basedisplay_var,
            promodisplay: promodisplay_var,
            promodisplayad: promodisplayad_var,
            AdTypeALabel: AdTypeALabel_var,
            AdTypeBLabel: AdTypeBLabel_var,
            AdTypeCLabel: AdTypeCLabel_var,
            AdTypeCustomLabel: AdTypeCustomLabel_var,
            adeffect_options,
            promoeffect_options
        })

    }
    
    getItemInfo = async (dataset, geo, start, end) => {
        this.addBackdrop()
        //First Pull In Item Info For dataset
        console.log('I am Being Run with datasetid: ' + dataset + " and geoid: " + geo)
        var items_lookup_object = {}
        const res = await fetch(Constant.baseapiurl + "Datasets/" + dataset + "/Parameters?geos=" + geo)
        var results = []
        var resultformatted = ''
        var data = { 'attributes': [], 'items': [] }
        var data2 = []
        var data3 = []
        var data5 = []
        var userbrands = []
        var companybrandsdata = []
        var competitorsdata = []
        var compitems = []
        var compitems_checked = []
        var internalcompitems = []
        if (res.status === 200) {
            data = await res.json()
        }
        else if (res.status === 511) {
            results = await res.json()
            this.setState({
                displayReloadDialog: true
                , reloadDialog: data["Reason"]
                , showBackdrop_CreateSim: false
            })
        }
        else {
            console.log('There was an Error Running the API')
            //Show an error to the user...
            this.setState({
                displayReloadDialog: true
                , reloadDialog: "There was an error loading data.  Please reload this page."
                , showBackdrop_CreateSim: false
            })
            data = { 'attributes': [], 'items': [] }
        }
        //console.log(data)
        //console.log(data.items)
        let attributes = data.attributes.map(attr=>attr.attribute)
        //Create Item Lookup Object...
        data.items.forEach(item => {
            items_lookup_object[item.prodid] = {}
            attributes.forEach(attr => {
                if (attr === 'equiv') {
                    items_lookup_object[item.prodid][attr] = 1 //parseFloat(item[attr]) //Removed Based on Feedback
                }
                else {
                    items_lookup_object[item.prodid][attr] = item[attr]
                }
                
            })   
            items_lookup_object[item.prodid]['proddesc'] = item['proddesc']
        })
        
        //Get Brands for Items
        const res2 = await fetch(Constant.baseapiurl + "Datasets/" + dataset + "/BrandsFromItems?geos=" + geo,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Object.keys(items_lookup_object))
            })

        if (res2.status === 200) {
            data2 = await res2.json()
            //console.log('data2 - brands for items', data2)
        }
        else if (res2.status === 511) {
            results = await res2.json()
            this.setState({
                displayReloadDialog: true
                , reloadDialog: results["Reason"]
                , showBackdrop_CreateSim: false
            })
        }
        else {
            console.log('There was an Error Running the API')
            //Show an error to the user...
            this.setState({
                displayReloadDialog: true
                , reloadDialog: "There was an error loading data.  Please reload this page."
                , showBackdrop_CreateSim: false
            })
            data2 = []
        }

        data2.map(item => items_lookup_object[item.prodid]['brand'] = item.brand)        
        
        const res3 = await fetch(Constant.baseapiurl + "Datasets/" + dataset + "/CheckYearAgoSales?geos=" + geo +'&start_date=' + start + '&end_date=' + end)
        if (res3.status === 200) {
            data3 = await res3.json()
            //console.log('data3', data3)
        }
        else if (res3.status === 511) {
            results = await res3.json()
            this.setState({
                displayReloadDialog: true
                , reloadDialog: results["Reason"]
                , showBackdrop_CreateSim: false
            })
        }
        else {
            console.log('There was an Error Running the API')
            //Show an error to the user...
            this.setState({
                displayReloadDialog: true
                , reloadDialog: "There was an error loading data.  Please reload this page."
                , showBackdrop_CreateSim: false
            })
            data3 = []
        }


        Object.keys(items_lookup_object).forEach(itemid => {
            if (data3.includes(parseInt(itemid))) {
                items_lookup_object[itemid]['hassales'] = true
            }
            else {
                items_lookup_object[itemid]['hassales'] = false
            }
        })

        //for the items that have sales, perform the item lookup...

        var basecalcdata = {
            itemids: data3,
            startdate: start,
            enddate: end,
            dataset_id: dataset,
            geogid: [geo]
        }

        //var basecalcdata = undefined
        //console.log(JSON.stringify(basecalcdata))
        
        const res4 = await fetch(Constant.baseapiurl + "Simulation/base_calculation",
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(basecalcdata)
            })

        if (res4.status === 200) {
            var data4_t = await res4.text()
            data4_t.replace(/NaN/g, "0").replace(/Infinity/g, "0")
            resultformatted = JSON.parse(data4_t)

        }
        else if (res4.status === 511) {
            results = await res4.json()
            this.setState({
                displayReloadDialog: true
                , reloadDialog: results["Reason"]
                , showBackdrop_CreateSim: false
            })
        }
        else {
            console.log('There was an Error Running the API')
            resultformatted = { 'itemids': [] }
            //Show an error to the user...
            this.setState({
                  displayReloadDialog: true
                , reloadDialog: "There was an error loading data.  Please reload this page."
                , showBackdrop_CreateSim: false
            })
        }

        var item_vol_lookup_object = {}
        resultformatted['itemids'].forEach((item, idx) => {
            item_vol_lookup_object[item] = {}
            item_vol_lookup_object[item]['avg_discount'] = resultformatted['avg_discount'][idx]
            item_vol_lookup_object[item]['avgbp'] = resultformatted['avgbp'][idx]
            item_vol_lookup_object[item]['basevolume'] = resultformatted['basevolume'][idx]
            item_vol_lookup_object[item]['covidvolume'] = resultformatted['covidvolume'][idx]
            item_vol_lookup_object[item]['holidayvolume'] = resultformatted['holidayvolume'][idx]
        })

        //console.log(item_vol_lookup_object)
        if (Object.keys(item_vol_lookup_object).length > 0) {
            Object.keys(items_lookup_object).forEach(itemid => {
                //console.log(itemid)
                //console.log(data3)
               // console.log(data3.includes(parseInt(itemid)))
                if (data3.includes(parseInt(itemid)) && (Math.round(item_vol_lookup_object[itemid]['basevolume']) + Math.round(item_vol_lookup_object[itemid]['covidvolume']) + Math.round(item_vol_lookup_object[itemid]['holidayvolume'])) >= 1) {
                    items_lookup_object[itemid]['avg_discount'] = item_vol_lookup_object[itemid]['avg_discount']
                    items_lookup_object[itemid]['avgbp'] = item_vol_lookup_object[itemid]['avgbp']
                    items_lookup_object[itemid]['basevolume'] = item_vol_lookup_object[itemid]['basevolume']
                    items_lookup_object[itemid]['covidvolume'] = item_vol_lookup_object[itemid]['covidvolume']
                    items_lookup_object[itemid]['holidayvolume'] = item_vol_lookup_object[itemid]['holidayvolume']
                }
                else {
                    items_lookup_object[itemid]['avg_discount'] = 0
                    items_lookup_object[itemid]['avgbp'] = 0
                    items_lookup_object[itemid]['basevolume'] = 0
                    items_lookup_object[itemid]['covidvolume'] = 0
                    items_lookup_object[itemid]['holidayvolume'] = 0
                    items_lookup_object[itemid]['hassales'] = false
                }
            })
        }

        else {
            Object.keys(items_lookup_object).forEach(itemid => {
                items_lookup_object[itemid]['avg_discount'] = 0
                items_lookup_object[itemid]['avgbp'] = 0
                items_lookup_object[itemid]['basevolume'] = 0
                items_lookup_object[itemid]['covidvolume'] = 0
                items_lookup_object[itemid]['holidayvolume'] = 0
                items_lookup_object[itemid]['hassales'] = false
            })
        }

        //Ad Coefficients
        const res5 = await fetch(Constant.baseapiurl + 'Datasets/' + dataset + '/CheckAdCoefficients?geos=' + geo)
        if (res5.status === 200) {
            data5 = await res5.json()
        }
        else if (res5.status === 511) {
            results = await res5.json()
            this.setState({
                displayReloadDialog: true
                , reloadDialog: results["Reason"]
                , showBackdrop_CreateSim: false
            })
        }
        else {
            console.log('There was an Error Running the API')
            //Show an error to the user...
            this.setState({
                displayReloadDialog: true
                , reloadDialog: "There was an error loading data.  Please reload this page."
                , showBackdrop_CreateSim: false
            })
            data5 = []
        }

        //console.log(data5)

        if (data5.length > 0) {
            data5.forEach(item => {
                try {
                    items_lookup_object[item.prodid]['adeffects'] = [item.ownadtypea, item.ownadtypeb, item.ownadtypec, item.owncustompromo]
                }
                catch (error) {
                    console.log(error)
                }
            })
        }

        else {
            Object.keys(items_lookup_object).map(item => items_lookup_object[item]['adeffects'] = [false, false, false, false])
        }

        let asp_vals = Object.keys(item_vol_lookup_object).map(item => item_vol_lookup_object[item]['avgbp'])
        let sliderRange = [Math.floor(Math.min(...asp_vals)), Math.round(Math.max(...asp_vals))]
        let sliderRangeMax = Math.round(Math.max(...asp_vals))
        let sliderRangeMin = Math.floor(Math.min(...asp_vals))
        let asp_max = Math.round(Math.max(...asp_vals))
        let asp_min = Math.floor(Math.min(...asp_vals))
        //create options...

        let asp_range_raw = asp_max - asp_min
        let max_num_bands = 7
        let band_size_raw = asp_range_raw / max_num_bands
        var band_size = 0
        if (band_size_raw < 7.5) {
            band_size = 5
        }
        else if (band_size_raw >= 7.5 && band_size_raw < 15) {
            band_size = 10
        }

        else if (band_size_raw >= 15 && band_size_raw < 35) {
            band_size = 25
        }

        else if (band_size_raw >= 35 && band_size_raw < 65) {
            band_size = 50
        }

        else if (band_size_raw >= 65 && band_size_raw < 95) {
            band_size = 75
        }

        else if (band_size_raw >= 95 && band_size_raw < 150) {
            band_size = 100
        }

        else if (band_size_raw >= 150 && band_size_raw < 250) {
            band_size = 200
        }

        else if (band_size_raw >= 250 && band_size_raw < 350) {
            band_size = 300
        }

        else if (band_size_raw >= 350 && band_size_raw < 450) {
            band_size = 400
        }

        else {
            band_size = 500
        }

        //console.log(band_size)

        let band_start = 0
        let band_end = band_size
        let price_band_options = []
        for (var band = 0; band < max_num_bands; band++) {
            if (band_start < asp_max) {

                if ((band_end < asp_max) && band !== (max_num_bands -1)) {
                    let price_band_option_label = this.state.currencysymbol + band_start + ' to ' + this.state.currencysymbol + band_end
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                }

                else if ((band_end < asp_max) && band === (max_num_bands - 1)) {
                    let price_band_option_label = this.state.currencysymbol + band_start + '+'
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, asp_max] })
                }

                else {
                    let price_band_option_label = this.state.currencysymbol + band_start + '+'
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                }

                band_start += band_size
                band_end += band_size
            }
        }

        if (['Notebook Computers', 'Monitors', 'Notebooks and Tablets'].includes(this.state.DataSetChosen_option[0].label)) {
            price_band_options = [
                { 'label': '$300 and Under', 'value': [0, 300] }
                , { 'label': '$301 to $500', 'value': [300, 500] }
                , { 'label': '$501 to $700', 'value': [500, 700] }
                , { 'label': '$701 to $900', 'value': [700, 900] }
                , { 'label': '$901 to $1100', 'value': [900, 1100] }
                , { 'label': '$1101 to $1500', 'value': [1100, 1500] }
                , { 'label': '$1501 and Above', 'value': [1500, asp_max] }
            ]
        }

        if (['Coffee Makers'].includes(this.state.DataSetChosen_option[0].label)) {
            price_band_options = [
                { 'label': '$75 and Under', 'value': [0, 75] }
                , { 'label': '$76 to $150', 'value': [75, 150] }
                , { 'label': '$151 to $225', 'value': [150, 225] }
                , { 'label': '$226 to $300', 'value': [225, 300] }
                , { 'label': '$301 to $375', 'value': [300, 375] }
                , { 'label': '$376 to $450', 'value': [375, 450] }
                , { 'label': '$451 and Above', 'value': [450, asp_max] }
            ]
        }


        //If create lookup users brands, otherwise pull from the sim
        //Need to handle loading from state
        if (Constant.entrytype === 'create' || Constant.entrytype === 'createfromreport') {            
            const userbrandsres = await fetch(Constant.baseapiurl + 'UserSettings/Brands/' + this.state.DataSetChosen)
            if (userbrandsres.status === 200) {
                userbrands = await userbrandsres.json()
            }
            else if (userbrandsres.status === 511) {
                results = await userbrandsres.json()
                console.log('results', results)
                userbrands = []
                this.setState({
                    displayReloadDialog: true
                    , reloadDialog: results["Reason"]
                    , showBackdrop_CreateSim: false
                })
            }
            else {
                console.log('There was an Error Running the API')
                //Show an error to the user...
                //this.setState({
                //    displayReloadDialog: true
                //    , reloadDialog: "There was an error loading data.  Please reload this page."
                //    , showBackdrop_CreateSim: false
                //})
                userbrands = []
            }
            //If no brands selected grab from the company
            //Get Username
            if (userbrands.length === 0) {

                const companybrandsres = await fetch(Constant.baseapiurl_gen + "SessionUser/Company/Brands/" + dataset)
                if (companybrandsres.status === 200) {
                    companybrandsdata = await companybrandsres.json()
                }
                else if (companybrandsres.status === 511) {
                    results = await companybrandsres.json()
                    this.setState({
                        displayReloadDialog: true
                        , reloadDialog: results["Reason"]
                        , showBackdrop_CreateSim: false
                    })
                    companybrandsdata = []
                }
                else {
                    console.log('There was an Error Running the API')
                    companybrandsdata = []
                }

                userbrands = companybrandsdata
            }

        }

        else {
            userbrands = this.state.sim_brands
        }
        //console.log(userbrands)
        if (!userbrands) {
            userbrands = []
        }

        //Place items into the appropriate list
        //console.log(userbrands)
        //console.log(this.state.itemsfromsim)
        var mybranditems = []               
        var mybranditems_checked = []

        //Start with mybrand
        //Only Use Items that have sales        
        Object.keys(items_lookup_object).forEach(item => {
            //console.log(item)            
            if (items_lookup_object[item]['hassales'] === true && userbrands.includes(items_lookup_object[item]['brand'])) {
                mybranditems.push(item)
                if (this.state.itemsfromsim.includes(parseInt(item))) {
                    mybranditems_checked.push(true)
                }
                else {
                    mybranditems_checked.push(false)
                }
            }            
        })

        //Grab competitors only for the items already selected...
        let itemstograbcomps = []
        mybranditems.forEach(item => {
            if (this.state.itemsfromsim.includes(parseInt(item))) {
                itemstograbcomps.push(parseInt(item))
            }
        })
       
        if (itemstograbcomps.length > 0) {
            const comp_post_data = {
                itemids: itemstograbcomps,
                dataset_id: dataset, //Need to replace with
                geogid: [geo],
            }
            console.log('Running Get Comps...')
            const comptetitorsres = await fetch(Constant.baseapiurl + "Simulation/get_competitors",
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(comp_post_data)
                })
            if (comptetitorsres.status === 200) {
                var competitorsdata = await comptetitorsres.json()
                //console.log(competitorsdata)
            }
           
            else if (comptetitorsres.status === 511) {
                results = await comptetitorsres.json()
                this.setState({
                    displayReloadDialog: true
                    , reloadDialog: results["Reason"]
                    , showBackdrop_CreateSim: false
                })
            }
            else {
                console.log('There was an Error Running the API')
                //Show an error to the user...
                this.setState({
                    displayReloadDialog: true
                    , reloadDialog: "There was an error loading data.  Please reload this page."
                    , showBackdrop_CreateSim: false
                })
                competitorsdata = []
            }

            //console.log(competitorsdata)
            //console.log(competitorsdata['comp_prodid'])
            var compitems_fromapi = []
            competitorsdata.forEach(row =>
                row['comp_prodid'].forEach(item => {
                    if (!compitems_fromapi.includes(item)) {
                        compitems_fromapi.push(item)
                    }
                })
            )
            //Check if the competitive item has sales
            Object.keys(items_lookup_object).forEach(item => {
                //console.log(item)
                if (items_lookup_object[item]['hassales'] === true && compitems_fromapi.includes(parseInt(item))) {
                    //If the item is in my brands, track as internal competitor
                    if (mybranditems.includes(item)) {
                        internalcompitems.push(item)
                    }
                    else {
                        compitems.push(item)
                        if (this.state.itemsfromsim.includes(parseInt(item))) {
                            compitems_checked.push(true)
                        }
                        else {
                            compitems_checked.push(false)
                        }
                    }

                }
            })
        }

        else {
            compitems = []
            compitems_checked = []
            internalcompitems = []

        }

        //Move remaining

        var otheritems = []
        var otheritems_checked = []

        Object.keys(items_lookup_object).forEach(item => {
            //console.log(item)
            if (items_lookup_object[item]['hassales'] === true && !mybranditems.includes(item) && !compitems.includes(item)) {
                otheritems.push(item)
                if (this.state.itemsfromsim.includes(parseInt(item))) {
                    otheritems_checked.push(true)
                }
                else {
                    otheritems_checked.push(false)
                }
            }
        })

        //create state objects for 'Select All' mybrands, compitems, otheritems

        

        var numselected_mybrands = 0
        var numselected_compitems = 0
        var numselected_otheritems = 0

        mybranditems_checked.forEach(check => { if (check) { numselected_mybrands += 1 } })
        compitems_checked.forEach(check => { if (check) { numselected_compitems += 1 } })
        otheritems_checked.forEach(check => { if (check) { numselected_otheritems += 1 } })

        //console.log(mybranditems.length)
        //console.log(numselected_mybrands)
        var mybranditems_allchecked = false
        var compitems_allchecked = false 
        var otheritems_allchecked = false

        if (numselected_mybrands === mybranditems.length && mybranditems.length !== 0) {
            mybranditems_allchecked = true
        }
        else {
            mybranditems_allchecked = false            
        }

        if (numselected_compitems === compitems.length && compitems.length !== 0) {
            compitems_allchecked = true
        }
        else {
            compitems_allchecked = false            
        }

        if (numselected_otheritems === otheritems.length && otheritems.length !== 0) {
            otheritems_allchecked = true
        }
        else {
            otheritems_allchecked = false
        }

        //Look through the items from the sim.  If it is in the lookup object, set it as selected.  If Not, notify the user that sales are no longer in the dataset
        var selected_items = []
        var removed_items = []
        //console.log(this.state.itemsfromsim)
        //console.log(this.state.items_lookup_object)
        this.state.itemsfromsim.forEach(item => {
            if (Object.keys(items_lookup_object).includes(String(item))) {
                if (items_lookup_object[(String(item))]['hassales']) {
                    selected_items.push(String(item))
                }
                else {
                    removed_items.push(String(item))
                }
            }
            else {
                removed_items.push(String(item))
            }
        })

        //Review Item Groups and Avaialable Items...
        //If There Are All Items For a Given Group in SElected Items, mark that Group As In the Sim....

   

        let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + this.state.DataSetChosen)
        var user_dataset_settings_data
        if (user_dataset_settings_response.status === 200) {
            user_dataset_settings_data = await user_dataset_settings_response.json()
        }
        else {
            user_dataset_settings_data = []
        }

        var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)
        var itemgroupssettings_data
        if (usersettings_list.includes('ItemGroups')) {
            let itemgroupsettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + this.state.DataSetChosen + '/ItemGroups')
            if (itemgroupsettings_response.status === 200) {
                itemgroupssettings_data = await itemgroupsettings_response.json()
            }
        }

        else {
            itemgroupssettings_data = {}
        }

        //Determine if any are aleady in the sim (?)        

        //Open the modal

        //Add a key called checked to itemgroupssettings_data
        var itemgroups = this.state.itemgroups
        if (!itemgroups) {
            itemgroups = itemgroupssettings_data['setting_value'] || []
            itemgroups.forEach(group => {
                group['checked'] = false
            })
        }

        let valid_items_for_geo = Object.keys(items_lookup_object)
        var selectedgroups = []
        var selectedgroupsforitems = []
        itemgroups.forEach(group => {
            let group_items = group.item_vals
            let valid_items_forgroup_and_geo = []
            console.log(valid_items_for_geo)
            console.log(group_items)
            valid_items_for_geo.forEach(item => {
                if (group_items.includes(parseInt(item))){
                    valid_items_forgroup_and_geo.push(parseInt(item))
                }
            })
            console.log(valid_items_forgroup_and_geo)
            //Determine if items_from_sim contains the whole valid group
            let items_from_sim_from_group = []
            console.log(this.state.itemsfromsim)
            this.state.itemsfromsim.forEach(item => {
                if (valid_items_forgroup_and_geo.includes(parseInt(item))) {
                    items_from_sim_from_group.push(parseInt(item))
                }
            })

            //console.log(items_from_sim_from_group)

            if (items_from_sim_from_group.length >= 1) { //Change this to allow partial groups... == valid_items_forgroup_and_geo.length
                group['checked'] = true
                selectedgroups.push(group)
                selectedgroupsforitems.push(group)
            }
            

        })


        let selecteditems_fromgroup = []
        selectedgroupsforitems.forEach(group => {
            group.item_vals.forEach(item => {
                //confirm its in the item_lookuop_object and if so, add to the list of items
                let valid_items = Object.keys(items_lookup_object)
                if (valid_items.includes(String(item))) {
                   // console.log(valid_items.includes(String(item)))
                    selecteditems_fromgroup.push(String(item))
                   // console.log(selecteditems_fromgroup, 'getItemInfo')
                }
            })
        })
        


        this.setState({
              items_lookup_object
            , sliderRange
            , sliderRangeMax
            , sliderRangeMin
            , price_band_options
            , selectedbrands: userbrands.map(brand => { return { 'value': brand, 'label': brand } })
            , selecteditems: selected_items //this.state.itemsfromsim.map(item => String(item))
            , removeditems: removed_items
            , Itemschosen: selected_items.map(item => { return { 'label': items_lookup_object[item]['proddesc'], 'value': item } })
            , mybranditems
            , compitems
            , otheritems
            , internalcompitems
            , mybranditems_checked
            , compitems_checked
            , otheritems_checked
            , mybranditems_allchecked
            , compitems_allchecked
            , otheritems_allchecked
            , sim_brands: userbrands
            , selectedgroups
            , selectedgroupsforitems
            , selecteditems_fromgroup
            , itemgroups
        })
        console.log('Removing Backdrop')
        this.removeBackdrop()
    }

    //First Form Page

    LoadData = (entrytype) => {

        //Get All Sims for Name Check
        fetch(Constant.baseapiurl + "SimSettings")
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    //console.log('ok')
                    res.json()
                        .then((result) => {
                            console.log('Setting State...')
                            this.setState({ allSimNames: result.map(sim => sim.sim_name) })
                        })
                }

                else if (res.status === 511) {
                    res.json()
                        .then(
                            (results) => {
                                console.log(results);
                                this.setState({
                                    displayReloadDialog: true
                                    , reloadDialog: results["Reason"]
                                    , showBackdrop_CreateSim: false
                                })
                            })
                }

                else {
                    console.log('There was an Error Running the API')
                    //Show an error to the user...
                    this.setState({
                        displayReloadDialog: true
                        , reloadDialog: "There was an error loading data.  Please reload this page."
                        , showBackdrop_CreateSim: false
                    })
                }
            })
        //If we are not in create, get the name, dataset, geos, dates, and items in the simulation
        if (entrytype !== 'create') {
            this.getSimInfo(entrytype)
        }
        else {
            //Get Available Datasets...
            fetch(Constant.baseapiurl + "Datasets")
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        //console.log('ok')
                        res.json()
                            .then(
                                (results) => {
                                    //console.log(results);
                                    var dataset_array = []
                                    results.map(result => (dataset_array.push({ 'value': result.dataset_id, 'label': result.shortdesc })))
                                    console.log('Setting State...')
                                    this.setState({
                                        datasets: dataset_array,
                                        itemsfromsim: []
                                    });
                                    console.log('Removing Backdrop')
                                    this.removeBackdrop()
                                },

                                (error) => {
                                    console.log('Setting State...')
                                    this.setState({
                                        isLoaded: true,                                        
                                        error
                                    });
                                }
                            )
                    }

                    else if (res.status === 511) {
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop_CreateSim: false
                                    })
                                })
                    }

                    else {
                        console.log('There was an Error Running the API')
                        //Show an error to the user...
                        this.setState({
                            displayReloadDialog: true
                            , reloadDialog: "There was an error loading data.  Please reload this page."
                            , showBackdrop_CreateSim: false
                        })
                    }
                })
            
        }
    }

    getSimInfo = (entrytype) => {
        let idtocheck = this.findid()
        if (entrytype === 'edit' || entrytype === 'copy') {
            //Pull From SimSettings
            fetch(Constant.baseapiurl + "SimSettings/" + idtocheck)
                .then(
                    res => {
                        if (res.status === 200) {
                            res.json()
                                .then(
                                    (result) => {
                                        var simname = ''
                                        var timeupdated = null
                                        //From the simulation geo and dataset, set the information
                                        this.SetDataSetInfo(result.dataset_id)                                        
                                        this.SetGeos(result.dataset_id, result.geogid[0])
                                        this.SetParametersInfo(result.dataset_id, result.geogid[0])

                                        //set name & timeupdated
                                        if (entrytype === 'edit') {
                                            simname = result.sim_name
                                            timeupdated = result.time_updated
                                        }
                                        else {
                                            simname = 'Copy of ' + result.sim_name
                                            timeupdated = null
                                        }                                        

                                        //set Sim Dates
                                        let enddate = result.sim_end.substring(0, 10)
                                        let startdate = result.sim_start.substring(0, 10)
                                        //subtract 6 days to display sunday

                                        //SimType, Coverage
                                        let simtype = result.sim_type
                                        // Setting coverage to be 100% per Mike N. 8/29/23
                                        let coverage = 1
                                        //let coverage = result.coverage
                                        let sim_brands = result.sim_brands
                                        //For Each Item, get Item Id, competitor flag, override info, and sim info...
                                        let itemsfromsim = result.items.map(item => item.prod_id)                                       

                                        //create sim_info_lookup object...
                                        var sim_item_info_lookup = {}
                                        result.items.forEach(item => {
                                            sim_item_info_lookup[item.prod_id] = {
                                                'competitor_flag': item.competitor_flag,
                                                'ad_type': item.ad_type,
                                                'promo_type': item.promo_type,
                                                'override_base_flag': item.override_base_flag,
                                                'override_base_price': item.override_base_price,
                                                'override_base_volume': item.override_base_volume,
                                                'override_base_discount': item.override_base_discount,
                                                'cost_per_unit': item.cost_per_unit,
                                                'gross_margin': item.gross_margin,
                                                'list_price': item.list_price,
                                                'retail_margin': item.retail_margin,
                                                'sim_base_price': item.sim_base_price,
                                                'sim_discount_pct': item.sim_discount_pct
                                            }
                                        })

                                        //Set State
                                        this.setState({
                                            simid: idtocheck,
                                            simname: simname,
                                            selectedstartdate: startdate,
                                            selectedenddate: enddate,
                                            timeupdated: timeupdated,
                                            coverage: coverage,
                                            itemsfromsim: itemsfromsim,                                            
                                            simtype: simtype,
                                            sim_brands,
                                            sim_information: result,
                                            sim_item_info_lookup                                            
                                        })
                                        console.log('Removing Backdrop')
                                        this.removeBackdrop()

                                    })
                        }
                        else if (res.status === 511) {
                            res.json()
                                .then(
                                    (results) => {
                                        console.log(results);
                                        this.setState({
                                            displayReloadDialog: true
                                            , reloadDialog: results["Reason"]
                                            , showBackdrop_CreateSim: false
                                        })
                                    })
                        }

                        else {
                            console.log('There was an Error Running the API')
                            //Show an error to the user...
                            this.setState({
                                displayReloadDialog: true
                                , reloadDialog: "There was an error loading data.  Please reload this page."
                                , showBackdrop_CreateSim: false
                            })
                        }
                    }
            )
        }

        else {
            //Pull From ReportSettings
            fetch(Constant.baseapiurl + "ReportSettings/" + idtocheck)
                .then(
                    res => {
                        //Look at status code, and if not 200, throw error
                        if (res.status === 200) {
                            //console.log('ok')
                            res.json()
                                .then(
                                    (result) => {
                                        console.log(result)

                                        //set dataset
                                        this.SetDataSetInfo(result.dataset_id)
                                        //set geography, dates, attributes, and brands available
                                        this.SetGeos(result.dataset_id, result.geogid[0])
                                        this.SetParametersInfo(result.dataset_id, result.geogid[0])   

                                        var simname = "Simulation from " + result.report_name

                                        console.log('Running Data')
                                        fetch(Constant.baseapiurl + "ReportData/" + idtocheck)
                                            .then(
                                                res => {
                                                    //Look at status code, and if not 200, throw error
                                                    if (res.status === 200) {
                                                        //console.log('ok')
                                                        res.json()
                                                            .then(
                                                                (result) => {
                                                                    console.log(result)
                                                                    var distinctitemsarray = []
                                                                    var distinctitemnumsarray = []
                                                                    var itemdict = {}
                                                                    var itemdict_opp = {}
                                                                    var items_array = []
                                                                    result.map(row => (distinctitemsarray.push(row.proddesc)));
                                                                    result.map(row => (distinctitemnumsarray.push(row.prodid)));
                                                                    console.log(distinctitemnumsarray)
                                                                    result.map(row => itemdict[row.proddesc] = row.prodid);
                                                                    result.map(row => itemdict_opp[row.prodid] = row.proddesc);
                                                                    let total_distinct_itemnums = Array.from(new Set(distinctitemnumsarray))
                                                                    console.log(total_distinct_itemnums)
                                                                    total_distinct_itemnums.map(itemnum => items_array.push({ 'value': itemnum, 'label': itemdict_opp[itemnum] }))

                                                                    var sim_item_info_lookup = {}
                                                                    total_distinct_itemnums.forEach(item => {
                                                                        sim_item_info_lookup[item] = {
                                                                            'competitor_flag': false,
                                                                            'ad_type': null,
                                                                            'promo_type': null,
                                                                            'override_base_flag': false,
                                                                            'override_base_price': null,
                                                                            'override_base_volume': null,
                                                                            'override_base_discount': null,
                                                                            'cost_per_unit': null,
                                                                            'gross_margin': null,
                                                                            'list_price': null,
                                                                            'retail_margin': null,
                                                                            'sim_base_price': null,
                                                                            'sim_discount_pct': null
                                                                        }
                                                                    })

                                                                    this.setState({
                                                                        simname
                                                                        , reportid: idtocheck
                                                                        , itemsfromsim: total_distinct_itemnums 
                                                                        , sim_item_info_lookup

                                                                        
                                                                    })
                                                                    console.log('Removing Backdrop')
                                                                    this.removeBackdrop()

                                                                })
                                                    }

                                                    else if (res.status === 511) {
                                                        res.json()
                                                            .then(
                                                                (results) => {
                                                                    console.log(results);
                                                                    this.setState({
                                                                        displayReloadDialog: true
                                                                        , reloadDialog: results["Reason"]
                                                                        , showBackdrop_CreateSim: false
                                                                    })
                                                                })
                                                    }

                                                    else {
                                                        console.log('There was an Error Running the API')
                                                        //Show an error to the user...
                                                        this.setState({
                                                            displayReloadDialog: true
                                                            , reloadDialog: "There was an error loading data.  Please reload this page."
                                                            , showBackdrop_CreateSim: false
                                                        })
                                                    }
                                                })
                                            .catch((error) => {
                                                console.log(error)
                                            })
                                    })
                        }

                        else if (res.status === 511) {
                            res.json()
                                .then(
                                    (results) => {
                                        console.log(results);
                                        this.setState({
                                            displayReloadDialog: true
                                            , reloadDialog: results["Reason"]
                                            , showBackdrop_CreateSim: false
                                        })
                                    })
                        }

                        else {
                            console.log('There was an Error Running the API')
                            //Show an error to the user...
                            this.setState({
                                displayReloadDialog: true
                                , reloadDialog: "There was an error loading data.  Please reload this page."
                                , showBackdrop_CreateSim: false
                            })
                        }
                    }
                )
        }
    }

    bulkEditChecks = idx => e => {
        var bulkedit = [...this.state.bulkedit]
        bulkedit[idx] = !bulkedit[idx]
        this.setState({ bulkedit })
    }

    showBulkEdit = () => {
        //console.log('Showing the Checkmarks')
        if (this.state.bulkeditdisplay === 'none') {
            this.setState({ bulkeditdisplay: 'table-cell' })
        }
        else if (this.state.bulkeditdisplay === 'table-cell') {
            this.setState({ bulkeditdisplay: 'none' })
        }

    }

    showBulkEditModal = () => {
        //console.log('Opening Modal')
        this.setState({ openBulkEditModal: true })
    }

    hideBulkEditModal = () => {
        this.setState({ openBulkEditModal: false })
    }


    handleChangeBulkEditBasePrice = e => {
        //console.log(e.target.value)
        //Add Validation Here
        if (e.target.value < .01) {
            console.log('Throw an Error')
            this.setState({
                bulkediterror: true, bulkedit_error_text: 'Please ensure your base price is greater than .01'})
        }
        else if (isNaN(e.target.value / 1)) {
            console.log('Throw an Error')
            this.setState({ bulkediterror: true, bulkedit_error_text: 'Please ensure you have entered a number' })
        }
        else {
            this.setState({ bulkediterror: false, bulkedit_error_text: '' })
        }
        this.setState({
            bulkedit_basepriceval: e.target.value,
            bulkedit_basepricechangeval: null,
            bulkedit_discountval: null,
            bulkedit_promotedpriceval: null,
        })
    }

    handleChangeBulkEditBasePriceChg = e => {
        //console.log(e.target.value)
        //Add Validation Here
        if (e.target.value <= -100) {
            console.log('Throw an Error')
            this.setState({ bulkediterror: true, bulkedit_error_text: 'Please ensure your base price is greater than .01' })
        }
        else if (isNaN(e.target.value / 1)) {
            console.log('Throw an Error')
            this.setState({ bulkediterror: true, bulkedit_error_text: 'Please ensure you have entered a number' })
        }
        else {
            this.setState({ bulkediterror: false, bulkedit_error_text: ''  })
        }
        this.setState({
            bulkedit_basepriceval: null,
            bulkedit_basepricechangeval: e.target.value,
            bulkedit_discountval: null,
            bulkedit_promotedpriceval: null,
        })
    }

    handleChangeBulkEditDiscount = e => {
        //console.log(e.target.value)
        //Add Validation Here
        if (e.target.value >= 100 || e.target.value < 0) {
            console.log('Throw an Error')
            this.setState({ bulkediterror: true, bulkedit_error_text: 'Please ensure discount % is between 0% and 99%' })
        }
        else if (isNaN(e.target.value / 1)) {
            console.log('Throw an Error')
            this.setState({ bulkediterror: true, bulkedit_error_text: 'Please ensure you have entered a number' })
        }
        else {
            this.setState({ bulkediterror: false, bulkedit_error_text: ''  })
        }
        //Add Validation Here        
        this.setState({
            bulkedit_basepriceval: null,
            bulkedit_basepricechangeval: null,
            bulkedit_discountval: e.target.value,
            bulkedit_promotedpriceval: null,
        })
    }

    handleChangeBulkEditPromotedPrice = e => {
        //console.log(e.target.value)
        if (e.target.value <= 0) {
            console.log('Throw an Error')
            this.setState({ bulkediterror: true, bulkedit_error_text: 'Please ensure discount % is between 0% and 99%' })
        }
        else if (isNaN(e.target.value / 1)) {
            console.log('Throw an Error')
            this.setState({ bulkediterror: true, bulkedit_error_text: 'Please ensure you have entered a number' })
        }
        else {
            this.setState({ bulkediterror: false, bulkedit_error_text: ''  })
        }

        //Add Validation Herepromo_value_tracking_bulkedit
        this.setState({
            bulkedit_basepriceval: null,
            bulkedit_basepricechangeval: null,
            bulkedit_discountval: null,
            bulkedit_promotedpriceval: e.target.value,
        })
    }
    handleChangeBulkEditPromoEffect(selectedOptions) {
        console.log(selectedOptions)
        //console.log('PlaceHolder to Send Promo Type')       
        let value = selectedOptions.value

        var promo_value_tracking_bulkedit = this.state.promo_value_tracking_bulkedit
        promo_value_tracking_bulkedit = value
        this.setState({ promo_value_tracking_bulkedit })

        var selected_options_promos_bulkedit = this.state.selected_options_promos_bulkedit
        selected_options_promos_bulkedit = selectedOptions
        this.setState({ selected_options_promos_bulkedit })

        //Find Out Which Data set We are In...
        var datasetname = this.state.DataSetChosen_option[0].label
        //Process the rules according to the logic of the dataset
        if (Constant.promo_add_one_only.includes(datasetname)) {
            //Motor Oil Datasets
            var motoroildatasets = ['Passenger Car Motor Oil', 'Heavy Duty Motor Oil']
            var promo_value_bulkedit = this.state.promo_value_bulkedit
            if (motoroildatasets.includes(datasetname)) {
                promo_value_bulkedit = this.state.promo_value_bulkedit
                var ad_value_bulkedit = this.state.ad_value_bulkedit                
                //Look at the value for promo
                //If Set then take the value and set to the appropriate Promo Type...
                //If Not Set, leave it be...
                //Get Ad Value from tracking...
                let current_ad_value_bulkedit = ad_value_bulkedit

                var advalue_bulkedit = null
                var promovalue_bulkedit = null
                if (current_ad_value_bulkedit && current_ad_value_bulkedit !== 'None') {
                    if (value === 'None') {
                        advalue_bulkedit = current_ad_value_bulkedit
                        promovalue_bulkedit = null
                    }

                    else if (current_ad_value_bulkedit === 'AdTypeA') {
                        advalue_bulkedit = null
                        promovalue_bulkedit = 'PromoTypeB'
                    }
                    else if (current_ad_value_bulkedit === 'AdTypeB') {
                        advalue_bulkedit = null
                        promovalue_bulkedit = 'PromoTypeC'
                    }
                    else if (current_ad_value_bulkedit === 'AdTypeC') {
                        advalue_bulkedit = null
                        promovalue_bulkedit = 'PromoTypeD'
                    }

                    promo_value_bulkedit = promovalue_bulkedit                    
                    ad_value_bulkedit = advalue_bulkedit
                    this.setState({
                        promo_value_bulkedit,                        
                        ad_value_bulkedit
                    })
                }

                else {
                    promo_value_bulkedit = value                    
                    this.setState({
                        promo_value_bulkedit
                    })
                }
            }
        }
        //Otherwise take the value
        else {
            promo_value_bulkedit = this.state.promo_value_bulkedit
            promo_value_bulkedit = value                        

            this.setState({
                promo_value_bulkedit
            })
        }
    }

    handleChangeBulkEditAdEffect(selectedOptions){
        //console.log('Changing Ad Type')
        let value = selectedOptions.value

        var ad_value_tracking_bulkedit = this.state.ad_value_tracking_bulkedit
        ad_value_tracking_bulkedit = value
        this.setState({ ad_value_tracking_bulkedit })

        var selected_options_ads_bulkedit = this.state.selected_options_ads_bulkedit
        selected_options_ads_bulkedit = selectedOptions
        this.setState({ selected_options_ads_bulkedit })
        //Find Out Which Data set We are In...
        var datasetname = this.state.DataSetChosen_option[0].label        
        var ad_value_bulkedit = this.state.ad_value_bulkedit
        //Process the rules according to the logic of the dataset
        if (Constant.promo_add_one_only.includes(datasetname)) {
            //Motor Oil Datasets
            var motoroildatasets = ['Passenger Car Motor Oil', 'Heavy Duty Motor Oil']
            if (motoroildatasets.includes(datasetname)) {
                var promo_value_bulkedit = this.state.promo_value_bulkedit
                ad_value_bulkedit = this.state.ad_value_bulkedit
                //Look at the value for promo
                //If Set then take the value and set to the appropriate Promo Type...
                //If Not Set, leave it be...
                var current_promo_value_bulkedit = this.state.promo_value_tracking_bulkedit
                var promovalue_bulkedit = ''

                if (current_promo_value_bulkedit && current_promo_value_bulkedit !== 'None') {
                    if (value === 'None') {
                        promovalue_bulkedit = 'PromoTypeA'
                    }
                    else if (value === 'AdTypeA') {
                        promovalue_bulkedit = 'PromoTypeB'
                    }
                    else if (value === 'AdTypeB') {
                        promovalue_bulkedit = 'PromoTypeC'
                    }
                    else if (value === 'AdTypeC') {
                        promovalue_bulkedit = 'PromoTypeD'
                    }
                    //Set Ad Value to Null and Promo Value to What was defined above
                    promo_value_bulkedit = promovalue_bulkedit
                    ad_value_bulkedit = null
                    this.setState({
                        promo_value_bulkedit,
                        ad_value_bulkedit
                    })
                }

                else {
                    ad_value_bulkedit = value
                    this.setState({
                        ad_value_bulkedit
                    })
                }
            }
        }
        //Otherwise take the value
        else {
            ad_value_bulkedit = this.state.ad_value_bulkedit
            ad_value_bulkedit = value
            this.setState({
                ad_value_bulkedit
            })
        }
    }
     

    handleChangeBulkEditFinancials = pos => e => {
        console.log('Changing BulkEdit Financials')
        let bulkedit_financials = this.state.bulkedit_financials || []
        bulkedit_financials[pos] = parseFloat(e.target.value.replace(/,/g, ""), 2)
        console.log(bulkedit_financials)
        
        let financials = this.state.financials
        console.log(financials)
        this.setState({ bulkedit_financials, financials })
    }

    selectAllBulkEdit = () => {
        var selectallbulkedit = !this.state.selectallbulkedit
        var bulkedit = this.state.bulkedit
        var bulkeditnew = []
        //Find Num Marked True
        var numchecked = bulkedit.reduce(function (total, num) { return total + num })
        //console.log(numchecked)
        //If less than all, select all as true
        if (numchecked < bulkedit.length) {
            bulkedit.map(bulkedit => bulkeditnew.push(true))
        }
        //If all are checked, set as false
        else {
            bulkedit.map(bulkedit => bulkeditnew.push(false))
        }
        this.setState({ selectallbulkedit, bulkedit: bulkeditnew })
    }

    applyBulkEditChangesFinancials = () => {
        console.log('Applying Bulk Edit Financial Changes')
        this.setState({ openBulkEditModal: false })
        this.setState({ bulkeditdisplay: 'none' })
        //Get Financials Objects
        let current_financials = this.state.financials
        console.log('current_financials', current_financials)
        let financials_to_set = this.state.bulkedit_financials
        console.log('financials_to_set', financials_to_set)
        var new_bulk_edits = []
        //console.log(current_financials)
        console.log(this.state.bulkedit)
        this.state.bulkedit.forEach((bulkedit, idx) => {
            console.log(bulkedit)
            new_bulk_edits.push(false)
            if (bulkedit === true) {
                current_financials[idx] = (financials_to_set)
            }
        })
        //console.log(current_financials)
        this.setState({ bulkedit: new_bulk_edits, selectallbulkedit: false, financials: current_financials })
    }

    applyBulkEditChanges = () => {
        //Check if bulkediterror is true...
        if (this.state.bulkediterror) {
            console.log('Errors')
        }
        else {
            //console.log('Applying Changes')
            //Close the Modal
            this.setState({ openBulkEditModal: false })
            //Close the bulk Edit Flags
            this.setState({ bulkeditdisplay: 'none' })

            //Apply the changes....
            //console.log(this.state.simtype)
            //console.log(this.state.bulkedit_basepriceval)
            //console.log(this.state.bulkedit_basepricechangeval)
            //console.log(this.state.bulkedit_discountval)
            //console.log(this.state.bulkedit_promotedpriceval)
            //Add Validation Here
            var newbases = [...this.state.newbases]
            var newbases_chg = [...this.state.newbases_chg]
            var newpcts = [...this.state.newpcts]
            var newpcts_chg = [...this.state.newpcts_chg]
            var asps = [...this.state.asps]

            var ad_value = [...this.state.ad_value]
            var promo_value = [...this.state.promo_value]
            var ad_value_tracking = [...this.state.ad_value_tracking]
            var promo_value_tracking = [...this.state.promo_value_tracking]
            var selected_options_promos = [...this.state.selected_options_promos]
            var selected_options_ads = [...this.state.selected_options_ads]

            var new_bulk_edits = []
            var input_errors_promo = [...this.state.input_errors_promo]
            var input_errors_base = [...this.state.input_errors_base]
            this.state.bulkedit.forEach((bulkedit, idx) => {
                new_bulk_edits.push(false)
                if (bulkedit === true) {
                    //console.log(idx)
                    //Look at the sim Type
                    if (this.state.simtype === 'promo') {
                        //Look at the Eligible values
                        if (this.state.bulkedit_discountval) {
                            //Set Discount % equal to that value for the rows selected
                            //Remove Input Errors
                            input_errors_promo[idx] = false
                            newpcts[idx] = Math.round(parseFloat(this.state.bulkedit_discountval))
                            newpcts_chg[idx] = Math.round((parseFloat(asps[idx]) * (1 - (parseFloat(this.state.bulkedit_discountval) / 100))) * 100) / 100.
                            //newpcts_chg[idx] = (asps[idx] - (asps[idx] * (this.state.bulkedit_discountval / 100))).toFixed(6).slice(0,-4)
                            //this.setState({ newpcts, newpcts_chg, input_errors_promo })
                        }
                        else if (this.state.bulkedit_promotedpriceval) {
                            //Calculate the discount percent based on the new promoted price...
                            //input_errors_promo[idx] = false
                            newpcts_chg[idx] = this.state.bulkedit_promotedpriceval
                            newpcts[idx] = Math.round((10000 - (this.state.bulkedit_promotedpriceval / parseFloat(asps[idx])) * 10000)) / 100.0
                            console.log(newpcts[idx])
                            //newpcts[idx] = Math.round((1 - (this.state.bulkedit_promotedpriceval / asps[idx])) * 100).toFixed(6).slice(0,-4)
                            if (newpcts[idx] >= 100 || newpcts[idx] < 0) {
                                input_errors_promo[idx] = true
                            }
                            else {
                                input_errors_promo[idx] = false
                            }
                            //this.setState({ newpcts, newpcts_chg, input_errors_promo })
                        }

                        //Ad to Promo and Ad Values if Present:
                        if (this.state.promodisplayad !== 'none') {
                            ad_value[idx] = this.state.ad_value_bulkedit
                            promo_value[idx] = this.state.promo_value_bulkedit

                            ad_value_tracking[idx] = this.state.ad_value_tracking_bulkedit
                            promo_value_tracking[idx] = this.state.promo_value_tracking_bulkedit

                            selected_options_promos[idx] = [this.state.selected_options_promos_bulkedit]
                            selected_options_ads[idx] = [this.state.selected_options_ads_bulkedit]
                        }
                    }

                    else if (this.state.simtype === 'base') {
                        //Look at the Eligible values
                        if (this.state.bulkedit_basepriceval) {
                            //Set New base price equal to the amount for the rows selected
                            newbases[idx] = this.state.bulkedit_basepriceval
                            newbases_chg[idx] = Math.round(((this.state.bulkedit_basepriceval - asps[idx]) / asps[idx]) * 100)
                            input_errors_base[idx] = false
                            //this.setState({ newbases, newbases_chg, input_errors_base })
                        }
                        else if (this.state.bulkedit_basepricechangeval) {
                            //Calculate the base price based off of the percentage change
                            newbases_chg[idx] = this.state.bulkedit_basepricechangeval
                            newbases[idx] = ((1 + (this.state.bulkedit_basepricechangeval / 100)) * parseFloat(asps[idx])).toFixed(2)
                            if (newbases[idx] < .01) {
                                input_errors_base[idx] = true
                            }
                            else {
                                input_errors_base[idx] = false
                            }                            
                            //this.setState({ newbases, newbases_chg, input_errors_base })
                        }
                    }
                }

                //Reset All Flags to False
                //console.log('Setting State...')
                //this.setState({ bulkedit: new_bulk_edits, selectallbulkedit: false })

            })

            //Set State here....
            //console.log('Setting State...')
            this.setState({
                  newbases
                , newbases_chg
                , input_errors_base
                , newpcts
                , newpcts_chg
                , input_errors_promo
                , bulkedit: new_bulk_edits
                , selectallbulkedit: false
                , ad_value
                , promo_value
                , ad_value_tracking
                , promo_value_tracking
                , selected_options_promos
                , selected_options_ads
            })
            //this.setState({ newpcts, newpcts_chg, input_errors_promo })
            //this.setState({ bulkedit: new_bulk_edits, selectallbulkedit: false })
        }
        
    }

    openItemGroupsModal = async () => {
        //Grab the item Groups
        this.setState({ showBackdrop_CreateSim: true})
        let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + this.state.DataSetChosen)
        var user_dataset_settings_data
        if (user_dataset_settings_response.status === 200) {
            user_dataset_settings_data = await user_dataset_settings_response.json()
        }
        else {
            user_dataset_settings_data = []
        }

        var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)
        var itemgroupssettings_data
        if (usersettings_list.includes('ItemGroups')) {
            let itemgroupsettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + this.state.DataSetChosen + '/ItemGroups')
            if (itemgroupsettings_response.status === 200) {
                itemgroupssettings_data = await itemgroupsettings_response.json()
            }
        }

        else {
            itemgroupssettings_data = {}
        }

        //Determine if any are aleady in the sim (?)        

        //Open the modal

        //Add a key called checked to itemgroupssettings_data

        
        var itemgroups = this.state.itemgroups
        if (!itemgroups) {
            itemgroups = itemgroupssettings_data['setting_value']
            itemgroups.forEach(group => {
                group['checked'] = false
            })
        }

        this.setState({ ItemGroupsModalOpen: true, itemgroupssettings_data, showBackdrop_CreateSim: false, itemgroups })
    }

    handleRemoveSpecificRow = (idx) => () => {
        console.log('Removing Row')
        //Perform a check to see if the number of rows would be reduced to zero...if so, throw a warning, and 

        if (this.state.selecteditems.length === 1) {
            this.setState({ openRemoveAllItemsErrorDialog: true })
        }
        else {
            const selecteditems = [...this.state.selecteditems]
            let item_removing = selecteditems[idx]
            selecteditems.splice(idx, 1)
            this.setState({ selecteditems })

            //Reset Checkboxes
            const mybranditems = [...this.state.mybranditems]
            const mybranditems_checked = [...this.state.mybranditems_checked]
            if (mybranditems.indexOf(item_removing) > -1) {
                mybranditems_checked[mybranditems.indexOf(item_removing)] = false
            }

            const compitems = [...this.state.compitems]
            const compitems_checked = [...this.state.compitems_checked]
            if (compitems.indexOf(item_removing) > -1) {
                compitems_checked[compitems.indexOf(item_removing)] = false
            }

            const otheritems = [...this.state.otheritems]
            const otheritems_checked = [...this.state.otheritems_checked]
            if (otheritems.indexOf(item_removing) > -1) {
                otheritems_checked[otheritems.indexOf(item_removing)] = false
            }

            this.setState({
                mybranditems_checked
                ,compitems_checked
                ,otheritems_checked
            })

            const Itemschosen = [...this.state.Itemschosen]
            Itemschosen.splice(idx, 1)
            this.setState({ Itemschosen })

            const rowSelectedList = [...this.state.rowSelectedList]
            rowSelectedList.splice(idx, 1)
            this.setState({ rowSelectedList })

            const vols = [...this.state.vols]
            vols.splice(idx, 1)
            this.setState({ vols })

            const hist_vols = [...this.state.hist_vols]
            hist_vols.splice(idx, 1)
            this.setState({ hist_vols })

            const override_base_flags = [...this.state.override_base_flags]
            override_base_flags.splice(idx, 1)
            this.setState({ override_base_flags })

            const volume_revert_button_color = [...this.state.volume_revert_button_color]
            volume_revert_button_color.splice(idx, 1)
            this.setState({ volume_revert_button_color })

            const volume_font_weight = [...this.state.volume_font_weight]
            volume_font_weight.splice(idx, 1)
            this.setState({ volume_font_weight })

            const volume_table_cell_class = [...this.state.volume_table_cell_class]
            volume_table_cell_class.splice(idx, 1)
            this.setState({ volume_table_cell_class })

            const asps = [...this.state.asps]
            asps.splice(idx, 1)
            this.setState({ asps })

            const hist_asps = [...this.state.hist_asps]
            hist_asps.splice(idx, 1)
            this.setState({ hist_asps })

            const ASP_revert_button_color = [...this.state.ASP_revert_button_color]
            ASP_revert_button_color.splice(idx, 1)
            this.setState({ ASP_revert_button_color })

            const ASP_font_weight = [...this.state.ASP_font_weight]
            ASP_font_weight.splice(idx, 1)
            this.setState({ ASP_font_weight })

            const ASP_table_cell_class = [...this.state.ASP_table_cell_class]
            ASP_table_cell_class.splice(idx, 1)
            this.setState({ ASP_table_cell_class })

            const pcts = [...this.state.pcts]
            pcts.splice(idx, 1)
            this.setState({ pcts })

            const hist_pcts = [...this.state.hist_pcts]
            hist_pcts.splice(idx, 1)
            this.setState({ hist_pcts })

            const PCT_revert_button_color = [...this.state.PCT_revert_button_color]
            PCT_revert_button_color.splice(idx, 1)
            this.setState({ PCT_revert_button_color })

            const PCT_font_weight = [...this.state.PCT_font_weight]
            PCT_font_weight.splice(idx, 1)
            this.setState({ PCT_font_weight })

            const PCT_table_cell_class = [...this.state.PCT_table_cell_class]
            PCT_table_cell_class.splice(idx, 1)
            this.setState({ PCT_table_cell_class })

            const newbases = [...this.state.newbases]
            newbases.splice(idx, 1)
            this.setState({ newbases })

            const newbases_chg = [...this.state.newbases_chg]
            newbases_chg.splice(idx, 1)
            this.setState({ newbases_chg })

            const newpcts = [...this.state.newpcts]
            newpcts.splice(idx, 1)
            this.setState({ newpcts })

            const newpcts_chg = [...this.state.newpcts_chg]
            newpcts_chg.splice(idx, 1)
            this.setState({ newpcts_chg })

            const competitors = [...this.state.competitors]
            competitors.splice(idx, 1)
            this.setState({ competitors })

            const bulkedit = [...this.state.bulkedit]
            bulkedit.splice(idx, 1)
            this.setState({ bulkedit })

            const financials = [...this.state.financials]
            financials.splice(idx, 1)
            this.setState({ financials })

            const input_errors_financials = [...this.state.input_errors_financials]
            input_errors_financials.splice(idx, 1)
            this.setState({ input_errors_financials })

            const input_errors_base = [...this.state.input_errors_base]
            input_errors_base.splice(idx, 1)
            this.setState({ input_errors_base })

            const input_errors_promo = [...this.state.input_errors_promo]
            input_errors_promo.splice(idx, 1)
            this.setState({ input_errors_promo })
            
        }

    }

    revertVolumeRow = (idx) => () => {
        //Get Current Values and Styles
        var volume_revert_button_color = this.state.volume_revert_button_color
        var volume_font_weight = this.state.volume_font_weight
        var volume_table_cell_class = this.state.volume_table_cell_class
        var vols = this.state.vols
        var hist_vols = this.state.hist_vols

        //Change to No Class, wait .5 seconds, then continue
        volume_table_cell_class[idx] = "" //turn it off
        this.setState({ volume_table_cell_class })

        function startanimation(element) {
            element.classList.add("change_data_flash");
            element.addEventListener("animationend", function () {
                element.classList.remove("change_data_flash");
            });
        }

        startanimation(document.getElementById("histvolcell_" + idx))

        //Change Style
        volume_revert_button_color[idx] = "#8E908F" //Change to NPD light grey
        volume_font_weight[idx] = "normal" //Change to normal
        volume_table_cell_class[idx] = "change_data_flash" //turn it off
        //Change Value
        vols[idx] = hist_vols[idx]
        //Check ASPs and PCTs...if also changed, do not change override flag....
        var override_base_flags = [...this.state.override_base_flags]
        var ASP_font_weight_idx = this.state.ASP_font_weight[idx]
        var PCT_font_weight_idx = this.state.PCT_font_weight[idx]

        if (ASP_font_weight_idx === 'bold' || PCT_font_weight_idx === 'bold') {
            override_base_flags[idx] = true
        }

        else {
            override_base_flags[idx] = false
        }


        //Set State                        
        this.setState({ vols, volume_revert_button_color, volume_font_weight, volume_table_cell_class, override_base_flags })

    }

    revertASPRow = (idx) => () => {
        //Get Current Values and Styles
        var ASP_revert_button_color = this.state.ASP_revert_button_color
        var ASP_font_weight = this.state.ASP_font_weight
        var ASP_table_cell_class = this.state.ASP_table_cell_class
        var asps = this.state.asps
        var hist_asps = this.state.hist_asps

        //Change to No Class, wait .5 seconds, then continue
        ASP_table_cell_class[idx] = "" //turn it off
        this.setState({ ASP_table_cell_class })

        function startanimation(element) {
            element.classList.add("change_data_flash");
            element.addEventListener("animationend", function () {
                element.classList.remove("change_data_flash");
            });
        }

        startanimation(document.getElementById("histaspcell_" + idx))
        startanimation(document.getElementById("histopromopricecell_" + idx))

        //Change Style
        ASP_revert_button_color[idx] = "#8E908F" //Change to NPD light grey
        ASP_font_weight[idx] = "normal" //Change to normal
        ASP_table_cell_class[idx] = "change_data_flash" //turn it off
        //Change Value
        asps[idx] = hist_asps[idx]

        //Check ASPs and PCTs...if also changed, do not change override flag....
        var override_base_flags = [...this.state.override_base_flags]
        var PCT_font_weight_idx = this.state.PCT_font_weight[idx]
        var volume_font_weight_idx = this.state.volume_font_weight[idx]

        if (PCT_font_weight_idx === 'bold' || volume_font_weight_idx === 'bold') {
            override_base_flags[idx] = true
        }

        else {
            override_base_flags[idx] = false
        }

        //Set State                        
        this.setState({ asps, ASP_revert_button_color, ASP_font_weight, ASP_table_cell_class, override_base_flags })

        //Look at NewBase...if populated, change the percent change based on new value
        const newbases = [...this.state.newbases];
        const newbases_chg = [...this.state.newbases_chg];

        if (newbases[idx] > 0) {
            newbases_chg[idx] = Math.round(((newbases[idx] - asps[idx]) / asps[idx]) * 100)
            this.setState({ newbases_chg })
        }

        //Look at PCTs and if populated, change the value
        const newpcts = [...this.state.newpcts];
        const newpcts_chg = [...this.state.newpcts_chg];

        if (newpcts[idx] > 0) {
            newpcts_chg[idx] = (asps[idx] * ((100 - newpcts[idx]) / 100)).toFixed(6).slice(0,-4)
            //newpcts_chg[idx] = Math.round(((Math.round(parseFloat(newpcts[idx])) - pcts[idx]) / pcts[idx]) * 100)
            this.setState({ newpcts_chg })
        }

    }

    revertPCTRow = (idx) => () => {
        //Get Current Values and Styles
        var PCT_revert_button_color = this.state.PCT_revert_button_color
        var PCT_font_weight = this.state.PCT_font_weight
        var PCT_table_cell_class = this.state.PCT_table_cell_class
        var pcts = this.state.pcts
        var hist_pcts = this.state.hist_pcts

        //Change to No Class, wait .5 seconds, then continue
        PCT_table_cell_class[idx] = "" //turn it off
        this.setState({ PCT_table_cell_class })

        function startanimation(element) {
            element.classList.add("change_data_flash");
            element.addEventListener("animationend", function () {
                element.classList.remove("change_data_flash");
            });
        }

        startanimation(document.getElementById("histpctcell_" + idx))
        startanimation(document.getElementById("histopromopricecell_" + idx))

        //Change Style
        PCT_revert_button_color[idx] = "#8E908F" //Change to NPD light grey
        PCT_font_weight[idx] = "normal" //Change to normal
        PCT_table_cell_class[idx] = "change_data_flash" //turn it off
        //Change Value
        pcts[idx] = hist_pcts[idx]
        //Check ASPs and PCTs...if also changed, do not change override flag....
        var override_base_flags = [...this.state.override_base_flags]
        var ASP_font_weight_idx = this.state.ASP_font_weight[idx]
        var volume_font_weight_idx = this.state.volume_font_weight[idx]

        if (ASP_font_weight_idx === 'bold' || volume_font_weight_idx === 'bold') {
            override_base_flags[idx] = true
        }
        else {
            override_base_flags[idx] = false
        }
        //Set State                        
        this.setState({ pcts, PCT_revert_button_color, PCT_font_weight, PCT_table_cell_class, override_base_flags })
    }    

    onChangeAdType = idx => e => {
        //console.log('Changing Ad Type')
        let value = e.value
        var selected_options_ads = this.state.selected_options_ads
        selected_options_ads[idx] = [e]
        this.setState({ selected_options_ads })
        //Find Out Which Data set We are In...
        var datasetname = this.state.DataSetChosen_option[0].label
        var ad_value_tracking = this.state.ad_value_tracking
        var ad_value = this.state.ad_value
        ad_value_tracking[idx] = value
        this.setState({ ad_value_tracking })
        //Process the rules according to the logic of the dataset
        if (Constant.promo_add_one_only.includes(datasetname)) {
            //Motor Oil Datasets
            var motoroildatasets = ['Passenger Car Motor Oil','Heavy Duty Motor Oil']
            if (motoroildatasets.includes(datasetname)) {
                var promo_value = this.state.promo_value
                ad_value = this.state.ad_value
                //Look at the value for promo
                //If Set then take the value and set to the appropriate Promo Type...
                //If Not Set, leave it be...
                var current_promo_value = promo_value[idx]
                var promovalue = ''

                if (current_promo_value && current_promo_value !== 'None') {
                    if (value === 'None') {
                        promovalue = 'PromoTypeA'
                    }
                    else if (value === 'AdTypeA') {
                        promovalue = 'PromoTypeB'
                    }
                    else if (value === 'AdTypeB') {
                        promovalue = 'PromoTypeC'
                    }
                    else if (value === 'AdTypeC') {
                        promovalue = 'PromoTypeD'
                    }
                    //Set Ad Value to Null and Promo Value to What was defined above
                    promo_value[idx] = promovalue
                    ad_value[idx] = null
                    this.setState({
                        promo_value,
                        ad_value
                    })
                }

                else {
                    ad_value[idx] = value
                    this.setState({
                        ad_value
                    })
                }
            }
        }
        //Otherwise take the value
        else {
            ad_value = this.state.ad_value
            ad_value[idx] = value
            this.setState({                
                ad_value
            })
        }
    }

    onChangeAdType_Group = idx => e => {
        //console.log('Changing Ad Type')

        //Get Group...
        let group = this.state.selectedgroups[idx]
        let groupitems = group.item_vals

        //create indexs from selecteditems
        let indexes = this.state.selecteditems.map((item, idx) => {
            if (groupitems.includes(parseInt(item))) {
                return idx
            }
            else {
                return null
            }
        }).filter(Number.isFinite)

        let value = e.value
        var selected_options_ads = this.state.selected_options_ads

        indexes.forEach(idx => {

            selected_options_ads[idx] = [e]
            this.setState({ selected_options_ads })
            //Find Out Which Data set We are In...
            var datasetname = this.state.DataSetChosen_option[0].label
            var ad_value_tracking = this.state.ad_value_tracking
            var ad_value = this.state.ad_value
            ad_value_tracking[idx] = value
            this.setState({ ad_value_tracking })
            //Process the rules according to the logic of the dataset
            if (Constant.promo_add_one_only.includes(datasetname)) {
                //Motor Oil Datasets
                var motoroildatasets = ['Passenger Car Motor Oil', 'Heavy Duty Motor Oil']
                if (motoroildatasets.includes(datasetname)) {
                    var promo_value = this.state.promo_value
                    ad_value = this.state.ad_value
                    //Look at the value for promo
                    //If Set then take the value and set to the appropriate Promo Type...
                    //If Not Set, leave it be...
                    var current_promo_value = promo_value[idx]
                    var promovalue = ''

                    if (current_promo_value && current_promo_value !== 'None') {
                        if (value === 'None') {
                            promovalue = 'PromoTypeA'
                        }
                        else if (value === 'AdTypeA') {
                            promovalue = 'PromoTypeB'
                        }
                        else if (value === 'AdTypeB') {
                            promovalue = 'PromoTypeC'
                        }
                        else if (value === 'AdTypeC') {
                            promovalue = 'PromoTypeD'
                        }
                        //Set Ad Value to Null and Promo Value to What was defined above
                        promo_value[idx] = promovalue
                        ad_value[idx] = null
                        this.setState({
                            promo_value,
                            ad_value
                        })
                    }

                    else {
                        ad_value[idx] = value
                        this.setState({
                            ad_value
                        })
                    }
                }
            }
            //Otherwise take the value
            else {
                ad_value = this.state.ad_value
                ad_value[idx] = value
                this.setState({
                    ad_value
                })
            }
        })
    }

    onChangePromoType = idx => e => {
        //console.log('PlaceHolder to Send Promo Type')       
        let value = e.value
        //console.log(value)

        var selected_options_promos = this.state.selected_options_promos
        selected_options_promos[idx] = [e]
        this.setState({ selected_options_promos })
       
        var promo_value_tracking = this.state.promo_value_tracking
        promo_value_tracking[idx] = value
        this.setState({ promo_value_tracking })
        //Find Out Which Data set We are In...
        var datasetname = this.state.DataSetChosen_option[0].label
        //Process the rules according to the logic of the dataset
        if (Constant.promo_add_one_only.includes(datasetname)) {
            //Motor Oil Datasets
            var motoroildatasets = ['Passenger Car Motor Oil', 'Heavy Duty Motor Oil']
            var promo_value = this.state.promo_value
            if (motoroildatasets.includes(datasetname)) {
                promo_value = this.state.promo_value
                var ad_value = this.state.ad_value
                var ad_value_tracking = this.state.ad_value_tracking
                //Look at the value for promo
                //If Set then take the value and set to the appropriate Promo Type...
                //If Not Set, leave it be...
                //Get Ad Value from tracking...
                let current_ad_value = ad_value_tracking[idx]

                var advalue = null
                var promovalue = null
                if (current_ad_value && current_ad_value!=='None') {
                    if (value === 'None') {
                        advalue = current_ad_value
                        promovalue = null
                    }

                    else if (current_ad_value === 'AdTypeA') {
                        advalue = null
                        promovalue = 'PromoTypeB'
                    }
                    else if (current_ad_value === 'AdTypeB') {
                        advalue = null
                        promovalue = 'PromoTypeC'
                    }
                    else if (current_ad_value === 'AdTypeC') {
                        advalue = null
                        promovalue = 'PromoTypeD'
                    }

                    promo_value[idx] = promovalue
                    ad_value[idx] = advalue
                    this.setState({
                        promo_value,
                        ad_value
                    })
                }

                else {
                    promo_value[idx] = value
                    this.setState({
                        promo_value
                    })
                }
            }
        }
        //Otherwise take the value
        else {
            promo_value = this.state.promo_value
            promo_value[idx] = value
            this.setState({
                promo_value
            })
        }

    }

    onChangePromoType_Group = idx => e => {

        //Get Group...
        let group = this.state.selectedgroups[idx]
        let groupitems = group.item_vals

        //create indexs from selecteditems
        let indexes = this.state.selecteditems.map((item, idx) => {
            if (groupitems.includes(parseInt(item))) {
                return idx
            }
            else {
                return null
            }
        }).filter(Number.isFinite)

        //console.log('PlaceHolder to Send Promo Type')       
        let value = e.value
        //console.log(value)

        var selected_options_promos = this.state.selected_options_promos

        indexes.forEach(idx => {
            selected_options_promos[idx] = [e]
            this.setState({ selected_options_promos })

            var promo_value_tracking = this.state.promo_value_tracking
            promo_value_tracking[idx] = value
            this.setState({ promo_value_tracking })
            //Find Out Which Data set We are In...
            var datasetname = this.state.DataSetChosen_option[0].label
            //Process the rules according to the logic of the dataset
            if (Constant.promo_add_one_only.includes(datasetname)) {
                //Motor Oil Datasets
                var motoroildatasets = ['Passenger Car Motor Oil', 'Heavy Duty Motor Oil']
                var promo_value = this.state.promo_value
                if (motoroildatasets.includes(datasetname)) {
                    promo_value = this.state.promo_value
                    var ad_value = this.state.ad_value
                    var ad_value_tracking = this.state.ad_value_tracking
                    //Look at the value for promo
                    //If Set then take the value and set to the appropriate Promo Type...
                    //If Not Set, leave it be...
                    //Get Ad Value from tracking...
                    let current_ad_value = ad_value_tracking[idx]

                    var advalue = null
                    var promovalue = null
                    if (current_ad_value && current_ad_value !== 'None') {
                        if (value === 'None') {
                            advalue = current_ad_value
                            promovalue = null
                        }

                        else if (current_ad_value === 'AdTypeA') {
                            advalue = null
                            promovalue = 'PromoTypeB'
                        }
                        else if (current_ad_value === 'AdTypeB') {
                            advalue = null
                            promovalue = 'PromoTypeC'
                        }
                        else if (current_ad_value === 'AdTypeC') {
                            advalue = null
                            promovalue = 'PromoTypeD'
                        }

                        promo_value[idx] = promovalue
                        ad_value[idx] = advalue
                        this.setState({
                            promo_value,
                            ad_value
                        })
                    }

                    else {
                        promo_value[idx] = value
                        this.setState({
                            promo_value
                        })
                    }
                }
            }
            //Otherwise take the value
            else {
                promo_value = this.state.promo_value
                promo_value[idx] = value
                this.setState({
                    promo_value
                })
            }
        })
    }

    handleSetSelectedRow = (idx) => () => {
        //console.log(idx)
        this.setState({ rowSelected: idx })

        const rowSelectedList = this.state.rowSelectedList
        var newvalues = rowSelectedList.map(row => false)
        newvalues[idx] = true
        this.setState({ rowSelectedList: newvalues })
    }

    expandGroup = (e, idx) => {
        console.log('Expanding Group!')
        console.log(e)
        console.log(idx)
        let group = (this.state.selectedgroups || [])[idx]
        //let groupitems = group.item_vals
        //Show Group
        let tablerows = document.getElementsByClassName(group.groupname);
        for (var i = 0; i < tablerows.length; i++) {
            if (tablerows[i].style.display === "none") {
                tablerows[i].style.display = "";
            }
            else {
                tablerows[i].style.display = "none";
            }
        }

    }

    onChangeName(event) {
        this.setState({ simname: event.target.value });
        //console.log(Constant.entrytype)
        //console.log(this.state.allSimNames)
        let allSimNames = this.state.allSimNames
        let simname = event.target.value
        if (Constant.entrytype !== 'edit') {
            //Check Name and See if in the corresponding list....if so, throw a warning...
            if (allSimNames.includes(simname)) {
                console.log('Throw a warning here...')
                this.setState({ sim_name_same: true })
            }
            else {
                //Revert Back to Safe State
                this.setState({ sim_name_same: false })
            }
        }
    }

    checkItemGroups(selectedOptions) {
        console.log(selectedOptions)
        if (selectedOptions) {
            let itemstocheck = []
            selectedOptions.forEach(option => {
                let items = option.value
                items.forEach(item=>itemstocheck.push(String(item)))
            })
            let selecteditems = this.state.selecteditems
            let bulkedit = this.state.bulkedit
            selecteditems.forEach((item, idx) =>{
                if (itemstocheck.includes(item)) {
                    bulkedit[idx] = true
                }
                else {
                    bulkedit[idx] = false
                }
            })

            //Check if bulkedit display needs to be shown:
            let bulkeditdisplay = this.state.bulkeditdisplay
            console.log('Showing the Checkmarks')
            if (bulkeditdisplay === 'none') {
                bulkeditdisplay = 'table-cell'
            }

            this.setState({ bulkeditdisplay, bulkedit })
        }
    }

    onChangeDataSet(selectedOptions, action) {
        console.log(selectedOptions)
        this.addBackdrop()
        this.SetDataSetInfo(selectedOptions.value)
        this.SetGeos(selectedOptions.value, null)
        //Clear out Selections....
        this.setState({ Geochosen: [], items_brand_dict: {}, selectedstartdate: "", selectedenddate: "", selectedstartdate_adj: "" })
        console.log('Removing Backdrop')
        this.removeBackdrop()
    }

    onChangeGeo(selectedOptions, action) {
        this.addBackdrop()
        let dataset = this.state.DataSetChosen
        this.setState({ Geochosen: selectedOptions })
        this.SetParametersInfo(dataset, selectedOptions.value)
        //this.createItemBrandDict(dataset, selectedOptions.value)
        console.log('Removing Backdrop')
        this.removeBackdrop()
    }

    async onChangeBrands_comp (selectedOptions, action) {
        //console.log('I am Running On Change Brands')
        var selecteditems = this.state.selecteditems
        var items_lookup_object = this.state.items_lookup_object
        var mybranditems = []
        var mybranditems_checked = []
        var compitems = []
        var compitems_checked = []
        var internalcompitems = []
        var otheritems = []
        var otheritems_checked = []
        if (isNull(selectedOptions)) {
            mybranditems = []
            mybranditems_checked = []
            compitems = []
            compitems_checked = []
            internalcompitems = []
            otheritems = Object.keys(this.state.items_lookup_object)
            otheritems_checked = []
            var selectedbrands = []
            Object.keys(this.state.items_lookup_object).forEach(item => {
                if (selecteditems.includes(item)) {
                    otheritems_checked.push(true)
                }
                else {
                    otheritems_checked.push(false)
                }
            })
        }
        else {            
            //Check if ('Select All') is in userbrands...
            let values = selectedOptions.map(option => option.value) 
            var userbrands = []
            var selectedbrands = []
            if (values.includes('*')) {
                userbrands = this.state.brands.map(brand => brand.value)
                selectedbrands = this.state.brands
            }
            else {
                userbrands = selectedOptions.map(option => option.value)
                selectedbrands = selectedOptions
            }
                             
            mybranditems = []
            mybranditems_checked = []
            compitems = []
            compitems_checked = []
            internalcompitems = []
            otheritems = Object.keys(this.state.items_lookup_object)
            otheritems_checked = []
            Object.keys(items_lookup_object).forEach(item => {
                console.log(item)
                if (items_lookup_object[item]['hassales'] === true && userbrands.includes(items_lookup_object[item]['brand'])) {
                    mybranditems.push(item)
                    if (selecteditems.includes(item)) {
                        mybranditems_checked.push(true)
                    }
                    else {
                        mybranditems_checked.push(false)
                    }
                }
            })

            //Grab competitors only for the items already selected...
            let itemstograbcomps = []
            console.log('selectedItems',selecteditems)
            mybranditems.forEach(item => {
                if (selecteditems.includes(item))  {
                    itemstograbcomps.push(parseInt(item))
                }
                console.log('itemstograbcomps', itemstograbcomps)
            })

            if (itemstograbcomps.length > 0) {
                const comp_post_data = {
                    itemids: itemstograbcomps,
                    dataset_id: this.state.DataSetChosen, //Need to replace with
                    geogid: [this.state.Geochosen.value],
                }

                console.log('Running Get Comps...')
                const comptetitorsres = await fetch(Constant.baseapiurl + "Simulation/get_competitors",
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(comp_post_data)
                    })
                if (comptetitorsres.status === 200) {
                    var competitorsdata = await comptetitorsres.json()
                }
                else if (comptetitorsres.status === 511) {
                    var results = await comptetitorsres.json()
                    this.setState({
                        displayReloadDialog: true
                        , reloadDialog: results["Reason"]
                        , showBackdrop_CreateSim: false
                    })
                }
                else {
                    console.log('There was an Error Running the API')
                    //Show an error to the user...
                    this.setState({
                        displayReloadDialog: true
                        , reloadDialog: "There was an error loading data.  Please reload this page."
                        , showBackdrop_CreateSim: false
                    })
                }

                //console.log(competitorsdata)
                //console.log(competitorsdata['comp_prodid'])
                var compitems_fromapi = []
                competitorsdata.forEach(row =>
                    row['comp_prodid'].map(item => {
                        if (!compitems_fromapi.includes(item)) {
                            compitems_fromapi.push(item)
                        }
                    })
                )
                compitems = []
                internalcompitems = []
                compitems_checked = []
                //Check if the competitive item has sales
                Object.keys(items_lookup_object).forEach(item => {
                    console.log(item)
                    if (items_lookup_object[item]['hassales'] === true && compitems_fromapi.includes(parseInt(item))) {
                        //If the item is in my brands, track as internal competitor
                        if (mybranditems.includes(item)) {
                            internalcompitems.push(item)
                        }
                        else {
                            compitems.push(item)
                            if (selecteditems.includes(item)){
                                compitems_checked.push(true)
                            }
                            else {
                                compitems_checked.push(false)
                            }
                        }

                    }
                })
            }

            else {
                compitems = []
                compitems_checked = []
                internalcompitems = []

            }

            //Move remaining

            otheritems = []
            otheritems_checked = []

            Object.keys(items_lookup_object).forEach(item => {
                //console.log(item)
                if (items_lookup_object[item]['hassales'] === true && !mybranditems.includes(item) && !compitems.includes(item)) {
                    otheritems.push(item)
                    if (selecteditems.includes(item)) {
                        otheritems_checked.push(true)
                    }
                    else {
                        otheritems_checked.push(false)
                    }
                }
            })

            //create state objects for 'Select All' mybrands, compitems, otheritems

            var numselected_mybrands = 0
            var numselected_compitems = 0
            var numselected_otheritems = 0

            mybranditems_checked.forEach(check => { if (check) { numselected_mybrands += 1 } })
            compitems_checked.forEach(check => { if (check) { numselected_compitems += 1 } })
            otheritems_checked.forEach(check => { if (check) { numselected_otheritems += 1 } })

            var mybranditems_allchecked = false
            var compitems_allchecked = false
            var otheritems_allchecked = false

            if (numselected_mybrands === mybranditems.length && mybranditems.length !== 0) {
                mybranditems_allchecked = true
            }

            else {
                mybranditems_allchecked = false

            }
            if (numselected_compitems === compitems.length && compitems.length !== 0) {
                compitems_allchecked = true
            }
            else {
                compitems_allchecked = false
            }

            if (numselected_otheritems === otheritems.length && otheritems.length !== 0) {
                otheritems_allchecked = true
            }

            else {
                otheritems_allchecked = false
            }

        }

        this.setState({
              mybranditems
            , mybranditems_checked
            , compitems
            , compitems_checked
            , internalcompitems
            , otheritems
            , otheritems_checked
            , mybranditems_allchecked
            , compitems_allchecked
            , otheritems_allchecked
            , selectedbrands
            , sim_brands: userbrands
        })

    }

    handleDateStart(date) {
        console.log(date)
        if (date.length < 10) {
            this.setState({ selectedstartdate: "", selectedstartdate_adj: "" });
        }
        else {
            try {
                var startdate = String(date.format('YYYY-MM-DD'))
                var adjstartdate = String(date.add(6, 'days').format('YYYY-MM-DD'))
                this.setState({ selectedstartdate: startdate, selectedstartdate_adj: adjstartdate });
            }
            catch (err) {
                console.log(err)
            }
        }

    };

    handleDateEnd(date) {
        if (date.length < 10) {
            this.setState({ selectedenddate: "" });
        }
        else {
            try {
                var enddate = String(date.format('YYYY-MM-DD'))
                this.setState({ selectedenddate: enddate });
            }
            catch (err) {
                console.log(err)
            }
        }
    };

    handleChangeSimTypeRadio = event => {
        this.setState({ simtype: event.target.value })
        //this.SetDisplayTypes(event.target.value, this.state.DataSetChosen)
    }

    handleChangeCoverage = e => {
        this.setState({ coverage: e.target.value / 100 })
    }

    handleChangeGeneric = (idx, arg1, arg2) => e => {
        //console.log(idx)
        //console.log(arg1)
        //console.log(arg2)
        function startanimation(element) {
            element.classList.add("change_data_flash");
            element.addEventListener("animationend", function () {
                element.classList.remove("change_data_flash");
            });
        }

        //console.log(idx)
        //console.log(arg1)
        //console.log(e.target.value)
        //Change Values
        var newValue = 0
        var string_value = e.target.value.replace(/,/g, "") // Replace all commas with empty char
        //console.log(string_value)
        //Check length...if greater than 0, then parseFloat, else 0
        if (string_value.length > 0) {
            //console.log(parseFloat(string_value, 10))
            newValue = parseFloat(string_value, 10)
            //newValue = parseFloat(string_value, 10).toFixed(2)
            
        }

        else {
            if (arg1 === 'Financials') {
                newValue = null
            }
            else {
                newValue = 0
            }
        }

        //console.log(newValue)
      


        const override_base_flags = [...this.state.override_base_flags]
        const vols = [...this.state.vols]
        const asps = [...this.state.asps];
        const pcts = [...this.state.pcts];
        const newbases = [...this.state.newbases];
        const newbases_chg = [...this.state.newbases_chg];
        const newpcts = [...this.state.newpcts];
        const newpcts_chg = [...this.state.newpcts_chg];
        const volume_revert_button_color = [...this.state.volume_revert_button_color]
        const volume_font_weight = [...this.state.volume_font_weight]
        const volume_table_cell_class = [...this.state.volume_table_cell_class]
        const ASP_revert_button_color = [...this.state.ASP_revert_button_color]
        const ASP_font_weight = [...this.state.ASP_font_weight]
        const ASP_table_cell_class = [...this.state.ASP_table_cell_class]
        const PCT_revert_button_color = [...this.state.PCT_revert_button_color]
        const PCT_font_weight = [...this.state.PCT_font_weight]
        const PCT_table_cell_class = [...this.state.PCT_table_cell_class]
        var financials = this.state.financials
        var input_errors_financials = this.state.input_errors_financials
        var input_errors_base = this.state.input_errors_base
        var input_errors_promo = this.state.input_errors_promo
        if (arg2 === 'OverRide') {
            override_base_flags[idx] = true
        }

        if (arg1 === 'Volume') {
            volume_revert_button_color[idx] = "#4e106f" //Change to NPD MidBlue
            volume_font_weight[idx] = "bold" //Change to bold
            volume_table_cell_class[idx] = "change_data_flash"
            startanimation(document.getElementById("histvolcell_" + idx))
            vols[idx] = newValue
            //console.log(vols)
            this.setState({ vols, volume_revert_button_color, volume_font_weight, volume_table_cell_class, override_base_flags })
        }

        else if (arg1 === 'BasePrice') {


            ASP_revert_button_color[idx] = "#4e106f" //Change to NPD MidBlue
            ASP_font_weight[idx] = "bold" //Change to bold
            ASP_table_cell_class[idx] = "change_data_flash"
            startanimation(document.getElementById("histaspcell_" + idx))
            startanimation(document.getElementById("histopromopricecell_" + idx))
            asps[idx] = (newValue)
            console.log(asps[idx].toLocaleString())
            console.log(asps)
            //console.log('Setting State...')
            this.setState({ asps, ASP_revert_button_color, ASP_font_weight, ASP_table_cell_class, override_base_flags })

            //Look at NewBase...if populated, change the percent change based on new value            
            if (newbases[idx] > 0) {
                newbases_chg[idx] = Math.round(((newbases[idx] - asps[idx]) / asps[idx]) * 100)
                this.setState({ newbases_chg })
                console.log(newbases_chg)
            }
            //Look at PCTs and if populated, change the value            
            if (newpcts[idx] > 0) {
                newpcts_chg[idx] = (asps[idx] * ((100 - newpcts[idx]) / 100)).toFixed(6).slice(0,-4)
                this.setState({ newpcts_chg })
                console.log(newpcts_chg)
            }

        }

        

        else if (arg1 === 'DiscPct') {
            PCT_revert_button_color[idx] = "#4e106f" //Change to NPD MidBlue
            PCT_font_weight[idx] = "bold" //Change to bold
            PCT_table_cell_class[idx] = "change_data_flash"
            startanimation(document.getElementById("histpctcell_" + idx))
            startanimation(document.getElementById("histopromopricecell_" + idx))
            pcts[idx] = newValue
            console.log(pcts)
            this.setState({ pcts, PCT_revert_button_color, PCT_font_weight, PCT_table_cell_class, override_base_flags })
        }

        else if (arg1 === 'SimBasePrice') {

            //Add Validation Here
            input_errors_base = this.state.input_errors_base

            if (e.target.value < 0.01) {
                //console.log('We have a problem...')
                input_errors_base[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please make sure your base price is greater or equal to ' + this.state.currencysymbol + '0.01'
                })
            }

            else if (isNaN(e.target.value/1)) {
                //console.log('We have a problem...')
                input_errors_base[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please make sure you have entered a number'
                })
            }

            else {
                input_errors_base[idx] = false
                //if all errors are false, set to none
                let num_errors = 0
                input_errors_base.forEach(error => {
                    if (error) {
                        num_errors += 1
                    }
                })
                if (num_errors === 0) {
                    this.setState({
                        item_error_display: 'none',
                        item_error_text: ''
                    })
                }
            }

            newbases[idx] = e.target.value
            newbases_chg[idx] = Math.round(((e.target.value - asps[idx]) / asps[idx]) * 100)
            this.setState({ newbases, newbases_chg })
        }

        else if (arg1 === 'SimBasePrice_Group') {

            //Get Group...
            let group = this.state.selectedgroups[idx]
            let groupitems = group.item_vals

            //create indexs from selecteditems
            let indexes = this.state.selecteditems.map((item, idx) => {
                if (groupitems.includes(parseInt(item))) {
                    return idx
                }
                else {
                    return null
                }
            }).filter(Number.isFinite)



            //Add Validation Here
            input_errors_base = this.state.input_errors_base

            indexes.forEach(idx => {

                if (e.target.value < 0.01) {
                    //console.log('We have a problem...')
                    input_errors_base[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please make sure your base price is greater or equal to ' + this.state.currencysymbol + '0.01'
                    })
                }

                else if (isNaN(e.target.value / 1)) {
                    //console.log('We have a problem...')
                    input_errors_base[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please make sure you have entered a number'
                    })
                }

                else {
                    input_errors_base[idx] = false
                    //if all errors are false, set to none
                    let num_errors = 0
                    input_errors_base.forEach(error => {
                        if (error) {
                            num_errors += 1
                        }
                    })
                    if (num_errors === 0) {
                        this.setState({
                            item_error_display: 'none',
                            item_error_text: ''
                        })
                    }
                }

                newbases[idx] = e.target.value
                newbases_chg[idx] = Math.round(((e.target.value - asps[idx]) / asps[idx]) * 100)
                this.setState({ newbases, newbases_chg })
            })
        }

        else if (arg1 === 'SimBasePriceChange') {
            input_errors_base = this.state.input_errors_base
            let newbp = (parseFloat(asps[idx]) + ((e.target.value / 100) * asps[idx])).toFixed(2)
            
            if (newbp < 0.01) {
                console.log('We have a problem...')
                input_errors_base[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please make sure your base price is greater or equal to ' + this.state.currencysymbol + '0.01'
                })
            }

            else if (isNaN(e.target.value / 1)) {
                console.log('We have a problem...')
                console.log(input_errors_base)
                input_errors_base[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please make sure you have entered a number'
                })
            }

            else {
                input_errors_base[idx] = false
                //if all errors are false, set to none
                let num_errors = 0
                input_errors_base.forEach(error => {
                    if (error) {
                        num_errors += 1
                    }
                })
                if (num_errors === 0) {
                    this.setState({
                        item_error_display: 'none',
                        item_error_text: ''
                    })
                }

            }


            newbases_chg[idx] = e.target.value
            newbases[idx] = (parseFloat(asps[idx]) + ((e.target.value / 100) * asps[idx])).toFixed(2)
            this.setState({ newbases, newbases_chg })
        }

        else if (arg1 === 'SimBasePriceChange_Group') {

            //Get Group...
            let group = this.state.selectedgroups[idx]
            let groupitems = group.item_vals

            //create indexs from selecteditems
            let indexes = this.state.selecteditems.map((item, idx) => {
                if (groupitems.includes(parseInt(item))) {
                    return idx
                }
                else {
                    return null
                }
            }).filter(Number.isFinite)

            input_errors_base = this.state.input_errors_base

            indexes.forEach(idx => {

                let newbp = (parseFloat(asps[idx]) + ((e.target.value / 100) * asps[idx])).toFixed(2)

                if (newbp < 0.01) {
                    console.log('We have a problem...')
                    input_errors_base[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please make sure your base price is greater or equal to ' + this.state.currencysymbol + '0.01'
                    })
                }

                else if (isNaN(e.target.value / 1)) {
                    console.log('We have a problem...')
                    console.log(input_errors_base)
                    input_errors_base[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please make sure you have entered a number'
                    })
                }

                else {
                    input_errors_base[idx] = false
                    //if all errors are false, set to none
                    let num_errors = 0
                    input_errors_base.forEach(error => {
                        if (error) {
                            num_errors += 1
                        }
                    })
                    if (num_errors === 0) {
                        this.setState({
                            item_error_display: 'none',
                            item_error_text: ''
                        })
                    }

                }


                newbases_chg[idx] = e.target.value
                newbases[idx] = (parseFloat(asps[idx]) + ((e.target.value / 100) * asps[idx])).toFixed(2)
                this.setState({ newbases, newbases_chg })
            })
        }

        else if (arg1 === 'SimPctDisc') {

            //Add validation here....
            //check newValue
            input_errors_promo = this.state.input_errors_promo

            if (e.target.value >= 100 || e.target.value < 0) {
                //console.log('We have a problem...')
                input_errors_promo[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please make sure your discount percent is between 0% and 99%'
                })
            }

            else if (isNaN((parseFloat(asps[idx]) * (1 - (e.target.value / 100))).toFixed(2))) {
                //console.log('We have a problem...')
                input_errors_promo[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please ensure that you have entered a number'
                })
            }

            else {
                input_errors_promo[idx] = false
                //if all errors are false, set to none
                let num_errors = 0
                input_errors_promo.forEach(error => {
                    if (error) {
                        num_errors +=1
                    }
                })
                if (num_errors === 0) {
                    this.setState({
                        item_error_display: 'none',
                        item_error_text: ''
                    })
                }
                
            }
            
            newpcts[idx] = Math.round(parseFloat(e.target.value))
//            newpcts_chg[idx] = (parseFloat(asps[idx]) * (1 - (e.target.value / 100))).toFixed(6).slice(0,-4)
            // just commenting to test ---------- newpcts_chg[idx] = Math.round((parseFloat(asps[idx]) * (1 - (e.target.value / 100))) * 100) / 100.
            if (e.target.value === 0) {
                newpcts_chg[idx] = parseFloat(asps[idx])
            }
            else {
                newpcts_chg[idx] = (Math.round((parseFloat(asps[idx]) * (1 - (e.target.value / 100))) * 100) / 100.)
            }
            this.setState({ newpcts, newpcts_chg })
            
            
        }

        else if (arg1 === 'SimPctDisc_Group') {
            //Get Group...
            let group = this.state.selectedgroups[idx]
            let groupitems = group.item_vals

            //create indexs from selecteditems
            let indexes = this.state.selecteditems.map((item, idx) => {
                if (groupitems.includes(parseInt(item))) {
                    return idx
                }
                else {
                    return null
                }
            }).filter(Number.isFinite)

            //Add validation here....
            //check newValue
            input_errors_promo = this.state.input_errors_promo

            indexes.forEach(idx => {
                if (e.target.value >= 100 || e.target.value < 0) {
                    //console.log('We have a problem...')
                    input_errors_promo[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please make sure your discount percent is between 0% and 99%'
                    })
                }

                else if (isNaN((parseFloat(asps[idx]) * (1 - (e.target.value / 100))).toFixed(2))) {
                    //console.log('We have a problem...')
                    input_errors_promo[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please ensure that you have entered a number'
                    })
                }

                else {
                    input_errors_promo[idx] = false
                    //if all errors are false, set to none
                    let num_errors = 0
                    input_errors_promo.forEach(error => {
                        if (error) {
                            num_errors += 1
                        }
                    })
                    if (num_errors === 0) {
                        this.setState({
                            item_error_display: 'none',
                            item_error_text: ''
                        })
                    }

                }

                newpcts[idx] = e.target.value
                newpcts_chg[idx] = Math.round((parseFloat(asps[idx]) * (1 - (e.target.value / 100))).toFixed(6).slice(0, -4))
                this.setState({ newpcts, newpcts_chg })
            })

        }

        else if (arg1 === 'SimPctDiscChange') {

            //Add validation here....
            //check newValue
            input_errors_promo = this.state.input_errors_promo
            let newpct_calc = Math.round((100 - (e.target.value / parseFloat(asps[idx])) * 100))
            if (newpct_calc >= 100 || newpct_calc < 0) {
                //console.log('We have a problem...')
                input_errors_promo[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please make sure your discount percent is between 0% and 99%'
                })
            }

            else if (isNaN(newpct_calc)) {
                //console.log('We have a problem...')
                input_errors_promo[idx] = true
                this.setState({
                    item_error_display: 'inline',
                    item_error_text: 'Please ensure that you have entered a number'
                })
            }

            else {
                input_errors_promo[idx] = false
                //if all errors are false, set to none
                let num_errors = 0
                input_errors_promo.forEach(error => {
                    if (error) {
                        num_errors += 1
                    }
                })
                if (num_errors === 0) {
                    this.setState({
                        item_error_display: 'none',
                        item_error_text: ''
                    })
                }

            }
            newpcts_chg[idx] = (parseFloat(e.target.value)*100) / 100.
            //newpcts[idx] = Math.round((100 - (e.target.value / parseFloat(asps[idx])) * 100))
            console.log(asps[idx] === e.target.value)
            // commenting out to test only -------------            newpcts[idx] = Math.round((10000 - (e.target.value / parseFloat(asps[idx])) * 10000))/100.0
            if (asps[idx] === e.target.value) {
               
                newpcts[idx] = 0
            }
            else {
                newpcts[idx] = Math.round((10000 - (e.target.value / parseFloat(asps[idx])) * 10000) / 100)

            }
            this.setState({ newpcts, newpcts_chg })
        }

        else if (arg1 === 'SimPctDiscChange_Group') {

            //Get Group...
            let group = this.state.selectedgroups[idx]
            let groupitems = group.item_vals

            //create indexs from selecteditems
            let indexes = this.state.selecteditems.map((item, idx) => {
                if (groupitems.includes(parseInt(item))) {
                    return idx
                }
                else {
                    return null
                }
            }).filter(Number.isFinite)

            //Add validation here....
            //check newValue
            input_errors_promo = this.state.input_errors_promo
            indexes.forEach(idx => {
                let newpct_calc = Math.round((100 - (e.target.value / parseFloat(asps[idx])) * 100))
                if (newpct_calc >= 100 || newpct_calc < 0) {
                    //console.log('We have a problem...')
                    input_errors_promo[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please make sure your discount percent is between 0% and 99%'
                    })
                }

                else if (isNaN(newpct_calc)) {
                    //console.log('We have a problem...')
                    input_errors_promo[idx] = true
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please ensure that you have entered a number'
                    })
                }

                else {
                    input_errors_promo[idx] = false
                    //if all errors are false, set to none
                    let num_errors = 0
                    input_errors_promo.forEach(error => {
                        if (error) {
                            num_errors += 1
                        }
                    })
                    if (num_errors === 0) {
                        this.setState({
                            item_error_display: 'none',
                            item_error_text: ''
                        })
                    }

                }
                newpcts_chg[idx] = e.target.value
                newpcts[idx] = Math.round((100 - (e.target.value / parseFloat(asps[idx])) * 100))
                this.setState({ newpcts, newpcts_chg })
            })
        }

        else if (arg1 === 'Financials') {
            console.log('Changing Financials')
            var financials_errors = false
            var financials_errors_text = ''
            //check newValue....
            if (newValue < 0) {
                //console.log('Error with Financials data')
                input_errors_financials[idx][arg2] = true
                financials_errors = true
                financials_errors_text = 'Please ensure your entry is greater than 0'
            }
            else if (isNaN(newValue / 1)) {
                //console.log('Error with Financials data')
                input_errors_financials[idx][arg2] = true
                financials_errors = true
                financials_errors_text = 'Please ensure your entry is a number'
            }
            else {
                input_errors_financials[idx][arg2] = false
                financials_errors = false
                financials_errors_text = ''
            }
            console.log('financials[idx][arg2]', financials[idx][arg2])
            financials[idx][arg2] = parseFloat(newValue)
            //console.log(financials[idx][arg2])

            this.setState({ financials, financials_errors, financials_errors_text })
        }

        else if (arg1 === 'Financials_Group') {
            console.log('Changing Financials')

            //Get Group...
            let group = this.state.selectedgroups[idx]
            let groupitems = group.item_vals

            //create indexs from selecteditems
            let indexes = this.state.selecteditems.map((item, idx) => {
                if (groupitems.includes(parseInt(item))) {
                    return idx
                }
                else {
                    return null
                }
            }).filter(Number.isFinite)

            var financials_errors = false
            var financials_errors_text = ''
            indexes.forEach(idx => {
                //check newValue....
                if (newValue < 0) {
                    //console.log('Error with Financials data')
                    input_errors_financials[idx][arg2] = true
                    financials_errors = true
                    financials_errors_text = 'Please ensure your entry is greater than 0'
                }
                else if (isNaN(newValue / 1)) {
                    //console.log('Error with Financials data')
                    input_errors_financials[idx][arg2] = true
                    financials_errors = true
                    financials_errors_text = 'Please ensure your entry is a number'
                }
                else {
                    input_errors_financials[idx][arg2] = false
                    financials_errors = false
                    financials_errors_text = ''
                }
                financials[idx][arg2] = parseFloat(newValue)
                

                this.setState({ financials, financials_errors, financials_errors_text })
            })
        }

    }

    //Second Form Page
    //Filters
    handleChangeSlider = (event, newValue) => {
        //console.log('Setting Slider Value...')
        //console.log(newValue)
        this.setState({ sliderRange: newValue })

    }

    handleChangeASPMinBox = (event, newValue) => {
        //console.log('Setting State')
        var input = document.getElementById("aspmin");
        let value = input.value
        //console.log(value)
        var sliderRange = []
        if (isNaN(value)) {
            alert('You Must Only input Numbers.  Resetting to ASP Minimum')
            sliderRange = [this.state.sliderRangeMin, this.state.sliderRange[1]]
            this.setState({ sliderRange })
        }

        else {
            //check that value >= 0 and <= max
            if (value < 0 || value > this.state.sliderRangeMax) {
                let alerttext = 'Value Entered Must Be Between' + this.state.sliderRangeMin + ' and ' + this.state.sliderRangeMax + '. Resetting ASP Minimum'
                sliderRange = [this.state.sliderRangeMin, this.state.sliderRange[1]]
                this.setState({ sliderRange })
                alert(alerttext)
            }

            else {
                //console.log('Setting State')
                sliderRange = [value, this.state.sliderRange[1]]
                this.setState({ sliderRange })
            }

        }

        const setfilters = this.setfilters
        // Execute a function when the user releases a key on the keyboard
        let numruns = 0
        input.addEventListener("keyup", function (event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                //event.preventDefault();
                // Trigger the button element with a click
                console.log('Enter Key Hit')
                //numruns += 1
                //console.log(numruns)
                //if (numruns === 1) {                        
                //    setTimeout(function () {
                //        setfilters(sliderRange, 'asprange')
                //    }, 1000);
                //}

                //run setfilters
            }
        });
    }

    handleChangeASPMaxBox = (event, newValue) => {
        //console.log('Setting State')
        var input = document.getElementById("aspmax");
        let value = input.value
        //console.log(value)
        var sliderRange = []
        if (isNaN(value)) {
            alert('You Must Only input Numbers.  Resetting to ASP Maximum')
            sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
            this.setState({ sliderRange })
        }

        else {
            //check that value >= 0 and <= max
            if (value < 0 || value > this.state.sliderRangeMax) {
                let alerttext = 'Value Entered Must Be Between' + this.state.sliderRangeMin + ' and ' + this.state.sliderRangeMax + '. Resetting ASP Maximum'
                sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
                this.setState({ sliderRange })
                alert(alerttext)
            }

            else {
                //console.log('Setting State')
                sliderRange = [this.state.sliderRange[0], value]
                this.setState({ sliderRange })
            }

        }

        const setfilters = this.setfilters
        // Execute a function when the user releases a key on the keyboard
        let numruns = 0
        input.addEventListener("keyup", function (event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                //event.preventDefault();
                // Trigger the button element with a click
                //console.log('Enter Key Hit')
                //numruns += 1
                //console.log(numruns)
                //if (numruns === 1) {                        
                //    setTimeout(function () {
                //        setfilters(sliderRange, 'asprange')
                //    }, 1000);
                //}

                //run setfilters
            }
        });
    }

    sliderCommitted = () => {

        //console.log('Committed Slider Changes')
        var sliderRange = this.state.sliderRange
        //Find Items that meet the criteria...
        let matching_proddesc_asp = []
        let matching_prodids_asp = []

        Object.keys(this.state.items_lookup_object).forEach((item, idx) => {
            //console.log(key)
            let itemasp = this.state.items_lookup_object[item]['avgbp']
            let itemname = this.state.items_lookup_object[item]['proddesc']
            if (itemasp >= sliderRange[0] && itemasp <= sliderRange[1]) {
                matching_proddesc_asp.push(itemname)
                matching_prodids_asp.push(item)
            }
        })
        //console.log(matching_proddesc_asp)
        this.setState({ matching_prodids_asp: matching_prodids_asp })
        //console.log(this.state.matching_prodids_attr)
        let matching_proddesc_attr = this.state.matching_prodids_attr.map(item => this.state.items_lookup_object[item]['proddesc'])
        //final check...item should be in both lists...so, union together and take use the set...
        var matching_items_proddesc = []
        if (matching_proddesc_attr.length === 0) {
            matching_items_proddesc = matching_proddesc_asp
        }
        else {
            matching_proddesc_asp.forEach(itemname => {
                if (matching_proddesc_attr.includes(itemname)) {
                    matching_items_proddesc.push(itemname)
                }
            })
        }

        //Now, apply the filter...
        //Once we have filtered down the items, we then apply the style to hide/display the items in question
        let mybranditems = document.getElementById("myBrandItemsList");
        let mybranddivs = mybranditems.getElementsByClassName("MuiListItem-root");
        let compitems = document.getElementById("compItemsList");
        let compdivs = compitems.getElementsByClassName("MuiListItem-root");
        let otheritems = document.getElementById("otherItemsList");
        let otherdivs = otheritems.getElementsByClassName("MuiListItem-root");
        var mybrandli, mybrandi, mybrandtxtValue, compli, compi, comptxtValue, otherli, otheri, othertxtValue;

        for (mybrandi = 0; mybrandi < mybranddivs.length; mybrandi++) {
            mybrandli = mybranddivs[mybrandi].getElementsByClassName("MuiListItemText-root")[0];
            mybrandtxtValue = mybrandli.textContent || mybrandli.innerText;
            if (matching_items_proddesc.includes(mybrandtxtValue) || mybrandtxtValue === 'Select All') {
                mybranddivs[mybrandi].style.display = "";
            }
            else {
                mybranddivs[mybrandi].style.display = "none";
            }
        }

        for (compi = 0; compi < compdivs.length; compi++) {
            compli = compdivs[compi].getElementsByClassName("MuiListItemText-root")[0];
            comptxtValue = compli.textContent || compli.innerText;
            if (matching_items_proddesc.includes(comptxtValue) || comptxtValue === 'Select All') {
                compdivs[compi].style.display = "";
            }
            else {
                compdivs[compi].style.display = "none";
            }
        }

        for (otheri = 0; otheri < otherdivs.length; otheri++) {
            otherli = otherdivs[otheri].getElementsByClassName("MuiListItemText-root")[0];
            othertxtValue = otherli.textContent || otherli.innerText;
            if (matching_items_proddesc.includes(othertxtValue) || othertxtValue === 'Select All') {
                otherdivs[otheri].style.display = "";
            }
            else {
                otherdivs[otheri].style.display = "none";
            }
        }

    }

    sliderCommittedNew = () => {
        //console.log('Committed Slider Changes')
        var sliderRange = this.state.sliderRange
        //Find Items that meet the criteria...
        let matching_proddesc_asp = []
        let matching_prodids_asp = []

        //console.log(Object.keys(this.state.items_lookup_object))

        Object.keys(this.state.items_lookup_object).forEach((item, idx) => {
            let itemasp = this.state.items_lookup_object[item]['avgbp']
            let itemname = this.state.items_lookup_object[item]['proddesc']
            //console.log(itemasp)
            //console.log(itemasp >= parseFloat(sliderRange[0]) && itemasp <= parseFloat(sliderRange[1]))
            if (itemasp >= parseFloat(sliderRange[0]) && itemasp <= parseFloat(sliderRange[1])) {
                matching_proddesc_asp.push(itemname)
                matching_prodids_asp.push(item)
            }
        })
        this.setState({ matching_prodids_asp: matching_prodids_asp })
        //console.log(matching_prodids_asp)
        this.filterDataNew(matching_prodids_asp, this.state.matching_prodids_attr, this.state.matching_prodids_pricebands)
    }

    filterData(selectedOptions, action) {
        //console.log('Filtering Data')
        let attribute = action.name
        var attribute_values = []
        var already_applied_filters = []
        var attribute_selections = {}
        //Attribute values
        if (selectedOptions && selectedOptions.length > 0) {
            attribute_values = selectedOptions.map(selection => selection.value)
        }
        else {
            attribute_values = []
            this.state.attributes.forEach(attr => { if (attr.attribute === attribute) { attribute_values = attr.attribute_values } })
        }

        if (this.state.attribute_selections) {
            already_applied_filters = Object.keys(this.state.attribute_selections)
            attribute_selections = this.state.attribute_selections
            attribute_selections[attribute] = attribute_values
        }
        else {
            already_applied_filters = []
            attribute_selections = {}
        }

        let attr_to_apply = []
        already_applied_filters.forEach(attr => { if (attr !== attribute) { attr_to_apply.push(attr) } })
        this.setState({ attribute_selections })

        function apply_filter(items, attribute, attribute_values, lookup_object) {
            let matching_items = []
            items.forEach(item => {
                if (attribute_values.includes(lookup_object[item][attribute])) {
                    matching_items.push(item)
                }
            })
            return matching_items
        }

        let matching_items = apply_filter(Object.keys(this.state.items_lookup_object), attribute, attribute_values, this.state.items_lookup_object)

        //run through the other attribute columns and apply the filters...
        already_applied_filters.forEach(attr => {
            matching_items = apply_filter(matching_items, attr, attribute_selections[attr], this.state.items_lookup_object)
        })

        //console.log(matching_items)
        let matching_proddesc_attr = matching_items.map(item => this.state.items_lookup_object[item]['proddesc'])
        let matching_items_prodid = matching_items.map(item => item)
        this.setState({ matching_prodids_attr: matching_items_prodid })
        //matching_proddesc_attr.push('Select All') //So we do not remove from the lists
        //console.log(matching_proddesc_attr)

        var matching_items_proddesc = matching_proddesc_attr
        //Account for ASP Filter
        //console.log(this.state.matching_prodids_asp)
        
        let matching_proddesc_asp = this.state.matching_prodids_asp.map(item => this.state.items_lookup_object[item]['proddesc'])
        //final check...item should be in both lists...so, union together and take use the set...
        matching_items_proddesc = []
        if (matching_proddesc_asp.length === 0) {
            matching_items_proddesc = matching_proddesc_attr
        }
        else {
            matching_proddesc_attr.forEach(itemname => {
                if (matching_proddesc_asp.includes(itemname)) {
                    matching_items_proddesc.push(itemname)
                }
            })
        }

        //console.log(matching_items_proddesc)
        this.setState({ matching_items_proddesc })
        
        //Once we have filtered down the items, we then apply the style to hide/display the items in question
        let mybranditems = document.getElementById("myBrandItemsList");
        let mybranddivs = mybranditems.getElementsByClassName("MuiListItem-root");
        let compitems = document.getElementById("compItemsList");
        let compdivs = compitems.getElementsByClassName("MuiListItem-root");
        let otheritems = document.getElementById("otherItemsList");
        let otherdivs = otheritems.getElementsByClassName("MuiListItem-root");
        var mybrandli, mybrandi, mybrandtxtValue, compli, compi, comptxtValue, otherli, otheri, othertxtValue;

        for (mybrandi = 0; mybrandi < mybranddivs.length; mybrandi++) {
            mybrandli = mybranddivs[mybrandi].getElementsByClassName("MuiListItemText-root")[0];
            mybrandtxtValue = mybrandli.textContent || mybrandli.innerText;
            if (matching_items_proddesc.includes(mybrandtxtValue) || mybrandtxtValue === 'Select All') {
                mybranddivs[mybrandi].style.display = "";
            }
            else {
                mybranddivs[mybrandi].style.display = "none";
            }
        }

        for (compi = 0; compi < compdivs.length; compi++) {
            compli = compdivs[compi].getElementsByClassName("MuiListItemText-root")[0];
            comptxtValue = compli.textContent || compli.innerText;
            if (matching_items_proddesc.includes(comptxtValue) || comptxtValue === 'Select All') {
                compdivs[compi].style.display = "";
            }
            else {
                compdivs[compi].style.display = "none";
            }
        }

        for (otheri = 0; otheri < otherdivs.length; otheri++) {
            otherli = otherdivs[otheri].getElementsByClassName("MuiListItemText-root")[0];
            othertxtValue = otherli.textContent || otherli.innerText;
            if (matching_items_proddesc.includes(othertxtValue) || othertxtValue === 'Select All') {
                otherdivs[otheri].style.display = "";
            }
            else {
                otherdivs[otheri].style.display = "none";
            }
        }
    }

    filterDataNew(aspitems, attritems, pricebanditems) {
        //console.log(aspitems)
        //console.log(attritems)
        //console.log(pricebanditems)
        //if an array is greater than length 0, use it, otherwise use the items in the lookup...
        var aspitemstouse = []
        var attritemstouse = []
        var pricebanditemstouse = []

        if ((aspitems || Object.keys(this.state.items_lookup_object)).length > 0) {
            if (aspitems) {
                aspitemstouse = aspitems
            }
            else {
                aspitemstouse = Object.keys(this.state.items_lookup_object)
            }
        }
        else {
            aspitemstouse = [] //Object.keys(this.state.items_lookup_object)
        }

        if ((attritems || Object.keys(this.state.items_lookup_object)).length > 0) {
            if (attritems) {
                attritemstouse = attritems
            }
            else {
                attritemstouse = Object.keys(this.state.items_lookup_object)
            }            
        }
        else {
            attritemstouse = [] //Object.keys(this.state.items_lookup_object)
        }

        if ((pricebanditems || Object.keys(this.state.items_lookup_object)).length > 0) {
            if (pricebanditems) {
                pricebanditemstouse = pricebanditems
            }
            else {
                pricebanditemstouse = Object.keys(this.state.items_lookup_object)
            }               
        }
        else {
            pricebanditemstouse = [] //Object.keys(this.state.items_lookup_object)
        }

        //If Item in all three lists, add to valid items <----This logic needs to change.  Should concatenate all lists.  Then make a set, and then take the items from that set.
        //This logic is still not good enough.  Need to look at the union of all three.  But, if a list is null, we need to consider all the items....
        //The tricky part here is we need to consider a null list, i.e. the filter hasn't been applied differently than the an empty array
        //let allitemstouse = Array.from(new Set((aspitemstouse.concat(attritemstouse)).concat(pricebanditemstouse)))
        let allitemstouse = []
        Object.keys(this.state.items_lookup_object).forEach(item => {
            if (aspitemstouse.includes(item) && attritemstouse.includes(item) && pricebanditemstouse.includes(item)) {
                allitemstouse.push(item)
            }
        })
        let validitems = []
        Object.keys(this.state.items_lookup_object).forEach(item => {
            if (allitemstouse.includes(item)) {//(aspitemstouse.includes(item) && attritemstouse.includes(item) && pricebanditemstouse.includes(item)) {
                validitems.push(item)
            }
        })

        let matching_proddesc = validitems.map(item => this.state.items_lookup_object[item]['proddesc'])
        //console.log(matching_proddesc)
        this.setState({ matching_items_proddesc: matching_proddesc })
        //Now, apply the filter...
        //Once we have filtered down the items, we then apply the style to hide/display the items in question
        let mybranditems = document.getElementById("myBrandItemsList");
        let mybranddivs = mybranditems.getElementsByClassName("MuiListItem-root");
        let compitems = document.getElementById("compItemsList");
        let compdivs = compitems.getElementsByClassName("MuiListItem-root");
        let otheritems = document.getElementById("otherItemsList");
        let otherdivs = otheritems.getElementsByClassName("MuiListItem-root");
        var mybrandli, mybrandi, mybrandtxtValue, compli, compi, comptxtValue, otherli, otheri, othertxtValue;

        for (mybrandi = 0; mybrandi < mybranddivs.length; mybrandi++) {
            mybrandli = mybranddivs[mybrandi].getElementsByClassName("MuiListItemText-root")[0];
            mybrandtxtValue = mybrandli.textContent || mybrandli.innerText;
            //console.log(mybrandtxtValue)
            //console.log(matching_proddesc.includes(mybrandtxtValue))
            if (matching_proddesc.includes(mybrandtxtValue) || mybrandtxtValue === 'Select All') {
                mybranddivs[mybrandi].style.display = "";
            }
            else {
                mybranddivs[mybrandi].style.display = "none";
            }
        }

        for (compi = 0; compi < compdivs.length; compi++) {
            compli = compdivs[compi].getElementsByClassName("MuiListItemText-root")[0];
            comptxtValue = compli.textContent || compli.innerText;
            if (matching_proddesc.includes(comptxtValue) || comptxtValue === 'Select All') {
                compdivs[compi].style.display = "";
            }
            else {
                compdivs[compi].style.display = "none";
            }
        }

        for (otheri = 0; otheri < otherdivs.length; otheri++) {
            otherli = otherdivs[otheri].getElementsByClassName("MuiListItemText-root")[0];
            othertxtValue = otherli.textContent || otherli.innerText;
            if (matching_proddesc.includes(othertxtValue) || othertxtValue === 'Select All' ) {
                otherdivs[otheri].style.display = "";
            }
            else {
                otherdivs[otheri].style.display = "none";
            }
        }
    }

    filterAttributeNew(selectedOptions, action) {
        console.log('Filtering Data')
        let attribute = action.name
        var attribute_values = []
        var already_applied_filters = []
        var attribute_selections = {}
        //Attribute values
        if (selectedOptions && selectedOptions.length > 0) {
            attribute_values = selectedOptions.map(selection => selection.value)
            attribute_selections[attribute] = attribute_values
        }
        else {
            attribute_values = []
            this.state.attributes.forEach(attr => { if (attr.attribute === attribute) { attribute_values = attr.attribute_values } })
        }
        if (this.state.attribute_selections) {
            already_applied_filters = Object.keys(this.state.attribute_selections)
            attribute_selections = this.state.attribute_selections
            attribute_selections[attribute] = attribute_values
        }
        //else {
        //    already_applied_filters = []
        //    attribute_selections = {}
        //}

        let attr_to_apply = []
        already_applied_filters.forEach(attr => { if (attr !== attribute) { attr_to_apply.push(attr) } })
        this.setState({ attribute_selections })

        //This is only taking into account 1 attribute....need to look at all selected attributes and if the item matches on all, then push
        function apply_filter(items, attribute, attribute_values, lookup_object) {
            let matching_items = []
            items.forEach(item => {
                if (attribute_values.includes(lookup_object[item][attribute])) {
                    matching_items.push(item)
                }
            })
            return matching_items
        }

        let matching_items = apply_filter(Object.keys(this.state.items_lookup_object), attribute, attribute_values, this.state.items_lookup_object)
        //console.log(matching_items)
        //run through the other attribute columns and apply the filters...
        already_applied_filters.forEach(attr => {
            matching_items = apply_filter(matching_items, attr, attribute_selections[attr], this.state.items_lookup_object)
        })

        //console.log(matching_items)
        //let matching_proddesc_attr = matching_items.map(item => this.state.items_lookup_object[item]['proddesc'])
        let matching_items_prodid = matching_items.map(item => item)
        //push matching_item_descriptions...

        this.setState({ matching_prodids_attr: matching_items_prodid })
        
        this.filterDataNew(this.state.matching_prodids_asp, matching_items_prodid, this.state.matching_prodids_pricebands )
    }

    filterPriceBands(selectedOptions, action) {
        var pricebands = []
        if (selectedOptions && selectedOptions.length > 0) {
            pricebands = selectedOptions.map(option => option.label)
        }
        else {
            pricebands = this.state.price_band_options.map(option => option.label)
        }
        //Find Items that meet the criteria...
        let matching_proddesc_pricebands = []
        let matching_prodids_pricebands = []
        
        var item_lookup_price_band = {}
        Object.keys(this.state.items_lookup_object).forEach((item, idx) => {
            //console.log(key)
            let itemasp = this.state.items_lookup_object[item]['avgbp']
            let itemname = this.state.items_lookup_object[item]['proddesc']

            //Create labels...
            this.state.price_band_options.forEach(band => {
                if (itemasp >= band.value[0] && itemasp <= band.value[1]) {
                    item_lookup_price_band[item] = band.label                    
                }
            })

            if (pricebands.includes(item_lookup_price_band[item])) {
                matching_proddesc_pricebands.push(itemname)
                matching_prodids_pricebands.push(item)
            }
        })

        //console.log(matching_proddesc_pricebands)
        //console.log(this.state)
        /*
        //console.log(matching_proddesc_asp)
        this.setState({ matching_prodids_asp: matching_prodids_asp })
        console.log(this.state.matching_prodids_attr)
        let matching_proddesc_attr = this.state.matching_prodids_attr.map(item => this.state.items_lookup_object[item]['proddesc'])
        //final check...item should be in both lists...so, union together and take use the set...
        var matching_items_proddesc = []
        if (matching_proddesc_attr.length === 0) {
            matching_items_proddesc = matching_proddesc_asp
        }
        else {
            matching_proddesc_asp.map(itemname => {
                if (matching_proddesc_attr.includes(itemname)) {
                    matching_items_proddesc.push(itemname)
                }
            })
        }
        */

        var matching_items_proddesc = []
        matching_items_proddesc = matching_proddesc_pricebands



        //Once we have filtered down the items, we then apply the style to hide/display the items in question
        let mybranditems = document.getElementById("myBrandItemsList");
        let mybranddivs = mybranditems.getElementsByClassName("MuiListItem-root");
        let compitems = document.getElementById("compItemsList");
        let compdivs = compitems.getElementsByClassName("MuiListItem-root");
        let otheritems = document.getElementById("otherItemsList");
        let otherdivs = otheritems.getElementsByClassName("MuiListItem-root");
        var mybrandli, mybrandi, mybrandtxtValue, compli, compi, comptxtValue, otherli, otheri, othertxtValue;

        for (mybrandi = 0; mybrandi < mybranddivs.length; mybrandi++) {
            mybrandli = mybranddivs[mybrandi].getElementsByClassName("MuiListItemText-root")[0];
            mybrandtxtValue = mybrandli.textContent || mybrandli.innerText;
            if (matching_items_proddesc.includes(mybrandtxtValue) || mybrandtxtValue === 'Select All' ) {
                mybranddivs[mybrandi].style.display = "";
            }
            else {
                mybranddivs[mybrandi].style.display = "none";
            }
        }

        for (compi = 0; compi < compdivs.length; compi++) {
            compli = compdivs[compi].getElementsByClassName("MuiListItemText-root")[0];
            comptxtValue = compli.textContent || compli.innerText;
            if (matching_items_proddesc.includes(comptxtValue) || comptxtValue === 'Select All') {
                compdivs[compi].style.display = "";
            }
            else {
                compdivs[compi].style.display = "none";
            }
        }

        for (otheri = 0; otheri < otherdivs.length; otheri++) {
            otherli = otherdivs[otheri].getElementsByClassName("MuiListItemText-root")[0];
            othertxtValue = otherli.textContent || otherli.innerText;
            if (matching_items_proddesc.includes(othertxtValue) || othertxtValue === 'Select All') {
                otherdivs[otheri].style.display = "";
            }
            else {
                otherdivs[otheri].style.display = "none";
            }
        }
    }

    filterPriceBandsNew(selectedOptions, action) {
        var pricebands = []
        var pricebands_sel = []
        if (selectedOptions && selectedOptions.length > 0) {
            pricebands = selectedOptions.map(option => option.label)
            pricebands_sel = selectedOptions
        }
        else {
            pricebands = this.state.price_band_options.map(option => option.label)
        }
        //Find Items that meet the criteria...
        let matching_proddesc_pricebands = []
        let matching_prodids_pricebands = []

        var item_lookup_price_band = {}
        Object.keys(this.state.items_lookup_object).forEach((item, idx) => {
            //console.log(key)
            let itemasp = this.state.items_lookup_object[item]['avgbp']
            let itemname = this.state.items_lookup_object[item]['proddesc']

            //Create labels...
            this.state.price_band_options.forEach(band => {
                if (itemasp >= band.value[0] && itemasp <= band.value[1]) {
                    item_lookup_price_band[item] = band.label
                }
            })

            if (pricebands.includes(item_lookup_price_band[item])) {
                matching_proddesc_pricebands.push(itemname)
                matching_prodids_pricebands.push(item)
            }
        })

        console.log(matching_prodids_pricebands)
        this.setState({ matching_prodids_pricebands: matching_prodids_pricebands, pricebands_sel: pricebands_sel})
        this.filterDataNew(this.state.matching_prodids_asp, this.state.matching_prodids_attr, matching_prodids_pricebands)
    }

    filterAttribute(selectedOptions, action) {
        //console.log(selectedOptions)
        //console.log(action)
        //console.log(action.name)
        var attribute_values = []

        //Item Attribute Lookup
        let item_attribute_lookup = this.state.item_attribute_lookup
        //Attribute we are looking at
        let attribute = action.name
        //Attribute values
        if (selectedOptions && selectedOptions.length > 0) {
            attribute_values = selectedOptions.map(selection => selection.value)
        }
        else {
            attribute_values = []
            this.state.attributes.forEach(attr => { if (attr.attribute === attribute) { attribute_values = attr.attribute_values } })
        }
        //Apply already applied filters...
        //console.log(this.state.attribute_selections)
        let already_applied_filters = Object.keys(this.state.attribute_selections)
        let attr_to_apply = []
        already_applied_filters.forEach(attr => { if (attr !== attribute) { attr_to_apply.push(attr) } })

        let attribute_selections = this.state.attribute_selections
        attribute_selections[attribute] = attribute_values
        this.setState({ attribute_selections })

        function apply_filter(items, attribute, attribute_values) {
            let matching_items = []
            items.forEach(item => {
                if (attribute_values.includes(item[attribute])) {
                    matching_items.push(item)
                }
            })
            return matching_items
        }

        let matching_items = apply_filter(item_attribute_lookup, attribute, attribute_values)

        //run through the other attribute columns and apply the filters...
        already_applied_filters.forEach(attr => {
            matching_items = apply_filter(matching_items, attr, attribute_selections[attr])
        })

        //console.log(matching_items)
        let matching_proddesc_attr = matching_items.map(item => item.proddesc)
        let matching_items_prodid = matching_items.map(item => item.prodid)
        this.setState({ matching_prodids_attr: matching_items_prodid })
        //matching_proddesc_attr.push('Select All') //So we do not remove from the lists
        //console.log(matching_proddesc_attr)


        //Account for ASP Filter
        //console.log(this.state.matching_prodids_asp)
        let matching_proddesc_asp = this.state.matching_prodids_asp.map(item => this.state.item_lookup[item])
        //final check...item should be in both lists...so, union together and take use the set...
        var matching_items_proddesc = []
        if (matching_proddesc_asp.length === 0) {
            matching_items_proddesc = matching_proddesc_attr
        }
        else {
            matching_proddesc_attr.forEach(itemname => {
                if (matching_proddesc_asp.includes(itemname)) {
                    matching_items_proddesc.push(itemname)
                }
            })
        }

        //Once we have filtered down the items, we then apply the style to hide/display the items in question
        let mybranditems = document.getElementById("myBrandItemsList");
        let mybranddivs = mybranditems.getElementsByClassName("MuiListItem-root");
        let compitems = document.getElementById("compItemsList");
        let compdivs = compitems.getElementsByClassName("MuiListItem-root");
        let otheritems = document.getElementById("otherItemsList");
        let otherdivs = otheritems.getElementsByClassName("MuiListItem-root");
        var mybrandli, mybrandi, mybrandtxtValue, compli, compi, comptxtValue, otherli, otheri, othertxtValue;

        for (mybrandi = 0; mybrandi < mybranddivs.length; mybrandi++) {
            mybrandli = mybranddivs[mybrandi].getElementsByClassName("MuiListItemText-root")[0];
            mybrandtxtValue = mybrandli.textContent || mybrandli.innerText;
            if (matching_items_proddesc.includes(mybrandtxtValue) || mybrandtxtValue === 'Select All') {
                mybranddivs[mybrandi].style.display = "";
            }
            else {
                mybranddivs[mybrandi].style.display = "none";
            }
        }

        for (compi = 0; compi < compdivs.length; compi++) {
            compli = compdivs[compi].getElementsByClassName("MuiListItemText-root")[0];
            comptxtValue = compli.textContent || compli.innerText;
            if (matching_items_proddesc.includes(comptxtValue) || comptxtValue === 'Select All') {
                compdivs[compi].style.display = "";
            }
            else {
                compdivs[compi].style.display = "none";
            }
        }

        for (otheri = 0; otheri < otherdivs.length; otheri++) {
            otherli = otherdivs[otheri].getElementsByClassName("MuiListItemText-root")[0];
            othertxtValue = otherli.textContent || otherli.innerText;
            if (matching_items_proddesc.includes(othertxtValue) || othertxtValue === 'Select All') {
                otherdivs[otheri].style.display = "";
            }
            else {
                otherdivs[otheri].style.display = "none";
            }
        }

    }

    

    submitsim = () => {

        var currentstate = this.state
        //console.log('currentstate', currentstate)
        //build the item dict
        var simdata = []
        var numrows = currentstate.selecteditems.length
        var promo_base = this.state.simtype// currentstate.types[0].toLowerCase();
        var sim_brands = this.state.sim_brands
        for (var row = 0; row < numrows; row++) {
            var item = parseInt(currentstate.selecteditems[row])
            var coverage = this.state.coverage + .0000000001
            var comp_flag = currentstate.competitors[row] //true or false based on the mybrands selection
            var override_base_flag = this.state.override_base_flags[row]
            var override_base_volume = parseFloat(this.state.vols[row]) + .0000000011
            var override_base_price = parseFloat(this.state.asps[row])
            var override_base_discount = parseFloat(this.state.pcts[row] / 100.0) + .0000000001
            //console.log('override_base_discount', override_base_discount )
            //console.log(parseFloat(this.state.pcts[row]))
            var newbase = null
            var newpct = null
            var promo_type = null
            var ad_type = null
            var override_base_price_toset = null
            var override_base_discount_toset = null
            var cost_per_unit = null
            var list_price = null
            var gross_margin = null
            var retail_margin = null
            if (promo_base === 'base') {
                newbase = (parseFloat(currentstate.newbases[row], 10) * 1.00) + .0000000001
                newpct = null
                promo_type = null
                ad_type = null
                override_base_price_toset = parseFloat(override_base_price) + .0000000001
                override_base_discount_toset = null
                
            }

            else if (promo_base === 'promo') {
                newbase = null //parseFloat(currentstate.asps[row], 10); //Use the Old Base Price
                if (currentstate.newpcts[row] == "") {newpct = 0.0000000001 }
                else { newpct = parseFloat(currentstate.newpcts[row]) / 100.0; }
                    
                promo_type = currentstate.promo_value[row] || 'None'
                ad_type = currentstate.ad_value[row] || 'None'
                override_base_price_toset = null
                override_base_discount_toset = parseFloat(override_base_discount) + .0000000001
            }

            if (this.state.financials[row][0] && this.state.financials[row][1] && this.state.financials[row][2] && this.state.financials[row][3]) {
                //cost_per_unit = this.state.financials[row][0] + .0000000001  // this is original calc
                cost_per_unit = (parseFloat(this.state.financials[row][0], 10)  *1.00)  + .0000000001
                //list_price = this.state.financials[row][1] + .0000000001 // this is original calc
                list_price = (parseFloat(this.state.financials[row][1], 10) * 1.00) + .0000000001
                gross_margin = this.state.financials[row][2] + .0000000001
                retail_margin = this.state.financials[row][3] + .0000000001
            }

            else {
                cost_per_unit = this.state.financials[row][0]
                list_price = this.state.financials[row][1]
                gross_margin = this.state.financials[row][2]
                retail_margin = this.state.financials[row][3]
            }


            var itemdict = {
                prod_id: item,
                //item_type: promo_base,
                //coverage: coverage,
                sim_base_price: newbase,
                sim_discount_pct: newpct,
                competitor_flag: comp_flag,
                override_base_flag: override_base_flag,
                override_base_volume: parseFloat(override_base_volume) + .0000000001,
                override_base_price: override_base_price_toset,
                override_base_discount: override_base_discount_toset,
                ad_type: ad_type, 
                promo_type: promo_type,
                cost_per_unit: cost_per_unit,
                list_price: list_price,
                gross_margin: gross_margin,
                retail_margin: retail_margin
            };

            //console.log(itemdict)
            simdata.push(itemdict)
        }
        //If Create Do this
        if (Constant.entrytype === 'create') {

            //console.log(simdata)

            const sim_post_data = {
                sim_name: currentstate.simname,
                report_id: null, //Need to Replace if null or with real reportval
                sim_type: promo_base,
                coverage: coverage,
                sim_start: currentstate.selectedstartdate, //selectedstartdate_adj
                sim_end: currentstate.selectedenddate,
                dataset_id: currentstate.DataSetChosen,
                geogid: [currentstate.Geochosen.value],
                sim_brands: sim_brands,
                items: simdata
            }

            //console.log(sim_post_data)
            //console.log(JSON.stringify(sim_post_data))
            //run fetch with post data
            //var sim_result_status = 0
            fetch(Constant.baseapiurl + "SimSettings", 
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sim_post_data)
                })
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        console.log('ok')
                        res.json()
                            .then(
                                (result) => {
                                    console.log(result)
                                    console.log('Sim Created!')
                                    this.setState({ showBackdrop_CreateSim: true, showSnackbar_CreateSimSuccess: true })
                                    
                                    // save base prices to local storage
                                    basePricesToLocalStorage(result.sim_id, this.state.selecteditems, this.state.asps)

                                    window.location.href = Constant.baseurl + 'PNP/Simulation/' + result.sim_id
                                },
                                (error) => {
                                    console.log(error)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                }
                            )
                    }
                    else if (res.status === 500) {
                        console.log('failure')
                        res.text()
                            .then(
                                (result) => {
                                    console.log(result)

                                    //Save the current state object
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));

                                    this.setState({
                                        errorAlert: true,
                                        errorTitle: "Simulation Error",
                                        errorText: 'There was an error submitting the simulation, please try again'
                                    })

                                    

                                    console.log(result)
                                },
                                (error) => {
                                    console.log(error)
                                    console.log('Saving State')
                                    localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                }
                            )
                    }

                    else if (res.status === 511) {
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop_CreateSim: false
                                    })
                                })
                    }

                    else {
                        console.log('failure')
                        res.json()
                            .then(
                                (result) => {
                                    console.log(result)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                    var error_string = ""
                                    result.Messages.map(message => error_string += "\n" + message)
                                    this.setState({
                                        errorAlert: true,
                                        errorTitle: "Simulation Error",
                                        errorText: 'There was an error submitting the simulation, please try again. The errors were as follows: ' + error_string
                                    })
                                },
                                (error) => {
                                    console.log(error)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                }
                            )
                    }
                })
        }

        else if (Constant.entrytype === 'edit') {
            this.setState({ showBackdrop_CreateSim: true })
            const sim_post_data = {
                sim_name: currentstate.simname,
                report_id: null, //Need to Replace if null or with real reportval
                sim_type: promo_base,
                coverage: coverage,
                sim_start: currentstate.selectedstartdate, //selectedstartdate_adj
                sim_end: currentstate.selectedenddate,
                dataset_id: currentstate.DataSetChosen,
                geogid: [currentstate.Geochosen.value],
                sim_brands: sim_brands,
                items: simdata,
                time_updated: currentstate.timeupdated,
                sim_id: currentstate.simid
            }

            console.log(sim_post_data)
            //console.log(JSON.stringify(sim_post_data))
            //run fetch with post data

            fetch(Constant.baseapiurl + "SimSettings/" + currentstate.simid,
                {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sim_post_data)
                })

                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        console.log('ok')
                        res.json()
                            .then(
                                (result) => {
                                    console.log('Sim Updated!')
                                    this.setState({ showBackdrop_CreateSim: true, showSnackbar_CreateSimSuccess: true })
                                    
                                    // save base prices to local storage
                                    basePricesToLocalStorage(result.sim_id, this.state.selecteditems, this.state.asps)

                                    console.log(result);
                                    window.location.href = Constant.baseurl + 'PNP/Simulation/' + currentstate.simid
                                },
                                (error) => {
                                    console.log(error)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                }
                            )
                    }

                    else if (res.status === 500) {
                        console.log('Failure')
                        console.log('Saving State')
                        //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                        this.setState({
                            showBackdrop_CreateSim: false,
                            errorAlert: true,
                            errorTitle: "Simulation Error",
                            errorText: 'There was an error submitting the simulation, please try again.'
                        })
                    }

                    else if (res.status === 511) {
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop_CreateSim: false
                                    })
                                })
                    }

                    else {
                        console.log('failure')
                        console.log('Saving State')
                        //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                        console.log(res)
                        res.json()
                            .then(
                                (result) => {
                                    console.log(result)
                                    this.setState({ showBackdrop_CreateSim: false })
                                    var error_string = ""
                                    try {
                                        result.Messages.map(message => error_string += "\n" + message)
                                    }
                                    catch{
                                        console.log('No Error Message')
                                    }
                                    this.setState({
                                        errorAlert: true,
                                        errorTitle: "Simulation Error",
                                        errorText: 'There was an error submitting the simulation, please try again. The errors were as follows: ' + error_string
                                    })
                                },
                                (error) => {
                                    console.log(error)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                }
                            )
                    }

                })
        }

        else if (Constant.entrytype === 'createfromreport') {
            const sim_post_data = {
                sim_name: currentstate.simname,
                report_id: currentstate.reportid, //Need to Replace if null or with real reportval //null
                sim_type: promo_base,
                coverage: coverage,
                sim_start: currentstate.selectedstartdate, //selectedstartdate_adj
                sim_end: currentstate.selectedenddate,
                dataset_id: currentstate.DataSetChosen,
                geogid: [currentstate.Geochosen.value],
                sim_brands: sim_brands,
                items: simdata
            }

            //console.log(sim_post_data)
            //console.log(JSON.stringify(sim_post_data))
            //run fetch with post data                
            fetch(Constant.baseapiurl + "SimSettings",
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sim_post_data)
                })
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        console.log('ok')
                        res.json()
                            .then(
                                (result) => {
                                    console.log(result)
                                    console.log('Sim Created!')
                                    this.setState({ showBackdrop_CreateSim: true, showSnackbar_CreateSimSuccess: true })

                                    // save base prices to local storage
                                    basePricesToLocalStorage(result.sim_id, this.state.selecteditems, this.state.asps)
                                    
                                    window.location.href = Constant.baseurl + 'PNP/Simulation/' + result.sim_id
                                },
                                (error) => {
                                    console.log(error)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                }
                            )
                    }

                    else if (res.status === 511) {
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop_CreateSim: false
                                    })
                                })
                    }

                    else {
                        console.log('failure')                        
                        res.json()
                            .then(
                                (result) => {
                                    console.log(result)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                    var error_string = ""
                                    result.Messages.map(message => error_string += "\n" + message)
                                    this.setState({
                                        errorAlert: true,
                                        errorTitle: "Simulation Error",
                                        errorText: 'There was an error submitting the simulation, please try again. The errors were as follows: ' + error_string
                                    })
                                },
                                (error) => {
                                    console.log(error)
                                    console.log('Saving State')
                                    //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                }
                            )
                    }
                })
        }

        else //If Create Do this
            if (Constant.entrytype === 'copy') {
                const sim_post_data = {
                    sim_name: currentstate.simname,
                    report_id: null, //Need to Replace if null or with real reportval
                    sim_type: promo_base,
                    coverage: coverage,
                    sim_start: currentstate.selectedstartdate, //selectedstartdate_adj
                    sim_end: currentstate.selectedenddate,
                    dataset_id: currentstate.DataSetChosen,
                    geogid: [currentstate.Geochosen.value],
                    sim_brands: sim_brands,
                    items: simdata
                }

                //console.log(sim_post_data)
                //console.log(JSON.stringify(sim_post_data))
                //run fetch with post data
                //var sim_result_status = 0
                fetch(Constant.baseapiurl + "SimSettings",
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(sim_post_data)
                    })
                    .then(res => {
                        //Look at status code, and if not 200, throw error
                        if (res.status === 200) {
                            console.log('ok')
                            res.json()
                                .then(
                                    (result) => {
                                        console.log(result)
                                        console.log('Sim Created!')
                                        this.setState({ showBackdrop_CreateSim: true, showSnackbar_CreateSimSuccess: true })

                                        // save base prices to local storage
                                        basePricesToLocalStorage(result.sim_id, this.state.selecteditems, this.state.asps)
                                        
                                        window.location.href = Constant.baseurl + 'PNP/Simulation/' + result.sim_id
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )
                        }

                        else if (res.status === 511) {
                            res.json()
                                .then(
                                    (results) => {
                                        console.log(results);
                                        console.log('Saving State')
                                        //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                        this.setState({
                                            displayReloadDialog: true
                                            , reloadDialog: results["Reason"]
                                            , showBackdrop_CreateSim: false
                                        })
                                    })
                        }

                        else {
                            console.log('failure')
                            res.json()
                                .then(
                                    (result) => {
                                        console.log(result)
                                        console.log('Saving State')
                                        //localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                        var error_string = ""
                                        result.Messages.map(message => error_string += "\n" + message)
                                        this.setState({
                                            errorAlert: true,
                                            errorTitle: "Simulation Error",
                                            errorText: 'There was an error submitting the simulation, please try again. The errors were as follows: ' + error_string
                                        })
                                    },
                                    (error) => {
                                        console.log(error)
                                        console.log('Saving State')
                                        ////localStorage.setItem('SimEntry_state', JSON.stringify(this.state));
                                        this.setState({
                                            errorAlert: true,
                                            errorTitle: "Simulation Error",
                                            errorText: 'There was an error submitting the simulation, please try again.'
                                        })
                                    }
                                )
                        }
                    })
            }
    }

    componentDidMount() {
        //Different mounting behavior depending on where we are in the app
        //console.log(Constant.entrytype)
        this.addBackdrop()

        //Check if state is in memory...if so, load that instead of starting from scratch...
        let loadedstate = JSON.parse(localStorage.getItem('SimEntry_state'))
        //console.log(loadedstate)
        
        this.LoadData(Constant.entrytype)
        
        if (loadedstate) {
            /*
            //For Each key, set the state...
            console.log(Object.keys(loadedstate))
            let keys = Object.keys(loadedstate)
            let values = Object.values(loadedstate)

            for (var i = 0; i < Object.keys(loadedstate).length; i++) {
                let keytoset = keys[i]
                let valuetoset = values[i]
                console.log(keytoset)
                console.log(valuetoset)
                //this.setState({ simname: loadedstate.simname }) //Works...
                if (keytoset === 'skipped') {
                    this.setState({ [keytoset]: new Set() })
                } 

                else if (keytoset === 'activeStep') {
                    this.setState({ [keytoset]: 0 })
                }

                else {
                    this.setState({ [keytoset]: valuetoset })
                }

                
                
            }
            this.removeBackdrop()
            this.setState({'loadedfromstate':true})
            */
            
        }
        else {
            //this.LoadData(Constant.entrytype)
        }
        
        //this.removeBackdrop()
        
    }
    render() {
        this.setState()
        //console.log(this.state)
        const searchFunction = (event) => {
            console.log(event.target.value);
            var input, filter, mybranditems, mybranddivs, mybrandli, mybrandi, mybrandtxtValue, compitems, compdivs, compli, compi, comptxtValue, otheritems, otherdivs, otherli, otheri, othertxtValue;
            input = event.target.value
            filter = input.toUpperCase();
            //My Brands
            mybranditems = document.getElementById("myBrandItemsList");
            console.log('mybranditems ', mybranditems)
            mybranddivs = mybranditems.getElementsByClassName("MuiListItem-root");
            console.log('mybranddivs ', mybranddivs)

            for (mybrandi = 0; mybrandi < mybranddivs.length; mybrandi++) {
                mybrandli = mybranddivs[mybrandi].getElementsByClassName("MuiListItemText-root")[0];
                mybrandtxtValue = mybrandli.textContent || mybrandli.innerText;
                if (mybrandtxtValue.toUpperCase().indexOf(filter) > -1) {
                    mybranddivs[mybrandi].style.display = "";
                }
                else if (mybrandtxtValue === 'Select All') {
                    mybranddivs[mybrandi].style.display = "";
                }
                else {
                    mybranddivs[mybrandi].style.display = "none";
                }
            }
            //Competitors
            compitems = document.getElementById("compItemsList");
            compdivs = compitems.getElementsByClassName("MuiListItem-root");
            for (compi = 0; compi < compdivs.length; compi++) {
                compli = compdivs[compi].getElementsByClassName("MuiListItemText-root")[0];
                comptxtValue = compli.textContent || compli.innerText;
                if (comptxtValue.toUpperCase().indexOf(filter) > -1) {
                    compdivs[compi].style.display = "";
                }
                else if (comptxtValue === 'Select All') {
                    compdivs[compi].style.display = "";
                }
                else {
                    compdivs[compi].style.display = "none";
                }
            }
            //Other Items
            otheritems = document.getElementById("otherItemsList");
            otherdivs = otheritems.getElementsByClassName("MuiListItem-root");
            for (otheri = 0; otheri < otherdivs.length; otheri++) {
                otherli = otherdivs[otheri].getElementsByClassName("MuiListItemText-root")[0];
                othertxtValue = otherli.textContent || otherli.innerText;
                if (othertxtValue.toUpperCase().indexOf(filter) > -1) {
                    otherdivs[otheri].style.display = "";
                }
                else if (othertxtValue === 'Select All') {
                    otherdivs[otheri].style.display = "";
                }
                else {
                    otherdivs[otheri].style.display = "none";
                }
            }

        }

        const searchFunction2 = (event) => {
            console.log(event.target.value);
            var input, filter, tablerows, itemcells, i, txtValue;
            input = event.target.value
            filter = input.toUpperCase();
            tablerows = document.getElementsByClassName("tableRow");
            itemcells = document.getElementsByClassName("itemNameCell");
            console.log(tablerows)
            console.log(itemcells)
            for (i = 0; i < tablerows.length; i++) {
                txtValue = itemcells[i].title;
                console.log(txtValue)
                console.log(filter)
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tablerows[i].style.display = "";
                }
                else {
                    tablerows[i].style.display = "none";
                }
            }
        }

        const filterItemGroups = (selectedOptions) => {
            console.log(selectedOptions)            
            let selecteditems = this.state.selecteditems
            let tablerows = document.getElementsByClassName("tableRow");

            var validitems = []
            if (selectedOptions && selectedOptions.length > 0) {
                //Push group items into the list of valid items
                selectedOptions.forEach(option => {
                    option.value.forEach(item => {
                        validitems.push(String(item))
                    })
                })
                //Loop through selecteditems and if item in the list show it as valid.               
                selecteditems.forEach((item, idx) => {
                    if (validitems.includes(item)) {
                        tablerows[idx].style.display = "";
                    }
                    else {
                        tablerows[idx].style.display = "none";
                    }
                })                
            }

            else {
                selecteditems.forEach((item, idx) => {
                    tablerows[idx].style.display = "";
                })               
            }
        }

        const showHideFilters = () => {
            var filterState = this.state.displayFilters;
            console.log(filterState)
            if (filterState === 'none') {
                this.setState({ displayFilters: 'flex' })
                console.log('set to block-inline')
            }
            else if (filterState === 'flex') {
                this.setState({ displayFilters: 'none' })
                console.log('set to none')
            }
        }

        const handleCloseRemoveAllItemsErrorDialog = () => {
            this.setState({ openRemoveAllItemsErrorDialog: false })
        }

        const handleToggle_checkmarks = (type, idx) => async () => {            
            console.log('handling checkmarks')
            var items_lookup_object = this.state.items_lookup_object
            var alreadyselected = this.state.selecteditems //.map(item=>parseInt(item))
            //If my brands is selected, need to reset the competitors list.
            if (type === 'mybrands') {
                var listtochange = this.state.mybranditems_checked
                var allcheckedvar = this.state.mybranditems_allchecked

                //If idx = -1, this is select all case
                if (idx === -1) {
                    var valtoset = !allcheckedvar
                    //console.log(valtoset)
                    //set all values...but consider the filters...
                    let mybranditems = document.getElementById("myBrandItemsList");
                    let mybranddivs = mybranditems.getElementsByClassName("MuiListItem-root");
                    //console.log(mybranditems)
                    //console.log(mybranddivs)
                    var mybrandi
                    var newvals = []
                    for (mybrandi = 0; mybrandi < mybranddivs.length; mybrandi++) {
                        //console.log(mybrandi)
                        //console.log(mybranddivs[mybrandi])         
                        //console.log(mybranddivs[mybrandi].textContent)
                        //console.log(mybranddivs[mybrandi].style.display)
                        if (mybranddivs[mybrandi].textContent !== 'Select All') {
                            if (mybranddivs[mybrandi].style.display === "") {
                                newvals.push(valtoset)
                            }
                            else {
                                newvals.push(false) //listtochange[mybrandi])
                            }
                        }
                        
                        //console.log(newvals)
                    }
                    //newvals = listtochange.map(val => valtoset)
                }

                else {
                    var valtoset = allcheckedvar
                    var valtochange = listtochange[idx]
                    var newvals = listtochange
                    newvals[idx] = !valtochange
                }

                //get all the selecteditems....
                let selecteditems = []
                newvals.map((item, idx) => {
                    if (item) {
                        selecteditems.push(this.state.mybranditems[idx])
                        console.log('selected items pushing into mybranditems',this.state.mybranditems[idx])
                    }
                })

                if (selecteditems.length > 0) {
                    const comp_post_data = {
                        itemids: selecteditems.map(item=>parseInt(item)),
                        dataset_id: this.state.DataSetChosen, //Need to replace with
                        geogid: [this.state.Geochosen.value],
                    }

                    console.log('comp_post_data', comp_post_data)
                    //console.log('Running Get Comps...')
                    const comptetitorsres = await fetch(Constant.baseapiurl + "Simulation/get_competitors",
                        {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(comp_post_data)
                        })
                    if (comptetitorsres.status === 200) {
                        var competitorsdata = await comptetitorsres.json()
                        console.log('competitorsdata', competitorsdata)
                    }
                    else if (comptetitorsres.status === 511) {
                        var results = await comptetitorsres.json()
                        this.setState({
                            displayReloadDialog: true
                            , reloadDialog: results["Reason"]
                            , showBackdrop_CreateSim: false
                        })
                    }
                    else {
                        console.log('There was an Error Running the API')
                        //Show an error to the user...
                        this.setState({
                            displayReloadDialog: true
                            , reloadDialog: "There was an error loading data.  Please reload this page."
                            , showBackdrop_CreateSim: false
                        })
                    }

                    //console.log(competitorsdata)
                    //console.log(competitorsdata['comp_prodid'])
                    
                    try {
                        var compitems_fromapi = []
                        competitorsdata.map(row =>
                            row['comp_prodid'].map(item => {
                                if (!compitems_fromapi.includes(item)) {
                                    compitems_fromapi.push(item)
                                }
                            })
                        )
                        var compitems = []
                        var internalcompitems = []
                        var compitems_checked = []
                        var ishighlighted = []

                        console.log('already selected', alreadyselected)
                        //Check if the competitive item has sales
                        Object.keys(items_lookup_object).map(item => {
                            //console.log(item)
                            if (items_lookup_object[item]['hassales'] === true && compitems_fromapi.includes(parseInt(item))) {
                                //If the item is in my brands, track as internal competitor
                                if (this.state.mybranditems.includes(item)) {
                                    internalcompitems.push(item)
                                    //internalcompitems.push({item: item, color: 'purple'}) /// need to change the color of these
                                    //compitems.push(item)
                                }
                                
                                else {
                                   
                                  //  if (!this.state.selecteditems.includes(item)) {
                                        compitems.push(item)
                                        if (alreadyselected.includes(item)) {
                                            compitems_checked.push(true)
                                        }
                                        else {
                                            compitems_checked.push(false)
                                        }
                                    }
                               // }

                            }
                            //let ishighlighted = []
                            //if (this.state.mybranditems.includes(item) && this.state.internalcompitems(item)) {
                            //    ishighlighted.push(true)
                            //}
                            //this.setState({ ishighlighted })
                        })
                    }

                    catch (error) {
                        var compitems = []
                        var compitems_checked = []
                        var internalcompitems = []
                    }
                    
                }

                else {
                    var compitems = []
                    var compitems_checked = []
                    var internalcompitems = []

                }
                

                console.log(this.state.mybranditems)
                console.log(compitems)
                console.log(internalcompitems)
                console.log(alreadyselected)
                //Move remaining

                var otheritems = []
                var otheritems_checked = []

                Object.keys(items_lookup_object).map(item => {
                    //console.log(item)
                    if (items_lookup_object[item]['hassales'] === true && !this.state.mybranditems.includes(item) && !compitems.includes(item)) {
                        otheritems.push(item)
                        if (alreadyselected.includes(item)) {
                            otheritems_checked.push(true)
                        }
                        else {
                            otheritems_checked.push(false)
                        }
                    }
                })

                compitems_checked.map((item, idx) => {
                    if (item) {
                        selecteditems.push(compitems[idx])
                    }
                })

                otheritems_checked.map((item, idx) => {
                    if (item) {
                        selecteditems.push(otheritems[idx])
                    }
                })


                let Itemschosen = selecteditems.map(item => { return { 'label': items_lookup_object[item]['proddesc'], 'value': item }})

                this.setState({
                    mybranditems_checked: newvals,
                    mybranditems_allchecked: valtoset,
                    compitems,
                    compitems_checked,
                    otheritems,
                    otheritems_checked,
                    selecteditems,
                    Itemschosen
                })

                let compitemsid = document.getElementById("compItemsList");
                let compdivs = compitemsid.getElementsByClassName("MuiListItem-root");
                let otheritemsid = document.getElementById("otherItemsList");
                let otherdivs = otheritemsid.getElementsByClassName("MuiListItem-root");                
                var compi, compli, comptxtValue, otherli, otheri, othertxtValue

                //Apply styling so competitors do not show when they shouldn't


                //First Clear Formatting...then reapply...
                for (otheri = 0; otheri < otherdivs.length; otheri++) {
                    otherdivs[otheri].style.display = "";
                }

                for (compi = 0; compi < compdivs.length; compi++) {
                    compdivs[compi].style.display = "";
                }


                //Only if filters are applied....
                var usefilters = false
                if ((this.state.attribute_selections || []).length !== 0 || sliderRange[0] !== this.state.sliderRangeMin || sliderRange[1] !== this.state.sliderRangeMax || (this.state.pricebands_sel || []).length !== 0) {
                    usefilters = true
                }

                console.log(usefilters)

                if (usefilters) {
                    console.log('Clearing Filters!')
                    for (compi = 0; compi < compdivs.length; compi++) {
                        compli = compdivs[compi].getElementsByClassName("MuiListItemText-root")[0];
                        comptxtValue = compli.textContent || compli.innerText;
                        if (this.state.matching_items_proddesc.includes(comptxtValue) || comptxtValue === 'Select All') { //|| this.state.matching_items_proddesc.length === 0) {
                            compdivs[compi].style.display = "";
                        }
                        else {
                            compdivs[compi].style.display = "none";
                        }
                    }

                    

                    //pause for a second


                    setTimeout(function () {
                        //Apply styling so otheritems do not show when they shouldn't
                        //console.log(this.state.matching_items_proddesc)
                        for (otheri = 0; otheri < otherdivs.length; otheri++) {
                            otherli = otherdivs[otheri].getElementsByClassName("MuiListItemText-root")[0];
                            othertxtValue = otherli.textContent || otherli.innerText;
                            //console.log(othertxtValue)
                            if (this.state.matching_items_proddesc.includes(othertxtValue) || othertxtValue === 'Select All') { // || this.state.matching_items_proddesc.length === 0) {
                                //console.log('Setting to Display')
                                otherdivs[otheri].style.display = "";
                            }
                            else {
                                //console.log('Setting to None')
                                otherdivs[otheri].style.display = "none";
                            }
                        }

                        let otherdivs_after = otheritemsid.getElementsByClassName("MuiListItem-root");
                        //console.log(otherdivs_after)
                    }.bind(this), 1000);
                }
                
                
                
            }

            else if (type === 'comp') {
                var listtochange = this.state.compitems_checked
                var allcheckedvar = this.state.compitems_allchecked

                //If idx = -1, this is select all case
                if (idx === -1) {
                    var valtoset = !allcheckedvar
                    //set all values
                    //Change this...
                    //newvals = listtochange.map(val => valtoset)
                    let compitems = document.getElementById("compItemsList");
                    let compdivs = compitems.getElementsByClassName("MuiListItem-root");
                    //console.log(compdivs)
                    //console.log(listtochange)
                    var compi
                    var newvals = []
                    for (compi = 0; compi < compdivs.length; compi++) {
                        if (compdivs[compi].textContent !== 'Select All') {
                            if (compdivs[compi].style.display === "") {
                                newvals.push(valtoset)
                            }
                            else {
                                newvals.push(false) //listtochange[compi])
                            }
                        }
                    }
                }

                else {
                    var valtoset = allcheckedvar
                    var valtochange = listtochange[idx]
                    var newvals = listtochange
                    newvals[idx] = !valtochange
                }

                //get all the selecteditems....
                let selecteditems = []
                this.state.mybranditems_checked.map((item, idx) => {
                    if (item) {
                        selecteditems.push(this.state.mybranditems[idx])
                    }
                })

                newvals.map((item, idx) => {
                    if (item) {
                        selecteditems.push(this.state.compitems[idx])
                    }
                })

                this.state.otheritems_checked.map((item, idx) => {
                    if (item) {
                        selecteditems.push(this.state.otheritems[idx])
                    }
                })

                let Itemschosen = selecteditems.map(item => { return { 'label': items_lookup_object[item]['proddesc'], 'value': item } })

                this.setState({
                    compitems_checked: newvals,
                    compitems_allchecked: valtoset,
                    selecteditems,
                    Itemschosen
                })

            }

            else if (type === 'other') {
                var listtochange = this.state.otheritems_checked
                var allcheckedvar = this.state.otheritems_allchecked

                //If idx = -1, this is select all case
                if (idx === -1) {
                    var valtoset = !allcheckedvar
                    //set all values
                    //Change this...
                    //newvals = listtochange.map(val => valtoset)
                    let otheritems = document.getElementById("otherItemsList");
                    let otherdivs = otheritems.getElementsByClassName("MuiListItem-root");
                    var otheri
                    var newvals = []
                    for (otheri = 0; otheri < otherdivs.length; otheri++) {
                        if (otherdivs[otheri].textContent !== 'Select All') {
                            if (otherdivs[otheri].style.display === "") {
                                newvals.push(valtoset)
                            }
                            else {
                                newvals.push(false) //listtochange[otheri])
                            }
                        }
                    }
                }

                else {
                    var valtoset = allcheckedvar
                    var valtochange = listtochange[idx]
                    var newvals = listtochange
                    newvals[idx] = !valtochange
                }

                //get all the selecteditems....
                let selecteditems = []
                this.state.mybranditems_checked.map((item, idx) => {
                    if (item) {
                        selecteditems.push(this.state.mybranditems[idx])
                    }
                })

                this.state.compitems_checked.map((item, idx) => {
                    if (item) {
                        selecteditems.push(this.state.compitems[idx])
                    }
                })

                newvals.map((item, idx) => {
                    if (item) {
                        selecteditems.push(this.state.otheritems[idx])
                    }
                })

                let Itemschosen = selecteditems.map(item => { return { 'label': items_lookup_object[item]['proddesc'], 'value': item } })


                this.setState({
                    otheritems_checked: newvals,
                    otheritems_allchecked: valtoset,
                    selecteditems,
                    Itemschosen
                })
            }
        }

        const handleNext = () => {
            let prevActiveStep = this.state.activeStep;
           
            //this.setState({ activeStep: prevActiveStep + 1 })
            //Perform Error Checks

            var num_errors = 0;
            //Error Checking at each step
            if (this.state.activeStep === 0) {
                //var num_errors = 0;
                //Check for Name, Dataset, Geography, and Start and End Date
                //Report Name
                if ((this.state.simname || "") === "") {
                    num_errors += 1
                    this.setState({ sim_name_missing: true })
                    //console.log('No Sim Name')
                }
                else {
                    this.setState({ sim_name_missing: false })
                }
                //Start Date Missing or Wrong Format
                if (this.state.selectedstartdate) {
                    this.setState({ start_date_missing: false })
                }
                else {
                    num_errors += 1
                    this.setState({ start_date_missing: true })
                    //console.log('No Start Date')
                }
                //End Date Missing or Wrong Format
                if (this.state.selectedenddate) {
                    this.setState({ end_date_missing: false })
                }
                else {
                    num_errors += 1
                    this.setState({ end_date_missing: true })
                    //console.log('No End Date')
                }
                //Missing Geos
                if (this.state.Geochosen.length === 0) {
                    num_errors += 1
                    this.setState({ geos_missing: true })
                    //console.log('No Geos')
                }
                else {
                    this.setState({ geos_missing: false })
                }
                //Missing SimType
                if (isNull(this.state.simtype)) {
                    num_errors += 1
                    this.setState({ sim_base_missing: true })
                }
                else {
                    this.setState({ sim_base_missing: false })
                }
                //Missing SimType
                if (this.state.simtype.length === 0) {
                    num_errors += 1
                    this.setState({ sim_base_missing: true })
                }
                else {
                    this.setState({ sim_base_missing: false })
                }


                //Coverage >0
                if (this.state.coverage <= 0) {
                    num_errors += 1
                    this.setState({ coverage_lte_zero: true })
                }
                else {
                    this.setState({ coverage_lte_zero: false })
                }

                //Start GT End
                if (this.state.selectedstartdate && this.state.selectedenddate && this.state.selectedstartdate > this.state.selectedenddate) {
                    num_errors += 1
                    this.setState({ start_gt_end: true })
                    //console.log('Start GT End')
                }
                else {
                    this.setState({ start_gt_end: false })
                }


                //If No Errors
                //Build Lookups and Set Items in Proper Places
                //Set Display Based on Simulation set
                if (num_errors === 0) {
                    //console.log('No Errors...Proceed')
                    //Get Items
                    this.getItemInfo(this.state.DataSetChosen, this.state.Geochosen.value, this.state.selectedstartdate, this.state.selectedenddate)
                    this.SetDisplayTypes(this.state.simtype, this.state.DataSetChosen)

                }
                else {
                    //console.log('Errors...can not proceed')
                }

            }

            else if (this.state.activeStep === 1) {
                //Check that at least one item is selected...
                if (this.state.selecteditems.length === 0) {
                    num_errors += 1
                    this.setState({ items_missing: true })
                    //console.log('No Items')
                }
                else {
                    //Populate Rows for Simulation Entry...
                    var bulkedit = []
                    var rowSelectedList = []
                    var comp_type = []
                    var comp_title = []
                    var item_comp_type_dict = {}
                    var item_comp_title_dict = {}

                    var vols = []
                    var asps = []
                    var pcts = []

                    var hist_vols = []
                    var hist_asps = []
                    var hist_pcts = []

                    var newbases = []
                    var newbases_chg = []
                    var newpcts = []
                    var newpcts_chg = []
                    var input_errors_base = []
                    var input_errors_promo = []

                    var competitors = []

                    var override_base_flags = []

                    var volume_revert_button_color = []
                    var volume_font_weight = []
                    var volume_table_cell_class = []

                    var ASP_revert_button_color = []
                    var ASP_font_weight = []
                    var ASP_table_cell_class = []

                    var PCT_revert_button_color = []
                    var PCT_font_weight = []
                    var PCT_table_cell_class = []

                    var add_effects_enabled = []
                    var promo_value = []
                    var ad_value = []
                    var promo_value_tracking = []
                    var ad_value_tracking = []

                    var selected_options_ads = []
                    var selected_options_promos = []

                    var financials = []
                    var input_errors_financials = []

                    const localStorageBasePrices = basePricesFromLocalStorage(this.state.simid, this.state.selecteditems)
                    //console.log(this.state)
                    this.state.selecteditems.map((item, idx) => {


                        hist_vols.push(Math.round((this.state.items_lookup_object[item]['basevolume'] + this.state.items_lookup_object[item]['covidvolume'] + this.state.items_lookup_object[item]['holidayvolume']) * this.state.items_lookup_object[item]['equiv']))
                        hist_asps.push(this.state.items_lookup_object[item]['avgbp'].toFixed(2))
                        hist_pcts.push(Math.round(this.state.items_lookup_object[item]['avg_discount'] * 100))

                        console.log(item)
                        //Competitor Type and Label
                        if (this.state.mybranditems.includes(item) && !this.state.internalcompitems.includes(item)) {
                            item_comp_type_dict[item] = 'mybrand'
                            comp_type[idx] = 'mybrand'
                            item_comp_title_dict[item] = 'An item in my brand(s)'
                            comp_title[idx] = 'An item in my brand(s)'
                            competitors.push(false)
                        }

                        else if (this.state.internalcompitems.includes(item)) {
                            item_comp_type_dict[item] = 'internal'
                            comp_type[idx] = 'internal'
                            item_comp_title_dict[item] = 'An item in my brand(s) that competes with another item in my brand(s)'
                            comp_title[idx] = 'An item in my brand(s) that competes with another item in my brand(s)'
                            competitors.push(false)
                        }

                        else if (this.state.compitems.includes(item)) {
                            item_comp_type_dict[item] = 'external_with'
                            comp_type[idx] = 'external_with'
                            item_comp_title_dict[item] = 'Competitive effects found within my brand(s)'
                            comp_title[idx] = 'Competitive effects found within my brand(s)'
                            competitors.push(true)
                        }

                        else {
                            item_comp_type_dict[item] = 'external_without'
                            comp_type[idx] = 'external_without'
                            item_comp_title_dict[item] = 'No Competitive effects found within my brand(s)'
                            comp_title[idx] = 'No Competitive effects found within my brand(s)'
                            competitors.push(false)
                        }
                        //set bulkeditflag = false
                        bulkedit.push(false)
                        input_errors_base.push(false)
                        input_errors_promo.push(false)

                        if (idx === 0) {
                            rowSelectedList.push(true)
                        }
                        else {
                            rowSelectedList.push(false)
                        }
                        if (this.state.itemsfromsim.includes(parseInt(item))) {

                            //console.log(this.state.sim_item_info_lookup[item])
                            if (this.state.sim_item_info_lookup[item]['override_base_flag']) {
                                //console.log('Using OverRide Information')
                                //Formatting
                                override_base_flags.push(true)
                                volume_revert_button_color.push("#4e106f")
                                volume_font_weight.push("bold") //Change to normal
                                volume_table_cell_class.push("change_data_flash")
                                PCT_revert_button_color.push("#4e106f") //Change to NPD MidBlue
                                PCT_font_weight.push("bold") //Change to bold
                                PCT_table_cell_class.push("change_data_flash")
                                ASP_revert_button_color[idx] = "#4e106f" //Change to NPD Light Gray
                                ASP_font_weight[idx] = "bold" //Change to normal
                                ASP_table_cell_class[idx] = "change_data_flash"
                                //Values
                                try {
                                    vols.push(Math.round(this.state.sim_item_info_lookup[item]['override_base_volume']))
                                }
                                catch (error) {
                                    //console.log(error)
                                    vols.push(Math.round((this.state.items_lookup_object[item]['basevolume'] + this.state.items_lookup_object[item]['covidvolume'] + this.state.items_lookup_object[item]['holidayvolume']) * this.state.items_lookup_object[item]['equiv']))
                                }
                                
                                // check if there is local storage base price
                                if (localStorageBasePrices[idx] != null) {
                                    asps.push(parseFloat(localStorageBasePrices[idx]).toFixed(2))
                                } else {
                                    try {
                                        asps.push(this.state.sim_item_info_lookup[item]['override_base_price'].toFixed(2))
                                    }
                                    catch (error) {
                                        //console.log(error)
                                        asps.push(this.state.items_lookup_object[item]['avgbp'].toFixed(2))
                                    }
                                }

                                try {
                                    pcts.push(Math.round(this.state.sim_item_info_lookup[item]['override_base_discount'] * 100))
                                }
                                catch (error) {
                                    //console.log(error)
                                    pcts.push(Math.round(this.state.items_lookup_object[item]['avg_discount'] * 100))
                                }
                            }
                            else {
                                //console.log('Populate Historical information from lookup')
                                //Formatting
                                override_base_flags.push(false)
                                volume_revert_button_color.push("#8E908F")
                                volume_font_weight.push("normal") //Change to normal
                                volume_table_cell_class.push("change_data_flash")
                                PCT_revert_button_color.push("#8E908F") //Change to NPD MidBlue
                                PCT_font_weight.push("normal") //Change to bold
                                PCT_table_cell_class.push("change_data_flash")
                                ASP_revert_button_color[idx] = "#8E908F" //Change to NPD Light Gray
                                ASP_font_weight[idx] = "normal" //Change to normal
                                ASP_table_cell_class[idx] = "change_data_flash"
                                //Values
                                vols.push(Math.round((this.state.items_lookup_object[item]['basevolume'] + this.state.items_lookup_object[item]['covidvolume'] + this.state.items_lookup_object[item]['holidayvolume']) * this.state.items_lookup_object[item]['equiv']))
                                
                                // check for local storage base prices
                                if (localStorageBasePrices[idx] != null) {
                                    asps.push(parseFloat(localStorageBasePrices[idx]).toFixed(2))
                                } else {
                                    asps.push(this.state.items_lookup_object[item]['avgbp'].toFixed(2))
                                }

                                pcts.push(Math.round(this.state.items_lookup_object[item]['avg_discount'] * 100))
                            }
                            //console.log('Pull Form information from previously entered information')

                            if (this.state.simtype === 'base') {

                                if (this.state.sim_item_info_lookup[item]['sim_base_price']) {
                                    newbases.push(this.state.sim_item_info_lookup[item]['sim_base_price'])
                                    let change = Math.round(((this.state.sim_item_info_lookup[item]['sim_base_price'] - asps[idx]) / asps[idx]) * 100)
                                    newbases_chg.push(change)
                                }
                                else {
                                    newbases.push("")
                                    newbases_chg.push("")
                                }

                                newpcts.push("")
                                newpcts_chg.push("")
                            }

                            else if (this.state.simtype === 'promo') {
                                newbases.push("")
                                newbases_chg.push("")
                                if (this.state.sim_item_info_lookup[item]['sim_discount_pct']) {
                                    newpcts.push(Math.round(this.state.sim_item_info_lookup[item]['sim_discount_pct'] * 10000) / 100.)
                                    let change = Math.round((asps[idx] - (asps[idx] * (this.state.sim_item_info_lookup[item]['sim_discount_pct']))) * 100) / 100.
                                    newpcts_chg.push(change)
                                }
                                else {
                                    newpcts.push("")
                                    newpcts_chg.push("")
                                }
                            }

                            financials.push([
                                this.state.sim_item_info_lookup[item]['cost_per_unit']
                                , this.state.sim_item_info_lookup[item]['list_price']
                                , this.state.sim_item_info_lookup[item]['gross_margin']
                                , this.state.sim_item_info_lookup[item]['retail_margin']
                            ])
                            input_errors_financials.push([false, false, false, false])

                            //Perform the reverse mapping...so we can set the value for the selected option....
                            var datasetname = this.state.DataSetChosen_option[0].label
                            if (Constant.promo_add_one_only.includes(datasetname)) {
                                //Motor Oil Datasets
                                var motoroildatasets = ['Passenger Car Motor Oil', 'Heavy Duty Motor Oil']
                                if (motoroildatasets.includes(datasetname)) {
                                    //console.log(item)
                                    //console.log(this.state.sim_item_info_lookup[item]['promo_type'])
                                    //console.log(this.state.sim_item_info_lookup[item]['ad_type'])
                                    //Look at the Promo Type...If populated, put the value in promo_value, track type A, and set the option to default...
                                    if (!this.state.sim_item_info_lookup[item]['promo_type']) {
                                        selected_options_promos.push([Constant.promoeffect_options[datasetname][0]])
                                        promo_value.push(null)
                                        promo_value_tracking.push(null)
                                    }
                                    else if (this.state.sim_item_info_lookup[item]['promo_type'] === 'None') {
                                        selected_options_promos.push([Constant.promoeffect_options[datasetname][0]])
                                        promo_value.push(null)
                                        promo_value_tracking.push(null)
                                    }
                                    else {
                                        selected_options_promos.push([Constant.promoeffect_options[datasetname][1]])
                                        promo_value.push(this.state.sim_item_info_lookup[item]['promo_type'])
                                        promo_value_tracking.push(Constant.promoeffect_options[datasetname][1].value)
                                    }
                                    //If No Ad Type, Need to See if Promo is populated
                                    if (!this.state.sim_item_info_lookup[item]['ad_type'] || this.state.sim_item_info_lookup[item]['ad_type'] === 'None') {
                                        //If No Promo, set Ad to No
                                        if (!this.state.sim_item_info_lookup[item]['promo_type'] || this.state.sim_item_info_lookup[item]['promo_type'] === 'None') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][0]])
                                            ad_value.push(null)
                                            ad_value_tracking.push(null)
                                        }
                                        //If PromoTypeA set to no Add
                                        else if (this.state.sim_item_info_lookup[item]['promo_type'] === 'PromoTypeA') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][0]])
                                            ad_value.push(null)
                                            ad_value_tracking.push(null)
                                        }
                                        //If PromoTypeB set to no ad, but track ad type A
                                        else if (this.state.sim_item_info_lookup[item]['promo_type'] === 'PromoTypeB') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][1]])
                                            ad_value.push(null)
                                            ad_value_tracking.push(Constant.adeffect_options[datasetname][1].value)
                                        }

                                        //If PromoTypeC set to no ad, but track ad type B
                                        else if (this.state.sim_item_info_lookup[item]['promo_type'] === 'PromoTypeC') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][2]])
                                            ad_value.push(null)
                                            ad_value_tracking.push(Constant.adeffect_options[datasetname][2].value)
                                        }

                                        //If PromoTypeB set to no ad, but track ad type A
                                        else if (this.state.sim_item_info_lookup[item]['promo_type'] === 'PromoTypeD') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][3]])
                                            ad_value.push(null)
                                            ad_value_tracking.push(Constant.adeffect_options[datasetname][3].value)
                                        }
                                    }

                                    else {

                                        if (this.state.sim_item_info_lookup[item]['ad_type'] === 'None') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][0]])
                                            ad_value.push(null)
                                            ad_value_tracking.push(null)
                                        }

                                        else if (this.state.sim_item_info_lookup[item]['ad_type'] === 'AdTypeA') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][1]])
                                            ad_value.push(Constant.adeffect_options[datasetname][1].value)
                                            ad_value_tracking.push(Constant.adeffect_options[datasetname][1].value)
                                        }

                                        else if (this.state.sim_item_info_lookup[item]['ad_type'] === 'AdTypeB') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][2]])
                                            ad_value.push(Constant.adeffect_options[datasetname][2].value)
                                            ad_value_tracking.push(Constant.adeffect_options[datasetname][2].value)
                                        }

                                        else if (this.state.sim_item_info_lookup[item]['ad_type'] === 'AdTypeC') {
                                            selected_options_ads.push([Constant.adeffect_options[datasetname][3]])
                                            ad_value.push(Constant.adeffect_options[datasetname][3].value)
                                            ad_value_tracking.push(Constant.adeffect_options[datasetname][3].value)
                                        }
                                    }

                                }
                            }

                            else if (Object.keys(Constant.adeffect_options).includes(datasetname)) {
                                //Use Native Value...
                                promo_value.push(this.state.sim_item_info_lookup[item]['promo_type'])
                                promo_value_tracking.push(this.state.sim_item_info_lookup[item]['promo_type'])
                                ad_value.push(this.state.sim_item_info_lookup[item]['ad_type'])
                                ad_value_tracking.push(this.state.sim_item_info_lookup[item]['ad_type'])
                                //Add option to selected options
                                Constant.adeffect_options[datasetname].map(option => {
                                    if (option.value === this.state.sim_item_info_lookup[item]['ad_type']) {
                                        selected_options_ads.push([option])
                                    }
                                })

                                Constant.promoeffect_options[datasetname].map(option => {
                                    if (option.value === this.state.sim_item_info_lookup[item]['promo_type']) {
                                        selected_options_promos.push([option])
                                    }
                                })

                            }

                            else {
                                promo_value_tracking.push(null)
                                ad_value_tracking.push(null)
                                selected_options_promos.push([])
                                selected_options_ads.push([])
                            }

                        }
                        else {
                            //console.log('Populate Historical information from lookup')
                            vols.push(Math.round((this.state.items_lookup_object[item]['basevolume'] + this.state.items_lookup_object[item]['covidvolume'] + this.state.items_lookup_object[item]['holidayvolume']) * this.state.items_lookup_object[item]['equiv']))
                            
                            // check if base prices in localstorage
                            if (localStorageBasePrices[idx] != null) {
                                asps.push(parseFloat(localStorageBasePrices[idx]).toFixed(2))
                            } else {
                                asps.push(this.state.items_lookup_object[item]['avgbp'].toFixed(2))
                            }

                            pcts.push(Math.round(this.state.items_lookup_object[item]['avg_discount'] * 100))
                            add_effects_enabled.push(this.state.items_lookup_object[item]['adeffects'])
                            //console.log('Populate Nulls')
                            newbases.push("")
                            newbases_chg.push("")
                            newpcts.push("")
                            newpcts_chg.push("")
                            override_base_flags.push(false)
                            volume_revert_button_color.push("#8E908F") //Change to NPD Light Grey
                            volume_font_weight.push("normal") //Change to normal
                            volume_table_cell_class.push("change_data_flash")
                            PCT_revert_button_color.push("#8E908F") //Change to NPD MidBlue
                            PCT_font_weight.push("normal") //Change to bold
                            PCT_table_cell_class.push("change_data_flash")
                            ASP_revert_button_color[idx] = "#8E908F" //Change to NPD Light Gray
                            ASP_font_weight[idx] = "normal" //Change to normal
                            ASP_table_cell_class[idx] = "change_data_flash"
                            financials.push([null, null, null, null])
                            input_errors_financials.push([false, false, false, false])
                            promo_value.push(null)
                            ad_value.push(null)
                            promo_value_tracking.push(null)
                            ad_value_tracking.push(null)
                            selected_options_promos.push([])
                            selected_options_ads.push([])
                        }
                    })


                    this.setState({
                        bulkedit
                        , rowSelectedList
                        , rowSelected: 0
                        , comp_type
                        , comp_title
                        , vols
                        , asps
                        , pcts
                        , hist_vols
                        , hist_asps
                        , hist_pcts
                        , newbases
                        , newbases_chg
                        , newpcts
                        , newpcts_chg
                        , input_errors_base
                        , input_errors_promo
                        , volume_revert_button_color
                        , volume_font_weight
                        , volume_table_cell_class
                        , ASP_revert_button_color
                        , ASP_font_weight
                        , ASP_table_cell_class
                        , PCT_revert_button_color
                        , PCT_font_weight
                        , PCT_table_cell_class
                        , add_effects_enabled
                        , ad_value
                        , promo_value
                        , ad_value_tracking
                        , promo_value_tracking
                        , selected_options_promos
                        , selected_options_ads
                        , competitors
                        , override_base_flags
                        , financials
                        , input_errors_financials
                    })
                }
            }

            else if (this.state.activeStep === 2) {
                //console.log('Historical Pcts ', this.state.hist_pcts)
                var type = this.state.simtype
                var newpcts = this.state.newpcts
                var histpcts = this.state.hist_pcts
                var asps = this.state.asps
                var newbases = this.state.newbases
                var histbases = this.state.hist_asps

                //Check the number of errors...
                var num_errors = 0
                if (type === 'base') {
                    this.state.input_errors_base.map(error => {
                        if (error) {
                            num_errors += 1
                        }
                    })
                    if (num_errors === 0) {
                        this.setState({
                            item_error_display: 'none',
                            item_error_text: ''
                        })
                    }
                }
                else if (type === 'promo') {
                    this.state.input_errors_promo.map(error => {
                        if (error) {
                            num_errors += 1
                        }
                    })
                    if (num_errors === 0) {
                        this.setState({
                            item_error_display: 'none',
                            item_error_text: ''
                        })
                    }
                }

                if (num_errors === 0) {
                    if (type === 'base') {
                        this.state.selecteditems.map((item, idx) => {
                            if (newbases[idx] === undefined || newbases[idx] === null || newbases[idx] === '') {
                                //console.log('assuming previous base price')
                                //console.log(parseFloat(asps[idx]))
                                newbases[idx] = parseFloat(asps[idx]) + .00000001 //accounts for override being set
                                //newbases[idx] = histbases[idx] + .00000001
                            }
                        })

                        this.setState({ newbases })
                    }

                    else if (type === 'promo') {
                        this.state.selecteditems.map((item, idx) => {

                            if (newpcts[idx] === undefined || newpcts[idx] === null) {
                                console.log('assuming previous percent')
                                console.log(this.state.histpcts)
                                newpcts[idx] = parseFloat(histpcts[idx]) + .00000001
                               
                            }
                        })

                        this.setState({ newpcts })

                    }
                }

                else {
                    this.setState({
                        item_error_display: 'inline',
                        item_error_text: 'Please fix the highlighted cells'
                    })
                }


            }

            else if (this.state.activeStep === 3) {
                var validentries =0
                var financials = this.state.financials
                financials.map(financialrow => {
                    var numentries = 0
                    var numnulls = 0
                    financialrow.map(entry => {
                        if (entry) {
                            numentries += 1
                        }
                        else {
                            numnulls += 1
                        }
                    })
                    if (numnulls === 4 || numentries === 4) {
                        validentries += 1
                    }
                })
                if (validentries !== financials.length) {
                    num_errors = 1
                    this.setState({
                        financials_errors: true,
                        financials_errors_text: 'Please ensure all 4 cells have values'
                    })
                }

                else if (this.state.financials_errors) {
                    num_errors = 1
                    this.setState({
                        financials_errors: true,
                        financials_errors_text: 'Please fix highlighted cells'
                    })
                }
            }
        

            //console.log(num_errors);
            if (num_errors > 0) {
                this.setState({ showSnackbar_CreateSimError: true })
                //console.log(this.state)
            }
            else {
                //console.log('There are no Errors')
                this.setState({ activeStep: prevActiveStep + 1 })
                //this.setState({ skipped: newSkipped });
                //console.log(prevActiveStep)
                if (prevActiveStep === 0 && Constant.entrytype === 'createfromreport') {
                    this.setState({ openCreateFromReportItemsDialog: true })
                }
                //put in a 1 second delay with loader in between add items and sim input screens...
                if (prevActiveStep === 1) {
                    this.setState({ showBackdrop_CreateSim: true })
                    setTimeout(() => { this.setState({ showBackdrop_CreateSim: false }) }, 1000)
                }

                else if (prevActiveStep === 3) { //No Errors and In Last Step
                    //console.log('Running Simulation')
                    this.submitsim()
                }


            }

            
        }

        const handleOpenAllAttrsModal = () => {
            this.setState({ AllAttrsModalOpen: true })
            //console.log('Open All Attributes')
        }

        const handleCloseAllAttrsModal = () => {
            this.setState({ AllAttrsModalOpen: false })
        }

        const handleOpenItemGroupsModal = () => {
            this.setState({ ItemGroupsModalOpen: true })
            //console.log('Open All Attributes')
        }

        const handleCloseItemGroupsModal = () => {
            this.setState({ ItemGroupsModalOpen: false })
        }


        const handleToggle_itemgroups = (group) => () => {
            console.log(group)

            //Look to state object of itemgroups
            let itemgroups = this.state.itemgroups
            let selectedgroups = []
            let selectedgroupsforitems = []
            itemgroups.forEach(itemgroup => {
                if (group.groupname === itemgroup.groupname) {
                    let newchecked = !itemgroup.checked
                    itemgroup.checked = newchecked
                    if (newchecked === true) {
                        selectedgroups.push(itemgroup)
                        selectedgroupsforitems.push(itemgroup)
                    }                    
                }
                else if (itemgroup.checked === true) {
                    selectedgroups.push(itemgroup)
                    selectedgroupsforitems.push(itemgroup)
                }
            })

            this.setState({ selectedgroups, selectedgroupsforitems, itemgroups })

        }

        const addItemGroupstoSim = async () => {
            console.log('Adding Groups')
            let selectedgroupsforitems = this.state.selectedgroupsforitems || []
            let selecteditems_fromgroup = []
            selectedgroupsforitems.forEach(group => {
                group.item_vals.forEach(item => {
                    //confirm its in the item_lookuop_object and if so, add to the list of items
                    let valid_items = Object.keys(this.state.items_lookup_object)                    
                    if (valid_items.includes(String(item))) {
                        selecteditems_fromgroup.push(String(item))
                    }
                   // console.log(selecteditems_fromgroup, 'from Render')
                })
                this.setState({selecteditems_fromgroup})
            })

            let selecteditems = this.state.selecteditems
            let newselecteditems = Array.from(new Set(selecteditems.concat(selecteditems_fromgroup)))


            let Itemschosen = newselecteditems.map(item => { return { 'label': this.state.items_lookup_object[item]['proddesc'], 'value': item } })
            console.log(newselecteditems)
            //walk through the branditems, compitems, and otheritems and if in new selected items, mark as checked...

            let mybranditems_checked = this.state.mybranditems_checked
            let selecteditems_mybrand = []
            this.state.mybranditems.forEach((item,idx) => {
                if (newselecteditems.includes(item)) {
                    //look at mybranditems_checked
                    if (!mybranditems_checked[idx]) {
                        mybranditems_checked[idx] = true
                    }

                    selecteditems_mybrand.push(item)
                }
            })


            //Find the competitors for the selecteditems_mybrand.
            if (selecteditems_mybrand.length > 0) {
                const comp_post_data = {
                    itemids: selecteditems_mybrand.map(item => parseInt(item)),
                    dataset_id: this.state.DataSetChosen, //Need to replace with
                    geogid: [this.state.Geochosen.value],
                }
                console.log('Running Get Comps...')
                const comptetitorsres = await fetch(Constant.baseapiurl + "Simulation/get_competitors",
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(comp_post_data)
                    })
                if (comptetitorsres.status === 200) {
                    var competitorsdata = await comptetitorsres.json()
                }
                else if (comptetitorsres.status === 511) {
                    var results = await comptetitorsres.json()
                    this.setState({
                        displayReloadDialog: true
                        , reloadDialog: results["Reason"]
                        , showBackdrop_CreateSim: false
                    })
                }
                else {
                    console.log('There was an Error Running the API')
                    //Show an error to the user...
                    this.setState({
                        displayReloadDialog: true
                        , reloadDialog: "There was an error loading data.  Please reload this page."
                        , showBackdrop_CreateSim: false
                    })
                }

                try {
                    var compitems_fromapi = []
                    competitorsdata.map(row =>
                        row['comp_prodid'].map(item => {
                            if (!compitems_fromapi.includes(item)) {
                                compitems_fromapi.push(item)
                            }
                        })
                    )
                    var compitems = []
                    var internalcompitems = []
                    var compitems_checked = []

                    //console.log(alreadyselected)
                    //Check if the competitive item has sales
                    Object.keys(this.state.items_lookup_object).map(item => {
                        console.log(item)
                        if (this.state.items_lookup_object[item]['hassales'] === true && compitems_fromapi.includes(parseInt(item))) {
                            //If the item is in my brands, track as internal competitor
                            if (this.state.mybranditems.includes(item)) {
                                internalcompitems.push(item)
                            }
                            else {
                                compitems.push(item)
                                compitems_checked.push(false)
                            }

                        }
                    })
                }

                catch (error) {
                    var compitems = []
                    var compitems_checked = []
                    var internalcompitems = []
                }

                //let compitems_checked = this.state.compitems_checked
                compitems.forEach((item, idx) => {
                    if (newselecteditems.includes(item)) {
                        //look at compitems_checked
                        if (!compitems_checked[idx]) {
                            compitems_checked[idx] = true
                        }
                    }
                })

                //Move remaining

                var otheritems = []
                var otheritems_checked = []

                Object.keys(this.state.items_lookup_object).map(item => {
                    //console.log(item)
                    if (this.state.items_lookup_object[item]['hassales'] === true && !this.state.mybranditems.includes(item) && !compitems.includes(item)) {
                        otheritems.push(item)
                        if (newselecteditems.includes(item)) {
                            otheritems_checked.push(true)
                        }
                        else {
                            otheritems_checked.push(false)
                        }
                    }
                })

                //Set State
                this.setState({
                    selecteditems: newselecteditems,
                    mybranditems_checked,
                    compitems_checked,
                    otheritems_checked,
                    Itemschosen,
                    ItemGroupsModalOpen: false,
                    compitems,
                    otheritems,
                    selectedgroupsforitems: [],
                    selecteditems_fromgroup
                })
                //console.log(this.state.selecteditems_fromgroup)
            }
            

            else {

                let compitems_checked = this.state.compitems_checked                
                this.state.compitems.forEach((item, idx) => {
                    if (newselecteditems.includes(item)) {
                        //look at mybranditems_checked
                        if (!compitems_checked[idx]) {
                            compitems_checked[idx] = true
                        }                        
                    }
                })

                let otheritems_checked = this.state.otheritems_checked
                this.state.otheritems.forEach((item, idx) => {
                    if (newselecteditems.includes(item)) {
                        //look at mybranditems_checked
                        if (!otheritems_checked[idx]) {
                            otheritems_checked[idx] = true
                        }
                    }
                })


                this.setState({
                    selecteditems: newselecteditems,
                    mybranditems_checked,
                    compitems_checked,
                    otheritems_checked,
                    Itemschosen,
                    ItemGroupsModalOpen: false,
                    selectedgroupsforitems: []
                })

                

            }
            



            

            //after toggling, reshuffle...see handleToggle_checkmarks for example...


        }

        
        const handleOpenAllItemsModal = (group) => () =>{
            console.log(group)
            this.setState({ AllItemsModalOpen: true, groupformodal: group })            
        }

        const handleCloseAllItemsModal = () => {
            this.setState({ AllItemsModalOpen: false, groupformodal: null })
        }

        const handleBack = () => {
            let prevActiveStep = this.state.activeStep;
            this.setState({ activeStep: prevActiveStep - 1 })
        }

        const handleSkip = () => {
            if (!isStepOptional(this.state.activeStep)) {
                // You probably want to guard against something like this,
                // it should never occur unless someone's actively trying to break something.
                throw new Error("You can't skip a step that isn't optional.");
            }

            let prevActiveStep = this.state.activeStep;
            this.setState({ activeStep: prevActiveStep + 1 })
            let prevSkipped = this.state.skipped;
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(this.state.activeStep);
            this.setState({ skipped: newSkipped });
        }

        const handleReset = () => {
            this.setState({ activeStep: 0 })
        }

        const handleCloseReloadDialog = () => {
            this.setState({ displayReloadDialog: false })

            if (Constant.entrytype === 'create') {
                var wheretosendme = 'PNP/createsimulation'
            }

            else if (Constant.entrytype === 'edit') {
                var wheretosendme = 'PNP/EditSimulation/' + this.state.simid
            }

            else if (Constant.entrytype === 'copy') {
                var wheretosendme = 'PNP/copysimulation/' + this.state.simid
            }

            else if (Constant.entrytype === 'createfromreport') {
                var wheretosendme = 'PNP/createsimulationfromreport/' + this.state.reportid
            }

            window.location.href = Constant.baseurl + wheretosendme
            
        }

        

        const isStepOptional = step => {
            return step === 5;
        };
        
        const isStepSkipped = step => {
            return this.state.skipped.has(step);
        };
        
        const closeSimSuccessSnackBar = () => {
            this.setState({ showSnackbar_CreateSimSuccess: false })
        }

        const closeSimErrorSnackBar = () => {
            this.setState({ showSnackbar_CreateSimError: false })
        }

        const showInfoCompetitors = event => {
            //console.log('Opening popover')
            this.setState({ openInfoCompetitors: true })
            this.setState({ anchorElCompInfo: event.target })
        }

        const hideInfoCompetitors = () => {
            //console.log('Closing popover')
            this.setState({ openInfoCompetitors: false })
            this.setState({ anchorElCompInfo: null })
        }

        const showInfoMyBrandItems = event => {
            //console.log('Opening popover')
            this.setState({ openInfoMyBrandItems: true })
            this.setState({ anchorElMyBrandItemsInfo: event.target })
        }

        const hideInfoMyBrandItems = () => {
            //console.log('Closing popover')
            this.setState({ openInfoMyBrandItems: false })
            this.setState({ anchorElMyBrandItemsInfo: null })
        }

        const showInfoOtherItems = event => {
            //console.log('Opening popover')
            this.setState({ openInfoOtherItems: true })
            this.setState({ anchorElOtherItemsInfo: event.target })
        }

        const hideInfoOtherItems = () => {
            //console.log('Closing popover')
            this.setState({ openInfoOtherItems: false })
            this.setState({ anchorElOtherItemsInfo: null })
        }

        const showInfoProject = event => {
            //console.log('Opening popover')
            this.setState({ openInfoProject: true })
            this.setState({ anchorElProjectInfo: event.target })
        }

        const hideInfoProject = () => {
            //console.log('Closing popover')
            this.setState({ openInfoProject: false })
            this.setState({ anchorElProjectInfo: null })
        }

        const showInfoCoverage = event => {
            //console.log('Opening popover')
            this.setState({ openInfoCoverage: true })
            this.setState({ anchorElCoverageInfo: event.target })
        }

        const hideInfoCoverage = () => {
            //console.log('Closing popover')
            this.setState({ openInfoCoverage: false })
            this.setState({ anchorElCoverageInfo: null })
        }

        const showInfoHistData = event => {
            //console.log('Opening popover')
            this.setState({ openInfoHistData: true })
            this.setState({ anchorElHistData: event.target })
        }

        const hideInfoHistData = () => {
            //console.log('Closing popover')
            this.setState({ openInfoHistData: false })
            this.setState({ anchorElHistData: null })
        }

        const showInfoSimData = event => {
            //console.log('Opening popover')
            this.setState({ openInfoSimData: true })
            this.setState({ anchorElSimData: event.target })
        }

        const hideInfoSimData = () => {
            //console.log('Closing popover')
            this.setState({ openInfoSimData: false })
            this.setState({ anchorElSimData: null })
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
        }

        const GoToItemGroupDefinitions = () => {
            if (this.state.DataSetChosen === 0) {
                window.location.href = Constant.baseurl + 'PNP/ItemGroupDefinition'
            }
            else {
                window.location.href = Constant.baseurl + 'PNP/ItemGroupDefinition?datasetid=' + this.state.DataSetChosen
            }
        }

        const valid = (current) => {
            return current.day() === 6 && current.isSameOrAfter(this.state.futuredate) && current.isSameOrBefore(this.state.futuredateplustwo)
        };

        const valid_start = (current) => {
            return current.day() === 0 && current.isSameOrAfter(this.state.futuredate) && current.isSameOrBefore(this.state.futuredateplustwo)
        };

        try {
            if (this.state.selectedstartdate && this.state.selectedenddate) {
                var date1 = new Date(this.state.selectedstartdate);
                var date2 = new Date(this.state.selectedenddate);
                var Difference_In_Time = date2.getTime() - date1.getTime();
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
                var Difference_In_Weeks = Difference_In_Days / 7;
                if (Difference_In_Weeks) {
                    var displaynumweeks = 'inline'
                }
                else {
                    var displaynumweeks = 'none'
                }
            }
            else {
                var displaynumweeks = 'none'
            }

            //this.setState({ displaynumweeks: 'inline', Difference_In_Weeks })
        }
        catch (error) {
            var Difference_In_Weeks = 0
            var displaynumweeks = 'none'
            //this.setState({ displaynumweeks: 'none', Difference_In_Weeks })
        }

        const dropdownInput = {
            //width: '45rem',
            height: '50px',
            borderRadius: '5px',
            padding: '10px',
            fontFamily: 'Roboto',

        }

        const dateInput = {
            width: '300px',
            height: '50px',
            borderRadius: '5px',
            padding: '10px',
            fontFamily: 'Roboto',
        }

        const useStyles = makeStyles({
            root: {
                width: '100%',
                overflowX: 'auto',
            },
            table: {
                minWidth: 650,
            },
        });

        const classes = makeStyles(theme => ({
            root: {
                width: '100%',
            },
            button: {
                marginRight: theme.spacing(1),
            },
            instructions: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        }));

        const muiTheme = createMuiTheme({
            palette: {
                primary: {
                    main: '#4e106f',
                },
                secondary: {
                    main: '#ef2a79',
                },
                white: {
                    main: '#FFF'
                }
            }
        });

        //console.log(this.state.newpcts)
       // console.log(this.state.newpcts_chg)
        //console.log(this.state.asps)

        if (this.state.sliderRange) {
            //console.log(this.state.sliderRange)
            var sliderRange = this.state.sliderRange
            var sliderRangeMax = this.state.sliderRangeMax
            var sliderRangeMin = this.state.sliderRangeMin
            //console.log('Set Range to State Value')
        }
        else {
            var sliderRange = [0, 100]
            var sliderRangeMax = 100
            var sliderRangeMin = 0
            //console.log('Set Range to Default Value')
        }
        //console.log(sliderRange)
        var price_band_options = this.state.price_band_options

        return (

            <Layout title="Simulation Entry" >
                <Constant.NPDBackdrop
                    open={this.state.showBackdrop_CreateSim}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <Constant.NPDSnackbarSuccess
                    open={this.state.showSnackbar_CreateSimSuccess}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={closeSimSuccessSnackBar}
                >
                    <Constant.NPDSnackbarContentSuccess
                        message="Simulation Created Successfully"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeSimSuccessSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContentSuccess>
                </Constant.NPDSnackbarSuccess>

                <Constant.NPDSnackbarError
                    open={this.state.showSnackbar_CreateSimError}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={closeSimErrorSnackBar}
                >
                    <Constant.NPDSnackbarContentError
                        message="There are Errors in the form"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeSimErrorSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContentError>
                </Constant.NPDSnackbarError>

                <Dialog
                    open={this.state.displayReloadDialog}
                    onClose={handleCloseReloadDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Page Must Be Reloaded"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.reloadDialog}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseReloadDialog} className="btn btn-primary" autoFocus>
                            Reload
		                </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openRemoveAllItemsErrorDialog}
                    onClose={handleCloseRemoveAllItemsErrorDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"At Least One Item Needed"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            In order to create a simulation, you must have at least one item in the simulation.  If you wish to change the items, please use the preceding step to select new items.
		                </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseRemoveAllItemsErrorDialog} className="btn btn-primary" autoFocus>
                            Close
		                </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.errorTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.errorText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <div name='banner' className='banner' style={{
                }}>
                    <h1white align='center'>Simulation Entry</h1white><br/>
                    <h2white align='center'>Follow the steps to create your simulation.</h2white>
                </div>
                <div className="contentHolderGradient" style={{ width: '100%', position: 'absolute', left: '120px', top: '210px',  minWidth: '1200px', maxWidth: 'calc(100vw - 120px)', padding: '20px' }}>

                    <ThemeProvider theme={Constant.muiTheme}>
                        <Stepper activeStep={this.state.activeStep} style={{ marginLeft: '40px', marginRight: '40px' }}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = <Typography variant="caption">Optional</Typography>;
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </ThemeProvider>
                    {/*<div style={{ marginLeft: '40px', marginRight: '40px', marginTop:'20px', marginBotton: '20px' }}>*/}
                        
                    {/*    <span style={{*/}
                    {/*        color: '#ffffff',*/}
                    {/*        fontFamily: 'Roboto',*/}
                    {/*        cursor: 'pointer',*/}
                    {/*        //fontWeight: 'bold',                            */}
                    {/*        padding: '10px',*/}
                    {/*        backgroundColor: '#4e106f',*/}
                    {/*        borderRadius: '10px 10px 10px 10px',*/}
                    {/*        fontSize:'1.25rem'*/}
                    {/*    }}*/}
                    {/*        onClick={GoToItemGroupDefinitions}*/}
                    {/*        key="FiltersLink"*/}
                    {/*    >*/}
                    {/*        Define Item Groups*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <div>
                        {this.state.activeStep === steps.length ? (
                            <div style={{ marginLeft: '40px', marginRight: '40px' }}>
                                {/*<CircularProgress />*/}
                                <div class="custom-loader"></div>
                            </div>
                        ) : (
                                <div>
                                    {/*This is where the different divs will be added for the simulation entry steps*/}

                                    {this.state.activeStep === 0 ?
                                        <form className="reportentry" style={{ marginLeft: '40px' }}> 
                                        <div style={{ marginLeft: '40px', marginRight: '40px' }}>
                                            <div className="col-sm-6" style={{ padding: '10px', fontFamily: 'Roboto', }}>
                                                <h3 style={{ fontFamily: 'Roboto', color: '#4e106f', padding: '10px', }}>Create a new simulation</h3>
                                                <p style={{ fontFamily: 'Roboto', padding: '10px', }}>Define Criteria</p>
                                                <sup><span className='formfieldtitle'> * indicates required field</span></sup>
                                            </div>
                                            <div style={dropdownInput} className="col-sm-6">
                                                <span className='formfieldtitle'>Simulation Name<sup>*</sup></span>
                                                <div className=" " style={{ backgroundColor: "#FFF", borderRadius: '5px', padding: '0px', }}>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Simulation Name"
                                                        inputProps={{ 'aria-label': 'naked' }}
                                                        onChange={this.onChangeName}
                                                        id="simulationname"
                                                        style={{ width: '100%' }}
                                                        value={this.state.simname}
                                                    />
                                                    <br />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-sm-6">
                                                <div>
                                                    {this.state.sim_name_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Simulation name must be provided</span> : <span></span>}
                                                    {this.state.sim_name_same ? < span style={{ color: '#f0ab00' }}><WarningRoundedIcon style={{ color: '#f0ab00' }} />  Warning! This simulation is named the same as a previous one.  This may make it difficult to find later.</span> : <span></span>}
                                                </div>
                                            </div>
                                            <div style={dropdownInput} className="col-sm-6">
                                                <span className='formfieldtitle'>Dataset<sup>*</sup></span>
                                                <InfoRoundedIcon
                                                    style={{ position: 'relative', top: '-10px', fontSize: '15px' }}
                                                    onMouseEnter={showInfoProject}
                                                    onMouseLeave={hideInfoProject}
                                                />
                                                <Popover
                                                    style={{ pointerEvents: 'none' }}
                                                    open={this.state.openInfoProject}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'right',
                                                    }}
                                                    anchorEl={this.state.anchorElProjectInfo}
                                                >
                                                    This will contain the items, brands, and geographies you wish to run in your simulation.
                                                    <br />
                                                    <br />The selected dataset contains the following geograpgies: {this.state.geo_string}
                                                    <br />
                                                    <br />The selected dataset contains the following brands: {this.state.brand_string}
                                                    <br />
                                                </Popover>
                                                <Select
                                                    inputId="datasetdropdown"
                                                    options={this.state.datasets}
                                                    placeholder={'Select a dataset to use'}
                                                    onChange={(item, action) => this.onChangeDataSet(item, action)}
                                                    components={{ DropdownIndicator, IndicatorSeparator }}
                                                    value={this.state.DataSetChosen_option}
                                                    styles={selectColourStyles}
                                                />

                                            </div>
                                            <br />

                                            <div style={dropdownInput} className="col-sm-6">
                                                <span className='formfieldtitle'>Geography<sup>*</sup></span>
                                                <Select
                                                    inputId="geodropdown"
                                                    options={this.state.geos}
                                                    placeholder={'Select a Geography'}
                                                    onChange={(item, action) => this.onChangeGeo(item, action)}
                                                    components={{ DropdownIndicator, IndicatorSeparator }}
                                                    value={this.state.Geochosen}
                                                    styles={selectColourStyles}
                                                />
                                                {this.state.geos_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  A Geography must be provided</span> : <span></span>}
                                            </div>
                                            <div id="spacer_geo">
                                                <br />
                                                <br />
                                            </div>

                                            <div className="row" style={{ paddingLeft: '30px' }}>
                                                <span className='formfieldtitle'>Simulation Type<sup>*</sup></span>
                                            </div>
                                            <div className="row" style={{ paddingLeft: '30px' }}>
                                                <FormControlLabel
                                                    className='formfieldtitle'
                                                    //value="base"
                                                    control={
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Radio 
                                                                checked={this.state.simtype === 'base'}
                                                                onChange={this.handleChangeSimTypeRadio}
                                                                value="base"
                                                                name="simtyperadio"
                                                                size="small"
                                                                color="secondary"
                                                                id="basesimradio"
                                                            />
                                                        </ThemeProvider>
                                                    }

                                                    label=" Base Price Change"
                                                />
                                                <FormControlLabel
                                                    className='formfieldtitle'
                                                    //value="promo"
                                                    control={
                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                            <Radio
                                                                checked={this.state.simtype === 'promo'}
                                                                onChange={this.handleChangeSimTypeRadio}
                                                                value="promo"
                                                                name="simtyperadio"
                                                                size="small"
                                                                color="secondary"
                                                                id="promosimradio"
                                                            />
                                                        </ThemeProvider>
                                                    }
                                                    label="Promotion"
                                                />
                                                <br />
                                                {this.state.sim_base_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Simulation Type must be provided</span> : <span></span>}

                                            </div>

                                            <div className='row' style={{ paddingLeft: '10px' }}>
                                                <div className='col-sm-3'>
                                                    <TodayRoundedIcon /> <span className='formfieldtitle'>Simulation Start Date<sup>*</sup></span>
                                                    <Datetime
                                                        input={true}
                                                        isValidDate={valid_start}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        onChange={this.handleDateStart}
                                                        value={this.state.selectedstartdate}
                                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "simstartdate" }}
                                                        viewDate={this.state.futuredate}

                                                    />
                                                    {this.state.start_date_missing ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date must be provided</span> : <span></span>}
                                                    <br />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <TodayRoundedIcon /> <span className='formfieldtitle'>Simulation End Date<sup>*</sup></span>
                                                    <Datetime
                                                        input={true}
                                                        isValidDate={valid}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        onChange={this.handleDateEnd}
                                                        value={this.state.selectedenddate}
                                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "simenddate" }}
                                                        viewDate={this.state.futuredateplustwo}
                                                    />
                                                    {this.state.end_date_missing ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> End date must be provided</span> : <span></span>}
                                                    {this.state.start_gt_end ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date must be less than end date</span> : <span></span>}

                                                </div>
                                                <div className='col-sm-3'>  </div>
                                                <div className='col-sm-3'>  </div>
                                            </div>

                                                <div className='row' style={{ paddingLeft: '10px' }} >
                                                    {/*
                                                <div className='col-sm-3' style={dropdownInput}>
                                                    <span className="formfieldtitle">Coverage<sup>*</sup></span>
                                                    <InfoRoundedIcon
                                                        style={{ position: 'relative', top: '-10px', fontSize: '15px' }}
                                                        onMouseEnter={showInfoCoverage}
                                                        onMouseLeave={hideInfoCoverage}
                                                    />
                                                    <Popover
                                                        style={{ pointerEvents: 'none' }}
                                                        open={this.state.openInfoCoverage}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right',
                                                        }}
                                                        anchorEl={this.state.anchorElCoverageInfo}
                                                    >
                                                        Use this field to scale the results of the simulation relative to the market you wish to run the promotion/base price change in.
                                                </Popover>
                                                    <div className="" style={{ backgroundColor: "#FFF", borderRadius: '5px', padding: '0px', textAlign: 'left' }} >
                                                        <Constant.NPDTextField
                                                            label=""
                                                            id="bulkeditbasepricechg"
                                                            InputProps={{
                                                                endAdornment: <Constant.NPDInputAdornment position="end" style={{ fontSize: '1rem' }}>%</Constant.NPDInputAdornment>,
                                                            }}
                                                            variant="outlined"
                                                            //size="small"
                                                            onChange={this.handleChangeCoverage}
                                                            value={this.state.coverage * 100}
                                                            style={{ width: '100%', textAlign: 'left' }}
                                                        />
                                                    </div>
                                                    {this.state.coverage_lte_zero ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Coverage must be greater than zero</span> : <span></span>}
                                                </div>
                                                */}
                                                <div className='col-sm-3' style={{ display: displaynumweeks, textAlign: 'left', paddingTop: '10px' }}> <span className='formfieldtitle'>Number of Weeks Selected:</span> <br /> <p style={{ paddingTop: '2px', paddingLeft: '15px' }}>{Difference_In_Weeks}</p> </div>
                                            </div>
                                            <div className='row' style={{ paddingLeft: '10px' }} >
                                                <div className='col-sm-12'>

                                                </div>
                                            </div>
                                            <br />
                                            </div>
                                        </form>
                                        : <div></div>
                                    }
                                    {this.state.activeStep === 1 ? <div style={{ marginLeft: '40px', marginRight: '40px' }}>
                                        <div style={{ padding: '10px', fontFamily: 'Roboto', }} className="col-sm-6">
                                            <h3>Create a new simulation</h3>                                            
                                            <p>Add Items</p>
                                            <span className='formfieldtitle'> * indicates required field<sup>*</sup></span>
                                        </div>
                                        <div style={{ marginLeft: '10px', marginRight: '40px', marginTop:'10px', marginBotton: '20px' }}>

                                            <span style={{
                                                color: '#ffffff',
                                                fontFamily: 'Roboto',
                                                cursor: 'pointer',
                                                //fontWeight: 'bold',                            
                                                padding: '10px',
                                                backgroundColor: '#4e106f',
                                                borderRadius: '10px 10px 10px 10px',
                                                fontSize:'1.25rem'
                                            }}
                                                onClick={GoToItemGroupDefinitions}
                                                key="FiltersLink"
                                            >
                                                Define Item Groups
                                            </span>
                                        </div>
                                        {/*<div className="row">*/}
                                        {/*    <div style={dropdownInput} className="col-sm-6">*/}
                                        {/*        <button*/}
                                        {/*            onClick={ GoToItemGroupDefinitions }*/}
                                        {/*            className="btn btn-primary"*/}
                                        {/*            autoFocus*/}
                                        {/*            key="FiltersLink"*/}
                                        {/*        >*/}
                                        {/*            Define Item Groups*/}
                                        {/*        </button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="row" style={{ marginLeft: '10px', marginRight: '40px', marginTop: '10px', marginBotton: '20px' }}>
                                            <div style={dropdownInput} className="col-sm-6">
                                                <button
                                                    onClick={this.openItemGroupsModal}
                                                    className="btn btn-primary"
                                                    autoFocus
                                                >
                                                    Add Item Groups
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row" style={{ margin: '0px' }}>
                                            <div style={dropdownInput} className="col-sm-6">
                                                <span className='formfieldtitle'>Select Brands<sup>*</sup></span>
                                                <Select
                                                    id="mybrandsselection"
                                                    options={[alloption, ...this.state.brands]}
                                                    placeholder={'Select brands'}
                                                    onChange={(item, action) => this.onChangeBrands_comp(item, action)}
                                                    components={{ DropdownIndicator, IndicatorSeparator }}
                                                    isMulti={true}
                                                    value={this.state.selectedbrands}
                                                    styles={selectColourStyles}
                                                />
                                                {this.state.brands_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  A Brand must be provided</span> : <span></span>}
                                            </div>
                                        </div>

                                                                                
                                        <div className="row" style={{ margin: '0px', display: this.state.displayFilters, paddingTop: '50px' }} id="attributeFilters">

                                            <Modal
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                                open={this.state.ItemGroupsModalOpen}
                                                onClose={handleCloseItemGroupsModal}
                                                style={{ overflowY: 'scroll' }}
                                            >
                                                <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                    <h3>Item Groups</h3>
                                                
                                                    {(this.state.itemgroups || []).map(group => {

                                                        //perform a lookup on group.item_vals...
                                                        let itemnames = group.item_vals.map(item => {
                                                            return (this.state.items_lookup_object[String(item)] || {})['proddesc']
                                                        }).filter(Boolean)

                                                        return <div key={group.groupname}>
                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                <Checkbox                                                                    
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    color="secondary"
                                                                    onChange={handleToggle_itemgroups(group)}
                                                                    checked={group['checked']}
                                                                />
                                                            </ThemeProvider>
                                                            <Tooltip title="Click to See All Items in the group" placement="bottom">
                                                                <span
                                                                    style={{
                                                                        color: '#4e106f',
                                                                        cursor: 'pointer',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '1.4rem'
                                                                    }}
                                                                    onClick={handleOpenAllItemsModal(group)}                                                                
                                                                >
                                                                    {group.groupname + ' (' + itemnames.length + ') items'}
                                                                </span>                                                            
                                                            </Tooltip>
                                                         </div>
                                                    })}

                                                    <button
                                                        className="btn btn-primary"
                                                        autoFocus
                                                        onClick={addItemGroupstoSim }
                                                    >
                                                        Add Item Groups to Simulation
                                                    </button>

                                                </div>
                                            </Modal>

                                            <Modal
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                                open={this.state.AllItemsModalOpen}
                                                onClose={handleCloseAllItemsModal}
                                                style={{ overflowY: 'scroll' }}
                                            >
                                                <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                    <h4>{'All Items in ' + (this.state.groupformodal || {})['groupname']} </h4>
                                                    <ul>
                                                        {((this.state.groupformodal || {})['item_vals'] || []).map(item => {
                                                            if ((this.state.items_lookup_object[String(item)] || {})['proddesc']) {
                                                                return <li key={item}> {
                                                                    (this.state.items_lookup_object[String(item)] || {})['proddesc']
                                                                }
                                                                </li>
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                            
                                                        })}
                                                    </ul>
                                                </div>
                                            </Modal>

                                            <Modal
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                                open={this.state.AllAttrsModalOpen}
                                                onClose={handleCloseAllAttrsModal}
                                                style={{ overflowY: 'scroll' }}
                                            >
                                                <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                    <h4>Product Attributes</h4>
                                                    <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                                        {this.state.attributes.map((attribute, idx) => {
                                                            let invalidattrs = ['equiv', 'suppression','geogdesc']
                                                            if (!invalidattrs.includes(attribute.attribute)) {
                                                                return <div className="col-sm-4" key={"attributeSelctionModal_" + attribute.attribute + idx}>
                                                                    <span className='formfieldtitle'>{this.state.attribute_lookup[attribute.attribute]}</span>
                                                                    <Select
                                                                        id={"attributeSelctionModal_" + attribute.attribute}
                                                                        name={attribute.attribute}
                                                                        options={attribute.attribute_values.map(value => { return { 'label': value, 'value': value } })}
                                                                        placeholder={'Select ' + this.state.attribute_lookup[attribute.attribute]}
                                                                        onChange={(item, action) => this.filterAttributeNew(item, action)} //this.filterAttribute(item, action)}
                                                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                                                        isMulti={true}
                                                                        styles={selectColourStyles}
                                                                        //value={this.state.attribute_selections && (this.state.attribute_selections[attribute.attribute] || []).map(value => { return { 'label': value, 'value': value } })}
                                                                        //value={this.state.selectedattributes[idx]}
                                                                    />
                                                                </div>
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </Modal>
                                            {/*Look at the filters and based on the length show a link*/}
                                            {this.state.attributes.length <= 9 ?
                                                <div className='col-sm-12' />
                                                :
                                                <div className='col-sm-12'>
                                                    <span style={{
                                                        color: '#4e106f',
                                                        cursor: 'pointer',
                                                        fontWeight: 'bold',
                                                        fontSize: '13px'
                                                    }}
                                                        onClick={handleOpenAllAttrsModal}
                                                        key="AllAttrsLink"
                                                    >
                                                        View All {this.state.attributes && this.state.attributes.length} Attributes
									                </span>
                                                </div>
                                            }
                                            {this.state.attributes.length <= 9 ?
                                                this.state.attributes.map((attribute, idx) => {
                                                    let invalidattrs = ['equiv', 'suppression','geogdesc']
                                                    if (!invalidattrs.includes(attribute.attribute)) {
                                                        return <div className="col-sm-4" key={"attributeSelction_" + attribute.attribute+idx}>
                                                            <span className='formfieldtitle'>{this.state.attribute_lookup[attribute.attribute]}</span>
                                                            <Select
                                                                id={"attributeSelction_" + attribute.attribute}
                                                                name={attribute.attribute}
                                                                options={attribute.attribute_values.map(value => { return { 'label': value, 'value': value } })}
                                                                placeholder={'Select ' + this.state.attribute_lookup[attribute.attribute]}
                                                                onChange={(item, action) => this.filterAttributeNew(item, action)} //this.filterAttribute(item, action)}
                                                                components={{ DropdownIndicator, IndicatorSeparator }}
                                                                isMulti={true}
                                                                styles={selectColourStyles}
                                                                //value={this.state.attribute_selections && (this.state.attribute_selections[attribute.attribute] || []).map(value => { return { 'label': value, 'value': value } })}
                                                                //value={this.state.selectedattributes[idx]}
                                                            />
                                                        </div>
                                                    }

                                                })
                                                :
                                                this.state.attributes.map((attribute, idx) => {
                                                    let invalidattrs = ['equiv', 'suppression', 'geogdesc']
                                                    if (!invalidattrs.includes(attribute.attribute)) {
                                                        if (idx < 9) {
                                                            return <div className="col-sm-4" key={"attributeSelction_" + attribute.attribute + idx}>
                                                                <span className='formfieldtitle'>{this.state.attribute_lookup[attribute.attribute]}</span>
                                                                <Select
                                                                    id={"attributeSelction_" + attribute.attribute}
                                                                    name={attribute.attribute}
                                                                    options={attribute.attribute_values.map(value => { return { 'label': value, 'value': value } })}
                                                                    placeholder={'Select ' + this.state.attribute_lookup[attribute.attribute]}
                                                                    onChange={(item, action) => this.filterAttributeNew(item, action)} //this.filterAttribute(item, action)}
                                                                    components={{ DropdownIndicator, IndicatorSeparator }}
                                                                    isMulti={true}
                                                                    styles={selectColourStyles}
                                                                    //value={this.state.attribute_selections && (this.state.attribute_selections[attribute.attribute] || []).map(value => { return { 'label': value, 'value': value} })}
                                                                    //value={this.state.selectedattributes[idx]}
                                                                />
                                                            </div>
                                                        }
                                                        else {
                                                            return <div className='col-sm-4' key={"attributeSelction_" + attribute.attribute + idx} />
                                                        }
                                                    }

                                                })
                                            }

                                        </div>


                                        <div id="spacer_brand">
                                            <br />
                                            <br />
                                        </div>
                                        <div className="row col-sm-12" style={{ padding: '10px', margin: '0px', display: this.state.displayFilters }}>
                                            
                                            <div className="col-sm-6" style={{ fontSize: '12px', padding: '10px', margin: '0px', display: this.state.displayFilters }}>
                                                <span className='formfieldtitle' align="center">Average Selling Price</span>
                                                <br />
                                                <div className='row col-sm-12 '>
                                                    <div className='col-sm-3 ' style={{ padding: "0px", backgroundColor: "#FFF" }}>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                startAdornment: <Constant.NPDInputAdornment position="start"> { this.state.currencysymbol } </Constant.NPDInputAdornment>
                                                            }}
                                                            onChange={this.handleChangeASPMinBox}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    this.sliderCommittedNew()
                                                            }}
                                                            id="aspmin"
                                                            value={sliderRange[0]}
                                                        />
                                                    </div>
                                                    <div className='col-sm-6 '>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Slider
                                                                color="primary"
                                                                value={sliderRange}
                                                                onChange={this.handleChangeSlider}
                                                                onChangeCommitted={this.sliderCommittedNew}
                                                                valueLabelDisplay="auto"
                                                                aria-labelledby="range-slider"
                                                                max={sliderRangeMax}
                                                            />
                                                        </ThemeProvider>
                                                    </div>
                                                    <div className='col-sm-3 ' style={{ padding: "0px", backgroundColor: "#FFF" }}>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                startAdornment: <Constant.NPDInputAdornment position="start"> {this.state.currencysymbol} </Constant.NPDInputAdornment>
                                                            }}
                                                            onChange={this.handleChangeASPMaxBox}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    this.sliderCommittedNew()
                                                            }}
                                                            id="aspmax"
                                                            value={sliderRange[1]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6" style={{ fontSize: '12px', padding: '10px', margin: '0px', display: this.state.displayFilters }}>
                                                <span className='formfieldtitle' align="center">Price Band Group</span>
                                                <Select
                                                    id="pricebandgroupfilter" 
                                                    name="pricebandgroupfilter"
                                                    options={price_band_options}
                                                    placeholder='Select A Price Band' 
                                                    onChange={(item, action) => this.filterPriceBandsNew(item, action)}
                                                    components={{ DropdownIndicator, IndicatorSeparator }}
                                                    isMulti={true}
                                                    styles={selectColourStyles}
                                                />
                                            </div>

                                            <div className="col-sm-6" style={{ backgroundColor: '#FFF', fontSize: '12px', padding: '1px', margin: '0px', display: this.state.displayFilters }}>
                                                <TextField
                                                    style={{ width: '100%' }}
                                                    variant="outlined"
                                                    InputProps={{ endAdornment: <Constant.NPDInputAdornment position="end"><SearchRoundedIcon /></Constant.NPDInputAdornment>, }}
                                                    placeholder="Search for an item"
                                                    onChange={searchFunction}
                                                >
                                                </TextField>
                                            </div>
                                        </div>

                                        <div id="spacer_brand">
                                            <br />
                                            <br />
                                        </div>
                                        <div className="row" style={{ margin: '0px' }}>
                                            <div className="col-sm-4">
                                                <p>Items in selected Brands
                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                        <InfoRoundedIcon
                                                            style={{
                                                                position: 'relative', top: '-20px', color: '#4e106f'
                                                            }}
                                                            onMouseEnter={showInfoMyBrandItems}
                                                            onMouseLeave={hideInfoMyBrandItems}
                                                        />
                                                        <Popover
                                                            style={{ pointerEvents: 'none' }}
                                                            open={this.state.openInfoMyBrandItems}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            anchorEl={this.state.anchorElMyBrandItemsInfo}
                                                        >
                                                            For the brands selected, these are the items that are available to select.
                                                        </Popover>
                                                    </ThemeProvider>
                                                </p>
                                                <div className="contentHolder" style={{ marginLeft: '0px', marginRight: '0px' }} id="myBrandItemsList">
                                                    <List dense component="div" role="list" style={{ height: '500px', overflowY: 'auto', fontSize: '12px' }}>
                                                        {this.state.mybranditems && this.state.mybranditems_checked &&
                                                            <ListItem key={'SelectAllmy_brand'} role="listitem" button onClick={handleToggle_checkmarks('mybrands', -1)} >
                                                                <ListItemIcon>
                                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                                        <Checkbox
                                                                            checked={this.state.mybranditems_allchecked}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            inputProps={{ 'aria-labelledby': 'Select All' }}
                                                                        />
                                                                    </ThemeProvider>
                                                                </ListItemIcon>
                                                                <ListItemText id={'Select All'} primary={'Select All'} />
                                                            </ListItem>
                                                        }
                                                        
                                                         {/*const ishighlighted = this.state.items_lookup_object.filter(item => this.state.*/}
                                                        {this.state.mybranditems && this.state.mybranditems_checked && this.state.mybranditems.map((item, idx) => {
                                                            //var ishighlighted = []
                                                            //if (this.state.internalcompitems.includes(item)) { ishighlighted = true } else {ishighlighted = false}
                                                           /* if (!this.state.selecteditems.includes(item)) {*/
                                                                return (
                                                                    <ListItem key={this.state.items_lookup_object[item]['proddesc'] + 'my_brand'} role="listitem" button onClick={handleToggle_checkmarks('mybrands', idx)} style={{ backgroundColor: this.state.ishighlighted===true ? '4e106f' : 'default' }} > {/*sx={{backgroundColor: ishighlighted ? 'purple' : 'default' }}*/}
                                                                        <ListItemIcon>
                                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                                <Checkbox
                                                                                    checked={this.state.mybranditems_checked[idx]}
                                                                                    tabIndex={-1}
                                                                                    disableRipple
                                                                                    inputProps={{ 'aria-labelledby': this.state.items_lookup_object[item]['proddesc'] }}
                                                                                //className='checkboxes_mine'
                                                                                //defaultChecked={this.state.mybranditems_checked[idx]}
                                                                                //onChange={this.onCheckItems_myBrands(idx)}
                                                                                />
                                                                            </ThemeProvider>
                                                                        </ListItemIcon>
                                                                        <ListItemText id={this.state.items_lookup_object[item]['proddesc']} primary={this.state.items_lookup_object[item]['proddesc']} />
                                                                        {/*this.state.internal_flags[idx] ? <Constant.NPDMyBrandCompetitorChip_Label label='Internal Competitor' /> : <Fragment />*/}
                                                                    </ListItem>
                                                                )
                                                           // }
                                                        })}
                                                    </List>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <p>Competitive Items
                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                        <InfoRoundedIcon
                                                            style={{ position: 'relative', top: '-20px', color: '#4e106f' }}
                                                            onMouseEnter={showInfoCompetitors}
                                                            onMouseLeave={hideInfoCompetitors}
                                                        />
                                                        <Popover
                                                            style={{ pointerEvents: 'none' }}
                                                            open={this.state.openInfoCompetitors}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            anchorEl={this.state.anchorElCompInfo}
                                                        >
                                                            When an item in the previous column is selected, the items with identified cross-effects will be listed here.  If an item in the previous column has cross effects, it will be labeled with an "Internal Competitor" Tag
                                                        </Popover>
                                                    </ThemeProvider>
                                                </p>
                                                <div className="contentHolder" style={{ marginLeft: '0px', marginRight: '0px' }} id="compItemsList">
                                                    <List dense component="div" role="list" style={{ height: '500px', overflowY: 'auto', fontSize: '12px' }}>
                                                        <ListItem key={'SelectAllmy_brand'} role="listitem" button onClick={handleToggle_checkmarks('comp', -1)} >
                                                            <ListItemIcon>
                                                                <ThemeProvider theme={Constant.muiTheme}>
                                                                    <Checkbox
                                                                        checked={this.state.compitems_allchecked}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': 'Select All' }}
                                                                    />
                                                                </ThemeProvider>
                                                            </ListItemIcon>
                                                            <ListItemText id={'Select All'} primary={'Select All'} />
                                                        </ListItem>
                                                        {(this.state.compitems || []).map((item, idx) => {
                                                            //if (!this.state.selecteditems.includes(item)) {
                                                                //console.log(item)
                                                                //console.log(this.state.items_lookup_object[item])
                                                                return (
                                                                    <ListItem key={this.state.items_lookup_object[item]['proddesc'] + 'comp_with'} role="listitem" button onClick={handleToggle_checkmarks('comp', idx)} >
                                                                        <ListItemIcon>
                                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                                <Checkbox
                                                                                    checked={(this.state.compitems_checked || [])[idx]}
                                                                                    tabIndex={-1}
                                                                                    disableRipple
                                                                                    inputProps={{ 'aria-labelledby': this.state.items_lookup_object[item]['proddesc'] }}
                                                                                //onChange={this.onCheckItems_myBrands(idx)}
                                                                                />
                                                                            </ThemeProvider>
                                                                        </ListItemIcon>
                                                                        <ListItemText id={this.state.items_lookup_object[item]['proddesc']} primary={this.state.items_lookup_object[item]['proddesc']} />
                                                                    </ListItem>
                                                                )
                                                            //}
                                                        })}
                                                    </List>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <p>Other Items
                                                <ThemeProvider theme={Constant.muiTheme}>
                                                        <InfoRoundedIcon
                                                            style={{ position: 'relative', top: '-20px', color: '#4e106f' }}
                                                            onMouseEnter={showInfoOtherItems}
                                                            onMouseLeave={hideInfoOtherItems}
                                                        />
                                                        <Popover
                                                            style={{ pointerEvents: 'none' }}
                                                            open={this.state.openInfoOtherItems}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            anchorEl={this.state.anchorElOtherItemsInfo}
                                                        >
                                                            These are all other items that are not in the selected brands or do not have competitive effects for selected items in the selected brands.
                                                    </Popover>
                                                    </ThemeProvider>
                                                </p>
                                                <div className="contentHolder" style={{ marginLeft: '0px', marginRight: '0px' }} id="otherItemsList">
                                                    <List dense component="div" role="list" style={{ height: '500px', overflowY: 'auto', fontSize: '12px' }}>
                                                        <ListItem key={'SelectAllmy_brand'} role="listitem" button onClick={handleToggle_checkmarks('other', -1)} >
                                                            <ListItemIcon>
                                                                <ThemeProvider theme={Constant.muiTheme}>
                                                                    <Checkbox
                                                                        checked={this.state.otheritems_allchecked}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': 'Select All' }}
                                                                    />
                                                                </ThemeProvider>
                                                            </ListItemIcon>
                                                            <ListItemText id={'Select All'} primary={'Select All'} />
                                                        </ListItem>
                                                        {(this.state.otheritems || []).map((item, idx) => {
                                                            return (
                                                                <ListItem key={this.state.items_lookup_object[item]['proddesc'] + 'comp_without'} role="listitem" button onClick={handleToggle_checkmarks('other',idx)} >
                                                                    <ListItemIcon>
                                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                                            <Checkbox
                                                                                checked={(this.state.otheritems_checked || [])[idx]}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': this.state.items_lookup_object[item]['proddesc'] }}
                                                                            //onChange={this.onCheckItems_myBrands(idx)}
                                                                            />
                                                                        </ThemeProvider>
                                                                    </ListItemIcon>
                                                                    <ListItemText id={this.state.items_lookup_object[item]['proddesc']} primary={this.state.items_lookup_object[item]['proddesc']} />
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </List>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ margin: '0px' }}>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'white' }}>All Items in the simulation</p>
                                                <div className="contentHolder" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                    <Select
                                                        id="itemdropdown"
                                                        //Change={(item, action) => this.onChangeItems(item, action)}
                                                        isMulti={true}
                                                        style={{ margin: '25px' }}
                                                        value={this.state.Itemschosen}
                                                        isDisabled={true}
                                                        styles={selectColourStyles}
                                                    />
                                                    {this.state.items_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  At least one item must be provided<br /><br /></span> : <span></span>}
                                                    {this.state.removeditems && this.state.removeditems.length > 0 ? < span style={{ color: '#F0AB00' }}><WarningRoundedIcon style={{ color: '#F0AB00' }} />  Unfortunately, One or More Items from your simulation no longer has sales and has been removed from the available items.<br /><br /></span> : <span></span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div id="spacer_items"></div>

                                    </div>

                                        : <div></div>
                                    }
                                    {this.state.activeStep === 2 ? <div style={{ marginLeft: '40px', marginRight: '40px' }}>

                                        <div style={dropdownInput} className="col-sm-6">
                                            <h3>Create a new simulation</h3>
                                            {/*
                                            <span style={{
                                                color: '#0078BE',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',

                                            }}
                                                onClick={this.handleOpenReportCriteriaModal}
                                                key="SimCriteriaLink"
                                            >
                                                View Simulation Criteria
                                            </span>
                                            */}
                                            <p>Input Simulation Data</p>

                                        </div>

                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.openBulkEditModal}
                                            onClose={this.hideBulkEditModal}
                                        >
                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                                                    <h5>Bulk Edit Items</h5>
                                                    <h6>Items({this.state.bulkedit && this.state.bulkedit.reduce(function (total, num) { return total + num })})</h6>                                                    
                                                    {this.state.bulkedit && this.state.bulkedit.map((bulkeditval, idx) => {
                                                        if (bulkeditval == true) {
                                                            return <span key={idx}>{this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']}<br /></span>
                                                        }
                                                    })}                                                    
                                                </div>
                                                <div style={{ display: this.state.basedisplay }}>
                                                    <span className="formfieldtitle" >New Base Price</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditbaseprice"
                                                        InputProps={{
                                                            startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditBasePrice}
                                                        error={this.state.bulkediterror}
                                                    />
                                                    <br />
                                                    <span className="formfieldtitle">% Change from Base Price</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditbasepricechg"
                                                        InputProps={{
                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditBasePriceChg}
                                                        error={this.state.bulkediterror}
                                                    />
                                                </div>
                                                <div style={{ display: this.state.promodisplay }}>
                                                    <span className="formfieldtitle" >% Discount</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditdiscount"
                                                        InputProps={{
                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditDiscount}
                                                        error={this.state.bulkediterror}
                                                    />
                                                    <br />
                                                    <span className="formfieldtitle">Promoted Price</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditdiscountchg"
                                                        InputProps={{
                                                            startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditPromotedPrice}
                                                        error={this.state.bulkediterror}
                                                    />
                                                </div>
                                                <br />
                                                <div style={{ display: this.state.promodisplayad, minWidth: '500px' }}>
                                                    <span className="formfieldtitle" >Promotion Effect</span>
                                                    <br />
                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                        <Select
                                                            id={"promoeffectdropdownbulkedit"}
                                                            options={this.state.promoeffect_options}
                                                            placeholder={'Select an ad effect to use'}
                                                            onChange={(options)=>this.handleChangeBulkEditPromoEffect(options)}
                                                            components={{ DropdownIndicator, IndicatorSeparator }}
                                                            value={this.state.selected_options_promos_bulkedit}
                                                            styles={selectColourStyles}
                                                        />
                                                    </ThemeProvider>
                                                </div>
                                                <br />

                                                <div style={{ display: this.state.promodisplayad, minWidth: '500px' }}>
                                                    <span className="formfieldtitle" >Ad Effect</span>
                                                    <br />
                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                        <Select
                                                            id={"adeffectdropdownbulkedit"}
                                                            options={this.state.adeffect_options}
                                                            placeholder={'Select an promotion effect to use'}
                                                            onChange={(options)=>this.handleChangeBulkEditAdEffect(options)}
                                                            components={{ DropdownIndicator, IndicatorSeparator }}
                                                            value={this.state.selected_options_ads_bulkedit}
                                                            styles={selectColourStyles}
                                                        />
                                                    </ThemeProvider>
                                                </div>
                                                <br />
                                                {this.state.bulkediterror ? <div><span id='item_error' style={{ color: '#822433', display: 'inline' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {this.state.bulkedit_error_text}</span></div>:<div/>}
                                                <ThemeProvider theme={Constant.muiTheme}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.applyBulkEditChanges}
                                                        className={classes.button}
                                                    >
                                                        Apply Bulk Edit
                                                    </Button>
                                                    <span style={{ padding: '10px' }} />
                                                    <Button
                                                        variant="contained"
                                                        color="white"
                                                        onClick={this.hideBulkEditModal}
                                                        className={classes.button}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </ThemeProvider>
                                            </div>
                                        </Modal>
                                        <br />
                                        <br />
                                        <div className="" style={{ width: '100%', position: 'relative', left: '0vw', paddingLeft: '0px', minWidth: '1000px' }}>
                                            <div className="row clearfix">
                                                <div className="col-md-12 column ">

                                                    <Paper className='test' style={{ padding: '10px' }}>
                                                        <div className="col-md-12 column " align="center">
                                                            <h5 >Items</h5>
                                                        </div>
                                                        <div className="col-md-12 column actionbarsimentry" style={{ padding: '10px' }}>
                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                <Tooltip title="Use this to edit multiple items at once" placement="bottom">
                                                                    <Button
                                                                        //style={{ color: '#FFF', borderRadius: '5px 0px 0px 5px', backgroundColor: '#FFF', borderColor: '#FAFAFA', borderWidth: '1px' }}
                                                                        onClick={this.showBulkEdit}
                                                                        color="primary"
                                                                        variant="contained"
                                                                        className={classes.button}
                                                                    >
                                                                        Bulk Edit
                                                                    </Button>
                                                                </Tooltip>
                                                            </ThemeProvider>
                                                            <br />
                                                            <br />
                                                            <div className='row col-sm-12'>
                                                                <TextField
                                                                    style={{ width: '50%', backgroundColor:"#FFF" }}
                                                                    variant="outlined"
                                                                    InputProps={{ endAdornment: <Constant.NPDInputAdornment position="end"><SearchRoundedIcon /></Constant.NPDInputAdornment>, }}
                                                                    placeholder="Search for an item"
                                                                    onChange={searchFunction2}
                                                                >
                                                                </TextField>                                                                
                                                            </div>
                                                            {this.state.bulkeditdisplay === 'table-cell' ?
                                                                <div style={{ width: '50%', backgroundColor: "#FFF" }}>
                                                                    <Select
                                                                        id={"bulkedititemgroups"}
                                                                        options={(this.state.selectedgroups || []).map(group => {
                                                                            return { 'label': group.groupname, 'value': group.item_vals }
                                                                        })}
                                                                        placeholder={'Select Item Groups to Bulk Edit'}
                                                                        onChange={(selectedOptions) => this.checkItemGroups(selectedOptions)}
                                                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                                                        isMulti
                                                                        //Add Z-INdex Here....
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        styles={selectColourStyles}
                                                                    />
                                                                </div>
                                                                :
                                                                <div style={{ width: '50%', backgroundColor: "#FFF" }}>
                                                                    <Select
                                                                        id={"filteritemgroups"}
                                                                        options={(this.state.selectedgroups || []).map(group => {
                                                                            return { 'label': group.groupname, 'value': group.item_vals }
                                                                        })}
                                                                        placeholder={'Select Item Groups to filter to'}
                                                                        onChange={(selectedOptions) => filterItemGroups(selectedOptions)}
                                                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                                                        isMulti
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        styles={selectColourStyles}
                                                                    />
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                        <div className='row col-sm-12' style={{maxHeight: '500px', overflowY:'auto', zIndex:0}}>
                                                            <Constant.NPDTable>
                                                                <TableHead style={{ width: '100%', padding: '10px' }} className='sticky'>
                                                                    <Constant.NPDTableRow >
                                                                        <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}> </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Item</Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Data </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}>
                                                                            Historical Data

                                                                            <InfoRoundedIcon
                                                                                style={{ position: 'relative', top: '-10%', fontSize: '15px', color: '#4e106f' }}
                                                                                onMouseEnter={showInfoHistData}
                                                                                onMouseLeave={hideInfoHistData}
                                                                            />
                                                                        
                                                                        
                                                                            <Popover
                                                                                style={{ pointerEvents: 'none' }}
                                                                                open={this.state.openInfoHistData}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                                anchorEl={this.state.anchorElHistData}
                                                                            >
                                                                                For each item, the base price, promoted price, and discount percent come from the last week of data available.  The base volume is the average weekly base volume for the time period 1 year ago from the selected simulation period.
                                                                            </Popover>
                                                                        </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}>  </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}>  </Constant.NPDTableCell>
                                                                    
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.basedisplay }}>

                                                                            Simulated Data
                                                                            <InfoRoundedIcon
                                                                                style={{ position: 'relative', top: '-10%', fontSize: '15px', color: '#4e106f' }}
                                                                                onMouseEnter={showInfoSimData}
                                                                                onMouseLeave={hideInfoSimData}
                                                                            />
                                                                        
                                                                        
                                                                            <Popover
                                                                                style={{ pointerEvents: 'none' }}
                                                                                open={this.state.openInfoSimData}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                                anchorEl={this.state.anchorElSimData}
                                                                            >
                                                                                For each item this will be the information that gets modeled in the simulation.
                                                                            </Popover>
                                                                        </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.basedisplay }}>  </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplay }}> Simulated Data </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplay }}> </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell align='center' style={{ width: '10%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplayad }}> Ad &  </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplayad }}> Promo Effects </Constant.NPDTableCell >
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> </Constant.NPDTableCell >
                                                                    </Constant.NPDTableRow>
                                                                    <Constant.NPDTableRow>
                                                                        <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>
                                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                                <Checkbox
                                                                                    checked={this.state.selectallbulkedit}
                                                                                    onChange={this.selectAllBulkEdit}
                                                                                    color="secondary"
                                                                                />
                                                                            </ThemeProvider>

                                                                        </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Item Type</Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '15%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Item </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Average Weekly Base Volume </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Most Recent Base Price </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Most Recent % Discount </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Most Recent Promoted Price </Constant.NPDTableCell>
                                                                    
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.basedisplay }}> New Base Price </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.basedisplay }}> % Change From Current </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplay }}> New Discount % </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplay }}> New Promoted Price </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell align='center' style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplayad }}> {"Ad Effect"} </Constant.NPDTableCell>
                                                                        <Constant.NPDTableCell style={{ width: '10%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.promodisplayad }}>  {"Promotion Effect"}  </Constant.NPDTableCell>
                                                                    
                                                                    
                                                                        <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> </Constant.NPDTableCell >
                                                                    </Constant.NPDTableRow>
                                                                </TableHead>

                                                                <TableBody style={{ padding: '25px', width: '100%' }}>
                                                                    <Constant.NPDTableRow style={{ height: '5px' }} />

                                                                    {this.state.bulkedit && this.state.selectedgroups.map((group, gpidx) => (
                                                                        <Fragment key={"GroupFragment_" + gpidx}>
                                                                            <Constant.NPDTableRow
                                                                                className="tableRow groupRow"
                                                                                id="addr0"
                                                                                key={gpidx}                                                                                
                                                                                //selected={this.state.rowSelectedList[idx]}
                                                                            >
                                                                                <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>
                                                                                    
                                                                                </Constant.NPDTableCell>

                                                                                <Constant.NPDTableCell style={{ width: '5%' }}>
                                                                                    <Tooltip title="Click to See Item Details" placement="bottom">
                                                                                        <IconButton
                                                                                            onClick={(e, idx) => this.expandGroup('test', gpidx)}
                                                                                        >
                                                                                            <ExpandMoreIcon style={{ color: '#8e908f', fontSize: '1.5rem' }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>

                                                                                    <Tooltip title={group.groupname} placement="right-end" >                                                                                        
                                                                                        <Constant.NPDItemGroupChip
                                                                                            label={group.groupname}
                                                                                            style={{ width: '65%' }}                                                                                            
                                                                                        />
                                                                                    </Tooltip>
                                                                                    
                                                                                </Constant.NPDTableCell>
                                                                                <Tooltip title={group.groupname} placement="top" arrow className="itemNameCell">
                                                                                    <Constant.NPDTableCell style={{ width: '15%' }}>
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            value={group.groupname}
                                                                                            readOnly
                                                                                            style={{ width: '100%' }}
                                                                                        />
                                                                                    </Constant.NPDTableCell>
                                                                                </Tooltip>
                                                                                <Constant.NPDTableCell style={{ width: '5%' }} id={"grouphistvolcell_" + gpidx} align="right">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"grouphistvol_" + gpidx}
                                                                                        size="small"
                                                                                        variant="outlined"
                                                                                        value={'Expand to See Item Details'}                                                                                        
                                                                                    />
                                                                                </Constant.NPDTableCell >
                                                                                <Constant.NPDTableCell style={{ width: '5%' }} id={"grouphistaspcell_" + gpidx} align="right" size="small">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id="standard-start-adornment-base-{idx}"                                                                                        
                                                                                        variant="outlined"                                                                                        
                                                                                        size="small"
                                                                                        value = { 'Expand to See Item Details'}
                                                                                    />
                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell style={{ width: '5%' }} id={"grouphistpctcell_" + gpidx} align="right" size="small">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id="standard-start-adornment-discount-{idx}"                                                                                        
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        value={'Expand to See Item Details'}
                                                                                    />
                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell style={{ width: '5%' }} id={"grouphistopromopricecell_" + gpidx} align="right" size="small">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id="standard-start-adornment-basepct-{idx}"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        value={'Expand to See Item Details'}
                                                                                        readonly                                                                                                                                                            
                                                                                    />
                                                                                </Constant.NPDTableCell>

                                                                                <Constant.NPDTableCell style={{ display: this.state.basedisplay, width: '5%' }} align="right" size="small">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"groupnewbaseprice" + gpidx}
                                                                                        InputProps={{
                                                                                            startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"                                                                                        
                                                                                        onChange={this.handleChangeGeneric(gpidx, 'SimBasePrice_Group', 'SimData')}
                                                                                        //value={this.state.newbases[idx]}
                                                                                        //error={this.state.input_errors_base[idx]}
                                                                                    />
                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell style={{ display: this.state.basedisplay, width: '5%' }} align="right" size="small">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"groupbasepricepctchg" + gpidx}
                                                                                        InputProps={{
                                                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"                                                                                        
                                                                                        onChange={this.handleChangeGeneric(gpidx, 'SimBasePriceChange_Group', 'SimData')}
                                                                                        //value={this.state.newbases_chg[idx]}
                                                                                        //error={this.state.input_errors_base[idx]}
                                                                                    />
                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell style={{ display: this.state.promodisplay, width: '5%' }} align="right" size="small">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"groupnewdiscpercent" + gpidx}
                                                                                        InputProps={{
                                                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"                                                                                        
                                                                                        onChange={this.handleChangeGeneric(gpidx, 'SimPctDisc_Group', 'SimData')}
                                                                                        //value={this.state.newpcts[idx]}
                                                                                        //error={this.state.input_errors_promo[idx]}
                                                                                    />
                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell style={{ display: this.state.promodisplay, width: '5%' }} align="right" size="small">
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                      
                                                                                        id={"groupnewpromoprice" + gpidx}
                                                                                        InputProps={{
                                                                                            startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        pattern="[0-9.]*"
                                                                                        size="small"                                                                                                                                                                          
                                                                                        onChange={this.handleChangeGeneric(gpidx, 'SimPctDiscChange_Group', 'SimData')}
                                                                                        //value={this.state.newpcts_chg[idx]}      
                                                                                        //error={this.state.input_errors_promo[idx]}
                                                                                    />
                                                                                </Constant.NPDTableCell>

                                                                                <Constant.NPDTableCell style={{ display: this.state.promodisplayad, width: '10%' }} align="center">
                                                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                                                        <Select
                                                                                            id={"groupaddeffectdropdown" + gpidx}
                                                                                            options={this.state.adeffect_options}
                                                                                            placeholder={'Select an ad effect to use'}
                                                                                            onChange={this.onChangeAdType_Group(gpidx)}
                                                                                            components={{ DropdownIndicator, IndicatorSeparator }}
                                                                                            styles={selectColourStyles}
                                                                                            //value={this.state.selected_options_ads[idx]}                                                                                        
                                                                                        />
                                                                                    </ThemeProvider>

                                                                                </Constant.NPDTableCell>

                                                                                <Constant.NPDTableCell style={{ display: this.state.promodisplayad, width: '10%' }} align="center">
                                                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                                                        <Select
                                                                                            id={"grouppromoeffectdropdown" + gpidx}
                                                                                            options={this.state.promoeffect_options}
                                                                                            placeholder={'Select an ad effect to use'}
                                                                                            onChange={this.onChangePromoType_Group(gpidx)}
                                                                                            components={{ DropdownIndicator, IndicatorSeparator }}
                                                                                            styles={selectColourStyles}
                                                                                            //value={this.state.selected_options_promos[idx]}                                                                                        
                                                                                        />
                                                                                    </ThemeProvider>

                                                                                </Constant.NPDTableCell>

                                                                                <Constant.NPDTableCell style={{ width: '5%' }} align="right">

                                                                                    <Tooltip title="Delete Row" placement="bottom">
                                                                                        <IconButton
                                                                                            //onClick={this.handleRemoveSpecificRow(idx)}
                                                                                        >
                                                                                            <HighlightOffRoundedIcon style={{ color: 'red', fontSize: '1.5rem' }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Constant.NPDTableCell>
                                                                            </Constant.NPDTableRow>

                                                                            
                                                                            {this.state.bulkedit && this.state.selecteditems.map((item, idx) => {

                                                                                if (group.item_vals.includes(parseInt(item))) {
                                                                                    return <Fragment key={"Fragment_" + idx}>
                                                                                        <Constant.NPDTableRow className={"tableRow " + group.groupname} style={{ display: 'none' }} id="addr0" key={idx} onClick={this.handleSetSelectedRow(idx)} selected={this.state.rowSelectedList[idx]} >
                                                                                            <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>
                                                                                                <ThemeProvider theme={Constant.muiTheme}>
                                                                                                    <Checkbox
                                                                                                        checked={this.state.bulkedit[idx]}
                                                                                                        onChange={this.bulkEditChecks(idx)}
                                                                                                        color="secondary"
                                                                                                    />
                                                                                                </ThemeProvider>
                                                                                            </Constant.NPDTableCell>

                                                                                            <Constant.NPDTableCell style={{ width: '5%' }}>

                                                                                                {this.state.comp_type[idx] === 'mybrand' &&
                                                                                                    <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                        <Constant.NPDMyBrandChip label='My Brand' style={{ width: '100%' }} />
                                                                                                    </Tooltip>
                                                                                                }
                                                                                                {this.state.comp_type[idx] === 'internal' &&
                                                                                                    <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                        <Constant.NPDMyBrandCompetitorChip label='Internal' style={{ width: '100%' }} />
                                                                                                    </Tooltip>
                                                                                                }

                                                                                                {this.state.comp_type[idx] === 'external_with' &&
                                                                                                    <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                        <Constant.NPDCompetitorWithChip label='External' style={{ width: '100%' }} />
                                                                                                    </Tooltip>
                                                                                                }

                                                                                                {this.state.comp_type[idx] === 'external_without' &&
                                                                                                    <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                        <Constant.NPDCompetitorWithOutChip label='No Effects' style={{ width: '100%' }} />
                                                                                                    </Tooltip>
                                                                                                }

                                                                                            </Constant.NPDTableCell>
                                                                                            <Tooltip title={this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']} placement="top" arrow className="itemNameCell">
                                                                                                <Constant.NPDTableCell style={{ width: '15%' }}>
                                                                                                    <Constant.NPDTextField
                                                                                                        label=""
                                                                                                        size="small"
                                                                                                        variant="outlined"
                                                                                                        value={this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']}
                                                                                                        readOnly
                                                                                                        style={{ width: '100%' }}
                                                                                                    />
                                                                                                </Constant.NPDTableCell>
                                                                                            </Tooltip>
                                                                                            <Constant.NPDTableCell style={{ width: '5%' }} id={"histvolcell_" + idx} align="right">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id={"histvol_" + idx}
                                                                                                    size="small"
                                                                                                    variant="outlined"
                                                                                                    value={this.state.vols[idx] && this.state.vols[idx].toLocaleString()}
                                                                                                    style={{ fontWeight: this.state.volume_font_weight[idx], textAlign: 'right' }}
                                                                                                    //onChange={this.handleChangeVolume(idx)}
                                                                                                    onChange={this.handleChangeGeneric(idx, 'Volume', 'OverRide')}
                                                                                                    InputProps={{
                                                                                                        endAdornment:
                                                                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                                                                <Tooltip title="Revert to Current Volume" placement="right-end" >
                                                                                                                    <Constant.NPDIconButton color="primary" aria-label="revert" onClick={this.revertVolumeRow(idx)}>
                                                                                                                        <HistoryRoundedIcon style={{ color: this.state.volume_revert_button_color[idx], fontSize: '1rem' }} />
                                                                                                                    </Constant.NPDIconButton>
                                                                                                                </Tooltip>
                                                                                                            </ThemeProvider>
                                                                                                    }}
                                                                                                    align="right"
                                                                                                //classes={this.state.volume_table_cell_class[idx]}

                                                                                                />
                                                                                            </Constant.NPDTableCell >
                                                                                            <Constant.NPDTableCell style={{ width: '5%' }} id={"histaspcell_" + idx} align="right" size="small">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id="standard-start-adornment-base-{idx}"
                                                                                                    InputProps={{
                                                                                                        startAdornment: <Constant.NPDInputAdornment position="start" style={{ display: 'hidden' }}>{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                                        endAdornment:
                                                                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                                                                <Tooltip title="Revert to Current Base Price" placement="right-end" >
                                                                                                                    <Constant.NPDIconButton color="primary" aria-label="revert" onClick={this.revertASPRow(idx)}>
                                                                                                                        <HistoryRoundedIcon style={{ color: this.state.ASP_revert_button_color[idx], fontSize: '1rem' }} />
                                                                                                                    </Constant.NPDIconButton>
                                                                                                                </Tooltip>
                                                                                                            </ThemeProvider>                                                                                                        
                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    style={{ fontWeight: this.state.ASP_font_weight[idx] }}
                                                                                                    size="small"
                                                                                                    value={this.state.asps[idx]}                                                                                                     
                                                                                                    onChange={this.handleChangeGeneric(idx, 'BasePrice', 'OverRide')}
                                                                                                    type='number'
                                                                                                />
                                                                                            </Constant.NPDTableCell>
                                                                                            <Constant.NPDTableCell style={{ width: '5%' }} id={"histpctcell_" + idx} align="right" size="small">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id="standard-start-adornment-discount-{idx}"
                                                                                                    InputProps={{
                                                                                                        endAdornment:
                                                                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                                                                <span style={{ color: '#4e106f', fontFamily: 'Roboto', fontSize: '1rem', }}>%</span>
                                                                                                                <Tooltip title="Revert to Current Discount %" placement="right-end" >
                                                                                                                    <Constant.NPDIconButton color="primary" aria-label="revert" onClick={this.revertPCTRow(idx)}>
                                                                                                                        <HistoryRoundedIcon style={{ color: this.state.PCT_revert_button_color[idx], fontSize: '1rem' }} />
                                                                                                                    </Constant.NPDIconButton>
                                                                                                                </Tooltip>
                                                                                                            </ThemeProvider>

                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    value={this.state.pcts[idx]}
                                                                                                    type='number'
                                                                                                    //onChange={this.handleChangePCT(idx)}
                                                                                                    onChange={this.handleChangeGeneric(idx, 'DiscPct', 'OverRide')}
                                                                                                    style={{ width: '100%', fontWeight: this.state.PCT_font_weight[idx] }}

                                                                                                />
                                                                                            </Constant.NPDTableCell>
                                                                                            <Constant.NPDTableCell style={{ width: '5%' }} id={"histopromopricecell_" + idx} align="right" size="small">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id="standard-start-adornment-basepct-{idx}"
                                                                                                    InputProps={{
                                                                                                        startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    value={
                                                                                                        (this.state.asps[idx] * (1 - (this.state.pcts[idx] / 100))).toFixed(2)
                                                                                                    }
                                                                                                    readonly
                                                                                                    type='number'

                                                                                                //onChange={this.handleChangeASP(idx)}                                                                            
                                                                                                />
                                                                                            </Constant.NPDTableCell>

                                                                                            <Constant.NPDTableCell style={{ display: this.state.basedisplay, width: '5%' }} align="right" size="small">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id={"newbaseprice" + idx}
                                                                                                    InputProps={{
                                                                                                        startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    //onChange={this.handleChangeSimBasePrice(idx)}
                                                                                                    onChange={this.handleChangeGeneric(idx, 'SimBasePrice', 'SimData')}
                                                                                                    value={this.state.newbases[idx]}
                                                                                                    error={this.state.input_errors_base[idx]}

                                                                                                />
                                                                                            </Constant.NPDTableCell>
                                                                                            <Constant.NPDTableCell style={{ display: this.state.basedisplay, width: '5%' }} align="right" size="small">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id={"basepricepctchg" + idx}
                                                                                                    InputProps={{
                                                                                                        endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                                    }}
                                                                                                    
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    //onChange={this.handleChangeSimBasePriceChange(idx)}
                                                                                                    onChange={this.handleChangeGeneric(idx, 'SimBasePriceChange', 'SimData')}
                                                                                                    value={this.state.newbases_chg[idx]}
                                                                                                    error={this.state.input_errors_base[idx]}

                                                                                                />
                                                                                            </Constant.NPDTableCell>
                                                                                            <Constant.NPDTableCell style={{ display: this.state.promodisplay, width: '5%' }} align="right" size="small">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id={"newdiscpercent" + idx}
                                                                                                    InputProps={{
                                                                                                        endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    //onChange={this.handleChangeSimPctDisc(idx)}
                                                                                                    onChange={this.handleChangeGeneric(idx, 'SimPctDisc', 'SimData')}
                                                                                                    value={this.state.newpcts[idx]}
                                                                                                    error={this.state.input_errors_promo[idx]}


                                                                                                />
                                                                                            </Constant.NPDTableCell>
                                                                                            <Constant.NPDTableCell style={{ display: this.state.promodisplay, width: '5%' }} align="right" size="small">
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id={"newpromoprice" + idx}
                                                                                                    InputProps={{
                                                                                                        startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                                    }}
                                                                                                    type="number"
                                                                                                    step="any"
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    value={this.state.newpcts_chg[idx]}
                                                                                                    //onChange={this.handleChangeSimPctDiscChange(idx)}
                                                                                                    onChange={this.handleChangeGeneric(idx, 'SimPctDiscChange', 'SimData')}
                                                                                                    error={this.state.input_errors_promo[idx]}


                                                                                                />
                                                                                            </Constant.NPDTableCell>

                                                                                            <Constant.NPDTableCell style={{ display: this.state.promodisplayad, width: '10%' }} align="center">
                                                                                                <ThemeProvider theme={Constant.muiTheme}>
                                                                                                    <Select
                                                                                                        id={"addeffectdropdown" + idx}
                                                                                                        options={this.state.adeffect_options}
                                                                                                        placeholder={'Select an ad effect to use'}
                                                                                                        onChange={this.onChangeAdType(idx)}
                                                                                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                                                                                        value={this.state.selected_options_ads[idx]}
                                                                                                        styles={selectColourStyles}
                                                                                                    //disabled={!this.state.add_effects_enabled[idx][0]}
                                                                                                    />
                                                                                                </ThemeProvider>

                                                                                            </Constant.NPDTableCell>

                                                                                            <Constant.NPDTableCell style={{ display: this.state.promodisplayad, width: '10%' }} align="center">
                                                                                                <ThemeProvider theme={Constant.muiTheme}>
                                                                                                    <Select
                                                                                                        id={"promoeffectdropdown" + idx}
                                                                                                        options={this.state.promoeffect_options}
                                                                                                        placeholder={'Select an ad effect to use'}
                                                                                                        onChange={this.onChangePromoType(idx)}
                                                                                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                                                                                        value={this.state.selected_options_promos[idx]}
                                                                                                        styles={selectColourStyles}
                                                                                                    //disabled={!this.state.add_effects_enabled[idx][0]}
                                                                                                    />
                                                                                                </ThemeProvider>

                                                                                            </Constant.NPDTableCell>

                                                                                            <Constant.NPDTableCell style={{ width: '5%' }} align="right">

                                                                                                <Tooltip title="Delete Row" placement="bottom">
                                                                                                    <IconButton
                                                                                                        onClick={this.handleRemoveSpecificRow(idx)}
                                                                                                    >
                                                                                                        <HighlightOffRoundedIcon style={{ color: 'red', fontSize: '1.5rem' }} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Constant.NPDTableCell>
                                                                                        </Constant.NPDTableRow>
                                                                                        <TableRow style={{ height: '5px' }} />
                                                                                    </Fragment>
                                                                                }
                                                                                else {
                                                                                    return null
                                                                                }
                                                                            })}

                                                                            <TableRow style={{ height: '5px' }} />
                                                                        </Fragment>
                                                                    ))}
                                                                    {this.state.bulkedit && this.state.selecteditems.map((item, idx)  => {                                                                     
                                                                        if (!this.state.selecteditems_fromgroup.includes(item)){
                                                                            return <Fragment key={"Fragment_" + idx}>
                                                                                <Constant.NPDTableRow className="tableRow" id="addr0" key={idx} onClick={this.handleSetSelectedRow(idx)} selected={this.state.rowSelectedList[idx]} >
                                                                                    <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>
                                                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                                                            <Checkbox
                                                                                                checked={this.state.bulkedit[idx]}
                                                                                                onChange={this.bulkEditChecks(idx)}
                                                                                                color="secondary"
                                                                                            />
                                                                                        </ThemeProvider>
                                                                                    </Constant.NPDTableCell>

                                                                                    <Constant.NPDTableCell style={{ width: '5%' }}>

                                                                                        {this.state.comp_type[idx] === 'mybrand' &&
                                                                                            <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                <Constant.NPDMyBrandChip label='My Brand' style={{ width: '100%' }} />
                                                                                            </Tooltip>
                                                                                        }
                                                                                        {this.state.comp_type[idx] === 'internal' &&
                                                                                            <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                <Constant.NPDMyBrandCompetitorChip label='Internal' style={{ width: '100%' }} />
                                                                                            </Tooltip>
                                                                                        }

                                                                                        {this.state.comp_type[idx] === 'external_with' &&
                                                                                            <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                <Constant.NPDCompetitorWithChip label='External' style={{ width: '100%' }} />
                                                                                            </Tooltip>
                                                                                        }

                                                                                        {this.state.comp_type[idx] === 'external_without' &&
                                                                                            <Tooltip title={this.state.comp_title[idx]} placement="right-end" >
                                                                                                <Constant.NPDCompetitorWithOutChip label='No Effects' style={{ width: '100%' }} />
                                                                                            </Tooltip>
                                                                                        }

                                                                                    </Constant.NPDTableCell>
                                                                                    <Tooltip title={this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']} placement="top" arrow className="itemNameCell">
                                                                                        <Constant.NPDTableCell style={{ width: '15%' }}>
                                                                                            <Constant.NPDTextField
                                                                                                label=""
                                                                                                size="small"
                                                                                                variant="outlined"
                                                                                                value={this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']}
                                                                                                readOnly
                                                                                                style={{ width: '100%' }}
                                                                                            />
                                                                                        </Constant.NPDTableCell>
                                                                                    </Tooltip>
                                                                                    <Constant.NPDTableCell style={{ width: '5%' }} id={"histvolcell_" + idx} align="right">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id={"histvol_" + idx}
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            value={this.state.vols[idx] && this.state.vols[idx].toLocaleString()}
                                                                                            style={{ fontWeight: this.state.volume_font_weight[idx], textAlign: 'right' }}
                                                                                            //onChange={this.handleChangeVolume(idx)}
                                                                                            onChange={this.handleChangeGeneric(idx, 'Volume', 'OverRide')}
                                                                                            InputProps={{
                                                                                                endAdornment:
                                                                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                                                                        <Tooltip title="Revert to Current Volume" placement="right-end" >
                                                                                                            <Constant.NPDIconButton color="primary" aria-label="revert" onClick={this.revertVolumeRow(idx)}>
                                                                                                                <HistoryRoundedIcon style={{ color: this.state.volume_revert_button_color[idx], fontSize: '1rem' }} />
                                                                                                            </Constant.NPDIconButton>
                                                                                                        </Tooltip>
                                                                                                    </ThemeProvider>
                                                                                            }}
                                                                                            align="right"
                                                                                        //classes={this.state.volume_table_cell_class[idx]}

                                                                                        />
                                                                                    </Constant.NPDTableCell >
                                                                                    <Constant.NPDTableCell style={{ width: '5%' }} id={"histaspcell_" + idx} align="right" size="small">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id="standard-start-adornment-base-{idx}"
                                                                                            InputProps={{
                                                                                                startAdornment: <Constant.NPDInputAdornment position="start" style={{ display: 'hidden' }}>{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                                endAdornment:
                                                                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                                                                        <Tooltip title="Revert to Current Base Price" placement="right-end" >
                                                                                                            <Constant.NPDIconButton color="primary" aria-label="revert" onClick={this.revertASPRow(idx)}>
                                                                                                                <HistoryRoundedIcon style={{ color: this.state.ASP_revert_button_color[idx], fontSize: '1rem' }} />
                                                                                                            </Constant.NPDIconButton>
                                                                                                        </Tooltip>
                                                                                                    </ThemeProvider>
                                                                                                //inputProps: { min: "0.01", max: "100.00", step: "0.01" }
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            style={{ fontWeight: this.state.ASP_font_weight[idx] }}
                                                                                            size="small"
                                                                                            value={this.state.asps[idx]}                                                                                             
                                                                                            onChange={this.handleChangeGeneric(idx, 'BasePrice', 'OverRide')}
                                                                                            type='number'
                                                                                        />
                                                                                    </Constant.NPDTableCell>
                                                                                    <Constant.NPDTableCell style={{ width: '5%' }} id={"histpctcell_" + idx} align="right" size="small">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id="standard-start-adornment-discount-{idx}"
                                                                                            InputProps={{
                                                                                                endAdornment:
                                                                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                                                                        <span style={{ color: '#4e106f', fontFamily: 'Roboto', fontSize: '1rem', }}>%</span>
                                                                                                        <Tooltip title="Revert to Current Discount %" placement="right-end" >
                                                                                                            <Constant.NPDIconButton color="primary" aria-label="revert" onClick={this.revertPCTRow(idx)}>
                                                                                                                <HistoryRoundedIcon style={{ color: this.state.PCT_revert_button_color[idx], fontSize: '1rem' }} />
                                                                                                            </Constant.NPDIconButton>
                                                                                                        </Tooltip>
                                                                                                    </ThemeProvider>

                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            value={this.state.pcts[idx]}
                                                                                            type='number'
                                                                                            //onChange={this.handleChangePCT(idx)}
                                                                                            onChange={this.handleChangeGeneric(idx, 'DiscPct', 'OverRide')}
                                                                                            style={{ width: '100%', fontWeight: this.state.PCT_font_weight[idx] }}

                                                                                        />
                                                                                    </Constant.NPDTableCell>
                                                                                    <Constant.NPDTableCell style={{ width: '5%' }} id={"histopromopricecell_" + idx} align="right" size="small">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id="standard-start-adornment-basepct-{idx}"
                                                                                            InputProps={{
                                                                                                startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            value={
                                                                                                (this.state.asps[idx] * (1 - (this.state.pcts[idx] / 100))).toFixed(2)
                                                                                            }
                                                                                            readonly
                                                                                            type='number'

                                                                                        //onChange={this.handleChangeASP(idx)}                                                                            
                                                                                        />
                                                                                    </Constant.NPDTableCell>

                                                                                    <Constant.NPDTableCell style={{ display: this.state.basedisplay, width: '5%' }} align="right" size="small">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id={"newbaseprice" + idx}
                                                                                            InputProps={{
                                                                                                startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            //onChange={this.handleChangeSimBasePrice(idx)}
                                                                                            onChange={this.handleChangeGeneric(idx, 'SimBasePrice', 'SimData')}
                                                                                            value={this.state.newbases[idx]}
                                                                                            error={this.state.input_errors_base[idx]}

                                                                                        />
                                                                                    </Constant.NPDTableCell>
                                                                                    <Constant.NPDTableCell style={{ display: this.state.basedisplay, width: '5%' }} align="right" size="small">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id={"basepricepctchg" + idx}
                                                                                            InputProps={{
                                                                                                endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            //onChange={this.handleChangeSimBasePriceChange(idx)}
                                                                                            onChange={this.handleChangeGeneric(idx, 'SimBasePriceChange', 'SimData')}
                                                                                            value={this.state.newbases_chg[idx]}
                                                                                            error={this.state.input_errors_base[idx]}

                                                                                        />
                                                                                    </Constant.NPDTableCell>
                                                                                    <Constant.NPDTableCell style={{ display: this.state.promodisplay, width: '5%' }} align="right" size="small">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id={"newdiscpercent" + idx}
                                                                                            InputProps={{
                                                                                                endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                           
                                                                                            //onChange={this.handleChangeSimPctDisc(idx)}
                                                                                            onChange={this.handleChangeGeneric(idx, 'SimPctDisc', 'SimData')}
                                                                                            value={this.state.newpcts[idx]}
                                                                                            error={this.state.input_errors_promo[idx]}


                                                                                        />
                                                                                    </Constant.NPDTableCell>
                                                                                    <Constant.NPDTableCell style={{ display: this.state.promodisplay, width: '5%' }} align="right" size="small">
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id={"newpromoprice" + idx}
                                                                                            InputProps={{
                                                                                                startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            type="number"
                                                                                            step="any"
                                                                                            value={this.state.newpcts_chg[idx]}
                                                                                            //onChange={this.handleChangeSimPctDiscChange(idx)}
                                                                                            onChange={this.handleChangeGeneric(idx, 'SimPctDiscChange', 'SimData')}
                                                                                            error={this.state.input_errors_promo[idx]}


                                                                                        />
                                                                                    </Constant.NPDTableCell>

                                                                                    <Constant.NPDTableCell style={{ display: this.state.promodisplayad, width: '10%' }} align="center">
                                                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                                                            <Select
                                                                                                id={"addeffectdropdown" + idx}
                                                                                                options={this.state.adeffect_options}
                                                                                                placeholder={'Select an ad effect to use'}
                                                                                                onChange={this.onChangeAdType(idx)}
                                                                                                components={{ DropdownIndicator, IndicatorSeparator }}
                                                                                                value={this.state.selected_options_ads[idx]}
                                                                                                styles={selectColourStyles}
                                                                                            //disabled={!this.state.add_effects_enabled[idx][0]}
                                                                                            />
                                                                                        </ThemeProvider>

                                                                                    </Constant.NPDTableCell>

                                                                                    <Constant.NPDTableCell style={{ display: this.state.promodisplayad, width: '10%' }} align="center">
                                                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                                                            <Select
                                                                                                id={"promoeffectdropdown" + idx}
                                                                                                options={this.state.promoeffect_options}
                                                                                                placeholder={'Select an ad effect to use'}
                                                                                                onChange={this.onChangePromoType(idx)}
                                                                                                components={{ DropdownIndicator, IndicatorSeparator }}
                                                                                                value={this.state.selected_options_promos[idx]}
                                                                                                styles={selectColourStyles}
                                                                                            //disabled={!this.state.add_effects_enabled[idx][0]}
                                                                                            />
                                                                                        </ThemeProvider>

                                                                                    </Constant.NPDTableCell>

                                                                                    <Constant.NPDTableCell style={{ width: '5%' }} align="right">

                                                                                        <Tooltip title="Delete Row" placement="bottom">
                                                                                            <IconButton
                                                                                                onClick={this.handleRemoveSpecificRow(idx)}
                                                                                            >
                                                                                                <HighlightOffRoundedIcon style={{ color: 'red', fontSize: '1.5rem' }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Constant.NPDTableCell>
                                                                                </Constant.NPDTableRow>
                                                                                <TableRow style={{ height: '5px' }} />
                                                                            </Fragment>
                                                                        }
                                                                        else {
                                                                            return null
                                                                        }                                                                        
                                                                    })}
                                                                </TableBody>
                                                            </Constant.NPDTable>
                                                        </div>
                                                        <span id='item_error' style={{ color: '#822433', display: this.state.item_error_display }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {this.state.item_error_text}</span>
                                                        <br />


                                                        {this.state.sim_inputs_missing ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Inputs to the Simulation are missing.  Please correct highlighted fields<br /><br /></span> : <span></span>}
                                                        <Constant.NPDMyBrandChip_Label label='My Brand' />
                                                        <Constant.NPDMyBrandCompetitorChip_Label label='Internal Competitor' />
                                                        <Constant.NPDCompetitorWithChip_Label label='External Competitor with Competitive Effects' />
                                                        <Constant.NPDCompetitorWithOutChip_Label label='External Competitor without Competitive Effects' />
                                                    </Paper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        : <div></div>
                                    }
                                    {this.state.activeStep === 3 ? <div style={{ marginLeft: '40px', marginRight: '40px' }}>
                                        <div style={dropdownInput} className="col-sm-12">
                                            <h3>Create a new simulation</h3>
                                            <p>Add Financials</p>
                                            <span>Please note, not all items need to have financial information populated.  However, if an item does have financial information entered, all four fields must be populated.</span>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.openBulkEditModal}
                                            onClose={this.hideBulkEditModal}
                                        >
                                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                                <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                                                    <h5>Bulk Edit Items</h5>
                                                    <h6>Items({this.state.bulkedit.reduce(function (total, num) { return total + num })})</h6>
                                                    {this.state.bulkedit.map((bulkeditval, idx) => {
                                                        if (bulkeditval == true) {
                                                            return <span key={idx}>{this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']}<br /></span>
                                                        }
                                                    })}
                                                </div>
                                                <div>
                                                    <span className="formfieldtitle" >Cost Per Promoted Unit</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditCPPU"
                                                        InputProps={{
                                                            startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditFinancials(0)}
                                                    />
                                                    <br />
                                                    <span className="formfieldtitle">List Price</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditbaseListPrice"
                                                        InputProps={{
                                                            startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditFinancials(1)}
                                                    />
                                                    <br />
                                                    <span className="formfieldtitle" >Gross Margin</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditgrossmargin"
                                                        InputProps={{
                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditFinancials(2)}
                                                    />
                                                    <br />
                                                    <span className="formfieldtitle">Retail Margin</span>
                                                    <br />
                                                    <Constant.NPDTextField
                                                        label=""
                                                        id="bulkeditretailmargin"
                                                        InputProps={{
                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChangeBulkEditFinancials(3)}
                                                    />
                                                </div>
                                                <br />
                                                <ThemeProvider theme={Constant.muiTheme}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.applyBulkEditChangesFinancials}
                                                        className={classes.button}
                                                    >
                                                        Apply Bulk Edit
                                                    </Button>
                                                    <span style={{ padding: '10px' }} />
                                                    <Button
                                                        variant="contained"
                                                        color="white"
                                                        onClick={this.hideBulkEditModal}
                                                        className={classes.button}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </ThemeProvider>
                                            </div>
                                        </Modal>

                                        <div className="" style={{ width: '100%', position: 'relative', left: '0vw', paddingLeft: '0px' }}>
                                            <div className="row clearfix">
                                                <div className="col-md-12 column ">

                                                    <Paper className='test' style={{ padding: '10px' }}>
                                                        <div className="col-md-12 column " align="center">
                                                            <h5 >Items</h5>
                                                        </div>
                                                        <div className="col-md-12 column actionbarsimentry">
                                                            <div className="col-md-12 column ">
                                                                <ThemeProvider theme={Constant.muiTheme}>
                                                                    <Tooltip title="Use this to edit multiple items at once" placement="bottom">
                                                                        <Button
                                                                            //style={{ color: '#FFF', borderRadius: '5px 0px 0px 5px', backgroundColor: '#FFF', borderColor: '#FAFAFA', borderWidth: '1px' }}
                                                                            onClick={this.showBulkEdit}
                                                                            color="primary"
                                                                            variant="contained"
                                                                            className={classes.button}
                                                                        >
                                                                            Bulk Edit
                                                                    </Button>
                                                                    </Tooltip>
                                                                </ThemeProvider>
                                                            </div>
                                                            {this.state.bulkeditdisplay === 'table-cell' ?
                                                                <div style={{ width: '50%', backgroundColor: "#FFF" }}>
                                                                    <Select
                                                                        id={"bulkedititemgroups"}
                                                                        options={(this.state.selectedgroups || []).map(group => {
                                                                            return { 'label': group.groupname, 'value': group.item_vals }
                                                                        })}
                                                                        placeholder={'Select Item Groups to Bulk Edit'}
                                                                        onChange={(selectedOptions) => this.checkItemGroups(selectedOptions)}
                                                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                                                        isMulti
                                                                        styles={selectColourStyles}
                                                                    />
                                                                </div>
                                                                :
                                                                <div style={{ width: '50%', backgroundColor: "#FFF" }}>
                                                                    <Select
                                                                        id={"filteritemgroups"}
                                                                        options={(this.state.selectedgroups || []).map(group => {
                                                                            return { 'label': group.groupname, 'value': group.item_vals }
                                                                        })}
                                                                        placeholder={'Select Item Groups to filter to'}
                                                                        onChange={(selectedOptions) => filterItemGroups(selectedOptions)}
                                                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                                                        isMulti
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        styles={selectColourStyles}
                                                                    />
                                                                </div>
                                                            }

                                                        </div>
                                                        <div className='row col-sm-12' style={{ maxHeight: '500px', overflowY: 'auto', zIndex:0 }}>
                                                            <Constant.NPDTable id="tab_logic">
                                                            <TableHead style={{ width: '100%', padding: '10px' }} className='sticky'>
                                                                <Constant.NPDTableRow>
                                                                    <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>
                                                                        <ThemeProvider theme={Constant.muiTheme}>
                                                                            <Checkbox
                                                                                checked={this.state.selectallbulkedit}
                                                                                onChange={this.selectAllBulkEdit}
                                                                                color="secondary"
                                                                            />
                                                                        </ThemeProvider>
                                                                    </Constant.NPDTableCell>
                                                                    <Constant.NPDTableCell style={{ width: '15%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Item </Constant.NPDTableCell>
                                                                    <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Cost Per Promoted Unit </Constant.NPDTableCell>
                                                                    <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> List Price </Constant.NPDTableCell>
                                                                    <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Gross Margin </Constant.NPDTableCell>
                                                                    <Constant.NPDTableCell style={{ width: '5%', fontSize: '10px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px' }}> Retail Margin </Constant.NPDTableCell>
                                                                </Constant.NPDTableRow>
                                                            </TableHead>

                                                            <TableBody style={{ padding: '25px' }}>
                                                            <Constant.NPDTableRow style={{ height: '5px' }} />

                                                                {this.state.bulkedit && this.state.selectedgroups.map((group, gpidx) => (
                                                                    <Fragment key={"GroupFragment_" + gpidx}>
                                                                        <Constant.NPDTableRow
                                                                            className="tableRow groupRow"
                                                                            id="addr0"
                                                                            key={gpidx}
                                                                        //selected={this.state.rowSelectedList[idx]}
                                                                        >
                                                                            <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>

                                                                            </Constant.NPDTableCell>

                                                                            <Constant.NPDTableCell style={{ width: '5%' }}>
                                                                                <Tooltip title="Click to See Item Details" placement="bottom">
                                                                                    <IconButton
                                                                                        onClick={(e, idx) => this.expandGroup('test', gpidx)}
                                                                                    >
                                                                                        <ExpandMoreIcon style={{ color: '#8e908f', fontSize: '1.5rem' }} />
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                                <Tooltip title={group.groupname} placement="right-end" >
                                                                                    <Constant.NPDItemGroupChip
                                                                                        label={group.groupname}
                                                                                        style={{ width: '65%' }}
                                                                                    />
                                                                                </Tooltip>

                                                                            </Constant.NPDTableCell>
                                                                            

                                                                            <Constant.NPDTableCell >
                                                                                <Constant.NPDTextField
                                                                                    label=""
                                                                                    id={"FinancialCPU_Group_" + gpidx}
                                                                                    InputProps={{
                                                                                        startAdornment: <Constant.NPDInputAdornment position="start" >{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                        //inputProps: { min: "0.01", max: "100.00", step: "0.01" }
                                                                                    }}
                                                                                    size="small"
                                                                                    variant="outlined"
                                                                                    disabled={false}
                                                                                    type='number'
                                                                                    //value={this.state.financials[idx][0]}
                                                                                    onChange={this.handleChangeGeneric(gpidx, 'Financials_Group', 0)}
                                                                                    //error={this.state.input_errors_financials[idx][0]}
                                                                                />
                                                                            </Constant.NPDTableCell>
                                                                            <Constant.NPDTableCell >
                                                                                <Constant.NPDTextField
                                                                                    label=""
                                                                                    id={"FinancialListPrice_Group_" + gpidx}
                                                                                    InputProps={{
                                                                                        startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                        //inputProps: { min: "0.01", max: "100.00", step: "0.01" }
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    disabled={false}
                                                                                    type='number'
                                                                                    //value={this.state.financials[idx][1]}
                                                                                    onChange={this.handleChangeGeneric(gpidx, 'Financials_Group', 1)}
                                                                                    //error={this.state.input_errors_financials[idx][1]}
                                                                                />

                                                                            </Constant.NPDTableCell>
                                                                            <Constant.NPDTableCell>
                                                                                <Constant.NPDTextField
                                                                                    label=""
                                                                                    id={"FinancialItemsGrossMargin_Group_" + gpidx}
                                                                                    InputProps={{
                                                                                        endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    disabled={false}
                                                                                    type='number'
                                                                                    //value={this.state.financials[idx][2]}
                                                                                    onChange={this.handleChangeGeneric(gpidx, 'Financials_Group', 2)}
                                                                                    //error={this.state.input_errors_financials[idx][2]}

                                                                                />
                                                                            </Constant.NPDTableCell>
                                                                            <Constant.NPDTableCell>
                                                                                <Constant.NPDTextField
                                                                                    label=""
                                                                                    id={"FinancialItemsRetailsMargin_Group_" + gpidx}
                                                                                    InputProps={{
                                                                                        endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                        //inputProps: { min: "1", max: "100", step: "1" }
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    disabled={false}
                                                                                    type='number'
                                                                                    //value={this.state.financials[idx][3]}
                                                                                    onChange={this.handleChangeGeneric(gpidx, 'Financials_Group', 3)}
                                                                                    //error={this.state.input_errors_financials[idx][3]}
                                                                                />
                                                                            </Constant.NPDTableCell>

                                                                        </Constant.NPDTableRow>


                                                                        {this.state.bulkedit && this.state.selecteditems.map((item, idx) => {

                                                                            if (group.item_vals.includes(parseInt(item))) {
                                                                                return <Fragment key={"Fragment_" + idx}>
                                                                                    <Constant.NPDTableRow className={"tableRow " + group.groupname} style={{ display: 'none' }} id="addr0" key={idx} onClick={this.handleSetSelectedRow(idx)} selected={this.state.rowSelectedList[idx]} >
                                                                                        <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>
                                                                                            <ThemeProvider theme={Constant.muiTheme}>
                                                                                                <Checkbox
                                                                                                    checked={this.state.bulkedit[idx]}
                                                                                                    onChange={this.bulkEditChecks(idx)}
                                                                                                    color="secondary"
                                                                                                />
                                                                                            </ThemeProvider>
                                                                                        </Constant.NPDTableCell>
                                                                                        <Tooltip title={"Item Selected for row " + idx + 1 + ": " + this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']} placement="top" arrow >
                                                                                            <Constant.NPDTableCell>
                                                                                                <Constant.NPDTextField
                                                                                                    label=""
                                                                                                    id="FinancialItems_{idx}"
                                                                                                    size="small"
                                                                                                    variant="outlined"
                                                                                                    disabled={false}
                                                                                                    value={this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']}
                                                                                                    style={{ width: '100%' }}
                                                                                                />
                                                                                            </Constant.NPDTableCell>
                                                                                        </Tooltip>
                                                                                        <Constant.NPDTableCell >
                                                                                            <Constant.NPDTextField
                                                                                                label=""
                                                                                                id={"FinancialCPU_" + idx}
                                                                                                InputProps={{
                                                                                                    startAdornment: <Constant.NPDInputAdornment position="start" >{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                                    //inputProps: { min: "0.01", max: "100.00", step: "0.01" }
                                                                                                }}
                                                                                                size="small"
                                                                                                variant="outlined"
                                                                                                disabled={false}
                                                                                                type='number'
                                                                                                value={this.state.financials[idx][0]}
                                                                                                onChange={this.handleChangeGeneric(idx, 'Financials', 0)}
                                                                                                error={this.state.input_errors_financials[idx][0]}
                                                                                            />
                                                                                        </Constant.NPDTableCell>
                                                                                        <Constant.NPDTableCell >
                                                                                            <Constant.NPDTextField
                                                                                                label=""
                                                                                                id={"FinancialListPrice_" + idx}
                                                                                                InputProps={{
                                                                                                    startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                                    //inputProps: { min: "0.01", max: "100.00", step: "0.01" }
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                disabled={false}
                                                                                                type='number'
                                                                                                value={this.state.financials[idx][1]}
                                                                                                onChange={this.handleChangeGeneric(idx, 'Financials', 1)}
                                                                                                error={this.state.input_errors_financials[idx][1]}
                                                                                            />

                                                                                        </Constant.NPDTableCell>
                                                                                        <Constant.NPDTableCell>
                                                                                            <Constant.NPDTextField
                                                                                                label=""
                                                                                                id={"FinancialItemsGrossMargin_" + idx}
                                                                                                InputProps={{
                                                                                                    endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                disabled={false}
                                                                                                type='number'
                                                                                                value={this.state.financials[idx][2]}
                                                                                                onChange={this.handleChangeGeneric(idx, 'Financials', 2)}
                                                                                                error={this.state.input_errors_financials[idx][2]}

                                                                                            />
                                                                                        </Constant.NPDTableCell>
                                                                                        <Constant.NPDTableCell>
                                                                                            <Constant.NPDTextField
                                                                                                label=""
                                                                                                id={"FinancialItemsRetailsMargin_" + idx}
                                                                                                InputProps={{
                                                                                                    endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                                    //inputProps: { min: "1", max: "100", step: "1" }
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                disabled={false}
                                                                                                type='number'
                                                                                                value={this.state.financials[idx][3]}
                                                                                                onChange={this.handleChangeGeneric(idx, 'Financials', 3)}
                                                                                                error={this.state.input_errors_financials[idx][3]}
                                                                                            />
                                                                                        </Constant.NPDTableCell>

                                                                                    </Constant.NPDTableRow>
                                                                                    <Constant.NPDTableRow style={{ height: '5px' }} />
                                                                                </Fragment>
                                                                            }
                                                                            else {
                                                                                return null
                                                                            }
                                                                        })}

                                                                        <TableRow style={{ height: '5px' }} />
                                                                    </Fragment>
                                                                ))}

                                                                {this.state.selecteditems.map((item, idx) => {
                                                                    if (!this.state.selecteditems_fromgroup.includes(item)) {
                                                                        return <Fragment key={"Fragment_" + idx}>
                                                                            <Constant.NPDTableRow className="tableRow" id="addr0" key={idx} onClick={this.handleSetSelectedRow(idx)} selected={this.state.rowSelectedList[idx]} >
                                                                                <Constant.NPDTableCell align='center' style={{ width: '5%', fontSize: '15px', fontWeight: 'bold', color: '#565A5C', lineHeight: '10px', display: this.state.bulkeditdisplay }}>
                                                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                                                        <Checkbox
                                                                                            checked={this.state.bulkedit[idx]}
                                                                                            onChange={this.bulkEditChecks(idx)}
                                                                                            color="secondary"
                                                                                        />
                                                                                    </ThemeProvider>
                                                                                </Constant.NPDTableCell>
                                                                                <Tooltip title={"Item Selected for row " + idx + 1 + ": " + this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']} placement="top" arrow >
                                                                                    <Constant.NPDTableCell>
                                                                                        <Constant.NPDTextField
                                                                                            label=""
                                                                                            id="FinancialItems_{idx}"
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            disabled={false}
                                                                                            value={this.state.items_lookup_object[this.state.selecteditems[idx]]['proddesc']}
                                                                                            style={{ width: '100%' }}
                                                                                        />
                                                                                    </Constant.NPDTableCell>
                                                                                </Tooltip>
                                                                                <Constant.NPDTableCell >
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"FinancialCPU_" + idx}
                                                                                        InputProps={{
                                                                                            startAdornment: <Constant.NPDInputAdornment position="start" >{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                            //inputProps: { min: "0.01", max: "100.00", step: "0.01" }
                                                                                        }}
                                                                                        size="small"
                                                                                        variant="outlined"
                                                                                        disabled={false}
                                                                                        type='number'
                                                                                        value={this.state.financials[idx][0]}
                                                                                        onChange={this.handleChangeGeneric(idx, 'Financials', 0)}
                                                                                        error={this.state.input_errors_financials[idx][0]}
                                                                                    />
                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell >
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"FinancialListPrice_" + idx}
                                                                                        InputProps={{
                                                                                            startAdornment: <Constant.NPDInputAdornment position="start">{this.state.currencysymbol}</Constant.NPDInputAdornment>,
                                                                                            //inputProps: { min: "0.01", max: "100.00", step: "0.01" }
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        disabled={false}
                                                                                        type='number'
                                                                                        value={this.state.financials[idx][1]}
                                                                                        onChange={this.handleChangeGeneric(idx, 'Financials', 1)}
                                                                                        error={this.state.input_errors_financials[idx][1]}
                                                                                    />

                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell>
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"FinancialItemsGrossMargin_" + idx}
                                                                                        InputProps={{
                                                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        disabled={false}
                                                                                        type='number'
                                                                                        value={this.state.financials[idx][2]}
                                                                                        onChange={this.handleChangeGeneric(idx, 'Financials', 2)}
                                                                                        error={this.state.input_errors_financials[idx][2]}

                                                                                    />
                                                                                </Constant.NPDTableCell>
                                                                                <Constant.NPDTableCell>
                                                                                    <Constant.NPDTextField
                                                                                        label=""
                                                                                        id={"FinancialItemsRetailsMargin_" + idx}
                                                                                        InputProps={{
                                                                                            endAdornment: <Constant.NPDInputAdornment position="end">%</Constant.NPDInputAdornment>,
                                                                                            //inputProps: { min: "1", max: "100", step: "1" }
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        disabled={false}
                                                                                        type='number'
                                                                                        value={this.state.financials[idx][3]}
                                                                                        onChange={this.handleChangeGeneric(idx, 'Financials', 3)}
                                                                                        error={this.state.input_errors_financials[idx][3]}
                                                                                    />
                                                                                </Constant.NPDTableCell>

                                                                            </Constant.NPDTableRow>
                                                                            <Constant.NPDTableRow style={{ height: '5px' }} />
                                                                        </Fragment>
                                                                    }
                                                                    else {
                                                                        return null
                                                                    }
                                                                })}
                                                                
                                                            </TableBody>
                                                            </Constant.NPDTable>
                                                        </div>

                                                        {this.state.financials_errors ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {this.state.financials_errors_text}<br /><br /></span> : <span></span>}
                                                        <br />
                                                    </Paper>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                        : <div></div>
                                    }
                                    <br />
                                    <div style={{ marginLeft: '40px', marginRight: '40px' }} className="row">
                                        <ThemeProvider theme={Constant.muiTheme}>

                                            <Button style={{color: 'white' }} disabled={this.state.activeStep === 0} onClick={handleBack} className={classes.button}>
                                                Back
                                            </Button>
                                            {isStepOptional(this.state.activeStep) && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSkip}
                                                    className={classes.button}
                                                >
                                                    Skip
                                                </Button>
                                            )}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                id="runbutton"
                                                onClick={
                                                    this.state.bulkeditdisplay === 'table-cell' && this.state.activeStep === 2 ? this.showBulkEditModal :
                                                        this.state.bulkeditdisplay === 'table-cell' && this.state.activeStep === steps.length - 1 ? this.showBulkEditModal :
                                                            handleNext}
                                                className={classes.button}
                                            >
                                                {this.state.bulkeditdisplay === 'table-cell' && this.state.activeStep === 2 ? 'Bulk Edit Items' :
                                                    this.state.bulkeditdisplay === 'table-cell' && this.state.activeStep === steps.length - 1 ? 'Bulk Edit Items' :
                                                        this.state.bulkeditdisplay !== 'table-cell' && this.state.activeStep === steps.length - 1 ? 'Finish' :
                                                            'Next'
                                                }
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                    <div style={{ height: '250px' }} />
                                </div>
                            )}
                    </div>
                </div>
            </Layout>
        )
    }
}