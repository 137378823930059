import CreateWeeksNew from '../../Utilities/CreateWeeks'
import removeItemOnce from '../../Utilities/RemoveItemOnce'
import replaceEmptyArrays from '../../Utilities/RemoveItemOnce'
import * as Constant from '../../../../constants'

export default async function changeSelectedItemMulti(selectedOptions, position) {
    if (selectedOptions && selectedOptions.length > 0) {
        this.setState({ isLoaded: false })

        let reportid = this.state.reportsettings.report_id
        var filters_raw = this.state.filter_object
        let items = selectedOptions.map(option => this.state.item_lookup_reverse[option.value] || 0).filter(value => value > 0)
        let weeks_info = CreateWeeksNew(this.state.filter_object, this.state.times_data, this.state.datasettimeagg)

        let groupeddates = weeks_info['groupeddates']
        // clean up filters
        // Use the Weeks Info to Pass the Curr Range, and Prior Range to the API
        // Rework values so if just an array of All make empty
        // Rework Attributes
        let apifilterattrs = Object.keys(filters_raw['attributes']).map(attr => {
            return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All')]
        })

        let filters = {}
        filters['brands'] = removeItemOnce(filters_raw['brands'], 'All')
        filters['exclude_brands'] = this.state.exclude_array[0]
        filters['items'] = removeItemOnce(filters_raw['items'], 'All')
        filters['exclude_items'] = this.state.exclude_array[1]
        filters['week_aggregation'] = filters_raw['numweeks']
        filters['asp_range'] = filters_raw['asprange'] || []
        filters['date_range'] = [weeks_info.startdate, weeks_info.enddate]
        filters['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
        filters['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
        filters['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
        filters['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
        filters['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
        filters['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
        filters['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
        filters['attributes'] = apifilterattrs
        filters['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
        filters['price_bands_defs'] = this.state.priceBandOptions
        filters['selected_items_weeklyviews'] = items
        filters['channels'] = removeItemOnce(filters_raw['channels'], 'All')

        let url_items_weekly = Constant.baseapiurl + "ReportData/Filtered/ItemsWeekly/" + reportid + this.state.extraparam
        let my_results = await this.FetchDataWithErrorHandling([
            { 'url': url_items_weekly, 'method': 'POST', 'body': filters },
        ], 'ChangeSelectedItemMulti.js changeSelectedItemMulti()', 'Item Multiselect change')
        let itemweekly_obj = my_results['ReturnList'][0]
        let item_data_weekly = itemweekly_obj['items_data_weekly']

        let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
            return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
        })

        var distinctitems_forweeklyitemviews_set = Array.from(new Set(this.state.distinctitems))
        distinctitems_forweeklyitemviews_set.concat(['Total Category', 'Total Selection']).forEach(item => {
            if (!item_data_weekly[item]) {
                item_data_weekly[item] = {}
            }
        })

        distinctitems_forweeklyitemviews_set.concat(['Total Category', 'Total Selection']).forEach(item => {
            let weeks = Object.keys(item_data_weekly[item] || {})
            groupeddates.forEach(date => {
                if (!weeks.includes(date)) {
                    item_data_weekly[item][date] = {
                        "baseunits": 0
                        , "incrementalunits": 0
                        , "promotedunits": 0
                        , "nonpromotedunits": 0
                        , "subsidizedbaseunits": 0
                        , "unsubsidizedbaseunits": 0
                        , "revopportunity": 0
                        , "basedollars": 0
                        , "incrementaldollars": 0
                        , "nonpromoteddollars": 0
                        , "unsubsidizedbasedollars": 0
                        , "subsidizedbasedollars": 0
                        , "promoteddollars": 0
                        , "discountdollars": 0
                        , "totalunits": 0
                        , "totalunits_withoutno": 0
                        , "totaldollars": 0
                        , "weightedpctdisc": 0
                        , "weightedpctdisc_withoutno": 0
                        , "weightedbp": 0
                        , "weightedasp": 0
                        , "carrieddoors": 0
                        , "totaldoors_s1": 0
                        , "totaldoors_s2": 0
                        , "totaldoors_s1_segment": 0
                        , "totaldoors_s2_segment": 0
                    }
                }
            })
        })

        this.setState({ selectedItems: selectedOptions.map(option => option.value), showSnackbar_changebrands: true, item_data_weekly, isLoaded: true })
    } else {
        this.setState({
            selectedItems: [], item_data_weekly: {}
        })
    }
}
