import React, { Component } from 'react';
import { Layout } from '../Layout';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Radio from '@material-ui/core/Radio';
import '../styles/custom.css'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Constant from '../constants'

export class CompareSimsPage extends Component {
    static displayName = CompareSimsPage.name;

    constructor(props) {
        super(props);
        this.state = {
            sims: [],
            sort_order: [],
            results: {},
            results_status: {},
            results_names: {},
            results_dates: {},
            results_types: {},
            results_brands: {},
            results_brands_string: {},
            results_volume: {},
            results_base_volume: {},
            results_simulation: {},
            results_simulation_items: {},
            results_volume_change_base: {},
            results_volume_change_disc: {},
            results_base_volume_base: {},
            results_base_volume_disc: {},
            //Sim Object
            sim_dict: {},
            //styles
            sim_styles: {},
            sim_graph_cols: {},
            lowest_vol_sim_id: null,
            highest_vol_sim_id: null,
            sim_style_vol: {},
            sim_style_vol_pct: {},
            sim_style_vol_pct_base: {},
            sim_style_vol_pct_disc: {},
            lowest_basevol_sim_id: null,
            highest_basevol_sim_id: null,
            sim_style_basevol: {},
            lowest_discvol_sim_id: null,
            highest_discvol_sim_id: null,
            sim_style_discvol: {},
            showBackdrop_CompareSims: true,
            volchangeselected: true,
            dolchangeselected: false,
            discdolselected: false,
            pbrselected: false,
            dataset_info_items: [],
            runsimerror: false,
        }
    }

    

    async componentDidMount() {
        const findids = () => {
            var url = window.location.href;
            var temp = url.split("/");
            var i = unescape(temp[5]);
            return i.split('_');
        }

        const reducer = (accumulator, currentValue) => accumulator + currentValue;

        var simids = findids()
        var results_simulation_items_init = {}
        var sim_styles = {}
        var sim_graph_cols = {} 
        var resultmap = {}
        var resultmap_names = {}
        var resultmap_dates = {}
        var resultmap_types = {}
        var resultmap_volume_change = {}
        var resultmap_base_volume = {}
        var resultmap_brands = {}
        var resultmap_brands_string = {}
        
        var numsims = simids.length

        this.setState({ sims: simids })
       
        simids.forEach(async (simid,idx) => {

            results_simulation_items_init[simid] = [];
            //this.setState({ results_simulation_items: results_simulation_items_init })

            if (idx === 0) {
                sim_styles[simid] = 'simcomp_start'
                sim_graph_cols[simid] = '#ff8b6a'
                //this.setState({ sim_styles, sim_graph_cols })
            }
            else {
                sim_styles[simid] = 'simcomp_normal'
                sim_graph_cols[simid] = '#7d32ba'
                //this.setState({ sim_styles, sim_graph_cols })
            }

            var sim_dict = this.state.sim_dict            
            var numweeks = 0
            var coverage = 1
            var simname = ""
            var simtype = ""
            var discs = []
            var bps = []
            var itemids = []
            var overrideflags = []
            var overridebps = []
            var overridedisc = []
            var overridebasevol = []
            var startdate = ""
            var enddate = ""
            var dataset = 0
            var geogid = [0]

            sim_dict[simid] = {}

            let simresp = await fetch(Constant.baseapiurl + "SimSettings/" + simid)
            if (simresp.status === 200) {
                var simdata = await simresp.json()
                numweeks = Math.round((new Date(simdata.sim_start.substring(0, 4), simdata.sim_start.substring(5, 7), simdata.sim_start.substring(8, 10)) - new Date(simdata.sim_end.substring(0, 4), simdata.sim_end.substring(5, 7), simdata.sim_end.substring(8, 10))) / (7 * 24 * 60 * 60 * 1000)) * -1
                coverage = simdata.coverage
                simname = simdata.sim_name
                simtype = simdata.sim_type.substring(0, 1).toUpperCase() + simdata.sim_type.substring(1)
                discs = simdata.items.map(item => item.sim_discount_pct)
                bps = simdata.items.map(item => item.sim_base_price)
                itemids = simdata.items.map(item => item.prod_id)
                overrideflags = simdata.items.map(item => item.override_base_flag)
                overridebps = simdata.items.map(item => item.override_base_price)
                overridedisc = simdata.items.map(item => item.override_base_discount)
                overridebasevol = simdata.items.map(item => item.override_base_volume)
                startdate = simdata.sim_start.substring(0, 10)
                enddate = simdata.sim_end.substring(0, 10)
                dataset = simdata.dataset_id
                geogid = simdata.geogid
            }
            else {
                simdata = {}
            }

            resultmap_names[simid] = simname
            resultmap_dates[simid] = startdate + ' to ' + enddate
            resultmap_types[simid] = simtype
            sim_dict[simid]['numweeks'] = numweeks
            sim_dict[simid]['coverage'] = coverage
            sim_dict[simid]['name'] = simname
            sim_dict[simid]['type'] = simtype
            sim_dict[simid]['sim_discounts'] = discs
            sim_dict[simid]['sim_bps'] = bps
            sim_dict[simid]['item_ids'] = itemids
            sim_dict[simid]['override_flags'] = overrideflags
            sim_dict[simid]['override_base_price'] = overridebps
            sim_dict[simid]['override_base_discount'] = overridedisc
            sim_dict[simid]['override_base_volume'] = overridebasevol


            this.setState({
                results_simulation_items: results_simulation_items_init,
                sim_styles,
                sim_graph_cols, 
                results_names: resultmap_names,
                results_dates: resultmap_dates,
                results_types: resultmap_types,
                sim_dict: sim_dict,
                result_datasetid: dataset,
                result_geogid: geogid
            });

            //Get Dataset Items....
            var itemsdata = []

            let datasetresp = await fetch(Constant.baseapiurl + "Datasets/" + dataset)
            if (datasetresp.status === 200) {
                var datasetdata = await datasetresp.json()
                this.setState({ datasetdata: datasetdata })
            }

            let geosresp = await fetch(Constant.baseapiurl + "Datasets/" + dataset + "/Geos")
            if (datasetresp.status === 200) {
                var geosdata = await geosresp.json()
                var geolookup = {}
                geosdata.forEach(geo => geolookup[geo.geogid] = geo.geogdesc)

                this.setState({ geosdata: geosdata, geolookup})
            }


            
            let itemsresp = await fetch(Constant.baseapiurl + "Datasets/" + dataset + "/Items?geos=" + geogid[0])
            if (itemsresp.status === 200) {
                itemsdata = await itemsresp.json()

            }
            else {

            }

            this.setState({ dataset_info_items: itemsdata })

            //Run Simulation
            bps = []
            var vol = []
            var change = []
            simdata = {}
            var simresultresp = await fetch(Constant.baseapiurl + "Simulation/" + simid)
            if (simresultresp.status === 200) {
                let simdata_text = await simresultresp.text()
                let simdata_text_final = simdata_text.replace(/NaN/g, "0").replace(/Infinity/g, "0")
                if (simdata_text_final.length > 0) {
                    var resultformatted = JSON.parse(simdata_text_final)
                    simdata = resultformatted
                    console.log(simdata)
                    var itemstouse = []
                    Object.keys(resultformatted).forEach(item => {
                        if (!['config', 'coverage', 'item_list'].includes(item)) {
                            itemstouse.push(item)
                        }
                    })

                    console.log(this.state.sim_dict[simid]['item_ids'])
                    let simitems = this.state.sim_dict[simid]['item_ids'].map(item => String(item))

                    console.log(simitems)

                    resultmap_volume_change[simid] = simitems.map(item => {

                        if (((resultformatted[item] || {})['item_type'] || {})['from_sim']) {
                            return (resultformatted[item]['starting_base_volume'] + resultformatted[item]['covid_base_volume'] + resultformatted[item]['holiday_base_volume']) * resultformatted[item]['total'] * numweeks * coverage
                        }
                        else {
                            return 0
                        }

                    }).reduce(reducer)

                    resultmap_base_volume[simid] = simitems.map(item => {
                        if (((resultformatted[item] || {})['item_type'] || {})['from_sim']) {
                            return (resultformatted[item]['starting_base_volume'] + resultformatted[item]['covid_base_volume'] + resultformatted[item]['holiday_base_volume']) * numweeks 
                        }
                        else {
                            return 0
                        }
                    }).reduce(reducer)

                    resultmap_brands[simid] = simitems.map(item => {
                        if (((resultformatted[item] || {})['item_type'] || {})['from_sim']) {
                            return resultformatted[item]['brand']
                        }
                        else {
                            return ""
                        }
                    })
                    

                    simitems.forEach(item => {
                        if (((resultformatted[item] || {})['item_type'] || {})['from_sim']) {
                            //Look to the sim dict and see if the override is set...if so, use that as the baseprice....
                            console.log(this.state.sim_dict[simid])
                            console.log(item)
                            //find where the item is in the simlist
                            console.log(this.state.sim_dict[simid]['item_ids'])
                            console.log(this.state.sim_dict[simid]['item_ids'].indexOf(parseInt(item)))
                            let indextouse = this.state.sim_dict[simid]['item_ids'].indexOf(parseInt(item))
                            console.log(this.state.sim_dict[simid]['override_flags'][indextouse])
                            let overrideset = this.state.sim_dict[simid]['override_flags'][indextouse]
                            if (overrideset && this.state.sim_dict[simid]['type'] === 'Promo') {
                                vol.push((resultformatted[item]['starting_base_volume'] + resultformatted[item]['covid_base_volume'] + resultformatted[item]['holiday_base_volume']))
                                //fetch the api for base volume...
                                let start = this.state.results_dates[simid].substring(0, 10)
                                let end = this.state.results_dates[simid].substring(14)
                                var basecalcdata = {
                                    itemids: [parseInt(item)],
                                    startdate: start,
                                    enddate: end,
                                    dataset_id: this.state.result_datasetid,
                                    geogid: this.state.result_geogid
                                }
                                var jsonbody = JSON.stringify(basecalcdata)
                                console.log(jsonbody)

                                fetch(Constant.baseapiurl + "Simulation/base_calculation",
                                    {
                                        method: "POST",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: jsonbody
                                    })
                                    .then(res => {
                                        //Look at status code, and if not 200, throw error
                                        if (res.status === 200) {
                                            //console.log('ok')
                                            res.text()
                                                .then(
                                                    (result) => {
                                                        //console.log(result)
                                                        var result2 = result.replace(/NaN/g, "0").replace(/Infinity/g, "0")
                                                        //console.log(result2)
                                                        try {
                                                            var resultformatted = JSON.parse(result2)
                                                            console.log(resultformatted)
                                                            bps.push(resultformatted['avgbp'][0])
                                                        }

                                                        catch (error) {
                                                            this.setState({ runsimerror: true })
                                                        }
                                                        
                                                    })
                                        }

                                        else {
                                            console.log('There was an Error Running the API')
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log('There was an Error Running the API')
                                    })


                            }

                            else if (overrideset && this.state.sim_dict[simid]['type'] === 'Base') {
                                //vol.push(this.state.sim_dict[simid]['override_base_volume'][indextouse])
                                vol.push((resultformatted[item]['starting_base_volume'] + resultformatted[item]['covid_base_volume'] + resultformatted[item]['holiday_base_volume']) )
                                bps.push(this.state.sim_dict[simid]['override_base_price'][indextouse])

                            }

                            else {
                                vol.push((resultformatted[item]['starting_base_volume'] + resultformatted[item]['covid_base_volume'] + resultformatted[item]['holiday_base_volume']) )
                                bps.push(resultformatted[item]['avgbp'])

                            }


                            change.push(resultformatted[item]['total'])
                        }
                    }
                    )

                    //console.log(sim_dict)
                    //console.log(sim_dict[simid])
                    sim_dict[simid]['bps'] = bps
                    sim_dict[simid]['vol'] = vol
                    sim_dict[simid]['change'] = change

                    let brand_string = ""
                    var added_brands = []
                    resultmap_brands[simid].forEach(brand => {
                        if (brand !== "" && !added_brands.includes(brand)) {
                            brand_string += "," + brand
                            added_brands.push(brand)
                        }
                    })

                    resultmap_brands_string[simid] = brand_string.substring(1)

                    //sim_dict[sim_id]['numweeks'] = numweeks
                    //sim_dict[sim_id]['coverage'] = coverage
                    //sim_dict[sim_id]['name'] = result.sim_name

                    console.log(resultmap_volume_change)

                    let volume_values = Object.values(resultmap_volume_change)
                    let volume_values_sorted = volume_values.sort((a, b) => a - b)
                    console.log(volume_values_sorted)

                    let added_to_finalmap = []
                    //let finalmap = {}
                    let simidkeys = Object.keys(resultmap_volume_change)
                    volume_values_sorted.forEach(volume => {
                        simidkeys.forEach(simid => {
                            if (resultmap_volume_change[simid] === volume && !added_to_finalmap.includes(simid)) {
                                added_to_finalmap.push(simid)
                                //finalmap[simid] =  volume
                            }
                        })
                    })

                    console.log(added_to_finalmap)

                    this.setState({
                        results: resultmap,
                        sort_order: added_to_finalmap,
                        results_volume: resultmap_volume_change,
                        results_base_volume: resultmap_base_volume,
                        results_brands: resultmap_brands,
                        results_brands_string: resultmap_brands_string,
                        sim_dict: sim_dict
                    })

                }
                else {
                    this.setState({ runsimerror: true })
                }

            }
            else {
                this.setState({ runsimerror: true })
            }

            resultmap[simid] = simdata

            console.log(idx)
            if (idx === numsims - 1) {
                this.setState({ showBackdrop_CompareSims: false })
            }
        })

        

    }

    render() {
        console.log(this.state);
        var doll_change_sim = {}
        var doll_base_sim = {}
        var disc_doll_sim = {}
        var item_dict_lookup = {}

        this.state.dataset_info_items.forEach(item => {
            item_dict_lookup[item.prodid] = item.proddesc
        })
        const currency_obj = { style: 'currency', currency: 'USD' }
        const percent_obj = { style: 'percent', maximumFractionDigits: 0 }
        try {
            Object.keys(this.state.sim_dict).forEach(sim => {
                doll_change_sim[sim] = 0
                this.state.sim_dict[sim]['vol'].forEach((volume, idx) => {
                    let vol = volume 
                    //console.log(vol)
                    if (this.state.sim_dict[sim]['type'] === 'Base') {
                        doll_change_sim[sim] +=

                            ((vol + (vol * this.state.sim_dict[sim]['change'][idx])) * this.state.sim_dict[sim]['sim_bps'][idx] * this.state.sim_dict[sim]['numweeks'] * this.state.sim_dict[sim]['coverage']) //New Dollars                         
                            -
                            (vol * this.state.sim_dict[sim]['bps'][idx] * this.state.sim_dict[sim]['numweeks'] * this.state.sim_dict[sim]['coverage']) //Base Dollars
                    }
                    else {
                        doll_change_sim[sim] +=
                            (vol * this.state.sim_dict[sim]['change'][idx] * (this.state.sim_dict[sim]['bps'][idx] * (1 - this.state.sim_dict[sim]['sim_discounts'][idx])) * this.state.sim_dict[sim]['numweeks'] * this.state.sim_dict[sim]['coverage']) // New Dollars
                    }
                })
            })
        }
        catch (error) {
            //console.log(error)
        }

        try {
            Object.keys(this.state.sim_dict).forEach(sim => {
                doll_base_sim[sim] = 0
                this.state.sim_dict[sim]['vol'].forEach((volume, idx) => {
                    let vol = volume 
                    if (this.state.sim_dict[sim]['type'] === 'Base') {
                        doll_base_sim[sim] +=
                            (vol * this.state.sim_dict[sim]['bps'][idx] * this.state.sim_dict[sim]['numweeks'] * this.state.sim_dict[sim]['coverage']) //Base Dollars
                    }
                    else {
                        doll_base_sim[sim] +=
                            (vol * this.state.sim_dict[sim]['bps'][idx] * this.state.sim_dict[sim]['numweeks'] * this.state.sim_dict[sim]['coverage'])
                    }
                })
            })
        }
        catch (error) {
            //console.log(error)
        }
        //Disc Dollars
        try {
            Object.keys(this.state.sim_dict).forEach(sim => {
                disc_doll_sim[sim] = 0
                this.state.sim_dict[sim]['vol'].forEach((volume, idx) => {
                    let vol = volume 

                    if (this.state.sim_dict[sim]['type'] === 'Base') {
                        disc_doll_sim[sim] += 0
                    }
                    else {
                        disc_doll_sim[sim] +=
                            (vol * this.state.sim_dict[sim]['bps'][idx] * this.state.sim_dict[sim]['sim_discounts'][idx] * this.state.sim_dict[sim]['numweeks'] * this.state.sim_dict[sim]['coverage'] * (1 + this.state.sim_dict[sim]['change'][idx]))
                    }
                })
            })
        }
        catch (error) {
            //console.log(error)
        }

        const muiTheme = createMuiTheme({
            palette: {
                primary: {
                    main: '#7d32ba',
                },
                secondary: {
                    main: '#ff8b6a',
                },
                white: {
                    main: '#FFF'
                }
            }
        });

        const handleChangeRadio = (event) => {
            var type = event.currentTarget.value
            //console.log(type)
            var volchangeelement = document.getElementById('VolumeChangeChart')
            var dolchangeelement = document.getElementById('DollarChangeChart')
            var discdolchangeelement = document.getElementById('DiscDollarChart')
            var pbrelement = document.getElementById('PBRChart')

            if (type === 'Volume') {
                volchangeelement.style.display = ''                
                dolchangeelement.style.display = 'none'                
                discdolchangeelement.style.display = 'none'
                pbrelement.style.display = 'none'                
                this.setState({ volchangeselected: true, dolchangeselected: false, discdolselected: false, pbrselected: false })
            }

            else if (type === 'Dollar') {
                volchangeelement.style.display = 'none'
                dolchangeelement.style.display = ''
                discdolchangeelement.style.display = 'none'
                pbrelement.style.display = 'none'
                this.setState({ volchangeselected: false, dolchangeselected: true, discdolselected: false, pbrselected: false  })
            }

            else if (type === 'DiscDollar') {
                volchangeelement.style.display = 'none'
                dolchangeelement.style.display = 'none'
                discdolchangeelement.style.display = ''
                pbrelement.style.display = 'none'
                this.setState({ volchangeselected: false, dolchangeselected: false, discdolselected: true, pbrselected: false  })
            }

            else if (type === 'PBR') {
                volchangeelement.style.display = 'none'
                dolchangeelement.style.display = 'none'
                discdolchangeelement.style.display = 'none'
                pbrelement.style.display = ''
                this.setState({ volchangeselected: false, dolchangeselected: false, discdolselected: false, pbrselected: true })
            }            
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ runsimerror: false })
        }

        var currencycode = 'USD'

        let numweeks = Object.keys(this.state.sim_dict).map(sim => { return this.state.sim_dict[sim]['numweeks'] })
        //console.log(numweeks)
        let minimum_weeks = Math.min(...numweeks)
        let maximum_weeks = Math.max(...numweeks)
        let numpromosims = 0
        let numbasesims = 0

        Object.keys(this.state.sim_dict).forEach(sim => {
            if (this.state.sim_dict[sim]['type'] === 'Promo') {
                numpromosims += 1
            }
            else {
                numbasesims += 1
            }
        })

        var geo_title = 'Geography Selected: ' + (this.state.geolookup || {})[((this.state.result_geogid || [])[0] || "")]
        var time_title = 'Simulations range in number of weeks from ' + minimum_weeks + ' weeks to ' + maximum_weeks + ' weeks'
        var types_title = 'Comparison contains ' + numpromosims + ' Promotion Simulations and ' + numbasesims + ' Base Price Simulations'
        var chart_title_info = '<br>' + geo_title + '<br>' + time_title + '<br>' + types_title

        return (
            <Layout title="Compare Simulations">
                <Constant.NPDBackdrop
                    open={this.state.showBackdrop_CompareSims}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <Dialog
                    open={this.state.runsimerror}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Simulation Error"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There was an error running one or more of the simulations.  Please reload the page
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <div name='banner' className='banner'>
                    <h1white align='center'>Compare Simulations</h1white><br/>
                    <h2white align='center'> Here we can compare multiple simulations</h2white>
                </div>

                <div className="" style={{ width: '100%', position: 'absolute', left: '120px', top: '210px', backgroundColor: '#F4F4F4', maxWidth: 'calc(100vw - 120px)', padding: '75px' }}>
                    <div className="row col-md-12 " style={{ maxWidth: '1250px' }}>
                        <div className="col-md-12" >
                            <h5>Please Select a Metric to Compare</h5>
                            <ThemeProvider theme={muiTheme}>
                                <Radio
                                    checked={this.state.volchangeselected}
                                    onChange={handleChangeRadio}
                                    value="Volume"
                                    name="VolumeRadio"
                                    label="Units Change"
                                    inputProps={{ 'aria-label': 'Units Change' }}
                                />
                                <span>Units Change</span>
                                <Radio
                                    checked={this.state.dolchangeselected}
                                    onChange={handleChangeRadio}
                                    value="Dollar"
                                    name="DollarRadio"
                                    label="Dollar Change"
                                    inputProps={{ 'aria-label': 'Dollar Change' }}
                                />
                                <span>Dollar Change</span>
                                <Radio
                                    checked={this.state.discdolselected}
                                    onChange={handleChangeRadio}
                                    value="DiscDollar"
                                    name="DiscDollarRadio"
                                    label="Discount Dollars"
                                    inputProps={{ 'aria-label': 'Discount Dollars' }}
                                />
                                <span>Discount Dollars</span>
                                <Radio
                                    checked={this.state.pbrselected}
                                    onChange={handleChangeRadio}
                                    value="PBR"
                                    name="PBRRadio"
                                    label="Payback Ratio"
                                    inputProps={{ 'aria-label': 'Payback Ratio' }}
                                />
                                <span>Payback Ratio</span>
                            </ThemeProvider>
                        </div>
                        <div className="col-md-12" id="VolumeChangeChart">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'column',
                                            height: (9 / 16 * 100) + '%', //16:9 ratio
                                        },
                                        caption: {
                                            text: 'The green bar denotes the starting simulation for the comparison'
                                        },
                                        title: {
                                            text: 'Total Units Change by Simulation' + chart_title_info
                                        },
                                        series: [
                                            {
                                                name: 'Total Units Change',
                                                data: this.state.sort_order.map(simid => this.state.results_volume[simid]),
                                                colors: this.state.sort_order.map(simid => this.state.sim_graph_cols[simid]),
                                            },
                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Simulation Name'
                                            },
                                            categories: this.state.sort_order.map(simid => this.state.results_names[simid]), //Object.values(this.state.results_names),

                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1
                                        },
                                        yAxis: {
                                            title: {
                                                text: 'Units Change'
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1, 
                                            labels: {
                                                formatter: function () {
                                                    function nFormatter(num, digits) {
                                                        var si = [
                                                            { value: 1E18, symbol: "E" },
                                                            { value: 1E15, symbol: "P" },
                                                            { value: 1E12, symbol: "T" },
                                                            { value: 1E9, symbol: "B" },
                                                            { value: 1E6, symbol: "M" },
                                                            { value: 1E3, symbol: "k" }
                                                        ], i;
                                                        for (i = 0; i < si.length; i++) {
                                                            if (num >= si[i].value) {
                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                            }
                                                        }
                                                        return num.toString();
                                                    }

                                                    return nFormatter(this.value, 0).toLocaleString()

                                                }
                                            }
                                        },
                                        plotOptions: {
                                            series: {
                                                //stacking: 'none',
                                                dataLabels: {
                                                    enabled: true,
                                                    formatter: function () {
                                                        //console.log(this)
                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (Math.abs(num) >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (Math.abs(num) < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        return nFormatter(this.point.y, 1).toLocaleString()
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '20px'
                                                    },
                                                    y: 0
                                                },
                                                colorByPoint: true
                                            }
                                        },
                                        tooltip: {
                                            formatter: function () {
                                                return this.series.name + ' : ' + Math.round(this.y).toLocaleString()
                                            }
                                        }

                                    }}
                            />
                        </div>
                        <div className="col-md-12" id="DollarChangeChart" style={{ display: "none" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'column',
                                            height: (9 / 16 * 100) + '%', //16:9 ratio
                                        },
                                        caption: {
                                            text: 'The green bar denotes the starting simulation for the comparison'
                                        },
                                        title: {
                                            text: 'Total Dollar Change by Simulation' + chart_title_info
                                        },
                                        series: [
                                            {
                                                name: 'Total Dollar Change',
                                                data: this.state.sort_order.map(simid => doll_change_sim[simid] || 0), //Object.values(doll_change_sim),
                                                colors: this.state.sort_order.map(simid => this.state.sim_graph_cols[simid]), //Object.values(sim_graph_cols),
                                            },
                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Simulation Name'
                                            },
                                            categories: this.state.sort_order.map(simid => this.state.results_names[simid]),

                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1
                                        },
                                        yAxis: {
                                            title: {
                                                text: 'Dollar Change'
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1,
                                            labels: {
                                                formatter: function () {

                                                    function nFormatter(num, digits) {
                                                        var si = [
                                                            { value: 1E18, symbol: "E" },
                                                            { value: 1E15, symbol: "P" },
                                                            { value: 1E12, symbol: "T" },
                                                            { value: 1E9, symbol: "B" },
                                                            { value: 1E6, symbol: "M" },
                                                            { value: 1E3, symbol: "k" }
                                                        ], i;
                                                        for (i = 0; i < si.length; i++) {
                                                            if (Math.abs(num) >= si[i].value) {
                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                            }
                                                            else if (Math.abs(num) < 1E3) {
                                                                return (num).toFixed(0);
                                                            }
                                                        }
                                                        return num.toString();
                                                    }

                                                    let value = nFormatter(this.value, 1)
                                                    let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                    let symbol = value.substring(value.length - 1)
                                                    return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                }

                                            }

                                        },
                                        plotOptions: {
                                            series: {
                                                dataLabels: {
                                                    enabled: true,
                                                    formatter: function () {
                                                        //console.log(this)
                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (Math.abs(num) >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (Math.abs(num) < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        let value = nFormatter(this.point.y, 1)
                                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                        let symbol = value.substring(value.length - 1)
                                                        return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '20px'
                                                    },
                                                    y: 0
                                                },
                                                colorByPoint: true
                                            }
                                        },
                                        tooltip: {
                                            formatter: function () {
                                                return this.series.name + ' : ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                            }
                                        }

                                    }}
                            />
                        </div>
                        <div className="col-md-12" id="DiscDollarChart" style={{ display: "none" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'column',
                                            height: (9 / 16 * 100) + '%', //16:9 ratio
                                        },
                                        caption: {
                                            text: 'The green bar denotes the starting simulation for the comparison'
                                        },
                                        title: {
                                            text: 'Total Discount Dollar Change by Simulation' + chart_title_info
                                        },
                                        series: [
                                            {
                                                name: 'Total Discount Dollar Change',
                                                data: this.state.sort_order.map(simid => disc_doll_sim[simid] || 0), //Object.values(disc_doll_sim),
                                                colors: this.state.sort_order.map(simid => this.state.sim_graph_cols[simid]), //Object.values(this.state.sim_graph_cols),
                                            },
                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Simulation Name'
                                            },
                                            categories: this.state.sort_order.map(simid => this.state.results_names[simid]), //Object.values(this.state.results_names),

                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1
                                        },
                                        yAxis: {
                                            title: {
                                                text: 'Discount Dollars'
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1,
                                            labels: {
                                                formatter: function () {

                                                    function nFormatter(num, digits) {
                                                        var si = [
                                                            { value: 1E18, symbol: "E" },
                                                            { value: 1E15, symbol: "P" },
                                                            { value: 1E12, symbol: "T" },
                                                            { value: 1E9, symbol: "B" },
                                                            { value: 1E6, symbol: "M" },
                                                            { value: 1E3, symbol: "k" }
                                                        ], i;
                                                        for (i = 0; i < si.length; i++) {
                                                            if (Math.abs(num) >= si[i].value) {
                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                            }
                                                            else if (Math.abs(num) < 1E3) {
                                                                return (num).toFixed(0);
                                                            }
                                                        }
                                                        return num.toString();
                                                    }

                                                    let value = nFormatter(this.value, 1)
                                                    let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                    let symbol = value.substring(value.length - 1)
                                                    return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                }

                                            }

                                        },
                                        plotOptions: {
                                            series: {                                                
                                                dataLabels: {
                                                    enabled: true,
                                                    formatter: function () {
                                                        //console.log(this)
                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (Math.abs(num) >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (Math.abs(num) < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        let value = nFormatter(this.point.y, 1)
                                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                        let symbol = value.substring(value.length - 1)
                                                        return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '20px'
                                                    },
                                                    y: 0
                                                },
                                                colorByPoint: true
                                            }
                                        },
                                        tooltip: {
                                            formatter: function () {
                                                return this.series.name + ' : ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                            }
                                        }

                                    }}
                            />
                        </div>
                        <div className="col-md-12" id="PBRChart" style={{ display: "none" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'column',
                                            height: (9 / 16 * 100) + '%', //16:9 ratio
                                        },
                                        caption: {
                                            text: 'The green bar denotes the starting simulation for the comparison'
                                        },
                                        title: {
                                            text: 'Payback Ratio by Simulation' + chart_title_info
                                        },
                                        series: [
                                            {
                                                name: 'Pay Back Ratio',
                                                data: this.state.sort_order.map(simid => {
                                                    if (this.state.results_types[simid] === 'Base') {
                                                        return 0
                                                    }
                                                    else {
                                                        return ((doll_change_sim[simid] / disc_doll_sim[simid]) || 0)
                                                    }
                                                }),
                                                colors: this.state.sort_order.map(simid => this.state.sim_graph_cols[simid]), //Object.values(this.state.sim_graph_cols),
                                            },
                                        ],
                                        xAxis: {
                                            title: {
                                                text: 'Simulation Name'
                                            },
                                            categories: this.state.sort_order.map(simid => this.state.results_names[simid]), //Object.values(this.state.results_names),

                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1
                                        },
                                        yAxis: {
                                            title: {
                                                text: 'Payback Ratio'
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1,
                                            labels: {
                                                formatter: function () {

                                                    function nFormatter(num, digits) {
                                                        var si = [
                                                            { value: 1E18, symbol: "E" },
                                                            { value: 1E15, symbol: "P" },
                                                            { value: 1E12, symbol: "T" },
                                                            { value: 1E9, symbol: "B" },
                                                            { value: 1E6, symbol: "M" },
                                                            { value: 1E3, symbol: "k" }
                                                        ], i;
                                                        for (i = 0; i < si.length; i++) {
                                                            if (Math.abs(num) >= si[i].value) {
                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                            }
                                                            else if (Math.abs(num) < 1E3) {
                                                                return (num).toFixed(digits);
                                                            }
                                                        }
                                                        return num.toString();
                                                    }
                                                    
                                                    return nFormatter(this.value, 1)
                                                }

                                            }

                                        },
                                        plotOptions: {
                                            series: {
                                                dataLabels: {
                                                    enabled: true,
                                                    formatter: function () {
                                                        //console.log(this)
                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (Math.abs(num) >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (Math.abs(num) < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        let value = nFormatter(this.point.y, 1)
                                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                        let symbol = value.substring(value.length - 1)
                                                        return numpart.toLocaleString() + symbol
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '20px'
                                                    },
                                                    y: 0
                                                },
                                                colorByPoint: true
                                            }
                                        },
                                        tooltip: {
                                            formatter: function () {
                                                return this.series.name + ' : ' + this.y.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            }
                                        }

                                    }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12" style={{ maxWidth: '1250px', padding: '20px' }} >
                        <br />
                        <h4>Simulation Comparison Results Table</h4>
                        <Table style={{ width: '100%' }}>
                            <TableHead className='tableheader'>
                                <TableRow>
                                    <TableCell align='left'>Sim Name</TableCell>
                                    <TableCell align='center'>Sim Type</TableCell>
                                    <TableCell align='center'>Date Range</TableCell>
                                    <TableCell align='center'>Brands</TableCell>
                                    <TableCell align='center'>Base Units</TableCell>
                                    <TableCell align='center'>Units Change</TableCell>
                                    <TableCell align='center'>Units % Change</TableCell>
                                    <TableCell align='center'>Dollar Change</TableCell>
                                    <TableCell align='center'>Dollar % Change</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.sort_order.map((sim, idx) =>

                                    <TableRow key={idx}>
                                        <TableCell align='left' className={this.state.sim_styles[sim]} > {this.state.results_names[sim]}</TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]} > {this.state.results_types[sim]}</TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]} > {this.state.results_dates[sim]}</TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]} >{this.state.results_brands_string[sim]}</TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]} >{Math.round(this.state.results_base_volume[sim]).toLocaleString()}</TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]} >{Math.round(this.state.results_volume[sim]).toLocaleString()}</TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]}>{(this.state.results_volume[sim] / this.state.results_base_volume[sim]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]}>
                                            {
                                                doll_change_sim[sim] && doll_change_sim[sim].toLocaleString(undefined, { style: 'currency', currency: 'USD' })
                                            }
                                        </TableCell>
                                        <TableCell align='center' className={this.state.sim_styles[sim]}>
                                            {
                                                doll_change_sim[sim] && doll_base_sim[sim] && (doll_change_sim[sim] / doll_base_sim[sim]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            }
                                        </TableCell>
                                    </TableRow>

                                )}
                                <TableRow>
                                    <TableCell align='left'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="simcomp_start" align='left'>Denotes Starting Simulation</TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    {/*
                                    <TableCell className="simcomp_max" align='center'>Indicates Highest Change for Given Metric</TableCell>
                                    <TableCell className="simcomp_min" align='center'>Indicates Lowest Change for Given Metric</TableCell>  
                                    */}
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </div>

                    <div className="row col-md-12 " style={{ padding: '20px', width: '1250px' }}>
                        {this.state.sort_order.map((simid, idx) => {
                            return <div className="row col-md-12 " style={{ padding: '20px', width: '1250px' }} key={simid}>
                                <ExpansionPanel style={{ width: '1250px' }}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <h5 style={{ padding: '10px' }}>Item Specifics - {this.state.sim_dict[simid]['name']}</h5>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div className="row col-sm-12">
                                            <div align='center' className="col-sm-12" style={{ marginRight: '10px', marginLeft: '0px' }}>
                                                <Table>
                                                    <TableHead className='tableheader'>
                                                        <TableRow key={'tablerow_header'}>
                                                            <TableCell align='left'>Item Name</TableCell>
                                                            <TableCell align='center'>Base Units</TableCell>
                                                            <TableCell align='center'>Base Price</TableCell>
                                                            <TableCell align='center'>{this.state.sim_dict[simid]['type'] === 'Base' ? <span>Simulated Base Price</span> : <span>Simulated Discount Percent</span>}</TableCell>
                                                            <TableCell align='center'>{this.state.sim_dict[simid]['type'] === 'Base' ? <span>Base Price % Change</span> : <span>Simulated Promoted Price</span>}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.sim_dict[simid]['item_ids'] && this.state.sim_dict[simid]['item_ids'].map((item, idx) => (
                                                            <TableRow key={'tablerow_' + item}>
                                                                <TableCell align='left'>
                                                                    {this.state.dataset_info_items.map(item_info => {
                                                                        if (parseInt(item_info.prodid) === item) {
                                                                            return item_info.proddesc
                                                                        }
                                                                        else {
                                                                            return null
                                                                        }
                                                                    })}
                                                                </TableCell>
                                                                <TableCell align='center'>{this.state.sim_dict[simid]['vol'] && this.state.sim_dict[simid]['vol'][idx] && Math.round(this.state.sim_dict[simid]['vol'][idx]).toLocaleString()}</TableCell>

                                                                <TableCell align='center'>{this.state.sim_dict[simid]['bps'] && this.state.sim_dict[simid]['bps'][idx] && this.state.sim_dict[simid]['bps'][idx].toLocaleString(undefined, currency_obj)}</TableCell>
                                                                <TableCell align='center'>{
                                                                    this.state.sim_dict[simid]['type'] === 'Base' ?
                                                                        <span> {this.state.sim_dict[simid]['sim_bps'] && this.state.sim_dict[simid]['sim_bps'][idx] && this.state.sim_dict[simid]['sim_bps'][idx].toLocaleString(undefined, currency_obj)}</span>
                                                                        :
                                                                        <span>{this.state.sim_dict[simid]['sim_discounts'] && this.state.sim_dict[simid]['sim_discounts'][idx] && this.state.sim_dict[simid]['sim_discounts'][idx].toLocaleString(undefined, percent_obj)}</span>
                                                                }
                                                                </TableCell>
                                                                <TableCell align='center'>{
                                                                    this.state.sim_dict[simid]['type'] === 'Base' ?
                                                                        <span>{this.state.sim_dict[simid]['sim_bps'] && this.state.sim_dict[simid]['bps'] && this.state.sim_dict[simid]['sim_bps'][idx] && this.state.sim_dict[simid]['bps'][idx] && ((this.state.sim_dict[simid]['sim_bps'][idx] - this.state.sim_dict[simid]['bps'][idx]) / this.state.sim_dict[simid]['bps'][idx]).toLocaleString(undefined, percent_obj)}</span>
                                                                        :
                                                                        <span>{this.state.sim_dict[simid]['bps'] && this.state.sim_dict[simid]['sim_discounts'] && this.state.sim_dict[simid]['bps'][idx] && this.state.sim_dict[simid]['sim_discounts'][idx] && ((this.state.sim_dict[simid]['bps'][idx]) * (1 - this.state.sim_dict[simid]['sim_discounts'][idx])).toLocaleString(undefined, currency_obj)}</span>
                                                                }
                                                                </TableCell>

                                                            </TableRow>
                                                        )
                                                        )}

                                                    </TableBody>

                                                </Table>
                                            </div>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        })
                        }
                    </div>

                </div>

            </Layout>
        )
    }
}