import React, { Component, Fragment } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import selectColourStyles from '../styles/SelectColourStyles'
import { Layout } from '../Layout';
import Select from 'react-select'
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import {ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AddBoxIcon from '@material-ui/icons/AddBox';
import * as Constant from '../constants'

// API CRUD requests and error handling
import FetchConfigWithDefault from './Functions/Utilities/FetchConfigWithDefault'
import FetchDataWithErrorHandling from './Functions/Utilities/FetchDataWithErrorHandling'
import { ApiErrorDialog, openApiErrorDialog, closeApiErrorDialog, on_api_entity_crud_error } from '../ApiMethods/ApiErrorDialog'
import { entityCrud } from '../ApiMethods/EntityCrud'

export class PDTListofAllDatasets extends Component {
    static displayName = PDTListofAllDatasets.name;

    constructor(props) {
        super(props);
        this.state = {
            datasetsdata: [],
            datasetnames: [],
            industries: [],
            countries: [],
            timeaggs: [],
            storegroups: [],
            categories: [],
            subcategories: [],
            filters: {
                'datasets': ['All'],
                'countries': ['All'],
                'industries': ['All'],
                'storegroups': ['All'],
                'categories': ['All'],
                'subcategories': ['All'],
                'timeaggregations': ['All'],
                'lastupdated_start': null,
                'lastupdated_end': null
            },
            showBackground: true,
            header_sort_active: [true, false, false, false, false, false, false],
            header_sort_direction: ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc'],
            dataset_for_modal: {
                datasetinfo: {},
                attr_lookup: {},
                datasetattrsettings_data: [],
                datasetbrands_data: [],
                datasetdiscbands_data: []
            },
            levels_formodal: {},
            DatevaluesTimeRange: null,
            DatevaluesLastUpdated: null,
            countrynamelookup: {},
            storegroupmodal: false,
            subcatgroupmodal: false,
            datasetgroupmodal: false,
            stagingdatasets_exist: false,
        }

        this.entityCrud = entityCrud.bind(this)
        this.openApiErrorDialog = openApiErrorDialog.bind(this)
        this.closeApiErrorDialog = closeApiErrorDialog.bind(this)
        this.on_api_entity_crud_error = on_api_entity_crud_error.bind(this)
        this.FetchConfigWithDefault = FetchConfigWithDefault.bind(this)
        this.FetchDataWithErrorHandling = FetchDataWithErrorHandling.bind(this)
    }

    async componentDidMount() {
        var appname = Constant.whereamilist[3]

        let my_results = await this.entityCrud('downloading list of datasets and countries', [
            { 'url': Constant.baseapiurl + "Datasets/DatasetDetails", 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + "lookups/Countries", 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl + "Datasets/StagingExists?Retailer=" + (appname === 'PDTRetailer'), 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            this.setState({ showBackground: false })
            return
        }

        let datasetsdata = my_results.ResultList[0]
        let countriesdata = my_results.ResultList[1]
        let stagingdatasets_exist = my_results.ResultList[2]

        console.log('stagingdatasets_exist: ' + stagingdatasets_exist)

        datasetsdata.forEach(async (dataset, idx) => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    datasetsdata[idx]['showdataset'] = true
                } else {
                    datasetsdata[idx]['showdataset'] = false
                }
            } else {
                datasetsdata[idx]['showdataset'] = true
            }
        })

        let datasetnames = datasetsdata.map(dataset => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    return dataset.short_desc
                } else {
                    return null
                }
            } else {
                return dataset.short_desc
            }
        }).filter(Boolean)

        let industries = Array.from(
            new Set(
                datasetsdata.map(dataset => {
                    if (appname === 'PDTRetailer') {
                        if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                            return dataset.industry_name
                        } else {
                            return null
                        }
                    } else {
                        return dataset.industry_name
                    }
                }).filter(Boolean)
            )
        )

        let countries = Array.from(
            new Set(
                datasetsdata.map(dataset => {
                    if (appname === 'PDTRetailer') {
                        if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                            return dataset.country
                        } else {
                            return null
                        }
                    } else {
                        return dataset.country
                    }
                }).filter(Boolean)
            )
        )

        let timeaggs = Array.from(
            new Set(
                datasetsdata.map(dataset => {
                    if (appname === 'PDTRetailer') {
                        if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                            return dataset.time_aggregation
                        } else {
                            return null
                        }
                    } else {
                        return dataset.time_aggregation
                    }
                }).filter(Boolean)
            )
        )

        let storegroupsall = []
        datasetsdata.forEach(dataset => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    (dataset.store_groups || []).forEach(sg => storegroupsall.push(sg.storegpdesc))
                }
            } else {
                (dataset.store_groups || []).forEach(sg => storegroupsall.push(sg.storegpdesc))
            }
        })
        let storegroups = Array.from(new Set(storegroupsall))

        let categoriesall = []
        datasetsdata.forEach(dataset => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    (dataset.levels.items || []).forEach(level => categoriesall.push(level.sel_name))
                }
            } else {
                (dataset.levels.items || []).forEach(level => categoriesall.push(level.sel_name))
            }
        })

        let categories = Array.from(new Set(categoriesall))

        let subcategoriesall = []
        datasetsdata.forEach(dataset => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    (dataset.levels.items || []).forEach(level => (level.items || []).forEach(sublevel => subcategoriesall.push(sublevel.sel_name)))
                }
            } else {
                (dataset.levels.items || []).forEach(level => (level.items || []).forEach(sublevel => subcategoriesall.push(sublevel.sel_name)))
            }
        })

        let subcategories = Array.from(new Set(subcategoriesall))

        let countrynamelookup = {}
        countriesdata.forEach(country => {
            countrynamelookup[country.country_code] = country.country_shortname
        })

        this.setState({
            datasetsdata,
            datasetnames,
            industries,
            countries,
            timeaggs,
            storegroups,
            categories,
            subcategories,
            countrynamelookup,
            showBackground: false,
            stagingdatasets_exist,
        })

        console.log(datasetsdata)
        console.log('Finished!')
    }

    render() {
        console.log(this.state)

        const filterDatasets = (selectedOptions, filterby) => {
            let filters = this.state.filters
            let datasets = this.state.datasetsdata
            var appname = Constant.whereamilist[3]
            if (filterby === 'datasets') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['datasets'] = selectedOptions.map(option => option.value)
                } else {
                    filters['datasets'] = ['All']
                }
            }

            if (filterby === 'countries') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['countries'] = selectedOptions.map(option => option.value)
                } else {
                    filters['countries'] = ['All']
                }
            }

            if (filterby === 'industries') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['industries'] = selectedOptions.map(option => option.value)
                } else {
                    filters['industries'] = ['All']
                }
            }

            if (filterby === 'storegroups') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['storegroups'] = selectedOptions.map(option => option.value)
                } else {
                    filters['storegroups'] = ['All']
                }
            }

            if (filterby === 'categories') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['categories'] = selectedOptions.map(option => option.value)
                } else {
                    filters['categories'] = ['All']
                }
            }

            if (filterby === 'subcategories') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['subcategories'] = selectedOptions.map(option => option.value)
                } else {
                    filters['subcategories'] = ['All']
                }
            }

            if (filterby === 'timeaggregations') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['timeaggregations'] = selectedOptions.map(option => option.value)
                } else {
                    filters['timeaggregations'] = ['All']
                }
            }

            if (filterby === 'lastupdated') {
                console.log(this.state.selectedstart)
                console.log(this.state.selectedend)
                filters['lastupdated_start'] = this.state.selectedstart
                filters['lastupdated_end'] = this.state.selectedend
            }

            if (filterby === 'daterange') {
                console.log(this.state.selectedstartdaterange)
                console.log(this.state.selectedenddaterange)
                filters['daterange_start'] = this.state.selectedstartdaterange
                filters['daterange_end'] = this.state.selectedenddaterange
            }

            //Loop through reports, and ensure that all filters are met...
            datasets.forEach(dataset => {
                let showdataset = false
                let datasetmatch = false
                let countrymatch = false
                let industrymatch = false
                let storegroupmatch = false
                let categorymatch = false
                let subcategorymatch = false
                let timeaggregationmatch = false
                let lastupdatedmatch = false
                let daterangematch = false

                if (filters['datasets'].includes(dataset.short_desc) || (filters['datasets'].length === 1 && filters['datasets'][0] === 'All')) {
                    datasetmatch = true
                }

                if (filters['countries'].includes(dataset.country) || (filters['countries'].length === 1 && filters['countries'][0] === 'All')) {
                    countrymatch = true
                }

                if (filters['industries'].includes(dataset.industry_name) || (filters['industries'].length === 1 && filters['industries'][0] === 'All')) {
                    industrymatch = true
                }

                if (filters['timeaggregations'].includes(dataset.time_aggregation) || (filters['timeaggregations'].length === 1 && filters['timeaggregations'][0] === 'All')) {
                    timeaggregationmatch = true
                }

                let storegroups = (dataset.store_groups || []).map(sg => sg.storegpdesc)

                storegroups.forEach(sg => {
                    if (filters['storegroups'].includes(sg) || (filters['storegroups'].length === 1 && filters['storegroups'][0] === 'All')) {
                        storegroupmatch = true
                    }
                })

                let categories = (dataset.levels.items || []).map(level => level.sel_name)
                let fulllevel = dataset.complete_at_level
                let full_cat = false
                if (fulllevel === 1) {
                    full_cat = true
                }

                let fullcategories = (dataset.levels.items || []).map(level => full_cat)
                categories.forEach((cat,idx) => {
                    if (this.state.useCompleteCats) {
                        if ((filters['categories'].includes(cat) && fullcategories[idx]) || (filters['categories'].length === 1 && filters['categories'][0] === 'All')) {
                            categorymatch = true
                        }
                    } else {
                        if (filters['categories'].includes(cat) || (filters['categories'].length === 1 && filters['categories'][0] === 'All')) {
                            categorymatch = true
                        }
                    }
                })

                console.log(dataset)
                let subcategories = (dataset.levels.items || []).map(level => (level.items || []).map(sublevel => sublevel.sel_name))

                subcategories.forEach(subcat => {
                    subcat.forEach(subcatvalue => {
                        if (filters['subcategories'].includes(subcatvalue) || (filters['subcategories'].length === 1 && filters['subcategories'][0] === 'All')) {
                            subcategorymatch = true
                        }
                    })

                    if (subcat.length === 0 && (filters['subcategories'].length === 1 && filters['subcategories'][0] === 'All')) {
                        subcategorymatch = true
                    }
                })

                if (categories.length === 0 && (filters['categories'].length === 1 && filters['categories'][0] === 'All')) {
                    if (this.state.useCompleteCats) {
                        categorymatch = false
                    } else {
                        categorymatch = true
                    }
                }

                if (filters['categories'].length === 1 && filters['categories'][0] === 'All') {
                    if (this.state.useCompleteCats) {
                        let numcompletecats = 0
                        fullcategories.forEach(cat => { if (cat) { numcompletecats += 1 } })
                        if (numcompletecats > 0) {
                            categorymatch = true
                        } else {
                            categorymatch = false
                        }
                    } else {
                        categorymatch = true
                    }
                }

                if (subcategories.length === 0 && (filters['subcategories'].length === 1 && filters['subcategories'][0] === 'All')) {
                    subcategorymatch = true
                }

                let time_updated_forcomp = dataset.time_updated.substring(0, 10)
                if (filters['lastupdated_start'] && filters['lastupdated_end']) {
                    if (time_updated_forcomp <= filters['lastupdated_end'] && time_updated_forcomp >= filters['lastupdated_start']) {
                        lastupdatedmatch = true
                    }
                }

                if (filters['lastupdated_start'] && !filters['lastupdated_end']) {
                    if (time_updated_forcomp >= filters['lastupdated_start']) {
                        lastupdatedmatch = true
                    }
                }

                if (!filters['lastupdated_start'] && filters['lastupdated_end']) {
                    if (time_updated_forcomp <= filters['lastupdated_end'] ) {
                        lastupdatedmatch = true
                    }
                }

                if (!filters['lastupdated_start'] && !filters['lastupdated_end']) {
                    lastupdatedmatch = true
                }

                let time_updated_daterange_start = ((dataset.times || {})['min'] || "").substring(0, 10)
                let time_updated_daterange_end = ((dataset.times || {})['max'] || "").substring(0, 10)

                if (filters['daterange_start'] && filters['daterange_end']) {
                    if (time_updated_daterange_start <= filters['daterange_start'] && time_updated_daterange_end >= filters['daterange_end']) {
                        daterangematch = true
                    }
                }

                if (filters['daterange_start'] && !filters['daterange_end']) {
                    if (time_updated_daterange_start <= filters['daterange_start']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && filters['daterange_end']) {
                    if (time_updated_daterange_end >= filters['daterange_end']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && !filters['daterange_end']) {
                    daterangematch = true
                }

                if (datasetmatch && countrymatch && industrymatch && storegroupmatch && timeaggregationmatch && categorymatch && subcategorymatch && lastupdatedmatch && daterangematch) {
                    if (appname === 'PDTRetailer') {
                        if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                            showdataset = true
                        } else {
                            showdataset = false
                        }
                    } else {
                        showdataset = true
                    }
                }

                dataset['showdataset'] = showdataset
            })

            this.setState({ filters, datasetdata: datasets })
        }

        const sortData = (event, sortby) => {
            console.log(event)
            console.log('Sorting by ' + sortby)

            var current_header_direction = this.state.header_sort_direction
            var new_header_active = []
            if (sortby === 'Name') {
                new_header_active = [true, false, false, false, false, false, false, false, false]
            } else if (sortby === 'Country') {
                new_header_active = [false, true, false, false, false, false, false, false, false]
            } else if (sortby === 'Industry') {
                new_header_active = [false, false, true, false, false, false, false, false, false]
            } else if (sortby === 'Category') {
                new_header_active = [false, false, false, true, false, false, false, false, false]
            } else if (sortby === 'SubCategory') {
                new_header_active = [false, false, false, false, true, false, false, false, false]
            } else if (sortby === 'StoreGroup') {
                new_header_active = [false, false, false, false, false, true, false, false, false]
            } else if (sortby === 'DateRange') {
                new_header_active = [false, false, false, false, false, false, true, false, false]
            } else if (sortby === 'AggLevel') {
                new_header_active = [false, false, false, false, false, false, false, true, false]
            } else if (sortby === 'LastModified') {
                new_header_active = [false, false, false, false, false, false, false, false, true]
            }

            var new_direction = ""
            var new_header_direction = []
            if (sortby === 'Name') {
                new_direction = (current_header_direction[0] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[0] = new_direction
            } else if (sortby === 'Country') {
                new_direction = (current_header_direction[1] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[1] = new_direction
            } else if (sortby === 'Industry') {
                new_direction = (current_header_direction[2] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[2] = new_direction
            } else if (sortby === 'Category') {
                new_direction = (current_header_direction[3] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[3] = new_direction
            } else if (sortby === 'SubCategory') {
                new_direction = (current_header_direction[4] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[4] = new_direction
            } else if (sortby === 'StoreGroup') {
                new_direction = (current_header_direction[5] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[5] = new_direction
            } else if (sortby === 'DateRange') {
                new_direction = (current_header_direction[6] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[6] = new_direction
            } else if (sortby === 'AggLevel') {
                new_direction = (current_header_direction[7] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[7] = new_direction
            } else if (sortby === 'LastModified') {
                new_direction = (current_header_direction[8] === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[8] = new_direction
            }

            // Sort the data...
            var data_to_sort = this.state.datasetsdata
            console.log(data_to_sort)
            var final_sort = []
            if (sortby === 'Name') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.short_desc || "").toLowerCase() < (b.short_desc || "").toLowerCase() ? -1 : ((a.short_desc || "").toLowerCase() > (b.short_desc || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.short_desc || "").toLowerCase() < (a.short_desc || "").toLowerCase() ? -1 : ((b.short_desc || "").toLowerCase() > (a.short_desc || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Country') {
                let country_lookup = this.state.countrynamelookup
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let countryname_a = (country_lookup[a.country] || "").toLowerCase()
                        let countryname_b = (country_lookup[b.country] || "").toLowerCase()
                        return countryname_a < countryname_b ? -1 : (countryname_a > countryname_b ? 1 : 0)
                    })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let countryname_a = (country_lookup[a.country] || "").toLowerCase()
                        let countryname_b = (country_lookup[b.country] || "").toLowerCase()
                        return countryname_b < countryname_a ? -1 : (countryname_b > countryname_a ? 1 : 0)
                    })
                }
            } else if (sortby === 'Industry') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.industry_name || "").toLowerCase() < (b.industry_name || "").toLowerCase() ? -1 : ((a.industry_name || "").toLowerCase() > (b.industry_name || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.industry_name || "").toLowerCase() < (a.industry_name || "").toLowerCase() ? -1 : ((b.industry_name || "").toLowerCase() > (a.industry_name || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'AggLevel') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => (a.time_aggregation || "").toLowerCase() < (b.time_aggregation || "").toLowerCase() ? -1 : ((a.time_aggregation || "").toLowerCase() > (b.time_aggregation || "").toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => (b.time_aggregation || "").toLowerCase() < (a.time_aggregation || "").toLowerCase() ? -1 : ((b.time_aggregation || "").toLowerCase() > (a.time_aggregation || "").toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'StoreGroup') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let storegroup_a = ((a.store_groups || []).map(sg => sg.storegpdesc).join(',') || "").toLowerCase()
                        let storegroup_b = ((b.store_groups || []).map(sg => sg.storegpdesc).join(',') || "").toLowerCase()
                        return storegroup_a < storegroup_b ? -1 : (storegroup_a > storegroup_b ? 1 : 0)
                    })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let storegroup_a = ((a.store_groups || []).map(sg => sg.storegpdesc).join(',') || "").toLowerCase()
                        let storegroup_b = ((b.store_groups || []).map(sg => sg.storegpdesc).join(',') || "").toLowerCase()
                        return storegroup_b < storegroup_a ? -1 : (storegroup_b > storegroup_a ? 1 : 0)
                    })
                }
            } else if (sortby === 'Category') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let category_a = (a.levels.items || []).map(level => level.sel_name).join(',')
                        let category_b = (b.levels.items || []).map(level => level.sel_name).join(',')
                        return (category_a || "").toLowerCase() < (category_b || "").toLowerCase() ? -1 : ((category_a || "").toLowerCase() > (category_b || "").toLowerCase() ? 1 : 0)
                    })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let category_a = (a.levels.items || []).map(level => level.sel_name).join(',')
                        let category_b = (b.levels.items || []).map(level => level.sel_name).join(',')
                        return (category_b || "").toLowerCase() < (category_a || "").toLowerCase() ? -1 : ((category_b || "").toLowerCase() > (category_a || "").toLowerCase() ? 1 : 0)
                    })
                }
            } else if (sortby === 'SubCategory') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let category_a = []
                        let category_b = []
                        let levels_a = (a.levels.items || [])
                        let levels_b = (b.levels.items || [])
                        levels_a.forEach(level => { (level.items || []).forEach(sublevel => { category_a.push(sublevel.sel_name) }) })
                        levels_b.forEach(level => { (level.items || []).forEach(sublevel => { category_b.push(sublevel.sel_name) }) })
                        let subcategory_a = category_a.join(',')
                        let subcategory_b = category_b.join(',')
                        return (subcategory_a || "").toLowerCase() < (subcategory_b || "").toLowerCase() ? -1 : ((subcategory_a || "").toLowerCase() > (subcategory_b || "").toLowerCase() ? 1 : 0)
                    })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let category_a = []
                        let category_b = []
                        let levels_a = (a.levels.items || [])
                        let levels_b = (b.levels.items || [])
                        levels_a.forEach(level => { (level.items || []).forEach(sublevel => { category_a.push(sublevel.sel_name) }) })
                        levels_b.forEach(level => { (level.items || []).forEach(sublevel => { category_b.push(sublevel.sel_name) }) })
                        let subcategory_a = category_a.join(',')
                        let subcategory_b = category_b.join(',')
                        return (subcategory_b || "").toLowerCase() < (subcategory_a || "").toLowerCase() ? -1 : ((subcategory_b || "").toLowerCase() > (subcategory_a || "").toLowerCase() ? 1 : 0)
                    })
                }
            } else if (sortby === 'DateRange') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let timetocheck_a = (a.times || {})['min']
                        let timetocheck_b = (b.times || {})['min']
                        return Date.parse(timetocheck_a) - Date.parse(timetocheck_b)
                    })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) {
                        let timetocheck_a = (a.times || {})['max']
                        let timetocheck_b = (b.times || {})['max']
                        return Date.parse(timetocheck_b) - Date.parse(timetocheck_a)
                    })
                }
            } else if (sortby === 'LastModified') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.time_updated) - Date.parse(b.time_updated) })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                }
            }

            console.log(final_sort)

            this.setState({
                header_sort_direction: new_header_direction,
                header_sort_active: new_header_active,
                datasetsdata: final_sort
            })
        }

        const handleDates_TimeRange = (values) => {
            console.log(values)
            let selectedstartdaterange = null
            let selectedenddaterange = null
            if (values) {
                selectedstartdaterange = (values[0].toISOString()).substring(0, 10)
                selectedenddaterange = (values[1].toISOString()).substring(0, 10)
            } else {
                selectedstartdaterange = null
                selectedenddaterange = null
            }

            this.setState({
                selectedstartdaterange: selectedstartdaterange,
                selectedenddaterange: selectedenddaterange,
                DatevaluesTimeRange: values
            }, () => {
                    filterDatasets(values, 'daterange')
            })
        }

        const handleDates_LastUpdated = (values) => {
            console.log(values)
            let selectedstart = null
            let selectedend = null
            if (values) {
                selectedstart = (values[0].toISOString()).substring(0, 10)
                selectedend = (values[1].toISOString()).substring(0, 10)
            } else {
                selectedstart = null
                selectedend = null
            }

            this.setState({
                selectedstart: selectedstart,
                selectedend: selectedend,
                DatevaluesLastUpdated: values
            }, () => {
                filterDatasets(values, 'lastupdated')
                }
            )
        }

        const handleToggle_useCompleteCats = () => {
            console.log('Setting State')
            this.setState({
                useCompleteCats: !this.state.useCompleteCats
            }, () => {
                    filterDatasets(!this.state.useCompleteCats, 'fullcats')
            })
        }

        const handleOpenStoreGroupModal = (event, storegroups, datasetname) => {
            console.log(storegroups)
            this.setState({ storegroupmodal: true, storegroups_formodal: storegroups, selectedDataSetName: datasetname })
        }

        const handleOpenSubCategoryModal = (event, levels, datasetname) => {
            console.log(levels)
            this.setState({ subcatgroupmodal: true, levels_formodal: levels, selectedDataSetName: datasetname })
        }

        const handleOpenDataSetInfoModal = async (event, datasetid, datasetinfo) => {
            console.log(datasetid)
            console.log(datasetinfo)

            let my_results = await this.entityCrud('downloading list of datasets and countries', [
                { 'url': Constant.baseapiurl + 'Datasets/' + datasetid, 'method': 'GET', 'body': null },
                { 'url': Constant.baseapiurl + 'Datasets/' + datasetid + '/Brands', 'method': 'GET', 'body': null },
                { 'url': Constant.baseapiurl + 'Datasets/' + datasetid + '/AttributeValues', 'method': 'GET', 'body': null },
                { 'url': Constant.baseapiurl + 'Datasets/' + datasetid + '/GlobalAttributeValues', 'method': 'GET', 'body': null },
                { 'url': Constant.baseapiurl + 'Datasets/' + datasetid + '/DiscountBuckets', 'method': 'GET', 'body': null },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails
                this.setState({ showBackground: false })
                return
            }

            let datasetparams_data = my_results.ResultList[0]
            let datasetbrands_data = my_results.ResultList[1]
            let datasetattrsettings_data_dataset = my_results.ResultList[2]
            let datasetattrsettings_data_global = my_results.ResultList[3]
            let datasetdiscbands_data = my_results.ResultList[4]

            console.log(datasetparams_data)

            let datasetattrsettings_data = datasetattrsettings_data_dataset.concat(datasetattrsettings_data_global)

            // Build a lookup of humanreadable attributes
            let attr_lookup = {}
            datasetparams_data.attributes.forEach((attr, idx) => {
                attr_lookup[attr] = datasetparams_data.attributes_human[idx]
            })

            datasetparams_data.global_attributes.forEach((attr, idx) => {
                attr_lookup[attr] = datasetparams_data.global_attributes_human[idx]
            })

            // Set info for Modal...
            let dataset_for_modal = {
                datasetinfo: datasetinfo,
                attr_lookup: attr_lookup,
                datasetattrsettings_data: datasetattrsettings_data,
                datasetbrands_data: datasetbrands_data,
                datasetdiscbands_data: datasetdiscbands_data
            }

            this.setState({ datasetgroupmodal: true, dataset_for_modal: dataset_for_modal, selectedDataSetName: datasetinfo.short_desc })
        }

        const sendToCreateReportForm = (event, datasetid) => {
            //Route the user to the create report tab with a parameter for the datasetid to use
            var appname = Constant.whereamilist[3]
            window.open(Constant.baseurl + appname + '/createreport/' + datasetid)
        }

        const handleCloseStoreGroupModal = () => {
            this.setState({ storegroupmodal: false })
        }

        const handleCloseSubCategoryModal = () => {
            this.setState({ subcatgroupmodal: false })
        }

        const handleCloseDataSetInfoModal = () => {
            this.setState({ datasetgroupmodal: false })
        }

        const openStagingDatasets = async (event) => {
            var appname = Constant.whereamilist[3]
            window.open(Constant.baseurl + appname + '/DatasetsStaging', '_blank')
        }

        return (
            <Layout title="All Datasets" >
                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                {/* API error pop-up */}
                <ApiErrorDialog
                    state={this.state}
                    closeApiErrorDialog={this.closeApiErrorDialog}
                />

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.storegroupmodal}
                    onClose={handleCloseStoreGroupModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'scroll', maxHeight: '85%' }} className="contentHolder">
                        <div className='row'>
                            <div style={{ textAlign: 'left', width: '50%' }}><h4>{'All Store Groups for ' + this.state.selectedDataSetName}</h4></div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <IconButton
                                    onClick={handleCloseStoreGroupModal}
                                >
                                    <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </div>
                        </div>
                        <ul>
                            {(this.state.storegroups_formodal || []).map(sg => {
                                return <li key={sg.storegpdesc }> {sg.storegpdesc}</li>
                            })}
                        </ul>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.subcatgroupmodal}
                    onClose={handleCloseSubCategoryModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'scroll', maxHeight:'85%' }} className="contentHolder">
                        <div className='row'>
                            <div style={{ textAlign: 'left', width: '50%' }}><h4>{'All Categories and Subcategories for ' + this.state.selectedDataSetName}</h4></div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <IconButton
                                    onClick={handleCloseSubCategoryModal}
                                >
                                    <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </div>
                        </div>

                        {(this.state.levels_formodal.items || []).map(level => {
                            let sel2 = level.items || []
                            let complete_at_level = this.state.levels_formodal.complete_at_level
                            let sel1_complete = false
                            if (complete_at_level === 0) {
                                sel1_complete = true
                            }
                            return <Fragment key={level.sel_name}>
                                {sel1_complete ?
                                    <h6>{level.sel_name}</h6>
                                    :
                                    <p>{level.sel_name}</p>
                                }
                                <ul>
                                    {sel2.map(sel => {
                                        return <li key={sel.sel_name}> {sel.sel_name}</li>
                                    })}
                                </ul>
                            </Fragment>
                        })}
                        <br />
                        <h6>Bold Indicates the Category Selection is Complete</h6>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.datasetgroupmodal}
                    onClose={handleCloseDataSetInfoModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'scroll', maxHeight: '85%' }} className="contentHolder">
                        <div className='row'>
                            <div style={{ textAlign: 'left', width: '50%' }}><h4>{'More Information for ' + this.state.selectedDataSetName}</h4></div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <IconButton
                                    onClick={handleCloseDataSetInfoModal}
                                >
                                    <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3>Long Description: </h3>
                                <p> {this.state.dataset_for_modal.datasetinfo.description} </p>
                            </div>
                            <div className='row col-sm-12' style={{ overflowY: 'scroll', maxHeight: '500px', padding:'25px' }}>
                                <div className='col-sm-12'>
                                    <h3>Attributes:</h3>
                                </div>
                                {this.state.dataset_for_modal.datasetattrsettings_data.map(attr => {
                                    var attribute_values = []
                                    var attribute = ""
                                    if (attr.attribute_values) {
                                        attribute_values = attr.attribute_values
                                        attribute = attr.attribute
                                    } else if (attr.global_attribute_values) {
                                        attribute_values = attr.global_attribute_values
                                        attribute = attr.global_attribute
                                    }
                                    return <div className='col-sm-4' style={{ overflowY: 'scroll', maxHeight: '450px', padding: '25px' }} key={attr.attribute}>
                                        <h6>{this.state.dataset_for_modal.attr_lookup[attribute]}</h6>
                                        <ul>
                                            { attribute_values.map(value => { return <li key={value}>{value}</li> }) }
                                        </ul>
                                    </div>
                                })}
                            </div>
                            <div className='col-sm-12' style={{ height:'50px'}}></div>
                            <div className='col-sm-12' style={{ overflowY: 'scroll', maxHeight: '450px', padding: '25px' }}>
                                <h3>Brands:</h3>
                                <p>{this.state.dataset_for_modal.datasetbrands_data.join(', ')}</p>
                            </div>
                            <div className='col-sm-12' style={{ height: '50px' }}></div>
                            <div className='col-sm-12'>
                                <h3>Discount Band Definitions:</h3>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <h4>Discount Range Name</h4>
                                            </TableCell>
                                            <TableCell>
                                                <h4>Range Start</h4>
                                            </TableCell>
                                            <TableCell>
                                                <h4>Range Stop</h4>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.dataset_for_modal.datasetdiscbands_data.map(band => {
                                            return <TableRow key={band.discount_range}>
                                                <TableCell>
                                                    {band.discount_range_name}
                                                </TableCell>
                                                <TableCell>
                                                    {(band.discount_range_min || "").toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                </TableCell>
                                                <TableCell>
                                                    {(band.discount_range_max || "").toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div name='banner' className='banner'>
                    <h1white align='center'>All Datasets</h1white><br/>
                    <h2white align='center'>Below is a list of all Datasets in the tool you have access to.</h2white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    {this.state.stagingdatasets_exist === true ?
                        <div className='contentHolder' >
                            <InfoRoundedIcon style={{ fontSize: '1.875rem' }} />
                            Staging Datasets Exist. &nbsp;
                            <span
                                style={{
                                    color: '#4e106f',
                                    cursor: 'pointer',
                                    fontWeight: 'bold'
                                }}
                                onClick={openStagingDatasets}
                            >
                                Click Here
							</span>
                            &nbsp; to Review
                        </div>
                        :
                        <div/>
                    }

                    <div className='contentHolder'>
                        <div className='row'>
                            <div className='col-sm-12'><h4>Filters</h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Search for a Dataset</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Industry</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Country</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Store Groups</span></h4></div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="datasetdropdown"
                                    options={this.state.datasetnames.map(dataset => {
                                        return { 'value': dataset, 'label': dataset }
                                    })}
                                    placeholder={'Select Datasets to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'datasets')}
                                    styles={selectColourStyles}
                                />
                                <br/>
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="industrydropdown"
                                    options={this.state.industries.map(industry => {
                                        return { 'value': industry, 'label': industry || "Unknown" }
                                    })}
                                    placeholder={'Select Industries to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'industries')}
                                    styles={selectColourStyles}
                                />
                                <br />
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="countrydropdown"
                                    options={this.state.countries.map(country => {
                                        return { 'value': country, 'label': this.state.countrynamelookup[country] || 'Unknown' }
                                    })}
                                    placeholder={'Select Countries to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'countries')}
                                    styles={selectColourStyles}
                                />
                                <br />
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="storegroupdropdown"
                                    options={this.state.storegroups.map(storegroup => {
                                        return { 'value': storegroup, 'label': storegroup }
                                    })}
                                    placeholder={'Select Store Groups to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'storegroups')}
                                    styles={selectColourStyles}
                                />
                                <br />
                            </div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Category</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Subcategory</span></h4></div>
                            <div className='col-sm-3'><h4><span className='formfieldtitle'>Time Aggregation</span></h4></div>
                            <div className='col-sm-3'></div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="categorydropdown"
                                    options={this.state.categories.map(category => {
                                        return { 'value': category, 'label': category }
                                    })}
                                    placeholder={'Select Categories to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'categories')}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="subcategorydropdown"
                                    options={this.state.subcategories.map(subcategory => {
                                        return { 'value': subcategory, 'label': subcategory }
                                    })}
                                    placeholder={'Select Subcategories to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'subcategories')}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className='col-sm-3'>
                                <Select
                                    inputId="timeaggdropdown"
                                    options={this.state.timeaggs.map(timeagg => {
                                        return { 'value': timeagg, 'label': timeagg }
                                    })}
                                    placeholder={'Select Time Aggregations to filter to'}
                                    isMulti
                                    onChange={(options, filterby) => filterDatasets(options, 'timeaggregations')}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className='col-sm-3'></div>
                            <div className='col-sm-12'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        checked={this.state.useCompleteCats}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Use Full Categories Only' }}
                                        onChange={handleToggle_useCompleteCats}
                                    />
                                    Use Full Categories Only
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-6'><h4><span className='formfieldtitle'>Dataset Includes Time Range</span></h4></div>
                            <div className='col-sm-6'><h4 style={{ textAlign: 'right' }}><span className='formfieldtitle' >Dataset Last updated Between</span></h4></div>
                            <div className="col-sm-3" style={{ backgroundColor: '#FFFFFF', padding: '0px' }}>
                                <DateRangePicker
                                    onChange={handleDates_TimeRange}
                                    value={this.state.DatevaluesTimeRange}
                                    calendarIcon={<DateRangeRoundedIcon style={{ color: '#4e106f' }} />}
                                    clearIcon={<ClearRoundedIcon style={{ color: '#822433' }} />}
                                    className={"testForDatePicker"}
                                />
                            </div>
                            <div className='col-sm-3'></div>
                            <div className='col-sm-3'></div>
                            <div className="col-sm-3" style={{ backgroundColor: '#FFFFFF', padding: '0px' }}>
                                <DateRangePicker
                                    onChange={handleDates_LastUpdated}
                                    value={this.state.DatevaluesLastUpdated}
                                    calendarIcon={<DateRangeRoundedIcon style={{ color: '#4e106f' }} />}
                                    clearIcon={<ClearRoundedIcon style={{ color: '#822433' }} />}
                                    className={"testForDatePicker"}
                                />
                            </div>
                            <div className='col-sm-12' style={{ height: '50px' }}></div>
                            <div className='col-sm-12'><b style={{ color: "#4e106f" }}> Bold Indicates that the Category Selection is Complete </b></div>
                        </div>
                    </div>

                    <Table id="DatasetsTable">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }}>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[0]}
                                        direction={this.state.header_sort_direction[0]}
                                        onClick={(event) => sortData(event, 'Name')}
                                    >
                                        <h4>Extract Name</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[1]}
                                    direction={this.state.header_sort_direction[1]}
                                    onClick={(event) => sortData(event, 'Country')}
                                    >
                                        <h4>Country</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[2]}
                                    direction={this.state.header_sort_direction[2]}
                                    onClick={(event) => sortData(event, 'Industry')}
                                    >
                                        <h4>Industry</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[3]}
                                    direction={this.state.header_sort_direction[3]}
                                    onClick={(event) => sortData(event, 'Category')}
                                    >
                                        <h4>Categories</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[4]}
                                    direction={this.state.header_sort_direction[4]}
                                        onClick={(event) => sortData(event, 'SubCategory')}
                                    >
                                        <h4>SubCategories</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[5]}
                                    direction={this.state.header_sort_direction[5]}
                                    onClick={(event) => sortData(event, 'StoreGroup')}
                                    >
                                        <h4>Store Groups</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[6]}
                                    direction={this.state.header_sort_direction[6]}
                                    onClick={(event) => sortData(event, 'DateRange')}
                                    >
                                        <h4>Date Range</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[7]}
                                    direction={this.state.header_sort_direction[7]}
                                    onClick={(event) => sortData(event, 'AggLevel')}
                                    >
                                        <h4>Aggregation Level</h4>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                    active={this.state.header_sort_active[8]}
                                    direction={this.state.header_sort_direction[8]}
                                    onClick={(event) => sortData(event, 'LastModified')}
                                    >
                                        <h4>Last Updated</h4>
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.state.datasetsdata || []).map((dataset) => {
                                let showdataset = dataset.showdataset
                                //Define Variables in Style Here...
                                let countryname = this.state.countrynamelookup[dataset.country] || 'Unknown'
                                let industryname = dataset.industry_name || "Unknown"
                                let timeagg = dataset.time_aggregation
                                //Format Date...
                                let lastupdateddate = dataset.time_updated
                                let datasetstart = (dataset.times || {})['min']
                                let datasetend = (dataset.times || {})['max']

                                let dt = new Date()
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                let dateupdatedformatted = (new Date(Date.parse(lastupdateddate) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                                var datasetstartformatted, datasetendformatted, timeaggdesc
                                if (timeagg === 'Weekly') {
                                    datasetstartformatted = (new Date(Date.parse(datasetstart) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                                    datasetendformatted = (new Date(Date.parse(datasetend) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                                    timeaggdesc = 'Week Ending'
                                } else {
                                    datasetstartformatted = (new Date(Date.parse(datasetstart) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                    datasetendformatted = (new Date(Date.parse(datasetend) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                    timeaggdesc = ''
                                }

                                let storegroups = dataset.store_groups || []
                                let levels = dataset.levels || []
                                let categories = (levels.items || []).map(level => level.sel_name)

                                let fulllevel = dataset.complete_at_level
                                let full_cat = false
                                if (fulllevel === 0) {
                                    full_cat = true
                                }

                                let categories_full = (dataset.levels.items || []).map(level => full_cat)
                                let subcategories = []
                                let numcategories = categories.length
                                let numcategoriesfull = 0
                                categories_full.forEach(cat => { if (cat) { numcategoriesfull += 1 } })

                                let fulldataset = false
                                if (numcategories === numcategoriesfull && numcategories > 0) {
                                    fulldataset = true
                                }

                                (levels.items || []).forEach(level => ((level.items || []).forEach(sublevel => subcategories.push(sublevel.sel_name))))

                                if (showdataset) {
                                    return <TableRow key={dataset.dataset_id}>
                                        <TableCell style={{ width: '5%' }}>
                                            <IconButton
                                                onClick={(event)=> sendToCreateReportForm(event, dataset.dataset_id)}
                                            >
                                                <Tooltip
                                                    title={'Create a new report with the ' + dataset.short_desc + ' dataset'}
                                                    placement="top-start"
                                                >
                                                    <AddBoxIcon style={{ color: '#4e106f', fontSize: '1vw' }} />
                                                </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <span style={{
                                                color: '#4e106f',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                            }}
                                                onClick={(event) => handleOpenDataSetInfoModal(event, dataset.dataset_id, dataset)}
                                            >
                                                {dataset.short_desc}
                                            </span>
                                        </TableCell>
                                        <TableCell> {countryname}</TableCell>
                                        <TableCell> {industryname}</TableCell>
                                        <TableCell> {categories.map((cat, idx) => {
                                            let sep = ", "
                                            if (idx === (numcategories - 1)) {
                                                sep = ""
                                            }

                                            if (categories_full[idx]) {
                                                return <b style={{ color: "#4e106f" }} key={cat + sep}>{cat + sep}</b>
                                            } else {
                                                return <span key={cat + sep}>{cat + sep}</span>
                                            }
                                        })}
                                        </TableCell>
                                        {subcategories.length > 5 ?
                                            <TableCell>
                                                <span style={{
                                                    color: '#4e106f',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                    onClick={(event) => handleOpenSubCategoryModal(event, levels, dataset.short_desc )}
                                                >
                                                    {'Click Here to See all ' + subcategories.length + ' Subcategories'}
					                            </span>
                                            </TableCell>
                                            :
                                            <TableCell>{subcategories.join(',')}</TableCell>
                                        }
                                        {storegroups.length > 5 ?
                                            <TableCell>
                                                <span style={{
                                                    color: '#4e106f',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                    onClick={(event) => handleOpenStoreGroupModal(event, storegroups, dataset.short_desc )}
                                                >
                                                    {'Click Here to See all ' + storegroups.length + ' Store Groups'}
                                                </span>
                                            </TableCell>
                                            :
                                            <TableCell> {storegroups.map(sg => sg.storegpdesc).join(',')}  </TableCell>
                                        }
                                        <TableCell> {timeaggdesc + ' ' + datasetstartformatted + ' to ' + timeaggdesc + ' ' + datasetendformatted} </TableCell>
                                        <TableCell> {timeagg} </TableCell>
                                        <TableCell> {dateupdatedformatted} </TableCell>
                                    </TableRow>
                                } else {
                                    return null
                                }
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Layout>
        )
    }
}
