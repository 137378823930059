import React, { Component } from 'react';
import { BrandItemsSwitch } from '../../UIElements/Switches/BrandItemsSwitch'
import { MultiItemSelect } from '../../UIElements/Dropdowns/MultiItemSelect'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
import { SmoothingWeekSelections } from '../../UIElements/Dropdowns/SmoothingWeekSelections'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import WeeklyViewMultipleSeries from '../../../Graphs/WeeklyViewMultipleSeries'
import movingAvg from '../../Functions/Utilities/MovingAvg'
import * as Constant from '../../../constants';

export class BaseVelocity extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }
    
    render() {

        
        //Series List
        var seriesList, selectedBrand, yAxisType, selectedBrands, aggtype, weekly_data
        aggtype = this.props.state.datasettimeagg
        selectedBrand = this.props.state.selectedBrand

        if (this.props.state.viewItems) {
            yAxisType = 'units_items'
            selectedBrands = this.props.state.selectedItems
            weekly_data = this.props.state.item_data_weekly
            seriesList = this.props.state.selectedItems.map((item, idx) => {
                return {
                    name: item,
                    type: 'spline',
                    color: Constant.graph_colors[idx],
                    yAxis: 0,
                    tooltip: {
                        pointFormatter: function () {
                            let dt = new Date(this.x)
                            let offset = dt.getTimezoneOffset() * 60 * 1000
                            let date = new Date(this.x + offset)
                            if (aggtype === 'Monthly') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }
                            else {
                                return '<span>Week Ending:'
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }
                        }
                    },
                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                    (((this.props.state.item_data_weekly[item] || {})[date] || {})['baseunits']) / (((this.props.state.item_data_weekly[item] || {})[date] || {})['carrieddoors'] / ((this.props.state.item_data_weekly[item] || {})[date] || {})['totaldoors_s1'])
                    ]),
                    zIndex: 1
                }
            }).concat(this.props.state.selectedItems.map((item, idx) => {
                let basevelos = (this.props.state.distinctdates).sort().map(date => (((this.props.state.item_data_weekly[item] || {})[date] || {})['baseunits']) / (((this.props.state.item_data_weekly[item] || {})[date] || {})['carrieddoors'] / ((this.props.state.item_data_weekly[item] || {})[date] || {})['totaldoors_s1']))
                let movingavgs = movingAvg(basevelos, this.props.state.smoothingWeekNum)
                return {
                    name: this.props.state.smoothingWeekNum + ' ' + this.props.state.timeperiodlabels + ' Moving Average - ' + item,
                    type: 'spline',
                    color: Constant.graph_colors[idx],
                    dashStyle: 'DashDot',
                    yAxis: 0,
                    tooltip: {
                        pointFormatter: function () {
                            let dt = new Date(this.x)
                            let offset = dt.getTimezoneOffset() * 60 * 1000
                            let date = new Date(this.x + offset)
                            //Check Dataset agg
                            if (aggtype === 'Monthly') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }

                        }
                    },
                    data: (this.props.state.distinctdates).sort().map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), movingavgs[idx]]),
                    zIndex: 1
                }
            })
            )
        }
        else {
            yAxisType = 'units_brands'
            selectedBrands = this.props.state.selectedBrands
            weekly_data = this.props.state.weekly_data
            seriesList = this.props.state.selectedBrands.map((brand, idx) => {
                return {
                    name: brand,
                    type: 'spline',
                    color: Constant.graph_colors[idx],
                    yAxis: 0,
                    tooltip: {

                        pointFormatter: function () {
                            let dt = new Date(this.x)
                            let offset = dt.getTimezoneOffset() * 60 * 1000
                            let date = new Date(this.x + offset)
                            if (aggtype === 'Monthly') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }
                            else {
                                return '<span>Week Ending:'
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }
                        }
                    },
                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                    ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['baseunits']) / ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldoors_s1'] / (((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldoors_s1_segment'])
                    ]),
                    zIndex: 1
                }
            }).concat(this.props.state.selectedBrands.map((brand, idx) => {
                let basevelos = (this.props.state.distinctdates).sort().map(date => ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['baseunits']) / ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldoors_s1'] / (((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldoors_s1_segment']))
                let movingavgs = movingAvg(basevelos, this.props.state.smoothingWeekNum)
                return {
                    name: this.props.state.smoothingWeekNum + ' ' + this.props.state.timeperiodlabels + ' Moving Average - ' + brand,
                    type: 'spline',
                    color: Constant.graph_colors[idx],
                    dashStyle: 'DashDot',
                    yAxis: 0,
                    tooltip: {
                        pointFormatter: function () {
                            let dt = new Date(this.x)
                            let offset = dt.getTimezoneOffset() * 60 * 1000
                            let date = new Date(this.x + offset)
                            //Check Dataset agg
                            if (aggtype === 'Monthly') {
                                return '<span>Month: '
                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }
                            else {
                                return '<span>Week Ending: '
                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                    + '<br>'
                                    + this.series.name
                                    + '<br> '
                                    + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    + '</span>'
                            }

                        }
                    },
                    data: (this.props.state.distinctdates).sort().map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), movingavgs[idx]]),
                    zIndex: 1
                }
            })
            )
        }

        return (
            <div style={{ width: "100%", height: "100%"}}>
                <div className="row" >
                    <div align='left' className="col-sm-12" >
                        <BrandItemsSwitch
                            state={this.props.state}
                            handleChange_BrandsItemsSwitch={this.props.handleChange_BrandsItemsSwitch}
                        />
                    </div>
                    {this.props.state.viewItems ?
                        <div align='left' className="col-sm-12" >
                            <MultiItemSelect
                                state={this.props.state}
                                changeSelectedItemMulti={this.props.changeSelectedItemMulti}
                            />
                        </div>
                        :
                        <div align='left' className="col-sm-12" >
                            <MultiBrandSelect
                                state={this.props.state}
                                changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                            />
                        </div>
                    }
                    <div align='left' className="col-sm-12" >
                        <SmoothingWeekSelections
                            state={this.props.state}
                            changesmoothingWeekNum={this.props.changesmoothingWeekNum}
                        />
                    </div>
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%"}}>                        
                        <WeeklyViewMultipleSeries
                            chart_title={"Base Velocity by " + this.props.state.xAxisText}
                            chart_title_info={this.props.state.chart_title_info}
                            unitsvar={this.props.state.unitsvar}
                            seriesList={seriesList}
                            distinctdates={this.props.state.distinctdates}
                            weekly_data={weekly_data}
                            selectedBrand={selectedBrand}
                            showDataTable={this.props.state.showDataTable}
                            showDataTableStyle={this.props.state.showDataTableStyle}
                            weekagg={this.props.state.filter_object.numweeks}
                            height={1}
                            attr_caption_text={this.props.state.attr_caption_text}
                            yAxisTitle='Base Velocity'
                            yAxisType={yAxisType}
                            selectedBrands={selectedBrands}
                            aggperiod={this.props.state.datasettimeagg}
                            currencycode={this.props.state.currencycode}
                            currencytype={this.props.state.currencyType}
                            metadata={this.props.state.chartmetadata}
                        />                                                    
                    </div>
                </div>
            </div>
        )
    }
}