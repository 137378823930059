export default function filterData(selectedOptions, event,) {
    console.log('Changing filterData')
    //Set Applied Filters
    let applied_filters = this.state.applied_filters
    if (selectedOptions && selectedOptions.length > 0) {
        if (event.name === 'ASP') {
            applied_filters[event.name] = selectedOptions
        }
        else {
            applied_filters[event.name] = selectedOptions.map(option => option.value)
        }
    }

    //If options are not provided, remove from applied_filters...i.e. no filter on that attribute
    else {
        delete applied_filters[event.name]
    }

    //console.log(applied_filters)

    this.setState({ applied_filters })

    //Compare Filters to the simulation results to reduce the set...
    let starting_items = this.state.dataset_info.items
    //let simitems = Object.keys(this.state.simulation_results)
    let simitems = this.state.simsettings.items.map(item => item.prod_id)
    let filter_columns = Object.keys(applied_filters)

    //console.log(simitems)

    if (filter_columns.length > 0) {
        var filtered_items = []
        filter_columns.map((col, idx) => {
            if (idx === 0) {
                var items_to_filter = starting_items
            }
            else {
                var items_to_filter = filtered_items
            }

            let filtered_items_new = []

            items_to_filter.map(item => {
                //console.log(item)
                //console.log(col)
                //console.log(applied_filters[col])
                //console.log(item[col])
                //console.log(simitems)
                if (col === 'ASP') {
                    //ASP Is within the filtered set
                    if (this.state.basecalcresultasp[item.prodid] >= applied_filters[col][0] && this.state.basecalcresultasp[item.prodid] <= applied_filters[col][1] && simitems.includes(item.prodid)) {
                        filtered_items_new.push(item)
                    }
                }
                else if (applied_filters[col].includes(item[col]) && simitems.includes(item.prodid)) { //.toString()
                    filtered_items_new.push(item)
                }
            })

            filtered_items = filtered_items_new
        })
    }

    else {
        var filtered_items = []
        starting_items.map(item => {
            if (simitems.includes(item.prodid)) {
                filtered_items.push(item)
            }
        })
    }

    //console.log(filtered_items)

    //Use the filtered_items to wittle down the simulation results...
    if (filtered_items.length > 0) {
        var filtered_item_ids = filtered_items.map(item => item.prodid)
    }
    else {
        var filtered_item_ids = simitems //this.state.simsettings.items.map(item => item.prod_id)
    }

    //From the filtered_items, create the list of item options, brands, attributes

    //console.log(starting_items)
    let brands = []
    let brand_options = []
    let item_options = []

    filtered_item_ids.map(prodid => {
        if (!brands.includes(this.state.simulation_results[prodid].brand)) {
            brands.push(this.state.simulation_results[prodid].brand)
            brand_options.push({ 'label': this.state.simulation_results[prodid].brand, 'value': this.state.simulation_results[prodid].brand })
        }
        this.state.dataset_info.items.map(iteminfo => {
            if (iteminfo.prodid === prodid) {
                item_options.push({ 'label': iteminfo.proddesc, 'value': prodid })
            }
        })
    })

    //walk through the simitems and if we find a match, push its attribute values to valid attributes
    let valid_attributes = {}
    let dataset_attributes = this.state.dataset_info.attributes
    let attributes = dataset_attributes.map(attr => attr.attribute)
    attributes.map(attr => valid_attributes[attr] = [])
    this.state.dataset_info.items.map(item => {
        if (filtered_item_ids.includes(item.prodid)) {
            attributes.map(attr => {
                if (!valid_attributes[attr].includes(item[attr])) {
                    valid_attributes[attr].push(item[attr])
                }
            })
        }
    })

    console.log(valid_attributes)

    let attribute_options = {}
    dataset_attributes.map(attr => { attribute_options[attr.attribute] = valid_attributes[attr.attribute].map(val => { return { 'label': val, 'value': val } }) })

    this.setState({ brand_options, item_options, selected_item: item_options[0].value, selected_brands: brands[0], attribute_options })

    //Finally Run the filtered data through the calc_metrics function:

    if (this.state.simsettings.sim_type === 'base') {
        this.calc_metrics_base_new(this.state.simulation_results, filtered_item_ids, item_options[0].value, brands[0])
    }
    else {
        this.calc_metrics_promo_new(this.state.simulation_results, filtered_item_ids, item_options[0].value, brands[0])
    }
}