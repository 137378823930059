export default function handleCloseUsererrorAlert () {
    this.setState({
        errorAlert: false
        , errorAlert_report: false
        , reportloaddialog: false
        , errorAlert_runcompg1: false
        , errorAlert_runcompg2: false
        , errorAlert_runcompg1g2: false
        , errorAlert_noAccess: false
    })
}
