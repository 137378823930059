import React, { Component, Fragment } from 'react';
import { NavMenu } from './NavMenu';
import { PNPSideBar, PDTSideBar, PDTETLSideBar, PNPETLSideBar, PDTRetailerSideBar } from './Sidebar';
import * as Constant from './constants'

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        document.title = this.props.title;
    }
    render() {
        var appname = Constant.whereamilist[3]
        
        return (
            <div style={{ width: 'calc(100vw - 0px)', minWidth: '1320px'}}>
                <NavMenu />
                {appname === 'PNP' ?                    
                    <PNPSideBar />                    
                    :
                    appname === 'PDT' ?                        
                        <PDTSideBar />
                        :
                        appname === 'PDTETL' ?
                            <PDTETLSideBar />
                            :
                            appname === 'PNPETL' ?
                                <PNPETLSideBar />
                                :
                                appname === 'PDTRetailer' ?
                                    <PDTRetailerSideBar />
                                    :
                                    <Fragment/> 
                }                
                {this.props.children}
            </div>
        );
    }
}