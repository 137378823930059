import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'

const FairShare = props => {
    console.log('FairShare.js props',props)
    var totalMetric, subMetric, promoMetric
    const reducer = (accumulator, currentValue) => (accumulator || 0) + (currentValue || 0)
    if (props.propsCalcType === 'currency') {
        totalMetric = 'totaldollars'
        subMetric = 'subsidizedbasedollars'
        promoMetric = 'promoteddollars'
    }
    else {
        totalMetric = 'totalunits'
        subMetric = 'subsidizedbaseunits'
        promoMetric = 'promotedunits'
    }


    //Determine the number of categories.....
    let numsub = props.SubData.length
    let numunsum = 0 //props.UnSubData.length
    let numinc = 0 //props.IncData.length

    let numcats = Math.max(numsub, numunsum, numinc)

    console.log(numcats)
    let numcats_array = Array.from(Array(numcats).keys())

    return (
            <Fragment>
                <HighchartsReact
                    highcharts={Highcharts}
                    oneToOne={true}
                    options={
                        {
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'column',
                                height: (9 / 16 * 100) + '%',
                            },
                            metadata: props.chartmetadata,
                            caption: {
                                text: props.attr_caption_text
                            },
                            title: {
                                text: 'Fair Share of Subsidization' + props.chart_title_info
                            },
                            series: [
                                {
                                    'name': 'Total ' + props.shareType + ' Share',
                                    'data': numcats_array.map((item, idx) => {
                                        let totaldollars = props.SubData[idx] + props.UnSubData[idx] + props.IncData[idx]
                                        let cattotdollars = props.distinctdates_forcalc.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[totalMetric]).reduce(reducer)
                                        //let cattotdollars = props.distinctdates.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[totalMetric]).reduce(reducer)
                                        return totaldollars / cattotdollars
                                    }),
                                    'type': 'column',
                                    yAxis: 0,
                                    color: '#7d32ba'
                                },

                                {
                                    'name': 'Subsidized ' + props.shareType + ' Share',
                                    'data': numcats_array.map((item, idx) => {
                                        let totaldollars = props.SubData[idx] 
                                        let cattotdollars = props.distinctdates_forcalc.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[subMetric]).reduce(reducer)
                                        //let cattotdollars = props.distinctdates.sort().map(date => (((props.weekly_data_selected || {})['Total Category'] || {})[date] || {})[subMetric]).reduce(reducer)
                                        return totaldollars / cattotdollars
                                    }),
                                    'type': 'column',
                                    yAxis: 0,
                                    color: '#bc81ec'
                                },

                                {
                                    'name': 'Promoted ' + props.shareType + ' Share',
                                    'data': numcats_array.map((item, idx) => {
                                        let totaldollars = props.PromoData[idx]
                                        let cattotdollars = props.distinctdates_forcalc.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[promoMetric]).reduce(reducer)
                                        //let cattotdollars = props.distinctdates.sort().map(date => (((props.weekly_data_selected || {})['Total Category'] || {})[date] || {})[promoMetric]).reduce(reducer)
                                        return totaldollars / cattotdollars
                                    }),
                                    'type': 'column',
                                    yAxis: 0,
                                    color: '#b0df97'
                                },

                                {
                                    'name': 'Subsidized ' + props.shareType + ' Share to Total ' + props.shareType + ' Share Ratio',
                                    'data': numcats_array.map((item, idx) => {
                                        let totaldollars = props.SubData[idx] + props.UnSubData[idx] + props.IncData[idx]
                                        let cattotdollars = props.distinctdates_forcalc.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[totalMetric]).reduce(reducer)
                                        //let cattotdollars = props.distinctdates.sort().map(date => (((props.weekly_data_selected || {})['Total Category'] || {})[date] || {})[totalMetric]).reduce(reducer)
                                        let subdollars = props.SubData[idx]
                                        let catsubdollars = props.distinctdates_forcalc.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[subMetric]).reduce(reducer)
                                        //let catsubdollars = props.distinctdates.sort().map(date => (((props.weekly_data_selected || {})['Total Category'] || {})[date] || {})[subMetric]).reduce(reducer)
                                        let ratio = (subdollars / catsubdollars) / (totaldollars / cattotdollars)
                                        return ratio
                                    }),
                                    'type': 'line',
                                    lineWidth: 0,
                                    marker: {
                                        enabled: true,
                                        radius: 8,
                                        symbol: 'diamond'
                                    },
                                    states: {
                                        hover: {
                                            lineWidthPlus: 0
                                        }
                                    },
                                    yAxis: 1,
                                    zIndex: 1,
                                    color: '#ff9971'
                                },


                            ],
                            xAxis: {
                                title: {
                                    text: ''
                                },
                                categories: props.categories,
                            },
                            yAxis: [
                                {
                                    gridLineColor: "#CCC",
                                    gridLineWidth: 1,
                                    title: {
                                        text: 'Total/Subsidized ' + props.shareType + ' Share by ' + props.level
                                    },
                                    labels: {
                                        formatter: function () {
                                            return this.value.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 })
                                        }
                                    }
                                },
                                {
                                    gridLineColor: "#CCC",
                                    gridLineWidth: 1,
                                    title: {
                                        text: 'Ratio of Subsidized ' + props.shareType + ' Share to Total ' + props.shareType + ' Share'
                                    },
                                    opposite: true
                                }
                            ],
                            plotOptions: {
                                column: {
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function () {
                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                        },
                                        color: 'black',
                                        borderRadius: 5,
                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                        borderWidth: 1,
                                        borderColor: '#d6d7d8',
                                        padding: 2,
                                        style: {
                                            textOutline: 'none',
                                            fontSize: '20px'
                                        },
                                    }
                                },


                            },
                            tooltip: {
                                formatter: function () {
                                    if (this.series.name === 'Subsidized ' + props.shareType + ' Share to Total ' + props.shareType + ' Share Ratio') {
                                        return '<span> '
                                            + this.series.name
                                            + '<br/>'
                                            + this.x
                                            + '<br/>'
                                            + this.y.toLocaleString()
                                    }
                                    else {
                                        return '<span> '
                                            + this.series.name
                                            + '<br/>'
                                            + this.x
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }

                                }
                            },
                            exporting: {
                                showTable: props.showDataTable,
                                tableCaption: false
                            },
                        }}
                />
                <div style={{ display: props.showDataTableStyle }}>
                    <Constant.NPDTable>
                        <Constant.NPDTableBody>
                            <Constant.NPDTableRow key="header_g3">
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{props.level}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Total ' + props.shareType + ' Share'}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Subsidized ' + props.shareType + ' Share'}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Subsidized ' + props.shareType + ' Share to Total ' + props.shareType + ' Share Ratio'}</Constant.NPDTableCell>
                            </Constant.NPDTableRow>
                        {numcats_array.map((item,idx) => {
                                let totaldollars = props.SubData[idx] + props.UnSubData[idx] + props.IncData[idx]
                                let cattotdollars = props.distinctdates_forcalc.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[totalMetric]).reduce(reducer)
                                //let cattotdollars = props.distinctdates.sort().map(date => (((props.weekly_data_selected || {})['Total Category'] || {})[date] || {})[totalMetric]).reduce(reducer)
                                let subdollars = props.SubData[idx]
                                let catsubdollars = props.distinctdates_forcalc.sort().map(date => (((props.weekly_data_category || {})['Total Category'] || {})[date] || {})[subMetric]).reduce(reducer)
                                //let catsubdollars = props.distinctdates.sort().map(date => (((props.weekly_data_selected || {})['Total Category'] || {})[date] || {})[subMetric]).reduce(reducer)
                                let totalval = (totaldollars / cattotdollars)
                                let subval = (subdollars / catsubdollars)
                                let ratio = (subdollars / catsubdollars) / (totaldollars / cattotdollars)
                                return <Constant.NPDTableRow key={item + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                        {item}
                                    </Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{totalval.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{subval.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{ratio.toLocaleString()}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            })
                            }
                        </Constant.NPDTableBody>
                    </Constant.NPDTable>
                </div>
            </Fragment>            
    )
}

export default FairShare;
        