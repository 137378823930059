import * as Constant from '../constants'
import React, { Component, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GenericDialog } from './UIElements/Generic/GenericDialog';
import { OutputSection } from './SimOutput/OutputSection';
import { TotalCategorySection } from './SimOutput/TotalCategorySection';
import { CompareSection } from './SimOutput/CompareButton';
import { CriteriaSection } from './SimOutput/CriteriaSection';
import { FilterSection } from './SimOutput/FilterSection';
import { Layout } from '../Layout';
import SimToolTips from './IconBar_Simulation'
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
//Functions
import findid from './Functions/Utilities/FindID'
import getsimsettings from './Functions/Simulation/GetSimSettings'
import getdatasetinfo from './Functions/Simulation/GetDataSetInfo'
import getstartingvalues from './Functions/Simulation/GetStartingValues'
import getgroups from './Functions/Simulation/GetGroups'
import getcompsims from './Functions/Simulation/GetCompSims'
import runsimulation from './Functions/Simulation/RunSimulation'
//Calculations
import calc_metrics_brands from './Functions/Simulation/Calculations/BrandMetrics'
import calc_metrics_base_new from './Functions/Simulation/Calculations/BaseMetricsNew'
import calc_metrics_promo_new from './Functions/Simulation/Calculations/PromoMetricsNew'
import calc_metrics_total_cat from './Functions/Simulation/Calculations/TotalCatMetrics'
//UI Functions
import handleCloseUsererrorAlert from './Functions/HandleCloseUserError'
import handleCloseUsererrorAlertPriv from './Functions/HandleCloseUserPriv'
import changeBrandSelected from './Functions/ChangeBrandSelected'
import changeItemGroupSelected from './Functions/ChangeItemGroup'
import changeItemSelected from './Functions/ChangeItemSelected'
import changeBrandSelectedTotalCat from './Functions/ChangeBrandSelectedTotalCat'
import filterData from './Functions/FilterData'

export class SimulationOutputAdjusted extends Component {
    static displayName = SimulationOutputAdjusted.name;
    constructor(props) {
        super(props);
        this.state = {
            showBackground: true,
            errorAlert: false,
            errorAlert_priveleges: false,
            simsettings: {},
            dataset_info: {},
            simulation_results: [],
            selected_brands: null,
            selected_brands_totalcat: null,
            brand_options: [],
            item_options: [],
            itemgroup_options: [],
            //Base Volume, Base Price, and Disc %
            basecalcresultvol: {},
            basecalcresultasp: {},
            basecalcresultdis: {},
            //Brand Base Vols
            brand_base_vols: {},
            brand_inc_vols: {},
            comp_item_changes_brand: {},
            comp_item_changes_item: {},
            comp_item_changes_brand_dollars: {},
            comp_item_changes_item_dollars: {},
            brand_base_dols: {},
            brand_inc_dols: {},            
            allSimsForCompare: [],            
            unitsvar: "Units",
            starting_base_volume: 0,
            base_volume_change: 0,
            starting_base_volumes: [],
            base_vol_changes: [],
            applied_filters: {},
            attribute_lookup: {},
            currencycode: 'USD',
            currencytype: 'Dollars',
            currencysymbol: '$'
        }
    }

    async componentDidMount() {
        //settings
        this.getsimsettings = getsimsettings.bind(this)
        this.getdatasetinfo = getdatasetinfo.bind(this)
        this.getstartingvalues = getstartingvalues.bind(this)
        this.getgroups = getgroups.bind(this)
        this.getcompsims = getcompsims.bind(this)
        this.runsimulation = runsimulation.bind(this)
        //calculations
        this.calc_metrics_brands = calc_metrics_brands.bind(this)
        this.calc_metrics_base_new = calc_metrics_base_new.bind(this)
        this.calc_metrics_promo_new = calc_metrics_promo_new.bind(this)
        this.calc_metrics_total_cat = calc_metrics_total_cat.bind(this)
        //ui 
        this.handleCloseUsererrorAlert = handleCloseUsererrorAlert.bind(this)
        this.handleCloseUsererrorAlertPriv = handleCloseUsererrorAlertPriv.bind(this)
        this.changeBrandSelected = changeBrandSelected.bind(this)
        this.changeItemGroupSelected = changeItemGroupSelected.bind(this)
        this.changeItemSelected = changeItemSelected.bind(this)
        this.changeBrandSelectedTotalCat = changeBrandSelectedTotalCat.bind(this)
        this.filterData = filterData.bind(this)

        const simid = findid();
        //Get Sim Settings
        await this.getsimsettings(simid)
        //Get Dataset Info
        await this.getdatasetinfo(this.state.datasetid, this.state.items, this.state.geo, this.state.start, this.state.end)
        //Get Starting Info
        await this.getstartingvalues(simid, this.state.datasetid, this.state.items, this.state.geo, this.state.start, this.state.end, this.state.item_dict_lookup)
        //Find groups
        await this.getgroups(this.state.datasetid, this.state.items, this.state.item_dict_lookup)
        //Find Comparable Simulations
        await this.getcompsims(simid)
        //Run the Simulation
        await this.runsimulation(simid, this.state.item_dict_lookup)
    }

    render() {
        //console.log(this.state)

        return (
            <Layout title="Simulation Output">
                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <GenericDialog
                    openVal={this.state.errorAlert}
                    onCloseFunc={this.handleCloseUsererrorAlert}
                    title={"Simulation Error"}
                    content={"There was an error running the simulation"}
                />

                <GenericDialog
                    openVal={this.state.errorAlert_priveleges}
                    onCloseFunc={this.handleCloseUsererrorAlertPriv}
                    title={"Insufficient Priveleges"}
                    content={"You do not have access to this simulation.  You will now be sent back to the home screen."}
                />

                <div className="outputStyle contentHolderGradient">
                    <SimToolTips state={this.state} />

                    <div className="row" style={{ paddingLeft: '40px' }}>
                        <div className="col-sm-7">
                            <h3>{this.state.simsettings.sim_name}</h3>
                        </div>
                        <div className="col-sm-5 row" style={{ alignContent: 'right', paddingRight: '0px' }}>
                            <div className="col-sm-12" style={{ paddingRight: '0px', alignContent: 'right', margin: '0px' }} align='right'></div>
                        </div>
                    </div>

                    <CompareSection
                        allSimsForCompare={this.state.allSimsForCompare || []}
                    />

                    <CriteriaSection
                        state={this.state}
                    />

                    <FilterSection
                        state={this.state}
                        filterData={this.filterData}
                    />


                    <div className="col-sm-12 contentHolder simOutputGraphs" align='center'>
                        <h5 style={{ padding: '10px' }}>Simulation Summary</h5>
                        {this.state.simsettings.sim_type === 'base' ?
                            <p> The simulation resulted in a change of {Math.round(this.state.base_volume_change_total).toLocaleString()} {this.state.unitsvar} or {(this.state.base_volume_change_total / this.state.starting_base_volume_total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })} change in base volume for the simulated items.</p>
                            :
                            <p> The simulation resulted in {Math.round(this.state.incremental_volume_total).toLocaleString()} incremental {this.state.unitsvar} or a lift of {(this.state.incremental_volume_total / this.state.starting_base_volume_total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })} for the simulated items.</p>
                        }
                    </div>

                    <br />
                    <OutputSection
                        state={this.state}
                        sectiontitle="Only Items That Have Simulated Price Changes"
                        level='All'
                        firstGraphData={
                            this.state.simsettings.sim_type === 'base' ?
                                {
                                    series1: [this.state.base_volume_change_total],
                                    series2: [this.state.diff_base_dollars_total],
                                    series3: [(this.state.base_volume_change_total / this.state.starting_base_volume_total)],
                                    series4: [(this.state.diff_base_dollars_total / this.state.starting_base_dollars_total)],
                                    categories: ['All Items']
                                }
                                :
                                {
                                    series1: [this.state.incremental_volume_total],
                                    series2: [this.state.incremental_dollars_total],
                                    series3: [(this.state.incremental_volume_total / this.state.starting_base_volume_total)],
                                    series4: [(this.state.incremental_dollars_total / this.state.starting_base_dollars_total)],
                                    categories: ['All Items']
                                }
                        }
                        secondGraphData={
                            {
                                waterfall_data: this.state.waterfall_data,
                                categories: this.state.waterfall_categories
                            }
                        }
                        metricsData={
                            {
                                starting_base_volume_total: this.state.starting_base_volume_total,
                                base_volume_change_total: this.state.base_volume_change_total,
                                starting_base_dollars_total: this.state.starting_base_dollars_total,
                                incremental_dollars_total: this.state.incremental_dollars_total,
                                diff_base_dollars_total: this.state.diff_base_dollars_total,
                                new_base_dollars_total: this.state.new_base_dollars_total,
                                incremental_volume_total: this.state.incremental_volume_total,
                                promo_volume_total: this.state.promo_volume_total,
                                nonpromo_volume_total: this.state.nonpromo_volume_total,
                                promodollar_total: this.state.promodollar_total,
                                nonpromodollar_total: this.state.nonpromodollar_total,
                                discountdollar_total: this.state.discountdollar_total,
                                unsubsidized_base_volume_total: this.state.unsubsidized_base_volume_total,
                                subsidized_base_volume_total: this.state.subsidized_base_volume_total,
                                unsubsidized_base_dollars_total: this.state.unsubsidized_base_dollars_total,
                                subsidized_base_dollars_total: this.state.subsidized_base_dollars_total
                            }
                        }

                        financialData={
                            {
                                gross_revenue_total: this.state.gross_revenue_total,
                                incremental_profit_total: this.state.incremental_profit_total,
                                total_spend_total: this.state.total_spend_total,
                                net_revenue_total: this.state.net_revenue_total,
                                incremental_gross_revenue_total: this.state.incremental_gross_revenue_total,
                                incremental_net_revenue_total: this.state.incremental_net_revenue_total,
                                incremental_profit_total: this.state.incremental_profit_total,
                                retailer_promo_margin_dollars_total: this.state.retailer_promo_margin_dollars_total,
                                retailer_eday_margin_dollars_total: this.state.retailer_eday_margin_dollars_total,
                                retailer_promotion_profit_total: this.state.retailer_promotion_profit_total,
                                ttl_cost_per_addl_unit_total: this.state.ttl_cost_per_addl_unit_total
                            }
                        }

                        thirdGraphData={
                            {
                                title: 'Payback Ratio - Aggregate of All Items',
                                data: [(this.state.incremental_dollars_total / this.state.discountdollar_total)],
                                categories: ['All Items']
                            }
                        }
                    />

                    <OutputSection
                        state={this.state}
                        sectiontitle="Brand View of the Items That Have Simulated Changes"
                        level='Brand'
                        firstGraphData={
                            this.state.simsettings.sim_type === 'base' ?
                                {
                                    series1: Object.values(this.state.total_volume_changes_brand || {}),
                                    series2: Object.values(this.state.diff_total_dollars_brand || {}),
                                    series3: Object.values(this.state.total_volume_changes_brand || {}).map((value, idx) => (value / Object.values(this.state.starting_volume_brand || {})[idx])),
                                    series4: Object.values(this.state.diff_total_dollars_brand || {}).map((value, idx) => (value / Object.values(this.state.start_total_dollars_brand || {})[idx])),
                                    categories: Object.keys(this.state.starting_volume_brand || {})
                                }
                                :
                                {
                                    series1: Object.values(this.state.total_volume_changes_brand || {}),
                                    series2: Object.values(this.state.diff_total_dollars_brand || {}),
                                    series3: Object.values(this.state.total_volume_changes_brand || {}).map((value, idx) => (value / Object.values(this.state.starting_volume_brand || {})[idx])),
                                    series4: Object.values(this.state.diff_total_dollars_brand || {}).map((value, idx) => (value / Object.values(this.state.start_total_dollars_brand || {})[idx])),
                                    categories: Object.keys(this.state.starting_volume_brand || {})
                                }
                        }
                        secondGraphData={
                            {
                                waterfall_data: (this.state.waterfall_data_brand || {})[this.state.selected_brands],
                                categories: this.state.waterfall_categories
                            }
                        }
                        metricsData={
                            this.state.simsettings.sim_type === 'base' ?
                                {
                                    starting_base_volume_total: this.state.starting_base_volume_brand,
                                    base_volume_change_total: this.state.total_volume_change_brand,
                                    starting_base_dollars_total: this.state.old_total_dollar_brand,
                                    incremental_dollars_total: this.state.incdollar_total_brand,
                                    diff_base_dollars_total: this.state.diff_total_dollar_brand,
                                    new_base_dollars_total: this.state.old_total_dollar_brand,
                                    incremental_volume_total: this.state.total_volume_change_brand,
                                    promo_volume_total: this.state.total_promo_volume_brand,
                                    nonpromo_volume_total: this.state.total_nonpromo_volume_brand,
                                    promodollar_total: this.state.promodollar_total_brand,
                                    nonpromodollar_total: this.state.nonpromodollar_total_brand,
                                    discountdollar_total: this.state.discountdollar_total_brand,
                                    unsubsidized_base_volume_total: this.state.unsubsidized_base_volume_total_brand,
                                    subsidized_base_volume_total: this.state.subsidized_base_volume_total_brand,
                                    unsubsidized_base_dollars_total: this.state.unsubsidized_base_dollars_total_brand,
                                    subsidized_base_dollars_total: this.state.subsidized_base_dollars_total_brand
                                }
                                :
                                {
                                    starting_base_volume_total: this.state.starting_base_volume_brand,
                                    base_volume_change_total: this.state.base_volume_change_brand,
                                    starting_base_dollars_total: this.state.old_total_dollar_brand,
                                    incremental_dollars_total: this.state.incdollar_total_brand,
                                    diff_base_dollars_total: this.state.diff_base_dollars_brand,
                                    new_base_dollars_total: this.state.old_total_dollar_brand,
                                    incremental_volume_total: this.state.total_volume_change_brand,
                                    promo_volume_total: this.state.total_promo_volume_brand,
                                    nonpromo_volume_total: this.state.total_nonpromo_volume_brand,
                                    promodollar_total: this.state.promodollar_total_brand,
                                    nonpromodollar_total: this.state.nonpromodollar_total_brand,
                                    discountdollar_total: this.state.discountdollar_total_brand,
                                    unsubsidized_base_volume_total: this.state.unsubsidized_base_volume_total_brand,
                                    subsidized_base_volume_total: this.state.subsidized_base_volume_total_brand,
                                    unsubsidized_base_dollars_total: this.state.unsubsidized_base_dollars_total_brand,
                                    subsidized_base_dollars_total: this.state.subsidized_base_dollars_total_brand
                                    
                                }
                        }

                        financialData={
                            this.state.simsettings.sim_type === 'base' ?
                                {
                                    gross_revenue_total: (this.state.old_total_dollar_brand + this.state.diff_total_dollar_brand),
                                    incremental_profit_total: this.state.incremental_profit_total_brand,
                                    total_spend_total: this.state.total_spend_total_brand,
                                    net_revenue_total: this.state.net_revenue_total_brand,
                                    incremental_gross_revenue_total: this.state.incremental_gross_revenue_total_brand,
                                    incremental_net_revenue_total: this.state.incremental_net_revenue_total_brand,
                                    incremental_profit_total: this.state.incremental_profit_total_brand,
                                    retailer_promo_margin_dollars_total: this.state.retailer_promo_margin_dollars_total_brand,
                                    retailer_eday_margin_dollars_total: this.state.retailer_eday_margin_dollars_total_brand,
                                    retailer_promotion_profit_total: this.state.retailer_promotion_profit_total_brand
                                }
                                :
                                {
                                    gross_revenue_total: this.state.gross_revenue_total_brand,
                                    incremental_profit_total: this.state.incremental_profit_total_brand,
                                    total_spend_total: this.state.total_spend_total_brand,
                                    net_revenue_total: this.state.net_revenue_total_brand,
                                    incremental_gross_revenue_total: this.state.incremental_gross_revenue_total_brand,
                                    incremental_net_revenue_total: this.state.incremental_net_revenue_total_brand,
                                    incremental_profit_total: this.state.incremental_profit_total_brand,
                                    retailer_promo_margin_dollars_total: this.state.retailer_promo_margin_dollars_total_brand,
                                    retailer_eday_margin_dollars_total: this.state.retailer_eday_margin_dollars_total_brand,
                                    retailer_promotion_profit_total: this.state.retailer_promotion_profit_total_brand,
                                    ttl_cost_per_addl_unit_total: this.state.ttl_cost_per_addl_unit_total_brand
                                }
                        }

                        thirdGraphData={
                            {
                                title: 'Payback Ratio - By Brand',
                                data: Object.keys(this.state.starting_volume_brand || {}).map(brand => {
                                    return ((this.state.incdollar_brand || {})[brand] / (this.state.discountdollar_brand || {})[brand])
                                }),
                                categories: Object.keys(this.state.starting_volume_brand || {})
                            }
                        }
                        changeBrandSelected={this.changeBrandSelected}
                    />

                    {(this.state.selectedgroups || []).length > 0 ?
                        <OutputSection
                            state={this.state}
                            sectiontitle="Item Group View"
                            level='Item Group'
                            firstGraphData={
                                this.state.simsettings.sim_type === 'base' ?
                                    {
                                        series1: Object.values(this.state.total_volume_changes_itemgroup || {}),
                                        series2: Object.values(this.state.diff_total_dollars_itemgroup || {}),
                                        series3: Object.values(this.state.total_volume_changes_itemgroup || {}).map((value, idx) => (value / Object.values(this.state.starting_volume_itemgroup || {})[idx])),
                                        series4: Object.values(this.state.diff_total_dollars_itemgroup || {}).map((value, idx) => (value / Object.values(this.state.start_total_dollars_itemgroup || {})[idx])),
                                        categories: Object.keys(this.state.starting_volume_itemgroup || {})
                                    }
                                    :
                                    {
                                        series1: Object.values(this.state.total_volume_changes_itemgroup || {}),
                                        series2: Object.values(this.state.diff_total_dollars_itemgroup || {}),
                                        series3: Object.values(this.state.total_volume_changes_itemgroup || {}).map((value, idx) => (value / Object.values(this.state.starting_volume_itemgroup || {})[idx])),
                                        series4: Object.values(this.state.diff_total_dollars_itemgroup || {}).map((value, idx) => (value / Object.values(this.state.start_total_dollars_itemgroup || {})[idx])),
                                        categories: Object.keys(this.state.starting_volume_itemgroup || {})
                                    }
                            }
                            secondGraphData={
                                {
                                    waterfall_data: (this.state.waterfall_data_itemgroup || {})[this.state.selected_itemgroup],
                                    categories: this.state.waterfall_categories
                                }
                            }
                            metricsData={
                                this.state.simsettings.sim_type === 'base' ?
                                    {
                                        starting_base_volume_total: this.state.starting_base_volume_itemgroup,
                                        base_volume_change_total: this.state.total_volume_change_itemgroup,
                                        starting_base_dollars_total: this.state.old_total_dollar_itemgroup,
                                        incremental_dollars_total: this.state.incdollar_total_itemgroup,
                                        diff_base_dollars_total: this.state.diff_total_dollar_itemgroup,
                                        new_base_dollars_total: this.state.old_total_dollar_itemgroup,
                                        incremental_volume_total: this.state.total_volume_change_itemgroup,
                                        promo_volume_total: this.state.total_promo_volume_itemgroup,
                                        nonpromo_volume_total: this.state.total_nonpromo_volume_itemgroup,
                                        promodollar_total: this.state.promodollar_total_itemgroup,
                                        nonpromodollar_total: this.state.nonpromodollar_total_itemgroup,
                                        discountdollar_total: this.state.discountdollar_total_itemgroup,
                                        unsubsidized_base_volume_total: this.state.unsubsidized_base_volume_total_itemgroup,
                                        subsidized_base_volume_total: this.state.subsidized_base_volume_total_itemgroup,
                                        unsubsidized_base_dollars_total: this.state.unsubsidized_base_dollars_total_itemgroup,
                                        subsidized_base_dollars_total: this.state.subsidized_base_dollars_total_itemgroup
                                    }
                                    :
                                    {
                                        starting_base_volume_total: this.state.starting_base_volume_itemgroup,
                                        base_volume_change_total: this.state.base_volume_change_itemgroup,
                                        starting_base_dollars_total: this.state.old_total_dollar_itemgroup,
                                        incremental_dollars_total: this.state.incdollar_total_itemgroup,
                                        diff_base_dollars_total: this.state.diff_base_dollars_itemgroup,
                                        new_base_dollars_total: this.state.old_total_dollar_itemgroup,
                                        incremental_volume_total: this.state.total_volume_change_itemgroup,
                                        promo_volume_total: this.state.total_promo_volume_itemgroup,
                                        nonpromo_volume_total: this.state.total_nonpromo_volume_itemgroup,
                                        promodollar_total: this.state.promodollar_total_itemgroup,
                                        nonpromodollar_total: this.state.nonpromodollar_total_itemgroup,
                                        discountdollar_total: this.state.discountdollar_total_itemgroup,
                                        unsubsidized_base_volume_total: this.state.unsubsidized_base_volume_total_itemgroup,
                                        subsidized_base_volume_total: this.state.subsidized_base_volume_total_itemgroup,
                                        unsubsidized_base_dollars_total: this.state.unsubsidized_base_dollars_total_itemgroup,
                                        subsidized_base_dollars_total: this.state.subsidized_base_dollars_total_itemgroup
                                    }
                            }

                            financialData={
                                this.state.simsettings.sim_type === 'base' ?
                                    {
                                        gross_revenue_total: (this.state.old_total_dollar_itemgroup + this.state.diff_total_dollar_itemgroup),
                                        incremental_profit_total: this.state.incremental_profit_total_itemgroup,
                                        total_spend_total: this.state.total_spend_total_itemgroup,
                                        net_revenue_total: this.state.net_revenue_total_itemgroup,
                                        incremental_gross_revenue_total: this.state.incremental_gross_revenue_total_itemgroup,
                                        incremental_net_revenue_total: this.state.incremental_net_revenue_total_itemgroup,
                                        incremental_profit_total: this.state.incremental_profit_total_itemgroup,
                                        retailer_promo_margin_dollars_total: this.state.retailer_promo_margin_dollars_total_itemgroup,
                                        retailer_eday_margin_dollars_total: this.state.retailer_eday_margin_dollars_total_itemgroup,
                                        retailer_promotion_profit_total: this.state.retailer_promotion_profit_total_itemgroup
                                    }
                                    :
                                    {
                                        gross_revenue_total: this.state.gross_revenue_total_itemgroup,
                                        incremental_profit_total: this.state.incremental_profit_total_itemgroup,
                                        total_spend_total: this.state.total_spend_total_itemgroup,
                                        net_revenue_total: this.state.net_revenue_total_itemgroup,
                                        incremental_gross_revenue_total: this.state.incremental_gross_revenue_total_itemgroup,
                                        incremental_net_revenue_total: this.state.incremental_net_revenue_total_itemgroup,
                                        incremental_profit_total: this.state.incremental_profit_total_itemgroup,
                                        retailer_promo_margin_dollars_total: this.state.retailer_promo_margin_dollars_total_itemgroup,
                                        retailer_eday_margin_dollars_total: this.state.retailer_eday_margin_dollars_total_itemgroup,
                                        retailer_promotion_profit_total: this.state.retailer_promotion_profit_total_itemgroup,
                                        ttl_cost_per_addl_unit_total: this.state.ttl_cost_per_addl_unit_total_itemgroup
                                    }
                            }

                            thirdGraphData={
                                {
                                    title: 'Payback Ratio - By Item Group',
                                    data: Object.keys(this.state.starting_volume_itemgroup || {}).map(brand => {
                                        return ((this.state.incdollar_itemgroup || {})[brand] / (this.state.discountdollar_itemgroup || {})[brand])
                                    }),
                                    categories: Object.keys(this.state.starting_volume_itemgroup || {})
                                }
                            }
                            changeItemGroupSelected={this.changeItemGroupSelected}
                        />
                        :
                        <Fragment />
                    }

                    <OutputSection
                        state={this.state}
                        sectiontitle="Only Items That Have Simulated Price Changes​"
                        level='Item'
                        firstGraphData={{
                            series1: [(this.state.total_volume_changes_item || {})[this.state.selected_item]],
                            series2: [(this.state.diff_total_dollars_item || {})[this.state.selected_item]],
                            series3: [(this.state.total_volume_changes_item || {})[this.state.selected_item] / (this.state.starting_volume_item || {})[this.state.selected_item]],
                            series4: [(this.state.diff_total_dollars_item || {})[this.state.selected_item] / (this.state.start_total_dollars_item || {})[this.state.selected_item]],
                            categories: [(this.state.item_dict_lookup || {})[this.state.selected_item]]
                        }}                                                        
                        secondGraphData={
                            {
                                waterfall_data: (this.state.waterfall_data_item || {})[this.state.selected_item],
                                categories: this.state.waterfall_categories
                            }
                        }
                        metricsData={
                            this.state.simsettings.sim_type === 'base' ?
                                {
                                    starting_base_volume_total: this.state.starting_base_volume_item,
                                    base_volume_change_total: this.state.total_volume_change_item,
                                    starting_base_dollars_total: this.state.old_total_dollar_item,
                                    incremental_dollars_total: this.state.incdollar_total_item,
                                    diff_base_dollars_total: this.state.diff_total_dollar_item,
                                    new_base_dollars_total: this.state.old_total_dollar_item,
                                    incremental_volume_total: this.state.total_volume_change_item,
                                    promo_volume_total: this.state.total_promo_volume_item,
                                    nonpromo_volume_total: this.state.total_nonpromo_volume_item,
                                    promodollar_total: this.state.promodollar_total_item,
                                    nonpromodollar_total: this.state.nonpromodollar_total_item,
                                    discountdollar_total: this.state.discountdollar_total_item,
                                    unsubsidized_base_volume_total: this.state.unsubsidized_base_volume_total_item,
                                    subsidized_base_volume_total: this.state.subsidized_base_volume_total_item,
                                    unsubsidized_base_dollars_total: this.state.unsubsidized_base_dollars_total_item,
                                    subsidized_base_dollars_total: this.state.subsidized_base_dollars_total_item
                                }
                                :
                                {
                                    starting_base_volume_total: this.state.starting_base_volume_item,
                                    base_volume_change_total: this.state.base_volume_change_item,
                                    starting_base_dollars_total: this.state.old_total_dollar_item,
                                    incremental_dollars_total: this.state.incdollar_total_item,
                                    diff_base_dollars_total: this.state.diff_base_dollars_item,
                                    new_base_dollars_total: this.state.old_total_dollar_item,
                                    incremental_volume_total: this.state.total_volume_change_item,
                                    promo_volume_total: this.state.total_promo_volume_item,
                                    nonpromo_volume_total: this.state.total_nonpromo_volume_item,
                                    promodollar_total: this.state.promodollar_total_item,
                                    nonpromodollar_total: this.state.nonpromodollar_total_item,
                                    discountdollar_total: this.state.discountdollar_total_item,
                                    unsubsidized_base_volume_total: this.state.unsubsidized_base_volume_total_item,
                                    subsidized_base_volume_total: this.state.subsidized_base_volume_total_item,
                                    unsubsidized_base_dollars_total: this.state.unsubsidized_base_dollars_total_item,
                                    subsidized_base_dollars_total: this.state.subsidized_base_dollars_total_item
                                    
                                }
                        }

                        financialData={
                            this.state.simsettings.sim_type === 'base' ?
                                {
                                    gross_revenue_total: (this.state.old_total_dollar_item + this.state.diff_total_dollar_item),
                                    incremental_profit_total: this.state.incremental_profit_total_item,
                                    total_spend_total: this.state.total_spend_total_item,
                                    net_revenue_total: this.state.net_revenue_total_item,
                                    incremental_gross_revenue_total: this.state.incremental_gross_revenue_total_item,
                                    incremental_net_revenue_total: this.state.incremental_net_revenue_total_item,
                                    incremental_profit_total: this.state.incremental_profit_total_item,
                                    retailer_promo_margin_dollars_total: this.state.retailer_promo_margin_dollars_total_item,
                                    retailer_eday_margin_dollars_total: this.state.retailer_eday_margin_dollars_total_item,
                                    retailer_promotion_profit_total: this.state.retailer_promotion_profit_total_item
                                }
                                :
                                {
                                    gross_revenue_total: this.state.gross_revenue_total_item,
                                    incremental_profit_total: this.state.incremental_profit_total_item,
                                    total_spend_total: this.state.total_spend_total_item,
                                    net_revenue_total: this.state.net_revenue_total_item,
                                    incremental_gross_revenue_total: this.state.incremental_gross_revenue_total_item,
                                    incremental_net_revenue_total: this.state.incremental_net_revenue_total_item,
                                    incremental_profit_total: this.state.incremental_profit_total_item,
                                    retailer_promo_margin_dollars_total: this.state.retailer_promo_margin_dollars_total_item,
                                    retailer_eday_margin_dollars_total: this.state.retailer_eday_margin_dollars_total_item,
                                    retailer_promotion_profit_total: this.state.retailer_promotion_profit_total_item,
                                    ttl_cost_per_addl_unit_total: this.state.ttl_cost_per_addl_unit_total_item

                                }
                        }

                        thirdGraphData={
                            {
                                title: 'Payback Ratio - By Item',
                                data: Object.keys(this.state.starting_volume_item || {}).map(brand => {
                                    return ((this.state.incdollar_item || {})[brand] / (this.state.discountdollar_item || {})[brand])
                                }),
                                categories: Object.keys(this.state.starting_volume_item || {}).map(item => (this.state.item_dict_lookup || {})[item])
                            }
                        }
                        changeItemSelected={this.changeItemSelected}                        
                    />

                    <TotalCategorySection
                        state={this.state}
                        changeBrandSelectedTotalCat={this.changeBrandSelectedTotalCat}
                    />
                </div>
            </Layout>        
        )
    }
}
