import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export class Dialogs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    open={this.props.state.reportloaddialog}
                    onClose={this.props.handleCloseDataLoadingAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">{"Data Loading"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Data is loading in the background for this report.  It should take a few minutes to complete.
                            While the report is loading, you may work in any other open tabs of your browser, or any other applications
                            you have open on your machine.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseDataLoadingAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.errorAlert_report}
                    onClose={this.props.handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"No Rows Found"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            One or more errors occurred while loading this report.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.errorAlert}
                    onClose={this.props.handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Please Select a Metric"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You must Select a metric to run a comparison.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.errorAlert_runcompg1}
                    onClose={this.props.handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Please Select a Metric"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There were no rows found for Group 1.  Please change your selection criteria.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.errorAlert_runcompg2}
                    onClose={this.props.handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Please Select a Metric"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There were no rows found for Group 2.  Please change your selection criteria.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.errorAlert_runcompg1g2}
                    onClose={this.props.handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Please Select a Metric"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There were no rows found both Group 1 and Group 2.  Please change your selection criteria.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.errorAlert_noAccess}
                    onClose={this.props.handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"You do not have access to this report"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Either you do not have access to this report, or the report no longer exists.
                            <br/>
                            Please contact Pricing.Support@NPD.com
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.changeInFilters_alert}
                    onClose={this.props.handleClosechangeInFiltersAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Filters Reset
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The previously saved filter for the report does not match the values in the dataset.  In order for the report to complete, the saved filter has been cleared and reset to include all values.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleClosechangeInFiltersAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.DateCheck_alert}
                    onClose={this.props.handleCloseDateCheckAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Start Date Greater Than End Date
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.state.DateCheck_alert_text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseDateCheckAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.props.state.ScreenOrderAlert}
                    onClose={this.props.handleCloseScreenOrderAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        No Report Visuals Selected
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            No visuals are selected for report output.  Please go <a href='/PDT/ScreenOrdering'> HERE </a> to define what visuals to see.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.handleCloseScreenOrderAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}
