import CreateWeeksNew from '../../Utilities/CreateWeeks'
import { reducer } from '../../Utilities/Reducer'
import { isROM } from '../../Utilities/IsROM'
import removeItemOnce from '../../Utilities/RemoveItemOnce'
import replaceEmptyArrays from '../../Utilities/RemoveItemOnce'
import findPercentile from '../../Utilities/FindPercentile'
import findPercentileValue from '../../Utilities/FindPercentileValue'
import * as Constant from '../../../../constants'

export default async function setSelectedItem_new(selectedOptions, position) {
    this.setState({ isLoaded: false })
    //Functions

    //Variables
    //Get the ID from the dropdown
    console.log(selectedOptions)
    let item = selectedOptions.value //this.state.item_lookup_reverse[selectedOptions.value]

    console.log('Running APIs')
    let reportid = this.state.reportsettings.report_id
    var filters_raw = this.state.filter_object
    let url_itemaspdist = Constant.baseapiurl + "ReportData/Filtered/ItemASPDistribution/" + reportid + this.state.extraparam
    //Calc Dates            
    let weeks_info = CreateWeeksNew(this.state.filter_object, this.state.times_data, this.state.datasettimeagg)

    //clean up filters
    //Use the Weeks Info to Pass the Curr Range, and Prior Range to the API
    let filters = {}

    //Rework values so if just an array of All make empty

    //Rework Attributes
    let apifilterattrs = Object.keys(filters_raw['attributes']).map(attr => {
        return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All')]
    })

    filters['brands'] = removeItemOnce(filters_raw['brands'], 'All')
    filters['exclude_brands'] = this.state.exclude_array[0]
    filters['items'] = removeItemOnce(filters_raw['items'], 'All')
    filters['exclude_items'] = this.state.exclude_array[1]
    filters['week_aggregation'] = filters_raw['numweeks']
    filters['asp_range'] = filters_raw['asp_range']
    filters['date_range'] = [weeks_info.startdate, weeks_info.enddate]
    filters['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
    filters['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
    filters['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
    filters['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
    filters['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
    filters['attributes'] = apifilterattrs
    filters['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
    filters['price_bands_defs'] = this.state.priceBandOptions
    filters['selected_item_aspdist'] = item
    filters['channels'] = removeItemOnce(filters_raw['channels'], 'All')
    //Add price_dist_option
    filters['price_dist_option'] = [this.state.price_dist_option || 'asp']

    let itemaspdist_obj = {}
    let itemaspdist_response = await fetch(url_itemaspdist,
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filters)
        }
    )

    if (itemaspdist_response.status === 200) {
        itemaspdist_obj = await itemaspdist_response.json()
    } else {
        console.log('***** SetSelectedItemNew.js setSelectedItem_new failed to fetch ' + url_itemaspdist +
                    ' with status ' + itemaspdist_response.status + ', setting errorAlert_report = true')
        itemaspdist_obj = {}
        this.setState({ errorAlert_report: true })
    }

    let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
        return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
    })

    filters['brands'] = replaceEmptyArrays(filters_raw['brands'])
    filters['exclude_brands'] = this.state.exclude_array[0]
    filters['items'] = replaceEmptyArrays(filters_raw['items'])
    filters['exclude_items'] = this.state.exclude_array[1]
    filters['week_aggregation'] = filters_raw['numweeks']
    filters['asp_range'] = filters_raw['asp_range']
    filters['date_range'] = [weeks_info.startdate, weeks_info.enddate]
    filters['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
    filters['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
    filters['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
    filters['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
    filters['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
    filters['attributes'] = apifilterattrs_replaced
    //filters['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
    filters['price_bands_defs'] = this.state.priceBandOptions
    filters['channels'] = replaceEmptyArrays(filters_raw['channels'])

    let histdataItem_outlet_new = itemaspdist_obj['item_outlet_data']
    let histdataItem_outlet_weekly = itemaspdist_obj['item_outlet_week_data']
    let outlet_data_weekly_item = itemaspdist_obj['item_outlet_weeky_metric_data']
    let item_brand_lookup = itemaspdist_obj['item_brand_lookup']

    let outlets = this.state.outlets
    let rom_outlet_idx = outlets.findIndex(isROM)
    let retailer_outlet_idx = 1
    if (rom_outlet_idx === 1) {
        retailer_outlet_idx = 0
    }

    console.log(histdataItem_outlet_new)

    let pricesobj_ROM = histdataItem_outlet_new[item][outlets[rom_outlet_idx]] || {}
    let pricesobj_RETAILER = histdataItem_outlet_new[item][outlets[retailer_outlet_idx]] || {}
    let percentiles = [.05, .10, .15, .20, .25, .30, .35, .40, .45, .50, .55, .60, .65, .70, .75, .80, .85, .90, .95]

    console.log(pricesobj_RETAILER)

    //Calculate Average Price for Retailer....
    let totalPrice = 0
    Object.keys(pricesobj_RETAILER || {}).forEach(price => {
        totalPrice += price * pricesobj_RETAILER[price]
    })
    let numunits = [0].concat(Object.values(pricesobj_RETAILER || {})).reduce(reducer)
    let averageASP = totalPrice / numunits
    let numunits_rom = [0].concat(Object.values(pricesobj_ROM || {})).reduce(reducer)
    //Run through the distributions....

    let percentile_vals = percentiles.map(p => { return findPercentile(p, numunits_rom, pricesobj_ROM) })
    let aspPercentileVal = findPercentileValue(averageASP, numunits_rom, pricesobj_ROM)

    let itemDistYAxisMin = Math.floor(percentile_vals[0] / 25) * 25
    //aspPercentAxisVal = (Math.round(aspPercentileVal) / .05) - 1 //-1 comes from 0 index

    //Set State
    this.setState({
        selectedItem_new: item
        , percentile_vals
        , aspPercentileVal
        , ASPRetailerItem: averageASP.toLocaleString(undefined, { style: 'currency', currency: this.state.currencycode })
        , itemDistYAxisMin
        , histdataItem_outlet_new
        , histdataItem_outlet_weekly
        , outlet_data_weekly_item
        , item_brand_lookup
        , isLoaded: true
    })
}
