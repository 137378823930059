import React, { Component, Fragment } from 'react'; 
import { withStyles } from '@material-ui/core/styles';
import { Layout } from './Layout';
import { PDTRecentAccess, PNPRecentAccess } from './RecentAccess';
import { CookieBar } from './CookieBar';
import { Tutorial } from './Tutorial';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
//import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
//import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
//import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import PDT from './images/PDT.png';
import PDTETL from './images/PDTETL.png';
import PNPETL from './images/PNPETL.png';
import PNP from './images/PNP.png';
import CreatePlan from './images/CreatePlan.png';
import CreateSim from './images/CreateSimulation.png';
import TotalIndustry from './images/TotalIndustry.png';



import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import Tooltip from '@material-ui/core/Tooltip';
import './styles/custom.css'
import logoreverse from "./images/logo.png"
import logobiggerreverse from "./images/logobiggerreverse.png"
//import intro_video from "./videos/PDT/PDT Intro.mp4"
import comingsoon from "./videos/PDT/ComingSoon.png"
import * as Constant from './constants'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 550,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export class Home extends Component {
    static displayName = Home.name;    

    constructor(props) {
        super(props);
        this.state = {
            userappsdata: [],
            usertype: "",
            env: null,
            showbackground: true
        }
    }

    async componentDidMount(){
        var userapps = await fetch(Constant.baseurl + 'account/isauthenticated')
        var userappsres = userapps.status
        if (userappsres === 200) {
            var userappsdata = await userapps.json()
            var usertype = 'False'
            var usertype_admin = 'False'
            if (userappsdata.internalUser === 'True' && (userappsdata.userRole.includes('Admin') || userappsdata.userRole.includes('Power User'))){
                usertype = 'True'
            }

            if (userappsdata.internalUser === 'True' && (userappsdata.userRole.includes('Admin'))) {
                usertype_admin = 'True'
            }            

                        
            
            let userinforesp = await fetch(Constant.baseapiurl_gen + 'SessionUser/Details')
            var userinfo, companyid, is_manufacturer, is_retailer
            if (userinforesp.status === 200) {
                userinfo = await userinforesp.json()
                companyid = userinfo.company_id
                is_manufacturer = userinfo.is_manufacturer
                is_retailer = userinfo.is_retailer
            }
            //determine if the user is retailer or manufacturer            
            var retailer_company = false, internal_company = false, manufacturer_company = false
           
            if (companyid === 1) {
                internal_company = true
            }

            else if (is_retailer) {                
                manufacturer_company = true
                //The logic below needs to be implemented when we are ready to launch.  Until then, we will set all as manufacturers
                //retailer_company = true
            }

            else if (is_manufacturer) {
                manufacturer_company = true
            }

            //default to manufacturer company
            else {
                manufacturer_company = true
            }
            
            
            

            this.setState({
                userappsdata: userappsdata.applicationList,
                usertype: usertype,
                usertype_admin: usertype_admin,
                retailer_company,
                internal_company,
                manufacturer_company
            })
        }
        else {
            console.log('There was an error!')
        }

        //Get Environment...
        fetch(Constant.baseurl + 'environment/getenvironment')
            .then(res => res.text())
            .then(
                (result) => {
                    this.setState({ env: result, showbackground: false })
                },
                (error) => {
                    this.setState({ env: 'Unknown' })
                }
            )
    }

    render() {        
        console.log(this.state.env)
        console.log(this.state)
        const pricepromodesc = "See reports on past promotion events and simulate new promotion events.";
        const pricepromonoaccess = "See reports on past promotion events and simulate new promtion events.  For more information click here.";

        const pdtdesc = "See trends related to price and discounts.";
        const pdtnoaccess = "See trends related to price and discounts.  For more information click here.";

        const pdtretailerdesc = "See trends related to price and discounts.";
        const pdtretailernoaccess = "See trends related to price and discounts.  For more information click here.";

        const pdtwholeindustry = "See total industry views.";
        const pdtwholeindustrynoaccess = "See trends related to price and discounts.  For more information click here.";

        const pdtetldesc = "Create, edit and run an extract for Price and Discount Trends";
        const pnpetldesc = "Create, edit and run an extract for Price and Promotion";
        
        return (
            <div style={{ backgroundColor: '#000000', height: '100%', width: '100vw', minHeight: '100vh', minWidth: '1320px' }} >
                <Constant.NPDBackdropWhiteout
                    open={this.state.showbackground}
                    invisible={false}
                />

                <Layout title="Circana Analytics" >
                    <div className="row col-sm-8 bannerfull" style={{ paddingBottom: '15px', paddingRight: '0px', paddingLeft: '0px', margin: '0px' }} >
                        <div className="col-sm-4 " style={{ padding: '0px', textAlign: 'center' }}></div>
                        <div className="col-sm-4 " style={{/* padding: '10px',*/ textAlign: 'center', alignContent: 'center'}}>
                            <img src={logoreverse} align='center' style={{
                                width: '300px',
                                height: '123px', padding: '5px' }} alt="NPDLogo" />
                        </div>
                        {/*<div className="col-sm-4 " style={{ padding: '0px', textAlign: 'center' }}></div>*/}

                        <div className="col-sm-12" style={{ padding: '0px', textAlign: 'center' }}>
                            <h1white align='center'>Welcome to Circana's Pricing Analytics Page</h1white><br/>
                        {/*    <h2white align='center'> Please click on an application below to get started</h2white>*/}
                        </div>
                        <div className="col-sm-12 " style={{ padding: '0px', textAlign: 'center', height: '0px' }}></div>
                    </div>

                    
                      



                    <div style={{ maxWidth: 'calc(100vw - 10px)' }} >
                        <div  style={{backgroundColor: '#000000', marginTop: '50px', paddingBottom: '15px',  }} >
                           <div className=" contentHolderBlack" style={{ textAlign: 'center',  }}>
                                    {this.state.userappsdata.includes('pricendiscounttrends') ?
                                        this.state.manufacturer_company ?
                                            <Fragment>
                                                <HtmlTooltip title={pdtdesc} interactive placement="top">
                                                <div  style={{ }}>
                                                        <a href="/PDT/Home" > <img src={PDT} className='homepagehoveractive' style={{backgroundColor:'#000000', width: '75px', height: '75px' }} /> </a>
                                                        <br />
                                                        <br/>
                                                        <span style={{ fontSize: '1.4rem', color: '#ffffff' }}>Price and Discount Trends</span>
                                                    </div>
                                                </HtmlTooltip>
                                            </Fragment>
                                                
                                            :
                                           

                                            <Fragment>
                                               
                                                <div className="row" style={{paddingLeft: '30px' }}>
                                                    <div className='col'>
                                                        <HtmlTooltip title={pdtdesc} interactive placement="top">
                                                            <div>
                                                                <a href="/PDT/Home" > <img src={PDT} className='homepagehoveractive' style={{ backgroundColor: '#000000', width: '75px', height: '75px' }}/> </a>
                                                                <br />
                                                                <br/>
                                                                <span style={{alignContent: 'center', fontSize: '1.4rem', color: '#ffffff' }}>Price and Discount Trends</span>
                                                            </div>
                                                        </HtmlTooltip>
                                                    </div>
                                                    

                                                    <div className='col'>
                                                        <HtmlTooltip title={pdtwholeindustry} interactive placement="top">
                                                            <div>
                                                                <a href="/PDT/WholeIndustry" > <img src={TotalIndustry} className='homepagehoveractive' style={{ backgroundColor: '#000000', width: '75px', height: '75px' }} /> </a>
                                                                <br />
                                                                <br />
                                                                <span style={{ fontSize: '1.4rem', color: '#ffffff' }}>Price and Discount Trends</span><br />
                                                                <span style={{ fontSize: '1.2rem', color: '#ffffff' }}>Total Industry</span>
                                                            </div>
                                                        </HtmlTooltip>
                                                    </div>
                                                </div>
                                            </Fragment>                                            
                                        :

                                            this.state.manufacturer_company ?
                                                <Fragment>
                                                    <HtmlTooltip title={
                                                        <Fragment>
                                                            {pdtnoaccess}<a href='/PDT/Home'> here. </a>
                                                        </Fragment>
                                                    } interactive placement="top">
                                                        <div  style={{ }}>
                                                            <a href="/PDT/Home" > <img src={PDT} style={{ backgroundColor: '#000000', width: '75px', height: '75px' }} /> </a>
                                                            <br />
                                                            <br />
                                                            <span style={{ fontSize: '1.4rem', color: '#333333' }}>Price and Discount Trends</span>
                                                        </div>
                                                </HtmlTooltip>
                                               
                                                </Fragment>
                                                :
                                               
                                                <Fragment>
                                               
                                                        <div className='col-sm-6'>
                                                            <HtmlTooltip title={
                                                                <Fragment>
                                                                    {pdtnoaccess}<a href='/PDT/Home'> here. </a>
                                                                </Fragment>
                                                            } interactive placement="top">
                                                                 <div className="row" style={{ }}>
                                                                    <a href="/PDT/Home" > <img src={PDT} /> </a>
                                                                    <br />
                                                                    <br />
                                                                 <span style={{ fontSize: '1.4rem', color: '#333333' }}>Price and Discount Trends</span>
                                                                </div>
                                                            </HtmlTooltip>
                                                        </div>
                                               
                                        </Fragment>


                                }
                                 
                                    {this.state.userappsdata.includes('priceandpromotion') ?
                                        <Fragment>
                                            <HtmlTooltip title={pricepromodesc} interactive placement="top">
                                                <div style={{paddingLeft: '30px' }}>   
                                                    <a href="/PNP/Home" > <img src={PNP} className='homepagehoveractive' style={{ backgroundColor: '#000000', width: '75px', height: '75px' }} /> </a>
                                                    <br />
                                                    <br />
                                                    <span style={{ fontSize: '1.4rem', color: '#ffffff' }}>Price and Promotion Simulator</span>
                                                </div>
                                            </HtmlTooltip>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <HtmlTooltip title={
                                                <Fragment>
                                                    {pricepromonoaccess}<a href='/PNP/Home'> here. </a>
                                                </Fragment>
                                            } interactive placement="top">
                                            <div style={{ paddingLeft: '30px'  }}>
                                                    <a href="/PNP/Home" > <img src={PNP} className='homepagehoveractive' style={{ backgroundColor: '#000000', width: '75px', height: '75px' }} /> </a>
                                                    {/* <a href="/PNP/Home" > <TrendingUpRoundedIcon style={{ fontSize: '8rem', color: '#333333' }} /> </a>*/}
                                                    <br />
                                                    <br />
                                                    <span style={{ align: 'center', fontSize: '1.4rem', color: '#333333' }}>Price and Promotion Simulator</span>
                                                </div>
                                            </HtmlTooltip>
                                        </Fragment>
                                    }
                                </div>
                        </div>

                        {this.state.usertype === 'True' ?
                            <div  style={{backgroundColor: '#000000', marginTop: '50px', paddingBottom: '15px',  }} >
                            <div className="contentHolderBlack  " style={{ textAlign: 'center' }} >
                               <div style={{display: 'flex', justifyContent:'center', padding: '20px'}}>
                                    <div className=" col" style={{ textAlign: 'center', align: 'center', padding: '20px' }}>
                                    <div  style={{ textAlign: 'center'}}>
                                        <HtmlTooltip title={pdtetldesc} interactive placement="top">
                                            <div>
                                                <a href="/PDTETL/EntryForm" > <img src={PDTETL} className='homepagehoveractiveetl' style={{ width: '50px', height: '50px'}} /> </a>
                                                <br />
                                                <br />
                                                    <span style={{ font: 'Roboto', fontSize: '1.1rem', color: '#7A4C93' }}>Price and Discount Trends</span><br/>
                                                    <span style={{ font: 'Roboto', fontSize: '1.1rem', color: '#7A4C93' }}>Data Extraction</span>
                                            </div>
                                        </HtmlTooltip>
                                    </div>
                                </div>
                                    <div className=" col" style={{ textAlign: 'center', padding: '20px' }}>
                                    <div  style={{ textAlign: 'center' }}>
                                        <HtmlTooltip title={pnpetldesc} interactive placement="top">
                                            <div>
                                                    <a href="/PNPETL/PNPEntryForm" > <img src={PNPETL} className='homepagehoveractiveetl' style={{ width: '50px', height: '50px' }} /> </a>
                                                <br />
                                                <br />
                                                    <span style={{ fontSize: '1.1rem', color: '#7A4C93' }}>Price and Promotion</span><br />
                                                    <span style={{ fontSize: '1.1rem', color: '#7A4C93' }}>Data Extraction</span>
                                            </div>
                                        </HtmlTooltip>
                                    </div>
                                </div>
                                    <div className=" col" style={{ textAlign: 'center', padding: '20px' }}>
                                    <div  style={{ textAlign: 'center' }}>
                                        <HtmlTooltip title={'Administrative Page'} interactive placement="top">
                                            <div>
                                                    <a href="/Admin" > <AdminPanelSettingsRoundedIcon className='homepagehoveractiveetl' style={{ color: '#7A4C93', width: '50px', height: '50px' }} /> </a>
                                                <br />
                                                <br />
                                                    <span style={{ fontSize: '1.1rem', color: '#7A4C93' }}>Administrative</span><br />
                                                    <span style={{ fontSize: '1.1rem', color: '#7A4C93' }}>Page</span>
                                            </div>
                                        </HtmlTooltip>
                                    </div>
                                    </div>
                                </div>
                               
                                   
                                </div>
                            </div>
                            :
                            <div />
                        }                        
                    </div>
                    <CookieBar />                        
                </Layout>
            </div>
                
            
        )
    }
}

export class PDTHome extends Component {
    static displayName = PDTHome.name;

    constructor(props) {
        super(props);
        this.state = {
            mostRecentReports: [],
            mostRecentSims: [],
            mostRecentPlans: [],
        }
    }

    showTutorial = () => {
        localStorage.setItem('tutorialseen', JSON.stringify(false));
        window.location.reload()
    }

    render() {
        //console.log(this.state)
        var appname = Constant.whereamilist[3]
        return (
            <Layout title="Price and Discount Trends">
                <div name='banner' className='banner'>
                    <div className='col-sm-12'>
                        <h1white align='center'>Welcome to Circana's Price and Discount Trends Tool</h1white><br/>
                        <h2white align='center'> In this tool you can evaluate trends related to discounts and pricing for various products</h2white>
                    </div>
                </div>
                <div name="frontpage" className="row col-sm-12 contentHolderGradient" style={{ height: '100vh', width: '100%', position: 'absolute', left: '120px', top: '210px',  maxWidth: 'calc(100vw - 103px)', paddingTop: '25px', minWidth: '1217px' }} >
                    <div className='col-sm-6' style={{ display: 'flex' }}>
                        <div className='contentHolderNoMargin' style={{ height: '100%' }}>
                            <div style={{ maxHeight: '75vh' }} className='row' align='center'>
                                <div className='col-sm-12' align='center'  >
                                    <h4>Create a new report</h4>
                                    <a href={"/" + appname + "/createreport"}>
                                        <span className="dot">
                                            <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                                                <img src={ PDT } style={{ height: '5vw', width: '5vw' }} />
                                            </div>
                                            <span className="box" style={{ position: 'relative', left: '60px', top: '-20px' }}></span>
                                            <AddCircleRoundedIcon style={{ color: '#4E106F', fontSize: '3vw', position: 'relative', left: '20px', top: '-30px' }} />
                                        </span>
                                    </a>
                                    <br />
                                    <br />
                                    <div className='' style={{ fontSize: '1vw', color: '#000000', width: '100%' }} align='center'>
                                        This will evaluate a previous promotion and calculate metrics to help evaluate how effective the promotion was.
                                            <br />
                                            or <a href={"/" + appname + "/Reports"}>View existing reports</a>
                                    </div>
                                </div>
                                <div className="testingborders" style={{ height: '50px', borderColor: '#FFFFFF', borderStyle: 'solid' }}></div>
                                <PDTRecentAccess />
                            </div>

                        </div>
                    </div>
                    <div className='col-sm-6' style={{ display: 'flex' }} >
                        <div className='contentHolderNoMargin' style={{ height: '100%' }}>
                            {/*<a href={"/" + appname + "/Home"} onClick={this.showTutorial} > Click This Link to See the Tutorial Popup again</a>*/}
                            <p>Learn more about using the tool with this video.</p>
                            <img src={comingsoon} width="750px" height="440px" />
                            {/*<video width="100%" controls>*/}
                            {/*    <source src={intro_video} type="video/mp4" />*/}
                            {/*</video>*/}
                            {/*width="960" height="540"*/}
                        </div>
                    </div>

                    <div className='col-sm-12'  >
                        <div style={{ padding: '10px', margin: '10px' }} className='' align='center'>
                            <p style={{ fontFamily: 'Roboto', color: '#ffffff', fontSize: '1vw' }}>
                                We hope you find this tool useful.  
                                You may also refer to our &nbsp;
                                        <a href={"/" + appname + "/Resources"}>
                                    resources page
                                        </a>
                                         &nbsp; for more information.
                                    </p>
                        </div>
                    </div>

                </div>
                <Tutorial />
            </Layout>


        );
    }
}

export class PNPHome extends Component {
    static displayName = PNPHome.name;

    constructor(props) {
        super(props);
        this.state = {
            mostRecentReports: [],
            mostRecentSims: [],
            mostRecentPlans: [],
            env: null,
        }
    }

    async componentDidMount() {
        //Get Environment...
        fetch(Constant.baseurl + 'environment/getenvironment')
            .then(res => res.text())
            .then(
                (result) => {
                    this.setState({ env: result })
                },
                (error) => {
                    this.setState({ env: 'Unknown' })
                }
            )
    }

    render() {
        //console.log(this.state)
        return (
            <Layout title="Price and Promotion Simulator">
                <div name='banner' className='banner'>
                    <div className='col-sm-12'>
                        <h1white align='center'>Welcome to Circana's Price and Promotion Simulator</h1white><br/>
                        <h2white align='center'> In this tool you can either evaluate a past promotion event or simulate a future promotion event</h2white>
                    </div>
                </div>
                <div name="frontpage" className="contentHolderGradient" style={{ height: '100vh', width: '100%', position: 'absolute', left: '120px', top: '210px',  maxWidth: 'calc(100vw - 120px)', minWidth: '1200px'  }} >
                    <div style={{ height: '10px' }}></div>
                    <div className='contentHolder '>
                        <div style={{ maxHeight: '75vh' }} className='row  col-sm-12' align='center'>
                            <div className='col-sm-4  ' align='center'  >
                                <h4>Create a new report</h4>
                                <a href="/PNP/createreport">
                                    <span className="dot">
                                        <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                                            <img src={PDT} style={{ width: '5vw', height: '5vw' }} />
                                        </div>
                                        <span className="box" style={{ position: 'relative', left: '60px', top: '-20px' }}></span>
                                        <AddCircleRoundedIcon style={{ color: '#4E106F', fontSize: '3vw', position: 'relative', left: '20px', top: '-30px' }} />
                                    </span>
                                </a>
                                <br />
                                <br />
                                <div className='' style={{ fontSize: '1vw', color: '#000000', width: '100%' }} align='center'>
                                    This will evaluate a previous promotion and calculate metrics to help evaluate how effective the promotion was.
                                        <br />
                                        or <a href='/PNP/Reports'>View existing reports</a>
                                </div>
                            </div>
                            <div className='col-sm-4  ' align='center'  >
                                <h4>Create a new simulation</h4>
                                <a href="/PNP/createsimulation">
                                    <span className="dot">
                                        <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                                            <img src={CreateSim} style={{ width: '5vw', height: '5vw' }} />
                                        </div>
                                        <span className="box" style={{ position: 'relative', left: '60px', top: '-20px' }}></span>
                                        <AddCircleRoundedIcon style={{ color: '#4E106F', fontSize: '3vw', position: 'relative', left: '20px', top: '-30px' }} />
                                    </span>
                                </a>
                                <br />
                                <br />
                                <div className='' style={{ fontSize: '1vw', color: '#000000', width: '100%' }} align='center'>
                                    This will simulate a new promotion and will calculate metrics to help evaluate how effective the promotion will be.
                                        <br />
                                        or <a href='/PNP/Simulations'>View existing simulations</a>
                                </div>
                            </div>
                            <div className='col-sm-4' align='center'  >
                                <h4>Create a new plan</h4>
                                <a href="/PNP/createplan">
                                    <span className="dot">
                                        <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                                            <img src={CreatePlan} style={{ width: '5vw', height: '5vw' }} />
                                        </div>
                                        <span className="box" style={{ position: 'relative', left: '60px', top: '-20px' }}></span>
                                        <AddCircleRoundedIcon style={{ color: '#4E106F', fontSize: '3vw', position: 'relative', left: '20px', top: '-30px' }} />
                                    </span>
                                </a>
                                <br />
                                <br />
                                <div className='' style={{ fontSize: '1vw', color: '#000000', width: '100%' }} align='center'>
                                    This will combine simulations to give a view of promotions occurring in a given time frame and what their effects will be.
                                        <br />
                                        or <a href='/PNP/Plans'>View existing plans</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <PNPRecentAccess />
                    {this.state.env !== 'Development' ? <CookieBar /> : <div/>}
                </div>

            </Layout>


        );


    }
}