import * as Constant from '../../../constants'

export default async function updateDiscountBandData(filters, timeperiod) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_discountband = Constant.baseapiurl + "ReportData/Filtered/DiscountBands/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_discountband, 'method': 'POST', 'body': filters }
    ], 'UpdateDiscountBands.js updateDiscountBandData()', 'Discount Band')
    let discountband_obj = my_results['ReturnList'][0]
    let discountband_data = discountband_obj['discountband_data'] || {}
    let discountband_data_geo = discountband_obj['discountband_data_geo'] || {}

    if (timeperiod === 'totaltimeperiod') {
        var discountbuckets_sorted = this.state.discountbucketlabels
        var discountbuckets = []
        try {
            var discountbuckets_indata = Object.keys(discountband_data['Total Category'])
            discountbuckets = []
            discountbuckets_sorted.forEach(bucket => {
                if (discountbuckets_indata.includes(bucket)) {
                    discountbuckets.push(bucket)
                }
            })
        } catch (error) {
            discountbuckets = []
        }

        this.setState({
            discountband_data,
            discountband_data_geo,
            discountbuckets
        })
    } else if (timeperiod === 'threeyearsago') {
        this.setState({
            discountband_data_threeyears: discountband_data,
            discountband_data_threeyears_geo: discountband_data_geo
        })
    } else if (timeperiod === 'twoyearsago') {
        this.setState({
            discountband_data_last: discountband_data,
            discountband_data_last_geo: discountband_data_geo
        })
    } else if (timeperiod === 'currentyear') {
        this.setState({
            discountband_data_current: discountband_data,
            discountband_data_current_geo: discountband_data_geo
        })
    } else if (timeperiod === 'prioryear') {
        this.setState({
            discountband_data_prior: discountband_data,
            discountband_data_prior_geo: discountband_data_geo
        })
    }
}
