import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { LastYearSwitch } from '../../UIElements/Switches/LastYearSwitch'
import { LastYearChangeSwitch } from '../../UIElements/Switches/LastYearChangeSwitch'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { PromoSupportAndShareImpact } from '../../../Graphs/PromoSupportAndShareImpact'


export class PromoSupportAndShareImpactGraph extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" style={{ paddingLeft: '50px' }} >

                    <div align='left' className="col-sm-4 row" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>

                    <div align='left' className="col-sm-4" >
                        <LastYearChangeSwitch
                            state={this.props.state}
                            handleChange_showChange={this.props.handleChange_showChange}
                        />
                    </div>

                    {this.props.state.showChange ?
                        <div align='left' className="col-sm-4" >
                            <LastYearSwitch
                                state={this.props.state}
                                handleChange_twoyago={this.props.handleChange_twoyago}
                            />
                        </div>
                        :
                        <div/>
                    }

                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <PromoSupportAndShareImpact
                            state={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}