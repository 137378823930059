import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { reducer } from '../PDT/Functions/Utilities/Reducer'
import * as Constant from '../constants'

export class PromoSupportAndShareImpact extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }


    render() {
        var aggtype = this.props.state.datasettimeagg
        var currencycode = this.props.state.currencycode
        var weeklytimeperiods = this.props.state.weeklytimeperiods
        //Define Variables
        var seriesList = [],
            titlestring = "",
            prior_dates,
            prior_data,
            total_curr_rom,
            total_curr_alr,
            total_prev_rom,
            total_prev_alr,
            sharetype,
            totalmetric,
            submetric,
            unsubmetric,
            incmetric,            
            showdollars
        let current_dates = this.props.state.distinctdates_forcalc
        let current_data = this.props.state.outlet_data_weekly
        
        if (this.props.state.TwoYAGO) {
            prior_dates = this.props.state.distinctdates_last
            prior_data = this.props.state.outlet_data_weekly_last
        }
        else {
            prior_dates = this.props.state.distinctdates_prior
            prior_data = this.props.state.outlet_data_weekly_prior
        }

        let retailername = Object.keys(current_data).map(key => {
            if (key !== 'ROM') {
                return key
            }
            else {
                return null
            }
        }).filter(Boolean)[0]

        let rom = 'ROM'

        //create calculations...
        if (this.props.state.indexSwitchCheckDollars) {
            totalmetric = 'totaldollars'
            submetric = 'subsidizedbasedollars'
            unsubmetric = 'unsubsidizedbasedollars'
            incmetric = 'incrementaldollars'
            sharetype = this.props.state.currencyType
            showdollars = true

        }

        else {
            totalmetric = 'totalunits'
            submetric = 'subsidizedbaseunits'
            unsubmetric = 'unsubsidizedbaseunits'
            incmetric = 'incrementalunits'
            sharetype = this.props.state.unitsvar
            showdollars = false
        }

        //Share
        total_curr_alr = current_dates.map(date => current_data[retailername][date][totalmetric]).reduce(reducer)
        total_curr_rom = current_dates.map(date => current_data[rom][date][totalmetric]).reduce(reducer)

        total_prev_alr = prior_dates.map(date => prior_data[retailername][date][totalmetric]).reduce(reducer)
        total_prev_rom = prior_dates.map(date => prior_data[rom][date][totalmetric]).reduce(reducer)

        let current_share_alr = current_dates.map(date => (current_data[retailername][date][totalmetric]) / (total_curr_alr + total_curr_rom))
        let current_share_rom = current_dates.map(date => (current_data[rom][date][totalmetric]) / (total_curr_alr + total_curr_rom))

        let prev_share_alr = prior_dates.map(date => (prior_data[retailername][date][totalmetric]) / (total_prev_alr + total_prev_rom))
        let prev_share_rom = prior_dates.map(date => (prior_data[rom][date][totalmetric]) / (total_prev_alr + total_prev_rom))

        let diff_share_alr = current_dates.map((date, idx) => {
            return current_share_alr[idx] - prev_share_alr[idx]
        })

        //Base Support

        let current_basesupport_alr = current_dates.map(date => (current_data[retailername][date][submetric]) / (current_data[retailername][date][submetric] + current_data[retailername][date][unsubmetric]))
        let current_basesupport_rom = current_dates.map(date => (current_data[rom][date][submetric]) / (current_data[rom][date][submetric] + current_data[rom][date][unsubmetric]))

        let prev_basesuport_alr = prior_dates.map(date => (prior_data[retailername][date][submetric]) / (prior_data[retailername][date][submetric] + prior_data[retailername][date][unsubmetric]))
        let prev_basesuport_rom = prior_dates.map(date => (prior_data[rom][date][submetric]) / (prior_data[rom][date][submetric] + prior_data[rom][date][unsubmetric]))

        let diff_basesuport_alr = current_dates.map((date, idx) => {
            return current_basesupport_alr[idx] - prev_basesuport_alr[idx]
        })

        let diff_basesuport_rom = current_dates.map((date, idx) => {
            return current_basesupport_rom[idx] - prev_basesuport_rom[idx]
        })

        //Incremental

        let current_incremental_alr = current_dates.map(date => (current_data[retailername][date][incmetric]))
        let current_incremental_rom = current_dates.map(date => (current_data[rom][date][incmetric]))

        let prev_incremental_alr = prior_dates.map(date => (prior_data[retailername][date][incmetric]))
        let prev_incremental_rom = prior_dates.map(date => (prior_data[rom][date][incmetric]))

        let diff_incremental_alr = current_dates.map((date, idx) => {
            return current_incremental_alr[idx] - prev_incremental_alr[idx]
        })

        //create series

        var series1_data, series2_data, series3_data, series4_data, series_name_addition

        if (this.props.state.showChange) {
            titlestring = 'Promotional Trend Comparison'
            series_name_addition = 'Change in '
            series1_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), diff_incremental_alr[idx]])
            series2_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), diff_share_alr[idx]])
            series3_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), diff_basesuport_alr[idx]])
            series4_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), diff_basesuport_rom[idx]])
        }

        else {
            titlestring = 'Impact on Promotional Support on Share'
            series_name_addition = ''
            series1_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), current_incremental_alr[idx]])
            series2_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), current_share_alr[idx]])
            series3_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), current_basesupport_alr[idx]])
            series4_data = current_dates.map((date, idx) => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), current_basesupport_rom[idx]])
        }

        let series1_settings = {
            name: series_name_addition + ' ALR Incremental ' + sharetype,
            type: 'column',
            tooltip: {
                //xDateFormat: xDateFormatString,
                pointFormatter: function () {
                    let dt = new Date(this.x)
                    let offset = dt.getTimezoneOffset() * 60 * 1000
                    let date = new Date(this.x + offset)
                    if (aggtype === 'Monthly') {
                        return '<span>Month: '
                            + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            + ' </span>'
                    }
                    else {
                        return '<span>Week Ending: '
                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            + ' </span>'
                    }

                }
                , enabled: true
            },
            color: '#7d32ba',
            data: series1_data,
            enableMouseTracking: true,
            dataLabels: {
                enabled: false,
                formatter: function () {
                    return (this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                },
            },
            yAxis: 0,
            visible: true,
        }

        let series2_settings = {
            name: series_name_addition + ' ALR ' + sharetype + ' Share',
            type: 'spline',
            tooltip: {
                //xDateFormat: xDateFormatString,
                pointFormatter: function () {
                    let dt = new Date(this.x)
                    let offset = dt.getTimezoneOffset() * 60 * 1000
                    let date = new Date(this.x + offset)
                    if (aggtype === 'Monthly') {
                        return '<span>Month: '
                            + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                            + ' </span>'
                    }
                    else {
                        return '<span>Week Ending: '
                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                            + ' </span>'
                    }

                }
                , enabled: true
            },
            color: '#bc81ec',
            data: series2_data,
            enableMouseTracking: true,
            dataLabels: {
                enabled: false,
                formatter: function () {
                    return (this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                },
            },
            yAxis: 1,
            visible: true,
        }

        let series3_settings = {
            name: 'ALR ' + series_name_addition + 'Promo Support',
            type: 'spline',
            tooltip: {
                //xDateFormat: xDateFormatString,
                pointFormatter: function () {
                    let dt = new Date(this.x)
                    let offset = dt.getTimezoneOffset() * 60 * 1000
                    let date = new Date(this.x + offset)
                    if (aggtype === 'Monthly') {
                        return '<span>Month: '
                            + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            + ' </span>'
                    }
                    else {
                        return '<span>Week Ending: '
                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            + ' </span>'
                    }

                }
                , enabled: true
            },
            color: '#ff8b6a',
            data: series3_data,
            enableMouseTracking: true,
            dataLabels: {
                enabled: false,
                formatter: function () {
                    return (this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                },
            },
            yAxis: 2,
            visible: true,
        }

        let series4_settings = {
            name: 'ROM ' + series_name_addition + 'Promo Support',
            type: 'spline',
            tooltip: {
                //xDateFormat: xDateFormatString,
                pointFormatter: function () {
                    let dt = new Date(this.x)
                    let offset = dt.getTimezoneOffset() * 60 * 1000
                    let date = new Date(this.x + offset)
                    if (aggtype === 'Monthly') {
                        return '<span>Month: '
                            + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            + ' </span>'
                    }
                    else {
                        return '<span>Week Ending: '
                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                            + '<br>'
                            + this.series.name
                            + '<br> '
                            + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            + ' </span>'
                    }

                }
                , enabled: true
            },
            color: '#b0df97',
            data: series4_data,
            enableMouseTracking: true,
            dataLabels: {
                enabled: false,
                formatter: function () {
                    return (this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                },
            },
            yAxis: 2,
            visible: true,
        }

        seriesList = [series1_settings, series2_settings, series3_settings, series4_settings]

        return (
            <Fragment>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={
                        {
                            chart: {
                                type: 'area',
                                zoomType: 'xy',
                                height: (9 / 16 * 100) + '%' //16:9 ratio
                            },
                            metadata: this.props.state.chartmetadata,
                            caption: {
                                text: this.props.state.attr_caption_text
                            },
                            title: {
                                text: titlestring + this.props.state.chart_title_info
                            },
                            series: seriesList,
                            xAxis: {
                                title: {
                                    text: this.props.state.xAxisText
                                },
                                type: 'datetime',
                                dateTimeLabelFormats: {
                                    month: this.props.state.xAxisLabelFormatString
                                },
                                tickPositions: this.props.state.distinctdates_forcalc.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                labels: {
                                    formatter: function () {
                                        let dt = new Date(this.value)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.value + offset)
                                        const options = { year: 'numeric', month: 'short' };
                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                        if ((weeklytimeperiods || "") === 'Monthly') {
                                            return date.toLocaleDateString(undefined, options)
                                        }
                                        else {
                                            return date.toLocaleDateString(undefined, options2) //toISOString().substring(0, 10)
                                        }
                                    },
                                    rotation: 270,
                                    step: 1,
                                    padding: 5,
                                    style: {
                                        fontSize: '11px'
                                    }
                                },
                            },
                            yAxis: [
                                {
                                    title: {
                                        text: series_name_addition + ' Incremental ' + sharetype
                                    },
                                    gridLineColor: "#CCC",
                                    gridLineWidth: 1,
                                    reversedStacks: false,
                                    labels: {

                                        formatter: function () {

                                            function nFormatter(num, digits, axisMax) {
                                                var si = [
                                                    { value: 1E18, symbol: "E" },
                                                    { value: 1E15, symbol: "P" },
                                                    { value: 1E12, symbol: "T" },
                                                    { value: 1E9, symbol: "B" },
                                                    { value: 1E6, symbol: "M" },
                                                    { value: 1E3, symbol: "k" }
                                                ], i;

                                                let symbolForAxis = " "
                                                let valueForAxis = 1

                                                for (i = 0; i < si.length; i++) {
                                                    if (axisMax >= si[i].value) {
                                                        symbolForAxis = si[i].symbol
                                                        valueForAxis = si[i].value
                                                        break;
                                                    }
                                                }

                                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                            }

                                            if (showdollars) {
                                                console.log(this.axis.max)
                                                let value = nFormatter(this.value, 1, this.axis.max)
                                                let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                let symbol = value.substring(value.length - 1)
                                                return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                            }
                                            else {
                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                            }

                                        }
                                    },

                                },
                                {
                                    title: {
                                        text: series_name_addition + ' ALR ' + sharetype + ' Share'
                                    },
                                    gridLineColor: "#CCC",
                                    gridLineWidth: 1,
                                    reversedStacks: false,                                    
                                    labels: {

                                        formatter: function () {

                                            return ((this.value) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })

                                        }
                                    },

                                },
                                {
                                    title: {
                                        text: series_name_addition + ' Promo Support' 
                                    },
                                    gridLineColor: "#CCC",
                                    gridLineWidth: 1,
                                    reversedStacks: false,
                                    opposite: true,
                                    labels: {

                                        formatter: function () {

                                            return ((this.value) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })

                                        }
                                    },

                                },
                            ],
                            plotOptions: {
                            },                           
                            exporting: {
                                showTable: this.props.state.showDataTable,
                                tableCaption: false
                            },

                        }}
                />
                <div style={{ display: this.props.state.showDataTableStyle }}>
                    <Constant.NPDTable>
                        <Constant.NPDTableBody>
                            <Constant.NPDTableRow key="header_g15">
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series1_settings.name}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series2_settings.name}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series3_settings.name}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series4_settings.name}</Constant.NPDTableCell>
                            </Constant.NPDTableRow>
                            {current_dates.map((date, idx) => {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g15"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>

                                    {this.props.state.indexSwitchCheckDollars ?
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series1_data[idx][1].toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                        :
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series1_data[idx][1].toLocaleString()}</Constant.NPDTableCell>
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series2_data[idx][1].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series3_data[idx][1].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{series4_data[idx][1].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>

                                </Constant.NPDTableRow>
                            })
                            }
                        </Constant.NPDTableBody>
                    </Constant.NPDTable>
                </div>
            </Fragment>
        )
    }
}