import React, { Component, Fragment } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Select from 'react-select'
import PropTypes from 'prop-types';
import * as Constant from '../../constants'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';
import HC_export_data from 'highcharts/modules/export-data'
import { saveAs } from "file-saver";
const ExcelJS = require('exceljs');

more(Highcharts);
HC_export_data(Highcharts)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export class TotalCategorySection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            mktsharedolSwitchCheck: false,
            mktshareSwitchCheck: false,
            dolstyle: 'none',
            volstyle: '',
            pageNum: 0,
            rowsPerPage: 5,
            header_sort_active: {},
            header_sort_direction: {},
            selectedBrandsRank: { 'value': [0, 5], 'label': 'Brands 1 - 5' },
            brandRankStart: 0,
            brandRankEnd: 5,
            selectedItemsRank: { 'value': [0, 5], 'label': 'Items 1 - 5' },
            itemRankStart: 0,
            itemRankEnd: 5,
            selected_brands_totalcat: ''
        }
        //console.log(props)
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log(this.props.state.selected_brands_totalcat)
        //console.log('TotalCategorySection props', this.props.state)
        //Check Update
        var items_defined = false, brands_defined = false, state_mismatch = false
        if (this.state.selected_brands_totalcat !== this.props.state.selected_brands_totalcat) {
            state_mismatch = true
        }

        if (Object.keys(this.props.state.brand_base_vols || {}).length > 0) {
            brands_defined = true
        }

        if ((this.props.state.brand_items_names || []).length > 0) {
            items_defined = true
        }

        if (state_mismatch && brands_defined && items_defined) {
            //console.log('Creating Options')
            function createRankOptions(numbertorank, labelbeginning) {
                let rankOptions = []
                var labelstring, labelstart, labelend, numiterations

                if (numbertorank % 5 === 0) {
                    numiterations = (numbertorank / 5) - 1
                }
                else {
                    numiterations = Math.floor(numbertorank / 5)
                }

                for (var i = 0; i <= numiterations; i++) {
                    labelstart = (i * 5) + 1
                    if (i === numiterations) {
                        labelend = numbertorank
                    }
                    else {
                        labelend = Math.min(((i + 1) * 5), numbertorank)
                    }

                    labelstring = labelbeginning + labelstart + ' - ' + labelend
                    rankOptions.push({ 'label': labelstring, 'value': [(i * 5), labelend] })
                }
                return (rankOptions)
            }

            let numbrands = Object.keys(this.props.state.brand_base_vols || {}).length
            //console.log(Object.keys(this.props.state.brand_base_vols || {}))
            //console.log(numbrands)
            let brandRanks = createRankOptions(numbrands, 'Brands ')

            let numitems = (this.props.state.brand_items_names || []).length
            let itemRanks = createRankOptions(numitems, 'Items ')

            this.setState({ brandRanks, itemRanks, selected_brands_totalcat: this.props.state.selected_brands_totalcat})
        }
    }

    render() {
        //console.log(this.state)
        //console.log(this.props.state)

        function filterArray(array, start, end, categories = false) {
            //console.log(categories)
            if (categories) {
                let new_array = (array || []).map((value, idx) => {
                    if (idx >= start && idx < end) {
                        return value
                    }
                    else {
                        return null
                    }
                }).filter(x => x !== null)
                return new_array
            }
            else {
                let new_array = (array || []).map((value, idx) => {
                    if (idx >= start && idx < end) {
                        return value
                    }
                    else {
                        return null
                    }
                }).filter(Number.isFinite)
                return new_array
            }
        }

        const handleChangeTab = (event, newValue) => {
            this.setState({ value: newValue })
        };

        const handleChangePage = (event, newPage) => {
            this.setState({ pageNum: newPage })
        }

        const handleChangeRowsPerPage = (event) => {
            this.setState({ rowsPerPage: parseInt(event.target.value, 10) })
            this.setState({
                pageNum: 0,
            })
        };

        const handleChange_mktsharedolSwitch = event => {
            let mktsharedolSwitchCheck_curr = !this.state.mktsharedolSwitchCheck
            //if true, set the style for the divs to 'inline' and vol to none.  vice versa if false
            if (mktsharedolSwitchCheck_curr) {
                var dolstyle = ''
                var volstyle = 'none'
            }
            else {
                var dolstyle = 'none'
                var volstyle = ''
            }
            this.setState({
                mktsharedolSwitchCheck: mktsharedolSwitchCheck_curr,
                dolstyle,
                volstyle
            })
            //console.log(this.state)
        }

        const handleChange_mktshareSwitch = event => {
            this.setState({
                mktshareSwitchCheck: !this.state.mktshareSwitchCheck,
            })
        }

        const changeBrandNums = (selectedOptions) => {
            //console.log(selectedOptions)
            let brandRankStart = selectedOptions.value[0]
            let brandRankEnd = selectedOptions.value[1]
            this.setState({ brandRankStart, brandRankEnd, selectedBrandsRank: selectedOptions})
        }

        const changeItemNums = (selectedOptions) => {
            //console.log(selectedOptions)
            let itemRankStart = selectedOptions.value[0]
            let itemRankEnd = selectedOptions.value[1]
            this.setState({ itemRankStart, itemRankEnd, selectedItemsRank: selectedOptions })
        }

        async function downloadData(level, state, props) {
            //console.log(level)
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            //File Information
            var name = 'All Data By ' + level
            var newdate = new Date();
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
            datestring = datestring.replaceAll(',', '')
            let filenameexcel = name + ' - Extracted - ' + datestring
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet('Metrics');
            const worksheet = workbook.getWorksheet('Metrics');
            var levelstoview = [], headers = [], headers_base = [], levels = [], units_label, header1, header2, header3, header4, header5, header6, header7, header8

            if (state.volstyle !== 'none') {
                units_label = props.state.unitsvar
            }
            else {
                units_label = props.state.currencytype
            }

            if (level === 'brand') {
                header1 = 'Brand Name'
            }
            else {
                header1 = 'Item Name'
            }

            header2 = 'Base ' + units_label
            header3 = 'Subsidized Base ' + units_label
            header4 = 'Simulated Total ' + units_label
           
            if (props.state.simsettings.sim_type === 'base') {
                header5 = 'Change in Base ' + units_label
            }
            else {
                header5 = 'Incremental ' + units_label
            }
            if (props.state.simsettings.sim_type === 'base') {
                header6 = '% Change in Base ' + units_label
            }
            else {
                header6 = 'Incremental ' + units_label + '(' + units_label + ')'
            }

            header7 = 'Simulated Market Share (' + units_label + ')'
            header8 = 'Difference in Market Share (Points)'
            
            headers = [header1, header2, header3, header4, header5, header6, header7, header8]
            headers_base = [header1, header2, header5, header6, header7, header8]

            var metric_start_col = 2
            if (level === 'brand') {
                levelstoview = (state.brands_for_totalcat || Object.keys(props.state.brand_base_vols))

                let base = []
                let change = []
                let change_pct = []
                let mktshare = []
                let mktshare_change = []
                let sub_base = []
                let promo = []
                //console.log('level', level, 'state.volstyle', state.volstyle)
                if (state.volstyle !== 'none') {
                   
                    let total_category_volume_old = Object.values(props.state.brand_base_vols).reduce(reducer)
                    let total_category_changes = Object.values(props.state.brand_inc_vols).reduce(reducer)
                    let total_category_compchanges = ([0].concat(Object.values(props.state.comp_item_changes_brand))).reduce(reducer)
                    let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges
                    let total_category_sub_base = 0
                    if (props.state.simsettings.sim_type === 'promo') {
                        total_category_sub_base = Object.values(props.state.subsidized_base_volume_brand).reduce(reducer)
                    }

                    levelstoview.forEach(brand => {
                        let basevol = props.state.brand_base_vols[brand]
                        let changevol = props.state.brand_inc_vols[brand]
                        let compchangevol = props.state.comp_item_changes_brand[brand] || 0
                        let subbasevol = 0
                        if (props.state.simsettings.sim_type === 'promo') {
                            subbasevol = props.state.subsidized_base_volume_brand[brand]
                        }

                        let newshare = 0
                        if (total_category_volume_new !== 0) {
                            newshare = (basevol + changevol + compchangevol) / total_category_volume_new // this original calc is correct for units
                            //newshare = (subbasevol + changevol) / (total_category_sub_base + total_category_changes)  don't change original units calc

                        }

                        let oldshare = 0
                        if (total_category_volume_old !== 0) {
                            oldshare = basevol / total_category_volume_old
                        }

                        levels.push(brand)
                        base.push(basevol)
                        change.push(changevol + compchangevol)
                        if (basevol !== 0) {
                            change_pct.push(((changevol + compchangevol) / basevol) || 0)
                        }
                        else {
                            change_pct.push(0)
                        }
                        mktshare.push(newshare)
                        mktshare_change.push((newshare - oldshare) * 100)
                        sub_base.push(subbasevol)
                        promo.push(subbasevol + changevol)

                    })
                }
                else {
                    let total_category_volume_old = Object.values(props.state.brand_base_dols).reduce(reducer)
                    let total_category_sub_base = 0
                    if (props.state.simsettings.sim_type === 'promo') {
                        total_category_sub_base = Object.values(props.state.subsidized_base_dollars_brand).reduce(reducer)
                    }
                    //incremental volume
                    let total_category_changes = Object.values(props.state.brand_inc_dols).reduce(reducer) 
                    let total_category_compchanges = ([0].concat(Object.values(props.state.comp_item_changes_brand_dollars))).reduce(reducer)
                    let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges
                   
                    levelstoview.forEach(brand => {
                        let basevol = props.state.brand_base_dols[brand]
                        let changevol = props.state.brand_inc_dols[brand]
                        let compchangevol = props.state.comp_item_changes_brand_dollars[brand] || 0

                        let subbasevol = 0
                        if (props.state.simsettings.sim_type === 'promo') {
                            subbasevol = props.state.subsidized_base_dollars_brand[brand]
                        }

                        let newshare = 0
                        if (total_category_volume_new !== 0) {
                            if (props.state.simsettings.sim_type === 'base') {
                                newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                            }
                            else {
                                newshare = (subbasevol + changevol) / (total_category_sub_base + total_category_changes)
                            }
                        }

                        let oldshare = 0
                        if (total_category_volume_old !== 0) {
                            oldshare = basevol / total_category_volume_old
                        }

                        //console.log(newshare)
                        //console.log(oldshare)
                        levels.push(brand)
                        base.push(basevol)
                        change.push(changevol + compchangevol)
                        if (basevol !== 0) {
                            change_pct.push(((changevol + compchangevol) / basevol) || 0)
                        }
                        else {
                            change_pct.push(0)
                        }
                        mktshare.push(newshare)
                        mktshare_change.push((newshare - oldshare) * 100)
                        sub_base.push(subbasevol)
                        promo.push(subbasevol + changevol)
                    })
                }

                if (props.state.simsettings.sim_type === 'promo') {
                    worksheet.getColumn(0 + metric_start_col).values = base
                    worksheet.getColumn(1 + metric_start_col).values = sub_base
                    worksheet.getColumn(2 + metric_start_col).values = promo
                    worksheet.getColumn(3 + metric_start_col).values = change
                    worksheet.getColumn(4 + metric_start_col).values = change_pct
                    worksheet.getColumn(5 + metric_start_col).values = mktshare
                    worksheet.getColumn(6 + metric_start_col).values = mktshare_change
                }
                else {
                    worksheet.getColumn(0 + metric_start_col).values = base
                    worksheet.getColumn(1 + metric_start_col).values = change
                    worksheet.getColumn(2 + metric_start_col).values = change_pct
                    worksheet.getColumn(3 + metric_start_col).values = mktshare
                    worksheet.getColumn(4 + metric_start_col).values = mktshare_change

                }
               

            }
            //Item changes and Market Share for selected brand
            else {
                levelstoview = (state.items_for_totalcat || props.state.brand_items_nums)

                let base = []
                let change = []
                let change_pct = []
                let mktshare = []
                let mktshare_change = []
                let subbase = []
                let promo = []

                if (state.volstyle !== 'none') {
                    levelstoview.forEach(item => {
                        let total_category_volume_old = Object.values(props.state.brand_base_vols).reduce(reducer)
                        let total_category_changes = Object.values(props.state.brand_inc_vols).reduce(reducer)
                        let total_category_compchanges = ([0].concat(Object.values(props.state.comp_item_changes_brand))).reduce(reducer)
                        let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges

                        let metrics = props.state.brand_items_all_metrics[item]
                        let compchanges = props.state.comp_item_changes_item[parseInt(item)] || 0

                        let subbasevol = 0
                        if (props.state.simsettings.sim_type === 'promo') {
                            subbasevol = props.state.subsidized_base_volume_item[parseInt(item)]
                        }
                        //console.log('subbasevol', subbasevol)

                        let itemname = metrics['proddesc']
                        let incvol = metrics['incvol'] + compchanges
                        let basevol = metrics['basevol']


                        let volpct = 0
                        if (basevol !== 0) {
                            volpct = incvol / basevol
                        }

                        let newshare = 0
                        if (total_category_volume_new !== 0) {
                            newshare = (basevol + incvol + compchanges) / total_category_volume_new
                        }
                       //let newshare = metrics['newshare'] / 100
                        let oldshare = metrics['oldshare'] / 100
                        let sharechange = metrics['newshare'] - metrics['oldshare']

                        levels.push(itemname)
                        base.push(basevol)
                        change.push(incvol)
                        change_pct.push(volpct)
                        mktshare.push(newshare)
                        mktshare_change.push(sharechange)
                        subbase.push(subbasevol)
                        promo.push(subbasevol + incvol)
                    })
                }
                else {
                    levelstoview.forEach(item => {
                        let total_category_volume_old_dol = Object.values(props.state.brand_base_dols).reduce(reducer)
                        let total_category_sub_base = 0
                        if (props.state.simsettings.sim_type === 'promo') {
                            total_category_sub_base = Object.values(props.state.subsidized_base_dollars_brand).reduce(reducer)
                        }
                        //incremental volume
                        let total_category_incdollars = Object.values(props.state.brand_inc_dols).reduce(reducer)
                        let total_category_compchanges = ([0].concat(Object.values(props.state.comp_item_changes_brand_dollars))).reduce(reducer)
                        let total_category_volume_new_dol = total_category_volume_old_dol + total_category_incdollars + total_category_compchanges


                        let metrics = props.state.brand_items_all_metrics[item]
                        let compchanges = props.state.comp_item_changes_item_dollars[parseInt(item)] || 0
                        let itemname = metrics['proddesc']
                        let incdollars = metrics['incdollars'] + compchanges
                        let basedollars = metrics['basedollars']
                        let subbasedollars = 0
                        if (props.state.simsettings.sim_type === 'promo') {
                            subbasedollars = props.state.subsidized_base_dollars_item[parseInt(item)]
                        }
                        //let subbasedollars = props.state.subsidized_base_dollars_item[parseInt(item)]
                        //console.log('subbasedollars',subbasedollars)

                        let volpct_dollars = 0
                        if (basedollars !== 0) {
                            volpct_dollars = incdollars / basedollars
                        }

                        //let newshare = metrics['newshare_dollars']

                        let newshare = 0
                        if (total_category_volume_new_dol !== 0) {
                            if (props.state.simsettings.sim_type === 'base') {
                                newshare = (basedollars + incdollars + compchanges) / total_category_volume_new_dol
                            }
                            else {
                                newshare = (subbasedollars + incdollars) / (total_category_sub_base + total_category_incdollars)
                            }
                        }

                        //let oldshare = metrics['oldshare_dollars']
                        let oldshare = 0
                        if (total_category_volume_old_dol !== 0) {
                            oldshare = basedollars / total_category_volume_old_dol
                        }

                        let sharechange = (newshare - oldshare) * 100

                        if (basedollars !== 0) {
                            change_pct.push(((incdollars + compchanges) / basedollars) || 0)
                        }
                        else {
                            change_pct.push(0)
                        }

                        levels.push(itemname)
                        base.push(basedollars)
                        change.push(incdollars)
                        //change_pct.push(volpct_dollars)
                        //change_pct.push(change_pct)
                        mktshare.push(newshare)
                        //mktshare_change.push(sharechange)
                        mktshare_change.push((newshare - oldshare) * 100)
                        subbase.push(subbasedollars)
                        promo.push(subbasedollars + incdollars)
                    })
                }
                if (props.state.simsettings.sim_type === 'promo') {
                    worksheet.getColumn(0 + metric_start_col).values = base
                    worksheet.getColumn(1 + metric_start_col).values = subbase
                    worksheet.getColumn(2 + metric_start_col).values = promo
                    worksheet.getColumn(3 + metric_start_col).values = change
                    worksheet.getColumn(4 + metric_start_col).values = change_pct
                    worksheet.getColumn(5 + metric_start_col).values = mktshare
                    worksheet.getColumn(6 + metric_start_col).values = mktshare_change
                }
                else {
                    worksheet.getColumn(0 + metric_start_col).values = base
                    worksheet.getColumn(1 + metric_start_col).values = change
                    worksheet.getColumn(2 + metric_start_col).values = change_pct
                    worksheet.getColumn(3 + metric_start_col).values = mktshare
                    worksheet.getColumn(4 + metric_start_col).values = mktshare_change

                }

                //worksheet.getColumn(0 + metric_start_col).values = base
                //worksheet.getColumn(1 + metric_start_col).values = change
                //worksheet.getColumn(2 + metric_start_col).values = change_pct
                //worksheet.getColumn(3 + metric_start_col).values = mktshare
                //worksheet.getColumn(4 + metric_start_col).values = mktshare_change

            }

            worksheet.getColumn(1).values = levels
            if (props.state.simsettings.sim_type === 'promo') {
                worksheet.insertRow(1, headers)
            }
            else {
                worksheet.insertRow(1, headers_base)
            };

            if (props.state.simsettings.sim_type === 'promo') {
                if (state.volstyle !== 'none') {
                    worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
                    worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
                    worksheet.getColumn(metric_start_col + 2).numFmt = '##,#'
                    worksheet.getColumn(metric_start_col + 3).numFmt = '##,#'

                }
                else {
                    worksheet.getColumn(metric_start_col + 0).numFmt = props.state.currencysymbol + '#,##'
                    worksheet.getColumn(metric_start_col + 1).numFmt = props.state.currencysymbol + '##,#'
                    worksheet.getColumn(metric_start_col + 2).numFmt = props.state.currencysymbol + '#,##'
                    worksheet.getColumn(metric_start_col + 3).numFmt = props.state.currencysymbol + '##,#'
                }

                worksheet.getColumn(metric_start_col + 4).numFmt = '0%'
                worksheet.getColumn(metric_start_col + 5).numFmt = '0%'
                worksheet.getColumn(metric_start_col + 6).numFmt = '#.000'
            }
            else {
                if (state.volstyle !== 'none') {
                    worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
                    worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'

                }
                else {
                    worksheet.getColumn(metric_start_col + 0).numFmt = props.state.currencysymbol + '#,##'
                    worksheet.getColumn(metric_start_col + 1).numFmt = props.state.currencysymbol + '##,#'
                   
                }

                worksheet.getColumn(metric_start_col + 2).numFmt = '0%'
                worksheet.getColumn(metric_start_col + 3).numFmt = '0%'
                worksheet.getColumn(metric_start_col + 4).numFmt = '#.000'
            }

            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const blob = new Blob([buffer], { type: fileType });

            saveAs(blob, filenameexcel + fileExtension);
        }

        const changeBrandSelectedTotalCatLocal = (selectedOptions) => {
            this.setState(
                {
                    itemRankStart: 0
                    , itemRankEnd: 5
                    , selectedItemsRank: { 'value': [0, 5], 'label': 'Items 1 - 5' }
                }, () => this.props.changeBrandSelectedTotalCat(selectedOptions)
            )
        }

        const muiTheme = createTheme({
            palette: {
                primary: {
                    main: '#4e106f',
                },
                secondary: {
                    main: '#ef2a79',
                },
                white: {
                    main: '#FFF'
                }
            }
        });

        const sortDataUnits = (event, data, sortby) => {
            //console.log(event)
            //console.log(data)
            //console.log(sortby)

            //Set Direction and Active
            let header_sort_active = this.state.header_sort_active
            header_sort_active[data] = header_sort_active[data] || {}
            header_sort_active[data][sortby] = header_sort_active[data][sortby] || true
            header_sort_active[data][sortby] = true
            //For Every Other Key, set to false...
            Object.keys(header_sort_active[data]).forEach(header => {
                if (header !== sortby) {
                    header_sort_active[data][header] = false
                }
            })

            let header_sort_direction = this.state.header_sort_direction
            header_sort_direction[data] = header_sort_direction[data] || {}
            header_sort_direction[data][sortby] = header_sort_direction[data][sortby] || 'asc'
            if (header_sort_direction[data][sortby] === 'asc') {
                header_sort_direction[data][sortby] = 'desc'
            }
            else {
                header_sort_direction[data][sortby] = 'asc'
            }

            this.setState({ header_sort_active, header_sort_direction })

            //Sort the Data..
            if (data === 'brands') {
                const reducer = (accumulator, currentValue) => accumulator + currentValue;
                let total_category_volume_old = Object.values(this.props.state.brand_base_vols).reduce(reducer)
                let total_category_changes = Object.values(this.props.state.brand_inc_vols).reduce(reducer)
                let total_category_compchanges = ([0].concat(Object.values(this.props.state.comp_item_changes_brand))).reduce(reducer)
                let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges
                let initial_brands_for_totalcat = Object.keys(this.props.state.brand_base_vols)
                var brands_for_totalcat
                if (sortby === 'brandname') {
                    if (header_sort_direction[data][sortby] === 'asc') {
                        brands_for_totalcat = initial_brands_for_totalcat.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : (a.toLowerCase() > b.toLowerCase() ? 1 : 0))
                    }
                    else {
                        brands_for_totalcat = initial_brands_for_totalcat.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? -1 : (a.toLowerCase() < b.toLowerCase() ? 1 : 0))
                    }
                }
                else if (sortby === 'units') {
                    var sortable = [];
                    initial_brands_for_totalcat.forEach(brand => {
                        sortable.push([brand, this.props.state.brand_base_vols[brand]])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {

                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat = sortable.map(brand => brand[0])
                }
                else if (sortby === 'incremental') {
                    var sortable = [];
                    initial_brands_for_totalcat.forEach(brand => {
                        let changevol = this.props.state.brand_inc_vols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        sortable.push([brand, changevol + compchangevol])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat = sortable.map(brand => brand[0])
                }
                else if (sortby === 'lift') {
                    var sortable = [];
                    initial_brands_for_totalcat.forEach(brand => {
                        let basevol = this.props.state.brand_base_vols[brand]
                        let changevol = this.props.state.brand_inc_vols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        sortable.push([brand, (changevol + compchangevol) / basevol])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {

                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat = sortable.map(brand => brand[0])
                }
                else if (sortby === 'simmktshare') {
                    var sortable = [];
                    initial_brands_for_totalcat.forEach(brand => {
                        let basevol = this.props.state.brand_base_vols[brand]
                        let changevol = this.props.state.brand_inc_vols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        let newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                        let oldshare = basevol / total_category_volume_old

                        sortable.push([brand, newshare])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {

                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat = sortable.map(brand => brand[0])
                }

                else if (sortby === 'diffmktshare') {
                    var sortable = [];
                    initial_brands_for_totalcat.forEach(brand => {
                        let basevol = this.props.state.brand_base_vols[brand]
                        let changevol = this.props.state.brand_inc_vols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        let newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                        let oldshare = basevol / total_category_volume_old

                        sortable.push([brand, (newshare - oldshare)])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat = sortable.map(brand => brand[0])
                }

                this.setState({ brands_for_totalcat })
            }
            else if (data === 'items') {
                let initial_items_for_totalcat = this.props.state.brand_items_nums
                var items_for_totalcat
                var sortable = [];

                if (sortby === 'itemname') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['proddesc']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort((a, b) => a[1].toLowerCase() < b[1].toLowerCase() ? -1 : (a[1].toLowerCase() > b[1].toLowerCase() ? 1 : 0))
                    }
                    else {
                        sortable.sort((a, b) => a[1].toLowerCase() > b[1].toLowerCase() ? -1 : (a[1].toLowerCase() < b[1].toLowerCase() ? 1 : 0))
                    }
                }
                else if (sortby === 'incremental') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['incvol']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }
                else if (sortby === 'lift') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['volpct']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }
                else if (sortby === 'simmktshare') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['newshare']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }
                else if (sortby === 'diffmktshare') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, (this.props.state.brand_items_all_metrics[item]['newshare'] - this.props.state.brand_items_all_metrics[item]['oldshare'])])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }

                items_for_totalcat = sortable.map(item => item[0])
                this.setState({ items_for_totalcat })
            }
        }

        const sortDataDollars = (event, data, sortby) => {
            //console.log(event)
            //console.log(data)
            //console.log(sortby)

            //Set Direction and Active
            let header_sort_active = this.state.header_sort_active
            header_sort_active[data] = header_sort_active[data] || {}
            header_sort_active[data][sortby] = header_sort_active[data][sortby] || true
            header_sort_active[data][sortby] = true
            //For Every Other Key, set to false...
            Object.keys(header_sort_active[data]).forEach(header => {
                if (header !== sortby) {
                    header_sort_active[data][header] = false
                }
            })

            let header_sort_direction = this.state.header_sort_direction
            header_sort_direction[data] = header_sort_direction[data] || {}
            header_sort_direction[data][sortby] = header_sort_direction[data][sortby] || 'asc'
            if (header_sort_direction[data][sortby] === 'asc') {
                header_sort_direction[data][sortby] = 'desc'
            }
            else {
                header_sort_direction[data][sortby] = 'asc'
            }

            this.setState({ header_sort_active, header_sort_direction })

            //Sort the Data..
            if (data === 'brands') {
                const reducer = (accumulator, currentValue) => accumulator + currentValue;
                let total_category_volume_old = Object.values(this.props.state.brand_base_dols).reduce(reducer)
                let total_category_changes = Object.values(this.props.state.brand_inc_dols).reduce(reducer)
                let total_category_compchanges = ([0].concat(Object.values(this.props.state.comp_item_changes_brand))).reduce(reducer)
                let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges
                let initial_brands_for_totalcat_dollars = Object.keys(this.props.state.brand_base_dols)
                var brands_for_totalcat_dollars
                if (sortby === 'brandname') {
                    if (header_sort_direction[data][sortby] === 'asc') {
                        brands_for_totalcat_dollars = initial_brands_for_totalcat_dollars.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : (a.toLowerCase() > b.toLowerCase() ? 1 : 0))
                    }
                    else {
                        brands_for_totalcat_dollars = initial_brands_for_totalcat_dollars.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? -1 : (a.toLowerCase() < b.toLowerCase() ? 1 : 0))
                    }
                }
                else if (sortby === 'dollars') {
                    var sortable = [];
                    initial_brands_for_totalcat_dollars.forEach(brand => {
                        sortable.push([brand, this.props.state.brand_base_dols[brand]])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat_dollars = sortable.map(brand => brand[0])
                }
                else if (sortby === 'incremental') {
                    var sortable = [];
                    initial_brands_for_totalcat_dollars.forEach(brand => {
                        let changevol = this.props.state.brand_inc_dols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        sortable.push([brand, changevol + compchangevol])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat_dollars = sortable.map(brand => brand[0])
                }
                else if (sortby === 'lift') {
                    var sortable = [];
                    initial_brands_for_totalcat_dollars.forEach(brand => {
                        let basevol = this.props.state.brand_base_dols[brand]
                        let changevol = this.props.state.brand_inc_dols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        sortable.push([brand, (changevol + compchangevol) / basevol])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat_dollars = sortable.map(brand => brand[0])
                }
                else if (sortby === 'simmktshare') {
                    var sortable = [];
                    initial_brands_for_totalcat_dollars.forEach(brand => {
                        let basevol = this.props.state.brand_base_dols[brand]
                        let changevol = this.props.state.brand_inc_dols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        let newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                        let oldshare = basevol / total_category_volume_old

                        sortable.push([brand, newshare])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat_dollars = sortable.map(brand => brand[0])
                }
                else if (sortby === 'diffmktshare') {
                    var sortable = [];
                    initial_brands_for_totalcat_dollars.forEach(brand => {
                        let basevol = this.props.state.brand_base_dols[brand]
                        let changevol = this.props.state.brand_inc_dols[brand]
                        let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                        let newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                        let oldshare = basevol / total_category_volume_old

                        sortable.push([brand, (newshare - oldshare)])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }

                    brands_for_totalcat_dollars = sortable.map(brand => brand[0])
                }

                this.setState({ brands_for_totalcat_dollars })
            }
            else if (data === 'items') {
                let initial_items_for_totalcat = this.props.state.brand_items_nums
                var items_for_totalcat
                var sortable = [];

                if (sortby === 'itemname') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['proddesc']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort((a, b) => a[1].toLowerCase() < b[1].toLowerCase() ? -1 : (a[1].toLowerCase() > b[1].toLowerCase() ? 1 : 0))
                    }
                    else {
                        sortable.sort((a, b) => a[1].toLowerCase() > b[1].toLowerCase() ? -1 : (a[1].toLowerCase() < b[1].toLowerCase() ? 1 : 0))
                    }
                }
                else if (sortby === 'incremental') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['incdollars']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }
                else if (sortby === 'lift') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['volpct_dollars']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }
                else if (sortby === 'simmktshare') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, this.props.state.brand_items_all_metrics[item]['newshare_dollars']])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }
                else if (sortby === 'diffmktshare') {
                    initial_items_for_totalcat.forEach(item => {
                        sortable.push([item, (this.props.state.brand_items_all_metrics[item]['newshare_dollars'] - this.props.state.brand_items_all_metrics[item]['oldshare_dollars'])])
                    })

                    if (header_sort_direction[data][sortby] === 'asc') {
                        sortable.sort(function (a, b) {
                            return a[1] - b[1];
                        });
                    }
                    else {
                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                    }
                }

                items_for_totalcat = sortable.map(item => item[0])
                this.setState({ items_for_totalcat })
            }
        }

        var currencycode = this.props.state.currencycode
        var simtype_string_title = '<br/> Simulation Type: ' + (this.props.state.simsettings['sim_type'] || "").charAt(0).toUpperCase() + (this.props.state.simsettings['sim_type'] || "").slice(1).toLowerCase()
        var geo_string_title = '<br/> Geography: ' + (this.props.state.geolookup || {})[(this.props.state.simsettings['geogid'] || [])[0]]
        var date_string_title = '<br/> ' + this.props.state.simsettings['numweeks'] + ' weeks ending ' + (this.props.state.simsettings['sim_end'] || "").substring(0, 10)
        var chart_title_info = simtype_string_title + geo_string_title + date_string_title

        const reducer = (accumulator, currentValue) => accumulator + currentValue;

        var cat_unit_change = Math.round([0].concat(Object.keys(this.props.state.brand_base_vols).map(brand => {
            return ((this.props.state.brand_inc_vols[brand] + (this.props.state.comp_item_changes_brand[brand] || 0)))
        })).reduce(reducer))

        var cat_unit_pct_change = cat_unit_change / this.props.state.total_category_volume_old

        var items_price = filterArray(this.props.state.brand_items_price, this.state.itemRankStart, this.state.itemRankEnd)

        return (
            <div align='center' className="contentHolder simOutputGraphs">
                <h5>Total Category View</h5>
                <h5normal>All Items in the Modeling Dataset</h5normal>

                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }} className="">
                    <Constant.NPDTabs
                        value={this.state.value}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                    >
                        <Constant.NPDTab label="BRAND CHANGES AND MARKET SHARE"  {...a11yProps(0)} />
                        <Constant.NPDTab label="ITEM CHANGES AND MARKET SHARE" {...a11yProps(1)} />
                    </Constant.NPDTabs>
                </Constant.NPDAppbar>
                <div className="row" align="left" style={{ margin: '10px' }}>
                    <div className="col-sm-3" align="left">
                        <ThemeProvider theme={muiTheme}>
                            <span>Units</span>
                            <Switch
                                checked={this.state.mktsharedolSwitchCheck}
                                onChange={handleChange_mktsharedolSwitch}
                                color="secondary"
                            />
                            <span>{this.props.state.currencytype}</span>
                        </ThemeProvider>
                    </div>
                    <div className="col-sm-3" align="left">
                        <ThemeProvider theme={muiTheme}>
                            {this.props.state.simsettings.sim_type === 'base' ?
                                <span>Change in Base</span>
                                :
                                <span>Incremental</span>
                            }
                            <Switch
                                checked={this.state.mktshareSwitchCheck}
                                onChange={handleChange_mktshareSwitch}
                                color="secondary"
                            />
                            <span>Market Share</span>
                        </ThemeProvider>
                    </div>
                    <div className="col-sm-3" align="left">
                    </div>
                    {this.state.value === 0 ?
                        <div className="col-sm-3" align="left">
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                style={{ paddingRight: '5px' }}
                                onClick={(level, state) => downloadData('brand', this.state, this.props)}
                            >
                                <Tooltip
                                    title="Download All Brand Data"
                                    placement="right"
                                >
                                    <GetAppRoundedIcon
                                        fontSize="large"
                                    />
                                </Tooltip>
                            </IconButton>
                        </div>
                        :
                        <div className="col-sm-3" align="left">
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                style={{ paddingRight: '5px' }}
                                onClick={(level, state) => downloadData('item', this.state, this.props)}
                            >
                                <Tooltip
                                    title="Download All Item Data"
                                    placement="right"
                                >
                                    <GetAppRoundedIcon
                                        fontSize="large"
                                    />
                                </Tooltip>
                            </IconButton>
                        </div>
                    }
                </div>
                <TabPanel value={this.state.value} index={0} style={{ width: '100%', height: '100%' }} >
                    <div className="row col-sm-12" style={{ display: this.state.volstyle }}>
                        {
                            this.props.state.simsettings.sim_type === 'base' ?
                                <h4>The total change to the category was: {Math.round((this.props.state.total_category_volume_new + ([0].concat(Object.values(this.props.state.comp_item_changes_brand))).reduce(reducer)) - this.props.state.total_category_volume_old).toLocaleString() + ' ' + this.props.state.unitsvar + ' or ' + (Math.round((this.props.state.total_category_volume_new + ([0].concat(Object.values(this.props.state.comp_item_changes_brand))).reduce(reducer)) - this.props.state.total_category_volume_old) / this.props.state.total_category_volume_old).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h4>
                                :
                                <h4>
                                    The total change to the category was:
                                    {
                                        Math.round(cat_unit_change).toLocaleString()
                                        + ' '
                                        + this.props.state.unitsvar
                                        + ' or '
                                        + cat_unit_pct_change.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                </h4>
                        }
                    </div>
                    <div className="row col-sm-12" style={{ display: this.state.dolstyle }}>
                        <h4>The total dollar change to the category was: {Math.round((this.props.state.total_category_volume_new_dol + ([0].concat(Object.values(this.props.state.comp_item_changes_brand_dollars))).reduce(reducer)) - this.props.state.total_category_volume_old_dol).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' ' + this.props.state.currencytype + ' or ' + (Math.round((this.props.state.total_category_volume_new_dol + ([0].concat(Object.values(this.props.state.comp_item_changes_brand_dollars))).reduce(reducer)) - this.props.state.total_category_volume_old_dol) / this.props.state.total_category_volume_old_dol).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h4>
                    </div>

                    <div className ='row'>
                        {Object.keys(this.props.state.brand_base_vols).length > 5 ?
                            <div className="col-sm-6" align="left">
                                <span className="formfieldtitle">Select Brands</span>
                                <Select
                                    options={this.state.brandRanks}
                                    onChange={(brand) => changeBrandNums(brand)}
                                    value={this.state.selectedBrandsRank}
                                />
                            </div>
                            :
                            <div className="col-sm-6" />
                        }
                        <div className="col-sm-6" />
                    </div>

                    <div className="row" style={{ display: this.state.volstyle }}>
                        {!this.state.mktshareSwitchCheck ?
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - ' + this.props.state.unitsvar + ' by Brand' + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.totalCatVoltype,
                                                        data: filterArray(Object.values(this.props.state.brand_base_vols), this.state.brandRankStart, this.state.brandRankEnd),
                                                        color: '#4e106f'
                                                    },
                                                    {
                                                        name: this.props.state.unitChangeType,
                                                        data: filterArray((Object.keys(this.props.state.brand_base_vols) || []), this.state.brandRankStart, this.state.brandRankEnd, true).map(brand => this.props.state.brand_inc_vols[brand] + (this.props.state.comp_item_changes_brand[brand] || 0)),
                                                        color: '#000000'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Brand'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(Object.keys(this.props.state.brand_base_vols), this.state.brandRankStart, this.state.brandRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }

                                                                return nFormatter(this.value, 0).toLocaleString()
                                                            }
                                                        }
                                                    },
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.x + '<br>' + this.series.name + ' : ' + Math.round(this.y).toLocaleString()
                                                    }
                                                }
                                            }}
                                    />
                                </div>
                            </div>
                            :
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - Lift % and Market Share by Brand' + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.unitPctChangeType,
                                                        data: filterArray(Object.keys(this.props.state.brand_base_vols), this.state.brandRankStart, this.state.brandRankEnd, true).map(brand => {
                                                            return ((this.props.state.brand_inc_vols[brand] + (this.props.state.comp_item_changes_brand[brand] || 0)) / this.props.state.brand_base_vols[brand])
                                                        }),
                                                        color: '#ef2a79'
                                                    },
                                                    {
                                                        name: 'Market Share',
                                                        data: filterArray(Object.keys(this.props.state.brand_base_vols), this.state.brandRankStart, this.state.brandRankEnd, true).map(brand => {
                                                            return (this.props.state.brand_inc_vols[brand] + (this.props.state.comp_item_changes_brand[brand] || 0) + this.props.state.brand_base_vols[brand]) / (Object.values(this.props.state.brand_base_vols).reduce(reducer) + Object.values(this.props.state.brand_inc_vols).reduce(reducer) + ([0].concat(Object.values(this.props.state.comp_item_changes_brand))).reduce(reducer))
                                                        }),
                                                        color: '#565a5c'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Brand'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(Object.keys(this.props.state.brand_base_vols), this.state.brandRankStart, this.state.brandRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: 'Percent'
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        opposite: true,
                                                        labels: {
                                                            formatter: function () {
                                                                return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                            }
                                                        }
                                                    }
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.x + '<br>' + this.series.name + ' : ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                    }
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        }

                        <div className="row col-sm-12">
                            {/*Add Pagination and Sorting*/}
                            <Table id="TotalCatTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['brandname'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['brandname'] || 'asc'}
                                                onClick={(event) => sortDataUnits(event, 'brands', 'brandname')}
                                            >
                                                <h4>Brand</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['units'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['units'] || 'asc'}
                                                onClick={(event) => sortDataUnits(event, 'brands', 'units')}
                                            >
                                                <h4>Base {this.props.state.unitsvar}</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        {this.props.state.simsettings.sim_type === 'base' ?
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'brands', 'incremental')}
                                                    >
                                                        <h4>Change in Base {this.props.state.unitsvar}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'brands', 'lift')}
                                                    >
                                                        <h4>% Change in Base {this.props.state.unitsvar}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'brands', 'incremental')}
                                                    >
                                                        <h4>Incremental {this.props.state.unitsvar}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'brands', 'lift')}
                                                    >
                                                        <h4>% Lift</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                        }
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['simmktshare'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['simmktshare'] || 'asc'}
                                                onClick={(event) => sortDataUnits(event, 'brands', 'simmktshare')}
                                            >
                                                <h4>Simulated Market Share</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['diffmktshare'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['diffmktshare'] || 'asc'}
                                                onClick={(event) => sortDataUnits(event, 'brands', 'diffmktshare')}
                                            >
                                                <h4>Difference in Market Share (Points)</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.props.state.brand_base_vols && this.props.state.brand_inc_vols && this.props.state.brand_vol_pct && this.props.state.brand_share_pct_new && this.props.state.brand_share_pct_old &&
                                        (this.state.brands_for_totalcat || Object.keys(this.props.state.brand_base_vols)).slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map(brand => {
                                            const reducer = (accumulator, currentValue) => accumulator + currentValue;

                                            let total_category_volume_old = Object.values(this.props.state.brand_base_vols).reduce(reducer)
                                            let total_category_changes = Object.values(this.props.state.brand_inc_vols).reduce(reducer)
                                            let total_category_compchanges = ([0].concat(Object.values(this.props.state.comp_item_changes_brand))).reduce(reducer)
                                            let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges

                                            let basevol = this.props.state.brand_base_vols[brand]
                                            let changevol = this.props.state.brand_inc_vols[brand]
                                            let compchangevol = this.props.state.comp_item_changes_brand[brand] || 0
                                            let newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                                            let oldshare = basevol / total_category_volume_old

                                            return <TableRow key={'tablerow_' + brand}>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{brand}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{Math.round(basevol).toLocaleString()}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{Math.round(changevol + compchangevol).toLocaleString()}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(((changevol + compchangevol) / basevol) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(newshare).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{((newshare - oldshare) * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={(Object.keys(this.props.state.brand_base_vols || {}) || []).length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.pageNum}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                style={{ width: '500px' }}
                            />
                        </div>
                    </div>
                    {/*Dollar Change Metrics Go Here*/}
                    <div className="row" style={{ display: this.state.dolstyle }}>
                        {!this.state.mktshareSwitchCheck ?
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - ' + this.props.state.currencytype + ' by Brand' + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.totalCatDoltype,
                                                        data: filterArray(Object.values(this.props.state.brand_base_dols), this.state.brandRankStart, this.state.brandRankEnd),
                                                        color: '#4e106f'
                                                    },
                                                    {
                                                        name: this.props.state.dollarChangeType,
                                                        data: filterArray((Object.keys(this.props.state.brand_base_dols) || []), this.state.brandRankStart, this.state.brandRankEnd, true).map(brand => this.props.state.brand_inc_dols[brand] + (this.props.state.comp_item_changes_brand_dollars[brand] || 0)),
                                                        color: '#000000'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Brand'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(Object.keys(this.props.state.brand_base_dols), this.state.brandRankStart, this.state.brandRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.currencytype
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                        else if (Math.abs(num) < 1E3) {
                                                                            return (num).toFixed(0);
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }

                                                                let value = nFormatter(this.value, 1)
                                                                let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                                let symbol = value.substring(value.length - 1)
                                                                return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                            }
                                                        }
                                                    },
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.series.name + ' : ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                    }
                                                }
                                            }}
                                    />
                                </div>
                            </div>
                            :
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - Lift % and Market Share by Brand' + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.dollarPctChangeType,
                                                        data: filterArray(Object.keys(this.props.state.brand_base_dols), this.state.brandRankStart, this.state.brandRankEnd, true).map(brand => {
                                                            return ((this.props.state.brand_inc_dols[brand] + (this.props.state.comp_item_changes_brand_dollars[brand] || 0)) / this.props.state.brand_base_dols[brand])
                                                        }),
                                                        color: '#ef2a79'
                                                    },
                                                    {
                                                        name: 'Market Share',
                                                        data: filterArray(Object.keys(this.props.state.brand_base_dols), this.state.brandRankStart, this.state.brandRankEnd, true).map(brand => {
                                                            return (this.props.state.brand_inc_dols[brand] + (this.props.state.comp_item_changes_brand_dollars[brand] || 0) + this.props.state.brand_base_dols[brand]) / (Object.values(this.props.state.brand_base_dols).reduce(reducer) + Object.values(this.props.state.brand_inc_dols).reduce(reducer) + ([0].concat(Object.values(this.props.state.comp_item_changes_brand_dollars))).reduce(reducer))
                                                        }),
                                                        color: '#565a5c'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Brand'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(Object.keys(this.props.state.brand_base_dols), this.state.brandRankStart, this.state.brandRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: 'Percent'
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        opposite: true,
                                                        labels: {
                                                            formatter: function () {
                                                                return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                            }
                                                        }
                                                    }
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.x + '<br>' + this.series.name + ' : ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                    }
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        }

                        <div className="row col-sm-12">
                            <Table id="TotalCatTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['brandname'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['brandname'] || 'asc'}
                                                onClick={(event) => sortDataDollars(event, 'brands', 'brandname')}
                                            >
                                                <h4>Brand</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['dollars'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['dollars'] || 'asc'}
                                                onClick={(event) => sortDataDollars(event, 'brands', 'dollars')}
                                            >
                                                <h4>{'Base ' + this.props.state.currencytype}</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        {this.props.state.simsettings.sim_type === 'base' ?
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'brands', 'incremental')}
                                                    >
                                                        <h4>{'Change in Base ' + this.props.state.currencytype}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'brands', 'lift')}
                                                    >
                                                        <h4>{'% Change in Base ' + this.props.state.currencytype}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'brands', 'incremental')}
                                                    >
                                                        <h4>{'Incremental ' + this.props.state.currencytype}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['brands'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['brands'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'brands', 'lift')}
                                                    >
                                                        <h4>% Lift</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                        }
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['simmktshare'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['simmktshare'] || 'asc'}
                                                onClick={(event) => sortDataDollars(event, 'brands', 'simmktshare')}
                                            >
                                                <h4>Simulated Market Share</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['brands'] || {})['diffmktshare'] || false}
                                                direction={(this.state.header_sort_direction['brands'] || {})['diffmktshare'] || 'asc'}
                                                onClick={(event) => sortDataDollars(event, 'brands', 'diffmktshare')}
                                            >
                                                <h4>Difference in Market Share (Points)</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.props.state.brand_base_dols && this.props.state.brand_inc_dols && this.props.state.brand_dol_pct && this.props.state.brand_share_pct_new_dol && this.props.state.brand_share_pct_old_dol &&
                                        (this.state.brands_for_totalcat_dollars || Object.keys(this.props.state.brand_base_dols)).slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map(brand => {
                                            //Create Vars
                                            const reducer = (accumulator, currentValue) => accumulator + currentValue;

                                            let total_category_volume_old = Object.values(this.props.state.brand_base_dols).reduce(reducer)
                                            let total_category_changes = Object.values(this.props.state.brand_inc_dols).reduce(reducer)
                                            let total_category_compchanges = ([0].concat(Object.values(this.props.state.comp_item_changes_brand_dollars))).reduce(reducer)
                                            let total_category_volume_new = total_category_volume_old + total_category_changes + total_category_compchanges
                                            //console.log(total_category_volume_new)
                                            let basevol = this.props.state.brand_base_dols[brand]
                                            let changevol = this.props.state.brand_inc_dols[brand]
                                            let compchangevol = this.props.state.comp_item_changes_brand_dollars[brand] || 0
                                            let newshare = (basevol + changevol + compchangevol) / total_category_volume_new
                                            let oldshare = basevol / total_category_volume_old
                                            //console.log(newshare)
                                            //console.log(oldshare)
                                            //console.log(newshare - oldshare)
                                            return <TableRow key={'tablerow_' + brand}>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{brand}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{Math.round(basevol).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{Math.round(changevol + compchangevol).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(((changevol + compchangevol) / basevol) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(newshare).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{((newshare - oldshare)*100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={(Object.keys(this.props.state.brand_base_dols || {}) || []).length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.pageNum}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                style={{ width: '500px' }}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={this.state.value} index={1} style={{ width: '100%', height: '100%' }} >
                    {(this.props.state.selected_brands_totalcat !== 'All') ?
                        <div className="row col-sm-12" style={{ display: this.state.volstyle }}>
                            <h4>The total change to the brand was: {Math.round(this.props.state.brand_items_incvols_total + (this.props.state.comp_item_changes_brand[this.props.state.selected_brands_totalcat] || 0)).toLocaleString() + ' ' + this.props.state.unitsvar + ' or ' + (Math.round((this.props.state.brand_items_incvols_total + (this.props.state.comp_item_changes_brand[this.props.state.selected_brands_totalcat] || 0))) / Math.round(this.props.state.brand_items_basevols_total)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h4>
                        </div>
                        :
                        <div className="row col-sm-12" style={{ display: this.state.volstyle }} />
                    }
                    <div className="row" style={{ display: this.state.volstyle }}>
                        <div className='col-sm-6' align="left">
                            <span className="formfieldtitle">Select a brand to see a breakdown of volume by item</span>
                            <Select
                                options={this.props.state.brand_options_totalCat}
                                value={{ 'label': this.props.state.selected_brands_totalcat, 'value': this.props.state.selected_brands_totalcat }}
                                onChange={(brand) => changeBrandSelectedTotalCatLocal(brand)}
                            />
                        </div>
                        {(this.props.state.brand_items_names || []).length > 5
                            ?
                            <div className='col-sm-3' >
                                <span className="formfieldtitle">Select Items</span>
                                <Select
                                    options={this.state.itemRanks}
                                    onChange={(item) => changeItemNums(item)}
                                    value={this.state.selectedItemsRank}
                                />
                            </div>
                            :
                            <div className='col-sm-3' />
                        }

                        {!this.state.mktshareSwitchCheck ?
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - ' + this.props.state.unitsvar + ' by Item for Brand: ' + this.props.state.selected_brands_totalcat + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.totalCatVoltype,
                                                        data: filterArray(this.props.state.brand_items_basevols, this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#4e106f'
                                                    },
                                                    {
                                                        name: this.props.state.unitChangeType,
                                                        data: filterArray((this.props.state.brand_items_nums || []).map((item, idx) => {
                                                            let inc = this.props.state.brand_items_incvols[idx] || 0
                                                            let comp = this.props.state.comp_item_changes_item[item] || 0
                                                            return (inc + comp)
                                                        }), this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#000000'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Item'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(this.props.state.brand_items_names, this.state.itemRankStart, this.state.itemRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }

                                                                return nFormatter(this.value, 0).toLocaleString()
                                                            }
                                                        }
                                                    },
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.x + '<br>' + this.series.name + ' : ' + Math.round(this.y).toLocaleString() + '<br>Item Price : ' + Math.round(items_price[this.point.x]).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                    }
                                                }
                                            }}
                                    />
                                </div>
                            </div>
                            :
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - Lift % and Market Share by Item for Brand: ' + this.props.state.selected_brands_totalcat + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.unitPctChangeType,
                                                        data: filterArray((this.props.state.brand_items_nums || []).map((item, idx) => {
                                                            let inc = this.props.state.brand_items_incvols[idx] || 0
                                                            let comp = this.props.state.comp_item_changes_item[item] || 0
                                                            let base = this.props.state.brand_items_basevols[idx] || 0
                                                            return ((inc + comp) / base)
                                                        }), this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#ef2a79'
                                                    },
                                                    {
                                                        name: 'Market Share',
                                                        data: filterArray((this.props.state.brand_items_nums || []).map((item, idx) => {
                                                            let inc = this.props.state.brand_items_incvols[idx] || 0
                                                            let comp = this.props.state.comp_item_changes_item[item] || 0
                                                            let items_base = this.props.state.brand_items_basevols[idx] || 0
                                                            let base = Object.values(this.props.state.brand_base_vols).reduce(reducer) || 0
                                                            let baseinc = Object.values(this.props.state.brand_inc_vols).reduce(reducer) || 0
                                                            let basecomp = ([0].concat(Object.values(this.props.state.comp_item_changes_brand))).reduce(reducer) || 0
                                                            return (
                                                                (inc + comp + items_base) / (base + baseinc + basecomp)
                                                            )
                                                        }), this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#565a5c'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Item'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(this.props.state.brand_items_names, this.state.itemRankStart, this.state.itemRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: 'Percent'
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        opposite: true,
                                                        labels: {
                                                            formatter: function () {
                                                                return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                            }
                                                        }
                                                    }
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.x + '<br>' + this.series.name + ' : ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '<br>Item Price : ' + Math.round(items_price[this.point.x]).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                    }
                                                }
                                            }}
                                    />
                                </div>
                            </div>
                        }

                        <div className="row col-sm-12">
                            <Table id="TotalCatTableItems">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['items'] || {})['itemname'] || false}
                                                direction={(this.state.header_sort_direction['items'] || {})['itemname'] || 'asc'}
                                                onClick={(event) => sortDataUnits(event, 'items', 'itemname')}
                                            >
                                                <h4>Item</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        {this.props.state.simsettings.sim_type === 'base' ?
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'items', 'incremental')}
                                                    >
                                                        <h4>Change in Base {this.props.state.unitsvar}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'items', 'lift')}
                                                    >
                                                        <h4>% Change in Base {this.props.state.unitsvar}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'items', 'incremental')}
                                                    >
                                                        <h4>Incremental {this.props.state.unitsvar}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataUnits(event, 'items', 'lift')}
                                                    >
                                                        <h4>% Lift</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                        }
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['items'] || {})['simmktshare'] || false}
                                                direction={(this.state.header_sort_direction['items'] || {})['simmktshare'] || 'asc'}
                                                onClick={(event) => sortDataUnits(event, 'items', 'simmktshare')}
                                            >
                                                <h4>Simulated Market Share</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['items'] || {})['diffmktshare'] || false}
                                                direction={(this.state.header_sort_direction['items'] || {})['diffmktshare'] || 'asc'}
                                                onClick={(event) => sortDataUnits(event, 'items', 'diffmktshare')}
                                            >
                                                <h4>Difference in Market Share (Points)</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.props.state.brand_items_nums && (this.state.items_for_totalcat || this.props.state.brand_items_nums).slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map((item, itemidx) => {
                                            //Set up vars
                                            let metrics = this.props.state.brand_items_all_metrics[item]
                                            let compchanges = this.props.state.comp_item_changes_item[parseInt(item)] || 0
                                            let itemname = metrics['proddesc']
                                            let incvol = metrics['incvol'] + compchanges
                                            let basevol = metrics['basevol']
                                            let volpct = (incvol / basevol) //metrics['volpct'] / 100
                                            let newshare = metrics['newshare'] / 100
                                            let oldshare = metrics['oldshare'] / 100
                                            let sharechange = metrics['newshare'] - metrics['oldshare']

                                            return <TableRow key={'tablerow_' + item}>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{itemname}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{Math.round(incvol).toLocaleString()}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(volpct).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(newshare).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(sharechange).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={(this.props.state.brand_items_nums || []).length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.pageNum}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                style={{ width: '500px' }}
                            />
                        </div>
                    </div>
                    <div className="row col-sm-12" style={{ display: this.state.dolstyle }}>
                        <h4>The total dollar change to the brand was: {Math.round(this.props.state.brand_items_incdols_total + (this.props.state.comp_item_changes_brand_dollars[this.props.state.selected_brands_totalcat] || 0)).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' ' + this.props.state.currencytype + ' or ' + (Math.round(this.props.state.brand_items_incdols_total + (this.props.state.comp_item_changes_brand_dollars[this.props.state.selected_brands_totalcat] || 0)) / Math.round(this.props.state.brand_items_basedols_total)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h4>
                    </div>
                    <div className="row" style={{ display: this.state.dolstyle }}>
                        <div className='col-sm-6' align="left">
                            <span className="formfieldtitle">Select a brand to see a breakdown of dollars by item</span>
                            <Select
                                options={this.props.state.brand_options_totalCat}
                                value={{ 'label': this.props.state.selected_brands_totalcat, 'value': this.props.state.selected_brands_totalcat }}
                                onChange={(brand) => changeBrandSelectedTotalCatLocal(brand)}
                            />
                        </div>

                        {(this.props.state.brand_items_names || []).length > 5
                            ?
                            <div className='col-sm-3' >
                                <span className="formfieldtitle">Select Items</span>
                                <Select
                                    options={this.state.itemRanks}
                                    onChange={(item) => changeItemNums(item)}
                                    value={this.state.selectedItemsRank}
                                />
                            </div>
                            :
                            <div className='col-sm-3' />
                        }

                        {!this.state.mktshareSwitchCheck ?
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - ' + this.props.state.currencytype + ' by Item for Brand: ' + this.props.state.selected_brands_totalcat + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.totalCatDoltype,
                                                        data: filterArray(this.props.state.brand_items_basedols, this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#4e106f'
                                                    },
                                                    {
                                                        name: this.props.state.dollarChangeType,
                                                        data: filterArray((this.props.state.brand_items_nums || []).map((item, idx) => {
                                                            let inc = this.props.state.brand_items_incdols[idx] || 0
                                                            let comp = this.props.state.comp_item_changes_item_dollars[parseInt(item)] || 0
                                                            return (inc + comp)
                                                        }), this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#000000'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Item'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(this.props.state.brand_items_names, this.state.itemRankStart, this.state.itemRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.currencytype
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                        else if (Math.abs(num) < 1E3) {
                                                                            return (num).toFixed(0);
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }

                                                                let value = nFormatter(this.value, 1)
                                                                let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                                let symbol = value.substring(value.length - 1)
                                                                return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                            }
                                                        }
                                                    },
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.series.name + ' : ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + '<br>Item Price : ' + Math.round(items_price[this.point.x]).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                    }
                                                }
                                            }}
                                    />
                                </div>
                            </div>
                            :
                            <div className="col-sm-12" style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                                <h5 style={{ padding: '10px' }}></h5>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'bar',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Total Category - Lift % and Market Share by Item for Brand: ' + this.props.state.selected_brands_totalcat + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.dollarPctChangeType,
                                                        data: filterArray((this.props.state.brand_items_nums || []).map((item, idx) => {
                                                            let change = this.props.state.brand_items_incdols[idx] || 0
                                                            let compchange = this.props.state.comp_item_changes_item_dollars[item] || 0
                                                            let brand_base = this.props.state.brand_items_basedols[idx]
                                                            return ((change + compchange) / brand_base)
                                                        }), this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#ef2a79'
                                                    },
                                                    {
                                                        name: 'Market Share',
                                                        data: filterArray((this.props.state.brand_items_nums || []).map((item, idx) => {
                                                            let change = this.props.state.brand_items_incdols[idx] || 0
                                                            let compchange = this.props.state.comp_item_changes_item_dollars[item] || 0
                                                            let items_base = this.props.state.brand_items_basedols[idx] || 0
                                                            let brand_base = Object.values(this.props.state.brand_base_dols).reduce(reducer)
                                                            let brand_inc = Object.values(this.props.state.brand_inc_dols).reduce(reducer)
                                                            let item_comp_changes = [0].concat(Object.values(this.props.state.comp_item_changes_brand_dollars)).reduce(reducer)
                                                            return (
                                                                (change + compchange + items_base) / (brand_base + brand_inc + item_comp_changes)
                                                            )
                                                        }), this.state.itemRankStart, this.state.itemRankEnd),
                                                        color: '#565a5c'
                                                    },
                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Item'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: filterArray(this.props.state.brand_items_names, this.state.itemRankStart, this.state.itemRankEnd, true)
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: 'Percent'
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        opposite: true,
                                                        labels: {
                                                            formatter: function () {
                                                                return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                            }
                                                        }
                                                    }
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: false,
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        return this.x + '<br>' + this.series.name + ' : ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '<br>Item Price : ' + Math.round(items_price[this.point.x]).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                    }
                                                }
                                            }}
                                    />
                                </div>
                            </div>
                        }

                        <div className="row col-sm-12">
                            <Table id="TotalCatTableItems">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['items'] || {})['itemname'] || false}
                                                direction={(this.state.header_sort_direction['items'] || {})['itemname'] || 'asc'}
                                                onClick={(event) => sortDataDollars(event, 'items', 'itemname')}
                                            >
                                                <h4>Item</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        {this.props.state.simsettings.sim_type === 'base' ?
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'items', 'incremental')}
                                                    >
                                                        <h4>{'Change in Base ' + this.props.state.currencytype}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'items', 'lift')}
                                                    >
                                                        <h4>{'% Change in Base ' + this.props.state.currencytype}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['incremental'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['incremental'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'items', 'incremental')}
                                                    >
                                                        <h4>{'Incremental ' + this.props.state.currencytype}</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <TableSortLabel
                                                        active={(this.state.header_sort_active['items'] || {})['lift'] || false}
                                                        direction={(this.state.header_sort_direction['items'] || {})['lift'] || 'asc'}
                                                        onClick={(event) => sortDataDollars(event, 'items', 'lift')}
                                                    >
                                                        <h4>% Lift</h4>
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Fragment>
                                        }
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['items'] || {})['simmktshare'] || false}
                                                direction={(this.state.header_sort_direction['items'] || {})['simmktshare'] || 'asc'}
                                                onClick={(event) => sortDataDollars(event, 'items', 'simmktshare')}
                                            >
                                                <h4>Simulated Market Share</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TableSortLabel
                                                active={(this.state.header_sort_active['items'] || {})['diffmktshare'] || false}
                                                direction={(this.state.header_sort_direction['items'] || {})['diffmktshare'] || 'asc'}
                                                onClick={(event) => sortDataDollars(event, 'items', 'diffmktshare')}
                                            >
                                                <h4>Difference in Market Share (Points)</h4>
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.props.state.brand_items_nums && (this.state.items_for_totalcat || this.props.state.brand_items_nums).slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map((item, itemidx) => {
                                            //variables...
                                            let idx = itemidx + (this.state.pageNum * this.state.rowsPerPage)
                                            //Set up vars
                                            let metrics = this.props.state.brand_items_all_metrics[item]
                                            let compchanges = this.props.state.comp_item_changes_item_dollars[parseInt(item)] || 0
                                            let itemname = metrics['proddesc']
                                            let incdollars = metrics['incdollars'] + compchanges
                                            let basedollars = metrics['basedollars']
                                            let volpct_dollars = incdollars / basedollars // metrics['volpct_dollars'] / 100
                                            let newshare = metrics['newshare_dollars']
                                            let oldshare = metrics['oldshare_dollars']
                                            let sharechange = (newshare - oldshare) * 100

                                            return <TableRow key={'tablerow_' + item}>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{itemname}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{Math.round(incdollars).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(volpct_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(newshare).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</TableCell>
                                                <TableCell align='center' style={{ fontSize: '.875rem' }}>{(sharechange).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={(this.props.state.brand_items_nums || []).length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.pageNum}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                style={{ width: '500px' }}
                            />
                        </div>
                    </div>
                </TabPanel>
            </div>
        )
    }
}
