import React, { Component, Fragment } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import Select from 'react-select'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import '../styles/custom.css'
import * as Constant from '../constants'
import selectColourStyles from '../styles/SelectColourStyles'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}


export class PdtSplittingDialog extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Fragment>
                {this.props.state.pdtselected &&
                    <TabPanel value={this.props.state.datasetTabSelected} index={2}>
                        <div className="row">
                            <h4>Split PDT Dataset into Store Group / RoM Datasets</h4>
                            <div className='col-sm-12 row'>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select a country</span>
                                    <Select
                                        placeholder='Select Country'
                                        inputId="pdtsplitcountrydropdown"
                                        options={this.props.state.finalSortCountry}
                                        width="200px"
                                        onChange={(item, action) => this.props.onChangePdtSplittingCountry(item, action)}
                                    value={(this.props.state.pdtSplitCountryName) ? { 'label': this.props.state.pdtSplitCountryName, 'value': this.props.state.pdtSplitCountry } : ''}
                                    styles={selectColourStyles}
                                />

                                </div>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select one industry</span>
                                    <Select
                                        placeholder='Select Industry'
                                        inputId="pdtsplitindustrydropdown"
                                        options={this.props.state.countryInd_options}
                                        width="200px"
                                        onChange={(item, action) => this.props.onChangePdtSplittingIndustry(item, action)}
                                    value={(this.props.state.pdtSplitIndustryName) ? { 'label': this.props.state.pdtSplitIndustryName, 'value': this.props.state.pdtSplitIndustry } : ''}
                                    styles={selectColourStyles}
                                />
                                </div>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select the environment</span><br />
                                    <FormControlLabel
                                        className='formfieldtitle'
                                        control={<ThemeProvider theme={Constant.muiTheme}>
                                            <Radio
                                                checked={this.props.state.splitting_env === 'active'}
                                                onChange={(event) => this.props.onChangePdtSplittingEnvRadio(event)}
                                                value="active"
                                                name="splittingenvradio"
                                                size="small"
                                                color="secondary"
                                                id="activeradio" />
                                        </ThemeProvider>}
                                        label="Active" />
                                    <FormControlLabel
                                        className='formfieldtitle'
                                        control={<ThemeProvider theme={Constant.muiTheme}>
                                            <Radio
                                                checked={this.props.state.splitting_env === 'staging'}
                                                onChange={(event) => this.props.onChangePdtSplittingEnvRadio(event)}
                                                value="staging"
                                                name="splittingenvradio"
                                                size="small"
                                                color="secondary"
                                                id="stagingradio" />
                                        </ThemeProvider>}
                                        label="Staging" />
                                </div>
                                <div className='col-sm-3'></div>
                            </div>
                            <div className='col-sm-12 row'>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select the Dataset for Splitting</span>
                                    <Select
                                        placeholder='Select a Dataset'
                                        inputId="pdtSplitDataset"
                                        options={this.props.state.pdt_split_dataset_options}
                                        width="200px"
                                        onChange={(item, action) => this.props.onChangePdtSplittingDataset(item, action)}
                                        value={(this.props.state.pdtSplitDatasetName) ? { 'label': this.props.state.pdtSplitDatasetName, 'value': this.props.state.pdtSplitDatasetId } : ''} 
                                        styles={selectColourStyles}
                                   />
                                </div>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select the Store Group</span>
                                    <Select
                                    placeholder='Select a Store Group'
                                    inputId="pdtSplitStoreGroup"
                                    options={this.props.state.pdt_split_store_group_options}
                                    width="200px"
                                    onChange={(item, action) => this.props.onChangePdtSplittingStoreGroup(item, action)}
                                    value={(this.props.state.pdtSplitStoreGroupName) ? { 'label': this.props.state.pdtSplitStoreGroupName, 'value': this.props.state.pdtSplitStoreGroupId } : ''}
                                    styles={selectColourStyles}
                                   />
                                </div>
                                <div className='col-sm-6'></div>
                            </div>
                            <div className='col-sm-12 row' style={{ marginTop: '10px' }}>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Enter the new RoM Dataset Store Group / Outlet Suffix</span>
                                    <TextField
                                        placeholder='Enter the desired RoM Outlet Suffix'
                                        variant="outlined"
                                        inputProps={{ style: { padding: 10 } }}
                                        id="pdtSplittingromoutlet"
                                        value={this.props.state.pdt_splitting_rom_outlet}
                                        onChange={this.props.onChangePdtSplittingRomOutlet}
                                        fullWidth />
                                </div>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Enter the Split Dataset Short Description Prefix</span>
                                    <TextField
                                        placeholder='Enter a short description prefix'
                                        variant="outlined"
                                        inputProps={{ style: { padding: 10 } }}
                                        id="pdtSplittingShortDesc"
                                        value={this.props.state.pdt_splitting_short_desc}
                                        onChange={this.props.onChangePdtSplittingShortDesc}
                                        fullWidth />
                                </div>
                                <div className='col-sm-6'>
                                    <span className='formfieldtitle'>Enter the Split Dataset Full Description Prefix</span>
                                    <TextField
                                        placeholder='Enter a long description prefix'
                                        variant="outlined"
                                        inputProps={{ style: { padding: 10 } }}
                                        id="pdtSplittingLongDesc"
                                        value={this.props.state.pdt_splitting_description}
                                        onChange={this.props.onChangePdtSplittingLongDesc}
                                        fullWidth />
                                </div>
                            </div>
                            <div className='col-sm-12 row' style={{ margin: '25px' }}>
                                <div className='col-sm-8'>
                                </div>
                                <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                    <ThemeProvider theme={this.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.props.handleSubmitPdtSplitting}
                                            disabled={!this.props.state.pdt_splitting_button_enabled}
                                        >
                                            Submit Splitting Request
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                            <div className="row" style={{ textAlign: 'left', margin: '25px' }}>
                                <p>
                                    The process of splitting a single dataset into two datasets, one with only the selected Store Group (or outlet) and the
                                    second with the rest of market for that store group, involves the following steps:
                                </p>
                                <ul>
                                    <li>
                                        Select a dataset to split by selecting a country, industry, environment, and then the dataset.  Once
                                        you select the dataset to split, default values will be provided for the RoM Dataset Store Group / Outlet Name,
                                        the short description, and the long description.
                                    </li>
                                    <li>
                                        Select the Store Group to split into two datasets as the store group and its rest of market.
                                    </li>
                                    <li>
                                        Finally, make sure that you have your desired values for the RoM Store Group / Outlet Name, short description prefix,
                                        and full description prefix for the new split datasets to be created.  See the note below for more details.
                                    </li>
                                </ul>
                                <p>
                                    You do not have to accept the default values of the dataset prefix or full description.  The defaults are only populated
                                    for convenience, although in most cases you want to keep the default short and long description prefixes. If your
                                    selected industry is US Auto (Tires) then the RoM Store Group / Outlet Name will automatically be populated as
                                    "Modern Trade."  Otherwise you will have to fill it in.  When the dataset is split, the short description and the full
                                    description for the new split datasets will have the outlet name and " (ALR)" appended (for the ALR), or the configured
                                    RoM Store Group / Outlet name appended (for the RoM).
                                </p>
                                <p>
                                    For a dataset to be split, it must have at least one ALR. The new split datasets will always be created in staging.
                                </p>
                            </div>
                        </div>
                    </TabPanel>
                }
            </Fragment>
        )
    }
}
