import { reducer } from '../../Utilities/Reducer'
export default function changeMetricsCompareROM(selectedOptions) {
    var priordata, priordates, currentdata, currentdates, outlets

    currentdata = this.state.outlet_data_weekly
    currentdates = this.state.distinctdates_forcalc
    outlets = this.state.all_outlets

    if (this.state.ThreeYAGO) {
        priordata = this.state.outlet_data_weekly_threeyears
        priordates = this.state.distinctdates_threeyears
    }

    else if (this.state.TwoYAGO) {
        priordata = this.state.outlet_data_weekly_last
        priordates = this.state.distinctdates_last
    }
    else {
        priordata = this.state.outlet_data_weekly_prior
        priordates = this.state.distinctdates_prior
    }

    let metricsselected = (selectedOptions || []).map(option => option.value)

    let dataforviews = metricsselected.map(metric => {
        if (metric === 'Total Units') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['totalunits']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
            })


            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Total Units', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }

        else if (metric === 'Base Units') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['baseunits']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['baseunits'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Base Units', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }

        else if (metric === 'Unsubsidized Base Units') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['unsubsidizedbaseunits']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['unsubsidizedbaseunits'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Unsubsidized Base Units', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }

        else if (metric === 'Subsidized Base Units') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['subsidizedbaseunits']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['subsidizedbaseunits'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Subsidized Base Units', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }

        else if (metric === 'Incremental Units') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['incrementalunits']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['incrementalunits'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Incremental Units', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }

        else if (metric === 'Promoted Units') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['promotedunits']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['promotedunits'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Promoted Units', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }

        else if (metric === 'Non-Promoted Units') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['nonpromotedunits']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['nonpromotedunits'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Non-Promoted Units', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }

        else if (metric === 'Total Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['totaldollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['totaldollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Total Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Base Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['basedollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['basedollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Base Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Unsubsidized Base Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['unsubsidizedbasedollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['unsubsidizedbasedollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Unsubsidized Base Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Subsidized Base Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['subsidizedbasedollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['subsidizedbasedollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Subsidized Base Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Incremental Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['incrementaldollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['incrementaldollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Incremental Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Promoted Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['promoteddollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['promoteddollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Promoted Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Non-Promoted Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['nonpromoteddollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['nonpromoteddollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Non-Promoted Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Discount Dollars') {
            let current_values = outlets.map(outlet => {
                return currentdates.map(date => {
                    return currentdata[outlet][date]['discountdollars']
                }).reduce(reducer)
            })

            let prior_values = outlets.map(outlet => {
                return priordates.map(date => { return priordata[outlet][date]['discountdollars'] }).reduce(reducer)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Discount Dollars', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Average Base Price') {
            let current_values = outlets.map(outlet => {
                let weightedbp = currentdates.map(date => { return currentdata[outlet][date]['weightedbp'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                return (weightedbp / totalunits)
            })

            let prior_values = outlets.map(outlet => {
                let weightedbp = priordates.map(date => { return priordata[outlet][date]['weightedbp'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                return (weightedbp / totalunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Average Base Price', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Average Selling Price') {
            let current_values = outlets.map(outlet => {
                let weightedasp = currentdates.map(date => { return currentdata[outlet][date]['weightedasp'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                return (weightedasp / totalunits)
            })

            let prior_values = outlets.map(outlet => {
                let weightedasp = priordates.map(date => { return priordata[outlet][date]['weightedasp'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                return (weightedasp / totalunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Average Selling Price', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Average Promo Price') {
            let current_values = outlets.map(outlet => {
                let weightedbp = currentdates.map(date => { return currentdata[outlet][date]['weightedbp'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                let totaldisc_wo = currentdates.map(date => { return currentdata[outlet][date]['weightedpctdisc_withoutno'] }).reduce(reducer)
                let totalunits_wo = currentdates.map(date => { return currentdata[outlet][date]['totalunits_withoutno'] }).reduce(reducer)
                return ((weightedbp / totalunits) * (1 - (totaldisc_wo / totalunits_wo)))
            })

            let prior_values = outlets.map(outlet => {
                let weightedbp = priordates.map(date => { return priordata[outlet][date]['weightedbp'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                let totaldisc_wo = priordates.map(date => { return priordata[outlet][date]['weightedpctdisc_withoutno'] }).reduce(reducer)
                let totalunits_wo = priordates.map(date => { return priordata[outlet][date]['totalunits_withoutno'] }).reduce(reducer)
                return ((weightedbp / totalunits) * (1 - (totaldisc_wo / totalunits_wo)))
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Average Promo Price', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Average Non-Promo Price') {
            let current_values = outlets.map(outlet => {
                let nonpromodollars = currentdates.map(date => { return currentdata[outlet][date]['nonpromoteddollars'] }).reduce(reducer)
                let nonpromounits = currentdates.map(date => { return currentdata[outlet][date]['nonpromotedunits'] }).reduce(reducer)
                return (nonpromodollars / nonpromounits)
            })

            let prior_values = outlets.map(outlet => {
                let nonpromodollars = priordates.map(date => { return priordata[outlet][date]['nonpromoteddollars'] }).reduce(reducer)
                let nonpromounits = priordates.map(date => { return priordata[outlet][date]['nonpromotedunits'] }).reduce(reducer)
                return (nonpromodollars / nonpromounits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Average Non-Promo Price', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'currency' }
        }

        else if (metric === 'Average Promoted Discount') {
            let current_values = outlets.map(outlet => {
                let totaldisc_wo = currentdates.map(date => { return currentdata[outlet][date]['weightedpctdisc_withoutno'] }).reduce(reducer)
                let totalunits_wo = currentdates.map(date => { return currentdata[outlet][date]['totalunits_withoutno'] }).reduce(reducer)
                return (totaldisc_wo / totalunits_wo)
            })

            let prior_values = outlets.map(outlet => {
                let totaldisc_wo = priordates.map(date => { return priordata[outlet][date]['weightedpctdisc_withoutno'] }).reduce(reducer)
                let totalunits_wo = priordates.map(date => { return priordata[outlet][date]['totalunits_withoutno'] }).reduce(reducer)
                return (totaldisc_wo / totalunits_wo)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Average Promoted Discount', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Average Discount') {
            let current_values = outlets.map(outlet => {
                let totaldisc = currentdates.map(date => { return currentdata[outlet][date]['weightedpctdisc'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                return (totaldisc / totalunits)
            })

            let prior_values = outlets.map(outlet => {
                let totaldisc = priordates.map(date => { return priordata[outlet][date]['weightedpctdisc'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                return (totaldisc / totalunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Average Discount', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Market Lift') {
            let current_values = outlets.map(outlet => {
                let incunits = currentdates.map(date => { return currentdata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let baseunits = currentdates.map(date => { return currentdata[outlet][date]['baseunits'] }).reduce(reducer)
                return (incunits / baseunits)
            })

            let prior_values = outlets.map(outlet => {
                let incunits = priordates.map(date => { return priordata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let baseunits = priordates.map(date => { return priordata[outlet][date]['baseunits'] }).reduce(reducer)
                return (incunits / baseunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Market Lift', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Promoted Lift') {
            let current_values = outlets.map(outlet => {
                let incunits = currentdates.map(date => { return currentdata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let subbaseunits = currentdates.map(date => { return currentdata[outlet][date]['subsidizedbaseunits'] }).reduce(reducer)
                return (incunits / subbaseunits)
            })

            let prior_values = outlets.map(outlet => {
                let incunits = priordates.map(date => { return priordata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let subbaseunits = priordates.map(date => { return priordata[outlet][date]['subsidizedbaseunits'] }).reduce(reducer)
                return (incunits / subbaseunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Promoted Lift', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Efficiency') {
            let current_values = outlets.map(outlet => {
                let incunits = currentdates.map(date => { return currentdata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let promotedunits = currentdates.map(date => { return currentdata[outlet][date]['promotedunits'] }).reduce(reducer)
                return (incunits / promotedunits)
            })

            let prior_values = outlets.map(outlet => {
                let incunits = priordates.map(date => { return priordata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let promotedunits = priordates.map(date => { return priordata[outlet][date]['promotedunits'] }).reduce(reducer)
                return (incunits / promotedunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Efficiency', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Market Lift (Dollars)') {
            let current_values = outlets.map(outlet => {
                let incunits = currentdates.map(date => { return currentdata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let baseunits = currentdates.map(date => { return currentdata[outlet][date]['basedollars'] }).reduce(reducer)
                return (incunits / baseunits)
            })

            let prior_values = outlets.map(outlet => {
                let incunits = priordates.map(date => { return priordata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let baseunits = priordates.map(date => { return priordata[outlet][date]['basedollars'] }).reduce(reducer)
                return (incunits / baseunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Market Lift (Dollars)', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Promoted Lift (Dollars)') {
            let current_values = outlets.map(outlet => {
                let incunits = currentdates.map(date => { return currentdata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let subbaseunits = currentdates.map(date => { return currentdata[outlet][date]['subsidizedbasedollars'] }).reduce(reducer)
                return (incunits / subbaseunits)
            })

            let prior_values = outlets.map(outlet => {
                let incunits = priordates.map(date => { return priordata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let subbaseunits = priordates.map(date => { return priordata[outlet][date]['subsidizedbasedollars'] }).reduce(reducer)
                return (incunits / subbaseunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Promoted Lift (Dollars)', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Efficiency (Dollars)') {
            let current_values = outlets.map(outlet => {
                let incunits = currentdates.map(date => { return currentdata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let promotedunits = currentdates.map(date => { return currentdata[outlet][date]['promoteddollars'] }).reduce(reducer)
                return (incunits / promotedunits)
            })

            let prior_values = outlets.map(outlet => {
                let incunits = priordates.map(date => { return priordata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let promotedunits = priordates.map(date => { return priordata[outlet][date]['promoteddollars'] }).reduce(reducer)
                return (incunits / promotedunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Efficiency (Dollars)', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Non-Promoted Units % of Total') {
            let current_values = outlets.map(outlet => {
                let nonpromounits = currentdates.map(date => { return currentdata[outlet][date]['nonpromotedunits'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                return (nonpromounits / totalunits)
            })

            let prior_values = outlets.map(outlet => {
                let nonpromounits = priordates.map(date => { return priordata[outlet][date]['nonpromotedunits'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                return (nonpromounits / totalunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Non-Promoted Units % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Promoted Units % of Total') {
            let current_values = outlets.map(outlet => {
                let promounits = currentdates.map(date => { return currentdata[outlet][date]['promotedunits'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                return (promounits / totalunits)
            })

            let prior_values = outlets.map(outlet => {
                let promounits = priordates.map(date => { return priordata[outlet][date]['promotedunits'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                return (promounits / totalunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Promoted Units % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Base Units % of Total') {
            let current_values = outlets.map(outlet => {
                let baseunits = currentdates.map(date => { return currentdata[outlet][date]['baseunits'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                return (baseunits / totalunits)
            })

            let prior_values = outlets.map(outlet => {
                let baseunits = priordates.map(date => { return priordata[outlet][date]['baseunits'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                return (baseunits / totalunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Base Units % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Incremental Units % of Total') {
            let current_values = outlets.map(outlet => {
                let incrementalunits = currentdates.map(date => { return currentdata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let totalunits = currentdates.map(date => { return currentdata[outlet][date]['totalunits'] }).reduce(reducer)
                return (incrementalunits / totalunits)
            })

            let prior_values = outlets.map(outlet => {
                let incrementalunits = priordates.map(date => { return priordata[outlet][date]['incrementalunits'] }).reduce(reducer)
                let totalunits = priordates.map(date => { return priordata[outlet][date]['totalunits'] }).reduce(reducer)
                return (incrementalunits / totalunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Incremental Units % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Unsubsidized Base Units % of Base') {
            let current_values = outlets.map(outlet => {
                let unsubsidizedbaseunits = currentdates.map(date => { return currentdata[outlet][date]['unsubsidizedbaseunits'] }).reduce(reducer)
                let baseunits = currentdates.map(date => { return currentdata[outlet][date]['baseunits'] }).reduce(reducer)
                return (unsubsidizedbaseunits / baseunits)
            })

            let prior_values = outlets.map(outlet => {
                let unsubsidizedbaseunits = priordates.map(date => { return priordata[outlet][date]['unsubsidizedbaseunits'] }).reduce(reducer)
                let baseunits = priordates.map(date => { return priordata[outlet][date]['baseunits'] }).reduce(reducer)
                return (unsubsidizedbaseunits / baseunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Unsubsidized Base Units % of Base', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Subsidized Base Units % of Base') {
            let current_values = outlets.map(outlet => {
                let subsidizedbaseunits = currentdates.map(date => { return currentdata[outlet][date]['subsidizedbaseunits'] }).reduce(reducer)
                let baseunits = currentdates.map(date => { return currentdata[outlet][date]['baseunits'] }).reduce(reducer)
                return (subsidizedbaseunits / baseunits)
            })

            let prior_values = outlets.map(outlet => {
                let subsidizedbaseunits = priordates.map(date => { return priordata[outlet][date]['subsidizedbaseunits'] }).reduce(reducer)
                let baseunits = priordates.map(date => { return priordata[outlet][date]['baseunits'] }).reduce(reducer)
                return (subsidizedbaseunits / baseunits)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Subsidized Base Units % of Base', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Non-Promoted % of Total Dollars') {
            let current_values = outlets.map(outlet => {
                let nonpromodollars = currentdates.map(date => { return currentdata[outlet][date]['nonpromoteddollars'] }).reduce(reducer)
                let totaldollars = currentdates.map(date => { return currentdata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (nonpromodollars / totaldollars)
            })

            let prior_values = outlets.map(outlet => {
                let nonpromodollars = priordates.map(date => { return priordata[outlet][date]['nonpromoteddollars'] }).reduce(reducer)
                let totaldollars = priordates.map(date => { return priordata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (nonpromodollars / totaldollars)
            })


            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Non-Promoted Dollars % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Promoted % of Total Dollars') {
            let current_values = outlets.map(outlet => {
                let promodollars = currentdates.map(date => { return currentdata[outlet][date]['promoteddollars'] }).reduce(reducer)
                let totaldollars = currentdates.map(date => { return currentdata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (promodollars / totaldollars)
            })

            let prior_values = outlets.map(outlet => {
                let promodollars = priordates.map(date => { return priordata[outlet][date]['promoteddollars'] }).reduce(reducer)
                let totaldollars = priordates.map(date => { return priordata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (promodollars / totaldollars)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Promoted Dollars % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Base % of Total Dollars') {
            let current_values = outlets.map(outlet => {
                let basedollars = currentdates.map(date => { return currentdata[outlet][date]['basedollars'] }).reduce(reducer)
                let totaldollars = currentdates.map(date => { return currentdata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (basedollars / totaldollars)
            })

            let prior_values = outlets.map(outlet => {
                let basedollars = priordates.map(date => { return priordata[outlet][date]['basedollars'] }).reduce(reducer)
                let totaldollars = priordates.map(date => { return priordata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (basedollars / totaldollars)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Base Dollars % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Incremental % of Total Dollars') {
            let current_values = outlets.map(outlet => {
                let incrementaldollars = currentdates.map(date => { return currentdata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let totaldollars = currentdates.map(date => { return currentdata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (incrementaldollars / totaldollars)
            })

            let prior_values = outlets.map(outlet => {
                let incrementaldollars = priordates.map(date => { return priordata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let totaldollars = priordates.map(date => { return priordata[outlet][date]['totaldollars'] }).reduce(reducer)
                return (incrementaldollars / totaldollars)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Incremental Dollars % of Total', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Unsubsidized Base % of Base Dollars') {
            let current_values = outlets.map(outlet => {
                let unsubsidizedbasedollars = currentdates.map(date => { return currentdata[outlet][date]['unsubsidizedbasedollars'] }).reduce(reducer)
                let basedollars = currentdates.map(date => { return currentdata[outlet][date]['basedollars'] }).reduce(reducer)
                return (unsubsidizedbasedollars / basedollars)
            })

            let prior_values = outlets.map(outlet => {
                let unsubsidizedbasedollars = priordates.map(date => { return priordata[outlet][date]['unsubsidizedbasedollars'] }).reduce(reducer)
                let basedollars = priordates.map(date => { return priordata[outlet][date]['basedollars'] }).reduce(reducer)
                return (unsubsidizedbasedollars / basedollars)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Unsubsidized Base Dollars % of Base', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Subsidized Base % of Base Dollars') {
            let current_values = outlets.map(outlet => {
                let subsidizedbasedollars = currentdates.map(date => { return currentdata[outlet][date]['subsidizedbasedollars'] }).reduce(reducer)
                let basedollars = currentdates.map(date => { return currentdata[outlet][date]['basedollars'] }).reduce(reducer)
                return (subsidizedbasedollars / basedollars)
            })

            let prior_values = outlets.map(outlet => {
                let subsidizedbasedollars = priordates.map(date => { return priordata[outlet][date]['subsidizedbasedollars'] }).reduce(reducer)
                let basedollars = priordates.map(date => { return priordata[outlet][date]['basedollars'] }).reduce(reducer)
                return (subsidizedbasedollars / basedollars)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Subsidized Base Dollars % of Base', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'percent' }
        }

        else if (metric === 'Payback Ratio') {
            let current_values = outlets.map(outlet => {
                let incrementaldollars = currentdates.map(date => { return currentdata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let discountdollars = currentdates.map(date => { return currentdata[outlet][date]['discountdollars'] }).reduce(reducer)
                return (incrementaldollars / discountdollars)
            })

            let prior_values = outlets.map(outlet => {
                let incrementaldollars = priordates.map(date => { return priordata[outlet][date]['incrementaldollars'] }).reduce(reducer)
                let discountdollars = priordates.map(date => { return priordata[outlet][date]['discountdollars'] }).reduce(reducer)
                return (incrementaldollars / discountdollars)
            })

            let priorvalues_chg = prior_values.map((value, idx) => {
                let currval = current_values[idx]
                let prevval = prior_values[idx]
                return ((currval - prevval) / prevval)
            })

            return { 'metric': 'Payback Ratio', 'currvalues': current_values, 'priorvalues': prior_values, 'priorvalchg': priorvalues_chg, 'metrictype': 'units' }
        }


    })

    this.setState({ dataforviews_romcompare: dataforviews })
}