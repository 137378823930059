import * as Constant from '../../../constants'

export default async function getTableInfo(filters) {
    var startTime = new Date()

    let url_for_pull = Constant.baseapiurl + "ReportData/Filtered/TableInfo/" + this.state.reportsettings.report_id + this.state.extraparam
    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_for_pull, 'method': 'POST', 'body': filters }
    ], 'GetTableInfo.js getTableInfo()', 'Table Info')
    let aggregated_data = my_results['ReturnList'][0]

    var endTime = new Date()
    var numsecs = (endTime - startTime) / 1000
    console.log('It Took ' + numsecs + ' seconds to pull TABLE data')
    startTime = new Date()
    let all_outlets = aggregated_data['all_outlets'] || []
    let all_geos = aggregated_data['all_geos'] || []
    let geos = aggregated_data['all_geos'] || []
    let aspmin = aggregated_data['aspmin'] || 0
    let aspmax = aggregated_data['aspmax'] || 0
    let outlets = all_outlets

    if (outlets.length > 1 && outlets.includes("ROM")) {
        outlets = outlets.filter(element => element !== "ROM")
        outlets.sort()
        outlets.push("ROM")
    }

    if (all_outlets.length > 1 && all_outlets.includes("ROM")) {
        all_outlets = outlets.filter(element => element !== "ROM")
        all_outlets.sort()
        all_outlets.push("ROM")
    }

    console.log('Aggregated Data State Update')
    this.setState({
        outlets,
        geos,
        all_outlets,
        all_geos,
        aspmin,
        aspmax,        
    })

    endTime = new Date()
    numsecs = (endTime - startTime) / 1000
    console.log('It Took ' + numsecs + ' seconds to set the TABLE data')

    // Return a completion...
    return 'Completed'
}
