export default function findPercentile(p, n, obj) {
    let index = Math.round(p * n)
    let runningSum = 0
    let pricepoint = 0

    let prices = Object.keys(obj).sort(function (a, b) { return a - b })
    prices.forEach(price => {
        if (runningSum >= index) {
        }
        else {
            runningSum += obj[price]
            pricepoint = price
        }
    })

    return parseFloat(pricepoint)
}