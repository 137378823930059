import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Select from 'react-select'
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import '../styles/custom.css'
import * as Constant from '../constants'

export class CompanyDialogs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                {this.props.state.isLoaded === true &&
                    <div>
                        {/* Company Edit Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showcompanyinfo}
                            onClose={this.props.handleCloseCompanyInfo}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Company Info</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseCompanyInfo}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                Company Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeCompanyName}
                                        id="companyinfoName"
                                        value={this.props.state.companyinfoName}
                                        fullWidth
                                    />
                                </div>
                                Retailer?
                                <div>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.companyinfoRetailer}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleChangeCompanyCheckboxRetailer}
                                        />
                                    </ThemeProvider>
                                </div>
                                Manufacturer?
                                <div>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.companyinfoManufacturer}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleChangeCompanyCheckboxManufacturer}
                                        />
                                    </ThemeProvider>
                                </div>
                                Company ID
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.companyinfodata.company_id}
                                        fullWidth
                                    />
                                </div>
                                Last Updated
                                <div>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        value={this.props.state.companyinfodata.time_updated}
                                        fullWidth
                                    />
                                </div>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.props.handleDeleteCompany}
                                                disabled={!this.props.state.company_delete_button_enabled}
                                            >
                                                Delete Company
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitCompany}
                                                disabled={!this.props.state.company_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* New Company (Create) Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showcompanyinfo_new}
                            onClose={this.props.handleCloseCompanyInfoNew}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>New Company Info</h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseCompanyInfoNew}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                Company Name
                                <div>
                                    <TextField
                                        variant="outlined"
                                        onChange={this.props.handleChangeCompanyName}
                                        id="companyinfoName"
                                        value={this.props.state.companyinfoName}
                                        fullWidth
                                    />
                                </div>
                                Retailer?
                                <div>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.companyinfoRetailer}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleChangeCompanyCheckboxRetailer}
                                        />
                                    </ThemeProvider>
                                </div>
                                Manufacturer?
                                <div>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.companyinfoManufacturer}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleChangeCompanyCheckboxManufacturer}
                                        />
                                    </ThemeProvider>
                                </div>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                    </div>
                                    <div className='col-sm-4'>

                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitCompanyNew}
                                                disabled={!this.props.state.company_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* Company Permissions Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.showcompanyperms}
                            onClose={this.props.handleCloseCompanyPerms}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className="row">
                                    <div style={{ textAlign: 'left', width: '50%' }}>
                                        <h1>Company Permissions </h1>
                                    </div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleCloseCompanyPerms}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <h4>Price and Promotion Permissions</h4>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_pnp_permissions">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Permission ID</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Permission Dataset</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Geographies</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Time Updated</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>More Dataset Information</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Remove Permission</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                            {
                                                this.props.state.companyinfoPermsPNP.slice(this.props.state.page_start_company_pnp_dataset_perms,
                                                                                           this.props.state.page_start_company_pnp_dataset_perms + this.props.state.rowsPerPage_company_pnp_dataset_perms).map((permission, idx) => {
                                                var index = idx
                                                return <Constant.NPDTableRow key={"datasetrow_" + permission.permission_id + "_pnp"}>
                                                    {permission.permission_id > 0 ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.permission_id}</Constant.NPDTableCell>
                                                        :
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}></Constant.NPDTableCell>
                                                    }
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={(this.props.state.pnp_datasets || []).map(dataset => {
                                                                return { 'label': dataset.shortdesc, 'value': dataset.dataset_id }
                                                            })}
                                                            placeholder={'Select A Dataset'}
                                                            onChange={(selectedOptions) => this.props.handleChangeCompanyPerms(selectedOptions, 'pnp', index, 'dataset')}
                                                            value={[{ 'label': permission.shortdesc, 'value': permission.dataset_id }]}
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={Object.keys(((this.props.state.datasetgeomapping_pnp || {})[permission.dataset_id] || {})).map(geo => {
                                                                return { 'label': this.props.state.datasetgeomapping_pnp[permission.dataset_id][geo], 'value': parseInt(geo) }
                                                            })}
                                                            placeholder={'Select Geos'}
                                                            onChange={(selectedOptions) => this.props.handleChangeCompanyPerms(selectedOptions, 'pnp', index, 'geos')}
                                                            value={permission.geogids.map(geo => { return { 'label': ((this.props.state.datasetgeomapping_pnp || {})[permission.dataset_id] || {})[geo], 'value': parseInt(geo) } })}
                                                            isMulti
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.time_updated}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.openPnpDataSetInfo(permission.dataset_id)}>
                                                            <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.removeCompanyPermission(permission.permission_id, 'pnp')}>
                                                            <RemoveCircleRoundedIcon style={{ color: 'red', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })}
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={this.props.state.companyinfoPermsPNP.length}
                                        rowsPerPage={this.props.state.rowsPerPage_company_pnp_dataset_perms}
                                        page={this.props.state.page_company_pnp_dataset_perms}
                                        onChangePage={(event, newPage) => this.props.handleChangePageCompany(event, newPage, 'pnpdatasetperms')}
                                        onChangeRowsPerPage={(event) => this.props.handleChangeRowsPerPageCompany(event, 'pnpdatasetperms')}
                                    />
                                </div>
                                <div className="row">
                                    <ThemeProvider theme={this.props.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.props.addNewCompanyPermission('pnp', this.props.state.companyinfodata.company_id)}
                                        >
                                            Add a New <br />PNP Permission
                                        </Button>
                                        <span style={{ width: '100px' }} />
                                    </ThemeProvider>
                                </div>
                                <div className="row">
                                    <h4>Price and Discount Trends Permissions</h4>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_pdt_permissions">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Permission ID</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Permission Dataset</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Store Groups</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Time Updated</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>More Dataset Information</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Remove Permission</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                            {
                                                this.props.state.companyinfoPermsPDT.slice(this.props.state.page_start_company_pdt_dataset_perms,
                                                                                           this.props.state.page_start_company_pdt_dataset_perms + this.props.state.rowsPerPage_company_pdt_dataset_perms).map((permission, idx) => {
                                                var index = idx
                                                return <Constant.NPDTableRow key={"datasetrow_" + permission.permission_id + "_pdt"}>
                                                    {permission.permission_id > 0 ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.permission_id}</Constant.NPDTableCell>
                                                        :
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}></Constant.NPDTableCell>
                                                    }
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={(this.props.state.pdt_datasets || []).map(dataset => {
                                                                return { 'label': dataset.short_desc, 'value': dataset.dataset_id }
                                                            })}
                                                            placeholder={'Select A Dataset'}
                                                            onChange={(selectedOptions) => this.props.handleChangeCompanyPerms(selectedOptions, 'pdt', index, 'dataset')}
                                                            value={[{ 'label': permission.short_desc, 'value': permission.dataset_id }]}
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <Select
                                                            options={Object.keys(((this.props.state.datasetgeomapping_pdt || {})[permission.dataset_id] || {})).map(storegroup => {
                                                                return { 'label': this.props.state.datasetgeomapping_pdt[permission.dataset_id][storegroup], 'value': parseInt(storegroup) }
                                                            })}
                                                            placeholder={'Select Store Groups'}
                                                            onChange={(selectedOptions) => this.props.handleChangeCompanyPerms(selectedOptions, 'pdt', index, 'geos')}
                                                            value={permission.store_groups.map(storegroup => { return { 'label': ((this.props.state.datasetgeomapping_pdt || {})[permission.dataset_id] || {})[storegroup], 'value': parseInt(storegroup) } })}
                                                            isMulti
                                                        />
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{permission.time_updated}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.openPdtDataSetInfo(permission.dataset_id)}>
                                                            <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <IconButton onClick={() => this.props.removeCompanyPermission(permission.permission_id, 'pdt')}>
                                                            <RemoveCircleRoundedIcon style={{ color: 'red', fontSize: '1.5vw' }} />
                                                        </IconButton>
                                                    </Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })}
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={this.props.state.companyinfoPermsPDT.length}
                                        rowsPerPage={this.props.state.rowsPerPage_company_pdt_dataset_perms}
                                        page={this.props.state.page_company_pdt_dataset_perms}
                                        onChangePage={(event, newPage) => this.props.handleChangePageCompany(event, newPage, 'pdtdatasetperms')}
                                        onChangeRowsPerPage={(event) => this.props.handleChangeRowsPerPageCompany(event, 'pdtdatasetperms')}
                                    />
                                </div>
                                <div className="row">
                                    <ThemeProvider theme={this.props.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.props.addNewCompanyPermission('pdt', this.props.state.companyinfodata.company_id)}
                                        >
                                            Add a New <br />PDT Permission
                                        </Button>
                                        <span style={{ width: '100px' }} />
                                    </ThemeProvider>
                                </div>
                                <div className="row">
                                    <div className="col-sm-9"></div>
                                    <div className="col-sm-3">
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitCompanyChangesPerms}
                                                disabled={!this.props.state.company_edit_perm_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                }
            </Fragment>
        )
    }
}
