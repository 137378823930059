export default function handleChangeDateRangeWeeksBox (event, newValue) {
    var input = document.getElementById("daterangenumweeks");
    let value = input.value
    if (isNaN(value)) {
        alert('You Must Only input Numbers.')
        this.setState({ daterangenumweeks: null, calculatedrange: null })
    }
    else {
        this.setState({ daterangenumweeks: value, calculatedrange: null })
    }

}