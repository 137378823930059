import React, { Component } from 'react';
import { ItemASPDistSelect } from '../../UIElements/Dropdowns/ItemASPDistSelect'
import { ItemASPDistPriceSelect } from '../../UIElements/Dropdowns/ItemASPDistPriceSelect'
import { ItemASPDistDownload } from '../../UIElements/IconButtons/ItemASPDistDownload'
import { ItemASPDistRetailerROMGraph } from '../../../Graphs/ItemASPDistRetailerROMGraph'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';

export class ItemASPDistRetailerROM extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%", padding:'25px' }}>
                <div className="row" >
                    <div align='left' className="col-sm-6" >
                        <ItemASPDistSelect
                            state={this.props.state}
                            setSelectedItem_new={this.props.setSelectedItem_new}
                        />
                    </div>
                    <div align='left' className="col-sm-3" >
                        <ItemASPDistPriceSelect
                            state={this.props.state}
                            setPriceDistOption={this.props.setPriceDistOption}
                        />
                    </div>
                    <div align='right' className="col-sm-3" >
                        <ItemASPDistDownload
                            state={this.props.state}
                            exportItemWeeklyData={this.props.exportItemWeeklyData}
                        />
                    </div>
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", padding: '25px' }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <ItemASPDistRetailerROMGraph
                            state={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}