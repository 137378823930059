import React, { useState , Fragment} from 'react';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'react-select'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
//import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportHelpIcons from "../images/ReportIconsHelpPDT.jpg"
import '../styles/custom.css'
import { isNull } from 'util';
import * as Constant from '../constants'
import { saveAs } from "file-saver";

const ExcelJS = require('exceljs');



const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paperwide: {
        position: 'absolute',
        width: 1000,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const hasstaging = () => {
    var url = window.location.href;
    var temp = url.split("/");
    var i = unescape(temp[5]);
    var hasstagingval
    if (i.indexOf('?env=staging') > 0) {
        hasstagingval = true
    }
    else {
        hasstagingval = false
    }
    return hasstagingval
}

function getModalStyle() {
    const top = 25 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function getModalStyleNew() {
    const top = 30;
    const left = 40;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        padding:'25px'
    };
}

function getModalStyleHelp() {
    const top = 40;
    const left = 40;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}



const ReportToolTips = props => {    

    //console.log(props)

    const classes = useStyles(); 
    const [modalStyle] = React.useState(getModalStyle);
    const [modalStyleHelp] = React.useState(getModalStyleHelp);
    const [modalStyleNew] = React.useState(getModalStyleNew)
    const [open, setOpen] = React.useState(false);
    const [openHelp, setOpenHelp] = React.useState(false);
    const [openDownload, setOpenDownload] = React.useState(false); 
    const [errordisplay, seterrordisplay] = React.useState('none')
    const [availableusers, setAvailableUsers] = useState([])
    const [selectedusers, setselectedusers] = useState([])
    const [loadershare, setLoaderShare] = useState(false)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialog_deleteError, setOpenDialog_deleteError] = React.useState(false);
    const [openDialogShare, setOpenDialogShare] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [brand, setbrand] = React.useState(false);
    const [proddesc, setproddesc] = React.useState(false);
    const [geogdesc, setgeogdesc] = React.useState(false);
    const [attributes, setattributes] = React.useState(false);
    const [segment1name, setsegment1name] = React.useState(false);
    const [segment2name, setsegment2name] = React.useState(false);
    const [segment3name, setsegment3name] = React.useState(false);
    const [segment4name, setsegment4name] = React.useState(false);
    
    const muiTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#4e107f',
            },
            secondary: {
                main: '#ef2a79',
            },
            white: {
                main: '#FFF'
            }
        }
    });

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleCloseModalHelp = () => {
        setOpenHelp(false);
    };

    const handleCloseModalDownload = () => {
        setOpenDownload(false);
    };

    const handleOpenModalDownload = () => {
        setOpenDownload(true);
    };

    const handleToggle_download = (column) => {
        if (column === 'brand') {
            setbrand(!brand)
        }
        if (column === 'proddesc') {
            setproddesc(!proddesc)
        }
        if (column === 'geogdesc') {
            setgeogdesc(!geogdesc)
        }
        if (column === 'attributes') {
            setattributes(!attributes)
        }
        if (column === 'segment1_name') {
            setsegment1name(!segment1name)
        }
        if (column === 'segment2_name') {
            setsegment2name(!segment2name)
        }
        if (column === 'segment3_name') {
            setsegment3name(!segment3name)
        }
        if (column === 'segment4_name') {
            setsegment4name(!segment4name)
        }
    }

    const shareReport = () => {

        setLoaderShare(true)

        ////console.log(availableusers);
        ////console.log(selectedusers);

        //Make response data equal to an array of the values of the Selected Options
        let userids = selectedusers.map(user => user.value)
        ////console.log(userids)
        fetch(Constant.baseapiurl + "ReportSettings/" + findid() +"/Sharing",
            {
                method: "PUT",
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userids)
            })
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    ////console.log('ok')
                    res.text()
                    .then(
                    () => {
                        //console.log('Report Shared!');
                        handleOpenDialogShare()
                        //alert('Report Successfully Shared')
                        setOpen(false)
                        setLoaderShare(false)
                    },
                        (error) => {
                            //console.log(error)
                        }
                )
                }
                else {
                    //console.log('There was an Error Running the API')
                }
            }
        )
        
    }

    const ChangeUsers = (event) => {
        //console.log(event)
        if (isNull(event)) {
            setselectedusers([]);
            seterrordisplay('none');
            //console.log(selectedusers);
        }
        else {
            setselectedusers(event);
            seterrordisplay('none');
            //console.log(selectedusers);
        }
    }

    const findid = () => {
        var url = window.location.href;
        //console.log(url)
        var temp = url.split("/");
        //console.log(temp)
        var i = unescape(temp[5]);
        var idnum = parseInt(i, 10);
        //console.log(idnum)
        return idnum;
    }

    const handleClick_copy =()=> {
        window.location.href = Constant.baseurl + 'PDT/copyreport/' + findid()
    }

    const handleClick_edit = () => {
        window.location.href = Constant.baseurl +'PDT/EditReport/' + findid()
    }

    const handleClick_delete = () => {
        var reportid = findid();
        //console.log(reportid);
        //console.log('Running Delete!')
        fetch(Constant.baseapiurl+'ReportSettings/' + reportid, { method: 'DELETE' })
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.text()
                        .then(
                            () => {
                                window.location.reload(true);
                                window.location.href = Constant.baseurl + 'PDT/Home'
                            },
                            (error) => {
                                //console.log('There Was an Error' + error)
                            }
                        )                    
                }

                else if (res.status === 401 || res.status === 404) {
                    setOpenDialog(false)
                    setOpenDialog_deleteError(true)
                }

                else {
                    //console.log('There was an Error Running the API')
                }
            })

        //Delete from cache
        caches.open('reportDataCache').then(async cache => {
            cache.delete(Constant.baseapiurl + 'ReportData/' + reportid).then(reportdelete => {
                //console.log('Report Cache Deleted')
            })
        })
    }


    const request_users = async () => {
        const response = await fetch(Constant.baseapiurl + "ReportSettings/" + findid() + "/SharableWith")
        const json = await response.json();
        let user_options = []
        json.map(company => company.users.map(user => user_options.push( { 'value': user.user_id, 'label': user.user_name } )))
        //let new_users = json.map(company => company.users.map(user => { return { 'value': user.user_id, 'label': user.user_name } }))
        setAvailableUsers(user_options)

        const response_shared = await fetch(Constant.baseapiurl + "ReportSettings/" + findid() + "/Sharing")
        const json_shared = await response_shared.json();
        let user_options_shared = []
        //Need a map of all users to get emails...
        let user_dict = []
        json.map(company => company.users.map(user => user_dict[user.user_id] = user.user_name))
        json_shared.map(user => user_options_shared.push({ 'value': user.user_id, 'label': user_dict[user.user_id] }))
        //let new_users = json.map(company => company.users.map(user => { return { 'value': user.user_id, 'label': user.user_name } }))
        setselectedusers(user_options_shared)
        
    }
    

    const handleClick_sharerpt = () => {
        setOpen(true);
        request_users();       
    }

    const handleClick_help = () => {
        setOpenHelp(true)
    }
    /*
    const handleClick_generatePDF = () => {
        var reportid = findid();
        //console.log(reportid);
        window.location.href = Constant.baseurl + 'PDT/Report/' + reportid +'/#' //'PDT/GeneratePDF/report/' + reportid
    }
    */
    const handleOpenDialog = () => {
        //console.log('Click!')
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCloseDialog_deleteError = () => {
        setOpenDialog_deleteError(false)
    }


    const handleOpenDialogShare = () => {        
        setOpenDialogShare(true)
    }

    const handleCloseDialogShare = () => {
        setOpenDialogShare(false)
    }    

    const runDownload = () => {
        let includeattributes = false
        let columnstouse = []
        if (brand) {
            columnstouse.push('brand')
        }
        if (proddesc) {
            columnstouse.push('proddesc')
        }
        //Always push outlet
        columnstouse.push('outlet')
        if (geogdesc) {
            columnstouse.push('geogdesc')
        }
        if (attributes) {
            includeattributes = true
        }
        if (segment1name) {
            columnstouse.push('segment1_name')
        }
        if (segment2name) {
            columnstouse.push('segment2_name')
        }
        if (segment3name) {
            columnstouse.push('segment3_name')
        }
        if (segment4name) {
            columnstouse.push('segment4_name')
        }
        //console.log(columnstouse)
        alertDownload2(columnstouse, includeattributes)
    }

    const alertDownload = async (download_cols, includeattributes) => {

        let hasstagingval = hasstaging()
        var extraparam = ""
        if (hasstagingval === true) {
            extraparam = '?env=staging'
        }

        function removeItemOnce(arr, value) {
            let arrcopy = arr
            var index = arrcopy.indexOf(value);
            if (index > -1) {
                arrcopy.splice(index, 1);
            }
            return arrcopy;
        }
        function replaceEmptyArrays(arr) {

            if (arr.length === 0) {
                arr.push('All')
            }
            return arr;
        }
        handleCloseModalDownload()
        //alert('Download is Processing.  File will appear in lower bar when available.')
        setShowSnackbar(true)
        //build filters
        let filters_raw = props.filters
        let exclude_array = props.exclude_array
        let priceBandOptions = props.priceBandOptions
        let download_includeattributes = includeattributes
        let download_includedoors = props.download_includedoors
        let attributenamelabels = props.attributenamelabels
        let segment1label = props.segment1label
        let segment2label = props.segment2label
        let segment3label = props.segment3label
        let segment4label = props.segment4label
        let currencysymbol = props.currencysymbol
        let unitsvar = props.unitsvar
        let currencyType = props.currencyType
        let chartmetadata = props.chartmetadata
        

        var reportidnum = findid();
        let filters = {}

       
        //fetch dataset id from report settings
        var settings = await fetch(Constant.baseapiurl + "ReportSettings/" + reportidnum)
        var settingsres = settings.status
        if (settingsres === 200) {
            console.log('Getting Settings')
            //console.log(extractdetailres)
            var settingsdetail = await settings.json()
            console.log(settingsdetail)
            var dataset_id = settingsdetail.dataset_id
            var storegrpid = settingsdetail.store_group
            console.log(dataset_id, storegrpid)
        }

       
        
        
        //Rework values so if just an array of All make empty
        //Rework Attributes
        let apifilterattrs = Object.keys(filters_raw['attributes']).map(attr => {
            return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All')]
        })

        filters['brands'] = removeItemOnce(filters_raw['brands'], 'All')
        filters['exclude_brands'] = exclude_array[0]
        filters['items'] = removeItemOnce(filters_raw['items'], 'All')
        filters['exclude_items'] = exclude_array[1]
        filters['week_aggregation'] = filters_raw['numweeks']
        filters['asp_range'] = filters_raw['asp_range']
        filters['date_range'] = [filters_raw.startdate, filters_raw.enddate]
        filters['date_range_curr'] = [filters_raw.startdate, filters_raw.enddate]
        filters['date_range_prior'] = [filters_raw.startdate, filters_raw.enddate]
        filters['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
        filters['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
        filters['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
        filters['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
        filters['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
        filters['attributes'] = apifilterattrs
        filters['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
        filters['price_bands_defs'] = priceBandOptions
        filters['channels'] = removeItemOnce(filters_raw['channels'], 'All')
        filters['storegpid'] = storegrpid



        filters['download_columns'] = download_cols
        filters['download_includeattributes'] = download_includeattributes
        filters['download_includedoors'] = download_includedoors


       


        //fetch data
        let url_download = Constant.baseapiurl + "DatasetData/Filtered/DownloadData/" + dataset_id + extraparam
        //let url_download = Constant.baseapiurl + "ReportData/Filtered/DownloadData/" + reportidnum + extraparam
        //console.log('Starting Downloaded')
        var t0 = performance.now()
        let results = await fetch(url_download,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filters)
            }
        )

        
        var download_data = {}
        if (results.status === 200) {
            download_data = await results.json()
            //Clean Up Filters

            let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
                return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
            })

            filters['brands'] = replaceEmptyArrays(filters_raw['brands'])
            filters['exclude_brands'] = exclude_array[0]
            filters['items'] = replaceEmptyArrays(filters_raw['items'])
            filters['exclude_items'] = exclude_array[1]
            filters['week_aggregation'] = filters_raw['numweeks']
            filters['asp_range'] = filters_raw['asp_range']
            filters['date_range'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_curr'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_prior'] = [filters_raw.startdate, filters_raw.enddate]
            filters['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
            filters['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
            filters['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
            filters['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
            filters['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
            filters['attributes'] = apifilterattrs_replaced
            //filters['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
            filters['price_bands_defs'] = priceBandOptions
            filters['channels'] = replaceEmptyArrays(filters_raw['channels'])
            filters['storegpid'] = storegrpid
        }
        else {
            download_data = { 'download_data': [] }
            alert('There was an Error Downloading the Data')
            //Clean Up Filters
            let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
                return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
            })

            filters['brands'] = replaceEmptyArrays(filters_raw['brands'])
            filters['exclude_brands'] = exclude_array[0]
            filters['items'] = replaceEmptyArrays(filters_raw['items'])
            filters['exclude_items'] = exclude_array[1]
            filters['week_aggregation'] = filters_raw['numweeks']
            filters['asp_range'] = filters_raw['asp_range']
            filters['date_range'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_curr'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_prior'] = [filters_raw.startdate, filters_raw.enddate]
            filters['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
            filters['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
            filters['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
            filters['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
            filters['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
            filters['attributes'] = apifilterattrs_replaced
            //filters['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
            filters['price_bands_defs'] = priceBandOptions
            filters['channels'] = replaceEmptyArrays(filters_raw['channels'])
            filters['storegpid'] = storegrpid

            //End the Function
            return
        }
        //console.log('Data Downloaded')
        var t1 = performance.now()
        //console.log("Data Download Took " + (t1 - t0) + " milliseconds.")
        //console.log(download_data)

        //Format Headers

        let column_mapping = {
            //columns
            'brand': 'Brand',
            'geogdesc': 'Channel',
            'proddesc': 'Item Name',
            'segment1_name': segment1label,
            'segment2_name': segment2label,
            'segment3_name': segment3label,
            'segment4_name': segment4label,
            'rowdate': 'Week Ending',
            'outlet': 'Outlet',
            //metrics
            'basedollars': 'Base ' + currencyType,
            'baseunits': 'Base ' + unitsvar,
            'carrieddoors': 'Carried Doors',
            'discountdollars': 'Discount ' + currencyType,
            'incrementaldollars': 'Incremental ' + currencyType,
            'incunits': 'Incremental ' + unitsvar,
            'nonpromodollars': 'Non-Promoted ' + currencyType,
            'nonpromounits': 'Non-Promoted ' + unitsvar,
            'promodollars': 'Promoted ' + currencyType,
            'promounits': 'Promoted ' + unitsvar,
            'subbasedollars': 'Subsidized Base ' + currencyType,
            'subbasevol': 'Subsidized Base ' + unitsvar,
            'totaldollars': 'Total ' + currencyType,
            'totaldoors_s1': 'Total Doors Segment 1',
            'totaldoors_s1_segment': 'Total Doors Segment 1 Segment 1',
            'totaldoors_s2': 'Total Doors Segment 2',
            'totaldoors_s2_segment': 'Total Doors Segment 2 Segment 2',
            'totalunits': 'Total ' + unitsvar,
            'totalunits_withoutno': unitsvar + ' for Promoted Only Metric Calculations',
            'unsubbasedollars': 'Unsubsidized Base ' + currencyType,
            'unsubbasevol': 'Unsubsidized Base ' + unitsvar,
            'weightedasp': 'Weighted Average Selling Price',
            'weightedbp': 'Weighted Average Base Price',
            'weightedbp_withoutno': 'Weighted Average Base Price (Promoted Only)',
            'weightedpctdisc': 'Weighted Average Percent Discount',
            'weightedpctdisc_withoutno': 'Weighted Average Percent Discount (Promoted Only)',
        }

        //Add Names for Attributes
        Object.keys(attributenamelabels).forEach(attr => column_mapping[attr] = attributenamelabels[attr])
        //console.log(column_mapping)

        //Add Header
        //Using order from the dataset
        //If we want, we can specify a column list here and add the appropriate columns as needed
        let proper_order_cols = download_cols
        let proper_order_attributes = Object.keys(attributenamelabels)
        let proper_order_metrics = [
            //Not a Metric But Row Date Needs to Come After All of the Other Places
            'rowdate' 
            //Units
            ,'totalunits'
            ,'baseunits'
            ,'subbasevol'
            ,'unsubbasevol'
            ,'incunits'
            ,'nonpromounits'
            ,'promounits'
            //Dollars
            ,'totaldollars'
            ,'basedollars'
            ,'subbasedollars'
            ,'unsubbasedollars'
            ,'incrementaldollars'
            ,'nonpromodollars'            
            ,'promodollars'
            ,'discountdollars'
            //Weighted                
            ,'totalunits_withoutno'
            ,'weightedasp'
            ,'weightedbp'
            ,'weightedbp_withoutno'
            ,'weightedpctdisc'
            ,'weightedpctdisc_withoutno'
        ]
        //console.log(download_includedoors)
        if (download_includedoors === true) {
            //console.log('Adding Metrics for Doors')
            proper_order_metrics = proper_order_metrics.concat([
                'carrieddoors'
                ,'totaldoors_s1'
                ,'totaldoors_s1_segment'
                ,'totaldoors_s2'
                ,'totaldoors_s2_segment'
            ])
        }

        let numcolsunformatted = proper_order_cols.length + proper_order_attributes.length + 1 + 1
        let proper_order = proper_order_cols.concat(proper_order_attributes).concat(proper_order_metrics)
        //console.log(proper_order)

        let header = proper_order //Object.keys(download_data['download_data'][0] || {}) 
        let header_formatted = header.map(col => column_mapping[col])

        //console.log(header_formatted)

        //Add Each row to the dataset
        var name = 'Download Data Report ' + reportidnum
        var newdate = new Date();
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
        datestring = datestring.replaceAll(',', '')
    }


    const alertDownload2 = async (download_cols, includeattributes) => {
        let hasstagingval = hasstaging()
        var extraparam = ""
        if (hasstagingval === true) {
            extraparam = '?env=staging'
        }

        function removeItemOnce(arr, value) {
            let arrcopy = arr
            var index = arrcopy.indexOf(value);
            if (index > -1) {
                arrcopy.splice(index, 1);
            }
            return arrcopy;
        }
        function replaceEmptyArrays(arr) {

            if (arr.length === 0) {
                arr.push('All')
            }
            return arr;
        }
        handleCloseModalDownload()
        //alert('Download is Processing.  File will appear in lower bar when available.')
        setShowSnackbar(true)
        //build filters
        let filters_raw = props.filters
        let exclude_array = props.exclude_array
        let priceBandOptions = props.priceBandOptions
        let download_includeattributes = includeattributes
        let download_includedoors = props.download_includedoors
       

        var reportidnum = findid();
        let filters = {}

        var name = 'Download Data Report ' + reportidnum
        var newdate = new Date();
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
        datestring = datestring.replaceAll(',', '')        
        let filenameexcel = name + ' - Extracted - ' + datestring + '.xlsx'

        //fetch dataset id from report settings
        var settings = await fetch(Constant.baseapiurl + "ReportSettings/" + reportidnum)
        var settingsres = settings.status
        if (settingsres === 200) {
            console.log('Getting Settings')
            //console.log(extractdetailres)
            var settingsdetail = await settings.json()
            console.log(settingsdetail)
            var dataset_id = settingsdetail.dataset_id
            var storegrpid = settingsdetail.store_group
            console.log(dataset_id, storegrpid)
        }


        //Rework values so if just an array of All make empty
        //Rework Attributes
        let apifilterattrs = Object.keys(filters_raw['attributes']).map(attr => {
            return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All')]
        })

        filters['brands'] = removeItemOnce(filters_raw['brands'], 'All')
        filters['exclude_brands'] = exclude_array[0]
        filters['items'] = removeItemOnce(filters_raw['items'], 'All')
        filters['exclude_items'] = exclude_array[1]
        filters['week_aggregation'] = filters_raw['numweeks']
        filters['asp_range'] = filters_raw['asp_range']
        filters['date_range'] = [filters_raw.startdate, filters_raw.enddate]
        filters['date_range_curr'] = [filters_raw.startdate, filters_raw.enddate]
        filters['date_range_prior'] = [filters_raw.startdate, filters_raw.enddate]
        filters['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
        filters['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
        filters['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
        filters['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
        filters['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
        filters['attributes'] = apifilterattrs
        filters['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
        filters['price_bands_defs'] = priceBandOptions
        filters['channels'] = removeItemOnce(filters_raw['channels'], 'All')
        filters['storegpid'] = storegrpid


        filters['download_columns'] = download_cols
        filters['download_includeattributes'] = download_includeattributes
        filters['download_includedoors'] = download_includedoors

        //fetch data
        let url_download = Constant.baseapiurl + "DatasetData/Filtered/DownloadData/" + dataset_id + extraparam
        //let url_download = Constant.baseapiurl + "ReportData/Filtered/DownloadData/" + reportidnum + extraparam
       
        //console.log('Starting Downloaded')
        var t0 = performance.now()
        let results = await fetch(url_download,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filters),
                responseType: 'blob',
            }
        )
        var download_data = {}
        if (results.status === 200) {

            let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
                return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
            })

            filters['brands'] = replaceEmptyArrays(filters_raw['brands'])
            filters['exclude_brands'] = exclude_array[0]
            filters['items'] = replaceEmptyArrays(filters_raw['items'])
            filters['exclude_items'] = exclude_array[1]
            filters['week_aggregation'] = filters_raw['numweeks']
            filters['asp_range'] = filters_raw['asp_range']
            filters['date_range'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_curr'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_prior'] = [filters_raw.startdate, filters_raw.enddate]
            filters['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
            filters['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
            filters['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
            filters['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
            filters['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
            filters['attributes'] = apifilterattrs_replaced            
            filters['price_bands_defs'] = priceBandOptions
            filters['channels'] = replaceEmptyArrays(filters_raw['channels'])
            filters['storegpid'] = storegrpid
        
            download_data = await results.blob()
            const url = window.URL.createObjectURL(new Blob([download_data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filenameexcel) 
            document.body.appendChild(link);
            link.click();            
        }

        else {
            download_data = { 'download_data': [] }
            alert('There was an Error Downloading the Data')
            //Clean Up Filters
            let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
                return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
            })

            filters['brands'] = replaceEmptyArrays(filters_raw['brands'])
            filters['exclude_brands'] = exclude_array[0]
            filters['items'] = replaceEmptyArrays(filters_raw['items'])
            filters['exclude_items'] = exclude_array[1]
            filters['week_aggregation'] = filters_raw['numweeks']
            filters['asp_range'] = filters_raw['asp_range']
            filters['date_range'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_curr'] = [filters_raw.startdate, filters_raw.enddate]
            filters['date_range_prior'] = [filters_raw.startdate, filters_raw.enddate]
            filters['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
            filters['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
            filters['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
            filters['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
            filters['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
            filters['attributes'] = apifilterattrs_replaced
            //filters['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
            filters['price_bands_defs'] = priceBandOptions
            filters['channels'] = replaceEmptyArrays(filters_raw['channels'])
            filters['storegpid'] = storegrpid

            //End the Function
            return
        }
        
    }

    const closeSnackBar = () => {
        setShowSnackbar(false)
    }

    //console.log(Constant.whereamilist[5])
    
    var reportidnum = findid();

    return (
        <div className="" style={{ alignContent: 'right', paddingRight: '40px', marginRight: '0px' ,marginTop: '0px', right: '0vw', zIndex:'2' }}> {/*left:'72.5vw'*/}

            <Constant.NPDSnackbar
                open={showSnackbar}
                autoHideDuration={4000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={closeSnackBar}
            >
                <Constant.NPDSnackbarContent
                    message="Data is Downloading"
                    action={
                        <Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackBar}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Fragment>
                    }
                >
                </Constant.NPDSnackbarContent>
            </Constant.NPDSnackbar>


            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete this Report?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete this report. This action is final and can not be undone.  Continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialog} className="btn btn-secondarynpd">
                        Cancel
                    </button>
                    <button onClick={handleClick_delete} className="btn btn-primary" autoFocus>
                        Delete Report
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialog_deleteError}
                onClose={handleCloseDialog_deleteError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Insufficient Privileges"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have insufficient privileges to delete this report
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialog_deleteError} className="btn btn-primary" autoFocus>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogShare}
                onClose={handleCloseDialogShare}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Report Shared"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Report has been Successfully Shared.
                    </DialogContentText>
                </DialogContent>   
                <DialogActions>
                    <button onClick={handleCloseDialogShare} className="btn btn-secondarynpd">
                        Ok
                    </button>
                </DialogActions>
            </Dialog>

            <Tooltip title="What do these buttons do?  Click to discover more.">
                <div className="dotsmall_hideborders">
                    <IconButton aria-label="copy" onClick={handleClick_help}  >
                        <HelpOutlineRoundedIcon style={{ color: '#4e107f' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Copy Report">
                <div className="dotsmall">
                    <IconButton aria-label="copy" onClick={handleClick_copy}  >
                        <FileCopyRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Edit Report">
                <div className="dotsmall">
                    <IconButton aria-label="edit" onClick={handleClick_edit}>
                        <EditRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>            


            <Tooltip title="Share Report">
                <div className="dotsmall">
                    <IconButton aria-label="share" onClick={handleClick_sharerpt}>
                        <ShareRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Download Data" >
                <div className="dotsmall">                    
                    <IconButton aria-label="download" onClick={handleOpenModalDownload}>
                        <GetAppRoundedIcon style={{ color: '#8E908F' }}  />                            
                    </IconButton>                    
                </div>
            </Tooltip>
            {/*
            <Tooltip title="Generate PDF" >
                <div className="dotsmall">
                    <IconButton aria-label="pdf" onClick={handleClick_generatePDF}>
                        <PictureAsPdfRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>
            */}
            <Tooltip title="Delete Report">
                <div className="dotsmall">
                    <IconButton aria-label="delete" onClick={handleOpenDialog}>
                        <DeleteRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleCloseModal}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Share This Report</h2>
                    <p id="simple-modal-description">
                        Select who you would like to share this report with:
                    </p>
                    <div >
                        <Select                        
                            options={availableusers}
                            value={selectedusers}
                            placeholder={'Select Users'}
                            isMulti={true}
                            onChange={ChangeUsers}
                        >
                        </Select>
                        <ErrorRoundedIcon style={{ color: '#822433', display: errordisplay }} /><span style={{ color: '#822433', display: errordisplay  }}>Please Provide at Least one User</span>
                    </div>
                    <button
                        onClick={shareReport}
                        className="btn btn-primary"
                        style={{ margin: '10px' }}
                    >
                       Share This Report
                    </button>
                    {loadershare ? <div class="custom-loader"></div> /*<CircularProgress color="inherit" />*/ : <span />}
                </div>
            </Modal>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openHelp}
                onClose={handleCloseModalHelp}
            >
                <div style={modalStyleHelp} className={classes.paperwide}>
                    <h2 id="simple-modal-title" align="center">Report Action Buttons</h2>
                    <img src={ReportHelpIcons} alt="Help Icons"/>
                    <div ></div>
                </div>
            </Modal>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openDownload}
                onClose={handleCloseModalDownload}
            >
                <div style={modalStyleNew} className={classes.paperwide}>
                    <h2 id="simple-modal-title" align="center">
                        Choose What You Want Included In the Download
                    </h2>
                    <div className='col-sm-12' align='center'>
                        <p> Outlet and Date will always come with the download </p>
                    </div>
                    <div className='col-sm-12 row'>
                        <div className='col-sm-6 row' style={{ padding: '25px' }}>
                            <ThemeProvider theme={muiTheme}>                                
                                <Checkbox
                                    checked={brand}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={(column) => handleToggle_download('brand')}
                                /> 
                                <span style={{ lineHeight: '5', fontSize:'1.5rem' }} >Brand</span>
                            </ThemeProvider>
                        </div>
                        <div className='col-sm-6 row' style={{ padding: '25px' }}>
                            <ThemeProvider theme={muiTheme}>
                                <Checkbox
                                    checked={proddesc}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={(column) => handleToggle_download('proddesc')}
                                />
                                <span style={{ lineHeight: '5', fontSize: '1.5rem' }} >Item</span>
                            </ThemeProvider>
                        </div>
                        <div className='col-sm-6 row' style={{ padding: '25px' }}>
                            <ThemeProvider theme={muiTheme}>
                                <Checkbox
                                    checked={geogdesc}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={(column) => handleToggle_download('geogdesc')}
                                />
                                <span style={{ lineHeight: '5', fontSize: '1.5rem' }} >Channel</span>
                            </ThemeProvider>
                        </div>
                        <div className='col-sm-6 row' style={{ padding: '25px' }}>
                            <ThemeProvider theme={muiTheme}>
                                <Checkbox
                                    checked={attributes}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={(column) => handleToggle_download('attributes')}
                                />
                                <span style={{ lineHeight: '5', fontSize: '1.5rem' }} >Attributes</span>
                            </ThemeProvider>
                        </div>
                        {props.segment1label ?
                            <div className='col-sm-6 row' style={{ padding: '25px' }}>
                                <ThemeProvider theme={muiTheme}>
                                    <Checkbox
                                        checked={segment1name}
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(column) => handleToggle_download('segment1_name')}
                                    />
                                    <span style={{ lineHeight: '5', fontSize: '1.5rem' }} >{props.segment1label}</span>
                                </ThemeProvider>
                            </div>
                            :
                            <div />
                        }
                        {props.segment2label ?
                            <div className='col-sm-6 row' style={{ padding: '25px' }}>
                                <ThemeProvider theme={muiTheme}>
                                    <Checkbox
                                        checked={segment2name}
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(column) => handleToggle_download('segment2_name')}
                                    />
                                    <span style={{ lineHeight: '5', fontSize: '1.5rem' }} >{props.segment2label}</span>
                                </ThemeProvider>
                            </div>
                            :
                            <div />
                        }
                        {props.segment3label ?
                            <div className='col-sm-6 row' style={{ padding: '25px' }}>
                                <ThemeProvider theme={muiTheme}>
                                    <Checkbox
                                        checked={segment3name}
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(column) => handleToggle_download('segment3_name')}
                                    />
                                    <span style={{ lineHeight: '5', fontSize: '1.5rem' }} >{props.segment3label}</span>
                                </ThemeProvider>
                            </div>
                            :
                            <div />
                        }
                        {props.segment4label ?
                            <div className='col-sm-6 row' style={{ padding: '25px' }}>
                                <ThemeProvider theme={muiTheme}>
                                    <Checkbox
                                        checked={segment4name}
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(column) => handleToggle_download('segment4_name')}
                                    />
                                    <span style={{ lineHeight: '5', fontSize: '1.5rem' }} >{props.segment4label}</span>
                                </ThemeProvider>
                            </div>
                            :
                            <div />
                        }
                        <div className='col-sm-12 row'>
                            <div className='col-sm-6'>
                                <button
                                    onClick={runDownload}
                                    className="btn btn-primary"
                                    autoFocus
                                    style={{ margin: '25px' }}
                                
                                >
                                    Download Data
                                </button>
                            </div>
                            <div className='col-sm-6'>
                                <button
                                    onClick={handleCloseModalDownload}
                                    className="btn btn-secondarynpd"
                                    autoFocus
                                    style={{ margin: '25px' }}

                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
}

export default ReportToolTips;