import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import selectColourStyles from '../../../styles/SelectColourStyles'

export class ItemASPDistPriceSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle"> Select a Price</span>
                <Select
                    id="commonitems_new"
                    options={this.props.state.itemdistpriceoptions}
                    placeholder={'Select an Item'}
                    onChange={(item) => this.props.setPriceDistOption(item)}
                    value={this.props.state.selected_itemdistpriceoption}
                    //value={{ 'value': this.props.state.selectedItem_new, 'label': this.props.state.selectedItem_new }}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}