import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateWeeklyData(filters, timeperiod) {
    let filters_weekly = assignFilterLevels(filters, ['rowdate'])
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level1selection = Constant.baseapiurl + "ReportData/Filtered/Level1Data/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level1selection, 'method': 'POST', 'body': filters_weekly }
    ], 'updateWeeklyData.js updateWeeklyData()', 'Weekly')
    let weeklydata_data = my_results['ReturnList'][0]
    let weeklydata_obj = weeklydata_data['level1_data'] || {}
    let weeklydata_totalcat_obj = weeklydata_data['level1_data_totalcat'] || {}

    if (timeperiod === 'totaltimeperiod') {
        this.setState({
            weekly_data_new: weeklydata_obj,
            weekly_data_totalsel_new: weeklydata_obj,
            weekly_data_totalcat_new: weeklydata_totalcat_obj
        })        
    } else if (timeperiod === 'threeyearsago') {
        this.setState({
            weekly_data_threeyears_new: weeklydata_obj,
            weekly_data_totalsel_threeyears_new: weeklydata_obj,
            weekly_data_totalcat_threeyears_new: weeklydata_totalcat_obj
        })
    } else if (timeperiod === 'twoyearsago') {
        this.setState({
            weekly_data_last_new: weeklydata_obj,
            weekly_data_totalsel_last_new: weeklydata_obj,
            weekly_data_totalcat_last_new: weeklydata_totalcat_obj
        })
    } else if (timeperiod === 'prioryear') {
        this.setState({
            weekly_data_prior_new: weeklydata_obj,
            weekly_data_totalsel_prior_new: weeklydata_obj,
            weekly_data_totalcat_prior_new: weeklydata_totalcat_obj
        })
    }

    return 'Completed'
}
