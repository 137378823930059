import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'


const LiftEffDiscPct = props => {    

    var appname = props.appname || 'PNP'
    var avgdiscname = 'Average Discount'
    if (appname === 'PDT') {
        avgdiscname = 'Average Promoted Discount'
    }

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            //type: 'column',
                            height: (9 / 16 * (100 * (props.height || 1))) + '%' //16:9 ratio
                        },
                        metadata: props.metadata || {},
                        caption: {
                            text: props.attr_caption_text
                        },
                        title: {
                            text: 'Lift, Efficiency, ' + avgdiscname + ' by ' + props.level + props.chart_title_info
                        },
                        series: [
                            {
                                name: 'Market Lift',
                                data: props.LiftGraphData.map(value => { return value || 0 }),
                                type: 'line',
                                lineWidth: 0,
                                marker: {
                                    enabled: true,
                                    radius: 10
                                },
                                states: {
                                    hover: {
                                        lineWidthPlus: 0
                                    }
                                },
                                zIndex: 1
                            },
                            {
                                name: 'Promoted Lift',
                                data: props.PromoLiftGraphData.map(value => { return value || 0 }),
                                type: 'line',
                                lineWidth: 0,
                                marker: {
                                    enabled: true,
                                    radius: 10
                                },
                                states: {
                                    hover: {
                                        lineWidthPlus: 0
                                    }
                                },
                                zIndex: 1
                            },
                            {
                                name: 'Efficiency',
                                data: props.EffGraphData.map(value => { return value || 0 }),
                                type: 'line',
                                lineWidth: 0,
                                marker: {
                                    enabled: true,
                                    radius: 10
                                },
                                states: {
                                    hover: {
                                        lineWidthPlus: 0
                                    }
                                },
                                zIndex: 1
                            },
                            {
                                name: avgdiscname,
                                type: 'column',
                                tooltip: {
                                    pointFormatter: function () {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                },

                                data: props.DiscGraphData.map(value => { return value || 0 }),
                                zIndex: 0,
                                yAxis:1
                            },
                        ],
                        xAxis: [
                            {
                                title: {
                                    text: ''
                                },
                                categories: props.categories
                            },

                        ],
                        yAxis: [
                            {
                                title: {
                                    text: 'Lift/Efficiency'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                }
                            },
                            {
                                title: {
                                    text: avgdiscname
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                }
                            },

                        ],
                        plotOptions: {
                            column: {
                                //stacking: 'normal',
                                dataLabels: {
                                    enabled: false, //(Object.keys(props.volume_by_brand)).map(brand => brand).length < 5,
                                    format: '{point.y:,.0f}'
                                }
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                return this.x
                                    + '<br/><span style="color:'
                                    + this.series.color + '">'
                                    + this.series.name
                                    + '</span>: <b>'
                                    + (this.y).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            }
                        },
                        exporting: {
                            showTable: props.showDataTable,
                            tableCaption: false
                        },
                    }}
                oneToOne={true}
            />
            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g11">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{props.level}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Lift</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Efficiency</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{avgdiscname}</Constant.NPDTableCell>
                        </Constant.NPDTableRow>
                        {props.categories.map((category, idx) => {
                            return <Constant.NPDTableRow key={"datarow_" + category + "_g11"}>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{category}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(props.LiftGraphData[idx] || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(props.EffGraphData[idx] || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(props.DiscGraphData[idx] || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                            </Constant.NPDTableRow>
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default LiftEffDiscPct;