import React, { Fragment, Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import * as Constant from '../../../constants';
import { DownloadItemData } from '../../UIElements/IconButtons/DownloadItemData';
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';

export class ItemYOYInformation extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        return (
            <Fragment>

                

                <div className="row col-sm-12">
                    <div className="col-sm-12">
                        <h4>Selection Information</h4>
                        {this.props.state.channelviews === true ?
                            <span>{this.props.state.brandsstring}<br /></span>
                            :
                            <span>{this.props.state.selectedBrandLabel}<br /></span>
                        }
                        {
                            this.props.state.channelviews === true ?
                                <span>{this.props.state.geos[0] + ', ' + this.props.state.selectedChannel}<br /></span>
                                :
                                <span>{this.props.state.chartmetadata_singlebrand.geography}<br /></span>
                        }
                        <span>{this.props.state.chartmetadata_singlebrand.segments}<br /></span>
                        <span>{this.props.state.chartmetadata_singlebrand.attributes}<br /></span>
                        <span>{'Number of Weeks in Comparison Period: ' + this.props.state.distinctdates_forcalc.length}<br /></span>
                        <span> Previous Year Ranges From: {this.props.state.prior_start} to {this.props.state.distinctdates_prior[this.props.state.distinctdates_prior.length - 1]}</span>
                        <br />
                        <span> Current &nbsp; Year Ranges From: {this.props.state.distinctdates_forcalc[0]} to {this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1]}</span>
                    </div>
                    <div className="col-sm-12" align='right'>
                        <DownloadItemData
                            download_data_itemmetrics_yoy={this.props.download_data_itemmetrics_yoy}
                        />                           
                    </div>
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow>
                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                Brand
                            </Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                Item Name
                            </Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                Change in Average Selling Price
                                                        </Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                Change in Promoted Price
                                                        </Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                Change in Non-Promoted Price
                                                        </Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                Change in Unit Share
                                                        </Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                Change in Dollar Share
                            </Constant.NPDTableCell>

                        </Constant.NPDTableRow>
                        {Object.keys(this.props.state.all_period_metrics).filter(function (value, index, arr) {
                            let invalidvalues = ['curr_total_dollars_forshare', 'curr_total_units_forshare', 'prior_total_dollars_forshare', 'prior_total_units_forshare', 'Total Category', 'Total Selection']
                            return !invalidvalues.includes(value);
                        }).map((brand, brandidx) => {
                            return <Fragment>
                                {(this.props.state.all_items_for_yoy || []).slice(this.props.state.page * this.props.state.rowsPerPage, this.props.state.page * this.props.state.rowsPerPage + this.props.state.rowsPerPage).map((item, idx) => {

                                    var sharechange_units, sharechange_dollars, asppricechange, promopricechange, nonpromopricechange

                                    try {
                                        sharechange_units = (this.props.state.all_period_metrics[brand][item]['unitsshare_curr'] || 0) - (this.props.state.all_period_metrics[brand][item]['unitsshare_prior'] || 0)
                                        sharechange_dollars = (this.props.state.all_period_metrics[brand][item]['dollarshare_curr'] || 0) - (this.props.state.all_period_metrics[brand][item]['dollarshare_prior'] || 0)
                                        asppricechange = (this.props.state.all_period_metrics[brand][item]['aspprice_curr'] || 0) - (this.props.state.all_period_metrics[brand][item]['aspprice_prior'] || 0)
                                        promopricechange = (this.props.state.all_period_metrics[brand][item]['promoprice_curr'] || 0) - (this.props.state.all_period_metrics[brand][item]['promoprice_prior'] || 0)
                                        nonpromopricechange = (this.props.state.all_period_metrics[brand][item]['nonpromoprice_curr'] || 0) - (this.props.state.all_period_metrics[brand][item]['nonpromoprice_prior'] || 0)
                                    }
                                    catch (error) {
                                        sharechange_units = 0
                                        sharechange_dollars = 0
                                        asppricechange = 0
                                        promopricechange = 0
                                        nonpromopricechange = 0
                                    }

                                    if (sharechange_units === 0 && sharechange_dollars === 0 && asppricechange === 0 && promopricechange === 0 && nonpromopricechange === 0) {
                                        return null
                                    }
                                    else {
                                        return <Constant.NPDTableRow key={'prevyearcomp' + idx}>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                {brand}
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                {item}
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                {asppricechange.toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                {promopricechange.toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                {nonpromopricechange.toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                {parseFloat(((sharechange_units) * 100).toFixed(2)).toFixed(2) + ' ppts'}
                                            </Constant.NPDTableCell>
                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>
                                                {parseFloat(((sharechange_dollars) * 100).toFixed(2)).toFixed(2) + ' ppts'}
                                            </Constant.NPDTableCell>
                                        </Constant.NPDTableRow>
                                    }
                                })}

                            </Fragment>
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={(this.props.state.all_items_for_yoy || []).length}
                    rowsPerPage={this.props.state.rowsPerPage}
                    page={this.props.state.page}
                    onChangePage={this.props.handleChangePage}
                    onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                />
            </Fragment>
        )
    }
}