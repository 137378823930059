export default function  handleChange_IndexSwitch (event) {
    let current_switch = this.state.indexSwitchCheck
    let new_switch = !current_switch

    if (new_switch === false) {

        this.setState({
            indexSwitchCheck: new_switch,
            index_compare_type: 'Indexed',
        })
    }
    else {

        this.setState({
            indexSwitchCheck: new_switch,
            index_compare_type: 'OverUnder',
        })
    }
    //this.runcomparison()
    this.runcomparison_apis()
}