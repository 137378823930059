import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Select from 'react-select'
import Checkbox from '@material-ui/core/Checkbox';
import { Layout } from './Layout';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TranslateRoundedIcon from '@material-ui/icons/TranslateRounded';
import './styles/custom.css'
import * as Constant from './constants'

export class Profile extends Component {
    static displayName = Profile.name;

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            emailaddress: "",
            internalUser: "",
            userbrands: [],
            available_brands: [],
            userid: null,
            companyid: null,
            adminflag: null,
            companyname: null,
            permissions: null,
            geomap: {},
            showSnackbar_success: false,
            showSnackbar_failure: false,
            appname: '',
            language_options: [
                { label: 'English – United States', value: 'en-US' },
                { label: 'English – United Kingdom', value: 'en-UK' },
                { label: 'Français', value: 'fr' },
                { label: 'Español', value: 'es' },
                { label: 'Deutsch', value: 'de' },
                { label: 'Italiano', value: 'it' },
                { label: 'русский', value: 'ru' },
            ]
        }
    }

    onChangeLang = (selectedOptions) => {
        let code = selectedOptions.value
        let selectedLang = this.state.language_lookup[code]
        this.setState({ selectedLang })
    }

    onChangeDataset = async (selectedOptions, action) => {
        console.log(selectedOptions)
        if (selectedOptions) {
            this.setState({ selecteddataset: selectedOptions });
            //Get the available Brands....
            var datasetid = selectedOptions.value
            this.setState({ datasetid })
            const brands = await axios.get(Constant.baseapiurl + 'Datasets/' + datasetid + '/Brands');
            console.log(brands.data)
            var available_brands = []
            brands.data.map(brand => available_brands.push({ 'value': brand, 'label': brand }))
            this.setState({ available_brands })
            //Get User Brands for Dataset
            const userbrandsresult = await axios.get(Constant.baseapiurl + 'UserSettings/Brands/' + datasetid);
            console.log(userbrandsresult.data)
            var userbrands = []
            userbrandsresult.data.map(brand => userbrands.push({ 'value': brand, 'label': brand }))
            this.setState({ userbrands })
        }
        else {
            this.setState({
                selecteddataset: null
            })
        }
    }

    onChangeBrand(selectedOptions, action) {
        console.log(selectedOptions)
        if (selectedOptions && selectedOptions.length > 0) {
            this.setState({ userbrands: selectedOptions });
        }
        else {
            this.setState({ userbrands: [] })
        }
    }



    componentDidMount() {

        console.log(window.navigator.language)


        let language_lookup = {}
        this.state.language_options.forEach(option => {
            //let lang = option.label
            let code = option.value
            language_lookup[code] = option
        })

        this.setState({ selectedLang: language_lookup[window.navigator.language], language_lookup})

        console.log(Constant.whereamilist[3].toLowerCase())
        let appname = Constant.whereamilist[3].toLowerCase()
        this.setState({ appname })

        const GetUserInfo = async () => {
            const usersresult = await axios.get(Constant.baseapiurl_gen + 'SessionUser/Details');
            this.setState({
                companyid: usersresult.data.company_id,
                companyname: usersresult.data.company_name,
                userid: usersresult.data.user_id,
            })
        }

        /*
        const GetCompanyInfo = async (companyid) => {
            const usersresult = await axios.get(Constant.baseapiurl_gen + 'Companies/' + companyid);
            this.setState({
                companyname: usersresult.data.company_name,
            })
        }

        const GetUserInfo = async (userid) => {
            const usersresult = await axios.get(Constant.baseapiurl_gen + 'Users/' + userid);
            this.setState({
                companyid: usersresult.data.company_id,
                //adminflag: usersresult.data.is_admin
            })
            GetCompanyInfo(usersresult.data.company_id)
        }

        const GetUserId = async (username) => {
            const usersresult = await axios.get(Constant.baseapiurl_gen + 'Users');
            console.log(usersresult)
            usersresult.data.map(user => {
                if (user.user_name === username) {
                    this.setState({ userid: user.user_id })
                    GetUserInfo(user.user_id)
                }
                return user.user_id
            })
        }
        */
        const GetSessionPermissions = async () => {
            const permissions = await axios.get(Constant.baseapiurl_gen + 'SessionUser/Permissions');
            console.log(permissions)
            let available_datasets = []
            //Set Eligible Datasets for setting brands
            if (appname) {
                var brands_array = []
                var brands_added = []
                var geomap = {}
                permissions.data[appname].forEach(dataset => {
                    available_datasets.push({ 'value': dataset.dataset_id, 'label': dataset.shortdesc })
                    if (appname === 'pnp') {
                        try {
                            fetch(Constant.baseapiurl + "Datasets/" + dataset.dataset_id + "/Parameters")
                                .then(result => {
                                    if (result.status === 200) {
                                        console.log('OK')
                                        result.json()
                                            .then(
                                                (results) => {

                                                    //Create Geo Map...
                                                    var specific_geo = {}
                                                    results.geos.forEach(geo => {
                                                        console.log(geo)
                                                        console.log(geo.geogid)
                                                        //Perform search here...

                                                        if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                                                            geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
                                                        }
                                                        else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                                                            geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
                                                        }
                                                        else {
                                                            geo.geogdesc_modified = geo.geogdesc
                                                        }

                                                        specific_geo[geo.geogid] = geo.geogdesc_modified
                                                        console.log(specific_geo)
                                                        geomap[dataset.dataset_id] = specific_geo
                                                        this.setState({ geomap })
                                                    })

                                                    results.brands.forEach(brand => {
                                                        if (!brands_added.includes(brand)) {
                                                            brands_array.push({ 'value': brand, 'label': brand })
                                                            brands_added.push(brand)
                                                        }
                                                    })
                                                },
                                                (error) => {
                                                    console.log(error)
                                                }
                                            )
                                    }
                                    else {
                                        console.log('There was an Error Running the API')
                                    }
                                })
                        }
                        catch (error) {
                            console.log(error)
                        }
                    }
                    else if (appname === 'pdt') {
                        try {
                            fetch(Constant.baseapiurl + "Datasets/" + dataset.dataset_id + "/Parameters")
                                .then(result => {
                                    if (result.status === 200) {
                                        console.log('OK')
                                        result.json()
                                            .then(
                                                (results) => {
                                                    console.log(results)
                                                    //Create Geo Map...
                                                    var specific_geo = {}
                                                    results.store_groups.forEach(storegroup => {
                                                        console.log(storegroup)
                                                        console.log(storegroup.storegpid)
                                                        //Perform search here...

                                                        if (storegroup.storegpdesc.toUpperCase().indexOf('RM') > -1) {
                                                            storegroup.storegpdesc_modified = storegroup.storegpdesc + ' (Remaining Market)'
                                                        }
                                                        else if (storegroup.storegpdesc.toUpperCase().indexOf('ALR') > -1) {
                                                            storegroup.storegpdesc_modified = storegroup.storegpdesc + ' (Account Level Report)'
                                                        }
                                                        else {
                                                            storegroup.storegpdesc_modified = storegroup.storegpdesc
                                                        }

                                                        specific_geo[storegroup.storegpid] = storegroup.storegpdesc_modified
                                                        console.log(specific_geo)
                                                        geomap[dataset.dataset_id] = specific_geo
                                                        this.setState({ geomap })
                                                    })

                                                    results.brands.forEach(brand => {
                                                        if (!brands_added.includes(brand)) {
                                                            brands_array.push({ 'value': brand, 'label': brand })
                                                            brands_added.push(brand)
                                                        }
                                                    })
                                                },
                                                (error) => {
                                                    console.log(error)
                                                }
                                            )
                                    }
                                    else {
                                        console.log('There was an Error Running the API')
                                    }
                                })
                        }
                        catch (error) {
                            console.log(error)
                        }
                    }
                    
                })
            }

            this.setState({
                permissions: permissions.data[appname], available_datasets
            })
        }

        //Get User Info
        const checkAuthenticated = async () => {
            const result = await axios.get('/account/isauthenticated');
            GetUserInfo()
            console.log(result.data.userRole)
            var adminflag = false
            if (result.data.userRole.includes("Admin")) {
                adminflag = true
            }

            this.setState({
                name: result.data.name,
                emailaddress: result.data.emailaddress,
                internalUser: result.data.internalUser,
                adminflag: adminflag
            })
        }
        checkAuthenticated()
        GetSessionPermissions()
    }



    render() {

        const saveChanges = () => {
            var datasetid = this.state.datasetid
            var url = Constant.baseapiurl + 'UserSettings/Brands/' + datasetid
            var body = this.state.userbrands.map(userbrand => userbrand.value)
            //Post the brands to the list...
            fetch(url,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                })
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        //console.log('ok')
                        res.json()
                            .then(
                                (result) => {
                                    console.log(result)
                                    if (result.Status === 'Success') {
                                        console.log('Brands Set Successfully')
                                        this.setState({ showSnackbar_success: true })
                                    }
                                    else {
                                        console.log('There was some error')
                                        this.setState({ showSnackbar_failure: true })

                                    }
                                })
                    }
                    else {
                        console.log('There was an Error Running the API')
                    }
                })
        }

        const closeSnackBar = () => {
            this.setState({ showSnackbar_success: false, showSnackbar_failure: false })
        }

        console.log(this.state)
        console.log(this.state.adminflag)
        return (
            <Layout title="Profile Page">

                <Constant.NPDSnackbarSuccess
                    open={this.state.showSnackbar_success}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={closeSnackBar}
                >
                    <Constant.NPDSnackbarContentSuccess
                        message="Brands Set"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContentSuccess>
                </Constant.NPDSnackbarSuccess>

                <Constant.NPDSnackbarError
                    open={this.state.showSnackbar_failure}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={closeSnackBar}
                >
                    <Constant.NPDSnackbarContentError
                        message="There Was an Error"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContentError>
                </Constant.NPDSnackbarError>

                <div style={{ width: '100%', minHeight: '100%', position: 'absolute', left: '120px', top: '60px', backgroundColor: '#F4F4F4', maxWidth: 'calc(100vw - 120px)', paddingLeft:'40px' }}>
                    <div className="col-sm-6">
                        <h1>Profile</h1>
                    </div>
                    <div className="col-sm-6">
                        <span className="formfieldtitle">Name</span>
                        <p>{this.state.name}</p>
                    </div>
                    <div className="col-sm-6">
                        <span className="formfieldtitle">Company Name</span>
                        <p>{this.state.companyname}</p>
                    </div>
                    <div className="col-sm-6">
                        <span className="formfieldtitle">Email Address</span>
                        <p>{this.state.emailaddress}</p>
                    </div>
                    <div className="col-sm-6">
                        <span className="formfieldtitle">Administrator</span>
                        <Checkbox
                            checked={this.state.adminflag === true} //this.state.adminflag} 
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            style={{ color: "#4e106f" }}
                        />
                    </div>
                    <div className="col-sm-6">
                        <span className="formfieldtitle" style={{ margin: '10px', padding:'10px 0' }}> <TranslateRoundedIcon style={{ marginBottom: '10px' }}/> Change Language</span>
                        <Select
                            id="branddropdown"
                            options={this.state.language_options}
                            placeholder={'Select A Language'}
                            onChange={(item) => this.onChangeLang(item)}
                            value={this.state.selectedLang}
                        />
                    </div>

                    {this.state.appname === 'pnp' ?
                        <div className="col-sm-6">
                            <span className="formfieldtitle">Select a Dataset to Set Your Brands</span>
                            <Select
                                id="branddropdown"
                                options={this.state.available_datasets}
                                placeholder={'Select A Dataset'}
                                onChange={(item, action) => this.onChangeDataset(item, action)}
                                value={this.state.selecteddataset}
                            />
                        </div>
                        :
                        <div/>
                    }
                    {this.state.appname === 'pnp' ?
                        <div className="col-sm-6">
                            <span className="formfieldtitle">My Brands</span>
                            <Select
                                id="branddropdown"
                                options={this.state.available_brands}
                                isMulti
                                placeholder={'Select Brands'}
                                onChange={(item, action) => this.onChangeBrand(item, action)}
                                value={this.state.userbrands}
                            />
                        </div>
                        :
                        <div />
                    }
                    <br />
                    {this.state.appname === 'pnp' ?
                        <div className="col-sm-6">
                            <button onClick={saveChanges} className="btn btn-primary" autoFocus>
                                Save Changes
                            </button>
                        </div>
                        :
                        <div />
                    }
                    <br />
                    
                    <div className="col-sm-6" style={{ height: '50px' }}>
                    </div>


                </div>
            </Layout>
        )
    }
}