export default function cancelSetViz() {
    //console.log(selectedOptions)

    //Set User Settings

    //Set the state
    let ordername = this.state.screenOrder    
    let savedOrderValue_forDropdown = { 'value': ordername, 'label': ordername }
    this.setState({ savedOrderValue_forDropdown })
    this.openChangeVizzes()
}