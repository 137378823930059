import React, { Component } from 'react';
import './styles/custom.css'
import { Layout } from './Layout';
import intro_video from "./videos/PNP/Introduction Video.mp4"
import create_report_video from "./videos/PNP/Create New Report.mp4"
import report_outputs_video from "./videos/PNP/Report Outputs.mp4"
import edit_report_video from "./videos/PNP/Edit Report.mp4"
import create_simulation_video from "./videos/PNP/Simulation Entry.mp4"
import simulation_outputs_video from "./videos/PNP/Simulation Outputs.mp4"
import edit_simulation_video from "./videos/PNP/Edit Simulation.mp4"
import sim_from_report_video from "./videos/PNP/Sim from Report.mp4"
import compare_sims_video from "./videos/PNP/Compare Sims.mp4"
import additional_features_video from "./videos/PNP/Additional Features.mp4"
import competitive_alignment_video from "./videos/PNP/Competitive Alignment Chart.mp4"
import comingsoon from "./videos/PDT/ComingSoon.png"


import pdt_intro_video from "./videos/PDT/PDT Intro.mp4"
import pdt_create_report_video from "./videos/PDT/PDT Create Report.mp4"
import pdt_report_outputs_video from "./videos/PDT/PDT Report Output.mp4"
import pdt_edit_report_video from "./videos/PDT/PDT Edit Report.mp4"
import pdt_additional_features_video from "./videos/PDT/PDT Additional Features.mp4"
import pdt_competitive_alignment_video from "./videos/PDT/PDT Competitive Alignment.mp4"

export class PNPGlossary extends Component {
    static displayName = PNPGlossary.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {

    }

    render() {

        return (
            <Layout title="Glossary">
                <div name='banner' className='banner'>
                    <h1white align='center'>Glossary</h1white><br/>
                    <h2white align='center'>Here you will find commonly used terms throughout the site</h2white>
                </div>
                <div name="glossary" className="mainbodydiv">
                    <div style={{ height: '10px' }}></div>
                    <div style={{ padding: '0px', margin: '25px', width: '90%' }}>
                        <div id="BaseAndIncremental">
                            <h5>Base Price</h5>
                            <p>Base Price is an estimate of the non-promoted selling price for an item in a week in a store. It is the rolling average of the previous prices that are within 5% of the max price over the previous 12 weeks. It is used to identify promoted weeks and non-promoted weeks. </p>
                            <h5>Promoted Weeks</h5>
                            <p>Store weeks where the price is discounted. A week is considered discounted when the price of an item is 5% or more lower than the estimated base price. </p>
                            <h5>Base Sales</h5>
                            <p>An estimate of unit sales that would have occurred without promotions from a temporary price reduction.</p>
                            <h5>Incremental Sales </h5>
                            <p>An estimate of the impact on units due to the presence of a temporary price reduction. Incremental sales are Total Sales minus Base Sales in stores with a temporary price discount.</p>
                        </div>

                        <div id="PromotedNonPromoted">
                            <h5>Promoted Sales</h5>
                            <p>Unit or Value ($, €, £) sales in store/weeks that had a temporary price reduction.</p>
                            <h5>Non-Promoted Sales </h5>
                            <p>Unit or Value ($, €, £) sales that occur when there is no temporary price reduction.</p>
                        </div>

                        <div id="SubsidizedUnSubsidized">
                            <h5>Subsidized Base Sales</h5>
                            <p>Subsidized Base Units are estimated base units that were sold in stores with a temporary price reduction. These are the estimated units that would have sold regardless of the promotion. Subsidized Base Dollars is calculated by multiplying Subsidized Base Units by the Promoted Price.</p>
                            <h5>Unsubsidized Base Sales</h5>
                            <p>Unsubsidized Base Units are the estimated base units sold in non-promoted stores. Unsubsidized Base Value ($, €, £) is calculated by multiplying Unsubsidized Base Units by the Base Price.</p>
                        </div>

                        <div id="SubsidizedVolume%">
                            <h5>Subsidized Volume Percent</h5>
                            <p>Subsidized Volume Percent is calculated by (Promoted Units - Incremental Units) / (Promoted Units)</p>
                        </div>

                        <div id="LiftEfficiency">
                            <h5>Market Lift</h5>
                            <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            <h5>Promoted Lift</h5>
                            <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            <h5>Efficiency</h5>
                            <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                        </div>

                        <div id="TotalSales">
                            <h5>Total Sales</h5>
                            <p>
                                The Total Units or Value ($, €, £) sales of a product or products during a specific time period. Please note that:
                              <br />Total Sales = Non-Promoted Sales + Promoted Sales, and
                              <br />Total Sales = Base Sales + Incremental Sales
                           </p>
                        </div>

                        <div id="DiscountDollars">
                            <h5>Discount Dollars </h5>
                            <p>The total value ($, €, £) of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the observed price of a promoted product and multiplying by Promoted Units.</p>
                        </div>

                        <div id="PaybackRatio">
                            <h5>Payback Ratio </h5>
                            <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                        </div>

                        <div id="DueTo">
                            <h5>Core & Distribution</h5>
                            <p>A sub-set of Base Sales, representing the number of total sales driven by distribution impacts, new products, and other marketing impacts (e.g., brand advertising, consumer support or other activities unrelated to trade marketing).</p>
                            <h5>Seasonality</h5>
                            <p>The number of sales due to seasonal fluctuations in category interest or traffic. This includes overall holiday shopping patterns, as well as seasonal changes in interest for some categories.</p>
                            <h5>Holiday</h5>
                            <p>The number of sales driven by a specific holiday or ‘event,’ like Black Friday or Cyber Monday.</p>
                            <h5>Base Price</h5>
                            <p>The impact of ‘every day’ price changes made by the brands being analyzed. For example, a long term 10% price roll back that lasts more than 8 weeks would typically be considered a long-term price change, unless it occurs in Walmart where the change needs to be more than 13 weeks to be considered long-term.</p>
                            <h5>Competitive Pricing Impacts</h5>
                            <p>The impact of competitive pricing actions, such as competitive price increases or rollbacks.</p>
                            <h5>Temporary Price Reduction (TPR) or Price Discounting</h5>
                            <p>TPR impacts represent the impact of short-term promotional price changes driven by discounting at specific retailers.</p>

                        </div>

                        <div id="FinancialMetrics...">
                            <h5>Gross Revenue</h5>
                            <p>Gross Revenue is Total Dollars earned.</p>
                            <h5>Total Spend</h5>
                            <p>This is the total cost of the promotion. This is calculated for each item as (Promoted Units * Cost Per Promoted Unit) + (Promoted Units * (Base Price - Promoted Price)). This calculation is then summed up for each item.</p>
                            <h5>Net Revenue</h5>
                            <p>This is calculated as (Gross Revenue – Cost Per Promoted Unit).  Cost Per Promoted Unit is entered by the user on the “Add Financials” input page, to account for any additional costs associated with a promotion not related to price reduction.</p>
                            <h5>Incremental Gross Revenue</h5>
                            <p>Incremental Gross Revenue is Incremental Dollars earned from a promotion.</p>
                            <h5>Incremental Net Revenue</h5>
                            <p>This is calculated as Incremental Gross Revenue -Total Spend.</p>
                            <h5>Incremental Profit</h5>
                            <p>This is calculated as Incremental Net Revenue * Gross Margin for each item. These are then added up to get a total profit for the promotion.</p>
                            <h5>Sales ROI</h5>
                            <p>The return on investment is calculated as Incremental Net Revenue/Total Spend.</p>
                            <h5>Retailer Promotion Margin $</h5>
                            <p>For each item this is calculated as Promoted Dollars * Retailer Margin %. This is added up to get the total Retailer Promotion Margin Dollars.</p>
                            <h5>Retailer Promotion Margin %</h5>
                            <p>This is the Retailer Promotion Margin Dollars / Promotion Dollars.</p>
                            <h5>Retailer Everyday Margin %</h5>
                            <p>For each item, we take the Retailer Margin Percentage * Subsidized Base Dollars. We add all these together and then divide by the total Subsidized Base Dollars.</p>
                            <h5>Retailer Promotion Profit</h5>
                            <p>For each item, we calculate (Gross Revenue - Subsidized Base Dollars) * Retailer Margin %. We then add all these together to get profit.</p>
                        </div>

                        <div id="SimulationMetrics...">
                            <h5>Simulated Market Share</h5>
                            <p>This is a measure of the simulated sales for brand or item divided by the total simulated sales for the whole category.</p>
                            <h5>Difference in Market Share (Points)</h5>
                            <p>This measures the difference in the simulated market share from the base market share (base sales for a brand or item divided by the total base sales for the category) and displays the difference in percentage points. For example, if the base market share was 25% and the simulated market share was 28% the value displayed would be 3 ppts.</p>
                        </div>
                    </div>
                    <div style={{ height: '50px' }}></div>
                </div>
            </Layout>


        );


    }
}

export class PNPCredits extends Component {
    static displayName =PNPCredits.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {

    }

    render() {

        let yearnum = new Date().getFullYear()

        return (

            <Layout title="Credits">
                <div className="contentHolderGradient" style={{ height: "100%", width: '100%', position: 'absolute', left: '120px', top: '60px', maxWidth: 'calc(100vw - 120px)', padding: '20px' }} >

                    <div className="contentHolder">
                        <h4>Credits</h4>

                        {'Copyright© ' + yearnum +' Circana, Inc. and Circana Group, L.P. (“Circana”). All rights reserved. Circana, the Circana logo, and the names of Circana products and services referenced herein are either trademarks or registered trademarks of Circana. All other trademarks are the property of their respective owners.'}

                    </div>
                    <div style={{ height: '50px' }}></div>
                </div>
            </Layout>


        );


    }
}

export class PNPResources extends Component {
    static displayName = PNPResources.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {

    }

    render() {
        return (
            <Layout title="Resources">
                <div name='banner' className='banner'>
                    <h1white align='center'>Resources</h1white>
                </div>
                <div name="glossary" className="mainbodydiv">
           
                    <div style={{ paddingLeft: '55px', paddingTop: '25px' }}>

                        <h3>Links</h3>
                        <ul>
                            <li><a href="/PNP/glossary">Glossary</a></li>
                            <li><a href=" https://www.circana.com/">Circana Site</a></li>
                        {/*    <li><a href="https://www.npd.com/wps/portal/npd/us/solutions/solutions/price-and-promotion-optimization/">Circana Site on Price and Promotion Management</a></li>*/}
                        </ul>

                        <h3>Video Tutorials</h3>
                        <h7>Video Tutorials Coming Soon</h7>

                        {/*<h5><a href="/PNP/Resources#IntroVideo">Introduction Video</a></h5>*/}
                        {/*<h5><a href="/PNP/Resources#ReportVideos">Report Videos</a></h5>*/}
                        {/*<ul>*/}
                        {/*    <li><a href="/PNP/Resources#ReportVideo1">Create a New Report</a></li>*/}
                        {/*    <li><a href="/PNP/Resources#ReportVideo2">Report Outputs</a></li>*/}
                        {/*    <li><a href="/PNP/Resources#ReportVideo3">Edit a Report</a></li>*/}
                        {/*</ul>*/}
                        {/*<h5><a href="/PNP/Resources#SimVideos">Simulation Videos</a></h5>*/}
                        {/*<ul>*/}
                        {/*    <li><a href="/PNP/Resources#SimVideo1">Create a New Simulation</a></li>*/}
                        {/*    <li><a href="/PNP/Resources#SimVideo2">Simulation Outputs</a></li>*/}
                        {/*    <li><a href="/PNP/Resources#SimVideo3">Edit a Simulation</a></li>*/}
                        {/*</ul>*/}
                        {/*<h5><a href="/PNP/Resources#AdvancedVideos">Advanced Features Videos</a></h5>*/}
                        {/*<ul>*/}
                        {/*    <li><a href="/PNP/Resources#AdvancedVideo1">Create a Simulation from a Report</a></li>*/}
                        {/*    <li><a href="/PNP/Resources#AdvancedVideo2">Compare Simulations</a></li>*/}
                        {/*    <li><a href="/PNP/Resources#AdvancedVideo3">Additional Features</a></li>*/}
                        {/*    <li><a href="/PNP/Resources#AdvancedVideo4">Competitive Alignment</a></li>*/}
                        {/*</ul>*/}

                        <h5 id="IntroVideo">Introduction</h5>
                        <p>
                            This video will show a broad overview of the application as well as give some basic navigation tips.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={intro_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h4 id="ReportVideos">Report Videos</h4>
                        <p>
                            This series of videos will show how to use the reporting features of the application.  This covers creating new reports, reviewing the output of reports, and editing a report.
                            <br />
                            Total Time: ~6 mins
                        </p>

                        <h5 id="ReportVideo1">Create a New Report</h5>
                        <p>
                            This video will show how to create a new report and describe the fields in the form.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={create_report_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="ReportVideo2">Report Outputs</h5>
                        <p>
                            This video will show how to navigate the report output page and explain how to get the most out of the output.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={report_outputs_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="ReportVideo3">Edit a Report</h5>
                        <p>
                            This video will show how to edit an existing report and review the new results.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={edit_report_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h4 id="SimVideos">Simulation Videos</h4>
                        <p>
                            This series of videos will show how to use the simulation features of the application.  This covers creating new simulations, reviewing the output of simulations, and editing a simulation.
                            <br />
                            Total Time: ~12 mins
                        </p>

                        <h5 id="SimVideo1" >Create a New Simulation</h5>
                        <p>
                            This video will show how to create a new simulation and describe all of the fields in the entry forms.  This will also show how to add items and identify competitors.
                            <br />
                            Time: ~7 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={create_simulation_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="SimVideo2" >Simulation Outputs</h5>
                        <p>
                            This video will show how to navigate the simulation output page and explain how the metrics are organized.
                            <br />
                            Time: ~3 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={simulation_outputs_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="SimVideo3" >Edit a Simulation</h5>
                        <p>
                            This video will show how to edit an existing simulation and see the new results.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={edit_simulation_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h4 id="AdvancedVideos">Advanced Features Videos</h4>
                        <p>
                            This series of videos will show how to use the newly developed features for the application, including creating a simulation from a report, comparing simulations, and using the icon tray in reports and simulations.
                            <br />
                            Total Time: ~12 mins
                        </p>

                        <h5 id="AdvancedVideo1">Create a Simulation from a Report</h5>
                        <p>
                            This video will show how to create a new simulation directly from a report and how the information is populated on the simulation entry form.
                            <br />
                            Time: ~3 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={sim_from_report_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="AdvancedVideo2">Compare Simulations</h5>
                        <p>
                            This video will show how to compare the results of several simulations at once.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={compare_sims_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="AdvancedVideo3">Additional Features</h5>
                        <p>
                            This video will show what the icon buttons in the top right in reports and simulations do.
                            <br />
                            Time: ~5 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={additional_features_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="AdvancedVideo4">Competitive Alignment</h5>
                        <p>
                            This video will show how to use the competitive alignment chart in the reporting module.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={competitive_alignment_video} type="video/mp4" />*/}
                        {/*</video>*/}



                    </div>
                    <div style={{ height: '50px' }}></div>
                </div>
            </Layout>


        );


    }
}

export class PDTGlossary extends Component {
    static displayName = PDTGlossary.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {

    }

    render() {

        return (
            <Layout title="Glossary">
                <div name='banner' className='banner'>
                    <h1white align='center'>Glossary</h1white><br/>
                    <h2white align='center'>Here you will find commonly used terms throughout the site</h2white>
                </div>
                <div name="glossary" className="mainbodydiv">
                    <div style={{ height: '10px' }}></div>
                    <div style={{ padding: '0px', margin: '25px', width: '90%' }}>
                        <div id="BaseAndIncremental">
                            <h5>Base Price</h5>
                            <p>Base Price is an estimate of the non-promoted selling price for an item in a week in a store. It is the rolling average of the previous prices that are within 5% of the max price in the previous 12 weeks. It is used to identify promoted weeks and non-promoted weeks. </p>
                            <h5>Promoted Weeks</h5>
                            <p>Store weeks where the price is discounted. A week is considered discounted when the price of an item is 5% or more lower than the estimated base price.  </p>
                            <h5>Base Sales</h5>
                            <p>An estimate of unit sales that would have occurred without promotions from a temporary price reduction.</p>
                            <h5>Incremental Sales </h5>
                            <p>An estimate of the impact on unit sales due to the presence of a temporary price reduction. Incremental sales are Total Sales minus Base Sales in stores with a temporary price discount.</p>
                        </div>

                        <div id="PromotedNonPromoted">
                            <h5>Promoted Sales</h5>
                            <p>Unit or Value ($, €, £) sales in store/weeks that had a temporary price reduction.</p>
                            <h5>Non-Promoted Sales </h5>
                            <p>Unit or Value ($, €, £) sales that occur when there is no temporary price reduction.</p>
                        </div>

                        <div id="SubsidizedUnSubsidized">
                            <h5>Subsidized Base Sales</h5>
                            <p>Subsidized Base Units are estimated base units that were sold in stores with a temporary price reduction. These are the estimated units that would have sold regardless of the promotion. Subsidized Base Dollars is calculated by multiplying Subsidized Base Units by the Promoted Price.</p>
                            <h5>Unsubsidized Base Sales</h5>
                            <p>Unsubsidized Base Units are the estimated base units sold in non-promoted stores. Unsubsidized Base Value ($, €, £) is calculated by multiplying Unsubsidized Base Units by the Base Price.</p>
                        </div>

                        <div id="SubsidizedVolume%">
                            <h5>Subsidized Volume Percent</h5>
                            <p>Subsidized Volume Percent is calculated by (Promoted Units - Incremental Units) / (Promoted Units)</p>
                        </div>

                        <div id="LiftEfficiency">
                            <h5>Market Lift</h5>
                            <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            <h5>Promoted Lift</h5>
                            <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            <h5>Efficiency</h5>
                            <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                        </div>

                        <div id="TotalSales">
                            <h5>Total Sales</h5>
                            <p>
                               The Total Units or Value ($, €, £) sales of a product or products during a specific time period. Please note that:
                              <br />Total Sales = Non-Promoted Sales + Promoted Sales, and
                              <br />Total Sales = Base Sales + Incremental Sales
                           </p>
                        </div>

                        <div id="DiscountDollars">
                            <h5>Discount Dollars </h5>
                            <p>The total value ($, €, £) of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the actual price of a promoted product and multiplying by Promoted Unit sales.</p>
                        </div>

                        <div id="PaybackRatio">
                            <h5>Payback Ratio </h5>
                            <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                        </div>

                        <div id="BaseVelocity">
                            <h5>Base Velocity</h5>
                            <p>
                                Base Velocity is defined as Base Units or Value ($, €, £) divided by the distribution of stores that carried the item or brand.
                                <br />
                                For example, if base units was 1000, and the item or brand was carried in 75 stores out of 100 possible stores, the base velocity will be 1000 / (75/100) = 1,333 units.
                            </p>
                        </div>

                        <div id="BaseSupport">
                            <h5>% Base Support</h5>
                            <p>
                                Base Support % is defined as Subsidized Base Units or Value ($, €, £) divided by Base Units or Value ($, €, £).
                                <br />
                                For example, if subsidized base units was 1000, and the base units was 1500, the base support % will be 1000 / 1500 = 67%.
                            </p>
                        </div>

                        <div id="QuadWeekBaseWeightedWeeks">
                            <h5>Base Weighted Weeks</h5>
                            <p>
                                Base Weighted Weeks is calculated by taking the ratio of the subsidized units over the base units times the number of weeks we are calculating the measure for.
                                <br />
                                For Example, if we are looking at the 4 week time period end January 28th, and the subsidized base and base values look as such:                                                                 
                            </p>
                            <table
                                style={{
                                    fontSize: "1.2rem",
                                    textAlign: "center",
                                    color: "#565A5C"
                                }}
                            >
                                <thead>
                                    <tr>
                                        <td>Week Ending</td>
                                        <td>Base </td>
                                        <td>Subsidized Base </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>January 7</td>
                                        <td>100</td>
                                        <td>40</td>
                                    </tr>
                                    <tr>
                                        <td>January 14</td>
                                        <td>300</td>
                                        <td>30</td>
                                    </tr>
                                </tbody>
                                <tr>
                                    <td>January 21</td>
                                    <td>200</td>
                                    <td>50</td>
                                </tr>
                                <tr>
                                    <td>January 28</td>
                                    <td>400</td>
                                    <td>20</td>
                                </tr>
                            </table>
                            <br/>
                            <p>
                                Then the Quad Week Base Weighted Week value would be: ((40 + 30 + 50 + 20) / (100 + 300 + 200 + 400)) * 4 = (140/1000) * 4 = 0.56
                                <br />
                            </p>
                        </div>
                        
                    </div>
                    <div style={{ height: '50px' }}></div>
                </div>
            </Layout>


        );


    }
}

export class PDTCredits extends Component {
    static displayName = PDTGlossary.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {

    }

    render() {

        let yearnum = new Date().getFullYear()

        return (
            
            <Layout title="Credits">
                <div className="contentHolderGradient" style={{ height: "100%", width: '100%', position: 'absolute', left: '120px', top: '60px', maxWidth: 'calc(100vw - 120px)', padding: '20px' }} >

                    <div className="contentHolder">
                        <h4>Credits</h4>

                        {'Copyright© ' + yearnum + ' Circana, Inc. and Circana Group, L.P. (“Circana”). All rights reserved. Circana, the Circana logo, and the names of Circana products and services referenced herein are either trademarks or registered trademarks of Circana. All other trademarks are the property of their respective owners.'}

                    </div>
                    <div style={{ height: '50px' }}></div>
                </div>
            </Layout>


        );


    }
}

export class PDTResources extends Component {
    static displayName = PDTResources.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {

    }

    render() {
        return (
            <Layout title="Resources">
                <div name='banner' className='banner'>
                    <h1white align='center'>Resources</h1white>
                </div>
                <div name="glossary" className="mainbodydiv">

                    <div style={{ paddingLeft: '55px', paddingTop: '25px' }}>
                        <h3>Links</h3>
                        <ul>
                            <li><a href="/PDT/glossary">Glossary</a></li>
                            <li><a href="https://www.circana.com">Circana Site</a></li>
                        {/*    <li><a href="https://www.npd.com/wps/portal/npd/us/solutions/price-and-discount-trends-for-retailers/">Circana Site on Price and Discount Trends</a></li>*/}
                        </ul>
                        
                        <h3>Video Tutorials</h3>
                        <h7>Video Tutorials Coming Soon</h7>

                        {/*<h5><a href="/PDT/Resources#IntroVideo">Introduction Video</a></h5>*/}
                        {/*<h5><a href="/PDT/Resources#ReportVideos">Report Videos</a></h5>*/}
                        {/*<ul>*/}
                        {/*    <li><a href="/PDT/Resources#ReportVideo1">Create a New Report</a></li>*/}
                        {/*    <li><a href="/PDT/Resources#ReportVideo2">Report Outputs</a></li>*/}
                        {/*    <li><a href="/PDT/Resources#ReportVideo3">Edit a Report</a></li>*/}
                        {/*</ul>*/}
                        {/*<h5><a href="/PDT/Resources#AdvancedVideos">Advanced Features Videos</a></h5>*/}
                        {/*<ul>*/}
                        {/*    <li><a href="/PDT/Resources#AdvancedVideo1">Additional Features</a></li>*/}
                        {/*    <li><a href="/PDT/Resources#AdvancedVideo2">Competitive Alignment</a></li>*/}
                        {/*</ul>*/}

                        <h5 id="IntroVideo">Introduction</h5>
                        <p>
                            This video will show a broad overview of the application as well as give some basic navigation tips.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={pdt_intro_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h4 id="ReportVideos">Report Videos</h4>
                        <p>
                            This series of videos will show how to use the reporting features of the application.  This covers creating new reports, reviewing the output of reports, and editing a report.
                            <br />
                            Total Time: ~9 mins
                        </p>

                        <h5 id="ReportVideo1">Create a New Report</h5>
                        <p>
                            This video will show how to create a new report and describe the fields in the form.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={pdt_create_report_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="ReportVideo2">Report Outputs</h5>
                        <p>
                            This video will show how to navigate the report output page and explain how to get the most out of the output.
                            <br />
                            Time: ~5 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={pdt_report_outputs_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="ReportVideo3">Edit a Report</h5>
                        <p>
                            This video will show how to edit an existing report and review the new results.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={pdt_edit_report_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h4 id="AdvancedVideos">Advanced Features Videos</h4>
                        <p>
                            This series of videos will show how to use the newly developed features for the application, including creating a simulation from a report, comparing simulations, and using the icon tray in reports and simulations.
                            <br />
                            Total Time: ~7 mins
                        </p>

                        <h5 id="AdvancedVideo1">Additional Features</h5>
                        <p>
                            This video will show what the icon buttons in the top right in reports and simulations do.
                            <br />
                            Time: ~2 mins
                        </p>
                        <p></p>
                        {/*<video width="960" height="540" controls>*/}
                        {/*    <source src={pdt_additional_features_video} type="video/mp4" />*/}
                        {/*</video>*/}

                        <h5 id="AdvancedVideo2">Competitive Alignment</h5>
                        <p>
                            This video will show how to use the competitive alignment chart in the reporting module.
                            <br />
                            Time: ~5 mins
                        </p>
                        <p></p>
                    {/*    <video width="960" height="540" controls>*/}
                    {/*        <source src={pdt_competitive_alignment_video} type="video/mp4" />*/}
                    {/*    </video>*/}
                    </div>
                    <div style={{ height: '50px' }}></div>
                </div>
            </Layout>


        );


    }
}