import React, { Component } from 'react';
import { BrandChannelSwitch } from '../../UIElements/Switches/BrandChannelSwitch'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
import { MultiGeoSelect } from '../../UIElements/Dropdowns/MultiGeoSelect'
import { MultiItemSelect } from '../../UIElements/Dropdowns/MultiItemSelect'
import { BrandItemsSwitch } from '../../UIElements/Switches/BrandItemsSwitch'
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { PromotedUnitsDollarsByWeekGraph } from '../../../Graphs/PromotedUnitsDollarsByWeekGraph'


export class PromotedUnitsDollarsByWeek extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        return (

            <div style={{ width: "100%", height: "100%" }}>
                <div className="row">
                    <div className="col-sm-3" align='left'>
                        <BrandChannelSwitch
                            state={this.props.state}
                            handleChange_channelviews={this.props.handleChange_channelviews}
                        />
                    </div>
                    {
                        this.props.state.channelviews ?
                            <div/>
                            :
                            <div className="col-sm-3 row" align='left'>

                                <BrandItemsSwitch
                                    state={this.props.state}
                                    handleChange_BrandsItemsSwitch={this.props.handleChange_BrandsItemsSwitch}
                                />
                            </div>
                    }
                    <div className="col-sm-3 row" align='left'>
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    

                    {
                        this.props.state.channelviews ?
                            <div className="col-sm-12" align='left'>
                                <div className="col-sm-12">
                                    <MultiGeoSelect
                                        state={this.props.state}
                                        changeSelectedChannelMulti={this.props.changeSelectedChannelMulti}
                                    />
                                </div>
                            </div>
                            :
                            this.props.state.viewItems ?
                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <MultiItemSelect
                                            state={this.props.state}
                                            changeSelectedItemMulti={this.props.changeSelectedItemMulti}
                                        />
                                    </div>
                                </div>
                                :
                                <div className="col-sm-12">
                                    <MultiBrandSelect
                                        state={this.props.state}
                                        changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                                    />
                                </div>
                    }
                    
                </div>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <PromotedUnitsDollarsByWeekGraph
                            state={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}