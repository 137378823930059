import * as Constant from '../../../../constants'

export default async function setNewVizOrderAndSetting(selectedOptions) {
    console.log(selectedOptions)
    
    let ordername = this.state.savedOrderValue_forDropdown.value
    let order = this.state.savedOrderOptions[ordername]
    let reportid = this.state.reportsettings.report_id

    //Change Setting for the Report....

    var orderReportMap = {}
    var orderReportMap_data
    let orderReportMap_response = await fetch(Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderReportMapping")
    if (orderReportMap_response.status === 200) {
        orderReportMap_data = await orderReportMap_response.json()
        orderReportMap = orderReportMap_data.setting_value
        console.log(orderReportMap)
    }


    //Remove the reportid from all other keys but the new order
    let alloptions = Object.keys(this.state.savedOrderOptions)
    let optionidx = alloptions.indexOf(ordername)
    alloptions.splice(optionidx, 1)

    alloptions.forEach(oldordername => {
        let oldlist = orderReportMap[oldordername] || []
        let reportidx = oldlist.indexOf(reportid)
        console.log(reportidx)
        if (reportidx > -1) {
            oldlist.splice(reportidx, 1)
        }
        orderReportMap[oldordername] = oldlist
    })

    //Add the reportid to the new order
    let newlist = orderReportMap[ordername] || []    
    newlist.push(reportid)
    orderReportMap[ordername] = newlist
    

    //Set Settings
    async function setReportOrderMapping(orderReportMapping) {
        //Save orderReportMap to settings
        console.log('Saving Order Report Map')
        console.log(orderReportMapping)
        let setting = orderReportMapping
        console.log(JSON.stringify(setting))
        let url = Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderReportMapping"
        //let orderReportMap = this.state.orderReportMap || {}
        //console.log(JSON.stringify(orderReportMap_new));
        let settings_response = await fetch(url,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(setting)
            })

        var settings_obj
        if (settings_response.status === 200) {
            settings_obj = await settings_response.json()
            //openDialog('Configuration Saved', 'Configuration Successfully Saved.')
        }
        else {
            settings_obj = {}
            //openDialog('Configuration Error', 'There was an Error Saving the Configuration.')
        }
    }
    async function setScreenSetting(reportid, ordername) {
        let url = Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid + '/pdtReportOrder'
        console.log(JSON.stringify(ordername));
        let settings_response = await fetch(url,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(ordername)
            })
        var settings_obj
        if (settings_response.status === 200) {
            settings_obj = await settings_response.json()
        }
        else {
            settings_obj = {}
            alert('There was an Error Saving the filters')
        }

        return settings_response.status
    }

    await setReportOrderMapping(orderReportMap)
    await setScreenSetting(reportid, ordername)
    //Set State
    this.setState({ order, screenOrder: ordername })
    this.openChangeVizzes()

}