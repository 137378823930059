import * as Constant from '../constants'

export async function loadAsync(method, url, body, refresh_time, state_messages_name, state_isactive_name, on_error, on_success) {
    const start = performance.now()
    console.log('Starting async task with request ' + method + ' ' + url)

    this.setState({
        [state_isactive_name]: true,
        [state_messages_name]: []
    })

    let async_submit_status
    if (method === 'GET' || body === null) {
        async_submit_status = await fetch(url,
            {
                method: method,
                headers: Constant.json_headers
            })
    } else {
        async_submit_status = await fetch(url,
            {
                method: method,
                headers: Constant.json_headers,
                body: JSON.stringify(body)
            })
    }

    if (async_submit_status.status === 200) {
        this.setState({ [state_isactive_name]: false })
        console.log('Async job successful on submission')
        if (on_success !== null && on_success !== undefined) {
            this.on_success = on_success.bind(this)
            this.on_success([], 0)
        }
    } else if (async_submit_status.status === 202) {
        let async_status_url = Constant.baseurl.slice(0, -1) + new URL(async_submit_status.headers.get('Location'), Constant.baseurl).pathname
        console.log('Getting Task Status')
        console.log(async_status_url)

        var interval_id = setInterval(async function () {
            console.log('Checking async status after ' + refresh_time + ' sec')
            let async_status = await fetch(async_status_url)
            let async_messages = await async_status.json()

            // If the job is still running, just update the current status text
            if (async_status.status === 202) {
                this.setState({ [state_messages_name]: async_messages.partial_status })
                return
            }

            // Else the job has completed -- either successfully or with error
            clearInterval(interval_id)
            this.setState({ [state_isactive_name]: false, [state_messages_name]: async_messages })
            let job_duration = Number((performance.now() - start) / 1000.).toFixed(1)

            if (async_status.status === 200) {
                console.log('Async job successful after ' + job_duration + ' sec')
                if (on_success !== null && on_success !== undefined) {
                    this.on_success = on_success.bind(this)
                    this.on_success(async_messages, job_duration)
                }
            } else {
                console.log('Async job failed after ' + job_duration + ' sec')
                if (on_error !== null && on_error !== undefined) {
                    this.on_error = on_error.bind(this)
                    this.on_error(async_status.status, async_messages, job_duration)
                }
            }
        }.bind(this), refresh_time * 1000)
    } else {
        console.log('There was an error submitting the task')
        this.setState({ [state_isactive_name]: false })
    }
}
