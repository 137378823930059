import { reducer } from '../Utilities/Reducer'
import { isROM } from '../Utilities/IsROM'
import findPercentile from '../Utilities/FindPercentile'
import { saveAs } from "file-saver";
const ExcelJS = require('exceljs');
export default async function exportItemWeeklyData(event) {

    function setWidthAndAlignment(worksheet, colnum, widthnum) {
        var Col
        Col = worksheet.getColumn(colnum);
        Col.width = widthnum;
        Col.eachCell(function (cell, rowNumber) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        })
    }

    console.log('Export Started')
    //Variables
    let zero_object = {
        'basedollars': 0,
        'baseunits': 0,
        'carrieddoors': 0,
        'discountdollars': 0,
        'incrementaldollars': 0,
        'incrementalunits': 0,
        'nonpromoteddollars': 0,
        'nonpromotedunits': 0,
        'promoteddollars': 0,
        'promotedunits': 0,
        'revopportunity': 0,
        'subsidizedbasedollars': 0,
        'subsidizedbaseunits': 0,
        'totaldollars': 0,
        'totaldoors_s1': 0,
        'totaldoors_s1_segment': 0,
        'totaldoors_s2': 0,
        'totaldoors_s2_segment': 0,
        'totalunits': 0,
        'totalunits_withoutno': 0,
        'unsubsidizedbasedollars': 0,
        'unsubsidizedbaseunits': 0,
        'weightedasp': 0,
        'weightedbp': 0,
        'weightedbp_withoutno': 0,
        'weightedpctdisc': 0,
        'weightedpctdisc_withoutno': 0,

    }

    var distinctdates = this.state.distinctdates
    let item = this.state.selectedItem_new
    let outlets = this.state.outlets
    let rom_outlet_idx = outlets.findIndex(isROM)
    let retailer_outlet_idx = 1
    if (rom_outlet_idx === 1) {
        retailer_outlet_idx = 0
    }    

    let pricesobj_ROM = this.state.histdataItem_outlet_weekly[item][outlets[rom_outlet_idx]]    
    let pricesobj_RETAILER = this.state.histdataItem_outlet_weekly[item][outlets[retailer_outlet_idx]]

    //Fill in Dates...
    distinctdates.forEach(date => {
        pricesobj_ROM[date] = pricesobj_ROM[date] || { 0: 0 }
        pricesobj_RETAILER[date] = pricesobj_RETAILER[date] || { 0: 0 }
    })

    let outlet_data_weekly_retailer = this.state.outlet_data_weekly_item[outlets[retailer_outlet_idx]][item]
    let outlet_data_weekly_rom = this.state.outlet_data_weekly_item[outlets[rom_outlet_idx]][item] 

    distinctdates.forEach(date => {
        outlet_data_weekly_retailer[date] = outlet_data_weekly_retailer[date] || zero_object
        outlet_data_weekly_rom[date] = outlet_data_weekly_rom[date] || zero_object
    })

    let percentiles = [.05, .10, .15, .20, .25, .30, .35, .40, .45, .50, .55, .60, .65, .70, .75, .80, .85, .90, .95]
    
    var storegroup = this.state.filter_object['geo']
    var retailername = outlets[retailer_outlet_idx]
    var brand = this.state.item_brand_lookup[item]
    var headers = [[
        { type: 'string', value: 'Store Group' }
        , { type: 'string', value: 'Week Ending' }
        , { type: 'string', value: 'Brand' }
        , { type: 'string', value: 'Product Description' }
        , { type: 'string', value: 'Units Sold - ' + retailername }
        , { type: 'string', value: 'Units Sold - ROM' }
        , { type: 'string', value: 'Percentile Price - 05%' }
        , { type: 'string', value: 'Percentile Price - 10%' }
        , { type: 'string', value: 'Percentile Price - 15%' }
        , { type: 'string', value: 'Percentile Price - 20%' }
        , { type: 'string', value: 'Percentile Price - 25%' }
        , { type: 'string', value: 'Percentile Price - 30%' }
        , { type: 'string', value: 'Percentile Price - 35%' }
        , { type: 'string', value: 'Percentile Price - 40%' }
        , { type: 'string', value: 'Percentile Price - 45%' }
        , { type: 'string', value: 'Percentile Price - 50%' }
        , { type: 'string', value: 'Percentile Price - 55%' }
        , { type: 'string', value: 'Percentile Price - 60%' }
        , { type: 'string', value: 'Percentile Price - 65%' }
        , { type: 'string', value: 'Percentile Price - 70%' }
        , { type: 'string', value: 'Percentile Price - 75%' }
        , { type: 'string', value: 'Percentile Price - 80%' }
        , { type: 'string', value: 'Percentile Price - 85%' }
        , { type: 'string', value: 'Percentile Price - 90%' }
        , { type: 'string', value: 'Percentile Price - 95%' }
        , { type: 'string', value: retailername + ' Promoted Price' }
        , { type: 'string', value: retailername + ' NonPromoted Price' }
        , { type: 'string', value: retailername + ' Average Price' }
        , { type: 'string', value: 'ROM Promoted Price' }
        , { type: 'string', value: 'ROM NonPromoted Price' }
        , { type: 'string', value: 'ROM Average Price' }
    ]]

    //Build Data
    var datarows = distinctdates.map(date => {

        //Calculate Average Price for Retailer....
        let totalPrice_retailer = 0
        Object.keys(pricesobj_RETAILER[date]).forEach(price => {
            totalPrice_retailer += price * pricesobj_RETAILER[date][price]
        })

        let totalPrice_rom = 0
        Object.keys(pricesobj_ROM[date]).forEach(price => {
            totalPrice_rom += price * pricesobj_ROM[date][price]
        })

        let numunits_retailer = Object.values(pricesobj_RETAILER[date]).reduce(reducer)
        let numunits_rom = Object.values(pricesobj_ROM[date]).reduce(reducer)

        let averageASP_retailer = totalPrice_retailer / numunits_retailer
        let averageASP_rom = totalPrice_rom / numunits_rom
        //Run through the distributions....

        let percentile_vals = percentiles.map(p => { return findPercentile(p, numunits_rom, pricesobj_ROM[date]) })

        let numsold_retailer = numunits_retailer
        let numsold_rom = numunits_rom
        let percentile_05 = percentile_vals[0]
        let percentile_10 = percentile_vals[1]
        let percentile_15 = percentile_vals[2]
        let percentile_20 = percentile_vals[3]
        let percentile_25 = percentile_vals[4]
        let percentile_30 = percentile_vals[5]
        let percentile_35 = percentile_vals[6]
        let percentile_40 = percentile_vals[7]
        let percentile_45 = percentile_vals[8]
        let percentile_50 = percentile_vals[9]
        let percentile_55 = percentile_vals[10]
        let percentile_60 = percentile_vals[11]
        let percentile_65 = percentile_vals[12]
        let percentile_70 = percentile_vals[13]
        let percentile_75 = percentile_vals[14]
        let percentile_80 = percentile_vals[15]
        let percentile_85 = percentile_vals[16]
        let percentile_90 = percentile_vals[17]
        let percentile_95 = percentile_vals[18]

        var nonpromoprice_retailer, promoprice_retailer, nonpromoprice_rom, promoprice_rom

        if (this.state.outlet_data_weekly_item[outlets[retailer_outlet_idx]][item][date]['nonpromotedunits'] > 0) {
            nonpromoprice_retailer = (outlet_data_weekly_retailer[date]['nonpromoteddollars'] / outlet_data_weekly_retailer[date]['nonpromotedunits']) || 0
        }
        else {
            nonpromoprice_retailer = 0
        }

        if (this.state.outlet_data_weekly_item[outlets[retailer_outlet_idx]][item][date]['promotedunits'] > 0) {
            promoprice_retailer = (outlet_data_weekly_retailer[date]['promoteddollars'] / outlet_data_weekly_retailer[date]['promotedunits']) || 0
        }
        else {
            promoprice_retailer = 0
        }

        if (this.state.outlet_data_weekly_item[outlets[rom_outlet_idx]][item][date]['nonpromotedunits'] > 0) {
            nonpromoprice_rom = (outlet_data_weekly_rom[date]['nonpromoteddollars'] / outlet_data_weekly_rom[date]['nonpromotedunits']) || 0
        }
        else {
            nonpromoprice_rom = 0
        }

        if (this.state.outlet_data_weekly_item[outlets[rom_outlet_idx]][item][date]['promotedunits'] > 0) {
            promoprice_rom = (outlet_data_weekly_rom[date]['promoteddollars'] / outlet_data_weekly_rom[date]['promotedunits']) || 0
        }
        else {
            promoprice_rom = 0
        }

        let asp_retailer = averageASP_retailer
        let asp_rom = averageASP_rom


        return [
            { type: 'string', value: storegroup }
            , { type: 'string', value: date }
            , { type: 'string', value: brand }
            , { type: 'string', value: item }
            , { type: 'number', value: numsold_retailer }
            , { type: 'number', value: numsold_rom }
            , { type: 'number', value: percentile_05 }
            , { type: 'number', value: percentile_10 }
            , { type: 'number', value: percentile_15 }
            , { type: 'number', value: percentile_20 }
            , { type: 'number', value: percentile_25 }
            , { type: 'number', value: percentile_30 }
            , { type: 'number', value: percentile_35 }
            , { type: 'number', value: percentile_40 }
            , { type: 'number', value: percentile_45 }
            , { type: 'number', value: percentile_50 }
            , { type: 'number', value: percentile_55 }
            , { type: 'number', value: percentile_60 }
            , { type: 'number', value: percentile_65 }
            , { type: 'number', value: percentile_70 }
            , { type: 'number', value: percentile_75 }
            , { type: 'number', value: percentile_80 }
            , { type: 'number', value: percentile_85 }
            , { type: 'number', value: percentile_90 }
            , { type: 'number', value: percentile_95 }
            , { type: 'number', value: promoprice_retailer.toFixed(2) }
            , { type: 'number', value: nonpromoprice_retailer.toFixed(2) }
            , { type: 'number', value: asp_retailer.toFixed(2) }
            , { type: 'number', value: promoprice_rom.toFixed(2) }
            , { type: 'number', value: nonpromoprice_rom.toFixed(2) }
            , { type: 'number', value: asp_rom.toFixed(2) }
        ]
    })


    var xlsxRows = headers.concat(datarows)

    //Use the Existing Rows to add to a new worksheet


    var name = 'Weekly Price Distribution Data'
    var newdate = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
    datestring = datestring.replaceAll(',', '')


    let filenameexcel = name + ' - Extracted - ' + datestring
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Metrics');
    const worksheet = workbook.getWorksheet('Metrics');

    xlsxRows.forEach((row, rowidx) => {
        //console.log(row)
        let rowvals = row.map((cell, cellidx) => {
            if (cellidx >= 6 && rowidx >= 1) {
                return parseFloat(cell.value)
            }
            else {
                return cell.value
            }

        })
        let rownum = rowidx + 1
        worksheet.insertRow(rownum, rowvals);
    })

    //Format Columns as Units

    worksheet.getColumn(5).numFmt = '##,#';
    worksheet.getColumn(6).numFmt = '##,#';

    //Format Columns G - AE as Price
    let startingcol = 7
    let endingcol = 31
    while (startingcol <= endingcol) {
        worksheet.getColumn(startingcol).numFmt = this.state.currencysymbol + '#,##.00'
        startingcol += 1
    }

    //Add Selection Information
    const newsheet = workbook.addWorksheet('Selection Criteria');
    const newworksheet = workbook.getWorksheet('Selection Criteria')

    let titles = Object.keys(this.state.chartmetadata)
    let values = Object.values(this.state.chartmetadata)

    newworksheet.getColumn(1).values = titles
    newworksheet.getColumn(2).values = values

    setWidthAndAlignment(newworksheet, 1, 22)


    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, filenameexcel + fileExtension);


}