import React, { Component } from 'react';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

const indicatorSeparatorStyle = {
    alignSelf: 'stretch',
    backgroundColor: '#FFF',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

export class DropdownIndicator extends Component {
    static displayName = DropdownIndicator.name;
    //Need to create a component that will be the dropdown indicator
    render() {
        return (
            <ArrowDropDownRoundedIcon  style={{
                fontSize: '2.5rem', color:'#565A5C' }} />
        );
    }
}

export class IndicatorSeparator  extends Component {
    static displayName = DropdownIndicator.name;
    //Need to create a component that will be the dropdown indicator
    render() {
        return (
            <span style={indicatorSeparatorStyle}/>
        );
    }
}