import React, { Component } from 'react';
import LiftEffPBRByDiscBand from '../../../Graphs/LiftEffPBRByDiscBand'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';

export class LiftEffPBRByDiscBandAggregate extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            console.log('Rendering')
            return true
        }

        else {
            console.log('Not Rendering')
            return false
        }
    }

    render() {

        return (
            <div style={{ width: "100%", height: "100%"}}>
                
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                
                
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ height: '100%', width: "100%"}} className="col-sm-12">
                        {
                            this.props.state.nodiscSwitchCheck ?
                                <LiftEffPBRByDiscBand
                                    chart_title_info={this.props.state.chart_title_info}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    discountbuckets={this.props.state.discountbuckets.slice(1)}
                                    discountband_data={this.props.state.discountband_data}
                                    selectedBrand={'Total Selection'}
                                    showDataTable={this.props.state.showDataTable}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    metadata={this.props.state.chartmetadata_singlebrand}
                                />
                                :
                                <LiftEffPBRByDiscBand
                                    chart_title_info={this.props.state.chart_title_info}
                                    attr_caption_text={this.props.state.attr_caption_text}
                                    discountbuckets={this.props.state.discountbuckets}
                                    discountband_data={this.props.state.discountband_data}
                                    selectedBrand={'Total Selection'}
                                    showDataTable={this.props.state.showDataTable}
                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                    metadata={this.props.state.chartmetadata_singlebrand}
                                />
                        }
                    </div>
                </div>
            </div>

        )
    }
}