export default function setAttrsForGraphs(selectedOptions) {
    var graph_selected_attributes = []
    var graph_selected_attributes_labels = []
    let curr_labels = this.state.graph_selected_attributes_labels

    if (selectedOptions && selectedOptions.length > 0) {
        if (curr_labels.length === 1 && curr_labels[0].value === 'All') {
            graph_selected_attributes = []
            selectedOptions.forEach(option => { if (option.value !== 'All') { graph_selected_attributes.push(option.value) } })
            graph_selected_attributes_labels = graph_selected_attributes.map(attr => { return { 'label': this.state.attributenamelabels[attr], 'value': attr } })

        }

        else if (selectedOptions.map(option => option.value).includes('All')) {
            graph_selected_attributes = Object.keys(this.state.attributes)
            graph_selected_attributes_labels = [{ 'label': 'All', 'value': 'All' }]
        }
        else {
            graph_selected_attributes = selectedOptions.map(option => option.value)
            graph_selected_attributes_labels = (selectedOptions.map(option => option.value)).map(attr => { return { 'label': this.state.attributenamelabels[attr], 'value': attr } })
        }

    }
    else {
        graph_selected_attributes = []
        graph_selected_attributes_labels = []
    }

    this.setState({ graph_selected_attributes, graph_selected_attributes_labels, viewattrnum: 0 })

}