export default function showHideReportInsights () {
    var insightsState = this.state.displayInsights;
    if (insightsState === 'none') {

        this.setState({ displayInsights: 'flex' })
    }
    else if (insightsState === 'flex') {

        this.setState({ displayInsights: 'none' })
    }
}