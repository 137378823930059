import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class MetricsComparisonsSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle">Select Metrics to Compare</span>
                <Select
                    options={this.props.state.metricsforcomparerom.map(metric => { return { 'label': metric, 'value': metric } })}
                    placeholder={'Select Metrics to Compare'}
                    isMulti
                    onChange={(options) => this.props.changeMetricsCompareROM(options)}
                    styles={Constant.dropdownStyles}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}