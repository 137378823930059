import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { UnitsDollarSwitch } from '../../../UIElements/Switches/UnitsDollarSwitch'
import { BaseIncPromoNonPromoRadio } from '../../../UIElements/Radios/BaseIncPromoNonPromoRadio'
import { MetricLevelOptionsBaseInc } from '../../../UIElements/Dropdowns/MetricLevelOptionsBaseInc'
//import { FilterBarReportViews } from '../../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../../UIElements/Filters/FilterBarReportViewsFunctional';
import { RankOptions } from '../../../UIElements/Dropdowns/RankOptions'
import { PriceBandDefIcon } from '../../../UIElements/IconButtons/PriceBandDefIcon'
import { AttributeGroupDefIcon } from '../../../UIElements/IconButtons/AttrGroupDefIcon';
import { DownloadPrevYearsCheckbox } from '../../../UIElements/Checkboxes/DownloadPrevYearsCheckbox'
import { DownloadAggregateDataAll } from '../../../UIElements/IconButtons/DownloadAggregateDataAll'
import { StackedBar } from '../../../../Graphs/NewGraphs/StackedBarGraph'
import Checkbox from '@material-ui/core/Checkbox';
import * as Constant from '../../../../constants'

export class BaseIncrementalPromoNonPromoAggregate extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        function filterArray(array, start, end) {
            let new_array = array.map((value, idx) => {
                if (idx >= start && idx < end) {
                    return value
                }
                else {
                    return null
                }
            }).filter(x => x !== null)
            return new_array
        }

        var nonAttrVals = [
            'Total Selection'
            ,'Total'
            ,'Brand'
            ,'Channel'
            ,'Outlet'
            ,'Price Band'
            ,'Item'
            ,'Segment 1'
            ,'Segment 2'
            ,'Segment 3'
            ,'Segment 4'
        ]

        var showAttrGroups = false

        console.log(this.props.state.selectedLevel_BaseInc.value)
        console.log(!nonAttrVals.includes(this.props.state.selectedLevel_BaseInc.value))

        if (!nonAttrVals.includes(this.props.state.selectedLevel_BaseInc.value)) {
            showAttrGroups = true
        }

        //create series list....
        //Use Aggregated Data
        var seriesList, ChartTitle, categories, unitsvar
        var categories_dollars = this.props.state.agg_categories_dollars || []
        var categories_units = this.props.state.agg_categories_units || []
        var RankStart = this.props.state.RankStart || 0
        var RankEnd = this.props.state.RankEnd || 10

        var dataset = this.props.state.aggregatedData
        if (this.props.state.use_attr_groups && (!nonAttrVals.includes(this.props.state.selectedLevel_BaseInc.value))) {
            dataset = this.props.state.attr_groups_data
            categories_dollars = this.props.state.agg_categories_attr_groups_dollars
            categories_units = this.props.state.agg_categories_attr_groups_units
        }

        if (this.props.state.promoselected) {
            ChartTitle = 'Non-Promoted & Promoted '
            if (this.props.state.indexSwitchCheckDollars) {
                categories = filterArray(categories_dollars, RankStart, RankEnd)
                unitsvar = this.props.state.currencyType
                seriesList = [
                    {
                        name: 'Non-Promoted',
                        data: filterArray(categories_dollars.map(key => (dataset[key] || {})['nonpromoteddollars']), RankStart, RankEnd),
                        color: Constant.graph_colors[0]
                    },
                    {
                        name: 'Promoted',
                        data: filterArray(categories_dollars.map(key => (dataset[key] || {})['promoteddollars']), RankStart, RankEnd),
                        color: Constant.graph_colors[1]
                    }
                ]
            }
            else {
                categories = filterArray(categories_units, RankStart, RankEnd)
                unitsvar = this.props.state.unitsvar
                seriesList = [
                    {
                        name: 'Non-Promoted',
                        data: filterArray(categories_units.map(key => (dataset[key] || {})['nonpromotedunits']), RankStart, RankEnd),
                        color: Constant.graph_colors[0]
                    },
                    {
                        name: 'Promoted',
                        data: filterArray(categories_units.map(key => (dataset[key] || {})['promotedunits']), RankStart, RankEnd),
                        color: Constant.graph_colors[1]
                    }
                ]
            }
        }
        else {
            ChartTitle = 'Base & Incremental '
            if (this.props.state.indexSwitchCheckDollars) {
                categories = filterArray(categories_dollars, RankStart, RankEnd)
                unitsvar = this.props.state.currencyType
                seriesList = [
                    {
                        name: 'UnSubsidized Base',
                        data: filterArray(categories_dollars.map(key => (dataset[key] || {})['unsubsidizedbasedollars']), RankStart, RankEnd),
                        color: Constant.graph_colors[0]
                    },
                    {
                        name: 'Subsidized Base',
                        data: filterArray(categories_dollars.map(key => (dataset[key] || {})['subsidizedbasedollars']), RankStart, RankEnd),
                        color: Constant.graph_colors[1]
                    },
                    {
                        name: 'Incremental',
                        data: filterArray(categories_dollars.map(key => (dataset[key] || {})['incrementaldollars']), RankStart, RankEnd),
                        color: '#fdd900'
                    },
                ]
            }
            else {
                categories = filterArray(categories_units, RankStart, RankEnd)
                unitsvar = this.props.state.unitsvar
                seriesList = [
                    {
                        name: 'UnSubsidized Base',
                        data: filterArray(categories_units.map(key => (dataset[key] || {})['unsubsidizedbaseunits']), RankStart, RankEnd),
                        color: Constant.graph_colors[0]
                    },
                    {
                        name: 'Subsidized Base',
                        data: filterArray(categories_units.map(key => (dataset[key] || {})['subsidizedbaseunits']), RankStart, RankEnd),
                        color: Constant.graph_colors[1]
                    },
                    {
                        name: 'Incremental',
                        data: filterArray(categories_units.map(key => (dataset[key] || {})['incrementalunits']), RankStart, RankEnd),
                        color: '#fdd900'
                    },
                ]
            }
        }

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div className="col-sm-4" >
                        <MetricLevelOptionsBaseInc
                            state={this.props.state}
                            changeSelectedLevel_BaseInc={this.props.changeSelectedLevel_BaseInc}
                        />
                    </div>
                    <div className="col-sm-2 row" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    <div className="col-sm-6 row">
                        <BaseIncPromoNonPromoRadio
                            state={this.props.state}
                            handleChangeRadioBasePromo={this.props.handleChangeRadioBasePromo}
                            handleToggle_stackingType={this.props.handleToggle_stackingType}
                        />
                    </div>
                    {(this.props.state.RankOptions || []).length > 1 ?
                        <Fragment>
                            <div align='left' className="col-sm-3" >
                                <RankOptions
                                    state={this.props.state}
                                    changeRank={this.props.changeRank}
                                />
                            </div>
                            {
                                //(this.props.state.showYOYViews && (this.props.state.level_baseinc === 'Brand' || this.props.state.level_baseinc === 'Item')) ?
                                (this.props.state.showYOYViews) ?
                                    <div className='col-sm-8' align='right'>
                                        {this.props.state.showLastViews ? 'Download Previous Years with Current Year?' : 'Download Previous Year with Current Year?'}
                                        <DownloadPrevYearsCheckbox
                                            state={this.props.state}
                                            handleToggle_downloadAllYOY={this.props.handleToggle_downloadAllYOY}
                                        />
                                    </div>
                                    :
                                    <div className='col-sm-8'></div>
                            }

                            {this.props.state.level_baseinc === 'Brand' ?
                                <div>
                                {
                                    this.props.state.downloadAllYOY === true ?
                                    <div align='right' className="col-sm-1" style={{ padding: '0px' }} >
                                        <DownloadAggregateDataAll
                                            title="Download Data to Compare Current Period vs Prior Period"
                                            downloadfunc={this.props.download_alldata_YOY}
                                            level='brand'
                                            metrics='basepromo'
                                        />
                                    </div>
                                    :
                                    <div align='right' className="col-sm-1" style={{ padding: '0px' }} >
                                        <DownloadAggregateDataAll
                                            title="Download All Data for this View"
                                            downloadfunc={this.props.download_alldata}
                                            level='brand'
                                            metrics='basepromo'
                                        />
                                    </div>
                                    }
                                </div>
                                :
                                this.props.state.level_baseinc === 'Item' ?
                                    <div>
                                    {
                                        this.props.state.downloadAllYOY === true ?
                                            <div align='right' className="col-sm-1" style={{ padding: '0px' }} >
                                                <DownloadAggregateDataAll
                                                    title="Download Data to Compare Current Period vs Prior Period"
                                                    downloadfunc={this.props.download_alldata_YOY}
                                                    level='item'
                                                    metrics='basepromo'
                                                />
                                            </div>
                                            :
                                            <div align='right' className="col-sm-1" style={{ padding: '0px' }} >
                                                <DownloadAggregateDataAll
                                                    title="Download All Data for this View"
                                                    downloadfunc={this.props.download_alldata}
                                                    level='item'
                                                    metrics='basepromo'
                                                />
                                            </div>
                                    }
                                    </div>
                               :
                                    <div>
                                        {
                                            this.props.state.downloadAllYOY === true ?
                                                <div align='right' className="col-sm-1" style={{ padding: '0px' }} >
                                                    <DownloadAggregateDataAll
                                                        title="Download Data to Compare Current Period vs Prior Period"
                                                        downloadfunc={this.props.download_alldata_YOY}
                                                        level={this.props.state.level_baseinc}
                                                        metrics='basepromo'
                                                    />
                                                </div>
                                                :
                                                <div align='right' className="col-sm-1" style={{ padding: '0px' }} >
                                                    <DownloadAggregateDataAll
                                                        title="Download All Data for this View"
                                                        downloadfunc={this.props.download_alldata}
                                                        level={this.props.state.level_baseinc}
                                                        metrics='basepromo'
                                                    />
                                                </div>
                                        }
                                    </div>
                            }
                        </Fragment>
                        :
                        <div/>
                    }
                    {this.props.state.level_baseinc === 'Price Band' ?
                        <div align='left' className="col-sm-3" >
                            <PriceBandDefIcon
                                state={this.props.state}
                            />
                        </div>
                        :
                        <div />
                    }
                </div>
                {showAttrGroups ?
                    <div className="row">
                        {this.props.state.use_attr_groups ?
                            <div className='col-sm-4'>
                                <AttributeGroupDefIcon
                                    state={this.props.state}
                                />
                            </div>
                            :
                            <div />
                        }
                        <div className='col-sm-4'>
                            <ThemeProvider theme={Constant.muiTheme}>
                                <Checkbox
                                    checked={this.props.state.use_attr_groups}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': 'Use Groups?' }}
                                    onChange={this.props.handleToggle_useAttrGroups}
                                />
                            </ThemeProvider>
                            <span style={{ lineHeight: '5' }} >Use Groups?</span>
                        </div>
                    </div>
                    :
                    <div />
                }
                <div className="row">
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <Fragment>
                            <StackedBar
                                seriesList={seriesList}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                showDataTable={this.props.state.showDataTable}
                                unitsvar={unitsvar}
                                categories={categories}
                                attr_caption_text={this.props.state.attr_caption_text}
                                chart_title_info={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.selectedBrand === 'Total Selection' ?
                                            this.props.state.chart_title_info_nobrands + ' Brand(s) Selected: ' + this.props.state.brandsstring
                                            :
                                            this.props.state.chart_title_info_nobrands + ' Brand Selected: ' + this.props.state.selectedBrand
                                        :
                                        this.props.state.chart_title_info
                                }
                                level={this.props.state.level_baseinc}
                                metadata={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.chartmetadata_singlebrand
                                        :
                                        this.props.state.chartmetadata
                                }
                                height={.5}
                                currencycode={this.props.state.currencycode}
                                stackingType={this.props.state.stackingType}
                                ChartTitle={ChartTitle}
                            />
                        </Fragment>
                    </div>
                </div>
            </div>
        )
    }
}
