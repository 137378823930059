import React, { Component, Fragment, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Popover, IconButton, Box } from '@mui/material';
import * as Constant from '../../../constants';

export default function AttributeGroupDefIconFunctional({customUserAttrGroups, attributenamelabels}) {
    
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = anchorEl != null

    return (
        <Box>
            <span style={{ fontSize: '.94rem', lineHeight: '2.5', padding: '5px' }}>
                <IconButton onClick={handleClick}>
                    <InfoRoundedIcon
                        style={{ fontSize: '1.875rem'}}
                    />
                </IconButton>
                Click Icon for Attribute Group Definitions
            </span>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <Box sx={{maxHeight: "500px", overflow: "auto", padding: "8px"}}>
                {Object.keys(customUserAttrGroups).filter(attrId => customUserAttrGroups[attrId].length > 0).map(attrId => (
                    <Fragment key={attrId}>
                        <h4>{' Attribute Group Definitions for ' + attributenamelabels[attrId]}</h4>
                    
                        {customUserAttrGroups[attrId].map(group => {
                            return <ul key={group.groupname}> <h5>{group.groupname}</h5>
                                {group.attr_vals.map(val => {
                                    return <li> {val} </li>
                                })}
                            </ul>
                        })}
                    </Fragment>
                ))}
                </Box>
            </Popover>
        </Box>
    )
    
}