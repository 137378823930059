import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateChannelData(filters) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let filters_for_pull = assignFilterLevels(filters, ['geogdesc'])
    let url_for_pull = Constant.baseapiurl + "ReportData/Filtered/Level1Data/" + reportid + extraparam

    // Perform the Pull
    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_for_pull, 'method': 'POST', 'body': filters_for_pull }
    ], 'UpdateChannelData.js updateChannelData()', 'Channel')
    let aggregated_data = my_results['ReturnList'][0]
    let agg_categories_dollars = aggregated_data['level1_ordered_dollars'] || []
    let agg_categories_units = aggregated_data['level1_ordered_units'] || []
    let agg_channel_data = aggregated_data['level1_data'] || {}
     
    let TotalBarLookup_Channel = {}
    let TotalBarLookup_Dollars_Channel = {}

    let channels_sorted
    if (this.state.indexSwitchCheckDollars) {
        channels_sorted = agg_categories_dollars
    } else {
        channels_sorted = agg_categories_units
    }

    let channels_sorted_dollars = agg_categories_dollars

    channels_sorted.forEach(channel => {
        TotalBarLookup_Channel[channel] = (agg_channel_data[channel] || {})['totalunits']
    })

    channels_sorted.forEach(channel => {
        TotalBarLookup_Dollars_Channel[channel] = (agg_channel_data[channel] || {})['totaldollars']
    })

    let selectedChannels = channels_sorted.slice(0, 10)

    // SetState
    console.log('Aggregated Data State Update')
    this.setState({
        selectedChannels,
        channels_sorted,
        channels_sorted_dollars,
        TotalBarLookup_Channel,
        TotalBarLookup_Dollars_Channel
    })

    // Return a completion...
    return 'Completed'
}
