import * as Constant from '../../../constants'
import createRankOptions from '../Utilities/CreateRankOptions'

export default async function updateAggregatedData(filters) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let level_baseinc = this.state.level_baseinc || 'Total Selection'

    function assign_levels(filters, levels) {
        console.log(filters)
        console.log(levels)
        let newfilters = JSON.parse(JSON.stringify(filters));
        levels.forEach((level, idx) => {
            let keyname = 'level' + String((idx + 1))
            newfilters[keyname] = level
        })

        return newfilters
        console.log(newfilters)
    }

    let levels_lookup = {
        'Total Selection': 'total',
        'Brand': 'brand',
        'Channel': 'geogdesc',
        'Outlet': 'outlet',
        'Price Band': 'pricebandgroup',
        'Item': 'proddesc',
        'Segment 1': 'segment1_name',
        'Segment 2': 'segment2_name',
        'Segment 3': 'segment3_name',
        'Segment 4': 'segment4_name'
    }

    // Add Attributes to lookup
    Object.keys(this.state.attributes).forEach(attr => {
        levels_lookup[attr] = attr
    })

    let levels_label_lookup = {
        'Total Selection': 'Total Selection',
        'Brand': 'Brand',
        'Channel': 'Channel',
        'Outlet': 'Outlet',
        'Price Band': 'Price Band',
        'Item': 'Item',
        'Segment 1': this.state.segment1label,
        'Segment 2': this.state.segment2label,
        'Segment 3': this.state.segment3label,
        'Segment 4': this.state.segment4label
    }
    console.log('levels_label_lookup',levels_label_lookup)

    // Add Attributes to lookup
    Object.keys(this.state.attributes).forEach(attr => {
        levels_label_lookup[attr] = this.state.attributenamelabels[attr]
    })

    // pull categories and level1data
    var url_for_pull, filters_for_pull
    if ((level_baseinc) === 'Total Selection') {
        url_for_pull = Constant.baseapiurl + "ReportData/Filtered/Level0Data/" + reportid + extraparam
        filters_for_pull = filters
    }
    else {
        url_for_pull = Constant.baseapiurl + "ReportData/Filtered/Level1Data/" + reportid + extraparam
        filters_for_pull = assign_levels(filters, [levels_lookup[level_baseinc]])
    }

    // Perform the Pull
    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_for_pull, 'method': 'POST', 'body': filters_for_pull }
    ], 'updateAggregatedData.js updateAggregatedData()', 'Aggregated')
    let aggregated_data = my_results['ReturnList'][0]
    console.log(my_results)

    // Get Data and Categories...
    console.log(aggregated_data)
    var aggregatedData, agg_categories_dollars, agg_categories_units
    if (level_baseinc === 'Total Selection') {
        aggregatedData = {
            'Total Selection': aggregated_data['level0_data']
        }
        agg_categories_dollars = ['Total Selection']
        agg_categories_units = ['Total Selection']
    }
    // Use filtered Price Bands instead of default priceBandOptions
    //else if (level_baseinc === 'Price Band') {
    //    aggregatedData = aggregated_data['level1_data'] || {}
    //    agg_categories_dollars = this.state.priceBandOptions.map(pband=>pband.label)
    //    agg_categories_units = this.state.priceBandOptions.map(pband => pband.label)
    //}
    else {
        aggregatedData = aggregated_data['level1_data'] || {}
        agg_categories_dollars = aggregated_data['level1_ordered_dollars'] || []
        agg_categories_units = aggregated_data['level1_ordered_units'] || []
    }

    console.log(aggregatedData)
    console.log(levels_lookup[level_baseinc])
    let attr_groups_data = {}
    let agg_categories_attr_groups_dollars = []
    let agg_categories_attr_groups_units = []
    if (Object.keys(this.state.attributes).includes(levels_lookup[level_baseinc])) {
        console.log('Special handling for attributes is needed!')
        Object.keys(this.state.selected_attr_groups).forEach(attr => {
            if (attr === levels_lookup[level_baseinc]) {
                let groups = this.state.selected_attr_groups[attr]
                groups.forEach(group => {
                    let groupname = group.label
                    let groupvalues = group.value
                    let attr_values = Object.keys(aggregatedData)
                    attr_values.forEach(value => {
                        if (groupvalues.includes(value)) {
                            // add to attr_groups_data
                            attr_groups_data[groupname] = attr_groups_data[groupname] || {}
                            let keys = Object.keys(aggregatedData[value])
                            keys.forEach(key => {
                                let existingval = attr_groups_data[groupname][key] || 0
                                let newval = existingval + aggregatedData[value][key]
                                attr_groups_data[groupname][key] = newval
                            })
                        }
                    })
                })
            }
        })

        // TO DO -- Sort keys by value
        agg_categories_attr_groups_dollars = Object.keys(attr_groups_data)
        agg_categories_attr_groups_units = Object.keys(attr_groups_data)
    }

    // Perform some special handling for attribute data to get groups working...

    // Based on the category dollars, change the rank...
    var RankOptions = createRankOptions(agg_categories_dollars.length, levels_label_lookup[level_baseinc] + ' Ranked ');

    // SetState
    console.log('Aggregated Data State Update')
    this.setState({
        aggregatedData,
        agg_categories_dollars,
        agg_categories_units,
        agg_categories_attr_groups_dollars,
        agg_categories_attr_groups_units,
        RankOptions,
        RankStart: 0,
        RankEnd: 10,
        selectedRank: RankOptions[0],
        attr_groups_data
    })

    // Return a completion...
    return 'Completed'
}
