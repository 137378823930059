import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

export class DownloadItemData extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <IconButton aria-label="close" color="inherit" style={{ paddingRight: '5px' }} onClick={(level, metrics) => this.props.download_data_itemmetrics_yoy()}>
                    <Tooltip title={this.props.title} placement="right">
                        <GetAppRoundedIcon fontSize="large" />
                    </Tooltip>
                </IconButton>
            </Fragment>
        )
    }
}