import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import CloseIcon from '@material-ui/icons/Close';
import openingscreen from "./images/PDT Tutorial/Opening Screen.png"
import highlightedbar from "./images/PDT Tutorial/HighlightedSideBar.png"
import existingreports from "./images/PDT Tutorial/Existing Reports.png"
import seeallreports from "./images/PDT Tutorial/SeeAllReports.png"
import createnewreport from "./images/PDT Tutorial/CreateNewReport.png"
import createreportform from "./images/PDT Tutorial/CreateReportForm.png"
import namehighlighted from "./images/PDT Tutorial/NameHighlighted.png"
import calendarselections from "./images/PDT Tutorial/Calendar Selections.png"
import dropdownstoregroup from "./images/PDT Tutorial/DropDownStoreGroup.png"
import saveandrun from "./images/PDT Tutorial/SaveAndRun.png"
import reportoutput from "./images/PDT Tutorial/ReportOutput.png"
import moreinfo from "./images/PDT Tutorial/More Info.png"

const tutorialdict = {
    0: {
        'image': openingscreen,
        'text1': 'Welcome to the Price and Discount Application. This initial tutorial will show some basic navigation of the application as well as show how to get access to more resources.',
        'text2': 'Use the Forward and Backwards Buttons to navigate through the tutorial.',
        'text3': 'You may exit the tutorial at anytime by hitting the Close button below the Forwards and Backwards Buttons.',
        'text4': 'You may review this tutorial at anytime by hitting the "View Tutorial" button on the Home Page.'
    },
    1: {
        'image': highlightedbar,
        'text1': 'The sidebar on the lefthand side of the application is how you can access various features of the application.',
        'text2': 'The sidebar has two menus that can be expanded for more information: Reports and Resources.  The home button will return you to the main landing page of the application.',
        'text3': 'The rest of this tutorial will walk through how to use the report menu so you can see all of your reports, as well as generate a new report.',
    },
    2: {
        'image': existingreports,
        'text1': 'Clicking on the Reports Icon will expand the menu, showing a list of all reports available to you.  The reports are listed with favorites first, then in descending order from when they were created.',
        'text2': 'To favorite or unfavorite a report, simply click on the star next to the report.',
        'text3': 'Clicking on a report name will take you to the output page for that particular report.',  
        'text4': 'If you wish to close the report menu, just click on the report icon again, and the menu will close.',    
    },
    3: {
        'image': seeallreports,
        'text1': 'It might be possible that you have access to a report, but it is not appearing in the menu.  This is because the menu is limited to the first 15 reports you have access to for space considerations.',
        'text2': 'If you do not see a report you are looking for, you can click on the "All Reports" menu option.  This will direct you to a page with a table where all of your reports are listed. ',
        'text3': 'The table of reports can be sorted by name, dataset, brands, and date created.  On the page, a link will be provided to the report output and you can click on the link to go to the report.  You can also favorite the report so it appears at the top of your menu.',        
    },
    4: {
        'image': createnewreport,
        'text1': 'If you do not see what you are looking for in the list of reports you have access to, you can always generate a new report.',
        'text2': 'To create a new report, you can click on the "Create A New Report" menu selection, or click on the Create a New Report icon on the main page.',
        'text3': 'Clicking either of those will redirect you to the Create Report Form where you can specify the parameters for the report you wish to create.',    
        'text4': '',    
        'text5': '',    
    },
    5: {
        'image': createreportform,
        'text1': 'The Create Report Form is where you can fill out the fields to generate a new report for your analysis.',
        'text2': 'The form is meant to be completed top to bottom, and certain fields will not populate until you have made the selections necessary.',
        'text3': 'All fields are required in this form, and if you try to submit without providing the information, you will recieve an error informing you of what needs to be corrected.',
        'text4': '',
        'text5': '', 
    },
    6: {
        'image': namehighlighted,
        'text1': 'You must provide a name for your report.  There are no limitations to this name, but make it something meaningful so you can find it easily in the report menu or table if you are trying to locate it later.',
        'text2': 'If you name the report with a name that you have already used, a warning will pop up letting you know that you have already used this name.  You can continue with the duplicate name, but you may want to consider changing it for ease of use.',
        'text3': '',
        'text4': '',
        'text5': '', 
    },
    7: {
        'image': calendarselections,
        'text1': 'After you have provided a name, use the dropdown below to select a dataset.  This will then change the minimum and maximum dates allowed in the calendar dropdowns.',
        'text2': 'The calendar dates are week ending, so by selecting the 8th in the example to the left, that represents data from December 2nd to December 8th',
        'text3': 'Some datasets only have monthly data, and the calendar selections will be reflected to show whole months only.',
        'text4': 'You must select a start and end date, and if you try to select an invalid date, or date combination, the form will provide you an error that you can correct.',
        'text5': '', 
    },
    8: {
        'image': dropdownstoregroup,
        'text1': 'Once you have selected a Name, dataset, and Start and End Date, you can provide which store group you wish to view the data for.  By selecting the store group, you can then see what brands you can select in the dropdown below.',
        'text2': 'Brands will not populated until you have selected a store group.',
        'text3': 'In the store group and brand dropdowns, you may type in the box to search for a value, and then select from the limited selection.',
        'text4': '',
        'text5': '', 
    },
    9: {
        'image': saveandrun,
        'text1': 'Once all of the fields in the form have been filled out, you can hit the "Save and Run" button to generate the input.',
        'text2': ' Once you have hit the button, you will be automatically redirected to the report output page.  The newly created report will also be present in the report menu for you to access later.',
        'text3': '',
        'text4': '',
        'text5': '', 
    },
    10: {
        'image': reportoutput,
        'text1': 'Once you are on the page and the data has finished loading, you can then navigate the report outputs.  You can cycle through the different views, filter the data further, and run comparisons on subsets of the data.',
        'text2': 'Data for each of the charts, as well as the data for the report can be downloaded into CSV and XLS formats.  You can also easily export the charts into image files.',
        'text3': 'If you wish, you can also share the report, edit the report, and make a copy the report.',
        'text4': '', 
        'text5': '', 
    },
    11: {
        'image': moreinfo,
        'text1': 'If you wish to see more how tos, you can watch videos on the resources Page found in the Resources Menu.  In the videos, you can see more in depth examples of navigating a report and using the advanced features.',
        'text2': '',
        'text3': 'You have reached the end of the tutorial, and may now close this window by hitting the close button below.',
        'text4': '',
        'text5': '', 
    }
}

export class Tutorial extends Component {
    static displayName = Tutorial.name;
    constructor(props) {
        super(props);
        this.state = {
            displayTutorial: false,
            step: 0
        }
    }


    handleCloseTutorial = () => {
        this.setState({ displayTutorial: false })
        localStorage.setItem('tutorialseen', JSON.stringify(true)); //Turning off for now while developing
    }

    handleNext = () => {
        const numslides = Object.keys(tutorialdict).length - 1
        var step

        if (this.state.step !== numslides) {
            step = this.state.step + 1
        }
        else {
            step = this.state.step 
        }

        this.setState({ step })
    }

    handlePrev = () => {
        var step

        if (this.state.step !== 0) {
            step = this.state.step - 1
        }
        else {
            step = this.state.step
        }

        this.setState({ step })
    }


    componentDidMount() {
        console.log('Mounting Cookie Bar')
        //Find Out if the user has a value of true set in their browser...
        let tutorialseen = JSON.parse(localStorage.getItem('tutorialseen'))
        console.log(tutorialseen)
        if (tutorialseen) {
            this.setState({ displayTutorial: false })
        }

    }

    render() {

        return (
            <div>
                
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.displayTutorial}
                    onClose={this.handleCloseTutorial}
                    style={{ overflowY: 'scroll' }}
                    disableBackdropClick
                >
                    <div className='contentHolder col-sm-12 row' style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '90%', overflowY: 'auto', height: '90%' }} >
                        <div className='col-sm-12' >
                            <h1>Tutorial</h1>
                        </div>
                        <div className='col-sm-9 npdborders' style={{ height: '90%', padding: '20px' }} >
                            <img src={tutorialdict[this.state.step].image} height='100%' width='100%' align='center' style={{ padding: '0px', marginTop:'0px'}} alt="TutorialImage" />
                        </div>
                        <div className='col-sm-3 ' style={{ height: '90%' }} >
                            <div className='row npdborders' style={{ height: '75%', padding:'25px', overflowY:'scroll' }} >
                                <p>{tutorialdict[this.state.step].text1}</p>
                                <p>{tutorialdict[this.state.step].text2}</p>
                                <p>{tutorialdict[this.state.step].text3}</p>
                                <p>{tutorialdict[this.state.step].text4}</p>
                                <p>{tutorialdict[this.state.step].text5}</p>
                            </div>
                            <div className='row npdborders' style={{height: '25%', textAlign: 'center'}} >
                                <div className= 'col-sm-4 ' >
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={this.handlePrev}
                                        value='previous'
                                        style={{ padding: '5px', margin: '5px', position: 'absolute', top: '25%', transform: 'translateX(-50%)' }}
                                    >
                                        <Tooltip title="Previous" placement="right">
                                            <SkipPreviousRoundedIcon style={{ fontSize: '5vw', color: '#4e106f' }}/>
                                        </Tooltip>
                                    </IconButton>
                                </div>
                                <div className='col-sm-4 '  >

                                </div>
                                <div className='col-sm-4 '  >
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={this.handleNext}
                                        value='next'
                                        style={{ padding: '5px', margin: '5px', position: 'absolute', top: '25%', transform: 'translateX(-50%)' }}
                                    >
                                        <Tooltip title="Next" placement="right">
                                            <SkipNextRoundedIcon style={{ fontSize: '5vw', color: '#4e106f' }}/>
                                        </Tooltip>
                                    </IconButton>
                                </div>
                                <div className='col-sm-3 '  >

                                </div>
                                <div className='col-sm-6 '  >
                                    <IconButton
                                        className="btn btn-primary"
                                        onClick={this.handleCloseTutorial}
                                        value='close'
                                        style={{ padding: '5px', margin: '5px', position: 'absolute', top: '-25%', transform: 'translateX(-50%)' }}
                                    >
                                        <Tooltip title="Close" placement="right">
                                            <CloseIcon style={{ fontSize: '5vw', color: '#4e106f' }} />
                                        </Tooltip>
                                    </IconButton>
                                </div>
                                <div className='col-sm-3 ' >
                                    
                                </div>                                
                            </div>
                            
                        </div>
                        <div className='col-sm-12' >
                            slide {this.state.step + 1} of {Object.keys(tutorialdict).length}
                        </div>
                        
                        

                    </div>
                </Modal>
            </div>
        )

    }
}