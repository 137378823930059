import React, { Component } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import { Layout } from '../Layout';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Draggable from 'react-draggable'
import GridLayout from 'react-grid-layout';
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import * as Constant from '../constants'


const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4e106f',
        },
        secondary: {
            main: '#ef2a79',
        },
        white: {
            main: '#FFF'
        }
    }
});

export class PNPPriceBandDefinition extends Component {
    static displayName = PNPPriceBandDefinition.name;

    constructor(props) {
        super(props);
        this.useDataset = this.useDataset.bind(this)
        this.openDialog_LeaveReport = this.openDialog_LeaveReport.bind(this)
        this.LeaveForReport = this.LeaveForReport.bind(this)
        this.state = {
            deftype_custom: false,
            deftype_rules: false,
            showBackground: true,
            rules: [
                {
                    'label': 'Fixed Dollars', 'value': 'Fixed Dollars'
                },
                {
                    'label': 'Fixed Units', 'value': 'Fixed Units'
                },
            ],
            numbands: 10,
            bandsize: 100,
            custombands: [{ id: 0, 'name': "", 'start': "", 'end': "", errors: {} }],
            currencycode: 'USD',
            currencyType: 'Dollars',
            currencyTypeSingular: 'Dollar',
            currencysymbol: '$'
        }
    }

    async componentDidMount() {
        console.log('Getting Datasets')
        const datasetsres = await fetch(Constant.baseapiurl + "Datasets/DatasetDetails")
        var datasetsdata = []
        if (datasetsres.status === 200) {
            datasetsdata = await datasetsres.json()
        }
        else {
            datasetsdata = []
        }
        //set options
        var dataset_lookup = {}
        datasetsdata.forEach(dataset => { dataset_lookup[dataset.dataset_id] = dataset.description })
        let datasetoptions = datasetsdata.map(dataset => { return { 'label': dataset.description, 'value': dataset.dataset_id } })
        this.setState({ datasetoptions, datasetsdata })
        //Parse the url, and see if we have a dataset_id and report_id
        var url = window.location.href;
        var temp = url.split("?");
        console.log(temp)
        temp = temp.slice(1)
        //var temp = url.split("reportid=");
        console.log(temp)
        var datasetid_val, reportid_val, env_val
        temp.forEach(parameter => {
            let vals = parameter.split("=")
            let variable_name = vals[0]
            let variable_val = vals[1]
            if (variable_name === 'datasetid') {
                datasetid_val = variable_val
            }
            else if (variable_name === 'reportid') {
                reportid_val = variable_val
            }
            else if (variable_name === 'env') {
                env_val = variable_val
            }
        })

        if (reportid_val && datasetid_val) {
            this.setState({ reportid: reportid_val })
            this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
        }
        else if (datasetid_val) {
            this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
        }

        if (env_val) {
            this.setState({ extra_param: '?env=staging' })
        }
        else {
            this.setState({ extra_param: '' })
        }

        //Look and See if there are rules defined...

        var pricebandssettings_data
        let pricebandssettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetid_val + '/PriceBands')
        if (pricebandssettings_response.status === 200) {
            pricebandssettings_data = await pricebandssettings_response.json()
        }


        if (pricebandssettings_data) {
            console.log('Use Price Bands')
            console.log(pricebandssettings_data)
            let settings_value = pricebandssettings_data.setting_value
            if (settings_value.type === 'rulebased') {
                let bandsize = settings_value.bandsize
                let numbands = settings_value.numbands
                let deftype_custom = false
                let deftype_rules = true
                this.setState({ bandsize, numbands, deftype_custom, deftype_rules, showBackground: false })
            }
            else if (settings_value.type === 'custom') {
                let bands = settings_value.bands
                let custombands = bands.map((band, idx) => {
                    var start, end
                    start = String(band.value[0])
                    if (band.value[1] === 10000000) {
                        end = ""
                    }
                    else {
                        end = String(band.value[1])
                    }
                    return {
                        'id': idx
                        , 'name': band.label
                        , 'start': start
                        , 'end': end
                        , 'errors': {}
                    }
                })
                let deftype_custom = true
                let deftype_rules = false
                this.setState({ custombands, deftype_custom, deftype_rules, showBackground: false })
            }
        }

        else {
            this.setState({ showBackground: false })
        }
    }

    async useDataset(selectedOptions) {
        console.log(selectedOptions)
        this.setState({ selecteddataset: selectedOptions })
        //Look up Dataset Info...
        let dataset_id = selectedOptions.value

        let dataset_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id)
        if (dataset_response.status === 200) {
            var dataset_data = await dataset_response.json()
            var currencycode = ''
            var currencyType = ''
            var currencyTypeSingular = ''
            var currencysymbol = ''
            if (dataset_data.currency === 'GBP') {
                currencycode = 'GBP'
                currencyType = 'Pounds'
                currencyTypeSingular = 'Pound'
                currencysymbol = '£'
                //currencysymbol = '&#163;'

            }

            else if (dataset_data.currency === 'CAD') {
                currencycode = 'CAD'
                currencyType = 'Dollars'
                currencyTypeSingular = 'Dollar'
                currencysymbol = '$'
            }

            else if (dataset_data.currency === 'EUR') {
                currencycode = 'EUR'
                currencyType = 'Euros'
                currencyTypeSingular = 'Euro'
                currencysymbol = '€'
                //currencysymbol = '&#8364;'
            }

            else if (dataset_data.currency === 'MXN') {
                currencycode = 'MXN'
                currencyType = 'Pesos'
                currencyTypeSingular = 'Peso'
                currencysymbol = '$'
            }

            //If Not Explicitly set, use dollars
            else {
                currencycode = 'USD'
                currencyType = 'Dollars'
                currencyTypeSingular = 'Dollar'
                currencysymbol = '$';
            }
            this.setState({ currencycode, currencyType, currencyTypeSingular, currencysymbol })
        }
        else {
            dataset_data = { attributes: [] }
            this.setState({ errorAlert_report: true })
        }
    }

    openDialog_LeaveReport() {
        this.setState({ dialogopen_LeaveReport: true, })
    }

    LeaveForReport() {
        var url
        if (this.state.reportid) {
            url = Constant.baseurl + 'PNP/Report/' + this.state.reportid + this.state.extra_param
        }

        else {
            url = Constant.baseurl + 'PNP/Home'
        }

        window.location.href = url
    }

    render() {
        console.log(this.state)

        const handleChangeRadioDefinitionType = (event) => {
            var type = event.currentTarget.value
            if (type === 'Rules') {
                this.setState({ deftype_rules: true, deftype_custom: false, errormessages: {}, numerrors: 0 })
            }
            else if (type === 'Custom') {
                this.setState({ deftype_rules: false, deftype_custom: true, errormessages: {}, numerrors: 0 })
            }
        }



        const useRule = (selectedOptions) => {
            console.log(selectedOptions)
            this.setState({ selectedrule: selectedOptions })
        }

        const handleChangeBandSize = (event, newValue) => {
            console.log('Setting State')
            var input = document.getElementById("bandsize");
            let value = input.value
            console.log(value)

            if (isNaN(value)) {
                alert('You Must Only input Numbers.  Resetting to default value.')
                this.setState({ bandsize: 100 })
            }

            else {
                //check that value >= 0 and <= max
                if (value < 0) {
                    let alerttext = 'Value Entered Must Be Greater Than 0. Resetting to default value.'
                    this.setState({ bandsize: 100 })
                    alert(alerttext)
                }

                else {
                    var bandsize = value
                    this.setState({ bandsize })
                }

            }
        }

        const handleChangeCustomBand = (id, type) => {


            let custombands = this.state.custombands
            if (type === 'name') {
                let input = document.getElementById("bandname_" + id);
                let value = input.value
                custombands[id].name = value
            }

            else if (type === 'start') {
                let input = document.getElementById("bandstart_" + id);
                let value = input.value
                custombands[id].start = value
            }

            else if (type === 'end') {
                let input = document.getElementById("bandend_" + id);
                let value = input.value
                custombands[id].end = value
            }

            this.setState({ custombands })
        }

        const addNewCustomBand = () => {
            let custombands = this.state.custombands
            let numbands = custombands.length
            let idtoadd = numbands
            if (idtoadd <= 9) {
                custombands.push({ 'id': idtoadd, 'name': "", 'start': "", 'end': "", errors: {} })
            }
            else {
                alert('You can only add a maximum of 10 price bands')
            }
            this.setState({ custombands })
        }

        const removeCustomBand = (id) => {
            console.log(id)
            let custombands = this.state.custombands
            custombands.splice(id, 1)
            //Reindex
            custombands.forEach((band, idx) => {
                band.id = idx
            })
            //Set State
            this.setState({ custombands })
        }

        const applyRuleBands = async () => {
            console.log(this.state.numbands)
            console.log(this.state.bandsize)
            let numerrors = 0
            let bandsizeerror = false
            let bandsizetext = ""
            let numbandserror = false
            let numbandstext = ""
            let errormessages = {}
            //Error Check #1.  
            //There Should be a number of bands supplied, numeric, and gt 0, and less than 10

            let numbands = parseFloat(this.state.numbands)
            let bandsize = parseFloat(this.state.bandsize)
            if (numbands && numbands <= 0) {
                numerrors += 1
                numbandserror = true
                numbandstext = "Please Provide a Number of Price Bands Greater than 0."
                errormessages['numbands'] = numbandstext
            }

            else if (numbands && numbands > 10) {
                numerrors += 1
                numbandserror = true
                numbandstext = "Please Provide a Number of Price Bands Less than 10."
                errormessages['numbands'] = numbandstext
            }

            else if (!numbands) {
                numerrors += 1
                numbandserror = true
                numbandstext = "Please Provide a Number of Price Bands."
                errormessages['numbands'] = numbandstext
            }

            //Error Check #2.  
            //There Should be a band supplied, numeric, and gt 0

            if (bandsize && bandsize <= 0) {
                numerrors += 1
                bandsizeerror = true
                bandsizetext = "Please Provide a Band Size Greater than 0."
                errormessages['bandsize'] = bandsizetext
            }

            else if (!bandsize) {
                numerrors += 1
                bandsizeerror = true
                bandsizetext = "Please Provide a Band Size Greater than 0."
                errormessages['bandsize'] = bandsizetext
            }

            this.setState({
                numerrors,
                bandsizeerror,
                bandsizetext,
                numbandserror,
                numbandstext,
                errormessages
            })

            //If No Errors:
            if (numerrors === 0) {
                //create the jsonbody

                const setting = {
                    type: 'rulebased',
                    bandsize: bandsize,
                    numbands: numbands
                };

                //Show the backdrop & Snackbar
                let datasetidnum = this.state.selecteddataset.value
                let url = Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/PriceBands'
                console.log(JSON.stringify(setting));
                let settings_response = await fetch(url,
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(setting)
                    })

                var settings_obj
                if (settings_response.status === 200) {
                    settings_obj = await settings_response.json()
                    //if report id, then send back to the report page...
                    if (this.state.reportid) {
                        let url = Constant.baseurl + 'PNP/Report/' + this.state.reportid + this.state.extra_param
                        window.location.href = url
                    }
                }
                else {
                    settings_obj = {}
                    alert('There was an Error Setting the Price Bands')
                }

            }

        }

        const applyCustomBands = async () => {
            let custombands = this.state.custombands
            let numbands = custombands.length
            console.log(custombands)
            console.log(numbands)
            let numerrors = 0
            let errorstate = false
            let errormessages = {}
            let numwarnings = 0
            let warningstate = false
            let warningmessages = {}

            //Error Check #1.  
            //There Should be at least 2 price bands defined

            if (numbands < 2) {
                numerrors += 1
                errorstate = true
                errormessages['minimumbands'] = "There must be at least 2 price bands defined.  Please add the necessary amount of bands."
            }
            if (numbands > 0) {
                //Error Check #2
                //Unique Names of Bands
                let bandnames = custombands.map(band => band.name)
                let uniquebandnames = Array.from(new Set(bandnames))

                //Create a Map of the Names
                let namemap = {}
                custombands.forEach((band, idx) => {
                    namemap[band.name] = namemap[band.name] || []
                    namemap[band.name].push(idx)
                })

                console.log(namemap)
                let dupednames = []
                Object.keys(namemap).forEach(key => {
                    if (namemap[key].length > 1) {
                        dupednames.push(key)
                    }
                })
                console.log(dupednames)
                console.log(uniquebandnames)
                if (uniquebandnames.length !== numbands) {
                    numerrors += 1
                    errorstate = true
                    errormessages['uniquenames'] = "Price Bands should have unique names."
                    //Add a check on each name...
                    custombands.forEach(band => {
                        if (dupednames.includes(band.name)) {
                            band.errors['name'] = {}
                            band.errors['name']['status'] = true
                            band.errors['name']['errortext'] = 'Please Enter a Unique Name'
                        }
                        else {
                            band.errors['name'] = {}
                        }
                    })
                }

                else {
                    custombands.forEach(band => {
                        band.errors['name'] = {}
                    })
                }

                //Error Check #5
                //Band Start Must Be Populated
                custombands.forEach((band, idx) => {
                    let currstart = parseFloat(band.start)
                    console.log(currstart)
                    console.log(band)
                    if (!currstart && currstart !== 0) {
                        numerrors += 1
                        errorstate = true
                        errormessages['incorrectbandstart'] = "Price Band Start Must be Populated"
                        band.errors['start'] = {}
                        band.errors['start']['status'] = true
                        band.errors['start']['errortext'] = 'Band Start Must be populated'
                    }
                    else {
                        band.errors['start'] = {}
                    }
                })

                //Error Check #3 
                //First Bands Must Start at 0
                console.log(parseFloat(custombands[0].start))
                if (parseFloat(custombands[0].start) !== 0) {
                    numerrors += 1
                    errorstate = true
                    errormessages['incorrectstart'] = "The first price band must start at 0."
                    custombands[0].errors['start'] = {}
                    custombands[0].errors['start']['status'] = true
                    custombands[0].errors['start']['errortext'] = 'Band Must Start at 0'

                }

                else {
                    custombands[0].errors['start'] = {}
                }

                //Error Check #4
                //Band Must start .01 away from previous Band's end (Except First Band)
                custombands.forEach((band, idx) => {
                    if (idx >= 1) {
                        let prevend = parseFloat(custombands[idx - 1].end)
                        let currstart = parseFloat(band.start)
                        let diff = currstart - parseFloat(prevend)
                        if (diff.toFixed(2) > .01) {
                            console.log(idx)
                            console.log(diff)
                            numerrors += 1
                            errorstate = true
                            errormessages['incorrectbandstart'] = "Price Bands, other than the first, must be .01 greater than the previous band's ending price."
                            band.errors['start'] = {}
                            band.errors['start']['status'] = true
                            band.errors['start']['errortext'] = 'Band Must Start at 0.01 higher than previous band end.'
                        }
                        else if (diff.toFixed(2) < .01) {
                            numerrors += 1
                            console.log(idx)
                            console.log(diff)
                            errorstate = true
                            errormessages['incorrectbandstart'] = "Price Bands, other than the first, must be .01 greater than the previous band's ending price."
                            band.errors['start'] = {}
                            band.errors['start']['status'] = true
                            band.errors['start']['errortext'] = 'Band Must Start at 0.01 higher than previous band end.'
                        }
                        else if (band.start) {
                            band.errors['start'] = {}
                        }
                    }
                })


                //Error Check #6
                //Band End Can Not Be Blank, unless last band
                custombands.forEach((band, idx) => {
                    //let currstart = parseFloat(band.start)
                    let currend = parseFloat(band.end)
                    if (!currend && idx !== (numbands - 1)) {
                        numerrors += 1
                        errorstate = true
                        errormessages['incorrectbandend'] = "Price Band end must not be blank"
                        band.errors['end'] = {}
                        band.errors['end']['status'] = true
                        band.errors['end']['errortext'] = 'Band End Must be populated.'
                    }
                    else if (idx !== (numbands - 1)) {
                        band.errors['end'] = {}
                    }
                })

                //Error Check #7
                //Band End Can Not Be Less Than Band Start
                custombands.forEach((band, idx) => {
                    let currstart = parseFloat(band.start)
                    let currend = parseFloat(band.end)
                    if (currend - currstart <= 0) {
                        numerrors += 1
                        errorstate = true
                        errormessages['incorrectbandend'] = "Price Band end must be greater than Price Band Start"
                        band.errors['end'] = {}
                        band.errors['end']['status'] = true
                        band.errors['end']['errortext'] = 'Band End Must be Greater than Band Start.'
                    }
                    else if (currend) {
                        band.errors['end'] = {}
                    }
                })

                //Warning Check #1
                //Only Last Band Can be blank. (Not Needed, and if populated throw a warning)
                if (custombands[numbands - 1].end !== "") {
                    numwarnings += 1
                    warningstate = true
                    warningmessages['lastband'] = "Last Price Band will include prices greater than " + custombands[numbands - 1].end + " when creating visuals in the report output."
                }
            }


            this.setState({
                numerrors,
                errorstate,
                errormessages,
                numwarnings,
                warningstate,
                warningmessages
            })

            //if No Errors
            if (numerrors === 0) {
                console.log(custombands)
                let bands = custombands.map(band => {
                    let label = band.name
                    let start = parseFloat(band.start)
                    var end
                    if (band.end !== "") {
                        end = parseFloat(band.end)
                    }
                    else {
                        end = 10000000
                    }
                    return { 'label': label, 'value': [start, end] }
                })

                const setting = {
                    type: 'custom',
                    bands: bands,
                };

                let datasetidnum = this.state.selecteddataset.value
                let url = Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/PriceBands'
                console.log(JSON.stringify(setting));
                let settings_response = await fetch(url,
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(setting)
                    })

                var settings_obj
                if (settings_response.status === 200) {
                    settings_obj = await settings_response.json()
                    //if report id, then send back to the report page...
                    if (this.state.reportid) {
                        let url = Constant.baseurl + 'PNP/Report/' + this.state.reportid + this.state.extra_param
                        window.location.href = url
                    }
                }
                else {
                    settings_obj = {}
                    alert('There was an Error Setting the Price Bands')
                }

            }

        }

        const handleChangeNumBands = (event, newValue) => {
            console.log('Setting State')
            var input = document.getElementById("numbands");
            let value = input.value
            console.log(value)

            if (isNaN(value)) {
                alert('You Must Only input Numbers.  Resetting to default value')
                this.setState({ numbands: 10 })
            }

            else if (value === "") {
                this.setState({ numbands: null })
            }

            else {
                //check that value >= 0 and <= max
                if (value < 0) {
                    let alerttext = 'Value Entered Must Be Greater Than 0. Resetting to default value.'
                    this.setState({ numbands: 10 })
                    alert(alerttext)
                }

                else if (value > 10) {
                    let alerttext = 'Value Entered Must Be Less Than 10. Resetting to default value.'
                    this.setState({ numbands: 10 })
                    alert(alerttext)
                }

                else {
                    var numbands = value
                    this.setState({ numbands })
                }

            }
        }

        const handleCloseDialog_LeaveReport = () => {
            this.setState({ dialogopen_LeaveReport: false })
        }

        return (
            <Layout title="Price Band Definition" >

                <Dialog
                    open={this.state.dialogopen_LeaveReport}
                    onClose={handleCloseDialog_LeaveReport}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">Go Back to Report?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to navigate back to the report.  Would you like to save your changes, or navigate back without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog_LeaveReport} className="btn btn-cancel" autoFocus>
                            Cancel
                        </button>
                        {this.state.deftype_rules ?
                            <button onClick={applyRuleBands} className="btn btn-primary" autoFocus>
                                Save Changes and Go Back
                            </button>
                            :
                            this.state.deftype_custom ?
                                <button onClick={applyCustomBands} className="btn btn-primary" autoFocus>
                                    Save Changes and Go Back
                                </button>
                                :
                                <span />
                        }
                        <button onClick={() => this.LeaveForReport()} className="btn btn-secondarynpd" autoFocus>
                            Go Back Without Saving
                        </button>
                    </DialogActions>
                </Dialog>

                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <div name='banner' className='banner'>
                    <h1 align='center'>Price and Discount Trends Price Band Definition</h1>
                    <h2 align='center'>Use this page to set how Price Bands are defined per Dataset</h2>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h4>Select a Dataset</h4>
                            <div className='col-sm-6'>
                                <Select
                                    inputId="datasetdropdown"
                                    options={this.state.datasetoptions}
                                    placeholder={'Select a Datasets'}
                                    onChange={(options) => this.useDataset(options)}
                                    value={this.state.selecteddataset}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {this.state.selecteddataset ?
                            <div className='col-sm-12'>
                                <h4>Select How to Define</h4>
                                <ThemeProvider theme={muiTheme}>
                                    <Radio
                                        checked={this.state.deftype_rules}
                                        onChange={handleChangeRadioDefinitionType}
                                        value="Rules"
                                        name="PriceBandDefinitionType"
                                    />
                                    <span style={{ lineHeight: '5' }}>Rule Based</span>
                                    <Radio
                                        checked={this.state.deftype_custom}
                                        onChange={handleChangeRadioDefinitionType}
                                        value="Custom"
                                        name="PriceBandDefinitionType"
                                    />
                                    <span style={{ lineHeight: '5' }}>Custom Definition</span>
                                </ThemeProvider>
                            </div>
                            :
                            <div />
                        }
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 row'>
                            {this.state.deftype_rules ?
                                <div>
                                    <p>Please provide a size for each band, and a maximum number of bands you wish to see, up to 10.</p>
                                    <p>{'For example, if you provide a band size of ' + this.state.currencysymbol + '100, and a maximum of 5 bands, the bands will be as follows:'}</p>
                                    <ul>
                                        <li>{this.state.currencysymbol + '0 - ' + this.state.currencysymbol + '100'}</li>
                                        <li>{this.state.currencysymbol + '101 - ' + this.state.currencysymbol + '200'}</li>
                                        <li>{this.state.currencysymbol + '201 - ' + this.state.currencysymbol + '300'}</li>
                                        <li>{this.state.currencysymbol + '301 - ' + this.state.currencysymbol + '400'}</li>
                                        <li>{this.state.currencysymbol + '401+'}</li>
                                    </ul>
                                    <p>With the last band being all prices at or above the last specified band.</p>
                                </div>
                                :
                                this.state.deftype_custom ?
                                    <div>
                                        <p>For Each Band please provide a name, a starting point, and an ending point.</p>
                                        <p>Please keep the following rules in mind</p>
                                        <ul>
                                            <li>There must be at least 2 bands defined, but no more than 10.</li>
                                            <li>The first band must start at 0</li>
                                            <li>Each subsequent band must begin at .01 after the previous band's end.  For example, if the previous band ends at 10, the next band must start at 10.01</li>
                                            <li>Each Band must have a unique name</li>
                                            <li>The end of the band must be greater than the start of the band</li>
                                            <li>Each Band must have a unique name</li>
                                            <li>The last band's end is optional.  If provided, note that in the visuals, values greater than what is entered will be added to this band. </li>
                                        </ul>
                                    </div>
                                    :
                                    <p />
                            }
                        </div>
                    </div>

                    <div className='row'>
                        {this.state.deftype_rules ?
                            <div className='col-sm-12 row'>
                                <h4>Rules Entry</h4>
                                <div className='col-sm-12 row'>
                                    <div className='col-sm-3'>
                                        <p>Band Size</p>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <Constant.NPDInputAdornment position="start"> {this.state.currencysymbol} </Constant.NPDInputAdornment>
                                            }}
                                            onChange={handleChangeBandSize}
                                            id="bandsize"
                                            value={this.state.bandsize}
                                            error={this.state.bandsizeerror}
                                            helperText={this.state.bandsizetext}
                                        />
                                    </div>
                                    <div className='col-sm-3'>
                                        <p>Number of Bands</p>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChangeNumBands}
                                            id="numbands"
                                            value={this.state.numbands}
                                            error={this.state.numbandserror}
                                            helperText={this.state.numbandstext}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-12 row'>
                                    <h4>Bands Will Be As Follows</h4>
                                </div>
                                <div className='col-sm-12 row'>
                                    <ul>
                                        {
                                            Array.from(Array(parseInt(this.state.numbands || 0)).keys()).map((band, idx) => {
                                                let start = this.state.currencysymbol + String(this.state.bandsize * idx + 1)
                                                let end = ' - ' + this.state.currencysymbol + this.state.bandsize * (idx + 1)

                                                if (idx === 0) {
                                                    start = 'Under ' + this.state.currencysymbol
                                                    end = this.state.bandsize * (idx + 1)
                                                }

                                                if (idx === Array.from(Array(parseInt(this.state.numbands)).keys()).length - 1) {
                                                    end = ' + '
                                                }
                                                return <li>{start + end} </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='col-sm-12 row'>
                                    <Tooltip
                                        title='Save Row Definitions'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={applyRuleBands}
                                        >
                                            <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title='Go Back to Report'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={() => this.openDialog_LeaveReport()}
                                        >
                                            <SettingsBackupRestoreRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            :
                            this.state.deftype_custom ?
                                <div className='col-sm-12'>
                                    <h4>Custom Entry</h4>
                                    <div className='col-sm-6'>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Price Band Name</TableCell>
                                                    <TableCell>Price Band Start</TableCell>
                                                    <TableCell>Price Band End</TableCell>
                                                    <TableCell>Remove Price Band</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.custombands.map(band => {
                                                    return <TableRow key={band.id} >
                                                        <TableCell>
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(id, name) => handleChangeCustomBand(band.id, 'name')}
                                                                id={"bandname_" + band.id}
                                                                value={band.name}
                                                                error={(band.errors['name'] || {}).status}
                                                                helperText={(band.errors['name'] || {}).errortext}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(id, name) => handleChangeCustomBand(band.id, 'start')}
                                                                id={"bandstart_" + band.id}
                                                                value={band.start}
                                                                error={(band.errors['start'] || {}).status}
                                                                helperText={(band.errors['start'] || {}).errortext}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(id, name) => handleChangeCustomBand(band.id, 'end')}
                                                                id={"bandend_" + band.id}
                                                                value={band.end}
                                                                error={(band.errors['end'] || {}).status}
                                                                helperText={(band.errors['end'] || {}).errortext}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip
                                                                title='Remove Band'
                                                                placement='top-start'
                                                            >
                                                                <IconButton
                                                                    onClick={(id) => removeCustomBand(band.id)}
                                                                >
                                                                    <ClearRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                        <Tooltip
                                            title='Add A New Band'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={addNewCustomBand}
                                            >
                                                <AddRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title='Save Row Definitions'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={applyCustomBands}
                                            >
                                                <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title='Go Back to Report'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={() => this.openDialog_LeaveReport()}
                                            >
                                                <SettingsBackupRestoreRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>

                                    </div>
                                </div>
                                :
                                <div />
                        }

                    </div>
                    {this.state.numerrors > 0 ?
                        <div className='row'>
                            <div className='col-sm-12'>
                                There are Errors. Please Fix the Highlighted Cells.
                                <br />
                                <br />
                                Error List:
                                <ul>
                                    {Object.values(this.state.errormessages || {}).map(message => {
                                        return <li>{message}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                        :
                        <div />
                    }
                </div>


            </Layout>
        )
    }
}