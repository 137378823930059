import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'

const DiscountDollarsAndPBR = props => {
    console.log(props)    

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {                            
                            height: (9 / 16 * (100 * (props.height || 1))) + '%' //16:9 ratio
                        },
                        metadata: props.metadata || {},
                        caption: {
                            text: props.attr_caption_text
                        },
                        title: {
                            text: 'Discount ' + props.currencyType + ' and Payback Ratio by ' + props.level + props.chart_title_info
                        },
                        series: [
                            {
                                name: 'Discount ' + props.currencyType,
                                data: props.DiscountDollarData.map(value => { return value || 0 }),
                                type: 'column',
                                tooltip: {
                                    pointFormatter: function () {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: props.currencycode, minimumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                },
                                zIndex: 0,
                                yAxis: 1
                            },
                            {
                                name: 'Payback Ratio',
                                data: props.PBRData.map(value => { return value || 0 }),
                                type: 'line',
                                lineWidth: 0,
                                marker: {
                                    enabled: true,
                                    radius: 10
                                },
                                states: {
                                    hover: {
                                        lineWidthPlus: 0
                                    }
                                },
                                zIndex: 1,
                                yAxis: 0,
                                color: '#ff8b6a'
                            },
                            
                        ],
                        xAxis: [
                            {
                                title: {
                                    text: ''
                                },
                                categories: props.categories
                            },

                        ],
                        yAxis: [
                            {
                                title: {
                                    text: 'Payback Ratio'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    }
                                }
                            },
                            {
                                title: {
                                    text: 'Discount ' + props.currencyType 
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        function nFormatter(num, digits, axisMax) {
                                            var si = [
                                                { value: 1E18, symbol: "E" },
                                                { value: 1E15, symbol: "P" },
                                                { value: 1E12, symbol: "T" },
                                                { value: 1E9, symbol: "B" },
                                                { value: 1E6, symbol: "M" },
                                                { value: 1E3, symbol: "k" }
                                            ], i;

                                            let symbolForAxis = " "
                                            let valueForAxis = 1

                                            for (i = 0; i < si.length; i++) {
                                                if (axisMax >= si[i].value) {
                                                    symbolForAxis = si[i].symbol
                                                    valueForAxis = si[i].value
                                                    break;
                                                }
                                            }

                                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                        }

                                        let value = nFormatter(this.value, 1, this.axis.max)
                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                        let symbol = value.substring(value.length - 1)
                                        return numpart.toLocaleString(undefined, { style: 'currency', currency: props.currencycode, minimumFractionDigits: 0 }) + symbol

                                        }

                                    }
                                }
                        ],
                        plotOptions: {
                            column: {                                
                                dataLabels: {
                                    enabled: false, 
                                    format: '{point.y:,.0f}'
                                }
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                if (this.series.name === 'Payback Ratio') {
                                    return this.x
                                        + '<br/><span style="color:'
                                        + this.series.color + '">'
                                        + this.series.name
                                        + '</span>: <b>'
                                        + (this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                }
                                else {
                                    return this.x
                                        + '<br/><span style="color:'
                                        + this.series.color + '">'
                                        + this.series.name
                                        + '</span>: <b>'
                                        + (this.y).toLocaleString(undefined, { style: 'currency', currency: props.currencycode, minimumFractionDigits: 0 })
                                }
                                
                            }
                        },
                        exporting: {
                            showTable: props.showDataTable,
                            tableCaption: false
                        },
                    }}
                oneToOne={true}
            />
            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g11">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{props.level}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Discount ' + props.currencyType}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Payback Ratio</Constant.NPDTableCell>                            
                        </Constant.NPDTableRow>
                        {props.categories.map((category, idx) => {
                            return <Constant.NPDTableRow key={"datarow_" + category + "_g11"}>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{category}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(props.DiscountDollarData[idx] || 0).toLocaleString(undefined, { style: 'currency', currency: props.currencycode, minimumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(props.PBRData[idx] || 0).toLocaleString(undefined, {  minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>                                
                            </Constant.NPDTableRow>
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default DiscountDollarsAndPBR;