import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants';

export class VolumeYOYGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {

        var metric = this.props.metric || 'totalunits'
        var formatting_object = this.props.formatting_object || {
            type: 'units',
            axisText: this.props.state.unitsvar,
            metricName: 'Total ' + this.props.stateunitsvar
        }
        console.log(formatting_object)
        var distinctdates_forcalc = this.props.state.distinctdates_forcalc
        var distinctdates_prior = this.props.state.distinctdates_prior
        var distinctdates_last = this.props.state.distinctdates_last
        var distinctdates_threeyears = this.props.state.distinctdates_threeyears

        let current_year_ending = this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1]
        let prior_year_ending = this.props.state.distinctdates_prior[this.props.state.distinctdates_prior.length - 1]
        let twoyear_year_ending = this.props.state.distinctdates_last[this.props.state.distinctdates_last.length - 1]
        let threeyear_year_ending = this.props.state.distinctdates_threeyears[this.props.state.distinctdates_threeyears.length - 1]

        function formatDate(datestring) {
            let date = Date.UTC(parseInt(datestring.substring(0, 4)), parseInt(datestring.substring(5, 7)) - 1, parseInt(datestring.substring(8, 10)), 0, 0, 0, 0)
            let dt = new Date(date)
            let offset = dt.getTimezoneOffset() * 60 * 1000
            var dateformatted = (new Date(date + offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
            return dateformatted
        }

        let current_year_ending_string = formatDate(current_year_ending)
        let prior_year_ending_string = formatDate(prior_year_ending)
        let twoyear_year_ending_string = formatDate(twoyear_year_ending)
        let threeyear_year_ending_string = formatDate(threeyear_year_ending)

        let numweeks_curr = this.props.state.distinctdates_forcalc.length
        let numweeks_prior = this.props.state.distinctdates_prior.length
        let numweeks_twoyear = this.props.state.distinctdates_last.length
        let numweeks_threeyear = this.props.state.distinctdates_threeyears.length

        var title_string

        if (this.props.state.showThreeYearViews) {
            title_string = formatting_object.metricName
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[1]
                + '<br>'
                + numweeks_curr
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + current_year_ending_string
                + ' , '
                + numweeks_prior
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + prior_year_ending_string
                + ' , '
                + numweeks_twoyear
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + twoyear_year_ending_string

                + ' , '
                + numweeks_threeyear
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + threeyear_year_ending_string

                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[3]
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[4]
        }

        else if (this.props.state.showLastViews) {
            title_string = formatting_object.metricName
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[1]
                + '<br>'
                + numweeks_curr
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + current_year_ending_string
                + ' , '
                + numweeks_prior
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + prior_year_ending_string
                + ' , '
                + numweeks_twoyear
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + twoyear_year_ending_string
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[3]
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[4]
        }
        else {
            title_string = formatting_object.metricName
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[1]
                + '<br>'
                + numweeks_curr
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + current_year_ending_string
                + ' , '
                + numweeks_prior
                + ' '
                + this.props.state.timeperiodlabels
                + 's Ending:'
                + ' '
                + prior_year_ending_string
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[3]
                + '<br>'
                + this.props.state.chart_title_info.split('<br>')[4]
        }
       
        var seriesYOY_brand = [
            {
                name: 'Current Year ' + formatting_object.metricName,
                type: 'spline',
                tooltip: {
                    pointFormatter: function () {
                        var yval
                        if (formatting_object.type === 'units') {
                            yval = Math.round(this.y).toLocaleString()
                        }
                        else {
                            yval = Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                        }
                        return '<span>'
                            + this.series.name
                            + '<br> '
                            + 'Week '
                            + parseInt(this.x) + 1 + ': '
                            + distinctdates_forcalc[this.x]
                            + '<br> '
                            + yval
                            + '<br> '
                            + '</span>'
                    },
                    headerFormat: ''
                },
                data: this.props.state.distinctdates_forcalc.map(date => (((this.props.state.weekly_data_new || {})[date] || {})[metric]))

            },

            {
                name: 'Previous Year ' + formatting_object.metricName,
                type: 'spline',
                color: '#ef2a79',
                tooltip: {
                    pointFormatter: function () {
                        var yval
                        if (formatting_object.type === 'units') {
                            yval = Math.round(this.y).toLocaleString()
                        }
                        else {
                            yval = Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                        }
                        return '<span>'
                            + this.series.name
                            + '<br> '
                            + 'Week '
                            + parseInt(this.x) + 1 + ': '
                            + distinctdates_prior[this.x]
                            + '<br> '
                            + yval
                            + '<br> '
                            + '</span>'
                    },
                    headerFormat: ''
                },
                data: this.props.state.distinctdates_prior.map(date => (((this.props.state.weekly_data_prior_new || {})[date] || {})[metric] || 0))
            },
        ]

        if (this.props.state.showLastViews) {
            
            seriesYOY_brand.push(
                {
                    name: 'Two Years Ago ' + formatting_object.metricName,
                    type: 'spline',
                    color: '#ff9971',
                    tooltip: {
                        pointFormatter: function () {
                            var yval
                            if (formatting_object.type === 'units') {
                                yval = Math.round(this.y).toLocaleString()
                            }
                            else {
                                yval = Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                            }
                            return '<span>'
                                + this.series.name
                                + '<br> '
                                + 'Week '
                                + parseInt(this.x) + 1 + ': '
                                + distinctdates_last[this.x]
                                + '<br> '
                                + yval
                                + '<br> '
                                + '</span>'
                        },
                        headerFormat: ''
                    },
                    data: this.props.state.distinctdates_last.map(date => (((this.props.state.weekly_data_last_new || {})[date] || {})[metric] || 0))
                }
            )
        }

        if (this.props.state.showThreeYearViews) {

            seriesYOY_brand.push(
                {
                    name: 'Three Years Ago ' + formatting_object.metricName,
                    type: 'spline',
                    color: '#fdd900',
                    tooltip: {
                        pointFormatter: function () {
                            var yval
                            if (formatting_object.type === 'units') {
                                yval = Math.round(this.y).toLocaleString()
                            }
                            else {
                                yval = Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                            }
                            return '<span>'
                                + this.series.name
                                + '<br> '
                                + 'Week '
                                + parseInt(this.x) + 1 + ': '
                                + distinctdates_threeyears[this.x]
                                + '<br> '
                                +yval
                                + '<br> '
                                + '</span>'
                        },
                        headerFormat: ''
                    },
                    data: this.props.state.distinctdates_threeyears.map(date => (((this.props.state.weekly_data_threeyears_new || {})[date] || {})[metric] || 0))
                }
            )
        }

        var currencycode = this.props.state.currencycode || 'USD'
        var formatting_obj_table
        if (formatting_object.type !== 'units') {
            formatting_obj_table = { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }
        }

        return (
            <Fragment>

                <div style={{ height: '100%', width: "100%", }}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={
                                {
                                    chart: {
                                        zoomType: 'xy',
                                        height: (9 / 16 * 100) + '%' //16:9 ratio
                                    },
                                    metadata: this.props.state.chartmetadata,
                                    caption: {
                                        text: this.props.state.attr_caption_text
                                    },
                                    title: {
                                        text: title_string
                                    },
                                    series: seriesYOY_brand,
                                    xAxis: {
                                        title: {
                                            text: this.props.state.xAxisText === 'Week Ending' ? 'Week' : this.props.state.xAxisText
                                        },
                                        allowDecimals: false,
                                        labels: {
                                            formatter: function () {
                                                return this.value + 1;
                                            }
                                        }
                                    },
                                    yAxis: [
                                        {
                                            title: {
                                                text: formatting_object.axisText
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1,
                                            labels: {

                                                formatter: function () {

                                                    function nFormatter(num, digits, axisMax) {
                                                        var si = [
                                                            { value: 1E18, symbol: "E" },
                                                            { value: 1E15, symbol: "P" },
                                                            { value: 1E12, symbol: "T" },
                                                            { value: 1E9, symbol: "B" },
                                                            { value: 1E6, symbol: "M" },
                                                            { value: 1E3, symbol: "k" }
                                                        ], i;

                                                        let symbolForAxis = " "
                                                        let valueForAxis = 1

                                                        for (i = 0; i < si.length; i++) {
                                                            if (axisMax >= si[i].value) {
                                                                symbolForAxis = si[i].symbol
                                                                valueForAxis = si[i].value
                                                                break;
                                                            }
                                                        }

                                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                                    }
                                                    if (formatting_object.type === 'units') {
                                                        return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                                    }
                                                    else {
                                                        
                                                        let value = nFormatter(this.value, 1, this.axis.max)
                                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                        let symbol = value.substring(value.length - 1)
                                                        return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        
                                                    }

                                                }
                                            },

                                        },
                                    ],
                                    plotOptions: {
                                        column: {
                                            stacking: 'normal',
                                            dataLabels: {
                                                enabled: false,
                                                formatter: function () {
                                                    function nFormatter(num, digits) {
                                                        var si = [
                                                            { value: 1E18, symbol: "E" },
                                                            { value: 1E15, symbol: "P" },
                                                            { value: 1E12, symbol: "T" },
                                                            { value: 1E9, symbol: "B" },
                                                            { value: 1E6, symbol: "M" },
                                                            { value: 1E3, symbol: "k" }
                                                        ], i;
                                                        for (i = 0; i < si.length; i++) {
                                                            if (num >= si[i].value) {
                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                            }
                                                        }
                                                        return num.toString();
                                                    }

                                                    return nFormatter(this.point.y, 0).toLocaleString()

                                                },
                                                color: 'black',
                                                borderRadius: 5,
                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                borderWidth: 1,
                                                borderColor: '#d6d7d8',
                                                padding: 2,
                                                style: {
                                                    textOutline: 'none',
                                                    fontSize: '20px'
                                                },
                                            }

                                        },

                                        spline: {
                                            marker: {
                                                enabled: false
                                            }
                                        },

                                        series: {
                                            states: {
                                                inactive: {
                                                    opacity: 1
                                                }
                                            }
                                        }

                                    },
                                    exporting: {
                                        showTable: this.props.state.showDataTable,
                                        tableCaption: false
                                    },

                                }}
                        />
                    </div>
                
                <div style={{ display: this.props.state.showDataTableStyle }}>
                    <Constant.NPDTable>
                        <Constant.NPDTableBody>
                            <Constant.NPDTableRow key="header_g19">
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Week</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Current Year " + formatting_object.metricName}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Prior Year " + formatting_object.metricName}</Constant.NPDTableCell>
                                {this.props.state.showLastViews ?
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{"Two Years Ago " + formatting_object.metricName}</Constant.NPDTableCell>
                                    :
                                    <div/>
                                }
                            </Constant.NPDTableRow>

                            

                            {(this.props.state.distinctdates_forcalc).map(date => {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g18"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>                                    
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{((((this.props.state.weekly_data_new || {})[date] || {})[metric]) || 0).toLocaleString(undefined, formatting_obj_table)}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                    {this.props.state.showLastViews ?
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                        :
                                        <div />
                                    }
                                </Constant.NPDTableRow>
                            })
                            }

                            {(Object.keys(this.props.state.weekly_data_prior_new || {})).map(date => {
                                return < Constant.NPDTableRow key={"datarowprior_" + date + "_g18"} >
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(((this.props.state.weekly_data_prior_new || {})[date] || {})[metric] || 0).toLocaleString(undefined, formatting_obj_table)}</Constant.NPDTableCell>                                    
                                    {this.props.state.showLastViews ?
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                        :
                                        <div />
                                    }
                                </Constant.NPDTableRow>

                            })
                            }


                            {(this.props.state.distinctdates_last).map(date => {
                                if (this.props.state.showLastViews) {
                                    return <Constant.NPDTableRow key={"datarow_" + date + "_g19"}>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{((((this.props.state.weekly_data_last_new || {})[date] || {})[metric]) || 0).toLocaleString(undefined, formatting_obj_table)}</Constant.NPDTableCell>
                                    </Constant.NPDTableRow>
                                }
                                else {
                                    return null
                                }
                            })
                            }

                            

                        </Constant.NPDTableBody>
                    </Constant.NPDTable>
                </div>
                
            </Fragment>
        )
    }
}