import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import Tooltip from '@material-ui/core/Tooltip';

const Scorecard = props => {

    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'
    var appname = props.appname

    var unitsvar = props.unitsvar
    var base_units_curr = props.base_units_curr
    var base_units_prior = props.base_units_prior

    var sub_base_units_curr = props.sub_base_units_curr
    var sub_base_units_prior = props.sub_base_units_prior

    var unsub_base_units_curr = base_units_curr - sub_base_units_curr
    var unsub_base_units_prior = base_units_prior - sub_base_units_prior

    var unsub_base_units_curr_pct = unsub_base_units_curr / base_units_curr
    var unsub_base_units_prior_pct = unsub_base_units_prior / base_units_prior

    var sub_base_units_curr_pct = sub_base_units_curr / base_units_curr
    var sub_base_units_prior_pct = sub_base_units_prior / base_units_prior

    var inc_units_curr = props.inc_units_curr
    var inc_units_prior = props.inc_units_prior
    var total_units_curr = base_units_curr + inc_units_curr
    var total_units_prior = base_units_prior + inc_units_prior
    var base_unit_pct_curr = props.base_unit_pct_curr
    var base_unit_pct_prior = props.base_unit_pct_prior
    var inc_unit_pct_curr = props.inc_unit_pct_curr
    var inc_unit_pct_prior = props.inc_unit_pct_prior
    var nonpromo_units_curr = props.nonpromo_units_curr
    var nonpromo_units_prior = props.nonpromo_units_prior
    var promo_units_curr = props.promo_units_curr
    var promo_units_prior = props.promo_units_prior
    var nonpromo_unit_pct_curr = props.nonpromo_unit_pct_curr
    var nonpromo_unit_pct_prior = props.nonpromo_unit_pct_prior
    var promo_unit_pct_curr = props.promo_unit_pct_curr
    var promo_unit_pct_prior = props.promo_unit_pct_prior
    var avg_base_price_curr = props.avg_base_price_curr
    var avg_base_price_prior = props.avg_base_price_prior
    
    var avg_disc_curr = props.avg_disc_curr
    var avg_disc_prior = props.avg_disc_prior
    var avg_disc_withoutno_curr = props.avg_disc_withoutno_curr
    var avg_disc_withoutno_prior = props.avg_disc_withoutno_prior
    var disc_dollars_curr = props.disc_dollars_curr
    var disc_dollars_prior = props.disc_dollars_prior
    var lift_curr = props.lift_curr
    var lift_prior = props.lift_prior
    var lift_promo_curr = props.lift_promo_curr
    var lift_promo_prior = props.lift_promo_prior
    var eff_curr = props.eff_curr
    var eff_prior = props.eff_prior
    var payback_ratio_curr = props.payback_ratio_curr
    var payback_ratio_prior = props.payback_ratio_prior

    var total_dollars_curr = props.total_dollars_curr
    var total_dollars_prior = props.total_dollars_prior

    var nonpromoted_dollars_curr = props.nonpromoted_dollars_curr
    var nonpromoted_dollars_prior = props.nonpromoted_dollars_prior

    var promoted_dollars_curr = props.promoted_dollars_curr
    var promoted_dollars_prior = props.promoted_dollars_prior

    var base_dollars_curr = props.base_dollars_curr
    var base_dollars_prior = props.base_dollars_prior

    var incremental_dollars_curr = props.incremental_dollars_curr
    var incremental_dollars_prior = props.incremental_dollars_prior
    var avg_promo_price_curr, avg_promo_price_prior, avg_selling_price_curr, avg_selling_price_prior
    if (appname === 'pdt') {
        avg_promo_price_curr = promoted_dollars_curr / promo_units_curr
        avg_promo_price_prior = promoted_dollars_prior / promo_units_prior        
    }

    else {
        avg_promo_price_curr = props.avg_promo_price_curr
        avg_promo_price_prior = props.avg_promo_price_prior
    }
    

    var avg_nonpromo_price_curr = nonpromoted_dollars_curr / nonpromo_units_curr
    var avg_nonpromo_price_prior = nonpromoted_dollars_prior / nonpromo_units_prior

    if (appname === 'pdt') {
        avg_selling_price_curr = total_dollars_curr / total_units_curr
        avg_selling_price_prior = total_dollars_prior / total_units_prior
    }
    else {
        avg_selling_price_curr = props.avg_promo_price_curr
        avg_selling_price_prior = props.avg_promo_price_prior
    }


    var subbasedollars_curr = props.subbasedollars_curr
    var unsubbasedollars_curr = props.unsubbasedollars_curr

    var subbasedollars_prior = props.subbasedollars_prior
    var unsubbasedollars_prior = props.unsubbasedollars_prior

    var promodollars_pct_curr = promoted_dollars_curr / total_dollars_curr
    var promodollars_pct_prior = promoted_dollars_prior / total_dollars_prior

    var nonpromodollars_pct_curr = nonpromoted_dollars_curr / total_dollars_curr
    var nonpromodollars_pct_prior = nonpromoted_dollars_prior / total_dollars_prior

    var incdollars_pct_curr = incremental_dollars_curr / total_dollars_curr
    var incdollars_pct_prior = incremental_dollars_prior / total_dollars_prior

    var basedollars_pct_curr = base_dollars_curr / total_dollars_curr
    var basedollars_pct_prior = base_dollars_prior / total_dollars_prior

    var subbasedollars_pct_curr = subbasedollars_curr / base_dollars_curr
    var subbasedollars_pct_prior = subbasedollars_prior / base_dollars_prior

    var unsubbasedollars_pct_curr = unsubbasedollars_curr / base_dollars_curr
    var unsubbasedollars_pct_prior = unsubbasedollars_prior / base_dollars_prior

    var lift_curr_dollars = (incremental_dollars_curr / base_dollars_curr)
    var lift_prior_dollars = (incremental_dollars_prior / base_dollars_prior)
    var lift_promo_curr_dollars = (incremental_dollars_curr / subbasedollars_curr)
    var lift_promo_prior_dollars = (incremental_dollars_prior / subbasedollars_prior)
    var eff_curr_dollars = (incremental_dollars_curr / promoted_dollars_curr)
    var eff_prior_dollars = (incremental_dollars_prior / promoted_dollars_prior)

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 750,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    return (        
        appname === 'pdt' ?
            <Fragment>
                <h3>{'Base and Incremental ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Total Units Sold in the Reporting Period</p>
                                <p> Please note that:</p>
                                <p> Total Units = Non-Promoted Units + Promoted Units, and</p>
                                <p> Total Units = Base Units + Incremental Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >  
                        <div className="col-sm-2 " align='center' style={{ padding: '0px' }} >
                            <div className="contentHolder_scorecard">                                                         
                                <h5>{'Total ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>                                                            
                                <p>{(parseInt(total_units_curr, 10)).toLocaleString()}</p>
                                {(total_units_curr - total_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((total_units_curr - total_units_prior)).toLocaleString() + ' (+' + (((total_units_curr - total_units_prior)) / total_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((total_units_curr - total_units_prior)).toLocaleString() + ' (' + (((total_units_curr - total_units_prior)) / total_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>An estimate of unit sales that would have occurred without promotions from a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    > 
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }} >
                            <div className="contentHolder_scorecard">
                                <h5>{'Base ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(base_units_curr, 10)).toLocaleString()}</p>
                                {(base_units_curr - base_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((base_units_curr - base_units_prior)).toLocaleString() + ' (+' + (((base_units_curr - base_units_prior)) / base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((base_units_curr - base_units_prior)).toLocaleString() + ' (' + (((base_units_curr - base_units_prior)) / base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>An estimate of the impact on unit sales due to the presence of a temporary price reduction. Incremental sales are Total Sales minus Base Sales in stores with a temporary price discount.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    > 
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental ' + unitsvar}</h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(inc_units_curr, 10)).toLocaleString()}</p>
                                {(inc_units_curr - inc_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((inc_units_curr - inc_units_prior)).toLocaleString() + ' (+' + (((inc_units_curr - inc_units_prior)) / inc_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((inc_units_curr - inc_units_prior)).toLocaleString() + ' (' + (((inc_units_curr - inc_units_prior)) / inc_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Base Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    > 

                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base ' + unitsvar}</h5> <h5>Percent of Total</h5>
                                <p>{(base_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(base_unit_pct_curr - base_unit_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(base_unit_pct_curr - base_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(base_unit_pct_curr - base_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Incremental Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    > 
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental ' + unitsvar}</h5><h5>Percent of Total</h5>
                                <p>{(inc_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(inc_unit_pct_curr - inc_unit_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(inc_unit_pct_curr - inc_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(inc_unit_pct_curr - inc_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                </div>

                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Unsubsidized Base Units are the estimated base units sold in non-promoted stores.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base ' + unitsvar}</h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(unsub_base_units_curr, 10)).toLocaleString()}</p>
                                {(unsub_base_units_curr - unsub_base_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((unsub_base_units_curr - unsub_base_units_prior)).toLocaleString() + ' (+' + (((unsub_base_units_curr - unsub_base_units_prior)) / unsub_base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((unsub_base_units_curr - unsub_base_units_prior)).toLocaleString() + ' (' + (((unsub_base_units_curr - unsub_base_units_prior)) / unsub_base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Subsidized Base Units are estimated base units that were sold in stores with a temporary price reduction. These are the estimated units that would have sold regardless of the promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base ' + unitsvar} <span style={{ color: '#FFFFFF' }}> Un </span></h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(sub_base_units_curr, 10)).toLocaleString()}</p>
                                {(sub_base_units_curr - sub_base_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((sub_base_units_curr - sub_base_units_prior)).toLocaleString() + ' (+' + (((sub_base_units_curr - sub_base_units_prior)) / sub_base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((sub_base_units_curr - sub_base_units_prior)).toLocaleString() + ' (' + (((sub_base_units_curr - sub_base_units_prior)) / sub_base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Unsubsidized Base Units / Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    > 
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base ' + unitsvar}</h5> <h5>Percent of Base</h5>
                                <p>{unsub_base_units_curr_pct.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(unsub_base_units_curr_pct - unsub_base_units_prior_pct) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((unsub_base_units_curr_pct - unsub_base_units_prior_pct) * 100).toLocaleString() + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((unsub_base_units_curr_pct - unsub_base_units_prior_pct) * 100).toLocaleString() + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Subsidized Base Units / Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base ' + unitsvar} <span style={{ color: '#FFFFFF' }}> Un </span></h5> <h5>Percent of Base</h5>
                                <p>{sub_base_units_curr_pct.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(sub_base_units_curr_pct - sub_base_units_prior_pct) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((sub_base_units_curr_pct - sub_base_units_prior_pct)*100).toLocaleString() + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((sub_base_units_curr_pct - sub_base_units_prior_pct) * 100).toLocaleString() + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                </div>
                <h3>{'Promoted and Non-Promoted ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Unit sales that occur when there is no temporary price reduction</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Non-Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(nonpromo_units_curr, 10)).toLocaleString()}</p>
                                {(nonpromo_units_curr - nonpromo_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (+' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Unit sales in store/weeks that had a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                                <p>{(parseInt(promo_units_curr, 10)).toLocaleString()}</p>
                                {(promo_units_curr - promo_units_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (+' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Non-Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Non-Promoted ' + unitsvar}</h5> <h5>Percent of Total</h5>
                                <p>{(nonpromo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted ' + unitsvar}</h5><h5>Percent of Total</h5>
                                <p>{(promo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(promo_unit_pct_curr - promo_unit_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                </div>
                <h3>Sales Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Total ' + currencytype + ' Sold in the Reporting Period'}</p>
                                <p> Please note that:</p>
                                <p> {'Total ' + currencytype + ' = Non-Promoted ' + currencytype + ' + Promoted ' + currencytype + ', and'}</p>
                                <p> {'Total ' + currencytype + ' = Base ' + currencytype + ' + Incremental ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    > 
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Total ' + currencytype}</h5>
                                <p>{(total_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(total_dollars_curr - total_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(total_dollars_curr - total_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((total_dollars_curr - total_dollars_prior) / total_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(total_dollars_curr - total_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((total_dollars_curr - total_dollars_prior) / total_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{currencytype + ' sales that occur when there is no temporary price reduction'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5 style={{ fontSize: "1.25rem" }}>{'Non-Promoted ' + currencytype}</h5>
                                <p>{(nonpromoted_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(nonpromoted_dollars_curr - nonpromoted_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromoted_dollars_curr - nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((nonpromoted_dollars_curr - nonpromoted_dollars_prior) / nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromoted_dollars_curr - nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((nonpromoted_dollars_curr - nonpromoted_dollars_prior) / nonpromoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{currencytype + ' sales in store/weeks that had a temporary price reduction'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted ' + currencytype}</h5>
                                <p>{(promoted_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(promoted_dollars_curr - promoted_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promoted_dollars_curr - promoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((promoted_dollars_curr - promoted_dollars_prior) / promoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promoted_dollars_curr - promoted_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((promoted_dollars_curr - promoted_dollars_prior) / promoted_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'An estimate of unit sales that would have occurred without promotions from a temporary price reduction multiplied by the base price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base ' + currencytype}</h5>
                                <p>{(base_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(base_dollars_curr - base_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(base_dollars_curr - base_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((base_dollars_curr - base_dollars_prior) / base_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(base_dollars_curr - base_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((base_dollars_curr - base_dollars_prior) / base_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'An estimate of the impact on unit sales due to the presence of a temporary price reduction multiplied by the promoted price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental ' + currencytype}</h5>
                                <p>{(incremental_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(incremental_dollars_curr - incremental_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(incremental_dollars_curr - incremental_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((incremental_dollars_curr - incremental_dollars_prior) / incremental_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(incremental_dollars_curr - incremental_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((incremental_dollars_curr - incremental_dollars_prior) / incremental_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>    
                    </HtmlTooltip>
                </div>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Unsubsidized Base Units are the estimated base units sold in non-promoted stores multiplied by the base price'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base ' + currencytype}</h5>
                                <p>{(unsubbasedollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(unsubbasedollars_curr - unsubbasedollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(unsubbasedollars_curr - unsubbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((unsubbasedollars_curr - unsubbasedollars_prior) / unsubbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(unsubbasedollars_curr - unsubbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((unsubbasedollars_curr - unsubbasedollars_prior) / unsubbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Subsidized Base Units are estimated base units that were sold in stores with a temporary price reduction multiplied by the promoted price.  These are the estimated units that would have sold regardless of the promotion.'}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base ' + currencytype}</h5>
                                <p>{(subbasedollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(subbasedollars_curr - subbasedollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(subbasedollars_curr - subbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((subbasedollars_curr - subbasedollars_prior) / subbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(subbasedollars_curr - subbasedollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((subbasedollars_curr - subbasedollars_prior) / subbasedollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                </div>                

                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Non-Promoted ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    > 
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Non-Promoted % of Total ' + currencytype}</h5>
                                <p>{(nonpromodollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(nonpromodollars_pct_curr - nonpromodollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromodollars_pct_curr - nonpromodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromodollars_pct_curr - nonpromodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Promoted ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted % of Total ' + currencytype}</h5>
                                <p>{(promodollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(promodollars_pct_curr - promodollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promodollars_pct_curr - promodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promodollars_pct_curr - promodollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Base ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Base % of Total ' + currencytype}</h5>
                                <p>{(basedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(basedollars_pct_curr - basedollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(basedollars_pct_curr - basedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(basedollars_pct_curr - basedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Incremental ' + currencytype + ' / Total ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Incremental % of Total ' + currencytype}</h5>
                                <p>{(incdollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(incdollars_pct_curr - incdollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(incdollars_pct_curr - incdollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(incdollars_pct_curr - incdollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'Subsidized Base ' + currencytype + ' / Base ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Subsidized Base % of Base ' + currencytype}</h5>
                                <p>{(subbasedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(subbasedollars_pct_curr - subbasedollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(subbasedollars_pct_curr - subbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(subbasedollars_pct_curr - subbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>{'UnSubsidized Base ' + currencytype + ' / Base ' + currencytype}</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'UnSubsidized Base % of Base ' + currencytype}</h5>
                                <p>{(unsubbasedollars_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(unsubbasedollars_pct_curr - unsubbasedollars_pct_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(unsubbasedollars_pct_curr - unsubbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(unsubbasedollars_pct_curr - unsubbasedollars_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                </div>
                <h3>Pricing Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of Units Identified as Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Base Price <span style={{ color: '#FFFFFF' }}> UN </span></h5>
                                <p>{(avg_base_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_base_price_curr - avg_base_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Estimated Average Selling Price of All Units Sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Selling Price <span style={{ color: '#FFFFFF' }}> Un </span></h5>
                                <p>{(avg_selling_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_selling_price_curr - avg_selling_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_selling_price_curr - avg_selling_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_selling_price_curr - avg_selling_price_prior) / avg_selling_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_selling_price_curr - avg_selling_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_selling_price_curr - avg_selling_price_prior) / avg_selling_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Promo Price <span style={{ color: '#FFFFFF' }}> Un </span></h5>
                                <p>{(avg_promo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_promo_price_curr - avg_promo_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as not being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Non-Promo Price</h5>
                                <p>{(avg_nonpromo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_nonpromo_price_curr - avg_nonpromo_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_nonpromo_price_curr - avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_nonpromo_price_curr - avg_nonpromo_price_prior) / avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_nonpromo_price_curr - avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_nonpromo_price_curr - avg_nonpromo_price_prior) / avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across all units sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Discount <span style={{ color: '#FFFFFF' }}> Gettonext </span></h5>
                                <p>{(avg_disc_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(avg_disc_curr - avg_disc_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across units identified as being on promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Promoted Discount</h5>
                                <p>{(avg_disc_withoutno_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(avg_disc_withoutno_curr - avg_disc_withoutno_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_disc_withoutno_curr - avg_disc_withoutno_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_disc_withoutno_curr - avg_disc_withoutno_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The total value of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the actual price of a promoted product and multiplying by Promoted Unit sales.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Discount ' + currencytype}</h5>
                                <p>{(disc_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(disc_dollars_curr - disc_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                </div>

                <h3>Promotion Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>


                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Market Lift <span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_curr - lift_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Promoted Lift<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_promo_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_promo_curr - lift_promo_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Efficiency<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(eff_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(eff_curr - eff_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Market Lift (' + currencytype + ')'}<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_curr_dollars - lift_prior_dollars) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_curr_dollars - lift_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_curr_dollars - lift_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>

                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted Lift (' + currencytype + ')'}</h5>
                                <p>{(lift_promo_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_promo_curr_dollars - lift_promo_prior_dollars) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_promo_curr_dollars - lift_promo_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_promo_curr_dollars - lift_promo_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Efficiency (' + currencytype + ')'}<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(eff_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(eff_curr - eff_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(eff_curr_dollars - eff_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(eff_curr_dollars - eff_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Payback Ratio</h5>
                                <p>{(payback_ratio_curr).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</p>
                                {(payback_ratio_curr - payback_ratio_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </HtmlTooltip>
                </div>
            </Fragment>
            :
            <Fragment>
                <h3>{'Base and Incremental ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>An estimate of unit sales that would have occurred without promotions from a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Base ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(base_units_curr, 10)).toLocaleString()}</p>
                            {(base_units_curr - base_units_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((base_units_curr - base_units_prior)).toLocaleString() + ' (+' + (((base_units_curr - base_units_prior)) / base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((base_units_curr - base_units_prior)).toLocaleString() + ' (' + (((base_units_curr - base_units_prior)) / base_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>An estimate of the impact on unit sales due to the presence of a temporary price reduction. Incremental sales are Total Sales minus Base Sales in stores with a temporary price discount.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Incremental ' + unitsvar}</h5> <h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(inc_units_curr, 10)).toLocaleString()}</p>
                            {(inc_units_curr - inc_units_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((inc_units_curr - inc_units_prior)).toLocaleString() + ' (+' + (((inc_units_curr - inc_units_prior)) / inc_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((inc_units_curr - inc_units_prior)).toLocaleString() + ' (' + (((inc_units_curr - inc_units_prior)) / inc_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Base Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Base ' + unitsvar}</h5> <h5>Percent of Total</h5>
                            <p>{(base_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(base_unit_pct_curr - base_unit_pct_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(base_unit_pct_curr - base_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(base_unit_pct_curr - base_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Incremental Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Incremental ' + unitsvar}</h5><h5>Percent of Total</h5>
                            <p>{(inc_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(inc_unit_pct_curr - inc_unit_pct_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(inc_unit_pct_curr - inc_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(inc_unit_pct_curr - inc_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                </div>
                <h3>{'Promoted and Non-Promoted ' + unitsvar}</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Unit sales that occur when there is no temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Non-Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(nonpromo_units_curr, 10)).toLocaleString()}</p>
                            {(nonpromo_units_curr - nonpromo_units_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (+' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((nonpromo_units_curr - nonpromo_units_prior)).toLocaleString() + ' (' + (((nonpromo_units_curr - nonpromo_units_prior)) / nonpromo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Unit sales in store/weeks that had a temporary price reduction.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Promoted ' + unitsvar}</h5><h5 style={{ color: '#FFFFFF' }}>a</h5>
                            <p>{(parseInt(promo_units_curr, 10)).toLocaleString()}</p>
                            {(promo_units_curr - promo_units_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (+' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {Math.round((promo_units_curr - promo_units_prior)).toLocaleString() + ' (' + (((promo_units_curr - promo_units_prior)) / promo_units_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Non-Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Non-Promoted ' + unitsvar}</h5> <h5>Percent of Total</h5>
                            <p>{(nonpromo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(nonpromo_unit_pct_curr - nonpromo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Promoted Units / Total Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Promoted ' + unitsvar}</h5><h5>Percent of Total</h5>
                            <p>{(promo_unit_pct_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(promo_unit_pct_curr - promo_unit_pct_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(promo_unit_pct_curr - promo_unit_pct_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                </div>
                <h3>Pricing Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of Units Identified as Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Base Price</h5>
                            <p>{(avg_base_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            {(avg_base_price_curr - avg_base_price_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Promo Price</h5>
                            <p>{(avg_promo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            {(avg_promo_price_curr - avg_promo_price_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across all units sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Discount</h5>
                            <p>{(avg_disc_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(avg_disc_curr - avg_disc_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The total value of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the actual price of a promoted product and multiplying by Promoted Unit sales.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Discount ' + currencytype}</h5>
                            <p>{(disc_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(disc_dollars_curr - disc_dollars_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                </div>
                <h3>Promotion Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Market Lift</h5>
                            <p>{(lift_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(lift_curr - lift_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Promoted Lift</h5>
                            <p>{(lift_promo_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(lift_promo_curr - lift_promo_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Efficiency</h5>
                            <p>{(eff_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(eff_curr - eff_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Payback Ratio</h5>
                            <p>{(payback_ratio_curr).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</p>
                            {(payback_ratio_curr - payback_ratio_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </HtmlTooltip>
                </div>
            </Fragment>                
    )
}

export default Scorecard;