export default function setNewVizOrderOnly(selectedOptions) {
    console.log(selectedOptions)

    //Set User Settings

    //Set the state
    let ordername = this.state.savedOrderValue_forDropdown.value
    let order = this.state.savedOrderOptions[ordername]

    this.setState({ order, screenOrder: ordername })
    this.openChangeVizzes()
}