import * as Constant from '../../constants'

export default async function PNPhandleClickSave(saveOnly) {
    console.log(this.state)

    //console.log(saveOnly)
    //console.log(this.state)

    var num_errors = 0;
    this.setState({ report_type_missing: false })
    // REPORT/INDUSTRY OPTIONS 
    //Country Missing
    if (this.state.selectedCountryName === "") {
        num_errors += 1
        this.setState({ country_missing: true })
        
    }
    else {
        this.setState({ country_missing: false })
    }

    //Industry Missing
    if (this.state.selectedIndustry === "") {
        num_errors += 1
        this.setState({ industry_missing: true })
    }
    else {
        this.setState({ industry_missing: false })
    }

    //Causal Filename Missing
    //if (this.state.selectedCausal === "Yes" && this.state.causal_filename === "") {
    //    num_errors += 1
    //    this.setState({ causal_filename_missing: true })
    //}
    //else {
    //    this.setState({ causal_filename_missing: false })
    //}


    //Short Name Missing
    if (this.state.selectedShortDesc === "") {
        num_errors += 1
        this.setState({ short_desc_missing: true })
    }
    else {
        this.setState({ short_desc_missing: false })
    }

    //Abbr Missing
    if (this.state.selectedAbbr === "") {
        num_errors += 1
        this.setState({ abbr_missing: true })
    }
    else {
        this.setState({ abbr_missing: false })
    }

    //Report Name Missing
    if (this.state.selectedReportName === "") {
        num_errors += 1
        this.setState({ report_name_missing: true })
    }
    else {
        this.setState({ report_name_missing: false })
    }

    //Email Address Missing
    if (this.state.selectedEmailAddress.length < 1) {
        num_errors += 1
        this.setState({ email_missing: true })
    }
    else {
        this.setState({ email_missing: false })
    }

    // DEFINE COMPETITIVE SET 
    //CompSet Level of Hierarchy
    if (this.state.selectedCSCatName === "") {
        num_errors += 1
        this.setState({ cs_hierarchy_missing: true })
    }
    else {
        this.setState({ cs_hierarchy_missing: false })
    }

    //CompSet Price Threshold
    if (this.state.compset_price_threshold === "" || this.state.compset_price_threshold <1) {
        num_errors += 1
        this.setState({ cs_priceThresh_missing: true })
    }
    else {
        this.setState({ cs_priceThresh_missing: false })
    }


    // ITEM SELECTION
    //Item Type missing
    if (this.state.item_type === "") {
        num_errors += 1
        this.setState({ item_type_missing: true })
        console.log('SETTING ITEM TYPE MISSING TO TRUE')
    }
    else {
        this.setState({ item_type_missing: false })
    }

    //PPG filename missing
    if (this.state.item_type === "PPGs" && this.state.ppgName === "") {
        num_errors += 1
        this.setState({ ppg_filename_missing: true })
    }
    else {
        this.setState({ ppg_filename_missing: false })
    }

    //Item List filename missing
    if (this.state.item_type === "Item List" && this.state.itemFileName === "") {
        num_errors += 1
        this.setState({ item_filename_missing: true })
    }
    else {
        this.setState({ item_filename_missing: false })
    }
    //Top N Percent Missing
    if (this.state.item_type === "Criteria" && (this.state.top_n_percent === null || this.state.top_n_percent <1)) {
        num_errors += 1
        this.setState({ top_n_missing: true })
    }
    else {
        this.setState({ top_n_missing: false })
    }
    //Max Item Count Missing
    if (this.state.item_type === "Criteria" && (this.state.max_item_count === null || this.state.max_item_count < 1)) {
        num_errors += 1
        this.setState({ max_item_missing: true })
    }
    else {
        this.setState({ max_item_missing: false })
    }

    //Hierarchy Level missing
    if (this.state.item_type === "Criteria" && this.state.selectedCat === "") {
        num_errors += 1
        this.setState({ catLevel_missing: true })
    }
    else {
        this.setState({ catLevel_missing: false })
    }

    //Cat List Missing
    if (this.state.item_type === "Criteria" && this.state.Catchosen === "") {
        num_errors += 1
        this.setState({ catList_missing: true })
    }
    else {
        this.setState({ catList_missing: false })
    }


    // GEOGRAPHY SECTION
    // If no geos are selected
    if (this.state.geoNames.length < 1) {
        num_errors += 1
        this.setState({ geo_missing: true })
    }
    else {
        this.setState({ geo_missing: false })
    }
    console.log(this.state.geos)
       //If No Errors
    //Build Lookups and Set Items in Proper Places
   
    if (num_errors > 0) {
        this.setState({
            setOpenError: true
        })
    }
    else {
        const report = {
            attribute_ids: this.state.selectedAttrID,
            causal_filename: '',
            channel_reporting_level: this.state.selectedChannelOption,
            compset_attributes: this.state.cs_selectedAttrID || [],
            compset_category_level: this.state.selectedCSCatValue,
            compset_global_attributes: this.state.cs_selectedGlblAttrID || [],
            compset_price_threshold: this.state.compset_price_threshold,
            contract_ids: this.state.contract_ids,
            etl_abbr: this.state.selectedAbbr,
            etl_description: this.state.selectedReportName,
            etl_email_address: this.state.selectedEmailAddress,
            etl_shortname: this.state.selectedShortDesc,
            geos: this.state.geos,
            global_attribute_ids: this.state.selectedGlblAttrID || [],
            industry_id: this.state.selectedIndustry,
            is_manufacturer: false,
            is_retailer: false,
            item_selection: this.state.item_selection,
            item_type: this.state.item_type,
            job_frequency: this.state.selectedDelFreq,
            time_period_type: this.state.selectedTimeValue
            
        };
        var method
      
        var urlAddition = ""

        if (Constant.entrytype === 'create') {
            this.setState({ selectedRetMan: 'Manufacturer', is_manufacturer: true, is_retailer: false })
            method = "POST"
            if (this.state.selectedDelFreq === 'On-Demand') {
                var contract_ids = this.state.selectedContractId
            }
            else {
                var contract_ids = [
                    (parseInt(this.state.selectedContractId))]
            }


            this.setState({ showBackdrop: false })
        }

        else if (Constant.entrytype === 'edit') {
            method = "PUT"
            report['channels'] = this.state.editChannels
            report['etl_id'] = this.state.etlIDEdit
            report['time_updated'] = this.state.editTimeUpdated
            report['contract_ids'] = this.state.contract_ids
            report['max_item_count'] = this.state.editmax_item_count
            report['top_n_percent'] = this.state.edittop_n_percent
            urlAddition = "/" + String(this.state.etlIDEdit)


            //Show the backdrop & Snackbar
            //set showBackdrop to true to show the spinner


            this.setState({ showBackdrop: false })

        }
        console.log(JSON.stringify(report), 'REPORT SELECTIONS TO SEND TO API')
        let api_response = await fetch(Constant.baseapiurl_gen + "etl/Pnp_Etl" + urlAddition,
            {
                method: method,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(report)
            })
        
        
        if (api_response.status === 200) {
            let data = await api_response.json()
            //console.log('RESULTS FROM RUNNING EXTRACT ONLY')
            this.setState({ setOpenSaveOnly: true })
            //console.log(data);

            if (saveOnly === false) {
                this.setState({ showSnackbarSuccess: true, showBackdrop: false, openModal: false })
                this.setState({ setOpenSaveRun: true, showBackdrop: false })
                var runextract_response
                // Full run on Cloud
                if (this.state.runCloud === true) {
                    runextract_response = await fetch(Constant.baseapiurl_gen + "etl/PnpEtlExecution/" + data.etl_id + "?cloud=" + this.state.runCloud + "&job_flags=trap_col_error" + this.state.skip_extract_text + this.state.skip_calculations_text + this.state.resume_if_possible_text)
                    //console.log(runextract_response)
                }
                this.setState({ setOpenSaveRun: true, showBackdrop: false })
                if (runextract_response.status === 202) {
                    //console.log(runextract_response.headers.get('Location'))
                    var taskid = runextract_response.headers.get('Location')
                    let messages = taskid.split("/")
                    let realtaskid = messages[6]
                    //console.log(messages)

                    this.setState({ realtaskid })
                }
                else {
                    this.setState({ errorAlert: true })
                }
                //console.log(runextract_response)


            }

            this.setState({ showSnackbarSuccess: true, showBackdrop: false })
            this.setState({ setOpenSaveOnly: true, showBackdrop: false })
            //alert('Your extract job has been submitted.  An email will be sent when the job is complete')

        }
        else if (api_response.status === 511) {
            //console.log('Server Went Down, Display message to user')
            let data = await api_response.json()
            this.setState({
                displayReloadDialog: true
                , reloadDialog: data["Reason"]
                , showBackdrop: false
            })

        }

        else if (api_response.status === 409) {
            let data = await api_response.json()
            alert(data.Reason)

        }
        else if (api_response.status === 422) {
            console.log()
            let data = await api_response.json()
            console.log(data)
            var datajson = JSON.stringify(data)
            alert(JSON.stringify(data.Messages))

        }
        else {
            let data = await api_response.json()
            alert('There was an error - Error Code ' + api_response.status + ' - ' + data.Reason)
            this.setState({ errorAlert: true })
        }

    }

}



