import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
//Material UI Components
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import { Layout } from '../../Layout';
import { Loaders } from '../StaticReportComponents/Loaders';
import Select from 'react-select'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../../constants'
import selectColourStyles from '../../styles/SelectColourStyles'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export class IndustryView extends Component {
    static displayName = IndustryView.name;

    constructor(props) {
        super(props);
        this.state = {
            industry_data_years: {},
            metric: 'totalunits',
            selectedMetric: { 'label': 'Total', 'value': 'total' },
            units_dollars: 'units',
            metricOptions: [
                { 'label': 'Total', 'value': 'total' },
                { 'label': 'Base', 'value': 'base' },
                { 'label': 'Subsidized Base', 'value': 'subsidizedbase' },
                { 'label': 'UnSubsidized Base', 'value': 'unsubsidizedbase' },
                { 'label': 'Incremental', 'value': 'incremental' },
                { 'label': 'Promoted', 'value': 'promoted' },
                { 'label': 'Non-Promoted', 'value': 'nonpromoted' },
            ],
            formatting_object: {
                type: 'units',
                axisText: 'Units',
                metricName: 'Total Units'
            },
            unitsvar: 'Units',
            currencyType: 'Dollars',
            indexSwitchCheckDollars: false,
            wholeIndustryDatasets: [],
            selectedIndustry: null,
            selectedFilters: {
                'brand': [],
                'segment1': [],
                'segment2': [],
                'segment3': [],
                'rowdate': []
            },
            filters: {
                'brand': [],
                'segment1': [],
                'segment2': [],
                'segment3': [],
                'rowdate': []
            },
            time_filters: {
                'year': [2019, 2020, 2021], // FIXME: this shouldn't be hardcoded
                'quarter': [1,2,3,4],
            },
            value: 0,
            className_q1: 'buttonfilled',
            className_q2: 'buttonfilled',
            className_q3: 'buttonfilled',
            className_q4: 'buttonfilled',
            className_y0: 'buttonfilled',
            className_y1: 'buttonfilled',
            className_y2: 'buttonfilled',
            show_two_yago: true,
            show_one_yago: true,
            show_current: true,
            isLoaded: false,
            setAPIRequestsFailed: false,
            errorAlert_report: false,
        }
        this.getDatasetInfo = this.getDatasetInfo.bind(this)
        this.pullData = this.pullData.bind(this)
        this.handleChange_IndexSwitchDollars = this.handleChange_IndexSwitchDollars.bind(this)
        this.changeSelectedMetric = this.changeSelectedMetric.bind(this)
        this.changeSelectedIndustry = this.changeSelectedIndustry.bind(this)
        this.changeFilters = this.changeFilters.bind(this)
        this.json_headers_dict = {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
        }
    }

    async pullFilters(datasetnum) {
        var startTime = new Date()
        console.log('Starting Filter Pull')
        let filters = this.state.filters
        let filter_keys = Object.keys(filters)
        // For an industry with two levels, remove 'segment3' from our list of filters
        if (this.state.dataset_segment_names[datasetnum].segment3 == null) {
            filter_keys = filter_keys.filter(function (el) { return el != 'segment3'; });
        }
        console.log('Filter keys for the current dataset are:')
        console.log(filter_keys)

        let apiCalls = filter_keys.map(filter => {
            if (filter !== 'rowdate') {
                let localfilters = JSON.parse(JSON.stringify(filters))
                // Empty out filter for the specific keys
                localfilters[filter] = []
                // Add filter and filter +_name to levels
                localfilters['levels'] = [filter, filter + '_name']

                return fetch(Constant.baseapiurl + 'IndustryData/Filtered/FilterOptions/' + datasetnum,
                    {
                        method: "POST",
                        headers: this.json_headers_dict,
                        body: JSON.stringify(localfilters)
                    }
                )
            } else {
                return null
            }
        }).filter(Boolean)

        let responses = await Promise.all(apiCalls)

        let filter_options = {}
        responses.forEach(async (filteroptions_response, idx) => {
            let filter = filter_keys[idx]
            if (filteroptions_response.status === 200) {
                let filter_options_values = await filteroptions_response.json()
                filter_options[filter] = filter_options_values.options
                console.log('Adding Key')
                console.log(filter_options)
            } else {
                console.log('pullFilters got an HTTP error')
                this.setState({ errorAlert_report: true })
            }

            console.log('Setting State')
            this.setState({
                filter_options
            })
        })

        var endTime = new Date()
        var numsecs_rl = (endTime - startTime) / 1000
        console.log('It Took ' + numsecs_rl + ' seconds to loop through the rows')
    }

    async pullData(datasetnum) {
        this.setState({ isLoaded: false})
        let localfilters = JSON.parse(JSON.stringify(this.state.filters))
        localfilters['levels'] = ['rowdate']
        let time_filters = this.state.time_filters || {'year':[2019, 2020, 2021], 'quarter':[1, 2, 3, 4]} // FIXME: Years shouldn't be hard coded
        let filters_current = JSON.parse(JSON.stringify(localfilters))
        let filters_prev = JSON.parse(JSON.stringify(localfilters))
        let filters_twoyears = JSON.parse(JSON.stringify(localfilters))

        let url_time = Constant.baseapiurl + 'IndustryData/Filtered/StartEndDates/' + datasetnum
        let time_response = await fetch(url_time,
            {
                method: "POST",
                headers: this.json_headers_dict,
                body: JSON.stringify(time_filters)
            }
        )

        var time_start_end = {}
        if (time_response.status === 200) {
            time_start_end = await time_response.json()
        } else {
            console.log('pullData got an HTTP error')
            this.setState({ errorAlert_report: true })
        }

        console.log(time_start_end)

        let years = Object.keys(time_start_end['start_end_dates'])
        console.log(years)

        filters_current['rowdate'] = [time_start_end['start_end_dates'][years[2]]['start_date'].substring(0, 10), time_start_end['start_end_dates'][years[2]]['end_date'].substring(0, 10)]//['2021-01-09', '2022-01-01']
        filters_prev['rowdate'] = [time_start_end['start_end_dates'][years[1]]['start_date'].substring(0, 10), time_start_end['start_end_dates'][years[1]]['end_date'].substring(0, 10)]
        filters_twoyears['rowdate'] = [time_start_end['start_end_dates'][years[0]]['start_date'].substring(0, 10), time_start_end['start_end_dates'][years[0]]['end_date'].substring(0, 10)]

        let industry_data_years = {}
        let summary_vals = {}

        let industry_data_segment1 = {}
        let industry_data_segment2 = {}

        let url = Constant.baseapiurl + 'IndustryData/Filtered/YearObjects/' + datasetnum

        let apiCalls = [
            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_current)
                }
            ),

            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_prev)
                }
            ),

            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_twoyears)
                }
            )
        ]

        let responses = await Promise.all(apiCalls)
        let keys = ['current_data', 'one_year_ago_data', 'two_years_ago_data']
        responses.forEach(async (industry_response, idx) => {
            if (industry_response.status === 200) {
                let current_key = keys[idx]
                let industry_data = await industry_response.json()
                let data_returned = industry_data['data_object']

                industry_data_years[current_key] = data_returned

                let dates = Object.keys(data_returned)
                let weeks_returned = dates.length
                console.log('The industry data call returned ' + weeks_returned + ' rows of weekly data for ' + current_key)

                // If there are no weeks of sales for this filter, then summary_vals will just be the empty dictionary
                summary_vals[current_key] = {}
                if (weeks_returned > 0) {
                    let metric_keys = Object.keys(data_returned[dates[0]]) // This is the metrics returned in the first week of data that was returned
                    console.log(metric_keys)
                    // Consolidate the industry_data_years objects into summarized values
                    dates.forEach(date => {
                        metric_keys.forEach(metric => {
                            if (summary_vals[current_key][metric]) {
                                summary_vals[current_key][metric] += data_returned[date][metric]
                            } else {
                                summary_vals[current_key][metric] = data_returned[date][metric]
                            }
                        })
                    })
                }
            } else {
                console.log('pullData apicalls got an HTTP error')
                this.setState({ errorAlert_report: true })
            }
        })

        filters_current['levels'] = ['segment1_name']
        filters_prev['levels'] = ['segment1_name']
        filters_twoyears['levels'] = ['segment1_name']

        let apiCallsSegment1 = [
            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_current)
                }
            ),

            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_prev)
                }
            ),

            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_twoyears)
                }
            )
        ]

        let responsesSegment1 = await Promise.all(apiCallsSegment1)

        responsesSegment1.forEach(async (segment1_response, idx) => {
            if (segment1_response.status === 200) {
                let segment1_data = await segment1_response.json()
                industry_data_segment1[keys[idx]] = segment1_data['data_object']
            } else {
                console.log('pullData responsesSegment1 got an HTTP error')
                this.setState({ errorAlert_report: true })
            }
        })

        filters_current['levels'] = ['segment2_name']
        filters_prev['levels'] = ['segment2_name']
        filters_twoyears['levels'] = ['segment2_name']

        let apiCallsSegment2 = [
            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_current)
                }
            ),

            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_prev)
                }
            ),

            fetch(url,
                {
                    method: "POST",
                    headers: this.json_headers_dict,
                    body: JSON.stringify(filters_twoyears)
                }
            )
        ]

        let responsesSegment2 = await Promise.all(apiCallsSegment2)

        responsesSegment2.forEach(async (segment2_response, idx) => {
            if (segment2_response.status === 200) {
                let segment2_data = await segment2_response.json()
                industry_data_segment2[keys[idx]] = segment2_data['data_object']
            } else {
                console.log('pullData responsesSegment2 got an HTTP error')
                this.setState({ errorAlert_report: true })
            }
        })

        this.setState({ industry_data_years, summary_vals, industry_data_segment1, industry_data_segment2, isLoaded: true })
    }

    async getDatasetInfo() {
        let Datasets_url = Constant.baseapiurl_gen + 'pdt/Datasets'
        let datasets_response = await fetch(Datasets_url)
        if (datasets_response.status === 200) {
            let dataset_data = await datasets_response.json()
            let wholeIndustryDatasets = dataset_data.filter(x => x.is_whole_industry)
            console.log('Whole industry dataset list:')
            console.log(wholeIndustryDatasets)

            let dataset_options = []
            let dataset_segment_names = {}
            wholeIndustryDatasets.forEach(async dataset_data => {
                dataset_options.push({ 'label': dataset_data.description, 'value': dataset_data.dataset_id })
                dataset_segment_names[dataset_data.dataset_id] = {
                    'segment1': dataset_data.selection1_human
                    , 'segment2': dataset_data.selection2_human
                    , 'segment3': dataset_data.selection3_human
                    , 'segment4': dataset_data.selection4_human
                }
            })
            this.setState({ dataset_options, dataset_segment_names, isLoaded: true })
        } else {
            console.log('Failed to load dataset info, getting HTTP ' + datasets_response.status)
            // This will make us open the error control inside render()
            this.setState({ setAPIRequestsFailed: true, showBackdrop: false, isLoaded: true });
        }
    }

    handleChange_IndexSwitchDollars(event) {
        let current_switch = this.state.indexSwitchCheckDollars
        let new_switch = !current_switch
        this.setState({
            indexSwitchCheckDollars: new_switch
        }, () => this.changeSelectedMetric(this.state.selectedMetric))
    }

    async changeSelectedIndustry(selectedOptions) {
        this.setState({ selectedIndustry: selectedOptions })

        // Clear out all filter values so we don't pass them to another industry
        var filters = this.state.filters
        filters.brand = []
        filters.segment1 = []
        filters.segment2 = []
        filters.segment3 = []
        filters.rowdate = []

        // Clear out previously-selected filters as well so we don't use them for the wrong industry
        var selectedFilters = this.state.selectedFilters
        selectedFilters.brand = []
        selectedFilters.segment1 = []
        selectedFilters.segment2 = []
        selectedFilters.segment3 = []
        selectedFilters.rowdate = []

        this.setState({ filters, selectedFilters })

        await this.pullData(selectedOptions.value)
        await this.pullFilters(selectedOptions.value)
    }

    async changeFilters(selectedOptions, filter) {
        let selectedFilters = this.state.selectedFilters
        let filters = this.state.filters
        let datasetnum = this.state.selectedIndustry.value
        selectedFilters[filter] = (selectedOptions || [])
        filters[filter] = (selectedOptions || []).map(option => option.value)
        this.setState({ filters, selectedFilters }, async () => await this.pullFilters(datasetnum), await this.pullData(datasetnum))
    }

    handleChangeTabs(event, newValue, idx) {
        this.setState({ value: newValue })
    }

    handleYearChange(year){
        console.log(year)
        let current_class_y0 = this.state.className_y0
        let current_class_y1 = this.state.className_y1
        let current_class_y2 = this.state.className_y2
        if (year === 'y0') {
            if (current_class_y0 === 'buttonunfilled') {
                current_class_y0 = 'buttonfilled'
            } else {
                current_class_y0 = 'buttonunfilled'
            }
        } else if (year === 'y1') {
            if (current_class_y1 === 'buttonunfilled') {
                current_class_y1 = 'buttonfilled'
            } else {
                current_class_y1 = 'buttonunfilled'
            }
        } else if (year === 'y2') {
            if (current_class_y2 === 'buttonunfilled') {
                current_class_y2 = 'buttonfilled'
            } else {
                current_class_y2 = 'buttonunfilled'
            }
        }

        //Perform some Logic...to fill in the gaps..

        if (current_class_y2 === 'buttonfilled' && current_class_y0 === 'buttonfilled') {
            current_class_y1 = 'buttonfilled'
        }

        //Check the logic to show the series or not...
        let show_two_yago = false
        if (current_class_y2 === 'buttonfilled') {
            show_two_yago = true
        }

        //Check the logic to show the series or not...
        let show_one_yago = false
        if (current_class_y1 === 'buttonfilled') {
            show_one_yago = true
        }

        //Check the logic to show the series or not...
        let show_current = false
        if (current_class_y0 === 'buttonfilled') {
            show_current = true
        }

        this.setState({
            className_y0: current_class_y0,
            className_y1: current_class_y1,
            className_y2: current_class_y2,
            show_two_yago,
            show_one_yago,
            show_current
        })
    }

    handleQuarterChange(quarter) {
        console.log('Quarter changed for ' + quarter)

        // Get Current Quarter Classes...
        let current_class_q1 = this.state.className_q1
        let current_class_q2 = this.state.className_q2
        let current_class_q3 = this.state.className_q3
        let current_class_q4 = this.state.className_q4

        if (quarter === 'Q1') {
            current_class_q1 = (current_class_q1 === 'buttonunfilled') ? 'buttonfilled' : 'buttonunfilled'
        }

        if (quarter === 'Q2') {
            current_class_q2 = (current_class_q2 === 'buttonunfilled') ? 'buttonfilled' : 'buttonunfilled'
        }

        if (quarter === 'Q3') {
            current_class_q3 = (current_class_q3 === 'buttonunfilled') ? 'buttonfilled' : 'buttonunfilled'
        }

        if (quarter === 'Q4') {
            current_class_q4 = (current_class_q4 === 'buttonunfilled') ? 'buttonfilled' : 'buttonunfilled'
        }

        // Perform some Logic to fill in the gaps so we always have contiguous quarters
        if (current_class_q4 === 'buttonfilled' && current_class_q1 === 'buttonfilled') {
            current_class_q2 = 'buttonfilled'
            current_class_q3 = 'buttonfilled'
        }

        if (current_class_q4 === 'buttonfilled' && current_class_q2 === 'buttonfilled') {
            current_class_q3 = 'buttonfilled'
        }

        if (current_class_q3 === 'buttonfilled' && current_class_q1 === 'buttonfilled') {
            current_class_q2 = 'buttonfilled'
        }

        let quarters = []
        if (current_class_q1 === 'buttonfilled') {
            quarters.push(1)
        }

        if (current_class_q2 === 'buttonfilled') {
            quarters.push(2)
        }

        if (current_class_q3 === 'buttonfilled') {
            quarters.push(3)
        }

        if (current_class_q4 === 'buttonfilled') {
            quarters.push(4)
        }

        let current_time_filters = this.state.time_filters
        current_time_filters['quarter'] = quarters
        let datasetnum = this.state.selectedIndustry.value
        this.setState({
            className_q1: current_class_q1,
            className_q2: current_class_q2,
            className_q3: current_class_q3,
            className_q4: current_class_q4,
            time_filters: current_time_filters
        }, () => this.pullData(datasetnum))
    }

    changeSelectedMetric(selectedOptions) {
        console.log(selectedOptions)
        if (selectedOptions) {

            var units_dollars, formatting_object
            if (this.state.indexSwitchCheckDollars) {
                units_dollars = 'dollars'
                formatting_object = {
                    type: units_dollars,
                    axisText: this.state.currencyType,
                    metricName: selectedOptions.label + ' ' + this.state.currencyType
                }
            } else {
                units_dollars = 'units'
                formatting_object = {
                    type: units_dollars,
                    axisText: this.state.unitsvar,
                    metricName: selectedOptions.label + ' ' + this.state.unitsvar
                }
            }

            var metric = selectedOptions.value + units_dollars
            this.setState({
                selectedMetric: selectedOptions,
                metric,
                units_dollars,
                formatting_object
            })
        } else {
            this.setState({
                selectedMetric: { 'label': 'Total', 'value': 'total' },
                metric: 'totalunits',
                units_dollars: 'units',
                formatting_object: {
                    type: 'units',
                    axisText: this.state.unitsvar,
                    metricName: 'Total ' + this.state.unitsvar
                }
            })
        }
    }

    async componentDidMount() {
        console.log('Mounted')
        await this.getDatasetInfo()
    }

    render() {
        console.log(this.state)
        var time_filters = this.state.time_filters
        var units_dollars = 'units'
        if (this.state.indexSwitchCheckDollars) {
            units_dollars = 'dollars'
        }

        const handleCloseDialog = () => {
            this.setState({ setOpen: false })
        }

        const closeModalFunc = () => {
            this.setState({ setAPIRequestsFailed: false, errorAlert_report: false });
        }

        var metric = this.state.metric
        var formatting_object = this.state.formatting_object
        console.log(formatting_object)

        var current_year_data = this.state.industry_data_years['current_data'] || {}
        var one_year_ago_data = this.state.industry_data_years['one_year_ago_data'] || {}
        var two_years_ago_data = this.state.industry_data_years['two_years_ago_data'] || {}

        var distinctdates_forcalc = Object.keys(current_year_data).sort()
        var distinctdates_prior = Object.keys(one_year_ago_data).sort()
        var distinctdates_last = Object.keys(two_years_ago_data).sort()

        // Get end of each annual period

        let current_year_ending = distinctdates_forcalc[distinctdates_forcalc.length - 1] || '2999-12-31'
        let prior_year_ending = distinctdates_prior[distinctdates_prior.length - 1] || '2999-12-31'
        let twoyear_year_ending = distinctdates_last[distinctdates_last.length - 1] || '2999-12-31'

        function formatDate(datestring) {
            let date = Date.UTC(parseInt(datestring.substring(0, 4)), parseInt(datestring.substring(5, 7)) - 1, parseInt(datestring.substring(8, 10)), 0, 0, 0, 0)
            let dt = new Date(date)
            let offset = dt.getTimezoneOffset() * 60 * 1000
            var dateformatted = (new Date(date + offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
            return dateformatted
        }

        let current_year_ending_string = formatDate(current_year_ending)
        let prior_year_ending_string = formatDate(prior_year_ending)
        let twoyear_year_ending_string = formatDate(twoyear_year_ending)

        // Get year for current, year ago, and two years ago

        let current_year_starting = distinctdates_forcalc[1] || '2999-01-01'
        let prior_year_starting = distinctdates_prior[1] || '2999-01-01'
        let twoyear_year_starting = distinctdates_last[1] || '2999-01-01'

        function formatYear(datestring) {
            return datestring.substring(0, 4)
        }

        let current_year_starting_string = formatYear(current_year_starting)
        let prior_year_starting_string = formatYear(prior_year_starting)
        let twoyear_year_starting_string = formatYear(twoyear_year_starting)

        let numweeks_curr = distinctdates_forcalc.length
        let numweeks_prior = distinctdates_prior.length
        let numweeks_twoyear = distinctdates_last.length


        // Summary Data
        let summary_vals = this.state.summary_vals || {}
        summary_vals['current_data'] = summary_vals['current_data'] || {}
        summary_vals['one_year_ago_data'] = summary_vals['one_year_ago_data'] || {}
        summary_vals['two_years_ago_data'] = summary_vals['two_years_ago_data'] || {}

        let curr_to_prev_totalunits_diff = summary_vals['current_data']['totalunits'] - summary_vals['one_year_ago_data']['totalunits']
        let oneyear_to_twoyear_totalunits_diff = summary_vals['one_year_ago_data']['totalunits'] - summary_vals['two_years_ago_data']['totalunits']

        let curr_to_prev_totaldollars_diff = summary_vals['current_data']['totaldollars'] - summary_vals['one_year_ago_data']['totaldollars']
        let oneyear_to_twoyear_totaldollars_diff = summary_vals['one_year_ago_data']['totaldollars'] - summary_vals['two_years_ago_data']['totaldollars']

        let curr_to_prev_promotedprice_diff = (summary_vals['current_data']['promoteddollars'] / summary_vals['current_data']['promotedunits']) - (summary_vals['one_year_ago_data']['promoteddollars'] / summary_vals['one_year_ago_data']['promotedunits'])
        let oneyear_to_twoyear_promotedprice_diff = (summary_vals['one_year_ago_data']['promoteddollars'] / summary_vals['one_year_ago_data']['promotedunits']) - (summary_vals['two_years_ago_data']['promoteddollars'] / summary_vals['two_years_ago_data']['promotedunits'])

        let curr_to_prev_nonpromotedprice_diff = (summary_vals['current_data']['nonpromoteddollars'] / summary_vals['current_data']['nonpromotedunits']) - (summary_vals['one_year_ago_data']['nonpromoteddollars'] / summary_vals['one_year_ago_data']['nonpromotedunits'])
        let oneyear_to_twoyear_nonpromotedprice_diff = (summary_vals['one_year_ago_data']['nonpromoteddollars'] / summary_vals['one_year_ago_data']['nonpromotedunits']) - (summary_vals['two_years_ago_data']['nonpromoteddollars'] / summary_vals['two_years_ago_data']['nonpromotedunits'])

        let curr_to_prev_promotedunitspct_diff = (summary_vals['current_data']['promotedunits'] / summary_vals['current_data']['totalunits']) - (summary_vals['one_year_ago_data']['promotedunits'] / summary_vals['one_year_ago_data']['totalunits'])
        let oneyear_to_twoyear_promotedunitspct_diff = (summary_vals['one_year_ago_data']['promotedunits'] / summary_vals['one_year_ago_data']['totalunits']) - (summary_vals['two_years_ago_data']['promotedunits'] / summary_vals['two_years_ago_data']['totalunits'])

        let curr_to_prev_avgpromoteddiscpct_diff = (summary_vals['current_data']['weightedpctdisc_withoutno'] / summary_vals['current_data']['totalunits_withoutno']) - (summary_vals['one_year_ago_data']['weightedpctdisc_withoutno'] / summary_vals['one_year_ago_data']['totalunits_withoutno'])
        let oneyear_to_twoyear_avgpromoteddiscpct_diff = (summary_vals['one_year_ago_data']['weightedpctdisc_withoutno'] / summary_vals['one_year_ago_data']['totalunits_withoutno']) - (summary_vals['two_years_ago_data']['weightedpctdisc_withoutno'] / summary_vals['two_years_ago_data']['totalunits_withoutno'])

        let curr_to_prev_promotedprice_diff_pct = curr_to_prev_promotedprice_diff / (summary_vals['one_year_ago_data']['promoteddollars'] / summary_vals['one_year_ago_data']['promotedunits'])
        let oneyear_to_twoyear_promotedprice_diff_pct = oneyear_to_twoyear_promotedprice_diff / (summary_vals['two_years_ago_data']['promoteddollars'] / summary_vals['two_years_ago_data']['promotedunits'])

        let curr_to_prev_nonpromotedprice_diff_pct = curr_to_prev_nonpromotedprice_diff / (summary_vals['one_year_ago_data']['nonpromoteddollars'] / summary_vals['one_year_ago_data']['nonpromotedunits'])
        let oneyear_to_twoyear_nonpromotedprice_diff_pct = oneyear_to_twoyear_nonpromotedprice_diff / (summary_vals['two_years_ago_data']['nonpromoteddollars'] / summary_vals['two_years_ago_data']['nonpromotedunits'])

        let curr_to_prev_totaldollars_diff_pct = curr_to_prev_totaldollars_diff / summary_vals['one_year_ago_data']['totaldollars']
        let oneyear_to_twoyear_totaldollars_diff_pct = oneyear_to_twoyear_totaldollars_diff / summary_vals['two_years_ago_data']['totaldollars']

        let curr_to_prev_totalunits_diff_pct = curr_to_prev_totalunits_diff / summary_vals['one_year_ago_data']['totalunits']
        let oneyear_to_twoyear_totalunits_diff_pct = oneyear_to_twoyear_totalunits_diff / summary_vals['two_years_ago_data']['totalunits']

        function yoy_change_point_formatter(series_name, y) {
            var yval = y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
            return '<span>' + series_name + '<br> ' + yval + '<br> ' + '</span>'
        }

        var seriesPCTDiffs = [
            {
                name: 'Current Year',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return yoy_change_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: [curr_to_prev_totaldollars_diff_pct
                    , curr_to_prev_totalunits_diff_pct
                    , curr_to_prev_promotedunitspct_diff
                    , curr_to_prev_avgpromoteddiscpct_diff
                    , curr_to_prev_promotedprice_diff_pct
                    , curr_to_prev_nonpromotedprice_diff_pct
                ],
                visible: this.state.show_current
            },
            {
                name: 'Previous Year',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return yoy_change_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: [
                    oneyear_to_twoyear_totaldollars_diff_pct
                    , oneyear_to_twoyear_totalunits_diff_pct
                    , oneyear_to_twoyear_promotedunitspct_diff
                    , oneyear_to_twoyear_avgpromoteddiscpct_diff
                    , oneyear_to_twoyear_promotedprice_diff_pct
                    , oneyear_to_twoyear_nonpromotedprice_diff_pct
                ],
                visible: this.state.show_one_yago

            },
        ]
        var segment1data = this.state.industry_data_segment1 || {}
        var current_year_segment1data = segment1data['current_data'] || {}
        var one_year_ago_segment1data = segment1data['one_year_ago_data'] || {}
        var two_years_ago_segment1data = segment1data['two_years_ago_data'] || {}

        let segment1s_current = Object.keys(current_year_segment1data)
        let segment1s_yago = Object.keys(one_year_ago_segment1data)
        let segment1s_2yago = Object.keys(two_years_ago_segment1data)

        let segment1s = Array.from(new Set(segment1s_current.concat(segment1s_yago).concat(segment1s_2yago))).sort()

        function pct_on_promo_point_formatter(series_name, y) {
            var yval = y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
            return '<span>' + series_name + '<br> ' + yval + '<br> ' + '</span>'
        }

        var seriesSegment1_PctPromo = [
            {
                name: 'Previous Year',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return pct_on_promo_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: segment1s.map(segment => (one_year_ago_segment1data[segment] || {})['promotedunits'] / (one_year_ago_segment1data[segment] || {})['totalunits']),
                visible: this.state.show_one_yago
            },
            {
                name: 'Current Year',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return pct_on_promo_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: segment1s.map(segment => (current_year_segment1data[segment] || {})['promotedunits'] / (current_year_segment1data[segment] || {})['totalunits']),
                visible: this.state.show_current
            },
        ]

        if (twoyear_year_starting_string != '2999') {
            seriesSegment1_PctPromo = [{
                name: 'Two Years Ago',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return pct_on_promo_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: segment1s.map(segment => (two_years_ago_segment1data[segment] || {})['promotedunits'] / (two_years_ago_segment1data[segment] || {})['totalunits']),
                visible: this.state.show_two_yago
            }].concat(seriesSegment1_PctPromo)
        }

        var segment2data = this.state.industry_data_segment2 || {}
        var current_year_segment2data = segment2data['current_data'] || {}
        var one_year_ago_segment2data = segment2data['one_year_ago_data'] || {}
        var two_years_ago_segment2data = segment2data['two_years_ago_data'] || {}

        let segment2s_current = Object.keys(current_year_segment2data)
        let segment2s_yago = Object.keys(one_year_ago_segment2data)
        let segment2s_2yago = Object.keys(two_years_ago_segment2data)

        let segment2s = Array.from(new Set(segment2s_current.concat(segment2s_yago).concat(segment2s_2yago))).sort()

        var seriesSegment2_PctPromo = [
            {
                name: 'Previous Year',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return pct_on_promo_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: segment2s.map(segment => (one_year_ago_segment2data[segment] || {})['promotedunits'] / (one_year_ago_segment2data[segment] || {})['totalunits']),
                visible: this.state.show_one_yago
            },
            {
                name: 'Current Year',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return pct_on_promo_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: segment2s.map(segment => (current_year_segment2data[segment] || {})['promotedunits'] / (current_year_segment2data[segment] || {})['totalunits']),
                visible: this.state.show_current
            },
        ]

        if (twoyear_year_starting_string != '2999') {
            seriesSegment2_PctPromo = [{
                name: 'Two Years Ago',
                type: 'column',
                tooltip: {
                    pointFormatter: function () {
                        return pct_on_promo_point_formatter(this.series.name, this.y)
                    },
                    headerFormat: ''
                },
                data: segment2s.map(segment => (two_years_ago_segment2data[segment] || {})['promotedunits'] / (two_years_ago_segment2data[segment] || {})['totalunits']),
                visible: this.state.show_two_yago
            }].concat(seriesSegment2_PctPromo)
        }

        let brands_selected = this.state.selectedFilters['brand'].map(brand=>brand.label)
        let segment1_selected = this.state.selectedFilters['segment1'].map(segment1 => segment1.label)
        let segment2_selected = this.state.selectedFilters['segment2'].map(segment2 => segment2.label)
        let segment3_selected = this.state.selectedFilters['segment3'].map(segment3 => segment3.label)
        let segment4_selected = (this.state.selectedFilters['segment4'] || []).map(segment4 => segment4.label)

        // function to create string for title
        function create_title_info(selectionArray, selectionTitle) {
            var selectionInfo, titleString
            if (selectionTitle) {
                if (selectionArray.length === 0) {
                    selectionInfo = 'All'
                } else {
                    selectionInfo = selectionArray.join(', ')
                }

                if (selectionInfo.length > 50) {
                    selectionInfo = selectionArray.length + ' ' + selectionArray + ' selected'
                }

                titleString = selectionTitle + ': ' + selectionInfo
            } else {
                titleString = ''
            }

            return titleString
        }

        let brandInfo = create_title_info(brands_selected, 'Brands')
        let segment1Info = create_title_info(segment1_selected, ((this.state.dataset_segment_names || {})[(this.state.selectedIndustry || {}).value] || {})['segment1'])
        let segment2Info = create_title_info(segment2_selected, ((this.state.dataset_segment_names || {})[(this.state.selectedIndustry || {}).value] || {})['segment2'])
        let segment3Info = create_title_info(segment3_selected, ((this.state.dataset_segment_names || {})[(this.state.selectedIndustry || {}).value] || {})['segment3'])
        let segment4Info = create_title_info(segment4_selected, ((this.state.dataset_segment_names || {})[(this.state.selectedIndustry || {}).value] || {})['segment4'])

        var common_title_str = '<br>'
            + (this.state.selectedIndustry || { 'label': 'Total Industry Data' }).label + '<br>';
        if (numweeks_curr > 0) {
            common_title_str += numweeks_curr + ' Weeks Ending: ' + current_year_ending_string + ', ';
        }
        if (numweeks_prior > 0) {
            common_title_str += numweeks_prior + ' Weeks Ending: ' + prior_year_ending_string + ', ';
        }
        if (numweeks_twoyear > 0) {
            common_title_str += numweeks_twoyear + ' Weeks Ending: ' + twoyear_year_ending_string + '<br>';
        }

        common_title_str += brandInfo + '<br>';

        common_title_str += segment1Info + ', ' + segment2Info;
        if (segment3Info != '') {
            common_title_str += ', ' + segment3Info;
        }
        if (segment4Info != '') {
            common_title_str += ', ' + segment4Info;
        }

        var title_string = formatting_object.metricName + common_title_str;
        var title_string_pctpromo_weekly = 'Percent of ' + units_dollars + ' on Promotion' + common_title_str;
        var title_string_yoy = 'Year over Year Change' + common_title_str;
        var title_string_pctpromo = 'Percent of Promoted Units' + common_title_str;

        function weekly_metrics_point_formatter(series_name, week_num, y, week_desc) {
            var yval
            if (formatting_object.type === 'units') {
                yval = Math.round(y).toLocaleString()
            } else {
                yval = Math.round(y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
            }
            let lowest_quarter = Math.min(...time_filters['quarter'] || [1, 2, 3, 4])
            week_num = String(parseInt(week_num) + 1 + ((lowest_quarter - 1) * 13))
            return '<span>' + series_name + '<br> ' + 'Week ' + week_num + ': ' + week_desc + '<br> ' + yval + '<br> ' + '</span>'
        }

        var seriesYOY_brand = [
            {
                name: 'Current Year ' + formatting_object.metricName,
                type: 'spline',
                tooltip: {
                    pointFormatter: function () {
                        return weekly_metrics_point_formatter(this.series.name, this.x, this.y, distinctdates_forcalc[this.x])
                    },
                    headerFormat: ''
                },
                data: distinctdates_forcalc.map(date => ((current_year_data[date] || {})[metric])),
                visible: this.state.show_current
            },
            {
                name: 'Previous Year ' + formatting_object.metricName,
                type: 'spline',
                color: '#4e106f',
                tooltip: {
                    pointFormatter: function () {
                        return weekly_metrics_point_formatter(this.series.name, this.x, this.y, distinctdates_prior[this.x])
                    },
                    headerFormat: ''
                },
                data: distinctdates_prior.map(date => ((one_year_ago_data[date] || {})[metric] || 0)),
                visible: this.state.show_one_yago
            }
        ]

        if (twoyear_year_starting_string != '2999') {
            seriesYOY_brand.push({
                name: 'Two Years Ago ' + formatting_object.metricName,
                type: 'spline',
                color: '#ef2a79',
                tooltip: {
                    pointFormatter: function () {
                        return weekly_metrics_point_formatter(this.series.name, this.x, this.y, distinctdates_last[this.x])
                    },
                    headerFormat: ''
                },
                data: distinctdates_last.map(date => ((two_years_ago_data[date] || {})[metric] || 0)),
                visible: this.state.show_two_yago
            });
        }

        function yoy_pct_series_point_formatter(series_name, week_num, y, week_desc) {
            var yval = y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
            let lowest_quarter = Math.min(...time_filters['quarter'] || [1, 2, 3, 4])
            week_num = String(parseInt(week_num) + 1 + ((lowest_quarter - 1) * 13))
            return '<span>' + series_name + '<br> ' + 'Week ' + week_num + ': ' + week_desc + '<br> ' + yval + '<br> ' + '</span>'
        }

        var seriesYOY_brand_promotedpct = [
            {
                name: 'Current Year ' + formatting_object.metricName,
                type: 'spline',
                tooltip: {
                    pointFormatter: function () {
                        return yoy_pct_series_point_formatter(this.series.name, this.x, this.y, distinctdates_forcalc[this.x])
                    },
                    headerFormat: ''
                },
                data: distinctdates_forcalc.map(date => ((current_year_data[date] || {})['promoted' + units_dollars]) / ((current_year_data[date] || {})['total' + units_dollars])),
                visible: this.state.show_current
            },
            {
                name: 'Previous Year ' + formatting_object.metricName,
                type: 'spline',
                color: '#4e106f',
                tooltip: {
                    pointFormatter: function () {
                        return yoy_pct_series_point_formatter(this.series.name, this.x, this.y, distinctdates_prior[this.x])
                    },
                    headerFormat: ''
                },
                data: distinctdates_prior.map(date => ((one_year_ago_data[date] || {})['promoted' + units_dollars]) / ((one_year_ago_data[date] || {})['total' + units_dollars])),
                visible: this.state.show_one_yago
            }
        ]

        if (twoyear_year_starting_string != '2999') {
            seriesYOY_brand_promotedpct.push({
                name: 'Two Years Ago ' + formatting_object.metricName,
                type: 'spline',
                color: '#ef2a79',
                tooltip: {
                    pointFormatter: function () {
                        return yoy_pct_series_point_formatter(this.series.name, this.x, this.y, distinctdates_last[this.x])
                    },
                    headerFormat: ''
                },
                data: distinctdates_last.map(date => ((two_years_ago_data[date] || {})['promoted' + units_dollars]) / ((two_years_ago_data[date] || {})['total' + units_dollars])),
                visible: this.state.show_two_yago
            });
        }

        var currencycode = 'USD'
        var formatting_obj_table
        if (formatting_object.type !== 'units') {
            formatting_obj_table = { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }
        }

        return (
            <Layout title="Industry Output">
                <Loaders
                    state={this.state || {}}
                />

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <Dialog
                        open={this.state.setAPIRequestsFailed}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ overflowY: 'scroll' }}
                    >
                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                The list of Whole-Industry datasets failed to load.  You can try refreshing this page.
                                If it still does not load correctly, please reach out to pricing support at Pricing.Support@npd.com
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={closeModalFunc} className="btn btn-secondary">
                                Ok
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <Dialog
                        open={this.state.errorAlert_report}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ overflowY: 'scroll' }}
                    >
                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                The whole-industry data failed to load for the selected industry.  You can try refreshing this page
                                and selecting that industry again. If it still does not load correctly, please reach out to pricing
                                support at Pricing.Support@npd.com
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={closeModalFunc} className="btn btn-secondary">
                                Ok
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div className="contentHolderGradient" style={{
                      width: '100%'
                    , position: 'absolute'
                    , left: '120px'
                    , top: '60px'
                    , minWidth: '1200px'
                    , maxWidth: 'calc(100vw - 120px)'
                    , minHeight: '100%'
                    , padding: '20px'
                }}>
                    INDUSTRY OUTPUT

                    <div className="col-sm-3" style={{ padding: '15px' }}>
                        <span className="formfieldtitle">Select An Industry</span>
                        <Select
                            options={this.state.dataset_options}
                            placeholder={'Select An Industry'}
                            onChange={this.changeSelectedIndustry}
                            value={this.state.selectedIndustry}
                            styles={Constant.dropdownStyles}
                            styles={selectColourStyles}
                        />
                    </div>

                    {this.state.selectedIndustry ?
                        <div className='col-sm-12 row'>
                            {Object.keys(this.state.filter_options || {}).sort().map(filter => {
                                let filtername = this.state.dataset_segment_names[this.state.selectedIndustry.value][filter] || filter.charAt(0).toUpperCase() + filter.slice(1)
                                return <div className="col-sm-3" style={{ padding: '15px' }}>
                                    <span className="formfieldtitle">{'Select A ' + filtername}</span>
                                    <Select
                                        options={this.state.filter_options[filter]}
                                        placeholder={'Select A ' + filtername}
                                        onChange={(options, filterVal)=>this.changeFilters(options, filter)}
                                        value={this.state.selectedFilters[filter]}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                        isMulti
                                    />
                                </div>
                            })}
                        </div>
                        :
                        <div />
                    }

                    {this.state.selectedIndustry ?
                        <div className='col-sm-12 row'>
                            <div className="col-sm-3" style={{ padding: '15px' }}>
                                <span className="formfieldtitle">{'Select Years'}</span>
                                <br />
                                {twoyear_year_starting_string != '2999' ?
                                    <button
                                        className={this.state.className_y2}
                                        onClick={() => this.handleYearChange('y2')}
                                    >
                                        {twoyear_year_starting_string}
                                    </button>
                                    :
                                    <br />
                                }

                                {prior_year_starting_string != '2999' ?
                                    <button
                                        className={this.state.className_y1}
                                        onClick={() => this.handleYearChange('y1')}
                                    >
                                        {prior_year_starting_string}
                                    </button>
                                    :
                                    <br />
                                }

                                {current_year_starting_string != '2999' ?
                                    <button
                                        className={this.state.className_y0}
                                        onClick={() => this.handleYearChange('y0')}
                                    >
                                        {current_year_starting_string}
                                    </button>
                                    :
                                    <br />
                                }
                            </div>

                            <div className="col-sm-3" style={{ padding: '15px' }}>
                                <span className="formfieldtitle">{'Select Quarters'}</span>
                                <br/>
                                <button
                                    className={this.state.className_q1}
                                    onClick={() => this.handleQuarterChange('Q1')}
                                >
                                    Q1
                                </button>

                                <button
                                    className={this.state.className_q2}
                                    onClick={() => this.handleQuarterChange('Q2')}
                                >
                                    Q2
                                </button>

                                <button
                                    className={this.state.className_q3}
                                    onClick={() => this.handleQuarterChange('Q3')}
                                >
                                    Q3
                                </button>

                                <button
                                    className={this.state.className_q4}
                                    onClick={() => this.handleQuarterChange('Q4')}
                                >
                                    Q4
                                </button>
                            </div>
                        </div>
                        :
                        <div />
                    }

                    {this.state.selectedIndustry ?
                        <div className="row" >
                            <div className="col-sm-12" style={{ maxWidth: '1500px' }}>
                                <div align='center' className="contentHolderTight" >
                                    <h5>Total Industry Views</h5>
                                    <Constant.NPDAppbar
                                        position="static"
                                        style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }}
                                        className=""
                                    >
                                        <Constant.NPDTabs
                                            value={this.state.value}
                                            onChange={(event, newValue) => this.handleChangeTabs(event, newValue)}
                                            aria-label="simple tabs example"
                                            indicatorColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                        >
                                            <Constant.NPDTab
                                                label={'Percent on Promotion by Week'} {...a11yProps(0)}
                                                key={'0_Percent on Promotion by Week'}
                                            />

                                            <Constant.NPDTab
                                                label={'Year over Year Change'} {...a11yProps(1)}
                                                key={'1_Year over Year Change'}
                                            />

                                            <Constant.NPDTab
                                                label={'Percent on Promotion by ' + this.state.dataset_segment_names[this.state.selectedIndustry.value]['segment1']} {...a11yProps(2)}
                                                key={'2_Percent on Promotion by Category'}
                                            />

                                            <Constant.NPDTab
                                                label={'Percent on Promotion by ' + this.state.dataset_segment_names[this.state.selectedIndustry.value]['segment2']} {...a11yProps(3)}
                                                key={'2_Percent on Promotion by SubCategory'}
                                            />

                                            <Constant.NPDTab
                                                label={'Weekly Metrics'} {...a11yProps(4)}
                                                key={'3_Weekly Metrics'}
                                            />
                                        </Constant.NPDTabs>
                                    </Constant.NPDAppbar>

                                    <TabPanel
                                        value={this.state.value}
                                        index={0}
                                        key={'TabPanel_0'}
                                    >
                                        <div style={{ height: '100%', width: "100%", padding: '15px' }} className="col-sm-12">
                                            <div style={{ width: "100%", height: "100%" }}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {
                                                            chart: {
                                                                zoomType: 'xy',
                                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                                            },
                                                            metadata: {},
                                                            caption: {
                                                                text: ''
                                                            },
                                                            title: {
                                                                text: title_string_pctpromo_weekly
                                                            },
                                                            series: seriesYOY_brand_promotedpct,
                                                            xAxis: {
                                                                title: {
                                                                    text: 'Week Number'
                                                                },
                                                                allowDecimals: false,
                                                                labels: {
                                                                    formatter: function () {
                                                                        //Look and See what the min value of quarters is...use that to multiply the week number by.
                                                                        let lowest_quarter = Math.min(...time_filters['quarter'] || [1,2,3,4])
                                                                        return this.value + 1 + ((lowest_quarter - 1) * 13);
                                                                    }
                                                                }
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: 'Percent of ' + this.state.formatting_object.axisText
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    labels: {
                                                                        formatter: function () {
                                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                                        }
                                                                    },
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                spline: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                },
                                                                series: {
                                                                    states: {
                                                                        inactive: {
                                                                            opacity: 1
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            exporting: {
                                                                showTable: false,
                                                                tableCaption: false
                                                            },
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        value={this.state.value}
                                        index={1}
                                        key={'TabPanel_1'}
                                    >
                                        <div style={{ height: '100%', width: "100%", padding: '15px' }} className="col-sm-12">
                                            <div style={{ width: "100%", height: "100%" }}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {
                                                            chart: {
                                                                zoomType: 'xy',
                                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                                            },
                                                            metadata: {},
                                                            caption: {
                                                                text: ''
                                                            },
                                                            title: {
                                                                text: title_string_yoy
                                                            },
                                                            series: seriesPCTDiffs,
                                                            xAxis: {
                                                                title: {
                                                                    text: ''
                                                                },
                                                                categories: ['Total Dollar Growth', 'Total Unit Growth', '% of Promoted Units Point Change', 'Avg. Promoted Discount % Point Change', 'Promoted Price Change', 'Non-Promoted Price Change']
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: this.state.formatting_object.axisText
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    labels: {
                                                                        formatter: function () {
                                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }
                                                                    },
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                column: {
                                                                    stacking: undefined,
                                                                    dataLabels: {
                                                                        enabled: true,
                                                                        formatter: function () {
                                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        },
                                                                        color: 'black',
                                                                        borderRadius: 5,
                                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                        borderWidth: 1,
                                                                        borderColor: '#d6d7d8',
                                                                        padding: 2,
                                                                        style: {
                                                                            textOutline: 'none',
                                                                            fontSize: '20px'
                                                                        },
                                                                    }
                                                                },
                                                                spline: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                },
                                                                series: {
                                                                    states: {
                                                                        inactive: {
                                                                            opacity: 1
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            exporting: {
                                                                showTable: false,
                                                                tableCaption: false
                                                            },
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        value={this.state.value}
                                        index={2}
                                        key={'TabPanel_2'}
                                    >
                                        <div style={{ height: '100%', width: "100%", padding: '15px' }} className="col-sm-12">
                                            <div style={{ width: "100%", height: "100%" }}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {
                                                            chart: {
                                                                zoomType: 'xy',
                                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                                            },
                                                            metadata: {},
                                                            caption: {
                                                                text: ''
                                                            },
                                                            title: {
                                                                text: title_string_pctpromo
                                                            },
                                                            series: seriesSegment1_PctPromo,
                                                            xAxis: {
                                                                title: {
                                                                    text: ''
                                                                },
                                                                categories: segment1s
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: this.state.formatting_object.axisText
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    labels: {

                                                                        formatter: function () {
                                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }
                                                                    },
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                column: {
                                                                    stacking: undefined,
                                                                    dataLabels: {
                                                                        enabled: true,
                                                                        formatter: function () {
                                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        },
                                                                        color: 'black',
                                                                        borderRadius: 5,
                                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                        borderWidth: 1,
                                                                        borderColor: '#d6d7d8',
                                                                        padding: 2,
                                                                        style: {
                                                                            textOutline: 'none',
                                                                            fontSize: '20px'
                                                                        },
                                                                    }
                                                                },
                                                                spline: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                },
                                                                series: {
                                                                    states: {
                                                                        inactive: {
                                                                            opacity: 1
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            exporting: {
                                                                showTable: false,
                                                                tableCaption: false
                                                            },
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        value={this.state.value}
                                        index={3}
                                        key={'TabPanel_3'}
                                    >
                                        <div style={{ height: '100%', width: "100%", padding: '15px' }} className="col-sm-12">
                                            <div style={{ width: "100%", height: "100%" }}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {
                                                            chart: {
                                                                zoomType: 'xy',
                                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                                            },
                                                            metadata: {},
                                                            caption: {
                                                                text: ''
                                                            },
                                                            title: {
                                                                text: title_string_pctpromo
                                                            },
                                                            series: seriesSegment2_PctPromo,
                                                            xAxis: {
                                                                title: {
                                                                    text: ''
                                                                },
                                                                categories: segment2s
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: this.state.formatting_object.axisText
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    labels: {
                                                                        formatter: function () {
                                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }
                                                                    },
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                column: {
                                                                    stacking: undefined,
                                                                    dataLabels: {
                                                                        enabled: true,
                                                                        formatter: function () {
                                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        },
                                                                        color: 'black',
                                                                        borderRadius: 5,
                                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                        borderWidth: 1,
                                                                        borderColor: '#d6d7d8',
                                                                        padding: 2,
                                                                        style: {
                                                                            textOutline: 'none',
                                                                            fontSize: '20px'
                                                                        },
                                                                    }
                                                                },
                                                                spline: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                },
                                                                series: {
                                                                    states: {
                                                                        inactive: {
                                                                            opacity: 1
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            exporting: {
                                                                showTable: false,
                                                                tableCaption: false
                                                            },
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        value={this.state.value}
                                        index={4}
                                        key={'TabPanel_4'}
                                    >
                                        <div style={{ height: '100%', width: "100%", padding: '15px' }} className="col-sm-12">
                                            <div className='col-sm-12 row'>
                                                <div className="col-sm-3" style={{ padding: '15px' }}>
                                                    <span className="formfieldtitle">Select A Metric</span>
                                                    <Select
                                                        options={this.state.metricOptions}
                                                        placeholder={'Select A Metric'}
                                                        onChange={this.changeSelectedMetric}
                                                        value={this.state.selectedMetric}
                                                        styles={Constant.dropdownStyles}
                                                        styles={selectColourStyles}
                                                    />
                                                </div>

                                                <div className="col-sm-3 row" style={{ padding: '15px' }}>
                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                        <span style={{ lineHeight: '5' }}>Units</span>
                                                        <div style={{ lineHeight: '5' }}>
                                                            <Switch
                                                                checked={this.state.indexSwitchCheckDollars}
                                                                onChange={this.handleChange_IndexSwitchDollars}
                                                                color="secondary"
                                                            />
                                                        </div>
                                                        <span style={{ lineHeight: '5' }}>{this.state.currencyType} </span>
                                                    </ThemeProvider>
                                                </div>
                                            </div>
                                            <div style={{ width: "100%", height: "100%" }}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={
                                                        {
                                                            chart: {
                                                                zoomType: 'xy',
                                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                                            },
                                                            metadata: {},
                                                            caption: {
                                                                text: ''
                                                            },
                                                            title: {
                                                                text: title_string
                                                            },
                                                            series: seriesYOY_brand,
                                                            xAxis: {
                                                                title: {
                                                                    text: 'Week Number'
                                                                },
                                                                allowDecimals: false,
                                                                labels: {
                                                                    formatter: function () {
                                                                        let lowest_quarter = Math.min(...time_filters['quarter'] || [1, 2, 3, 4])
                                                                        return this.value + 1 + ((lowest_quarter - 1) * 13);
                                                                    }
                                                                }
                                                            },
                                                            yAxis: [
                                                                {
                                                                    title: {
                                                                        text: this.state.formatting_object.axisText
                                                                    },
                                                                    gridLineColor: "#CCC",
                                                                    gridLineWidth: 1,
                                                                    labels: {
                                                                        formatter: function () {

                                                                            function nFormatter(num, digits, axisMax) {
                                                                                var si = [
                                                                                    { value: 1E18, symbol: "E" },
                                                                                    { value: 1E15, symbol: "P" },
                                                                                    { value: 1E12, symbol: "T" },
                                                                                    { value: 1E9, symbol: "B" },
                                                                                    { value: 1E6, symbol: "M" },
                                                                                    { value: 1E3, symbol: "k" }
                                                                                ], i;

                                                                                let symbolForAxis = " "
                                                                                let valueForAxis = 1

                                                                                for (i = 0; i < si.length; i++) {
                                                                                    if (axisMax >= si[i].value) {
                                                                                        symbolForAxis = si[i].symbol
                                                                                        valueForAxis = si[i].value
                                                                                        break;
                                                                                    }
                                                                                }

                                                                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()
                                                                            }

                                                                            if (formatting_object.type === 'units') {
                                                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                                                            } else {
                                                                                let value = nFormatter(this.value, 1, this.axis.max)
                                                                                let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                                                let symbol = value.substring(value.length - 1)
                                                                                return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                                            }
                                                                        }
                                                                    },
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                column: {
                                                                    stacking: 'normal',
                                                                    dataLabels: {
                                                                        enabled: false,
                                                                        formatter: function () {
                                                                            function nFormatter(num, digits) {
                                                                                var si = [
                                                                                    { value: 1E18, symbol: "E" },
                                                                                    { value: 1E15, symbol: "P" },
                                                                                    { value: 1E12, symbol: "T" },
                                                                                    { value: 1E9, symbol: "B" },
                                                                                    { value: 1E6, symbol: "M" },
                                                                                    { value: 1E3, symbol: "k" }
                                                                                ], i;
                                                                                for (i = 0; i < si.length; i++) {
                                                                                    if (num >= si[i].value) {
                                                                                        return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                                    }
                                                                                }
                                                                                return num.toString();
                                                                            }

                                                                            return nFormatter(this.point.y, 0).toLocaleString()
                                                                        },
                                                                        color: 'black',
                                                                        borderRadius: 5,
                                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                        borderWidth: 1,
                                                                        borderColor: '#d6d7d8',
                                                                        padding: 2,
                                                                        style: {
                                                                            textOutline: 'none',
                                                                            fontSize: '20px'
                                                                        },
                                                                    }
                                                                },
                                                                spline: {
                                                                    marker: {
                                                                        enabled: false
                                                                    }
                                                                },
                                                                series: {
                                                                    states: {
                                                                        inactive: {
                                                                            opacity: 1
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            exporting: {
                                                                showTable: false,
                                                                tableCaption: false
                                                            },
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                        :
                        <div />
                    }
                </div>
            </Layout>
        )
    }
}
