import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select, { components } from 'react-select'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import { Layout } from '../Layout';
import IconButton from '@material-ui/core/IconButton';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Popover from '@material-ui/core/Popover'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CloseIcon from '@material-ui/icons/Close';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import * as Constant from '../constants'
import selectColourStyles from '../styles/SelectColourStyles'
import dropdownStyles from '../styles/DropdownStyles'
import FetchConfigWithDefault from './Functions/Utilities/FetchConfigWithDefault'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 750,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

//const dropdownStyles = {
//    control: (styles) => ({
//        ...styles,
//        backgroundColor: "white",
//        maxHeight: "225px",
//        overflowY: "auto"
//    }),
//}

export class PDTReportEntry extends Component {
    static displayName = PDTReportEntry.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickcancel = this.handleClickcancel.bind(this);
        this.check_start_date_on_change = this.check_start_date_on_change.bind(this)
        this.check_end_date_on_change = this.check_end_date_on_change.bind(this)
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        this.handleDateStart_monthly = this.handleDateStart_monthly.bind(this)
        this.handleDateEnd_monthly = this.handleDateEnd_monthly.bind(this)
        this.FetchConfigWithDefault = FetchConfigWithDefault.bind(this)
        this.state =
        {
            date: new Date(),
            startDate: new Date(),
            setStartDate: new Date(),
            geos: [],
            brands: [],
            items: [],
            datasets: [],
            dataset_info: {},
            reportname: "",
            Geochosen: [],
            Brandchosen: [],
            Itemchosen: [],
            DataSetChosen: 0,
            selectedstart: null,
            selectedend: null,
            allBrandsChosen: false,

            // Form Errors
            num_form_errors: 0,

            report_name_missing: false,
            start_date_missing: false,
            start_date_ltstart: false,
            start_date_gtend: false,
            start_date_invalid: false,

            end_date_ltstart: false,
            end_date_gtend: false,
            end_date_missing: false,
            end_date_invalid: false,

            geos_missing: false,
            brands_missing: false,
            items_missing: false,
            start_gt_end: false,
            // Snackbars
            showBackdrop_CreateReport: false,
            showSnackbar_CreateReportSuccess: false,
            showSnackbar_CreateReportError: false,
            // dates
            pastdate: Datetime.moment().set({ 'year': 2019, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            futuredate: Datetime.moment().set({ 'year': 2021, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            pastdate_formatted: '2019-01-01',
            futuredate_formatted: '2021-01-01',
            // Info Icons
            openInfoProject: false,
            anchorElProjectInfo: null,
            errorAlert: false,
            allReportNames: [],
            report_name_same: false,
            brand_string: "",
            item_string: "",
            geo_string: "",
            displayReloadDialog: false,
            errorAlert_report: false,
            errorAlert_noAccess: false,
            showBackdrop: false,
            hasstagingval: false
        }
    }

    handleClick() {
        // Check for errors before we submit the report for update or create...
        var num_errors = 0;
        console.log(this.state)
        var appname = Constant.whereamilist[3]

        // Report Name
        if (this.state.reportname === "") {
            num_errors += 1
            this.setState({ report_name_missing: true })
            console.log('No Report Name entered')
        } else {
            this.setState({ report_name_missing: false })
        }

        // Start Date Missing or Wrong Format
        if (this.state.selectedstart) {
            this.setState({ start_date_missing: false })
        } else {
            num_errors += 1
            this.setState({ start_date_missing: true })
            console.log('No Start Date entered')
        }

        // End Date Missing or Wrong Format
        if (this.state.selectedend) {
            this.setState({ end_date_missing: false })
        } else {
            num_errors += 1
            this.setState({ end_date_missing: true })
            console.log('No End Date entered')
        }

        // Missing Geos
        if (this.state.Geochosen.length === 0) {
            num_errors += 1
            this.setState({ geos_missing: true })
            console.log('No Geo selected')
        } else {
            this.setState({ geos_missing: false })
        }

        // Missing Brands
        if (this.state.Brandchosen.length === 0) {
            num_errors += 1
            this.setState({ brands_missing: true })
            console.log('No Brand selected -- must be at least one selected')
        } else {
            this.setState({ brands_missing: false })
        }

        // Start date after End date
        if (this.state.selectedstart && this.state.selectedend && this.state.selectedstart > this.state.selectedend) {
            num_errors += 1
            this.setState({ start_gt_end: true })
            console.log('Start date is after End date')
        } else {
            this.setState({ start_gt_end: false })
        }

        // Start date format is invalid (or empty)
        if (this.state.start_date_invalid) {
            num_errors += 1
            console.log('Start date has an invalid format or is missing')
        }

        // End date format is invalid (or empty)
        if (this.state.end_date_invalid) {
            num_errors += 1
            console.log('End date has an invalid format or is missing')
        }

        // Start date after end of dataset data (error checking is computed elsewhere)
        if (this.state.start_date_gtend) {
            num_errors += 1
            console.log('Start GT End')
        }

        // Start date after end of dataset data (error checking is computed elsewhere)
        if (this.state.start_date_ltstart) {
            num_errors += 1
            console.log('Start Date Less than Report Period')
        }

        const PutOrPostReport = () => {
            var report = {
                report_name: this.state.reportname,
                store_group: this.state.Geochosen[0].value,
                report_brands: this.state.Brandchosen.map(brand => brand.value),
                report_items: this.state.Itemchosen.map(item => item.value),
                start_date: this.state.selectedstart,
                end_date: this.state.selectedend,
                dataset_id: this.state.DataSetChosen
            };

            // Figure out our method to use, and for edit, add Report ID and Time Updated to what we will PUT
            var method = 'POST'
            if (Constant.entrytype === 'edit') {
                report['report_id'] = this.state.reportid
                report['time_updated'] = this.state.timeupdated
                method = 'PUT'
            }

            // Show the backdrop & Snackbar
            this.setState({ showBackdrop: true })
            //console.log(JSON.stringify(report));

            //console.log(method + 'ing report settings for ' + Constant.entrytype)
            var url = Constant.baseapiurl + "ReportSettings"
            if (Constant.entrytype === 'edit') {
                url = url + '/' + this.state.reportid
            }

            fetch(url,
                {
                    method: method,
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(report)
                })
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    console.log((Constant.entrytype === 'edit') ? 'Report Updated!' : 'Report Created!');
                                    console.log(result)

                                    // Redirect to the newly-created or updated report
                                    this.setState({ showSnackbarSuccess: true })
                                    if (this.state.hasstagingval === true) {
                                        window.location.href = Constant.baseurl + appname + '/Report/' + result.report_id + '?env=staging'
                                    } else {
                                        window.location.href = Constant.baseurl + appname + '/Report/' + result.report_id
                                    }
                                },
                                (error) => {
                                    console.log('Error saving report: ' + error)
                                }
                            )
                    } else if (res.status === 511) {
                        console.log('Server Restarted or went down, so let the user know they have to reload the page to re-authenticate')
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop: false
                                    })
                                })
                    } else {
                        // FIXME: Pop up an error window?
                        console.log('Failed to save the report')
                        this.setState({ errorAlert: true })
                    }
                })
        }

        if (num_errors > 0) {
            console.log(num_errors + ' errors found in report configuration');
            console.log(this.state)
            this.setState({ showSnackbar_CreateReportError: true })
        } else {
            console.log('No errors found in report configuration -- saving report')
            PutOrPostReport()
        }
    }

    handleClickcancel() {
        const findid = () => {
            var url = window.location.href;
            var temp = url.split("/");
            console.log(temp)
            var i = unescape(temp[5]);
            var idnum = parseInt(i, 10);
            return idnum;
        }
        let appname = Constant.whereamilist[3]
        const reportid = findid();
        console.log(reportid)
        window.location.href = Constant.baseurl + appname + '/Report/' + reportid
    }

    check_start_date_on_change(startdate, pastdate_to_comp) {
        this.setState({
            selectedstart: startdate,
            start_date_invalid: false
        })

        if (pastdate_to_comp.isBefore(this.state.pastdate)) {
            // Set an error
            console.log('Start date set by user ' + String(pastdate_to_comp) +
                ' is before beginning of dataset ' + String(this.state.pastdate) +
                ' (detected after start date change)')
            this.setState({ start_date_ltstart: true })
            this.setState({ start_date_gtend: false })
        } else if (pastdate_to_comp.isAfter(this.state.futuredate)) {
            // Set an error
            console.log('Start date set by user ' + String(pastdate_to_comp) +
                ' is after the end of the dataset ' + String(this.state.futuredate) +
                ' (detected after start date change)')
            this.setState({ start_date_ltstart: false })
            this.setState({ start_date_gtend: true })
        } else {
            this.setState({ start_date_ltstart: false })
            this.setState({ start_date_gtend: false })
        }

        // If end date is populated, compare start to end date
        if (startdate && this.state.selectedend && startdate > this.state.selectedend) {
            // Set an error
            this.setState({ start_gt_end: true })
            console.log('New start date ' + String(startdate) + ' after old end date ' + String(this.state.selectedend) +
                ' (detected after start date change)')
        } else {
            this.setState({ start_gt_end: false })
        }
    }

    check_end_date_on_change(enddate, future_to_comp) {
        this.setState({
            selectedend: enddate,
            end_date_invalid: false
        })

        if (future_to_comp.isBefore(this.state.pastdate)) {
            // Set an error
            console.log('End date set by user ' + String(future_to_comp) +
                ' is before beginning of dataset ' + String(this.state.pastdate) +
                ' (detected after end date change)')
            this.setState({ end_date_ltstart: true })
            this.setState({ end_date_gtend: false })
        } else if (future_to_comp.isAfter(this.state.futuredate)) {
            // Set an error
            console.log('End date set by user ' + String(future_to_comp) +
                ' is after the end of the dataset ' + String(this.state.futuredate) +
                ' (detected after end date change)')
            this.setState({ end_date_ltstart: false })
            this.setState({ end_date_gtend: true })
        } else {
            this.setState({ end_date_ltstart: false })
            this.setState({ end_date_gtend: false })
        }

        // If start date is populated, compare start to end date
        if (this.state.selectedstart && enddate && this.state.selectedstart > enddate) {
            // Set an error
            this.setState({ start_gt_end: true })
            console.log('Old start date ' + String(this.state.selectedstart) + ' after new end date ' + String(enddate) +
                ' (detected after end date change)')
        } else {
            this.setState({ start_gt_end: false })
        }
    }

    handleDateStart_monthly(date) {
        if (typeof date == 'string') {
            // Set an error
            console.log('Invalid start date "' + date + '"')
            this.setState({ start_date_invalid: true})
        } else {
            try {
                var startdate = String(date.format('YYYY-MM-DD'))
                startdate = startdate.substring(0, 4) + '-' + startdate.substring(5, 7) + '-01'
                var startdate_year = parseInt(startdate.substring(0, 4))
                var startdate_month = parseInt(startdate.substring(5, 7)) - 1 // Zero Based Month
                var startdate_day = 1
                var pastdate_to_comp = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

                this.check_start_date_on_change(startdate, pastdate_to_comp)
            } catch (err) {
                console.log('handleDateStart_monthly got an exception: ' + err)
            }
        }
    }

    handleDateEnd_monthly(date) {
        if (typeof date == 'string') {
            // Set an error
            console.log('Invalid end date "' + date + '"')
            this.setState({ end_date_invalid: true })
        } else {
            try {
                var enddate = String(date.format('YYYY-MM-DD'))
                enddate = enddate.substring(0, 4) + '-' + enddate.substring(5, 7) + '-01'
                var enddate_year = parseInt(enddate.substring(0, 4))
                var enddate_month = parseInt(enddate.substring(5, 7)) - 1 // Zero Based Month
                var enddate_day = 1
                var future_to_comp = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day , 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

                this.check_end_date_on_change(enddate, future_to_comp)
            } catch (err) {
                console.log('handleDateEnd_monthly got an exception: ' + err)
            }
        }
    };

    handleDateStart(date) {
        if (typeof date == 'string') {
            // Set an error
            console.log('Invalid start date "' + date + '"')
            this.setState({ start_date_invalid: true })
        } else {
            try {
                var startdate = String(date.format('YYYY-MM-DD'))
                var startdate_year = parseInt(startdate.substring(0, 4))
                var startdate_month = parseInt(startdate.substring(5, 7)) - 1 // Zero Based Month
                var startdate_day = parseInt(startdate.substring(8, 10))
                var pastdate_to_comp = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day , 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

                this.check_start_date_on_change(startdate, pastdate_to_comp)
            } catch (err) {
                console.log('handleDateStart got an exception: ' + err)
            }
        }
    };

    handleDateEnd(date) {
        if (typeof date == 'string') {
            // Set an error
            console.log('Invalid end date "' + date + '"')
            this.setState({ end_date_invalid: true })
        } else {
            try {
                var enddate = String(date.format('YYYY-MM-DD'))
                console.log(enddate)
                var enddate_year = parseInt(enddate.substring(0, 4))
                var enddate_month = parseInt(enddate.substring(5, 7)) - 1 // Zero Based Month
                var enddate_day = parseInt(enddate.substring(8, 10)) - 1
                var future_to_comp = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

                this.check_end_date_on_change(enddate, future_to_comp)
            } catch (err) {
                console.log('handleDateEnd got an exception: ' + err)
            }
        }
    };

    handleChange = () => {
        this.setState({ reportname: document.getElementById('reportname').value })
        if (this.state.brands && this.state.brands.length > 0) {
            this.setState({ selectedbrands: document.getElementById('branddropdown').value })
        }
        if (this.state.items && this.state.items.length > 0) {
            this.setState({ selecteditems: document.getElementById('itemdropdown').value })
        }
        this.setState({ selectedgeos: document.getElementById('geodropdown').value })
        // Run the Report Parameters
        let allReportNames = this.state.allReportNames
        let reportname = document.getElementById('reportname').value
        console.log(allReportNames)
        console.log(reportname)
        if (allReportNames.includes(reportname)) {
            console.log('Throw a warning here...')
            this.setState({ report_name_same: true })
        } else {
            // Revert Back to Safe State
            this.setState({ report_name_same: false })
        }
    }

    async onChangeGeo(selectedOptions, action) {
        console.log(selectedOptions)
        var Geochosen = [selectedOptions]
        var selected_geo = selectedOptions.value
        // Set a Loading Screen...
        this.setState({ showBackdrop: true })

        let brand_url = Constant.baseapiurl + "Datasets/" + this.state.DataSetChosen + "/Brands?store_group=" + selected_geo + this.state.extraparam_multi
        let item_url = Constant.baseapiurl + "Datasets/" + this.state.DataSetChosen + "/Items?store_group=" + selected_geo + this.state.extraparam_multi
        let my_results = await this.FetchConfigWithDefault([
            { 'url': brand_url, 'default': [] },
            { 'url': item_url, 'default': [] },
        ], 'ReportEntry.js onChangeGeo()', 'Brands and Items for geo')

        if (my_results['ErrorCount'] > 0) {
            console.log('Bailing out of report entry fetch due to error loading items or brands for store group ' + selected_geo)
            this.setState({
                displayReloadDialog: true
                , reloadDialog: "There was an error getting dataset information.  Please reload"
                , showBackdrop: false
            })
            return
        }

        let brand_results = my_results['ReturnList'][0]
        let item_results = my_results['ReturnList'][1]

        // -------------------- Process the list of brands for the selected geography --------------------

        console.log('Got brands for store group ' + selected_geo)
        console.log(brand_results);

        var brands_array = []
        brand_results.map(brand => (brands_array.push({ 'value': brand.brand_name, 'label': brand.brand_name })))

        // Compare brands_array to already selected brands...
        // If brand is selected, but not in brands....remove from the list
        let selectedbrands = this.state.Brandchosen.map(brand => brand.label)
        let eligible_brands = brands_array.map(brand => brand.label)

        let brands_chosen = []
        selectedbrands.forEach(brand => { if (eligible_brands.includes(brand)) { brands_chosen.push({ 'value': brand, 'label': brand }) } })
        this.setState({ brands: brands_array, Brandchosen: brands_chosen, showBackdrop: false })

        // -------------------- Process the list of items for the selected geography --------------------

        console.log('Got items for store group ' + selected_geo)
        console.log(item_results);

        // If the first item is null, ignore the whole list of items we got back
        var items_array = []
        if (item_results.length > 0 && item_results[0].prodid !== null) {
            item_results.map(item => (items_array.push({ 'value': item.proddesc, 'label': item.proddesc })))
        }

        // Compare items_array to already selected items...
        // If item is selected, but not in items....remove from the list
        let selecteditems = this.state.Itemchosen.map(item => item.label)
        let eligible_items = items_array.map(item => item.label)

        let items_chosen = []
        selecteditems.forEach(item => { if (eligible_items.includes(item)) { items_chosen.push({ 'value': item, 'label': item }) } })
        this.setState({ items: items_array, Itemchosen: items_chosen, showBackdrop: false })

        // -------------------- DONE --------------------

        this.setState({ Geochosen })
    }

    onChangeBrand(selectedOptions, action) {
        console.log(selectedOptions)
        var num_brands_selected = 0
        if (selectedOptions && selectedOptions.length > 0) {
            var selected_brands = selectedOptions.map(option => option.label)
            if (selected_brands.includes('All')) {
                this.setState({ Brandchosen: this.state.brands, allBrandsChosen: true });
                num_brands_selected = this.state.brands.length
            } else {
                this.setState({ Brandchosen: selectedOptions, allBrandsChosen: false });
                num_brands_selected = selectedOptions.length
            }
        } else {
            this.setState({
                Brandchosen: [],
                allBrandsChosen: false
            })
            num_brands_selected = 0
        }
        console.log("num_brands_selected: ", num_brands_selected)
    }

    onChangeItem(selectedOptions, action) {
        console.log(selectedOptions)
        var num_items_selected = 0
        if (selectedOptions && selectedOptions.length > 0) {
            var selected_items = selectedOptions.map(option => option.label)
            if (selected_items.includes('Select All')) {
                this.setState({ Itemchosen: this.state.items });
                num_items_selected = this.state.items.length
            } else {
                this.setState({ Itemchosen: selectedOptions });
                num_items_selected = selectedOptions.length
            }
        } else {
            this.setState({
                Itemchosen: []
            })
            num_items_selected = 0
        }
        console.log(num_items_selected)
    }

    async onChangeDataSet(selectedOptions, action) {
        // Set Loading State to True...
        this.setState({ DataSetChosen: selectedOptions.value, showBackdrop_CreateReport: true });
        console.log(selectedOptions.value)
        // Reset Selected Brands/Items to []
        // Reset Selected Geos to []
        // Reset Brand/Item Options to []
        this.setState({
            Geochosen: []
            , Brandchosen: []
            , brands: []
            , Itemchosen: []
            , items: []
        })

        let dataset_url = Constant.baseapiurl + "Datasets/" + selectedOptions.value + this.state.extraparam
        let dataset_geos_url = Constant.baseapiurl + "Datasets/" + selectedOptions.value + "/StoreGroups" + this.state.extraparam
        let dataset_times_url = Constant.baseapiurl + "Datasets/" + selectedOptions.value + "/Times" + this.state.extraparam

        let my_results = await this.FetchConfigWithDefault([
            { 'url': dataset_url, 'default': [] },
            { 'url': dataset_geos_url, 'default': [] },
            { 'url': dataset_times_url, 'default': [] },
        ], 'ReportEntry.js onChangeDataSet()', 'dataset, geos, and times')

        if (my_results['ErrorCount'] > 0) {
            console.log('Bailing out of report entry fetch after dataset selection change')
            this.setState({
                displayReloadDialog: true
                , reloadDialog: "There was an error getting dataset information.  Please reload"
                , showBackdrop: false
            })
            return
        }

        let dataset_results = my_results['ReturnList'][0]
        let dataset_geo_results = my_results['ReturnList'][1]
        let dataset_times_results = my_results['ReturnList'][2]

        // -------------------- Process the list of datasets --------------------

        console.log(dataset_results);
        var dateFormat = "YYYY-MM-DD"
        if (dataset_results.time_aggregation === "Monthly") {
            dateFormat = "YYYY-MM"
        } else {
            dateFormat = "YYYY-MM-DD"
        }
        this.setState({ time_aggregation: dataset_results.time_aggregation, dateFormat: dateFormat})

        // -------------------- Process the list of geos --------------------

        console.log(dataset_geo_results);
        var geos_array = []
        dataset_geo_results.forEach(geo => {
            if (geo.storegpdesc.toUpperCase().indexOf('RM') > -1) {
                geo.geogdesc_modified = geo.storegpdesc + ' (Remaining Market)'
            } else if (geo.storegpdesc.toUpperCase().indexOf('ALR') > -1) {
                geo.geogdesc_modified = geo.storegpdesc + ' (Account Level Report)'
            } else if (geo.storegpdesc.toUpperCase().indexOf('ASSG') > -1) {
                geo.geogdesc_modified = geo.storegpdesc + ' (Athletic Specialty Sporting Goods)'
            } else {
                geo.geogdesc_modified = geo.storegpdesc
            }
        })
        dataset_geo_results.map(geo => (geos_array.push({ 'value': geo.storegpid, 'label': geo.geogdesc_modified })))
        let geo_string = ""
        dataset_geo_results.map(geo => geo_string += "," + geo.geogdesc_modified)
        console.log(geo_string)
        console.log(geo_string.substring(1))
        this.setState({ geo_string: geo_string.substring(1) })

        console.log(geos_array)
        // get labels
        var geo_labels = geos_array.map(geo => geo.label)
        // Find if 'Total US' is in geo_labels and remove from the array....
        var TUS_index = geo_labels.indexOf('Total US')
        console.log(TUS_index)
        var sorted_geos = []
        if (TUS_index !== -1) {
            geo_labels.splice(TUS_index, 1)
            // Need to Sort geos_array with Total US first, and everything ekse sorted by the description
            sorted_geos = ['Total US'].concat(geo_labels.sort())
        } else {
            sorted_geos = geo_labels.sort()
        }
        console.log(sorted_geos)

        var finalsorted_geos = []
        sorted_geos.forEach(geo => {
            for (var g = 0; g < geos_array.length; g++) {
                if (geos_array[g].label === geo) {
                    finalsorted_geos.push(geos_array[g])
                }
            }
        })

        console.log(finalsorted_geos)
        // Set Loading State to False
        this.setState({
            geos: finalsorted_geos,
            showBackdrop_CreateReport: false
        });

        // -------------------- Process the times --------------------

        console.log(dataset_times_results);
        var enddate_dow = new Date(dataset_times_results[1]).getDay()
        console.log(enddate_dow)

        // startdate
        var startdate = dataset_times_results[0]
        var startdate_year = parseInt(startdate.substring(0, 4))
        var startdate_month = parseInt(startdate.substring(5, 7)) - 1 // Zero Based Month
        var startdate_day = parseInt(startdate.substring(8, 10)) - 1
        var pastdate = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(6, 'd');
        var pastdate_formatted = pastdate.subtract(-1, 'd').format('YYYY-MM-DD')

        // enddate
        var enddate = dataset_times_results[1]
        var futuredate_formatted = enddate.substring(0, 10)
        var enddate_year = parseInt(enddate.substring(0, 4))
        var enddate_month = parseInt(enddate.substring(5, 7)) - 1 // Zero Based Month
        var enddate_day = parseInt(enddate.substring(8, 10))
        var futuredate = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

        // set state
        this.setState({ pastdate, futuredate, pastdate_formatted, futuredate_formatted, enddate_dow })

        // -------------------- DONE --------------------
    }

    async componentDidMount() {
        const findid = () => {
            var url = window.location.href;
            var temp = url.split("/");
            var i = unescape(temp[5]);
            var idnum = parseInt(i, 10);
            return idnum;
        }

        const hasstaging = () => {
            var url = window.location.href;
            var temp = url.split("/");
            var i = unescape(temp[5]);
            var hasstagingval
            if (i.indexOf('?env=staging') > 0) {
                hasstagingval = true
            } else {
                hasstagingval = false
            }
            return hasstagingval
        }

        const get_report_names = async () => {
            // Get a list of all Reports we have access to so we can check for and warn about duplicate names
            let all_reports_list_url = Constant.baseapiurl + "ReportSettings"
            let my_results = await this.FetchConfigWithDefault([
                { 'url': all_reports_list_url, 'default': [] },
            ], 'ReportEntry.js componentDidMount()', 'getting all report settings')

            if (my_results['ErrorCount'] > 0) {
                console.log('Bailing out of report entry fetch in componentDidMount() after report list request')
                return
            }

            let report_list_results = my_results['ReturnList'][0]
            this.setState({ allReportNames: report_list_results.map(report => report.report_name) })
        }

        const get_dataset_hover_params = async (datasetid, dataset_info) => {
            let hover_parameters_url = Constant.baseapiurl + 'Datasets/' + datasetid + '/' + 'HoverParameters' + this.state.extraparam

            my_results = await this.FetchConfigWithDefault([
                { 'url': hover_parameters_url, 'default': [] }
            ], 'ReportEntry.js componentDidMount()', 'hover information for dataset ' + datasetid)

            if (my_results['ErrorCount'] > 0) {
                console.log('Bailing out of dataset hover parameter fetch in componentDidMount()')
                return
            }

            let hover_param_results = my_results['ReturnList'][0]
            let dataset_store_group_results = hover_param_results['store_groups']
            let dataset_time_results = hover_param_results['times']
            let dataset_levels_results = hover_param_results['levels']

            let storegroups = []
            dataset_store_group_results.forEach(storegroup => storegroups.push(storegroup.storegpdesc))
            dataset_info['storegroups'] = storegroups
            dataset_info['times'] = dataset_time_results
            dataset_info['levels'] = dataset_levels_results
        }

        const populate_all_datasets = async (dataset_settings_results) => {
            let dataset_info = {}
            dataset_settings_results.forEach(dataset => {
                dataset_info[dataset.dataset_id] = {
                    'country': dataset.country,
                    'description': dataset.description,
                    'industry_name': dataset.industry_name || "Unknown",
                    'short_desc': dataset.short_desc,
                    'time_aggregation': dataset.time_aggregation,
                    'time_updated': dataset.time_updated
                }
            })

            // Get Additional Parameters for Each Dataset...
            await dataset_settings_results.forEach(async dataset => {
                await get_dataset_hover_params(dataset.dataset_id, dataset_info[dataset.dataset_id])
            })

            return dataset_info
        }

        const populate_one_dataset = async (one_dataset_id, one_dataset_info) => {
            let one_dataset = {
                'country': one_dataset_info.country,
                'description': one_dataset_info.description,
                'industry_name': one_dataset_info.industry_name || "Unknown",
                'short_desc': one_dataset_info.short_desc,
                'time_aggregation': one_dataset_info.time_aggregation,
                'time_updated': one_dataset_info.time_updated
            }

            // Get Additional Parameters for this single dataset
            await get_dataset_hover_params(one_dataset_id, one_dataset)
            return one_dataset
        }

        var appname = Constant.whereamilist[3]
        var extraparam = ""
        var extraparam_multi = ""
        let my_results

        var hasstagingval = hasstaging()
        console.log('has staging: ' + hasstagingval)

        if (hasstagingval) {
            extraparam = "?env=staging"
            extraparam_multi = "&env=staging"
        } else {
            extraparam = ""
            extraparam_multi = ""
        }

        this.setState({ hasstagingval, extraparam, extraparam_multi })

        var dataset_on_url = findid()
        this.setState({ dataset_on_url })
        console.log('dataset_on_url = ' + dataset_on_url)

        // Get a list of all report names we can see so we can warn on duplicate names
        await get_report_names()

        if (Constant.entrytype !== 'create') {
            // If Copy or Edit, pull in Report Info and Populate the State....
            var geos_array = []
            var brands_array = []
            var items_array = []
            var distinctgeonames = []

            // Get the id from the URL
            var reportid = findid()

            // First, get parameters of the report
            my_results = await this.FetchConfigWithDefault([
                { 'url': Constant.baseapiurl + "ReportSettings/" + reportid, 'default': [] }
            ], 'ReportEntry.js componentDidMount() not for create', 'just report ID ' + reportid)

            if (my_results['ErrorCount'] > 0) {
                console.log('Bailing out of report entry fetch in componentDidMount() after report settings ' + reportid + ' request')
                return
            }

            let report_settings_results = my_results['ReturnList'][0]

            let edit_dataset_id = report_settings_results.dataset_id
            console.log('The dataset for report ' + reportid + ' is ' + edit_dataset_id)

            this.setState({ reportid: report_settings_results.report_id })
            if (Constant.entrytype === 'edit') {
                this.setState({ reportname: report_settings_results.report_name })
            } else {
                this.setState({ reportname: 'Copy of ' + report_settings_results.report_name })
            }
            this.setState({ DataSetChosen: edit_dataset_id })
            var datasetfromreport = edit_dataset_id

            var selectedbrands = []
            report_settings_results.report_brands.forEach(brand => { selectedbrands.push({ 'label': brand, 'value': brand }) })
            this.setState({ Brandchosen: selectedbrands })

            var selecteditems = []
            if (report_settings_results.report_items !== null) {
                report_settings_results.report_items.forEach(item => { selecteditems.push({ 'label': item, 'value': item }) })
            }
            this.setState({ Itemchosen: selecteditems })

            this.setState({ selectedstart: report_settings_results.start_date.substring(0, 10) })
            this.setState({ selectedend: report_settings_results.end_date.substring(0, 10) })
            this.setState({ timeupdated: report_settings_results.time_updated })

            var report_geos = report_settings_results.store_group

            // -------------------- Get and Process Many Dataset Settings --------------------

            let dataset_url = Constant.baseapiurl + "Datasets/" + edit_dataset_id + extraparam
            let dataset_times_url = Constant.baseapiurl + "Datasets/" + edit_dataset_id + "/Times" + extraparam
            let dataset_brand_list_url = Constant.baseapiurl + "Datasets/" + edit_dataset_id + "/Brands?store_group=" + report_geos + extraparam_multi
            let dataset_item_list_url = Constant.baseapiurl + "Datasets/" + edit_dataset_id + "/Items?store_group=" + report_geos + extraparam_multi
            let dataset_store_groups_url = Constant.baseapiurl + "Datasets/" + edit_dataset_id + "/StoreGroups" + extraparam

            my_results = await this.FetchConfigWithDefault([
                { 'url': dataset_url, 'default': [] },
                { 'url': dataset_times_url, 'default': [] },
                { 'url': dataset_brand_list_url, 'default': [] },
                { 'url': dataset_item_list_url, 'default': [] },
                { 'url': dataset_store_groups_url, 'default': [] },
            ], 'ReportEntry.js componentDidMount() not for create', 'xxxxx')

            if (my_results['ErrorCount'] > 0) {
                console.log('Bailing out of report entry fetch in componentDidMount() #3')
                return
            }

            let dataset_results = my_results['ReturnList'][0]
            let dataset_times_results = my_results['ReturnList'][1]
            let dataset_brands_results = my_results['ReturnList'][2]
            let dataset_items_results = my_results['ReturnList'][3]
            let dataset_store_groups = my_results['ReturnList'][4]

            // -------------------- Process the Dataset Settings --------------------

            console.log(dataset_results);
            var dateFormat = "YYYY-MM-DD"
            if (dataset_results.time_aggregation === "Monthly") {
                dateFormat = "YYYY-MM"
            } else {
                dateFormat = "YYYY-MM-DD"
            }
            this.setState({ time_aggregation: dataset_results.time_aggregation, dateFormat: dateFormat })

            let dataset_info = {}
            dataset_info[edit_dataset_id] = await populate_one_dataset(edit_dataset_id, dataset_results)

            let industryname = dataset_results.industry_name || "Unknown"

            var dataset_array = []
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(edit_dataset_id)) {
                    dataset_array.push({ 'label': industryname, 'options': [{ 'value': edit_dataset_id, 'label': dataset_results.short_desc }] })
                }
            } else {
                dataset_array.push({ 'label': industryname, 'options': [{ 'value': edit_dataset_id, 'label': dataset_results.short_desc }] })
            }

            this.setState({
                datasets: dataset_array,
                dataset_info
            });

            // -------------------- Process the Dataset Times Settings --------------------

            console.log(dataset_times_results);
            // Make a date from the last date available to get what end days this report has
            var enddate_dow = new Date(dataset_times_results[1]).getDay()
            console.log(enddate_dow)

            // startdate
            var startdate = dataset_times_results[0]
            var startdate_year = parseInt(startdate.substring(0, 4))
            var startdate_month = parseInt(startdate.substring(5, 7)) - 1 // Zero Based Month
            var startdate_day = parseInt(startdate.substring(8, 10))
            var pastdate = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(6, 'd');
            var pastdate_formatted = pastdate.subtract(-1, 'd').format('YYYY-MM-DD')

            // enddate
            var enddate = dataset_times_results[1]
            var enddate_year = parseInt(enddate.substring(0, 4))
            var enddate_month = parseInt(enddate.substring(5, 7)) - 1 // Zero Based Month
            var enddate_day = parseInt(enddate.substring(8, 10))
            var futuredate = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
            var futuredate_formatted = futuredate.subtract(-1, 'd').format('YYYY-MM-DD')

            // set state
            this.setState({ pastdate, futuredate, pastdate_formatted, futuredate_formatted, enddate_dow })

            // -------------------- Process the Dataset Brand List Settings --------------------

            console.log(dataset_brands_results);
            dataset_brands_results.map(brand => (brands_array.push({ 'value': brand.brand_name, 'label': brand.brand_name })))
            // Create a string of brands
            let brand_string = ""
            dataset_brands_results.map(brand => brand_string += "," + brand)
            console.log(brand_string)
            console.log(brand_string.substring(1))
            this.setState({ brand_string: brand_string.substring(1) })
            this.setState({ brands: brands_array });

            // -------------------- Process the Dataset Item List Settings --------------------

            console.log(dataset_items_results);

            //results.map(item => (items_array.push({ 'value': item.proddesc, 'label': item.proddesc })))
            // Check if first item is Null
            if (dataset_items_results.length > 0 && dataset_items_results[0].prodid !== null) {
                dataset_items_results.map(item => (items_array.push({ 'value': item.proddesc, 'label': item.proddesc })))
            }

            // Create a string of items
            let item_string = ""
            //results.map(item => item_string += "," + item)
            dataset_items_results.map(item => (
                item.prodid !== null
                    ? item_string += "," + item
                    : item_string = ""))

            console.log(item_string)
            console.log(item_string.substring(1))
            this.setState({ item_string: item_string.substring(1) })
            this.setState({
                items: items_array,
            });

            // -------------------- Process the Dataset Store Groups Settings --------------------

            console.log(dataset_store_groups);
            var datasetgeos = dataset_store_groups

            // Walkthrough geos and if RM or ALR in the name, then add the appropriate clarification to the label...
            datasetgeos.forEach(geo => {
                if (geo.storegpdesc.toUpperCase().indexOf('RM') > -1) {
                    geo.geogdesc_modified = geo.storegpdesc + ' (Remaining Market)'
                } else if (geo.storegpdesc.toUpperCase().indexOf('ALR') > -1) {
                    geo.geogdesc_modified = geo.storegpdesc + ' (Account Level Report)'
                } else if (geo.storegpdesc.toUpperCase().indexOf('ASSG') > -1) {
                    geo.geogdesc_modified = geo.storegpdesc + ' (Athletic Specialty Sporting Goods)'
                } else {
                    geo.geogdesc_modified = geo.storegpdesc
                }
            })

            dataset_store_groups.map(geo => (geos_array.push({ 'value': geo.storegpid, 'label': geo.geogdesc_modified })))

            let geo_string = ""
            dataset_store_groups.map(geo => geo_string += "," + geo.geogdesc_modified)
            console.log(geo_string)
            console.log(geo_string.substring(1))
            this.setState({ geo_string: geo_string.substring(1) })

            console.log(geos_array)
            // get labels
            var geo_labels = geos_array.map(geo => geo.label)
            // Find if 'Total US' is in geo_labels and remove from the array....
            var TUS_index = geo_labels.indexOf('Total US')
            console.log(TUS_index)
            var sorted_geos = []
            if (TUS_index !== -1) {
                geo_labels.splice(TUS_index, 1)
                // Need to Sort geos_array with Total US first, and everything ekse sorted by the description
                sorted_geos = ['Total US'].concat(geo_labels.sort())
            } else {
                sorted_geos = geo_labels.sort()
            }
            console.log(sorted_geos)

            var finalsorted_geos = []
            sorted_geos.forEach(geo => {
                for (var g = 0; g < geos_array.length; g++) {
                    if (geos_array[g].label === geo) {
                        finalsorted_geos.push(geos_array[g])
                    }
                }
            })

            console.log(finalsorted_geos)

            this.setState({
                geos: finalsorted_geos,
                datasetgeos: dataset_store_groups
            });
            var selectedgeos = []
            datasetgeos.forEach(datasetgeo => {
                console.log(datasetgeo.storegpid)
                console.log(report_settings_results.store_group)
                console.log(datasetgeo.storegpid === report_settings_results.store_group)
                if (report_settings_results.store_group === datasetgeo.storegpid) {
                    selectedgeos.push(({ 'value': report_settings_results.store_group, 'label': datasetgeo.geogdesc_modified }))
                    distinctgeonames.push(datasetgeo.geogdesc_modified)
                    this.setState({ Geochosen: selectedgeos, distinctgeos: datasetgeos })
                }
            })

            // -------------------- DONE --------------------

            this.setState({ showBackdrop: false })
        } else if (Constant.entrytype === 'create' && dataset_on_url) {
            // This is when someone is look at the dataset list and clicks the "+" to create a new report against that dataset

            // If Create, and we have a parameter for find id....load the dataset info into the appropriate places.
            console.log('Need to look up the dataset')
            this.setState({ DataSetChosen: dataset_on_url })

            my_results = await this.FetchConfigWithDefault([
                { 'url': Constant.baseapiurl + "Datasets/" + dataset_on_url + extraparam, 'default': [] },
                { 'url': Constant.baseapiurl + "Datasets/" + dataset_on_url + "/Times" + extraparam, 'default': [] },
                { 'url': Constant.baseapiurl + "Datasets/" + dataset_on_url + "/StoreGroups" + extraparam, 'default': [] },
            ], 'ReportEntry.js componentDidMount() not for create', 'just report ID ' + reportid)

            if (my_results['ErrorCount'] > 0) {
                console.log('Bailing out of report entry fetch in componentDidMount() after report settings ' + reportid + ' request')
                return
            }

            let dataset_settings_results = my_results['ReturnList'][0]
            let dataset_times_settings_results = my_results['ReturnList'][1]
            let dataset_store_group_settings_results = my_results['ReturnList'][2]

            // -------------------- Process the Dataset Settings --------------------

            console.log(dataset_settings_results);
            var dateFormat = "YYYY-MM-DD"
            if (dataset_settings_results.time_aggregation === "Monthly") {
                dateFormat = "YYYY-MM"
            } else {
                dateFormat = "YYYY-MM-DD"
            }
            this.setState({ time_aggregation: dataset_settings_results.time_aggregation, dateFormat: dateFormat })

            let dataset_info = {}
            dataset_info[dataset_settings_results.dataset_id] = await populate_one_dataset(dataset_settings_results.dataset_id, dataset_settings_results)

            var dataset_array = []
            let industryname = dataset_settings_results.industry_name || "Unknown"
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset_settings_results.dataset_id)) {
                    dataset_array.push({ 'label': industryname, 'options': [{ 'value': dataset_settings_results.dataset_id, 'label': dataset_settings_results.short_desc }] })
                }
            } else {
                dataset_array.push({ 'label': industryname, 'options': [{ 'value': dataset_settings_results.dataset_id, 'label': dataset_settings_results.short_desc }] })
            }

            this.setState({
                datasets: dataset_array,
                dataset_info
            });

            // -------------------- Process the Dataset Times Settings --------------------

            console.log(dataset_times_settings_results);
            // Make a date from the last date available to get what end days this report has

            var enddate_dow = new Date(dataset_times_settings_results[1]).getDay()
            console.log(enddate_dow)

            // startdate
            var startdate = dataset_times_settings_results[0]
            var startdate_year = parseInt(startdate.substring(0, 4))
            var startdate_month = parseInt(startdate.substring(5, 7)) - 1 // Zero Based Month
            var startdate_day = parseInt(startdate.substring(8, 10))
            var pastdate = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(6, 'd');
            var pastdate_formatted = pastdate.subtract(-1, 'd').format('YYYY-MM-DD')

            // enddate
            var enddate = dataset_times_settings_results[1]
            var enddate_year = parseInt(enddate.substring(0, 4))
            var enddate_month = parseInt(enddate.substring(5, 7)) - 1 // Zero Based Month
            var enddate_day = parseInt(enddate.substring(8, 10))
            var futuredate = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
            var futuredate_formatted = futuredate.subtract(-1, 'd').format('YYYY-MM-DD')

            // set state
            this.setState({ pastdate, futuredate, pastdate_formatted, futuredate_formatted, enddate_dow })

            // -------------------- Process the Dataset Store Groups Settings --------------------

            console.log(dataset_store_group_settings_results);
            var datasetgeos = dataset_store_group_settings_results

            // Walkthrough geos and if RM or ALR in the name, then add the appropriate clarification to the label...
            datasetgeos.forEach(geo => {
                if (geo.storegpdesc.toUpperCase().indexOf('RM') > -1) {
                    geo.geogdesc_modified = geo.storegpdesc + ' (Remaining Market)'
                } else if (geo.storegpdesc.toUpperCase().indexOf('ALR') > -1) {
                    geo.geogdesc_modified = geo.storegpdesc + ' (Account Level Report)'
                } else if (geo.storegpdesc.toUpperCase().indexOf('ASSG') > -1) {
                    geo.geogdesc_modified = geo.storegpdesc + ' (Athletic Specialty Sporting Goods)'
                } else {
                    geo.geogdesc_modified = geo.storegpdesc
                }
            })

            var geos_array = []
            dataset_store_group_settings_results.map(geo => (geos_array.push({ 'value': geo.storegpid, 'label': geo.geogdesc_modified })))

            let geo_string = ""
            dataset_store_group_settings_results.map(geo => geo_string += "," + geo.geogdesc_modified)
            console.log(geo_string)
            console.log(geo_string.substring(1))
            this.setState({ geo_string: geo_string.substring(1) })

            console.log(geos_array)
            // get labels
            var geo_labels = geos_array.map(geo => geo.label)
            // Find if 'Total US' is in geo_labels and remove from the array....
            var TUS_index = geo_labels.indexOf('Total US')
            console.log(TUS_index)
            var sorted_geos = []
            if (TUS_index !== -1) {
                geo_labels.splice(TUS_index, 1)
                // Need to Sort geos_array with Total US first, and everything ekse sorted by the description
                sorted_geos = ['Total US'].concat(geo_labels.sort())
            } else {
                sorted_geos = geo_labels.sort()
            }
            console.log(sorted_geos)

            var finalsorted_geos = []
            sorted_geos.forEach(geo => {
                for (var g = 0; g < geos_array.length; g++) {
                    if (geos_array[g].label === geo) {
                        finalsorted_geos.push(geos_array[g])
                    }
                }
            })

            console.log(finalsorted_geos)

            this.setState({
                geos: finalsorted_geos,
                datasetgeos: dataset_store_group_settings_results
            });

            // -------------------- DONE --------------------
        } else {
            my_results = await this.FetchConfigWithDefault([
                { 'url': Constant.baseapiurl + "Datasets" + extraparam, 'default': [] },
            ], 'ReportEntry.js componentDidMount() for create', 'just plain create')

            if (my_results['ErrorCount'] > 0) {
                console.log('Bailing out of report entry fetch in componentDidMount() for create, request')
                return
            }

            let dataset_settings_results = my_results['ReturnList'][0]
            let dataset_info = await populate_all_datasets(dataset_settings_results)

            let industrymapping = {}

            dataset_settings_results.forEach(dataset => {
                if (appname === 'PDTRetailer') {
                    if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                        let industryname = dataset.industry_name || "Unknown"
                        industrymapping[industryname] = industrymapping[industryname] || []
                        industrymapping[industryname].push({ 'value': dataset.dataset_id, 'label': dataset.short_desc })
                    }
                } else {
                    let industryname = dataset.industry_name || "Unknown"
                    industrymapping[industryname] = industrymapping[industryname] || []
                    industrymapping[industryname].push({ 'value': dataset.dataset_id, 'label': dataset.short_desc })
                }
            })

            console.log(industrymapping)
            var dataset_array = Object.keys(industrymapping).sort().map(industry => {
                return {
                    'label': industry, 'options': industrymapping[industry].sort(function (a, b) {
                        return a.label - b.label;
                    }) }
            })

            this.setState({
                datasets: dataset_array,
                dataset_info
            });
        }
    }

    render() {
        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            color: '#000000',
        };

        const groupBadgeStyles = {
            backgroundColor: '#ffffff',
            borderRadius: '2em',
            color: '#4e106f',
            display: 'inline-block',
            fontSize: '.75rem',
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = (data) => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        const Option = (props) => {
            let dataset_id = props.data.value
            let my_dataset = this.state.dataset_info[dataset_id]
            let countryname = Constant.countrynamelookup[my_dataset['country']] || 'Unknown'

            let offset = new Date().getTimezoneOffset() * 60 * 1000 // Get our DST offset

            let lastupdateddate = my_dataset['time_updated']
            let dateupdatedformatted = (new Date(Date.parse(lastupdateddate) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })

            // Some fields (like 'times') are lazily loaded in the background and might not be available at first.
            // We want to just display nothing for those fields at first, and show their values once we have them.
            let datasetstart = (my_dataset['times'] || [])[0]
            let datasetend = (my_dataset['times'] || [])[1]

            var datasetstartformatted, datasetendformatted, timeaggdesc
            let timeagg = my_dataset['time_aggregation']
            if (timeagg === 'Weekly') {
                datasetstartformatted = (new Date(Date.parse(datasetstart) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                datasetendformatted = (new Date(Date.parse(datasetend) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
                timeaggdesc = 'Week Ending'
            } else {
                datasetstartformatted = (new Date(Date.parse(datasetstart) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                datasetendformatted = (new Date(Date.parse(datasetend) - offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                timeaggdesc = ''
            }
            // If we don't have formattable dates don't show something that will confuse the user
            let time_description = timeaggdesc + ' ' + datasetstartformatted + ' to ' + timeaggdesc + ' ' + datasetendformatted

            let storegroups = my_dataset['storegroups'] || []
            let storegroups_list = storegroups.join(',')

            // Some extra items use metadata that is lazily loaded -- don't show them unless we have that data
            let show_extras = datasetendformatted !== 'Invalid Date' && datasetendformatted !== 'Invalid Date' && storegroups_list.length > 0

            let levels = my_dataset['levels'] || []
            let categories = (levels.items || []).map(level => level.sel_name)

            let fulllevel = my_dataset['complete_at_level']
            let full_cat = false
            if (fulllevel === 1) {
                full_cat = true
            }

            let categories_full = (levels.items || []).map(level => full_cat)
            let numcategoriesfull = 0
            categories_full.forEach(cat => { if (cat) { numcategoriesfull += 1 } })

            let numcategories = categories.length
            let fulldataset = (numcategories === numcategoriesfull && numcategories > 0)

            let subcategories = []
            let cats = (levels.items || [])
            cats.forEach(level => (level.items || []).forEach(sublevel => subcategories.push(sublevel.sel_name)))

            return (
                <HtmlTooltip title={
                    <Fragment>
                        {fulldataset ?
                            <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{my_dataset['short_desc']}</Typography>
                            :
                            <Typography color="inherit">{my_dataset['short_desc']}</Typography>
                        }
                        {!show_extras &&
                            <div><em>Some information about this dataset is still being loaded...</em></div>
                        }
                        <b>Country:</b> {countryname}
                        <br />
                        <b>Industry:</b> {my_dataset['industry_name']}
                        <br />
                        <b>Time Aggregation:</b> {timeagg}
                        <br />
                        {show_extras &&
                            <div>
                                <b>Time Range:</b> {time_description}
                                <br />
                                <b>Store Groups:</b> {storegroups_list}
                                <br />
                                <b>Categories:</b> {categories.map((cat, idx) => {
                                    let sep = ", "
                                    if (idx === (numcategories - 1)) {
                                        sep = ""
                                    }

                                    if (categories_full[idx]) {
                                        return <b style={{ color: "#4e106f" }}>{cat + sep}</b>
                                    } else {
                                        return <span>{cat + sep}</span>
                                    }
                                })}
                                <br />
                                <b>Sub-Categories:</b> {subcategories.join(',')}
                                <br />
                            </div>
                        }
                        <b>Description:</b> {my_dataset['description']}
                        <br />
                        <b>Last Updated Date:</b> {dateupdatedformatted}
                        <br />
                        <br />
                        <b style={{ color: "#4e106f" }}>*Bold Dataset Names indicate that the dataset selection is comprised of only full categories</b>
                        <br />
                        <b style={{ color: "#4e106f" }}>*Bold Category Names indicate that the category selection is complete</b>
                    </Fragment>
                } placement="right" {...props}>
                    <div style={{ marginLeft: '25px' }}>
                        <components.Option {...props} />
                    </div>
                </HtmlTooltip>
            );
        };

        console.log(this.state)

        const divheight = {
            height: '00px'
        }

        const buttonStyle = {
            color: '#fff',
            backgroundColor: '#4e106f',
            borderColor: '#4e106f',
            padding: '10px',
            fontFamily: 'Roboto',
        }

        const buttonStyle2 = {
            color: '#fff',
            backgroundColor: '#ef2a79',
            borderColor: '#ef2a79',
            padding: '10px',
            fontFamily: 'Roboto',
            marginLeft: '50px'
        }

        const dateInput = {
            width: '300px',
            height: '50px',
            borderRadius: '5px',
            //padding: '10px',
            fontFamily: 'Roboto',
            borderColor: '#4E106F'
        }

        const dropdownInput = {
            width: '100%',
            height: '50px',
            borderRadius: '5px',
            //padding: '10px',
            fontFamily: 'Roboto',
            borderColor: '#4E106F'
            
        }

        const closeSuccessSnackBar = () => {
            this.setState({ showSnackbarSuccess: false })
        }

        const closeErrorSnackBar = () => {
            this.setState({ showSnackbarError: false })
        }

        const handleCloseReloadDialog = () => {
            this.setState({ displayReloadDialog: false })
            let appname = Constant.whereamilist[3]
            var wheretosendme = ""
            if (Constant.entrytype === 'create') {
                if (this.state.dataset_on_url) {
                    wheretosendme = appname + '/createreport/' + this.state.dataset_on_url + this.state.extraparam
                } else {
                    wheretosendme = appname + '/createreport'
                }
            } else if (Constant.entrytype === 'edit') {
                wheretosendme = appname + '/EditReport/' + this.state.reportid
            } else if (Constant.entrytype === 'copy') {
                wheretosendme = appname + '/copyreport/' + this.state.reportid
            }

            window.location.href = Constant.baseurl + wheretosendme
        }

        const valid = (current) => {
            if (this.state.time_aggregation === 'Monthly') {
                return current.format('D')=== "1" && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            } else {
                return current.day() === this.state.enddate_dow && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            }
        };

        const valid_start = (current) => {
            if (this.state.time_aggregation === 'Monthly') {
                return current.format('D') === "1" && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            } else {
                return current.day() === ((this.state.enddate_dow + 1) % 7) && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
            }
        };

        const alloption = { 'label': 'All', 'value': '*' }

        const showInfoProject = event => {
            console.log('Opening popover')
            this.setState({ openInfoProject: true })
            this.setState({ anchorElProjectInfo: event.target })
        }

        const hideInfoProject = () => {
            console.log('Closing popover')
            this.setState({ openInfoProject: false })
            this.setState({ anchorElProjectInfo: null })
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({
                errorAlert: false,
                errorAlert_report: false,
                errorAlert_noAccess: false
            })
        }

        const type = Constant.entrytype.substring(0, 1).toUpperCase() + Constant.entrytype.substring(1)

        return (
            <Layout title={type + ' Report'}>
                <Constant.NPDBackdrop
                    open={this.state.showBackdrop}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>
                <Constant.NPDSnackbarSuccess
                    open={this.state.showSnackbarSuccess}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={closeSuccessSnackBar}
                >
                    <Constant.NPDSnackbarContentSuccess
                        message="Changes Made Successfully"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeErrorSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    />
                </Constant.NPDSnackbarSuccess>
                <Constant.NPDSnackbarError
                    open={this.state.showSnackbarError}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={closeErrorSnackBar}
                >
                    <Constant.NPDSnackbarContentError
                        message="There are Errors in the form"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeErrorSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContentError>
                </Constant.NPDSnackbarError>
                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Create Report Error"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There was an error with the report.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.displayReloadDialog}
                    onClose={handleCloseReloadDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Page Must Be Reloaded</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.reloadDialog}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseReloadDialog} className="btn btn-primary" autoFocus>
                            Reload
		                </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.errorAlert_report}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Failed to fetch metadata needed to edit or create a report</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            One or more errors occurred while loading metadata needed to edit or create a report.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.errorAlert_noAccess}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">You do not have access to this report</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Either you do not have access to this report, or the report no longer exists.
                            <br />
                            Please contact Pricing.Support@NPD.com
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <div name='banner' className='banner'>
                    <h1white align='center'>{type + ' a Report'}</h1white><br/>
                    <h2white align='center'> This will evaluate a previous promotion and calculate metrics</h2white>
                </div>
               
                    <div className="contentHolderGradient" style={{ width: '100%', height: '100%', position: 'absolute', left: '120px', top: '210px',  maxWidth: 'calc(100vw - 120px)', paddingLeft: '40px' }}>
                    <div style={divheight}></div>
                    <h3 style={{fontFamily: 'Roboto',color: '#4e106f',padding: '10px', }}>{type + ' Report'}</h3>
                    <p style={{ fontFamily: 'Roboto', padding: '10px', }}> Please complete the form for evalulation. </p>
                    <span className='formfieldtitle' style={{ padding: '10px' }}> <sup>* indicates required field</sup></span>
                    <form className="reportentry" noValidate>
                        <input type='hidden' id='datasetid' />
                        <div className="row" style={{ padding: '10px' }}>
                            <div className='col-sm-12'>
                                <span className='formfieldtitle'>Report Name<sup>*</sup></span>
                                <br />
                                <div className="" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Report Name"
                                        inputProps={{ 'aria-label': 'naked', id: "reportname" }}
                                        onChange={this.handleChange}
                                        id="reportname"
                                        style={dropdownInput}
                                        value={this.state.reportname}
                                    />
                                </div>
                                {this.state.report_name_missing ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Report name must be provided</span> : <span></span>}
                                {this.state.report_name_same ? <span style={{ color: '#f0ab00' }}><WarningRoundedIcon style={{ color: '#f0ab00' }} />  Warning! This Report is named the same as a previous one.  This may make it difficult to find later.</span> : <span></span>}
                            </div>
                        </div>

                        <div className="row" style={{ padding: '10px' }}>
                            <div style={dropdownInput} className='col-sm-12'>
                                <span className='formfieldtitle'>Dataset<sup>*</sup></span>
                                <InfoRoundedIcon
                                    style={{ position: 'relative', top: '-10px', fontSize: '.94rem' }}
                                    onMouseEnter={showInfoProject}
                                    onMouseLeave={hideInfoProject}
                                />
                                <Popover
                                    style={{ pointerEvents: 'none' }}
                                    open={this.state.openInfoProject}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    anchorEl={this.state.anchorElProjectInfo}
                                >
                                <br />Hover over a dataset name to see more information on what comprises the dataset
                                </Popover>
                                {Constant.entrytype === 'create' ?
                                    this.state.dataset_on_url ?
                                        <Select
                                            inputId="datasetdropdown"
                                            styles={selectColourStyles  }
                                            options={this.state.datasets}
                                            value={(this.state.datasets[0] || {}).options}
                                            components={{ Option }}
                                            formatGroupLabel={formatGroupLabel}
                                        />
                                        :
                                        <Select
                                            inputId="datasetdropdown"
                                            styles={ selectColourStyles }
                                            options={this.state.datasets}
                                            placeholder={'Select a dataset to use'}
                                            onChange={(item, action) => this.onChangeDataSet(item, action)}
                                            components={{ Option }}
                                            formatGroupLabel={formatGroupLabel}
                                        />
                                    :
                                    <Select
                                        inputId="datasetdropdown"
                                        styles={ selectColourStyles }
                                        options={this.state.datasets}
                                        value={(this.state.datasets[0] || {}).options}
                                        components={{ Option }}
                                        formatGroupLabel={ formatGroupLabel }
                                    />
                                }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '10px' }}>
                            <div style={dateInput} className='col-sm-6'>
                                <span className='formfieldtitle'>Report Start Date<sup>*</sup></span>
                                {this.state.time_aggregation === 'Monthly' ?
                                    <Datetime
                                        input={true}
                                        isValidDate={valid_start}
                                        dateFormat={"MMM YYYY"}
                                        timeFormat={false}
                                        onChange={this.handleDateStart_monthly}
                                        initialViewMode={'months'}
                                        value={this.state.selectedstart}
                                        inputProps={{ autoComplete: 'off' }}
                                        styles={selectColourStyles}
                                        //ref="datetime"
                                        //inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportstartdate" }}
                                        //viewDate={(Constant.entrytype === 'edit' || Constant.entrytype === 'copy') ? new Date(this.state.selectedend) : this.state.futuredate}
                                    />
                                    :
                                    <Datetime
                                        input={true}
                                        styles={selectColourStyles}
                                        isValidDate={valid_start}
                                        dateFormat={"YYYY-MM-DD"}
                                        timeFormat={false}
                                        onChange={this.handleDateStart}
                                        value={this.state.selectedstart}
                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportstartdate", autoComplete: 'off' }}
                                        viewDate={(Constant.entrytype === 'edit' || Constant.entrytype === 'copy') ? new Date(this.state.selectedstart) : this.state.pastdate}
                                    />
                                }
                            </div>
                            <div style={dateInput} className='col-sm-6'>
                                <span className='formfieldtitle'>Report End Date<sup>*</sup></span>
                                {this.state.time_aggregation === 'Monthly' ?
                                    <Datetime
                                        input={true}
                                        styles={selectColourStyles}
                                        isValidDate={valid}
                                        dateFormat={"MMM YYYY"}
                                        timeFormat={false}
                                        onChange={this.handleDateEnd_monthly}
                                        initialViewMode={'months'}
                                        value={this.state.selectedend}
                                        inputProps={{ autoComplete: 'off' }}
                                        //ref="datetime"
                                        //inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportstartdate" }}
                                        //viewDate={(Constant.entrytype === 'edit' || Constant.entrytype === 'copy') ? new Date(this.state.selectedend) : this.state.futuredate}
                                    />
                                    :
                                    <Datetime
                                        input={true}
                                        styles={selectColourStyles}
                                        isValidDate={valid}
                                        dateFormat={"YYYY-MM-DD"}
                                        timeFormat={false}
                                        onChange={this.handleDateEnd}
                                        value={this.state.selectedend}
                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddate", autoComplete: 'off'  }}
                                        viewDate={(Constant.entrytype === 'edit' || Constant.entrytype === 'copy') ? new Date(this.state.selectedstart) : this.state.futuredate}
                                    />
                                }
                            </div>

                            <div style={{ paddingTop: '10px' }} className='col-sm-6'>
                                {this.state.start_date_missing ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date must be provided</span> : <span></span>}
                                {this.state.start_date_ltstart ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {"Start date must be greater than or equal to " + this.state.pastdate_formatted} </span> : <span></span>}
                                {this.state.start_date_gtend ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {"Start date must be less than or equal to " + this.state.futuredate_formatted} </span> : <span></span>}
                                {this.state.start_date_invalid ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date format is invalid </span> : <span></span>}
                            </div>

                            <div style={{ paddingTop: '10px' }} className='col-sm-6'>
                                {this.state.end_date_missing ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> End date must be provided</span> : <span></span>}
                                {this.state.start_gt_end ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> End date must be after Start date </span> : <span></span>}
                                {this.state.end_date_ltstart ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {"End date must be greater than or equal to " + this.state.pastdate_formatted} </span> : <span></span>}
                                {this.state.end_date_gtend ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {"End date must be less than or equal to " + this.state.futuredate_formatted} </span> : <span></span>}
                                {this.state.end_date_invalid ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> End date format is invalid </span> : <span></span>}
                            </div>
                        </div>

                        <div className="row" style={{ padding: '10px' }} id="dropdowns">
                            <div style={dropdownInput} className='col-sm-12'>
                                <span className='formfieldtitle'>Store Group<sup>*</sup></span>
                                <Select
                                    inputId="geodropdown"
                                    styles={selectColourStyles}
                                    options={this.state.geos}
                                    placeholder={'Select A Store Group'}
                                    onChange={(item, action) => this.onChangeGeo(item, action)}
                                    value={this.state.Geochosen}
                                />
                                {this.state.geos_missing ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />A Store Group must be selected</span> : <span></span>}
                            </div>
                        </div>

                        {this.state.brands && this.state.brands.length > 0
                            ?
                            <div style={{ padding: '10px' }}>
                                <span className='formfieldtitle'>Brands<sup>*</sup></span>
                                <div>
                                    <Select
                                        inputId="branddropdown"
                                        options={[alloption, ...this.state.brands]}
                                        isMulti
                                        placeholder={'Select Brands'}
                                        onChange={(item, action) => this.onChangeBrand(item, action)}
                                        value={this.state.allBrandsChosen ? [alloption] : this.state.Brandchosen}
                                        style={{ padding: '10px' }}
                                        styles={dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                    {this.state.brands_missing ? <span style={{ color: '#822433', paddingTop: '10px' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> At least one brand must be provided</span> : <span></span>}
                                </div>
                            </div>
                            :
                            <div />
                        }

                        {this.state.items && this.state.items.length > 0
                            ?
                            <div style={{ padding: '10px' }}>
                                <span className='formfieldtitle'>Items</span>
                                <div>
                                    <Select
                                        inputId="itemdropdown"
                                       
                                        options={[alloption, ...this.state.items]}
                                        isMulti
                                        placeholder={'Select Items'}
                                        onChange={(item, action) => this.onChangeItem(item, action)}
                                        value={this.state.Itemchosen}
                                        style={{ padding: '10px' }}
                                        styles={dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>
                            </div>
                            :
                            <div />
                        }

                        <div className="row" style={{ paddingTop: '30px', paddingBottom: '10px', paddingRight: '10px', paddingLeft: '10px' }}>
                            <div style={dateInput} className='col-sm-12'>
                                <Button variant="contained" onClick={this.handleClick} style={buttonStyle} id="runbutton">
                                    Save and Run
                                    </Button>
                                {Constant.entrytype !== 'create' ?
                                    <Button variant="contained" onClick={this.handleClickcancel} style={buttonStyle2}>
                                        Cancel
                                    </Button>
                                    :
                                    <span />}
                            </div>
                        </div>
                    </form>
                </div>
              
            </Layout>
        )
    }
}
