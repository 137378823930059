import React, { Component, Fragment, useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Select from 'react-select'
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as Constant from '../../constants'



export default function EditGroupMemebers(props) {

    const {
        open,
        onClose,
        groupinfoName,
        group_id,
        time_updated,
        users_by_company,
        groupinfoUsers,
        handleSubmitGroup,
        group_membership_button_enabled
    } = props

    const [selectedUsers, setSelectedUsers] = useState({})

    useEffect(() => {
        const users = (users_by_company ?? []).map(company => company.users)
            .flat()
            .map(user => user.user_id)
            .reduce((prev, current) => ({...prev, [current.toString()]: groupinfoUsers.includes(parseInt(current))}), {})
        setSelectedUsers(users)
    }, [groupinfoUsers, users_by_company])

    function toggleUser(userId) {
        setSelectedUsers({...selectedUsers,  [userId.toString()]: !selectedUsers[userId.toString()]})
    }

    function submitGroup() {
        const newValues = Object.keys(selectedUsers).filter(userId => selectedUsers[userId]).map(userId => parseInt(userId))
        handleSubmitGroup(newValues)
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={onClose}
            style={{ overflowY: 'scroll' }}
        >
            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                <div className="row">
                    <div style={{ textAlign: 'left', width: '50%' }}>
                        <h1>Group Membership</h1>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%' }}>
                        <IconButton onClick={onClose}>
                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                        </IconButton>
                    </div>
                </div>
                Group Name
                <div>
                    <TextField
                        variant="outlined"
                        id="groupinfoName"
                        value={groupinfoName}
                        fullWidth
                        disabled
                    />
                </div>
                Group ID
                <div>
                    <TextField
                        variant="outlined"
                        disabled
                        value={group_id}
                        fullWidth
                    />
                </div>
                Last Updated
                <div>
                    <TextField
                        variant="outlined"
                        disabled
                        value={time_updated}
                        fullWidth
                    />
                </div>
                <h4>Users by Company</h4>
                {(users_by_company || []).map(company => {
                    const num_selected = company.users.reduce((prev, current) => prev + (selectedUsers[current.user_id.toString()] === true ? 1 : 0), 0)
                    return <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 style={{ padding: '10px' }}>{company.company_name}</h5>
                            <p>{num_selected + '/' + company.users.length + ' selected'}</p>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <h5>{ }</h5>
                                {(company.users || []).map(user => {
                                    return <div>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                checked={selectedUsers[user.user_id] === true}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                onChange={() => toggleUser(user.user_id)}
                                            />
                                            {user.user_name}
                                        </ThemeProvider>
                                    </div>
                                })
                                }
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                })
                }
                <div className='row' style={{ margin: '25px' }}>
                    <div className='col-sm-4'></div>
                    <div className='col-sm-4'></div>
                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitGroup}
                            disabled={!group_membership_button_enabled}
                        >
                            Submit Changes
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}   