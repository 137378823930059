import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants';



export class DepthOfDiscountByALRGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        return (
            <Fragment>
                {this.props.state.indexSwitchCheckDollars ?
                    <Fragment>
                        <HighchartsReact
                            highcharts={Highcharts}
                            oneToOne={true}
                            options={
                                {
                                    chart: {
                                        //type: 'column'
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        type: 'column',
                                        //16:9 ratio
                                        height: (9 / 16 * 100) + '%',
                                    },
                                    metadata: this.props.state.chartmetadata,
                                    caption: {
                                        text: this.props.state.attr_caption_text
                                    },
                                    title: {
                                        text: 'Percent of ' + this.props.state.currencyType + ' by Discount Range Retailer vs ROM' + this.props.state.chart_title_info
                                    },
                                    series: (
                                        this.props.state.outlets.map((outlet, idx) => {
                                            var graphcolor
                                            if (idx === 1) {
                                                graphcolor = Constant.graph_colors[2]
                                            }
                                            else {
                                                graphcolor = Constant.graph_colors[idx]
                                            }
                                            return {
                                                'name': outlet,
                                                //'data': this.props.state.discountbuckets.map((discountbucket, idx) => (this.props.state.outlet_data_discountrange[outlet][discountbucket]['totaldollars'] / this.props.state.outlet_data[outlet]['totaldollars'])),
                                                'data': this.props.state.discountbuckets.map((discountbucket, idx) => ((((this.props.state.outlet_data_discountrange[outlet] || {})[discountbucket] || {})['totaldollars'] || 0) / ((this.props.state.outlet_data[outlet] || {})['totaldollars'] || 0))),
                                                'type': 'column',
                                                'color': graphcolor
                                            }
                                        })
                                    )
                                    ,
                                    xAxis: {
                                        title: {
                                            text: 'Discount Range'
                                        },
                                        categories:
                                            this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                    },
                                    yAxis: {
                                        gridLineColor: "#CCC",
                                        gridLineWidth: 1,
                                        title: {
                                            text: 'Percent of ' + this.props.state.currencyType + ' Sold in Discount Range'
                                        },
                                        labels: {
                                            //format: '{value}%'
                                            formatter: function () {
                                                return Highcharts.numberFormat(this.value * 100, 0) + '%'
                                            }
                                        }
                                    },
                                    plotOptions: {
                                        column: {
                                            dataLabels: {
                                                enabled: true,
                                                formatter: function () {
                                                    return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                },
                                                color: 'black',
                                                borderRadius: 5,
                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                borderWidth: 1,
                                                borderColor: '#d6d7d8',
                                                padding: 2,
                                                style: {
                                                    textOutline: 'none',
                                                    fontSize: '20px'
                                                },
                                            }

                                        },


                                    },
                                    tooltip: {
                                        //pointFormat: '{series.name}: <b>{point}%</b>'
                                        formatter: function () {
                                            return '<span> '
                                                + this.series.name
                                                + '<br/>'
                                                + this.x
                                                + '<br/>'
                                                + this.y.toLocaleString(undefined, {
                                                    style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                })
                                        }
                                    },
                                    exporting: {
                                        showTable: this.props.state.showDataTable,
                                        tableCaption: false
                                    },
                                }}
                        />
                        <div style={{ display: this.props.state.showDataTableStyle }}>
                            <Constant.NPDTable>
                                <Constant.NPDTableBody>
                                    <Constant.NPDTableRow key="header_g3">
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                        {this.props.state.outlets.map(outlet => {
                                            return <Constant.NPDTableCell key={"headercol_" + outlet + "_g4"} style={{ textAlign: 'center' }}>{outlet + this.props.state.currencyType + ' in Discount Range'}</Constant.NPDTableCell>
                                        })
                                        }
                                    </Constant.NPDTableRow>
                                    {this.props.state.discountbuckets.map(discountbucket => {
                                        return <Constant.NPDTableRow key={"datarow_" + discountbucket + "_g3"}>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{discountbucket}</Constant.NPDTableCell>
                                            {this.props.state.outlets.map(outlet => {
                                                return <Constant.NPDTableCell key={"datarow_" + discountbucket + outlet + "_g3"} style={{ textAlign: 'center' }}>{
                                                    //(this.props.state.outlet_data_discountrange[outlet][discountbucket]['totaldollars'] / this.props.state.outlet_data[outlet]['totaldollars']).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                    ((((this.props.state.outlet_data_discountrange[outlet] || {})[discountbucket] || {})['totaldollars'] || 0) / ((this.props.state.outlet_data[outlet] || {})['totaldollars'] || 0)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            }</Constant.NPDTableCell>
                                            })
                                            }
                                        </Constant.NPDTableRow>
                                    })
                                    }
                                </Constant.NPDTableBody>
                            </Constant.NPDTable>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <HighchartsReact
                            highcharts={Highcharts}
                            oneToOne={true}
                            options={
                                {
                                    chart: {
                                        //type: 'column'
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        type: 'column',
                                        //16:9 ratio
                                        height: (9 / 16 * 100) + '%',
                                    },
                                    metadata: this.props.state.chartmetadata,
                                    caption: {
                                        text: this.props.state.attr_caption_text
                                    },
                                    title: {
                                        text: 'Percent of Units by Discount Range Retailer vs ROM' + this.props.state.chart_title_info
                                    },
                                    series: (
                                        this.props.state.outlets.map((outlet, idx) => {
                                            var graphcolor
                                            if (idx === 1) {
                                                graphcolor = Constant.graph_colors[2]
                                            }
                                            else {
                                                graphcolor = Constant.graph_colors[idx]
                                            }
                                            return {
                                                'name': outlet,
                                                //'data': this.props.state.discountbuckets.map((discountbucket, idx) => (this.props.state.outlet_data_discountrange[outlet][discountbucket]['totalunits'] / this.props.state.outlet_data[outlet]['totalunits'])),
                                                'data': this.props.state.discountbuckets.map((discountbucket, idx) => ((((this.props.state.outlet_data_discountrange[outlet] || {})[discountbucket] || {})['totalunits'] || 0) / ((this.props.state.outlet_data[outlet] || {})['totalunits'] || 0))),
                                                'type': 'column',
                                                'color': graphcolor
                                            }
                                        })
                                    )
                                    ,
                                    xAxis: {
                                        title: {
                                            text: 'Discount Range'
                                        },
                                        categories:
                                            this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                    },
                                    yAxis: {
                                        gridLineColor: "#CCC",
                                        gridLineWidth: 1,
                                        title: {
                                            text: 'Percent of ' + this.props.state.unitsvar + ' Sold in Discount Range'
                                        },
                                        labels: {
                                            //format: '{value}%'
                                            formatter: function () {
                                                return Highcharts.numberFormat(this.value * 100, 0) + '%'
                                            }
                                        }
                                    },
                                    plotOptions: {
                                        column: {
                                            dataLabels: {
                                                enabled: true,
                                                formatter: function () {
                                                    return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                },
                                                color: 'black',
                                                borderRadius: 5,
                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                borderWidth: 1,
                                                borderColor: '#d6d7d8',
                                                padding: 2,
                                                style: {
                                                    textOutline: 'none',
                                                    fontSize: '20px'
                                                },
                                            }
                                        },


                                    },
                                    tooltip: {
                                        //pointFormat: '{series.name}: <b>{point}%</b>'
                                        formatter: function () {
                                            return '<span> '
                                                + this.series.name
                                                + '<br/>'
                                                + this.x
                                                + '<br/>'
                                                + this.y.toLocaleString(undefined, {
                                                    style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                })
                                        }
                                    },
                                    exporting: {
                                        showTable: this.props.state.showDataTable,
                                        tableCaption: false
                                    },
                                }}
                        />
                        <div style={{ display: this.props.state.showDataTableStyle }}>
                            <Constant.NPDTable>
                                <Constant.NPDTableBody>
                                    <Constant.NPDTableRow key="header_g3">
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                        {this.props.state.outlets.map(outlet => {
                                            return <Constant.NPDTableCell key={"headercol_" + outlet + "_g4"} style={{ textAlign: 'center' }}>{outlet + ' Units in Discount Range'}</Constant.NPDTableCell>
                                        })
                                        }
                                    </Constant.NPDTableRow>
                                    {this.props.state.discountbuckets.map(discountbucket => {
                                        return <Constant.NPDTableRow key={"datarow_" + discountbucket + "_g3"}>
                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{discountbucket}</Constant.NPDTableCell>
                                            {this.props.state.outlets.map(outlet => {
                                                return <Constant.NPDTableCell key={"datarow_" + discountbucket + outlet + "_g3"} style={{ textAlign: 'center' }}>{
                                                    //(this.props.state.outlet_data_discountrange[outlet][discountbucket]['totalunits'] / this.props.state.outlet_data[outlet]['totalunits']).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                    ((((this.props.state.outlet_data_discountrange[outlet] || {})[discountbucket] || {})['totalunits'] || 0) / ((this.props.state.outlet_data[outlet] || {})['totalunits'] || 0)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            }</Constant.NPDTableCell>
                                            })
                                            }
                                        </Constant.NPDTableRow>
                                    })
                                    }
                                </Constant.NPDTableBody>
                            </Constant.NPDTable>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}