import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateLevel4DataGeneric(item_type, filters_list, level_list) {
    let report_id = this.state.reportsettings.report_id
    let extra_param = this.state.extraparam

    let url_level4selection = '/api/pdt/ReportData/Filtered/Level4Data/' + report_id + extra_param
    let url_level3selection = '/api/pdt/ReportData/Filtered/Level3Data/' + report_id + extra_param
    let url_level2selection = '/api/pdt/ReportData/Filtered/Level2Data/' + report_id + extra_param
    let url_level1selection = '/api/pdt/ReportData/Filtered/Level1Data/' + report_id + extra_param

    // Create our list of 16 batch requests for level4, level3, level2, level1 data each for current year 1YAGO, 2YAGO, 3YAGO
    let batch_requests = []
    filters_list.forEach(filters => {
        filters = { ...filters } // Make a copy before we alter the filter we're working with for this step
        filters['level3'] = level_list.slice(0, 3)
        filters['level2'] = level_list.slice(0, 2)
        filters['level1'] = level_list.slice(0, 1)

        let filters_level4 = assignFilterLevels(filters, level_list)
        let filters_level3 = assignFilterLevels(filters, level_list.slice(0, 3))
        let filters_level2 = assignFilterLevels(filters, level_list.slice(0, 2))
        let filters_level1 = assignFilterLevels(filters, level_list.slice(0, 1))
        batch_requests = batch_requests.concat([
            { 'path': url_level4selection, 'method': 'POST', 'body': filters_level4 },
            { 'path': url_level3selection, 'method': 'POST', 'body': filters_level3 },
            { 'path': url_level2selection, 'method': 'POST', 'body': filters_level2 },
            { 'path': url_level1selection, 'method': 'POST', 'body': filters_level1 }
        ])
    })

    let batch_request = [{ 'url': Constant.baseapiurl_gen + 'pdt/Batch' + extra_param, 'method': 'POST', 'body': batch_requests }]
    let results = await this.entityCrud('PDT 4-level ' + item_type + ' data, via batch', batch_request, this.on_api_entity_crud_error)
    if (results.Errors === 0) {
        let all_results = results.ResultList[0]
        if (item_type.length > 0) {
            item_type = item_type + '_'
        }
        this.setState({
            ['level4_' + item_type + 'table_currentyear']: all_results[0].body.level4_data || {},
            ['level3_' + item_type + 'table_currentyear']: all_results[1].body.level3_data || {},
            ['level2_' + item_type + 'table_currentyear']: all_results[2].body.level2_data || {},
            ['level1_' + item_type + 'table_currentyear']: all_results[3].body.level1_data || {},

            ['level4_' + item_type + 'table_oneyearago']: all_results[4].body.level4_data || {},
            ['level3_' + item_type + 'table_oneyearago']: all_results[5].body.level3_data || {},
            ['level2_' + item_type + 'table_oneyearago']: all_results[6].body.level2_data || {},
            ['level1_' + item_type + 'table_oneyearago']: all_results[7].body.level1_data || {},

            ['level4_' + item_type + 'table_twoyearsago']: all_results[8].body.level4_data || {},
            ['level3_' + item_type + 'table_twoyearsago']: all_results[9].body.level3_data || {},
            ['level2_' + item_type + 'table_twoyearsago']: all_results[10].body.level2_data || {},
            ['level1_' + item_type + 'table_twoyearsago']: all_results[11].body.level1_data || {},

            ['level4_' + item_type + 'table_threeyearsago']: all_results[12].body.level4_data || {},
            ['level3_' + item_type + 'table_threeyearsago']: all_results[13].body.level3_data || {},
            ['level2_' + item_type + 'table_threeyearsago']: all_results[14].body.level2_data || {},
            ['level1_' + item_type + 'table_threeyearsago']: all_results[15].body.level1_data || {}
        })
    }
}
