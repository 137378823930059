import React, { Component } from 'react';
import { BrandChannelSwitch } from '../../UIElements/Switches/BrandChannelSwitch'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
import { MultiGeoSelect } from '../../UIElements/Dropdowns/MultiGeoSelect'
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { DepthOfDiscountGraph } from '../../../Graphs/DepthOfDiscountGraph'
import { reducer } from '../../Functions/Utilities/Reducer'

export class DepthOfDiscount extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        var totalvol_totalcat = 0
        try {
            totalvol_totalcat = this.props.state.distinctdates.map(date => this.props.state.weekly_data['Total Category'][date]['totalunits']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat = 0
        }

        var totalvol_totalcat_geo = 0
        try {
            totalvol_totalcat_geo = this.props.state.distinctdates.map(date => this.props.state.weekly_data_geo['All Channels'][date]['totalunits']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat_geo = 0
        }

        var total_cat_data = []
        try {
            total_cat_data = this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data['Total Category'][discountbucket]['totalunits'] / totalvol_totalcat)
        }
        catch (error) {
            total_cat_data = []
        }

        var totalvol_totalcat_dollars = 0
        try {
            totalvol_totalcat_dollars = this.props.state.distinctdates.map(date => this.props.state.weekly_data['Total Category'][date]['totaldollars']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat_dollars = 0
        }

        var totalvol_totalcat_geo_dollars = 0
        try {
            totalvol_totalcat_geo_dollars = this.props.state.distinctdates.map(date => this.props.state.weekly_data_geo['All Channels'][date]['totaldollars']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat_geo_dollars = 0
        }

        var total_cat_data_dollars = []
        try {
            total_cat_data_dollars = this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data['Total Category'][discountbucket]['totaldollars'] / totalvol_totalcat_dollars)
        }
        catch (error) {
            total_cat_data_dollars = []
        }

        return (

            <div style={{ width: "100%", height: "100%"}}>
                <div className="row">
                    <div className="col-sm-6" align='left'>
                        <BrandChannelSwitch
                            state={this.props.state}
                            handleChange_channelviews={this.props.handleChange_channelviews}
                        />
                    </div>
                    <div className="col-sm-6 row" align='left'>
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}   
                        />
                    </div>
                    
                    {
                    this.props.state.channelviews ?
                        <div className="col-sm-12" align='left'>
                            <div className="col-sm-9">
                                <MultiGeoSelect
                                    state={this.props.state}
                                    changeSelectedChannelMulti={this.props.changeSelectedChannelMulti}
                                />
                            </div>
                            <div className="col-sm-3" />
                        </div>
                        :
                        <div className="col-sm-12" align='left'>
                            <div className="col-sm-9">
                                <MultiBrandSelect
                                    state={this.props.state}
                                    changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                                />
                            </div>
                            <div className="col-sm-3" />
                        </div>
                    }

                    <div>
                        {/* <FilterBarReportViews
                            state={this.props.state}
                            changeViewFilters={this.props.changeViewFilters}
                            handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                            changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                        /> */}
                        <FilterBarReportViewsFunctional />
                    </div>
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%"}}>
                        <DepthOfDiscountGraph
                            state={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}