import Datetime from 'react-datetime'
import * as Constant from '../../constants'

export const initialState = {
    api_error_dialog: false,
    api_error_details: null,
    api_error_count: 0,
    activevalue: 1,
    value: 0,
    value2: 0,
    value3: 0,
    value3a: 0,
    value4: 0,
    value5: 0,
    value6: 0,
    comptabvalue: 0,
    pastdate: Datetime.moment().set({ 'year': 2019, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
    futuredate: Datetime.moment().set({ 'year': 2021, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
    unitsvar: 'Units',
    unitsvarSingular: 'Unit',
    isLoaded: false,
    showDataTableStyle: 'none',
    voldecomp_type: 'bar',
    volume_by_geo: {},
    displayCriteria: 'block-inline',
    displayFilters: 'none',
    displayFilters_comp: 'none',
    displayInsights: 'none',
    ReportCriteriaModalOpen: false,
    AllItemsModalOpen: false,
    AllBrandsModalOpen: false,
    AllGeosModalOpen: false,
    AllAttrsModalOpen: false,
    AllAttrsModalOpenG1: false,
    AllAttrsModalOpenG2: false,
    ComputeDateRangeModalOpen: false,
    reportsettings: {},
    distinctgeos: [],
    reportbrands: [],
    reportitems: [],
    distinctitems: [],
    reportInsightText: 'This is the',
    reportInsightText_emph: ' insight ',
    reportInsightText_end: 'for the report.',
    distinctdates: [],
    distinctdates_forcalc: [],
    distinctdates_prior: [],
    distinctdates_last: [],
    discountbuckets: [],
    attributes: {},
    weekly_data: {},
    weekly_data_prior: {},
    weekly_data_last: {},
    weekly_data_geo: {},
    discountband_data: {},
    discountband_data_current: {},
    discountband_data_prior: {},
    discountband_data_last: {},
    item_data: {},
    attr_data: {},
    selectedBrands: [],
    selectedItems: [],
    selectedItems_brand: [],
    histdata1: [],
    histdata2: [],
    histdataItem_outlet: {},
    histdataItem_outlet_new: {},
    histdataItem_outlet_weekly: {},
    commonitems: [],
    commonitems_new: [],
    outlet_data: {},
    reportData: [],
    brands: [],
    brands_sorted: [],
    brands_sorted_dollars: [],
    items: [],
    items_sorted: [],
    items_sorted_dollars: [],
    items_sorted_brand: [],
    segment1s_sorted: [],
    segment1s_sorted_dollars: [],
    segment2s_sorted: [],
    segment2s_sorted_dollars: [],
    segment3s_sorted: [],
    segment3s_sorted_dollars: [],
    segment4s_sorted: [],
    segment4s_sorted_dollars: [],
    outlets: [],
    all_outlets: [],
    nonpromo_my: 0,
    promo_my: 0,
    inc_my: 0,
    sub_my: 0,
    unsub_my: 0,
    base_my: 0,
    disc_my: 0,
    total_my: 0,
    weightedbp_my: 0,
    weightedbp_withoutno_my: 0,
    incdollars_my: 0,
    discdollars_my: 0,
    revopp_my: 0,

    nonpromo_my_withoutYOY: 0,
    promo_my_withoutYOY: 0,
    inc_my_withoutYOY: 0,
    sub_my_withoutYOY: 0,
    unsub_my_withoutYOY: 0,
    base_my_withoutYOY: 0,
    disc_my_withoutYOY: 0,
    total_my_withoutYOY: 0,
    weightedbp_my_withoutYOY: 0,
    weightedbp_withoutno_my_withoutYOY: 0,
    incdollars_my_withoutYOY: 0,
    discdollars_my_withoutYOY: 0,
    revopp_my_withoutYOY: 0,

    revopp_my_prior: 0,
    nonpromo_my_prior: 0,
    promo_my_prior: 0,
    inc_my_prior: 0,
    sub_my_prior: 0,
    unsub_my_prior: 0,
    base_my_prior: 0,
    disc_my_prior: 0,
    total_my_prior: 0,
    weightedbp_my_prior: 0,
    incdollars_my_prior: 0,
    discdollars_my_prior: 0,

    revopp_my_last: 0,
    nonpromo_my_last: 0,
    promo_my_last: 0,
    inc_my_last: 0,
    sub_my_last: 0,
    unsub_my_last: 0,
    base_my_last: 0,
    disc_my_last: 0,
    total_my_last: 0,
    weightedbp_my_last: 0,
    incdollars_my_last: 0,
    discdollars_my_last: 0,
    totaldollars_my_last: 0,

    totaldollars_my: 0,
    totaldollars_my_prior: 0,
    totaldollars_my_withoutYOY: 0,

    nonpromoteddollars_my: 0,
    promoteddollars_my: 0,
    basedollars_my: 0,
    incrementaldollars_my: 0,

    totaldollars_my_withoutYOY: 0,
    nonpromoteddollars_my_withoutYOY: 0,
    promoteddollars_my_withoutYOY: 0,
    basedollars_my_withoutYOY: 0,
    incrementaldollars_my_withoutYOY: 0,

    nonpromoteddollars_my_prior: 0,
    promoteddollars_my_prior: 0,
    basedollars_my_prior: 0,
    incrementaldollars_my_prior: 0,

    nonpromoteddollars_my_last: 0,
    promoteddollars_my_last: 0,
    basedollars_my_last: 0,
    incrementaldollars_my_last: 0,

    totalcatselected: false,
    totalselselected: true,
    channelselected: false,
    brandselected: false,
    itemselected: false,
    pricebandselected: false,
    segment1selected: false,
    segment2selected: false,
    segment3selected: false,
    segment4selected: false,
    baseselected: true,
    promoselected: false,
    showSnackbar_changebrands: false,
    showSnackbar_datafiltered: false,
    exclude_brands: false,
    exclude_brands_g1: false,
    exclude_brands_g2: false,
    exclude_items: false,
    filter_object: {
        'brands': [],
        'items': [],
        'startdate': null,
        'enddate': null,
        'geo': '',
        'attributes': {},
        'asprange': [],
        'pricebands': [],
        'numweeks': 1,
        'segment1': [],
        'segment2': [],
        'segment3': [],
        'segment4': [],
        'outlets': [],
        'channels': []
    },
    asp_max: 0,
    asp_min: 0,
    sliderRange: [0, 0],
    priceBandOptions: [],
    sliderRangeMin: 0,
    sliderRangeMax: 100,
    numruns: 0,
    showGroup1DefModal: false,
    showGroup2DefModal: false,
    selectedBrands_g1: [],
    selectedAttributes_g1: {},
    selectedPriceBands_g1: [],
    selectedsegment1_g1: [],
    selectedsegment2_g1: [],
    selectedsegment3_g1: [],
    selectedsegment4_g1: [],
    selectedoutlet_g1: [],
    selectedchannel_g1: [],
    selectedBrands_g2: [],
    selectedAttributes_g2: {},
    selectedPriceBands_g2: [],
    selectedsegment1_g2: [],
    selectedsegment2_g2: [],
    selectedsegment3_g2: [],
    selectedsegment4_g2: [],
    selectedoutlet_g2: [],
    selectedchannel_g2: [],
    comparisonMetricOptions: Constant.comparisonMetricOptions,
    comparisonMetricOptions_trended: Constant.comparisonMetricOptions_trended,
    overlayMetricOptions: Constant.overlayMetricOptions,
    comparisonMetricSelected: "",
    comparisonMetricSelected_trended: "",
    overlayMetricSelected: "",
    comparisonLevelOptions: Constant.comparisonLevelOptions,
    comparisonLevelSelected: "",
    comparisonMemberOptions_g1: [],
    comparisonMemberOptions_g2: [],
    comparison_starting_options: [],
    comparisonGroup1: [],
    comparisonGroup2: [],
    MetricToCompare: "",
    MetricToCompare_trended: "",
    compareGraphData: [],
    compareGraphData_trended: [],
    compareIndexGraphData: [],
    compareIndexGraphDataOverUnder: [],
    //compareIndexGraphData_toview: [],
    index_compare_type: 'Indexed',
    indexSwitchCheck: false,
    chart_title_info: "",
    chart_title_info_nobrands: "",
    chart_title_info_nobrands_prior: "",
    chart_title_info_nochannels_prior: "",

    chart_title_info_nobrands_last: "",
    chart_title_info_nochannels_last: "",

    baseselectedattr: true,
    promoselectedattr: false,
    liftselectedattr: false,
    nodiscSwitchCheck: true,

    errorAlert: false,
    errorAlert_report: false,
    errorAlert_runcompg1: false,
    errorAlert_runcompg2: false,
    errorAlert_runcompg1g2: false,
    errorAlert_noAccess: false,

    xAxisStepNum: 1,
    xAxisPadNum: 5,
    xAxisFontSizeString: '11px',
    xAxisText: '',
    timeperiodlabels: '',
    xDateFormatString: 'Week Ending: %Y-%m-%d',
    xAxisLabelFormatString: '%b %Y',
    weeklytimeperiods: "Week",
    indexSwitchCheckDollars: false,
    indexSwitchROMRolledUp: false,
    indexSwitchYOY: false,
    indexSwitchseg1seg2: false,
    filterPause: 'pause',
    filterPause_reportViews: 'pause',
    slicers_not_applied: false,
    graph_selected_attributes: [],
    graph_selected_attributes_labels: [],
    viewMultipleBrands: false,
    viewItems: false,
    reportloaddialog: true,

    retailerbrandviewsavgdisc: true,
    retailerbrandviewspromounits: false,
    retailerbrandviewspromodollars: false,
    retailerbrandviewspromo: true,
    retailerbrandviewsnonpromo: false,
    retailerbrandviewsasp: false,
    retailerbrandviewsbase: false,
    sharemetricstotal: true,
    sharemetricsbase: false,
    sharemetricsincremental: false,
    sharemetricspromoted: false,
    sharemetricsnonpromoted: false,
    segment1s: [],
    segment2s: [],
    segment3s: [],
    segment4s: [],
    segment1_options: [],
    segment2_options: [],
    segment3_options: [],
    segment4_options: [],
    segment1label: '',
    segment2label: '',
    segment3label: 'Segment 3',
    segment4label: 'Segment 4',
    currencycode: 'USD',
    currencyType: 'Dollars',
    currencysymbol: '$',
    numrowevals: 0,



    NonPromotedBarData: [],
    PromotedBarData: [],
    UnSubBarData: [],
    SubBarData: [],
    IncBarData: [],
    LiftGraphData: [],
    PromoLiftGraphData: [],
    EffGraphData: [],
    DiscGraphData: [],
    NonPromotedBarData_Dollars: [],
    PromotedBarData_Dollars: [],
    UnSubBarData_Dollars: [],
    SubBarData_Dollars: [],
    IncBarData_Dollars: [],
    LiftGraphData_Dollars: [],
    PromoLiftGraphData_Dollars: [],
    EffGraphData_Dollars: [],
    DiscGraphData_Dollars: [],

    NonPromotedBarData_All: [],
    PromotedBarData_All: [],
    UnSubBarData_All: [],
    SubBarData_All: [],
    IncBarData_All: [],
    LiftGraphData_All: [],
    PromoLiftGraphData_All: [],
    EffGraphData_All: [],
    DiscGraphData_All: [],
    NonPromotedBarData_Dollars_All: [],
    PromotedBarData_Dollars_All: [],
    UnSubBarData_Dollars_All: [],
    SubBarData_Dollars_All: [],
    IncBarData_Dollars_All: [],
    LiftGraphData_Dollars_All: [],
    PromoLiftGraphData_Dollars_All: [],
    EffGraphData_Dollars_All: [],
    DiscGraphData_Dollars_All: [],
    DiscountDollarData: [],
    PBRData: [],
    g1Name: 'Group 1',
    g2Name: 'Group 2',
    filterpage: 1,
    openScorecardMenu: false,
    openScorecardMenuYOY: false,
    openFlexibleTableMenu: false,
    isretailerview: false,
    metricsforindexviews: Constant.metricsforindexviews,
    metricsforindexviews_selected: { 'metric': "", 'metrictype': 'units' },
    metricsforcomparerom: Constant.metricsforcomparerom,

    metricOptions: Constant.metricOptions,
    metricOptions_Area: Constant.metricOptions_Area,
    levelOptions: Constant.levelOptions,
    levelOptions_BaseInc: Constant.levelOptions_BaseInc,
    ALRvsROMDataset: [],
    ALRvsROMCategories: [],
    categories_baseinc: [],
    level_baseinc: '',
    levelsSelected: [],
    selectedMetrics: [],
    selectedMetric: { 'label': 'Base', 'value': 'Base' },
    selectedLevel: { 'label': 'Total', 'value': 'Total' },
    selectedLevel_BaseInc: { 'label': 'Total', 'value': 'Total' },
    selectedMetric_Area: { 'label': 'Total', 'value': 'total' },
    dataforviews_romcompare: [],
    viewromnum: 0,
    viewattrnum: 0,
    indices: [],
    weekindices: [],
    indexTopBottom: false,
    chartmetadata: {
        brands: "",
        segments: "",
        geography: "",
        dates: "",
        attributes: "",
        pricebands: "",
        asprange: "",
    },
    showitem_views: false,
    usercompanyname: null,
    chartmetadata_singlebrand: {},
    chartmetadata_singlechannel: {},
    all_geos: [],
    selectedChannel: 'All Channels',
    channelviews: false,
    OneYAGO: true,
    TwoYAGO: false,    
    ThreeYAGO: false,
    selectedChannels: [],
    dataset_data: {},
    filterpage1_open: false,
    filterpage2_open: false,
    smoothingWeeks: [4, 6, 8, 12],
    smoothingWeekNum: 4,
    stackingType: 'normal',
    stackingType_checked: false,    
    changeInFilters_alert: false,
    DateCheck_alert: false,
    attributenamelabels: {},
    downloadAllYOY: false,
    page: 0,
    rowsPerPage: 5,
    ScreenOrderAlert: false,
    default_alr_order: Constant.default_alr_order,
    default_tmm_order: Constant.default_tmm_order,
    showVizzesModal: false,
    customAttrGroups: {},
    use_attr_groups: false,
    price_dist_option: 'asp',
    itemdistpriceoptions: Constant.itemdistpriceoptions,
    selected_itemdistpriceoption: { value: 'asp', 'label': 'Average Selling Price' },
    ALRvsROM_loading: true,
    isLoaded_Filters: true
}