import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import * as Constant from '../../constants'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';
import HC_export_data from 'highcharts/modules/export-data'
import selectColourStyles from '../../styles/SelectColourStyles'

more(Highcharts);
HC_export_data(Highcharts)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export class OutputSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            openDefinition: false,
            anchorElDefinition: null,
            definitiontodisplay: ''
        }
        //console.log(props)
    }

    

    render() {
        //console.log('this.props.secondGraphData.waterfall_data', this.props.secondGraphData.waterfall_data)
        //console.log('this.state',this.props.state)
        //console.log('this.props.financialdata', this.props.financialData)
        const handleChangeTab = (event, newValue) => {
            this.setState({ value: newValue })
        };

        const handleChangeTabItemView = () => {
            if (this.state.value === 0) {
                this.setState({ value: 6 })
                setTimeout(function () { this.setState({ value: 0 }) }.bind(this), 200)
            }

            else if (this.props.state.simsettings['sim_type'] === 'promo' && this.state.value === 5) {
                this.setState({ value: 6 })
                setTimeout(function () { this.setState({ value: 5 }) }.bind(this), 200)
            }

            else if (this.props.state.simsettings['sim_type'] !== 'promo' && this.state.value === 4) {
                this.setState({ value: 6 })
                setTimeout(function () { this.setState({ value: 4 }) }.bind(this), 200)
            }
        };

        const showDefinition = (event) => {
            let target = event.currentTarget
            //console.log(target)
            let popover_string = target.id
            let popover_list = popover_string.split('_')
            let popover = popover_list[0]
            //console.log(popover)
            //console.log('Opening popover')

            var definitions_obj = {
                'grossrevenue': 'Simulated Total ' + this.props.state.currencytype + ' earned during the promotion(Subsidized Base and Incremental ' + this.props.state.currencytype + ')',
                'basegrossrevenue': 'Total ' + this.props.state.currencytype + ' Earned (Base ' + this.props.state.currencytype + ')',
                'totalspend': 'This is the total cost of the promotion. This is calculated for each item as (Promoted Units * Cost Per Promoted Unit) + (Promoted Units * (Base Price - Promoted Price)). This calculation is then summed up for each item.',
                'netrevenue': 'Gross Revenue - Cost Per Promoted Unit.',
                'costperpromounit': 'Total Promoted Units * Cost Per Additional Unit (as entered on the "Add Financials" page)',
                'incgrossrev': 'Incremental ' + this.props.state.currencytype + ' earned during the promotion',
                'incnetrev': 'Incremental Gross Revenue -Total Spend.',
                'incprofit': 'Incremental Net Revenue * Gross Margin for each item. These are then added up to get a total profit for the promotion.',
                'baseincprofit': 'Base Revenue * Gross Margin for each item. These are then added up to get a total profit',
                'salesroi': 'The return on investment is calculated as Incremental Net Revenue/Total Spend.',
                'retailpromomargindol': 'For each item this is calculated as Promoted ' + this.props.state.currencytype + ' * Retailer Margin %. This is added up to get the total Retailer Promotion Margin ' + this.props.state.currencytype + '.',
                'retailpromomarginpct': 'This is the Retailer Promotion Margin ' + this.props.state.currencytype + ' / Promotion ' + this.props.state.currencytype + '.',
                'retailedaymargin': 'For each item, we take the Retailer Margin Percentage * Subsidized Base ' + this.props.state.currencytype + '. We add all of these together and then divide by the total Subsidized Base ' + this.props.state.currencytype + '.',
                'retailpromoprofit': 'For each item, we calculate (Gross Revenue - Subsidized Base ' + this.props.state.currencytype + ') * Retailer Margin %. We then add all of these together to get profit.',
            }

            var definition = definitions_obj[popover]

            this.setState({
                openDefinition: true,
                anchorElDefinition: event.target,
                definitiontodisplay: definition
            })

        }

        const hideDefinition = () => {
            //console.log('Closing popovers')
            this.setState({
                openDefinition: false,
                //anchorElDefinition: null,
            })

        }

        var simtype_string_title = '<br/> Simulation Type: ' + (this.props.state.simsettings['sim_type'] || "").charAt(0).toUpperCase() + (this.props.state.simsettings['sim_type'] || "").slice(1).toLowerCase()
        var geo_string_title = '<br/> Geography: ' + (this.props.state.geolookup || {})[(this.props.state.simsettings['geogid'] || [])[0]]
        var date_string_title = '<br/> ' + this.props.state.simsettings['numweeks'] + ' weeks ending ' + (this.props.state.simsettings['sim_end'] || "").substring(0, 10)
        var chart_title_info = simtype_string_title + geo_string_title + date_string_title
        var currencycode = this.props.state.currencycode

        //Determine if we should show the item, item group, or brand dropdown
        var show_brand_dropdown = false
        if (this.props.level === 'Brand' && this.state.value !== 0) {
            if (this.props.state.simsettings['sim_type'] === 'promo' && this.state.value !== 4) {
                show_brand_dropdown = true
            }
            else if (this.props.state.simsettings['sim_type'] !== 'promo') {
                show_brand_dropdown = true
            }
        }

        var show_itemgroup_dropdown = false
        if (this.props.level === 'Item Group' && this.state.value !== 0) {
            if (this.props.state.simsettings['sim_type'] === 'promo' && this.state.value !== 4) {
                show_itemgroup_dropdown = true
            }
            else if (this.props.state.simsettings['sim_type'] !== 'promo') {
                show_itemgroup_dropdown = true
            }             
        }

        var show_item_dropdown = false
        if (this.props.level === 'Item') {
            if (this.props.state.simsettings['sim_type'] === 'promo' && this.state.value !== 4) {
                show_item_dropdown = true
            }
            else if (this.props.state.simsettings['sim_type'] !== 'promo') {
                show_item_dropdown = true
            }             
        }

        //console.log(this.props.state.competitor_graph_data_total)
        //console.log((this.props.level === 'Item' && this.props.state.competitor_graph_data_total !== 0))

        return (

            <div align='center' className="contentHolder simOutputGraphs">
                <h5>{this.props.sectiontitle}</h5>

                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }} className="">
                    {this.props.state.simsettings['sim_type'] === 'promo' ?
                        <Constant.NPDTabs
                            value={this.state.value}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                        >
                            <Constant.NPDTab label={this.props.state.unitsvar.toUpperCase() + " AND DOLLARS"}  {...a11yProps(0)} />
                            <Constant.NPDTab label={"BREAKDOWN OF " + this.props.state.unitsvar.toUpperCase()} {...a11yProps(1)} />
                            <Constant.NPDTab label="METRICS" {...a11yProps(2)} />
                            <Constant.NPDTab label="FINANCIALS" {...a11yProps(3)} />
                            <Constant.NPDTab label="PAYBACK RATIO" {...a11yProps(4)} />
                            {
                                (this.props.level === 'Item' && this.props.state.competitor_graph_data_total !== 0) ?
                                    <Constant.NPDTab label="COMPETITIVE LIFT" {...a11yProps(5)} />
                                    :
                                    <Constant.NPDTab disabled={true}/>
                            }
                        </Constant.NPDTabs>
                        :
                        <Constant.NPDTabs
                            value={this.state.value}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                        >
                            <Constant.NPDTab label={this.props.state.unitsvar.toUpperCase() + " AND DOLLARS"}  {...a11yProps(0)} />
                            <Constant.NPDTab label={"BREAKDOWN OF " + this.props.state.unitsvar.toUpperCase()} {...a11yProps(1)} />
                            <Constant.NPDTab label="METRICS" {...a11yProps(2)} />
                            <Constant.NPDTab label="FINANCIALS" {...a11yProps(3)} />
                            {
                                (this.props.level === 'Item' && this.props.state.competitor_graph_data_total !== 0) ?
                                    <Constant.NPDTab label="COMPETITIVE LIFT" {...a11yProps(4)} />
                                    :
                                    <Constant.NPDTab />
                            }
                        </Constant.NPDTabs>
                    }
                </Constant.NPDAppbar>

                {show_brand_dropdown ?
                    <div className='row'>
                        <div className='col-sm-3'/>                                
                            <div className='col-sm-6'>
                                <span className="formfieldtitle">Select a brand</span>
                                <Select
                                    options={this.props.state.brand_options}
                                    value={{ 'label': this.props.state.selected_brands, 'value': this.props.state.selected_brands }}
                                    onChange={(brand) => this.props.changeBrandSelected(brand)}
                                    styles={selectColourStyles}

                                />
                            </div>
                        <div className='col-sm-3' />
                    </div>
                    :
                    <div/>
                }

                {show_itemgroup_dropdown ?
                    <div className='row'>
                        <div className='col-sm-3' />
                        <div className='col-sm-6'>
                            <span className="formfieldtitle">Select an item group</span>
                            <Select
                                options={this.props.state.itemgroup_options}
                                value={{ 'label': this.props.state.selected_itemgroup, 'value': this.props.state.selected_itemgroup }}
                                onChange={(brand) => this.props.changeItemGroupSelected(brand)}
                                styles={selectColourStyles}

                            />
                        </div>
                        <div className='col-sm-3' />
                    </div>
                    :
                    <div />
                }

                {show_item_dropdown ?
                    <div className='row'>
                        <div className='col-sm-3' />
                        <div className='col-sm-6'>
                            <span className="formfieldtitle">Select an item</span>
                            <Select
                                options={this.props.state.item_options.concat(this.props.state.rollup_options || [])}
                                value={this.props.state.item_dict_lookup && { 'value': this.props.state.selected_item, 'label': (this.props.state.item_dict_lookup[this.props.state.selected_item] || this.props.state.simulation_results[this.props.state.selected_item]['brand'] + ' All Other') }}
                                onChange={(item) => { this.props.changeItemSelected(item); handleChangeTabItemView() }}
                                styles={selectColourStyles}

                            />
                        </div>
                        <div className='col-sm-3' />
                    </div>
                    :
                    <div />
                }

                <TabPanel value={this.state.value} index={0} style={{ width: '100%', height: '100%' }} >
                    <div className="col-sm-12" align='center'>
                        {this.props.firstGraphData.series1[0] ?
                            <div >
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={
                                        {
                                            chart: {
                                                type: 'column',
                                                alignTicks: false,
                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                            },
                                            title: {
                                                text: 'Simulated Data - Change in Base ' + this.props.state.unitsvar + ' and Base ' + this.props.state.currencytype + chart_title_info
                                            },
                                            series: [
                                                {
                                                    name: this.props.state.unitChangeType,
                                                    data: this.props.firstGraphData.series1,
                                                    yAxis: 0,
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            //console.log(this)
                                                            function nFormatter(num, digits) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;
                                                                for (i = 0; i < si.length; i++) {
                                                                    if (Math.abs(num) >= si[i].value) {
                                                                        return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                    }
                                                                    else if (Math.abs(num) < 1E3) {
                                                                        return (num).toFixed(digits) + " ";
                                                                    }
                                                                }
                                                                return num.toString();
                                                            }
                                                            return nFormatter(this.point.y, 1).toLocaleString()
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '1.25rem'
                                                        },
                                                        y: 0
                                                    }

                                                },

                                                {
                                                    name: this.props.state.dollarChangeType,
                                                    data: this.props.firstGraphData.series2,
                                                    yAxis: 2,
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            //console.log(this)
                                                            function nFormatter(num, digits) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;
                                                                for (i = 0; i < si.length; i++) {
                                                                    if (Math.abs(num) >= si[i].value) {
                                                                        return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                    }
                                                                    else if (Math.abs(num) < 1E3) {
                                                                        return (num).toFixed(digits) + " ";
                                                                    }
                                                                }
                                                                return num.toString();
                                                            }
                                                            let value = nFormatter(this.point.y, 1)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '1.25rem'
                                                        },
                                                        y: 0
                                                    }
                                                },

                                                {
                                                    name: this.props.state.unitPctChangeType,
                                                    data: this.props.firstGraphData.series3,
                                                    yAxis: 1,
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '1.25rem'
                                                        },
                                                        y: 0
                                                    },

                                                },

                                                {
                                                    name: this.props.state.dollarPctChangeType,
                                                    data: this.props.firstGraphData.series4,
                                                    yAxis: 1,
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '1.25rem'
                                                        },
                                                        y: 0
                                                    },

                                                },

                                            ],
                                            xAxis: {
                                                title: {
                                                    text: ''
                                                },
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                categories: this.props.firstGraphData.categories
                                            },
                                            yAxis: [
                                                {
                                                    title: {
                                                        text: this.props.state.unitsvar
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    labels: {
                                                        formatter: function () {
                                                            function nFormatter(num, digits) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;
                                                                for (i = 0; i < si.length; i++) {
                                                                    if (Math.abs(num) >= si[i].value) {
                                                                        return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                    }
                                                                }
                                                                return num.toString();
                                                            }

                                                            return nFormatter(this.value, 1).toLocaleString()

                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        text: 'Percent Change'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 0,
                                                    opposite: true,
                                                    labels: {
                                                        formatter: function () {
                                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        text: this.props.state.currencytype
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 0,
                                                    labels: {
                                                        formatter: function () {

                                                            function nFormatter(num, digits) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;
                                                                for (i = 0; i < si.length; i++) {
                                                                    if (Math.abs(num) >= si[i].value) {
                                                                        return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                    }
                                                                    else if (Math.abs(num) < 1E3) {
                                                                        return (num).toFixed(0) + " ";
                                                                    }
                                                                }
                                                                return num.toString();
                                                            }

                                                            let value = nFormatter(this.value, 1)
                                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                                            let symbol = value.substring(value.length - 1)
                                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                                        }

                                                    }
                                                },
                                            ],
                                            plotOptions: {
                                                series: {
                                                    //stacking: 'normal',

                                                }
                                            },

                                            tooltip: {
                                                formatter: function () {
                                                    //Percent
                                                    if (this.series.userOptions.yAxis === 1) {
                                                        return this.series.name + ' : ' + this.y.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })
                                                    }
                                                    //Currency
                                                    else if (this.series.userOptions.yAxis === 2) {
                                                        return this.series.name + ' : ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                    }
                                                    //Units
                                                    else {
                                                        return this.series.name + ' : ' + Math.round(this.y).toLocaleString()
                                                    }
                                                }
                                            }

                                        }}
                                    allowChartUpdate={true}
                                    callback={function (chart) {

                                        //console.log(chart.yAxis[0].dataMin)
                                        //console.log(chart.yAxis[1].dataMin)
                                        //console.log(chart.yAxis[2].dataMin)
                                        //console.log(chart.yAxis[0].dataMax)
                                        //console.log(chart.yAxis[1].dataMax)
                                        //console.log(chart.yAxis[2].dataMax)


                                        let axis_1_min = chart.yAxis[0].dataMin
                                        let axis_2_min = chart.yAxis[1].dataMin
                                        let axis_3_min = chart.yAxis[2].dataMin

                                        let axis_1_max = chart.yAxis[0].dataMax
                                        let axis_2_max = chart.yAxis[1].dataMax
                                        let axis_3_max = chart.yAxis[2].dataMax

                                        if (axis_1_min < 0 && axis_2_min < 0 && axis_3_min < 0 && axis_1_max < 0 && axis_2_max < 0 && axis_3_max < 0) {
                                            console.log('All Negative')
                                        }

                                        else if (axis_1_min >= 0 && axis_2_min >= 0 && axis_3_min >= 0 && axis_1_max >= 0 && axis_2_max >= 0 && axis_3_max >= 0) {
                                            //console.log('All Positive')
                                        }

                                        else {
                                            console.log('Mixed')
                                            //Set each Min/Max to the most extreme....
                                            let axis_1_min_abs = Math.abs(chart.yAxis[0].min)
                                            let axis_1_max_abs = Math.abs(chart.yAxis[0].max)
                                            let extreme_1 = Math.max(axis_1_min_abs, axis_1_max_abs)
                                            chart.yAxis[0].update({ min: extreme_1 * -1 });
                                            chart.yAxis[0].update({ max: extreme_1 * 1 });


                                            let axis_2_min_abs = Math.abs(chart.yAxis[1].min)
                                            let axis_2_max_abs = Math.abs(chart.yAxis[1].max)
                                            let extreme_2 = Math.max(axis_2_min_abs, axis_2_max_abs)
                                            chart.yAxis[1].update({ min: extreme_2 * -1 });
                                            chart.yAxis[1].update({ max: extreme_2 * 1 });


                                            let axis_3_min_abs = Math.abs(chart.yAxis[2].min)
                                            let axis_3_max_abs = Math.abs(chart.yAxis[2].max)
                                            let extreme_3 = Math.max(axis_3_min_abs, axis_3_max_abs)
                                            chart.yAxis[2].update({ min: extreme_3 * -1 });
                                            chart.yAxis[2].update({ max: extreme_3 * 1 });

                                            //chart.yAxis[0].update({ tickInterval: 1000 });
                                            //console.log(chart)
                                        }

                                    }}
                                />
                            </div>
                            :
                            <div/>
                        }
                        
                    </div>
                </TabPanel>

                <TabPanel value={this.state.value} index={1} style={{ width: '100%', height: '100%' }} >
                    <div className="col-sm-12" align='center'>
                        <div>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'waterfall',
                                            height: (9 / 16 * 100) + '%' //16:9 ratio
                                        },
                                        title: {
                                            text: 'Simulated Data - Breakdown in Change to ' + this.props.state.unitsvar + chart_title_info
                                        },
                                        series: [
                                            {
                                                name: this.props.state.unitsvar,
                                                data: this.props.secondGraphData.waterfall_data
                                            }],

                                        xAxis: {
                                            title: {
                                                text: ''
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1,
                                            categories: this.props.secondGraphData.categories
                                        },
                                        yAxis: {
                                            title: {
                                                text: this.props.state.unitsvar
                                            },
                                            gridLineColor: "#CCC",
                                            gridLineWidth: 1
                                        },
                                        plotOptions: {
                                            series: {
                                                //stacking: 'normal',
                                                dataLabels: {
                                                    enabled: true,
                                                    formatter: function () {
                                                        //console.log(this)
                                                        function nFormatter(num, digits) {
                                                            var si = [
                                                                { value: 1E18, symbol: "E" },
                                                                { value: 1E15, symbol: "P" },
                                                                { value: 1E12, symbol: "T" },
                                                                { value: 1E9, symbol: "B" },
                                                                { value: 1E6, symbol: "M" },
                                                                { value: 1E3, symbol: "k" }
                                                            ], i;
                                                            for (i = 0; i < si.length; i++) {
                                                                if (Math.abs(num) >= si[i].value) {
                                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                }
                                                                else if (Math.abs(num) < 1E3) {
                                                                    return (num).toFixed(digits) + " ";
                                                                }
                                                            }
                                                            return num.toString();
                                                        }
                                                        return nFormatter(this.point.y, 1).toLocaleString()
                                                    },
                                                    color: 'black',
                                                    borderRadius: 5,
                                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                    borderWidth: 1,
                                                    borderColor: '#d6d7d8',
                                                    padding: 0,
                                                    style: {
                                                        textOutline: 'none',
                                                        fontSize: '1.25rem'
                                                    },
                                                    y: 0
                                                }
                                            },
                                            waterfall: {
                                                borderColor: '#ffffff'
                                            }
                                        },
                                        tooltip: {
                                            formatter: function () {
                                                return this.x + ': ' + (Math.round(this.y)).toLocaleString() //+ ' ' + this.props.state.unitsvar
                                            }
                                        }

                                    }}
                            />

                        </div>
                    </div>
                </TabPanel>

                <TabPanel value={this.state.value} index={2} style={{ width: '100%', height: '100%' }} >
                    <div className="col-sm-12">
                        <p style={{ padding: '10px', lineHeight: '1.2' }}>
                            Simulated Data - Metrics
                            {chart_title_info.split('<br/>').map(text => { return <span key={'sdm_' + text}>{text}<br /></span> })}
                        </p>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_base }}>
                            <div className="col-sm-3">
                                <h5>Starting Base {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.starting_base_volume_total).toLocaleString()}
                            </div>
                            <div className="col-sm-3">
                                <h5>Change to Base {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.base_volume_change_total).toLocaleString()}
                            </div>
                            <div className="col-sm-3">
                                <h5>Simulated Base {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.starting_base_volume_total + this.props.metricsData.base_volume_change_total).toLocaleString()}
                            </div>
                            <div className="col-sm-3">
                                <h5>Base {this.props.state.unitsvar} Percent Change</h5>
                                {(this.props.metricsData.base_volume_change_total / this.props.metricsData.starting_base_volume_total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                            </div>

                            <div className="col-sm-3">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_base }}>
                            <div className="col-sm-3">
                                <h5>{'Starting Base ' + this.props.state.currencytype}</h5>
                                {Math.round(this.props.metricsData.starting_base_dollars_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-3">
                                <h5>{'Change to Base ' + this.props.state.currencytype}</h5>
                                {(Math.round(this.props.metricsData.diff_base_dollars_total)).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-3">
                                <h5>{'Simulated Base ' + this.props.state.currencytype}</h5>
                                {(Math.round(this.props.metricsData.diff_base_dollars_total + this.props.metricsData.starting_base_dollars_total)).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-3">
                                <h5>{'Base ' + this.props.state.currencytype + ' Percent Change'}</h5>
                                {((this.props.metricsData.diff_base_dollars_total) / this.props.metricsData.starting_base_dollars_total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                            </div>

                            <div className="col-sm-3">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-2">
                                <h5>Base {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.starting_base_volume_total).toLocaleString()}
                            </div>
                            <div className="col-sm-2">
                                <h5>Incremental {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.incremental_volume_total).toLocaleString()}
                            </div>
                            <div className="col-sm-2">
                                <h5>Promoted {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.promo_volume_total).toLocaleString()}
                            </div>
                            <div className="col-sm-2">
                                <h5>Non Promoted {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.nonpromo_volume_total).toLocaleString()}
                            </div>

                            <div className="col-sm-2">
                                <h5>Simulated Total {this.props.state.unitsvar}</h5>
                                {Math.round(this.props.metricsData.starting_base_volume_total + this.props.metricsData.incremental_volume_total).toLocaleString()}
                            </div>
                            <div className="col-sm-2">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-2">
                                <h5>{'Base ' + this.props.state.currencytype}</h5>
                                {Math.round(this.props.metricsData.starting_base_dollars_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>{'Incremental ' + this.props.state.currencytype}</h5>
                                {Math.round(this.props.metricsData.incremental_dollars_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>

                            <div className="col-sm-2">
                                <h5>{'Promoted ' + this.props.state.currencytype}</h5>
                                {Math.round(this.props.metricsData.promodollar_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>{'Non-Promoted ' + this.props.state.currencytype}</h5>
                                {Math.round((this.props.metricsData.nonpromodollar_total)).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>{'Discount ' + this.props.state.currencytype}</h5>
                                {Math.round(this.props.metricsData.discountdollar_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>

                            <div className="col-sm-2">
                                <h5>{'Simulated Total ' + this.props.state.currencytype}</h5>
                                {Math.round(this.props.metricsData.promodollar_total + this.props.metricsData.nonpromodollar_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>

                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-2">
                                <h5>Un-Subsidized Base Units</h5>
                                {Math.round(this.props.metricsData.unsubsidized_base_volume_total).toLocaleString()}
                            </div>
                            <div className="col-sm-2">
                                <h5>Subsidized Base Units</h5>
                                {Math.round(this.props.metricsData.subsidized_base_volume_total).toLocaleString()}
                            </div>
                            <div className="col-sm-2">
                                <h5>{'Un-Subsidized Base ' + this.props.state.currencytype}</h5>
                                {Math.round(this.props.metricsData.unsubsidized_base_dollars_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>{'Subsidized Base ' + this.props.state.currencytype}</h5>
                                {Math.round((this.props.metricsData.subsidized_base_dollars_total)).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5></h5>
                                { }
                            </div>
                            <div className="col-sm-2">
                                <h5></h5>
                                { }
                            </div>

                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-2">
                                <h5>Lift to Total</h5>
                                {(this.props.metricsData.incremental_volume_total / this.props.metricsData.starting_base_volume_total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>Lift where Promoted</h5>
                                {((this.props.metricsData.incremental_volume_total) / (this.props.metricsData.starting_base_volume_total)).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>Efficiency</h5>
                                {((this.props.metricsData.incremental_volume_total) / (this.props.metricsData.promo_volume_total)).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>Payback Ratio</h5>
                                {(this.props.metricsData.incremental_dollars_total / this.props.metricsData.discountdollar_total).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </div>
                            <div className="col-sm-2">
                                <h5>% Subsidized Volume</h5>
                                {((this.props.metricsData.promo_volume_total - this.props.metricsData.incremental_volume_total) / (this.props.metricsData.promo_volume_total)).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                            </div>
                            <div className="col-sm-2">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                    </div>
                </TabPanel>

                <TabPanel value={this.state.value} index={3} style={{ width: '100%', height: '100%' }} >
                    <div className="col-sm-12" style={{ minWidth: '900px' }}>
                        <p style={{ padding: '10px', lineHeight: '1.2' }}>
                            Simulated Data - Financial Metrics
                            {chart_title_info.split('<br/>').map(text => { return <span key={'sdfm_' + text}>{text}<br /></span> })}
                        </p>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_base }}>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Simulated Total Dollars</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='basegrossrevenue_all_base'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.gross_revenue_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Profit</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='baseincprofit_all_base'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.incremental_profit_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <h5></h5>
                                { }
                            </div>
                            <div className="col-sm-4">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Simulated Total Dollars</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='grossrevenue_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.gross_revenue_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Net Revenue</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='netrevenue_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.net_revenue_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Cost per Promoted Unit</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='costperpromounit'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.ttl_cost_per_addl_unit_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Total Spend</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='totalspend_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.total_spend_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Incremental Gross Revenue</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='incgrossrev_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.incremental_gross_revenue_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Incremental Net Revenue</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='incnetrev_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.incremental_net_revenue_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Incremental Profit</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='incprofit_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.incremental_profit_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Sales ROI</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='salesroi_all'
                                    />
                                </div>
                                <div className="row">
                                    {(this.props.financialData.incremental_net_revenue_total / this.props.financialData.total_spend_total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>{'Retailer Promotion Margin ' + this.props.state.currencysymbol}</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='retailpromomargindol_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.retailer_promo_margin_dollars_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                        <div className="row" style={{ padding: '25px', display: this.props.state.display_promo }}>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Retailer Promotion Margin %</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='retailpromomarginpct_all'
                                    />
                                </div>
                                <div className="row">
                                    {((this.props.financialData.retiler_promo_margin_dollars_total / this.props.financialData.gross_revenue_total) || 0).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Retailer Everyday Margin (%)</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='retailedaymargin_all'
                                    />
                                </div>
                                <div className="row">
                                    {(this.props.financialData.retailer_eday_margin_dollars_total / this.props.metricsData.starting_base_dollars_total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <h5>Retailer Promotion Profit</h5>
                                    <InfoRoundedIcon
                                        style={{ fontSize: '1rem' }}
                                        onMouseEnter={showDefinition}
                                        onMouseLeave={hideDefinition}
                                        id='retailpromoprofit_all'
                                    />
                                </div>
                                <div className="row">
                                    {Math.round(this.props.financialData.retailer_promotion_profit_total).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <h5></h5>
                                { }
                            </div>
                            <div className="col-sm-4">
                                <h5></h5>
                                { }
                            </div>
                        </div>

                    </div>
                </TabPanel>


                {this.props.state.simsettings['sim_type'] === 'promo' ?
                    <Fragment>
                        <TabPanel value={this.state.value} index={4} style={{ width: '100%', height: '100%' }} >
                            <div className="col-sm-12" align='center'>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={
                                        {
                                            chart: {
                                                type: 'column',
                                                alignTicks: false,
                                                height: (9 / 16 * 100) + '%' //16:9 ratio
                                            },
                                            title: {
                                                text: this.props.thirdGraphData.title + chart_title_info
                                            },
                                            series: [
                                                {
                                                    name: 'Payback Ratio',
                                                    data: this.props.thirdGraphData.data,
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            //console.log(this)
                                                            function nFormatter(num, digits) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;
                                                                for (i = 0; i < si.length; i++) {
                                                                    if (Math.abs(num) >= si[i].value) {
                                                                        return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                    }
                                                                    else if (Math.abs(num) < 1E3) {
                                                                        return (num).toFixed(digits) + " ";
                                                                    }
                                                                }
                                                                return num.toString();
                                                            }
                                                            return nFormatter(this.point.y, 1).toLocaleString()
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '1.25rem'
                                                        },
                                                        y: 0
                                                    }
                                                },

                                            ],
                                            xAxis: {
                                                title: {
                                                    text: ''
                                                },
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                categories: this.props.thirdGraphData.categories
                                            },
                                            yAxis: [
                                                {
                                                    title: {
                                                        text: 'Payback Ratio'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    labels: {
                                                        formatter: function () {
                                                            function nFormatter(num, digits) {
                                                                var si = [
                                                                    { value: 1E18, symbol: "E" },
                                                                    { value: 1E15, symbol: "P" },
                                                                    { value: 1E12, symbol: "T" },
                                                                    { value: 1E9, symbol: "B" },
                                                                    { value: 1E6, symbol: "M" },
                                                                    { value: 1E3, symbol: "k" }
                                                                ], i;
                                                                for (i = 0; i < si.length; i++) {
                                                                    if (Math.abs(num) >= si[i].value) {
                                                                        return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                    }
                                                                }
                                                                return num.toString();
                                                            }

                                                            return nFormatter(this.value, 1).toLocaleString()

                                                        }
                                                    }

                                                },
                                            ],
                                            plotOptions: {
                                                series: {
                                                    dataLabels: {
                                                        enabled: true,
                                                    }
                                                }
                                            },
                                            tooltip: {
                                                formatter: function () {
                                                    return this.series.name + ' : ' + this.y.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                }

                                            }

                                        }}
                                    allowChartUpdate={true}
                                />
                            </div>
                        </TabPanel>

                        {this.props.level === 'Item' ?
                            <TabPanel value={this.state.value} index={5} style={{ width: '100%', height: '100%' }} >
                                <div className="col-sm-12" align='center'>
                                    <div>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                {
                                                    chart: {
                                                        type: 'column',
                                                        height: (9 / 16 * 100) + '%' //16:9 ratio
                                                    },
                                                    title: {
                                                        text: 'Change in ' + this.props.state.unitsvar + ' for Competitors to ' + (this.props.state.item_dict_lookup || {})[(this.props.state.selected_item || "")] + chart_title_info
                                                    },
                                                    series: [
                                                        {
                                                            name: this.props.state.unitChangeType,
                                                            data: this.props.state.competitor_graph_data_total
                                                        },

                                                        {
                                                            name: this.props.state.unitPctChangeType,
                                                            data: this.props.state.competitor_graph_data_total_pct,
                                                            yAxis: 1,
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter: function () {
                                                                    return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 0,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '1.25rem'
                                                                },
                                                                y: 0
                                                            },
                                                        },


                                                    ],
                                                    xAxis: {
                                                        title: {
                                                            text: 'Product'
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        categories: this.props.state.competitor_graph_data_items && this.props.state.competitor_graph_data_items.map(itemnum => this.props.state.item_dict_lookup[itemnum])
                                                    },
                                                    yAxis: [
                                                        {
                                                            title: {
                                                                text: this.props.state.unitsvar
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            labels: {
                                                                formatter: function () {
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (Math.abs(num) >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                        }
                                                                        return num.toString();
                                                                    }

                                                                    return nFormatter(this.value, 1).toLocaleString()

                                                                }
                                                            }

                                                        },
                                                        {
                                                            title: {
                                                                text: 'Percent Change'
                                                            },
                                                            gridLineColor: "#CCC",
                                                            gridLineWidth: 1,
                                                            opposite: true,
                                                            labels: {
                                                                formatter: function () {
                                                                    return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                                }
                                                            }
                                                        }
                                                    ],
                                                    plotOptions: {
                                                        series: {
                                                            //stacking: 'normal',
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter: function () {
                                                                    //console.log(this)
                                                                    function nFormatter(num, digits) {
                                                                        var si = [
                                                                            { value: 1E18, symbol: "E" },
                                                                            { value: 1E15, symbol: "P" },
                                                                            { value: 1E12, symbol: "T" },
                                                                            { value: 1E9, symbol: "B" },
                                                                            { value: 1E6, symbol: "M" },
                                                                            { value: 1E3, symbol: "k" }
                                                                        ], i;
                                                                        for (i = 0; i < si.length; i++) {
                                                                            if (Math.abs(num) >= si[i].value) {
                                                                                return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                            }
                                                                            else if (Math.abs(num) < 1E3) {
                                                                                return (num).toFixed(digits) + " ";
                                                                            }
                                                                        }
                                                                        return num.toString();
                                                                    }
                                                                    return nFormatter(this.point.y, 1).toLocaleString()
                                                                },
                                                                color: 'black',
                                                                borderRadius: 5,
                                                                backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                                borderWidth: 1,
                                                                borderColor: '#d6d7d8',
                                                                padding: 0,
                                                                style: {
                                                                    textOutline: 'none',
                                                                    fontSize: '1.25rem'
                                                                },
                                                                y: 0
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        formatter: function () {
                                                            //Percent
                                                            if (this.series.userOptions.yAxis === 1) {
                                                                return this.series.name + ' : ' + this.y.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })
                                                            }
                                                            //Currency
                                                            else if (this.series.userOptions.yAxis === 2) {
                                                                return this.series.name + ' : ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                            }
                                                            //Units
                                                            else {
                                                                return this.series.name + ' : ' + Math.round(this.y).toLocaleString()
                                                            }
                                                        }
                                                    }

                                                }}
                                            callback={function (chart) {

                                                let axis_1_min = chart.yAxis[0].dataMin
                                                let axis_2_min = chart.yAxis[1].dataMin

                                                let axis_1_max = chart.yAxis[0].dataMax
                                                let axis_2_max = chart.yAxis[1].dataMax


                                                if (axis_1_min < 0 && axis_2_min < 0  && axis_1_max < 0 && axis_2_max < 0 ) {
                                                    console.log('All Negative')
                                                }

                                                else if (axis_1_min >= 0 && axis_2_min >= 0 && axis_1_max >= 0 && axis_2_max >= 0) {
                                                    console.log('All Positive')
                                                }

                                                else {
                                                    console.log('Mixed')
                                                    //Set each Min/Max to the most extreme....
                                                    let axis_1_min_abs = Math.abs(chart.yAxis[0].min)
                                                    let axis_1_max_abs = Math.abs(chart.yAxis[0].max)
                                                    let extreme_1 = Math.max(axis_1_min_abs, axis_1_max_abs)
                                                    chart.yAxis[0].update({ min: extreme_1 * -1 });
                                                    chart.yAxis[0].update({ max: extreme_1 * 1 });


                                                    let axis_2_min_abs = Math.abs(chart.yAxis[1].min)
                                                    let axis_2_max_abs = Math.abs(chart.yAxis[1].max)
                                                    let extreme_2 = Math.max(axis_2_min_abs, axis_2_max_abs)
                                                    chart.yAxis[1].update({ min: extreme_2 * -1 });
                                                    chart.yAxis[1].update({ max: extreme_2 * 1 });

                                                    //chart.yAxis[0].update({ tickInterval: 1000 });
                                                    //console.log(chart)
                                                }

                                            }}
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            :
                            <Fragment />
                        }
                    </Fragment>
                    :
                    this.props.level === 'Item' ?
                        <TabPanel value={this.state.value} index={4} style={{ width: '100%', height: '100%' }} >
                            <div className="col-sm-12" align='center'>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            {
                                                chart: {
                                                    type: 'column',
                                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                                },
                                                title: {
                                                    text: 'Change in ' + this.props.state.unitsvar + ' for Competitors to ' + (this.props.state.item_dict_lookup || {})[(this.props.state.selected_item || "")] + chart_title_info
                                                },
                                                series: [
                                                    {
                                                        name: this.props.state.unitChangeType,
                                                        data: this.props.state.competitor_graph_data_total
                                                    },

                                                    {
                                                        name: this.props.state.unitPctChangeType,
                                                        data: this.props.state.competitor_graph_data_total_pct,
                                                        yAxis: 1,
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter: function () {
                                                                return this.point.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 0,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '1.25rem'
                                                            },
                                                            y: 0
                                                        },
                                                    },


                                                ],
                                                xAxis: {
                                                    title: {
                                                        text: 'Product'
                                                    },
                                                    gridLineColor: "#CCC",
                                                    gridLineWidth: 1,
                                                    categories: this.props.state.competitor_graph_data_items && this.props.state.competitor_graph_data_items.map(itemnum => this.props.state.item_dict_lookup[itemnum])
                                                },
                                                yAxis: [
                                                    {
                                                        title: {
                                                            text: this.props.state.unitsvar
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        labels: {
                                                            formatter: function () {
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }

                                                                return nFormatter(this.value, 1).toLocaleString()

                                                            }
                                                        }

                                                    },
                                                    {
                                                        title: {
                                                            text: 'Percent Change'
                                                        },
                                                        gridLineColor: "#CCC",
                                                        gridLineWidth: 1,
                                                        opposite: true,
                                                        labels: {
                                                            formatter: function () {
                                                                return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                            }
                                                        }
                                                    }
                                                ],
                                                plotOptions: {
                                                    series: {
                                                        //stacking: 'normal',
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter: function () {
                                                                //console.log(this)
                                                                function nFormatter(num, digits) {
                                                                    var si = [
                                                                        { value: 1E18, symbol: "E" },
                                                                        { value: 1E15, symbol: "P" },
                                                                        { value: 1E12, symbol: "T" },
                                                                        { value: 1E9, symbol: "B" },
                                                                        { value: 1E6, symbol: "M" },
                                                                        { value: 1E3, symbol: "k" }
                                                                    ], i;
                                                                    for (i = 0; i < si.length; i++) {
                                                                        if (Math.abs(num) >= si[i].value) {
                                                                            return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                                        }
                                                                        else if (Math.abs(num) < 1E3) {
                                                                            return (num).toFixed(digits) + " ";
                                                                        }
                                                                    }
                                                                    return num.toString();
                                                                }
                                                                return nFormatter(this.point.y, 1).toLocaleString()
                                                            },
                                                            color: 'black',
                                                            borderRadius: 5,
                                                            backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                            borderWidth: 1,
                                                            borderColor: '#d6d7d8',
                                                            padding: 0,
                                                            style: {
                                                                textOutline: 'none',
                                                                fontSize: '1.25rem'
                                                            },
                                                            y: 0
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    formatter: function () {
                                                        //Percent
                                                        if (this.series.userOptions.yAxis === 1) {
                                                            return this.series.name + ' : ' + this.y.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })
                                                        }
                                                        //Currency
                                                        else if (this.series.userOptions.yAxis === 2) {
                                                            return this.series.name + ' : ' + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 })
                                                        }
                                                        //Units
                                                        else {
                                                            return this.series.name + ' : ' + Math.round(this.y).toLocaleString()
                                                        }
                                                    }
                                                }

                                            }}
                                        callback={function (chart) {

                                            let axis_1_min = chart.yAxis[0].dataMin
                                            let axis_2_min = chart.yAxis[1].dataMin

                                            let axis_1_max = chart.yAxis[0].dataMax
                                            let axis_2_max = chart.yAxis[1].dataMax


                                            if (axis_1_min < 0 && axis_2_min < 0 && axis_1_max < 0 && axis_2_max < 0) {
                                                console.log('All Negative')
                                            }

                                            else if (axis_1_min >= 0 && axis_2_min >= 0 && axis_1_max >= 0 && axis_2_max >= 0) {
                                                console.log('All Positive')
                                            }

                                            else {
                                                console.log('Mixed')
                                                //Set each Min/Max to the most extreme....
                                                let axis_1_min_abs = Math.abs(chart.yAxis[0].min)
                                                let axis_1_max_abs = Math.abs(chart.yAxis[0].max)
                                                let extreme_1 = Math.max(axis_1_min_abs, axis_1_max_abs)
                                                chart.yAxis[0].update({ min: extreme_1 * -1 });
                                                chart.yAxis[0].update({ max: extreme_1 * 1 });


                                                let axis_2_min_abs = Math.abs(chart.yAxis[1].min)
                                                let axis_2_max_abs = Math.abs(chart.yAxis[1].max)
                                                let extreme_2 = Math.max(axis_2_min_abs, axis_2_max_abs)
                                                chart.yAxis[1].update({ min: extreme_2 * -1 });
                                                chart.yAxis[1].update({ max: extreme_2 * 1 });

                                                //chart.yAxis[0].update({ tickInterval: 1000 });
                                                //console.log(chart)
                                            }

                                        }}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        :
                        <Fragment />
                }
                    

                <Popover
                    style={{ pointerEvents: 'none' }}
                    open={this.state.openDefinition}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    anchorEl={this.state.anchorElDefinition}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ padding: '10px', margin: '10px', maxWidth: '500px' }}>
                        {this.state.definitiontodisplay}
                    </div>
                </Popover>

            </div>
        )
    }
}