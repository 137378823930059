import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../../constants'

export class StackedBar extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {        
        var unitsvar = this.props.unitsvar
        var currencycode = this.props.currencycode || 'USD'
        var stackingType = this.props.stackingType || 'normal'

        var showdollars
        if (unitsvar === 'Units' || unitsvar === 'Quarts') {
            showdollars = false
        }
        else {
            showdollars = true
        }

        var yaxistext
        if (stackingType === 'percent') {
            yaxistext = 'Percent'
        }
        else {
            yaxistext = this.props.unitsvar
        }

        return (
            <Fragment>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        chart: {
                            type: 'column',
                            height: (9 / 16 * (250 * (this.props.height || 1))) + '%',                                
                            zoomType: 'x'
                        },
                        metadata: this.props.metadata || {},
                        caption: {
                            text: this.props.attr_caption_text
                        },
                        title: {
                            text:  this.props.ChartTitle + this.props.unitsvar + this.props.chart_title_info
                        },
                        series: this.props.seriesList,
                        xAxis: {
                            title: {
                                text: ''
                            },
                            categories: this.props.categories,
                            min: 0
                        },
                        yAxis: {
                            title: {
                                text: yaxistext
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            reversedStacks: false,
                            labels: {
                                formatter: function () {
                                    function nFormatter(num, digits, axisMax) {
                                        var si = [
                                            { value: 1E18, symbol: "E" },
                                            { value: 1E15, symbol: "P" },
                                            { value: 1E12, symbol: "T" },
                                            { value: 1E9, symbol: "B" },
                                            { value: 1E6, symbol: "M" },
                                            { value: 1E3, symbol: "k" }
                                        ], i;

                                        let symbolForAxis = " "
                                        let valueForAxis = 1

                                        for (i = 0; i < si.length; i++) {
                                            if (axisMax >= si[i].value) {
                                                symbolForAxis = si[i].symbol
                                                valueForAxis = si[i].value
                                                break;
                                            }
                                        }

                                        return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                    }
                                    if (stackingType === 'percent') {
                                        return ((this.value / 100) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                    else {
                                        if (showdollars) {
                                            console.log(this.axis.max)
                                            let value = nFormatter(this.value, 1, this.axis.max)
                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                            let symbol = value.substring(value.length - 1)
                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                        }
                                        else {
                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                        }
                                    }

                                }
                            }
                        },
                        plotOptions: {
                            column: {
                                stacking: stackingType,
                                dataLabels: {
                                    enabled: true,                                        
                                    formatter: function () {                                            
                                        function nFormatter(num, digits) {
                                            var si = [
                                                { value: 1E18, symbol: "E" },
                                                { value: 1E15, symbol: "P" },
                                                { value: 1E12, symbol: "T" },
                                                { value: 1E9, symbol: "B" },
                                                { value: 1E6, symbol: "M" },
                                                { value: 1E3, symbol: "k" }
                                            ], i;
                                            for (i = 0; i < si.length; i++) {
                                                if (num >= si[i].value) {
                                                    return (num / si[i].value).toFixed(digits) + si[i].symbol;
                                                }
                                                else if (num < 1000) {
                                                    return num.toFixed(0)
                                                }
                                            }
                                            return num.toString();
                                        }
                                        if (showdollars) {                                                
                                            let value = nFormatter(this.point.y, 1)
                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || ""
                                            let symbol = value.substring(value.length - 1)
                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                        }
                                        else {                                                
                                            return nFormatter(this.point.y, 1).toLocaleString()
                                        }
                                    },
                                    color: 'black',
                                    borderRadius: 5,
                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                    borderWidth: 1,
                                    borderColor: '#d6d7d8',
                                    padding: 0,
                                    style: {
                                        textOutline: 'none',
                                        fontSize: '20px'
                                    },
                                    y: -5
                                }
                            },
                        },
                        tooltip: {
                            formatter: function () {
                                if (showdollars) {
                                    return this.x
                                        + '<br/> <span style="color:'
                                        + this.series.color
                                        + '"> '
                                        + this.series.name
                                        + '</span>: <b>'
                                        + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0 })
                                        + '</b> ('
                                        + (this.y / this.total).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        + ') <br/>'
                                }
                                else {
                                    return this.x
                                        + '<br/> <span style="color:'
                                        + this.series.color
                                        + '"> '
                                        + this.series.name
                                        + '</span>: <b>'
                                        + Math.round(this.y).toLocaleString()
                                        + ' '
                                        + unitsvar
                                        + '</b> ('
                                        + (this.y / this.total).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        + ') <br/>'
                                }
                            },
                        },
                        exporting: {
                            showTable: this.props.showDataTable,
                            tableCaption: false
                        },
                    }}
                />
                <div style={{ display: this.props.showDataTableStyle }}>
                    <Constant.NPDTable>
                        <Constant.NPDTableBody>
                            <Constant.NPDTableRow key="header_g8">
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                    {this.props.level}
                                </Constant.NPDTableCell>
                                {this.props.seriesList.map(series => {
                                    return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={'header_' + series.name}>
                                        {series.name + ' ' + unitsvar}
                                    </Constant.NPDTableCell>
                                })}
                            </Constant.NPDTableRow>
                            {this.props.categories.map((category, idx) => {
                                return <Constant.NPDTableRow key={"datarow_" + category + "_g8"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                        {category}
                                    </Constant.NPDTableCell>
                                    {this.props.seriesList.map(series => {
                                        if (showdollars) {
                                            return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={ series.name }>
                                                {Math.round(series.data[idx]).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0 })}
                                            </Constant.NPDTableCell>
                                        }
                                        else {
                                            return <Constant.NPDTableCell style={{ textAlign: 'center' }} key={series.name}>
                                                {Math.round(series.data[idx]).toLocaleString()}
                                            </Constant.NPDTableCell>
                                        }
                                    })}
                                </Constant.NPDTableRow>
                            })}                            
                        </Constant.NPDTableBody>
                    </Constant.NPDTable>
                </div>
            </Fragment>
        )
    }
}