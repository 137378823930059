import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as Constant from '../../../constants';

export class MarketShareSwitch extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        var label1 = this.props.label1 || 'Absolute Change'
        var label2 = this.props.label2 || 'Market Share'

        return (
            <Fragment>
                <ThemeProvider theme={Constant.muiTheme}>
                    <span style={{ lineHeight: '5' }}>{label1}</span>
                    <Switch
                        checked={this.props.state.use_market_share}
                        onChange={this.props.handleChange_marketshare}
                        color="secondary"
                    />                    
                    <span style={{ lineHeight: '5' }}>{label2} </span>
                </ThemeProvider>
            </Fragment>
        )
    }
}