import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class MultiGeoSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <div align='left' className="col-sm-6" >
                    <span className="formfieldtitle">Select Channels</span>
                    <Select
                        options={this.props.state.all_geos.map(geo => { return { 'value': geo, 'label': geo } })}
                        isMulti
                        placeholder={'Select Channels'}
                        onChange={(item, position) => this.props.changeSelectedChannelMulti(item, 0)}
                        value={this.props.state.selectedChannels.map(geo => { return { 'label': geo, 'value': geo } })}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}
                    />
                </div>
                <div align='left' className="col-sm-6" >
                    <span className="formfieldtitle">If More than 10 Channels are selected in the filters, this will default to the first 10 Channels by sales volume.</span>
                </div>
            </Fragment>
        )
    }
}