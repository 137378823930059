import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import * as Constant from '../../../constants';

export class PriceBandDefIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Constant.HTMLTooltip title={
                <Fragment>
                    <p> Price Band Definitions</p>
                    <br />
                    <ul>
                        {this.props.state.priceBandOptions.map(priceband => {
                            let pbname = priceband.label
                            let pbstart = priceband.value[0]
                            let pbend = priceband.value[1]
                            if ((String(pbstart).slice(-3) !== '.01') && pbstart > 0) {
                                pbstart += 1
                            }
                            var pb_def_string
                            if (!pbend) {
                                pb_def_string = pbname + ': ' + this.props.state.currencysymbol + pbstart + ' + '
                            }
                            else if (pbstart === 0) {
                                pb_def_string = pbname + ': Under ' + this.props.state.currencysymbol + pbend
                            }
                            else {
                                pb_def_string = pbname + ': ' + this.props.state.currencysymbol + pbstart + ' - ' + this.props.state.currencysymbol + pbend
                            }
                            return <li key={pb_def_string}><Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{pb_def_string}</Typography></li>
                        })}
                    </ul>
                </Fragment>
            } placement="right">
                <span>
                    <InfoRoundedIcon
                        style={{ fontSize: '1.875rem', color: '#7d32ba' }}
                    />
                    Hover Here for Price Band Definitions
                </span>
            </Constant.HTMLTooltip>
        )
    }
}