//React
import React, { Component, Fragment } from 'react';

//Material UI Components
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//HighCharts
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_export_data from 'highcharts/modules/export-data'
import HighchartOptions from "../highcharts_options";

//Application Components
import { Layout } from '../Layout';
import { Loaders } from './StaticReportComponents/Loaders';
import { Snackbars } from './StaticReportComponents/Snackbars';
import { Dialogs } from './StaticReportComponents/Dialogs';
import { ReDirectDialog } from './StaticReportComponents/ReDirectDialog';
import { Modals } from './StaticReportComponents/Modals';
import { ReportHeader } from './StaticReportComponents/ReportHeader';
import { CompetitiveAnalysisSection } from './StaticReportComponents/CompetitiveAnalysisSection';
import { FlexibleViewSection } from './StaticReportComponents/FlexibleViewSection';
import { FlexibleWeeklyTableView } from './ReportOutputComponents/FlexibleReportViews/FlexibleWeeklyTableView';
import { FlexibleSegmentTableView } from './ReportOutputComponents/FlexibleReportViews/FlexibleSegmentTableView';
import { FlexibleBrandTableView } from './ReportOutputComponents/FlexibleReportViews/FlexibleBrandTableView';
import { ShowTableIcon } from './UIElements/IconButtons/ShowTableIcon'

//Constants
import * as Constant from '../constants'
import { initialState } from './InitialState/initialState'

//Graph Components

//Base And Incremental Aggregate Views
import { BaseIncrementalPromoNonPromoAggregate } from './ReportOutputComponents/AggregateViews/NewViews/BaseInc_or_PromoNonpromo_Aggregate'
import { LiftEffDiscAggregate_New } from './ReportOutputComponents/AggregateViews/NewViews/Lift_Eff_Disc_Aggregate'
import { DiscDollar_PBR } from './ReportOutputComponents/AggregateViews/NewViews/DiscDollar_PBR'
import { Fair_Share } from './ReportOutputComponents/AggregateViews/NewViews/Fair_Share'
import { LiftEffPBRByDiscBandAggregate } from './ReportOutputComponents/AggregateViews/LiftEffPBRByDiscBandAggregate'

//Base And Incremental Trended Views
import { BaseIncrementalTrended } from './ReportOutputComponents/TrendedViews/BaseIncrementalTrended'
import { LiftAndEfficiencyTrended } from './ReportOutputComponents/TrendedViews/LiftAndEfficiencyTrended'
import { DiscDollarsAndPBRTrended } from './ReportOutputComponents/TrendedViews/DiscDollarsAndPBRTrended'
import { BaseVelocity } from './ReportOutputComponents/TrendedViews/BaseVelocity'
import { ShareTrended } from './ReportOutputComponents/TrendedViews/ShareTrended'
import { AreaChart } from './ReportOutputComponents/TrendedViews/AreaChart'

//Price and Discount Views
import { AvgPromotedDiscountTrended } from './ReportOutputComponents/PriceAndDiscount/AvgPromotedDiscountTrended'
import { AvgPromotedDiscountWithDollarShare } from './ReportOutputComponents/PriceAndDiscount/AvgPromotedDiscountWithIndexedDollarShare'
import { DepthOfDiscount } from './ReportOutputComponents/PriceAndDiscount/DepthOfDiscount'
import { PromotedNonPromotedPriceByWeek } from './ReportOutputComponents/PriceAndDiscount/PromotedNonPromotedPriceByWeek'
import { ASPBasePriceByWeek } from './ReportOutputComponents/PriceAndDiscount/ASPBasePriceByWeek'
import { PromotedUnitsDollarsByWeek } from './ReportOutputComponents/PriceAndDiscount/PromotedUnitsDollarsWeekly'

//YOY Views
import { DiscountRangeYOY } from './ReportOutputComponents/YOY/DiscountRangeYOY'
import { VolumeYOY } from './ReportOutputComponents/YOY/VolumeYOY'
import { ItemYOYInformation } from './ReportOutputComponents/YOY/ItemYOYInformation'
import { MetricChangesWithMktShare } from './ReportOutputComponents/YOY/MetricChangesWithMktShare'

//Scorecard Views
//import { ScoreCardBaseInc } from './ReportOutputComponents/ScoreCard/ScoreCardBaseInc'
import ScoreCardBaseInc from './ReportOutputComponents/ScoreCard/ScoreCardBaseIncFunctional';
import { ScoreCardPromoNonPromo } from './ReportOutputComponents/ScoreCard/ScoreCardPromoNonPromo'
import { ScoreCardSalesMetrics } from './ReportOutputComponents/ScoreCard/ScoreCardSalesMetrics'
import { ScoreCardPricingMetrics } from './ReportOutputComponents/ScoreCard/ScoreCardPricingMetrics'
import { ScoreCardPromotionMetrics } from './ReportOutputComponents/ScoreCard/ScoreCardPromotionMetrics'

//ALRvsROM Views
import { ALRvsROMGraph } from './ReportOutputComponents/ALRvsROM/ALRvsROM'
import { AvgDiscPercentALRvsROM } from './ReportOutputComponents/ALRvsROM/AvgDiscPercentALRvsROM'
import { ASPWeeklyALRvsROM } from './ReportOutputComponents/ALRvsROM/ASPWeeklyALRvsROM'
import { DepthOfDiscountByALR } from './ReportOutputComponents/ALRvsROM/DepthOfDiscountByALR'
import { ItemASPDistRetailerROM } from './ReportOutputComponents/ALRvsROM/ItemASPDistRetailerROM'
import { PromoSupportAndShareImpactGraph } from './ReportOutputComponents/ALRvsROM/PromoSupportAndShare'

//ALRvsROM Base Incremental
import { ALRvsROM_BaseIncremental } from './ReportOutputComponents/ALRvsROM_BaseIncremental/ALRvsROM_BaseIncremental'
import { ALRvsROM_LiftEfficiency } from './ReportOutputComponents/ALRvsROM_BaseIncremental/ALRvsROM_LiftEfficiency'
import { ALRvsROM_MetricChanges } from './ReportOutputComponents/ALRvsROM_BaseIncremental/ALRvsROM_MetricChanges'
import { ALRvsROM_MetricComparisons } from './ReportOutputComponents/ALRvsROM_BaseIncremental/ALRvsROM_MetricComparisons'
import { ALRvsROM_TopBottomWeeks } from './ReportOutputComponents/ALRvsROM_BaseIncremental/ALRvsROM_TopBottomWeeks'

//Flex View
import { FlexibleView } from './ReportOutputComponents/FlexibleReportViews/FlexibleView'

//Styles
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'

//Functions

//Utilities
import findid from './Functions/Utilities/FindID'
import hasstaging from './Functions/Utilities/HasStaging'
import a11yProps from './Functions/Utilities/TabPropsFunc'
import CreateChartMetadata from './Functions/Utilities/CreateChartMetadata'
import FetchConfigWithDefault from './Functions/Utilities/FetchConfigWithDefault'
import FetchDataWithErrorHandling from './Functions/Utilities/FetchDataWithErrorHandling'

//Datapulls
import evaluateRows_newAPIs_consolidated from './Functions/DataPulls/EvaluateRows_Consolidated'
import getReportSettings from './Functions/DataPulls/GetReportSettings'
import definePriceBands from './Functions/DataPulls/DefinePriceBands'
import defineAttrGroups from './Functions/DataPulls/DefineAttrGroups'
import runcomparison_apis from './Functions/DataPulls/RunComparison'
import setChartCats from './Functions/DataPulls/SetChartCats'
import updateReportViews from './Functions/DataPulls/updateReportViews'
import updateAggregatedData from './Functions/DataPulls/updateAggregatedData'
import updateAggregatedDataOutlet from './Functions/DataPulls/updateAggregatedDataOutlet'
import updateWeeklyData from './Functions/DataPulls/updateWeeklyData'
import updateWeeklyBrandData from './Functions/DataPulls/UpdateWeeklyBrandData'
import updateItemWeeklyData from './Functions/DataPulls/UpdateItemWeeklyData'
import updateWeeklyChannelData from './Functions/DataPulls/UpdateWeeklyChannel'
import updateWeeklyOutletData from './Functions/DataPulls/UpdateWeeklyOutlet'
import updateDiscountBandData from './Functions/DataPulls/UpdateDiscountBands'
import updateOutletDiscountBandData from './Functions/DataPulls/UpdateOutletDiscountBands'
import updateBrandData from './Functions/DataPulls/UpdateBrandData'
import updateItemData from './Functions/DataPulls/UpdateItemData'
import updateChannelData from './Functions/DataPulls/UpdateChannelData'
import updateOutletBrandWeeklyData from './Functions/DataPulls/UpdateOutletWeeklyBrand'
import updateOutletChannelWeeklyData from './Functions/DataPulls/UpdateOutletWeeklyChannel'
import updateLevel4DataGeneric from './Functions/DataPulls/UpdateLevel4DataGeneric'
import updateLevel4WeeklyData from './Functions/DataPulls/UpdateLevel4WeeklyData'
import updateLevel4SegmentData from './Functions/DataPulls/UpdateLevel4SegmentData'
import updateLevel4BrandData from './Functions/DataPulls/UpdateLevel4BrandData'
import updateDropdowns from './Functions/DataPulls/UpdateDropdowns'
import updateScorecardData from './Functions/DataPulls/updateScorecardData'
import ASPWaterfallCalc from './Functions/DataPulls/ASPWaterfallCalc'
import getTableInfo from './Functions/DataPulls/GetTableInfo'
import updateCommonItems from './Functions/DataPulls/UpdateCommonItems'
import clearDropdowns from './Functions/DataPulls/clearDropdowns'

//Download Data Functions
import download_alldata from './Functions/Downloads/DownloadAllDataAggView'
import download_alldata_YOY from './Functions/Downloads/DownloadAllDataAggViewYOY'
import download_data_itemmetrics_yoy from './Functions/Downloads/DownloadDataItemMetricsYOY'
import downloadScoreCardData from './Functions/Downloads/DownloadScoreCardData'
import downloadScoreCardDataYOY_All from './Functions/Downloads/DownloadScoreCardDataYOYAll'
import downloadScoreCardDataYOY from './Functions/Downloads/DownloadScoreCardDataYOY'
import downloadFlexibleWeeklyTableData from './Functions/Downloads/DownloadFlexibleWeeklyTableData'
import downloadFlexibleBrandTableData from './Functions/Downloads/DownloadFlexibleBrandTableData'
import downloadFlexibleSegmentTableData from './Functions/Downloads/DownloadFlexibleSegmentTableData'
import downloadFlexibleTableDataCommon from './Functions/Downloads/DownloadFlexibleTableDataCommon'
import exportItemWeeklyData from './Functions/Downloads/ExportItemWeeklyData'

//Filters
import setfilters from './Functions/Filters/SetFilters'

//UI Functions

//Change Functions
import changeViewFilters from './Functions/UIFunctions/ChangeFunctions/changeViewFilters'
import changeSelectedChannelMulti from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedChannelMulti'
import changeSelectedBrandMulti from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedBrandMulti'
import changeSelectedItemMulti from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedItemMulti'
import changeRank from './Functions/UIFunctions/ChangeFunctions/ChangeRank'
import changeItemRank from './Functions/UIFunctions/ChangeFunctions/ChangeItemRank'
import changeBrandRank from './Functions/UIFunctions/ChangeFunctions/ChangeBrandRank'
import changeSeg1Rank from './Functions/UIFunctions/ChangeFunctions/ChangeSeg1Rank'
import changeSeg2Rank from './Functions/UIFunctions/ChangeFunctions/ChangeSeg2Rank'
import changesmoothingWeekNum from './Functions/UIFunctions/ChangeFunctions/ChangeSmoothingWeekNum'
import changeSelectedChannel from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedChannel'
import changeSelectedMetric from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedMetric'
import changeSelectedMetric_Area from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedMetricArea'
import changeSelectedLevel_BaseInc from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedLevelBaseInc'
import changeSelectedLevel from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedLevel'
import changeSelectedBrand from './Functions/UIFunctions/ChangeFunctions/ChangeSelectedBrand'
import changeMetricsCompareROM from './Functions/UIFunctions/ChangeFunctions/ChangeMetricsCompareROM'
import changeMetricsIndexedWeeks from './Functions/UIFunctions/ChangeFunctions/ChangeMetricsIndexedWeeks'
import changeShowDataTable from './Functions/UIFunctions/ChangeFunctions/ChangeShowDataTable'

//Handle Functions
import handleChangeTabs from './Functions/UIFunctions/HandleFunctions/HandleChangeTabs'
import handleToggle_stackingType from './Functions/UIFunctions/HandleFunctions/HandleToggleStackingType'
import handleToggle_useAttrGroups from './Functions/UIFunctions/HandleFunctions/HandleToggleUseAttrGroups'
import handleChange_IndexSwitchDollars from './Functions/UIFunctions/OtherFunctions/DollarUnitsSwitch'
import handleChange_twoyago from './Functions/UIFunctions/HandleFunctions/HandleChangeTwoAgo'
import handleChangePage from './Functions/UIFunctions/HandleFunctions/HandleChangePage'
import handleChangeRowsPerPage from './Functions/UIFunctions/HandleFunctions/HandleChangeRowsPerPage'
import handleChange_IndexSwitchYOY from './Functions/UIFunctions/HandleFunctions/HandleChangeIndexSwitchYOY'
import handleChange_IndexTopBottom from './Functions/UIFunctions/HandleFunctions/HandleChangeIndexTopBottom'
import handleToggle_downloadAllYOY from './Functions/UIFunctions/HandleFunctions/HandleToggleYOYDownload'
import handleChangeRadioRetailerPromoNonPromo from './Functions/UIFunctions/HandleFunctions/HandleChangeRadioRetailerPromoNonPromo'
import handleFilterPage from './Functions/UIFunctions/HandleFunctions/HandleFilterPage'
import handleDateStart from './Functions/UIFunctions/HandleFunctions/HandleDateStart'
import handleDateEndRange from './Functions/UIFunctions/HandleFunctions/HandleDateEndRange'
import handleDateEnd from './Functions/UIFunctions/HandleFunctions/HandleDateEnd'
import handleToggle_exclude_brands from './Functions/UIFunctions/HandleFunctions/HandleToggleExcludeBrands'
import handleChangeRadioBasePromo from './Functions/UIFunctions/HandleFunctions/HandleChangeRadioBasePromo'
import handleChangeRadioAttr from './Functions/UIFunctions/HandleFunctions/HandleChangeRadioAttr'
import handleToggle_exclude_brands_g1 from './Functions/UIFunctions/HandleFunctions/HandleToggleExcludeBrandsG1'
import handleToggle_exclude_brands_g2 from './Functions/UIFunctions/HandleFunctions/HandleToggleExcludeBrandsG2'
import handleToggle_exclude_items from './Functions/UIFunctions/HandleFunctions/HandleToggleExcludeItems'
import handleChange_channelviews from './Functions/UIFunctions/HandleFunctions/HandleChangeChannelViews'
import handleChange_BrandsItemsSwitch from './Functions/UIFunctions/HandleFunctions/HandleChangeBrandsItemsSwitch'
import handleChangeShareMetrics from './Functions/UIFunctions/HandleFunctions/HandleChangeShareMetrics'
import handleChange_MultipleBrandsSwitch from './Functions/UIFunctions/HandleFunctions/HandleChangeMultipleBrandsSwitch'
import handleChangeASPMinBox from './Functions/UIFunctions/HandleFunctions/HandleChangeASPMinBox'
import handleChangeASPMaxBox from './Functions/UIFunctions/HandleFunctions/HandleChangeASPMaxBox'
import handleChangeSlider from './Functions/UIFunctions/HandleFunctions/HandleChangeSlider'
import handleChangeDateRangeWeeksBox from './Functions/UIFunctions/HandleFunctions/HandleChangeDateRangeWeeksBox'
import handleOpenAllGeosModal from './Functions/UIFunctions/HandleFunctions/HandleOpenAllGeosModal'
import handleOpenAllBrandsModal from './Functions/UIFunctions/HandleFunctions/HandleOpenAllBrandsModal'
import handleOpenAllItemsModal from './Functions/UIFunctions/HandleFunctions/HandleOpenAllItemsModal'
import handleOpenReportCriteriaModal from './Functions/UIFunctions/HandleFunctions/HandleOpenReportCriteriaModal'
import handleCloseUsererrorAlert from './Functions/UIFunctions/HandleFunctions/HandleCloseUsererrorAlert'
import handleCloseDataLoadingAlert from './Functions/UIFunctions/HandleFunctions/HandleCloseDataLoadingAlert'
import handleClosechangeInFiltersAlert from './Functions/UIFunctions/HandleFunctions/HandleClosechangeInFiltersAlert'
import handleCloseDateCheckAlert from './Functions/UIFunctions/HandleFunctions/HandleCloseDateCheckAlert'
import handleCloseComputeDateRangeModal from './Functions/UIFunctions/HandleFunctions/HandleCloseComputeDateRangeModal'
import handleCloseReportCriteriaModal from './Functions/UIFunctions/HandleFunctions/HandleCloseReportCriteriaModal'
import handleCloseAllGeosModal from './Functions/UIFunctions/HandleFunctions/HandleCloseAllGeosModal'
import handleCloseAllBrandsModal from './Functions/UIFunctions/HandleFunctions/HandleCloseAllBrandsModal'
import handleCloseAllItemsModal from './Functions/UIFunctions/HandleFunctions/HandleCloseAllItemsModal'
import handleCloseAllAttrsModalG1 from './Functions/UIFunctions/HandleFunctions/HandleCloseAllAttrsModalG1'
import handleCloseAllAttrsModalG2 from './Functions/UIFunctions/HandleFunctions/HandleCloseAllAttrsModalG2'
import handleOpenAllAttrsModalG1 from './Functions/UIFunctions/HandleFunctions/HandleOpenAllAttrsModalG1'
import handleOpenAllAttrsModalG2 from './Functions/UIFunctions/HandleFunctions/HandleOpenAllAttrsModalG2'
import handleChange_IndexSwitch from './Functions/UIFunctions/HandleFunctions/HandleChange_IndexSwitch'
import handleChangeCompTabValue from './Functions/UIFunctions/HandleFunctions/HandleChangeCompTabValue'
import handleChangeG1NameBox from './Functions/UIFunctions/HandleFunctions/HandleChangeG1NameBox'
import handleChangeG2NameBox from './Functions/UIFunctions/HandleFunctions/HandleChangeG2NameBox'
import handleCloseScreenOrderAlert from './Functions/UIFunctions/HandleFunctions/HandleCloseScreenOrderAlert'
import handleChange_marketshare from './Functions/UIFunctions/HandleFunctions/HandleChange_MarketShare'
import handleCloseNewPageAlert from './Functions/UIFunctions/HandleFunctions/HandleCloseNewPageAlert'
import handleChange_showChange from './Functions/UIFunctions/HandleFunctions/HandleChangeShowChange'

//Set Functions
import setfilterpage1Open from './Functions/UIFunctions/SetFunctions/SetFilterPage1Open'
import setfilterpage1Close from './Functions/UIFunctions/SetFunctions/SetFilterPage1Close'
import setfilterpage2Open from './Functions/UIFunctions/SetFunctions/SetFilterPage2Open'
import setfilterpage2Close from './Functions/UIFunctions/SetFunctions/SetFilterPage2Close'
import setAttrsForGraphs from './Functions/UIFunctions/SetFunctions/SetAttrsForGraphs'
import setRangeStartingPoint from './Functions/UIFunctions/SetFunctions/SetRangeStartingPoint'
import setRangeAddSubtract from './Functions/UIFunctions/SetFunctions/SetRangeAddSubtract'
import setSelectedItem from './Functions/UIFunctions/SetFunctions/SetSelectedItem'
import setSelectedItem_new from './Functions/UIFunctions/SetFunctions/SetSelectedItemNew'
import setPriceDistOption from './Functions/UIFunctions/SetFunctions/SetPriceDistOption'
import setg1definition from './Functions/UIFunctions/SetFunctions/SetG1Definition'
import setg2definition from './Functions/UIFunctions/SetFunctions/SetG2Definition'
import setcomparison_metric from './Functions/UIFunctions/SetFunctions/SetComparisonMetric'
import setcomparison_metric_trended from './Functions/UIFunctions/SetFunctions/SetComparisonMetricTrended'
import setoverlay_metric from './Functions/UIFunctions/SetFunctions/SetOverlayMetric'

//Other Functions
import moveviewup from './Functions/UIFunctions/OtherFunctions/MoveViewUp'
import moveviewdown from './Functions/UIFunctions/OtherFunctions/MoveViewDown'
import moveviewup_attr from './Functions/UIFunctions/OtherFunctions/MoveViewUpAttr'
import moveviewdown_attr from './Functions/UIFunctions/OtherFunctions/MoveViewDownAttr'
import openScoreCardMenu from './Functions/UIFunctions/OtherFunctions/OpenScoreCardMenu'
import closeScoreCardMenu from './Functions/UIFunctions/OtherFunctions/CloseScoreCardMenu'
import openScoreCardMenuYOY from './Functions/UIFunctions/OtherFunctions/OpenScoreCardMenuYOY'
import closeScoreCardMenuYOY from './Functions/UIFunctions/OtherFunctions/CloseScoreCardMenuYOY'
import openFlexibleTableMenu from './Functions/UIFunctions/OtherFunctions/OpenFlexibleTableMenu'
import openFlexibleBrandTableMenu from './Functions/UIFunctions/OtherFunctions/OpenFlexibleBrandTableMenu'
import openFlexibleSegmentTableMenu from './Functions/UIFunctions/OtherFunctions/OpenFlexibleSegmentTableMenu'
import openFlexibleWeeklyTableMenu from './Functions/UIFunctions/OtherFunctions/OpenFlexibleWeeklyTableMenu'
import closeFlexibleTableMenu from './Functions/UIFunctions/OtherFunctions/CloseFlexibleTableMenu'
import closeFlexibleBrandTableMenu from './Functions/UIFunctions/OtherFunctions/CloseFlexibleBrandTableMenu'
import closeFlexibleSegmentTableMenu from './Functions/UIFunctions/OtherFunctions/CloseFlexibleSegmentTableMenu'
import closeFlexibleWeeklyTableMenu from './Functions/UIFunctions/OtherFunctions/CloseFlexibleWeeklyTableMenu'
import closeSnackBar_changebrands from './Functions/UIFunctions/OtherFunctions/CloseSnackBar_changebrands'
import closeSnackBar_datafiltered from './Functions/UIFunctions/OtherFunctions/CloseSnackBar_datafiltered'
import calculateDateRange from './Functions/UIFunctions/OtherFunctions/CalculateDateRange'
import applyCalculatedRange from './Functions/UIFunctions/OtherFunctions/ApplyCalculatedRange'
import sliderCommitted from './Functions/UIFunctions/OtherFunctions/SliderCommitted'
import changeFilterPause from './Functions/UIFunctions/OtherFunctions/ChangeFilterPause'
import changeFilterPause_reportViews from './Functions/UIFunctions/OtherFunctions/ChangeFilterPauseReportViews'
import computeDateRange from './Functions/UIFunctions/OtherFunctions/ComputeDateRange'
import showHideReportInsights from './Functions/UIFunctions/OtherFunctions/ShowHideReportInsights'
import showHideFilters from './Functions/UIFunctions/OtherFunctions/ShowHideFilters'
import openPriceBandsDefInition from './Functions/UIFunctions/OtherFunctions/OpenPriceBandsDefInition'
import openAttributeGroupsDefinition from './Functions/UIFunctions/OtherFunctions/OpenAttributeGroupsDefinition'
import openChangeVizzes from './Functions/UIFunctions/OtherFunctions/OpenChangeVizzes'
import showGroup1Def from './Functions/UIFunctions/OtherFunctions/ShowGroup1Def'
import showGroup2Def from './Functions/UIFunctions/OtherFunctions/ShowGroup2Def'
import hideGroup1Def from './Functions/UIFunctions/OtherFunctions/HideGroup1Def'
import hideGroup2Def from './Functions/UIFunctions/OtherFunctions/HideGroup2Def'
import setVizOption from './Functions/UIFunctions/OtherFunctions/SetVizOption'
import cancelSetViz from './Functions/UIFunctions/OtherFunctions/CancelSetViz'
import setNewVizOrderAndSetting from './Functions/UIFunctions/OtherFunctions/SetNewVizOrderAndSetting'
import setNewVizOrderOnly from './Functions/UIFunctions/OtherFunctions/SetNewVizOrderOnly'
import setDefaultOrder from './Functions/UIFunctions/OtherFunctions/SetDefaultOrder'
import openRedirectDialog from './Functions/UIFunctions/OtherFunctions/OpenRedirectDialog'

// API CRUD requests and error handling
import { ApiErrorDialog, openApiErrorDialog, closeApiErrorDialog, on_api_entity_crud_error } from '../ApiMethods/ApiErrorDialog'
import { entityCrud } from '../ApiMethods/EntityCrud'

// React Context
import {ReportContext} from "./Context/ReportContext"

// import tempCall from './Functions/DataPulls/__OldDataPullCheck' // FIXME TEMP

// Set Highcharts Objects
HC_exporting(Highcharts)
HC_export_data(Highcharts)
HighchartOptions(Highcharts);
window.Highcharts = Highcharts; //this line did the magic

(function (H) {
    H.Pointer.prototype.reset = function () {
        return undefined;
    };

    /**
     * Highlight a point by showing tooltip, setting hover state and draw crosshair
     */
    H.Point.prototype.highlight = function (event) {
        event = this.series.chart.pointer.normalize(event);
        this.onMouseOver(); // Show the hover marker
        //this.series.chart.tooltip.refresh(this); // Show the tooltip
        this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };

    H.syncExtremes = function (e) {
        var thisChart = this.chart;
        if (e.trigger !== "syncExtremes") {
            // Prevent feedback loop
            Highcharts.each(Highcharts.charts, function (chart) {
                if (chart && chart !== thisChart) {
                    if (chart.xAxis[0].setExtremes) {
                        // It is null while updating
                        chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                            trigger: "syncExtremes"
                        });
                    }
                }
            });
        }
    };
})(Highcharts);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class PDTReportOutputWithCustomOrdering extends Component {
    static displayName = PDTReportOutputWithCustomOrdering.name;

    constructor(props) {
        super(props);
        this.state = initialState
    }

    async componentDidMount() {
        var appname = Constant.whereamilist[3]

        // Bind Functions From Import
        this.entityCrud = entityCrud.bind(this)
        this.openApiErrorDialog = openApiErrorDialog.bind(this)
        this.closeApiErrorDialog = closeApiErrorDialog.bind(this)
        this.on_api_entity_crud_error = on_api_entity_crud_error.bind(this)

        this.FetchConfigWithDefault = FetchConfigWithDefault.bind(this)
        this.FetchDataWithErrorHandling = FetchDataWithErrorHandling.bind(this)
        this.CreateChartMetadata = CreateChartMetadata.bind(this)
        this.handleChangeTabs = handleChangeTabs.bind(this)
        this.handleToggle_stackingType = handleToggle_stackingType.bind(this)
        this.handleToggle_useAttrGroups = handleToggle_useAttrGroups.bind(this)
        this.evaluateRows_newAPIs_consolidated = evaluateRows_newAPIs_consolidated.bind(this)
        this.runcomparison_apis = runcomparison_apis.bind(this)
        this.getReportSettings = getReportSettings.bind(this)
        this.definePriceBands = definePriceBands.bind(this)
        this.defineAttrGroups = defineAttrGroups.bind(this)
        this.setChartCats = setChartCats.bind(this)
        this.updateReportViews = updateReportViews.bind(this)
        this.updateAggregatedData = updateAggregatedData.bind(this)
        this.updateAggregatedDataOutlet = updateAggregatedDataOutlet.bind(this)
        this.updateDropdowns = updateDropdowns.bind(this)
        this.clearDropdowns = clearDropdowns.bind(this)
        this.updateWeeklyData = updateWeeklyData.bind(this)
        this.updateWeeklyBrandData = updateWeeklyBrandData.bind(this)
        this.updateItemWeeklyData = updateItemWeeklyData.bind(this)
        this.updateWeeklyChannelData = updateWeeklyChannelData.bind(this)
        this.updateWeeklyOutletData = updateWeeklyOutletData.bind(this)
        this.updateBrandData = updateBrandData.bind(this)
        this.updateItemData = updateItemData.bind(this)
        this.updateChannelData = updateChannelData.bind(this)
        this.updateDiscountBandData = updateDiscountBandData.bind(this)
        this.updateOutletDiscountBandData = updateOutletDiscountBandData.bind(this)
        this.updateScorecardData = updateScorecardData.bind(this)
        this.updateOutletBrandWeeklyData = updateOutletBrandWeeklyData.bind(this)
        this.updateLevel4DataGeneric = updateLevel4DataGeneric.bind(this)
        this.updateLevel4WeeklyData = updateLevel4WeeklyData.bind(this)
        this.updateLevel4SegmentData = updateLevel4SegmentData.bind(this)
        this.updateLevel4BrandData = updateLevel4BrandData.bind(this)
        this.updateOutletChannelWeeklyData = updateOutletChannelWeeklyData.bind(this)
        this.ASPWaterfallCalc = ASPWaterfallCalc.bind(this)
        this.getTableInfo = getTableInfo.bind(this)
        this.updateCommonItems = updateCommonItems.bind(this)
        this.handleChange_IndexSwitchDollars = handleChange_IndexSwitchDollars.bind(this)
        this.handleChange_twoyago = handleChange_twoyago.bind(this)
        this.handleChange_IndexSwitchYOY = handleChange_IndexSwitchYOY.bind(this)
        this.handleChangePage = handleChangePage.bind(this)
        this.handleChangeRowsPerPage = handleChangeRowsPerPage.bind(this)
        this.handleChange_showChange = handleChange_showChange.bind(this)
        this.handleToggle_downloadAllYOY = handleToggle_downloadAllYOY.bind(this)
        this.download_alldata = download_alldata.bind(this)
        this.download_alldata_YOY = download_alldata_YOY.bind(this)
        this.download_data_itemmetrics_yoy = download_data_itemmetrics_yoy.bind(this)
        this.downloadScoreCardData = downloadScoreCardData.bind(this)
        this.downloadScoreCardDataYOY_All = downloadScoreCardDataYOY_All.bind(this)
        this.downloadScoreCardDataYOY = downloadScoreCardDataYOY.bind(this)
        this.downloadFlexibleWeeklyTableData = downloadFlexibleWeeklyTableData.bind(this)
        this.downloadFlexibleBrandTableData = downloadFlexibleBrandTableData.bind(this)
        this.downloadFlexibleSegmentTableData = downloadFlexibleSegmentTableData.bind(this)
        this.downloadFlexibleTableDataCommon = downloadFlexibleTableDataCommon.bind(this)
        this.exportItemWeeklyData = exportItemWeeklyData.bind(this)
        this.changeViewFilters = changeViewFilters.bind(this)
        this.changeSelectedChannelMulti = changeSelectedChannelMulti.bind(this)
        this.changeSelectedBrandMulti = changeSelectedBrandMulti.bind(this)
        this.changeSelectedItemMulti = changeSelectedItemMulti.bind(this)
        this.changeRank = changeRank.bind(this)
        this.changeBrandRank = changeBrandRank.bind(this)
        this.changeItemRank = changeItemRank.bind(this)
        this.changeSeg1Rank = changeSeg1Rank.bind(this)
        this.changeSeg2Rank = changeSeg2Rank.bind(this)
        this.setfilters = setfilters.bind(this)
        this.changesmoothingWeekNum = changesmoothingWeekNum.bind(this)
        this.changeSelectedChannel = changeSelectedChannel.bind(this)
        this.changeSelectedMetric = changeSelectedMetric.bind(this)
        this.changeSelectedMetric_Area = changeSelectedMetric_Area.bind(this)
        this.changeSelectedLevel_BaseInc = changeSelectedLevel_BaseInc.bind(this)
        this.changeSelectedLevel = changeSelectedLevel.bind(this)
        this.changeSelectedBrand = changeSelectedBrand.bind(this)
        this.changeMetricsCompareROM = changeMetricsCompareROM.bind(this)
        this.changeMetricsIndexedWeeks = changeMetricsIndexedWeeks.bind(this)
        this.handleFilterPage = handleFilterPage.bind(this)
        this.setfilterpage1Open = setfilterpage1Open.bind(this)
        this.setfilterpage1Close = setfilterpage1Close.bind(this)
        this.setfilterpage2Open = setfilterpage2Open.bind(this)
        this.setfilterpage2Close = setfilterpage2Close.bind(this)
        this.moveviewup = moveviewup.bind(this)
        this.moveviewdown = moveviewdown.bind(this)
        this.moveviewup_attr = moveviewup_attr.bind(this)
        this.moveviewdown_attr = moveviewdown_attr.bind(this)
        this.handleDateStart = handleDateStart.bind(this)
        this.handleDateEndRange = handleDateEndRange.bind(this)
        this.handleDateEnd = handleDateEnd.bind(this)
        this.handleToggle_exclude_brands = handleToggle_exclude_brands.bind(this)
        this.handleChangeRadioRetailerPromoNonPromo = handleChangeRadioRetailerPromoNonPromo.bind(this)
        this.handleChangeRadioBasePromo = handleChangeRadioBasePromo.bind(this)
        this.handleChangeRadioAttr = handleChangeRadioAttr.bind(this)
        this.handleToggle_exclude_brands_g1 = handleToggle_exclude_brands_g1.bind(this)
        this.handleToggle_exclude_brands_g2 = handleToggle_exclude_brands_g2.bind(this)
        this.handleToggle_exclude_items = handleToggle_exclude_items.bind(this)
        this.handleChange_channelviews = handleChange_channelviews.bind(this)
        this.handleChange_BrandsItemsSwitch = handleChange_BrandsItemsSwitch.bind(this)
        this.handleChangeShareMetrics = handleChangeShareMetrics.bind(this)
        this.handleChange_MultipleBrandsSwitch = handleChange_MultipleBrandsSwitch.bind(this)
        this.handleChange_IndexTopBottom = handleChange_IndexTopBottom.bind(this)
        this.handleChangeASPMinBox = handleChangeASPMinBox.bind(this)
        this.handleChangeASPMaxBox = handleChangeASPMaxBox.bind(this)
        this.handleChangeSlider = handleChangeSlider.bind(this)
        this.handleChangeDateRangeWeeksBox = handleChangeDateRangeWeeksBox.bind(this)
        this.handleOpenAllGeosModal = handleOpenAllGeosModal.bind(this)
        this.handleOpenAllBrandsModal = handleOpenAllBrandsModal.bind(this)
        this.handleOpenAllItemsModal = handleOpenAllItemsModal.bind(this)
        this.handleOpenReportCriteriaModal = handleOpenReportCriteriaModal.bind(this)
        this.handleCloseUsererrorAlert = handleCloseUsererrorAlert.bind(this)
        this.handleCloseDataLoadingAlert = handleCloseDataLoadingAlert.bind(this)
        this.handleClosechangeInFiltersAlert = handleClosechangeInFiltersAlert.bind(this)
        this.handleCloseDateCheckAlert = handleCloseDateCheckAlert.bind(this)
        this.handleCloseComputeDateRangeModal = handleCloseComputeDateRangeModal.bind(this)
        this.handleCloseReportCriteriaModal = handleCloseReportCriteriaModal.bind(this)
        this.handleCloseAllGeosModal = handleCloseAllGeosModal.bind(this)
        this.handleCloseAllBrandsModal = handleCloseAllBrandsModal.bind(this)
        this.handleCloseAllItemsModal = handleCloseAllItemsModal.bind(this)
        this.handleCloseAllAttrsModalG1 = handleCloseAllAttrsModalG1.bind(this)
        this.handleCloseAllAttrsModalG2 = handleCloseAllAttrsModalG2.bind(this)
        this.handleOpenAllAttrsModalG1 = handleOpenAllAttrsModalG1.bind(this)
        this.handleOpenAllAttrsModalG2 = handleOpenAllAttrsModalG2.bind(this)
        this.handleChange_IndexSwitch = handleChange_IndexSwitch.bind(this)
        this.handleChangeCompTabValue = handleChangeCompTabValue.bind(this)
        this.handleChangeG1NameBox = handleChangeG1NameBox.bind(this)
        this.handleChangeG2NameBox = handleChangeG2NameBox.bind(this)
        this.handleChange_marketshare = handleChange_marketshare.bind(this)
        this.handleCloseScreenOrderAlert = handleCloseScreenOrderAlert.bind(this)
        this.setAttrsForGraphs = setAttrsForGraphs.bind(this)
        this.setRangeStartingPoint = setRangeStartingPoint.bind(this)
        this.setRangeAddSubtract = setRangeAddSubtract.bind(this)
        this.setSelectedItem = setSelectedItem.bind(this)
        this.setSelectedItem_new = setSelectedItem_new.bind(this)
        this.setPriceDistOption = setPriceDistOption.bind(this)
        this.setg1definition = setg1definition.bind(this)
        this.setg2definition = setg2definition.bind(this)
        this.setcomparison_metric = setcomparison_metric.bind(this)
        this.setcomparison_metric_trended = setcomparison_metric_trended.bind(this)
        this.setoverlay_metric = setoverlay_metric.bind(this)
        this.changeShowDataTable = changeShowDataTable.bind(this)
        this.openScoreCardMenu = openScoreCardMenu.bind(this)
        this.closeScoreCardMenu = closeScoreCardMenu.bind(this)
        this.openScoreCardMenuYOY = openScoreCardMenuYOY.bind(this)
        this.closeScoreCardMenuYOY = closeScoreCardMenuYOY.bind(this)
        this.openFlexibleTableMenu = openFlexibleTableMenu.bind(this)
        this.openFlexibleWeeklyTableMenu = openFlexibleWeeklyTableMenu.bind(this)
        this.openFlexibleBrandTableMenu = openFlexibleBrandTableMenu.bind(this)
        this.openFlexibleSegmentTableMenu = openFlexibleSegmentTableMenu.bind(this)
        this.closeFlexibleTableMenu = closeFlexibleTableMenu.bind(this)
        this.closeFlexibleWeeklyTableMenu = closeFlexibleWeeklyTableMenu.bind(this)
        this.closeFlexibleBrandTableMenu = closeFlexibleBrandTableMenu.bind(this)
        this.closeFlexibleSegmentTableMenu = closeFlexibleSegmentTableMenu.bind(this)
        this.closeSnackBar_changebrands = closeSnackBar_changebrands.bind(this)
        this.closeSnackBar_datafiltered = closeSnackBar_datafiltered.bind(this)
        this.calculateDateRange = calculateDateRange.bind(this)
        this.applyCalculatedRange = applyCalculatedRange.bind(this)
        this.sliderCommitted = sliderCommitted.bind(this)
        this.changeFilterPause = changeFilterPause.bind(this)
        this.changeFilterPause_reportViews = changeFilterPause_reportViews.bind(this)
        this.computeDateRange = computeDateRange.bind(this)
        this.showHideReportInsights = showHideReportInsights.bind(this)
        this.showHideFilters = showHideFilters.bind(this)
        this.openPriceBandsDefInition = openPriceBandsDefInition.bind(this)
        this.openAttributeGroupsDefinition = openAttributeGroupsDefinition.bind(this)
        this.openChangeVizzes = openChangeVizzes.bind(this)
        this.showGroup1Def = showGroup1Def.bind(this)
        this.showGroup2Def = showGroup2Def.bind(this)
        this.hideGroup1Def = hideGroup1Def.bind(this)
        this.hideGroup2Def = hideGroup2Def.bind(this)
        this.setVizOption = setVizOption.bind(this)
        this.cancelSetViz = cancelSetViz.bind(this)
        this.setNewVizOrderOnly = setNewVizOrderOnly.bind(this)
        this.setNewVizOrderAndSetting = setNewVizOrderAndSetting.bind(this)
        this.setDefaultOrder = setDefaultOrder.bind(this)

        this.openRedirectDialog = openRedirectDialog.bind(this)
        this.handleCloseNewPageAlert = handleCloseNewPageAlert.bind(this)

        this.setUserSelectedAttrGroups = this.setUserSelectedAttrGroups.bind(this)

//        this.tempCall = tempCall.bind(this) // FIXME TEMP

        var hasstagingval = hasstaging()
        var extraparam, extraparam_multi
        if (hasstagingval) {
            extraparam = "?env=staging"
            extraparam_multi = "&env=staging"
        } else {
            extraparam = ""
            extraparam_multi = ""
        }

        let results = await this.entityCrud('PDT Report initial data fetch', [
            { 'url': Constant.baseurl + 'account/isauthenticated', 'method': 'GET', 'body': null },
            { 'url': Constant.baseurl + 'environment/getenvironment', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'SessionUser/Details', 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (results.Errors > 0) {
            // An error screen will be popped up if this fails, but we want to set "isLoaded" before we bail
            this.setState({ isLoaded: true })
            return
        }

        let user_auth_status = results.ResultList[0]
        let env_information = results.ResultList[1]
        let userinfo = results.ResultList[2]

        let user_role = (user_auth_status.userRole !== undefined) ? user_auth_status.userRole[0] : 'None'
        var companyname = userinfo.company_name

        this.setState({
            environment: env_information,
            hasstagingval,
            extraparam,
            extraparam_multi,
            usercompanyname: companyname,
            role: user_role
        })

        const reportid = findid();
        if (!await this.getReportSettings(reportid)) {
            console.log('Failed to get report settings so cannot continue')
            this.setState({ isLoaded: true })
            return
        }

        // Get Custom Attribute Groups
        await this.defineAttrGroups();

        // Check the initial filter and set the attribute groups as needed....
        var customAttrGroups = this.state.customAttrGroups || {}
        var selected_attr_groups = this.state.selected_attr_groups || {}
        console.log(this.state.filter_object)
        console.log(this.state.filter_object.attributes)
        let attributes = Object.keys(this.state.filter_object.attributes)
        
        var user_selected_attr_groups = this.state.user_selected_attr_groups || attributes.reduce((prev, current) => ({...prev, [current]: []}), {})
        
        attributes.forEach(attr => {
            let attrvalues = this.state.filter_object['attributes'][attr]
            let customAttrGroups_thisattr = customAttrGroups[attr] || []
            selected_attr_groups[attr] = []
            let checker = (arr, target) => target.every(v => arr.includes(v));
            customAttrGroups_thisattr.forEach(group => {
                // if the attributes in the filter === All or Empty, then set the group as true
                if (attrvalues.length === 0 || (attrvalues.length === 1 && attrvalues[0] === 'All')) {
                    selected_attr_groups[attr].push({ 'label': group.groupname, 'value': group.attr_vals })
                } else if (checker(attrvalues, group.attr_vals)) {
                    // if attrvalues includes the whole group attr then set the selected_attr_groups as true
                    selected_attr_groups[attr].push({ 'label': group.groupname, 'value': group.attr_vals })
                }
                //else do not push the group
            })
        })

        this.setState({ selected_attr_groups, user_selected_attr_groups })

        // Load Chart Metadata if segments are null...
        let totalnumsubcats = 0
        this.state.reportcategories.forEach(category => {
            totalnumsubcats += this.state.reportsubcategories[category].length || 0
        })
        if (this.state.reportcategories.length === 0 || totalnumsubcats === 0) {
            await this.setChartCats(reportid)
        }

        // Does the user has a non-default ordering for this report?
        var savedOrder
        let filtersettings_response = await fetch(Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderOptions")
        if (filtersettings_response.status === 200) {
            let savedOrder_data = await filtersettings_response.json()
            let savedOrderOptions = savedOrder_data.setting_value
            let savedOrderOptions_forDropdown = Object.keys(savedOrderOptions).map(option => { return { 'label': option, 'value': option } })
            savedOrder = savedOrderOptions[this.state.screenOrder] // this.state.screenOrder is set in GetReportSettings.js
            if (savedOrder) {
                console.log('*** Custom Visualization Order selected')
                let savedOrderValue_forDropdown = { 'value': this.state.screenOrder, 'label': this.state.screenOrder }
                this.setState({ savedOrderOptions_forDropdown, savedOrderOptions, savedOrderValue_forDropdown })
            } else {
                if (this.state.all_outlets.length > 1 || appname === 'PDTRetailer') {
                    console.log('*** Default ALR Visualization Order selected - PDTReportScreenOrderOptions exists but there is no saved order for the current report')
                    savedOrder = this.state.default_alr_order
                } else {
                    console.log('*** Default TMM Visualization Order selected - PDTReportScreenOrderOptions exists but there is no saved order for the current report')
                    savedOrder = this.state.default_tmm_order
                }
                this.setState({ savedOrderOptions_forDropdown, savedOrderOptions })
            }
        } else {
            // Any error means take the default -- 404 is totally OK; any other error is not expected 
            if (this.state.all_outlets.length > 1 || appname === 'PDTRetailer') {
                console.log('*** Default ALR Visualization Order selected - PDTReportScreenOrderOptions does not exist')
                savedOrder = this.state.default_alr_order
            } else {
                console.log('*** Default TMM  Visualization Order selected - PDTReportScreenOrderOptions does not exist')
                savedOrder = this.state.default_tmm_order
            }
        }

        const visualization_set = new Set()
        savedOrder.map((section, section_idx) => {
            section.tabs.map((tab, tab_idx) => {
                visualization_set.add(tab.visual)
            })
        })
        this.setState({ visualization_set })

        // Now that we've set the visualization_set, load all the data for those components that are going to be loaded
        await this.evaluateRows_newAPIs_consolidated(this.state.filter_object, this.state.exclude_array, this.state.exclude_array, 'initialloading');

        // Now that the data is loaded, we can display the visualizations that view the data
        let tabvalues = savedOrder.map(section => { return 0 })
        this.setState({ order: savedOrder, tabvalues })

        this.setState({ isLoaded: true })
    }

    setUserSelectedAttrGroups(newValue) {
        this.setState({user_selected_attr_groups: newValue})
    }

    render() {
        //console.log(this.state)

        return (
            <Layout title="Report Output" >
                {/* API error pop-up */}
                <ApiErrorDialog
                    state={this.state}
                    closeApiErrorDialog={this.closeApiErrorDialog}
                />

                <Loaders
                    state={this.state || {}}
                />

                <Snackbars
                    state={this.state}
                    closeSnackBar_changebrands={this.closeSnackBar_changebrands}
                    closeSnackBar_datafiltered={this.closeSnackBar_datafiltered}
                />

                <Dialogs
                    state={this.state}
                    handleCloseUsererrorAlert={this.handleCloseUsererrorAlert}
                    handleCloseDataLoadingAlert={this.handleCloseDataLoadingAlert}
                    handleClosechangeInFiltersAlert={this.handleClosechangeInFiltersAlert}
                    handleCloseDateCheckAlert={this.handleCloseDateCheckAlert}
                    handleCloseScreenOrderAlert={this.handleCloseScreenOrderAlert}
                />

                <ReDirectDialog
                    state={this.state}
                    handleCloseNewPageAlert={this.handleCloseNewPageAlert}
                    redirectfunction={this.state.redirectfunction}
                />

                <Modals
                    state={this.state}
                    handleCloseComputeDateRangeModal={this.handleCloseComputeDateRangeModal}
                    handleCloseReportCriteriaModal={this.handleCloseReportCriteriaModal}
                    handleCloseAllGeosModal={this.handleCloseAllGeosModal}
                    handleCloseAllBrandsModal={this.handleCloseAllBrandsModal}
                    handleCloseAllItemsModal={this.handleCloseAllItemsModal}
                    handleChangeDateRangeWeeksBox={this.handleChangeDateRangeWeeksBox}
                    handleOpenAllGeosModal={this.handleOpenAllGeosModal}
                    handleOpenAllBrandsModal={this.handleOpenAllBrandsModal}
                    handleOpenAllItemsModal={this.handleOpenAllItemsModal}
                    setRangeStartingPoint={this.setRangeStartingPoint}
                    handleDateEndRange={this.handleDateEndRange}
                    setRangeAddSubtract={this.setRangeAddSubtract}
                    calculateDateRange={this.calculateDateRange}
                    applyCalculatedRange={this.applyCalculatedRange}
                    openChangeVizzes={this.openChangeVizzes}
                    setVizOption={this.setVizOption}
                    cancelSetViz={this.cancelSetViz}
                    setNewVizOrderOnly={this.setNewVizOrderOnly}
                    setNewVizOrderAndSetting={this.setNewVizOrderAndSetting}
                    setDefaultOrder={this.setDefaultOrder}
                />

                <div className="contentHolderGradient" style={{ width: '100%', position: 'absolute', left: '120px', top: '60px',  minWidth: '1200px',maxWidth: 'calc(100vw - 120px)', padding: '20px' }}>
                    <div className="col-sm-12" style={{ borderRadius: '0px', borderColor: '#3f3f3f', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '40px', paddingRight: '40px' }}>
                    <ReportContext.Provider value={{
                        use_attr_groups: this.state.use_attr_groups,
                        filter_view_selected: this.state.filter_view_selected,
                        filter_view_options: this.state.filter_view_options,
                        changeViewFilters: this.changeViewFilters,
                        changeFilterPause_reportViews: this.changeFilterPause_reportViews,
                        all_geos: this.state.all_geos,
                        all_outlets: this.state.all_outlets,
                        dataset_data: this.state.dataset_data,
                        segment1label: this.state.segment1label,
                        segment2label: this.state.segment2label,
                        segment3label: this.state.segment3label,
                        segment4label: this.state.segment4label,
                        isLoaded_Filters: this.state.isLoaded_Filters,
                        handleToggle_useAttrGroups: this.handleToggle_useAttrGroups,
                        filterPause_reportViews: this.state.filterPause_reportViews,
                        slicers_not_applied: this.state.slicers_not_applied,
                        priceBandOptions: this.state.priceBandOptions,
                        item_options_filters: this.state.item_options_filters,
                        attributenamelabels: this.state.attributenamelabels,
                        attributes: this.state.attributes,

                        customUserAttrGroups: this.state.customUserAttrGroups,
                        user_selected_attr_groups: this.state.user_selected_attr_groups,
                        setUserSelectedAttrGroups: this.setUserSelectedAttrGroups
                    }}>
                        <ReportHeader
                            state={this.state}
                            showHideFilters={this.showHideFilters}
                            handleOpenReportCriteriaModal={this.handleOpenReportCriteriaModal}
                            showHideReportInsights={this.showHideReportInsights}
                            openPriceBandsDefInition={this.openPriceBandsDefInition}
                            openAttributeGroupsDefinition={this.openAttributeGroupsDefinition}
                            openChangeVizzes={this.openChangeVizzes}
                            changeFilterPause={this.changeFilterPause}
                            handleToggle_exclude_brands={this.handleToggle_exclude_brands}
                            handleToggle_exclude_items={this.handleToggle_exclude_items}
                            setfilters={this.setfilters}
                            handleDateStart={this.handleDateStart}
                            handleDateEnd={this.handleDateEnd}
                            computeDateRange={this.computeDateRange}
                            handleFilterPage={this.handleFilterPage}
                            handleChangeASPMinBox={this.handleChangeASPMinBox}
                            handleChangeASPMaxBox={this.handleChangeASPMaxBox}
                            handleChangeSlider={this.handleChangeSlider}
                            sliderCommitted={this.sliderCommitted}
                            openRedirectDialog={this.openRedirectDialog}
                        />
                        <div className="row" >
                            <div className="col-sm-12" style={{ maxWidth: '1500px' }}>
                                {/*Iterate Over the Order and Add Sections as Needed*/}
                                {(this.state.order || []).map((section,idx) => {
                                    return <Fragment
                                        key={'section_' + section.sectionname}
                                    >
                                        <div align='center' className="contentHolderTight" >
                                            <h5>{section.sectionname}</h5>
                                            <Constant.NPDAppbar
                                                position="static"
                                                style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }}
                                                className=""
                                            >
                                                <Constant.NPDTabs
                                                    value={this.state.tabvalues[idx]}
                                                    onChange={(event, newValue) => this.handleChangeTabs(event, newValue, idx)}
                                                    aria-label="simple tabs example"
                                                    indicatorColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                {section.tabs.map((tab,tabidx) => {
                                                    return <Constant.NPDTab
                                                        label={tab.tabname} {...a11yProps(tabidx)}
                                                        key={idx + '_' + tabidx + '_' + tab.tabname}
                                                    />
                                                })}
                                                </Constant.NPDTabs>
                                            </Constant.NPDAppbar>
                                            {section.tabs.map((tab, tabidx) => {
                                                return <TabPanel
                                                    value={this.state.tabvalues[idx]} index={tabidx}
                                                    key={'TabPanel_' + idx + '_' + tabidx}
                                                >
                                                    <div className="row" align='left' id="IconBar" style={{ paddingLeft: '15px' }}>
                                                        <ShowTableIcon
                                                            changeShowDataTable={this.changeShowDataTable}
                                                        />
                                                    </div>
                                                    {/* Inside this DIV, on this tab, will go a single visualization, identified by name */}
                                                    <div style={{ width: "100%" }}>
                                                        <Fragment>
                                                        {
                                                            (tab.visual === 'Base and Incremental') ?
                                                                <BaseIncrementalPromoNonPromoAggregate
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleToggle_downloadAllYOY={this.handleToggle_downloadAllYOY}
                                                                    handleChangeRadioBasePromo={this.handleChangeRadioBasePromo}
                                                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                                                    changeSelectedLevel_BaseInc={this.changeSelectedLevel_BaseInc}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    changeRank={this.changeRank}
                                                                    download_alldata_YOY={this.download_alldata_YOY}
                                                                    download_alldata={this.download_alldata}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Lift, Efficiency & Discount') ?
                                                                <LiftEffDiscAggregate_New
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleToggle_downloadAllYOY={this.handleToggle_downloadAllYOY}
                                                                    handleChangeRadioBasePromo={this.handleChangeRadioBasePromo}
                                                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                                                    changeSelectedLevel_BaseInc={this.changeSelectedLevel_BaseInc}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                    changeRank={this.changeRank}
                                                                    download_alldata_YOY={this.download_alldata_YOY}
                                                                    download_alldata={this.download_alldata}
                                                                />
                                                            : (tab.visual === 'Discount Dollars and Payback Ratio - Aggregate') ?
                                                                <DiscDollar_PBR
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleToggle_downloadAllYOY={this.handleToggle_downloadAllYOY}
                                                                    handleChangeRadioBasePromo={this.handleChangeRadioBasePromo}
                                                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                                                    changeSelectedLevel_BaseInc={this.changeSelectedLevel_BaseInc}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                    changeRank={this.changeRank}
                                                                    download_alldata_YOY={this.download_alldata_YOY}
                                                                    download_alldata={this.download_alldata}
                                                                />
                                                            : (tab.visual === 'Fair Share of Subsidization') ?
                                                                <Fair_Share
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleToggle_downloadAllYOY={this.handleToggle_downloadAllYOY}
                                                                    handleChangeRadioBasePromo={this.handleChangeRadioBasePromo}
                                                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                                                    changeSelectedLevel_BaseInc={this.changeSelectedLevel_BaseInc}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                    changeRank={this.changeRank}
                                                                    download_alldata_YOY={this.download_alldata_YOY}
                                                                    download_alldata={this.download_alldata}
                                                                />
                                                            : (tab.visual === 'Payback Ratio and Efficiency by Discount Range') ?
                                                                <LiftEffPBRByDiscBandAggregate
                                                                    state={this.state}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Base and Incremental by Attribute') ?
                                                                <BaseIncrementalPromoNonPromoAggregate
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleToggle_downloadAllYOY={this.handleToggle_downloadAllYOY}
                                                                    handleChangeRadioBasePromo={this.handleChangeRadioBasePromo}
                                                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                                                    changeSelectedLevel_BaseInc={this.changeSelectedLevel_BaseInc}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                    changeRank={this.changeRank}
                                                                    download_alldata_YOY={this.download_alldata_YOY}
                                                                    download_alldata={this.download_alldata}
                                                                />
                                                            : (tab.visual === 'Promotion Performance') ?
                                                                <BaseIncrementalTrended
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                                                    handleChangeRadioBasePromo={this.handleChangeRadioBasePromo}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Lift and Efficiency') ?
                                                                <LiftAndEfficiencyTrended
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Discount Dollars and Payback Ratio') ?
                                                                <DiscDollarsAndPBRTrended
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Base Velocity') ?
                                                                <BaseVelocity
                                                                    state={this.state}
                                                                    handleChange_BrandsItemsSwitch={this.handleChange_BrandsItemsSwitch}
                                                                    changesmoothingWeekNum={this.changesmoothingWeekNum}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedItemMulti={this.changeSelectedItemMulti}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Share by Brands') ?
                                                                <ShareTrended
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleChange_BrandsItemsSwitch={this.handleChange_BrandsItemsSwitch}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedItemMulti={this.changeSelectedItemMulti}
                                                                    handleChangeShareMetrics={this.handleChangeShareMetrics}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Units by Brand/Item') ?
                                                                <AreaChart
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleChange_BrandsItemsSwitch={this.handleChange_BrandsItemsSwitch}
                                                                    changeSelectedMetric_Area={this.changeSelectedMetric_Area}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedItemMulti={this.changeSelectedItemMulti}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Average Promoted Discount Pct Trended') ?
                                                                <AvgPromotedDiscountTrended
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    handleChange_BrandsItemsSwitch={this.handleChange_BrandsItemsSwitch}
                                                                    handleChange_MultipleBrandsSwitch={this.handleChange_MultipleBrandsSwitch}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedItemMulti={this.changeSelectedItemMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedChannel={this.changeSelectedChannel}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Average Promoted Discount Pct With Dollar Share') ?
                                                                <AvgPromotedDiscountWithDollarShare
                                                                    state={this.state}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Depth of Discount') ?
                                                                <DepthOfDiscount
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Promoted/Non-Promoted Price') ?
                                                                <PromotedNonPromotedPriceByWeek
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    handleChange_BrandsItemsSwitch={this.handleChange_BrandsItemsSwitch}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    changeSelectedItemMulti={this.changeSelectedItemMulti}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Base/Average Selling Price') ?
                                                                <ASPBasePriceByWeek
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    handleChange_BrandsItemsSwitch={this.handleChange_BrandsItemsSwitch}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    changeSelectedItemMulti={this.changeSelectedItemMulti}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Promoted Units/Dollars') ?
                                                                <PromotedUnitsDollarsByWeek
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    handleChange_BrandsItemsSwitch={this.handleChange_BrandsItemsSwitch}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    changeSelectedItemMulti={this.changeSelectedItemMulti}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Discount Range') ?
                                                                <DiscountRangeYOY
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedItem={this.changeSelectedBrand}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    handleChange_IndexSwitchYOY={this.handleChange_IndexSwitchYOY}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Total Volume') ?
                                                                <VolumeYOY
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedItem={this.changeSelectedBrand}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                />
                                                            : (tab.visual === 'Item Change Information') ?
                                                                <ItemYOYInformation
                                                                    state={this.state}
                                                                    handleChangePage={this.handleChangePage}
                                                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                    download_data_itemmetrics_yoy={this.download_data_itemmetrics_yoy}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Base and Incremental Units') ?
                                                                <ScoreCardBaseInc
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedItem={this.changeSelectedBrand}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    openScoreCardMenu={this.openScoreCardMenu}
                                                                    closeScoreCardMenu={this.closeScoreCardMenu}
                                                                    downloadScoreCardData={this.downloadScoreCardData}
                                                                    downloadScoreCardDataYOY_All={this.downloadScoreCardDataYOY_All}
                                                                    openScoreCardMenuYOY={this.openScoreCardMenuYOY}
                                                                    closeScoreCardMenuYOY={this.closeScoreCardMenuYOY}
                                                                    downloadScoreCardDataYOY={this.downloadScoreCardDataYOY}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Promoted and Non-Promoted Units') ?
                                                                <ScoreCardPromoNonPromo
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedItem={this.changeSelectedBrand}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    openScoreCardMenu={this.openScoreCardMenu}
                                                                    closeScoreCardMenu={this.closeScoreCardMenu}
                                                                    downloadScoreCardData={this.downloadScoreCardData}
                                                                    downloadScoreCardDataYOY_All={this.downloadScoreCardDataYOY_All}
                                                                    openScoreCardMenuYOY={this.openScoreCardMenuYOY}
                                                                    closeScoreCardMenuYOY={this.closeScoreCardMenuYOY}
                                                                    downloadScoreCardDataYOY={this.downloadScoreCardDataYOY}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Sales Metrics') ?
                                                                <ScoreCardSalesMetrics
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedItem={this.changeSelectedBrand}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    openScoreCardMenu={this.openScoreCardMenu}
                                                                    closeScoreCardMenu={this.closeScoreCardMenu}
                                                                    downloadScoreCardData={this.downloadScoreCardData}
                                                                    downloadScoreCardDataYOY_All={this.downloadScoreCardDataYOY_All}
                                                                    openScoreCardMenuYOY={this.openScoreCardMenuYOY}
                                                                    closeScoreCardMenuYOY={this.closeScoreCardMenuYOY}
                                                                    downloadScoreCardDataYOY={this.downloadScoreCardDataYOY}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Pricing Metrics') ?
                                                                <ScoreCardPricingMetrics
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedItem={this.changeSelectedBrand}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    openScoreCardMenu={this.openScoreCardMenu}
                                                                    closeScoreCardMenu={this.closeScoreCardMenu}
                                                                    downloadScoreCardData={this.downloadScoreCardData}
                                                                    downloadScoreCardDataYOY_All={this.downloadScoreCardDataYOY_All}
                                                                    openScoreCardMenuYOY={this.openScoreCardMenuYOY}
                                                                    closeScoreCardMenuYOY={this.closeScoreCardMenuYOY}
                                                                    downloadScoreCardDataYOY={this.downloadScoreCardDataYOY}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Promotion Metrics') ?
                                                                <ScoreCardPromotionMetrics
                                                                    state={this.state}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrand={this.changeSelectedBrand}
                                                                    changeSelectedItem={this.changeSelectedBrand}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    openScoreCardMenu={this.openScoreCardMenu}
                                                                    closeScoreCardMenu={this.closeScoreCardMenu}
                                                                    downloadScoreCardData={this.downloadScoreCardData}
                                                                    downloadScoreCardDataYOY_All={this.downloadScoreCardDataYOY_All}
                                                                    openScoreCardMenuYOY={this.openScoreCardMenuYOY}
                                                                    closeScoreCardMenuYOY={this.closeScoreCardMenuYOY}
                                                                    downloadScoreCardDataYOY={this.downloadScoreCardDataYOY}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'ALR vs ROM') ?
                                                                <ALRvsROMGraph
                                                                    state={this.state}
                                                                    changeSelectedMetric={this.changeSelectedMetric}
                                                                    changeSelectedLevel={this.changeSelectedLevel}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Average Promoted Discount % Trended') ?
                                                                <AvgDiscPercentALRvsROM
                                                                    state={this.state}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Average Selling ') ?
                                                                <ASPWeeklyALRvsROM
                                                                    state={this.state}
                                                                    handleChangeRadioRetailerPromoNonPromo={this.handleChangeRadioRetailerPromoNonPromo}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Depth of Discount by Discount Range') ?
                                                                <DepthOfDiscountByALR
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Average Selling Price Distribution by item') ?
                                                                <ItemASPDistRetailerROM
                                                                    state={this.state}
                                                                    setSelectedItem_new={this.setSelectedItem_new}
                                                                    setPriceDistOption={this.setPriceDistOption}
                                                                    exportItemWeeklyData={this.exportItemWeeklyData}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Base and Incremental ALR') ?
                                                                <ALRvsROM_BaseIncremental
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Lift, Efficiency, and Discount') ?
                                                                <ALRvsROM_LiftEfficiency
                                                                    state={this.state}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Metrics Changes') ?
                                                                <ALRvsROM_MetricChanges
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    handleChangeShareMetrics={this.handleChangeShareMetrics}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    handleChange_marketshare={this.handleChange_marketshare}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Metrics Changes With Market Share') ?
                                                                <MetricChangesWithMktShare
                                                                    state={this.state}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    changeSelectedBrandMulti={this.changeSelectedBrandMulti}
                                                                    changeSelectedChannelMulti={this.changeSelectedChannelMulti}
                                                                    handleChangeShareMetrics={this.handleChangeShareMetrics}
                                                                    handleChange_channelviews={this.handleChange_channelviews}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    handleChange_marketshare={this.handleChange_marketshare}
                                                                    handleChangeRadioBasePromo={this.handleChangeRadioBasePromo}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Metric Comparisons to ROM') ?
                                                                <ALRvsROM_MetricComparisons
                                                                    state={this.state}
                                                                    changeMetricsCompareROM={this.changeMetricsCompareROM}
                                                                    moveviewdown={this.moveviewdown}
                                                                    moveviewup={this.moveviewup}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Top/Bottom Performing Weeks') ?
                                                                <ALRvsROM_TopBottomWeeks
                                                                    state={this.state}
                                                                    changeMetricsIndexedWeeks={this.changeMetricsIndexedWeeks}
                                                                    handleChange_IndexTopBottom={this.handleChange_IndexTopBottom}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Promo Support and Share') ?
                                                                <PromoSupportAndShareImpactGraph
                                                                    state={this.state}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                                                    handleChange_showChange={this.handleChange_showChange}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                                                />
                                                            : (tab.visual === 'Weekly Performance') ?
                                                                <FlexibleWeeklyTableView
                                                                    state={this.state}
                                                                    updateData={this.updateLevel4WeeklyData}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    openFlexibleWeeklyTableMenu={this.openFlexibleWeeklyTableMenu}
                                                                    closeFlexibleWeeklyTableMenu={this.closeFlexibleWeeklyTableMenu}
                                                                    downloadFlexibleWeeklyTableData={this.downloadFlexibleWeeklyTableData}
                                                                />
                                                            : (tab.visual === 'Segment Performance') ?
                                                                <FlexibleSegmentTableView
                                                                    state={this.state}
                                                                    updateData={this.updateLevel4SegmentData}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    openFlexibleSegmentTableMenu={this.openFlexibleSegmentTableMenu}
                                                                    closeFlexibleSegmentTableMenu={this.closeFlexibleSegmentTableMenu}
                                                                    downloadFlexibleSegmentTableData={this.downloadFlexibleSegmentTableData}
                                                                />
                                                            : (tab.visual === 'Brand Performance') ?
                                                                <FlexibleBrandTableView
                                                                    state={this.state}
                                                                    updateData={this.updateLevel4BrandData}
                                                                    handleChange_twoyago={this.handleChange_twoyago}
                                                                    changeViewFilters={this.changeViewFilters}
                                                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                                                    openFlexibleBrandTableMenu={this.openFlexibleBrandTableMenu}
                                                                    closeFlexibleBrandTableMenu={this.closeFlexibleBrandTableMenu}
                                                                    downloadFlexibleBrandTableData={this.downloadFlexibleBrandTableData}
                                                                />
                                                            // If we don't find the visual in our list above, put a default place holder
                                                            : <div>{tab.visual}</div>
                                                        }
                                                        </Fragment>
                                                    </div>
                                                </TabPanel>
                                            })}
                                        </div>
                                    </Fragment>
                                })}
                            </div>
                        </div>

                        {/*
                        <div className="row" >
                            <div className="col-sm-12" style={{ maxWidth: '1250px', marginBottom:'25px' }}>
                                <FlexibleViewSection
                                    state={this.state}
                                    changeViewFilters={this.changeViewFilters}
                                    changeFilterPause_reportViews={this.changeFilterPause_reportViews}
                                    handleToggle_useAttrGroups={this.handleToggle_useAttrGroups}
                                    handleToggle_stackingType={this.handleToggle_stackingType}
                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                />
                            </div>
                        </div>
                        */}

                        <div className="row" >
                            <div className="col-sm-12" style={{ maxWidth: '1250px' }}>
                                <CompetitiveAnalysisSection
                                    state={this.state}
                                    handleCloseAllAttrsModalG1={this.handleCloseAllAttrsModalG1}
                                    handleCloseAllAttrsModalG2={this.handleCloseAllAttrsModalG2}
                                    handleOpenAllAttrsModalG1={this.handleOpenAllAttrsModalG1}
                                    handleOpenAllAttrsModalG2={this.handleOpenAllAttrsModalG2}
                                    handleChange_IndexSwitch={this.handleChange_IndexSwitch}
                                    handleChange_IndexSwitchDollars={this.handleChange_IndexSwitchDollars}
                                    handleChangeCompTabValue={this.handleChangeCompTabValue}
                                    handleChangeG1NameBox={this.handleChangeG1NameBox}
                                    handleToggle_exclude_brands_g1={this.handleToggle_exclude_brands_g1}
                                    handleChangeG2NameBox={this.handleChangeG2NameBox}
                                    handleToggle_exclude_brands_g2={this.handleToggle_exclude_brands_g2}
                                    setcomparison_metric={this.setcomparison_metric}
                                    setcomparison_metric_trended={this.setcomparison_metric_trended}
                                    setPriceDistOption={this.setPriceDistOption}
                                    setoverlay_metric={this.setoverlay_metric}
                                    setg2definition={this.setg2definition}
                                    setg1definition={this.setg1definition}
                                    changeShowDataTable={this.changeShowDataTable}
                                    showGroup1Def={this.showGroup1Def}
                                    showGroup2Def={this.showGroup2Def}
                                    hideGroup1Def={this.hideGroup1Def}
                                    hideGroup2Def={this.hideGroup2Def}
                                />
                            </div>
                        </div>
                        </ReportContext.Provider>
                    </div>
                </div>
            </Layout>
        )
    }
}
