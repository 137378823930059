export default function hasstaging() {
    var url = window.location.href;
    var temp = url.split("/");
    var i = unescape(temp[5]);
    var hasstagingval
    if (i.indexOf('?env=staging') > 0) {
        hasstagingval = true
    }
    else {
        hasstagingval = false
    }
    return hasstagingval
}