import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Select from 'react-select'
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Grid from '@material-ui/core/Grid'
import { TableWithPagination } from '../TableWithPagination'
import '../styles/custom.css'
import * as Constant from '../constants'

export class PdtDialogs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                {this.props.state.isLoaded === true &&
                    <div>
                        {/* Edit PDT Dataset Configuration Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.view_pdt_config}
                            onClose={this.props.hidePdtConfig}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className='row'>
                                    <div style={{ textAlign: 'left', width: '50%' }}><h1>PDT Dataset Configuration</h1></div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.hidePdtConfig}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>Dataset ID</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            disabled
                                            value={this.props.state.config_data.dataset_id}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Last Modified Date/Time</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            disabled
                                            value={this.props.state.config_data.time_updated}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Time Data Updated</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfig_etl_id"
                                            value={this.props.state.config_data.time_data_updated}
                                            disabled
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Is Being Modified</Grid>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            checked={this.props.state.pdt_being_modified}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Is Being Modified?' }}
                                            id="datasetconfigBeingModified"
                                            onChange={this.props.handleChangePdtDataSetConfigBeingModified}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Is Invalid</Grid>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            checked={this.props.state.pdt_is_invalid}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Is Invalid?' }}
                                            id="datasetconfigIsInvalid"
                                            onChange={this.props.handleChangePdtDataSetConfigIsInvalid}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Is Whole Industry</Grid>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            checked={this.props.state.pdt_is_whole_industry}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Is Whole Industry?' }}
                                            id="datasetconfigIsWholeIndustry"
                                            onChange={this.props.handleChangePdtDataSetConfigIsWholeIndustry}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Short Description</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigShort}
                                            id="datasetconfigShortDesc"
                                            value={this.props.state.pdt_dataset_short_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Table Prefix</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigTablePrefix}
                                            id="datasetconfigTablePrefix"
                                            value={this.props.state.pdt_dataset_table_prefix}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Flags</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigFlags}
                                            id="datasetconfigFlags"
                                            value={this.props.state.pdt_dataset_flags}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Store Groups</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigStoreGroups}
                                            id="datasetconfigStoreGroups"
                                            value={this.props.state.pdt_dataset_store_groups}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Time Aggregation</Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            id="TimeAgg"
                                            options={[{ 'label': 'Weekly', 'value': 'Weekly' }, { 'label': 'Monthly', 'value': 'Monthly' }]}
                                            placeholder={'Select Time Aggregation'}
                                            value={[{ 'label': this.props.state.pdt_dataset_time_agg, 'value': this.props.state.pdt_dataset_time_agg }]}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Industry</Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            id="datasetconfigIndustry"
                                            onChange={this.props.handleChangePdtDataSetConfigIndustry}
                                            options={this.props.state.industry_options}
                                            placeholder={'Select An Industry'}
                                            value={[{ 'label': this.props.state.industry_lookup[this.props.state.pdt_dataset_industry], 'value': this.props.state.pdt_dataset_industry }]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Creator ID</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            disabled
                                            value={this.props.state.config_data.creator_id}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Category Level</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigCategoryLevel}
                                            id="datasetconfig_category_level"
                                            value={this.props.state.pdt_dataset_category_level}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>ETL ID</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfig_etl_id"
                                            value={this.props.state.pdt_dataset_etl_id}
                                            disabled
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Long Description</Grid>
                                    <Grid item xs={10}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigLong}
                                            id="datasetconfigLongDesc"
                                            value={this.props.state.pdt_dataset_long_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigAttributes}
                                            id="datasetconfigAttributes"
                                            value={this.props.state.pdt_dataset_attributes.join(',')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Human Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigAttributesHuman}
                                            id="datasetconfigAttributesHuman"
                                            value={this.props.state.pdt_dataset_attributes_human}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Global Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigGlobalAttributes}
                                            id="datasetconfigAttributes"
                                            value={this.props.state.pdt_dataset_global_attributes.join(',')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Human Global Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigGlobalAttributesHuman}
                                            id="datasetconfigAttributesHuman"
                                            value={this.props.state.pdt_dataset_global_attributes_human}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 1</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment1}
                                            id="datasetconfigSegment1"
                                            value={this.props.state.pdt_dataset_segment_1}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 2</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment2}
                                            id="datasetconfigSegment2"
                                            value={this.props.state.pdt_dataset_segment_2}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 3</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment3}
                                            id="datasetconfigSegment3"
                                            value={this.props.state.pdt_dataset_segment_3}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 4</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment4}
                                            id="datasetconfigSegment4"
                                            value={this.props.state.pdt_dataset_segment_4}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 1 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel1List}
                                            id="datasetconfigSel1List"
                                            value={this.props.state.pdt_dataset_sel1_list}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 2 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel2List}
                                            id="datasetconfigSel2List"
                                            value={this.props.state.pdt_dataset_sel2_list}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 3 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel3List}
                                            id="datasetconfigSel3List"
                                            value={this.props.state.pdt_dataset_sel3_list}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 4 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel4List}
                                            id="datasetconfigSel4List"
                                            value={this.props.state.pdt_dataset_sel4_list}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                        {/* Delete Config Button here? */}
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitPdtConfig}
                                                disabled={!this.props.state.pdt_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* New PDT Dataset Configuration Modal */}
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.props.state.view_pdt_config_new}
                            onClose={this.props.handleClosePdtNewConfig}
                            style={{ overflowY: 'scroll' }}
                        >
                            <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                <div className='row'>
                                    <div style={{ textAlign: 'left', width: '50%' }}><h1>New PDT Dataset Configuration</h1></div>
                                    <div style={{ textAlign: 'right', width: '50%' }}>
                                        <IconButton onClick={this.props.handleClosePdtNewConfig}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>Is Being Modified</Grid>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            checked={this.props.state.pdt_being_modified}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Is Being Modified?' }}
                                            id="datasetconfigBeingModified"
                                            onChange={this.props.handleChangePdtDataSetConfigBeingModified}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Is Invalid</Grid>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            checked={this.props.state.pdt_is_invalid}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Is Invalid?' }}
                                            id="datasetconfigIsInvalid"
                                            onChange={this.props.handleChangePdtDataSetConfigIsInvalid}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Is Whole Industry</Grid>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            checked={this.props.state.pdt_is_whole_industry}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Is Whole Industry?' }}
                                            id="datasetconfigIsWholeIndustry"
                                            onChange={this.props.handleChangePdtDataSetConfigIsWholeIndustry}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Short Description</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigShort}
                                            id="datasetconfigShortDesc"
                                            value={this.props.state.pdt_dataset_short_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Table Prefix</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigTablePrefix}
                                            id="datasetconfigTablePrefix"
                                            value={this.props.state.pdt_dataset_table_prefix}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Flags</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigFlags}
                                            id="datasetconfigFlags"
                                            value={this.props.state.pdt_dataset_flags}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Store Groups</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigStoreGroups}
                                            id="datasetconfigStoreGroups"
                                            value={this.props.state.pdt_dataset_store_groups}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Time Aggregation</Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            id="TimeAgg"
                                            options={[{ 'label': 'Weekly', 'value': 'Weekly' }, { 'label': 'Monthly', 'value': 'Monthly' }]}
                                            placeholder={'Select Time Aggregation'}
                                            onChange={this.props.handleChangePdtDataSetConfigTimeAgg}
                                            value={[{ 'label': this.props.state.pdt_dataset_time_agg, 'value': this.props.state.pdt_dataset_time_agg }]}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Industry</Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            id="datasetconfigIndustry"
                                            onChange={this.props.handleChangePdtDataSetConfigIndustry}
                                            options={this.props.state.industry_options}
                                            placeholder={'Select An Industry'}
                                            value={[{ 'label': this.props.state.industry_lookup[this.props.state.pdt_dataset_industry], 'value': this.props.state.pdt_dataset_industry }]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Category Level</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigCategoryLevel}
                                            id="datasetconfig_category_level"
                                            value={this.props.state.pdt_dataset_category_level}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>ETL ID</Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            id="datasetconfig_etl_id"
                                            value={this.props.state.pdt_dataset_etl_id}
                                            disabled
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={2}>Long Description</Grid>
                                    <Grid item xs={10}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigLong}
                                            id="datasetconfigLongDesc"
                                            value={this.props.state.pdt_dataset_long_desc}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigAttributes}
                                            id="datasetconfigAttributes"
                                            value={this.props.state.pdt_dataset_attributes.join(',')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Human Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigAttributesHuman}
                                            id="datasetconfigAttributesHuman"
                                            value={this.props.state.pdt_dataset_attributes_human}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Global Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigGlobalAttributes}
                                            id="datasetconfigAttributes"
                                            value={this.props.state.pdt_dataset_global_attributes.join(',')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Dataset Human Global Attributes</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigGlobalAttributesHuman}
                                            id="datasetconfigAttributesHuman"
                                            value={this.props.state.pdt_dataset_global_attributes_human}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 1</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment1}
                                            id="datasetconfigSegment1"
                                            value={this.props.state.pdt_dataset_segment_1}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 2</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment2}
                                            id="datasetconfigSegment2"
                                            value={this.props.state.pdt_dataset_segment_2}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 3</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment3}
                                            id="datasetconfigSegment3"
                                            value={this.props.state.pdt_dataset_segment_3}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 4</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSegment4}
                                            id="datasetconfigSegment4"
                                            value={this.props.state.pdt_dataset_segment_4}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 1 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel1List}
                                            id="datasetconfigSel1List"
                                            value={this.props.state.pdt_dataset_sel1_list}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 2 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel2List}
                                            id="datasetconfigSel2List"
                                            value={this.props.state.pdt_dataset_sel2_list}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 3 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel3List}
                                            id="datasetconfigSel3List"
                                            value={this.props.state.pdt_dataset_sel3_list}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>Segment 4 List</Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            inputProps={{ style: { padding: 10 } }}
                                            onChange={this.props.handleChangePdtDataSetConfigSel4List}
                                            id="datasetconfigSel4List"
                                            value={this.props.state.pdt_dataset_sel4_list}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <div className='row' style={{ margin: '25px' }}>
                                    <div className='col-sm-4' style={{ textAlign: 'left' }}>
                                        {/* No delete button for the "new" item */}
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                    <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                        <ThemeProvider theme={this.props.muiTheme}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.props.handleSubmitPdtConfigNew}
                                                disabled={!this.props.state.pdt_edit_button_enabled}
                                            >
                                                Submit Changes
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* PDT Dataset Information Modal */}
                        {this.props.state.show_pdt_dataset_info &&
                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={this.props.state.show_pdt_dataset_info}
                                onClose={this.props.handleClosePdtDatasetInfo}
                                style={{ overflowY: 'scroll', overlay: { zIndex: 750 } }}
                            >
                                <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', overflowY: 'auto', marginTop: '10px', marginLeft: '40px', marginRight: '20px' }} className="contentHolder">
                                    <div className="row">
                                        <div style={{ textAlign: 'left', width: '50%' }}>
                                            <h1>PDT Dataset Info</h1>
                                        </div>
                                        <div style={{ textAlign: 'right', width: '50%' }}>
                                            <IconButton onClick={this.props.handleClosePdtDatasetInfo}>
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </div>
                                        <div className='col-sm-12'>
                                            <h2>Dataset Short Description: {this.props.state.datasetsettings_data.short_desc}</h2>
                                        </div>
                                        <div className='col-sm-12'>
                                            <h5>Time Ranges From: {(this.props.state.datasettimesettings_data[0] || "").substring(0, 10)} to {(this.props.state.datasettimesettings_data[1] || "").substring(0, 10)}</h5>
                                        </div>
                                        <div className='col-sm-6'>
                                            <h5>Store Groups</h5>
                                        </div>
                                        <div className='col-sm-6'>
                                            <h5>Brands</h5>
                                        </div>
                                        <div className='col-sm-6'>
                                            <TableWithPagination
                                                test='This is a test'
                                                length={this.props.state.datasetgeosettings_data.length}
                                                columns={['Store Group ID', 'Store Group Description']}
                                                data={this.props.state.datasetgeosettings_data.map(geo => { return [geo.storegpid, geo.storegpdesc] })}
                                            />
                                        </div>
                                        <div className='col-sm-6'>
                                            <TableWithPagination
                                                test='This is a test'
                                                length={this.props.state.datasetbrandsettings_data.length}
                                                columns={['Brand Name']}
                                                data={this.props.state.datasetbrandsettings_data.map(brand => { return [brand] })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <h5>Attributes</h5>
                                    </div>
                                    <div className='col-sm-12'>
                                        <TableWithPagination
                                            test='This is a test'
                                            length={this.props.state.datasetattrsettings_data.length}
                                            columns={['Attribute Column Name', 'Attribute Human Readable Label', 'Attribute Values']}
                                            data={this.props.state.datasetattrsettings_data.map(attr => { return [attr.attribute, this.props.state.pdt_attr_lookup[attr.attribute], attr.attribute_values.join(',')] })}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
                }
            </Fragment>
        )
    }
}
