export default function adjustWeeklyDataObjectOutlets(dataobject, outlets, brands, weekmapping, dates) {
    let weekly_data = {}
    outlets.forEach(outlet => {
        weekly_data[outlet] = weekly_data[outlet] || {}
        dataobject[outlet] = dataobject[outlet] || {}
        brands.forEach(brand => {
            weekly_data[outlet][brand] = weekly_data[outlet][brand] || {}
            dataobject[outlet][brand] = dataobject[outlet][brand] || {}
            dates.forEach(dateraw => {
                let date = weekmapping[(dateraw || "").substring(0, 10)]
                weekly_data[outlet][brand][date] = weekly_data[outlet][brand][date] || {
                    "baseunits": 0
                    , "incrementalunits": 0
                    , "promotedunits": 0
                    , "nonpromotedunits": 0
                    , "subsidizedbaseunits": 0
                    , "unsubsidizedbaseunits": 0
                    , "revopportunity": 0
                    , "basedollars": 0
                    , "incrementaldollars": 0
                    , "nonpromoteddollars": 0
                    , "promoteddollars": 0
                    , "unsubsidizedbasedollars": 0
                    , "subsidizedbasedollars": 0
                    , "discountdollars": 0
                    , "totalunits": 0
                    , "totalunits_withoutno": 0
                    , "totaldollars": 0
                    , "weightedpctdisc": 0
                    , "weightedpctdisc_withoutno": 0
                    , "weightedbp": 0
                    , "weightedasp": 0
                    , "weightedbp_withoutno": 0
                    , "carrieddoors": 0
                    , "totaldoors_s1": 0
                    , "totaldoors_s2": 0
                    , "totaldoors_s1_segment": 0
                    , "totaldoors_s2_segment": 0
                }

                //Add the variables from the old Data Object
                let old_object = dataobject[outlet][brand][dateraw] || {
                    "baseunits": 0
                    , "incrementalunits": 0
                    , "promotedunits": 0
                    , "nonpromotedunits": 0
                    , "subsidizedbaseunits": 0
                    , "unsubsidizedbaseunits": 0
                    , "revopportunity": 0
                    , "basedollars": 0
                    , "incrementaldollars": 0
                    , "nonpromoteddollars": 0
                    , "promoteddollars": 0
                    , "unsubsidizedbasedollars": 0
                    , "subsidizedbasedollars": 0
                    , "discountdollars": 0
                    , "totalunits": 0
                    , "totalunits_withoutno": 0
                    , "totaldollars": 0
                    , "weightedpctdisc": 0
                    , "weightedpctdisc_withoutno": 0
                    , "weightedbp": 0
                    , "weightedasp": 0
                    , "weightedbp_withoutno": 0
                    , "carrieddoors": 0
                    , "totaldoors_s1": 0
                    , "totaldoors_s2": 0
                    , "totaldoors_s1_segment": 0
                    , "totaldoors_s2_segment": 0
                }

                let baseunits = old_object['baseunits']
                let incunits = old_object['incrementalunits']
                let promounits = old_object['promotedunits']
                let nonpromounits = old_object['nonpromotedunits']
                let subbaseunits = old_object['subsidizedbaseunits']
                let unsubbaseunits = old_object['unsubsidizedbaseunits']
                let basedollars = old_object['basedollars']
                let incrementaldollars = old_object['incrementaldollars']
                let nonpromodollars = old_object['nonpromoteddollars']
                let promodollars = old_object['promoteddollars']
                let unsubbasedollars = old_object['unsubsidizedbasedollars']
                let subbasedollars = old_object['subsidizedbasedollars']
                let discountdollars = old_object['discountdollars']
                let totalunits = old_object['totalunits']
                let totalunits_withoutno = old_object['totalunits_withoutno']
                let totaldollars = old_object['totaldollars']
                let weightedpctdisc = old_object['weightedpctdisc']
                let weightedpctdisc_withoutno = old_object['weightedpctdisc_withoutno']
                let weightedbp = old_object['weightedbp']
                let weightedasp = old_object['weightedasp']
                let weightedbp_withoutno = old_object['weightedbp_withoutno']
                let carrieddoors = old_object['carrieddoors']
                let totaldoors_s1 = old_object['totaldoors_s1']
                let totaldoors_s2 = old_object['totaldoors_s2']
                let totaldoors_s1_segment = old_object['totaldoors_s1_segment']
                let totaldoors_s2_segment = old_object['totaldoors_s2_segment']

                weekly_data[outlet][brand][date]["baseunits"] += baseunits || 0
                weekly_data[outlet][brand][date]["incrementalunits"] += incunits || 0
                weekly_data[outlet][brand][date]["promotedunits"] += promounits || 0
                weekly_data[outlet][brand][date]["nonpromotedunits"] += nonpromounits || 0
                weekly_data[outlet][brand][date]["subsidizedbaseunits"] += subbaseunits || 0
                weekly_data[outlet][brand][date]["unsubsidizedbaseunits"] += unsubbaseunits || 0
                weekly_data[outlet][brand][date]["revopportunity"] += subbasedollars || 0
                weekly_data[outlet][brand][date]["basedollars"] += basedollars || 0
                weekly_data[outlet][brand][date]["incrementaldollars"] += incrementaldollars || 0
                weekly_data[outlet][brand][date]["nonpromoteddollars"] += nonpromodollars || 0
                weekly_data[outlet][brand][date]["promoteddollars"] += promodollars || 0
                weekly_data[outlet][brand][date]["unsubsidizedbasedollars"] += unsubbasedollars || 0 //(basedollars - subbasedollars) || 0
                weekly_data[outlet][brand][date]["subsidizedbasedollars"] += subbasedollars || 0
                weekly_data[outlet][brand][date]["discountdollars"] += discountdollars || 0
                weekly_data[outlet][brand][date]["totalunits"] += totalunits || 0
                weekly_data[outlet][brand][date]["totalunits_withoutno"] += totalunits_withoutno || 0
                weekly_data[outlet][brand][date]["totaldollars"] += totaldollars || 0
                weekly_data[outlet][brand][date]["weightedpctdisc"] += weightedpctdisc || 0
                weekly_data[outlet][brand][date]["weightedpctdisc_withoutno"] += weightedpctdisc_withoutno || 0
                weekly_data[outlet][brand][date]["weightedbp"] += weightedbp || 0
                weekly_data[outlet][brand][date]["weightedasp"] += weightedasp || 0
                weekly_data[outlet][brand][date]["weightedbp_withoutno"] += weightedbp_withoutno || 0
                weekly_data[outlet][brand][date]["carrieddoors"] += carrieddoors || 0
                weekly_data[outlet][brand][date]["totaldoors_s1"] += totaldoors_s1 || 0
                weekly_data[outlet][brand][date]["totaldoors_s2"] += totaldoors_s2 || 0
                weekly_data[outlet][brand][date]["totaldoors_s1_segment"] += totaldoors_s1_segment || 0
                weekly_data[outlet][brand][date]["totaldoors_s2_segment"] += totaldoors_s2_segment || 0
            })
        })
    })


    return weekly_data

}