export default function handleFilterPage() {

    let currentfilterpage = this.state.filterpage
    var newfilterpage
    if (currentfilterpage === 1) {
        newfilterpage = 2
        this.setState({ filterpage1_open: false })
    }
    else {
        newfilterpage = 1
        this.setState({ filterpage2_open: false })
    }
    this.setState({ filterpage: newfilterpage })
}