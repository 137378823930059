import * as Constant from '../constants'

export function hidePnpConfig(event) {
    this.setState({ view_pnp_config: false })
}

export function closeApiLoadingSuccessful(event) {
    this.setState({ pnp_loading_successful: false })
}

export function closeApiLoadingFailed(event) {
    this.setState({ pnp_loading_failed: false })
}

export function handleChangePnpDataSetConfigLong(event, newValue) {
    this.setState({ pnp_dataset_long_desc: document.getElementById("datasetconfigLongDesc").value})
}

export function handleChangePnpDataSetConfigShort(event, newValue) {
    this.setState({ pnp_dataset_short_desc: document.getElementById("datasetconfigShortDesc").value })
}

export function handleChangePnpDataSetConfigTablePrefix(event, newValue) {
    this.setState({ pnp_dataset_table_prefix: document.getElementById("datasetconfigTablePrefix").value })
}

export function handleChangePnpDataSetConfigDatasetIdentifier(event, newValue) {
    this.setState({ pnp_dataset_identifier: document.getElementById("datasetconfigDatasetIdentifier").value })
}

export function handleChangePnpDataSetConfigDatasetFolder(event, newValue) {
    this.setState({ pnp_dataset_folder: document.getElementById("datasetconfigDatasetFolder").value })
}

export function handleChangePnpDataSetConfigIndustry(selectedOptions) {
    this.setState({ pnp_dataset_industry: selectedOptions['value'] })
}

export function handleChangePnpConfigCheckboxEnabled(event, newValue) {
    this.setState({ pnp_dataset_is_enabled: (this.state.pnp_dataset_is_enabled) ? false : true })
}

export function handleChangeTimeIDCheckboxEnabled(event, newValue) {
    this.setState({ timeiddiff: (this.state.timeiddiff) ? false : true })
}

export function handleChangeProdIDCheckboxEnabled(event, newValue) {
    this.setState({ prodiddiff: (this.state.prodiddiff) ? false : true })
}

export async function openEditPnpDataSetConfig(configid) {
    if (!configid) {
        alert('There is no Dataset Associated with this Config')
        return
    }

    console.log('Getting PNP Dataset Config for dataset ID ' + configid)
    let results = await this.entityCrud('getting PNP Dataset configuration for ID ' + configid, [
        { 'url': Constant.baseapiurl_gen + 'pnp/DatasetLoading/Import/' + configid, 'method': 'GET', 'body': null }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var config_data = results['ResultList'][0]
        this.setState({
            config_data,
            view_pnp_config: true,
            pnp_dataset_long_desc: config_data.long_desc,
            pnp_dataset_short_desc: config_data.short_desc,
            pnp_dataset_table_prefix: config_data.table_prefix,
            pnp_dataset_identifier: config_data.dataset_identifier,
            pnp_dataset_folder: config_data.folder,
            pnp_dataset_industry: config_data.industry_id,
            pnp_dataset_is_enabled: config_data.is_enabled
        })
    }
}

// We are invoked directly in onclick() so we return a closure that when called does what we want
export async function openPnpDataSetInfo(dataset_id) {
    if (!dataset_id) {
        alert('There is no Dataset Associated with this Config')
        return
    }

    console.log('Getting PNP Dataset Information for Dataset ID ' + dataset_id)
    let url = Constant.baseapiurl_gen + 'pnp/Datasets/' + dataset_id
    let results = await this.entityCrud('getting PNP dataset information for ID ' + dataset_id, [
        { 'url': url, 'method': 'GET', 'body': null },
        { 'url': url + '/Geos', 'method': 'GET', 'body': null },
        { 'url': url + '/Brands', 'method': 'GET', 'body': null },
        { 'url': url + '/Times', 'method': 'GET', 'body': null },
        { 'url': url + '/AttributeValues', 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var datasetsettings_data = results['ResultList'][0]
        var datasetgeosettings_data = results['ResultList'][1]
        var datasetbrandsettings_data = results['ResultList'][2]
        var datasettimesettings_data = results['ResultList'][3]
        var datasetattrsettings_data = results['ResultList'][4]

        // Build a lookup of human-readable attributes
        let pnp_attr_lookup = {}
        datasetsettings_data.attributes.forEach((attr, idx) => {
            pnp_attr_lookup[attr] = datasetsettings_data.attributes_human[idx]
        })

        this.setState({
            datasetsettings_data,
            datasetgeosettings_data,
            datasetbrandsettings_data,
            datasettimesettings_data,
            datasetattrsettings_data,
            pnp_attr_lookup,
            show_pnp_dataset_info: true
        })
    }
}

export function handleOpenNewPnpConfig() {
    this.setState({
        config_data: {},
        pnp_dataset_long_desc: null,
        pnp_dataset_short_desc: null,
        pnp_dataset_table_prefix: null,
        pnp_dataset_identifier: null,
        pnp_dataset_folder: null,
        pnp_dataset_is_enabled: false,
        pnp_dataset_industry: null,
        view_pnp_config_new: true
    })
}

export async function handleSubmitPnpConfigNew() {
    this.setState({ pnp_edit_button_enabled: false })
    var config = {
        dataset_identifier: this.state.pnp_dataset_identifier,
        table_prefix: this.state.pnp_dataset_table_prefix,
        folder: this.state.pnp_dataset_folder,
        short_desc: this.state.pnp_dataset_short_desc,
        long_desc: this.state.pnp_dataset_long_desc,
        is_enabled: this.state.pnp_dataset_is_enabled,
        industry_id: this.state.pnp_dataset_industry
    }

    console.log('Submitting new PNP dataset configuration')
    let results = await this.entityCrud('submit for new PNP dataset configuration', [
        { 'url': Constant.baseapiurl_gen + 'pnp/DatasetLoading/Import', 'method': 'POST', 'body': config }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        results = await this.entityCrud('getting new list of all PNP datasets including the new one', [
            { 'url': Constant.baseapiurl_gen + 'pnp/DatasetLoading/Import', 'method': 'GET', 'body': null }
        ], this.on_api_entity_crud_error)
        if (results.Errors === 0) {
            let pnp_datasetimports_data = results.ResultList[0]
            let pnp_import_options = (pnp_datasetimports_data || []).map(import_item => {
                return { 'label': import_item.short_desc, 'value': import_item.dataset_detail_id }
            })
            pnp_import_options.sort((a, b) => a.label.localeCompare(b.label))
            this.setState({ datasetinfo_data: pnp_datasetimports_data, pnp_import_options })
        }

        this.handleCloseNewPnpConfig()
        alert('Changes Made Successfully')
    }

    this.setState({ pnp_edit_button_enabled: true })
}

export async function handleSubmitPnpConfig() {
    this.setState({ pnp_edit_button_enabled: false })
    let config_data = this.state.config_data

    var dataset_id = config_data.dataset_detail_id
    var config = {
        dataset_detail_id: dataset_id,
        time_updated: config_data.time_updated,
        dataset_identifier: this.state.pnp_dataset_identifier,
        table_prefix: this.state.pnp_dataset_table_prefix,
        folder: this.state.pnp_dataset_folder,
        short_desc: this.state.pnp_dataset_short_desc,
        long_desc: this.state.pnp_dataset_long_desc,
        is_enabled: this.state.pnp_dataset_is_enabled,
        industry_id: this.state.pnp_dataset_industry
    }

    console.log('Updating PNP dataset configuration for dataset ID ' + dataset_id)
    let results = await this.entityCrud('submit for updated PNP dataset configuration for dataset ' + dataset_id, [
        { 'url': Constant.baseapiurl_gen + 'pnp/DatasetLoading/Import/' + config_data.dataset_detail_id, 'method': 'PUT', 'body': config }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        let pnp_datasetimports_data = results.ResultList[0]
        let pnp_import_options = (pnp_datasetimports_data || []).map(import_item => {
            return { 'label': import_item.short_desc, 'value': import_item.dataset_detail_id }
        })
        pnp_import_options.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ datasetinfo_data: pnp_datasetimports_data, pnp_import_options })
        this.hidePnpConfig()
        alert('Changes Made Successfully')
    }

    this.setState({ pnp_edit_button_enabled: true })
}

export function handleCloseNewPnpConfig() {
    this.setState({ view_pnp_config_new: false })
}

export function handleClosePnpDatasetInfo() {
    this.setState({ show_pnp_dataset_info: false })
}

export async function identifyPNPDatasetstoLoad () {
    this.setState({ pnp_dataset_ready_button_enabled: false })

    let results = await this.entityCrud('finding PNP datasets ready to be loaded', [
        { 'url': Constant.baseapiurl_gen + 'pnp/DatasetLoading/FindReadyDatasets', 'method': 'GET', 'body': null }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        this.setState({ pnp_check_dataset_messages: results['ResultList'][0] })
    }

    this.setState({ pnp_dataset_ready_button_enabled: true })
}

export async function loadPNPDataset() {
    if (this.state.PNPdatasettoload === undefined || this.state.PNPdatasettoload === null) {
        alert('You must select a dataset to load')
        return
    }

    console.log('Kicking off an async load for PNP dataset ' + this.state.PNPdatasettoload)
    var prodiddiff_string = (this.state.prodiddiff) ? 'True' : 'False'
    var timeiddiff_string = (this.state.timeiddiff) ? 'True' : 'False'

    var identifier
    this.state.datasetinfo_data.forEach(dataset => {
        if (dataset.dataset_detail_id === this.state.PNPdatasettoload) {
            identifier = dataset.dataset_identifier
        }
    })
    let url = Constant.baseapiurl_gen + 'pnp/DatasetLoading/LoadDataset/' + identifier + '?allow_time_difference=' + timeiddiff_string + '&allow_prodid_differences=' + prodiddiff_string

    function on_success(async_messages, job_duration) {
        console.log('on_success function invoked')
        console.log(async_messages)
        console.log(job_duration)
        this.setState({
            pnp_loading_is_running: false,
            pnp_loading_successful: true,
            pnp_loading_duration: job_duration
        })
    }

    function on_error(async_status_code, async_messages, job_duration) {
        console.log('on_error function invoked')
        console.log(async_status_code)
        console.log(async_messages)
        console.log(job_duration)
        this.setState({
            pnp_loading_is_running: false,
            pnp_loading_failed: true,
            pnp_loading_duration: job_duration
        })
    }

    this.setState({
        pnp_loading_is_running: true,
        pnp_loading_duration: 0,
        pnp_loading_successful: false,
        pnp_loading_failed: false,
    })
    this.loadAsync('GET', url, null, 10, 'pnp_dataset_loading_messages', 'showloadingbar', on_error, on_success)
}

export function handleChangeDatasetToLoad(selectedOptions) {
    this.setState({
        PNPdatasettoload: selectedOptions.value,
        pnp_dataset_name_to_load: selectedOptions.label
    })
}
