import React, { Component, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Constant from '../../constants';
import '../../styles/custom.css'


export class Loaders extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    render() {

        return (
            <Fragment>
                <Constant.NPDBackdrop
                    open={!this.props.state.isLoaded}
                    invisible={false}
                >
                    {/*<CircularProgress color="#4e106f" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>
            </Fragment>
        )
    }
}