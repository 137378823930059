import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export class ItemASPDistRetailerROMGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        var currencycode = this.props.state.currencycode
        var selected_itemdistpriceoption = this.props.state.selected_itemdistpriceoption
        var price_label = selected_itemdistpriceoption.label
        return (
            <Fragment>
                {this.props.state.ASPRetailerItem ?
                    <p>Your {price_label} of {this.props.state.ASPRetailerItem} is higher than {(this.props.state.aspPercentileVal || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })} of the {this.props.state.unitsvar} sold in the rest of the market. </p>
                    :
                    <p />
                }

                {this.props.state.ASPRetailerItem ?
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'column',
                                height: (9 / 16 * 100) + '%' //16:9 ratio
                            },
                            metadata: this.props.state.chartmetadata,
                            title: { text: price_label + ' Distribution - Rest of Market <br>' + this.props.state.selectedItem_new  + this.props.state.chart_title_info },
                            series: [
                                {
                                    name: 'ROM ' + price_label + ' Distribution',
                                    type: 'column',
                                    data: this.props.state.percentile_vals,
                                    color: '#ff8b6a',
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function () {
                                            return this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                        }
                                    }
                                },
                            ],
                            xAxis: [

                                {
                                    title: { text: 'Percentile' },
                                    alignTicks: false,
                                    opposite: false,
                                    visible: true,
                                    categories: [.05, .10, .15, .20, .25, .30, .35, .40, .45, .50, .55, .60, .65, .70, .75, .80, .85, .90, .95],
                                    labels: {
                                        formatter: function () {
                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                    },
                                    plotLines: [{
                                        color: '#7d328a',
                                        dashStyle: 'solid',
                                        value: ((this.props.state.aspPercentileVal / .05) - 1),
                                        width: 3,
                                        label: {
                                            text: 'Your ' + price_label + ': ' + this.props.state.ASPRetailerItem,  //.toLocaleString(undefined, {style: 'currency', currency: currencycode}),
                                            align: 'left',
                                            rotation: 0
                                        },
                                        zIndex: 5
                                    }]
                                },

                            ],

                            yAxis: [
                                {
                                    title: { text: price_label },
                                    visible: true,
                                    labels: {
                                        formatter: function () {
                                            return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                    },
                                    min: this.props.state.itemDistYAxisMin
                                },


                            ],
                            tooltip: {
                                enabled: false
                            }

                        }
                        }
                    />
                    :
                    <div style={{ height: '400px' }}/>
                }
            </Fragment>
        )
    }
}