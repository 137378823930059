import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { BrandItemsSwitch } from '../../UIElements/Switches/BrandItemsSwitch'
import { AreaChartMetricSelect } from '../../UIElements/Dropdowns/AreaChartMetricSelect'
import { MultiItemSelect } from '../../UIElements/Dropdowns/MultiItemSelect'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { AreaChartTrended } from '../../../Graphs/AreaChartTrended'


export class AreaChart extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        var aggtype = this.props.state.datasettimeagg

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div align='left' className="row col-sm-4" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}                            
                        />
                    </div>
                    <div align='left' className="col-sm-4" >
                        <BrandItemsSwitch
                            state={this.props.state}
                            handleChange_BrandsItemsSwitch={this.props.handleChange_BrandsItemsSwitch}
                        />
                    </div>
                    <div align='left' className="col-sm-4" >
                        <AreaChartMetricSelect
                            state={this.props.state}
                            changeSelectedMetric_Area={this.props.changeSelectedMetric_Area}
                        />
                    </div>
                    {this.props.state.viewItems ?
                        <div align='left' className="col-sm-12" >
                            <MultiItemSelect
                                state={this.props.state}
                                changeSelectedItemMulti={this.props.changeSelectedItemMulti}
                            />
                        </div>
                        :
                        <div align='left' className="col-sm-12" >
                            <MultiBrandSelect
                                state={this.props.state}
                                changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                            />
                        </div>
                    }
                </div>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>                        
                        <AreaChartTrended
                            state={this.props.state}
                        />                        
                    </div>
                </div>
            </div>
        )
    }
}