export default function changeItemSelected(selectedOptions) {
    if (this.state.simsettings.sim_type === 'promo') {
        this.setState({
            selected_item: selectedOptions.value,
            // The waterfall data item must be a totally new item for the widget to function, so we make a deep copy
            waterfall_data_item: JSON.parse(JSON.stringify(this.state.waterfall_data_item)),
            //waterfall_data_item_graph: this.state.waterfall_data_item[selectedOptions.value],
            total_volume_change_item: this.state.total_volume_changes_item[selectedOptions.value],
            starting_base_volume_item: this.state.starting_volume_item[selectedOptions.value],
            diff_total_dollar_item: this.state.diff_total_dollars_item[selectedOptions.value],
            old_total_dollar_item: this.state.start_total_dollars_item[selectedOptions.value],
            incremental_profit_total_item: this.state.incremental_profit_item[selectedOptions.value],
            competitor_graph_data_items: (this.state.competitor_data[selectedOptions.value] || [])[0],
            competitor_graph_data_price: (this.state.competitor_data[selectedOptions.value] || [])[1],
            competitor_graph_data_promo: (this.state.competitor_data[selectedOptions.value] || [])[2],
            competitor_graph_data_total: (this.state.competitor_data[selectedOptions.value] || [])[3],
            competitor_graph_data_price_pct: (this.state.competitor_data[selectedOptions.value] || [])[4],
            competitor_graph_data_promo_pct: (this.state.competitor_data[selectedOptions.value] || [])[5],
            competitor_graph_data_total_pct: (this.state.competitor_data[selectedOptions.value] || [])[6],

            //If Promo Sim Change Metrics

            basedollars_total_item: this.state.basedollars_item[selectedOptions.value],
            retailer_eday_margin_dollars_total_item: this.state.retailer_eday_margin_dollars_item[selectedOptions.value],
            retailer_promotion_profit_total_item: this.state.retailer_promotion_profit_item[selectedOptions.value],
            retailer_promo_margin_dollars_total_item: this.state.retailer_promo_margin_dollars_item[selectedOptions.value],
            incremental_profit_total_item: this.state.incremental_profit_item[selectedOptions.value],
            incremental_net_revenue_total_item: this.state.incremental_net_revenue_item[selectedOptions.value],
            incremental_gross_revenue_total_item: this.state.incremental_gross_revenue_item[selectedOptions.value],
            net_revenue_total_item: this.state.net_revenue_item[selectedOptions.value],
            ttl_cost_per_addl_unit_total_item: this.state.ttl_cost_per_addl_unit_item[selectedOptions.value],
            total_spend_total_item: this.state.total_spend_item[selectedOptions.value],
            gross_revenue_total_item: this.state.gross_revenue_item[selectedOptions.value],
            total_promo_volume_item: this.state.promo_volume_item[selectedOptions.value],
            total_nonpromo_volume_item: this.state.nonpromo_volume_item[selectedOptions.value],
            incdollar_total_item: this.state.incdollar_item[selectedOptions.value],
            promodollar_total_item: this.state.promodollar_item[selectedOptions.value],
            nonpromodollar_total_item: this.state.nonpromodollar_item[selectedOptions.value],
            discountdollar_total_item: this.state.discountdollar_item[selectedOptions.value],
            unsubsidized_base_volume_total_item: this.state.unsubsidized_base_volume_item[selectedOptions.value],
            subsidized_base_volume_total_item: this.state.subsidized_base_volume_item[selectedOptions.value],
            unsubsidized_base_dollars_total_item: this.state.unsubsidized_base_dollars_item[selectedOptions.value],
            subsidized_base_dollars_total_item: this.state.subsidized_base_dollars_item[selectedOptions.value],
        })
    } else {
        this.setState({
            selected_item: selectedOptions.value,
            // The waterfall data item must be a totally new item for the widget to function, so we make a deep copy
            waterfall_data_item: JSON.parse(JSON.stringify(this.state.waterfall_data_item)),
            //waterfall_data_item_graph: this.state.waterfall_data_item[selectedOptions.value],
            total_volume_change_item: this.state.total_volume_changes_item[selectedOptions.value],
            starting_base_volume_item: this.state.starting_volume_item[selectedOptions.value],
            diff_total_dollar_item: this.state.diff_total_dollars_item[selectedOptions.value],
            old_total_dollar_item: this.state.start_total_dollars_item[selectedOptions.value],
            incremental_profit_total_item: this.state.incremental_profit_item[selectedOptions.value],
            competitor_graph_data_items: (this.state.competitor_data[selectedOptions.value] || [])[0],
            competitor_graph_data_price: (this.state.competitor_data[selectedOptions.value] || [])[1],
            competitor_graph_data_promo: (this.state.competitor_data[selectedOptions.value] || [])[2],
            competitor_graph_data_total: (this.state.competitor_data[selectedOptions.value] || [])[3],
            competitor_graph_data_price_pct: (this.state.competitor_data[selectedOptions.value] || [])[4],
            competitor_graph_data_promo_pct: (this.state.competitor_data[selectedOptions.value] || [])[5],
            competitor_graph_data_total_pct: (this.state.competitor_data[selectedOptions.value] || [])[6],
        })
    }
}
