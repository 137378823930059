import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class ALRLevelSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle">Select A Level</span>
                <Select
                    options={this.props.state.levelOptions}
                    placeholder={'Select A Level'}
                    onChange={(item) => this.props.changeSelectedLevel(item)}
                    value={this.props.state.selectedLevel}
                    styles={Constant.dropdownStyles}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}