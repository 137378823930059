import React, { Component, Fragment } from 'react';
import ReportToolTips from '../IconBar_Report';
import { ThemeProvider } from '@material-ui/core/styles';
import Datetime from 'react-datetime'
import Select, { components }  from 'react-select'
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import * as Constant from '../../constants'
import selectColourStyles from '../../styles/SelectColourStyles'

export class ReportHeader extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {            
            useDoors_inDownload: false,
        }
    }

    async componentDidMount() {
        //Determine if the user is internal or external...This will determine if the usedoors prop is set to true.
        var res = await fetch(Constant.baseurl + 'account/isauthenticated')
        if (res.status === 200) {
            let data = await res.json()
            if (data.internalUser === 'True') {
                this.setState({ useDoors_inDownload: true })
            }
        }
    }

    render() {

        const valid = (current) => {
            if (this.props.state.datasettimeagg === 'Monthly') {
                return current.format('D') === "1" && current.isSameOrAfter(this.props.state.pastdate) && current.isSameOrBefore(this.props.state.futuredate)
            }
            else {
                return current.day() === this.props.state.enddate_dow && current.isSameOrAfter(this.props.state.pastdate) && current.isSameOrBefore(this.props.state.futuredate)
            }

        };

        const valid_start = (current) => {
            if (this.props.state.time_aggregation === 'Monthly') {
                return current.format('D') === "1" && current.isSameOrAfter(this.props.state.pastdate) && current.isSameOrBefore(this.props.state.futuredate)
            }
            else {
                return current.day() === ((this.props.state.enddate_dow + 1) % 7) && current.isSameOrAfter(this.props.state.pastdate) && current.isSameOrBefore(this.props.state.futuredate)
            }

        };

        var filter_segment_label = this.props.state.segment1label + '/' + this.props.state.segment2label
        if (this.props.state.dataset_data.sel3_list) {
            filter_segment_label += '/' + this.props.state.segment3label
        }

        if (this.props.state.dataset_data.sel4_list) {
            filter_segment_label += '/' + this.props.state.segment4label
        }

        const Option = (props) => {
            //Define Variables in Style Here...
            let pbname = props.data.label
            let pbstart = props.data.value[0]
            let pbend = props.data.value[1]
            if ((String(pbstart).slice(-3) !== '.01') && pbstart > 0) {
                pbstart += 1
            }
            var pb_def_string
            if (!pbend) {
                pb_def_string = pbname + ': ' + this.props.state.currencysymbol + pbstart + ' + '
            }
            else if (pbstart === 0) {
                pb_def_string = pbname + ': Under ' + this.props.state.currencysymbol + pbend
            }
            else {
                pb_def_string = pbname + ': ' + this.props.state.currencysymbol + pbstart + ' - ' + this.props.state.currencysymbol + pbend
            }

            return (
                <Constant.HTMLTooltip title={
                    <Fragment>
                        <Typography style={{ fontWeight: "bold", color: "#4E106F" }}>{pb_def_string}</Typography>
                    </Fragment>
                } placement="right" {...props}>
                    <div style={{ marginLeft: '25px' }}>
                        <components.Option {...props} />
                    </div>
                </Constant.HTMLTooltip>
            );
        };
        
        function getFirstWeek(enddate) {
            if (enddate) {
                //Use the date to find the year
                var year = enddate.getFullYear()
                var startingdate = new Date(year, 0, 1)
                var enddate_dow = enddate.getDay()
                var target_dow = (enddate_dow)

                //from beginning date, loop through date until we get the target_dow
                var firstDate
                while (!firstDate) {
                    let startingdate_dow = startingdate.getDay()
                    if (startingdate_dow === target_dow) {
                        firstDate = new Date((new Date(startingdate)).setDate(startingdate.getDate() + 7))
                    }
                    else {
                        startingdate = new Date((new Date(startingdate)).setDate(startingdate.getDate() + 1))
                    }
                }

                return firstDate
            }
            else {
                return null
            }
        }

        var lastDate
        if (this.props.state.times_data) {
            lastDate = new Date((this.props.state.times_data || [])[1] || '2000-12-31T00:00:00')
        }

        var firstDate
        if (lastDate) {
            firstDate = getFirstWeek(lastDate)
        }

        var lastDate_form, firstDate_form
        if (lastDate && firstDate) {
            //Format Them...
            function formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            }

            lastDate_form = formatDate(lastDate)
            firstDate_form = formatDate(firstDate)
        }

        //Check Filters against lastDate and EndDate
        var useYTDAggs = false
        var weekAggOptions = [1, 4, 5, 13, 26, 52]
        if (this.props.state.filter_object['enddate'] === lastDate_form && this.props.state.filter_object['startdate'] === firstDate_form) {
            useYTDAggs = true
            weekAggOptions = [1, 4, 5, 13, 26, 52, 'YTD']
        }
        
        //If Num Weeks set to YTD but we are in an ineligible period, reset to 1 for num weeks.
        if (this.props.state.filter_object['numweeks_raw'] === 'YTD' && useYTDAggs === false) {
            let item = {'value': 1, 'label': 1 + ' ' + this.props.state.timeperiodlabels + 's'}
            this.props.setfilters(item, 'numweeks')
        }

        // All element for filters
        const all_value = [{ 'label': 'All', 'value': 'All' }]
        //const all_value = { 'label': 'All', 'value': 'All' }

        return (
            <Fragment>
                <div className="row col-sm-12" style={{ borderRadius: '0px', borderColor: '#565A5C', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '40px', paddingRight: '40px' }}>
                    <div className="col-sm-4" align='left'>
                        <h3>{this.props.state.reportsettings.report_name}</h3>
                    </div>
                    <div className="col-sm-8" align='right'>
                        <ReportToolTips
                           
                            filters={this.props.state.filter_object}
                            exclude_array={this.props.state.exclude_array}
                            weeks_info={this.props.state.weeks_info}
                            priceBandOptions={this.props.state.priceBandOptions}
                            download_includedoors={this.state.useDoors_inDownload}
                            attributenamelabels={this.props.state.attributenamelabels}
                            segment1label={this.props.state.segment1label}
                            segment2label={this.props.state.segment2label}
                            segment3label={this.props.state.segment3label}
                            segment4label={this.props.state.segment4label}
                            currencysymbol={this.props.state.currencysymbol}
                            unitsvar={this.props.state.unitsvar}
                            currencyType={this.props.state.currencyType}
                            chartmetadata={this.props.state.chartmetadata}
                        />
                    </div>
                </div>


                <div className="row sticky" style={{ paddingLeft: '15px', zIndex: '2', minWidth: '1200px'}}>
                    <div className="row col-sm-12" style={{}}>
                        <span style={{
                            color: '#4e106f',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            marginLeft: '40px',
                            paddingLeft: '10px',
                            backgroundColor: '#FFF',
                            borderRadius: '10px 0px 0px 10px'
                        }}
                            onClick={this.props.showHideFilters}
                            key="FiltersLink"
                        >
                            Show/Hide Filters
					    </span>
                        <span style={{
                            color: '#4e106f',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            paddingLeft: '40px',
                            backgroundColor: '#FFF'
                        }}
                            onClick={this.props.handleOpenReportCriteriaModal}
                            key="AllGeosLink"
                        >
                            View Report Criteria
					    </span>
                        <span style={{
                            color: '#4e106f',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            paddingLeft: '40px',
                            backgroundColor: '#FFF',
                        }}
                            onClick={this.props.showHideReportInsights}
                            key="InsightsLink"
                        >
                            Show/Hide Report Insights
					    </span>
                        <span
                            style={{
                                color: '#4e106f',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                paddingLeft: '40px',                            
                                backgroundColor: '#FFF',                            
                            }}
                            onClick={(feature) => this.props.openRedirectDialog('priceband')}
                            key="PriceBandLink"
                        >
                            Define Price Bands
					    </span>
                        
                        <span
                            style={{
                                color: '#4e106f',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                paddingLeft: '40px',                            
                                backgroundColor: '#FFF',                            
                            }}
                            onClick={(feature) => this.props.openRedirectDialog('attributegroups')}
                            key="AttributeGroupLink"
                        >
                            Define Attribute Groups
					    </span>
                        
                        <span style={{
                            color: '#4e106f',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            paddingLeft: '40px',
                            paddingRight: '10px',
                            backgroundColor: '#FFF',
                            borderRadius: '0px 10px 10px 0px'
                        }}
                            onClick={this.props.openChangeVizzes}
                            key="VizSelectionLink"
                        >
                            Configure Layout
					    </span>
                    </div>

                    {this.props.state.hasstagingval === true ?
                        <div className="row col-sm-12 contentHolder" style={{ paddingLeft: '15px', zIndex: '2', maxWidth: '1390px', marginRight: '55px' }}>
                            <InfoRoundedIcon style={{ fontSize: '1.875rem' }} />
                            <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}> &nbsp; &nbsp; This Dataset is in STAGING.  Clients will not see these outputs until the dataset is moved to ACTIVE.</span>
                        </div>
                        :
                        <div />
                    }

                    <br />
                    {/*Filters Go Here...*/}
                    <div className="row sticky contentHolder" style={{ padding: '10px', display: this.props.state.displayFilters, maxWidth: '1390px', marginRight: '55px', maxHeight:'750px', overflowY: 'auto' }}>
                        <div className='col-sm-12'>
                            <h4>Filters</h4>
                        </div>
                        <div className='col-sm-4'>
                            {this.props.state.filterPause === 'apply' ?
                                <IconButton
                                    className="btn btn-primary"
                                    onClick={this.props.changeFilterPause}
                                    value='pause'
                                    style={{ padding: '5px', margin: '5px' }}
                                >
                                    <Tooltip title="Pause Filters" placement="right">
                                        <PauseRoundedIcon />
                                    </Tooltip>
                                </IconButton>

                                :
                                <IconButton
                                    className="btn btn-primary"
                                    onClick={this.props.changeFilterPause}
                                    value='apply'
                                    style={{ padding: '5px', margin: '5px' }}
                                >
                                    <Tooltip title="Apply Filters" placement="right">
                                        <PlayArrowRoundedIcon />
                                    </Tooltip>
                                </IconButton>
                            }

                        </div>
                        {this.props.state.filterpage === 1 ?
                            <Fragment>
                                <div className='col-sm-4'>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.state.exclude_brands}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                            onChange={this.props.handleToggle_exclude_brands}
                                        />
                                    Exclude Brands?
                                </ThemeProvider>
                                </div>
                                {this.props.state.showitem_filters ?
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                checked={this.props.state.exclude_items}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                                                onChange={this.props.handleToggle_exclude_items}
                                            />
                                            Exclude Items?
                                        </ThemeProvider>
                                    </div>
                                    :
                                    <div className='col-sm-4' />
                                }                                
                                <div className='col-sm-4'>
                                    <p>Outlet</p>
                                    <Select
                                        id="Outlet"
                                        options={all_value.concat((this.props.state.all_outlets || []).map(outlet => {
                                            return {
                                                'value': outlet, 'label': outlet
                                            }
                                        }))}
                                        isMulti
                                        placeholder={'Select Outlets'}
                                        onChange={(item, position) => this.props.setfilters(item, 'outlets')}
                                        value={(this.props.state.filter_object['outlets'] || []).map(item => { return { 'label': item, 'value': item } })}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <p>Brands</p>
                                    <Select
                                        id="BrandFilter1"
                                        options={all_value.concat(this.props.state.brand_options_filters)}
                                        isMulti
                                        placeholder={'Select Brands'}
                                        onChange={(item, position) => this.props.setfilters(item, 'brands')}
                                        value={(this.props.state.filter_object['brands'] || []).map(brand => { return { 'label': this.props.state.brand_lookup_filters[brand], 'value': brand } })}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                {this.props.state.showitem_filters ?
                                    <div className='col-sm-4'>
                                        <p>Items</p>
                                        <Select
                                            id="itemdropdown"
                                            options={all_value.concat(this.props.state.item_options_filters)}
                                            isMulti
                                            placeholder={'Select Items'}
                                            onChange={(item, position) => this.props.setfilters(item, 'items')}
                                            value={(this.props.state.filter_object['items'] || []).map(item => { return { 'label': this.props.state.item_lookup[item], 'value': item } })}
                                            closeMenuOnSelect={false}
                                            styles={Constant.dropdownStyles}
                                            styles={selectColourStyles}
                                        />
                                    </div>
                                    :
                                    <div />
                                }
                                <div className='col-sm-4'>
                                    <span className="row">
                                        <IconButton
                                            className="btn btn-primary"
                                            onClick={this.props.computeDateRange}
                                            style={{ padding: '5px', margin: '5px' }}
                                        >
                                            <Tooltip title="Compute a Date Range" placement="left">
                                                <DateRangeRoundedIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <p style={{ marginTop: '1rem' }}>Start Date</p>
                                    </span>
                                    <Datetime
                                        input={true}
                                        isValidDate={valid_start}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        onChange={this.props.handleDateStart}
                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportstartdate" }}
                                        value={this.props.state.startdate_raw}
                                    />
                                    {!this.props.state.showYOYViews ?
                                        < span style={{ color: '#f0ab00' }}><WarningRoundedIcon style={{ color: '#f0ab00' }} />  {'To see Previous Year Comparison, Start Date should be no earlier than '
                                            + new Date(new Date(((this.props.state.times_data || [])[1] || "").substring(0, 10) + 'T00:00:00') - (359 * 1000 * 60 * 60 * 24)).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + ', or use the entire available period.'}</span>
                                        :
                                        <span />
                                    }
                                </div>

                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }} >End Date</p>
                                    <Datetime
                                        input={true}
                                        isValidDate={valid}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        onChange={this.props.handleDateEnd}
                                        inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddate" }}
                                        value={this.props.state.selectedend}
                                    />
                                </div>

                                {/*TO DO ADD A WAY TO ADD 'YTD' ONLY IF END DATE AND START DATE are the beginning and ending of most recent year*/}
                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }}>{this.props.state.timeperiodlabels + ' Aggregation'}</p>
                                    <Select
                                        id="Week Aggregation"                                        
                                        options={(weekAggOptions || []).map(num => {
                                            return {
                                                'value': num, 'label': num + ' ' + this.props.state.timeperiodlabels + 's'
                                            }
                                        })}
                                        placeholder={'Select Number of Time Periods to Aggregate Trended Views'}
                                        onChange={(item, position) => this.props.setfilters(item, 'numweeks')}
                                        value={[{
                                            'label': this.props.state.filter_object['numweeks'] + ' ' + this.props.state.timeperiodlabels + 's', 'value': this.props.state.filter_object['numweeks']
                                        }]}
                                        styles={selectColourStyles}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }}>Channels</p>
                                    <Select
                                        id="Channel"
                                        options={all_value.concat(this.props.state.channel_options_filters)}
                                        isMulti
                                        placeholder={'Select Channels'}
                                        onChange={(channel, position) => this.props.setfilters(channel, 'channels')}
                                        value={(this.props.state.filter_object['channels'] || []).map(channel => { return { 'label': this.props.state.channel_lookup[channel], 'value': channel } })}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }} >Average Selling Price</p>
                                    <div className='row col-sm-12 '>
                                        <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                            {this.props.state.currencycode === 'USD' ?
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment: <Constant.NPDInputAdornment position="start"> &#x24; </Constant.NPDInputAdornment>
                                                    }}
                                                    onChange={this.props.handleChangeASPMinBox}
                                                    onKeyUp={(event) => {
                                                        if (event.key == 'Enter')
                                                            this.props.sliderCommitted()
                                                    }}
                                                    id="aspmin"
                                                    value={this.props.state.sliderRange[0]}
                                                />
                                                :
                                                this.props.state.currencycode === 'GBP' ?
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{
                                                            startAdornment: <Constant.NPDInputAdornment position="start"> &#xA3; </Constant.NPDInputAdornment>
                                                        }}
                                                        onChange={this.props.handleChangeASPMinBox}
                                                        onKeyUp={(event) => {
                                                            if (event.key == 'Enter')
                                                                this.props.sliderCommitted()
                                                        }}
                                                        id="aspmin"
                                                        value={this.props.state.sliderRange[0]}
                                                    />
                                                    :
                                                    this.props.state.currencycode === 'EUR' ?
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                startAdornment: <Constant.NPDInputAdornment position="start"> &#x20AC; </Constant.NPDInputAdornment>
                                                            }}
                                                            onChange={this.props.handleChangeASPMinBox}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    this.props.sliderCommitted()
                                                            }}
                                                            id="aspmin"
                                                            value={this.props.state.sliderRange[0]}
                                                        />
                                                        :
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                startAdornment: <Constant.NPDInputAdornment position="start"> &#x24; </Constant.NPDInputAdornment>
                                                            }}
                                                            onChange={this.props.handleChangeASPMinBox}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    this.props.sliderCommitted()
                                                            }}
                                                            id="aspmin"
                                                            value={this.props.state.sliderRange[0]}
                                                        />
                                            }
                                        </div>
                                        <div className='col-sm-6 '>
                                            <ThemeProvider theme={Constant.muiTheme}>
                                                <Slider
                                                    color="primary"
                                                    value={this.props.state.sliderRange}
                                                    onChange={this.props.handleChangeSlider}
                                                    onChangeCommitted={this.props.sliderCommitted}
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                    max={this.props.state.sliderRangeMax}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                            {this.props.state.currencycode === 'USD' ?
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment: <Constant.NPDInputAdornment position="start"> &#x24; </Constant.NPDInputAdornment>
                                                    }}
                                                    onChange={this.props.handleChangeASPMaxBox}
                                                    onKeyUp={(event) => {
                                                        if (event.key == 'Enter')
                                                            this.props.sliderCommitted()
                                                    }}
                                                    id="aspmax"
                                                    value={this.props.state.sliderRange[1]}
                                                />
                                                :
                                                this.props.state.currencycode === 'GBP' ?
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{
                                                            startAdornment: <Constant.NPDInputAdornment position="start"> &#xA3; </Constant.NPDInputAdornment>
                                                        }}
                                                        onChange={this.props.handleChangeASPMaxBox}
                                                        onKeyUp={(event) => {
                                                            if (event.key == 'Enter')
                                                                this.props.sliderCommitted()
                                                        }}
                                                        id="aspmax"
                                                        value={this.props.state.sliderRange[1]}
                                                    />
                                                    :
                                                    this.props.state.currencycode === 'EUR' ?
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                startAdornment: <Constant.NPDInputAdornment position="start"> &#x20AC; </Constant.NPDInputAdornment>
                                                            }}
                                                            onChange={this.props.handleChangeASPMaxBox}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    this.props.sliderCommitted()
                                                            }}
                                                            id="aspmax"
                                                            value={this.props.state.sliderRange[1]}
                                                        />
                                                        :
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                startAdornment: <Constant.NPDInputAdornment position="start"> &#x24; </Constant.NPDInputAdornment>
                                                            }}
                                                            onChange={this.props.handleChangeASPMaxBox}
                                                            onKeyUp={(event) => {
                                                                if (event.key == 'Enter')
                                                                    this.props.sliderCommitted()
                                                            }}
                                                            id="aspmax"
                                                            value={this.props.state.sliderRange[1]}
                                                        />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <p style={{ marginTop: '1rem' }}>Price Band</p>
                                    <Select
                                        inputId='pricebandfilter'
                                        options={this.props.state.priceBandOptions}
                                        isMulti
                                        placeholder='Select a Price Band'
                                        onChange={(item, position) => this.props.setfilters(item, 'priceband')}
                                        value={this.props.state.filter_object['pricebands']}
                                        maxMenuHeight={250}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        components={{ Option }}
                                        styles={selectColourStyles}
                                    />
                                </div>

                                

                            </Fragment>
                            :
                            <Fragment>
                                <div className='col-sm-12'>
                                    <br />
                                    <h5>{filter_segment_label}</h5>
                                </div>

                                <div className='col-sm-6'>
                                    <p>{this.props.state.segment1label}</p>
                                    <Select
                                        options={all_value.concat(this.props.state.segment_1_options_filters)}
                                        isMulti
                                        placeholder={'Select ' + this.props.state.segment1label}
                                        onChange={(item, position) => this.props.setfilters(item, 'segment1')}
                                        value={(this.props.state.filter_object['segment1'] || []).map(segment => {
                                            let segmentlabel_raw = this.props.state.segment_1_lookup[segment]
                                            let segmentlabel = (segmentlabel_raw.split(' ') || []).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
                                            if (segmentlabel.substring(0, 12) === 'Makeup Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(13)
                                            }
                                            else if (segmentlabel.substring(0, 6) === 'Makeup' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(7)
                                            }
                                            else if (segmentlabel.substring(0, 9) === 'Fragrance' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(10)
                                            }

                                            else if (segmentlabel.substring(0, 4) === 'Hair' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(5)
                                            }

                                            else if (segmentlabel.substring(0, 14) === 'Skincare Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(15)
                                            }

                                            else if (segmentlabel.substring(0, 8) === 'Skincare' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(9)
                                            }
                                            return { 'label': segmentlabel, 'value': segment }
                                        })
                                        }
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                    />
                                </div>

                                <div className='col-sm-6'>
                                    <p>{this.props.state.segment2label}</p>
                                    <Select
                                        options={all_value.concat(this.props.state.segment_2_options_filters)}
                                        isMulti
                                        placeholder={'Select ' + this.props.state.segment2label}
                                        onChange={(item, position) => this.props.setfilters(item, 'segment2')}
                                        value={(this.props.state.filter_object['segment2'] || []).map(segment => {
                                            //cleanup segment name...lowercase and Remove Extraneous Information...
                                            //Break segment by spaces and captialize each word       
                                            let segmentlabel_raw = this.props.state.segment_2_lookup[segment]
                                            let segmentlabel = (segmentlabel_raw.split(' ') || []).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
                                            if (segmentlabel.substring(0, 12) === 'Makeup Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(13)
                                            }
                                            else if (segmentlabel.substring(0, 6) === 'Makeup' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(7)
                                            }
                                            else if (segmentlabel.substring(0, 9) === 'Fragrance' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(10)
                                            }

                                            else if (segmentlabel.substring(0, 4) === 'Hair' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(5)
                                            }

                                            else if (segmentlabel.substring(0, 14) === 'Skincare Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(15)
                                            }

                                            else if (segmentlabel.substring(0, 8) === 'Skincare' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(9)
                                            }
                                            return { 'label': segmentlabel, 'value': segment }
                                        })}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                    />
                                </div>
                                {this.props.state.dataset_data.sel3_list ? <div className='col-sm-6'>
                                    <p>{this.props.state.segment3label}</p>
                                    <Select
                                        options={all_value.concat(this.props.state.segment_3_options_filters)}
                                        isMulti
                                        placeholder={'Select ' + this.props.state.segment3label}
                                        onChange={(item, position) => this.props.setfilters(item, 'segment3')}
                                        value={(this.props.state.filter_object['segment3'] || []).map(segment => {
                                            //cleanup segment name...lowercase and Remove Extraneous Information...
                                            //Break segment by spaces and captialize each word   
                                            let segmentlabel_raw = this.props.state.segment_3_lookup[segment]
                                            let segmentlabel = (segmentlabel_raw.split(' ') || []).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
                                            if (segmentlabel.substring(0, 12) === 'Makeup Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(13)
                                            }
                                            else if (segmentlabel.substring(0, 6) === 'Makeup' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(7)
                                            }
                                            else if (segmentlabel.substring(0, 9) === 'Fragrance' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(10)
                                            }

                                            else if (segmentlabel.substring(0, 4) === 'Hair' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(5)
                                            }

                                            else if (segmentlabel.substring(0, 14) === 'Skincare Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(15)
                                            }

                                            else if (segmentlabel.substring(0, 8) === 'Skincare' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(9)
                                            }
                                            return { 'label': segmentlabel, 'value': segment }
                                        })}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                    />
                                </div>
                                    : <div className='col-sm-6' />
                                }
                                {this.props.state.dataset_data.sel4_list ? <div className='col-sm-6'>
                                    <p>{this.props.state.segment4label}</p>
                                    <Select
                                        options={all_value.concat(this.props.state.segment_4_options_filters)}
                                        isMulti
                                        placeholder={'Select ' + this.props.state.segment4label}
                                        onChange={(item, position) => this.props.setfilters(item, 'segment4')}
                                        value={(this.props.state.filter_object['segment4'] || []).map(segment => {
                                            //cleanup segment name...lowercase and Remove Extraneous Information...
                                            //Break segment by spaces and captialize each word       
                                            let segmentlabel_raw = this.props.state.segment_4_lookup[segment]
                                            let segmentlabel = (segmentlabel_raw.split(' ') || []).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
                                            if (segmentlabel.substring(0, 12) === 'Makeup Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(13)
                                            }
                                            else if (segmentlabel.substring(0, 6) === 'Makeup' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(7)
                                            }
                                            else if (segmentlabel.substring(0, 9) === 'Fragrance' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(10)
                                            }

                                            else if (segmentlabel.substring(0, 4) === 'Hair' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(5)
                                            }

                                            else if (segmentlabel.substring(0, 14) === 'Skincare Total' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(15)
                                            }

                                            else if (segmentlabel.substring(0, 8) === 'Skincare' && this.props.state.report_industry_name === 'U.S. Beauty') {
                                                segmentlabel = segmentlabel.slice(9)
                                            }
                                            return { 'label': segmentlabel, 'value': segment }
                                        })}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                    />
                                </div> : <div className='col-sm-6' />}




                                {(Object.keys(this.props.state.attributes) || []).length > 0
                                    ?
                                    <div className='col-sm-12'>
                                        <br />
                                        <h5>Attributes</h5>
                                    </div>
                                    :
                                    <div className='col-sm-12'/>
                                }
                                

                                {(Object.keys(this.props.state.attributes) || []).map(attribute => {
                                    let attribute_vals = this.props.state.attributes[attribute] || []
                                    let idname = attribute + "dropdown"
                                    return <div className='col-sm-4' key={idname}>
                                        <p>{this.props.state.attributenamelabels[attribute]}</p>
                                        <Select
                                            id={idname}
                                            //options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                            options={all_value.concat(attribute_vals.map(value => { return { 'label': value, 'value': value } }))}
                                            isMulti
                                            placeholder={'Select ' + this.props.state.attributenamelabels[attribute]}
                                            onChange={(item, position) => this.props.setfilters(item, attribute)}
                                            value={(((this.props.state.filter_object['attributes'] || {})[attribute]) || []).map(attrval => { return { 'label': attrval, 'value': attrval } })}
                                            closeMenuOnSelect={false}
                                            styles={Constant.dropdownStyles}
                                        />
                                    </div>
                                })

                                }

                                {(Object.keys(this.props.state.customAttrGroups) || []).length > 0
                                    ?
                                    <div className='col-sm-12'>
                                        <br />
                                        <h5>Attribute Groups</h5>
                                    </div>
                                    :
                                    <div className='col-sm-12' />
                                }

                                {(Object.keys(this.props.state.customAttrGroups) || []).map(attribute => {
                                    let attribute_groups = this.props.state.customAttrGroups[attribute] || []
                                    let idname = attribute + "groupdropdown"
                                    return <div className='col-sm-4' key={idname}>
                                        <p>{this.props.state.attributenamelabels[attribute]}</p>
                                        <Select
                                            id={idname}
                                            //options={attribute_groups.map(group => { return { 'label': group.groupname, 'value': group.attr_vals } })}
                                            options={all_value.concat(attribute_groups.map(group => { return { 'label': group.groupname, 'value': group.attr_vals } }))}
                                            isMulti
                                            placeholder={'Select ' + this.props.state.attributenamelabels[attribute] + ' Group'}
                                            onChange={(item, position, excludearray, filterpause, attrsent) => this.props.setfilters(item, 'attributegroup', [this.props.state.exclude_brands, this.props.state.exclude_items], this.props.state.filterPause, attribute)}
                                            value={(this.props.state.selected_attr_groups|| {} )[attribute] || [] }
                                            closeMenuOnSelect={false}
                                            styles={Constant.dropdownStyles}
                                        />
                                    </div>
                                })}

                                <div className='row col-sm-12'>
                                    <div className='col-sm-4'>
                                        
                                    </div>
                                </div>
                                <div className="row col-sm-12" style={{ height: '50px' }} />
                            </Fragment>
                        }
                        {this.props.state.filterpage === 1 ?
                            <div className="col-sm-12" style={{ textAlign: 'right' }}>
                                <IconButton
                                    className="btn btn-primary"
                                    onClick={this.props.handleFilterPage}
                                    value='2'
                                    style={{ padding: '5px', margin: '5px', }}
                                >
                                    <Tooltip title="Addtional Filters" placement="right" open={this.props.state.filterpage1_open} onOpen={this.props.setfilterpage1Open} onClose={this.props.setfilterpage1Close}>
                                        <SkipNextRoundedIcon style={{ fontSize: '2vw', color: '#4e106f' }} />
                                    </Tooltip>
                                </IconButton>
                            </div>
                            :
                            <div className="col-sm-12" style={{ textAlign: 'left' }}>
                                <IconButton
                                    className="btn btn-primary"
                                    onClick={this.props.handleFilterPage}
                                    value='1'
                                    style={{ padding: '5px', margin: '5px', }}
                                >
                                    <Tooltip title="Previous Filters" placement="right" open={this.props.state.filterpage2_open} onOpen={this.props.setfilterpage2Open} onClose={this.props.setfilterpage2Close}>
                                        <SkipPreviousRoundedIcon style={{ fontSize: '2vw', color: '#4e106f' }} />
                                    </Tooltip>
                                </IconButton>
                            </div>
                        }
                    </div>

                </div>



                

                <div className="row sticky" style={{ display: this.props.state.displayInsights, zIndex: '1' }}>
                    <div className="col-sm-12" style={{ maxWidth: '1500px' }} >
                        <div className="contentHolderTight" align='center'>
                            {this.props.state.isLoaded ?
                                <span>
                                    <h5>Market Summary</h5>
                                    <p>{this.props.state.reportInsightText}  <b>{this.props.state.reportInsightText_emph}</b>  {this.props.state.reportInsightText_end} </p>
                                </span>
                                : <div />
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}