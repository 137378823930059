import React, { Component, Fragment } from 'react';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { BrandItemsSwitch } from '../../UIElements/Switches/BrandItemsSwitch'
import { MultiItemSelect } from '../../UIElements/Dropdowns/MultiItemSelect'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
import { ShareMetricRadio } from '../../UIElements/Radios/ShareMetricRadio'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import WeeklyStackedBarMultipleSeries from '../../../Graphs/WeeklyStackedBarMultipleSeries'
import * as Constant from '../../../constants';

export class ShareTrended extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        var aggtype = this.props.state.datasettimeagg

        return (
            <div style={{ width: "100%", height: "100%"}}>
                <div className="row" >
                    
                    <div align='left' className="col-sm-3" >
                        <BrandItemsSwitch
                            state={this.props.state}
                            handleChange_BrandsItemsSwitch={this.props.handleChange_BrandsItemsSwitch}
                        />
                    </div>

                    {this.props.state.viewItems ?
                        <div align='left' className="col-sm-9" >
                            <MultiItemSelect
                                state={this.props.state}
                                changeSelectedItemMulti={this.props.changeSelectedItemMulti}
                            />
                        </div>
                        :
                        <div align='left' className="col-sm-9" >
                            <MultiBrandSelect
                                state={this.props.state}
                                changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                            />
                        </div>
                    }

                    <div align='left' className="row col-sm-3" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>

                    <div align='left' className="col-sm-9" >
                        <ShareMetricRadio
                            state={this.props.state}
                            handleChangeShareMetrics={this.props.handleChangeShareMetrics}
                        />
                    </div>

                   
                    
                                     
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%"}}>
                        {this.props.state.channelviews ?
                            <div id="" className="row" style={{ width: "100%", height: "100%" }}>
                                {this.props.state.sharemetricstotal ?
                                    this.props.state.indexSwitchCheckDollars ?
                                        <div id="unitsshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                            <WeeklyStackedBarMultipleSeries
                                                chart_title={'Total ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                chart_title_info={this.props.state.chart_title_info}
                                                seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                    return {
                                                        name: geo,
                                                        type: 'column',
                                                        stack: 'one',
                                                        color: Constant.graph_colors[idx],
                                                        yAxis: 0,
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                let dt = new Date(this.x)
                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                let date = new Date(this.x + offset)
                                                                if (aggtype === 'Monthly') {
                                                                    return '<span>Month: '
                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                                else {
                                                                    return '<span>Week Ending: '
                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                            }
                                                        },
                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                        (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['totaldollars']) / (this.props.state.weekly_data_geo['All Channels'][date]['totaldollars'])
                                                        ]),
                                                        zIndex: 0
                                                    }
                                                })}
                                                unitsvar={this.props.state.unitsvar}
                                                distinctdates={this.props.state.distinctdates}
                                                weekly_data={this.props.state.weekly_data_geo}
                                                selectedBrand={this.props.state.selectedChannel}
                                                showDataTable={this.props.state.showDataTable}
                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                weekagg={this.props.state.filter_object.numweeks}
                                                height={1}
                                                attr_caption_text={this.props.state.attr_caption_text}
                                                yAxisTitle={'Total ' + this.props.state.currencyType + ' Share'}
                                                yAxisType='percent'
                                                selectedBrands={this.props.state.selectedChannels}
                                                aggperiod={this.props.state.datasettimeagg}
                                                currencycode={this.props.state.currencycode}
                                                currencytype={this.props.state.currencyType}
                                                metadata={this.props.state.chartmetadata}
                                            />
                                        </div>
                                        :
                                        <div id="unitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                            <WeeklyStackedBarMultipleSeries
                                                chart_title={'Total ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                chart_title_info={this.props.state.chart_title_info}
                                                seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                    return {
                                                        name: geo,
                                                        type: 'column',
                                                        stack: 'one',
                                                        color: Constant.graph_colors[idx],
                                                        yAxis: 0,
                                                        tooltip: {
                                                            pointFormatter: function () {
                                                                let dt = new Date(this.x)
                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                let date = new Date(this.x + offset)
                                                                if (aggtype === 'Monthly') {
                                                                    return '<span>Month: '
                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                                else {
                                                                    return '<span>Week Ending: '
                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                        + '<br>'
                                                                        + this.series.name
                                                                        + '<br> '
                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        + '</span>'
                                                                }
                                                            }
                                                        },
                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                        (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['totalunits']) / (this.props.state.weekly_data_geo['All Channels'][date]['totalunits'])
                                                        ]),
                                                        zIndex: 0
                                                    }
                                                })}
                                                unitsvar={this.props.state.unitsvar}
                                                distinctdates={this.props.state.distinctdates}
                                                weekly_data={this.props.state.weekly_data_geo}
                                                selectedBrand={this.props.state.selectedChannel}
                                                showDataTable={this.props.state.showDataTable}
                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                weekagg={this.props.state.filter_object.numweeks}
                                                height={1}
                                                attr_caption_text={this.props.state.attr_caption_text}
                                                yAxisTitle={'Total ' + this.props.state.unitsvar + ' Share'}
                                                yAxisType='percent'
                                                selectedBrands={this.props.state.selectedChannels}
                                                aggperiod={this.props.state.datasettimeagg}
                                                currencycode={this.props.state.currencycode}
                                                currencytype={this.props.state.currencyType}
                                                metadata={this.props.state.chartmetadata}

                                            />
                                        </div>
                                    :
                                    this.props.state.sharemetricsbase ?
                                        this.props.state.indexSwitchCheckDollars ?
                                            <div id="baseshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={'Base ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info}
                                                    seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                        return {
                                                            name: geo,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['basedollars']) / (this.props.state.weekly_data_geo['All Channels'][date]['basedollars'])
                                                            ]),
                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={'Base ' + this.props.state.currencyType + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                            :
                                            <div id="baseunitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={'Base ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info}
                                                    seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                        return {
                                                            name: geo,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['baseunits']) / (this.props.state.weekly_data_geo['All Channels'][date]['baseunits'])
                                                            ]),
                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={'Base ' + this.props.state.unitsvar + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                        :
                                        this.props.state.sharemetricsincremental ?
                                            this.props.state.indexSwitchCheckDollars ?
                                                <div id="incshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={'Incremental ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info}
                                                        seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                            return {
                                                                name: geo,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['incrementaldollars']) / (this.props.state.weekly_data_geo['All Channels'][date]['incrementaldollars'])
                                                                ]),
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.weekly_data_geo}
                                                        selectedBrand={this.props.state.selectedChannel}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={1}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={'Incremental ' + this.props.state.currencyType + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedChannels}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                                :
                                                <div id="incunitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={'Incremental ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info}
                                                        seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                            return {
                                                                name: geo,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['incrementalunits']) / (this.props.state.weekly_data_geo['All Channels'][date]['incrementalunits'])
                                                                ]),
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.weekly_data_geo}
                                                        selectedBrand={this.props.state.selectedChannel}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={1}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={'Incremental ' + this.props.state.unitsvar + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedChannels}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                            :
                                            this.props.state.sharemetricspromoted ?
                                                this.props.state.indexSwitchCheckDollars ?
                                                    <div id="promoshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyStackedBarMultipleSeries
                                                            chart_title={'Promoted ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                            chart_title_info={this.props.state.chart_title_info}
                                                            seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                                return {
                                                                    name: geo,
                                                                    type: 'column',
                                                                    stack: 'one',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['promoteddollars']) / (this.props.state.weekly_data_geo['All Channels'][date]['promoteddollars'])
                                                                    ]),
                                                                    zIndex: 0
                                                                }
                                                            })}
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.weekly_data_geo}
                                                            selectedBrand={this.props.state.selectedChannel}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={1}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle={'Promoted ' + this.props.state.currencyType + ' Share'}
                                                            yAxisType='percent'
                                                            selectedBrands={this.props.state.selectedChannels}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            metadata={this.props.state.chartmetadata}
                                                        />
                                                    </div>
                                                    :
                                                    <div id="promounitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyStackedBarMultipleSeries
                                                            chart_title={'Promoted ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                            chart_title_info={this.props.state.chart_title_info}
                                                            seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                                return {
                                                                    name: geo,
                                                                    type: 'column',
                                                                    stack: 'one',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['promotedunits']) / (this.props.state.weekly_data_geo['All Channels'][date]['promotedunits'])
                                                                    ]),
                                                                    zIndex: 0
                                                                }
                                                            })}
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.weekly_data_geo}
                                                            selectedBrand={this.props.state.selectedChannel}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={1}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle={'Promoted ' + this.props.state.unitsvar + ' Share'}
                                                            yAxisType='percent'
                                                            selectedBrands={this.props.state.selectedChannels}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            metadata={this.props.state.chartmetadata}
                                                        />
                                                    </div>
                                                :
                                                this.props.state.sharemetricsnonpromoted ?
                                                    this.props.state.indexSwitchCheckDollars ?
                                                        <div id="nonpromoshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyStackedBarMultipleSeries
                                                                chart_title={'Total ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                                chart_title_info={this.props.state.chart_title_info}
                                                                seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                                    return {
                                                                        name: geo,
                                                                        type: 'column',
                                                                        stack: 'one',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['nonpromoteddollars']) / (this.props.state.weekly_data_geo['All Channels'][date]['nonpromoteddollars'])
                                                                        ]),
                                                                        zIndex: 0
                                                                    }
                                                                })}
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.weekly_data_geo}
                                                                selectedBrand={this.props.state.selectedChannel}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={1}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle={'Non-Promoted ' + this.props.state.currencyType + ' Share'}
                                                                yAxisType='percent'
                                                                selectedBrands={this.props.state.selectedChannels}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                metadata={this.props.state.chartmetadata}
                                                            />
                                                        </div>
                                                        :
                                                        <div id="nonpromounitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyStackedBarMultipleSeries
                                                                chart_title={'Non-Promoted ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                                chart_title_info={this.props.state.chart_title_info}
                                                                seriesList={this.props.state.selectedChannels.map((geo, idx) => {
                                                                    return {
                                                                        name: geo,
                                                                        type: 'column',
                                                                        stack: 'one',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (((this.props.state.weekly_data_geo[geo] || {})[date] || {})['nonpromotedunits']) / (this.props.state.weekly_data_geo['All Channels'][date]['nonpromotedunits'])
                                                                        ]),
                                                                        zIndex: 0
                                                                    }
                                                                })}
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.weekly_data_geo}
                                                                selectedBrand={this.props.state.selectedChannel}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={1}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle={'Non-Promoted ' + this.props.state.unitsvar + ' Share'}
                                                                yAxisType='percent'
                                                                selectedBrands={this.props.state.selectedChannels}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                metadata={this.props.state.chartmetadata}
                                                            />
                                                        </div>
                                                    :
                                                    <div />
                                }

                            </div>
                            :
                            !this.props.state.viewItems ?
                                <div id="" className="row" style={{ width: "100%", height: "100%" }}>
                                    {this.props.state.sharemetricstotal ?
                                        this.props.state.indexSwitchCheckDollars ?
                                            <div id="unitsshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={'Total ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info}
                                                    seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                        return {
                                                            name: brand,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldollars']) / (((this.props.state.weekly_data_totalcat_new|| {})[date] || {})['totaldollars'])
                                                            //]),
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totaldollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totaldollars'])
                                                            ]),
                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={'Total ' + this.props.state.currencyType + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedBrands}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                                />
                                            </div>
                                            :
                                            <div id="unitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={'Total ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info}
                                                    seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                        return {
                                                            name: brand,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits']) / (((this.props.state.weekly_data_totalcat_new|| {})[date] || {})['totalunits'])
                                                            //]),
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totalunits'])
                                                            ]),

                                                             

                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.weekly_data}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={'Total ' + this.props.state.unitsvar + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedBrands}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata_multiplebrands}

                                                />
                                            </div>
                                        :
                                        this.props.state.sharemetricsbase ?
                                            this.props.state.indexSwitchCheckDollars ?
                                                <div id="baseshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={'Base ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info}
                                                        seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                            return {
                                                                name: brand,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['basedollars']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['basedollars'])
                                                                //]),
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['basedollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['basedollars'])
                                                                ]),
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.weekly_data}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={1}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={'Base ' + this.props.state.currencyType + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedBrands}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata_multiplebrands}
                                                    />
                                                </div>
                                                :
                                                <div id="baseunitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={'Base ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info}
                                                        seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                            return {
                                                                name: brand,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['baseunits']) / (((this.props.state.weekly_data_totalcat_new|| {})[date] || {})['baseunits'])
                                                                //]),
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['baseunits']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['baseunits'])
                                                                ]),

                                                                
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.weekly_data}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={1}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={'Base ' + this.props.state.unitsvar + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedBrands}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata_multiplebrands}
                                                    />
                                                </div>
                                            :
                                            this.props.state.sharemetricsincremental ?
                                                this.props.state.indexSwitchCheckDollars ?
                                                    <div id="incshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyStackedBarMultipleSeries
                                                            chart_title={'Incremental ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                            chart_title_info={this.props.state.chart_title_info}
                                                            seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                                return {
                                                                    name: brand,
                                                                    type: 'column',
                                                                    stack: 'one',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['incrementaldollars']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['incrementaldollars'])
                                                                    //]),
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['incrementaldollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['incrementaldollars'])
                                                                    ]),
                                                                    zIndex: 0
                                                                }
                                                            })}
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.weekly_data}
                                                            selectedBrand={this.props.state.selectedBrand}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={1}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle={'Incremental ' + this.props.state.currencyType + ' Share'}
                                                            yAxisType='percent'
                                                            selectedBrands={this.props.state.selectedBrands}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            metadata={this.props.state.chartmetadata_multiplebrands}
                                                        />
                                                    </div>
                                                    :
                                                    <div id="incunitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyStackedBarMultipleSeries
                                                            chart_title={'Incremental ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                            chart_title_info={this.props.state.chart_title_info}
                                                            seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                                return {
                                                                    name: brand,
                                                                    type: 'column',
                                                                    stack: 'one',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['incrementalunits']) / (((this.props.state.weekly_data_totalcat_new|| {})[date] || {})['incrementalunits'])
                                                                    //]),
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['incrementalunits']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['incrementalunits'])
                                                                    ]),

                                                                   
                                                                    zIndex: 0
                                                                }
                                                            })}
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.weekly_data}
                                                            selectedBrand={this.props.state.selectedBrand}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={1}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle={'Incremental ' + this.props.state.unitsvar + ' Share'}
                                                            yAxisType='percent'
                                                            selectedBrands={this.props.state.selectedBrands}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            metadata={this.props.state.chartmetadata_multiplebrands}
                                                        />
                                                    </div>
                                                :
                                                this.props.state.sharemetricspromoted ?
                                                    this.props.state.indexSwitchCheckDollars ?
                                                        <div id="promoshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyStackedBarMultipleSeries
                                                                chart_title={'Promoted ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                                chart_title_info={this.props.state.chart_title_info}
                                                                seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                                    return {
                                                                        name: brand,
                                                                        type: 'column',
                                                                        stack: 'one',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['promoteddollars']) / (((this.props.state.weekly_data_totalcat_new|| {})[date] || {})['promoteddollars'])
                                                                        //]),
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['promoteddollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['promoteddollars'])
                                                                        ]),
                                                                        zIndex: 0
                                                                    }
                                                                })}
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.weekly_data}
                                                                selectedBrand={this.props.state.selectedBrand}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={1}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle={'Promoted ' + this.props.state.currencyType + ' Share'}
                                                                yAxisType='percent'
                                                                selectedBrands={this.props.state.selectedBrands}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                metadata={this.props.state.chartmetadata_multiplebrands}
                                                            />
                                                        </div>
                                                        :
                                                        <div id="promounitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyStackedBarMultipleSeries
                                                                chart_title={'Promoted ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                                chart_title_info={this.props.state.chart_title_info}
                                                                seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                                    return {
                                                                        name: brand,
                                                                        type: 'column',
                                                                        stack: 'one',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['promotedunits']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['promotedunits'])
                                                                        //]),
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['promotedunits']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['promotedunits'])
                                                                        ]),
                                                                        zIndex: 0
                                                                    }
                                                                })}
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.weekly_data}
                                                                selectedBrand={this.props.state.selectedBrand}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={1}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle={'Promoted ' + this.props.state.unitsvar + ' Share'}
                                                                yAxisType='percent'
                                                                selectedBrands={this.props.state.selectedBrands}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                metadata={this.props.state.chartmetadata_multiplebrands}
                                                            />
                                                        </div>
                                                    :
                                                    this.props.state.sharemetricsnonpromoted ?
                                                        this.props.state.indexSwitchCheckDollars ?
                                                            <div id="nonpromoshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                                <WeeklyStackedBarMultipleSeries
                                                                    chart_title={'Total ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                                    chart_title_info={this.props.state.chart_title_info}
                                                                    seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                                        return {
                                                                            name: brand,
                                                                            type: 'column',
                                                                            stack: 'one',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['nonpromoteddollars']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['nonpromoteddollars'])
                                                                            //]),
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                                ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['nonpromoteddollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['nonpromoteddollars'])
                                                                            ]),
                                                                            zIndex: 0
                                                                        }
                                                                    })}
                                                                    unitsvar={this.props.state.unitsvar}
                                                                    distinctdates={this.props.state.distinctdates}
                                                                    weekly_data={this.props.state.weekly_data}
                                                                    selectedBrand={this.props.state.selectedBrand}
                                                                    showDataTable={this.props.state.showDataTable}
                                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                                    weekagg={this.props.state.filter_object.numweeks}
                                                                    height={1}
                                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                                    yAxisTitle={'Non-Promoted ' + this.props.state.currencyType + ' Share'}
                                                                    yAxisType='percent'
                                                                    selectedBrands={this.props.state.selectedBrands}
                                                                    aggperiod={this.props.state.datasettimeagg}
                                                                    currencycode={this.props.state.currencycode}
                                                                    currencytype={this.props.state.currencyType}
                                                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                                                />
                                                            </div>
                                                            :
                                                            <div id="nonpromounitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                                <WeeklyStackedBarMultipleSeries
                                                                    chart_title={'Non-Promoted ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                                    chart_title_info={this.props.state.chart_title_info}
                                                                    seriesList={this.props.state.selectedBrands.map((brand, idx) => {
                                                                        return {
                                                                            name: brand,
                                                                            type: 'column',
                                                                            stack: 'one',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            //data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            //((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['nonpromotedunits']) / (((this.props.state.weekly_data_totalcat_new || {})[date] || {})['nonpromotedunits'])
                                                                            //]),
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                                ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['nonpromotedunits']) / ((((this.props.state.weekly_data || {})['Total Category'] || {}) [date] || {})['nonpromotedunits'])
                                                                            ]),

                                                                            zIndex: 0
                                                                        }
                                                                    })}
                                                                    unitsvar={this.props.state.unitsvar}
                                                                    distinctdates={this.props.state.distinctdates}
                                                                    weekly_data={this.props.state.weekly_data}
                                                                    selectedBrand={this.props.state.selectedBrand}
                                                                    showDataTable={this.props.state.showDataTable}
                                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                                    weekagg={this.props.state.filter_object.numweeks}
                                                                    height={1}
                                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                                    yAxisTitle={'Non-Promoted ' + this.props.state.unitsvar + ' Share'}
                                                                    yAxisType='percent'
                                                                    selectedBrands={this.props.state.selectedBrands}
                                                                    aggperiod={this.props.state.datasettimeagg}
                                                                    currencycode={this.props.state.currencycode}
                                                                    currencytype={this.props.state.currencyType}
                                                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                                                />
                                                            </div>
                                                        :
                                                        <div />
                                    }

                                </div>
                                :
                                <div id="" className="row" style={{ width: "100%", height: "100%" }}>
                                    {this.props.state.sharemetricstotal ?
                                        this.props.state.indexSwitchCheckDollars ?
                                            <div id="unitsshareweekdollarsitem" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={'Total ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info}
                                                    seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                        return {
                                                            name: item,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            (((this.props.state.item_data_weekly[item] || {})[date] || {})['totaldollars']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['totaldollars'])
                                                            ]),
                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.item_data_weekly}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={'Total ' + this.props.state.currencyType + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedItems}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                            :
                                            <div id="unitsshareweekitem" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyStackedBarMultipleSeries
                                                    chart_title={'Total ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                    chart_title_info={this.props.state.chart_title_info}
                                                    seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                        return {
                                                            name: item,
                                                            type: 'column',
                                                            stack: 'one',
                                                            color: Constant.graph_colors[idx],
                                                            yAxis: 0,
                                                            tooltip: {
                                                                pointFormatter: function () {
                                                                    let dt = new Date(this.x)
                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                    let date = new Date(this.x + offset)
                                                                    if (aggtype === 'Monthly') {
                                                                        return '<span>Month: '
                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                    else {
                                                                        return '<span>Week Ending: '
                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                            + '<br>'
                                                                            + this.series.name
                                                                            + '<br> '
                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            + '</span>'
                                                                    }
                                                                }
                                                            },
                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                            (((this.props.state.item_data_weekly[item] || {})[date] || {})['totalunits']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['totalunits'])
                                                            ]),
                                                            zIndex: 0
                                                        }
                                                    })}
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.item_data_weekly}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={1}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle={'Total ' + this.props.state.unitsvar + ' Share'}
                                                    yAxisType='percent'
                                                    selectedBrands={this.props.state.selectedItems}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                        :
                                        this.props.state.sharemetricsbase ?
                                            this.props.state.indexSwitchCheckDollars ?
                                                <div id="baseshareweekdollarsitem" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={'Base ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info}
                                                        seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                            return {
                                                                name: item,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                (((this.props.state.item_data_weekly[item] || {})[date] || {})['basedollars']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['basedollars'])
                                                                ]),
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.item_data_weekly}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={1}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={'Base ' + this.props.state.currencyType + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedItems}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                                :
                                                <div id="baseunitsshareweekitem" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyStackedBarMultipleSeries
                                                        chart_title={'Base ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                        chart_title_info={this.props.state.chart_title_info}
                                                        seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                            return {
                                                                name: item,
                                                                type: 'column',
                                                                stack: 'one',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                (((this.props.state.item_data_weekly[item] || {})[date] || {})['baseunits']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['baseunits'])
                                                                ]),
                                                                zIndex: 0
                                                            }
                                                        })}
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.item_data_weekly}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={1}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle={'Base ' + this.props.state.unitsvar + ' Share'}
                                                        yAxisType='percent'
                                                        selectedBrands={this.props.state.selectedItems}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                            :
                                            this.props.state.sharemetricsincremental ?
                                                this.props.state.indexSwitchCheckDollars ?
                                                    <div id="incshareweekdollarsitem" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyStackedBarMultipleSeries
                                                            chart_title={'Incremental ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                            chart_title_info={this.props.state.chart_title_info}
                                                            seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                                return {
                                                                    name: item,
                                                                    type: 'column',
                                                                    stack: 'one',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((this.props.state.item_data_weekly[item] || {})[date] || {})['incrementaldollars']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['incrementaldollars'])
                                                                    ]),
                                                                    zIndex: 0
                                                                }
                                                            })}
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.item_data_weekly}
                                                            selectedBrand={this.props.state.selectedBrand}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={1}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle={'Incremental ' + this.props.state.currencyType + ' Share'}
                                                            yAxisType='percent'
                                                            selectedBrands={this.props.state.selectedItems}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            metadata={this.props.state.chartmetadata}
                                                        />
                                                    </div>
                                                    :
                                                    <div id="incunitsshareweekitem" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyStackedBarMultipleSeries
                                                            chart_title={'Incremental ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                            chart_title_info={this.props.state.chart_title_info}
                                                            seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                                return {
                                                                    name: item,
                                                                    type: 'column',
                                                                    stack: 'one',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((this.props.state.item_data_weekly[item] || {})[date] || {})['incrementalunits']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['incrementalunits'])
                                                                    ]),
                                                                    zIndex: 0
                                                                }
                                                            })}
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.item_data_weekly}
                                                            selectedBrand={this.props.state.selectedBrand}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={1}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle={'Incremental ' + this.props.state.unitsvar + ' Share'}
                                                            yAxisType='percent'
                                                            selectedBrands={this.props.state.selectedItems}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            metadata={this.props.state.chartmetadata}
                                                        />
                                                    </div>
                                                :
                                                this.props.state.sharemetricspromoted ?
                                                    this.props.state.indexSwitchCheckDollars ?
                                                        <div id="promoshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyStackedBarMultipleSeries
                                                                chart_title={'Promoted ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                                chart_title_info={this.props.state.chart_title_info}
                                                                seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                                    return {
                                                                        name: item,
                                                                        type: 'column',
                                                                        stack: 'one',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (((this.props.state.item_data_weekly[item] || {})[date] || {})['promoteddollars']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['promoteddollars'])
                                                                        ]),
                                                                        zIndex: 0
                                                                    }
                                                                })}
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.item_data_weekly}
                                                                selectedBrand={this.props.state.selectedBrand}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={1}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle={'Promoted ' + this.props.state.currencyType + ' Share'}
                                                                yAxisType='percent'
                                                                selectedBrands={this.props.state.selectedItems}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                metadata={this.props.state.chartmetadata}
                                                            />
                                                        </div>
                                                        :
                                                        <div id="promounitsshareweekitem" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyStackedBarMultipleSeries
                                                                chart_title={'Promoted ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                                chart_title_info={this.props.state.chart_title_info}
                                                                seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                                    return {
                                                                        name: item,
                                                                        type: 'column',
                                                                        stack: 'one',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (((this.props.state.item_data_weekly[item] || {})[date] || {})['promotedunits']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['promotedunits'])
                                                                        ]),
                                                                        zIndex: 0
                                                                    }
                                                                })}
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.item_data_weekly}
                                                                selectedBrand={this.props.state.selectedBrand}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={1}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle={'Promoted ' + this.props.state.unitsvar + ' Share'}
                                                                yAxisType='percent'
                                                                selectedBrands={this.props.state.selectedItems}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                metadata={this.props.state.chartmetadata}
                                                            />
                                                        </div>
                                                    :
                                                    this.props.state.sharemetricsnonpromoted ?
                                                        this.props.state.indexSwitchCheckDollars ?
                                                            <div id="nonpromoshareweekdollars" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                                <WeeklyStackedBarMultipleSeries
                                                                    chart_title={'Total ' + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                                                    chart_title_info={this.props.state.chart_title_info}
                                                                    seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                                        return {
                                                                            name: item,
                                                                            type: 'column',
                                                                            stack: 'one',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            (((this.props.state.item_data_weekly[item] || {})[date] || {})['nonpromoteddollars']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['nonpromoteddollars'])
                                                                            ]),
                                                                            zIndex: 0
                                                                        }
                                                                    })}
                                                                    unitsvar={this.props.state.unitsvar}
                                                                    distinctdates={this.props.state.distinctdates}
                                                                    weekly_data={this.props.state.item_data_weekly}
                                                                    selectedBrand={this.props.state.selectedBrand}
                                                                    showDataTable={this.props.state.showDataTable}
                                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                                    weekagg={this.props.state.filter_object.numweeks}
                                                                    height={1}
                                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                                    yAxisTitle={'Non-Promoted ' + this.props.state.currencyType + ' Share'}
                                                                    yAxisType='percent'
                                                                    selectedBrands={this.props.state.selectedItems}
                                                                    aggperiod={this.props.state.datasettimeagg}
                                                                    currencycode={this.props.state.currencycode}
                                                                    currencytype={this.props.state.currencyType}
                                                                    metadata={this.props.state.chartmetadata}
                                                                />
                                                            </div>
                                                            :
                                                            <div id="nonpromounitsshareweek" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                                                <WeeklyStackedBarMultipleSeries
                                                                    chart_title={'Non-Promoted ' + this.props.state.unitsvar + " Share by " + this.props.state.xAxisText}
                                                                    chart_title_info={this.props.state.chart_title_info}
                                                                    seriesList={this.props.state.selectedItems.map((item, idx) => {
                                                                        return {
                                                                            name: item,
                                                                            type: 'column',
                                                                            stack: 'one',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            (((this.props.state.item_data_weekly[item] || {})[date] || {})['nonpromotedunits']) / (((this.props.state.item_data_weekly['Total Category'] || {})[date] || {})['nonpromotedunits'])
                                                                            ]),
                                                                            zIndex: 0
                                                                        }
                                                                    })}
                                                                    unitsvar={this.props.state.unitsvar}
                                                                    distinctdates={this.props.state.distinctdates}
                                                                    weekly_data={this.props.state.item_data_weekly}
                                                                    selectedBrand={this.props.state.selectedBrand}
                                                                    showDataTable={this.props.state.showDataTable}
                                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                                    weekagg={this.props.state.filter_object.numweeks}
                                                                    height={1}
                                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                                    yAxisTitle={'Non-Promoted ' + this.props.state.unitsvar + ' Share'}
                                                                    yAxisType='percent'
                                                                    selectedBrands={this.props.state.selectedItems}
                                                                    aggperiod={this.props.state.datasettimeagg}
                                                                    currencycode={this.props.state.currencycode}
                                                                    currencytype={this.props.state.currencyType}
                                                                    metadata={this.props.state.chartmetadata}
                                                                />
                                                            </div>
                                                        :
                                                        <div />
                                    }

                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}