import { reducer } from '../../Utilities/Reducer'
export default function changeMetricsIndexedWeeks(selectedOptions) {
    let metric = selectedOptions.value
    let currentdata = this.state.outlet_data_weekly
    let currentdates = this.state.distinctdates
    let numweeks = currentdates.length
    let outlets = this.state.all_outlets
    let allweeksdata = this.state.weekly_data['Total Selection']

    if (metric === 'Total Units') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['totalunits']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['totalunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'units' } })
    }

    else if (metric === 'Total Dollars') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['totaldollars']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['totaldollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'currency' } })
    }

    else if (metric === 'Total Units Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['totalunits']
            }).reduce(reducer)
        })

        let totalunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / totalunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['totalunits'] / allweeksdata[date]['totalunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Total Dollars Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['totaldollars']
            }).reduce(reducer)
        })

        let totalunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / totalunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['totaldollars'] / allweeksdata[date]['totaldollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Base Units') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['baseunits']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['baseunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'units' } })
    }

    else if (metric === 'Base Dollars') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['basedollars']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['basedollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'currency' } })
    }

    else if (metric === 'Base Units Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['baseunits']
            }).reduce(reducer)
        })

        let baseunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / baseunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['baseunits'] / allweeksdata[date]['baseunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Base Dollars Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['basedollars']
            }).reduce(reducer)
        })

        let baseunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / baseunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['basedollars'] / allweeksdata[date]['basedollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Incremental Units') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['incrementalunits']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['incrementalunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'units' } })
    }

    else if (metric === 'Incremental Dollars') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['incrementaldollars']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['incrementaldollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'currency' } })
    }

    else if (metric === 'Incremental Units Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['incrementalunits']
            }).reduce(reducer)
        })

        let incrementalunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / incrementalunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['incrementalunits'] / allweeksdata[date]['incrementalunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Incremental Dollars Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['incrementaldollars']
            }).reduce(reducer)
        })

        let incrementalunits = current_values.reduce(reducer)
        let indices = current_values.map(value => { return value / incrementalunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['incrementaldollars'] / allweeksdata[date]['incrementaldollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Promoted Units') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['promotedunits']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['promotedunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'units' } })
    }

    else if (metric === 'Promoted Dollars') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['promoteddollars']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['promoteddollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'currency' } })
    }

    else if (metric === 'Promoted Units Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['promotedunits']
            }).reduce(reducer)
        })

        let promotedunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / promotedunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['promotedunits'] / allweeksdata[date]['promotedunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Promoted Dollars Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['promoteddollars']
            }).reduce(reducer)
        })

        let promotedunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / promotedunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['promoteddollars'] / allweeksdata[date]['promoteddollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Non-Promoted Units') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['nonpromotedunits']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['nonpromotedunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'units' } })
    }

    else if (metric === 'Non-Promoted Dollars') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['nonpromoteddollars']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['nonpromoteddollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'currency' } })
    }

    else if (metric === 'Non-Promoted Units Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['nonpromotedunits']
            }).reduce(reducer)
        })

        let nonpromotedunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / nonpromotedunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['nonpromotedunits'] / allweeksdata[date]['nonpromotedunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Non-Promoted Dollars Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['nonpromoteddollars']
            }).reduce(reducer)
        })

        let nonpromotedunits = current_values.reduce(reducer)

        let indices = current_values.map(value => { return value / nonpromotedunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['nonpromoteddollars'] / allweeksdata[date]['nonpromoteddollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Subsidized Base Units') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['subsidizedbaseunits']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['subsidizedbaseunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'units' } })
    }

    else if (metric === 'Subsidized Base Dollars') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['subsidizedbasedollars']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['subsidizedbasedollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'currency' } })
    }

    else if (metric === 'Subsidized Base Units Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['subsidizedbaseunits']
            }).reduce(reducer)
        })

        let subsidizedbaseunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / subsidizedbaseunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['subsidizedbaseunits'] / allweeksdata[date]['subsidizedbaseunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Subsidized Base Dollars Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['subsidizedbasedollars']
            }).reduce(reducer)
        })

        let subsidizedbaseunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / subsidizedbaseunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['subsidizedbasedollars'] / allweeksdata[date]['subsidizedbasedollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Unsubsidized Base Units') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['unsubsidizedbaseunits']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['unsubsidizedbaseunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'units' } })
    }

    else if (metric === 'Unsubsidized Base Dollars') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['unsubsidizedbasedollars']
            }).reduce(reducer)
        })
        let indices = current_values.map(value => { return value / numweeks })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['unsubsidizedbasedollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'currency' } })
    }

    else if (metric === 'Unsubsidized Base Units Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['unsubsidizedbaseunits']
            }).reduce(reducer)
        })

        let unsubsidizedbaseunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / unsubsidizedbaseunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['unsubsidizedbaseunits'] / allweeksdata[date]['unsubsidizedbaseunits']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }

    else if (metric === 'Unsubsidized Base Dollars Share') {
        let current_values = outlets.map(outlet => {
            return currentdates.map(date => {
                return currentdata[outlet][date]['unsubsidizedbasedollars']
            }).reduce(reducer)
        })

        let unsubsidizedbaseunits = current_values.reduce(reducer)


        let indices = current_values.map(value => { return value / unsubsidizedbaseunits })
        let weekindices = outlets.map((outlet, idx) => {
            return currentdates.map(date => {
                let value = currentdata[outlet][date]['unsubsidizedbasedollars'] / allweeksdata[date]['unsubsidizedbasedollars']
                return { 'date': date, 'value': value, 'index': value / indices[idx] }
            })
        })
        this.setState({ weekindices, indices, metricsforindexviews_selected: { 'metric': metric, 'metrictype': 'percent' } })
    }



}