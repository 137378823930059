import React, { Component, Fragment } from 'react';
import TableChartRoundedIcon from '@material-ui/icons/TableChartRounded';
import IconButton from '@material-ui/core/IconButton';

export class ShowTableIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <IconButton
                    className="btn btn-primary ChangeDataTableButton"
                    onClick={this.props.changeShowDataTable}
                    style={{padding: '5px', margin: '5px'}}
                    id='SourceOfVolume'
                >
                    <TableChartRoundedIcon />
                </IconButton>
            </Fragment>
        )
    }
}