export default function changeSelectedBrandMulti(selectedOptions, position) {
    //Set yMax
    var avgbps = []
    var avgasps = []
    var avgpromos = []
    var avgnonpromos = []
    if (selectedOptions && selectedOptions.length > 0) {

        //Check and see if 'Select All' is in selectedOptions....
        var brandstouse = []
        if (selectedOptions.map(option=>option.value).includes('Select All')) {
            brandstouse = this.state.brands_sorted.map(brand => { return { 'value': brand, 'label': brand } })
        }

        else {
            brandstouse = selectedOptions
        }

        (brandstouse.map(option => option.value)).forEach((brand, idx) => {
            
            (this.state.distinctdates).sort().map(date => {                
                //test the prices....if infinite, do not push
                let avgbp = (((((this.state.weekly_data || {})[brand] || {})[date] || {})['weightedbp'] / (((this.state.weekly_data || {})[brand] || {})[date] || {})['totalunits']) || 0)
                let avgasp = (((((this.state.weekly_data || {})[brand] || {})[date] || {})['weightedasp'] / (((this.state.weekly_data || {})[brand] || {})[date] || {})['totalunits']) || 0)
                let avgpromo = (((((this.state.weekly_data || {})[brand] || {})[date] || {})['promoteddollars'] / (((this.state.weekly_data || {})[brand] || {})[date] || {})['promotedunits']) || 0)
                let avgnonpromo = (((((this.state.weekly_data || {})[brand] || {})[date] || {})['nonpromoteddollars'] / (((this.state.weekly_data || {})[brand] || {})[date] || {})['nonpromotedunits']) || 0)

                if (isFinite(avgbp)) {
                    avgbps.push(avgbp)
                }

                if (isFinite(avgasp)) {
                    avgasps.push(avgasp)
                }

                if (isFinite(avgpromo)) {
                    avgpromos.push(avgpromo)
                }

                if (isFinite(avgnonpromo)) {
                    avgnonpromos.push(avgnonpromo)
                }
                //Map through the outlets as well
                this.state.outlets.forEach(outlet => {
                    let avgbp = ((this.state.outlet_data_weekly_brand[outlet][brand][date]['weightedbp'] / this.state.outlet_data_weekly_brand[outlet][brand][date]['totalunits']) || 0)
                    let avgasp = ((this.state.outlet_data_weekly_brand[outlet][brand][date]['weightedasp'] / this.state.outlet_data_weekly_brand[outlet][brand][date]['totalunits']) || 0)
                    let avgpromo = ((this.state.outlet_data_weekly_brand[outlet][brand][date]['promoteddollars'] / this.state.outlet_data_weekly_brand[outlet][brand][date]['promotedunits']) || 0)
                    let avgnonpromo = ((this.state.outlet_data_weekly_brand[outlet][brand][date]['nonpromoteddollars'] / this.state.outlet_data_weekly_brand[outlet][brand][date]['nonpromotedunits']) || 0)

                    if (isFinite(avgbp)) {
                        avgbps.push(avgbp)
                    }

                    if (isFinite(avgasp)) {
                        avgasps.push(avgasp)
                    }

                    if (isFinite(avgpromo)) {
                        avgpromos.push(avgpromo)
                    }

                    if (isFinite(avgnonpromo)) {
                        avgnonpromos.push(avgnonpromo)
                    }
                })
            })
        })
        var ymaxs = [Math.max(...avgbps), Math.max(...avgasps), Math.max(...avgpromos), Math.max(...avgnonpromos)]
        var ymax = Math.max(...ymaxs)

        var chartmetadata_multiplebrands = this.state.chartmetadata_multiplebrands
        let brandsselected = selectedOptions.map(option => option.value)
        chartmetadata_multiplebrands['brands'] = brandsselected.join(', ')
        this.setState({ selectedBrands: brandstouse.map(option => option.value), showSnackbar_changebrands: true, yMax: ymax, chartmetadata_multiplebrands })
        //if selectedLevel === 'Brand', change ALRvsROM categories
        if (this.state.selectedLevel.value === 'Brand') {
            var selectedBrands_fromOptions
            if (brandsselected.includes('Select All')){
                selectedBrands_fromOptions = this.state.brands_sorted
            }
            else {
                selectedBrands_fromOptions = selectedOptions.map(option => option.value)                
            }
            this.setState({
                ALRvsROMCategories: selectedBrands_fromOptions
            })
        }
    }
    else {
        this.setState({
            selectedBrands: [], yMax: 0
        })
    }
}