import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// This file is used to provide a standard pop-up for any API request errors caught in ApiMethods/EntityCrud.js.
// To use it, do the following:
//
// 0) Make sure the page invokes "account/isauthenticated" and sets the role in the page state as "role"
//
// 1) Import this as well as EntityCrud.js in the main JS file for the page
//        import { entityCrud } from './ApiMethods/EntityCrud'
//        import { ApiErrorDialog, openApiErrorDialog, closeApiErrorDialog, on_api_entity_crud_error } from './ApiMethods/ApiErrorDialog'
//
// 2) In the constructor in the main JS file for the page in "this.state = {" add the following items:
//        api_error_dialog: false,
//        api_error_details: null,
//        api_error_count: 0,
//
// 3) In componentDidMount() in the main JS file for the page add:
//        this.entityCrud = entityCrud.bind(this)
//        this.openApiErrorDialog = openApiErrorDialog.bind(this)
//        this.closeApiErrorDialog = closeApiErrorDialog.bind(this)
//        this.on_api_entity_crud_error = on_api_entity_crud_error.bind(this)
//
// 4) In render() in the main JS file for the page add the following:
//        {/* API error pop-up */}
//        <ApiErrorDialog
//            state={this.state}
//            closeApiErrorDialog={this.closeApiErrorDialog}
//        />
//
// 5) For any instance of this.entityCrud where you want to have this popup appear, pass as the last argument:
//        this.on_api_entity_crud_error

export function openApiErrorDialog(error_count, error_values) {
    this.setState({ api_error_dialog: true, api_error_details: error_values, api_error_count: error_count })
}

export function closeApiErrorDialog() {
    this.setState({ api_error_dialog: false })
}

export function on_api_entity_crud_error(crud_description, error_list) {
    // error_list is set in EntityCrud.js and contains a list of objects each with the following properties:
    // * index - the index of the failure in the list of requests
    // * len - total number of requests in the list of requests
    // * promise_status - "fulfilled" if we got an error from the Middleware or API services, but if the request failed in
    //                     the browser you might see something different.
    // * request - the request object entry passed into this.entityCrud() that failed
    // * response_json - the full response, if JSON, or null if no response or response isn't JSON
    // * status - the HTTP response status
    // * status_message - The status message from the API, usually "Error"
    // * error_message - The API error message explaining what went wrong
    // * error_message_list - An optional list of strings explaining what went wrong
    // * error_entity_list - An optional list of JSON entities explaining what went wrong
    // The last four items are all undefined if the response is not JSON, if there is no response, or if they are not present in the JSON response
    var complaints = [{ line: error_list.length + ' error(s) occurred getting ' + crud_description }]
    error_list.forEach(item => {
        let index_str = 'Request #' + (item.index + 1) + '/' + item.len
        if (item.promise_status === "fulfilled") {
            let batch_index_str = ((item.batch_index !== undefined) ? ' (Batch #' + (item.batch_index + 1) + '/' + item.batch_len + ')' : '')
            complaints.push({
                line: index_str + batch_index_str + ' failed with ' + item.status_message + ' - ' + ' HTTP ' + item.status,
                error_message: item.error_message,
                error_message_list: item.error_message_list,
                error_entity_list: item.error_entity_list
            })
        } else {
            complaints.push({
                line: index_str + ' failed in the browser',
                error_message: undefined,
                error_message_list: undefined,
                error_entity_list: undefined
            })
        }
    })
    this.openApiErrorDialog(error_list.length, complaints)
}

export class ApiErrorDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    open={this.props.state.api_error_dialog}
                    onClose={this.props.closeApiErrorDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ overlay: { zIndex: 1000 } }}
                >
                    <DialogTitle id="alert-dialog-title">{this.props.state.api_error_count} API Error(s) occurred</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.state.api_error_dialog &&
                                this.props.state.api_error_details.map(item => {
                                    let items = []
                                    items.push(<span>{item.line}</span>)
                                    // Only give detailed information for Power Users and Admins
                                    if (this.props.state.role !== 'User') {
                                        if (item.error_message !== undefined) {
                                            items.push(<span><br />{item.error_message}</span>)
                                        }
                                        if (item.error_message_list !== undefined) {
                                            items.push(<ul>{item.error_message_list.map(line => <li>{line}</li>)}</ul>)
                                        }
                                        if (item.error_entity_list !== undefined) {
                                            item.error_entity_list.forEach(it => {
                                                // There will only be one key in the map for "it"
                                                items.push(...Object.entries(it).map(([failed_entity, failure_information]) => {
                                                    // There are multiple keys in "failure_information"
                                                    let x = Object.entries(failure_information).map(([key, value]) => {
                                                        return <li>{key} = {value}</li>
                                                    })
                                                    return <span><br />{failed_entity}: <ul>{x}</ul></span>
                                                }))
                                            })
                                        }
                                    }

                                    return <div>{items}<br /><br /></div>
                                })
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.props.closeApiErrorDialog} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}
