import React, { Component, Fragment } from 'react'; 
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as Constant from '../../constants';

export class Snackbars extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    render() {

        return (
            <Fragment>
                <Constant.NPDSnackbar
                    open={this.props.state.showSnackbar_changebrands}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={this.props.closeSnackBar_changebrands}
                >
                    <Constant.NPDSnackbarContent
                        message="Visuals Updated"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.props.closeSnackBar_changebrands}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContent>
                </Constant.NPDSnackbar>

                <Constant.NPDSnackbar
                    open={this.props.state.showSnackbar_datafiltered}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={this.props.closeSnackBar_datafiltered}
                >
                    <Constant.NPDSnackbarContent
                        message="Data Filtered"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.props.closeSnackBar_datafiltered}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContent>
                </Constant.NPDSnackbar>
            </Fragment>
        )
    }
}