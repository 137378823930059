import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateItemData(filters) {
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let filters_for_pull = assignFilterLevels(filters, ['proddesc'])
    let url_for_pull = Constant.baseapiurl + "ReportData/Filtered/Level1Data/" + reportid + extraparam

    // Perform the Pull
    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_for_pull, 'method': 'POST', 'body': filters_for_pull }
    ], 'UpdateItemData.js updateItemData()', 'Item')
    let aggregated_data = my_results['ReturnList'][0]
    let agg_categories_dollars = aggregated_data['level1_ordered_dollars'] || []
    let agg_categories_units = aggregated_data['level1_ordered_units'] || []
    let agg_item_data = aggregated_data['level1_data'] || {}

    let TotalBarLookup_Item = {}
    let TotalBarLookup_Dollars_Item = {}

    let items_sorted
    if (this.state.indexSwitchCheckDollars) {
        items_sorted = agg_categories_dollars
    } else {
        items_sorted = agg_categories_units
    }

    let items_sorted_dollars = agg_categories_dollars

    items_sorted.forEach(item => {
        TotalBarLookup_Item[item] = (agg_item_data[item] || {})['totalunits']
    })

    items_sorted.forEach(item => {
        TotalBarLookup_Dollars_Item[item] = (agg_item_data[item] || {})['totaldollars']
    })

    let selectedItems = items_sorted.slice(0, 10)

    // SetState
    console.log('Aggregated Data State Update')
    this.setState({
        selectedItems,
        items_sorted,
        items_sorted_dollars,
        TotalBarLookup_Item,
        TotalBarLookup_Dollars_Item
    })

    // Return a completion...
    return 'Completed'
}
