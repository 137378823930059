 

const dropdownStyles = {
    control: (styles, state) =>
    ({
        ...styles,
        backgroundColor: 'white',
        maxHeight: "225px",
        overflowY: "auto",
        borderColor: '#4e106f',
        border: state.isFocused ? '1px solid #4e106f' : '1px solid #000000',
        boxShadow: state.isFocused ? "0px 0px 6px #4e106f" : "none",
        "&:hover": {
            border: "1px solid #4e106f",
            boxShadow: "0px 0px 6px #4e106f"
        }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) =>
    {
        const color = "#d3c3db";
        return { ...styles, borderColor: '#4e106f', backgroundColor: isDisabled ? undefined : isSelected ? color : isFocused ? color: undefined, ':active': { ...styles[':active'], backgroundColor: !isDisabled ? isSelected ?  color : color : undefined, }, };
    },
};

export default dropdownStyles

