import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../UIElements/Switches/UnitsDollarSwitch'
import { BrandChannelSwitch } from '../../UIElements/Switches/BrandChannelSwitch'
import { LastYearSwitch } from '../../UIElements/Switches/LastYearSwitch'
import { MarketShareSwitch } from '../../UIElements/Switches/MarketShareSwitch'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
import { MultiGeoSelect } from '../../UIElements/Dropdowns/MultiGeoSelect'
import { ShareMetricRadio } from '../../UIElements/Radios/ShareMetricRadio'
import { MetricsChangesGraph } from '../../../Graphs/MetricsChangesGraph'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';

export class ALRvsROM_MetricChanges extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    {this.props.state.channelviews ?
                        <div align='left' className="row col-sm-12" style={{ padding: '25px' }} >
                            <MultiGeoSelect
                                state={this.props.state}
                                changeSelectedChannelMulti={this.props.changeSelectedChannelMulti}
                            />
                        </div>
                        :
                        <div align='left' className="row col-sm-12" style={{ padding: '25px' }} >
                            <MultiBrandSelect
                                state={this.props.state}
                                changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                            />
                        </div>
                    }
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div className="row">
                    <div align='left' className="col-sm-12" style={{ padding: '5px' }} >
                        <ShareMetricRadio
                            state={this.props.state}
                            handleChangeShareMetrics={this.props.handleChangeShareMetrics}
                        />
                    </div>
                    <div align='left' className="col-sm-12 row" style={{ padding: '5px', margin: '10px', minWidth: '1000px'  }} >
                        <div align='left' className="row col-sm-2" style={{ padding: '5px'}} >
                            <UnitsDollarSwitch
                                state={this.props.state}
                                handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                            />
                        </div>
                        <div align='left' className="col-sm-2" style={{ padding: '5px' }} >
                            <BrandChannelSwitch
                                state={this.props.state}
                                handleChange_channelviews={this.props.handleChange_channelviews}
                            />
                        </div>
                        <div align='left' className="col-sm-5" style={{ padding: '5px' }} >
                            <LastYearSwitch
                                state={this.props.state}
                                handleChange_twoyago={this.props.handleChange_twoyago}
                            /> 
                        </div>
                        <div align='left' className="col-sm-3" style={{ padding: '5px' }} >
                            <MarketShareSwitch
                                state={this.props.state}
                                handleChange_marketshare={this.props.handleChange_marketshare}
                                label1='Absolute'
                                label2='Market Share'
                            />
                        </div>
                    </div>
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <MetricsChangesGraph
                            state={this.props.state}
                        />
                    </div>
                </div>
            </div>
        )
    }
}