import * as Constant from '../../../constants'
export default async function setfilters(selectedOptions, position, exclude_array = [this.state.exclude_brands, this.state.exclude_items], filterPause = this.state.filterPause, attrsent = null) {

    var filter_object = this.state.filter_object
    var selected_attr_groups = this.state.selected_attr_groups || {}
    var reportbrands = this.state.reportbrands
    var reportcategories = this.state.reportcategories

    console.log(filter_object)

    if (selectedOptions && selectedOptions.length > 0) {
        console.log('SetFilters: ' + position + '-' + selectedOptions[selectedOptions.length - 1].value + '-' + selectedOptions[selectedOptions.length - 1].label)
    }
    else {
        console.log('SetFilters: ' + position + '- All selected')
    }

    //Geo
    if (position === 'geo') {
        filter_object['geo'] = selectedOptions['value']
    }
    //Brands
    else if (position === 'brands') {
        if (selectedOptions && selectedOptions.length > 0) {
            let brands = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                brands = ['All']
            }
            else {
                selectedOptions.forEach(brand => {
                    if (brand.value !== 'All') {
                        brands.push(brand.value)
                    }
                })
            }

            filter_object['brands'] = brands
        }
        else {
            filter_object['brands'] = ["All"]
        }
    }
    //Items
    else if (position === 'items') {
        if (selectedOptions && selectedOptions.length > 0) {
            let items = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                items = ['All']
            }
            else {
                selectedOptions.forEach(item => {
                    if (item.value !== 'All') {
                        items.push(item.value)
                    }
                })
            }

            filter_object['items'] = items
        }
        else {
            filter_object['items'] = ["All"]
        }
    }
    //startdate
    else if (position === 'startdate') {
        filter_object['startdate'] = selectedOptions
    }

    //startdate
    else if (position === 'enddate') {
        filter_object['enddate'] = selectedOptions
    }

    else if (position === 'daterangecalc') {
        filter_object['startdate'] = selectedOptions[0]
        filter_object['enddate'] = selectedOptions[1]
    }

    //asprange
    else if (position === 'asprange') {
        filter_object['asp_range'] = selectedOptions
    }

    else if (position === 'priceband') {
        if (selectedOptions && selectedOptions.length > 0) {
            filter_object['pricebands'] = selectedOptions
        }
        else {
            filter_object['pricebands'] = []
        }
    }
    //numweeks for aggregation
    else if (position === 'numweeks') {

        var weekagg = selectedOptions['value']

        if (selectedOptions['value'] === 'YTD') {
            //Calculate Week from data
            function getFirstWeek(enddate) {
                //Use the date to find the year
                var year = enddate.getFullYear()
                var startingdate = new Date(year, 0, 1)
                var enddate_dow = enddate.getDay()
                var target_dow = (enddate_dow + 1) % 7

                //from beginning date, loop through date until we get the target_dow
                var firstDate
                while (!firstDate) {
                    //console.log(startingdate)
                    let startingdate_dow = startingdate.getDay()
                    if (startingdate_dow === target_dow) {
                        firstDate = new Date((new Date(startingdate)).setDate(startingdate.getDate() + 6))
                    }
                    else {
                        startingdate = new Date((new Date(startingdate)).setDate(startingdate.getDate() + 1))
                    }
                }

                return firstDate

            }
            let lastDate = new Date(this.state.times_data[1])
            let firstDate = getFirstWeek(lastDate)
            //Calculate the number of days difference
            let diffTime = Math.abs(lastDate - firstDate)
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 7
            let numWeeks = Math.ceil(diffDays / 7)
            weekagg = numWeeks
        }

        filter_object['numweeks'] = weekagg
        filter_object['numweeks_raw'] = selectedOptions['value']
        
        var xDateFormatString = ""
        var weeklytimeperiods = ""
        var axisFormatString = ""
        var axisText = ""
        var timeperiodlabels = ""
        var stepNum = 1
        var paddingNum = 5
        var fontSizeString = '11px'
        if (this.state.datasettimeagg === 'Monthly') {
            timeperiodlabels = 'Month'
            if (weekagg !== 1) {
                xDateFormatString = weekagg + ' Month Period Ending: %Y-%m-%d'
                weeklytimeperiods = weekagg + " Month Time Periods"
                axisFormatString = '%e. %b'
                axisText = weekagg + ' Month Period'
            }

            else {
                xDateFormatString = 'Month Ending: %Y-%m-%d'
                weeklytimeperiods = "Month"
                axisFormatString = '%b %Y'
                axisText = 'Month'
            }
        }
        else {
            timeperiodlabels = 'Week'
            if (weekagg !== 1) {
                xDateFormatString = weekagg + ' Week Period Ending: %Y-%m-%d'
                weeklytimeperiods = weekagg + " Week Time Periods"
                axisFormatString = '%b %Y'
                axisText = weekagg + ' Week Period'
            }

            else {
                xDateFormatString = 'Week Ending: %Y-%m-%d'
                weeklytimeperiods = "Week"
                axisFormatString = '%b %Y'
                axisText = 'Week Ending'
            }
        }

        this.setState({
            xAxisStepNum: stepNum,
            xAxisPadNum: paddingNum,
            xAxisFontSizeString: fontSizeString,
            xAxisText: axisText,
            timeperiodlabels: timeperiodlabels,
            xDateFormatString: xDateFormatString,
            xAxisLabelFormatString: axisFormatString,
            weeklytimeperiods: weeklytimeperiods
        })


    }

    else if (position === 'segment1') {
        if (selectedOptions && selectedOptions.length > 0) {
            let segment1s = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                segment1s = ['All']
            }
            else {
                selectedOptions.forEach(segment => {
                    if (segment.value !== 'All') {
                        segment1s.push(segment.value)
                    }
                })
            }

            filter_object['segment1'] = segment1s
        }
        else {
            filter_object['segment1'] = ["All"]
        }
    }

    else if (position === 'segment2') {
        if (selectedOptions && selectedOptions.length > 0) {
            let segment2s = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                segment2s = ['All']
            }
            else {
                selectedOptions.forEach(segment => {
                    if (segment.value !== 'All') {
                        segment2s.push(segment.value)
                    }
                })
            }

            filter_object['segment2'] = segment2s
        }
        else {
            filter_object['segment2'] = ["All"]
        }
    }

    else if (position === 'segment3') {
        if (selectedOptions && selectedOptions.length > 0) {
            let segment3s = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                segment3s = ['All']
            }
            else {
                selectedOptions.forEach(segment => {
                    if (segment.value !== 'All') {
                        segment3s.push(segment.value)
                    }
                })
            }

            filter_object['segment3'] = segment3s
        }
        else {
            filter_object['segment3'] = ["All"]
        }
    }

    else if (position === 'segment4') {
        if (selectedOptions && selectedOptions.length > 0) {
            let segment4s = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                segment4s = ['All']
            }
            else {
                selectedOptions.forEach(segment => {
                    if (segment.value !== 'All') {
                        segment4s.push(segment.value)
                    }
                })
            }

            filter_object['segment4'] = segment4s
        }
        else {
            filter_object['segment4'] = ["All"]
        }
    }

    else if (position === 'outlets') {
        if (selectedOptions && selectedOptions.length > 0) {
            let outlets = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                outlets = ['All']
            }
            else {
                selectedOptions.forEach(outlet => {
                    if (outlet.value !== 'All') {
                        outlets.push(outlet.value)
                    }
                })
            }

            filter_object['outlets'] = outlets
        }
        else {
            filter_object['outlets'] = ["All"]
        }
    }

    else if (position === 'channels') {
        if (selectedOptions && selectedOptions.length > 0) {
            let channels = []
            //if (selectedOptions.map(option => option.value).includes('All') && selectedOptions.length === 1) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                channels = ['All']
            }
            else {
                selectedOptions.forEach(channel => {
                    if (channel.value !== 'All') {
                        channels.push(channel.value)
                    }
                })
            }

            filter_object['channels'] = channels
        }
        else {
            filter_object['channels'] = ["All"]
        }
    }

    else if (position === 'attributegroup') {
        if (selectedOptions && selectedOptions.length > 0) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                filter_object['attributes'][attrsent] = ["All"];
                selected_attr_groups[attrsent] = [{ 'label': 'All', 'value': 'All' }]
            }
            else {
                let attrvalues = []
                let updatedSelectedOptions = []
                selectedOptions.forEach(attrvalue => {
                    if (attrvalue.value !== 'All') {
                        attrvalues = attrvalues.concat(attrvalue.value)
                        updatedSelectedOptions.push({ 'label': attrvalue.label, 'value': attrvalue.value })
                    }
                })
                filter_object['attributes'][attrsent] = attrvalues
                selected_attr_groups[attrsent] = updatedSelectedOptions //selectedOptions
            }
        }
        else {
            filter_object['attributes'][attrsent] = ["All"];            
            selected_attr_groups[attrsent] = [{ 'label': 'All', 'value': 'All' }]   
        }
    }
    
    //attributes
    else {
        if (selectedOptions && selectedOptions.length > 0) {
            if (selectedOptions.map(option => option.value).includes('All') && selectedOptions[selectedOptions.length - 1].value === 'All') {
                filter_object['attributes'][position] = ["All"]
                selected_attr_groups[position] = [{ 'label': 'All', 'value': 'All' }]
            }
            else {
                let attrvalues = []
                selectedOptions.forEach(attrvalue => {
                    console.log('SSSetFilters: ' + attrvalue.value)
                    if (attrvalue.value !== 'All') {
                        attrvalues.push(attrvalue.value)
                    }
                })
                filter_object['attributes'][position] = attrvalues

                //Perform a check here on the attribute values compared to the groups defined for that attribute...
                let customAttrGroups = this.state.customAttrGroups
                let customAttrGroups_thisattr = customAttrGroups[position] || []
                selected_attr_groups[position] = []

                let checker = (arr, target) => target.every(v => arr.includes(v));

                customAttrGroups_thisattr.forEach(group => {
                    //if attrvalues includes the whole group attr then set the selected_attr_groups as true
                    if (checker(attrvalues, group.attr_vals)) {
                        selected_attr_groups[position].push({ 'label': group.groupname, 'value': group.attr_vals })
                    }
                    //else do not push the group
                })
            }
        }
        else {
            filter_object['attributes'][position] = ["All"]
            selected_attr_groups[position] = [{ 'label': 'All', 'value': 'All' }]
        }
    }

    //Set title strings...

    var selectedstart = filter_object['startdate']
    var selectedend = filter_object['enddate']


    if (this.state.datasettimeagg === 'Monthly') {
        var alldates = [selectedstart]
        let monthnum = selectedstart.substring(5, 7)
        let yearnum = selectedstart.substring(0, 4)
        var newmonthnum = ''
        var newyearnum = ''
        if (monthnum === '12') {
            newmonthnum = '01'
            newyearnum = String(parseInt(yearnum) + 1)
        }
        else {
            newmonthnum = String(parseInt(monthnum) + 1)
            if (newmonthnum.length < 2) {
                newmonthnum = '0' + newmonthnum
            }
            newyearnum = yearnum
        }

        var newdate = newyearnum + '-' + newmonthnum + '-01'

        while (newdate <= selectedend) {
            alldates.push(newdate)
            let monthnum = newdate.substring(5, 7)
            let yearnum = newdate.substring(0, 4)
            var newmonthnum = ''
            var newyearnum = ''
            if (monthnum === '12') {
                newmonthnum = '01'
                newyearnum = String(parseInt(yearnum) + 1)
            }
            else {
                newmonthnum = String(parseInt(monthnum) + 1)
                if (newmonthnum.length < 2) {
                    newmonthnum = '0' + newmonthnum
                }
                newyearnum = yearnum
            }

            newdate = newyearnum + '-' + newmonthnum + '-01'
        }
    }
    else {
        var alldates = [selectedstart]
        let dateconv = new Date(selectedstart + 'T00:00:00Z')
        let newdate = new Date(dateconv.setTime(dateconv.getTime() + (7 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)

        //new Date(dateconv.setDate(dateconv.getDate() + 7)).toISOString().substring(0,10)

        while (newdate <= selectedend) {
            alldates.push(newdate)
            dateconv = new Date(newdate + 'T00:00:00Z')
            newdate = new Date(dateconv.setTime(dateconv.getTime() + (7 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)
        }
    }

    var numweeks = alldates.length


    //Excluding Brands...
    var brandsstring = ''
    var brandsstringforcaption = ''
    if (exclude_array[0]) {
        brandsstring = 'All Brands Excluding ' + filter_object['brands'].map(brand => this.state.brand_lookup_filters[brand]).join(', ')
        brandsstringforcaption = 'All Brands Excluding ' + filter_object['brands'].map(brand => this.state.brand_lookup_filters[brand]).join(', ')
    }
    else {
        if (filter_object['brands'].length === 1 && filter_object['brands'][0] === "All") {
            brandsstring = 'All Brands'
            brandsstringforcaption = this.state.initialbrands.map(brand => this.state.brand_lookup_filters[brand]).join(', ')
        }

        else {
            brandsstring = filter_object['brands'].map(brand => this.state.brand_lookup_filters[brand]).join(', ')
            brandsstringforcaption = filter_object['brands'].map(brand => this.state.brand_lookup_filters[brand]).join(', ')
            if (brandsstring.length > 100) {
                brandsstring = filter_object['brands'].length + ' of ' + reportbrands.length + ' Brands'
            }
        }
    }

    var showTotalCategoryLabel = false
    if (filter_object['brands'][0] === 'All' && filter_object['items'][0] === 'All' && filter_object['segment1'][0] === 'All' && filter_object['segment2'][0] === 'All' && filter_object['segment3'][0] === 'All' && filter_object['segment4'][0] === 'All') {
        showTotalCategoryLabel = true
    }

    this.setState({
          brandsstring
        , brandsstringforcaption
        , selected_attr_groups
        , showTotalCategoryLabel
    })
    

    //Save Filters
    let reportid = this.state.reportsettings.report_id
    let filtertosave = { filter_object, exclude_array }
    //Save the Filter using the API

    let url = Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid + '/pdtReportFilters'
    console.log(JSON.stringify(filtertosave));
    let settings_response = await fetch(url,
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filtertosave)
        })

    var settings_obj
    if (settings_response.status === 200) {
        settings_obj = await settings_response.json()
    }
    else {
        settings_obj = {}
        alert('There was an Error Saving the filters')
    }

    console.log(filter_object)

    //evaluaterows...
    if (filterPause === 'apply') {
        this.setState({ isLoaded: false, filterPause_reportViews: 'apply' }) //Add unpause slicers here...
        setTimeout(function () {
            this.evaluateRows_newAPIs_consolidated(filter_object, exclude_array, exclude_array)
        }.bind(this), 500);
    }

    else {
        this.setState({ isLoaded: true })
    }

}
