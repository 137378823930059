import * as Constant from '../constants'

export function handleCloseCompanyInfo() {
    this.setState({ showcompanyinfo: false })
}

export function handleCloseCompanyInfoNew() {
    this.setState({ showcompanyinfo_new: false })
}

export function handleCloseCompanyPerms() {
    this.setState({ showcompanyperms: false })
}

export function handleOpenNewCompany() {
    this.setState({
        companyinfodata: {},
        companyinfoName: null,
        companyinfoRetailer: null,
        companyinfoManufacturer: null,
        companyinfoPermsPDT: [],
        companyinfoPermsPNP: [],
        showcompanyinfo_new: true
    })
}

export function handleChangePageCompany(event, newPage, type) {
    if (type === 'pnpdatasetperms') {
        this.setState({
            page_company_pnp_dataset_perms: newPage,
            page_start_company_pnp_dataset_perms: newPage * this.state.rowsPerPage_company_pnp_dataset_perms
        })
    } else if (type === 'pdtdatasetperms') {
        this.setState({
            page_company_pdt_dataset_perms: newPage,
            page_start_company_pdt_dataset_perms: newPage * this.state.rowsPerPage_company_pdt_dataset_perms
        })
    }
}

export function handleChangeRowsPerPageCompany(event, type) {
    if (type === 'pnpdatasetperms') {
        this.setState({
            rowsPerPage_company_pnp_dataset_perms: parseInt(event.target.value, 10),
            page_company_pnp_dataset_perms: 0,
            page_start_company_pnp_dataset_perms: 0,
        })
    } else if (type === 'pdtdatasetperms') {
        this.setState({
            rowsPerPage_company_pdt_dataset_perms: parseInt(event.target.value, 10),
            page_company_pdt_dataset_perms: 0,
            page_start_company_pdt_dataset_perms: 0
        })
    }
}

export function handleChangeCompanyName(event, newValue) {
    var input = document.getElementById("companyinfoName")
    let value = input.value
    this.setState({ companyinfoName: value })
}

export async function handleSubmitCompany() {
    let companyid = this.state.companyinfodata.company_id
    console.log('Updating Company ID ' + companyid)

    this.setState({ company_edit_button_enabled: false })

    var companyinfo = {
        company_id: companyid,
        time_updated: this.state.companyinfodata.time_updated,
        company_name: this.state.companyinfoName,
        is_manufacturer: this.state.companyinfoManufacturer,
        is_retailer: this.state.companyinfoRetailer,
        pdt_permissions: this.state.companyinfodata.pdt_permissions,
        pnp_permissions: this.state.companyinfodata.pnp_permissions
    }

    let results = await this.entityCrud('updating company ID ' + companyid, [
        { 'url': Constant.baseapiurl_gen + 'Companies/' + companyid, 'method': 'PUT', 'body': companyinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchCompanyList()
        this.handleCloseCompanyInfo()
        alert('Changes Made Successfully')
    }

    this.setState({ company_edit_button_enabled: true })
}

export async function handleSubmitCompanyNew() {
    console.log('Creating new company')

    this.setState({ company_edit_button_enabled: false })

    var companyinfo = {
        company_name: this.state.companyinfoName,
        is_manufacturer: this.state.companyinfoManufacturer || false,
        is_retailer: this.state.companyinfoRetailer || false,
        pdt_permissions: [],
        pnp_permissions: []
    }

    let results = await this.entityCrud('creating new company', [
        { 'url': Constant.baseapiurl_gen + 'Companies', 'method': 'POST', 'body': companyinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchCompanyList()
        this.handleCloseCompanyInfoNew()
        alert('Changes Made Successfully')
    }

    this.setState({ company_edit_button_enabled: true })
}

export async function handleSubmitCompanyChangesPerms() {
    let companyid = this.state.companyinfodata.company_id
    console.log('Submitting permission change for company ID ' + companyid)

    this.setState({ company_edit_perm_button_enabled: false })

    var finalperms_pnp = []
    this.state.companyinfodata.pnp_permissions.forEach(perm => {
        // Read through perms and cleanup negative permids
        if (perm.permission_id < 0) {
            perm.permission_id = null
        }
        // Read through perms and cleanup emptystring timeupdated
        if (perm.time_updated === "") {
            perm.time_updated = null
        }
        // Cleanup geogids to make ints
        perm.geogids = perm.geogids.map(geogid => parseInt(geogid))
        // If shortdesc !== 'Add a Dataset' push to final perms
        if (perm.shortdesc !== 'Add a Dataset') {
            finalperms_pnp.push(perm)
        }
    })

    var finalperms_pdt = []
    this.state.companyinfodata.pdt_permissions.forEach(perm => {
        // Read through perms and cleanup negative permids
        if (perm.permission_id < 0) {
            perm.permission_id = null
        }
        // Read through perms and cleanup emptystring timeupdated
        if (perm.time_updated === "") {
            perm.time_updated = null
        }
        // Cleanup geogids to make ints
        perm.store_groups = perm.store_groups.map(storegroup => parseInt(storegroup))
        // If shortdesc !== 'Add a Dataset' push to final perms
        if (perm.short_desc !== 'Add a Dataset') {
            finalperms_pdt.push(perm)
        }
    })

    var companyinfo = {
        company_id: companyid,
        time_updated: this.state.companyinfodata.time_updated,
        company_name: this.state.companyinfoName,
        is_manufacturer: this.state.companyinfoManufacturer,
        is_retailer: this.state.companyinfoRetailer,
        pdt_permissions: finalperms_pdt,
        pnp_permissions: finalperms_pnp
    }

    let results = await this.entityCrud('changing permissions for company ' + companyid, [
        { 'url': Constant.baseapiurl_gen + 'Companies/' + companyid, 'method': 'PUT', 'body': companyinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchCompanyList()
        alert('Changes Made Successfully')
        this.handleCloseCompanyPerms()
    }

    this.setState({ company_edit_perm_button_enabled: true })
}

export async function handleDeleteCompany() {
    this.setState({ company_delete_button_enabled: false })
    var company_id = this.state.companyinfodata.company_id
    let results = await this.entityCrud('deleting company ' + company_id, [
        { 'url': Constant.baseapiurl_gen + 'Companies/' + company_id, 'method': 'DELETE', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchCompanyList()
        this.handleCloseCompanyInfo()
        alert('Company Deleted Successfully')
    }

    this.setState({ company_delete_button_enabled: true })
}

export function handleChangeCompanyPerms(selectedOptions, app, idx, perm) {
    let index = idx + ((app === 'pnp') ? this.state.page_start_company_pnp_dataset_perms : this.state.page_start_company_pdt_dataset_perms)
    let page = (app === 'pnp') ? this.state.page_company_pnp_dataset_perms : this.state.page_company_pdt_dataset_perms
    let common = 'For current company popup, setting ' + app + ' index #' + index + ' (page ' + page + ', idx ' + idx + ') '
    if (perm === 'dataset') {
        console.log(common + ' dataset ' + selectedOptions.label + ' (' + selectedOptions.value + ')')
    } else {
        console.log(common + ' geos ' + (selectedOptions || []).map(x => x.label + ' (' + x.value + ')').join(', '))
    }

    var permission_object
    if (app === 'pnp') {
        var permissionsPNP = this.state.companyinfoPermsPNP
        permission_object = this.state.companyinfoPermsPNP[index]

        if (perm === 'dataset') {
            // The user selected a dataset
            permission_object['geogids'] = [] // Remove Geos since we're selecting a new or different PNP dataset
            permission_object['dataset_id'] = selectedOptions.value
            permission_object['shortdesc'] = selectedOptions.label
        } else if (perm === 'geos') {
            // The user selected a geo
            permission_object['geogids'] = (selectedOptions || []).map(option => option.value)
        }

        permissionsPNP[index] = permission_object
        this.setState({ companyinfoPermsPNP: permissionsPNP })
    } else if (app === 'pdt') {
        var permissionsPDT = this.state.companyinfoPermsPDT
        permission_object = this.state.companyinfoPermsPDT[index]

        if (perm === 'dataset') {
            // The user selected a dataset
            permission_object['store_groups'] = [] // Remove store groups since we're selecting a new or different PDT dataset
            permission_object['dataset_id'] = selectedOptions.value
            permission_object['short_desc'] = selectedOptions.label
        } else if (perm === 'geos') {
            // The user selected a store group
            permission_object['store_groups'] = (selectedOptions || []).map(option => option.value)
        }

        permissionsPDT[index] = permission_object
        this.setState({ companyinfoPermsPDT: permissionsPDT })
    }
}

export function removeCompanyPermission(permissionid, app) {
    console.log('For current company popup, removing ' + app + ' permission # ' + permissionid)

    if (app === 'pnp') {
        let permissions = this.state.companyinfoPermsPNP
        permissions.every((permission, idx) => {
            if (permission.permission_id === permissionid) {
                console.log('Found permission entry to remove at index ' + idx + ':')
                permissions.splice(idx, 1)
                return false
            }
            return true
        })

        console.log('New permissions:')
        console.log(permissions)
        this.setState({ companyinfoPermsPNP: permissions })
    } else if (app === 'pdt') {
        let permissions = this.state.companyinfoPermsPDT
        permissions.every((permission, idx) => {
            if (permission.permission_id === permissionid) {
                console.log('Found permission entry to remove at index ' + idx + ':')
                permissions.splice(idx, 1)
                return false
            }
            return true
        })

        console.log('New permissions:')
        console.log(permissions)
        this.setState({ companyinfoPermsPDT: permissions })
    }
}

export function addNewCompanyPermission(app, company_id) {
    var addedPermissionsCounter = this.state.addedPermissionsCounter - 1

    if (app === 'pnp') {
        let permissions = this.state.companyinfoPermsPNP
        permissions.push(
            {
                'company_id': company_id,
                'shortdesc': 'Add a Dataset',
                'permission_id': this.state.addedPermissionsCounter,
                'dataset_id': null,
                'geogids': [],
                'time_updated': ''
            }
        )
        this.setState({ companyinfoPermsPNP: permissions, addedPermissionsCounter })
    } else if (app === 'pdt') {
        let permissions = this.state.companyinfoPermsPDT
        permissions.push(
            {
                'company_id': company_id,
                'short_desc': 'Add a Dataset',
                'permission_id': this.state.addedPermissionsCounter,
                'dataset_id': null,
                'store_groups': [],
                'time_updated': ''
            }
        )
        this.setState({ companyinfoPermsPDT: permissions, addedPermissionsCounter })
    }
}

export function handleChangeCompanyCheckboxRetailer(event, newValue) {
    this.setState({ companyinfoRetailer: (this.state.companyinfoRetailer) ? false : true })
}

export function handleChangeCompanyCheckboxManufacturer(event, newValue) {
    this.setState({ companyinfoManufacturer: (this.state.companyinfoManufacturer) ? false : true })
}

export function handleChangeUserInfoName(event, newValue) {
    var input = document.getElementById("userinfoName")
    let value = input.value
    this.setState({ userinfoName: value })
}

export function handleChangeUserInfoCompany(selectedOptions) {
    var comapnyid = selectedOptions['value']
    this.setState({ userinfoCompanyId: comapnyid })
}

export async function getCompanyInfo(company_id) {
    let results = await this.entityCrud('getting information for company ' + company_id, [
        { 'url': Constant.baseapiurl_gen + 'Companies/' + company_id, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var companyinfodata = results['ResultList'][0]
        this.setState({
            companyinfodata: companyinfodata,
            companyinfoName: companyinfodata.company_name,
            companyinfoRetailer: companyinfodata.is_retailer,
            companyinfoManufacturer: companyinfodata.is_manufacturer,
            showcompanyinfo: true
        })
    }
}

export async function getCompanyPerms(company_id) {
    let results = await this.entityCrud('getting permissions for company ' + company_id, [
        { 'url': Constant.baseapiurl_gen + 'Companies/' + company_id, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var companyinfodata = results['ResultList'][0]
        this.setState({
            companyinfodata: companyinfodata,
            companyinfoName: companyinfodata.company_name,
            companyinfoRetailer: companyinfodata.is_retailer,
            companyinfoManufacturer: companyinfodata.is_manufacturer,
            companyinfoPermsPDT: companyinfodata.pdt_permissions,
            companyinfoPermsPNP: companyinfodata.pnp_permissions,
            page_company_pnp_dataset_perms: 0,
            page_start_company_pnp_dataset_perms: 0,
            page_company_pdt_dataset_perms: 0,
            page_start_company_pdt_dataset_perms: 0,
            showcompanyperms: true
        })
    }
}

export async function fetchCompanyList() {
    console.log('Fetching list of all companies')

    let results = await this.entityCrud('getting the list of all companies', [
        { 'url': Constant.baseapiurl_gen + 'Companies', 'method': 'GET', 'body': null }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var companiesdata = results['ResultList'][0]
        var company_lookup = {}
        var company_options = []
        companiesdata.forEach(company => {
            company_lookup[company.company_id] = company.company_name
            company_options.push({ 'label': company.company_name, 'value': company.company_id })
        })

        this.setState({
            companiesdata: companiesdata,
            company_lookup: company_lookup,
            company_options: company_options
        })
    }
}
