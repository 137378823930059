import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateItemWeeklyData(filters, timeperiod) {
    console.log(filters)
    let filters_item_weekly = assignFilterLevels(filters, ['proddesc', 'rowdate'])
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level2selection = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level2selection, 'method': 'POST', 'body': filters_item_weekly }
    ], 'UpdateItemWeeklyData.js updateItemWeeklyData()', 'Item Weekly')
    let item_weeklydata_data = my_results['ReturnList'][0]
    let item_weeklydata_obj = item_weeklydata_data['level2_data'] || {}

    if (timeperiod === 'totaltimeperiod') {
        this.setState({ item_data_weekly: item_weeklydata_obj })
    } else if (timeperiod === 'threeyearsago') {
        this.setState({ item_data_weekly_threeyears: item_weeklydata_obj })
    } else if (timeperiod === 'twoyearsago') {
        this.setState({ item_data_weekly_last: item_weeklydata_obj })
    } else if (timeperiod === 'prioryear') {
        this.setState({ item_data_weekly_prior: item_weeklydata_obj })
    }
}
