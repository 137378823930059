import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateOutletChannelWeeklyData(filters, timeperiod) {
    let filters_outlet_geo_weekly = assignFilterLevels(filters, ['outlet', 'geogdesc', 'rowdate'])
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level3selection = Constant.baseapiurl + "ReportData/Filtered/Level3Data/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level3selection, 'method': 'POST', 'body': filters_outlet_geo_weekly }
    ], 'UpdateOutletWeeklyChannel.js updateOutletChannelWeeklyData()', 'Channel Weekly')
    let outlet_geo_weeklydata_data = my_results['ReturnList'][0]
    let outlet_geo_weeklydata_obj = outlet_geo_weeklydata_data['level3_data'] || {}

    if (timeperiod === 'totaltimeperiod') {
        this.setState({ outlet_data_weekly_geo: outlet_geo_weeklydata_obj })
    } else if (timeperiod === 'currentyear') {
        this.setState({ outlet_data_weekly_geo: outlet_geo_weeklydata_obj })
    } else if (timeperiod === 'threeyearsago') {
        this.setState({ outlet_data_weekly_geo_threeyears: outlet_geo_weeklydata_obj })
    } else if (timeperiod === 'twoyearsago') {
        this.setState({ outlet_data_weekly_geo_last: outlet_geo_weeklydata_obj })
    } else if (timeperiod === 'prioryear') {
        this.setState({ outlet_data_weekly_geo_prior: outlet_geo_weeklydata_obj })
    }
}
