import React from 'react';
import { makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import Drawer from '@material-ui/core/Drawer';
import Popover from '@material-ui/core/Popover';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

/*Test*/
export const test_import2 = 'This is from the file'

/*Page Routes and API URLs*/
//Get where in the app we are--used for conditional rendering and for API calls
var whereami = window.location.href;
export const whereamilist = whereami.split("/");
let app_for_api = whereamilist[3]
let appname = whereamilist[3]
if (app_for_api === 'PDTRetailer') {
    app_for_api = 'PDT'
}

export const baseurl = whereamilist[0] + '/' + whereamilist[1] + '/' + whereamilist[2] + '/'
export const baseapiurl_gen = whereamilist[0] + '/' + whereamilist[1] + '/' + whereamilist[2] + '/api/' // + whereamilist[3].toLower()
export const baseapiurl_app = whereamilist[0] + '/' + whereamilist[1] + '/' + whereamilist[2] + '/api/' + app_for_api.toLowerCase() + '/'
export const baseapiurl = baseapiurl_app

//Use this list to determine if I am in create, edit, or create from report

let entrytype_lookup = {
    'createsimulation': 'create',
    'createreport': 'create',
    'entryform': 'create',
    'pnpentryform': 'create',
    'editsimulation': 'edit',
    'editreport': 'edit',
    'editextract': 'edit',
    'createsimulationfromreport': 'createfromreport',
    'copysimulation': 'copy',
    'copyreport': 'copy',
    'copyetl': 'copy'
}

var entrytype_1 = entrytype_lookup[(whereamilist[4] || "").toLowerCase()] || whereamilist[4]
export const entrytype = entrytype_1

/*Static Values*/
export const countrynamelookup = {
    'USA': 'United States of America',
    'GBR': 'United Kingdom',
    'CAN': 'Canada',
    'FRA': 'France',
    'ITA': 'Italy',
    'DEU': 'Germany',

}

export const date_dictionary = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
}

export const checktranslations = ['dt_PROMOTYPEA', 'dt_PROMOTYPEB', 'dt_PROMOTYPEC', 'dt_PROMOTYPED', 'dt_ADTYPEA', 'dt_ADTYPEB', 'dt_ADTYPEC']

export const PDT_RETAILER_DATASETS = [275, 276, 281, 285]

export const colnamelabels = {
    "dt_COMPPDISC": 'Competitive Pricing',
    "dt_CENLNBPU": 'Base Price',
    "dt_HOLIDAY": 'Holiday',
    "dt_CORE": 'Core Distribution',
    "_ACTUAL": 'Actual',
    "dt_PDISC": 'TPR',
    "dt_LNSEAS1": 'Seasonality',
    "dt_LNSEAS2": 'Seasonality',
    "dt_LNSEAS3": 'Seasonality',
    "dt_PROMOTYPEA": 'Promotion A',
    "dt_PROMOTYPEB": 'Promotion B',
    "dt_PROMOTYPEC": 'Promotion C',
    "dt_PROMOTYPED": 'Promotion D',
    "dt_ADTYPEA": 'AD A',
    "dt_ADTYPEB": 'AD B',
    "dt_ADTYPEC": 'AD C'
}

export const colnamelabels_promos_ads = {
    "Monitors": {
        "dt_ADTYPEB": 'Front Page',
        "dt_ADTYPEC": 'Back Page',
        "dt_PROMOTYPED": 'Online'
    },
    "Passenger Car Motor Oil": {
        "dt_ADTYPEA": 'Front Page',
        "dt_ADTYPEB": 'Back Page',
        "dt_ADTYPEC": 'Middle Page / Line Listings',
        "dt_PROMOTYPEA": 'OCS & Front Page',
        "dt_PROMOTYPEB": 'OCS & Back Page',
        "dt_PROMOTYPEC": 'OCS & Middle Page / Line Listings',
        "dt_PROMOTYPED": 'Oil Change Special Only',
    },
    "Heavy Duty Motor Oil": {
        "dt_ADTYPEA": 'Front Page',
        "dt_ADTYPEB": 'Back Page',
        "dt_ADTYPEC": 'Middle Page / Line Listings',
        "dt_PROMOTYPEA": 'OCS & Front Page',
        "dt_PROMOTYPEB": 'OCS & Back Page',
        "dt_PROMOTYPEC": 'OCS & Middle Page / Line Listings',
        "dt_PROMOTYPED": 'Oil Change Special Only',
    },
    "Notebook Computers": {
        "dt_ADTYPEA": 'Front Page',
        "dt_ADTYPEB": 'Inside',
        "dt_ADTYPEC": 'Back Page',
        "dt_PROMOTYPEA": 'Online',
        "dt_PROMOTYPEB": 'Bundle or Peripheral',
        "dt_PROMOTYPEC": 'Recycling',
        "dt_PROMOTYPED": 'Student'
    },

}

export const colnamesolids = {
    "dt_COMPPDISC": '#7d32ba',
    "dt_CENLNBPU": '#bc81ec',
    "dt_HOLIDAY": '#ff9971',
    "dt_CORE": '#b0df97',
    "_ACTUAL": '#f3508e',
    "dt_PDISC": '#ff80b0',
    "dt_LNSEAS1": '#ff5929',
    "dt_LNSEAS2": '#ff8b6a',
    "dt_LNSEAS3": '#83b767',
    "dt_PROMOTYPEA": '#ff9971',
    "dt_PROMOTYPEB": '#8bb0D2',
    "dt_PROMOTYPEC": '#c9e3fb',
    "dt_PROMOTYPED": '#18cff8',
    "dt_ADTYPEA": '#acf0ff',
    "dt_ADTYPEB": '#4f5ba3',
    "dt_ADTYPEC": '#a7add1'
}

export const covid_periods = {
    "Monitors": {
        "2020-03-07": "Covid Week1",
        "2020-03-14": "Covid Week2",
        "2020-03-21": "Covid Week3",
        "2020-03-28": "Covid Week4",
        "2020-04-04": "Post Covid Week1",
        "2020-04-11": "Post Covid Week2",
        "2020-04-18": "Post Covid Week3",
        "2020-04-25": "Post Covid Week4",
        "2020-05-02": "Post Covid Week5",
        "2020-03-07T00:00:00": "Covid Week1",
        "2020-03-14T00:00:00": "Covid Week2",
        "2020-03-21T00:00:00": "Covid Week3",
        "2020-03-28T00:00:00": "Covid Week4",
        "2020-04-04T00:00:00": "Post Covid Week1",
        "2020-04-11T00:00:00": "Post Covid Week2",
        "2020-04-18T00:00:00": "Post Covid Week3",
        "2020-04-25T00:00:00": "Post Covid Week4",
        "2020-05-02T00:00:00": "Post Covid Week5",
    }
}

export const graph_colors = ["#7d32ba", "#bc81ec", "#ff8b6a",  "#83b767",  "#f3508e", "#ff80b0", "#ff5929", "#b0df97", "#8bb0D2", "#c9e3fb", "#18cff8", "#ff9971","#acf0ff", "#4f5ba3", "#a7add1"]

export const unitsvarlookup = {
    "Passenger Car Motor Oil": "Quarts",
    "Heavy Duty Motor Oil": "Quarts"
}

export const adeffect_options = {
    "Monitors": [
        { 'value': "None", 'label': 'No Ad' }
        , { 'value': "AdTypeA", 'label': "Front Page" }
        , { 'value': "AdTypeB", 'label': "Inside" }
        , { 'value': "AdTypeC", 'label': "Back Page" }
    ],
    "Notebook computers": [
        { 'value': "None", 'label': 'No Ad' }
        , { 'value': "AdTypeA", 'label': "Front Page" }
        , { 'value': "AdTypeB", 'label': "Inside" }
        , { 'value': "AdTypeC", 'label': "Back Page" }
    ],
    "Notebook Computers": [
        { 'value': "None", 'label': 'No Ad' }
        , { 'value': "AdTypeA", 'label': "Front Page" }
        , { 'value': "AdTypeB", 'label': "Inside" }
        , { 'value': "AdTypeC", 'label': "Back Page" }
    ],
    "Passenger Car Motor Oil": [
        { 'value': "None", 'label': 'No Ad' },
        { 'value': "AdTypeA", 'label': 'Front Page' },
        { 'value': "AdTypeB", 'label': 'Back Page' },
        { 'value': "AdTypeC", 'label': 'Middle Page / Line Listings' }
    ],
    "Heavy Duty Motor Oil": [
        { 'value': "None", 'label': 'No Ad' },
        { 'value': "AdTypeA", 'label': 'Front Page' },
        { 'value': "AdTypeB", 'label': 'Back Page' },
        { 'value': "AdTypeC", 'label': 'Middle Page / Line Listings' }
    ],

}

export const promoeffect_options = {
    "Monitors": [
        { 'value': "None", 'label': 'No Promo' }
        , { 'value': "PromoTypeA", 'label': "Online" }
        , { 'value': "PromoTypeB", 'label': "Bundle or Peripheral" }
        , { 'value': "PromoTypeC", 'label': "Recylcing" }
        , { 'value': "PromoTypeD", 'label': "Student" }
    ],
    "Notebook computers": [
        { 'value': "None", 'label': 'No Promo' }
        , { 'value': "PromoTypeA", 'label': "Online" }
        , { 'value': "PromoTypeB", 'label': "Bundle or Peripheral" }
        , { 'value': "PromoTypeC", 'label': "Recylcing" }
        , { 'value': "PromoTypeD", 'label': "Student" }
    ],
    "Notebook Computers": [
        { 'value': "None", 'label': 'No Promo' }
        , { 'value': "PromoTypeA", 'label': "Online" }
        , { 'value': "PromoTypeB", 'label': "Bundle or Peripheral" }
        , { 'value': "PromoTypeC", 'label': "Recylcing" }
        , { 'value': "PromoTypeD", 'label': "Student" }
    ],
    "Passenger Car Motor Oil": [
        { 'value': "None", 'label': 'No Promo' },
        { 'value': "PromoTypeA", 'label': 'Oil Change Special' },
    ],
    "Heavy Duty Motor Oil": [
        { 'value': "None", 'label': 'No Promo' },
        { 'value': "PromoTypeA", 'label': 'Oil Change Special' },
    ],

}

export const promo_add_one_only = ['Passenger Car Motor Oil', 'Heavy Duty Motor Oil']

export const alr_object = {
    "Heavy Duty Motor Oil": {
        2: [2, 8],
        3: [3, 6]
    },
    "Passenger Car Motor Oil": {
        2: [2, 6],
        3: [3, 8]
    },
    "Single Brew, Drip, and Espresso Makers": {
        1: [1, 2],
        6: [6, 7]
    },
    "Athletic and Casual Socks": {
        2: [2, 3]
    },
    "Athletic Socks": {
        3: [3, 4]
    },
    "Blenders and Processors": {
        1: [1, 2],
        3: [3, 4],
        6: [6, 7]
    },
}

export const alr_object_new = {
    "Heavy Duty Motor Oil": [[2, 8], [3, 6], [4, 7]],
    "Passenger Car Motor Oil": [[2, 6], [3, 8], [4, 7]],
    "Single Brew, Drip, and Espresso Makers": [[1, 2], [6, 7]],
    "Athletic and Casual Socks": [[2, 3]],
    "Athletic Socks": [[3, 4]],
    "Blenders and Processors": [[1, 2], [3, 4], [6, 7]]
}

let default_alr_order_all = [
    {
        "sectionname": "Price and Discount Trend Views",
        "tabs": [
            {
                "tabname": "ALR VS ROM",
                "visual": "ALR vs ROM"
            },
            {
                "tabname": "AVERAGE PROMOTED DISCOUNT % TRENDED",
                "visual": "Average Promoted Discount % Trended"
            },
            {
                "tabname": "AVERAGE SELLING PRICE",
                "visual": "Average Selling "
            },
            {
                "tabname": "DEPTH OF DISCOUNT BY DISCOUNT RANGE",
                "visual": "Depth of Discount by Discount Range"
            },
            {
                "tabname": "AVERAGE SELLING PRICE DISTRIBUTION BY ITEM",
                "visual": "Average Selling Price Distribution by item"
            }
        ]
    },
    {
        "sectionname": "Base and Incremental - Comparison to Rest of Market",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL",
                "visual": "Base and Incremental ALR"
            },
            {
                "tabname": "LIFT, EFFICIENCY & DISCOUNT",
                "visual": "Lift, Efficiency, and Discount"
            },
            {
                "tabname": "METRIC CHANGES",
                "visual": "Metrics Changes"
            },
            {
                "tabname": "METRIC COMPARISONS TO ROM",
                "visual": "Metric Comparisons to ROM"
            },
            {
                "tabname": "TOP/BOTTOM PERFORMING WEEKS",
                "visual": "Top/Bottom Performing Weeks"
            }
        ]
    },
    {
        "sectionname": "Base and Incremental",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL",
                "visual": "Base and Incremental"
            },
            {
                "tabname": "LIFT, EFFICIENCY & DISCOUNT",
                "visual": "Lift, Efficiency & Discount"
            },
            {
                "tabname": "PAYBACK RATIO AND EFFICIENCY BY DISCOUNT RANGE",
                "visual": "Payback Ratio and Efficiency by Discount Range"
            },
            {
                "tabname": "FAIR SHARE OF SUBSIDIZATION",
                "visual": "Fair Share of Subsidization"
            },
            {
                "tabname": "DISCOUNT DOLLARS AND PAYBACK RATIO",
                "visual": "Discount Dollars and Payback Ratio - Aggregate"
            }
        ]
    },
    {
        "sectionname": "Base and Incremental Trended",
        "tabs": [
            {
                "tabname": "PROMOTION PERFORMANCE",
                "visual": "Promotion Performance"
            },
            {
                "tabname": "LIFT AND EFFICIENCY",
                "visual": "Lift and Efficiency"
            },
            {
                "tabname": "DISCOUNT DOLLARS AND PAYBACK RATIO",
                "visual": "Discount Dollars and Payback Ratio"
            },
            {
                "tabname": "BASE VELOCITY",
                "visual": "Base Velocity"
            },
            {
                "tabname": "SHARE BY BRANDS/ITEMS",
                "visual": "Share by Brands"
            },
            {
                "tabname": "UNITS BY BRAND/ITEM",
                "visual": "Units by Brand/Item"
            }
        ]
    },
    {
        "sectionname": "Year Over Year Changes",
        "tabs": [
            {
                "tabname": "DISCOUNT RANGE",
                "visual": "Discount Range"
            },
            {
                "tabname": "TOTAL VOLUME",
                "visual": "Total Volume"
            },
            {
                "tabname": "ITEM PRICE CHANGES",
                "visual": "Item Change Information"
            },
        ]
    },
    {
        "sectionname": "Scorecard",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL UNITS",
                "visual": "Base and Incremental Units"
            },
            {
                "tabname": "PROMOTED AND NON-PROMOTED UNITS",
                "visual": "Promoted and Non-Promoted Units"
            },
            {
                "tabname": "SALES METRICS",
                "visual": "Sales Metrics"
            },
            {
                "tabname": "PRICING METRICS",
                "visual": "Pricing Metrics"
            },
            {
                "tabname": "PROMOTION METRICS",
                "visual": "Promotion Metrics"
            }
        ]
    }
]

let default_alr_order_retail = [
    {
        "sectionname": "Price and Discount Trend Views",
        "tabs": [
            {
                "tabname": "ALR VS ROM",
                "visual": "ALR vs ROM"
            },
            {
                "tabname": "AVERAGE PROMOTED DISCOUNT % TRENDED",
                "visual": "Average Promoted Discount % Trended"
            },
            {
                "tabname": "AVERAGE SELLING PRICE",
                "visual": "Average Selling "
            },
            {
                "tabname": "DEPTH OF DISCOUNT BY DISCOUNT RANGE",
                "visual": "Depth of Discount by Discount Range"
            },
            {
                "tabname": "PROMOTION SUPPORT AND SHARE",
                "visual": "Promo Support and Share"
            }
        ]
    },
    {
        "sectionname": "Base and Incremental - Comparison to Rest of Market",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL",
                "visual": "Base and Incremental ALR"
            },
            {
                "tabname": "LIFT, EFFICIENCY & DISCOUNT",
                "visual": "Lift, Efficiency, and Discount"
            },
            {
                "tabname": "METRIC CHANGES",
                "visual": "Metrics Changes"
            },
            {
                "tabname": "METRIC COMPARISONS TO ROM",
                "visual": "Metric Comparisons to ROM"
            },
            {
                "tabname": "TOP/BOTTOM PERFORMING WEEKS",
                "visual": "Top/Bottom Performing Weeks"
            }
        ]
    },
    {
        "sectionname": "Base and Incremental",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL",
                "visual": "Base and Incremental"
            },
            {
                "tabname": "LIFT, EFFICIENCY & DISCOUNT",
                "visual": "Lift, Efficiency & Discount"
            },
            {
                "tabname": "PAYBACK RATIO AND EFFICIENCY BY DISCOUNT RANGE",
                "visual": "Payback Ratio and Efficiency by Discount Range"
            },
            {
                "tabname": "FAIR SHARE OF SUBSIDIZATION",
                "visual": "Fair Share of Subsidization"
            },
            {
                "tabname": "DISCOUNT DOLLARS AND PAYBACK RATIO",
                "visual": "Discount Dollars and Payback Ratio - Aggregate"
            }
        ]
    },
    {
        "sectionname": "Base and Incremental Trended",
        "tabs": [
            {
                "tabname": "PROMOTION PERFORMANCE",
                "visual": "Promotion Performance"
            },
            {
                "tabname": "LIFT AND EFFICIENCY",
                "visual": "Lift and Efficiency"
            },
            {
                "tabname": "DISCOUNT DOLLARS AND PAYBACK RATIO",
                "visual": "Discount Dollars and Payback Ratio"
            },
            {
                "tabname": "BASE VELOCITY",
                "visual": "Base Velocity"
            },
            {
                "tabname": "SHARE BY BRANDS",
                "visual": "Share by Brands"
            },
            {
                "tabname": "UNITS BY BRAND",
                "visual": "Units by Brand/Item"
            }
        ]
    },
    {
        "sectionname": "Year Over Year Changes",
        "tabs": [
            {
                "tabname": "DISCOUNT RANGE",
                "visual": "Discount Range"
            },
            {
                "tabname": "TOTAL VOLUME",
                "visual": "Total Volume"
            },
        ]
    },
    {
        "sectionname": "Scorecard",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL UNITS",
                "visual": "Base and Incremental Units"
            },
            {
                "tabname": "PROMOTED AND NON-PROMOTED UNITS",
                "visual": "Promoted and Non-Promoted Units"
            },
            {
                "tabname": "SALES METRICS",
                "visual": "Sales Metrics"
            },
            {
                "tabname": "PRICING METRICS",
                "visual": "Pricing Metrics"
            },
            {
                "tabname": "PROMOTION METRICS",
                "visual": "Promotion Metrics"
            }
        ]
    },

    {
        "sectionname": "Performance Review",
        "tabs": [
            {
                "tabname": "WEEKLY PERFORMANCE",
                "visual": "Weekly Performance"
            },
            {
                "tabname": "SEGMENT PERFORMANCE",
                "visual": "Segment Performance"
            },
            {
                "tabname": "BRAND PERFORMANCE",
                "visual": "Brand Performance"
            }
        ]
    }
]

var default_alr_order_computed
if (appname === 'PDTRetailer') {
    default_alr_order_computed = default_alr_order_retail
}
else {
    default_alr_order_computed = default_alr_order_all
}

export const default_alr_order = default_alr_order_computed

export const default_tmm_order = [
    {
        "sectionname": "Base and Incremental",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL",
                "visual": "Base and Incremental"
            },
            {
                "tabname": "LIFT, EFFICIENCY & DISCOUNT",
                "visual": "Lift, Efficiency & Discount"
            },
            {
                "tabname": "PAYBACK RATIO AND EFFICIENCY BY DISCOUNT RANGE",
                "visual": "Payback Ratio and Efficiency by Discount Range"
            },
            {
                "tabname": "FAIR SHARE OF SUBSIDIZATION",
                "visual": "Fair Share of Subsidization"
            },
            {
                "tabname": "DISCOUNT DOLLARS AND PAYBACK RATIO",
                "visual": "Discount Dollars and Payback Ratio - Aggregate"
            }
        ]
    },
    {
        "sectionname": "Base and Incremental Trended",
        "tabs": [
            {
                "tabname": "PROMOTION PERFORMANCE",
                "visual": "Promotion Performance"
            },
            {
                "tabname": "LIFT AND EFFICIENCY",
                "visual": "Lift and Efficiency"
            },
            {
                "tabname": "DISCOUNT DOLLARS AND PAYBACK RATIO",
                "visual": "Discount Dollars and Payback Ratio"
            },
            {
                "tabname": "BASE VELOCITY",
                "visual": "Base Velocity"
            },
            {
                "tabname": "SHARE BY BRANDS/ITEMS",
                "visual": "Share by Brands"
            },
            {
                "tabname": "UNITS BY BRAND/ITEM",
                "visual": "Units by Brand/Item"
            }
        ]
    },
    {
        "sectionname": "Price and Discount Trends",
        "tabs": [
            {
                "tabname": "AVERAGE PROMOTED DISCOUNT % TRENDED",
                "visual": "Average Promoted Discount Pct Trended"
            },
            {
                "tabname": "AVERAGE PROMOTED DISCOUNT % WITH INDEXED DOLLAR SHARE",
                "visual": "Average Promoted Discount Pct With Dollar Share"
            },
            
            {
                "tabname": "DEPTH OF DISCOUNT",
                "visual": "Depth of Discount"
            },
            {
                "tabname": "PROMOTED/NON-PROMOTED PRICE",
                "visual": "Promoted/Non-Promoted Price"
            },
            {
                "tabname": "BASE/AVERAGE SELLING PRICE",
                "visual": "Base/Average Selling Price"
            },
            {
                "tabname": "PROMOTED UNITS/DOLLARS",
                "visual": "Promoted Units/Dollars"
            }
        ]
    },
    {
        "sectionname": "Year Over Year Changes",
        "tabs": [
            {
                "tabname": "DISCOUNT RANGE",
                "visual": "Discount Range"
            },
            {
                "tabname": "TOTAL VOLUME",
                "visual": "Total Volume"
            },
            {
                "tabname": "ITEM PRICE CHANGES",
                "visual": "Item Change Information"
            },
            {
                "tabname": "METRIC CHANGES",
                "visual": "Metrics Changes"
            },
            {
                "tabname": "METRIC CHANGES WITH MARKET SHARE",
                "visual": "Metrics Changes With Market Share"
            },
            
        ]
    },
    {
        "sectionname": "Scorecard",
        "tabs": [
            {
                "tabname": "BASE AND INCREMENTAL UNITS",
                "visual": "Base and Incremental Units"
            },
            {
                "tabname": "PROMOTED AND NON-PROMOTED UNITS",
                "visual": "Promoted and Non-Promoted Units"
            },
            {
                "tabname": "SALES METRICS",
                "visual": "Sales Metrics"
            },
            {
                "tabname": "PRICING METRICS",
                "visual": "Pricing Metrics"
            },
            {
                "tabname": "PROMOTION METRICS",
                "visual": "Promotion Metrics"
            }
        ]
    }
]

export const comparisonMetricOptions = [
    { 'label': 'Payback Ratio', 'value': 'Payback Ratio' },
    { 'label': 'Market Lift', 'value': 'Market Lift' },
    { 'label': 'Promoted Lift', 'value': 'Promoted Lift' },
    { 'label': 'Efficiency', 'value': 'Efficiency' },
    //{ 'label': 'Payback Ratio and Discount Depth Weekly', 'value': 'Payback Ratio and Discount Depth Weekly' },
    //{ 'label': 'Dollar Share By Week', 'value': 'Dollar Share By Week'}
]

export const comparisonMetricOptions_trended = [
    { 'label': 'Payback Ratio and Discount Depth Trended', 'value': 'Payback Ratio and Discount Depth Trended' },
    { 'label': 'Average Promoted Discount Trended', 'value': 'Average Promoted Discount Trended' },
    { 'label': 'Market Lift Trended', 'value': 'Market Lift Trended' },
    { 'label': 'Promoted Lift Trended', 'value': 'Promoted Lift Trended' },
    { 'label': 'Efficiency Trended', 'value': 'Efficiency Trended' },
    { 'label': 'Average Selling Price Trended', 'value': 'Average Selling Price Trended' },
    { 'label': 'Base Price Trended', 'value': 'Base Price Trended' },
    { 'label': 'ASP Gap (Group 1 minus Group 2) Trended', 'value': 'ASP Gap (Group 1 minus Group 2) Trended' },
    { 'label': 'Base Price Gap (Group 1 minus Group 2) Trended', 'value': 'Base Price Gap (Group 1 minus Group 2) Trended' },
    //{ 'label': 'Dollar Share By Week', 'value': 'Dollar Share By Week'}
]

export const overlayMetricOptions = [
    { 'label': 'Average Promoted Discount', 'value': 'Average Promoted Discount' },
    { 'label': 'Market Lift', 'value': 'Market Lift' },
    { 'label': 'Promoted Lift', 'value': 'Promoted Lift' },
    { 'label': 'Efficiency', 'value': 'Efficiency' },
    { 'label': 'Average Selling Price', 'value': 'Average Selling Price' },
    { 'label': 'Base Price', 'value': 'Base Price' },
    { 'label': 'ASP Gap (Group 1 minus Group 2)', 'value': 'ASP Gap (Group 1 minus Group 2)' },
    { 'label': 'Base Price Gap (Group 1 minus Group 2)', 'value': 'Base Price Gap (Group 1 minus Group 2)' },
]

export const comparisonLevelOptions = [
    { 'label': 'Brand', 'value': 'Brand' },
    { 'label': 'Product', 'value': 'Item' },
    { 'label': 'Price Band', 'value': 'Price Band' }
]

export const metricsforindexviews = [
    'Total Units', 'Total Units Share', 'Total Dollars', 'Total Dollars Share',
    'Base Units', 'Base Units Share', 'Base Dollars', 'Base Dollars Share',
    'Incremental Units', 'Incremental Units Share', 'Incremental Dollars', 'Incremental Dollars Share',
    'Promoted Units', 'Promoted Units Share', 'Promoted Dollars', 'Promoted Dollars Share',
    'Non-Promoted Units', 'Non-Promoted Units Share', 'Non-Promoted Dollars', 'Non-Promoted Dollars Share',
    'Subsidized Base Units', 'Subsidized Base Units Share', 'Subsidized Base Dollars', 'Subsidized Base Dollars Share',
    'Unsubsidized Base Units', 'Unsubsidized Base Units Share', 'Unsubsidized Base Dollars', 'Unsubsidized Base Dollars Share'
]

export const metricsforcomparerom = [
    'Total Units'
    , 'Base Units'
    , 'Incremental Units'
    , 'Base Units % of Total'
    , 'Incremental Units % of Total'
    , 'Unsubsidized Base Units'
    , 'Subsidized Base Units'
    , 'Unsubsidized Base Units % of Base'
    , 'Subsidized Base Units % of Base'
    , 'Non-Promoted Units'
    , 'Promoted Units'
    , 'Non-Promoted Units % of Total'
    , 'Promoted Units % of Total'
    , 'Total Dollars'
    , 'Non-Promoted Dollars'
    , 'Promoted Dollars'
    , 'Base Dollars'
    , 'Incremental Dollars'
    , 'Unsubsidized Base Dollars'
    , 'Subsidized Base Dollars'
    , 'Non-Promoted % of Total Dollars'
    , 'Promoted % of Total Dollars'
    , 'Base % of Total Dollars'
    , 'Incremental % of Total Dollars'
    , 'Unsubsidized Base % of Base Dollars'
    , 'Subsidized Base % of Base Dollars'
    , 'Average Base Price'
    , 'Average Selling Price'
    , 'Average Promo Price'
    , 'Average Non-Promo Price'
    , 'Average Discount'
    , 'Average Promoted Discount'
    , 'Discount Dollars'
    , 'Market Lift'
    , 'Promoted Lift'
    , 'Efficiency'
    , 'Market Lift (Dollars)'
    , 'Promoted Lift (Dollars)'
    , 'Efficiency (Dollars)'
    , 'Payback Ratio'
]

export const metricOptions = [
    { 'label': 'Base', 'value': 'Base' },
    { 'label': 'Subsidized Base', 'value': 'Subsidized Base' },
    { 'label': 'UnSubsidized Base', 'value': 'UnSubsidized Base' },
    { 'label': 'Incremental', 'value': 'Incremental' },
    { 'label': 'Promoted', 'value': 'Promoted' },
    { 'label': 'Non-Promoted', 'value': 'Non-Promoted' },
    { 'label': 'Average Promoted Discount %', 'value': 'Average Promoted Discount %' },
    { 'label': 'Market Lift', 'value': 'Market Lift' },
    { 'label': 'Promoted Lift', 'value': 'Promoted Lift' },
    { 'label': 'Efficiency', 'value': 'Efficiency' },
]

export const metricOptions_Area = [
    { 'label': 'Total', 'value': 'total' },
    { 'label': 'Base', 'value': 'base' },
    { 'label': 'Subsidized Base', 'value': 'subsidizedbase' },
    { 'label': 'UnSubsidized Base', 'value': 'unsubsidizedbase' },
    { 'label': 'Incremental', 'value': 'incremental' },
    { 'label': 'Promoted', 'value': 'promoted' },
    { 'label': 'Non-Promoted', 'value': 'nonpromoted' },
]

export const levelOptions = [
    { 'label': 'Total', 'value': 'Total' },
    { 'label': 'Channel', 'value': 'Channel' },
    { 'label': 'Brand', 'value': 'Brand' },
    { 'label': 'Price Band', 'value': 'Price Band' },
    //{ 'label': 'Discount Range', 'value': 'Discount Range'}
]

var levelOptions_BaseInc_all = [
    { 'label': 'Total', 'value': 'Total' },
    { 'label': 'Channel', 'value': 'Channel' },
    { 'label': 'Brand', 'value': 'Brand' },
    { 'label': 'Item', 'value': 'Item' },
    { 'label': 'Price Band', 'value': 'Price Band' },
    { 'label': 'Outlet', 'value': 'Outlet' },
    //{ 'label': 'Discount Range', 'value': 'Discount Range' }
]

var levelOptions_BaseInc_retail = [
    { 'label': 'Total', 'value': 'Total' },
    { 'label': 'Channel', 'value': 'Channel' },
    { 'label': 'Brand', 'value': 'Brand' },    
    { 'label': 'Price Band', 'value': 'Price Band' },
    { 'label': 'Outlet', 'value': 'Outlet' },
    //{ 'label': 'Discount Range', 'value': 'Discount Range' }
]

var levelOptions_BaseInc_computed
if (appname === 'PDTRetailer') {
    levelOptions_BaseInc_computed = levelOptions_BaseInc_retail
}

else {
    levelOptions_BaseInc_computed = levelOptions_BaseInc_all
}

export const levelOptions_BaseInc = levelOptions_BaseInc_computed

export const itemdistpriceoptions = [
    { value: 'asp', 'label': 'Average Selling Price' },
    { value: 'promoted', 'label': 'Promoted Price' },
    { value: 'nonpromoted', 'label': 'Non-Promoted Price' }
]

/*Styles*/
export const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4e106f',
        },
        secondary: {
            main: '#4e106f',
        },
        white: {
            main: '#FFF'
        }
    }
});

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: '1rem',
        backgroundColor: state.isSelected ? '#4e106f' : '#FFF',
        color: state.isSelected ? '#FFF' : '#4e106f'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        fontSize: '1rem',
        maxWidth: '500px',
        wordBreak: 'break-all',
        fontFamily: "Roboto",
        
    }),
}

export const dropdownStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        maxHeight: "75px",
        overflowY: "auto"
    }),
}

export const classes = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

/*Components*/

export const NPDPopover = withStyles({
    root: {
        maxHeight: '100vh',
        borderRadius: '0px',
        backgrounColor: "#F4F4F4",
        //display:'none'
    },
    paper: {
        maxHeight: '100vh',
        borderRadius: '0px',
        backgrounColor: "#F4F4F4"
    },

})(Popover);

export const HTMLTooltip = withStyles({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 750,
        fontSize: '1.0rem',
        border: '1px solid #dadde9',
    },
})(Tooltip);

export const NPDDrawer = withStyles({
    root: {

    },
    paperAnchorLeft: {

        borderWidth: '0px'
    },

})(Drawer);

export const NPDAppbar = withStyles({
    root: {
        backgroundColor: '#FFF',
        boxShadow: '0px 0px 0px rgba(0,0,0,0)',
        textTransform: 'none',
        fontWeight: 'normal',
        fontFamily: "Roboto",
        color: '#565A5C',
        borderBottom: '2px solid #8E908F',
        margin: '10px'
    },
    //selected: {},
})(AppBar);

export const NPDTabs = withStyles({
    root: {
        //borderBottom: '2px solid #8E908F',
    },
    indicator: {
        backgroundColor: '#4e106f',
        position: 'absolute',
        top: '46px'

    },
})(Tabs);

export const NPDTab = withStyles({
    root: {
        textTransform: 'none',
        fontWeight: 'normal',
        fontFamily: "Roboto",
        color: '#565A5C',
        '&:hover': {
            color: '#565A5C',
            opacity: 1,
            border: '1px solid #FFF'
        },
        '&:selected': {
            color: '#565A5C',
            opacity: 1,
            border: '1px solid #FFF'
        },
        '&$selected': {
            color: '#565A5C',
            fontWeight: 'bold',
            border: '1px solid #FFF'
        },
        '&:focus': {
            color: '#565A5C',
            border: '1px solid #FFF'
        },
        '&:Component-selected-202': {
            color: '#565A5C',
            border: '1px solid #FFF'
        },
    },
    selected: {
        border: '1px solid #FFF'
    },
})(Tab);

export const NPDTable = withStyles({
    root: {
        backgroundColor: '#FFF',
    },
})(Table);

export const NPDTableBody = withStyles({
    root: {
        backgroundColor: '#FFF',
    },
})(TableBody);

export const NPDBackdrop = withStyles({
    root: {
        backgroundColor: 'rgba(142, 144, 143,.5)',
        color: '#4e106f',
        zIndex: '9999999'
    },

})(Backdrop);

export const NPDBackdropWhiteout = withStyles({
    root: {
        backgroundColor: 'rgba(255, 255, 255,1)',
        color: '#FFFFFF',
        zIndex: '9999999'
    },

})(Backdrop);

export const NPDSnackbarSuccess = withStyles({
    root: {
        backgroundColor: 'green',
        color: '#fff'
    },
    anchorOriginBottomCenter: {
        backgroundColor: 'green',
        color: '#fff'
    },

})(Snackbar);

export const NPDSnackbarContentSuccess = withStyles({
    root: {
        backgroundColor: 'green',
        color: '#fff'
    },
    message: {
        backgroundColor: 'green',
        color: '#fff'
    },
    action: {
        backgroundColor: 'green',
        color: '#fff'
    },

})(SnackbarContent);

export const NPDSnackbarError = withStyles({
    root: {
        backgroundColor: 'red',
        color: '#fff'
    },
    anchorOriginBottomCenter: {
        backgroundColor: 'red',
        color: '#fff'
    },

})(Snackbar);

export const NPDSnackbarContentError = withStyles({
    root: {
        backgroundColor: 'red',
        color: '#fff'
    },
    message: {
        backgroundColor: 'red',
        color: '#fff'
    },
    action: {
        backgroundColor: 'red',
        color: '#fff'
    },

})(SnackbarContent);

export const NPDSnackbar = withStyles({
    root: {
        backgroundColor: '#565A5C',
        color: '#fff'
    },
    anchorOriginBottomCenter: {
        backgroundColor: '#565A5C',
        color: '#fff'
    },
    anchorOriginBottomLeft: {
        backgroundColor: '#565A5C',
        color: '#fff'
    },

})(Snackbar);

export const NPDSnackbarContent = withStyles({
    root: {
        backgroundColor: '#565A5C',
        color: '#fff'
    },
    message: {
        backgroundColor: '#565A5C',
        color: '#fff'
    },
    action: {
        backgroundColor: '#565A5C',
        color: '#fff'
    },

})(SnackbarContent);

export const NPDTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#4e106f', //Change to NPD Color
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#8E908F', //Change to NPD Color
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#8E908F', //Change to NPD Color

            },
            '&:hover fieldset': {
                borderColor: '#4e106f', //Change to NPD Color
            },
            '&.Mui-focused fieldset': {
                borderColor: '#4e106f', //Change to NPD Color
            },
        },

        '& .MuiOutlinedInput-inputMarginDense': {
            color: '#4e106f',
            fontFamily: 'Roboto',
            fontSize: '1rem',
            textAlign: 'right'
        },
        '& .Mui-disabled': {
            color: 'black',
            fontFamily: 'Roboto',
            fontSize: '.65rem',
            textAlign: 'right'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px',
            paddingLeft: '5px',
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingRight: '5px',
            paddingLeft: '5px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px 5px',
            textAlign: 'right'
        },
    },
})(TextField);

export const NPDInputAdornment = withStyles({
    root: {
        '& .MuiTypography-colorTextSecondary': {
            color: '#4e106f',
            fontFamily: 'Roboto',
            fontSize: '1rem',
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px',
            paddingLeft: '5px'
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingRight: '5px',
            paddingLeft: '5px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px 5px'
        },
    },
})(InputAdornment);

export const NPDTableRow = withStyles({
    root: {
        color: 'inherit',
        borderColor: '#FFF',
        borderRadius: '5px',
        borderWidth: '1px 1px 1px 1px',
        borderStyle: 'solid',
        backgroundColor: '#E1E1E1',
        outlineWidth: '1px',
        margin: '20px',
        '&$selected': {
            backgroundColor: '#FFF',
            borderColor: '#4e106f',
            borderRadius: '5px',
            borderWidth: '1px',
            borderStyle: 'solid',
            outlineWidth: '1px',
        },
        
        '& .Mui-selected:hover': {
            backgroundColor: '#d3c3db', //Change to NPD Color
        }
        
    },
    selected: {
        backgroundColor: 'indigo'
    }

})(TableRow);

export const NPDTableCell = withStyles({
    root: {
        color: 'inherit',
        borderWidth: '0px',
        padding: '5px'
    },
})(TableCell);

export const NPDMyBrandChip = withStyles({
    root: {
        backgroundColor: '#4e106f',
        color: "#FFF",
        //color: '#8E908F',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '16px'
    },
})(Chip);

export const NPDMyBrandCompetitorChip = withStyles({
    root: {
        backgroundColor: '#4e106f',
        color: "#FFF",
        //color: '#0078BE',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '16px'
    },
})(Chip);

export const NPDCompetitorWithChip = withStyles({
    root: {
        backgroundColor: '#ff8b6a',
        color: "#FFF",
        //color: '#ef2a79',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '16px'
    },
})(Chip);

export const NPDCompetitorWithOutChip = withStyles({
    root: {
        backgroundColor: '#ff8b6a',
        color: "#FFF",
        //color: '#8E908F',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '16px'
    },
})(Chip);

export const NPDMyBrandChip_Label = withStyles({
    root: {
        backgroundColor: '#4e106f',
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '0px'
    },
})(Chip);

export const NPDMyBrandCompetitorChip_Label = withStyles({
    root: {
        backgroundColor: '#4e107f',
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '0px'
    },
})(Chip);

export const NPDCompetitorWithChip_Label = withStyles({
    root: {
        backgroundColor: '#ff8b6a',
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '0px'
    },
})(Chip);

export const NPDCompetitorWithOutChip_Label = withStyles({
    root: {
        backgroundColor: '#8E908F',
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '0px'
    },
})(Chip);

export const NPDItemGroupChip = withStyles({
    root: {
        backgroundColor: '#84329B',
        color: "#FFF",
        //color: '#8E908F',
        fontFamily: 'Roboto',
        fontSize: '.75rem',
        margin: '5px',
        padding: '5px',
        height: '100%',
        borderRadius: '16px'
    },
})(Chip);

export const NPDIconButton = withStyles({
    root: {
        padding: '5px'
    },
})(IconButton);

export const json_headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}
