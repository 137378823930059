import { saveAs } from "file-saver";
import CreateWeeksNew from '../Utilities/CreateWeeks'
import { reducer } from '../Utilities/Reducer'
import removeItemOnce from '../Utilities/RemoveItemOnce'
import replaceEmptyArrays from '../Utilities/RemoveItemOnce'
import * as Constant from '../../../constants'
const ExcelJS = require('exceljs');

export default async function download_data_itemmetrics_yoy() {
    console.log('Building Download')
    let items = this.state.items_sorted_dollars
    let brands = Object.keys(this.state.all_period_metrics).filter(function (value, index, arr) {
        let invalidvalues = ['curr_total_dollars_forshare', 'curr_total_units_forshare', 'prior_total_dollars_forshare', 'prior_total_units_forshare', 'Total Category', 'Total Selection']
        return !invalidvalues.includes(value);
    })
    let levelstoview = items
    let data = this.state.all_period_metrics
    let unitsvar = this.state.unitsvar
    let currencyType = this.state.currencyType
    let currencysymbol = this.state.currencysymbol

    const zero_object = {
        'basedollars': 0,
        'baseunits': 0,
        'carrieddoors': 0,
        'discountdollars': 0,
        'incrementaldollars': 0,
        'incrementalunits': 0,
        'nonpromoteddollars': 0,
        'nonpromotedunits': 0,
        'promoteddollars': 0,
        'promotedunits': 0,
        'revopportunity': 0,
        'subsidizedbasedollars': 0,
        'subsidizedbaseunits': 0,
        'totaldollars': 0,
        'totaldoors_s1': 0,
        'totaldoors_s1_segment': 0,
        'totaldoors_s2': 0,
        'totaldoors_s2_segment': 0,
        'totalunits': 0,
        'totalunits_withoutno': 0,
        'unsubsidizedbasedollars': 0,
        'unsubsidizedbaseunits': 0,
        'weightedasp': 0,
        'weightedbp': 0,
        'weightedbp_withoutno': 0,
        'weightedpctdisc': 0,
        'weightedpctdisc_withoutno': 0,
        'unitsshare_curr': 0,
        'dollarshare_curr': 0,
        'aspprice_curr': 0,
        'promoprice_curr': 0,
        'nonpromoprice_curr': 0,
        'unitsshare_prior': 0,
        'dollarshare_prior': 0,
        'aspprice_prior': 0,
        'promoprice_prior': 0,
        'nonpromoprice_prior': 0
    }

    var metricnames = [
        'Current Year Total ' + unitsvar,
        'Current Year Non Promoted ' + unitsvar,
        'Current Year Promoted ' + unitsvar,
        'Current Year Total ' + currencyType,
        'Current Year Non Promoted ' + currencyType,
        'Current Year Promoted ' + currencyType,
        'Current Year ASP',
        'Current Year Non Promoted Price',
        'Current Year Promoted Price',
        'Previous Year Total ' + unitsvar,
        'Previous Year Non Promoted ' + unitsvar,
        'Previous Year Promoted ' + unitsvar,
        'Previous Year Total ' + currencyType,
        'Previous Year Non Promoted ' + currencyType,
        'Previous Year Promoted ' + currencyType,
        'Previous Year ASP',
        'Previous Year Non Promoted Price',
        'Previous Year Promoted Price',
        'Change in ASP',
        'Change in Non Promoted Price',
        'Change in Promoted Price',
        'Change in Non Promoted ' + unitsvar,
        'Change in Non Promoted ' + currencyType,
        'Change in Promoted ' + unitsvar,
        'Change in Promoted ' + currencyType,
        'Current Year ' + unitsvar + ' Share',
        'Previous Year ' + unitsvar + ' Share',
        'Current Year ' + currencyType + ' Share',
        'Previous Year ' + currencyType + ' Share',
        'Change in ' + unitsvar + ' Share',
        'Change in ' + currencyType + ' Share',
        'Total ASP % Change',
        'Non-Promoted ASP % Change',
        'Promoted ASP % Change'
    ]

    function rowcheck(itemdata) {
        let sharechange_units = itemdata['unitsshare_curr'] - itemdata['unitsshare_prior']
        let sharechange_dollars = itemdata['dollarshare_curr'] - itemdata['dollarshare_prior']
        let asppricechange = itemdata['aspprice_curr'] - itemdata['aspprice_prior']
        let promopricechange = itemdata['promoprice_curr'] - itemdata['promoprice_prior']
        let nonpromopricechange = itemdata['nonpromoprice_curr'] - itemdata['nonpromoprice_prior']

        if (sharechange_units === 0 && sharechange_dollars === 0 && asppricechange === 0 && promopricechange === 0 && nonpromopricechange === 0) {
            return false
        }

        else {
            return true
        }
    }

    function createMetricArray(itemdataset, items, timeperiod, metric) {
        var metricArray = items.map(item => {
            let itemdata = itemdataset[item] || zero_object
            itemdata['current'] = itemdata['current'] || zero_object
            itemdata['prior'] = itemdata['prior'] || zero_object

            //Check item to see if it passes
            let userow = rowcheck(itemdata)
            let value = itemdata[timeperiod][metric]
            if (userow === true) {
                if (isFinite(value)) {
                    return value
                }
                else {
                    return 0
                }
            }
            else {
                return null
            }

        })
        return metricArray.filter(function (n) {
            return (n !== null);
        })
    }

    function createMetricArray_noperiod(itemdataset, items, metric) {
        var metricArray = items.map(item => {


            let itemdata = itemdataset[item] || zero_object
            let userow = rowcheck(itemdata)
            let value = itemdata[metric]
            if (userow === true) {
                if (isFinite(value)) {
                    return value
                }
                else {
                    return 0
                }
            }
            else {
                return null
            }
        })
        return metricArray.filter(function (n) {
            return (n !== null);
        })
    }

    function createMetricArray_delta(itemdataset, items, metric) {
        var metricArray = items.map(item => {


            let itemdata = itemdataset[item] || zero_object
            let userow = rowcheck(itemdata)
            itemdata['current'] = itemdata['current'] || zero_object
            itemdata['prior'] = itemdata['prior'] || zero_object
            let value = itemdata['current'][metric] - itemdata['prior'][metric]
            if (userow === true) {
                if (isFinite(value)) {
                    return value
                }
                else {
                    return 0
                }
            }
            else {
                return null
            }
        })
        return metricArray.filter(function (n) {
            return (n !== null);
        })
    }

    function createMetricArray_delta_noperiod(itemdataset, items, metric1, metric2) {
        var metricArray = items.map(item => {


            let itemdata = itemdataset[item] || zero_object
            let userow = rowcheck(itemdata)
            let value = itemdata[metric1] - itemdata[metric2]

            if (userow === true) {
                if (isFinite(value)) {
                    return value
                }
                else {
                    return 0
                }
            }
            else {
                return null
            }
        })
        return metricArray.filter(function (n) {
            return (n !== null);
        });
    }

    function createMetricArray_delta_pct_noperiod(itemdataset, items, metric1, metric2) {
        var metricArray = items.map(item => {

            let itemdata = itemdataset[item] || zero_object
            let userow = rowcheck(itemdata)
            let value = (itemdata[metric1] - itemdata[metric2]) / itemdata[metric2]

            if (userow === true) {
                if (isFinite(value)) {
                    return value
                }
                else {
                    return 0
                }
            }
            else {
                return null
            }
        })
        return metricArray.filter(function (n) {
            return (n !== null);
        });
    }

    //Set Styling
    function setFormat_Number(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = '##,#0';
        })

    }

    function setFormat_Percent(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = '0.00%';
        })

    }

    function setFormat_Currency(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = currencysymbol + '#,##0';
        })

    }

    function setFormat_Currency_Price(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = currencysymbol + '#,##.00';
        })

    }

    function setFormat_PPTs(worksheet, cols) {
        cols.forEach(col => {
            const colToIterate = worksheet.getColumn(col);
            colToIterate.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
                if (rowNumber > 1) {
                    let value = cell.value
                    let newValue = String((value * 100).toFixed(3)) + ' ppts'
                    cell.value = newValue
                }

            });

        })

    }

    function setFormat_Decimal(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = '##,#.000';
        })

    }

    let items_for_col = []
    let brands_for_col = []

    brands.forEach(brand => {
        console.log(brand)
        let itemdataset = data[brand]
        items.map(item => {
            console.log(item)
            let itemdata = itemdataset[item] || zero_object
            console.log(itemdata)
            let userow = rowcheck(itemdata)
            console.log(userow)
            if (userow === true) {
                console.log('Pushing Brand')
                console.log('Pushing Item')
                brands_for_col.push(brand)
                items_for_col.push(item)
            }
        })
    })


    let asp_change = []
    let promoprice_change = []
    let nonpromoprice_change = []
    let unitshare_change = []
    let dollarshare_change = []

    let promunits_change = []
    let promdollars_change = []
    let nonpromunits_change = []
    let nonpromdollars_change = []

    let totalunits_curr = []
    let nonpromunits_curr = []
    let promunits_curr = []
    let unitsshare_curr = []
    let totaldollars_curr = []
    let nonpromdollars_curr = []
    let promudollars_curr = []
    let dollarsshare_curr = []

    let totalunits_prior = []
    let nonpromunits_prior = []
    let promunits_prior = []
    let unitsshare_prior = []
    let totaldollars_prior = []
    let nonpromdollars_prior = []
    let promudollars_prior = []
    let dollarsshare_prior = []

    let promoprice_curr = []
    let promoprice_prior = []
    let nonpromoprice_curr = []
    let nonpromoprice_prior = []
    let asp_curr = []
    let asp_prior = []

    let asp_change_pct = []
    let promoprice_change_pct = []
    let nonpromoprice_change_pct = []

    //Create Metrics
    brands.forEach(brand => {
        asp_change = asp_change.concat(createMetricArray_delta_noperiod(data[brand], items, 'aspprice_curr', 'aspprice_prior'))        
        promoprice_change = promoprice_change.concat(createMetricArray_delta_noperiod(data[brand], items, 'promoprice_curr', 'promoprice_prior'))
        nonpromoprice_change = nonpromoprice_change.concat(createMetricArray_delta_noperiod(data[brand], items, 'nonpromoprice_curr', 'nonpromoprice_prior'))
        unitshare_change = unitshare_change.concat(createMetricArray_delta_noperiod(data[brand], items, 'unitsshare_curr', 'unitsshare_prior'))
        dollarshare_change = dollarshare_change.concat(createMetricArray_delta_noperiod(data[brand], items, 'dollarshare_curr', 'dollarshare_prior'))

        asp_change_pct = asp_change_pct.concat(createMetricArray_delta_pct_noperiod(data[brand], items, 'aspprice_curr', 'aspprice_prior'))  
        promoprice_change_pct = promoprice_change_pct.concat(createMetricArray_delta_pct_noperiod(data[brand], items, 'promoprice_curr', 'promoprice_prior')) 
        nonpromoprice_change_pct = nonpromoprice_change_pct.concat(createMetricArray_delta_pct_noperiod(data[brand], items, 'nonpromoprice_curr', 'nonpromoprice_prior')) 

        promunits_change = promunits_change.concat(createMetricArray_delta(data[brand], items, 'promotedunits'))
        promdollars_change = promdollars_change.concat(createMetricArray_delta(data[brand], items, 'promoteddollars'))
        nonpromunits_change = nonpromunits_change.concat(createMetricArray_delta(data[brand], items, 'nonpromotedunits'))
        nonpromdollars_change = nonpromdollars_change.concat(createMetricArray_delta(data[brand], items, 'nonpromoteddollars'))

        
        totalunits_curr = totalunits_curr.concat(createMetricArray(data[brand], items, 'current', 'totalunits'))
        nonpromunits_curr = nonpromunits_curr.concat(createMetricArray(data[brand], items, 'current', 'nonpromotedunits'))
        promunits_curr = promunits_curr.concat(createMetricArray(data[brand], items, 'current', 'promotedunits'))
        unitsshare_curr = unitsshare_curr.concat(createMetricArray_noperiod(data[brand], items, 'unitsshare_curr'))
        totaldollars_curr = totaldollars_curr.concat(createMetricArray(data[brand], items, 'current', 'totaldollars'))
        nonpromdollars_curr = nonpromdollars_curr.concat(createMetricArray(data[brand], items, 'current', 'nonpromoteddollars'))
        promudollars_curr = promudollars_curr.concat(createMetricArray(data[brand], items, 'current', 'promoteddollars'))
        dollarsshare_curr = dollarsshare_curr.concat(createMetricArray_noperiod(data[brand], items, 'dollarshare_curr'))

        totalunits_prior = totalunits_prior.concat(createMetricArray(data[brand], items, 'prior', 'totalunits'))
        nonpromunits_prior = nonpromunits_prior.concat(createMetricArray(data[brand], items, 'prior', 'nonpromotedunits'))
        promunits_prior = promunits_prior.concat(createMetricArray(data[brand], items, 'prior', 'promotedunits'))
        unitsshare_prior = unitsshare_prior.concat(createMetricArray_noperiod(data[brand], items, 'unitsshare_prior'))
        totaldollars_prior = totaldollars_prior.concat(createMetricArray(data[brand], items, 'prior', 'totaldollars'))
        nonpromdollars_prior = nonpromdollars_prior.concat(createMetricArray(data[brand], items, 'prior', 'nonpromoteddollars'))
        promudollars_prior = promudollars_prior.concat(createMetricArray(data[brand], items, 'prior', 'promoteddollars'))
        dollarsshare_prior = dollarsshare_prior.concat(createMetricArray_noperiod(data[brand], items, 'dollarshare_prior'))

        promoprice_curr = promoprice_curr.concat(createMetricArray_noperiod(data[brand], items, 'promoprice_curr'))
        promoprice_prior = promoprice_prior.concat(createMetricArray_noperiod(data[brand], items, 'promoprice_prior'))
        nonpromoprice_curr = nonpromoprice_curr.concat(createMetricArray_noperiod(data[brand], items, 'nonpromoprice_curr'))
        nonpromoprice_prior = nonpromoprice_prior.concat(createMetricArray_noperiod(data[brand], items, 'nonpromoprice_prior'))
        asp_curr = asp_curr.concat(createMetricArray_noperiod(data[brand], items, 'aspprice_curr'))
        asp_prior = asp_prior.concat(createMetricArray_noperiod(data[brand], items, 'aspprice_prior'))
    })

    //Create Sheet

    var metricstoview = [
        totalunits_curr,
        nonpromunits_curr,
        promunits_curr,
        totaldollars_curr,
        nonpromdollars_curr,
        promudollars_curr,
        asp_curr,
        nonpromoprice_curr,
        promoprice_curr,
        totalunits_prior,
        nonpromunits_prior,
        promunits_prior,
        totaldollars_prior,
        nonpromdollars_prior,
        promudollars_prior,
        asp_prior,
        nonpromoprice_prior,
        promoprice_prior,
        asp_change,
        nonpromoprice_change,
        promoprice_change,
        nonpromunits_change,
        nonpromdollars_change,
        promunits_change,
        promdollars_change,
        unitsshare_curr,
        unitsshare_prior,
        dollarsshare_curr,
        dollarsshare_prior,
        unitshare_change,
        dollarshare_change,
        asp_change_pct,
        nonpromoprice_change_pct,
        promoprice_change_pct
    ]

    //File Information
    var name = 'Item Data Current Year vs Prior Year - '
    var newdate = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
    datestring = datestring.replaceAll(',', '')
    let filenameexcel = name + ' - Extracted - ' + datestring
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Metrics');
    const worksheet = workbook.getWorksheet('Metrics');
    
    worksheet.getColumn(1).values = brands_for_col
    worksheet.getColumn(2).values = items_for_col

    let headers = ['Brand', 'Item'].concat(metricnames)
    //Add Data
    metricstoview.forEach((metric, idx) => {
        worksheet.getColumn(idx + 3).values = metric
    })
    //Add Headers
    worksheet.insertRow(1, headers);

    //Formatting
    setFormat_Decimal(worksheet, [32, 33])
    setFormat_Number(worksheet, [3,4,5,12,13,14,24,26])
    setFormat_Percent(worksheet, [28,29,30,31,34,35,36])
    setFormat_Currency_Price(worksheet, [9,10,11,18,19,20,21,22,23], currencysymbol)
    setFormat_Currency(worksheet, [6,7,8,15,16,17,25,27], currencysymbol)

    //Add in Metdata
    var captionstring = this.state.attr_caption_text
    var titleinfo = this.state.chart_title_info.split('<br>')
    var brandsstring = this.state.brandsstring

    var date_prior_string = 'Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1]
    var date_curr_string = 'Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]

    //Add Selection Information
    const newsheet = workbook.addWorksheet('Selection Criteria');
    const newworksheet = workbook.getWorksheet('Selection Criteria')

    let titles = Object.keys(this.state.chartmetadata)
    let values = Object.values(this.state.chartmetadata)

    var metadatadates = 'Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    

    titles.push('Comparison Date Range: ')
    values.push(metadatadates)

    newworksheet.getColumn(1).values = titles
    newworksheet.getColumn(2).values = values

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, filenameexcel + fileExtension);

}