import React, { Component } from 'react';
import './styles/custom.css'
import { Layout } from './Layout'
import * as Constant from './constants' 

export class Unauthorized extends Component {   
    static displayName = Unauthorized.name;
    render() {        
        console.log(Constant.whereamilist[4])    
        var iframesource = ""
        var toolname = ""        
        if (Constant.whereamilist[4] === 'pnp') {
            iframesource = "https://www.circana.com/contact.html"
            toolname = "Price and Promotion Simulator"            
        }
        else if (Constant.whereamilist[4] === 'pdt') {
            iframesource = "https://www.circana.com/contact.html"
            toolname = "Price and Discount Trends"            
        }
        else {
            iframesource = ""
            toolname = ""            

        }
        return (
            <Layout title="Unauthorized">                
                <div style={{ paddingLeft: '250px', paddingRight: '250px', paddingTop: '50px', paddingBottom: '50px' }}>
                    {(Constant.whereamilist[4] || '' )!== '' ?
                        //<p>To subscribe to Pricing Analytics, please contact your account representative, or complete the form below to learn more.</p>                        
                        <p>To subscribe to Pricing Analytics, please contact your account representative.</p>
                        :
                        <p>The page you are looking for does not exist.</p>
                    }
                    <br />
                    {/*<div style={{ width: "75vw" }}>*/}
                    {/*    */}{/*Render the Approriate Iframe*/}
                    {/*    {(Constant.whereamilist[4] || '' )=== '' ? <span></span>*/}
                    {/*        :*/}
                    {/*        <iframe*/}
                    {/*            src={iframesource}*/}
                    {/*            title={"Learn more about our " + toolname  + " Tool"}*/}
                    {/*            width="100%"*/}
                    {/*            height="750"*/}
                    {/*            frameborder="0"*/}
                    {/*            allowfullscreen*/}
                    {/*            sandbox*/}
                    {/*        />                        */}
                    {/*    }*/}
                    {/*</div>                      */}
                </div>                
            </Layout>
        )
    }
}