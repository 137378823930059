import React, { Component } from 'react';
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import LiftEffDiscByWeek from '../../../Graphs/NewGraphs/LiftEffDiscByWeekNew'

export class LiftAndEfficiencyTrended extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {        

        return (
            <div style={{ width: "100%", height: "100%"}}>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        <LiftEffDiscByWeek
                            chart_title_info={this.props.state.chart_title_info}
                            unitsvar={this.props.state.unitsvar}
                            distinctdates={this.props.state.distinctdates}
                            weekly_data={this.props.state.weekly_data_new}                            
                            showDataTable={this.props.state.showDataTable}
                            showDataTableStyle={this.props.state.showDataTableStyle}
                            attr_caption_text={this.props.state.attr_caption_text}
                            weekagg={this.props.state.filter_object.numweeks}
                            aggperiod={this.props.state.datasettimeagg}
                            product={'PDT'}
                            metadata={this.props.state.chartmetadata}
                        />                
                    </div>
                </div>
            </div>
        )
    }
}