import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class SmoothingWeekSelections extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <div align='left' className="col-sm-12" >
                    <span className="formfieldtitle">Select Number of Weeks for Smoothing</span>
                    <Select
                        options={this.props.state.smoothingWeeks.map(smoothnum => { return { 'label': smoothnum + ' Weeks', 'value': smoothnum } })}
                        onChange={(item, position) => this.props.changesmoothingWeekNum(item, 0)}
                        value={{ 'label': this.props.state.smoothingWeekNum + ' Weeks', 'value': this.props.state.smoothingWeekNum }}                                                 
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}
                    />
                </div>                
            </Fragment>
        )
    }
}



