export default function openRedirectDialog(feature) {
    console.log(feature)
    var redirectfunction

    if (feature === 'priceband') {
        redirectfunction = this.openPriceBandsDefInition
    }

    else if (feature === 'attributegroups') {
        redirectfunction = this.openAttributeGroupsDefinition
    }
    
    this.setState({ newpagewarningdialog: true, redirectfunction }) 

}