export default function handleDateEnd(date) {
    if (date.length < 10) {
    }
    else {
        try {
            var enddate = String(date.format('YYYY-MM-DD'))

            //Check that startdate is less than end date here...                
            let strtDt = new Date(this.state.startdate_raw)
            let endDt = new Date(enddate)
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            console.log(strtDt)
            console.log(endDt)
            if (endDt <= strtDt) {
                console.log('Less Than End')
                let DateCheck_alert_text = "Start Date is Greater Than End Date.  Please Select Another Date Greater Than " + strtDt.toLocaleDateString(undefined, options)
                this.setState({ DateCheck_alert_text, DateCheck_alert: true })
            }
            else {
                this.setState({ selectedend: enddate });
                this.setfilters(enddate, 'enddate')
            }


        }
        catch (err) {

        }
    }
};