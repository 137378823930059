export default function handleChange_twoyago(event) {

    var type = event.currentTarget.value
    var OneYAGO, TwoYAGO, ThreeYAGO

    console.log(type)

    if (type === 'PrevYear') {
        OneYAGO = true
        TwoYAGO = false
        ThreeYAGO = false
    }

    else if (type === 'TwoYearAgo') {
        OneYAGO = false
        TwoYAGO = true
        ThreeYAGO = false
    }

    else if (type === 'ThreeYearAgo') {
        OneYAGO = false
        TwoYAGO = false
        ThreeYAGO = true
    }

    else {
        OneYAGO = false
        TwoYAGO = false
        ThreeYAGO = false
    }

    console.log(OneYAGO)
    console.log(TwoYAGO)
    console.log(ThreeYAGO)

    var options = this.state.dataforviews_romcompare.map(metric => { return { 'label': metric.metric, 'value': metric.metric } })

    this.setState({
        OneYAGO, TwoYAGO, ThreeYAGO
    }, () => {
        this.changeMetricsCompareROM(options)
    }
    )
}