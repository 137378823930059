import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import * as Constant from '../../../constants';

export class ALRMetricRadio extends Component {
    constructor(props) {
        super(props);
    }
    render() {


        return (
            <Fragment>
                <ThemeProvider theme={Constant.muiTheme}>
                    <Radio
                        checked={this.props.state.retailerbrandviewspromo}
                        onChange={this.props.handleChangeRadioRetailerPromoNonPromo}
                        value="Promoted Price"
                        name="RetailerBrandViewsRadioPromotedPrice"
                        label="Promoted Price"
                        inputProps={{ 'aria-label': 'Promoted Price' }}
                    />
                    <span style={{ lineHeight: '5' }}>Promoted Price</span>
                    <Radio
                        checked={this.props.state.retailerbrandviewsnonpromo}
                        onChange={this.props.handleChangeRadioRetailerPromoNonPromo}
                        value="Non-Promoted Price"
                        name="RetailerBrandViewsRadioNonPromotedPrice"
                        label="Non-Promoted Price"
                        inputProps={{ 'aria-label': 'Non-Promoted Price' }}
                    />
                    <span style={{ lineHeight: '5' }}>Non-Promoted Price</span>
                    <Radio
                        checked={this.props.state.retailerbrandviewsbase}
                        onChange={this.props.handleChangeRadioRetailerPromoNonPromo}
                        value="Base Price"
                        name="RetailerBrandViewsRadioBasePrice"
                        label="Base Price"
                        inputProps={{ 'aria-label': 'Base Price' }}
                    />
                    <span style={{ lineHeight: '5' }}>Base Price</span>
                    <Radio
                        checked={this.props.state.retailerbrandviewsasp}
                        onChange={this.props.handleChangeRadioRetailerPromoNonPromo}
                        value="Average Selling Price"
                        name="RetailerBrandViewsRadioAverageSellingPrice"
                        label="Average Selling Price"
                        inputProps={{ 'aria-label': 'Average Selling Price' }}
                    />
                    <span style={{ lineHeight: '5' }}>Average Selling Price</span>
                    <span style={{ width: '100px' }}></span>
                </ThemeProvider>
            </Fragment>
        )
    }
}