import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import selectColourStyles from '../../../styles/SelectColourStyles'

export class SingleBrandDropDown extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle">Select a Brand</span>
                {
                    this.props.state.showTotalCategoryLabel ?
                        <Select
                            options={[{ 'value': 'Total Selection', 'label': 'Total Category' }].concat(this.props.state.brands_sorted.map(brand => { return { 'value': brand, 'label': brand } }))}
                            placeholder={'Select a Brand'}
                            onChange={(item, position) => this.props.changeSelectedBrand(item, 0)}
                            value={this.props.state.selectedBrand === 'No Rows' ? [] : [{ 'label': this.props.state.selectedBrandLabel, 'value': this.props.state.selectedBrand }]}
                            styles={selectColourStyles}
                        />
                        :
                        <Select
                            options={[{ 'value': 'Total Selection', 'label': 'Total Selection' }].concat(this.props.state.brands_sorted.map(brand => { return { 'value': brand, 'label': brand } }))}
                            placeholder={'Select a Brand'}
                            onChange={(item, position) => this.props.changeSelectedBrand(item, 0)}
                            value={this.props.state.selectedBrand === 'No Rows' ? [] : [{ 'label': this.props.state.selectedBrandLabel, 'value': this.props.state.selectedBrand }]}
                            styles={selectColourStyles}
                        />
                }
            </Fragment>
        )
    }
}