import React, { Component } from 'react';
import { Layout } from '../Layout';
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import { TransferList } from './UIElements/TransferList/TransferList'
import '../styles/custom.css'
import * as Constant from '../constants'
import helppdf from "../HelpDocuments/Custom Attribute Groups.pdf"
import helpvideo from "../videos/PDT/Attribute Group Definition.mp4"
import selectColourStyles from '../styles/SelectColourStyles'

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

export class PDTAttributeGroupDefinition extends Component {
    static displayName = PDTAttributeGroupDefinition.name;
    constructor(props) {
        super(props);
        this.useDataset = this.useDataset.bind(this)
        this.SaveAttributeGroups = this.SaveAttributeGroups.bind(this)
        this.LeaveForReport = this.LeaveForReport.bind(this)
        this.setAttr = this.setAttr.bind(this)
        this.openDialog = this.openDialog.bind(this)
        this.openDialog_LeaveReport = this.openDialog_LeaveReport.bind(this)
        this.state = {
            attr_group_defs: {},
            showBackground: true,
            dialogopen: false,
            dialogtitle: "",
            dialogtext: "",
        }
    }
    async componentDidMount() {
        console.log('Getting Datasets')
        var appname = Constant.whereamilist[3]
        const datasetsres = await fetch(Constant.baseapiurl + "Datasets/DatasetDetails")
        var datasetsdata = []
        if (datasetsres.status === 200) {
            datasetsdata = await datasetsres.json()
        }
        else {
            datasetsdata = []
        }
        //set options
        var dataset_lookup = {}
        datasetsdata.forEach(dataset => { dataset_lookup[dataset.dataset_id] = dataset.short_desc })
        let datasetoptions = datasetsdata.map(dataset => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(dataset.dataset_id)) {
                    return { 'label': dataset.short_desc, 'value': dataset.dataset_id }
                }
                else {
                    return null
                }
            }
            else {
                return { 'label': dataset.short_desc, 'value': dataset.dataset_id }
            }
        }).filter(Boolean)
        this.setState({ datasetoptions, datasetsdata })

        //Parse the url, and see if we have a dataset_id and report_id
        var url = window.location.href;
        var datasetid_val, reportid_val, env_val;
        const search = window.location.search;
        const params = new URLSearchParams(search);
        datasetid_val = params.get('datasetid');
        reportid_val = params.get('reportid');
        env_val = params.get('env');

        if (reportid_val && datasetid_val) {
            this.setState({ reportid: reportid_val })
            await this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
            await this.findSettings(datasetid_val)
        }
        else if (datasetid_val) {
            await this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
            await this.findSettings(datasetid_val)
        }
        else {
            this.setState({ showBackground: false })
        }

        if (env_val) {
            this.setState({ extra_param: '?env=staging' })
        }
        else {
            this.setState({ extra_param: '' })
        }
    }

    openDialog(title, text) {
        this.setState({
            dialogopen: true,
            dialogtitle: title,
            dialogtext: text
        })
    }

    openDialog_LeaveReport() {
        this.setState({ dialogopen_LeaveReport: true,})
    }

    async useDataset(selectedOptions) {
        console.log(selectedOptions)
        this.setState({ selecteddataset: selectedOptions })
        //Look up Dataset Info...
        let dataset_id = selectedOptions.value

        let datasets_url = Constant.baseapiurl + "Datasets/" + dataset_id
        let dataset_response = await fetch(datasets_url)
        if (dataset_response.status === 200) {
            var dataset_data = await dataset_response.json()
            var currencycode = ''
            var currencyType = ''
            var currencyTypeSingular = ''
            var currencysymbol = ''
            if (dataset_data.currency === 'GBP') {
                currencycode = 'GBP'
                currencyType = 'Pounds'
                currencyTypeSingular = 'Pound'
                currencysymbol = '£'
                //currencysymbol = '&#163;'
            }
            else if (dataset_data.currency === 'CAD') {
                currencycode = 'CAD'
                currencyType = 'Dollars'
                currencyTypeSingular = 'Dollar'
                currencysymbol = '$'
            }
            else if (dataset_data.currency === 'EUR') {
                currencycode = 'EUR'
                currencyType = 'Euros'
                currencyTypeSingular = 'Euro'
                currencysymbol = '€'
                //currencysymbol = '&#8364;'
            }
            else if (dataset_data.currency === 'MXN') {
                currencycode = 'MXN'
                currencyType = 'Pesos'
                currencyTypeSingular = 'Peso'
                currencysymbol = '$'
            }
            //If Not Explicitly set, use dollars
            else {
                currencycode = 'USD'
                currencyType = 'Dollars'
                currencyTypeSingular = 'Dollar'
                currencysymbol = '$';
            }
            this.setState({ currencycode, currencyType, currencyTypeSingular, currencysymbol })
        }
        else {
            console.log('***** AttributeGroupDefinition.js useDataset failed to fetch ' + datasets_url +
                        ' with status ' + dataset_response.status + ', setting errorAlert_report = true')
            dataset_data = {}
            this.setState({ errorAlert_report: true })
        }

        //Pull Attributes
        let dataset_attr_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id + '/AttributeValues')
        if (dataset_attr_response.status === 200) {
            var dataset_attr_data = await dataset_attr_response.json()
        }
        let dataset_attr_global_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id + '/GlobalAttributeValues')
        if (dataset_attr_global_response.status === 200) {
            var dataset_attr_global_data = await dataset_attr_global_response.json()
        }

        //Create Lookups
        let allattrs = (dataset_data.attributes || []).concat(dataset_data.global_attributes || [])
        let allattrs_human = (dataset_data.attributes_human || []).concat(dataset_data.global_attributes_human || [])
        let attr_lookup = {}
        allattrs.forEach((attr, idx) => {
            attr_lookup[attr] = allattrs_human[idx]
        })

        //Attr Val Lookup
        let attr_val_lookup = {}
        dataset_attr_data.forEach(attr => {
            attr_val_lookup[attr.attribute] = attr.attribute_values
        })

        dataset_attr_global_data.forEach(attr => {
            attr_val_lookup[attr.global_attribute] = attr.global_attribute_values
        })

        //Create Attribute Options
        let attr_options = allattrs.map(attr => { return { 'label': attr_lookup[attr], 'value': attr } })

        this.setState({ attr_val_lookup, attr_options, attr_lookup })
    }

    async findSettings(datasetid_val) {
        //Look and See if there are attribute groups defined...

        let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetid_val)
        var user_dataset_settings_data
        if (user_dataset_settings_response.status === 200) {
            user_dataset_settings_data = await user_dataset_settings_response.json()
        }
        else {
            user_dataset_settings_data = []
        }

        var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)
        var attributegroupssettings_data
        if (usersettings_list.includes('AttributeGroups')) {
            let attributegroupsettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetid_val + '/AttributeGroups')
            if (attributegroupsettings_response.status === 200) {
                attributegroupssettings_data = await attributegroupsettings_response.json()
            }
        }
        else {
            attributegroupssettings_data = {}
        }
        //Mao through Each Attribute in Settings and create valid values...
        console.log(this.state.attr_val_lookup)

        let attr_valid_lookup = this.state.attr_valid_lookup || {}
        Object.keys(attributegroupssettings_data.setting_value || {}).forEach(attribute => {
            let used_values = []
            console.log(attributegroupssettings_data.setting_value)
            console.log(attributegroupssettings_data.setting_value[attribute])
            attributegroupssettings_data.setting_value[attribute].forEach(group => {
                used_values = used_values.concat(group.attr_vals)
            })
            console.log(used_values)
            let attr_vals = this.state.attr_val_lookup[attribute]
            let valid_vals = not(attr_vals, used_values)
            attr_valid_lookup[attribute] = valid_vals
        })

        this.setState({ attr_group_defs: (attributegroupssettings_data.setting_value || {}), attr_valid_lookup: attr_valid_lookup, showBackground: false })
    }

    async SaveAttributeGroups(goBack) {
        //Perform Error Checks....
        //Check for attr_group_defs that each group has at least one value...
        let group_defs = this.state.attr_group_defs
        let attrs_defined = Object.keys(group_defs)
        var numerrors = 0
        var error_list = []
        attrs_defined.forEach(attr => {
            let groups = group_defs[attr]
            groups.forEach(group => {
                if (group.attr_vals.length < 1) {
                    numerrors += 1
                    error_list.push({
                        'errortype': 'Too few values. ',
                        'errortext': 'All Groups must have at least one value assigned. ',
                        'attribute': this.state.attr_lookup[attr],
                        'groupname': group.groupname
                    })
                }
                else if (!group.groupname) {
                    numerrors += 1
                    error_list.push({
                        'errortype': 'Name must be provided. ',
                        'errortext': 'All Groups must have a name. ',
                        'attribute': this.state.attr_lookup[attr],
                        'groupname': group.groupname
                    })
                }
            })
        })

        if (numerrors === 0) {
            const setting = this.state.attr_group_defs
            let datasetidnum = this.state.selecteddataset.value
            let url = Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/AttributeGroups'
            console.log(JSON.stringify(setting));
            let settings_response = await fetch(url,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(setting)
                })

            if (settings_response.status === 200) {
                //if report id, then send back to the report page...
                this.openDialog('Attribute Groups Saved', 'Attribute Groups Successfully Saved.')
                //Allow the user a choice to keep editing or go back to the report...
                if (goBack) {
                    if (this.state.reportid) {

                        var urlBack
                        var appname = Constant.whereamilist[3]
                        if (this.state.reportid) {
                            urlBack = Constant.baseurl + appname + '/Report/' + this.state.reportid + this.state.extra_param
                        }
                        else {
                            urlBack = Constant.baseurl + appname + '/Home'
                        }
                        window.location.href = urlBack

                    }
                }
            }
            else {
                this.openDialog('Error', 'There was an Error Setting the Attribute Groups')
            }
        }
        else {
            var errortext = ''
            error_list.forEach(error => {
                errortext += error.errortype + error.errortext + 'Error was found in attribute ' + error.attribute + ' in group ' + error.groupname +'.  '
            })
            this.openDialog('Errors', 'There are the following Errors. ' + errortext)
        }
    }

    LeaveForReport() {
        var url
        var appname = Constant.whereamilist[3]
        if (this.state.reportid) {
            url = Constant.baseurl + appname + '/Report/' + this.state.reportid + this.state.extra_param
        }
        else {
            url = Constant.baseurl + appname + '/Home'
        }

        window.location.href = url
    }

    setAttr(selectedOptions) {
        console.log(selectedOptions)
        this.setState({ selectedattr: selectedOptions })
    }

    render() {
        console.log(this.state)

        const clearChecked = (attr) => {
            let attr_checked_lookup = this.state.attr_checked_lookup || {}
            attr_checked_lookup[attr] = []
            this.setState({ attr_checked_lookup })
        }

        const addattrgroup = (attr) => {
            console.log(attr)

            let attr_vals = ((this.state.attr_val_lookup || {})[attr] || [])
            let valid_vals = ((this.state.attr_valid_lookup || {})[attr] || attr_vals)

            //Check that valid_values are not empty

            if (valid_vals.length > 0) {
                let attr_group_defs = this.state.attr_group_defs
                let existinggroups = (attr_group_defs[attr] || [])

                //check that all existing groups have at least one value
                let numerrors = 0
                existinggroups.forEach(group => {
                    if (group.attr_vals.length < 1) {
                        numerrors += 1
                    }
                })

                if (numerrors === 0) {
                    let newgroupnum = existinggroups.length + 1
                    let newgroup = { 'groupname': 'New Group ' + newgroupnum, 'attr_vals': [] }
                    existinggroups.push(newgroup)
                    attr_group_defs[attr] = existinggroups
                    this.setState({ attr_group_defs })
                }
                else {
                    this.openDialog('Can Not Add Group', 'Please add values to the existing group that is empty.')
                }
            }
            else {
                this.openDialog('Can Not Add Group', 'There are no more values to add to groups.')
            }
        }

        const removeattrgroup = (attr, idx) => {
            console.log(attr)
            console.log(idx)
            let attr_group_defs = this.state.attr_group_defs
            let existinggroups = (attr_group_defs[attr] || [])
            let grouptodelete = existinggroups[idx]

            let groupvals = grouptodelete['attr_vals']
            let attr_val_lookup = this.state.attr_val_lookup
            let attr_valid_lookup = this.state.attr_valid_lookup || {}
            let attr_vals = ((attr_val_lookup || {})[attr] || [])
            let valid_vals = ((attr_valid_lookup || {})[attr] || attr_vals)

            let newvalidvals = valid_vals.concat(groupvals)
            attr_valid_lookup[attr] = newvalidvals

            existinggroups.splice(idx,1)
            attr_group_defs[attr] = existinggroups
            this.setState({ attr_group_defs, attr_valid_lookup })
            clearChecked(attr);
        }

        const handleGroupNameChange = (attr, idx) => {
            let attr_group_defs = this.state.attr_group_defs
            let existinggroups = (attr_group_defs[attr] || [])

            let existingnames = existinggroups.map(group=> group.groupname)

            let group = existinggroups[idx]
            let ElID = "groupname" + idx
            let name = document.getElementById(ElID).value

            if (existingnames.includes(name)) {
                this.openDialog('Name Already Exists', 'Name Already Exists.  Adding COPY to name')
                name += ' COPY'
            }

            group['groupname'] = name
            this.setState({ attr_group_defs })
        }

        const handleToggle = (attr, value) => {
            let attr_checked_lookup = this.state.attr_checked_lookup || {}
            let checked = ((attr_checked_lookup || {})[attr] || [])
            let currentIndex = checked.indexOf(value);
            let newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);
            }
            else {
                newChecked.splice(currentIndex, 1);
            }
            attr_checked_lookup[attr] = newChecked
            this.setState({ attr_checked_lookup })
        }

        const handleCheckedRight = (attr, groupnum) => {
            console.log(attr)
            console.log(groupnum)
            let attr_group_defs = this.state.attr_group_defs
            let attr_val_lookup = this.state.attr_val_lookup
            let attr_valid_lookup = this.state.attr_valid_lookup || {}
            let attr_checked_lookup = this.state.attr_checked_lookup

            let checked = ((attr_checked_lookup || {})[attr] || [])
            let attr_vals = ((attr_val_lookup || {})[attr] || [])
            let valid_vals = ((attr_valid_lookup || {})[attr] || attr_vals)
            let group = (attr_group_defs[attr] || [])[groupnum]

            let left = valid_vals
            let right = group.attr_vals
            let leftchecked = intersection(checked, left)

            let newvals = right.concat(leftchecked)
            let newvalid_vals = not(left, leftchecked)

            attr_group_defs[attr][groupnum]['attr_vals'] = newvals
            attr_valid_lookup[attr] = newvalid_vals

            //Set State
            this.setState({
                attr_group_defs, attr_valid_lookup
            })

            clearChecked(attr);
        }

        const handleCheckedLeft = (attr, groupnum) => {
            console.log(attr)
            console.log(groupnum)
            let attr_group_defs = this.state.attr_group_defs
            let attr_val_lookup = this.state.attr_val_lookup
            let attr_valid_lookup = this.state.attr_valid_lookup || {}
            let attr_checked_lookup = this.state.attr_checked_lookup

            let checked = ((attr_checked_lookup || {})[attr] || [])
            let attr_vals = ((attr_val_lookup || {})[attr] || [])
            let valid_vals = ((attr_valid_lookup || {})[attr] || attr_vals)
            let group = (attr_group_defs[attr] || [])[groupnum]

            let left = valid_vals
            let right = group.attr_vals

            let rightchecked = intersection(checked, right)

            let newvalid_vals = left.concat(rightchecked)
            let newvals = not(right, rightchecked)

            attr_group_defs[attr][groupnum]['attr_vals'] = newvals
            attr_valid_lookup[attr] = newvalid_vals

            //Set State
            this.setState({
                attr_group_defs, attr_valid_lookup
            })

            clearChecked(attr);
        }

        const dropdownInput = {
            width: '100%',
            //height: '50px',
            borderRadius: '5px',
            //padding: '10px',
            fontFamily: 'Roboto',
            backgroundColor:'#FFF'
        }

        const handleCloseDialog = () => {
            this.setState({ dialogopen: false })
        }

        const handleCloseDialog_LeaveReport = () => {
            this.setState({ dialogopen_LeaveReport: false })
        }

        return (
            <Layout title="Attribute Group Definition" >
                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <Dialog
                    open={this.state.dialogopen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dialogtitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.dialogtext}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog} className="btn btn-primary" autoFocus>
                            Ok
		                </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogopen_LeaveReport}
                    onClose={handleCloseDialog_LeaveReport}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">Go Back to Report?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to navigate back to the report.  Would you like to save your changes, or navigate back without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog_LeaveReport} className="btn btn-cancel" autoFocus>
                            Cancel
                        </button>
                        <button onClick={() => this.SaveAttributeGroups(true)} className="btn btn-primary" autoFocus>
                            Save Changes and Go Back
                        </button>
                        <button onClick={() => this.LeaveForReport()} className="btn btn-secondarynpd" autoFocus>
                            Go Back Without Saving
                        </button>
                    </DialogActions>
                </Dialog>

                <div name='banner' className='banner'>
                    <h1white align='center'>Price and Discount Trends Attribute Groups Definition</h1white><br/>
                    <h2white align='center'>Use this page to set custom attribute groups</h2white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <div className='row'>
                        <div className='col-sm-12' style={{ padding: '12px' }}>
                            <h7> Video Tutorial Coming Soon</h7>
                            {/*<a href={helppdf} target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.2rem' }}> Click here to view instructions </a>*/}
                            {/*<br />*/}
                            {/*<a href={helpvideo} target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.2rem' }}>Click here to watch a how to video</a>*/}
                        </div>
                        <div className='col-sm-12' style={{padding: '12px'}}>
                            <h4>Select a Dataset</h4>
                            <div className='col-sm-6' >
                                <Select
                                    inputId="datasetdropdown"
                                    options={this.state.datasetoptions}
                                    placeholder={'Select a Dataset'}
                                    onChange={(options) => this.useDataset(options)}
                                    value={this.state.selecteddataset}
                                    styles={selectColourStyles}
                                />
                            </div>
                        </div>
                        <div className='col-sm-12' style={{ padding: '12px' }}>
                            <h4>Select an Attribute</h4>
                            <div className='col-sm-6'>
                                <Select
                                    inputId="attrdropdown"
                                    options={this.state.attr_options}
                                    placeholder={'Select an Attribute'}
                                    onChange={(options) => this.setAttr(options)}
                                    value={this.state.selectedattr}
                                    styles={selectColourStyles}
                                />
                            </div>
                        </div>
                        {this.state.selectedattr ?
                            <div className='col-sm-12'>
                                <div className='col-sm-12 row'>
                                    <Tooltip
                                        title='Go Back to Report'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={() => this.openDialog_LeaveReport()}
                                        >
                                            <SettingsBackupRestoreRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title='Add An Attribute Group'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={(attr) => addattrgroup((this.state.selectedattr || {}).value)}
                                        >
                                            <AddRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title='Save Attribute Group'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={()=>this.SaveAttributeGroups(false)}
                                        >
                                            <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                {(this.state.attr_group_defs[(this.state.selectedattr || {}).value] || []).length > 0 ?
                                    <div className='col-sm-12' style={{ padding: '12px' }}>
                                        Note: All groups must have at least 1 value assigned to them.  However, not all attribute values need to be assigned to a group. Any unassigned values will be displayed in the charts by themselves when viewing by groups.
                                    </div>
                                    :
                                    <div/>
                                }

                                {(this.state.attr_group_defs[(this.state.selectedattr || {}).value] || []).map((group, idx) => {
                                    let groupnum = idx
                                    let attr_vals = ((this.state.attr_val_lookup || {})[(this.state.selectedattr || {}).value] || [])
                                    let valid_vals = ((this.state.attr_valid_lookup || {})[(this.state.selectedattr || {}).value] || attr_vals)
                                    let checked = ((this.state.attr_checked_lookup || {})[(this.state.selectedattr || {}).value] || [])
                                    let elID = "groupname" + groupnum
                                    return <div key={ 'groupdef_' + groupnum } className='col-sm-12 row' style={{padding:'12px'}}>
                                        <div className='col-sm-3' style={{ padding: "12px" }}>
                                        {/*Dropdown with name*/}
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                placeholder="Group Name"
                                                inputProps={{ 'aria-label': 'naked', id: elID }}
                                                onChange={(attr, idx) => handleGroupNameChange((this.state.selectedattr || {}).value, groupnum)}
                                                style={dropdownInput}
                                                value={group.groupname}
                                            />
                                        </div>
                                        <div className='col-sm-3'>
                                            {/*Delete Button*/}
                                            <Tooltip
                                                title='Delete This Attribute Group'
                                                placement='top-start'
                                            >
                                                <IconButton
                                                    onClick={(attr, idx) => removeattrgroup((this.state.selectedattr || {}).value, groupnum)}
                                                >
                                                    <DeleteRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className='col-sm-6'/>
                                        <div className='col-sm-12' style={{ padding: "12px" }}>
                                            {/*Transfer List*/}
                                            <TransferList
                                                left={valid_vals}
                                                right={group.attr_vals}
                                                attr={(this.state.selectedattr || {}).value}
                                                groupnum={groupnum}
                                                checked={checked}
                                                handleToggle={handleToggle}
                                                handleCheckedRight={handleCheckedRight}
                                                handleCheckedLeft={handleCheckedLeft}
                                            />
                                        </div>
                                    </div>
                                })}
                            </div>
                            :
                            <div className='col-sm-12'>
                                <div className='col-sm-12 row'>
                                    <Tooltip
                                        title='Go Back to Report'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={() => this.openDialog_LeaveReport()}
                                        >
                                            <SettingsBackupRestoreRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}
