import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import selectColourStyles from '../../../styles/SelectColourStyles'

const dropdownStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        maxHeight: "75px",
        overflowY: "auto"
    }),
}

export class MetricLevelOptionsBaseInc extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        //console.log(this.props.state.levelOptions_BaseInc)
        //console.log(Array.from(new Set(this.props.state.levelOptions_BaseInc)))
        //let values = this.props.state.levelOptions_BaseInc.map(option => option.value)
        let newOptions = []
        let usedvalues = []
        this.props.state.levelOptions_BaseInc.forEach(option => {
            if (!usedvalues.includes(option.value)) {
                usedvalues.push(option.value)
                newOptions.push(option)
            }
        })

        return (
            <Fragment>
                <span className="formfieldtitle">Select A Level</span>
                <Select
                    options={newOptions.concat(Object.keys(this.props.state.attributes).map(attr => { return { 'label': this.props.state.attributenamelabels[attr], 'value': attr } }))}
                    placeholder={'Select A Level'}
                    onChange={(item) => this.props.changeSelectedLevel_BaseInc(item)}
                    value={this.props.state.selectedLevel_BaseInc}
                    styles={dropdownStyles}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}