export default function handleChangeRadioRetailerPromoNonPromo (event) {
    var type = event.currentTarget.value
    if (type === 'Promoted Price') {
                
        this.setState({ retailerbrandviewspromo: true, retailerbrandviewsnonpromo: false, retailerbrandviewsbase: false, retailerbrandviewsasp: false, showSnackbar_changebrands: true })
    }
    else if (type === 'Non-Promoted Price') {
                
        this.setState({ retailerbrandviewspromo: false, retailerbrandviewsnonpromo: true, retailerbrandviewsbase: false, retailerbrandviewsasp: false, showSnackbar_changebrands: true })
    }
    else if (type === 'Base Price') {
                
        this.setState({ retailerbrandviewspromo: false, retailerbrandviewsnonpromo: false, retailerbrandviewsbase: true, retailerbrandviewsasp: false, showSnackbar_changebrands: true })
    }
    else if (type === 'Average Selling Price') {
                
        this.setState({ retailerbrandviewspromo: false, retailerbrandviewsnonpromo: false, retailerbrandviewsbase: false, retailerbrandviewsasp: true, showSnackbar_changebrands: true })
    }
}