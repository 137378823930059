import React, { Fragment, Component } from 'react';
import * as Constant from '../constants';

export class TopBottomGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        var currencycode = this.props.state.currencycode
        return (
            <Fragment>
                {this.props.state.all_outlets.map((outlet, idx) => {
                    return <div className='col-sm-6' key={outlet + '_' + idx}>
                        <h4>{outlet}</h4>
                        {this.props.state.metricsforindexviews_selected.metrictype === 'units' ?
                            <p>Average: {Math.round((this.props.state.indices[idx] || 0)).toLocaleString()}</p>
                            :
                            this.props.state.metricsforindexviews_selected.metrictype === 'percent' ?
                                <p>Average: {(this.props.state.indices[idx] || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                :
                                this.props.state.metricsforindexviews_selected.metrictype === 'currency' ?
                                    <p>Average: {Math.round((this.props.state.indices[idx] || 0)).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                    :
                                    <p></p>
                        }

                    </div>
                })}
                {!this.props.state.indexTopBottom ?
                    this.props.state.all_outlets.map((outlet, idx) => {
                        return <div className='col-sm-6' key={'TopBottom_' + outlet + '_' + idx}>
                            <Constant.NPDTable>
                                <Constant.NPDTableBody>
                                    <Constant.NPDTableRow key="header_insights">
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Week</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{this.props.state.metricsforindexviews_selected.metric}</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>% Over Average</Constant.NPDTableCell>
                                    </Constant.NPDTableRow>
                                    {((this.props.state.weekindices[idx] || []).sort(function (a, b) { return b.value - a.value })).map(week => {
                                        if (week.index >= 1.2) {
                                            return <Constant.NPDTableRow key={outlet + week.date}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{week.date}</Constant.NPDTableCell>
                                                {this.props.state.metricsforindexviews_selected.metrictype === 'units' ?
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(week.value).toLocaleString()}</Constant.NPDTableCell>
                                                    :
                                                    this.props.state.metricsforindexviews_selected.metrictype === 'percent' ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{week.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                        :
                                                        this.props.state.metricsforindexviews_selected.metrictype === 'currency' ?
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(week.value).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                            :
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}></Constant.NPDTableCell>
                                                }
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(week.index - 1).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        }
                                        else {
                                            return null
                                        }
                                    })}
                                </Constant.NPDTableBody>
                            </Constant.NPDTable>
                        </div>
                    })
                    :
                    this.props.state.all_outlets.map((outlet, idx) => {
                        return <div className='col-sm-6' key={'AllOutlets_' + outlet + '_' + idx}>
                            <Constant.NPDTable>
                                <Constant.NPDTableBody>
                                    <Constant.NPDTableRow key="header_insights">
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Week</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{this.props.state.metricsforindexviews_selected.metric}</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>% Under Average</Constant.NPDTableCell>
                                    </Constant.NPDTableRow>
                                    {((this.props.state.weekindices[idx] || []).sort(function (a, b) { return a.value - b.value })).map(week => {
                                        if (week.index <= .8) {
                                            return <Constant.NPDTableRow key={outlet + week.date}>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{week.date}</Constant.NPDTableCell>
                                                {this.props.state.metricsforindexviews_selected.metrictype === 'units' ?
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(week.value).toLocaleString()}</Constant.NPDTableCell>
                                                    :
                                                    this.props.state.metricsforindexviews_selected.metrictype === 'percent' ?
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{week.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                        :
                                                        this.props.state.metricsforindexviews_selected.metrictype === 'currency' ?
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(week.value).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                            :
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}></Constant.NPDTableCell>
                                                }
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(week.index - 1).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                        }
                                        else {
                                            return null
                                        }
                                    })}
                                </Constant.NPDTableBody>
                            </Constant.NPDTable>
                        </div>
                    })
                }
            </Fragment>
        )
    }
}