import React, { Component } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'
import { Layout } from '../Layout'
import clsx from 'clsx'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DirectionsRunRoundedIcon from '@material-ui/icons/DirectionsRunRounded'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import Modal from '@mui/material/Modal'
import Radio from '@material-ui/core/Radio'
import Select from 'react-select'
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import * as Constant from '../constants'
import selectColourStyles from '../styles/SelectColourStyles'
import '../styles/custom.css'
import "./index.css"
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'

// API CRUD requests and error handling
import { ApiErrorDialog, openApiErrorDialog, closeApiErrorDialog, on_api_entity_crud_error } from '../ApiMethods/ApiErrorDialog'
import { entityCrud } from '../ApiMethods/EntityCrud'

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paperwide: {
        position: 'absolute',
        width: 1000,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

const dateInput = {
    width: '300px',
    height: '50px',
    borderRadius: '5px',
    //padding: '10px',
    fontFamily: 'Roboto',
}

export function StyledRadio(props) {
    const classes = useStyles()
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="#565a5c"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}

export class PDTListofAllExtracts extends Component {
    static displayName = PDTListofAllExtracts.name

    constructor(props) {
        super(props)
        this.handleClickRunExtract = this.handleClickRunExtract.bind(this)
        this.handleClickRun = this.handleClickRun.bind(this)
        this.onChangeRunCloud = this.onChangeRunCloud.bind(this)
        this.onChangeRunSAS = this.onChangeRunSAS.bind(this)
        this.onChangeSkipExtract = this.onChangeSkipExtract.bind(this)
        this.onChangeSkipCalculations = this.onChangeSkipCalculations.bind(this)
        this.onChangeResumeIfPossible = this.onChangeResumeIfPossible.bind(this)
        this.onChangeNBrands = this.onChangeNBrands.bind(this)
        this.onChangeNItems = this.onChangeNItems.bind(this)
        this.onChangeJupServer = this.onChangeJupServer.bind(this)
        this.onChangeUseDisaggregateFile = this.onChangeUseDisaggregateFile.bind(this)
        this.onChangeContainer = this.onChangeContainer.bind(this)
        this.onChangeForceEuBpFile = this.onChangeForceEuBpFile.bind(this)
        this.entityCrud = entityCrud.bind(this)
        this.openApiErrorDialog = openApiErrorDialog.bind(this)
        this.closeApiErrorDialog = closeApiErrorDialog.bind(this)
        this.on_api_entity_crud_error = on_api_entity_crud_error.bind(this)
        this.state = {
            error: null,
            isLoaded: false,
            selectedEtlId: '',
            selectedServer: '',
            extracts: [],
            jupServer: [],
            datamap: {},
            store_group_map: {},
            errorAlert: false,
            extract_favorites: [],
            page: 0,
            rowsPerPage: 25,
            header_sort_active: [true, false, false, false, false, false, false, false, false],
            header_sort_direction: ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc'],
            showBackground: true,
            displayReloadDialog: false,
            openDialog: false,
            setOpenDialog: false,
            lastrun_etl_id_info: {},
            extractsdata: [],
            openModal: false,
            n_brands: null,
            n_items: null,
            runCloud: false,
            runSAS: false,
            skipExtract: false,
            skip_extract_text: '',
            skipCalculations: false,
            skip_calculations_text: '',
            resumeIfPossible: false,
            resume_if_possible_text: '',
            forceEuBpFile: false,
            force_eu_bp_file_text: '',
            datasetsdata: [],
            etlid: [],
            extractname: [],
            industries: [],
            countries: [],
            user: [],
            filters: {
                'etlid': ['All'],
                'etlshortdesc': ['All'],
                'country': ['All'],
                'industry': ['All'],
                'user': ['All'],
                'job_start': ['All'],
                'job_complete': ['All'],
                'lastupdated_start': null,
                'lastupdated_end': null
            },
            countrynamelookup: {}
        }
    }

    async handleClickRun() {
        // find the parameters of the report and set to the state.
        let my_results = await this.entityCrud('getting all information for the PDT extracts page', [
            { 'url': Constant.baseapiurl_gen + "etl/Pdt_Etl/" + this.state.selectedEtlIdModal, 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            console.log('There was an error running the get extract for run')
            return
        }

        let result = my_results.ResultList[0]
        console.log(result)
        console.log('RETRIEVE ETL FOR EDIT RESULT')
        var selectedAttrID = result.attribute_ids
        var selectedCategoryLevelName = result.category_level
        var category_list = result.category_list
        var selectedChannelOption = result.channel_reporting_level
        var editChannels = result.channels
        var contract_ids = result.contract_ids
        var selectedReportName = result.etl_description
        var selectedEmailAddress = result.etl_email_address
        var selectedShortDesc = result.etl_shortname
        var selectedBrand = result.forced_brand_list
        var global_attribute_ids = result.global_attribute_ids
        var global_attribute_filters = result.global_attribute_filters
        var selectedIndustry = result.industry_id
        var selectedDelFreq = result.job_frequency
        var selectedOutlet = result.outlets
        var selectedOutputAgg = result.time_aggregation
        var selectedTimePeriodType = result.time_period_type

        this.setState({
            selectedAttrID, selectedCategoryLevelName, category_list, selectedChannelOption, editChannels, contract_ids,
            selectedReportName, selectedEmailAddress, selectedShortDesc, selectedBrand, global_attribute_ids, global_attribute_filters,
            selectedIndustry, selectedDelFreq, selectedOutlet, selectedOutputAgg, selectedTimePeriodType
        })

        console.log(this.state)
        const report = {
            attribute_ids: this.state.selectedAttrID,
            category_level: this.state.selectedCategoryLevelName,
            category_list: this.state.selectedCategory,
            channel_reporting_level: this.state.selectedChannelOption,
            channels: this.state.editChannels,
            etl_id: this.state.etlIDEdit,
            contract_ids: this.state.contract_ids,
            etl_description: this.state.selectedReportName,
            etl_email_address: this.state.selectedEmailAddress,
            etl_shortname: this.state.selectedShortDesc,
            forced_brand_list: this.state.selectedBrand,
            industry_id: this.state.selectedIndustry,
            is_manufacturer: true,
            is_retailer: false,
            job_frequency: this.state.selectedDelFreq,
            outlets: this.state.selectedOutlet,
            time_aggregation: this.state.selectedOutputAgg,
            time_period_type: this.state.selectedTimePeriodType,
            time_updated: this.state.editTimeUpdated,
            global_attribute_filters: this.state.global_attribute_filters || [],
            global_attribute_ids: this.state.global_attribute_ids || []
        }
        // Show the backdrop & Snackbar
        // set showBackdrop to true to show the spinner
        console.log(JSON.stringify(report))

        this.setState({ showBackdrop: false, setOpenSaveRun: true, openModal: false, showBackdrop: false })
        if (this.state.runCloud === true && this.state.runSAS === false) {
            let my_results = await this.entityCrud('launching a PDT ETL in the cloud', [
                { 'url': Constant.baseapiurl_gen + "etl/PdtEtlExecution/" + this.state.selectedEtlIdModal + "?cloud=" + this.state.runCloud + "&job_flags=trap_col_error" + this.state.skip_extract_text + this.state.skip_calculations_text + this.state.resume_if_possible_text + this.state.force_eu_bp_file_text + this.state.useDisaggFile_text + this.state.selectedContainer, 'method': 'GET', 'body': null },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails
                console.log('There was an error launching the PDT ETL in the cloud')
                return
            }

            let runextract_response = my_results.ResultList[0]
            console.log(runextract_response)
        } else if (this.state.runCloud === false && this.state.runSAS === true) {
            const jupyter_launch_body = {
                jupyter_server: this.state.selectedServer,
                n_brands: this.state.n_brands,
                n_items: this.state.n_items,
                job_flags: "job_flags=trap_col_error" + this.state.skip_extract_text + this.state.skip_calculations_text + this.state.resume_if_possible_text + this.state.force_eu_bp_file_text
            }
            //console.log('REPORT 2 BODY', JSON.stringify(report2))

            let my_results = await this.entityCrud('launching a PDT ETL in Jupyter', [
                { 'url': Constant.baseapiurl_gen + "etl/PdtEtlExecution/" + this.state.selectedEtlIdModal + "?cloud=" + this.state.runCloud, 'method': 'POST', 'body': jupyter_launch_body },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails
                console.log('There was an error launching the PDT ETL in Jupyter')
                return
            }

            let runextract_response = my_results.ResultList[0]
            console.log(runextract_response)
        }
    }

    async handleClickRunExtract(event) {
        let selectedEtlId = event.target.value
        this.setState({ selectedEtlId })
    }

    onChangeNItems(selNItems, action) {
        let n_items = parseInt(selNItems.target.value, 10)
        if (isNaN(n_items)) {
            this.setState({ n_items: null })
        } else {
            this.setState({ n_items: n_items })
        }
    }

    onChangeNBrands(selNBrands, action) {
        let n_brands = parseInt(selNBrands.target.value, 10)
        if (isNaN(n_brands)) {
            this.setState({ n_brands: null })
        } else {
            this.setState({ n_brands: n_brands })
        }
    }

    onChangeForceEuBpFile(event, action) {
        this.setState({ forceEuBpFile: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ force_eu_bp_file_text: ',use_eu_bp_file' })
        } else {
            this.setState({ force_eu_bp_file_text: ' ' })
        }
    }

    onChangeJupServer(selServer, action) {
        let selectedServer = selServer.value
        this.setState({ selectedServer })
    }

    onChangeRunCloud(event, action) {
        this.setState({ runCloud: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ runSAS: false, n_brands: null, n_items: null, selectedServer: '' })
        }
    }

    onChangeRunSAS(event, action) {
        this.setState({ runSAS: event.target.checked, selectedServer: '', selectedContainer: '', useDisaggFile: false }) // n_brands: null, n_items: null,
        if (event.target.checked === true) {
            this.setState({ runCloud: false })
        }
    }

    onChangeSkipExtract(event, action) {
        this.setState({ skipExtract: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ skip_extract_text: ',skip_extract' })
        } else {
            this.setState({ skip_extract_text: ' ' })
        }
    }

    onChangeSkipCalculations(event, action) {
        this.setState({ skipCalculations: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ skip_calculations_text: ',skip_calculations' })
        } else {
            this.setState({ skip_calculations_text: ' ' })
        }
    }

    onChangeResumeIfPossible(event, action) {
        this.setState({ resumeIfPossible: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ resume_if_possible_text: ',resume_if_possible' })
        } else {
            this.setState({ resume_if_possible_text: ' ' })
        }
    }

    onChangeUseDisaggregateFile(event, action) {
        this.setState({ useDisaggFile: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ useDisaggFile_text: ',disaggregate_output&container=' })
        } else {
            this.setState({ useDisaggFile_text: '', selectedContainer: '' })
        }
    }

    onChangeContainer(selContainer, action) {
        let selectedContainer = selContainer.value
        this.setState({ selectedContainer })
    }

    // Process the Extracts Detail (contains id, shortname, complete, start)
    processExtractsDetail = async (extractsdetaildata) => {
        console.log('Processing Extract Details')
        let dt = new Date()
        let offset = dt.getTimezoneOffset() * 60 * 1000
        extractsdetaildata.map((extract, idx) => {
            try {
                extract.time_updated_adj = (new Date(Date.parse(extract.job_start || '') - offset)).toISOString()
            } catch (error) {
                extract.time_updated_adj = ''
            }
        })
        let extract_sorted = extractsdetaildata.sort(function (a, b) { return Number(a.etl_id) - Number(b.etl_id) })

        for (var i = 0; i < extract_sorted.length; i++) {
            let extract = extract_sorted[i]
            extract['Extract_ID'] = this.state.datamap[extract.etl_id]
            extract['favorite'] = false

            // Perform Logic on Last Udated Date....
            var date_dictionary = {
                '01': 'January',
                '02': 'February',
                '03': 'March',
                '04': 'April',
                '05': 'May',
                '06': 'June',
                '07': 'July',
                '08': 'August',
                '09': 'September',
                '10': 'October',
                '11': 'November',
                '12': 'December'
            }
            var current_date = new Date()
            var extract_updated_date = new Date(extract.time_updated_adj.substring(0, 19))
            var current_date_val = current_date.toISOString()
            var extract_updated_date_val = extract.time_updated_adj.substring(0, 19)

            var Difference_In_Time = current_date.getTime() - extract_updated_date.getTime()
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
            var timetodisplay = ''
            var timeString = ""
            var H = ""
            var h = 0
            var ampm = ""
            if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === extract_updated_date_val.substring(0, 10)) {
                // Just the time in AM/PM
                timeString = extract.time_updated_adj.substring(11, 19)
                H = +timeString.substr(0, 2)
                h = H % 12 || 12
                ampm = (H < 12 || H === 24) ? "AM" : "PM"
                timeString = h + timeString.substr(2, 3) + ampm
                timetodisplay = timeString
            } else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== extract_updated_date_val.substring(0, 10)) {
                // Just the time in AM/PM
                timeString = extract.time_updated_adj.substring(11, 19)
                H = +timeString.substr(0, 2)
                h = H % 12 || 12
                ampm = (H < 12 || H === 24) ? "AM" : "PM"
                timeString = h + timeString.substr(2, 3) + ampm
                timetodisplay = 'Yesterday ' + timeString
            } else if (Difference_In_Days < 2) {
                // Yesterday plus the time in AM/PM
                timeString = extract.time_updated_adj.substring(11, 19)
                H = +timeString.substr(0, 2)
                h = H % 12 || 12
                ampm = (H < 12 || H === 24) ? "AM" : "PM"
                timeString = h + timeString.substr(2, 3) + ampm
                timetodisplay = 'Yesterday ' + timeString
            } else {
                // The Date with the time
                var dateString = extract.time_updated_adj.substring(0, 10)
                var year = dateString.substring(0, 4)
                var month = dateString.substring(5, 7)
                var dateNum = dateString.substring(8, 10)
                var monthName = date_dictionary[month]
                timeString = extract.time_updated_adj.substring(11, 19)
                H = +timeString.substr(0, 2)
                h = H % 12 || 12
                ampm = (H < 12 || H === 24) ? "AM" : "PM"
                timeString = h + timeString.substr(2, 3) + ampm
                timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
            }

            extract['timetodisplay'] = timetodisplay
        }

        var lastrun_etl_id_info = {}
        this.state.extracts.forEach(extract_id => {
            extractsdetaildata.forEach(extract => {
                if (extract.etl_id === extract_id.etl_id) {
                    if (!(extract.etl_id in lastrun_etl_id_info)) {
                        lastrun_etl_id_info[extract.etl_id] = {}
                    }
                    let lastrun_info = lastrun_etl_id_info[extract.etl_id]
                    let job_start = lastrun_info['job_start'] || ''
                    if (extract.job_start > job_start) {
                        lastrun_info['job_start'] = extract.job_start
                        lastrun_info['user_name'] = extract.user_name
                        lastrun_info['job_complete'] = String(extract.job_complete)
                        lastrun_info['job_start_formatted'] = extract.timetodisplay
                    }
                }
            })
        })

        // Handle jobs never executed as well as jobs last executed in Jupyter that will be tagged as last executed by "Maintenance User"
        this.state.extracts.forEach(extract => {
            if (!(extract.etl_id in lastrun_etl_id_info)) {
                lastrun_etl_id_info[extract.etl_id] = {
                    'job_start': 'N/A',
                    'user_name': 'N/A',
                    'job_complete': 'N/A',
                    'job_start_formatted': 'N/A'
                }
            } else {
                let lastrun_info = lastrun_etl_id_info[extract.etl_id]
                if (lastrun_info['user_name'] === 'Maintenance User') {
                    lastrun_info['user_name'] = 'Jupyter User'
                }
            }
        })

        this.setState({ showBackground: false, extractsdetaildata, lastrun_etl_id_info })
    }

    // Process the PDT ETL list data (adds in industry, country)
    processExtractsList = async (extractsdata) => {
        console.log('Processing Extracts')
        // Add a key called 'showdataset'
        extractsdata.forEach(extract => extract['showdataset'] = true)
        // Add a key that allows for uniqueness if an ETL has multiple datasets -- this prevents weird behavior in render()
        extractsdata.forEach(extract => {
            if (extract.dataset_id !== null) {
                extract['unique_key'] = '' + extract.etl_id + '.' + extract.dataset_id
            } else {
                extract['unique_key'] = '' + extract.etl_id
            }
        })

        this.setState({
            extractsdata,
            showBackground: false
        })

        let dt = new Date()
        let offset = dt.getTimezoneOffset() * 60 * 1000
        extractsdata.map(extract => extract.time_updated_adj = (new Date(Date.parse(extract.time_updated) - offset)).toISOString())
        let extract_sorted = extractsdata.sort(function (a, b) { return Number(a.etl_id) - Number(b.etl_id) })

        for (var i = 0; i < extract_sorted.length; i++) {
            let extract = extract_sorted[i]
            extract['Extract_ID'] = this.state.datamap[extract.etl_id]
            extract['favorite'] = false

            // Initial sort of favorites by timestamp then nonfavorites by timestamp
            let nonfavs = []
            extract_sorted.forEach(extract => { nonfavs.push(extract) })

            // Sort nonfavs
            let nonfavssorted = nonfavs.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
            let final_sort = nonfavssorted
            this.setState({
                extracts: extract_sorted,
                isLoaded: true,
                reports: final_sort,
                showBackground: false
            })
        }
    }

    combineExtractObjects = async () => {
        let extractsdata = this.state.extractsdata
        let lastrundata = this.state.lastrun_etl_id_info

        extractsdata.forEach(extractobj => {
            let key = extractobj['etl_id']
            let lastrundataETL = lastrundata[key] || { 'job_complete': 'false', 'job_start': extractobj['time_updated'], 'job_start_formatted': '', 'user_name': ''}
            extractobj['job_complete'] = lastrundataETL['job_complete']
            extractobj['job_start'] = lastrundataETL['job_start']
            extractobj['job_start_formatted'] = lastrundataETL['job_start_formatted']
            extractobj['user_name'] = lastrundataETL['user_name']
        })

        // Build Options for extracts data
        function buildOptions(key) {
            let uniqueOptions = (Array.from(new Set(extractsdata.map(extract => extract[key])))) || []
            return uniqueOptions.map(option => {
                return { 'label': option, 'value': option }
            })
        }

        let etlID_options = buildOptions('etl_id')
        let etlName_options = buildOptions('etl_shortname')
        let industry_options = buildOptions('industry_name')
        let country_options = buildOptions('country')
        let user_options = buildOptions('user_name')
        let jobcomplete_options = buildOptions('job_complete')

        this.setState({ extractsdata, etlID_options, etlName_options, industry_options, country_options, user_options, jobcomplete_options })
    }

    // Load the Jupyter Server Info
    getJupyterServer = async (jupServer) => {
        console.log('Processing Jupyter Server Names')
        let jupServer_options = []
        jupServer.forEach(server => {
            jupServer_options.push({ 'label': server, 'value': server })
        })

        this.setState({ listServers: jupServer_options })
        //console.log('Check for key pair')
    }
    //Load the User-specfic containers
    getContainer = async (container_map) => {
        console.log(Constant.baseapiurl_gen)
        console.log(Constant.baseapiurl)

        let my_results = await this.entityCrud('Getting Container Names', [
            { 'url': Constant.baseapiurl_gen + "cloud/Containers/Visible", 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let container = my_results.ResultList[0]
        console.log(my_results)
        console.log(container)

        let container_options = []
        container.forEach(container => {
            container_options.push({ 'label': container.sharing_group_name, 'value': container.blob_container_name })
        })

        //this.setState({ listContainers: container_options, selectedContainer: container_options[0] })
        this.setState({ listContainers: container_options })
    }


    async componentDidMount() {
        // Run the big API requests in parallel and then pass the results to the methods that process them in series
        let my_results = await this.entityCrud('getting all information for the PDT extracts page', [
            { 'url': Constant.baseapiurl_gen + "etl/Pdt_Etl", 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + "etl/PdtEtlExecution/Detail", 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + "etl/PdtEtlExecution/GetJupyterServers", 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            var extractsdata = []
            var extractsdetaildata = []
            var jupServer = []
        } else {
            var extractsdata = my_results.ResultList[0]
            var extractsdetaildata = my_results.ResultList[1]
            var jupServer = my_results.ResultList[2]
        }

        await this.processExtractsList(extractsdata)
        await this.processExtractsDetail(extractsdetaildata)
        await this.combineExtractObjects()
        this.getJupyterServer(jupServer)
        this.getContainer()
    }

    render() {
        const sortData = (event, sortby) => {
            var current_header_direction = this.state.header_sort_direction
            var new_header_active = []
            if (sortby === 'ExtractId') {
                new_header_active = [true, false, false, false, false, false, false, false, false]
            } else if (sortby === 'EtlShortName') {
                new_header_active = [false, true, false, false, false, false, false, false, false]
            } else if (sortby === 'Country') {
                new_header_active = [false, false, true, false, false, false, false, false, false]
            } else if (sortby === 'IndustryName') {
                new_header_active = [false, false, false, true, false, false, false, false, false]
            } else if (sortby === 'DataAggregation') {
                new_header_active = [false, false, false, false, true, false, false, false, false]
            } else if (sortby === 'LongDescription') {
                new_header_active = [false, false, false, false, false, true, false, false, false]
            } else if (sortby === 'JobFrequency') {
                new_header_active = [false, false, false, false, false, false, true, false, false]
            } else if (sortby === 'LastModified') {
                new_header_active = [false, false, false, false, false, false, false, true, false]
            } else if (sortby === 'JobComplete') {
                new_header_active = [false, false, false, false, false, false, false, false, true]
            }

            var curr_direction = ""
            var new_direction = ""
            var new_header_direction = []
            if (sortby === 'ExtractId') {
                curr_direction = current_header_direction[0]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[0] = new_direction
            } else if (sortby === 'EtlShortName') {
                curr_direction = current_header_direction[1]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[1] = new_direction
            } else if (sortby === 'Country') {
                curr_direction = current_header_direction[2]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[2] = new_direction
            } else if (sortby === 'IndustryName') {
                curr_direction = current_header_direction[3]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[3] = new_direction
            } else if (sortby === 'DataAggregation') {
                curr_direction = current_header_direction[4]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[4] = new_direction
            } else if (sortby === 'LongDescription') {
                curr_direction = current_header_direction[5]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[5] = new_direction
            } else if (sortby === 'User') {
                curr_direction = current_header_direction[6]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[6] = new_direction
            } else if (sortby === 'LastRun') {
                curr_direction = current_header_direction[7]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[7] = new_direction
            } else if (sortby === 'JobComplete') {
                curr_direction = current_header_direction[8]
                new_direction = (curr_direction === 'asc') ? 'desc' : 'asc'
                new_header_direction = current_header_direction
                new_header_direction[8] = new_direction
            }

            // Sort the data...
            var data_to_sort = this.state.extracts
            //console.log(data_to_sort)
            data_to_sort.forEach(extract => {
                extract['user_name'] = (((this.state.lastrun_etl_id_info || {})[extract.etl_id] || {})['user_name'] || '')
                extract['job_start'] = (((this.state.lastrun_etl_id_info || {})[extract.etl_id] || {})['job_start'] || '')
                extract['job_complete'] = (((this.state.lastrun_etl_id_info || {})[extract.etl_id] || {})['job_complete'])

            })
            //console.log(data_to_sort)

            var final_sort = []
            if (sortby === 'ExtractId') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => Number(a.etl_id) < Number(b.etl_id) ? -1 : (Number(a.etl_id) > Number(b.etl_id) ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => Number(b.etl_id) < Number(a.etl_id) ? -1 : (Number(b.etl_id) > Number(a.etl_id) ? 1 : 0))
                }
            } else if (sortby === 'EtlShortName') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.etl_shortname.toLowerCase() < b.etl_shortname.toLowerCase() ? -1 : (a.etl_shortname.toLowerCase() > b.etl_shortname.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => b.etl_shortname.toLowerCase() < a.etl_shortname.toLowerCase() ? -1 : (b.etl_shortname.toLowerCase() > a.etl_shortname.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'Country') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.country.toLowerCase() < b.country.toLowerCase() ? -1 : (a.country.toLowerCase() > b.country.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => b.country.toLowerCase() < a.country.toLowerCase() ? -1 : (b.country.toLowerCase() > a.country.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'IndustryName') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.industry_name.toLowerCase() < b.industry_name.toLowerCase() ? -1 : (a.industry_name.toLowerCase() > b.industry_name.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => b.industry_name.toLowerCase() < a.industry_name.toLowerCase() ? -1 : (b.industry_name.toLowerCase() > a.industry_name.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'DataAggregation') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.time_aggregation.toLowerCase() < b.time_aggregation.toLowerCase() ? -1 : (a.time_aggregation.toLowerCase() > b.time_aggregation.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => b.time_aggregation.toLowerCase() < a.time_aggregation.toLowerCase() ? -1 : (b.time_aggregation.toLowerCase() > a.time_aggregation.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'LongDescription') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.etl_description.toLowerCase() < b.etl_description.toLowerCase() ? -1 : (a.etl_description.toLowerCase() > b.etl_description.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => b.etl_description.toLowerCase() < a.etl_description.toLowerCase() ? -1 : (b.etl_description.toLowerCase() > a.etl_description.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'User') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.user_name.toLowerCase() < b.user_name.toLowerCase() ? -1 : (a.user_name.toLowerCase() > b.user_name.toLowerCase() ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => b.user_name.toLowerCase() < a.user_name.toLowerCase() ? -1 : (b.user_name.toLowerCase() > a.user_name.toLowerCase() ? 1 : 0))
                }
            } else if (sortby === 'job_start') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(b.job_start) - Date.parse(a.job_start) })
                } else {
                    final_sort = data_to_sort.sort(function (a, b) { return Date.parse(a.job_start) - Date.parse(b.job_start) })
                }
            } else if (sortby === 'JobComplete') {
                if (new_direction === 'asc') {
                    final_sort = data_to_sort.sort((a, b) => a.job_complete < b.job_complete ? -1 : (a.job_complete > b.job_complete ? 1 : 0))
                } else {
                    final_sort = data_to_sort.sort((a, b) => b.job_complete < a.job_complete ? -1 : (b.job_complete > a.job_complete ? 1 : 0))
                }
            }

            this.setState({
                header_sort_direction: new_header_direction,
                header_sort_active: new_header_active,
                reports: final_sort
            })
        }

        const filterJobs = (selectedOptions, filterby) => {
            let filters = this.state.filters
            let datasets = this.state.extractsdata

            if (filterby === 'etlid') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['etlid'] = selectedOptions.map(option => option.value)
                } else {
                    filters['etlid'] = ['All']
                }
            }

            if (filterby === 'etlshortdesc') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['etlshortdesc'] = selectedOptions.map(option => option.value)
                } else {
                    filters['etlshortdesc'] = ['All']
                }
            }

            if (filterby === 'country') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['country'] = selectedOptions.map(option => option.value)
                } else {
                    filters['country'] = ['All']
                }
            }

            if (filterby === 'industry') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['industry'] = selectedOptions.map(option => option.value)
                } else {
                    filters['industry'] = ['All']
                }
            }

            if (filterby === 'user') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['user'] = selectedOptions.map(option => option.value)
                } else {
                    filters['user'] = ['All']
                }
            }

            if (filterby === 'job_complete') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['job_complete'] = selectedOptions.map(option => option.value)
                } else {
                    filters['job_complete'] = ['All']
                }
            }

            if (filterby === 'job_start') {
                filters['daterange_start'] = this.state.selectedstartdaterange
                filters['daterange_end'] = this.state.selectedenddaterange
            }

            // Loop through reports, and ensure that all filters are met...
            datasets.forEach(dataset => {
                let showdataset = false
                let etlidmatch = false
                let etlshortdescmatch = false
                let industrymatch = false
                let countrymatch = false
                let usermatch = false
                let jobcompletematch = false
                let daterangematch = false

                if (filters['etlid'].includes(dataset.etl_id) || (filters['etlid'].length === 1 && filters['etlid'][0] === 'All')) {
                    etlidmatch = true
                }

                if (filters['etlshortdesc'].includes(dataset.etl_shortname) || (filters['etlshortdesc'].length === 1 && filters['etlshortdesc'][0] === 'All')) {
                    etlshortdescmatch = true
                }

                if (filters['country'].includes(dataset.country) || (filters['country'].length === 1 && filters['country'][0] === 'All')) {
                    countrymatch = true
                }

                if (filters['industry'].includes(dataset.industry_name) || (filters['industry'].length === 1 && filters['industry'][0] === 'All')) {
                    industrymatch = true
                }

                if (filters['user'].includes(dataset.user_name) || (filters['user'].length === 1 && filters['user'][0] === 'All')) {
                    usermatch = true
                }

                if (filters['job_complete'].includes(dataset.job_complete) || (filters['job_complete'].length === 1 && filters['job_complete'][0] === 'All')) {
                    jobcompletematch = true
                }

                let start_time_tocheck = (dataset['job_start'] || "").substring(0, 10)
                if (filters['daterange_start'] && filters['daterange_end']) {
                    if (start_time_tocheck <= filters['daterange_end'] && start_time_tocheck >= filters['daterange_start']) {
                        daterangematch = true
                    }
                }

                if (filters['daterange_start'] && !filters['daterange_end']) {
                    if (start_time_tocheck >= filters['daterange_start']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && filters['daterange_end']) {
                    if (start_time_tocheck <= filters['daterange_end']) {
                        daterangematch = true
                    }
                }

                if (!filters['daterange_start'] && !filters['daterange_end']) {
                    daterangematch = true
                }

                if (etlidmatch && etlshortdescmatch && industrymatch && countrymatch && usermatch && daterangematch && jobcompletematch) {
                    showdataset = true
                }

                dataset['showdataset'] = showdataset
            })

            this.setState({ filters, extractsdata: datasets, page: 0 })
        }

        const handleClick_edit = (etlid) => async () => {
            window.location.href = Constant.baseurl + 'PDTETL/EditExtract/' + etlid
        }

        const openModalFunc = (etlid) => {
            let selectedEtlIdModal = etlid
            this.setState({ selectedEtlIdModal, runCloud: false, runSAS: false, openModal: true })
        }

        const closeModalFunc = () => {
            this.setState({ openModal: false, setOpenSaveRun: false })
            window.location.href = Constant.baseurl + 'PDTETL/Extracts'
        }

        const handleClick_delete = async () => {
            let my_results = await this.entityCrud('deleting a PDT ETL', [
                { 'url': Constant.baseapiurl_gen + "etl/Pdt_Etl/" + this.state.etl_delete_id, 'method': 'DELETE', 'body': null },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails
                return
            }

            //alert('Your extract job has been permanently deleted.')
            var etl_deletion_data = my_results.ResultList[0]
            console.log('Extract Deleted')
            console.log(etl_deletion_data)
            window.location.href = Constant.baseurl + 'PDTETL/Extracts'
        }

        const setOpen = (etl_id) => {
            this.setState({ setOpen: true, etl_delete_id: etl_id })
        }

        const handleOpenDialog = (etl_id) => {
            console.log('Click')
            setOpen(etl_id)
            //setOpenRun(etl_id)
        }

        const handleCloseDialog = () => {
            //setOpenDialog(false)
            this.setState({ setOpen: false })
        }

        const handleCloseDialog_Run = () => {
            //setOpenDialog(false)
            this.setState({ setOpenSaveRun: false, openModal: false })
            closeModalFunc()
        }

        const handleCloseDialog_RunCalcs = () => {
            //setOpenDialog(false)
            this.setState({ setOpenRunCalc: false })
        }

        const handleChangePage = (event, newPage) => {
            this.setState({ page: newPage })
        }

        const handleChangeRowsPerPage = (event) => {
            this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })
        }

        const handleDates_TimeRange = (values) => {
            let selectedstartdaterange = null
            let selectedenddaterange = null
            if (values) {
                let start_year = values[0].getFullYear()
                let start_day = values[0].getDate()
                let start_month = values[0].getMonth() + 1

                let end_year = values[1].getFullYear()
                let end_day = values[1].getDate()
                let end_month = values[1].getMonth() + 1

                if (start_day < 10) {
                    start_day = String('0' + start_day)
                }

                if (start_month < 10) {
                    start_month = String('0' + start_month)
                }

                if (end_day < 10) {
                    end_day = String('0' + end_day)
                }

                if (end_month < 10) {
                    end_month = String('0' + end_month)
                }

                selectedstartdaterange = String(start_year) + '-' + String(start_month) + '-' + String(start_day) //(values[0].toISOString()).substring(0, 10)
                selectedenddaterange = String(end_year) + '-' + String(end_month) + '-' + String(end_day) //(values[1].toISOString()).substring(0, 10)
            } else {
                selectedstartdaterange = null
                selectedenddaterange = null
            }

            this.setState({
                selectedstartdaterange: selectedstartdaterange,
                selectedenddaterange: selectedenddaterange,
                DatevaluesTimeRange: values
            }, () => {
                filterJobs(values, 'job_start')
            })
        }

        return (
            <Layout title="All Extracts">
                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    <div class="custom-loader"></div>
                    {/*<CircularProgress color="inherit" />*/}
                </Constant.NPDBackdrop>

                <div name='banner' className='banner'>
                    <h1white align='center'>Price and Discount Trends Data Extracts</h1white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    {/* API error pop-up */}
                    <ApiErrorDialog
                        state={this.state}
                        closeApiErrorDialog={this.closeApiErrorDialog}
                    />

                    <Dialog
                        open={this.state.setOpen}
                        onClose={handleCloseDialog}
                        style={{ overflowY: 'scroll' }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete this Extract?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to delete this extract. This action is final and can not be undone.  Continue?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={handleCloseDialog} className="btn btn-secondary">
                                Cancel
                            </button>
                            <button onClick={handleClick_delete} className="btn btn-primary" autoFocus>
                                Delete Extract
                            </button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.setOpenSaveRun}
                        onClose={handleCloseDialog_Run}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ overflowY: 'scroll' }}
                    >
                        <DialogTitle id="alert-dialog-title">{"Extract Submitted"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Your job has been submitted.  An email will be sent when the job is complete.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={closeModalFunc} className="btn btn-secondary">
                                Ok
                            </button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.setOpenRunCalc}
                        onClose={handleCloseDialog_RunCalcs}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ overflowY: 'scroll' }}
                    >
                        <DialogTitle id="alert-dialog-title">{"Calculation Code Submitted"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                The job to run the calculations has been submitted.  An email will be sent when the job is complete.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={handleCloseDialog_RunCalcs} className="btn btn-secondary">
                                Ok
                            </button>
                        </DialogActions>
                    </Dialog>
                    <div className='row col-sm-12'>
                        <div className='col-sm-3'>
                            <span className='formfieldtitle3'>ETL ID</span>
                            <Select
                                options={this.state.etlID_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'etlid')}
                                styles={selectColourStyles}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <span className='formfieldtitle3'>ETL Short Desc</span>
                            <Select
                                options={this.state.etlName_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'etlshortdesc')}
                                styles={selectColourStyles}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <span className='formfieldtitle3'>Country</span>
                            <Select
                                options={this.state.country_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'country')}
                                styles={selectColourStyles}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <span className='formfieldtitle3'>Industry</span>
                            <Select
                                options={this.state.industry_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'industry')}
                                styles={selectColourStyles}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <span className='formfieldtitle3'>Last Executing User</span>
                            <Select
                                options={this.state.user_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'user')}
                                styles={selectColourStyles}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <span className='formfieldtitle3'>Job Complete</span>
                            <Select
                                options={this.state.jobcomplete_options}
                                isMulti={true}
                                onChange={(options, filterby) => filterJobs(options, 'job_complete')}
                                styles={selectColourStyles}
                            />
                        </div>
                        <div className="col-sm-3">
                            <span className='formfieldtitle3'>Last Run Date</span>
                            <DateRangePicker
                                onChange={handleDates_TimeRange}
                                value={this.state.DatevaluesTimeRange}
                                calendarIcon={<DateRangeRoundedIcon style={{ color: '#4e106f' }} />}
                                clearIcon={<ClearRoundedIcon style={{ color: '#822433' }} />}
                                className={"testForDatePicker"}
                                style={{
                                    backgroundColor: '#FFFFFF'
                                }}
                            />
                        </div>
                    </div>
                    <Table id="reporTable">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '2%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[0]}
                                        direction={this.state.header_sort_direction[0]}
                                        onClick={(event) => sortData(event, 'ExtractId')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Extract ID</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[1]}
                                        direction={this.state.header_sort_direction[1]}
                                        onClick={(event) => sortData(event, 'EtlShortName')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Extract Name</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[2]}
                                        direction={this.state.header_sort_direction[2]}
                                        onClick={(event) => sortData(event, 'Country')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Country</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[3]}
                                        direction={this.state.header_sort_direction[3]}
                                        onClick={(event) => sortData(event, 'IndustryName')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Industry</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[5]}
                                        direction={this.state.header_sort_direction[5]}
                                        onClick={(event) => sortData(event, 'LongDescription')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Long Description</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[6]}
                                        direction={this.state.header_sort_direction[6]}
                                        onClick={(event) => sortData(event, 'User')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Last User</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[7]}
                                        direction={this.state.header_sort_direction[7]}
                                        onClick={(event) => sortData(event, 'LastRun')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Last Run</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '1%' }}>
                                    <TableSortLabel
                                        active={this.state.header_sort_active[8]}
                                        direction={this.state.header_sort_direction[8]}
                                        onClick={(event) => sortData(event, 'JobComplete')}
                                        hideSortIcon={false}
                                    >
                                        <span className='greenHeader'>Job Completed</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ width: '1%' }}>
                                </TableCell>
                                <TableCell style={{ width: '1%' }}>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.extractsdata.filter(a => a.showdataset).slice(this.state.page * this.state.rowsPerPage,
                                                                                      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(extract => {
                                return <TableRow key={extract.unique_key}>
                                    <TableCell> {extract.etl_id} </TableCell>
                                    <TableCell> {extract.etl_shortname} </TableCell>
                                    <TableCell> {extract.country} </TableCell>
                                    <TableCell> {extract.industry_name} </TableCell>
                                    <TableCell> {extract.etl_description} </TableCell>
                                    <TableCell> {extract.user_name} </TableCell>
                                    <TableCell> {extract.job_start_formatted} </TableCell>
                                    <TableCell> {extract.job_complete} </TableCell>
                                    <TableCell >
                                        <Tooltip title="Run with Options" arrow>
                                            <DirectionsRunRoundedIcon style={{ color: '#4e106f' }}
                                                onClick={(etl_id) => openModalFunc((extract.etl_id))} />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell >
                                        <Tooltip title="Edit" arrow>
                                            <EditIcon style={{ color: '#3f3f3f' }}
                                                onClick={handleClick_edit(extract.etl_id)}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Delete" arrow>
                                            <DeleteForeverIcon onClick={(etl_id) => handleOpenDialog(extract.etl_id)} style={{ color: '#822433' }} />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.state.extractsdata.filter(a => a.showdataset).length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{ overflowY: 'scroll', paddingRight: '0 !important' }}
                    open={this.state.openModal}
                    onClose={closeModalFunc}
                >
                    <div style={{ paddingLeft: '20px', paddingRight: '10px', overflowY: 'auto', borderRadius: '3', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '900px', backgroundColor: '#FFF' }} >
                        <h6 id="simple-modal-title">Save and Run Options</h6>
                        <span className="h7" >{'Select Type of Run (select only one)'}</span>
                        <div className='col-sm-12 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-4'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.runCloud}
                                        checked={this.state.runCloud}
                                        //defaultChecked
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Run on Cloud?' }}
                                        onChange={this.onChangeRunCloud}
                                    />
                                    Full Run in the Cloud?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'If checked, the extract definition will be saved and both the extract and calculation code will run in the cloud when you click Save and Run.'}</span>
                            </div>
                        </div>
                        <div className='col-sm-12 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-4'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.runSAS}
                                        checked={this.state.runSAS}
                                        //defaultChecked
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Run on on SAS?' }}
                                        onChange={this.onChangeRunSAS}
                                    />
                                    Full Run in SAS and Jupyter?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'If checked, the extract definition will be saved and the extract will run in SAS and the calculation code will run in Jupyter when you click Save and Run. '}</span>
                            </div>
                        </div>

                        {(this.state.runCloud === true || this.state.runSAS === true) ?
                            <div>
                                <span className="h7" >{'Additional Options for Full Runs'}</span>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.skipExtract}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Skip extraction (the extract must already exist or it will fail)' }}
                                                onChange={this.onChangeSkipExtract}
                                            />
                                            Skip Extract?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Skip extraction (the extract must already exist or it will fail)'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.skipCalculations}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Skip calculations and go straight to post-calculation code' }}
                                                onChange={this.onChangeSkipCalculations}
                                            />
                                            Skip Calculations?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Skip calculations and go straight to the post-calculation code'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.resumeIfPossible}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'For jobs going partition by partition, resume if it is possible to do so' }}
                                                onChange={this.onChangeResumeIfPossible}
                                            />
                                            Resume if Possible?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'For jobs running partition by partition that fail, resume if it is possible to do so'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.forceEuBpFile}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'For jobs using EU industries, use the EU base price file instead of calculating base price' }}
                                                onChange={this.onChangeForceEuBpFile}
                                            />
                                            Use EU Base Price File?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'For jobs using EU industries, use the EU base price file instead of calculating base price'}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                        {(this.state.runSAS || this.state.runCalcsOnJup) ?
                            <div>
                                <span className="h7" >{'Jupyter Options'}</span>
                                <br />
                                <span className='formfieldtitle3'>Choose a Jupyter Server </span><span style={{ fontWeight: 'bold', color: 'red' }}>(*Mandatory*)</span>
                                <div className='col-sm-5' style={{ paddingTop: '10px' }}>
                                    <Select
                                        options={this.state.listServers}
                                        isMulti={false}
                                        onChange={(item, action) => this.onChangeJupServer(item, action)}
                                        value={{ 'label': this.state.selectedServer, 'value': this.state.selectedServer }}
                                        styles={selectColourStyles}
                                    >
                                    </Select>
                                </div>
                                <br />
                            </div>
                            :
                            <div></div>
                        }
                        {(this.state.runSAS === false && this.state.runCloud === true) ?
                            <div>

                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.useDisaggFile}
                                                checked={this.state.useDisaggFile}
                                                disableRipple
                                                onChange={this.onChangeUseDisaggregateFile}
                                            />
                                            Create a Disaggregate File?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Write the results of the ETL job as a disaggregate file.  Note: You must choose a container below.'}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                        {(this.state.useDisaggFile === true && this.state.runSAS === false) ?
                            <div>
                                <span className='formfieldtitle3'>Select the container where the file should be saved </span><span style={{ fontWeight: 'bold', color: 'red' }}>(*Mandatory*)</span>
                                <div className='col-sm-5' style={{ paddingTop: '10px' }}>
                                    <Select
                                        options={this.state.listContainers}
                                        //defaultValue={{ 'label': this.state.selectedContainer[0], 'value': this.state.selectedContainer[0] }}
                                        isMulti={false}
                                        onChange={(item, action) => this.onChangeContainer(item, action)}
                                        value={{ 'label': this.state.selectedContainer, 'value': this.state.selectedContainer }}
                                        styles={selectColourStyles}
                                    >
                                    </Select>
                                </div>
                                <br />
                            </div>
                            :
                            <div></div>
                        }

                        <div className='col-sm-12 row' style={{ padding: '10px' }}>
                            <div style={dateInput} className='col-sm-3'>
                                <button
                                    onClick={closeModalFunc}
                                    className="btn btn-primary"
                                    style={{ margin: '10px' }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div style={dateInput} className='col-sm-9'>
                                <button
                                    onClick={this.handleClickRun}
                                    className="btn btn-primary"
                                    style={{ margin: '10px' }}
                                >
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Layout>
        )
    }
}
