import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as Constant from './constants'

export class CookieBar extends Component {
    static displayName = CookieBar.name;
    constructor(props) {
        super(props);
        this.state = {
            displayCookieMessage: 'inherit'
        }        
    }

    handleCloseCookieBar = () => {
        this.setState({ displayCookieMessage: 'none' })
        localStorage.setItem('cookieaccepted', JSON.stringify(true));
    }

    componentDidMount() {
        console.log('Mounting Cookie Bar')
        //Find Out if the user has a value of true set in their browser...
        let cookieaccepted = JSON.parse(localStorage.getItem('cookieaccepted'))
        console.log(cookieaccepted)
        if (cookieaccepted) {
            this.setState({ displayCookieMessage: 'none'})
        }
        
    }

    render() {

        return (
            <div
                className="row sticky contentHolder"
                style={{
                    padding: '20px',
                    display: this.state.displayCookieMessage,
                    maxWidth: '500px',
                    minWidth: '500px',
                    marginRight: '55px',                    
                    bottom: '10px',                    
                    zIndex: '9999998',
                    backgroundColor: '#000000',
                    color: '#FFFFFF'
                }}
            >
                By using our website, you agree to our&nbsp;
                <a
                    href='https://www.npd.com/wps/portal/npd/us/about-npd/privacy-notices/'
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#4e106f'}}
                >
                    cookie policy
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <ThemeProvider theme={Constant.muiTheme}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={this.handleCloseCookieBar}                        
                    >
                        OK
	                </Button>
                </ThemeProvider>
            </div>
        )

    }
}
