import React, { Component, Fragment } from 'react';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import selectColourStyles from '../styles/SelectColourStyles'
import { Layout } from '../Layout';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Select, { components } from 'react-select'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import TextFieldsRoundedIcon from '@material-ui/icons/TextFieldsRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import InsertChartRoundedIcon from '@material-ui/icons/InsertChartRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import AddToPhotosRoundedIcon from '@material-ui/icons/AddToPhotosRounded';

import baseandincremental from "./ChartScreenshots/Base and Incremental/Base and Incremental.png"
import liftefficiencydiscount from "./ChartScreenshots/Base and Incremental/Lift Efficiency Discount.png"
import baseandincrementalbyattribute from "./ChartScreenshots/Base and Incremental/Base and Incremental by Attribute.png"
import paybackratioandefficiencybydiscountrange from "./ChartScreenshots/Base and Incremental/Payback Ratio and Efficiency by Discount Range.png"
import fairshareofsubsidization from "./ChartScreenshots/Base and Incremental/Fair Share of Subsidization.png"
import discdollarsandpbraggregate from "./ChartScreenshots/Base and Incremental/DiscDollarsAndPBRAggregate.png"

import promotionperformance from "./ChartScreenshots/Base and Incremental Trended/Promotion Performance.png"
import liftandefficiency from "./ChartScreenshots/Base and Incremental Trended/Lift and Efficiency.png"
import discountdollarsandpaybackratio from "./ChartScreenshots/Base and Incremental Trended/Discount Dollars and Payback Ratio.png"
import basevelocity from "./ChartScreenshots/Base and Incremental Trended/Base Velocity.png"
import unitsbybranditem from "./ChartScreenshots/Base and Incremental Trended/Units by Brand.png"

import averagepromoteddiscountpcttrended from "./ChartScreenshots/Price and Discount Trends/Average Promoted Discount Pct Trended.png"
import averagepromoteddiscountwithindex from "./ChartScreenshots/Price and Discount Trends/Avg Promo With Index Dollar Share.png"
import depthofdiscount from "./ChartScreenshots/Price and Discount Trends/Depth of Discount.png"
import promotednonpromotedprice from "./ChartScreenshots/Price and Discount Trends/Promoted Non-Promoted Price.png"
import baseaveragesellingprice from "./ChartScreenshots/Price and Discount Trends/Base Average Selling Price.png"
import promotedunitsdollars from "./ChartScreenshots/Price and Discount Trends/Promoted Units Dollars.png"
import sharebybrands from "./ChartScreenshots/Price and Discount Trends/Share by Brands.png"

import discountrange from "./ChartScreenshots/Previous Period/Discount Range.png"
import totalvolume from "./ChartScreenshots/Previous Period/Total Volume.png"
import itemyoyinfo from "./ChartScreenshots/Previous Period/ItemYOYInformation.png"
import changeinmetricswithmktshare from "./ChartScreenshots/Previous Period/Change In Metrics With Market Share.png"

import baseandincrementalunits from "./ChartScreenshots/Scorecard/Base and Incremental Units.png"
import promotedandnonpromotedunits from "./ChartScreenshots/Scorecard/Promoted and Non-Promoted Units.png"
import salesmetrics from "./ChartScreenshots/Scorecard/Sales Metrics.png"
import pricingmetrics from "./ChartScreenshots/Scorecard/Pricing Metrics.png"
import promotionmetrics from "./ChartScreenshots/Scorecard/Promotion Metrics.png"

import averagepromoteddiscounttrended from "./ChartScreenshots/ALR vs ROM - Price and Discount Trends/Average Promoted Discount Pct Trended.png"
import averageselling from "./ChartScreenshots/ALR vs ROM - Price and Discount Trends/Average Selling.png"
import depthofdiscountbydiscountrange from "./ChartScreenshots/ALR vs ROM - Price and Discount Trends/Depth of Discount by Discount Range.png"
import averagesellingpricedistributionbyitem from "./ChartScreenshots/ALR vs ROM - Price and Discount Trends/Average Selling Price Distribution by Item.png"
import alrvsrom from "./ChartScreenshots/ALR vs ROM - Price and Discount Trends/ALRvsROM.png"

import baseandincrementalalr from "./ChartScreenshots/ALR vs ROM - Base and Incremental/Base and Incremental.png"
import liftefficiencyanddiscount from "./ChartScreenshots/ALR vs ROM - Base and Incremental/Lift Efficiency and Discount.png"
import metricschanges from "./ChartScreenshots/ALR vs ROM - Base and Incremental/Metrics Changes.png"
import metriccomparisonstorom from "./ChartScreenshots/ALR vs ROM - Base and Incremental/Metric Comparisons to ROM.png"
import topbottomperformingweeks from "./ChartScreenshots/ALR vs ROM - Base and Incremental/Top Bottom Performing Weeks.png"
import promosupportandshare from "./ChartScreenshots/ALR vs ROM - Base and Incremental/PromoSupportAndShare.png"

import flexibletable from "./ChartScreenshots/ALR vs ROM - Base and Incremental/FlexibleTable.png"
import flexibletableweekly from "./ChartScreenshots/ALR vs ROM - Base and Incremental/FlexibleTableWeekly.png"
import flexibletablesegment from "./ChartScreenshots/ALR vs ROM - Base and Incremental/FlexibleTableSegment.png"
import flexibletablebrand from "./ChartScreenshots/ALR vs ROM - Base and Incremental/FlexibleTableBrand.png"

import helppdf from "../HelpDocuments/Report Configuration Instructions.pdf"
import helpvideo from "../videos/PDT/Report Config Screen.mp4"

import * as Constant from '../constants'
import FetchConfigWithDefault from './Functions/Utilities/FetchConfigWithDefault'
import FetchDataWithErrorHandling from './Functions/Utilities/FetchDataWithErrorHandling'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

var appname = Constant.whereamilist[3]

var charts_all = [
    {
        'id': 1,
        'name': 'Base and Incremental',
        'tags': ['Base'],
        'type': 'Aggregate',
        'picture': baseandincremental,
        'description': 'Breakdown of total sales into base/incremental and promoted and non-promoted summed across the week selected for different product groups.',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?'],
        'showchart': true
    },
    {
        'id': 2,
        'name': 'Lift, Efficiency & Discount',
        'tags': ['Lift'],
        'type': 'Aggregate',
        'picture': liftefficiencydiscount,
        'description': 'Summary lift and efficiency measures of promotional effectiveness along with the average discount when promoting for different product groups.',
        'questions': ['Are promotions an efficient way to generate sales?', 'What % lift is generated by promoting?', 'Which brands are more efficient?'],
        'showchart': true
    },
    {
        'id': 3,
        'name': 'Base and Incremental by Attribute',
        'tags': ['Base'],
        'type': 'Aggregate',
        'picture': baseandincrementalbyattribute,
        'description': 'Breakdown of total sales into base/incremental and promoted and non-promoted summed across the week selected for different product groups.',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?'],
        'showchart': false
    },
    {
        'id': 4,
        'name': 'Payback Ratio and Efficiency by Discount Range',
        'tags': ['Lift'],
        'type': 'Aggregate',
        'picture': paybackratioandefficiencybydiscountrange,
        'description': 'Summary lift, efficiency and payback ration measures of promotional effectiveness by level of discount for different product groups.',
        'questions': ['Are price promotions cost effective at driving sales?', 'Are deeper discount more effective at driving incremental sales?', 'What discount level is optimal?'],
        'showchart': true
    },
    {
        'id': 5,
        'name': 'Fair Share of Subsidization',
        'tags': ['Fair Share'],
        'type': 'Aggregate',
        'picture': fairshareofsubsidization,
        'description': 'Summary of the share of subsidized sales across brands compared to the brands share of total sales',
        'questions': ['Which brands over index on subsidized sales?', 'What is the share of subsidized sales across brands?'],
        'showchart': true
    },
    {
        'id': 34,
        'name': 'Discount Dollars and Payback Ratio - Aggregate',
        'tags': ['Discount Dollars'],
        'type': 'Aggregate',
        'picture': discdollarsandpbraggregate,
        'description': 'Summary of the share of Discount Dollars across brands compared to the Payback Ratio',
        'questions': ['Which brands have the best Payback for Promotion?'],
        'showchart': true
    },
    {
        'id': 6,
        'name': 'Promotion Performance',
        'tags': ['Base'],
        'type': 'Trended',
        'picture': promotionperformance,
        'description': 'Trended sales broken out into Base, subsidized base, & Incremental sale as well as promoted and non-promoted sales',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?', 'Which weeks have the greatest incremental sales?', 'How does my average selling price vary by week?'],
        'showchart': true
    },
    {
        'id': 7,
        'name': 'Lift and Efficiency',
        'tags': ['Lift'],
        'type': 'Trended',
        'picture': liftandefficiency,
        'description': 'Trended lift and efficiency measures of promotional effectiveness along with the average discount when promoting for different brands.',
        'questions': ['Are promotions an efficient way to generate sales?', 'What % lift is generated by promoting?', 'Which promoted weeks are most efficient for each brand?'],
        'showchart': true
    },
    {
        'id': 8,
        'name': 'Discount Dollars and Payback Ratio',
        'tags': ['Discount Dollars'],
        'type': 'Trended',
        'picture': discountdollarsandpaybackratio,
        'description': 'Trended view of Payback ratio, discount dollars and incremental dollars.',
        'questions': ['Which weeks have the best payback for promotions?'],
        'showchart': true
    },
    {
        'id': 9,
        'name': 'Base Velocity',
        'tags': ['Base Velocity'],
        'type': 'Trended',
        'picture': basevelocity,
        'description': 'Trended view of base velocity ',
        'questions': ['What is the health of my brand, items?', 'Is core demand increasing or decreasing?'],
        'showchart': true
    },
    {
        'id': 10,
        'name': 'Units by Brand/Item',
        'tags': ['Trended Sales'],
        'type': 'Trended',
        'picture': unitsbybranditem,
        'description': 'Trended units sales',
        'questions': ['What is the trend of my brand and items?', 'How does the trends across brands differ?'],
        'showchart': true
    },
    {
        'id': 11,
        'name': 'Average Promoted Discount Pct Trended',
        'tags': ['Discount'],
        'type': 'Trended',
        'picture': averagepromoteddiscountpcttrended,
        'description': 'Trended dollar share and average promoted discount',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?'],
        'showchart': true
    },
    {
        'id': 35,
        'name': 'Average Promoted Discount Pct With Dollar Share',
        'tags': ['Discount'],
        'type': 'Trended',
        'picture': averagepromoteddiscountwithindex,
        'description': 'Trended dollar share and average promoted discount',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?'],
        'showchart': true
    },
    {
        'id': 12,
        'name': 'Depth of Discount',
        'tags': ['Discounted Sales'],
        'type': 'Aggregate',
        'picture': depthofdiscount,
        'description': 'Units sales by discount bucket',
        'questions': ['Which discount level drives sales? ', 'How does the distribution across discount compare across brands?'],
        'showchart': true
    },
    {
        'id': 13,
        'name': 'Promoted/Non-Promoted Price',
        'tags': ['Promoted Price'],
        'type': 'Trended',
        'picture': promotednonpromotedprice,
        'description': 'Trended promoted and non-promoted prices by brand and across brands',
        'questions': ['How does my promoted price compare to other brands?', 'How does my non-promoted price compare to other brands?'],
        'showchart': true
    },
    {
        'id': 14,
        'name': 'Base/Average Selling Price',
        'tags': ['Base Price'],
        'type': 'Trended',
        'picture': baseaveragesellingprice,
        'description': 'Trended base price and avera selling price',
        'questions': ['How do base prices compare across brands?', 'How do base prices compare to average selling price?'],
        'showchart': true
    },
    {
        'id': 15,
        'name': 'Promoted Units/Dollars',
        'tags': ['Promoted Sales'],
        'type': 'Trended',
        'picture': promotedunitsdollars,
        'description': 'Trended promoted unit and dollars by brand',
        'questions': ['Which brands over index on subsidized sales?', 'What is the share of subsidized sales across brands?'],
        'showchart': true
    },
    {
        'id': 16,
        'name': 'Share by Brands',
        'tags': ['Share'],
        'type': 'Trended',
        'picture': sharebybrands,
        'description': 'Trended shares of total, base, incremental sales ',
        'questions': ['How do brands shares differs across weeks?', 'Which brands won the week?', 'Which weeks are promoted by more brands?'],
        'showchart': true
    },
    {
        'id': 17,
        'name': 'Discount Range',
        'tags': ['Discount Ranges'],
        'type': 'Aggregate',
        'picture': discountrange,
        'description': 'Summary of Year over Year changes in sales by discount range. Periods can range from 1 to 52 weeks',
        'questions': ['Which promotion range drove the changes in promoted sales?'],
        'showchart': true
    },
    {
        'id': 18,
        'name': 'Total Volume',
        'tags': ['Trended Sales'],
        'type': 'Trended',
        'picture': totalvolume,
        'description': 'Trended sales year over year with aligned weeks',
        'questions': ['Which weeks drove the overall change in sales '],
        'showchart': true
    },
    {
        'id': 36,
        'name': 'Item Change Information',
        'tags': ['Item','Previous Year'],
        'type': 'Aggregate',
        'picture': itemyoyinfo,
        'description': 'Summary of the changes by item for current year versus previous year.',
        'questions': ['How have items changed in the previous year?'],
        'showchart': true
    },
    {
        'id': 37,
        'name': 'Metrics Changes With Market Share',
        'tags': ['Brand', 'Previous Year'],
        'type': 'Aggregate',
        'picture': changeinmetricswithmktshare,
        'description': 'Year over year changes in promotional sales metrics with Market Share Changes',
        'questions': ['Is my year over year sales performance driven by more/less promotions?', 'Are my promotions generating more or less incremental sales?', 'How is my year over year performance compared to the Rest of Market?'],
        'showchart': true
    },
    {
        'id': 19,
        'name': 'Base and Incremental Units',
        'tags': ['Base Sales'],
        'type': 'Aggregate',
        'picture': baseandincrementalunits,
        'description': 'Year over year change in base & Incremental metrics',
        'questions': ['Did base or incremental sales drive year over year sales changes?', 'Did subsidized base sales increase year over year?'],
        'showchart': true
    },
    {
        'id': 20,
        'name': 'Promoted and Non-Promoted Units',
        'tags': ['Promoted Sales'],
        'type': 'Aggregate',
        'picture': promotedandnonpromotedunits,
        'description': 'Year over year change in promoted and non-promoted sales metrics',
        'questions': ['Did promoted or non-promoted sales drive year over year sales changes?', 'Are promotion more important brands?'],
        'showchart': true
    },
    {
        'id': 21,
        'name': 'Sales Metrics',
        'tags': ['Base Dollars'],
        'type': 'Aggregate',
        'picture': salesmetrics,
        'description': 'Year over year dollar sales metrics for base, Incremental and subsidized sales ',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?'],
        'showchart': true
    },
    {
        'id': 22,
        'name': 'Pricing Metrics',
        'tags': ['Base Price'],
        'type': 'Aggregate',
        'picture': pricingmetrics,
        'description': 'Year over year pricing metric changes for base price, promoted price, and average selling price, discount percentage and discount dollars',
        'questions': ['How did prices change year over year?', 'Were there more price promotions this year?'],
        'showchart': true
    },
    {
        'id': 23,
        'name': 'Promotion Metrics',
        'tags': ['Lift'],
        'type': 'Aggregate',
        'picture': promotionmetrics,
        'description': 'Year over year promotion effectiveness metric changes for lift, efficiency, and payback ratio',
        'questions': ['Were promotions more or less effective this year compared to last year?'],
        'showchart': true
    },
    {
        'id': 24,
        'name': 'ALR vs ROM',
        'tags': ['Retailer', 'Promoted Units'],
        'type': 'Aggregate',
        'picture': alrvsrom,
        'description': 'Retailer vs Rest of Market comparison of various metrics, including discount levels and Promoted sales at varying levels.',
        'questions': ['How much volume is sold on Promotion?', 'How does my discounting compare to the rest of market?', 'Does the rest of market promote more than me?'],
        'showchart': true
    },

    {
        'id': 25,
        'name': 'Average Promoted Discount % Trended',
        'tags': ['Retailer'],
        'type': 'Trended',
        'picture': averagepromoteddiscounttrended,
        'description': 'Trended Share and discount comparison between retailer and rest of market',
        'questions': ['Am I promoting in the right weeks?', 'Do my promotions drive share gains?', 'When does my competition promote?'],
        'showchart': true
    },
    {
        'id': 26,
        'name': 'Average Selling ',
        'tags': ['Promoted Price'],
        'type': 'Trended',
        'picture': averageselling,
        'description': 'Trended price comparisons between one retailer and rest of market',
        'questions': ['How do my prices compare to the competition?'],
        'showchart': true
    },    
    {
        'id': 27,
        'name': 'Depth of Discount by Discount Range',
        'tags': ['Promoted Units'],
        'type': 'Aggregate',
        'picture': depthofdiscountbydiscountrange,
        'description': 'Promoted sales by discount range comparisons between one retailer and rest of market',
        'questions': ['How much volume is sold on Promotion?', 'Do I sell more or less at different discount ranges?', 'Does the rest of market promote more than me?'],
        'showchart': true
    },
    {
        'id': 28,
        'name': 'Average Selling Price Distribution by item',
        'tags': ['Price Distributions'],
        'type': 'Aggregate',
        'picture': averagesellingpricedistributionbyitem,
        'description': 'Item specific price position between retailer and the distribution of prices in the rest of market',
        'questions': ['How much competitive volume is sold above and below my average selling price?'],
        'showchart': true
    },
    {
        'id': 29,
        'name': 'Base and Incremental ALR',
        'tags': ['Base Sales'],
        'type': 'Aggregate',
        'picture': baseandincrementalalr,
        'description': 'Comparison of base & incremental and promoted & non-promoted sales between retailer and the rest of market',
        'questions': ['Do my promotions drive more incremental sales that my competition?'],
        'showchart': true
    },
    {
        'id': 30,
        'name': 'Lift, Efficiency, and Discount',
        'tags': ['Lift'],
        'type': 'Aggregate',
        'picture': liftefficiencyanddiscount,
        'description': 'Comparison of promoted lift, efficiency, and discount levels between the retailer and the rest of market',
        'questions': ['Are my promotions more efficient than the rest of market?', 'Do my promotions generate a better incremental lift?', 'How do my discounts compare to the rest of market?'],
        'showchart': true
    },
    {
        'id': 31,
        'name': 'Metrics Changes',
        'tags': ['Previous Year'],
        'type': 'Aggregate',
        'picture': metricschanges,
        'description': 'Year over year changes in promotional sales metrics',
        'questions': ['Is my year over year sales performance driven by more/less promotions?', 'Are my promotions generating more or less incremental sales?', 'How is my Year over year performance compared to the rest of market?'],
        'showchart': true
    },
    {
        'id': 32,
        'name': 'Metric Comparisons to ROM',
        'tags': ['Previous Year'],
        'type': 'Aggregate',
        'picture': metriccomparisonstorom,
        'description': 'Retailer vs Rest of Market comparison across price and promotion measures',
        'questions': ['How do my price and promotions measures compare to the rest of market?', 'How does the change in my price and promotion measure compare to the rest of market?'],
        'showchart': true
    },
    {
        'id': 33,
        'name': 'Top/Bottom Performing Weeks',
        'tags': ['Top Weeks'],
        'type': 'Aggregate',
        'picture': topbottomperformingweeks,
        'description': 'Identify weeks that under or over performed across different measures',
        'questions': ['Which weeks performed well?', 'Which weeks did my promotions payback?', 'Which weeks does the rest of market promote?'],
        'showchart': true
    },

    {
        'id': 40,
        'name': 'Weekly Performance',
        'tags': ['Previous Year', 'Incremental', 'Share', 'Retailer', 'Promotion Support'],
        'type': 'Trended',
        'picture': flexibletableweekly,
        'description': 'Weekly promotional trend comparison between ALR and ROM',
        'questions': ['Does ROM promote more than me?', 'Are my promotions more efficient than the ROM?'],
        'showchart': true
    },
    {
        'id': 41,
        'name': 'Segment Performance',
        'tags': ['Previous Year', 'Incremental', 'Share', 'Retailer', 'Promotion Support'],
        'type': 'Aggregate',
        'picture': flexibletablesegment,
        'description': 'Segment comparison between ALR and ROM',
        'questions': ['Does ROM promote more than me?', 'Are my promotions more efficient than the ROM?'],
        'showchart': true
    },
    {
        'id': 42,
        'name': 'Brand Performance',
        'tags': ['Previous Year', 'Incremental', 'Share', 'Retailer', 'Promotion Support'],
        'type': 'Aggregate',
        'picture': flexibletablebrand,
        'description': 'Brand comparison between ALR and ROM',
        'questions': ['Does ROM promote more than me?', 'Are my promotions more efficient than the ROM?'],
        'showchart': true
    }

]

var charts_retail = [
    
    {
        'id': 19,
        'name': 'Base and Incremental Units',
        'tags': ['Base Sales'],
        'type': 'Aggregate',
        'picture': baseandincrementalunits,
        'description': 'Year over year change in base & Incremental metrics',
        'questions': ['Did base or incremental sales drive year over year sales changes?', 'Did subsidized base sales increase year over year?'],
        'showchart': true
    },
    {
        'id': 20,
        'name': 'Promoted and Non-Promoted Units',
        'tags': ['Promoted Sales'],
        'type': 'Aggregate',
        'picture': promotedandnonpromotedunits,
        'description': 'Year over year change in promoted and non-promoted sales metrics',
        'questions': ['Did promoted or non-promoted sales drive year over year sales changes?', 'Are promotion more important brands?'],
        'showchart': true
    },
    {
        'id': 21,
        'name': 'Sales Metrics',
        'tags': ['Base Dollars'],
        'type': 'Aggregate',
        'picture': salesmetrics,
        'description': 'Year over year dollar sales metrics for base, incremental and subsidized sales ',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?'],
        'showchart': true
    },
    {
        'id': 22,
        'name': 'Pricing Metrics',
        'tags': ['Base Price'],
        'type': 'Aggregate',
        'picture': pricingmetrics,
        'description': 'Year over year pricing metric changes for base price, promoted price, average selling price, discount percentage and discount dollars',
        'questions': ['How did prices change year over year?', 'Were there more price promotions this year?'],
        'showchart': true
    },
    {
        'id': 23,
        'name': 'Promotion Metrics',
        'tags': ['Lift'],
        'type': 'Aggregate',
        'picture': promotionmetrics,
        'description': 'Year over year promotion effectiveness metric changes for lift, efficiency, and payback ratio',
        'questions': ['Were promotions more or less effective this year compared to last year?'],
        'showchart': true
    },
    {
        'id': 24,
        'name': 'ALR vs ROM',
        'tags': ['Retailer', 'Promoted Units'],
        'type': 'Aggregate',
        'picture': alrvsrom,
        'description': 'Retailer vs rest of market comparison of various metrics, including discount levels and Promoted sales at varying levels.',
        'questions': ['How much volume is sold on promotion?', 'How does my discounting compare to the rest of market?', 'Does the rest of market promote more than me?'],
        'showchart': true
    },

    {
        'id': 25,
        'name': 'Average Promoted Discount % Trended',
        'tags': ['Retailer'],
        'type': 'Trended',
        'picture': averagepromoteddiscounttrended,
        'description': 'Trended share and discount comparison between retailer and rest of market',
        'questions': ['Am I promoting in the right weeks?', 'Do my promotions drive share gains?', 'When does my competition promote?'],
        'showchart': true
    },
    {
        'id': 26,
        'name': 'Average Selling ',
        'tags': ['Promoted Price'],
        'type': 'Trended',
        'picture': averageselling,
        'description': 'Trended price comparisons between one retailer and rest of market',
        'questions': ['How do my prices compare to the competition?'],
        'showchart': true
    },
    {
        'id': 27,
        'name': 'Depth of Discount by Discount Range',
        'tags': ['Promoted Units'],
        'type': 'Aggregate',
        'picture': depthofdiscountbydiscountrange,
        'description': 'Promoted sales by discount range comparisons between one retailer and Rest of Market',
        'questions': ['How much volume is sold on promotion?', 'Do I sell more or less at different discount ranges?', 'Does the Rest of Market promote more than me?'],
        'showchart': true
    },

    {
        'id': 29,
        'name': 'Base and Incremental ALR',
        'tags': ['Base Sales'],
        'type': 'Aggregate',
        'picture': baseandincrementalalr,
        'description': 'Comparison of base & incremental and promoted & non-promoted sales between retailer and the rest of market',
        'questions': ['Do my promotions drive more incremental sales that my competition?'],
        'showchart': true
    },
    {
        'id': 30,
        'name': 'Lift, Efficiency, and Discount',
        'tags': ['Lift'],
        'type': 'Aggregate',
        'picture': liftefficiencyanddiscount,
        'description': 'Comparison of promoted lift, efficiency, and disount levels between the retailer and rest of market',
        'questions': ['Are my promotions more efficient than rest of market?', 'Do my promotions generate a better incremental lift?', 'How do my discounts compare to rest of market?'],
        'showchart': true
    },
    {
        'id': 31,
        'name': 'Metrics Changes',
        'tags': ['Previous Year'],
        'type': 'Aggregate',
        'picture': metricschanges,
        'description': 'Year over year changes in promotional sales metrics',
        'questions': ['Is my year over year sales performance driven by more/less promotions?', 'Are my promotions generating more or less incremental sales?', 'How is my year over year performance compared to the Rest of Market?'],
        'showchart': true
    },
    {
        'id': 32,
        'name': 'Metric Comparisons to ROM',
        'tags': ['Previous Year'],
        'type': 'Aggregate',
        'picture': metriccomparisonstorom,
        'description': 'Retailer vs Rest of Market comparison across price and promotion measures',
        'questions': ['How do my price and promotion measures compare to the rest of market?', 'How does the change in my price and promotion measure compare to the rest of market?'],
        'showchart': true
    },
    {
        'id': 33,
        'name': 'Top/Bottom Performing Weeks',
        'tags': ['Top Weeks'],
        'type': 'Aggregate',
        'picture': topbottomperformingweeks,
        'description': 'Identify weeks that under or over performed across different measures',
        'questions': ['Which weeks performed well?', 'Which weeks did my promotions payback?', 'Which weeks does the rest of market promote?'],
        'showchart': true
    },
    {
        'id': 34,
        'name': 'Promo Support and Share',
        'tags': ['Incremental', 'Promo Support', 'Share'],
        'type': 'Trended',
        'picture': promosupportandshare,
        'description': 'Identify weeks where share was impacted by promotion support and compare to rest of market',
        'questions': ['How much volume do promotions generate?', 'How much volume is subsidized when promoted?', 'Which brands generate more incremental sales from promotions?', 'Which weeks have the greatest incremental sales?', 'How does my average selling price vary by week?'],
        'showchart': true
    },

    {
        'id': 40,
        'name': 'Weekly Performance',
        'tags': ['Previous Year', 'Incremental', 'Share', 'Retailer', 'Promotion Support'],
        'type': 'Trended',
        'picture': flexibletableweekly,
        'description': 'Weekly promotional trend comparison between ALR and ROM',
        'questions': ['Does ROM promote more than me?', 'Are my promotions more efficient than the ROM?'],
        'showchart': true
    },
    {
        'id': 41,
        'name': 'Segment Performance',
        'tags': ['Previous Year', 'Incremental', 'Share', 'Retailer', 'Promotion Support'],
        'type': 'Aggregate',
        'picture': flexibletablesegment,
        'description': 'Segment comparison between ALR and ROM',
        'questions': ['Does ROM promote more than me?', 'Are my promotions more efficient than the ROM?'],
        'showchart': true
    },
    {
        'id': 42,
        'name': 'Brand Performance',
        'tags': ['Previous Year', 'Incremental', 'Share', 'Retailer', 'Promotion Support'],
        'type': 'Aggregate',
        'picture': flexibletablebrand,
        'description': 'Brand comparison between ALR and ROM',
        'questions': ['Does ROM promote more than me?', 'Are my promotions more efficient than the ROM?'],
        'showchart': true
    }

]

var charts
if (appname === 'PDTRetailer') {
    charts = charts_retail
}

else {
    charts = charts_all
}

const types = Array.from(new Set(charts.map(chart => chart.type)))

let Alltags = []
charts.forEach(chart => {
    chart.tags.forEach(tag => Alltags.push(tag))
})

let AllQuestions = []
charts.forEach(chart => {
    chart.questions.forEach(question => AllQuestions.push(question))
})

const tags = Array.from(new Set(Alltags))
const names = Array.from(new Set(charts.map(chart => { if (chart.showchart) { return chart.name } else return null }).filter(Boolean)))
const questions = Array.from(new Set(AllQuestions))

//Sort
tags.sort()
//names.sort()
questions.sort()

export class PDTScreenOrdering extends Component {
    static displayName = PDTScreenOrdering.name;

    constructor(props) {
        super(props);
        //this.openDialog = openDialog.bind(this)
        //this.getFavorites = this.getFavorites.bind(this)
        this.state = {
            order: [],
            ordername: null,
            showApplyModal: false,
            showOrderModal: false,
            showShareModal: false,
            showSectionModal: false,
            showTabModal: false,
            showVizModal: false,
            showVizModalMulti: false,
            activeSection: null,
            activeTab: null,
            value: {},
            openInfoDataset: {},
            charts,
            filters: {
                'types': ['All'],
                'tags': ['All'],
                'names': ['All'],
                'questions': ['All'],
            },
            types,
            tags,
            names,
            questions,
            deletedialogopen: false,
            dialogopen: false,
            dialogtitle: "",
            dialogtext: "",
            selectedSection: "0",
            selectedTab: "0",
            nameinput: "",
            nameinputSave: 'Select Visualization by Name',
            selectedReports: [],
            selectedUsers: [],
            orderReportMap: {},
            allUsers_data: [],
            user_company_lookup: {},
        }
    }

    async componentDidMount() {
        console.log('SceenOrdering: componentDidMount!')
        var appname = Constant.whereamilist[3]

        this.FetchConfigWithDefault = FetchConfigWithDefault.bind(this)
        this.FetchDataWithErrorHandling = FetchDataWithErrorHandling.bind(this)

        let my_results = await this.FetchConfigWithDefault([
            { 'url': Constant.baseurl + "account/isauthenticated", 'default': {} },
        ], 'SceenOrdering.js', 'User Authentication Status')

        if (my_results['ErrorCount'] > 0) {
            console.log('Error getting User Info in ScreenOrdering')
            this.setState({
                isLoaded: true,
                showBackground: false,
                setAPIRequestsFailed: true
            });
            return
        }

        let userdata = my_results['ReturnList'][0]
        let userRole_data = userdata.userRole || []
        let userRole_val = userRole_data[0] || "User"
        this.setState({ userRole: userRole_val })
        console.log('ScreenOrdering: userRole: ' + userRole_val)

        var allUsers_data
        //let allUsers_response = await fetch(Constant.baseapiurl_gen + "Users")
        let allUsers_response = await fetch(Constant.baseapiurl_gen + "Users?by_company = true")
        if (allUsers_response.status === 200) {
            allUsers_data = await allUsers_response.json()
        }
        else {
            allUsers_data = []
        }
        console.log('ScreenOrdering: Num allUsers: ' + allUsers_data.length)

        let allUsers_list = allUsers_data.map(user => {
            return { 'value': user.user_id, 'label': user.user_name }
        }).filter(Boolean)

        let user_company_lookup = {}
        allUsers_data.forEach(user => {
            user_company_lookup[user.user_id] = user.company_name
        })

        //Find User Settings

        //If User has Screen Order, set it here

        var savedOrder, user_settings_data
        let user_settings_response = await fetch(Constant.baseapiurl_gen + "Users/SettingKeys")
        if (user_settings_response.status === 200) {
            user_settings_data = await user_settings_response.json()
        }
        else {
            user_settings_data = []
        }

        //Check if pdtReportFilters is in the list of settings....
        if (user_settings_data.includes('PDTReportScreenOrderOptions')) {
            let filtersettings_response = await fetch(Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderOptions")
            if (filtersettings_response.status === 200) {
                let savedOrder_data = await filtersettings_response.json()
                savedOrder = savedOrder_data.setting_value
                let orderOptions = Object.keys(savedOrder).map(ordername => { return { 'value': ordername,'label':ordername}})
                this.setState({ orderOptionsMap: savedOrder, orderOptions})
            }
        }

        //Check if pdtReportFilters is in the list of settings....
        if (user_settings_data.includes('PDTReportScreenOrderReportMapping')) {
            let orderReportMap_response = await fetch(Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderReportMapping")
            if (orderReportMap_response.status === 200) {
                let orderReportMap_data = await orderReportMap_response.json()
                let orderReportMap = orderReportMap_data.setting_value
                console.log(orderReportMap)
                this.setState({ orderReportMap })
            }
        }

        //Get Dataset Lookup
        const datasetsres = await fetch(Constant.baseapiurl + "Datasets")
        var datasetsdata = []
        if (datasetsres.status === 200) {
            datasetsdata = await datasetsres.json()
        }
        else {
            datasetsdata = []
        }

        var DatasetLookup = {}

        datasetsdata.forEach(dataset => {
            DatasetLookup[dataset.dataset_id] = dataset.short_desc
        })

        //Get Report Settings
        const reportsres = await fetch(Constant.baseapiurl + "ReportSettings/ReportDetails")
        var reportsdata = []
        if (reportsres.status === 200) {
            reportsdata = await reportsres.json()
        }
        else {
            reportsdata = []
        }

        let reportOptions = reportsdata.map(report => {
            if (appname === 'PDTRetailer') {
                if (Constant.PDT_RETAILER_DATASETS.includes(report.dataset_id)) {
                    return { 'value': report.report_id, 'label': report.report_name }
                }
                else {
                    return null
                }
            }
            else {
                return { 'value': report.report_id, 'label': report.report_name }
            }
        }).filter(Boolean)

        let reportIDMapping = {}
        reportsdata.forEach(report => { reportIDMapping[report.report_id] = report.report_name })
        let reportCreatorMapping = {}
        reportsdata.forEach(report => { reportCreatorMapping[report.report_id] = report.creator_id })
        let reportTimeMapping = {}
        reportsdata.forEach(report => { reportTimeMapping[report.report_id] = report.time_updated })
        let reportDatasetMapping = {}
        reportsdata.forEach(report => { reportDatasetMapping[report.report_id] = DatasetLookup[report.dataset_id] })
        let reportDatasetIDMapping = {}
        reportsdata.forEach(report => { reportDatasetIDMapping[report.report_id] = report.dataset_id })

        console.log(reportIDMapping)
        console.log(reportCreatorMapping)
        console.log(reportTimeMapping)

        console.log('Setting State....')
        this.setState({ reportsdata, reportOptions, reportIDMapping, reportCreatorMapping, reportTimeMapping, reportDatasetMapping, reportDatasetIDMapping, DatasetLookup, allUsers_list,})

        var userid = null
        var companyid = null
        var companyname = null
        var userinfo = null
        let userinforesp = await fetch(Constant.baseapiurl_gen + 'SessionUser/Details')
        if (userinforesp.status === 200) {
            userinfo = await userinforesp.json()
            userid = userinfo.user_id
            companyid = userinfo.company_id
            companyname = userinfo.company_name
        }
        else {
            userinfo = null
        }

        this.setState({ userinfo, userid })

    }

   

    render() {
        console.log(this.state)

        const openDialog = (title, text) => {
            this.setState({
                dialogopen: true,
                dialogtitle: title,
                dialogtext: text
            })
        }

        const filterViews = (selectedOptions, filterby, mode) => {
            let filters = this.state.filters
            let charts = this.state.charts
            if (filterby === 'types') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['types'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['types'] = ['All']
                }
            }

            if (filterby === 'tags') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['tags'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['tags'] = ['All']
                }
            }

            if (filterby === 'questions') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['questions'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['questions'] = ['All']
                }
            }

            if (filterby === 'names') {
                if (selectedOptions && selectedOptions.length > 0) {
                    filters['names'] = selectedOptions.map(option => option.value)
                }
                else {
                    filters['names'] = ['All']
                }
            }

            charts.forEach(chart => {
                let showchart = false
                let usechart = false
                let typesmatch = false
                let tagsmatch = false
                let namesmatch = false
                let questionssmatch = false

                if (filters['types'].includes(chart.type) || (filters['types'].length === 1 && filters['types'][0] === 'All')) {
                    typesmatch = true
                }

                let tags = chart.tags || []
                tags.forEach(tag => {
                    if (filters['tags'].includes(tag) || (filters['tags'].length === 1 && filters['tags'][0] === 'All')) {
                        tagsmatch = true
                    }
                })

                let questions = chart.questions || []
                questions.forEach(question => {
                    if (filters['questions'].includes(question) || (filters['questions'].length === 1 && filters['questions'][0] === 'All')) {
                        questionssmatch = true
                    }
                })

                if (filters['names'].includes(chart.name) || (filters['names'].length === 1 && filters['names'][0] === 'All')) {
                    namesmatch = true
                }

                if (typesmatch && tagsmatch && namesmatch && questionssmatch) {
                    showchart = true
                }

                if (filters['names'].includes(chart.name)) {
                    usechart = true
                    //applytotab
                    if (mode === 'single') {
                        setvisual(chart)
                    }
                    else if (mode === 'multi') {
                        setvisualMulti(chart)
                    }
                }

                if (chart['name'] === 'Base and Incremental by Attribute') {
                    showchart = false
                    usechart = false
                }

                chart['showchart'] = showchart
                chart['usechart'] = usechart

            })

            this.setState({ filters, charts })

            //Set Value of Charts to display


        }

        const setSelectedReports = (selectedOptions) => {
            this.setState({ selectedReports: selectedOptions })
        }

        const setSelectedUsers = (selectedOptions) => {
            this.setState({ selectedUsers: selectedOptions })
        }

        const showInfoDataset = chart => (event) => {
            let openInfoDataset = this.state.openInfoDataset
            openInfoDataset[chart.id] = true
            this.setState({ openInfoDataset, anchorElProjectInfo: event.target })
        }

        const hideInfoDataset = () => {
            console.log('Closing popover')
            this.setState({ openInfoDataset: {} })
            this.setState({ anchorElProjectInfo: null })
        }

        const handleCloseDialog = () => {
            this.setState({ dialogopen: false})
        }

        const handleOpenDeleteDialog = () => {
            this.setState({ deletedialogopen: true })
        }

        const handleCloseDeleteDialog = () => {
            this.setState({ deletedialogopen: false })
        }

        const changeSectionModal = (newsection) => {

            if (this.state.order[this.state.selectedSection]['sectionname'].length === 0 || !this.state.order[this.state.selectedSection]['sectionname']) {
                openDialog('Name must be Provided.', 'You must provide a name for this section.')
            }

            else {
                this.setState({
                    showSectionModal: !this.state.showSectionModal,
                    activeSection: newsection
                })
            }
            
        }

        const changeOrderModal = (newsection) => {

            if (this.state.ordername.length === 0 || !this.state.ordername) {
                openDialog('Name must be Provided.', 'You must provide a name for your configuration.')
            }

            else {
                this.setState({
                    showOrderModal: !this.state.showOrderModal,
                })
            }
            
        }

        const applyOrderModal = (newsection) => {
            this.setState({
                showApplyModal: !this.state.showApplyModal,
            })
        }

        const shareOrderModal = (newsection) => {
            this.setState({
                showShareModal: !this.state.showShareModal,
            })
        }

        const applyOrdertoReports = async () => {
            console.log('Applying Order')            
            let orderReportMap_new = this.state.orderReportMap            
            let reportids = this.state.selectedReports.map(report => report.value)

            async function setScreenSetting(reportid, ordername) {
                let url = Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid + '/pdtReportOrder'
                console.log(JSON.stringify(ordername));
                let settings_response = await fetch(url,
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(ordername)
                    })
                var settings_obj
                if (settings_response.status === 200) {
                    settings_obj = await settings_response.json()
                }
                else {
                    settings_obj = {}
                    alert('There was an Error Saving the filters')
                }

                return settings_response.status
            }

            async function setReportOrderMapping(orderReportMapping) {
                //Save orderReportMap to settings
                console.log('Saving Order Report Map')
                console.log(orderReportMapping)
                let setting = orderReportMapping
                console.log(JSON.stringify(setting))
                let url = Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderReportMapping"
                //let orderReportMap = this.state.orderReportMap || {}
                //console.log(JSON.stringify(orderReportMap_new));
                let settings_response = await fetch(url,
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(setting)
                    })

                var settings_obj
                if (settings_response.status === 200) {
                    settings_obj = await settings_response.json()
                    openDialog('Configuration Saved', 'Configuration Successfully Saved.')
                }
                else {
                    settings_obj = {}
                    openDialog('Configuration Error', 'There was an Error Saving the Configuration.')
                }
            }

            let lastidx = reportids.length - 1
            reportids.forEach(async (reportid,idx) =>  {
                let status = await setScreenSetting(reportid, this.state.ordername)
                if (status === 200) {
                    //Remove from any existing mapping
                    Object.keys(orderReportMap_new).forEach(order => {
                        let current_reports = orderReportMap_new[order] || []
                        let reportidx = current_reports.indexOf(reportid)
                        //Found a match
                        if (reportidx > -1) {
                            current_reports.splice(reportidx, 1)
                        }
                        orderReportMap_new[order] = current_reports
                    })

                    //Add to order report map
                    orderReportMap_new[this.state.ordername] = orderReportMap_new[this.state.ordername] || []
                    orderReportMap_new[this.state.ordername].push(reportid)
                }

                if (idx === lastidx) {
                    console.log(JSON.stringify(orderReportMap_new))
                    this.setState({  orderReportMap: orderReportMap_new }, async () => await setReportOrderMapping(this.state.orderReportMap))
                }
            })
            
            applyOrderModal()
        }

        const shareOrderwithUsers = async () => {

            console.log('Share Configuration')

            //Save to User Settings as Default Order

            //Get settings
            //let url = Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderOptions"

            // Share other user's settings
            //let url = Constant.baseapiurl_gen + "Users/" + this.state.userid + "/Share/PDTReportScreenOrderOptions"

            // Share current user's settings
            let url = Constant.baseapiurl_gen + "SessionUser/Share/PDTReportScreenOrderOptions"

            /*
            console.log('Share Configuration url = ' + url)
            console.log('Share Configuration current userid = ' + this.state.userid)
            console.log('Share Configuration this.state.ordername = ' + this.state.ordername)
            console.log('Share Configuration this.state.order = ' + JSON.stringify(this.state.order))
            */

            let orderOptionsMap = this.state.orderOptionsMap || {}
            orderOptionsMap[this.state.ordername] = this.state.order
            console.log('Share Configuration orderOptionsMap: ' + JSON.stringify(orderOptionsMap))

            if (this.state.selectedUsers !== null) {

                let userIDs = this.state.selectedUsers.map(user => user.value)

                //userIDs.forEach(async (userid, idx) => {
                console.log('Share Configuration userIDs.length = ' + userIDs.length);

                if (userIDs.length > 0) {

                    console.log('Share Configuration to users = ' + userIDs);

                    let settings_response = await fetch(url,
                        {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ "user_list": userIDs, "share_name": this.state.ordername })
                        })

                    var settings_obj
                    if (settings_response.status === 200) {
                        settings_obj = await settings_response.json()
                        openDialog('Configuration Shared', 'Configuration Successfully Shared.')
                    }
                    else {
                        settings_obj = {}
                        openDialog('Configuration Error', 'There was an Error Sharing the Configuration.')
                    }

                    //Add to a list of Options for the User
                    let orderOptions = Object.keys(orderOptionsMap).map(ordername => { return { 'value': ordername, 'label': ordername } })
                    this.setState({ orderOptionsMap, orderOptions })
                }
            }

            shareOrderModal()

        }

        const changeTabModal = (section, tab) => {

            let tabname = this.state.order[this.state.selectedSection]['tabs'][this.state.selectedTab]['tabname']

            if (tabname.length === 0 || !tabname) {
                openDialog('Name must be Provided.', 'Please provide a name for this tab.')
            }

            else {
                this.setState({
                    showTabModal: !this.state.showTabModal,
                    activeSection: section,
                    activeTab: tab
                })
            }
            
        }

        const changeVizModal = (section, tab) => {
            this.setState({
                showVizModal: !this.state.showVizModal,
                activeSection: section,
                activeTab: tab
            })
        }

        const changeVizModalMulti = (section) => {
            this.setState({
                showVizModalMulti: !this.state.showVizModalMulti,
                activeSection: section,                
            })
        }

        const handleSectionNameChange = () => {
            let order_new = this.state.order
            let name = document.getElementById('sectionname').value
            order_new[this.state.activeSection]['sectionname'] = name
            this.setState({order:order_new})
        }

        const handleOrderNameChange = () => {        
            console.log(this.state.ordername)
            let name = document.getElementById('ordername').value 

            //check if name is already in OrderMap
            let existingnames = Object.keys(this.state.orderOptionsMap || {})
            if (existingnames.includes(name)) {
                openDialog( name + ' Already Exists', 'Please Choose Another Name')                
            }
            else { 
                let orderOptionsMap = this.state.orderOptionsMap || {}
                delete orderOptionsMap[this.state.ordername]
                orderOptionsMap[name] = this.state.order
                let orderOptions = Object.keys(orderOptionsMap).map(ordername => { return { 'value': ordername, 'label': ordername } })
                this.setState({ ordername: name, orderOptionsMap, orderOptions })
            }           
            
        }

        const handleTabNameChange = () => {
            let order_new = this.state.order
            let name = document.getElementById('tabname').value
            order_new[this.state.activeSection]['tabs'][this.state.activeTab]['tabname'] = name
            this.setState({order:order_new})
        }

        const handleChangeTabs = key => (event, newValue) => {
            let values = this.state.value
            values[key] = newValue
            this.setState({ value: values })
        }

        const changeSelectedSection = key => {
            console.log(key)
            this.setState({ selectedSection: key })
        }

        const changeSelectedTab = key => {
            console.log(key)
            this.setState({ selectedTab: key })
        }

        const setOrder = (selectedOptions) => {
            console.log(selectedOptions)
            let ordername = selectedOptions.value
            let order = this.state.orderOptionsMap[ordername] || []
            let reportIDMapping = this.state.reportIDMapping || {}
            console.log(reportIDMapping)
            var appname = Constant.whereamilist[3]
            //Set Selected Reports Based on ordername
            let selectedReports = (this.state.orderReportMap[ordername] || []).map(reportid => {
                //Check if ReportID is in list of reports
                let reportdatasetid = this.state.reportDatasetIDMapping[reportid]
                if (appname === 'PDTRetailer') {
                    if (Constant.PDT_RETAILER_DATASETS.includes(reportdatasetid)) {
                        return { 'label': reportIDMapping[reportid], 'value': reportid }
                    }
                    else {
                        return null
                    }
                }
                else {
                    return { 'label': reportIDMapping[reportid], 'value': reportid }
                }
            }).filter(Boolean)

            this.setState({ order, ordername, selectedReports})
        }

        const addNewOrder = () => {
            let ordername = 'My Saved Configuration'
            let order = []
            this.setState({ order, ordername })
        }

        const deleteOrder = async () => {
            let ordername = this.state.ordername
            let orderOptionsMap = this.state.orderOptionsMap
            let orderReportMap = this.state.orderReportMap
            delete orderOptionsMap[ordername]

            //Remove ordername from reports
            let reports = orderReportMap[ordername] || []
            reports.forEach(async reportid => {
                let url = Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid + '/pdtReportOrder'
                let reportsres = await fetch(url, { method: 'DELETE' })
                var reportsdata = []
                if (reportsres.status === 200) {
                    reportsdata = await reportsres.json()
                }
                else {
                    reportsdata = []
                }
            })
            delete orderReportMap[ordername]

            let orderOptions = Object.keys(orderOptionsMap).map(ordername => { return { 'value': ordername, 'label': ordername } })
            this.setState({ ordername: null, order: [], orderOptionsMap, orderOptions, orderReportMap }, () => saveconfigAll())
            handleCloseDeleteDialog()
            
        }

        const saveconfigAll = async () => {
            let url = Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderOptions"
            let orderOptionsMap = this.state.orderOptionsMap || {}
            console.log(JSON.stringify(orderOptionsMap));
            let settings_response = await fetch(url,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orderOptionsMap)
                })

            var settings_obj
            if (settings_response.status === 200) {
                settings_obj = await settings_response.json()
                openDialog('Configuration Saved', 'Configuration Successfully Saved.')
            }
            else {
                settings_obj = {}
                openDialog('Configuration Error', 'There was an Error Saving the Configuration.')
            }

            //Add to a list of Options for the User
            let orderOptions = Object.keys(orderOptionsMap).map(ordername => { return { 'value': ordername, 'label': ordername } })
            this.setState({ orderOptionsMap, orderOptions })
        }

        const saveconfig = async () => {
            console.log('Saving Configuration')

            //Save to User Settings as Default Order
            let url = Constant.baseapiurl_gen + "Users/Settings/PDTReportScreenOrderOptions"

            let orderOptionsMap = this.state.orderOptionsMap || {}
            orderOptionsMap[this.state.ordername] = this.state.order

            console.log(JSON.stringify(orderOptionsMap));
            let settings_response = await fetch(url,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orderOptionsMap)
                })

            var settings_obj
            if (settings_response.status === 200) {
                settings_obj = await settings_response.json()
                openDialog('Configuration Saved', 'Configuration Successfully Saved.')
            }
            else {
                settings_obj = {}                
                openDialog('Configuration Error', 'There was an Error Saving the Configuration.')
            }

            //Add to a list of Options for the User
            let orderOptions = Object.keys(orderOptionsMap).map(ordername => { return { 'value': ordername, 'label': ordername } })
            this.setState({ orderOptionsMap, orderOptions})
            
        }

        const addsection = () => {

            let numsections = this.state.order.length//Object.keys(this.state.order).length
            let newsection = numsections //+= 1
            let newsectionnum = numsections += 1
            let newsectionname = 'New Section #' + newsectionnum
            let order_new = this.state.order
            order_new[newsection] = {
                'sectionname': newsectionname, 'tabs': [  ]
            }
            this.setState({ order: order_new })
            addvisuals(newsection)
        }

        const addtab = (key) => {
            let order_new = this.state.order
            let section = order_new[key]
            console.log(section)
            let numtabs = section['tabs'].length//Object.keys(section['tabs']).length
            let newtab = numtabs //+= 1    
            let newtabnum = numtabs += 1
            let newtabname = 'New Tab #' + newtabnum
            section['tabs'][newtab] = { 'tabname': newtabname, 'visual': '' }
            this.setState({ order: order_new, activeTab: newtabnum }, () => { addvisual(key, newtab) })
            
        }

        const addvisual = (section,tab) => {
            changeVizModal(section, tab)
        }

        const addvisuals = (section) => {
            changeVizModalMulti(section)
        }

        const setvisual = (chart) => {
            console.log(chart)
            let order_new = this.state.order
            let current_visual = order_new[this.state.activeSection]['tabs'][this.state.activeTab]['visual']
            if (current_visual === chart.name) {
                order_new[this.state.activeSection]['tabs'][this.state.activeTab]['visual'] = ''
            }
            else {
                order_new[this.state.activeSection]['tabs'][this.state.activeTab]['visual'] = chart.name
            }
            
            this.setState({ order: order_new })
        }

        const setvisualMulti = (chart) => {
            console.log(chart)
            let order_new = this.state.order
            let current_visuals = (order_new[this.state.activeSection]['tabs'] || []).map(tab=>tab.visual)
            if (current_visuals.includes(chart.name) && chart.usechart === false) {
                //Remove from visuals
                const index = current_visuals.indexOf(chart.name);
                (order_new[this.state.activeSection]['tabs'] || []).splice(index,1)
            }

            else if (current_visuals.includes(chart.name) && chart.usechart === true) {
                //Do Nothing
            }

            else {
                order_new[this.state.activeSection]['tabs'].push({ 'tabname': chart.name,'visual':chart.name})//[this.state.activeTab]['visual'] = chart.name
            }

            this.setState({ order: order_new })
        }

        const applyViz = () => {
            let order_new = this.state.order
            //reset charts to show all..
            let charts = this.state.charts
            charts.forEach(chart => {
                chart['showchart'] = true
            })
            this.setState({
                order: order_new,
                filters: {
                    'types': ['All'],
                    'tags': ['All'],
                    'names': ['All'],
                    'questions': ['All']
                },
                charts
            })
            changeVizModal(this.state.activeSection, this.state.activeTab)
            hideInfoDataset()
        }

        const applyVizMulti = () => {
            console.log('Applying multi Viz')
            this.setState({
                filters: {
                    'types': ['All'],
                    'tags': ['All'],
                    'names': ['All'],
                    'questions': ['All']
                }
            })
            changeVizModalMulti(this.state.activeSection)
        }

        const deletesection = (section) => {                        
            //Remove The Section
            let order = this.state.order
            if (order.length > 1) {
                order.splice(section, 1)
                if (parseInt(this.state.selectedSection) >= order.length) {
                    this.setState({
                        selectedSection: String(order.length - 1)
                    })
                }
            }
            else {                
                openDialog('Minimum Sections Needed', 'There Must be at Least One Section')
            }
            this.setState({order})
        } 

        const movesectionup = (section) => {
            //Remove The Section
            let order = this.state.order
            if (parseInt(section) !== 0) {
                let newsection = parseInt(section) - 1
                let sectiontoswapwith = order[newsection]
                let sectiontoswap = order[parseInt(section)]
                //Swap 
                order[newsection] = sectiontoswap
                order[parseInt(section)] = sectiontoswapwith       
                this.setState({ selectedSection: String(newsection)})
            }
            else {
                openDialog('Can Not Move Further', 'Can Not Move Section Further Up.  It is in the first position.')                
            }
            this.setState({ order })
        } 

        const movesectiondown = (section) => {
            //Remove The Section
            let order = this.state.order
            if (parseInt(section) !== (this.state.order.length -1)) {
                let newsection = parseInt(section) + 1
                let sectiontoswapwith = order[newsection]
                let sectiontoswap = order[parseInt(section)]
                //Swap 
                order[newsection] = sectiontoswap
                order[parseInt(section)] = sectiontoswapwith
                this.setState({ selectedSection: String(newsection) })
            }
            else {
                openDialog('Can Not Move Further', 'Can Not Move Section Further Down.  It is in the last position.')
            }
            this.setState({ order })
        }

        const movetableft = (section, tab) => {
            let order = this.state.order
            let tabs = order[parseInt(section)]['tabs']
            let value = this.state.value
            if (parseInt(tab) !== 0) {
                let newtab = parseInt(tab) - 1
                let tabtoswapwith = tabs[newtab]
                let tabtoswap = tabs[parseInt(tab)]
                //Swap 
                tabs[newtab] = tabtoswap
                tabs[parseInt(tab)] = tabtoswapwith
                order[parseInt(section)]['tabs'] = tabs
                value[parseInt(section)] = newtab
            }
            else {
                openDialog('Can Not Move Further', 'Can Not Move Tab Further Left.  It is in the first position.')
            }
            this.setState({ order, value })
        }

        const movetabright = (section, tab) => {
            //Remove The Section
            let order = this.state.order
            let tabs = order[parseInt(section)]['tabs']
            let value = this.state.value
            if (parseInt(tab) !== (tabs.length - 1)) {
                let newtab = parseInt(tab) + 1
                let tabtoswapwith = tabs[newtab]
                let tabtoswap = tabs[parseInt(tab)]
                //Swap 
                tabs[newtab] = tabtoswap
                tabs[parseInt(tab)] = tabtoswapwith
                order[parseInt(section)]['tabs'] = tabs
                value[parseInt(section)] = newtab
                
            }
            else {
                openDialog('Can Not Move Further', 'Can Not Move Tab Further Right.  It is in the last position.')
            }
            this.setState({ order, value })
        }

        const deletetab = (section, tab) => {
            //Remove The Tabs
            let order = this.state.order
            let tabs = order[section]['tabs']
            let value = this.state.value
            if (tabs.length > 1) {
                tabs.splice(tab, 1)
                order[section]['tabs'] = tabs
                //If the tab is greater than the length of the tabs, set to the end
                if (tab > (tabs.length - 1)) {
                    value[section] = (tabs.length - 1)
                    if (parseInt(this.state.selectedTab) >= tabs.length) {
                        this.setState({
                            selectedTab: String(tabs.length - 1)
                        })
                    }
                }
            }

            else {                
                openDialog('Minimum Tabs Needed', 'There Must be at Least One Tab to a Section')
            }

            this.setState({ order, value })
        }

        const setNameInput = (newValue) => {
            console.log(newValue)
            this.setState({ nameinput: newValue })
        }

        const setNameSave = (value) => {
            console.log(value)
            this.setState({ nameinputSave: value })
        }


        const dropdownInput = {
            width: '100%',
            height: '50px',
            borderRadius: '5px',
            //padding: '10px',
            fontFamily: 'Roboto',

        }

        const ReportOption = (props) => {

            let report_name = props.data.label
            let report_id = props.data.value

            //reportIDMapping, reportCreatorMapping, reportTimeMapping
            let creator_id = this.state.reportCreatorMapping[report_id]
            let timeupdated = this.state.reportTimeMapping[report_id]
            let datasetname = this.state.reportDatasetMapping[report_id]
            var ownership_string
            if (creator_id === this.state.userid) {
                ownership_string = 'my report.'
            }

            else {
                ownership_string = "someone else's report."
            }

            //Create Locale String from Date...
            let timeupdated_formatted_date = new Date(timeupdated)            
            

            return (
                <Constant.HTMLTooltip title={
                    <Fragment>
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{report_name}</Typography>
                        <br />
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{'The report is for dataset: ' + datasetname}</Typography>
                        <br />
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{'Last Updated: ' + timeupdated_formatted_date.toLocaleDateString()}</Typography>
                        <br />
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{'This is ' + ownership_string }</Typography>
                    </Fragment>
                } placement="right" {...props}>
                    <div>
                        <components.ReportOption {...props} />
                    </div>
                </Constant.HTMLTooltip>
            );
        }

        const UserOption = (props) => {

            let user_name = props.data.label
            let user_id = props.data.value
            let company_name = this.state.user_company_lookup[user_id]

            return (
                <Constant.HTMLTooltip title={
                    <Fragment>
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{user_name}</Typography>
                        <br />
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{'User ID: ' + user_id}</Typography>
                        <br />
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{'Company: ' + company_name}</Typography>
                    </Fragment>
                } placement="right" {...props}>
                    <div>
                        <components.UserOption {...props} />
                    </div>
                </Constant.HTMLTooltip>
            );
        }

        return (
            <Layout title="Report Ordering Configuration" >

                <Dialog
                    open={this.state.dialogopen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dialogtitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.dialogtext}
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog} className="btn btn-primary" autoFocus>
                            Ok
		                </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.deletedialogopen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">Delete Order</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to delete this configuration from your settings.  
                            Deleting this configuration will also remove the configuration from any reports you have set this to.  
                            This action is final and can not be undone.  
                            Are you sure you wish to continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={deleteOrder} className="btn btn-primary" autoFocus>
                            Delete
		                </button>

                        <button onClick={handleCloseDeleteDialog} className="btn btn-secondarynpd" autoFocus>
                            Cancel
		                </button>
                    </DialogActions>
                </Dialog>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showOrderModal}
                    onClose={changeOrderModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(50%, 20%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                        <div className="row">

                            <div className='row col-sm-12'>
                                <div style={{ textAlign: 'left', width: '50%' }}><p style={{ marginTop: '1rem', marginBottom: '1rem' }}> Add A Name For This Configuration </p></div>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={changeOrderModal}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Report Name"
                                inputProps={{ 'aria-label': 'naked', id: "ordername" }}
                                onChange={handleOrderNameChange}
                                id="ordername"
                                style={dropdownInput}
                                value={this.state.ordername}
                            />
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <Tooltip
                                title='Save Configuration Name'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={changeOrderModal}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showSectionModal}
                    onClose={changeSectionModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(50%, 20%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                        <div className="row">
                            
                            <div className='row col-sm-12'>
                                <div style={{ textAlign: 'left', width: '50%' }}><p style={{ marginTop: '1rem', marginBottom: '1rem' }}> Add A Name For This Section </p></div>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={changeSectionModal}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </div>
                            </div> 
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Report Name"
                                inputProps={{ 'aria-label': 'naked', id: "sectionname" }}
                                onChange={handleSectionNameChange}
                                id="sectionname"
                                style={dropdownInput}
                                value={(this.state.order[this.state.activeSection] || {})['sectionname']}
                            />
                        </div> 
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <Tooltip
                                title='Save Section Name'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={changeSectionModal}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>                            
                        </div>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showTabModal}
                    onClose={changeTabModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(50%, 20%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                        <div className="row">
                            <div className='row col-sm-12'>
                                <div style={{ textAlign: 'left', width: '50%' }}><p style={{ marginTop: '1rem', marginBottom: '1rem' }}> Add A Name For This Tab </p></div>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={changeTabModal}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </div>
                            </div>                             
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Report Name"
                                inputProps={{ 'aria-label': 'naked', id: "tabname" }}
                                onChange={handleTabNameChange}
                                id="tabname"
                                style={dropdownInput}
                                value={(((this.state.order[this.state.activeSection] || {})['tabs'] || {})[this.state.activeTab] || {})['tabname']}
                            />
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <Tooltip
                                title='Save Tab Name'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={changeTabModal}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>                              
                        </div>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showVizModal}
                    onClose={applyViz}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '95%', overflowY: 'auto' }} className="contentHolder">
                        <div className="row col-sm-12">
                            <div className="row col-sm-12">                                
                                <div style={{ textAlign: 'left', width: '50%' }}><h4 style={{ marginTop: '1rem', marginBottom: '1rem' }}> Select a visualization for tab </h4></div>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={applyViz}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </div>                                                               
                            </div>
                            <div className="row col-sm-12">                                
                                <p>
                                    {'Active Section: ' + (this.state.order[this.state.activeSection] || {})['sectionname'] + 
                                    ', Active Tab: ' + (((this.state.order[this.state.activeSection] || {})['tabs'] || {})[this.state.activeTab] || {})['tabname'] + 
                                    ', Current Visual: ' + (((this.state.order[this.state.activeSection] || {})['tabs'] || {})[this.state.activeTab] || {})['visual']
                                    }
                                </p>                                
                            </div>
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="typedropdown"
                                    options={this.state.types.map(type => {
                                        return { 'value': type, 'label': type || "Unknown" }
                                    })
                                    }
                                    placeholder={'Select Visualization by Type'}
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'types', 'single')}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="questionsdropdown"
                                    options={this.state.questions.map(question => {
                                        return { 'value': question, 'label': question || "Unknown" }
                                    })
                                    }
                                    placeholder={'Select Visualization by Question'}
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'questions', 'single')}
                                    styles={selectColourStyles}

                                />
                            </div>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="tagsdropdown"
                                    options={this.state.tags.map(tag => {
                                        return { 'value': tag, 'label': tag || "Unknown" }
                                    })
                                    }
                                    placeholder={'Select Visualization by Tags'}
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'tags', 'single')}
                                    styles={selectColourStyles}

                                />
                            </div>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="namesdropdown"
                                    options={this.state.names.map(name => {
                                        return { 'value': name, 'label': name || "Unknown" }
                                    })
                                    }
                                    placeholder={'Select Visualization by Name'}
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'names', 'single')}
                                    closeMenuOnSelect={false}
                                    styles={selectColourStyles}

                                />
                                <br/>
                            </div>                            
                        </div>
                        <div className="row col-sm-12"  style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', maxHeight:'500px',overflowY:'auto'}}>
                            {this.state.charts.map(chart => {
                                //Look to the visual in the tab, and if selected, check the box
                                let visualselected = (((this.state.order[this.state.activeSection] || {})['tabs'] || {})[this.state.activeTab] || {})['visual']
                                let visualchecked = false
                                if (visualselected === chart.name) {
                                    visualchecked = true
                                }
                                if (chart.usechart === true) {
                                    visualchecked = true
                                }
                                if (chart.showchart) {
                                    return <div className="row col-sm-4" key={chart.id} style={{ padding: '20px' }}>
                                        <div className="row col-sm-2">
                                            <ThemeProvider theme={Constant.muiTheme}>
                                                <Checkbox
                                                    checked={visualchecked}
                                                    tabIndex={-1}
                                                    disableRipple                                                    
                                                    onChange={(key) => setvisual(chart)}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <div className="row col-sm-10"><h6>{chart.name}</h6></div>


                                        <Constant.HTMLTooltip
                                            title={
                                                <Fragment>
                                                    <p>{chart.description}</p>
                                                    <b>What questions can this chart help answer?</b>
                                                    <ul>
                                                        {chart.questions.map(question => {
                                                            return <li key={chart.name.replace('/','')+question}>{question}</li>
                                                        })}
                                                    </ul>
                                                </Fragment>
                                            }
                                            placement="right-start"
                                        >
                                            <div>
                                                <Fragment>
                                                    <img
                                                        src={chart.picture}
                                                        height='100%'
                                                        width='100%'
                                                        align='center'
                                                        style={{ padding: '0px', marginTop: '0px' }}
                                                        alt="ChartImage"
                                                    />
                                                </Fragment>
                                            </div>
                                        </Constant.HTMLTooltip>
                                    </div>
                                }
                                else {
                                    return null
                                }
                                
                            })}
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', padding:'25px'}}>
                            <Tooltip
                                title='Save Visualization Selection'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={applyViz}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>                              
                        </div>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showVizModalMulti}
                    onClose={applyVizMulti}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '95%', overflowY: 'auto' }} className="contentHolder">
                        <div className="row col-sm-12">
                            <div className="row col-sm-12">
                                <div style={{ textAlign: 'left', width: '50%' }}><h4 style={{ marginTop: '1rem', marginBottom: '1rem' }}> Select Visualizations for Section </h4></div>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={applyVizMulti}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="row col-sm-12">
                                <p> {'Active Section: ' + (this.state.order[this.state.activeSection] || {})['sectionname']}</p>
                            </div>
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="typedropdown"
                                    options={this.state.types.map(type => {
                                        return { 'value': type, 'label': type || "Unknown" }
                                    })
                                    }
                                    placeholder={'Select Visualization by Type'}
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'types', 'multi')}
                                    styles={selectColourStyles}

                                />
                            </div>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="questionsdropdown"
                                    options={this.state.questions.map(question => {
                                        return { 'value': question, 'label': question || "Unknown" }
                                    })
                                    }
                                    placeholder={'Select Visualization by Question'}
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'questions', 'multi')}
                                    styles={selectColourStyles}

                                />
                            </div>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="tagsdropdown"
                                    options={this.state.tags.map(tag => {
                                        return { 'value': tag, 'label': tag || "Unknown" }
                                    })
                                    }
                                    placeholder={'Select Visualization by Tags'}
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'tags', 'multi')}
                                    styles={selectColourStyles}

                                />
                            </div>
                            <div className="col-sm-3" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                <Select
                                    inputId="namesdropdown"
                                    options={this.state.names.map(name => {
                                        return { 'value': name, 'label': name || "Unknown" }
                                    })
                                    }
                                    placeholder={this.state.nameinputSave}
                                    
                                    isMulti
                                    onChange={(options, filterby, mode) => filterViews(options, 'names', 'multi')}
                                    closeMenuOnSelect={false}
                                    inputValue={this.state.nameinput} // allows you continue where you left off
                                    onInputChange={(value, action) => {setNameInput(value)}} // allows for actually typing
                                    onMenuClose={() => setNameSave(this.state.nameinput)} // before the input is cleared, save it
                                    onFocus={() => {
                                        if (this.state.nameinputSave === 'Select Visualization by Name') {
                                            setNameInput("");
                                        }
                                        else if (this.state.nameinputSave === '') {
                                            setNameSave('Select Visualization by Name');
                                        }
                                        else {
                                            setNameInput(this.state.nameinputSave)
                                            setNameSave(this.state.nameinputSave);
                                        }
                                    }}
                                    blurInputOnSelect  // actually allows for ^^ to work
                                    styles={selectColourStyles}

                                />
                                <br/>
                            </div>
                            
                        </div>

                        <div className="row col-sm-12" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', maxHeight: '500px', overflowY: 'auto' }}>
                            {this.state.charts.map(chart => {
                                //Look to the visual in the tab, and if selected, check the box
                                let visualsselected = ((this.state.order[this.state.activeSection] || {})['tabs'] || []).map(tab => tab.visual)
                                let visualchecked = false
                                if (visualsselected.includes(chart.name)) {
                                    visualchecked = true
                                }
                                if (chart.usechart === true) {
                                    visualchecked = true
                                }
                                if (chart.showchart) {
                                    return <div className="row col-sm-4" key={chart.id} style={{ padding: '20px' }}>
                                        <div className="row col-sm-2">
                                            <ThemeProvider theme={Constant.muiTheme}>
                                                <Checkbox
                                                    checked={visualchecked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    onChange={(key) => setvisualMulti(chart)}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <div className="row col-sm-10"><h6>{chart.name}</h6></div>


                                        <Constant.HTMLTooltip
                                            title={
                                                <Fragment>
                                                    <p>{chart.description}</p>
                                                    <b>What questions can this chart help answer?</b>
                                                    <ul>
                                                        {chart.questions.map(question => {
                                                            return <li key={chart.name.replace('/', '') + question}>{question}</li>
                                                        })}
                                                    </ul>
                                                </Fragment>
                                            }
                                            placement="right-start"
                                        >
                                            <div>
                                                <Fragment>
                                                    <img
                                                        src={chart.picture}
                                                        height='100%'
                                                        width='100%'
                                                        align='center'
                                                        style={{ padding: '0px', marginTop: '0px' }}
                                                        alt="ChartImage"
                                                    />
                                                </Fragment>
                                            </div>
                                        </Constant.HTMLTooltip>
                                    </div>
                                }
                                else {
                                    return null
                                }

                            })}
                        </div>
                        <div className="row" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', padding: '25px' }}>
                            <Tooltip
                                title='Save Visualization Selection'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={applyVizMulti}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showApplyModal}
                    onClose={applyOrderModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(50%, 20%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                        <div className="row">

                            <div className='row col-sm-12'>
                                <div style={{ textAlign: 'left', width: '50%' }}><p style={{ marginTop: '1rem', marginBottom: '1rem' }}> Select Reports This Configuration Should Apply To </p></div>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={applyOrderModal}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12' style={{ padding: '25px' }} >
                            <Select
                                inputId="typedropdown"
                                options={this.state.reportOptions}
                                placeholder={'Select Reports'}
                                isMulti
                                onChange={(options) => setSelectedReports(options)}
                                value={this.state.selectedReports}
                                maxMenuHeight={125}
                                closeMenuOnSelect={false}
                                styles={Constant.dropdownStyles}
                                styles={selectColourStyles}
                                components={{ ReportOption }}
                            />
                        </div>
                        <div className="row" style={{ padding: '25px' }}>
                            <Tooltip
                                title='Apply Configuration to Reports'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={applyOrdertoReports}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showShareModal}
                    onClose={shareOrderModal}
                    style={{ overflowY: 'scroll' }}
                >
                    <div style={{ transform: 'translate(50%, 20%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                        <div className="row">

                            <div className='row col-sm-12'>
                                <div style={{ textAlign: 'left', width: '50%' }}><p style={{ marginTop: '1rem', marginBottom: '1rem' }}> Select Users To Share This Configuration With </p></div>
                                <div style={{ textAlign: 'right', width: '50%' }}>
                                    <IconButton
                                        onClick={shareOrderModal}
                                    >
                                        <CancelRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12' style={{ padding: '25px' }} >
                            <Select
                                inputId="typedropdown"
                                options={this.state.allUsers_list}
                                placeholder={'Select Users'}
                                isMulti
                                onChange={(options) => setSelectedUsers(options)}
                                value={this.state.selectedUsers}
                                maxMenuHeight={125}
                                closeMenuOnSelect={false}
                                styles={Constant.dropdownStyles}
                                styles={selectColourStyles}
                                components={{ UserOption }}
                            />
                        </div>
                        <div className="row" style={{ padding: '25px' }}>
                            <Tooltip
                                title='Share Configuration with Users'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={shareOrderwithUsers}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </Modal>

                <div name='banner' className='banner'>
                    <h1white align='center'>Price and Discount Trends Report Configuration</h1white><br/>
                    <h2white align='center'>Use this page to adjust the sections, tabs, and order of the report output</h2white>
                </div>

                <div className="mainbodydiv contentHolderGradient" style={{ padding: '50px' }}>   
                    <div className="row" >
                        
                        <div className="col-sm-12" id="helpertextlink" style={{ height: "100px", overflowY: "auto" }} >                            
                        {/*    <a href={helppdf} target="_blank" rel="noopener noreferrer" style={{ fontSize:'1.2rem'}}>Click here to view instructions</a>*/}
                        {/*    <br/>*/}
                            {/*    <a href={helpvideo} target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.2rem' }}>Click here to watch a how to video</a>*/}
                            <h7>Instructions and video coming soon</h7>
                        </div>
                                                
                    </div>
                    
                    <div className="row" >
                        <div className="col-sm-3">
                            <span className="formfieldtitle">Select an Existing Configuration</span>
                            <Select
                                inputId="typedropdown"
                                options={this.state.orderOptions}
                                placeholder={'Select A Configuration to Modify/Use'}
                                onChange={(options) => setOrder(options)}
                                value={this.state.ordername ? { 'label': this.state.ordername, 'value': this.state.ordername } : null}
                                style={{
                                    backgroundColor: "#FFF"
                                }}
                                styles={selectColourStyles}
                            />
                        </div>
                        <div className="col-sm-3" style={{ padding: "12px" }}>                            
                            <Tooltip
                                title='Add A New Configuration'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={addNewOrder}
                                >
                                    <AddRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>                            
                        </div>
                        <div className="col-sm-3">
                            
                        </div>
                        <div className="col-sm-3">
                            
                        </div>
                    </div>
                    {/*Order Control Bar*/}
                    {this.state.ordername ?
                        <Fragment>
                            <h4>{this.state.ordername}</h4>

                            <Tooltip
                                title='Add A Section'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={addsection}
                                >
                                    <AddRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title='Rename This Saved Configuration'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={changeOrderModal}
                                >
                                    <TextFieldsRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title='Delete This Configuration'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={() => handleOpenDeleteDialog()}
                                >
                                    <DeleteRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title='Save Configuration'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={saveconfig}
                                >
                                    <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title='Apply to Reports'
                                placement='top-start'
                            >
                                <IconButton
                                onClick={applyOrderModal}
                                >
                                    <AddToPhotosRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>

                            {this.state.userRole !== "User" ?
                                <Tooltip
                                    title='Share Configuration'
                                    placement='top-start'
                                >
                                    <IconButton
                                        onClick={shareOrderModal}
                                    >
                                        <ShareRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </Tooltip>
                                :
                                <div />
                            }

                            {/*Sections*/}
                            <div className='col-sm-12 row'>
                                {Object.keys(this.state.order).map(orderkey => {
                                    var classtouse
                                    if (orderkey === this.state.selectedSection) {
                                        classtouse = 'SectionBoxSelected'
                                    }

                                    else {
                                        classtouse = 'SectionBox'
                                    }

                                    return <div className={classtouse} key={orderkey} onClick={(key) => changeSelectedSection(orderkey)}>
                                        <span style={{
                                            //color: '#0078BE',
                                            cursor: 'pointer',
                                            //fontWeight: 'bold'
                                        }}>
                                            {this.state.order[orderkey]['sectionname']}
                                        </span>
                                    </div>
                                })}
                            </div>
                        </Fragment>
                        :
                        <Fragment />
                    }
                    {/*Section Control Bar*/}
                    {Object.keys(this.state.order).length >= 1 ?
                        <div className='col-sm-12 row'>

                            <Tooltip
                                title='Add a Tab'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={(key) => addtab(this.state.selectedSection)}
                                >
                                    <AddRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title='Rename This Section'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={(key) => changeSectionModal(this.state.selectedSection)}
                                >
                                    <TextFieldsRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title='Delete This Section'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={(key) => deletesection(this.state.selectedSection)}
                                >
                                    <DeleteRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title='Move This Section Up'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={(key) => movesectionup(this.state.selectedSection)}
                                >
                                    <ArrowBackRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title='Move This Section Down'
                                placement='top-start'
                            >
                                <IconButton
                                    onClick={(key) => movesectiondown(this.state.selectedSection)}
                                >
                                    <ArrowForwardRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        :
                        <div />
                    }
                    {Object.keys(this.state.order).map(orderkey => {
                        var displaystyle
                        if (orderkey === this.state.selectedSection) {
                            displaystyle = 'block'
                        }
                        else {
                            displaystyle = 'none'
                        }
                        return <div className='contentHolder' key={orderkey} style={{ 'display': displaystyle}}>
                            <div className='row'>
                                <div className='col-sm-12'><h4>{this.state.order[orderkey]['sectionname']}</h4></div>                                                                                                
                            </div>
                            <div className='row'>
                                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }} className="">
                                    <Constant.NPDTabs
                                        value={this.state.value[orderkey] || 0}
                                        onChange={handleChangeTabs(orderkey)}
                                        //onChange={handleChangeTabs}
                                        aria-label="simple tabs example"
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        {Object.keys(this.state.order[orderkey]['tabs']).map((tabkey, idx) => {
                                            return <Constant.NPDTab
                                                label={this.state.order[orderkey]['tabs'][tabkey]['tabname']}
                                                key={orderkey + '_' + tabkey}
                                                {...a11yProps(idx)}
                                                onClick={(key) => changeSelectedTab(tabkey)}
                                            />
                                        })}
                                    </Constant.NPDTabs>
                                </Constant.NPDAppbar>
                            </div>

                            {Object.keys(this.state.order[orderkey]['tabs']).map((tabkey, idx) => {
                                let currentvisual = (((this.state.order[orderkey] || {})['tabs'] || {})[tabkey] || {})['visual']
                                let image = null
                                {
                                    this.state.charts.forEach(chart => {
                                        if (currentvisual === chart.name) {
                                            image = chart.picture
                                        }
                                    })
                                }
                                return <TabPanel value={this.state.value[orderkey] || 0} index={idx} key={orderkey + '_' + tabkey + '_tabpanel'}>
                                    <div className="row" style={{ width: "100%", height: "100%" }}>
                                        <Tooltip
                                            title='Change Visualization for This Tab'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={(key, tab) => addvisual(orderkey, tabkey)}
                                            >
                                                <InsertChartRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title='Rename This Tab'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={(key, tab) => changeTabModal(orderkey, tabkey)}
                                            >
                                                <TextFieldsRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip
                                            title='Delete This Tab'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={(key, tab) => deletetab(orderkey, tabkey)}
                                            >
                                                <DeleteRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip
                                            title='Move This Tab Left'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={(key, tab) => movetableft(orderkey, tabkey)}
                                            >
                                                <ArrowBackRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip
                                            title='Move This Tab Right'
                                            placement='top-start'
                                        >
                                            <IconButton
                                                onClick={(key, tab) => movetabright(orderkey, tabkey)}
                                            >
                                                <ArrowForwardRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                            </IconButton>
                                        </Tooltip>
                                        
                                    </div>
                                   
                                    {image ?
                                        <div>
                                            <img src={image} height='75%' width='75%' align='center' style={{ padding: '0px', marginTop: '0px' }} alt="ChartImage" />
                                        </div>
                                        :
                                        < div >
                                            <p>No Visual Selected!</p>
                                        </div>
                                    }
                                </TabPanel>
                            })}

                        </div>
                    })
                    }
                </div>

                
            </Layout>
        )
    }
}