import * as Constant from '../../../constants'

export default async function definePriceBands(report_id = undefined, store_group = undefined) {
    // Do we have user-defined price bands in the user's settings?
    let dataset_id = this.state.dataset_data.dataset_id
    var pricebandssettings_data, user_dataset_settings_data

    // Check if PriceBands is in the list of settings....
    let user_dataset_settings_response = await fetch(Constant.baseapiurl + 'Datasets/Settings/User/' + dataset_id)
    if (user_dataset_settings_response.status === 200) {
        user_dataset_settings_data = await user_dataset_settings_response.json()
    } else {
        user_dataset_settings_data = []
    }

    var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)
    if (usersettings_list.includes('PriceBands')) {
        let pricebandssettings_response = await fetch(Constant.baseapiurl + 'Datasets/Settings/User/' + dataset_id + '/PriceBands')
        if (pricebandssettings_response.status === 200) {
            pricebandssettings_data = await pricebandssettings_response.json()
        }
    }

    let currencysymbol = this.state.currencysymbol

    // If the user has price bands defined for this dataset, use those
    if (pricebandssettings_data) {
        var price_band_options = []
        let settings_value = pricebandssettings_data.setting_value
        if (settings_value.type === 'rulebased') {
            let bandsize = settings_value.bandsize
            let numbands = settings_value.numbands
            //create the bands...
            let band_start = 0
            let band_end = bandsize
            for (var band = 0; band < numbands; band++) {
                let band_start_label = band_start + 1
                if (band === 0) {
                    let price_band_option_label = 'Under ' + currencysymbol + band_end
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                } else if (band !== (numbands - 1)) {
                    let price_band_option_label = currencysymbol + band_start_label + ' to ' + currencysymbol + band_end
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                } else if (band === (numbands - 1)) {
                    let price_band_option_label = currencysymbol + band_start_label + '+'
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, null] })  /*arbitrary price to max out the band...*/
                }

                band_start += bandsize
                band_end += bandsize
            }
        } else if (settings_value.type === 'custom') {
            price_band_options = settings_value.bands
        }

        this.setState({ priceBandOptions: price_band_options })
        return true
    }
    // Get the min, max and asp of the dataset
    if (report_id !== undefined) {
        var min_max_avg_asp_url = Constant.baseapiurl + "ReportData/MinMaxAvgASP/" + report_id + this.state.extraparam
    } else {
        var min_max_avg_asp_url = Constant.baseapiurl + "Datasets/" + dataset_id + '/MinMaxAvgASP?store_group=' + store_group  + this.state.extraparam_multi
    }

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': min_max_avg_asp_url, 'method': 'GET' }
    ], 'DefinePriceBands.js definePriceBands()', 'Price Band Definition')
    if (my_results['ErrorCount'] == 0) {
        let min_max_asp_info = my_results['ReturnList'][0]
        let asp_max = Math.round(min_max_asp_info['aspmax'])
        let band_size_raw = min_max_asp_info['aspavg']

        var price_band_options = []

        // Calculate Price Bands...
        let max_num_bands = 7
        var band_size = 0
        if (band_size_raw < 5) {
            band_size = 1
        } else if (band_size_raw >= 5 && band_size_raw < 10) {
            band_size = 5
        } else if (band_size_raw >= 10 && band_size_raw < 25) {
            band_size = 10
        } else if (band_size_raw >= 25 && band_size_raw < 100) {
            band_size = 25
        } else if (band_size_raw >= 100 && band_size_raw < 250) {
            band_size = 50
        } else if (band_size_raw >= 250 && band_size_raw < 500) {
            band_size = 100
        } else if (band_size_raw >= 500 && band_size_raw < 1000) {
            band_size = 250
        } else {
            band_size = 500
        }

        // If Notebooks, Notebooks and Tablets, or Monitors, set band to 300.
        if (['Notebook Computers', 'Monitors', 'Notebooks and Tablets'].includes(this.state.dataset_data.description)) {
            band_size = 300
            max_num_bands = 6
        }

        let band_start = 0
        let band_end = band_size

        for (var band = 0; band < max_num_bands; band++) {
            if (band_start < asp_max) {
                let band_start_label = band_start + 1
                if (band === 0) {
                    let price_band_option_label = 'Under ' + currencysymbol + band_end
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                } else if ((band_end < asp_max) && band !== (max_num_bands - 1)) {
                    let price_band_option_label = currencysymbol + band_start_label + ' to ' + currencysymbol + band_end
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                } else if ((band_end < asp_max) && band === (max_num_bands - 1)) {
                    let price_band_option_label = currencysymbol + band_start_label + '+'
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, null] })
                } else {
                    let price_band_option_label = currencysymbol + band_start_label + '+'
                    price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, null] })
                }

                band_start += band_size
                band_end += band_size
            }
        }

        if (['Notebook Computers', 'Monitors', 'Notebooks and Tablets'].includes(this.state.dataset_data.description)) {
            price_band_options = [
                { 'label': '$300 and Under', 'value': [0, 300] }
                , { 'label': '$301 to $500', 'value': [300, 500] }
                , { 'label': '$501 to $700', 'value': [500, 700] }
                , { 'label': '$701 to $900', 'value': [700, 900] }
                , { 'label': '$901 to $1100', 'value': [900, 1100] }
                , { 'label': '$1101 to $1500', 'value': [1100, 1500] }
                , { 'label': '$1501 and Above', 'value': [1500, null] }
            ]
        }

        if (['Coffee makers'].includes(this.state.dataset_data.description)) {
            price_band_options = [
                { 'label': '$10 and Under', 'value': [0, 10] }
                , { 'label': '$11 to $20', 'value': [10, 20] }
                , { 'label': '$21 to $50', 'value': [20, 50] }
                , { 'label': '$51 to $100', 'value': [50, 100] }
                , { 'label': '$101 to $250', 'value': [100, 250] }
                , { 'label': '$251 to $500', 'value': [250, 500] }
                , { 'label': '$501 and Above', 'value': [500, null] }
            ]
        }

        // set State
        this.setState({
            min_max_asp_info,
            priceBandOptions: price_band_options
        })
        return true
    }

    return false
}
