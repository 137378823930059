import React, { Component } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import { Layout } from '../Layout';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import * as Constant from '../constants'

export class PNPDiscountBandDefinition extends Component {
    static displayName = PNPDiscountBandDefinition.name;

    constructor(props) {
        super(props);
        this.useDataset = this.useDataset.bind(this)
        this.openDialog_LeaveReport = this.openDialog_LeaveReport.bind(this)
        this.LeaveForReport = this.LeaveForReport.bind(this)
        this.state = {
            showBackground: true,
            custombands: [
                { id: 0, 'name': "No Discount", 'start': 0, 'end': 0.05, errors: {} },
                { id: 1, 'name': "5-19%", 'start': 0.05, 'end': 0.2, errors: {} },
                { id: 2, 'name': "20-29%", 'start': 0.2, 'end': 0.3, errors: {} },
                { id: 3, 'name': "30-49%", 'start': 0.3, 'end': 0.5, errors: {} },
                { id: 4, 'name': "50+", 'start': 0.5, 'end': 1, errors: {} }
            ],

        }
    }

    async componentDidMount() {
        console.log('Getting Datasets')
        const datasetsres = await fetch(Constant.baseapiurl + "Datasets/DatasetDetails")
        var datasetsdata = []
        if (datasetsres.status === 200) {
            datasetsdata = await datasetsres.json()
        }
        else {
            datasetsdata = []
        }
        //set options
        var dataset_lookup = {}
        datasetsdata.forEach(dataset => { dataset_lookup[dataset.dataset_id] = dataset.description })
        let datasetoptions = datasetsdata.map(dataset => { return { 'label': dataset.description, 'value': dataset.dataset_id } })
        this.setState({ datasetoptions, datasetsdata })
        //Parse the url, and see if we have a dataset_id and report_id
        var url = window.location.href;
        var temp = url.split("?");
        console.log(temp)
        temp = temp.slice(1)
        //var temp = url.split("reportid=");
        console.log(temp)
        var datasetid_val, reportid_val, env_val
        temp.forEach(parameter => {
            let vals = parameter.split("=")
            let variable_name = vals[0]
            let variable_val = vals[1]
            if (variable_name === 'datasetid') {
                datasetid_val = variable_val
            }
            else if (variable_name === 'reportid') {
                reportid_val = variable_val
            }
            else if (variable_name === 'env') {
                env_val = variable_val
            }
        })

        if (reportid_val && datasetid_val) {
            this.setState({ reportid: reportid_val })
            this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
        }
        else if (datasetid_val) {
            this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
        }

        if (env_val) {
            this.setState({ extra_param: '?env=staging' })
        }
        else {
            this.setState({ extra_param: '' })
        }

        //Look and See if there are rules defined...

        var discountbandssettings_data
        let discountbandssettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetid_val + '/DiscountBands')
        if (discountbandssettings_response.status === 200) {
            discountbandssettings_data = await discountbandssettings_response.json()
        }


        if (discountbandssettings_data) {
            console.log('Use Discount Bands')
            console.log(discountbandssettings_data)
            let settings_value = discountbandssettings_data.setting_value

            let bands = settings_value.bands
            let custombands = bands.map((band, idx) => {
                var start, end
                start = band.value[0]
                if (band.value[1] === 10000000) {
                    end = ""
                }
                else {
                    end = band.value[1]
                }
                return {
                    'id': idx
                    , 'name': band.label
                    , 'start': start
                    , 'end': end
                    , 'errors': {}
                }
            })

            this.setState({ custombands, showBackground: false })
            
        }

        else {
            this.setState({ showBackground: false })
        }
    }

    async useDataset(selectedOptions) {
        console.log(selectedOptions)
        this.setState({ selecteddataset: selectedOptions })
        //Look up Dataset Info...
        let dataset_id = selectedOptions.value

        let dataset_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id)
        if (dataset_response.status === 200) {
            var dataset_data = await dataset_response.json()
            this.setState({ dataset_data })
        }
        else {
            dataset_data = { attributes: [] }
            this.setState({ errorAlert_report: true })
        }
    }

    openDialog_LeaveReport() {
        this.setState({ dialogopen_LeaveReport: true, })
    }

    LeaveForReport() {
        var url
        if (this.state.reportid) {
            url = Constant.baseurl + 'PNP/Report/' + this.state.reportid + this.state.extra_param
        }

        else {
            url = Constant.baseurl + 'PNP/Home'
        }

        window.location.href = url
    }

    render() {
        console.log(this.state)

        const handleChangeCustomBand = (id, type) => {


            let custombands = this.state.custombands
            if (type === 'name') {
                let input = document.getElementById("bandname_" + id);
                let value = input.value
                custombands[id].name = value
            }

            else if (type === 'start') {
                let input = document.getElementById("bandstart_" + id);
                let value = input.value
                custombands[id].start = value / 100
            }

            else if (type === 'end') {
                let input = document.getElementById("bandend_" + id);
                let value = input.value
                custombands[id].end = value / 100
            }

            this.setState({ custombands })
        }

        const applyCustomBands = async () => {
            let custombands = this.state.custombands
            let numbands = custombands.length
            console.log(custombands)
            console.log(numbands)
            let numerrors = 0
            let errorstate = false
            let errormessages = {}
            let numwarnings = 0
            let warningstate = false
            let warningmessages = {}

            //Error Check #1.  
            //There Should be at least 2 price bands defined

            if (numbands < 2) {
                numerrors += 1
                errorstate = true
                errormessages['minimumbands'] = "There must be at least 2 price bands defined.  Please add the necessary amount of bands."
            }
            if (numbands > 0) {
                //Error Check #2
                //Unique Names of Bands
                let bandnames = custombands.map(band => band.name)
                let uniquebandnames = Array.from(new Set(bandnames))

                //Create a Map of the Names
                let namemap = {}
                custombands.forEach((band, idx) => {
                    namemap[band.name] = namemap[band.name] || []
                    namemap[band.name].push(idx)
                })

                console.log(namemap)
                let dupednames = []
                Object.keys(namemap).forEach(key => {
                    if (namemap[key].length > 1) {
                        dupednames.push(key)
                    }
                })
                console.log(dupednames)
                console.log(uniquebandnames)
                if (uniquebandnames.length !== numbands) {
                    numerrors += 1
                    errorstate = true
                    errormessages['uniquenames'] = "Price Bands should have unique names."
                    //Add a check on each name...
                    custombands.forEach(band => {
                        if (dupednames.includes(band.name)) {
                            band.errors['name'] = {}
                            band.errors['name']['status'] = true
                            band.errors['name']['errortext'] = 'Please Enter a Unique Name'
                        }
                        else {
                            band.errors['name'] = {}
                        }
                    })
                }

                else {
                    custombands.forEach(band => {
                        band.errors['name'] = {}
                    })
                }

                //Error Check #5
                //Band Start Must Be Populated
                custombands.forEach((band, idx) => {
                    let currstart = parseFloat(band.start)
                    console.log(currstart)
                    console.log(band)
                    if (!currstart && currstart !== 0) {
                        numerrors += 1
                        errorstate = true
                        errormessages['incorrectbandstart'] = "Price Band Start Must be Populated"
                        band.errors['start'] = {}
                        band.errors['start']['status'] = true
                        band.errors['start']['errortext'] = 'Band Start Must be populated'
                    }
                    else {
                        band.errors['start'] = {}
                    }
                })

                //Error Check #3 
                //First Bands Must Start at 0
                console.log(parseFloat(custombands[0].start))
                if (parseFloat(custombands[0].start) !== 0) {
                    numerrors += 1
                    errorstate = true
                    errormessages['incorrectstart'] = "The first price band must start at 0."
                    custombands[0].errors['start'] = {}
                    custombands[0].errors['start']['status'] = true
                    custombands[0].errors['start']['errortext'] = 'Band Must Start at 0'

                }

                else {
                    custombands[0].errors['start'] = {}
                }

                //Error Check #4
                //Band Must start .01 away from previous Band's end (Except First Band)
                custombands.forEach((band, idx) => {
                    if (idx >= 1) {
                        let prevend = parseFloat(custombands[idx - 1].end)
                        let currstart = parseFloat(band.start)
                        let diff = currstart - parseFloat(prevend)
                        if (diff.toFixed(2) > .00) {
                            console.log(idx)
                            console.log(diff)
                            numerrors += 1
                            errorstate = true
                            errormessages['incorrectbandstart'] = "Discount Bands, other than the first, must start at the previous band's ending value."
                            band.errors['start'] = {}
                            band.errors['start']['status'] = true
                            band.errors['start']['errortext'] = 'Band Must Start at previous band end.'
                        }
                        else if (diff.toFixed(2) < .00) {
                            numerrors += 1
                            console.log(idx)
                            console.log(diff)
                            errorstate = true
                            errormessages['incorrectbandstart'] = "Discount Bands, other than the first, must start at the previous band's ending value."
                            band.errors['start'] = {}
                            band.errors['start']['status'] = true
                            band.errors['start']['errortext'] = 'Band Must Start at previous band end.'
                        }
                        else if (band.start) {
                            band.errors['start'] = {}
                        }
                    }
                })


                //Error Check #6
                //Band End Can Not Be Blank, unless last band
                custombands.forEach((band, idx) => {
                    //let currstart = parseFloat(band.start)
                    let currend = parseFloat(band.end)
                    if (!currend && idx !== (numbands - 1)) {
                        numerrors += 1
                        errorstate = true
                        errormessages['incorrectbandend'] = "Price Band end must not be blank"
                        band.errors['end'] = {}
                        band.errors['end']['status'] = true
                        band.errors['end']['errortext'] = 'Band End Must be populated.'
                    }
                    else if (idx !== (numbands - 1)) {
                        band.errors['end'] = {}
                    }
                })

                //Error Check #7
                //Band End Can Not Be Less Than Band Start
                custombands.forEach((band, idx) => {
                    let currstart = parseFloat(band.start)
                    let currend = parseFloat(band.end)
                    if (currend - currstart <= 0) {
                        numerrors += 1
                        errorstate = true
                        errormessages['incorrectbandend'] = "Price Band end must be greater than Price Band Start"
                        band.errors['end'] = {}
                        band.errors['end']['status'] = true
                        band.errors['end']['errortext'] = 'Band End Must be Greater than Band Start.'
                    }
                    else if (currend) {
                        band.errors['end'] = {}
                    }
                })

                //Warning Check #1
                //Only Last Band Can be blank. (Not Needed, and if populated throw a warning)
                if (custombands[numbands - 1].end !== "") {
                    numwarnings += 1
                    warningstate = true
                    warningmessages['lastband'] = "Last Price Band will include prices greater than " + custombands[numbands - 1].end + " when creating visuals in the report output."
                }
            }


            this.setState({
                numerrors,
                errorstate,
                errormessages,
                numwarnings,
                warningstate,
                warningmessages
            })

            //if No Errors
            if (numerrors === 0) {
                console.log(custombands)
                let bands = custombands.map(band => {
                    let label = band.name
                    let start = parseFloat(band.start)
                    var end
                    if (band.end !== "") {
                        end = parseFloat(band.end)
                    }
                    else {
                        end = null
                    }
                    return { 'label': label, 'value': [start, end] }
                })

                const setting = {
                    bands
                };

                let datasetidnum = this.state.selecteddataset.value
                let url = Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/DiscountBands'
                console.log(JSON.stringify(setting));
                let settings_response = await fetch(url,
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(setting)
                    })

                var settings_obj
                if (settings_response.status === 200) {
                    settings_obj = await settings_response.json()
                    //if report id, then send back to the report page...
                    if (this.state.reportid) {
                        let url = Constant.baseurl + 'PNP/Report/' + this.state.reportid + this.state.extra_param
                        window.location.href = url
                    }
                }
                else {
                    settings_obj = {}
                    alert('There was an Error Setting the Price Bands')
                }

            }

        }

        const handleCloseDialog_LeaveReport = () => {
            this.setState({ dialogopen_LeaveReport: false })
        }

        return (
            <Layout title="Price Band Definition" >

                <Dialog
                    open={this.state.dialogopen_LeaveReport}
                    onClose={handleCloseDialog_LeaveReport}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">Go Back to Report?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to navigate back to the report.  Would you like to save your changes, or navigate back without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog_LeaveReport} className="btn btn-cancel" autoFocus>
                            Cancel
                        </button>

                        <button onClick={applyCustomBands} className="btn btn-primary" autoFocus>
                            Save Changes and Go Back
                        </button>

                        <button onClick={() => this.LeaveForReport()} className="btn btn-secondarynpd" autoFocus>
                            Go Back Without Saving
                        </button>
                    </DialogActions>
                </Dialog>

                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    <div class="custom-loader"></div>
                    {/*<CircularProgress color="inherit" />*/}
                </Constant.NPDBackdrop>

                <div name='banner' className='banner'>
                    <h1white align='center'>Price and Discount Trends Price Band Definition</h1white><br/>
                    <h2white align='center'>Use this page to set how Price Bands are defined per Dataset</h2white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h4>Select a Dataset</h4>
                            <div className='col-sm-6'>
                                <Select
                                    inputId="datasetdropdown"
                                    options={this.state.datasetoptions}
                                    placeholder={'Select a Datasets'}
                                    onChange={(options) => this.useDataset(options)}
                                    value={this.state.selecteddataset}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <p>
                            For Each Discount Band please enter a name, starting value, and ending value.
                            Note, that for each band, except the first, the starting value must equal the ending value of the previous band.
                        </p>
                    </div>

                    <div className='row'>

                        <div className='col-sm-12'>
                            <h4>Discount Band Entry</h4>
                            <div className='col-sm-6'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Discount Band Name</TableCell>
                                            <TableCell>Discount Band Start</TableCell>
                                            <TableCell>Discount Band End</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.custombands.map(band => {
                                            return <TableRow key={band.id} >
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={(id, name) => handleChangeCustomBand(band.id, 'name')}
                                                        id={"bandname_" + band.id}
                                                        value={band.name}
                                                        error={(band.errors['name'] || {}).status}
                                                        helperText={(band.errors['name'] || {}).errortext}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={(id, name) => handleChangeCustomBand(band.id, 'start')}
                                                        id={"bandstart_" + band.id}
                                                        value={band.start * 100}
                                                        error={(band.errors['start'] || {}).status}
                                                        helperText={(band.errors['start'] || {}).errortext}
                                                        InputProps={{
                                                            endAdornment: <Constant.NPDInputAdornment position="end" style={{ fontSize: '1rem' }}>%</Constant.NPDInputAdornment>,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={(id, name) => handleChangeCustomBand(band.id, 'end')}
                                                        id={"bandend_" + band.id}
                                                        value={band.end * 100}
                                                        error={(band.errors['end'] || {}).status}
                                                        helperText={(band.errors['end'] || {}).errortext}
                                                        InputProps={{
                                                            endAdornment: <Constant.NPDInputAdornment position="end" style={{ fontSize: '1rem' }}>%</Constant.NPDInputAdornment>,
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>

                                <Tooltip
                                    title='Save Definition'
                                    placement='top-start'
                                >
                                    <IconButton
                                        onClick={applyCustomBands}
                                    >
                                        <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title='Go Back to Report'
                                    placement='top-start'
                                >
                                    <IconButton
                                        onClick={() => this.openDialog_LeaveReport()}
                                    >
                                        <SettingsBackupRestoreRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                    </IconButton>
                                </Tooltip>

                            </div>
                        </div>


                    </div>
                    {this.state.numerrors > 0 ?
                        <div className='row'>
                            <div className='col-sm-12'>
                                There are Errors. Please Fix the Highlighted Cells.
                                <br />
                                <br />
                                Error List:
                                <ul>
                                    {Object.values(this.state.errormessages || {}).map(message => {
                                        return <li>{message}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                        :
                        <div />
                    }
                </div>


            </Layout>
        )
    }
}