import removeItemOnce from '../Utilities/RemoveItemOnce'
//import CreateChartMetadata from '../Utilities/CreateChartMetadata'
import * as Constant from '../../../constants'

export default async function updateReportViews(viewtype) {
    console.log('Running Update for Views')    
    //let exclude_array = this.state.exclude_array
    let exclude_array = this.state.exclude_array.slice()

    let priceBandOptions = this.state.priceBandOptions

    // combine filters...
    let filter_object = this.state.filter_object
    let filter_view_selected = this.state.filter_view_selected
    let final_filters = {}

    // Always take dates, asp range, geo from filters
    final_filters['asp_range'] = filter_object['asp_range']
    final_filters['enddate'] = filter_object['enddate']
    final_filters['startdate'] = filter_object['startdate']
    final_filters['numweeks'] = filter_object['numweeks']
    final_filters['numweeks_raw'] = filter_object['numweeks_raw']
    final_filters['geo'] = filter_object['geo']
    final_filters['attributes'] = {}

    // take the value from filter_view_selected, unless it's All.  Then, take value from this.state.filters.

    // brand
    if (filter_view_selected['brand'][0].value === 'All') {
        final_filters['brands'] = filter_object['brands']
    } else {
        final_filters['brands'] = filter_view_selected['brand'].map(option => option.value)//[filter_view_selected['brand'][0].value]
        exclude_array[0] = false
    }

    // item
    if (filter_view_selected['item'][0].value === 'All') {
        final_filters['items'] = filter_object['items']
    } else {
        final_filters['items'] = filter_view_selected['item'].map(option => option.value)//[filter_view_selected['item'][0].value]
        exclude_array[1] = false
    }

    // channel
    if (filter_view_selected['channel'][0].value === 'All') {
        final_filters['channels'] = filter_object['channels']
    } else {
        final_filters['channels'] = filter_view_selected['channel'].map(option => parseInt(option.value))//[parseInt(filter_view_selected['channel'][0].value)]
    }

    // outlet
    if (filter_view_selected['outlet'][0].value === 'All') {
        final_filters['outlets'] = filter_object['outlets']
    } else {
        final_filters['outlets'] = filter_view_selected['outlet'].map(option => option.value) //[filter_view_selected['outlet'][0].value]
    }

    // priceband
    if (filter_view_selected['priceband'][0].value === 'All') {
        final_filters['pricebands'] = filter_object['pricebands']
    } else {
        final_filters['pricebands'] = []
        filter_view_selected['priceband'].forEach(option => {
            this.state.priceBandOptions.forEach(pboption => {
                console.log(option)
                console.log(pboption)
                if (pboption.label === option.label) {
                    console.log('Match!')
                    final_filters['pricebands'].push(pboption)
                }
            })
        })
        console.log(final_filters['pricebands'])
    }

    // segment1
    if (filter_view_selected['segment1'][0].value === 'All') {
        final_filters['segment1'] = filter_object['segment1']
    } else {
        final_filters['segment1'] = filter_view_selected['segment1'].map(option => option.value) //[filter_view_selected['segment1'][0].value]
    }

    // segment2
    if (filter_view_selected['segment2'][0].value === 'All') {
        final_filters['segment2'] = filter_object['segment2']
    } else {
        final_filters['segment2'] = filter_view_selected['segment2'].map(option => option.value) //[filter_view_selected['segment2'][0].value]
    }

    // segment3
    if (filter_view_selected['segment3'][0].value === 'All') {
        final_filters['segment3'] = filter_object['segment3']
    } else {
        final_filters['segment3'] = filter_view_selected['segment3'].map(option => option.value) //[filter_view_selected['segment3'][0].value]
    }

    // segment4
    if (filter_view_selected['segment4'][0].value === 'All') {
        final_filters['segment4'] = filter_object['segment4']
    } else {
        final_filters['segment4'] = filter_view_selected['segment4'].map(option => option.value) //[filter_view_selected['segment4'][0].value]
    }

    // attributes
    Object.keys(this.state.customAttrGroups).forEach(attribute => {
        if (filter_view_selected[attribute][0].value === 'All') {
            let filter_obj_attr = filter_object['attributes'][attribute]
            if (filter_obj_attr !== null && filter_obj_attr !== undefined) {
                final_filters['attributes'][attribute] = filter_obj_attr
            } else {
                console.log('Skipping null or undefined "All" filter attribute ' + attribute)
            }
        } else {
            let values = filter_view_selected[attribute].map(option => option.value)
            final_filters['attributes'][attribute] = values
        }
    })

    console.log(final_filters)

    // Clean Up the Filters

    let filters = {}

    //let apifilterattrs = Object.keys(final_filters['attributes']).map(attr => {
    //    return  removeItemOnce(final_filters['attributes'][attr]).filter(Boolean)
    //})

    filters['brands'] = removeItemOnce(final_filters['brands'], 'All').filter(Boolean)
    filters['exclude_brands'] = exclude_array[0]
    filters['items'] = removeItemOnce(final_filters['items'], 'All').filter(Boolean)
    filters['exclude_items'] = exclude_array[1]
    filters['week_aggregation'] = 1 //filters_raw['numweeks']
    filters['asp_range'] = final_filters['asp_range']
    filters['startdate'] = final_filters.startdate
    filters['enddate'] = final_filters.enddate
    filters['date_range'] = [final_filters.startdate, final_filters.enddate]
    //filters['date_range_curr'] = [final_filters.startdate, final_filters.enddate] //Might Need to Calculate
    //filters['date_range_prior'] = [final_filters.startdate, final_filters.enddate] //Might Need to Calculate
    filters['outlets'] = removeItemOnce(final_filters['outlets'], 'All').filter(Boolean)
    filters['segment1'] = removeItemOnce(final_filters['segment1'], 'All').filter(Boolean)
    filters['segment2'] = removeItemOnce(final_filters['segment2'], 'All').filter(Boolean)
    filters['segment3'] = removeItemOnce(final_filters['segment3'], 'All').filter(Boolean)
    filters['segment4'] = removeItemOnce(final_filters['segment4'], 'All').filter(Boolean)
    filters['attributes'] = final_filters['attributes']
    filters['pricebands'] = removeItemOnce(final_filters['pricebands'], 'All').filter(Boolean)
    filters['price_bands_defs'] = priceBandOptions
    filters['channels'] = removeItemOnce(final_filters['channels'], 'All').filter(Boolean)

    // Update the Appropriate API calls....

    console.log('Running Eval Rows')
    await this.evaluateRows_newAPIs_consolidated(filters, this.state.exclude_array, exclude_array, 'update');
    console.log('Eval Rows Complete')

    // SetState
    console.log('Setting State for Options')
    console.log(this.state.filter_view_options)
    this.setState({
        isLoaded: true,
        final_filters: filters,
        render_update: true,
    })
}
