import React, { Component } from 'react';
import { UnitsDollarSwitch } from '../../../UIElements/Switches/UnitsDollarSwitch'
import { MetricLevelOptionsBaseInc } from '../../../UIElements/Dropdowns/MetricLevelOptionsBaseInc'
//import { FilterBarReportViews } from '../../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../../UIElements/Filters/FilterBarReportViewsFunctional';
import { RankOptions } from '../../../UIElements/Dropdowns/RankOptions'
import { PriceBandDefIcon } from '../../../UIElements/IconButtons/PriceBandDefIcon'
import { DownloadAggregateDataAll } from '../../../UIElements/IconButtons/DownloadAggregateDataAll'
import FairShare from '../../../../Graphs/FairShare'

export class Fair_Share extends Component {
    constructor(props) {
        super(props);
        console.log('Fair_Share.js props',this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }
        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        //Create Data...
        function filterArray(array, start, end) {
            let new_array = array.map((value, idx) => {
                if (idx >= start && idx < end) {
                    return value
                }
                else {
                    return null
                }
            }).filter(x => x !== null)
            return new_array
            console.log('new_array', new_array)
        }

        //create series list....
        //Use Aggregated Data
        var categories, shareType, SubBarData, UnSubBarData, IncData, PromoData, weekly_data, weekly_data_category, weekly_data_selected, propsCalcType
        var categories_dollars = this.props.state.agg_categories_dollars || []
        var categories_units = this.props.state.agg_categories_units || []
        var RankStart = this.props.state.RankStart || 0
        var RankEnd = this.props.state.RankEnd || 10
        if (this.props.state.indexSwitchCheckDollars) {
            categories = filterArray(categories_dollars, RankStart, RankEnd)
            shareType = this.props.state.currencyType
            SubBarData = filterArray(categories_dollars.map(key => (this.props.state.aggregatedData[key] || {})['subsidizedbasedollars'] || 0), RankStart, RankEnd)
            UnSubBarData = filterArray(categories_dollars.map(key => (this.props.state.aggregatedData[key] || {})['unsubsidizedbasedollars'] || 0), RankStart, RankEnd)
            IncData = filterArray(categories_dollars.map(key => (this.props.state.aggregatedData[key] || {})['incrementaldollars'] || 0), RankStart, RankEnd)
            PromoData = filterArray(categories_dollars.map(key => (this.props.state.aggregatedData[key] || {})['promoteddollars'] || 0), RankStart, RankEnd)
            propsCalcType = 'currency'
        }
        else {
            categories = filterArray(categories_units, RankStart, RankEnd)
            shareType = this.props.state.unitsvar
            SubBarData = filterArray(categories_units.map(key => (this.props.state.aggregatedData[key] || {})['subsidizedbaseunits'] || 0), RankStart, RankEnd)
            UnSubBarData = filterArray(categories_units.map(key => (this.props.state.aggregatedData[key] || {})['unsubsidizedbaseunits'] || 0), RankStart, RankEnd)
            IncData = filterArray(categories_units.map(key => (this.props.state.aggregatedData[key] || {})['incrementalunits'] || 0), RankStart, RankEnd)
            PromoData = filterArray(categories_units.map(key => (this.props.state.aggregatedData[key] || {})['promotedunits'] || 0), RankStart, RankEnd)
            propsCalcType = 'units'
        }

        console.log('categories', categories)
        console.log('shareType', shareType)
        console.log('SubBarData', SubBarData)
        console.log('UnSubBarData', UnSubBarData)
        console.log('IncData', IncData)
        console.log('PromoData', PromoData)


        weekly_data = this.props.state.weekly_data_new
        weekly_data_category = { 'Total Category': this.props.state.weekly_data_totalcat_new }
        weekly_data_selected = { 'Total Category': this.props.state.weekly_data_totalsel_new }
        console.log('weekly_data_category', this.props.state.weekly_data_totalcat_new )

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div className="col-sm-4" >
                        <MetricLevelOptionsBaseInc
                            state={this.props.state}
                            changeSelectedLevel_BaseInc={this.props.changeSelectedLevel_BaseInc}
                        />
                    </div>
                    <div className="col-sm-2 row" >
                        <UnitsDollarSwitch
                            state={this.props.state}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </div>
                    {(this.props.state.RankOptions || []).length > 1 ?
                        <div align='left' className="col-sm-3" >
                            <RankOptions
                                state={this.props.state}
                                changeRank={this.props.changeRank}
                            />
                        </div>
                        :
                        <div />
                    }

                    {this.props.state.level_baseinc === 'Price Band' ?
                        <div align='left' className="col-sm-3" >
                            <PriceBandDefIcon
                                state={this.props.state}
                            />
                        </div>
                        :
                        <div />
                    }
                </div>
                <div className="row">
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ height: '100%', width: "100%" }} className="col-sm-12">
                        <div className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                            <FairShare
                                attr_caption_text={this.props.state.attr_caption_text}
                                chart_title_info={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.selectedBrand === 'Total Selection' ?
                                            this.props.state.chart_title_info_nobrands + ' Brand(s) Selected: ' + this.props.state.brandsstring
                                            :
                                            this.props.state.chart_title_info_nobrands + ' Brand Selected: ' + this.props.state.selectedBrand
                                        :
                                        this.props.state.chart_title_info
                                }
                                shareType={shareType}
                                distinctdates_forcalc={this.props.state.distinctdates_forcalc}
                                datemapping_new_withend={this.props.datemapping_new_withend}
                                distinctdates={this.props.state.distinctdates}
                                weekly_data={weekly_data}
                                weekly_data_selected={weekly_data_selected}
                                weekly_data_category={weekly_data_category}
                                categories={categories}
                                level={this.props.state.level_baseinc}
                                showDataTable={this.props.state.showDataTable}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                propsCalcType={propsCalcType}
                                SubData={SubBarData}
                                UnSubData={UnSubBarData}
                                IncData={IncData}
                                PromoData={PromoData}
                                chartmetadata={
                                    (this.props.state.level_baseinc === 'Price Band' || this.props.state.level_baseinc === 'Segment 1' || this.props.state.level_baseinc === 'Segment 2') ?
                                        this.props.state.chartmetadata_singlebrand
                                        :
                                        this.props.state.chartmetadata
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
