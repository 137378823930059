export default function setDefaultOrder() {

    console.log(this.state.all_outlets)
    var savedOrder
    if (this.state.all_outlets.length > 1) {
        savedOrder = this.state.default_alr_order
    }
    else {
        savedOrder = this.state.default_tmm_order
    }

    let tabvalues = savedOrder.map(section => { return 0 })
    this.setState({ order: savedOrder, tabvalues })
    
    this.openChangeVizzes()
}