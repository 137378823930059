import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'

//const reducer = (accumulator, currentValue) => accumulator + currentValue;
const WeeklyStackedBarMultipleSeries = props => {
    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'

    var aggtype = ""

    if ((props.aggperiod || "") === 'Monthly') {
        aggtype = 'Monthly'
    }
    else {
        aggtype = 'Weekly'
    }


    //var xDateFormatString = ""
    var weeklytimeperiods = ""
    var axisFormatString = ""
    var axisText = ""
    //var tickIntervalNum = 24 * 3600 * 1000 * 7 * props.weekagg
    var tickpositionsarray = props.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))))
    var stepNum = 1
    var paddingNum = 5
    var fontSizeString = '11px'
    if (tickpositionsarray.length > 26 && props.height === 2) {
        stepNum = 2
        paddingNum = 10
        fontSizeString = '9px'
    }

    if (aggtype === 'Monthly') {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Month Period Ending: %b %Y'
            weeklytimeperiods = props.weekagg + " Month Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Month Period'
        }

        else {
            //xDateFormatString = '%b %Y'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Month'
        }

    }
    else {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Week Period Ending: %Y-%m-%d'
            weeklytimeperiods = props.weekagg + " Week Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Week Period'
        }

        else {
            //xDateFormatString = 'Week Ending: %Y-%m-%d'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Week Ending'
        }
    }
    //console.log(props.yAxisTitle)
    //console.log('Incremental ' + props.currencyType + ' Share')
    //console.log(props.yAxisTitle === 'Incremental ' + props.currencyType + ' Share')
    //console.log(props.yAxisTitle == 'Incremental ' + props.currencyType + ' Share')
    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            zoomType: 'xy',
                            height: (9 / 16 * (100*(props.height || 1))) + '%' //16:9 ratio
                        },
                        metadata: props.metadata || {},
                        caption: {
                            text: props.attr_caption_text
                        },
                        title: {
                            text: props.chart_title + weeklytimeperiods + props.chart_title_info 
                        },
                        series: props.seriesList,

                        xAxis: {
                            title: {
                                text: axisText
                            },
                            type: 'datetime',
                            dateTimeLabelFormats: {
                                month: axisFormatString
                            },
                            tickPositions: tickpositionsarray,
                            labels: {
                                formatter: function () {
                                    let dt = new Date()
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    let date = new Date(this.value + offset)
                                    const options = { year: 'numeric', month: 'short' };
                                    const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                    if ((props.aggperiod || "") === 'Monthly') {
                                        return date.toLocaleDateString(undefined, options)
                                    }
                                    else {
                                        return date.toLocaleDateString(undefined, options2) //toISOString().substring(0, 10)
                                    }
                                },
                                rotation: 270,
                                step: stepNum,
                                padding: paddingNum,
                                style: {
                                    fontSize: fontSizeString
                                }
                            },
                            events: {
                                setExtremes: function (e) {
                                    Highcharts.syncExtremes(e);
                                }
                            }
                        },

                        yAxis: [
                            {
                                title: {
                                    text: props.yAxisTitle
                                },
                                labels: {
                                    formatter: function () {
                                        if (props.yAxisType === 'percent') {
                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                        else if (props.yAxisType === 'currency') {
                                            return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                        else {
                                            return this.value.toLocaleString()
                                        }
                                    }
                                },
                                opposite: true,
                                reversedStacks: false
                            },
                            {
                                title: {
                                    text: props.yAxisTitle2
                                },
                                labels: {
                                    formatter: function () {
                                        if (props.yAxisType2 === 'percent') {
                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                        else if (props.yAxisType2 === 'currency') {
                                            return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                        else {
                                            return this.value.toLocaleString()
                                        }
                                    }
                                },
                                opposite: false,
                                reversedStacks: false
                            },
                        ],
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                            },
                           
                        },
                        tooltip: {
                            headerFormat: '',
                        },

                    }
                }
            />

            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g2">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                            {props.selectedBrands.map(brand => {
                                return <Constant.NPDTableCell key={"headercol_" + brand + "_g2"} style={{ textAlign: 'center' }}>{brand}</Constant.NPDTableCell>
                            })
                            }
                        </Constant.NPDTableRow>
                        {props.distinctdates.map(date => {
                            if (props.yAxisTitle === 'Total ' + props.unitsvar + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['totalunits'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['totalunits']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Promoted ' + props.unitsvar + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['promotedunits'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['promotedunits']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Non-Promoted ' + props.unitsvar + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['nonpromotedunits'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['nonpromotedunits']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Base ' + props.unitsvar + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['baseunits'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['baseunits']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Incremental ' + props.unitsvar + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['incrementalunits'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['incrementalunits']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Total ' + currencytype + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['totaldollars'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['totaldollars']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Promoted ' + currencytype + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['promoteddollars'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['promoteddollars']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Non-Promoted ' + currencytype + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['nonpromoteddollars'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['nonpromoteddollars']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Base ' + currencytype + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['basedollars'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'])[date] || {})['basedollars']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Incremental ' + currencytype + ' Share') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['incrementaldollars'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['incrementaldollars']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g2"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g2"} style={{ textAlign: 'center' }}>{
                                            ((((props.weekly_data[brand] || {})[date] || {})['totaldollars'] / ((props.weekly_data['Total Category'] || props.weekly_data['All Channels'] || {})[date] || {})['totaldollars']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default WeeklyStackedBarMultipleSeries;