import React, {  Fragment } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import * as Constant from './constants'

export class TableWithPagination extends React.Component {
    static displayName = TableWithPagination.name;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            data: props.data,
            columns: props.columns,
            length: props.length,
            page: 0,
            rowsPerPage: 5
        }
    }

    componentDidMount() {
        //console.log(props)
        console.log(this.state)
    }


    render() {

        const handleChangePage = (event, newPage) => {
            this.setState({ page: newPage })
        };

        const handleChangeRowsPerPage = (event) => {
            this.setState({ rowsPerPage: parseInt(event.target.value, 10) })
            this.setState({ page: 0 })
        };

        
        return (
            <Fragment>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow>
                            {this.state.columns.map(column => { return <Constant.NPDTableCell style={{ textAlign: 'left' }}>{column}</Constant.NPDTableCell> })}
                        </Constant.NPDTableRow>
                        {this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,idx) => {
                            return <Constant.NPDTableRow key={"datasetrow_" + idx}>
                                {row.map(col => { return <Constant.NPDTableCell style={{ textAlign: 'left' }}>{col}</Constant.NPDTableCell> })}
                            </Constant.NPDTableRow>

                        })}
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Fragment>
         )
    }
}