import React, { Component, Fragment } from 'react';
import { ALRMetricRadio } from '../../UIElements/Radios/ALRMetricRadio'
import { BrandChannelSwitch } from '../../UIElements/Switches/BrandChannelSwitch'
import { MultiBrandSelect } from '../../UIElements/Dropdowns/MultiBrandSelect'
import { MultiGeoSelect } from '../../UIElements/Dropdowns/MultiGeoSelect'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import WeeklyViewMultipleSeries from '../../../Graphs/WeeklyViewMultipleSeries'
import { reducer } from '../../Functions/Utilities/Reducer'
import * as Constant from '../../../constants';

export class ASPWeeklyALRvsROM extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        var currencycode = this.props.state.currencycode
        var aggtype = this.props.state.datasettimeagg

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div className="row" >
                    <div align='left' className="col-sm-8" >
                        <ALRMetricRadio
                            state={this.props.state}
                            handleChangeRadioRetailerPromoNonPromo={this.props.handleChangeRadioRetailerPromoNonPromo}
                        />
                    </div>
                    <div className="col-sm-4" align='left'>
                        <BrandChannelSwitch
                            state={this.props.state}
                            handleChange_channelviews={this.props.handleChange_channelviews}
                        />
                    </div>
                    {
                        this.props.state.channelviews ?
                            <div className="col-sm-12" align='left'>
                                <MultiGeoSelect
                                    state={this.props.state}
                                    changeSelectedChannelMulti={this.props.changeSelectedChannelMulti}
                                />
                            </div>
                            :
                            <div align='left' className="col-sm-12" >
                                <MultiBrandSelect
                                    state={this.props.state}
                                    changeSelectedBrandMulti={this.props.changeSelectedBrandMulti}
                                />
                            </div>
                    }
                </div>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        {this.props.state.channelviews ?
                            <Fragment>
                                {this.props.state.retailerbrandviewspromo ?
                                    <div className="row" style={{ width: "100%", height: "100%" }}>
                                        <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                            <WeeklyViewMultipleSeries
                                                chart_title={"Average Promoted Price - " + this.props.state.outlets[0] + " "}
                                                chart_title_info={this.props.state.chart_title_info_nochannels}
                                                seriesList={
                                                    this.props.state.selectedChannels.map((geo, idx) => {

                                                        //Check if data is greater than 0 for the selected outlet and geo....if so, return data, else return empty...
                                                        let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                            let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promotedunits']) || 0
                                                            if (isFinite(price)) {
                                                                return price
                                                            }
                                                            else {
                                                                return 0
                                                            }
                                                        }).reduce(reducer)

                                                        console.log("%s total prices are: %s", geo, totalprices);

                                                        if (totalprices > 0) {
                                                            return {
                                                                name: this.props.state.outlets[0] + ' ' + geo,
                                                                type: 'spline',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }

                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                parseFloat((((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promotedunits']))
                                                                ]),
                                                                zIndex: 1,
                                                                visible: true,
                                                                showInLegend: true,
                                                            }
                                                        }

                                                        else if (totalprices > 0) {
                                                            return {
                                                                name: this.props.state.outlets[0] + ' ' + geo,
                                                                type: 'spline',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), 1
                                                                    //parseFloat(((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promotedunits']))
                                                                ]),
                                                                zIndex: 1,
                                                                visible: true,
                                                                showInLegend: true,
                                                            }
                                                        }

                                                        else {
                                                            return {
                                                                name: this.props.state.outlets[0] + ' ' + geo,
                                                                type: 'spline',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                parseFloat((((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['promotedunits']))
                                                                ]),
                                                                zIndex: 1,
                                                                visible: false,
                                                                showInLegend: false
                                                            }
                                                        }
                                                    })
                                                }
                                                unitsvar={this.props.state.unitsvar}
                                                distinctdates={this.props.state.distinctdates}
                                                weekly_data={this.props.state.outlet_data_weekly_geo}
                                                selectedBrand={this.props.state.selectedChannel}
                                                showDataTable={this.props.state.showDataTable}
                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                weekagg={this.props.state.filter_object.numweeks}
                                                height={2}
                                                attr_caption_text={this.props.state.attr_caption_text}
                                                yAxisTitle='Promoted Price'
                                                yAxisTitle2='Promoted Price'
                                                yAxisType='currency'
                                                yAxisType2='currency'
                                                selectedBrands={this.props.state.selectedChannels}
                                                aggperiod={this.props.state.datasettimeagg}
                                                numTicks={5}
                                                yMin={0}
                                                yMax={this.props.state.yMax}
                                                currencycode={this.props.state.currencycode}
                                                currencytype={this.props.state.currencyType}
                                                outletcode={this.props.state.outlets[0]}
                                                metadata={this.props.state.chartmetadata}
                                            />
                                        </div>
                                        <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                            <WeeklyViewMultipleSeries
                                                chart_title={"Average Promoted Price - " + this.props.state.outlets[1]}
                                                chart_title_info={this.props.state.chart_title_info_nochannels}
                                                seriesList={
                                                    this.props.state.selectedChannels.map((geo, idx) => {
                                                        //Check if data is greater than 0 for the selected outlet and geo....if so, return data, else return empty...
                                                        let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                            let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promotedunits']) || 0
                                                            if (isFinite(price)) {
                                                                return price
                                                            }
                                                            else {
                                                                return 0
                                                            }
                                                        }).reduce(reducer)

                                                        console.log("%s total prices are: %s", geo, totalprices);

                                                        if (totalprices > 0) {
                                                            return {
                                                                name: this.props.state.outlets[1] + ' ' + geo,
                                                                type: 'spline',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                parseFloat(((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promotedunits']))
                                                                ]),
                                                                zIndex: 1,
                                                                visible: true,
                                                                showInLegend: true,
                                                            }
                                                        }

                                                        else if (totalprices > 0) {
                                                            return {
                                                                name: 'test', //this.props.state.outlets[1] + ' ' + geo,
                                                                type: 'spline',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                parseFloat(((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promotedunits']))
                                                                ]),
                                                                zIndex: 1,
                                                                visible: true,
                                                                showInLegend: true,
                                                            }
                                                        }

                                                        else {
                                                            return {
                                                                name: this.props.state.outlets[1] + ' ' + geo,
                                                                type: 'spline',
                                                                color: Constant.graph_colors[idx],
                                                                yAxis: 0,
                                                                tooltip: {
                                                                    pointFormatter: function () {
                                                                        let dt = new Date(this.x)
                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                        let date = new Date(this.x + offset)
                                                                        if (aggtype === 'Monthly') {
                                                                            return '<span>Month: '
                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                        else {
                                                                            return '<span>Week Ending: '
                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                + '<br>'
                                                                                + this.series.name
                                                                                + '<br> '
                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                + '</span>'
                                                                        }
                                                                    }
                                                                },
                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                parseFloat(((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['promotedunits']))
                                                                ]),
                                                                zIndex: 1,
                                                                visible: false,
                                                                showInLegend: false,
                                                            }

                                                        }
                                                    })
                                                }
                                                unitsvar={this.props.state.unitsvar}
                                                distinctdates={this.props.state.distinctdates}
                                                weekly_data={this.props.state.outlet_data_weekly_geo}
                                                selectedBrand={this.props.state.selectedChannel}
                                                showDataTable={this.props.state.showDataTable}
                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                weekagg={this.props.state.filter_object.numweeks}
                                                height={2}
                                                attr_caption_text={this.props.state.attr_caption_text}
                                                yAxisTitle='Promoted Price'
                                                yAxisTitle2='Promoted Price'
                                                yAxisType='currency'
                                                yAxisType2='currency'
                                                selectedBrands={this.props.state.selectedChannels}
                                                aggperiod={this.props.state.datasettimeagg}
                                                numTicks={5}
                                                yMin={0}
                                                yMax={this.props.state.yMax}
                                                currencycode={this.props.state.currencycode}
                                                currencytype={this.props.state.currencyType}
                                                outletcode={this.props.state.outlets[1]}
                                                metadata={this.props.state.chartmetadata}
                                            />
                                        </div>
                                    </div>
                                    : this.props.state.retailerbrandviewsnonpromo ?
                                        <div className="row" style={{ width: "100%", height: "100%" }}>
                                            <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyViewMultipleSeries
                                                    chart_title={"Average Non-Promoted Price - " + this.props.state.outlets[0]}
                                                    chart_title_info={this.props.state.chart_title_info_nochannels}
                                                    seriesList={
                                                        this.props.state.selectedChannels.map((geo, idx) => {
                                                            let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['nonpromoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['nonpromotedunits']) || 0
                                                                if (isFinite(price)) {
                                                                    return price
                                                                }
                                                                else {
                                                                    return 0
                                                                }
                                                            }).reduce(reducer)

                                                            console.log("%s total prices are: %s", geo, totalprices);

                                                            if (totalprices > 0) {
                                                                return {
                                                                    name: this.props.state.outlets[0] + ' ' + geo,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['nonpromoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['nonpromotedunits'])
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: true,
                                                                    showInLegend: true,
                                                                }
                                                            }
                                                            else {
                                                                return {
                                                                    name: this.props.state.outlets[0] + ' ' + geo,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['nonpromoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['nonpromotedunits'])
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: false,
                                                                    showInLegend: false,
                                                                }
                                                            }
                                                        })
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.outlet_data_weekly_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle='Non-Promoted Price'
                                                    yAxisTitle2='Non-Promoted Price'
                                                    yAxisType='currency'
                                                    yAxisType2='currency'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    numTicks={5}
                                                    yMin={0}
                                                    yMax={this.props.state.yMax}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    outletcode={this.props.state.outlets[0]}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                            <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyViewMultipleSeries
                                                    chart_title={"Average Non-Promoted Price - " + this.props.state.outlets[1]}
                                                    chart_title_info={this.props.state.chart_title_info_nochannels}
                                                    seriesList={
                                                        this.props.state.selectedChannels.map((geo, idx) => {
                                                            let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['nonpromoteddollars']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['nonpromotedunits']) || 0
                                                                if (isFinite(price)) {
                                                                    return price
                                                                }
                                                                else {
                                                                    return 0
                                                                }
                                                            }).reduce(reducer)

                                                            console.log("%s total prices are: %s", geo, totalprices);

                                                            if (totalprices > 0) {
                                                                return {
                                                                    name: this.props.state.outlets[1] + ' ' + geo,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['nonpromoteddollars']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['nonpromotedunits'])
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: true,
                                                                    showInLegend: true,
                                                                }
                                                            }
                                                            else {
                                                                return {
                                                                    name: this.props.state.outlets[1] + ' ' + geo,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['nonpromoteddollars']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['nonpromotedunits'])
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: false,
                                                                    showInLegend: false,
                                                                }
                                                            }
                                                        })
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.outlet_data_weekly_geo}
                                                    selectedBrand={this.props.state.selectedChannel}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle='Non-Promoted Price'
                                                    yAxisTitle2='Non-Promoted Price'
                                                    yAxisType='currency'
                                                    yAxisType2='currency'
                                                    selectedBrands={this.props.state.selectedChannels}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    numTicks={5}
                                                    yMin={0}
                                                    yMax={this.props.state.yMax}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    outletcode={this.props.state.outlets[1]}
                                                    metadata={this.props.state.chartmetadata}
                                                />
                                            </div>
                                        </div>
                                        : this.props.state.retailerbrandviewsasp ?
                                            <div className="row" style={{ width: "100%", height: "100%" }}>
                                                <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyViewMultipleSeries
                                                        chart_title={"Average Selling Price - " + this.props.state.outlets[0]}
                                                        chart_title_info={this.props.state.chart_title_info_nochannels}
                                                        seriesList={
                                                            this.props.state.selectedChannels.map((geo, idx) => {
                                                                let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                    let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['weightedasp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['totalunits']) || 0
                                                                    if (isFinite(price)) {
                                                                        return price
                                                                    }
                                                                    else {
                                                                        return 0
                                                                    }
                                                                }).reduce(reducer)

                                                                console.log("%s total prices are: %s", geo, totalprices);

                                                                if (totalprices > 0) {
                                                                    return {
                                                                        name: this.props.state.outlets[0] + ' ' + geo,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['weightedasp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: true,
                                                                        showInLegend: true,
                                                                    }
                                                                }
                                                                else {
                                                                    return {
                                                                        name: this.props.state.outlets[0] + ' ' + geo,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['weightedasp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: false,
                                                                        showInLegend: false,
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.outlet_data_weekly_geo}
                                                        selectedBrand={this.props.state.selectedChannel}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle='Average Selling Price'
                                                        yAxisTitle2='Average Selling Price'
                                                        yAxisType='currency'
                                                        yAxisType2='currency'
                                                        selectedBrands={this.props.state.selectedChannels}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        numTicks={5}
                                                        yMin={0}
                                                        yMax={this.props.state.yMax}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        outletcode={this.props.state.outlets[0]}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                                <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyViewMultipleSeries
                                                        chart_title={"Average Selling Price - " + this.props.state.outlets[1]}
                                                        chart_title_info={this.props.state.chart_title_info_nochannels}
                                                        seriesList={
                                                            this.props.state.selectedChannels.map((geo, idx) => {
                                                                let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                    let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['weightedasp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['totalunits']) || 0
                                                                    if (isFinite(price)) {
                                                                        return price
                                                                    }
                                                                    else {
                                                                        return 0
                                                                    }
                                                                }).reduce(reducer)

                                                                console.log("%s total prices are: %s", geo, totalprices);

                                                                if (totalprices > 0) {
                                                                    return {
                                                                        name: this.props.state.outlets[1] + ' ' + geo,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['weightedasp']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: true,
                                                                        showInLegend: true,
                                                                    }
                                                                }
                                                                else {
                                                                    return {
                                                                        name: this.props.state.outlets[1] + ' ' + geo,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['weightedasp']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: false,
                                                                        showInLegend: false,
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.outlet_data_weekly_geo}
                                                        selectedBrand={this.props.state.selectedChannel}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle='Average Selling Price'
                                                        yAxisTitle2='Average Selling Price'
                                                        yAxisType='currency'
                                                        yAxisType2='currency'
                                                        selectedBrands={this.props.state.selectedChannels}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        numTicks={5}
                                                        yMin={0}
                                                        yMax={this.props.state.yMax}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        outletcode={this.props.state.outlets[1]}
                                                        metadata={this.props.state.chartmetadata}
                                                    />
                                                </div>
                                            </div> :
                                            this.props.state.retailerbrandviewsbase ?
                                                <div className="row" style={{ width: "100%", height: "100%" }}>
                                                    <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyViewMultipleSeries
                                                            chart_title={"Average Base Price - " + this.props.state.outlets[0]}
                                                            chart_title_info={this.props.state.chart_title_info_nochannels}
                                                            seriesList={
                                                                this.props.state.selectedChannels.map((geo, idx) => {
                                                                    let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                        let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['weightedbp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['totalunits']) || 0
                                                                        if (isFinite(price)) {
                                                                            return price
                                                                        }
                                                                        else {
                                                                            return 0
                                                                        }
                                                                    }).reduce(reducer)

                                                                    console.log("%s total prices are: %s", geo, totalprices);

                                                                    if (totalprices > 0) {
                                                                        return {
                                                                            name: this.props.state.outlets[0] + ' ' + geo,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['weightedbp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: true,
                                                                            showInLegend: true,
                                                                        }
                                                                    }
                                                                    else {
                                                                        return {
                                                                            name: this.props.state.outlets[0] + ' ' + geo,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['weightedbp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[0]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: false,
                                                                            showInLegend: false,
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.outlet_data_weekly_geo}
                                                            selectedBrand={this.props.state.selectedChannel}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={2}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle='Base Price'
                                                            yAxisTitle2='Base Price'
                                                            yAxisType='currency'
                                                            yAxisType2='currency'
                                                            selectedBrands={this.props.state.selectedChannels}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            numTicks={5}
                                                            yMin={0}
                                                            yMax={this.props.state.yMax}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            outletcode={this.props.state.outlets[0]}
                                                            metadata={this.props.state.chartmetadata}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyViewMultipleSeries
                                                            chart_title={"Average Base Price - " + this.props.state.outlets[1]}
                                                            chart_title_info={this.props.state.chart_title_info_nochannels}
                                                            seriesList={
                                                                this.props.state.selectedChannels.map((geo, idx) => {
                                                                    let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                        let price = (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['weightedbp']) / (((((this.props.state.outlet_data_weekly_geo || {})[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['totalunits']) || 0
                                                                        if (isFinite(price)) {
                                                                            return price
                                                                        }
                                                                        else {
                                                                            return 0
                                                                        }
                                                                    }).reduce(reducer)

                                                                    console.log("%s total prices are: %s", geo, totalprices);

                                                                    if (totalprices > 0) {
                                                                        return {
                                                                            name: this.props.state.outlets[1] + ' ' + geo,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['weightedbp']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: true,
                                                                            showInLegend: true,
                                                                        }
                                                                    }
                                                                    else {
                                                                        return {
                                                                            name: this.props.state.outlets[1] + ' ' + geo,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['weightedbp']) / ((((this.props.state.outlet_data_weekly_geo[this.props.state.outlets[1]] || {})[geo] || {})[date] || {})['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: false,
                                                                            showInLegend: false,
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.outlet_data_weekly_geo}
                                                            selectedBrand={this.props.state.selectedChannel}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={2}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle='Base Price'
                                                            yAxisTitle2='Base Price'
                                                            yAxisType='currency'
                                                            yAxisType2='currency'
                                                            selectedBrands={this.props.state.selectedChannels}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            numTicks={5}
                                                            yMin={0}
                                                            yMax={this.props.state.yMax}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            outletcode={this.props.state.outlets[1]}
                                                            metadata={this.props.state.chartmetadata}
                                                        />
                                                    </div>
                                                </div> :
                                                <div />
                                }
                            </Fragment>
                            :
                            <Fragment>
                                {
                                    this.props.state.retailerbrandviewspromo ?
                                        <div className="row" style={{ width: "100%", height: "100%" }}>
                                            <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyViewMultipleSeries
                                                    chart_title={"Average Promoted Price - " + this.props.state.outlets[0] + " "}
                                                    chart_title_info={this.props.state.chart_title_info_nobrands}
                                                    seriesList={
                                                        this.props.state.selectedBrands.map((brand, idx) => {

                                                            //Check if data is greater than 0 for the selected outlet and brand....if so, return data, else return empty...
                                                            let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                let price = ((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['promoteddollars']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['promotedunits'])) || 0
                                                                if (isFinite(price)) {
                                                                    return price
                                                                }
                                                                else {
                                                                    return 0
                                                                }
                                                            }).reduce(reducer)

                                                            console.log("%s total prices are: %s", brand, totalprices);

                                                            if (totalprices > 0) {
                                                                return {
                                                                    name: this.props.state.outlets[0] + ' ' + brand,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }

                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    parseFloat((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['promoteddollars']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['promotedunits']))
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: true,
                                                                    showInLegend: true,
                                                                }
                                                            }

                                                            else if (totalprices > 0) {
                                                                return {
                                                                    name: this.props.state.outlets[0] + ' ' + brand,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), 1
                                                                        //parseFloat(((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]] || {})[brand] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]] || {})[brand] || {})[date] || {})['promotedunits']))
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: true,
                                                                    showInLegend: true,
                                                                }
                                                            }

                                                            else {
                                                                return {
                                                                    name: this.props.state.outlets[0] + ' ' + brand,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    parseFloat((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['promoteddollars']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['promotedunits']))
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: false,
                                                                    showInLegend: false
                                                                }
                                                            }
                                                        })
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.outlet_data_weekly_brand}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle='Promoted Price'
                                                    yAxisTitle2='Promoted Price'
                                                    yAxisType='currency'
                                                    yAxisType2='currency'
                                                    selectedBrands={this.props.state.selectedBrands}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    numTicks={5}
                                                    yMin={0}
                                                    yMax={this.props.state.yMax}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    outletcode={this.props.state.outlets[0]}
                                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                                />
                                            </div>
                                            <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                <WeeklyViewMultipleSeries
                                                    chart_title={"Average Promoted Price - " + this.props.state.outlets[1]}
                                                    chart_title_info={this.props.state.chart_title_info_nobrands}
                                                    seriesList={
                                                        this.props.state.selectedBrands.map((brand, idx) => {
                                                            //Check if data is greater than 0 for the selected outlet and brand....if so, return data, else return empty...
                                                            let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                let price = (((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promotedunits'])) || 0
                                                                if (isFinite(price)) {
                                                                    return price
                                                                }
                                                                else {
                                                                    return 0
                                                                }
                                                            }).reduce(reducer)

                                                            console.log("%s total prices are: %s", brand, totalprices);

                                                            if (totalprices > 0) {
                                                                return {
                                                                    name: this.props.state.outlets[1] + ' ' + brand,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    parseFloat(((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promotedunits']))
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: true,
                                                                    showInLegend: true,
                                                                }
                                                            }

                                                            else if (totalprices > 0) {
                                                                return {
                                                                    name: 'test', //this.props.state.outlets[1] + ' ' + brand,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    parseFloat(((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promotedunits']))
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: true,
                                                                    showInLegend: true,
                                                                }
                                                            }

                                                            else {
                                                                return {
                                                                    name: this.props.state.outlets[1] + ' ' + brand,
                                                                    type: 'spline',
                                                                    color: Constant.graph_colors[idx],
                                                                    yAxis: 0,
                                                                    tooltip: {
                                                                        pointFormatter: function () {
                                                                            let dt = new Date(this.x)
                                                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                            let date = new Date(this.x + offset)
                                                                            if (aggtype === 'Monthly') {
                                                                                return '<span>Month: '
                                                                                    + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                            else {
                                                                                return '<span>Week Ending: '
                                                                                    + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                    + '<br>'
                                                                                    + this.series.name
                                                                                    + '<br> '
                                                                                    + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                    + '</span>'
                                                                            }
                                                                        }
                                                                    },
                                                                    data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                    parseFloat(((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['promotedunits']))
                                                                    ]),
                                                                    zIndex: 1,
                                                                    visible: false,
                                                                    showInLegend: false,
                                                                }

                                                            }
                                                        })
                                                    }
                                                    unitsvar={this.props.state.unitsvar}
                                                    distinctdates={this.props.state.distinctdates}
                                                    weekly_data={this.props.state.outlet_data_weekly_brand}
                                                    selectedBrand={this.props.state.selectedBrand}
                                                    showDataTable={this.props.state.showDataTable}
                                                    showDataTableStyle={this.props.state.showDataTableStyle}
                                                    weekagg={this.props.state.filter_object.numweeks}
                                                    height={2}
                                                    attr_caption_text={this.props.state.attr_caption_text}
                                                    yAxisTitle='Promoted Price'
                                                    yAxisTitle2='Promoted Price'
                                                    yAxisType='currency'
                                                    yAxisType2='currency'
                                                    selectedBrands={this.props.state.selectedBrands}
                                                    aggperiod={this.props.state.datasettimeagg}
                                                    numTicks={5}
                                                    yMin={0}
                                                    yMax={this.props.state.yMax}
                                                    currencycode={this.props.state.currencycode}
                                                    currencytype={this.props.state.currencyType}
                                                    outletcode={this.props.state.outlets[1]}
                                                    metadata={this.props.state.chartmetadata_multiplebrands}
                                                />
                                            </div>
                                        </div>
                                        : this.props.state.retailerbrandviewsnonpromo ?
                                            <div className="row" style={{ width: "100%", height: "100%" }}>
                                                <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyViewMultipleSeries
                                                        chart_title={"Average Non-Promoted Price - " + this.props.state.outlets[0]}
                                                        chart_title_info={this.props.state.chart_title_info_nobrands}
                                                        seriesList={
                                                            this.props.state.selectedBrands.map((brand, idx) => {
                                                                let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                    let price = ((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['nonpromoteddollars']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['nonpromotedunits'])) || 0
                                                                    if (isFinite(price)) {
                                                                        return price
                                                                    }
                                                                    else {
                                                                        return 0
                                                                    }


                                                                }).reduce(reducer)

                                                                console.log("%s total prices are: %s", brand, totalprices);

                                                                if (totalprices > 0) {
                                                                    return {
                                                                        name: this.props.state.outlets[0] + ' ' + brand,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['nonpromoteddollars']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['nonpromotedunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: true,
                                                                        showInLegend: true,
                                                                    }
                                                                }
                                                                else {
                                                                    return {
                                                                        name: this.props.state.outlets[0] + ' ' + brand,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['nonpromoteddollars']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['nonpromotedunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: false,
                                                                        showInLegend: false,
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.outlet_data_weekly_brand}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle='Non-Promoted Price'
                                                        yAxisTitle2='Non-Promoted Price'
                                                        yAxisType='currency'
                                                        yAxisType2='currency'
                                                        selectedBrands={this.props.state.selectedBrands}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        numTicks={5}
                                                        yMin={0}
                                                        yMax={this.props.state.yMax}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        outletcode={this.props.state.outlets[0]}
                                                        metadata={this.props.state.chartmetadata_multiplebrands}
                                                    />
                                                </div>
                                                <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                    <WeeklyViewMultipleSeries
                                                        chart_title={"Average Non-Promoted Price - " + this.props.state.outlets[1]}
                                                        chart_title_info={this.props.state.chart_title_info_nobrands}
                                                        seriesList={
                                                            this.props.state.selectedBrands.map((brand, idx) => {
                                                                let totalprices = (this.props.state.distinctdates).sort().map(date => {

                                                                    let price = (((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['nonpromoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['nonpromotedunits'])) || 0

                                                                    if (isFinite(price)) {
                                                                        return price
                                                                    }
                                                                    else {
                                                                        return 0
                                                                    }

                                                                }).reduce(reducer)

                                                                console.log("%s total prices are: %s", brand, totalprices);

                                                                if (totalprices > 0) {
                                                                    return {
                                                                        name: this.props.state.outlets[1] + ' ' + brand,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['nonpromoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['nonpromotedunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: true,
                                                                        showInLegend: true,
                                                                    }
                                                                }
                                                                else {
                                                                    return {
                                                                        name: this.props.state.outlets[1] + ' ' + brand,
                                                                        type: 'spline',
                                                                        color: Constant.graph_colors[idx],
                                                                        yAxis: 0,
                                                                        tooltip: {
                                                                            pointFormatter: function () {
                                                                                let dt = new Date(this.x)
                                                                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                let date = new Date(this.x + offset)
                                                                                if (aggtype === 'Monthly') {
                                                                                    return '<span>Month: '
                                                                                        + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                                else {
                                                                                    return '<span>Week Ending: '
                                                                                        + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                        + '<br>'
                                                                                        + this.series.name
                                                                                        + '<br> '
                                                                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                        + '</span>'
                                                                                }
                                                                            }
                                                                        },
                                                                        data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                        ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['nonpromoteddollars']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['nonpromotedunits'])
                                                                        ]),
                                                                        zIndex: 1,
                                                                        visible: false,
                                                                        showInLegend: false,
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        unitsvar={this.props.state.unitsvar}
                                                        distinctdates={this.props.state.distinctdates}
                                                        weekly_data={this.props.state.outlet_data_weekly_brand}
                                                        selectedBrand={this.props.state.selectedBrand}
                                                        showDataTable={this.props.state.showDataTable}
                                                        showDataTableStyle={this.props.state.showDataTableStyle}
                                                        weekagg={this.props.state.filter_object.numweeks}
                                                        height={2}
                                                        attr_caption_text={this.props.state.attr_caption_text}
                                                        yAxisTitle='Non-Promoted Price'
                                                        yAxisTitle2='Non-Promoted Price'
                                                        yAxisType='currency'
                                                        yAxisType2='currency'
                                                        selectedBrands={this.props.state.selectedBrands}
                                                        aggperiod={this.props.state.datasettimeagg}
                                                        numTicks={5}
                                                        yMin={0}
                                                        yMax={this.props.state.yMax}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        outletcode={this.props.state.outlets[1]}
                                                        metadata={this.props.state.chartmetadata_multiplebrands}
                                                    />
                                                </div>
                                            </div>
                                            : this.props.state.retailerbrandviewsasp ?
                                                <div className="row" style={{ width: "100%", height: "100%" }}>
                                                    <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyViewMultipleSeries
                                                            chart_title={"Average Selling Price - " + this.props.state.outlets[0]}
                                                            chart_title_info={this.props.state.chart_title_info_nobrands}
                                                            seriesList={
                                                                this.props.state.selectedBrands.map((brand, idx) => {
                                                                    let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                        let price = (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['weightedasp']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['totalunits']) || 0
                                                                        if (isFinite(price)) {
                                                                            return price
                                                                        }
                                                                        else {
                                                                            return 0
                                                                        }
                                                                    }).reduce(reducer)

                                                                    console.log("%s total prices are: %s", brand, totalprices);

                                                                    if (totalprices > 0) {
                                                                        return {
                                                                            name: this.props.state.outlets[0] + ' ' + brand,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['weightedasp']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: true,
                                                                            showInLegend: true,
                                                                        }
                                                                    }
                                                                    else {
                                                                        return {
                                                                            name: this.props.state.outlets[0] + ' ' + brand,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['weightedasp']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: false,
                                                                            showInLegend: false,
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.outlet_data_weekly_brand}
                                                            selectedBrand={this.props.state.selectedBrand}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={2}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle='Average Selling Price'
                                                            yAxisTitle2='Average Selling Price'
                                                            yAxisType='currency'
                                                            yAxisType2='currency'
                                                            selectedBrands={this.props.state.selectedBrands}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            numTicks={5}
                                                            yMin={0}
                                                            yMax={this.props.state.yMax}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            outletcode={this.props.state.outlets[0]}
                                                            metadata={this.props.state.chartmetadata_multiplebrands}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                        <WeeklyViewMultipleSeries
                                                            chart_title={"Average Selling Price - " + this.props.state.outlets[1]}
                                                            chart_title_info={this.props.state.chart_title_info_nobrands}
                                                            seriesList={
                                                                this.props.state.selectedBrands.map((brand, idx) => {
                                                                    let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                        let price = ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['weightedasp']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['totalunits']) || 0
                                                                        if (isFinite(price)) {
                                                                            return price
                                                                        }
                                                                        else {
                                                                            return 0
                                                                        }
                                                                    }).reduce(reducer)

                                                                    console.log("%s total prices are: %s", brand, totalprices);

                                                                    if (totalprices > 0) {
                                                                        return {
                                                                            name: this.props.state.outlets[1] + ' ' + brand,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['weightedasp']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: true,
                                                                            showInLegend: true,
                                                                        }
                                                                    }
                                                                    else {
                                                                        return {
                                                                            name: this.props.state.outlets[1] + ' ' + brand,
                                                                            type: 'spline',
                                                                            color: Constant.graph_colors[idx],
                                                                            yAxis: 0,
                                                                            tooltip: {
                                                                                pointFormatter: function () {
                                                                                    let dt = new Date(this.x)
                                                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                    let date = new Date(this.x + offset)
                                                                                    if (aggtype === 'Monthly') {
                                                                                        return '<span>Month: '
                                                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                    else {
                                                                                        return '<span>Week Ending: '
                                                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                            + '<br>'
                                                                                            + this.series.name
                                                                                            + '<br> '
                                                                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                            + '</span>'
                                                                                    }
                                                                                }
                                                                            },
                                                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                            ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['weightedasp']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['totalunits'])
                                                                            ]),
                                                                            zIndex: 1,
                                                                            visible: false,
                                                                            showInLegend: false,
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            unitsvar={this.props.state.unitsvar}
                                                            distinctdates={this.props.state.distinctdates}
                                                            weekly_data={this.props.state.outlet_data_weekly_brand}
                                                            selectedBrand={this.props.state.selectedBrand}
                                                            showDataTable={this.props.state.showDataTable}
                                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                                            weekagg={this.props.state.filter_object.numweeks}
                                                            height={2}
                                                            attr_caption_text={this.props.state.attr_caption_text}
                                                            yAxisTitle='Average Selling Price'
                                                            yAxisTitle2='Average Selling Price'
                                                            yAxisType='currency'
                                                            yAxisType2='currency'
                                                            selectedBrands={this.props.state.selectedBrands}
                                                            aggperiod={this.props.state.datasettimeagg}
                                                            numTicks={5}
                                                            yMin={0}
                                                            yMax={this.props.state.yMax}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            outletcode={this.props.state.outlets[1]}
                                                            metadata={this.props.state.chartmetadata_multiplebrands}
                                                        />
                                                    </div>
                                                </div> :
                                                this.props.state.retailerbrandviewsbase ?
                                                    <div className="row" style={{ width: "100%", height: "100%" }}>
                                                        <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyViewMultipleSeries
                                                                chart_title={"Average Base Price - " + this.props.state.outlets[0]}
                                                                chart_title_info={this.props.state.chart_title_info_nobrands}
                                                                seriesList={
                                                                    this.props.state.selectedBrands.map((brand, idx) => {
                                                                        let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                            let price = (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['weightedbp']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['totalunits']) || 0
                                                                            if (isFinite(price)) {
                                                                                return price
                                                                            }
                                                                            else {
                                                                                return 0
                                                                            }
                                                                        }).reduce(reducer)

                                                                        console.log("%s total prices are: %s", brand, totalprices);

                                                                        if (totalprices > 0) {
                                                                            return {
                                                                                name: this.props.state.outlets[0] + ' ' + brand,
                                                                                type: 'spline',
                                                                                color: Constant.graph_colors[idx],
                                                                                yAxis: 0,
                                                                                tooltip: {
                                                                                    pointFormatter: function () {
                                                                                        let dt = new Date(this.x)
                                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                        let date = new Date(this.x + offset)
                                                                                        if (aggtype === 'Monthly') {
                                                                                            return '<span>Month: '
                                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                        else {
                                                                                            return '<span>Week Ending: '
                                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                    }
                                                                                },
                                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                                (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['weightedbp']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['totalunits'])
                                                                                ]),
                                                                                zIndex: 1,
                                                                                visible: true,
                                                                                showInLegend: true,
                                                                            }
                                                                        }
                                                                        else {
                                                                            return {
                                                                                name: this.props.state.outlets[0] + ' ' + brand,
                                                                                type: 'spline',
                                                                                color: Constant.graph_colors[idx],
                                                                                yAxis: 0,
                                                                                tooltip: {
                                                                                    pointFormatter: function () {
                                                                                        let dt = new Date(this.x)
                                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                        let date = new Date(this.x + offset)
                                                                                        if (aggtype === 'Monthly') {
                                                                                            return '<span>Month: '
                                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                        else {
                                                                                            return '<span>Week Ending: '
                                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                    }
                                                                                },
                                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                                (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['weightedbp']) / (this.props.state.outlet_data_weekly_brand[this.props.state.outlets[0]][brand][date]['totalunits'])
                                                                                ]),
                                                                                zIndex: 1,
                                                                                visible: false,
                                                                                showInLegend: false,
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.outlet_data_weekly_brand}
                                                                selectedBrand={this.props.state.selectedBrand}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={2}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle='Base Price'
                                                                yAxisTitle2='Base Price'
                                                                yAxisType='currency'
                                                                yAxisType2='currency'
                                                                selectedBrands={this.props.state.selectedBrands}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                numTicks={5}
                                                                yMin={0}
                                                                yMax={this.props.state.yMax}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                outletcode={this.props.state.outlets[0]}
                                                                metadata={this.props.state.chartmetadata_multiplebrands}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                                                            <WeeklyViewMultipleSeries
                                                                chart_title={"Average Base Price - " + this.props.state.outlets[1]}
                                                                chart_title_info={this.props.state.chart_title_info_nobrands}
                                                                seriesList={
                                                                    this.props.state.selectedBrands.map((brand, idx) => {
                                                                        let totalprices = (this.props.state.distinctdates).sort().map(date => {
                                                                            let price = ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['weightedbp']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['totalunits']) || 0
                                                                            if (isFinite(price)) {
                                                                                return price
                                                                            }
                                                                            else {
                                                                                return 0
                                                                            }
                                                                        }).reduce(reducer)

                                                                        console.log("%s total prices are: %s", brand, totalprices);

                                                                        if (totalprices > 0) {
                                                                            return {
                                                                                name: this.props.state.outlets[1] + ' ' + brand,
                                                                                type: 'spline',
                                                                                color: Constant.graph_colors[idx],
                                                                                yAxis: 0,
                                                                                tooltip: {
                                                                                    pointFormatter: function () {
                                                                                        let dt = new Date(this.x)
                                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                        let date = new Date(this.x + offset)
                                                                                        if (aggtype === 'Monthly') {
                                                                                            return '<span>Month: '
                                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                        else {
                                                                                            return '<span>Week Ending: '
                                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                    }
                                                                                },
                                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                                ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['weightedbp']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['totalunits'])
                                                                                ]),
                                                                                zIndex: 1,
                                                                                visible: true,
                                                                                showInLegend: true,
                                                                            }
                                                                        }
                                                                        else {
                                                                            return {
                                                                                name: this.props.state.outlets[1] + ' ' + brand,
                                                                                type: 'spline',
                                                                                color: Constant.graph_colors[idx],
                                                                                yAxis: 0,
                                                                                tooltip: {
                                                                                    pointFormatter: function () {
                                                                                        let dt = new Date(this.x)
                                                                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                                                                        let date = new Date(this.x + offset)
                                                                                        if (aggtype === 'Monthly') {
                                                                                            return '<span>Month: '
                                                                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                        else {
                                                                                            return '<span>Week Ending: '
                                                                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                                                                + '<br>'
                                                                                                + this.series.name
                                                                                                + '<br> '
                                                                                                + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                                                                                + '</span>'
                                                                                        }
                                                                                    }
                                                                                },
                                                                                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                                                                ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['weightedbp']) / ((((this.props.state.outlet_data_weekly_brand[this.props.state.outlets[1]] || {})[brand] || {})[date] || {})['totalunits'])
                                                                                ]),
                                                                                zIndex: 1,
                                                                                visible: false,
                                                                                showInLegend: false,
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                unitsvar={this.props.state.unitsvar}
                                                                distinctdates={this.props.state.distinctdates}
                                                                weekly_data={this.props.state.outlet_data_weekly_brand}
                                                                selectedBrand={this.props.state.selectedBrand}
                                                                showDataTable={this.props.state.showDataTable}
                                                                showDataTableStyle={this.props.state.showDataTableStyle}
                                                                weekagg={this.props.state.filter_object.numweeks}
                                                                height={2}
                                                                attr_caption_text={this.props.state.attr_caption_text}
                                                                yAxisTitle='Base Price'
                                                                yAxisTitle2='Base Price'
                                                                yAxisType='currency'
                                                                yAxisType2='currency'
                                                                selectedBrands={this.props.state.selectedBrands}
                                                                aggperiod={this.props.state.datasettimeagg}
                                                                numTicks={5}
                                                                yMin={0}
                                                                yMax={this.props.state.yMax}
                                                                currencycode={this.props.state.currencycode}
                                                                currencytype={this.props.state.currencyType}
                                                                outletcode={this.props.state.outlets[1]}
                                                                metadata={this.props.state.chartmetadata_multiplebrands}
                                                            />
                                                        </div>
                                                    </div> :
                                                    <div />
                                }
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}