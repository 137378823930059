import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import selectColourStyles from '../../../styles/SelectColourStyles'

export class SingleChannelDropDown extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle">Select a Channel</span>
                <Select
                    options={[{ 'value': 'All Channels', 'label': 'All Channels' }].concat(this.props.state.all_geos.map(geo => { return { 'value': geo, 'label': geo } }))}
                    placeholder={'Select a Channel'}
                    onChange={(geo, position) => this.props.changeSelectedChannel(geo, 0)}
                    value={this.props.state.selectedChannel === 'No Rows' ? [] : [{ 'label': this.props.state.selectedChannel, 'value': this.props.state.selectedChannel }]}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}