import * as Constant from '../../../constants'

function getLocalStorageBasePrices(simId) {
    if (simId == null) {
        return {}
    }
    const basePrices = JSON.parse(localStorage.getItem(String(simId)) || "{}")
    return Object.keys(basePrices).reduce((prev, current) => ({...prev, [current]: parseFloat(basePrices[current])}), {})
}

export default async function getstartingvalues(simid, datasetid, items, geo, start, end) {

    var basecalcdata = {
        itemids: items,
        startdate: start,
        enddate: end,
        dataset_id: datasetid,
        geogid: geo
    }
    var jsonbody = JSON.stringify(basecalcdata)

    let baseCalcResult = await fetch(Constant.baseapiurl + "Simulation/base_calculation",
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: jsonbody
        })

    var finalresultvol = {}, finalresultasp = {}, finalresultdis = {}, sliderRange = [], sliderRangeMin = 0, sliderRangeMax = 0

    if (baseCalcResult.status === 200) {
        let result = await baseCalcResult.text()
        var result2 = result.replace(/NaN/g, "0").replace(/Infinity/g, "0")
        var resultformatted = JSON.parse(result2)
        
        for (var i = 0; i < resultformatted.itemids.length; i++) {
            try {
                finalresultvol[resultformatted.itemids[i]] = resultformatted.basevolume[i] + resultformatted.covidvolume[i] + resultformatted.holidayvolume[i]
            }
            catch (error) {
                //console.log(error)
                finalresultvol[resultformatted.itemids[i]] = resultformatted.basevolume[i]
            }
            finalresultasp[resultformatted.itemids[i]] = resultformatted.avgbp[i]
            finalresultdis[resultformatted.itemids[i]] = resultformatted.avg_discount[i]
        }

        let asp_vals = Object.values(finalresultasp)
        sliderRange = [Math.floor(Math.min(...asp_vals)), Math.round(Math.max(...asp_vals))]
        sliderRangeMin = Math.floor(Math.min(...asp_vals))
        sliderRangeMax = Math.round(Math.max(...asp_vals))
    }

    else {
        this.setState({ showBackground: false, errorAlert: true })
    }

    // check for local storage base prices 
    const localStorageBasePrices = getLocalStorageBasePrices(simid)
    for (const itemId of Object.keys(localStorageBasePrices)) {
        if (finalresultasp[itemId] != null) {
            finalresultasp[itemId] = localStorageBasePrices[itemId]
        }
    }

    this.setState({
        basecalcresultvol: finalresultvol,
        basecalcresultasp: finalresultasp,
        basecalcresultdis: finalresultdis,
        sliderRange: sliderRange,
        sliderRangeMin: sliderRangeMin,
        sliderRangeMax: sliderRangeMax
    })

}