export default function setg2definition(selectedOptions, position) {

    var g2definition = ""
    var selectedBrands_g2 = this.state.selectedBrands_g2
    var exclude_brands_g2 = this.state.exclude_brands_g2
    var selectedPriceBands_g2 = this.state.selectedPriceBands_g2
    var selectedsegment1_g2 = this.state.selectedsegment1_g2
    var selectedsegment2_g2 = this.state.selectedsegment2_g2
    var selectedsegment3_g2 = this.state.selectedsegment3_g2
    var selectedsegment4_g2 = this.state.selectedsegment4_g2
    var selectedoutlet_g2 = this.state.selectedoutlet_g2
    var selectedAttributes_g2 = this.state.selectedAttributes_g2
    var selectedchannel_g2 = this.state.selectedchannel_g2

    if (position === 'brand') {
        if (selectedOptions) {
            selectedBrands_g2 = selectedOptions
            this.setState({ selectedBrands_g2 })
        }
        else {
            selectedBrands_g2 = []
            this.setState({ selectedBrands_g2 })
        }
    }
    else if (position === 'priceband') {
        if (selectedOptions) {
            selectedPriceBands_g2 = selectedOptions
            this.setState({ selectedPriceBands_g2 })
        }
        else {
            selectedPriceBands_g2 = []
            this.setState({ selectedPriceBands_g2 })
        }
    }

    else if (position === 'segment1') {
        if (selectedOptions) {
            selectedsegment1_g2 = selectedOptions
            this.setState({ selectedsegment1_g2 })
        }
        else {
            selectedsegment1_g2 = []
            this.setState({ selectedsegment1_g2 })
        }
    }

    else if (position === 'segment2') {
        if (selectedOptions) {
            selectedsegment2_g2 = selectedOptions
            this.setState({ selectedsegment2_g2 })
        }
        else {
            selectedsegment2_g2 = []
            this.setState({ selectedsegment2_g2 })
        }
    }

    else if (position === 'segment3') {
        if (selectedOptions) {
            selectedsegment3_g2 = selectedOptions
            this.setState({ selectedsegment3_g2 })
        }
        else {
            selectedsegment3_g2 = []
            this.setState({ selectedsegment3_g2 })
        }
    }

    else if (position === 'segment4') {
        if (selectedOptions) {
            selectedsegment4_g2 = selectedOptions
            this.setState({ selectedsegment4_g2 })
        }
        else {
            selectedsegment4_g2 = []
            this.setState({ selectedsegment4_g2 })
        }
    }

    else if (position === 'outlet') {
        if (selectedOptions) {
            selectedoutlet_g2 = selectedOptions
            this.setState({ selectedoutlet_g2 })
        }
        else {
            selectedoutlet_g2 = []
            this.setState({ selectedoutlet_g2 })
        }
    }

    else if (position === 'channel') {
        if (selectedOptions) {
            selectedchannel_g2 = selectedOptions
            this.setState({ selectedchannel_g2 })
        }
        else {
            selectedchannel_g2 = []
            this.setState({ selectedchannel_g2 })
        }
    }

    else {

        if (selectedOptions) {
            selectedAttributes_g2[position] = selectedOptions
            this.setState({ selectedAttributes_g2 })
        }
        else {
            selectedAttributes_g2[position] = []
            this.setState({ selectedAttributes_g2 })
        }
    }

    //Set Definition
    var brandstring = ""
    var attrstring = ""
    var pricebandstring = ""
    var seg1string = ""
    var seg2string = ""
    var seg3string = ""
    var seg4string = ""
    var outletstring = ""
    var channelstring = ""

    if (selectedBrands_g2.length > 0) {
        if (exclude_brands_g2) {
            brandstring = '<span> Excluding Brands: ' + selectedBrands_g2.map(brand => brand.label).join(', ') + '<br /></span>'
        }
        else {
            brandstring = '<span> Brands: ' + selectedBrands_g2.map(brand => brand.label).join(', ') + '<br /></span>'
        }
    }

    Object.keys(this.state.selectedAttributes_g2).map(attribute => {
        if (selectedAttributes_g2[attribute].length === 0) {
            attrstring += ""
        }
        else {
            attrstring += '<span>' + this.state.attributenamelabels[attribute] + ' : ' + selectedAttributes_g2[attribute].map(attr => attr.label).join(', ') + ' <br /></span>'
        }
    })

    if (selectedPriceBands_g2.length > 0) {
        pricebandstring = '<span> Price Bands: ' + selectedPriceBands_g2.map(band => band.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment1_g2.length > 0) {
        seg2string = '<span> ' + this.state.segment1label + ': ' + selectedsegment1_g2.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment2_g2.length > 0) {
        seg2string = '<span> ' + this.state.segment2label + ': ' + selectedsegment2_g2.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment3_g2.length > 0) {
        seg3string = '<span> ' + this.state.segment3label + ': ' + selectedsegment3_g2.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment2_g2.length > 0) {
        seg4string = '<span> ' + this.state.segment4label + ': ' + selectedsegment4_g2.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedoutlet_g2.length > 0) {
        outletstring = '<span> Outlets: ' + selectedoutlet_g2.map(outlet => outlet.label).join(', ') + '<br /></span>'
    }

    if (selectedchannel_g2.length > 0) {
        channelstring = '<span> Channels: ' + selectedchannel_g2.map(channel => channel.label).join(', ') + '<br /></span>'
    }

    g2definition = brandstring + attrstring + pricebandstring + seg1string + seg2string + seg3string + seg4string + outletstring + channelstring
    this.setState({ g2definition })
}