import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


export class AreaChartTrended extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        var showdollars
        if (this.props.state.indexSwitchCheckDollars === true) {
            showdollars = true
        }
        else {
            showdollars = false
        }

        var currencycode = this.props.state.currencycode
        var weeklytimeperiods = this.props.state.weeklytimeperiods
        var aggtype = this.props.state.datasettimeagg

        var unitsvar_forgraphs_area
        if (this.props.state.indexSwitchCheckDollars === true) {
            unitsvar_forgraphs_area = this.props.state.currencyType
        }
        else {
            unitsvar_forgraphs_area = this.props.state.unitsvar
        }
        return (
            <Fragment>
                {this.props.state.viewItems ?
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={
                            {
                                chart: {
                                    type: 'area',
                                    zoomType: 'xy',
                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                },
                                metadata: this.props.state.chartmetadata,
                                caption: {
                                    text: this.props.state.attr_caption_text //'Attributes Selected: ' + Object.keys(this.props.state.filters[5]).map(attribute => this.props.state.attribute_lookup[attribute] + ': ' + this.props.state.filters[5][attribute].map(attrval => attrval.value))
                                },
                                title: {
                                    text:
                                        this.props.state.indexSwitchCheckDollars ?
                                            this.props.state.selectedMetric_Area.label + ' ' + this.props.state.currencyType + ' by Item' + this.props.state.chart_title_info
                                            :
                                            this.props.state.selectedMetric_Area.label + ' ' + this.props.state.unitsvar + ' by Item' + this.props.state.chart_title_info

                                },
                                series: this.props.state.selectedItems.map(item => {
                                    return {
                                        name: item,
                                        data: this.props.state.distinctdates.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), ((this.props.state.item_data_weekly[item] || {})[date] || {})[this.props.state.AreaKey]])
                                    }
                                }),
                                xAxis: {
                                    title: {
                                        text: this.props.state.xAxisText
                                    },
                                    type: 'datetime',
                                    dateTimeLabelFormats: {
                                        month: this.props.state.xAxisLabelFormatString
                                    },
                                },
                                yAxis: {
                                    title: {
                                        text: this.props.state.indexSwitchCheckDollars ? this.props.state.currencyType : this.props.state.unitsvar
                                    },
                                    gridLineColor: "#CCC",
                                    gridLineWidth: 1,
                                    reversedStacks: false,
                                    labels: {

                                        formatter: function () {

                                            function nFormatter(num, digits, axisMax) {
                                                var si = [
                                                    { value: 1E18, symbol: "E" },
                                                    { value: 1E15, symbol: "P" },
                                                    { value: 1E12, symbol: "T" },
                                                    { value: 1E9, symbol: "B" },
                                                    { value: 1E6, symbol: "M" },
                                                    { value: 1E3, symbol: "k" }
                                                ], i;

                                                let symbolForAxis = " "
                                                let valueForAxis = 1

                                                for (i = 0; i < si.length; i++) {
                                                    if (axisMax >= si[i].value) {
                                                        symbolForAxis = si[i].symbol
                                                        valueForAxis = si[i].value
                                                        break;
                                                    }
                                                }

                                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                            }

                                            if (showdollars) {
                                                console.log(this.axis.max)
                                                let value = nFormatter(this.value, 1, this.axis.max)
                                                let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                let symbol = value.substring(value.length - 1)
                                                return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                            }
                                            else {
                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                            }


                                        }
                                    },

                                },
                                plotOptions: {
                                    area: {
                                        //stacking: 'percent',
                                        stacking: 'normal',
                                        lineColor: '#ffffff',
                                        lineWidth: 1,
                                        marker: {
                                            lineWidth: 1,
                                            lineColor: '#ffffff',
                                            enabled: false
                                        },
                                        line: {
                                            marker: {
                                                enabled: false
                                            }
                                        }
                                    }
                                },
                                tooltip: {
                                    formatter: function () {
                                        let dt = new Date(this.x)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.x + offset)
                                        if (aggtype === 'Monthly') {
                                            return '<span>Month: '
                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString()
                                                + ' '
                                                + unitsvar_forgraphs_area
                                                + '</span>'
                                        }
                                        else {
                                            return '<span>Week Ending: '
                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString()
                                                + ' '
                                                + unitsvar_forgraphs_area
                                                + '</span>'
                                        }
                                    }
                                },
                                exporting: {
                                    showTable: this.props.state.showDataTable,
                                    tableCaption: false
                                },

                            }}
                    />
                    :
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={
                            {
                                chart: {
                                    type: 'area',
                                    zoomType: 'xy',
                                    height: (9 / 16 * 100) + '%' //16:9 ratio
                                },
                                metadata: this.props.state.chartmetadata,
                                caption: {
                                    text: this.props.state.attr_caption_text
                                },
                                title: {
                                    text: this.props.state.indexSwitchCheckDollars ?
                                        this.props.state.selectedMetric_Area.label + ' ' + this.props.state.currencyType + ' by Brand' + this.props.state.chart_title_info
                                        :
                                        this.props.state.selectedMetric_Area.label + ' ' + this.props.state.unitsvar + ' by Brand' + this.props.state.chart_title_info
                                },
                                series: this.props.state.selectedBrands.map(brand => {
                                    return {
                                        name: brand,
                                        data: this.props.state.distinctdates.sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)) + 1), (((this.props.state.weekly_data || {})[brand] || {})[date] || {})[this.props.state.AreaKey]])
                                    }
                                }),
                                xAxis: {
                                    title: {
                                        text: this.props.state.xAxisText
                                    },
                                    type: 'datetime',
                                    dateTimeLabelFormats: {
                                        month: this.props.state.xAxisLabelFormatString
                                    },
                                    tickPositions: this.props.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                    labels: {
                                        formatter: function () {
                                            let dt = new Date(this.value)
                                            let offset = dt.getTimezoneOffset() * 60 * 1000
                                            let date = new Date(this.value + offset)
                                            const options = { year: 'numeric', month: 'short' };
                                            const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                            if ((weeklytimeperiods || "") === 'Monthly') {
                                                return date.toLocaleDateString(undefined, options)
                                            }
                                            else {
                                                return date.toLocaleDateString(undefined, options2) //toISOString().substring(0, 10)
                                            }
                                        },
                                        rotation: 270,
                                        step: 1,
                                        padding: 5,
                                        style: {
                                            fontSize: '11px'
                                        }
                                    },
                                },
                                yAxis: {
                                    title: {
                                        text: this.props.state.indexSwitchCheckDollars ? this.props.state.currencyType : this.props.state.unitsvar
                                    },
                                    gridLineColor: "#CCC",
                                    gridLineWidth: 1,
                                    reversedStacks: false,
                                    labels: {

                                        formatter: function () {

                                            function nFormatter(num, digits, axisMax) {
                                                var si = [
                                                    { value: 1E18, symbol: "E" },
                                                    { value: 1E15, symbol: "P" },
                                                    { value: 1E12, symbol: "T" },
                                                    { value: 1E9, symbol: "B" },
                                                    { value: 1E6, symbol: "M" },
                                                    { value: 1E3, symbol: "k" }
                                                ], i;

                                                let symbolForAxis = " "
                                                let valueForAxis = 1

                                                for (i = 0; i < si.length; i++) {
                                                    if (axisMax >= si[i].value) {
                                                        symbolForAxis = si[i].symbol
                                                        valueForAxis = si[i].value
                                                        break;
                                                    }
                                                }

                                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                            }

                                            if (showdollars) {
                                                console.log(this.axis.max)
                                                let value = nFormatter(this.value, 1, this.axis.max)
                                                let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                                let symbol = value.substring(value.length - 1)
                                                return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                            }
                                            else {
                                                return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                            }

                                        }
                                    },

                                },
                                plotOptions: {
                                    area: {
                                        //stacking: 'percent',
                                        stacking: 'normal',
                                        lineColor: '#ffffff',
                                        lineWidth: 1,
                                        marker: {
                                            lineWidth: 1,
                                            lineColor: '#ffffff',
                                            enabled: false
                                        },
                                        line: {
                                            marker: {
                                                enabled: false
                                            }
                                        }
                                    }
                                },
                                tooltip: {
                                    formatter: function () {
                                        let dt = new Date(this.x)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.x + offset)
                                        if (aggtype === 'Monthly') {
                                            return '<span>Month: '
                                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString()
                                                + ' '
                                                + unitsvar_forgraphs_area
                                                + '</span>'
                                        }
                                        else {
                                            return '<span>Week Ending: '
                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString()
                                                + ' '
                                                + unitsvar_forgraphs_area
                                                + '</span>'
                                        }
                                    }
                                },
                                exporting: {
                                    showTable: this.props.state.showDataTable,
                                    tableCaption: false
                                },

                            }}
                    />
                }
                <div style={{ display: this.props.showDataTableStyle }}>

                </div>
            </Fragment>
        )
    }
    
}