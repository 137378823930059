import React, { Component, Fragment } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TablePagination from '@material-ui/core/TablePagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import { Layout } from './Layout'
import './styles/custom.css'
import selectColourStyles from './styles/SelectColourStyles'
import * as Constant from './constants'
import { ApiErrorDialog, openApiErrorDialog, closeApiErrorDialog, on_api_entity_crud_error } from './ApiMethods/ApiErrorDialog'
import { entityCrud } from './ApiMethods/EntityCrud'
import { loadAsync } from './ApiMethods/SubmitAsync'
import {
    handleCloseUserInfo, handleCloseUserPerms, handleCloseNewUser, handleCloseNewUsers, handleCloseUsersITTickets, handleOpenNewUser,
    handleChangeUserInfoCheckboxEnabled, handleChangeUserInfoEmail, handleChangeUserInfoGroup, handleSubmitUser, handleSubmitUserNew, handleDeleteUser,
    handleChangePageUser, handleChangeRowsPerPageUser, handleOpenUsersITTickets, addNewUserITTickets, removeUserITTickets,
    handleChangeUserInfoITTickets, submitITTickets, addNewUserMultiple, removeUserMultiple, handleChangeUserInfoMultiple,
    submitMultipleUsers, handleOpenNewUserMultiple, getUserInfo, getUserPerms, fetchUserList
} from './Admin/UserModalFunctions.js'
import { UserDialogs } from './Admin/UserModalDialogs'
import {
    handleCloseGroupInfo, handleCloseGroupInfoNew, handleCloseGroupPerms, handleCloseGroupUsers, handleOpenNewGroup, handleChangeGroupName,
    handleSubmitGroup, handleSubmitGroupNew, handleSubmitGroupChangesPerms, handleDeleteGroup, handleChangeUsersCheckbox,
    handleChangeGroupPerms, removeGroupPermission, addNewGroupPermission, handleChangePageGroups, handleChangeRowsPerPageGroups,
    getGroupInfo, getGroupPerms, getGroupMembers, fetchGroupList
} from './Admin/GroupModalFunctions.js'
import { GroupDialogs } from './Admin/GroupModalDialogs'
import EditGroupMemebers from './Admin/GroupModals/EditGroupMembers'
import {
    handleCloseCompanyInfo, handleCloseCompanyInfoNew, handleCloseCompanyPerms, handleOpenNewCompany, handleChangeCompanyName,
    handleChangeCompanyPerms, removeCompanyPermission, addNewCompanyPermission, handleChangeCompanyCheckboxRetailer,
    handleChangeCompanyCheckboxManufacturer, handleChangeUserInfoName, handleSubmitCompany, handleSubmitCompanyNew,
    handleSubmitCompanyChangesPerms, handleDeleteCompany, handleChangeUserInfoCompany, handleChangePageCompany,
    handleChangeRowsPerPageCompany, getCompanyInfo, getCompanyPerms, fetchCompanyList
} from './Admin/CompanyModalFunctions'
import { CompanyDialogs } from './Admin/CompanyModalDialogs'
import { PnpDialogs } from './Admin/PnpModalDialogs'
import {
    hidePnpConfig, handleChangePnpDataSetConfigLong, handleChangePnpDataSetConfigShort, handleChangePnpDataSetConfigTablePrefix,
    handleChangePnpDataSetConfigDatasetIdentifier, handleChangePnpDataSetConfigDatasetFolder, handleChangePnpDataSetConfigIndustry,
    handleChangePnpConfigCheckboxEnabled, handleSubmitPnpConfigNew, handleSubmitPnpConfig, handleCloseNewPnpConfig, handleClosePnpDatasetInfo,
    handleOpenNewPnpConfig, openEditPnpDataSetConfig, openPnpDataSetInfo, handleChangeTimeIDCheckboxEnabled, handleChangeProdIDCheckboxEnabled,
    identifyPNPDatasetstoLoad, loadPNPDataset, handleChangeDatasetToLoad, closeApiLoadingSuccessful, closeApiLoadingFailed
} from './Admin/PnpModalFunctions'
import { PdtDialogs } from './Admin/PdtModalDialogs'
import {
    hidePdtConfig, handleChangePdtDataSetConfigLong, handleChangePdtDataSetConfigShort, handleChangePdtDataSetConfigTablePrefix,
    handleChangePdtDataSetConfigAttributes, handleChangePdtDataSetConfigAttributesHuman, handleChangePdtDataSetConfigGlobalAttributes,
    handleChangePdtDataSetConfigGlobalAttributesHuman, handleChangePdtDataSetConfigCategoryLevel, handleChangePdtDataSetConfigSegment1,
    handleChangePdtDataSetConfigSegment2, handleChangePdtDataSetConfigSegment3, handleChangePdtDataSetConfigSegment4,
    handleChangePdtDataSetConfigStoreGroups, handleSubmitPdtConfigNew, handleClosePdtNewConfig, handleChangePdtDataSetConfigTimeAgg,
    handleClosePdtDatasetInfo, handleSubmitPdtConfig, openEditPdtDataSetConfig, openPdtDataSetInfo, handleOpenNewPdtConfig,
    handleChangePdtDataSetConfigIndustry, handleChangePdtDataSetConfigSel1List, handleChangePdtDataSetConfigSel2List,
    handleChangePdtDataSetConfigSel3List, handleChangePdtDataSetConfigSel4List, handleChangePdtDataSetConfigBeingModified,
    handleChangePdtDataSetConfigIsInvalid, handleChangePdtDataSetConfigIsWholeIndustry, handleChangePdtDataSetConfigFlags
} from './Admin/PdtModalFunctions'
import { PdtStackingDialog } from "./Admin/PdtStackingDialog"
import {
    onChangePdtStackingCountry, onChangePdtStackingIndustry, onChangePdtStackingEnvRadio, onChangePdtStackingTemplateDataset,
    onChangePdtStackingExtraDatasets, onChangePdtStackingPrefix, onChangePdtStackingShortDesc, onChangePdtStackingLongDesc,
    handleSubmitPdtStacking, getTemplateDatasetList
} from './Admin/PdtStackingFunctions'
import { PdtSplittingDialog } from "./Admin/PdtSplittingDialog"
import {
    onChangePdtSplittingCountry, onChangePdtSplittingIndustry, onChangePdtSplittingEnvRadio, onChangePdtSplittingDataset,
    onChangePdtSplittingStoreGroup, onChangePdtSplittingRomOutlet, onChangePdtSplittingShortDesc, onChangePdtSplittingLongDesc,
    handleSubmitPdtSplitting, getSplittingDatasetList
} from './Admin/PdtSplittingFunctions'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export class Admin extends Component {
    static displayName = Admin.name

    constructor(props) {
        super(props)
        let default_rows_per_page = 5
        this.state = {
            usertype: "",
            env: null,
            adminselected: true,
            pnpselected: false,
            pdtselected: false,
            datasetTabSelected: 0,
            adminTabSelected: 0,
            datasetinfo_data: [],
            companyinfodata: {},
            groupinfodata: {},
            config_data: {},
            userinfodata: {},
            datasetsettings_data: {},
            datasetgeosettings_data: [],
            datasetbrandsettings_data: [],
            datasettimesettings_data: [],
            datasetattrsettings_data: [],
            pdt_dataset_attributes: [],
            pdt_dataset_attributes_human: [],
            pdt_dataset_global_attributes: [],
            pdt_dataset_global_attributes_human: [],
            pdt_dataset_store_groups: [],
            usersdata: [],
            companiesdata: [],
            company_lookup: {},
            company_options: [],
            user_company_lookup: {},
            groupsdata: [],
            industry_lookup: {},
            industry_options: [],

            view_pnp_config: false,
            view_pnp_config_new: false,
            show_pnp_dataset_info: false,
            view_pdt_config: false,
            view_pdt_config_new: false,
            show_pdt_dataset_info: false,
            showuserinfo: false,
            showuserinfo_new: false,
            showuserinfo_new_multiple: false,
            showcompanyinfo: false,
            showcompanyinfo_new: false,
            showcompanyperms: false,
            viewuserperms: false,
            userpermspdtdata: [],
            userpermspnpdata: [],
            companyinfoPermsPNP: [],
            companyinfoPermsPDT: [],
            groupinfoPermsPNP: [],
            groupinfoPermsPDT: [],
            groupinfoUsers: [],
            users_selected_by_company: {},

            page_pnpdataset: 0,
            page_pdtdataset: 0,
            page_users: 0,
            page_companies: 0,
            page_groups: 0,
            rowsPerPage: default_rows_per_page,

            pnp_edit_button_enabled: true,                                 // Used only in PnpModalFunctions.js and PnpModalDialogs.js

            pdt_edit_button_enabled: true,                                 // Used only in PdtModalFunctions.js and PdtModalDialogs.js

            user_edit_button_enabled: true,                                // Used only in UserModalFunctions.js and UserModalDialogs.js
            user_delete_button_enabled: true,                              // Used only in UserModalFunctions.js and UserModalDialogs.js
            rowsPerPage_user_pnp_dataset_perms: default_rows_per_page,     // Used only in UserModalFunctions.js and UserModalDialogs.js
            page_user_pnp_dataset_perms: 0,                                // Used only in UserModalFunctions.js and UserModalDialogs.js
            page_start_user_pnp_dataset_perms: 0,                          // Used only in UserModalFunctions.js and UserModalDialogs.js
            rowsPerPage_user_pdt_dataset_perms: default_rows_per_page,     // Used only in UserModalFunctions.js and UserModalDialogs.js
            page_user_pdt_dataset_perms: 0,                                // Used only in UserModalFunctions.js and UserModalDialogs.js
            page_start_user_pdt_dataset_perms: 0,                          // Used only in UserModalFunctions.js and UserModalDialogs.js

            company_edit_button_enabled: true,                             // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            company_delete_button_enabled: true,                           // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            company_edit_perm_button_enabled: true,                        // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            rowsPerPage_company_pnp_dataset_perms: default_rows_per_page,  // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            page_company_pnp_dataset_perms: 0,                             // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            page_start_company_pnp_dataset_perms: 0,                       // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            rowsPerPage_company_pdt_dataset_perms: default_rows_per_page,  // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            page_company_pdt_dataset_perms: 0,                             // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js
            page_start_company_pdt_dataset_perms: 0,                       // Used only in CompanyModalFunctions.js and CompanyModalDialogs.js

            group_edit_button_enabled: true,                               // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            group_delete_button_enabled: true,                             // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            group_edit_perm_button_enabled: true,                          // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            group_membership_button_enabled: true,                         // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            rowsPerPage_group_pnp_dataset_perms: default_rows_per_page,    // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            page_group_pnp_dataset_perms: 0,                               // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            page_start_group_pnp_dataset_perms: 0,                         // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            rowsPerPage_group_pdt_dataset_perms: default_rows_per_page,    // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            page_group_pdt_dataset_perms: 0,                               // Used only in GroupModalFunctions.js and GroupModalDialogs.js
            page_start_group_pdt_dataset_perms: 0,                         // Used only in GroupModalFunctions.js and GroupModalDialogs.js

            addedPermissionsCounter: -1, // In our UI, we assign negative permission IDs to newly added permissions
            pnp_dataset_ready_button_enabled: true,
            pnp_check_dataset_messages: [],
            pnp_dataset_loading_messages: [],
            pnp_loading_is_running: false,
            pnp_loading_duration: 0,
            pnp_loading_failed: false,
            pnp_loading_successful: false,
            pnp_dataset_name_to_load: '',

            showloadingbar: false,

            api_error_dialog: false,
            api_error_details: null,
            api_error_count: 0,

            // Values used in both PDT stacking and PDT splitting
            finalSortCountry: [],
            countryInd_options: [],

            // PDT Stacking values
            pdt_stacking_button_enabled: false,
            stacking_env: 'active',
            template_dataset_options: [],
            selectedCountry: '',
            selectedCountryName: '',
            selectedIndustry: null,
            selectedIndustryName: '',
            selectedTemplateDatasetId: '',
            selectedTemplateDatasetName: '',
            extra_dataset_options_values: [],
            pdt_stacking_prefix: '',
            pdt_stacking_short_desc: '',
            pdt_stacking_description: '',

            // PDT Splitting values
            pdt_splitting_button_enabled: false,
            splitting_env: 'active',
            pdtSplitCountry: '',
            pdtSplitCountryName: '',
            pdtSplitIndustry: null,
            pdtSplitIndustryName: '',
            pdtSplitDatasetId: '',
            pdtSplitDatasetName: '',
            pdtSplitStoreGroupId: '',
            pdtSplitStoreGroupName: '',
            pdt_splitting_rom_outlet: '',
            pdt_splitting_short_desc: '',
            pdt_splitting_description: '',

            isLoaded: false,
        }
    }

    async componentDidMount() {
        this.entityCrud = entityCrud.bind(this)
        this.loadAsync = loadAsync.bind(this)
        this.openApiErrorDialog = openApiErrorDialog.bind(this)
        this.closeApiErrorDialog = closeApiErrorDialog.bind(this)
        this.on_api_entity_crud_error = on_api_entity_crud_error.bind(this)

        // Functions from UserModalFunctions.js
        this.handleCloseUserInfo = handleCloseUserInfo.bind(this)
        this.handleCloseUserPerms = handleCloseUserPerms.bind(this)
        this.handleCloseNewUser = handleCloseNewUser.bind(this)
        this.handleCloseNewUsers = handleCloseNewUsers.bind(this)
        this.handleOpenNewUser = handleOpenNewUser.bind(this)
        this.handleChangeUserInfoCheckboxEnabled = handleChangeUserInfoCheckboxEnabled.bind(this)
        this.handleChangeUserInfoEmail = handleChangeUserInfoEmail.bind(this)
        this.handleChangeUserInfoGroup = handleChangeUserInfoGroup.bind(this)
        this.handleSubmitUser = handleSubmitUser.bind(this)
        this.handleSubmitUserNew = handleSubmitUserNew.bind(this)
        this.handleDeleteUser = handleDeleteUser.bind(this)
        this.handleChangePageUser = handleChangePageUser.bind(this)
        this.handleChangeRowsPerPageUser = handleChangeRowsPerPageUser.bind(this)
        this.handleCloseUsersITTickets = handleCloseUsersITTickets.bind(this)
        this.handleOpenUsersITTickets = handleOpenUsersITTickets.bind(this)
        this.addNewUserITTickets = addNewUserITTickets.bind(this)
        this.removeUserITTickets = removeUserITTickets.bind(this)
        this.handleChangeUserInfoITTickets = handleChangeUserInfoITTickets.bind(this)
        this.submitITTickets = submitITTickets.bind(this)
        this.addNewUserMultiple = addNewUserMultiple.bind(this)
        this.removeUserMultiple = removeUserMultiple.bind(this)
        this.handleChangeUserInfoMultiple = handleChangeUserInfoMultiple.bind(this)
        this.submitMultipleUsers = submitMultipleUsers.bind(this)
        this.handleOpenNewUserMultiple = handleOpenNewUserMultiple.bind(this)
        this.getUserInfo = getUserInfo.bind(this)
        this.getUserPerms = getUserPerms.bind(this)
        this.fetchUserList = fetchUserList.bind(this)

        // Functions from GroupModalFunctions.js
        this.handleCloseGroupInfo = handleCloseGroupInfo.bind(this)
        this.handleCloseGroupInfoNew = handleCloseGroupInfoNew.bind(this)
        this.handleCloseGroupPerms = handleCloseGroupPerms.bind(this)
        this.handleCloseGroupUsers = handleCloseGroupUsers.bind(this)
        this.handleOpenNewGroup = handleOpenNewGroup.bind(this)
        this.handleChangeGroupName = handleChangeGroupName.bind(this)
        this.handleSubmitGroup = handleSubmitGroup.bind(this)
        this.handleSubmitGroupNew = handleSubmitGroupNew.bind(this)
        this.handleSubmitGroupChangesPerms = handleSubmitGroupChangesPerms.bind(this)
        this.handleDeleteGroup = handleDeleteGroup.bind(this)
        this.handleChangeGroupPerms = handleChangeGroupPerms.bind(this)
        this.removeGroupPermission = removeGroupPermission.bind(this)
        this.addNewGroupPermission = addNewGroupPermission.bind(this)
        this.handleChangeUsersCheckbox = handleChangeUsersCheckbox.bind(this)
        this.handleChangePageGroups = handleChangePageGroups.bind(this)
        this.handleChangeRowsPerPageGroups = handleChangeRowsPerPageGroups.bind(this)
        this.fetchGroupList = fetchGroupList.bind(this)
        this.getGroupInfo = getGroupInfo.bind(this)
        this.getGroupPerms = getGroupPerms.bind(this)
        this.getGroupMembers = getGroupMembers.bind(this)

        // Functions from CompanyModalFunctions.js
        this.handleCloseCompanyInfo = handleCloseCompanyInfo.bind(this)
        this.handleCloseCompanyInfoNew = handleCloseCompanyInfoNew.bind(this)
        this.handleCloseCompanyPerms = handleCloseCompanyPerms.bind(this)
        this.handleOpenNewCompany = handleOpenNewCompany.bind(this)
        this.handleChangePageCompany = handleChangePageCompany.bind(this)
        this.handleChangeRowsPerPageCompany = handleChangeRowsPerPageCompany.bind(this)
        this.handleChangeCompanyName = handleChangeCompanyName.bind(this)
        this.handleChangeCompanyPerms = handleChangeCompanyPerms.bind(this)
        this.removeCompanyPermission = removeCompanyPermission.bind(this)
        this.addNewCompanyPermission = addNewCompanyPermission.bind(this)
        this.handleChangeCompanyCheckboxRetailer = handleChangeCompanyCheckboxRetailer.bind(this)
        this.handleChangeCompanyCheckboxManufacturer = handleChangeCompanyCheckboxManufacturer.bind(this)
        this.handleChangeUserInfoName = handleChangeUserInfoName.bind(this)
        this.handleChangeUserInfoCompany = handleChangeUserInfoCompany.bind(this)
        this.handleSubmitCompany = handleSubmitCompany.bind(this)
        this.handleSubmitCompanyNew = handleSubmitCompanyNew.bind(this)
        this.handleSubmitCompanyChangesPerms = handleSubmitCompanyChangesPerms.bind(this)
        this.handleDeleteCompany = handleDeleteCompany.bind(this)
        this.getCompanyInfo = getCompanyInfo.bind(this)
        this.getCompanyPerms = getCompanyPerms.bind(this)
        this.fetchCompanyList = fetchCompanyList.bind(this)

        // Functions from PnpModalFunctions.js
        this.hidePnpConfig = hidePnpConfig.bind(this)
        this.handleChangePnpDataSetConfigLong = handleChangePnpDataSetConfigLong.bind(this)
        this.handleChangePnpDataSetConfigShort = handleChangePnpDataSetConfigShort.bind(this)
        this.handleChangePnpDataSetConfigTablePrefix = handleChangePnpDataSetConfigTablePrefix.bind(this)
        this.handleChangePnpDataSetConfigDatasetIdentifier = handleChangePnpDataSetConfigDatasetIdentifier.bind(this)
        this.handleChangePnpDataSetConfigDatasetFolder = handleChangePnpDataSetConfigDatasetFolder.bind(this)
        this.handleChangePnpDataSetConfigIndustry = handleChangePnpDataSetConfigIndustry.bind(this)
        this.handleChangePnpConfigCheckboxEnabled = handleChangePnpConfigCheckboxEnabled.bind(this)
        this.handleSubmitPnpConfigNew = handleSubmitPnpConfigNew.bind(this)
        this.handleSubmitPnpConfig = handleSubmitPnpConfig.bind(this)
        this.handleCloseNewPnpConfig = handleCloseNewPnpConfig.bind(this)
        this.handleClosePnpDatasetInfo = handleClosePnpDatasetInfo.bind(this)
        this.handleOpenNewPnpConfig = handleOpenNewPnpConfig.bind(this)
        this.openEditPnpDataSetConfig = openEditPnpDataSetConfig.bind(this)
        this.openPnpDataSetInfo = openPnpDataSetInfo.bind(this)
        this.handleChangeTimeIDCheckboxEnabled = handleChangeTimeIDCheckboxEnabled.bind(this)
        this.handleChangeProdIDCheckboxEnabled = handleChangeProdIDCheckboxEnabled.bind(this)
        this.identifyPNPDatasetstoLoad = identifyPNPDatasetstoLoad.bind(this)
        this.loadPNPDataset = loadPNPDataset.bind(this)
        this.handleChangeDatasetToLoad = handleChangeDatasetToLoad.bind(this)
        this.closeApiLoadingSuccessful = closeApiLoadingSuccessful.bind(this)
        this.closeApiLoadingFailed = closeApiLoadingFailed.bind(this)

        // Functions from PdtModalFunctions.js
        this.hidePdtConfig = hidePdtConfig.bind(this)
        this.handleChangePdtDataSetConfigLong = handleChangePdtDataSetConfigLong.bind(this)
        this.handleChangePdtDataSetConfigShort = handleChangePdtDataSetConfigShort.bind(this)
        this.handleChangePdtDataSetConfigTablePrefix = handleChangePdtDataSetConfigTablePrefix.bind(this)
        this.handleChangePdtDataSetConfigAttributes = handleChangePdtDataSetConfigAttributes.bind(this)
        this.handleChangePdtDataSetConfigAttributesHuman = handleChangePdtDataSetConfigAttributesHuman.bind(this)
        this.handleChangePdtDataSetConfigGlobalAttributes = handleChangePdtDataSetConfigGlobalAttributes.bind(this)
        this.handleChangePdtDataSetConfigGlobalAttributesHuman = handleChangePdtDataSetConfigGlobalAttributesHuman.bind(this)
        this.handleChangePdtDataSetConfigCategoryLevel = handleChangePdtDataSetConfigCategoryLevel.bind(this)
        this.handleChangePdtDataSetConfigSegment1 = handleChangePdtDataSetConfigSegment1.bind(this)
        this.handleChangePdtDataSetConfigSegment2 = handleChangePdtDataSetConfigSegment2.bind(this)
        this.handleChangePdtDataSetConfigSegment3 = handleChangePdtDataSetConfigSegment3.bind(this)
        this.handleChangePdtDataSetConfigSegment4 = handleChangePdtDataSetConfigSegment4.bind(this)
        this.handleChangePdtDataSetConfigStoreGroups = handleChangePdtDataSetConfigStoreGroups.bind(this)
        this.handleSubmitPdtConfigNew = handleSubmitPdtConfigNew.bind(this)
        this.handleClosePdtNewConfig = handleClosePdtNewConfig.bind(this)
        this.handleChangePdtDataSetConfigTimeAgg = handleChangePdtDataSetConfigTimeAgg.bind(this)
        this.handleClosePdtDatasetInfo = handleClosePdtDatasetInfo.bind(this)
        this.handleSubmitPdtConfig = handleSubmitPdtConfig.bind(this)
        this.openEditPdtDataSetConfig = openEditPdtDataSetConfig.bind(this)
        this.openPdtDataSetInfo = openPdtDataSetInfo.bind(this)
        this.handleOpenNewPdtConfig = handleOpenNewPdtConfig.bind(this)
        this.handleChangePdtDataSetConfigIndustry = handleChangePdtDataSetConfigIndustry.bind(this)
        this.handleChangePdtDataSetConfigSel1List = handleChangePdtDataSetConfigSel1List.bind(this)
        this.handleChangePdtDataSetConfigSel2List = handleChangePdtDataSetConfigSel2List.bind(this)
        this.handleChangePdtDataSetConfigSel3List = handleChangePdtDataSetConfigSel3List.bind(this)
        this.handleChangePdtDataSetConfigSel4List = handleChangePdtDataSetConfigSel4List.bind(this)
        this.handleChangePdtDataSetConfigBeingModified = handleChangePdtDataSetConfigBeingModified.bind(this)
        this.handleChangePdtDataSetConfigIsInvalid = handleChangePdtDataSetConfigIsInvalid.bind(this)
        this.handleChangePdtDataSetConfigIsWholeIndustry = handleChangePdtDataSetConfigIsWholeIndustry.bind(this)
        this.handleChangePdtDataSetConfigFlags = handleChangePdtDataSetConfigFlags.bind(this)

        // Functions from PdtStackingFunctions.js
        this.onChangePdtStackingCountry = onChangePdtStackingCountry.bind(this)
        this.onChangePdtStackingIndustry = onChangePdtStackingIndustry.bind(this)
        this.onChangePdtStackingEnvRadio = onChangePdtStackingEnvRadio.bind(this)
        this.onChangePdtStackingTemplateDataset = onChangePdtStackingTemplateDataset.bind(this)
        this.onChangePdtStackingExtraDatasets = onChangePdtStackingExtraDatasets.bind(this)
        this.onChangePdtStackingPrefix = onChangePdtStackingPrefix.bind(this)
        this.onChangePdtStackingShortDesc = onChangePdtStackingShortDesc.bind(this)
        this.onChangePdtStackingLongDesc = onChangePdtStackingLongDesc.bind(this)
        this.handleSubmitPdtStacking = handleSubmitPdtStacking.bind(this)
        this.getTemplateDatasetList = getTemplateDatasetList.bind(this)

        // Functions from PdtSplittingFunctions.js
        this.onChangePdtSplittingCountry = onChangePdtSplittingCountry.bind(this)
        this.onChangePdtSplittingIndustry = onChangePdtSplittingIndustry.bind(this)
        this.onChangePdtSplittingEnvRadio = onChangePdtSplittingEnvRadio.bind(this)
        this.onChangePdtSplittingDataset = onChangePdtSplittingDataset.bind(this)
        this.onChangePdtSplittingStoreGroup = onChangePdtSplittingStoreGroup.bind(this)
        this.onChangePdtSplittingRomOutlet = onChangePdtSplittingRomOutlet.bind(this)
        this.onChangePdtSplittingShortDesc = onChangePdtSplittingShortDesc.bind(this)
        this.onChangePdtSplittingLongDesc = onChangePdtSplittingLongDesc.bind(this)
        this.handleSubmitPdtSplitting = handleSubmitPdtSplitting.bind(this)
        this.getSplittingDatasetList = getSplittingDatasetList.bind(this)

        // ------------------------------------------------------------------------------------

        let results = await this.entityCrud('Admin page initial data fetch', [
            { 'url': Constant.baseurl + 'account/isauthenticated', 'method': 'GET', 'body': null },
            { 'url': Constant.baseurl + 'environment/getenvironment', 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (results.Errors > 0) {
            // An error screen will be popped up if this fails, but we want to set "isLoaded" before we bail
            this.setState({ isLoaded: true })
            return
        }

        let user_auth_status = results.ResultList[0]
        let env_information = results.ResultList[1]
        let user_role = (user_auth_status.userRole !== undefined) ? user_auth_status.userRole[0] : 'None'

        this.setState({
            userappsdata: user_auth_status.applicationList,
            usertype: user_auth_status.internalUser,
            role: user_role,
            env: env_information,
        })

        // ------------------------------------------------------------------------------------

        results = await this.entityCrud('Admin page initial data fetch', [
            { 'url': Constant.baseapiurl_gen + 'Users?by_company=true', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'pnp/Datasets', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'pdt/Datasets', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'Users', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'Companies', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'Groups', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'etl/IndustryTables', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'etl/IndustryTables/Countries', 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + 'pnp/DatasetLoading/Import', 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (results.Errors > 0) {
            // An error screen will be popped up if this fails, but we want to set "isLoaded" before we bail
            this.setState({ isLoaded: true })
            return
        }

        let usersdata = results.ResultList[0]
        let pnp_datasetsettings_data = results.ResultList[1]
        let pdt_datasetsettings_data = results.ResultList[2]
        let usersalldata = results.ResultList[3]
        let companiesdata = results.ResultList[4]
        let groupsdata = results.ResultList[5]
        let industry_data = results.ResultList[6]
        let country_data = results.ResultList[7]
        let pnp_datasetimports_data = results.ResultList[8]

        let user_company_lookup = {}
        usersdata.forEach(company => {
            company.users.forEach(user => {
                user_company_lookup[user.user_id] = company.company_name
            })
        })

        var company_lookup = {}
        var company_options = []
        companiesdata.forEach(company => {
            company_lookup[company.company_id] = company.company_name
            company_options.push({ 'label': company.company_name, 'value': company.company_id })
        })

        var group_options = groupsdata.map(group => { return { 'value': group.group_id, 'label': group.group_name } })

        var industry_lookup = {}
        var industry_options = []
        industry_data.forEach(ind => {
            industry_lookup[ind.industry_id] = ind.industry_name
            industry_options.push({ 'label': ind.industry_name, 'value': ind.industry_id })
        })

        let pnp_import_options = (pnp_datasetimports_data || []).map(import_item => {
            return { 'label': import_item.short_desc, 'value': import_item.dataset_detail_id }
        })
        pnp_import_options.sort((a, b) => a.label.localeCompare(b.label))

        this.setState({
            user_company_lookup,
            users_by_company: usersdata,
            pnp_datasets: pnp_datasetsettings_data,
            pdt_datasets: pdt_datasetsettings_data,
            datasetgeomapping_pnp: dataset_geo_mapping_pnp,
            datasetgeomapping_pdt: dataset_geo_mapping_pdt,
            totalusers: usersalldata.length,
            usersdata: usersalldata,
            companiesdata: companiesdata,
            company_lookup: company_lookup,
            company_options: company_options,
            groupsdata: groupsdata,
            group_options: group_options,
            industry_lookup,
            industry_options,
            pnp_import_options
        })

        // ------------------------------------------------------------------------------------
        // Get a geo mapping for Each PNP Dataset

        let geo_requests = []
        pnp_datasetsettings_data.forEach(dataset => {
            geo_requests.push({ 'url': Constant.baseapiurl_gen + 'pnp/Datasets/' + dataset.dataset_id + '/Geos', 'method': 'GET', 'body': null })
        })
        results = await this.entityCrud('PNP Dataset Geo Mappings', geo_requests, this.on_api_entity_crud_error)
        if (results.Errors === 0) {
            let all_results = results.ResultList
            var dataset_geo_mapping_pnp = {}
            for (let i = 0; i < all_results.length; i++) {
                let dataset_id = pnp_datasetsettings_data[i].dataset_id
                dataset_geo_mapping_pnp[dataset_id] = {}
                all_results[i].forEach(geo => {
                    dataset_geo_mapping_pnp[dataset_id][geo.geogid] = geo.geogdesc
                })
            }

            this.setState({ datasetgeomapping_pnp: dataset_geo_mapping_pnp })
        }

        // ------------------------------------------------------------------------------------
        // Get a geo mapping for Each PDT Dataset

        var batch_requests = []
        pdt_datasetsettings_data.forEach(dataset => {
            batch_requests.push({ 'path': '/api/pdt/Datasets/' + dataset.dataset_id + '/StoreGroups', 'method': 'GET', 'body': null })
        })
        geo_requests = [{ 'url': Constant.baseapiurl_gen + 'pdt/Batch', 'method': 'POST', 'body': batch_requests }]
        results = await this.entityCrud('PDT Dataset Geo Mappings by batch', geo_requests, this.on_api_entity_crud_error)
        if (results.Errors === 0) {
            let all_results = results.ResultList[0]
            var dataset_geo_mapping_pdt = {}
            for (let i = 0; i < all_results.length; i++) {
                let dataset_id = pdt_datasetsettings_data[i].dataset_id
                dataset_geo_mapping_pdt[dataset_id] = {}
                if (all_results[i].status_code === 200) {
                    all_results[i].body.forEach(storegroup => {
                        dataset_geo_mapping_pdt[dataset_id][storegroup.storegpid] = storegroup.storegpdesc
                    })
                }
            }

            this.setState({ datasetgeomapping_pdt: dataset_geo_mapping_pdt })
        }

        // ------------------------------------------------------------------------------------

        // finalSortCountry is used by both splitting and stacking drop downs
        let country_options = []
        country_data.forEach(country => {
            country_options.push({ 'label': country.country_shortname, 'value': country.country })
        })
        let countrysorted = country_options.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ finalSortCountry: countrysorted })

        // ------------------------------------------------------------------------------------

        this.setState({ isLoaded: true })
    }

    render() {
        console.log(this.state)
        const muiTheme = createMuiTheme({
            palette: {
                primary: {
                    main: '#4e106f',
                },
                secondary: {
                    main: '#ff8b6a',
                },
                white: {
                    main: '#FFF'
                }
            }
        })

        const handleDatasetTabChange = (event, newValue) => {
            this.setState({ datasetTabSelected: newValue })
        }

        const handleAdminTabChange = (event, newValue) => {
            this.setState({ adminTabSelected: newValue })
        }

        const handleChangeRadioApplication = async (event) => {
            var type = event.currentTarget.value
            if (type === 'admin') {
                this.setState({ adminselected: true, pnpselected: false, pdtselected: false })
                return
            }

            // Run the API to get the selected datasets for the application....
            let results = await this.entityCrud('admin page radio button selected', [
                { 'url': Constant.baseapiurl_gen + type + '/DatasetLoading/Import', 'method': 'GET', 'body': null }
            ], this.on_api_entity_crud_error)
            if (results.Errors === 0) {
                // If we are switching from PDT to PNP then make sure a tab that exists is selected
                // PNPMAXTAB: This is because PNP has only 2 tabs while PDT has 3
                if (type == 'pnp' && this.state.datasetTabSelected > 1) {
                    this.setState({ datasetTabSelected: 1 })
                }

                if (type === 'pnp') {
                    this.setState({ adminselected: false, pnpselected: true, pdtselected: false, datasetinfo_data: results.ResultList[0] })
                } else if (type === 'pdt') {
                    this.setState({ adminselected: false, pnpselected: false, pdtselected: true, datasetinfo_data: results.ResultList[0] })
                }
            }
        }

        const handleChangePage = (event, newPage, type) => {
            console.log(type)
            if (type === 'pnpdataset') {
                this.setState({ page_pnpdataset: newPage })
            } else if (type === 'pdtdataset') {
                this.setState({ page_pdtdataset: newPage })
            } else if (type === 'users') {
                this.setState({ page_users: newPage })
            } else if (type === 'companies') {
                this.setState({ page_companies: newPage })
            } else if (type === 'groups') {
                this.setState({ page_groups: newPage })
            } else {
                console.log('Unexpected value ' + type + ' for handleChangePage()')
            }
        }

        const handleChangeRowsPerPage = (event) => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10),
                page_pnpdataset: 0,
                page_pdtdataset: 0,
                page_users: 0,
                page_companies: 0,
                page_groups: 0,
            })
        }

        return (
            <ThemeProvider theme={muiTheme}>
            <div className="contentHolderGradient" style={{ height: '100%', width: '100vw', minHeight: '100vh' }} >
                <Layout title="Admin">
                    {/* API error pop-up */}
                    <ApiErrorDialog
                        state={this.state}
                        closeApiErrorDialog={this.closeApiErrorDialog}
                    />

                    {/* All PNP-related Modals */}
                    <PnpDialogs
                        state={this.state}
                        muiTheme={muiTheme}
                        hidePnpConfig={this.hidePnpConfig}
                        handleChangePnpDataSetConfigLong={this.handleChangePnpDataSetConfigLong}
                        handleChangePnpDataSetConfigShort={this.handleChangePnpDataSetConfigShort}
                        handleChangePnpDataSetConfigTablePrefix={this.handleChangePnpDataSetConfigTablePrefix}
                        handleChangePnpDataSetConfigDatasetIdentifier={this.handleChangePnpDataSetConfigDatasetIdentifier}
                        handleChangePnpDataSetConfigDatasetFolder={this.handleChangePnpDataSetConfigDatasetFolder}
                        handleChangePnpDataSetConfigIndustry={this.handleChangePnpDataSetConfigIndustry}
                        handleChangePnpConfigCheckboxEnabled={this.handleChangePnpConfigCheckboxEnabled}
                        handleSubmitPnpConfigNew={this.handleSubmitPnpConfigNew}
                        handleSubmitPnpConfig={this.handleSubmitPnpConfig}
                        handleCloseNewPnpConfig={this.handleCloseNewPnpConfig}
                        handleClosePnpDatasetInfo={this.handleClosePnpDatasetInfo}
                        closeApiLoadingSuccessful={this.closeApiLoadingSuccessful}
                        closeApiLoadingFailed={this.closeApiLoadingFailed}
                    />

                    {/* All PDT-related Modals */}
                    <PdtDialogs
                        state={this.state}
                        muiTheme={muiTheme}
                        hidePdtConfig={this.hidePdtConfig}
                        handleChangePdtDataSetConfigLong={this.handleChangePdtDataSetConfigLong}
                        handleChangePdtDataSetConfigShort={this.handleChangePdtDataSetConfigShort}
                        handleChangePdtDataSetConfigTablePrefix={this.handleChangePdtDataSetConfigTablePrefix}
                        handleChangePdtDataSetConfigAttributes={this.handleChangePdtDataSetConfigAttributes}
                        handleChangePdtDataSetConfigAttributesHuman={this.handleChangePdtDataSetConfigAttributesHuman}
                        handleChangePdtDataSetConfigGlobalAttributes={this.handleChangePdtDataSetConfigGlobalAttributes}
                        handleChangePdtDataSetConfigGlobalAttributesHuman={this.handleChangePdtDataSetConfigGlobalAttributesHuman}
                        handleChangePdtDataSetConfigCategoryLevel={this.handleChangePdtDataSetConfigCategoryLevel}
                        handleChangePdtDataSetConfigSegment1={this.handleChangePdtDataSetConfigSegment1}
                        handleChangePdtDataSetConfigSegment2={this.handleChangePdtDataSetConfigSegment2}
                        handleChangePdtDataSetConfigSegment3={this.handleChangePdtDataSetConfigSegment3}
                        handleChangePdtDataSetConfigSegment4={this.handleChangePdtDataSetConfigSegment4}
                        handleChangePdtDataSetConfigSel1List={this.handleChangePdtDataSetConfigSel1List}
                        handleChangePdtDataSetConfigSel2List={this.handleChangePdtDataSetConfigSel2List}
                        handleChangePdtDataSetConfigSel3List={this.handleChangePdtDataSetConfigSel3List}
                        handleChangePdtDataSetConfigSel4List={this.handleChangePdtDataSetConfigSel4List}
                        handleChangePdtDataSetConfigBeingModified={this.handleChangePdtDataSetConfigBeingModified}
                        handleChangePdtDataSetConfigIsInvalid={this.handleChangePdtDataSetConfigIsInvalid}
                        handleChangePdtDataSetConfigIsWholeIndustry={this.handleChangePdtDataSetConfigIsWholeIndustry}
                        handleChangePdtDataSetConfigFlags={this.handleChangePdtDataSetConfigFlags}
                        handleChangePdtDataSetConfigStoreGroups={this.handleChangePdtDataSetConfigStoreGroups}
                        handleChangePdtDataSetConfigIndustry={this.handleChangePdtDataSetConfigIndustry}
                        handleSubmitPdtConfigNew={this.handleSubmitPdtConfigNew}
                        handleClosePdtNewConfig={this.handleClosePdtNewConfig}
                        handleChangePdtDataSetConfigTimeAgg={this.handleChangePdtDataSetConfigTimeAgg}
                        handleClosePdtDatasetInfo={this.handleClosePdtDatasetInfo}
                        handleSubmitPdtConfig={this.handleSubmitPdtConfig}
                    />

                    {/* All User-related Modals */}
                    <UserDialogs
                        state={this.state}
                        muiTheme={muiTheme}
                        handleCloseUserInfo={this.handleCloseUserInfo}
                        handleCloseNewUser={this.handleCloseNewUser}
                        handleCloseUserPerms={this.handleCloseUserPerms}
                        handleCloseNewUsers={this.handleCloseNewUsers}
                        handleChangePageUser={this.handleChangePageUser}
                        handleChangeRowsPerPageUser={this.handleChangeRowsPerPageUser}
                        handleChangeUserInfoName={this.handleChangeUserInfoName}
                        handleChangeUserInfoCompany={this.handleChangeUserInfoCompany}
                        handleChangeUserInfoCheckboxEnabled={this.handleChangeUserInfoCheckboxEnabled}
                        handleChangeUserInfoEmail={this.handleChangeUserInfoEmail}
                        handleChangeUserInfoGroup={this.handleChangeUserInfoGroup}
                        handleSubmitUser={this.handleSubmitUser}
                        handleSubmitUserNew={this.handleSubmitUserNew}
                        handleDeleteUser={this.handleDeleteUser}
                        addNewUserMultiple={this.addNewUserMultiple}
                        removeUserMultiple={this.removeUserMultiple}
                        handleChangeUserInfoMultiple={this.handleChangeUserInfoMultiple}
                        submitMultipleUsers={this.submitMultipleUsers}
                        addNewUserITTickets={this.addNewUserITTickets}
                        removeUserITTickets={this.removeUserITTickets}
                        handleChangeUserInfoITTickets={this.handleChangeUserInfoITTickets}
                        handleCloseUsersITTickets={this.handleCloseUsersITTickets}
                        submitITTickets={this.submitITTickets}
                        openPdtDataSetInfo={this.openPdtDataSetInfo}
                        openPnpDataSetInfo={this.openPnpDataSetInfo}
                    />

                    {/* All Group-related Modals */}
                    <GroupDialogs
                        state={this.state}
                        muiTheme={muiTheme}
                        handleCloseGroupInfo={this.handleCloseGroupInfo}
                        handleCloseGroupInfoNew={this.handleCloseGroupInfoNew}
                        handleCloseGroupPerms={this.handleCloseGroupPerms}
                        handleCloseGroupUsers={this.handleCloseGroupUsers}
                        handleOpenNewGroup={this.handleOpenNewGroup}
                        handleChangeGroupName={this.handleChangeGroupName}
                        handleSubmitGroup={this.handleSubmitGroup}
                        handleSubmitGroupNew={this.handleSubmitGroupNew}
                        handleDeleteGroup={this.handleDeleteGroup}
                        handleChangeGroupPerms={this.handleChangeGroupPerms}
                        removeGroupPermission={this.removeGroupPermission}
                        addNewGroupPermission={this.addNewGroupPermission}
                        handleSubmitGroupChangesPerms={this.handleSubmitGroupChangesPerms}
                        handleChangeUsersCheckbox={this.handleChangeUsersCheckbox}
                        handleChangePageGroups={this.handleChangePageGroups}
                        handleChangeRowsPerPageGroups={this.handleChangeRowsPerPageGroups}
                        openPdtDataSetInfo={this.openPdtDataSetInfo}
                        openPnpDataSetInfo={this.openPnpDataSetInfo}
                    />
                    <EditGroupMemebers
                        open={this.state.showgroupusers}
                        onClose={this.handleCloseGroupUsers}
                        groupinfoName={this.state.groupinfoName}
                        group_id={this.state.groupinfodata.group_id}
                        time_updated={this.state.groupinfodata.update_time}
                        users_by_company={this.state.users_by_company}
                        groupinfoUsers={this.state.groupinfoUsers}
                        handleSubmitGroup={this.handleSubmitGroup}
                        group_membership_button_enabled={this.state.group_membership_button_enabled}
                    />

                    {/* All Company-related Modals */}
                    <CompanyDialogs
                        state={this.state}
                        muiTheme={muiTheme}
                        handleCloseCompanyInfo={this.handleCloseCompanyInfo}
                        handleCloseCompanyInfoNew={this.handleCloseCompanyInfoNew}
                        handleCloseCompanyPerms={this.handleCloseCompanyPerms}
                        handleOpenNewCompany={this.handleOpenNewCompany}
                        handleChangeCompanyName={this.handleChangeCompanyName}
                        handleChangeCompanyPerms={this.handleChangeCompanyPerms}
                        removeCompanyPermission={this.removeCompanyPermission}
                        addNewCompanyPermission={this.addNewCompanyPermission}
                        handleChangeCompanyCheckboxRetailer={this.handleChangeCompanyCheckboxRetailer}
                        handleChangeCompanyCheckboxManufacturer={this.handleChangeCompanyCheckboxManufacturer}
                        handleChangeUserInfoName={this.handleChangeUserInfoName}
                        handleChangeUserInfoCompany={this.handleChangeUserInfoCompany}
                        handleChangePageCompany={this.handleChangePageCompany}
                        handleChangeRowsPerPageCompany={this.handleChangeRowsPerPageCompany}
                        handleSubmitCompany={this.handleSubmitCompany}
                        handleSubmitCompanyNew={this.handleSubmitCompanyNew}
                        handleSubmitCompanyChangesPerms={this.handleSubmitCompanyChangesPerms}
                        handleDeleteCompany={this.handleDeleteCompany}
                        openPdtDataSetInfo={this.openPdtDataSetInfo}
                        openPnpDataSetInfo={this.openPnpDataSetInfo}
                    />

                    {/* Here is where the actual Administrator page begins */}
                    <div className="row col-sm-12 bannerfull" style={{ paddingBottom: '15px', paddingRight: '0px', paddingLeft: '0px', margin: '0px' }}>
                        <div className='col-sm-12' style={{ textAlign: 'center' }}>
                            <h1white>Administrative Page</h1white>
                        </div>
                    </div>
                    <div className='contentHolder' style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: 0 }}>
                        {(this.state.isLoaded) ? <h3>Select the component of Pricing that you wish to manage</h3>
                            : <h4>The Dataset controls will be enabled once all background data has loaded</h4>}
                        <ThemeProvider theme={muiTheme}>
                            <Radio
                                id="admin_radio"
                                checked={this.state.adminselected}
                                onChange={handleChangeRadioApplication}
                                value="admin"
                                name="adminRadio"
                                label="admin"
                                inputProps={{ 'aria-label': 'admin' }}
                            />
                            <label htmlFor="admin_radio"><span style={{ lineHeight: '5' }}>Administration</span></label>
                            <Radio
                                id="pnp_radio"
                                checked={this.state.pnpselected}
                                onChange={handleChangeRadioApplication}
                                value="pnp"
                                name="pnpRadio"
                                label="pnp"
                                disabled={!this.state.isLoaded}
                                inputProps={{ 'aria-label': 'pnp' }}
                            />
                            <label htmlFor="pnp_radio"><span style={{ lineHeight: '5' }}>Price and Promotion Datasets</span></label>
                            <Radio
                                id="pdt_radio"
                                checked={this.state.pdtselected}
                                onChange={handleChangeRadioApplication}
                                value="pdt"
                                name="pdtRadio"
                                label="pdt"
                                disabled={!this.state.isLoaded}
                                inputProps={{ 'aria-label': 'pdt' }}
                            />
                            <label htmlFor="pdt_radio"><span style={{ lineHeight: '5' }}>Price and Discount Trends Datasets</span></label>
                        </ThemeProvider>
                    </div>

                    {/* Which radio button above is selected determines what panel is visible below */}
                    <div className='contentHolder' style={{ textAlign: 'center', paddingTop: '10px' }}>
                        {(this.state.pnpselected || this.state.pdtselected) ?
                            <Fragment>
                                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }} className="">
                                    <Constant.NPDTabs
                                        value={this.state.datasetTabSelected}
                                        onChange={handleDatasetTabChange}
                                        aria-label="simple tabs example"
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        {/* If the number of tabs here changes, also modify method handleChangeRadioApplication() (search this file for "PNPMAXTAB") */}
                                        {/* PDT has 3 tabs, PNP has 2, so that method makes sure that if you switch apps, a tab is always selected */}
                                        <Constant.NPDTab label="DATASETS" {...a11yProps(0)} />
                                        {(this.state.pdtselected) ?
                                            <Constant.NPDTab label="DATASET STACKING" {...a11yProps(1)} />
                                            :
                                            <Constant.NPDTab label="DATASET LOADING" {...a11yProps(1)} />
                                        }
                                        {this.state.pdtselected &&
                                            <Constant.NPDTab label="DATASET SPLITTING" {...a11yProps(2)} />
                                        }
                                    </Constant.NPDTabs>
                                </Constant.NPDAppbar>
                                <TabPanel value={this.state.datasetTabSelected} index={0}>
                                    {this.state.pnpselected ?
                                        <div className="row" style={{ textAlign: 'left', margin: '25px' }}>
                                            <div style={{ width: "50%", margin: '0px' }}>
                                                <p>
                                                    <br />Click on the Edit Icon to view/change the configuration for the dataset
                                                    <br />Click on the Info Icon to see more details on the dataset (Brands, Geos, Attributes, etc)
                                                </p>
                                            </div>
                                            <div style={{ width: "50%", marginTop: '25px' }}>
                                                <ThemeProvider theme={muiTheme}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleOpenNewPnpConfig}
                                                    >
                                                        Add a New PNP <br/>Dataset Configuration
	                                                </Button>
                                                </ThemeProvider>
                                            </div>
                                            <Constant.NPDTable>
                                                <Constant.NPDTableBody>
                                                    <Constant.NPDTableRow key="header_pnp_datasets">
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Dataset Config ID</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'left' }}>Dataset Short Desc</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'left' }}>Dataset Long Desc</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>View/Edit Configuration</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>More Information</Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                    {this.state.datasetinfo_data.slice(this.state.page_pnpdataset * this.state.rowsPerPage, this.state.page_pnpdataset * this.state.rowsPerPage + this.state.rowsPerPage).map(dataset => {
                                                        return <Constant.NPDTableRow key={"datasetrow_" + dataset.dataset_detail_id + "_pnp"}>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{dataset.dataset_detail_id}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>{dataset.short_desc}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>{dataset.long_desc}</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                                <IconButton onClick={() => this.openEditPnpDataSetConfig(dataset.dataset_detail_id)}>
                                                                    <EditRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                                </IconButton>
                                                            </Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                                {dataset.dataset_id !== null &&
                                                                    <IconButton onClick={() => this.openPnpDataSetInfo(dataset.dataset_id)}>
                                                                        <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                                    </IconButton>
                                                                }
                                                            </Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                    })}
                                                </Constant.NPDTableBody>
                                            </Constant.NPDTable>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={this.state.datasetinfo_data.length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page_pnpdataset}
                                                onChangePage={(event, newPage, type) => handleChangePage(event, newPage, 'pnpdataset')}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </div>
                                        :
                                        this.state.pdtselected ?
                                            <div className="row" style={{ textAlign: 'left',  margin: '25px' }}>
                                                <div style={{ width: "50%", margin: '0px' }}>
                                                    <p>
                                                        <br />Click on the Edit Icon to view/change the configuration for the dataset
                                                        <br />Click on the Info Icon to see more details on the dataset (Brands, Geos, Attributes, etc)
                                                    </p>
                                                </div>
                                                <div style={{ width: "50%", marginTop: '25px' }}>
                                                    <ThemeProvider theme={muiTheme}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.handleOpenNewPdtConfig}
                                                        >
                                                            Add a New PDT <br />Dataset Configuration
	                                                </Button>
                                                    </ThemeProvider>
                                                </div>
                                                <Constant.NPDTable>
                                                    <Constant.NPDTableBody>
                                                        <Constant.NPDTableRow key="header_pdt_datasets">
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Dataset ID</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Dataset Short Desc</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Dataset Table Prefix</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'left' }}>Dataset Time Aggregation</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>View/Edit Configuration</Constant.NPDTableCell>
                                                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>More Information</Constant.NPDTableCell>
                                                        </Constant.NPDTableRow>
                                                        {this.state.datasetinfo_data.slice(this.state.page_pdtdataset * this.state.rowsPerPage, this.state.page_pdtdataset * this.state.rowsPerPage + this.state.rowsPerPage).map(dataset => {
                                                            return <Constant.NPDTableRow key={"datasetrow_" + dataset.dataset_id + "_pdt"}>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{dataset.dataset_id}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>{dataset.short_desc}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>{dataset.dataset_prefix}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>{dataset.time_aggregation}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                                    <IconButton onClick={() => this.openEditPdtDataSetConfig(dataset.dataset_id)}>
                                                                        <EditRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                                    </IconButton>
                                                                </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                                    <IconButton onClick={() => this.openPdtDataSetInfo(dataset.dataset_id)}>
                                                                        <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                                    </IconButton>
                                                                </Constant.NPDTableCell>
                                                            </Constant.NPDTableRow>
                                                        })}
                                                    </Constant.NPDTableBody>
                                                </Constant.NPDTable>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    component="div"
                                                    count={this.state.datasetinfo_data.length}
                                                    rowsPerPage={this.state.rowsPerPage}
                                                    page={this.state.page_pdtdataset}
                                                    onChangePage={(event, newPage, type) => handleChangePage(event, newPage, 'pdtdataset')}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </div>
                                            :
                                            <div className="row">
                                                Other Datasets
                                            </div>
                                    }
                                </TabPanel>
                                {this.state.pnpselected &&
                                    <TabPanel value={this.state.datasetTabSelected} index={1}>
                                        <div className='row col-sm-12'>
                                            {/* Controls for identifying PNP datasets ready to be loaded */}
                                        <div className="row col-sm-6" style={{ maxHeight: '500px', overflowY: 'auto', padding: '20px' }}>
                                            
                                            <div className="row" style={{ padding: '20px' }}>
                                               
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.identifyPNPDatasetstoLoad}
                                                        disabled={!this.state.pnp_dataset_ready_button_enabled}
                                                    >
                                                        Identify Datasets Ready to Load
                                                    </Button>
                                               
                                                   
                                                </div>
                                          
                                                {this.state.pnp_check_dataset_messages.length > 0 ?
                                                    <div className="row" style={{ padding: '20px', textAlign: 'left' }}>
                                                        {this.state.pnp_check_dataset_messages.map(message => { return <span className="col-sm-12">{message}<br /></span> })}
                                                    </div>
                                                    :
                                                    <div className="row col-sm-12" style={{ height: '500px' }}>
                                                        &nbsp;
                                                    </div>
                                                }
                                            </div>
                                            {/* Controls for loading a PNP dataset */}
                                            <div className="row col-sm-6" style={{ width: '100%', maxHeight: '500px', overflowY: 'auto', padding: '20px' }} >
                                                <div className="contentHolder" style={{ width: '100%' }} >
                                                    <p>Select a Dataset to Load</p>
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        options={this.state.pnp_import_options}
                                                        placeholder={'Select A Dataset'}
                                                    onChange={(selectedOptions) => this.handleChangeDatasetToLoad(selectedOptions)}
                                                    styles={selectColourStyles}
                                                    />
                                                    <ThemeProvider theme={Constant.muiTheme}>
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                checked={this.state.timeiddiff}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Time Difference OK?' }}
                                                                onChange={this.handleChangeTimeIDCheckboxEnabled}
                                                            />
                                                        } label='Time Difference OK?'/>
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                checked={this.state.prodiddiff}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': 'Prod ID Difference OK?' }}
                                                                onChange={this.handleChangeProdIDCheckboxEnabled}
                                                            />
                                                        } label='Prod ID Difference OK?' />
                                                    </ThemeProvider>
                                                    <div className="row" style={{ padding: '20px' }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.loadPNPDataset}
                                                            style={{ marginTop: '20px', marginBottom: '40px', marginRight: '100px' }}
                                                            disabled={this.state.pnp_loading_is_running}
                                                        >
                                                            Load Dataset
                                                        </Button>
                                                        <div className="row" style={{ padding: '20px' }}>
                                                            {this.state.showloadingbar ?
                                                                <div className="row" style={{ paddingLeft: '20px' }}>
                                                                    <div class="custom-loader"></div>
                                                                   {/* <CircularProgress color="inherit" />*/}
                                                                    <p style={{ paddingLeft: '30px' }}>Data is Loading</p>
                                                                </div>
                                                             :
                                                                <div />
                                                            }
                                                        </div>
                                                        <div className="row" style={{ padding: '20px', textAlign: 'left' }}>
                                                            {this.state.pnp_dataset_loading_messages.map(message => { return <span className="col-sm-12">{message}</span> })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                }
                                <PdtStackingDialog
                                    state={this.state}
                                    muiTheme={muiTheme}
                                    onChangePdtStackingCountry={this.onChangePdtStackingCountry}
                                    onChangePdtStackingIndustry={this.onChangePdtStackingIndustry}
                                    onChangePdtStackingEnvRadio={this.onChangePdtStackingEnvRadio}
                                    onChangePdtStackingTemplateDataset={this.onChangePdtStackingTemplateDataset}
                                    onChangePdtStackingExtraDatasets={this.onChangePdtStackingExtraDatasets}
                                    onChangePdtStackingPrefix={this.onChangePdtStackingPrefix}
                                    onChangePdtStackingShortDesc={this.onChangePdtStackingShortDesc}
                                    onChangePdtStackingLongDesc={this.onChangePdtStackingLongDesc}
                                    handleSubmitPdtStacking={this.handleSubmitPdtStacking}
                                    getTemplateDatasetList={this.getTemplateDatasetList}
                                />
                                <PdtSplittingDialog
                                    state={this.state}
                                    muiTheme={muiTheme}
                                    onChangePdtSplittingCountry={this.onChangePdtSplittingCountry}
                                    onChangePdtSplittingIndustry={this.onChangePdtSplittingIndustry}
                                    onChangePdtSplittingEnvRadio={this.onChangePdtSplittingEnvRadio}
                                    onChangePdtSplittingDataset={this.onChangePdtSplittingDataset}
                                    onChangePdtSplittingStoreGroup={this.onChangePdtSplittingStoreGroup}
                                    onChangePdtSplittingRomOutlet={this.onChangePdtSplittingRomOutlet}
                                    onChangePdtSplittingShortDesc={this.onChangePdtSplittingShortDesc}
                                    onChangePdtSplittingLongDesc={this.onChangePdtSplittingLongDesc}
                                    handleSubmitPdtSplitting={this.handleSubmitPdtSplitting}
                                    getSplittingDatasetList={this.getSplittingDatasetList}
                                    />
                            </Fragment>
                            :
                            <Fragment>
                                {/* Administration controls */}
                                <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }} className="">
                                    <Constant.NPDTabs
                                        value={this.state.adminTabSelected}
                                        onChange={handleAdminTabChange}
                                        aria-label="simple tabs example"
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Constant.NPDTab label="USERS"  {...a11yProps(0)} />
                                        <Constant.NPDTab label="COMPANIES" {...a11yProps(1)} />
                                        <Constant.NPDTab label="GROUPS" {...a11yProps(2)} />
                                        <Constant.NPDTab label="USEFUL LINKS" {...a11yProps(3)} />
                                    </Constant.NPDTabs>
                                </Constant.NPDAppbar>

                                {/* The Users Tab */}
                                <TabPanel value={this.state.adminTabSelected} index={0}>
                                    <div className="row" style={{ textAlign: 'left', margin: '25px' }}>
                                        <div style={{ width: "50%", margin: '0px' }}>
                                            <p>
                                                <br />Click on the Edit Icon to view/change the information on the user
                                                <br />Click on the Info Icon to see the permissions for the user
                                            </p>
                                        </div>
                                        <div style={{ width: "50%", marginTop: '25px' }}>
                                            <ThemeProvider theme={muiTheme}>
                                                <div className="row">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleOpenNewUser}
                                                    >
                                                        Add a New <br />User
                                                    </Button>
                                                    <div style={{ width: "50px" }} />
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={this.handleOpenNewUserMultiple}
                                                    >
                                                        Add Multiple New <br />Users
                                                    </Button>
                                                    <div style={{ width: "50px" }} />
                                                    <Button
                                                        variant="contained"
                                                        color="white"
                                                        onClick={this.handleOpenUsersITTickets}
                                                    >
                                                        Generate IT Ticket Form <br /> for User Access
                                                    </Button>
                                                </div>
                                            </ThemeProvider>
                                        </div>
                                    </div>
                                    <div>
                                        <Constant.NPDTable>
                                            <Constant.NPDTableBody>
                                                <Constant.NPDTableRow key="header_users">
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>User ID</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>User Name</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>Company</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>View/Edit User Info</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>View User Permissions</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                                {this.state.usersdata.slice(this.state.page_users * this.state.rowsPerPage, this.state.page_users * this.state.rowsPerPage + this.state.rowsPerPage).map(user => {
                                                    return <Constant.NPDTableRow key={"userrow_" + user.user_id}>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{user.user_id}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'left' }}>{user.user_name}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'left' }}>{this.state.user_company_lookup[user.user_id]}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            <IconButton onClick={() => this.getUserInfo(user.user_id)}>
                                                                <EditRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            <IconButton onClick={() => this.getUserPerms(user.user_id)}>
                                                                <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                })}
                                            </Constant.NPDTableBody>
                                        </Constant.NPDTable>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={this.state.totalusers || 0}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page_users}
                                            onChangePage={(event, newPage, type) => handleChangePage(event, newPage, 'users')}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </TabPanel>

                                {/* The Companies Tab */}
                                <TabPanel value={this.state.adminTabSelected} index={1}>
                                    <div className="row" style={{ textAlign: 'left', margin: '25px' }}>
                                        <div style={{ width: "50%", margin: '0px' }}>
                                            <p>
                                                <br />Click on the Edit Icon to view/change the information on the company
                                                <br />Click on the Info Icon to see and edit the permissions for the company
                                            </p>
                                        </div>
                                        <div style={{ width: "50%", marginTop: '25px' }}>
                                            <ThemeProvider theme={muiTheme}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleOpenNewCompany}
                                                >
                                                    Add a New <br />Company
                                                </Button>
                                            </ThemeProvider>
                                        </div>
                                    </div>
                                    <div>
                                        <Constant.NPDTable>
                                            <Constant.NPDTableBody>
                                                <Constant.NPDTableRow key="header_companies">
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Company ID</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>Company Name</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>View/Edit Company Info</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>View Company Permissions</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                                {this.state.companiesdata.slice(this.state.page_companies * this.state.rowsPerPage, this.state.page_companies * this.state.rowsPerPage + this.state.rowsPerPage).map(company => {
                                                    return <Constant.NPDTableRow key={"userrow_" + company.company_id}>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{company.company_id}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'left' }}>{company.company_name}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            <IconButton onClick={() => this.getCompanyInfo(company.company_id)}>
                                                                <EditRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            <IconButton onClick={() => this.getCompanyPerms(company.company_id)}>
                                                                <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                })}
                                            </Constant.NPDTableBody>
                                        </Constant.NPDTable>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={this.state.companiesdata.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page_companies}
                                            onChangePage={(event, newPage, type) => handleChangePage(event, newPage, 'companies')}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </TabPanel>

                                {/* The Groups Tab */}
                                <TabPanel value={this.state.adminTabSelected} index={2}>
                                    <div className="row" style={{ textAlign: 'left', margin: '25px' }}>
                                        <div style={{ width: "50%", margin: '0px' }}>
                                            <p>
                                                <br />Click on the Edit Icon to view/change the information of the group
                                                <br />Click on the Info Icon to see and edit the permissions of the group
                                                <br />Click on the User Icon to see and edit the members of the group
                                            </p>
                                        </div>
                                        <div style={{ width: "50%", marginTop: '25px' }}>
                                            <ThemeProvider theme={muiTheme}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleOpenNewGroup}
                                                >
                                                    Add a New <br />Group
                                                </Button>
                                            </ThemeProvider>
                                        </div>
                                    </div>
                                    <div>
                                        <Constant.NPDTable>
                                            <Constant.NPDTableBody>
                                                <Constant.NPDTableRow key="header_groups">
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Group ID</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>Group Name</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>View/Edit Group Info</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>View/Edit Group Permissions</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>View/Edit Group Members</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                                {this.state.groupsdata.slice(this.state.page_groups * this.state.rowsPerPage, this.state.page_groups * this.state.rowsPerPage + this.state.rowsPerPage).map(group => {
                                                    return <Constant.NPDTableRow key={"userrow_" + group.group_id}>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{group.group_id}</Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'left' }}>{group.group_name}</Constant.NPDTableCell>

                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            <IconButton onClick={() => this.getGroupInfo(group.group_id)}>
                                                                <EditRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            <IconButton onClick={() => this.getGroupPerms(group.group_id)}>
                                                                <InfoRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                            <IconButton onClick={() => this.getGroupMembers(group.group_id)}>
                                                                <GroupRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                            </IconButton>
                                                        </Constant.NPDTableCell>
                                                    </Constant.NPDTableRow>
                                                })}
                                            </Constant.NPDTableBody>
                                        </Constant.NPDTable>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={this.state.groupsdata.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page_groups}
                                            onChangePage={(event, newPage, type) => handleChangePage(event, newPage, 'groups')}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </TabPanel>

                                {/* The "Useful Links" Tab */}
                                <TabPanel value={this.state.adminTabSelected} index={3}>
                                    <div className='row' style={{ textAlign: 'left' }}>
                                        <div className='col-sm-12'>
                                            <a href='/PDT/DatasetAccess'>Price and Discount Trends Access to Datasets and Reports</a>
                                        </div>
                                        <div className='col-sm-12'>
                                            <a href='/PDT/Reports'>All Price and Discount Trends Reports</a>
                                        </div>
                                        <div className='col-sm-12'>
                                            <a href='/PNP/Reports'>All Price and Promotion Reports</a>
                                        </div>
                                        <div className='col-sm-12'>
                                            <a href='/PNP/Simulations'>All Price and Promotion Simulations</a>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Fragment>
                        }
                    </div>
                </Layout>
                </div>
            </ThemeProvider>
        )
    }
}
