import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportHelpIcons from "../images/ReportIconsHelp.jpg"
import '../styles/custom.css'
import { isNull } from 'util';
import * as Constant from '../constants'



const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paperwide: {
        position: 'absolute',
        width: 1000,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function getModalStyle() {
    const top = 25 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function getModalStyleHelp() {
    const top = 40;
    const left = 40;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}



export default function ReportToolTips() {    
    
    const classes = useStyles(); 
    const [modalStyle] = React.useState(getModalStyle);
    const [modalStyleHelp] = React.useState(getModalStyleHelp);
    const [open, setOpen] = React.useState(false);
    const [openHelp, setOpenHelp] = React.useState(false);
    const [errordisplay, seterrordisplay] = React.useState('none')
    const [availableusers, setAvailableUsers] = useState([])
    const [selectedusers, setselectedusers] = useState([])
    const [loadershare, setLoaderShare] = useState(false)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialog_deleteError, setOpenDialog_deleteError] = React.useState(false);
    const [openDialogShare, setOpenDialogShare] = React.useState(false);

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleCloseModalHelp = () => {
        setOpenHelp(false);
    };

    const shareReport = () => {

        setLoaderShare(true)

        console.log(availableusers);
        console.log(selectedusers);

        //Make response data equal to an array of the values of the Selected Options
        let userids = selectedusers.map(user => user.value)
        console.log(userids)
        fetch(Constant.baseapiurl + "ReportSettings/" + findid() +"/Sharing",
            {
                method: "PUT",
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userids)
            })
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    //console.log('ok')
                    res.text()
                    .then(
                    () => {
                        console.log('Report Shared!');
                        handleOpenDialogShare()
                        //alert('Report Successfully Shared')
                        setOpen(false)
                        setLoaderShare(false)
                    },
                        (error) => {
                            console.log(error)
                        }
                )
                }
                else {
                    console.log('There was an Error Running the API')
                }
            }
        )
        
    }

    const ChangeUsers = (event) => {
        console.log(event)
        if (isNull(event)) {
            setselectedusers([]);
            seterrordisplay('none');
            console.log(selectedusers);
        }
        else {
            setselectedusers(event);
            seterrordisplay('none');
            console.log(selectedusers);
        }
    }

    const findid = () => {
        var url = window.location.href;
        console.log(url)
        var temp = url.split("/");
        console.log(temp)
        var i = unescape(temp[5]);
        var idnum = parseInt(i, 10);
        return idnum;
    }

    const handleClick_copy =()=> {
        window.location.href = Constant.baseurl + 'PNP/copyreport/' + findid()
    }

    const handleClick_edit = () => {
        window.location.href = Constant.baseurl +'PNP/EditReport/' + findid()
    }

    const handleClick_delete = () => {
        var reportid = findid();
        console.log(reportid);
        console.log('Running Delete!')
        fetch(Constant.baseapiurl+'ReportSettings/' + reportid, { method: 'DELETE' })
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.text()
                        .then(
                            () => {
                                window.location.reload(true);
                                window.location.href = Constant.baseurl + 'PNP/Home'
                            },
                            (error) => {
                                console.log('There Was an Error' + error)
                            }
                        )                    
                }

                else if (res.status === 401 || res.status === 404) {
                    setOpenDialog(false)
                    setOpenDialog_deleteError(true)
                }

                else {
                    console.log('There was an Error Running the API')
                }
            })                
    }

    const handleClick_createsimfromrpt = () => {
        var reportid = findid();
        console.log(reportid);
        window.location.href = Constant.baseurl + 'PNP/createsimulationfromreport/' + reportid
    }

    const request_users = async () => {
        const response = await fetch(Constant.baseapiurl + "ReportSettings/" + findid() + "/SharableWith")
        const json = await response.json();
        let user_options = []
        json.map(company => company.users.map(user => user_options.push( { 'value': user.user_id, 'label': user.user_name } )))
        //let new_users = json.map(company => company.users.map(user => { return { 'value': user.user_id, 'label': user.user_name } }))
        setAvailableUsers(user_options)

        const response_shared = await fetch(Constant.baseapiurl + "ReportSettings/" + findid() + "/Sharing")
        const json_shared = await response_shared.json();
        let user_options_shared = []
        //Need a map of all users to get emails...
        let user_dict = []
        json.map(company => company.users.map(user => user_dict[user.user_id] = user.user_name))
        json_shared.map(user => user_options_shared.push({ 'value': user.user_id, 'label': user_dict[user.user_id] }))
        //let new_users = json.map(company => company.users.map(user => { return { 'value': user.user_id, 'label': user.user_name } }))
        setselectedusers(user_options_shared)
        
    }
    

    const handleClick_sharerpt = () => {
        setOpen(true);
        request_users();       
    }

    const handleClick_help = () => {
        setOpenHelp(true)
    }

    const handleClick_generatePDF = () => {
        var reportid = findid();
        console.log(reportid);
        window.location.href = Constant.baseurl + 'PNP/GeneratePDF/report/' + reportid
    }

    const handleOpenDialog = () => {
        console.log('Click!')
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCloseDialog_deleteError = () => {
        setOpenDialog_deleteError(false)
    }


    const handleOpenDialogShare = () => {        
        setOpenDialogShare(true)
    }

    const handleCloseDialogShare = () => {
        setOpenDialogShare(false)
    }
    return (
        <div className="" style={{ alignContent: 'right', paddingRight: '40px', marginRight: '10px' ,marginTop: '10px', position: 'absolute', top: '0px', right: '0vw', zIndex:'2' }}> {/*left:'72.5vw'*/}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete this Report?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete this report. This action is final and can not be undone.  Continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialog} className="btn btn-secondarynpd">
                        Cancel
                    </button>
                    <button onClick={handleClick_delete} className="btn btn-primary" autoFocus>
                        Delete Report
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialog_deleteError}
                onClose={handleCloseDialog_deleteError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Insufficient Privileges"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have insufficient privileges to delete this report
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialog_deleteError} className="btn btn-primary" autoFocus>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogShare}
                onClose={handleCloseDialogShare}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Report Shared"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Report has been Successfully Shared.
                    </DialogContentText>
                </DialogContent>   
                <DialogActions>
                    <button onClick={handleCloseDialogShare} className="btn btn-secondarynpd">
                        Ok
                    </button>
                </DialogActions>
            </Dialog>

            <Tooltip title="What do these buttons do?  Click to discover more.">
                <div className="dotsmall_hideborders">
                    <IconButton aria-label="copy" onClick={handleClick_help}  >
                        <HelpOutlineRoundedIcon style={{ color: '#4e106f' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Copy Report">
                <div className="dotsmall">
                    <IconButton aria-label="copy" onClick={handleClick_copy}  >
                        <FileCopyRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Edit Report">
                <div className="dotsmall">
                    <IconButton aria-label="edit" onClick={handleClick_edit}>
                        <EditRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>            

            <Tooltip title="Create Simulation From Report">
                <div className="dotsmall">
                    <IconButton aria-label="plan" onClick={handleClick_createsimfromrpt}>
                        <TrendingUpRoundedIcon style={{ color: '#8E908F' }} />
                        <AddRoundedIcon style={{ color: '#8E908F', top: '22px', left: '22px', position: 'absolute', fontSize:'16px' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Share Report">
                <div className="dotsmall">
                    <IconButton aria-label="share" onClick={handleClick_sharerpt}>
                        <ShareRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Download Data" >
                <div className="dotsmall">
                    <a href={Constant.baseapiurl + "ReportData/" + Constant.whereamilist[5] + "?format=csv"} download>
                        <IconButton aria-label="download">
                            <GetAppRoundedIcon style={{ color: '#8E908F' }} />                            
                        </IconButton>
                    </a>

                </div>
            </Tooltip>

            <Tooltip title="Generate PDF" >
                <div className="dotsmall">
                    <IconButton aria-label="pdf" onClick={handleClick_generatePDF}>
                        <PictureAsPdfRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title="Delete Report">
                <div className="dotsmall">
                    <IconButton aria-label="delete" onClick={handleOpenDialog}>
                        <DeleteRoundedIcon style={{ color: '#8E908F' }} />
                    </IconButton>
                </div>
            </Tooltip>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleCloseModal}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Share This Report</h2>
                    <p id="simple-modal-description">
                        Select who you would like to share this report with:
                    </p>
                    <div >
                        <Select                        
                            options={availableusers}
                            value={selectedusers}
                            placeholder={'Select Users'}
                            isMulti={true}
                            onChange={ChangeUsers}
                        >
                        </Select>
                        <ErrorRoundedIcon style={{ color: '#822433', display: errordisplay }} /><span style={{ color: '#822433', display: errordisplay  }}>Please Provide at Least one User</span>
                    </div>
                    <button
                        onClick={shareReport}
                        className="btn btn-primary"
                        style={{ margin: '10px' }}
                    >
                       Share This Report
                    </button>
                    {loadershare ? <div class="custom-loader"></div> /*<CircularProgress color="inherit" />*/ : <span />}
                </div>
            </Modal>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openHelp}
                onClose={handleCloseModalHelp}
            >
                <div style={modalStyleHelp} className={classes.paperwide}>
                    <h2 id="simple-modal-title" align="center">Report Action Buttons</h2>
                    <img src={ReportHelpIcons} alt="Help Icons"/>
                    <div ></div>
                </div>
            </Modal>

        </div>
    );
}
