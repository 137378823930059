import React, { Component } from 'react'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Checkbox, InputBase } from '@material-ui/core'
import clsx from 'clsx'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import CheckboxTree from 'react-checkbox-tree'
import 'react-dropdown-tree-select/dist/styles.css'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Datetime from 'react-datetime'
import Modal from '@mui/material/Modal'
import moment from 'moment'
import Radio from '@material-ui/core/Radio'
import Tooltip from '@material-ui/core/Tooltip'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import Paper from '@mui/material/Paper'
import TextField from '@material-ui/core/TextField'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { Layout } from '../Layout'
import * as Constant from '../constants'
import selectColourStyles from '../styles/SelectColourStyles'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import dropdownStyles from '../styles/DropdownStyles'
import "./index.css"

//Functions
//Utilities
import handleClickSave from './DataFunctions/handleClickSave'

// API CRUD requests and error handling
import { ApiErrorDialog, openApiErrorDialog, closeApiErrorDialog, on_api_entity_crud_error } from '../ApiMethods/ApiErrorDialog'
import { entityCrud } from '../ApiMethods/EntityCrud'

const findid = () => {
    var url = window.location.href
    var temp = url.split("/")
    var i = unescape(temp[5])
    var idnum = parseInt(i, 10)
    return idnum
}

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4e106f',
        },
        secondary: {
            main: '#ef2a79',
        },
        white: {
            main: '#FFF'
        }
    }
})

const CssRadio = withStyles({
    colorSecondary: {
        color: '#3f3f3f',
        '&$checked': {
            color: '#4e106f',
        },
    },
    checked: {}
})(Radio)

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        table: {
            minWidth: 650,
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#ffffff',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(78,16,111,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#4e106f',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#fe106f',
            },
        },
        InputBase: {
            border: "1px solid #4e106f"
        }
    }
})

export function StyledRadio(props) {
    const classes = useStyles()
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}

const dropdownInput = {
    width: '500px',
    height: '50px',
    borderRadius: '5px',
    padding: '10px',
    fontFamily: 'Roboto',
    lineColor: '#ffffff',
}

const dateInput = {
    width: '300px',
    height: '50px',
    borderRadius: '5px',
    fontFamily: 'Roboto',
}

const buttonStyle = {
    color: '#fff',
    backgroundColor: '#4e106f',
    borderColor: '#4e106f',
    padding: '10px',
    fontFamily: 'Roboto',
}

const buttonStyle2 = {
    color: '#fff',
    backgroundColor: '#ef2a79',
    borderColor: '#ef2a79',
    padding: '10px',
    fontFamily: 'Roboto',
    // marginLeft: '50px'
}

const buttonStyle3 = {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderColor: '#3f3f3f',
    padding: '10px',
    fontFamily: 'Roboto',
    // marginLeft: '50px'
}

const buttonStyle4 = {
    color: '#fff',
    backgroundColor: '#ff9971',
    borderColor: '#ff9971',
    padding: '10px',
    fontFamily: 'Roboto',
    // marginLeft: '50px'
}

const GreenCheckbox = withStyles({
    root: {
        color: '#4ef106f',
        '&$checked': {
            color: '#4ef106f',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

const GreenRadio = withStyles({
    root: {
        color: '#4ef106f',
        '&$checked': {
            color: '#4ef106f',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)

const onChangeTree = (currentNode, selectedNodes) => {
    console.log('onChange::', currentNode, selectedNodes)
}

const onActionTree = (node, action) => {
    console.log('onAction::', action, node)
}

const onNodeToggleTree = currentNode => {
    console.log('onNodeToggle::', currentNode)
}

const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach(k => {
        const node = obj[k]
        if (typeof node === "object") {
            node.path = stack ? `${stack}.${k}` : k
            assignObjectPaths(node, node.path)
        }
    })
}

export class EntryForm extends Component {
    static displayName = EntryForm.name

    constructor(props) {
        super(props)

        this.onChangeNewCompany = this.onChangeNewCompany.bind(this)
        this.handleClickNewCompany = this.handleClickNewCompany.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangeReportName = this.onChangeReportName.bind(this)
        this.onChangeShortDesc = this.onChangeShortDesc.bind(this)
        this.onChangeIndustry = this.onChangeIndustry.bind(this)
        this.onChangeChannelOption = this.onChangeChannelOption.bind(this)
        this.onChangeChannels = this.onChangeChannels.bind(this)
        this.onChangeCountry = this.onChangeCountry.bind(this)
        this.onChangeCatLevel = this.onChangeCatLevel.bind(this)
        this.onChangeAttributes = this.onChangeAttributes.bind(this)
        this.onChangeGlblAttribNoFilter = this.onChangeGlblAttribNoFilter.bind(this)
        // this.onChangeGlblAttributes = this.onChangeGlblAttributes.bind(this)
        this.onChangeCatSelected = this.onChangeCatSelected.bind(this)
        this.onChangeRetMan = this.onChangeRetMan.bind(this)
        // this.onChangeOutAgg = this.onChangeOutAgg.bind(this)
        this.handleClickcancel = this.handleClickcancel.bind(this)
        this.onChangeClient = this.onChangeClient.bind(this)
        this.onChangeDelFreq = this.onChangeDelFreq.bind(this)
        this.cancelExtract = this.cancelExtract.bind(this)
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        this.onChangeSelectContractId = this.onChangeSelectContractId.bind(this)
        this.onChangeRunCloud = this.onChangeRunCloud.bind(this)
        this.onChangeNoDefaults = this.onChangeNoDefaults.bind(this)
        this.onChangeChannelAgg = this.onChangeChannelAgg.bind(this)
        this.onChangeRunSAS = this.onChangeRunSAS.bind(this)
        this.onChangeSkipExtract = this.onChangeSkipExtract.bind(this)
        this.onChangeSkipCalculations = this.onChangeSkipCalculations.bind(this)
        this.onChangeResumeIfPossible = this.onChangeResumeIfPossible.bind(this)
        this.onChangeForceEuBpFile = this.onChangeForceEuBpFile.bind(this)
        this.onChangeUseDisaggregateFile = this.onChangeUseDisaggregateFile.bind(this)
        this.onChangeContainer = this.onChangeContainer.bind(this)
        this.onChangeNBrands = this.onChangeNBrands.bind(this)
        this.onChangeNItems = this.onChangeNItems.bind(this)
        this.onChangeJupServer = this.onChangeJupServer.bind(this)
        this.getLoadEtlDef = this.getLoadEtlDef.bind(this)
        this.getLoadCompanyName = this.getLoadCompanyName.bind(this)
        this.getLoadCountries = this.getLoadCountries.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.onExpand = this.onExpand.bind(this)
        this.handleClickSave = handleClickSave.bind(this)

        this.entityCrud = entityCrud.bind(this)
        this.openApiErrorDialog = openApiErrorDialog.bind(this)
        this.closeApiErrorDialog = closeApiErrorDialog.bind(this)
        this.on_api_entity_crud_error = on_api_entity_crud_error.bind(this)

        this.state = {
            aggregate_channels: false,
            alrChannel: [],
            alrchecked: false,
            attr_desc: [],
            attr_id: [],
            attr_name: [],
            attr_varname: [],
            attrSel: [],
            bucket1min: 0,
            channels: [],
            channelOptions_data: [],
            checked: [],
            contract_data: [],
            contracts: {},
            dataloadingmessages: [],
            date: new Date(),
            discpct: .10,
            discpctbands: [[0, .05], [.05, .10], [.10, .20], [.20, .30], [.30, .50], [.50, 1]],
            etl_email_address: [],
            expanded: [],
            finalSortAttr: [],
            finalSortCountry: [],
            finalSortGlblAttr: [],
            finalSortIndustry: [],
            finalSortSel1: [],
            finalSortSel2: [],
            force_eu_bp_file_text: '',
            useDisaggFile_text: '',
            //forceEuBpFile: false,
            futuredate: Datetime.moment().set({ 'year': 2021, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            futuredate_formatted: '2021-01-01',
            glbl_attr_sub_options: [],
            glbl_attribute_value_data: [],
            glbl_attribute_value_data_filter: [],
            glbl_attribute_value_data_nofilter: [],
            glblAttrChosen: [],
            glblAttrNoFilterChosen: [],
            indSelections: {},
            n_brands: null,
            n_items: null,
            openModal: false,
            pastdate: Datetime.moment().set({ 'year': 2019, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            pastdate_formatted: '2019-01-01',
            ppmonth_data: {},
            reportName: '',
            reportRadioValue: "",
            reporttype: "",
            resume_if_possible_text: '',
            resumeIfPossible: false,
            retbrandchecked: false,
            retBrandRadioValue: "",
            runCloud: false,
            runSAS: false,
            sel1_list: [],
            sel2_array: [],
            sel2_list: [],
            selectedCat: [],
            selectedCategory: [],
            selectedCategoryLevel: [],
            selectedCategoryLevelName: [],
            selectedCategoryName: [],
            selectedClient: 'Internal',
            selectedContainer: '',
            selectedContractId: [],
            selectedCountry: [],
            selectedEmailAddress: [],
            selectedend: null,
            selectedGlblAttrID: [],
            selectedNewCompanyName: '',
            selectedNumSuppOut: '',
            selectedOutlet: [],
            selectedOutlet_options: [],
            selectedOutletName: [],
            selectedOutputAgg: 'Weekly',
            selectedReportName: '',
            selectedShortDesc: '',
            selectedstart: null,
            selectedSubcategory: '',
            selectedSuppThresh: .80,
            setStartDate: new Date(),
            shortDesc: '',
            skip_calculations_text: '',
            skip_extract_text: '',
            disaggregate_output: '',
            skipCalculations: false,
            skipExtract: false,
            startDate: new Date(),
            uschecked: false,
            useDefaults: true,
            value1: true,

            //Form Errors
            num_form_errors: 0,
            report_name_missing: false,
            short_desc_missing: false,
            report_type_missing: false,
            alr_type_missing: false,
            jup_server_missing: false,
            email_missing: false,
            output_agg_missing: false,
            client_yes_no_missing: false,
            client_del_freq_missing: false,
            industry_missing: false,
            catLevel_missing: false,
            catList_missing: false,
            supp_thresh_missing: false,
            no_sel1_made: false,
            cat_size_large: false,

            indChildren: [],
            catlevellist: [],
            human_name: [],
            catLevelSel: [],
            selectedRetMan: 'Manufacturer',
            selectedAlr: 'Total US',
            selectedClient: 'Internal',
            selectedDelFreq: 'On-Demand',
            selectedIndustry: '',
            selectedIndCodeName: '',
            Selection1: '',
            selectedCompany: [],
            selectedContract: '',
            selectedCompanyName: '',
            selectedNewCompanyName: '',
            country_data: [],
            indcode: [],
            countryInd_options: [],
            hierarchy_options: [],
            attribute_options: [],
            glbl_attribute_options: [],
            selectedTimePeriod: { 'label': '2+ Years', 'value': 'twoPlusYears' },
            timePeriodOptions: [{ 'label': '2+ Years', 'value': 'twoPlusYears' }, { 'label': '3+ Years', 'value': 'threePlusYears' }],
            selectedTimePeriodType: 'twoPlusYears',

            industry_country_data: [],
            selectedBrand: [],
            selectedBrandName: [],
            //selectedChannelOption: [],
            alrBrand: [],
            country: [],
            country_options: [],
            alrBrand_options: [],
            selectedChannel: [],
            channelOptions: [],
            contract_ids: [],
            selEmail: [],
            page: 0,
            rowsPerPage: 5,
        }
    }

    handleClickcancel() {
        window.location.href = Constant.baseurl + 'PDTETL/EntryForm'
    }

    handleDateStart(date) {
        if (date.length < 10) {
            if (date.length === 0) {
                this.setState({ selectedstart: null })
            }
        } else {
            try {
                var startdate = String(date.format('YYYY-MM-DD'))
                var startdate_year = parseInt(startdate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set...
                var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month
                var startdate_day = parseInt(startdate.substring(8, 10))
                var pastdate_to_comp = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })
                //look at the value...if less than report start date, throw an error...
                if (pastdate_to_comp.isBefore(this.state.pastdate)) {
                    //Set an error
                    this.setState({ start_date_ltstart: true })
                    this.setState({ selectedstart: startdate })
                    //this.setState({ selectedstart: "invalid" })
                } else if (pastdate_to_comp.isAfter(this.state.futuredate)) {
                    this.setState({ start_date_gtend: true })
                    this.setState({ selectedstart: startdate })
                } else {
                    this.setState({ selectedstart: startdate })
                    this.setState({ start_date_ltstart: false })
                    this.setState({ start_date_gtend: false })
                }

                if (startdate && this.state.selectedend && startdate > this.state.selectedend) {
                    this.setState({ start_gt_end: true })
                } else {
                    this.setState({ start_gt_end: false })
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    handleDateEnd(date) {
        if (date.length < 10) {
            if (date.length === 0) {
                this.setState({ selectedend: null })
            }
        } else {
            try {
                var enddate = String(date.format('YYYY-MM-DD'))
                var enddate_year = parseInt(enddate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set...
                var enddate_month = parseInt(enddate.substring(5, 7)) - 1 //Zero Based Month
                var enddate_day = parseInt(enddate.substring(8, 10)) - 1
                var future_to_comp = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })
                //look at the value...if less than report start date, throw an error...
                if (future_to_comp.isBefore(this.state.pastdate)) {
                    //Set an error
                    this.setState({ end_date_ltstart: true })
                    this.setState({ selectedend: enddate })
                    //this.setState({ selectedstart: "invalid" })
                } else if (future_to_comp.isAfter(this.state.futuredate)) {
                    this.setState({ end_date_gtend: true })
                    this.setState({ selectedend: enddate })
                } else {
                    this.setState({ selectedend: enddate })
                    this.setState({ end_date_ltstart: false })
                    this.setState({ end_date_gtend: false })
                }

                if (this.state.selectedstart && enddate && this.state.selectedstart > enddate) {
                    this.setState({ start_gt_end: true })
                } else {
                    this.setState({ start_gt_end: false })
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    // onChangeOutAgg(event, action) {
    //    console.log(event.target.value)
    //    let selectedOutputAgg = event.target.value
    //    this.setState({ selectedOutputAgg, output_agg_missing: false })
    // }

    onChangeRetMan(event, action) {
        let selectedRetMan = event.target.value
        this.setState({ selectedRetMan, report_type_missing: false })
    }

    onChangeRunCloud(event, action) {
        this.setState({ runCloud: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ runSAS: false, selectedServer: '' }) //n_brands: null, n_items: null,
        }
    }

    onChangeChannelAgg(event, action) {
        this.setState({ aggregate_channels: event.target.checked })
        if (event.target.checked === true) {
            this.setState({aggregate_channels: true })
        } else {
            this.setState({ aggregate_channels: false })
        }
    }

    onChangeNoDefaults(event, action) {
        this.setState({ useDefaults: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ n_brands: null, n_items: null, useDefaults: true })
        } else {
            this.setState({useDefaults: false})
        }
    }

    onChangeRunSAS(event, action) {
        this.setState({ runSAS: event.target.checked, selectedServer: '', selectedContainer: '', useDisaggFile: false }) //n_brands: null, n_items: null,
        if (event.target.checked === true) {
            this.setState({ runCloud: false })
        }
    }

    onChangeSkipExtract(event, action) {
        this.setState({ skipExtract: event.target.checked })

        if (event.target.checked === true) {
            this.setState({ skip_extract_text: ',skip_extract' })
        } else {
            this.setState({ skip_extract_text: ' ' })
        }
    }

    onChangeSkipCalculations(event, action) {
        this.setState({ skipCalculations: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ skip_calculations_text: ',skip_calculations' })
        } else {
            this.setState({ skip_calculations_text: ' ' })
        }

    }
    onChangeResumeIfPossible(event, action) {
        this.setState({ resumeIfPossible: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ resume_if_possible_text: ',resume_if_possible' })
        } else {
            this.setState({ resume_if_possible_text: ' ' })
        }
    }

    onChangeForceEuBpFile(event, action) {
        this.setState({ forceEuBpFile: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ force_eu_bp_file_text: ',use_eu_bp_file' })
        } else {
            this.setState({ force_eu_bp_file_text: ' ' })
        }
    }

    onChangeUseDisaggregateFile(event, action) {
        this.setState({ useDisaggFile: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ useDisaggFile_text: ',disaggregate_output&container=' })
        } else {
            this.setState({ useDisaggFile_text: '', selectedContainer: '' })
        }
    }
    onChangeNItems(selNItems, action) {
        let n_items = parseInt(selNItems.target.value, 10)
        if (isNaN(n_items)) {
            this.setState({ n_items: null })
        } else {
            this.setState({ n_items: n_items })
        }
    }

    onChangeNBrands(selNBrands, action) {
        console.log(selNBrands.target.value)
        let n_brands = parseInt(selNBrands.target.value, 10)

        if (isNaN(n_brands)) {
            this.setState({ n_brands: null })
        } else {
            this.setState({ n_brands: n_brands })
        }
    }

    onChangeJupServer(selServer, action) {
        let selectedServer = selServer.value
        this.setState({ selectedServer, jup_server_missing: false })
    }

    onChangeContainer(selContainer, action) {
        let selectedContainer = selContainer.value
        this.setState({ selectedContainer })
    }

    onChangeSelectContractId(event, action) {
        let selectedContractId = event.target.value
        this.setState({ selectedContractId })
    }

    onChangeClient(event, action) {
        let selectedClient = event.target.value
        this.setState({ selectedClient, client_yes_no_missing: false })
    }

    onChangeDelFreq(event, action) {
        let selectedDelFreq = event.target.value
        this.setState({ selectedDelFreq, client_del_freq_missing: false })
    }

    OnChangeAlr = (event, action) => {
        let selectedAlr = event.target.value
        this.setState({ selectedAlr, alr_type_missing: false })
        this.onChangeOutlet([])
    }

    async onChangeCompany(selCompany, action) {
        if (selCompany) {
            let selectedCompany = selCompany.value
            let selectedCompanyName = selCompany.label
            this.setState({ selectedCompany, selectedCompanyName })

            let my_results = await this.entityCrud('getting a list of contracts by company', [
                { 'url': Constant.baseapiurl_gen + "etl/Contracts/ByCompany/" + selectedCompany, 'method': 'GET', 'body': null },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails
                var contract_data = []
            } else {
                var contract_data = my_results.ResultList[0]
            }

            this.setState({ contract_data })

            let contract_options = [{ 'label': 'Add New', 'value': -1 }]
            contract_data.forEach(contract => {
                if (contract.contract_id !== 1) {
                    contract_options.push({ 'label': contract.contract_id, 'value': ' ' })
                }
            })
            this.setState({ contracts: contract_options })
        } else {
            var selCompany = ''
            this.setState({ selectedCompany: '' })
        }
    }

    handleChangeReportTypeRadio = (event) => {
        let currvalue = this.state.reportRadioValue
        let newvalue = !currvalue
        this.setState({ reportRadioValue: newvalue })
    }

    handleChangeAggLevelRadio = (event) => {
        let currvalue = this.state.aggTypeRadioValue
        let newvalue = !currvalue
        this.setState({ aggTypeRadioValue: newvalue })
    }

    handleChangeSupp = (event) => {
        this.setState({ suppRadioValue: event.target.value })
        if (event.target.value === 'yes') {
            this.setState({ suppchecked: true })
        } else {
            this.setState({ suppchecked: false })
        }
    }

    onChangeSuppThresh(selSuppThresh, action) {
        let selectedSuppThresh = selSuppThresh.value
        this.setState({ selectedSuppThresh })
    }

    onChangeEmail2(selEmail) {
        if (selEmail) {
            var selectedEmailAddress = selEmail.map(option => option.label)
        } else {
            var selectedEmailAddress = []
        }
        this.setState({ selectedEmailAddress })
    }

    onChangeEmail(selEmail) {
        let selectedEmailAddress = selEmail.target.value.split(',')
        this.setState({ selectedEmailAddress })
    }

    onChangeReportName(selReportName, action) {
        let selectedReportName = selReportName.target.value
        this.setState({ selectedReportName })
    }

    onChangeShortDesc(selShortDesc, action) {
        let selectedShortDesc = selShortDesc.target.value
        this.setState({ selectedShortDesc })
    }

    onChangeOutletSupp(selectedOutlet, action) {
        let selectedNumSuppOut = selectedOutlet.value
        this.setState({ selectedNumSuppOut })
    }

    onChangeNewCompany(selNewName, action) {
        let selectedNewCompanyName = selNewName.target.value
        this.setState({ selectedNewCompanyName })
    }

    async handleClickNewCompany(selNewName, action) {
        //if (this.state.selectedRetMan = 'Retailer') {
        //    this.setState({
        //        is_manufacturer: false,
        //        is_retailer: true
        //    })
        // }
        //else {
        //    if (this.state.selectedRetMan = 'Manufacturer') {
        //        this.setState({
        //            is_manufacturer: true,
        //            is_retailer: false
        //        })
        //    }
        //}

        const companybody = {
            company_name: this.state.selectedNewCompanyName,
            is_manufacturer: true,
            is_retailer: false,
            pdt_permissions: [],
            pnp_permissions: []
        }

        let my_results = await this.entityCrud('creating a new company', [
            { 'url': Constant.baseapiurl_gen + "Companies", 'method': 'POST', 'body': companybody },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let result = my_results.ResultList[0]

        let companies = this.state.companies
        companies.push({ 'label': result.company_name, 'value': result.company_id })

        this.setState({
            selectedCompany: result.company_id,
            selectedCompanyName: result.company_name,
            company_name: { 'label': result.company_name, 'value': result.company_id },
            companies
        })

        const contractbody = {
            company_id: result.company_id,
            contract_end_date: this.state.selectedend,
            contract_start_date: this.state.selectedstart,
            delivery_frequency: this.state.selectedDelFreq,
            industry_id: this.state.selectedIndustry,
            manufacturer_reports: this.state.is_manufacturer,
            retailer_reports: this.state.is_retailer,
            time_aggregation: this.state.selectedOutputAgg
        }
        console.log(JSON.stringify(contractbody))

        // The Company was made successfully, so now make a contract for that company
        my_results = await this.entityCrud('creating a new contract', [
            { 'url': Constant.baseapiurl_gen + "etl/Contracts", 'method': 'POST', 'body': contractbody },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        result = my_results.ResultList[0]

        let contract_data = this.state.contract_data
        contract_data.push({
            'company_name': this.state.selectedCompanyName,
            'company_id': result.company_id,
            'contract_id': result.contract_id,
            'contract_start_date': result.contract_start_date,
            'contract_end_date': result.contract_end_date,
            'delivery_frequency': result.delivery_frequency,
            'manufacturer_reports': result.manufacturer_reports,
            'retailer_reports': result.retailer_reports,
            'time_aggregation': result.time_aggregation,
            'time_updated': result.time_updated,
            'industry_id': result.industry_id,
            'industry_name': this.state.selectedIndustry
        })

        this.setState({
            selectedContract: result.contract_id,
            contract_name: { 'label': result.contract_id, 'value': result.contract_id },
            contract_data
        })
    }

    onChangeOutlet(selectedOptions, action) {
        //console.log(selectedOptions)
        if (selectedOptions) {
            var selectedOutlet = selectedOptions.map(option => option.value)
            var selectedOutletName = selectedOptions.map(option => option.label)
        } else {
            var selectedOutlet = []
            var selectedOutletName = []
        }
        this.setState({ selectedOutlet_options: selectedOptions, selectedOutlet, selectedOutletName })
    }

    onChangeBrand(selectedOptions, action) {
        if (selectedOptions) {
            var selectedBrand = selectedOptions.map(option => option.value)
            var selectedBrandName = selectedOptions.map(option => option.label)
        } else {
            var selectedBrand = []
            var selectedBrandName = []
        }
        this.setState({ selectedBrand, selectedBrandName })
    }

    async onChangeTimePeriodOption(selTimePeriodOption, action) {
        this.setState({ selectedTimePeriod: selTimePeriodOption, selectedTimePeriodType: selTimePeriodOption.value })

        // Because we've changed the time period, fetch the new industry dates that will be pulled
        let my_results = await this.entityCrud('updating PDT ETL reporting time period', [
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryTimes/" + this.state.selectedIndustry + '?time_period_type=' + selTimePeriodOption.value, 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let ppmonth_data = my_results.ResultList[0]
        this.setState({ ppmonth_data })
    }

    async onChangeChannelOption(selChannelOption, action) {
        console.log('SelChannelOption here')
        //console.log(selChannelOption)
        if (selChannelOption) {
            var selectedChannelOptionName = selChannelOption.label
            this.setState({ selectedChannelOption: selectedChannelOptionName })
        }
    }

    onChangeAttributes(selectedOptions, action) {
        if (selectedOptions) {
            //console.log(selectedOptions)
            console.log('SELECTED ATTRIBUTES HERE')
            var selectedAttrID = selectedOptions && selectedOptions.map(option => option.value)
            var selectedAttrName = selectedOptions && selectedOptions.map(option => option.label)
            var attr_name = []
            var attr_desc = []
            var attr_varname = []
            var attr_id = []
            if (selectedAttrName.includes('Select All')) {
                selectedAttrID = this.state.attribute_options.map(option => option.value)
                selectedAttrName = this.state.attribute_options.map(option => option.label)
                this.setState({ attrChosen: this.state.attribute_options })
            } else {
                this.setState({ attrChosen: selectedOptions })
            }
        } else {
            this.setState({ attrChosen: [] })
        }

        this.setState({ attrChosen: selectedOptions, selectedAttrID, selectedAttrName })
        console.log(this.state)

        this.state.attribute_data.forEach(node => {
            if (selectedAttrID.includes(node.attr_id)) {
                let selattr_name = node.attr_name
                let selattr_desc = node.attr_desc
                let selattr_varname = node.attr_varname
                let selattr_id = node.attr_id

                attr_name.push(selattr_name)
                attr_desc.push(selattr_desc)
                attr_varname.push(selattr_varname)
                attr_id.push(selattr_id)
            }
        })

        this.setState({ attr_name, attr_id, attr_varname, attr_desc })
    }

    onChangeGlblAttribNoFilter(selectedOptions, action) {
        console.log('SELECTED OPTIONS CHANGE', selectedOptions)
        if (selectedOptions) {
            console.log('SELECTED OPTIONS FOR NO FILTER G.A.', selectedOptions)
            console.log('SELECTED Non-Filterable Global ATTRIBUTES HERE')
            var selectedGlblNFAttrID = selectedOptions && selectedOptions.map(option => option.value)
            var selectedGlblNFAttrName = selectedOptions && selectedOptions.map(option => option.label)
            if (selectedGlblNFAttrName.includes('Select All')) {
                selectedGlblNFAttrID = this.state.glbl_attribute_value_data_nofilter.map(option => option.value)
                selectedGlblNFAttrName = this.state.glbl_attribute_value_data_nofilter.map(option => option.label)
                this.setState({ glblAttrNoFilterChosen: this.state.glbl_attribute_value_data_nofilter })
            } else {
                this.setState({ glblAttrNoFilterChosen: selectedOptions })
            }
        } else {
            this.setState({ glblAttrNoFilterChosen: [] })
        }

        //this.setState({ glblAttrNoFilterChosen: selectedOptions, selectedGlblNFAttrID, selectedGlblNFAttrName })
        console.log('SELECTED GLOBAL ATTRIBUTES NO FILTERS - TOP LEVEL', this.state)
    }

    onChangeChannels(selectedOptions, action) {
        if (selectedOptions) {
            console.log(selectedOptions)
            console.log('SELECTED CHANNELS HERE')
            var selectedChannelID = selectedOptions && selectedOptions.map(option => option.value)
            var selectedChannelName = selectedOptions && selectedOptions.map(option => option.label)

            if (selectedChannelName.includes('Select All')) {
                selectedChannelID = this.state.alrChannel.map(option => option.value)
                selectedChannelName = this.state.alrChannel.map(option => option.label)
                this.setState({ channelsChosen: this.state.alrChannel, channels: selectedChannelID })
            } else {
                this.setState({ channelsChosen: selectedOptions, channels: selectedChannelID })
                console.log(selectedChannelID)
                console.log(selectedOptions)
            }
        } else {
            this.setState({ channelsChosen: [], channels: [] })
        }

        console.log(this.state)
        //this.handleChannels(selectedChannelID)
    }

    cancelExtract() {
        this.setState({
            selectedRetMan: 'Manufacturer',
            selectedIndustryName: [],
            selectedAttrName: [],
            selectedGlblAttrName: [],
            selectedOutputAgg: 'Weekly',
            countryInd_options: [],
            hierarchy_options: [],
            attribute_options: [],
            glbl_attribute_options: [],
            alrBrand_options: [],
            country_options: [],
            channelOptions_data: [],
            selectedAlr: 'Total US',
            selectedClient: '',
            selectedDelFreq: 'On-Demand',
            selectedIndustry: [],
            attr_name: '',
            selectedCategoryLevel: [],
            selectedCountry: [],
            finalSortCountry: [],
            finalSortGlblAttr: [],
            finalSortAttr: [],
            glbl_attribute_value_data_nofilter: [],
            glbl_attribute_value_data_filter: [],
            glblAttrNoFilterChosen: [],
            selectedCategory: [],
            selectedEmailAddress: [],
            channelOptions: [],
            shortDesc: '',
            reportName: '',
            selectedReportName: '',
            selectedShortDesc: '',
            selectedCompany: [],
            selectedNewCompanyName: '',
            selectedOutlet: [],
            selectedBrand: [],
            selectedBrandName: [],
            selectedAttrID: [],
            selectedGlblAttrID: [],
            selectedoptions1: null,
            selectedoptions2: null,
            selectedAttributes: null,
            selectedChannel: [],
            selEmail: [],
            industry: [],
            catlevellist: [],
            human_name: [],
            indChildren: [],
            sel1Children: [],
            attribute: [],
            alrBrand: [],
            country: [],
            alrOutlet: [],
            companies: [],
        })
    }

    async onChangeCountry(selectedOptions, action) {
        //reset fields to be blank when changing or clearing selections
        this.setState({
            attribute: [], alrBrand: [], alrOutlet: [], selectedBrandName: [], selectedBrand: [],
            cat_size_large: false,
            selectedAttrID: [], selectedAttrName: [], selectedAttributes: [], selectedOutlet: [],
            selectedCategory: [], selectedCategoryName: [], selectedCategoryLevel: [], selectedCategoryLevelName: [],
            selectedIndustry: [], selectedIndustryName: [], Catchosen: [], attr_desc: [], attr_name: [],
            attr_id: [], attr_varname: [], attr_desc: [], attr_id: [], attr_name: [], attribute_data: [],
            attr_varname: [], catLevelSel: [], hierarchy_options: [], hierarchySel: [], hierarchy_Sel: [], category_levels: [], sel1_hrlabel: [],
            sel1_label: [], sel1_list: [], sel1_name: [], attribute_options: [], catLevelSel: [], selectedCat: [], attrChosen: [], finalSortAttr: [], finalSortGlblAttr: [],
            glblAttrNoFilterChosen: []
        })
        if (Constant.entrytype === 'create') {
            this.setState({
                 selectedChannelName: [], selectedChannelID: [], channelsChosen: [], selectedChannelOption: 'No Channel', aggregate_channels: false,
                channels: []
            })
        }

        var selectedCountry = selectedOptions.value
        var selectedCountryName = selectedOptions.label
        this.setState({ selectedCountry, selectedCountryName })

        //Read in Industries from Country lookup
        let my_results = await this.entityCrud('getting industry tables by country', [
            { 'url': Constant.baseapiurl_gen + "etl/IndustryTables/ByCountry/" + selectedCountry, 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let industry_country_data = my_results.ResultList[0]
        console.log(industry_country_data)

        let countryInd_options = []

        if (Constant.entrytype === 'create') {
            industry_country_data.forEach(country => {
                if (country.is_enabled === true) {
                    countryInd_options.push({ 'label': country.industry_name, 'value': country.industry_id })
                }
            })
        } else {
            industry_country_data.forEach(country => {
                if (country.is_enabled === true) {
                    if (country.industry_id === this.state.selectedIndustryValue) {
                        countryInd_options.push({ 'label': country.industry_name, 'value': country.industry_id })
                        this.setState({ selectedIndustryName: country.industry_name, selectedIndustry: country.industry_id })
                        this.onChangeIndustry({ 'label': country.industry_name, 'value': country.industry_id }, 'Industry')
                    }
                }
            })
        }
        this.setState({ countryInd_options })
        let industrysorted = countryInd_options.sort((a, b) => a.label.localeCompare(b.label))

        let finalSortIndustry = industrysorted
        this.setState({ finalSortIndustry })
    }

    async onChangeIndustry(selectedOptions, action) {
        //reset fields to be blank when changing or clearing selections
        this.setState({
            attribute: [], alrBrand: [], alrOutlet: [], selectedBrandName: [], selectedBrand: [],
            cat_size_large: false,
            selectedAttrID: [], selectedAttrName: [], selectedAttributes: [], selectedOutlet: [],
            selectedCategory: [], selectedCategoryName: [], selectedCategoryLevel: [], selectedCategoryLevelName: [],
            Catchosen: [], attr_desc: [], attr_name: [], attribute_data: [], finalSortAttr: [], finalSortGlblAttr: [],
            attr_id: [], attr_varname: [], attr_id: [], attrChosen: [],
            attr_varname: [], catLevelSel: [], hierarchy_options: [], hierarchySel: [], hierarchy_Sel: [], category_levels: [], sel1_hrlabel: [],
            sel1_label: [], sel1_list: [], sel1_name: [], attribute_options: [], selectedCat: [],
            glblAttrNoFilterChosen: []
        })
        if (Constant.entrytype === 'create') {
            this.setState({
                selectedChannelName: [], selectedChannelID: [], channelsChosen: [], selectedChannelOption: 'No Channel', aggregate_channels: false,
                channels: []
            })
        }

        var selectedIndustry = selectedOptions.value
        var selectedIndCodeName = 'industry=' + selectedOptions.value
        var selectedIndustryName = selectedOptions.label
        this.setState({ selectedIndustry, selectedIndustryName, selectedIndCodeName })
        let sel1_list = []
        let sel1_label = []
        let sel1_hrlabel = []
        let children = []

        //Read in Industry Lookup Table (parent and children)
        let my_results = await this.entityCrud('downloading industry metadata', [
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryLookup", 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryTimes/" + selectedIndustry + '?time_period_type=' + this.state.selectedTimePeriodType, 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + "etl/IndustryTables/" + selectedIndustry + "/ChannelReportingLevel", 'method': 'GET', 'body': null },
            { 'url': Constant.baseapiurl_gen + "etl/IndustryTables/" + selectedIndustry, 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let industry_data = my_results.ResultList[0]
        let ppmonth_data = my_results.ResultList[1]
        let channelOptions_data = my_results.ResultList[2]
        let category_levels = my_results.ResultList[3].category_levels
        let forceEuBpFile = my_results.ResultList[3].eu_base_price_file

        // ------------------------------ Set the EU Base Price File Initial State ----------------------------------------
        if (forceEuBpFile === true) {
            var force_eu_bp_file_text = ",use_eu_bp_file"
        } else {
            var force_eu_bp_file_text = ''
        }

        // ------------------------------ Process the downloaded information ----------------------------------------

        let industry_options = []

        industry_data.forEach(industry => {
            let indArray = industry.value.split('=')
            let indcode = indArray[1]
            industry_options.push({ 'label': industry.label, 'value': indcode, 'orgValue': industry.value })
        })
        this.setState({ industry: industry_options, industry_data: industry_data, forceEuBpFile, force_eu_bp_file_text })

        this.state.industry_data.forEach(node => {
            if (node.value === selectedIndCodeName) {
                children = node.children
                node.children.forEach(child1 => {
                    let valueArray = child1.value.split('=')
                    let selcode = valueArray[1]
                    let sellabel = valueArray[0]
                    let selhrlabel = child1.label
                    sel1_list.push(selcode)
                    sel1_label.push(sellabel)
                    sel1_hrlabel.push(selhrlabel)
                })
            }

            //            let company_options = [{ 'label': 'Add New', 'value': -1 }]
            //           companies_data.forEach(company => {
            //                if (company.company_id !== 1) {
            //                    company_options.push({ 'label': company.company_name, 'value': company.company_id })
            //                }
            //            })
            //           this.setState({ companies: company_options })
        })

        this.setState({ sel1_list, sel1_label, sel1_name: sel1_label[0], sel1_hrlabel, indChildren: children, industry_missing: false })

        let sel1sorted = children.sort((a, b) => a.label.localeCompare(b.label))
        let finalSortSel1 = sel1sorted

        let indChildren_options = []
        sel1sorted.forEach(child => {
            indChildren_options.push({ 'label': child.label, 'value': child.value })
        })

        this.setState({
            finalSortSel1,
            level1Sel: indChildren_options
        })

        // ------------------------------ Min and Max PPMonth for selected industry ----------------------------------------

        this.setState({ ppmonth_data })

        // ------------------------------ Channel info selections for Industry ----------------------------------------

        console.log('Channel Options Pulled')
        //console.log(channelOptions_data)
        var channelOptions = []

        if (Constant.entrytype === 'create') {
            channelOptions_data.forEach(channel => {
                channelOptions.push({ 'label': channel, 'value': channel })
            })
        } else {
            var editchan = []
            channelOptions_data.forEach(channel => {
                channelOptions.push({ 'label': channel, 'value': channel })
                //console.log(this.state.editSelectedChannelOptionValue)
                //console.log(channel)
                if (this.state.editChannels.includes(channel)) {
                    editchan.push({ 'label': channel, 'value': channel })
                    this.setState({ channelOptions: editchan })
                }

                console.log('EDIT CHANNELS WORKING')
            })
        }

        this.setState({ channelOptions })
        //console.log(channelOptions)

        // ------------------------------ Industry Levels for Industry ----------------------------------------

        let catList_options = []

        if (Constant.entrytype === 'create') {
            category_levels.forEach(child => {
                catList_options.push({ 'label': child.human_name, 'value': child.variable })
            })
        } else {
            category_levels.forEach(child => {
                catList_options.push({ 'label': child.human_name, 'value': child.variable })
                if (child.human_name === this.state.selectedCatValue) {
                    this.setState({ selectedCat: { 'label': child.human_name, 'value': child.variable } })
                    this.onChangeCatLevel({ 'label': child.human_name, 'value': child.variable }, 'HierarchyLevel')
                }
            })
        }
        this.setState({
            category_levels: category_levels,
            catLevelSel: catList_options
        })
    }

    async onChangeCatLevel(selectedOptions, action) {
        //reset fields to be blank when changing or clearing selections
        this.setState({
            attribute: [], alrBrand: [], alrOutlet: [], selectedBrandName: [], selectedBrand: [],
            cat_size_large: false,
            selectedAttrID: [], selectedAttrName: [], selectedAttributes: [], selectedOutlet: [],
            attr_desc: [], attr_name: [],
            attr_id: [], attr_varname: [], attr_desc: [], attr_id: [], attr_name: [],
            attr_varname: [], attribute_data: [], finalSortAttr: [], finalSortGlblAttr: [],
            attribute_options: [], attrChosen: [],
            Catchosen: [], selectedCategory: [], selectedCategoryName: [], selectedCat: [],
            glblAttrNoFilterChosen: []
        })
        var selectedCategoryLevel = selectedOptions.value
        var selectedCategoryLevelName = selectedOptions.label
        var selectedCategory = selectedOptions.value
        var selectedCat = selectedOptions

        this.setState({ selectedCat, selectedCategory, selectedCategoryLevel, selectedCategoryLevelName })

        //Read in Category Levels from Cat Levels lookup
        let my_results = await this.entityCrud('downloading category levels', [
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryLookup/" + this.state.selectedIndustry + "?level=" + selectedCategoryLevelName, 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let hierarchy_data = my_results.ResultList[0]

        //console.log(hierarchy_data)
        let hierarchy_options = []

        if (Constant.entrytype === 'create') {
            hierarchy_data.forEach(category => {
                hierarchy_options.push({ 'label': category.name, 'value': category.selection })
            })
        } else {
            var subcats = []
            hierarchy_data.forEach(category => {
                hierarchy_options.push({ 'label': category.name, 'value': category.selection })
                if (this.state.CatchosenValue.includes(category.selection)) {
                    subcats.push({ 'label': category.name, 'value': category.selection })
                }
            })
            this.setState({ catChosen: subcats })
            this.onChangeCatSelected(subcats, 'Categories Chosen', hierarchy_options)
        }
        this.setState({ hierarchy_options })
        this.setState({ hierarchySel: hierarchy_options })

        let hierarchysorted = hierarchy_options.sort((a, b) => a.label.localeCompare(b.label))
        let finalSortHierarchy = hierarchysorted
        this.setState({ finalSortHierarchy })
    }

    async onChangeCatSelected(selectedOptions, action, hierarchy_options) {
        //reset fields to be blank when changing or clearing selections
        this.setState({
            attribute: [], alrBrand: [], alrOutlet: [], selectedBrandName: [], selectedBrand: [],
            selectedAttrID: [], selectedAttrName: [], selectedAttributes: [], selectedOutlet: [], attribute_data: [], selectedCategory: [], selectedCategoryName: [],
            attr_desc: [], attr_name: [], attr_id: [], attr_varname: [], cat_size_large: false, finalSortAttr: [], finalSortGlblAttr: [], attrChosen: [],
            glblAttrNoFilterChosen: []
        })

        var num_cat_selected = 0
        var num_cat_half = 0
        if (selectedOptions && selectedOptions.length > 0) {
            var selectedCategory = selectedOptions && selectedOptions.map(option => option.value)
            var selectedCategoryName = selectedOptions && selectedOptions.map(option => option.label)
            if (selectedCategoryName.includes('Select All')) {
                selectedCategory = this.state.hierarchySel.map(option => option.value)
                selectedCategoryName = this.state.hierarchySel.map(option => option.label)
                this.setState({ Catchosen: this.state.hierarchySel })
                num_cat_selected = this.state.hierarchySel.length
            } else {
                this.setState({ Catchosen: selectedOptions })
                num_cat_selected = (selectedOptions || []).length
                num_cat_half = hierarchy_options.length * .50 - 1 // the -1 is to subtract out the Select All option from the number of cats
            }
        } else {
            this.setState({
                // hierarchySel: [],
                cat_size_large: false,
                Catchosen: []
            })
            num_cat_selected = 0
            num_cat_half = 0
        }
        this.setState({ selectedoptions1: selectedOptions, selectedCategory, selectedCategoryName })

        // Pulling more than 50% of the industry warning
        if ((selectedOptions || []).includes('Select All') || num_cat_half <= num_cat_selected - 1) {
            this.setState({ cat_size_large: true })
            console.log('Selected categories represent more than 50% of total industry')
        } else {
            this.setState({ cat_size_large: false })
        }

        // Read in Category Levels from Cat Levels lookup no amtter what
        let my_results = await this.entityCrud('getting global attributes', [
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryGlobalAttributes/" + this.state.selectedIndustry, 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let glbl_attribute_value_data = my_results.ResultList[0]

        var glbl_attribute_list_filters = []
        var glbl_attribute_list_nofilter = []
        console.log('GLOBAL ATTRIBUTE VALUE DATA', glbl_attribute_value_data)
        glbl_attribute_value_data.forEach(object => {
            if (object['filterable']) {
                object['children'] = object['values']
                object['label'] = object['global_attr_human']
                object['id'] = object['global_attr_id']
                object['value'] = object['global_attr_id']
                object['children'].forEach(childObject => {
                    childObject['label'] = childObject['global_attr_name']
                    childObject['id'] = childObject['global_attr']
                    childObject['value'] = childObject['global_attr'] + '_' + object['global_attr_id']
                })
                glbl_attribute_list_filters.push(object)
            } else {
                object['label'] = object['global_attr_human']
                object['id'] = object['global_attr_id']
                object['value'] = object['global_attr_id']
                glbl_attribute_list_nofilter.push(object)
            }
        })

        this.setState({
            glbl_attribute_value_data: glbl_attribute_value_data,
            glbl_attribute_value_data_filter: glbl_attribute_list_filters,
            glbl_attribute_value_data_nofilter: glbl_attribute_list_nofilter
        })
        console.log(this.state)

        if (Constant.entrytype !== 'create') {
            var editchecked = []
            var editchecked_final = []
            console.log('STARTING', this.state.edit_global_attribute_filters)
            this.state.glbl_attribute_value_data_filter.forEach(object => {
                console.log('OBJECT', object)
                this.state.edit_global_attribute_ids.forEach(id => {
                    console.log('ID', id)
                    let targetvalues = []
                    this.state.edit_global_attribute_filters.forEach(attrobj => {
                        console.log('ATTROBJ', attrobj)
                        if (attrobj['global_attr_id'] === id) {
                            console.log((attrobj['global_attr_id'] === id))
                            targetvalues = attrobj['global_attr_code'] //[1203018228]
                            console.log('TARGET VALUES', targetvalues)
                        }
                    })
                    if (object['id'] === id) {
                        // console.log('COMPARING IDS', object['id'], id) //5 5 8 8
                        object['children'].forEach(childObject => {
                            //console.log('Child Object', childObject)
                            console.log(targetvalues)
                            if (targetvalues.includes(childObject['id'])) {
                                //console.log('PUSHING CHILD OBJECT VALUE INTO EDIT CHECKED WHEN MATCHED TO TARGET VALUES ')
                                editchecked.push(childObject['value'])
                                console.log('editchecked with ids match', editchecked)
                            } else {
                                editchecked.push(childObject['value'])
                                console.log('editchecked with NO ids matching', editchecked)
                            }
                            console.log(editchecked)
                        })
                    }
                })
            })
            console.log(this.state.edit_global_attribute_filters)
            var allglblattrids = this.state.edit_global_attribute_filters.map(global_attr => global_attr.global_attr_id)
            console.log(allglblattrids)
            var validattrids = this.state.edit_global_attribute_ids.map(id => {
                console.log(validattrids)
                if (!allglblattrids.includes(id)) {
                    return id
                } else {
                    return null
                }
            }).filter(Boolean)
            console.log(validattrids)

            var glblAttrNoFilterChosen = validattrids.map(id => {
                var idobject = {}
                this.state.glbl_attribute_value_data_nofilter.forEach(globalid => {
                    if (id === globalid.id) {
                        idobject['label'] = globalid.label
                        idobject['value'] = globalid.id
                    }
                })
                return idobject
            })

            console.log(glblAttrNoFilterChosen)

            editchecked.forEach(id => {
                this.state.edit_global_attribute_filters.forEach(filtered => {
                    console.log(this.state.edit_global_attribute_filters)
                    if (id === (String(filtered['global_attr_code']) + "_" + String(filtered['global_attr_id']))) {
                        editchecked_final.push(id)
                        console.log(editchecked_final) //["1203018228_5"]
                    } else {
                        //console.log('ENTERING ELSE FOR EACH EDIT CHECKED')
                        var split_filtered = id.split('_')
                        if (split_filtered[1] !== String(filtered['global_attr_id'])) {
                            console.log(split_filtered[1])
                            console.log(String(filtered['global_attr_id']))
                            console.log(split_filtered[1] !== String(filtered['global_attr_id']))
                            editchecked_final.push(id)
                            console.log(editchecked_final)
                        }
                    }
                })
            })

            var checked_final3 = []
            for (const outerFilter of this.state.glbl_attribute_value_data_filter) {
                const childrenIds = outerFilter.children.map(child => child.global_attr)
                const selected = this.state.edit_global_attribute_filters.find(x => x.global_attr_id === outerFilter.global_attr_id)
                if (selected == null) {
                    continue
                }
                if ((new Set(childrenIds)) === (new Set(selected.global_attr_code))) {
                    checked_final3.push(String(outerFilter.value))
                }
                for (const code of selected.global_attr_code) {
                    checked_final3.push(`${code}_${selected.global_attr_id}`)
                }
            }
            var editchecked_final2 = Array.from(new Set(editchecked.concat(editchecked_final)))
            this.setState({ checked: checked_final3, glblAttrNoFilterChosen })
            console.log(this.state.checked)
            console.log(editchecked_final)
        } else {
            this.setState({ checked: [] })
        }

        // If everything is removed, no more work to do; set empty arrays and return
        if ((selectedCategory || []).length == 0) {
            this.setState({
                attribute: [], alrBrand: [], alrOutlet: [], selectedBrandName: [], selectedBrand: [],
                selectedAttrID: [], selectedAttrName: [], selectedAttributes: [], selectedOutlet: [], attribute_data: [], selectedCategory: [], selectedCategoryName: [],
                attr_desc: [], attr_name: [], attr_id: [], attr_varname: [], cat_size_large: false, finalSortAttr: [], finalSortGlblAttr: [], glbl_attribute_value_data_nofilter: [],
                glbl_attribute_value_data_filter: [], glblAttrNoFilterChosen: []
            })
            return
        }

        // Else we have some levels, so get industry information for the selected levels
        let request_body = { "category_level": this.state.selectedCategoryLevelName, "category_list": selectedCategory }
        my_results = await this.entityCrud('getting global attributes', [
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryAttributes/" + this.state.selectedIndustry, 'method': 'POST', 'body': request_body },
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryOutlets/" + this.state.selectedIndustry, 'method': 'POST', 'body': request_body },
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryChannels/" + this.state.selectedIndustry, 'method': 'POST', 'body': request_body },
            { 'url': Constant.baseapiurl_gen + "etl/GetIndustryBrands/" + this.state.selectedIndustry, 'method': 'POST', 'body': request_body },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let attribute_data = my_results.ResultList[0]
        let alrOutlet_data = my_results.ResultList[1]
        let alrChannel_data = my_results.ResultList[2]
        let alrBrand_data = my_results.ResultList[3]

        // ---------------------------------------- Process category attributes ----------------------------------------

        let attribute_options = []

        if (Constant.entrytype === 'create') {
            attribute_data.forEach(attribute => {
                attribute_options.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
            })
        } else {
            var editatts = []
            attribute_data.forEach(attribute => {
                //console.log(attribute)
                attribute_options.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                if (this.state.editAttrChosen.includes(attribute.attr_id)) {
                    editatts.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                }
            })
            this.setState({ attribute_options: editatts })
            this.onChangeAttributes(editatts, 'Attributes Chosen', attribute_options)
        }

        let attrsorted = attribute_options.sort((a, b) => a.label.localeCompare(b.label))
        let finalSortAttr = attrsorted
        this.setState({ finalSortAttr })

        // ---------------------------------------- Process outlets ----------------------------------------

        let alrOutlet_options = []
        //console.log(alrOutlet_data)

        let outletname = alrOutlet_data.outlet_name
        let outlettype = alrOutlet_data.outlet
        if (Object.keys(alrOutlet_data).length > 0) {
            if (Constant.entrytype === 'create') {
                alrOutlet_data.values.forEach(outlet => {
                    alrOutlet_options.push({ 'label': outlet[outletname], 'value': outlet[outlettype] })
                })
            } else {
                var selectedOutlet_options = []
                alrOutlet_data.values.forEach(outlet => {
                    alrOutlet_options.push({ 'label': outlet[outletname], 'value': outlet[outlettype] })
                    if (this.state.editSelectedOutletValue.includes(outlet[outlettype])) {
                        selectedOutlet_options.push({ 'label': outlet[outletname], 'value': outlet[outlettype] })
                        console.log('Edit Outlets Worked')
                    }
                })
                this.setState({ alrOutlet: alrOutlet_options })
                this.onChangeOutlet(selectedOutlet_options, 'Outlets Chosen', alrOutlet_options)
            }
            this.setState({ alrOutlet: alrOutlet_options, outletname, outlettype })

            console.log('ALR Outlets Pulled!')
            //console.log(alrOutlet_data)
        }

        // ---------------------------------------- Process outlets ----------------------------------------

        let alrChannel_options = []

        if (Constant.entrytype === 'create') {
            alrChannel_data.forEach(channel => {
                alrChannel_options.push({ 'label': channel.channel_name, 'value': channel.channel })
            })
        } else {
            var editchannelvals = []
            alrChannel_data.forEach(channel => {
                alrChannel_options.push({ 'label': channel.channel_name, 'value': channel.channel })
                if (this.state.editChannels.includes(channel.channel)) {
                    editchannelvals.push({ 'label': channel.channel_name, 'value': channel.channel})
                }
            })
            this.setState({ alrChannel_options: editchannelvals })
            this.onChangeChannels(editchannelvals, 'Channels Chosen', alrChannel_options)
        }

        this.setState({ alrChannel: alrChannel_options })

        console.log('Channels Values Pulled!')
        console.log(alrChannel_data)

        // ---------------------------------------- Process ALR brands ----------------------------------------

        let alrBrand_options = []

        if (Constant.entrytype === 'create') {
            alrBrand_data.forEach(brand => {
                alrBrand_options.push({ 'label': brand.brand_name, 'value': brand.brand, 'brandsupp': brand.suppressed_brand })
            })
        } else {
            var editbrands = []
            alrBrand_data.forEach(brand => {
                alrBrand_options.push({ 'label': brand.brand_name, 'value': brand.brand, 'brandsupp': brand.suppressed_brand })
                if (this.state.editSelectedBrandValue.includes(brand.brand)) {
                    editbrands.push({ 'label': brand.brand_name, 'value': brand.brand, 'brandsupp': brand.suppressed_brand })
                }
            })
            this.setState({ alrBrand_options: editbrands })
            this.onChangeBrand(editbrands, 'Brands Chosen', alrBrand_options)
        }

        this.setState({ alrBrand: alrBrand_options })
    }

    /*
    onChangeGlblAttributes(selectedOptions, action) {
        if (selectedOptions) {
            var selectedGlblAttrIDna = selectedOptions.value
            var selectedGlblAttrID = selectedOptions && selectedOptions.map(option => option.value)
            var selectedGlblAttrName = selectedOptions && selectedOptions.map(option => option.label)
                        if (selectedGlblAttrName.includes('Select All')) {
                            //selectedGlblAttrID.includes this.state.glbl_attribute_options.map(option => option.value)
                            selectedGlblAttrName = this.state.glbl_attribute_options.map(option => option.label)
                            this.setState({ glblAttrChosen: this.state.glbl_attribute_options })
                        } else {
                           this.setState({ glblAttrChosen: selectedOptions })
                        }

            this.state.glbl_attribute_value_data.forEach(attribute => {
                if (attribute.global_attr_id === selectedGlblAttrIDna) {
                    console.log(attribute.global_attr_id)
                    console.log(selectedGlblAttrIDna)
                    this.state.glbl_attribute_value_data.forEach(node => {
                        node.values.forEach(child1 => {
                            let glbl_attr_sub_options = []
                            glbl_attr_sub_options.push({ 'label': child1.global_attr_name, 'value': child1.global_attr })
                        })
                    })
                }
            })
        } else {
            this.setState({
                glblAttrSel: [], glblAttrChosen: [], glbl_attr_sub_options: []
            })
        }
        this.setState({ selectedGlblAttributes: selectedOptions, selectedGlblAttrID, selectedGlblAttrName, selectedGlblAttrIDna })
    }
    */

    //Load the Jupyter Server Info
    getJupyterServer = async (jupyter_map) => {
        let my_results = await this.entityCrud('Getting Jupyter Server Names', [
            { 'url': Constant.baseapiurl_gen + "etl/PdtEtlExecution/GetJupyterServers", 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let jupServer = my_results.ResultList[0]

        let jupServer_options = []
        jupServer.forEach(server => {
            jupServer_options.push({ 'label': server, 'value': server })
        })

        this.setState({ listServers: jupServer_options, selectedServer: jupServer_options[0] })
    }

    //Load the User-specfic containers
    getContainer = async (container_map) => {
        console.log(Constant.baseapiurl_gen)
        console.log(Constant.baseapiurl)

        let my_results = await this.entityCrud('Getting Container Names', [
            { 'url': Constant.baseapiurl_gen + "cloud/Containers/Visible", 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let container = my_results.ResultList[0]
        console.log(my_results)
        console.log(container)

        let container_options = []
        container.forEach(container => {
            container_options.push({ 'label': container.sharing_group_name, 'value': container.blob_container_name })
        })

        //this.setState({ listContainers: container_options, selectedContainer: container_options[0] })
        this.setState({ listContainers: container_options })
    }

    //If Copy or Edit, pull in PDT ETL Info and populate the State....
    getLoadEtlDef = async (editETL_map) => {
        if (Constant.entrytype !== 'create') {
            //find the id and set to the state.
            var etl_id = findid()
            this.setState({ etlIDEdit: etl_id })

            //find the parameters of the PDT ETL and set to the state.
            let my_results = await this.entityCrud('getting the PDT ETL configuration', [
                { 'url': Constant.baseapiurl_gen + "etl/Pdt_Etl/" + etl_id, 'method': 'GET', 'body': null },
            ], this.on_api_entity_crud_error)
            if (my_results.Errors > 0) {
                // An error screen will be popped up if this fails
                return
            }

            let result = my_results.ResultList[0]

            console.log(result)
            console.log('RETRIEVE ETL FOR EDIT RESULT')
            var editAttrChosen = result.attribute_ids
            var aggregate_channels = result.aggregate_channels
            var selectedCatValue = result.category_level
            var CatchosenValue = result.category_list
            var editSelectedChannelOptionValue = result.channel_reporting_level
            var editChannels = result.channels
            var contract_ids = result.contract_ids
            var creator_id = result.creator_id
            var selectedReportName = result.etl_description
            var selectedEmailAddress = result.etl_email_address
            var selectedShortDesc = result.etl_shortname
            var editSelectedBrandValue = result.forced_brand_list
            var edit_global_attribute_ids = result.global_attribute_ids
            var edit_global_attribute_filters = result.global_attribute_filters
            var selectedIndustryValue = result.industry_id
            var is_manufacturer_var = result.is_manufacturer
            var is_retailer_var = result.is_retailer
            var selectedDelFreq = result.job_frequency
            var editSelectedOutletValue = result.outlets
            var selectedOutputAgg = result.time_aggregation
            var selectedCountryvalue = result.country
            var editTimeUpdated = result.time_updated
            var n_brands = result.n_brands
            var n_items = result.n_items
            var forceEuBpFile = result.industry_has_base_price_file
            if (forceEuBpFile === true) {
                var force_eu_bp_file_text = ",use_eu_bp_file"
            } else {
                var force_eu_bp_file_text = ''
            }
            if (is_retailer_var === true) {
                this.setState({ selectedRetMan: 'Retailer', is_retailer: true })
            } else {
                this.setState({ selectedRetMan: 'Manufacturer', is_manufacturer: true })
            }
            if ((n_items>0) || (n_brands>0)) {
                this.setState({ useDefaults: false })
            }
            if (editSelectedOutletValue.length > 0) {
                this.setState({ selectedAlr: "ALR" })
            } else {
                this.setState({ selectedAlr: "Total US" })
            }
            var selectedTimePeriodType = result.time_period_type
            if (selectedTimePeriodType == 'threePlusYears') {
                var selectedTimePeriod = { 'label': '3+ Years', 'value': 'threePlusYears' }
            } else {
                var selectedTimePeriod = { 'label': '2+ Years', 'value': 'twoPlusYears' }
            }
            console.log('Setting State for EDIT ETL ')
            console.log(result)

            this.setState({
                editAttrChosen, editTimeUpdated, selectedCatValue, CatchosenValue, selectedChannelOption: editSelectedChannelOptionValue,
                editSelectedChannelOptionValue, editChannels, contract_ids, creator_id, selectedReportName,
                selectedEmailAddress, selectedEmailAddress, selectedShortDesc, editSelectedBrandValue, selectedIndustryValue,
                is_manufacturer_var, is_retailer_var, selectedDelFreq, edit_global_attribute_filters, edit_global_attribute_ids, editSelectedOutletValue,
                selectedOutputAgg, selectedCountryvalue, n_brands, n_items, aggregate_channels, channels: editChannels, selectedTimePeriodType, selectedTimePeriod,
                forceEuBpFile, force_eu_bp_file_text
            })
            console.log(this.state)
        }
    }

    //Read in Company name
    getLoadCompanyName = async (companyname_map) => {
        let my_results = await this.entityCrud('getting a list of companies', [
            { 'url': Constant.baseapiurl_gen + "Companies", 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }

        let companies_data = my_results.ResultList[0]
        let company_options = [{ 'label': 'Add New', 'value': -1 }]
        companies_data.forEach(company => {
            if (company.company_id !== 1) {
                company_options.push({ 'label': company.company_name, 'value': company.company_id })
            }
        })
        this.setState({ companies: company_options })
    }

    //Read in Country Lookup Table
    getLoadCountries = async (countries_map) => {
        let my_results = await this.entityCrud('getting a list of countries', [
            { 'url': Constant.baseapiurl_gen + "etl/IndustryTables/Countries", 'method': 'GET', 'body': null },
        ], this.on_api_entity_crud_error)
        if (my_results.Errors > 0) {
            // An error screen will be popped up if this fails
            return
        }
        console.log(my_results)
        let country_data = my_results.ResultList[0]

        this.setState({ country_data })

        let country_options = []
        if (Constant.entrytype === 'create') {
            country_data.forEach(country => {
                country_options.push({ 'label': country.country_shortname, 'value': country.country })
            })
        } else {
            country_data.forEach(country => {
                if (country.country === this.state.selectedCountryvalue) {
                    country_options.push({ 'label': country.country_shortname, 'value': country.country })
                    this.setState({ selectedCountryName: country.country_shortname, selectedCountry: country.country })
                    this.onChangeCountry({ 'label': country.country_shortname, 'value': country.country }, 'Country')
                }
            })
        }
        this.setState({ country: country_options })

        let countrysorted = country_options.sort((a, b) => a.label.localeCompare(b.label))
        let finalSortCountry = countrysorted

        this.setState({ finalSortCountry })
    }

    async componentDidMount() {
        await this.getLoadEtlDef()
        await this.getLoadCompanyName()
        await this.getLoadCountries()
        await this.getJupyterServer()
        await this.getContainer()
        //await this.onChangeCountry()
    }

    onCheck(checked, targetNode) {
        console.log(checked, targetNode)
        this.setState({ checked })
        console.log(this.state.checked)
    }

    onExpand(expanded, targetNode) {
        console.log(expanded, targetNode)
        this.setState({ expanded })
    }

    render() {
        console.log(this.state)

        const openModalFunc = (etlid) => {
            console.log("The OpenFunc running")
            let selectedEtlIdModal = etlid
            this.setState({ selectedEtlIdModal })
            this.setState({ runCloud: false, runSAS: false, runETLSAS: false, runCalcsOnJup: false, openModal: true })
        }

        const closeModalFunc = () => {
            this.setState({ openModal: false, useDisaggFile: false, selectedContainer: '' })
        }

        const handleCloseDialog_SaveRun = () => {
            //setOpenDialog(false)
            this.setState({ setOpenSaveRun: false })
            if (Constant.entrytype === 'create') {
                window.location.href = Constant.baseurl + 'PDTETL/EntryForm'
            } else {
                window.location.href = Constant.baseurl + 'PDTETL/EntryForm'
            }
        }

        const handleCloseDialog_SaveOnly = () => {
            //setOpenDialog(false)
            this.setState({ setOpenSaveOnly: false })
            if (Constant.entrytype === 'create') {
                window.location.href = Constant.baseurl + 'PDTETL/EntryForm'
            } else {
              window.location.href = Constant.baseurl + 'PDTETL/EditExtract/' + this.state.etlIDEdit
            }
        }

        const { checked, expanded } = this.state

        const alloption = { 'label': 'Select All', 'value': '*' }
        return (
            <Layout title={'Create an Extract'}>
                <div name='banner' className='banner'>
                    <h1white align='center'>{'Price and Discount Trends Data Extraction Form'}</h1white>
                </div>
                <br />
                <div className="mainbodydiv contentHolderGradient" style={{ width: '100%', position: 'absolute', minHeight: '100vh', left: '120px', top: '210px', maxWidth: 'calc(100vw - 137px)', paddingLeft: '80px' }}>
                    {Constant.entrytype === 'create' ?
                        <h3white>{'Create a new extract'}</h3white>
                        :
                        <h3white>{'Edit Extract #' + this.state.etlIDEdit}</h3white>
                    }
                    <br />
                    <Paper elevation={2} style={{ maxWidth: '98%' }}>
                        <span className='h7' style={{ paddingLeft: '10px' }}>Report Options</span>
                        <div className="row" style={{ paddingLeft: '30px' }}>
                            {/* API error pop-up */}
                            <ApiErrorDialog
                                state={this.state}
                                closeApiErrorDialog={this.closeApiErrorDialog}
                            />

                            <Dialog
                                open={this.state.setOpenSaveRun}
                                onClose={handleCloseDialog_SaveRun}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Extract Saved and Submitted"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Your extract job has been saved and submitted.  An email will be sent when the job is complete.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <button onClick={handleCloseDialog_SaveRun} className="btn btn-secondary">
                                        Ok
                                    </button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={this.state.setOpenSaveOnly}
                                onClose={handleCloseDialog_SaveOnly}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Extract Saved"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Your extract configuration has been saved.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <button onClick={handleCloseDialog_SaveOnly} className="btn btn-secondary">
                                        Ok
                                    </button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div className='col-sm-12 row' style={{}}>
                            {Constant.entrytype === 'create' ?
                                <div className='col-sm-3' style={{ paddingLeft: '20px' }}>
                                    {this.state.report_type_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please select whether the report will be a retailer or manufacturer report<br /><br /></span> : <span></span>}
                                    <ThemeProvider theme={muiTheme}>
                                        <span className='formfieldtitle'>Is this a Retailer or Manufacturer report?<sup>*</sup></span>
                                        <FormControl component="fieldset" >
                                            <RadioGroup row aria-label="retbrnd" name="retailerbrand"
                                                onChange={this.onChangeRetMan} margin-left="20px"
                                                value={this.state.selectedRetMan} >
                                                <FormControlLabel className='radiofieldtitle' value="Retailer" disabled control={<CssRadio />} label="Retailer" />
                                                <FormControlLabel className='radiofieldtitle' value="Manufacturer" disabled control={<CssRadio />} label="Manufacturer" />
                                            </RadioGroup>
                                        </FormControl>
                                    </ThemeProvider>
                                </div>
                                :
                                <div className='col-sm-3' style={{ paddingLeft: '20px' }}>
                                    {this.state.report_type_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please select whether the report will be a retailer or manufacturer report<br /><br /></span> : <span></span>}
                                    <ThemeProvider theme={muiTheme}>
                                        <span className='formfieldtitle'>Is this a Retailer or Manufacturer report?<sup>*</sup></span>
                                        <FormControl component="fieldset" >
                                            <RadioGroup row aria-label="retbrnd" name="retailerbrand"
                                                onChange={this.onChangeRetMan} margin-left="20px"
                                                value={this.state.selectedRetMan} >
                                                <FormControlLabel className='radiofieldtitle' value="Retailer" disabled control={<CssRadio />} label="Retailer" />
                                                <FormControlLabel className='radiofieldtitle' value="Manufacturer" disabled control={<CssRadio />} label="Manufacturer" />
                                            </RadioGroup>
                                        </FormControl>
                                    </ThemeProvider>
                                </div>
                            }
                            {/*
                            <div className='col-sm-3'>
                                {this.state.output_agg_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please select how the output should be aggregated<br /><br /></span> : <span></span>}
                                <ThemeProvider theme={muiTheme}>
                                    <span className='formfieldtitle'>How should the extract output be aggregated?<sup>*</sup></span>
                                    <FormControl component="fieldset" >
                                        <RadioGroup row aria-label="aggregation" name="outputAgg" onChange={this.onChangeOutAgg} margin-left="40px" value={this.state.selectedOutputAgg}>
                                            <FormControlLabel value="Weekly" control={<GreenRadio />} label="Weekly" />
                                            <FormControlLabel value="Monthly" control={<GreenRadio />} label="Monthly" />
                                        </RadioGroup>
                                    </FormControl>
                                </ThemeProvider>
                            </div>
                            */}
                        </div>
                        <br />
                        <span className='h7' style={{ paddingLeft: '10px' }}>Product selections</span>
                        <div className='col-sm-12 row' >
                            <div className='col-sm-3' >
                                <span className='formfieldtitle'>Select a country<sup>*</sup></span>
                                <Select
                                    inputId="countrydropdown"
                                    options={this.state.finalSortCountry}
                                    width="200px"
                                    onChange={(item, action) => this.onChangeCountry(item, action)}
                                    // value={this.state.selectedCountry}
                                    value={{ 'label': this.state.selectedCountryName, 'value': this.state.selectedCountry }}
                                    isDisabled={(Constant.entrytype === 'edit') ? true : false}
                                    styles={selectColourStyles}
                                />
                            </div>
                            <div className='col-sm-3' >
                                <span className='formfieldtitle'>Select one industry<sup>*</sup></span>
                                <Select
                                    inputId="industrydropdown"
                                    options={this.state.countryInd_options}
                                    width="200px"
                                    //placeholder={'Select one Industry'}
                                    onChange={(item, action) => this.onChangeIndustry(item, action)}
                                    value={{ 'label': this.state.selectedIndustryName, 'value': this.state.selectedIndustry }}
                                    isDisabled={(Constant.entrytype === 'edit') ? true : false}
                                    styles={selectColourStyles}
                                />
                                {this.state.industry_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  You must choose an industry<br /><br /></span> : <span></span>}
                            </div>
                            <div className='col-sm-3'>
                                <span className='formfieldtitle'>{'Select a Level of the Hierarchy'}<sup>*</sup></span>
                                <Select
                                    inputId="catlevdropdown"
                                    options={this.state.catLevelSel}
                                    width="200px"
                                    styles={dropdownStyles}
                                    onChange={(item, action) => this.onChangeCatLevel(item, action)}
                                    value={this.state.selectedCat}
                                    styles={selectColourStyles}
                                />
                                {this.state.calLevel_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please make a selection<br /><br /></span> : <span></span>}
                            </div>
                            <div className='col-sm-3'>
                                <span className='formfieldtitle'>{'Select ' + (this.state.selectedCategoryLevelName || [])}<sup>*</sup></span>
                                <Select
                                    inputId="catdropdown"
                                    options={[alloption, ...this.state.hierarchy_options]}
                                    width="200px"
                                    styles={dropdownStyles}
                                    placeholder={'search by entering text here'}
                                    onChange={(item, action, hierarchy_options) => this.onChangeCatSelected(item, action, this.state.hierarchy_options)}
                                    value={this.state.Catchosen}
                                    isMulti
                                    isSearchable
                                    isClearable={true}
                                    styles={selectColourStyles}
                                />
                                {this.state.catList_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please make a selection<br /><br /></span> : <span></span>}
                                {this.state.cat_size_large ? < span style={{ color: '#f0ab00' }}><WarningRoundedIcon style={{ color: '#f0ab00' }} />  Warning! These selections represent more than 50% of the total industry, please run this on the cloud.</span> : <span></span>}
                            </div>
                        </div>
                        <br />
                        <div className='col-sm-12 row' >
                            {this.state.finalSortAttr.length > 0 ?
                                <div className='col-sm-3'>
                                    <br />
                                    <span className='formfieldtitle'>Select category attributes</span>
                                    <Select
                                        inputId="attrdropdown"
                                        options={this.state.finalSortAttr}
                                        width="200px"
                                        styles={dropdownStyles}
                                        //placeholder={'Select a maximum of 5 attributes'}
                                        onChange={(item, action) => this.onChangeAttributes(item, action)}
                                        value={this.state.attrChosen}
                                        isMulti
                                        styles={selectColourStyles}
                                    />
                                </div>
                                :
                                <div />
                            }
                            {this.state.glbl_attribute_value_data_nofilter.length > 0 ?
                                <div className='col-sm-3'>
                                    <br />
                                    <span className='formfieldtitle'>Select global attributes (industry-level) to be included in report</span>
                                    <Select
                                        inputId="glblattrnfdropdown"
                                        options={[alloption, ...this.state.glbl_attribute_value_data_nofilter]}
                                        width="200px"
                                        styles={dropdownStyles}
                                        onChange={(item, action) => this.onChangeGlblAttribNoFilter(item, action)}
                                        value={this.state.glblAttrNoFilterChosen}
                                        isMulti
                                        styles={selectColourStyles}
                                    />
                                </div>
                                :
                                <div />
                            }
                            {this.state.glbl_attribute_value_data_filter.length > 0 ?
                                <div className='col-sm-3'>
                                    <br />
                                    <span className='formfieldtitle'>Select and filter global (industry-level) attributes</span><br />
                                    <span className='formfieldtitle2'>(for entire attribute, select only top level)</span>
                                    <CheckboxTree
                                        textStyle={{ color: 'orange' }}
                                        nativeCheckboxes={true}
                                        checked={this.state.checked || []}
                                        onlyLeafCheckboxes={false}
                                        noCascade={false}
                                        checkModel={'leaf'}
                                        showNodeTitle={true}
                                        onClick={this.onClick}
                                        expanded={this.state.expanded || []}
                                        nodes={this.state.glbl_attribute_value_data_filter || []}
                                        onCheck={checked => this.setState({ checked })}
                                        onExpand={this.onExpand}
                                        nameAsArray={true}
                                        icons={{
                                            check: <span className="rct-icon rct-icon-check" style={{color: '#4e106f'}}/>,
                                            expandClose: <AddIcon style={{ color: '#4e106f' }} />,
                                            expandOpen: <RemoveIcon style={{ color: '#4e106f' }} />,
                                        }}
                                    />
                                </div>
                                :
                                <div />
                            }
                            <div className='col-sm-3'>
                                <span className='formfieldtitle'>Choose any brands that must be included in client report </span><br />
                                <span className='formfieldtitle2'>(in case they are not in the top 30)</span>
                                <Select
                                    inputId="branddropdown"
                                    options={this.state.alrBrand}
                                    width="200px"
                                    placeholder={'Select Brand(s)'}
                                    onChange={(item, action) => this.onChangeBrand(item, action)}
                                    value={this.state.selectedBrandName.map((brand, idx) => { return { 'label': brand, 'value': this.state.selectedBrand[idx] } })}
                                    isMulti
                                    styles={selectColourStyles}
                                />
                            </div>
                        </div>
                        <br />
                        <ThemeProvider theme={Constant.muiTheme}>
                            <Checkbox
                                value={this.state.useDefaults}
                                checked={this.state.useDefaults}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'Use program default values for number of items and brands' }}
                                onChange={this.onChangeNoDefaults}
                            />
                            Use program default values for number of items and brands
                        </ThemeProvider>
                        {this.state.useDefaults === false ?
                        <div>
                            <span className='formfieldtitle3' style={{ paddingLeft: '30px' }}>The number of brands and items that are displayed per lowest category level will be set using the program default.  If you need to override those numbers please enter required number below. (optional) </span>
                            <div className='col-sm-9 row' style={{paddingLeft: '30px' }}>
                                <div className='col-sm-3'>
                                     <TextField
                                            id="number"
                                            type="number"
                                        label="# of Brands"
                                        value={this.state.n_brands}
                                        onChange={this.onChangeNBrands}
                                        inputProps={{ variant: 'filled', 'aria-label': 'naked', id: "num_brands" }}
                                        />
                                </div>
                                <div> </div>
                                <div className='col-sm-3'>
                                    <TextField
                                        id="number"
                                        label="# of Items"
                                        value={this.state.n_items}
                                        onChange={this.onChangeNItems}
                                        inputProps={{ variant: 'foilled', 'aria-label': 'naked', id: "num_brands" }}
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div></div>
                        }
                        <br />
                        <span className='h7' style={{ paddingLeft: '10px' }}>Time</span>
                        <div className='col-sm-3'>
                            <Select
                                inputId="time_option_dropdown"
                                value={this.state.selectedTimePeriod}
                                options={this.state.timePeriodOptions}
                                width="200px"
                                onChange={(item, action) => this.onChangeTimePeriodOption(item, action)}
                                styles={selectColourStyles}
                            />
                        </div>
                        <p></p>
                           
                            <div className='col-sm-12'>
                                <span className='h7' >{'Analysis period: ' + (moment(this.state.ppmonth_data.start_ppmonth_date).format('MMM, YYYY') || "") + ' - ' + (moment(this.state.ppmonth_data.end_ppmonth_date).format('MMM, YYYY') || "")}</span>
                                <br />
                                <span className='h7' >{'Reporting period: ' + (moment(this.state.ppmonth_data.report_start_ppmonth_date).format('MMM, YYYY') || "") + ' - ' + (moment(this.state.ppmonth_data.end_ppmonth_date).format('MMM, YYYY') || "")}</span>
                                <br />
                            </div>
                            <br />
                        <br />
                        <span className='h7' style={{ paddingLeft: '10px' }}>Channel Reporting Level</span>
                        <div className='col-sm-3'>
                            <Select
                                inputId="channeloptionsdropdown"
                                //defaultValue={[{ 'label': this.state.selectedChannel[0], 'value': this.state.selectedChannel[0] }]}
                                value={[{ 'label': this.state.selectedChannelOption, 'value': this.state.selectedChannelOption }]}
                                options={this.state.channelOptions}
                                width="200px"
                                onChange={(item, action) => this.onChangeChannelOption(item, action)}
                                styles={selectColourStyles}
                            />
                        </div>
                        <br />
                        {this.state.selectedChannelOption === "Channel" ?
                        <div>
                            <span className='h7' style={{ paddingLeft: '10px' }}>Channel Selections</span>
                            <div className='col-sm-4'>
                                <Select
                                    //id="datasetdropdown"
                                    inputId="channeldropdown"
                                    options={[alloption, ...this.state.alrChannel]}
                                    width="200px"
                                    // value={(Constant.entrytype === 'edit') ? [{ 'label': this.state.channelsChosen.label, 'value': this.state.channelsChosen.value }]} :
                                    value={this.state.channelsChosen}
                                    placeholder={'Select Channel(s)'}
                                    onChange={(item, action) => this.onChangeChannels(item, action)}
                                    isMulti
                                />
                            </div>
                            <ThemeProvider theme={Constant.muiTheme}>
                                <Checkbox
                                    value={this.state.aggregate_channels}
                                    checked={this.state.aggregate_channels}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': 'Aggregate channels separately?' }}
                                    onChange={this.onChangeChannelAgg}
                                />
                                Aggregate the selected channels together (into one combined channel)
                            </ThemeProvider>
                        </div>
                            :
                            <div />
                        }
                        <br />
                        <span className='h7' style={{ paddingLeft: '10px' }}>Store Grouping</span>
                        <div className='col-sm-12'>
                            <div className='col-sm-8' style={{ paddingLeft: '0px' }}>
                                {this.state.report_type_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please select whether the report will be a retailer or manufacturer report<br /><br /></span> : <span></span>}
                                <ThemeProvider theme={muiTheme}>
                                    <FormControl component="fieldset" >
                                        <RadioGroup row aria-label="alr" name="alr" onChange={this.OnChangeAlr} margin-left="20px" value={this.state.selectedAlr}>
                                            <FormControlLabel className='radioalr' value="ALR" control={<CssRadio />} label={"ALR (extract will include RM and Total Measured Market - " + this.state.selectedCountryName + ")"} />
                                            <FormControlLabel className='radioalr' value="Total US" control={<CssRadio />} label={"Total Measured Market (" + this.state.selectedCountryName + " only)"} />
                                        </RadioGroup>
                                    </FormControl>
                                </ThemeProvider>
                            </div>
                        </div>
                        {this.state.selectedAlr === "ALR" ?
                            <div className='col-sm-8 '>
                                <div className='col-sm-4'>
                                </div>
                                <div className='col-sm-4'>
                                    <Select
                                        inputId="outletfamdropdown"
                                        options={this.state.alrOutlet}
                                        width="200px"
                                        placeholder={'Select Outlet Family(ies) for ALR'}
                                        onChange={(item, action) => this.onChangeOutlet(item, action)}
                                        isMulti
                                        value={this.state.selectedOutlet_options}
                                        styles={selectColourStyles}
                                    />
                                </div>
                                {/* hide for now - channel ALR will be in version 2
                                <div className='col-sm-4'>
                                    <Select
                                        //id="datasetdropdown"
                                        inputId="channeldropdown"
                                        options={this.state.channelName}
                                        width="200px"
                                        //value={this.state.datasets}
                                        placeholder={'Select Channel(s) for ALR'}
                                        onChange={(item, action) => this.onChangeChannel(item, action)}
                                        isMulti
                                        styles={selectColourStyles}
                                    />
                                </div>
                                */}
                            </div>
                            :
                            <div />
                        }
                        {/* HIDE METHODOLOGY FOR NOW - WON'T NEED UNTIL GEOS ARE INTRODUCED
                        <br />
                        <span className="h7" align='left'>{'Methodology'}</span>
                        <br />
                        <div className='col-sm-12 row'>
                            <div className='col-sm-3' style={{}}>
                                <span className='formfieldtitle'>Suppression Threshold (>=)<sup>*</sup></span>
                                <Select
                                    id="combo-box-suppthresh"
                                    options={suppThresh}
                                    //placeholder={'Select suppression threshold'}
                                    width="250px"
                                    size="small"
                                    onChange={(item, action) => this.onChangeSuppThresh(item, action)}
                                />
                            </div>
                            <div className='col-sm-3' style={{}}>
                                <span className='formfieldtitle'>Number of outlets required for suppression<sup>*</sup></span>
                                <Select
                                    id="combo-box-suppoutlets"
                                    options={suppOutlets}
                                    fontSize="8px"
                                    width="250px"
                                    //placeholder={'Select number of outlets required for suppression'}
                                    size="small"
                                    onChange={(item, action) => this.onChangeOutletSupp(item, action)}
                                />
                            </div>
                        </div>
                        <br />
                        */}
                        {/* ////////HIDE FOR NOW - buckets will be fixed
                        <span className='formfieldtitle'>Discount Threshold<sup>*</sup></span>
                        <div className='col-sm-8 row'>
                            <div className='col-sm-8' style={{}}>
                                <Select
                                    id="combo-box-discount"
                                    options={discThresh}
                                    width="400px"
                                    size="small"
                                    placeholder={'Choose the discount threshold'}
                                    onChange={(item, action) => this.onChangeDiscPercent(item, action)}
                                />
                            </div>
                        </div>
                        */}
                        
                        <br />
                        <span className='h7' style={{ paddingLeft: '10px' }}>Discount Groupings</span>
                        {(this.state.selectedCountry === "DEU" || this.state.selectedCountry === "ESP" || this.state.selectedCountry === "FRA" || this.state.selectedCountry === "GBR" || this.state.selectedCountry === "ITA") ?
                            <div>
                                <div className='col-sm-12'>
                                    <span className='formfieldtitle'>European Discount buckets will be as follows:</span>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 1</div>
                                    <div className='col-sm-6'> No Discount (0%-10%) </div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 2</div>
                                    <div className='col-sm-6'>Discount 10%-20%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 3</div>
                                    <div className='col-sm-6'>Discount 20%-25%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 4</div>
                                    <div className='col-sm-6'>Discount 25%-30%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 5</div>
                                    <div className='col-sm-6'>Discount 30%-35%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 6</div>
                                    <div className='col-sm-6'>Discount 35% - 100%</div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className='col-sm-12'>
                                    <span className='formfieldtitle'>Discount buckets will be as follows:</span>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 1</div>
                                    <div className='col-sm-6'> No Discount (0%-5%) </div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 2</div>
                                    <div className='col-sm-6'>Discount 5%-10%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 3</div>
                                    <div className='col-sm-6'>Discount 10%-20%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 4</div>
                                    <div className='col-sm-6'>Discount 20%-30%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 5</div>
                                    <div className='col-sm-6'>Discount 30%-50%</div>
                                </div>
                                <div className='col-sm-6 row'>
                                    <div className='col-sm-3'>Bucket 6</div>
                                    <div className='col-sm-6'>Discount 50% - 100%</div>
                                </div>
                            </div>
                        }
                        {/*
                         * //This code can be used later if needed for dynamic buckets with user defined thresholds
                        {this.state.discpctbands.map((band, idx) => {
                            return <div className='col-sm-6 row'>
                                <div className='col-sm-3'>Bucket {idx + 1}</div>
                                <div className='col-sm-3'> {band[0].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                                <div className='col-sm-3' style={{ backgroundColor: "#F4F4F4" }}>{band[1].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                                <div className='col-sm-3'>Discount {band[0].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })} - {band[1].toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                            </div>
                        })
                        }
                        */}
                     
                        <br />
                        <span className='h7' style={{ paddingLeft: '10px' }}>Client and Contract Information</span>
                        <div className='col-sm-12 row'>
                        </div>
                        <div className="row" style={{ paddingLeft: '30px' }}>
                            <span className='formfieldtitle'>Is this for a new/existing client or for internal use?<sup>*</sup></span>
                        </div>
                        <div className='col-sm-12 row' style={{}}>
                            <div className='col-sm-6'>
                                {this.state.client_yes_no_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please select if this extract is for internal use or will be delivered to a client<br /><br /></span> : <span></span>}
                                <ThemeProvider theme={muiTheme}>
                                    <FormControl component="fieldset" >
                                        <RadioGroup row aria-label="client" name="clientInternal" onChange={this.onChangeClient} margin-left="40px" value={this.state.selectedClient}>
                                            <FormControlLabel className='client' value="Internal" control={<CssRadio />} label="Internal" />
                                            <FormControlLabel className='client' value="Client" disabled control={<CssRadio />} label="Client" />
                                        </RadioGroup>
                                    </FormControl>
                                </ThemeProvider>
                            </div>
                        </div>
                        {this.state.selectedClient === 'Client' ?
                            <div>
                                <div className='col-sm-12' style={{ paddingLeft: '20px' }}>
                                    <span className='formfieldtitle'>Client Name<sup>*</sup></span>
                                </div>
                                <div className='col-sm-3'>
                                    <Select
                                        inputId="companydropdown"
                                        options={this.state.companies}
                                        value={this.state.company_name}
                                        placeholder={'Select a client'}
                                        onChange={(item, action) => this.onChangeCompany(item, action)}
                                        isClearable
                                        styles={selectColourStyles}
                                    />
                                </div>
                                {this.state.selectedCompany === -1 ?
                                    <div className='col-sm-12' style={{ paddingLeft: '20px' }}>
                                        <span className='formfieldtitle'>Enter client name<sup>*</sup></span>
                                        <div className='col-sm-3' style={{ backgroundColor: "#FFF", height: '50%', borderRadius: '5px', }}>
                                            <InputBase
                                                type="text"
                                                size="small"
                                                disableUnderline="true"
                                                inputProps={{ 'aria-label': 'naked', id: "newcompanyname" }}
                                                onChange={this.onChangeNewCompany}
                                                id="CompanyName"
                                                style={dropdownInput}
                                                value={this.state.selectedNewCompanyName}
                                            />
                                        </div>
                                        <div className="row" >
                                            <div style={dateInput} className='col-sm-3'>
                                                <span className='formfieldtitle'>Data Delivery Start Date<sup>*</sup></span>
                                                <Datetime
                                                    input={true}
                                                    dateFormat={"YYYY-MM-DD"}
                                                    timeFormat={false}
                                                    onChange={this.handleDateStart}
                                                    value={this.state.selectedstart}
                                                    inputProps={{ placeholder: 'YYYY-MM-DD', id: "datastartdate", autoComplete: 'off' }}
                                                />
                                            </div>
                                            <div style={dateInput} className='col-sm-3'>
                                                <span className='formfieldtitle'>Data Delivery End Date<sup>*</sup></span>
                                                <Datetime
                                                    input={true}
                                                    dateFormat={"YYYY-MM-DD"}
                                                    timeFormat={false}
                                                    onChange={this.handleDateEnd}
                                                    value={this.state.selectedend}
                                                    inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddate", autoComplete: 'off' }}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row" style={{ paddingLeft: '20px' }}>
                                            <span className='formfieldtitle'>What is the frequency of delivery to the client?<sup>*</sup></span>
                                        </div>
                                        <div className='col-sm-6'>
                                            {this.state.client_del_freq_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please select the delivery frequency to the client<br /><br /></span> : <span></span>}
                                            <ThemeProvider theme={muiTheme}>
                                                <FormControl component="fieldset" >
                                                    <RadioGroup row aria-label="frequency" name="delFrequeny" value={this.state.selectedDelFreq} onChange={this.onChangeDelFreq} margin-left="40px">
                                                        <FormControlLabel value="Monthly" control={<CssRadio />} label="Monthly" />
                                                        <FormControlLabel value="Quarterly" control={<CssRadio />} label="Quarterly" />
                                                        <FormControlLabel value="Semi-Annually" control={<CssRadio />} label="Semi-Annually" />
                                                        <FormControlLabel value="Annually" control={<CssRadio />} label="Annually" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </ThemeProvider>
                                        </div>
                                        <div>
                                            <Tooltip title="Add" arrow>
                                                <AddBoxIcon variant="contained" onClick={this.handleClickNewCompany} >
                                                </AddBoxIcon>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                                <br />
                                <div className="row" style={{ paddingLeft: '30px' }}>
                                    <span className='formfieldtitle'>Contract<sup>*</sup></span>
                                </div>
                                {/*
                                <div className='col-sm-3'>
                                    <Select
                                        //id="datasetdropdown"
                                        inputId="contractdropdown"
                                        options={this.state.contracts}
                                        //value={this.state.company_name}
                                        placeholder={'Select a contract'}
                                        onChange={(item, action) => this.onChangeContract(item, action)}
                                        isClearable
                                    />
                                </div>
                                */}
                                <div>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_groups">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Select</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Contract ID</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Company Name</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Industry Name</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Time Aggregation</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Frequency of Delivery</Constant.NPDTableCell>
                                                <Constant.NPDTableCell style={{ textAlign: 'left' }}>Last Updated</Constant.NPDTableCell>
                                            </Constant.NPDTableRow>
                                            {this.state.contract_data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(contract => {
                                                return <Constant.NPDTableRow key={"userrow_" + contract.contract_id}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                        <GreenCheckbox
                                                            onClick={this.onChangeSelectContractId}
                                                            value={contract.contract_id}
                                                        >
                                                        </GreenCheckbox>
                                                    </Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{contract.contract_id}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>{contract.company_name}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>{contract.industry_name}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>{contract.time_aggregation}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>{contract.delivery_frequency}</Constant.NPDTableCell>
                                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>{contract.time_updated}</Constant.NPDTableCell>
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                    {/*
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={this.state.contracts.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                       page={this.state.page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                    */}
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                     
                        <br />
                        <span className='h7' style={{ paddingLeft: '10px' }}>Finish and Save</span>
                        <div className="row" style={{ padding: '10px' }}>
                            <div className='col-sm-5'>
                                {this.state.email_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Email address required<br /></span> : <span></span>}
                                <span className='formfieldtitle'>Enter the email address for job notifications (use a , to separate multiple)<sup>*</sup> </span>
                                <div className="" style={{ backgroundColor: "#bfbfbf", height: '50%', borderRadius: '5px', }}>
                                    <InputBase
                                        type="text"
                                        size="small"
                                        disableUnderline="true"
                                        inputProps={{ 'aria-label': 'naked', id: "emailaddress" }}
                                        onChange={this.onChangeEmail}
                                        id="email"
                                        style={dropdownInput}
                                        styles={dropdownStyles}
                                        value={this.state.selectedEmailAddress}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: '10px' }}>
                            <div className='col-sm-5'>
                                {this.state.short_desc_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Short description must be provided<br /></span> : <span></span>}
                                <span className='formfieldtitle'>Short description which will display on client dataset dropdown (i.e. US - Tablets)<sup>* - 25 characters max</sup></span>
                                <div className="" style={{ backgroundColor: "#bfbfbf", height: '50%', borderRadius: '5px', }}>
                                    <InputBase
                                        id="custom-css-standard-input"
                                        label=""
                                        type="text"
                                        size="small"
                                        inputProps={{ variant: 'standard', 'aria-label': 'naked', id: "shortdesc", maxLength: 25 }}
                                        onChange={this.onChangeShortDesc}
                                        id="shortdesc"
                                        style={dropdownInput}
                                        value={this.state.selectedShortDesc}
                                        disabled={(Constant.entrytype === 'edit') ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: '10px' }}>
                            <div className='col-sm-5'>
                                {this.state.report_name_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Extract name must be provided<br /></span> : <span></span>}
                                <span className='formfieldtitle'>Extract Name (For internal use only and will display in saved extracts folder.  Should be more descriptive than the short name)<sup>*</sup></span>
                                <div className="" style={{backgroundColor: "#bfbfbf", height: '50%', borderRadius: '5px', }}>
                                    <InputBase
                                        id="custom-css-standard-input"
                                        label=""
                                        type="text"
                                        size="small"
                                        inputProps={{ variant: 'standard', 'aria-label': 'naked', id: "reportname" }}
                                        onChange={this.onChangeReportName}
                                        id="reportname"
                                        style={dropdownInput}
                                        value={this.state.selectedReportName}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*
                        <span className="h7" >{'Job Execution Options'}</span>
                        <div className='col-sm-7 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-3'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.runCloud}
                                        defaultChecked
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Run on Cloud?' }}
                                        onChange={this.onChangeRunCloud}
                                    />
                                        Full Run in the Cloud?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-6 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'If checked, both the extract and calculation code will run in the cloud when you click Save and Run.'}</span>
                            </div>
                        </div>
                        <div className='col-sm-7 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-3'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.runSAS}
                                        defaultChecked
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Run on on SAS?' }}
                                        onChange={this.onChangeRunSAS}
                                    />
                                        Full Run in SAS and Jupyter?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-6 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'If checked, the extract will run in SAS and the calculation code will run in Jupyter when you click Save and Run. '}</span>
                            </div>
                        </div>
                        <div className='col-sm-7 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-3'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.runETLSAS}
                                        defaultChecked
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Run on Cloud?' }}
                                        onChange={this.onChangeRunETLSAS}
                                    />
                                        Only Run Extract in SAS?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-6 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'If checked, only the extract will run (in SAS) and when you receive notification that it is complete, you will need to run the calculation code from the Extracts page'}</span>
                            </div>
                        </div>
                        {this.state.runCloud === true ?
                        <div>
                        <div className='col-sm-7 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-3'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.skipExtract}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Skip extraction (the extract must already exist or it will fail)' }}
                                        onChange={this.onChangeSkipExtract}
                                    />
                                        Skip Extract?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-6 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'Skip extraction (the extract must already exist or it will fail)'}</span>
                            </div>
                        </div>
                        <div className='col-sm-7 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-3'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.skipCalculations}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Skip calculations and go straight to post-calculation code' }}
                                        onChange={this.onChangeSkipCalculations}
                                    />
                                        Skip Calculations?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-6 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'Skip calculations and go straight to the post-calculation code'}</span>
                            </div>
                        </div>
                        <div className='col-sm-7 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-3'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.resumeIfPossible}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'For jobs going partition by partition, resume if it is possible to do so' }}
                                        onChange={this.onChangeResumeIfPossible}
                                    />
                                        Resume if Possible?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-6 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'For jobs running partition by partition that fail, resume if it is possible to do so'}</span>
                            </div>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                        */}
                        <br />
                    </Paper>
                    <br />
                    <div className='col-sm-6 row'>
                        <div style={dateInput} className='col-sm-3'>
                            <Button variant="contained" onClick={(saveOnly) => this.handleClickSave(true)} style={buttonStyle} id="runbutton">
                                Save Only
                            </Button>
                        </div>
                        <div style={dateInput} className='col-sm-3'>
                            <Button variant="contained" onClick={(etl_id) => openModalFunc((etl_id))} style={buttonStyle2} id="runbutton">
                                Save and Run
                            </Button>
                        </div>
                        {Constant.entrytype === 'create' ?
                            <div style={dateInput} className='col-sm-3'>
                                <Button variant="contained" onClick={this.handleClickcancel} style={buttonStyle3}>
                                    Clear Form
                                </Button>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                    <div className="row" style={{ padding: '20px' }}>
                    </div>
                </div>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{ overflowY: 'visible' }}
                    open={this.state.openModal}
                    onClose={closeModalFunc}
                >
                    <div style={{ paddingLeft: '20px', paddingRight: '10px', overflowY: 'visible', borderRadius: '3', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '900px', backgroundColor: '#FFF' }} >
                        <h6 id="simple-modal-title">Save and Run Options</h6>
                        <span className="h7" >{'Select Type of Run (select only one)'}</span>
                        <div className='col-sm-12 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-4'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.runCloud}
                                        checked={this.state.runCloud}
                                        //defaultChecked
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Run on Cloud?' }}
                                        onChange={this.onChangeRunCloud}
                                    />
                                    Full Run in the Cloud?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                <span className="h6" >{'If checked, the extract definition will be saved and both the extract and calculation code will run in the cloud when you click Save and Run.'}</span>
                            </div>
                        </div>
                        <div className='col-sm-12 row' style={{ paddingLeft: '10px' }}>
                            <div className='col-sm-4'>
                                <ThemeProvider theme={Constant.muiTheme}>
                                    <Checkbox
                                        value={this.state.runSAS}
                                        checked={this.state.runSAS}
                                        //defaultChecked
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'Run on on SAS?' }}
                                        onChange={this.onChangeRunSAS}
                                    />
                                    Full Run in SAS and Jupyter?
                                </ThemeProvider>
                            </div>
                            <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                <span className="h6">{'If checked, the extract definition will be saved and the extract will run in SAS and the calculation code will run in Jupyter when you click Save and Run. '}</span>
                            </div>
                        </div>
                        {(this.state.runCloud === true || this.state.runSAS === true) ?
                            <div>
                                <span className="h7" >{'Additional Options for Full Runs'}</span>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.skipExtract}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Skip extraction (the extract must already exist or it will fail)' }}
                                                onChange={this.onChangeSkipExtract}
                                            />
                                            Skip Extract?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Skip extraction (the extract must already exist or it will fail)'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.skipCalculations}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Skip calculations and go straight to post-calculation code' }}
                                                onChange={this.onChangeSkipCalculations}
                                            />
                                            Skip Calculations?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Skip calculations and go straight to the post-calculation code'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.resumeIfPossible}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'For jobs going partition by partition, resume if it is possible to do so' }}
                                                onChange={this.onChangeResumeIfPossible}
                                            />
                                            Resume if Possible?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'For jobs running partition by partition that fail, resume if it is possible to do so'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.forceEuBpFile}
                                                checked={this.state.forceEuBpFile}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'For jobs using EU industries, use the EU base price file instead of calculating base price' }}
                                                onChange={this.onChangeForceEuBpFile}
                                            />
                                            Use EU Base Price File?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'For jobs using EU industries, use the EU base price file instead of calculating base price'}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                        {(this.state.runSAS || this.state.runCalcsOnJup) ?

                            <div>
                                <span className="h7" >{'Jupyter Options'}</span>
                                <br />
                                <span className='formfieldtitle3'>Choose a Jupyter Server </span><span style={{ fontWeight: 'bold', color: 'red' }}>(*Mandatory*)</span>
                                <div className='col-sm-5' style={{ paddingTop: '10px' }}>
                                    <Select
                                        options={this.state.listServers}
                                        defaultValue={{ 'label': this.state.selectedServer[0], 'value': this.state.selectedServer[0] }}
                                        isMulti={false}
                                        onChange={(item, action) => this.onChangeJupServer(item, action)}
                                        value={{ 'label': this.state.selectedServer, 'value': this.state.selectedServer }}
                                        styles={selectColourStyles}
                                    >
                                    </Select>
                                </div>
                                <br />
                            </div>
                            :
                            <div></div>
                        }
                        {(this.state.runSAS===false && this.state.runCloud === true) ?
                            <div>
                               
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.useDisaggFile}
                                                checked={this.state.useDisaggFile}
                                                disableRipple
                                                onChange={this.onChangeUseDisaggregateFile}
                                            />
                                            Create a Disaggregate File?
                                        </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Write the results of the ETL job as a disaggregate file.  Note: You must choose a container below.'}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                                {(this.state.useDisaggFile === true && this.state.runSAS === false) ?
                                    <div>
                                        <span className='formfieldtitle3'>Select the container where the file should be saved </span><span style={{ fontWeight: 'bold', color: 'red' }}>(*Mandatory*)</span>
                                        <div className='col-sm-5' style={{ paddingTop: '10px' }}>
                                            <Select
                                                options={ this.state.listContainers }
                                                //defaultValue={{ 'label': this.state.selectedContainer[0], 'value': this.state.selectedContainer[0] }}
                                                isMulti={false}
                                                onChange={(item, action) => this.onChangeContainer(item, action)}
                                                value={{ 'label': this.state.selectedContainer, 'value': this.state.selectedContainer }}
                                                styles={selectColourStyles}
                                            >
                                            </Select>
                                        </div>
                                        <br />
                                </div>
                                        :
                                        <div></div>
                                }
                            
                        <div className='col-sm-12 row' style={{ padding: '10px' }}>
                            <div style={dateInput} className='col-sm-3'>
                                <Button variant="contained" onClick={closeModalFunc} style={buttonStyle3}>
                                    Cancel
                                </Button>
                            </div>
                            <div style={dateInput} className='col-sm-9'>
                                <Button variant="contained" onClick={(saveOnly) => this.handleClickSave(false)} style={buttonStyle}>
                                    Run
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Layout>
        )
    }
}
