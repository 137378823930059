import Datetime from 'react-datetime'
export default function handleDateStart(date) {
    if (date.length < 10) {

    }
    else {
        try {
            var startdate = String(date.format('YYYY-MM-DD'))
            var startdate_raw = String(date.format('YYYY-MM-DD'))
            //Adjust Start Date....
            var enddate_year = parseInt(this.state.selectedend.substring(0, 4))
            var enddate_month = parseInt(this.state.selectedend.substring(5, 7)) - 1 //Zero Based Month                                    
            var enddate_day = parseInt(this.state.selectedend.substring(8, 10))

            var startdate_year = parseInt(startdate.substring(0, 4))
            var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
            var startdate_day = parseInt(startdate.substring(8, 10))

            var startdate_moment = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
            var enddate_moment = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

            //Change this so we always add six days back (on the report entry we go from day after end date)
            if (this.state.dataset_data.time_aggregation !== 'Monthly' && enddate_moment.day() !== startdate_moment.day()) {
                startdate = startdate_moment.subtract(-6, 'd').format('YYYY-MM-DD')
            }

            //Check that startdate is less than end date here...
            let enddate = enddate_year + '-' + enddate_month + 1 + '-' + enddate_day
            let strtDt = new Date(startdate)
            let endDt = new Date(this.state.selectedend)
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            console.log(strtDt)
            console.log(endDt)
            if (endDt < strtDt) {
                console.log('Less Than End')
                let DateCheck_alert_text = "Start Date is Greater Than End Date.  Please Select Another Date Less Than " + endDt.toLocaleDateString(undefined, options)
                this.setState({ DateCheck_alert_text, DateCheck_alert: true })
            }

            else {
                this.setState({ selectedstart: startdate, startdate_raw });
                this.setfilters(startdate, 'startdate')
            }

        }
        catch (err) {

        }
    }

};