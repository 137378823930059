import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'

export default async function updateWeeklyChannelData(filters, timeperiod) {
    let filters_geo_weekly = assignFilterLevels(filters, ['geogdesc', 'rowdate'])
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level2selection = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level2selection, 'method': 'POST', 'body': filters_geo_weekly }
    ], 'UpdateWeeklyChannel.js updateWeeklyChannelData()', 'Weekly Channel')
    let geo_weeklydata_data = my_results['ReturnList'][0]
    let geo_weeklydata_obj = geo_weeklydata_data['level2_data'] || {}

    if (timeperiod === 'totaltimeperiod') {
        this.setState({ weekly_data_geo: geo_weeklydata_obj })
    }
    else if (timeperiod === 'threeyearsago') {
        this.setState({ weekly_data_threeyears_geo: geo_weeklydata_obj })
    }
    else if (timeperiod === 'twoyearsago') {
        this.setState({ weekly_data_last_geo: geo_weeklydata_obj })
    }
    else if (timeperiod === 'prioryear') {
        this.setState({ weekly_data_prior_geo: geo_weeklydata_obj })
    }

    return 'Completed'
}
