import React, { Component, Fragment } from 'react'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Checkbox,  Tab, Tabs  } from '@material-ui/core';
import clsx from 'clsx';
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import 'react-dropdown-tree-select/dist/styles.css'
import Datetime from 'react-datetime'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Modal from '@mui/material/Modal'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Input from '@mui/material/Input'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { Layout } from '../Layout'
import * as Constant from '../constants'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import selectColourStyles from '../styles/SelectColourStyles'
import PNPhandleClickSave from './DataFunctions/PNPhandleClickSave'

var timeOptions_data = [{
        'label': 'All available weeks of data',
        'value': 'All Weeks'
    },
    {
        'label': 'The most recent 2 years',
        'value': '108 Weeks'
    }]
var geoOptions_data = [{
        'label': 'Total Measured Market',
        'value': 'Total Measure Market'
    },
    {
        'label': 'ALR and RoM',
        'value': 'ALR and RoM'
    },
    {
        'label': 'ALR Only',
        'value': 'ALR Only'
    },
    {
        'label': 'RoM Only',
        'value': 'RoM Only'
    }]
var itemOptions_data = [{
        'label': 'PPGs',
        'value': 'PPGs'
    },
    {
        'label': 'Item List',
        'value': 'Item List'
    },
    {
        'label': 'Criteria',
        'value': 'Criteria'
    }]

const findid = () => {
    var url = window.location.href;
    var temp = url.split("/");
    var i = unescape(temp[5]);
    var idnum = parseInt(i, 10);
    return idnum;
}


const dropdownStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        maxHeight: "225px",
        overflowY: "auto"
    }),
}

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#a687b7',
        },
        white: {
            main: '#FFF'
        },
        primary2: {
            main: '#ffffff',
        },
        secondary2: {
            main: '#ef2a79',
        },
    },
    typography: {
        fontSize: 12,
    },
});

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        table: {
            minWidth: 650,
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
       
    }
});


export function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}


const dropdownInput = {
    width: '500px',
    height: '50px',
    borderRadius: '5px',
    padding: '10px',
    fontFamily: 'Roboto',
    lineColor: '#ffffff',
}

const dateInput = {
    width: '300px',
    height: '50px',
    borderRadius: '5px',
    fontFamily: 'Roboto',
}

const buttonStyle = {
    color: '#fff',
    backgroundColor: '#4e106f',
    borderColor: '#4e106f',
    padding: '10px',
    fontFamily: 'Roboto',
}

const buttonStyle2 = {
    color: '#fff',
    backgroundColor: '#ef2a79',
    borderColor: '#ef2a79',
    padding: '10px',
    fontFamily: 'Roboto',
    //   marginLeft: '50px'
}
const buttonStyle3 = {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderColor: '#3f3f3f',
    padding: '10px',
    fontFamily: 'Roboto',
}
const buttonStyle4 = {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    padding: '10px',
    fontFamily: 'Roboto',
}


const GreenRadio = withStyles({
    root: {
        color: '#4e106f',
        '&$checked': {
            color: '#4e106f',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach(k => {
        const node = obj[k];
        if (typeof node === "object") {
            node.path = stack ? `${stack}.${k}` : k;
            assignObjectPaths(node, node.path);
        }
    });
};



export class PNPEntryForm extends Component {
    static displayName = PNPEntryForm.name;
  
    constructor(props) {
        super(props);
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangeTimeSelected = this.onChangeTimeSelected.bind(this)
        this.onChangeItemType = this.onChangeItemType.bind(this)
        this.onChangePPG = this.onChangePPG.bind(this)
        this.onChangePPGFile = this.onChangePPGFile.bind(this)
        //this.onChangeCausal = this.onChangeCausal.bind(this)
        //this.onChangeCausalName = this.onChangeCausalName.bind(this)
        this.onChangeItemFile = this.onChangeItemFile.bind(this)
        this.onChangeReportName = this.onChangeReportName.bind(this)
        this.onChangeShortDesc = this.onChangeShortDesc.bind(this)
        this.onChangeAbbr = this.onChangeAbbr.bind(this)
        this.onChangeMaxItems = this.onChangeMaxItems.bind(this)
        this.onChangeCompSetThresh = this.onChangeCompSetThresh.bind(this)
        this.onChangeTopNPercent = this.onChangeTopNPercent.bind(this)
        this.onChangeGeoAbbr = this.onChangeGeoAbbr.bind(this)
        this.onChangeIndustry = this.onChangeIndustry.bind(this)
        this.onChangeChannelOption = this.onChangeChannelOption.bind(this)
        this.onChangeCountry = this.onChangeCountry.bind(this)
        this.onChangeCatLevel = this.onChangeCatLevel.bind(this)
        this.onChangeCSCatLevel = this.onChangeCSCatLevel.bind(this)
        this.onChangeAttributes = this.onChangeAttributes.bind(this)
        this.onChangeCompSetAttributes = this.onChangeCompSetAttributes.bind(this)
        this.onChangeGeoChannel = this.onChangeGeoChannel.bind(this)
        this.onChangeGeoChannelType = this.onChangeGeoChannelType.bind(this)
        this.onChangeGeoGlblAttrFilters = this.onChangeGeoGlblAttrFilters.bind(this)
        this.onChangeGlblAttributes = this.onChangeGlblAttributes.bind(this)
        this.onChangeCompSetGlblAttributes = this.onChangeCompSetGlblAttributes.bind(this)
        this.onChangeCatSelected = this.onChangeCatSelected.bind(this)
        this.handleClickSaveGeo = this.handleClickSaveGeo.bind(this)
        this.handleClickDeleteGeo = this.handleClickDeleteGeo.bind(this)
        this.handleClickAddGeo = this.handleClickAddGeo.bind(this)
        this.handleClickcancel = this.handleClickcancel.bind(this)
        this.getContainers = this.getContainers.bind(this)
        this.onChangeContainer = this.onChangeContainer.bind(this)
        
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        this.onChangeSkipExtract = this.onChangeSkipExtract.bind(this)
        this.onChangeSkipCalculations = this.onChangeSkipCalculations.bind(this)
        this.onChangeResumeIfPossible = this.onChangeResumeIfPossible.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.getLoadEtlDef = this.getLoadEtlDef.bind(this)
        this.getLoadCountries = this.getLoadCountries.bind(this)
        this.LoadGeos = this.LoadGeos.bind(this)

        


        this.PNPhandleClickSave = PNPhandleClickSave.bind(this)


        this.state =
        {
            abbr: '',
            activeStep: 0,
            alrchecked: false,
            attr_desc: [],
            attr_id: [],
            attr_name: [],
            attr_varname: [],
            attrSel: [],
            causal_filename: '',
            channelOptions_data: [],
            checked: [],
            compset_glbl_attribute_value_data: [],
            compset_price_threshold: 20,
            cs_selectedAttrID: [],
            cs_selectedGlblAttrID: [],
            selectedAttrID: [],
            contract_data: [],
            contracts: {},
            cs_glblAttrChosen: [],
            dataloadingmessages: [],
            date: new Date(),
            etl_email_address: [],
            finalSortAttr: [],
            finalSortCompSetAttr: [],
            finalSortCountry: [],
            finalSortGlblAttr: [],
            finalSortIndustry: [],
            finalSortSel1: [],
            finalSortSel2: [],
            geo_abbr: '',
            geo_global_attr_filters: [],
            geo_selectedOutlet: 0,
            geoNames_id: 0,
            glbl_attr_sub_options: [],
            glbl_attribute_value_data: [],
            glbl_attribute_value_data_filter: [],
            glblAttrChosen: [],
            global_attr_filters: [],
            indSelections: {},
            item_type: '',
            itemFileName: "",
            item_selection: {},
            max_item_count: null,

            ppgName: "",
            reportName: '',
            reportRadioValue: "",
            reporttype: "",
            resume_if_possible_text: '',
            resumeIfPossible: false,
            retbrandchecked: false,
            retBrandRadioValue: "",
            runCloud: true,
            sel1_list: [],
            sel2_array: [],
            sel2_list: [],
            selectedAbbr: '',
            selectedCat: [],
            selectedCategory: [],
            selectedCategoryLevel: [],
            selectedCategoryLevelName: [],
            selectedCategoryName: [],
            selectedCSCatName: "",
            selectedCausal: 'No',
            selectedClient: 'Internal',
            selectedCompSetThresh: null,
            selectedContractId: [],
            selectedCountry: [],
            selectedEmailAddress: [],
            selectedend: null,
            selectedGeoChannel: [],
            selectedGeoChannel_options: [],
            selectedGeoChannelName: [],
            selectedGeoChannelType: [],
            selectedGeoChannelType_options: [],
            selectedGeoChannelTypeName: [],
            selectedCountry: "",
            selectedCountryName: "",
            selectedGlblAttrID: [],
            selectedNewCompanyName: '',
            selectedNumSuppOut: '',
            selectedOutlet_options: [],
            selectedOutletName: [],
            selectedOutputAgg: 'Weekly',
            selectedReportName: '',
            selectedShortDesc: '',
            selectedSpikeThresh: 0.10,
            selectedstart: null,
            selectedSubcategory: '',
            selectedTPRThresh: 0.07,
            selectedTPRWeeks: 8,
            selectedWeeks: '108 Weeks',
            selectedTimeValue: '108 Weeks',
            selectedTimeLabel: 'The most recent 2 years',
            shortDesc: '',
            skip_calculations_text: '',
            skip_extract_text: '',
            skipCalculations: false,
            skipExtract: false,
            startDate: new Date(),
            tabIndex: 0,
            //top_n_percent: null,
            uschecked: false,
            value1: true,

            //container_chosen: { 'label': 'Default', 'value': null },

            geos: [],
            geoNames: [],
            editgeoNames: [],
            


            setStartDate: new Date(),
            pastdate: Datetime.moment().set({ 'year': 2019, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            futuredate: Datetime.moment().set({ 'year': 2021, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            pastdate_formatted: '2019-01-01',
            futuredate_formatted: '2021-01-01',
            openModal: false,
           

            //Form Errors
            num_form_errors: 0,
            country_missing: false,
            industry_missing: false,
            //causal_filename_missing: false,
            short_desc_missing: false,
            abbr_missing: false,
            report_name_missing: false,
            email_missing: false,
            catLevel_missing: false,
            catList_missing: false,
            attributes_max: false,
            no_sel1_made: false,
            cat_size_large: false,
            item_type_missing: false,
            ppg_filename_missing: false,
            item_filename_missing: false,
            cs_priceThresh_missing: false,
            cs_hierarchy_missing: false,
            geo_missing: false,
            max_item_missing: false,
            top_n_missing: false,


            indChildren: [],
            newalert: '',
            catlevellist: [],
            human_name: [],
            catLevelSel: [],
            cs_catLevelSel: [],
            selectedRetMan: 'Manufacturer',
            selectedAlr: 'Total US',
            selectedClient: 'Internal',
            selectedDelFreq: 'On-Demand',
            selectedIndustry: '',
            selectedIndCodeName: '',
            Selection1: '',
            selectedCompany: [],
            selectedContract: '',
            selectedCompanyName: '',
            selectedNewCompanyName: '',
            country_data: [],
            indcode: [],
            countryInd_options: [],
            hierarchy_options: [],
            attribute_options: [],
            glbl_attribute_options: [],
            cs_glbl_attribute_options: [],
            industry_country_data: [],
           

            country: [],
            country_options: [],

            selectedChannel: [],
            channelOptions: [],
            timeOptions: [],
            geoOptions: [],
            contract_ids: [],
            selEmail: [],
            page: 0,
            rowsPerPage: 5,

            //Snackbars
            showBackdrop_CreateReport: false,
            showSnackbar_CreateReportSuccess: false,
            showSnackbar_CreateReportError: false,
            showBackdrop: false

           

        }
        
    }
 
    handleClickcancel() {
        const findid = () => {
            var url = window.location.href;
            var temp = url.split("/");
            var i = unescape(temp[4]);
            var idnum = parseInt(i, 10);
            return idnum;
        }
        window.location.href = Constant.baseurl + 'PNPETL/PNPEntryForm'
    }

    //getContainers = async () => {
    //    let my_results = await fetch(Constant.baseapiurl_gen + "cloud/Containers/Visible", 'method',
    //        {
    //            method: "GET",
    //            headers: {
    //                'Accept': 'application/json',
    //                'Content-Type': 'application/json'
    //            },
    //        })

    //    console.log(my_results)


        
    //    if (my_results.Errors > 0) {
    //        // An error screen will be popped up if this fails, but we want to set "isLoaded" before we bail
    //        this.setState({ isLoaded: true })
    //        return
    //    }

    //    let containers_data = my_results.ResultList[0]

    //    let containers = [{ 'label': 'Default', 'value': null }].concat(containers_data.map(c => { return { 'label': c.blob_container_name, 'value': c.blob_container_name } }))

    //    this.setState({ containers })
    //}

    handleDateStart(date) {
        if (date.length < 10) {
            if (date.length === 0) {
                this.setState({ selectedstart: null })
            }

        }
        else {
            try {
                var startdate = String(date.format('YYYY-MM-DD'))
                var startdate_year = parseInt(startdate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set... 
                var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
                var startdate_day = parseInt(startdate.substring(8, 10))
                var pastdate_to_comp = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                //look at the value...if less than report start date, throw an error...
                if (pastdate_to_comp.isBefore(this.state.pastdate)) {
                    //Set an error
                    this.setState({ start_date_ltstart: true })
                    this.setState({ selectedstart: startdate });
                    //this.setState({ selectedstart: "invalid" }); 
                }
                else if (pastdate_to_comp.isAfter(this.state.futuredate)) {
                    this.setState({ start_date_gtend: true })
                    this.setState({ selectedstart: startdate });
                }
                else {
                    this.setState({ selectedstart: startdate });
                    this.setState({ start_date_ltstart: false })
                    this.setState({ start_date_gtend: false })
                }
                if (startdate && this.state.selectedend && startdate > this.state.selectedend) {
                    this.setState({ start_gt_end: true })
                }
                else {
                    this.setState({ start_gt_end: false })
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    };

    handleDateEnd(date) {
        if (date.length < 10) {
            if (date.length === 0) {
                this.setState({ selectedend: null })
            }
        }
        else {
            try {
                var enddate = String(date.format('YYYY-MM-DD'))
                var enddate_year = parseInt(enddate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set... 
                var enddate_month = parseInt(enddate.substring(5, 7)) - 1 //Zero Based Month                                    
                var enddate_day = parseInt(enddate.substring(8, 10)) - 1
                var future_to_comp = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                //look at the value...if less than report start date, throw an error...
                if (future_to_comp.isBefore(this.state.pastdate)) {
                    //Set an error
                    this.setState({ end_date_ltstart: true })
                    this.setState({ selectedend: enddate });
                    //this.setState({ selectedstart: "invalid" }); 
                }
                else if (future_to_comp.isAfter(this.state.futuredate)) {
                    this.setState({ end_date_gtend: true })
                    this.setState({ selectedend: enddate });
                }
                else {
                    this.setState({ selectedend: enddate });
                    this.setState({ end_date_ltstart: false })
                    this.setState({ end_date_gtend: false })
                }
                if (this.state.selectedstart && enddate && this.state.selectedstart > enddate) {
                    this.setState({ start_gt_end: true })
                }
                else {
                    this.setState({ start_gt_end: false })
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    };


    onChangeSkipExtract(event, action) {
        this.setState({ skipExtract: event.target.checked })

        if (event.target.checked === true) {
            this.setState({ skip_extract_text: ',skip_extract' })
        }
        else {
            this.setState({ skip_extract_text: ' ' })
        }
    }
    onChangeSkipCalculations(event, action) {
        this.setState({ skipCalculations: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ skip_calculations_text: ',skip_calculations' })
        }
        else {
            this.setState({ skip_calculations_text: ' ' })
        }

    }
    onChangeResumeIfPossible(event, action) {
        this.setState({ resumeIfPossible: event.target.checked })
        if (event.target.checked === true) {
            this.setState({ resume_if_possible_text: ',resume_if_possible' })
        }
        else {
            this.setState({ resume_if_possible_text: ' ' })
        }
    }

    OnChangeAlr = (event, action) => {
        let selectedAlr = event.target.value
        this.setState({ selectedAlr, alr_type_missing: false })
        this.onChangeOutlet([])
    };


    handleChangeReportTypeRadio = (event) => {
        let currvalue = this.state.reportRadioValue
        let newvalue = !currvalue
        this.setState({ reportRadioValue: newvalue })
    };


    onChangeEmail(selEmail) {
        let selectedEmailAddress = selEmail.target.value.split(',')
        this.setState({ selectedEmailAddress, email_missing: false })
    }

    //onChangeCausalName(filename) {
    //    let causal_filename = filename.target.value
    //    this.setState({ causal_filename, causal_filename_missing: false })
    //}

    onChangeReportName(selReportName, action) {
        let selectedReportName = selReportName.target.value
        this.setState({ selectedReportName, report_name_missing: false })
    }

    onChangeShortDesc(selShortDesc, action) {
        let selectedShortDesc = selShortDesc.target.value
        this.setState({ selectedShortDesc, short_desc_missing: false })
    }
    onChangeAbbr(selAbbr, action) {
        let selectedAbbr = selAbbr.target.value.replace(/[^a-z]/gi, '')
        this.setState({ selectedAbbr, abbr_missing: false })
    }
    onChangeMaxItems(maxItems, action) {
        //let max_item_count = +maxItems.target.value
        let max_item_count = parseInt(+maxItems.target.value)
        this.setState({
            max_item_count, max_item_missing: false,
            item_selection: {
                category_levels_list: this.state.selectedCategory,
                category_name: this.state.selectedCategoryLevel,
                max_item_count: parseInt(maxItems.target.value),
                top_n_percent: parseInt(this.state.top_n_percent)
            }
        })
     
    }
    onChangeCompSetThresh(thresh, action) {
        console.log(thresh)
        let compset_price_threshold = +thresh.target.value
        console.log(compset_price_threshold)
        this.setState({ compset_price_threshold })
    }
    onChangeTopNPercent(npct, action) {
        let top_n_percent = parseInt(+npct.target.value)
        this.setState({
            top_n_percent, top_n_missing: false ,
            item_selection: {
                category_levels_list: this.state.selectedCategory,
                category_name: this.state.selectedCategoryLevel,
                max_item_count: parseInt(this.state.max_item_count),
                top_n_percent: parseInt(top_n_percent)
            }
        })
    }
    onChangeGeoAbbr(selAbbr, action) {
        this.setState({ selectedOutlet_options: [] })
        let selectedGeoAbbr = selAbbr.target.value.replace(/[^a-z]/gi, '')
        this.setState({ geo_abbr: selectedGeoAbbr })
    }

    onChangeOutletSupp(selectedOutlet, action) {
        let selectedNumSuppOut = selectedOutlet.value
        this.setState({ selectedNumSuppOut })
    }

    onChangeCompSet(selectedComps, action) {
        console.log(selectedComps)
    };

    //onChangeCausal(event, action) {
    //    console.log(event.target.value);
    //    let selectedCausal = event.target.value
    //    this.setState({ selectedCausal, causal_missing: false, causal_filename: '' })
    //};


    onChangeItemFile(event, action) {
        console.log(event.target.value);
        let itemFileName = event.target.value
        this.setState({
            itemFileName, item_filename_missing: false,
            item_selection: {
                container_id: this.state.container_chosen.value,
                item_file_fullpath: itemFileName
            },        })
    };

    onChangePPGFile(event, action) {
        console.log('onChangePPGFile getting invoked')
        console.log(event.target.value);
        let ppgName = event.target.value
        this.setState({
            ppgName, ppg_filename_missing: false,
            item_selection: {
                container_id: this.state.container_chosen.value,
                item_file_fullpath: ppgName
            },
            })
    };

    onChangePPG(event, action) {
        console.log(event.target.value);
        let selectedPPG = event.target.value
        this.setState({ selectedPPG, ppg_missing: false })

    };

    onChangeOutlet(selectedOptions, action) {
        //console.log(selectedOptions)
        if (selectedOptions) {
            var geo_selectedOutlet = selectedOptions.value
            var selectedOutletName = selectedOptions.label
        }
        else {
            var geo_selectedOutlet = 0
            var selectedOutletName = ''
        }
        this.setState({ selectedOutlet_options: selectedOptions, geo_selectedOutlet, selectedOutletName })
    };
    onChangeGeoChannelType(selectedOptions, action) {
        console.log(selectedOptions)
        if (selectedOptions) {
            var selectedGeoChannelType = selectedOptions && selectedOptions.map(selectedOptions => selectedOptions.value)
            var selectedGeoChannelTypeName = selectedOptions && selectedOptions.map(selectedOptions => selectedOptions.label)
        }
        else {
            var selectedGeoChannelType = []
            var selectedGeoChannelTypeName = []
        }
        this.setState({ selectedGeoChannelType_options: selectedOptions, selectedGeoChannelType, selectedGeoChannelTypeName })
    };
    onChangeGeoGlblAttrFilters(selectedOptions, action) {
        let global_attr_filters = []
        console.log(selectedOptions)
        if (selectedOptions) {
            var selectedGeoGlobalAttrCode =  selectedOptions.map(option => option.geo_global_attr_code) 
            var selectedGeoGlobalAttrID =  selectedOptions.map(option => option.geo_global_attr_id) 
            var selectedGeoGlobalAttrName = selectedOptions.map(option => option.label)
            selectedOptions.forEach(attr => {
                global_attr_filters.push({ 'global_attr_code': attr.geo_global_attr_code, 'global_attr_id': attr.geo_global_attr_id })
            })
        }
        else {
            var selectedGeoGlobalAttrCode = []
            var selectedGeoGlobalAttrID = []
            var selectedGeoGlobalAttrName = []
            global_attr_filters = []
        }
        console.log('global_attr_filters in ON CHANGE', global_attr_filters)
        this.setState({ geo_global_attr_filters: selectedOptions, selectedGeoGlobalAttrName, selectedGeoGlobalAttrID, selectedGeoGlobalAttrCode, global_attr_filters })
        console.log(this.state)
    };


    onChangeGeoChannel(selectedOptions, action) {
        console.log(selectedOptions)
        if (selectedOptions) {
            var selectedGeoChannel = selectedOptions && selectedOptions.map(selectedOptions => selectedOptions.value)
            var selectedGeoChannelName = selectedOptions && selectedOptions.map(selectedOptions => selectedOptions.label)
        }
        else {
            var selectedGeoChannel = []
            var selectedGeoChannelName = []
        }
        this.setState({ selectedGeoChannel_options: selectedOptions, selectedGeoChannel, selectedGeoChannelName })
    };


 
    async onChangeChannelOption(selChannelOption, action) {
        this.setState({geos: [], geoNames: []})
        console.log(this.state.geos, 'GEOS')
        console.log('SelChannelOption here')
        console.log(selChannelOption)
        if (selChannelOption) {
            var selectedChannelOptionName = selChannelOption.label
            this.setState({ selectedChannelOption: selectedChannelOptionName })
        }
       
    };

    async onChangeTimeSelected(selTime, action) {
        console.log('selTime here')
        console.log(selTime)
        if (selTime) {
            var selectedTimeLabel = selTime.label
            var selectedTimeValue = selTime.value
            this.setState({ selectedTimeLabel: selectedTimeLabel, selectedTimeValue: selectedTimeValue })
        }
    };

    async onChangeItemType(selType, action) {
        console.log('selType here')

        console.log(selType)
        if (selType) {
            var selectedTypeLabel = selType.label
            var selectedTypeValue = selType.value
            this.setState({
                item_type: selectedTypeLabel, item_type_value: selectedTypeValue, item_type_missing: false, ppgName: "", itemFileName: "", top_n_percent: null, max_item_count: null,
                selectedCat: [], Catchosen: [], selectedCategory: [], selectedCategoryLevel: [], selectedCategoryLevelName: [], selectedCategoryName: [], item_selection: {}, container_chosen: {}
            })
        }
        this.getContainers()
    };

    async onChangeContainer(selectedOptions, action) {
        console.log(selectedOptions)

        var container_chosen = selectedOptions
        if (this.state.item_type === 'PPGs') {
            var filename = this.state.ppgName
        }
        else {
            var filename = this.state.itemFileName
        }
        this.setState({
            container_chosen,
            item_selection: { container_id: container_chosen.value, item_file_fullpath: filename }

        })
    };


    async onChangeCSCatLevel(cscat, action) {
        console.log('cscat here')
        console.log(cscat)
        if (cscat) {
            var selectedCSCatLabel = cscat.label
            var selectedCSCatValue = cscat.value
            
            this.setState({ selectedCSCatName: selectedCSCatLabel, selectedCSCatValue:selectedCSCatValue, cs_hierarchy_missing: false })
        }
    };

    async onChangeGeoType(selType, action) {
        console.log('Geo Type selected here')
        console.log(selType)
        if (selType) {
            var geoTypeLabel = selType.label
            var geoTypeValue = selType.value
            this.setState({ geo_type: geoTypeLabel, geo_type_value: geoTypeValue })
        }
    };

    onChangeAttributes(selectedOptions, action) {
        if (selectedOptions) {
            console.log(selectedOptions)
            console.log('SELECTED ATTRIBUTES HERE')
            var selectedAttrID = selectedOptions && selectedOptions.map(option => option.value)
            var selectedAttrName = selectedOptions && selectedOptions.map(option => option.label)
            var attr_name = []
            var attr_desc = []
            var attr_varname = []
            var attr_id = []
            if (selectedAttrName.includes('Select All')) {
                selectedAttrID = this.state.attribute_options.map(option => option.value)
                selectedAttrName = this.state.attribute_options.map(option => option.label)
                this.setState({ attrChosen: this.state.attribute_options });
            }
            else {
                this.setState({ attrChosen: selectedOptions });
            }
        }
        else {
            selectedAttrID = []
            selectedAttrName = []
        }
        this.setState({ attrChosen: selectedOptions, selectedAttrID, selectedAttrName })
       
        this.state.attribute_data.forEach(node => {
            if (selectedAttrID.includes(node.attr_id)) {
                let selattr_name = node.attr_name
                let selattr_desc = node.attr_desc
                let selattr_varname = node.attr_varname
                let selattr_id = node.attr_id

                attr_name.push(selattr_name)
                attr_desc.push(selattr_desc)
                attr_varname.push(selattr_varname)
                attr_id.push(selattr_id)
            }
        })

        this.setState({ attr_name, attr_id, attr_varname, attr_desc })

    };
   
    onChangeCompSetAttributes(selectedOptions, action) {
        console.log(this.state)
        if (selectedOptions) {
            var cs_selectedAttrID = selectedOptions && selectedOptions.map(option => option.value)
            var cs_selectedAttrName = selectedOptions && selectedOptions.map(option => option.label)
            var cs_attr_name = []
            var cs_attr_desc = []
            var cs_attr_varname = []
            var cs_attr_id = []
            if (cs_selectedAttrName.includes('Select All')) {
                cs_selectedAttrID = this.state.attribute_options.map(option => option.value)
                cs_selectedAttrName = this.state.attribute_options.map(option => option.label)
                this.setState({ compSetAttrChosen: this.state.attribute_options });
            }
            else {
                this.setState({ compSetAttrChosen: selectedOptions });
            }
        }
        else {
            cs_selectedAttrID = []
            cs_selectedAttrName = []
        }


        this.setState({ compSetAttrChosen: selectedOptions, cs_selectedAttrID, cs_selectedAttrName })


        this.state.attribute_data.forEach(node => {
            if (cs_selectedAttrID.includes(node.attr_id)) {
                let cs_selattr_name = node.attr_name
                let cs_selattr_desc = node.attr_desc
                let cs_selattr_varname = node.attr_varname
                let cs_selattr_id = node.attr_id

                cs_attr_name.push(cs_selattr_name)
                cs_attr_desc.push(cs_selattr_desc)
                cs_attr_varname.push(cs_selattr_varname)
                cs_attr_id.push(cs_selattr_id)
            }
        })

        this.setState({ cs_attr_name, cs_attr_id, cs_attr_varname, cs_attr_desc })
        //this.onChangeCompSetGlblAttributes()
        console.log(this.state)
    };



    onChangeGlblAttributes(selectedOptions, action) {
        console.log(selectedOptions)
        console.log('SELECTED GLOBAL ATTRIBUTES HERE')
        let glbl_attr_sub_options = []
        
        if (selectedOptions) {
            var selectedGlblAttrID = selectedOptions.value
            var selectedGlblAttrID = selectedOptions && selectedOptions.map(option => option.value)
            var selectedGlblAttrName = selectedOptions && selectedOptions.map(option => option.label)
            
           
            if (selectedGlblAttrName.includes('Select All')) {
                selectedGlblAttrName = this.state.glbl_attribute_options.map(option => option.label)
                this.setState({ glblAttrChosen: this.state.glbl_attribute_options });
            }
            else {
                this.setState({ glblAttrChosen: selectedOptions });
            }

            if (Constant.entrytype === 'create' || this.state.editglblattrids.length === this.state.glblAttrChosen.length) {
              
                selectedOptions.forEach(attribute => {
                    attribute.values.forEach(child1 => {
                        glbl_attr_sub_options.push({ 'label': child1.label, 'geo_global_attr_code': child1.global_attr, 'geo_global_attr_id': attribute.id })
                    })
                })
            }
            else {
               
                this.state.glbl_attribute_value_data.forEach(attribute => {
                    attribute.values.forEach(child1 => {
                        console.log(child1)
                            console.log(this.state.editglblattrids )
                            if (this.state.editglblattrids === child1.global_attr_id) {
                                
                                glbl_attr_sub_options.push({ 'label': child1.label, 'global_attr_code': child1.global_attr, 'global_attr_id': attribute.id })
                                console.log('PUSHING SUB OPTIONS HERE FOR EDIT', glbl_attr_sub_options )
                            }
                        })
                    })
                }
            }

        else {
            this.setState({
                glblAttrSel: [], glblAttrChosen: []
            })
        }
        this.setState({ selectedGlblAttributes: selectedOptions, selectedGlblAttrID, selectedGlblAttrName, selectedGlblAttrID, glbl_attr_sub_options })
        console.log(this.state.selectedGlblAttrID, 'selectedGlblAttrID')

    };

   

    onChangeCompSetGlblAttributes(selectedOptions, action) {
        console.log(selectedOptions)
        console.log('SELECTED COMPSET GLOBAL ATTRIBUTES HERE')
        if (selectedOptions) {
            var cs_selectedGlblAttrID = selectedOptions.value
            var cs_selectedGlblAttrID = selectedOptions && selectedOptions.map(option => option.value)
            var cs_selectedGlblAttrName = selectedOptions && selectedOptions.map(option => option.label)
            if (cs_selectedGlblAttrName.includes('Select All')) {
                cs_selectedGlblAttrName = this.state.cs_glbl_attribute_options.map(option => option.label)
                this.setState({ cs_glblAttrChosen: this.state.cs_glbl_attribute_options });
            }
            else {
                this.setState({ cs_glblAttrChosen: selectedOptions });
            }

            this.state.compset_glbl_attribute_value_data.forEach(attribute => {
                if (attribute.global_attr_id === cs_selectedGlblAttrID) {
                    console.log(attribute.global_attr_id)
                    console.log(cs_selectedGlblAttrID)
                    this.state.compset_glbl_attribute_value_data.forEach(node => {
                        node.values.forEach(child1 => {
                            let cs_glbl_attr_sub_options = []
                            cs_glbl_attr_sub_options.push({ 'label': child1.global_attr_name, 'value': child1.global_attr })
                        })
                    })
                }
                else {

                }
            })
        }

        else {
            console.log('hitting the on change for no compset glbl attr filters')
            this.setState({
                cs_glblAttrSel: [], cs_glblAttrChosen: [], cs_glbl_attr_sub_options: [], cs_selectedGlblAttrID:[]
            })
        }
        this.setState({ cs_selectedGlblAttributes: selectedOptions, cs_selectedGlblAttrID, cs_selectedGlblAttrName, cs_selectedGlblAttrID })
        console.log(this.state)

    };

    async onChangeCountry(selectedOptions, action) {
        console.log('Entering onChangeCountry')
        //reset fields to be blank when changing or clearing selections
        if (Constant.entrytype === 'create') {
            this.setState({
                geos: [], geoNames: [], ppgName: "", itemFileName: "",
            })
        }
            this.setState({
                attribute: [], alrOutlet: [], selectedCSCatName: [], selectedCSCatValue: [], 
                cat_size_large: false, country_missing: false, glblAttrChosen: [], attrChosen: [], compSetAttrChosen: [], cs_glblAttrChosen: [],
               Catchosen: [],
                selectedAttrID: [], selectedAttrName: [], selectedAttributes: [], geo_selectedOutlet: 0, selectedGeoChannelType_options: [],
                selectedCategory: [], selectedCategoryName: [], selectedCategoryLevel: [], selectedCategoryLevelName: [],
                selectedIndustry: [], selectedIndustryName: [], Catchosen: [], attr_desc: [], attr_name: [],
                attr_id: [], attr_varname: [], attr_desc: [], attr_id: [], attr_name: [], attribute_data: [],
                attr_varname: [], catLevelSel: [], cs_catLevelSel: [], hierarchy_options: [], hierarchySel: [], hierarchy_Sel: [], category_levels: [], sel1_hrlabel: [],
                sel1_label: [], sel1_list: [], sel1_name: [], attribute_options: [], selectedCat: [], finalSortAttr: [], finalSortCompSetAttr: [], finalSortGlblAttr: [],
                glbl_attribute_value_data: [], compset_glbl_attribute_value_data: []
            })
        console.log(this.state.geos, 'GEOS')
        var selectedCountry = selectedOptions.value
        var selectedCountryName = selectedOptions.label
        this.setState({ selectedCountry, selectedCountryName })

        //Read in Industries from Country lookup
        let industry_country_response = await fetch(Constant.baseapiurl_gen + "etl/IndustryTables/ByCountry/" + selectedCountry)
        if (industry_country_response.status === 200) {
            var industry_country_data = await industry_country_response.json()
        }
        else {
            industry_country_data = []
            this.setState({ errorAlert: true })
        }
        let countryInd_options = []

        if (Constant.entrytype === 'create') {
            industry_country_data.forEach(country => {
                if (country.is_enabled === true) {
                    countryInd_options.push({ 'label': country.industry_name, 'value': country.industry_id })
                }
            })
        }
        else {
            industry_country_data.forEach(country => {
                if (country.is_enabled === true) {
                    if (country.industry_id === this.state.editselectedIndustryValue) {
                        console.log(country.industry_id === this.state.editselectedIndustryValue)
                        countryInd_options.push({ 'label': country.industry_name, 'value': country.industry_id })
                        this.setState({ selectedIndustryName: country.industry_name, selectedIndustry: country.industry_id })
                        this.onChangeIndustry({ 'label': country.industry_name, 'value': country.industry_id }, 'Industry')
                    }
                }
            })
        }
        this.setState({ countryInd_options })
        let industrysorted = countryInd_options.sort((a, b) => a.label.localeCompare(b.label))
        console.log('countryInd options run')
        let finalSortIndustry = industrysorted
        this.setState({ finalSortIndustry })
        console.log('finalSortIndustry options run')
    }

    async onChangeIndustry(selectedOptions, action) {
        //reset fields to be blank when changing or clearing selections
        if (Constant.entrytype === 'create') {
            this.setState({
                geos: [], geoNames: [], ppgName: "", itemFileName: "",
            })
        }
        
            this.setState({
                attribute: [], alrOutlet: [], selectedCSCatName: [], selectedCSCatValue: [],
                cat_size_large: false, glblAttrChosen: [], attrChosen: [], compSetAttrChosen: [], cs_glblAttrChosen: [],
                industry_missing: false,
                selectedAttrID: [], selectedAttrName: [], selectedAttributes: [], geo_selectedOutlet: 0, selectedGeoChannelType_options: [],
                selectedCategory: [], selectedCategoryName: [], selectedCategoryLevel: [], selectedCategoryLevelName: [],
                selectedIndustry: [], selectedIndustryName: [], Catchosen: [], attr_desc: [], attr_name: [],
                attr_id: [], attr_varname: [], attr_desc: [], attr_id: [], attr_name: [], attribute_data: [],
                attr_varname: [], catLevelSel: [], cs_catLevelSel: [], hierarchy_options: [], hierarchySel: [], hierarchy_Sel: [], category_levels: [], sel1_hrlabel: [],
                sel1_label: [], sel1_list: [], sel1_name: [], attribute_options: [], selectedCat: [], finalSortAttr: [], finalSortCompSetAttr: [], finalSortGlblAttr: [], glbl_attribute_value_data: [], compset_glbl_attribute_value_data: []
            })
        console.log(this.state.geos, 'GEOS')
        var selectedIndustry = selectedOptions.value
        var selectedIndCodeName = 'industry=' + selectedOptions.value
        var selectedIndustryName = selectedOptions.label
        this.setState({ selectedIndustry, selectedIndustryName, selectedIndCodeName })
        let sel1_list = []
        let sel1_label = []
        let sel1_hrlabel = []
        let children = []

        //Read in Industry Lookup Table (parent and children)
        let industry_response = await fetch(Constant.baseapiurl_gen + "etl/GetIndustryLookup")
        if (industry_response.status === 200) {
            var industry_data = await industry_response.json()
        }
        else {
            industry_data = []
            this.setState({ errorAlert: true })
        }

        let industry_options = []

        industry_data.forEach(industry => {
            let indArray = industry.value.split('=')
            let indcode = indArray[1]
            industry_options.push({ 'label': industry.label, 'value': indcode, 'orgValue': industry.value })
        })
        this.setState({ industry: industry_options, industry_data: industry_data })

        this.state.industry_data.forEach(node => {
            if (node.value === selectedIndCodeName) {
                children = node.children
                node.children.forEach(child1 => {
                    let valueArray = child1.value.split('=')
                    let selcode = valueArray[1]
                    let sellabel = valueArray[0]
                    let selhrlabel = child1.label
                    sel1_list.push(selcode)
                    sel1_label.push(sellabel)
                    sel1_hrlabel.push(selhrlabel)
                })
            }
        })

        this.setState({ sel1_list, sel1_label, sel1_name: sel1_label[0], sel1_hrlabel, indChildren: children, industry_missing: false })

        let sel1sorted = children.sort((a, b) => a.label.localeCompare(b.label))

        let finalSortSel1 = sel1sorted
        this.setState({ finalSortSel1 })

        let level1Sel = []
        let indChildren_options = []
        sel1sorted.forEach(child => {
            indChildren_options.push({ 'label': child.label, 'value': child.value })
        })
        this.setState({ level1Sel: indChildren_options })

        //Read in Channel info selections for Industry
        let channelOptions_response = await fetch(Constant.baseapiurl_gen + "etl/IndustryTables/" + selectedIndustry + "/ChannelReportingLevel")
        if (channelOptions_response.status === 200) {
            var channelOptions_data = await channelOptions_response.json()
        }
        else {
            channelOptions_data = []
            this.setState({ errorAlert: true })
        }
        console.log('Channel Options Pulled')
        //console.log(channelOptions_data)
        var channelOptions = []

            channelOptions_data.forEach(channel => {
                console.log('Channel', channel)
                channelOptions.push({ 'label': channel, 'value': channel })
            })

        this.setState({ channelOptions })
       console.log(channelOptions)

        //Read in Industry Levels
        let levelOptions_response = await fetch(Constant.baseapiurl_gen + "etl/IndustryTables/" + selectedIndustry)
        if (levelOptions_response.status === 200) {
            var levelOptions_data = await levelOptions_response.json()
            var category_levels = levelOptions_data.category_levels
            //console.log('LEVEL OPTIONS DATA', levelOptions_data)
            //console.log('CATEGORY LEVELS', category_levels)
        }
        else {
            levelOptions_data = []
            this.setState({ errorAlert: true })
        }
        this.setState({ category_levels: category_levels })

        let catLevelSel = []
        let catList_options = []

        if (Constant.entrytype === 'create') {
            category_levels.forEach(child => {
                catList_options.push({ 'label': child.human_name, 'value': child.variable })
            })
        }
        else {
            category_levels.forEach(child => {
                catList_options.push({ 'label': child.human_name, 'value': child.variable })
                if (child.human_name === this.state.selectedCatValue) {
                    this.setState({ selectedCat: [{ 'label': child.human_name, 'value': child.variable }] })
                    this.onChangeCatLevel({ 'label': child.human_name, 'value': child.variable }, 'HierarchyLevel')
                    
                }
                if (child.variable === this.state.editselectedCSCatValue) {
                    this.setState({ selectedCSCatValue: [{ 'label': child.human_name, 'value': child.variable }] })
                    this.onChangeCSCatLevel({ 'label': child.human_name, 'value': child.variable }, 'HierarchyLevel')

                }
                if (child.variable === this.state.editItemselectedCat) {
                    this.setState({ selectedCat: [{ 'label': child.human_name, 'value': child.variable }] })
                    this.onChangeCatLevel({ 'label': child.human_name, 'value': child.variable }, 'HierarchyLevel')

                }

            })
        }
        this.setState({ catLevelSel: catList_options, cs_catLevelSel: catList_options  })

        //Fetch Industry-Level Global Attributes
        let glbl_attributes_value_response = await fetch(Constant.baseapiurl_gen + "etl/GetIndustryGlobalAttributes/" + this.state.selectedIndustry,
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })

        if (glbl_attributes_value_response.status === 200) {
            var glbl_attribute_value_data = await glbl_attributes_value_response.json()
            var glbl_attribute_list_filters = []
            var glbl_attribute_list_filters_children = []
            glbl_attribute_value_data.forEach(object => {
                object['children'] = object['values']
                object['label'] = object['global_attr_human']
                object['id'] = object['global_attr_id']
                object['value'] = object['global_attr_id']
                object['children'].forEach(childObject => {
                    childObject['label'] = object['label']+" - "+childObject['global_attr_name']
                    childObject['id'] = childObject['global_attr']
                    childObject['value'] = childObject['global_attr'] + '_' + object['global_attr_id']
                    glbl_attribute_list_filters_children.push(childObject)
                })
                glbl_attribute_list_filters.push(object)
               

            })
        }
        else {
            glbl_attribute_value_data = []
            glbl_attribute_list_filters = []
            glbl_attribute_list_filters_children = []
            
            this.setState({ errorAlert: true, showBackdrop: false })
        }

        this.setState({
            glbl_attribute_value_data: glbl_attribute_value_data, compset_glbl_attribute_value_data: glbl_attribute_value_data,
            glbl_attribute_value_data_filter: glbl_attribute_list_filters, glbl_attribute_list_filters_children: glbl_attribute_list_filters_children
        })

        if (Constant.entrytype !== 'create') {
            let glblAttrChosen = []
            let cs_glblAttrChosen = []
            glbl_attribute_value_data.forEach(attr => {
                if (this.state.editglblattrids.includes(attr.global_attr_id)) {
                    glblAttrChosen.push(attr)
                }
            })

            glbl_attribute_value_data.forEach(csattr => {
                if (this.state.editCSGlblAttrChosenValue.includes(csattr.global_attr_id)) {
                    cs_glblAttrChosen.push(csattr)
                }
            })
            this.setState({ glblAttrChosen, cs_glblAttrChosen })
            console.log(this.state.glblAttrChosen)
            console.log(this.state.cs_glblAttrChosen)

            let glbl_attr_sub_options = []
            this.state.glblAttrChosen.forEach(attribute => {
                attribute.values.forEach(child1 => {
                    console.log(child1)
                    console.log(this.state.editglblattrids)
                    

                        glbl_attr_sub_options.push({ 'label': child1.label, 'global_attr_code': child1.global_attr, 'global_attr_id': attribute.id })
                        console.log('PUSHING SUB OPTIONS HERE FOR EDIT', glbl_attr_sub_options)
                   
                })
            })
        this.setState({ glbl_attr_sub_options })
        }
      
            //Fetch Category-Level Attributes
            let attributes_response = await fetch(Constant.baseapiurl_gen + "etl/GetIndustryAttributes/" + this.state.selectedIndustry,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    //                    body: JSON.stringify({ "category_level": this.state.selectedCategoryLevelName, "category_list": selectedCategory })
                    body: JSON.stringify({ "category_level": null, "category_list": null })

                })
            //console.log(attributes_response)
            //console.log('ATTRIBUTES_RESPONSE')

            if (attributes_response.status === 200) {
                var attribute_data = await attributes_response.json()
                this.setState({ showBackdrop: false })
            }
            else {
                attribute_data = []
                this.setState({ errorAlert: true, showBackdrop: false })
        }
        console.log('Attribute_data',attribute_data)
        let attribute_options = []
        let cs_attribute_options = []

            if (Constant.entrytype === 'create') {
                attribute_data.forEach(attribute => {
                    attribute_options.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                    cs_attribute_options.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                })
            }
            else {
                var editatts = []
                var compseteditatts = []
                attribute_data.forEach(attribute => {
                    //console.log('attribute', attribute)
                    attribute_options.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                    //console.log('attribute_options', attribute_options)
                    cs_attribute_options.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                    //console.log('cs_attribute_options', cs_attribute_options)
                    if (this.state.editAttrChosen.includes(attribute.attr_id)) {
                       
                        editatts.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                    }
                    if (this.state.editCompsetAttrChosen.includes(attribute.attr_id)) {
                        //console.log(this.state.editCompsetAttrChosen)
                        //console.log(this.state.editCompsetAttrChosen.includes(attribute.attr_id))
                        //console.log(this.state.editCompsetAttrChosen)
                        
                        compseteditatts.push({ 'label': attribute.attr_name, 'value': attribute.attr_id })
                    }
                })
                this.setState({ attribute_options: editatts, cs_attribute_options: compseteditatts })
                this.onChangeAttributes(editatts, 'Attributes Chosen', attribute_options)
                this.onChangeCompSetAttributes((cs_attribute_options, 'Attributes Chosen', compseteditatts))
                
            }

            let attrsorted = attribute_options.sort((a, b) => a.label.localeCompare(b.label))
            let cs_attrsorted = cs_attribute_options.sort((a, b) => a.label.localeCompare(b.label))
            let finalSortAttr = attrsorted
            let finalSortCompSetAttr = cs_attrsorted
        this.setState({ finalSortAttr, finalSortCompSetAttr })
        console.log(this.state)

        ///Outlet Families for ALR
        let alrOutlets_response = await fetch(Constant.baseapiurl_gen + "etl/GetIndustryOutlets/" + this.state.selectedIndustry,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "category_level": null, "category_list": null })
                //                    body: JSON.stringify({ "category_level": this.state.selectedCategoryLevelName, "category_list": selectedCategory })
            })
        if (alrOutlets_response.status === 200) {
            var alrOutlet_data = await alrOutlets_response.json()
        }
        else {
            alrOutlet_data = []
            this.setState({ errorAlert: true })
        }

        let alrOutlet_options = []
        //console.log(alrOutlet_data)
        let outletname = alrOutlet_data.outlet_name
        let outlettype = alrOutlet_data.outlet
        if (Object.keys(alrOutlet_data).length > 0) {

           
                alrOutlet_data.values.forEach(outlet => {
                    alrOutlet_options.push({ 'label': outlet[outletname], 'value': outlet[outlettype] })
                })
          
            this.setState({ alrOutlet: alrOutlet_options, outletname, outlettype })

            console.log('ALR Outlets Pulled!');
            //console.log(selectedOutlet_options)
            console.log(alrOutlet_data)
        }

        ///Channels for ALR - NOT INDUSTRY
        let alrChannels_response = await fetch(Constant.baseapiurl_gen + "etl/GetIndustryChannels/" + this.state.selectedIndustry,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "category_level": null, "category_list": null })
               // body: JSON.stringify({ "category_level": this.state.selectedCategoryLevelName, "category_list": selectedCategory })
            })
        if (alrChannels_response.status === 200) {
            var alrChannel_data = await alrChannels_response.json()
            console.log(alrChannel_data)
        }
        else {
            alrChannel_data = []
            this.setState({ errorAlert: true })
        }

        let alrChannel_options = []

        alrChannel_data.forEach(channel => {
            alrChannel_options.push({ 'label': channel.channel_name, 'value': channel.channel })
        })

        this.setState({ alrChannel: alrChannel_options, alrChannel_options })

        console.log('ALR Channels Pulled!');
        console.log(alrChannel_options)

        ///Channel TYPE for GEOS
        let geoChannelType_response = await fetch(Constant.baseapiurl_gen + "etl/GetIndustryChannelTypes/" + this.state.selectedIndustry,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "category_level": null, "category_list": null })
                // body: JSON.stringify({ "category_level": this.state.selectedCategoryLevelName, "category_list": selectedCategory })
            })
        if (geoChannelType_response.status === 200) {
            var geoChannelType_data = await geoChannelType_response.json()
        }
        else {
            geoChannelType_data = []
            this.setState({ errorAlert: true })
        }

        let geoChannelType_options = []

        geoChannelType_data.forEach(channelType => {
            geoChannelType_options.push({ 'label': channelType.channeltype_name, 'value': channelType.channeltype })
        })

        this.setState({ geoChannelType: geoChannelType_options })

        console.log('Geo Channel Types Pulled!');
        console.log(geoChannelType_data)

        //ITEM UNSUPPRESSED VARIABLE FOR INDUSTRY (USED FOR HELPER TEXT IN ITEM SELECTION PAGE)
        console.log(this.state.selectedIndustry)
        console.log(Constant.baseapiurl_gen)
        console.log(Constant.baseapiurl_gen + "etl/IndustryTables/" + this.state.selectedIndustry)
        let itemnum_response = await fetch(Constant.baseapiurl_gen + "etl/IndustryTables/" + this.state.selectedIndustry)
        if (itemnum_response.status === 200) {
            var industrytable_data = await itemnum_response.json()
            var itemnumberuns = industrytable_data.unsuppressed_item
        }
        else {
            industrytable_data = []
            this.setState({ errorAlert: true })
        }

        

       

        this.setState({ itemnumberuns })

        console.log('Item Unsuppressed Field Pulled');
        console.log(industrytable_data)
        console.log(this.state)
    }

    async onChangeCatLevel(selectedOptions, action) {
        //reset fields to be blank when changing or clearing selections
        this.setState({
          
            cat_size_large: false,
          geo_selectedOutlet: 0,
           
            Catchosen: [], selectedCategory: [], selectedCategoryName: [], selectedCat: []
        })
        var selectedCategoryLevel = selectedOptions.value
        var selectedCategoryLevelName = selectedOptions.label
        var selectedCategory = selectedOptions
        var selectedCat = selectedOptions

        this.setState({ selectedCat, selectedCategory, selectedCategoryLevel, selectedCategoryLevelName })

        //Read in Category Levels from Cat Levels lookup
        let hierarchy_response = await fetch(Constant.baseapiurl_gen + "etl/GetIndustryLookup/" + this.state.selectedIndustry + "?level=" + selectedCategoryLevelName)
        if (hierarchy_response.status === 200) {
            var hierarchy_data = await hierarchy_response.json()
        }
        else {
            hierarchy_data = []
            this.setState({ errorAlert: true })
        }
        console.log(hierarchy_data)
        let hierarchy_options = []

        if (Constant.entrytype === 'create') {
            hierarchy_data.forEach(category => {
                hierarchy_options.push({ 'label': category.name, 'value': category.selection })
            })
        }
        else {
            var subcats = []
            hierarchy_data.forEach(category => {
                hierarchy_options.push({ 'label': category.name, 'value': category.selection })
                //if (this.state.CatchosenValue.includes(category.selection)) {
                //console.log(hierarchy_data)
                //console.log(category.selection)
                //console.log(this.state.edititemCatchosen)
                if (this.state.edititemCatchosen.includes(category.selection)) {
                   
                    subcats.push({ 'label': category.name, 'value': category.selection })
                    console.log (subcats)
                }
            })
            this.setState({ catChosen: subcats, Catchosen: subcats })
            this.onChangeCatSelected(subcats, 'Categories Chosen', hierarchy_options)
            //this.LoadGeos()
        }
        this.setState({ hierarchy_options })
        this.setState({ hierarchySel: hierarchy_options })

        let hierarchysorted = hierarchy_options.sort((a, b) => a.label.localeCompare(b.label))
        let finalSortHierarchy = hierarchysorted
        this.setState({ finalSortHierarchy })
    }

    async onChangeCatSelected(selectedOptions, action, hierarchy_options) {
        //reset fields to be blank when changing or clearing selections
        this.setState({
          
            geo_selectedOutlet: 0,  selectedCategory: [], selectedCategoryName: [],
            cat_size_large: false,  finalSortGlblAttr: []
        })

        var num_cat_selected = 0
        var num_cat_half = 0
        if (selectedOptions && selectedOptions.length > 0) {
            var selectedCategory = selectedOptions && selectedOptions.map(option => option.value)
            var selectedCategoryName = selectedOptions && selectedOptions.map(option => option.label)
            if (selectedCategoryName.includes('Select All')) {
                selectedCategory = this.state.hierarchySel.map(option => option.value)
                selectedCategoryName = this.state.hierarchySel.map(option => option.label)
                this.setState({ Catchosen: this.state.hierarchySel });
                num_cat_selected = this.state.hierarchySel.length
            }
            else {
                this.setState({ Catchosen: selectedOptions });
                num_cat_selected = (selectedOptions || []).length
                num_cat_half = hierarchy_options.length * .50 - 1 // the -1 is to subtract out the Select All option from the number of cats
            }
        }
        else {
            this.setState({
                // hierarchySel: [],
                cat_size_large: false,
                Catchosen: []
            })
            num_cat_selected = 0
            num_cat_half = 0
        }
        this.setState({
            selectedoptions1: selectedOptions, selectedCategory, selectedCategoryName,
            item_selection: {
                category_levels_list: selectedCategory,
                category_name: this.state.selectedCategoryLevel,
                max_item_count: parseInt( this.state.max_item_count),
                top_n_percent: parseInt(this.state.top_n_percent)
            }
         })

        // Pulling more than 50% of the industry warning

        if ((selectedOptions || []).includes('Select All') || num_cat_half <= num_cat_selected - 1) {
            this.setState({ cat_size_large: true })
            console.log('Selected categories represent more than 50% of total industry')
        }
        else {
            this.setState({ cat_size_large: false })
        }


        //Set a Loading Screen...
        this.setState({
           showBackdrop: false,
          
        })

    };

    //If Copy or Edit, pull in Report Info and Populate the State....
    getLoadEtlDef = async (editETL_map) => {
        //if (Constant.entrytype !== 'create') {
            //find the id and set to the state.
            var etl_id = findid()
            this.setState({ etlIDEdit: etl_id })


            //find the parameters of the report and set to the state.
            var etl_edit_def = await fetch(Constant.baseapiurl_gen + "etl/Pnp_Etl/" + etl_id + "?add_names=True")
            var etl_edit_resp = etl_edit_def.status
            console.log(etl_edit_def)
            let editglblAttrChosen = []
            if (etl_edit_resp === 200) {
                var result = await etl_edit_def.json()
                console.log(result)
                console.log('RETRIEVE ETL FOR EDIT RESULT')

                var selectedTimeValue = result.time_period_type
                if (selectedTimeValue === '108 Weeks') {
                    var selectedTimeLabel = 'The most recent 2 years'
                }
                else {
                    var selectedTimeLabel = 'All available weeks of data'
                }
                var editselectedCountryName = result.country

                var editselectedIndustryValue = result.industry_id
                var selectedIndustryName = result.industry_name
                var selectedIndustry = result.industry_id
                var selectedChannelOption = result.channel_reporting_level
                var editAttrChosen = result.attribute_ids
                
                var editglblattrids = result.global_attribute_ids
                var editglblattrnames = result.global_attirubte_names
                var causal_filename = ''
                var selectedCausal = 'No'
                
                var selectedShortDesc = result.etl_shortname
                var selectedAbbr = result.etl_abbr
                var selectedReportName = result.etl_description
                var selectedEmailAddress = result.etl_email_address


                var editTimeUpdated = result.time_updated
                var is_manufacturer_var = result.is_manufacturer
                var is_retailer_var = result.is_retailer
                var selectedDelFreq = result.job_frequency

                var editselectedCSCatValue = result.compset_category_level
                var compset_price_threshold = result.compset_price_threshold
                var editCompsetAttrChosen = result.compset_attributes
                var editCSGlblAttrChosenValue = result.compset_global_attributes
                var editCSGlblAttrChosenName = result.compset_global_attribute_names

                var edititem_type = result.item_type
                var edititem_type_value = result.item_type
                var edititem_selection = result.item_selection
                var editgeos = result.geos
                var item_selection = {}
                
                if (edititem_type === 'Criteria') {

                    var editItemselectedCat = result.item_selection.category_name
                    var edititemCatchosen = result.item_selection.category_levels_list
                    var max_item_count = result.item_selection.max_item_count
                    var top_n_percent = result.item_selection.top_n_percent

                }
                else if (edititem_type === 'PPGs') {
                    var edititem_file_fullpath = result.item_selection.item_file_fullpath
                    var ppgName = result.item_selection.item_file_fullpath
                    var edit_container_id = result.item_selection.container_id
                    
                    var container_id = result.item_selection.container_id
                    var ppg_filename_missing = false
                    var item_filename_missing = true
                    this.setState({
                        item_selection: {
                            item_file_fullpath: ppgName,
                            container_id: container_id
                        }, edit_container_id
                    })
                   
                    
                }

                else {
                    var edititem_file_fullpath = result.item_selection.item_file_fullpath
                    var itemFileName = result.item_selection.item_file_fullpath
                    var edit_container_id = result.item_selection.container_id
                    var container_id = result.item_selection.container_id
                    var item_filename_missing = false
                    var ppg_filename_missing = true
                    this.setState({
                        item_selection: {
                            item_file_fullpath: itemFileName,
                            container_id: container_id

                        }, edit_container_id
                    })

                }
               
               
                console.log('Setting State for EDIT ETL ')
                console.log(result)
                

            }
            else {
                console.log('There was an error running the get extract for edit')
            }
            
            this.setState({
                selectedTimeLabel, selectedTimeValue, editselectedCountryName, editselectedIndustryValue, selectedIndustryName, selectedIndustry, selectedChannelOption, editAttrChosen,
                 causal_filename, selectedCausal, selectedShortDesc, selectedAbbr, selectedReportName, selectedEmailAddress, editTimeUpdated,
                is_manufacturer_var, is_retailer_var, selectedDelFreq, editselectedCSCatValue, compset_price_threshold, editCompsetAttrChosen, 
                edititem_selection, edititem_type, edititem_type_value, editgeos, editItemselectedCat, edititemCatchosen, max_item_count, top_n_percent, edititem_file_fullpath,
                editglblattrids, editglblattrnames, editCSGlblAttrChosenValue, item_type_missing: false, item_type: edititem_type, selectedGlblAttrID: editglblattrids, editCSGlblAttrChosenName,
                cs_selectedGlblAttrID: editCSGlblAttrChosenValue, itemFileName: edititem_file_fullpath, ppgName, itemFileName, ppg_filename_missing, item_filename_missing, edit_container_id
            })
        console.log(this.state)
        

        //find the parameters of the report and set to the state.
        var etl_edit_def_nonames = await fetch(Constant.baseapiurl_gen + "etl/Pnp_Etl/" + etl_id )
        var etl_edit_resp_nonames = etl_edit_def_nonames.status
        console.log(etl_edit_def_nonames)
        let editglblAttrChosen_nonames = []
        if (etl_edit_resp_nonames === 200) {
            var result2 = await etl_edit_def_nonames.json()
            console.log(result2)
            console.log('RETRIEVE ETL FOR EDIT RESULT - NO NAMES')
            var editgeos_nonames = result2.geos
            console.log('Setting State for EDIT GEOS ONLY ')
            console.log(result2)


        }
        else {
            console.log('There was an error running the get extract for edit')
        }

        this.setState({
            geos: editgeos_nonames, editgeos_nonames
        })
        console.log(this.state.geos, 'GEOS')
        
        this.LoadGeos()
        this.getContainers()
    }

    //Read in available blob containers
  
    getContainers = async (containers_map) => {
        let container_response = await fetch(Constant.baseapiurl_gen + "cloud/Containers/Visible")
        if (container_response.status === 200) {
            var containers_data = await container_response.json()
        }
        else {
            containers_data = []
            //this.setState({ errorAlert: true })
        }
        this.setState({ containers_data })
        console.log('Running getContainers')
        console.log('Constant Type', Constant.entrytype)
        console.log(this.state)
        let container_options = []
        let container_chosen = []
        if (Constant.entrytype === 'create') {
            //let container = [{ 'label': 'Default', 'value': null }].concat(containers_data.map(c => { return { 'label': c.blob_container_name, 'value': c.blob_container_name } }))
            containers_data.forEach(container => {
                container_options.push({ 'label': container.blob_container_name, 'value': container.container_id })
            })
        }
        else {
            containers_data.forEach(container => {
                console.log(container.container_id)
                console.log(this.state.edit_container_id)
                container_options.push({ 'label': container.blob_container_name, 'value': container.container_id })
                console.log('Edit Container Options', container_options)

                if (container.container_id === this.state.edit_container_id) {
                    console.log('ENTERING EDIT of getLoadContainers')
                    container_chosen.push({ 'label': container.blob_container_name, 'value': container.container_id })
                   
                    console.log(container_chosen)
                    
                    //this.onChangeContainer()
                }
            })
        }
        this.setState({ containers: container_options, container_chosen })
    }





    //Read in Country Lookup Table
    getLoadCountries = async (countries_map) => {
        let country_response = await fetch(Constant.baseapiurl_gen + "etl/IndustryTables/Countries")
        if (country_response.status === 200) {
            var country_data = await country_response.json()
        }
        else {
            country_data = []
            //this.setState({ errorAlert: true })
        }
        this.setState({ country_data })
        console.log('Running getLoadCountries')
        console.log('Constant Type', Constant.entrytype)
        console.log(this.state)
        let country_options = []
        if (Constant.entrytype === 'create') {
            country_data.forEach(country => {
                country_options.push({ 'label': country.country_shortname, 'value': country.country })
            })
        }
        else {
            country_data.forEach(country => {
                if (country.country === this.state.editselectedCountryName) {
                    console.log('ENTERING EDIT of getLoadCountries')
                    country_options.push({ 'label': country.country_shortname, 'value': country.country })
                    this.setState({ selectedCountryName: country.country_shortname, selectedCountry: country.country })
                    this.onChangeCountry({ 'label': country.country_shortname, 'value': country.country }, 'Country')
                }
            })
        }
        this.setState({ country: country_options })

        let countrysorted = country_options.sort((a, b) => a.label.localeCompare(b.label))
        let finalSortCountry = countrysorted

        this.setState({ finalSortCountry })
    }

     async LoadGeos() {
        if (Constant.entrytype === 'create' || this.state.editgeos.length <1 ) {

        }

        else {
            console.log('ENTERING LOAD GEOS HERE')

            this.state.editgeos.forEach(geo => {
                let edit_global_attr_filters = []
                let edit_global_attr_filters_name = []
                
                var edit_geo_allowed_channel_list = geo.allowed_channel_list
                var edit_geo_allowed_channel_list_names = geo.allowed_channel_names || []
                var edit_geo_allowed_channel_type_list = geo.allowed_channel_type_list
                var edit_geo_allowed_channel_type_list_names = geo.allowed_channel_type_list_names || []
                var edit_geo_abbr = geo.geo_abbr
                var edit_geo_type = geo.geo_type
                var edit_geoset_id = geo.geoset_id
                var edit_outlet_family = geo.outlet_family
                var edit_outlet_family_name = geo.outlet_family_name || []
                console.log(geo.global_attr_filters)
                geo.global_attr_filters.forEach(fltr => {
                    console.log(fltr)
                    
                    //var geo_global_attr_filters.push([fltr.geo_global_attr_code, fltr.geo_global_attr_id])

                    fltr.global_attr_code.forEach(attcode => {
                        edit_global_attr_filters.push({ 'global_attr_code': attcode, 'global_attr_id': fltr.global_attr_id })
                    })
                })
                geo.global_attr_filters.forEach(fltrname => {
                    console.log(fltrname)
                    fltrname.global_attr_names.forEach(attname => {
                        edit_global_attr_filters_name = [...edit_global_attr_filters_name,fltrname.global_attr_name + ' - ' + attname]
                        console.log(edit_global_attr_filters_name)

                       
                    })
                })
                this.setState({
                    edit_geo_allowed_channel_list, edit_geo_allowed_channel_list_names, edit_geo_allowed_channel_type_list, edit_geo_abbr, edit_geo_type, edit_global_attr_filters, edit_global_attr_filters_name,
                    edit_geo_allowed_channel_type_list_names, edit_outlet_family, edit_outlet_family_name, edit_geoset_id
                })
                console.log(this.state, 'GEO GLOBAL ATTR FILTERS geo_global_attr_filters')
                const new_geo_names = {
                    'allowed_channel_list':this.state.edit_geo_allowed_channel_list,
                    'allowed_channel_list_names' : this.state.edit_geo_allowed_channel_list_names,
                    'allowed_channel_type_list' : this.state.edit_geo_allowed_channel_type_list,
                    'allowed_channel_type_list_names' : this.state.edit_geo_allowed_channel_type_list_names,
                    'geo_abbr': this.state.edit_geo_abbr,
                    'geo_type': this.state.edit_geo_type,
                    'global_attr_filters': this.state.edit_global_attr_filters,
                    'global_attr_filters_name': this.state.edit_global_attr_filters_name,
                    'outlet_family': this.state.edit_outlet_family,
                    'outlet_family_name': this.state.edit_outlet_family_name,
                    'geoset_id' : this.state.edit_geoset_id
                }

                let editgeoNames = [...this.state.editgeoNames, new_geo_names]
                let geoNames = [...this.state.geoNames, new_geo_names]
                
                this.setState({ editgeoNames, geoNames })
                console.log(this.state)
            })
        }
    };

    async componentDidMount() {
       
        if (Constant.entrytype === 'edit') {
            await this.getLoadEtlDef()
        }
        await this.getLoadCountries()
        await this.getContainers()

        //Create Time Selections
        var timeOptions = []
            timeOptions_data.forEach(week => {
                timeOptions.push({ 'label': week.label, 'value': week.value })
            })
        this.setState({ timeOptions })
        console.log(timeOptions)

        //Create Item Selections
        var itemOptions = []
        itemOptions_data.forEach(item => {
            itemOptions.push({ 'label': item.label, 'value': item.value })
        })
        this.setState({ itemOptions })
        console.log(itemOptions)

        //Create Geo Type Selections
        var geoOptions = []
        geoOptions_data.forEach(geo => {
            geoOptions.push({ 'label': geo.label, 'value': geo.value })
        })
        this.setState({ geoOptions })
        console.log(geoOptions)
        console.log(this.state)

        //this.getJupyterServer()



        //Read in Company name
        let companies_response = await fetch(Constant.baseapiurl_gen + "Companies")
        if (companies_response.status === 200) {
            var companies_data = await companies_response.json()
        }
        else {
            companies_data = []
            this.setState({ errorAlert: true })
        }

        let company_options = [{ 'label': 'Add New', 'value': -1 }]
        companies_data.forEach(company => {
            if (company.company_id !== 1) {
                company_options.push({ 'label': company.company_name, 'value': company.company_id })
            }
        })
        this.setState({ companies: company_options })


    }

    onCheck(checked, targetNode) {
        console.log(checked, targetNode)

        this.setState({ checked });
        console.log(this.state.checked)

    }



    handleClickSaveGeo() {

        // if (Constant.entrytype === 'create') {
        if ((this.state.geo_abbr.length === 3) && (this.state.geo_type === "ALR Only" || this.state.geo_type === "ALR and RoM") && this.state.selectedOutlet_options.length < 1) {
            alert('You must choose an outlet for your ALR')

        }

        else if (this.state.geo_abbr.length === 3 ) {
            const new_Name = {
                'allowed_channel_list': this.state.selectedGeoChannel,
                'allowed_channel_list_names': this.state.selectedGeoChannelName,
                'allowed_channel_type_list': this.state.selectedGeoChannelType,
                'allowed_channel_type_list_names': this.state.selectedGeoChannelTypeName,
                'geo_abbr': this.state.geo_abbr,
                'geo_type': this.state.geo_type,
                'global_attr_filters_name': this.state.selectedGeoGlobalAttrName,
                'global_attr_filters': this.state.global_attr_filters,
                'outlet_family': this.state.geo_selectedOutlet,
                'outlet_family_name': this.state.selectedOutletName,
                'geoset_id':  null
            }
            var geoNames = [...this.state.geoNames, new_Name]

            //if (Constant.entrytype === 'create') {
            //    var geoNames = [...this.state.geoNames, new_Name]
            //}
            //else {
            //    var geoNames = [...this.state.editgeoNames, new_Name]
            //    console.log(geoNames,'GEONAMES ON SAVE GEO')
            //}
           



            const varMap = {}

            for (const filter of this.state.geo_global_attr_filters) {

                if (varMap[filter.geo_global_attr_id] == null) {
                    console.log(varMap[filter.geo_global_attr_id])

                    varMap[filter.geo_global_attr_id] = {
                        global_attr_id: filter.geo_global_attr_id,
                        global_attr_code: [filter.geo_global_attr_code]
                    }
                    console.log('global_attr_id:', filter.geo_global_attr_id)
                    console.log('global_attr_code:', [filter.geo_global_attr_code])

                } else {

                    varMap[filter.geo_global_attr_id].global_attr_code.push(filter.geo_global_attr_code)

                }

            }


            const finalVar = Object.values(varMap)
            console.log('FINAL VAR', finalVar)
            console.log(this.state.geos, 'GEOS')
            const new_geos = [...this.state.geos, {
                allowed_channel_list: new_Name.allowed_channel_list,
                allowed_channel_type_list: new_Name.allowed_channel_type_list,
                geo_abbr: new_Name.geo_abbr,
                geo_type: new_Name.geo_type,
                global_attr_filters: finalVar,
                geoset_id: new_Name.geoset_id,

                outlet_family: new_Name.outlet_family
            }]

            this.setState({ geos: new_geos, geoNames, geo_missing: false })
            console.log(this.state.geos, 'GEOS')
            this.handleClickAddGeo()
        }
    
            else {
                alert('The abbreviation must be exactly 3 characters and cannot be blank')
            }

    };

    handleClickDeleteGeo(index) {
        console.log(index)
        console.log(this.state.geoNames, 'GEONAMES BEFORE SLICING')
        let new_geoNames = this.state.geoNames.slice(0, index).concat(this.state.geoNames.slice(index + 1))
        console.log(new_geoNames, 'NEW_GEONAMES')
        let new_geos = this.state.geoNames.slice(0, index).concat(this.state.geoNames.slice(index + 1))
        console.log(new_geos, 'NEW_GEOS')
        this.setState({ geoNames: new_geoNames, geos: new_geos }, function () {
            console.log(this.state)
        })
        console.log(this.state)
    };

    handleClickAddGeo() {
        this.setState({
          selectedGeoChannel: [],
          selectedGeoChannelName: [],
         selectedGeoChannel_options: [],
          selectedGeoChannelType_options:[],
          geo_abbr: '',
          geo_type: '',
          selectedGeoGlobalAttrName:[],
          global_attr_filters: [],
          geo_global_attr_filters: [],
          geo_selectedOutlet: 0,
          selectedOutletName: '',

        })

    };

    



    render() {
        console.log(this.state)

        

        //const onChangeContainer = (selectedOptions, action) => {
        //    console.log(selectedOptions)
        //    var container_chosen = selectedOptions
        //    this.setState({ container_chosen })
        //}

        const openModalFunc = (etlid) => {
            console.log("The OpenFunc running")
            let selectedEtlIdModal = etlid;
            this.setState({ selectedEtlIdModal })
            this.setState({ runCloud: true,  openModal: true })
        }

        const closeModalFunc = () => {
            this.setState({ openModal: false });
        }
        const closeErrorModalFunc = () => {
            this.setState({ setOpenError: false, openModal: false });
        } 

        const handleCloseDialog_SaveRun = () => {
            //setOpenDialog(false)
            this.setState({ showBackdrop: false, setOpenSaveRun: false })
            if (Constant.entrytype === 'create') {
                window.location.href = Constant.baseurl + 'PNPETL/PNPEntryForm'
            }
            else {
                window.location.href = Constant.baseurl + 'PNPETL/PNPEntryForm'
            }
        }
        const handleCloseDialog_Error = () => {
            //setOpenDialog(false)
            this.setState({ showBackdrop: false, setOpenError: false, openModal: false  })
           
        }
        const handleCloseDialog_SaveOnly = () => {
            //setOpenDialog(false)
            this.setState({ showBackdrop: false, setOpenSaveOnly: false })
            if (Constant.entrytype === 'create') {
                window.location.href = Constant.baseurl + 'PNPETL/PNPEntryForm'
            }
            else {
                window.location.reload(true)
//                window.location.href = Constant.baseurl + 'PNPETL/PNPEntryForm' + this.state.etlIDEdit
            }
        }
        const handleCloseDialog_Status = () => {
            //setOpenDialog(false)
            this.setState({ showBackdrop: false, setOpenStatus: false })
            //window.location.href = Constant.baseurl + 'PNPETL/PNPEntryForm'
        }
      

        

        const loadJobStatus = async () => {
            // console.log('Loading Job Status')
            //Get the Config
            let url = Constant.baseapiurl_gen + 'Tasks/task_status/' + this.state.realtaskid
            //console.log(url)
            let datasetload = await fetch(url)
            let datasetloadres = datasetload.status
            if (datasetloadres === 202) {
                //console.log('Getting Task Status')
                let taskid = await datasetload.json()
                //console.log(taskid)
                let messages = taskid.message.split(" ")
                let realtaskid = messages[2]
                let taskurl = Constant.baseapiurl_gen + 'Tasks/task_status/' + realtaskid
                //console.log(taskurl)
                let taskstatus = await fetch(taskurl)
                let taskstatusres = taskstatus.status
                let taskstatusmessages = await taskstatus.json()
                //console.log(taskstatusres)
                //console.log(taskstatusmessages)
                this.setState({ showloadingbar: true })
                if (taskstatusres === 202) {
                    var runload = setInterval(async function () {
                        //console.log('Running 2 seconds later')
                        var newtaskstatus = await fetch(taskurl)
                        var newtaskstatusres = newtaskstatus.status
                        var newtaskstatusmessages = await newtaskstatus.json()
                        //console.log(newtaskstatusres)
                        var dataloadingmessages = []
                        //console.log(newtaskstatusmessages)
                        if (newtaskstatusres === 200) {
                            clearInterval(runload)
                            //alert('Run Complete')
                            dataloadingmessages = newtaskstatusmessages
                            this.setState({ showloadingbar: false, dataloadingmessages: dataloadingmessages })
                            window.location.href = Constant.baseurl + 'PNPETL/PNPEntryForm'

                        }
                        else if (newtaskstatusres !== 202) {
                            //console.log('There was Some Error')
                            clearInterval(runload)
                            dataloadingmessages = ['There was an Error'] //newtaskstatusmessages
                            this.setState({ showloadingbar: false, dataloadingmessages: dataloadingmessages })
                        }
                        else if (newtaskstatusres === 202) {
                            dataloadingmessages = newtaskstatusmessages.partial_status
                            this.setState({ dataloadingmessages: dataloadingmessages })
                        }

                    }.bind(this), 5000)

                }

            }
            else {
                console.log('There was an error!')
            }
        }

 
        const alloption = { 'label': 'Select All', 'value': '*' }
        return (
            <Layout title={'Create a new extract'}>
                <Constant.NPDBackdrop
                    open={this.state.showBackdrop}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>
                <div name='banner' className='banner'>
                    <h1white align='center'>{'Price and Promotion Data Extraction Form'}</h1white><br/>
                    <h2white align='center'>Follow the steps to create your extract.</h2white>
                </div>
                <br />
                
                <div className="contentHolderGradient" style={{ width: '100%', position: 'absolute', minHeight: '100vh', left: '120px', top: '210px', maxWidth: 'calc(100vw - 137px)', paddingLeft: '80px' }}>
                    {Constant.entrytype === 'create' ?
                        <h2>{'Create a new extract'}</h2>
                        :
                        <h2>{'Edit Extract #' + this.state.etlIDEdit}</h2>
                    }
                    <br />
                    <ThemeProvider theme={muiTheme}>
                        <Paper elevation={2} style={{ maxWidth: '95%' }}>
                        <Constant.NPDTabs
                        
                        value={this.state.tabIndex}
                            onChange={(e, val) => this.setState({ tabIndex: val })}
                            textColor="white"
                                indicatorColor="secondary"
                                aria-label="simple tabs example"
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                       
                    >
                       
                        <Constant.NPDTab
                            value={0}
                            label="1. REPORT/INDUSTRY OPTIONS"
                        />

                            <Constant.NPDTab
                            value={1}
                            label="2. DEFINE COMPETITIVE SET"
                        />
                            <Constant.NPDTab
                            value={2}
                            label="3. ITEM SELECTION"
                        />
                        <Constant.NPDTab
                            value={3}
                            label="4. GEOGRAPHIES / SAVE AND RUN"
                        />
                  
                        </Constant.NPDTabs>
                            <br />


                        </Paper>
                    </ThemeProvider>
                    <br/>
                    

                    {(this.state.tabIndex === 0) ?

                    <Paper elevation={2} style={{maxWidth: '95%'}}>
                            <span className='h7' style={{ paddingLeft: '10px' }}> Report and Industry Options </span><br/>
                            <span className='h6' style={{ paddingLeft: '10px' }}> <sup>*</sup> Denotes a required field</span><br /><br />


                    <div className="row" style={{ paddingLeft: '30px' }}>
                    </div>

                       
                    <div className='col-sm-12 row' style={{}}>
                      
                            <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                <div className='col-sm-3' >
                                       <span className='formfieldtitle'>Select number of weeks to be included in the output</span>
                                       <Select
                                            inputId="time_options"
                                            value={{ 'label': this.state.selectedTimeLabel, 'value': this.state.selectedTimeValue }}
                                            options={this.state.timeOptions}
                                            width="200px"
                                            defaultValue={[{ 'label': this.state.timeOptions[1], 'value': this.state.timeOptions[1] }]}
                                            onChange={(item, action) => this.onChangeTimeSelected(item, action)}
                                            styles={selectColourStyles}
                                        />
                                        <br/>

                                </div>
                                <div className='col-sm-12 row'>
                                    <div className='col-sm-3' >
                                        <span className='formfieldtitle'>Select a country<sup>*</sup></span>
                                        <Select
                                            inputId="countrydropdown"
                                            options={this.state.finalSortCountry}
                                                width="200px"
                                                required='true'
                                            onChange={(item, action) => this.onChangeCountry(item, action)}
                                            // value={this.state.selectedCountry}
                                            value={{ 'label': this.state.selectedCountryName, 'value': this.state.selectedCountry }}
                                                isDisabled={(Constant.entrytype === 'edit') ? true : false}
                                                styles={selectColourStyles}
                                        />
                                            {this.state.country_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  You must choose a country<br /><br /></span> : <span></span>}

                                    </div>
                                    <div className='col-sm-3' >
                                        <span className='formfieldtitle'>Select one industry<sup>*</sup></span>
                                        <Select
                                            inputId="industrydropdown"
                                            options={this.state.countryInd_options}
                                            width="200px"
                                            //placeholder={'Select one Industry'}
                                            onChange={(item, action) => this.onChangeIndustry(item, action)}
                                            value={{ 'label': this.state.selectedIndustryName, 'value': this.state.selectedIndustry }}
                                                isDisabled={(Constant.entrytype === 'edit') ? true : false}
                                                styles={selectColourStyles}
                                        />
                                        {this.state.industry_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  You must choose an industry<br /><br /></span> : <span></span>}
                                    </div>
                                   
                                    <div className='col-sm-3' style={{ paddingLeft: '20px' }}>
                                        <span className='formfieldtitle'>Channel Reporting Level</span>
                                        <Select
                                            inputId="channeloptionsdropdown"
                                                value={{ 'label': this.state.selectedChannelOption, 'value': this.state.selectedChannelOption }}
                                            options={this.state.channelOptions}
                                            width="200px"
                                                onChange={(item, action) => this.onChangeChannelOption(item, action)}
                                                styles={selectColourStyles}
                                        />
                                        </div>
                                    <div className='col-sm-12 row'>
                                            {this.state.finalSortAttr.length > 0 || Constant.entrytype === 'edit' ?
                                            <div className='col-sm-3'>
                                                <br />
                                                <span className='formfieldtitle'>Select category attributes to be included in the output</span>
                                                <Select
                                                    inputId="attrdropdown"
                                                    options={[alloption, ...this.state.finalSortAttr]}
                                                    width="200px"
                                                    styles={dropdownStyles}
                                                    onChange={(item, action) => this.onChangeAttributes(item, action)}
                                                    value={this.state.attrChosen}
                                                        isMulti
                                                        styles={selectColourStyles}
                                                />
                                            </div>
                                            :
                                            <div></div>
                                        }
                                       
                                        
                                            {this.state.glbl_attribute_value_data.length > 0 ?
                                                <div className='col-sm-3'>
                                                    <br />
                                                    <span className='formfieldtitle'>Select global attributes to be included in the output</span>
                                                    <Select
                                                        inputId="attrdropdown"
                                                        //options={[alloption, ...this.state.glbl_attribute_value_data]}
                                                        options={this.state.glbl_attribute_value_data}
                                                        width="200px"
                                                        styles={dropdownStyles}
                                                        onChange={(item, action) => this.onChangeGlblAttributes(item, action)}
                                                        value={this.state.glblAttrChosen}
                                                        isMulti
                                                        styles={selectColourStyles}
                                                    />
                                                
                                                <Fragment> 
                                                    <br />
                                                            <span className='formfieldNote'>Please be sure to select any global attributes here that you would like to include in your geography definitions (if they are filterable)</span>
                                                </Fragment>
                                                </div>


                                                :
                                                <div></div>
                                                }
                                                <br />
                                                <br />


                                            </div>
                                     
                                    {/*<div className="row" style={{ paddingLeft: '30px', paddingTop: '20px' }}>*/}
                                    {/*    <span className='formfieldtitle'>Do you have causal data?</span>*/}
                                    {/*</div>*/}
                                    {/*<div className='col-sm-12' style={{}}>*/}
                                    {/*    <div className='col-sm-3'>*/}
                                    {/*        {this.state.causal_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please select whether or not there is causal data<br /><br /></span> : <span></span>}*/}
                                    {/*        <ThemeProvider theme={muiTheme}>*/}
                                    {/*            <FormControl component="fieldset" >*/}
                                    {/*                <RadioGroup row aria-label="causal" name="causal" onChange={this.onChangeCausal} margin-left="40px" value={this.state.selectedCausal}>*/}
                                    {/*                    <FormControlLabel value="Yes" control={<GreenRadio />} label="Yes" />*/}
                                    {/*                    <FormControlLabel value="No" control={<GreenRadio />} label="No" />*/}
                                    {/*                </RadioGroup>*/}
                                    {/*            </FormControl>*/}
                                    {/*        </ThemeProvider>*/}
                                    {/*    </div>*/}

                                    {/*    {this.state.selectedCausal === "Yes" ?*/}

                                    {/*                <div className='col-sm-8' style={{ height: '50%', borderRadius: '5px', }}>*/}
                                    {/*                    <span className='formfieldtitle'>Enter full file path and name of the causal file.  Must be a SAS file and end with .sas7bdat <sup>*</sup></span><br/>*/}
                                    {/*            <TextField*/}
                                    {/*                type="text"*/}
                                    {/*                size="small"*/}
                                                    
                                    {/*                disableUnderline="false"*/}
                                    {/*                inputProps={{ 'aria-label': 'naked', id: "ppgfile" }}*/}
                                    {/*                onChange={this.onChangeCausalName}*/}
                                    {/*                style={dropdownInput}*/}
                                    {/*                value={this.state.causal_filename}*/}
                                    {/*                placeholder={'Enter full file path and name of causal file (*.sas7bdat)'}*/}
                                    {/*                    />*/}
                                    {/*                    {this.state.causal_filename_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  You must enter the full path and name<br /><br /></span> : <span></span>}*/}
                                    {/*        </div>*/}
                                    {/*        :*/}
                                    {/*        <div />*/}
                                    {/*    }*/}
                                    {/*        </div>*/}
                                        </div>
                                       
                                
                                    <div classname='col-sm-12 row' style={{ padding: '10px', paddingLeft: '10px' }}>
                                        <span className='formfieldtitle' >Short description which will display on client dataset dropdown (i.e. US - Tablets)<sup>* - 25 characters max</sup></span>
                                        <div className='col-sm-8' style={{ padding: '5px' }}>
                                            
                                            <div className="" style={{ height: '50%', borderRadius: '5px', }}>
                                                <TextField
                                                    id="custom-css-standard-input"
                                                    label=""
                                                    type="text"
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{ variant: 'standard', 'aria-label': 'naked', id: "shortdesc", maxLength: 25 }}
                                                    onChange={this.onChangeShortDesc}
                                                    id="shortdesc"
                                                    style={dropdownInput}
                                                    value={this.state.selectedShortDesc}
                                                    disabled={(Constant.entrytype === 'edit') ? true : false}
                                                    />
                                                    {this.state.short_desc_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Short description must be provided<br /></span> : <span></span>}

                                            </div>
                                        </div>
                                        <span className='formfieldtitle' >Enter an ETL abbreviation.  It must be 3 letters and is recommended to be in all CAPS<sup>*</sup></span>
                                        <div className='col-sm-4' style={{ padding: '5px' }}>
                                            
                                            <div className="" style={{ height: '50%', borderRadius: '5px', paddingLeft: '10px',}}>
                                                <TextField
                                                    id="custom-css-standard-input"
                                                    label=""
                                                    type="text"
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{ variant: 'standard', 'aria-label': 'naked', id: "etlabbr", maxLength: 3, style: { textAlign: 'center', width: '100px', paddingLeft: '10px', }  }}
                                                    onChange={this.onChangeAbbr}
                                                    id="abbr"
                                                    //style={dropdownInput}
                                                    value={this.state.selectedAbbr}
                                                    disabled={(Constant.entrytype === 'edit') ? true : false}
                                                    />
                                                    < br />
                                                    {this.state.short_desc_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Abbreviation must not be blank<br /></span> : <span></span>}

                                            </div>
                                        </div>
                                   

                                       
                                        <div className='col-sm-12' style={{ padding: '5px' }}>
                                            <span className='formfieldtitle'>Extract Name (For internal use only and will display in saved extracts folder.  It should be more descriptive than the short name) </span>
                                            
                                            <div className="" style={{ height: '50%', borderRadius: '5px', }}>
                                                <TextField
                                                    id="custom-css-standard-input"
                                                    label=""
                                                    type="text"
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{ variant: 'standard', 'aria-label': 'naked', id: "reportname" }}
                                                    onChange={this.onChangeReportName}
                                                    id="reportname"
                                                    style={dropdownInput}
                                                    value={this.state.selectedReportName}
                                                    />
                                                    < br />
                                                    {this.state.report_name_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Extract name must be provided<br /></span> : <span></span>}

                                            </div>
                                        </div>
                                    

                                        
                                        <div className='col-sm-8' style={{ padding: '5px' }}>
                                            <span className='formfieldtitle' >Enter the email address for job notifications (use a , to separate multiple)<sup>*</sup>  </span>
                                            <div className="" style={{ height: '50%', borderRadius: '5px', }}>
                                                <TextField
                                                    type="text"
                                                    size="small"
                                                    variant="outlined"
                                                    disableUnderline="false"
                                                    inputProps={{ 'aria-label': 'naked', id: "emailaddress" }}
                                                    onChange={this.onChangeEmail}
                                                    id="email"
                                                    style={dropdownInput}
                                                    value={this.state.selectedEmailAddress}
                                                    />
                                                   
                                                    {this.state.email_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Email address required<br /></span> : <span></span>}

                                            </div>
                                        </div>
                                   
                                    </div>


                            </div>
                        </div>
                                    <br />
                               
                                </Paper>
                           
                        :
                        <div></div>
                    }
                               
                        

                    {/******************************************************************* COMPETITIVE SET SECTION *******************************************************/}
                    {(this.state.tabIndex === 1) ?
                        <Paper elevation={2} style={{ maxWidth: '95%' }}>

                            <span className='h7' style={{ paddingLeft: '10px' }}>Competitive Set Definitions</span><br />
                            <span className='h6' style={{ paddingLeft: '10px' }}> <sup>*</sup> Denotes a required field</span><br /><br />
                            <div className='col-sm-12 row'>


                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>{'Select a Level of the Hierarchy'}<sup>*</sup></span>
                                    <Select
                                        inputId="catlevdropdown"
                                        options={this.state.cs_catLevelSel}
                                        width="200px"
                                        styles={dropdownStyles}
                                        onChange={(item, action) => this.onChangeCSCatLevel(item, action)}
                                        value={{ 'label': this.state.selectedCSCatName, 'value': this.state.selectedCSCatValue }}
                                        styles={selectColourStyles}
                                    />
                                    {this.state.cs_hierarchy_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please make a selection<br /><br /></span> : <span></span>}
                                </div>
                                <div className='col-sm-3'>
                                </div>
                                <div className='row-sm-3' style={{ height: '50%', borderRadius: '5px', paddingLeft: '10px', }}>
                                    <span className='formfieldtitle'>Competitive Set Price Threshold (1-100)</span>
                                    <TextField
                                        type="text"

                                        size="small"
                                        disableUnderline="false"
                                        inputProps={{ 'aria-label': 'naked', id: "compsetthresh", maxLength: 3, style: { textAlign: 'center', width: '100px', } }}
                                        onChange={this.onChangeCompSetThresh}
                                        id="compsetthresh"
                                        //style={dropdownInput}3
                                        value={this.state.compset_price_threshold}
                                        isClearable
                                    />
                                    {this.state.cs_priceThresh_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please enter a number 1-100<br /><br /></span> : <span></span>}
                                </div>

                                <div className='col-sm-12 row'>
                                    {this.state.finalSortCompSetAttr.length > 0 ?
                                        <div className='col-sm-3'>
                                            <br />
                                            <span className='formfieldtitle'>Select attributes to be used as competitive set constraints</span>
                                            <Select
                                                inputId="attrdropdown"
                                                options={this.state.finalSortCompSetAttr}
                                                width="200px"
                                                styles={dropdownStyles}
                                                //placeholder={'Select a maximum of 5 attributes'}
                                                onChange={(item, action) => this.onChangeCompSetAttributes(item, action)}
                                                value={this.state.compSetAttrChosen}
                                                isMulti
                                                styles={selectColourStyles}
                                            />
                                        </div>
                                        :
                                        <div></div>
                                    }


                                    {this.state.compset_glbl_attribute_value_data.length > 0 ?
                                        <div className='col-sm-3'>
                                            <br />
                                            <span className='formfieldtitle'>Select global attributes to be used as competitive set constraints</span>
                                            <Select
                                                inputId="csglblattrdropdown"
                                                options={this.state.compset_glbl_attribute_value_data}
                                                width="200px"
                                                styles={dropdownStyles}
                                                //placeholder={'Select a maximum of 5 attributes'}
                                                onChange={(item, action) => this.onChangeCompSetGlblAttributes(item, action)}
                                                value={this.state.cs_glblAttrChosen}
                                                isMulti
                                                styles={selectColourStyles}
                                            />
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <br />
                            <br />
                        </Paper>
                        :
                        <div></div>
                    }
                   
                  

                            {/******************************************************************* ITEM SELECTION SECTION *******************************************************/}
                    {(this.state.tabIndex === 2) ?
                        <Paper elevation={2} style={{ maxWidth: '95%' }}>


                           
                                <span className="h7" style={{ paddingLeft: '10px' }}>Item Selections</span><br />
                                <span className='h6' style={{ paddingLeft: '10px' }}> <sup>*</sup> Denotes a required field</span><br /><br />
                           
                            <div className='col-sm-3'>
                                <span className='formfieldtitle'>{'Item Selection Type'}<sup>*</sup></span>
                                <Select
                                    inputId="catlevdropdown"
                                    width="200px"
                                    styles={dropdownStyles}
                                    value={(Constant.entrytype === 'edit') ? [{ 'label': this.state.edititem_type, 'value': this.state.edititem_type_value }] : [{ 'label': this.state.item_type, 'value': this.state.item_type_value }]}
                                    options={this.state.itemOptions}
                                    onChange={(item, action) => this.onChangeItemType(item, action)}
                                    isDisabled={(Constant.entrytype === 'edit') ? true : false}
                                    styles={selectColourStyles}

                                />
                                {this.state.item_type_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please make a selection<br /><br /></span> : <span></span>}

                                <br />

                            </div>
                            {this.state.item_type === "PPGs" ?

                                <div className='col-sm-12' style={{ paddingLeft: '10px' }}>
                                     <div className='col-sm-4' style={{ paddingLeft: '10px' }}>
                                        <span className='formfieldtitle'>Choose the container where your file is saved</span>
                                      
                                            <Select
                                                inputId="container"
                                                styles={selectColourStyles}
                                                options={this.state.containers}
                                                placeholder={'Azure Container for Upload into Cloud'}
                                                onChange={(item, action) => this.onChangeContainer(item, action)}
                                                //value={(Constant.entrytype === 'edit') ? this.state.edit_container_id : this.state.this.state.container_chosen}
                                                value={this.state.container_chosen}
                                                //value={(Constant.entrytype === 'edit') ? [{ 'label': this.state.edititem_type, 'value': this.state.edititem_type_value }] : [{ 'label': 'Default', 'value': null }]}
                                            />
                                        <br/>
                                       
                                     </div>
                                    <div className='col-sm-4' style={{ paddingLeft: '10px' }}>
                                        <span className='formfieldtitle'>Enter full file path and name of PPG List.  Must be a CSV file and end with .csv</span>
                                    
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                color="#4E106F"
                                                borderColor="000000"
                                                disableUnderline="false"
                                                inputProps={{
                                                    'aria-label': 'naked', id: "ppgfile",  }}
                                            
                                                onChange={this.onChangePPGFile}
                                                style={dropdownInput}
                                                value={this.state.ppgName}
                                                defaultValue={(Constant.entrytype === 'edit') ? this.state.edititem_file_fullpath : this.state.ppgName}
                                                placeholder={'full path and filename .csv'}
                                            />
                                            < br/>
                                            {this.state.ppg_filename_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please enter the full path and filename for your PPGs<br /><br /></span> : <span></span>}

                                     
                                    </div>
                                    <br />
                                     <div className='col-sm-12 ' style={{ paddingLeft: '15px' }}>
                                        <div>
                                                <p>For PPGs you need to have 3 columns in your file </p>
                                                <p>1. fieldname: <b>ppg</b>, type: integer, desc: a PPG ID</p>
                                                <p>2. fieldname: <b>ppg_name</b>, type: string, desc: a PPG Name</p>
                                            <p><span>3. fieldname: </span><b>{this.state.itemnumberuns}</b><span>, type: integer, desc: the unsuppressed item id fieldname</span></p>
                                        </div>
                                    </div>
                                </div>


                                :
                                <div />
                            }

                            {this.state.item_type === "Item List" ?
                                <div className='col-sm-12' style={{ paddingLeft: '10px' }}>
                                    <div className='col-sm-4' style={{ paddingLeft: '10px' }}>
                                        <span className='formfieldtitle'>Choose the container where your file is saved</span>
                                            <Select
                                                inputId="container"
                                                styles={selectColourStyles}
                                                options={this.state.containers}
                                                placeholder={'Azure Container for Upload into Cloud'}
                                                onChange={(item, action) => this.onChangeContainer(item, action)}
                                                //value={(Constant.entrytype === 'edit') ? this.state.edit_container_id : this.state.this.state.container_chosen}
                                                value={this.state.container_chosen}
                                            />
                                        
                                    {this.state.item_type_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please make a selection<br /><br /></span> : <span></span>}

                                    <br />


                                        <span className='formfieldtitle'>Enter full file path and name of item list file.  Must be a CSV file and end with .csv</span>
                                        <div className='col-sm-12' style={{ paddingLeft: '5px', height: '50%', borderRadius: '5px' }}>
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                disableUnderline="false"
                                                inputProps={{ 'aria-label': 'naked', id: "itemlistfile" }}
                                                onChange={this.onChangeItemFile}
                                                style={dropdownInput}
                                                //value={this.state.itemFileName}
                                                value={(Constant.entrytype === 'edit') ? this.state.edititem_file_fullpath : this.state.itemFileName}
                                                placeholder={'full path and filename .csv'}
                                            />
                                            < br />
                                            {this.state.item_filename_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please enter the full path and filename for your Item List<br /><br /></span> : <span></span>}

                                        </div>
                                    </div>
                                        <br/>                                    
                                        <div>
                                            <p>The Item List file needs to have the following 3 columns (each item will be treated as a separate ppg) </p>
                                            <p>1. fieldname: <b>ppg</b>, type: integer, use the Item ID as the PPG ID</p>
                                            <p>2. fieldname: <b>ppg_name</b>, type: string, use the item description as the PPG name</p>
                                            <p><span>3. fieldname: </span><b>{this.state.itemnumberuns}</b><span>, type: integer, desc: the unsuppressed item id fieldname</span></p>
                                        </div>
                                    </div>
                                :
                                <div />
                            }


                            {this.state.item_type === "Criteria" || this.state.edititem_type === "Criteria" ?
                                <div>
                                    <div className='row-sm-3' style={{ paddingLeft: '10px', height: '50%', borderRadius: '5px', }}>
                                        <span className='formfieldtitle'>Top N Percent (1-100)<sup>*</sup></span>
                                        <TextField
                                            type="text"
                                            size="small"
                                            disableUnderline="false"
                                            inputProps={{ 'aria-label': 'naked', id: "topnpercent", inputMode: 'numeric', pattern: '[0-9]*', maxLength: 3, style: { textAlign: 'center', width: '100px', }, }}
                                            onChange={this.onChangeTopNPercent}
                                            id="topnpercent"
                                            //value={(Constant.entrytype === 'edit') ? this.state.edittop_n_percent : this.state.top_n_percent}
                                            value={ this.state.top_n_percent}
                                            isClearable 
                                        />
                                       {this.state.top_n_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please enter a number 1-100<br /><br /></span> : <span></span>}

                                    </div>
                                    <br />

                                    <div className='row-sm-3' style={{ paddingLeft: '10px', height: '50%', borderRadius: '5px', }}>
                                        <span className='formfieldtitle'>Maximum Item Count<sup>*</sup></span>
                                        <TextField
                                            type="number"
                                            size="small"
                                            disableUnderline="false"
                                            inputProps={{ 'aria-label': 'naked', id: "maxitems", inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'center', width: '100px' } }}
                                            onChange={this.onChangeMaxItems}
                                            id="maxitems"
                                            //value={(Constant.entrytype === 'edit') ? this.state.editmax_item_count : this.state.max_item_count}
                                            value={this.state.max_item_count}
                                            isClearable
                                        />
                                        {this.state.max_item_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please enter a number 1-100<br /><br /></span> : <span></span>}

                                    </div>
                                    <br />
                                    <br />
                                    <div className='col-sm-3'>
                                        <span className='formfieldtitle'>{'Select a Level of the Hierarchy'}<sup>*</sup></span>
                                        <Select
                                            inputId="catlevdropdown"
                                            options={this.state.catLevelSel}
                                            width="200px"
                                            styles={dropdownStyles}
                                            onChange={(item, action) => this.onChangeCatLevel(item, action)}
                                            value={this.state.selectedCat}
                                            styles={selectColourStyles}
                                        />
                                        {this.state.calLevel_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please make a selection<br /><br /></span> : <span></span>}
                                    </div>

                                    <div className='col-sm-3'>
                                        <span className='formfieldtitle'>{'Select ' + (this.state.selectedCategoryLevelName || [])}<sup>*</sup></span>
                                        <Select
                                            inputId="catdropdown"
                                            options={[alloption, ...this.state.hierarchy_options]}
                                            width="200px"
                                            styles={dropdownStyles}
                                            placeholder={'search by entering text here'}
                                            onChange={(item, action, hierarchy_options) => this.onChangeCatSelected(item, action, this.state.hierarchy_options)}
                                            value={this.state.Catchosen}
                                            isMulti
                                            isSearchable
                                            isClearable={true}
                                            styles={selectColourStyles}
                                        />
                                        {this.state.catList_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Please make a selection<br /><br /></span> : <span></span>}
                                        {this.state.cat_size_large ? < span style={{ color: '#f0ab00' }}><WarningRoundedIcon style={{ color: '#f0ab00' }} />  Warning! These selections represent more than 50% of the total industry and may take a long time to run.</span> : <span></span>}
                                    </div>

                                </div>
                                :
                                <div />
                            }
                            <br />
                        </Paper>
                        :
                        <div></div>
                    }

                   

                    {/******************************************************************* GEOGRPAHY SECTION *******************************************************/}
                    {(this.state.tabIndex === 3) ?
                        <Fragment>
                            <Paper elevation={2} style={{ maxWidth: '95%' }}>
                                <span className='h7' style={{ paddingLeft: '10px' }}>Geography Selections</span><br />
                                <span className='h6' style={{ paddingLeft: '10px' }}> <sup>*</sup> Denotes a required field</span><br /><br />
                                {this.state.geo_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  You must define at least one geography set<br /><br /></span> : <span></span>}

                               
                                <div className='col-sm-8' style={{ paddingLeft: '10px' }}>
                                        <span className='h6' >Define your geographic sets.</span>
                                    <br />
                                    <form onSubmit={this.onSubmitGeo}>
                                        <div className='col-sm-8 ' style={{ paddingLeft: '10px' }}>
                                            <span className='formfieldtitle' >Enter an abbreviation for your GEO.  It MUST be exactly 3 letters, unique, and case matters. (Please note that a T/A/R will automatically be added to the end of the name based on your geography type selected, so it isn't necessary to include in your abbreviation)</span>
                                        <div className='col-sm-1'  style={{ height: '50%', borderRadius: '5px'}}>
                                            <TextField
                                                id="custom-css-standard-input"
                                                label=""
                                                type="text"
                                                size="small"
                                                inputProps={{ id: "geoabbr", maxLength: 3, style: { textAlign: 'center', width: '100px' }  }}
                                                onChange={this.onChangeGeoAbbr}
                                                id="geoabbr"
                                                value={this.state.geo_abbr}
                                                //disabled={(Constant.entrytype === 'edit') ? true : false}
                                            />
                                            </div>
                                            </div>
                            
                                        <br />
                                            <div className='col-sm-3' style={{ paddingLeft: '20px' }}>
                                            <span className='formfieldtitle'>{'Select Geography Type'}</span>
                                            <Select
                                                inputId="catlevdropdown"
                                                width="200px"
                                                styles={dropdownStyles}
                                                value={{ 'label': this.state.geo_type, 'value': this.state.geo_type_value }}
                                                options={this.state.geoOptions}
                                                onChange={(item, action) => this.onChangeGeoType(item, action)}
                                                styles={selectColourStyles}

                                            />
                                        </div>
                                        {this.state.geo_type === "ALR Only" || this.state.geo_type === "ALR and RoM" || this.state.geo_type === "RoM Only" ?
                                 
                                                    <div className='col-sm-4' style={{ paddingLeft: '20px' }}><br />
                                                        <span className='formfieldtitle'>{'Select an outlet for the ALR'}<sup>*</sup></span>
                                                        <Select
                                                            inputId="outletfamdropdown"
                                                            options={this.state.alrOutlet}
                                                            width="200px"
                                                            placeholder={'Select a single outlet for ALR'}
                                                            onChange={(item, action) => this.onChangeOutlet(item, action)}
                                                            value={this.state.selectedOutlet_options}
                                                            isClearable
                                                            styles={selectColourStyles}
                                                />
                                                    </div>
                                                    :
                                                <div />
                                   
                                                }
                                                {this.state.selectedChannelOption === 'Channel' ?

                                                <div className='col-sm-3' style={{ paddingLeft: '20px' }}><br />
                                                        <span className='formfieldtitle'>Channel</span>
                                                        <Select
                                                            inputId="channeloptionsdropdown"
                                                            value={this.state.selectedGeoChannel_options}
                                                            options={this.state.alrChannel_options}
                                                            width="200px"
                                                            onChange={(item, action) => this.onChangeGeoChannel(item, action)}
                                                            isMulti
                                                            styles={selectColourStyles}
                                                        />
                                                    </div>
                                                    :
                                                    <div></div>
                                                }

                                                {this.state.selectedChannelOption === 'Channel Type' ?
                                                <div className='col-sm-3' style={{ paddingLeft: '20px' }}><br />
                                                        <span className='formfieldtitle'>Channel Type</span>
                                                        <Select
                                                            inputId="channeloptionsdropdown"
                                                            value={this.state.selectedGeoChannelType_options}
                                                            options={this.state.geoChannelType }
                                                            width="200px"
                                                            isMulti
                                                            onChange={(item, action) => this.onChangeGeoChannelType(item, action)}
                                                            styles={selectColourStyles}

                                                        />
                                                    </div>
                                                    :
                                                    <div></div>
                                                }   

                                                {this.state.glbl_attr_sub_options.length > 0 ?
                                                <div className='col-sm-4' style={{ paddingLeft: '20px' }}><br />
                                                <span className='formfieldtitle'>Global Attribute Value Filters<br />This is not a required field, however if you choose a filter only those rows that match your selection will be included</span>
                                                        <Select
                                                            inputId="geoglblattrfiltersdropdown"
                                                            value={this.state.geo_global_attr_filters}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.geo_global_attr_code}
                                                            //value={this.state.glbl_attr_sub_options.map((glblattrName, idx) => { return { 'label': this.state.selectedGeoGlobalAttrCode[idx], 'value': this.state.selectedGeoGlobalAttrCode[idx] } })}
                                                           options={this.state.glbl_attr_sub_options}
                                                            width="200px"
                                                            isMulti
                                                            onChange={(item, action) => this.onChangeGeoGlblAttrFilters(item, action)}
                                                            styles={selectColourStyles}

                                                    />
                                                    </div>
                                                    :
                                                <div></div>

                                                }

                                            <br />
                                            <br />

                                        <div className='col-sm-6 row'>
                                            <div style={dateInput} className='col-sm-3'>
                                                <Button variant="contained"
                                                    onClick={this.handleClickSaveGeo}
                                                   style={buttonStyle4}
                                                    id="runbutton">
                                                    Save Geo
                                                </Button>
                                            </div>
                                        </div>
                                        </form>

                                </div>

                                <br />
                                <br />

                                <span className='h7' style={{ paddingLeft: '10px' }}>Selected Geographies</span>

                               
                                <Table
                                    id="reporTable"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '5%' }}><span className='greenHeaderSmall'>Geo Abbr</span> </TableCell>
                                            <TableCell style={{ width: '15%' }}> <span className='greenHeaderSmall'>Geo Type</span> </TableCell>
                                            <TableCell style={{ width: '15%' }}> <span className='greenHeaderSmall'>ALR Outlet</span> </TableCell>
                                            <TableCell style={{ width: '20%' }}> <span className='greenHeaderSmall'>Channel</span> </TableCell>
                                            <TableCell style={{ width: '20%' }}> <span className='greenHeaderSmall'>Channel Type</span> </TableCell>
                                            <TableCell style={{ width: '20%' }}><span className='greenHeaderSmall'>Global Attr Values</span></TableCell>
                                            <TableCell style={{ width: '1%' }}> </TableCell>
                                            <TableCell style={{ width: '1%' }}> </TableCell>
                                        </TableRow>
                                    </TableHead>

                                   
                                    <TableBody>
                                        {this.state.geoNames.map((geolist, index) => {
                                            
                                            return <TableRow key={[index]}>
                                                <TableCell> {geolist.geo_abbr} </TableCell>
                                                <TableCell> {geolist.geo_type} </TableCell>
                                                <TableCell> {geolist.outlet_family_name} </TableCell>
                                                <TableCell> {geolist.allowed_channel_list_names} </TableCell>
                                                <TableCell> {geolist.allowed_channel_type_list_names} </TableCell>
                                                <TableCell> {geolist.global_attr_filters_name} </TableCell>
                                                <TableCell >
                                                    <Tooltip title="Delete" arrow>
                                                        <DeleteForeverIcon onClick={(geoNames_id) => this.handleClickDeleteGeo(index)} style={{ color: '#822433' }} />
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>


                                </Table>
                               

                            </Paper>
                       
                            <br />
                            <br />
                            <br />
                       
                    
                    <Paper elevation={2} style={{ maxWidth: '95%' }}>
                                <span className='h7' style={{ paddingLeft: '10px' }}>Finish and Save</span><br />
                            <br />
                            <div className='col-sm-12 row'> <br /><br />
                                <div style={dateInput} className='col-sm-3'>
                                        {/*
                                        <Button variant="contained" onClick={this.handleClickSaveOnly} style={buttonStyle} id="runbutton">
                                       Save Only
                                        </Button>
                                        */}
                                        <Button variant="contained" onClick={(saveOnly) => this.PNPhandleClickSave(true)} style={buttonStyle} id="runbutton">
                                            Save Only
                                        </Button>
                                </div>
                                    <div style={dateInput} className='col-sm-3'>
                                        {/*
                                    <Button variant="contained" onClick={(etl_id) => openModalFunc((etl_id))} style={buttonStyle2} id="runbutton">
                                        Save and Run
                                        </Button>
                                        */}
                                        <Button variant="contained" onClick={(etl_id) => openModalFunc((etl_id))} style={buttonStyle2} id="runbutton">
                                            Save and Run
                                        </Button>
                                </div>
                                 {Constant.entrytype === 'create' ?
                                    <div style={dateInput} className='col-sm-3'>
                                        <Button variant="contained" onClick={this.handleClickcancel} style={buttonStyle3}>
                                            Clear Form
                                                </Button>
                                    </div>
                                    :
                                    <div></div>
                                }
                                {/*<div style={dateInput} className='col-sm-3'>*/}
                                {/*    <Tooltip title="After clicking, the job status will appear in window below" arrow>*/}
                                {/*        <Button variant="contained" onClick={loadJobStatus}>*/}
                                {/*            View Job Status*/}
	                               {/*     </Button>*/}
                                {/*    </Tooltip>*/}
                                {/*    </div>*/}
                                    <Dialog
                                        open={this.state.setOpenSaveOnly}
                                        onClose={handleCloseDialog_SaveOnly}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Extract Saved"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Your extract configuration has been saved.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <button onClick={handleCloseDialog_SaveOnly} className="btn btn-secondary">
                                                Ok
                                            </button>
                                        </DialogActions>
                                    </Dialog>

                                    <Dialog
                                        open={this.state.setOpenSaveRun}
                                        onClose={handleCloseDialog_SaveRun}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Extract Saved and Submitted"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Your extract job has been saved and submitted.  An email will be sent when the job is complete.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <button onClick={handleCloseDialog_SaveRun} className="btn btn-secondary">
                                                Ok
                                            </button>
                                        </DialogActions>

                                    </Dialog>

                                </div>
                                <br />
                                <br />
                                </Paper>
                            
                        {/*    <Paper>*/}
                        {/*         <div className="row col-sm-9" style={{ padding: '20px', overflowY: 'auto', maxHeight: '250px', backgroundColor: "#FFF", borderRadius: '5px' }}>*/}
                        {/*             {this.state.dataloadingmessages.map(message => { return <p>{message}</p> })}*/}
                        {/*         </div>*/}
                        {/*</Paper>*/}
                        </Fragment>
                        :
                        <div></div>
                    }

                    <br />
                    <br />
                    <br />
                    <div className="row" style={{ padding: '20px' }}>
                    </div>
                </div>
               
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{ overflowY: 'scroll' }}
                    open={this.state.setOpenError}
                    onClose={closeErrorModalFunc}
                >
                    <div style={{ paddingLeft: '20px', paddingRight: '10px', overflowY: 'auto', borderRadius: '3', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '900px', backgroundColor: '#FFF' }} >
                        <h6 id="simple-modal-title"> There are required fields with no selections.  Click Ok below and fix all fields with <ErrorRoundedIcon style={{ color: '#822433' }} />.  Once fixed, submit again.</h6>

                            <div style={dateInput} className='col-sm-9'>

                                <button
                                onClick={closeErrorModalFunc}
                                    className="btn btn-primary"
                                    style={{ margin: '10px' }}
                                >
                                    Ok
                                </button>

                        </div>
                    </div>

                   
              </Modal>

              

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{ overflowY: 'scroll' }}
                    open={this.state.openModal}
                    onClose={closeModalFunc}
                >
                    <div style={{ paddingLeft: '20px', paddingRight: '10px', overflowY: 'auto', borderRadius: '3', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '900px', backgroundColor: '#FFF' }} >
                        <h6 id="simple-modal-title">Save and Run Options</h6>



                        {(this.state.runCloud === true ) ?
                            <div>

                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.skipExtract}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Skip extraction (the extract must already exist or it will fail)' }}
                                                onChange={this.onChangeSkipExtract}
                                            />
                                    Skip Extract?
                            </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Skip extraction (the extract must already exist or it will fail)'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.skipCalculations}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'Skip calculations and go straight to post-calculation code' }}
                                                onChange={this.onChangeSkipCalculations}
                                            />
                                    Skip Calculations?
                            </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'Skip calculations and go straight to the post-calculation code'}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 row' style={{ paddingLeft: '20px' }}>
                                    <div className='col-sm-4'>
                                        <ThemeProvider theme={Constant.muiTheme}>
                                            <Checkbox
                                                value={this.state.resumeIfPossible}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': 'For jobs going partition by partition, resume if it is possible to do so' }}
                                                onChange={this.onChangeResumeIfPossible}
                                            />
                                    Resume if Possible?
                            </ThemeProvider>
                                    </div>
                                    <div className='col-sm-8 row' style={{ paddingTop: '10px' }}>
                                        <span className="h6" >{'For jobs running partition by partition that fail, resume if it is possible to do so'}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                           

                        <div className='col-sm-12 row' style={{ padding: '10px' }}>
                            <div style={dateInput} className='col-sm-3'>
                                <button
                                    onClick={closeModalFunc}
                                    className="btn btn-primary"
                                    style={{ margin: '10px' }}
                                >
                                    Cancel
                                         </button>
                            </div>
                            <div style={dateInput} className='col-sm-9'>

                                <button
                                    onClick={(saveOnly) => this.PNPhandleClickSave(false)}
                                    className="btn btn-primary"
                                    style={{ margin: '10px' }}
                                >
                                    Run
                                         </button>

                            </div>

                        </div>


                    </div>
                </Modal>
               


           
            </Layout>
               
                   

        )
    }
}

