import React, { Fragment, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'

const ALRvsROM = props => {    

    const [chartOptions, setChartOptions] = useState(getChartOptions(props.outlets))


    function getChartOptions(outlets) {

        return {
            chart: {
                type: 'bar',
                height: (9 / 16 * 100) + '%', //16:9 ratio
                events: {
                    redraw: function resize() {
                        console.log('Chart has Been Redrawn')                                                                        
                        //window.dispatchEvent(new Event('resize'));                                    
                    }
                    , load: function resize() {
                        console.log('Chart has Loaded')                                    
                    }
                }
            },
            metadata: props.chartmetadata,
            caption: {
                text: props.attr_caption_text
            },
            title: {
                text: props.metric + ' - Retailer vs ROM ' + props.chart_title_info
            },
            series: outlets.map((outlet, idx) => {
                var graphcolor, y, units_dollars, units_dollars_type, metrictopull, totalmetrictopull, data
                if (idx === 1) {
                    graphcolor = Constant.graph_colors[2]
                }
                else {
                    graphcolor = Constant.graph_colors[idx]
                }
        
                if (props.usedollars) {
                    units_dollars = 'dollars'
                }
        
                else {
                    units_dollars = 'units'
                }
        
                if (props.metric === 'Non-Promoted') {
                    units_dollars_type = 'nonpromoted'
                }
        
                else if (props.metric === 'Promoted') {
                    units_dollars_type = 'promoted'
                }
        
                else if (props.metric === 'Incremental') {
                    units_dollars_type = 'incremental'
                }
        
                else if (props.metric === 'UnSubsidized Base') {
                    units_dollars_type = 'unsubsidizedbase'
                }
        
                else if (props.metric === 'Subsidized Base') {
                    units_dollars_type = 'subsidizedbase'
                }
        
                else if (props.metric === 'Base') {
                    units_dollars_type = 'base'
                }
        
                else if (props.metric === 'Efficiency') {
                    units_dollars_type = 'incremental'
                }
        
                else if (props.metric === 'Promoted Lift') {
                    units_dollars_type = 'incremental'
                }
        
                else if (props.metric === 'Market Lift') {
                    units_dollars_type = 'incremental'
                }
        
                else if (props.metric === 'Average Promoted Discount %') {
                    units_dollars_type = 'weightedpctdisc_withoutno'
                    units_dollars = ''
                }
        
                if (props.metric === 'Efficiency') {
                    totalmetrictopull = 'promoted' + units_dollars
                }
        
                else if (props.metric === 'Promoted Lift') {
                    totalmetrictopull = 'subsidizedbase' + units_dollars
                }
        
                else if (props.metric === 'Market Lift') {
                    totalmetrictopull = 'base' + units_dollars
                }
        
                else if (props.metric === 'Average Promoted Discount %') {
                    totalmetrictopull = 'totalunits_withoutno'
                }
        
                else {
                    totalmetrictopull = 'total' + units_dollars
                }
        
                metrictopull = units_dollars_type + units_dollars
        
                if (props.level === 'Total') {
        
                    let numerator = (props.dataset[outlet] || {})[metrictopull] || 0
                    let denominator = (props.dataset[outlet] || {})[totalmetrictopull] || 0
        
                    if (numerator === 0 && denominator === 0) {
                        y = null
                    }
        
                    else {
                        y = Math.min((numerator / denominator), 10)
                    }
        
                    data = [y]
                }
        
                else {
                    data = props.categories.map(cat => {
                        let numerator = ((props.dataset[outlet] || {})[cat] || {})[metrictopull] || 0
                        let denominator = ((props.dataset[outlet] || {})[cat] || {})[totalmetrictopull] || 0
                        if (numerator === 0 && denominator === 0) {
                            return null
                        }
                        else {
                            return Math.min((numerator / denominator), 10)
                        }
                        
                    })
                }
                    
                return {
                    'name': props.metric + ' % -' + outlet,
                    'data': data,
                    'type': 'bar',
                    'color': graphcolor,
                    'showInLegend': true
                }
        
            }),
            xAxis: {
                    title: { text: '' },
                    categories: props.categories
            },
            yAxis: {
                title: { text: props.metric },
                gridLineColor: "#CCC",
                gridLineWidth: 1,
                labels: {
                    formatter: function () {
                        return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                    }
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return (this.y !== 0) ? this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ""
                        },
                        color: 'black',
                        borderRadius: 5,
                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                        borderWidth: 1,
                        borderColor: '#d6d7d8',
                        padding: 2,
                        style: {
                            textOutline: 'none',
                            fontSize: '20px'
                        },                                    
                    }, 
                    connectNulls: false                                
                },
                waterfall: {
                    borderColor: '#ffffff'
                },
                column: {
                    grouping: false,
                }
            },
            tooltip: {
                formatter: function () {
                    var tooltiptitle
                    if (props.metric === 'Average Promoted Discount %') {
                        tooltiptitle = 'Discount Depth'
                    }
                    else {
                        tooltiptitle = props.metric
                    }
                    return '<span> ' + tooltiptitle + ' <br> '
                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                        + '</span>'
                }
            },
            exporting: {
                showTable: props.showDataTable,
                tableCaption: false
            },
        }
    }

    useEffect(() => {
        setChartOptions(getChartOptions(props.outlets))
    }, [props])
       

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />                       
            
        </Fragment>
    )
}

export default ALRvsROM;