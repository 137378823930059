export default function handleChangeRadioAttr(event) {
            var type = event.currentTarget.value
            if (type === 'Base') {
                
                this.setState({
                    baseselectedattr: true
                    , promoselectedattr: false
                    , liftselectedattr: false
                })

            }

            else if (type === 'Promo') {
                
                this.setState({
                    baseselectedattr: false
                    , promoselectedattr: true
                    , liftselectedattr: false
                })

            }

            else if (type === 'Lift') {
                
                this.setState({
                    baseselectedattr: false
                    , promoselectedattr: false
                    , liftselectedattr: true
                })

            }

        }