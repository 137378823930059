export default function setcomparison_metric(selectedOptions) {
    this.setState({
        comparisonMetricSelected: selectedOptions,
        MetricToCompare: selectedOptions.value
    }, () => {
        // this.runcomparison()                
        this.runcomparison_apis()
    }
    )

}