import React, { Component } from 'react';
import WeeklyStackedBarMultipleSeries from '../../../Graphs/WeeklyStackedBarMultipleSeries'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import * as Constant from '../../../constants';

export class AvgDiscPercentALRvsROM extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        var aggtype = this.props.state.datasettimeagg

        return (
            <div style={{ width: "100%", height: "100%" }}>

                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>

                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }} className="row">
                        <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                            <WeeklyStackedBarMultipleSeries
                                chart_title={"Average Promoted Discount Percent Retailer vs ROM by " + this.props.state.xAxisText}
                                chart_title_info={this.props.state.chart_title_info}
                                seriesList={
                                    this.props.state.outlets.map((outlet, idx) => {
                                        var graphcolor
                                        if (idx === 1) {
                                            graphcolor = Constant.graph_colors[2]
                                            
                                        }
                                        else {
                                            graphcolor = Constant.graph_colors[idx]
                                        }
                                        return {
                                            name: outlet,
                                            type: 'spline',
                                            color: graphcolor,
                                            yAxis: 1,
                                            tooltip: {
                                                pointFormatter: function () {
                                                    let dt = new Date(this.x)
                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                    let date = new Date(this.x + offset)
                                                    if (aggtype === 'Monthly') {
                                                        return '<span>Month: '
                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            + '</span>'
                                                    }
                                                    else {
                                                        return '<span>Week Ending: '
                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            + '</span>'
                                                    }

                                                }
                                            },
                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                            Math.min((this.props.state.outlet_data_weekly[outlet][date]['weightedpctdisc_withoutno']) / (this.props.state.outlet_data_weekly[outlet][date]['totalunits_withoutno']), 1)
                                            ]),
                                            zIndex: 1
                                        }
                                    }).concat([
                                        {
                                            name: 'Total Retail',
                                            type: 'spline',
                                            color: '#8bb0d2',
                                            yAxis: 1,
                                            tooltip: {
                                                pointFormatter: function () {
                                                    let dt = new Date(this.x)
                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                    let date = new Date(this.x + offset)
                                                    if (aggtype === 'Monthly') {
                                                        return '<span>Month: '
                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            + '</span>'
                                                    }
                                                    else {
                                                        return '<span>Week Ending: '
                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            + '</span>'
                                                    }

                                                }
                                            },

                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                            Math.min((this.props.state.weekly_data['Total Category'][date]['weightedpctdisc_withoutno'] / this.props.state.weekly_data['Total Category'][date]['totalunits_withoutno']), 1)
                                            ]),
                                            zIndex: 2
                                        }
                                    ])
                                }
                                unitsvar={this.props.state.unitsvar}
                                distinctdates={this.props.state.distinctdates}
                                weekly_data={this.props.state.weekly_data}
                                selectedBrand={this.props.state.selectedBrand}
                                showDataTable={this.props.state.showDataTable}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                weekagg={this.props.state.filter_object.numweeks}
                                height={2}
                                attr_caption_text={this.props.state.attr_caption_text}
                                yAxisTitle=''
                                yAxisTitle2='Average Promoted Discount'
                                yAxisType='percent'
                                yAxisType2='percent'
                                selectedBrands={this.props.state.selectedBrands}
                                aggperiod={this.props.state.datasettimeagg}
                                currencycode={this.props.state.currencycode}
                                currencytype={this.props.state.currencyType}
                                metadata={this.props.state.chartmetadata}
                            />
                        </div>
                        <div className="col-sm-6" style={{ width: "100%", height: "100%" }}>
                            <WeeklyStackedBarMultipleSeries
                                chart_title={this.props.state.currencyType + " Share Retailer vs ROM by " + this.props.state.xAxisText}
                                chart_title_info={this.props.state.chart_title_info}
                                seriesList={
                                    this.props.state.outlets.map((outlet, idx) => {
                                        var graphcolor
                                        if (idx === 1) {
                                            graphcolor = Constant.graph_colors[2]
                                        }
                                        else {
                                            graphcolor = Constant.graph_colors[idx]
                                        }
                                        return {
                                            name: outlet,
                                            type: 'column',
                                            color: graphcolor,
                                            yAxis: 0,
                                            tooltip: {
                                                pointFormatter: function () {
                                                    let dt = new Date(this.x)
                                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                                    let date = new Date(this.x + offset)
                                                    if (aggtype === 'Monthly') {
                                                        return '<span>Month: '
                                                            + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            + '</span>'
                                                    }
                                                    else {
                                                        return '<span>Week Ending: '
                                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                            + '<br>'
                                                            + this.series.name
                                                            + '<br> '
                                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                            + '</span>'
                                                    }

                                                }
                                            },
                                            data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                            (this.props.state.outlet_data_weekly[outlet][date]['totaldollars']) / (this.props.state.weekly_data['Total Category'][date]['totaldollars'])
                                            ]),
                                            zIndex: 1
                                        }
                                    })
                                }
                                unitsvar={this.props.state.unitsvar}
                                distinctdates={this.props.state.distinctdates}
                                weekly_data={this.props.state.weekly_data}
                                selectedBrand={this.props.state.selectedBrand}
                                showDataTable={this.props.state.showDataTable}
                                showDataTableStyle={this.props.state.showDataTableStyle}
                                weekagg={this.props.state.filter_object.numweeks}
                                height={2}
                                attr_caption_text={this.props.state.attr_caption_text}
                                yAxisTitle={this.props.state.currencyType + ' Share'}
                                yAxisTitle2=''
                                yAxisType='percent'
                                yAxisType2='percent'
                                selectedBrands={this.props.state.selectedBrands}
                                aggperiod={this.props.state.datasettimeagg}
                                currencycode={this.props.state.currencycode}
                                currencytype={this.props.state.currencyType}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}