import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import * as Constant from '../../../constants';

export class TransferList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props)
        const customList = (items) => (            
            <Paper style={{ width: 250, height: 400, overflow: 'scroll' }}>
                <List dense component="div" role="list">
                    {items.map((value, idx) => {
                        const labelId = `transfer-list-item-${value}-label`;
                        let attrvalue = value
                        return (
                            <ListItem
                                key={value + '_' + idx}
                                role="listitem"
                                button
                                onClick={(attr, value) => this.props.handleToggle(this.props.attr, attrvalue)}
                            >
                                <ListItemIcon>
                                    <ThemeProvider theme={Constant.muiTheme}>
                                        <Checkbox
                                            checked={this.props.checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ThemeProvider>                                    
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value}`} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Paper>
        );

        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center"  >                    
                    <Grid item>
                        <h5>Available Values</h5>
                        {customList(this.props.left)}
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center" justifyContent="center" >
                            <ThemeProvider theme={Constant.muiTheme}>
                                <Tooltip
                                    title='Add Value(s) to Group'
                                    placement='top-start'
                                >
                                    <span>
                                        <Button                                
                                            variant="contained"
                                            size="small"
                                            onClick={(attr, groupnum) => this.props.handleCheckedRight(this.props.attr, this.props.groupnum)}
                                            disabled={this.props.left.length === 0}
                                            aria-label="move selected right"
                                            style={{ padding: '5px', margin: '5px'}}
                                            color="primary"
                                            className={Constant.classes.button}
                                        >
                                            &gt;
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    title='Remove Value(s) to Group'
                                    placement='top-start'
                                >
                                    <span>
                                        <Button                                
                                            variant="contained"
                                            size="small"
                                            onClick={(attr, groupnum) => this.props.handleCheckedLeft(this.props.attr, this.props.groupnum)}
                                            disabled={this.props.right.length === 0}
                                            aria-label="move selected left"
                                            style={{ padding: '5px', margin: '5px' }}
                                            color="secondary"
                                            className={Constant.classes.button}
                                        >
                                            &lt;
                                        </Button> 
                                    </span>
                                </Tooltip>
                            </ThemeProvider>
                        </Grid>
                    </Grid>                    
                    <Grid item>
                        <h5>Selected Values for Group</h5>
                        {customList(this.props.right)}
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}