import React, { Fragment } from 'react';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import * as Constant from '../constants';

const ScorecardPromotionMetrics = props => {

    var currencytype = props.currencytype || 'Dollars'
    var appname = props.appname

    var lift_curr = props.lift_curr
    var lift_prior = props.lift_prior
    var lift_promo_curr = props.lift_promo_curr
    var lift_promo_prior = props.lift_promo_prior
    var eff_curr = props.eff_curr
    var eff_prior = props.eff_prior
    var payback_ratio_curr = props.payback_ratio_curr
    var payback_ratio_prior = props.payback_ratio_prior



    var promoted_dollars_curr = props.promoted_dollars_curr
    var promoted_dollars_prior = props.promoted_dollars_prior

    var base_dollars_curr = props.base_dollars_curr
    var base_dollars_prior = props.base_dollars_prior

    var incremental_dollars_curr = props.incremental_dollars_curr
    var incremental_dollars_prior = props.incremental_dollars_prior
    var subbasedollars_curr = props.subbasedollars_curr
    var subbasedollars_prior = props.subbasedollars_prior



    var lift_curr_dollars = (incremental_dollars_curr / base_dollars_curr)
    var lift_prior_dollars = (incremental_dollars_prior / base_dollars_prior)
    var lift_promo_curr_dollars = (incremental_dollars_curr / subbasedollars_curr)
    var lift_promo_prior_dollars = (incremental_dollars_prior / subbasedollars_prior)
    var eff_curr_dollars = (incremental_dollars_curr / promoted_dollars_curr)
    var eff_prior_dollars = (incremental_dollars_prior / promoted_dollars_prior)

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>Promotion Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>


                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Market Lift <span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_curr - lift_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Promoted Lift<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_promo_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_promo_curr - lift_promo_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Efficiency<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(eff_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(eff_curr - eff_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Market Lift (' + currencytype + ')'}<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(lift_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_curr_dollars - lift_prior_dollars) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_curr_dollars - lift_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_curr_dollars - lift_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>

                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Promoted Lift (' + currencytype + ')'}</h5>
                                <p>{(lift_promo_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(lift_promo_curr_dollars - lift_promo_prior_dollars) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_promo_curr_dollars - lift_promo_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_promo_curr_dollars - lift_promo_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Efficiency (' + currencytype + ')'}<span style={{ color: '#FFFFFF' }}> currencytype </span></h5>
                                <p>{(eff_curr_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(eff_curr_dollars - eff_prior_dollars) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(eff_curr_dollars - eff_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(eff_curr_dollars - eff_prior_dollars).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Payback Ratio</h5>
                                <p>{(payback_ratio_curr).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</p>
                                {(payback_ratio_curr - payback_ratio_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
            :
            <Fragment>
                <h3>Promotion Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions across promoted and non-promoted stores. It is the ratio of Incremental Sales to Base Sales. Lift = (Incremental Sales/Base Sales)*100, or ($15,000/$20,000)*100 = 75% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Market Lift</h5>
                            <p>{(lift_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(lift_curr - lift_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_curr - lift_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The impact of promotions in only the promoted stores. It is the ratio of Incremental Sales to Subsidized Base Sales. Lift = (Incremental Sales/Subsidized Base Sales)*100, or ($15,000/$10,000)*100 = 150% lift.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Promoted Lift</h5>
                            <p>{(lift_promo_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(lift_promo_curr - lift_promo_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(lift_promo_curr - lift_promo_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The ratio of Incremental Sales to Promoted Sales. Efficiency = (Incremental Sales/Total Promoted Sales)*100, or ($15,000/$25,000)*100 = 60%.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Efficiency</h5>
                            <p>{(eff_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(eff_curr - eff_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(eff_curr - eff_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The Payback Ratio is the ratio of Incremental Dollar Sales to Discount Dollars spent. This KPI can be used as a gauge of likely promotional ROI (though it is not a complete ROI analysis).</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Payback Ratio</h5>
                            <p>{(payback_ratio_curr).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</p>
                            {(payback_ratio_curr - payback_ratio_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(payback_ratio_curr - payback_ratio_prior).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
    )
}

export default ScorecardPromotionMetrics;