import React, { Fragment, Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants';
import { reducer } from '../PDT/Functions/Utilities/Reducer';

export class DepthOfDiscountGraph extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {

        var totalvol_totalcat = 0
        try {
            totalvol_totalcat = this.props.state.distinctdates.map(date => this.props.state.weekly_data['Total Category'][date]['totalunits']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat = 0
        }

        var totalvol_totalcat_geo = 0
        try {
            totalvol_totalcat_geo = this.props.state.distinctdates.map(date => this.props.state.weekly_data_geo['All Channels'][date]['totalunits']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat_geo = 0
        }

        var total_cat_data = []
        try {
            total_cat_data = this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data['Total Category'][discountbucket]['totalunits'] / totalvol_totalcat)
        }
        catch (error) {
            total_cat_data = []
        }

        var totalvol_totalcat_dollars = 0
        try {
            totalvol_totalcat_dollars = this.props.state.distinctdates.map(date => this.props.state.weekly_data['Total Category'][date]['totaldollars']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat_dollars = 0
        }

        var totalvol_totalcat_geo_dollars = 0
        try {
            totalvol_totalcat_geo_dollars = this.props.state.distinctdates.map(date => this.props.state.weekly_data_geo['All Channels'][date]['totaldollars']).reduce(reducer)
        }
        catch (error) {
            totalvol_totalcat_geo_dollars = 0
        }

        var total_cat_data_dollars = []
        try {
            total_cat_data_dollars = this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data['Total Category'][discountbucket]['totaldollars'] / totalvol_totalcat_dollars)
        }
        catch (error) {
            total_cat_data_dollars = []
        }

        return (
            <Fragment>
                {this.props.state.channelviews ?
                    <div className="row" style={{ width: "100%", height: "100%" }}>
                        {this.props.state.indexSwitchCheckDollars ?
                            <div className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    oneToOne={true}
                                    options={
                                        {
                                            chart: {
                                                //type: 'column'
                                                plotBackgroundColor: null,
                                                plotBorderWidth: null,
                                                plotShadow: false,
                                                type: 'column',
                                                //16:9 ratio
                                                height: (9 / 16 * 100) + '%',
                                                events: {
                                                    load: function renderMarkers() {

                                                        var positions = total_cat_data_dollars, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    },
                                                    redraw: function renderMarkers() {

                                                        var positions = total_cat_data_dollars, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    }
                                                }
                                            },
                                            metadata: this.props.state.chartmetadata,
                                            caption: {
                                                text: this.props.state.attr_caption_text
                                            },
                                            title: {
                                                text: 'Percent of ' + this.props.state.currencyType + ' by Discount Range' + this.props.state.chart_title_info_nochannels
                                            },
                                            series: (
                                                this.props.state.selectedChannels.map((geo, idx) => {
                                                    return {
                                                        'name': geo,
                                                        'data': this.props.state.discountbuckets.map((discountbucket, idx) => (this.props.state.discountband_data_geo[geo][discountbucket]['totaldollars'] / this.props.state.TotalBarLookup_Dollars_Channel[geo])),
                                                        'type': 'column',
                                                        'color': Constant.graph_colors[idx]
                                                    }
                                                })
                                            ).concat([

                                                {
                                                    'name': 'All Channels',
                                                    'data': this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data_geo['All Channels'][discountbucket]['totaldollars'] / totalvol_totalcat_geo_dollars),
                                                    'type': 'line',
                                                    'color': '#000000',
                                                    'marker': {
                                                        'symbol': 'rect',
                                                        'lineColor': null,
                                                        'radius': 8,
                                                        'lineWidth': 0
                                                    },
                                                    'lineWidth': 0,
                                                    'states': {
                                                        'hover': {
                                                            'lineWidthPlus': 0
                                                        }
                                                    }
                                                }
                                            ])
                                            ,
                                            xAxis: {
                                                title: {
                                                    text: 'Discount Range'
                                                },
                                                categories:
                                                    this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                            },
                                            yAxis: {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: 'Percent of ' + this.props.state.currencyType + 'Sold in Discount Range'
                                                },
                                                labels: {
                                                    //format: '{value}%'
                                                    formatter: function () {
                                                        return Highcharts.numberFormat(this.value * 100, 0) + '%'
                                                    }
                                                }
                                            },
                                            plotOptions: {
                                                column: {
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            return Highcharts.numberFormat(this.y * 100, 0) + '%'
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '20px'
                                                        },
                                                        y: -5
                                                    }
                                                },


                                            },
                                            tooltip: {
                                                //pointFormat: '{series.name}: <b>{point}%</b>'
                                                formatter: function () {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, {
                                                            style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })
                                                }
                                            },
                                            exporting: {
                                                showTable: this.props.state.showDataTable,
                                                tableCaption: false
                                            },
                                        }}
                                />
                                <div style={{ display: this.props.state.showDataTableStyle }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g3">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                                {this.props.state.selectedChannels.map(geo => {
                                                    return <Constant.NPDTableCell key={"headercol_" + geo + "_g4"} style={{ textAlign: 'center' }}>{geo + this.props.state.currencyType + ' in Discount Range'}</Constant.NPDTableCell>
                                                })
                                                }
                                            </Constant.NPDTableRow>
                                            {this.props.state.discountbuckets.map(discountbucket => {
                                                return <Constant.NPDTableRow key={"datarow_" + discountbucket + "_g3"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{discountbucket}</Constant.NPDTableCell>
                                                    {this.props.state.selectedChannels.map(geo => {
                                                        return <Constant.NPDTableCell key={"datarow_" + discountbucket + geo + "_g3"} style={{ textAlign: 'center' }}>{
                                                            (this.props.state.discountband_data_geo[geo][discountbucket]['totaldollars'] / this.props.state.TotalBarLookup_Dollars_Channel[geo]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                    })
                                                    }
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>

                            </div>
                            :
                            <div className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    oneToOne={true}
                                    options={
                                        {
                                            chart: {
                                                //type: 'column'
                                                plotBackgroundColor: null,
                                                plotBorderWidth: null,
                                                plotShadow: false,
                                                type: 'column',
                                                //16:9 ratio
                                                height: (9 / 16 * 100) + '%',
                                                events: {
                                                    load: function renderMarkers() {

                                                        var positions = total_cat_data, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    },
                                                    redraw: function renderMarkers() {

                                                        var positions = total_cat_data, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    }
                                                }
                                            },
                                            metadata: this.props.state.chartmetadata,
                                            caption: {
                                                text: this.props.state.attr_caption_text
                                            },
                                            title: {
                                                text: 'Percent of Units by Discount Range' + this.props.state.chart_title_info_nochannels
                                            },
                                            series: (
                                                this.props.state.selectedChannels.map((geo, idx) => {
                                                    return {
                                                        'name': geo,
                                                        'data': this.props.state.discountbuckets.map((discountbucket, idx) => (this.props.state.discountband_data_geo[geo][discountbucket]['totalunits'] / this.props.state.TotalBarLookup_Channel[geo])),
                                                        'type': 'column',
                                                        'color': Constant.graph_colors[idx]
                                                    }
                                                })
                                            ).concat([

                                                {
                                                    'name': 'All Channels',
                                                    'data': this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data_geo['All Channels'][discountbucket]['totalunits'] / totalvol_totalcat_geo),
                                                    'type': 'line',
                                                    'color': '#000000',
                                                    'marker': {
                                                        'symbol': 'rect',
                                                        'lineColor': null,
                                                        'radius': 8,
                                                        'lineWidth': 0
                                                    },
                                                    'lineWidth': 0,
                                                    'states': {
                                                        'hover': {
                                                            'lineWidthPlus': 0
                                                        }
                                                    }
                                                }
                                            ])
                                            ,
                                            xAxis: {
                                                title: {
                                                    text: 'Discount Range'
                                                },
                                                categories:
                                                    this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                            },
                                            yAxis: {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: 'Percent of ' + this.props.state.unitsvar + ' Sold in Discount Range'
                                                },
                                                labels: {
                                                    //format: '{value}%'
                                                    formatter: function () {
                                                        return Highcharts.numberFormat(this.value * 100, 0) + '%'
                                                    }
                                                }
                                            },
                                            plotOptions: {
                                                column: {
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            return Highcharts.numberFormat(this.y * 100, 0) + '%'
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '20px'
                                                        },
                                                        y: -5
                                                    }
                                                },


                                            },
                                            tooltip: {
                                                //pointFormat: '{series.name}: <b>{point}%</b>'
                                                formatter: function () {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, {
                                                            style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })
                                                }
                                            },
                                            exporting: {
                                                showTable: this.props.state.showDataTable,
                                                tableCaption: false
                                            },
                                        }}
                                />
                                <div style={{ display: this.props.state.showDataTableStyle }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g3">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                                {this.props.state.selectedChannels.map(geo => {
                                                    return <Constant.NPDTableCell key={"headercol_" + geo + "_g4"} style={{ textAlign: 'center' }}>{geo + ' Units in Discount Range'}</Constant.NPDTableCell>
                                                })
                                                }
                                            </Constant.NPDTableRow>
                                            {this.props.state.discountbuckets.map(discountbucket => {
                                                return <Constant.NPDTableRow key={"datarow_" + discountbucket + "_g3"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{discountbucket}</Constant.NPDTableCell>
                                                    {this.props.state.selectedChannels.map(geo => {
                                                        return <Constant.NPDTableCell key={"datarow_" + discountbucket + geo + "_g3"} style={{ textAlign: 'center' }}>{
                                                            (this.props.state.discountband_data_geo[geo][discountbucket]['totalunits'] / this.props.state.TotalBarLookup_Channel[geo]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                    })
                                                    }
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>

                            </div>
                        }
                    </div>
                    :
                    <div className="row" style={{ width: "100%", height: "100%" }}>
                        {this.props.state.indexSwitchCheckDollars ?
                            <div className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    oneToOne={true}
                                    options={
                                        {
                                            chart: {
                                                //type: 'column'
                                                plotBackgroundColor: null,
                                                plotBorderWidth: null,
                                                plotShadow: false,
                                                type: 'column',
                                                //16:9 ratio
                                                height: (9 / 16 * 100) + '%',
                                                events: {
                                                    load: function renderMarkers() {

                                                        var positions = total_cat_data_dollars, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    },
                                                    redraw: function renderMarkers() {

                                                        var positions = total_cat_data_dollars, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    }
                                                }
                                            },
                                            metadata: this.props.state.chartmetadata,
                                            caption: {
                                                text: this.props.state.attr_caption_text
                                            },
                                            title: {
                                                text: 'Percent of ' + this.props.state.currencyType + ' by Discount Range' + this.props.state.chart_title_info
                                            },
                                            series: (
                                                this.props.state.selectedBrands.map((brand, idx) => {
                                                    return {
                                                        'name': brand,
                                                        'data': this.props.state.discountbuckets.map((discountbucket, idx) => (this.props.state.discountband_data[brand][discountbucket]['totaldollars'] / this.props.state.TotalBarLookup_Dollars_Brand[brand])),
                                                        'type': 'column',
                                                        'color': Constant.graph_colors[idx]
                                                    }
                                                })
                                            ).concat([

                                                {
                                                    'name': 'Total Category',
                                                    'data': this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data['Total Category'][discountbucket]['totaldollars'] / totalvol_totalcat_dollars),
                                                    'type': 'line',
                                                    'color': '#000000',
                                                    'marker': {
                                                        'symbol': 'rect',
                                                        'lineColor': null,
                                                        'radius': 8,
                                                        'lineWidth': 0
                                                    },
                                                    'lineWidth': 0,
                                                    'states': {
                                                        'hover': {
                                                            'lineWidthPlus': 0
                                                        }
                                                    }
                                                }
                                            ])
                                            ,
                                            xAxis: {
                                                title: {
                                                    text: 'Discount Range'
                                                },
                                                categories:
                                                    this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                            },
                                            yAxis: {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: 'Percent of ' + this.props.state.currencyType + 'Sold in Discount Range'
                                                },
                                                labels: {
                                                    //format: '{value}%'
                                                    formatter: function () {
                                                        return Highcharts.numberFormat(this.value * 100, 0) + '%'
                                                    }
                                                }
                                            },
                                            plotOptions: {
                                                column: {
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            return Highcharts.numberFormat(this.y * 100, 0) + '%'
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '20px'
                                                        },
                                                        y: -5
                                                    }
                                                },


                                            },
                                            tooltip: {
                                                //pointFormat: '{series.name}: <b>{point}%</b>'
                                                formatter: function () {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, {
                                                            style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })
                                                }
                                            },
                                            exporting: {
                                                showTable: this.props.state.showDataTable,
                                                tableCaption: false
                                            },
                                        }}
                                />
                                <div style={{ display: this.props.state.showDataTableStyle }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g3">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                                {this.props.state.selectedBrands.map(brand => {
                                                    return <Constant.NPDTableCell key={"headercol_" + brand + "_g4"} style={{ textAlign: 'center' }}>{brand + this.props.state.currencyType + ' in Discount Range'}</Constant.NPDTableCell>
                                                })
                                                }
                                            </Constant.NPDTableRow>
                                            {this.props.state.discountbuckets.map(discountbucket => {
                                                return <Constant.NPDTableRow key={"datarow_" + discountbucket + "_g3"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{discountbucket}</Constant.NPDTableCell>
                                                    {this.props.state.selectedBrands.map(brand => {
                                                        return <Constant.NPDTableCell key={"datarow_" + discountbucket + brand + "_g3"} style={{ textAlign: 'center' }}>{
                                                            (this.props.state.discountband_data[brand][discountbucket]['totaldollars'] / this.props.state.TotalBarLookup_Dollars_Brand[brand]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                    })
                                                    }
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>

                            </div>
                            :
                            <div className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    oneToOne={true}
                                    options={
                                        {
                                            chart: {
                                                //type: 'column'
                                                plotBackgroundColor: null,
                                                plotBorderWidth: null,
                                                plotShadow: false,
                                                type: 'column',
                                                //16:9 ratio
                                                height: (9 / 16 * 100) + '%',
                                                events: {
                                                    load: function renderMarkers() {

                                                        var positions = total_cat_data, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    },
                                                    redraw: function renderMarkers() {

                                                        var positions = total_cat_data, //[300, 400, 650, 250, 50],
                                                            chart = this,
                                                            xAxis = chart.xAxis[0],
                                                            yAxis = chart.yAxis[0],
                                                            renderer = chart.renderer,
                                                            tempArray = [],
                                                            singleMarkerPath;


                                                        positions.forEach(function (position, index) {
                                                            singleMarkerPath = [
                                                                'M', xAxis.toPixels(index - 0.25), yAxis.toPixels(position),
                                                                'H', xAxis.toPixels(index + 0.25)
                                                            ];

                                                            if (!chart.additionalMarkers) {
                                                                tempArray.push(renderer.path(singleMarkerPath)
                                                                    .attr({
                                                                        'stroke-width': 2,
                                                                        stroke: '#565a5c',
                                                                        zIndex: 10
                                                                    })
                                                                    .add());
                                                            } else {
                                                                chart.additionalMarkers[index].attr({
                                                                    d: singleMarkerPath
                                                                })
                                                            }
                                                        });

                                                        if (!chart.additionalMarkers) {
                                                            chart.additionalMarkers = tempArray;
                                                        }
                                                        window.dispatchEvent(new Event('resize'));
                                                    }
                                                }
                                            },
                                            metadata: this.props.state.chartmetadata,
                                            caption: {
                                                text: this.props.state.attr_caption_text
                                            },
                                            title: {
                                                text: 'Percent of Units by Discount Range' + this.props.state.chart_title_info
                                            },
                                            series: (
                                                this.props.state.selectedBrands.map((brand, idx) => {
                                                    return {
                                                        'name': brand,
                                                        'data': this.props.state.discountbuckets.map((discountbucket, idx) => (this.props.state.discountband_data[brand][discountbucket]['totalunits'] / this.props.state.TotalBarLookup_Brand[brand])),
                                                        'type': 'column',
                                                        'color': Constant.graph_colors[idx]
                                                    }
                                                })
                                            ).concat([

                                                {
                                                    'name': 'Total Category',
                                                    'data': this.props.state.discountbuckets.map((discountbucket, idx) => this.props.state.discountband_data['Total Category'][discountbucket]['totalunits'] / totalvol_totalcat),
                                                    'type': 'line',
                                                    'color': '#000000',
                                                    'marker': {
                                                        'symbol': 'rect',
                                                        'lineColor': null,
                                                        'radius': 8,
                                                        'lineWidth': 0
                                                    },
                                                    'lineWidth': 0,
                                                    'states': {
                                                        'hover': {
                                                            'lineWidthPlus': 0
                                                        }
                                                    }
                                                }
                                            ])
                                            ,
                                            xAxis: {
                                                title: {
                                                    text: 'Discount Range'
                                                },
                                                categories:
                                                    this.props.state.discountbuckets.map(bucket => bucket.substring(9))
                                            },
                                            yAxis: {
                                                gridLineColor: "#CCC",
                                                gridLineWidth: 1,
                                                title: {
                                                    text: 'Percent of ' + this.props.state.unitsvar + ' Sold in Discount Range'
                                                },
                                                labels: {
                                                    //format: '{value}%'
                                                    formatter: function () {
                                                        return Highcharts.numberFormat(this.value * 100, 0) + '%'
                                                    }
                                                }
                                            },
                                            plotOptions: {
                                                column: {
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function () {
                                                            return Highcharts.numberFormat(this.y * 100, 0) + '%'
                                                        },
                                                        color: 'black',
                                                        borderRadius: 5,
                                                        backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                                        borderWidth: 1,
                                                        borderColor: '#d6d7d8',
                                                        padding: 0,
                                                        style: {
                                                            textOutline: 'none',
                                                            fontSize: '20px'
                                                        },
                                                        y: -5
                                                    }
                                                },


                                            },
                                            tooltip: {
                                                //pointFormat: '{series.name}: <b>{point}%</b>'
                                                formatter: function () {
                                                    return '<span> '
                                                        + this.series.name
                                                        + '<br/>'
                                                        + this.x
                                                        + '<br/>'
                                                        + this.y.toLocaleString(undefined, {
                                                            style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })
                                                }
                                            },
                                            exporting: {
                                                showTable: this.props.state.showDataTable,
                                                tableCaption: false
                                            },
                                        }}
                                />
                                <div style={{ display: this.props.state.showDataTableStyle }}>
                                    <Constant.NPDTable>
                                        <Constant.NPDTableBody>
                                            <Constant.NPDTableRow key="header_g3">
                                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                                                {this.props.state.selectedBrands.map(brand => {
                                                    return <Constant.NPDTableCell key={"headercol_" + brand + "_g4"} style={{ textAlign: 'center' }}>{brand + ' Units in Discount Range'}</Constant.NPDTableCell>
                                                })
                                                }
                                            </Constant.NPDTableRow>
                                            {this.props.state.discountbuckets.map(discountbucket => {
                                                return <Constant.NPDTableRow key={"datarow_" + discountbucket + "_g3"}>
                                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{discountbucket}</Constant.NPDTableCell>
                                                    {this.props.state.selectedBrands.map(brand => {
                                                        return <Constant.NPDTableCell key={"datarow_" + discountbucket + brand + "_g3"} style={{ textAlign: 'center' }}>{
                                                            (this.props.state.discountband_data[brand][discountbucket]['totalunits'] / this.props.state.TotalBarLookup_Brand[brand]).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                                    })
                                                    }
                                                </Constant.NPDTableRow>
                                            })
                                            }
                                        </Constant.NPDTableBody>
                                    </Constant.NPDTable>
                                </div>

                            </div>
                        }
                    </div>
                }
            </Fragment>
        )
    }

}