import * as Constant from '../../constants'
import React, { Component, Fragment } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Slider from '@material-ui/core/Slider';
import Select from 'react-select'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import selectColourStyles from '../../styles/SelectColourStyles'

export class FilterSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderRange: [null, null],
            sliderRangeMin: null,
            sliderRangeMax: null,
            AllAttrsModalOpen: false,
        }
        console.log(props)
    }

    render() {

        if (this.props.state.sliderRange && !this.state.sliderRange[1]) {
            this.setState({ sliderRange: this.props.state.sliderRange})
        }

        if (this.props.state.sliderRangeMin && !this.state.sliderRangeMin) {
            this.setState({ sliderRangeMin: this.props.state.sliderRangeMin})
        }

        if (this.props.state.sliderRangeMax && !this.state.sliderRangeMax) {
            this.setState({ sliderRangeMax: this.props.state.sliderRangeMax})
        }

        const handleChangeSlider = (event, newValue) => {
            console.log('Changing Slider')
            this.setState({ sliderRange: newValue })
        }

        const handleChangeASPMinBox = (event, newValue) => {
            console.log('Changing handleChangeASPMinBox')
            var input = document.getElementById("aspmin");
            let value = input.value
            console.log(value)
            var sliderRange = []
            if (isNaN(value)) {
                alert('You Must Only input Numbers.  Resetting to ASP Minimum')
                sliderRange = [this.state.sliderRangeMin, this.state.sliderRange[1]]
                this.setState({ sliderRange })
            }

            else {
                //check that value >= 0 and <= max
                if (value < 0 || value > this.state.sliderRangeMax) {
                    let alerttext = 'Value Entered Must Be Between' + this.state.sliderRangeMin + ' and ' + this.state.sliderRangeMax + '. Resetting ASP Minimum'
                    sliderRange = [this.state.sliderRangeMin, this.state.sliderRange[1]]
                    this.setState({ sliderRange })
                    alert(alerttext)
                }

                else {
                    console.log('Setting State')
                    sliderRange = [value, this.state.sliderRange[1]]
                    this.setState({ sliderRange })
                }

            }

        }

        const handleChangeASPMaxBox = (event, newValue) => {
            console.log('Changing handleChangeASPMaxBox')
            var input = document.getElementById("aspmax");
            let value = input.value
            console.log(value)
            var sliderRange = []
            if (isNaN(value)) {
                alert('You Must Only input Numbers.  Resetting to ASP Maximum')
                sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
                this.setState({ sliderRange })
            }

            else {
                //check that value >= 0 and <= max
                if (value < 0 || value > this.state.sliderRangeMax) {
                    let alerttext = 'Value Entered Must Be Between' + this.state.sliderRangeMin + ' and ' + this.state.sliderRangeMax + '. Resetting ASP Maximum'
                    sliderRange = [this.state.sliderRange[0], this.state.sliderRangeMax]
                    this.setState({ sliderRange })
                    alert(alerttext)
                }

                else {
                    console.log('Setting State')
                    sliderRange = [this.state.sliderRange[0], value]
                    this.setState({ sliderRange })
                }

            }
        }

        const sliderCommitted = () => {
            console.log('Committed Slider Changes')
            var sliderRange = this.state.sliderRange
            this.props.filterData(sliderRange, { name: 'ASP' })
        }

        const handleOpenAllAttrsModal = () => {
            console.log('Changing handleOpenAllAttrsModal')
            this.setState({ AllAttrsModalOpen: true })
            console.log('Open All Attributes')
        }

        const handleCloseAllAttrsModal = () => {
            console.log('Changing handleCloseAllAttrsModal')
            this.setState({ AllAttrsModalOpen: false })
        }


        const muiTheme = createTheme({
            palette: {
                primary: {
                    main: '#4e106f',
                },
                secondary: {
                    main: '#ef2a79',
                },
                white: {
                    main: '#FFF'
                }
            }
        });


        return (
            <div className="col-sm-12 contentHolder simOutputGraphs" style={{ padding: '0px' }}>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h5 style={{ padding: '10px' }}>Filters</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="col-sm-12">
                            <div className="row col-sm-12">
                                <h5 style={{ padding: '10px' }}>Average Selling Price</h5>
                            </div>
                            <div className='row col-sm-12 '>
                                <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: <Constant.NPDInputAdornment position="start"> {this.props.state.currencysymbol} </Constant.NPDInputAdornment>
                                        }}
                                        onChange={handleChangeASPMinBox}
                                        onKeyUp={(event) => {
                                            if (event.key == 'Enter')
                                                sliderCommitted()
                                        }}
                                        id="aspmin"
                                        value={this.state.sliderRange[0] || 0}
                                    />
                                </div>
                                <div className='col-sm-6 '>
                                    <ThemeProvider theme={muiTheme}>
                                        <Slider
                                            color="primary"
                                            value={this.state.sliderRange}
                                            onChange={handleChangeSlider}
                                            onChangeCommitted={sliderCommitted}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="range-slider"
                                            max={this.state.sliderRangeMax}
                                        />
                                    </ThemeProvider>
                                </div>
                                <div className='col-sm-3 ' style={{ padding: "0px" }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: <Constant.NPDInputAdornment position="start"> {this.props.state.currencysymbol} </Constant.NPDInputAdornment>
                                        }}
                                        onChange={handleChangeASPMaxBox}
                                        onKeyUp={(event) => {
                                            if (event.key == 'Enter')
                                                sliderCommitted()
                                        }}
                                        id="aspmax"
                                        value={this.state.sliderRange[1] || 0}
                                    />
                                </div>
                            </div>
                            <div className="row col-sm-12">
                                <ThemeProvider theme={muiTheme}>
                                    <Slider
                                        color="primary"
                                        value={this.state.sliderRange}
                                        onChange={handleChangeSlider}
                                        onChangeCommitted={sliderCommitted}
                                        valueLabelDisplay="auto"
                                        marks={true}
                                        step={1}
                                        aria-labelledby="range-slider"
                                        min={0}
                                    />
                                </ThemeProvider>
                            </div>
                            <div className="row col-sm-12">
                                <h5 style={{ padding: '10px' }}>Attributes</h5>
                            </div>
                            <div className="row col-sm-12">
                                <Modal
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    open={this.state.AllAttrsModalOpen}
                                    onClose={handleCloseAllAttrsModal}
                                    style={{ overflowY: 'scroll' }}
                                >
                                    <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                                        <h4>Product Attributes</h4>
                                        <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                                            {this.props.state.attributes && this.props.state.attributes.map(attribute => {
                                                let invalidattrs = ['equiv', 'suppression', 'geogdesc']
                                                if (!invalidattrs.includes(attribute)) {
                                                    return <div className='col-sm-4' key={attribute}>
                                                        <p>{this.props.state.attribute_lookup[attribute]}</p>
                                                        <Select
                                                            id={attribute + '_filter'}
                                                            name={attribute}
                                                            options={this.props.state.attribute_options[attribute]}
                                                            isMulti
                                                            placeholder={'Select ' + this.props.state.attribute_lookup[attribute]}
                                                            onChange={this.props.filterData}
                                                            styles={selectColourStyles}
                                                        />
                                                    </div>
                                                }
                                            })}
                                        </div>
                                    </div>
                                </Modal>
                                {this.props.state.attributes && this.props.state.attributes.length <= 9 ?
                                    <div className='col-sm-12' />
                                    :
                                    <div className='col-sm-12'>
                                        <span style={{
                                            color: '#4e106f',
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                            fontSize: '.75rem'
                                        }}
                                            onClick={handleOpenAllAttrsModal}
                                            key="AllAttrsLink"
                                        >
                                            View All {this.props.state.attributes && this.props.state.attributes.length} Attributes
                                        </span>
                                    </div>
                                }
                                {/*Look at the length...if greater than 9 show the modal...*/}
                                {this.props.state.attributes && this.props.state.attributes.length <= 9 ?
                                    this.props.state.attributes && this.props.state.attributes.map(attribute => {
                                        let invalidattrs = ['equiv', 'suppression', 'geogesc']
                                        if (!invalidattrs.includes(attribute)) {
                                            return <div className='col-sm-4' key={attribute}>
                                                <p>{this.props.state.attribute_lookup[attribute]}</p>
                                                <Select
                                                    id={attribute + '_filter'}
                                                    name={attribute}
                                                    options={this.props.state.attribute_options[attribute]}
                                                    isMulti
                                                    placeholder={'Select ' + this.props.state.attribute_lookup[attribute]}
                                                    onChange={this.props.filterData}
                                                    styles={selectColourStyles}
                                                />
                                            </div>
                                        }
                                    })
                                    :
                                    this.props.state.attributes && this.props.state.attributes.map((attribute, idx) => {
                                        let invalidattrs = ['equiv', 'suppression', 'geogdesc']
                                        if (!invalidattrs.includes(attribute)) {
                                            if (idx < 9) {
                                                return <div className='col-sm-4' key={attribute}>
                                                    <p>{this.props.state.attribute_lookup[attribute]}</p>
                                                    <Select
                                                        id={attribute + '_filter'}
                                                        name={attribute}
                                                        options={this.props.state.attribute_options[attribute]}
                                                        isMulti
                                                        placeholder={'Select ' + this.props.state.attribute_lookup[attribute]}
                                                        onChange={this.props.filterData}
                                                        styles={selectColourStyles}
                                                    />
                                                </div>
                                            }
                                            else {
                                                return <div className='col-sm-4' key={attribute} />
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion >
            </div>
        )
    }
}