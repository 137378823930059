import React, { Component } from 'react';
import TagManager from 'react-gtm-module'
import { Route } from 'react-router';
// Utilities and Share
import * as Constant from './components/constants'
import AuthService from './components/authorization/authService'
import { Home, PNPHome, PDTHome } from './components/Home';
import { PNPGlossary, PNPCredits, PNPResources, PDTGlossary, PDTCredits, PDTResources } from './components/StaticPages'
import { Admin } from './components/Admin';
import { Profile } from './components/Profile';
import { Unauthorized } from './components/Unauthorized'
import { DeBugTest } from './components/ErrorDebug';
import { Test } from './components/TestPage'
// PNP
import { ListofAllReports } from './components/PNP/AllReports'
import { ListofAllSimulations } from './components/PNP/AllSimulations'
import { CreatePlan, ListofPlans } from './components/PNP/Plans'
import { ReportOutput } from './components/PNP/ReportOutput'
import { SimulationOutputAdjusted } from './components/PNP/SimulationOutputAdjusted'
import { SimulationEntry } from './components/PNP/SimulationEntry'
import { CompareSimsPage } from './components/PNP/CompareSims'
import { ReportEntry } from './components/PNP/ReportEntry'
import { GeneratePDF } from './components/PNP/GeneratePDF'
import { PNPListofAllDatasets } from './components/PNP/Datasets'
import { PNPPriceBandDefinition } from './components/PNP/PriceBandDefinition'
import { PNPDiscountBandDefinition } from './components/PNP/DiscountBandDefinition'
import { PNPItemGroupDefinition } from './components/PNP/ItemGroupDefinition'
// PDT
import { PDTReportEntry } from './components/PDT/ReportEntry'
import { PDTReportOutputWithCustomOrdering } from './components/PDT/ReportOutputWithCustomOrdering'
import { PDTListofAllReports } from './components/PDT/AllReports'
import { PDTListofAllDatasets } from './components/PDT/Datasets'
import { PDTListofAllDatasetsStaging } from './components/PDT/DatasetsStaging'
import { PDTScreenOrdering } from './components/PDT/ScreenOrdering'
import { PDTPriceBandDefinition } from './components/PDT/PriceBandDefinition'
import { PDTAttributeGroupDefinition } from './components/PDT/AttributeGroupDefinition'
import { PDTDownloadData } from './components/PDT/DownloadData'
import { IndustryView } from './components/PDT/WholeIndustry/IndustryView'
import { DatasetAccessView } from './components/PDT/DatasetAccessView'

// PDT ETL
import { EntryForm } from './components/PDTETL/EntryFormNew'
import { PDTListofAllExtracts } from './components/PDTETL/Extracts'
import { PDTETLCloudStatus } from './components/PDTETL/CloudJobStatus'

// PNP ETL
import { PNPEntryForm } from './components/PNPETL/PNPEntryForm'
import { PNPListofAllExtracts } from './components/PNPETL/PNPExtracts'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state ={
            env: ""
        }
    }

    componentDidMount() {
        console.log(Constant.baseurl + 'environment/getenvironment')
        setInterval(this.runapicall, 300000); //Use 300000 for every 5 minutes
        fetch(Constant.baseurl + 'environment/getenvironment')
            .then(res => res.text())
            .then(
                (result) => {
                    this.setState({ env: result })
                },
                (error) => {
                    this.setState({ env: 'Unknown' })
                }
        )
        this.setTracker()
    }

    async runapicall() {
        console.log('Running Keep Alive Method')
        try {
            const res = await fetch(Constant.baseurl + 'account/isauthenticated')
            const data = await res.json()
            console.log(data)
        }
        catch (error) {
            console.log(error)
        }
    }

    async setTracker() {
        let accountinforesp = await fetch(Constant.baseurl + 'account/isauthenticated')
        let accountinfo = await accountinforesp.json()        
        let internalexternal = accountinfo.internalUser
        let envinforesp = await fetch(Constant.baseurl + 'environment/getenvironment')
        let environment = await envinforesp.text()        

        var userid = null
        var companyid = null
        var companyname = null
        var userinfo = null
        var gtm_id = ""
        let userinforesp = await fetch(Constant.baseapiurl_gen + 'SessionUser/Details')
        if (userinforesp.status === 200) {
            userinfo = await userinforesp.json()
            userid = userinfo.user_id
            companyid = userinfo.company_id
            companyname = userinfo.company_name
        }

        if (environment === 'Development') {
            // Development
            gtm_id = 'GTM-PLT5HP7'
        }
        else {
            // Production
            gtm_id = 'GTM-W8KPGDC'
        }

        const tagManagerArgs = {
            gtmId: gtm_id,            
            dataLayer: {
                'internalExternal': internalexternal,
                'companyID': companyid,
                'companyName': companyname,
                'userID': userid
            }
        }

        TagManager.initialize(tagManagerArgs)
    }

    render () {
        return (
            <div>
                <AuthService>
                    <DeBugTest>
                        {/* Utilities */}
                        <Route exact path='/' component={Home} />
                        <Route path='/Admin' component={Admin} />

                        {/* Price and Promotions App */}
                        <Route path='/PNP/Home' component={PNPHome} />                      
                        <Route path='/PNP/createreport' component={ReportEntry} />
                        <Route path='/PNP/copyreport' component={ReportEntry} />
                        <Route path='/PNP/EditReport' component={ReportEntry} />   
                        <Route path='/PNP/Reports' component={ListofAllReports} /> 
                        <Route path='/PNP/Datasets' component={PNPListofAllDatasets} />
                        <Route path='/PNP/Report' component={ReportOutput} />                       
                        <Route path='/PNP/createsimulation' component={SimulationEntry} />
                        <Route path='/PNP/copysimulation' component={SimulationEntry} />
                        <Route path='/PNP/EditSimulation' component={SimulationEntry} />                  
                        <Route path='/PNP/createsimulationfromreport' component={SimulationEntry} />
                        <Route path='/PNP/Simulations' component={ListofAllSimulations} />
                        <Route path='/PNP/Simulation' component={SimulationOutputAdjusted} />                      
                        <Route path='/PNP/CompareSims' component={CompareSimsPage} />                      
                        <Route path='/PNP/createplan' component={CreatePlan} />
                        <Route path='/PNP/Plans' component={ListofPlans} />
                        <Route path='/PNP/GeneratePDF' component={GeneratePDF} />
                        <Route path='/PNP/Glossary' component={PNPGlossary} />          
                        <Route path='/PNP/Credits' component={PNPCredits} />
                        <Route path='/PNP/profile' component={Profile} />
                        <Route path='/PNP/resources' component={PNPResources} />
                        <Route path='/PNP/PriceBandDefinition' component={PNPPriceBandDefinition} />
                        <Route path='/PNP/DiscountBandDefinition' component={PNPDiscountBandDefinition} />
                        <Route path='/PNP/ItemGroupDefinition' component={PNPItemGroupDefinition} />                      
                        <Route path='/PNP/testing' component={Test} />

                        {/* Price and Discount Trends App */}
                        <Route path='/PDT/Home' component={PDTHome} />
                        <Route path='/PDT/createreport' component={PDTReportEntry} />
                        <Route path='/PDT/copyreport' component={PDTReportEntry} />
                        <Route path='/PDT/EditReport' component={PDTReportEntry} />
                        <Route path='/PDT/Reports' component={PDTListofAllReports} />                      
                        <Route path='/PDT/Report' component={PDTReportOutputWithCustomOrdering} />                                             
                        <Route path='/PDT/Datasets' component={PDTListofAllDatasets} />
                        <Route path='/PDT/DatasetsStaging' component={PDTListofAllDatasetsStaging} />
                        <Route path='/PDT/profile' component={Profile} />
                        <Route path='/PDT/Credits' component={PDTCredits} />
                        <Route path='/PDT/Glossary' component={PDTGlossary} />
                        <Route path='/PDT/resources' component={PDTResources} />
                        <Route path='/PDT/ScreenOrdering' component={PDTScreenOrdering} />
                        <Route path='/PDT/PriceBandDefinition' component={PDTPriceBandDefinition} />
                        <Route path='/PDT/AttributeGroupDefinition' component={PDTAttributeGroupDefinition} />
                        <Route path='/PDT/DownloadData' component={PDTDownloadData} />
                        <Route path='/PDT/DatasetAccess' component={DatasetAccessView} />
                        <Route path='/PDT/WholeIndustry' component={IndustryView} />

                        {/* Price and Discount Trends ETL App */}
                        <Route path='/PDTETL/EntryForm' component={EntryForm} />
                        <Route path='/PDTETL/Extracts' component={PDTListofAllExtracts} />
                        <Route path='/PDTETL/EditExtract' component={EntryForm} />
                        <Route path='/PDTETL/CloudJobStatus' component={PDTETLCloudStatus} />

                        {/* Price and Promotions ETL App */}
                        <Route path='/PNPETL/PNPEntryForm' component={PNPEntryForm} />
                        <Route path='/PNPETL/PNPExtracts' component={PNPListofAllExtracts} />
                        <Route path='/PNPETL/EditExtract' component={PNPEntryForm} />

                        {/* Retailer App */}
                        <Route path='/PDTRetailer/Home' component={PDTHome} />
                        <Route path='/PDTRetailer/createreport' component={PDTReportEntry} />
                        <Route path='/PDTRetailer/copyreport' component={PDTReportEntry} />
                        <Route path='/PDTRetailer/EditReport' component={PDTReportEntry} />
                        <Route path='/PDTRetailer/Reports' component={PDTListofAllReports} />
                        <Route path='/PDTRetailer/Report' component={PDTReportOutputWithCustomOrdering} />
                        <Route path='/PDTRetailer/Datasets' component={PDTListofAllDatasets} />
                        <Route path='/PDTRetailer/DatasetsStaging' component={PDTListofAllDatasetsStaging} />
                        <Route path='/PDTRetailer/profile' component={Profile} />
                        <Route path='/PDTRetailer/Credits' component={PDTCredits} />
                        <Route path='/PDTRetailer/Glossary' component={PDTGlossary} />
                        <Route path='/PDTRetailer/resources' component={PDTResources} />
                        <Route path='/PDTRetailer/ScreenOrdering' component={PDTScreenOrdering} />
                        <Route path='/PDTRetailer/PriceBandDefinition' component={PDTPriceBandDefinition} />
                        <Route path='/PDTRetailer/AttributeGroupDefinition' component={PDTAttributeGroupDefinition} />
                        <Route path='/PDTRetailer/DownloadData' component={PDTDownloadData} />
                     </DeBugTest>
                </AuthService> 

                {/* These are unauthorized routes and the handling of the auth is done in authService.js */}
                <Route path='/unauthorized' component={Unauthorized} />              
            </div>
        );
    }
}
