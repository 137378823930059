import * as Constant from '../../../constants'
import _ from 'lodash'

export default async function defineAttrGroups() {
    let datasetidnum = this.state.dataset_data.dataset_id

    // Check if PriceBands is in the list of settings....
    var user_dataset_settings_data
    let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum)
    if (user_dataset_settings_response.status === 200) {
        user_dataset_settings_data = await user_dataset_settings_response.json()
    } else {
        user_dataset_settings_data = []
    }

    var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)
    console.log('The following user settings exist for dataset ' + datasetidnum + ':')
    console.log(usersettings_list.join(', '))

    var attrgroupsssettings_data
    if (usersettings_list.includes('AttributeGroups')) {
        console.log('Attribute Groups are defined; fetching their definition')
        let attrgroupsssettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/AttributeGroups')
        if (attrgroupsssettings_response.status === 200) {
            attrgroupsssettings_data = await attrgroupsssettings_response.json()
        }
    }
    console.log('"AttributeGroups" setting for dataset ' + datasetidnum + ':')
    console.log(attrgroupsssettings_data)

    var customAttrGroups, customUserAttrGroups, customAttrGroups_defined = false
    if (attrgroupsssettings_data) {
        let settings_value = attrgroupsssettings_data.setting_value
        customAttrGroups = settings_value
        customUserAttrGroups = _.cloneDeep(settings_value)
        customAttrGroups_defined = true
    } else {
        customAttrGroups = {}
        customUserAttrGroups = {}
    }

    // Cleanup customAttrGroups by having lone values be their own groups.....
    let attribute_lookup = this.state.attributes
    let all_attributes = ((this.state.dataset_data || {})['attributes'] || []).concat((this.state.dataset_data || {})['global_attributes'] || [])
    let all_attributes_human = ((this.state.dataset_data || {})['attributes_human'] || []).concat((this.state.dataset_data || {})['global_attributes_human'] || [])
    let attribute_human_lookup = {}
    all_attributes.forEach((attr, idx) => { attribute_human_lookup[attr] = all_attributes_human[idx] })

    // Loop through the attributes, and look and see what values have been used for the custom attribute groups...
    all_attributes.forEach(attr => {
        // attribute has no custom group defined....add all attribute vals as separate groups
        if (!Object.keys(customAttrGroups).includes(attr)) {
            customAttrGroups[attr] = []
            customUserAttrGroups[attr] = []
            let attr_vals = attribute_lookup[attr]
            attr_vals.forEach(val => {
                customAttrGroups[attr].push({ 'groupname': val, 'attr_vals': [val] })
            })
        } else {
            // Look and see what values are in the defined groups...
            let usedvalues = []
            let groups = customAttrGroups[attr]
            groups.forEach(group => {
                usedvalues = usedvalues.concat(group.attr_vals)
            })
            let attr_vals = attribute_lookup[attr]
            attr_vals.forEach(val => {
                // If value is not in the defined groups, add it as a seperate group
                if (!usedvalues.includes(val)) {
                    customAttrGroups[attr].push({ 'groupname': val, 'attr_vals': [val] })
                }
            })
        }
    })

    this.setState({
        customAttrGroups,
        customUserAttrGroups,
        customAttrGroups_defined
    })
}
