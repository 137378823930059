import React, { Component, Fragment } from 'react';
import {  ThemeProvider } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import * as Constant from '../../../constants';

export class ShareMetricRadio extends Component {
    constructor(props) {
        super(props);
    }
    render() {


        return (
            <Fragment>
                <ThemeProvider theme={Constant.muiTheme}>
                    <Radio
                        checked={this.props.state.sharemetricstotal}
                        onChange={this.props.handleChangeShareMetrics}
                        value="Total"
                        name="ShareMetricsRadioTotalUnits"
                        label="Total Units"
                        inputProps={{ 'aria-label': 'Total' }}
                    />
                    <span style={{ lineHeight: '5' }}>Total</span>
                    <Radio
                        checked={this.props.state.sharemetricsbase}
                        onChange={this.props.handleChangeShareMetrics}
                        value="Base"
                        name="ShareMetricsRadioBaseUnits"
                        label="Base Units"
                        inputProps={{ 'aria-label': 'Base' }}
                    />
                    <span style={{ lineHeight: '5' }}>Base</span>
                    <Radio
                        checked={this.props.state.sharemetricsincremental}
                        onChange={this.props.handleChangeShareMetrics}
                        value="Incremental"
                        name="ShareMetricsRadioIncrementalUnits"
                        label="Incremental Units"
                        inputProps={{ 'aria-label': 'Incremental' }}
                    />
                    <span style={{ lineHeight: '5' }}>Incremental</span>
                    <Radio
                        checked={this.props.state.sharemetricspromoted}
                        onChange={this.props.handleChangeShareMetrics}
                        value="Promoted"
                        name="ShareMetricsRadioPromotedUnits"
                        label="Promoted Units"
                        inputProps={{ 'aria-label': 'Promoted' }}
                    />
                    <span style={{ lineHeight: '5' }}>Promoted</span>
                    <Radio
                        checked={this.props.state.sharemetricsnonpromoted}
                        onChange={this.props.handleChangeShareMetrics}
                        value="Non-Promoted"
                        name="ShareMetricsRadioNonPromotedUnits"
                        label="Non-Promoted Units"
                        inputProps={{ 'aria-label': 'Non-Promoted' }}
                    />
                    <span style={{ lineHeight: '5' }}>Non-Promoted</span>

                </ThemeProvider>
            </Fragment>
        )
    }
}