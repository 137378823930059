import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'
import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import { Layout } from '../Layout';
import IconButton from '@material-ui/core/IconButton';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CloseIcon from '@material-ui/icons/Close';
import '../styles/react-datetime.css'
import '../styles/datepicker.scss'
import '../styles/custom.css'
import * as Constant from '../constants'

const dropdownStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        maxHeight: "225px",
        overflowY: "auto"
    }),
}

const dropdownStylesSmall = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        maxHeight: "75px",
        overflowY: "auto"
    }),
}

export class ReportEntry extends Component {
    static displayName = ReportEntry.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickcancel = this.handleClickcancel.bind(this);
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        this.state =
        {
            date: new Date(),
            startDate: new Date(),
            setStartDate: new Date(),
            geos: [],
            brands: [],
            datasets: [],
            reportname: "",
            Geochosen: [],
            Brandchosen: [],
            DataSetChosen: 0,
            selectedstart: null,
            selectedend: null,
            brand_error_display: 'none',
            brand_error_text: '',

            //Form Errors
            num_form_errors: 0,
            report_name_missing: false,
            start_date_missing: false,
            start_date_ltstart: false,
            start_date_gtend: false,
            end_date_ltstart: false,
            end_date_gtend: false,
            end_date_missing: false,
            geos_missing: false,
            brands_missing: false,
            start_gt_end: false,
            //Snackbars
            showBackdrop_CreateReport: false,
            showSnackbar_CreateReportSuccess: false,
            showSnackbar_CreateReportError: false,
            //dates
            pastdate: Datetime.moment().set({ 'year': 2019, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            futuredate: Datetime.moment().set({ 'year': 2021, 'month': 0, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            pastdate_formatted: '2019-01',
            futuredate_formatted: '2021-01',
            //Info Icons
            openInfoProject: false,
            anchorElProjectInfo: null,
            errorAlert: false,
            allReportNames: [],
            report_name_same: false,
            brand_string: "",
            geo_string: "",
            displayReloadDialog: false,
            showBackdrop: false
        }
    }

    handleClick() {
        //Check for errors...
        var num_errors = 0;
        console.log(this.state)
        //Report Name
        if (this.state.reportname === "") {
            num_errors += 1
            this.setState({ report_name_missing: true })
            console.log('No Report Name')
        }
        else {
            this.setState({ report_name_missing: false })
        }
        //Start Date Missing or Wrong Format

        if (this.state.selectedstart) {
            this.setState({ start_date_missing: false })

        }
        else {
            num_errors += 1
            this.setState({ start_date_missing: true })
            console.log('No Start Date')
        }
        //End Date Missing or Wrong Format
        if (this.state.selectedend) {
            this.setState({ end_date_missing: false })
        }
        else {
            num_errors += 1
            this.setState({ end_date_missing: true })
            console.log('No End Date')
        }
        //Missing Geos
        if (this.state.Geochosen.length === 0) {
            num_errors += 1
            this.setState({ geos_missing: true })
            console.log('No Geos')
        }
        else {
            this.setState({ geos_missing: false })
        }
        //Missing Brands
        if (this.state.Brandchosen.length === 0) {
            num_errors += 1
            this.setState({ brands_missing: true })
            console.log('No Brands')
        }
        else {
            this.setState({ brands_missing: false })
        }
        //Start GT End
        if (this.state.selectedstart && this.state.selectedend && this.state.selectedstart > this.state.selectedend) {
            num_errors += 1
            this.setState({ start_gt_end: true })
            console.log('Start GT End')
        }
        else {
            this.setState({ start_gt_end: false })
        }

        if (this.state.start_date_gtend) {
            num_errors += 1
            //this.setState({ start_gt_end: true })
            console.log('Start GT End')
        }
        else {
            //this.setState({ start_gt_end: false })
        }

        if (this.state.start_date_ltstart) {
            num_errors += 1
            //this.setState({ start_gt_end: true })
            console.log('Start Date Less than Report Period')
        }
        else {
            //this.setState({ start_gt_end: false })
        }


        console.log(num_errors);
        if (num_errors > 0) {
            this.setState({
                showSnackbar_CreateReportError: true
            })
            console.log(this.state)
        }

        else {
            if (Constant.entrytype === 'create') {
                const report = {
                    report_name: this.state.reportname,
                    geogid: this.state.Geochosen.map(geo => geo.value), //.map(geo=>geo.label).join(','), //$("#add-geos").val(),
                    item_id_list: [], //items.join(','),
                    start_date: this.state.selectedstart,
                    end_date: this.state.selectedend,
                    dataset_id: this.state.DataSetChosen, //$("#add-source").val(), 
                    report_brands: this.state.Brandchosen.map(brand => brand.value) //.map(brand => brand.label).join(','),
                };

                console.log(JSON.stringify(report));
                this.setState({ showBackdrop: true })
                //Run Fetch for Post
                fetch(Constant.baseapiurl + "ReportSettings",
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(report)
                    })
                    .then(res => {
                        if (res.status === 200) {
                            res.json()
                                .then(
                                    (result) => {
                                        console.log('Report Created!');
                                        console.log(result)                                        
                                        
                                        this.setState({ showSnackbarSuccess: true })
                                        window.location.href = Constant.baseurl + 'PNP/Report/' + result.report_id
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )
                        }
                        else if (res.status === 511) {
                            console.log('Server Went Down, Display message to user')
                            res.json()
                                .then(
                                    (results) => {
                                        console.log(results);
                                        this.setState({
                                            displayReloadDialog: true
                                            , reloadDialog: results["Reason"]
                                            , showBackdrop: false
                                        })
                                    })
                        }
                        else {
                            this.setState({ errorAlert: true })
                        }
                    })

                
            }

            else if (Constant.entrytype === 'edit') {
                const report = {
                    report_name: this.state.reportname,
                    geogid: this.state.Geochosen.map(geo => geo.value), //.map(geo => geo.label).join(','), //$("#add-geos").val(),
                    item_id_list: [], //items.join(','),
                    report_brands: this.state.Brandchosen.map(brand => brand.value), //.map(brand => brand.label).join(','),
                    start_date: this.state.selectedstart,
                    end_date: this.state.selectedend,
                    dataset_id: this.state.DataSetChosen, //$("#add-source").val(),             
                    report_id: this.state.reportid,
                    time_updated: this.state.timeupdated
                };

                //Show the backdrop & Snackbar
               
                console.log(JSON.stringify(report));
                this.setState({ showBackdrop: true })
                fetch(Constant.baseapiurl + "ReportSettings/" + String(this.state.reportid),
                    {
                        method: "PUT",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(report)
                    })
                    .then(res => {
                        //Look at status code, and if not 200, throw error
                        if (res.status === 200) {
                            res.text()
                                //.then(res => res.json())
                                .then(
                                    () => {
                                        console.log('Report Updated!');
                                       
                                        
                                        this.setState({ showSnackbarSuccess: true })
                                        //console.log(result)
                                        window.location.href = Constant.baseurl + 'PNP/Report/' + String(this.state.reportid)
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )
                        }

                        else if (res.status === 511) {
                            console.log('Server Went Down, Display message to user')
                            res.json()
                                .then(
                                    (results) => {
                                        console.log(results);
                                        this.setState({
                                            displayReloadDialog: true
                                            , reloadDialog: results["Reason"]
                                            , showBackdrop: false
                                        })
                                    })
                        }

                        else {
                            console.log('There was an Error Running the API')
                        }
                    })
            }

            else if (Constant.entrytype === 'copy') {
                const report = {
                    report_name: this.state.reportname,
                    geogid: this.state.Geochosen.map(geo => geo.value), //.map(geo => geo.label).join(','), //$("#add-geos").val(),
                    item_id_list: [], //items.join(','),
                    report_brands: this.state.Brandchosen.map(brand => brand.value), //.map(brand => brand.label).join(','),
                    start_date: this.state.selectedstart,
                    end_date: this.state.selectedend,
                    dataset_id: this.state.DataSetChosen, //$("#add-source").val(),             
                    //report_id: this.state.reportid,
                    //time_updated: this.state.timeupdated
                };

                //Show the backdrop & Snackbar
                this.setState({ showBackdrop: true })
                
                console.log(JSON.stringify(report));

                fetch(Constant.baseapiurl + "ReportSettings",
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(report)
                    })
                    .then(res => {
                        if (res.status === 200) {
                            res.json()
                                .then(
                                    (result) => {
                                        console.log('Report Created!');
                                        console.log(result)

                                        this.setState({ showSnackbarSuccess: true })
                                        window.location.href = Constant.baseurl + 'PNP/Report/' + result.report_id
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )
                        }

                        else if (res.status === 511) {
                            console.log('Server Went Down, Display message to user')
                            res.json()
                                .then(
                                    (results) => {
                                        console.log(results);
                                        this.setState({
                                            displayReloadDialog: true
                                            , reloadDialog: results["Reason"]
                                            , showBackdrop: false
                                        })
                                    })
                        }

                        else {
                            this.setState({ errorAlert: true })
                        }
                    }) 
            }            
        }
    }

    handleClickcancel() {
        const findid = () => {
            var url = window.location.href;
            var temp = url.split("/");
            var i = unescape(temp[5]);
            var idnum = parseInt(i, 10);
            return idnum;
        }

        const reportid = findid();
        window.location.href = Constant.baseurl + 'PNP/Report/' + reportid
    }

    handleDateStart(date) {
        console.log(date)
        console.log('In Date Change Func')
        if (date.length < 10) {
            if (date.length === 0) {
                this.setState({ selectedstart: null })
            }
            
        }
        else {
            try {
                var startdate = String(date.format('YYYY-MM-DD'))
                var startdate_year = parseInt(startdate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set... 
                var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
                var startdate_day = parseInt(startdate.substring(8, 10)) 
                var pastdate_to_comp = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day , 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                //look at the value...if less than report start date, throw an error...
                console.log(this.state.pastdate)
                console.log(this.state.futuredate)
                console.log(pastdate_to_comp)
                if (pastdate_to_comp.isBefore(this.state.pastdate)) {
                    console.log('Behind Past Date!!!')
                    //Set an error
                    this.setState({ start_date_ltstart: true })
                    this.setState({ selectedstart: startdate });
                    //this.setState({ selectedstart: "invalid" }); 
                }

                else if (pastdate_to_comp.isAfter(this.state.futuredate)) {
                    console.log('Ahead of Future Date!!!')
                    //this.setState({ selectedstart: "invalid" }); 
                    this.setState({ start_date_gtend: true })
                    this.setState({ selectedstart: startdate });
                }

                else {
                    console.log('Ok!!')
                    this.setState({ selectedstart: startdate });
                    this.setState({ start_date_ltstart: false })
                    this.setState({ start_date_gtend: false })
                }


            }
            catch (err) {
                console.log(err)
            }
        }

    };

    handleDateEnd(date) {
        if (date.length < 10) {
            if (date.length === 0) {
                this.setState({ selectedend: null })
            }
        }
        else {
            try {
                var enddate = String(date.format('YYYY-MM-DD'))
                var enddate_year = parseInt(enddate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set... 
                var enddate_month = parseInt(enddate.substring(5, 7)) - 1 //Zero Based Month                                    
                var enddate_day = parseInt(enddate.substring(8, 10)) - 1
                var future_to_comp = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                //look at the value...if less than report start date, throw an error...
                console.log(this.state.pastdate)
                console.log(this.state.futuredate)
                console.log(future_to_comp)
                if (future_to_comp.isBefore(this.state.pastdate)) {
                    console.log('Behind Past Date!!!')
                    //Set an error
                    this.setState({ end_date_ltstart: true })
                    this.setState({ selectedend: enddate });
                    //this.setState({ selectedstart: "invalid" }); 
                }

                else if (future_to_comp.isAfter(this.state.futuredate)) {
                    console.log('Ahead of Future Date!!!')
                    //this.setState({ selectedstart: "invalid" }); 
                    this.setState({ end_date_gtend: true })
                    this.setState({ selectedend: enddate });
                }

                else {
                    console.log('Ok!!')
                    this.setState({ selectedend: enddate });
                    this.setState({ end_date_ltstart: false })
                    this.setState({ end_date_gtend: false })
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    };

    handleChange = () => {
        this.setState({ reportname: document.getElementById('reportname').value })
        this.setState({ selectedbrands: document.getElementById('branddropdown').value })
        this.setState({ selectedgeos: document.getElementById('geodropdown').value })
        //Run the Report Parameters
        let allReportNames = this.state.allReportNames
        let reportname = document.getElementById('reportname').value
        console.log(allReportNames)
        console.log(reportname)
        if (allReportNames.includes(reportname)) {
            console.log('Throw a warning here...')
            this.setState({ report_name_same: true })
        }
        else {
            //Revert Back to Safe State
            this.setState({ report_name_same: false })
        }
    }

    onChangeGeo(selectedOptions, action) {
        console.log(selectedOptions)
        //var num_geos_selected = 0
        if (selectedOptions && selectedOptions.length > 0) {
            var selected_geos = selectedOptions.map(option => option.label)
            var selected_geo_ids = ['Total US', 'Target ALR', 'Walmart ALR']
            if (selected_geos.includes('Select All')) {
                this.setState({ Geochosen: this.state.geos });
                //num_geos_selected = this.state.geos.length
                selected_geo_ids = this.state.geos.map(option => option.value)
            }
            else {
                this.setState({ Geochosen: selectedOptions });
                //num_geos_selected = selectedOptions.length
                selected_geo_ids = selectedOptions.map(option => option.value)
            }

            //Run the API to set the brand options
            
            fetch(Constant.baseapiurl + "Datasets/" + this.state.DataSetChosen + "/Parameters?geos=" + selected_geo_ids.join(','))
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        //console.log('ok')
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    var brands_array = []
                                    results.brands.map(brand => (brands_array.push({ 'value': brand, 'label': brand })))

                                    //compare brands_array to already selected brands...
                                    //if brand is selected, but not in brands....remove from the list
                                    let selectedbrands = this.state.Brandchosen.map(brand=> brand.label)
                                    let eligible_brands = brands_array.map(brand => brand.label)

                                    let brands_chosen = []
                                    selectedbrands.forEach(brand => { if (eligible_brands.includes(brand)){ brands_chosen.push({ 'value': brand, 'label': brand }) }})

                                    this.setState({ brands: brands_array, Brandchosen: brands_chosen})
                                })
                    }
                })
        }

        else {
            this.setState({
                Geochosen: []
            })
            //num_geos_selected = 0
        }

        //var element = document.getElementById("geodropdown");

    }

    onChangeBrand(selectedOptions, action) {
        console.log(selectedOptions)
        var num_brands_selected = 0
        if (selectedOptions && selectedOptions.length > 0) {
            var selected_brands = selectedOptions.map(option => option.label)
            if (selected_brands.includes('Select All')) {
                this.setState({ Brandchosen: this.state.brands });
                num_brands_selected = this.state.brands.length
            }
            else {
                this.setState({ Brandchosen: selectedOptions });
                num_brands_selected = selectedOptions.length
            }
        }
        else {
            this.setState({
                Brandchosen: []
            })
            num_brands_selected = 0
        }
        console.log(num_brands_selected)

    }

    onChangeDataSet(selectedOptions, action) {
        //Set Loading State to True...
        this.setState({ DataSetChosen: selectedOptions.value, showBackdrop_CreateReport: true });
        console.log(selectedOptions.value)
        //Reset Selected Brands to []
        //Reset Selected Geos to []
        //Reset Brand Options to []
        this.setState( {
            Geochosen: []
            , Brandchosen: []
            , brands: []
        })
        fetch(Constant.baseapiurl + "Datasets/" + selectedOptions.value + "/Parameters")
            //.then(res => console.log(res.body))
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (results) => {
                                console.log(results);
                                //startdate
                                var pastdate_formatted = results.times[0].substring(0, 10)
                                var startdate = results.times[0]
                                var startdate_year = parseInt(startdate.substring(0, 4))  //Removing this for now to let the users select the entire time frame ...+1 //Indicates One Extra Year of Data....start with 2nd year in the data set... 
                                var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
                                var startdate_day = parseInt(startdate.substring(8, 10)) - 1
                                var pastdate = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(6, 'd');
                                
                                //enddate
                                var futuredate_formatted = results.times[1].substring(0, 10)
                                var enddate = results.times[1]
                                var enddate_year = parseInt(enddate.substring(0, 4))
                                var enddate_month = parseInt(enddate.substring(5, 7)) - 1 //Zero Based Month                                    
                                var enddate_day = parseInt(enddate.substring(8, 10))
                                var futuredate = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                                //set state
                                this.setState({ pastdate, futuredate, pastdate_formatted, futuredate_formatted })

                                var brands_array = []
                                results.brands.map(brand => (brands_array.push({ 'value': brand, 'label': brand })))
                                var geos_array = []

                                //Walkthrough geos and if RM or ALR in the name, then add the appropriate clarification to the label...
                                results.geos.forEach(geo => {
                                    if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                                        geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
                                    }
                                    else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                                        geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
                                    }
                                    else if (geo.geogdesc.toUpperCase().indexOf('ASSG') > -1) {
                                        geo.geogdesc_modified = geo.geogdesc + ' (Athletic Specialty Sporting Goods)'
                                    }
                                    else {
                                        geo.geogdesc_modified = geo.geogdesc
                                    }
                                }
                                )

                                results.geos.map(geo => (geos_array.push({ 'value': geo.geogid, 'label': geo.geogdesc_modified })))

                                //Create a string of brands
                                let brand_string = ""
                                results.brands.map(brand => brand_string += "," + brand)
                                console.log(brand_string)
                                console.log(brand_string.substring(1))
                                this.setState({ brand_string: brand_string.substring(1) })

                                let geo_string = ""
                                results.geos.map(geo => geo_string += "," + geo.geogdesc_modified)
                                console.log(geo_string)
                                console.log(geo_string.substring(1))
                                this.setState({ geo_string: geo_string.substring(1) })

                                console.log(geos_array)
                                //get labels
                                var geo_labels = geos_array.map(geo => geo.label)
                                //Find if 'Total US' is in geo_labels and remove from the array....
                                var TUS_index = geo_labels.indexOf('Total US')
                                console.log(TUS_index)
                                var sorted_geos = []
                                if (TUS_index !== -1) {
                                    geo_labels.splice(TUS_index, 1)
                                    //Need to Sort geos_array with Total US first, and everything ekse sorted by the description
                                    sorted_geos = ['Total US'].concat(geo_labels.sort())

                                }
                                else {
                                    sorted_geos = geo_labels.sort()
                                }
                                console.log(sorted_geos)

                                var finalsorted_geos = []
                                sorted_geos.forEach(geo => {
                                    for (var g = 0; g < geos_array.length; g++) {
                                        if (geos_array[g].label === geo) {
                                            finalsorted_geos.push(geos_array[g])
                                        }
                                    }
                                })

                                console.log(finalsorted_geos)
                                //Set Loading State to False
                                this.setState({
                                    //brands: brands_array,
                                    geos: finalsorted_geos,
                                    showBackdrop_CreateReport: false

                                });
                            },

                            (error) => {
                                console.log(error)
                                this.setState({
                                    isLoaded: true,
                                    error,
                                    showBackdrop_CreateReport: false
                                });
                            }
                        )
                }

                else if (res.status === 511) {
                    console.log('Server Went Down, Display message to user')
                    res.json()
                        .then(
                            (results) => {
                                console.log(results);
                                this.setState({
                                    displayReloadDialog: true
                                    , reloadDialog: results["Reason"]
                                    , showBackdrop: false
                                })
                            })
                }

                else {
                    //alert('There was an error')
                }
            })

    }

    componentDidMount() {
        const findid = () => {            
            var url = window.location.href;            
            var temp = url.split("/");            
            var i = unescape(temp[5]);
            var idnum = parseInt(i, 10);
            return idnum;
        }

        //Get all Reports
        fetch(Constant.baseapiurl + "ReportSettings")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (results) => {
                                this.setState({ allReportNames: results.map(report => report.report_name) })
                            })
                }
                else if (res.status === 511) {
                    console.log('Server Went Down, Display message to user')
                    res.json()
                        .then(
                            (results) => {
                                console.log(results);
                                this.setState({
                                    displayReloadDialog: true
                                    , reloadDialog: results["Reason"]
                                    , showBackdrop: false
                                })
                            })
                }
                else {
                    console.log('There was an error')
                }
            })

        //If Copy or Edit, pull in Report Info and Populate the State....
        if (Constant.entrytype !== 'create') {
            //find the id and set to the state.
            var reportid = findid()
            var geos_array = []
            var brands_array = []
            var distinctgeonames = [];

            //find the parameters of the report and set to the state.
            fetch(Constant.baseapiurl + "ReportSettings/" + reportid).then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    //console.log('ok')
                    res.json()
                        .then(
                            (result) => {
                                var datasetgeos = []
                                var report_geos = result.geogid
                                fetch(Constant.baseapiurl + "Datasets/" + result.dataset_id + "/Parameters?geos=" + report_geos.join(','))
                                    .then(res => {
                                        //Look at status code, and if not 200, throw error
                                        if (res.status === 200) {
                                            //console.log('ok')
                                            res.json()
                                                .then(
                                                    (results) => {
                                                        console.log(results);

                                                        //startdate
                                                        var startdate = results.times[0]
                                                        var startdate_year = parseInt(startdate.substring(0, 4))
                                                        var startdate_month = parseInt(startdate.substring(5, 7)) - 1 //Zero Based Month                                    
                                                        var startdate_day = parseInt(startdate.substring(8, 10))
                                                        var pastdate = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day - 1, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(6, 'd');
                                                        var pastdate_formatted = pastdate.subtract(-1, 'd').format('YYYY-MM-DD')
                                                        //enddate
                                                        var enddate = results.times[1]
                                                        var enddate_year = parseInt(enddate.substring(0, 4))
                                                        var enddate_month = parseInt(enddate.substring(5, 7)) - 1 //Zero Based Month                                    
                                                        var enddate_day = parseInt(enddate.substring(8, 10))
                                                        var futuredate = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                                                        var futuredate_formatted = pastdate.format('YYYY-MM-DD')
                                                        //set state
                                                        this.setState({ pastdate, futuredate, pastdate_formatted, futuredate_formatted })

                                                        datasetgeos = results.geos
                                                        results.brands.map(brand => (brands_array.push({ 'value': brand, 'label': brand })))

                                                        //Walkthrough geos and if RM or ALR in the name, then add the appropriate clarification to the label...
                                                        results.geos.forEach(geo => {
                                                            if (geo.geogdesc.toUpperCase().indexOf('RM') > -1) {
                                                                geo.geogdesc_modified = geo.geogdesc + ' (Remaining Market)'
                                                            }
                                                            else if (geo.geogdesc.toUpperCase().indexOf('ALR') > -1) {
                                                                geo.geogdesc_modified = geo.geogdesc + ' (Account Level Report)'
                                                            }
                                                            else if (geo.geogdesc.toUpperCase().indexOf('ASSG') > -1) {
                                                                geo.geogdesc_modified = geo.geogdesc + ' (Athletic Specialty Sporting Goods)'
                                                            }
                                                            else {
                                                                geo.geogdesc_modified = geo.geogdesc
                                                            }
                                                        }
                                                        )

                                                        //var geos_array = []
                                                        results.geos.map(geo => (geos_array.push({ 'value': geo.geogid, 'label': geo.geogdesc_modified })))

                                                        //Add Geo String Here
                                                        //Create a string of brands
                                                        let brand_string = ""
                                                        results.brands.map(brand => brand_string += "," + brand)
                                                        console.log(brand_string)
                                                        console.log(brand_string.substring(1))
                                                        this.setState({ brand_string: brand_string.substring(1) })

                                                        let geo_string = ""
                                                        results.geos.map(geo => geo_string += "," + geo.geogdesc_modified)
                                                        console.log(geo_string)
                                                        console.log(geo_string.substring(1))
                                                        this.setState({ geo_string: geo_string.substring(1) })

                                                        console.log(geos_array)
                                                        //get labels
                                                        var geo_labels = geos_array.map(geo => geo.label)
                                                        //Find if 'Total US' is in geo_labels and remove from the array....
                                                        var TUS_index = geo_labels.indexOf('Total US')
                                                        console.log(TUS_index)
                                                        var sorted_geos = []
                                                        if (TUS_index !== -1) {
                                                            geo_labels.splice(TUS_index, 1)
                                                            //Need to Sort geos_array with Total US first, and everything ekse sorted by the description
                                                            sorted_geos = ['Total US'].concat(geo_labels.sort())

                                                        }
                                                        else {
                                                            sorted_geos = geo_labels.sort()
                                                        }
                                                        console.log(sorted_geos)

                                                        var finalsorted_geos = []
                                                        sorted_geos.forEach(geo => {
                                                            for (var g = 0; g < geos_array.length; g++) {
                                                                if (geos_array[g].label === geo) {
                                                                    finalsorted_geos.push(geos_array[g])
                                                                }
                                                            }
                                                        })

                                                        console.log(finalsorted_geos)


                                                        this.setState({
                                                            brands: brands_array,
                                                            geos: finalsorted_geos,
                                                            datasetgeos: results.geos
                                                        });
                                                        var selectedgeos = []
                                                        datasetgeos.forEach(datasetgeo => {
                                                            //console.log(datasetgeo)
                                                            result.geogid.forEach(geo => {
                                                                //console.log(geo)
                                                                if (geo === datasetgeo.geogid) {
                                                                    selectedgeos.push(({ 'value': geo, 'label': datasetgeo.geogdesc_modified }))
                                                                    distinctgeonames.push(datasetgeo.geogdesc_modified)
                                                                    this.setState({ Geochosen: selectedgeos, distinctgeos: datasetgeos })
                                                                }
                                                            })
                                                        })

                                                    },

                                                    (error) => {
                                                        this.setState({
                                                            isLoaded: true,
                                                            error
                                                        });
                                                    }
                                                )
                                        }

                                        else if (res.status === 511) {
                                            console.log('Server Went Down, Display message to user')
                                            res.json()
                                                .then(
                                                    (results) => {
                                                        console.log(results);
                                                        this.setState({
                                                            displayReloadDialog: true
                                                            , reloadDialog: results["Reason"]
                                                            , showBackdrop: false
                                                        })
                                                    })
                                        }

                                        else {
                                            console.log('There was an Error Running the API')
                                        }
                                    })

                                //console.log(result)
                                this.setState({ reportid: result.report_id })
                                if (Constant.entrytype === 'edit') {
                                    this.setState({ reportname: result.report_name })
                                }
                                else {
                                    this.setState({ reportname: 'Copy of ' + result.report_name })
                                }
                                this.setState({ DataSetChosen: result.dataset_id })
                                var datasetfromreport = result.dataset_id
                                var selectedbrands = []
                                result.report_brands.forEach(brand => { selectedbrands.push({ 'label': brand, 'value': brand }) })
                                this.setState({ Brandchosen: selectedbrands })

                                this.setState({ selectedstart: result.start_date.substring(0, 10) })
                                this.setState({ selectedend: result.end_date.substring(0, 10) })
                                this.setState({ timeupdated: result.time_updated })

                                fetch(Constant.baseapiurl + "Datasets")
                                    .then(res => {
                                        //Look at status code, and if not 200, throw error
                                        if (res.status === 200) {
                                            //console.log('ok')
                                            res.json()
                                                .then(
                                                    (results) => {
                                                        //console.log(results);                                
                                                        results.forEach(result => {
                                                            //console.log(result.dataset_id)
                                                            //console.log(datasetfromreport)
                                                            var dataset_array = []
                                                            if (result.dataset_id === datasetfromreport) {
                                                                //console.log('match')
                                                                dataset_array.push({ 'value': result.dataset_id, 'label': result.shortdesc })
                                                                this.setState({
                                                                    datasets: dataset_array
                                                                });
                                                            }
                                                        })
                                                        this.setState({ showBackdrop: false })

                                                    },

                                                    (error) => {
                                                        this.setState({
                                                            isLoaded: true,
                                                            error
                                                        });
                                                    }
                                                )
                                        }
                                        else if (res.status === 511) {
                                            console.log('Server Went Down, Display message to user')
                                            res.json()
                                                .then(
                                                    (results) => {
                                                        console.log(results);
                                                        this.setState({
                                                            displayReloadDialog: true
                                                            , reloadDialog: results["Reason"]
                                                            , showBackdrop: false
                                                        })
                                                    })
                                        }
                                        else {
                                            console.log('There was an Error Running the API')
                                        }
                                    })
                            }
                        )
                }
                else if (res.status === 511) {
                    console.log('Server Went Down, Display message to user')
                    res.json()
                        .then(
                            (results) => {
                                console.log(results);
                                this.setState({
                                    displayReloadDialog: true
                                    , reloadDialog: results["Reason"]
                                    , showBackdrop: false
                                })
                            })
                }
                else {
                    console.log('There was an Error Running the API')
                }
            })
        }

        else {
            console.log('Loading Datasets for Create Report')
            fetch(Constant.baseapiurl + "Datasets")
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (results) => {
                                    //console.log(results);
                                    var dataset_array = []
                                    results.map(result => (dataset_array.push({ 'value': result.dataset_id, 'label': result.shortdesc })))
                                    this.setState({
                                        datasets: dataset_array
                                    });
                                },

                                (error) => {
                                    this.setState({
                                        isLoaded: true,
                                        error
                                    });
                                }
                            )
                    }
                    else if (res.status === 511) {
                        console.log('Server Went Down, Display message to user')
                        res.json()
                            .then(
                                (results) => {
                                    console.log(results);
                                    this.setState({
                                        displayReloadDialog: true
                                        , reloadDialog: results["Reason"]
                                        , showBackdrop: false
                                    })
                                })
                    }
                    else {
                        //alert('There was an Error')
                    }
                }
                )
        }
    }




    render() {
        console.log(this.state)

        const divheight = {
            height: '00px'
        }

        const buttonStyle = {
            color: '#fff',
            backgroundColor: '#4e106f',
            borderColor: '#4e106f',
            padding: '10px',
            fontFamily: 'Roboto',
        }

        const buttonStyle2 = {
            color: '#fff',
            backgroundColor: '#000000',
            borderColor: '#000000',
            padding: '10px',
            fontFamily: 'Roboto',
            marginLeft:'50px'
        }

        const dateInput = {
            width: '300px',
            height: '50px',
            borderRadius: '5px',
            //padding: '10px',
            fontFamily: 'Roboto',
        }

        const dropdownInput = {
            width: '100%',
            height: '50px',
            borderRadius: '5px',
            //padding: '10px',
            fontFamily: 'Roboto',

        }



        const closeSuccessSnackBar = () => {
            this.setState({ showSnackbarSuccess: false })
        }

        const closeErrorSnackBar = () => {
            this.setState({ showSnackbarError: false })
        }

        const handleCloseReloadDialog = () => {
            this.setState({ displayReloadDialog: false })
            var wheretosendme = ""
            if (Constant.entrytype === 'create') {
                wheretosendme = 'PNP/createreport'
            }

            else if (Constant.entrytype === 'edit') {
                wheretosendme = 'PNP/EditReport/' + this.state.reportid
            }

            else if (Constant.entrytype === 'copy') {
                wheretosendme = 'PNP/copyreport/' + this.state.reportid
            }

            window.location.href = Constant.baseurl + wheretosendme
        }

        const valid = (current) => {
            return current.day() === 6 && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
        };

        const valid_start = (current) => {
            return current.day() === 0 && current.isSameOrAfter(this.state.pastdate) && current.isSameOrBefore(this.state.futuredate)
        };

        const alloption = { 'label': 'Select All', 'value': '*' }

        const showInfoProject = event => {
            console.log('Opening popover')
            this.setState({ openInfoProject: true })
            this.setState({ anchorElProjectInfo: event.target })
        }

        const hideInfoProject = () => {
            console.log('Closing popover')
            this.setState({ openInfoProject: false })
            this.setState({ anchorElProjectInfo: null })
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
        }

        const type = Constant.entrytype.substring(0,1).toUpperCase() + Constant.entrytype.substring(1)

        return (
            <Layout title={ type+ ' Report'}>
                <Constant.NPDBackdrop
                    open={this.state.showBackdrop}
                    //style={{  }} //, color: '#fff' }}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>
                <Constant.NPDSnackbarSuccess
                    open={this.state.showSnackbarSuccess}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={closeSuccessSnackBar}
                >
                    <Constant.NPDSnackbarContentSuccess
                        message="Changes Made Successfully"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeErrorSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    />
                </Constant.NPDSnackbarSuccess>
                <Constant.NPDSnackbarError
                    open={this.state.showSnackbarError}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={closeErrorSnackBar}
                >
                    <Constant.NPDSnackbarContentError
                        message="There are Errors in the form"
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeErrorSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    >
                    </Constant.NPDSnackbarContentError>
                </Constant.NPDSnackbarError>
                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Create Report Error"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There was an error with the report.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.displayReloadDialog}
                    onClose={handleCloseReloadDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Page Must Be Reloaded"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.reloadDialog}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseReloadDialog} className="btn btn-primary" autoFocus>
                            Reload
		                </button>
                    </DialogActions>
                </Dialog>

                <div name='banner' className='banner'>
                    <h1white align='center'>{type + ' a Report'}</h1white><br/>
                    <h2white align='center'> This will evaluate a previous promotion and calculate metrics</h2white>
                </div>
                <div className="contentHolderGradient" style={{ width: '100%', height: '100%', position: 'absolute', left: '120px', top: '210px', maxWidth: 'calc(100vw - 120px)', paddingLeft: '40px' }}>
                    <div style={divheight}></div>
                    <h3 style={{ fontFamily: 'Roboto',color: '#4e106f',padding: '10px', }}>{type + ' Report'}</h3>
                    <p style={{ fontFamily: 'Roboto',padding: '10px', }}> Please complete the form for evalulation. </p>
                    <span className='formfieldtitle' style={{ padding: '10px' }}> <sup>* indicates required field</sup></span>
                    <form className="reportentry" noValidate >
                        <input type='hidden' id='datasetid' />
                        <div className="row" style={{ padding: '10px', }}>
                            <div className='col-sm-12' style={{ borderRadius: '5px' }}>
                                <span className='formfieldtitle'>Report Name<sup>*</sup></span>
                                <br />
                                <div className="" style={{ backgroundColor: "#FFF", height: '55%', borderRadius: '5px', }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Report Name"
                                        inputProps={{ 'aria-label': 'naked', id:"reportname" }}
                                        onChange={this.handleChange}
                                        id="reportname"
                                        style={dropdownInput}
                                        value={this.state.reportname}
                                    />
                                </div>
                            </div>
                            {this.state.report_name_missing ? < span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} />  Report name must be provided</span> : <span></span>}
                            {this.state.report_name_same ? < span style={{ color: '#f0ab00' }}><WarningRoundedIcon style={{ color: '#f0ab00' }} />  Warning! This Report is named the same as a previous one.  This may make it difficult to find later.</span> : <span></span>}
                        </div>


                        <div className="row" style={{ padding: '10px' }}>
                            <div style={dropdownInput} className='col-sm-12'>
                                <span className='formfieldtitle'>Dataset<sup>*</sup></span>
                                <InfoRoundedIcon
                                    style={{ position: 'relative', top: '-10px', fontSize: '15px' }}
                                    onMouseEnter={showInfoProject}
                                    onMouseLeave={hideInfoProject}
                                />
                                <Popover
                                    style={{ pointerEvents: 'none' }}
                                    open={this.state.openInfoProject}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    anchorEl={this.state.anchorElProjectInfo}
                                >
                                    This will contain the items, brands, and geographies you wish to run in your report.
                                    <br />
                                    <br />The selected dataset contains the following geographies: {this.state.geo_string}
                                    <br />
                                    <br />The selected dataset contains the following brands: {this.state.brand_string}
                                    <br />
                                </Popover>
                                {Constant.entrytype === 'create' ?
                                    <Select
                                        //id="datasetdropdown"
                                        inputId="datasetdropdown"
                                        options={this.state.datasets}
                                        //value={this.state.datasets}
                                        placeholder={'Select a dataset to use'}
                                        onChange={(item, action) => this.onChangeDataSet(item, action)}
                                    />
                                    :
                                    <Select
                                        inputId="datasetdropdown"
                                        options={this.state.datasets}
                                        value={this.state.datasets}
                                        //placeholder={'Select a dataset to use'}
                                        //onChange={(item, action) => this.onChangeDataSet(item, action)}
                                    />
                                }
                                

                            </div>
                        </div>

                        <div className="row" style={{ padding: '10px' }}>
                            <div style={dateInput} className='col-sm-6'>
                                <span className='formfieldtitle'>Report Start Date<sup>*</sup></span>
                                <Datetime
                                    input={true}
                                    isValidDate={valid_start}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    onChange={this.handleDateStart}
                                    value={this.state.selectedstart}
                                    inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportstartdate" }}
                                    viewDate={(Constant.entrytype === 'edit' || Constant.entrytype === 'copy') ? new Date(this.state.selectedstart) : this.state.pastdate}
                                />
                                {this.state.start_date_missing ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date must be provided</span> : <span></span>}
                                {this.state.start_date_ltstart ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date must be greater than or equal to {this.state.pastdate_formatted} </span> : <span></span>}
                                {this.state.start_date_gtend ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date must be less than or equal to {this.state.futuredate_formatted} </span> : <span></span>}

                            </div>

                            <div style={dateInput} className='col-sm-6'>
                                <span className='formfieldtitle'>Report End Date<sup>*</sup></span>
                                <Datetime
                                    input={true}
                                    isValidDate={valid}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    onChange={this.handleDateEnd}
                                    value={this.state.selectedend}
                                    inputProps={{ placeholder: 'YYYY-MM-DD', id: "reportenddate" }}
                                    viewDate={(Constant.entrytype === 'edit' || Constant.entrytype === 'copy') ? new Date(this.state.selectedend) : this.state.futuredate}
                                />
                                {this.state.end_date_missing ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> End date must be provided</span> : <span></span>}
                                {this.state.start_gt_end ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> Start date must be less than end date</span> : <span></span>}
                                {this.state.end_date_ltstart ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> End date must be greater than or equal to {this.state.pastdate_formatted} </span> : <span></span>}
                                {this.state.end_date_gtend ? <span style={{ color: '#822433' }}><ErrorRoundedIcon style={{ color: '#822433' }} /> End date must be less than or equal to {this.state.futuredate_formatted} </span> : <span></span>}                            
                            </div>
                            

                        </div>

                        <div className="row" style={{ padding: '10px' }} id="dropdowns">

                            <div  className='col-sm-12'>
                                <span className='formfieldtitle'>Geographies<sup>*</sup></span>
                                <Select
                                    inputId="geodropdown"
                                    options={[alloption, ...this.state.geos]}
                                    isMulti
                                    placeholder={'Select Geographies'}
                                    onChange={(item, action) => this.onChangeGeo(item, action)}
                                    value={this.state.Geochosen}
                                    styles={dropdownStylesSmall}
                                />
                                {this.state.geos_missing ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> At least one geography must be provided</span> : <span></span>}
                            </div>

                            

                            <div className='col-sm-12'>
                                <span className='formfieldtitle'>Brands<sup>*</sup></span>
                                <Select                                    
                                    inputId="branddropdown"
                                    options={[alloption, ...this.state.brands]}
                                    isMulti
                                    placeholder={'Select Brands'}
                                    onChange={(item, action) => this.onChangeBrand(item, action)}
                                    value={this.state.Brandchosen}
                                    styles={dropdownStyles}
                                />
                                {this.state.brands_missing ? <span style={{ color: '#822433', }}><ErrorRoundedIcon style={{ color: '#822433' }} /> At least one brand must be provided</span> : <span></span>}

                            </div>

                            

                        </div>                        

                        <div className="row" style={{ padding: '25px' }}>
                            <div style={dateInput} className='col-sm-6'></div>
                            <div style={dateInput} className='col-sm-6'>
                                <span id='brand_error' style={{ color: '#822433', display: this.state.brand_error_display }}><ErrorRoundedIcon style={{ color: '#822433' }} /> {this.state.brand_error_text}</span>

                                    <Button variant="contained" onClick={this.handleClick} style={buttonStyle} id="runbutton">
                                    Save and Run
                                    </Button>

                                    {Constant.entrytype !== 'create' ?
                                    <Button variant="contained" onClick={this.handleClickcancel} style={buttonStyle2}>
                                        Cancel
                                    </Button>
                                    :
                                    <span />}

                            </div>
                        </div>
                    </form>

                </div>
            </Layout>
        )
    }
}
