import React from 'react';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './styles/custom.css'
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import PDT from './images/PDT.png';

import WidgetsRoundedIcon from '@material-ui/icons/WidgetsRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import logoreverse from "./images/logo_reverse.png"
import circleonly from "./images/android-chrome-192x192.png"
import * as Constant from './constants'

export class PNPSideBar extends React.Component {
    static displayName = PNPSideBar.name;

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            error: null,
            isLoaded: false,
            reports: [],
            simulations: [],
            anchorEl_Rpt: null,
            open_Rpt: false,
            anchorEl_Sim: null,
            open_Sim: false,
            open_Plan: false,
            open_Resource: false,
            active_report: false,
            active_sim: false,
            active_plan: false,
            active_resource: false,
            errorAlert: false,
            reportIconColor: '#7A4C93',
            simIconColor: '#7A4C93',
            planIconColor: '#7A4C93',
            resourceIconColor: '#7A4C93',
            homeIconColor: '#7A4C93',
            environment: "",
            //Report Favorites
            report_favorites: [],
            //Sim Favorites
            sim_favorites: []
        };
    }

    componentDidMount() {
        console.log('API Calls Being Made...')
        this.mounted = true;
        if (this.mounted) {
            fetch('/environment/getenvironment')
                .then(res => {
                    res.text()
                        .then(
                            (result) => {
                                this.setState({ environment: result })
                            })
                })
            //Report Favorites
            fetch(Constant.baseapiurl + "ReportSettings/Favorites")
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let favorites = result.map(favorite => favorite.report_id)
                                    this.setState({ report_favorites: favorites })
                                })
                    }
                    else {
                        this.setState({ errorAlert: false })
                    }
                })

            //Sim Favorites
            fetch(Constant.baseapiurl + "SimSettings/Favorites")
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let favorites = result.map(favorite => favorite.sim_id)
                                    this.setState({ sim_favorites: favorites })
                                })
                    }
                    else {
                        this.setState({ errorAlert: false })
                    }
                })

            fetch(Constant.baseapiurl + "ReportSettings")
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let dt = new Date()
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                    let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                    this.setState({
                                        isLoaded: true,
                                        reports: result_sorted,
                                        errorAlert: false
                                    });
                                },

                                (error) => {
                                    this.setState({
                                        isLoaded: true,
                                        error
                                    });
                                }
                            )
                    }
                    else if (res.status === 401) {
                        this.setState({ errorAlert: false })
                    }

                    else {
                        this.setState({ errorAlert: false })
                    }
                })

            fetch(Constant.baseapiurl + "SimSettings")
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let dt = new Date()
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                    let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                    this.setState({
                                        isLoaded: true,
                                        simulations: result_sorted
                                    });
                                },

                                (error) => {
                                    this.setState({
                                        isLoaded: true,
                                        error
                                    });
                                }
                            )
                    }
                    else {
                        console.log('There was an Error Running the API')
                    }
                })
        }
        else {
            console.log('Component is not mounted')
        }


    }

    componentWillUnmount() {
        //Set Mount State to false...if not mounted, do not call the api requests...
        this.mounted = false;
    }


    render() {
        //console.log(this.state)
        const reports = this.state.reports;
        //Split reports into favorites and nonfavorites...
        var favorite_reports = []
        var nonfavorite_reports = []

        reports.forEach(report => {
            if (this.state.report_favorites.includes(report.report_id)) {
                favorite_reports.push(report)
            }
            else {
                nonfavorite_reports.push(report)
            }
        })

        const simulations = this.state.simulations;
        var favorite_sims = []
        var nonfavorite_sims = []
        simulations.forEach(simulation => {
            if (this.state.sim_favorites.includes(simulation.sim_id)) {
                favorite_sims.push(simulation)
            }
            else {
                nonfavorite_sims.push(simulation)
            }
        })

        const handlePopover_Rpt = event => {
            var currentstate_rpt = this.state.open_Rpt
            var active_report = this.state.active_report
            this.setState({ open_Rpt: !currentstate_rpt, active_report: !active_report })
            if (currentstate_rpt === false) {
                this.setState({ reportIconColor: '#7A4C93' })
            }

            else {
                this.setState({ reportIconColor: '#7A4C93' })
            }

            //Refresh List of Reports...
            //Report Favorites
            fetch(Constant.baseapiurl + "ReportSettings/Favorites")
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let favorites = result.map(favorite => favorite.report_id)
                                    this.setState({ report_favorites: favorites })
                                })
                    }
                    else {
                        this.setState({ errorAlert: false })
                    }
                })

            fetch(Constant.baseapiurl + "ReportSettings")
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    //console.log(result)
                                    let dt = new Date()
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                    let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                    this.setState({
                                        isLoaded: true,
                                        reports: result_sorted,
                                        errorAlert: false
                                    });
                                },

                                (error) => {
                                    this.setState({
                                        isLoaded: true,
                                        error
                                    });
                                }
                            )
                    }
                    else if (res.status === 401) {
                        this.setState({ errorAlert: false })
                    }

                    else {
                        this.setState({ errorAlert: false })
                    }
                })
        }

        const handlePopover_Sim = event => {
            var currentstate_sim = this.state.open_Sim
            var active_sim = this.state.active_sim
            this.setState({ open_Sim: !currentstate_sim, active_sim: !active_sim })
            if (currentstate_sim === false) {
                this.setState({ simIconColor: '#7A4C93' })
            }

            else {
                this.setState({ simIconColor: '#7A4C93' })
            }

            //Refresh List of Sims...
            //Sim Favorites
            fetch(Constant.baseapiurl + "SimSettings/Favorites")
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let favorites = result.map(favorite => favorite.sim_id)
                                    this.setState({ sim_favorites: favorites })
                                })
                    }
                    else {
                        this.setState({ errorAlert: false })
                    }
                })

            fetch(Constant.baseapiurl + "SimSettings")
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let dt = new Date()
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                    let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                    this.setState({
                                        isLoaded: true,
                                        simulations: result_sorted
                                    });
                                },

                                (error) => {
                                    this.setState({
                                        isLoaded: true,
                                        error
                                    });
                                }
                            )
                    }
                    else {
                        console.log('There was an Error Running the API')
                    }
                })
        }

        const handlePopover_Plan = event => {
            var currentstate_plan = this.state.open_Plan
            var active_plan = this.state.active_plan
            this.setState({ open_Plan: !currentstate_plan, active_plan: !active_plan })
            if (currentstate_plan === false) {
                this.setState({ planIconColor: '#7A4C93' })
            }

            else {
                this.setState({ planIconColor: '#7A4C93' })
            }
        }

        const handlePopover_Resource = event => {
            var currentstate_resource = this.state.open_Resource
            var active_resource = this.state.active_resource
            this.setState({ open_Resource: !currentstate_resource, active_resource: !active_resource })
            if (currentstate_resource === false) {
                this.setState({ resourceIconColor: '#7A4C93' })
            }

            else {
                this.setState({ resourceIconColor: '#7A4C93' })
            }
        }

        const handlegobackhome = event => {
            window.location.href = Constant.baseurl + 'PNP/Home'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
            window.setTimeout(function () {
                window.location.reload()
            }, 1000)
            //location.reload();
            //window.location.href = baseurl + 'account/logout'
        }

        const handlegodatasets = event => {
            window.location.href = Constant.baseurl + 'PNP/Datasets'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handleClickFavorite = (event, id, type, remove_add) => {

            var current_favorites = []
            var new_favorites = []

            if (type === 'report') {
                current_favorites = this.state.report_favorites
                new_favorites = []
                if (remove_add === 'remove') {
                    //Perform the following
                    //Remove from state of report favorites                    
                    current_favorites.forEach(reportid => { if (reportid !== id) { new_favorites.push(reportid) } })
                    this.setState({ report_favorites: new_favorites })

                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
                else {
                    //Perform the following
                    //Add to report favorites
                    current_favorites.push(id)
                    this.setState({ report_favorites: current_favorites })
                    //Perform API to add to favorites

                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
            }
            else if (type === 'sim') {
                current_favorites = this.state.sim_favorites
                new_favorites = []
                if (remove_add === 'remove') {
                    current_favorites.forEach(simid => { if (simid !== id) { new_favorites.push(simid) } })
                    this.setState({ sim_favorites: new_favorites })
                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "SimSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })
                }
                else {
                    current_favorites.push(id)
                    this.setState({ sim_favorites: current_favorites })
                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "SimSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })
                }
            }
        }

        return (
            <Constant.NPDDrawer
                variant="permanent"
                anchor="left"
                style={{ backgroundColor: '#FFF', height: '100%' }}
            >
                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Unfortunately your session has expired. This page will be refreshed.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <div style={{ width: '120px' }}>
                    <a href="/">
                        <img src={circleonly} height='120px' width='120px' align='center' style={{ padding: '20px' }} alt="Logo" /> 
                    </a>
                    <div style={{ height: '25px' }}></div>
                    <div
                        className={this.state.active_report ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Rpt}
                        align='center'
                        style={{ padding: '30px' }}
                    >
                        <DescriptionRoundedIcon
                            style={{ color: this.state.reportIconColor, fontSize: '2.2rem', pointerEvents: 'none' }}
                            align='center'
                        />

                        <div align='center'>
                            <span style={{ color: this.state.reportIconColor, fontSize: '1rem', }}>Reports</span>
                        </div>
                    </div>
                    <div
                        className={this.state.active_sim ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Sim}
                        align='center'
                        style={{ padding: '30px' }}
                    >
                        <TrendingUpRoundedIcon style={{ color: this.state.simIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.simIconColor, fontSize: '1rem', }}>Simulations</span>
                        </div>

                    </div>
                    <div
                        className={this.state.active_plan ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Plan}
                        align='center'
                        style={{ padding: '30px' }}
                    >
                        <EventNoteRoundedIcon style={{ color: this.state.planIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.planIconColor, fontSize: '1rem', }}>Plans</span>
                        </div>

                    </div>
                    <div
                        className='sidebar_div'
                        onClick={handlegodatasets}
                        align='center'
                        style={{ padding: '30px' }}
                    >
                        <WidgetsRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Datasets</span>
                        </div>

                    </div>
                    <div
                        className={this.state.active_resource ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Resource}
                        align='center'
                        style={{ padding: '30px' }}
                    >
                        <InfoRoundedIcon style={{ color: this.state.resourceIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.resourceIconColor, fontSize: '1rem', }}>Resources</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegobackhome}
                        align='center'
                        style={{ padding: '30px' }}
                    >
                        <HomeRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Home</span>
                        </div>

                    </div>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        //open={true}
                        open={this.state.open_Rpt || false}
                        onClose={handlePopover_Rpt}
                        marginThreshold={0}
                        transitionDuration={0}
                    >

                        <div style={{ width: '235px', height: '100vh', backgroundColor: "#FFF", verticalAlign: 'middle', textAlign: "center" }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Rpt}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PNP/createreport' >Create A New Report <AddCircleRoundedIcon /></a>
                            </div>
                            {/*Return Favorites First*/}
                            
                            {favorite_reports.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if (idx < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Report/' + report.report_id} >{report.report_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')} >
                                                    <StarRoundedIcon style={{ color: '#4E106F' }} />
                                                </div>
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Report/' + report.report_id} >{report.report_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')}>
                                                    <StarRoundedIcon style={{ color: '#4E106F' }} />
                                                </div>
                                            </div>



                                        )
                                    }
                                }
                                else {
                                    return null
                                }
                            })}
                            {/*Then NonFavorites*/}
                            {nonfavorite_reports.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if ((idx + 1 + favorite_reports.length) < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Report/' + report.report_id} >{report.report_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')} >
                                                    <StarBorderRoundedIcon style={{ color: '#4E106F' }} />
                                                </div>
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Report/' + report.report_id} >{report.report_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')}>
                                                    <StarBorderRoundedIcon style={{ color: '#4E106F' }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                else {
                                    return null
                                }
                            })}
                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PNP/Reports' >All Reports</a>
                            </div>
                        </div>

                    </Constant.NPDPopover>



                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.open_Sim || false} //{open}
                        onClose={handlePopover_Sim}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Sim}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PNP/createsimulation' >Create A New Simulation <AddCircleRoundedIcon /></a>
                            </div>
                            {/*First Favorites*/}
                            {favorite_sims.map((simulation, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var report_updated_date = new Date(simulation.time_updated_adj.substring(0, 19))
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = simulation.time_updated_adj.substring(0, 19)
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = simulation.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if (idx < 15) { //Only return the first 15...
                                    if (simulation.sim_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Simulation' + simulation.sim_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Simulation/' + simulation.sim_id} >{simulation.sim_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, simulation.sim_id, 'sim', 'remove')} >
                                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Simulation' + simulation.sim_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Simulation/' + simulation.sim_id} >{simulation.sim_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, simulation.sim_id, 'sim', 'remove')} >
                                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                }

                                else {
                                    return null
                                }


                            })}

                            {nonfavorite_sims.map((simulation, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var report_updated_date = new Date(simulation.time_updated_adj.substring(0, 19))
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = simulation.time_updated_adj.substring(0, 19)
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = simulation.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = simulation.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if ((idx + 1 + favorite_sims.length) < 15) { //Only return the first 15...
                                    if (simulation.sim_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Simulation' + simulation.sim_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Simulation/' + simulation.sim_id} >{simulation.sim_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, simulation.sim_id, 'sim', 'add')} >
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Simulation' + simulation.sim_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PNP/Simulation/' + simulation.sim_id} >{simulation.sim_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, simulation.sim_id, 'sim', 'add')} >
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                else {
                                    return null
                                }

                            })}

                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PNP/Simulations' >All Simulations</a>
                            </div>
                        </div>

                    </Constant.NPDPopover>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.open_Plan || false}
                        onClose={handlePopover_Plan}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                    >
                        {this.state.environment === 'Development' ?
                            <div style={{ width: '235px', height: '100vh' }}>
                                <div className="reportlinksclosediv" align='left'>
                                    <span
                                        onClick={handlePopover_Plan}
                                    >
                                        Close Menu
                                    </span>
                                </div>
                                <div className="reportlinksdiv" align='left'>
                                    <a className='createlinks' href='/PNP/createplan' >Create A New Plan <AddCircleRoundedIcon /></a>
                                </div>
                                {/*plans.map(plan => (
                                    <div className="reportlinksdiv" key={'Plan'+plan.plan_id}>
                                        <a className='reportlinks' href={'/Plan/' + plan.plan_id} >{plan.plan_name} </a>
                                    </div>
                                ))*/}
                            </div>
                            :
                            <div style={{ width: '235px', height: '100vh' }}>
                                <div className="reportlinksclosediv" align='left'>
                                    <span
                                        onClick={handlePopover_Plan}
                                    >
                                        Close Menu
                                    </span>
                                </div>
                                <div className="reportlinksdiv" align='left'>
                                    This Feature will be coming soon
                                </div>
                            </div>
                        }
                    </Constant.NPDPopover>
                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.open_Resource || false}
                        onClose={handlePopover_Resource}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Resource}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PNP/Resources'} target="_blank" rel="noopener noreferrer" >Resources Page </a>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PNP/Glossary'} target="_blank" rel="noopener noreferrer" >Glossary </a>
                            </div>
                            {/*
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/Contact'} target="_blank">Contact Us </a>
                            </div>
                            */}
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PNP/Credits'} target="_blank" rel="noopener noreferrer">Credits </a>
                            </div>
                        </div>
                    </Constant.NPDPopover>
                </div>
            </Constant.NPDDrawer>
        )
    }
}

export class PDTSideBar extends React.Component {
    static displayName = PDTSideBar.name;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            reports: [],
            simulations: [],
            anchorEl_Rpt: null,
            open_Rpt: false,
            anchorEl_Sim: null,
            open_Sim: false,
            open_Plan: false,
            open_Resource: false,
            open_Config: false,
            active_report: false,
            active_sim: false,
            active_plan: false,
            active_resource: false,
            active_config: false,
            errorAlert: false,
            reportIconColor: '#7A4C93',
            simIconColor: '#7A4C93',
            planIconColor: '#7A4C93',
            resourceIconColor: '#7A4C93',
            homeIconColor: '#7A4C93',
            configIconColor: '#7A4C93',
            environment: "",
            //Report Favorites
            report_favorites: [],
            //Sim Favorites
            sim_favorites: []
        };
    }

    componentDidMount() {
        
        fetch('/environment/getenvironment')
            .then(res => {
                res.text()
                    .then(
                        (result) => {
                            this.setState({ environment: result })
                        })
            })
        
        //Report Favorites
        fetch(Constant.baseapiurl + "ReportSettings/Favorites")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                let favorites = result.map(favorite => favorite.report_id)
                                this.setState({ report_favorites: favorites })
                            })
                }
                else {
                    this.setState({ errorAlert: false })
                }
            })

        fetch(Constant.baseapiurl + "ReportSettings")
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                let dt = new Date()
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                this.setState({
                                    isLoaded: true,
                                    reports: result_sorted,
                                    errorAlert: false
                                });
                            },

                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                }
                else if (res.status === 401) {
                    this.setState({ errorAlert: false })
                }

                else {
                    this.setState({ errorAlert: false })
                }
            })
            
    }


    render() {
        //console.log(this.state)
        const reports = this.state.reports;
        //Split reports into favorites and nonfavorites...
        var favorite_reports = []
        var nonfavorite_reports = []

        reports.forEach(report => {
            if (this.state.report_favorites.includes(report.report_id)) {
                favorite_reports.push(report)
            }
            else {
                nonfavorite_reports.push(report)
            }
        })

        const handlePopover_Rpt = event => {
            var currentstate_rpt = this.state.open_Rpt
            var active_report = this.state.active_report
            this.setState({ open_Rpt: !currentstate_rpt, active_report: !active_report })
            if (currentstate_rpt === false) {
                this.setState({ reportIconColor: '#7A4C93' })
            }

            else {
                this.setState({ reportIconColor: '#7A4C93' })
            }
            //Refresh List of Reports...
            fetch(Constant.baseapiurl + "ReportSettings/Favorites")
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let favorites = result.map(favorite => favorite.report_id)
                                    this.setState({ report_favorites: favorites })
                                })
                    }
                    else {
                        this.setState({ errorAlert: false })
                    }
                })
            
            fetch(Constant.baseapiurl + "ReportSettings")
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let dt = new Date()
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                    let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                    this.setState({
                                        isLoaded: true,
                                        reports: result_sorted,
                                        errorAlert: false
                                    });
                                },

                                (error) => {
                                    this.setState({
                                        isLoaded: true,
                                        error
                                    });
                                }
                            )
                    }
                    else if (res.status === 401) {
                        this.setState({ errorAlert: false })
                    }

                    else {
                        this.setState({ errorAlert: false })
                    }
                })
        }

        const handlePopover_Resource = event => {
            var currentstate_resource = this.state.open_Resource
            var active_resource = this.state.active_resource
            this.setState({ open_Resource: !currentstate_resource, active_resource: !active_resource })
            if (currentstate_resource === false) {
                this.setState({ resourceIconColor: '#7A4C93' })
            }

            else {
                this.setState({ resourceIconColor: '#7A4C93' })
            }
        }

        const handlePopover_Config = event => {
            var currentstate_config = this.state.open_Config
            var active_config = this.state.active_config
            this.setState({ open_Config: !currentstate_config, active_config: !active_config })
            if (currentstate_config === false) {
                this.setState({ configIconColor: '#7A4C93' })
            }

            else {
                this.setState({ configIconColor: '#7A4C93' })
            }
        }

        const handlegobackhome = event => {
            window.location.href = Constant.baseurl + 'PDT/Home'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
            window.setTimeout(function () {
                window.location.reload()
            }, 1000)
        }

        const handlegodatasets = event => {
            window.location.href = Constant.baseurl + 'PDT/Datasets'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handlegodatasetdownload = event => {
            window.location.href = Constant.baseurl + 'PDT/DownloadData'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handleClickFavorite = (event, id, type, remove_add) => {

            if (type === 'report') {
                var current_favorites = this.state.report_favorites
                var new_favorites = []
                if (remove_add === 'remove') {
                    //Perform the following
                    //Remove from state of report favorites                    
                    current_favorites.forEach(reportid => { if (reportid !== id) { new_favorites.push(reportid) } })
                    this.setState({ report_favorites: new_favorites })

                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
                else {
                    //Perform the following
                    //Add to report favorites
                    current_favorites.push(id)
                    this.setState({ report_favorites: current_favorites })
                    //Perform API to add to favorites

                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
            }

        }

        return (
            <Constant.NPDDrawer
                variant="permanent"
                anchor="left"
                style={{ backgroundColor: '#FFF', height: '100%' }}
            >
                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Unfortunately your session has expired. This page will be refreshed.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <div style={{ width: '120px' }}>
                    <a href="/">
                        <img src={circleonly} height='120px' width='120px' align='center' style={{ padding: '20px' }} alt="Logo" />
                    </a>
                    <div style={{ height: '25px' }}></div>
                    <div
                        className={this.state.active_report ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Rpt}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <DescriptionRoundedIcon
                            style={{ color: this.state.reportIconColor, fontSize: '2.2rem', pointerEvents: 'none' }}
                            align='center'
                        />

                        <div align='center'>
                            <span style={{ color: this.state.reportIconColor, fontSize: '1rem', }}>Reports</span>
                        </div>
                    </div>

                    <div
                        className={this.state.active_config ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Config}
                        align='center'
                        style={{ paddingTop: '25px', paddingBottom: '25px', paddingLeft: '5px', paddingRight: '5px' }}
                    >
                        <SettingsRoundedIcon style={{ color: this.state.configIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.configIconColor, fontSize: '1rem', }}>Configuration</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegodatasets}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <WidgetsRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Datasets</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegodatasetdownload}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <GetAppRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Download Data</span>
                        </div>

                    </div>

                    <div
                        className={this.state.active_resource ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Resource}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <InfoRoundedIcon style={{ color: this.state.resourceIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.resourceIconColor, fontSize: '1rem', }}>Resources</span>
                        </div>

                    </div>                    

                    <div
                        className='sidebar_div'
                        onClick={handlegobackhome}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <HomeRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Home</span>
                        </div>

                    </div>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        //open={true}
                        open={this.state.open_Rpt || false}
                        onClose={handlePopover_Rpt}
                        marginThreshold={0}
                        transitionDuration={0}
                    >

                        <div style={{ width: '235px', height: '100vh', backgroundColor: "#FFF", verticalAlign: 'middle', textAlign: "center" }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Rpt}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PDT/createreport' >Create A New Report <AddCircleRoundedIcon /></a>
                            </div>
                            {/*Return Favorites First*/}
                            {favorite_reports.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if (idx < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDT/Report/' + report.report_id} >{report.report_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')} >
                                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDT/Report/' + report.report_id} >{report.report_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')}>
                                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>



                                        )
                                    }
                                }

                                else {
                                    return null
                                }
                            })}
                            {/*Then NonFavorites*/}
                            {nonfavorite_reports.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if ((idx + 1 + favorite_reports.length) < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDT/Report/' + report.report_id} >{report.report_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')} >
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDT/Report/' + report.report_id} >{report.report_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')}>
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                }

                                else {
                                    return null
                                }
                            })}

                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PDT/Reports' >All Reports</a>
                            </div>
                        </div>

                    </Constant.NPDPopover>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.open_Resource || false}
                        onClose={handlePopover_Resource}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Resource}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDT/Resources'} target="_blank" rel="noopener noreferrer" >Resources Page </a>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDT/Glossary'} target="_blank" rel="noopener noreferrer">Glossary </a>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDT/Credits'} target="_blank" rel="noopener noreferrer" >Credits </a>
                            </div>
                        </div>
                    </Constant.NPDPopover>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.open_Config || false}
                        onClose={handlePopover_Config}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Config}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDT/ScreenOrdering'} target="_blank" rel="noopener noreferrer" >Report Output Ordering </a>
                            </div>
                        </div>
                    </Constant.NPDPopover>

                </div>
            </Constant.NPDDrawer>
        )
    }
    
}

export class PDTETLSideBar extends React.Component {
    static displayName = PDTETLSideBar.name;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            extracts: [],
            simulations: [],
            anchorEl_Rpt: null,
            open_Rpt: false,
            anchorEl_Sim: null,
            open_Sim: false,
            open_Plan: false,
            open_Resource: false,
            active_report: false,
            active_sim: false,
            active_plan: false,
            active_resource: false,
            errorAlert: false,
            reportIconColor: '#7A4C93',
            simIconColor: '#7A4C93',
            planIconColor: '#7A4C93',
            resourceIconColor: '#7A4C93',
            homeIconColor: '#7A4C93',
            environment: "",
            //Report Favorites
            extracts_favorites: [],
            //Sim Favorites
            sim_favorites: []
        };
    }

    componentDidMount() {

        fetch('/environment/getenvironment')
            .then(res => {
                res.text()
                    .then(
                        (result) => {
                            this.setState({ environment: result })
                        })
            })
        //Report Favorites
        {/*
        fetch(Constant.baseapiurl + "ReportSettings/Favorites")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                let favorites = result.map(favorite => favorite.report_id)
                                this.setState({ report_favorites: favorites })
                            })
                }
                else {
                    this.setState({ errorAlert: false })
                }
            })
            */}
        fetch(Constant.baseapiurl_gen + "etl/Pdt_Etl")
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    console.log(res)
                    res.json()
                        .then(
                            (result) => {
                                let dt = new Date()
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                console.log(result_sorted)
                                this.setState({
                                    isLoaded: true,
                                    reports: result_sorted,
                                    errorAlert: false
                                });
                            },

                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                }
                else if (res.status === 401) {
                    this.setState({ errorAlert: false })
                }

                else {
                    this.setState({ errorAlert: false })
                }
            })
    }


    render() {
        //console.log(this.state)
        const extracts = this.state.extracts;
        //Split reports into favorites and nonfavorites...

        var nonfavorite_extracts = []

        extracts.map(extracts => {

            nonfavorite_extracts.push(extracts)

        })

        const handlePopover_Rpt = event => {
            var currentstate_rpt = this.state.open_Rpt
            var active_report = this.state.active_report
            this.setState({ open_Rpt: !currentstate_rpt, active_report: !active_report })
            if (currentstate_rpt === false) {
                this.setState({ reportIconColor: '#7A4C93' })
            }

            else {
                this.setState({ reportIconColor: '#7A4C93' })
            }
        }

        const handlePopover_Resource = event => {
            var currentstate_resource = this.state.open_Resource
            var active_resource = this.state.active_resource
            this.setState({ open_Resource: !currentstate_resource, active_resource: !active_resource })
            if (currentstate_resource === false) {
                this.setState({ resourceIconColor: '#7A4C93' })
            }

            else {
                this.setState({ resourceIconColor: '#7A4C93' })
            }
        }
        const handlegobackhome = event => {
            window.location.href = Constant.baseurl
        }

        const handlegocloudstatus = event => {
            window.location.href = Constant.baseurl + 'PDTETL/CloudJobStatus'
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
            window.setTimeout(function () {
                window.location.reload()
            }, 1000)
        }


        return (
            <Constant.NPDDrawer
                variant="permanent"
                anchor="left"
                style={{ backgroundColor: '#FFF', height: '50%' }}
                open={false}
            >
                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Unfortunately your session has expired. This page will be refreshed.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <div style={{ width: '120px' }}>
                    <a href="/">
                        <img src={circleonly} height='120px' width='120px' align='center' style={{ padding: '20px' }} alt="Logo" />
                    </a>
                    <div style={{ height: '25px' }}></div>
                    <div
                        className={this.state.active_report ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Rpt}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <DescriptionRoundedIcon
                            style={{ color: this.state.reportIconColor, fontSize: '2.2rem', pointerEvents: 'none' }}
                            align='center'
                        />

                        <div align='center'>
                            <span style={{ color: this.state.reportIconColor, fontSize: '1rem', }}>Extracts</span>
                        </div>
                    </div>

                    {/*}
                    <div
                        className={this.state.active_resource ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Resource}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <InfoRoundedIcon style={{ color: this.state.resourceIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.resourceIconColor, fontSize: '1rem', }}>Resources</span>
                        </div>

                    </div>
                    */}

                    <div
                        className='sidebar_div'
                        onClick={handlegocloudstatus}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <CloudRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Cloud Jobs Status</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegobackhome}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <HomeRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Home</span>
                        </div>

                    </div>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 120, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        //open={true}
                        open={this.state.open_Rpt || false}
                        onClose={handlePopover_Rpt}
                        marginThreshold={0}
                        transitionDuration={0}
                    >

                        <div style={{ width: '235px', height: '20vh', backgroundColor: "#FFF", verticalAlign: 'middle', textAlign: "center" }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Rpt}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PDTETL/EntryForm' >Create A New Extract <AddCircleRoundedIcon /></a>
                            </div>
                            {/*Then NonFavorites*/}
                            {nonfavorite_extracts.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                //console.log(current_date_val)
                                //console.log(report_updated_date_val)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                //console.log(Difference_In_Time)
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if ((idx + 1 + nonfavorite_extracts.length) < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Extract' + extracts.etl_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTETL/Extracts/' + extracts.etl_id} >{extracts.etl_shortname.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                {/* <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')} >
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>*/}
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Extract' + extracts.etl_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTETL/Extracts/' + extracts.etl_id} >{extracts.etl_shortname}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                {/*<div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')}>
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>*/}
                                            </div>
                                        )
                                    }
                                }
                            })}
                            {/*}
                            <div className="row" align='left' key={'ReportDemo'} >
                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDT/Report/Demo'} >Demo Report
                                        <br />
                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                            Jan 01 2020
                                        </span>
                                    </a>
                                </div>
                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} >
                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                </div>
                            </div>
                            */}

                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PDTETL/Extracts' >All Extracts</a>
                            </div>
                        </div>

                    </Constant.NPDPopover>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                        open={false}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Resource}
                                >
                                    Close Menu
                                </span>
                            </div>
                            
                        </div>
                    </Constant.NPDPopover>
                </div>
            </Constant.NPDDrawer>
        )
    }
}

export class PNPETLSideBar extends React.Component {
    static displayName = PNPETLSideBar.name;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            extracts: [],
            simulations: [],
            anchorEl_Rpt: null,
            open_Rpt: false,
            anchorEl_Sim: null,
            open_Sim: false,
            open_Plan: false,
            open_Resource: false,
            active_report: false,
            active_sim: false,
            active_plan: false,
            active_resource: false,
            errorAlert: false,
            reportIconColor: '#7A4C93',
            simIconColor: '#7A4C93',
            planIconColor: '#7A4C93',
            resourceIconColor: '#7A4C93',
            homeIconColor: '#7A4C93',
            environment: "",
            //Report Favorites
            extracts_favorites: [],
            //Sim Favorites
            sim_favorites: []
        };
    }

    componentDidMount() {

        fetch('/environment/getenvironment')
            .then(res => {
                res.text()
                    .then(
                        (result) => {
                            this.setState({ environment: result })
                        })
            })
        //Report Favorites
        {/*
        fetch(Constant.baseapiurl + "ReportSettings/Favorites")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                let favorites = result.map(favorite => favorite.report_id)
                                this.setState({ report_favorites: favorites })
                            })
                }
                else {
                    this.setState({ errorAlert: false })
                }
            })
            */}
        fetch(Constant.baseapiurl_gen + "etl/Pnp_Etl")
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    console.log(res)
                    res.json()
                        .then(
                            (result) => {
                                let dt = new Date()
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                console.log(result_sorted)
                                this.setState({
                                    isLoaded: true,
                                    reports: result_sorted,
                                    errorAlert: false
                                });
                            },

                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                }
                else if (res.status === 401) {
                    this.setState({ errorAlert: false })
                }

                else {
                    this.setState({ errorAlert: false })
                }
            })
    }


    render() {
        //console.log(this.state)
        const extracts = this.state.extracts;
        //Split reports into favorites and nonfavorites...

        var nonfavorite_extracts = []

        extracts.map(extracts => {

            nonfavorite_extracts.push(extracts)

        })

        const handlegocloudstatus = event => {
            window.location.href = Constant.baseurl + 'PDTETL/CloudJobStatus'
        }

        const handlePopover_Rpt = event => {
            var currentstate_rpt = this.state.open_Rpt
            var active_report = this.state.active_report
            this.setState({ open_Rpt: !currentstate_rpt, active_report: !active_report })
            if (currentstate_rpt === false) {
                this.setState({ reportIconColor: '#7A4C93' })
            }

            else {
                this.setState({ reportIconColor: '#7A4C93' })
            }
        }

        const handlePopover_Resource = event => {
            var currentstate_resource = this.state.open_Resource
            var active_resource = this.state.active_resource
            this.setState({ open_Resource: !currentstate_resource, active_resource: !active_resource })
            if (currentstate_resource === false) {
                this.setState({ resourceIconColor: '#7A4C93' })
            }

            else {
                this.setState({ resourceIconColor: '#7A4C93' })
            }
        }
        const handlegobackhome = event => {
            window.location.href = Constant.baseurl
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
            window.setTimeout(function () {
                window.location.reload()
            }, 1000)
        }


        return (
            <Constant.NPDDrawer
                variant="permanent"
                anchor="left"
                style={{ backgroundColor: '#FFF', height: '50%' }}
            >
                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Unfortunately your session has expired. This page will be refreshed.
		            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
		            </button>
                    </DialogActions>
                </Dialog>

                <div style={{ width: '120px' }}>
                    <img src={circleonly} height='120px' width='120px' align='center' style={{ padding: '20px' }} />
                    <div style={{ height: '25px' }}></div>
                    <div
                        className={this.state.active_report ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Rpt}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <DescriptionRoundedIcon
                            style={{ color: this.state.reportIconColor, fontSize: '2.2rem', pointerEvents: 'none' }}
                            align='center'
                        />

                        <div align='center'>
                            <span style={{ color: this.state.reportIconColor, fontSize: '1rem', }}>Extracts</span>
                        </div>
                    </div>

                    {/*}
                    <div
                        className={this.state.active_resource ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Resource}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <InfoRoundedIcon style={{ color: this.state.resourceIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.resourceIconColor, fontSize: '1rem', }}>Resources</span>
                        </div>

                    </div>
                    */}

                    <div
                        className='sidebar_div'
                        onClick={handlegocloudstatus}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <CloudRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Cloud Jobs Status</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegobackhome}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <HomeRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Home</span>
                        </div>

                    </div>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 120, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        //open={true}
                        open={this.state.open_Rpt || false}
                        onClose={handlePopover_Rpt}
                        marginThreshold={0}
                        transitionDuration={0}
                    >

                        <div style={{ width: '235px', height: '20vh', backgroundColor: "#FFF", verticalAlign: 'middle', textAlign: "center" }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Rpt}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PNPETL/PNPEntryForm' >Create A New Extract <AddCircleRoundedIcon /></a>
                            </div>
                            {/*Then NonFavorites*/}
                            {nonfavorite_extracts.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                //console.log(current_date_val)
                                //console.log(report_updated_date_val)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                //console.log(Difference_In_Time)
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    var timeString = report.time_updated_adj.substring(11, 19);
                                    var H = +timeString.substr(0, 2);
                                    var h = H % 12 || 12;
                                    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if ((idx + 1 + nonfavorite_extracts.length) < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Extract' + extracts.etl_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTETL/Extracts/' + extracts.etl_id} >{extracts.etl_shortname.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                {/* <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')} >
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>*/}
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Extract' + extracts.etl_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTETL/Extracts/' + extracts.etl_id} >{extracts.etl_shortname}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                {/*<div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')}>
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>*/}
                                            </div>
                                        )
                                    }
                                }
                            })}
                            {/*}
                            <div className="row" align='left' key={'ReportDemo'} >
                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDT/Report/Demo'} >Demo Report
                                        <br />
                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                            Jan 01 2020
                                        </span>
                                    </a>
                                </div>
                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} >
                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                </div>
                            </div>
                            */}

                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PNPETL/PNPExtracts' >All Extracts</a>
                            </div>
                        </div>

                    </Constant.NPDPopover>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                        open={false}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Resource}
                                >
                                    Close Menu
                                </span>
                            </div>

                        </div>
                    </Constant.NPDPopover>
                </div>
            </Constant.NPDDrawer>
        )
    }
}


export class PDTRetailerSideBar extends React.Component {
    static displayName = PDTSideBar.name;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            reports: [],
            simulations: [],
            anchorEl_Rpt: null,
            open_Rpt: false,
            anchorEl_Sim: null,
            open_Sim: false,
            open_Plan: false,
            open_Resource: false,
            open_Config: false,
            active_report: false,
            active_sim: false,
            active_plan: false,
            active_resource: false,
            active_config: false,
            errorAlert: false,
            reportIconColor: '#7A4C93',
            simIconColor: '#7A4C93',
            planIconColor: '#7A4C93',
            resourceIconColor: '#7A4C93',
            homeIconColor: '#7A4C93',
            configIconColor: '#7A4C93',
            environment: "",
            //Report Favorites
            report_favorites: [],
            //Sim Favorites
            sim_favorites: []
        };
    }

    componentDidMount() {

        fetch('/environment/getenvironment')
            .then(res => {
                res.text()
                    .then(
                        (result) => {
                            this.setState({ environment: result })
                        })
            })

        //Report Favorites
        fetch(Constant.baseapiurl + "ReportSettings/Favorites")
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                let favorites = result.map(favorite => favorite.report_id)
                                this.setState({ report_favorites: favorites })
                            })
                }
                else {
                    this.setState({ errorAlert: false })
                }
            })

        fetch(Constant.baseapiurl + "ReportSettings")
            .then(res => {
                //Look at status code, and if not 200, throw error
                if (res.status === 200) {
                    res.json()
                        .then(
                            (result) => {
                                let dt = new Date()
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                this.setState({
                                    isLoaded: true,
                                    reports: result_sorted,
                                    errorAlert: false
                                });
                            },

                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        )
                }
                else if (res.status === 401) {
                    this.setState({ errorAlert: false })
                }

                else {
                    this.setState({ errorAlert: false })
                }
            })

    }


    render() {
        //console.log(this.state)
        const reports = this.state.reports;        
        //Split reports into favorites and nonfavorites...
        var favorite_reports = []
        var nonfavorite_reports = []

        //Look to datasets in constant file and if report dataset is there, push to appropriate list
        reports.forEach(report => {
            if (Constant.PDT_RETAILER_DATASETS.includes(report.dataset_id)) {
                if (this.state.report_favorites.includes(report.report_id)) {
                    favorite_reports.push(report)
                }
                else {
                    nonfavorite_reports.push(report)
                }
            }            
        })

        const handlePopover_Rpt = event => {
            var currentstate_rpt = this.state.open_Rpt
            var active_report = this.state.active_report
            this.setState({ open_Rpt: !currentstate_rpt, active_report: !active_report })
            if (currentstate_rpt === false) {
                this.setState({ reportIconColor: '#7A4C93' })
            }

            else {
                this.setState({ reportIconColor: '#7A4C93' })
            }
            //Refresh List of Reports...
            fetch(Constant.baseapiurl + "ReportSettings/Favorites")
                .then(res => {
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let favorites = result.map(favorite => favorite.report_id)
                                    this.setState({ report_favorites: favorites })
                                })
                    }
                    else {
                        this.setState({ errorAlert: false })
                    }
                })

            fetch(Constant.baseapiurl + "ReportSettings")
                .then(res => {
                    //Look at status code, and if not 200, throw error
                    if (res.status === 200) {
                        res.json()
                            .then(
                                (result) => {
                                    let dt = new Date()
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    result.map(row => row.time_updated_adj = (new Date(Date.parse(row.time_updated) - offset)).toISOString())
                                    let result_sorted = result.sort(function (a, b) { return Date.parse(b.time_updated) - Date.parse(a.time_updated) })
                                    this.setState({
                                        isLoaded: true,
                                        reports: result_sorted,
                                        errorAlert: false
                                    });
                                },

                                (error) => {
                                    this.setState({
                                        isLoaded: true,
                                        error
                                    });
                                }
                            )
                    }
                    else if (res.status === 401) {
                        this.setState({ errorAlert: false })
                    }

                    else {
                        this.setState({ errorAlert: false })
                    }
                })
        }

        const handlePopover_Resource = event => {
            var currentstate_resource = this.state.open_Resource
            var active_resource = this.state.active_resource
            this.setState({ open_Resource: !currentstate_resource, active_resource: !active_resource })
            if (currentstate_resource === false) {
                this.setState({ resourceIconColor: '#7A4C93' })
            }

            else {
                this.setState({ resourceIconColor: '#7A4C93' })
            }
        }

        const handlePopover_Config = event => {
            var currentstate_config = this.state.open_Config
            var active_config = this.state.active_config
            this.setState({ open_Config: !currentstate_config, active_config: !active_config })
            if (currentstate_config === false) {
                this.setState({ configIconColor: '#7A4C93' })
            }

            else {
                this.setState({ configIconColor: '#7A4C93' })
            }
        }

        const handlegobackhome = event => {
            window.location.href = Constant.baseurl + 'PDTRetailer/Home'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handleCloseUsererrorAlert = () => {
            this.setState({ errorAlert: false })
            window.setTimeout(function () {
                window.location.reload()
            }, 1000)
        }

        const handlegodatasets = event => {
            window.location.href = Constant.baseurl + 'PDTRetailer/Datasets'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handlegodatasetdownload = event => {
            window.location.href = Constant.baseurl + 'PDTRetailer/DownloadData'
            //this.setState({ resourceIconColor: '#4e106f' })
        }

        const handleClickFavorite = (event, id, type, remove_add) => {

            if (type === 'report') {
                var current_favorites = this.state.report_favorites
                var new_favorites = []
                if (remove_add === 'remove') {
                    //Perform the following
                    //Remove from state of report favorites                    
                    current_favorites.forEach(reportid => { if (reportid !== id) { new_favorites.push(reportid) } })
                    this.setState({ report_favorites: new_favorites })

                    //Perform API to remove from favorites
                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'DELETE' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
                else {
                    //Perform the following
                    //Add to report favorites
                    current_favorites.push(id)
                    this.setState({ report_favorites: current_favorites })
                    //Perform API to add to favorites

                    fetch(Constant.baseapiurl + "ReportSettings/" + id + "/Favorite", { method: 'POST' })
                        .then(res => {
                            if (res.status === 200) {
                                console.log('OK')
                            }
                            else {
                                console.log('Error')
                            }
                        })

                }
            }

        }

        return (
            <Constant.NPDDrawer
                variant="permanent"
                anchor="left"
                style={{ backgroundColor: '#FFF', height: '100%' }}
            >
                <Dialog
                    open={this.state.errorAlert}
                    onClose={handleCloseUsererrorAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Unfortunately your session has expired. This page will be refreshed.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseUsererrorAlert} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <div style={{ width: '120px' }}>
                    <a href="/">
                        <img src={circleonly} height='120px' width='120px' align='center' style={{ padding: '20px' }} alt="Logo" />
                    </a>
                    <div style={{ height: '25px' }}></div>
                    <div
                        className={this.state.active_report ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Rpt}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <DescriptionRoundedIcon
                            style={{ color: this.state.reportIconColor, fontSize: '2.2rem', pointerEvents: 'none' }}
                            align='center'
                        />

                        <div align='center'>
                            <span style={{ color: this.state.reportIconColor, fontSize: '1rem', }}>Reports</span>
                        </div>
                    </div>

                    <div
                        className={this.state.active_config ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Config}
                        align='center'
                        style={{ paddingTop: '25px', paddingBottom: '25px', paddingLeft: '5px', paddingRight: '5px' }}
                    >
                        <SettingsRoundedIcon style={{ color: this.state.configIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.configIconColor, fontSize: '1rem', }}>Configuration</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegodatasets}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <WidgetsRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Datasets</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegodatasetdownload}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <GetAppRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Download Data</span>
                        </div>

                    </div>

                    <div
                        className={this.state.active_resource ? 'sidebar_div sidebar_div_active' : 'sidebar_div'}
                        onClick={handlePopover_Resource}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <InfoRoundedIcon style={{ color: this.state.resourceIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.resourceIconColor, fontSize: '1rem', }}>Resources</span>
                        </div>

                    </div>

                    <div
                        className='sidebar_div'
                        onClick={handlegobackhome}
                        align='center'
                        style={{ padding: '25px' }}
                    >
                        <HomeRoundedIcon style={{ color: this.state.homeIconColor, fontSize: '2.2rem' }} align='center' />
                        <div>
                            <span align='center' style={{ color: this.state.homeIconColor, fontSize: '1rem', }}>Home</span>
                        </div>

                    </div>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        //open={true}
                        open={this.state.open_Rpt || false}
                        onClose={handlePopover_Rpt}
                        marginThreshold={0}
                        transitionDuration={0}
                    >

                        <div style={{ width: '235px', height: '100vh', backgroundColor: "#FFF", verticalAlign: 'middle', textAlign: "center" }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Rpt}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PDTRetailer/createreport' >Create A New Report <AddCircleRoundedIcon /></a>
                            </div>
                            {/*Return Favorites First*/}
                            {favorite_reports.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if (idx < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTRetailer/Report/' + report.report_id} >{report.report_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')} >
                                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTRetailer/Report/' + report.report_id} >{report.report_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'remove')}>
                                                    <StarRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>



                                        )
                                    }
                                }

                                else {
                                    return null
                                }
                            })}
                            {/*Then NonFavorites*/}
                            {nonfavorite_reports.map((report, idx) => {
                                //Perform Logic on Last Udated Date....
                                var current_date = new Date()
                                var current_date_val = current_date.toISOString()
                                var report_updated_date_val = report.time_updated_adj.substring(0, 19)
                                var report_updated_date = new Date(report.time_updated_adj.substring(0, 19))
                                var Difference_In_Time = current_date.getTime() - report_updated_date.getTime();
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                var timetodisplay = ''
                                var timeString = ''
                                var H = 0
                                var h = 0
                                var ampm = ''
                                if (Difference_In_Days < 1 && current_date_val.substring(0, 10) === report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = timeString
                                }
                                else if (Difference_In_Days < 1 && current_date_val.substring(0, 10) !== report_updated_date_val.substring(0, 10)) {
                                    //Just the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else if (Difference_In_Days < 2) {
                                    //Yesterday plus the time in AM/PM
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = 'Yesterday ' + timeString
                                }
                                else {
                                    //The Date with the time
                                    var dateString = report.time_updated_adj.substring(0, 10)
                                    var year = dateString.substring(0, 4)
                                    var month = dateString.substring(5, 7)
                                    var dateNum = dateString.substring(8, 10)
                                    var monthName = Constant.date_dictionary[month]
                                    timeString = report.time_updated_adj.substring(11, 19);
                                    H = +timeString.substr(0, 2);
                                    h = H % 12 || 12;
                                    ampm = (H < 12 || H === 24) ? "AM" : "PM";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    timetodisplay = monthName + ' ' + dateNum + ', ' + year + ' ' + timeString
                                }
                                if ((idx + 1 + favorite_reports.length) < 15) { //Only return the first 15...
                                    if (report.report_name.length > 15) {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className="reportlinksdiv" style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTRetailer/Report/' + report.report_id} >{report.report_name.substring(0, 15) + '...'}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')} >
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className="row" align='left' key={'Report' + report.report_id} >
                                                <div className='reportlinksdiv' style={{ width: '70%' }}>
                                                    <a className='reportlinks' style={{ marginLeft: '10px' }} href={'/PDTRetailer/Report/' + report.report_id} >{report.report_name}
                                                        <br />
                                                        <span style={{ paddingTop: '8px', fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                                                            {timetodisplay}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className='reportlinksfavoritediv' style={{ width: '30%' }} onClick={(event) => handleClickFavorite(event, report.report_id, 'report', 'add')}>
                                                    <StarBorderRoundedIcon style={{ color: '#4e106f' }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                }

                                else {
                                    return null
                                }
                            })}

                            <div className="reportlinksdiv" align='left'>
                                <a className='createlinks' href='/PDTRetailer/Reports' >All Reports</a>
                            </div>
                        </div>

                    </Constant.NPDPopover>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.open_Resource || false}
                        onClose={handlePopover_Resource}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Resource}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDTRetailer/Resources'} target="_blank" rel="noopener noreferrer" >Resources Page </a>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDTRetailer/Glossary'} target="_blank" rel="noopener noreferrer">Glossary </a>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDTRetailer/Credits'} target="_blank" rel="noopener noreferrer" >Credits </a>
                            </div>
                        </div>
                    </Constant.NPDPopover>

                    <Constant.NPDPopover
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: 120 }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.open_Config || false}
                        onClose={handlePopover_Config}
                        marginThreshold={0}
                        style={{ pointerEvents: 'auto' }}
                        transitionDuration={0}
                    >
                        <div style={{ width: '235px', height: '100vh' }}>
                            <div className="reportlinksclosediv" align='left'>
                                <span
                                    onClick={handlePopover_Config}
                                >
                                    Close Menu
                                </span>
                            </div>
                            <div className="reportlinksdiv">
                                <a className='reportlinks' href={'/PDTRetailer/ScreenOrdering'} target="_blank" rel="noopener noreferrer" >Report Output Ordering </a>
                            </div>
                        </div>
                    </Constant.NPDPopover>

                </div>
            </Constant.NPDDrawer>
        )
    }

}
