export default function setg1definition(selectedOptions, position) {
    var g1definition = ""
    var selectedBrands_g1 = this.state.selectedBrands_g1
    var exclude_brands_g1 = this.state.exclude_brands_g1
    var selectedPriceBands_g1 = this.state.selectedPriceBands_g1
    var selectedsegment1_g1 = this.state.selectedsegment1_g1
    var selectedsegment2_g1 = this.state.selectedsegment2_g1
    var selectedsegment3_g1 = this.state.selectedsegment3_g1
    var selectedsegment4_g1 = this.state.selectedsegment4_g1
    var selectedoutlet_g1 = this.state.selectedoutlet_g1
    var selectedAttributes_g1 = this.state.selectedAttributes_g1
    var selectedchannel_g1 = this.state.selectedchannel_g1

    if (position === 'brand') {
        if (selectedOptions) {
            selectedBrands_g1 = selectedOptions
            this.setState({ selectedBrands_g1 })
        }
        else {
            selectedBrands_g1 = []
            this.setState({ selectedBrands_g1 })
        }

        console.log(selectedBrands_g1)
    }    

    else if (position === 'priceband') {
        if (selectedOptions) {
            selectedPriceBands_g1 = selectedOptions
            this.setState({ selectedPriceBands_g1 })
        }
        else {
            selectedPriceBands_g1 = []
            this.setState({ selectedPriceBands_g1 })
        }
    }

    else if (position === 'segment1') {
        if (selectedOptions) {
            selectedsegment1_g1 = selectedOptions
            this.setState({ selectedsegment1_g1 })
        }
        else {
            selectedsegment1_g1 = []
            this.setState({ selectedsegment1_g1 })
        }
    }

    else if (position === 'segment2') {
        if (selectedOptions) {
            selectedsegment2_g1 = selectedOptions
            this.setState({ selectedsegment2_g1 })
        }
        else {
            selectedsegment2_g1 = []
            this.setState({ selectedsegment2_g1 })
        }
    }

    else if (position === 'segment3') {
        if (selectedOptions) {
            selectedsegment3_g1 = selectedOptions
            this.setState({ selectedsegment3_g1 })
        }
        else {
            selectedsegment3_g1 = []
            this.setState({ selectedsegment3_g1 })
        }
    }

    else if (position === 'segment4') {
        if (selectedOptions) {
            selectedsegment4_g1 = selectedOptions
            this.setState({ selectedsegment4_g1 })
        }
        else {
            selectedsegment4_g1 = []
            this.setState({ selectedsegment4_g1 })
        }
    }

    else if (position === 'outlet') {
        if (selectedOptions) {
            selectedoutlet_g1 = selectedOptions
            this.setState({ selectedoutlet_g1 })
        }
        else {
            selectedoutlet_g1 = []
            this.setState({ selectedoutlet_g1 })
        }
    }

    else if (position === 'channel') {
        if (selectedOptions) {
            selectedchannel_g1 = selectedOptions
            this.setState({ selectedchannel_g1 })
        }
        else {
            selectedchannel_g1 = []
            this.setState({ selectedchannel_g1 })
        }
    }

    else {

        if (selectedOptions) {
            selectedAttributes_g1[position] = selectedOptions
            this.setState({ selectedAttributes_g1 })
        }
        else {
            selectedAttributes_g1[position] = []
            this.setState({ selectedAttributes_g1 })
        }
    }

    //Set Definition
    var brandstring = ""
    var attrstring = ""
    var pricebandstring = ""
    var seg1string = ""
    var seg2string = ""
    var seg3string = ""
    var seg4string = ""
    var outletstring = ""
    var channelstring = ""

    if (selectedBrands_g1.length) {
        if (exclude_brands_g1) {
            brandstring = '<span> Excluding Brands: ' + selectedBrands_g1.map(brand => brand.label).join(', ') + '<br /></span>'
        }
        else {
            brandstring = '<span> Brands: ' + selectedBrands_g1.map(brand => brand.label).join(', ') + '<br /></span>'
        }
    }

    console.log(brandstring)

    Object.keys(this.state.selectedAttributes_g1).map(attribute => {
        if (selectedAttributes_g1[attribute].length === 0) {
            attrstring += ""
        }
        else {
            attrstring += '<span>' + this.state.attributenamelabels[attribute] + ' : ' + selectedAttributes_g1[attribute].map(attr => attr.label).join(', ') + ' <br /></span>'
        }
    })

    if (selectedPriceBands_g1.length > 0) {
        pricebandstring = '<span> Price Bands: ' + selectedPriceBands_g1.map(band => band.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment1_g1.length > 0) {
        seg1string = '<span> ' + this.state.segment1label + ': ' + selectedsegment1_g1.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment2_g1.length > 0) {
        seg2string = '<span> ' + this.state.segment2label + ': ' + selectedsegment2_g1.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment3_g1.length > 0) {
        seg3string = '<span> ' + this.state.segment3label + ': ' + selectedsegment3_g1.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedsegment4_g1.length > 0) {
        seg4string = '<span> ' + this.state.segment4label + ': ' + selectedsegment4_g1.map(segment => segment.label).join(', ') + '<br /></span>'
    }

    if (selectedoutlet_g1.length > 0) {
        outletstring = '<span> Outlets: ' + selectedoutlet_g1.map(outlet => outlet.label).join(', ') + '<br /></span>'
    }

    if (selectedchannel_g1.length > 0) {
        channelstring = '<span> Channels: ' + selectedchannel_g1.map(channel => channel.label).join(', ') + '<br /></span>'
    }

    g1definition = brandstring + attrstring + pricebandstring + seg1string + seg2string + seg3string + seg4string + outletstring + channelstring
    console.log(g1definition)
    this.setState({ g1definition })
}