import removeItemOnce from '../Utilities/RemoveItemOnce'
import replaceEmptyArrays from '../Utilities/RemoveItemOnce'
import CreateWeeksNew from '../Utilities/CreateWeeks'
import * as Constant from '../../../constants'
import Datetime from 'react-datetime'

export default async function getReportSettings(reportid) {
    let report_settings_url = Constant.baseapiurl + "ReportSettings/" + reportid // + this.state.extraparam
    let my_results = await this.FetchConfigWithDefault([
        { 'url': report_settings_url, 'default': { time_updated: new Date(), report_brands: [], start_date: (new Date()).toISOString(), end_date: (new Date()).toISOString() } },
    ], 'GetReportSettings.js getReportSettings()', 'Report')

    if (my_results['ErrorCount'] > 0) {
        console.log('Bailing out of report setting fetch #1 due to errors')
        return false
    }

    let reportsettings_data = my_results['ReturnList'][0]
    let report_dataset_id = reportsettings_data.dataset_id
    let report_store_group = reportsettings_data.store_group

    // adjust the modified date based on timezone
    let dt = new Date()
    let offset = dt.getTimezoneOffset() * 60 * 1000
    reportsettings_data.time_updated_adj = (new Date(Date.parse(reportsettings_data.time_updated) - offset)).toISOString()

    let base_dataset_url = Constant.baseapiurl + "Datasets/" + report_dataset_id
    my_results = await this.FetchConfigWithDefault([
        { 'url': base_dataset_url + this.state.extraparam, 'default': {} },
        { 'url': base_dataset_url + '/Parameters?store_group=' + report_store_group + this.state.extraparam_multi, 'default': {} },
    ], 'GetReportSettings.js getReportSettings()', 'dataset information')
    let dataset_data = my_results['ReturnList'][0]
    let params_data = my_results['ReturnList'][1]

    if (my_results['ErrorCount'] > 0) {
        console.log('Bailing out of report setting fetch #2 (dataset information) due to errors')
        return false
    }

    // -------------------- Process Dataset and Dataset Parameters config data --------------------

    let report_industry_name = dataset_data.industry_name
    let report_industry_id = dataset_data.industry_id

    let levels = params_data.levels || {}
    // From the levels, create the options
    let numlevels = levels.complete_at_level || 2
    if (levels.segment3_human) {
        numlevels = 3
    }
    if (levels.segment4_human) {
        numlevels = 4
    }

    let segment_1_lookup = { 'All': 'All' }
    let segment_2_lookup = { 'All': 'All' }
    let segment_3_lookup = { 'All': 'All' }
    let segment_4_lookup = { 'All': 'All' }
    let segment_1_options_filters = []
    let segment_2_options_filters = []
    let segment_3_options_filters = []
    let segment_4_options_filters = []

    let segment1items = levels.items || []
    //console.log(segment1items)
    let segment2items = []
    segment1items.forEach(level1 => {
        level1.items.forEach(nextlevel => {
            segment2items.push(nextlevel)
        })
    })

    //console.log(segment2items)
    let segment3items = []
    let segment4items = []

    if (numlevels === 3) {
        segment2items.forEach(level2 => {
            level2.items.forEach(nextlevel => {
                segment3items.push(nextlevel)
            })
        })
    } else if (numlevels === 4) {
        segment2items.forEach(level2 => {
            level2.items.forEach(nextlevel => {
                segment3items.push(nextlevel)
            })
        })

        segment3items.forEach(level3 => {
            level3.items.forEach(nextlevel => {
                segment4items.push(nextlevel)
            })
        })
    }

    segment1items.forEach(level => segment_1_lookup[level.sel] = level.sel_name)
    segment2items.forEach(level => segment_2_lookup[level.sel] = level.sel_name)
    segment3items.forEach(level => segment_3_lookup[level.sel] = level.sel_name)
    segment4items.forEach(level => segment_4_lookup[level.sel] = level.sel_name)

    segment_1_options_filters = segment1items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })
    segment_2_options_filters = segment2items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })
    segment_3_options_filters = segment3items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })
    segment_4_options_filters = segment4items.map(level => { return { 'label': level.sel_name, 'value': level.sel } })

    let channels = params_data.channels || []
    let channel_options_filters = channels.map(channel => { return { 'label': channel.geogdesc, 'value': channel.geogid } })
    let channel_lookup = { 'All': 'All' }
    channels.forEach(channel => channel_lookup[channel.geogid] = channel.geogdesc)

    let items = params_data.items || []
    let item_options_filters = items.map(item => { return { 'label': item.proddesc, 'value': item.prodid } })
    let item_lookup = { 'All': 'All' }
    let item_lookup_reverse = { 'All': 'All' }
    items.forEach(item => item_lookup[item.prodid] = item.proddesc)
    items.forEach(item => item_lookup_reverse[item.proddesc] = item.prodid)

    this.setState({
        segment_1_options_filters,
        segment_2_options_filters,
        segment_3_options_filters,
        segment_4_options_filters,
        segment_1_lookup,
        segment_2_lookup,
        segment_3_lookup,
        segment_4_lookup,
        channel_options_filters,
        channel_lookup,
        item_options_filters,
        item_lookup,
        item_lookup_reverse
    })

    let categories = levels.items || []
    let subcategories = categories.map(level => (level.items || []).map(sublevel => sublevel.sel_name))

    var reportcategories = categories.map(level => level.sel_name)
    var reportcategories_hr = categories.map(level => {
        let segment = level.sel_name
        let segmentlabel = segment.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
        if (segmentlabel.substring(0, 12) === 'Makeup Total' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(13)
        } else if (segmentlabel.substring(0, 6) === 'Makeup' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(7)
        } else if (segmentlabel.substring(0, 9) === 'Fragrance' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(10)
        } else if (segmentlabel.substring(0, 4) === 'Hair' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(5)
        } else if (segmentlabel.substring(0, 14) === 'Skincare Total' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(15)
        } else if (segmentlabel.substring(0, 8) === 'Skincare') {
            segmentlabel = segmentlabel.slice(9)
        }

        return segmentlabel
    })
    var reportcategoriesfull = {}
    var reportsubcategories = {}
    var reportsubcategories_hr = {}

    let fulllevel = levels.complete_at_level
    let full_cat = false
    if (fulllevel === 1) {
        full_cat = true
    }
    categories.forEach(level => reportcategoriesfull[level['sel_name']] = full_cat)
    categories.forEach(level => reportsubcategories[level['sel_name']] = (level['items'] || []).map(subcat => subcat['sel_name']))
    categories.forEach(level => reportsubcategories_hr[level['sel_name']] = (level['items'] || []).map(subcat => {
        let segment = subcat['sel_name'] || ''
        let segmentlabel = segment.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
        if (segmentlabel.substring(0, 12) === 'Makeup Total' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(13)
        } else if (segmentlabel.substring(0, 6) === 'Makeup' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(7)
        } else if (segmentlabel.substring(0, 9) === 'Fragrance' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(10)
        } else if (segmentlabel.substring(0, 4) === 'Hair' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(5)
        } else if (segmentlabel.substring(0, 14) === 'Skincare Total' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(15)
        } else if (segmentlabel.substring(0, 8) === 'Skincare' && report_industry_name === 'U.S. Beauty') {
            segmentlabel = segmentlabel.slice(9)
        }

        return segmentlabel
    }))

    // Create a list if the category is full...
    var fullstringscats = {}
    reportcategories.forEach((cat, idx) => {
        let cat_hr = reportcategories_hr[idx]
        var subcatlist
        if (reportcategoriesfull[cat]) {
            subcatlist = ''
        } else {
            if (reportsubcategories_hr[cat].length < 5) {
                subcatlist = ""
            } else {
                subcatlist = ""
            }
        }

        fullstringscats[cat] = cat_hr + subcatlist
    })

    this.setState({
        reportcategories,
        reportcategoriesfull,
        reportsubcategories,
        reportcategories_hr,
        reportsubcategories_hr,
        fullstringscats
    })

    // -------------------- Process geo config data --------------------

    var storegeodesc = ""
    params_data.store_groups.forEach(sg => {
        if (sg.storegpid === report_store_group) {
            storegeodesc = sg.storegpdesc
        }
    })
    var distinctgeos = []
    params_data.store_groups.forEach(storegroup => {
        if (storegroup.storegpid === report_store_group) {
            distinctgeos.push(storegroup.storegpdesc)
        }
    })

    // -------------------- Process brand config data --------------------

    var brand_options_filters = params_data.brands.map(brand => { return { 'label': brand.brand_name, 'value': brand.brand } })
    var brand_lookup_filters = { 'All': 'All' }
    var brand_lookup_filters_reverse = { 'All': 'All' }
    params_data.brands.forEach(brand => {
        brand_lookup_filters[brand.brand] = brand.brand_name
        brand_lookup_filters_reverse[brand.brand_name] = brand.brand
    })

    var reportbrands_init = params_data.brands.map(brand => brand.brand_name)
    var initialbrands_init = reportsettings_data.report_brands

    // clean up both
    var reportbrands = reportbrands_init.map(brand => brand.replaceAll('"', '').trim())
    var initialbrands = initialbrands_init.map(brand => brand.replaceAll('"', '').trim())

    var brandsforfilter = []
    var brandsstring = ''
    var brandsstringforcaption = ''
    if (initialbrands.length === reportbrands.length) {
        brandsforfilter = ["All"]
        brandsstring = 'All Brands'
        brandsstringforcaption = reportbrands.join(', ')
    } else {
        brandsforfilter = initialbrands.map(brand => brand_lookup_filters_reverse[brand])

        // If there are brands in the report configuration that are no longer in the dataset, clean them out
        let len_before = brandsforfilter.length
        brandsforfilter = brandsforfilter.filter(Number)
        let removed = brandsforfilter.length - len_before
        if (removed > 0) {
            console.log(removed + ' "null" brands were removed from the report brands list')
        }

        brandsstring = initialbrands.join(', ')
        if (brandsstring.length > 100) {
            brandsstring = initialbrands.length + ' of ' + reportbrands.length + ' Brands'
            brandsstringforcaption = initialbrands.map(brand => brand_lookup_filters[brand]).join(', ')
        }
    }
    console.log('brandsforfilter')
    console.log(brandsforfilter)

    // -------------------- Process item config data --------------------

    // Check if there are no items in the dataset
    if (params_data.items.length > 0 && params_data.items[0].prodid === null) {
        params_data.items = []
    }

    console.log('items_data')
    console.log(params_data.items)

    params_data.items.forEach(item => {
        if (item.proddesc === null) {
            item.proddesc = 'All Other'
        }
    })

    var item_lookup_filters = { 'All': 'All' }
    var item_lookup_filters_reverse = { 'All': 'All' }
    params_data.items.forEach(item => {
        item_lookup_filters[item.prodid] = item.proddesc
        item_lookup_filters_reverse[item.proddesc] = item.prodid
    })

    var reportitems_init = params_data.items.map(item => item.proddesc)
    var initialitems_init = reportsettings_data.report_items

  

    // clean up both
    var reportitems = reportitems_init.map(item => item.replaceAll('"', '').trim())

    var initialitems = null
    if (initialitems_init !== null && initialitems_init !== undefined) {
        initialitems = initialitems_init.map(item => item.replaceAll('"', '').trim())
    } else {
        initialitems = initialitems_init.map(item => item.replaceAll('"', '').trim())
    }

    var itemsforfilter = []
    var itemsstring = ''
    var itemsstringforcaption = ''
    if (initialitems.length === reportitems.length) {
        itemsforfilter = ["All"]
        itemsstring = 'All Items'
        itemsstringforcaption = reportitems.join(', ')
    } else {
        itemsforfilter = initialitems.map(item => item_lookup_filters_reverse[item])

        // If there are items in the report configuration that are no longer in the dataset, clean them out
        let len_before = itemsforfilter.length
        itemsforfilter = itemsforfilter.filter(Number)
        let removed = itemsforfilter.length - len_before
        if (removed > 0) {
            console.log(removed + ' "null" items were removed from the report items list')
        }

        itemsstring = initialitems.join(', ')
        if (itemsstring.length > 100) {
            itemsstring = initialitems.length + ' of ' + reportitems.length + ' Items'
            itemsstringforcaption = initialitems.map(item => item_lookup_filters[item]).join(', ')
        }
    }
    console.log('itemsforfilter')
    console.log(itemsforfilter)

    // -------------------- Process Attribute and Global Attribute config data --------------------

    var attributes = {}
    let attribute_col_names = params_data.attributes.map(attribute => attribute.attribute).concat(params_data.global_attr_values.map(attribute => attribute.global_attribute))

    console.log('setting attributes....')
    params_data.attributes.forEach(attribute => {
        attributes[attribute.attribute] = (attribute.attribute_values || []).map(attrval => (attrval || ""))
    })

    params_data.global_attr_values.forEach(attribute => {
        attributes[attribute.global_attribute] = (attribute.global_attribute_values || []).map(attrval => (attrval || ""))
    })

    // -------------------- Process Discount Bucket config data --------------------

    // Get Min/Max of Discount Ranges...
    let minmax_discranges = params_data.discount_buckets.map(bucket => { return [bucket.discount_range_min, bucket.discount_range_max] })
    let discountbucketlabels = params_data.discount_buckets.map(bucket => { return bucket.discount_range_name })

    // -------------------- Process Dataset Times config data --------------------

    let times_data = params_data.times
    var datasetstart = (times_data[0] || '2000-01-01').substring(0, 10)
    var datasetend = (times_data[1] || '2000-12-31').substring(0, 10)

    // -------------------- Process Dataset Outlets config data --------------------

    let outlets_data = params_data.outlets
    if (outlets_data.length > 1 && outlets_data.includes("ROM")) {
        outlets_data = outlets_data.filter(element => element !== "ROM")
        outlets_data.sort()
        outlets_data.push("ROM")
    }

    // -------------------- DONE LOADING config data --------------------

    // Set Currency from dataset_data...
    var currencycode = ''
    var currencyType = ''
    var currencyTypeSingular = ''
    var currencysymbol = ''
    if (dataset_data.currency === 'GBP') {
        currencycode = 'GBP'
        currencyType = 'Pounds'
        currencyTypeSingular = 'Pound'
        currencysymbol = '£'
        //currencysymbol = '&#163;'
    } else if (dataset_data.currency === 'CAD') {
        currencycode = 'CAD'
        currencyType = 'Dollars'
        currencyTypeSingular = 'Dollar'
        currencysymbol = '$'
    } else if (dataset_data.currency === 'EUR') {
        currencycode = 'EUR'
        currencyType = 'Euros'
        currencyTypeSingular = 'Euro'
        currencysymbol = '€'
        //currencysymbol = '&#8364;'
    } else if (dataset_data.currency === 'MXN') {
        currencycode = 'MXN'
        currencyType = 'Pesos'
        currencyTypeSingular = 'Peso'
        currencysymbol = '$'
    } else {
        // If Not Explicitly set, use dollars
        currencycode = 'USD'
        currencyType = 'Dollars'
        currencyTypeSingular = 'Dollar'
        currencysymbol = '$'
    }

    // Use Datasetid and Environment to set the flag as to whether or not we can view the item views....
    // We will Default to false, and open up the views only in Development or If the dataset is Pre-School Toys
    // Also possibly add in the client Name as Melissa and Doug/The NPD Group...

    var showitem_views = false
    if (this.state.environment === 'Development') {
        showitem_views = true
    } else if (this.state.environment === 'QA' && this.state.usercompanyname === 'The NPD Group, Inc.') {
        showitem_views = true
    } else if (this.state.environment === 'Production' && report_dataset_id === 63) {
        showitem_views = true
    } else if (this.state.environment === 'Production' && this.state.usercompanyname === 'Melissa and Doug') {
        showitem_views = true
    } else if (this.state.environment === 'Production' && this.state.usercompanyname === 'The NPD Group, Inc.') {
        showitem_views = true
    } else {
        showitem_views = false
    }

    // if one of the datasets for Retailer, do not show item views...
    var showitem_filters = true
    if (Constant.PDT_RETAILER_DATASETS.includes(report_dataset_id)) {
        showitem_views = false
        showitem_filters = false
    }

    let segment1hr = dataset_data.selection1_human
    let segment2hr = dataset_data.selection2_human
    let segment3hr = dataset_data.selection3_human
    let segment4hr = dataset_data.selection4_human

    let segment1_nonhr = dataset_data.segment1
    let segment2_nonhr = dataset_data.segment2
    let segment3_nonhr = dataset_data.segment3
    let segment4_nonhr = dataset_data.segment4

    let numsegment_levels = 2

    var segment1label = ""
    var segment2label = ""

    var segment3label = ""
    var segment4label = ""

    if (segment1hr) {
        segment1label = segment1hr.charAt(0).toUpperCase() + segment1hr.slice(1)
    } else if (segment1_nonhr) {
        segment1label = segment1_nonhr.charAt(0).toUpperCase() + segment1_nonhr.slice(1)
    }

    if (segment2hr) {
        segment2label = segment2hr.charAt(0).toUpperCase() + segment2hr.slice(1)
    } else if (segment2_nonhr) {
        segment2label = segment2_nonhr.charAt(0).toUpperCase() + segment2_nonhr.slice(1)
    }

    if (segment3hr) {
        segment3label = segment3hr.charAt(0).toUpperCase() + segment3hr.slice(1)
    } else if (segment3_nonhr) {
        segment3label = segment3_nonhr.charAt(0).toUpperCase() + segment3_nonhr.slice(1)
    }

    if (segment4hr) {
        segment4label = segment4hr.charAt(0).toUpperCase() + segment4hr.slice(1)
    } else if (segment4_nonhr) {
        segment4label = segment4_nonhr.charAt(0).toUpperCase() + segment4_nonhr.slice(1)
    }

    let levelOptions = this.state.levelOptions
    let levelOptions_BaseInc = this.state.levelOptions_BaseInc
    levelOptions.push({ 'label': segment1label, 'value': 'Segment 1' })
    levelOptions.push({ 'label': segment2label, 'value': 'Segment 2' })

    if (segment3_nonhr) {
        levelOptions.push({ 'label': segment3label, 'value': 'Segment 3' })
        levelOptions_BaseInc.push({ 'label': segment3label, 'value': 'Segment 3' })
    }

    if (segment4_nonhr) {
        levelOptions.push({ 'label': segment4label, 'value': 'Segment 4' })
        levelOptions_BaseInc.push({ 'label': segment4label, 'value': 'Segment 4' })
    }

    levelOptions_BaseInc.push({ 'label': segment1label, 'value': 'Segment 1' })
    levelOptions_BaseInc.push({ 'label': segment2label, 'value': 'Segment 2' })

    this.setState({
        dataset_data,
        datasetname: dataset_data.description,
        datasettimeagg: dataset_data.time_aggregation,
        datasetupdated: dataset_data.time_updated,
        segment1label: segment1label,
        segment2label: segment2label,
        segment3label: segment3label,
        segment4label: segment4label,
        levelOptions,
        levelOptions_BaseInc,
        currencycode,
        currencyType,
        currencyTypeSingular,
        currencysymbol,
        minmax_discranges,
        discountbucketlabels,
        showitem_views,
        showitem_filters,
        report_industry_name,
        report_industry_id
    })
    var attributenamelabels = {}
    dataset_data.attributes.forEach((attr, idx) => {
        attributenamelabels[attr] = dataset_data.attributes_human[idx]
    })

    // Add Global Attributes
    dataset_data.global_attributes.forEach((attr, idx) => {
        attributenamelabels[attr] = dataset_data.global_attributes_human[idx]
    })

    // Get the list of all settings this user has on this report
    var savedFilter, savedOrder, user_report_settings_data
    let user_report_settings_response = await fetch(Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid)
    if (user_report_settings_response.status === 200) {
        user_report_settings_data = await user_report_settings_response.json()
    } else {
        user_report_settings_data = []
    }
    var usersettings_list_reports = user_report_settings_data.map(setting => setting.setting_name)

    // Check if pdtReportFilters is in the list of settings....
    if (usersettings_list_reports.includes('pdtReportFilters')) {
        let filtersettings_response = await fetch(Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid + '/pdtReportFilters')
        if (filtersettings_response.status === 200) {
            let savedFilter_data = await filtersettings_response.json()
            savedFilter = savedFilter_data.setting_value
        }
    }

    console.log('savedFilter')
    console.log(savedFilter)

    // Check if pdtReportOrder is in list of settings
    if (usersettings_list_reports.includes('pdtReportOrder')) {
        let ordersettings_response = await fetch(Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid + '/pdtReportOrder')
        if (ordersettings_response.status === 200) {
            let savedOrder_data = await ordersettings_response.json()
            savedOrder = savedOrder_data.setting_value
        }
    }

    console.log('savedOrder')
    console.log(savedOrder)

    var filter_object, exclude_array

    // Set initial values from filters if applicable...
    var attribute_filters, selectedstart, selectedend, startdate_raw
    if (savedFilter && Object.keys(savedFilter['filter_object']).includes('channels')) {
        attribute_filters = savedFilter.filter_object.attributes
        selectedstart = savedFilter.filter_object.startdate
        startdate_raw = savedFilter.filter_object.startdate
        selectedend = savedFilter.filter_object.enddate

        // If there are any nulls in the saved filter items list or brands list, remove them
        let len_before = savedFilter.filter_object.items.length
        savedFilter.filter_object.items = savedFilter.filter_object.items.filter(Number)
        let removed = savedFilter.filter_object.items.length - len_before
        if (removed > 0) {
            console.log(removed + ' "null" items were removed from the report items filter')
        }

        len_before = savedFilter.filter_object.brands.length
        savedFilter.filter_object.brands = savedFilter.filter_object.brands.filter(Number)
        removed = savedFilter.filter_object.brands.length - len_before
        if (removed > 0) {
            console.log(removed + ' "null" brands were removed from the report brands filter')
        }
    } else {
        attribute_filters = {}
        Object.keys(attributes).forEach(attr => attribute_filters[attr] = ['All'])
        selectedstart = reportsettings_data.start_date.substring(0, 10)
        startdate_raw = reportsettings_data.start_date.substring(0, 10)
        selectedend = reportsettings_data.end_date.substring(0, 10)
    }

    //console.log(attribute_filters)

    var startdate_year = parseInt(selectedstart.substring(0, 4))
    var startdate_month = parseInt(selectedstart.substring(5, 7)) - 1 // Zero Based Month
    var startdate_day = parseInt(selectedstart.substring(8, 10))

    var enddate_year = parseInt(selectedend.substring(0, 4))
    var enddate_month = parseInt(selectedend.substring(5, 7)) - 1 // Zero Based Month
    var enddate_day = parseInt(selectedend.substring(8, 10))

    var startdate_moment = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
    var enddate_moment = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

    // Change this so we always add six days back (on the report entry we go from day after end date)
    if (dataset_data.time_aggregation !== 'Monthly' && enddate_moment.day() === startdate_moment.day()) {
        startdate_raw = startdate_moment.subtract(6, 'd').format('YYYY-MM-DD')
    }

    var datasetstartdate_year = parseInt(datasetstart.substring(0, 4))
    var datasetstartdate_month = parseInt(datasetstart.substring(5, 7)) - 1 // Zero Based Month
    var datasetstartdate_day = parseInt(datasetstart.substring(8, 10))

    var datasetenddate_year = parseInt(datasetend.substring(0, 4))
    var datasetenddate_month = parseInt(datasetend.substring(5, 7)) - 1 // Zero Based Month
    var datasetenddate_day = parseInt(datasetend.substring(8, 10))

    var pastdate = Datetime.moment().set({ 'year': datasetstartdate_year, 'month': datasetstartdate_month, 'date': datasetstartdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
    var futuredate = Datetime.moment().set({ 'year': datasetenddate_year, 'month': datasetenddate_month, 'date': datasetenddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

    // Make Past date the beginning of the week
    pastdate = pastdate.subtract(6, 'd')

    var startdate_year = parseInt(selectedstart.substring(0, 4))
    var startdate_month = parseInt(selectedstart.substring(5, 7)) - 1 // Zero Based Month
    var startdate_day = parseInt(selectedstart.substring(8, 10))

    var enddate_year = parseInt(selectedend.substring(0, 4))
    var enddate_month = parseInt(selectedend.substring(5, 7)) - 1 // Zero Based Month
    var enddate_day = parseInt(selectedend.substring(8, 10))

    var startdate_moment = Datetime.moment().set({ 'year': startdate_year, 'month': startdate_month, 'date': startdate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
    var enddate_moment = Datetime.moment().set({ 'year': enddate_year, 'month': enddate_month, 'date': enddate_day, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });

    // Change this so we always add six days back (on the report entry we go from day after end date)
    if (dataset_data.time_aggregation !== 'Monthly' && enddate_moment.day() !== startdate_moment.day()) {
        selectedstart = startdate_moment.subtract(-6, 'd').format('YYYY-MM-DD')
    }

    // Set EndDate Day to state
    this.setState({ enddate_dow: enddate_moment.day() })

    var enddate_year = parseInt(selectedend.substring(0, 4))
    var enddate_month = parseInt(selectedend.substring(5, 7)) - 1 // Zero Based Month
    var enddate_day = parseInt(selectedend.substring(8, 10))

    if (dataset_data.time_aggregation === 'Monthly') {
        var alldates = [selectedstart]
        let monthnum = selectedstart.substring(5, 7)
        let yearnum = selectedstart.substring(0, 4)
        var newmonthnum = ''
        var newyearnum = ''
        if (monthnum === '12') {
            newmonthnum = '01'
            newyearnum = String(parseInt(yearnum) + 1)
        } else {
            newmonthnum = String(parseInt(monthnum) + 1)
            if (newmonthnum.length < 2) {
                newmonthnum = '0' + newmonthnum
            }
            newyearnum = yearnum
        }

        var newdate = newyearnum + '-' + newmonthnum + '-01'

        while (newdate <= selectedend) {
            alldates.push(newdate)
            let monthnum = newdate.substring(5, 7)
            let yearnum = newdate.substring(0, 4)
            var newmonthnum = ''
            var newyearnum = ''
            if (monthnum === '12') {
                newmonthnum = '01'
                newyearnum = String(parseInt(yearnum) + 1)
            } else {
                newmonthnum = String(parseInt(monthnum) + 1)
                if (newmonthnum.length < 2) {
                    newmonthnum = '0' + newmonthnum
                }
                newyearnum = yearnum
            }

            newdate = newyearnum + '-' + newmonthnum + '-01'
        }
    } else {
        var alldates = [selectedstart]
        let dateconv = new Date(selectedstart + 'T00:00:00Z')
        let newdate = new Date(dateconv.setTime(dateconv.getTime() + (7 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)

        while (newdate <= selectedend) {
            alldates.push(newdate)
            dateconv = new Date(newdate + 'T00:00:00Z')
            newdate = new Date(dateconv.setTime(dateconv.getTime() + (7 * 24 * 60 * 60 * 1000))).toISOString().substring(0, 10)
        }
    }

    var numweeks = alldates.length
    /* TO DO -- MAKE THIS MORE GENERIC TO INCLUDE ALL VALID FILTER KEYS */

    if (savedFilter && Object.keys(savedFilter['filter_object']).includes('channels') && Object.keys(savedFilter['filter_object']).includes('segment3') && Object.keys(savedFilter['filter_object']).includes('segment4')) {
        filter_object = savedFilter.filter_object
        exclude_array = savedFilter.exclude_array

        // Adjust the filter_object to reference the attribute_filters
        filter_object['attributes'] = attribute_filters
        filter_object['asp_range'] = savedFilter['asp_range'] || savedFilter['asprange'] || []
    } else {
        filter_object = {
            'brands': brandsforfilter,
            'items': itemsforfilter,
            'startdate': selectedstart,
            'enddate': selectedend,
            'geo': distinctgeos[0],
            'attributes': attribute_filters,
            'asp_range': [],
            'pricebands': [],
            'numweeks': 1,
            'segment1': ['All'],
            'segment2': ['All'],
            'segment3': ['All'],
            'segment4': ['All'],
            'outlets': ['All'],
            'channels': ['All']
        }
        exclude_array = [false, false]
    }

    // Get Price Bands before we check the filter object
    await this.definePriceBands(reportid);

    // Check filter_object
    let filters_raw = filter_object
    let filters = {}
    let weeks_info = CreateWeeksNew(filter_object, this.state.times_data, this.state.datasettimeagg)
    // Rework Attributes
    let apifilterattrs = Object.keys(filters_raw['attributes']).map(attr => {
        return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All')]
    })

    filters['brands'] = removeItemOnce(filters_raw['brands'], 'All')
    filters['exclude_brands'] = exclude_array[0]
    filters['items'] = removeItemOnce(filters_raw['items'], 'All')
    filters['exclude_items'] = exclude_array[1]
    filters['week_aggregation'] = filters_raw['numweeks']
    filters['asp_range'] = filters_raw['asp_range'] || []
    filters['date_range'] = [weeks_info.startdate, weeks_info.enddate]
    filters['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
    filters['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
    filters['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
    filters['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
    filters['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
    filters['attributes'] = apifilterattrs
    filters['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
    filters['price_bands_defs'] = filters_raw['price_bands_defs'] || this.state.priceBandOptions
    filters['channels'] = removeItemOnce(filters_raw['channels'], 'All')

    console.log('filters[brands]')
    console.log(filters['brands'])
    console.log('filters[items]')
    console.log(filters['items'])

    let url_checkfilter = Constant.baseapiurl + "ReportData/Filtered/CheckFilter/" + reportid + this.state.extraparam
    let checkfilter_response = await fetch(url_checkfilter,
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filters)
        })

    if (checkfilter_response.status === 200) {
        var checkfilter_data = await checkfilter_response.json()
        console.log('Filter check is successful')
        console.log(checkfilter_data)
    } else {
        console.log('Opening "Filters Reset" notification because CheckFilter returned HTTP ' + checkfilter_response.status)
        try {
            var checkfilter_error_data = await checkfilter_response.json()
            console.log('The filter error details are:')
            console.log(checkfilter_error_data)
        } catch (err) {
            console.log('Failed to get check-filter error: ' + err)
        }

        this.setState({ changeInFilters_alert: true })

        // Make an empty default Filter
        let empty_attribute_filters = {}
        Object.keys(attributes).forEach(attr => empty_attribute_filters[attr] = ['All'])
        filter_object = {
            'brands': brandsforfilter,
            'items': itemsforfilter,
            'startdate': selectedstart,
            'enddate': selectedend,
            'geo': distinctgeos[0],
            'attributes': empty_attribute_filters,
            'asp_range': [],
            'pricebands': [],
            'numweeks': 1,
            'segment1': ['All'],
            'segment2': ['All'],
            'segment3': ['All'],
            'segment4': ['All'],
            'outlets': ['All'],
            'channels': ['All']
        }

        // Save the new empty Filter
        let url = Constant.baseapiurl + "ReportSettings/Settings/User/" + reportid + '/pdtReportFilters'
        let filter_to_save = { filter_object, exclude_array }
        console.log('New empty filter to save:')
        console.log(filter_to_save);
        let settings_response = await fetch(url,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filter_to_save)
            })

        var settings_obj
        if (settings_response.status === 200) {
            settings_obj = await settings_response.json()
        } else {
            settings_obj = {}
            alert('Encountered HTTP Error ' + settings_response.status + ' saving the filters')
        }
    }

    let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
        return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
    })

    filters['brands'] = replaceEmptyArrays(filters_raw['brands'])
    filters['exclude_brands'] = exclude_array[0]
    filters['items'] = replaceEmptyArrays(filters_raw['items'])
    filters['exclude_items'] = exclude_array[1]
    filters['week_aggregation'] = filters_raw['numweeks']
    filters['asp_range'] = filters_raw['asp_range']
    filters['date_range'] = [weeks_info.startdate, weeks_info.enddate]
    filters['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
    filters['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
    filters['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
    filters['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
    filters['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
    filters['attributes'] = apifilterattrs_replaced
    filters['price_bands_defs'] = filters_raw['price_bands_defs'] || this.state.priceBandOptions
    filters['channels'] = replaceEmptyArrays(filters_raw['channels'])

    let exclude_brands = exclude_array[0]
    let exclude_items = exclude_array[1]

    if (filter_object['brands'][0] !== 'All') {
        let initialbrands = filter_object['brands']
        brandsstring = initialbrands.map(brand => brand_lookup_filters[brand]).join(', ')
        if (brandsstring.length > 100) {
            brandsstring = initialbrands.length + ' of ' + reportbrands.length + ' Brands'
            brandsstringforcaption = initialbrands.map(brand => brand_lookup_filters[brand]).join(', ')
        }
    }

    if (exclude_array[0]) {
        brandsstring = 'All Brands Excluding ' + filter_object['brands'].map(brand => brand_lookup_filters[brand]).join(', ')
        brandsstringforcaption = 'All Brands Excluding ' + filter_object['brands'].map(brand => brand_lookup_filters[brand]).join(', ')
    }

    this.setState({ brandsstring, brandsstringforcaption, initialbrands })

    if (filter_object['items'][0] !== 'All') {
        let initialitems = filter_object['items']
        itemsstring = initialitems.map(item => item_lookup_filters[item]).join(', ')
        if (itemsstring.length > 100) {
            itemsstring = initialitems.length + ' of ' + reportitems.length + ' Items'
            itemsstringforcaption = initialitems.map(item => item_lookup_filters[item]).join(', ')
        }
    }

    if (exclude_array[0]) {
        itemsstring = 'All Items Excluding ' + filter_object['items'].map(item => item_lookup_filters[item]).join(', ')
        itemsstringforcaption = 'All Items Excluding ' + filter_object['items'].map(item => item_lookup_filters[item]).join(', ')
    }

    this.setState({ itemsstring, itemsstringforcaption, initialitems })

    var showTotalCategoryLabel = false
    if (filter_object['brands'][0] === 'All' && filter_object['items'][0] === 'All' && filter_object['segment1'][0] === 'All' && filter_object['segment2'][0] === 'All' && filter_object['segment3'][0] === 'All' && filter_object['segment4'][0] === 'All') {
        showTotalCategoryLabel = true
    }

    var reportsettings = reportsettings_data
    this.setState({
        reportbrands
        , reportitems
        , brand_options_filters
        , brand_lookup_filters
        , distinctgeos
        , reportsettings
        , attributes
        , attributenamelabels
        , selectedstart
        , startdate_raw
        , selectedend
        , pastdate
        , futuredate
        , filter_object
        , exclude_array
        , exclude_items
        , exclude_brands
        , showTotalCategoryLabel
        , storegeodesc
        , graph_selected_attributes: attribute_col_names
        , graph_selected_attributes_labels: [{ 'label': 'All', 'value': 'All' }]
        , params_data
        , all_outlets: outlets_data
        , dataset_data
        , times_data
        , screenOrder: savedOrder
    })

    return true
}
