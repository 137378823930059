export default function calc_metrics_total_cat(simulation_results, item_dict_lookup, brand_selected_totalcat){
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let all_items = simulation_results.item_list
    let is_all_brand_selected_totalcat = false
    if (brand_selected_totalcat === 'All') {
        is_all_brand_selected_totalcat = true
    }

    //var item_dict_lookup = item_dict_lookup
    var simsettings = this.state.simsettings
    let sim_items = {}
    simsettings.items.forEach(item => {
        sim_items[item.prod_id] = {
            'simdisc': item.sim_discount_pct,
            'simbase': item.sim_base_price
        }
    })

    let brand_base_vols = {}
    let brand_inc_vols = {}
    let brand_vol_pct = {}
    let brand_share_pct_old = {}
    let brand_share_pct_new = {}

    //Add Dollars For Share....
    let brand_base_dols = {}
    let brand_inc_dols = {}

    let simitems = this.state.simsettings.items.map(item => item.prod_id)
    //console.log(simitems)

    all_items.forEach(item => {
        var item_total
        //Adjust the total if item in simitems
        if (simitems.includes(parseInt(item))) {
            //console.log('Adjusting Metrics')
            let totalcrosschange = [0].concat(simulation_results[item]['cross_items'].map(crossitem => {
                if (simitems.includes(parseInt(crossitem))) {
                    return simulation_results[item]['cross'][crossitem]['cross_total']
                }
                else {
                    return 0
                }
            })).reduce(reducer)
            item_total = simulation_results[item]['total'] - simulation_results[item]['total_cross'] + totalcrosschange
            //console.log(item_total)
        }
        else {
            item_total = simulation_results[item].total
        }

        // LL - Uncommented above code to make Item View numbers match Total Category numbers
        //item_total = simulation_results[item].total
        simulation_results[item]['adjusted_total'] = item_total
    })

    all_items.forEach(item => {
        //console.log(item)
        var item_price = 0
        var old_item_price = 0
        var item_disc_pct = 0
        try {
            let item_brand = simulation_results[item].brand
            let item_starting_base_volume = simulation_results[item].starting_base_volume + simulation_results[item].covid_base_volume + simulation_results[item].holiday_base_volume
            //console.log(item_starting_base_volume)
            var item_total = simulation_results[item].adjusted_total

            if (this.state.simsettings.sim_type === 'base') {
                item_price = (sim_items[item] || {}).simbase || simulation_results[item].avgbp || 0
                old_item_price = this.state.basecalcresultasp[item] || simulation_results[item].avgbp || 0
                item_disc_pct = 0 //this.state.basecalcresultdis[item] || 0 
            }
            else {
                item_price = simulation_results[item].avgbp || 0
                item_disc_pct = (sim_items[item] || {}).simdisc || 0
                old_item_price = (item_price) * (1 - (item_disc_pct))
            }

            if (Object.keys(brand_base_vols).includes(item_brand)) {
                brand_base_vols[item_brand] += ((item_starting_base_volume * this.state.simsettings.numweeks)) //+ (item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage))
            }
            else {
                brand_base_vols[item_brand] = ((item_starting_base_volume * this.state.simsettings.numweeks)) // + (item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage))
            }

            if (Object.keys(brand_inc_vols).includes(item_brand)) {
                brand_inc_vols[item_brand] += item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage
            }
            else {
                brand_inc_vols[item_brand] = item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage
            }

            if (Object.keys(brand_base_dols).includes(item_brand)) {
                brand_base_dols[item_brand] += ((item_starting_base_volume * this.state.simsettings.numweeks * old_item_price)) //+ (item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage * item_price))
            }
            else {
                brand_base_dols[item_brand] = ((item_starting_base_volume * this.state.simsettings.numweeks * old_item_price)) //+ (item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage * item_price))
            }
            if (this.state.simsettings.sim_type === 'base') {
                if (Object.keys(brand_inc_dols).includes(item_brand)) {
                    brand_inc_dols[item_brand] += ((item_starting_base_volume * (1 + item_total) * this.state.simsettings.numweeks * this.state.simsettings.coverage) * item_price * (1 - item_disc_pct)) - (((item_starting_base_volume * this.state.simsettings.numweeks * old_item_price)))
                }
                else {
                    brand_inc_dols[item_brand] = ((item_starting_base_volume * (1 + item_total) * this.state.simsettings.numweeks * this.state.simsettings.coverage) * item_price * (1 - item_disc_pct)) - (((item_starting_base_volume * this.state.simsettings.numweeks * old_item_price)))
                }
            }
            else {
                if (Object.keys(brand_inc_dols).includes(item_brand)) {
                    brand_inc_dols[item_brand] += item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage * item_price * (1 - item_disc_pct)
                }
                else {
                    brand_inc_dols[item_brand] = item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage * item_price * (1 - item_disc_pct)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    })

    console.log('brand_base_vols from TotalCatMetrics',brand_base_vols)
    var total_category_volume_old = Object.values(brand_base_vols).reduce(reducer)
    var total_category_volume_new = Object.values(brand_base_vols).reduce(reducer) + Object.values(brand_inc_vols).reduce(reducer)

    var total_category_volume_old_dol = Object.values(brand_base_dols).reduce(reducer)
    var total_category_volume_new_dol = Object.values(brand_base_dols).reduce(reducer) + Object.values(brand_inc_dols).reduce(reducer)

    Object.keys(brand_base_vols).map(brand => brand_vol_pct[brand] = (brand_inc_vols[brand] / (brand_base_vols[brand] - brand_inc_vols[brand])) * 100)
    Object.keys(brand_base_vols).map(brand => brand_share_pct_new[brand] = ((brand_inc_vols[brand] + brand_base_vols[brand]) / total_category_volume_new) * 100)//total_category_volume_old) * 100)
    Object.keys(brand_base_vols).map(brand => brand_share_pct_old[brand] = (brand_base_vols[brand] / total_category_volume_old) * 100)

    //create list of brand dropdown options
    let brand_options_totalCat = [{ 'label': 'All', 'value': 'All' }].concat(Object.keys(brand_base_vols).map(brand => { return { 'label': brand, 'value': brand } }))
    let brand_items_basevols = []
    let brand_items_incvols = []
    let brand_items_vol_pct = []
    let brand_items_share_new = []
    let brand_items_share_old = []

    let brand_items_basedols = []
    let brand_items_incdols = []
    let brand_items_dol_pct = []
    let brand_items_share_new_dol = []
    let brand_items_share_old_dol = []

    let brand_items_names = []
    let brand_items_nums = []
    let brand_items_price = []

    //Push base vols
    Object.values(simulation_results).forEach(result => {
        let item_starting_base_volume = (result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume) || 0
        if (is_all_brand_selected_totalcat || (result.brand === brand_selected_totalcat)) {
            brand_items_basevols.push((item_starting_base_volume * this.state.simsettings.numweeks)) 
        }
    })
    //Push Inc Vols
    Object.values(simulation_results).forEach(result => {
        let item_starting_base_volume = (result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume) || 0
        if (is_all_brand_selected_totalcat || (result.brand === brand_selected_totalcat)) {
            brand_items_incvols.push(item_starting_base_volume * result.adjusted_total * this.state.simsettings.numweeks * this.state.simsettings.coverage)
        }
    })
    //Push Vols % Change
    Object.values(simulation_results).forEach(result => {        
        if (is_all_brand_selected_totalcat || (result.brand === brand_selected_totalcat)) {
            brand_items_vol_pct.push((result.adjusted_total * 100 * this.state.simsettings.coverage))
        }
    })
    //Old Share
    Object.values(simulation_results).forEach(result => {
        let item_starting_base_volume = (result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume) || 0
        if (is_all_brand_selected_totalcat || (result.brand === brand_selected_totalcat)) {
            brand_items_share_old.push((((item_starting_base_volume * this.state.simsettings.numweeks) + (item_starting_base_volume * result.adjusted_total * this.state.simsettings.numweeks)) / total_category_volume_old) * 100)
        }
    })

    //New Share
    Object.values(this.state.simulation_results).forEach(result => {
        let item_starting_base_volume = (result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume) || 0
        if (is_all_brand_selected_totalcat || (result.brand === brand_selected_totalcat)) {
            brand_items_share_new.push((((item_starting_base_volume * this.state.simsettings.numweeks) + (item_starting_base_volume * result.adjusted_total * this.state.simsettings.numweeks * this.state.simsettings.coverage)) / total_category_volume_new) * 100)
        }
    })

    //Push dollars
    Object.values(simulation_results).forEach((result, idx) => {
        let itemnum = Object.keys(simulation_results)[idx]
        let equiv = 1 // item_equiv_lookup[itemnum] || 1 //Removed Based on Feedback
        let item_starting_base_volume = (result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume) * equiv

        var item_total = result.adjusted_total
       
        var item_price = 0
        var old_item_price = 0
        var item_disc_pct = 0
        if (this.state.simsettings.sim_type === 'base') {
            item_price = (sim_items[itemnum] || {}).simbase || simulation_results[itemnum].avgbp || 0
            old_item_price = this.state.basecalcresultasp[itemnum] || simulation_results[itemnum].avgbp || 0
            item_disc_pct = 0 
        }
        else {
            item_price = simulation_results[itemnum].avgbp || 0
            old_item_price = simulation_results[itemnum].avgbp || 0
            item_disc_pct = (sim_items[itemnum] || {}).simdisc || 0
        }
        //push base dollars
        if (is_all_brand_selected_totalcat || (result.brand === brand_selected_totalcat)) {

            var basedollars = ((item_starting_base_volume * this.state.simsettings.numweeks * old_item_price))
            var incdollars = 0
            var pctchg = 0
            if (this.state.simsettings.sim_type === 'base') {
                incdollars = (((item_starting_base_volume * (1 + item_total) * this.state.simsettings.numweeks * this.state.simsettings.coverage) * item_price * (1 - item_disc_pct)) - (((item_starting_base_volume * this.state.simsettings.numweeks * old_item_price))))
            }
            else {
                incdollars = (item_starting_base_volume * item_total * this.state.simsettings.numweeks * this.state.simsettings.coverage * item_price * (1 - item_disc_pct))
            }
            //base dollars
            brand_items_basedols.push(basedollars)
            //inc dollars                    
            brand_items_incdols.push(incdollars)
            //percent change...
            if (this.state.simsettings.sim_type === 'base') {
                pctchg = (incdollars / basedollars) * 100
            }

            else {
                pctchg = (incdollars / basedollars) * 100
            }

            brand_items_dol_pct.push(pctchg)
            //Old Share
            brand_items_share_old_dol.push(basedollars / total_category_volume_old_dol)
            //New Share
            brand_items_share_new_dol.push((basedollars + incdollars) / total_category_volume_new_dol)
            //Avg Price
            brand_items_price.push(item_price)
        }

    })

    //Push Item Names
    Object.keys(this.state.simulation_results).forEach(itemnum => {
        var bad_keys = ['config', 'coverage', 'item_list', 'cross_effect_count_max', 'cross_effect_limits','max_cross_effects','self_vs_cross_corrections_made','self_vs_cross_enabled','top_n_cross_corrections_made']
        if (bad_keys.includes(itemnum)) {
            //console.log('Bad Key')
        }
        else if (is_all_brand_selected_totalcat || (this.state.simulation_results[itemnum].brand === brand_selected_totalcat)) {
            brand_items_names.push(item_dict_lookup[itemnum] || this.state.simulation_results[itemnum].brand + ' All Other')
            brand_items_nums.push(itemnum)
        }
        else {
            //console.log('Bad Key')
        }
    })

    //Create a lookup for items....
    let brand_items_all_metrics = {}
    Object.keys(simulation_results).forEach(itemnum => {
        var bad_keys = ['config', 'coverage', 'item_list', 'cross_effect_count_max', 'cross_effect_limits', 'max_cross_effects', 'self_vs_cross_corrections_made', 'self_vs_cross_enabled', 'top_n_cross_corrections_made']
        var numweeks = this.state.simsettings.numweeks
        var coverage = this.state.simsettings.coverage
        if (bad_keys.includes(itemnum)) {
            //console.log('Bad Key')
        }
        else if (is_all_brand_selected_totalcat || (simulation_results[itemnum].brand === brand_selected_totalcat)) {
            brand_items_all_metrics[itemnum] = {}
            let result = simulation_results[itemnum]
            let item_starting_base_volume = (result.starting_base_volume + result.covid_base_volume + result.holiday_base_volume) || 0

            var item_total = simulation_results[itemnum].adjusted_total
            
            var item_price = 0
            var old_item_price = 0
            var item_disc_pct = 0
            if (this.state.simsettings.sim_type === 'base') {
                item_price = (sim_items[itemnum] || {}).simbase || result.avgbp || 0
                old_item_price = this.state.basecalcresultasp[itemnum] || result.avgbp || 0
                item_disc_pct = 0
            }
            else {
                item_price = result.avgbp || 0
                old_item_price = result.avgbp || 0
                item_disc_pct = (sim_items[itemnum] || {}).simdisc || 0
            }

            var basedollars = ((item_starting_base_volume * numweeks * old_item_price))
            var incdollars = 0
            var pctchg = 0
            if (this.state.simsettings.sim_type === 'base') {
                incdollars = (((item_starting_base_volume * (1 + item_total) * numweeks * coverage) * item_price * (1 - item_disc_pct)) - (((item_starting_base_volume * numweeks * old_item_price))))
            }
            else {
                incdollars = (item_starting_base_volume * item_total * numweeks * coverage * item_price * (1 - item_disc_pct))
            }

            if (this.state.simsettings.sim_type === 'base') {
                pctchg = (incdollars / basedollars) * 100
            }
            else {
                pctchg = (incdollars / basedollars) * 100
            }

            //Add Name
            brand_items_all_metrics[itemnum]['proddesc'] = item_dict_lookup[itemnum] || result.brand + ' All Other'

            //Add Measures
            brand_items_all_metrics[itemnum]['basevol'] = item_starting_base_volume * numweeks
            brand_items_all_metrics[itemnum]['incvol'] = item_starting_base_volume * result.adjusted_total * numweeks * coverage
            brand_items_all_metrics[itemnum]['volpct'] = result.adjusted_total * 100 * coverage
            brand_items_all_metrics[itemnum]['oldshare'] = ((((item_starting_base_volume * numweeks)) / total_category_volume_old) * 100)
            brand_items_all_metrics[itemnum]['newshare'] = ((((item_starting_base_volume * numweeks) + (item_starting_base_volume * result.adjusted_total * numweeks * coverage)) / total_category_volume_new) * 100)

            //Dollars
            brand_items_all_metrics[itemnum]['basedollars'] = basedollars
            brand_items_all_metrics[itemnum]['incdollars'] = incdollars
            brand_items_all_metrics[itemnum]['volpct_dollars'] = pctchg
            brand_items_all_metrics[itemnum]['oldshare_dollars'] = basedollars / total_category_volume_old_dol
            brand_items_all_metrics[itemnum]['newshare_dollars'] = (basedollars + incdollars) / total_category_volume_new_dol
            brand_items_all_metrics[itemnum]['item_price'] = item_price
        }
        else {
            //console.log('Bad Key')
        }
    })


    var brand_items_basevols_total = 0
    var brand_items_incvols_total = 0
    try {
        brand_items_basevols_total = brand_items_basevols.reduce(reducer)
        brand_items_incvols_total = brand_items_incvols.reduce(reducer)
    }
    catch (error) {
        brand_items_basevols_total = 0
        brand_items_incvols_total = 0
    }

    var brand_items_basedols_total = 0
    var brand_items_incdols_total = 0
    try {
        brand_items_basedols_total = brand_items_basedols.reduce(reducer)
        brand_items_incdols_total = brand_items_incdols.reduce(reducer)
    }
    catch (error) {
        brand_items_basedols_total = 0
        brand_items_incdols_total = 0
    }

    //Setting State
    this.setState({
        brand_base_vols
        , brand_inc_vols
        , brand_share_pct_old
        , brand_share_pct_new
        , total_category_volume_old
        , total_category_volume_new
        , brand_options_totalCat
        , brand_items_basevols
        , brand_items_incvols
        , brand_items_vol_pct
        , brand_items_share_new
        , brand_items_share_old
        , brand_items_basedols
        , brand_items_incdols
        , brand_items_dol_pct
        , brand_items_share_new_dol
        , brand_items_share_old_dol
        , brand_items_names
        , brand_items_nums
        , brand_items_price
        , brand_items_basevols_total
        , brand_items_incvols_total
        , brand_items_basedols_total
        , brand_items_incdols_total
        , brand_items_all_metrics
        , items_for_totalcat: brand_items_nums
    })
}