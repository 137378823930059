import * as Constant from '../../../constants'


function getLocalStorageBasePrices(simId) {
    if (simId == null) {
        return {}
    }
    const basePrices = JSON.parse(localStorage.getItem(String(simId)) || "{}")
    return Object.keys(basePrices).reduce((prev, current) => ({...prev, [current]: parseFloat(basePrices[current])}), {})
}

export default async function runsimulation(simid, item_dict_lookup) {
    let simResult = await fetch(Constant.baseapiurl + "Simulation/" + simid)
    if (simResult.status === 200) {
        let result = await simResult.text()
        let result2 = result.replace(/NaN/g, "0").replace(/Infinity/g, "0")        
        let resultformatted = JSON.parse(result2)
        
        // check for and insert any local storage base prices
        const localStorageBasePrices = getLocalStorageBasePrices(simid)
        for (const itemId of resultformatted.item_list) {
            if (localStorageBasePrices[itemId] != null) {
                resultformatted[itemId].avgbp = localStorageBasePrices[itemId]
            }
        }

        let simbrands = []
        Object.values(resultformatted).forEach(result => {            
            try {
                if (result.item_type.from_sim) {
                    simbrands.push(result.brand)
                }
            }
            catch (error) {                
            }
        })

        let simbrands_unique = Array.from(new Set(simbrands)).sort()
        let brand_options = simbrands_unique.map(brand => { return { 'label': brand, 'value': brand } })

        this.setState({
            simulation_results: resultformatted,
            selected_brands: simbrands_unique[0],
            selected_brands_totalcat: Object.values(resultformatted)[0].brand,
            brand_options: brand_options,
        })

        //Run Calculations
        this.calc_metrics_brands(resultformatted, item_dict_lookup);
        if (this.state.simsettings.sim_type === 'base') {
            this.calc_metrics_base_new(resultformatted)
        }
        else {
            this.calc_metrics_promo_new(resultformatted)
        }
        
    }

    else {
        this.setState({ showBackground: false, errorAlert: true })
    }
}