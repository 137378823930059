export default function(H) {
    H.setOptions({
        lang: {
            thousandsSep: ',',
            numericSymbols: ["K", "M", "B", "T", "P", "E"]
        },
        colors: ["#7d32ba", "#bc81ec", "#ff8b6a", "#b0df97", "#f3508e", "#ff80b0", "#ff5929", "#c9e3fb", "#8bb0D2",  "#83b767", "#ff9971","#18cff8", "#acf0ff", "#4f5ba3", "#a7add1"],
        credits: {
            enabled: true,
            href: "https://www.circana.com/",
            text: "Circana"
        },
        chart: {
            style: {
                fontFamily: 'Roboto'
            },            
        },
        exporting: {
            scale: 1,
            sourceHeight: 900,
            sourceWidth: 1600,
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "separator",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        //"downloadSVG",
                        "separator",
                        //"downloadCSV",
                        "downloadXLS",
                        //"viewData",
                        //"openInCloud"
                    ]
                }
            },
            chartOptions: {
                chart: {
                    style: {
                        fontFamily: 'Roboto' //Roboto Font Appears to Not Render Properly with Highcharts Export Server
                    },
                }
            },
        },
        title: {
            style: {
                fontSize: '1.4rem',
                color: '#000000',
                fontWeight: 'bold'
            }
        },
        xAxis: {
            title: {
                style: {
                    color: '#000000',
                    fontSize: '1.4rem',
                }
            },
            labels: {
                style: {
                    color: '#000000',
                    fontSize: '1.2rem',
                    //textOverflow: 'none'
                },
                autoRotation: [-45],
                autoRotationLimit: 100
            }
        },
        yAxis: {
            title: {
                style: {
                    color: '#000000',
                    fontSize: '1.4rem'
                }
            },
            labels: {
                style: {
                    color: '#000000',
                    fontSize: '1.2rem'
                },
                autoRotation: false
            },
            showEmpty: false
        },
        legend: {
            itemStyle: {
                fontSize:'1.2rem'
            }
        },
        tooltip: {
            backgroundColor: 'rgba(247,247,247,1)',
            borderColor: '#000000',
            borderWidth: 2,
            shadow: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    style: {
                        color: "black",
                        fontSize: "1rem",
                        fontWeight: "normal",
                        textOutline: "0px black"
                    }
                },
            },
        },
    })

    if (window.zipcelx && H.getOptions().exporting) {
        H.Chart.prototype.downloadXLSX = function () {
            var div = document.createElement('div')
            div.style.display = 'none'
            document.body.appendChild(div)
            var rows = this.getDataRows(true)

            let metadata_for_sheet = this.userOptions.metadata || {}
            let metadata_keys = Object.keys(metadata_for_sheet)

            var titleinfo = [this.title.textStr.split('<br>')[0]]
            var titleinfoExcel = titleinfo.map(titleitem => {
                return [{ value: titleitem, type: 'string' }]
            })

            var selectionArray = [
                [ { value: "", type: 'string' } ],
                [ { value: 'Selection Information', type: 'string' } ]
            ]
            metadata_keys.forEach(key => {
                if (key.length > 0) {
                    selectionArray.push([
                        { value: key + ':', type: 'string' },
                        { value: metadata_for_sheet[key], type: 'string' },
                    ])
                }
            })
            selectionArray.push( [ { value: "", type: 'string' } ] )

            var metadata = [ [ { value: 'Title', type: 'string' } ] ].concat(titleinfoExcel).concat(selectionArray)
            var xlsxRows = rows.slice(1).map(function (row) {
                return row.map(function (column) {
                    // Adding a check to see if the value is not NaN.  If exists, add the value.  Otherwise add empty string
                    if (column) {
                        if (typeof column !== 'number') {
                            if (String(column).substring(String(column).length - 8) === '00:00:00') {
                                column = String(column).substring(0,(String(column).length - 8))
                            }
                        }
                        return {
                            type: typeof column === 'number' ? 'number' : 'string',
                            value: column
                        }
                    } else {
                        return {
                            type: 'string',
                            value: ""
                        }
                    }
                })
            })

            // Get the filename, copied from the Chart.fileDownload function
            var base_name
            if (this.options.exporting.filename) {
                base_name = this.options.exporting.filename
            } else if (this.title && this.title.textStr) {
                let namearray = this.title.textStr.split('<br>')
                base_name = namearray[0]
                base_name = base_name.replace(/ /g, '-').toLowerCase()
                // only take the first part until the break
            } else {
                base_name = 'chart'
            }

            let newdate = new Date()
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            let datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
            datestring = datestring.replaceAll(',', '')

            let filename = base_name + ' - Extracted - ' + datestring
            console.log('Saving Excel file ' + filename)
            window.zipcelx({
                filename: filename,
                sheet: {
                    data: metadata.concat(xlsxRows)
                }
            })
        }

        // Default lang string, overridable in i18n options
        H.getOptions().lang.downloadXLSX = 'Download XLSX'

        // Add the menu item handler
        H.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
            textKey: 'downloadXLSX',
            onclick: function () {
                this.downloadXLSX()
            }
        }

        // Replace the menu item
        var menuItems = H.getOptions().exporting.buttons.contextButton.menuItems
        menuItems[menuItems.indexOf('downloadXLS')] = 'downloadXLSX'
    }
}
