
export default function changeBrandSelected(selectedOptions) {
    if (this.state.simsettings.sim_type === 'promo') {
        this.setState({
            selected_brands: selectedOptions.value,
            //waterfall_data_brand_graph: this.state.waterfall_data_brand[selectedOptions.value],
            // The waterfall data item must be a totally new item for the widget to function, so we make a deep copy
            waterfall_data_brand: JSON.parse(JSON.stringify(this.state.waterfall_data_brand)),
            total_volume_change_brand: this.state.total_volume_changes_brand[selectedOptions.value],
            starting_base_volume_brand: this.state.starting_volume_brand[selectedOptions.value],
            diff_total_dollar_brand: this.state.diff_total_dollars_brand[selectedOptions.value],
            old_total_dollar_brand: this.state.start_total_dollars_brand[selectedOptions.value],
            incremental_profit_total_brand: this.state.incremental_profit_brand[selectedOptions.value],

            //If Promo Sim Change Metrics
            basedollars_total_brand: this.state.basedollars_brand[selectedOptions.value],
            retailer_eday_margin_dollars_total_brand: this.state.retailer_eday_margin_dollars_brand[selectedOptions.value],
            retailer_promotion_profit_total_brand: this.state.retailer_promotion_profit_brand[selectedOptions.value],
            retailer_promo_margin_dollars_total_brand: this.state.retailer_promo_margin_dollars_brand[selectedOptions.value],
            incremental_profit_total_brand: this.state.incremental_profit_brand[selectedOptions.value],
            incremental_net_revenue_total_brand: this.state.incremental_net_revenue_brand[selectedOptions.value],
            incremental_gross_revenue_total_brand: this.state.incremental_gross_revenue_brand[selectedOptions.value],
            net_revenue_total_brand: this.state.net_revenue_brand[selectedOptions.value],
            ttl_cost_per_addl_unit_total_brand: this.state.ttl_cost_per_addl_unit_brand[selectedOptions.value],
            total_spend_total_brand: this.state.total_spend_brand[selectedOptions.value],
            gross_revenue_total_brand: this.state.gross_revenue_brand[selectedOptions.value],
            total_promo_volume_brand: this.state.promo_volume_brand[selectedOptions.value],
            total_nonpromo_volume_brand: this.state.nonpromo_volume_brand[selectedOptions.value],
            incdollar_total_brand: this.state.incdollar_brand[selectedOptions.value],
            promodollar_total_brand: this.state.promodollar_brand[selectedOptions.value],
            nonpromodollar_total_brand: this.state.nonpromodollar_brand[selectedOptions.value],
            discountdollar_total_brand: this.state.discountdollar_brand[selectedOptions.value],
            unsubsidized_base_volume_total_brand: this.state.unsubsidized_base_volume_brand[selectedOptions.value],
            subsidized_base_volume_total_brand: this.state.subsidized_base_volume_brand[selectedOptions.value],
            unsubsidized_base_dollars_total_brand: this.state.unsubsidized_base_dollars_brand[selectedOptions.value],
            subsidized_base_dollars_total_brand: this.state.subsidized_base_dollars_brand[selectedOptions.value],
        })
    } else {
        this.setState({
            selected_brands: selectedOptions.value,
            //waterfall_data_brand_graph: this.state.waterfall_data_brand[selectedOptions.value],
            // The waterfall data item must be a totally new item for the widget to function, so we make a deep copy
            waterfall_data_brand: JSON.parse(JSON.stringify(this.state.waterfall_data_brand)),
            total_volume_change_brand: this.state.total_volume_changes_brand[selectedOptions.value],
            starting_base_volume_brand: this.state.starting_volume_brand[selectedOptions.value],
            diff_total_dollar_brand: this.state.diff_total_dollars_brand[selectedOptions.value],
            old_total_dollar_brand: this.state.start_total_dollars_brand[selectedOptions.value],
            incremental_profit_total_brand: this.state.incremental_profit_brand[selectedOptions.value],
        })
    }
}
