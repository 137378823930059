import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../../constants'

const DiscDollarsAndPBRByWeek = props => {

    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'

    var aggtype = ""
    if ((props.aggperiod || "") === 'Monthly') {
        aggtype = 'Monthly'
    }
    else {
        aggtype = 'Weekly'
    }

    //var xDateFormatString = ""
    var weeklytimeperiods = ""
    var axisFormatString = ""
    var axisText = ""
    var tickpositionsarray = props.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))))

    var stepNum = 1
    var paddingNum = 5
    var fontSizeString = '11px'
    if (tickpositionsarray.length > 26 && props.height === 2) {
        stepNum = 2
        paddingNum = 10
        fontSizeString = '9px'
    }

    if (aggtype === 'Monthly') {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Month Period Ending: %b %Y'
            weeklytimeperiods = props.weekagg + " Month Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Month Period'
        }

        else {
            //xDateFormatString = '%b %Y'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Month'
        }

    }
    else {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Week Period Ending: %Y-%m-%d'
            weeklytimeperiods = props.weekagg + " Week Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Week Period'
        }

        else {
            //xDateFormatString = 'Week Ending: %Y-%m-%d'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Week Ending'
        }
    }

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            zoomType: 'xy',
                            height: (9 / 16 * 100) + '%' //16:9 ratio
                        },
                        metadata: props.metadata || {},
                        caption: {
                            text: props.attr_caption_text
                        },
                        title: {
                            text: 'Discount ' + currencytype + ', Incremental ' + props.unitsvar + ' and Payback Ratio - ' + props.chart_title_info + weeklytimeperiods
                        },
                        series: [
                            {
                                name: 'Discount ' + currencytype,
                                type: 'spline',
                                tooltip: {
                                    //xDateFormat: xDateFormatString,
                                    pointFormatter: function () {
                                        let dt = new Date(this.x)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.x + offset)
                                        if (aggtype === "Monthly") {
                                            return '<span>Month: '
                                                + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0 })
                                                + '</span>'
                                        }
                                        else {
                                            return '<span>Week Ending: '
                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0 })
                                                + '</span>'
                                        }

                                    }
                                },
                                data: (props.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((props.weekly_data || {})[date] || {})['discountdollars']]), //Need to Edit
                                zIndex: 3

                            },

                            {
                                name: 'Incremental ' + currencytype,
                                type: 'spline',
                                tooltip: {
                                    //xDateFormat: xDateFormatString,
                                    pointFormatter: function () {
                                        let dt = new Date(this.x)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.x + offset)
                                        if (aggtype === "Monthly") {
                                            return '<span>Month: '
                                                + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0 })
                                                + '</span>'
                                        }
                                        else {
                                            return '<span>Week Ending: '
                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0 })
                                                + '</span>'
                                        }                                        
                                    }
                                },
                                data: (props.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((props.weekly_data || {})[date] || {})['incrementaldollars']]),
                                zIndex: 2

                            },

                            {
                                name: 'Incremental ' + props.unitsvar,
                                type: 'column',
                                tooltip: {
                                    //xDateFormat: xDateFormatString,
                                    pointFormatter: function () {
                                        let dt = new Date(this.x)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.x + offset)

                                        if (aggtype === "Monthly") {
                                            return '<span>Month: '
                                                + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString() 
                                                + ' ' 
                                                + props.unitsvar
                                                + '</span>'
                                        }
                                        else {
                                            return '<span>Week Ending: '
                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + Math.round(this.y).toLocaleString() 
                                                + ' '
                                                + props.unitsvar
                                                + '</span>'
                                        }
                                    }
                                },
                                data: (props.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), ((props.weekly_data || {})[date] || {})['incrementalunits']]),
                                yAxis: 1,
                                pointWidth: '5',
                                zIndex: 1
                            },

                            {
                                name: 'Payback Ratio',
                                type: 'column',
                                tooltip: {
                                    pointFormatter: function () {
                                        let dt = new Date(this.x)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.x + offset)

                                        if (aggtype === "Monthly") {
                                            return '<span>Month: '
                                                + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + this.y.toFixed(2)
                                                + '</span>'
                                        }
                                        else {
                                            return '<span>Week Ending: '
                                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                                + '<br>'
                                                + this.series.name
                                                + '<br> '
                                                + this.y.toFixed(2)
                                                + '</span>'
                                        }
                                    }
                                },
                                data: (props.distinctdates).sort().map(date => {
                                    let payback_ratio = 0
                                    let avg_disc = (((props.weekly_data || {})[date] || {})['weightedpctdisc'] / ((props.weekly_data || {})[date] || {})['totalunits'])
                                    if (avg_disc > .01) {
                                        payback_ratio = ((props.weekly_data || {})[date] || {})['incrementaldollars'] / ((props.weekly_data || {})[date] || {})['discountdollars']
                                    }
                                    else {
                                        payback_ratio = 0 
                                    }

                                    return [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), payback_ratio]
                                }),
                                yAxis: 2,
                                pointWidth: '15',
                                zIndex: 0
                            },
                        ],
                        xAxis: [
                            {
                                title: {
                                    text: axisText
                                },
                                type: 'datetime',
                                dateTimeLabelFormats: {
                                    month: axisFormatString
                                },
                                tickPositions: tickpositionsarray,
                                labels: {
                                    formatter: function () {
                                        let dt = new Date(this.value)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.value + offset)
                                        const options = { year: 'numeric', month: 'short' };
                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                        if ((props.aggperiod || "") === 'Monthly') {
                                            return date.toLocaleDateString(undefined, options)
                                        }
                                        else {
                                            return date.toLocaleDateString(undefined, options2) 
                                        }
                                    },
                                    rotation: 270,
                                    step: stepNum,
                                    padding: paddingNum,
                                    style: {
                                        fontSize: fontSizeString
                                    }
                                }
                            },
                            {
                                title: {
                                    text: ""
                                },
                                type: 'datetime',
                                dateTimeLabelFormats: {
                                    month: axisFormatString
                                },
                                tickPositions: tickpositionsarray,
                                labels: {
                                    formatter: function () {
                                        let dt = new Date(this.value)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.value + offset)
                                        //const options = { year: 'numeric', month: 'short' };
                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                        return date.toLocaleDateString(undefined, options2) //toISOString().substring(0, 10)
                                    },
                                    rotation: 270,
                                    step: stepNum,
                                    padding: paddingNum,
                                    style: {
                                        fontSize: fontSizeString
                                    }
                                },
                                opposite: true
                            }
                        ],
                        yAxis: [
                            {
                                title: {
                                    text: currencytype
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                labels: {

                                    formatter: function () {
                                        //console.log(this)
                                        function nFormatter(num, digits, axisMax) {
                                            var si = [
                                                { value: 1E18, symbol: "E" },
                                                { value: 1E15, symbol: "P" },
                                                { value: 1E12, symbol: "T" },
                                                { value: 1E9, symbol: "B" },
                                                { value: 1E6, symbol: "M" },
                                                { value: 1E3, symbol: "k" }
                                            ], i;

                                            let symbolForAxis = " "
                                            let valueForAxis = 1

                                            for (i = 0; i < si.length; i++) {
                                                if (axisMax >= si[i].value) {
                                                    symbolForAxis = si[i].symbol
                                                    valueForAxis = si[i].value
                                                    break;
                                                }
                                            }

                                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                        }
                                        //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        let value = nFormatter(this.value, 1, this.axis.max)
                                        let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                        let symbol = value.substring(value.length - 1)
                                        return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol                                        
                                    }
                                },
                                showEmpty: false

                            },
                            {
                                title: {
                                    text: 'Incremental ' + props.unitsvar
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                opposite: true,
                                showEmpty: false,
                                labels: {

                                    formatter: function () {
                                        //console.log(this)
                                        function nFormatter(num, digits, axisMax) {
                                            var si = [
                                                { value: 1E18, symbol: "E" },
                                                { value: 1E15, symbol: "P" },
                                                { value: 1E12, symbol: "T" },
                                                { value: 1E9, symbol: "B" },
                                                { value: 1E6, symbol: "M" },
                                                { value: 1E3, symbol: "k" }
                                            ], i;

                                            let symbolForAxis = " "
                                            let valueForAxis = 1

                                            for (i = 0; i < si.length; i++) {
                                                if (axisMax >= si[i].value) {
                                                    symbolForAxis = si[i].symbol
                                                    valueForAxis = si[i].value
                                                    break;
                                                }
                                            }

                                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                        }
                                        return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                        
                                    }
                                },
                            },
                            {
                                title: {
                                    text: 'Payback Ratio'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                opposite: true,
                                showEmpty: false
                            }
                        ],
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: false,
                                    format: '{point.y:,.0f}'
                                }
                            },

                            spline: {
                                marker: {
                                    enabled: false
                                }
                            },

                            series: {
                                states: {
                                    inactive: {
                                        opacity: 1
                                    }
                                },
                            }

                        },
                        tooltip: {
                            headerFormat: '',
                        },
                        exporting: {
                            showTable: props.showDataTable,
                            tableCaption: false
                        },

                    }}
            />
            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g18">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Discount ' + currencytype}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Incremental ' + currencytype}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Incremental Units</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Payback Ratio</Constant.NPDTableCell>
                        </Constant.NPDTableRow>
                        {props.distinctdates.map(date => {
                            let payback_ratio = 0
                            let avg_disc = (((props.weekly_data || {})[date] || {})['weightedpctdisc'] / ((props.weekly_data || {})[date] || {})['totalunits'])
                            if (avg_disc > .01) {
                                payback_ratio = ((props.weekly_data || {})[date] || {})['incrementaldollars'] / ((props.weekly_data || {})[date] || {})['discountdollars']
                            }
                            else {
                                payback_ratio = ((props.weekly_data || {})[date] || {})['incrementaldollars'] / ((props.weekly_data || {})[date] || {})['discountdollars'] //0
                            }
                            return <Constant.NPDTableRow key={"datarow_" + date + "_g18"}>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data || {})[date] || {})['discountdollars']).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0, minimumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data || {})[date] || {})['incrementaldollars']).toLocaleString(undefined, { style: 'currency', currency: currencycode, maximumFractionDigits: 0, minimumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.round(((props.weekly_data || {})[date] || {})['incrementalunits']).toLocaleString()}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(payback_ratio || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Constant.NPDTableCell>
                            </Constant.NPDTableRow>
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default DiscDollarsAndPBRByWeek;

