import React, { Fragment } from 'react';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import * as Constant from '../constants';

const ScorecardPricingMetrics = props => {

    var currencycode = props.currencycode || 'USD'
    var currencytype = props.currencytype || 'Dollars'
    var appname = props.appname

    var base_units_curr = props.base_units_curr
    var base_units_prior = props.base_units_prior

    var inc_units_curr = props.inc_units_curr
    var inc_units_prior = props.inc_units_prior
    var total_units_curr = base_units_curr + inc_units_curr
    var total_units_prior = base_units_prior + inc_units_prior

    var nonpromo_units_curr = props.nonpromo_units_curr
    var nonpromo_units_prior = props.nonpromo_units_prior
    var promo_units_curr = props.promo_units_curr
    var promo_units_prior = props.promo_units_prior

    var avg_base_price_curr = props.avg_base_price_curr
    var avg_base_price_prior = props.avg_base_price_prior

    var avg_disc_curr = props.avg_disc_curr
    var avg_disc_prior = props.avg_disc_prior
    var avg_disc_withoutno_curr = props.avg_disc_withoutno_curr
    var avg_disc_withoutno_prior = props.avg_disc_withoutno_prior
    var disc_dollars_curr = props.disc_dollars_curr
    var disc_dollars_prior = props.disc_dollars_prior

    var total_dollars_curr = props.total_dollars_curr
    var total_dollars_prior = props.total_dollars_prior

    var nonpromoted_dollars_curr = props.nonpromoted_dollars_curr
    var nonpromoted_dollars_prior = props.nonpromoted_dollars_prior

    var promoted_dollars_curr = props.promoted_dollars_curr
    var promoted_dollars_prior = props.promoted_dollars_prior

    var avg_promo_price_curr, avg_promo_price_prior, avg_selling_price_curr, avg_selling_price_prior
    if (appname === 'pdt') {
        avg_promo_price_curr = promoted_dollars_curr / promo_units_curr
        avg_promo_price_prior = promoted_dollars_prior / promo_units_prior
    }

    else {
        avg_promo_price_curr = props.avg_promo_price_curr
        avg_promo_price_prior = props.avg_promo_price_prior
    }


    var avg_nonpromo_price_curr = nonpromoted_dollars_curr / nonpromo_units_curr
    var avg_nonpromo_price_prior = nonpromoted_dollars_prior / nonpromo_units_prior

    if (appname === 'pdt') {
        avg_selling_price_curr = total_dollars_curr / total_units_curr
        avg_selling_price_prior = total_dollars_prior / total_units_prior
    }
    else {
        avg_selling_price_curr = props.avg_promo_price_curr
        avg_selling_price_prior = props.avg_promo_price_prior
    }

    return (
        appname === 'pdt' ?
            <Fragment>
                <h3>Pricing Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of Units Identified as Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Base Price <span style={{ color: '#FFFFFF' }}> UN </span></h5>
                                <p>{(avg_base_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_base_price_curr - avg_base_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Average Selling Price of All Units Sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Selling Price <span style={{ color: '#FFFFFF' }}> Un </span></h5>
                                <p>{(avg_selling_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_selling_price_curr - avg_selling_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_selling_price_curr - avg_selling_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_selling_price_curr - avg_selling_price_prior) / avg_selling_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_selling_price_curr - avg_selling_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_selling_price_curr - avg_selling_price_prior) / avg_selling_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Promo Price <span style={{ color: '#FFFFFF' }}> Un </span></h5>
                                <p>{(avg_promo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_promo_price_curr - avg_promo_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as not being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Non-Promo Price</h5>
                                <p>{(avg_nonpromo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {(avg_nonpromo_price_curr - avg_nonpromo_price_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_nonpromo_price_curr - avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_nonpromo_price_curr - avg_nonpromo_price_prior) / avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_nonpromo_price_curr - avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_nonpromo_price_curr - avg_nonpromo_price_prior) / avg_nonpromo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across all units sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Discount <span style={{ color: '#FFFFFF' }}> Gettonext </span></h5>
                                <p>{(avg_disc_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(avg_disc_curr - avg_disc_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across units identified as being on promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>Average Promoted Discount</h5>
                                <p>{(avg_disc_withoutno_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(avg_disc_withoutno_curr - avg_disc_withoutno_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_disc_withoutno_curr - avg_disc_withoutno_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_disc_withoutno_curr - avg_disc_withoutno_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The total value of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the actual price of a promoted product and multiplying by Promoted Unit sales.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2" align='center' style={{ padding: '0px' }}>
                            <div className="contentHolder_scorecard">
                                <h5>{'Discount ' + currencytype}</h5>
                                <p>{(disc_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                {(disc_dollars_curr - disc_dollars_prior) > 0 ?
                                    <Fragment>
                                        <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
            :
            <Fragment>
                <h3>Pricing Metrics</h3>
                <div className="row" style={{ padding: '10px' }}>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of Units Identified as Base Units</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Base Price</h5>
                            <p>{(avg_base_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            {(avg_base_price_curr - avg_base_price_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_base_price_curr - avg_base_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_base_price_curr - avg_base_price_prior) / avg_base_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Estimated Selling Price of All Units Identifed as being sold on Promotion.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Promo Price</h5>
                            <p>{(avg_promo_price_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            {(avg_promo_price_curr - avg_promo_price_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (+' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_promo_price_curr - avg_promo_price_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + ((avg_promo_price_curr - avg_promo_price_prior) / avg_promo_price_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>Average Discount Percent across all units sold</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>Average Discount</h5>
                            <p>{(avg_disc_curr).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(avg_disc_curr - avg_disc_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(avg_disc_curr - avg_disc_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).slice(0, -1) + ' ppts YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                    <Constant.HTMLTooltip
                        title={
                            <Fragment>
                                <p>The total value of the discounts of promoted products in the weeks they were promoted. This is calculated by taking the difference between the base price and the actual price of a promoted product and multiplying by Promoted Unit sales.</p>
                            </Fragment>
                        }
                        placement="right-start"
                    >
                        <div className="col-sm-2 contentHolder_scorecard" align='center' >
                            <h5>{'Discount ' + currencytype}</h5>
                            <p>{(disc_dollars_curr).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            {(disc_dollars_curr - disc_dollars_prior) > 0 ?
                                <Fragment>
                                    <ArrowUpwardRoundedIcon style={{ color: '#7d32ba' }} /><span style={{ color: '#7d32ba', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (+' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                                :
                                <Fragment>
                                    <ArrowDownwardRoundedIcon style={{ color: '#f3508e' }} /><span style={{ color: '#f3508e', fontSize: '16px' }}><b> {(disc_dollars_curr - disc_dollars_prior).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' (' + ((disc_dollars_curr - disc_dollars_prior) / disc_dollars_prior).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ') YOY'}</b></span>
                                </Fragment>
                            }
                        </div>
                    </Constant.HTMLTooltip>
                </div>
            </Fragment>
    )
}

export default ScorecardPricingMetrics;