import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as Constant from '../../../constants';

export class SideBySideSwitch extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <ThemeProvider theme={Constant.muiTheme}>
                    <span style={{ lineHeight: '5' }}>Difference</span>
                    <Switch
                        checked={this.props.state.indexSwitchYOY}
                        onChange={this.props.handleChange_IndexSwitchYOY}
                        color="secondary"
                    />
                    <span style={{ lineHeight: '5' }}>Side by Side </span>
                </ThemeProvider>
            </Fragment>
        )
    }
}