import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import * as Constant from '../../../constants';
import selectColourStyles from '../../../styles/SelectColourStyles'

export class AreaChartMetricSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                <span className="formfieldtitle">Select a Metric</span>
                <Select
                    options={this.props.state.metricOptions_Area}
                    placeholder={'Select A Metric'}
                    onChange={(item) => this.props.changeSelectedMetric_Area(item)}
                    value={this.props.state.selectedMetric_Area}
                    styles={Constant.dropdownStyles}
                    styles={selectColourStyles}
                />
            </Fragment>
        )
    }
}


