import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'

const LiftEffDiscByWeek = props => {
    var product = props.product || 'PNP'
    var additional_text = ''
    if (product === 'PDT') {
        additional_text = 'Promoted'
    }
    var aggtype = ""
    if ((props.aggperiod || "") === 'Monthly') {
        aggtype = 'Monthly'
    }
    else {
        aggtype = 'Weekly'
    }

    //var xDateFormatString = ""
    var weeklytimeperiods = ""
    var axisFormatString = ""
    var axisText = ""
    var tickpositionsarray = props.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))))

    var stepNum = 1
    var paddingNum = 5
    var fontSizeString = '11px'
    if (tickpositionsarray.length > 26 && props.height === 2) {
        stepNum = 2
        paddingNum = 10
        fontSizeString = '9px'
    }

    if (aggtype === 'Monthly') {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Month Period Ending: %b %Y'
            weeklytimeperiods = props.weekagg + " Month Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Month Period'
        }

        else {
            //xDateFormatString = '%b %Y'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Month'
        }

    }
    else {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Week Period Ending: %Y-%m-%d'
            weeklytimeperiods = props.weekagg + " Week Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Week Period'
        }

        else {
            //xDateFormatString = 'Week Ending: %Y-%m-%d'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Week Ending'
        }
    }
    

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        zoomType: 'xy',
                        height: (9 / 16 * 100) + '%' //16:9 ratio
                    },
                    metadata: props.metadata || {},
                    caption: { text: props.attr_caption_text },
                    title: {
                        text: product === 'PDT' ?
                            'Lift and Efficiency with Average ' + additional_text + ' Discount % by Week' + props.chart_title_info + weeklytimeperiods
                            :
                            'Lift and Efficiency with Average ' + additional_text + ' Discount % by Week <br/> ' + props.chart_title_info + weeklytimeperiods
                    },
                    series: [
                        {
                            name: 'Market Lift',
                            type: 'spline',
                            tooltip: {
                                //xDateFormat: xDateFormatString,
                                pointFormatter: function () {
                                    let dt = new Date(this.x)
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    let date = new Date(this.x + offset)
                                    if (aggtype === "Monthly") {
                                        return '<span>Month: '
                                            + date.toLocaleDateString(undefined,{ month: 'short', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>Week Ending: '
                                            + date.toLocaleDateString(undefined,{ month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                    
                                }
                            },

                            data: (props.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), Math.min((props.weekly_data[props.selectedBrand][date]['incrementalunits'] / props.weekly_data[props.selectedBrand][date]['baseunits']),10)])
                        },
                        {
                            name: 'Promoted Lift',
                            type: 'spline',
                            tooltip: {
                                //xDateFormat: xDateFormatString,
                                pointFormatter: function () {
                                    let dt = new Date(this.x)
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    let date = new Date(this.x + offset)
                                    if (aggtype === "Monthly") {
                                        return '<span>Month: '
                                            + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>Week Ending: '
                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                }
                            },

                            data: (props.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), Math.min(((props.weekly_data[props.selectedBrand][date]['incrementalunits'] / props.weekly_data[props.selectedBrand][date]['subsidizedbaseunits']) || 0), 10)])
                        },
                        {
                            name: 'Efficiency',
                            type: 'spline',
                            tooltip: {
                                //xDateFormat: xDateFormatString,
                                pointFormatter: function () {
                                    let dt = new Date(this.x)
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    let date = new Date(this.x + offset)
                                    if (aggtype === "Monthly") {
                                        return '<span>Month: '
                                            + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>Week Ending: '
                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                }
                            },

                            data: (props.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))), Math.min(((props.weekly_data[props.selectedBrand][date]['incrementalunits'] / props.weekly_data[props.selectedBrand][date]['promotedunits']) || 0),1)])
                        },
                        {
                            name: 'Average ' + additional_text + ' Discount %',
                            type: 'column',
                            yAxis: 1,
                            tooltip: {
                                //xDateFormat: xDateFormatString,
                                pointFormatter: function () {
                                    let dt = new Date(this.x)
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    let date = new Date(this.x + offset)
                                    if (aggtype === "Monthly") {
                                        return '<span>Month: '
                                            + date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>Week Ending: '
                                            + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                }
                            },
                            data: (props.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                                Math.min(((((props.weekly_data[props.selectedBrand][date]['weightedpctdisc_withoutno'] || props.weekly_data[props.selectedBrand][date]['weightedpctdisc']) / (props.weekly_data[props.selectedBrand][date]['totalunits_withoutno'] || props.weekly_data[props.selectedBrand][date]['totalunits']))) || 0),1)
                            ])

                        },
                    ],
                    xAxis: {
                        title: {
                            text: axisText
                        },
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            month: axisFormatString
                        },
                        tickPositions: tickpositionsarray,
                        labels: {
                            formatter: function () {
                                let dt = new Date(this.value)
                                let offset = dt.getTimezoneOffset() * 60 * 1000
                                let date = new Date(this.value + offset)
                                const options = { year: 'numeric', month: 'short' };
                                const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                if ((props.aggperiod || "") === 'Monthly') {
                                    return date.toLocaleDateString(undefined, options)
                                }
                                else {
                                    return date.toLocaleDateString(undefined, options2) //date.toISOString().substring(0, 10)
                                }
                            },
                            rotation: 270,
                            step: stepNum,
                            padding: paddingNum,
                            style: {
                                fontSize: fontSizeString
                            }
                        }
                    },
                    yAxis: [
                        {
                            title: {
                                text: 'Lift/Efficiency'
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            reversedStacks: false,
                            labels: {
                                formatter: function () {
                                    return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                }
                            }
                        },
                        {
                            title: {
                                text: 'Average ' + additional_text + ' Discount %'
                            },
                            gridLineColor: "#CCC",
                            gridLineWidth: 1,
                            opposite: true,
                            labels: {
                                formatter: function () {
                                    return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: false,
                                format: '{point.y:,.0f}'
                            },
                            opacity: .75
                        },

                        spline: {
                            marker: {
                                enabled: false
                            }
                        },
                        line: {
                            marker: {
                                enabled: false
                            },
                            tooltip: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            },
                        },
                        series: {
                            states: {
                                inactive: {
                                    opacity: 1
                                }
                            }
                        }

                    },
                    tooltip: {
                        headerFormat: '',
                    },
                    exporting: {
                        showTable: props.showDataTable,
                        tableCaption: false
                    },

                }}
            />
            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g17">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Lift</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Efficiency</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Average ' + additional_text + ' Discount %'}</Constant.NPDTableCell>
                        </Constant.NPDTableRow>
                        {props.distinctdates.map(date => {
                            return <Constant.NPDTableRow key={"datarow_" + date + "_g17"}>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(Math.min((props.weekly_data[props.selectedBrand][date]['incrementalunits'] / props.weekly_data[props.selectedBrand][date]['baseunits']),10) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(Math.min((props.weekly_data[props.selectedBrand][date]['incrementalunits'] / props.weekly_data[props.selectedBrand][date]['promotedunits']),1) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                <Constant.NPDTableCell style={{ textAlign: 'center' }}>{Math.min((((((props.weekly_data[props.selectedBrand][date]['weightedpctdisc_withoutno'] || props.weekly_data[props.selectedBrand][date]['weightedpctdisc']) / (props.weekly_data[props.selectedBrand][date]['totalunits_withoutno'] || props.weekly_data[props.selectedBrand][date]['totalunits']))) || 0)),1).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                            </Constant.NPDTableRow>
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default LiftEffDiscByWeek;