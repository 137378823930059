import * as Constant from '../../constants'
import React, { Component, Fragment } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export class CriteriaSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            checked: [],
            simsCompare: [],
            compareErrorDisplay: 'none',
        }
        console.log(props)
    }

    render() {

        
        const currency_obj = { style: 'currency', currency: this.props.state.currencycode }
        const percent_obj = { style: 'percent', maximumFractionDigits: 0 }

        return (
            <div className="col-sm-12 contentHolder simOutputGraphs" align='center' style={{ marginLeft: '40px', padding: '0px' }}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h5 style={{ padding: '10px' }}>Simulation Criteria</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row col-sm-12">
                            <div className="row col-sm-12 " >
                                <div className="col-sm-6" align='left'>
                                    <h4>Date Range</h4>
                                    <p>{this.props.state.simsettings.sim_start && this.props.state.simsettings.sim_start.substr(0, 10)} to {this.props.state.simsettings.sim_end && this.props.state.simsettings.sim_end.substr(0, 10)}</p>
                                </div>
                                <div className="col-sm-6" align='right'>
                                    <h4>Last Modified</h4>
                                    <p>{this.props.state.simsettings.time_updated_adj && this.props.state.simsettings.time_updated_adj.substring(0, 19)}</p>
                                </div>
                            </div>
                            <div className="row col-sm-12 " >
                                <div className="col-sm-6" align='left'>
                                    <h4>Geographies</h4>
                                    <p>{this.props.state.simsettings.geogid && this.props.state.dataset_info.geos && this.props.state.simsettings.geogid.map(geo => (
                                        this.props.state.dataset_info.geos.map(geo_info => {
                                            if (parseInt(geo_info.geogid) == parseInt(geo)) {
                                                return geo_info.geogdesc_modified
                                            }
                                        })
                                    ))}
                                    </p>
                                </div>
                                <div className="col-sm-6" align='right'>
                                    <h4>Type</h4>
                                    <p>{this.props.state.simsettings.sim_type && this.props.state.simsettings.sim_type.substring(0, 1).toUpperCase() + this.props.state.simsettings.sim_type.substring(1)}</p>
                                </div>
                            </div>
                            <div className="row col-sm-12 " >
                                <div align='left' className="col-sm-3" style={{ marginRight: '10px', marginLeft: '0px' }}>
                                    <h4>Item Information</h4>
                                </div>
                                <div align='center' className="col-sm-12" style={{ marginRight: '10px', marginLeft: '0px' }}>
                                    <Constant.NPDTable>
                                        <TableHead className='tableheader'>
                                            <TableRow key={'tablerow_header'}>
                                                <TableCell align='left'>Item</TableCell>
                                                <TableCell align='center'>Base {this.props.state.unitsvar}</TableCell>
                                                <TableCell align='center'>Base Price</TableCell>
                                                <TableCell align='center'>Promoted Price</TableCell>
                                                <TableCell align='center'>Discount Percent</TableCell>
                                                <TableCell align='center'>{this.props.state.simsettings.sim_type === 'base' ? <span>Simulated Base Price</span> : <span>Simulated Discount Percent</span>}</TableCell>
                                                <TableCell align='center'>{this.props.state.simsettings.sim_type === 'base' ? <span>Base Price % Change</span> : <span>Simulated Promoted Price</span>}</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {this.props.state.simsettings.items && this.props.state.simsettings.items.map(item => (
                                                this.props.state.simsettings.sim_type === 'base' ?
                                                    <Constant.NPDTableRow key={'tablerow_' + item.prod_id}>
                                                        <Constant.NPDTableCell align='left' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>
                                                            {this.props.state.dataset_info.items &&
                                                                this.props.state.dataset_info.items.map(item_info => {
                                                                    if (parseInt(item_info.prodid) == item.prod_id) {
                                                                        return item_info.proddesc
                                                                    }
                                                                })
                                                            }
                                                        </Constant.NPDTableCell>
                                                        {item.override_base_flag === true ?
                                                            <Fragment>
                                                                <Constant.NPDTableCell align='center' style={{ fontWeight: 'bold', color: '#4e106f', fontFamily: 'Roboto' }}> {item.override_base_volume.toLocaleString()} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ fontWeight: 'bold', color: '#4e106f', fontFamily: 'Roboto' }}> {item.override_base_price.toLocaleString(undefined, currency_obj)} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {(item.override_base_price * (1 - (parseFloat(this.props.state.basecalcresultdis[item.prod_id])))).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode })}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {this.props.state.basecalcresultdis[item.prod_id] && (this.props.state.basecalcresultdis[item.prod_id]).toLocaleString(undefined, percent_obj)}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {item.sim_base_price.toLocaleString(undefined, currency_obj)}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {((item.sim_base_price - item.override_base_price) / item.override_base_price).toLocaleString(undefined, percent_obj)}</Constant.NPDTableCell>
                                                            </Fragment>
                                                            : <Fragment>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {Math.round(this.props.state.basecalcresultvol[item.prod_id]).toLocaleString()} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {this.props.state.basecalcresultasp[item.prod_id] && (this.props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, currency_obj)} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {((this.props.state.basecalcresultasp[item.prod_id]) * (1 - ((Math.round(parseFloat(this.props.state.basecalcresultdis[item.prod_id]) * 100)) / 100))).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode })}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {this.props.state.basecalcresultdis[item.prod_id] && (this.props.state.basecalcresultdis[item.prod_id]).toLocaleString(undefined, percent_obj)}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {item.sim_base_price.toLocaleString(undefined, currency_obj)}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {((item.sim_base_price - this.props.state.basecalcresultasp[item.prod_id]) / this.props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, percent_obj)}</Constant.NPDTableCell>
                                                            </Fragment>
                                                        }
                                                    </Constant.NPDTableRow>
                                                    :
                                                    <Constant.NPDTableRow key={'tablerow_' + item.prod_id}>
                                                        <Constant.NPDTableCell align='left' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>
                                                            {this.props.state.dataset_info.items &&
                                                                this.props.state.dataset_info.items.map(item_info => {
                                                                    if (parseInt(item_info.prodid) == item.prod_id) {
                                                                        return item_info.proddesc
                                                                    }
                                                                })
                                                            }
                                                        </Constant.NPDTableCell>
                                                        {item.override_base_flag === true ?
                                                            <Fragment>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto', fontWeight: 'bold' }}> {this.props.state.item_equiv_lookup && Math.round((item.override_base_volume * this.props.state.item_equiv_lookup[item.prod_id])).toLocaleString()} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }} > {this.props.state.basecalcresultasp[item.prod_id] && (this.props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, currency_obj)} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>{((this.props.state.basecalcresultasp[item.prod_id]) * (1 - (parseFloat(item.override_base_discount)))).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode })}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto', fontWeight: 'bold' }}>{(item.override_base_discount).toLocaleString(undefined, percent_obj)}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>{item.sim_discount_pct.toLocaleString(undefined, percent_obj)}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>{((this.props.state.basecalcresultasp[item.prod_id]) * (1 - item.sim_discount_pct)).toLocaleString(undefined, currency_obj)}</Constant.NPDTableCell>
                                                            </Fragment>
                                                            : <Fragment>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {this.props.state.item_equiv_lookup && Math.round(this.props.state.basecalcresultvol[item.prod_id] * this.props.state.item_equiv_lookup[item.prod_id]).toLocaleString()} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}> {this.props.state.basecalcresultasp[item.prod_id] && (this.props.state.basecalcresultasp[item.prod_id]).toLocaleString(undefined, currency_obj)} </Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>{((this.props.state.basecalcresultasp[item.prod_id]) * (1 - ((Math.round(parseFloat(this.props.state.basecalcresultdis[item.prod_id]) * 100)) / 100))).toLocaleString(undefined, { style: 'currency', currency: this.props.state.currencycode })}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>{this.props.state.basecalcresultdis[item.prod_id] && (this.props.state.basecalcresultdis[item.prod_id].toLocaleString(undefined, percent_obj))}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>{item.sim_discount_pct.toLocaleString(undefined, percent_obj)}</Constant.NPDTableCell>
                                                                <Constant.NPDTableCell align='center' style={{ color: '#4e106f', fontFamily: 'Roboto' }}>{((this.props.state.basecalcresultasp[item.prod_id]) * (1 - item.sim_discount_pct)).toLocaleString(undefined, currency_obj)}</Constant.NPDTableCell>
                                                            </Fragment>
                                                        }
                                                    </Constant.NPDTableRow>
                                            )
                                            )}

                                        </TableBody>

                                    </Constant.NPDTable>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion >
            </div>
        )
    }
}