import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { reducer } from '../PDT/Functions/Utilities/Reducer';

export class MetricsChangesGraph extends Component {

    render() {       
        var currencycode = this.props.state.currencycode

        let current_year_ending = this.props.state.distinctdates_forcalc[this.props.state.distinctdates_forcalc.length - 1]
        let prior_year_ending = this.props.state.distinctdates_prior[this.props.state.distinctdates_prior.length - 1]
        let twoyear_year_ending = this.props.state.distinctdates_last[this.props.state.distinctdates_last.length - 1]
        let threeyear_year_ending = this.props.state.distinctdates_threeyears[this.props.state.distinctdates_threeyears.length - 1]

        function formatDate(datestring) {
            let date = Date.UTC(parseInt(datestring.substring(0, 4)), parseInt(datestring.substring(5, 7)) - 1, parseInt(datestring.substring(8, 10)), 0, 0, 0, 0)
            let dt = new Date(date)
            let offset = dt.getTimezoneOffset() * 60 * 1000
            var dateformatted = (new Date(date + offset)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
            return dateformatted
        }

        let current_year_ending_string = formatDate(current_year_ending)
        let prior_year_ending_string = formatDate(prior_year_ending)
        let twoyear_year_ending_string = formatDate(twoyear_year_ending)
        let threeyear_year_ending_string = formatDate(threeyear_year_ending)

        let numweeks_curr = this.props.state.distinctdates_forcalc.length
        let numweeks_prior = this.props.state.distinctdates_prior.length
        let numweeks_twoyear = this.props.state.distinctdates_last.length
        let numweeks_threeyear = this.props.state.distinctdates_threeyears.length

        var title_string_curr = '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string            
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]

        var title_string       

        if (this.props.state.ThreeYAGO) {
            title_string = '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string
            + ' Compared To '                
            + numweeks_threeyear
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + threeyear_year_ending_string

            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]
        }

        else if (this.props.state.TwoYAGO) {
            title_string = '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string
            + ' Compared To '
            + numweeks_twoyear
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + twoyear_year_ending_string

            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]
        }

        else if (this.props.state.OneYAGO) {
            title_string = '<br>'
            + this.props.state.chart_title_info.split('<br>')[1]
            + '<br>'
            + numweeks_curr
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + current_year_ending_string
            + ' Compared To '
            + numweeks_prior
            + ' '
            + this.props.state.timeperiodlabels
            + 's Ending:'
            + ' '
            + prior_year_ending_string

            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[3]
            + '<br>'
            + this.props.state.chart_title_info.split('<br>')[4]
        }

        //Create Variables to Create the Charts
        var title, title_curr, aggregation, units, units_type,
            vals_to_map, dataset_to_use, dataset_to_use_prior, dates_to_use_prior, dates_to_use,
            metric_type, metric_val, series_var_current, series_var_change, mkt_share,
            yaxis_curr, yaxis_change, datalabels, tooltip_curr, tooltip_change, level_for_share


        dates_to_use = this.props.state.distinctdates_forcalc

        if (this.props.state.ThreeYAGO) {
            dates_to_use_prior = this.props.state.distinctdates_threeyears
        }

        else if (this.props.state.TwoYAGO) {
            dates_to_use_prior = this.props.state.distinctdates_last
        }

        else {
            dates_to_use_prior = this.props.state.distinctdates_prior
        }

        if (this.props.state.channelviews) {
            aggregation = 'Channel'            
            vals_to_map = this.props.state.selectedChannels
            dataset_to_use = this.props.state.outlet_data_weekly_geo
            level_for_share = 'All Channels'

            if (this.props.state.TwoYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_geo_threeyears
            }
            
            else if (this.props.state.TwoYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_geo_last                
            }

            else {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_geo_prior
            }
            
        }

        else {
            aggregation = 'Brand'            
            vals_to_map = this.props.state.selectedBrands
            dataset_to_use = this.props.state.outlet_data_weekly_brand
            level_for_share = 'Total Category'

            if (this.props.state.ThreeYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_brand_threeyears
            }

            else if (this.props.state.TwoYAGO) {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_brand_last
            }

            else {
                dataset_to_use_prior = this.props.state.outlet_data_weekly_brand_prior
            }
        }

        if (this.props.state.indexSwitchCheckDollars) {
            units = this.props.state.currencyType
            metric_val = 'dollars'
        }
        else {
            units = this.props.state.unitsvar
            metric_val = 'units'
        }

        if (this.props.state.sharemetricstotal) {
            units_type = 'Total'
            metric_type = 'total'
        }

        else if (this.props.state.sharemetricsbase) {
            units_type = 'Base'
            metric_type = 'base'
        }

        else if (this.props.state.sharemetricsincremental) {
            units_type = 'Incremental'
            metric_type = 'incremental'
        }

        else if (this.props.state.sharemetricspromoted) {
            units_type = 'Promoted'
            metric_type = 'promoted'
        }

        else if (this.props.state.sharemetricsnonpromoted) {
            units_type = 'Non-Promoted'
            metric_type = 'nonpromoted'
        }

        if (this.props.state.use_market_share) {
            mkt_share = ' Market Share'
        }

        else {
            mkt_share = ""
        }

        title = units_type + ' ' + units + mkt_share + ' by ' + aggregation + title_string 
        title_curr = units_type + ' ' + units + mkt_share + ' by ' + aggregation + title_string_curr
        

        //create series
        series_var_current = []
        series_var_change = []

        let metric_to_use = metric_type + metric_val

        let curr_series_data = []
        if (this.props.state.use_market_share) {

            let vals = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
            let mkt = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))

            curr_series_data = vals.map((val, idx) => val/mkt[idx])
        }
        else {
            curr_series_data = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
        }

        let change_series_data = []
        if (this.props.state.use_market_share) {
            let vals = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
            let mkt = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[0]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
            let prior_series_data = vals.map((val, idx) => val / mkt[idx])

            change_series_data = vals.map((val, idx) => (curr_series_data[idx] - prior_series_data[idx]) * 100)
        }
        else {
            change_series_data = vals_to_map.map(val =>
            (dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer) -
                dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[0]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
            )
        }

        series_var_current.push({
            'name': this.props.state.outlets[0],
            'data': curr_series_data,
            'type': 'bar',
            yAxis: 0,
            color: '#7d328a'
        })

        series_var_change.push({
            'name': this.props.state.outlets[0],
            'data': change_series_data,
            'type': 'bar',
            yAxis: 0,
            color: '#7d328a'
        })

        if (this.props.state.all_outlets.length > 1) {

            let curr_series_data = []
            if (this.props.state.use_market_share) {
                let vals = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                let mkt = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))

                curr_series_data = vals.map((val, idx) => val / mkt[idx])
            }
            else {
                curr_series_data = vals_to_map.map(val => dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
            }

            let change_series_data = []
            if (this.props.state.use_market_share) {
                let vals = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                let mkt = vals_to_map.map(val => dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[1]] || {})[level_for_share] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                let prior_series_data = vals.map((val, idx) => val / mkt[idx])

                change_series_data = vals.map((val, idx) => curr_series_data[idx] - prior_series_data[idx])
            }
            else {
                change_series_data = vals_to_map.map(val =>
                (dates_to_use.sort().map(date => (((((dataset_to_use || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer) -
                    dates_to_use_prior.sort().map(date => (((((dataset_to_use_prior || {})[this.props.state.outlets[1]] || {})[val] || {})[date] || {})[metric_to_use] || 0)).reduce(reducer))
                )
            }

            series_var_current.push({
                'name': this.props.state.outlets[1],
                'data': curr_series_data,
                'type': 'bar',
                yAxis: 0,
                color: '#ff8b6a'
            })

            series_var_change.push({
                'name': this.props.state.outlets[1],
                'data': change_series_data,
                'type': 'bar',
                yAxis: 0,
                color: '#ff8b6a'
            })
        }

        if (this.props.state.use_market_share) {
            yaxis_curr = [
                {
                    gridLineColor: "#CCC",
                    gridLineWidth: 1,
                    title: {
                        text: units_type + ' ' + units + ' Market Share'
                    },
                    labels: {
                        formatter: function () {
                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })

                        }
                    }
                },
            ]

            yaxis_change = [{
                gridLineColor: "#CCC",
                gridLineWidth: 1,
                title: {
                    text: 'Change in ' + units_type + ' ' + units + ' Market Share (pts)'
                },
                labels: {
                    formatter: function () {
                        return this.value.toFixed(1)

                    }
                }
            },]

            datalabels = {
                enabled: true,
                formatter: function () {
                    return this.y.toLocaleString()
                }
            }

            tooltip_curr = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + 'Current Year share: ' + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                }
            }

            tooltip_change = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + 'Change in share: ' + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' pts'
                }
            }
        }

        else if (this.props.state.indexSwitchCheckDollars) {
            yaxis_curr = [
                {
                    gridLineColor: "#CCC",
                    gridLineWidth: 1,
                    title: {
                        text: units_type + ' ' + units
                    },
                    labels: {
                        formatter: function () {
                            function nFormatter(num, digits, axisMax) {
                                var si = [
                                    { value: 1E18, symbol: "E" },
                                    { value: 1E15, symbol: "P" },
                                    { value: 1E12, symbol: "T" },
                                    { value: 1E9, symbol: "B" },
                                    { value: 1E6, symbol: "M" },
                                    { value: 1E3, symbol: "k" }
                                ], i;

                                let symbolForAxis = " "
                                let valueForAxis = 1

                                for (i = 0; i < si.length; i++) {
                                    if (Math.abs(axisMax) >= si[i].value) {
                                        symbolForAxis = si[i].symbol
                                        valueForAxis = si[i].value
                                        break;
                                    }
                                }

                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                            }
                            //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            let value = nFormatter(this.value, 1, Math.max(Math.abs(this.axis.max), Math.abs(this.axis.min)))
                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                            let symbol = value.substring(value.length - 1)
                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                        }
                    }
                },
            ]

            yaxis_change = [{
                gridLineColor: "#CCC",
                gridLineWidth: 1,
                title: {
                    text: 'Change in ' + units_type + ' ' + units
                },
                labels: {
                    formatter: function () {
                        function nFormatter(num, digits, axisMax) {
                            var si = [
                                { value: 1E18, symbol: "E" },
                                { value: 1E15, symbol: "P" },
                                { value: 1E12, symbol: "T" },
                                { value: 1E9, symbol: "B" },
                                { value: 1E6, symbol: "M" },
                                { value: 1E3, symbol: "k" }
                            ], i;

                            let symbolForAxis = " "
                            let valueForAxis = 1

                            for (i = 0; i < si.length; i++) {
                                if (Math.abs(axisMax) >= si[i].value) {
                                    symbolForAxis = si[i].symbol
                                    valueForAxis = si[i].value
                                    break;
                                }
                            }

                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                        }
                        //return (this.point.y || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                        let value = nFormatter(this.value, 1, Math.max(Math.abs(this.axis.max), Math.abs(this.axis.min)))
                        let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                        let symbol = value.substring(value.length - 1)
                        return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                    }
                }
            }]

            datalabels = {
                enabled: true,
                formatter: function () {
                    return this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                }
            }

            tooltip_curr = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                }
            }

            tooltip_change = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                }
            }
        }        
        
        else {
            yaxis_curr = [
                {
                    gridLineColor: "#CCC",
                    gridLineWidth: 1,
                    title: {
                        text: units_type + ' ' + units
                    },
                    labels: {
                        formatter: function () {

                            function nFormatter(num, digits, axisMax) {
                                var si = [
                                    { value: 1E18, symbol: "E" },
                                    { value: 1E15, symbol: "P" },
                                    { value: 1E12, symbol: "T" },
                                    { value: 1E9, symbol: "B" },
                                    { value: 1E6, symbol: "M" },
                                    { value: 1E3, symbol: "k" }
                                ], i;

                                let symbolForAxis = " "
                                let valueForAxis = 1

                                for (i = 0; i < si.length; i++) {
                                    if (Math.abs(axisMax) >= si[i].value) {
                                        symbolForAxis = si[i].symbol
                                        valueForAxis = si[i].value
                                        break;
                                    }
                                }

                                return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                            }
                            return nFormatter(this.value, 1, Math.max(Math.abs(this.axis.max),Math.abs(this.axis.min))).toLocaleString()

                        }
                    }
                },
            ]

            yaxis_change = [{
                gridLineColor: "#CCC",
                gridLineWidth: 1,
                title: {
                    text: 'Change in ' + units_type + ' ' + units
                },
                labels: {
                    formatter: function () {

                        function nFormatter(num, digits, axisMax) {
                            var si = [
                                { value: 1E18, symbol: "E" },
                                { value: 1E15, symbol: "P" },
                                { value: 1E12, symbol: "T" },
                                { value: 1E9, symbol: "B" },
                                { value: 1E6, symbol: "M" },
                                { value: 1E3, symbol: "k" }
                            ], i;

                            let symbolForAxis = " "
                            let valueForAxis = 1

                            for (i = 0; i < si.length; i++) {
                                if (Math.abs(axisMax) >= si[i].value) {
                                    symbolForAxis = si[i].symbol
                                    valueForAxis = si[i].value
                                    break;
                                }
                            }

                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                        }
                        return nFormatter(this.value, 1, Math.max(Math.abs(this.axis.max), Math.abs(this.axis.min))).toLocaleString()

                    }
                }
            },]

            datalabels = {
                enabled: true,
                formatter: function () {
                    return this.y.toLocaleString()
                }
            }

            tooltip_curr = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + this.y.toLocaleString()
                }
            }

            tooltip_change = {
                //pointFormat: '{series.name}: <b>{point}%</b>'
                formatter: function () {
                    return '<span> '
                        + this.series.name
                        + '<br/>'
                        + this.x
                        + '<br/>'
                        + this.y.toLocaleString()
                }
            }
        }


        return (

            <div className="row col-sm-12" style={{ padding: '25px' }} >
                <div className='col-sm-6'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        oneToOne={true}
                        options={
                            {
                                chart: {
                                    //type: 'column'
                                    plotBackgroundColor: null,
                                    plotBorderWidth: null,
                                    plotShadow: false,
                                    type: 'bar',
                                    //16:9 ratio
                                    height: (9 / 16 * 200) + '%',
                                },
                                metadata: this.props.state.chartmetadata,
                                caption: {
                                    text: this.props.state.attr_caption_text
                                },
                                title: {
                                    text: title_curr
                                },
                                series: series_var_current,
                                xAxis: {
                                    title: {
                                        text: ''
                                    },
                                    categories: vals_to_map,
                                },
                                yAxis: yaxis_curr,
                                plotOptions: {
                                    column: {
                                        dataLabels: datalabels
                                    },


                                },
                                tooltip: tooltip_curr,
                                exporting: {
                                    showTable: this.props.state.showDataTable,
                                    tableCaption: false
                                },
                            }}
                    />
                </div>
                <div className='col-sm-6'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        oneToOne={true}
                        options={
                            {
                                chart: {
                                    //type: 'column'
                                    plotBackgroundColor: null,
                                    plotBorderWidth: null,
                                    plotShadow: false,
                                    type: 'bar',
                                    //16:9 ratio
                                    height: (9 / 16 * 200) + '%',
                                },
                                metadata: this.props.state.chartmetadata,
                                caption: {
                                    text: this.props.state.attr_caption_text
                                },
                                title: {
                                    text: 'Change in ' + title
                                },
                                series: series_var_change,
                                xAxis: {
                                    title: {
                                        text: ''
                                    },
                                    categories: vals_to_map,
                                },
                                yAxis: yaxis_change,
                                plotOptions: {
                                    column: {
                                        dataLabels: datalabels
                                    },


                                },
                                tooltip: tooltip_change,
                                exporting: {
                                    showTable: this.props.state.showDataTable,
                                    tableCaption: false
                                },
                            }}
                    />
                </div>
            </div>

        
        )
    }
}