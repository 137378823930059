import React, { Component } from 'react';
import { BrandChannelSwitch } from '../../UIElements/Switches/BrandChannelSwitch'
import { LastYearSwitch } from '../../UIElements/Switches/LastYearSwitch'
import { ScoreCardDownloadMenu } from '../../UIElements/IconButtons/ScoreCardDownloadMenu'
import { ScoreCardDownloadMenuYOY } from '../../UIElements/IconButtons/ScorecardDownloadMenuYOY'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import { SelectionInformation } from '../../UIElements/InfoBoxes/SelectionInformation'
import ScorecardWithYOYSalesMetrics from '../../../Graphs/Scorecard_SalesMetrics'
import ScorecardWithoutYOYSalesMetrics from '../../../Graphs/ScorecardWithoutYOY_SalesMetrics'


export class ScoreCardSalesMetrics extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        //Metrics for Scorecard
        var base_units_curr = this.props.state.base_my
        var base_units_prior = this.props.state.base_my_prior

        var sub_base_units_curr = this.props.state.sub_my
        var sub_base_units_prior = this.props.state.sub_my_prior

        var inc_units_curr = this.props.state.inc_my
        var inc_units_prior = this.props.state.inc_my_prior

        var nonpromo_units_curr = this.props.state.nonpromo_my
        var nonpromo_units_prior = this.props.state.nonpromo_my_prior

        var promo_units_curr = this.props.state.promo_my
        var promo_units_prior = this.props.state.promo_my_prior

        var total_units_curr = base_units_curr + inc_units_curr
        var total_units_prior = base_units_prior + inc_units_prior

        var base_unit_pct_curr = base_units_curr / total_units_curr
        var base_unit_pct_prior = (base_units_prior / total_units_prior) || 0

        var inc_unit_pct_curr = inc_units_curr / total_units_curr
        var inc_unit_pct_prior = (inc_units_prior / total_units_prior) || 0

        var nonpromo_unit_pct_curr = nonpromo_units_curr / total_units_curr
        var nonpromo_unit_pct_prior = (nonpromo_units_prior / total_units_prior) || 0

        var promo_unit_pct_curr = promo_units_curr / total_units_curr
        var promo_unit_pct_prior = (promo_units_prior / total_units_prior) || 0

        var lift_curr = inc_units_curr / base_units_curr
        var lift_prior = (inc_units_prior / base_units_prior) || 0

        var lift_promo_curr = inc_units_curr / sub_base_units_curr
        var lift_promo_prior = (inc_units_prior / sub_base_units_prior) || 0

        var eff_curr = inc_units_curr / promo_units_curr
        var eff_prior = (inc_units_prior / promo_units_prior) || 0

        var avg_base_price_curr = this.props.state.weightedbp_my / this.props.state.total_my
        var avg_base_price_prior = (this.props.state.weightedbp_my_prior / this.props.state.total_my_prior) || 0

        var avg_promo_price_curr = (this.props.state.weightedbp_my / this.props.state.total_my) * (1 - (this.props.state.disc_my_withoutno / this.props.state.total_my_withoutno))
        var avg_promo_price_prior = ((this.props.state.weightedbp_my_prior / this.props.state.total_my_prior) * (1 - (this.props.state.disc_my_withoutno_prior / this.props.state.total_my_withoutno_prior))) || 0

        var avg_disc_curr = Math.min((this.props.state.disc_my / this.props.state.total_my), 1)
        var avg_disc_prior = Math.min((((this.props.state.disc_my_prior / this.props.state.total_my_prior)) || 0), 1)

        var avg_disc_withoutno_curr = Math.min((this.props.state.disc_my_withoutno / this.props.state.total_my_withoutno), 1)
        var avg_disc_withoutno_prior = Math.min(
            (
                (
                    (this.props.state.disc_my_withoutno_prior / this.props.state.total_my_withoutno_prior)
                ) || 0
            ), 1
        )

        var incremental_dollars_curr = this.props.state.incdollars_my
        var incremental_dollars_prior = this.props.state.incdollars_my_prior

        var disc_dollars_curr = this.props.state.discdollars_my
        var disc_dollars_prior = this.props.state.discdollars_my_prior

        var payback_ratio_curr = incremental_dollars_curr / disc_dollars_curr
        var payback_ratio_prior = (incremental_dollars_prior / disc_dollars_prior) || 0

        var total_dollars_curr = this.props.state.totaldollars_my
        var total_dollars_prior = this.props.state.totaldollars_my_prior

        var nonpromoted_dollars_curr = this.props.state.nonpromoteddollars_my
        var nonpromoted_dollars_prior = this.props.state.nonpromoteddollars_my_prior
        var promoted_dollars_curr = this.props.state.promoteddollars_my
        var promoted_dollars_prior = this.props.state.promoteddollars_my_prior
        var base_dollars_curr = this.props.state.basedollars_my
        var base_dollars_prior = this.props.state.basedollars_my_prior
        var incremental_dollars_curr = total_dollars_curr - base_dollars_curr//this.props.state.incrementaldollars_my
        var incremental_dollars_prior = total_dollars_prior - base_dollars_prior //this.props.state.incrementaldollars_my_prior

        var subbasedollars_curr = this.props.state.revopp_my
        var unsubbasedollars_curr = base_dollars_curr - subbasedollars_curr

        var subbasedollars_prior = this.props.state.revopp_my_prior
        var unsubbasedollars_prior = base_dollars_prior - subbasedollars_prior



        var base_units_last = this.props.state.base_my_last
        var sub_base_units_last = this.props.state.sub_my_last
        var unsub_base_units_last = base_units_last - sub_base_units_last
        var unsub_base_units_last_pct = unsub_base_units_last / base_units_last
        var sub_base_units_last_pct = sub_base_units_last / base_units_last
        var inc_units_last = this.props.state.inc_my_last
        var nonpromo_units_last = this.props.state.nonpromo_my_last
        var promo_units_last = this.props.state.promo_my_last
        var total_units_last = base_units_last + inc_units_last
        var base_unit_pct_last = (base_units_last / total_units_last) || 0
        var inc_unit_pct_last = (inc_units_last / total_units_last) || 0
        var nonpromo_unit_pct_last = (nonpromo_units_last / total_units_last) || 0
        var promo_unit_pct_last = (promo_units_last / total_units_last) || 0
        var lift_last = (inc_units_last / base_units_last) || 0
        var lift_promo_last = (inc_units_last / sub_base_units_last) || 0
        var eff_last = (inc_units_last / promo_units_last) || 0
        var avg_base_price_last = (this.props.state.weightedbp_my_last / this.props.state.total_my_last) || 0
        var avg_disc_last = Math.min((((this.props.state.disc_my_last / this.props.state.total_my_last)) || 0), 1)
        var avg_disc_withoutno_last = Math.min(
            (
                (
                    (this.props.state.disc_my_withoutno_last / this.props.state.total_my_withoutno_last)
                ) || 0
            ), 1)

        var nonpromoted_dollars_last = this.props.state.nonpromoteddollars_my_last
        var promoted_dollars_last = this.props.state.promoteddollars_my_last

        var incremental_dollars_last = this.props.state.incdollars_my_last
        var disc_dollars_last = this.props.state.discdollars_my_last
        var payback_ratio_last = (incremental_dollars_last / disc_dollars_last) || 0
        var total_dollars_last = this.props.state.totaldollars_my_last
        var nonpromo_dollars_last = this.props.state.nonpromoteddollars_my_last
        var promo_dollars_last = this.props.state.promoteddollars_my_last
        var base_dollars_last = this.props.state.basedollars_my_last
        var inc_dollars_last = total_dollars_last - base_dollars_last //this.props.state.incrementaldollars_my_last
        var base_dollars_pct_last = (base_dollars_last / total_dollars_last) || 0
        var inc_dollars_pct_last = (inc_dollars_last / total_dollars_last) || 0
        var nonpromo_dollars_pct_last = (nonpromo_dollars_last / total_dollars_last) || 0
        var promo_dollars_pct_last = (promo_dollars_last / total_dollars_last) || 0
        var avg_promo_price_last = promo_dollars_last / promo_units_last
        var subbase_dollars_last = this.props.state.revopp_my_last
        var unsub_base_dollars_last = base_dollars_last - subbase_dollars_last
        var unsub_base_dollars_last_pct = unsub_base_dollars_last / base_dollars_last
        var sub_base_dollars_last_pct = subbase_dollars_last / base_dollars_last
        var avg_nonpromo_price_last = nonpromoted_dollars_last / nonpromo_units_last
        var avg_selling_price_last = total_dollars_last / total_units_last
        var lift_last_dollars = (incremental_dollars_last / base_dollars_last)
        var lift_promo_last_dollars = (incremental_dollars_curr / subbase_dollars_last)
        var eff_last_dollars = (incremental_dollars_last / promoted_dollars_last)

        var subbasedollars_last = this.props.state.revopp_my_last
        var unsubbasedollars_last = base_dollars_last - subbasedollars_last


        var base_units_threeyears = this.props.state.base_my_threeyears
        var sub_base_units_threeyears = this.props.state.sub_my_threeyears
        var unsub_base_units_threeyears = base_units_threeyears - sub_base_units_threeyears
        var unsub_base_units_threeyears_pct = unsub_base_units_threeyears / base_units_threeyears
        var sub_base_units_threeyears_pct = sub_base_units_threeyears / base_units_threeyears
        var inc_units_threeyears = this.props.state.inc_my_threeyears
        var nonpromo_units_threeyears = this.props.state.nonpromo_my_threeyears
        var promo_units_threeyears = this.props.state.promo_my_threeyears
        var total_units_threeyears = base_units_threeyears + inc_units_threeyears
        var base_unit_pct_threeyears = (base_units_threeyears / total_units_threeyears) || 0
        var inc_unit_pct_threeyears = (inc_units_threeyears / total_units_threeyears) || 0
        var nonpromo_unit_pct_threeyears = (nonpromo_units_threeyears / total_units_threeyears) || 0
        var promo_unit_pct_threeyears = (promo_units_threeyears / total_units_threeyears) || 0
        var lift_threeyears = (inc_units_threeyears / base_units_threeyears) || 0
        var lift_promo_threeyears = (inc_units_threeyears / sub_base_units_threeyears) || 0
        var eff_threeyears = (inc_units_threeyears / promo_units_threeyears) || 0
        var avg_base_price_threeyears = (this.props.state.weightedbp_my_threeyears / this.props.state.total_my_threeyears) || 0
        var avg_disc_threeyears = Math.min((((this.props.state.disc_my_threeyears / this.props.state.total_my_threeyears)) || 0), 1)
        var avg_disc_withoutno_threeyears = Math.min(
            (
                (
                    (this.props.state.disc_my_withoutno_threeyears / this.props.state.total_my_withoutno_threeyears)
                ) || 0
            ), 1)

        var nonpromoted_dollars_threeyears = this.props.state.nonpromoteddollars_my_threeyears
        var promoted_dollars_threeyears = this.props.state.promoteddollars_my_threeyears

        var incremental_dollars_threeyears = this.props.state.incdollars_my_threeyears
        var disc_dollars_threeyears = this.props.state.discdollars_my_threeyears
        var payback_ratio_threeyears = (incremental_dollars_threeyears / disc_dollars_threeyears) || 0
        var total_dollars_threeyears = this.props.state.totaldollars_my_threeyears
        var nonpromo_dollars_threeyears = this.props.state.nonpromoteddollars_my_threeyears
        var promo_dollars_threeyears = this.props.state.promoteddollars_my_threeyears
        var base_dollars_threeyears = this.props.state.basedollars_my_threeyears
        var inc_dollars_threeyears = total_dollars_threeyears - base_dollars_threeyears //this.props.state.incrementaldollars_my_threeyears
        var base_dollars_pct_threeyears = (base_dollars_threeyears / total_dollars_threeyears) || 0
        var inc_dollars_pct_threeyears = (inc_dollars_threeyears / total_dollars_threeyears) || 0
        var nonpromo_dollars_pct_threeyears = (nonpromo_dollars_threeyears / total_dollars_threeyears) || 0
        var promo_dollars_pct_threeyears = (promo_dollars_threeyears / total_dollars_threeyears) || 0
        var avg_promo_price_threeyears = promo_dollars_threeyears / promo_units_threeyears
        var subbase_dollars_threeyears = this.props.state.revopp_my_threeyears
        var unsub_base_dollars_threeyears = base_dollars_threeyears - subbase_dollars_threeyears
        var unsub_base_dollars_threeyears_pct = unsub_base_dollars_threeyears / base_dollars_threeyears
        var sub_base_dollars_threeyears_pct = subbase_dollars_threeyears / base_dollars_threeyears
        var avg_nonpromo_price_threeyears = nonpromoted_dollars_threeyears / nonpromo_units_threeyears
        var avg_selling_price_threeyears = total_dollars_threeyears / total_units_threeyears
        var lift_threeyears_dollars = (incremental_dollars_threeyears / base_dollars_threeyears)
        var lift_promo_threeyears_dollars = (incremental_dollars_curr / subbase_dollars_threeyears)
        var eff_threeyears_dollars = (incremental_dollars_threeyears / promoted_dollars_threeyears)

        var subbasedollars_threeyears = this.props.state.revopp_my_threeyears
        var unsubbasedollars_threeyears = base_dollars_threeyears - subbasedollars_threeyears

        //Without YOY

        var base_units_curr_withoutYOY = this.props.state.base_my_withoutYOY
        var sub_base_units_curr_withoutYOY = this.props.state.sub_my_withoutYOY
        var inc_units_curr_withoutYOY = this.props.state.inc_my_withoutYOY
        var nonpromo_units_curr_withoutYOY = this.props.state.nonpromo_my_withoutYOY
        var promo_units_curr_withoutYOY = this.props.state.promo_my_withoutYOY
        var total_units_curr_withoutYOY = base_units_curr_withoutYOY + inc_units_curr_withoutYOY
        var base_unit_pct_curr_withoutYOY = base_units_curr_withoutYOY / total_units_curr_withoutYOY
        var inc_unit_pct_curr_withoutYOY = inc_units_curr_withoutYOY / total_units_curr_withoutYOY
        var nonpromo_unit_pct_curr_withoutYOY = nonpromo_units_curr_withoutYOY / total_units_curr_withoutYOY
        var promo_unit_pct_curr_withoutYOY = promo_units_curr_withoutYOY / total_units_curr_withoutYOY
        var lift_curr_withoutYOY = inc_units_curr_withoutYOY / base_units_curr_withoutYOY
        var lift_promo_curr_withoutYOY = inc_units_curr_withoutYOY / sub_base_units_curr_withoutYOY
        var eff_curr_withoutYOY = inc_units_curr_withoutYOY / promo_units_curr_withoutYOY

        var avg_base_price_curr_withoutYOY = this.props.state.weightedbp_my_withoutYOY / this.props.state.total_my_withoutYOY
        var avg_promo_price_curr_withoutYOY = (this.props.state.weightedbp_my_withoutYOY / this.props.state.total_my_withoutYOY) * (1 - (this.props.state.disc_my_withoutYOY_withoutno / this.props.state.total_my_withoutYOY_withoutno))
        var avg_disc_curr_withoutYOY = Math.min((this.props.state.disc_my_withoutYOY / this.props.state.total_my_withoutYOY), 1)
        var avg_disc_withoutno_curr_withoutYOY = Math.min((this.props.state.disc_my_withoutYOY_withoutno / this.props.state.total_my_withoutYOY_withoutno), 1)
        var incremental_dollars_curr_withoutYOY = this.props.state.incdollars_my_withoutYOY
        var disc_dollars_curr_withoutYOY = this.props.state.discdollars_my_withoutYOY
        var payback_ratio_curr_withoutYOY = incremental_dollars_curr_withoutYOY / disc_dollars_curr_withoutYOY
        var total_dollars_curr_withoutYOY = this.props.state.totaldollars_my_withoutYOY
        var nonpromoted_dollars_curr_withoutYOY = this.props.state.nonpromoteddollars_my_withoutYOY
        var promoted_dollars_curr_withoutYOY = this.props.state.promoteddollars_my_withoutYOY
        var base_dollars_curr_withoutYOY = this.props.state.basedollars_my_withoutYOY
        var incremental_dollars_curr_withoutYOY = total_dollars_curr_withoutYOY - base_dollars_curr_withoutYOY//this.props.state.incrementaldollars_my_withoutYOY
        var subbasedollars_curr_withoutYOY = this.props.state.revopp_my_withoutYOY
        var unsubbasedollars_curr_withoutYOY = base_dollars_curr_withoutYOY - subbasedollars_curr_withoutYOY

        return (

            <div style={{ width: "100%", height: "100%", backgroundColor: '#bfbfbf', borderRadius: '10px', padding: '20px', boxShadow: '10px 10px 5px rgba(0, 0, 0, .1)' }}>
                <div className="row">
                    
                    <div className="col-sm-12" align='left'>
                        <LastYearSwitch
                            state={this.props.state}
                            handleChange_twoyago={this.props.handleChange_twoyago}
                        />
                    </div>

                    <div className="col-sm-12">
                        {/* <FilterBarReportViews
                            state={this.props.state}
                            changeViewFilters={this.props.changeViewFilters}
                            handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                            changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                        /> */}
                        <FilterBarReportViewsFunctional />
                    </div>
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        {this.props.state.showYOYViews ?
                            <div className="row" id="Scorecard" style={{ borderRadius: '0px', borderColor: '#3f3f3f', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div align='left' className="col-sm-11" >
                                            <h3>Key Metrics for {(this.props.state.channelviews === true ? this.props.state.selectedChannel : this.props.state.selectedBrandLabel) + ' - ' + this.props.state.distinctdates_forcalc.length + ' ' + this.props.state.timeperiodlabels + 's Current Year vs ' + (this.props.state.ThreeYAGO === true ? ' Three Years Ago ' : this.props.state.TwoYAGO === true ? ' Two Years Ago ' : 'Prior Year')}</h3>
                                        </div>
                                        <div align='right' className="col-sm-1" >
                                            <ScoreCardDownloadMenu
                                                state={this.props.state}
                                                openScoreCardMenu={this.props.openScoreCardMenu}
                                                closeScoreCardMenu={this.props.closeScoreCardMenu}
                                                downloadScoreCardData={this.props.downloadScoreCardData}
                                                downloadScoreCardDataYOY_All={this.props.downloadScoreCardDataYOY_All}
                                            />
                                        </div>
                                    </div>

                                    <SelectionInformation
                                        state={this.props.state}
                                    />
                                    
                                    {this.props.state.showLastViews ?
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {this.props.state.ThreeYAGO ?
                                                    <ScorecardWithYOYSalesMetrics
                                                        unitsvar={this.props.state.unitsvar}
                                                        base_units_curr={base_units_curr}
                                                        base_units_prior={base_units_threeyears}
                                                        sub_base_units_curr={sub_base_units_curr}
                                                        sub_base_units_prior={sub_base_units_threeyears}
                                                        inc_units_curr={inc_units_curr}
                                                        inc_units_prior={inc_units_threeyears}
                                                        base_unit_pct_curr={base_unit_pct_curr}
                                                        base_unit_pct_prior={base_unit_pct_threeyears}
                                                        inc_unit_pct_curr={inc_unit_pct_curr}
                                                        inc_unit_pct_prior={inc_unit_pct_threeyears}
                                                        nonpromo_units_curr={nonpromo_units_curr}
                                                        nonpromo_units_prior={nonpromo_units_threeyears}
                                                        promo_units_curr={promo_units_curr}
                                                        promo_units_prior={promo_units_threeyears}
                                                        nonpromo_unit_pct_curr={nonpromo_unit_pct_curr}
                                                        nonpromo_unit_pct_prior={nonpromo_unit_pct_threeyears}
                                                        promo_unit_pct_curr={promo_unit_pct_curr}
                                                        promo_unit_pct_prior={promo_unit_pct_threeyears}
                                                        avg_base_price_curr={avg_base_price_curr}
                                                        avg_base_price_prior={avg_base_price_threeyears}
                                                        avg_promo_price_curr={avg_promo_price_curr}
                                                        avg_promo_price_prior={avg_promo_price_threeyears}
                                                        avg_disc_curr={avg_disc_curr}
                                                        avg_disc_prior={avg_disc_threeyears}
                                                        avg_disc_withoutno_curr={avg_disc_withoutno_curr}
                                                        avg_disc_withoutno_prior={avg_disc_withoutno_threeyears}
                                                        disc_dollars_curr={disc_dollars_curr}
                                                        disc_dollars_prior={disc_dollars_threeyears}
                                                        lift_curr={lift_curr}
                                                        lift_prior={lift_threeyears}
                                                        lift_promo_curr={lift_promo_curr}
                                                        lift_promo_prior={lift_promo_threeyears}
                                                        eff_curr={eff_curr}
                                                        eff_prior={eff_threeyears}
                                                        payback_ratio_curr={payback_ratio_curr}
                                                        payback_ratio_prior={payback_ratio_threeyears}
                                                        total_dollars_curr={total_dollars_curr}
                                                        total_dollars_prior={total_dollars_threeyears}
                                                        nonpromoted_dollars_curr={nonpromoted_dollars_curr}
                                                        nonpromoted_dollars_prior={nonpromoted_dollars_threeyears}
                                                        promoted_dollars_curr={promoted_dollars_curr}
                                                        promoted_dollars_prior={promoted_dollars_threeyears}
                                                        base_dollars_curr={base_dollars_curr}
                                                        base_dollars_prior={base_dollars_threeyears}
                                                        incremental_dollars_curr={incremental_dollars_curr}
                                                        incremental_dollars_prior={incremental_dollars_threeyears}
                                                        subbasedollars_curr={subbasedollars_curr}
                                                        unsubbasedollars_curr={unsubbasedollars_curr}
                                                        subbasedollars_prior={subbasedollars_threeyears}
                                                        unsubbasedollars_prior={unsubbasedollars_threeyears}
                                                        currencycode={this.props.state.currencycode}
                                                        currencytype={this.props.state.currencyType}
                                                        appname={'pdt'}
                                                    />
                                                    :
                                                    this.props.state.TwoYAGO ?
                                                        <ScorecardWithYOYSalesMetrics
                                                            unitsvar={this.props.state.unitsvar}
                                                            base_units_curr={base_units_curr}
                                                            base_units_prior={base_units_last}
                                                            sub_base_units_curr={sub_base_units_curr}
                                                            sub_base_units_prior={sub_base_units_last}
                                                            inc_units_curr={inc_units_curr}
                                                            inc_units_prior={inc_units_last}
                                                            base_unit_pct_curr={base_unit_pct_curr}
                                                            base_unit_pct_prior={base_unit_pct_last}
                                                            inc_unit_pct_curr={inc_unit_pct_curr}
                                                            inc_unit_pct_prior={inc_unit_pct_last}
                                                            nonpromo_units_curr={nonpromo_units_curr}
                                                            nonpromo_units_prior={nonpromo_units_last}
                                                            promo_units_curr={promo_units_curr}
                                                            promo_units_prior={promo_units_last}
                                                            nonpromo_unit_pct_curr={nonpromo_unit_pct_curr}
                                                            nonpromo_unit_pct_prior={nonpromo_unit_pct_last}
                                                            promo_unit_pct_curr={promo_unit_pct_curr}
                                                            promo_unit_pct_prior={promo_unit_pct_last}
                                                            avg_base_price_curr={avg_base_price_curr}
                                                            avg_base_price_prior={avg_base_price_last}
                                                            avg_promo_price_curr={avg_promo_price_curr}
                                                            avg_promo_price_prior={avg_promo_price_last}
                                                            avg_disc_curr={avg_disc_curr}
                                                            avg_disc_prior={avg_disc_last}
                                                            avg_disc_withoutno_curr={avg_disc_withoutno_curr}
                                                            avg_disc_withoutno_prior={avg_disc_withoutno_last}
                                                            disc_dollars_curr={disc_dollars_curr}
                                                            disc_dollars_prior={disc_dollars_last}
                                                            lift_curr={lift_curr}
                                                            lift_prior={lift_last}
                                                            lift_promo_curr={lift_promo_curr}
                                                            lift_promo_prior={lift_promo_last}
                                                            eff_curr={eff_curr}
                                                            eff_prior={eff_last}
                                                            payback_ratio_curr={payback_ratio_curr}
                                                            payback_ratio_prior={payback_ratio_last}
                                                            total_dollars_curr={total_dollars_curr}
                                                            total_dollars_prior={total_dollars_last}
                                                            nonpromoted_dollars_curr={nonpromoted_dollars_curr}
                                                            nonpromoted_dollars_prior={nonpromoted_dollars_last}
                                                            promoted_dollars_curr={promoted_dollars_curr}
                                                            promoted_dollars_prior={promoted_dollars_last}
                                                            base_dollars_curr={base_dollars_curr}
                                                            base_dollars_prior={base_dollars_last}
                                                            incremental_dollars_curr={incremental_dollars_curr}
                                                            incremental_dollars_prior={incremental_dollars_last}
                                                            subbasedollars_curr={subbasedollars_curr}
                                                            unsubbasedollars_curr={unsubbasedollars_curr}
                                                            subbasedollars_prior={subbasedollars_last}
                                                            unsubbasedollars_prior={unsubbasedollars_last}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            appname={'pdt'}
                                                        />
                                                        :
                                                        <ScorecardWithYOYSalesMetrics
                                                            unitsvar={this.props.state.unitsvar}
                                                            base_units_curr={base_units_curr}
                                                            base_units_prior={base_units_prior}
                                                            sub_base_units_curr={sub_base_units_curr}
                                                            sub_base_units_prior={sub_base_units_prior}
                                                            inc_units_curr={inc_units_curr}
                                                            inc_units_prior={inc_units_prior}
                                                            base_unit_pct_curr={base_unit_pct_curr}
                                                            base_unit_pct_prior={base_unit_pct_prior}
                                                            inc_unit_pct_curr={inc_unit_pct_curr}
                                                            inc_unit_pct_prior={inc_unit_pct_prior}
                                                            nonpromo_units_curr={nonpromo_units_curr}
                                                            nonpromo_units_prior={nonpromo_units_prior}
                                                            promo_units_curr={promo_units_curr}
                                                            promo_units_prior={promo_units_prior}
                                                            nonpromo_unit_pct_curr={nonpromo_unit_pct_curr}
                                                            nonpromo_unit_pct_prior={nonpromo_unit_pct_prior}
                                                            promo_unit_pct_curr={promo_unit_pct_curr}
                                                            promo_unit_pct_prior={promo_unit_pct_prior}
                                                            avg_base_price_curr={avg_base_price_curr}
                                                            avg_base_price_prior={avg_base_price_prior}
                                                            avg_promo_price_curr={avg_promo_price_curr}
                                                            avg_promo_price_prior={avg_promo_price_prior}
                                                            avg_disc_curr={avg_disc_curr}
                                                            avg_disc_prior={avg_disc_prior}
                                                            avg_disc_withoutno_curr={avg_disc_withoutno_curr}
                                                            avg_disc_withoutno_prior={avg_disc_withoutno_prior}
                                                            disc_dollars_curr={disc_dollars_curr}
                                                            disc_dollars_prior={disc_dollars_prior}
                                                            lift_curr={lift_curr}
                                                            lift_prior={lift_prior}
                                                            lift_promo_curr={lift_promo_curr}
                                                            lift_promo_prior={lift_promo_prior}
                                                            eff_curr={eff_curr}
                                                            eff_prior={eff_prior}
                                                            payback_ratio_curr={payback_ratio_curr}
                                                            payback_ratio_prior={payback_ratio_prior}
                                                            total_dollars_curr={total_dollars_curr}
                                                            total_dollars_prior={total_dollars_prior}
                                                            nonpromoted_dollars_curr={nonpromoted_dollars_curr}
                                                            nonpromoted_dollars_prior={nonpromoted_dollars_prior}
                                                            promoted_dollars_curr={promoted_dollars_curr}
                                                            promoted_dollars_prior={promoted_dollars_prior}
                                                            base_dollars_curr={base_dollars_curr}
                                                            base_dollars_prior={base_dollars_prior}
                                                            incremental_dollars_curr={incremental_dollars_curr}
                                                            incremental_dollars_prior={incremental_dollars_prior}
                                                            subbasedollars_curr={subbasedollars_curr}
                                                            unsubbasedollars_curr={unsubbasedollars_curr}
                                                            subbasedollars_prior={subbasedollars_prior}
                                                            unsubbasedollars_prior={unsubbasedollars_prior}
                                                            currencycode={this.props.state.currencycode}
                                                            currencytype={this.props.state.currencyType}
                                                            appname={'pdt'}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        :
                                        <ScorecardWithYOYSalesMetrics
                                            unitsvar={this.props.state.unitsvar}
                                            base_units_curr={base_units_curr}
                                            base_units_prior={base_units_prior}
                                            sub_base_units_curr={sub_base_units_curr}
                                            sub_base_units_prior={sub_base_units_prior}
                                            inc_units_curr={inc_units_curr}
                                            inc_units_prior={inc_units_prior}
                                            base_unit_pct_curr={base_unit_pct_curr}
                                            base_unit_pct_prior={base_unit_pct_prior}
                                            inc_unit_pct_curr={inc_unit_pct_curr}
                                            inc_unit_pct_prior={inc_unit_pct_prior}
                                            nonpromo_units_curr={nonpromo_units_curr}
                                            nonpromo_units_prior={nonpromo_units_prior}
                                            promo_units_curr={promo_units_curr}
                                            promo_units_prior={promo_units_prior}
                                            nonpromo_unit_pct_curr={nonpromo_unit_pct_curr}
                                            nonpromo_unit_pct_prior={nonpromo_unit_pct_prior}
                                            promo_unit_pct_curr={promo_unit_pct_curr}
                                            promo_unit_pct_prior={promo_unit_pct_prior}
                                            avg_base_price_curr={avg_base_price_curr}
                                            avg_base_price_prior={avg_base_price_prior}
                                            avg_promo_price_curr={avg_promo_price_curr}
                                            avg_promo_price_prior={avg_promo_price_prior}
                                            avg_disc_curr={avg_disc_curr}
                                            avg_disc_prior={avg_disc_prior}
                                            avg_disc_withoutno_curr={avg_disc_withoutno_curr}
                                            avg_disc_withoutno_prior={avg_disc_withoutno_prior}
                                            disc_dollars_curr={disc_dollars_curr}
                                            disc_dollars_prior={disc_dollars_prior}
                                            lift_curr={lift_curr}
                                            lift_prior={lift_prior}
                                            lift_promo_curr={lift_promo_curr}
                                            lift_promo_prior={lift_promo_prior}
                                            eff_curr={eff_curr}
                                            eff_prior={eff_prior}
                                            payback_ratio_curr={payback_ratio_curr}
                                            payback_ratio_prior={payback_ratio_prior}
                                            total_dollars_curr={total_dollars_curr}
                                            total_dollars_prior={total_dollars_prior}
                                            nonpromoted_dollars_curr={nonpromoted_dollars_curr}
                                            nonpromoted_dollars_prior={nonpromoted_dollars_prior}
                                            promoted_dollars_curr={promoted_dollars_curr}
                                            promoted_dollars_prior={promoted_dollars_prior}
                                            base_dollars_curr={base_dollars_curr}
                                            base_dollars_prior={base_dollars_prior}
                                            incremental_dollars_curr={incremental_dollars_curr}
                                            incremental_dollars_prior={incremental_dollars_prior}
                                            subbasedollars_curr={subbasedollars_curr}
                                            unsubbasedollars_curr={unsubbasedollars_curr}
                                            subbasedollars_prior={subbasedollars_prior}
                                            unsubbasedollars_prior={unsubbasedollars_prior}
                                            currencycode={this.props.state.currencycode}
                                            currencytype={this.props.state.currencyType}
                                            appname={'pdt'}
                                        />
                                    }
                                </div>
                            </div>
                            :
                            <div className="row" id="ScorecardWithoutYOY" style={{ borderRadius: '0px', borderColor: '#3f3f3f', borderWidth: '0px', borderStyle: 'groove', marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div align='left' className="col-sm-11" >
                                            <h3>Key Metrics for {(this.props.state.channelviews === true ? this.props.state.selectedChannel : this.props.state.selectedBrandLabel) + ' - ' + this.props.state.distinctdates_forcalc.length + ' ' + this.props.state.timeperiodlabels + 's Current Year'}
                                            </h3>
                                        </div>

                                        <div align='right' className="col-sm-1" >
                                            <ScoreCardDownloadMenuYOY
                                                state={this.props.state}
                                                openScoreCardMenuYOY={this.props.openScoreCardMenuYOY}
                                                closeScoreCardMenuYOY={this.props.closeScoreCardMenuYOY}
                                                downloadScoreCardDataYOY={this.props.downloadScoreCardDataYOY}
                                                downloadScoreCardDataYOY_All={this.props.downloadScoreCardDataYOY_All}
                                            />
                                        </div>
                                    </div>

                                    <SelectionInformation
                                        state={this.props.state}
                                    />

                                    <ScorecardWithoutYOYSalesMetrics
                                        unitsvar={this.props.state.unitsvar}
                                        base_units_curr={base_units_curr_withoutYOY}
                                        sub_base_units_curr={sub_base_units_curr_withoutYOY}
                                        inc_units_curr={inc_units_curr_withoutYOY}
                                        base_unit_pct_curr={base_unit_pct_curr_withoutYOY}
                                        inc_unit_pct_curr={inc_unit_pct_curr_withoutYOY}
                                        nonpromo_units_curr={nonpromo_units_curr_withoutYOY}
                                        promo_units_curr={promo_units_curr_withoutYOY}
                                        nonpromo_unit_pct_curr={nonpromo_unit_pct_curr_withoutYOY}
                                        promo_unit_pct_curr={promo_unit_pct_curr_withoutYOY}
                                        avg_base_price_curr={avg_base_price_curr_withoutYOY}
                                        avg_promo_price_curr={avg_promo_price_curr_withoutYOY}
                                        avg_disc_curr={avg_disc_curr_withoutYOY}
                                        avg_disc_withoutno_curr={avg_disc_withoutno_curr_withoutYOY}
                                        disc_dollars_curr={disc_dollars_curr_withoutYOY}
                                        lift_curr={lift_curr_withoutYOY}
                                        lift_promo_curr={lift_promo_curr_withoutYOY}
                                        eff_curr={eff_curr_withoutYOY}
                                        payback_ratio_curr={payback_ratio_curr_withoutYOY}
                                        total_dollars_curr={total_dollars_curr_withoutYOY}
                                        nonpromoted_dollars_curr={nonpromoted_dollars_curr_withoutYOY}
                                        promoted_dollars_curr={promoted_dollars_curr_withoutYOY}
                                        base_dollars_curr={base_dollars_curr_withoutYOY}
                                        incremental_dollars_curr={incremental_dollars_curr_withoutYOY}
                                        subbasedollars_curr={subbasedollars_curr_withoutYOY}
                                        unsubbasedollars_curr={unsubbasedollars_curr_withoutYOY}
                                        currencycode={this.props.state.currencycode}
                                        currencytype={this.props.state.currencyType}
                                        appname={'pdt'}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}