import React, { Component } from 'react';
import './styles/custom.css'
import { Layout } from './Layout';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Select, { components } from 'react-select'
import Tooltip from '@material-ui/core/Tooltip';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
//Test

const GreenCheckbox = withStyles({
    root: {
        color: '#565a5c',
        '&$checked': {
            color: '#ef2a79',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const Option = (props) => {
    console.log(props)
    console.log(props.data.label)
    let titlestring = 'Customise your option component!' + props.data.label
    return (        
        <Tooltip title={titlestring} placement="right" {...props}>
            <div>
                <components.Option {...props} />
            </div>
        </Tooltip>
    );
};

const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', disabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];

export class Test extends Component {
    static displayName = Test.name;

    constructor(props) {
        super(props);        
        this.state = {
            checked: false,
            checked2: true,
            Datevalues: null
        }
    }

    async componentDidMount() {
    }

    handleChange = (event) => {
        console.log("I'm Clicked")
        this.setState({
            checked: !this.state.checked,
            checked2: !this.state.checked2
        })
    }

    handleChangeDates = (event) => {
        console.log(event)
        this.setState({ Datevalues: event })        
    }

    onChangeFunc = (selectedOptions) => {
        console.log(selectedOptions)
        console.log('This is a Test')
    }

    

    render() {
        console.log(this.state)
        console.log('Test')
        return (
            <Layout title="Test">
                <div name="Test" style={{ width: '100%', marginLeft: '120px', backgroundColor: '#F4F4F4' }}>
                    <div style={{ height: '50px' }} />
                    <div className="row">
                        <div className="col-sm-3">
                            <span className="formfieldtitle" style={{ padding: '10px' }}> Label</span>
                            <br/>
                            <GreenCheckbox
                                checked={this.state.checked}
                                onChange={this.handleChange}
                            />

                            <Checkbox
                                checked={this.state.checked2}
                                onChange={this.handleChange}
                            />

                            <Select
                                id="TimeAgg"
                                options={[{ 'label': 'Weekly', 'value': 'Weekly' }, { 'label': 'Monthly', 'value': 'Monthly' }]}
                                placeholder={'Select Time Aggregation'}
                                components={{ Option }}
                                styles={{ option: (base) => ({ ...base, border: `1px dotted ${colourOptions[2].color}`, height: '100%' }) }}
                                onChange={this.onChangeFunc}
                            />

                        </div>
                        
                        
                        
                    </div>

                    <div className="col-sm-3" style={{ backgroundColor: '#FFFFFF', padding:'0px' }}>
                        <DateRangePicker
                            onChange={this.handleChangeDates}
                            value={this.state.Datevalues}
                            calendarIcon={<DateRangeRoundedIcon style={{ color: '#4e106f' }} />}
                            clearIcon={<ClearRoundedIcon style={{ color: '#822433' }} />}
                        />
                    </div>

                    <div style={{ height: '50px' }} />
                </div>
            </Layout>
        );
    }
}