import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';

export class MetricsComparisonsToggle extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <IconButton
                    className="btn btn-primary ChangeDataTableButton"
                    onClick={this.props.moveviewdown}
                    style={{
                        padding: '5px', margin: '5px'
                    }}
                    id='MoveViewDown'
                >
                    < SkipPreviousRoundedIcon />
                </IconButton>
                <IconButton
                    className="btn btn-primary ChangeDataTableButton"
                    onClick={this.props.moveviewup}
                    style={{
                        padding: '5px', margin: '5px'
                    }}
                    id='MoveViewUp'
                >
                    <SkipNextRoundedIcon />
                </IconButton>
            </Fragment>
        )
    }
}