import * as Constant from '../constants'

export function handleCloseUserInfo() {
    this.setState({ showuserinfo: false })
}

export function handleCloseUserPerms() {
    this.setState({ viewuserperms: false })
}

export function handleCloseNewUser() {
    this.setState({ showuserinfo_new: false })
}

export function handleCloseNewUsers() {
    this.setState({ showuserinfo_new_multiple: false })
}

export function handleCloseUsersITTickets() {
    this.setState({ showittickets: false })
}

export function handleOpenNewUser() {
    this.setState({
        userinfodata: {},
        userinfoName: null,
        userinfoEmail: null,
        userinfoCompanyId: null,
        userinfoLogin: null,
        showuserinfo_new: true
    })
}

export function handleChangePageUser(event, newPage, type) {
    if (type === 'pnpdatasetperms') {
        this.setState({
            page_user_pnp_dataset_perms: newPage,
            page_start_user_pnp_dataset_perms: newPage * this.state.rowsPerPage_user_pnp_dataset_perms
        })
    } else if (type === 'pdtdatasetperms') {
        this.setState({
            page_user_pdt_dataset_perms: newPage,
            page_start_user_pdt_dataset_perms: newPage * this.state.rowsPerPage_user_pdt_dataset_perms
        })
    }
}

export function handleChangeRowsPerPageUser(event, type) {
    if (type === 'pnpdatasetperms') {
        this.setState({
            rowsPerPage_user_pnp_dataset_perms: parseInt(event.target.value, 10),
            page_user_pnp_dataset_perms: 0,
            page_start_user_pnp_dataset_perms: 0,
        })
    } else if (type === 'pdtdatasetperms') {
        this.setState({
            rowsPerPage_user_pdt_dataset_perms: parseInt(event.target.value, 10),
            page_user_pdt_dataset_perms: 0,
            page_start_user_pdt_dataset_perms: 0
        })
    }
}

export function handleChangeUserInfoCheckboxEnabled(event, newValue) {
    console.log(this.state.userinfoLogin)
    var userinfoLoginenabled = this.state.userinfoLogin
    var newuserinfoLoginenabled
    if (userinfoLoginenabled) {
        newuserinfoLoginenabled = false
    } else {
        newuserinfoLoginenabled = true
    }
    this.setState({ userinfoLogin: newuserinfoLoginenabled })
}

export function handleChangeUserInfoEmail(event, newValue) {
    var input = document.getElementById("userinfoEmail")
    let value = input.value
    console.log(value)
    this.setState({ userinfoEmail: value })
}

export function handleChangeUserInfoGroup(selectedOptions) {
    console.log(selectedOptions)
    var groupid = selectedOptions['value']
    this.setState({ userinfoGroupId: groupid })
}

export async function handleSubmitUser() {
    this.setState({ user_edit_button_enabled: false })

    let userid = this.state.userinfodata.user_id
    var userinfo = {
        user_id: userid,
        time_updated: this.state.userinfodata.time_updated,
        user_name: this.state.userinfoName,
        email: this.state.userinfoEmail,
        company_id: this.state.userinfoCompanyId,
        login_allowed: this.state.userinfoLogin,
    }

    let results = await this.entityCrud('updating user ID ' + userid, [
        { 'url': Constant.baseapiurl_gen + 'Users/' + userid, 'method': 'PUT', 'body': userinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchUserList()
        this.handleCloseUserInfo()
        alert('Changes Made Successfully')
    }

    this.setState({ user_edit_button_enabled: true })
}

export async function handleSubmitUserNew() {
    this.setState({ user_edit_button_enabled: false })

    var userinfo = {
        user_name: this.state.userinfoName,
        email: this.state.userinfoEmail,
        company_id: this.state.userinfoCompanyId,
        login_allowed: this.state.userinfoLogin,
    }

    let results = await this.entityCrud('creating new user', [
        { 'url': Constant.baseapiurl_gen + 'Users', 'method': 'POST', 'body': userinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchUserList()
        this.handleCloseNewUser()
        alert('Changes Made Successfully')
    }

    this.setState({ user_edit_button_enabled: true })
}

export async function handleDeleteUser() {
    this.setState({ user_delete_button_enabled: false })

    let results = await this.entityCrud('creating new user', [
        { 'url': Constant.baseapiurl_gen + 'Users/' + this.state.userinfodata.user_id, 'method': 'DELETE', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchUserList()
        this.handleCloseUserInfo()
        alert('Configuration Deleted Successfully')
    }

    this.setState({ user_delete_button_enabled: true })
}

export function handleOpenUsersITTickets() {
    this.setState({
        selectedUsers_ITTickets: [{ 'username': null, 'email': null, 'userrole': null, 'iprestriction': null }],
        showittickets: true
    })
}

export function addNewUserITTickets() {
    let selectedUsers_ITTickets = this.state.selectedUsers_ITTickets
    selectedUsers_ITTickets.push({ 'userid': null, 'username': null, 'userrole': null, 'iprestriction': null })
    this.setState({ selectedUsers_ITTickets })
}

export function removeUserITTickets(idx) {
    let selectedUsers_ITTickets = this.state.selectedUsers_ITTickets
    selectedUsers_ITTickets.splice(idx, 1)
    this.setState({ selectedUsers_ITTickets })
}

export function handleChangeUserInfoITTickets(selectedOptions, infotype, idx) {
    console.log(selectedOptions)
    console.log(infotype)
    console.log(idx)
    var selectedUsers_ITTickets = this.state.selectedUsers_ITTickets
    let userid = selectedUsers_ITTickets[idx].userid
    let username = selectedUsers_ITTickets[idx].username
    let userrole = selectedUsers_ITTickets[idx].userrole
    let iprestriction = selectedUsers_ITTickets[idx].iprestriction

    if (infotype === 'user') {
        userid = selectedOptions.value
        username = selectedOptions.label
    } else if (infotype === 'role') {
        userrole = selectedOptions.value
    } else if (infotype === 'ip') {
        iprestriction = selectedOptions.value
    }

    selectedUsers_ITTickets[idx] = { 'userid': userid, 'username': username, 'userrole': userrole, 'iprestriction': iprestriction }

    this.setState({ selectedUsers_ITTickets })
}

export async function submitITTickets() {
    console.log('submit IT Tickets invoked')

    var requests = []
    this.state.selectedUsers_ITTickets.forEach((user, idx) => {
        requests.push({ 'url': Constant.baseapiurl_gen + 'Users/' + user.userid, 'method': 'GET', 'body': null })
    })
    let results = await this.entityCrud('user information for IT ticket', requests, this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        let all_results = results['ResultList']
        var rows = all_results.map((userinfodata, idx) => {
            var user = this.state.selectedUsers_ITTickets[idx]
            let usernameparts = userinfodata.user_name.split(' ')
            if (usernameparts.length == 1) {
                usernameparts.push('')
            } else if (usernameparts.length > 2) {
                usernameparts[1] = usernameparts.slice(1).join(' ')
            }

            return [
                { type: 'string', value: this.state.company_lookup[userinfodata.company_id] },
                { type: 'string', value: userinfodata.email },
                { type: 'string', value: usernameparts[1] },
                { type: 'string', value: usernameparts[0] },
                { type: 'string', value: userinfodata.email },
                { type: 'string', value: user.userrole },
                { type: 'string', value: user.iprestriction }
            ]
        })

        var headers = [[
            { type: 'string', value: 'Company' },
            { type: 'string', value: 'User ID' },
            { type: 'string', value: 'Last Name' },
            { type: 'string', value: 'First Name' },
            { type: 'string', value: 'E-mail' },
            { type: 'string', value: 'NPD Price and Promotion Role (User, Power User, Admin)' },
            { type: 'string', value: 'IP Restriction Needed (user has ALR access)' }
        ]]
        var xlsxRows = headers.concat(rows)

        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        var newdate = new Date()
        var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
        datestring = datestring.replaceAll(',', '')
        var spreadsheet = {
            filename: 'User Tickets - Extracted - ' + datestring,
            sheet: {
                data: xlsxRows
            }
        }

        console.log('Creating spreadsheet with the following content:')
        console.log(spreadsheet)
        window.zipcelx(spreadsheet)
    }
}

export function addNewUserMultiple() {
    let newusers = this.state.newusers
    newusers.push({ 'username': null, 'email': null })
    this.setState({ newusers })
}

export function removeUserMultiple(idx) {
    let newusers = this.state.newusers
    newusers.splice(idx, 1)
    this.setState({ newusers })
}

export function handleChangeUserInfoMultiple(infotype, idx) {
    console.log(infotype)
    console.log(idx)
    var id, input, value
    var newusers = this.state.newusers
    if (infotype === 'username') {
        id = "userinfoNameMultiple_" + idx
        input = document.getElementById(id)
        value = input.value
        newusers[idx] = { 'username': value, 'email': newusers[idx].email }
    } else if (infotype === 'email') {
        id = "userinfoEmailMultiple_" + idx
        input = document.getElementById(id)
        value = input.value
        newusers[idx] = { 'username': newusers[idx].username, 'email': value }
    }

    this.setState({ newusers })
}

export async function submitMultipleUsers() {
    console.log('Submiting multiple users for creation')

    let user_list = []
    this.state.newusers.forEach(user => user_list.push([user.username, user.email]))
    var multusersdata = {
        login_allowed: this.state.newuserinfoLoginenabled || false,
        company_id: this.state.userinfoCompanyId,
        group_id: this.state.userinfoGroupId,
        user_list: user_list
    }

    let results = await this.entityCrud('creating multiple users', [
        { 'url': Constant.baseapiurl_gen + 'Users/BulkCreate', 'method': 'POST', 'body': multusersdata },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        this.handleCloseNewUsers()
        alert('Changes Made Successfully')
    }
}

export async function getUserInfo(userid) {
    let results = await this.entityCrud('getting user info for user ID ' + userid, [
        { 'url': Constant.baseapiurl_gen + 'Users/' + userid, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var userinfodata = results['ResultList'][0]
        this.setState({
            userinfodata: userinfodata,
            userinfoName: userinfodata.user_name,
            userinfoEmail: userinfodata.email,
            userinfoCompanyId: userinfodata.company_id,
            userinfoLogin: userinfodata.login_allowed,
            showuserinfo: true
        })
    }
}

export async function getUserPerms(userid) {
    let results = await this.entityCrud('getting user permissions for user ID ' + userid, [
        { 'url': Constant.baseapiurl_gen + 'Users/' + userid, 'method': 'GET', 'body': null },
        { 'url': Constant.baseapiurl_gen + 'pnp/Permissions/' + userid, 'method': 'GET', 'body': null },
        { 'url': Constant.baseapiurl_gen + 'pdt/Permissions/' + userid, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var userinfodata = results['ResultList'][0]
        var userpermspnpdata = results['ResultList'][1]
        var userpermspdtdata = results['ResultList'][2]
        this.setState({
            userinfodata: userinfodata,
            userinfoName: userinfodata.user_name,
            userinfoEmail: userinfodata.email,
            userinfoCompanyId: userinfodata.company_id,
            userinfoLogin: userinfodata.login_allowed,
            userpermspnpdata: userpermspnpdata,
            userpermspdtdata: userpermspdtdata
        })
    }

    this.setState({ viewuserperms: true })
}

export function handleOpenNewUserMultiple() {
    console.log('Opening "Add Multiple New Users" dialog')
    this.setState({
        newusers: [{ 'username': null, 'email': null }],
        userinfoCompanyId: null,
        userinfoLogin: null,
        showuserinfo_new_multiple: true
    })
}

export async function fetchUserList() {
    console.log('Fetching list of all users')
    let results = await this.entityCrud('getting the list of all companies', [
        { 'url': Constant.baseapiurl_gen + 'Users', 'method': 'GET', 'body': null }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var usersalldata = results['ResultList'][0]
        this.setState({ usersdata: usersalldata, totalusers: usersalldata.length })
    }
}
