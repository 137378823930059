import { saveAs } from "file-saver";
const ExcelJS = require('exceljs');
export default async function download_alldata(level, metrics) {

    console.log('Downloading All Brand Data')
    let usedollars = this.state.indexSwitchCheckDollars

    var levelstoview = []
    var metricstoview = []
    var metricnames = []
    var metricstyle = ""
    var metricshr = ""
    var levelhr = ""

    //Use new AggregatedData...
    var categories_dollars = this.state.agg_categories_dollars || []
    var categories_units = this.state.agg_categories_units || []

    var dataset = this.state.aggregatedData
    if (this.state.use_attr_groups) {
        dataset = this.state.attr_groups_data
        categories_dollars = this.state.agg_categories_attr_groups_dollars
        categories_units = this.state.agg_categories_attr_groups_units
    }

    if (usedollars) {
        levelstoview = categories_dollars
    }
    else {
        levelstoview = categories_units
    }

    var NonPromotedBarData_All = levelstoview.map(key => (dataset[key] || {})['nonpromotedunits'])
    var PromotedBarData_All = levelstoview.map(key => (dataset[key] || {})['promotedunits'])
    var UnSubBarData_All = levelstoview.map(key => (dataset[key] || {})['unsubsidizedbaseunits'])
    var SubBarData_All = levelstoview.map(key => (dataset[key] || {})['subsidizedbaseunits'])
    var IncBarData_All = levelstoview.map(key => (dataset[key] || {})['incrementalunits'])

    var NonPromotedBarData_Dollars_All = levelstoview.map(key => (dataset[key] || {})['nonpromoteddollars'])
    var PromotedBarData_Dollars_All = levelstoview.map(key => (dataset[key] || {})['promoteddollars'])
    var UnSubBarData_Dollars_All = levelstoview.map(key => (dataset[key] || {})['unsubsidizedbasedollars'])
    var SubBarData_Dollars_All = levelstoview.map(key => (dataset[key] || {})['subsidizedbasedollars'])
    var IncBarData_Dollars_All = levelstoview.map(key => (dataset[key] || {})['incrementaldollars'])

    //Change to Always provide Units and Dollars
    if (metrics === 'basepromo') {
        metricshr = "Base and Incremental and Promoted and Non-Promoted " + this.state.unitsvar + " and " + this.state.currencyType
        metricnames = ['Non Promoted ' + this.state.unitsvar, 'Promoted ' + this.state.unitsvar, 'Unsubsidized Base ' + this.state.unitsvar, 'Subsidized Base ' + this.state.unitsvar, 'Incremental ' + this.state.unitsvar,
        'Non Promoted ' + this.state.currencyType, 'Promoted ' + this.state.currencyType, 'Unsubsidized Base ' + this.state.currencyType, 'Subsidized Base ' + this.state.currencyType, 'Incremental ' + this.state.currencyType
        ]
        metricstoview = [NonPromotedBarData_All, PromotedBarData_All, UnSubBarData_All, SubBarData_All, IncBarData_All, NonPromotedBarData_Dollars_All, PromotedBarData_Dollars_All, UnSubBarData_Dollars_All, SubBarData_Dollars_All, IncBarData_Dollars_All]
        metricstyle = 'currencyandunits'
    }
    else if (metrics === 'lifteff') {
        metricshr = "Lift and Efficiency and Average Promoted Discount"
        metricnames = ['Market Lift', 'Promoted Lift', 'Efficiency', 'Market Lift (' + this.state.currencyType + ')', 'Promoted Lift (' + this.state.currencyType + ')', 'Efficiency (' + this.state.currencyType + ')', 'Average Promoted Discount']
        metricstoview = [this.state.LiftGraphData_All, this.state.PromoLiftGraphData_All, this.state.EffGraphData_All, this.state.LiftGraphData_Dollars_All, this.state.PromoLiftGraphData_Dollars_All, this.state.EffGraphData_Dollars_All, this.state.DiscGraphData_All]
        metricstyle = 'percent'
    }

    //Try Creating A new Workbook Here....
    //File Information
    var name = 'All Data For ' + metricshr + ' by ' + levelhr
    var newdate = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
    datestring = datestring.replaceAll(',', '')
    let filenameexcel = name + ' - Extracted - ' + datestring
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Metrics');
    const worksheet = workbook.getWorksheet('Metrics');

    var metric_start_col = 2
    var headers = []

    worksheet.getColumn(1).values = levelstoview
    metric_start_col = 2
    headers = [levelhr].concat(metricnames)
    
    metricstoview.forEach((metric, idx) => {
        worksheet.getColumn(idx + metric_start_col).values = metric
    })

    worksheet.insertRow(1, headers);

    //Format Columns Depending on Metrics Selected
    if (metricstyle === 'percent') {
        worksheet.getColumn(metric_start_col+  0).numFmt = '0.00%'
        worksheet.getColumn(metric_start_col + 1).numFmt = '0.00%'
        worksheet.getColumn(metric_start_col + 2).numFmt = '0.00%'
        worksheet.getColumn(metric_start_col + 3).numFmt = '0.00%'
        worksheet.getColumn(metric_start_col + 4).numFmt = '0.00%'
        worksheet.getColumn(metric_start_col + 5).numFmt = '0.00%'
        worksheet.getColumn(metric_start_col + 6).numFmt = '0.00%'
    }
    else if (metricstyle === 'currencyandunits') {
        worksheet.getColumn(metric_start_col + 0).numFmt = '##,#'
        worksheet.getColumn(metric_start_col + 1).numFmt = '##,#'
        worksheet.getColumn(metric_start_col + 2).numFmt = '##,#'
        worksheet.getColumn(metric_start_col + 3).numFmt = '##,#'
        worksheet.getColumn(metric_start_col + 4).numFmt = '##,#'        
        worksheet.getColumn(metric_start_col + 5).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(metric_start_col + 6).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(metric_start_col + 7).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(metric_start_col + 8).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(metric_start_col + 9).numFmt = this.state.currencysymbol + '#,##0'        
    }
    else if (metricstyle === 'units') {
        worksheet.getColumn(2).numFmt = '##,#'
        worksheet.getColumn(3).numFmt = '##,#'
        worksheet.getColumn(4).numFmt = '##,#'
        worksheet.getColumn(5).numFmt = '##,#'
        worksheet.getColumn(6).numFmt = '##,#'
    }
    else if (metricstyle === 'currency') {
        worksheet.getColumn(2).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(3).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(4).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(5).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(6).numFmt = this.state.currencysymbol + '#,##0'
    }

    //Add in Metdata
    var captionstring = this.state.attr_caption_text
    var titleinfo = this.state.chart_title_info.split('<br>')
    var brandsstring = this.state.brandsstring

    //Add Selection Information
    const newsheet = workbook.addWorksheet('Selection Criteria');
    const newworksheet = workbook.getWorksheet('Selection Criteria')

    let titles = Object.keys(this.state.chartmetadata)
    let values = Object.values(this.state.chartmetadata)
    let values_updated = values.map(function (item) { return item.length > 32000 ? item.substring(0, 32000) : item; });

    newworksheet.getColumn(1).values = titles
    newworksheet.getColumn(2).values = values_updated

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, filenameexcel + fileExtension);
}