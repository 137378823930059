import * as Constant from '../../../constants'
export default async function getcompsims(simid) {
    let simsResult = await fetch(Constant.baseapiurl + "SimSettings/" + simid + "/Comparable")
    var checked_array = [], result_less_one = []
    if (simsResult.status === 200) {
        let result = await simsResult.json()
        result.forEach(async row => {
            let simsettingsResult = await fetch(Constant.baseapiurl + "SimSettings/" + row.sim_id)
            if (simsettingsResult.status === 200) {
                let simresult = await simsettingsResult.json()
                let dt = new Date()
                let offset = dt.getTimezoneOffset() * 60 * 1000
                simresult.time_updated_adj = (new Date(Date.parse(simresult.time_updated) - offset)).toISOString()
                if (simresult.sim_id !== simid) {
                    checked_array.push(false)
                    result_less_one.push(simresult)
                }
            }
        })
        this.setState({
            allSimsForCompare: result_less_one,
            checked: checked_array
        });
    }

    else {
        this.setState({ showBackground: false, errorAlert: true })
    }
}