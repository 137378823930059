import { saveAs } from "file-saver";
import CreateWeeksNew from '../Utilities/CreateWeeks'
import { reducer } from '../Utilities/Reducer'
import removeItemOnce from '../Utilities/RemoveItemOnce'
import replaceEmptyArrays from '../Utilities/RemoveItemOnce'
import * as Constant from '../../../constants'
const ExcelJS = require('exceljs');
export default async function download_alldata_YOY(level, metrics) {
    console.log('Downloading All Data')
    let url_items = Constant.baseapiurl + "ReportData/Filtered/Items/" + this.state.reportsettings.report_id + this.state.extraparam
    let url_total_sel = Constant.baseapiurl + "ReportData/Filtered/TotalSelection/" + this.state.reportsettings.report_id + this.state.extraparam
    let weeks_info = CreateWeeksNew(this.state.filter_object, this.state.times_data, this.state.datasettimeagg)
    let filters_raw = this.state.filter_object

    let attrLevel = false
    var nonAttrVals = [
        'Total Selection'
        , 'Total'
        , 'Brand'
        , 'Channel'
        , 'Outlet'
        , 'Price Band'
        , 'Item'
        , 'Segment 1'
        , 'Segment 2'
        , 'Segment 3'
        , 'Segment 4'
    ]
    attrLevel = !nonAttrVals.includes(level)

    //Rework Attributes
    let apifilterattrs = Object.keys(filters_raw['attributes']).map(attr => {
        return [attr, removeItemOnce(filters_raw['attributes'][attr], 'All')]
    })

    console.log("Level = " + level)
    console.log("Attribute Level = " + attrLevel)

    //create filters
    let filters_current = {}
    let filters_prior = {}
    var filters_last = {}
    var filters_threeyear = {}

    //current
    filters_current['brands'] = removeItemOnce(filters_raw['brands'], 'All')
    filters_current['exclude_brands'] = this.state.exclude_array[0]
    filters_current['items'] = removeItemOnce(filters_raw['items'], 'All')
    filters_current['exclude_items'] = this.state.exclude_array[1]
    filters_current['week_aggregation'] = filters_raw['numweeks']
    filters_current['asp_range'] = filters_raw['asp_range']
    filters_current['date_range'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_current['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_current['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_current['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
    filters_current['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
    filters_current['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
    filters_current['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
    filters_current['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
    filters_current['attributes'] = apifilterattrs
    filters_current['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
    filters_current['price_bands_defs'] = this.state.priceBandOptions
    //filters_current['selected_items_weeklyviews'] = items
    filters_current['channels'] = removeItemOnce(filters_raw['channels'], 'All')

    //prior - one year
    filters_prior['brands'] = removeItemOnce(filters_raw['brands'], 'All')
    filters_prior['exclude_brands'] = this.state.exclude_array[0]
    filters_prior['items'] = removeItemOnce(filters_raw['items'], 'All')
    filters_prior['exclude_items'] = this.state.exclude_array[1]
    filters_prior['week_aggregation'] = filters_raw['numweeks']
    filters_prior['asp_range'] = filters_raw['asp_range']
    filters_prior['date_range'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_prior['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_prior['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_prior['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
    filters_prior['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
    filters_prior['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
    filters_prior['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
    filters_prior['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
    filters_prior['attributes'] = apifilterattrs
    filters_prior['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
    filters_prior['price_bands_defs'] = this.state.priceBandOptions
    //filters_prior['selected_items_weeklyviews'] = items
    filters_prior['channels'] = removeItemOnce(filters_raw['channels'], 'All')

    //last - two years
    filters_last['brands'] = removeItemOnce(filters_raw['brands'], 'All')
    filters_last['exclude_brands'] = this.state.exclude_array[0]
    filters_last['items'] = removeItemOnce(filters_raw['items'], 'All')
    filters_last['exclude_items'] = this.state.exclude_array[1]
    filters_last['week_aggregation'] = filters_raw['numweeks']
    filters_last['asp_range'] = filters_raw['asp_range']
    filters_last['date_range'] = [weeks_info.last_start, weeks_info.last_end]
    filters_last['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_last['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_last['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
    filters_last['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
    filters_last['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
    filters_last['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
    filters_last['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
    filters_last['attributes'] = apifilterattrs
    filters_last['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
    filters_last['price_bands_defs'] = this.state.priceBandOptions
    //filters_last['selected_items_weeklyviews'] = items
    filters_last['channels'] = removeItemOnce(filters_raw['channels'], 'All')

    //three years
    filters_threeyear['brands'] = removeItemOnce(filters_raw['brands'], 'All')
    filters_threeyear['exclude_brands'] = this.state.exclude_array[0]
    filters_threeyear['items'] = removeItemOnce(filters_raw['items'], 'All')
    filters_threeyear['exclude_items'] = this.state.exclude_array[1]
    filters_threeyear['week_aggregation'] = filters_raw['numweeks']
    filters_threeyear['asp_range'] = filters_raw['asp_range']
    filters_threeyear['date_range'] = [weeks_info.threeyear_start, weeks_info.threeyear_end]
    filters_threeyear['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_threeyear['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_threeyear['outlets'] = removeItemOnce(filters_raw['outlets'], 'All')
    filters_threeyear['sel1_list'] = removeItemOnce(filters_raw['segment1'], 'All')
    filters_threeyear['sel2_list'] = removeItemOnce(filters_raw['segment2'], 'All')
    filters_threeyear['sel3_list'] = removeItemOnce(filters_raw['segment3'], 'All')
    filters_threeyear['sel4_list'] = removeItemOnce(filters_raw['segment4'], 'All')
    filters_threeyear['attributes'] = apifilterattrs
    filters_threeyear['price_bands'] = removeItemOnce(filters_raw['pricebands'], 'All')
    filters_threeyear['price_bands_defs'] = this.state.priceBandOptions
    //filters_last['selected_items_weeklyviews'] = items
    filters_threeyear['channels'] = removeItemOnce(filters_raw['channels'], 'All')

    var results
    if (level === 'brand') {
        results = await Promise.all([
            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_current)
                }),

            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_prior)
                }),

            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_last)
                }),

            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_threeyear)
                }),
        ])
    }
    else if (level === 'item') {
        results = await Promise.all([
            fetch(url_items,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_current)
                }),

            fetch(url_items,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_prior)
                }),

            fetch(url_items,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_last)
                }),

            fetch(url_items,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_threeyear)
                }),
        ])
    }
    else {
        results = await Promise.all([
            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_current)
                }),

            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_prior)
                }),

            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_last)
                }),

            fetch(url_total_sel,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filters_threeyear)
                }),
        ])
    }

    let items_response_curr = results[0]
    let items_response_prior = results[1]
    let items_response_last = results[2]
    let items_response_threeyear = results[3]

    let items_curr_obj = {}
    let items_prior_obj = {}
    let items_last_obj = {}
    let items_threeyear_obj = {}

    if (items_response_curr.status === 200) {
        items_curr_obj = await items_response_curr.json()
    }
    else {
        items_curr_obj = {}
    }

    if (items_response_prior.status === 200) {
        items_prior_obj = await items_response_prior.json()
    }
    else {
        items_prior_obj = {}
    }

    if (items_response_last.status === 200) {
        items_last_obj = await items_response_last.json()
    }
    else {
        items_last_obj = {}
    }

    if (items_response_threeyear.status === 200) {
        items_threeyear_obj = await items_response_threeyear.json()
    }
    else {
        items_threeyear_obj = {}
    }

    var item_data_curr, item_data_prior, item_data_last, item_data_threeyear

    if (level === 'brand') {
        //categories_baseinc.map(brand => distinctdates.map(date => weekly_data[brand][date]['nonpromotedunits']).reduce(reducer))
        item_data_curr = items_curr_obj['weekly_data']
        item_data_prior = items_prior_obj['weekly_data']
        item_data_last = items_last_obj['weekly_data']
        item_data_threeyear = items_threeyear_obj['weekly_data']
    }
    else if (level === 'item') {
        item_data_curr = items_curr_obj['items_brand_data']
        item_data_prior = items_prior_obj['items_brand_data']
        item_data_last = items_last_obj['items_brand_data']
        item_data_threeyear = items_last_obj['items_brand_data']
    }
    else if (level === 'Segment 1') {
        item_data_curr = items_curr_obj['segment1_data']
        item_data_prior = items_prior_obj['segment1_data']
        item_data_last = items_last_obj['segment1_data']
        item_data_threeyear = items_last_obj['segment1_data']
    }
    else if (level === 'Segment 2') {
        item_data_curr = items_curr_obj['segment2_data']
        item_data_prior = items_prior_obj['segment2_data']
        item_data_last = items_last_obj['segment2_data']
        item_data_threeyear = items_last_obj['segment2_data']
    }
    else if (level === 'Segment 3') {
        item_data_curr = items_curr_obj['segment3_data']
        item_data_prior = items_prior_obj['segment3_data']
        item_data_last = items_last_obj['segment3_data']
        item_data_threeyear = items_last_obj['segment3_data']
    }
    else if (level === 'Segment 4') {
        item_data_curr = items_curr_obj['segment4_data']
        item_data_prior = items_prior_obj['segment4_data']
        item_data_last = items_last_obj['segment4_data']
        item_data_threeyear = items_last_obj['segment4_data']
    }
    else if (attrLevel) {
        item_data_curr = items_curr_obj['attr_aggregated_data']
        item_data_prior = items_prior_obj['attr_aggregated_data']
        item_data_last = items_last_obj['attr_aggregated_data']
        item_data_threeyear = items_last_obj['attr_aggregated_data']
    }
    else {
        item_data_curr = items_curr_obj['weekly_data']
        item_data_prior = items_prior_obj['weekly_data']
        item_data_last = items_last_obj['weekly_data']
        item_data_threeyear = items_threeyear_obj['weekly_data']
    }

    //Clean Up Filters
    let apifilterattrs_replaced = Object.keys(filters_raw['attributes']).map(attr => {
        return [attr, replaceEmptyArrays(filters_raw['attributes'][attr])]
    })

    filters_current['brands'] = replaceEmptyArrays(filters_raw['brands'])
    filters_current['exclude_brands'] = this.state.exclude_array[0]
    filters_current['items'] = replaceEmptyArrays(filters_raw['items'])
    filters_current['exclude_items'] = this.state.exclude_array[1]
    filters_current['week_aggregation'] = filters_raw['numweeks']
    filters_current['asp_range'] = filters_raw['asprange']
    filters_current['date_range'] = [weeks_info.startdate, weeks_info.enddate]
    filters_current['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_current['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_current['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
    filters_current['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
    filters_current['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
    filters_current['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
    filters_current['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
    filters_current['attributes'] = apifilterattrs_replaced
    //filters_current['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
    filters_current['price_bands_defs'] = this.state.priceBandOptions
    filters_current['channels'] = replaceEmptyArrays(filters_raw['channels'])

    filters_prior['brands'] = replaceEmptyArrays(filters_raw['brands'])
    filters_prior['exclude_brands'] = this.state.exclude_array[0]
    filters_prior['items'] = replaceEmptyArrays(filters_raw['items'])
    filters_prior['exclude_items'] = this.state.exclude_array[1]
    filters_prior['week_aggregation'] = filters_raw['numweeks']
    filters_prior['asp_range'] = filters_raw['asprange']
    filters_prior['date_range'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_prior['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_prior['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_prior['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
    filters_prior['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
    filters_prior['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
    filters_prior['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
    filters_prior['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
    filters_prior['attributes'] = apifilterattrs_replaced
    //filters_prior['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
    filters_prior['price_bands_defs'] = this.state.priceBandOptions
    filters_prior['channels'] = replaceEmptyArrays(filters_raw['channels'])

    filters_last['brands'] = replaceEmptyArrays(filters_raw['brands'])
    filters_last['exclude_brands'] = this.state.exclude_array[0]
    filters_last['items'] = replaceEmptyArrays(filters_raw['items'])
    filters_last['exclude_items'] = this.state.exclude_array[1]
    filters_last['week_aggregation'] = filters_raw['numweeks']
    filters_last['asp_range'] = filters_raw['asprange']
    filters_last['date_range'] = [weeks_info.startdate, weeks_info.enddate]
    filters_last['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_last['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_last['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
    filters_last['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
    filters_last['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
    filters_last['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
    filters_last['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
    filters_last['attributes'] = apifilterattrs_replaced
    //filters_last['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
    filters_last['price_bands_defs'] = this.state.priceBandOptions
    filters_last['channels'] = replaceEmptyArrays(filters_raw['channels'])

    filters_threeyear['brands'] = replaceEmptyArrays(filters_raw['brands'])
    filters_threeyear['exclude_brands'] = this.state.exclude_array[0]
    filters_threeyear['items'] = replaceEmptyArrays(filters_raw['items'])
    filters_threeyear['exclude_items'] = this.state.exclude_array[1]
    filters_threeyear['week_aggregation'] = filters_raw['numweeks']
    filters_threeyear['asp_range'] = filters_raw['asprange']
    filters_threeyear['date_range'] = [weeks_info.startdate, weeks_info.enddate]
    filters_threeyear['date_range_curr'] = [weeks_info.startdate_calc, weeks_info.enddate]
    filters_threeyear['date_range_prior'] = [weeks_info.prior_start, weeks_info.prior_end]
    filters_threeyear['outlets'] = replaceEmptyArrays(filters_raw['outlets'])
    filters_threeyear['sel1_list'] = replaceEmptyArrays(filters_raw['segment1'])
    filters_threeyear['sel2_list'] = replaceEmptyArrays(filters_raw['segment2'])
    filters_threeyear['sel3_list'] = replaceEmptyArrays(filters_raw['segment3'])
    filters_threeyear['sel4_list'] = replaceEmptyArrays(filters_raw['segment4'])
    filters_threeyear['attributes'] = apifilterattrs_replaced
    //filters_last['price_bands'] = replaceEmptyArrays(filters_raw['pricebands'])
    filters_threeyear['price_bands_defs'] = this.state.priceBandOptions
    filters_threeyear['channels'] = replaceEmptyArrays(filters_raw['channels'])

    console.log(item_data_curr)
    console.log(item_data_prior)
    console.log(item_data_last)

    let zero_object = {
        'basedollars': 0,
        'baseunits': 0,
        'carrieddoors': 0,
        'discountdollars': 0,
        'incrementaldollars': 0,
        'incrementalunits': 0,
        'nonpromoteddollars': 0,
        'nonpromotedunits': 0,
        'promoteddollars': 0,
        'promotedunits': 0,
        'revopportunity': 0,
        'subsidizedbasedollars': 0,
        'subsidizedbaseunits': 0,
        'totaldollars': 0,
        'totaldoors_s1': 0,
        'totaldoors_s1_segment': 0,
        'totaldoors_s2': 0,
        'totaldoors_s2_segment': 0,
        'totalunits': 0,
        'totalunits_withoutno': 0,
        'unsubsidizedbasedollars': 0,
        'unsubsidizedbaseunits': 0,
        'weightedasp': 0,
        'weightedbp': 0,
        'weightedbp_withoutno': 0,
        'weightedpctdisc': 0,
        'weightedpctdisc_withoutno': 0,
    }

    let zero_object_date = {
        'empty':
        {
            'basedollars': 0,
            'baseunits': 0,
            'carrieddoors': 0,
            'discountdollars': 0,
            'incrementaldollars': 0,
            'incrementalunits': 0,
            'nonpromoteddollars': 0,
            'nonpromotedunits': 0,
            'promoteddollars': 0,
            'promotedunits': 0,
            'revopportunity': 0,
            'subsidizedbasedollars': 0,
            'subsidizedbaseunits': 0,
            'totaldollars': 0,
            'totaldoors_s1': 0,
            'totaldoors_s1_segment': 0,
            'totaldoors_s2': 0,
            'totaldoors_s2_segment': 0,
            'totalunits': 0,
            'totalunits_withoutno': 0,
            'unsubsidizedbasedollars': 0,
            'unsubsidizedbaseunits': 0,
            'weightedasp': 0,
            'weightedbp': 0,
            'weightedbp_withoutno': 0,
            'weightedpctdisc': 0,
            'weightedpctdisc_withoutno': 0,
        }
    }

    //Combine the Current, Prior and Last Data
    //Get All Items
    var all_items
    var all_brands
    if (level === 'item') {
        all_brands = Array.from(new Set(Object.keys(item_data_curr).concat(Object.keys(item_data_prior)).concat(Object.keys(item_data_last))))
        all_items = Array.from(new Set(Object.keys(item_data_curr['Total Selection'] || {}).concat(Object.keys(item_data_prior['Total Selection'] || {})).concat(Object.keys(item_data_last['Total Selection'] || {}))))

        //remove Total Selection and Total Category from Brands
        let total_cat_idx = all_brands.indexOf('Total Category')
        all_brands.splice(total_cat_idx, 1)
        let total_sel_idx = all_brands.indexOf('Total Selection')
        all_brands.splice(total_sel_idx, 1)
    }
    else if (level === 'brand') {
        all_items = Array.from(new Set(Object.keys(item_data_curr).concat(Object.keys(item_data_prior)).concat(Object.keys(item_data_last))))
    }
    else if (attrLevel) {
        all_items = Array.from(new Set(Object.keys(item_data_curr[level] || {}).concat(Object.keys(item_data_prior[level] || {})).concat(Object.keys(item_data_last[level] || {}))))
    }
    else {
        all_items = Array.from(new Set(Object.keys(item_data_curr).concat(Object.keys(item_data_prior)).concat(Object.keys(item_data_last))))
    }

    let all_period_metrics = {}
    if (level === 'item') {
        all_brands.forEach(brand => {
            all_period_metrics[brand] = all_period_metrics[brand] || {}
            item_data_curr[brand] = item_data_curr[brand] || {}
            item_data_prior[brand] = item_data_prior[brand] || {}
            item_data_last[brand] = item_data_last[brand] || {}
            item_data_threeyear[brand] = item_data_threeyear[brand] || {}
            all_items.forEach(item => {
                all_period_metrics[brand][item] = {
                    'current': item_data_curr[brand][item] || zero_object,
                    'prior': (item_data_prior[brand] || {})[item] || zero_object,
                    'last': (item_data_last[brand] || {})[item] || zero_object,
                    'threeyear': (item_data_threeyear[brand] || {})[item] || zero_object,
                }
            })
        })
        
    }
    else if (level === 'brand') {
        all_items.forEach(item => {
            all_period_metrics[item] = {
                'current': item_data_curr[item] || zero_object_date,
                'prior': item_data_prior[item] || zero_object_date,
                'last': item_data_last[item] || zero_object_date,
                'threeyear': item_data_threeyear[item] || zero_object_date,
            }
        })
    }
    else if (attrLevel) {
        all_items.forEach(item => {
            all_period_metrics[item] = {
                'current': (item_data_curr[level] || {})[item] || zero_object_date,
                'prior': (item_data_prior[level] || {})[item] || zero_object_date,
                'last': (item_data_last[level] || {})[item] || zero_object_date,
                'threeyear': (item_data_threeyear[level] || {})[item] || zero_object_date,
            }
        })
    }
    else {
        all_items.forEach(item => {
            all_period_metrics[item] = {
                'current': item_data_curr[item] || zero_object_date,
                'prior': item_data_prior[item] || zero_object_date,
                'last': item_data_last[item] || zero_object_date,
                'threeyear': item_data_threeyear[item] || zero_object_date,
            }
        })
    }

    console.log(all_period_metrics)

    let usedollars = this.state.indexSwitchCheckDollars

    var levelstoview = []
    var metricstoview = []
    var metricnames = []
    var metricstyle = ""
    var metricshr = ""
    var levelhr = ""

    function createMetricArray(itemdataset, items, timeperiod, metric, level, attrLevel) {
        var metricArray
        if (level === 'item') {
            metricArray = items.map(item => itemdataset[item][timeperiod][metric])
        }
        else if (level === 'brand') {
            metricArray = items.map(item => {
                let dates = Object.keys(itemdataset[item][timeperiod])
                return dates.map(date => itemdataset[item][timeperiod][date][metric]).reduce(reducer)
            })
        }
        else if ((level === 'Segment 1') || (level === 'Segment 2') || (level === 'Segment 3') || (level === 'Segment 4')) {
            metricArray = items.map(item => itemdataset[item][timeperiod][metric])
        }
        else if (attrLevel) {
            metricArray = items.map(item => itemdataset[item][timeperiod][metric])
        }
        else {
            metricArray = items.map(item => {
                let dates = Object.keys(itemdataset[item][timeperiod])
                return dates.map(date => itemdataset[item][timeperiod][date][metric]).reduce(reducer)
            })
        }
        return metricArray
    }

    if (metrics === 'basepromo') {
        metricshr = "Base and Incremental and Promoted and Non-Promoted " + this.state.unitsvar + ' and ' + this.state.currencyType

        metricnames = [
            'Current Year Non Promoted ' + this.state.unitsvar, 'Current Year Promoted ' + this.state.unitsvar, 'Current Year Unsubsidized Base ' + this.state.unitsvar, 'Current Year Subsidized Base ' + this.state.unitsvar, 'Current Year Incremental ' + this.state.unitsvar,
            'Prior Year Non Promoted ' + this.state.unitsvar, 'Prior Year Promoted ' + this.state.unitsvar, 'Prior Year Unsubsidized Base ' + this.state.unitsvar, 'Prior Year Subsidized Base ' + this.state.unitsvar, 'Prior Year Incremental ' + this.state.unitsvar,
        ]

        if (this.state.showLastViews === true) {
            metricnames.push('Two Years Ago Non Promoted ' + this.state.unitsvar)
            metricnames.push('Two Years Ago Promoted ' + this.state.unitsvar)
            metricnames.push('Two Years Ago Unsubsidized Base ' + this.state.unitsvar)
            metricnames.push('Two Years Ago Subsidized Base ' + this.state.unitsvar)
            metricnames.push('Two Years Ago Incremental ' + this.state.unitsvar)
        }

        if (this.state.showThreeYearViews === true) {
            metricnames.push('Three Years Ago Non Promoted ' + this.state.unitsvar)
            metricnames.push('Three Years Ago Promoted ' + this.state.unitsvar)
            metricnames.push('Three Years Ago Unsubsidized Base ' + this.state.unitsvar)
            metricnames.push('Three Years Ago Subsidized Base ' + this.state.unitsvar)
            metricnames.push('Three Years Ago Incremental ' + this.state.unitsvar)
        }

        metricnames.push('Current Year Non Promoted ' + this.state.currencyType)
        metricnames.push('Current Year Promoted ' + this.state.currencyType)
        metricnames.push('Current Year Unsubsidized Base ' + this.state.currencyType)
        metricnames.push('Current Year Subsidized Base ' + this.state.currencyType)
        metricnames.push('Current Year Incremental ' + this.state.currencyType)
        metricnames.push('Prior Year Non Promoted ' + this.state.currencyType)
        metricnames.push('Prior Year Promoted ' + this.state.currencyType)
        metricnames.push('Prior Year Unsubsidized Base ' + this.state.currencyType)
        metricnames.push('Prior Year Subsidized Base ' + this.state.currencyType)
        metricnames.push('Prior Year Incremental ' + this.state.currencyType)

        if (this.state.showLastViews === true) {
            metricnames.push('Two Years Ago Non Promoted ' + this.state.currencyType)
            metricnames.push('Two Years Ago Promoted ' + this.state.currencyType)
            metricnames.push('Two Years Ago Unsubsidized Base ' + this.state.currencyType)
            metricnames.push('Two Years Ago Subsidized Base ' + this.state.currencyType)
            metricnames.push('Two Years Ago Incremental ' + this.state.currencyType)
        }

        if (this.state.showThreeYearViews === true) {
            metricnames.push('Three Years Ago Non Promoted ' + this.state.currencyType)
            metricnames.push('Three Years Ago Promoted ' + this.state.currencyType)
            metricnames.push('Three Years Ago Unsubsidized Base ' + this.state.currencyType)
            metricnames.push('Three Years Ago Subsidized Base ' + this.state.currencyType)
            metricnames.push('Three Years Ago Incremental ' + this.state.currencyType)
        }

        if (level === 'item') {
            let nonpromunits_curr = []
            let promunits_curr = []
            let unsubunits_curr = []
            let subunits_curr = []
            let incunits_curr = []
            let nonpromunits_prior = []
            let promunits_prior = []
            let unsubunits_prior = []
            let subunits_prior = []
            let incunits_prior = []
            let nonpromunits_last = []
            let promunits_last = []
            let unsubunits_last = []
            let subunits_last = []
            let incunits_last = []

            let nonpromunits_threeyear = []
            let promunits_threeyear = []
            let unsubunits_threeyear = []
            let subunits_threeyear = []
            let incunits_threeyear = []

            let nonpromdollars_curr = []
            let promdollars_curr = []
            let unsubdollars_curr = []
            let subdollars_curr = []
            let incdollars_curr = []
            let nonpromdollars_prior = []
            let promdollars_prior = []
            let unsubdollars_prior = []
            let subdollars_prior = []
            let incdollars_prior = []
            let nonpromdollars_last = []
            let promdollars_last = []
            let unsubdollars_last = []
            let subdollars_last = []
            let incdollars_last = []

            let nonpromdollars_threeyear = []
            let promdollars_threeyear = []
            let unsubdollars_threeyear = []
            let subdollars_threeyear = []
            let incdollars_threeyear = []

            var items_for_col = []
            var brands_for_col = []

            //run all the metrics for each brand 
            console.log(all_period_metrics)
            all_brands.forEach(brand => {

                let all_period_metrics_data = all_period_metrics[brand]
                console.log(all_period_metrics_data)
                let brand_nonpromunits_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'nonpromotedunits', level, attrLevel)
                let brand_promunits_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'promotedunits', level, attrLevel)
                let brand_unsubunits_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'unsubsidizedbaseunits', level, attrLevel)
                let brand_subunits_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'subsidizedbaseunits', level, attrLevel)
                let brand_incunits_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'incrementalunits', level, attrLevel)

                let brand_nonpromunits_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'nonpromotedunits', level, attrLevel)
                let brand_promunits_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'promotedunits', level, attrLevel)
                let brand_unsubunits_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'unsubsidizedbaseunits', level, attrLevel)
                let brand_subunits_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'subsidizedbaseunits', level, attrLevel)
                let brand_incunits_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'incrementalunits', level, attrLevel)

                let brand_nonpromunits_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'nonpromotedunits', level, attrLevel)
                let brand_promunits_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'promotedunits', level, attrLevel)
                let brand_unsubunits_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'unsubsidizedbaseunits', level, attrLevel)
                let brand_subunits_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'subsidizedbaseunits', level, attrLevel)
                let brand_incunits_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'incrementalunits', level, attrLevel)

                let brand_nonpromunits_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'nonpromotedunits', level, attrLevel)
                let brand_promunits_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'promotedunits', level, attrLevel)
                let brand_unsubunits_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'unsubsidizedbaseunits', level, attrLevel)
                let brand_subunits_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'subsidizedbaseunits', level, attrLevel)
                let brand_incunits_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'incrementalunits', level, attrLevel)

                let brand_nonpromdollars_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'nonpromoteddollars', level, attrLevel)
                let brand_promdollars_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'promoteddollars', level, attrLevel)
                let brand_unsubdollars_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'unsubsidizedbasedollars', level, attrLevel)
                let brand_subdollars_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'subsidizedbasedollars', level, attrLevel)
                let brand_incdollars_curr = createMetricArray(all_period_metrics_data, all_items, 'current', 'incrementaldollars', level, attrLevel)

                let brand_nonpromdollars_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'nonpromoteddollars', level, attrLevel)
                let brand_promdollars_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'promoteddollars', level, attrLevel)
                let brand_unsubdollars_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'unsubsidizedbasedollars', level, attrLevel)
                let brand_subdollars_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'subsidizedbasedollars', level, attrLevel)
                let brand_incdollars_prior = createMetricArray(all_period_metrics_data, all_items, 'prior', 'incrementaldollars', level, attrLevel)

                let brand_nonpromdollars_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'nonpromoteddollars', level, attrLevel)
                let brand_promdollars_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'promoteddollars', level, attrLevel)
                let brand_unsubdollars_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'unsubsidizedbasedollars', level, attrLevel)
                let brand_subdollars_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'subsidizedbasedollars', level, attrLevel)
                let brand_incdollars_last = createMetricArray(all_period_metrics_data, all_items, 'last', 'incrementaldollars', level, attrLevel)

                let brand_nonpromdollars_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'nonpromoteddollars', level, attrLevel)
                let brand_promdollars_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'promoteddollars', level, attrLevel)
                let brand_unsubdollars_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'unsubsidizedbasedollars', level, attrLevel)
                let brand_subdollars_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'subsidizedbasedollars', level, attrLevel)
                let brand_incdollars_threeyear = createMetricArray(all_period_metrics_data, all_items, 'threeyear', 'incrementaldollars', level, attrLevel)

                let brand_items_for_col = []
                let brand_brands_for_col = []

                all_items.forEach(item => {
                    brand_items_for_col.push(item)
                    brand_brands_for_col.push(brand)
                })
                
                items_for_col = items_for_col.concat(brand_items_for_col)
                brands_for_col = brands_for_col.concat(brand_brands_for_col)

                nonpromunits_curr = nonpromunits_curr.concat(brand_nonpromunits_curr)
                promunits_curr = promunits_curr.concat(brand_promunits_curr)
                unsubunits_curr = unsubunits_curr.concat(brand_unsubunits_curr)
                subunits_curr = subunits_curr.concat(brand_subunits_curr)
                incunits_curr = incunits_curr.concat(brand_incunits_curr)

                nonpromunits_prior = nonpromunits_prior.concat(brand_nonpromunits_prior)
                promunits_prior = promunits_prior.concat(brand_promunits_prior)
                unsubunits_prior = unsubunits_prior.concat(brand_unsubunits_prior)
                subunits_prior = subunits_prior.concat(brand_subunits_prior)
                incunits_prior = incunits_prior.concat(brand_incunits_prior)

                nonpromunits_last = nonpromunits_last.concat(brand_nonpromunits_last)
                promunits_last = promunits_last.concat(brand_promunits_last)
                unsubunits_last = unsubunits_last.concat(brand_unsubunits_last)
                subunits_last = subunits_last.concat(brand_subunits_last)
                incunits_last = incunits_last.concat(brand_incunits_last)

                nonpromunits_threeyear = nonpromunits_threeyear.concat(brand_nonpromunits_threeyear)
                promunits_threeyear = promunits_threeyear.concat(brand_promunits_threeyear)
                unsubunits_threeyear = unsubunits_threeyear.concat(brand_unsubunits_threeyear)
                subunits_threeyear = subunits_threeyear.concat(brand_subunits_threeyear)
                incunits_threeyear = incunits_threeyear.concat(brand_incunits_threeyear)

                nonpromdollars_curr = nonpromdollars_curr.concat(brand_nonpromdollars_curr)
                promdollars_curr = promdollars_curr.concat(brand_promdollars_curr)
                unsubdollars_curr = unsubdollars_curr.concat(brand_unsubdollars_curr)
                subdollars_curr = subdollars_curr.concat(brand_subdollars_curr)
                incdollars_curr = incdollars_curr.concat(brand_incdollars_curr)

                nonpromdollars_prior = nonpromdollars_prior.concat(brand_nonpromdollars_prior)
                promdollars_prior = promdollars_prior.concat(brand_promdollars_prior)
                unsubdollars_prior = unsubdollars_prior.concat(brand_unsubdollars_prior)
                subdollars_prior = subdollars_prior.concat(brand_subdollars_prior)
                incdollars_prior = incdollars_prior.concat(brand_incdollars_prior)

                nonpromdollars_last = nonpromdollars_last.concat(brand_nonpromdollars_last)
                promdollars_last = promdollars_last.concat(brand_promdollars_last)
                unsubdollars_last = unsubdollars_last.concat(brand_unsubdollars_last)
                subdollars_last = subdollars_last.concat(brand_subdollars_last)
                incdollars_last = incdollars_last.concat(brand_incdollars_last)

                nonpromdollars_threeyear = nonpromdollars_threeyear.concat(brand_nonpromdollars_threeyear)
                promdollars_threeyear = promdollars_threeyear.concat(brand_promdollars_threeyear)
                unsubdollars_threeyear = unsubdollars_threeyear.concat(brand_unsubdollars_threeyear)
                subdollars_threeyear = subdollars_threeyear.concat(brand_subdollars_threeyear)
                incdollars_threeyear = incdollars_threeyear.concat(brand_incdollars_threeyear)
            })

            //Get the sum for each value.  If it is 0, remove the index.
            let indexes_to_remove = []
            nonpromunits_curr.forEach((item, idx) => {
                let nonpromunits_curr_val = nonpromunits_curr[idx]
                let promunits_curr_val = promunits_curr[idx]
                let unsubunits_curr_val = unsubunits_curr[idx]
                let subunits_curr_val = subunits_curr[idx]
                let incunits_curr_val = incunits_curr[idx]

                let nonpromunits_prior_val = nonpromunits_prior[idx]
                let promunits_prior_val = promunits_prior[idx]
                let unsubunits_prior_val = unsubunits_prior[idx]
                let subunits_prior_val = subunits_prior[idx]
                let incunits_prior_val = incunits_prior[idx]

                let nonpromunits_last_val = nonpromunits_last[idx]
                let promunits_last_val = promunits_last[idx]
                let unsubunits_last_val = unsubunits_last[idx]
                let subunits_last_val = subunits_last[idx]
                let incunits_last_val = incunits_last[idx]

                let nonpromunits_threeyear_val = nonpromunits_threeyear[idx]
                let promunits_threeyear_val = promunits_threeyear[idx]
                let unsubunits_threeyear_val = unsubunits_threeyear[idx]
                let subunits_threeyear_val = subunits_threeyear[idx]
                let incunits_threeyear_val = incunits_threeyear[idx]

                let nonpromdollars_curr_val = nonpromdollars_curr[idx]
                let promdollars_curr_val = promdollars_curr[idx]
                let unsubdollars_curr_val = unsubdollars_curr[idx]
                let subdollars_curr_val = subdollars_curr[idx]
                let incdollars_curr_val = incdollars_curr[idx]

                let nonpromdollars_prior_val = nonpromdollars_prior[idx]
                let promdollars_prior_val = promdollars_prior[idx]
                let unsubdollars_prior_val = unsubdollars_prior[idx]
                let subdollars_prior_val = subdollars_prior[idx]
                let incdollars_prior_val = incdollars_prior[idx]

                let nonpromdollars_last_val = nonpromdollars_last[idx]
                let promdollars_last_val = promdollars_last[idx]
                let unsubdollars_last_val = unsubdollars_last[idx]
                let subdollars_last_val = subdollars_last[idx]
                let incdollars_last_val = incdollars_last[idx]

                let nonpromdollars_threeyear_val = nonpromdollars_threeyear[idx]
                let promdollars_threeyear_val = promdollars_threeyear[idx]
                let unsubdollars_threeyear_val = unsubdollars_threeyear[idx]
                let subdollars_threeyear_val = subdollars_threeyear[idx]
                let incdollars_threeyear_val = incdollars_threeyear[idx]

                //Indexes to remove
                if (nonpromunits_curr_val === 0
                    && promunits_curr_val === 0
                    && unsubunits_curr_val === 0
                    && subunits_curr_val === 0
                    && incunits_curr_val === 0

                    && nonpromunits_prior_val === 0
                    && promunits_prior_val === 0
                    && unsubunits_prior_val === 0
                    && subunits_prior_val === 0
                    && incunits_prior_val === 0

                    && nonpromunits_last_val === 0
                    && promunits_last_val === 0
                    && unsubunits_last_val === 0
                    && subunits_last_val === 0
                    && incunits_last_val === 0

                    && nonpromunits_threeyear_val === 0
                    && promunits_threeyear_val === 0
                    && unsubunits_threeyear_val === 0
                    && subunits_threeyear_val === 0
                    && incunits_threeyear_val === 0

                    && nonpromdollars_curr_val === 0
                    && promdollars_curr_val === 0
                    && unsubdollars_curr_val === 0
                    && subdollars_curr_val === 0
                    && incdollars_curr_val === 0

                    && nonpromdollars_prior_val === 0
                    && promdollars_prior_val === 0
                    && unsubdollars_prior_val === 0
                    && subdollars_prior_val === 0
                    && incdollars_prior_val === 0

                    && nonpromdollars_last_val === 0
                    && promdollars_last_val === 0
                    && unsubdollars_last_val === 0
                    && subdollars_last_val === 0
                    && incdollars_last_val === 0

                    && nonpromdollars_threeyear_val === 0
                    && promdollars_threeyear_val === 0
                    && unsubdollars_threeyear_val === 0
                    && subdollars_threeyear_val === 0
                    && incdollars_threeyear_val === 0

                ) {
                    indexes_to_remove.push(idx)
                }

            })

            //for each index, remove from the arrays
            //Go in reverse order so we don't change the indexes
            console.log(indexes_to_remove)
            indexes_to_remove.sort(function (a, b) { return b - a; });
            console.log(indexes_to_remove)

            indexes_to_remove.forEach(idx => {
                nonpromunits_curr.splice(idx, 1)
                promunits_curr.splice(idx, 1)
                unsubunits_curr.splice(idx, 1)
                subunits_curr.splice(idx, 1)
                incunits_curr.splice(idx, 1)

                nonpromunits_prior.splice(idx, 1)
                promunits_prior.splice(idx, 1)
                unsubunits_prior.splice(idx, 1)
                subunits_prior.splice(idx, 1)
                incunits_prior.splice(idx, 1)

                nonpromunits_last.splice(idx, 1)
                promunits_last.splice(idx, 1)
                unsubunits_last.splice(idx, 1)
                subunits_last.splice(idx, 1)
                incunits_last.splice(idx, 1)

                nonpromunits_threeyear.splice(idx, 1)
                promunits_threeyear.splice(idx, 1)
                unsubunits_threeyear.splice(idx, 1)
                subunits_threeyear.splice(idx, 1)
                incunits_threeyear.splice(idx, 1)

                nonpromdollars_curr.splice(idx, 1)
                promdollars_curr.splice(idx, 1)
                unsubdollars_curr.splice(idx, 1)
                subdollars_curr.splice(idx, 1)
                incdollars_curr.splice(idx, 1)

                nonpromdollars_prior.splice(idx, 1)
                promdollars_prior.splice(idx, 1)
                unsubdollars_prior.splice(idx, 1)
                subdollars_prior.splice(idx, 1)
                incdollars_prior.splice(idx, 1)

                nonpromdollars_last.splice(idx, 1)
                promdollars_last.splice(idx, 1)
                unsubdollars_last.splice(idx, 1)
                subdollars_last.splice(idx, 1)
                incdollars_last.splice(idx, 1)

                nonpromdollars_threeyear.splice(idx, 1)
                promdollars_threeyear.splice(idx, 1)
                unsubdollars_threeyear.splice(idx, 1)
                subdollars_threeyear.splice(idx, 1)
                incdollars_threeyear.splice(idx, 1)

                items_for_col.splice(idx, 1)
                brands_for_col.splice(idx, 1)
            })

            metricstoview = [
                nonpromunits_curr, promunits_curr, unsubunits_curr, subunits_curr, incunits_curr,
                nonpromunits_prior, promunits_prior, unsubunits_prior, subunits_prior, incunits_prior,
            ]

            if (this.state.showLastViews === true) {
                metricstoview.push(nonpromunits_last)
                metricstoview.push(promunits_last)
                metricstoview.push(unsubunits_last)
                metricstoview.push(subunits_last)
                metricstoview.push(incunits_last)
            }

            if (this.state.showThreeYearViews === true) {
                metricstoview.push(nonpromunits_threeyear)
                metricstoview.push(promunits_threeyear)
                metricstoview.push(unsubunits_threeyear)
                metricstoview.push(subunits_threeyear)
                metricstoview.push(incunits_threeyear)
            }

            metricstoview.push(nonpromdollars_curr)
            metricstoview.push(promdollars_curr)
            metricstoview.push(unsubdollars_curr)
            metricstoview.push(subdollars_curr)
            metricstoview.push(incdollars_curr)
            metricstoview.push(nonpromdollars_prior)
            metricstoview.push(promdollars_prior)
            metricstoview.push(unsubdollars_prior)
            metricstoview.push(subdollars_prior)
            metricstoview.push(incdollars_prior)

            if (this.state.showLastViews === true) {
                metricstoview.push(nonpromdollars_last)
                metricstoview.push(promdollars_last)
                metricstoview.push(unsubdollars_last)
                metricstoview.push(subdollars_last)
                metricstoview.push(incdollars_last)
            }

            if (this.state.showThreeYearViews === true) {
                metricstoview.push(nonpromdollars_threeyear)
                metricstoview.push(promdollars_threeyear)
                metricstoview.push(unsubdollars_threeyear)
                metricstoview.push(subdollars_threeyear)
                metricstoview.push(incdollars_threeyear)
            }

            //If we have prior period, include it

            //metricstoview = [this.state.NonPromotedBarData_Dollars_All, this.state.PromotedBarData_Dollars_All, this.state.UnSubBarData_Dollars_All, this.state.SubBarData_Dollars_All, this.state.IncBarData_Dollars_All]
            metricstyle = 'currencyandunits'
        }
        else {
            let nonpromunits_curr = createMetricArray(all_period_metrics, all_items, 'current', 'nonpromotedunits', level, attrLevel)
            let promunits_curr = createMetricArray(all_period_metrics, all_items, 'current', 'promotedunits', level, attrLevel)
            let unsubunits_curr = createMetricArray(all_period_metrics, all_items, 'current', 'unsubsidizedbaseunits', level, attrLevel)
            let subunits_curr = createMetricArray(all_period_metrics, all_items, 'current', 'subsidizedbaseunits', level, attrLevel)
            let incunits_curr = createMetricArray(all_period_metrics, all_items, 'current', 'incrementalunits', level, attrLevel)

            let nonpromunits_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'nonpromotedunits', level, attrLevel)
            let promunits_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'promotedunits', level, attrLevel)
            let unsubunits_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'unsubsidizedbaseunits', level, attrLevel)
            let subunits_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'subsidizedbaseunits', level, attrLevel)
            let incunits_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'incrementalunits', level, attrLevel)

            let nonpromunits_last = createMetricArray(all_period_metrics, all_items, 'last', 'nonpromotedunits', level, attrLevel)
            let promunits_last = createMetricArray(all_period_metrics, all_items, 'last', 'promotedunits', level, attrLevel)
            let unsubunits_last = createMetricArray(all_period_metrics, all_items, 'last', 'unsubsidizedbaseunits', level, attrLevel)
            let subunits_last = createMetricArray(all_period_metrics, all_items, 'last', 'subsidizedbaseunits', level, attrLevel)
            let incunits_last = createMetricArray(all_period_metrics, all_items, 'last', 'incrementalunits', level, attrLevel)

            let nonpromunits_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'nonpromotedunits', level, attrLevel)
            let promunits_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'promotedunits', level, attrLevel)
            let unsubunits_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'unsubsidizedbaseunits', level, attrLevel)
            let subunits_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'subsidizedbaseunits', level, attrLevel)
            let incunits_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'incrementalunits', level, attrLevel)

            let nonpromdollars_curr = createMetricArray(all_period_metrics, all_items, 'current', 'nonpromoteddollars', level, attrLevel)
            let promdollars_curr = createMetricArray(all_period_metrics, all_items, 'current', 'promoteddollars', level, attrLevel)
            let unsubdollars_curr = createMetricArray(all_period_metrics, all_items, 'current', 'unsubsidizedbasedollars', level, attrLevel)
            let subdollars_curr = createMetricArray(all_period_metrics, all_items, 'current', 'subsidizedbasedollars', level, attrLevel)
            let incdollars_curr = createMetricArray(all_period_metrics, all_items, 'current', 'incrementaldollars', level, attrLevel)

            let nonpromdollars_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'nonpromoteddollars', level, attrLevel)
            let promdollars_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'promoteddollars', level, attrLevel)
            let unsubdollars_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'unsubsidizedbasedollars', level, attrLevel)
            let subdollars_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'subsidizedbasedollars', level, attrLevel)
            let incdollars_prior = createMetricArray(all_period_metrics, all_items, 'prior', 'incrementaldollars', level, attrLevel)

            let nonpromdollars_last = createMetricArray(all_period_metrics, all_items, 'last', 'nonpromoteddollars', level, attrLevel)
            let promdollars_last = createMetricArray(all_period_metrics, all_items, 'last', 'promoteddollars', level, attrLevel)
            let unsubdollars_last = createMetricArray(all_period_metrics, all_items, 'last', 'unsubsidizedbasedollars', level, attrLevel)
            let subdollars_last = createMetricArray(all_period_metrics, all_items, 'last', 'subsidizedbasedollars', level, attrLevel)
            let incdollars_last = createMetricArray(all_period_metrics, all_items, 'last', 'incrementaldollars', level, attrLevel)

            let nonpromdollars_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'nonpromoteddollars', level, attrLevel)
            let promdollars_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'promoteddollars', level, attrLevel)
            let unsubdollars_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'unsubsidizedbasedollars', level, attrLevel)
            let subdollars_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'subsidizedbasedollars', level, attrLevel)
            let incdollars_threeyear = createMetricArray(all_period_metrics, all_items, 'threeyear', 'incrementaldollars', level, attrLevel)

            metricstoview = [
                nonpromunits_curr, promunits_curr, unsubunits_curr, subunits_curr, incunits_curr,
                nonpromunits_prior, promunits_prior, unsubunits_prior, subunits_prior, incunits_prior,
            ]

            if (this.state.showLastViews === true) {
                metricstoview.push(nonpromunits_last)
                metricstoview.push(promunits_last)
                metricstoview.push(unsubunits_last)
                metricstoview.push(subunits_last)
                metricstoview.push(incunits_last)
            }

            if (this.state.showThreeYearViews === true) {
                metricstoview.push(nonpromunits_threeyear)
                metricstoview.push(promunits_threeyear)
                metricstoview.push(unsubunits_threeyear)
                metricstoview.push(subunits_threeyear)
                metricstoview.push(incunits_threeyear)
            }

            metricstoview.push(nonpromdollars_curr)
            metricstoview.push(promdollars_curr)
            metricstoview.push(unsubdollars_curr)
            metricstoview.push(subdollars_curr)
            metricstoview.push(incdollars_curr)
            metricstoview.push(nonpromdollars_prior)
            metricstoview.push(promdollars_prior)
            metricstoview.push(unsubdollars_prior)
            metricstoview.push(subdollars_prior)
            metricstoview.push(incdollars_prior)

            if (this.state.showLastViews === true) {
                metricstoview.push(nonpromdollars_last)
                metricstoview.push(promdollars_last)
                metricstoview.push(unsubdollars_last)
                metricstoview.push(subdollars_last)
                metricstoview.push(incdollars_last)
            }

            if (this.state.showThreeYearViews === true) {
                metricstoview.push(nonpromdollars_threeyear)
                metricstoview.push(promdollars_threeyear)
                metricstoview.push(unsubdollars_threeyear)
                metricstoview.push(subdollars_threeyear)
                metricstoview.push(incdollars_threeyear)
            }

            //If we have prior period, include it

            //metricstoview = [this.state.NonPromotedBarData_Dollars_All, this.state.PromotedBarData_Dollars_All, this.state.UnSubBarData_Dollars_All, this.state.SubBarData_Dollars_All, this.state.IncBarData_Dollars_All]
            metricstyle = 'currencyandunits'
        }
    }

    //Create Excel Sheet

    if (level === 'brand') {
        levelhr = 'Brand'
        if (usedollars) {
            levelstoview = all_items
        }
        else {
            levelstoview = all_items
        }
    }
    else if (level === 'item') {
        levelhr = 'Item'
        if (usedollars) {
            levelstoview = items_for_col            
        }
        else {
            levelstoview = items_for_col
        }
    }
    else if (level === 'Segment 1') {
        levelhr = this.state.segment1label
        if (usedollars) {
            levelstoview = all_items
        }
        else {
            levelstoview = all_items
        }
    }
    else if (level === 'Segment 2') {
        levelhr = this.state.segment2label
        if (usedollars) {
            levelstoview = all_items
        }
        else {
            levelstoview = all_items
        }
    }
    else if (level === 'Segment 3') {
        levelhr = this.state.segment3label
        if (usedollars) {
            levelstoview = all_items
        }
        else {
            levelstoview = all_items
        }
    }
    else if (level === 'Segment 4') {
        levelhr = this.state.segment4label
        if (usedollars) {
            levelstoview = all_items
        }
        else {
            levelstoview = all_items
        }
    }
    else if (attrLevel) {
        levelhr = this.state.attributenamelabels[level]
        if (usedollars) {
            levelstoview = all_items
        }
        else {
            levelstoview = all_items
        }
    }
    else {
        levelhr = level
        if (usedollars) {
            levelstoview = all_items
        }
        else {
            levelstoview = all_items
        }
    }

    //Try Creating A new Workbook Here....
    //File Information
    var name = 'All Data For ' + metricshr + ' by ' + levelhr
    var newdate = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
    datestring = datestring.replaceAll(',', '')
    let filenameexcel = name + ' - Extracted - ' + datestring
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Metrics');
    const worksheet = workbook.getWorksheet('Metrics');

    var headers = []
    var metric_start_col = 2
    if (level === 'item') {        
        worksheet.getColumn(1).values = brands_for_col
        worksheet.getColumn(2).values = levelstoview
        metric_start_col = 3
        headers = ['Brand',levelhr].concat(metricnames)
    }
    else {
        worksheet.getColumn(1).values = levelstoview
        metric_start_col = 2
        headers = [levelhr].concat(metricnames)
    }

    metricstoview.forEach((metric, idx) => {
        worksheet.getColumn(idx + metric_start_col).values = metric
        //worksheet.getColumn(idx + metric_start_col).values = metric.map(i => { return isNaN(i) ? 0 : i })
    })

    worksheet.insertRow(1, headers);

    //Format Columns Depending on Metrics Selected
    if (metricstyle === 'percent') {
        worksheet.getColumn(2).numFmt = '0.00%'
        worksheet.getColumn(3).numFmt = '0.00%'
        worksheet.getColumn(4).numFmt = '0.00%'
        worksheet.getColumn(5).numFmt = '0.00%'
        worksheet.getColumn(6).numFmt = '0.00%'
        worksheet.getColumn(7).numFmt = '0.00%'
        worksheet.getColumn(8).numFmt = '0.00%'
        worksheet.getColumn(9).numFmt = '0.00%'
        worksheet.getColumn(10).numFmt = '0.00%'
        worksheet.getColumn(11).numFmt = '0.00%'
        worksheet.getColumn(12).numFmt = '0.00%'
        worksheet.getColumn(13).numFmt = '0.00%'
        worksheet.getColumn(14).numFmt = '0.00%'
        worksheet.getColumn(15).numFmt = '0.00%'
        worksheet.getColumn(16).numFmt = '0.00%'
    }
    else if (metricstyle === 'units') {
        worksheet.getColumn(2).numFmt = '##,#0'
        worksheet.getColumn(3).numFmt = '##,#0'
        worksheet.getColumn(4).numFmt = '##,#0'
        worksheet.getColumn(5).numFmt = '##,#0'
        worksheet.getColumn(6).numFmt = '##,#0'
        worksheet.getColumn(7).numFmt = '##,#0'
        worksheet.getColumn(8).numFmt = '##,#0'
        worksheet.getColumn(9).numFmt = '##,#0'
        worksheet.getColumn(10).numFmt = '##,#0'
        worksheet.getColumn(11).numFmt = '##,#0'
        worksheet.getColumn(12).numFmt = '##,#0'
        worksheet.getColumn(13).numFmt = '##,#0'
        worksheet.getColumn(14).numFmt = '##,#0'
        worksheet.getColumn(15).numFmt = '##,#0'
        worksheet.getColumn(16).numFmt = '##,#0'
    }
    else if (metricstyle === 'currency') {
        worksheet.getColumn(2).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(3).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(4).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(5).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(6).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(7).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(8).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(9).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(10).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(11).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(12).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(13).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(14).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(15).numFmt = this.state.currencysymbol + '#,##0'
        worksheet.getColumn(16).numFmt = this.state.currencysymbol + '#,##0'
    }
    else if (metricstyle === 'currencyandunits') {
        //If We Have prior period format it...
        if (this.state.showThreeYearViews === true) {
            worksheet.getColumn(metric_start_col + 0).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 1).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 2).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 3).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 4).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 5).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 6).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 7).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 8).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 9).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 10).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 11).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 12).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 13).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 14).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 15).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 16).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 17).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 18).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 19).numFmt = '##,#0'

            worksheet.getColumn(metric_start_col + 20).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 21).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 22).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 23).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 24).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 25).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 26).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 27).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 28).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 29).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 30).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 32).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 33).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 34).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 35).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 36).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 37).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 38).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 39).numFmt = this.state.currencysymbol + '#,##0'
        }
        else if (this.state.showLastViews === true) {
            worksheet.getColumn(metric_start_col + 0).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 1).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 2).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 3).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 4).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 5).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 6).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 7).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 8).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 9).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 10).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 11).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 12).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 13).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 14).numFmt = '##,#0'

            worksheet.getColumn(metric_start_col + 15).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 16).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 17).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 18).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 19).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 20).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 21).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 22).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 23).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 24).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 25).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 26).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 27).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 28).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 29).numFmt = this.state.currencysymbol + '#,##0'
        }
        else {
            worksheet.getColumn(metric_start_col + 0).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 1).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 2).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 3).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 4).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 5).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 6).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 7).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 8).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 9).numFmt = '##,#0'
            worksheet.getColumn(metric_start_col + 10).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 11).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 12).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 13).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 14).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 15).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 16).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 17).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 18).numFmt = this.state.currencysymbol + '#,##0'
            worksheet.getColumn(metric_start_col + 19).numFmt = this.state.currencysymbol + '#,##0'
        }
    }

    //Add in Metdata
    var captionstring = this.state.attr_caption_text
    var titleinfo = this.state.chart_title_info.split('<br>')
    var brandsstring = this.state.brandsstring

    var date_threeyear_string = 'Three Years Ago Ranges From: ' + this.state.distinctdates_threeyears[0] + ' to ' + this.state.distinctdates_threeyears[this.state.distinctdates_threeyears.length - 1]
    var date_last_string = 'Two Years Ago Ranges From: ' + this.state.distinctdates_last[0] + ' to ' + this.state.distinctdates_last[this.state.distinctdates_last.length - 1]
    var date_prior_string = 'Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1]
    var date_curr_string = 'Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]

    //Add Selection Information
    const newsheet = workbook.addWorksheet('Selection Criteria');
    const newworksheet = workbook.getWorksheet('Selection Criteria')

    let titles = Object.keys(this.state.chartmetadata)
    let values = Object.values(this.state.chartmetadata)
    let values_updated = values.map(function (item) { return item.length > 32000 ? item.substring(0, 32000) : item; });

    var metadatadates
    if (this.state.ThreeYAGO === true) {
        metadatadates = 'Three Years Ago Ranges From: ' + this.state.distinctdates_threeyears[0] + ' to ' + this.state.distinctdates_threeyears[this.state.distinctdates_threeyears.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }
    else if (this.state.TwoYAGO === true) {
        metadatadates = 'Two Years Ago Ranges From: ' + this.state.distinctdates_last[0] + ' to ' + this.state.distinctdates_last[this.state.distinctdates_last.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }
    else {
        metadatadates = 'Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }

    titles.push('Comparison Date Range: ')
    values_updated.push(metadatadates)

    newworksheet.getColumn(1).values = titles
    newworksheet.getColumn(2).values = values_updated

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, filenameexcel + fileExtension);

}