import { saveAs } from "file-saver";
const ExcelJS = require('exceljs');
export default async function downloadScoreCardDataYOY_All(event) {
    alert("Downloading All Data")
    console.log('Scorecard Export Started')

    var name = 'Key Metrics Data'
    var newdate = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
    datestring = datestring.replaceAll(',', '')

    //get metadata

    var metadatabrands = ""
    var metadatasegments = ""
    var metadatageos = ""
    var metadatadates = ""
    var metadataattributes = ""
    var metadatapricebands = ""
    var metadataasprange = ""

    try {
        //metadatabrands = this.state.chartmetadata_singlebrand.brands
        //metadatageos = this.state.chartmetadata_singlebrand.geography
        if (this.state.channelviews === true) {
            metadatabrands = this.state.brandsstring
            metadatageos = this.state.geos[0] + ', ' + this.state.selectedChannel
        }
        else {
            metadatabrands = this.state.selectedBrandLabel
            metadatageos = this.state.chartmetadata_singlebrand.geography
        }
                
        metadatasegments = this.state.chartmetadata_singlebrand.segments                
        metadataattributes = this.state.chartmetadata_singlebrand.attributes
        metadatapricebands = this.state.chartmetadata_singlebrand.pricebands
        metadataasprange = this.state.chartmetadata_singlebrand.asprange

        metadatadates = 'Three Years Ago Ranges From: ' + this.state.distinctdates_threeyears[0] + ' to ' + this.state.distinctdates_threeyears[this.state.distinctdates_threeyears.length - 1] + ' Two Years Ago Ranges From: ' + this.state.distinctdates_last[0] + ' to ' + this.state.distinctdates_last[this.state.distinctdates_last.length - 1] + ' Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]

    }
    catch (error) {

    }


    var headers = [
        'Metric'
        , 'Current Year Value'
        , 'Previous Year Value'
        , 'Current Year Difference From Previous Year'
        , 'Current Year % Difference From Previous Year'
        , 'Two Years Ago Value'
        , 'Current Year Difference From Two Years Ago'
        , 'Current Year % Difference From Two Years Ago'
        , 'Three Years Ago Value'
        , 'Current Year Difference From Three Years Ago'
        , 'Current Year % Difference From Three Years Ago'
    ]

    //Metrics for Scorecard
    var subbasedollars_curr = this.state.revopp_my
    var nonpromoted_dollars_curr = this.state.nonpromoteddollars_my
    var promoted_dollars_curr = this.state.promoteddollars_my
    var subbasedollars_prior = this.state.revopp_my_prior
    var nonpromoted_dollars_prior = this.state.nonpromoteddollars_my_prior
    var promoted_dollars_prior = this.state.promoteddollars_my_prior
    var subbasedollars_last = this.state.revopp_my_last
    var nonpromoted_dollars_last = this.state.nonpromoteddollars_my_last
    var promoted_dollars_last = this.state.promoteddollars_my_last

    var subbasedollars_threeyears = this.state.revopp_my_threeyears
    var nonpromoted_dollars_threeyears = this.state.nonpromoteddollars_my_threeyears
    var promoted_dollars_threeyears = this.state.promoteddollars_my_threeyears

    var base_units_curr = this.state.base_my
    var sub_base_units_curr = this.state.sub_my
    var unsub_base_units_curr = base_units_curr - sub_base_units_curr
    var unsub_base_units_curr_pct = unsub_base_units_curr / base_units_curr
    var sub_base_units_curr_pct = sub_base_units_curr / base_units_curr
    var inc_units_curr = this.state.inc_my
    var nonpromo_units_curr = this.state.nonpromo_my
    var promo_units_curr = this.state.promo_my
    var total_units_curr = base_units_curr + inc_units_curr
    var base_unit_pct_curr = base_units_curr / total_units_curr
    var inc_unit_pct_curr = inc_units_curr / total_units_curr
    var nonpromo_unit_pct_curr = nonpromo_units_curr / total_units_curr
    var promo_unit_pct_curr = promo_units_curr / total_units_curr
    var lift_curr = inc_units_curr / base_units_curr
    var lift_promo_curr = inc_units_curr / sub_base_units_curr
    var eff_curr = inc_units_curr / promo_units_curr
    var avg_base_price_curr = this.state.weightedbp_my / this.state.total_my
    var avg_disc_curr = Math.min((this.state.disc_my / this.state.total_my), 1)
    var avg_disc_withoutno_curr = Math.min((this.state.disc_my_withoutno / this.state.total_my_withoutno), 1)
    var incremental_dollars_curr = this.state.incdollars_my
    var disc_dollars_curr = this.state.discdollars_my
    var payback_ratio_curr = incremental_dollars_curr / disc_dollars_curr
    var total_dollars_curr = this.state.totaldollars_my
    var nonpromo_dollars_curr = this.state.nonpromoteddollars_my
    var promo_dollars_curr = this.state.promoteddollars_my
    var base_dollars_curr = this.state.basedollars_my
    var inc_dollars_curr = total_dollars_curr - base_dollars_curr
    var base_dollars_pct_curr = base_dollars_curr / total_dollars_curr
    var inc_dollars_pct_curr = inc_dollars_curr / total_dollars_curr
    var nonpromo_dollars_pct_curr = nonpromo_dollars_curr / total_dollars_curr
    var promo_dollars_pct_curr = promo_dollars_curr / total_dollars_curr
    var avg_promo_price_curr = promo_dollars_curr / promo_units_curr
    var sub_base_dollars_curr = this.state.revopp_my
    var unsub_base_dollars_curr = base_dollars_curr - subbasedollars_curr
    var unsub_base_dollars_curr_pct = unsub_base_dollars_curr / base_dollars_curr
    var sub_base_dollars_curr_pct = sub_base_dollars_curr / base_dollars_curr
    var avg_nonpromo_price_curr = nonpromoted_dollars_curr / nonpromo_units_curr
    var avg_selling_price_curr = total_dollars_curr / total_units_curr
    var lift_curr_dollars = (incremental_dollars_curr / base_dollars_curr)
    var lift_promo_curr_dollars = (incremental_dollars_curr / subbasedollars_curr)
    var eff_curr_dollars = (incremental_dollars_curr / promoted_dollars_curr)

    //Metrics for Scorecard -- Prior
    var base_units_prior
    var sub_base_units_prior
    var unsub_base_units_prior
    var unsub_base_units_prior_pct
    var sub_base_units_prior_pct
    var inc_units_prior
    var nonpromo_units_prior
    var promo_units_prior
    var total_units_prior
    var base_unit_pct_prior
    var inc_unit_pct_prior
    var nonpromo_unit_pct_prior
    var promo_unit_pct_prior
    var lift_prior
    var lift_promo_prior
    var eff_prior
    var avg_base_price_prior
    var avg_disc_prior
    var avg_disc_withoutno_prior
    var incremental_dollars_prior
    var disc_dollars_prior
    var payback_ratio_prior
    var total_dollars_prior
    var nonpromo_dollars_prior
    var promo_dollars_prior
    var base_dollars_prior
    var inc_dollars_prior
    var base_dollars_pct_prior
    var inc_dollars_pct_prior
    var nonpromo_dollars_pct_prior
    var promo_dollars_pct_prior
    var avg_promo_price_prior
    var sub_base_dollars_prior
    var subbase_dollars_prior
    var unsub_base_dollars_prior
    var unsub_base_dollars_prior_pct
    var sub_base_dollars_prior_pct
    var avg_nonpromo_price_prior
    var avg_selling_price_prior
    var lift_prior_dollars
    var lift_promo_prior_dollars
    var eff_prior_dollars

    //Metrics for Scorecard -- Prior
    var base_units_last
    var sub_base_units_last
    var unsub_base_units_last
    var unsub_base_units_last_pct
    var sub_base_units_last_pct
    var inc_units_last
    var nonpromo_units_last
    var promo_units_last
    var total_units_last
    var base_unit_pct_last
    var inc_unit_pct_last
    var nonpromo_unit_pct_last
    var promo_unit_pct_last
    var lift_last
    var lift_promo_last
    var eff_last
    var avg_base_price_last
    var avg_disc_last
    var avg_disc_withoutno_last
    var incremental_dollars_last
    var disc_dollars_last
    var payback_ratio_last
    var total_dollars_last
    var nonpromo_dollars_last
    var promo_dollars_last
    var base_dollars_last
    var inc_dollars_last
    var base_dollars_pct_last
    var inc_dollars_pct_last
    var nonpromo_dollars_pct_last
    var promo_dollars_pct_last
    var avg_promo_price_last
    var sub_base_dollars_last
    var subbase_dollars_last
    var unsub_base_dollars_last
    var unsub_base_dollars_last_pct
    var sub_base_dollars_last_pct
    var avg_nonpromo_price_last
    var avg_selling_price_last
    var lift_last_dollars
    var lift_promo_last_dollars
    var eff_last_dollars


    //Metrics for Scorecard -- Prior
    var base_units_threeyears
    var sub_base_units_threeyears
    var unsub_base_units_threeyears
    var unsub_base_units_threeyears_pct
    var sub_base_units_threeyears_pct
    var inc_units_threeyears
    var nonpromo_units_threeyears
    var promo_units_threeyears
    var total_units_threeyears
    var base_unit_pct_threeyears
    var inc_unit_pct_threeyears
    var nonpromo_unit_pct_threeyears
    var promo_unit_pct_threeyears
    var lift_threeyears
    var lift_promo_threeyears
    var eff_threeyears
    var avg_base_price_threeyears
    var avg_disc_threeyears
    var avg_disc_withoutno_threeyears
    var incremental_dollars_threeyears
    var disc_dollars_threeyears
    var payback_ratio_threeyears
    var total_dollars_threeyears
    var nonpromo_dollars_threeyears
    var promo_dollars_threeyears
    var base_dollars_threeyears
    var inc_dollars_threeyears
    var base_dollars_pct_threeyears
    var inc_dollars_pct_threeyears
    var nonpromo_dollars_pct_threeyears
    var promo_dollars_pct_threeyears
    var avg_promo_price_threeyears
    var sub_base_dollars_threeyears
    var subbase_dollars_threeyears
    var unsub_base_dollars_threeyears
    var unsub_base_dollars_threeyears_pct
    var sub_base_dollars_threeyears_pct
    var avg_nonpromo_price_threeyears
    var avg_selling_price_threeyears
    var lift_threeyears_dollars
    var lift_promo_threeyears_dollars
    var eff_threeyears_dollars
            
    base_units_prior = this.state.base_my_prior
    sub_base_units_prior = this.state.sub_my_prior
    unsub_base_units_prior = base_units_prior - sub_base_units_prior
    unsub_base_units_prior_pct = unsub_base_units_prior / base_units_prior
    sub_base_units_prior_pct = sub_base_units_prior / base_units_prior
    inc_units_prior = this.state.inc_my_prior
    nonpromo_units_prior = this.state.nonpromo_my_prior
    promo_units_prior = this.state.promo_my_prior
    total_units_prior = base_units_prior + inc_units_prior
    base_unit_pct_prior = (base_units_prior / total_units_prior) || 0
    inc_unit_pct_prior = (inc_units_prior / total_units_prior) || 0
    nonpromo_unit_pct_prior = (nonpromo_units_prior / total_units_prior) || 0
    promo_unit_pct_prior = (promo_units_prior / total_units_prior) || 0
    lift_prior = (inc_units_prior / base_units_prior) || 0
    lift_promo_prior = (inc_units_prior / sub_base_units_prior) || 0
    eff_prior = (inc_units_prior / promo_units_prior) || 0
    avg_base_price_prior = (this.state.weightedbp_my_prior / this.state.total_my_prior) || 0
    avg_disc_prior = Math.min((((this.state.disc_my_prior / this.state.total_my_prior)) || 0), 1)
    avg_disc_withoutno_prior = Math.min((((this.state.disc_my_withoutno_prior / this.state.total_my_withoutno_prior)) || 0), 1)
    incremental_dollars_prior = this.state.incdollars_my_prior
    disc_dollars_prior = this.state.discdollars_my_prior
    payback_ratio_prior = (incremental_dollars_prior / disc_dollars_prior) || 0
    total_dollars_prior = this.state.totaldollars_my_prior
    nonpromo_dollars_prior = this.state.nonpromoteddollars_my_prior
    promo_dollars_prior = this.state.promoteddollars_my_prior
    base_dollars_prior = this.state.basedollars_my_prior
    inc_dollars_prior = total_dollars_prior - base_dollars_prior
    base_dollars_pct_prior = (base_dollars_prior / total_dollars_prior) || 0
    inc_dollars_pct_prior = (inc_dollars_prior / total_dollars_prior) || 0
    nonpromo_dollars_pct_prior = (nonpromo_dollars_prior / total_dollars_prior) || 0
    promo_dollars_pct_prior = (promo_dollars_prior / total_dollars_prior) || 0
    avg_promo_price_prior = promo_dollars_prior / promo_units_prior
    sub_base_dollars_prior = this.state.revopp_my_prior
    unsub_base_dollars_prior = base_dollars_prior - subbasedollars_prior
    unsub_base_dollars_prior_pct = unsub_base_dollars_prior / base_dollars_prior
    sub_base_dollars_prior_pct = sub_base_dollars_prior / base_dollars_prior
    avg_nonpromo_price_prior = nonpromoted_dollars_prior / nonpromo_units_prior
    avg_selling_price_prior = total_dollars_prior / total_units_prior
    lift_prior_dollars = (incremental_dollars_prior / base_dollars_prior)
    lift_promo_prior_dollars = (incremental_dollars_prior / subbasedollars_prior)
    eff_prior_dollars = (incremental_dollars_prior / promoted_dollars_prior)
            

    base_units_last = this.state.base_my_last
    sub_base_units_last = this.state.sub_my_last
    unsub_base_units_last = base_units_last - sub_base_units_last
    unsub_base_units_last_pct = unsub_base_units_last / base_units_last
    sub_base_units_last_pct = sub_base_units_last / base_units_last
    inc_units_last = this.state.inc_my_last
    nonpromo_units_last = this.state.nonpromo_my_last
    promo_units_last = this.state.promo_my_last
    total_units_last = base_units_last + inc_units_last
    base_unit_pct_last = (base_units_last / total_units_last) || 0
    inc_unit_pct_last = (inc_units_last / total_units_last) || 0
    nonpromo_unit_pct_last = (nonpromo_units_last / total_units_last) || 0
    promo_unit_pct_last = (promo_units_last / total_units_last) || 0
    lift_last = (inc_units_last / base_units_last) || 0
    lift_promo_last = (inc_units_last / sub_base_units_last) || 0
    eff_last = (inc_units_last / promo_units_last) || 0
    avg_base_price_last = (this.state.weightedbp_my_last / this.state.total_my_last) || 0
    avg_disc_last = Math.min((((this.state.disc_my_last / this.state.total_my_last)) || 0), 1)
    avg_disc_withoutno_last = Math.min((((this.state.disc_my_withoutno_last / this.state.total_my_withoutno_last)) || 0), 1)
    nonpromoted_dollars_last = this.state.nonpromoteddollars_my_last
    promoted_dollars_last = this.state.promoteddollars_my_last
    incremental_dollars_last = this.state.incdollars_my_last
    disc_dollars_last = this.state.discdollars_my_last
    payback_ratio_last = (incremental_dollars_last / disc_dollars_last) || 0
    total_dollars_last = this.state.totaldollars_my_last
    nonpromo_dollars_last = this.state.nonpromoteddollars_my_last
    promo_dollars_last = this.state.promoteddollars_my_last
    base_dollars_last = this.state.basedollars_my_last
    inc_dollars_last = total_dollars_last - base_dollars_last //this.state.incrementaldollars_my_last
    base_dollars_pct_last = (base_dollars_last / total_dollars_last) || 0
    inc_dollars_pct_last = (inc_dollars_last / total_dollars_last) || 0
    nonpromo_dollars_pct_last = (nonpromo_dollars_last / total_dollars_last) || 0
    promo_dollars_pct_last = (promo_dollars_last / total_dollars_last) || 0
    avg_promo_price_last = promo_dollars_last / promo_units_last
    subbase_dollars_last = this.state.revopp_my_last
    sub_base_dollars_last = this.state.revopp_my_last
    unsub_base_dollars_last = base_dollars_last - subbase_dollars_last
    unsub_base_dollars_last_pct = unsub_base_dollars_last / base_dollars_last
    sub_base_dollars_last_pct = subbase_dollars_last / base_dollars_last
    avg_nonpromo_price_last = nonpromoted_dollars_last / nonpromo_units_last
    avg_selling_price_last = total_dollars_last / total_units_last
    lift_last_dollars = (incremental_dollars_last / base_dollars_last)
    lift_promo_last_dollars = (incremental_dollars_curr / subbase_dollars_last)
    eff_last_dollars = (incremental_dollars_last / promoted_dollars_last)


    base_units_threeyears = this.state.base_my_threeyears
    sub_base_units_threeyears = this.state.sub_my_threeyears
    unsub_base_units_threeyears = base_units_threeyears - sub_base_units_threeyears
    unsub_base_units_threeyears_pct = unsub_base_units_threeyears / base_units_threeyears
    sub_base_units_threeyears_pct = sub_base_units_threeyears / base_units_threeyears
    inc_units_threeyears = this.state.inc_my_threeyears
    nonpromo_units_threeyears = this.state.nonpromo_my_threeyears
    promo_units_threeyears = this.state.promo_my_threeyears
    total_units_threeyears = base_units_threeyears + inc_units_threeyears
    base_unit_pct_threeyears = (base_units_threeyears / total_units_threeyears) || 0
    inc_unit_pct_threeyears = (inc_units_threeyears / total_units_threeyears) || 0
    nonpromo_unit_pct_threeyears = (nonpromo_units_threeyears / total_units_threeyears) || 0
    promo_unit_pct_threeyears = (promo_units_threeyears / total_units_threeyears) || 0
    lift_threeyears = (inc_units_threeyears / base_units_threeyears) || 0
    lift_promo_threeyears = (inc_units_threeyears / sub_base_units_threeyears) || 0
    eff_threeyears = (inc_units_threeyears / promo_units_threeyears) || 0
    avg_base_price_threeyears = (this.state.weightedbp_my_threeyears / this.state.total_my_threeyears) || 0
    avg_disc_threeyears = Math.min((((this.state.disc_my_threeyears / this.state.total_my_threeyears)) || 0), 1)
    avg_disc_withoutno_threeyears = Math.min((((this.state.disc_my_withoutno_threeyears / this.state.total_my_withoutno_threeyears)) || 0), 1)
    nonpromoted_dollars_threeyears = this.state.nonpromoteddollars_my_threeyears
    promoted_dollars_threeyears = this.state.promoteddollars_my_threeyears
    incremental_dollars_threeyears = this.state.incdollars_my_threeyears
    disc_dollars_threeyears = this.state.discdollars_my_threeyears
    payback_ratio_threeyears = (incremental_dollars_threeyears / disc_dollars_threeyears) || 0
    total_dollars_threeyears = this.state.totaldollars_my_threeyears
    nonpromo_dollars_threeyears = this.state.nonpromoteddollars_my_threeyears
    promo_dollars_threeyears = this.state.promoteddollars_my_threeyears
    base_dollars_threeyears = this.state.basedollars_my_threeyears
    inc_dollars_threeyears = total_dollars_threeyears - base_dollars_threeyears //this.state.incrementaldollars_my_threeyears
    base_dollars_pct_threeyears = (base_dollars_threeyears / total_dollars_threeyears) || 0
    inc_dollars_pct_threeyears = (inc_dollars_threeyears / total_dollars_threeyears) || 0
    nonpromo_dollars_pct_threeyears = (nonpromo_dollars_threeyears / total_dollars_threeyears) || 0
    promo_dollars_pct_threeyears = (promo_dollars_threeyears / total_dollars_threeyears) || 0
    avg_promo_price_threeyears = promo_dollars_threeyears / promo_units_threeyears
    subbase_dollars_threeyears = this.state.revopp_my_threeyears
    sub_base_dollars_threeyears = this.state.revopp_my_threeyears
    unsub_base_dollars_threeyears = base_dollars_threeyears - subbase_dollars_threeyears
    unsub_base_dollars_threeyears_pct = unsub_base_dollars_threeyears / base_dollars_threeyears
    sub_base_dollars_threeyears_pct = subbase_dollars_threeyears / base_dollars_threeyears
    avg_nonpromo_price_threeyears = nonpromoted_dollars_threeyears / nonpromo_units_threeyears
    avg_selling_price_threeyears = total_dollars_threeyears / total_units_threeyears
    lift_threeyears_dollars = (incremental_dollars_threeyears / base_dollars_threeyears)
    lift_promo_threeyears_dollars = (incremental_dollars_curr / subbase_dollars_threeyears)
    eff_threeyears_dollars = (incremental_dollars_threeyears / promoted_dollars_threeyears)
            

    var metrics = [
        'Total Units'
        , 'Base Units'
        , 'Incremental Units'
        , 'Base Units % of Total'
        , 'Incremental Units % of Total'
        , 'Unsubsidized Base Units'
        , 'Subsidized Base Units'
        , 'Unsubsidized Base Units % of Base'
        , 'Subsidized Base Units % of Base'
        , 'Non-Promoted Units'
        , 'Promoted Units'
        , 'Non-Promoted Units % of Total'
        , 'Promoted Units % of Total'
        , 'Total Dollars'
        , 'Non-Promoted Dollars'
        , 'Promoted Dollars'
        , 'Base Dollars'
        , 'Incremental Dollars'
        , 'Unsubsidized Base Dollars'
        , 'Subsidized Base Dollars'
        , 'Non-Promoted % of Total Dollars'
        , 'Promoted % of Total Dollars'
        , 'Base % of Total Dollars'
        , 'Incremental % of Total Dollars'
        , 'Unsubsidized Base % of Base Dollars'
        , 'Subsidized Base % of Base Dollars'
        , 'Average Base Price'
        , 'Average Selling Price'
        , 'Average Promo Price'
        , 'Average Non-Promo Price'
        , 'Average Discount'
        , 'Average Promoted Discount'
        , 'Discount Dollars'
        , 'Market Lift'
        , 'Promoted Lift'
        , 'Efficiency'
        , 'Market Lift (Dollars)'
        , 'Promoted Lift (Dollars)'
        , 'Efficiency (Dollars)'
        , 'Payback Ratio'
        //, 'Average Base Price in Promoted Discount Ranges'
    ]

    var curr_metrics = [
        total_units_curr
        , base_units_curr
        , inc_units_curr
        , base_unit_pct_curr
        , inc_unit_pct_curr
        , unsub_base_units_curr
        , sub_base_units_curr
        , unsub_base_units_curr_pct
        , sub_base_units_curr_pct
        , nonpromo_units_curr
        , promo_units_curr
        , nonpromo_unit_pct_curr
        , promo_unit_pct_curr
        , total_dollars_curr
        , nonpromo_dollars_curr
        , promo_dollars_curr
        , base_dollars_curr
        , inc_dollars_curr
        , unsub_base_dollars_curr
        , sub_base_dollars_curr
        , nonpromo_dollars_pct_curr
        , promo_dollars_pct_curr
        , base_dollars_pct_curr
        , inc_dollars_pct_curr
        , unsub_base_dollars_curr_pct
        , sub_base_dollars_curr_pct
        , avg_base_price_curr
        , avg_selling_price_curr
        , avg_promo_price_curr
        , avg_nonpromo_price_curr
        , avg_disc_curr
        , avg_disc_withoutno_curr
        , disc_dollars_curr
        , lift_curr
        , lift_promo_curr
        , eff_curr
        , lift_curr_dollars
        , lift_promo_curr_dollars
        , eff_curr_dollars
        , payback_ratio_curr
    ]

    var prior_metrics = [
        total_units_prior
        , base_units_prior
        , inc_units_prior
        , base_unit_pct_prior
        , inc_unit_pct_prior
        , unsub_base_units_prior
        , sub_base_units_prior
        , unsub_base_units_prior_pct
        , sub_base_units_prior_pct
        , nonpromo_units_prior
        , promo_units_prior
        , nonpromo_unit_pct_prior
        , promo_unit_pct_prior
        , total_dollars_prior
        , nonpromo_dollars_prior
        , promo_dollars_prior
        , base_dollars_prior
        , inc_dollars_prior
        , unsub_base_dollars_prior
        , sub_base_dollars_prior
        , nonpromo_dollars_pct_prior
        , promo_dollars_pct_prior
        , base_dollars_pct_prior
        , inc_dollars_pct_prior
        , unsub_base_dollars_prior_pct
        , sub_base_dollars_prior_pct
        , avg_base_price_prior
        , avg_selling_price_prior
        , avg_promo_price_prior
        , avg_nonpromo_price_prior
        , avg_disc_prior
        , avg_disc_withoutno_prior
        , disc_dollars_prior
        , lift_prior
        , lift_promo_prior
        , eff_prior
        , lift_prior_dollars
        , lift_promo_prior_dollars
        , eff_prior_dollars
        , payback_ratio_prior
    ]

    var last_metrics = [
        total_units_last
        , base_units_last
        , inc_units_last
        , base_unit_pct_last
        , inc_unit_pct_last
        , unsub_base_units_last
        , sub_base_units_last
        , unsub_base_units_last_pct
        , sub_base_units_last_pct
        , nonpromo_units_last
        , promo_units_last
        , nonpromo_unit_pct_last
        , promo_unit_pct_last
        , total_dollars_last
        , nonpromo_dollars_last
        , promo_dollars_last
        , base_dollars_last
        , inc_dollars_last
        , unsub_base_dollars_last
        , sub_base_dollars_last
        , nonpromo_dollars_pct_last
        , promo_dollars_pct_last
        , base_dollars_pct_last
        , inc_dollars_pct_last
        , unsub_base_dollars_last_pct
        , sub_base_dollars_last_pct
        , avg_base_price_last
        , avg_selling_price_last
        , avg_promo_price_last
        , avg_nonpromo_price_last
        , avg_disc_last
        , avg_disc_withoutno_last
        , disc_dollars_last
        , lift_last
        , lift_promo_last
        , eff_last
        , lift_last_dollars
        , lift_promo_last_dollars
        , eff_last_dollars
        , payback_ratio_last
    ]


    var threeyears_metrics = [
        total_units_threeyears
        , base_units_threeyears
        , inc_units_threeyears
        , base_unit_pct_threeyears
        , inc_unit_pct_threeyears
        , unsub_base_units_threeyears
        , sub_base_units_threeyears
        , unsub_base_units_threeyears_pct
        , sub_base_units_threeyears_pct
        , nonpromo_units_threeyears
        , promo_units_threeyears
        , nonpromo_unit_pct_threeyears
        , promo_unit_pct_threeyears
        , total_dollars_threeyears
        , nonpromo_dollars_threeyears
        , promo_dollars_threeyears
        , base_dollars_threeyears
        , inc_dollars_threeyears
        , unsub_base_dollars_threeyears
        , sub_base_dollars_threeyears
        , nonpromo_dollars_pct_threeyears
        , promo_dollars_pct_threeyears
        , base_dollars_pct_threeyears
        , inc_dollars_pct_threeyears
        , unsub_base_dollars_threeyears_pct
        , sub_base_dollars_threeyears_pct
        , avg_base_price_threeyears
        , avg_selling_price_threeyears
        , avg_promo_price_threeyears
        , avg_nonpromo_price_threeyears
        , avg_disc_threeyears
        , avg_disc_withoutno_threeyears
        , disc_dollars_threeyears
        , lift_threeyears
        , lift_promo_threeyears
        , eff_threeyears
        , lift_threeyears_dollars
        , lift_promo_threeyears_dollars
        , eff_threeyears_dollars
        , payback_ratio_threeyears
    ]

    var diff_metrics = curr_metrics.map((metric, idx) => { return (metric - prior_metrics[idx]) })
    var pct_diff_metrics = curr_metrics.map((metric, idx) => { return ((metric - prior_metrics[idx]) / prior_metrics[idx]) })

    var diff_metrics_last = curr_metrics.map((metric, idx) => { return (metric - last_metrics[idx]) })
    var pct_diff_metrics_last = curr_metrics.map((metric, idx) => { return ((metric - last_metrics[idx]) / last_metrics[idx]) })

    var diff_metrics_threeyears = curr_metrics.map((metric, idx) => { return (metric - threeyears_metrics[idx]) })
    var pct_diff_metrics_threeyears = curr_metrics.map((metric, idx) => { return ((metric - threeyears_metrics[idx]) / threeyears_metrics[idx]) })

    //Try Creating A new Workbook Here....
    let filenameexcel = name + ' - Extracted - ' + datestring
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Metrics');
    const worksheet = workbook.getWorksheet('Metrics');


    worksheet.getColumn(1).values = metrics //A
    worksheet.getColumn(2).values = curr_metrics //B
    worksheet.getColumn(3).values = prior_metrics //C
    worksheet.getColumn(4).values = diff_metrics //D
    worksheet.getColumn(5).values = pct_diff_metrics //E
    worksheet.getColumn(6).values = last_metrics //F
    worksheet.getColumn(7).values = diff_metrics_last //G
    worksheet.getColumn(8).values = pct_diff_metrics_last //H

    worksheet.getColumn(9).values = threeyears_metrics //I
    worksheet.getColumn(10).values = diff_metrics_threeyears //J
    worksheet.getColumn(11).values = pct_diff_metrics_threeyears //K

    //Set Styling
    function setFormat_Number(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = '##,#';
        })

    }

    function setFormat_Percent(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = '0.00%';
        })

    }

    function setFormat_Currency(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = currencysymbol + '#,##0';
        })

    }

    function setFormat_Currency_Price(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = currencysymbol + '#,##.00';
        })

    }

    function setFormat_PPTs(worksheet, cols) {
        cols.forEach(col => {
            let value = worksheet.getCell(col).value
            let newValue = String((value * 100).toFixed(3)) + ' ppts'
            worksheet.getCell(col).value = newValue
        })

    }

    function setFormat_Empty(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).value = null
        })

    }

    function setFormat_Decimal(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getCell(col).numFmt = '##,#.00';
        })

    }

    setFormat_Number(worksheet, ['B1', 'C1', 'D1', 'B2', 'C2', 'D2', 'B3', 'C3', 'D3', 'B6', 'C6', 'D6', 'B7', 'C7', 'D7', 'B10', 'C10', 'D10', 'B11', 'C11', 'D11', 'I1', 'J1', 'I2', 'J2', 'I3', 'J3', 'I6', 'J6', 'I7', 'J7', 'I10', 'J10', 'I11', 'J11', 'I1', 'J1', 'I2', 'J2', 'I3', 'J3', 'I6', 'J6', 'I7', 'J7', 'I10', 'J10', 'I11', 'J11'])
    setFormat_Percent(worksheet,
        ['B4', 'C4', 'B5', 'C5', 'B8', 'C8', 'B9', 'C9', 'B12', 'C12', 'B13', 'C13',
            'B21', 'C21', 'B22', 'C22', 'B23', 'C23', 'B24', 'C24', 'B25', 'C25', 'B26', 'C26',
            'B31', 'C31', 'B32', 'C32', 'B34', 'C34', 'B35', 'C35', 'B36', 'C36', 'B37', 'C37',
            'B38', 'C38', 'B39', 'C39',
            'F4', 'F5', 'F8', 'F9', 'F12', 'F13',
            'F21', 'F22', 'F23', 'F24', 'F25', 'F26',
            'F31', 'F32', 'F34', 'F35', 'F36', 'F37',
            'F38', 'F39',
            'I4', 'I5', 'I8', 'I9', 'I12', 'I13',
            'I21', 'I22', 'I23', 'I24', 'I25', 'I26',
            'I31', 'I32', 'I34', 'I35', 'I36', 'I37',
            'I38', 'I39'

        ])
    setFormat_Currency(worksheet, ['B14', 'C14', 'D14',
        'B15', 'C15', 'D15',
        'B16', 'C16', 'D16',
        'B17', 'C17', 'D17',
        'B18', 'C18', 'D18',
        'B19', 'C19', 'D19',
        'B20', 'C20', 'D20',
        'B33', 'C33', 'D33',
        'F14', 'G14',
        'F15', 'G15',
        'F16', 'G16',
        'F17', 'G17',
        'F18', 'G18',
        'F19', 'G19',
        'F20', 'G20',
        'F33', 'G33',
        'I14', 'J14',
        'I15', 'J15',
        'I16', 'J16',
        'I17', 'J17',
        'I18', 'J18',
        'I19', 'J19',
        'I20', 'J20',
        'I33', 'J33',

    ], this.state.currencysymbol)
    setFormat_Currency_Price(worksheet, ['B27', 'C27', 'D27',
        'B28', 'C28', 'D28',
        ' B29', 'C29', 'D29',
        'B30', 'C30', 'D30',
        'F27', 'G27',
        'F28', 'G28',
        'F29', 'G29',
        'F30', 'G30',

        'I27', 'J27',
        'I28', 'J28',
        'I29', 'J29',
        'I30', 'J30',

    ], this.state.currencysymbol)
    setFormat_PPTs(worksheet, ['D4', 'D5', 'D8', 'D9', 'D12', 'D13', 'D21', 'D22', 'D23', 'D24', 'D25', 'D26', 'D31', 'D32', 'D34', 'D35', 'D36', 'D37', 'D38', 'D39',
        'G4', 'G5', 'G8', 'G9', 'G12', 'G13', 'G21', 'G22', 'G23', 'G24', 'G25', 'G26', 'G31', 'G32', 'G34', 'G35', 'G36', 'G37', 'G38', 'G39',
        'J4', 'J5', 'J8', 'J9', 'J12', 'J13', 'J21', 'J22', 'J23', 'J24', 'J25', 'J26', 'J31', 'J32', 'J34', 'J35', 'J36', 'J37', 'J38', 'J39'
    ])
    setFormat_Empty(worksheet, ['E4', 'E5', 'E8', 'E9', 'E12', 'E13', 'E21', 'E22', 'E23', 'E24', 'E25', 'E26', 'E31', 'E32', 'E34', 'E35', 'E36', 'E37', 'E38', 'E39', 'E40', 
        'H4', 'H5', 'H8', 'H9', 'H12', 'H13', 'H21', 'H22', 'H23', 'H24', 'H25', 'H26', 'H31', 'H32', 'H34', 'H35', 'H36', 'H37', 'H38', 'H39', 'H40',
        'K4', 'K5', 'K8', 'K9', 'K12', 'K13', 'K21', 'K22', 'K23', 'K24', 'K25', 'K26', 'K31', 'K32', 'K34', 'K35', 'K36', 'K37', 'K38', 'K39', 'K40',
    ])
    setFormat_Decimal(worksheet, ['B40', 'C40', 'D40', 'F40', 'G40', 'I40', 'J40'])

    worksheet.getColumn(5).numFmt = '0.00%'
    worksheet.getColumn(8).numFmt = '0.00%'
    worksheet.getColumn(11).numFmt = '0.00%'

    //Add Headers

    worksheet.insertRow(1, headers);

    //Set Widths
    function setWidthAndAlignment(worksheet, colnum, widthnum) {
        var Col
        Col = worksheet.getColumn(colnum);
        Col.width = widthnum;
        Col.eachCell(function (cell, rowNumber) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        })
    }

    setWidthAndAlignment(worksheet, 1, 35)
    setWidthAndAlignment(worksheet, 2, 20)
    setWidthAndAlignment(worksheet, 3, 20)
    setWidthAndAlignment(worksheet, 4, 15)
    setWidthAndAlignment(worksheet, 5, 20)
    setWidthAndAlignment(worksheet, 6, 20)
    setWidthAndAlignment(worksheet, 7, 15)
    setWidthAndAlignment(worksheet, 8, 20)
    setWidthAndAlignment(worksheet, 9, 20)
    setWidthAndAlignment(worksheet, 10, 15)
    setWidthAndAlignment(worksheet, 11, 20)

    //Add Selection Information
    const newsheet = workbook.addWorksheet('Selection Criteria');
    const newworksheet = workbook.getWorksheet('Selection Criteria')

    let titles = Object.keys(this.state.chartmetadata)
    let values = Object.values(this.state.chartmetadata)

    var metadatadates
    if (this.state.ThreeYAGO === true) {
        metadatadates = 'Three Years Ago Ranges From: ' + this.state.distinctdates_threeyears[0] + ' to ' + this.state.distinctdates_threeyears[this.state.distinctdates_threeyears.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }
    else if (this.state.TwoYAGO === true) {
        metadatadates = 'Two Years Ago Ranges From: ' + this.state.distinctdates_last[0] + ' to ' + this.state.distinctdates_last[this.state.distinctdates_last.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }
    else {
        metadatadates = 'Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    }

    titles.push('Comparison Date Range: ')
    values.push(metadatadates)

    newworksheet.getColumn(1).values = titles
    newworksheet.getColumn(2).values = values


    setWidthAndAlignment(newworksheet, 1, 22)

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, filenameexcel + fileExtension);
}