export default function handleChangeRadioBasePromo(event){
    var type = event.currentTarget.value
    if (type === 'Base') {

        this.setState({ baseselected: true, promoselected: false, showSnackbar_changebrands: true })
    }
    else {

        this.setState({ baseselected: false, promoselected: true, showSnackbar_changebrands: true })
    }

}