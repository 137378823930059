import React, { Component, Fragment } from 'react';
import { FlexibleView } from '../ReportOutputComponents/FlexibleReportViews/FlexibleView'
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import a11yProps from '../Functions/Utilities/TabPropsFunc'
import * as Constant from '../../constants'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class FlexibleViewSection extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        return (
            <Fragment>
                <div align='center' className="contentHolderTight pdtOutputGraphs" >
                    <h5>Flexible View</h5>
                    <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }}>
                        <Constant.NPDTabs
                            value={0}
                            //onChange={this.props.handleChangeCompTabValue}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Constant.NPDTab label="FLEXIBLE VIEW"  {...a11yProps(0)} />
                        </Constant.NPDTabs>
                    </Constant.NPDAppbar>
                    <TabPanel value={0} index={0}>
                        <FlexibleView
                            state={this.props.state}
                            changeViewFilters={this.props.changeViewFilters}
                            changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                            handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                            handleToggle_stackingType={this.props.handleToggle_stackingType}
                            handleChange_IndexSwitchDollars={this.props.handleChange_IndexSwitchDollars}
                        />
                    </TabPanel>
                </div>

            </Fragment>
        )
    }
}