import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as Constant from '../../../constants';

export class BrandItemsSwitch extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Fragment>
                {this.props.state.showitem_views === true ?
                    <div className="row" style={{ width: "100%", height: "100%" }}>
                        <ThemeProvider theme={Constant.muiTheme}>
                            <span style={{ lineHeight: '5' }}>Brands</span>
                            <div style={{ lineHeight: '5' }}>
                                <Switch
                                    checked={this.props.state.viewItems}
                                    onChange={this.props.handleChange_BrandsItemsSwitch}
                                    color="secondary"
                                />
                            </div>
                            <span style={{ lineHeight: '5' }}>Items </span>
                        </ThemeProvider>
                    </div>
                    :
                    <div />
                }
            </Fragment>
        )
    }
}