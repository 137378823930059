import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as Constant from '../../../constants';

export class UnitsDollarSwitch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        return(
            <Fragment>
                <ThemeProvider theme={Constant.muiTheme}>
                    <span style={{ lineHeight: '5' }}>Units</span>
                    <div style={{ lineHeight: '5' }}>
                        <Switch
                            checked={this.props.state.indexSwitchCheckDollars}
                            onChange={this.props.handleChange_IndexSwitchDollars}
                            color="secondary"
                        />
                    </div>
                    <span style={{ lineHeight: '5' }}>{this.props.state.currencyType} </span>
                </ThemeProvider>
            </Fragment>
        )
    }
}