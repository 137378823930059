export default async function clearDropdowns() {

    const all_value = [{ 'label': 'All', 'value': 'All' }]

    let filter_view_all = {
        'brand': all_value,
        'item': all_value,
        'priceband': all_value,
        'outlet': all_value,
        'channel': all_value,
        'segment1': all_value,
        'segment2': all_value,
        'segment3': all_value,
        'segment4': all_value,
    }

    //Add each attribute
    Object.keys(this.state.customAttrGroups).forEach(attribute => { filter_view_all[attribute] = all_value })

    let filter_view_selected = filter_view_all
    this.setState({ filter_view_selected })
}