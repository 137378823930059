import * as Constant from '../constants'

export function handleCloseGroupInfo() {
    this.setState({ showgroupinfo: false })
}

export function handleCloseGroupInfoNew() {
    this.setState({ showgroupinfo_new: false })
}

export function handleCloseGroupPerms() {
    this.setState({ showgroupperms: false })
}

export function handleCloseGroupUsers() {
    this.setState({ showgroupusers: false })
}

export function handleOpenNewGroup() {
    this.setState({
        showgroupinfo_new: true,
        groupinfodata: {},
        groupinfoName: null,
        groupinfoPermsPDT: [],
        groupinfoPermsPNP: [],
    })
}

export function handleChangePageGroups(event, newPage, type) {
    if (type === 'pnpdatasetperms') {
        this.setState({
            page_group_pnp_dataset_perms: newPage,
            page_start_group_pnp_dataset_perms: newPage * this.state.rowsPerPage_group_pnp_dataset_perms
        })
    } else if (type === 'pdtdatasetperms') {
        this.setState({
            page_group_pdt_dataset_perms: newPage,
            page_start_group_pdt_dataset_perms: newPage * this.state.rowsPerPage_group_pdt_dataset_perms
        })
    }
}

export function handleChangeRowsPerPageGroups(event, type) {
    if (type === 'pnpdatasetperms') {
        this.setState({
            rowsPerPage_group_pnp_dataset_perms: parseInt(event.target.value, 10),
            page_group_pnp_dataset_perms: 0,
            page_start_group_pnp_dataset_perms: 0,
        })
    } else if (type === 'pdtdatasetperms') {
        this.setState({
            rowsPerPage_group_pdt_dataset_perms: parseInt(event.target.value, 10),
            page_group_pdt_dataset_perms: 0,
            page_start_group_pdt_dataset_perms: 0
        })
    }
}

export function handleChangeGroupName(event, newValue) {
    var input = document.getElementById("groupinfoName")
    let value = input.value
    console.log(value)
    this.setState({ groupinfoName: value })
}

export async function handleSubmitGroup(newUsers) {
    let group_id = this.state.groupinfodata.group_id
    console.log('Updating Group ' + group_id)

    this.setState({ group_edit_button_enabled: false, group_membership_button_enabled: false })

    var groupinfo = {
        group_id: group_id,
        time_updated: this.state.groupinfodata.time_updated,
        group_name: this.state.groupinfoName,
        pdt_permissions: this.state.groupinfodata.pdt_permissions,
        pnp_permissions: this.state.groupinfodata.pnp_permissions,
        user_ids: newUsers
    }

    let results = await this.entityCrud('updating group ' + group_id, [
        { 'url': Constant.baseapiurl_gen + 'Groups/' + group_id, 'method': 'PUT', 'body': groupinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchGroupList()
        this.handleCloseGroupInfo()
        this.handleCloseGroupUsers()
        alert('Changes Made Successfully')
    }

    this.setState({ group_edit_button_enabled: true, group_membership_button_enabled: true })
}

export async function handleSubmitGroupNew() {
    console.log('Creating new Group')

    this.setState({ group_edit_button_enabled: false, group_membership_button_enabled: false })

    var groupinfo = {
        group_id: null,
        time_updated: null,
        group_name: this.state.groupinfoName,
        pdt_permissions: [],
        pnp_permissions: [],
        user_ids: []
    }

    let results = await this.entityCrud('creating new group', [
        { 'url': Constant.baseapiurl_gen + 'Groups', 'method': 'POST', 'body': groupinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchGroupList()
        this.handleCloseGroupInfoNew()
        this.handleCloseGroupUsers()
        alert('Changes Made Successfully')
    }

    this.setState({ group_edit_button_enabled: true, group_membership_button_enabled: true })
}

export async function handleSubmitGroupChangesPerms() {
    let group_id = this.state.groupinfodata.group_id
    console.log('Submitting Permission Changes for Group ID ' + group_id)

    this.setState({ group_edit_perm_button_enabled: false })

    var finalperms_pnp = []
    this.state.groupinfodata.pnp_permissions.forEach(perm => {
        // Handle permissions that are newly-added
        if (perm.permission_id < 0) {
            perm.permission_id = null
            perm.time_updated = null
        }

        // Cleanup geogids to make ints
        perm.geogids = perm.geogids.map(geogid => parseInt(geogid))

        // If shortdesc !== 'Add a Dataset' push to final perms
        if (perm.shortdesc !== 'Add a Dataset') {
            finalperms_pnp.push(perm)
        }
    })

    var finalperms_pdt = []
    this.state.groupinfodata.pdt_permissions.forEach(perm => {
        // Handle permissions that are newly-added
        if (perm.permission_id < 0) {
            perm.permission_id = null
            perm.time_updated = null
        }

        // Cleanup geogids to make ints
        perm.store_groups = perm.store_groups.map(storegroup => parseInt(storegroup))

        // If shortdesc !== 'Add a Dataset' push to final perms
        if (perm.short_desc !== 'Add a Dataset') {
            finalperms_pdt.push(perm)
        }
    })

    var groupinfo = {
        group_id,
        time_updated: this.state.groupinfodata.time_updated,
        group_name: this.state.groupinfoName,
        pdt_permissions: finalperms_pdt,
        pnp_permissions: finalperms_pnp,
        user_ids: this.state.groupinfodata.user_ids
    }

    let results = await this.entityCrud('updating group permissions for group ID ' + group_id, [
        { 'url': Constant.baseapiurl_gen + 'Groups/' + group_id, 'method': 'PUT', 'body': groupinfo },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchGroupList()
        alert('Changes Made Successfully')
        this.handleCloseGroupPerms()
    }

    this.setState({ group_edit_perm_button_enabled: true })
}

export async function handleDeleteGroup() {
    this.setState({ group_delete_button_enabled: false })
    var group_id = this.state.groupinfodata.group_id
    let results = await this.entityCrud('deleting group ID ' + group_id, [
        { 'url': Constant.baseapiurl_gen + 'Groups/' + group_id, 'method': 'DELETE', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        await this.fetchGroupList()
        this.handleCloseGroupInfo()
        alert('Group Deleted Successfully')
    }

    this.setState({ group_delete_button_enabled: true })
}

export function handleChangeGroupPerms(selectedOptions, app, idx, perm) {
    let index = idx + ((app === 'pnp') ? this.state.page_start_group_pnp_dataset_perms : this.state.page_start_group_pdt_dataset_perms)
    let page = (app === 'pnp') ? this.state.page_group_pnp_dataset_perms : this.state.page_group_pdt_dataset_perms
    let common = 'For current group popup, setting ' + app + ' index #' + index + ' (page ' + page + ', idx ' + idx + ') '
    if (perm === 'dataset') {
        console.log(common + ' dataset ' + selectedOptions.label + ' (' + selectedOptions.value + ')')
    } else {
        console.log(common + ' geos ' + (selectedOptions || []).map(x => x.label + ' (' + x.value + ')').join(', '))
    }

    var permission_object
    if (app === 'pnp') {
        var permissionsPNP = this.state.groupinfoPermsPNP
        permission_object = this.state.groupinfoPermsPNP[index]

        if (perm === 'dataset') {
            // The user selected a dataset
            permission_object['geogids'] = [] // Remove Geos since we're selecting a new or different PNP dataset
            permission_object['dataset_id'] = selectedOptions.value
            permission_object['shortdesc'] = selectedOptions.label
        } else if (perm === 'geos') {
            // The user selected a geo
            permission_object['geogids'] = (selectedOptions || []).map(option => option.value)
        }

        permissionsPNP[index] = permission_object
        this.setState({ groupinfoPermsPNP: permissionsPNP })
    } else if (app === 'pdt') {
        var permissionsPDT = this.state.groupinfoPermsPDT
        permission_object = this.state.groupinfoPermsPDT[index]

        if (perm === 'dataset') {
            // The user selected a dataset
            permission_object['store_groups'] = [] // Remove store groups since we're selecting a new or different PDT dataset
            permission_object['dataset_id'] = selectedOptions.value
            permission_object['short_desc'] = selectedOptions.label
        } else if (perm === 'geos') {
            // The user selected a store group
            permission_object['store_groups'] = (selectedOptions || []).map(option => option.value)
        }

        permissionsPDT[index] = permission_object
        this.setState({ groupinfoPermsPDT: permissionsPDT })
    }
}

export function removeGroupPermission(permissionid, app) {
    console.log('For current group popup, removing ' + app + ' permission # ' + permissionid)

    if (app === 'pnp') {
        let permissions = this.state.groupinfoPermsPNP
        console.log(permissions)
        permissions.every((permission, idx) => {
            if (permission.permission_id === permissionid) {
                console.log('Found permission entry to remove at index ' + idx + ':')
                permissions.splice(idx, 1)
                return false
            }
            return true
        })

        console.log('New permissions:')
        console.log(permissions)
        this.setState({ groupinfoPermsPNP: permissions })
    } else if (app === 'pdt') {
        let permissions = this.state.groupinfoPermsPDT
        console.log(permissions)
        permissions.every((permission, idx) => {
            if (permission.permission_id === permissionid) {
                console.log('Found permission entry to remove at index ' + idx + ':')
                permissions.splice(idx, 1)
                return false
            }
            return true
        })

        console.log('New permissions:')
        console.log(permissions)
        this.setState({ groupinfoPermsPDT: permissions })
    }
}

export function addNewGroupPermission(app, group_id) {
    var addedPermissionsCounter = this.state.addedPermissionsCounter - 1

    if (app === 'pnp') {
        let permissions = this.state.groupinfoPermsPNP
        permissions.push(
            {
                'group_id': group_id,
                'shortdesc': 'Add a Dataset',
                'permission_id': this.state.addedPermissionsCounter,
                'dataset_id': null,
                'geogids': [],
                'time_updated': ''
            }
        )
        this.setState({ groupinfoPermsPNP: permissions, addedPermissionsCounter })
    } else if (app === 'pdt') {
        let permissions = this.state.groupinfoPermsPDT
        permissions.push(
            {
                'group_id': group_id,
                'shortdesc': 'Add a Dataset',
                'permission_id': this.state.addedPermissionsCounter,
                'dataset_id': null,
                'store_groups': [],
                'time_updated': ''
            }
        )
        this.setState({ groupinfoPermsPDT: permissions, addedPermissionsCounter })
    }
}

export function handleChangeUsersCheckbox(userid) {
    console.log('Toggling presence of user ID ' + userid + ' in group')
    let currentusers = this.state.groupinfoUsers
    console.log(currentusers)
    let newusers = []
    if (currentusers.includes(userid)) {
        currentusers.forEach(user => {
            if (user !== userid) {
                newusers.push(user)
            }
        })
    } else {
        newusers = currentusers
        newusers.push(userid)
    }
    console.log(newusers)
    this.setState({ groupinfoUsers: newusers })
}

export async function getGroupInfo(group_id) {
    console.log('Getting group information for ID ' + group_id)

    let results = await this.entityCrud('getting information for group ID ' + group_id, [
        { 'url': Constant.baseapiurl_gen + 'Groups/' + group_id, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var groupinfodata = results['ResultList'][0]
        this.setState({
            groupinfodata: groupinfodata,
            groupinfoName: groupinfodata.group_name,
            showgroupinfo: true
        })
    }
}

export async function getGroupPerms(group_id) {
    console.log('Getting permissions for group ID ' + group_id)

    let results = await this.entityCrud('getting permissions for group ID ' + group_id, [
        { 'url': Constant.baseapiurl_gen + 'Groups/' + group_id, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var groupinfodata = results['ResultList'][0]
        this.setState({
            groupinfodata: groupinfodata,
            groupinfoName: groupinfodata.group_name,
            groupinfoPermsPDT: groupinfodata.pdt_permissions,
            groupinfoPermsPNP: groupinfodata.pnp_permissions,
            page_group_pnp_dataset_perms: 0,
            page_start_group_pnp_dataset_perms: 0,
            page_group_pdt_dataset_perms: 0,
            page_start_group_pdt_dataset_perms: 0,
            showgroupperms: true
        })
    }
}

export async function getGroupMembers(group_id) {
    console.log('Getting users for group ID ' + group_id)

    let results = await this.entityCrud('getting permissions for group ID ' + group_id, [
        { 'url': Constant.baseapiurl_gen + 'Groups/' + group_id, 'method': 'GET', 'body': null },
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var groupinfodata = results['ResultList'][0]
        var groupinfoUsers = groupinfodata.user_ids

        // get how many users per company are selected...
        var users_selected_by_company = {}
        this.state.users_by_company.forEach(company => {
            users_selected_by_company[company.company_id] = 0
            company.users.forEach(user => {
                if (groupinfoUsers.includes(user.user_id)) {
                    users_selected_by_company[company.company_id] += 1
                }
            })
        })

        this.setState({
            groupinfodata: groupinfodata,
            groupinfoName: groupinfodata.group_name,
            groupinfoPermsPDT: groupinfodata.pdt_permissions,
            groupinfoPermsPNP: groupinfodata.pnp_permissions,
            groupinfoUsers: groupinfoUsers,
            users_selected_by_company,
            showgroupusers: true
        })
    }
}

export async function fetchGroupList() {
    console.log('Fetching list of all groups')

    let results = await this.entityCrud('getting the list of all groups', [
        { 'url': Constant.baseapiurl_gen + 'Groups', 'method': 'GET', 'body': null }
    ], this.on_api_entity_crud_error)
    if (results['Errors'] === 0) {
        var groupsdata = results['ResultList'][0]
        var group_options = groupsdata.map(group => { return { 'value': group.group_id, 'label': group.group_name } })
        this.setState({
            groupsdata: groupsdata,
            group_options: group_options
        })
    }
}
