import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export class ReDirectDialog extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    render() {

        return (
            <Dialog
                open={this.props.state.newpagewarningdialog || false}
                onClose={this.props.handleCloseNewPageAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ zIndex: '99999999' }}
            >
                <DialogTitle id="alert-dialog-title">{"Feature will Open in a New Page"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This feature will open in a new page, and after modifying your settings, the report page will have to be reloaded.  Continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={this.props.handleCloseNewPageAlert} className="btn btn-secondarynpd" autoFocus>
                        Cancel
                    </button>

                    <button onClick={this.props.redirectfunction} className="btn btn-primary" autoFocus>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
}

