import * as Constant from '../../../constants'
import assignFilterLevels from '../Utilities/AssignFilterLevels'
import { reducer } from '../Utilities/Reducer'

export default async function ASPWaterfallCalc(filters_current, filters_prior) {
    console.log('Calculating Items for ASP Waterfall')
    let reportid = this.state.reportsettings.report_id
    let extraparam = this.state.extraparam
    let url_level2selection = Constant.baseapiurl + "ReportData/Filtered/Level2Data/" + reportid + extraparam
    let filters_prod_brand_curr = assignFilterLevels(filters_current, ['brand', 'proddesc'])
    let filters_prod_brand_prior = assignFilterLevels(filters_prior, ['brand', 'proddesc'])

    let my_results = await this.FetchDataWithErrorHandling([
        { 'url': url_level2selection, 'method': 'POST', 'body': filters_prod_brand_curr },
        { 'url': url_level2selection, 'method': 'POST', 'body': filters_prod_brand_prior }
    ], 'ASPWaterfallCalc.js ASPWaterfallCalc()', 'ASP Waterfall Calc')
    let items_curr_obj = my_results['ReturnList'][0]
    let items_prior_obj = my_results['ReturnList'][1]

    var item_data_curr = (items_curr_obj['level2_data']) || {}  //change to items_brand_data
    var item_data_prior = (items_prior_obj['level2_data']) || {} //change to items_brand_data

    //let all_brands = Array.from(new Set(Object.keys(item_data_curr).concat(Object.keys(item_data_prior))))
    let temp_brands = Object.keys(item_data_curr).concat(Object.keys(item_data_prior))
    let all_brands = Array.from(new Set(temp_brands))

    let curr_items = []
    let prior_items = []
    Object.keys(item_data_curr).forEach(brand => curr_items = curr_items.concat(Object.keys(item_data_curr[brand])))
    Object.keys(item_data_prior).forEach(brand => prior_items = prior_items.concat(Object.keys(item_data_prior[brand])))
    let all_items = Array.from(new Set(curr_items.concat(prior_items)))

    let all_period_metrics = {}

    let zero_object = {
        'basedollars': 0,
        'baseunits': 0,
        'carrieddoors': 0,
        'discountdollars': 0,
        'incrementaldollars': 0,
        'incrementalunits': 0,
        'nonpromoteddollars': 0,
        'nonpromotedunits': 0,
        'promoteddollars': 0,
        'promotedunits': 0,
        'revopportunity': 0,
        'subsidizedbasedollars': 0,
        'subsidizedbaseunits': 0,
        'totaldollars': 0,
        'totaldoors_s1': 0,
        'totaldoors_s1_segment': 0,
        'totaldoors_s2': 0,
        'totaldoors_s2_segment': 0,
        'totalunits': 0,
        'totalunits_withoutno': 0,
        'unsubsidizedbasedollars': 0,
        'unsubsidizedbaseunits': 0,
        'weightedasp': 0,
        'weightedbp': 0,
        'weightedbp_withoutno': 0,
        'weightedpctdisc': 0,
        'weightedpctdisc_withoutno': 0,
    }

    all_brands.forEach(brand => {
        all_period_metrics[brand] = all_period_metrics[brand] || {}
        item_data_curr[brand] = item_data_curr[brand] || {}
        item_data_prior[brand] = item_data_prior[brand] || {}
        all_items.forEach(item => {
            all_period_metrics[brand][item] = {
                'current': item_data_curr[brand][item] || zero_object,
                'prior': item_data_prior[brand][item] || zero_object,
            }
        })
    })

    // Remove Total Category and Total Selection from All Brands for Share Calcs
    //let all_brands_nocat_nosel = all_brands;
    let all_brands_nocat_nosel = Object.assign([], all_brands);
    let selidx = all_brands_nocat_nosel.indexOf('Total Selection')
    if (selidx > -1) {
        all_brands_nocat_nosel.splice(selidx, 1)
    }
    let catidx = all_brands_nocat_nosel.indexOf('Total Category')
    if (catidx > -1) {
        all_brands_nocat_nosel.splice(catidx, 1)
    }

    // Create Denominators for Share
    let curr_total_dollars_forshare = [0].concat(all_brands_nocat_nosel.map(brand => [0].concat(all_items.map(item => all_period_metrics[brand][item]['current']['totaldollars'])).reduce(reducer))).reduce(reducer)
    let curr_total_units_forshare = [0].concat(all_brands_nocat_nosel.map(brand => [0].concat(all_items.map(item => all_period_metrics[brand][item]['current']['totalunits'])).reduce(reducer))).reduce(reducer)
    let prior_total_dollars_forshare = [0].concat(all_brands_nocat_nosel.map(brand => [0].concat(all_items.map(item => all_period_metrics[brand][item]['prior']['totaldollars'])).reduce(reducer))).reduce(reducer)
    let prior_total_units_forshare = [0].concat(all_brands_nocat_nosel.map(brand => [0].concat(all_items.map(item => all_period_metrics[brand][item]['prior']['totalunits'])).reduce(reducer))).reduce(reducer)

    console.log(all_period_metrics)

    all_period_metrics['curr_total_dollars_forshare'] = curr_total_dollars_forshare
    all_period_metrics['curr_total_units_forshare'] = curr_total_units_forshare

    all_period_metrics['prior_total_dollars_forshare'] = prior_total_dollars_forshare
    all_period_metrics['prior_total_units_forshare'] = prior_total_units_forshare

    // Check Total Units From this Year Compared to Last Year
    // Create a Key For Summary Metrics for the Whole Category
    all_brands.forEach(brand => {
        all_items.forEach(item => {
            // Current
            let totalunits_curr = all_period_metrics[brand][item]['current']['totalunits'] || 0
            let totaldollars_curr = all_period_metrics[brand][item]['current']['totaldollars'] || 0

            let dollarshare_curr = totaldollars_curr / curr_total_dollars_forshare
            let unitsshare_curr = totalunits_curr / curr_total_units_forshare

            let nonpromoprice_curr = all_period_metrics[brand][item]['current']['nonpromoteddollars'] / all_period_metrics[brand][item]['current']['nonpromotedunits']
            let promoprice_curr = all_period_metrics[brand][item]['current']['promoteddollars'] / all_period_metrics[brand][item]['current']['promotedunits']
            let baseprice_curr = all_period_metrics[brand][item]['current']['weightedbp'] / all_period_metrics[brand][item]['current']['totalunits']
            let aspprice_curr = all_period_metrics[brand][item]['current']['weightedasp'] / all_period_metrics[brand][item]['current']['totalunits']
            // Prior
            let totalunits_prior = all_period_metrics[brand][item]['prior']['totalunits']
            let totaldollars_prior = all_period_metrics[brand][item]['prior']['totaldollars']

            let dollarshare_prior = totaldollars_prior / prior_total_dollars_forshare
            let unitsshare_prior = totalunits_prior / prior_total_units_forshare

            let nonpromoprice_prior = all_period_metrics[brand][item]['prior']['nonpromoteddollars'] / all_period_metrics[brand][item]['prior']['nonpromotedunits']
            let promoprice_prior = all_period_metrics[brand][item]['prior']['promoteddollars'] / all_period_metrics[brand][item]['prior']['promotedunits']
            let baseprice_prior = all_period_metrics[brand][item]['prior']['weightedbp'] / all_period_metrics[brand][item]['prior']['totalunits']
            let aspprice_prior = all_period_metrics[brand][item]['prior']['weightedasp'] / all_period_metrics[brand][item]['prior']['totalunits']

            // Clean Up Prices

            if (!nonpromoprice_curr) {
                nonpromoprice_curr = 0
            }

            if (!promoprice_curr) {
                promoprice_curr = 0
            }

            if (!nonpromoprice_prior) {
                nonpromoprice_prior = 0
            }

            if (!promoprice_prior) {
                promoprice_prior = 0
            }

            if (!baseprice_curr) {
                baseprice_curr = 0
            }

            if (!baseprice_prior) {
                baseprice_prior = 0
            }

            if (!aspprice_curr) {
                aspprice_curr = 0
            }

            if (!aspprice_prior) {
                aspprice_prior = 0
            }

            // Determine Classification
            let units_delta_numeric = totalunits_curr - totalunits_prior
            let units_delta_percent = units_delta_numeric / totalunits_curr
            let dollars_delta_numeric = totaldollars_curr - totaldollars_prior
            let dollars_delta_percent = dollars_delta_numeric / totaldollars_curr
            var classification

            // If the Number of Units in the Prior Period is Less Than or Equal to 0, then New Item
            if (totalunits_prior <= 0) {
                classification = 'New'
            } else if (units_delta_percent > 5) {
                classification = 'Dropped'
            } else if (totalunits_curr <= 0 && totalunits_prior >= 0) {
                classification = 'Dropped'
            } else if (units_delta_percent < -5) {
                classification = 'Dropped'
            } else {
                classification = 'Continuing'
            }

            var price_classification_nonpromo, price_classification_promo, price_classification_base, price_classification_asp
            if (nonpromoprice_curr <= nonpromoprice_prior && classification === 'Continuing') {
                price_classification_nonpromo = 'Decrease'
            } else if (nonpromoprice_curr > nonpromoprice_prior && classification === 'Continuing') {
                price_classification_nonpromo = 'Increase'
            } else {
                price_classification_nonpromo = 'No Comparison'
            }

            if (promoprice_curr <= promoprice_prior && classification === 'Continuing') {
                price_classification_promo = 'Decrease'
            } else if (promoprice_curr > promoprice_prior && classification === 'Continuing') {
                price_classification_promo = 'Increase'
            } else {
                price_classification_promo = 'No Comparison'
            }

            if (baseprice_curr <= baseprice_prior && classification === 'Continuing') {
                price_classification_base = 'Decrease'
            } else if (baseprice_curr > baseprice_prior && classification === 'Continuing') {
                price_classification_base = 'Increase'
            } else {
                price_classification_base = 'No Comparison'
            }

            if (aspprice_curr <= aspprice_prior && classification === 'Continuing') {
                price_classification_asp = 'Decrease'
            } else if (aspprice_curr > aspprice_prior && classification === 'Continuing') {
                price_classification_asp = 'Increase'
            } else {
                price_classification_asp = 'No Comparison'
            }

            // Set keys in the allmetrics
            // Share
            all_period_metrics[brand][item]['dollarshare_curr'] = dollarshare_curr
            all_period_metrics[brand][item]['unitsshare_curr'] = unitsshare_curr
            all_period_metrics[brand][item]['dollarshare_prior'] = dollarshare_prior
            all_period_metrics[brand][item]['unitsshare_prior'] = unitsshare_prior
            // Prices
            all_period_metrics[brand][item]['nonpromoprice_curr'] = nonpromoprice_curr
            all_period_metrics[brand][item]['promoprice_curr'] = promoprice_curr
            all_period_metrics[brand][item]['nonpromoprice_prior'] = nonpromoprice_prior
            all_period_metrics[brand][item]['promoprice_prior'] = promoprice_prior
            all_period_metrics[brand][item]['baseprice_curr'] = baseprice_curr
            all_period_metrics[brand][item]['baseprice_prior'] = baseprice_prior
            all_period_metrics[brand][item]['aspprice_curr'] = aspprice_curr
            all_period_metrics[brand][item]['aspprice_prior'] = aspprice_prior
            // Classifications
            all_period_metrics[brand][item]['classification'] = classification
            all_period_metrics[brand][item]['price_classification_nonpromo'] = price_classification_nonpromo
            all_period_metrics[brand][item]['price_classification_promo'] = price_classification_promo
            all_period_metrics[brand][item]['price_classification_base'] = price_classification_base
            all_period_metrics[brand][item]['price_classification_asp'] = price_classification_asp
            // Deltas
            all_period_metrics[brand][item]['units_delta_numeric'] = units_delta_numeric
            all_period_metrics[brand][item]['units_delta_percent'] = units_delta_percent
            all_period_metrics[brand][item]['dollars_delta_numeric'] = dollars_delta_numeric
            all_period_metrics[brand][item]['dollars_delta_percent'] = dollars_delta_percent
        })
    })

    this.setState({
        all_period_metrics,
        all_items_for_yoy: all_items,
        all_brands_for_yoy: all_brands
    })
}
