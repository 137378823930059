import { saveAs } from "file-saver";
import * as Constant from '../../../constants'
const ExcelJS = require('exceljs');

export default async function downloadFlexibleTableDataCommon(which_table, defaultLevelsSelected, filename_base) {
    console.log('Common download was invoked for ' + which_table)
    let summedData_current_level2 = this.state['level2_' + which_table + '_table_currentyear'] || {}
    let summedData_current_level3 = this.state['level3_' + which_table + '_table_currentyear'] || {}
    let summedData_current_level4 = this.state['level4_' + which_table + '_table_currentyear'] || {}
    let current_data = summedData_current_level4

    let summedData_prior_level3 = this.state['level3_' + which_table + '_table_oneyearago'] || {}
    let past_data = this.state['level4_' + which_table + '_table_oneyearago'] || {}

    // If two years ago is checked, change prior datasets
    if (this.state.TwoYAGO) {
        summedData_prior_level3 = this.state['level3_' + which_table + '_table_twoyearsago'] || {}
        past_data = this.state['level4_' + which_table + '_table_twoyearsago'] || {}
    }

    let level1 = Object.keys(current_data)

    let level2 = []
    level1.forEach(level1 => {
        let level1_obj = (current_data[level1] || {})
        level2 = level2.concat(Object.keys(level1_obj))
    })
    level2 = Array.from(new Set(level2))

    let level3 = []
    level1.forEach(level1 => {
        level2.forEach(level2 => {
            let level1_obj = (current_data[level1] || {})
            let level2_obj = (level1_obj[level2] || {})
            level3 = level3.concat(Object.keys(level2_obj))
        })
    })
    level3 = Array.from(new Set(level3))

    let level4 = []
    level1.forEach(level1 => {
        level2.forEach(level2 => {
            level3.forEach(level3 => {
                let level1_obj = (current_data[level1] || {})
                let level2_obj = (level1_obj[level2] || {})
                let level3_obj = (level2_obj[level3] || {})
                level4 = level4.concat(Object.keys(level3_obj))
            })
        })
    })
    level4 = Array.from(new Set(level4))

    let level1_prior = Object.keys(past_data)

    let level2_prior = []
    level1_prior.forEach(level1_prior => {
        let level1_prior_obj = (past_data[level1_prior] || {})
        level2_prior = level2_prior.concat(Object.keys(level1_prior_obj))
    })
    level2_prior = Array.from(new Set(level2_prior))

    let level3_prior = []
    level1_prior.forEach(level1_prior => {
        level2_prior.forEach(level2_prior => {
            let level1_prior_obj = (past_data[level1_prior] || {})
            let level2_prior_obj = (level1_prior_obj[level2_prior] || {})
            level3_prior = level3_prior.concat(Object.keys(level2_prior_obj))
        })
    })
    level3_prior = Array.from(new Set(level3_prior))

    let level4_prior = []
    level1_prior.forEach(level1_prior => {
        level2_prior.forEach(level2_prior => {
            level3_prior.forEach(level3_prior => {
                let level1_prior_obj = (past_data[level1_prior] || {})
                let level2_prior_obj = (level1_prior_obj[level2_prior] || {})
                let level3_prior_obj = (level2_prior_obj[level3_prior] || {})
                level4_prior = level4_prior.concat(Object.keys(level3_prior_obj))
            })
        })
    })
    level4_prior = Array.from(new Set(level4_prior))

    // Sort values
    level1.sort()
    level2.sort()
    level3.sort()
    level4.sort()
    level1_prior.sort()
    level2_prior.sort()
    level3_prior.sort()
    level4_prior.sort()

    // let levelsSelected = this.state.levelsSelected || defaultLevelsSelected
    let levelsSelected = defaultLevelsSelected
    let levelLabels = levelsSelected.map(level => level.label)
    let levelValues = levelsSelected.map(level => level.value)

    // Look at Levels and if Level is Week Ending, then we need to use the corresponding dates...
    let priorLevelValues = {}

    level1.forEach((level, idx) => {
        if (levelValues[0] === 'rowdate') {
            priorLevelValues[level] = level1_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    level2.forEach((level, idx) => {
        if (levelValues[1] === 'rowdate') {
            priorLevelValues[level] = level2_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    level3.forEach((level, idx) => {
        if (levelValues[2] === 'rowdate') {
            priorLevelValues[level] = level3_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    level4.forEach((level, idx) => {
        if (levelValues[3] === 'rowdate') {
            priorLevelValues[level] = level4_prior[idx]
        } else {
            priorLevelValues[level] = level
        }
    })

    let zero_object = {
        'basedollars': 0,
        'baseunits': 0,
        'carrieddoors': 0,
        'discountdollars': 0,
        'incrementaldollars': 0,
        'incrementalunits': 0,
        'nonpromoteddollars': 0,
        'nonpromotedunits': 0,
        'promoteddollars': 0,
        'promotedunits': 0,
        'revopportunity': 0,
        'subsidizedbasedollars': 0,
        'subsidizedbaseunits': 0,
        'totaldollars': 0,
        'totaldoors_s1': 0,
        'totaldoors_s1_segment': 0,
        'totaldoors_s2': 0,
        'totaldoors_s2_segment': 0,
        'totalunits': 0,
        'totalunits_withoutno': 0,
        'unsubsidizedbasedollars': 0,
        'unsubsidizedbaseunits': 0,
        'weightedasp': 0,
        'weightedbp': 0,
        'weightedbp_withoutno': 0,
        'weightedpctdisc': 0,
        'weightedpctdisc_withoutno': 0
    }

    let currencyType = this.state.currencyType
    let currencysymbol = this.state.currencysymbol

    // Default Metrics
    /*
    var selectedMetrics = [
        { label: 'Total ' + currencyType, value: 'totaldollars', format: 'currency' },
        { label: currencyType + ' Share', value: 'totaldollars', format: 'percent', denominator: 'dollarshare' },
        { label: 'Average Promoted Discount', value: 'weightedpctdisc_withoutno', format: 'percent', denominator: 'totalunits_withoutno' },
        { label: 'Promoted ' + currencyType + ' %', value: 'promoteddollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'Incremental ' + currencyType + ' %', value: 'incrementaldollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'Promoted Units %', value: 'promotedunits', format: 'percent', denominator: 'totalunits' },
        { label: 'Incremental Units %', value: 'incrementalunits', format: 'percent', denominator: 'totalunits' },
        { label: 'Efficiency (Units)', value: 'incrementalunits', format: 'percent', denominator: 'promotedunits' }]
    */

    // All Metrics -- are these implemented at all in this code? Or are only the default metrics above implemented?
    var selectedMetrics = [
        { label: 'Total Units', value: 'totalunits', format: 'units' },
        { label: 'Total ' + currencyType, value: 'totaldollars', format: 'currency' },
        { label: 'Unit Share', value: 'totalunits', format: 'percent', denominator: 'unitshare' },
        { label: currencyType + ' Share', value: 'totaldollars', format: 'percent', denominator: 'dollarshare' },
        { label: 'Base Units', value: 'baseunits', format: 'units' },
        { label: 'Base ' + currencyType, value: 'basedollars', format: 'currency' },
        { label: 'Base Units %', value: 'baseunits', format: 'percent', denominator: 'totalunits' },
        { label: 'Base ' + currencyType + ' %', value: 'basedollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'Incremental Units', value: 'incrementalunits', format: 'units' },
        { label: 'Incremental ' + currencyType, value: 'incrementaldollars', format: 'currency' },
        { label: 'Incremental Units %', value: 'incrementalunits', format: 'percent', denominator: 'totalunits' },
        { label: 'Incremental ' + currencyType + ' %', value: 'incrementaldollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'Promoted Units', value: 'promotedunits', format: 'units' },
        { label: 'Promoted ' + currencyType, value: 'promoteddollars', format: 'currency' },
        { label: 'Promoted Units %', value: 'promotedunits', format: 'percent', denominator: 'totalunits' },
        { label: 'Promoted ' + currencyType + ' %', value: 'promoteddollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'Non-Promoted Units', value: 'nonpromotedunits', format: 'units' },
        { label: 'Non-Promoted ' + currencyType, value: 'nonpromoteddollars', format: 'units' },
        { label: 'Non-Promoted Units %', value: 'nonpromotedunits', format: 'percent', denominator: 'totalunits' },
        { label: 'Non-Promoted ' + currencyType + ' %', value: 'nonpromoteddollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'Subsidized Base Units', value: 'subsidizedbaseunits', format: 'units' },
        { label: 'Subsidized Base ' + currencyType, value: 'subsidizedbasedollars', format: 'currency' },
        { label: 'Subsidized Base Units %', value: 'subsidizedbaseunits', format: 'percent', denominator: 'totalunits' },
        { label: 'Subsidized Base ' + currencyType + ' %', value: 'subsidizedbasedollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'UnSubsidized Base Units', value: 'unsubsidizedbaseunits', format: 'units' },
        { label: 'UnSubsidized Base ' + currencyType, value: 'unsubsidizedbasedollars', format: 'units' },
        { label: 'UnSubsidized Base Units %', value: 'unsubsidizedbaseunits', format: 'percent', denominator: 'totalunits' },
        { label: 'UnSubsidized Base ' + currencyType + ' %', value: 'unsubsidizedbasedollars', format: 'percent', denominator: 'totaldollars' },
        { label: 'Average Promoted Discount', value: 'weightedpctdisc_withoutno', format: 'percent', denominator: 'totalunits_withoutno' },
        { label: 'Efficiency (Units)', value: 'incrementalunits', format: 'percent', denominator: 'promotedunits' },
        { label: 'Market Lift (Units)', value: 'incrementalunits', format: 'percent', denominator: 'baseunits' },
        { label: 'Promoted Lift (Units)', value: 'incrementalunits', format: 'percent', denominator: 'subsidizedbaseunits' },
        { label: 'Payback Ratio', value: 'incrementaldollars', format: 'units', denominator: 'discountdollars' }
    ]

    function rowcheck(itemdata) {
        let total_units = itemdata['totalunits']
        let total_dollars = itemdata['totaldollars']
        return total_units !== 0 && total_dollars !== 0
    }

    // Set Styling
    function setFormat_Number(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = '##,#0';
        })
    }

    function setFormat_Percent(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = '0.00%';
        })
    }

    function setFormat_Currency(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = currencysymbol + '#,##0';
        })
    }

    function setFormat_Currency_Price(worksheet, cols, currencysymbol) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = currencysymbol + '#,##.00';
        })
    }

    function setFormat_Decimal(worksheet, cols) {
        cols.forEach(col => {
            worksheet.getColumn(col).numFmt = '##,#.0';
        })
    }

    function safe_concat(series, value) {
        if (!isFinite(value)) {
            value = 0
        }
        return series.concat(value)
    }

    let level1_for_col = []
    let level2_for_col = []
    let level3_for_col = []
    let level4_for_col = []

    let total_dollars = []
    let total_dollars_share = []
    let avg_promoted_discount = []
    let promoted_dollars_percent = []
    let incremental_dollars_percent = []
    let promoted_units_percent = []
    let incremental_units_percent = []
    let efficiency_units = []

    let promoted_dollars = []
    let total_units = []
    let total_units_share = []
    let base_dollars = []
    let base_units = []
    let incremental_units = []
    let incremental_dollars = []
    let subsidized_base_units = []
    let subsidized_base_dollars = []
    let unsubsidized_base_units = []
    let unsubsidized_base_dollars = []
    let promoted_units = []
    let nonpromoted_units = []
    let nonpromoted_dollars = []
    let nonpromoted_units_percent = []
    let base_units_percent = []
    let subsidized_base_units_percent = []
    let unsubsidized_base_units_percent = []
    let nonpromoted_dollars_percent = []
    let base_dollars_percent = []
    let subsidized_base_dollars_percent = []
    let unsubsidized_base_dollars_percent = []
    let market_lift = []
    let promoted_lift = []
    let payback_ratio = []

    let total_dollars_yoy = []
    let total_dollars_gap = []
    let total_dollars_share_yoy = []
    let total_dollars_share_gap = []
    let share_impact = []
    let fair_share_opp = []
    let avg_promoted_discount_yoy = []
    let avg_promoted_discount_gap = []
    let promoted_dollars_percent_yoy = []
    let promoted_dollars_percent_gap = []
    let incremental_dollars_percent_yoy = []
    let incremental_dollars_percent_gap = []
    let promoted_units_percent_yoy = []
    let promoted_units_percent_gap = []
    let incremental_units_percent_yoy = []
    let incremental_units_percent_gap = []
    let efficiency_units_yoy = []
    let efficiency_units_gap = []

    let promoted_dollars_yoy = []
    let promoted_dollars_gap = []
    let total_units_yoy = []
    let total_units_gap = []
    let total_units_share_yoy = []
    let total_units_share_gap = []
    let base_dollars_yoy = []
    let base_dollars_gap = []
    let base_units_yoy = []
    let base_units_gap = []
    let incremental_units_yoy = []
    let incremental_units_gap = []
    let incremental_dollars_yoy = []
    let incremental_dollars_gap = []
    let subsidized_base_units_yoy = []
    let subsidized_base_units_gap = []
    let subsidized_base_dollars_yoy = []
    let subsidized_base_dollars_gap = []
    let unsubsidized_base_units_yoy = []
    let unsubsidized_base_units_gap = []
    let unsubsidized_base_dollars_yoy = []
    let unsubsidized_base_dollars_gap = []
    let promoted_units_yoy = []
    let promoted_units_gap = []
    let nonpromoted_units_yoy = []
    let nonpromoted_units_gap = []
    let nonpromoted_dollars_yoy = []
    let nonpromoted_dollars_gap = []
    let nonpromoted_units_percent_yoy = []
    let nonpromoted_units_percent_gap = []
    let base_units_percent_yoy = []
    let base_units_percent_gap = []
    let subsidized_base_units_percent_yoy = []
    let subsidized_base_units_percent_gap = []
    let unsubsidized_base_units_percent_yoy = []
    let unsubsidized_base_units_percent_gap = []
    let nonpromoted_dollars_percent_yoy = []
    let nonpromoted_dollars_percent_gap = []
    let base_dollars_percent_yoy = []
    let base_dollars_percent_gap = []
    let subsidized_base_dollars_percent_yoy = []
    let subsidized_base_dollars_percent_gap = []
    let unsubsidized_base_dollars_percent_yoy = []
    let unsubsidized_base_dollars_percent_gap = []
    let market_lift_yoy = []
    let market_lift_gap = []
    let promoted_lift_yoy = []
    let promoted_lift_gap = []
    let payback_ratio_yoy = []
    let payback_ratio_gap = []

    let tableMetrics = selectedMetrics

    // Create Metrics
    level1.forEach(level1item => {
        let level1data = current_data[level1item] || {}
        let l1_item_current = current_data[level1item] || {}
        let l1_item_prior = past_data[priorLevelValues[level1item]] || {}
        let l1_item_summedl2_current = summedData_current_level2[level1item] || {}
        let l1_item_summedl3_current = summedData_current_level3[level1item] || {}
        let l1_item_summedl4_current = summedData_current_level4[level1item] || {}
        let l1_item_summedl3_prior = summedData_prior_level3[priorLevelValues[level1item]] || {}

        level2.forEach(level2item => {
            let level2data = level1data[level2item] || {}
            let l2_item_current = l1_item_current[level2item] || {}
            let l2_item_prior = l1_item_prior[priorLevelValues[level2item]] || {}
            let l2_item_summedl2_current = l1_item_summedl2_current[level2item] || {}
            let l2_item_summedl3_current = l1_item_summedl3_current[level2item] || {}
            let l2_item_summedl4_current = l1_item_summedl4_current[level2item] || {}
            let l2_item_summedl3_prior = l1_item_summedl3_prior[priorLevelValues[level2item]] || {}

            let total_cat_dollars = l2_item_summedl2_current['totaldollars'] || 0

            level3.forEach(level3item => {
                let level3data = level2data[level3item] || {}
                let l3_item_current = l2_item_current[level3item] || {}
                let l3_item_prior = l2_item_prior[priorLevelValues[level3item]] || {}
                let l3_item_summedl3_current = l2_item_summedl3_current[level3item] || {}
                let l3_item_summedl4_current = l2_item_summedl4_current[level3item] || {}
                let l3_item_summedl3_prior = l2_item_summedl3_prior[priorLevelValues[level3item]] || {}

                let l4_item_summedl4_current_rom = l3_item_summedl4_current['ROM'] // We want this to be undefined if 'ROM' is not present

                if (level3data !== undefined) {
                    level4.forEach(level4item => {
                        // NOTE: This code ASSUMES "Outlet" is the 4th level ... e.g. looking for "ROM" for ALRs
                        let level4data = level3data[level4item] || zero_object
                        let l4_item_current = l3_item_current[level4item] || {}
                        let l4_item_prior = l3_item_prior[priorLevelValues[level4item]] || {}
                        let l4_item_summedl4_current = l3_item_summedl4_current[level4item] || {}
                        let l4_item_summedl4_current_totaldollars = l4_item_summedl4_current['totaldollars'] || 0 // Current outlet total dollars

                        if (rowcheck(level4data) === true) {
                            level1_for_col.push(level1item)
                            level2_for_col.push(level2item)
                            level3_for_col.push(level3item)
                            level4_for_col.push(level4item)

                            let curr_total_units = l4_item_current['totalunits'] || 0
                            let prev_total_units = l4_item_prior['totalunits'] || 0
                            let curr_total_units_denominator = l3_item_summedl3_current['totalunits'] || 0
                            let prev_total_units_denominator = l3_item_summedl3_prior['totalunits'] || 0

                            let curr_total_dollars = l4_item_current['totaldollars'] || 0
                            let prev_total_dollars = l4_item_prior['totaldollars'] || 0
                            let curr_total_dollars_denominator = l3_item_summedl3_current['totaldollars'] || 0
                            let prev_total_dollars_denominator = l3_item_summedl3_prior['totaldollars'] || 0

                            let curr_weightedpctdisc_withoutno = l4_item_current['weightedpctdisc_withoutno'] || 0
                            let prev_weightedpctdisc_withoutno = l4_item_prior['weightedpctdisc_withoutno'] || 0
                            let curr_totalunits_withoutno = l4_item_current['totalunits_withoutno'] || 0
                            let prev_totalunits_withoutno = l4_item_prior['totalunits_withoutno'] || 0

                            let curr_promoted_dollars = l4_item_current['promoteddollars'] || 0
                            let prev_promoted_dollars = l4_item_prior['promoteddollars'] || 0
                            let curr_promoted_units = l4_item_current['promotedunits'] || 0
                            let prev_promoted_units = l4_item_prior['promotedunits'] || 0

                            let curr_incremental_dollars = l4_item_current['incrementaldollars'] || 0
                            let prev_incremental_dollars = l4_item_prior['incrementaldollars'] || 0
                            let curr_incremental_units = l4_item_current['incrementalunits'] || 0
                            let prev_incremental_units = l4_item_prior['incrementalunits'] || 0

                            let curr_base_dollars = l4_item_current['basedollars'] || 0
                            let prev_base_dollars = l4_item_prior['basedollars'] || 0
                            let curr_base_units = l4_item_current['baseunits'] || 0
                            let prev_base_units = l4_item_prior['baseunits'] || 0

                            let curr_nonpromoted_dollars = l4_item_current['nonpromoteddollars'] || 0
                            let prev_nonpromoted_dollars = l4_item_prior['nonpromoteddollars'] || 0
                            let curr_nonpromoted_units = l4_item_current['nonpromotedunits'] || 0
                            let prev_nonpromoted_units = l4_item_prior['nonpromotedunits'] || 0

                            let curr_subsidizedbase_dollars = l4_item_current['subsidizedbasedollars'] || 0
                            let prev_subsidizedbase_dollars = l4_item_prior['subsidizedbasedollars'] || 0
                            let curr_subsidizedbase_units = l4_item_current['subsidizedbaseunits'] || 0
                            let prev_subsidizedbase_units = l4_item_prior['subsidizedbaseunits'] || 0

                            let curr_unsubsidizedbase_dollars = l4_item_current['unsubsidizedbasedollars'] || 0
                            let prev_unsubsidizedbase_dollars = l4_item_prior['unsubsidizedbasedollars'] || 0
                            let curr_unsubsidizedbase_units = l4_item_current['unsubsidizedbaseunits'] || 0
                            let prev_unsubsidizedbase_units = l4_item_prior['unsubsidizedbaseunits'] || 0

                            let curr_discount_dollars = l4_item_current['discountdollars'] || 0
                            let prev_discount_dollars = l4_item_prior['discountdollars'] || 0

                            // Default metrics
                            total_dollars = total_dollars.concat(curr_total_dollars)
                            total_dollars_share = safe_concat(total_dollars_share, curr_total_dollars / curr_total_dollars_denominator)
                            avg_promoted_discount = safe_concat(avg_promoted_discount, curr_weightedpctdisc_withoutno / curr_totalunits_withoutno)
                            promoted_dollars_percent = safe_concat(promoted_dollars_percent, curr_promoted_dollars / curr_total_dollars)
                            incremental_dollars_percent = safe_concat(incremental_dollars_percent, curr_incremental_dollars / curr_total_dollars)
                            promoted_units_percent = safe_concat(promoted_units_percent, curr_promoted_units / curr_total_units)
                            incremental_units_percent = safe_concat(incremental_units_percent, curr_incremental_units / curr_total_units)
                            efficiency_units = safe_concat(efficiency_units, curr_incremental_units / curr_promoted_units)

                            // Plus all metrics
                            total_units = safe_concat(total_units, curr_total_units)
                            total_units_share = safe_concat(total_units_share, curr_total_units / curr_total_units_denominator)
                            promoted_units = safe_concat(promoted_units, curr_promoted_units)
                            promoted_dollars = safe_concat(promoted_dollars, curr_promoted_dollars)
                            incremental_units = safe_concat(incremental_units, curr_incremental_units)
                            incremental_dollars = safe_concat(incremental_dollars, curr_incremental_dollars)

                            base_units = safe_concat(base_units, curr_base_units)
                            base_dollars = safe_concat(base_dollars, curr_base_dollars)
                            base_units_percent = safe_concat(base_units_percent, curr_base_units / curr_total_units)
                            base_dollars_percent = safe_concat(base_dollars_percent, curr_base_dollars / curr_total_dollars)

                            subsidized_base_units = safe_concat(subsidized_base_units, curr_subsidizedbase_units)
                            subsidized_base_dollars = safe_concat(subsidized_base_dollars, curr_subsidizedbase_dollars)
                            subsidized_base_units_percent = safe_concat(subsidized_base_units_percent, curr_subsidizedbase_units / curr_total_units)
                            subsidized_base_dollars_percent = safe_concat(subsidized_base_dollars_percent, curr_subsidizedbase_dollars / curr_total_dollars)

                            unsubsidized_base_units = safe_concat(unsubsidized_base_units, curr_unsubsidizedbase_units)
                            unsubsidized_base_dollars = safe_concat(unsubsidized_base_dollars, curr_unsubsidizedbase_dollars)
                            unsubsidized_base_units_percent = safe_concat(unsubsidized_base_units_percent, curr_unsubsidizedbase_units / curr_total_units)
                            unsubsidized_base_dollars_percent = safe_concat(unsubsidized_base_dollars_percent, curr_unsubsidizedbase_dollars / curr_total_dollars)

                            nonpromoted_units = safe_concat(nonpromoted_units, curr_nonpromoted_units)
                            nonpromoted_dollars = safe_concat(nonpromoted_dollars, curr_nonpromoted_dollars)
                            nonpromoted_units_percent = safe_concat(nonpromoted_units_percent, curr_nonpromoted_units / curr_total_units)
                            nonpromoted_dollars_percent = safe_concat(nonpromoted_dollars_percent, curr_nonpromoted_dollars / curr_total_dollars)

                            market_lift = safe_concat(market_lift, curr_incremental_units / curr_base_units)
                            promoted_lift = safe_concat(promoted_lift, curr_incremental_units / curr_subsidizedbase_units)
                            payback_ratio = safe_concat(payback_ratio, curr_incremental_dollars / curr_discount_dollars)

                            // Default YOY metrics
                            total_dollars_yoy = safe_concat(total_dollars_yoy, (curr_total_dollars - prev_total_dollars) / prev_total_dollars)
                            total_dollars_share_yoy = safe_concat(total_dollars_share_yoy, ((curr_total_dollars / curr_total_dollars_denominator) - (prev_total_dollars / prev_total_dollars_denominator)) * 100)
                            promoted_dollars_percent_yoy = safe_concat(promoted_dollars_percent_yoy, ((curr_promoted_dollars / curr_total_dollars) - (prev_promoted_dollars / prev_total_dollars)) * 100)
                            incremental_dollars_percent_yoy = safe_concat(incremental_dollars_percent_yoy, ((curr_incremental_dollars / curr_total_dollars) - (prev_incremental_dollars / prev_total_dollars)) * 100)
                            promoted_units_percent_yoy = safe_concat(promoted_units_percent_yoy, ((curr_promoted_units / curr_total_units) - (prev_promoted_units / prev_total_units)) * 100)
                            incremental_units_percent_yoy = safe_concat(incremental_units_percent_yoy, ((curr_incremental_units / curr_total_units) - (prev_incremental_units / prev_total_units)) * 100)
                            avg_promoted_discount_yoy = safe_concat(avg_promoted_discount_yoy, ((curr_weightedpctdisc_withoutno / curr_totalunits_withoutno) - (prev_weightedpctdisc_withoutno / prev_totalunits_withoutno)) * 100)
                            efficiency_units_yoy = safe_concat(efficiency_units_yoy, ((curr_incremental_units / curr_promoted_units) - (prev_incremental_units / prev_promoted_units)) * 100)

                            // Plus all YOY metrics
                            total_units_yoy = safe_concat(total_units_yoy, (curr_total_units - prev_total_units) / prev_total_units)
                            total_units_share_yoy = safe_concat(total_units_share_yoy, ((curr_total_units / curr_total_units_denominator) - (prev_total_units / prev_total_units_denominator)) * 100)
                            promoted_units_yoy = safe_concat(promoted_units_yoy, (curr_promoted_units - prev_promoted_units) / prev_promoted_units)
                            promoted_dollars_yoy = safe_concat(promoted_dollars_yoy, (curr_promoted_dollars - prev_promoted_dollars) / prev_promoted_dollars)
                            incremental_units_yoy = safe_concat(incremental_units_yoy, (curr_incremental_units - prev_incremental_units) / prev_incremental_units)
                            incremental_dollars_yoy = safe_concat(incremental_dollars_yoy, (curr_incremental_dollars - prev_incremental_dollars) / prev_incremental_dollars)

                            base_units_yoy = safe_concat(base_units_yoy, (curr_base_units - prev_base_units) / prev_base_units)
                            base_dollars_yoy = safe_concat(base_dollars_yoy, (curr_base_dollars - prev_base_dollars) / prev_base_dollars)
                            base_units_percent_yoy = safe_concat(base_units_percent_yoy, ((curr_base_units / curr_total_units) - (prev_base_units / prev_total_units)) * 100)
                            base_dollars_percent_yoy = safe_concat(base_dollars_percent_yoy, ((curr_base_dollars / curr_total_dollars) - (prev_base_dollars / prev_total_dollars)) * 100)

                            subsidized_base_units_yoy = safe_concat(subsidized_base_units_yoy, (curr_subsidizedbase_units - prev_subsidizedbase_units) / prev_subsidizedbase_units)
                            subsidized_base_dollars_yoy = safe_concat(subsidized_base_dollars_yoy, (curr_subsidizedbase_dollars - prev_subsidizedbase_dollars) / prev_subsidizedbase_dollars)
                            subsidized_base_units_percent_yoy = safe_concat(subsidized_base_units_percent_yoy, ((curr_subsidizedbase_units / curr_total_units) - (prev_subsidizedbase_units / prev_total_units)) * 100)
                            subsidized_base_dollars_percent_yoy = safe_concat(subsidized_base_dollars_percent_yoy, ((curr_subsidizedbase_dollars / curr_total_dollars) - (prev_subsidizedbase_dollars / prev_total_dollars)) * 100)

                            unsubsidized_base_units_yoy = safe_concat(unsubsidized_base_units_yoy, (curr_unsubsidizedbase_units - prev_unsubsidizedbase_units) / prev_unsubsidizedbase_units)
                            unsubsidized_base_dollars_yoy = safe_concat(unsubsidized_base_dollars_yoy, (curr_unsubsidizedbase_dollars - prev_unsubsidizedbase_dollars) / prev_unsubsidizedbase_dollars)
                            unsubsidized_base_units_percent_yoy = safe_concat(unsubsidized_base_units_percent_yoy, ((curr_unsubsidizedbase_units / curr_total_units) - (prev_unsubsidizedbase_units / prev_total_units)) * 100)
                            unsubsidized_base_dollars_percent_yoy = safe_concat(unsubsidized_base_dollars_percent_yoy, ((curr_unsubsidizedbase_dollars / curr_total_dollars) - (prev_unsubsidizedbase_dollars / prev_total_dollars)) * 100)

                            nonpromoted_units_yoy = safe_concat(nonpromoted_units_yoy, (curr_nonpromoted_units - prev_nonpromoted_units) / prev_nonpromoted_units)
                            nonpromoted_dollars_yoy = safe_concat(nonpromoted_dollars_yoy, (curr_nonpromoted_dollars - prev_nonpromoted_dollars) / prev_nonpromoted_dollars)                            
                            nonpromoted_units_percent_yoy = safe_concat(nonpromoted_units_percent_yoy, ((curr_nonpromoted_units / curr_total_units) - (prev_nonpromoted_units / prev_total_units)) * 100)
                            nonpromoted_dollars_percent_yoy = safe_concat(nonpromoted_dollars_percent_yoy, ((curr_nonpromoted_dollars / curr_total_dollars) - (prev_nonpromoted_dollars / prev_total_dollars)) * 100)

                            market_lift_yoy = safe_concat(market_lift_yoy, ((curr_incremental_units / curr_base_units) - (prev_incremental_units / prev_base_units)) * 100)
                            promoted_lift_yoy = safe_concat(promoted_lift_yoy, ((curr_incremental_units / curr_subsidizedbase_units) - (prev_incremental_units / prev_subsidizedbase_units)) * 100)
                            payback_ratio_yoy = safe_concat(payback_ratio_yoy, (curr_incremental_dollars / curr_discount_dollars) - (prev_incremental_dollars / prev_discount_dollars))

                            // If ROM data exists *and* the current Outlet is the ALR (not ROM), then we can compute ROM gap measures for the ALR
                            // Else, ROM gap measures are all 0 (e.g. if outlet is ROM or Total Measured Market)
                            if (l4_item_summedl4_current_rom !== undefined && level4item !== 'ROM') {
                                let rom_curr_promoted_units = l4_item_summedl4_current_rom['promotedunits'] || 0
                                let rom_curr_incremental_units = l4_item_summedl4_current_rom['incrementalunits'] || 0
                                let rom_curr_total_units = l4_item_summedl4_current_rom['totalunits'] || 0
                                let rom_curr_incremental_dollars = l4_item_summedl4_current_rom['incrementaldollars'] || 0
                                let rom_curr_total_dollars = l4_item_summedl4_current_rom['totaldollars'] || 0
                                let rom_curr_promoted_dollars = l4_item_summedl4_current_rom['promoteddollars'] || 0
                                let rom_curr_weightedpctdisc_withoutno = l4_item_summedl4_current_rom['weightedpctdisc_withoutno'] || 0
                                let rom_curr_totalunits_withoutno = l4_item_summedl4_current_rom['totalunits_withoutno'] || 0
                                let rom_curr_base_dollars = l4_item_summedl4_current_rom['basedollars'] || 0
                                let rom_curr_base_units = l4_item_summedl4_current_rom['baseunits'] || 0
                                let rom_curr_nonpromoted_dollars = l4_item_summedl4_current_rom['nonpromoteddollars'] || 0
                                let rom_curr_nonpromoted_units = l4_item_summedl4_current_rom['nonpromotedunits'] || 0
                                let rom_curr_subsidizedbase_dollars = l4_item_summedl4_current_rom['subsidizedbasedollars'] || 0
                                let rom_curr_subsidizedbase_units = l4_item_summedl4_current_rom['subsidizedbaseunits'] || 0
                                let rom_curr_unsubsidizedbase_dollars = l4_item_summedl4_current_rom['unsubsidizedbasedollars'] || 0
                                let rom_curr_unsubsidizedbase_units = l4_item_summedl4_current_rom['unsubsidizedbaseunits'] || 0
                                let rom_curr_discount_dollars = l4_item_summedl4_current_rom['discountdollars'] || 0

                                let rom_curr_total_units_denominator = l4_item_summedl4_current_rom['totalunits'] || 0
                                let rom_curr_total_dollars_denominator = l4_item_summedl4_current_rom['totaldollars'] || 0

                                let total_row_dollars = l4_item_summedl4_current_totaldollars + rom_curr_total_dollars

                                share_impact = safe_concat(share_impact, (curr_total_dollars / curr_total_dollars_denominator - prev_total_dollars / prev_total_dollars_denominator) * total_cat_dollars)
                                fair_share_opp = safe_concat(fair_share_opp, (curr_total_dollars / curr_total_dollars_denominator - prev_total_dollars / prev_total_dollars_denominator) * total_row_dollars)
                                avg_promoted_discount_gap = safe_concat(avg_promoted_discount_gap, (curr_weightedpctdisc_withoutno / curr_totalunits_withoutno - rom_curr_weightedpctdisc_withoutno / rom_curr_totalunits_withoutno) * 100)
                                promoted_dollars_percent_gap = safe_concat(promoted_dollars_percent_gap, ((curr_promoted_dollars - rom_curr_promoted_dollars) / curr_total_dollars_denominator) * 100)
                                incremental_dollars_percent_gap = safe_concat(incremental_dollars_percent_gap, (curr_incremental_dollars / curr_total_dollars - rom_curr_incremental_dollars / rom_curr_total_dollars) * 100)
                                promoted_units_percent_gap = safe_concat(promoted_units_percent_gap, ((curr_promoted_units - rom_curr_promoted_units) / curr_total_units_denominator) * 100)
                                incremental_units_percent_gap = safe_concat(incremental_units_percent_gap, (curr_incremental_units / curr_total_units - rom_curr_incremental_units / rom_curr_total_units) * 100)
                                efficiency_units_gap = safe_concat(efficiency_units_gap, (curr_incremental_units / curr_promoted_units - rom_curr_incremental_units / rom_curr_promoted_units) * 100)
                                total_units_gap = safe_concat(total_units_gap, (curr_total_units - rom_curr_total_units))
                                total_dollars_gap = safe_concat(total_dollars_gap, (curr_total_dollars - rom_curr_total_dollars))
                                total_dollars_share_gap = safe_concat(total_dollars_share_gap, (curr_total_dollars / curr_total_dollars_denominator - rom_curr_total_dollars / rom_curr_total_dollars_denominator) * 100)
                                base_units_gap = safe_concat(base_units_gap, (curr_base_units - rom_curr_base_units))
                                base_dollars_gap = safe_concat(base_dollars_gap, (curr_base_dollars - rom_curr_base_dollars))
                                promoted_units_gap = safe_concat(promoted_units_gap, (curr_promoted_units - rom_curr_promoted_units))
                                promoted_dollars_gap = safe_concat(promoted_dollars_gap, (curr_promoted_dollars - rom_curr_promoted_dollars))
                                incremental_units_gap = safe_concat(incremental_units_gap, (curr_incremental_units - rom_curr_incremental_units))
                                incremental_dollars_gap = safe_concat(incremental_dollars_gap, (curr_incremental_dollars - rom_curr_incremental_dollars))
                                subsidized_base_units_gap = safe_concat(subsidized_base_units_gap, (curr_subsidizedbase_units - rom_curr_subsidizedbase_units))
                                subsidized_base_dollars_gap = safe_concat(subsidized_base_dollars_gap, (curr_subsidizedbase_dollars - rom_curr_subsidizedbase_dollars))
                                unsubsidized_base_units_gap = safe_concat(unsubsidized_base_units_gap, (curr_unsubsidizedbase_units - rom_curr_unsubsidizedbase_units))
                                unsubsidized_base_dollars_gap = safe_concat(unsubsidized_base_dollars_gap, (curr_unsubsidizedbase_dollars - rom_curr_unsubsidizedbase_dollars))
                                nonpromoted_units_gap = safe_concat(nonpromoted_units_gap, (curr_nonpromoted_units - rom_curr_nonpromoted_units))
                                nonpromoted_dollars_gap = safe_concat(nonpromoted_dollars_gap, (curr_nonpromoted_dollars - rom_curr_nonpromoted_dollars))
                                total_units_share_gap = safe_concat(total_units_share_gap, (curr_total_units / curr_total_units_denominator - rom_curr_total_units / rom_curr_total_units_denominator) * 100)
                                base_units_percent_gap = safe_concat(base_units_percent_gap, (curr_base_units / curr_total_units - rom_curr_base_units / rom_curr_total_units) * 100)
                                base_dollars_percent_gap = safe_concat(base_dollars_percent_gap, (curr_base_dollars / curr_total_dollars - rom_curr_base_dollars / rom_curr_total_dollars) * 100)
                                nonpromoted_units_percent_gap = safe_concat(nonpromoted_units_percent_gap, (curr_nonpromoted_units / curr_total_units - rom_curr_nonpromoted_units / rom_curr_total_units) * 100)
                                nonpromoted_dollars_percent_gap = safe_concat(nonpromoted_dollars_percent_gap, (curr_nonpromoted_dollars / curr_total_dollars - rom_curr_nonpromoted_dollars / rom_curr_total_dollars) * 100)
                                subsidized_base_units_percent_gap = safe_concat(subsidized_base_units_percent_gap, (curr_subsidizedbase_units / curr_total_units - rom_curr_subsidizedbase_units / rom_curr_total_units) * 100)
                                subsidized_base_dollars_percent_gap = safe_concat(subsidized_base_dollars_percent_gap, (curr_subsidizedbase_dollars / curr_total_dollars - rom_curr_subsidizedbase_dollars / rom_curr_total_dollars) * 100)
                                unsubsidized_base_units_percent_gap = safe_concat(unsubsidized_base_units_percent_gap, (curr_unsubsidizedbase_units / curr_total_units - rom_curr_unsubsidizedbase_units / rom_curr_total_units) * 100)
                                unsubsidized_base_dollars_percent_gap = safe_concat(unsubsidized_base_dollars_percent_gap, (curr_unsubsidizedbase_dollars / curr_total_dollars - rom_curr_incremental_dollars / rom_curr_promoted_dollars) * 100)
                                market_lift_gap = safe_concat(market_lift_gap, (curr_incremental_units / curr_base_units - rom_curr_incremental_units / rom_curr_base_units) * 100)
                                promoted_lift_gap = safe_concat(promoted_lift_gap, (curr_incremental_units / curr_subsidizedbase_units - rom_curr_incremental_units / rom_curr_subsidizedbase_units) * 100)
                                payback_ratio_gap = safe_concat(payback_ratio_gap, (curr_incremental_units / curr_discount_dollars - rom_curr_incremental_units / rom_curr_discount_dollars) * 100)
                            } else {
                                share_impact = share_impact.concat(0)
                                fair_share_opp = fair_share_opp.concat(0)
                                avg_promoted_discount_gap = avg_promoted_discount_gap.concat(0)
                                promoted_dollars_percent_gap = promoted_dollars_percent_gap.concat(0)
                                incremental_dollars_percent_gap = incremental_dollars_percent_gap.concat(0)
                                promoted_units_percent_gap = promoted_units_percent_gap.concat(0)
                                incremental_units_percent_gap = incremental_units_percent_gap.concat(0)
                                efficiency_units_gap = efficiency_units_gap.concat(0)
                                total_dollars_gap = total_dollars_gap.concat(0)
                                total_dollars_share_gap = total_dollars_share_gap.concat(0)
                                promoted_dollars_gap = promoted_dollars_gap.concat(0)
                                total_units_gap = total_units_gap.concat(0)
                                total_units_share_gap = total_units_share_gap.concat(0)
                                base_dollars_gap = base_dollars_gap.concat(0)
                                base_units_gap = base_units_gap.concat(0)
                                incremental_units_gap = incremental_units_gap.concat(0)
                                incremental_dollars_gap = incremental_dollars_gap.concat(0)
                                subsidized_base_units_gap = subsidized_base_units_gap.concat(0)
                                subsidized_base_dollars_gap = subsidized_base_dollars_gap.concat(0)
                                unsubsidized_base_units_gap = unsubsidized_base_units_gap.concat(0)
                                unsubsidized_base_dollars_gap = unsubsidized_base_dollars_gap.concat(0)
                                promoted_units_gap = promoted_units_gap.concat(0)
                                nonpromoted_units_gap = nonpromoted_units_gap.concat(0)
                                nonpromoted_dollars_gap = nonpromoted_dollars_gap.concat(0)
                                nonpromoted_units_percent_gap = nonpromoted_units_percent_gap.concat(0)
                                base_units_percent_gap = base_units_percent_gap.concat(0)
                                subsidized_base_units_percent_gap = subsidized_base_units_percent_gap.concat(0)
                                unsubsidized_base_units_percent_gap = unsubsidized_base_units_percent_gap.concat(0)
                                nonpromoted_dollars_percent_gap = nonpromoted_dollars_percent_gap.concat(0)
                                base_dollars_percent_gap = base_dollars_percent_gap.concat(0)
                                subsidized_base_dollars_percent_gap = subsidized_base_dollars_percent_gap.concat(0)
                                unsubsidized_base_dollars_percent_gap = unsubsidized_base_dollars_percent_gap.concat(0)
                                market_lift_gap = market_lift_gap.concat(0)
                                promoted_lift_gap = promoted_lift_gap.concat(0)
                                payback_ratio_gap = payback_ratio_gap.concat(0)
                            }
                        }
                    })
                }
            })
        })
    })

    // Create Sheet
    var metricstoview = [
        total_units,
        total_dollars,
        total_units_share,
        total_dollars_share,
        base_units,
        base_dollars,
        base_units_percent,
        base_dollars_percent,
        incremental_units,
        incremental_dollars,
        incremental_units_percent,
        incremental_dollars_percent,
        promoted_units,
        promoted_dollars,
        promoted_units_percent,
        promoted_dollars_percent,
        nonpromoted_units,
        nonpromoted_dollars,
        nonpromoted_units_percent,
        nonpromoted_dollars_percent,
        subsidized_base_units,
        subsidized_base_dollars,
        subsidized_base_units_percent,
        subsidized_base_dollars_percent,
        unsubsidized_base_units,
        unsubsidized_base_dollars,
        unsubsidized_base_units_percent,
        unsubsidized_base_dollars_percent,
        avg_promoted_discount,
        efficiency_units,
        market_lift,
        promoted_lift,
        payback_ratio,
        total_units_yoy,
        total_units_gap,
        total_dollars_yoy,
        total_dollars_gap,
        total_units_share_yoy,
        total_units_share_gap,
        total_dollars_share_yoy,
        total_dollars_share_gap,
        share_impact,
        fair_share_opp,
        base_units_yoy,
        base_units_gap,
        base_dollars_yoy,
        base_dollars_gap,
        base_units_percent_yoy,
        base_units_percent_gap,
        base_dollars_percent_yoy,
        base_dollars_percent_gap,
        incremental_units_yoy,
        incremental_units_gap,
        incremental_dollars_yoy,
        incremental_dollars_gap,
        incremental_units_percent_yoy,
        incremental_units_percent_gap,
        incremental_dollars_percent_yoy,
        incremental_dollars_percent_gap,
        promoted_units_yoy,
        promoted_units_gap,
        promoted_dollars_yoy,
        promoted_dollars_gap,
        promoted_units_percent_yoy,
        promoted_units_percent_gap,
        promoted_dollars_percent_yoy,
        promoted_dollars_percent_gap,
        nonpromoted_units_yoy,
        nonpromoted_units_gap,
        nonpromoted_dollars_yoy,
        nonpromoted_dollars_gap,
        nonpromoted_units_percent_yoy,
        nonpromoted_units_percent_gap,
        nonpromoted_dollars_percent_yoy,
        nonpromoted_dollars_percent_gap,
        subsidized_base_units_yoy,
        subsidized_base_units_gap,
        subsidized_base_dollars_yoy,
        subsidized_base_dollars_gap,
        subsidized_base_units_percent_yoy,
        subsidized_base_units_percent_gap,
        subsidized_base_dollars_percent_yoy,
        subsidized_base_dollars_percent_gap,
        unsubsidized_base_units_yoy,
        unsubsidized_base_units_gap,
        unsubsidized_base_dollars_yoy,
        unsubsidized_base_dollars_gap,
        unsubsidized_base_units_percent_yoy,
        unsubsidized_base_units_percent_gap,
        unsubsidized_base_dollars_percent_yoy,
        unsubsidized_base_dollars_percent_gap,
        avg_promoted_discount_yoy,
        avg_promoted_discount_gap,
        efficiency_units_yoy,
        efficiency_units_gap,
        market_lift_yoy,
        market_lift_gap,
        promoted_lift_yoy,
        promoted_lift_gap,
        payback_ratio_yoy,
        payback_ratio_gap
    ]

    // File Information
    var newdate = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    var datestring = newdate.toLocaleDateString(undefined, options) + ' ' + newdate.toLocaleTimeString()
    datestring = datestring.replaceAll(',', '')
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Metrics');
    const worksheet = workbook.getWorksheet('Metrics');

    worksheet.getColumn(1).values = level1_for_col
    worksheet.getColumn(2).values = level2_for_col
    worksheet.getColumn(3).values = level3_for_col
    worksheet.getColumn(4).values = level4_for_col

    var headers = []
    if (tableMetrics.length > 0) {
        headers = levelLabels.concat(tableMetrics.map(metric => metric.label)).concat(tableMetrics.map(metric => {
            if (metric.denominator === 'dollarshare') {
                return [metric.label + ' YOY Change', metric.label + ' Gap to ROM', 'Impact of Share Change', 'Fair Share Opportunity']
            } else if (metric.denominator === 'unitshare') {
                return [metric.label + ' YOY Change', metric.label + ' Gap to ROM']
            } else if (metric.denominator) {
                return [metric.label + ' YOY Change', metric.label + ' Gap to ROM']
            } else {
                return [metric.label + ' YOY Change', metric.label + ' Gap to ROM']
            }
        }).reduce((a, c) => { return a.concat(c) }))
        console.log(headers)
    }

    // Add Data
    metricstoview.forEach((metric, idx) => {
        worksheet.getColumn(idx + 5).values = metric
    })

    // Add Headers
    worksheet.insertRow(1, headers);

    // Formatting
    //setFormat_Currency_Price(worksheet, [5], currencysymbol)
    setFormat_Number(worksheet, [5,9,13,17,21,25,29,37])
    setFormat_Currency(worksheet, [6,10,14,18,22,26,30], currencysymbol)
    setFormat_Percent(worksheet, [7,8,11,12,15,16,19,20,23,24,27,28,31,32,33,34,35,36])
    setFormat_Decimal(worksheet, [38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105])

    // Add Selection Information
    const newsheet = workbook.addWorksheet('Selection Criteria');
    const newworksheet = workbook.getWorksheet('Selection Criteria')

    let titles = Object.keys(this.state.chartmetadata)
    let values = Object.values(this.state.chartmetadata)

    var metadatadates = 'Previous Year Ranges From: ' + this.state.prior_start + ' to ' + this.state.distinctdates_prior[this.state.distinctdates_prior.length - 1] + ' Current Year Ranges From: ' + this.state.distinctdates_forcalc[0] + ' to ' + this.state.distinctdates_forcalc[this.state.distinctdates_forcalc.length - 1]
    titles.push('Comparison Date Range: ')
    values.push(metadatadates)

    newworksheet.getColumn(1).values = titles
    newworksheet.getColumn(2).values = values

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    let filenameexcel = filename_base + ' - Extracted - ' + datestring + '.xlsx'

    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, filenameexcel);
}
