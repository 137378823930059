import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Constant from '../constants'

//const reducer = (accumulator, currentValue) => accumulator + currentValue;
const WeeklyViewMultipleSeries = props => {

    var currencycode = props.currencycode || 'USD'
    //var currencytype = props.currencytype || 'Dollars'

    var aggtype = ""
    if ((props.aggperiod || "") === 'Monthly') {
        aggtype = 'Monthly'
    }
    else {
        aggtype = 'Weekly'
    }

    //var xDateFormatString = ""
    var weeklytimeperiods = ""
    var axisFormatString = ""
    var axisText = ""
    //var tickIntervalNum = 24 * 3600 * 1000 * 7 * props.weekagg
    var tickpositionsarray = props.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))))
    var stepNum = 1
    var paddingNum = 5
    var fontSizeString = '11px'
    if (tickpositionsarray.length > 26 && props.height === 2) {
        stepNum = 2
        paddingNum = 10
        fontSizeString = '9px'
    }

    if (aggtype === 'Monthly') {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Month Period Ending: %b %Y'
            weeklytimeperiods = props.weekagg + " Month Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Month Period'
        }

        else {
            //xDateFormatString = '%b %Y'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Month'
        }

    }
    else {
        if (props.weekagg !== 1) {
            //xDateFormatString = props.weekagg + ' Week Period Ending: %Y-%m-%d'
            weeklytimeperiods = props.weekagg + " Week Time Periods"
            axisFormatString = '%e. %b'
            axisText = props.weekagg + ' Week Period'
        }

        else {
            //xDateFormatString = 'Week Ending: %Y-%m-%d'
            weeklytimeperiods = ""
            axisFormatString = '%b %Y'
            axisText = 'Week Ending'
        }
    }
    var weekly_data

    if (props.outletcode) {
        weekly_data = props.weekly_data[props.outletcode]
    }

    else {
        weekly_data = props.weekly_data
    }

    console.log(weekly_data)

    return (
        <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            zoomType: 'xy',
                            height: (9 / 16 * 100 * (props.height || 1)) + '%' //16:9 ratio
                        },
                        metadata: props.metadata || {},
                        caption: {
                            text: props.attr_caption_text
                        },
                        title: {
                            text: props.chart_title + weeklytimeperiods + props.chart_title_info 
                        },
                        series: props.seriesList,
                        xAxis: {
                            title: {
                                text: axisText
                            },
                            type: 'datetime',
                            dateTimeLabelFormats: {
                                month: axisFormatString
                            },
                            //tickInterval: tickIntervalNum
                            tickPositions: tickpositionsarray,
                            labels: {
                                formatter: function () {
                                    let dt = new Date(this.value)
                                    let offset = dt.getTimezoneOffset() * 60 * 1000
                                    let date = new Date(this.value + offset)
                                    const options = { year: 'numeric', month: 'short' };
                                    const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                    if ((props.aggperiod || "") === 'Monthly') {
                                        return date.toLocaleDateString(undefined, options)
                                    }
                                    else {
                                        return date.toLocaleDateString(undefined, options2) //.toISOString().substring(0, 10)
                                    }
                                },
                                rotation: 270,
                                step: stepNum,
                                padding: paddingNum,
                                style: {
                                    fontSize: fontSizeString
                                }
                            },
                            events: {
                                setExtremes: function (e) {
                                    Highcharts.syncExtremes(e);
                                }
                            }
                        },
                        yAxis: [
                            {
                                title: {
                                    text: props.yAxisTitle
                                },
                                labels: {
                                    formatter: function () {
                                        //console.log(this)
                                        function nFormatter(num, digits, axisMax) {
                                            var si = [
                                                { value: 1E18, symbol: "E" },
                                                { value: 1E15, symbol: "P" },
                                                { value: 1E12, symbol: "T" },
                                                { value: 1E9, symbol: "B" },
                                                { value: 1E6, symbol: "M" },
                                                { value: 1E3, symbol: "k" }
                                            ], i;

                                            let symbolForAxis = " "
                                            let valueForAxis = 1

                                            for (i = 0; i < si.length; i++) {
                                                if (axisMax >= si[i].value) {
                                                    symbolForAxis = si[i].symbol
                                                    valueForAxis = si[i].value
                                                    break;
                                                }
                                            }

                                            return ((num / valueForAxis).toFixed(digits) + symbolForAxis).toString()

                                        }
                                        if (props.yAxisType === 'percent') {
                                            return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                        }
                                        else if (props.yAxisType === 'currency') {                                            
                                            let value = nFormatter(this.value, 1, this.axis.max)
                                            let numpart = parseFloat(value.substring(0, value.length - 1)) || 0
                                            let symbol = value.substring(value.length - 1)
                                            return numpart.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0 }) + symbol
                                        }
                                        else {                                            
                                            return nFormatter(this.value, 1, this.axis.max).toLocaleString()
                                        }
                                    },
                                    
                                },                                
                                min: props.yMin,
                                max: props.yMax
                            },

                        ],
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                            },
                            
                        },
                        tooltip: {
                            headerFormat: '',
                        },
                    }
                    
                }
            />

            <div style={{ display: props.showDataTableStyle }}>
                <Constant.NPDTable>
                    <Constant.NPDTableBody>
                        <Constant.NPDTableRow key="header_g1">
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>Date</Constant.NPDTableCell>
                            {props.selectedBrands.map(brand => {
                                return <Constant.NPDTableCell key={"headercol_" + brand + "_g1"} style={{ textAlign: 'center' }}>{brand + ' ' + props.yAxisTitle}</Constant.NPDTableCell>
                            })
                            }
                            {props.yAxisTitle === 'Base Velocity' ? <Fragment /> : <Constant.NPDTableCell style={{ textAlign: 'center' }}>{'Total Category ' + props.yAxisTitle}</Constant.NPDTableCell>}
                        </Constant.NPDTableRow>
                        {props.distinctdates.map(date => {
                            if (props.yAxisTitle === 'Average Promoted Discount %') {

                                return <Constant.NPDTableRow key={"datarow_" + date + "_g1"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g1"} style={{ textAlign: 'center' }}>{
                                            (((((weekly_data[brand] || {})[date]||{})['weightedpctdisc']) / (((weekly_data[brand] || {})[date]||{})['totalunits'])) || 0)
                                                .toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                    })
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(((weekly_data['Total Category'] || weekly_data['All Channels'])[date]['weightedpctdisc'] / (weekly_data['Total Category'] || weekly_data['All Channels'])[date]['totalunits']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Promoted Price') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            ((((weekly_data[brand] || {})[date]||{})['promoteddollars'] / ((weekly_data[brand] || {})[date]||{})['promotedunits']) || 0)
                                        ).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                    })
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(((((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['weightedasp'] / (((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['totalunits']) || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Non-Promoted Price') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            ((((weekly_data[brand] || {})[date]||{})['nonpromoteddollars'] / ((weekly_data[brand] || {})[date]||{})['nonpromotedunits']) || 0)
                                        ).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                    })
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(((((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['weightedbp'] / (((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['totalunits']) || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Base Price') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            ((((weekly_data[brand] || {})[date]||{})['weightedbp'] / ((weekly_data[brand] || {})[date]||{})['totalunits']) || 0)
                                        ).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                    })
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(((((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['weightedasp'] / (((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['totalunits']) || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Average Selling Price') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            ((((weekly_data[brand] || {})[date]||{})['weightedasp'] / ((weekly_data[brand] || {})[date]||{})['totalunits']) || 0)
                                        ).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                    })
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{(((((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['weightedbp'] / (((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['totalunits']) || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Promoted Dollars' || props.yAxisTitle === 'Promoted Pounds') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            (((weekly_data[brand] || {})[date]||{})['promoteddollars'] || 0)
                                        ).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                    })
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{((((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['promoteddollars'] || 0).toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Promoted Units') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            (((weekly_data[brand] || {})[date]||{})['promotedunits'] || 0)
                                        ).toLocaleString()}</Constant.NPDTableCell>
                                    })
                                    }
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{((((weekly_data['Total Category'] || weekly_data['All Channels']) || {})[date] || {})['promotedunits'] || 0).toLocaleString()}</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Base Velocity' && props.yAxisType === 'units_items') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            Math.round((((weekly_data[brand] || {})[date]||{})['baseunits']) / (((weekly_data[brand] || {})[date]||{})['carrieddoors'] / ((weekly_data[brand] || {})[date]||{})['totaldoors_s1'])) || 0
                                        ).toLocaleString()}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else if (props.yAxisTitle === 'Base Velocity' && props.yAxisType === 'units_brands') {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>{(
                                            Math.round((((weekly_data[brand] || {})[date]||{})['baseunits']) / (((weekly_data[brand] || {})[date]||{})['totaldoors_s1'] / ((weekly_data[brand] || {})[date]||{})['totaldoors_s1_segment'])) || 0
                                        ).toLocaleString()}</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                            else {
                                return <Constant.NPDTableRow key={"datarow_" + date + "_g4"}>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>{date}</Constant.NPDTableCell>
                                    {props.selectedBrands.map(brand => {
                                        return <Constant.NPDTableCell key={"datarow_" + brand + "_g4"} style={{ textAlign: 'center' }}>0</Constant.NPDTableCell>
                                    })
                                    }
                                </Constant.NPDTableRow>
                            }
                        })
                        }
                    </Constant.NPDTableBody>
                </Constant.NPDTable>
            </div>
        </Fragment>
    )
}

export default WeeklyViewMultipleSeries;