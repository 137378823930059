import React, { Component, Fragment } from 'react';
import { SingleBrandDropDown } from '../../UIElements/Dropdowns/SingleBrandDropDown'
//import { FilterBarReportViews } from '../../UIElements/Filters/FilterBarReportViews'
import FilterBarReportViewsFunctional from '../../UIElements/Filters/FilterBarReportViewsFunctional';
import WeeklyStackedBarMultipleSeries from '../../../Graphs/WeeklyStackedBarMultipleSeries'
import { reducer } from '../../Functions/Utilities/Reducer'
import * as Constant from '../../../constants';

export class AvgPromotedDiscountWithDollarShare extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {
        var aggtype = this.props.state.datasettimeagg

        var brand_dollars = (this.props.state.distinctdates).sort().map(date => ((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totaldollars'])).reduce(reducer)
        var cat_dollars = (this.props.state.distinctdates).sort().map(date => ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totaldollars'])).reduce(reducer)

        var avg_share = brand_dollars / cat_dollars

        //Create weekly_data_object for 'All Brands - Exlcuding Selected Brand...'

        let key = 'All Brands - Exlcuding ' + this.props.state.selectedBrand
        console.log(key)
        let allbrands = Object.keys(this.props.state.weekly_data)
        //Remove 'Total Category','Total Selection', and Selected Brand
        let removeidx = allbrands.indexOf('Total Selection')
        if (removeidx > -1) {
            allbrands.splice(removeidx, 1);
        }

        removeidx = allbrands.indexOf('Total Category')
        if (removeidx > -1) {
            allbrands.splice(removeidx, 1);
        }

        removeidx = allbrands.indexOf(this.props.state.selectedBrand)
        if (removeidx > -1) {
            allbrands.splice(removeidx, 1);
        }

        console.log(allbrands)

        //Add to object...weightedpctdisc_withoutno, totalunits_withoutno
        let all_brands_object = {}
        this.props.state.distinctdates.sort().forEach(date => {
            all_brands_object[date] = all_brands_object[date] || { 'weightedpctdisc_withoutno': 0, 'totalunits_withoutno': 0 }
            allbrands.forEach(brand => {
                all_brands_object[date]['weightedpctdisc_withoutno'] += ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['weightedpctdisc_withoutno'] || 0)
                all_brands_object[date]['totalunits_withoutno'] += ((((this.props.state.weekly_data || {})[brand] || {})[date] || {})['totalunits_withoutno'] || 0)
            })
        })

        console.log(all_brands_object)

        let weekly_data_adjusted = this.props.state.weekly_data
        weekly_data_adjusted[key] = all_brands_object

        var seriesArray = [
            {
                name: this.props.state.selectedBrand + ' - Average Promoted Discount',
                type: 'spline',
                color: Constant.graph_colors[0],
                yAxis: 1,
                tooltip: {
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            return '<span>Month: '
                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + '</span>'
                        }
                        else {
                            return '<span>Week Ending: '
                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + '</span>'
                        }
                    }
                },
                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                Math.min(((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['weightedpctdisc_withoutno']) / ((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totalunits_withoutno']), 1)
                ]),
                zIndex: 1
            },
            {
                name: this.props.state.selectedBrand + ' - Under/Over Indexed ' + this.props.state.currencyType + ' Share',
                type: 'column',
                color: Constant.graph_colors[2],
                yAxis: 0,
                tooltip: {
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            return '<span>Month: '
                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + '</span>'
                        }
                        else {
                            return '<span>Week Ending: '
                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + '</span>'
                        }
                    }
                },
                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                    ((((((this.props.state.weekly_data || {})[this.props.state.selectedBrand] || {})[date] || {})['totaldollars']) / ((((this.props.state.weekly_data || {})['Total Category'] || {})[date] || {})['totaldollars'])) / avg_share) - 1
                ]),
                zIndex: 0
            },

            {
                name: key + ' - Average Promoted Discount',
                type: 'spline',
                color: '#000000',
                yAxis: 1,
                tooltip: {
                    pointFormatter: function () {
                        let dt = new Date(this.x)
                        let offset = dt.getTimezoneOffset() * 60 * 1000
                        let date = new Date(this.x + offset)
                        if (aggtype === 'Monthly') {
                            return '<span>Month: '
                                + date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + '</span>'
                        }
                        else {
                            return '<span>Week Ending: '
                                + date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                + '<br>'
                                + this.series.name
                                + '<br> '
                                + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                + '</span>'
                        }
                    }
                },

                data: (this.props.state.distinctdates).sort().map(date => [Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10))),
                    Math.min((weekly_data_adjusted[key][date]['weightedpctdisc_withoutno'] / weekly_data_adjusted[key][date]['totalunits_withoutno']), 1)
                ]),
                zIndex: 2
            }
        ]
        return (

            <div style={{ width: "100%", height: "100%" }}>
                <div className="row">
                    <div className="col-sm-3">
                        <SingleBrandDropDown
                            state={this.props.state}
                            changeSelectedBrand={this.props.changeSelectedBrand}
                        />
                    </div>
                </div>
                <div>
                    {/* <FilterBarReportViews
                        state={this.props.state}
                        changeViewFilters={this.props.changeViewFilters}
                        handleToggle_useAttrGroups={this.props.handleToggle_useAttrGroups}
                        changeFilterPause_reportViews={this.props.changeFilterPause_reportViews}
                    /> */}
                    <FilterBarReportViewsFunctional />
                </div>
                <div style={{ height: '100%', width: "100%", }} className="row">
                    <div style={{ width: "100%", height: "100%" }}>
                        {(this.props.state.selectedBrand === 'Total Selection' || this.props.state.selectedBrand === 'Total Category') ?
                            <div className="row col-sm-12" style={{ width: "100%", height: "100%" }}>
                                <p style={{ padding:'25px'}}> Please Select a brand other than Total Selection in the dropdown to see the visual </p>
                            </div>
                            :
                            <Fragment>
                                <div className="row col-sm-12" style={{ width: "100%", height: "100%" }}>

                                    <div id="dollarshareweekonebrand" className="col-sm-12" style={{ width: "100%", height: "100%" }}>
                                        <WeeklyStackedBarMultipleSeries
                                            chart_title={"Average Promoted Discount Percent and " + this.props.state.currencyType + " Share by " + this.props.state.xAxisText}
                                            chart_title_info={
                                                this.props.state.selectedBrand === 'Total Selection' ?
                                                    this.props.state.chart_title_info_nobrands + ' Brand(s) Selected: ' + this.props.state.brandsstring
                                                    :
                                                    this.props.state.chart_title_info_nobrands + ' Brand Selected: ' + this.props.state.selectedBrand
                                            }
                                            seriesList={
                                                seriesArray
                                            }
                                            unitsvar={this.props.state.unitsvar}
                                            distinctdates={this.props.state.distinctdates}
                                            weekly_data={this.props.state.weekly_data}
                                            selectedBrand={this.props.state.selectedBrand}
                                            showDataTable={this.props.state.showDataTable}
                                            showDataTableStyle={this.props.state.showDataTableStyle}
                                            weekagg={this.props.state.filter_object.numweeks}
                                            height={1}
                                            attr_caption_text={this.props.state.attr_caption_text}
                                            yAxisTitle={'Under/Over Indexed ' + this.props.state.currencyType + ' Share'}
                                            yAxisTitle2='Average Promoted Discount'
                                            yAxisType='percent'
                                            yAxisType2='percent'
                                            selectedBrands={this.props.state.selectedBrands}
                                            aggperiod={this.props.state.datasettimeagg}
                                            currencycode={this.props.state.currencycode}
                                            currencytype={this.props.state.currencyType}
                                            metadata={this.props.state.chartmetadata}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}