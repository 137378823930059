import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import Switch from '@material-ui/core/Switch';
import Select from 'react-select'
import * as Constant from '../constants'
import { Layout } from '../Layout';
import { Loaders } from './StaticReportComponents/Loaders';
import FetchConfigWithDefault from './Functions/Utilities/FetchConfigWithDefault'
import FetchDataWithErrorHandling from './Functions/Utilities/FetchDataWithErrorHandling'

export class DatasetAccessView extends Component {
    static displayName = DatasetAccessView.name;

    constructor(props) {
        super(props);
        this.state = {
            toggle_is_staging: false,
            toggle_is_dataset: false,
            environment_args: 'env=active',

            // Data for both active and staging
            active_datasets_data: [],
            staging_datasets_data: [],
            active_reports_data: [],
            staging_reports_data: [],

            user_role_options: [
                { 'label': 'Admin', 'value': 'Admin' },
                { 'label': 'Power User', 'value': 'Power User' },
                { 'label': 'User', 'value': 'User' }
            ],
            report_dataset_map: {},
            report_options: [],
            selectedReport: null,
            selectedRole: null,

            dataset_store_groups: {},
            selectedStoreGroup: null,
            selectedDataset: null,
            store_groups_map: {},

            users_data: [],
            groups_data: [],
            totalusers: 0,
            totalgroups: 0,
            rowsPerPage: 10,
            page_users: 0,
            page_groups: 0,
            users_page_start: 0,
            groups_page_start: 0,

            setAPIRequestsFailed: false,
            isLoaded: false,
        }

        this.handleChangeActiveStaging_Switch = this.handleChangeActiveStaging_Switch.bind(this)
        this.handleChangeReportDataset_Switch = this.handleChangeReportDataset_Switch.bind(this)
        this.changeSelectedDataset = this.changeSelectedDataset.bind(this)
        this.changeSelectedStoreGroup = this.changeSelectedStoreGroup.bind(this)
        this.changeSelectedReport = this.changeSelectedReport.bind(this)
        this.changeSelectedUserRole = this.changeSelectedUserRole.bind(this)
        this.reloadControls = this.reloadControls.bind(this)
    }

    async handleChangeActiveStaging_Switch() {
        let new_value = !this.state.toggle_is_staging
        console.log('toggle_is_staging toggled to: ' + new_value)
        await this.setState({
            toggle_is_staging: new_value,
            environment_args: (new_value) ? 'env=staging' : 'env=active',
            selectedReport: null,
            selectedRole: null,
            selectedStoreGroup: null,
            selectedDataset: null,
            users_data: [],
            groups_data: []
        })

        await this.reloadControls()
    }

    handleChangeReportDataset_Switch() {
        let new_value = !this.state.toggle_is_dataset
        console.log('Is dataset (not report) toggled to: ' + new_value)
        this.setState({
            toggle_is_dataset: new_value,
            selectedReport: null,
            selectedRole: null,
            selectedStoreGroup: null,
            selectedDataset: null,
            users_data: [],
            groups_data: []
        })
    }

    async changeSelectedDataset(selectedOptions) {
        this.setState({
            selectedDataset: selectedOptions,
            selectedStoreGroup: null,
            users_data: [],
            groups_data: []
        })

        let dataset_id = selectedOptions.value
        let my_results = await this.FetchConfigWithDefault([
            { 'url': Constant.baseapiurl_gen + 'pdt/Datasets/' + dataset_id + '/StoreGroups?' + this.state.environment_args, 'default': {} }
        ], 'DatasetAccessView.js changeSelectedDataset()', 'getting store groups for dataset ' + dataset_id)

        if (my_results['ErrorCount'] > 0) {
            console.log('Failed to get store groups list for dataset ' + dataset_id)
            this.setState({
                setAPIRequestsFailed: true,
                showBackdrop: false
            });
            return
        }

        let store_groups_data = my_results['ReturnList'][0]
        let dataset_store_groups = [ {'label': 'Any Store Group', 'value': null} ]
        let store_groups_map = {}
        await store_groups_data.forEach(async store_group => {
            dataset_store_groups.push({ 'label': store_group.storegpdesc, 'value': store_group.storegpid })
            store_groups_map[store_group.storegpid] = store_group.storegpdesc
        })

        this.setState({
            store_groups_map,
            dataset_store_groups
        })
    }

    async changeSelectedStoreGroup(selectedOptions) {
        this.setState({ selectedStoreGroup: selectedOptions })
        let dataset_id = this.state.selectedDataset.value
        let store_group_id = selectedOptions.value

        let fetch_url = Constant.baseapiurl_gen + 'pdt/Audit/DatasetAccess/' + dataset_id + ((store_group_id === null) ? '' : '?storegpid=' + store_group_id)
        let my_results = await this.FetchConfigWithDefault([
            { 'url': fetch_url, 'default': {} }
        ], 'DatasetAccessView.js changeSelectedStoreGroup()', 'getting access information for dataset ' + dataset_id + ' store group ' + store_group_id)

        if (my_results['ErrorCount'] > 0) {
            console.log('Failed to get access information for dataset ' + dataset_id + ' and store group ' + store_group_id)
            this.setState({
                setAPIRequestsFailed: true,
                showBackdrop: false
            });
            return
        }

        let access_information = my_results['ReturnList'][0]
        let users_data = access_information.user_access
        let groups_data = access_information.group_access
        this.setState({
            users_data,
            totalusers: users_data.length,
            groups_data,
            totalgroups: groups_data.length
        })
    }

    async changeSelectedReport(selectedOptions) {
        this.setState({
            selectedReport: selectedOptions,
            users_data: [],
            groups_data: [],
            selectedRole: null
        })
        let report_id = selectedOptions.value

        let dataset_id = this.state.report_dataset_map[report_id]
        console.log('Report ' + report_id + ' uses dataset ' + dataset_id)
        let my_results = await this.FetchConfigWithDefault([
            { 'url': Constant.baseapiurl_gen + 'pdt/Datasets/' + dataset_id + '/StoreGroups?' + this.state.environment_args, 'default': {} }
        ], 'DatasetAccessView.js changeSelectedDataset()', 'getting store groups for report ' + report_id + ' (dataset ' + dataset_id + ')')

        if (my_results['ErrorCount'] > 0) {
            console.log('Failed to get store groups list for report ' + report_id + ' (dataset ' + dataset_id + ')')
            this.setState({
                setAPIRequestsFailed: true,
                showBackdrop: false
            });
            return
        }

        let store_groups_data = my_results['ReturnList'][0]
        let dataset_store_groups = []
        let store_groups_map = {}
        await store_groups_data.forEach(async store_group => {
            dataset_store_groups.push({ 'label': store_group.storegpdesc, 'value': store_group.storegpid })
            store_groups_map[store_group.storegpid] = store_group.storegpdesc
        })

        this.setState({
            store_groups_map,
            dataset_store_groups
        })
    }

    async changeSelectedUserRole(selectedOptions) {
        this.setState({ selectedRole: selectedOptions })
        let report_id = this.state.selectedReport.value
        let role = selectedOptions.value

        let my_results = await this.FetchConfigWithDefault([
            { 'url': Constant.baseapiurl_gen + 'pdt/Audit/ReportAccess/' + report_id + '?role=' + role, 'default': {} }
        ], 'DatasetAccessView.js changeSelectedUserRole()', 'getting access information for report ' + report_id + ' and role ' + role)

        if (my_results['ErrorCount'] > 0) {
            console.log('Failed to get access information for report ' + report_id + ' and role ' + role)
            this.setState({
                setAPIRequestsFailed: true,
                showBackdrop: false
            });
            return
        }

        let access_information = my_results['ReturnList'][0]
        let users_data = access_information.user_access
        let groups_data = access_information.group_access
        this.setState({
            users_data,
            totalusers: users_data.length,
            groups_data,
            totalgroups: groups_data.length
        })
    }

    async reloadControls() {
        console.log('Reloading controls: Staging? ' + this.state.toggle_is_staging)
        let datasets_data = (this.state.toggle_is_staging) ? this.state.staging_datasets_data : this.state.active_datasets_data

        let dataset_options = []
        let dataset_store_groups = {}
        datasets_data.forEach(dataset_data => {
            dataset_options.push({ 'label': dataset_data.description, 'value': dataset_data.dataset_id })
            dataset_store_groups[dataset_data.dataset_id] = dataset_data.store_groups
        })

        // Sort the list of datasets alphabetically by dataset name
        dataset_options.sort((a, b) => a.label.localeCompare(b.label))

        let reports_data = (this.state.toggle_is_staging) ? this.state.staging_reports_data : this.state.active_reports_data

        let report_options = []
        let report_dataset_map = {}
        reports_data.forEach(report_data => {
            report_options.push({ 'label': report_data.report_name, 'value': report_data.report_id })
            report_dataset_map[report_data.report_id] = report_data.dataset_id
        })

        // Sort the list of reports alphabetically
        report_options.sort((a, b) => a.label.localeCompare(b.label))

        this.setState({
            dataset_options,
            dataset_store_groups,
            report_options,
            report_dataset_map,
            isLoaded: true
        })
    }

    async componentDidMount() {
        this.FetchConfigWithDefault = FetchConfigWithDefault.bind(this)
        this.FetchDataWithErrorHandling = FetchDataWithErrorHandling.bind(this)

        let my_results = await this.FetchConfigWithDefault([
            { 'url': Constant.baseapiurl_gen + 'pdt/Datasets?env=active', 'default': {} },
            { 'url': Constant.baseapiurl_gen + 'pdt/Datasets?env=staging', 'default': {} },
            { 'url': Constant.baseapiurl_gen + 'pdt/ReportSettings', 'default': {} },
        ], 'DatasetAccessView.js componentDidMount()', 'info for all datasets and reports')

        if (my_results['ErrorCount'] > 0) {
            console.log('Bailing out of dataset parameter fetch in componentDidMount()')
            this.setState({
                setAPIRequestsFailed: true,
                showBackdrop: false,
                isLoaded: true
            });
            return
        }

        let active_datasets_data = my_results['ReturnList'][0]
        let staging_datasets_data = my_results['ReturnList'][1]
        let reports_data = my_results['ReturnList'][2]

        console.log('Number of active datasets: ' + active_datasets_data.length)
        console.log('Number of staging datasets: ' + staging_datasets_data.length)

        let active_reports_data = []
        let staging_reports_data = []
        reports_data.forEach(report_data => {
            if (report_data.active_exists) {
                active_reports_data.push(report_data)
            }
            if (report_data.staging_exists) {
                staging_reports_data.push(report_data)
            }
        })

        console.log('Number of active reports: ' + active_reports_data.length)
        console.log('Number of staging reports: ' + staging_reports_data.length)

        await this.setState({
            active_datasets_data,
            staging_datasets_data,
            active_reports_data,
            staging_reports_data
        })

        await this.reloadControls()

        console.log('componentDidMount complete')
    }

    render() {
        console.log(this.state)

        const closeModalFunc = () => {
            this.setState({ setAPIRequestsFailed: false });
        }

        const handleChangePage = (event, newPage, type) => {
            console.log(type)
            if (type === 'users') {
                this.setState({
                    page_users: newPage,
                    users_page_start: newPage * this.state.rowsPerPage
                })
            } else if (type === 'groups') {
                this.setState({
                    page_groups: newPage,
                    groups_page_start: newPage * this.state.rowsPerPage
                })
            }
        }

        const handleChangeRowsPerPage = (event) => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10),
                page_users: 0,
                page_groups: 0,
                users_page_start: 0,
                groups_page_start: 0
            })
        }

        return (
            <Layout title="Dataset Access">
                <Loaders
                    state={this.state || {}}
                />

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <Dialog
                        open={this.state.setAPIRequestsFailed}
                        onClose={closeModalFunc}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ overflowY: 'scroll' }}
                    >
                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                An error occurred loading the requested information.  You can try refreshing this page.
                                If it still does not load correctly, please reach out to pricing support at Pricing.Support@npd.com
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={closeModalFunc} className="btn btn-secondary">
                                Ok
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div style={{
                      width: '100%'
                    , position: 'absolute'
                    , left: '120px'
                    , top: '60px'
                    , backgroundColor: '#F4F4F4'
                    , minWidth: '1200px'
                    , maxWidth: 'calc(100vw - 120px)'
                    , minHeight: '100%'
                    , padding: '20px'
                }}>
                    <div className="row col-sm-4">
                        <ThemeProvider theme={Constant.muiTheme}>
                            <span style={{ lineHeight: '5' }}>Active</span>
                            <div style={{ lineHeight: '5' }}>
                                <Switch
                                    checked={this.state.toggle_is_staging}
                                    onChange={this.handleChangeActiveStaging_Switch}
                                    color="secondary"
                                />
                            </div>
                            <span style={{ lineHeight: '5' }}>Staging</span>

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <span style={{ lineHeight: '5' }}>Reports</span>
                            <div style={{ lineHeight: '5' }}>
                                <Switch
                                    checked={this.state.toggle_is_dataset}
                                    onChange={this.handleChangeReportDataset_Switch}
                                    color="secondary"
                                />
                            </div>
                            <span style={{ lineHeight: '5' }}>Datasets</span>
                        </ThemeProvider>
                    </div>

                    {this.state.toggle_is_dataset ?
                        <div>
                            <h3>Dataset Access by Users and Groups</h3>
                            <div className="col-sm-3" style={{ padding: '15px' }}>
                                <Select
                                    options={this.state.dataset_options}
                                    placeholder={'Select A Dataset'}
                                    onChange={this.changeSelectedDataset}
                                    value={this.state.selectedDataset}
                                    styles={Constant.dropdownStyles}
                                />
                            </div>

                            {this.state.selectedDataset ?
                                <div className="col-sm-3" style={{ padding: '15px' }}>
                                    <Select
                                        options={this.state.dataset_store_groups}
                                        placeholder={'Select A Store Group'}
                                        onChange={this.changeSelectedStoreGroup}
                                        value={this.state.selectedStoreGroup}
                                        styles={Constant.dropdownStyles}
                                    />
                                </div>
                                :
                                <div />
                            }
                        </div>
                        :
                        <div>
                            <h3>Report Access by Users and Groups</h3>
                            <div className="col-sm-3" style={{ padding: '15px' }}>
                                <Select
                                    options={this.state.report_options}
                                    placeholder={'Select A Report'}
                                    onChange={this.changeSelectedReport}
                                    value={this.state.selectedReport}
                                    styles={Constant.dropdownStyles}
                                />
                            </div>
                            {this.state.selectedReport ?
                                <div>
                                    <h3>User Role</h3>
                                    <span>
                                        Admins and Power Users can see all reports.  Users can only see report sthat they
                                        created or that have been specifically shared with them.  Select the user role for
                                        which you want to see access.  This does not affect the list of groups, only the
                                        list of users.
                                    </span>
                                    <div className="col-sm-3" style={{ padding: '15px' }}>
                                        <Select
                                            options={this.state.user_role_options}
                                            placeholder={'Select A User Role'}
                                            onChange={this.changeSelectedUserRole}
                                            value={this.state.selectedRole}
                                            styles={Constant.dropdownStyles}
                                        />
                                    </div>
                                </div>
                                :
                                <div/>
                            }
                        </div>
                    }

                    {this.state.users_data ?
                    <div>
                        <h4>The following Users have the selected access:</h4>
                        <Constant.NPDTable>
                            <Constant.NPDTableBody>
                                <Constant.NPDTableRow key="header_add_multipleusers">
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>User ID</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>User Name</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Company ID</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Company</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Company has access</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Groups Allowing Access</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>User Login Allowed</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                                {this.state.users_data.slice(this.state.users_page_start,
                                                             this.state.users_page_start + this.state.rowsPerPage).map(user => {
                                        let groups_list = user.groups.map(x => x.group_name + ' (' + x.group_id  + ')').join(', ')
                                        return <Constant.NPDTableRow key={"userrow_" + user.user_id}>
                                               <Constant.NPDTableCell style={{ textAlign: 'center' }}>{user.user_id}</Constant.NPDTableCell>
                                               <Constant.NPDTableCell style={{ textAlign: 'left' }}>{user.user_name}</Constant.NPDTableCell>
                                               <Constant.NPDTableCell style={{ textAlign: 'center' }}>{user.company_id}</Constant.NPDTableCell>
                                               <Constant.NPDTableCell style={{ textAlign: 'center' }}>{user.company_name}</Constant.NPDTableCell>
                                               <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                   {user.company_has_access ? 'true' : <span style={{ color: 'red' }}>false</span>}
                                               </Constant.NPDTableCell>
                                               <Constant.NPDTableCell style={{ textAlign: 'center' }}>{groups_list}</Constant.NPDTableCell>
                                               <Constant.NPDTableCell style={{ textAlign: 'center' }}>
                                                   {user.login_allowed ? 'true' : <span style={{color: 'red'}}>false</span>}
                                               </Constant.NPDTableCell>
                                           </Constant.NPDTableRow>
                                })}
                            </Constant.NPDTableBody>
                        </Constant.NPDTable>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.totalusers || 0}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page_users}
                            onChangePage={(event, newPage, type) => handleChangePage(event, newPage, 'users')}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                    :
                    <div />
                    }

                    {this.state.groups_data ?
                    <div>
                        <h4>The following Groups provide the selected access:</h4>
                        <Constant.NPDTable>
                            <Constant.NPDTableBody>
                                <Constant.NPDTableRow key="header_add_multipleusers">
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Group ID</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'left' }}>Group Name</Constant.NPDTableCell>
                                    <Constant.NPDTableCell style={{ textAlign: 'center' }}>Store groups</Constant.NPDTableCell>
                                </Constant.NPDTableRow>
                                {this.state.groups_data.slice(this.state.groups_page_start,
                                                              this.state.groups_page_start + this.state.rowsPerPage).map(group => {
                                    let store_groups_list = group.store_groups.map(gid => this.state.store_groups_map[gid] + ' (' + gid + ')').join(', ')
                                    return <Constant.NPDTableRow key={"grouprow_" + group.group_id}>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{group.group_id}</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'left' }}>{group.group_name}</Constant.NPDTableCell>
                                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>{store_groups_list}</Constant.NPDTableCell>
                                    </Constant.NPDTableRow>
                                })}
                            </Constant.NPDTableBody>
                        </Constant.NPDTable>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.totalgroups || 0}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page_groups}
                            onChangePage={(event, newPage, type) => handleChangePage(event, newPage, 'groups')}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                    :
                    <div />
                }
                </div>
            </Layout>
        )
    }
}
