import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Select, { components }  from 'react-select'
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TableChartRoundedIcon from '@material-ui/icons/TableChartRounded';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import a11yProps from '../Functions/Utilities/TabPropsFunc'
import * as Constant from '../../constants'
import selectColourStyles from '../../styles/SelectColourStyles'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class CompetitiveAnalysisSection extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.state.render_update) {
            //console.log('Rendering')
            return true
        }

        else {
            //console.log('Not Rendering')
            return false
        }
    }

    render() {

        var aggtype = this.props.state.datasettimeagg
        var unitsvar_forgraphs = this.props.state.unitsvar
        var g1Name = this.props.state.g1Name
        var g2Name = this.props.state.g2Name
        var currencyType = this.props.state.currencyType
        var currencycode = this.props.state.currencycode
        var datasettimeagg = this.props.state.datasettimeagg
        var totalunits_g1_weekly = this.props.state.totalunits_g1_weekly
        var total_aspprice_weekly_g1 = this.props.state.total_aspprice_weekly_g1
        var total_aspprice_weekly_g2 = this.props.state.total_aspprice_weekly_g2
        var totalunits_g2_weekly = this.props.state.totalunits_g2_weekly

        //Create Label For ASP Dist Chart...

        var selected_itemdistpriceoption = this.props.state.selected_itemdistpriceoption
        var price_label = selected_itemdistpriceoption.label

        var compareIndexGraphData_toview_var = []
        if (this.props.state.index_compare_type === 'Indexed') {
            compareIndexGraphData_toview_var = this.props.state.compareIndexGraphData
        }
        else if (this.props.state.index_compare_type === 'OverUnder') {
            compareIndexGraphData_toview_var = this.props.state.compareIndexGraphDataOverUnder
        }

        const Option = (props) => {
            //Define Variables in Style Here...
            let pbname = props.data.label
            let pbstart = props.data.value[0]
            let pbend = props.data.value[1]
            if ((String(pbstart).slice(-3) !== '.01') && pbstart > 0) {
                pbstart += 1
            }
            var pb_def_string
            if (!pbend) {
                pb_def_string = pbname + ': ' + this.props.state.currencysymbol + pbstart + ' + '
            }
            else if (pbstart === 0) {
                pb_def_string = pbname + ': Under ' + this.props.state.currencysymbol + pbend
            }
            else {
                pb_def_string = pbname + ': ' + this.props.state.currencysymbol + pbstart + ' - ' + this.props.state.currencysymbol + pbend
            }

            return (
                <Constant.HTMLTooltip title={
                    <Fragment>
                        <Typography style={{ fontWeight: "bold", color: "#4e106f" }}>{pb_def_string}</Typography>
                    </Fragment>
                } placement="right" {...props}>
                    <div style={{ marginLeft: '25px' }}>
                        <components.Option {...props} />
                    </div>
                </Constant.HTMLTooltip>
            );
        };

        return (
            <Fragment>
                <div align='center' className="contentHolderTight pdtOutputGraphs" >
                    <h5>Competitive Analysis</h5>
                    <Constant.NPDAppbar position="static" style={{ fontFamily: 'Roboto', backgroundColor: '#FFF' }}>
                            <Constant.NPDTabs
                                value={this.props.state.comptabvalue}
                                onChange={this.props.handleChangeCompTabValue}
                                aria-label="simple tabs example"
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Constant.NPDTab label="AGGREGATE VIEWS"  {...a11yProps(0)} />
                                <Constant.NPDTab label="TRENDED VIEWS"  {...a11yProps(0)} />
                                <Constant.NPDTab label="INDEXED SHARE TRENDED"  {...a11yProps(0)} />
                                <Constant.NPDTab label="PRICE DISTRIBUTION"  {...a11yProps(0)} />
                            </Constant.NPDTabs>
                        </Constant.NPDAppbar>
                        <div className="" align='left' id="IconBar">

        <IconButton
            className="btn btn-primary ChangeDataTableButton"
            onClick={this.props.changeShowDataTable}
            style={{
                padding: '5px', margin: '5px'
            }}
            id='SourceOfVolume'
        >
            <TableChartRoundedIcon />
        </IconButton>
    </div>
                        <div className="row" style={{ padding: "10px" }}>
        <div className='col-sm-6' >
            <span style={{
                color: '#4e106f',
                cursor: 'pointer',
                fontWeight: 'bold',
                backgroundColor: '#FFF',
                borderRadius: '10px 0px 0px 10px',
                padding: '9px'
            }}
                onClick={this.props.showGroup1Def}
                key="Group1DefLink"
            >
                Click Here to Define Group 1
					                            </span>
        </div>
        <div className='col-sm-6'>
            <span style={{
                color: '#4e106f',
                cursor: 'pointer',
                fontWeight: 'bold',
                backgroundColor: '#FFF',
                borderRadius: '10px 0px 0px 10px',
                padding: '9px'
            }}
                onClick={this.props.showGroup2Def}
                key="Group1DefLink"
            >
                Click Here to Define Group 2
					                            </span>
        </div>
    </div>
                        <div className="row" style={{ padding: "5px" }}>

        <div className='col-sm-6' >
            <h5>{this.props.state.g1Name + ' Definition'}</h5>
            {this.props.state.selectedBrands_g1.length > 0 ?
                this.props.state.exclude_brands_g1 ?
                    <span> Excluding Brands: {this.props.state.selectedBrands_g1.map(brand => brand.label).join(', ')}<br /></span>
                    :
                    <span> Brands: {this.props.state.selectedBrands_g1.map(brand => brand.label).join(', ')}<br /></span>
                :
                <span />
            }

            {Object.keys(this.props.state.selectedAttributes_g1).map(attribute => {
                if (this.props.state.selectedAttributes_g1[attribute].length === 0) {
                    return <span key={'blanklineg1tab1' + attribute} />
                }
                else {
                    return <span key={attribute + 'tab1g1'}> {this.props.state.attributenamelabels[attribute] + ' : ' + this.props.state.selectedAttributes_g1[attribute].map(attr => attr.label).join(', ')} <br /></span>
                }
            })
            }

            {this.props.state.selectedPriceBands_g1.length > 0 ?
                <span> Price Bands: {this.props.state.selectedPriceBands_g1.map(band => band.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedsegment1_g1.length > 0 ?
                <span> {this.props.state.segment1label + ': '}  {this.props.state.selectedsegment1_g1.map(segment => segment.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedsegment2_g1.length > 0 ?
                <span> {this.props.state.segment2label + ': '}  {this.props.state.selectedsegment2_g1.map(segment => segment.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedoutlet_g1.length > 0 ?
                <span> Outlets: {this.props.state.selectedoutlet_g1.map(outlet => outlet.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedchannel_g1.length > 0 ?
                <span> Channels: {this.props.state.selectedchannel_g1.map(channel => channel.label).join(', ')}<br /></span>
                :
                <span />
            }
        </div>
        <div className='col-sm-6' >
            <h5>{this.props.state.g2Name + ' Definition'}</h5>
            {this.props.state.selectedBrands_g2.length > 0 ?
                this.props.state.exclude_brands_g2 ?
                    <span> Excluding Brands: {this.props.state.selectedBrands_g2.map(brand => brand.label).join(', ')}<br /></span>
                    :
                    <span> Brands: {this.props.state.selectedBrands_g2.map(brand => brand.label).join(', ')}<br /></span>
                :
                <span />
            }
            {Object.keys(this.props.state.selectedAttributes_g2).map(attribute => {
                if (this.props.state.selectedAttributes_g2[attribute].length === 0) {
                    return <span key={'blanklineg2tab1' + attribute} />
                }
                else {
                    return <span key={attribute + 'tab1g2'}> {this.props.state.attributenamelabels[attribute] + ' : ' + this.props.state.selectedAttributes_g2[attribute].map(attr => attr.label).join(', ')} <br /></span>
                }
            })
            }
            {this.props.state.selectedPriceBands_g2.length > 0 ?
                <span> Price Bands: {this.props.state.selectedPriceBands_g2.map(band => band.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedsegment1_g2.length > 0 ?
                <span> {this.props.state.segment1label + ': '}  {this.props.state.selectedsegment1_g2.map(segment => segment.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedsegment2_g2.length > 0 ?
                <span> {this.props.state.segment2label + ': '}  {this.props.state.selectedsegment2_g2.map(segment => segment.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedoutlet_g2.length > 0 ?
                <span> Outlets: {this.props.state.selectedoutlet_g2.map(outlet => outlet.label).join(', ')}<br /></span>
                :
                <span />
            }

            {this.props.state.selectedchannel_g2.length > 0 ?
                <span> Channels: {this.props.state.selectedchannel_g2.map(channel => channel.label).join(', ')}<br /></span>
                :
                <span />
            }

        </div>
    </div>



        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.props.state.showGroup1DefModal}
            onClose={this.props.hideGroup1Def}
            style={{ overflowY: 'scroll' }}
        >

        <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.state.AllAttrsModalOpenG1}
                onClose={this.props.handleCloseAllAttrsModalG1}
                style={{ overflowY: 'scroll' }}
            >
                <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                    <h4>Product Attributes</h4>
                    <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                        {Object.keys(this.props.state.attributes).map(attribute => {
                            let attribute_vals = this.props.state.attributes[attribute]
                            let idname = attribute + "dropdown"
                            return <div className='col-sm-4' key={idname}>
                                <p>{this.props.state.attributenamelabels[attribute]}</p>
                                <Select
                                    id={idname}
                                    options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                    isMulti
                                    placeholder={'Select ' + this.props.state.attributenamelabels[attribute]}
                                    onChange={(item, position) => this.props.setg1definition(item, attribute)}
                                    value={this.props.state.selectedAttributes_g1[attribute]}
                                    closeMenuOnSelect={false}
                                    styles={Constant.dropdownStyles}
                                    styles={selectColourStyles}
                                />
                            </div>
                        })}
                    </div>
                </div>
            </Modal>
            <h4>First Group Definition</h4>
            <p>Use the following form to select the brands, attributes and price bands that define the first group for comparison.</p>
            <p>Group Name</p>
            <TextField
                variant="outlined"
                size="small"
                onChange={this.props.handleChangeG1NameBox}
                inputProps={{ 'aria-label': 'naked', id: "g1Name" }}
                value={this.props.state.g1Name}
            />

            <div className='col-sm-12'>
                <br />
                <h5>Brands</h5>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <ThemeProvider theme={Constant.muiTheme}>
                        <Checkbox
                            checked={this.props.state.exclude_brands_g1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                            onChange={this.props.handleToggle_exclude_brands_g1}
                        />
                                                    Exclude Brands?
                                                </ThemeProvider>
                </div>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <Select
                        id="BrandFilter1"
                        //options={this.props.state.reportbrands.map(brand => {
                        //    return { 'label': brand, 'value': brand }
                        //})}
                        options={this.props.state.brand_options_filters}
                        isMulti
                        placeholder={'Select Brands'}
                        onChange={(item, position) => this.props.setg1definition(item, 'brand')}
                        value={this.props.state.selectedBrands_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}
                    />
                </div>
            </div>

            <div className='col-sm-12'>
                <br />
                {Object.keys(this.props.state.attributes).length <= 9 ?
                    <h5>
                        Product Attributes &nbsp;
                                                    </h5>
                    :
                    <h5>
                        Product Attributes &nbsp;
                                                    <span style={{
                            color: '#4e106f',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontSize: '.8rem'
                        }}
                            onClick={this.props.handleOpenAllAttrsModalG1}
                            key="AllAttrsLink"
                        >
                            View All {Object.keys(this.props.state.attributes).length} Attributes
									                </span>
                    </h5>
                }
            </div>
            <div className='row col-sm-12'>
                {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                {
                    Object.keys(this.props.state.attributes).length <= 9 ?
                        Object.keys(this.props.state.attributes).map(attribute => {
                            let attribute_vals = this.props.state.attributes[attribute]
                            let idname = attribute + "dropdown"
                            return <div className='col-sm-4' key={idname}>
                                <p>{this.props.state.attributenamelabels[attribute]}</p>
                                <Select
                                    id={idname}
                                    options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                    isMulti
                                    placeholder={'Select ' + this.props.state.attributenamelabels[attribute]}
                                    onChange={(item, position) => this.props.setg1definition(item, attribute)}
                                    value={this.props.state.selectedAttributes_g1[attribute]}
                                    closeMenuOnSelect={false}
                                    styles={Constant.dropdownStyles}
                                    styles={selectColourStyles}

                                />
                            </div>
                        })
                        :
                        Object.keys(this.props.state.attributes).map((attribute, idx) => {
                            let attribute_vals = this.props.state.attributes[attribute]
                            let idname = attribute + "dropdown"
                            if (idx < 9) {
                                return <div className='col-sm-4' key={idname}>
                                    <p>{this.props.state.attributenamelabels[attribute]}</p>
                                    <Select
                                        id={idname}
                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                        isMulti
                                        placeholder={'Select ' + this.props.state.attributenamelabels[attribute]}
                                        onChange={(item, position) => this.props.setg1definition(item, attribute)}
                                        value={this.props.state.selectedAttributes_g1[attribute]}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}

                                    />
                                </div>
                            }

                            else {
                                return <div className='col-sm-4' key={idname} />
                            }
                        })

                }
            </div>

            <div className='col-sm-12'>
                <br />
                <h5>{this.props.state.segment1label + '/' + this.props.state.segment2label + ' and Outlets'} </h5>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment1label}</p>
                    <Select
                        id='segment1filter'
                        options={this.props.state.segment_1_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment1label}
                        onChange={(item, position) => this.props.setg1definition(item, 'segment1')}
                        value={this.props.state.selectedsegment1_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment2label}</p>
                    <Select
                        id='segment2filter'
                        options={this.props.state.segment_2_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment2label}
                        onChange={(item, position) => this.props.setg1definition(item, 'segment2')}
                        value={this.props.state.selectedsegment2_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment3label}</p>
                    <Select
                        id='segment3filter'
                        options={this.props.state.segment_3_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment3label}
                        onChange={(item, position) => this.props.setg1definition(item, 'segment3')}
                        value={this.props.state.selectedsegment3_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment4label}</p>
                    <Select
                        id='segment4filter'
                        options={this.props.state.segment_4_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment4label}
                        onChange={(item, position) => this.props.setg1definition(item, 'segment4')}
                        value={this.props.state.selectedsegment4_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>Outlets</p>
                    <Select
                        id='outletfilter'
                        options={this.props.state.all_outlets.map(outlet => { return { 'value': outlet, 'label': outlet } })}
                        isMulti
                        placeholder='Select an Outlet'
                        onChange={(item, position) => this.props.setg1definition(item, 'outlet')}
                        value={this.props.state.selectedoutlet_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
            </div>

            <div className='col-sm-12'>
                <br />
                <h5>Price Band</h5>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <p>Channels</p>
                    <Select
                        id='channelsfilter'
                        options={this.props.state.channel_options_filters}
                        isMulti
                        placeholder='Select a Channel'
                        onChange={(item, position) => this.props.setg1definition(item, 'channel')}
                        value={this.props.state.selectedchannel_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>Price Band</p>
                    <Select
                        id='pricebandfilter'
                        options={this.props.state.priceBandOptions}
                        isMulti
                        placeholder='Select a Price Band'
                        onChange={(item, position) => this.props.setg1definition(item, 'priceband')}
                        value={this.props.state.selectedPriceBands_g1}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        components={{ Option }}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4' >
                    <p style={{ color: '#fff' }}>Definition Complete</p>
                                    <ThemeProvider theme={Constant.muiTheme}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.props.hideGroup1Def}
                            className={Constant.classes.button}
                        >
                            Ok
	                                                        </Button>
                    </ThemeProvider>
                </div>
            </div>
            <div className='col-sm-12' style={{ height: '300px' }}>

            </div>

        </div>
    </Modal>

    <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.state.showGroup2DefModal}
        onClose={this.props.hideGroup2Def}
        style={{ overflowY: 'scroll' }}
    >
        <div style={{ transform: 'translate(0%, 0%)', backgroundColor: '#fff', width: '75%', overflowY: 'auto' }} className="contentHolder">
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.state.AllAttrsModalOpenG2}
                onClose={this.props.handleCloseAllAttrsModalG2}
                style={{ overflowY: 'scroll' }}
            >
                <div style={{ transform: 'translate(50%, 0%)', backgroundColor: '#fff', width: '50%', overflowY: 'auto' }} className="contentHolder">
                    <h4>Product Attributes</h4>
                    <div className="row col-sm-12" style={{ overflowY: 'auto' }}>
                        {Object.keys(this.props.state.attributes).map(attribute => {
                            let attribute_vals = this.props.state.attributes[attribute]
                            let idname = attribute + "dropdown"
                            return <div className='col-sm-4' key={idname}>
                                <p>{this.props.state.attributenamelabels[attribute]}</p>
                                <Select
                                    id={idname}
                                    options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                    isMulti
                                    placeholder={'Select ' + this.props.state.attributenamelabels[attribute]}
                                    onChange={(item, position) => this.props.setg2definition(item, attribute)}
                                    value={this.props.state.selectedAttributes_g2[attribute]}
                                    closeMenuOnSelect={false}
                                    styles={Constant.dropdownStyles}
                                    styles={selectColourStyles}

                                />
                            </div>
                        })}
                    </div>
                </div>
            </Modal>
            <h4>Second Group Definition</h4>
            <p>Use the following form to select the brands, attributes and price bands that define the second group for comparison.</p>

            <p>Group Name</p>
            <TextField
                variant="outlined"
                size="small"
                onChange={this.props.handleChangeG2NameBox}
                inputProps={{ 'aria-label': 'naked', id: "g2Name" }}
                value={this.props.state.g2Name}
            />

            <div className='col-sm-12'>
                <br />
                <h5>Brands</h5>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <ThemeProvider theme={Constant.muiTheme}>
                        <Checkbox
                            checked={this.props.state.exclude_brands_g2}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'Exclude Brands/Items?' }}
                            onChange={this.props.handleToggle_exclude_brands_g2}
                        />
                                                    Exclude Brands?
                                                </ThemeProvider>
                </div>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <Select
                        id="BrandFilter2"
                        //options={this.props.state.reportbrands.map(brand => {
                        //    return { 'label': brand, 'value': brand }
                        //})}
                        options={this.props.state.brand_options_filters}
                        isMulti
                        placeholder={'Select Brands'}
                        //onKeyDown={(item, position) => this.props.setg2definition(item, 'brand')}
                        onChange={(item, position) => this.props.setg2definition(item, 'brand')}
                        value={this.props.state.selectedBrands_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
            </div>

            <div className='col-sm-12'>
                <br />
                {Object.keys(this.props.state.attributes).length <= 9 ?
                    <h5>
                        Product Attributes &nbsp;
                                                    </h5>
                    :
                    <h5>
                        Product Attributes &nbsp;
                                                    <span style={{
                            color: '#4e106f',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontSize: '.8rem'
                        }}
                            onClick={this.props.handleOpenAllAttrsModalG2}
                            key="AllAttrsLink"
                        >
                            View All {Object.keys(this.props.state.attributes).length} Attributes
									                </span>
                    </h5>
                }
            </div>
            <div className='row col-sm-12'>

                {/*Look at the length of the filters.  If more than 9 show the first 9 then a link to the open the modal for all the filters...*/}

                {
                    Object.keys(this.props.state.attributes).length <= 9 ?
                        Object.keys(this.props.state.attributes).map(attribute => {
                            let attribute_vals = this.props.state.attributes[attribute]
                            let idname = attribute + "dropdown"
                            return <div className='col-sm-4' key={idname}>
                                <p>{this.props.state.attributenamelabels[attribute]}</p>
                                <Select
                                    id={idname}
                                    options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                    isMulti
                                    placeholder={'Select ' + this.props.state.attributenamelabels[attribute]}
                                    onChange={(item, position) => this.props.setg2definition(item, attribute)}
                                    value={this.props.state.selectedAttributes_g2[attribute]}
                                    closeMenuOnSelect={false}
                                    styles={Constant.dropdownStyles}
                                    styles={selectColourStyles}

                                />
                            </div>
                        })
                        :
                        Object.keys(this.props.state.attributes).map((attribute, idx) => {
                            let attribute_vals = this.props.state.attributes[attribute]
                            let idname = attribute + "dropdown"
                            if (idx < 9) {
                                return <div className='col-sm-4' key={idname}>
                                    <p>{this.props.state.attributenamelabels[attribute]}</p>
                                    <Select
                                        id={idname}
                                        options={attribute_vals.map(value => { return { 'label': value, 'value': value } })}
                                        isMulti
                                        placeholder={'Select ' + this.props.state.attributenamelabels[attribute]}
                                        onChange={(item, position) => this.props.setg2definition(item, attribute)}
                                        value={this.props.state.selectedAttributes_g2[attribute]}
                                        closeMenuOnSelect={false}
                                        styles={Constant.dropdownStyles}
                                        styles={selectColourStyles}

                                    />
                                </div>
                            }

                            else {
                                return <div className='col-sm-4' key={idname} />
                            }
                        })

                }

            </div>

            <div className='col-sm-12'>
                <br />
                <h5>{this.props.state.segment1label + '/' + this.props.state.segment2label + ' and Outlets'} </h5>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment1label}</p>
                    <Select
                        id='segment1filter'
                        options={this.props.state.segment_1_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment1label}
                        onChange={(item, position) => this.props.setg2definition(item, 'segment1')}
                        value={this.props.state.selectedsegment1_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment2label}</p>
                    <Select
                        id='segment2filter'
                        options={this.props.state.segment_2_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment2label}
                        onChange={(item, position) => this.props.setg2definition(item, 'segment2')}
                        value={this.props.state.selectedsegment3_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment3label}</p>
                    <Select
                        id='segment3filter'
                        options={this.props.state.segment_3_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment3label}
                        onChange={(item, position) => this.props.setg2definition(item, 'segment3')}
                        value={this.props.state.selectedsegment4_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>{this.props.state.segment4label}</p>
                    <Select
                        id='segment4filter'
                        options={this.props.state.segment_4_options_filters}
                        isMulti
                        placeholder={'Select a ' + this.props.state.segment4label}
                        onChange={(item, position) => this.props.setg2definition(item, 'segment4')}
                        value={this.props.state.selectedsegment2_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>Outlets</p>
                    <Select
                        id='outletfilter'
                        options={this.props.state.all_outlets.map(outlet => { return { 'value': outlet, 'label': outlet } })}
                        isMulti
                        placeholder='Select an Outlet'
                        onChange={(item, position) => this.props.setg2definition(item, 'outlet')}
                        value={this.props.state.selectedoutlet_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
            </div>

            <div className='col-sm-12'>
                <br />
                <h5>Price Band</h5>
            </div>
            <div className='row col-sm-12'>
                <div className='col-sm-4'>
                    <p>Channels</p>
                    <Select
                        id='channelsfilter'
                        options={this.props.state.channel_options_filters}
                        isMulti
                        placeholder='Select a Channel'
                        onChange={(item, position) => this.props.setg2definition(item, 'channel')}
                        value={this.props.state.selectedchannel_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}

                    />
                </div>
                <div className='col-sm-4'>
                    <p>Price Band</p>
                    <Select
                        id='pricebandfilterg2'
                        options={this.props.state.priceBandOptions}
                        isMulti
                        placeholder='Select a Price Band'
                        onChange={(item, position) => this.props.setg2definition(item, 'priceband')}
                        value={this.props.state.selectedPriceBands_g2}
                        closeMenuOnSelect={false}
                        styles={Constant.dropdownStyles}
                        styles={selectColourStyles}
                        components={{ Option }}
                    />
                </div>
                <div className='col-sm-4' >
                    <p style={{ color: '#fff' }}>Definition Complete</p>
                    <ThemeProvider theme={Constant.muiTheme}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.props.hideGroup2Def}
                            className={Constant.classes.button}
                        >
                            Ok
	                                                        </Button>
                    </ThemeProvider>
                </div>
            </div>
            <div className='col-sm-12' style={{ height: '300px' }}>

            </div>

        </div>
    </Modal>




                    <TabPanel value={this.props.state.comptabvalue} index={0}>
        <div className="row">
            <div id="helperText1" className="col-sm-12" style={{ display: 'none' }}>
                <p>
                    This tab will show aggregated metrics for two groups.
                    To see the view, please select a metric you wish to compare and define the groups.
                    The groups must be defined at the brand or product level, and each group can have one or more members.
                    Once all fields are selected, hit "Run Comparison" to see the view.
                    If any changes are made to the fields, hit "Run Comparison" again to see the updated view.
                                                </p>
            </div>
            <div className="col-sm-6">
                <span>Please Select a Metric You Wish to Compare</span>
                <Select
                    id="compareMetricdropdown"
                    options={this.props.state.comparisonMetricOptions}
                    placeholder={'Select a Metric'}
                    onChange={(item, action) => this.props.setcomparison_metric(item, action)}
                    value={this.props.state.comparisonMetricSelected}
                    styles={selectColourStyles}
                />
                <br/>
            </div>
            <div className="col-sm-6">
            </div>
        </div>
        <div style={{ height: '100%', width: "100%" }}>

            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'column',
                            height: (9 / 16 * 100) + '%' //16:9 ratio
                        },
                        metadata: this.props.state.chartmetadata,
                        caption: {
                            text: this.props.state.g1Name + ' Definition: ' + this.props.state.g1definition + '<br>' + this.props.state.g2Name + ' Definition: ' + this.props.state.g2definition + '<br>'
                        },
                        title: {
                            text: 'Comparison of ' + this.props.state.MetricToCompare + this.props.state.chart_title_info.split('<br>').slice(0, 4).join('<br>') + '<br>'
                            //text: 'Comparison of ' + this.props.state.MetricToCompare + '<br/> ' + this.props.state.title_geo_selected + this.props.state.othergeo_alr + '<br/> ' + this.props.state.title_num_weeks + ' Weeks ending ' + this.props.state.title_end_date.substring(5, 7) + '/' + this.props.state.title_end_date.substring(8, 10) + '/' + this.props.state.title_end_date.substring(0, 4) + '<br/>'
                        },
                        series: this.props.state.compareGraphData
                        //{
                        //name: this.props.state.MetricToCompare,
                        //data: this.props.state.compareGraphData
                        // }
                        ,
                        xAxis: [
                            {
                                title: {
                                    text: ''
                                },
                                type: 'category',
                                categories: [g1Name, g2Name]
                            },
                        ],

                        yAxis: [
                            {
                                title: {
                                    text: this.props.state.MetricToCompare
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                labels: {
                                    formatter: function () {
                                        if (this.chart.series[0].name === 'Payback Ratio') {
                                            return this.value
                                        }
                                        else {
                                            return Highcharts.numberFormat(Math.round(this.value * 100), 0) + "%";
                                        }

                                    }
                                }
                            },
                            {
                                title: {
                                    text: this.props.state.MetricToCompare
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                reversed: true,
                                opposite: true
                            },

                        ],
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                        if (this.series.name === 'Payback Ratio') {
                                            return this.y.toFixed(2)
                                        }
                                        else if (this.series.name === 'Market Lift') {
                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                        else if (this.series.name === 'Promoted Lift') {
                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                        else if (this.series.name === 'Efficiency') {
                                            return this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        }
                                    },
                                    color: 'black',
                                    borderRadius: 5,
                                    backgroundColor: 'rgba(214, 215, 216, 0.7)',
                                    borderWidth: 1,
                                    borderColor: '#d6d7d8',
                                    padding: 2,
                                    style: {
                                        textOutline: 'none',
                                        fontSize: '1.25rem'
                                    },
                                    //format: '{point.y:,.2f}'
                                }
                            },
                            waterfall: {
                                borderColor: '#ffffff'
                            },
                            column: {
                                grouping: false,
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                if (this.series.name === g1Name + ' - Discount Depth') {
                                    return '<span> Discount Depth <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }
                                else if (this.series.name === g2Name + ' - Discount Depth') {
                                    return '<span> Discount Depth <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 2 Members: '
                                        //+ group2members
                                        + ' <br/> x: '
                                        + this.x
                                        + '</span>'

                                }

                                else if (this.series.name === g1Name + ' - Payback Ratio') {
                                    return '<span> Payback Ratio <br> '
                                        + this.y.toFixed(2)
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g2Name + ' - Payback Ratio') {
                                    return '<span> Payback Ratio <br> '
                                        + this.y.toFixed(2)
                                        //+ ' <br/> Group 2 Members: '
                                        //+ group2members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - ' + currencyType + ' Share Index') {
                                    return '<span> ' + currencyType + ' Share Index <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Average ' + currencyType + ' Share') {
                                    return '<span> Average ' + currencyType + ' Share <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Market Lift') {
                                    return '<span> Market Lift <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Promoted Lift') {
                                    return '<span> Promoted Lift <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Efficiency') {
                                    return '<span> Efficiency <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === 'Payback Ratio') {
                                    if (this.x === 'Group 1') {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toFixed(2) //.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 1 Members: '
                                            //+ group1members
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toFixed(2) //.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 2 Members: '
                                            //+ group2members
                                            + '</span>'
                                    }
                                }

                                else {
                                    if (this.x === g1Name) {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 1 Members: '
                                            //+ group1members
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 2 Members: '
                                            //+ group2members
                                            + '</span>'
                                    }

                                }

                            }
                        },
                        exporting: {
                            showTable: this.props.state.showDataTable,
                            tableCaption: false
                        },

                    }}
            />
        </div>
    </TabPanel>
                    <TabPanel value={this.props.state.comptabvalue} index={1}>
        <div className="row">
            <div id="helperText2" className="col-sm-12" style={{ display: 'none' }}>
                <p>
                    This tab will show trended metrics for two groups.
                    To see the view, please select a metric you wish to compare and define the groups.
                    The groups must be defined at the brand or product level, and each group can have one or more members.
                    Once all fields are selected, hit "Run Comparison" to see the view.
                    If any changes are made to the fields, hit "Run Comparison" again to see the updated view.
                                                </p>
            </div>
            <div className="col-sm-6">
                <span>Please Select a Trended View You Wish to See</span>
                <Select
                    id="compareMetricdropdownTrended"
                    options={this.props.state.comparisonMetricOptions_trended}
                    placeholder={'Select a View'}
                    onChange={(item, action) => this.props.setcomparison_metric_trended(item, action)}
                    value={this.props.state.comparisonMetricSelected_trended}
                    styles={selectColourStyles}
                />
                <br />
            </div>
            <div className="col-sm-6">

            </div>
        </div>
        <div style={{ height: '100%', width: "100%" }}>

            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'column',
                            height: (9 / 16 * 100) + '%',
                            zoomType: 'xy',
                        },
                        metadata: this.props.state.chartmetadata,
                        caption: {
                            text: this.props.state.g1Name + ' Definition: ' + this.props.state.g1definition + '<br>' + this.props.state.g2Name + ' Definition: ' + this.props.state.g2definition + '<br>'
                        },
                        title: {
                            text: 'Comparison of ' + this.props.state.MetricToCompare_trended + '<br/> By ' + this.props.state.weeklytimeperiods + this.props.state.chart_title_info.split('<br>').slice(0, 4).join('<br>') + '<br>'
                        },
                        series: this.props.state.compareGraphData_trended
                        ,
                        xAxis: [
                            {
                                title: {
                                    text: this.props.state.xAxisText
                                },
                                type: 'datetime',
                                dateTimeLabelFormats: {
                                    month: this.props.state.xAxisLabelFormatString
                                },
                                tickPositions: this.props.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                labels: {
                                    formatter: function () {
                                        let dt = new Date(this.value)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.value + offset)
                                        const options = { year: 'numeric', month: 'short' };
                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                        if ((datasettimeagg || "") === 'Monthly') {
                                            return date.toLocaleDateString(undefined, options)
                                        }
                                        else {
                                            return date.toLocaleDateString(undefined, options2) //toISOString().substring(0, 10)
                                        }

                                    },
                                    rotation: 270,
                                    step: this.props.state.xAxisStepNum,
                                    padding: this.props.state.xAxisPadNum,
                                    style: {
                                        fontSize: this.props.state.xAxisFontSizeString
                                    }
                                }
                            },
                        ],

                        yAxis: [
                            {
                                title: {
                                    text: this.props.state.MetricToCompare_trended_1
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    }

                                }
                            },
                            {
                                title: {
                                    text: this.props.state.MetricToCompare_trended_2
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                reversed: this.props.state.metric_trended_2_reverse,
                                opposite: false,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                }
                            },
                            {
                                title: {
                                    text: this.props.state.MetricToCompare_trended_2
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                reversed: this.props.state.metric_trended_2_reverse,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                    }
                                }
                            },
                            {
                                title: {
                                    text: g1Name + 'Base/Average Selling Price'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                reversed: this.props.state.metric_trended_2_reverse,
                                opposite: false,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                    }
                                }
                            },

                        ],
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: false,
                                    format: '{point.y:,.2f}'
                                }
                            },
                            waterfall: {
                                borderColor: '#ffffff'
                            },
                            column: {
                                grouping: false,
                            }
                        },
                        tooltip: {
                            //xDateFormat: this.props.state.xDateFormatString,
                            formatter: function () {
                                let datex_raw = new Date(this.x)
                                let tz_offset = datex_raw.getTimezoneOffset()
                                let datex = new Date(this.x - (tz_offset * 60))
                                let formatted_datex = datex.toISOString().substring(0, 10)
                                if (this.series.name === g1Name + ' - Discount Depth') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Discount Depth <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + ' <br/> ASP: '
                                            + (total_aspprice_weekly_g1[formatted_datex] / totalunits_g1_weekly[formatted_datex]).toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Discount Depth <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + ' <br/> ASP: '
                                            + (total_aspprice_weekly_g1[formatted_datex] / totalunits_g1_weekly[formatted_datex]).toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }

                                }
                                else if (this.series.name === g2Name + ' - Discount Depth') {
                                    //let formatted_datex = String(datex.getUTCFullYear()) + '-' + String(datex.getUTCMonth() + 1) + '-' + String(datex.getUTCDate()-1)
                                    if (aggtype === 'Monthly') {
                                        return '<span> Discount Depth <br>  Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 2 Members: '
                                            //+ group2members
                                            + ' <br/> ASP: '
                                            + (total_aspprice_weekly_g2[formatted_datex] / totalunits_g2_weekly[formatted_datex]).toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Discount Depth <br>  Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 2 Members: '
                                            //+ group2members
                                            + ' <br/> ASP: '
                                            + (total_aspprice_weekly_g2[formatted_datex] / totalunits_g2_weekly[formatted_datex]).toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g1Name + ' - Payback Ratio') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Payback Ratio <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toFixed(2)
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Payback Ratio <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toFixed(2)
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g2Name + ' - Payback Ratio') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Payback Ratio <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toFixed(2)
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Payback Ratio <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toFixed(2)
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g1Name + ' - Average Selling Price') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Average Selling Price <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Average Selling Price <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g2Name + ' - Average Selling Price') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Average Selling Price <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Average Selling Price <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === 'Average Selling Price Gap - Group 1 minus Group 2') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Average Selling Price Gap<br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Average Selling Price Gap<br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === 'Base Price Gap - Group 1 minus Group 2') {
                                    if (aggtype === 'Monthly') {
                                        return '<span>  Base Price Gap<br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>  Base Price Gap<br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === g1Name + ' - Base Price') {
                                    if (aggtype === 'Monthly') {
                                        return '<span>  Base Price <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>  Base Price <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g2Name + ' - Base Price') {
                                    if (aggtype === 'Monthly') {
                                        return '<span>  Base Price <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>  Base Price <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === g1Name + ' - ' + currencyType + ' Share Index') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> ' + currencyType + ' Share Index <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> ' + currencyType + ' Share Index <br>  Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g1Name + ' - Average ' + currencyType + ' Share') {

                                    if (aggtype === 'Monthly') {
                                        return '<span> Average ' + currencyType + ' Share <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Average ' + currencyType + ' Share <br>  Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g1Name + ' - Market Lift') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Market Lift <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Market Lift <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === g2Name + ' - Market Lift') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Market Lift <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Market Lift <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === g1Name + ' - Promoted Lift') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Promoted Lift <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Promoted Lift <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === g2Name + ' - Promoted Lift') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> Promoted Lift <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Promoted Lift <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === g1Name + ' - Efficiency') {

                                    if (aggtype === 'Monthly') {
                                        return '<span> Efficiency <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Efficiency <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === g2Name + ' - Efficiency') {

                                    if (aggtype === 'Monthly') {
                                        return '<span> Efficiency <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> Efficiency <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                }

                                else if (this.series.name === 'Payback Ratio') {
                                    if (this.x === g1Name) {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toFixed(2)
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toFixed(2)
                                            + '</span>'
                                    }
                                }

                                else {
                                    if (this.x === g1Name) {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            + '</span>'
                                    }

                                }

                            }
                        },
                        exporting: {
                            showTable: this.props.state.showDataTable,
                            tableCaption: false
                        },

                    }}
            />
        </div>
    </TabPanel>
                    <TabPanel value={this.props.state.comptabvalue} index={2}>
        <div className="row">
            <div id="helperText3" className="col-sm-12" style={{ display: 'none' }}>
                <p>
                    This tab will show the indexed share for a group.
                    To see the view, please define the members for the group.
                    The group must be defined at the brand or product level, and the group can have one or more members.
                    There is also an option to add an additional metric overlayed on the view.
                    Once all fields are selected, hit "Run Comparison" to see the view.
                    If any changes are made to the fields, hit "Run Comparison" again to see the updated view.
                                                </p>
            </div>
            <div className="col-sm-6">
                <span>Please Select a Metric You Wish to Overlay</span>
                <Select
                    id="OverlayMetricdropdown"
                    options={this.props.state.overlayMetricOptions}
                    placeholder={'Select a Metric'}
                    onChange={(item, action) => this.props.setoverlay_metric(item, action)}
                    value={this.props.state.overlayMetricSelected}
                    styles={selectColourStyles}
                />
                <br/>
            </div>
            <div className="col-sm-6">
                <div className="row" style={{ padding: "25px" }}>
                    <ThemeProvider theme={Constant.muiTheme}>
                        <span style={{ width: '100px' }}></span>
                        <span>Indexed View</span>
                        <Switch
                            checked={this.props.state.indexSwitchCheck}
                            onChange={this.props.handleChange_IndexSwitch}
                            color="secondary"
                        />
                        <span>Over/Under View </span>
                        <span style={{ width: '50px' }}></span>
                        <span>{this.props.state.unitsvar}</span>
                        <Switch
                            checked={this.props.state.indexSwitchCheckDollars}
                            onChange={this.props.handleChange_IndexSwitchDollars}
                            color="secondary"
                        />
                        <span>{this.props.state.currencyType} </span>
                    </ThemeProvider>
                </div>
            </div>
        </div>
        <div style={{ height: '100%', width: "100%" }}>

            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'column',
                            height: (9 / 16 * 100) + '%', //16:9 ratio
                            zoomType: 'xy',
                        },
                        metadata: this.props.state.chartmetadata,
                        caption: {
                            text: this.props.state.g1Name + ' Definition: ' + this.props.state.g1definition + '<br>' + this.props.state.g2Name + ' Definition: ' + this.props.state.g2definition + '<br>'
                        },
                        title: {
                            text: this.props.state.indexSwitchCheckDollars ?
                                'Comparison of  ' + this.props.state.currencyTypeSingular + ' Share By ' + this.props.state.weeklytimeperiods + this.props.state.chart_title_info.split('<br>').slice(0, 4).join('<br>') + '<br>'
                                :
                                'Comparison of  ' + this.props.state.unitsvarSingular + ' Share By ' + this.props.state.weeklytimeperiods + this.props.state.chart_title_info.split('<br>').slice(0, 4).join('<br>') + '<br>'
                        },
                        series: compareIndexGraphData_toview_var
                        ,
                        xAxis: [
                            {
                                title: {
                                    text: this.props.state.xAxisText
                                },
                                type: 'datetime',
                                dateTimeLabelFormats: {
                                    month: '%b %Y'
                                },
                                dateTimeLabelFormats: {
                                    month: this.props.state.xAxisLabelFormatString
                                },
                                tickPositions: this.props.state.distinctdates.sort().map(date => Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)))),
                                labels: {
                                    formatter: function () {
                                        let dt = new Date(this.value)
                                        let offset = dt.getTimezoneOffset() * 60 * 1000
                                        let date = new Date(this.value + offset)
                                        const options = { year: 'numeric', month: 'short' };
                                        const options2 = { year: 'numeric', month: 'numeric', day: 'numeric' }
                                        if ((datasettimeagg || "") === 'Monthly') {
                                            return date.toLocaleDateString(undefined, options)
                                        }
                                        else {
                                            return date.toLocaleDateString(undefined, options2) //toISOString().substring(0, 10)
                                        }

                                    },
                                    rotation: 270,
                                    step: this.props.state.xAxisStepNum,
                                    padding: this.props.state.xAxisPadNum,
                                    style: {
                                        fontSize: this.props.state.xAxisFontSizeString
                                    }
                                }
                            },
                        ],

                        yAxis: [
                            {
                                title: {
                                    text:
                                        this.props.state.indexSwitchCheckDollars ?
                                            'Indexed ' + this.props.state.currencyTypeSingular + ' Share'
                                            :
                                            'Indexed ' + this.props.state.unitsvarSingular + ' Share'

                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                }
                            },
                            {
                                title: {
                                    text: 'Average Promoted Discount/Lift/Efficiency'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                reversed: false,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    }
                                }
                            },
                            {
                                title: {
                                    text: 'Average Selling Price/Base Price/Price Gaps'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                reversed: false,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                    }
                                }
                            },
                            {
                                title: {
                                    text: 'Average Selling Price/Base Price'
                                },
                                gridLineColor: "#CCC",
                                gridLineWidth: 1,
                                reversed: false,
                                opposite: true,
                                labels: {
                                    formatter: function () {
                                        return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                    }
                                }
                            },

                        ],
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: false,
                                    format: '{point.y:,.2f}'
                                }
                            },
                            waterfall: {
                                borderColor: '#ffffff'
                            },
                            column: {
                                //pointWidth: 20,
                                //groupPadding: 0.5
                                grouping: false,
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                let datex_raw = new Date(this.x)
                                let tz_offset = datex_raw.getTimezoneOffset()
                                let datex = new Date(this.x - (tz_offset * 60))
                                let formatted_datex = datex.toISOString().substring(0, 10)
                                if (this.series.name === g1Name + ' - Discount Depth') {
                                    return '<span> Discount Depth <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }
                                else if (this.series.name === g2Name + ' - Discount Depth') {
                                    return '<span> Discount Depth <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 2 Members: '
                                        //+ group2members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Payback Ratio') {
                                    return '<span> Payback Ratio <br> '
                                        + this.y.toFixed(2)
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g2Name + ' - Payback Ratio') {
                                    return '<span> Payback Ratio <br> '
                                        + this.y.toFixed(2)
                                        //+ ' <br/> Group 2 Members: '
                                        //+ group2members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - ' + currencyType + ' Share Index') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> ' + currencyType + ' Share Index <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> ' + currencyType + ' Share Index <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g1Name + ' - Over/Under ' + currencyType + ' Share') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> ' + currencyType + ' Share Index <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>  Percent Over/Under Share'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> ' + currencyType + ' Share Index <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>  Percent Over/Under Share'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g1Name + ' - Over/Under ' + unitsvar_forgraphs + ' Share') {
                                    if (aggtype === 'Monthly') {
                                        return '<span> ' + unitsvar_forgraphs + ' Share Index <br> Month: '
                                            + datex.toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
                                            + '<br/>  Percent Over/Under Share'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }
                                    else {
                                        return '<span> ' + unitsvar_forgraphs + ' Share Index <br> Week Ending: '
                                            + datex.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: 'numeric' })
                                            + '<br/>  Percent Over/Under Share'
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            + '</span>'
                                    }

                                }

                                else if (this.series.name === g1Name + ' - Average ' + currencyType + ' Share') {
                                    return '<span> Average ' + currencyType + ' Share <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Average Promoted Discount') {
                                    return '<span> Average Promoted Discount <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Average Selling Price') {
                                    return '<span> Average Selling Price <br> '
                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Base Price') {
                                    return '<span> Base Price <br> '
                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - ASP Gap') {
                                    return '<span> ASP Gap <br> '
                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Base Price Gap') {
                                    return '<span> Base Price Gap <br> '
                                        + this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Market Lift') {
                                    return '<span> Market Lift <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Promoted Lift') {
                                    return '<span> Promoted Lift <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === g1Name + ' - Efficiency') {
                                    return '<span> Efficiency <br> '
                                        + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                        //+ ' <br/> Group 1 Members: '
                                        //+ group1members
                                        + '</span>'
                                }

                                else if (this.series.name === 'Payback Ratio') {
                                    if (this.x === g1Name) {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toFixed(2) //.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 1 Members: '
                                            //+ group1members
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toFixed(2) //.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 2 Members: '
                                            //+ group2members
                                            + '</span>'
                                    }
                                }

                                else {
                                    if (this.x === g1Name) {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 1 Members: '
                                            //+ group1members
                                            + '</span>'
                                    }
                                    else {
                                        return '<span>'
                                            + this.series.name
                                            + '<br> '
                                            + this.y.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            //+ ' <br/> Group 2 Members: '
                                            //+ group2members
                                            + '</span>'
                                    }

                                }

                            }
                        },
                        exporting: {
                            showTable: this.props.state.showDataTable,
                            tableCaption: false
                        },

                    }}
            />

            <Constant.NPDTable>
                <Constant.NPDTableBody>
                    <Constant.NPDTableRow key="ShareSummaryTable">
                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Metric Range</Constant.NPDTableCell>
                        <Constant.NPDTableCell style={{ textAlign: 'center' }}>Average Share</Constant.NPDTableCell>
                    </Constant.NPDTableRow>
                    {(this.props.state.sharelookup_sorted || []).map(label => {
                        return <Constant.NPDTableRow key={"datarow_" + label + "_g15"}>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{label}</Constant.NPDTableCell>
                            <Constant.NPDTableCell style={{ textAlign: 'center' }}>{((this.props.state.sharelookup[label]['totalshare'] / this.props.state.sharelookup[label]['numweeks']) || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Constant.NPDTableCell>
                        </Constant.NPDTableRow>
                    })}
                </Constant.NPDTableBody>
            </Constant.NPDTable>
        </div>
    </TabPanel>
                    <TabPanel value={this.props.state.comptabvalue} index={3}>

                        <span className="formfieldtitle"> Select a Price</span>
                        <Select
                            id="comp_price_dist"
                            options={this.props.state.itemdistpriceoptions}
                            placeholder={'Select a Price Distribution'}
                            onChange={(item) => this.props.setPriceDistOption(item)}
                            value={this.props.state.selected_itemdistpriceoption}
                        //value={{ 'value': this.props.state.selectedItem_new, 'label': this.props.state.selectedItem_new }}
                        />

        {this.props.state.ASP_g1_g2 ?
                            <p>{g1Name} {price_label} of {this.props.state.ASP_g1_g2} is higher than {(this.props.state.aspPercentileVal_g1_g2 || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })} of {g2Name}'s volume. </p>
            :
            <p />
        }

        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'column',
                    height: (9 / 16 * 100) + '%' //16:9 ratio
                },
                metadata: this.props.state.chartmetadata,
                caption: {
                    text: this.props.state.g1Name + ' Definition: ' + this.props.state.g1definition + '<br>' + this.props.state.g2Name + ' Definition: ' + this.props.state.g2definition + '<br>'
                },
                //caption: { text: 'This is a test' },
                title: { text: price_label + ' Distribution for Group 2' + '<br>' + this.props.state.weeklytimeperiods + this.props.state.chart_title_info.split('<br>').slice(0, 4).join('<br>') + '<br>' },
                series: [
                    {
                        name: 'Group 2 ' + price_label + ' Distribution',
                        type: 'column',
                        data: this.props.state.percentile_vals_g1_g2,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.y.toLocaleString(undefined, { style: 'currency', currency: currencycode })
                            }
                        }
                    },
                ],
                xAxis: [

                    {
                        title: { text: 'Percentile' },
                        alignTicks: false,
                        opposite: false,
                        visible: true,
                        categories: [.05, .10, .15, .20, .25, .30, .35, .40, .45, .50, .55, .60, .65, .70, .75, .80, .85, .90, .95],
                        labels: {
                            formatter: function () {
                                return this.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            }
                        },
                        plotLines: [{
                            color: '#ff8b6a',
                            dashStyle: 'solid',
                            value: ((this.props.state.aspPercentileVal_g1_g2 / .05) - 1),
                            width: 3,
                            label: {
                                text: g1Name + price_label + ': ' + this.props.state.ASP_g1_g2,
                                align: 'left',
                                rotation: 0
                            },
                            zIndex: 5
                        }]
                    },

                ],

                yAxis: [
                    {
                        title: { text: price_label },
                        visible: true,
                        labels: {
                            formatter: function () {
                                return this.value.toLocaleString(undefined, { style: 'currency', currency: currencycode, minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            }
                        },
                        min: this.props.state.itemDistYAxisMin_g1_g2
                    },


                ],
                tooltip: {
                    enabled: false
                }
            }
            }
        />
    </TabPanel>

                </div>

            </Fragment>
        )
    }
}