import React, { Component, Fragment } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import Select from 'react-select'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import '../styles/custom.css'
import * as Constant from '../constants'
import selectColourStyles from '../styles/SelectColourStyles'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}


export class PdtStackingDialog extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Fragment>
                {this.props.state.pdtselected &&
                    <TabPanel value={this.props.state.datasetTabSelected} index={1}>
                        <div className="row">
                            <div className='col-sm-12 row'>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select a country</span>
                                    <Select
                                        placeholder='Select Country'
                                        inputId="pdtstackingcountrydropdown"
                                        options={this.props.state.finalSortCountry}
                                        width="200px"
                                        onChange={(item, action) => this.props.onChangePdtStackingCountry(item, action)}
                                        value={(this.props.state.selectedCountryName) ? { 'label': this.props.state.selectedCountryName, 'value': this.props.state.selectedCountry } : ''} 
                                        styles={selectColourStyles}
                                />
                                </div>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select one industry</span>
                                    <Select
                                        placeholder='Select Industry'
                                        inputId="pdtstackingindustrydropdown"
                                        options={this.props.state.countryInd_options}
                                        width="200px"
                                        onChange={(item, action) => this.props.onChangePdtStackingIndustry(item, action)}
                                        value={(this.props.state.selectedIndustryName) ? { 'label': this.props.state.selectedIndustryName, 'value': this.props.state.selectedIndustry } : ''} 
                                        styles={selectColourStyles}
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select the environment</span><br />
                                    <FormControlLabel
                                        className='formfieldtitle'
                                        control={<ThemeProvider theme={Constant.muiTheme}>
                                            <Radio
                                                checked={this.props.state.stacking_env === 'active'}
                                                onChange={(event) => this.props.onChangePdtStackingEnvRadio(event)}
                                                value="active"
                                                name="stackingenvradio"
                                                size="small"
                                                color="secondary"
                                                id="activeradio" />
                                        </ThemeProvider>}
                                        label="Active" />
                                    <FormControlLabel
                                        className='formfieldtitle'
                                        control={<ThemeProvider theme={Constant.muiTheme}>
                                            <Radio
                                                checked={this.props.state.stacking_env === 'staging'}
                                                onChange={(event) => this.props.onChangePdtStackingEnvRadio(event)}
                                                value="staging"
                                                name="stackingenvradio"
                                                size="small"
                                                color="secondary"
                                                id="stagingradio" />
                                        </ThemeProvider>}
                                        label="Staging" />
                                </div>
                                <div className='col-sm-3'></div>
                            </div>
                            <div className='col-sm-12 row' style={{ marginTop: '10px' }}>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Select the Template Dataset for Stacking</span>
                                    <Select
                                        placeholder='Select a Template Dataset'
                                        inputId="pdtstackingtemplatedataset"
                                        options={this.props.state.template_dataset_options}
                                        width="200px"
                                        onChange={(item, action) => this.props.onChangePdtStackingTemplateDataset(item, action)}
                                        value={(this.props.state.selectedTemplateDatasetName) ? { 'label': this.props.state.selectedTemplateDatasetName, 'value': this.props.state.selectedTemplateDatasetId } : ''}
                                        styles={selectColourStyles}
                                />
                                </div>
                                <div className='col-sm-9'>
                                    <span className='formfieldtitle'>Select all datasets to stack with the template</span>
                                    <Select
                                        placeholder='Select additional datasets'
                                        options={this.props.state.extra_dataset_options}
                                        onChange={(selectedOptions, action) => this.props.onChangePdtStackingExtraDatasets(selectedOptions, action)}
                                        value={this.props.state.extra_dataset_options_values}
                                        isMulti 
                                        styles={selectColourStyles}
                                />
                                </div>
                            </div>
                            <div className='col-sm-12 row' style={{ marginTop: '10px' }}>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Enter the Stacked Dataset prefix</span>
                                    <TextField
                                        placeholder='Enter the desired dataset prefix'
                                        variant="outlined"
                                        inputProps={{ style: { padding: 10 } }}
                                        id="pdtStackingPrefix"
                                        value={this.props.state.pdt_stacking_prefix}
                                        onChange={this.props.onChangePdtStackingPrefix}
                                        fullWidth />
                                </div>
                                <div className='col-sm-3'>
                                    <span className='formfieldtitle'>Enter the Stacked Dataset Short Description</span>
                                    <TextField
                                        placeholder='Create a short description'
                                        variant="outlined"
                                        inputProps={{ style: { padding: 10 } }}
                                        id="pdtStackingShortDesc"
                                        value={this.props.state.pdt_stacking_short_desc}
                                        onChange={this.props.onChangePdtStackingShortDesc}
                                        fullWidth />
                                </div>
                                <div className='col-sm-6'>
                                    <span className='formfieldtitle'>Enter the Stacked Dataset Full Description</span>
                                    <TextField
                                        placeholder='Create a long description'
                                        variant="outlined"
                                        inputProps={{ style: { padding: 10 } }}
                                        id="pdtStackingLongDesc"
                                        value={this.props.state.pdt_stacking_description}
                                        onChange={this.props.onChangePdtStackingLongDesc}
                                        fullWidth />
                                </div>
                            </div>
                            <div className='col-sm-12 row' style={{ margin: '25px' }}>
                                <div className='col-sm-8'>
                                </div>
                                <div className='col-sm-4' style={{ textAlign: 'right' }}>
                                    <ThemeProvider theme={this.muiTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.props.handleSubmitPdtStacking}
                                            disabled={!this.props.state.pdt_stacking_button_enabled}
                                        >
                                            Submit Stacking Request
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                            <div className="row" style={{ textAlign: 'left', margin: '25px' }}>
                                <p>The process of stacking involves the following steps:</p>
                                <ul>
                                    <li>
                                        Select a template dataset by selecting a country, industry, environment, and then template dataset.  Once
                                        you select the template dataset, a default value for the new stacked dataset prefix and the new stacked
                                        dataset full description will be automatically populated.
                                    </li>
                                    <li>
                                        All datasets compatible with that template, if any, will then be populated in the multi-select combobox.
                                        So select one or more datasets to be stacked with the template dataset.  As you select datasets to be stacked,
                                        the default new stacked dataset full description will be updated.
                                    </li>
                                    <li>
                                        Finally, make sure that the dataset prefix, short description, and full description for the new stacked
                                        dataset to be created are filled in with the values you want.  You do not have to accept the default values
                                        of the dataset prefix or full description.  The defaults are only populated for convenience.
                                    </li>
                                </ul>
                                <p>
                                    The new stacked dataset will be created in the same enviroment (active or staging) as the input datasets.
                                </p>
                                <p>
                                    For datasets to be stacked together, they must 1) be in the same country and industry, 2) not overlap
                                    in industry hierarchy at all, 3) have exactly the same store groups, category attributes, and global
                                    attributes, and 4) start and end all in the same ppmonths as each other.
                                </p>
                            </div>
                        </div>
                    </TabPanel>}
            </Fragment>
        )
    }
}
