export default function handleChange_IndexSwitchDollars (event) {
    let current_switch = this.state.indexSwitchCheckDollars
    let new_switch = !current_switch
    this.setState({
        indexSwitchCheckDollars: new_switch,
    }, () => {
        this.changeSelectedLevel_BaseInc(this.state.selectedLevel_BaseInc)
        this.changeSelectedMetric_Area(this.state.selectedMetric_Area)
    })
    this.changeSelectedBrand({ label: this.state.selectedBrandLabel, value: this.state.selectedBrand }, 0)
}