import CreateWeeksNew from '../../Utilities/CreateWeeks'
import createRankOptions from '../../Utilities/CreateRankOptions'
import { reducer } from '../../Utilities/Reducer'
import removeItemOnce from '../../Utilities/RemoveItemOnce'
export default async function changeSelectedBrand(selectedOptions, position) {

    if (selectedOptions) {
        console.log(selectedOptions)


        var selectedBrand = selectedOptions.value
        var selectedBrandLabel = selectedOptions.label

        if (selectedBrand === 'Total Category') {
            selectedBrand = 'Total Selection'
        }
        
        let chartmetadata_singlebrand = this.state.chartmetadata_singlebrand
        let allbrands = this.state.brandsstringforcaption
        if (selectedBrand === 'Total Selection') {
            chartmetadata_singlebrand['brands'] = allbrands
        }
        else {
            chartmetadata_singlebrand['brands'] = selectedBrand
        }

        this.setState({
            selectedBrand,
            selectedBrandLabel,                     
            chartmetadata_singlebrand,
        })

    }
}