import React, { Component } from 'react';
import { Layout } from '../Layout';
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import { TransferList } from '../PDT/UIElements/TransferList/TransferList'
import '../styles/custom.css'
import * as Constant from '../constants'
import helppdf from "../HelpDocuments/Custom Attribute Groups.pdf"
import helpvideo from "../videos/PDT/Attribute Group Definition.mp4"
import selectColourStyles from '../styles/SelectColourStyles'

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

export class PNPItemGroupDefinition extends Component {
    static displayName = PNPItemGroupDefinition.name;
    constructor(props) {
        super(props);
        this.useDataset = this.useDataset.bind(this)
        this.useDatasetAndFindSettings = this.useDatasetAndFindSettings.bind(this)
        this.SaveItemGroups = this.SaveItemGroups.bind(this)
        this.SaveItemGroupsAndLeave = this.SaveItemGroupsAndLeave.bind(this)
        this.LeaveForReport = this.LeaveForReport.bind(this)        
        this.openDialog = this.openDialog.bind(this)
        this.openDialog_LeaveReport = this.openDialog_LeaveReport.bind(this)
        this.state = {
            item_group_defs: [],
            showBackground: true,
            dialogopen: false,
            dialogopen_LeaveReport: false,
            dialogtitle: "",
            dialogtext: "",
            filters_selected: {}
        }
    }
    async componentDidMount() {        
        const datasetsres = await fetch(Constant.baseapiurl + "Datasets/DatasetDetails")
        var datasetsdata = []
        if (datasetsres.status === 200) {
            datasetsdata = await datasetsres.json()
        }
        else {
            datasetsdata = []
        }
        //set options
        var dataset_lookup = {}
        datasetsdata.forEach(dataset => { dataset_lookup[dataset.dataset_id] = dataset.shortdesc })
        let datasetoptions = datasetsdata.map(dataset => { return { 'label': dataset.shortdesc, 'value': dataset.dataset_id } })
        this.setState({ datasetoptions, datasetsdata })
        //Parse the url, and see if we have a dataset_id and report_id
        var url = window.location.href;
        var temp = url.split("?");
        temp = temp.slice(1)        
        var datasetid_val, reportid_val, env_val
        temp.forEach(parameter => {
            let vals = parameter.split("=")
            let variable_name = vals[0]
            let variable_val = vals[1]
            if (variable_name === 'datasetid') {
                datasetid_val = variable_val
            }
            else if (variable_name === 'reportid') {
                reportid_val = variable_val
            }
            else if (variable_name === 'env') {
                env_val = variable_val
            }
        })

        if (reportid_val && datasetid_val) {
            this.setState({ reportid: reportid_val })
            await this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
            await this.findSettings(datasetid_val)
        }
        else if (datasetid_val) {
            await this.useDataset({ 'label': dataset_lookup[datasetid_val], 'value': datasetid_val })
            await this.findSettings(datasetid_val)
        }
        else {
            this.setState({ showBackground: false })
        }

        if (env_val) {
            this.setState({ extra_param: '?env=staging' })
        }
        else {
            this.setState({ extra_param: '' })
        }


    }

    openDialog(title, text) {
        this.setState({
            dialogopen: true,
            dialogtitle: title,
            dialogtext: text
        })
    }

    openDialog_LeaveReport() {
        this.setState({ dialogopen_LeaveReport: true, })
    }

    async useDataset(selectedOptions) {        
        this.setState({ selecteddataset: selectedOptions })
        //Look up Dataset Info...
        let dataset_id = selectedOptions.value

        var dataset_data
        let dataset_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id)
        if (dataset_response.status === 200) {
            dataset_data = await dataset_response.json()
        }
        else {
            dataset_data = {}
            this.setState({ errorAlert_report: true })
        }

        

        //Set Currency Code...
        let datasetsettings_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + dataset_id)
        var datasetsettings_data
        if (datasetsettings_resp.status === 200) {
            datasetsettings_data = await datasetsettings_resp.json()
        }
        else {
            datasetsettings_data = []
        }

        var currency_resp_data, datasetsettings_list = datasetsettings_data.map(setting => setting.setting_name)
        if (datasetsettings_list.includes('currency_info')) {
            let datasetsettings_currency_resp = await fetch(Constant.baseapiurl + "Datasets/Settings/" + dataset_id + '/currency_info')
            if (datasetsettings_currency_resp.status === 200) {
                currency_resp_data = await datasetsettings_currency_resp.json()
            }
            else {
                currency_resp_data = {
                    "setting_value": {
                        "currencycode": "USD",
                        "currencytype": "Dollars",
                        "currencysymbol": '$'
                    },
                }
            }
        }
        else {
            currency_resp_data = {
                "setting_value": {
                    "currencycode": "USD",
                    "currencytype": "Dollars",
                    "currencysymbol": '$'
                },
            }
        }

        let currencycode = currency_resp_data.setting_value.currencycode
        let currencytype = currency_resp_data.setting_value.currencytype
        let currencysymbol = currency_resp_data.setting_value.currencysymbol        

        var dataset_times_data
        let dataset_times_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id + '/Times')
        if (dataset_times_response.status === 200) {
            dataset_times_data = await dataset_times_response.json()
        }
        else {
            dataset_times_data = []
        }

        var dataset_items_data
        let dataset_items_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id + '/Items')
        if (dataset_items_response.status === 200) {
            dataset_items_data = await dataset_items_response.json()
        }
        else {
            dataset_items_data = []
        }

        //Get Attributes for filtering
        var dataset_attrs_data
        let dataset_attrs_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id + '/AttributeValues')
        if (dataset_attrs_response.status === 200) {
            dataset_attrs_data = await dataset_attrs_response.json()
        }
        else {
            dataset_attrs_data = []
        }

        //Get Brands for filtering

        var dataset_brands_data
        let dataset_brands_response = await fetch(Constant.baseapiurl + "Datasets/" + dataset_id + '/Brands')
        if (dataset_brands_response.status === 200) {
            dataset_brands_data = await dataset_brands_response.json()
        }
        else {
            dataset_brands_data = []
        }

        let itemnums = dataset_items_data.map(item => item.prodid)

        //Pull Brands From Items
        let url = Constant.baseapiurl + "Datasets/" + dataset_id + '/BrandsFromItems'        
        let brands_items_response = await fetch(url,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(itemnums)
            })

        var brands_items_data
        if (brands_items_response.status === 200) {
            brands_items_data = await brands_items_response.json()
        }
        else {
            brands_items_data = []
        }

        //Pull Base Calculation to get Base Prices
        let url_basecalc = Constant.baseapiurl + "Simulation/base_calculation"
        var base_calc_start, base_calc_end, base_calc_geogids, price_lookup = {}
        base_calc_geogids = dataset_data.geogids
        base_calc_start = dataset_times_data[1].substring(0, 10)

        function addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        function formatDate(d) {
            var month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }

            return [year, month, day].join('-');

        }

        var start_date_date = new Date(base_calc_start)
        base_calc_end = formatDate(addDays(start_date_date, 365))

        let base_calc_body = {
            "itemids": itemnums,
            "startdate": base_calc_start,
            "enddate": base_calc_end,
            "dataset_id": parseInt(dataset_id),
            "geogid":  base_calc_geogids
        }

        let base_calc_response = await fetch(url_basecalc,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(base_calc_body)
            })

        var base_calc_data
        if (base_calc_response.status === 200) {
            base_calc_data = await base_calc_response.json()
        }
        else {
            base_calc_data = {}
        }

        //Create Price Lookup
        
        (base_calc_data['itemids'] || []).forEach((item,idx) => {
            price_lookup[item] = (base_calc_data['avgbp']|| [])[idx]
        })

        const prices = base_calc_data['avgbp']
        var asp_min = Math.min(...prices)
        var asp_max = Math.max(...prices)

        //Create Price Band Options

        var user_dataset_settings_data, pricebandssettings_data, price_band_options = [], datasetname = dataset_data.shortdesc
        //Check if PriceBands is in the list of settings....

        let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + dataset_id)
        if (user_dataset_settings_response.status === 200) {
            user_dataset_settings_data = await user_dataset_settings_response.json()
        }
        else {
            user_dataset_settings_data = []
        }

        var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)

        if (usersettings_list.includes('PriceBands')) {
            let pricebandssettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + dataset_id + '/PriceBands')
            if (pricebandssettings_response.status === 200) {
                pricebandssettings_data = await pricebandssettings_response.json()
            }
        }

        if (pricebandssettings_data) {
            let settings_value = pricebandssettings_data.setting_value
            if (settings_value.type === 'rulebased') {
                let bandsize = settings_value.bandsize
                let numbands = settings_value.numbands
                //create the bands...
                let band_start = 0
                let band_end = bandsize
                for (var band = 0; band < numbands; band++) {
                    let band_start_label = band_start + 1
                    if (band === 0) {
                        let price_band_option_label = 'Under ' + currencysymbol + band_end
                        price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                    }
                    else if (band !== (numbands - 1)) {
                        let price_band_option_label = currencysymbol + band_start_label + ' to ' + currencysymbol + band_end
                        price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                    }

                    else if (band === (numbands - 1)) {
                        let price_band_option_label = currencysymbol + band_start_label + '+'
                        price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, 10000000] })  /*arbitrary price to max out the band...*/
                    }

                    band_start += bandsize
                    band_end += bandsize
                }
            }
            else if (settings_value.type === 'custom') {
                price_band_options = settings_value.bands
            }

            //this.setState({ priceBandOptions: price_band_options })
        }

        else {
            let asp_range_raw = asp_max - asp_min
            let max_num_bands = 7
            let band_size_raw = asp_range_raw / max_num_bands
            var band_size = 0
            if (band_size_raw < 7.5) {
                band_size = 5
            }
            else if (band_size_raw >= 7.5 && band_size_raw < 15) {
                band_size = 10
            }

            else if (band_size_raw >= 15 && band_size_raw < 35) {
                band_size = 25
            }

            else if (band_size_raw >= 35 && band_size_raw < 65) {
                band_size = 50
            }

            else if (band_size_raw >= 65 && band_size_raw < 95) {
                band_size = 75
            }

            else if (band_size_raw >= 95 && band_size_raw < 150) {
                band_size = 100
            }

            else if (band_size_raw >= 150 && band_size_raw < 250) {
                band_size = 200
            }

            else if (band_size_raw >= 250 && band_size_raw < 350) {
                band_size = 300
            }

            else if (band_size_raw >= 350 && band_size_raw < 450) {
                band_size = 400
            }

            else {
                band_size = 500
            }

            //If Notebooks, Notebooks and Tablets, or Monitors, set band to 300.
            if (['Notebook Computers', 'Monitors', 'Notebooks and Tablets'].includes(datasetname)) {
                band_size = 300
                max_num_bands = 6
            }

            let band_start = 0
            let band_end = band_size


            for (var band2 = 0; band2 < max_num_bands; band2++) {
                if (band_start < asp_max) {
                    let band_start_label = band_start + 1
                    if (band2 === 0) {
                        let price_band_option_label = 'Under $' + band_end
                        price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                    }
                    else if ((band_end < asp_max) && band2 !== (max_num_bands - 1)) {
                        let price_band_option_label = '$' + band_start_label + ' to $' + band_end
                        price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                    }

                    else if ((band_end < asp_max) && band2 === (max_num_bands - 1)) {
                        let price_band_option_label = '$' + band_start_label + '+'
                        price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, asp_max] })
                    }

                    else {
                        let price_band_option_label = '$' + band_start_label + '+'
                        price_band_options.push({ 'label': price_band_option_label, 'value': [band_start, band_end] })
                    }

                    band_start += band_size
                    band_end += band_size
                }
            }

            if (['Notebook Computers', 'Monitors', 'Notebooks and Tablets'].includes(datasetname)) {
                price_band_options = [
                    { 'label': '$300 and Under', 'value': [0, 300] }
                    , { 'label': '$301 to $500', 'value': [300, 500] }
                    , { 'label': '$501 to $700', 'value': [500, 700] }
                    , { 'label': '$701 to $900', 'value': [700, 900] }
                    , { 'label': '$901 to $1100', 'value': [900, 1100] }
                    , { 'label': '$1101 to $1500', 'value': [1100, 1500] }
                    , { 'label': '$1501 and Above', 'value': [1500, asp_max] }
                ]
            }

            if (['Coffee Makers'].includes(datasetname)) {
                price_band_options = [
                    { 'label': '$75 and Under', 'value': [0, 75] }
                    , { 'label': '$76 to $150', 'value': [75, 150] }
                    , { 'label': '$151 to $225', 'value': [150, 225] }
                    , { 'label': '$226 to $300', 'value': [225, 300] }
                    , { 'label': '$301 to $375', 'value': [300, 375] }
                    , { 'label': '$376 to $450', 'value': [375, 450] }
                    , { 'label': '$451 and Above', 'value': [450, asp_max] }
                ]
            }
        }
       
        //Create Brand Options

        let brand_options = dataset_brands_data.map(brand => { return {'label': brand, 'value': brand}})

        //Create Attribute Options

        let attr_options = {}
        dataset_attrs_data.forEach(attr => {
            attr_options[attr.attribute] = attr.attribute_values.map(attr => { return { 'label': attr, 'value': attr } })
        })

        //Create Item Lookups
        //Brand
        let brand_lookup = {}
        brands_items_data.forEach(item => {
            brand_lookup[item.prodid] = item.brand
        })

        //Attributes
        let attrs = dataset_attrs_data.map(attr=>attr.attribute)
        let attr_lookup = {}
        dataset_items_data.forEach(item => {
            attrs.forEach(attr => {
                attr_lookup[attr] = attr_lookup[attr] || {}
                attr_lookup[attr][item.prodid] = item[attr]
            })            
        })

        this.setState({
            dataset_items_data,
            dataset_data,
            dataset_times_data,
            attr_lookup,
            brand_lookup,
            price_lookup,
            attr_options,
            brand_options,
            price_band_options,
            itemnums,
            asp_min,
            asp_max,
            currencycode,
            currencytype,
            currencysymbol
        })


    }
    async findSettings(datasetid_val) {
        //Look and See if there are item groups defined...

        let user_dataset_settings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetid_val)
        var user_dataset_settings_data
        if (user_dataset_settings_response.status === 200) {
            user_dataset_settings_data = await user_dataset_settings_response.json()
        }
        else {
            user_dataset_settings_data = []
        }

        var usersettings_list = user_dataset_settings_data.map(setting => setting.setting_name)
        var itemgroupssettings_data
        if (usersettings_list.includes('ItemGroups')) {
            let itemgroupsettings_response = await fetch(Constant.baseapiurl + "Datasets/Settings/User/" + datasetid_val + '/ItemGroups')
            if (itemgroupsettings_response.status === 200) {
                itemgroupssettings_data = await itemgroupsettings_response.json()
            }
        }

        else {
            itemgroupssettings_data = {}
        }


        this.setState({ item_group_defs: (itemgroupssettings_data.setting_value || []), showBackground: false })
    }
    async useDatasetAndFindSettings(selectedOptions) {    
        this.setState({ showBackground: true })
        await this.useDataset(selectedOptions)
        await this.findSettings(selectedOptions.value)
        this.setState({ showBackground: false })
    }

    async SaveItemGroups() {

        //Perform Error Checks....

        //Check for item_group_defs that each group has at least one value...
        let group_defs = this.state.item_group_defs        
        var numerrors = 0
        var error_list = []
        group_defs.forEach(group => {            
            
            if (group.item_vals.length < 1) {
                numerrors += 1
                error_list.push({
                    'errortype': 'Too few values. ',
                    'errortext': 'All Groups must have at least one value assigned. ',                    
                    'groupname': group.groupname
                })
            }
            else if (!group.groupname) {
                numerrors += 1
                error_list.push({
                    'errortype': 'Name must be provided. ',
                    'errortext': 'All Groups must have a name. ',                    
                    'groupname': group.groupname
                })
            }
        })
        

        if (numerrors === 0) {
            const setting = this.state.item_group_defs
            let datasetidnum = this.state.selecteddataset.value
            let url = Constant.baseapiurl + "Datasets/Settings/User/" + datasetidnum + '/ItemGroups'
            let settings_response = await fetch(url,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(setting)
                })

            //var settings_obj
            if (settings_response.status === 200) {
                //settings_obj = await settings_response.json()
                //if report id, then send back to the report page...                
                this.openDialog('Item Groups Saved', 'Item Groups Successfully Saved.')
                //Allow the user a choice to keep editing or go back to the report...
                if (this.state.reportid) {
                    let url = Constant.baseurl + 'PNP/Report/' + this.state.reportid + this.state.extra_param
                    window.location.href = url
                }
            }
            else {
                //settings_obj = {}
                this.openDialog('Error', 'There was an Error Setting the Item Groups')
            }
        }
        else {
            var errortext = ''
            error_list.forEach(error => {
                errortext += error.errortype + error.errortext + ' in group ' + error.groupname + '.  '
            })
            this.openDialog('Errors', 'There are the following Errors. ' + errortext)
        }

        return 'Done Saving'

    }

    LeaveForReport() {
        var url
        if (this.state.reportid) {
            url = Constant.baseurl + 'PNP/Datasets'
        }

        else {
            url = Constant.baseurl + 'PNP/Datasets'
        }

        window.location.href = url
    }

    async SaveItemGroupsAndLeave() {        
        await this.SaveItemGroups()
        this.LeaveForReport()
    }

    render() {

        console.log(this.state)

        const dropdownInput = {
            width: '100%',
            //height: '50px',
            borderRadius: '5px',
            //padding: '10px',
            fontFamily: 'Roboto',
            backgroundColor: '#FFF'
        }

        const handleCloseDialog = () => {
            this.setState({ dialogopen: false })
        }

        const handleCloseDialog_LeaveReport = () => {
            this.setState({ dialogopen_LeaveReport: false })
        }

        const clearChecked = (groupnum) => {
            let items_checked_lookup = this.state.items_checked_lookup || {}            
            items_checked_lookup[groupnum] = []
            this.setState({ items_checked_lookup })
        }

        const handleGroupNameChange = (idx) => {
            let item_group_defs = this.state.item_group_defs
            let existinggroups = (item_group_defs || [])

            let existingnames = existinggroups.map(group => group.groupname)

            let group = existinggroups[idx]
            let ElID = "groupname" + idx
            let name = document.getElementById(ElID).value

            if (existingnames.includes(name)) {
                this.openDialog('Name Already Exists', 'Name Already Exists.  Adding COPY to name')
                name += ' COPY'
            }

            group['groupname'] = name
            this.setState({ item_group_defs })
        }

        const additemgroup = () => {
            let item_vals = this.state.dataset_items_data.map(item => item.prodid)
            let used_items = []
            this.state.item_group_defs.forEach(group => used_items = used_items.concat(group.item_vals))
            let valid_vals = not(item_vals, used_items)
            if (valid_vals.length > 0) {
                let item_group_defs = this.state.item_group_defs
                let existinggroups = (item_group_defs || [])

                //check that all existing groups have at least one value
                let numerrors = 0
                existinggroups.forEach(group => {
                    if (group.item_vals.length < 1) {
                        numerrors += 1
                    }
                })

                if (numerrors === 0) {
                    let newgroupnum = existinggroups.length + 1
                    let newgroup = { 'groupname': 'New Group ' + newgroupnum, 'item_vals': [] }
                    existinggroups.push(newgroup)
                    item_group_defs = existinggroups
                    this.setState({ item_group_defs })
                }

                else {
                    this.openDialog('Can Not Add Group', 'Please add values to the existing group that is empty.')
                }

            }

            else {
                this.openDialog('Can Not Add Group', 'There are no more values to add to groups.')
            }

        }

        const removeitemgroup = (idx) => {
            let item_group_defs = this.state.item_group_defs
            let existinggroups = (item_group_defs || [])
            //let grouptodelete = existinggroups[idx]
            //let groupvals = grouptodelete['item_vals']

            //let item_vals = this.state.dataset_items_data.map(item => item.prodid)
            let used_items = []
            this.state.item_group_defs.forEach(group => used_items = used_items.concat(group.item_vals))
            //let valid_vals = not(item_vals, used_items)

            existinggroups.splice(idx, 1)
            item_group_defs = existinggroups
            this.setState({ item_group_defs })            
        }

        const handleToggle = (groupnum, value) => {
            let item_num_lookup = {}
            this.state.dataset_items_data.forEach(item => {
                item_num_lookup[item.proddesc] = item.prodid
            })

            //let itemnum = item_num_lookup[value]

            let items_checked_lookup = this.state.items_checked_lookup || {}
            let checked = items_checked_lookup[groupnum] || []
            let currentIndex = checked.indexOf(value);
            let newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);
            }
            else {
                newChecked.splice(currentIndex, 1);
            }
            items_checked_lookup[groupnum] = newChecked
            this.setState({ items_checked_lookup })
        }

        const handleCheckedRight = (attr, groupnum) => {

            let item_group_defs = this.state.item_group_defs
            let items_checked_lookup = this.state.items_checked_lookup || {}

            let item_num_lookup = {}
            this.state.dataset_items_data.forEach(item => {
                item_num_lookup[item.proddesc] = item.prodid
            })

            let item_num_lookup_all = {}
            this.state.dataset_items_data.forEach(item => {
                if (item_num_lookup_all[item.proddesc]) {
                    item_num_lookup_all[item.proddesc].push(item.prodid)
                }
                else {
                    item_num_lookup_all[item.proddesc] = [item.prodid]
                }

            })

            //let itemnum = item_num_lookup[value]

            let items_checked_lookup_group = items_checked_lookup[groupnum] || []
            let checked = [] //items_checked_lookup_group.map(item => item_num_lookup[item])
            items_checked_lookup_group.forEach(item => checked = checked.concat(item_num_lookup_all[item]))
            
            let item_vals = this.state.dataset_items_data.map(item => item.prodid)
            let used_items = []
            this.state.item_group_defs.forEach(group => used_items = used_items.concat(group.item_vals))
            let valid_vals = not(item_vals, used_items)
            let group = (item_group_defs || [])[groupnum]

            let left = valid_vals
            let right = group.item_vals
            let leftchecked = intersection(checked, left)

            let newvals = right.concat(leftchecked)
            //let newvalid_vals = not(left, leftchecked)

            item_group_defs[groupnum]['item_vals'] = newvals


            //Set State
            this.setState({
                item_group_defs,
            })

            clearChecked(groupnum);

        }

        const handleCheckedLeft = (attr, groupnum) => {
            let item_group_defs = this.state.item_group_defs
            let items_checked_lookup = this.state.items_checked_lookup || {}

            let item_num_lookup = {}
            this.state.dataset_items_data.forEach(item => {
                item_num_lookup[item.proddesc] = item.prodid
            })

            let item_num_lookup_all = {}
            this.state.dataset_items_data.forEach(item => {
                if (item_num_lookup_all[item.proddesc]) {
                    item_num_lookup_all[item.proddesc].push(item.prodid)
                }
                else {
                    item_num_lookup_all[item.proddesc] = [item.prodid]
                }
                
            })

            //let itemnum = item_num_lookup[value]

            let items_checked_lookup_group = items_checked_lookup[groupnum] || []
            let checked = [] //items_checked_lookup_group.map(item => item_num_lookup[item])
            items_checked_lookup_group.forEach(item => checked = checked.concat(item_num_lookup_all[item]))
            //Find any other item descriptions that match the checked items and add to that...
            //let item_vals = this.state.dataset_items_data.map(item => item.prodid)
            let used_items = []
            this.state.item_group_defs.forEach(group => used_items = used_items.concat(group.item_vals))
            //let valid_vals = not(item_vals, used_items)


            let group = (item_group_defs || [])[groupnum]

            //let left = valid_vals
            let right = group.item_vals

            let rightchecked = intersection(checked, right)

            //let newvalid_vals = left.concat(rightchecked)
            let newvals = not(right, rightchecked)

            item_group_defs[groupnum]['item_vals'] = newvals


            //Set State
            this.setState({
                item_group_defs
            })

            clearChecked(groupnum);

        }

        const filterItems = (selectedOptions, level) => {
            //Set Filters....
            let filters = this.state.filters || {}
            let filters_selected = this.state.filters_selected || {}


            if (selectedOptions) {
                filters[level] = (selectedOptions).map(option => option.value)
                filters_selected[level] = filters_selected[level] || [{ 'label': 'All', 'value': 'All' }]

                if ((filters_selected[level]).length === 1 && (filters_selected[level])[0]['value'] === 'All') {
                    var selections = [], options = []
                    selectedOptions.forEach(selection => {
                        if (selection.value !== 'All') {
                            selections.push(selection)
                            options.push(selection.value)
                        }
                    })

                    filters_selected[level] = selections
                    filters[level] = options
                }

                else if (filters[level].includes('All') && (filters_selected[level]).length >= 1) {
                    filters_selected[level] = [{ 'label': 'All', 'value': 'All' }]
                    filters[level] = ['All']
                }

                else {
                    filters_selected[level] = selectedOptions
                }

                
            }
            else {
                filters[level] = []
                filters_selected[level] = [{'label': 'All', 'value': 'All'}]
            }

            //Look through the filters and find all eligible items....
            let allitems = this.state.itemnums

            let filter_keys = Object.keys(filters)
            let validitems = allitems
            let filteritems = []
            let all_filters_empty = false
            let numfilters_blank = 0
            filter_keys.forEach((key, idx) => {
                var filter_values = filters[key]
                var useAll = false
                if (filter_values.includes('All')) {
                    useAll = true
                }

                let itemsfromfilter = []
                //Check if the filter is blank...if all filters are blank, we should use all items
                if (filter_values.length === 0) {
                    numfilters_blank += 1
                }
                if (key === 'brand') {
                    //use the brand_lookup                    
                    allitems.forEach(item => {
                        let itembrand = this.state.brand_lookup[item]
                        if (useAll === true) {
                            itemsfromfilter.push(item)
                        }
                        else if (filter_values.includes(itembrand)) {
                            itemsfromfilter.push(item)
                        }
                    })
                }

                else if (key === 'priceband') {
                    //use the price_band_lookup    
                    //Unpack the Array
                    let values = []
                    filter_values.forEach(array => {
                        if (array !== 'All') {
                            array.forEach(val => values.push(val))
                        }                        
                    })
                    //get min max of filter range
                    let min_price = Math.min(...values)
                    let max_price = Math.max(...values)
                    allitems.forEach(item => {
                        let itemprice = this.state.price_lookup[item]
                        if (useAll === true) {
                            itemsfromfilter.push(item)
                        }
                        else if (itemprice >= min_price && itemprice <= max_price) {
                            itemsfromfilter.push(item)
                        }
                    })
                }

                else {                    
                    allitems.forEach(item => {
                        let itemattr = this.state.attr_lookup[key][item]
                        if (useAll === true) {
                            itemsfromfilter.push(item)
                        }
                        else if (filter_values.includes(itemattr)) {
                            itemsfromfilter.push(item)
                        }
                    })
                }

                //If blank values, use all items as eligible.
                if (filter_values.length === 0) {
                    itemsfromfilter = allitems
                }

                if (idx === 0) {
                    filteritems = itemsfromfilter
                }
                else {
                    filteritems = intersection(filteritems, itemsfromfilter)
                }
            })

            if (numfilters_blank === filter_keys.length) {
                all_filters_empty = true
            }

            if (all_filters_empty === true) {
                validitems = allitems
            }          

            else {
                validitems = intersection(validitems,filteritems)
            }

            this.setState({ filteritems: validitems, filters })

        }

        return (
            <Layout title="Item Group Definition" >
                <Constant.NPDBackdrop
                    open={this.state.showBackground}
                    invisible={false}
                >
                    {/*<CircularProgress color="inherit" />*/}
                    <div class="custom-loader"></div>
                </Constant.NPDBackdrop>

                <Dialog
                    open={this.state.dialogopen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dialogtitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.dialogtext}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog} className="btn btn-primary" autoFocus>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogopen_LeaveReport}
                    onClose={handleCloseDialog_LeaveReport}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: '99999999' }}
                >
                    <DialogTitle id="alert-dialog-title">Go Back to Datasets Page?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to navigate back to the datasets page.  Would you like to save your changes, or navigate back without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDialog_LeaveReport} className="btn btn-cancel" autoFocus>
                            Cancel
                        </button>
                        <button onClick={() => this.SaveItemGroupsAndLeave()} className="btn btn-primary" autoFocus>
                            Save Changes and Go Back
                        </button>
                        <button onClick={() => this.LeaveForReport()} className="btn btn-secondarynpd" autoFocus>
                            Go Back Without Saving
                        </button>
                    </DialogActions>
                </Dialog>

                <div name='banner' className='banner'>
                    <h1white align='center'>Price and Promotion Item Groups Definition</h1white><br/>
                    <h2white align='center'>Use this page to set custom item groups</h2white>
                </div>

                <div className="mainbodydiv" style={{ padding: '50px' }}>
                    <div className='row'>
                        <div className='col-sm-12' style={{ padding: '12px' }}>
                            {/*<a href={helppdf} target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.2rem' }}> Click here to view instructions </a>*/}
                            {/*<br />*/}
                            {/*<a href={helpvideo} target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.2rem' }}>Click here to watch a how to video</a>*/}
                            <h7  >Instructions and video coming soon</h7>
                        </div>
                        <div className='col-sm-12' style={{ padding: '12px' }}>
                            <h4>Select a Dataset</h4>
                            <div className='col-sm-6' >
                                <Select
                                    inputId="datasetdropdown"
                                    options={this.state.datasetoptions}
                                    placeholder={'Select a Dataset'}
                                    onChange={(options) => this.useDatasetAndFindSettings(options)}
                                    value={this.state.selecteddataset}
                                    styles={selectColourStyles}
                                />
                            </div>
                        </div>

                        {this.state.selecteddataset ?
                            <div className='col-sm-12'>
                                <div className='col-sm-12 row'>
                                    <Tooltip
                                        title='Go Back to Datasets Page'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={() => this.openDialog_LeaveReport()}
                                        >
                                            <SettingsBackupRestoreRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title='Add An Item Group'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={() => additemgroup()}
                                        >
                                            <AddRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title='Save Item Group'
                                        placement='top-start'
                                    >
                                        <IconButton
                                            onClick={() => this.SaveItemGroups()}
                                        >
                                            <SaveRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <div className='col-sm-12' style={{ padding: '12px' }}>
                                    Note: All groups must have at least 1 value assigned to them.  However, not all item values need to be assigned to a group. Any unassigned values will be displayed in the charts by themselves when viewing by groups.
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12' style={{ padding: '12px' }}>
                                        <h2>Filters</h2>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4' style={{ padding: '12px' }}>
                                        <h4>Brands</h4>

                                        <Select
                                            inputId={"BrandDropdown"}
                                            options={[{ 'label': 'All', 'value': 'All' }].concat((this.state.brand_options || []))}
                                            placeholder={'Select a Brand'}
                                            isMulti
                                            onChange={(options, level) => filterItems(options, 'brand')}
                                            value={this.state.filters_selected['brand'] || [{ 'label': 'All', 'value': 'All' }]}
                                            styles={selectColourStyles}
                                        />

                                    </div>

                                    <div className='col-sm-4' style={{ padding: '12px' }}>
                                        <h4>Price Bands</h4>

                                        <Select
                                            inputId={"PriceBandDropdown"}
                                            options={[{ 'label': 'All', 'value': 'All' }].concat((this.state.price_band_options || []))}
                                            placeholder={'Select a Price Band'}
                                            isMulti
                                            onChange={(options, level) => filterItems(options, 'priceband')}
                                            value={this.state.filters_selected['priceband'] || [{ 'label': 'All', 'value': 'All' }]}
                                            styles={selectColourStyles}
                                        />

                                    </div>

                                    {((this.state.dataset_data || {})['attributes'] || []).map((attr, idx) => {

                                        if (attr !== 'equiv') {
                                            return <div className='col-sm-4' style={{ padding: '12px' }} key={attr + '_' + idx}>
                                                <h4>{((this.state.dataset_data || {})['attributes_human'] || [])[idx]}</h4>

                                                <Select
                                                    inputId={"attrdropdown_" + attr}
                                                    options={[{ 'label': 'All', 'value': 'All' }].concat((((this.state.attr_options || {})[attr]) || []))}
                                                    placeholder={'Select a ' + ((this.state.dataset_data || {})['attributes_human'] || [])[idx]}
                                                    isMulti
                                                    onChange={(options, level) => filterItems(options, attr)}
                                                    value={this.state.filters_selected[attr] || [{ 'label': 'All', 'value': 'All' }]}
                                                    styles={selectColourStyles}
                                                />

                                            </div>
                                        }

                                        else {
                                            return null
                                        }
                                    })
                                    }
                                </div>


                                {(this.state.item_group_defs || []).map((group, idx) => {
                                    let groupnum = idx
                                    let checked = ((this.state.items_checked_lookup || {})[groupnum] || [])
                                    let item_vals = this.state.filteritems || this.state.itemnums
                                    let used_items = []
                                    this.state.item_group_defs.forEach(group => used_items = used_items.concat(group.item_vals))
                                    let valid_vals = Array.from(new Set(not(item_vals, used_items)))
                                    let item_name_lookup = {}
                                    this.state.dataset_items_data.forEach(item => {
                                        item_name_lookup[item.prodid] = item.proddesc
                                    })
                                    let valid_item_names = Array.from(new Set(valid_vals.map(item => item_name_lookup[item])))
                                    let group_item_names = Array.from(new Set(group.item_vals.map(item => item_name_lookup[item])))

                                    let elID = "groupname" + groupnum
                                    return <div key={'groupdef_' + groupnum} className='col-sm-12 row' style={{ padding: '12px' }}>
                                        <div className='col-sm-3' style={{ padding: "12px" }}>
                                            {/*Dropdown with name*/}
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                placeholder="Group Name"
                                                inputProps={{ 'aria-label': 'naked', id: elID }}
                                                onChange={(idx) => handleGroupNameChange(groupnum)}
                                                style={dropdownInput}
                                                value={group.groupname}
                                            />
                                        </div>
                                        <div className='col-sm-3'>

                                            {/*Delete Button*/}
                                            <Tooltip
                                                title='Delete This Item Group'
                                                placement='top-start'
                                            >
                                                <IconButton
                                                    onClick={(idx) => removeitemgroup(groupnum)}
                                                >
                                                    <DeleteRoundedIcon style={{ color: '#4e106f', fontSize: '1.5vw' }} />
                                                </IconButton>
                                            </Tooltip>

                                        </div>
                                        <div className='col-sm-6' />
                                        <div className='col-sm-12' style={{ padding: "12px" }}>
                                            {/*Transfer List*/}
                                            <TransferList
                                                left={valid_item_names}
                                                right={group_item_names}
                                                attr={groupnum}
                                                //attr={(this.state.selectedattr || {}).value}
                                                groupnum={groupnum}
                                                checked={checked}
                                                handleToggle={handleToggle}
                                                handleCheckedRight={handleCheckedRight}
                                                handleCheckedLeft={handleCheckedLeft}
                                            />

                                        </div>
                                    </div>
                                })}
                            </div>
                            :
                            <div/>
                        }
                        
                    </div>
                </div>
            </Layout>
        )
    }
}